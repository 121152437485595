import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {Faculty, KafedraGet} from "../../../models/disciplineworkload/externals.model";
import {handleError} from "../../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.department}`;
  constructor(private http: HttpClient) { }

  // Get departments
  getDepartments(): Observable<KafedraGet[]> {
    return this.http.get<KafedraGet[]>(this.baseUrl + '/GetAllDepartments/')
        .pipe(
            catchError(handleError));
  }

  // Get kafedras
  getKafedras(): Observable<KafedraGet[]> {
    return this.http.get<KafedraGet[]>(this.baseUrl + '/GetKafedras');
  }

  getFaculties(): Observable<Faculty[]> {
    return this.http.get<Faculty[]>(this.baseUrl + '/GetFaculties/')
        .pipe(
            catchError(handleError));
  }
}

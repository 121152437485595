import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DictLessonForm } from 'src/app/models/education/dictLessonForm.model';
import { DictLessonFormService } from 'src/app/services/education/DictLessonForm/dict-lesson-form.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'dict-lesson-form',
  templateUrl: './dict-lesson-form.component.html',
  styleUrls: ['./dict-lesson-form.component.scss']
})
export class DictLessonFormComponent implements OnInit {

  public gridStyle = 'grid-default';
  public editable?: boolean;
  public isOpened: boolean = false;
  public loading: boolean = false;
  public isNew: boolean = false;
  public lessonForms!: DictLessonForm[];

  constructor(private dictLessonFormService: DictLessonFormService,
    private notificationService: NotificationsService,
    private userAccessService:EducationUserAccessService) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getAllLessonForms();
  };

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictLessonFormId: new FormControl(0),
    dictLessonFormExternalId: new FormControl(""),
    dictLessonFormName: new FormControl(""),
    dictLessonFormShortName: new FormControl(""),
  });

  //Getting all entries from LessonForm
  public getAllLessonForms() {
    this.loading = true;
    this.dictLessonFormService.getAllLessonForms()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.lessonForms = response;
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить формы проведения занятий");
        }
      });
  }

  public saveLessonForm() {
    // Add new LessonForm
    if (this.isNew) {
      this.dictLessonFormService.addLessonForm(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Форма проведения занятий была успешно добавлена!");
              this.getAllLessonForms();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить форму проведения занятий");
              this.onCancel();
            }
          });
    }
    // Edit LessonForm
    else {
      this.dictLessonFormService.updateLessonForm(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Форма проведения занятий была успешно сохранена!");
              this.getAllLessonForms();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось изменить форму проведения занятий');
              this.onCancel();
            }
          })
    }
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }
}

const formGroup = (dataItem: {
  dictLessonFormId?: any;
  dictLessonFormExternalId?: any;
  dictLessonFormName?: any;
  dictLessonFormShortName?: any; }) =>
    new FormGroup({
      dictLessonFormId: new FormControl(dataItem.dictLessonFormId),
      dictLessonFormExternalId: new FormControl(dataItem.dictLessonFormExternalId),
      dictLessonFormName: new FormControl(dataItem.dictLessonFormName),
      dictLessonFormShortName: new FormControl(dataItem.dictLessonFormShortName),
    });
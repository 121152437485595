<kendo-appbar position="top" *ngIf="authService.isUserAuthenticated()">
  <kendo-appbar-section style="position: absolute">
    <a href="/" class="logo"></a>
    <h1 class="title">ЮНИВУЗ 3.0</h1>
  </kendo-appbar-section>
  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-appbar-section>
    <h1 class="title full">{{fullTitle}}</h1>
    <h1 class="title small">{{shortTitle}}</h1>
  </kendo-appbar-section>
  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-menu class="personMenu">
    <kendo-menu-item text="Name">
      <ng-template kendoMenuItemTemplate let-item="item">
        <div class="menuPicture" [style.background-image]="photoUrl"></div>
        <h2 class="personFullName">{{person.fullName}}</h2>
        <div class="burgerMenu k-icon k-i-menu k-icon-md"></div>
      </ng-template>
      <kendo-menu-item *ngIf="isAdmin && hasSwitchUser" [cssStyle]="{ color: '#42A7DD' }"  icon="user">
        <ng-template kendoMenuItemTemplate let-item="item">
          <div class="menuItem" (click)="switchUser()">
            <h2 class="menuTitle">Просмотр от другого лица</h2>
          </div>
        </ng-template>
      </kendo-menu-item>
      <kendo-menu-item *ngIf="isSwitchActive" [cssStyle]="{ color: '#42A7DD' }"  icon="circle">
        <ng-template kendoMenuItemTemplate let-item="item">
          <div class="menuItem" (click)="stopUserPreview()">
            <h2 class="menuTitle">Остановить просмотр</h2>
          </div>
        </ng-template>
      </kendo-menu-item>
      <kendo-menu-item [cssStyle]="{ color: '#42A7DD' }" text="logout"  icon="logout">
        <ng-template kendoMenuItemTemplate let-item="item">
          <div class="menuItem" (click)="logOut()">
            <h2 class="menuTitle">Выход</h2>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu-item>
  </kendo-menu>
</kendo-appbar>
<kendo-drawer-container *ngIf="authService.isUserAuthenticated() && !this.isBlocked && userHasSimpleSignature">
  <kendo-drawer
    #drawer
    [items]="items"
    [mini]="true"
    mode="push"
    [(expanded)]="expanded"
    (select)="onSelect($event)"
  >
  <ng-template kendoDrawerItemTemplate let-item>
    <a [ngClass]="{urlItem: item.return}" target="_blank" href="{{item.url}}" class="drawerItemBody"
       *ngIf="menuVisible(item)"
       [class.disabled]="item.disabled == true"
       (click)="menuClick(item)">
      <div class="drawerItemContent">
        <kendo-badge-container>
          <span class="k-icon item-icon {{ item.icon }}"></span>
          <kendo-badge *ngIf="isAlertEnabled(item.text)">{{getBadgeCount(item.text)}}</kendo-badge>
        </kendo-badge-container>
        <span class="drawerItemText" *ngIf="expanded">{{ item.text }}</span>
      </div>
    </a>
  </ng-template>
  </kendo-drawer>
  <kendo-drawer-content>
    <kendo-breadcrumb
      [collapseMode]="collapseMode"
      (itemClick)="onItemClick($event)"
      class="breadcrumb-style"
      [items]="breadcrumbitems"
    ></kendo-breadcrumb>
    <router-outlet></router-outlet>
  </kendo-drawer-content>
</kendo-drawer-container>
<login-page *ngIf="showLoginPage()"></login-page>
<callback *ngIf="showCallbackPage()"></callback>
<person-blocked-notification *ngIf="isBlocked"></person-blocked-notification>
<joining-person-to-regulation *ngIf="!userHasSimpleSignature"></joining-person-to-regulation>

import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Person} from "../../models/oferta/person.model";

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.oferta.person}`;

  constructor(private http: HttpClient) {
  }

  public getAllPersons(): Observable<Person[]> {
    return this.http.get<Person[]>(this.baseUrl);
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {handleError} from "../../../helpers/errorHandle-helper";
import {Role} from "../../../models/mfc/dicts/role.model";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/Role/`;

  constructor(private http: HttpClient) { }

  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.baseUrl);
  }

  public getRole(id: string): Observable<Role> {
    return this.http.get<Role>(`${this.baseUrl}${id}`);
  }

  public addRole(role: Role): Observable<Role> {
    return this.http.post<Role>(this.baseUrl, role)
      .pipe(catchError(handleError));
  }

  public updateRole(role: Role): Observable<Role> {
    return this.http.put<Role>(this.baseUrl, role)
      .pipe(catchError(handleError));
  }

  public removeRole(id: string): Observable<Role> {
    return this.http.delete<Role>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

export enum PublicationEditFormTabs {
    MainInfo = 'Основная информация',
    AuthorsAndThematicRubrications = 'Авторы, организации и ГРНТИ',
    Codes = 'Коды',
    About = 'О публикации',
    Comments = 'Комментарии'
}

export enum PublicationProperties {
    MainInfo = 1,
    AuthorsAndThematicRubrications = 2,
    Codes = 3,
    About = 4,
    Comments = 5
}

export enum KendoProperties {
    Textbox = 0,
    Number = 1,
    Float = 2,
    Date = 3,
    Boolean = 4,
    File = 5,
    Textarea = 6,
    Table = 7,
    Dropdown = 8,
    Multiselect = 9,
    Combobox = 10
}

export enum DissertationDataSources {
    DegreeSpeciality = 'Специальность ученой степени',
}

export enum ArticlesDigestDataSources {
  Edition = 'Тираж',
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UserAccessService} from '../../../services/mfc/userAccess.service';
import {
  AccessMainSettings,
  ApplicationAccessRights, ErrorInfo, filterKeyMap, UpdateAccessRights, UpdateApplicationAccess,
  UserAccessForm,
  UserAccessRight, UserAccessRightsForm,
  UserAccessRightsList
} from '../../../models/mfc/userAccess.model';
import {
  AddEvent,
  EditEvent,
  GridComponent, GridDataResult, PageChangeEvent,
  PagerSettings,
  RemoveEvent,
  SaveEvent
} from '@progress/kendo-angular-grid';
import {boolOptions, dropdownOptions} from '../../../models/useraccess/options';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogCloseResult, DialogService} from '@progress/kendo-angular-dialog';
import {openRemoveDialog} from '../../../helpers/dialogHelper';
import {
  DropDownFilterSettings, MultiSelectComponent,
  MultiSelectTreeComponent,
  VirtualizationSettings
} from '@progress/kendo-angular-dropdowns';
import {ApplicationTypeService} from 'src/app/services/mfc/dicts/application-type.service';
import {ApplicationCategoryService} from 'src/app/services/mfc/dicts/application-category.service';
import {FilialService} from '../../../services/mfc/dicts/filial.service';
import {TrainingLevelService} from '../../../services/mfc/dicts/training-level.service';
import {UserService} from '../../../services/mfc/user.service';
import {DepartmentService} from '../../../services/mfc/dicts/department.service';
import {StudyFormService} from '../../../services/mfc/dicts/study-form.service';
import {RoleService} from '../../../services/mfc/dicts/role.service';
import {lastValueFrom} from 'rxjs';
import {ApplicationType} from '../../../models/mfc/dicts/application-type.model';
import {ApplicationCategory} from '../../../models/mfc/dicts/application-category.model';
import {Role} from '../../../models/mfc/dicts/role.model';
import {StudyForm} from '../../../models/mfc/dicts/study-form.model';
import {TrainingLevel} from '../../../models/mfc/dicts/training-level.model';
import {pagerSettings} from '../../../models/mfc/pagerSettings.model';
import {AccessRights} from '../../../models/mfc/enums/access-rights.enum';
import {Dict} from '../../../models/mfc/dict.model';
import {FacultyService} from '../../../services/mfc/dicts/faculty.service';
import {FacultyTreeItem} from '../../../models/mfc/faculties-tree.model';
import {process, SortDescriptor, State} from '@progress/kendo-data-query';
import {PersonService} from '../../../services/mfc/person.service';
import {Person} from '../../../models/mfc/person.model';
import {NotificationsService} from '../../../services/notifications/notifications.service';


@Component({
  selector: 'mfc-user-access',
  templateUrl: './userAccess.component.html',
  styleUrls: ['./userAccess.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAccessComponent implements OnInit {

  @ViewChild('grid') protected readonly grid?: GridComponent;
  @ViewChild('mainSettingsGrid') protected readonly mainSettingsGrid?: GridComponent;
  @ViewChild('applicationAccessGrid') protected readonly applicationAccessGrid?: GridComponent;

  protected userAccesses: UserAccessRightsList[] = [];
  protected gridData: UserAccessRightsList[] = [];
  protected gridView!: GridDataResult;
  protected searchValue = '';
  protected formGroup = this.createFormGroup();

  protected dictTrainingLevels: TrainingLevel[] = [];
  protected dictFaculties: FacultyTreeItem[] = [];
  protected dictApplicationTypes: ApplicationType[] = [];
  protected dictApplicationCategories: ApplicationCategory[] = [];
  protected dictRoles: Role[] = [];
  protected persons: Person[] = [];
  protected dictStudyForms: StudyForm[] = [];

  protected typesView: (ApplicationType | ApplicationCategory)[] = [];

  protected readonly boolOptions = boolOptions;
  protected readonly dropdownOptions = dropdownOptions;
  protected readonly accessOptions = dropdownOptions.filter(a => a.id !== AccessRights.No);
  protected readonly userAccessText = dropdownOptions.map(a => a.text);

  protected editMode = false;
  protected loading = false;
  protected lastRowIndex?: number = undefined;
  protected isNew = false;

  protected errors: Map<number, ErrorInfo> = new Map([]);
  protected personExist = false;

  protected pagerSettings: PagerSettings = pagerSettings;
  protected skip = 0;
  protected pageSize = 10;
  protected state: State = {
    skip: 0,
    take: this.pageSize,
  };
  protected sort: SortDescriptor[] = [];
  protected expandedDetailKeys: string[] = [];

  protected readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  protected readonly filterVirtualization: boolean | VirtualizationSettings = {
    itemHeight: 28
  };

  constructor(
    private userAccessService: UserAccessService,
    private applicationTypeService: ApplicationTypeService,
    private applicationCategoryService: ApplicationCategoryService,
    private filialService: FilialService,
    private trainingLevelService: TrainingLevelService,
    private facultyService: FacultyService,
    private userService: UserService,
    private personService: PersonService,
    private departmentService: DepartmentService,
    private studyFormService: StudyFormService,
    private roleService: RoleService,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    await this.getPersons();
    this.getUserAccessData();
    this.getApplicationTypes();
    this.getTrainingLevels();
    this.getStudyForms();
    this.getFaculties();
    this.getRoles();
  }

  private getUserAccessData() {
    this.loading = true;
    this.userAccessService.getAll().subscribe((response) => {
      this.userAccesses = response.map((item) => this.mapUserAccessData(item));
      this.searchByFIO();
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  private mapUserAccessData(data: UserAccessRight): UserAccessRightsList {
    return {
      personId: data.personId,
      fio: this.getFio(data),
      userAccessAccessRights: data.userAccessAccessRights,
      userAccessMainSettings: {
        roles: data.roles,
        ...data.userAccessMainSettings
      }
    };
  }

  private getFio(user: UserAccessRight) {
    return `${user.lastName} ${user.firstName}${user.middleName ? ` ${user.middleName}` : ''}`;
  }

  private getApplicationTypes() {
    lastValueFrom(this.applicationTypeService.getApplicationTypes()).then(value => {
      this.dictApplicationTypes = value;
      this.getApplicationCategories();
    });
  }

  private getRoles() {
    lastValueFrom(this.roleService.getRoles()).then(value => this.dictRoles = value);
  }

  private getStudyForms() {
    lastValueFrom(this.studyFormService.getStudyForms()).then(value => this.dictStudyForms = value);
  }

  private getApplicationCategories() {
    lastValueFrom(this.applicationCategoryService.getApplicationCategories()).then(value => {
      this.dictApplicationCategories = value.filter(a => !a.hidden);
      const typesCategoryIds = this.dictApplicationTypes.map((item) => item.dictApplicationCategoryId);
      const categories = this.dictApplicationCategories.filter((item) => typesCategoryIds.includes(item.id));
      this.typesView.push(...categories);

      const categoriesIds = categories.map((item) => item.id);
      this.typesView.push(...this.dictApplicationTypes.filter((item) => categoriesIds.includes(item.dictApplicationCategoryId)));
    });
  }

  private getTrainingLevels() {
    lastValueFrom(this.trainingLevelService.getTrainingLevels()).then(value =>
      this.dictTrainingLevels = value);
  }

  private getFaculties() {
    lastValueFrom(this.facultyService.getFilialsAndFaculties()).then(value => {
      const filials = value.map((filial) => ({id: filial.id, name: filial.name}));
      this.dictFaculties = value.map((filial) => filial.faculties.map((faculty) =>
        ({id: faculty.id, name: faculty.name, filialId: filial.id}))).flat();
      this.dictFaculties.unshift(...filials);
    });
  }

  private async getPersons() {
    await lastValueFrom(this.personService.getAll()).then(value => this.persons = value);
  }

  private mapDataItemToForm(dataItem: UserAccessRightsList): UserAccessForm {
    return {
      personId: dataItem.personId,
      ...dataItem.userAccessMainSettings,
      roles: dataItem.userAccessMainSettings.roles.map((item) => item.id),
      userAccessAccessRights: dataItem.userAccessAccessRights
        .map((item) => {
          const applicationTypes = item.isAllApplicationTypesAvailable ? this.typesView : item.applicationTypes
            .map((item) => this.typesView.find((type) => type.id === item.id))
            .filter((item) => !!item);
          const faculties = item.isAllFacultiesAvailable ? this.dictFaculties : item.faculties
            .map((item) => this.dictFaculties.find((faculty) => faculty.id === item.id))
            .filter((item) => !!item);
          return {
            ...item,
            trainingLevels: (item.isAllTrainingLevelsAvailable ? this.dictTrainingLevels : item.trainingLevels).map((item) => item.id),
            faculties: faculties as FacultyTreeItem[],
            studyForms: (item.isAllStudyFormsAvailable ? this.dictStudyForms : item.studyForms).map((item) => item.id),
            applicationTypes: applicationTypes as (ApplicationType | ApplicationCategory)[],
          };
      })
    };
  }

  private createFormGroup(dataItem?: UserAccessForm) {
    const form = dataItem ?? new UserAccessForm();
    const arr: {[key: string]: AbstractControl<unknown> | FormArray<FormGroup>} = {};
    Object.keys(form).forEach((key) => {
      const value = form[key as keyof typeof form];
      arr[key] = key === 'userAccessAccessRights'
        ? this.createFormArray(form[key])
        : new FormControl(value, key === 'roles' ? null : Validators.required);
    });
    return new FormGroup(arr);
  }

  private createFormArray(accessRights?: UserAccessRightsForm[]) {
    const formArr = new FormArray<FormGroup>([]);
    accessRights?.forEach((item) => {
      formArr.push(this.createAccessFormGroup(item));
    });
    return formArr;
  }

  private createAccessFormGroup(dataItem: UserAccessRightsForm) {
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(dataItem).forEach((key) => {
      arr[key] = new FormControl(dataItem[key as keyof typeof dataItem], Validators.required);
    });
    return new FormGroup(arr);
  }

  protected get userAccessAccessRights() {
    return <FormArray>this.formGroup.get('userAccessAccessRights');
  }

  protected addAccessRights(data: ApplicationAccessRights[]) {
    this.userAccessAccessRights.push(this.createAccessFormGroup(new UserAccessRightsForm()));
    data.push(new ApplicationAccessRights());
    const rowIndex = data.length - 1;
    this.applicationAccessGrid?.editRow(rowIndex, this.userAccessAccessRights?.get(`${rowIndex}`) as FormGroup);
  }

  protected deleteAccessRights(data: ApplicationAccessRights[], index: number) {
    this.userAccessAccessRights.removeAt(index);
    data.splice(index, 1);
    this.userAccessAccessRights.controls.forEach((form, index) => {
      this.applicationAccessGrid?.closeRow(index);
      this.applicationAccessGrid?.editRow(index, form as FormGroup);
      if (this.errors.has(index)) {
        this.checkRowErrors(index, this.errors.get(index)?.fieldName ?? '');
      }
    });
    this.applicationAccessGrid?.closeRow(this.userAccessAccessRights.length);
  }

  protected addRow({sender}: AddEvent) {
    this.lastRowIndex = 0;
    this.isNew = true;
    this.gridView.data.unshift(new UserAccessRightsList());
    this.editRow({
      sender,
      rowIndex: this.lastRowIndex,
      dataItem: this.gridView.data[this.lastRowIndex],
      isNew: this.isNew
    });
  }

  protected editRow({sender, rowIndex, dataItem, isNew}: EditEvent) {
    if (this.editMode) {
      return;
    }

    this.onCellClick(dataItem, false);
    this.editMode = true;
    this.lastRowIndex = rowIndex;
    this.formGroup = this.createFormGroup(this.mapDataItemToForm(dataItem));
    if (isNew) {
      sender.editRow(rowIndex, this.formGroup);
      this.cdr.detectChanges();
    }

    this.editAccess(dataItem);
    this.editMain();
    this.cdr.detectChanges();
  }

  private editAccess(dataItem: UserAccessRightsList) {
    dataItem.userAccessAccessRights.forEach((dataItem, index) => {
      this.applicationAccessGrid?.editRow(index, this.userAccessAccessRights?.get(`${index}`) as FormGroup);
    });
  }

  private editMain() {
    this.mainSettingsGrid?.editRow(0, this.formGroup);
  }

  private closeAccess() {
    this.gridView.data[(this.lastRowIndex ?? 0) - this.skip].userAccessAccessRights
      .forEach((_: ApplicationAccessRights, index: number) => {
        this.applicationAccessGrid?.closeRow(index);
      });
  }

  private closeMain() {
    this.mainSettingsGrid?.closeRow(0);
  }

  protected onCancel() {
    if (this.isNew) {
      this.grid?.closeRow(this.lastRowIndex);
      this.isNew = false;
    } else {
      this.closeAccess();
      this.closeMain();
    }
    this.gridView = process(JSON.parse(JSON.stringify(this.gridData)), this.state);
    this.editMode = false;
    this.formGroup = this.createFormGroup();
    this.lastRowIndex = undefined;
  }

  protected onRemove({dataItem}: RemoveEvent) {
    const item: UserAccessRightsList = dataItem;
    openRemoveDialog(this.dialogService, `${item.fio} из настроек доступа`).result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        lastValueFrom(this.userService.delete(item.personId)).then(() => this.getUserAccessData());
      }
    });
  }

  protected async onSave({dataItem}: SaveEvent) {
    await lastValueFrom(this.userAccessService.edit(this.mapFormData(dataItem))).then();
    this.getUserAccessData();
    this.onCancel();
  }

  private mapFormData(form: UserAccessForm): UpdateAccessRights {
    const mainAccess: AccessMainSettings = {
      isAccessSettingsAvailable: form.isAccessSettingsAvailable,
      isApplicationConstructorAvailable: form.isApplicationConstructorAvailable,
      isApplicationConstructorTemplateAvailable: form.isApplicationConstructorTemplateAvailable,
      isDictAvailable: form.isDictAvailable,
      isDictCreateAvailable: form.isDictCreateAvailable,
      isReportAvailable: form.isReportAvailable,
      isSignStampSettingsAvailable: form.isSignStampSettingsAvailable,
    };

    const applicationAccess: UpdateApplicationAccess[] = form.userAccessAccessRights.map((access) => {
      const faculties = access.isAllFacultiesAvailable ? [] :
        access.faculties.filter((item) => item.filialId).map((item) => item.id);
      const types = access.isAllApplicationTypesAvailable ? [] :
        (access.applicationTypes as (ApplicationType & ApplicationCategory)[])
          .filter((item) => item?.dictApplicationCategoryId)
          .map((item) => item.id);
      return {
        ...access,
        trainingLevels: access.isAllTrainingLevelsAvailable ? [] : access.trainingLevels,
        studyForms: access.isAllStudyFormsAvailable ? [] : access.studyForms,
        faculties: faculties,
        applicationTypes: types,
      };
    });

    return {personId: <string>form.personId, roles: form.roles, userAccessMainSettingsDto: mainAccess, userAccessAccessRights: applicationAccess};
  }

  protected expandDetailsBy(dataItem: UserAccessRight) {
    return dataItem.personId;
  };

  protected onCellClick(dataItem: UserAccessRightsList, collapse: boolean = true) {
    if (this.editMode) return;

    if (!this.checkIfIdExist(dataItem.personId)) {
      this.expandedDetailKeys = [dataItem.personId];
    } else if (collapse) {
      this.expandedDetailKeys = [];
    }
    this.cdr.detectChanges();
  }

  private checkIfIdExist(id: string) {
    return this.expandedDetailKeys.some((i) => i === id);
  }

  protected pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;

    this.state = {
      ...this.state,
      skip: event.skip,
      take: event.take,
    };

    this.gridView = process(this.gridData, this.state);
  }

  protected sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.state = { ...this.state, sort: [{dir: sort[0].dir, field: 'fio'}]};
    this.gridView = process(this.gridData, this.state);
  }

  protected personChange(id: string) {
    this.personExist = this.userAccesses.some((item) => item.personId === id);
    if (this.personExist) {
      this.notificationsService.showError('Данному сотруднику уже выданы права доступа');
    }
  }

  protected searchByFIO() {
    this.gridData = this.userAccesses.filter((a) => a.fio?.includes(this.searchValue));
    this.gridView = process(JSON.parse(JSON.stringify(this.gridData)), this.state);
  }

  protected clearSearch() {
    this.searchValue = '';
    this.searchByFIO();
  }

  private checkErrors(rowIndex: number, fieldName: string) {
    this.checkRowErrors(rowIndex, fieldName);
    this.userAccessAccessRights.controls.forEach((_, index) => {
      const isIntersectionCancelled = !this.errors.get(rowIndex)?.hasIntersection
        && this.errors.get(index)?.intersectionRowIndex === rowIndex;
      const IsDuplicationCancelled = !this.errors.get(rowIndex)?.isDuplicate
        && this.errors.get(index)?.duplicateRowIndex === rowIndex;

      if (isIntersectionCancelled || IsDuplicationCancelled) {
        this.checkRowErrors(index, fieldName);
      }
    });
  }

  private checkRowErrors(rowIndex: number, fieldName: string) {
    let error: ErrorInfo = {fieldName, isDuplicate: false, hasIntersection: false};

    const access = this.userAccessAccessRights?.get(`${rowIndex}`) as AbstractControl;
    this.userAccessAccessRights.controls.forEach((form, index) => {
      if (error.isDuplicate || error.hasIntersection || index === rowIndex) return;

      if (form.get('userAccessRight')?.value === access?.get('userAccessRight')?.value) {
        error.isDuplicate = !this.isUnique(form, access);
        if (error.isDuplicate) error.duplicateRowIndex = index;
      } else {
        error.hasIntersection = this.IsIntersection(form, access);
        if (error.hasIntersection) error.intersectionRowIndex = index;
      }
    });

    const hasError = this.errors.has(rowIndex);
    if (error.isDuplicate || error.hasIntersection) {
      this.errors.set(rowIndex, error);
    } else if (hasError) {
      this.errors.delete(rowIndex);
    } else {
      this.errors.forEach((value) => {
        if (value.duplicateRowIndex === rowIndex || value.intersectionRowIndex === rowIndex) {
          this.errors.delete(rowIndex);
        }
      });
    }
  }

  private isUnique(row: AbstractControl, value: AbstractControl) {
    let isUnique = false;
    Object.keys(new UserAccessRightsForm()).forEach((key) => {
      if (isUnique) return;
      isUnique = Array.isArray(row.get(key)?.value)
        ? !(row.get(key)?.value.every((item: Object|string) => value?.get(key)?.value.includes(item)))
        : row.get(key)?.value !== value?.get(key)?.value;
    });
    return isUnique;
  }

  private IsIntersection(row: AbstractControl, value: AbstractControl) {
    const inter: {[key: string]: boolean} = {
      trainingLevels: false,
      faculties: false,
      studyForms: false,
      applicationTypes: false
    };
    Object.keys(inter).forEach((key) => {
      const isAllKey = <string>filterKeyMap.get(key);
      if ((row.get(isAllKey)?.value && value.get(isAllKey)?.value)
        || (row.get(key)?.value.length && value?.get(isAllKey)?.value)
        || (row.get(isAllKey)?.value && value?.get(key)?.value.length)
        || row.get(key)?.value.some((item: Object|string) => value?.get(key)?.value.includes(item))) {
        inter[key] = true;
      }
    });
    return Object.values(inter).every((item) => item);
  }

  protected hasIntersection(rowIndex: number) {
    let result = false;
    this.errors.forEach((value) => {
      if (result) return;
      result = value.intersectionRowIndex === rowIndex;
    });
    return result;
  }

  protected accessValueChange(value: AccessRights, rowIndex: number, fieldName: string) {
    this.checkErrors(rowIndex, fieldName);
  }

  protected isIndet(dictLength: number, valueLength: number) {
    return valueLength > 0 && valueLength < dictLength;
  }

  protected onIsAllClick(component: Object, isAll: AbstractControl, value: AbstractControl, dictValues: Dict[], valuePrimitive: boolean = true) {
    isAll.patchValue(!isAll.value);
    const values = valuePrimitive ? dictValues.map((item) => item.id) : dictValues;
    value.patchValue(isAll.value ? values : []);

    const multiselect = <MultiSelectComponent|MultiSelectTreeComponent>component;
    multiselect.valueChange.emit();
    multiselect.toggle(false);
  }

  protected multiselectValueChange(value: AbstractControl, dictValues: Dict[], isAll: AbstractControl, rowIndex: number, fieldName: string) {
    isAll.patchValue(value.value.length === dictValues.length);
    this.checkErrors(rowIndex, fieldName);
  }

  protected facultiesTagMapper(tags: FacultyTreeItem[]) {
    const faculties = tags.filter((item) => item.filialId);
    return faculties.length ? [faculties] : faculties;
  }

  protected typesTagMapper(tags: (ApplicationType & ApplicationCategory)[]) {
    const types = tags.filter((item) => item.dictApplicationCategoryId);
    return types.length > 1 ? [types] : types;
  }
}

import {Component} from '@angular/core';
import {CellClickEvent, PagerSettings} from "@progress/kendo-angular-grid";
import {pagerSettings} from 'src/app/models/mfc/pagerSettings.model';
import {Router} from "@angular/router";
import {signTypes} from '../../../../models/mfc/stamp/sign-type.model';

@Component({
  selector: 'mfc-stamp-home',
  templateUrl: './stamp-home.component.html',
  styleUrls: ['./stamp-home.component.scss']
})
export class StampHomeComponent {

  protected readonly pagerSettings: PagerSettings = pagerSettings;
  protected pageSize = 10;

  protected readonly signTypes = signTypes;

  constructor(
    private router: Router,
  ) { }

  protected openEditForm({dataItem}: CellClickEvent) {
    this.router.navigate([`mfc/stamp/stampForm/${dataItem.enum}`]);
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {AuthorsService} from "../../../../../services/science/publications/authors.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ExternalAuthor} from "../../../../../models/publications/authors/authors.model";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../../../helpers/date-helper";
import {Location} from "@angular/common";

@Component({
  selector: 'publications-author-card',
  templateUrl: './publications-author-card.component.html',
  styleUrls: ['./publications-author-card.component.scss']
})
export class PublicationsAuthorCardComponent implements OnInit {

  public author!: ExternalAuthor;
  public isEditingMode: boolean = false;
  public editable: boolean = false;
  public mask = "000-000-000 00";
  public formGroup: FormGroup = this.getFormGroup();

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    private authorsService: AuthorsService
  ) { }

  ngOnInit() {
    this.editable = true;
    this.getAuthor();
  }

  private getAuthor() {
    this.authorsService.getExternalAuthor(this.activatedRoute.snapshot.params['id'])
      .subscribe((value) => {
        this.author = value;
        this.formGroup = this.getFormGroup();
      });
  }

  private getFormGroup() {
    return new FormGroup({
      id: new FormControl(this.author?.id),
      type: new FormControl({value: "Внешний автор", disabled: true}),
      firstName: new FormControl({value: this.author?.firstName, disabled: true}, Validators.required),
      lastName: new FormControl({value: this.author?.lastName, disabled: true}, Validators.required),
      middleName: new FormControl({value: this.author?.middleName, disabled: true}),
      birthday: new FormControl({value: DateFromUTCAsLocal(this.author?.birthday), disabled: true}),
      identificator: new FormControl({value: this.author?.identificator, disabled: true})
    });
  }

  public onEdit() {
    this.isEditingMode = true;
    for (let key in this.formGroup.controls) {
      if (key !== "id" && key !== "type") {
        this.formGroup.controls[key].enable();
      }
    }
  }

  public onCancel() {
    this.isEditingMode = false;
    this.formGroup = this.getFormGroup();
  }

  public onSave() {
    this.formGroup.value.birthday = TimeZoneFix(this.formGroup.value.birthday);

    this.authorsService.updateExternalAuthor(this.formGroup.value).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Успешно');
        this.isEditingMode = false;
      },
      error: (error) => {
        this.notificationsService.showError(error);
      }
    });
  }

  public goBack() {
    this.location.back();
  }
}

import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {AddEvent, EditEvent, GridComponent, RemoveEvent} from '@progress/kendo-angular-grid';
import {DisciplineWorkloadUserAccess} from 'src/app/models/useraccess/disciplineworkload/disciplineworkloaduseraccess.model';
import {DictStudyForm, DictTrainingLevel, Faculty, KafedraGet, Person} from '../../../models/disciplineworkload/externals.model';
import {DisciplineWorkloadUserAccessService} from '../../../services/useraccess/disciplineworkload-user-access.service';
import {PersonService} from "../../../services/disciplineworkload/externals/person.service";
import {DepartmentService} from '../../../services/disciplineworkload/externals/department.service';
import {DictService} from "../../../services/disciplineworkload/externals/dict.service";
import {arrayRewrite, DisplayValuesInEditMode, valueChange} from "../../../helpers/multiselect-helper";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {SortDescriptor} from "@progress/kendo-data-query";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {getData$} from "../../../../environments/environment";
import {isChildOf} from "../../../helpers/elementRef-helper";
import {GridDisplayList} from "../../../helpers/grid-helper";
import {DisplaySNameForDuplicateKafedras} from "../../../helpers/array-helper";
import {KafedraFields} from "../../../models/useraccess/enums/kafedra.enum";

@Component({
  selector: 'app-discipline-workload-access',
  templateUrl: './discipline-workload-access.component.html',
  styleUrls: ['./discipline-workload-access.component.scss']
})
export class DisciplineWorkloadAccessComponent implements OnInit {
  public isAdmin: boolean;
  public virtual: any = {
      itemHeight: 28,
  };
  public trainingLevelsEdit: any[] = [];
  public studyFormsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public kafedrasEdit: any[] = [];

  public userAccess: any['userAccesses'] = [];
  public persons: Person[] = [];
  public trainingLevels: DictTrainingLevel[] = [];
  public studyForms: DictStudyForm[] = [];
  public faculties: Faculty[] = [];
  public kafedras: KafedraGet[] = [];

  public filteredData: any = {}

  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]

  public dropdownOptions: any[] = [
    {id: 0, text: 'Нет' },
    {id: 1, text: 'Чтение' },
    {id: 2, text: 'Запись' }
  ]

  public dropdownMonitoringOptions: any[] = [
    {id: 0, text: 'Нет' },
    {id: 1, text: 'Чтение' }
  ]

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;
  public sort: SortDescriptor[] = [{ field: "personFullName", dir: "asc" }];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: any[] = [];
  private dataSources: any[] = ['trainingLevels', 'studyForms', 'faculties', 'kafedras'];

  constructor(private dialogService: DialogService,
              private dictService: DictService,
              private departmentService: DepartmentService,
              private personService: PersonService,
              private notificationService: NotificationsService,
              private userAccessService: DisciplineWorkloadUserAccessService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  ngOnInit(): void {
    this.getPersons();
    this.getTrainingLevels();
    this.getStudyForms();
    this.getFaculties();
    this.getKafedras();
    getData$.subscribe(() => { this.getAccess();})
  }

  // Get access
  public getAccess() {
    this.userAccessService.getAccess()
      .subscribe(
        response => {
          this.userAccess = response;
          this.userAccess['userAccesses'].forEach((item: any) => {
            DisplaySNameForDuplicateKafedras(item.kafedras, KafedraFields.name, KafedraFields.facultySName)
          })
          this.gridData = this.userAccess['userAccesses'];
        }
      );
  }

  public getTrainingLevels() {
    this.dictService.getTrainingLevels().subscribe(response => {
      this.trainingLevels = response;
      if (this.trainingLevels.length > 0) {
        this.trainingLevels.unshift({
          id: '',
          name: 'Все',
          shortName: 'Все',
          profileName: 'Все'});
      }
    });
  }

  public getStudyForms() {
    this.dictService.getStudyForms().subscribe(response => {
      this.studyForms = response;
      if (this.studyForms.length > 0) {
        this.studyForms.unshift({
          id: '',
          studyFormName: 'Все',
          studyFormSName: 'Все',
          studyFormTypeId: ''
        });
      }
    });
  }

  public getFaculties() {
    this.departmentService.getFaculties().subscribe(response => {
      this.faculties = response;
      if (this.faculties.length > 0) {
        this.faculties.unshift({
          id: '',
          name: 'Все',
          sName: 'Все'
        });
      }
    });
  }

  public getKafedras() {
    this.departmentService.getKafedras().subscribe(response => {
      this.kafedras = DisplaySNameForDuplicateKafedras(response, KafedraFields.name, KafedraFields.facultySName);
      if (this.kafedras.length > 0) {
        this.kafedras.unshift({
          id: '',
          name: 'Все',
          sName: 'Все',
          facultyId: ''
        });
      }
    });
  }

  // Get persons
  public getPersons() {
    this.personService.getAllPersons().subscribe(response => this.persons = response);
  }

  public displayList(dataItem: any, key: any) {
    return GridDisplayList(dataItem, key);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.trainingLevelsEdit = [];
    this.facultiesEdit = [];
    this.kafedrasEdit = [];
    this.studyFormsEdit = [];

    this.formGroup = formGroup({
      id: 0,
      personId: '',
      trainingLevels: [],
      faculties: [],
      kafedras: [],
      studyForms: [],
      contingentLoadAccess: 0,
      errandsAccess: 0,
      subdepartmentLoadAccess: 0,
      teacherLoadAccess: 0,
      assignmentMonitoringAccess: 0,
      reportsAccess: 0,
      isModerator: false
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Start Editing
  public editHandler({sender, rowIndex, dataItem}: EditEvent): void {

    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      //this.saveCurrent();
      return;
    }

    // Вывод данных в редактирование
    for(let i = 0; i < this.dataSources.length; i++) {
      this.filteredData[this.dataSources[i]] = (this as any)[this.dataSources[i]];
      // Исправляет ошибку с ExpressionChanged из-за пустых массивов в formGroup
      if(dataItem[this.dataSources[i]]?.length == 0 ||
        dataItem[this.dataSources[i]]?.find((_: any) => _.id == null) != null) {
        switch(this.dataSources[i]) {
          case 'faculties':
            dataItem[this.dataSources[i]] = [{id: '', sName: 'Все'}];
            break;
          case 'studyForms':
            dataItem[this.dataSources[i]] = [{id: '', studyFormName: 'Все'}];
            break;
          default: {
            dataItem[this.dataSources[i]] = [{id: '', name: 'Все'}];
          }
        }
      }
      (this as any)[`${this.dataSources[i]}Edit`] = DisplayValuesInEditMode((dataItem[this.dataSources[i]]), 'id');
    }

    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {

      for(let i = 0; i < this.dataSources.length; i++) {
        valueChange(this.formGroup.value[`${this.dataSources[i]}`], "", null);
      }

      this.formGroup.value.id === 0
          ? this.userAccessService.saveAccess(this.formGroup.value)
          : this.userAccessService.updateAccess(this.formGroup.value)
      this.closeEditor(this.grid);
    }
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public clearData() {
    this.gridData = this.userAccess['userAccesses'];
    this.searchFIO = ''
  }

  public filterData(dataItem: any) {
    this.gridData = this.userAccess['userAccesses']
      .filter((s: DisciplineWorkloadUserAccess) => s.personFullName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.personFullName} из настроек доступа`);
    this.opened = dialogResultHelper(dialog.result, this.userAccessService.deleteAccess(dataItem.id), this.notificationService);
  }

  public valueChange(value: any, editItem: any) {
    (this as any)[`${editItem}`] = arrayRewrite(value);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    let element = (event as any).target;
    this.dataSources.forEach((item) => {
      if(isChildOf(element, item)) {
        this.filteredData[item] = (this as any)[item];
      }
    })
  }

  public onTagClick(items: any, sourceName: string) {
    this.filteredData[sourceName] = items;
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }
}

const formGroup = (dataItem:
  {
    id?: any;
    personId?: any;
    trainingLevels?: any[];
    faculties?: any[];
    kafedras?: any[];
    studyForms?: any[];
    contingentLoadAccess?: any;
    errandsAccess?: any;
    subdepartmentLoadAccess?: any;
    teacherLoadAccess?: any;
    assignmentMonitoringAccess?: any;
    reportsAccess?: any;
    isModerator?: boolean;
  }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    trainingLevels: new FormControl(dataItem.trainingLevels, Validators.required),
    faculties: new FormControl(dataItem.faculties, Validators.required),
    kafedras: new FormControl(dataItem.kafedras, Validators.required),
    studyForms: new FormControl(dataItem.studyForms, Validators.required),
    contingentLoadAccess: new FormControl(dataItem.contingentLoadAccess, Validators.required),
    errandsAccess: new FormControl(dataItem.errandsAccess, Validators.required),
    subdepartmentLoadAccess: new FormControl(dataItem.subdepartmentLoadAccess, Validators.required),
    teacherLoadAccess: new FormControl(dataItem.teacherLoadAccess, Validators.required),
    assignmentMonitoringAccess: new FormControl(dataItem.assignmentMonitoringAccess, Validators.required),
    reportsAccess: new FormControl(dataItem.reportsAccess, Validators.required),
    isModerator: new FormControl(dataItem.isModerator, Validators.required),
  });

<mfc-settings></mfc-settings>
<h1>Заявка {{!isAdd && application?.applicationNumber ? '№' + application?.applicationNumber : ''}}</h1>

<div class="buttons">
  <button kendoButton (click)="goBack()">
    {{isAdd ? 'Отмена' : 'Закрыть'}}
  </button>
  <button *ngIf="isAdd"
          kendoButton
          themeColor="primary"
          [disabled]="!applicationConstructor"
          (click)="onSave()">
    Сохранить и отправить
  </button>
  <ng-container *ngIf="!isAdd">
    <button *ngIf="editable" (click)="onSave()" themeColor="primary" kendoButton>Сохранить</button>
    <ng-container *ngFor="let status of allowedActions">
      <button kendoButton (click)="changeStatus(status.enum)"
              *ngIf="!status.checkConditions || editable || displayInProgressButton"
              [themeColor]="status.color"
              [class]="{
                    deny: status.color === 'none'
                }">
        {{status.description}}
      </button>
    </ng-container>
  </ng-container>
</div>
<div [formGroup]="formGroup" class="form">
  <span *ngIf="!isAdd && application" class="field status">
    <h4>Статус</h4>
    <h4>
      <span class="status-name">{{statusText}}</span>
      <span *ngIf="application?.applicationStatusInfo">
        (<span *ngIf="displayFIOStatuses.includes(application.statusEnum)">{{
          application?.applicationStatusInfo?.personFIO ?? application?.applicationStatusInfo?.studentFIO
        }} </span>{{application?.applicationStatusInfo?.statusUpdatedAt | date:'dd.MM.yyyy HH:mm'}})
      </span>
    </h4>
  </span>
  <span class="field field_fio" [ngSwitch]="isAdd">
    <h4>ФИО обучающегося</h4>
    <kendo-combobox *ngSwitchCase="true"
                    [data]="(dictPerson | async) ?? []"
                    [valuePrimitive]="true"
                    [kendoDropDownFilter]="filterSettings"
                    [virtual]="true"
                    (valueChange)="studentChange($event)"
                    #fio
                    [openOnFocus]="fio"
                    valueField="id"
                    textField="fio"
                    formControlName="student">
    </kendo-combobox>
    <ng-container *ngSwitchCase="false">
      <span class="value">{{application?.student?.fio}}</span>
      <h4 (click)="hyperlinkNavigate()" class="hyperlink">
        Перейти к карточке обучающегося
      </h4>
    </ng-container>
  </span>
  <span class="field" [ngSwitch]="isAdd">
    <h4>Категория</h4>
    <kendo-combobox *ngSwitchCase="true"
                    [data]="applicationCategoriesTree"
                    [valuePrimitive]="true"
                    [kendoDropDownFilter]="filterSettings"
                    (valueChange)="categoryChange($event)"
                    #category
                    [openOnFocus]="category"
                    formControlName="category"
                    valueField="externalId"
                    textField="name">
    </kendo-combobox>
    <span class="value" *ngSwitchCase="false">{{application?.applicationCategoryName}}</span>
  </span>
  <span class="field" [ngSwitch]="isAdd">
    <h4>Тип</h4>
    <ng-container *ngSwitchCase="true">
      <kendo-combobox [data]="applicationCategory?.dictApplicationTypes ?? []"
                      [valuePrimitive]="true"
                      [kendoDropDownFilter]="filterSettings"
                      (valueChange)="typeChange($event)"
                      #type
                      [openOnFocus]="type"
                      formControlName="type"
                      valueField="externalId"
                      textField="name">
      </kendo-combobox>
      <kendo-icon *ngIf="formGroup.get('type')?.value && typeTooltip"
                  kendoTooltip
                  [tooltipWidth]="450"
                  [title]="typeTooltip"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </ng-container>
    <span *ngSwitchCase="false" class="value">{{application?.applicationTypeName}}</span>
  </span>
  <ng-container *ngFor="let file of (applicationConstructor?.documents ?? application?.documents); let i = index">
    <span class="field">
      <h4 [ngSwitch]="file.documentType">
        <span *ngSwitchCase="DocumentTypes.Document">Пример документа</span>
        <span *ngSwitchCase="DocumentTypes.Statement">Шаблон заявления</span>
        "{{file.name}}"
      </h4>
      <button kendoButton icon="download" (click)="downloadFile(file)">Скачать шаблон</button>
    </span>
  </ng-container>

  <ng-container *ngIf="typeReceiptName && filteredTypeReceipt[0]?.typeReceipt !== ReceiptTypesEnum.NotProvided">
    <span class="field with-description">
      <h4>Вариант получения</h4>
      <ng-container *ngIf="filteredTypeReceipt.length > 1; else text">
        <span class="input">
          <kendo-dropdownlist [data]="filteredTypeReceipt"
                              [valuePrimitive]="true"
                              formControlName="dictTypeReceiptId"
                              textField="name"
                              valueField="id">
          </kendo-dropdownlist>
          <h6 class="required">*</h6>
        </span>
        <h6 class="description">{{typeReceiptDescription}}</h6>
      </ng-container>
      <ng-template #text>
        <span class="value">{{typeReceiptName}}</span>
      </ng-template>
    </span>
  </ng-container>

  <ng-container *ngIf="application?.registrationNumber?.registrationNumberLitera">
    <span class="field field_reg-number field_sent">
      <h4>Рег. номер</h4>
      <span class="input">
        <kendo-numerictextbox [disabled]="!editable"
                              [changeValueOnScroll]="false"
                              format="#"
                              [decimals]="0"
                              [min]="1"
                              formControlName="registrationNumber">
        </kendo-numerictextbox>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </span>
    <span class="field field_sent">
      <h4>Дата выдачи</h4>
      <span class="input">
        <kendo-datepicker [disabled]="!editable"
                          formControlName="issueDate">
        </kendo-datepicker>
        <h6 class="required" *ngIf="editable">*</h6>
      </span>
    </span>
  </ng-container>
</div>

<kendo-tabstrip (tabSelect)="onTabChange($event)">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <kendo-tabstrip-tab *ngIf="!isAdd || tab.displayInAddForm" [selected]="i === currentTab" [title]="tab.name">
        <ng-template kendoTabTitle>
          {{tab.name}}
          <kendo-badge *ngIf="tab.selector === ApplicationTabsEnum.Comments && application?.notificationCount" class="comments-badge">
            {{application?.notificationCount}}
          </kendo-badge>
        </ng-template>
        <ng-template kendoTabContent>
          <mfc-application-form-tab
            [status]="application?.statusEnum"
            [editable]="editable"
            [index]="i"
            [tabSelector]="tab.selector"
            [tabId]="tab.id"
            [settings]="applicationConstructor"
            [documents]="documents"
            [application]="application">
          </mfc-application-form-tab>
        </ng-template>
    </kendo-tabstrip-tab>
  </ng-container>
</kendo-tabstrip>

<div kendoDialogContainer></div>

<div class="flex valign">
    <p class="parameters w-200">Уровень подготовки</p>
    <kendo-multiselect
          class="w-300"
          kendoMultiSelectSummaryTag
          [autoClose]="false"
          [data]="trainingLevel"
          textField="name"
          valueField="externalId"
          [valuePrimitive]="true"
          (valueChange)="onChange($event)"
          (blur)="blur()"
          [(ngModel)]="trainingLevelList"
    >
      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
        <span>
          {{ getValue(dataItems) }} 
        </span>
      </ng-template>
    </kendo-multiselect>
</div>
<p class="parameters normal m-l200">Бланков найдено: {{ findBlanks }}</p>

<div class="m-t20" [ngClass]="{buttons: editable}" *ngIf="!deleting">
    <div class="buttons" *ngIf="editable">
        <button kendoButton themeColor="success" (click)="addBlanks()">Добавить бланки</button>
        <button kendoButton themeColor="primary" class="m-l15" (click)="issueBlanks()">Выдать бланки</button>
        <button kendoButton class="m-l15" (click)="deleteBlanks()">Удалить бланки</button>
    </div>
    <div [ngClass]="{'buttons, rightAlign': !editable}">
        <button kendoButton icon="filter-clear" (click)="clearFilters()">Сбросить фильтр</button>
        <button kendoButton class="m-l15" icon="download" (click)="exportList()">Экспорт списка</button>
    </div>
</div>
<div class="m-t20" *ngIf="deleting">
      <button kendoButton (click)="cancelDeleting()">Отмена</button>
      <button kendoButton themeColor="primary"
              class="m-l15"
              [disabled]="checkedBlanks.length == 0"
              (click)="removeBlanks()">
        Удалить
      </button>
</div>

<kendo-grid
    class="m-t20"
    [kendoGridBinding]="availableBlanks"
    [loading]="loading"
    [sortable]="true"
    [pageSize]="pageSize"
    [pageable]="pagerSettings"
    [filterable]="true"
    [filter]="filter"
    (filterChange)="filterChange($event)"
    (pageChange)="pageChange($event)"
    >
    <ng-template kendoGridLoadingTemplate>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>

    <kendo-grid-column [width]="80" *ngIf="!deleting"
                       title="№"
                       [filterable]="false"
                       [sortable]="false"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <span class="alignCells">
            {{ rowIndex + 1}}
          </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="deleting"
          [width]="80"
          [resizable]="false"
          headerClass="gridHeader dictionaries">
        <ng-template kendoGridHeaderTemplate>
        <div headerClass="gridHeader">
                <input
                type="checkbox"
                kendoCheckBox
                [checked]="allItemsChecked"
                (click)="selectAll(allItemsChecked)"
                />
        </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                [checked]="checked(dataItem.id)"
                (change)="checkedChange(dataItem.id)"
                />
            </span>
        </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column field="blankTypeName"
                       title="Тип бланка"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="blanksType" textField="blankTypeName" valueField="blankTypeName">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                {{ dataItem.blankTypeName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="series"
                       [width]="150"
                       title="Серия"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="blankSeries" textField="series" valueField="series">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.series }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="number"
                       [width]="180"
                       title="Номер"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.number }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="blankTemplateName"
                       [width]="220"
                       title="Шаблон"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="blankTemplates" textField="blankTemplateName" valueField="blankTemplateName">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.blankTemplateName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="addDate"
                       [width]="200"
                       title="Дата добавления"
                       filter="date" format="dd.MM.yyyy"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.addDate | date:'dd.MM.yyyy' }}
            </span>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>

<kendo-dialog *ngIf="opened"
              title="Пожалуйста, подтвердите"
              (close)="close('cancel')"
              [minWidth]="250"
              [width]="450">
  <p class="m-l15">Удалить выбранные бланки?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('no')">Нет</button>
    <button kendoButton (click)="close('yes')" themeColor="primary">Да</button>
  </kendo-dialog-actions>
</kendo-dialog>

<div kendoDialogContainer></div>
import {Component, OnInit} from '@angular/core';
import {StampService} from "../../../../services/mfc/stamp.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup} from '@angular/forms';
import {FileRestrictions} from "@progress/kendo-angular-upload";
import {Stamp, StampForm} from '../../../../models/mfc/stamp/stamp.model';
import {DownloadFile} from '../../../../helpers/downloadFile-helper';
import {saveAs} from '@progress/kendo-file-saver';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {signTypes} from '../../../../models/mfc/stamp/sign-type.model';
import {SignTypes} from '../../../../models/mfc/enums/sign-types.enum';

@Component({
  selector: 'mfc-stamp-form',
  templateUrl: './stamp-form.component.html',
  styleUrls: ['./stamp-form.component.scss']
})
export class StampFormComponent implements OnInit {

  protected readonly tooltip = `Для изменения шаблона штампа ЭП нужно скачать файл шаблона,
    внести необходимые изменения и загрузить файл обратно.`;
  private type = Number(this.activatedRoute.snapshot.params['type']);
  protected allowedExtensions: string = '.doc, .docx';

  protected fileRestrictions: FileRestrictions = {
    allowedExtensions: this.allowedExtensions.split(', ')
  };

  protected stamp?: Stamp;
  protected formGroup = this.createFormGroup();

  protected readonly signTypes = signTypes;

  constructor(
    private stampService: StampService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.getStamps();
  }

  private getStamps() {
    this.stampService.getStamps().subscribe((response) => {
      this.stamp = response.find((item) => item.type === this.type);
      this.getStampForm();
    });
  }

  private getStampForm() {
    this.formGroup = this.createFormGroup(this.stamp);
  }

  private createFormGroup(stamp?: Stamp) {
    return new FormGroup({
      signStampType: new FormControl(this.type),
      file: new FormControl(stamp?.fileName ? [new File([''], stamp.fileName)] : null)
    });
  }

  protected onTypeChange(type: SignTypes) {
    this.type = type;
    this.getStamps();
  }

  protected downloadFile(files: File[]) {
    if (files[0].size) {
      DownloadFile(files[0]);
    } else {
      this.stampService.getStamp(this.type).subscribe({
        next: (response: Blob) => saveAs(response, files[0].name),
        error: (error) => this.notificationsService.showError(error),
      });
    }
  }

  protected createStamp(files?: File[]) {
    if (!files) return;
    const form: StampForm = {signStampType: this.type, file: files[0]};
    this.stampService.createStamp(form).subscribe({
      next: () => {
        this.getStamps();
        this.notificationsService.showSuccess('Успешно');
      },
      error: (error) => {
        this.getStampForm();
        this.notificationsService.showError(error.error.Message);
      },
    });
  }

  protected removeStamp() {
    this.stampService.deleteStamp(this.type).subscribe({
      next: () => {
        this.getStamps();
        this.notificationsService.showSuccess('Успешно');
      },
      error: (error) => {
        this.getStampForm();
        this.notificationsService.showError(error.error.Message);
      },
    });
  }

  protected onCancel() {
    this.router.navigate(['mfc/stamp']);
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, Observable, throwError} from 'rxjs';
import { DictWorkScheduleStudyProcessType } from 'src/app/models/education/dictWorkScheduleStudyProcessType.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DictWorkScheduleStudyProcessTypeService {

  public allWorkScheduleStudyProcessTypes$ = new BehaviorSubject<DictWorkScheduleStudyProcessType[]>([]);

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictWorkScheduleStudyProcessType}`;

  constructor(private http: HttpClient) {
    this.updateAllWorkScheduleStudyProcessTypes();
  }

  //Get all WorkScheduleStudyProcessTypes
  public getAllWorkScheduleStudyProcessTypes(): Observable<DictWorkScheduleStudyProcessType[]> {
    return this.http.get<DictWorkScheduleStudyProcessType[]>(this.baseUrl + '/GetWorkScheduleStudyProcessTypes/');
  }

  public updateAllWorkScheduleStudyProcessTypes() {
    this.getAllWorkScheduleStudyProcessTypes().subscribe((response) => {
      this.allWorkScheduleStudyProcessTypes$.next(response);
    })
  }

  // Add WorkScheduleStudyProcessType
  addWorkScheduleStudyProcessType(dictWorkScheduleStudyProcessType: DictWorkScheduleStudyProcessType): Observable<DictWorkScheduleStudyProcessType> {
    return this.http.post<DictWorkScheduleStudyProcessType>(this.baseUrl + '/CreateWorkScheduleStudyProcessType/' , dictWorkScheduleStudyProcessType)
      .pipe(catchError(this.handleError));
  }

  // Edit WorkScheduleStudyProcessType
  updateWorkScheduleStudyProcessType(dictWorkScheduleStudyProcessType: DictWorkScheduleStudyProcessType): Observable<DictWorkScheduleStudyProcessType> {
    return this.http.put<DictWorkScheduleStudyProcessType>(this.baseUrl + '/UpdateWorkScheduleStudyProcessType/' + dictWorkScheduleStudyProcessType.dictWorkScheduleStudyProcessTypeId, dictWorkScheduleStudyProcessType)
      .pipe(catchError(this.handleError));
  }

  // Remove WorkScheduleStudyProcessType
  deleteWorkScheduleStudyProcessType(id: string): Observable<DictWorkScheduleStudyProcessType> {
    return this.http.delete<DictWorkScheduleStudyProcessType>(this.baseUrl + '/DeleteWorkScheduleStudyProcessType/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

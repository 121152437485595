import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from "rxjs/operators";
import { StandardType } from 'src/app/models/education/standardtype.model';

@Injectable({
  providedIn: 'root'
})
export class StandardtypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.standardtype}`;

  constructor(private http: HttpClient) { }

  //Get all standards types
  public getAllStandardTypes(): Observable<StandardType[]> {
    return this.http.get<StandardType[]>(this.baseUrl + '/GetAllStandardTypes');
  }

  //Add Standard type
  addStandardType(standardtype: StandardType): Observable<StandardType> {
    return this.http.post<StandardType>(this.baseUrl + '/CreateStandardType/', standardtype)
      .pipe(catchError(this.handleError));
  }

  //Delete Standard type
  deleteStandardType(id: string): Observable<StandardType> {
    return this.http.delete<StandardType>(this.baseUrl + '/DeleteStandardType/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit Standard type
  updateStandardType(standardtype: StandardType): Observable<StandardType> {
    return this.http.put<StandardType>(this.baseUrl + '/UpdateStandardType/' + standardtype.standardTypeId, standardtype)
      .pipe(catchError(this.handleError));
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common'
import { PersonService } from 'src/app/services/person/lkperson.service';
import { LKPerson } from 'src/app/models/person/lkPerson.model';
import { ComboBoxComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LKPersonSalaryService } from 'src/app/services/person/lkperson-salary.service';
import { LKPersonSalary } from 'src/app/models/person/lkPersonSalary.model';
import { SwitchUserService } from 'src/app/services/switchuser/switch-user.service';
import { tokenStore } from 'src/options/settings';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token.service';

@Component({
  selector: 'switch-user',
  templateUrl: './switchuser.component.html',
  styleUrls: ['./switchuser.component.scss']
})
export class SwitchUserComponent implements OnInit {

  public currentToken = "";
  public persons: LKPerson[] = [];
  public salaries: LKPersonSalary[] = [];
  public formGroup: FormGroup = new FormGroup({
    personId: new FormControl('', Validators.required),
    personName: new FormControl(''),
  });

  public virtual: any = {
    itemHeight: 30,
  };

  constructor(
    private location: Location,
    private personService: PersonService,
    private personSalaryService: LKPersonSalaryService,
    private switchUserService: SwitchUserService,
    private tokenService: TokenStorageService,
    private router: Router) { }

  ngOnInit(): void {
    this.getPersons();
    this.currentToken = this.tokenService.getToken();
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  // Get persons
  public getPersons() {
    this.personService.getPersons()
      .subscribe(
        response => {
          this.persons = response;
          this.persons = this.persons.sort((a, b) => a.fullName.localeCompare(b.fullName));
          this.getSalaries();
        }
      );
  }

  public getSalaries() {
    this.personSalaryService.getAllSalaries()
      .subscribe(
        response => {
          this.salaries = response;
        }
      );
  }

  public switchPerson() {
    this.switchUserService.switchPerson(this.formGroup.value.personId)
      .subscribe(
        response => {
          localStorage.setItem('currentToken', JSON.stringify(this.currentToken));
          localStorage.setItem('switchPerson', 'true');
          localStorage.removeItem(tokenStore);
          localStorage.setItem(tokenStore, JSON.stringify(response));
          this.router.navigate(['/profile/'])
          .then(() => {
            window.location.reload();
          });
        }
      );
  }

  findPost(personId: string) {
    let posts = this.salaries.filter((x) => x.personId === personId && x.isMainJob === true);
    let result = "";
    for(let i = 0; i < posts.length; i++)
    {
      result += posts[i].post + ", ";
    }
    result = result.slice(0, -2)
    return result;
  }

  onCancel(): void {
    this.location.back()
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {StudentInfoReestr} from "../../../models/cards/studentinfocards";
import {dictPagerSettings} from "../../../models/dicts/pagerSettings.model";
import {PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {ActivatedRoute, Router} from "@angular/router";
import {CardsService} from "../../../services/cards/cards.service";
import {Guid} from "guid-typescript";
import {CardReestr} from "../../../models/cards/cardreeestr";
import {ReestrService} from "../../../services/cards/reestr.service";
import {SalaryProjectService} from "../../../services/cards/salary-project.service";
import {SalaryProjectName} from "../../../models/cards/salaryproject";
import {saveAs} from "@progress/kendo-file-saver";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DateFromUTCAsLocal} from "../../../helpers/date-helper";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openRemoveDialog} from "../../../helpers/dialogHelper";
import {StudentInfoCardsEnum} from "../studentinfocards/studentinfocardsenum";
import {HttpResponse} from "@angular/common/http";
import {DownloadFile} from "../../../helpers/downloadFile-helper";

@Component({
  selector: 'app-reestr',
  templateUrl: './reestr.component.html',
  styleUrls: ['./reestr.component.css']
})
export class ReestrComponent implements OnInit {

  public pageSize: number = 10;
  public pagerSettings: PagerSettings = dictPagerSettings;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  private reestrId!: Guid;
  public reestr: CardReestr = new CardReestr();
  public salaryProjects: SalaryProjectName[] = [];
  public gridData: StudentInfoReestr[] = [];

  public reestrForm!: FormGroup;

  public isContingentSection: boolean = false;
  @Input() reestrIdFromInput: Guid | null = null;
  @Output() changeSection = new EventEmitter<StudentInfoCardsEnum>();
  @Output() reestrIdFromOutput = new EventEmitter<Guid | null>();

  constructor(
    private router: Router,
    private cardsService: CardsService,
    private activatedroute: ActivatedRoute,
    private reestrService: ReestrService,
    private salaryProjectService: SalaryProjectService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
  ) {
    this.reestrId = this.activatedroute.snapshot.params['id'];
  }

  ngOnInit(): void {
    if (this.reestrIdFromInput) {
      this.isContingentSection = true;
      this.reestrId = this.reestrIdFromInput;
    }

    this.getStudentInfoReestr(this.reestrId);
    this.getReestrInfo(this.reestrId);
    this.getSalaryProjectsNames();
  }

  public getStudentInfoReestr(reestrId: Guid) {
    this.cardsService.getCardRequestsToReestr(reestrId)
      .subscribe(
        response => this.gridData = response
      )
  }

  public getReestrInfo(reestrId: Guid){
    this.reestrService.getReestrInfo(reestrId)
      .subscribe(
        response => {
          this.reestr = response;
          this.reestr.reestrDate = DateFromUTCAsLocal(this.reestr.reestrDate);

          this.reestrForm = reestrForm(this.reestr);
        }
      )
  }

  public getSalaryProjectsNames() {
    this.salaryProjectService.getSalaryProjectsNames()
      .subscribe(
        response => this.salaryProjects = response
      )
  }

  public updateReestr() {
    if (this.reestrForm.invalid) {
      this.notificationService.showError("Заполните номер реестра и зарплатный проект");
      return;
    }

    this.reestrService.updateReestr(this.reestrForm.value)
      .subscribe(response => {
        this.reestr = response
        this.notificationService.showSuccess("Сохранено");
      },
error => {
        this.notificationService.showError("Не удалось сохранить изменения в реестре");
    });
  }

  public deleteCardRequest({ dataItem }: RemoveEvent) : void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.studentFullName} из реестра`);

    dialog.result.subscribe((result ) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.cardsService.deleteCardRequest(dataItem.studPersonId, this.reestrId, this.reestr.salaryProjectId)
          .subscribe(response => {
              this.gridData = response
              this.notificationService.showSuccess("Запись удалена");
            },
            error => {
              this.notificationService.showError("Не удалось удалить студента из реестра");
            })
      }
    })
  }

  public formReestr() {
    if (this.reestrForm.invalid) {
      this.notificationService.showError("Заполните номер реестра и зарплатный проект");
      return;
    }

    this.reestrService.formCardReestr(this.reestrId)
      .subscribe(response => this.reestr = response);
  }

  public downloadUploadFileXlsx(){
    this.reestrService.downloadUploadFileXlsx(this.reestrId)
      .subscribe(response => {
        DownloadFile(response);
      })
  }

  public downloadUploadFileXml(){
    this.reestrService.downloadUploadFileXml(this.reestrId)
      .subscribe(response => {
        DownloadFile(response);
      })
  }

  public toUpdateRequest(studPersonId: Guid) {
    if (this.isContingentSection) {
      this.changeSection.emit(StudentInfoCardsEnum.AddToListForming);
      return;
    }
    this.router.navigate([`/cards/addtolistforming/${studPersonId}`], { queryParams: { reestrId: this.reestrId }})
  }

  onCancel(): void {
    if (this.isContingentSection) {
      this.changeSection.emit(StudentInfoCardsEnum.Cards);
      this.reestrIdFromOutput.emit(null); // обнуляем reestrId, чтобы при переходе на добавление записи он не сохранялся и не открылась страница на добавление
      return;
    }
    this.router.navigate([`cards`]);
  }

  protected readonly dictPagerSettings = dictPagerSettings;
}

const reestrForm = (reestr: CardReestr) =>
  new FormGroup ({
    cardReestrId: new FormControl(reestr.reestrId),
    reestrNumber: new FormControl(reestr.reestrNumber, Validators.required),
    reestrDate: new FormControl(reestr.reestrDate),
    salaryProjectId: new FormControl(reestr.salaryProjectId, Validators.required),
  })

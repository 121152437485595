import {Component, OnInit, Input, Output, EventEmitter, Renderer2} from '@angular/core';
import { FormationDiplomaService } from '../../../../services/gia/formationdiploma.service';
import {
  basicInformationModel,
  DiplomaStatusesModel,
  ReplacingReasonsModel,
  TypeDiplomaModel,
  GeneralInformationModel,
  basicStudentInformationModel,
} from '../../../../models/gia/formationdiploma.model';
import { DateFromUTCAsLocal, TimeZoneFix } from '../../../../helpers/date-helper';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { LoaderType } from '@progress/kendo-angular-indicators';
import { GiaSettingService } from '../../../../services/gia/giaSetting.service';
import { GiaBlankSettingForDiploma } from '../../../../models/gia/giasettings/gia-setting.model';
import {
  BlankSettingSectionEnum,
  BlankSettingValueEnum,
  BlankTypeEnum,
  BlankTypeEnumList,
} from '../../../../models/gia/enums/blankSettings.enum';
import { DiplomaBlanksService } from '../../../../services/gia/diplomaBlanks.service';
import { DiplomaBlankModel } from '../../../../models/gia/diplomaBlank.model';
import { GiaUserAccessService } from '../../../../services/useraccess/gia-user-access.service';
import {hasClass, isChildOf} from "../../../../helpers/elementRef-helper";
import {SeriesAndNumbersBlanksEnum} from "../../../../models/gia/enums/seriesAndNumbersBlanksEnumList";
import {error} from "@angular/compiler-cli/src/transformers/util";

@Component({
  selector: 'app-basicinformation',
  templateUrl: './basicinformation.component.html',
  styleUrls: ['./basicinformation.component.scss'],
})
export class BasicInformationComponent implements OnInit {
  @Input() studentId: string = '';
  @Input() admissionOrder: string = '';
  @Input() allowEdit: boolean = false;
  @Input() generalInformation: GeneralInformationModel = new GeneralInformationModel();
  @Input() originalGeneralInformation: GeneralInformationModel = new GeneralInformationModel();

  @Output() getGeneralInformation = new EventEmitter();
  @Output() saveGeneralInfo = new EventEmitter();
  @Output() basicLoading = new EventEmitter<boolean>();
  @Output() fillRequiredFields = new EventEmitter<boolean>();

  public basicInformation: basicInformationModel = new basicInformationModel();
  public basicStudentInfo: basicStudentInformationModel = new basicStudentInformationModel();
  public loaderVisible: boolean = false;
  public type: LoaderType = 'converging-spinner';

  public typesDiploma: TypeDiplomaModel[] = [];
  public statuses: DiplomaStatusesModel[] = [];
  public reasonsReplacement: ReplacingReasonsModel[] = [];

  public diplomaIssueDate: Date | null = null;
  public replacementDate: Date | null = null;
  public giaProtocolDate: Date | null = null;

  public giaBlankSettingModel: GiaBlankSettingForDiploma[] = [];
  public personId: string = '';

  public blanks: DiplomaBlankModel[] = [];
  public diplomaBlankSeries: DiplomaBlankModel[] = [];
  public diplomaBlankNumbers: DiplomaBlankModel[] = [];

  public duplicateDiplomaBlankSeries: DiplomaBlankModel[] = [];
  public duplicateDiplomaBlankNumbers: DiplomaBlankModel[] = [];

  public diplomaBlankAttachmentSeries: DiplomaBlankModel[] = [];
  public diplomaBlankAttachmentNumber: DiplomaBlankModel[] = [];

  public duplicateDiplomaBlankAttachmentSeries: DiplomaBlankModel[] = [];
  public duplicateDiplomaBlankAttachmentNumber: DiplomaBlankModel[] = [];

  Form: FormGroup = new FormGroup({
    diplomaTypeId: new FormControl('', [Validators.required]),
    diplomaRegNumber: new FormControl('', [Validators.required]),
    diplomaIssueDate: new FormControl('', [Validators.required]),
    //  diplomaStatusId: new FormControl('', [Validators.required]),
    diplomaSeries: new FormControl(''),
    diplomaNumber: new FormControl(''),
    //    diplomaAttachmentStatusId: new FormControl('', [Validators.required]),
    diplomaAttachmentSeries: new FormControl(''),
    diplomaAttachmentNumber: new FormControl(''),
    giaProtocol: new FormControl(''),
    giaProtocolDate: new FormControl(''),
    secChairman: new FormControl(''),
  });

  constructor(
    private formationDiplomaService: FormationDiplomaService,
    private notificationService: NotificationsService,
    private giaSettingService: GiaSettingService,
    private diplomaBlanksService: DiplomaBlanksService,
    private giaUserAccessService: GiaUserAccessService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.getDiplomaStatuses();
    this.getBasicInformations();
    this.getBasicStudentInfo();
    this.getDiplomaReplacingReasons();
    this.getTypesDiploma();
    this.getBlankSettings();
    this.getPersonId();

    this.formationDiplomaService.tabApplication.next({
      application: false,
    });

    this.renderer.listen("document", "click", ({ target }) => {
      if (!hasClass(target, "showDiplomaBlankAttachmentSeries") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDiplomaBlankAttachmentSeries = false;
      if (!hasClass(target, "showDiplomaBlankAttachmentNumber") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDiplomaBlankAttachmentNumber = false;
      if (!hasClass(target, "showDiplomaBlankSeries") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDiplomaBlankSeries = false;
      if (!hasClass(target, "showDiplomaBlankNumbers") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDiplomaBlankNumbers = false;
      if (!hasClass(target, "showDuplicateDiplomaBlankSeries") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDuplicateDiplomaBlankSeries = false;
      if (!hasClass(target, "showDuplicateDiplomaBlankNumber") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDuplicateDiplomaBlankNumber = false;
      if (!hasClass(target, "showDuplicateDiplomaBlankAttachmentSeries") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDuplicateDiplomaBlankAttachmentSeries = false;
      if (!hasClass(target, "showDuplicateDiplomaBlankAttachmentNumber") && !hasClass(target, "k-input-value-text") && !hasClass(target, "k-input-inner") && !hasClass(target, "k-i-arrow-s")) this.showDuplicateDiplomaBlankAttachmentNumber = false;
    });
  }

  public getBlankSettings() {
    this.giaSettingService.getBlankSettingForDiploma().subscribe((response) => {
      this.giaBlankSettingModel = response;
    });
  }

  public getBlankSettingValue(sectionEnum: number, blankTypeEnum: number): number {
    const setting = this.giaBlankSettingModel.find((x) => x.blankTypeEnum == blankTypeEnum && x.sectionEnum == sectionEnum);
    return setting ? setting.valueEnum : 0;
  }

  public getBlanks(studentId: string, dictDiplomaTypeId: string) {
    this.diplomaBlanksService.getBlanks(studentId, dictDiplomaTypeId).subscribe((response) => {
      const customBlankDiploma: DiplomaBlankModel = {
        isDiploma: true,
        recipientPersonId: this.personId,
        series: "",
        number:"",
        externalId:"",
      };
      const customBlankAttachment: DiplomaBlankModel = {
        isDiploma: false,
        recipientPersonId: this.personId,
        series: "",
        number: "",
        externalId:"",
      };
      response.push(customBlankDiploma);
      response.push(customBlankAttachment);
      response.sort((a, b) => {
        if (a.series === "" && b.series !== "") return -1;
        if (a.series !== "" && b.series === "") return 1;
        return 0;
      });

      this.blanks = response;

      let diplomaBlankRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnumList.diploma) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;
      let diplomaBlankAttachmentRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnumList.application) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;
      let duplicateDiplomaBlankRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnumList.diploma) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;
      let duplicateDiplomaBlankAttachmentRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnumList.application) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;

      this.diplomaBlankSeries = this.filterBlanks(true, diplomaBlankRecipientPersonId, response);
      this.diplomaBlankAttachmentSeries = this.filterBlanks(false, diplomaBlankAttachmentRecipientPersonId, response);
      this.duplicateDiplomaBlankSeries = this.filterBlanks(true, duplicateDiplomaBlankRecipientPersonId, response);
      this.duplicateDiplomaBlankAttachmentSeries = this.filterBlanks(false, duplicateDiplomaBlankAttachmentRecipientPersonId, response);

      this.diplomaBlankNumbers = this.filterBlanksBySeries(true, diplomaBlankRecipientPersonId, this.basicInformation.diplomaSeries, response);
      this.diplomaBlankAttachmentNumber = this.filterBlanksBySeries(false, diplomaBlankAttachmentRecipientPersonId, this.basicInformation.diplomaAttachmentSeries, response);
      this.duplicateDiplomaBlankNumbers = this.filterBlanksBySeries(true, duplicateDiplomaBlankRecipientPersonId, this.basicInformation.duplicateDiplomaSeries, response);
      this.duplicateDiplomaBlankAttachmentNumber = this.filterBlanksBySeries(false, duplicateDiplomaBlankAttachmentRecipientPersonId, this.basicInformation.duplicateDiplomaAttachmentSeries, response);
    });

  }

  private filterBlanks(isDiploma: boolean, recipientPersonId: string | undefined, response: DiplomaBlankModel[]): DiplomaBlankModel[] {
    return response.filter(
      (value, index, self) =>
        value.isDiploma === isDiploma &&
        index === self.findIndex(t => t.series === value.series && t.isDiploma === value.isDiploma && (recipientPersonId === undefined || t.recipientPersonId === recipientPersonId))
    );
  }

  private filterBlanksBySeries(isDiploma: boolean, recipientPersonId: string | undefined, series: string | null | undefined, response: DiplomaBlankModel[]): DiplomaBlankModel[] {
    return response.filter(
      (value, index, self) =>
        value.isDiploma === isDiploma &&
        index === self.findIndex(t => t.number === value.number && t.isDiploma === value.isDiploma && (recipientPersonId === undefined || t.recipientPersonId === recipientPersonId) && (series === undefined || series === "" || series === null || t.series === series))
    );
  }

  public clearDiplomaNumber(isDiploma: boolean, isOriginal: boolean) {
    let diplomaBlankRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnumList.diploma) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;
    let diplomaBlankAttachmentRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnumList.application) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;
    let duplicateDiplomaBlankRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnumList.diploma) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;
    let duplicateDiplomaBlankAttachmentRecipientPersonId = this.getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnumList.application) == BlankSettingValueEnum.my_blanks ? this.personId : undefined;

    if (isOriginal) {
      if (isDiploma) {
        this.diplomaBlankNumbers = this.filterBlanksBySeries(true, diplomaBlankRecipientPersonId, this.basicInformation.diplomaSeries, this.blanks);
        this.basicInformation.diplomaNumber = this.diplomaBlankNumbers[0].number;
        this.Form.get('diplomaNumber')!.setValue(this.diplomaBlankNumbers[0].number);
      } else {
        this.diplomaBlankAttachmentNumber = this.filterBlanksBySeries(false, diplomaBlankAttachmentRecipientPersonId, this.basicInformation.diplomaAttachmentSeries, this.blanks);
        this.basicInformation.diplomaAttachmentNumber = this.diplomaBlankAttachmentNumber[0].number;
        this.Form.get('diplomaAttachmentNumber')!.setValue(this.diplomaBlankAttachmentNumber[0].number);
      }
    } else {
      if (isDiploma) {
        this.duplicateDiplomaBlankNumbers = this.filterBlanksBySeries(true, duplicateDiplomaBlankRecipientPersonId, this.basicInformation.duplicateDiplomaSeries, this.blanks);
        this.basicInformation.duplicateDiplomaNumber = this.duplicateDiplomaBlankNumbers[0].number;
      } else {
        this.duplicateDiplomaBlankAttachmentNumber = this.filterBlanksBySeries(false, duplicateDiplomaBlankAttachmentRecipientPersonId, this.basicInformation.duplicateDiplomaAttachmentSeries, this.blanks);
        this.basicInformation.duplicateDiplomaAttachmentNumber = this.duplicateDiplomaBlankAttachmentNumber[0].number;
      }
    }
  }

  public getBasicStudentInfo() {
    this.formationDiplomaService.getBasicStudentInfo(this.studentId).subscribe((response) => {
      this.basicStudentInfo = response;
    });
  }

  public getBasicInformations() {
    this.basicLoading.emit(true);
    this.loaderVisible = true;

    this.formationDiplomaService.getBasicDiplomaInfo(this.studentId).subscribe((response) => {
      this.basicInformation = response;
      this.basicLoading.emit(false);
      this.loaderVisible = false;

      if (this.basicInformation.diplomaIssueDate) this.diplomaIssueDate = DateFromUTCAsLocal(this.basicInformation.diplomaIssueDate);
      if (this.basicInformation.replacementDate) this.replacementDate = DateFromUTCAsLocal(this.basicInformation.replacementDate);
      if (this.basicInformation.giaProtocolDate) this.giaProtocolDate = DateFromUTCAsLocal(this.basicInformation.giaProtocolDate);

      this.changeForm();
    });
  }

  public getDiplomaStatuses() {
    this.formationDiplomaService.getDiplomaStatuses().subscribe((response) => {
      this.statuses = response;
    });
  }

  public getDiplomaReplacingReasons() {
    this.formationDiplomaService.getDiplomaReplacingReasons().subscribe((response) => {
      this.reasonsReplacement = response;
    });
  }

  public getTypesDiploma() {
    this.formationDiplomaService.getTypesDiploma(this.studentId).subscribe((response) => {
      this.typesDiploma = response;
    });
  }

  public period(value: number) {
    return value ? (value > 4 ? `${value} лет` : `${value} года`) : '-';
  }

  public saveInformation() {
    this.saveGeneralInfo.emit();
  }

  public saveBasicInformation(diplomaInfoId: string) {
    if (!this.allowEdit) return;

    this.basicInformation.diplomaInfoId = diplomaInfoId;

    if (!this.Form.valid) {
      this.notificationService.showError('Не заполнены обязательные поля. Основные сведения нельзя сохранить.');
      return;
    }
    if (this.diplomaIssueDate) this.basicInformation.diplomaIssueDate = new Date(TimeZoneFix(this.diplomaIssueDate).toISOString());
    if (this.replacementDate) this.basicInformation.replacementDate = new Date(TimeZoneFix(this.replacementDate).toISOString());
    if (this.giaProtocolDate) this.basicInformation.giaProtocolDate = new Date(TimeZoneFix(this.giaProtocolDate).toISOString());

    this.formationDiplomaService.addBasicInfo(this.basicInformation).subscribe({
      next: () => {
        this.notificationService.showSuccess('Основные сведения успешно сохранены');
        this.getBasicInformations();
      },
      error: (err) => {
        this.notificationService.showError(err.error, 5000);
        this.getBasicInformations();
      },
    });
  }

  public formatNumber(value: number) {
    return value ? Math.round(value * 100) / 100 : '-';
  }

  public changeForm(value?: string | Date) {
    let fillField = !(
      this.basicInformation.diplomaTypeId == null ||
      this.basicInformation.diplomaRegNumber == null ||
      this.diplomaIssueDate == null ||
      this.basicInformation.diplomaRegNumber.toString().trim() == ''
    );

    if (this.basicInformation.diplomaTypeId != null) {
      this.getBlanks(this.studentId, this.basicInformation.diplomaTypeId!);
    }

    this.fillRequiredFields.emit(fillField);
  }

  protected readonly BlankTypeEnumList = BlankTypeEnumList;
  protected readonly BlankSettingSectionEnum = BlankSettingSectionEnum;
  protected readonly BlankSettingValueEnum = BlankSettingValueEnum;

  private getPersonId() {
    this.giaUserAccessService.getCurrentUserAccess().subscribe((response) => (this.personId = response.personId));
  }

  protected readonly BlankTypeEnum = BlankTypeEnum;

  public showDiplomaBlankSeries: boolean = false;
  public showDiplomaBlankNumbers: boolean = false;
  public showDiplomaBlankAttachmentSeries: boolean = false;
  public showDiplomaBlankAttachmentNumber: boolean = false;
  public showDuplicateDiplomaBlankSeries: boolean = false;
  public showDuplicateDiplomaBlankNumber: boolean = false;
  public showDuplicateDiplomaBlankAttachmentSeries: boolean = false;
  public showDuplicateDiplomaBlankAttachmentNumber: boolean = false;

  public showDropDownList(seriesAndNumbersBlanksEnum: number) {
    switch (seriesAndNumbersBlanksEnum) {
      case SeriesAndNumbersBlanksEnum.diplomaBlankSeries: return  this.showDiplomaBlankSeries = true;
      case SeriesAndNumbersBlanksEnum.diplomaBlankNumbers: return this.showDiplomaBlankNumbers = true;
      case SeriesAndNumbersBlanksEnum.diplomaBlankAttachmentSeries: return this.showDiplomaBlankAttachmentSeries = true;
      case SeriesAndNumbersBlanksEnum.diplomaBlankAttachmentNumber: return this.showDiplomaBlankAttachmentNumber = true;
      case SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankSeries: return this.showDuplicateDiplomaBlankSeries = true;
      case SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankNumbers: return this.showDuplicateDiplomaBlankNumber = true;
      case SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankAttachmentSeries: return this.showDuplicateDiplomaBlankAttachmentSeries = true;
      case SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankAttachmentNumber: return this.showDuplicateDiplomaBlankAttachmentNumber = true;
      default: return;
    }

  }

  protected readonly SeriesAndNumbersBlanksEnum = SeriesAndNumbersBlanksEnum;
}

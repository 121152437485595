import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ControlActions,
  HeaderControlActions,
  StudentInSession,
} from 'src/app/models/middlecontrol/groupInSession.model';
import { MarksModel } from 'src/app/models/middlecontrol/marks.model';
import { DictsService } from 'src/app/services/middlecontrol/dicts.service';
import { SessionService } from 'src/app/services/middlecontrol/session.service';
import { TreeCommonModel } from '../../../models/middlecontrol/trees.model';
import {AcademicGroupQuery} from "../../../models/contingent/edugroup.model";
import {CurrentDayEnd} from "../../../helpers/date-helper";
import {TypeWorkEnum} from "../../../models/middlecontrol/enums/type-work.enum";

@Component({
  selector: 'app-sessiontable',
  templateUrl: './sessiontable.component.html',
  styleUrls: ['./sessiontable.component.scss'],
})
export class SessiontableComponent implements OnChanges {
  @Input() group: TreeCommonModel = {} as TreeCommonModel;
  @Input() semesterNumber = 0;
  @Input() contingentDate: Date = CurrentDayEnd();
  @Input() markDate: Date = CurrentDayEnd();
  @Input() editableStudentSession?: boolean;
  @Input() lastLoaded: Date | null = null;
  @Output() onChangeState: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeStudent: EventEmitter<{
    studentId: string;
    studentName?: string;
  }> = new EventEmitter<{
    studentId: string;
    studentName?: string;
  }>();
  @Output() onChangeDiscipline: EventEmitter<{
    disciplineId: string;
    electiveDisciplineId: string | null;
  }> = new EventEmitter<{
    disciplineId: string;
    electiveDisciplineId: string | null;
  }>();

  public currentDate: Date = CurrentDayEnd();
  controlActions: HeaderControlActions = {};
  headerControlActions: string[] | never[] = [];
  gridData: StudentInSession[] = [];
  dictMarks: { [key: string]: MarksModel } = {};
  isLoading: boolean = false;
  notImplement: boolean = true;
  eduGroupId: string = '';
  planId?: string;
  public academicGroupRequest?: AcademicGroupQuery

  constructor(
    private sessionService: SessionService,
    private dictService: DictsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const middle_control_settings = localStorage.getItem('middle_control_settings');
    if (middle_control_settings !== null) {
      let storage = JSON.parse(middle_control_settings)
      if(storage.discipline){
        this.openDiscipline(storage.disciplineId, storage.electiveDisciplineId)
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['group']) {
      this.eduGroupId = changes['group'].currentValue.id
        .toString()
        .trim()
        .split('_')
        .at(-1);
      this.planId = changes['group'].currentValue.planId
    }
    this.getGroupData();
  }

  public getGroupData() {
    this.isLoading = true;
    this.sessionService
      .getSheetHeader({
        eduGroupId: this.eduGroupId,
        semester: this.semesterNumber,
        planId: this.planId
      })
      .subscribe((response) => {
        this.controlActions = response.reduce((controlActions, item) => {
          if (controlActions[item.controlActionName]) {
            controlActions[item.controlActionName].push(item);
          } else controlActions[item.controlActionName] = [item];
          return controlActions;
        }, {} as HeaderControlActions);
        this.headerControlActions = Object.keys(this.controlActions);
        this.sessionService.sessionDisciplines$.next(response);
      });

    this.sessionService
      .getSheetStudentsList({
        eduGroupId: this.eduGroupId,
        semester: this.semesterNumber,
        contingentDate: this.contingentDate!,
        markDate: this.markDate!,
        planId: this.planId,
        isCurrentSemester: +this.currentDate !== +this.contingentDate
      })
      .subscribe((response) => {
        this.gridData = response.map((student) => {
          student.marksByDisciplineId = student.marks?.reduce((marks, item) => {
            if (marks)
              marks[
                item.educationPlanElectiveDisciplineId
                  ? item.educationPlanElectiveDisciplineId
                  : item.educationPlanDisciplineId
              ] = item;
            if (item.mark === 2 || item.mark === 0) student.isMarked = true;
            return marks;
          }, {} as StudentInSession['marksByDisciplineId']);
          if (student.sessionEnd)
            student.sessionEnd = this.formateDateStringFromUTC(
              student.sessionEnd
            );
          if (student.sessionBegin)
            student.sessionBegin = this.formateDateStringFromUTC(
              student.sessionBegin
            );
          return student;
        });
        this.isLoading = false;
      });

    this.dictService.marks$.subscribe((marksModel) => {
      this.dictMarks = marksModel.reduce((dict, mark) => {
        dict[mark.markValue.toString()] = mark;
        return dict;
      }, {} as { [key: string]: MarksModel });
    });
  }

  public openDiscipline(
    disciplineId: string,
    electiveDisciplineId: string | null
  ) {
    const middle_control_settings = localStorage.getItem('middle_control_settings');
    if (middle_control_settings !== null) {
      let storage = JSON.parse(middle_control_settings)
      let middleControlSaveSettings: any = {
        disciplineId: disciplineId,
        electiveDisciplineId: electiveDisciplineId,
        discipline:true,
        event: storage.event,
        selectedSemester:storage.selectedSemester,
        checkable: storage.checkable,
        checkedItems: storage.checkedItems,
        expandedKeys: storage.expandedKeys,
        selectedTrainingLevel: storage.selectedTrainingLevel,
        contingentDate: storage.contingentDate,
        markDate: storage.markDate
      }
      localStorage.setItem('middle_control_settings', JSON.stringify(middleControlSaveSettings));
    }

    this.onChangeDiscipline.emit({ disciplineId, electiveDisciplineId });
    this.onChangeState.emit('discipline');
  }

  public openSessionDate(studentId: string) {
    this.onChangeStudent.emit({ studentId });
    this.onChangeState.emit('session-date');
  }

  public openRetakeView(studentId: string, studentName: string) {
    this.onChangeStudent.emit({ studentId, studentName });
    this.onChangeState.emit('retakeView');
  }

  public formateDateStringFromUTC(date: string) {
    return date.split('T')[0].split('-').reverse().join('.');
  }

  public getMarkProperty(
    dataItem: StudentInSession,
    discipline: ControlActions,
    propertyName: keyof MarksModel
  ) {
    const disciplineId = discipline.educationPlanElectiveDisciplineId
      ? discipline.educationPlanElectiveDisciplineId
      : discipline.educationPlanDisciplineId;

    return this.dictMarks &&
      dataItem.marksByDisciplineId &&
      disciplineId &&
      dataItem.marksByDisciplineId[disciplineId] &&
      dataItem.marksByDisciplineId[disciplineId].mark !== null
      ? this.dictMarks[dataItem.marksByDisciplineId[disciplineId].mark][
          propertyName
        ]
      : '';
  }

  public getMark(
    dataItem: StudentInSession,
    discipline: ControlActions
  ) {
    const disciplineId = discipline.educationPlanElectiveDisciplineId
      ? discipline.educationPlanElectiveDisciplineId
      : discipline.educationPlanDisciplineId;

    return this.dictMarks &&
      dataItem.marksByDisciplineId &&
      disciplineId &&
      dataItem.marksByDisciplineId[disciplineId] &&
      dataItem.marksByDisciplineId[disciplineId].mark !== null
      ? dataItem.marksByDisciplineId[disciplineId].markView
      : '';
  }

  public getImplemented(
    dataItem: StudentInSession,
    discipline: ControlActions
  ) {
    const disciplineId = discipline.educationPlanElectiveDisciplineId
      ? discipline.educationPlanElectiveDisciplineId
      : discipline.educationPlanDisciplineId;

    return (
      this.dictMarks &&
      dataItem.marksByDisciplineId &&
      disciplineId &&
      dataItem.marksByDisciplineId[disciplineId] &&
      dataItem.marksByDisciplineId[disciplineId].notImplemented
    );
  }

  public getNotInTime(
    dataItem: StudentInSession,
    discipline: ControlActions
  ) {
    const disciplineId = discipline.educationPlanElectiveDisciplineId
      ? discipline.educationPlanElectiveDisciplineId
      : discipline.educationPlanDisciplineId;

    //только если явно признак указан
    return (dataItem.marksByDisciplineId
      && disciplineId
      && dataItem.marksByDisciplineId[disciplineId]
      && !dataItem.marksByDisciplineId[disciplineId].isInTime)
  }

  public navigateTo(studentId: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/contingent/studentmaininfo/${studentId}/progress`])
    );

    window.open(url, '_blank');
  }

  // instead -webkit-line-clamp in CSS
  public trimText(text: string, linesCount: number = 2, lineLength: number = 30) {
    const words = text.split(' ');
    const ellipsis = '…';
    let line = '';
    let lineNumber = 0;

    const result: string[] = words.reduce<string[]>((acc, word) => {
      const newLineLength = `${line} ${word}`.length;

      if (newLineLength > lineLength) {
        lineNumber++;
        if (lineNumber === linesCount) {
          line = `${line} ${word}`.substring(0, lineLength - ellipsis.length - 1);
          acc.push(`${line}${ellipsis}`);
          line = '';
        } else {
          if (line) {
            acc.push(line);
            line = word;
          } else {
            const hyphen = word.indexOf('-') + 1;
            const part = hyphen ? word.substring(0, hyphen) : word;
            acc.push(part);
            line = hyphen ? word.substring(hyphen) : '';
          }
        }
      } else {
        line += line ? ` ${word}` : word;
      }
      return acc;
    }, []);

    if (line) {
      result.push(line);
    }

    return result.join(' ');
  }

  protected readonly TypeWorkEnum = TypeWorkEnum;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";
import { StudyTechnology } from 'src/app/models/dicts/studytechnology.model';

@Injectable({
    providedIn: 'root'
  })
export class StudyTechnologyService {
    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studytechnology}`;

  constructor(private http: HttpClient) { }

  //Get all StudyTechnology
  public getAllStudyTechnology(): Observable<StudyTechnology[]> {
    return this.http.get<StudyTechnology[]>(this.baseUrl);
  }

  //Add StudyTechnology
  addStudyTechnology(studyTechnology: StudyTechnology): Observable<StudyTechnology> {
    return this.http.post<StudyTechnology>(this.baseUrl, studyTechnology)
      .pipe(catchError(this.handleError));
  }

  //Delete StudyTechnology
  deleteStudyTechnology(id: string): Observable<StudyTechnology> {
    return this.http.delete<StudyTechnology>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit StudyTechnology
  updateStudyTechnology(studyTechnology: StudyTechnology): Observable<StudyTechnology> {
    return this.http.put<StudyTechnology>(this.baseUrl + '/' + studyTechnology.dictStudyTechnologyExternalId, studyTechnology)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}
import { Injectable } from '@angular/core';
import {Observable, retry, throwError, BehaviorSubject} from 'rxjs';
import urlJoin from 'url-join';
import { catchError, tap } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import {
  EducationPlanChangeStatus,
  EducationPlanDetailsModels, ReattachPlan,
} from '../../../models/education/education-plan-details-model';
import { environment } from '../../../../environments/environment';
import { DictControlAction } from 'src/app/models/education/controlaction.model';
import {
  Discipline,
  DisciplineType,
} from 'src/app/models/education/discipline.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { join, sortBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class EducationPlanDetailsService {
  private eduPlanUrl = urlJoin(
    <string>environment.educationApiEndpoint,
    'v1',
    environment.apiPaths.education.educationPlan
  );
  private eduPlanDisciplineUrl = urlJoin(
    <string>environment.educationApiEndpoint,
    'v1',
    environment.apiPaths.education.planDiscipline
  );
  // TODO: the following to separate services
  private disciplineUrl = urlJoin(
    <string>environment.educationApiEndpoint,
    environment.apiPaths.education.discipline
  );
  private disciplineTypesUrl = urlJoin(
    <string>environment.educationApiEndpoint,
    environment.apiPaths.education.disciplineTypes
  );
  private controlActionsUrl = urlJoin(
    <string>environment.educationApiEndpoint,
    environment.apiPaths.education.dictControlAction
  );

  public currentEducationStandard: EducationPlanDetailsModels.EStandard =
    {} as EducationPlanDetailsModels.EStandard;
  public currentEducationProgram: EducationPlanDetailsModels.EProgram =
    {} as EducationPlanDetailsModels.EProgram;
  public currentEducationPlan: EducationPlanDetailsModels.EPlan =
    {} as EducationPlanDetailsModels.EPlan;
  public currentDiscipline: EducationPlanDetailsModels.EDiscipline =
    {} as EducationPlanDetailsModels.EDiscipline;
  public currentTrainingLevelId = '';
  public currentFilialId = '';

  //public studyForms: string[] = [];
  public eStudyForms: {studyFormName: string, studyFormOrderNum?: number}[] = [];
  public currentStudyForm = '';

  public educationPlans: EducationPlanDetailsModels.EPlan[] = [];

  public eduPlanStatuses: Array<{ text: string; value: number }> = [
    { text: 'В разработке', value: 1 },
    { text: 'Утверждён', value: 2 },
    { text: 'Закрыт', value: 3 },
  ];

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationsService
  ) {}

  /*
  Получение списка БУП - возвращет направление, все ОП по нему и все БУП по всем ОП
   */
  getDetails(
    eduProgramId: string
  ): Observable<EducationPlanDetailsModels.EStandard> {
    return this.httpClient
      .get<EducationPlanDetailsModels.EStandard>(
        urlJoin(this.eduPlanUrl, eduProgramId)
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
          this.currentTrainingLevelId = response.trainingLevelId;
          this.currentEducationStandard = response;
          // TODO: use find instead
          response.educationPrograms.forEach((eduProgram) => {
            eduProgram.educationProgramNameWithCipher = eduProgram.educationProgramCipher + ' - ' + eduProgram.educationProgramName;
            if (eduProgram.educationProgramId !== eduProgramId) {
              return;
            }
            this.currentEducationProgram = eduProgram;
            this.currentFilialId = eduProgram.filialId;
            const educationPlans = eduProgram.educationPlans;

            if (educationPlans.length > 0) {
              if (this.currentEducationPlan?.educationPlanId) {
                this.currentEducationPlan =
                  educationPlans.find(
                    (x) => x.educationPlanId == this.currentEducationPlan.educationPlanId
                  ) || educationPlans[0];
              } else {
                this.currentEducationPlan = educationPlans[0];
              }
              this.getStudyForms(eduProgram);
            } else {
              this.currentEducationPlan =
                {} as EducationPlanDetailsModels.EPlan;
              this.eStudyForms = [];
            }

          });

        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  setEducationProgram(eduProgramId: string) {
    if (!this.currentEducationStandard) return;

    this.currentEducationStandard.educationPrograms.forEach((eduProgram) => {
      if (eduProgram.educationProgramId === eduProgramId) {
        this.currentEducationProgram = eduProgram;
        this.currentEducationPlan =
          eduProgram.educationPlans[0] ||
          ({} as EducationPlanDetailsModels.EPlan);
        this.getStudyForms(eduProgram);
      }
    });
  }

  getStudyForms(eduProgram: EducationPlanDetailsModels.EProgram) {
    //this.studyForms = [
    //  ...new Set(eduProgram.educationPlans.map((ePlan) =>  ePlan.studyFormName)),
    //];
    this.eStudyForms = [
      ...new Set(eduProgram.educationPlans.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.studyFormName === value.studyFormName))
        .map((ePlan) => ({ studyFormName: ePlan.studyFormName, studyFormOrderNum: ePlan.studyFormOrderNum}))
        .sort((a: any, b: any) => a.studyFormOrderNum - b.studyFormOrderNum || a.studyFormName.localeCompare(b.studyFormName))),
    ];
    let currentStudyForm = this.eStudyForms[0].studyFormName ;
    if (
      this.currentEducationPlan.studyFormName &&
      this.eStudyForms.map(form => form.studyFormName.includes(this.currentEducationPlan.studyFormName))
    ) {
      currentStudyForm = this.currentEducationPlan.studyFormName;
    }
    this.currentStudyForm = currentStudyForm;
    this.filterEducationPlansByStudyForm(currentStudyForm);
  }

  filterEducationPlansByStudyForm(studyFormName: string): void {
    this.currentStudyForm = studyFormName;
    this.educationPlans = sortBy(
      this.currentEducationProgram.educationPlans.filter((item) => {
        return item.studyFormName === studyFormName;
      }),
      ['longName']
    );
    if (this.currentEducationPlan.studyFormName !== studyFormName) {
      this.setEducationPlan(
        this.educationPlans.length
          ? this.educationPlans[0]
          : ({} as EducationPlanDetailsModels.EPlan)
      );
    }
  }

  setEducationPlan(eduPlan: EducationPlanDetailsModels.EPlan) {
    if (!this.currentEducationProgram) {
      return;
    }
    this.currentEducationPlan = eduPlan;

    this.getStudyForms(this.currentEducationProgram)
  }

  changeEduPlanStatus(): Observable<any> {
    return this.httpClient
      .put<EducationPlanChangeStatus>(
        urlJoin(this.eduPlanUrl, 'changeStatus'),
        {
          educationPlanId: this.currentEducationPlan.educationPlanId,
          status: this.currentEducationPlan.status,
          description: this.currentEducationPlan.description || '',
        }
      )
      .pipe(retry(2), catchError(this.errorHandler.bind(this)))
  }

  addEduPlan(
    data: EducationPlanDetailsModels.EPlan
  ): Observable<EducationPlanDetailsModels.EPlan> {
    return this.httpClient
      .post<EducationPlanDetailsModels.EPlan>(this.eduPlanUrl, data)
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
          this.currentEducationPlan = response;
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  updateEduPlan(
    data: EducationPlanDetailsModels.EPlan
  ): Observable<EducationPlanDetailsModels.EPlan> {
    return this.httpClient
      .put<EducationPlanDetailsModels.EPlan>(this.eduPlanUrl, data)
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
          this.currentEducationPlan = response;
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  deleteEduPlan() {
    return this.httpClient
      .delete(urlJoin(this.eduPlanUrl, this.currentEducationPlan.educationPlanId))
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
          this.currentEducationPlan = {} as EducationPlanDetailsModels.EPlan;
          this.getDetails(
            this.currentEducationProgram.educationProgramId
          ).subscribe();
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  exportEduPlan(): Observable<HttpResponse<Blob>> {
    return this.httpClient
      .get<Blob>(
        urlJoin(this.eduPlanUrl, 'export', this.currentEducationPlan.educationPlanId),
        { observe: 'response', responseType: 'blob' as 'json' }
      )
      .pipe(retry(2), catchError(this.errorHandler.bind(this)));
  }

  getAllDisciplines(): Observable<Discipline[]> {
    return this.httpClient.get<Discipline[]>(this.disciplineUrl).pipe(
      retry(2),
      tap((response) => {
        if (!response) {
          return;
        }
      }),
      catchError(this.errorHandler.bind(this))
    );
  }

  getDisciplineTypes(): Observable<DisciplineType[]> {
    return this.httpClient.get<DisciplineType[]>(this.disciplineTypesUrl).pipe(
      retry(2),
      tap((response) => {
        if (!response) {
          return;
        }
      }),
      catchError(this.errorHandler.bind(this))
    );
  }

  getEduPlanDisciplines(
    educationPlanId: string
  ): Observable<{ items: EducationPlanDetailsModels.EPlanDiscipline[] }> {
    return this.httpClient
      .get<{ items: EducationPlanDetailsModels.EPlanDiscipline[] }>(
        urlJoin(this.eduPlanUrl, 'getPlan', educationPlanId)
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  getPlanDisciplineById(
    educationPlanId: string,
    disciplineId: string
  ): Observable<EducationPlanDetailsModels.EPlanDiscipline> {
    return this.httpClient
      .get<EducationPlanDetailsModels.EPlanDiscipline>(
        urlJoin(this.eduPlanDisciplineUrl, educationPlanId, disciplineId)
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  addDiscipline(
    educationPlanId: string,
    data: EducationPlanDetailsModels.EPlanDiscipline
  ): Observable<EducationPlanDetailsModels.EPlanDiscipline> {
    return this.httpClient
      .post<EducationPlanDetailsModels.EPlanDiscipline>(
        urlJoin(this.eduPlanDisciplineUrl, educationPlanId),
        data
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  updateDiscipline(
    educationPlanId: string,
    data: EducationPlanDetailsModels.EPlanDiscipline
  ): Observable<EducationPlanDetailsModels.EPlanDiscipline> {
    return this.httpClient
      .put<EducationPlanDetailsModels.EPlanDiscipline>(
        urlJoin(this.eduPlanDisciplineUrl, educationPlanId, data.id),
        data
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  deleteDiscipline(educationPlanId: string, id: string) {
    return this.httpClient
      .delete(urlJoin(this.eduPlanDisciplineUrl, educationPlanId, id))
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  getEduPlanSummary(
    educationPlanId: string
  ): Observable<EducationPlanDetailsModels.EPlanSummary> {
    return this.httpClient
      .get<EducationPlanDetailsModels.EPlanSummary>(
        urlJoin(this.eduPlanUrl, 'summary', educationPlanId)
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  getEduPlanCopyList(
    educationPlanId: string
  ): Observable<EducationPlanDetailsModels.ECopyPlan[]> {
    return this.httpClient
      .get<EducationPlanDetailsModels.ECopyPlan[]>(
        urlJoin(this.eduPlanUrl, 'getCopyList', educationPlanId)
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  copyEduPlan(
    sourceId: string,
    destinationId: string
  ): Observable<EducationPlanDetailsModels.EPlan> {
    return this.httpClient
      .post<EducationPlanDetailsModels.EPlan>(
        urlJoin(this.eduPlanUrl, 'copy', sourceId, destinationId),
        {}
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
          this.currentEducationPlan = response;
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  /*
    Получение данных о последнем использовании дисциплины в образовательной программе (кафедра, компонент, цикл)˝
  */
  getSubjectPrevRelation(
    educationPlanId: string,
    dictDisciplineId: string
  ): Observable<Partial<EducationPlanDetailsModels.EPlanDiscipline>> {
    return this.httpClient
      .get<EducationPlanDetailsModels.EPlanDiscipline>(
        urlJoin(
          this.eduPlanUrl,
          'getSubjectPrevRelation',
          educationPlanId,
          dictDisciplineId.toString()
        )
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  /*
  Получение сведений учебного плана (уровень подготовки, форма обучения и т.д.)
  */
  getPlanInfo(
    educationPlanId: string
  ): Observable<Partial<EducationPlanDetailsModels.EPlan>> {
    return this.httpClient
      .get<EducationPlanDetailsModels.EPlan>(
        urlJoin(this.eduPlanUrl, 'info', educationPlanId)
      )
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
          this.currentEducationPlan = response;
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  /*
  Получение сведений учебного плана (уровень подготовки, форма обучения и т.д.)
  */
  getControlActions(): Observable<DictControlAction[]> {
    return this.httpClient
      .get<DictControlAction[]>(this.controlActionsUrl)
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  setDiscipline(item: EducationPlanDetailsModels.EDiscipline) {
    // console.log('item', item)
    this.currentDiscipline = item;
  }

  reattachPlan(request: ReattachPlan) {
    return this.httpClient
        .put<ReattachPlan>(urlJoin(this.eduPlanUrl, 'reattach'), request)
        .pipe(
            retry(2),
            catchError(this.errorHandler.bind(this))
        );
  }

  public modification = new BehaviorSubject<any> ({
    value: false,
  });

  public locationURL = new BehaviorSubject<any> ({
    value: false,
  });

  private errorHandler(error: HttpErrorResponse) {
    console.log(error.error);
    if (typeof error.error === "string") {
      this.notificationService.showError(error.error);
    }
    else {
      let errorText = join(Object.getOwnPropertyNames(error.error).map(x=>error.error[x], ', '), '; ');
      if(errorText.length == 0) errorText = 'Произошла ошибка';
      this.notificationService.showError(errorText);
    }
    return throwError(() => error.message);
  }
}

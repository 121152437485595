import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectEvent} from "@progress/kendo-angular-layout";
import {PublicationEditFormTabs, PublicationProperties} from '../../../../../models/publications/editForm/editForm.enums';
import {PublicationsEditFormService} from "../../../../../services/science/publications/editForm.service";
import {PublicationsService} from "../../../../../services/science/publications/publications.service";
import {PublicationForm} from "../../../../../models/publications/editForm/publication.model";
import {AllowedStatus, Status, StatusEnum} from "../../../../../models/publications/statuses.model";
import {StatusesService} from "../../../../../services/science/publications/statuses.service";
import {ButtonThemeColor} from "@progress/kendo-angular-buttons";
import {PropertiesService} from "../../../../../services/science/publications/properties.service";
import {Subscription} from 'rxjs';
import {NotificationService} from "../../../../../services/science/publications/notification.service";


@Component({
  selector: 'publications-edit-form-home',
  templateUrl: './publications-edit-form-home.component.html',
  styleUrls: ['./publications-edit-form-home.component.scss']
})
export class PublicationsEditFormHomeComponent implements OnInit, OnDestroy {

  public status!: Status;
  public allowedStatuses!: AllowedStatus[];
  public currentTab: string = this.editFormService.currentTab$.value;
  public currentPublication!: PublicationForm;
  private publicationId!: string;

  public isNew: boolean = false;
  public isComments: boolean = this.currentTab === PublicationEditFormTabs.Comments;
  public disableCodes: boolean = false;

  private statusSubscription$!: Subscription;
  private typeSubscription$!: Subscription;
  private tabSubscription$!: Subscription;

  constructor(
      private notificationsService: NotificationsService,
      private dialogService: DialogService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private publicationsService: PublicationsService,
      private statusesService: StatusesService,
      private editFormService: PublicationsEditFormService,
      private propertiesService: PropertiesService,
      private badgeService: NotificationService
  ) { }

  async ngOnInit() {
    this.publicationId = this.activatedRoute.snapshot.params['publicationId'];
    this.isNew = this.publicationId === undefined;

    if (!this.isNew) {
      this.getPublication();
    }

    this.subscribe();
  }

  private subscribe() {
    this.statusSubscription$ = this.statusesService.statusData$.subscribe(() =>
      this.getStatus(this.publicationId));
    this.typeSubscription$ = this.editFormService.currentType$.subscribe((value) =>
      value && this.getCodesProperties(value));
    this.tabSubscription$ = this.editFormService.currentTab$.subscribe((value) => {
      this.currentTab = value ? value : PublicationEditFormTabs.MainInfo;
      this.isComments = this.currentTab === PublicationEditFormTabs.Comments;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  public reloadPageConfirmation() {
    if (!this.editFormService.isFormSaved(this.currentTab)) {
      window.opener.location.reload();
    }
  }

  private getStatus(publicationId?: string) {
    this.statusesService.getStatusInfo(publicationId).subscribe((value) => {
      this.status = value.currentStatus;
      this.allowedStatuses = value.allowedStatuses;
    });
  }

  private getPublication() {
    this.publicationsService.getPublication(this.publicationId)
      .subscribe((value) => {
        this.currentPublication = value;
        this.badgeService.refreshBadge();
        this.getCodesProperties(this.currentPublication.typeId);
      });
  }

  private getCodesProperties(typeId: string) {
    this.propertiesService.getAllowedProperties({
      typeId: typeId, categoryId: PublicationProperties.Codes})
      .subscribe(value => this.disableCodes = value === null);
  }

  public onDelete() {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить публикацию?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.publicationsService.removePublication(this.publicationId).subscribe({
          next: () => {
            this.notificationsService.showSuccess('Успешно');
            this.router.navigate(['publications/']);
          },
          error: (error) => this.notificationsService.showError(error)
        });
      }
    });
  }

  public onClear() {
    this.editFormService.clear$.next(true);
  }

  public onClose() {
    const isSaved = this.editFormService.isFormSaved(this.currentTab);
    if (!isSaved) {
      const dialog: DialogRef = openDialog(
        this.dialogService,
        `Вы уверены, что хотите закрыть публикацию?
      Несохраненные данные будут удалены.`
      );
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == "Да") {
          this.router.navigate(['publications/']);
        }
      });
    } else {
      this.router.navigate(['publications/']);
    }
  }

  public onSave() {
    this.editFormService.save$.next({name: this.currentTab, disableCodes: this.disableCodes});
  }

  public onCopy() {
    const isSaved = this.editFormService.isFormSaved(this.currentTab);
    if (isSaved) {
      this.publicationsService.getPublicationCopy(this.publicationId).subscribe({
        next: (value) => {
          this.router.navigate([`publications/newPublication/${value}`])
            .then(() => {
              this.notificationsService.showSuccess('Успешно');
              this.publicationId = value;
              this.statusesService.statusData$.next(this.currentTab);
            });
        },
        error: (error) => this.notificationsService.showError(error)
      });
    } else {
      this.notificationsService.showError('Сохраните изменения раздела');
    }
  }

  public onChangeStatus(id: number) {
    const isSaved = this.editFormService.isFormSaved(this.currentTab);
    if (isSaved) {
      this.statusesService.updateStatus(this.publicationId, id).subscribe({
        next: () => {
          this.notificationsService.showSuccess('Успешно');
          this.statusesService.statusData$.next(this.currentTab);
        },
        error: (error) => this.notificationsService.showError(error)
      });
    } else {
      this.notificationsService.showError('Сохраните изменения раздела');
    }
  }

  public onTabChange(source: SelectEvent) {
    if (this.currentTab === source.title) {
      return;
    }

    const isSaved = this.editFormService.isFormSaved(this.currentTab);
    if (isSaved || this.isComments) {
      this.isComments = source.title === PublicationEditFormTabs.Comments;
      this.currentTab = source.title;
    } else {
      source.preventDefault();
      this.notificationsService.showError('Сохраните изменения раздела');
    }

    this.editFormService.change$.next('');
  }

  public getThemeColor(status: Status, name: string): ButtonThemeColor {
    return status.name === name ? 'base' : 'primary';
  }

  private unsubscribe() {
    this.statusSubscription$.unsubscribe();
    this.typeSubscription$.unsubscribe();
    this.tabSubscription$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  protected readonly StatusEnum = StatusEnum;
  protected readonly PublicationEditFormTabs = PublicationEditFormTabs;
}

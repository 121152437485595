<mfc-settings></mfc-settings>
<h1>Настройки списка подписантов</h1>

<div [formGroup]="formGroup" class="container">
    <kendo-grid class="grid-data borderless"
                #grid
                [data]="gridData"
                [loading]="loading"
                [pageable]="pagerSettings"
                [skip]="skip"
                [pageSize]="pageSize"
                [filterable]="true"
                (filterChange)="filterChange($event)"
                (add)="addRow($event)"
                (cellClick)="editRow($event)"
                (pageChange)="pageChange($event)"
                (remove)="onRemove($event)"
                (cancel)="onCancel()">
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <ng-template position="both" kendoGridToolbarTemplate>
            <span *ngIf="editMode; else view">
                <button (click)="onCancel()" kendoButton>Отмена</button>
                <button (click)="onSave()" kendoButton themeColor="primary">Сохранить</button>
            </span>
            <ng-template #view>
                <button kendoGridAddCommand themeColor="success" icon="plus">Добавить</button>
            </ng-template>
        </ng-template>
        <kendo-grid-column field="categories" title="Категория">
            <ng-template kendoGridFilterCellTemplate let-filter="filter" let-column="column">
                <filter-multiselect [data]="dictCategories" gridField="categories" textField="name" valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <kendo-multiselect
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictCategories"
                        [valuePrimitive]="true"
                        formControlName="categories"
                        valueField="name"
                        textField="name">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                        {{dataItem.length}} выбрано
                    </ng-template>
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="filial" title="Филиал">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictFilials" gridField="filial" textField="name" valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <kendo-multiselect
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictFilials"
                        [valuePrimitive]="true"
                        formControlName="filial"
                        valueField="name"
                        textField="name">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                        {{dataItem.length}} выбрано
                    </ng-template>
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="trainingLevels" title="Уровень подготовки">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictTrainingLevels" gridField="trainingLevels" textField="name" valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <kendo-multiselect
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictTrainingLevels"
                        [valuePrimitive]="true"
                        formControlName="trainingLevels"
                        valueField="name"
                        textField="name">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                        {{dataItem.length}} выбрано
                    </ng-template>
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="faculties" title="Институт/факультет">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictDepartments" gridField="faculties" textField="name" valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <kendo-multiselect
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictDepartments"
                        [valuePrimitive]="true"
                        formControlName="faculties"
                        valueField="name"
                        textField="name">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                        {{dataItem.length}} выбрано
                    </ng-template>
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="roles" title="Роль">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictRoles" gridField="roles" textField="name" valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <kendo-multiselect
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictRoles"
                        [valuePrimitive]="true"
                        formControlName="roles"
                        valueField="name"
                        textField="name">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                        {{dataItem.length}} выбрано
                    </ng-template>
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="applicationTypes" title="Тип заявки">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictApplicationTypes" gridField="applicationTypes" textField="name" valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <kendo-multiselect
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictApplicationTypes"
                        [valuePrimitive]="true"
                        formControlName="applicationTypes"
                        valueField="name"
                        textField="name">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                        {{dataItem.length}} выбрано
                    </ng-template>
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="document" title="Наименование документа">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictDocuments" gridField="document" textField="name" valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <kendo-multiselect
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictDocuments"
                        [valuePrimitive]="true"
                        formControlName="roles"
                        valueField="name"
                        textField="name">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                        {{dataItem.length}} выбрано
                    </ng-template>
                </kendo-multiselect>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="70" title="">
            <ng-template kendoGridCellTemplate>
                <button kendoGridRemoveCommand
                        themeColor="secondary"
                        icon="delete"
                        fillMode="flat"
                        size="large"
                        rounded="full">
                </button>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

<div kendoDialogContainer></div>
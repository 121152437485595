import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SemesterYearsModel } from 'src/app/models/currentcontrol/semesterYears.model';
import { TreeCommonModel } from 'src/app/models/currentcontrol/trees.model';
import { DictsService } from 'src/app/services/currentcontrol/dicts.service';
import {CurrentDayEnd} from "../../../helpers/date-helper";

@Component({
  selector: 'app-currentcontrolhome',
  templateUrl: './currentcontrolhome.component.html',
  styleUrls: ['./currentcontrolhome.component.scss'],
})
export class CurrentcontrolHomeComponent implements OnInit {
  public stateList: string[] = ['main', 'discipline'];
  public state: string = '';
  public semester: SemesterYearsModel = {} as SemesterYearsModel;
  public contingentDate: Date = CurrentDayEnd();
  public columns: string | null = null;
  public disciplineId: string = '';
  public electiveDisciplineId: string | null = null;
  public selectedGroup: TreeCommonModel = {} as TreeCommonModel;
  public eduGroupId: string = '';
  public facultyId: string = '';

  constructor(
    private router: Router,
    private dictService: DictsService
  ) {}

  ngOnInit(): void {
    this.dictService.getAttestations();
    this.dictService.getCriterialMarks();
    const current_control_settings = localStorage.getItem('current_control_settings');
    if (current_control_settings !== null) {
      let storage = JSON.parse(current_control_settings)
      this.columns = storage.week === undefined? null : storage.week
    }
  }

  public onNavigateTo(path: string): void {
    localStorage.removeItem("current_control_settings")
    this.router.navigate([`/currentcontrol/${path}`]);
  }

  public onChangeSemester(value: SemesterYearsModel) {
    this.semester = value;
  }

  public onChangeContingentDate(value: Date) {
    this.contingentDate = value;
  }

  public onSelectionTreeChange(group: TreeCommonModel) {
    this.selectedGroup = group;

    this.eduGroupId = group.id.toString().trim().split('_').slice(-1)[0];
    this.facultyId = group.id.toString().trim().split('_')[0];
    this.state = 'main';
  }

  public editable?: boolean;

  public onChangeEditable(value: boolean) {
    this.editable = value;
  }

  public editableDiscipline?: boolean;

  public onChangeEditableDiscipline(value: boolean) {
    this.editableDiscipline = value;
  }

  public onStateChange(value: string) {
    this.state = value;
  }

  public onChangeColumns(value: string | null) {
    this.columns = value;
  }

  public onChangeDiscipline(discipline: {
    disciplineId: string;
    electiveDisciplineId: string | null;
  }) {
    this.disciplineId = discipline.disciplineId;
    this.electiveDisciplineId = discipline.electiveDisciplineId;
  }
}

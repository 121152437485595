<oferta-settings></oferta-settings>

<h1>Типы положений</h1>

<div class="grid-data">

  <kendo-grid [kendoGridBinding]="regulationTypes"
              [pageable]="pagerSettings"
              [pageSize]="pageSize"
              (cellClick)="editHandler($event)"
              [sortable]="true">

    <ng-template *ngIf="editable" kendoGridToolbarTemplate position="both">
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column field="name" title="Тип положения" headerClass="gridHeader">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [formControl]="formGroup.get('name')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.name}}
                      </span>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column field="order"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader"
    >
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-numerictextbox
                                 [style.width.px]="170"
                                 [value]="dataItem.order"
                                 [min]="1"
                                 format="n0"
                                 [decimals]="0"
                                 [formControl]="formGroup.get('order')">
                               </kendo-numerictextbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.order}}
            </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictWorkForm} from "../../../models/publications/dict/workForm.model";

@Injectable({
    providedIn: 'root'
})
export class PublicationsWorkFormService {
    private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/WorkForm/`;

    constructor(private http: HttpClient) {}

    public getWorkForms(): Observable<DictWorkForm[]> {
        return this.http.get<DictWorkForm[]>(this.baseUrl);
    }
}
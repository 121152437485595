import { Component, OnInit } from '@angular/core';
import { NodeClickEvent, TreeItem } from '@progress/kendo-angular-treeview';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { DiplomHomeService } from 'src/app/services/gia/diplomhome.service';
import { DictsService } from '../../../services/gia/dicts.service';
import { EducationStandardTree, DictTrainingLevel, DepartmentsModel } from 'src/app/models/gia/diplomhome.model';
import { StudyYearDto } from 'src/app/models/gia/dicts/dicts.model';
import { DiplomaPrintService } from '../../../services/gia/diplomaPrint.service';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { DepartmentType } from '../../../models/gia/enums/departmentType.enum';
import { DownloadFile } from '../../../helpers/downloadFile-helper';
import { TrainingLevelEnum } from '../../../models/gia/enums/training-level.enum';

@Component({
  selector: 'app-diplomhome',
  templateUrl: './diplomhome.component.html',
  styleUrls: ['./diplomhome.component.scss'],
})
export class DiplomHomeComponent implements OnInit {
  public trainingLevels: DictTrainingLevel[] = [];
  public yearAdmissions: StudyYearDto[] = [];
  public departments: DepartmentsModel[] = [];

  protected readonly trainingLevelEnum = TrainingLevelEnum;

  public dataTree: EducationStandardTree[] = [];
  public expandedKeys: string[] = [];
  public click: boolean = false;
  public groupName: string = '';
  public currentStandartId: string = '';
  public changeGroup: string = '';

  public trainingLevelId: string = '';
  public facultyId: string | null = null;
  public year: number = 0;
  public studyFormId: string | undefined = '';

  private filter: any | undefined;

  public url = window.location.href;

  constructor(
    private router: Router,
    private diplomHomeService: DiplomHomeService,
    private dictsService: DictsService,
    private notificationService: NotificationsService,
    private diplomaPrintService: DiplomaPrintService,
  ) {}

  ngOnInit() {
    if (this.url.endsWith('diploma') || this.url.endsWith('giaprotocol') || this.url.endsWith('admissionorder') || this.url.endsWith('releaseorder')) {
      localStorage.removeItem('diplomas_settings');
    } else {
      this.filter = this.getLocalStorage();
      if (this.filter) {
        this.trainingLevelId = this.filter.trainingLevel;
        this.year = this.filter.yearAdmission;
        this.facultyId = this.filter.faculty;
        this.studyFormId = this.filter.studyForm;
        this.currentStandartId = this.filter.standard;
      }
    }

    //this.selectedGroup();
    this.getTrainingLevels();
  }

  public getTrainingLevels() {
    this.diplomHomeService.getTrainingLevel().subscribe((response) => {
      this.trainingLevels = response;
      if (!this.filter) this.trainingLevelId = this.trainingLevels[0].id;

      this.getYearAdmissions();
    });
  }

  public getYearAdmissions() {
    this.dictsService.getStudyYears().subscribe((response) => {
      this.yearAdmissions = response;
      if (!this.filter) {
        const month = new Date().getMonth();
        month < 9 ? (this.year = new Date().getFullYear() - 1) : (this.year = new Date().getFullYear());
      }

      this.getFaculties();
    });
  }

  public getFaculties() {
    this.diplomHomeService.getDepartmentsByStudents(DepartmentType.facultie, this.trainingLevelId, this.year).subscribe((response) => {
      if (response !== null) {
        this.departments = response;
      } else {
        this.facultyId = null;
        this.departments = [];
      }

      if (!this.filter && this.departments.length !== 0) this.facultyId = this.departments[0].id;
      this.getEducationTree();
    });
  }

  public getEducationTree() {
    if (this.facultyId) {
      this.diplomHomeService.getStandardTree(this.facultyId, this.trainingLevelId, this.year).subscribe({
        next: (response) => {
          this.dataTree = response;
          this.selectedGroup();
        },
        error: () => {
          this.dataTree = [];
        },
      });
    } else this.dataTree = [];
  }

  public fetchChildren(node: any) {
    //Return the items collection of the parent node as children.
    return of(node.value);
  }

  public hasChildren(node: any): boolean {
    if (node.value) return Boolean(node.value) && !(Object.keys(node).includes('isStandard') && node.isStandard);
    else return node.items && node.items.length > 0;
  }

  public onSelectionTreeChange(event: TreeItem) {
    this.click = true;

    if (event.dataItem.value == null) {
      this.groupName = event.dataItem.text;
      this.changeGroup = event.dataItem.text;
      let index = event.dataItem.id.lastIndexOf('_');
      this.currentStandartId = event.dataItem.id.slice(index + 1);
      this.studyFormId = this.dataTree.find((el) => el.value?.some((_) => _.id == event.dataItem.id))?.id;
      this.openStandard(this.currentStandartId);

      let diplomsSaveSettings: any = {
        event: event,
      };

      const storage = this.getLocalStorage();
      if (storage !== null) {
        diplomsSaveSettings.expandedKeys = storage.expandedKeys;
      }
      localStorage.setItem('diplomas_settings', JSON.stringify(diplomsSaveSettings));
    }
  }

  public onNodeClick(event: NodeClickEvent): void {
    if (event.item) {
      if (!event.item.index.includes('_')) this.studyFormId = event.item.dataItem.id;
      else this.studyFormId = this.dataTree.find((el) => el.value?.some((_) => _.id == event.item?.dataItem.id))?.id;

      const index = this.expandedKeys.indexOf(event.item.index);
      if (index > -1) this.expandedKeys.splice(index, 1);
      else this.expandedKeys.push(event.item.index);

      const storage = this.getLocalStorage();
      if (storage !== null) {
        const diplomsSaveSettings = {
          event: storage.event,
          expandedKeys: this.expandedKeys,
          changeGroup: this.changeGroup,
          standard: this.currentStandartId,
          trainingLevel: this.trainingLevelId,
          yearAdmission: this.year,
          faculty: this.facultyId,
          studyForm: this.studyFormId,
        };
        localStorage.setItem('diplomas_settings', JSON.stringify(diplomsSaveSettings));
        localStorage.removeItem('protocol_paging');
      }
    }
  }

  public getLocalStorage() {
    let storage = localStorage.getItem('diplomas_settings');
    return storage ? JSON.parse(storage) : null;
  }

  public isExpanded = (dataItem: any, index: string) => {
    return this.expandedKeys.includes(index);
  };

  private selectedGroup() {
    const storage = this.getLocalStorage();
    if (storage !== null) {
      this.expandedKeys = storage.expandedKeys;

      setTimeout(() => {
        const index = storage.event.index;
        const all = document.querySelectorAll('span');
        const span = Array.from(all).findIndex((el) => el.dataset['treeindex'] == index);
        if (span > -1) all[span].classList.add('k-selected');
      }, 50);
    }
  }

  public openStandard(id: string) {
    if (this.url.includes('diploma')) this.router.navigateByUrl(`/gia/diploma/${this.studyFormId}/${id}`).then();
    if (this.url.includes('giaprotocol')) this.router.navigateByUrl(`/gia/giaprotocol/${id}`).then();
    if (this.url.includes('admissionorder')) {
      localStorage.removeItem("admission_order_grid_settings");
      this.router.navigateByUrl(`/gia/admissionorder/${id}/${this.facultyId}/${this.year}/${this.studyFormId}`).then();
    }
    if (this.url.includes('releaseorder')) {
      localStorage.removeItem("release_order_grid_settings");
      this.router.navigateByUrl(`/gia/releaseorder/${id}/${this.facultyId}/${this.year}/${this.studyFormId}`).then();
    }
  }

  public valueChange(value: string, getFaculty?: boolean) {
    this.filter = undefined;
    localStorage.removeItem('diplomas_settings');
    this.expandedKeys = [];

    getFaculty ? this.getFaculties() : this.getEducationTree();

    if (this.url.includes('diploma')) this.router.navigateByUrl(`/gia/diploma`).then();
    if (this.url.includes('giaprotocol')) this.router.navigateByUrl(`/gia/giaprotocol`).then();
    if (this.url.includes('admissionorder')) this.router.navigateByUrl(`/gia/admissionorder`).then();
    if (this.url.includes('releaseorder')) this.router.navigateByUrl(`/gia/releaseorder`).then();

    this.currentStandartId = '';
  }

  public getDiplomasBook() {
    if (this.facultyId != null) {
      this.diplomaPrintService.getDiplomasBook(this.facultyId, this.year, this.trainingLevelId, this.studyFormId).subscribe({
        next: (value) => {
          DownloadFile(value);
        },
        error: (err) => {
          this.notificationService.showError(err.status === 404 ? 'Не найден шаблон книги выдачи или студенты с дипломами' : 'Не удалось скачать файл');
        },
      });
    }
  }

  public pageDiploma() {
    return this.url.includes('/diploma');
  }

  public isGraduateTrainingLevel(trainingLevelId: string) {
    return this.trainingLevels.find((x) => x.id == trainingLevelId)?.trainingLevelType === this.trainingLevelEnum.Graduate;
  }
}

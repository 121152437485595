<kendo-grid
  [data]="gridData"
  class="table"
  scrollable="virtual"
  [loading]="isLoading"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="table__loader">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column
    title="№"
    [width]="50"
    [class]="'table__cell'"
    [headerClass]="'table__header'"
  >
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">{{
      rowIndex + 1
    }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="ФИО"
    [width]="200"
    [class]="'table__cell table__cell--fio'"
    [headerClass]="'table__header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p
        [class]="{ 'table__cell--marked': dataItem.isMarked }"
        (click)="navigateTo(dataItem.studentId)"
      >
        {{ dataItem.fio }}
      </p></ng-template
    >
  </kendo-grid-column>
  <kendo-grid-column
    title="№ зачетной книжки"
    field="studentNumber"
    [headerClass]="'table__header'"
    [width]="80"
    [class]="'table__cell table__cell--custom--studentNumber'"
  ></kendo-grid-column>
  <kendo-grid-column
    field="studentBudget"
    title="Источник финансирования"
    [width]="80"
    [headerClass]="'table__header table__header--group table__header--vertical'"
    [class]="'table__cell'"
  >
    <ng-template kendoGridHeaderTemplate>
      <div class="table__subheader table__subheader--cursor-default">
        <p>Источник финансирования</p>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column-group
    title="{{ controlActionName }}"
    [headerClass]="'table__header'"
    *ngFor="let controlActionName of headerControlActions"
  >
    <kendo-grid-column
      [headerClass]="'table__header--group'"
      [width]="controlActions[controlActionName].length > 1 ? 60 : 80"
      [class]="'table__cell table__cell--custom'"
      *ngFor="let discipline of controlActions[controlActionName]"
    >
      <ng-template kendoGridHeaderTemplate>
        <div
          [class]="'table__subheader table__subheader--link'"
          (click)="
            openDiscipline(
              discipline.educationPlanDisciplineId,
              discipline.educationPlanElectiveDisciplineId
            )
          "
        >
          <p class="overflow">
            {{trimText((discipline.electiveName
            ? discipline.electiveName + " (" + discipline.name + ")"
            : discipline.name)
            + (discipline.isDistributed ? ", рассред." : ""))
            }}
          </p>
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <p style="white-space: pre-wrap"
          [class]="{
            table__cell: true,
            'table__cell--height100': true,
            'table__cell--gray': getImplemented(dataItem, discipline)
          }"
        >{{ getBallAndHours(dataItem, discipline) }}</p>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column
    field="average"
    title="Средний балл"
    [width]="80"
    [headerClass]="'table__header'"
    [class]="'table__cell'"
  ></kendo-grid-column>
</kendo-grid>

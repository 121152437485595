<contingent-header></contingent-header>

<div class="content">
  <kendo-stacklayout [gap]="15" orientation="horizontal">
    <!--1 box-->
    <div class="first">
      <div class="left-filter-container">
        <dl>

          <kendo-label text="Уровень подготовки"></kendo-label>
          <dd class="left-filter">
            <kendo-multiselect  [data]="trainingLevels"
                                textField="trainingLevelName"
                                valueField="dictTrainingLevelExternalId"
                                kendoMultiSelectSummaryTag
                                [autoClose]="false"
                                [valuePrimitive]="true"
                                name="trainingLevel"
                                (blur)="cleanTrainingLevel()"
                                (valueChange)="sideFilterValueChange()"
                                style="width:235px"
                                [(ngModel)]="filter.trainingLevel">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span *ngIf="this.filter.trainingLevel"> {{ dataItems.length }} выбрано </span>
              </ng-template>

              <ng-template kendoMultiSelectHeaderTemplate>
                          <span class="multiselect-all">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectTrainingLevel"
                              [indeterminate]="isIndet(multiselectEnum.trainingLevel)"
                              (click)="onClick(multiselectEnum.trainingLevel, true)"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
              </ng-template>

              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                              <input
                                type="checkbox"
                                id="chk-{{ dataItem.dictTrainingLevelExternalId }}"
                                kendoCheckBox
                                [checked]="isItemSelected(dataItem, multiselectEnum.trainingLevel)"
                              />
                          </span>
                <span class="multiselect-text"> {{ dataItem.trainingLevelName }}</span>
              </ng-template>
            </kendo-multiselect>
          </dd>

          <div class="contingent-date-filters">
            <kendo-label text="Контингент на дату:" class="params-list__label">
              <div class="params-list__date" *ngIf="!isEditDate">
                <kendo-icon name="calendar"></kendo-icon>
                <p kendoTypography variant="p" class="params-list__text">
                  {{ getToday(currentContingentDate) }}
                </p>
              </div>
              <ng-template [ngIf]="isEditDate">
                <kendo-datepicker
                  class="params-list__field"
                  calendarType="infinite"
                  [(ngModel)]="contingentDate"
                ></kendo-datepicker>
              </ng-template>
            </kendo-label>
            <kendo-label text="Оценки на дату:" class="params-list__label" hidden="true">
              <div class="params-list__date" *ngIf="!isEditDate">
                <kendo-icon name="calendar"></kendo-icon>
                <p kendoTypography variant="p" class="params-list__text">
                  {{ getToday(currentRaitingDate) }}
                </p>
              </div>
              <ng-template [ngIf]="isEditDate">
                <kendo-datepicker
                  class="params-list__field"
                  calendarType="infinite"
                  [(ngModel)]="raitingDate"
                ></kendo-datepicker>
              </ng-template>
            </kendo-label>
            <div class="buttons">
              <button
                kendoButton
                fillMode="outline"
                icon="edit"
                class="buttons__edit"
                *ngIf="!isEditDate"
                (click)="toggleEditDate()"
              >
                Изменить дату
              </button>
              <ng-template [ngIf]="isEditDate">
                <button
                  kendoButton
                  class="buttons__action"
                  (click)="toggleEditDate()"
                >
                  Отмена
                </button>
                <button
                  kendoButton
                  class="buttons__action buttons__action--ok"
                  (click)="acceptDate(true)"
                >
                  Ok
                </button>
              </ng-template>
            </div>
          </div>

          <div class="contingent-date-filters">
            <kendo-label text="Академические статусы"></kendo-label>
            <dd class="left-filter">
              <kendo-multiselect [data]="dictStudentAcademicStates"
                                 kendoMultiSelectSummaryTag
                                 placeholder="Академические статусы"
                                 [autoClose]="false"
                                 textField="academicStateName"
                                 valueField="dictStudentAcademicStateExternalId"
                                 [valuePrimitive]="true"
                                 (valueChange)="sideFilterValueChange()"
                                 name="dictStudentAcademicStateId"
                                 [(ngModel)]="filter.dictStudentAcademicStateId"
                                 style="width:235px">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span></span> {{ dataItems.length }} выбрано
                </ng-template>

                <ng-template kendoMultiSelectHeaderTemplate>
                          <span class="multiselect-all">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectAcademicState"
                              [indeterminate]="isIndet(multiselectEnum.academicState)"
                              (click)="onClick(multiselectEnum.academicState, true)"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
                </ng-template>

                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                              <input
                                type="checkbox"
                                id="chk-{{ dataItem.dictStudentAcademicStateExternalId }}"
                                kendoCheckBox
                                [checked]="isItemSelected(dataItem, multiselectEnum.academicState)"
                              />
                          </span>
                  <span class="multiselect-text"> {{ dataItem.academicStateName }}</span>
                </ng-template>

              </kendo-multiselect>
            </dd>
          </div>

          <div class="group-reload">
            <kendo-label text="Группы:" class="label"></kendo-label>
            <button
              kendoButton
              fillMode="outline"
              icon="reload"
              class="button"
              (click)="treeFill(filter.trainingLevel)">
            </button>
          </div>

          <dd class="left-filter">
            <kendo-stacklayout style="padding-bottom: 20px;" *ngIf="loadingTreeData" [align]="{ horizontal: 'center', vertical: 'middle' }">
              <kendo-loader size="medium" type="converging-spinner"></kendo-loader>
            </kendo-stacklayout>
            <kendo-treeview [nodes]="dataTree"
                            *ngIf="!loadingTreeData"
                            [children]="children"
                            [hasChildren]="hasChildren"
                            (nodeClick)="onNodeClick($event)"
                            textField="text" valueField="id"
                            kendoTreeViewCheckable
                            (selectionChange)="onSelectionChange($event)"
                            (checkedChange)="onCheckedChange()"
                            [(checkedKeys)]="checkedKeys"
                            [isChecked]="isChecked"
                            [kendoTreeViewCheckable]="checkableSettings"
                            [checkBy]="key" [loadOnDemand]="false"
                            kendoTreeViewExpandable
                            [(expandedKeys)]="expandedKeys"
                            [isExpanded]="isExpanded">
              <ng-template kendoTreeViewNodeTemplate let-dataItem>
                <span class="treeElement">
                  <span class="treeText" [ngClass]="{studentsAbsent: dataItem.studentCount === 0}"> {{ dataItem.text }} </span>
                  <span [ngClass]="iconClass()" *ngIf="dataItem.value === null" (click)="onOpenGroup()" ></span>
                </span>
                <kendo-button icon="plus" title="bfg"></kendo-button>
              </ng-template>
            </kendo-treeview>
            <button kendoButton size="small" class="unselected-button" (click)="treeCheckingClear()">
              Снять выбор
            </button>
          </dd>
        </dl>
      </div>
    </div>

    <!--2 box-->
    <div class="second">
      <div class="header">
        <h1>Контингент</h1>
      </div>
      <!--Tile Buttons-->
      <div kendoTooltip class="tilesselectedfilters">
        <button kendoButton icon="x" size="small" themeColor="light" rounded="full" *ngIf="(filter.smartSearch && filter.smartSearch?.length !== 0) && filters" (click)="oneFilterClean(36)">
          Умный поиск: {{filter.smartSearch}}
        </button>
        <!--FilterView-->
        <!--<button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(1)" *ngIf="filter.fio?.length != 0 && filters">
          ФИО: {{filter.fio}}
        </button>-->
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(2)" *ngIf="filter.isMale !== undefined && filters">
          Пол: {{filter.isMale == true ? "Мужской" : "Женский"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(3)" *ngIf="filter.citizenshipId && filter.citizenshipId.length !== 0 && filters">
          Гражданство: {{ filter.citizenshipId.length }} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(4)" *ngIf="(filter.ageBegin || filter.ageEnd) && filters">
          Дата рождения: с {{filter.ageBegin| kendoDate: "dd.MM.yyyy"}} по {{filter.ageEnd| kendoDate: "dd.MM.yyyy"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(29)" *ngIf="(filter.ageStart || filter.ageFinish) && filters">
          Возраст: с {{filter.ageStart}} по {{filter.ageFinish}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(5)" *ngIf="filter.regState && filter.regState.length !== 0 && filters">
          Регион регистрации: {{filter.regState.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(6)" *ngIf="filter.dictMilitaryStatusId && filters">
          Категория воинского учёта: {{nameMilitaryStatus(filter.dictMilitaryStatusId)?.militaryStatusName }}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(48)" *ngIf="filter.dictMilitaryEnlistmentStatusId && filters">
          Категория воинского учёта: {{nameMilitaryStatus(filter.dictMilitaryEnlistmentStatusId)?.militaryStatusName }}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(32)" *ngIf="(filter.commissariatId && filter.commissariatId.length !== 0) && filters">
          Военкомат: {{ filter.commissariatId.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(47)" *ngIf="(filter.militaryEnlistmentCommissariatId && filter.militaryEnlistmentCommissariatId.length !== 0) && filters">
          Военкомат: {{ filter.militaryEnlistmentCommissariatId.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(7)" *ngIf="filter.healthId && filters">
          Группа здоровья: {{namehealth(filter.healthId)?.healthName}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(30)" *ngIf="filter.benefit && filters">
          Льготы: {{ nameBenefit(filter.benefit)?.benefitName}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(8)" *ngIf="filter.graduateYear && filters">
          Год получения предыдущего образования: {{filter.graduateYear}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(9)" *ngIf="filter.orderType && filters">
          Тип категории приказа: {{ nameOrderType(filter.orderType)?.typeName}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(10)" *ngIf="filter.orderCategory && filters">
          Категория приказа: {{ nameOrderCategory(filter.orderCategory)?.categoryName}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(33)" *ngIf="(filter.orderNumber && filter.orderNumber?.length !== 0) && filters">
          Номер приказа: {{filter.orderNumber}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(11)" *ngIf="(filter.orderBegin || filter.orderEnd) && filters">
          Дата приказа: с {{filter.orderBegin| kendoDate: "dd.MM.yyyy"}} по {{filter.orderEnd| kendoDate: "dd.MM.yyyy"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(45)" *ngIf="(filter.orderStartBegin || filter.orderStartEnd) && filters">
          Начало действия приказа: с {{filter.orderStartBegin| kendoDate: "dd.MM.yyyy"}} по {{filter.orderStartEnd| kendoDate: "dd.MM.yyyy"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(37)" *ngIf="(filter.institution && filter.institution?.length !== 0) && filters">
          Наименование предыдущей учебной организации: {{filter.institution}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(38)" *ngIf="filter.thisInstitution !== undefined && filters">
          Предыдущее образование получено в этом учебном заведении: {{filter.thisInstitution ? "Да" : "Нет"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(39)" *ngIf="filter.secondHigherEducation !== undefined && filters">
          Получает второе высшее: {{filter.secondHigherEducation ? "Да" : "Нет"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(12)" *ngIf="(filter.trainingLevel && filter.trainingLevel.length !== 0) && filters">
          Уровень подготовки: {{filter.trainingLevel.length + " выбрано"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(13)" *ngIf="filter.dictStudentAcademicStateId && filter.dictStudentAcademicStateId.length !== 0 && filters">
          Академические статусы: {{ filter.dictStudentAcademicStateId.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(14)" *ngIf="filter.faculties && filter.faculties.length !== 0 && filters">
          Институт/факультет: {{ filter.faculties.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(15)" *ngIf="filter.dictStudyFormId && filter.dictStudyFormId.length !== 0 && filters">
          Форма обучения: {{ filter.dictStudyFormId.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(16)" *ngIf="filter.course && filter.course.length !== 0 && filters">
          Курс: {{filter.course.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(17)" *ngIf="filter.group && filter.group.length !== 0 && filters">
          Группы: {{filter.group.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(18)" *ngIf="(filter.educationStandard) && filters">
          Направление подготовки: {{ filter.educationStandard}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(28)" *ngIf="(filter.educationProgram) && filters">
          Профиль (программа): {{ nameEducationProgram(filter.educationProgram)}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(19)" *ngIf="filter.isBudgetSelected && filter.isBudgetSelected.length !== 0 && filters">
          Источник финансирования: {{filter.isBudgetSelected.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(40)" *ngIf="(filter.budgetCategory) && filters">
          Категория финансирования: {{ nameBudgetCategory(filter.budgetCategory)}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(41)" *ngIf="(filter.budgetSubCategory) && filters">
          Подкатегория финансирования: {{ nameBudgetSubCategory(filter.budgetSubCategory)}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(20)" *ngIf="(filter.studyStart || filter.studyFinish) && filters">
          Период обучения: с {{filter.studyStart}} по {{filter.studyFinish}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(31)" *ngIf="filter.elder && filters">
          Староста: {{filter.elder == true ? "Только старосты" : "Нет"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(43)" *ngIf="filter.employed !== undefined && filters">
          Трудоустроен к заказчику целевого: {{filter.employed ? "Да" : "Нет"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(44)" *ngIf="filter.map !== undefined && filters">
          Карта: {{filter.map ? "Включен в реестр" : "Не включен в реестр"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(26)" *ngIf="(filter.dissertationDateBegin || filter.dissertationDateEnd) && filters">
          Дата защиты диссертации: с {{filter.dissertationDateBegin| kendoDate: "dd.MM.yyyy"}} по {{filter.dissertationDateEnd | kendoDate: "dd.MM.yyyy"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(27)" *ngIf="(filter.approveVAKDateBegin || filter.approveVAKDateEnd) && filters">
          Дата утверждения ВАК: с {{filter.approveVAKDateBegin | kendoDate: "dd.MM.yyyy"}} по {{filter.approveVAKDateEnd | kendoDate: "dd.MM.yyyy"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(22)" *ngIf="filter.work !== undefined && filters">
          Ведет трудовую деятельность: {{filter.work ? "Да" : "Нет"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(23)" *ngIf="filter.organizations && filter.organizations.length !== 0 && filters">
          Заказчик целевого обучения и работодатели: {{ filter.organizations.length }} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(42)" *ngIf="filter.departments && filter.departments.length !== 0 && filters">
          Кафедра: {{ filter.departments.length }} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(21)" *ngIf="filter.scientificPersons && filter.scientificPersons.length !== 0 && filters">
          Научный руководитель (куратор): {{ filter.scientificPersons.length }} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(25)" *ngIf="filter.post && filter.post.length !== 0 && filters">
          Должность: {{ filter.post.length }} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(35)" *ngIf="filter.directionActivityId !== undefined && filter.directionActivityId.length !== 0 && filters">
          Категории достижений: {{filter.directionActivityId.length}} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(34)" *ngIf="filter.hasAchievements !== undefined && filters">
          Наличие достижений: {{filter.hasAchievements == true ? "Есть" : "Нет"}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(46)" *ngIf="filter.filial !== undefined && filters">
          Филиалы: {{ nameFilial(filter.filial)}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="light" rounded="full" *ngIf="filters" (click)="filterClean()">
          Очистить фильтры
        </button>
      </div>

      <div class="filter-container">
        <div class="page">
          <div class="content">

            <kendo-expansionpanel subtitle="Все фильтры" style="background-color:#fdfdfd; position: relative; z-index: 0;" [(expanded)]="expandedState">
              <ng-template kendoExpansionPanelTitleDirective>
                <h2>Все фильтры</h2>
                <kendo-label class="count">Студентов найдено: {{(count == null? "0" : count)}}</kendo-label>
              </ng-template>

              <div class="functionButtons filterButtonsUpper" *ngIf="!groupAll">
                <button kendoButton size="small" (click)="filterClean()">
                  Очистить
                </button>
                <button kendoButton size="small" themeColor="primary" (click)="filterHandler()">
                  Применить
                </button>
              </div>

              <div class="main-block" *ngIf="groupAll">
                <h2>Фильтры отключены настройками</h2>
              </div>

              <div class="main-block" *ngIf="groupPersonalData">
                <h2>Персональные данные</h2>
                <div class="filter">
                  <!--FIO-->
                  <!--<div *ngIf="fmodel.fio">
                    <h3>ФИО</h3>
                    <dd>
                      <kendo-textbox [clearButton]="true" name="fio" (keydown.enter)="filterHandler()" [(ngModel)]="filter.fio" placeholder="Введите ФИО для фильтра"></kendo-textbox>
                    </dd>
                  </div>-->
                  <div *ngIf="fmodel.isMale">
                    <kendo-label text="Пол"></kendo-label>
                    <dd>
                      <kendo-dropdownlist [data]="listIsMale"
                                          textField="text"
                                          valueField="value"
                                          (keydown.enter)="filterHandler()"
                                          [defaultItem]="defaultItem"
                                          [valuePrimitive]="true"
                                          name="isMale"
                                          [(ngModel)]="filter.isMale">
                      </kendo-dropdownlist>
                    </dd>
                  </div>


                  <div *ngIf="fmodel.citizenship">
                    <kendo-label text="Гражданство"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="citizenships"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Гражданство"
                                         [autoClose]="false"
                                         (keydown.enter)="filterHandler()"
                                         [kendoDropDownFilter]="filterSettings"
                                         textField="country"
                                         valueField="citizenshipExternalId"
                                         [valuePrimitive]="true"
                                         name="citizenshipId"
                                         (valueChange)="onValueChange()"
                                         [(ngModel)]="filter.citizenshipId">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>
                        <ng-template kendoMultiSelectHeaderTemplate>
                          <span class="multiselect-all">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelect"
                              [indeterminate]="isIndet(multiselectEnum.citizenship)"
                              (click)="onClick(multiselectEnum.citizenship)"
                            />
                            <kendo-label for="chk">{{ toggleAllText }}</kendo-label>
                          </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                            <kendo-label for="chk-{{ dataItem.citizenshipExternalId }}">
                              <input
                                type="checkbox"
                                id="chk-{{ dataItem.citizenshipExternalId }}"
                                kendoCheckBox
                                [checked]="isItemSelected(dataItem, multiselectEnum.citizenship)"
                              />
                            </kendo-label>
                          </span>
                          <span class="multiselect-text"> {{ dataItem.country }}</span>
                        </ng-template>
                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.agedate">
                    <kendo-label text="Дата рождения"></kendo-label>
                    <dd>
                      <kendo-datepicker class = 'date-filter-begin'
                                        format="dd.MM.yyyy"
                                        placeholder="с"
                                        (keydown.enter)="filterHandler()"
                                        #ageBegin
                                        [openOnFocus]="ageBegin"
                                        name="ageBegin"
                                        [(ngModel)]="filter.ageBegin">
                      </kendo-datepicker>
                      <kendo-datepicker format="dd.MM.yyyy"
                                        [incompleteDateValidation]="true"
                                        #ageEnd
                                        (keydown.enter)="filterHandler()"
                                        [openOnFocus]="ageEnd"
                                        placeholder="по"
                                        name="ageEnd"
                                        [(ngModel)]="filter.ageEnd">
                      </kendo-datepicker>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.age">
                    <kendo-label text="Возраст"></kendo-label>
                    <dd>
                      <kendo-numerictextbox (keydown.enter)="filterHandler()"
                                            class = 'date-filter-begin'
                                            placeholder="c"
                                            [min]="1" [format]="'#.##'" [max]="150"
                                            [maxlength]=3
                                            name="ageStartYear"
                                            [(ngModel)]="filter.ageStart">
                      </kendo-numerictextbox>
                      <kendo-numerictextbox (keydown.enter)="filterHandler()"
                                            [min]="filter.ageStart!" [format]="'#.##'" [max]="150"
                                            placeholder="по"
                                            [maxlength]=3
                                            name="ageFinishYear"
                                            [(ngModel)]="filter.ageFinish">
                      </kendo-numerictextbox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.regRegion">
                    <kendo-label text="Регион регистрации"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="dictStates"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Регион регистрации"
                                         [kendoDropDownFilter]="filterSettings"
                                         [autoClose]="false"
                                         [checkboxes]="true"
                                         (keydown.enter)="filterHandler()"
                                         textField="stateName"
                                         valueField="stateName"
                                         [valuePrimitive]="true"
                                         name="regState"
                                         [(ngModel)]="filter.regState">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>

                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.militaryCategory">
                    <kendo-label text="Категория воинского учёта"></kendo-label>
                    <dd>
                      <kendo-dropdownlist [data]="militaryStatuses"
                                          textField="militaryStatusName"
                                          valueField="dictMilitaryStatusExternalId"
                                          (keydown.enter)="filterHandler()"
                                          (valueChange)="onMilitaryStatusChange()"
                                          [valuePrimitive]="true"
                                          [defaultItem]="defaultItemMilitaryStatuses"
                                          name="dictMilitaryStatusId"
                                          [(ngModel)]="selectedDictMilitaryStatusId">
                      </kendo-dropdownlist>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.militaryCommissariat">
                    <kendo-label text="Военкомат"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="militaryCommissariats"
                                         kendoMultiSelectSummaryTag
                                         textField="commissariatName"
                                         valueField="id"
                                         (keydown.enter)="filterHandler()"
                                         (valueChange)="onCommissariatChange()"
                                         [autoClose]="false"
                                         placeholder="Военкомат"
                                         [kendoDropDownFilter]="filterSettings"
                                         [checkboxes]="true"
                                         [valuePrimitive]="true"
                                         name="dictCommisariatId"
                                         [(ngModel)]="selectedCommissariatId">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>
                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.health">
                    <kendo-label text="Группа здоровья"></kendo-label>
                    <dd>
                      <kendo-dropdownlist [defaultItem]="defaultItemHealth"
                                          [data]="healths"
                                          textField="healthName"
                                          valueField="dictHealthExternalId"
                                          (keydown.enter)="filterHandler()"
                                          [valuePrimitive]="true"
                                          name="healthId"
                                          [(ngModel)]="filter.healthId">
                      </kendo-dropdownlist>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.benefit">
                    <kendo-label text="Льготы"></kendo-label>
                    <dd>
                      <kendo-combobox
                        [kendoDropDownFilter]="filterSettings"
                        placeholder="Льготы"
                        class="input-main-addition-data"
                        [data]="benefits"
                        textField="benefitName"
                        [openOnFocus]="benefit"
                        #benefit
                        valueField="dictBenefitExternalId"
                        [valuePrimitive]="true"
                        name="dictBenefitId"
                        [(ngModel)]="filter.benefit">
                      </kendo-combobox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.graduateYear">
                    <kendo-label text="Год получения предыдущего образования"></kendo-label>
                    <dd>
                      <kendo-numerictextbox (keydown.enter)="filterHandler()"
                                            [min]="1950" [format]="'#.##'" [max]="2100"
                                            style="width:300px"
                                            [maxlength]=4
                                            name="graduateYear"
                                            [(ngModel)]="filter.graduateYear">
                      </kendo-numerictextbox>
                    </dd>
                  </div>

                </div>
              </div>

              <div class="main-block" *ngIf="groupEducation">
                <h2>Учеба</h2>
                <div class="filter">
                  <div *ngIf="fmodel.institution">
                    <kendo-label text="Наименование предыдущей учебной организации"></kendo-label>
                    <dd>
                      <kendo-textbox [clearButton]="true" name="institution" (keydown.enter)="filterHandler()" [(ngModel)]="filter.institution" placeholder=""></kendo-textbox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.thisInstitution">
                    <kendo-label text="Предыдущее образование получено в этом учебном заведении"></kendo-label>
                    <dd>
                      <kendo-dropdownlist [data]="employedList"
                                          textField="text"
                                          valueField="value"
                                          (keydown.enter)="filterHandler()"
                                          [defaultItem]="defaultItemElder"
                                          [valuePrimitive]="true"
                                          name="thisInstitution"
                                          [(ngModel)]="filter.thisInstitution">
                      </kendo-dropdownlist>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.secondEducation">
                    <kendo-label text="Получает второе высшее"></kendo-label>
                    <dd>
                      <kendo-dropdownlist [data]="employedList"
                                          textField="text"
                                          valueField="value"
                                          (keydown.enter)="filterHandler()"
                                          [defaultItem]="defaultItemElder"
                                          [valuePrimitive]="true"
                                          name="secondEducation"
                                          [(ngModel)]="filter.secondHigherEducation">
                      </kendo-dropdownlist>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.filial">
                    <kendo-label text="Филиал"></kendo-label>
                    <dd>
                      <kendo-combobox [data]="filials"
                                      textField="filialSName"
                                      valueField="dictFilialExternalId"
                                      #comboboxComponent
                                      placeholder="Филиал"
                                      (valueChange)="onFilialChange($event)"
                                      (keydown.enter)="filterHandler()"
                                      [openOnFocus]="comboboxComponent"
                                      [kendoDropDownFilter]="filterSettings"
                                      name="dictFilialExternalId"
                                      [valuePrimitive]="true"
                                      [(ngModel)]="filter.filial">
                      </kendo-combobox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.trainingLevel">
                    <kendo-label text="Уровень подготовки"></kendo-label>
                    <dd>
                      <kendo-multiselect  [data]="trainingLevels"
                                          kendoMultiSelectSummaryTag
                                          textField="trainingLevelName"
                                          valueField="dictTrainingLevelExternalId"
                                          (keydown.enter)="filterHandler()"
                                          [valuePrimitive]="true"
                                          name="trainingLevel"
                                          (blur)="cleanTrainingLevel()"
                                          (valueChange)="onTrainingLevelChange()"
                                          [(ngModel)]="filter.trainingLevel"
                                          [kendoDropDownFilter]="filterSettings"
                                          placeholder="Уровень подготовки"
                                          [autoClose]="false">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span *ngIf="this.filter.trainingLevel"> {{ dataItems.length }} выбрано </span>
                        </ng-template>

                        <ng-template kendoMultiSelectHeaderTemplate>
                          <span class="multiselect-all">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectTrainingLevel"
                              [indeterminate]="isIndet(multiselectEnum.trainingLevel)"
                              (click)="onClick(multiselectEnum.trainingLevel, false)"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                              <input
                                type="checkbox"
                                id="chk-{{ dataItem.dictTrainingLevelExternalId }}"
                                kendoCheckBox
                                [checked]="isItemSelected(dataItem, multiselectEnum.trainingLevel)"
                              />
                          </span>
                          <span class="multiselect-text"> {{ dataItem.trainingLevelName }}</span>
                        </ng-template>
                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.academicState">
                    <kendo-label text="Академические статусы"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="dictStudentAcademicStates"
                                         kendoMultiSelectSummaryTag
                                         (keydown.enter)="filterHandler()"
                                         valueField="dictStudentAcademicStateExternalId"
                                         textField="academicStateName"
                                         [kendoDropDownFilter]="filterSettings"
                                         [valuePrimitive]="true"
                                         (valueChange)="sideFilterValueChange()"
                                         name="dictStudentAcademicStateId"
                                         [autoClose]="false"
                                         placeholder="Академические статусы"
                                         [(ngModel)]="filter.dictStudentAcademicStateId">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>

                        <ng-template kendoMultiSelectHeaderTemplate>
                          <span class="multiselect-all">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectAcademicState"
                              [indeterminate]="isIndet(multiselectEnum.academicState)"
                              (click)="onClick(multiselectEnum.academicState, false)"
                            />
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                              <input
                                type="checkbox"
                                id="chk-{{ dataItem.dictStudentAcademicStateExternalId }}"
                                kendoCheckBox
                                [checked]="isItemSelected(dataItem, multiselectEnum.academicState)"
                              />
                          </span>
                          <span class="multiselect-text"> {{ dataItem.academicStateName }}</span>
                        </ng-template>

                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.faculty">
                    <kendo-label text="Институт/факультет"></kendo-label>
                    <dd>
                      <label *ngIf="disableEduFilters" class="info">Отмените выбор в дереве для фильтрации</label>
                      <kendo-multiselect [data]="filteredFaculties"
                                         kendoMultiSelectSummaryTag
                                         [disabled]="disableEduFilters"
                                         textField="shortName"
                                         valueField="facultyExternalId"
                                         (keydown.enter)="filterHandler()"
                                         [autoClose]="false"
                                         placeholder="Институт/факультет"
                                         [kendoDropDownFilter]="filterSettings"
                                         [checkboxes]="true"
                                         [valuePrimitive]="true"
                                         (valueChange)="onDepartmentChange()"
                                         name="selectdepartment"
                                         [(ngModel)]="filter.faculties">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>
                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.studyForm">
                    <kendo-label text="Форма обучения"></kendo-label>
                    <dd>
                      <label *ngIf="disableEduFilters" class="info">Отмените выбор в дереве для фильтрации</label>
                      <kendo-multiselect [disabled]="disableEduFilters"
                                         [data]="dictStudyForms"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Форма обучения"
                                         [kendoDropDownFilter]="filterSettings"
                                         [autoClose]="false" [checkboxes]="true"
                                         textField="studyFormName"
                                         valueField="dictStudyFormExternalId"
                                         (keydown.enter)="filterHandler()"
                                         [valuePrimitive]="true"
                                         name="dictStudyFormId"
                                         (valueChange)="onStudyFormChange()"
                                         [(ngModel)]="filter.dictStudyFormId">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>
                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.course">
                    <kendo-label text="Курс"></kendo-label>
                    <dd>
                      <label *ngIf="disableEduFilters" class="info">Отмените выбор в дереве для фильтрации</label>
                      <kendo-multiselect [disabled]="disableEduFilters"
                                         [data]="courses"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Курс"
                                         textField="course"
                                         valueField="courseNum"
                                         (keydown.enter)="filterHandler()"
                                         [autoClose]="false"
                                         [checkboxes]="true"
                                         [kendoDropDownFilter]="filterSettings"
                                         [valuePrimitive]="true" name="course"
                                         (valueChange)="onCourseChange()"
                                         [(ngModel)]="filter.course">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>
                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.eduGroup">
                    <kendo-label text="Группа"></kendo-label>
                    <dd>
                      <label *ngIf="disableEduFilters" class="info">Отмените выбор в дереве для фильтрации</label>
                      <form #form="ngForm">
                        <kendo-multiselect [disabled]="disableEduFilters"
                                           [data]="departSelectEduGroup"
                                           kendoMultiSelectSummaryTag
                                           placeholder="Группа"
                                           textField="groupName"
                                           valueField="eduGroupExternalId"
                                           (keydown.enter)="filterHandler()"
                                           [autoClose]="false"
                                           [checkboxes]="true"
                                           [kendoDropDownFilter]="filterSettings"
                                           [valuePrimitive]="true"
                                           name="group"
                                           (valueChange)="onGroupChange()"
                                           [(ngModel)]="filter.group">
                          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                            <span></span> {{ dataItems.length }} выбрано
                          </ng-template>
                        </kendo-multiselect>
                      </form>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.educationWay">
                    <kendo-label text="Направление подготовки"></kendo-label>
                    <dd>
                      <kendo-combobox [data]="selectionEducationStandard"
                                      textField="standardFullName"
                                      valueField="standardFullName"
                                      #comboboxComponent
                                      placeholder="Направление подготовки"
                                      (keydown.enter)="filterHandler()"
                                      [openOnFocus]="comboboxComponent"
                                      [kendoDropDownFilter]="filterSettings"
                                      name="ordertype"
                                      [valuePrimitive]="true"
                                      (valueChange)="onStandardChange($event)"
                                      [(ngModel)]="filter.educationStandard">
                      </kendo-combobox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.educationProgram">
                    <kendo-label text="Профиль (программа)"></kendo-label>
                    <dd>
                      <kendo-combobox [data]="selectEducationProgram"
                                      textField="name"
                                      valueField="externalId"
                                      #comboboxComponent
                                      placeholder="Профиль (программа)"
                                      (keydown.enter)="filterHandler()"
                                      [openOnFocus]="comboboxComponent"
                                      [kendoDropDownFilter]="filterSettings"
                                      name="educationProgram"
                                      [valuePrimitive]="true"
                                      [(ngModel)]="filter.educationProgram">
                      </kendo-combobox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.isBudget">
                    <kendo-label text="Источник финансирования"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="filteredBudgets"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Источник финансирования"
                                         textField="studentBudgetName"
                                         valueField="dictStudentBudgetExternalId"
                                         (keydown.enter)="filterHandler()"
                                         (valueChange)="onBudgetChange($event)"
                                         [autoClose]="false"
                                         [kendoDropDownFilter]="filterSettings"
                                         [valuePrimitive]="true"
                                         name="studentBudget"
                                         [(ngModel)]="filter.isBudgetSelected">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>

                        <ng-template kendoMultiSelectHeaderTemplate>
                          <span class="multiselect-all">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectBudgetState"
                              [indeterminate]="isIndet(multiselectEnum.budget)"
                              (click)="onClick(multiselectEnum.budget)"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                            <kendo-label for="chk-{{ dataItem.dictStudentBudgetExternalId }}">
                              <input
                                type="checkbox"
                                id="chk-{{ dataItem.dictStudentBudgetExternalId }}"
                                kendoCheckBox
                                [checked]="isItemSelected(dataItem, multiselectEnum.budget)"/>
                            </kendo-label>
                          </span>
                          <span class="multiselect-text"> {{ dataItem.studentBudgetName }}</span>
                        </ng-template>

                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.budgetCategory">
                    <kendo-label text="Категория финансирования"></kendo-label>
                    <dd>
                      <kendo-combobox [data]="selectedBudgetCategories"
                                      textField="categoryName"
                                      valueField="dictStudentBudgetCategoryExternalId"
                                      #comboboxComponent
                                      placeholder="Категория финансирования"
                                      (valueChange)="onBudgetCategoryChange($event)"
                                      (keydown.enter)="filterHandler()"
                                      [openOnFocus]="comboboxComponent"
                                      [kendoDropDownFilter]="filterSettings"
                                      name="budgetCategory"
                                      [valuePrimitive]="true"
                                      [(ngModel)]="filter.budgetCategory">
                      </kendo-combobox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.budgetSubCategory">
                    <kendo-label text="Подкатегория финансирования"></kendo-label>
                    <dd>
                      <kendo-combobox
                        [data]="selectedBudgetSubCategories"
                        textField="budgetSubCategoryName"
                        valueField="dictStudentBudgetSubCategoryExternalId"
                        #comboboxComponent
                        placeholder="Подкатегория финансирования"
                        (keydown.enter)="filterHandler()"
                        [openOnFocus]="comboboxComponent"
                        [kendoDropDownFilter]="filterSettings"
                        name="budgetSubCategory"
                        [valuePrimitive]="true"
                        [(ngModel)]="filter.budgetSubCategory">
                      </kendo-combobox>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.employer">
                    <kendo-label text="Заказчик целевого обучения и работодатели"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="organizations"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Заказчик ц/о и работодатели"
                                         textField="organizationName"
                                         valueField="dictOrganizationExternalId"
                                         (keydown.enter)="filterHandler()"
                                         [autoClose]="false"
                                         [kendoDropDownFilter]="filterSettings"
                                         [valuePrimitive]="true"
                                         name="organizationId"
                                         [(ngModel)]="filter.organizations">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>

                        <ng-template kendoMultiSelectHeaderTemplate>
                          <span style="margin-left:11px">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectOrganization"
                              [indeterminate]="isIndet(multiselectEnum.organization)"
                              (click)="onClick(multiselectEnum.organization, false)"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                            <input
                              type="checkbox"
                              id="chk-{{ dataItem.id }}"
                              kendoCheckBox
                              [checked]="isItemSelected(dataItem, multiselectEnum.organization)"/>
                          </span>
                          <span style="color: #292929"> {{ dataItem.organizationName }}</span>
                        </ng-template>

                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.departments">
                    <kendo-label text="Кафедра"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="departments"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Кафедра"
                                         textField="name"
                                         valueField="externalId"
                                         (keydown.enter)="filterHandler()"
                                         [autoClose]="false"
                                         [kendoDropDownFilter]="filterSettings"
                                         [valuePrimitive]="true"
                                         name="department"
                                         [(ngModel)]="filter.departments">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>

                        <ng-template kendoMultiSelectHeaderTemplate>
                          <span style="margin-left:11px">
                            <input
                              type="checkbox"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectDepartment"
                              [indeterminate]="isIndet(multiselectEnum.department)"
                              (click)="onClick(multiselectEnum.department, false)"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                            <input
                              type="checkbox"
                              id="chk-{{ dataItem.id }}"
                              kendoCheckBox
                              [checked]="isItemSelected(dataItem, multiselectEnum.department)"/>
                          </span>
                          <span style="color: #292929"> {{ dataItem.name }}</span>
                        </ng-template>

                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.scientificPerson">
                    <kendo-label text="Научный руководитель (куратор)"></kendo-label>
                    <dd>
                      <kendo-multiselect [data]="persons"
                                         kendoMultiSelectSummaryTag
                                         placeholder="Научный руководитель (куратор)"
                                         textField="fio"
                                         valueField="personExternalId"
                                         (keydown.enter)="filterHandler()"
                                         [autoClose]="false"
                                         [kendoDropDownFilter]="filterSettings"
                                         [valuePrimitive]="true"
                                         name="scientificPersonId"
                                         [(ngModel)]="filter.scientificPersons">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span></span> {{ dataItems.length }} выбрано
                        </ng-template>

                        <ng-template kendoMultiSelectHeaderTemplate>
                          <span style="margin-left:11px">
                            <input
                              type="checkbox"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectScientific"
                              [indeterminate]="isIndet(multiselectEnum.scientificPerson)"
                              (click)="onClick(multiselectEnum.scientificPerson, false)"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
                        </ng-template>

                        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                            <input
                              type="checkbox"
                              id="chk-{{ dataItem.id }}"
                              kendoCheckBox
                              [checked]="isItemSelected(dataItem, multiselectEnum.scientificPerson)"/>
                          </span>
                          <span style="color: #292929"> {{ dataItem.fio }}</span>
                        </ng-template>

                      </kendo-multiselect>
                    </dd>
                  </div>

                  <div *ngIf="fmodel.dissertationDate">
                    <kendo-label text="Дата защиты диссертации"></kendo-label>
                    <dd>
                      <kendo-datepicker
                        format="dd.MM.yyyy"
                        placeholder="c"
                        #disBegin
                        class = 'date-filter-begin'
                        (keydown.enter)="filterHandler()"
                        [openOnFocus]="disBegin"
                        name="disBegin"
                        [(ngModel)]="filter.dissertationDateBegin">
                      </kendo-datepicker>
                      <kendo-datepicker
                        format="dd.MM.yyyy"
                        placeholder="по"
                        #disEnd
                        (keydown.enter)="filterHandler()"
                        [openOnFocus]="disEnd"
                        name="disEnd"
                        [(ngModel)]="filter.dissertationDateEnd">
                      </kendo-datepicker>
                    </dd>
                  </div>

                        <div *ngIf="fmodel.finishYear">
                          <kendo-label text="Год начала обучения  &nbsp;   Год выпуска"></kendo-label>
                          <dd>
                            <kendo-numerictextbox placeholder="с"
                                                  [min]="2000"
                                                  [format]="'#.##'"
                                                  [style.width.px]="160"
                                                  [max]="2100"
                                                  (keydown.enter)="filterHandler()"
                                                  class = 'date-filter-begin'
                                                  [maxlength]=4 name="studyStart"
                                                  [(ngModel)]="filter.studyStart">
                            </kendo-numerictextbox>

                            <kendo-numerictextbox placeholder="по"
                                                  [min]="2000"
                                                  [format]="'#.##'"
                                                  [style.width.px]="130"
                                                  [max]="2100"
                                                  [maxlength]=4
                                                  (keydown.enter)="filterHandler()"
                                                  name="studyFinish"
                                                  [(ngModel)]="filter.studyFinish">
                            </kendo-numerictextbox>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.elder">
                          <kendo-label text="Староста"></kendo-label>
                          <dd>
                            <kendo-dropdownlist [data]="elderList"
                                                textField="text"
                                                valueField="value"
                                                (keydown.enter)="filterHandler()"
                                                [defaultItem]="defaultItemElder"
                                                [valuePrimitive]="true"
                                                name="elder"
                                                [(ngModel)]="filter.elder">
                            </kendo-dropdownlist>
                          </dd>
                        </div>

                  <div *ngIf="fmodel.employed">
                    <kendo-label text="Трудоустроен к заказчику целевого обучения"></kendo-label>
                    <dd>
                      <kendo-dropdownlist [data]="employedList"
                                          textField="text"
                                          valueField="value"
                                          (keydown.enter)="filterHandler()"
                                          [defaultItem]="defaultItemElder"
                                          [valuePrimitive]="true"
                                          name="employed"
                                          [(ngModel)]="filter.employed">
                      </kendo-dropdownlist>
                    </dd>
                  </div>
                  <div *ngIf="fmodel.map">
                    <kendo-label text="Карты"></kendo-label>
                    <dd>
                      <kendo-dropdownlist [data]="mapList"
                                          textField="text"
                                          valueField="value"
                                          (keydown.enter)="filterHandler()"
                                          [defaultItem]="defaultItemElder"
                                          [valuePrimitive]="true"
                                          name="map"
                                          [(ngModel)]="filter.map">
                      </kendo-dropdownlist>
                    </dd>
                  </div>

                      </div>
                    </div>

                    <div class="main-block" *ngIf="groupOther">
                      <h2>Прочее</h2>
                      <div class="filter">
                        <div *ngIf="fmodel.orderType">
                          <kendo-label text="Тип категории приказа"></kendo-label>
                          <dd>
                            <kendo-combobox [data]="selectOrderTypes"
                                            textField="typeName"
                                            valueField="dictOrderTypeExternalId"
                                            #comboboxComponent
                                            placeholder="Тип категории приказа"
                                            [openOnFocus]="comboboxComponent"
                                            [kendoDropDownFilter]="filterSettings"
                                            (keydown.enter)="filterHandler()"
                                            (valueChange)="onTypeChange($event)"
                                            name="ordertype" [valuePrimitive]="true"
                                            [(ngModel)]="filter.orderType">
                            </kendo-combobox>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.orderCategory">
                          <kendo-label text="Категория приказа"></kendo-label>
                          <dd>
                            <kendo-dropdownlist [data]="selectOrderCategories"
                                                textField="categoryName"
                                                valueField="dictOrdersCategoryExternalId"
                                                [valuePrimitive]="true"
                                                [defaultItem]="defaultItemCategory"
                                                name="ordercategory"
                                                (keydown.enter)="filterHandler()"
                                                [(ngModel)]="filter.orderCategory">
                            </kendo-dropdownlist>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.orderNumber">
                          <kendo-label text="Номер приказа"></kendo-label>
                          <dd>
                            <kendo-textbox [clearButton]="true" name="orderNumber" (keydown.enter)="filterHandler()" [(ngModel)]="filter.orderNumber" placeholder="Введите номер приказа"></kendo-textbox>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.orderDate">
                          <kendo-label text="Дата приказа"></kendo-label>
                          <dd>
                            <kendo-datepicker format="dd.MM.yyyy"
                                              placeholder="с"
                                              #orderBegin
                                              (keydown.enter)="filterHandler()"
                                              [openOnFocus]="orderBegin"
                                              name="orderBegin"
                                              [(ngModel)]="filter.orderBegin"
                                              class = 'date-filter-begin'>
                            </kendo-datepicker>
                            <kendo-datepicker format="dd.MM.yyyy"
                                              #orderEnd
                                              (keydown.enter)="filterHandler()"
                                              [openOnFocus]="orderEnd"
                                              placeholder="по"
                                              name="orderEnd"
                                              [(ngModel)]="filter.orderEnd">
                            </kendo-datepicker>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.orderStartDate">
                          <kendo-label text="Начало действия приказа"></kendo-label>
                          <dd>
                            <kendo-datepicker format="dd.MM.yyyy"
                                              placeholder="с"
                                              #orderStartBegin
                                              (keydown.enter)="filterHandler()"
                                              [openOnFocus]="orderStartBegin"
                                              name="orderStartBegin"
                                              [(ngModel)]="filter.orderStartBegin"
                                              class = 'date-filter-begin'>
                            </kendo-datepicker>
                            <kendo-datepicker format="dd.MM.yyyy"
                                              #orderStartEnd
                                              (keydown.enter)="filterHandler()"
                                              [openOnFocus]="orderStartEnd"
                                              placeholder="по"
                                              name="orderStartEnd"
                                              [(ngModel)]="filter.orderStartEnd">
                            </kendo-datepicker>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.approveVAKDate">
                          <kendo-label text="Дата утверждения ВАК"></kendo-label>
                          <dd>
                            <kendo-datepicker
                              format="dd.MM.yyyy"
                              placeholder="c"
                              #vakBegin
                              class = 'date-filter-begin'
                              (keydown.enter)="filterHandler()"
                              [openOnFocus]="vakBegin"
                              name="vakBegin"
                              [(ngModel)]="filter.approveVAKDateBegin">
                            </kendo-datepicker>
                            <kendo-datepicker
                              format="dd.MM.yyyy"
                              placeholder="по"
                              #vakEnd
                              (keydown.enter)="filterHandler()"
                              [openOnFocus]="vakEnd"
                              name="vakEnd"
                              [(ngModel)]="filter.approveVAKDateEnd">
                            </kendo-datepicker>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.isWork">
                          <kendo-label text="Ведет трудовую деятельность"></kendo-label>
                          <dd>
                            <kendo-dropdownlist [data]="employedList"
                                                textField="text"
                                                valueField="value"
                                                (keydown.enter)="filterHandler()"
                                                [defaultItem]="defaultItemHasAchievements"
                                                [valuePrimitive]="true"
                                                name="isWork"
                                                [(ngModel)]="filter.work">
                            </kendo-dropdownlist>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.post">
                          <kendo-label text="Должность"></kendo-label>
                          <dd>
                            <kendo-multiselect
                              [data]="employerPosts"
                              kendoMultiSelectSummaryTag
                              placeholder="Должность"
                              textField="postName"
                              valueField="externalId"
                              (keydown.enter)="filterHandler()"
                              [autoClose]="false"
                              [kendoDropDownFilter]="filterSettings"
                              [valuePrimitive]="true"
                              name="postId"
                              [(ngModel)]="filter.post">
                              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span> {{ dataItems.length }} выбрано
                              </ng-template>

                              <ng-template kendoMultiSelectHeaderTemplate>
                                <span style="margin-left:11px">
                                  <input
                                    type="checkbox"
                                    kendoCheckBox
                                    [checked]="isCheckedMultiSelectScientific"
                                    [indeterminate]="isIndet(multiselectEnum.post)"
                                    (click)="onClick(multiselectEnum.post, false)"/>
                                  <kendo-label for="chk">{{'Все'}}</kendo-label>
                                </span>
                              </ng-template>
                              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                <span>
                                  <input
                                    type="checkbox"
                                    id="chk-{{ dataItem.id }}"
                                    kendoCheckBox
                                    [checked]="isItemSelected(dataItem, multiselectEnum.post)"/>
                                </span>
                                <span style="color: #292929"> {{ dataItem.postName }}</span>
                              </ng-template>

                            </kendo-multiselect>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.hasAchievements">
                          <kendo-label text="Наличие достижений"></kendo-label>
                          <dd>
                            <kendo-dropdownlist [data]="listHasAchievements"
                                                textField="text"
                                                valueField="value"
                                                (keydown.enter)="filterHandler()"
                                                [defaultItem]="defaultItemHasAchievements"
                                                [valuePrimitive]="true"
                                                name="hasAchievements"
                                                (valueChange)="onHasAcievementChange()"
                                                [(ngModel)]="filter.hasAchievements">
                            </kendo-dropdownlist>
                          </dd>
                        </div>

                        <div *ngIf="fmodel.dictAchievements">
                          <kendo-label text="Категория достижений"></kendo-label>
                          <dd>
                            <kendo-multiselect [data]="filteredDictAchievements"
                                               kendoMultiSelectSummaryTag
                                               placeholder="Категория достижений"
                                               textField="name"
                                               valueField="id"
                                               (keydown.enter)="filterHandler()"
                                               (valueChange)="onAcievementChange()"
                                               [autoClose]="false"
                                               [kendoDropDownFilter]="filterSettings"
                                               [valuePrimitive]="true"
                                               name="directionActivity"
                                               [(ngModel)]="filter.directionActivityId">
                              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span> {{ dataItems.length }} выбрано
                              </ng-template>

                              <ng-template kendoMultiSelectHeaderTemplate>
                                <span style="margin-left:11px">
                                  <input
                                    type="checkbox"
                                    id="chk"
                                    kendoCheckBox
                                    [checked]="isCheckedMultiSelectDirectionActivity"
                                    [indeterminate]="isIndet(multiselectEnum.dictAchievements)"
                                    (click)="onClick(multiselectEnum.dictAchievements, false)"/>
                                  <kendo-label for="chk">{{'Все'}}</kendo-label>
                                </span>
                              </ng-template>

                              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                <span>
                                  <input
                                    type="checkbox"
                                    id="chk-{{ dataItem.id }}"
                                    kendoCheckBox
                                    [checked]="isItemSelected(dataItem, multiselectEnum.dictAchievements)"/>
                                </span>
                                <span style="color: #292929"> {{ dataItem.name }}</span>
                              </ng-template>

                            </kendo-multiselect>
                          </dd>
                        </div>

                      </div>
                    </div>
                    <!-- <div class="main-block" *ngIf="group4">
                            <h2>Научная деятельность</h2>
                                          <div class="filter">

                                            <div *ngIf="fmodel.vakYear"> -->
              <!--Дата утверждения ВАК-->
              <!-- <h3>Дата утверждения ВАК</h3>
                                        <dd>
                                             <kendo-datepicker
                                                  format="dd.MM.yyyy"
                                                  placeholder=""
                                                  name="approveVAKDate"
                                                  #approveVAKDate
                                                  [openOnFocus]="approveVAKDate"
                                                  [(ngModel)]="filter.approveVAKDate"
                                                  style="width:250px">
                                             </kendo-datepicker>
                                        </dd>
                                      </div>
                                </div>
                            </div> -->
              <!--<div class="main-block" *ngIf="group5">
                              <h2>Сведения о трудовой деятельности</h2>
                                            <div class="filter">

                                          <div *ngIf="fmodel.isWork">
                                                <h3>Ведет трудовую деятельность</h3>
                                                <dd>
                                                        <kendo-dropdownlist [data]="listIsWork"
                                                                            textField="text"
                                                                            valueField="value"
                                                                            [defaultItem]="defaultItemIsMale"
                                                                            [valuePrimitive]="true"
                                                                            name="work"
                                                                            [(ngModel)]="filter.work">
                                                        </kendo-dropdownlist>
                                                </dd>
                                          </div>
                                        <div *ngIf="fmodel.post">
                                          <h3>Должность</h3>
                                          <dd>
                                                <kendo-dropdownlist [data]="listIsPost"
                                                                    textField="text"
                                                                    valueField="value"
                                                                    [defaultItem]="defaultItemIsPost"
                                                                    [valuePrimitive]="true"
                                                                    name="post"
                                                                    [(ngModel)]="filter.post"
                                                                    >
                                                </kendo-dropdownlist>
                                          </dd>
                                    </div>
                                  </div>
                            </div>-->
              <!--Buttons-->
              <div class="functionButtons filterButtons" *ngIf="!groupAll">
                <button kendoButton size="small" (click)="filterClean()">
                  Очистить
                </button>
                <button kendoButton size="small" themeColor="primary" (click)="filterHandler()">
                  Применить
                </button>
              </div>
            </kendo-expansionpanel>
            <kendo-expansionpanel subtitle="Сохраненные наборы фильтров" [(expanded)]="expandedSetState" style="position: relative; z-index: 0;">
              <ng-template kendoExpansionPanelTitleDirective>
                <h2>Сохраненные наборы фильтров</h2>
              </ng-template>

              <div *ngIf="newFilter" class="functionButtons" style="margin-bottom: 10px">
                <button kendoButton *ngIf="newFilter" icon="plus" style="float:right" size="small" (click)="addFilter()">
                  Добавить новый фильтр
                </button>
              </div>

              <div class="contingent-saved-filter-block">
                <div *ngIf="newFilter" class="savedFilter">
                  <kendo-label text="Выбрать сохраненный набор фильтров" class="label"></kendo-label>
                  <kendo-combobox [data]="savedFilters"
                                  placeholder="Не выбрано"
                                  #comboboxSaveFilter
                                  [openOnFocus]="comboboxSaveFilter"
                                  [(ngModel)]="selectSavedFilterName"
                                  class="contingent-saved-filters">
                  </kendo-combobox>
                  <button kendoButton themeColor="light" icon="edit" (click)="filterEditHandler()"></button>
                  <button kendoButton themeColor="light" icon="delete" (click)="filterRemoveHandler()"></button>
                </div>
                <div *ngIf="!newFilter" class="savedFilter">
                  <kendo-label text="Сохранить название нового набора фильтров" class="label"></kendo-label>
                  <kendo-textbox class="contingent-save-filters"
                                 [clearButton]="true"
                                 name="savedFilterName"
                                 [(ngModel)]="savedFilterName"
                                 placeholder="Название нового набора фильтров"></kendo-textbox>
                </div>
                <div *ngIf="newFilter" class="functionButtons">
                  <button kendoButton size="small" class="filterButtons" themeColor="primary" (click)="filterSetHandler()">
                    Применить
                  </button>
                  <button kendoButton size="small" class="filterButtons" (click)="cleanSelectedFilters()">
                    Очистить
                  </button>
                </div>
                <div *ngIf="!newFilter" class="functionButtons">
                  <button kendoButton size="small" [disabled]="savedFilterName==''" class="filterButtons" themeColor="primary" (click)="filterSaveHandler()">
                    Сохранить
                  </button>
                  <button kendoButton size="small" class="filterButtons" (click)="filterCloseHandler()">
                    Отмена
                  </button>
                </div>
              </div>
            </kendo-expansionpanel>
          </div>
        </div>
      </div>

      <div class="mass-operation" *ngIf="isInOperationMode">
        <h4>Массовые операции</h4>
        <kendo-label class="left-float student-selection-count"
                     *ngIf="!selection">
          Студентов в выборке: {{(markedStudentSelection.length == 0 ? "0" : markedStudentSelection.length)}}
        </kendo-label>
      </div>

      <div class="functionButtons" *ngIf="!gridHide" style="margin-bottom:6px">
        <button kendoButton icon="plus" size="small" themeColor="success" *ngIf="!isInOperationMode && editable" [routerLink]="['/contingent/addstudent']">
          Добавить студента
        </button>

        <button kendoButton type="button" icon="download" size="small" *ngIf="!isInOperationMode" (click)="exportToExcel(grid)">
          <kendo-loader *ngIf="loaderExcelExport" size="small" type="converging-spinner"> </kendo-loader>
          Экспорт в Excel
        </button>

        <button kendoButton icon="check" size="small" *ngIf="isInOperationMode" themeColor="success" (click)="selectMarked()">
          Добавить к выборке
        </button>

        <button kendoButton icon="redo" size="small" [routerLink]="['/contingent/contingentselection']" [state]="{markedStudentSelection}"
                [disabled]="!markedStudentSelection.length" *ngIf="isInOperationMode&&editable"
                themeColor="primary" (click)="saveContingentSettings()">
          Перейти к выборке
        </button>

        <button kendoButton icon="apply-format" size="small" [disabled]="!markedStudentSelection.length"
                *ngIf="isInOperationMode" (click)="cleanMarked()">
          Очистить
        </button>

        <button kendoButton type="button" icon="file-excel" size="small" [disabled]="!markedStudentSelection.length"
                *ngIf="isInOperationMode" (click)="exportToSelected(grid)">
          Экспорт
        </button>
        <kendo-grid-spacer></kendo-grid-spacer>

        <button kendoButton size="small" (click)="massOperation()" *ngIf="!isInOperationMode" style="position:relative; float:right" class="no-margin">
          Режим массовых операций
        </button>

        <button kendoButton icon="close" size="small" *ngIf="isInOperationMode" (click)="close()" class="no-margin" style="position:relative; float:right">
          Закрыть
        </button>

        <button kendoButton icon="folder-open" size="small" [routerLink]="['/contingent/contingentjournal']" *ngIf="isInOperationMode&&editable" class="right-float" style="text-align:right">
          Журнал
        </button>
      </div>

      <div class="second-function-row">
        <kendo-textbox class="smart-search" placeholder="Поиск по ФИО, идентификатору, зач. книжке, e-mail, тел., ИНН, СНИЛС"
                       (keydown.enter)="filterHandler()" [clearButton]="true" [(ngModel)]="filter.smartSearch"
                       (valueChange)="onChangeSmartSearch($event)">
          <ng-template kendoTextBoxSuffixTemplate>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton class="svg" fillMode="flat" size="small" icon="search" (click)="filterHandler()"></button>
          </ng-template>
        </kendo-textbox>

        <div class="outlineButtons filterButtonsUpper right">
          <button kendoButton fillMode="outline" size="small" *ngIf="!isInOperationMode" (click)="columnClean()">
            Сбросить
          </button>

          <button kendoButton fillMode="outline" size="small" icon="plus" [disabled]="gridHide" *ngIf="!isInOperationMode" (click)="listHideHandler()">
            Настроить список
            <span class="k-icon k-i-gear" style="padding-bottom: 2px;"></span>
          </button>
        </div>
      </div>

      <!------------------------------------------------------------------------------->
      <!----------------------------------GRID----------------------------------------->
      <!------------------------------------------------------------------------------->
      <div class="content-container" *ngIf="gridHide" name="gridhide">

        <div class="content-listbox">
          <!--Поля для отображения-->
          <kendo-listbox kendoListBoxDataBinding
                         [data]="tempHiddenColumns"
                         size='small'
                         [connectedWith]="list"
                         style="margin-left: 20px"
                         [toolbar]="toolbarSettingsFirst"
                         (actionClick)="listBoxChange($event)"
                          >
          </kendo-listbox>
          <kendo-listbox #list [data]="tempVisibleColumns" [toolbar]="false" size='small' style="margin-left: 12px">
          </kendo-listbox>
        </div>

        <!--Buttons-->
        <div class="functionButtons filterButtons">
          <button kendoButton size="small" (click)="listBoxClose()">
            Закрыть
          </button>
          <button kendoButton size="small" themeColor="primary" (click)="applyHandler()">
            Применить
          </button>
        </div>
      </div>

      <div class="content-container" id="grid" name="grid">
        <kendo-grid #grid="kendoGrid"
                    [pageable]="true" [pageSize]="pageSize" (pageChange)="pageChange($event)"
                    [reorderable]="false" [resizable]="true"
                    [data]="gridView"
                    (cellClick)="transitionHandler($event)"
                    (excelExport)="onExcelExport($event)"
                    kendoGridSelectBy="studentExternalId"
                    [selectable]="{checkboxOnly: true}" [(selectedKeys)]="studentSelection"
                    [sortable]="true" [sort]="sort" (sortChange)="dataStateChange($event)"
                    [skip]="skip"
                    [loading]="loading" [rowClass]="rowCallback">
          <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container contingent-loading">
              <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
          </ng-template>
          <ng-template kendoGriаdNoRecordsTemplate>
            <p style="text-align:left">Нет записей для отображения.</p>
          </ng-template>
          <kendo-grid-checkbox-column [showSelectAll]="selectableSettings" *ngIf="selectableSettings" [width]="80" [locked]="true"></kendo-grid-checkbox-column>

          <kendo-grid-column *ngFor="let column of columns"
                             headerClass="leftAlign"
                             [field]="column.field"
                             [title]="column.title"
                             [width]="column.width"
                             [locked]="column.locked"
                             [hidden]="isHidden(column.title)">
            <ng-template *ngIf="column.field === 'studentId'" kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{(rowIndex + 1)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'studentBudgetState.studentBudgetName'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.studentBudgetState == null ? "" : dataItem?.studentBudgetState.studentBudgetName)}}
            </ng-template>
            <ng-template *ngIf="column.field == 'studPersons.fullName'" kendoGridCellTemplate let-dataItem>
              <div [ngClass]="{elderColor: dataItem.starostaId}">
                {{(dataItem.studPersons.fullName)}}
              </div>
            </ng-template>
            <ng-template *ngIf="column.field === 'medicalDate'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.medicalDate == null ? "" : dataItem?.medicalDate| date: "dd.MM.yyyy":'GMT')}}
            </ng-template>
            <ng-template *ngIf="column.field === 'identifications.birthPlace'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.identifications == null ? "" : dataItem?.identifications.birthPlace)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'studPersons.country'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.studPersons.country == null ? "" : dataItem.studPersons.country)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'studentBudgetState.dictOrganizations.organizationName'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.studentBudgetState.dictOrganizations == null ? "" : dataItem?.studentBudgetState.dictOrganizations.organizationName)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'studentBudgetState.dictOrganizationEmployers.organizationName'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.studentBudgetState.dictOrganizationEmployers == null ? "" : dataItem?.studentBudgetState.dictOrganizationEmployers.organizationName)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'studentBudgetState.dictOrganizations.inn'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.studentBudgetState.dictOrganizations == null ? "" : dataItem?.studentBudgetState.dictOrganizations.inn)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'studentBudgetState.dictOrganizations.kpp'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.studentBudgetState.dictOrganizations == null ? "" : dataItem?.studentBudgetState.dictOrganizations.kpp)}}
            </ng-template>
            <ng-template *ngIf="column.field ==='studentBudgetState.dictOrganizations.ogrn'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.studentBudgetState.dictOrganizations == null? "" : dataItem?.studentBudgetState.dictOrganizations.ogrn)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'address'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.address == null ? "" : dataItem?.address?.country + " " + dataItem?.address?.state + " " + dataItem?.address?.city)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'ball'" kendoGridCellTemplate let-dataItem>
              {{(dataItem?.ball == null ? "-" : dataItem?.ball)}}
            </ng-template>
            <ng-template *ngIf="column.field === 'achievementsCount'" kendoGridCellTemplate let-dataItem>
              {{dataItem?.achievementsCount == null ? "" : dataItem?.achievementsCount}}
            </ng-template>
          </kendo-grid-column>

          <ng-template kendoPagerTemplate>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="3"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
          </ng-template>

          <kendo-grid-excel fileName="Students.xlsx" [fetchData]="allData">
          </kendo-grid-excel>

          <kendo-grid-pdf fileName="contingent.pdf" paperSize="A4" [scale]="0.5" [repeatHeaders]="true" [autoPrint]=true>
            <kendo-grid-pdf-margin top="3cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
            <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
              <div class="page-template">
                <div class="header">
                  Список студентов
                  <br>
                </div>
              </div>
            </ng-template>
          </kendo-grid-pdf>
        </kendo-grid>
      </div>
    </div>
  </kendo-stacklayout>
</div>

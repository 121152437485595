<current-control-menu-access></current-control-menu-access>
<h1>Справочник уровня критериев оценок</h1>

<div class="grid-container">
  <!--Grid-->
  <kendo-grid [kendoGridBinding]="criteriaLevels" (remove)="removeHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)">
    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid&&formGroup?.get('disciplineType')!=undefined"
                themeColor="primary"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column field="name"
                       title="Название"
                       [width]="270"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.name}}
                          </span>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                            <kendo-textbox
                              [clearButton]="true"
                              [formControl]="formGroup.get('name')"
                            >
                            </kendo-textbox>
                          </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shortName"
                       title="Сокр. название"
                       [width]="170"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.shortName}}
                        </span>
                      </ng-template>
                      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-textbox
                          [clearButton]="true"
                          [formControl]="formGroup.get('shortName')"
                        >
                        </kendo-textbox>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="minValue"
                       title="Минимум"
                       [width]="170"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.minValue}}
                        </span>
                      </ng-template>
                      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox 
                            [formControl]="formGroup.get('minValue')"
                            [decimals]="0"
                            format="n0"
                            >
                        </kendo-numerictextbox>
                       </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="maxValue"
                       title="Максимум"
                       [width]="170"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.maxValue}}
                        </span>
                      </ng-template>
                      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox 
                            [formControl]="formGroup.get('maxValue')"
                            [decimals]="0"
                            format="n0"
                            >
                        </kendo-numerictextbox>
                       </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="numOrder"
                       title="Порядковый номер"
                       [width]="170"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.numOrder}}
                        </span>
                      </ng-template>
                      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox 
                            [formControl]="formGroup.get('numOrder')"
                            [decimals]="0"
                            format="n0"
                            >
                        </kendo-numerictextbox>
                       </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="criteriaDictMarkId" title="Оценка"
                       [width]="170"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ nameMark(dataItem.criteriaDictMarkId)?.markName }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="marks"
                            textField="markName"
                            valueField="id"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('criteriaDictMarkId')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" title="" [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

    
  </kendo-grid>

</div>

<div kendoDialogContainer></div>
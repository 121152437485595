
import { Student } from "./student.model";
import {Guid} from "guid-typescript";

export class OrdersStudyPeriod{
  public ordersStudyPeriodId?: number;
  public ordersStudyPeriodExternalId?: Guid;
  public studentId?: Guid;
  public student?: Student;
  public dateStart?: Date|null;
  public dateFinish?: Date|null
  public editable?: boolean;
}

import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { PagerType } from '@progress/kendo-angular-pager';
import { PagerPosition, GridComponent, CellClickEvent, EditEvent} from '@progress/kendo-angular-grid';
import { DepartmentsAdminService } from 'src/app/services/persondepartment/departments.service';
import { CompositeFilterDescriptor, filterBy, } from "@progress/kendo-data-query";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { Status, statuses } from "src/app/components/persondepartment/status";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { getDayStart } from 'src/app/helpers/date-helper';

@Component({
    selector   : 'app-departments-name',
    templateUrl: './departments-name.component.html',
    styleUrls  : ['./departments-name.component.scss']
})

export class DepartmentsNameComponent implements OnInit{

    public departmentsName: any = [];

    public loading = false;
    public skip = 0;
    public pageSizes = [10, 20, 30];
    public pageSize = 10;
    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';
    public info = true;
    public loaderExcelExport = false;

    public statuses = statuses;

    public typeDepartment: any = [];
    public filterData: any = [];

    @ViewChild(GridComponent) private grid!: GridComponent;

    public formGroup: FormGroup | undefined;
    private isLine = false;
    public editedRowIndex: number | undefined;
    private isNew = false;
    public get isInEditingMode(): boolean{
        return this.editedRowIndex !== undefined || this.isNew;
    }
    public positionEdit: "top" | "bottom" | "both" = "top";

    public sNameEdit: string = "";
    public rNameEdit: string = "";

    public externalId: string = "";

    public exists: boolean = false;
    public empty: boolean = false;

    public typeName: string = "";
    public index: any;

constructor(
    private departmentsAdminService: DepartmentsAdminService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
){}


ngOnInit() {
    this.getDepartmentsName();
    this.getTypes();
    this.renderer.listen("document", "click", ({ target }) => {
        if (!isChildOf(target, "k-touch-action-auto") && !isChildOf(target, "editRecord") || target.outerHTML.indexOf('reset') !== -1 )
        {
          this.cancelHandler();
        }
    });
}

    public getDepartmentsName(){
        this.departmentsAdminService.getDepartmentsName()
            .subscribe(response =>{
                this.departmentsName = response;

                for (let i=0; i< this.departmentsName.length; i++){
                    this.departmentsName[i].createdAt = getDayStart(this.departmentsName[i].createdAt);
                    this.departmentsName[i].updatedAt = getDayStart(this.departmentsName[i].updatedAt);
                }
            })
    }

    public getTypes(){
        this.departmentsAdminService.getDepartmentsType()
            .subscribe( response => {
                this.typeDepartment = response;
                this.typeDepartment = this.typeDepartment.filter((el: any) => el.commonStatus !== 2);
            });
    }


    public filter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
      };

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        this.loadData();
    }

    public loadData(): void {
        this.filterData = filterBy(this.departmentsName, this.filter);
        this.filterData = orderBy(this.filterData, this.sort);
    }

    public Status(value:any){
        return Status(value)
    }

    public exportToExcel(grid: GridComponent): void {
        this.loaderExcelExport = true
        this.allData = this.allData.bind(this);
        if (grid != undefined) grid.saveAsExcel();

        this.getDepartmentsName();
        this.filterChange(this.filter);
    }

    public allData = (): any => {
        if (this.sort[0].field == "" && this.filter.filters.length == 0) {
            this.filterData = this.departmentsName;
        }
        let saveData = this.filterData;
        for (let element of saveData ){
            element.commonStatus = this.Status(element.commonStatus);
        }
        let response = {
          data: saveData,
          total: saveData.length
        };
        this.loaderExcelExport = false;
        return response;
    };

    public sort: SortDescriptor[] = [
        {
          field: "",
          dir: "asc",
        },
    ];

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadData();
    }

    public onChange(value: any){
        if (value.trim() == '')  this.empty = true;
        else this.empty = false;
    }

    public editHandler(value: EditEvent){
        if (this.isLine) {
            this.cancelHandler();
            return;
        }

        this.isLine = true;
        this.externalId = value.dataItem.externalId;
        this.typeName = value.dataItem.typeName;
        this.editedRowIndex = value.rowIndex;
        this.sNameEdit = value.dataItem.sName;
        this.rNameEdit = value.dataItem.rName;

        this.formGroup = formGroup(value.dataItem);

        this.index = this.departmentsName.findIndex((el: any) => el.sName == value.dataItem.sName && el.typeName == this.typeName)

        value.sender.editRow(value.rowIndex, this.formGroup );
    }

    public cancelHandler(){
        this.isLine = false;
        this.empty = false;
        this.closeEditor(this.grid, this.editedRowIndex);
    }

    private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
        ): void {
          this.isNew = false;
          grid.closeRow(rowIndex);
          this.editedRowIndex = undefined;
          this.formGroup = undefined;
    }

    public saveCurrent(){
        if (this.formGroup !== undefined) {
            this.exists = false;

            let sName = this.formGroup.value.sName.toLowerCase().trim();

            let departmentsNameFilter = this.departmentsName.filter((el: any) => el.sName !== null);
            let findSName = departmentsNameFilter.find((item:any) => item.sName.toLowerCase().trim() == sName);

            if (departmentsNameFilter.findIndex((item:any) => item.sName.toLowerCase().trim() == sName) !== -1 &&
                findSName.externalId !== this.externalId && findSName.typeName == this.typeName) {

                this.notificationService.showError("Невозможно сохранить, так как такое сокращенное название уже существует");
                this.exists = true;
            }

            let data = this.formGroup.value;
            data.commonStatus = this.departmentsName[this.index].commonStatus;

            if (this.exists == false){
                this.departmentsAdminService.updateDepartmentsName(data/*this.formGroup.value*/, this.externalId)
                .subscribe( response => {
                    this.getDepartmentsName();
                    this.notificationService.showSuccess("Сохранено");
                  },
                  error => {
                    this.notificationService.showError("Не удалось изменить запись");
                  });
            }
        }
        this.closeEditor(this.grid, this.editedRowIndex);
    }

}

const formGroup = (dataItem: {sName?: any; rName?: string; /*commonStatus?: any;*/ }) =>
  new FormGroup({
    sName: new FormControl(dataItem.sName, Validators.required),
    rName: new FormControl(dataItem.rName),
 //   commonStatus: new FormControl(dataItem.commonStatus, Validators.required),
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

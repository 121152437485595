import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { PagerPosition, RemoveEvent } from '@progress/kendo-angular-grid';
import { DictComponent } from 'src/app/models/education/dictcomponent.model';
import { DictComponentType } from 'src/app/models/education/dictcomponenttype.model';
import { DictCycle } from 'src/app/models/education/dictcycle.model';
import { DictComponentService } from 'src/app/services/education/DictComponent/dict-component.service';
import { DictComponentTypeService } from 'src/app/services/education/DictComponentType/dict-component-type.service';
import { DictCycleService } from 'src/app/services/education/DictCycle/dict-cycle.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { PagerType } from '@progress/kendo-angular-pager';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'dict-component',
  templateUrl: './dict-component.component.html',
  styleUrls: ['./dict-component.component.scss']
})
export class DictComponentComponent implements OnInit {

  public gridStyle: string = 'grid-default';
  public editable?: boolean;
  public isOpened: boolean = false;
  public loading: boolean = false;
  public isNew: boolean = false;
  public secondFilter: Filter = {
    standardTypeId: undefined,
    trainingLevelId: undefined,
    dictCycleId: undefined
  }

  // Pager settings
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [10, 20, 50];
  public previousNext = true;
  public position: PagerPosition = 'bottom';
  public opened = false;

  public traininglevels!: TrainingLevel[];
  public standardTypes!: StandardType[];
  public dictCycles!: DictCycle[];
  public componentTypes!: DictComponentType[];
  public components!: DictComponent[];
  public dictComponentsByFilter: DictComponent[] = [];
  public dictCyclesByFilter: DictCycle[] = [];
  public dictComponentsByCycleFilter: DictComponent[] = [];

  constructor(private componentService: DictComponentService,
    private notificationService: NotificationsService,
    private componentTypeService: DictComponentTypeService,
    private dictCycleService: DictCycleService,
    private userAccessService:EducationUserAccessService,
    private trainingLevelService: TrainingLevelService,
    private standardTypeService: StandardtypeService,
    private dialogService: DialogService,) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getAllStandardTypes();
    this.getAllTrainingLevels();
    this.getAllComponents();
    this.getAllComponentTypes();
   // this.getAllDictCycles();
  }

  public defaultItemTrainingLevel: { trainingLevelName: string; dictTrainingLevelExternalId?: any } = {
    trainingLevelName: "Не выбрано",
    dictTrainingLevelExternalId: null,
  };

  public defaultItemStandardType: { standardTypeShortName: string; standardTypeExternalId?: any } = {
    standardTypeShortName: "Не выбрано",
    standardTypeExternalId: null,
  };

  public defaultItemParentElement: { dictComponentName: string; dictComponentExternalId?: any } = {
    dictComponentName: "Не выбрано",
    dictComponentExternalId: null,
  };

  public cancelFilterHandler(): void {
    this.secondFilter.standardTypeId = undefined;
    this.secondFilter.trainingLevelId = undefined;
    this.secondFilter.dictCycleId = undefined;
    this.dictComponentsByFilter = [];
    this.dictCyclesByFilter = [];
    this.dictComponentsByCycleFilter = [];
  }

  public onValueChange(value: any): void {
    // this.editForm.value.dictCycleExternalId = undefined;
    // this.editForm.value.parentDictComponentId = undefined;
    this.saveCurrentFilter();
  }

  saveCurrentFilter() {
    this.dictComponentsByFilter = [];

    this.dictComponentsByFilter = this.components.filter(x => Guid.parse(x.dictCycle.standardTypeExternalId) == this.secondFilter.standardTypeId &&
    Guid.parse(x.dictCycle.trainingLevelExternalId) == this.secondFilter.trainingLevelId)

    this.filter =  { logic: 'and', filters: [] };
    this.dictCyclesByFilter = [];
    this.dictCyclesByFilter = this.dictCycles.filter(x => Guid.parse(x.standardTypeExternalId) == this.secondFilter.standardTypeId &&
    Guid.parse(x.trainingLevelExternalId) == this.secondFilter.trainingLevelId)
  }

  saveCurrentFilterByCycle() {
    this.dictComponentsByCycleFilter = [];
    this.dictComponentsByCycleFilter = this.components.filter(x => Guid.parse(x.dictCycle.standardTypeExternalId) == this.secondFilter.standardTypeId &&
    Guid.parse(x.dictCycle.trainingLevelExternalId) == this.secondFilter.trainingLevelId &&
    Guid.parse(x.dictCycle.dictCycleExternalId) == this.secondFilter.dictCycleId)
  }

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictComponentId: new FormControl(0),
    dictComponentExternalId: new FormControl(""),
    dictComponentName: new FormControl(""),
    dictComponentShortName: new FormControl(""),
    dictComponentPrintName: new FormControl(""),
    dictComponentNameGenitiveCase: new FormControl(""),
    dictCycleExternalId: new FormControl(""),
    dictCycle: new FormControl(""),
    dictComponentTypeExternalId: new FormControl(""),
    dictComponentType: new FormControl(""),
    parentDictComponentId: new FormControl(""),
    parentDictComponentName: new FormControl(""),
    serialNumber: new FormControl(),
    hiddenTitle: new FormControl(false),
    hiddenCipher: new FormControl(false),
    hiddenLaborIntensity: new FormControl(false),
    enumerateDisciplinesByTypeInCycle: new FormControl(false),
    hiddenDisciplineNumber: new FormControl(false),
  });

  //Getting all entries from components
  public getAllComponents() {
    this.loading = true;
    this.componentService.getAllComponents()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.components = response;
          this.getAllDictCycles();
        //  this.saveCurrentFilter();
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить список типов компонентов ");
        }
      });
  }

  //Getting all entries from component types
  public getAllComponentTypes() {
    this.componentTypeService.getAllComponentTypes()
      .subscribe(
        response => {
          this.componentTypes = response;
        }
      );
  }

  //Getting all entries from standard types
  public getAllStandardTypes() {
    this.standardTypeService.getAllStandardTypes()
      .subscribe(
        response => {
          this.standardTypes = response;
        }
      );
  }

  //Getting all entries from study levels
  public getAllTrainingLevels() {
    this.trainingLevelService.getAllTrainingLevels()
      .subscribe(
        response => {
          this.traininglevels = response;
        }
      );
  }

  //Getting all entries from DictCycle
  public getAllDictCycles() {
    this.dictCycleService.getAllCycles()
      .subscribe(
        response => {
          this.dictCycles = response;
          this.saveCurrentFilter();
        }
      );
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public cyclesChange(value: any): void {
    this.editForm.controls['parentDictComponentId'].reset();
    this.secondFilter.dictCycleId = value;
    this.saveCurrentFilterByCycle();
    this.getAllComponents();
  }

  public saveComponent() {
    this.checkBoxesNullCheck()
    if(this.editForm.value.dictComponentShortName === null) {
      this.editForm.value.dictComponentShortName = ' '
    }
    // Add new component
    if (this.isNew) {
      this.componentService.addComponent(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Компонент был успешно добавлен!");
              this.getAllComponents();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить компонент");
              this.onCancel();
            }
          });
    }
    // Edit component type
    else {
      this.componentService.updateComponent(this.editForm?.value)
          .subscribe({
            next: (response) => {
              this.notificationService.showSuccess("Компонент был успешно сохранен!");
              this.getAllComponents();
              this.saveCurrentFilter();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось изменить компонент');
              this.onCancel();
            }
          })
    }
  }

  checkBoxesNullCheck() {
    const booleans = ['hiddenTitle', 'hiddenCipher', 'hiddenLaborIntensity', 'enumerateDisciplinesByTypeInCycle', 'hiddenDisciplineNumber'];
    for(let i = 0; i < booleans.length; i++) {
      const formName = booleans[i];
      const value = this.editForm.get(`${formName}`)?.value;
      if(value === null) {
        this.editForm.get(`${formName}`)?.setValue(false);
      }
    }
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }

  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.dictComponentName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.componentService.deleteComponent(dataItem.dictComponentExternalId)
            .subscribe(
              (response: any) => {
                this.getAllComponents();
                this.notificationService.showSuccess("Удалено");
              },
              (error: any) => {
                if(error.error[0].name)
                  this.notificationService.showError(`Компонент не может быть удалён, так как используется ${error.error[0].usableCount} раз, пример использования в плане: ${error.error[0].name}`);
                else
                  this.notificationService.showError(error.error);
              }
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup =
    (dataItem: {
      dictComponentId?: any;
      dictComponentExternalId?: any,
      dictComponentName?: any;
      dictComponentShortName?: any;
      dictComponentPrintName?: any;
      dictComponentNameGenitiveCase?: any;
      dictCycleExternalId?: any,
      dictComponentTypeExternalId?: any;
      parentDictComponentId?: any;
      parentDictComponentName?: any;
      serialNumber?: any;
      hiddenTitle?: any;
      hiddenCipher?: any;
      hiddenLaborIntensity?: any;
      enumerateDisciplinesByTypeInCycle?: any;
      hiddenDisciplineNumber?: any;
    }) =>
        new FormGroup({
          dictComponentId: new FormControl(dataItem.dictComponentId),
          dictComponentExternalId: new FormControl(dataItem.dictComponentExternalId),
          dictComponentName: new FormControl(dataItem.dictComponentName),
          dictComponentShortName: new FormControl(dataItem.dictComponentShortName),
          dictComponentPrintName: new FormControl(dataItem.dictComponentPrintName),
          dictComponentNameGenitiveCase: new FormControl(dataItem.dictComponentNameGenitiveCase),
          dictCycleExternalId: new FormControl(dataItem.dictCycleExternalId),
          dictComponentTypeExternalId: new FormControl(dataItem.dictComponentTypeExternalId),
          parentDictComponentId: new FormControl(dataItem.parentDictComponentId),
          parentDictComponentName: new FormControl(dataItem.parentDictComponentName),
          serialNumber: new FormControl(dataItem.serialNumber),
          hiddenTitle: new FormControl(dataItem.hiddenTitle),
          hiddenCipher: new FormControl(dataItem.hiddenCipher),
          hiddenLaborIntensity: new FormControl(dataItem.hiddenLaborIntensity),
          enumerateDisciplinesByTypeInCycle: new FormControl(dataItem.enumerateDisciplinesByTypeInCycle),
          hiddenDisciplineNumber: new FormControl(dataItem.hiddenDisciplineNumber),
        });

export class Filter {
  public trainingLevelId!: Guid | undefined;
  public standardTypeId!: Guid | undefined;
  public dictCycleId!: Guid | undefined;
}

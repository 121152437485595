<div class="grid-layout-container">
    <kendo-gridlayout class="personLayout"
      [align]="align"
      [gap]="{ rows: 10, cols: 20}">
      <kendo-gridlayout-item [col]="1" [row]="1">
        <div class="profilePicture" [style.background-image]="photoUrl"></div>
        <h3 class="personName">{{person.fullName}}</h3>
        <div class="contacts">
          <h2 class="title">Контакты</h2>
          <div class="email">
            <table class="table__first">
              <tr class="spaceUnder">
                <th>E-mail</th>
              </tr>
            </table>
            <table class="table" *ngFor="let item of emails">
              <tr class="spaceUnder">
                <td>
                  <a class="mailLink" href="mailto:{{item.email}}">{{item.email}}</a>
                </td>
              </tr>
            </table>
          </div>
          <div class="phone">
            <table class="table__padding">
              <tr class="spaceUnder">
                <th>Телефон</th>
              </tr>
            </table>
            <table class="table" *ngFor="let item of phoneNumbers">
              <tr class="spaceUnder">
                <td>{{phoneFormat(item.phoneNumber) | imask: mask}}</td>
              </tr>
            </table>
          </div>
          <div class="social">
            <h2 class="social__title">Соц. сети</h2>
            <table *ngIf="!socialNetworkEditMode" class="social__table">
              <tr>
                <td>
                  <div class="socialItem" *ngFor="let item of socialNetworks">
                    <a class="{{item.dictSocialNetwork.socialNetworkName.toLowerCase()}}" href="{{item.socialNetwork}}" target="_blank"></a>
                  </div>
                </td>
              </tr>
            </table>

            <!-- Edit social networks -->
            <div *ngFor="let item of socialNetworks">
              <kendo-textbox class="editSocial" *ngIf="socialNetworkEditMode" [disabled]="true" [(ngModel)]="item.dictSocialNetwork.socialNetworkName"></kendo-textbox>
              <kendo-textbox class="editSocial" *ngIf="socialNetworkEditMode" [(ngModel)]="item.socialNetwork">
                <ng-template kendoTextBoxSuffixTemplate>
                  <kendo-textbox-separator></kendo-textbox-separator>
                  <button kendoButton icon="trash" (click)="open(item)"></button>
                </ng-template></kendo-textbox>
            </div>
            <button kendoButton class="addButton" icon="plus" fillMode="outline"
                    *ngIf="socialNetworkEditMode && !socialNetworkAddMode"
                    (click)="socialNetworkAddModeEvent()">
              Добавить
            </button>

            <form novalidate class="k-form" [formGroup]="socialNetworksForm" *ngIf="socialNetworkAddMode">
              <table class="table">
                <tr>
                  <td>
                    <kendo-dropdownlist
                    [data]="dictSocialNetworks"
                    textField="socialNetworkName"
                    valueField="dictSocialNetworkId"
                    [valuePrimitive]="true"
                    [formControl]="$any(socialNetworksForm).controls['dictSocialNetworkId']"
                    formControlName="dictSocialNetworkId" #dictSocialNetworkId required>
                    </kendo-dropdownlist>
                  </td>
                </tr>
                <tr>
                  <td>
                    <kendo-textbox formControlName="socialNetwork" #socialNetWork required></kendo-textbox>
                  </td>
                </tr>
              </table>
              <div class="buttons">
                <button class="addButton" fillMode="outline" kendoButton (click)="cancelSocialAddForm()" [primary]="true">Отмена</button>
                <button class="addButton" fillMode="outline" kendoButton (click)="addSocialNetwork()" [primary]="true">Сохранить</button>
              </div>
            </form>
          </div>
          <div class="identificationNumbers">
            <h2 class="identificationNumbersTitle">Идентификационные номера</h2>
            <table class="table" *ngFor="let item of identificationNumbers">
              <tr *ngIf="!identificationNumberEditMode">
                <td>
                  <a href="{{item.identificationValue}}" class="identificationLink" target="_blank">{{item.dictIdentificationNumber.identificationNumberName}}</a>
              </tr>
            </table>

            <!-- Edit identification numbers -->
            <div *ngFor="let item of identificationNumbers">
              <kendo-textbox class="editMode" [disabled]="true" *ngIf="identificationNumberEditMode" [(ngModel)]="item.dictIdentificationNumber.identificationNumberName"></kendo-textbox>
              <kendo-textbox class="editMode" *ngIf="identificationNumberEditMode" [(ngModel)]="item.identificationValue">
                <ng-template kendoTextBoxSuffixTemplate>
                  <kendo-textbox-separator></kendo-textbox-separator>
                  <button kendoButton icon="trash" (click)="openIdentification(item)"></button>
                </ng-template>
              </kendo-textbox>
            </div>
            <button kendoButton class="addButton" fillMode="outline" icon="plus" *ngIf="identificationNumberEditMode && !identificationNumberAddMode"
            (click)="identificationNumberAddModeEvent()">Добавить</button>

            <form novalidate class="k-form" [formGroup]="identificationNumberForm" *ngIf="identificationNumberAddMode">
              <table class="table">
                <tr>
                  <td>
                    <kendo-dropdownlist
                    [data]="dictIdentificationNumbers"
                    textField="identificationNumberName"
                    valueField="dictIdentificationNumberId"
                    [valuePrimitive]="true"
                    [formControl]="$any(identificationNumberForm).controls['dictIdentificationNumberId']"
                    formControlName="dictIdentificationNumberId" #dictIdentificationNumberId required>
                    </kendo-dropdownlist>
                  </td>
                </tr>
                <tr>
                  <td>
                    <kendo-textbox formControlName="identificationValue" #identificationValue required></kendo-textbox>
                  </td>
                </tr>
              </table>
              <h3 class="warning">*Введите ссылку на идентификационный номер</h3>
              <div class="buttons">
                <button class="addButton" fillMode="outline" kendoButton (click)="cancelIdentificationAddForm()" [primary]="true">Отмена</button>
                <button class="addButton" fillMode="outline" kendoButton (click)="addIdentificationNumber()" [primary]="true">Сохранить</button>
              </div>
            </form>
            <div class="buttons" *ngIf="identificationNumberEditMode">
              <button class="cancelButton" kendoButton (click)="cancelEvent()" [primary]="true">Отмена</button>
              <button class="confirmButton" kendoButton (click)="saveItems()" [primary]="true" [disabled]="this.identificationNumberAddMode || this.socialNetworkAddMode">ОК</button>
            </div>
          </div>
          <button kendoButton class="addButton" fillMode="outline" icon="pencil" *ngIf="!identificationNumberEditMode" (click)="editMode()">Редактировать</button>
        </div>
        <div class="support">
          <h2 class="supportTitle">Техническая поддержка ЛК</h2>
          <p>
            E-mail  <a class="mailLink" href="mailto:{{supportEmail}}">{{supportEmail}}</a>
          </p>
          <p>
            Тел. 220-15-94
          </p>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [col]="2" [row]="1">
        <div class="info">
          <h1>Основная информация</h1>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="rightColumn">
        <kendo-listview [data]="scientificDegrees" class="removeBackground">
        </kendo-listview>
        <table class="table" *ngFor="let item of experiences">
          <tr>
            <th>{{item.experienceType}}</th>
            <td>
              <div class="experienceNumber">
                <div *ngIf="item.years != 0">{{item.years}} {{yearConversion[item.years] ?? 'лет'}}</div>
                <div *ngIf="item.years != 0">&nbsp;</div>
                <div *ngIf="item.months != 0">{{item.months}} {{monthConversion[item.months] ?? 'месяцев'}}</div>
              </div>
            </td>
          </tr>
        </table>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="leftColumn">
        <table class="table">
            <tr class="spaceUnder">
              <th>Дата рождения</th>
              <td>{{person.birthday | date: 'dd.MM.yyyy':'GMT'}}</td>
            </tr>
            <tr>
              <th>Табельный номер</th>
              <td>{{salary?.buhCode }}</td>
            </tr>
        </table>
        <table class="table">
          <tr>
            <th>Ученая степень</th>
            <td>
              <div *ngFor="let item of scientificDegrees; let isLast=last">{{item.scientificDegree}}</div>
              <div *ngIf="scientificDegrees.length == 0">-</div>
            </td>
          </tr>
        </table>
        <table class="table">
          <tr>
            <th>Должность</th>
            <td>
              <div *ngFor="let item of salaries; let isLast=last">
                {{ formatSalaryInfo(item) }}
              </div>
              <div *ngIf="salaries.length == 0">-</div>
            </td>
          </tr>
        </table>
        <table class="table">
          <tr>
            <th>Количество дней отпуска</th>
            <td>{{ person.remainingVacationDaysNumber ? person.remainingVacationDaysNumber.toFixed(2) : '-' }}</td>
          </tr>
        </table>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="regulationColumn" *ngIf="userSignaturesInfo != undefined">
        <div *ngIf="userSignaturesInfo.length != 0">
          <h2>Присоединение к положениям</h2>
          <div class="userRegulationInfoContainer" *ngFor="let item of userSignaturesInfo">
            <div *ngIf="item.dateUntilBlocking === null" class="userRegulationInfo">
              <div class="userSignatureItem userRegulation">
                <p>{{item.regulationName}}</p>
                <button kendoButton icon="download"
                        (click)="getRegulationFile(item.regulationFileDto)">
                  Скачать
                </button>
              </div>
              <p>Дата присодинения к Положению: {{toLocalDatetime(item.joinDate)}}</p>
            </div>
            <div *ngIf="item.dateUntilBlocking !== null" class="userRegulationInfo">
              <p  class="regulationTextNotification">{{item.notificationText}}</p>
              <div class="userSignatureItem userRegulation">
                <p>{{item.regulationName}}</p>
                <button kendoButton icon="download"
                        (click)="getRegulationFile(item.regulationFileDto)">
                  Скачать
                </button>
              </div>
              <div class="userSignatureItem userRegulation">
                <p>{{item.statementText}}</p>
                <button kendoButton icon="download"
                        (click)="getRegulationFile(item.statementFileDto)">
                  Скачать
                </button>
              </div>
              <p>Дата присодинения к Положению: {{toLocalDatetime(item.joinDate)}}<span class="highlight-date">Количество дней до блокировки: {{calculateDateUntilBlocking(item.dateUntilBlocking)}}</span></p>
            </div>
          </div>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="educationColumn">
      <div class="grid">
        <h2>Образование (базовое, профессиональная переподготовка)</h2>
        <kendo-grid [data]="educationGridView">
            <ng-template kendoGridToolbarTemplate position="bottom" *ngIf="!allEducations">
              <button kendoButton (click)="showAllEducationData()">Показать все</button>
            </ng-template>
            <kendo-grid-column field="year" title="Год" [width]="100"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">{{dataItem.year}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="600" field="educationOrganization" title="Образовательная организация"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.educationOrganization }}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="380" field="speciality" title="Специальность (Курс)"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{dataItem.courseName}}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="200" field="qualification" title="Квалификация"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.qualification }}
                  </span>
              </ng-template>
            </kendo-grid-column>
        </kendo-grid>
      </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="qualificationColumn">
        <div class="grid">
          <h2>Повышение квалификации</h2>
          <kendo-grid [data]="qualificationsGridView" [pageSize]="qualificationsPageSize">
              <ng-template kendoGridToolbarTemplate position="bottom" *ngIf="!allQualifications">
                <button kendoButton (click)="showAllQualificationData()">Показать все</button>
              </ng-template>
              <kendo-grid-column field="year" title="Период" [width]="130"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    {{groupQualificationPeriod(dataItem.periodBegin, dataItem.periodEnd)}}
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="qualificationType" title="Вид" [width]="220"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{ dataItem.qualificationType }}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="hoursNumber" title="Ак.часы" [width]="120"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    <span *ngIf="dataItem.hoursNumber != 0">{{ dataItem.hoursNumber }}</span>
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="qualificationAdvancementDocument" title="Документ о повышении квалификации"
              headerClass="gridHeader" [width]="300">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells document">
                    {{groupQualificationDocument(dataItem.qualificationAdvancementDocument, dataItem.docSerial, dataItem.docNumber, dataItem.docDate)}}
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="300" field="organization" title="Организация"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{ dataItem.organization }}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="250" field="program" title="Программа"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{ dataItem.program }}
                    </span>
                </ng-template>
              </kendo-grid-column>
          </kendo-grid>
        </div>
        </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>

<kendo-dialog
        title="Пожалуйста подтвердите"
        *ngIf="opened"
        (close)="close()"
        [minWidth]="250"
        [width]="450">
<p class="confirmationText">
  Вы уверены что хотите удалить социальную сеть?
</p>
<kendo-dialog-actions>
  <button kendoButton (click)="close()">Нет</button>
  <button kendoButton (click)="deleteSocialNetwork()" [primary]="true">
    Да
  </button>
</kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
        title="Пожалуйста подтвердите"
        *ngIf="identificationOpened"
        (close)="close()"
        [minWidth]="250"
        [width]="450">
<p class="confirmationText">
  Вы уверены что хотите удалить идентификационный номер?
</p>
<kendo-dialog-actions>
  <button kendoButton (click)="close()">Нет</button>
  <button kendoButton (click)="deleteIdentificationNumber()" [primary]="true">
    Да
  </button>
</kendo-dialog-actions>
</kendo-dialog>

import {Injectable} from "@angular/core";
import {catchError, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Info, SummaryInfo} from "../../../models/publications/summaryInfo/summaryInfo.model";
import {handleError} from "../../../helpers/publications/errorHandle-helper";


@Injectable({
  providedIn: 'root'
})
export class SummaryInfoService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.summaryInfo}`;

  constructor(private http: HttpClient) {
  }

  public getSummaryInfo(authorId: string): Observable<SummaryInfo> {
    return this.http.get<SummaryInfo>(`${this.baseUrl}/${authorId}`);
  }

  public addSummaryInfo(authorId: string, info: Info[]): Observable<Info[]> {
    return this.http.post<Info[]>(`${this.baseUrl}/${authorId}`, info)
      .pipe(catchError(handleError));
  }

}

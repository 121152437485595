import { Component, OnInit } from '@angular/core';
import { PagerType } from '@progress/kendo-angular-pager';
import { PagerPosition, GridComponent, CellClickEvent, EditEvent} from '@progress/kendo-angular-grid';
import { Router} from "@angular/router";
import { CadreBaseService } from 'src/app/services/persondepartment/cadre-base.service';
import { CompositeFilterDescriptor, filterBy, } from "@progress/kendo-data-query";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { Status, statuses } from "src/app/components/persondepartment/status";
import { getDayStart } from 'src/app/helpers/date-helper';

@Component({
    selector   : 'app-cadre-base',
    templateUrl: './cadre-base.component.html',
    styleUrls  : ['./cadre-base.component.scss']
})

export class CadreBaseComponent implements OnInit{

    public persons: any = [];
    public posts: any = [];
    public departments: any = [];
    public recordSource: any = [];

    public loading = false;
    public skip = 0;
    public pageSizes = [10, 20, 30];
    public pageSize = 10;
    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public previousNext = true;
    public position: PagerPosition = 'bottom';
    public info = true;
    public loaderExcelExport = false;

    public filterData: any = [];
    public statuses = statuses;

constructor(
    private router: Router,
    private cadreBaseService:  CadreBaseService,
){}

ngOnInit() {
    this. getPersons();
}

    public getPersons(){
        this.cadreBaseService.getAllPersons()
            .subscribe(response =>{

            this.persons = response;
            this.persons.forEach(function (item:any) {
              item.postName = item.personnelSalaries.length > 0 ? item.personnelSalaries[0].postName : '';
              item.departamentName = item.personnelSalaries.length > 0 ? item.personnelSalaries[0].departamentName : '';
              item.recordSourceName = item.personnelSalaries.length > 0 ? item.personnelSalaries[0].recordSourceName : '';
            });

            let post = [...new Set(this.persons.flatMap((n:any) => n.personnelSalaries.map((salary:any) => salary.postName)))];
            this.posts = post.map(function(value) {
                return {postName: value};
            });

            let department = [...new Set(this.persons.flatMap((n:any) => n.personnelSalaries.map((salary:any) => salary.departamentName)))];
            this.departments = department.map(function(value) {
                return {departamentName: value};
            });
            this.departments = this.departments.filter((el: any) => el.departamentName !== null);

            let method = [...new Set(this.persons.flatMap((n:any) => n.personnelSalaries.map((salary:any) => salary.recordSourceName)))];
            this.recordSource = method.map(function(value) {
                return {recordSourceName: value};
            });

            for (let i=0; i< this.persons.length; i++){
                this.persons[i].createdAt = getDayStart(this.persons[i].createdAt);
                this.persons[i].updatedAt = getDayStart(this.persons[i].updatedAt);
            }
        });
    }

    public addHandler(value: any){
        this.router.navigate(['technicaladministators/paramperson']);
    }

    public editHandler(value: EditEvent): void {
        this.router.navigate(['technicaladministators/paramperson/' + value.dataItem.externalId]);
    }

    public viewHandler({sender, isEdited, rowIndex, dataItem, }: CellClickEvent): void{
        window.open(`technicaladministators/personcard/${dataItem.externalId}`);
    }

    public exportToExcel(grid: GridComponent): void {
        this.loaderExcelExport = true
        this.allData = this.allData.bind(this);
        if (grid != undefined) grid.saveAsExcel();
    }

    public allData = (): any => {
        if (this.sort[0].field == "" && this.filter.filters.length == 0)
            this.filterData = this.persons;
        let response = {
          data: this.filterData,
          total: this.filterData.length
        };
        this.loaderExcelExport = false
        return response;
    };

    public filter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
      };

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        this.loadData();
    }

    public loadData(): void {
        this.filterData = filterBy(this.persons, this.filter);
        this.filterData = orderBy(this.filterData, this.sort);
    }

    public sort: SortDescriptor[] = [
        {
          field: "",
          dir: "asc",
        },
    ];

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadData();
    }

}

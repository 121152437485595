import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DictSignatoryRole} from "../../../models/mfc/dicts/signatory-role.model";

@Injectable({
  providedIn: 'root'
})
export class SignatoryRoleService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/DictSignatoryRole/`;

  constructor(private http: HttpClient) { }

  public getSignatoryRoles() {
    return this.http.get<DictSignatoryRole[]>(this.baseUrl);
  }
}
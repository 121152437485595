export const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

export const isChildOf = (el: any, className: any) => {
    while (el && el.parentElement) {
        if (hasClass(el.parentElement, className)) {
            return true;
        }
        el = el.parentElement;
    }
    return false;
};

export const getParentElement = (el: any, className: any) => {
    while (el && el.parentElement) {
      if (hasClass(el.parentElement, className)) {
        return el.parentElement as HTMLElement;
      }
      el = el.parentElement;
    }
    return null;
  };
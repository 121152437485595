import { Component, OnInit, ViewChild, } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent, PagerPosition } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { Structural } from 'src/app/models/classroom/structural.model';
import { StructuralService } from 'src/app/services/classroom/structural.service';
import { Guid } from 'guid-typescript';
import { Router} from "@angular/router";

@Component({
    selector: 'app-types-auditorium',
    templateUrl: './structural.component.html',
    styleUrls: ['./structural.component.scss']
})

export class StructuralComponent implements OnInit{

    public structurals: any = [];

    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public info = true;
    public pageSizes = [20, 50, 100];
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';

    public loading = false;

    public position: "top" | "bottom" | "both" = "both";
    public formGroup: FormGroup | undefined;
    public editable?: boolean;

    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean{
        return this.editedRowIndex !== undefined || this.isNew;
    }


constructor(
    private structuralService: StructuralService,
)
    {}

ngOnInit() {
    this.getAllStructural();
}

    public getAllStructural() {
        this.loading = true;
        this.structuralService.getAllStructural()
        .subscribe(
            response => {
            this.structurals = response;
            console.log('все структурные подр.', this.structurals);
            this.loading = false;
            },
            error => {
            console.log(error.error);
            this.loading = false;
            });
    }

    public removeHandler({ dataItem }: RemoveEvent): void {
    }

    public editHandler({
        sender,
        isEdited,
        rowIndex,
        dataItem,
    }: CellClickEvent): void {
    }

    public addHandler({ sender }: AddEvent): void {
        this.closeEditor(sender);

        this.formGroup = formGroup({
        structuralId: 0,
        structuralName: '',
        abbreviatedName: '',
        displayNumber: 0,
        dictStructuralExternalId: Guid.create()
        });

        this.isLine = true;
        this.isNew = true;
        sender.addRow(this.formGroup);
    }

    public cancelHandler(): void {
        this.closeEditor(this.grid, this.editedRowIndex);
    }

    private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
        ): void {
        this.isNew = false;
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

    public onStateChange(state: any): void {
    }

}

const formGroup = (dataItem: { structuralId?: any; structuralName?: any; abbreviatedName?: any; displayNumber?: any; dictStructuralExternalId?: any}) =>
  new FormGroup({
    structuralId: new FormControl(dataItem.structuralId),
    structuralName: new FormControl(dataItem.structuralName, Validators.required),
    abbreviatedName: new FormControl(dataItem.abbreviatedName),
    displayNumber: new FormControl(dataItem.displayNumber),
    dictStructuralExternalId: new FormControl(dataItem.dictStructuralExternalId)
});
import {Component} from '@angular/core';
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {boolOptions} from "../../../../models/mfc/enums/bool-options.enum";
import {breadcrumbItemsMap} from "../../../../models/home/breadcrumb-items.model";
import {pagerSettings} from 'src/app/models/mfc/pagerSettings.model';
import {ApplicationStatus} from "../../../../models/mfc/dicts/application-status.model";
import {ApplicationStatusService} from "../../../../services/mfc/dicts/application-status.service";

@Component({
  selector: 'mfc-dict-application-status',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="applicationStatuses"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      [deletableField]="deletableField"
      [editOnly]="true"
      (getMethod)="get()"
      (updateMethod)="update($event)">
    </app-dict>
  `
})
export class ApplicationStatusComponent {

  protected applicationStatuses: ApplicationStatus[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly deletableField = 'deletable';
  protected readonly info: FieldInfo<ApplicationStatus>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название',
      width: 350,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    },
  ];

  protected formGroup(dataItem?: ApplicationStatus): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      name: new FormControl(dataItem?.name, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber),
      applicationStatusEnum: new FormControl(dataItem?.applicationStatusEnum)
    });
  }

  constructor(private applicationStatusService: ApplicationStatusService) {}

  protected get() {
    this.applicationStatusService.getAll().subscribe(data =>
      this.applicationStatuses = data
    );
  }

  protected update({value, observer}: EventData<ApplicationStatus>) {
    this.applicationStatusService.updateApplicationStatus(value).subscribe(observer);
  }
}

import {Component, OnInit} from '@angular/core';
import {StaticPropertyEnum} from "../../../models/disciplineworkload/enums/settings.enum";
import {
  getStaticProperty,
  getStaticPropertyValue,
  StaticProperty
} from "../../../models/disciplineworkload/settings.model";
import {AssignmentSettingsService} from "../../../services/disciplineworkload/assignment-settings.service";
import {DictService} from "../../../services/disciplineworkload/externals/dict.service";
import {DictTypeWorkGet} from "../../../models/disciplineworkload/assignements.model";
import {NotificationsService} from "../../../services/notifications/notifications.service";

@Component({
    selector: 'workload-global-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class WorkLoadSettingsComponent implements OnInit {

  public staticPropertyEnum = StaticPropertyEnum;
  public staticProperties: StaticProperty[] = [];

  public dictTypeWorks: DictTypeWorkGet[] = [];

  public lectionId: string | null = null;
  public practiceId: string | null = null;
  public laboratoryId: string | null = null;
  public csrId: string | null = null;
  public rgrId: string | null = null;
  public controlWorkId: string | null = null;
  public referatid: string | null = null;

  constructor(private settings: AssignmentSettingsService,
              private dictService: DictService,
              private notificationService: NotificationsService) { }

  public getStaticProperties() {
    this.settings.getStaticProperties().subscribe(value => {
      this.staticProperties = value!;

      this.lectionId = getStaticPropertyValue(value, this.staticPropertyEnum.lectionId)!;
      this.practiceId = getStaticPropertyValue(value, this.staticPropertyEnum.practiceId)!;
      this.laboratoryId = getStaticPropertyValue(value, this.staticPropertyEnum.laboratoryId)!;
      this.csrId = getStaticPropertyValue(value, this.staticPropertyEnum.CSR)!;
      this.rgrId = getStaticPropertyValue(value, this.staticPropertyEnum.RGR)!;
      this.controlWorkId = getStaticPropertyValue(value, this.staticPropertyEnum.ControlWork)!;
      this.referatid = getStaticPropertyValue(value, this.staticPropertyEnum.Referat)!;
    });
  }

  public getTypeWorks() {
    this.dictService.getDictTypeWorks().subscribe(value => this.dictTypeWorks = value!);
  }

  public getPropertyName(name: string) {
    return getStaticProperty(this.staticProperties, name)?.title;
  }

  public saveProperty(name: string, value: string) {
    this.settings.updateStaticProperty(name, value).subscribe({
      next: response => response
        ? this.notificationService.showSuccess('Сохранено')
        : this.notificationService.showError('Не удалось сохранить свойство'),
      error: err => this.notificationService.showError(err)
    })
  }

  async ngOnInit() {
    this.getStaticProperties();
    this.getTypeWorks();
  }
}

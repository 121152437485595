import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';
import { Guid } from 'guid-typescript';
import { Faculty } from 'src/app/models/contingent/departmentname.model';
import { Department } from 'src/app/models/education/department.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DepartmentService } from 'src/app/services/education/External/department.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
  public departments: Department[] = [];
  public faculty: Faculty | {} = {}

  public department: Department | {} = {};
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private departmentService: DepartmentService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private userAccessService: EducationUserAccessService, //need person userAccess
              private notificationService: NotificationsService,
              private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getAllDepartment();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
    isEdited
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.departmentService.updateDepartment(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllDepartment();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllDepartment() {
    this.departmentService.getAllDepartments()
      .subscribe(
        response => {
          this.departments = response;
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(
        response => {
          this.editable = response.accessLevel != 1
        },error => {});
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { kafedraId?: any; kafedraName?: any; facultyId?: any; mmisCode?: any; faculty?: any; }) =>
  new FormGroup({
    kafedraId: new FormControl(dataItem.kafedraId),
    kafedraName: new FormControl(dataItem.kafedraName),
    facultyId: new FormControl(dataItem.facultyId),
    faculty: new FormControl(dataItem.faculty),
    mmisCode: new FormControl(dataItem.mmisCode, Validators.required)
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { DictHealth } from 'src/app/models/contingent/dicthealth.model';

@Injectable({
  providedIn: 'root'
})
export class DicthealthService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dicthealth}`;

  constructor(private http: HttpClient) { }

  //Get all Health
  public getAllHealth(): Observable<DictHealth[]> {
    return this.http.get<DictHealth[]>(this.baseUrl);
  }

  //Add Health
  addHealth(dto: DictHealth): Observable<DictHealth> {
    return this.http.post<DictHealth>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete Health
  deleteHealth(id: string): Observable<DictHealth> {
    return this.http.delete<DictHealth>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit Health
  updateHealth(dto: DictHealth): Observable<DictHealth> {
    return this.http.put<DictHealth>(this.baseUrl + '/' + dto.dictHealthExternalId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
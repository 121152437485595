import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { items } from "../../../models/persondepartment/menu-items";
import { DrawerItem, DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { TokenStorageService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { Role } from 'src/app/models/useraccess/role';
import { checkRole } from "../../../helpers/token/role-check";

@Component({
  selector: 'persondepartment-header',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})

export class PersondepartmentLayoutComponent implements OnInit{
  //title = 'ContingentWebApp';

  //Menu items
  public items: any[] = items;
  public isMainPage: boolean = this.router.url.endsWith('/useraccess/technicaladministators');

  public isAdmin: boolean;

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  public personId: string = "";
  public settings: boolean = false;

  constructor(private router: Router,
              private jwtHelper: JwtHelperService,
              private tokenStore: TokenStorageService,
              private personService: PersonService,)
  {
      this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

    ngOnInit(): void {
       this.getAccessLevel();
    }

    public getAccessLevel(){
    /*  this.userAccessService.getAccessLevel()
      .subscribe(
        response => {
          if (this.isAdmin == true || response.settingsAccess == true){
            this.settings = true;
          } else 
          { this.settings == false}
        })*/
    }


 /* public accessSetting(settings: boolean){
    // Hide admin items
    const token = this.tokenStore.getAuthToken();
    let user;
    console.log('settings',settings);
    if(token !== null)
    {
      user = this.jwtHelper.decodeToken(token);
    }
    let role = user.role;
    if(!role.includes(Role.Admin)&&response)
    {
      this.items[0].items = this.items[0].items.filter((a: { admin: boolean; }) => a.admin==null);
    }
    if(settings){
      this.items[0].items = this.items[0].items.filter((a: { settings: boolean; }) => a.settings==null);
      console.log(this.items[0].items);
    }
  }*/

}

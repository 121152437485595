import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import {
  ContingentLoad, ContingentLoadAddDto, ContingentLoadUpdateDto, ContingentLoadUpdateRange,
} from 'src/app/models/disciplineworkload/disciplineworkloadContingent.model';
import { environment } from 'src/environments/environment';
import {YearAndSemestr} from "../../models/disciplineworkload/yearAndSemestrs.model";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class DisciplineWorkloadContingentService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.contingentLoad}`;
  constructor(private http: HttpClient) { }

  public getContingentLoad(contingentLoad: HttpParams): Observable<ContingentLoad> {
    return this.http.get<ContingentLoad>(this.baseUrl + '/GetContingentLoad/', {params: contingentLoad})
      .pipe(
        catchError(handleError));
  }

  public getAllYearAndSemestrs(): Observable<YearAndSemestr> {
    return this.http.get<YearAndSemestr>(this.baseUrl + '/GetAllYearAndSemestrs/')
        .pipe(
            catchError(handleError));
  }

  public updateContingentLoad(contingentLoad: ContingentLoadUpdateDto): Observable<ContingentLoadUpdateDto> {
    return this.http.put<ContingentLoadUpdateDto>(this.baseUrl + '/UpdateContingentLoad/', contingentLoad)
        .pipe(
            catchError(handleError));
  }

  public updateRangeContingentLoad(contingentLoad: ContingentLoadUpdateRange): Observable<ContingentLoadUpdateRange> {
    return this.http.put<ContingentLoadUpdateRange>(this.baseUrl + '/UpdateRangeContingentLoad/', contingentLoad)
        .pipe(
            catchError(handleError));
  }

  public addContingentLoad(contingentLoad: ContingentLoadAddDto): Observable<ContingentLoadAddDto> {
    return this.http.post<ContingentLoadAddDto>(this.baseUrl + '/AddContingentLoad/', contingentLoad)
        .pipe(
            catchError(handleError));
  }
}

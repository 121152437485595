import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {AcademicGroupStudents, GroupHistory, StudEduGroup} from '../../models/contingent/studedugroup.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class StudedugroupService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studedugroup}`;

  constructor(private http: HttpClient) { }

  //Get all studedugroup
  public getAllstudedugroup(): Observable<StudEduGroup[]> {
    return this.http.get<StudEduGroup[]>(this.baseUrl);
  }

  //Get By Id studedugroup
  getByIdstudedugroup(id: string): Observable<StudEduGroup> {
    return this.http.get<StudEduGroup>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getAllAcademicGroupStudents(id: string): Observable<AcademicGroupStudents[]> {
    return this.http.get<AcademicGroupStudents[]>(this.baseUrl + '/GetAllAcademicGroupStudents/' + id)
      .pipe(catchError(this.handleError));
  }

  getStudentGroupHistory(id: Guid): Observable<GroupHistory[]> {
    return this.http.get<GroupHistory[]>(this.baseUrl + '/GetStudentGroupHistory/' + id)
      .pipe(catchError(this.handleError));
  }

  getExportStudentList(id: string | Guid, full: boolean){
    return this.http.get(this.baseUrl + '/GetExportGroupStudentList/' + id + "/" + full, {responseType: 'blob'})
      .pipe(catchError(this.handleError));
  }

  //Add studedugroup
  addstudedugroup(studedugroup: StudEduGroup): Observable<StudEduGroup> {
    return this.http.post<StudEduGroup>(this.baseUrl, studedugroup)
      .pipe(catchError(this.handleError));
  }

  //Delete studedugroup
  deletestudedugroup(id: string, studentId : Guid| undefined): Observable<StudEduGroup> {
    return this.http.delete<StudEduGroup>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit studedugroup
  updatestudedugroup(studedugroup: StudEduGroup): Observable<StudEduGroup> {
    return this.http.put<StudEduGroup>(this.baseUrl + '/' + studedugroup.studEduGroupId, studedugroup)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}


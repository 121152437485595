import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  Observable,
  retry,
  tap,
  throwError,
} from 'rxjs';
import {
  CellDayType,
  DictWorkScheduleStudyProcessType,
  ResponseScheduleProcess,
  WorkType,
  TableDataType,
  SemestrSettings,
  ScheduleCourseData,
} from 'src/app/models/education/education-plan-schedule.model';
import { NotificationsService } from '../../notifications/notifications.service';
import {sortBy} from "lodash";

@Injectable({
  providedIn: 'root',
})
export class EducationPlanScheduleService {
  private educationProcessScheduleUrl = `${environment.educationApiEndpoint}v1/${environment.apiPaths.education.educationSchedule}`;
  public educationPlanId: string = '';
  public semestersByCourse: { [key: number]: SemestrSettings[] } = {};

  processScheduleData$ = new BehaviorSubject<ResponseScheduleProcess>({
    courses: [],
    dates: []
  });

  coursesHandleData$ = new BehaviorSubject<TableDataType[]>([
    {
      courseNumber: 0,
      weeksNumber: [],
    },
  ]);

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationsService
  ) {}

  public getProcessSchedule(
    educationPlanId: string
  ): Observable<ResponseScheduleProcess> {
    this.educationPlanId = educationPlanId;
    return this.httpClient.get<ResponseScheduleProcess>(
      this.educationProcessScheduleUrl + '/' + educationPlanId
    );
  }

  public postProcessSchedule(educationPlanId: string): Observable<any> {
    const courses = JSON.parse(
      JSON.stringify(this.processScheduleData$.getValue().courses)
    ) as ScheduleCourseData[];
    courses.forEach((course) => {
      course.workTypes.forEach((workType) => {
        workType.dictWorkScheduleStudyProcessType = {
          id: workType.dictWorkScheduleStudyProcessType.id,
        } as DictWorkScheduleStudyProcessType;
      });
      course.semesters = this.semestersByCourse[course.courseNumber];
    });

    return this.httpClient
      .post<any>(this.educationProcessScheduleUrl, {
        educationPlanId,
        courses,
      })
      .pipe(
        retry(2),
        tap((response) => {
          if (!response) {
            return;
          }
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  public updateProcessScheduleData(educationPlanId: string) {
    this.getProcessSchedule(educationPlanId).subscribe((response) => {
      // response.courses.sort(
      //   (prev, next) => prev.courseNumber - next.courseNumber
      // );
      // response.courses.forEach((course) =>
      //   course.workTypes.sort(
      //     (prev, next) => prev.serialNumberWeek - next.serialNumberWeek
      //   )
      // );
      this.processScheduleData$.next(response);
    });
  }

  public setProcessScheduleData(value: ResponseScheduleProcess) {
    this.processScheduleData$.next(value);
  }

  public changeWorkTypes(
    workTypes: CellDayType[],
    course: number,
    weekNumber: number
  ) {
    const processScheduleData = this.processScheduleData$.getValue();
    let currentWorkType = processScheduleData.courses[course].workTypes;
    const semestersCount = processScheduleData.courses[course].semesters.length;
    let semesterNumber = semestersCount * course;
    const offsetIndex = currentWorkType[0].weekNumber;

    if (
      semestersCount > 1 &&
      processScheduleData.courses[course].semesters[1]
        .semesterBeginWeekNumber !== null &&
      weekNumber >=
        (processScheduleData.courses[course].semesters[1]
          .semesterBeginWeekNumber || 1)
    ) {
      semesterNumber++;
    }
    semesterNumber++;

    workTypes.forEach((workType, index) => {
      const workTypeItem = {
        ...workType,
        weekNumber,
        serialNumberWeek: index,
        semesterNumber,
      } as WorkType;

      if (!index) {
        currentWorkType = currentWorkType.filter(
          (item) => item.weekNumber !== weekNumber
        );

        currentWorkType.splice(weekNumber - offsetIndex, 0, workTypeItem);
      } else currentWorkType.push(workTypeItem);
    });

    processScheduleData.courses[course].workTypes = sortBy(currentWorkType, 'weekNumber');
    this.setProcessScheduleData(processScheduleData);
  }

  private errorHandler(error: HttpErrorResponse) {
    this.notificationService.showError(
      'Произошла ошибка. Пожалуйста обновите страницу.'
    );
    return throwError(() => error.message);
  }

  public setCoursesHandleData(value: TableDataType[]) {
    this.coursesHandleData$.next(value);
  }
}

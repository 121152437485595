import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, lastValueFrom, Observable} from "rxjs";
import {environment, getData$} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {NotificationsService} from "../notifications/notifications.service";
import {handleError} from "../../helpers/errorHandle-helper";
import {map} from "rxjs/operators";
import {
  AccessRights,
  GiaCurrentUserAccess,
  GiaSectionFilter,
  GiaUserAccess
} from "../../models/useraccess/gia/giaUserAccess";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {GiaTabsEnum, GiaTabsEnumList} from "../../models/gia/enums/giaTabs.enum";

@Injectable({
  providedIn: 'root'
})
export class GiaUserAccessService {

  public currentUserAccess$ = new BehaviorSubject<GiaCurrentUserAccess>(new GiaCurrentUserAccess());

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.userAccess}`;

  constructor(private http: HttpClient,
              private notificationsService: NotificationsService,
              private location: Location,
              private router: Router,) { }

  public getCurrentUserAccess(studentId?: string): Observable<GiaCurrentUserAccess> {
    return this.http.post<GiaCurrentUserAccess>(`${this.baseUrl}/CurrentUser`, {studentId: studentId})
      .pipe(catchError(handleError));
  }

  public getAccess(fio?: string): Observable<GiaUserAccess[]> {
    const filter = fio ? `?Fio=${fio}` : '';
    return this.http.get<GiaUserAccess[]>(this.baseUrl + filter)
      .pipe(catchError(handleError));
  }

  public async hasEditingGia(filter: GiaSectionFilter): Promise<boolean> {
    let allowEdit = false;
    const access$ = this.http.post<AccessRights>(this.baseUrl + '/HasRightsInGiaSections', filter)
    await lastValueFrom(access$).then(value => { allowEdit = value == AccessRights.Write });

    return allowEdit;
  }

  public createAccess(useraccess:GiaUserAccess) {
    return this.http.post<GiaUserAccess>(this.baseUrl, useraccess)
      .pipe(catchError(handleError)).subscribe({
        next: () => {
          getData$.next(true);
          this.notificationsService.showSuccess("Добавлено");
        },
        error: () =>  {
          this.notificationsService.showError("Произошла ошибка")
        }
      });
  }

  public updateAccess(useraccess: GiaUserAccess) {
    return this.http.put<GiaUserAccess>(this.baseUrl, useraccess)
      .pipe(catchError(handleError)).subscribe({
        next: () => {
          getData$.next(true);
          if (useraccess.personId === this.currentUserAccess$.value.personId) {
            this.currentUserAccess$.next(new GiaCurrentUserAccess());
          }
          this.notificationsService.showSuccess("Успешно");
        },
        error: () =>  {
          this.notificationsService.showError("Произошла ошибка")
        }
      });
  }

  public deleteAccess(id: string): Observable<GiaUserAccess> {
    return this.http.delete<GiaUserAccess>(this.baseUrl + `/${id}`)
      .pipe(catchError(handleError));
  }
}

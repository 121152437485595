import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { DictComponentType } from 'src/app/models/education/dictcomponenttype.model';
import { DictComponentTypeService } from 'src/app/services/education/DictComponentType/dict-component-type.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'app-dict-component-type',
  templateUrl: './dict-component-type.component.html',
  styleUrls: ['./dict-component-type.component.scss']
})
export class DictComponentTypeComponent implements OnInit {

  public gridStyle = 'grid-default';
  public isOpened: boolean = false;
  public loading: boolean = false;
  public editable?: boolean;
  public isNew: boolean = false;
  public componentTypes!: DictComponentType[];

  constructor(private componentTypeService: DictComponentTypeService,
    private notificationService: NotificationsService,
    private userAccessService:EducationUserAccessService) { }

  ngOnInit(): void {
    this.getAccessLevel()
    this.getAllComponentType();
  }

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictComponentTypeId: new FormControl(0),
    dictComponentTypeName: new FormControl(""),
    dictComponentTypeSerialNumber: new FormControl(),
  });

  //Getting all entries from faculty
  public getAllComponentType() {
    this.loading = true;
    this.componentTypeService.getAllComponentTypes()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.componentTypes = response;
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить список типов компонентов ")
        }
      });
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public saveComponentType() {
    // Add new component type
    if (this.isNew) {
      this.componentTypeService.addComponentType(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Тип компонента был успешно добавлен!");
              this.getAllComponentType();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить тип компонента");
              this.onCancel();
            }
          });
    }
    // Edit component type
    else {
      this.componentTypeService.updateComponentType(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Тип компонента был успешно сохранен!");
              this.getAllComponentType();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось изменить тип компонента');
              this.onCancel();
            }
          });
    }
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }
}

const formGroup = (dataItem: {
  dictComponentTypeId?: any;
  dictComponentTypeName?: any;
  dictComponentTypeSerialNumber?: any; }) =>
    new FormGroup({
      dictComponentTypeId: new FormControl(dataItem.dictComponentTypeId),
      dictComponentTypeName: new FormControl(dataItem.dictComponentTypeName),
      dictComponentTypeSerialNumber: new FormControl(dataItem.dictComponentTypeSerialNumber),
    });
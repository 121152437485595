<mfc-settings></mfc-settings>
<h1>Конструктор заявок</h1>

<button kendoButton [primary]="true" icon="plus" (click)="addConstructor()">Добавить</button>

<div class="container">
    <kendo-grid
            class="grid-data borderless"
            [data]="gridView"
            [pageable]="pagerSettings"
            [sortable]="true"
            [sort]="sort"
            [skip]="skip"
            [pageSize]="pageSize"
            [filterable]="true"
            [rowClass]="rowCallback"
            (cellClick)="onCellClick($event)"
            (sortChange)="sortChange($event)"
            (pageChange)="pageChange($event)"
            [loading]="loading"
    >
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <kendo-grid-column [hidden]="true" field="category.name">
            <ng-template kendoGridGroupHeaderTemplate let-group="group">
                <span>{{ group.value }}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="245" field="status" title="Статус">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect (onFilterChange)="filterChange($event, column)"
                                    [data]="constructorStatuses"
                                    [ngModel]="filters.status"
                                    ngDefaultControl
                                    gridField="status"
                                    textField="name"
                                    valueField="name">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                {{ dataItem.status.name }}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="245" field="type" title="Тип">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictApplicationTypes"
                                    (onFilterChange)="filterChange($event, column)"
                                    gridField="dictApplicationType" textField="name" valueField="id">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                {{dataItem[column.field].name}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="245" field="name" title="Наименование шаблона заявки">
            <ng-template kendoGridFilterCellTemplate>
                <kendo-textbox (valueChange)="filterData()" [(ngModel)]="nameFilter"></kendo-textbox>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="245" field="trainingLevels" title="Уровень подготовки">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictTrainingLevels"
                                    (onFilterChange)="filterChange($event, column)"
                                    gridField="trainingLevels" textField="name" valueField="id">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                <ng-container *ngIf="!dataItem.isAllTrainingLevelAvailable; else allTemplate">
                    <span *ngFor="let item of dataItem[column.field]; let isLast=last">
                        {{ item.name }}{{ isLast ? '' : ', ' }}
                    </span>
                </ng-container>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="245" field="faculties" title="Институт/факультет">
            <ng-template kendoGridFilterCellTemplate let-column="column">
              <filter-multiselect-tree
                [data]="filialTree"
                (onFilterChange)="filterChange($event, column)"
                gridField="faculties"
                childrenField="faculties"
                valueField="id"
                textField="name">
              </filter-multiselect-tree>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                <ng-container *ngIf="!dataItem.isAllFacultyAvailable; else allTemplate">
                    <span *ngFor="let item of dataItem[column.field]; let isLast=last">
                        {{ item.name }}{{ isLast ? '' : ', ' }}
                    </span>
                </ng-container>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="245" field="studyForms" title="Форма обучения">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictStudyForms"
                                    (onFilterChange)="filterChange($event, column)"
                                    gridField="studyForms" textField="name" valueField="id">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                <ng-container *ngIf="!dataItem.isAllStudyFormAvailable; else allTemplate">
                    <span *ngFor="let item of dataItem[column.field]; let isLast=last">
                        {{ item.name }}{{ isLast ? '' : ', ' }}
                    </span>
                </ng-container>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="245" field="citizenShips" title="Гражданство">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect [data]="dictCitizenShips"
                                    (onFilterChange)="filterChange($event, column)"
                                    gridField="citizenShips" textField="name" valueField="id">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                <ng-container *ngIf="!dataItem.isAllCitizenshipAvailable; else allTemplate">
                    <span *ngFor="let item of dataItem[column.field]; let isLast=last">
                        {{ item.name }}{{ isLast ? '' : ', ' }}
                    </span>
                </ng-container>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

<ng-template #allTemplate>
    Все
</ng-template>
<dicts-header></dicts-header>
<h2 class="disciplineTitle">{{currentDiscipline?.disciplineName}}</h2>

<div class="buttons">
  <button kendoButton class="backButton" (click)="moveToDisciplines()">Назад</button>
</div>
<div class="grid-container">
    <kendo-grid [kendoGridBinding]="dictDisciplineInfos" class="grid" [data]="dictDisciplineInfos"
              [height]="700"
              [pageable]="{
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: positionPager
              }"
              [pageSize]="20"
              [filterable]="true"
              [sortable]="true">

            <kendo-grid-column field="location"
            [filterable]="false" 
            title="Расположение"
            [width]="120"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.location}}
                          </span>
                    </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="educationPlanName"
            title="Учебный план"
            [width]="150"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <a class="alignCells" style="color: #50B9F1; text-decoration: underline;" (click)="goToEduPlan(dataItem.educationProgramId, dataItem.externalId)">
                              {{dataItem.educationPlanName}}
                      </a>
                    </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                            </kendo-grid-string-filter-cell>
                    </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="year"
            title="Год"
            [width]="100"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                            </kendo-grid-string-filter-cell>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.year}}
                          </span>
                    </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="educationStandardName"
            title="Направление"
            [width]="200"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                            </kendo-grid-string-filter-cell>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.educationStandardName}}
                          </span>
                    </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="educationProgramName"
            title="Направление (профиль)"
            [width]="200"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                            </kendo-grid-string-filter-cell>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.educationProgramName}}
                          </span>
                    </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
            field="standardTypeName"
            title="Стандарт"
            [width]="100"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridFilterCellTemplate let-filter>
                            <filter-dropdown gridField="standardTypeName" [filter]="filter" [data]="dictStandardTypes" textField="standardTypeShortName"
                                             valueField="standardTypeShortName">
                            </filter-dropdown>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.standardTypeName}}
                          </span>
                    </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="studyFormName"
            title="Форма обучения"
            [width]="130"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridFilterCellTemplate let-filter>
                            <filter-dropdown gridField="studyFormName" [filter]="filter" [data]="studyForms" textField="studyFormName"
                                             valueField="studyFormName">
                            </filter-dropdown>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.studyFormName}}
                          </span>
                    </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="trainingLevelName"
            title="Уровень подготовки"
            [width]="150"
            headerClass="gridHeader dictionaries">
                    <ng-template kendoGridFilterCellTemplate let-filter>
                            <filter-dropdown gridField="trainingLevelName" [filter]="filter" [data]="trainingLevels" textField="trainingLevelName"
                                             valueField="trainingLevelName">
                            </filter-dropdown>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.trainingLevelName}}
                          </span>
                    </ng-template>
        </kendo-grid-column>
</kendo-grid>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Emails } from '../../models/contingent/emails.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';
import { MedicalCertificate } from 'src/app/models/contingent/medicalcertificate.model';

@Injectable({
  providedIn: 'root'
})
export class MedicalcertificateService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.medicalcertificate}`;

  constructor(private http: HttpClient) { }

  //Get By StudPersonId
  getStudentMedicalCertificates(id: string): Observable<MedicalCertificate[]> {
    return this.http.get<MedicalCertificate[]>(this.baseUrl + '/GetStudentMedicalCertificate' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add MedicalCertificate
  addMedicalCertificate(medicalCertificate: MedicalCertificate, studentId: string): Observable<MedicalCertificate> {
    return this.http.post<MedicalCertificate>(this.baseUrl + '/' + studentId, medicalCertificate)
      .pipe(catchError(this.handleError));
  }

  //Delete MedicalCertificate
  deleteMedicalCertificate(id: string, studentId: string): Observable<Emails> {
    return this.http.delete<Emails>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit MedicalCertificate
  updateMedicalCertificate(medicalCertificate: MedicalCertificate, studentId: string): Observable<MedicalCertificate> {
    return this.http.put<MedicalCertificate>(this.baseUrl + '/' + medicalCertificate.externalId + '/' + studentId, medicalCertificate)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

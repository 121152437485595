enum DictPrintTemplateCategoryEnumList {
  Приказ_о_составе_ГЭК = 1,
  Приказ_о_составе_экзаменаторов = 2,
  Приказ_об_апелляционной_комиссии = 3,
  Приказ_о_внесении_изменений_в_состав_ГЭК = 4,
  Приказ_о_внесении_изменений_в_состав_экзаменаторов = 5,
  Приказ_о_внесении_изменений_в_состав_апелляционной_комиссии = 6,
  Приказ_о_допуске = 7,
  Протокол_ГИА = 8,
  Дипломы = 9,
  Приказ_о_выпуске = 10,
  Книга_выдачи = 11
}
export var DictPrintTemplateCategoryEnum: any = DictPrintTemplateCategoryEnumList

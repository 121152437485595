import {SignTypes} from '../enums/sign-types.enum';

export interface SignType {
  enum: SignTypes;
  value: string;
}

export const signTypes = [
  {enum: SignTypes.Simple, value: 'Простая ЭП'},
  {enum: SignTypes.Enhance, value: 'Усиленная квалифицированная ЭП'},
];

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DictTypeWorkDisciplineAdditional } from '../../../models/education/dictTypeWorkDisciplineAdditional.model';
import { DictTypeWorkDisciplineAdditionalService } from '../../../services/education/DictTypeWorkDisciplineAdditional/dictTypeWorkDisciplineAdditional.service';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { EducationUserAccessService } from '../../../services/useraccess/education-user-access.service';

@Component({
  selector: 'app-dict-type-work-discipline-additional',
  templateUrl: './dict-type-work-discipline-additional.component.html',
  styleUrls: ['./dict-type-work-discipline-additional.component.scss']
})
export class DictTypeWorkDisciplineAdditionalComponent implements OnInit {

  public gridStyle = 'grid-default';
  public isOpened: boolean = false;
  public loading: boolean = false;
  public editable?: boolean;
  public isNew: boolean = false;
  public dictTypeWorkDisciplineAdditionals!: DictTypeWorkDisciplineAdditional[];

  constructor(private dictTypeWorkDisciplineAdditionalService: DictTypeWorkDisciplineAdditionalService,
    private notificationService: NotificationsService,
    private userAccessService: EducationUserAccessService) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getAllDictTypeWorkDisciplineAdditionals();
  }

  public editForm: FormGroup = new FormGroup ({
    dictTypeWorkDisciplineAdditionalExternalId: new FormControl(""),
    dictTypeWorkDisciplineAdditionalName: new FormControl(""),
    dictTypeWorkDisciplineAdditionalShortName: new FormControl(""),
    dictTypeWorkDisciplineAdditionalClassroomWork: new FormControl(""),
    dictTypeWorkDisciplineAdditionalIsRecognizingAmounts: new FormControl(""),
    dictTypeWorkDisciplineAdditionalIsActual: new FormControl(""),
  });

  public getAllDictTypeWorkDisciplineAdditionals() {
    this.loading = true;
    this.dictTypeWorkDisciplineAdditionalService.getAll()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.dictTypeWorkDisciplineAdditionals = response;
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить дополнительные работы по дисциплине")
        }
      });
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public saveDictTypeWorkDisciplineAdditional() {
    // Add
    if (this.isNew) {
      this.dictTypeWorkDisciplineAdditionalService.add(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Дополнительные работы по дисциплине успешно добавлены!");
              this.getAllDictTypeWorkDisciplineAdditionals();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить дополнительные работы по дисциплине");
              this.onCancel();
            }
          });
    }
    // Edit
    else {
      this.dictTypeWorkDisciplineAdditionalService.update(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("дополнительные работы по дисциплине успешно сохранены!");
              this.getAllDictTypeWorkDisciplineAdditionals();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось изменить дополнительные работы по дисциплине');
              this.onCancel();
            }
          })
    }
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }
}

const formGroup = (dataItem: {
    dictTypeWorkDisciplineAdditionalExternalId?: any;
    dictTypeWorkDisciplineAdditionalName?: any;
    dictTypeWorkDisciplineAdditionalShortName?: any;
    dictTypeWorkDisciplineAdditionalClassroomWork?: any;
    dictTypeWorkDisciplineAdditionalIsRecognizingAmounts?: any;
    dictTypeWorkDisciplineAdditionalIsActual?: any; }) =>
    new FormGroup({
        dictTypeWorkDisciplineAdditionalExternalId: new FormControl(dataItem.dictTypeWorkDisciplineAdditionalExternalId),
        dictTypeWorkDisciplineAdditionalName: new FormControl(dataItem.dictTypeWorkDisciplineAdditionalName),
        dictTypeWorkDisciplineAdditionalShortName: new FormControl(dataItem.dictTypeWorkDisciplineAdditionalShortName),
        dictTypeWorkDisciplineAdditionalClassroomWork: new FormControl(dataItem.dictTypeWorkDisciplineAdditionalClassroomWork),
        dictTypeWorkDisciplineAdditionalIsRecognizingAmounts: new FormControl(dataItem.dictTypeWorkDisciplineAdditionalIsRecognizingAmounts),
        dictTypeWorkDisciplineAdditionalIsActual: new FormControl(dataItem.dictTypeWorkDisciplineAdditionalIsActual),
});

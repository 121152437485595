import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/publications/errorHandle-helper";
import {
  DictPrintTemplateCategoryModel
} from "../../models/gia/dicts/dictPrintTemplateCategory.model";

@Injectable({
  providedIn: 'root'
})
export class DictPrintTemplateCategoryService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.dictPrintTemplateCategory}`;
  constructor(private http: HttpClient) { }

  getList(): Observable<DictPrintTemplateCategoryModel[]> {
    return this.http.get<DictPrintTemplateCategoryModel[]>(this.baseUrl);
  }

  update(dictPrintTemplateCategory: DictPrintTemplateCategoryModel): Observable<DictPrintTemplateCategoryModel> {
    return this.http.put<DictPrintTemplateCategoryModel>(this.baseUrl, dictPrintTemplateCategory)
      .pipe(catchError(handleError));
  }
}

<education-header></education-header>
<h1>Ограничения стандарта</h1>
<div class="selectContent">
  <dd style="display: flex; align-items: center">
    <span class="selectText">
      Уровень подготовки
    </span>
    <kendo-dropdownlist [data]="trainingLevels"
                        textField="trainingLevelName"
                        valueField="trainingLevelExternalId"
                        [valuePrimitive]="true"
                        name="trainingLevel"
                        [(ngModel)]="filter.trainingLevelId"
                        [defaultItem]="defaultItemTrainingLevel"
                        (valueChange)="onValueChange()"
                        style="width: 400px;">
    </kendo-dropdownlist>
  </dd>
  
  <dd style="display: flex; align-items: center">
    <span class="selectText">
      Тип стандарта
    </span>
    <kendo-dropdownlist [data]="standardTypes"
                        textField="standardTypeShortName"
                        valueField="standardTypeExternalId"
                        [valuePrimitive]="true"
                        name="standardType"
                        (valueChange)="onValueChange()"
                        [(ngModel)]="filter.standardTypeId"
                        [defaultItem]="defaultItemStandardType"
                        style="width: 400px;">
    </kendo-dropdownlist>
  </dd>
</div>
<div class="buttons" *ngIf="isInEditingMode" style="margin-top: 20px;">
  <button kendoButton
  (click)="cancelHandler()">
    Отмена
  </button>
  <button kendoButton
          themeColor="primary"
          [disabled]="formGroup!.invalid"
          (click)="saveCurrent()" style="margin-left: 20px;">
    Сохранить
  </button>
</div>

<div class="grid-container">
  <kendo-grid [data]="gridView"
              [height]="500"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)">

    <kendo-grid-column headerClass="gridHeader dictionaries" field="name" title="Название ограничения"
                       [width]="300">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getDictStandardLimitationById(dataItem.dictStandardLimitationExternalId)?.name }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="orderNumber" title="Порядковый номер"
                       [width]="100">
       <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-textbox class="border" [formControl]="formGroup.get('orderNumber')">
          </kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getDictStandardLimitationById(dataItem.dictStandardLimitationExternalId)?.orderNumber }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="unit" title="Единица измерения"
                       [width]="70">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getDictStandardLimitationById(dataItem.dictStandardLimitationExternalId)?.unit }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="unitTypes"
                            [formControl]="formGroup.get('unit')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="dictGroupStandardLimitationId" title="Группа ограничений"
                       [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getDictGroupStandardLimitationById(dataItem.dictGroupStandardLimitationId)?.groupStandardLimitationName }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="dictGroups"
                            textField="groupStandardLimitationName"
                            valueField="dictGroupStandardLimitationExternalId"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('dictGroupStandardLimitationId')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="isVerifiable" title="Включить в проверку"
                       [width]="70" 
                       editor="boolean">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getDictStandardLimitationById(dataItem.dictStandardLimitationExternalId)?.isVerifiable ? "Да" : "Нет" }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <!-- <div
        kendoTooltip
        [tooltipTemplate]="template"
        filter="a[href]"
        style="padding-top: 10px;"
      >
      <ng-template #template kendoGridCellTemplate let-dataItem let-anchor>
        <span>{{ anchor.nativeElement.getAttribute("href") }}</span>
      </ng-template>
      <a href="https://en.wikipedia.org/wiki/Web_application">web-based</a>,
      </div> -->
    
    <kendo-grid-column headerClass="gridHeader dictionaries" title="Описание" [width]="60">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          <div *ngIf="dataItem.tooltip != null && dataItem.tooltip != ''" kendoTooltip class="tooltip k-icon k-i-info k-icon-md" [title]="dataItem.tooltip"></div>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div class="buttons" *ngIf="isInEditingMode" style="margin-top: 20px;">
    <button kendoButton
    (click)="cancelHandler()">
      Отмена
    </button>
    <button kendoButton
            themeColor="primary"
            [disabled]="formGroup!.invalid"
            (click)="saveCurrent()" style="margin-left: 20px;">
      Сохранить
    </button>
  </div>
</div>

<div kendoDialogContainer></div>
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import {
  ControlActions,
  DisciplineStatement,
  StudentByDiscipline,
  StudentInSession,
} from 'src/app/models/middlecontrol/groupInSession.model';
import { MarksModel } from 'src/app/models/middlecontrol/marks.model';
import { SessionDate } from 'src/app/models/middlecontrol/sesssiondate.model';
import { environment } from '../../../environments/environment';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import * as moment from "moment/moment";
import { DictMarkChangeReason } from 'src/app/models/middlecontrol/dictMarkChangeReason.model';
import { Guid } from 'guid-typescript';
import {
  MiddleControlSheetDisciplineQuery,
  MiddleControlSheetHeaderQuery,
  MiddleControlSheetListQuery
} from "../../models/middlecontrol/query.model";
import {StudentExaminer} from "../../models/middlecontrol/studentExaminer.model";

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  baseUrl = `${environment.apiEndpoint}`;
  disciplineInfo$ = new BehaviorSubject<Partial<DisciplineStatement>>({});
  sessionDisciplines$ = new BehaviorSubject<ControlActions[]>([]);
  markDate$ = new BehaviorSubject<Date>(new Date());
  marksStatistic$ = new BehaviorSubject<MarksModel[]>([]);

  constructor(
    private httpClient: HttpClient,
    private notifications: NotificationsService
  ) {}

  public getSheetHeader(
      request: MiddleControlSheetHeaderQuery
  ): Observable<ControlActions[]> {
    return this.httpClient
      .post<ControlActions[]>(
        this.baseUrl +
          `${environment.apiPaths.session.sheetheader}`, request
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список дисциплин для группы'
          );
          return of([] as ControlActions[]);
        })
      );
  }

  public getSheetStudentsList(
    request: MiddleControlSheetListQuery
  ): Observable<StudentInSession[]> {
    return this.httpClient
      .post<StudentInSession[]>(
        this.baseUrl +`${environment.apiPaths.session.sheetstudentlist}`, request)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список студентов в группе'
          );
          return of([] as StudentInSession[]);
        })
      );
  }

  public getSheetDiscipline(
    disciplineId: string,
    eduGroupId: string,
    semesterNumber: number,
    electiveDisciplineId?: string | null
  ): Observable<DisciplineStatement> {
    return this.httpClient
      .get<DisciplineStatement>(
        this.baseUrl +
          `${environment.apiPaths.session.info}/${disciplineId}/${eduGroupId}/${semesterNumber}` +
          (electiveDisciplineId ? `/${electiveDisciplineId}` : '')
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить информацию о дисциплине'
          );
          return of({} as DisciplineStatement);
        })
      );
  }

  public getSheetStudentsByDiscipline(
    request: MiddleControlSheetDisciplineQuery
  ): Observable<StudentByDiscipline[]> {
    this.getSheetDiscipline(
      request.disciplineId,
      request.eduGroupId,
      request.semester,
      request.electiveDisciplineId
    ).subscribe((response) => this.disciplineInfo$.next(response));
    return this.httpClient
      .post<StudentByDiscipline[]>(
        this.baseUrl +`${environment.apiPaths.session.students}`, request
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список студентов для дисциплины'
          );
          return of([] as StudentByDiscipline[]);
        })
      );
  }

  public getDisciplineType() {
    return this.disciplineInfo$.getValue()?.type || '';
  }

  public changeMarkDate(
    students: (Pick<StudentByDiscipline, 'studentMarkId' | 'markDate'> & {
      dictMarkChangeReasonId: string | null;
    })[]
  ) {
    return this.httpClient
      .post<any>(
        this.baseUrl + environment.apiPaths.session.changemarkdate,
        students
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось сохранить дату оценки');
          return of([]);
        })
      );
  }

  public changeMarksDiscipline(disciplineId: string, students: any[]) {
    return this.httpClient
      .post<any>(this.baseUrl + environment.apiPaths.session.addmark, {
        disciplineId,
        students,
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось сохранить оценку');
          return of([]);
        })
      );
  }

  public changeDisciplineSheetStatus(data: {
    sheetId: string;
    dictSheetStatusId: string;
  }) {
    return this.httpClient
      .post<any>(this.baseUrl + environment.apiPaths.session.changestatus, data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось изменить статус ведомости');
          return of([]);
        })
      );
  }

  public convertDateToUTCDateOnly(date: Date) {
    return moment(date).utc(true).format('YYYY-MM-DDT00:00:00') + 'Z'
    //return date.toISOString().replace(/,/g, '.').split('.')[0] + 'Z';
  }

  public saveDisciplineSheet(saveData: {
    disciplineId: string;
    controllingActionDate: string | null;
    sheetDate: string | null;
    semesterNum: number;
    eduGroupId: string;
  }) {
    return this.httpClient
      .post<any>(this.baseUrl + environment.apiPaths.session.save, saveData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось сохранить ведомость');
          return of([]);
        })
      );
  }

  public changeProjectName(saveData: {
    studentId: string;
    projectName: string;
    disciplineId: string;
  }) {
    return this.httpClient
      .post<any>(
        this.baseUrl + environment.apiPaths.session.changeprojectname,
        saveData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось сохранить название курсовой работы'
          );
          return of([]);
        })
      );
  }

  public addExaminer(saveData: any) {
    return this.httpClient
      .post<any>(this.baseUrl + environment.apiPaths.session.examiner, saveData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось добавить преподавателя');
          return of([]);
        })
      );
  }

  public changeExaminer(teacherId: string, saveData: any) {
    return this.httpClient
      .put<any>(
        this.baseUrl + environment.apiPaths.session.examiner + '/' + teacherId,
        saveData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось изменить преподавателя');
          return of([]);
        })
      );
  }

  public deleteExaminer(examinerId: string) {
    return this.httpClient
      .delete<any>(
        this.baseUrl + environment.apiPaths.session.examiner + '/' + examinerId
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось удалить преподавателя');
          return of([]);
        })
      );
  }

  public addStudentExaminer(saveData: StudentExaminer) {
    return this.httpClient
      .post<StudentExaminer>(this.baseUrl + environment.apiPaths.session.examiner + '/AddStudentExaminer', saveData)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось добавить преподавателя');
          return of();
        })
      );
  }

  public addListStudentExaminer(saveData: StudentExaminer) {
    return this.httpClient
      .post<StudentExaminer[]>(
        this.baseUrl + environment.apiPaths.session.examiner + '/AddStudentExaminerList',
        saveData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось изменить преподавателя');
          return of([]);
        })
      );
  }

  public deleteStudentExaminer(examinerId: string) {
    return this.httpClient
      .delete<StudentExaminer>(
        this.baseUrl + environment.apiPaths.session.examiner + '/DeleteStudentExaminer/' + examinerId
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось удалить преподавателя');
          return of([]);
        })
      );
  }

  public getSessionDateByStudent(studentId: string, semesterNum: number) {
    return this.httpClient
      .get<SessionDate[]>(
        this.baseUrl +
          environment.apiPaths.session.session +
          `/${studentId}/${semesterNum}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить индивидуальные сроки сессии для студента'
          );
          return of([] as SessionDate[]);
        })
      );
  }

  public saveSessionDateByStudent(
    studentId: string,
    semesterNum: number,
    studentSession: (Omit<SessionDate, 'studentSessionDisciplines'> & {
      studentSessionDisciplines: string[];
    })[]
  ) {
    return this.httpClient
      .post<SessionDate[]>(
        this.baseUrl +
          environment.apiPaths.session.savesession +
          `/${studentId}/${semesterNum}`,
        studentSession
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось сохранить индивидуальные сроки сессии для студента'
          );
          return of([] as SessionDate[]);
        })
      );
  }

  public addMarkChangeReason(dto: DictMarkChangeReason) {
    return this.httpClient
    .post<DictMarkChangeReason>(
      this.baseUrl +
        environment.apiPaths.session.reasons,
      dto
    );
  }

  public updateMarkChangeReason(dto: DictMarkChangeReason) {
    return this.httpClient
    .put<DictMarkChangeReason>(
      this.baseUrl +
        environment.apiPaths.session.reasons,
      dto
    );
  }

  public deleteMarkChangeReason(id: Guid) {
    return this.httpClient
    .delete<DictMarkChangeReason>(
      this.baseUrl +
        environment.apiPaths.session.reasons + `/${id}`
    );
  }

  public getMarkChangeReason() {
    return this.httpClient
    .get<DictMarkChangeReason[]>(
      this.baseUrl +
        environment.apiPaths.session.reasons
    );
  }
}

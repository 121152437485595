export enum TrainingLevelEnum {
  Specialty = 1,
  Bachelor,
  Master,
  Graduate,
  Residency,
  Listener,
  College,
}

export const trainingLevelEnumList: {text: string, value: TrainingLevelEnum}[] = [
  {text: 'Специалитет', value: TrainingLevelEnum.Specialty},
  {text: 'Бакалавриат', value: TrainingLevelEnum.Bachelor},
  {text: 'Магистратура', value: TrainingLevelEnum.Master},
  {text: 'Аспирантура', value: TrainingLevelEnum.Graduate},
  {text: 'Ординатура', value: TrainingLevelEnum.Residency},
  {text: 'Слушатель', value: TrainingLevelEnum.Listener},
  {text: 'СПО', value: TrainingLevelEnum.College},
];

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { items } from 'src/app/models/disciplineworkload/menu-items';
import {DisciplineWorkloadUserAccessService} from "../../../services/useraccess/disciplineworkload-user-access.service";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../../../models/useraccess/role";
import { PersonService } from '../../../services/disciplineworkload/externals/person.service';

@Component({
  selector: 'workload-settings',
  templateUrl: './workload-settings.component.html',
  styleUrls: ['./workload-settings.component.scss']
})
export class WorkloadSettingsComponent implements OnInit {
  //Menu items
  public items: any[] = items;
  //Editing rights
  public accesses: any;

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private router: Router,
              private userAccess: DisciplineWorkloadUserAccessService,
              private personService: PersonService,
              private tokenService: TokenStorageService,
              private jwt: JwtHelperService) {
  }

  // Получаем информацию по права доступа у текущего пользователя
  private getUserAccessRights(): void {
    const token = this.jwt.decodeToken(this.tokenService.getToken().accessToken);
    this.userAccess.getAccess().subscribe({
      next: (response) => {
        this.accesses = response.userAccesses.filter((item: { personId: any; }) => item.personId === token.person_id);
        this.menuHandle(token);
      }
    })
  }

  private menuHandle(token: any) {
    //console.log(token);
    //console.log(this.accesses);

    const contingentLoadAccessLevel = this.accesses?.length > 0
      ? Math.max(...this.accesses.map((_: any) => _.contingentLoadAccess))
      : 0;
    const assignementsAccessLevel = this.accesses?.length > 0
      ? Math.max(...this.accesses.map((_: any) => _.errandsAccess))
      : 0;
    const cathedralLoadAccessLevel = this.accesses?.length > 0
      ? Math.max(...this.accesses.map((_: any) => _.subdepartmentLoadAccess))
      : 0;
    const userAccessSettings = this.accesses?.length > 0
      ? Math.max(...this.accesses.map((_: any) => _.isModerator ? 1 : 0))
      : 0;
    const teacherLoadAccessLevel = this.accesses?.length > 0
      ? Math.max(...this.accesses.map((_: any) => _.teacherLoadAccess))
      : 0;
    const reportsAccessLevel = this.accesses?.length > 0
      ? Math.max(...this.accesses.map((_: any) => _.reportsAccess))
      : 0;
    const assignmentMonitoringAccess = this.accesses?.length > 0
      ? Math.max(...this.accesses.map((_: any) => _.assignmentMonitoringAccess))
      : 0;

    //console.log('contingentLoadAccessLevel', contingentLoadAccessLevel);
    //console.log('assignementsAccessLevel', assignementsAccessLevel);
    //console.log('cathedralLoadAccessLevel', cathedralLoadAccessLevel);
    //console.log('userAccessSettings', userAccessSettings);
    //console.log('teacherLoadAccessLevel', teacherLoadAccessLevel);
    //console.log('reportsAccessLevel', reportsAccessLevel);

    if(token.role?.includes(Role.Admin)) {
      //console.log('is Admin');
      return;
    }
    this.items[0].items = this.items[0].items.filter((item: { pps: boolean; }) => !item.pps);
    // Проверка на доступ в контингент в нагрузку
    if(contingentLoadAccessLevel === 0) {
      //console.log('No access to contingent');
      this.items[0].items = this.items[0].items.filter((item: { contingentLoad: boolean; }) => !item.contingentLoad);
    }
    // Проверка на доступ в поручения
    if(assignementsAccessLevel === 0 ) {
      //console.log('No access to assignments');
      this.items[0].items = this.items[0].items.filter((item: { assignments: boolean; }) => !item.assignments);
    }
    // Проверка на доступ в кафедральную нагрузку
    if(cathedralLoadAccessLevel === 0 ) {
      //console.log('No access to cathedral load');
      this.items[0].items = this.items[0].items.filter((item: { cathedralLoad: boolean; }) => !item.cathedralLoad);
      //this.items[0].items = this.items[0].items.filter((item: { pps: boolean; }) => !item.pps);
    }
    // Проверка на доступ в настройки доступа
    if(!userAccessSettings) {
      //console.log('No access to userAccess settings');
      this.items[0].items = this.items[0].items.filter((item: { userAccess: boolean; }) => !item.userAccess);
    }
    // Проверка на доступ в нагрузку преподавателя
    if(teacherLoadAccessLevel === 0) {
      this.personService.hasPps().subscribe({
        next: value => {
          if (!value)
            this.items[0].items = this.items[0].items
              .filter((item: { teacherWorkload: boolean; }) => !item.teacherWorkload);
        },
        error: () => this.items[0].items = this.items[0].items
          .filter((item: { teacherWorkload: boolean; }) => !item.teacherWorkload)
      })
    }
    // Проверка на доступ в отчеты
    if(reportsAccessLevel === 0 ) {
      this.items[0].items = this.items[0].items.filter((item: {reportsWorkload: boolean; }) => !item.reportsWorkload);
    }
    // Проверка на доступ в мониторинг
    if(assignmentMonitoringAccess === 0 ) {
      this.items[0].items = this.items[0].items.filter((item: {assignmentsMonitoring: boolean; }) => !item.assignmentsMonitoring);
    }
  }

    ngOnInit(): void {
      this.getUserAccessRights();
    }
}

import {ButtonThemeColor} from '@progress/kendo-angular-buttons';
import { ContingentTabsEnumList } from "./enums/contingent-tabs.enum";
import {DisplaySettingEnumList} from "./enums/display-setting.enum";

export interface ContingentTab {
  title: string,
  titleFirstLine?: string,
  titleSecondLine?: string,
  color: ButtonThemeColor,
  disable?: boolean,
  value: ContingentTabsEnumList,
  display: DisplaySettingEnumList,
  style?: string,
}

export const contingentTabs: ContingentTab[] = [
  {
    title: "Персональные данные/документы",
    titleFirstLine: "Персональные данные/",
    titleSecondLine: "документы",
    color: "base",
    value: ContingentTabsEnumList.personaldata,
    display: DisplaySettingEnumList.personaldata,
    style: "height:50px;"
  },
  {
    title: "Успеваемость",
    color: "base",
    value: ContingentTabsEnumList.progress,
    display: DisplaySettingEnumList.progress,
  },
  {
    title: "Достижения",
    color: "base",
    value: ContingentTabsEnumList.achievements,
    display: DisplaySettingEnumList.achievements,
  },
  {
    title: "Целевое обучение",
    color: "base",
    value: ContingentTabsEnumList.targetedtraining,
    display: DisplaySettingEnumList.targetedtraining,
  },
  {
    title: "Приказы и распоряжения",
    color: "base",
    value: ContingentTabsEnumList.orders,
    display: DisplaySettingEnumList.orders,
    style: "padding-left:6px;"
  },
  {
    title: "Справки",
    color: "base",
    value: ContingentTabsEnumList.certificate,
    display: DisplaySettingEnumList.certificate,
  },
  {
    title: "Воинский учёт",
    color: "base",
    value: ContingentTabsEnumList.military,
    display: DisplaySettingEnumList.military,
  },
  {
    title: "Документы",
    color: "base",
    value: ContingentTabsEnumList.documents,
    display: DisplaySettingEnumList.documents,
  },
  {
    title: "История изменений",
    color: "base",
    value: ContingentTabsEnumList.history,
    display: DisplaySettingEnumList.history,
  },
  {
    title: "Карты",
    color: "base",
    value: ContingentTabsEnumList.cards,
    display: DisplaySettingEnumList.cards,
  },
  /*{
    title: "Стипендии",
    color: "base",
    disable: true,
    value: 6,
  },
  {
    title: "Социальная сфера",
    color: "base",
    disable: true,
    value: 7,
  },*/
];


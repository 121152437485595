import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {handleError} from "../../../helpers/errorHandle-helper";
import {DepartmentContactDetails} from "../../../models/mfc/dicts/department-contact-details.model";
import {NotificationsService} from "../../notifications/notifications.service";

@Injectable({
  providedIn: 'root'
})
export class DepartmentContactDetailsService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/DepartmentContactDetails/`;

  constructor(private http: HttpClient, private notificationsService: NotificationsService) { }

  public getDepartmentContactDetails(): Observable<DepartmentContactDetails[]> {
    return this.http.get<DepartmentContactDetails[]>(this.baseUrl)
      .pipe(map((data) => data ?? []));
  }

  public getDepartmentContactDetailsById(id: string): Observable<DepartmentContactDetails> {
    return this.http.get<DepartmentContactDetails>(`${this.baseUrl}${id}`);
  }

  public addDepartmentContactDetails(departmentContactDetails: DepartmentContactDetails): Observable<DepartmentContactDetails> {
    departmentContactDetails = this.patchDepartmentContactDetails(departmentContactDetails);
    return this.http.post<DepartmentContactDetails>(this.baseUrl, departmentContactDetails)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  public updateDepartmentContactDetails(departmentContactDetails: DepartmentContactDetails): Observable<DepartmentContactDetails> {
    departmentContactDetails = this.patchDepartmentContactDetails(departmentContactDetails);
    return this.http.put<DepartmentContactDetails>(this.baseUrl, departmentContactDetails)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  public removeDepartmentContactDetails(id: string): Observable<DepartmentContactDetails> {
    return this.http.delete<DepartmentContactDetails>(`${this.baseUrl}${id}`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  private patchDepartmentContactDetails(departmentContactDetails: DepartmentContactDetails): DepartmentContactDetails {
    return {
      ...departmentContactDetails,
      trainingLevels: departmentContactDetails.trainingLevels.filter(item => item)
    };
  }
}

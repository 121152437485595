import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Guid} from "guid-typescript";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {EducationService} from "../../../services/contingent/education.service";
import {EducationPlan, StudentEducationPlanList} from "../../../models/contingent/educationplan.model";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../helpers/dialogHelper";
import {StudplanService} from "../../../services/contingent/studplan.service";

@Component({
  selector: 'app-studplan',
  templateUrl: './studplan.component.html',
  styleUrls: ['./studplan.component.scss']
})
export class StudplanComponent implements OnInit {

  constructor( private router: Router,
               private activateRoute: ActivatedRoute,
               private educationService: EducationService,
               private notificationService: NotificationsService,
               private dialogService: DialogService,
               private studPlanService: StudplanService) {
    this.studentId = activateRoute.snapshot.params["studentId"];
    this.currentPlanId = this.router?.getCurrentNavigation()?.extras?.state?.['planId']
    if(this.currentPlanId === undefined)
      this.close()
  }

  ngOnInit(): void {
    this.getEducationPlanByStudent()
  }

  public studentId!: Guid;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public educationPlanList: StudentEducationPlanList[] = []
  public planId?: string;
  public currentPlanId?: Guid;
  public educationPlan: EducationPlan | undefined = undefined;

  getEducationPlanByStudent(){
    this.educationService.getEducationPlanByStudent(this.studentId.toString())
      .subscribe({
        next: (response) => {
          this.educationPlanList = response;
        },
        error: () => {
          this.notificationService.showError('Не удалось получить текущий учебный план');
        }}
      );
  }

  public planValueChange(id: any){
    this.getEducationPlan(id)
  }

  public getEducationPlan(id: any) {
    this.educationService.getEducationPlans(id)
      .subscribe(
        response => {
          this.educationPlan = response;
        }
      );
  }

  close(){
    this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/progress`).then();
  }

  onSave(template: TemplateRef<unknown>){
    let accelerated = this.educationPlanList.find((x: any) => x.planId == this.planId)?.acceleratedSign
    if(this.currentPlanId === this.planId)
      this.notificationService.showError("Выбранный учебный план совпадает с текущим", 5000)
    else this.dialog(template, accelerated)
  }

  dialog(template: any, accelerated?: boolean){
    const dialog: DialogRef = accelerated == true ?
      this.dialogService.open({
        title: "Пожалуйста подтвердите",
        content: template,
        actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
        width: 450,
        height: 210,
        minWidth: 250,
      })
      : openDialog(this.dialogService, `Вы действительно хотите сменить учебный план студента?`);

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.saveStudPlan()
        }
        else{
        }
      }
    });
  }

  saveStudPlan(){
    this.studPlanService.addstudplan({studentId: this.studentId, planId: this.planId})
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Успешно");
          this.close()
        },
        error:() => {
          this.notificationService.showError('Не удалось сменить учебный план');
        }}
      );
  }

  protected readonly undefined = undefined;
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {AllowedProperty, Property} from "../../../../../models/publications/editForm/property.model";
import {ActivatedRoute} from "@angular/router";
import {PropertiesService} from "../../../../../services/science/publications/properties.service";
import {KendoProperties, PublicationEditFormTabs, PublicationProperties} from "../../../../../models/publications/editForm/editForm.enums";
import {PublicationsEditFormService} from "../../../../../services/science/publications/editForm.service";
import {lastValueFrom, Subscription} from "rxjs";
import {PublicationsService} from "../../../../../services/science/publications/publications.service";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {PublicationsDictsService} from "../../../../../services/science/publications/dicts.service";
import {FileRestrictions, SelectEvent} from "@progress/kendo-angular-upload";
import {PublicationsFileService} from "../../../../../services/science/publications/file.service";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights, ConditionalRole} from "../../../../../models/useraccess/publications/publicationsUserAccess";
import {PublicationForm} from "../../../../../models/publications/editForm/publication.model";
import {DownloadFile} from "../../../../../helpers/downloadFile-helper";
import {StatusesService} from "../../../../../services/science/publications/statuses.service";
import {ComboBoxComponent, DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {PublicationsDict} from '../../../../../models/publications/dict/dict.model';

@Component({
  selector: 'publications-edit-form-about',
  templateUrl: './publications-edit-form-about.component.html',
  styleUrls: ['./publications-edit-form-about.component.scss']
})
export class PublicationsEditFormAboutComponent implements OnInit, OnDestroy {

  private publicationId!: string;
  private properties!: Property[];

  public isModerator: boolean = this.userAccessService.currentUserAccess$.value.conditionalRole === ConditionalRole.Moderator;
  private isUnsaved: boolean = false;
  public loading: boolean = false;
  private saveSubscription$!: Subscription;
  private clearSubscription$!: Subscription;
  private changeSubscription$!: Subscription;
  private statusSubscription$!: Subscription;

  public formGroup: FormGroup = this.getFormGroup();
  public maxFileCount = 5;
  public maxFileSizeInMb = 10;
  public fileRestrictions!: FileRestrictions;
  public currentPublication!: PublicationForm;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    private editFormService: PublicationsEditFormService,
    private statusesService: StatusesService,
    private publicationsService: PublicationsService,
    private dictService: PublicationsDictsService,
    private fileService: PublicationsFileService,
    private propertiesService: PropertiesService,
    private userAccessService: PublicationsUserAccessService
  ) {}

  async ngOnInit() {
    this.publicationId = this.activatedRoute.snapshot.params['publicationId'];
    this.formGroup.get('publicationId')!.patchValue(this.publicationId);
    this.getData();
    this.getFileRestrictions();
    this.subscribe();
  }

  private subscribe() {
    this.saveSubscription$ = this.editFormService.save$.subscribe(value => {
      if (PublicationEditFormTabs.About === value?.name) this.saveChanges();
    });
    this.clearSubscription$ = this.editFormService.clear$.subscribe(value => {
      if (value) this.clear();
    });
    this.changeSubscription$ = this.editFormService.change$.subscribe(value => {
      if (value === PublicationEditFormTabs.About) {
        this.editFormService.setIsSaved(!this.isUnsaved);
      }
    });
    this.statusSubscription$ = this.statusesService.statusData$.subscribe((value) => {
      if (value === PublicationEditFormTabs.About) {
        this.getPublication();
      }
    });
  }

  private getFileRestrictions() {
    this.fileService.getFileUploadRestrictions().subscribe(value => {
      this.fileRestrictions = {
        maxFileSize: value.maximumFileSize,
        allowedExtensions: value.extensions,
      };
      this.maxFileCount = value.maximumNumberOfFiles ?? 5;
      this.maxFileSizeInMb = value.maximumFileSizeInMb ?? 10;
    });
  }

  public getFileExtensionsString() {
    return this.fileRestrictions?.allowedExtensions ?
      `формата ${this.fileRestrictions.allowedExtensions.join(', ')}` : '';
  }

  private getData() {
    this.propertiesService
      .getProperties({publicationId: this.publicationId, categoryId: PublicationProperties.About})
      .subscribe(value => {
        this.properties = value;
        this.getPublication();
      });
  }

  private getPublication() {
    this.loading = true;
    this.publicationsService.getPublication(this.publicationId)
      .subscribe(value => {
        this.currentPublication = value;
        this.getProperties(value.typeId);
        this.loading = false;
      });
  }

  private getProperties(typeId: string) {
    this.propertiesService.getAllowedProperties({typeId: typeId, categoryId: PublicationProperties.About})
      .subscribe(async value => {
        this.formGroup = this.getFormGroup();
        this.mapFormArray(value);
        await this.handleFormArrayValues(value);
      });
  }

  private getFormGroup() {
    return new FormGroup({
      publicationId: new FormControl(this.publicationId),
      categoryId: new FormControl(PublicationProperties.About),
      properties: new FormArray([]),
      files: new FormControl([]),
      propertyFilesToDelete: new FormControl([])
    });
  }

  get formProperties(): FormArray {
    return <FormArray> this.formGroup.get('properties');
  }

  private mapFormArray(properties: AllowedProperty[]) {
    properties.forEach(item => {
      const disabled = this.isDynamicPropertyDisabled(item.allowModeratorOnly);
      this.formProperties.push(this.propertiesService.patchDynamicValues(item, disabled, true));
    });
  }

  private async handleFormArrayValues(properties: AllowedProperty[]) {
    for (const item of properties) {
      const index: number = properties.indexOf(item);
      if (item.dictId) {
        await this.patchValueFromDict(item.dictId, item, index, 'data');
      }

      if (item.dictLanguageId) {
        await this.patchValueFromDict(item.dictLanguageId, item, index, 'languageData');
      }

      if (item.toolTip) {
        this.formProperties.at(index).patchValue({tooltip: JSON.parse(JSON.stringify(item.toolTip))});
      }

      if (this.properties.length) {
        const multipleProperties = this.properties.filter(property => property.id === item.id);
        multipleProperties.forEach((property, propertyIndex) => {
          if (!propertyIndex) {
            this.propertiesService.convertFormGroupValuesForView(this.formProperties, item, index, property, this.publicationId);
            this.formProperties.at(index).get('isLast')?.patchValue(multipleProperties.length === 1);
            return;
          }
          const disabled = this.isDynamicPropertyDisabled(item.allowModeratorOnly);
          const formGroup = this.propertiesService.patchDynamicValues(item, disabled, true);
          formGroup.patchValue({
            languageCode:  property.languageCode,
            value: property.value,
            isLast: multipleProperties.length === propertyIndex + 1,
          });
          this.formProperties.push(formGroup);
        });
      }
    }
  }

  private async patchValueFromDict(id: string, item: AllowedProperty, index: number, key: keyof AllowedProperty) {
    await lastValueFrom(this.dictService.getDictData(id, true)).then((dictData) => {
      (item[key] as PublicationsDict[]) = dictData;
      this.formProperties.at(index).patchValue({[key]: dictData});
    });
  }

  public onFieldChange() {
    this.isUnsaved = true;
  }

  public onFileRemove(value: any) {
    const fileId = value.files[0].id;
    if (fileId) {
      this.formGroup.value.propertyFilesToDelete.push(fileId);
    }
  }

  public onFileSelect(e: SelectEvent, value: any[]) {
    const fileCount = value?.length ?? 0;
    if (fileCount + e.files.length > this.maxFileCount) {
      this.notificationsService.showError(`Можно добавить не более ${this.maxFileCount} файлов`);
      e.files.splice(this.maxFileCount - fileCount);
    }
  }

  public onFilesDownload(files: any[]) {
    files.forEach((file) => {
      if (file instanceof File) {
        DownloadFile(file);
      } else {
        this.fileService.getPropertyFile(file.id).subscribe({
          next: (value) => DownloadFile(value),
          error: (error) => this.notificationsService.showError(error)
        });
      }
    });
  }

  public filePropertyValue(property: AbstractControl<any>): any[] {
    return property.get('value')?.value;
  }

  private saveChanges() {
    if (this.formGroup.valid) {
      const formGroup: FormGroup = this.propertiesService.convertFormGroupValuesForRequest(this.formGroup);
      this.propertiesService.saveProperties(formGroup.value).subscribe({
        next: () => {
          this.notificationsService.showSuccess('Успешно');
          this.isUnsaved = false;
          this.editFormService.currentTab$.next(PublicationEditFormTabs.Comments);
        },
        error: (error) => this.notificationsService.showError(error)
      });
    } else {
      this.notificationsService.showError('Заполните все необходимые поля');
    }
  }

  public getResetValue(value: unknown) {
    if (typeof value === 'boolean') {
      return false;
    } else if (Array.isArray(value)) {
      return [];
    } else {
      return '';
    }
  }

  private clear() {
    let isFilled = false;
    const isUnsaved = this.isUnsaved;
    this.formProperties.controls.forEach((item) => {
      const value = item.get('value')?.value;
      isFilled = isFilled || (Array.isArray(value) ? !!value?.length : !!value);
      item.get('value')?.patchValue(this.getResetValue(value));
    });
    const languageFields = this.findSelectedMultipleFields();
    for (let i = languageFields.length - 1; i >= 0; i--) {
      if (languageFields[i] !== languageFields.find((item) => item.id === languageFields[i].id)) {
        this.deleteLanguageField(languageFields[i].index);
      }
    }
    this.isUnsaved = isUnsaved || isFilled;
  }

  public isDynamicPropertyDisabled(allowModeratorOnly: boolean): boolean {
    return this.propertiesService.disableDynamicProperty(this.currentPublication, this.isModerator, allowModeratorOnly);
  }

  public convertTextCase(index: number) {
    const property = this.formProperties.at(index);
    const value: string = property.value.value;
    if (value.match(/((?<=.{1})([А-Я]|[A-Z]))+/g)) {
      property.patchValue({value: value[0].toUpperCase() + value.slice(1).toLowerCase()});
      this.isUnsaved = true;
    }
  }

  public addLanguageField(property: AbstractControl<any>, index: number) {
    this.formProperties.at(index).patchValue({isLast: false});
    const disabled = this.isDynamicPropertyDisabled(property.value.allowModeratorOnly);
    const formGroup = this.propertiesService.patchDynamicValues(property.value, disabled, true, true);
    formGroup.get('value')?.patchValue('');
    this.formProperties.push(formGroup);
  }

  public isFirstField(id: string, index: number) {
    const multipleFields = this.findSelectedMultipleFields(id);
    return multipleFields.findIndex((item) => item.index === index) === 0;
  }

  private findSelectedMultipleFields(id?: string) {
    return this.formProperties.value
      .map((item: {id: string, languageCode: string, multipleByLanguage: boolean}, index: number) =>
        ({id: item.id, index, languageCode: item.languageCode, multipleByLanguage: item.multipleByLanguage}))
      .filter((item: {id: string, multipleByLanguage: boolean}) =>
        id ? item.id === id : item.multipleByLanguage) as {id: string, index: number, languageCode: string, multipleByLanguage: boolean}[];
  }

  public deleteLanguageField(index: number) {
    const id = this.formProperties.at(index).value.id;
    this.isUnsaved = this.isUnsaved || !!this.formProperties.at(index).value.value;
    this.formProperties.removeAt(index);
    const multipleFields = this.findSelectedMultipleFields(id);
    multipleFields.forEach((item: {id: string, index: number}, i: number) =>
      this.formProperties.at(item.index).patchValue({isLast: i+1 === multipleFields.length})
    );
  }

  public onLanguageChange(languageCode: string, index: number, combobox: ComboBoxComponent) {
    const id = this.formProperties.at(index).value.id;
    const fields = this.findSelectedMultipleFields(id);
    if (fields.filter((item) => item.languageCode === languageCode).length > 1) {
      this.formProperties.at(index).patchValue({languageCode: ''});
      combobox.writeValue(null);
      this.notificationsService.showError('Данный язык уже добавлен');
    }
  }

  private unsubscribe() {
    this.saveSubscription$.unsubscribe();
    this.clearSubscription$.unsubscribe();
    this.changeSubscription$.unsubscribe();
    this.statusSubscription$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.editFormService.save$.next(null);
    this.unsubscribe();
  }

  protected readonly KendoProperties = KendoProperties;
  protected readonly AccessRights = AccessRights;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  DictDto,
  Dicts, GiaDict, GiaDictAdd,
  GiaDictValue,
  GiaDictValueAdd, StudyYearDto,
} from '../../models/gia/dicts/dicts.model';

@Injectable({
  providedIn: 'root',
})
export class DictsService {
  public dictsLoaded$ = new BehaviorSubject<boolean>(false);
  public dicts$ = new BehaviorSubject<Dicts[]>([]);

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.gia.dict}`;

  constructor(private http: HttpClient) {}

  getDynamicDicts(): Observable<Dicts[]> {
    return this.http.get<Dicts[]>(`${this.baseUrl}/GetDynamicDictNames/`);
  }

  getDictData(dictId: string, isActive?: boolean): Observable<GiaDictValue[]> {
    const query = isActive ? '?isActive=true' : '';
    return this.http.get<GiaDictValue[]>(`${this.baseUrl}/${dictId}${query}`);
  }

  addDictData(dict: GiaDictValueAdd): Observable<GiaDictValueAdd> {
    return this.http.post<GiaDictValueAdd>(this.baseUrl, dict);
  }

  editDictData(dict: GiaDictValue): Observable<GiaDictValue> {
    return this.http.put<GiaDictValue>(this.baseUrl, dict);
  }

  removeDictData(id: string): Observable<GiaDictValue> {
    return this.http.delete<GiaDictValue>(`${this.baseUrl}/${id}`);
  }

  getFilials(name?: string): Observable<DictDto[]> {
    const filter = name ? `&Name=${name}` : '';
    return this.http.get<DictDto[]>(this.baseUrl + `/Filial/${filter}`);
  }

  getTrainingLevels(name?: string): Observable<DictDto[]> {
    const filter = name ? `&Name=${name}` : '';
    return this.http.get<DictDto[]>(this.baseUrl + `/TrainingLevel/${filter}`);
  }

  getStudentTrainingLevels(name?: string): Observable<DictDto[]> {
    const filter = name ? `&Name=${name}` : '';
    return this.http.get<DictDto[]>(this.baseUrl + `/StudentsTrainingLevel/${filter}`);
  }

  getStudyForms(name?: string): Observable<DictDto[]> {
    const filter = name ? `&Name=${name}` : '';
    return this.http.get<DictDto[]>(this.baseUrl + `/StudyForm/${filter}`);
  }

  getSections(name?: string): Observable<DictDto[]> {
    const filter = name ? `&Name=${name}` : '';
    return this.http.get<DictDto[]>(this.baseUrl + `/Section/${filter}`);
  }

  getStudyYears(): Observable<StudyYearDto[]> {
    return this.http.get<StudyYearDto[]>(this.baseUrl + `/StudyYear/`);
  }


  // Конструктор справочников
  getDicts(): Observable<GiaDict[]> {
    return this.http.get<GiaDict[]>(`${this.baseUrl}/GetAllDynamicDicts/`);
  }

  addDict(dict: GiaDictAdd): Observable<GiaDictAdd> {
    return this.http.post<GiaDictAdd>(`${this.baseUrl}/DynamicDictCreate/`, dict);
  }

  editDict(dict: GiaDict): Observable<GiaDict> {
    return this.http.put<GiaDict>(`${this.baseUrl}/DynamicDictUpdate/`, dict);
  }

  removeDict(id: string): Observable<GiaDict> {
    return this.http.delete<GiaDict>(`${`${this.baseUrl}/DynamicDictDelete`}/${id}`);
  }
}

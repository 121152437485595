import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SemesterYearsModel } from '../../../models/middlecontrol/semesterYears.model';
import { TreeCommonModel } from '../../../models/middlecontrol/trees.model';
import { SheetService } from '../../../services/middlecontrol/sheet.service';
import {
  GroupInfoModel,
  SaveEduGroupSession,
  SignatoriesModel, Signatory,
} from '../../../models/middlecontrol/sheets.model';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import {SessionService} from "../../../services/middlecontrol/session.service";
import {CurrentDayEnd, DateFromUTCAsLocal} from "../../../helpers/date-helper";
import {DownloadFile} from "../../../helpers/downloadFile-helper";
type SemesterType = { semesterNum: number; semesterName: string };

@Component({
  selector: 'app-middlecontrolfilters',
  templateUrl: './middlecontrolfilters.component.html',
  styleUrls: ['./middlecontrolfilters.component.scss'],
})
export class MiddlecontrolfiltersComponent implements OnInit {
  @Output() semesterChange: EventEmitter<SemesterYearsModel> =
    new EventEmitter<SemesterYearsModel>();
  @Output() needReload: EventEmitter<null> = new EventEmitter();
  @Output() onChangeEditable: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onChangeEditableStudentSession: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() stateChange: EventEmitter<string> = new EventEmitter();
  @Output() lastLoadedChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input('group') group: TreeCommonModel = {} as TreeCommonModel;
  @Input() semester: SemesterYearsModel | null = null;
  @Input() contingentDate: Date = CurrentDayEnd();
  @Input() markDate: Date = CurrentDayEnd();
  @Input() lastLoaded: Date | null = null;
  @Input() showSigner: boolean = true;

  public currentDate: Date = CurrentDayEnd();
  public isEditSessionDates: boolean = false;
  public isEditSigner: boolean = false;
  private defaultSigner = {
    fio: 'Фамилия Имя Отчество',
    postName: 'должность',
    id: Guid.create(),
    personId: Guid.create(),
  };
  public signer: SignatoriesModel = {
    ...this.defaultSigner,
  };
  public signerRequest: Signatory = {};
  public semesterList: SemesterType[] = [];
  public selectedSemester: SemesterType = {} as SemesterType;
  public newDate: Date = new Date();
  public startDate: Date = this.newDate;
  public endDate: Date = this.newDate;
  public eduGroupId: string = '';
  public planId?: string;
  public groupInfo: GroupInfoModel = {} as GroupInfoModel;
  public loadingEmptyList = false;
  public loadingFilledList = false;
  private destroy$!: Subscription;

  constructor(
    private sessionService: SessionService,
    private sheetService: SheetService,
  ) {}

  ngOnInit(): void {
    this.selectedSemester = {
      semesterNum: this.semester?.semesterNum || 1,
      semesterName: `${this.semester?.semesterNum} семестр ${this.semester?.semesterYearString}`,
    };

    this.destroy$ = this.sheetService.groupInfo$.subscribe((response) => {
      if (response.id?.toString() === this.eduGroupId) {
        this.groupInfo = response;
        this.editable = this.groupInfo.editable;
        this.editableSessionStudent = this.groupInfo.editableStudentSession;
        this.editableSession = this.groupInfo.editableSessionDate;
        this.onChangeEditable.emit(this.editable);
        this.onChangeEditableStudentSession.emit(this.editableSessionStudent);
        if (response.signatory) {
          this.signer = {
            fio: response.signatory.fio,
            postName: response.signatory.postName,
            personId: Guid.parse(response.signatory.personId),
          };
        } else {
          this.signer = { ...this.defaultSigner };
        }
        if (response.session) {
          this.startDate = DateFromUTCAsLocal(response.session.sessionStart);
          this.endDate = DateFromUTCAsLocal(response.session.sessionEnd);
        } else {
          this.startDate = this.newDate;
          this.endDate = this.newDate;
        }
        if (response.semesters) {
          this.semesterList = response.semesters;

          if (
            !this.semesterList.find(
              (item) => item.semesterName === this.selectedSemester.semesterName
            )
            && +this.currentDate === +this.contingentDate
          ) {
            this.stateChange.emit('');
          }
        }
      }
      //this.stateChange.emit('main');
    });
  }

  ngOnDestroy() {
    this.destroy$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setEduGroupId();

    if (changes['semester']) {
      this.selectedSemester = {
        semesterNum: this.semester?.semesterNum || 1,
        semesterName: `${this.semester?.semesterNum} семестр ${this.semester?.semesterYearString}`,
      };
      this.sheetService.updateGroupInfoBySemester(
        this.eduGroupId,
        this.selectedSemester.semesterNum
      );
    }
  }

  onChangeGraphicClick(): void {
    this.isEditSessionDates = true;
  }

  onSaveSessionDates(): void {
    this.isEditSessionDates = false;
    const data: SaveEduGroupSession = {
      eduGroupId: this.eduGroupId,
      semesterNum: this.selectedSemester.semesterNum,
      sessionBegin: this.sessionService.convertDateToUTCDateOnly(this.startDate),
      sessionEnd: this.sessionService.convertDateToUTCDateOnly(this.endDate),
    };
    this.sheetService.saveEduGroupSession(data).subscribe(() => {
      this.sheetService.updateGroupInfoBySemester(
        this.eduGroupId,
        this.selectedSemester.semesterNum
      );
      this.lastLoadedChange.emit(new Date()); //чтобы обновить сводную ведомость
    });
  }

  onEditSigner(): void {
    this.isEditSigner = true;
  }

  onChangeSignerPost(post: string) {
    this.signer.postName = post;
    /*this.sheetService.updateGroupInfoBySemester(
      this.eduGroupId,
      this.selectedSemester.semesterNum
    );*/
  }

  onChangeSigner(signer: SignatoriesModel) {
    this.signer.fio = signer.fio;
  }

  onPrintEmptyList(): void {
    this.loadingEmptyList = true;
    this.onPrintPersonPost()
    this.sheetService
      .printFormEmpty({
        eduGroupId: this.eduGroupId,
        semester: this.selectedSemester.semesterNum,
        contingentDate: this.contingentDate,
        markDate: this.markDate,
        signer: this.signerRequest,
        planId: this.planId
      })
      .subscribe((response) => {
        DownloadFile(response);
        this.loadingEmptyList = false;
      });
  }

  onPrintFilledList(): void {
    this.eduGroupId, this.selectedSemester.semesterNum, this.signerRequest
    this.loadingFilledList = true;
    this.onPrintPersonPost()
    this.sheetService
      .printFormFilled({
        eduGroupId: this.eduGroupId,
        semester: this.selectedSemester.semesterNum,
        contingentDate: this.contingentDate,
        markDate: this.markDate,
        signer: this.signerRequest,
        planId: this.planId
      })
      .subscribe((response) => {
        DownloadFile(response);
        this.loadingFilledList = false;
      });
  }

  onPrintPersonPost(): void{
    if(this.signer.fio === this.defaultSigner.fio)
    {
      this.signerRequest.postName = ""
      this.signerRequest.personId = null
    }
    else this.signerRequest = this.signer
    this.signerRequest.personId = this.signerRequest.personId?.toString()
  }

  onSelectSemester(value: SemesterType) {
    this.selectedSemester = value;
    this.semester &&
      this.semesterChange.emit({
        ...this.semester,
        semesterNum: value.semesterNum,
        semesterYearString: value.semesterName.split(' ')[2],
        semesterYear: Number(value.semesterName.split(' ')[2].split('-')[0]),
      });
  }

  setEduGroupId() {
    this.eduGroupId = this.group.id
      .toString()
      .trimStart()
      .split('_')
      .slice(-1)
      .join('');
    this.planId = this.group.planId
  }

  public editableSession?: boolean;
  public editableSessionStudent?: boolean;
  public editable?: boolean;
}

<div>
  <div class="title"> Выбор или формирование списка </div>
  <div class="save-list">
    <div class="buttons">
      <button kendoButton
              [ngClass]="{blue_button: savelist, grey_button: newlist}"
              class="m-r10"
              (click)="select_savelist()">
          Выбрать из сохраненного списка
      </button>
      <button kendoButton
              [ngClass]="{blue_button: newlist, grey_button: savelist}"
              (click)="select_newlist()">
          Сформировать новый список
      </button>
    </div>
  </div>
  <!--Выбрать из сохраненного-->
  <div *ngIf="Save_List">
  <div class="save-list">
    <div class="big-title">Сохраненные списки </div>
    <kendo-grid
      [data]="gridView"
      [kendoGridSelectBy]="mySelectionKey"
      [(selectedKeys)]="selection"
      class="table-students"
      (remove)="removeHandler($event)">
      <kendo-grid-checkbox-column
        [showSelectAll]="true"
        [width]="50">
      </kendo-grid-checkbox-column>
      <kendo-grid-column field="listName" headerClass="gridHeader" title="Название списка" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells text-left">
            {{ dataItem.title }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fio" headerClass="gridHeader" title="Автор списка" [width]="300">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells text-left">
              {{ dataItem.authorName }}
            </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="count" headerClass="gridHeader" title="Количество подписчиков" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.numberOfRecipients}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="" [width]="50">
        <ng-template kendoGridCellTemplate>
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full">
            </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
    <button kendoButton
            class = "add-list m-b30 shadow-none"
            icon="gear"
            (click)="downloadList()"
            >
        Загрузить список
    </button>
  </div>
  <div class="title"> Выбранные студенты и сотрудники </div>
  <div class="save-list">
    <div class="unit">
      <div class="search-explanation"> Название списка</div>
      <div>
        <kendo-textbox
            class="input-search m-r15"
            placeholder="Название списка" 
            [clearButton]="true"
            name="titleList"                                                        
            [(ngModel)]="titleList"
        >
        </kendo-textbox>
      </div>
      <button kendoButton
              class="white"
              icon="save"
              (click)="SendNewList(newlist)"
              >
      сохранить как новый список
      </button>
    </div>
      <app-selected-students-distr></app-selected-students-distr>
      <app-selected-persons-distr></app-selected-persons-distr>
  </div>
  </div>

  <!--Сформировать новый список-->
  <div *ngIf="New_List">
    <!--Поиск студентов-->
    <div *ngIf="VisibleAdvancedSearch">
      <app-search-students (buttonClick)="advanced_search()"></app-search-students>
    </div>
    <div *ngIf="!VisibleAdvancedSearch">
      <app-expand-students (buttonClick)="advanced_search()"></app-expand-students>
    </div>
    <!--Поиск сотрудников-->
    <app-search-persons></app-search-persons>
    <!--Найденные студенты-->
    <app-found-students [isNewList]="true"></app-found-students>
    <!--Найденные сотрудники-->
    <app-found-persons [isNewList]="true"></app-found-persons>
    <div class="title"> Выбранные студенты и сотрудники </div>
    <div class="save-list">
      <div class="unit">
        <div class="search-explanation">Название списка</div>
        <div>
          <kendo-textbox
              class="input-search m-r15"
              placeholder="Название списка" 
              [clearButton]="true"
              name="titleList"                                                        
              [(ngModel)]="titleList"
          >
          </kendo-textbox>
        </div>
        <button kendoButton
                class="white"
                icon="save"
                (click)="SendNewList(newlist)"
            >
        сохранить как новый список
        </button>
      </div>
      <!-- Выбранные студенты-->
      <app-selected-students-new-list></app-selected-students-new-list>
      <!--Выбранные сотрудники-->
      <app-selected-persons-new-list></app-selected-persons-new-list>
    </div>
  </div>
</div>
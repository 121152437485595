import {Component} from "@angular/core";
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {breadcrumbItemsMap} from "src/app/models/home/breadcrumb-items.model";
import {boolOptions} from "src/app/models/mfc/enums/bool-options.enum";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {Role} from "../../../../models/mfc/dicts/role.model";
import {RoleService} from "../../../../services/mfc/dicts/role.service";

@Component({
  selector: 'mfc-dict-role',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="roles"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      [deletableField]="deletableField"
      (getMethod)="get()"
      (addMethod)="add($event)"
      (updateMethod)="update($event)"
      (removeMethod)="remove($event)">
    </app-dict>
  `
})
export class RoleComponent {

  protected roles: Role[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly deletableField = 'allowDelete';
  protected readonly info: FieldInfo<Role>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название',
      width: 350,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    }
  ];

  protected formGroup(dataItem?: Role): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      name: new FormControl(dataItem?.name, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber),
    });
  }

  constructor(private roleService: RoleService) {}

  protected get() {
    this.roleService.getRoles().subscribe(data => this.roles = data);
  }

  protected add({value, observer}: EventData<Role>) {
    this.roleService.addRole(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<Role>) {
    this.roleService.updateRole(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<Role>) {
    this.roleService.removeRole(value.id).subscribe(observer);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
})

export class PersonCardService {

    baseUrl = `${environment.lkPersonApiEndpoint}`;

    constructor(private http: HttpClient) { }

    public downloadFile(externalId: string){
        return this.http.get(this.baseUrl + 'person/TraineeshipsDownloadFile/' + externalId, { responseType: 'blob' });
    }

    public downloadAchievementsFile(externalId: string){
        return this.http.get(this.baseUrl + 'person/AchievementsDownloadFile/' + externalId, { responseType: 'blob' });
    }

}
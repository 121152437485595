<div class="selectContent w-750">
      <span class="selectText">
        Поиск обучающегося
      </span>

  <dd class="list">
      <span class="selectText p-l20 ">
        Факультет
      </span>
      <kendo-dropdownlist [data]="faculties"
                          class="w-500"
                          textField="shortName"
                          valueField="id"
                          [(ngModel)]="facultyId"
                          [valuePrimitive]="true">
      </kendo-dropdownlist>
  </dd>

  <dd class="list">
      <span class="selectText p-l20 ">
        Форма обучения
      </span>
      <kendo-dropdownlist [data]="studyForms | async"
                          class="w-500"
                          textField="name"
                          valueField="id"
                          [(ngModel)]="studyFormId"
                          [valuePrimitive]="true">
      </kendo-dropdownlist>
  </dd>

  <dd class="list">
      <span class="selectText p-l20 ">
        Направление подготовки
      </span>
      <kendo-combobox [data]="educationStandard"
                      class="w-500"
                      textField="standardName"
                      valueField="id"
                      [kendoDropDownFilter]="filterSettings"
                      [(ngModel)]="educationStandardId"
                      [valuePrimitive]="true">
      </kendo-combobox>
  </dd>

  <dd class="list">
      <span class="selectText p-l20 ">
        ФИО
      </span>
      <kendo-textbox class="w-500"
                   [(ngModel)]="studentFio">
      </kendo-textbox>
  </dd>
  <dd class="b-f">
    <button kendoButton class="save-button" size="small" themeColor="primary" (click)="onSearch()">
      Найти студентов
    </button>
  </dd>
</div>

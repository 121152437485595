import {Component} from '@angular/core';
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {boolOptions} from "../../../../models/mfc/enums/bool-options.enum";
import {breadcrumbItemsMap} from "../../../../models/home/breadcrumb-items.model";
import {pagerSettings} from 'src/app/models/mfc/pagerSettings.model';
import {WorkProfile} from "../../../../models/mfc/dicts/work-profile.model";
import {WorkProfileService} from "../../../../services/mfc/dicts/work-profile.service";

@Component({
  selector: 'mfc-dict-work-profile',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="workProfiles"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      (getMethod)="get()"
      (addMethod)="add($event)"
      (updateMethod)="update($event)"
      (removeMethod)="remove($event)">
    </app-dict>
  `
})
export class WorkProfileComponent {

  protected workProfiles: WorkProfile[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly info: FieldInfo<WorkProfile>[] = [
    {
      field: 'post',
      type: FieldType.Textbox,
      title: 'Должность',
      width: 350,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    }
  ];

  protected formGroup(dataItem?: WorkProfile): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      post: new FormControl(dataItem?.post, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber),
    });
  }

  constructor(private workProfileService: WorkProfileService) {}

  protected get() {
    this.workProfileService.getWorkProfiles().subscribe(data =>
      this.workProfiles = data
    );
  }

  protected add({value, observer}: EventData<WorkProfile>) {
    this.workProfileService.addWorkProfile(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<WorkProfile>) {
    this.workProfileService.updateWorkProfile(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<WorkProfile>) {
    this.workProfileService.removeWorkProfile(value.id).subscribe(observer);
  }
}

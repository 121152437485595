import {Injectable} from "@angular/core";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {PublicationsDictsService} from "../../../../../services/science/publications/dicts.service";
import {FormGroup} from "@angular/forms";
import {getData$} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {PublicationsDictAdd} from "../../../../../models/publications/dict/dict.model";

@Injectable({
    providedIn: 'root'
})
export class PublicationsDictMethods {
    constructor(private dictService: PublicationsDictsService,
                private notificationService: NotificationsService) {
    }

    async saveDict(isNew: boolean, formGroup: FormGroup, dictId: string) {
        if (isNew) {
            const dictAddDto: PublicationsDictAdd = {
                dictId: dictId,
                name: formGroup.value.name,
                sortNumber: formGroup.value.sortNumber,
                isActive: formGroup.value.isActive
            }
            this.dictService.addDictData(dictAddDto).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: () => this.notificationService.showError('Произошла ошибка')
            })
        }
        else {
            this.dictService.editDictData(formGroup.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: () => this.notificationService.showError('Произошла ошибка')
            })
        }
    }

    removeDict(dialogResult: Observable<DialogResult>, id: string) {
        dialogResult.subscribe((result) => {
            if (result instanceof DialogCloseResult) {}
            else {
                if (result.text == "Да") {
                    this.dictService.removeDictData(id)
                        .subscribe({
                            next: () => {
                                getData$.next(true);
                                this.notificationService.showSuccess('Успешно');
                            },
                            error: () => this.notificationService.showError('Произошла ошибка')
                        })
                } else {}
            }
        });
    }
}

import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PagerPosition} from '@progress/kendo-angular-grid';
import {PagerType} from '@progress/kendo-angular-pager';
import {Guid} from 'guid-typescript';
import {Discipline} from 'src/app/models/dicts/discipline.model';
import {DisciplineInfo} from 'src/app/models/dicts/disciplineinfo.model';
import {StandardType} from 'src/app/models/education/standardtype.model';
import {DisciplineService} from 'src/app/services/dicts/discipline.service';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {StandardtypeService} from "../../../services/education/StandardType/standardtype.service";
import {TrainingLevelService} from "../../../services/dicts/traininglevel.service";
import {TrainingLevel} from "../../../models/dicts/traininglevel.model";
import {StudyForm} from "../../../models/dicts/studyform.model";
import {StudyformService} from "../../../services/dicts/studyform.service";

@Component({
    selector: 'app-discipline-info',
    templateUrl: './discipline-info.component.html',
    styleUrls: ['./discipline-info.component.scss']
})
export class DisciplineInfoComponent implements OnInit {

    public dictDisciplineId: any = this.route.params;
    public dictDisciplineInfos: DisciplineInfo[] = [];
    public dictStandardTypes: StandardType[] = [];
    public currentDiscipline!: Discipline;
    public trainingLevels: TrainingLevel[] = [];
    public studyForms: StudyForm[] = [];
    public formGroup: FormGroup | undefined;

    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public info = true;
    public pageSizes = [20, 50, 100];
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';

    constructor(private route: ActivatedRoute,
                private disciplineService: DisciplineService,
                private notificationService: NotificationsService,
                private standardTypesService: StandardtypeService,
                private trainingLevelsService: TrainingLevelService,
                private studyFormService: StudyformService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.getStandardTypes();
        this.getTrainingLevels();
        this.getStudyForms();
        this.getDisciplineInfo();
        this.getDisciplineById();
    }

    public getDisciplineInfo() {
        this.disciplineService.getAllDisciplineInfo(this.dictDisciplineId.value.discipline_id)
            .subscribe(
                response => {
                    this.dictDisciplineInfos = response;
                },
                error => {
                    console.log(error.error);
                    this.notificationService.showError("Отсутствует подключение к серверу");
                });
    }

    public getDisciplineById() {
        this.disciplineService.getDisciplineById(this.dictDisciplineId.value.discipline_id)
            .subscribe(
                response => {
                    this.currentDiscipline = response;
                },
                error => {
                    console.log(error.error);
                    this.notificationService.showError("Отсутствует подключение к серверу");
                });
    }

    private getStandardTypes() {
        this.standardTypesService.getAllStandardTypes().subscribe({
            next: (response) => {
                this.dictStandardTypes = response;
            }
        })
    }

    private getTrainingLevels() {
        this.trainingLevelsService.getAllTrainingLevel().subscribe({
            next: (response) => {
                this.trainingLevels = response;
            }
        })
    }

    private getStudyForms() {
        this.studyFormService.getAllStudyForm().subscribe({
            next: (response) => {
                this.studyForms = response;
            }
        })
    }

    public moveToDisciplines() {
        setTimeout(() => this.router.navigateByUrl('dicts/discipline'), 50);
    }

    public goToEduPlan(educationProgramId: Guid, externalId: Guid) {
        setTimeout(() => window.open('education/' + educationProgramId + '/plans/plan/' + externalId), 50);
    }
}
import {Injectable} from "@angular/core";
import {PublicationsPersonService} from "../../../../../services/science/publications/person.service";
import {AuthorsService} from "../../../../../services/science/publications/authors.service";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {DateFromUTCAsLocal} from "../../../../../helpers/date-helper";
import {Person} from "../../../../../models/publications/person.model";
import {getData$} from "../../../../../../environments/environment";
import {Observable} from "rxjs";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";

@Injectable({
    providedIn: 'root'
})
export class PublicationsAuthorsHomeMethods {
    constructor(private personService: PublicationsPersonService,
                private authorService: AuthorsService,
                private notificationService: NotificationsService) {}

    public mapEmployeeAuthors(person: Person[]) {
        return person
            ? person.map((elem) => ({
                id: elem.id,
                fio: elem.lastName + ' ' + elem.firstName + ' ' + elem.middleName,
                post: elem.post,
                birthday: DateFromUTCAsLocal(elem.birthday),
                department: elem.department
            }))
            : [];
    }

    public mapExternalAuthors(person: Person[]) {
        return person
            ? person.map((elem) => ({
                id: elem.id,
                fio: elem.lastName + ' ' + elem.firstName + ' ' + elem.middleName,
                hasPublication: elem.hasPublication}))
            : [];
    }

    public removeExternalAuthor(dialogResult: Observable<DialogResult>, id: string) {
        dialogResult.subscribe((result) => {
            if (result instanceof DialogCloseResult) {}
            else {
                if (result.text == "Да") {
                    this.authorService.removeExternalAuthor(id)
                        .subscribe({
                            next: () => {
                                getData$.next(true);
                                this.notificationService.showSuccess('Успешно');
                            },
                            error: (error) => this.notificationService.showError(error)
                        })
                } else {}
            }
        });
    }
}

export enum NumericApplicationConstructorStatusesEnum {
  Draft = 1,
  Published,
  Archive,
}

export enum ApplicationConstructorStatusesEnum {
  Draft = 'Черновик',
  Published = 'Опубликовано',
  Archive = 'Архив',
}

import { Guid } from "guid-typescript";

export class Faculty {
    public id!: number;
    facultyId!: Guid | string;
    public facultyName!: string;
    public facultyShortName!: string;
    // For user access
    public facultyFullName?: string;
    public filialId?: string;
}

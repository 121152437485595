import { Guid } from "guid-typescript";
import { DictLanguages } from "./dictlanguages.model";
import { LanguageLevels } from "./languagelevels.model";
import { StudPerson } from "./studperson.model";

export class Languages{
  public languageId?: number;
  public languageExternalId?: Guid;
  public studPersonId?: Guid;
  public studPersons?: StudPerson;
  public dictLanguageId?: Guid;
  public dictLanguageExternalId?: Guid;
  public languageName?: string;
  public dictLanguage?: DictLanguages;
  public dictLanguageLevelId?: Guid;
  public dictLanguageLevel?: LanguageLevels;
}

export class LanguagesShort
{
  public languageName!: string;
  public dictLanguageId!: number;
}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../../helpers/publications/errorHandle-helper";
import {DictOrganization} from "../../../models/publications/dict/organization.model";

@Injectable({
    providedIn: 'root'
})
export class OrganizationsService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/Organization/`;
  constructor(private http: HttpClient) { }

  getOrganizations(): Observable<DictOrganization[]> {
    return this.http.get<DictOrganization[]>(this.baseUrl);
  }

  addOrganization(organization: DictOrganization): Observable<DictOrganization> {
    return this.http.post<DictOrganization>(this.baseUrl, organization)
      .pipe(catchError(handleError));
  }

  updateOrganization(organization: DictOrganization): Observable<DictOrganization> {
    return this.http.put<DictOrganization>(this.baseUrl, organization)
      .pipe(catchError(handleError));
  }

  removeOrganization(id: string): Observable<DictOrganization> {
    return this.http.delete<DictOrganization>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

<div class="title"> Поиск студента </div>
<div >
    <div class="unit">
        <div class="search-explanation"> ФИО </div>
        <div class="input-search">
           <kendo-textbox
                [clearButton]="true"
                name="fio"
                [(ngModel)]="initials"
                >
            </kendo-textbox>
        </div>
    </div>
    <div class="unit">
        <div class="search-explanation"> Институт/Факультет</div>
        <div class="input-search">
            <kendo-dropdownlist
                [data]="facultys"
                 textField="shortName"
                 valueField="id"
                 [valuePrimitive]="true"
                 name="faculty"
                 (valueChange)="onFacultySelectionChange($event)"
                 [(ngModel)]="departments"
               >
            </kendo-dropdownlist>
        </div>
    </div>
    <div class="unit m-b25">
        <div class="search-explanation"> Курс</div>
        <div>
            <kendo-multiselect
            class="w-520"
            [data]="courses"
            kendoMultiSelectSummaryTag
            placeholder="Все"
            textField="course"
            valueField="courseNum"
            [autoClose]="false"
            [checkboxes]="true"
            [valuePrimitive]="true"
            name="course"
            [(ngModel)]="arrayOfCourse"
            >
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span></span>
                    {{ dataItems.length }} выбрано
                </ng-template>
        </kendo-multiselect>
        </div>
    </div>
    <div class="unit">
        <div class="search-explanation"></div>
        <div class="w-520 flex end">
            <button kendoButton class="blue-button m-r15"
            (click)="FindStudents()">
            Найти студентов
            </button>
            <button kendoButton class="blue-button"
                    (click)="advanced_search()">
            Расширенный поиск
            </button>
        </div>
    </div>
</div>

<card-header *ngIf="!isContingentSection"></card-header>

<h1 class="title">{{titlePage}}</h1>

<div class="error-required-fields" *ngIf="selectedStudent && selectedStudent?.errorMessage != ''">
  <p class="error-text">Для формирования карты у выбранного студента необходимо заполнить данные: {{selectedStudent.errorMessage.substring(0, selectedStudent.errorMessage.length - 2)}}</p>
</div>

<dd class="row-blocks row-student-info">
  <span>
    <h3 class="student-info-title-text">ФИО обучающегося</h3>
  </span>
  <div *ngIf="!isUpdate && !isContingentSection">
    <kendo-combobox
      [kendoDropDownFilter]="filterSettings"
      [data]="comboboxStudentInfo"
      textField="fullName"
      valueField="studPersonId"
      class="combobox"
      (selectionChange)="selectionStudent($event)"
    ></kendo-combobox>
  </div>
  <div *ngIf="isUpdate || isContingentSection">
    <p class="student-info-description-text">{{selectedStudent?.fullName}}</p>
  </div>
</dd>

<div *ngIf="selectedStudent">
  <dd class="row-blocks row-student-info">
    <span>
      <h3 class="student-info-title-text">Специальность</h3>
    </span>
    <p class="student-info-description-text">{{selectedStudent?.specializationName}}</p>
  </dd>
  <dd class="row-blocks row-student-info">
    <span>
      <h3 class="student-info-title-text">Курс</h3>
    </span>
    <p class="student-info-description-text">{{selectedStudent?.courseNum}}</p>
  </dd>
  <dd class="row-blocks row-student-info">
    <span>
      <h3 class="student-info-title-text">Группа</h3>
    </span>
    <p class="student-info-description-text">{{selectedStudent?.eduGroupName}}</p>
  </dd>
  <dd class="row-blocks row-student-info">
    <span>
      <h3 class="student-info-title-text">Форма обучения</h3>
    </span>
    <p class="student-info-description-text">{{selectedStudent?.studyFormName}}</p>
  </dd>
  <dd class="row-blocks" [formGroup]="addStudent">
    <span>
      <h3 class="student-info-title-text">Зарплатный проект</h3>
    </span>
    <kendo-combobox
      formControlName="salaryProject"
      [kendoDropDownFilter]="filterSettings"
      [data]="salaryProjects"
      [(value)]="selectedSalaryProject"
      textField="salaryProjectName"
      valueField="salaryProjectId"
      [valuePrimitive]="true"
      required
      class="combobox"
    ></kendo-combobox>
  </dd>
</div>

<button
  (click)="onCancel()"
  kendoButton
  class="margin-btn"
>
  Отмена
</button>

<button
  *ngIf="selectedStudent && selectedStudent?.errorMessage === ''"
  kendoButton
  themeColor="primary"
  class="k-ml-4 margin-btn"
  (click)="saveCardRequest()"
>
  Сохранить
</button>

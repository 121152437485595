import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { DictWorkScheduleStudyProcessTypeService } from 'src/app/services/education/DictWorkScheduleStudyProcessType/dict-work-schedule-study-process-type.service';
import { EducationPlanScheduleService } from 'src/app/services/education/EducationPlan/education-plan-schedule.service';
import {ProcessScheduleService} from "../../../services/education/ProcessSchedule/process-schedule.service";

@Component({
  selector: 'educational-process-schedule',
  templateUrl: './educational-process-schedule.component.html',
})
export class EducationalProcessScheduleComponent implements OnInit {
  @Input() educationPlanId: string = '';
  @Input() yearAdmission: number = 0;
  @Input() educationPlanStatus: number = 2;
  public showTableSettings: boolean = false;
  public isLoading = false;

  constructor(
    private educationPlanScheduleService: EducationPlanScheduleService,
    private processScheduleService: ProcessScheduleService,
    private dictWorkScheduleStudyProcessTypes: DictWorkScheduleStudyProcessTypeService
  ) {}

  ngOnInit(): void {
    this.dictWorkScheduleStudyProcessTypes.updateAllWorkScheduleStudyProcessTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['educationPlanId'].currentValue !== this.educationPlanScheduleService.educationPlanId &&
      changes['educationPlanId'].currentValue.length
    ) {
      this.isLoading = true;
      this.processScheduleService.getSettings(this.educationPlanId).subscribe(
        (response) => {
          this.processScheduleService.settings$.next(response);
          this.educationPlanScheduleService.updateProcessScheduleData(this.educationPlanId);
        });
    }
  }

  public toggleShowTableSettings(value: boolean) {
    this.showTableSettings = value;
  }
}

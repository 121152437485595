<publications-settings></publications-settings>
<h1>Должности</h1>
<button *ngIf="editable" class="addButton" (click)="addRow()" icon="plus"  kendoButton [primary]="true">Добавить</button>
<kendo-grid
        [kendoGridBinding]="posts"
        [pageable]="pagerSettings"
        (cellClick)="editRow($event)"
        (remove)="onRemove($event)"
        [filterable]="!isLine"
        [pageSize]="10">
    <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    <ng-template *ngIf="isLine" kendoGridToolbarTemplate position="both">
        <div class="toolbarButtons">
            <button kendoButton (click)="saveRow()" themeColor="primary">Сохранить</button>
            <button kendoButton (click)="onCancel()">Отмена</button>
        </div>
    </ng-template>
    <kendo-grid-column title="№" class="!k-text-center" headerClass="gridHeader dictionaries" [width]="160">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
            {{rowIndex+1}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="name" title="Название должности">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.name}}
          </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="shortName" title="Сокращенное название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.shortName}}
          </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="editable" headerClass="gridHeader dictionaries" [width]="160">
        <ng-template kendoGridCellTemplate>
            <span class="alignCells">
                <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
                        rounded="full">
                </button>
            </span>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>
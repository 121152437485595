import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MilitaryRanks } from '../../models/contingent/militaryranks.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class MilitaryranksService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.militaryranks}`;

  constructor(private http: HttpClient) { }

  //Get all MilitaryRanks
  public getAllMilitaryRanks(): Observable<MilitaryRanks[]> {
    return this.http.get<MilitaryRanks[]>(this.baseUrl);
  }

  //Add MilitaryRanks
  addMilitaryRanks(militaryranks: MilitaryRanks): Observable<MilitaryRanks> {
    return this.http.post<MilitaryRanks>(this.baseUrl, militaryranks)
      .pipe(catchError(this.handleError));
  }

  //Delete MilitaryRanks
  deleteMilitaryRanks(id: string): Observable<MilitaryRanks> {
    return this.http.delete<MilitaryRanks>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit MilitaryRanks
  updateMilitaryRanks(militaryranks: MilitaryRanks): Observable<MilitaryRanks> {
    return this.http
      .put<MilitaryRanks>(this.baseUrl + '/' + militaryranks.dictMilitaryRankExternalId, militaryranks)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
      return throwError(error);
  }
}

import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup } from '@angular/forms';
import {DictCycleType, DictCycleTypeEnum} from 'src/app/models/education/dictcycletype.model';
import { DictCycleTypeService } from 'src/app/services/education/DictCycleType/dict-cycle-type.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';
import {OrderCategoriesEnum} from "../../../models/contingent/enums/order-categories.enum";

@Component({
  selector: 'dict-cycle-type',
  templateUrl: './dict-cycle-type.component.html',
  styleUrls: ['./dict-cycle-type.component.scss']
})
export class DictCycleTypeComponent implements OnInit {

  public gridStyle = 'grid-default';
  public isOpened: boolean = false;
  public loading: boolean = false;
  public editable?: boolean;
  public cycleTypes!: DictCycleType[];
  public cycleTypeEnums!: DictCycleTypeEnum[];
  public isNew = false;

  constructor(private cycleTypeService: DictCycleTypeService,
    private notificationService: NotificationsService,
    private userAccessService:EducationUserAccessService) { }

  ngOnInit(): void {
    this.getAllCycleTypes();
    this.getCycleTypeEnum();
    this.getAccessLevel();
  }

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictCycleTypeId: new FormControl(0),
    dictCycleTypeName: new FormControl(""),
    dictCycleTypeSerialNumber: new FormControl(),
  });

  //Getting all entries from faculty
  public getAllCycleTypes() {
    this.loading = true;
    this.cycleTypeService.getAllCycleTypes()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.cycleTypes = response;
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить список типов компонентов ")
        }
      });
  }

  public saveCycleType() {
    // Add new cycle type
    if (this.isNew) {
      this.cycleTypeService.addCycleType(this.editForm?.value)
          .subscribe({
              next: () => {
                  this.notificationService.showSuccess("Тип цикла был успешно добавлен!");
                  this.getAllCycleTypes();
                  this.onCancel();
              },
              error: () => {
                  this.notificationService.showError("Не удалось добавить тип цикла");
                  this.onCancel();
              }
          });
    }
    // Edit cycle type
    else {
      this.cycleTypeService.updateCycleType(this.editForm?.value)
          .subscribe({
              next: () => {
                  this.notificationService.showSuccess("Тип цикла был успешно сохранен!");
                  this.getAllCycleTypes();
                  this.onCancel();
              },
              error: () => {
                  this.notificationService.showError('Не удалось изменить тип цикла');
                  this.onCancel();
              }
          })
    }
  }

  private getCycleTypeEnum() {
    this.cycleTypeService.getServiceCategories().subscribe((response) => this.cycleTypeEnums = response);
  }

  public nameEnum(value?: number){
    return this.cycleTypeEnums.find(x => x.value === value)?.name;
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
        .subscribe({
          next: (response) => {
            this.editable = !response.dictAccessLevel;
          },
          error: () => {}
        })
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }
}

const formGroup = (dataItem: {
  dictCycleTypeId?: any;
  dictCycleTypeName?: any;
  dictCycleTypeSerialNumber?: any;
  enum?: number;
}) =>
    new FormGroup({
      dictCycleTypeId: new FormControl(dataItem.dictCycleTypeId),
      dictCycleTypeName: new FormControl(dataItem.dictCycleTypeName),
      dictCycleTypeSerialNumber: new FormControl(dataItem.dictCycleTypeSerialNumber),
      enum: new FormControl(dataItem.enum),
    });

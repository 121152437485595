import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {StandardModel,
        DictsModel, 
        StructTabDto, 
        GiaDisciplineListModel, 
        StructTabShortModel} from '../../models/gia/constructorgiaprotocol.model';

@Injectable({
    providedIn: 'root'
})

export class ConstructorGiaProtocolService {

    baseUrl = `${environment.apiEndpoint}`;

    constructor(private http: HttpClient) { }

    public getAllStandards(trainingLevelId: string) {
        let params = new HttpParams();
        params = params.append('TrainingLevelId', trainingLevelId);
        return this.http.get<StandardModel[]>(this.baseUrl + 'gia/education/Standard', {params});
    }

    public getDisciplinesStateExam(filialStateExam: string, studyYear: number, faculty: string, level: string, standardId: string) {
        let params = new HttpParams();
        params = params.append('FilialId', filialStateExam);
        params = params.append('GraduateYear', studyYear);
        params = params.append('EducationStandardId', standardId);
        params = params.append('DepartmentId', faculty);
        params = params.append('TrainingLevelId', level);
        return this.http.get<[]>(this.baseUrl + 'gia/protocolConstructor/GetAllDisplayStateExamDisciplines', {params});
    }

    public getTabs(trainingLevel: string, filial?: string, facultyId?: string) {
        let params = new HttpParams();
        params = params.append('TrainingLevelId', trainingLevel);
        if (filial) params = params.append('FilialId', filial);        
        if (facultyId) params = params.append('DepartmentId', facultyId);        
        return this.http.get<[]>(this.baseUrl + 'gia/protocolConstructor/GetAllDisplayTabs', {params});
    }

    public getStructureProtocol(tabId: string)  {
        return this.http.get<StructTabDto>(this.baseUrl + 'gia/protocolConstructor/GetDisplayTabById/' + tabId);
    }

    public getDicts() {
        return this.http.get<DictsModel[]>(this.baseUrl + 'gia/dict/GetAllDynamicDicts');
    }

    public deleteTab(tabId: string) {
        return this.http.delete(this.baseUrl + 'gia/protocolConstructor/RemoveDisplayTab/' + tabId);
    }

    public saveTab(data: StructTabDto) {
        return this.http.post(this.baseUrl + 'gia/protocolConstructor', data);
    }

    public saveDisciplines(data: GiaDisciplineListModel) {
        return this.http.post(this.baseUrl + 'gia/protocolConstructor/SaveDisplayStateExams', data);
    }

    public renameTab(data: StructTabShortModel) {
        return this.http.put(this.baseUrl + 'gia/protocolConstructor', data);
    }
}
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DynamicDict} from "../../models/mfc/dicts/dynamic-dict.model";
import {tap} from "rxjs/operators";
import {BehaviorSubject, catchError, Observable} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class DictCreatorService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/Dynamic/`;
  public dicts$ = new BehaviorSubject<DynamicDict[]>([]);
  public dictsLoaded = false;

  constructor(private http: HttpClient) { }

  public getAllDicts(showHiddenOnly = false) {
    return this.http.get<DynamicDict[]>(this.baseUrl, {params: {hidden: showHiddenOnly}})
      .pipe(tap(a => this.dicts$.next(a ?? [])));
  }

  public addDict(dict: DynamicDict): Observable<DynamicDict> {
    return this.http.post<DynamicDict>(this.baseUrl, dict)
      .pipe(
        tap(() => this.dicts$.next([])),
        catchError(handleError)
      );
  }

  public updateDict(dict: DynamicDict): Observable<DynamicDict> {
    return this.http.put<DynamicDict>(this.baseUrl, dict)
      .pipe(
        tap(() => this.dicts$.next([])),
        catchError(handleError)
      );
  }

  public removeDict(id: string): Observable<DynamicDict> {
    return this.http.delete<DynamicDict>(`${this.baseUrl}${id}`)
      .pipe(
        tap(() => this.dicts$.next([])),
        catchError(handleError)
      );
  }
}
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/publications/errorHandle-helper";
import {DictStateExamCommissionMemberRoleModel} from "../../models/gia/dicts/dictStateExamCommissionMemberRole.model";
import {DictDto} from "../../models/gia/dicts/dicts.model";

@Injectable({
  providedIn: 'root'
})
export class DictStateExamCommissionMemberRoleService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.dictStateExamCommissionMemberRole}`;
  constructor(private http: HttpClient) { }

  getList(type?: number): Observable<DictStateExamCommissionMemberRoleModel[]> {
    const filter = type ? `?type=${type}` : '';
    return this.http.get<DictStateExamCommissionMemberRoleModel[]>(this.baseUrl + `/${filter}`);
  }

  update(dictStateExamCommissionMemberRole: DictStateExamCommissionMemberRoleModel): Observable<DictStateExamCommissionMemberRoleModel> {
    return this.http.put<DictStateExamCommissionMemberRoleModel>(this.baseUrl, dictStateExamCommissionMemberRole)
      .pipe(catchError(handleError));
  }
}

export const items: any[] = [
  {
    text: "Настройки",
    icon: "cog",
    items: [
      {
        text: "Справочники",
        items: [
          {
            text: "Академические статусы",
            path: "/contingent/academicstate",
          },
          {
            text: "ВУС",
            items: [
              {
                text: "Военные комиссариаты",
                path: "/contingent/militarycommissariat"
              },
              {
                text: "Документы воинского учета",
                path: "/contingent/militarydoctypes"
              },
              {
                text: "Воинские звания",
                path: "/contingent/militaryranks"
              },
              {
                text: "Категории воинского учёта",
                path: "/contingent/militarystatuses"
              },
              {
                text: "Категории запаса",
                path: "/contingent/militarystockcategories"
              },
              {
                text: "Категории годности к военной службе",
                path: "/contingent/militarycategories"
              },
              {
                text: "Состояния воинского учета",
                path: "/contingent/militarystates"
              },
              {
                text: "Составы (профили)",
                path: "/contingent/militaryprofiles"
              },
              {
                text: "Типы справок",
                path: "/contingent/militaryformtypes"
              },
            ],
          },
          {
            text: "Языки",
            items: [
              {
                text: "Изучаемые иностранные языки",
                path: "/contingent/languages",
              },
              {
                text: "Уровни владения языком",
                path: "/contingent/languagelevels"
              },

            ],
          },
          {
            text: "Гражданство",
            items: [
              {
                text: "Гражданство",
                path: "/contingent/citizenship",
              },
              {
                text: "Типы гражданства",
                path: "/contingent/citizenshiptype",
              },
            ],
          },
          {
            text: "Финансирование",
            items: [
              {
                text: "Источник финансирования",
                path: "/contingent/budget",
              },
              {
                text: "Категория финансирования",
                path: "/contingent/budgetcategory",
              },
              {
                text: "Подкатегория финансирования",
                path: "/contingent/budgetsubcategory",
              },
            ],
          },
          {
            text: "Приказы",
            items: [
              {
                text: "Тип категории приказов",
                path: "/contingent/ordertypes",
              },
              {
                text: "Категории приказов",
                path: "/contingent/orderscategory",
              },
            ],
          },
          {
            text: "Целевое обучение",
            items: [
              {
                text: "Статусы управления жалобами",
                path: "/contingent/complaintmanagementstatus",
              },
              {
                text: "Состояния платежей",
                path: "/contingent/paymentstate",
              },
              {
                text: "Статусы занятости работников",
                path: "/contingent/employmentstatus",
              },
              {
                text: "Причины нетрудоустройства",
                path: '/contingent/nonemploymentreasons',
              },

            ]
          },
          {
            text: "Достижения",
            items: [
              {
                text: "Категории достижений",
                path: "/contingent/dictachievement",
              },
              {
                text: "Статусы достижений",
                path: "/contingent/achievementsstatuses",
              },
              {
                text: "Типы работ",
                path: "/contingent/worktypes",
              }
            ]
          },
          {
            text: "Типы документов об образовании",
            path: "/contingent/educationdoctypes",
          },
          {
            text: "Документы, удостоверяющие личность",
            path: "/contingent/identificationtype",
          },
          /*{
            text: "Типы телефонов",
            path: "/contingent/phonetypes",
          },*/
          {
            text: "Статусы членов семьи",
            path: "/contingent/familymemberstatuses",
          },
          {
            text: "Семейное положение",
            path: "/contingent/dictmaritalstatus",
          },
          {
            text: "Типы улиц",
            path: "/contingent/streettypes",
          },
          {
            text: "Виды населенных пунктов",
            path: "/contingent/citytypes",
          },
          {
            text: "Типы контактов",
            items: [
              {
                text: "Типы email",
                path: "/contingent/emailtypes",
              },
              {
                text: "Тип номера",
                path: "/contingent/phonetypes",
              },
            ],
          },
          /*{
            text: "Дополнительные поля",
            path: "/contingent/additionalfields",
          },*/
          /* {
             text: "Филиалы",
             path: "/contingent/filial",
           },*/
          // {
          //   text: "Организации",
          //   path: "/contingent/organization",
          // },
          {
            text: "Льготы",
            path: "/contingent/benefits",
          },
          {
            text: "Типы учебных заведений",
            path: "/contingent/institutiontype",
          },
          {
            text: "Группы здоровья",
            path: "/contingent/health",
          },
          {
            text: "Спорт",
            items: [{
              text: "Спортивные разряды",
              path: "/contingent/sportcategory"
            },
              {
                text: "Виды спорта",
                path: "/contingent/sporttype"
              }
            ],
          },
          {
            text: "Тип справки",
            path: "/contingent/spravkatype",
          },
          {
            text: "Наименования документов",
            path: "/contingent/documentnames",
          },
          {
            text: "Статьи ТК РФ",
            path: "/contingent/codearticle",
          },
          {
            text: "Места для предоставления справки",
            path: "/contingent/dictcertificatepurpose",
          },
          {
            text: "Типы прочих документов",
            path: "/contingent/dictotherdocumenttypes",
          },
          {
            text: "Уровни подготовки",
            path: "/contingent/dicttraininglevels",
            admin: true
          },
          {
            text: "Факультеты",
            path: "/contingent/dictfaculty",
            admin: true
          },
          {
            text: "Роли локальных подписантов",
            path: "/contingent/localsignatoryrole",
          },
        ],
      },
      /*{
        text: "Внешние источники данных",
        dicts: true,
        items:[{
          text: "Формы обучения",
          path: "/contingent/studyform",
        }
        ]
      },*/
      {
        text: "Справки по воинскому учету",
        path: "/contingent/militarycertificates",
        militaryCertificate: true
      },
      {
        text: "Академические группы",
        path: "/contingent/academicgroupshome",
      },
      { text: "Настройки доступа",
        path: "/contingent/useraccess",
        access: true
      },
      { text: "Настройки списка подписантов",
        path: "/contingent/signatory",
        signatory: true
      },
      { text: "Настройки отображения",
        path: "/contingent/contingentsettings"
      },
      { text: "Настройки карточки студента",
        path: "/contingent/studentcardsettings",
        admin: true
      },
      { text: "Отчеты",
        path: "/contingent/report-list",
      }
    ],
  },
];

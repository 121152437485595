<kendo-grid [data]="gridData" class="table">
  <kendo-grid-column
    field="courseNumber"
    [width]=64
  >
    <ng-template kendoGridHeaderTemplate>
      <div
        class="table__header"
      >
        <p>Курс</p>
      </div>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <p class="table__footer">Итого</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [headerStyle]="{
      'text-align': 'center'
    }"
    *ngFor="let category of dataCategory"
  >
    <ng-template kendoGridHeaderTemplate>
      <div
        class="table__header"
      >
        <p>{{ category.dictWorkCategoryScheduleName }}</p>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="table__column">
        <div class="doublecolumn">
          <p class="table__cell w-54" *ngFor="let column of dataItem.semesters">{{getDataCell(category.dictWorkCategoryScheduleInternalName, dataItem.courseNumber, column.semesterNumber )}}</p>
        </div>
      <!--  <p class="table__cell table__cell-total">{{getDataCell(category.dictWorkCategoryScheduleInternalName, dataItem.courseNumber )}}</p>-->
        <p class="table__cell table__cell-total w-54">
            {{sum(category.dictWorkCategoryScheduleInternalName, dataItem.courseNumber)}}
        </p>
      </div>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <p class="table__footer table__footer-backgound">{{getSumDataCell(category.dictWorkCategoryScheduleInternalName) }}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]=74
  >
    <ng-template kendoGridHeaderTemplate>
      <div
        class="table__header"
      >
        <p>Всего</p>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="table__cell">{{getAllCategoryDataCell(dataItem.courseNumber)}}</p>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <p class="table__footer table__footer-backgound">{{getAllCategoryDataCell()}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]=86
  >
    <ng-template kendoGridHeaderTemplate>
      <div
        class="table__header"
      >
        <p>Зачетные единицы</p>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="table__cell">{{getCreditUnits(dataItem.courseNumber)}}</p>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <p class="table__footer">{{getCreditUnits()}}</p>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>


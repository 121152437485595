import {Component} from "@angular/core";
import { breadcrumbItemsMap } from "src/app/models/home/breadcrumb-items.model";
import { boolOptions } from "src/app/models/mfc/enums/bool-options.enum";
import { pagerSettings } from "src/app/models/mfc/pagerSettings.model";
import {ApplicationTypeService} from "../../../../services/mfc/dicts/application-type.service";
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {ApplicationType} from "../../../../models/mfc/dicts/application-type.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApplicationCategoryService} from "../../../../services/mfc/dicts/application-category.service";
import {ApplicationCategory} from "../../../../models/mfc/dicts/application-category.model";
import {GroupDescriptor} from "@progress/kendo-data-query";

@Component({
  selector: 'mfc-dict-application-type',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="applicationTypes"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [group]="group"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      (getMethod)="get()"
      (addMethod)="add($event)"
      (updateMethod)="update($event)"
      (removeMethod)="remove($event)">
    </app-dict>
  `
})
export class ApplicationTypeComponent {

  protected applicationTypes: ApplicationType[] = [];
  protected applicationCategories: ApplicationCategory[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly group: GroupDescriptor[] = [{field: 'dictApplicationCategoryId'}];
  protected readonly info: FieldInfo<ApplicationType>[] = [
    {
      field: 'dictApplicationCategoryId',
      type: FieldType.Combobox,
      title: 'Категория',
      width: 350,
    },
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название типа',
      width: 350,
      useForDelete: true
    },
    {
      field: 'displayInAddForm',
      type: FieldType.Dropdown,
      title: 'Отображать на главной форме добавления заявки',
      width: 200,
      listOptions: {
        data: this.boolOptions,
        textField: 'text',
        valueField: 'id'
      },
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    },
    {
      field: 'tooltip',
      type: FieldType.Textbox,
      title: 'Текст пояснения',
      width: 200,
    },
  ];

  protected formGroup(dataItem?: ApplicationType): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      dictApplicationCategoryId: new FormControl(dataItem?.dictApplicationCategoryId),
      name: new FormControl(dataItem?.name, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber),
      tooltip: new FormControl(dataItem?.tooltip),
      displayInAddForm: new FormControl(dataItem?.displayInAddForm ?? false),
    });
  }

  constructor(
    private applicationTypeService: ApplicationTypeService,
    private applicationCategoryService: ApplicationCategoryService
  ) {
    this.getApplicationCategories();
  }

  private getApplicationCategories() {
    this.applicationCategoryService.getApplicationCategories().subscribe({
      next: (response) => {
        this.applicationCategories = response;
        const dictApplicationCategory = this.info.find(
          (item) => item.field === 'dictApplicationCategoryId' && item.type === FieldType.Combobox
        );
        if (dictApplicationCategory) {
          dictApplicationCategory.listOptions = {
            data: response,
            textField: 'name',
            valueField: 'id',
          };
        }
      }
    })
  }

  protected get() {
    this.applicationTypeService.getApplicationTypes().subscribe(data =>
      this.applicationTypes = data
    );
  }

  protected add({value, observer}: EventData<ApplicationType>) {
    this.applicationTypeService.addApplicationType(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<ApplicationType>) {
    this.applicationTypeService.updateApplicationType(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<ApplicationType>) {
    this.applicationTypeService.removeApplicationType(value.id).subscribe(observer);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EPlanInfo } from "../../../../models/education/education-plan-edit.model";
import {EducationPlanDetailsModels} from "../../../../models/education/education-plan-details-model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EducationPlanDetailsService } from "../../../../services/education/EducationPlan/education-plan-details.service";
import { StudyFormService } from "../../../../services/education/External/edu.studyforms.service";
import { StudyForm } from "../../../../models/education/External/studyform.model";
import { StudyTechnology } from "../../../../models/education/External/studytechnologies.model";
import { StudyTechnologyService } from "../../../../services/education/External/edu.studytechnologies.service";
import { DictAcceleratedStudyBaseService } from 'src/app/services/education/DictAcceleratedStudyBase/dict-accelerated-study-base.service';
import { DictAcceleratedStudyBase } from 'src/app/models/education/dictAcceleratedStudyBase.model';
import { find } from 'lodash';
import { lastValueFrom } from 'rxjs';
import {Kafedra} from "../../../../models/education/kafedra.model";
import {Faculty} from "../../../../models/education/faculty.model";
import {KafedraService} from "../../../../services/education/Kafedra/kafedra.service";
import {FacultyService} from "../../../../services/education/Faculty/faculty.service";
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'education-plan-edit',
  templateUrl: './education-plan-edit.component.html',
  styleUrls: ['./education-plan-edit.component.scss'],
})
export class EducationPlanEditComponent implements OnInit {
  @Input() eduPlan?: EducationPlanDetailsModels.EPlan;
  @Input() eProgram?: EducationPlanDetailsModels.EProgram;
  @Input() isNew: boolean = false;
  @Output() onSave = new EventEmitter<EducationPlanDetailsModels.EPlan>();
  @Output() onCancel = new EventEmitter();

  model: EducationPlanDetailsModels.EPlan = {} as EducationPlanDetailsModels.EPlan;
  eduPlanFormGroup: FormGroup = new FormGroup({});
  acceleratedSignDataItems: { value: boolean, text: string }[] = [
    { value: false, text: 'Нет' },
    { value: true, text: 'Да' }
  ];
  acceleratedStudyBases: DictAcceleratedStudyBase[] = [];
  studyForms: StudyForm[] = [];
  studyTechnologies: StudyTechnology[] = [];
  faculties: Faculty[] = [];
  kafedras: Kafedra[] = [];
  currentDateStr: string = new Date().toLocaleDateString("ru-RU");
  semesterName = '';
  devPeriodInfo: EPlanInfo.DevelopmentPeriod[] = [];
  devPeriodSettings = {
    devPeriodYears: {
      min: 0,
      max: 10
    },
    devPeriodMonths: {
      min: 0,
      max: 12
    }
  }
  public isLoading = true;

  public acceleratedSignData: boolean = false;
  public studyForm: any;
  public studyTechnology: any;

  constructor(private activateRoute: ActivatedRoute, private router: Router,
    public eduPlanDetailsService: EducationPlanDetailsService,
    private studyFormService: StudyFormService,
    private studyTechnologyService: StudyTechnologyService,
    private kafedraService: KafedraService,
    private facultyService: FacultyService,
    private dictAcceleratedStudyBaseService: DictAcceleratedStudyBaseService,
  ) { }

  async ngOnInit() {
    this.eduPlanFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      yearAdmission: new FormControl(new Date().getFullYear(), [Validators.required]),
      developmentPeriodMonths: new FormControl('', [Validators.required]),
      developmentPeriodYears: new FormControl('', [Validators.required]),
      acceleratedSign: new FormControl('', [Validators.required]),
      dictStudyTechnologyId: new FormControl('', [Validators.required]),
      facultyId: new FormControl('', [Validators.required]),
      kafedraId: new FormControl('', [Validators.required]),
      dictStudyFormId: new FormControl('', [Validators.required]),
      dictAcceleratedStudyBaseId: new FormControl(''),
      protocol: new FormControl('', []),
      professionalActivities: new FormControl('', []),
      description: new FormControl('', []),
      semesterDuration: new FormControl([], [Validators.required]),
    });
    this.studyFormService.getList().subscribe(
      _ => {this.studyForms = _;
        this.studyForm = this.studyForms[0].id;
      }
    );
    this.dictAcceleratedStudyBaseService.getAllAcceleratedStudyBases()
      .subscribe(
        _ => this.acceleratedStudyBases = _
      );
    this.studyTechnologies = await lastValueFrom(this.studyTechnologyService.getList());
    this.studyTechnology = this.studyTechnologies[0].id;
    this.facultyService.getAllFaculties().subscribe(
      _ => {
        this.faculties = _;
        if (!this.eduPlan && this.eProgram && this.eProgram.facultyId)
          this.eduPlanFormGroup.patchValue({facultyId: this.eProgram.facultyId})
      });
    this.kafedraService.getAllKafedras().subscribe(
      _ => {
        this.kafedras = _;
        if (!this.eduPlan && this.eProgram && this.eProgram.graduatingDepartmentId)
          this.eduPlanFormGroup.patchValue({kafedraId: this.eProgram.graduatingDepartmentId})
      });

    if (this.eduPlan) {
      const formValues = {
        ...this.eduPlan,
        dictStudyTechnologyId: this.eduPlan.studyTechnologyId || this.eduPlan.dictStudyTechnologyId,
        dictStudyFormId: this.eduPlan.studyFormId || this.eduPlan.dictStudyFormId,
        facultyId: this.eduPlan.facultyId,
        kafedraId: this.eduPlan.kafedraId,
      }
      this.eduPlanFormGroup.patchValue(formValues)
      this.model = formValues
      this.onChangeStudyTechnology(this.eduPlan.studyTechnologyId)
      this.devPeriodParse(this.eduPlan.semesterDuration)
    }
  }

  developmentPeriodCalc() {
    const { dictStudyTechnologyId } = this.eduPlanFormGroup.value
    const years = this.model.developmentPeriodYears;
    const months = this.model.developmentPeriodMonths;
    const courseCount = Math.ceil(years + months / 12);
    const semesterCount = this.studyTechnologies.find(x =>
      x.id == dictStudyTechnologyId)?.numberSemestersPerYear;

    if (!semesterCount || (years === 0 && months === 0)) {
      this.devPeriodInfo = []
      return
    }

    if (this.devPeriodInfo.length > courseCount) {
      this.devPeriodInfo = this.devPeriodInfo.slice(0, courseCount - 1);
    }
    for (let course = 0; course < courseCount; course++) {
      let period = this.devPeriodInfo[course];
      if (period == null) {
        this.devPeriodInfo.push({
          courseNum: course + 1,
          semesters: []
        })
        period = this.devPeriodInfo[course];
      }
      if (period.semesters.length > semesterCount) {
        period.semesters = period.semesters.slice(0, semesterCount);
      } else {
        for (let semester = 0; semester < semesterCount; semester++) {
          if (period.semesters[semester])
            continue;
          const semesterNum = semesterCount * (course + 1) - (semesterCount - (semester + 1))
          period.semesters.push({
            semesterNum,
            duration: 0
          })
        }
      }
    }
  }

  developmentPeriodSave() {
    const semesterDuration: EducationPlanDetailsModels.EPlanSemester[] = [];
    this.devPeriodInfo.forEach(course => {
      course.semesters.forEach(semester => {
        semesterDuration.push({
          number: semester.semesterNum,
          courseNumber: course.courseNum,
          semesterDurationOrNumberSessions: semester.duration
        } as EducationPlanDetailsModels.EPlanSemester);
      });
    });
    this.eduPlanFormGroup.patchValue({ semesterDuration })
  }

  devPeriodParse(semesterDuration: EducationPlanDetailsModels.EPlanSemester[]) {
    const courses: any = {}
    semesterDuration.sort((a, b) => a.number - b.number);
    semesterDuration.forEach(semester => {
      if (!courses[semester.courseNumber]) {
        courses[semester.courseNumber] = []
      }
      courses[semester.courseNumber].push({
        semesterNum: semester.number,
        duration: semester.semesterDurationOrNumberSessions,
      })

    })
    this.devPeriodInfo = [];
    for (let courseNum in courses) {
      this.devPeriodInfo.push({
        courseNum: Number(courseNum),
        semesters: courses[courseNum]
      })
    }
  }

  onChangeDevPeriodValue(courseNum: number, semesterNum: number, event: any) {
    let period = this.devPeriodInfo.find(x => x.courseNum == courseNum);
    if (period == null)
      return;

    let semester = period.semesters.find(x => x.semesterNum == semesterNum);
    if (semester == null)
      return;

    semester.duration = event;
  }

  onChangeDevPeriod(period: 'years' | 'months', value: number) {
    const years = period === 'years' ? value : (this.model.developmentPeriodYears || 0);
    const months = period === 'months' ? value : (this.model.developmentPeriodMonths || 0);
    this.model.developmentPeriodYears = years;
    this.model.developmentPeriodMonths = months;
    this.eduPlanFormGroup.patchValue({
      developmentPeriodYears: years,
      developmentPeriodMonths: months,
    })
    this.developmentPeriodCalc();
  }

  onChangeStudyTechnology(dictStudyTechnologyId: string) {
    this.model.dictStudyTechnologyId = dictStudyTechnologyId;
    const studyTechnology = find(this.studyTechnologies, { id: dictStudyTechnologyId })
    this.semesterName = studyTechnology ? studyTechnology.semesterName : 'семестр';
    this.devPeriodInfo = []
    this.developmentPeriodCalc();
  }

  onChangeAcceleratedStudyBase(value: string): void {
    this.model.dictAcceleratedStudyBaseId = value
  }

  onChangeFaculty(value: string): void {
    this.model.facultyId = value
  }

  onChangeKafedra(value: string): void {
    this.model.kafedraId = value
  }

  onSubmitForm() {
    if (this.devPeriodInfo.length === 0) {
      this.eduPlanFormGroup.markAllAsTouched();
      return;
    }
    for (const course of this.devPeriodInfo) {
      for (const semester of course.semesters) {
        if (semester.duration === null || semester.duration === undefined) {
          return;
        }
      }
    }
    this.developmentPeriodSave();
    if (!this.eduPlanFormGroup.valid) {
      this.eduPlanFormGroup.markAllAsTouched();
      return;
    }
    if (this.eduPlanFormGroup.value.acceleratedSign === true && this.model.dictAcceleratedStudyBaseId) {
      this.eduPlanFormGroup.patchValue({ dictAcceleratedStudyBaseId: this.model.dictAcceleratedStudyBaseId })
    }
    this.onSave.emit(
      this.eduPlan ?
        {
          ...this.eduPlanFormGroup.value,
          educationPlanId: this.eduPlan.educationPlanId,
        } :
        this.eduPlanFormGroup.value)
  }

  public onFocus(departmentcombobox: { isOpen: boolean; toggle: (arg0: boolean) => void; }) {
    if (!departmentcombobox.isOpen) {
      departmentcombobox.toggle(true);
    }
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
}

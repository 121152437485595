<mfc-settings></mfc-settings>

<h1>Настройки штампов ЭП</h1>

<div class="container">
  <kendo-grid
          class="grid-data borderless"
          [kendoGridBinding]="signTypes"
          [pageable]="pagerSettings"
          [pageSize]="pageSize"
          [sortable]="true"
          (cellClick)="openEditForm($event)">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <kendo-grid-column title="Тип подписи" field="value"></kendo-grid-column>
  </kendo-grid>
</div>

<publications-settings></publications-settings>
<h1>Роли автора</h1>

<div *ngIf="editable">
  <button kendoButton themeColor="success"
          *ngIf="!isInEditingMode"
          (click)="addRow()"
          icon="plus">Добавить</button>
  <div class="buttons" *ngIf="isInEditingMode">
    <button kendoButton
            (click)="onCansel()">
      Отмена
    </button>
    <button kendoButton
            [disabled]="formGroup!.invalid"
            themeColor="primary"
            (click)="onSave()">
      Сохранить
    </button>
  </div>
</div>

<div class="grid-container">
  <kendo-grid [kendoGridBinding]="authorRoles"
              [pageable]="pagerSettings"
              [pageSize]="pageSize"
              (cellClick)="onEdit($event)"
              (remove)="onRemove($event)"
              [filterable]="!isInEditingMode"
              [loading]="loading">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column title="№" class="!k-text-center" headerClass="gridHeader dictionaries" [width]="30">
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        {{rowIndex+1}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="name"
                       title="Наименование роли автора"
                       [width]="270"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.name}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" title="" [width]="30" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
                  rounded="full" style="text-overflow: unset;">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>
</div>

<div kendoDialogContainer></div>

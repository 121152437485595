<kendo-loader class="loader" *ngIf="loaderVisible" [type]="type" size="medium"> </kendo-loader>
<div class="alignend" [ngClass]="{warning: checkMarks()}">
    <p class="red" *ngIf="checkMarks()">В приложении есть дисциплины, по которым не выставлены оценки!</p>
    <button kendoButton themeColor="primary" class="m-l15" [disabled]="loaderVisible" (click)="saveInformation()" *ngIf="allowEdit">Сохранить</button>
</div>

<p class="selectContent bold">Тема ВКР</p>

<kendo-textarea [rows]="3" resizable="none" class="w-700"
                (valueChange)="onChange($event)"
                [disabled] = !allowEdit
                [(ngModel)]="applicationData.vkrTitle">
</kendo-textarea>

<div class="m-t15 display-flex">
    <input type="checkbox" kendoCheckBox [disabled]="!allowEdit" [checked]="applicationData.isFacultativeDisciplinesInfoIncluded"
                           (change)="onChange(applicationData.isFacultativeDisciplinesInfoIncluded)"
                           [(ngModel)]="applicationData.isFacultativeDisciplinesInfoIncluded"/>
    <kendo-label
        class="m-l10"
        text="Включить в диплом информацию по факультативным дисциплинам"
    ></kendo-label>
</div>

<button kendoButton class="m-t15" (click)="fillStudyPlan()" *ngIf="allowEdit">Заполнить на основании УП</button>

<div class="selectContent m-t15">
    <dd class="list">
      <span class="selectText parameters">
        Количество аудиторных часов
      </span>
      <p class="w-300">{{ applicationData.auditHours }}</p>
    </dd>

    <dd class="list">
      <span class="selectText parameters">
        Трудоемкость ГИА, зач. ед.
      </span>
      <p class="w-300">{{ applicationData.giaInvestment }}</p>
    </dd>
</div>

<div class="m-t15">
    <button kendoButton (click)="clearAll()" [disabled]="deleteDisciplines(applicationData.disciplines)" *ngIf="allowEdit">Очистить все</button>
    <button kendoButton themeColor="success" icon="plus" class="m-l15" (click)="addDiscipline()" *ngIf="allowEdit">Добавить дисциплину</button>
</div>

<kendo-grid #grid
            class="m-t15"
            [data]="gridData"
            [loading]="loading"
            [rowClass]="rowCallback"
            (dataStateChange)="dataStateChange($event)"
            (cellClick)="cellClickHandler($event)"
            >
    <ng-template kendoGridLoadingTemplate>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>

    <kendo-grid-column field="serialNumber"
                       title="№ п/п"
                       [width]="100"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.serialNumber }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="disciplineName"
                        title="Дисциплина"
                        headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells" [ngClass]="{leftAlignment: !dataItem.total, total: dataItem.total, blocktitle: !dataItem.disciplineId}">
                {{ dataItem.attachmentDisciplineName ? dataItem.attachmentDisciplineName : dataItem.disciplineName}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="creditUnit"
                       title="З.ед."
                       [width]="100"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.creditUnit }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hours"
                       title="Часы"
                       [width]="100"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.hours }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="markName"
                       title="Оценка"
                       [width]="250"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells" *ngIf="showMark(dataItem) && !dataItem.editable">
                {{ getMarkDiscipline(dataItem.markId) }}
            </span>
            <kendo-dropdownlist *ngIf="dataItem.editable"
                [ngClass]="{'borderred': checkField(dataItem.markId)}"
                [data]="getDisciplineMarks(dataItem)"
                textField="name"
                valueField="id"
                [disabled]="!allowEdit"
                (selectionChange)="selectionChangeMark($event, dataItem)"
                [valuePrimitive]="true"
                [(ngModel)]="dataItem.markId"
            >
            </kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>

<div class="alignend m-t15">
    <button kendoButton themeColor="primary" class="m-l15" [disabled]="loaderVisible" (click)="saveInformation()" *ngIf="allowEdit">Сохранить</button>
</div>

<div kendoDialogContainer class="window"></div>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, tap, throwError } from 'rxjs';

import urlJoin from 'url-join';

import { environment } from 'src/environments/environment';
import { ErrorService } from '../../../services/education/error.service';
import { CompetenceMatrixModel } from 'src/app/models/education/competence-matrix.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { CompetenceMatrixSummary } from 'src/app/models/dicts/competence.model';

@Injectable({
  providedIn: 'root',
})
export class CompetenceMatrixService {
  baseUrl = urlJoin(<string>environment.apiEndpoint, "v1", environment.apiPaths.education.competenceMatrix);

  // competenceMatrix: CompetenceMatrixModel.CompetenceMatrix[] = [];

  constructor(private httpClient: HttpClient, private errorService: ErrorService,
    private notificationService: NotificationsService) { }

  getCompetenceMatrix(educationPlanId: string): Observable<CompetenceMatrixModel.CompetenceMatrix[]> {
    return this.httpClient
      .get<CompetenceMatrixModel.CompetenceMatrix[]>(urlJoin(this.baseUrl, educationPlanId), {})
      .pipe(
        retry(2),
        // tap(data => this.competenceMatrix = data),
        catchError(this.errorHandler.bind(this))
      );
  }
  /* Получение справочника компетенций */
  getSummaryCompetences(educationPlanId: string): Observable<CompetenceMatrixSummary[]> {
    return this.httpClient
      .get<CompetenceMatrixSummary[]>(urlJoin(this.baseUrl, 'Summary', educationPlanId), {})
      .pipe(
        retry(2),
        catchError(this.errorHandler.bind(this))
      );
  }

  saveCompetenceMatrix(educationPlanId: string, item: CompetenceMatrixModel.CompetenceMatrixSaveObject): Observable<CompetenceMatrixModel.CompetenceMatrixSaveObject> {
    return this.httpClient
      .post<CompetenceMatrixModel.CompetenceMatrixSaveObject>(urlJoin(this.baseUrl, educationPlanId), item)
      .pipe(
        retry(2),
        // tap((data) => (this.competenceMatrix = data)),
        catchError(this.errorHandler.bind(this))
      );
  }

  private errorHandler(error: HttpErrorResponse) {
    this.notificationService.showError('Произошла ошибка');
    this.errorService.handle(error.message);
    return throwError(() => error.message);
  }
}

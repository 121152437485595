import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MilitarystatesService } from 'src/app/services/contingent/militarystates.service';
import { MilitaryStates } from 'src/app/models/contingent/militarystates.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";

@Component({
  selector: 'app-militarystates',
  templateUrl: './militarystates.component.html',
  styleUrls: ['./militarystates.component.scss']
})
export class MilitarystatesComponent implements OnInit {

  public militaryStates: MilitaryStates[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private militaryStatesService: MilitarystatesService,
              private renderer: Renderer2, private formBuilder: UntypedFormBuilder,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: ContingentUserAccessService,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllMilitaryStates();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid") /*&& this.orderValid*/) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllMilitaryStates();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      militaryStateName: ""
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.militaryStatesService.addMilitaryStates(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllMilitaryStates();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить звание');
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.militaryStatesService.updateMilitaryStates(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllMilitaryStates();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllMilitaryStates() {
    this.militaryStatesService.getAllMilitaryStates()
      .subscribe(
        response => {
          this.militaryStates = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.militaryStateName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.militaryStatesService.deleteMilitaryStates(dataItem.dictMilitaryStateExternalId)
            .subscribe({
              next:() => {
                this.getAllMilitaryStates();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else this.opened = false;
      }
    });
  }


  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { militaryStateName: any; dictMilitaryStateId?: any; dictMilitaryStateExternalId?: any; order?: any;}) =>
  new UntypedFormGroup({
    dictMilitaryStateId: new UntypedFormControl(dataItem.dictMilitaryStateId),
    dictMilitaryStateExternalId: new UntypedFormControl(dataItem.dictMilitaryStateExternalId),
    order: new UntypedFormControl(dataItem.order),
    militaryStateName: new UntypedFormControl(dataItem.militaryStateName, Validators.required),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/publications/errorHandle-helper";
import {PrintTemplateModel} from "../../models/gia/printtemplate/printTemplate.model";

@Injectable({
  providedIn: 'root'
})
export class DiplomaPrintService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.diplomaPrint}`;
  constructor(private http: HttpClient) { }

  public getDiplomasBook(facultyId: string, graduateYear: number, trainingLevelId: string, studyFormId?: string | null) {

    let filter = '';

    if (studyFormId !== null && studyFormId !== undefined && studyFormId !== '') filter += `studyFormId=${studyFormId}`;

    const queryString = filter ? `?${filter}` : '';

    return this.http.get(`${this.baseUrl}/GetDiplomasBook/${facultyId}/${graduateYear}/${trainingLevelId}${queryString}`, { responseType: 'blob', observe: 'response', }).pipe(catchError(handleError));
  }

  public getDiploma(studentId: string) {
    return this.http.get(`${this.baseUrl}/GetDiploma/${studentId}`, { responseType: 'blob', observe: 'response', }).pipe(catchError(handleError));
  }

  public getAttachment(studentId: string, template: number) {
    return this.http.get(`${this.baseUrl}/GetAttachment/${studentId}/${template}`, { responseType: 'blob', observe: 'response', }).pipe(catchError(handleError));
  }

}

import { Input,Output, EventEmitter, Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CreateFormGroupArgs, EditEvent, GridDataResult  } from '@progress/kendo-angular-grid';
import { ActivatedRoute } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder,FormGroup } from "@angular/forms";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { Identifications } from 'src/app/models/contingent/identifications.model';
import { StudPerson } from 'src/app/models/contingent/studperson.model';
import { IdentificationType } from 'src/app/models/contingent/identificationtype.model';
import { IdentificationsService } from 'src/app/services/contingent/identifications.service';
import { IdentificationtypeService } from 'src/app/services/contingent/identificationtype.service';
import { StudentmaininfoComponent } from '../studentmaininfo/studentmaininfo.component';
import { Observable } from 'rxjs';
import { Emails } from 'src/app/models/contingent/emails.model';
import { EmailsService } from 'src/app/services/contingent/emails.service';
import { Phones } from 'src/app/models/contingent/phones.model';
import { PhoneTypes } from 'src/app/models/contingent/phonetypes.model';
import { PhonesService } from 'src/app/services/contingent/phones.service';
import { EducationDocLanguages, EducationDocs } from 'src/app/models/contingent/educationdocs.model';
import { EducationDocTypes } from 'src/app/models/contingent/educationdoctypes.model';
import { StudyLevel } from 'src/app/models/contingent/studylevel.model';
import { AdditionalFields } from 'src/app/models/contingent/additionalfields.model';
import { EducationdocsService } from 'src/app/services/contingent/educationdocs.service';
import { EducationdoctypesService } from 'src/app/services/contingent/educationdoctypes.service';
import { Addresses } from 'src/app/models/contingent/addresses.model';
import { CityTypes } from 'src/app/models/contingent/citytypes.model';
import { StreetTypes } from 'src/app/models/contingent/streettypes.model';
import { AddressesService } from 'src/app/services/contingent/addresses.service';
import { StreettypesService } from 'src/app/services/contingent/streettypes.service';
import { CitytypesService } from 'src/app/services/contingent/citytypes.service';
import { DictStateService } from 'src/app/services/contingent/dictstate.service';
import { DictState } from 'src/app/models/contingent/dictstate.model';
import { ComboBoxComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FamilyMemberStatuses } from 'src/app/models/contingent/familymemberstatuses.model';
import { FamilyMembers } from 'src/app/models/contingent/familymembers.model';
import { FamilymemberstatusesService } from 'src/app/services/contingent/familymemberstatuses.service';
import { FamilymembersService } from 'src/app/services/contingent/familymembers.service';
import { AdditionalfieldsService } from 'src/app/services/contingent/additionalfields.service';
import { SocialnetworksService } from 'src/app/services/contingent/socialnetworks.service';
import { SocialNetworks } from 'src/app/models/contingent/socialnetworks.model';
import { Guid } from 'guid-typescript';
import { Contacts } from 'src/app/models/contingent/contacts.model';
import { Citizenship } from 'src/app/models/contingent/citizenship.model';
import { CitizenshipService } from 'src/app/services/contingent/citizenship.service';
import {DictInstitutionType} from "../../../models/contingent/dictinstitutiontype.model";
import {DictinstitutiontypeService} from "../../../services/contingent/dictinstitutiontype.service";
import {CommondictService} from "../../../services/contingent/commondict.service";
import {StudpersonService} from "../../../services/contingent/studperson.service";
import {DicthostelService} from "../../../services/contingent/dicthostel.service";
import {DictHostel} from "../../../models/contingent/dicthostel";
import { MedicalCertificate } from 'src/app/models/contingent/medicalcertificate.model';
import { MedicalcertificateService } from 'src/app/services/contingent/medicalcertificate.service';
import { TokenStorageService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StudentService } from 'src/app/services/contingent/student.service';
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {DatePipe} from "@angular/common";
import {AdditionalStudentFields} from "../../../models/contingent/additionalstudentfields.model";
import {AdditionalStudentFieldsService} from "../../../services/contingent/additionalstudentfields.service";
import {DataTypeEnum} from "../../../models/contingent/enums/data-type.enum";
import {PhoneTypeEnum} from "../../../models/contingent/enums/phoneType.enum";
import {ContactTypeEnum} from "../../../models/contingent/enums/contacttype.enum";
import {StudentcarddisplaysettingService} from "../../../services/contingent/studentcarddisplaysetting.service";
import {DisplaySettingEnum} from "../../../models/contingent/enums/display-setting.enum";
import { AdditionalFieldEnum, nameAdditionalFields } from "../../../models/contingent/enums/additional_field.enum";
import { EmploymentRead } from 'src/app/models/contingent/employment.model';
import { PhonetypesService } from '../../../services/contingent/phonetypes.service';
import { EmailTypes } from 'src/app/models/contingent/emailtypes.model';
import { EmailtypesService } from '../../../services/contingent/emailtypes.service';
import { EmploymentService } from '../../../services/contingent/employment.service';
import {DepartmentName, Faculty} from 'src/app/models/contingent/departmentname.model';
import {DepartmentService} from 'src/app/services/contingent/department.service';
import { LanguagelevelsService } from 'src/app/services/contingent/languagelevels.service';
import { LanguageLevels } from 'src/app/models/contingent/languagelevels.model';
import { DictStudyForm } from 'src/app/models/contingent/dictstudyform.model';
import { DictQualificationService } from 'src/app/services/contingent/qualification.service';
import { Qualification } from 'src/app/models/contingent/qualification.model';
import {ContingentService} from "src/app/services/contingent/contingent.service";
import { levelPostgraduateId } from 'src/app/models/contingent/enums/trainingLevel.enum';
import {DictOtherDocumentType} from "../../../models/contingent/dictotherdocumenttype";
import {DictotherdocumentsService} from "../../../services/contingent/dictotherdocuments.service";
import { OtherDocumentService } from "src/app/services/contingent/otherdocuments.service";
import { OtherDocuments } from "src/app/models/contingent/otherdocuments.model";
import { openRemoveDialog }from "src/app/helpers/dialogHelper";

@Component({
  selector: 'app-personaldata',
  templateUrl: './personaldata.component.html',
  styleUrls: ['./personaldata.component.scss']
})
export class PersonaldataComponent implements OnInit {

  @Input() studPersonId!: string;
  @Output() update = new EventEmitter<string>();

  constructor(private activateRoute: ActivatedRoute,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private identificationService:IdentificationsService,
              private medicalCertificateService:MedicalcertificateService,
              private identificationTypesService:IdentificationtypeService,
              public studentMainInfoComponent:StudentmaininfoComponent,
              private emailService:EmailsService,
              private phoneService:PhonesService,
              private educationDocService:EducationdocsService,
              private educationDocTypesService: EducationdoctypesService,
              private addressesService: AddressesService,
              private streetTypesService: StreettypesService,
              private cityTypesService: CitytypesService,
              private dictStateService:DictStateService,
              private familyMemberStatusesService: FamilymemberstatusesService,
              private familyMemberService: FamilymembersService,
              private additionalFieldService: AdditionalfieldsService,
              private additionalStudentFieldService: AdditionalStudentFieldsService,
              private socialNetworksService:SocialnetworksService,
              private citizenshipService:CitizenshipService,
              private renderer: Renderer2,
              private formBuilder: FormBuilder,
              private dictInstitutionTypeService:DictinstitutiontypeService,
              private commonDictService:CommondictService,
              private studPersonService:StudpersonService,
              private dictHostelService:DicthostelService,
              private el: ElementRef,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private studService:StudentService,
              private displaySettingService: StudentcarddisplaysettingService,
              private phonetypesService: PhonetypesService,
              private emailtypesService: EmailtypesService,
              private employmentService: EmploymentService,
              private departmentService: DepartmentService,
              private languageLevelsService: LanguagelevelsService,
              private dictQualificationService: DictQualificationService,
              private contingentService: ContingentService,
              private dictOtherDocumentsService: DictotherdocumentsService,
              private otherDocumentService: OtherDocumentService,
  ){
    this.createFormGroup = this.createFormGroup.bind(this);
    this.studPersonId=history.state.navigationId;
    this.activateRoute.params.subscribe((res) => {
      this.studPersonId = res['studpersonId']
    });
    if(activateRoute.snapshot.parent!==null){
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
    }}

  ngOnInit(): void {
    this.getDisplaySettings();
    this.getStudents()
    this.getAllIdentificationTypes();
    this.getAllEducationDoc();
    this.getAllAddress();
    this.getDictState();
    this.getAllFamily();
    this.getAdditional();
    this.getStudentAdditional();
    this.getCitizenship();
    this.getAllInstitutionTypes();
    this.getAllStudyLevel();
    this.getAllPhones();
    this.getAllSocialNetworks();
    this.getAllEmails();
    this.getAllIdentification();
    this.getAllEducationDocTypes();
    this.getAllCityTypes();
    this.getAllStreetTypes();
    this.getAllFamilyMemberStatuses();
    this.getAllInstitutionTypes();
    this.getAllStudyLevel();
    this.getAllMedicalCertificates();
    this.getAllHostel();
    this.getAllPhoneTypes();
    this.getAllEmailTypes();
    this.getEmployment();
    this.getAllFaculty();
    this.getAllLanguageLevels();
    this.getAllStudyForm();
    this.getQualifications();
    this.getOtherDocumentTypes();
    this.getOtherDocument();
  }

  ngAfterViewInit() {
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  public position: "top" | "bottom" | "both" = "bottom";
  public enum = DisplaySettingEnum;
  public enumAdditional = AdditionalFieldEnum;
  public opened = false;
  public editMode: boolean = false;
  public editModeDop: boolean = false;
  private combobox!: ComboBoxComponent;
  public dictInstitutionTypes: DictInstitutionType[] = [];
  public dictHostels: DictHostel[] = [];
  public studyLevels!: StudyLevel[];
  public studPerson: StudPerson={
    studPersonId: 0,
    studPersonExternalId:undefined,
    firstName: '',
    lastName: '',
    middleName: '',
    maidenName:'',
    buhCode:'',
    isMale: false,
    birthday:new Date,
    citizenshipId: undefined,
    dictCitizenships: new Citizenship,
    inn:'',
    snils:'',
    login: '',
    lkPhoto:'',
    firstNameLatin:'',
    lastNameLatin:'',
    middleNameLatin:'',
    lastEdited: new Date,
  }

  public phoneType: any = PhoneTypeEnum;
  public contactTypeEnum: any = ContactTypeEnum;
  public prevContactTypeEnum: any = ContactTypeEnum;
  public serialIdentificationMask: string = "9999";
  public phoneNumberMask:string="+9 (999) 000-00-00";
  public view: Observable<GridDataResult> | undefined;
  public isNewIdentification:boolean = false;
  public isNewEmail:boolean =false;
  public isNewSocialNetworks:boolean =false;
  public isNewPhone:boolean =false;
  public isNewFamily:boolean =false;
  public isNewEducationDoc:boolean =false;
  public isNewAddressRegistration : boolean =false;
  public isNewAddressResidential  : boolean =false;
  public isNewDop  : boolean =false;
  public editModeIdentification: boolean = false;
  public editModeEmail: boolean = false;
  public editModeSocialNetworks: boolean = false;
  public editModePhone: boolean = false;
  public editModeFamily: boolean = false;
  public editModeEducationDoc: boolean = false;
  public editModeAddressRegistration : boolean = false;
  public medicalCertificates:MedicalCertificate[]=[];
  public medicalCertificate:MedicalCertificate={};
  public identifications:Identifications[]=[];
  public identification: Identifications={
    identificationId: 0,
    identificationExternalId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    studPersons:new StudPerson,
    dictIdentificationTypeId: Guid.createEmpty(),
    dictIdentificationTypes: new IdentificationType,
    serial:"",
    number:"",
    issueDate:DateFromUTCAsLocal(new Date()),
    issueCode:"",
    dateBegin: DateFromUTCAsLocal(new Date()),
    dateEnd: DateFromUTCAsLocal(new Date()),
    issueDateEnd: DateFromUTCAsLocal(new Date()),
    visaDateEnd: DateFromUTCAsLocal(new Date()),
    contractor:"",
    birthPlace:"",
  }
  public dictIdentificationTypes:IdentificationType[]=[];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public familyMemberStatuses: FamilyMemberStatuses[] = [];
  public familyMembers: FamilyMembers[] = [];
  public familyMember: FamilyMembers = {
    familyMemberId: 0,
    fio: undefined,
    dictFamilyMemberStatusId: undefined,
    workPlace: undefined,
    phoneNumber: undefined,
    studPersonId: undefined,
    birthday: DateFromUTCAsLocal(new Date())
  }

  public emails:Emails[]=[];
  public allAdditional: AdditionalFields[]=[];
  public allStudentAdditional: AdditionalStudentFields[]=[];
  public studentMedicalValidates: AdditionalFields[]=[];
  public studentHostel:boolean=false;
  public studentMedical: Date[]=[];
  public numberMedical:number=0;
  public studentHostelFields?:AdditionalFields={
    additionalFieldId: 0,
    dictAdditionalFieldId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    value:"",
  }
  public studentHostelNumberFields?:AdditionalFields={
    additionalFieldId: 0,
    dictAdditionalFieldId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    value:"",
  }
  public studentHostelRoomFields?:AdditionalFields={
    additionalFieldId: 0,
    dictAdditionalFieldId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    value:"",
  }
  public contacts: Contacts[] = [];
  public contactsSort(): Contacts[] {
    return this.contacts.sort((l: any, r: any) => r.updatedAt - l.updatedAt);
  };
  public filteredContacts: Contacts[] = this.contactsSort();
  public contact:Contacts={
    id:0,
    externalId:Guid.createEmpty(),
    studPersonId:0,
    comment:"",
    content:"",
    contactType:0
  };
  public phones:Phones[]=[];
  public phone:Phones={
    phoneId: 0,
    studPersonId: Guid.createEmpty(),
    studPersons:new StudPerson,
    phoneNumber:"",
    phoneFormatted:"",
    dictPhoneTypeId: Guid.createEmpty(),
    dictPhoneType: new PhoneTypes,
    comment:"",
  }
  public socialNetworks:SocialNetworks[]=[];
  public socialNetwork:SocialNetworks={
    socialNetworksId: 0,
    socialNetworksExternalId: Guid.create(),
    studPersonId: 0,
    studPersons:new StudPerson,
    socialNetwork:"",
    comment:"",
  }
  public selectLanguages:EducationDocLanguages[]=[];
  public selectEducationDocLanguages:string[]=[];
  public selectEducationDocBall:(number|undefined)[]=[];
  public educationDocLanguage:EducationDocLanguages={
    educationDocLanguageId: 0,
    languages: "",
    dictLanguageLevelId: "",
    ball:0
  }
  public educationDocs: EducationDocs[] = [];
  public educationDoc: EducationDocs={
    educationDocId: 0,
    educationDocExternalId:undefined,
    studPersonId: Guid.createEmpty(),
    studPersons:new StudPerson,
    dictEducationDocTypeId:Guid.createEmpty(),
    dictInstitutionTypeId:Guid.createEmpty(),
    dictStudyLevelId:Guid.createEmpty(),
    dictEducationDocType: new EducationDocTypes,
    number:"",
    issueDate: new Date,
    issueOrganization:"",
    comment:undefined,
    graduateYear:0,
    graduateSpecial:"",
    graduatorName:"",
    organizationNumber:"",
    dissertationYear:0,
    issueCountry:"",
    graduateThis: false,
    facultyId: "",
  }
  public educationDocTypes: EducationDocTypes[] = [];
  public citizenship: Citizenship[] = [];
  public dictLanguages: any[] = []
  public hasRegistered = false
  public addresses: Addresses[] = [];
  public address: Addresses={
    addressId: 0,
    addressExternalId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    studPersons: new StudPerson,
    country:"",
    state:"",
    city:"",
    street:"",
    house:"",
    building:"",
    flat:"",
    district:"",
    corpus:"",
    zipCode:"",
    isRegistered:false,
    comment:"",
    dateRegistered:new Date(),
    dictCityTypeId:Guid.createEmpty(),
    dictCityType: new CityTypes,
    dictStreetTypeId: Guid.createEmpty(),
    dictStreetType: new StreetTypes,
    objectGuid: undefined,
    cityFullName: '',
    streetFullName: '',
    houseFullName: ''
  }
  public cityTypes: CityTypes[] = [];
  public streetTypes: StreetTypes[] = [];
  public dictStates: DictState[] = [];
  public selectDictStates: DictState[] = [];
  public studentId?: any;
  public loadingIdentification = false;
  public loadingEducation = false;
  public loadingFamily = false;
  public loadingContacts = false;
  public loadingAddress = false;
  public loadingAdditional = false;
  public loadingStudentAdditional = false;

  public employment: EmploymentRead[] = [];
  public phoneTypes: PhoneTypes[] = [];
  public phoneTypeId: Guid | string | undefined;
  public emailTypes: EmailTypes[] = [];
  public emailTypeId: Guid | string | undefined;

  onFocus(){
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  //Identification
  public getAllIdentification() {
    this.identificationService.getStudentIdentifications(this.studPersonId)
      .subscribe(
        response => {
          this.identifications = response;
        });
  }
  //Identification Types
  public getAllIdentificationTypes() {
    this.loadingIdentification=true
    this.identificationTypesService.getAllIdentificationType()
      .subscribe(
        response => {
          this.dictIdentificationTypes = response;
          this.loadingIdentification=false
        });
  }
  public getAllInstitutionTypes() {
    this.dictInstitutionTypeService.getAllInstitutionType()
      .subscribe(
        response => {
          this.dictInstitutionTypes = response;
        });
  }
  public getAllHostel() {
    this.dictHostelService.getAllHostel()
      .subscribe(
        response => {
          this.dictHostels = response;
        });
  }
  public getAllMedicalCertificates() {
    this.loadingAdditional=true
    this.medicalCertificateService.getStudentMedicalCertificates(this.studPersonId)
      .subscribe(
        response => {
          this.medicalCertificates = response;
          this.loadingAdditional=false
        });
  }
  //Emails
  public getAllEmails() {
    this.emailService.getStudentEmails(this.studPersonId)
      .subscribe(
        response => {
          this.emails = response;

          if(this.emails != undefined)
            this.studentMainInfoComponent.contingent.email=this.emails[0]?.email

          for(let i of this.emails){
            let contactExist = this.contacts.find(x => x.id == i.emailId && x.contactType == this.contactTypeEnum.email);
            if(!contactExist){
              this.contact.id = i.emailId;
              this.contact.externalId = i.emailExternalId;
              this.contact.content = i.email;
              this.contact.comment = i.comment;
              this.contact.contactType = this.contactTypeEnum.email;
              this.contact.updatedAt = DateFromUTCAsLocal(i.updatedAt);
              this.contacts.push(this.contact);
            }
            else{
              contactExist.content = i.email;
              contactExist.comment = i.comment;
              contactExist.updatedAt = DateFromUTCAsLocal(i.updatedAt);
            }
            this.contact = {
              id: 0,
              contactType: 0,
              comment:'',
              content:''
            }
          }

          this.loadingContacts=false
        }
      );
  }
  //Phones
  public getAllPhones() {
    this.loadingContacts=true
    this.phoneService.getStudentPhones(this.studPersonId)
      .subscribe(
        response => {
          this.phones = response;
          if(this.phones!=undefined)this.studentMainInfoComponent.contingent.phone=this.phones[0]?.phoneNumber
          for(let i of this.phones){
            let contactExist = this.contacts.find(x=>x.externalId == i.phoneExternalId);
            if(!contactExist){
              this.contact.id = i.phoneId;
              this.contact.externalId = i.phoneExternalId;
              this.contact.comment = i.comment;
              this.contact.contactType = i.dictPhoneTypeId == this.phoneType.city ? this.contactTypeEnum.cityPhone : this.contactTypeEnum.mobilePhone;
              this.contact.content = this.contact.contactType == this.contactTypeEnum.mobilePhone ? i.phoneFormatted : i.phoneNumber;
              this.contact.updatedAt = DateFromUTCAsLocal(i.updatedAt);
              this.contacts.push(this.contact);
            }
            else{
              contactExist.contactType = i.dictPhoneTypeId == this.phoneType.city ? this.contactTypeEnum.cityPhone : this.contactTypeEnum.mobilePhone;
              contactExist.content = this.contact.contactType == this.contactTypeEnum.mobilePhone ? i.phoneFormatted : i.phoneNumber;
              contactExist.comment = i.comment;
              contactExist.updatedAt = DateFromUTCAsLocal(i.updatedAt);
            }

            this.contact = {
              id: 0,
              contactType: 0,
              comment:'',
              content:''
            }
          }
        }
      );
  }
  //Phones
  public getAllSocialNetworks() {
    this.socialNetworksService.getStudentSocialNetworks(this.studPersonId)
      .subscribe(
        response => {
          this.socialNetworks = response;
          for(let i of this.socialNetworks){
            let contactExist = this.contacts.find(x=>x.id == i.socialNetworksId && x.contactType == this.contactTypeEnum.social);
            if(!contactExist){
              this.contact.id = i.socialNetworksId;
              this.contact.externalId = i.socialNetworksExternalId;
              this.contact.content = i.socialNetwork;
              this.contact.comment = i.comment;
              this.contact.contactType = this.contactTypeEnum.social;
              this.contact.updatedAt = DateFromUTCAsLocal(i.updatedAt);
              this.contacts.push(this.contact);}
            else{
              contactExist.content = i.socialNetwork;
              contactExist.comment = i.comment;
              contactExist.updatedAt = DateFromUTCAsLocal(i.updatedAt);
            }
            this.contact = {
              id: 0,
              contactType: 0,
              comment:'',
              content:''
            }
          }
        }
      );
  }
  //Citizenship
  public getCitizenship(): void {
    this.citizenshipService.getAllCitizenship()
      .subscribe(
        response => {
          this.citizenship = response;
        }
      );
  }
  //Phones
  public getAllFamily() {
    this.loadingFamily=true
    this.familyMemberService.getStudentFamilyMembers(this.studPersonId)
      .subscribe(
        response => {
          this.familyMembers = response;
          this.loadingFamily = false;
        }
      );
  }
  //DictState
  public getDictState() {
    this.dictStateService.getAllstate()
      .subscribe(
        response => {
          this.dictStates = response;
          this.selectDictStates = this.dictStates
        }
      );
  }
  //EducationDoc
  public getAllEducationDoc() {
    this.loadingEducation=true
    this.educationDocService.getStudentEducationDocs(this.studPersonId)
      .subscribe(
        response => {
          this.educationDocs = response;
          this.loadingEducation=false
        }
      );
  }
  //EducationDoc Types
  public getAllEducationDocTypes() {
    this.educationDocTypesService.getAllEducationDocTypes()
      .subscribe(
        response => {
          this.educationDocTypes = response;
        }
      );
  }

  public editable?: boolean

  public getStudents(): void {
    this.studService.getByIdstudent(this.studentId!)
      .subscribe(
        response => {
          if(response!=null){
            this.editable = response.personalDataEditable
            //this.admin()
          }
        }
      );
  }

  //Address
  public getAllAddress() {
    this.loadingAddress=true
    this.addressesService.getStudentAddresses(this.studPersonId)
      .subscribe(
        response => {
          this.addresses = response;
          for(let address of this.addresses)
          {}
          this.hasRegistered = this.addresses.find((x) => x.isRegistered) != null
          this.loadingAddress=false
        }
      );
  }

  public getAddressReg(){
    this.addressesService.getRegAddress(this.studPersonId)
      .subscribe(
        response => {
          let address = response
          if(address)
          {
            if(this.formAddresses.value.isRegistered != undefined)
            {
              address.isRegistered = this.formAddresses.value.isRegistered
              address.addressExternalId = this.formAddresses.value.addressExternalId
            }
            this.formAddresses.reset(address);
          }
        }
      );
  }

  //City Types
  public getAllCityTypes() {
    this.cityTypesService.getAllcitytypes()
      .subscribe(
        response => {
          this.cityTypes = response;
        }
      );
  }
  //Street Types
  public getAllStreetTypes() {
    this.streetTypesService.getAllstreettypes()
      .subscribe(
        response => {
          this.streetTypes = response;
        }
      );
  }
  public getAllFamilyMemberStatuses() {
    this.familyMemberStatusesService.getAllFamilyMemberStatuses()
      .subscribe(
        response => {
          this.familyMemberStatuses = response;
        }
      );
  }

  public getDisplaySettings() {
    this.displaySettingService.getStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettingService.displaySettings$.next(response);
        }
      );
  }

  //Student Hostel
  public getAdditional() {
    this.additionalFieldService.getStudentadditionalfields(this.studPersonId)
      .subscribe(
        response => {
          this.allAdditional=response;
       //   this.studentMedicalValidates = response.filter((x) => x.dictAdditionalFieldId?.toString() ===  this.enumAdditional.medical_date);
          this.studentHostelFields = response.find((x) => x.dictAdditionalFieldId?.toString() === this.enumAdditional.live_hostel);
          this.studentHostelNumberFields = response.find((x) => x.dictAdditionalFieldId?.toString() === this.enumAdditional.hostel);
          this.studentHostelRoomFields = response.find((x) => x.dictAdditionalFieldId?.toString() === this.enumAdditional.hostel_room);
          this.criminalCertificate = response.find((x) => x.dictAdditionalFieldId?.toString() === this.enumAdditional.lack_criminal_record_date);

          this.criminalCertificate && this.criminalCertificate?.value !== "" ? this.criminalCertificateDate = new Date(this.criminalCertificate?.value)
                                                                             : this.criminalCertificateDate = undefined;;
          this.medicalInspectionCertificate = response.find((x) => x.dictAdditionalFieldId?.toString() ===  this.enumAdditional.medical_date);
          this.medicalInspectionCertificate && this.medicalInspectionCertificate?.value !== "" ? this.medicalCertificateDate = new Date(this.medicalInspectionCertificate?.value)
                                                                                               : this.medicalCertificateDate = undefined;
        }
      );
  }
  public getStudentAdditional() {
    this.loadingStudentAdditional = true;
    this.additionalStudentFieldService.getadditionalstudentfields(this.studentId)
      .subscribe(
        response => {
          this.allStudentAdditional = response;
          this.loadingStudentAdditional = false;
        }
      );
  }

  public getAllStudyLevel() {
    this.commonDictService.getAllStudyLevel()
      .subscribe(
        response => {
          this.studyLevels = response;
        }
      );
  }


  /// <summary>
  /// Getting Types Name
  /// <summary>

  //Get identification type name for id
  public nameIdentificationTypes(id: Guid): string|undefined {
    return this.dictIdentificationTypes.find((x) => x.dictIdentificationTypeExternalId === id)?.identificationTypeName;
  }

  public nameContactType(id: number): string|undefined {
    return this.contactsList.find((x) => x.type === id)?.typeName;
  }

  public nameContactSubType(externalId: Guid | string, contactType: number): string|undefined {
    const indexPhone = this.phones.findIndex(el => el.phoneExternalId == externalId);
    if (indexPhone > -1 && !this.isHidden(this.enum.pd_phone_type)) {
      return this.phoneTypes.find(el => el.dictPhoneTypeExternalId == this.phones[indexPhone].dictPhoneTypeId)?.phoneTypeName;
    }
    const indexEmail = this.emails.findIndex(el => el.emailExternalId == externalId);
    if (indexEmail > -1 && !this.isHidden(this.enum.pd_email_type)) {
      return this.emailTypes.find(el => el.dictEmailTypeExternalId == this.emails[indexEmail].dictEmailTypeId)?.emailTypeName;
    }

    return undefined;
  }

  //Get phone type name for id
  public nameFamilyMemberStatus(id: Guid): string|undefined {
    return this.familyMemberStatuses.find((x) => x.dictFamilyMemberStatusExternalId === id)?.familyMemberStatusName;
  }

  //Get educationDoc type name for id
  public nameEducationDocTypes(id: any): string|undefined {
    return this.educationDocTypes.find((x) => x.dictEducationDocTypeExternalId === id)?.educationDocTypeName;
  }

  //Get city type name for id
  public nameCityTypes(id: Guid): string|undefined {
    return this.cityTypes.find((x) => x.dictCityTypeExternalId === id)?.cityTypeName;
  }

  //Get street type name for id
  public nameStreetTypes(id: Guid): string|undefined {
    return this.streetTypes.find((x) => x.dictStreetTypeExternalId === id)?.streetTypeName;
  }

  /// <summary>
  /// Start Editing
  /// <summary>

  //Start Editing Identification
  public transitionHandler({dataItem}: EditEvent): void {
    this.identification=dataItem;
    this.identification.issueDate=DateFromUTCAsLocal(this.identification.issueDate);
    this.identification.dateBegin=DateFromUTCAsLocal(this.identification.dateBegin);
    this.identification.dateEnd=DateFromUTCAsLocal(this.identification.dateEnd);
    if(this.identification.visaDateEnd)this.identification.visaDateEnd=DateFromUTCAsLocal(this.identification.visaDateEnd);
    if(this.identification.issueDateEnd)this.identification.issueDateEnd=DateFromUTCAsLocal(this.identification.issueDateEnd);
    //this.getOrder(dataItem.ordersId);
    this.formIdentification.reset(this.identification);
    this.editModeIdentification = true;
    this.isNewIdentification = false;
  }

  public transitionHandlerMedicalCertificate({dataItem}: EditEvent): void {
    this.medicalCertificate=dataItem;
    this.medicalCertificate.date=new Date(this.medicalCertificate.date!);
    this.formMedicalCertificate.reset(this.medicalCertificate);
    this.editModeDop = true;
    this.isNewDop = false;
  }

  //Start Editing Phones
  public transitionHandlerContact({dataItem}: EditEvent): void {
    this.editModePhone = true;
    this.isNewPhone = false;
    this.editModeEmail = true;
    this.isNewEmail = false;
    this.editModeSocialNetworks = true;
    this.isNewSocialNetworks = false;
    this.contact = { ...dataItem };
    this.onContactTypeChange(dataItem.contactType);
    this.prevContactTypeEnum = dataItem.contactType;
   // this.contact = { ...dataItem };
    const indexPhone = this.phones.findIndex(el => el.phoneId == dataItem.id)
    if (indexPhone > -1) this.phoneTypeId = this.phones[indexPhone].dictPhoneTypeId;
    const indexEmail = this.emails.findIndex(el => el.emailId == dataItem.id);
    if (indexEmail > -1) this.emailTypeId = this.emails[indexEmail].dictEmailTypeId;
    this.formContacts.reset(this.contact);
  }

  public stateSelect = true
  public showExternalAddressSearch = false

  public onChange(ids: any): void {
    this.showExternalAddressSearch = this.stateSelect = ids == "Российская Федерация" || ids == "Россия";
  }

  public addressHints: any = [];
  public selectedAddressItem: any;

  public onAddressFilterChange(e: any) {
    if(e.length < 3) return;
    this.addressesService.GetAddressHint(e).subscribe((response: any) => {
        this.addressHints = response.hints;
      }
    );
  }

  public onAddressChange(e: any) {
    if(e) {
      this.addressesService.GetAddressItemById(e.object_id).subscribe((response: any) => {
        this.selectedAddressItem = response;
        let address = this.formAddresses.value;
        address.objectGuid = this.selectedAddressItem.objectGuid;
        address.zipCode = this.selectedAddressItem.zipCode;
        address.district = this.selectedAddressItem.district;
        address.city = this.selectedAddressItem.city;
        address.street = this.selectedAddressItem.street;
        if (this.selectedAddressItem.house) address.house = this.selectedAddressItem.house;
        if (this.selectedAddressItem.building) address.building = this.selectedAddressItem.building;
        if (this.selectedAddressItem.corpus) address.corpus = this.selectedAddressItem.corpus;
        if (this.selectedAddressItem.flat) address.flat = this.selectedAddressItem.flat;
        address.state = this.selectDictStates.find(
          item => item.stateName
                      .toLocaleLowerCase()
                      .includes(this.selectedAddressItem.state?.toLocaleLowerCase())
        )?.stateName;
        address.dictCityTypeId = this.cityTypes.find(
          item => item.cityTypeName
                      .toLocaleLowerCase()
                      .includes(this.selectedAddressItem.cityTypeName?.toLocaleLowerCase())
        )?.dictCityTypeExternalId;
        address.dictStreetTypeId = this.streetTypes.find(
          item => item.streetTypeName
                      .toLocaleLowerCase()
                      .includes(this.selectedAddressItem.streetTypeName?.toLocaleLowerCase())
        )?.dictStreetTypeExternalId;
        this.formAddresses.reset(address);
      });
    }
  }

  public hostelChange(hostel: any): void {
    let type = this.formAddresses.value.isRegistered
    hostel.isRegistered=type
    hostel.studentHostel = true
    if(this.formAddresses.value.addressExternalId !== null) hostel.addressExternalId = this.formAddresses.value.addressExternalId
    if(this.formAddresses.value.studPersonId !== null) hostel.studPersonId = this.formAddresses.value.studPersonId
    this.formAddresses.reset(hostel)
    this.formAddresses.value.isRegistered = type
  }

  @ViewChild("stateAutoComplete", { static: true }) public multiselect: any;

  public hideArrow = false;

  public valueChange(value: any): void {
    this.hideArrow = value.length != 0;
  }

  //Start Editing Phones
  public transitionHandlerFamily({dataItem}: EditEvent): void {
    this.familyMember=dataItem;
    this.familyMember.birthday = new Date(this.familyMember.birthday!);
    this.formFamilyMember.reset(this.familyMember);
    this.editModeFamily = true;
    this.isNewFamily = false;
  }

  public transitionHandlerEducationDoc({dataItem}: EditEvent): void {
    this.dictLanguages = this.studentMainInfoComponent.dictLanguages;
    this.selectEducationDocBall = [];
    this.selectLevelLanguages = [];
    this.selectEducationDocLanguages = [];

    this.educationDoc = dataItem;
    if(this.educationDoc.educationDocLanguage!=undefined){
      for(let i=0; i<this.educationDoc.educationDocLanguage.length; i++)
      {
        this.selectEducationDocBall[i] = this.educationDoc.educationDocLanguage[i].ball == undefined ? 0 : this.educationDoc.educationDocLanguage[i].ball;
        this.selectLevelLanguages[i] = this.educationDoc.educationDocLanguage[i].dictLanguageLevelId;
        this.selectEducationDocLanguages[i] = this.educationDoc.educationDocLanguage[i].languages;
      }
    }
    this.educationDoc.issueDate=new Date(this.educationDoc.issueDate);
    if(this.educationDoc.kandidateDisserDate != null)
      this.educationDoc.kandidateDisserDate = new Date(this.educationDoc.kandidateDisserDate);
    if(this.educationDoc.approveVAKDate != null)
      this.educationDoc.approveVAKDate = new Date(this.educationDoc.approveVAKDate);
    this.educationDoc.graduateYear = this.educationDoc.graduateYear?.toString();
    this.educationDoc.dissertationYear = this.educationDoc.dissertationYear?.toString();
    this.graduateThis = this.educationDoc.graduateThis;
    this.facultyId = this.educationDoc.facultyId;
    this.showFaculties();
    if (this.contingentService.levelTraining.getValue().levelTraining == levelPostgraduateId) this.visibleDissertationYear = true;
    this.formEducationDoc.reset(this.educationDoc);
    this.editModeEducationDoc = true;
    this.isNewEducationDoc = false;
  }

  livesInHostel(dataItem: any): string{
    if(this.dictHostels.find((x)=> x.zipCode == dataItem.zipCode && x.country === dataItem.country && x.street == dataItem.street && x.house == dataItem.house)
      || (!dataItem.country && !dataItem.house && !dataItem.street)) return "Проживает в общежитии"
    else return ""
  }

  //Start Editing Address Reg
  public transitionHandlerAddressReg({dataItem}: EditEvent): void {
    this.address = dataItem;
    let addressString = [dataItem.state, dataItem.district, dataItem.city, dataItem.street, dataItem.house].filter(Boolean).join(", ");
    this.onAddressFilterChange(addressString);
    if(this.address.dateRegistered)
      this.address.dateRegistered = new Date(this.address.dateRegistered);
    let hostelNum: any;
    let hostel: any;
    hostelNum = this.dictHostels.find((x) => x.zipCode == dataItem.zipCode && x.country === dataItem.country && x.street == dataItem.street && x.house == dataItem.house)
    if(hostelNum !== undefined){
      hostel = this.address
      hostel.hostelNum = hostelNum.hostelNum
      hostel.studentHostel = true
      hostel.hostelRoom = this.studentHostelRoomFields?.value
      this.formAddresses.reset(hostel)
    }
    else{
      if(!dataItem.country && !dataItem.house && !dataItem.street)
        this.address.studentHostel = true
      this.formAddresses.reset(this.address);
    }
    this.editModeAddressRegistration = true;
    this.isNewAddressRegistration = false;
    this.onChange(this.address.country)
  }

  //Start adding Identification
  public addHandlerIdentification({}: AddEvent): void {
    this.formIdentification.reset();
    this.editModeIdentification = true;
    this.isNewIdentification =true;
    this.formIdentification.value.studentId=this.studentId;
  }

  //Start adding Contact
  public addHandlerContact({}: AddEvent): void {
    this.editModePhone = true;
    this.isNewPhone = true;
    this.editModeEmail = true;
    this.isNewEmail = true;
    this.editModeSocialNetworks = true;
    this.isNewSocialNetworks = true;
   // this.onContactTypeChange(this.contactTypeEnum.mobilePhone);
    this.filterContactsList = this.contactsList;
    this.formContacts.reset();
    this.contact = {
      id: 0,
      contactType: 0,
      comment:'',
      content:''
    }
  }

  //Start adding Phone
  public addHandlerFamily({}: AddEvent): void {
    this.formFamilyMember.reset();
    this.editModeFamily = true;
    this.isNewFamily = true;
    this.formFamilyMember.value.studPersonId = this.studPerson.studPersonExternalId;
  }

  //Start adding EducationDoc
  public addHandlerEducationDoc({}: AddEvent): void {
    if (this.contingentService.levelTraining.getValue().levelTraining == levelPostgraduateId) this.visibleDissertationYear = true;
    this.formEducationDoc.reset();
    this.selectEducationDocBall = [];
    this.selectLevelLanguages = []
    this.selectEducationDocLanguages = [];
    this.educationDoc.educationDocLanguage = [];
    this.editModeEducationDoc = true;
    this.isNewEducationDoc = true;
  }

  //Start adding Address Reg
  public addHandlerAddressReg({}: AddEvent): void {
    this.formAddresses.reset();
    this.registeredAddress = true;
    this.editModeAddressRegistration = true;
    this.isNewAddressRegistration =true;
  }

  //Close edit form
  public closeIdentification(): void {
    this.editModeIdentification = false;
    this.formIdentification.reset();
  }

  public closeContact():void{
    this.editModePhone = false;
    this.editModeEmail = false;
    this.editModeSocialNetworks = false;
    this.emailMode=false;
    this.phoneMode=false;
    this.socialMode=false;
    this.phoneCityMode = false;
    this.formContacts.reset();
    this.phoneTypeId = undefined;
    this.emailTypeId = undefined;
  }

  //Close edit form Phone
  public closeFamily(): void {
    this.editModeFamily = false;
    this.formFamilyMember.reset();
  }

  //Close edit form EducationDoc
  public closeEducationDoc(): void {
    this.editModeEducationDoc = false;
    this.visibleDissertationYear = false;
    this.formEducationDoc.reset();
    this.selectLevelLanguages = [];
    this.graduateThis = false;
    this.selectLanguages = [];
  }

  //Close edit form Address Reg
  public closeAddressReg(): void {
    this.editModeAddressRegistration = false;
    this.formAddresses.reset();
  }

  //Save Identification
  public onSaveIdentification(e: PointerEvent): void {
    e.preventDefault();
    this.saveIdentification();
    this.editModeIdentification = false;
  }

  public onSaveMedicalCertificate(e: PointerEvent): void {
    e.preventDefault();
    this.saveMedicalCertificate();
    this.editModeDop = false;
  }

  //Save Contact
  public onSaveContact(e: PointerEvent): void {
    e.preventDefault();
    this.editModePhone = false;
    this.editModeEmail = false;
    switch(this.contactType){
      case this.contactTypeEnum.mobilePhone:
        this.formPhone.value.phoneNumber = this.phoneFormat(this.formContacts.value.content);
        this.formPhone.value.comment = this.formContacts.value.comment;
        this.formPhone.value.studPersonId = this.studPersonId
        this.formPhone.value.phoneId = this.formContacts.value.id;
        this.formPhone.value.phoneExternalId = this.formContacts.value.externalId;
        this.formPhone.value.dictPhoneTypeId = this.phoneTypeId;//this.phoneType.mobile
        this.savePhone(); break;
      case this.contactTypeEnum.email:
        this.formEmail.value.email = this.formContacts.value.content;
        this.formEmail.value.comment = this.formContacts.value.comment;
        this.formEmail.value.studPersonId = this.studPersonId
        this.formEmail.value.emailId = this.formContacts.value.id;
        this.formEmail.value.emailExternalId = this.formContacts.value.externalId;
        this.formEmail.value.dictEmailTypeId = this.emailTypeId;
        this.saveEmail(); break;
      case this.contactTypeEnum.social:
        this.formSocialNetworks.value.socialNetwork = this.formContacts.value.content;
        this.formSocialNetworks.value.comment = this.formContacts.value.comment;
        this.formSocialNetworks.value.studPersonId = this.studPersonId
        this.formSocialNetworks.value.socialNetworksId = this.formContacts.value.id;
        this.formSocialNetworks.value.socialNetworksExternalId = this.formContacts.value.externalId;
        this.saveSocialNetworks(); break;
      case this.contactTypeEnum.cityPhone:
        this.formPhone.value.phoneNumber = this.phoneUnformat(this.formContacts.value.content.toString());
        this.formPhone.value.comment = this.formContacts.value.comment;
        this.formPhone.value.studPersonId = this.studPersonId
        this.formPhone.value.phoneId = this.formContacts.value.id;
        this.formPhone.value.phoneExternalId = this.formContacts.value.externalId;
        this.formPhone.value.dictPhoneTypeId = this.phoneType.city
        this.savePhone(); break;
    }
  }

  //Save Phone
  public onSaveFamily(e: PointerEvent): void {
    e.preventDefault();
    this.saveFamily();
    this.editModeFamily = false;
  }

  //Save EducationDoc
  public onSaveEducationDoc(e: PointerEvent): void {
    e.preventDefault();
    this.saveEducationDoc();
    this.editModeEducationDoc = false;
  }

  //Save Address Reg
  public onSaveAddressReg(e: PointerEvent): void {
    e.preventDefault();
    this.saveAddresses();
    this.editModeAddressRegistration = false;
  }

  public datePipe: DatePipe = new DatePipe('ru-Ru');

  public saveIdentification() {
    this.formIdentification.value.issueDate = TimeZoneFix(this.formIdentification.value.issueDate);
    this.formIdentification.value.visaDateEnd = TimeZoneFix(this.formIdentification.value.visaDateEnd);
    this.formIdentification.value.issueDateEnd = TimeZoneFix(this.formIdentification.value.issueDateEnd);
    if(this.isNewIdentification)
    {
      this.formIdentification.value.studPersonId = this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId;
      this.identificationService.addidentifications(this.formIdentification?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Удостоверение личности было успешно добавлено!");
            this.updateGrid();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить удостоверение личности");
          }}
        );
    }
    // Edit identification
    else {
      this.identificationService.updateidentifications(this.formIdentification?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Удостоверение личности было успешно сохранено!");
            this.updateGrid();
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить удостоверение личности');
          }}
        )
    }
    this.formIdentification.reset();
    this.getAllIdentification();
  }

  public saveMedicalCertificate() {
    // Add new Identification
    this.formMedicalCertificate.value.date = TimeZoneFix(this.formMedicalCertificate.value.date);
    if(this.isNewDop)
    {
      this.formMedicalCertificate.value.studPersonId = this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId;
      this.medicalCertificateService.addMedicalCertificate(this.formMedicalCertificate?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Медицинская справка была успешно добавлена!");
            this.updateGridMedicalCertificate();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить медицинскую справку");
          }}
        );
    }
    else {
      this.medicalCertificateService.updateMedicalCertificate(this.formMedicalCertificate?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Медицинская справка была успешно сохранена!");
            this.updateGridMedicalCertificate();
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить медицинскую справку');
          }}
        )
    }
    this.formMedicalCertificate.reset();
    this.getAllMedicalCertificates();
  }

  //Save Email
  public saveEmail() {
    // Add new Email
    this.formEmail.value.lastEditDate = TimeZoneFix(this.formEmail.value.lastEditDate);
    if(this.isNewEmail || this.prevContactTypeEnum !== ContactTypeEnum.email)
    {
      this.deletePrevContact();
      this.formEmail.value.lastEditDate = new Date(2002,2,2);
      this.formEmail.value.isActive = true;
      this.emailService.addemails(this.formEmail?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Email был успешно добавлен!");
            this.updateGridContact();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить Email");
          }}
        );
    }
    // Edit standard
    else {
      this.emailService.updateemails(this.formEmail?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Email был успешно сохранен!");
            this.updateGridContact();
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить Email');
          }}
        )
    }
    this.formEmail.reset();
    this.closeContact();
  }

  public isHidden(field: number): boolean {
    return this.displaySettingService.isHidden(field);
  }

  //Save Email
  public saveSocialNetworks() {
    // Add new SocialNetwork
    if(this.isNewSocialNetworks || this.prevContactTypeEnum !== ContactTypeEnum.social)
    {
      this.deletePrevContact();

      this.socialNetworksService.addSocialNetworks(this.formSocialNetworks?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Соц.сеть была успешно добавлена!");
            this.updateGridContact();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить соц.сеть");
          }}
        );
    }
    // Edit standard
    else {
      this.socialNetworksService.updateSocialNetworks(this.formSocialNetworks?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Соц.сеть была успешно сохранена!");
            this.updateGridContact();
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить соц.сеть');
          }}
        )
    }
    this.formSocialNetworks.reset();
    this.closeContact();
  }

  //Save Phone
  public savePhone() {
    // Add new Phone
    if(this.isNewPhone || (this.prevContactTypeEnum !== ContactTypeEnum.mobilePhone && this.prevContactTypeEnum !== ContactTypeEnum.cityPhone))
    {
      this.deletePrevContact();

      this.phoneService.addphones(this.formPhone?.value, this.studentId)
        .subscribe({
          next:(response) => {
            this.notificationService.showSuccess("Номер был успешно добавлен!");
            this.updateGridContact();
            this.phoneService.phoneNumber$.next(response.phoneNumber);
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить номер");
          }}
        );
    }
    // Edit phone
    else {
      this.phoneService.updatephones(this.formPhone?.value, this.studentId)
        .subscribe({
          next:(response) => {
            this.notificationService.showSuccess("Номер был успешно сохранен!");
            this.updateGridContact();
            if (response && response.phoneNumber)
              this.phoneService.phoneNumber$.next(response.phoneNumber);
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить номер');
          }}
        )
    }
    this.formPhone.reset();
    this.closeContact();
  }

  //Save Family
  public saveFamily() {
    // Add new Family
    this.formFamilyMember.value.birthday = TimeZoneFix(this.formFamilyMember.value.birthday);
    if(this.isNewFamily)
    {
      this.formFamilyMember.value.studPersonId = this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId;
      this.familyMemberService.addfamilymembers(this.formFamilyMember?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Родственник успешно добавлен!");
            this.updateGridFamily();
            this.studentMainInfoComponent.contingent.familyMember=this.formFamilyMember.value;
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить");
          }}
        );
    }
    // Edit phone
    else {
      this.familyMemberService.updatefamilymembers(this.formFamilyMember?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Родственник был успешно сохранен!");
            this.updateGridFamily();
            this.studentMainInfoComponent.contingent.familyMember=this.formFamilyMember.value;
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить');
          }}
        )
    }
    this.formPhone.reset();
  }


  //Save EducationDoc
  public saveEducationDoc() {
    // Add new EducationDoc
    for(let i=0; i<this.selectEducationDocLanguages.length;i++)
    {
      let langId: number|undefined
      let langExternalId: Guid|undefined
      if(this.educationDoc.educationDocLanguage != undefined){
        langId = this.educationDoc.educationDocLanguage[i] === undefined ? undefined : this.educationDoc.educationDocLanguage[i].id
        langExternalId = this.educationDoc.educationDocLanguage[i] === undefined ? undefined : this.educationDoc.educationDocLanguage[i].externalId
      }
      this.educationDocLanguage={
        educationDocLanguageId: langId,
        educationDocLanguageExternalId:langExternalId,
        educationDocId: this.educationDoc.educationDocExternalId,
        languages: this.selectEducationDocLanguages[i],
        dictLanguageId: this.dictLanguages.find(x => x.languageName === this.selectEducationDocLanguages[i])?.dictLanguageExternalId,
        dictLanguageLevelId: this.selectLevelLanguages[i],
        ball: this.selectEducationDocBall[i]
      }
      this.selectLanguages.push(this.educationDocLanguage);
      this.educationDocLanguage = {
        educationDocLanguageId: undefined,
        educationDocLanguageExternalId: undefined,
        educationDocId:undefined,
        languages: "",
        dictLanguageLevelId: "",
        ball:0
      }
    }
    this.formEducationDoc.value.educationDocLanguage=this.selectLanguages;
    // Fix dates
    this.formEducationDoc.value.issueDate = TimeZoneFix(this.formEducationDoc.value.issueDate);
    if(this.formEducationDoc.value.approveVAKDate != undefined)
      this.formEducationDoc.value.approveVAKDate = TimeZoneFix(this.formEducationDoc.value.approveVAKDate);
    if(this.formEducationDoc.value.kandidateDisserDate != undefined)
      this.formEducationDoc.value.kandidateDisserDate = TimeZoneFix(this.formEducationDoc.value.kandidateDisserDate);

    if(this.isNewEducationDoc)
    {
     // this.formEducationDoc.value.dissertationYear = 0;

      this.formEducationDoc.value.studPersonId = this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId;


      this.educationDocService.addeducationdocs(this.formEducationDoc?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Документ об образовании был успешно добавлен!");
            this.updateGridEducationDoc();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить документ");
          }}
        );
    }
    // Edit educationDoc
    else {
      if (this.formEducationDoc.value.dissertationYear == "") this.formEducationDoc.value.dissertationYear = null;
      this.educationDocService.updateeducationdocs(this.formEducationDoc?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Документ об образовании был успешно сохранен!");
            this.updateGridEducationDoc();
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить документ об образовании');
          }}
        )
    }
    this.formEducationDoc.reset();
    this.closeEducationDoc();
  }

  //Save Addresses
  public saveAddresses() {
    // Add new Addresses
    this.formAddresses.value.dateRegistered = TimeZoneFix(this.formAddresses.value.dateRegistered);
    if(this.formAddresses.value.studentHostel && this.formAddresses.value.hostelNum === null){
      this.formAddresses.value.country = " "
      this.formAddresses.value.state = " "
      this.formAddresses.value.city = " "
      this.formAddresses.value.house = " "
      this.formAddresses.value.street = " "
    }
    if(this.isNewAddressRegistration || this.isNewAddressResidential) //
    {
      this.formAddresses.value.studPersonId = this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId;

      this.addressesService.addaddresses(this.formAddresses?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Адрес был успешно добавлен!");
            this.updateGridAddresses();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить адрес");
          }}
        );
    }
    // Edit educationDoc
    else {
      this.addressesService.updateaddresses(this.formAddresses?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Адрес был успешно сохранен!");
            this.updateGridAddresses();
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить адрес');
          }}
        )
    }
    if(this.formAddresses.value.studentHostel) this.updateDop()
    this.formAddresses.reset();
    this.isNewAddressRegistration = false;
    this.isNewAddressResidential= false;
  }

  //Update form identification
  public updateGrid() {
    this.getAllIdentification();
    this.getAllIdentificationTypes();
  }

  public updateGridMedicalCertificate() {
    this.getAllMedicalCertificates();
  }

  //Update form phone
  public updateGridContact() {
    this.getAllPhones();
    this.getAllEmails();
    this.getAllSocialNetworks();
  }

  //Update form phone
  public updateGridFamily() {
    this.getAllFamily();
    this.getAllFamilyMemberStatuses();
  }

  //Update form EducationDoc
  public updateGridEducationDoc() {
    this.getAllEducationDoc();
    this.getAllEducationDocTypes();
  }

  //Update form Addresses
  public updateGridAddresses() {
    this.getAllAddress();
    this.getAllCityTypes();
    this.getAllStreetTypes();
    this.getAdditional();
  }

  // Convert date to UTC
  public timeZoneFix() {
    // TODO: Временное решение проблемы с 1970 (потом закомментировать)
    const dates = [
      {
        formControlName: 'visaDateEnd',
        formGroup: 'formIdentification'
      },
      {
        formControlName: 'issueDate',
        formGroup: 'formIdentification'
      },
      {
        formControlName: 'dateBegin',
        formGroup: 'formIdentification'
      },
      {
        formControlName: 'dateEnd',
        formGroup: 'formIdentification'
      },
      {
        formControlName: 'issueDateEnd',
        formGroup: 'formIdentification'
      },
      {
        formControlName: 'lastEditDate',
        formGroup: 'formEmail'
      },
      {
        formControlName: 'issueDate',
        formGroup: 'formEducationdoc'
      },
      {
        formControlName: 'approveVAKDate',
        formGroup: 'formEducationdoc'
      },
      {
        formControlName: 'kandidateDisserDate',
        formGroup: 'formEducationdoc'
      },
      {
        formControlName: 'dateRegistered',
        formGroup: 'formAddresses'
      },
      {
        formControlName: 'date',
        formGroup: 'formMedicalCertificate'
      },
    ]

    dates.forEach((item) => {
      let date: any;
      let formControl: any;
      let untypedFormGroup: UntypedFormGroup | undefined;
      switch(item.formGroup) {
        case 'formIdentification':
          untypedFormGroup = this.formIdentification;
          break;
        case 'formEmail':
          untypedFormGroup = this.formEmail;
          break;
        case 'formEducationdoc':
          untypedFormGroup = this.formEducationDoc;
          break;
        case 'formAddresses':
          untypedFormGroup = this.formAddresses;
          break;
        case 'formMedicalCertificate':
          untypedFormGroup = this.formMedicalCertificate;
          break;
      }
      if(untypedFormGroup) {
        formControl = untypedFormGroup.controls[item.formControlName].value;
        date = this.datePipe.transform(formControl, 'dd/MM/yyyy');
        if(date === '01/01/1970' || date == null) {
          untypedFormGroup.controls[item.formControlName].setValue(null);
        }
      }
    });
  }

  /// <summary>
  /// Forms
  /// </summary>

  //Form identification
  public formIdentification: UntypedFormGroup = new UntypedFormGroup({
    identificationId: new UntypedFormControl(),
    identificationExternalId: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    studPersons:new UntypedFormControl(),
    dictIdentificationTypeId: new UntypedFormControl(),
    dictIdentificationTypes: new UntypedFormControl(),
    serial:new UntypedFormControl(),
    number:new UntypedFormControl(),
    issueDate:new UntypedFormControl(DateFromUTCAsLocal(new Date())),
    issueOrganization:new UntypedFormControl(),
    issueCode:new UntypedFormControl(),
    dateBegin: new UntypedFormControl(DateFromUTCAsLocal(new Date())),
    dateEnd: new UntypedFormControl(DateFromUTCAsLocal(new Date())),
    issueDateEnd: new UntypedFormControl(DateFromUTCAsLocal(new Date())),
    visaDateEnd: new UntypedFormControl(DateFromUTCAsLocal(new Date())),
    contractor:new UntypedFormControl(),
    birthPlace:new UntypedFormControl(),
  });

  emailRegex =
    '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  //Form Email
  public formEmail: UntypedFormGroup = new UntypedFormGroup({
    emailId: new UntypedFormControl(),
    emailExternalId: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    studPersons:new UntypedFormControl(),
    dictEmailTypeId: new UntypedFormControl(),
    dictEmailType: new UntypedFormControl(),
    email:new UntypedFormControl([
      Validators.required,
      Validators.pattern(this.emailRegex),
    ]),
    comment:new UntypedFormControl(),
    isActive:new UntypedFormControl(),
    lastEditDate: new UntypedFormControl(new Date()),
  });

  //Form Phone
  public formContacts: UntypedFormGroup = new UntypedFormGroup({
    contactType: new UntypedFormControl(),
    id: new UntypedFormControl(),
    externalId: new UntypedFormControl(),
    content: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    phoneTypeId: new UntypedFormControl(),
    emailTypeId: new UntypedFormControl(),
  });

  //Form Phone
  public formPhone: UntypedFormGroup = new UntypedFormGroup({
    phoneId: new UntypedFormControl(),
    phoneExternalId: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    studPersons: new UntypedFormControl(),
    phoneNumber: new UntypedFormControl(),
    dictPhoneTypeId: new UntypedFormControl(),
    dictPhoneType: new UntypedFormControl(),
    comment: new UntypedFormControl(),
  });


  //Form SocialNetwork
  public formSocialNetworks: UntypedFormGroup = new UntypedFormGroup({
    socialNetworksId: new UntypedFormControl(),
    socialNetworksExternalId: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    studPersons: new UntypedFormControl(),
    socialNetwork: new UntypedFormControl( this.socialNetwork.socialNetwork, [
      Validators.required,
      Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),
    ]),
    comment: new UntypedFormControl(),
  });

  //Form Education Doc
  public formEducationDoc: UntypedFormGroup = new UntypedFormGroup({
    educationDocId: new UntypedFormControl(),
    educationDocExternalId: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    studPersons:new UntypedFormControl(),
    dictEducationDocTypeId:new UntypedFormControl(),
    dictInstitutionTypeId:new UntypedFormControl(),
    dictStudyLevelId:new UntypedFormControl(),
    dictEducationDocType: new UntypedFormControl(),
    serial: new UntypedFormControl(),
    number: new UntypedFormControl(),
    educationDocLanguage: new UntypedFormControl(),
    issueDate: new UntypedFormControl(),
    issueOrganization: new UntypedFormControl(),
    //organizationNumber: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    graduateYear: new UntypedFormControl(),
    graduateSpecial: new UntypedFormControl(),
    graduatorName: new UntypedFormControl(),
    dissertationYear: new UntypedFormControl(),
    kandidateDisserDate: new UntypedFormControl(),
    approveVAKDate: new UntypedFormControl(),
    issueCountry: new UntypedFormControl(),
    graduateThis: new UntypedFormControl(),
    facultyId: new UntypedFormControl(),
    dictStudyFormId: new UntypedFormControl(),
    dictQualificationId: new UntypedFormControl(),
  });

  //Form Addresses Doc
  public formAddresses: UntypedFormGroup = new UntypedFormGroup({
    addressId:  new UntypedFormControl(),
    addressExternalId:  new UntypedFormControl(),
    studPersonId:  new UntypedFormControl(),
    studPersons: new UntypedFormControl(),
    country: new UntypedFormControl(),
    state: new UntypedFormControl(),
    city: new UntypedFormControl(),
    street: new UntypedFormControl(),
    house: new UntypedFormControl(),
    building: new UntypedFormControl(),
    flat: new UntypedFormControl(),
    zipCode: new UntypedFormControl(),
    district: new UntypedFormControl(),
    corpus: new UntypedFormControl(),
    isRegistered: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    dateRegistered: new UntypedFormControl(),
    dictCityTypeId: new UntypedFormControl(),
    dictCityType:  new UntypedFormControl(),
    dictStreetTypeId: new UntypedFormControl(),
    dictStreetType:  new UntypedFormControl(),
    hostelRoom: new UntypedFormControl(),
    hostelNum: new UntypedFormControl(),
    studentHostel: new UntypedFormControl(false),
    addressSearch: new UntypedFormControl(),
    objectGuid: new UntypedFormControl(),
  });

  public formFamilyMember: UntypedFormGroup = new UntypedFormGroup({
    familyMemberId: new UntypedFormControl(),
    familyMemberExternalId: new UntypedFormControl(),
    fio: new UntypedFormControl(),
    email: new UntypedFormControl(),
    post: new UntypedFormControl(),
    address: new UntypedFormControl(),
    dictFamilyMemberStatusId: new UntypedFormControl(),
    workPlace: new UntypedFormControl(),
    phoneNumber: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    birthday: new UntypedFormControl(),
  });

  public formMedicalCertificate: UntypedFormGroup = new UntypedFormGroup({
    id:new UntypedFormControl(),
    externalId:new UntypedFormControl(),
    name: new UntypedFormControl(),
    number: new UntypedFormControl(),
    date: new UntypedFormControl(),
    note: new UntypedFormControl(),
  });

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number|undefined;
  private isNew = false;

  public form: UntypedFormGroup = new UntypedFormGroup({
    address: new UntypedFormControl(),
    medicalCertificate: new UntypedFormControl(),
    hostel: new UntypedFormControl(),
  })

  phoneFormat(input: string): string { //returns +# (###) ###-####
    if (input != null) {
      const digits = input.replace(/\D/g, '');
      const formattedNumber = digits.slice(0, 11);

      return formattedNumber.replace(/^\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1 ($2) $3-$4-$5');
    }

    return input;
  }

  phoneUnformat(input: string): string {
    if (input != null) {
      return input.replace(/\D/g, '');
    }

    return input;
  }

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  public close(): void {
    this.opened = false;
  }

  public closeDop(): void {
    this.editModeDop = false;
    this.studentMedical.length=this.numberMedical;
    this.studentMedicalValidates.length=this.numberMedical;
    this.getAdditional();
  }

  public addLang():void{
    this.dictLanguages = this.studentMainInfoComponent.dictLanguages;
    this.educationDoc.educationDocLanguage!.length += 1;
    this.selectEducationDocLanguages.push("");
    this.selectLevelLanguages.length += 1;
    this.selectEducationDocBall.length += 1;
  }

  public removeLanguages(i:number):void{
    if (i > -1) {
      this.selectEducationDocLanguages.splice(i, 1);
      this.selectEducationDocBall.splice(i, 1);
      this.selectLevelLanguages.splice(i, 1);
      this.educationDoc.educationDocLanguage!.splice(i, 1);
    }
  }

  updateDop()
  {
    if(this.studentHostelFields?.additionalFieldId==null) //
    {
      this.studentHostelFields={
        additionalFieldId: 0,
        studPersonId: this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId,
        dictAdditionalFieldId: this.enumAdditional.live_hostel,
        value: this.formAddresses.value.studentHostel==true?"true":"false",
      }
      this.additionalFieldService.addadditionalfields(this.studentHostelFields!, this.studentId)
        .subscribe({
          next:() => {
            //this.notificationService.showSuccess("Дополнительная информация была успешно сохранена!");
          },
          error:() => {
            //this.notificationService.showError("Не удалось сохранить дополнительную информацию");
          }}
        );
    }
    else {
      this.studentHostelFields.value=this.formAddresses.value.studentHostel==true?"true":"false";
      this.additionalFieldService.updateadditionalfields(this.studentHostelFields!, this.studentId)
        .subscribe({
          next:() => {
            //this.notificationService.showSuccess("Дополнительная информация была успешно сохранена!");
          },
          error:() => {
            //this.notificationService.showError('Не удалось сохранить дополнительную информацию');
          }}
        )
    }
    if(this.formAddresses.value.hostelNum!=undefined){
      if(this.studentHostelNumberFields?.additionalFieldId==null) //
      {

        this.studentHostelNumberFields={
          additionalFieldId: 0,
          studPersonId: this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId,
          dictAdditionalFieldId: this.enumAdditional.hostel,
          value: this.formAddresses.value.hostelNum.toString(),
        }
        this.additionalFieldService.addadditionalfields(this.studentHostelNumberFields!, this.studentId)
          .subscribe({
            next:() => {
              //this.notificationService.showSuccess("Дополнительная информация была успешно сохранена!");
            },
            error:() => {
              //this.notificationService.showError("Не удалось сохранить дополнительную информацию");
            }}
          );
      }
      else {
        this.studentHostelNumberFields.value=this.formAddresses.value.hostelNum.toString();
        this.additionalFieldService.updateadditionalfields(this.studentHostelNumberFields!, this.studentId)
          .subscribe({
            next:() => {
              //this.notificationService.showSuccess("Дополнительная информация была успешно сохранена!");
            },
            error:() => {
              //this.notificationService.showError('Не удалось сохранить дополнительную информацию');
            }}
          )
      }}

    //if(this.studentHostelRoom!=undefined)
    if(this.formAddresses.value.hostelRoom!=undefined)
    {
      if(this.studentHostelRoomFields?.additionalFieldId==null) //
      {

        this.studentHostelRoomFields={
          additionalFieldId: 0,
          studPersonId: this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId,
          dictAdditionalFieldId: this.enumAdditional.hostel_room,
          value: this.formAddresses.value.hostelRoom//this.studentHostelRoom
        }
        this.additionalFieldService.addadditionalfields(this.studentHostelRoomFields!, this.studentId)
          .subscribe({
            next:() => {
            },
            error:() => {
            }}
          );
      }
      else {
        this.studentHostelRoomFields.value=this.formAddresses.value.hostelRoom
        this.additionalFieldService.updateadditionalfields(this.studentHostelRoomFields!, this.studentId)
          .subscribe({
            next:() => {
            },
            error:() => {
            }}
          )
      }}

    for(let i=0;i<this.studentMedical.length;i++){
      if(this.studentMedicalValidates[i]?.additionalFieldId==null) //
      {
        this.studentMedicalValidates[i]={
          additionalFieldId: 0,
          studPersonId: this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId,
          dictAdditionalFieldId: this.enumAdditional.medical_date,
          value: this.studentMedical[i].toString(),
        }
        this.additionalFieldService.addadditionalfields(this.studentMedicalValidates[i]!, this.studentId)
          .subscribe({
            next:() => {
              //this.notificationService.showSuccess("Дополнительная информация сохранена");
            },
            error:() => {
              //this.notificationService.showError("Не удалось сохранить дополнительную информацию");
            }}
          );
      }
      else {
        this.studentMedicalValidates[i].value=this.studentMedical[i].toString()
        this.additionalFieldService.updateadditionalfields(this.studentMedicalValidates[i]!, this.studentId)
          .subscribe({
            next:() => {
              //this.notificationService.showSuccess("Дополнительная информация была успешно сохранена!");
            },
            error:() => {
              //this.notificationService.showError('Не удалось сохранить дополнительную информацию');
            }})
      }}

    this.editModeDop = false;
    //this.notificationService.showSuccess("Сохранено");
  }

//Deleting an entry from dictionary
  public removeHandlerIdentification({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + this.nameIdentificationTypes(dataItem.dictIdentificationTypeId) + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.identificationService.deleteidentifications(dataItem.identificationExternalId, this.studentId)
            .subscribe({
              next:() => {
                this.getAllIdentification();
                this.notificationService.showSuccess("Удалено");
              }});
        }
        else{
          this.opened = false;
        }
      }
    });
  }
  public removeHandlerMedicalCertificate({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + dataItem.name + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.medicalCertificateService.deleteMedicalCertificate(dataItem.externalId, this.studentId)
            .subscribe({
              next:() => {
                this.getAllMedicalCertificates();
                this.notificationService.showSuccess("Удалено");
              }});
        }
        else{
          this.opened = false;
        }
      }
    });
  }
//Deleting an entry from dictionary
  public removeHandlerEducationDoc({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + this.nameEducationDocTypes(dataItem.dictEducationDocTypeId) + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.educationDocService.deleteeducationdocs(dataItem.educationDocExternalId, this.studentId)
            .subscribe({
              next:() => {
                this.getAllEducationDoc();
                this.notificationService.showSuccess("Удалено");
              }});
        }
        else{
          this.opened = false;
        }
      }
    });
  }

//Deleting an entry from dictionary
  public removeHandlerAddress({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + (dataItem.zipCode==null?"": dataItem.zipCode) + " " + dataItem.state + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          if(this.livesInHostel(dataItem)==="Проживает в общежитии")
          {
            this.additionalFieldService.deleteadditionalfields(this.studentHostelRoomFields?.additionalFieldExternalId,this.studentId).subscribe(
              () => {});
            this.additionalFieldService.deleteadditionalfields(this.studentHostelNumberFields?.additionalFieldExternalId, this.studentId).subscribe(
              () => {});
            this.additionalFieldService.deleteadditionalfields(this.studentHostelFields?.additionalFieldExternalId, this.studentId).subscribe(
              () => {});
          }
          this.addressesService.deleteaddresses(dataItem.addressExternalId, this.studentId)
            .subscribe({
              next:() => {
                this.getAllAddress();
                this.notificationService.showSuccess("Удалено");
              }});
        }
        else{
          this.opened = false;
        }
      }
    });
  }

//Deleting an entry from dictionary
  public removeHandlerContact({ dataItem }: RemoveEvent): void {
    let content = dataItem.content;
    switch(dataItem.contactType){
      case 1: content=this.phoneFormat(content);break;
      case 2:break;
      case 3:break;
    }
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + content  + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text == "Да") {
          this.opened = false;
          if (dataItem.contactType == this.contactTypeEnum.mobilePhone || dataItem.contactType == this.contactTypeEnum.cityPhone) {
            this.phoneService.deletephones(dataItem.externalId, this.studentId)
              .subscribe({
                next:(response) => {
                  const indexOfObject = this.contacts.findIndex((object) => {
                    return object.id === dataItem.id && object.contactType === dataItem.contactType;
                  });
                  if (indexOfObject !== -1) {
                    this.contacts.splice(indexOfObject, 1);
                  }
                  this.phoneService.phoneNumber$.next(response.phoneNumber);
                  this.notificationService.showSuccess("Удалено");
                }});
          }
          if (dataItem.contactType == this.contactTypeEnum.email) {
            this.emailService.deleteemails(dataItem.externalId, this.studentId)
              .subscribe({
                next:() => {
                  const indexOfObject = this.contacts.findIndex((object) => {
                    return object.id === dataItem.id && object.contactType === dataItem.contactType;
                  });
                  if (indexOfObject !== -1) {
                    this.contacts.splice(indexOfObject, 1);
                  }
                  this.notificationService.showSuccess("Удалено");
                }});
          }
          if (dataItem.contactType == this.contactTypeEnum.social) {
            this.socialNetworksService.deleteSocialNetworks(dataItem.externalId, this.studentId)
              .subscribe({
                next:() => {
                  const indexOfObject = this.contacts.findIndex((object) => {
                    return object.id === dataItem.id && object.contactType === dataItem.contactType;
                  });
                  if (indexOfObject !== -1) {
                    this.contacts.splice(indexOfObject, 1);
                  }
                  this.notificationService.showSuccess("Удалено");
                }});
          }
        } else {
          this.opened = false;
        }
      }
    });
  }

//Deleting an entry from dictionary
  public removeHandlerFamily({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + dataItem.fio  + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.familyMemberService.deletefamilymembers(dataItem.familyMemberExternalId, this.studentId)
            .subscribe({
              next:() => {
                this.getAllFamily();
                this.notificationService.showSuccess("Удалено");
              }});
        }
        else this.opened = false;
      }
    });
  }

  public addressType: Array<{ typeName: string; isRegistered: boolean }> = [
    { typeName: "Адрес по прописке", isRegistered: true },
    { typeName: "Фактический адрес проживания", isRegistered: false },
  ];

  public contactsList: Array<{ typeName: string; type: number }> = [
    { typeName: "Мобильный телефон", type: 1 },
    { typeName: "Городской телефон", type: 4 },
    { typeName: "E-mail", type: 2 },
    { typeName: "Соц. сеть", type: 3 },
  ];
  public filterContactsList: Array<{ typeName: string; type: number }> = this.contactsList;


  public open(): void {
    this.opened = true;
  }

  public focus(): void {
    this.combobox.toggle(true);
  }

  //Switch on edit mode
  public edit(): void {
    this.editMode = true;
  }

  public addHandlerMedicalCertificate({}: AddEvent): void {
    this.formMedicalCertificate.reset();
    this.editModeDop = true;
    this.isNewDop =true;
    this.formMedicalCertificate.value.studPersonId=this.studPersonId;
  }

  public phoneMode: boolean = false;
  public phoneCityMode: boolean = false;
  public emailMode: boolean = false;
  public socialMode: boolean = false;
  public contactType: number = 1;

  public onContactTypeChange(id:number): void {
    this.contact.contactType = id;
    this.formContacts.reset(this.contact);
    this.socialMode = false;
    this.emailMode = false;
    this.phoneMode = false;
    this.phoneCityMode = false;
    if (this.contact.id !== 0) this.filterContactsList = this.contactsList.filter(el => el.type == id);
    switch(id){
      case 1:
        this.phoneMode = true;
        break;
      case 2:
        this.emailMode = true;
        break;
      case 3:
        this.socialMode = true;
        break;
      case 4:
        this.phoneCityMode = true;
        break;
    }
    this.contactType = id;
  }

//Languages
  public formGroupLanguages!: UntypedFormGroup|undefined;
  public createFormGroup(args: CreateFormGroupArgs): FormGroup {
    args.isNew ? new EducationDocLanguages() : args.dataItem;
    this.formGroupLanguages = this.formBuilder.group({
      id: new UntypedFormControl(),
      educationDocId: new UntypedFormControl(Validators.required),
      languages: new UntypedFormControl(),
      ball: new UntypedFormControl(),
    });
    return this.formGroupLanguages;
  }

  public formatAdditionalValue(value: string, dataType: string): string
  {
    if(!value) return "";
    if(dataType == DataTypeEnum.bool)
      return value=="true" ? "да" : (value == "false" ? "нет" : "н/у");
    return value;
  }

  private removeContact(array: Contacts[], type: any)
  {
    const indexOfObject = array.findIndex((object) => {
      return object.id === this.contact.id && object.contactType === type;
    });
    if (indexOfObject !== -1) {
      array.splice(indexOfObject, 1);
    }
  }

  private removeFilteredObject(type: any) {
    this.removeContact(this.contacts, type);
    this.removeContact(this.filteredContacts, type);
  }

  private deletePrevContact() {
    if (this.contact.externalId !== undefined) {
      if ((this.prevContactTypeEnum === ContactTypeEnum.cityPhone || this.prevContactTypeEnum === ContactTypeEnum.mobilePhone)
        && (this.contact.contactType === ContactTypeEnum.social || this.contact.contactType === ContactTypeEnum.email))
      {
        this.phoneService.deletephones(this.contact.externalId.toString(), this.studentId)
          .subscribe({
            next: () => {
              this.prevContactTypeEnum === ContactTypeEnum.mobilePhone ?
                this.removeFilteredObject(ContactTypeEnum.mobilePhone) :
                this.removeFilteredObject(ContactTypeEnum.cityPhone)
            }
          });
      }
      if (this.prevContactTypeEnum === ContactTypeEnum.email && this.contact.contactType !== ContactTypeEnum.email)
      {
        this.emailService.deleteemails(this.contact.externalId.toString(), this.studentId)
          .subscribe({
            next: () => {
              this.removeFilteredObject(ContactTypeEnum.email);
            }
          });
      }

      if (this.prevContactTypeEnum === ContactTypeEnum.social && this.contact.contactType !== ContactTypeEnum.social)
      {
        this.socialNetworksService.deleteSocialNetworks(this.contact.externalId.toString(), this.studentId)
          .subscribe({
            next: () => {
              this.removeFilteredObject(ContactTypeEnum.social);
            }
          });
      }
    }
  }

  //Контакты: типы телефонов и e-mail

  public getAllPhoneTypes() {
    this.phonetypesService.getAllPhoneTypes()
      .subscribe(response => {
        this.phoneTypes = response;
      })
  }

  public getAllEmailTypes() {
    this.emailtypesService.getAllemailtypes()
      .subscribe(response => {
        this.emailTypes = response;
      })
  }

  //Трудовая деятельность

  @ViewChild("gridEmployment") private gridEmployment!: GridComponent;
  public graduateThis: boolean = false;

  public getEmployment() {
    this.employmentService.getEmployment(this.studPersonId)
      .subscribe(response => {
        this.employment = response;
      })
  }

  public loadFile(scanFileId: string) {

  }

  public exportToPDF() {
    this.gridEmployment.saveAsPDF();
  }

  //Документы об образовании

  public languageLevels: LanguageLevels[] = [];
  public selectLevelLanguages: (string)[] = [];
  public dictStudyForms: DictStudyForm[] = [];
  public registeredAddress: boolean = true;
  public qualifications: Qualification[] = [];
  public visibleDissertationYear: boolean = false;
  public faculties: Faculty[] = [];
  public facultyId: string | null = "";

  public getAllFaculty() {
    this.departmentService.getAllFaculty(2)
      .subscribe(
        response => {
          this.faculties = response;
        }
      );
  }

  public showFaculties() {
    if (!this.graduateThis) this.facultyId = null;
  }

  public getAllLanguageLevels() {
    this.languageLevelsService.getAlllanguagelevels()
    .subscribe(
      response => {
        this.languageLevels = response;
      }
    );
  }

  public getAllStudyForm() {
    this.commonDictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictStudyForms = response;
        }
      );
  }

  public valueAddressTypeChange(value: boolean) {
    this.registeredAddress = value;
  }

  public getQualifications() {
    this.dictQualificationService.getQualification()
      .subscribe(response => {
        this.qualifications = response;
      })
  }

  //Дополнительная информация

  public medicalCertificateDate: Date | undefined;
  public medicalInspectionCertificate?:AdditionalFields={
    additionalFieldId: 0,
    dictAdditionalFieldId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    value: "",
  }
  public criminalCertificateDate:  Date | undefined;
  public criminalCertificate?:AdditionalFields={
    additionalFieldId: 0,
    dictAdditionalFieldId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    value: "",
  }
  public editDateDopInformation: boolean = false;

  public editDopInformation() {
    this.editDateDopInformation = true;
  }

  public saveDopInformation() {
    if (this.criminalCertificateDate !== undefined) {
      if(this.criminalCertificate?.additionalFieldId == null)
      {
        this.criminalCertificate={
          additionalFieldId: 0,
          studPersonId: this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId,
          dictAdditionalFieldId: this.enumAdditional.lack_criminal_record_date,
          value: this.criminalCertificateDate.toString(),
        }
        this.saveAdditionalInformation(this.criminalCertificate, nameAdditionalFields.lack_criminal_record_date);
      }
      else {
        if (this.criminalCertificateDate == null || new Date(this.criminalCertificate.value).valueOf() !== this.criminalCertificateDate.valueOf()) {
          this.criminalCertificate.value = this.criminalCertificateDate ? this.criminalCertificateDate.toString() : "";
          this.updateAdditionalInformation(this.criminalCertificate, nameAdditionalFields.lack_criminal_record_date);
        }
      }
    }

    if (this.medicalCertificateDate !== undefined) {
      if(this.medicalInspectionCertificate?.additionalFieldId == null)
      {
        this.medicalInspectionCertificate={
          additionalFieldId: 0,
          studPersonId: this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId,
          dictAdditionalFieldId: this.enumAdditional.medical_date,
          value: this.medicalCertificateDate.toString(),
        }
        this.saveAdditionalInformation(this.medicalInspectionCertificate, nameAdditionalFields.medical_date);
      }
      else {
        if (this.medicalCertificateDate == null || new Date(this.medicalInspectionCertificate.value).valueOf() !== this.medicalCertificateDate.valueOf()) {
          this.medicalInspectionCertificate.value = this.medicalCertificateDate ? this.medicalCertificateDate.toString() : "";
          this.updateAdditionalInformation(this.medicalInspectionCertificate, nameAdditionalFields.medical_date);
        }
      }
    }
    this.getAdditional();
    this.closeDopInformation();
  }

  private saveAdditionalInformation(certificate: AdditionalFields, additionalFieldsName: string) {
    this.additionalFieldService.addadditionalfields(certificate, this.studentId)
    .subscribe({
      next:() => {
        this.notificationService.showSuccess("Дополнительная информация: " + additionalFieldsName + " была успешно сохранена!");
      },
      error:() => {
        this.notificationService.showError("Не удалось сохранить дополнительную информацию");
      }}
    );
  }

  private updateAdditionalInformation(certificate: any, additionalFieldsName: string) {
    this.additionalFieldService.updateadditionalfields(certificate, this.studentId)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Дополнительная информация  " + additionalFieldsName + " была успешно сохранена!");
        },
        error:() => {
          this.notificationService.showError('Не удалось сохранить дополнительную информацию');
        }}
      )
  }

  public closeDopInformation() {
    this.editDateDopInformation = false;
    this.getAdditional();
  }

  //Прочие документы
  public otherDocuments: OtherDocuments[] = [];
  public loadingOtherDocuments = false;
  public editOtherDocuments: boolean = false;
  public isNewDocument: boolean = false;
  public otherDocumentTypes: DictOtherDocumentType[] = [];
  public otherDocs: OtherDocuments = {
     otherDocumentId: "",
     dictOtherDocumentTypeId: "",
     serial: "",
     number: "",
     issueOrganization: "",
     issueDate: new Date(),
     typeName: ""
  };


  public formOtherDocuments: UntypedFormGroup = new UntypedFormGroup({
    dictOtherDocumentTypeId: new UntypedFormControl(),
    serial: new UntypedFormControl(),
    number: new UntypedFormControl(),
    issueOrganization: new UntypedFormControl(),
    issueDate: new UntypedFormControl(),
  });

  public getOtherDocument() {
    this.otherDocumentService.getOtherDocuments(this.studPersonId)
      .subscribe(response => {
        this.otherDocuments = response;
        this.loadingOtherDocuments = false;
      })
  }

  public addHandlerOtherDocuments() {
    this.editOtherDocuments = true;
    this.isNewDocument = true;
    this.formOtherDocuments.reset();
  }

  public removeOtherDocuments({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.typeName, 450, 200, 250);

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.otherDocumentService.deleteOtherDocuments(dataItem.otherDocumentId, this.studentId)
            .subscribe({
              next:() => {
                this.getOtherDocument();
                this.notificationService.showSuccess("Удалено");
              }});
        }
        else{
           this.opened = false;
        }
      }
    });
  }

  public transitionHandlerOtherDocuments({dataItem}: EditEvent): void {
    this.otherDocs = dataItem;
    this.isNewDocument = false;
    this.editOtherDocuments = true;
    this.otherDocs.issueDate = new Date(dataItem.issueDate);
    this.formOtherDocuments.reset(this.otherDocs);
  }

  public onSaveOtherDocuments(e: PointerEvent): void {
    e.preventDefault();
    this.formOtherDocuments.value.issueDate = TimeZoneFix(this.formOtherDocuments.value.issueDate);
    this.formOtherDocuments.value.studPersonId = this.studentMainInfoComponent.contingent.studPersons.studPersonExternalId;

    if (this.isNewDocument) {
      this.otherDocumentService.addOtherDocuments(this.formOtherDocuments?.value, this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Документ был успешно добавлен!");
            this.getOtherDocument();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить документ");
          }}
        );
    } else {
      this.otherDocumentService.updateOtherDocuments(this.formOtherDocuments?.value, this.otherDocs.otherDocumentId.toString(), this.studentId)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Документ был успешно cохранен!");
            this.getOtherDocument();
          },
          error:() => {
            this.notificationService.showError("Не удалось изменить документ");
          }}
        );
    }

    this.closeOtherDocuments();
  }

  public closeOtherDocuments() {
    this.editOtherDocuments = false;
    this.formOtherDocuments.reset();
  }

  public getOtherDocumentTypes() {
    this.dictOtherDocumentsService.getAllOtherDocument()
      .subscribe(
        response => {
          this.otherDocumentTypes = response;
        }
      );
  }

  public nameDocument(id: string) {
    return this.otherDocumentTypes.find(el => el.externalId == id)?.typeName;
  }

}

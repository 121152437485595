import {ChangeDetectionStrategy, Component, OnInit, ViewChild} from '@angular/core';
import {
  AddEvent,
  CellClickEvent,
  GridComponent, GridDataResult, PageChangeEvent,
  PagerSettings,
  RemoveEvent
} from "@progress/kendo-angular-grid";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {DialogService} from "@progress/kendo-angular-dialog";
import {openRemoveDialog} from "../../../helpers/dialogHelper";
import {State, process, CompositeFilterDescriptor, filterBy} from "@progress/kendo-data-query";
import {pagerSettings} from 'src/app/models/mfc/pagerSettings.model';
import {LocalSignatory} from "../../../models/mfc/localSignatory/localSignatory.model";
import {LocalSignatoryService} from "../../../services/mfc/localSignatory.service";

@Component({
  selector: 'mfc-local-signatory',
  templateUrl: './localSignatory.component.html',
  styleUrls: ['./localSignatory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalSignatoryComponent implements OnInit {

  @ViewChild('grid') public grid?: GridComponent;

  public pagerSettings: PagerSettings = pagerSettings;
  public skip = 0;
  public pageSize = 10;
  public state: State = {
    skip: 0,
    take: this.pageSize,
  };

  public gridData!: GridDataResult;
  public localSignatories: LocalSignatory[] = [];
  public formGroup = new FormGroup({});

  // TODO: Модели справочников
  public dictTrainingLevels: {name: string}[] = [];
  public dictFilials: {name: string}[] = [];
  public dictDepartments: {name: string}[] = [];
  public dictApplicationTypes: {name: string}[] = [];
  public dictCategories: {name: string}[] = [];
  public dictRoles: {name: string}[] = [];
  public dictStudyForms: {name: string}[] = [];
  public dictDocuments: {name: string}[] = [];

  public editMode = false;
  public loading = false;
  public editable = false;
  public lastRowIndex?: number = undefined;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  constructor(
    private localSignatoryService: LocalSignatoryService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    // Моковые штуки
    for (let i = 1; i <= 10; i++) {
      this.dictFilials.push({name: `Филиал ${i}`});
      this.dictTrainingLevels.push({name: `Уровень подготовки ${i}`});
      this.dictDepartments.push({name: `Факультет ${i}`});
      this.dictRoles.push({name: `Роль ${i}`});
      this.dictApplicationTypes.push({name: `Тип ${i}`});
      this.dictCategories.push({name: `Категория ${i}`});
      this.dictDocuments.push({name: `Документ ${i}`});
    }
    this.getLocalSignatoryData();
  }

  public pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.state = {
      skip: event.skip,
      take: event.take
    }
    this.gridData = process(this.localSignatories, this.state);
  }

  private getLocalSignatoryData() {
    this.localSignatories = this.localSignatoryService.getLocalSignatoryData();
    this.gridData = process(this.localSignatories, this.state);
  }

  public addRow({sender, rowIndex}: AddEvent) {
    this.lastRowIndex = rowIndex;
    this.editMode = true;
    this.formGroup = this.createFormGroup({dataItem: new LocalSignatory()});
    sender.addRow(this.formGroup);
  }

  public editRow({sender, rowIndex, dataItem}: CellClickEvent) {
    if (this.editMode) {
      return;
    }
    this.editMode = true;
    this.lastRowIndex = rowIndex;
    this.formGroup = this.createFormGroup({dataItem: dataItem});
    sender.editRow(rowIndex, this.formGroup);
  }

  private createFormGroup(options: {dataItem: LocalSignatory}) {
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(options.dataItem).forEach((key) => {
      arr[key] = new FormControl({value: options.dataItem[key as keyof typeof options.dataItem], disabled: false});
    });
    return new FormGroup(arr);
  }

  public onCancel() {
    this.grid?.closeRow(this.lastRowIndex);
    this.editMode = false;
    this.formGroup = new FormGroup({});
    this.lastRowIndex = undefined;
  }

  public onRemove({dataItem}: RemoveEvent) {
    const item: LocalSignatory = dataItem;
    openRemoveDialog(this.dialogService, `${item.categories} из настроек доступа`);
    // Удаляем после подтверждения
  }

  public filterChange(filter: CompositeFilterDescriptor) {
    // TODO: Текущие фильтры не работают
    // if (!filter.filters.length) {
    //   this.gridData = process(this.localSignatories, this.state);
    //   return;
    // }
    // let data = this.localSignatories;
    //
    // filter.filters.forEach((a) => {
    //   const item = a as FilterDescriptor;
    //   data = data.filter(b => (b[item.field as keyof typeof b] as string[]).includes(item.value));
    // });
    // this.gridData = process(data, this.state);
  }

  public onSave() {
    // Сохранение
    this.onCancel();
  }
}

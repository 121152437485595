<gia-settings></gia-settings>
<h1>Добавить обучающихся</h1>

<app-order-student-search
  [releaseOrderId] = orderId
  (save)="studentSearch($event)"
>
</app-order-student-search>

<div class="right-buttons">
  <div>
  </div>
  <button kendoButton size="small" (click)="cancel()">Отмена</button>
  <button kendoButton class="save-button" size="small" themeColor="primary" (click)="save()">
    Сохранить
  </button>
</div>

<h1 class="f-s17">Список найденных обучающихся {{students.length}}</h1>

<kendo-grid
  class="m-t15"
  (cellClick)="cellClickHandler($event)"
  [selectable]="{checkboxOnly: true}"
  kendoGridSelectBy="studentId"
  [kendoGridBinding]="students"
  [pageable]="pagerSettings"
  [pageSize]="pageSize"
  [loading]="loading"
  [sortable]="true"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="studentId"
                     [width]="60"
                     [sortable]="false"
                     [resizable]="false"
                     [filterable]="false"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridHeaderTemplate>
      <div headerClass="gridHeader">
        <div class="m-t10">
          <input
            type="checkbox"
            kendoCheckBox
            [disabled]="students.length === 0"
            [checked]="allItemsChecked"
            (click)="selectAll()"
          />
        </div>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                       [checked]="checked(dataItem.studentId)"
                       (change)="checkedChange(dataItem.studentId)"
                />
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="studentFio" title="ФИО обучающегося" [width]="200" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">{{ dataItem.studentFio }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="trainingLevelEnum !== TrainingLevelEnum.Graduate && trainingLevelEnum !== TrainingLevelEnum.Residency" field="groupName" title="Академическая группа" [width]="100" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">{{ dataItem.groupName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="programName" title="Специальность" [width]="100" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">{{ dataItem.programName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="diplomaType" title="Тип диплома" [width]="100" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.diplomaType }}</span>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<div class="right-buttons">
  <button kendoButton size="small" (click)="cancel()">Отмена</button>
  <button kendoButton class="save-button" size="small" themeColor="primary" (click)="save()">
    Сохранить
  </button>
</div>

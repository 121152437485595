<publications-settings></publications-settings>
<div class="container">
    <div class="title">
        <h1>Авторы</h1>
        <kendo-tabstrip (tabSelect)="replaceTableData($event)" class="custom-tabs">
            <kendo-tabstrip-tab title="Внешние" [selected]="currentTab ? currentTab === 'Внешние': true"></kendo-tabstrip-tab>
            <kendo-tabstrip-tab *ngIf="editable" title="Сотрудники" [selected]="currentTab === 'Сотрудники'"></kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
    <div *ngIf="!isEmployee" class="searchByName">
        <h2>Поиск по ФИО</h2>
        <kendo-textbox (valueChange)="resetGrid($event)" [(ngModel)]="searchInput" [clearButton]="true"></kendo-textbox>
        <button kendoButton (click)="searchFIO(searchInput)" themeColor="primary">Поиск</button>
    </div>
    <button *ngIf="!isEmployee && editable" (click)="addAuthor()" class="addButton" kendoButton icon="plus" [primary]="true">Добавить автора</button>
    <kendo-grid
            *ngIf="isEmployee"
            [kendoGridBinding]="gridData"
            [pageable]="pagerSettings"
            (remove)="onRemove($event)"
            [filterable]="isEmployee"
            [pageSize]="10"
            [loading]="loading"
            (cellClick)="onCellClick($event)">
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <kendo-grid-column [filterable]="false" field="number" [width]="100" headerClass="gridHeader" title="№">
            <ng-template kendoGridCellTemplate let-rowIndex='rowIndex'>
              <span class="alignCells">
                {{rowIndex + 1}}
              </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="fio" headerClass="gridHeader" title="ФИО">
            <ng-template kendoGridFilterCellTemplate let-filter>
                <filter-combobox [data]="employeeAuthors" [filter]="filter" textField="fio"
                                 valueField="fio">
                </filter-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.fio}}
              </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="230" field="birthday" headerClass="gridHeader" title="Дата рождения">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <kendo-grid-date-filter-cell
                        [column]="column"
                        [filter]="filter">
                </kendo-grid-date-filter-cell>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{DateFromUTCAsLocal(dataItem?.birthday) | date: 'dd.MM.yyyy'}}
              </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="post" headerClass="gridHeader" title="Должность">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <filter-combobox [filter]="filter"
                                 [data]="posts"
                                 gridField="post"
                                 textField="name"
                                 valueField="name">
                </filter-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.post}}
              </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="structureSubdivision" headerClass="gridHeader" title="Структурное подразделение">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <filter-combobox [filter]="filter"
                                 [data]="subdivisions"
                                 gridField="department"
                                 textField="name"
                                 valueField="name">
                </filter-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.department}}
              </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>

    <kendo-grid
            *ngIf="!isEmployee"
            [kendoGridBinding]="gridData"
            [pageable]="pagerSettings"
            (remove)="onRemove($event)"
            [filterable]="isEmployee"
            [pageSize]="10"
            [loading]="loading"
            (cellClick)="onCellClick($event)">
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <kendo-grid-column [filterable]="false" field="number" [width]="100" headerClass="gridHeader" title="№">
            <ng-template kendoGridCellTemplate let-rowIndex='rowIndex'>
              <span class="alignCells">
                {{rowIndex + 1}}
              </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="fio" headerClass="leftAlign" title="ФИО">
            <ng-template kendoGridFilterCellTemplate let-filter>
                <filter-combobox [data]="employeeAuthors" [filter]="filter" textField="fio"
                                 valueField="fio">
                </filter-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells leftAlign">
                {{dataItem.fio}}
              </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column *ngIf="editable" headerClass="gridHeader" [width]="100">
            <ng-template kendoGridCellTemplate>
                <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="large" class="custom-size"
                        rounded="full">
                </button>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

<div kendoDialogContainer></div>

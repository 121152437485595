import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {LKPerson} from "../../models/person/lkPerson.model";
import { catchError, Observable, throwError } from 'rxjs';
import {LKPersonPayslipInfo} from "../../models/payslip/payslip.model";
import {environment} from "../../../environments/environment";
import {TokenStorageService} from "../token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {createLogErrorHandler} from "@angular/compiler-cli/ngcc/src/execution/tasks/completion";
import {createGetSourceFile} from "@angular/compiler-cli/linker/src/file_linker/get_source_file";
import {currentToken} from "../../../options/settings";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class PayslipService {
  baseUrl: string = `${environment.lkPersonApiEndpoint}${environment.apiPaths.lkPersonPayslip}`;

  constructor(private http: HttpClient,
              private jwtHelper: JwtHelperService) { }

  private getTokenFromCurrentToken(): string | null {
    const tokenJSON = localStorage.getItem(currentToken);

    if (tokenJSON === null) {
      return null;
    }

    return JSON.parse(tokenJSON).accessToken;
  }

  public getPayslipsNames(): Observable<string[]>{
    const tokenViewPerson = this.getTokenFromCurrentToken(); // берем токен из currentToken, чтобы админам, смотрящим от другого лица отображать их же расчетные листы
    return this.http.get<string[]>(`${this.baseUrl}/GetLkPersonPayslipInfo?tokenViewPerson=${tokenViewPerson}`)
      .pipe(
        catchError(this.handleError));
  }

  public downloadPayslipFile(nameFile: string): Observable<Blob> {
    const tokenViewPerson = this.getTokenFromCurrentToken(); // берем токен из currentToken, чтобы админам, смотрящим от другого лица запретить скачивание чужих расченых листов
    return this.http.get(`${this.baseUrl}/GetPayslipFile?namePayslipFile=${nameFile}&tokenViewPerson=${tokenViewPerson}`, { responseType: 'blob' })
      .pipe(
        catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {DepartmentService} from "../../../services/disciplineworkload/externals/department.service";
import {EducationPlanService} from "../../../services/disciplineworkload/externals/education-plan.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CancelEvent, EditEvent, RemoveEvent, SaveEvent} from "@progress/kendo-angular-grid";
import {PersonService} from "../../../services/disciplineworkload/externals/person.service";
import {lastValueFrom} from "rxjs";
import {DictPost, DictSalaryType, Person, PPSGet} from "../../../models/disciplineworkload/externals.model";
import {DictService} from "../../../services/disciplineworkload/externals/dict.service";
import {DropDownFilterSettings, VirtualizationSettings} from "@progress/kendo-angular-dropdowns";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {getData$} from "../../../../environments/environment";
import {
  DisciplineWorkloadUserAccessService
} from '../../../services/useraccess/disciplineworkload-user-access.service';
import { PersonSalaryService } from '../../../services/disciplineworkload/externals/person-salary.service';

@Component({
  selector: 'dict-teachers',
  templateUrl: './dict-teachers.component.html',
  styleUrls: ['./dict-teachers.component.scss']
})
export class DictTeachersComponent implements OnInit {

  public allowEdit: boolean = false;

  constructor(private departmentService: DepartmentService,
              private educationPlanService: EducationPlanService,
              private personService: PersonService,
              private personSalaryService: PersonSalaryService,
              private dictService: DictService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: DisciplineWorkloadUserAccessService) { }

  // Dropdown elements
  public kafedras: any[] = [];
  public studyYears: any[] = [];

  // Selected items
  public kafedrasEdit: any;
  public studyYearsEdit: any;

  // Data
  public persons: Person[] = [];
  public gridData: PPSGet[] = [];
  public dictPosts: DictPost[] = [];
  public dictSalaryTypes: DictSalaryType[] = [];
  public filteredSalaryTypes: DictSalaryType[] = [];
  public getDictSalaryTypes() {
    this.filteredSalaryTypes = this.dictSalaryTypes.filter((_: DictSalaryType) =>
      this.existingPerson && (_.name.includes('Договор') || _.name.includes('совместительство')) ||
      !this.existingPerson && _.name.includes('Почас'));
  }

  public isNew: boolean = false;
  public isForm: boolean = false;
  public existingPerson: boolean = false;

  public editForm: FormGroup = new FormGroup<any>({});

  public options: any[] = [
    {name: 'Да', value: true},
    {name: 'Нет', value: false}
  ]

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public filterVirtualization: boolean | VirtualizationSettings = {
    itemHeight: 28
  };

  private async getDepartments() {
    await lastValueFrom(this.departmentService.getKafedras()).then(
      value => {
        this.kafedras = value;
        this.kafedrasEdit = value[0];
      })

    this.allowEdit = await this.userAccessService.hasEditingRightsInPps({
      cathedraId: this.kafedrasEdit.id
    });
  }

  private async getStudyYears() {
    await lastValueFrom(this.educationPlanService.getAvailableSemesters()).then(
      (response) => {
        response!.forEach((item) => {
          item.name = `${item.year} / ${item.year + 1}`;
        })
        this.studyYears = response.filter((value, index, self) =>
            index === self.findIndex((t) => t.name === value.name));
        this.studyYearsEdit = this.studyYears[0];
      })
  }

  private getPersons() {
    this.personService.getPPSPersons({
      kafedraId: this.kafedrasEdit.id,
      studyYear: this.studyYearsEdit.year,
      withLoad: true
    }).subscribe({
      next: value => {
        this.gridData = value;
      }
    })
  }

  private getPosts() {
    this.dictService.getPosts().subscribe(value => this.dictPosts = value);
  }

  private getSalaryTypes() {
    this.dictService.getSalaryTypes().subscribe({
      next: value => {
        this.dictSalaryTypes = value;
      }
    });
  }

  public addHandler(existingPerson: boolean = false) {
    if (!this.allowEdit) return;

    this.isForm = true;
    this.isNew = true;
    this.existingPerson = existingPerson;

    this.editForm = new FormGroup({
      id: this.existingPerson
        ? new FormControl('', Validators.required)
        : new FormControl(''),
      isManualAddition: new FormControl(!this.existingPerson),
      studyYear: new FormControl(this.studyYearsEdit.year),
      firstName: this.existingPerson
        ? new FormControl('')
        : new FormControl('', Validators.required),
      lastName: this.existingPerson
        ? new FormControl('')
        : new FormControl('', Validators.required),
      middleName: new FormControl(''),
      salary: new FormGroup({
        id: new FormControl(''),
        isManualAddition: new FormControl(true),
        departmentId: new FormControl(this.kafedrasEdit?.id, Validators.required),
        postId: this.existingPerson
          ? new FormControl('', Validators.required)
          : new FormControl(''),
        salaryTypeId: new FormControl('', Validators.required),
        salary: new FormControl()
      })
    });

    this.getDictSalaryTypes();
  }

  public onCancel({ sender, rowIndex }: CancelEvent): void  {
    sender.closeRow(rowIndex);
    this.closeEditing();
  }

  public onSave(): void {
    if (!this.allowEdit) return;

    if(!this.isNew) {
      if (this.existingPerson) {
        this.personSalaryService.update({
          id: this.editForm.value.salary.id,
          departmentId: this.editForm.value.salary.departmentId,
          personId: this.editForm.value.id,
          salaryTypeId: this.editForm.value.salary.salaryTypeId,
          postId: this.editForm.value.salary.postId != '' ? this.editForm.value.salary.postId : null,
          salary: this.editForm.value.salary.salary != '' ? this.editForm.value.salary.salary : null
        }).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            this.getPersons();
            this.closeEditing();
          },
          error: err => {this.notificationService.showError(err)}
        })
      }
      else {
        this.personService.updatePerson(this.editForm.value).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            this.getPersons();
            this.closeEditing();
          },
          error: err => {
            this.notificationService.showError(err)
          }
        })
      }
    }
    else {
      if (this.existingPerson) {
        this.personSalaryService.add({
          departmentId: this.editForm.value.salary.departmentId,
          personId: this.editForm.value.id,
          salaryTypeId: this.editForm.value.salary.salaryTypeId,
          postId: this.editForm.value.salary.postId != '' ? this.editForm.value.salary.postId : null,
          salary: this.editForm.value.salary.salary != '' ? this.editForm.value.salary.salary : null
        }).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            this.getPersons();
            this.closeEditing();
          },
          error: err => {this.notificationService.showError(err)}
        })
      }
      else {
        this.personService.addPerson(this.editForm.value).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            this.getPersons();
            this.closeEditing();
          },
          error: err => {this.notificationService.showError(err)}
        })
      }
    }
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    if (!dataItem || !this.allowEdit) return;

    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.fio} из таблицы`);
    dialogResultHelper(dialog.result,
      dataItem.salaryIsManualAddition
        ? this.personSalaryService.delete(dataItem.salaryId)
        : this.personService.delete(dataItem.id)
      , this.notificationService);
  }

  public closeEditing() {
    this.isNew = false;
    this.isForm = false;
    this.editForm.reset();
  }

  public editHandler(dataItem: PPSGet): void {
    if (!this.allowEdit) return;

    this.isNew = false;
    this.isForm = true;
    this.existingPerson = !dataItem.isManualAddition;

    this.editForm = new FormGroup({
      id: new FormControl(dataItem.id, Validators.required),
      isManualAddition: new FormControl(!this.existingPerson),
      studyYear: new FormControl(this.studyYearsEdit.year, Validators.required),
      firstName: this.existingPerson
        ? new FormControl(dataItem.firstName)
        : new FormControl(dataItem.firstName, Validators.required),
      lastName: this.existingPerson
        ? new FormControl(dataItem.lastName)
        : new FormControl(dataItem.lastName, Validators.required),
      middleName: new FormControl(dataItem.middleName),
      salary: new FormGroup({
        id: new FormControl(dataItem.salaryId, Validators.required),
        isManualAddition: new FormControl(dataItem.salaryIsManualAddition),
        departmentId: new FormControl(this.kafedrasEdit?.id, Validators.required),
        postId: this.existingPerson
          ? new FormControl(this.dictPosts.find((item) => item.name === dataItem.postName)?.id, Validators.required)
          : new FormControl(this.dictPosts.find((item) => item.name === dataItem.postName)?.id),
        salaryTypeId: new FormControl(this.dictSalaryTypes.find((item) => item.name === dataItem.salaryType)?.id , Validators.required),
        salary: new FormControl(dataItem.salary)
      })
    });

    this.getDictSalaryTypes();
  }

  public async onFilterChange() {
    if(this.kafedrasEdit) {
      this.allowEdit = await this.userAccessService.hasEditingRightsInPps({
        cathedraId: this.kafedrasEdit?.id
      });

      this.getPersons();
    }
  }

  async ngOnInit() {
    await this.getDepartments();
    await this.getStudyYears();
    getData$.subscribe(() => this.getPersons())
    this.getPosts();
    this.getSalaryTypes();

    this.personService.getAllPersons().subscribe(
      response => this.persons = response
    );
  }
}

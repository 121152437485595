import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { AddDiplomaTypeDto, DiplomaTypeDto } from "../../models/gia/dicts/dictDiplomaType.model";

@Injectable({
  providedIn: 'root'
})
export class DictDiplomaTypeService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.gia.dictdiploma}`;

  constructor(private http: HttpClient) {}

  getDiplomaTypes(): Observable<DiplomaTypeDto[]> {
    return this.http.get<DiplomaTypeDto[]>(`${this.baseUrl}/GetAllDiplomaType/`);
  }

  addDiplomaType(dict: AddDiplomaTypeDto): Observable<AddDiplomaTypeDto> {
    return this.http.post<AddDiplomaTypeDto>(`${this.baseUrl}/DiplomaType/`, dict);
  }

  editDiplomaType(dict: DiplomaTypeDto): Observable<DiplomaTypeDto> {
    return this.http.put<DiplomaTypeDto>(`${this.baseUrl}/DiplomaType/`, dict);
  }

  removeDiplomaType(id: string): Observable<DiplomaTypeDto> {
    return this.http.delete<DiplomaTypeDto>(`${`${this.baseUrl}/DiplomaType`}/${id}`);
  }
}

import {Component, OnInit, Renderer2} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AddEvent, CellClickEvent, GridComponent, RemoveEvent} from '@progress/kendo-angular-grid';
import {SortDescriptor} from '@progress/kendo-data-query';
import {Faculty} from 'src/app/models/education/faculty.model';
import {Kafedra} from 'src/app/models/education/kafedra.model';
import {
  Competence,
  CompetenceList,
  CompetencePayload,
  TypeCompetenceList
} from 'src/app/models/education/oopCompetences.model';
import {Qualification} from 'src/app/models/education/qualification.model';
import {Standard} from 'src/app/models/education/standard.model';
import {StandardType} from 'src/app/models/education/standardtype.model';
import {StudyLevel} from 'src/app/models/education/studylevel.model';
import {TrainingLevel} from 'src/app/models/education/traininglevel.model';
import {TrainingLevelService} from 'src/app/services/education/TrainingLevel/traininglevel.service';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {
  EducationProgramCompetenciesService
} from 'src/app/services/education/OOP/Competencies/education-program-competencies.service';
import {OopService} from 'src/app/services/education/OOP/oop.service';
import {QualificationService} from 'src/app/services/education/Qualification/qualification.service';
import {StandardtypeService} from 'src/app/services/education/StandardType/standardtype.service';
import {EducationUserAccessService} from '../../../services/useraccess/education-user-access.service';
import {typeCompetenceSort} from '../../../services/education/type-competence-sort';

@Component({
  selector: 'app-education-program-competencies',
  templateUrl: './education-program-competencies.component.html',
  styleUrls: ['./education-program-competencies.component.scss']
})
export class EducationProgramCompetenciesComponent implements OnInit {

  public selectedCompetence: any = {competenceId: 0, externalId: "", educationProgramId: "", code: "", description: "", typeCompetenceId: ""};
  public loading = false;
  public copyOOP = false;
  public addForm = false;
  public editable?: boolean;
  private readonly destinationEducationProgramId = "";

  public lastArgsSender: any;
  public lastRowIndex: any;
  public lastData: any;

  public formGroup: FormGroup | undefined;

  public editForm: FormGroup = new FormGroup({
    code: new FormControl(),
    description: new FormControl(),
    typeCompetenceId: new FormControl(),
  });

  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public isEdit = false;
  public isAdd = false;
  public opened = false;

  constructor(private competenceService: EducationProgramCompetenciesService,
              private renderer: Renderer2,
              private router: Router,
              private notificationService: NotificationsService,
              private route: ActivatedRoute,
              private oopService: OopService,
              private standardTypeService: StandardtypeService,
              private qualificationService: QualificationService,
              private trainingLevelService: TrainingLevelService,
              private userAccessService:EducationUserAccessService) {
      this.destinationEducationProgramId = route.snapshot.params["id"];
     }

  public studylevels!: StudyLevel[];
  public trainingLevels!: TrainingLevel[];
  public faculties!: Faculty[];
  public kafedras!: Kafedra[];
  public qualifications!: Qualification[];
  public standardTypes!: StandardType [];
  public standards: Standard[] = [];
  public competenciesList!: CompetenceList[];

  public typeCompetencies!: TypeCompetenceList[];
  public competence: Competence = new Competence();
  public payload: CompetencePayload = new CompetencePayload();

  public getAllCompetenceByProgramId(programId: string) {
    this.loading = true;
    this.competenceService.getAllCompetenceByProgramId(programId)
    .subscribe({
      next: (response) => {
        this.payload = response;
        this.competence = response.payload;
        this.competenciesList = response.payload.competencies;

        this.typeCompetencies = typeCompetenceSort(response.payload.typeCompetences, 'competenceId');

        for(let i=0; i < this.typeCompetencies.length; i++) {
          this.typeCompetencies[i].competence.sort((a, b) => {
                return a.code.localeCompare(b.code, 'en', {numeric: true});
              }
          );
        }
        this.loading = false;
      },
      error: () => {
        this.notificationService.showError("Отсутствует подключение к серверу");
        this.loading = false;
      }
    })
  }

  public saveRow(): void {
      if (this.formGroup !== undefined) {
        // Edit handler
        if(this.isEdit) {
          this.competenceService.updateCompetence(this.formGroup.value, this.destinationEducationProgramId, this.formGroup.value.externalId)
              .subscribe({
                next: (response) => {
                  this.getAllCompetenceByProgramId(this.destinationEducationProgramId);
                  this.isEdit = false;
                  this.notificationService.showSuccess("Компетенция успешно изменена!");
                },
                error: () => {
                  this.isEdit = false;
                  this.notificationService.showError("Не удалось изменить компетенцию");
                }
              })
        }
        // Add handler
        if(this.isAdd) {
          this.competenceService.addCompetence(this.formGroup.value, this.destinationEducationProgramId)
              .subscribe({
                next: () => {
                  this.notificationService.showSuccess("Компетенция успешно добавлена!");
                  this.isAdd = false;
                  this.getAllCompetenceByProgramId(this.destinationEducationProgramId);
                },
                error: () => {
                  this.notificationService.showError("Не удалось добавить компетенцию");
                  this.isAdd = false;
                }
              });
        }
      }
  }

  public onStateChange() {
    this.getAllCompetenceByProgramId(this.destinationEducationProgramId);
    this.copyOOP = false;
  }

  //Start adding
  public addHandler({ sender, rowIndex }: AddEvent, competenceTypeExtId: string): void {
    if(!this.editable) return
    if(!rowIndex) rowIndex = 0;
    this.isAdd = true;
    this.closeEditor(sender);
    let data: any = sender.data;
    this.lastData = data;
    let typeCompetenceId: string = data.length > 0 ? data[0].typeCompetenceId : competenceTypeExtId;;

    this.formGroup = formGroup({
      code: '',
      description: '',
      typeCompetenceId: typeCompetenceId
    });

    this.lastArgsSender = sender;
    this.lastRowIndex = rowIndex;
    sender.addRow(this.formGroup);
  }

  public blockSaveButtons(competences: any) {
    return this.lastData !== competences;
  }

  public oopReturn() {
    this.router.navigate(['education/oop'])
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    this.opened = false;
  }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getAllCompetenceByProgramId(this.destinationEducationProgramId);
    this.getStandardTypes();
    this.getAllQualifications();
    this.getAllTrainingLevels();
  }

  public deleteCompetence() {
    this.opened = false;
    this.competenceService.deleteCompetence(this.selectedCompetence.educationProgramId, this.selectedCompetence.externalId)
      .subscribe({
        next: () => {
          this.notificationService.showSuccess("Компетенция успешно удалена!");
          this.getAllCompetenceByProgramId(this.destinationEducationProgramId);
        },
        error: (error) => {
          this.notificationService.showError(error.error.Id[0]);
        }
      });
  }

  //Cancel
  public cancelHandler() {
    this.editForm = new FormGroup({
      code: new FormControl(''),
      description: new FormControl(''),
      typeCompetenceId: new FormControl(''),
    });
    this.isEdit = false;
    this.isAdd = false;
    this.closeEditor(this.lastArgsSender, this.editedRowIndex);
  }

  public getStandardTypes() {
    this.standardTypeService.getAllStandardTypes()
    .subscribe(
      response => {
        this.standardTypes = response;
      }
    )
  }

  //Start saving
  public saveCurrent() {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
    this.lastArgsSender.closeRow(this.lastRowIndex);
  }

  public getSelectedValues(args: CellClickEvent): void{
    if (args.isEdited || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    if(!this.editable) return

    this.isEdit = true;
    const { dataItem } = args;

    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    // Close previous opened row
    if (this.lastRowIndex != null) {
      if(this.lastArgsSender) {
        this.lastArgsSender.closeRow(this.lastRowIndex);
      }
      else {
        args.sender.closeRow(this.lastRowIndex);
      }
    }

    this.lastRowIndex = args.rowIndex;
    this.lastArgsSender = args.sender;

    this.formGroup = new FormGroup({
      code: new FormControl(dataItem.code),
      description: new FormControl(dataItem.description, Validators.required),
      typeCompetenceId: new FormControl(dataItem.typeCompetenceId),
      externalId: new FormControl(dataItem.externalId),
    });
    this.editedRowIndex = args.rowIndex;
    // put the row in edit mode, with the `FormGroup` build above
    args.sender.editRow(args.rowIndex, this.formGroup);
  }

  // Getting all entries from Qualifications
  public getAllQualifications() {
    this.qualificationService.getAllQualifications()
      .subscribe(
        response => {
          this.qualifications = response;
        }
      )
  }

  //Getting all entries from training levels
  public getAllTrainingLevels() {
    this.trainingLevelService.getAllTrainingLevels()
      .subscribe(
        response => {
          this.trainingLevels = response;
        }
      );
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = !response.dictAccessLevel;
    },error => {});
  }

  // Сортировка
  public sort: SortDescriptor[] = [
    {
      field: "standard.trainingProgram",
      dir: "asc",
    },
  ];

  //Getting all entries from OOP

  //Start Editing
  public editHandler({sender, columnIndex, rowIndex, dataItem}: CellClickEvent): void {
    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }


  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public close(): void {
    this.opened = false;
  }

  public open(values: any): void {
    if(!this.editable) return
    this.selectedCompetence = values;
    this.opened = true;
  }

  public showOOP() {
    this.copyOOP = !this.copyOOP;
  }
}

const formGroup = (dataItem: { code?: any; description?: any; typeCompetenceId?: any; }) =>
  new FormGroup({
    code: new FormControl(dataItem.code, Validators.required),
    description: new FormControl(dataItem.description, Validators.required),
    typeCompetenceId: new FormControl(dataItem.typeCompetenceId)
  });

<div class="p-l20"> 
  <div class="big-title">Список найденных студентов <span *ngIf="quantityStudents != 0">{{quantityStudents}}</span> </div>
  <kendo-grid
    [data]="gridView"
    [kendoGridSelectBy]="mySelectionKey"
    [(selectedKeys)]="selection"
    [selectable]="{enabled: true, checkboxOnly: true}"
    class="case-table">
    <kendo-grid-checkbox-column
      [showSelectAll]="true"
      [width]="50">
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="studentNumber" headerClass="gridHeader" title="Номер зачетной книжки" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.studentNumber }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="fio" headerClass="gridHeader" title="ФИО" [width]="400">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells text-left">
            {{ dataItem.fio }}
          </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="course" headerClass="gridHeader" title="Курс" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.course}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="group" headerClass="gridHeader" title="Группа" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.group}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="budget" headerClass="gridHeader" title="Источник финансирования" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.budget}}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <button kendoButton class="add-list shadow-none"
    icon="plus"
    (click)="addListStudent()">
    Добавить в список выбранных
  </button>
</div>
<gia-settings></gia-settings>

<h1>Состав ГЭК</h1>
<div class="selectContent">
  <dd class="filter">
    <span class="selectText">Филиал</span>
    <kendo-dropdownlist
      [data]="allFilial"
      class="w-400"
      textField="shortName"
      valueField="id"
      [valuePrimitive]="true"
      name="filial"
      (valueChange)="onValueChange($event)"
      [(ngModel)]="filial"
    >
    </kendo-dropdownlist>
  </dd>

  <dd class="filter">
    <span class="selectText">Уровень подготовки</span>
    <kendo-dropdownlist
      [data]="trainingLevels"
      class="w-400"
      textField="name"
      valueField="id"
      [valuePrimitive]="true"
      name="trainingLevel"
      (valueChange)="onValueChange($event)"
      [(ngModel)]="trainingLevel"
    >
    </kendo-dropdownlist>
  </dd>

  <dd class="filter">
    <span class="selectText">Учебный год</span>
    <kendo-dropdownlist
      [data]="yearAdmissionList"
      class="w-400"
      textField="studyYearString"
      valueField="studyYear"
      [valuePrimitive]="true"
      name="studyYear"
      (valueChange)="onValueChange($event)"
      [(ngModel)]="yearAdmission"
    >
    </kendo-dropdownlist>
  </dd>
</div>

<div>
  <div class="info k-my-3">Для того, чтобы увидеть список приказов следует выбрать данные в полях “Филиал”, “Уровень подготовки” и “Учебный год”</div>

  <div class="buttons" [ngClass]="{ alignend: !editable }">
    <div *ngIf="editable">
      <button kendoButton themeColor="success" (click)="addStateExamCommission()" icon="plus">Новый приказ</button>
      <button kendoButton class="k-ml-5" (click)="openDialog()">Скопировать из прошлого года</button>
    </div>
  </div>

  <kendo-grid
    class="m-t15"
    #grid
    [kendoGridBinding]="stateExamCommissionList"
    (cellClick)="onRowClick($event)"
    [pageable]="true"
    [pageSize]="pageSize"
    [loading]="loading"
    [sortable]="true"
    [pageable]="pagerSettings"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column field="number" title="Номер приказа" [width]="100" headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ dataItem.number }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="date" format="dd.MM.yyyy" title="Дата приказа" headerClass="gridHeader" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ dataItem.date | date: "dd.MM.yyyy" }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="stateExamCommissionTypeName" title="Тип приказа" [width]="300" headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlign">{{ dataItem.stateExamCommissionTypeName }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="childNumber" title="Связанный приказ" [width]="100" headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ dataItem.childNumber }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" [width]="50">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          <span class="k-icon k-i-download pointer" (click)="downloadFile(dataItem.id, dataItem.number, dataItem.date, dataItem.stateExamCommissionTypeName)"></span>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<kendo-dialog title="Пожалуйста, выберите приказ" *ngIf="opened" (close)="closeDialog()" [minWidth]="200" [width]="450">
  <div class="filter">
    <span class="selectText k-mr-3">Приказ</span>
    <kendo-dropdownlist
      [data]="previousOrders"
      textField="numberAndDate"
      valueField="id"
      [valuePrimitive]="true"
      (valueChange)="onPreviousOrderValueChange($event)"
    >
    </kendo-dropdownlist>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Отмена</button>
    <button kendoButton (click)="copy()" themeColor="primary">Скопировать приказ</button>
  </kendo-dialog-actions>
</kendo-dialog>

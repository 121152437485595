import { Student } from "./student.model";
import {Guid} from "guid-typescript";

export class StudentSemestr {
  public eduStudSemestrId!: number;
  public studentId!: number;
  public student!: Student;
  public semestrBegin!: Date;
  public semestrEnd!: Date;
  public semestrNum!: number;
  public courseNum!:number;
  public sessionBegin!:Date;
  public sessionEnd!:Date;
  public dateBegin!:Date;
  public dateEnd!:Date;
  public semesterNum!: number;
}

export class StudentSemesterEditDto{
  public externalId!: Guid | string;
  public studentId!: Guid | string;
  public semestrBegin!: Date;
  public semestrEnd?: Date;
  public semestrNum!: number;
  public courseNum!: number;
}

export class SemesterFromPlanRequest{
  public planId?: Guid | string;
  public studentId!: Guid | string;
  public dto!: StudentSemesterEditDto[];
}

export class SemesterRequest{
  public studentId!: Guid | string;
  public dateStart?: Date;
  public dateFinish?: Date;
}

export class CurrentSemester {
  public semestrBegin?:Date;
  public semestrEnd?:Date;
  public isCurrent?:boolean;
  public semesterInYear?:number;
  public semesterYear?:number;
}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {DictHealth} from "../../models/contingent/dicthealth.model";
import {catchError} from "rxjs/operators";
import {DictOtherDocumentType} from "../../models/contingent/dictotherdocumenttype";

@Injectable({
  providedIn: 'root'
})
export class DictotherdocumentsService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.otherdocumenttype}`;

  constructor(private http: HttpClient) { }

  public getAllOtherDocument(): Observable<DictOtherDocumentType[]> {
    return this.http.get<DictOtherDocumentType[]>(this.baseUrl);
  }

  addOtherDocument(dto: DictOtherDocumentType): Observable<DictOtherDocumentType> {
    return this.http.post<DictOtherDocumentType>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  deleteOtherDocument(id: string): Observable<DictOtherDocumentType> {
    return this.http.delete<DictOtherDocumentType>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  updateOtherDocument(dto: DictOtherDocumentType): Observable<DictOtherDocumentType> {
    return this.http.put<DictOtherDocumentType>(this.baseUrl + '/' + dto.externalId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

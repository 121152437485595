import { Component, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { items } from "../../../models/announcement/menu-items";
import { DrawerItem, DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import { AnnouncementUserAccessService } from "../../../services/useraccess/announcement-user-access.service";

@Component({
  selector: 'announcement-header',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: []
})

export class AnnouncementLayoutComponent implements OnInit{
  public adminCheck = false;

  //Menu items
  public items: any[] = items;
  public isMainPage: boolean = this.router.url.endsWith('/announcement');

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private router: Router,
              private jwtHelper: JwtHelperService,
              private tokenStore: TokenStorageService,
              private userAccessService:AnnouncementUserAccessService)
    {
      const token = this.tokenStore.getAuthToken();
      if(token !== null)
      {
        const user = this.jwtHelper.decodeToken(token);
        const userRole = user.role;
        if(userRole == Role.Admin)
          this.adminCheck = true;
      }
    }

    ngOnInit(): void {
      this.accessSetting()
  }

  public accessSetting(){
    // Hide admin items
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null)
    {
      user = this.jwtHelper.decodeToken(token);
    }
    let role = user.role;
    //if(!role.includes(Role.Admin)) this.items[0].items = this.items[0].items.filter((a: { admin: boolean; }) => a.admin==null);
  }
}

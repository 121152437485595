import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MarksModel } from 'src/app/models/middlecontrol/marks.model';
import { DictsService } from 'src/app/services/middlecontrol/dicts.service';
import { SessionService } from 'src/app/services/middlecontrol/session.service';

@Component({
  selector: 'app-disciplinefooter',
  templateUrl: './disciplinefooter.component.html',
  styleUrls: ['./disciplinefooter.component.scss'],
})
export class DisciplinefooterComponent implements OnInit {
  gradeLevelList: MarksModel[] = [];
  marksStatistic: { [key: string]: number } = {};

  @Output() onComeback: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private dictService: DictsService,
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {
    this.dictService.marksByDiciplineType$.subscribe(
      (list) => (this.gradeLevelList = list)
    );

    this.sessionService.marksStatistic$.subscribe((marks) => {
      this.marksStatistic = marks.reduce((statistic, mark) => {
        const index = mark.id.toString();
        if (statistic[index]) statistic[index]++;
        else statistic[index] = 1;

        return statistic;
      }, {} as { [key: string]: number });
    });
  }

  public handleClose() {
    this.onComeback.emit();
  }
}

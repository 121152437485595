import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { StudentSearchQuery } from "../../../../models/gia/orders/student-search-query.model";
import { CellClickEvent, PagerSettings } from "@progress/kendo-angular-grid";
import { dictPagerSettings } from "../../../../models/dicts/pagerSettings.model";
import { AdmissionOrderService } from "../../../../services/gia/admission-order.service";
import { OrderInfo } from "../../../../models/gia/education/standard.model";
import { TrainingLevelEnum } from "../../../../models/gia/enums/training-level.enum";
import { AdmissionSearchStudents } from "../../../../models/gia/orders/admission-order.model";
import { NotificationsService } from "../../../../services/notifications/notifications.service";
import { GiaTabsEnum } from "../../../../models/gia/enums/giaTabs.enum";
import { GiaUserAccessService } from "../../../../services/useraccess/gia-user-access.service";

@Component({
  selector: 'app-admission-order-add-student',
  templateUrl: './admission-order-add-student.component.html',
  styleUrls: ['./admission-order-add-student.component.scss']
})
export class AdmissionOrderAddStudentComponent implements OnInit {

  public orderId: string = "";
  public pageSize = 20;
  public loading: boolean = false;
  public pagerSettings: PagerSettings = dictPagerSettings;
  public standardInfo?: OrderInfo;
  public students: AdmissionSearchStudents[] = [];
  public allItemsChecked: boolean = false;
  public arrayStudents: string[] = [];

  constructor(private activateRoute: ActivatedRoute,
              private router: Router,
              private admissionOrderService: AdmissionOrderService,
              private userAccessService: GiaUserAccessService,
              private notificationService: NotificationsService,) {
    this.orderId = activateRoute.snapshot.params["orderId"];
  }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getOrderStudents();
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        if (!response.giaSections?.some((_) => _ === GiaTabsEnum.приказы_о_допуске || _ === null) && !response.isAdmin) {
          alert('Недостаточно прав для доступа на эту страницу');
          this.router.navigate([`/profile`]).then( );
        }
      },
      error: () => {},
    });
  }

  public studentSearch(query: StudentSearchQuery): void {
    this.loading = true;
    this.admissionOrderService.studentSearch(query)
      .subscribe({
        next:(response) => {
          if (response == null)
            this.students = [];
          else
            this.students = response;
          this.loading = false;
        },
        error: () => {
          this.students = [];
          this.loading = false;
        }
      })
  }

  public cellClickHandler({ dataItem }: CellClickEvent): void {
    this.checkedChange(dataItem.studentId);
  }

  public save(){
    if (this.arrayStudents.length === 0) {
      this.notificationService.showError("Не выбран ни один обучающийся");
      return;
    }

    const order = {
      admissionOrderId: this.orderId,
      students: this.arrayStudents.map(function(v){
        return {studentId: v};
      })
    };

    this.admissionOrderService.addOrderStudents(order)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.cancel();
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }}
      );
  }

  public getOrderStudents() {
    this.admissionOrderService.getOrderInfo(this.orderId)
      .subscribe(response => {
        this.standardInfo = response;
      })
  }

  public cancel(){
    this.router.navigateByUrl(`/gia/admissionorderform/${this.orderId}`).then();
  }

  public selectAll() {
    this.allItemsChecked = !this.allItemsChecked;
    this.allItemsChecked ? this.arrayStudents = this.students.map(el => el.studentId) : this.arrayStudents = [];
  }

  public checked(studentId: string) {
    return this.arrayStudents.includes(studentId);
  }

  public checkedChange(studentId: string) {
    this.getArraysForMassChange(studentId, this.arrayStudents);
    if (this.arrayStudents.length < this.students.length) this.allItemsChecked = false;
    if (this.arrayStudents.length == this.students.length) this.allItemsChecked = true;
  }

  private getArraysForMassChange(studentId: string, array: string[]) {
    const index = array.indexOf(studentId);
    index == -1 ? array.push(studentId) : array.splice(index, 1);
  }

  protected readonly TrainingLevelEnum = TrainingLevelEnum;
}

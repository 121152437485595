import {DictDisciplineGet, EduGroup} from "./externals.model";

export class CathedralLoadGet {
  discipline!: DictDisciplineGet;
  disciplineConjugations: string[] | null = null;
  flows!: FlowGet[];
  groups!: GroupGet[];
}

export class FlowGet {
  id!: string;
  typeWork!: DictCathedralLoadTypeWorkGet;
  number!: number;
  typeWorks!: FlowTypeWorkGet[];
  groups!: GroupGet[];
}

export class DictCathedralLoadTypeWorkGet {
  id!: string;
  name!: string;
  shortName!: string;
  coefficient!: number;
}

export class FlowTypeWorkGet {
  id!: string;
  typeWork!: DictCathedralLoadTypeWorkGet;
  parameters!: FlowParameterGet;
  coefficient!: number;
  hours!: number;
  person!: PersonGetDto;
  completedHours?: number;
}

export class FlowTypeWorkUpdate {
  id!: string;
  parameters!: FlowParameterGet;
  personId?: string;
}

export class GroupTypeWorkGet {
  id!: string;
  typeWork!: DictCathedralLoadTypeWorkGet;
  parameters!: GroupParameterGet;
  coefficient!: number;
  hours!: number;
  person!: PersonGetDto;
  completedHours?: number;
}

export class GroupTypeWorkUpdate {
  id!: string;
  parameters!: GroupParameterGet;
  personId?: string;
  subGroups!: SubGroupUpdate[];
}

export class SubGroupUpdate {
  id?: string;
  hours?: number;
  personId?: string;
}

export class FlowParameterGet {
  id!: string;
  examType?: number;
}

export class GroupParameterGet {
  id!: string;
  subGroupCount?: number;
  examType?: number;
}

export class PersonGetDto {
  id!: string;
  fio!: string;
  login?: string;
  postName!: string;
  eduPostName!: string;
}

export class GroupGet {
  id!: string;
  eduGroup!: EduGroup;
  groupFlows?: string[];
  semesterNumber!: number;
  typeWork!: GroupTypeWorkGet[];
}

export class Faculty {
  id!: string;
  name!: string;
  shortName!: string;
}

export class FacultyFilter {
  year!: number;
  semester!: number;
  kafedraId!: string | null;
}

<oferta-settings></oferta-settings>
<h1>Присоединившиеся к положениям пользователи</h1>

<div class="grid-data">
  <kendo-grid
    [kendoGridBinding]="userSignaturesBrief"
    [loading]="loading"
    [pageable]="pagerSettings"
    [filterable]="true"
    [sortable]="true"
    [sort]="[{ field: 'fio', dir: 'asc' }]"
    [pageSize]="pageSize"
    [skip]="skip"
    (cellClick)="moveToInfo($event)"
    [rowClass]="rowClassCallback"
  >
    <kendo-grid-column field="userTypeEnum" title="Тип" [width]="250" headerClass="gridHeader">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ nameEnum(dataItem.userTypeEnum) }}
        </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter" [data]="userTypeEnums" gridField="userTypeEnum"
                         textField="name" valueField="value">
        </filter-dropdown>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="fio" title="ФИО" [width]="500" headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.fio}}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="departmentName" title="Подразделение" [width]="370" headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.departmentName}}
          </span>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column headerClass="gridHeader dictionaries" field="status" editor="text" title="Статус учетной записи" [width]="150" [filterable]="true" [class]="{ codeColumn: true }">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <filter-dropdown [filter]="filter" [data]="status" [componentName]="componentName" textField="name" valueField="status">
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="alignCells" [ngSwitch]="dataItem.status">
          <div *ngSwitchCase="StateEnum.Active">{{"Активен"}}</div>
          <div *ngSwitchCase="StateEnum.Blocked">{{"Заблокирован"}}</div>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

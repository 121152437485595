import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, lastValueFrom, Observable, skip, switchMap} from 'rxjs';
import { Guid } from 'guid-typescript';
import { environment } from '../../../environments/environment';
import { TreeCommonModel } from '../../models/currentcontrol/trees.model';
import {CurrentControlStudentTreeQuery, CurrentControlTreeQuery} from "../../models/currentcontrol/query.model";

@Injectable({
  providedIn: 'root',
})
export class TreesService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.currentcontrol.trees}`;

  public departmentTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  public studentTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  public disciplineTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  public trainingLevelTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  protected triggerTreeFetch$ = new BehaviorSubject<CurrentControlTreeQuery>(new CurrentControlTreeQuery);
  protected triggerStudentTreeFetch$ = new BehaviorSubject<CurrentControlStudentTreeQuery>(new CurrentControlStudentTreeQuery);
  protected triggerDisciplineTreeFetch$ = new BehaviorSubject<CurrentControlTreeQuery>(new CurrentControlTreeQuery);

  constructor(private http: HttpClient) {
    this.triggerTreeFetch$.pipe(
      skip(1),
      switchMap((path) => {
        return this.getDepartmentTree(path)
      }))
      .subscribe((response) => {
        this.departmentTree$.next(response);
      });

    this.triggerStudentTreeFetch$.pipe(
      skip(1),
      switchMap((request) => {
        return this.getStudentTree(request)
      }))
      .subscribe((response) => {
        this.studentTree$.next(response);
      });

    this.triggerDisciplineTreeFetch$.pipe(
      skip(1),
      switchMap(async (request) => {
        return await lastValueFrom(this.getDisciplineTree(request))
      }))
      .subscribe((response) => {
        this.disciplineTree$.next(response);
      });
  }

  public getDepartmentTree(request: CurrentControlTreeQuery): Observable<TreeCommonModel[]> {
    return this.http.post<TreeCommonModel[]>(
      this.baseUrl +
        `/GetDepartmentTree`, request
    );
  }

  public async updateDepartmentTree(request: CurrentControlTreeQuery) {
    this.triggerTreeFetch$.next(request);
  }

  public getStudentTree(request: CurrentControlStudentTreeQuery
  ): Observable<TreeCommonModel[]> {
    return this.http.get<TreeCommonModel[]>(
      this.baseUrl +
        `/GetStudentTree/${request.semesterInYear}/${request.studyYear}/${request.trainingLevel}`
    );
  }

  public async updateStudentTree(
    trainingLevel: (string | Guid)[] | undefined,
    semesterInYear: number,
    studyYear: number
  ) {
    this.triggerStudentTreeFetch$.next({trainingLevel, semesterInYear, studyYear});
  }

  public getDisciplineTree(request: CurrentControlTreeQuery): Observable<TreeCommonModel[]> {
    return this.http.post<TreeCommonModel[]>(
      this.baseUrl +
        `/GetDisciplineTree`, request
    );
  }

  public async updateDisciplineTree(request: CurrentControlTreeQuery) {
    this.triggerDisciplineTreeFetch$.next(request);
  }

  public getTrainingLevelTree(): Observable<TreeCommonModel[]> {
    return this.http.get<TreeCommonModel[]>(
      this.baseUrl + '/GetTrainingLevelTree'
    );
  }

  public async updateTrainingLevelTree() {
    await lastValueFrom(this.getTrainingLevelTree()).then((response) => {
      this.trainingLevelTree$.next(response);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {Reports} from "../../../models/mfc/reports/reports.model";

@Component({
  selector: 'mfc-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class MFCReportsComponent implements OnInit {

  public reports: Reports[] = [];
  public selectedReport = '';

  constructor() { }

  ngOnInit(): void {
    this.getReports();
  }

  public getReports() {
    this.reports = [
      {name: 'Трудоустройство выпускников'}
    ];
  }

  public openReport() {
    // TODO: Открываем выбранный отчет
  }

}

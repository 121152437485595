import {Guid} from "guid-typescript";

export class StreetTypes
{
  public dictStreetTypeId!: number;
  public dictStreetTypeExternalId!: Guid;
  public streetTypeName!: string;
  public streetTypeSName?: string;
  public order?: number;
}

export class PublicationsUserAccess {
    personId!: string;
    isAdmin?: boolean;
    //conditionalRole?: number;
    // Для ячейки
    personName?: string;
    report!: boolean;
    myPublication!: boolean;
    allPublication!: number;
    author!: number;
    dict!: number;
    accessSettings!: boolean
}

export class PublicationCurrentUserAccess {
  personId!: string;
  isAdmin!: boolean;
  report!: boolean;
  myPublication!: boolean;
  allPublication!: number;
  author!: number;
  dict!: number;
  accessSettings!: boolean
  conditionalRole!: number;
}

export enum AccessRights {
  No,
  Read,
  Write
}

export enum ConditionalRole {
    NoRole,
    Author,
    Moderator
}

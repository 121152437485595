import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EducationPlanDetailsModels } from "../../../../models/education/education-plan-details-model";
import { EducationPlanDetailsService } from "../../../../services/education/EducationPlan/education-plan-details.service";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {Location} from "@angular/common";

@Component({
  selector: 'new-education-plan',
  templateUrl: './new-education-plan.component.html',
  styleUrls: ['./new-education-plan.component.scss'],
})
export class NewEducationPlanComponent implements OnInit {
  public eduProgramId: string = '';

  constructor(
    activateRoute: ActivatedRoute,
    private router: Router,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private notificationService: NotificationsService,
    private location: Location
  ) {
    activateRoute.params.subscribe(params => {
      this.eduProgramId = params['education_program_id'];
    });
  }

  ngOnInit(): void {
    if (!this.educationPlanDetailsService.currentEducationStandard.directionTraining) {
      this.educationPlanDetailsService.getDetails(this.eduProgramId).subscribe();
    }
  }

  goBack() {
    this.location.back();
  }

  onSave(eduPlan: EducationPlanDetailsModels.EPlan) {
    this.educationPlanDetailsService.addEduPlan({
      ...eduPlan,
      educationProgramId: this.eduProgramId,
    }).subscribe(
      _ => {
        this.notificationService.showSuccess("Учебный план создан");
        this.router.navigate([`education/${this.eduProgramId}/plans/plan/${_.id}`]);
      }
    );
  }

  goToEduProgramList() {
    this.router.navigate([`/education`]);
  }

  onChangeEduProgram($event: EducationPlanDetailsModels.EProgram): void {
    this.router.navigate([`/education/${$event.educationProgramId}/plans/add-plan`]);
    this.eduProgramId = $event.educationProgramId
  }
}

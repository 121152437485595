import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from '@progress/kendo-file-saver';
import { FileRestrictions } from "@progress/kendo-angular-upload";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { PortfolioService } from '../../../services/portfolio/portfolio.service';
import { DictService } from '../../../services/portfolio/dict.service';
import { StudentService } from '../../../services/contingent/student.service';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { AchievementStatusEnum, DictAchievementStatus, DictDirectionActivity, WorkType } from '../../../models/portfolio/dict.model';
import {Achievement, AchievementFile, AchievementList} from '../../../models/portfolio/achievement.model';
import { getErrorMessage } from '../../../helpers/errorHandle-helper';
import { openRemoveDialog } from '../../../helpers/dialogHelper';
import {DateFromUTCAsLocal, DateToString, TimeZoneFix, monthWord} from '../../../helpers/date-helper';
import { environment } from "../../../../environments/environment";
import { deepCopy } from '@angular-devkit/core/src/utils/object';
import moment from 'moment';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit {

  //#region VAR

  public studentId!: string;
  public loading = false;
  public editMode = false;
  public editable: boolean = false;
  public addable: boolean = false;
  public edited: boolean = false;
  public achievements: Achievement[] = [];
  public filteredAchievements: any[] = [];
  public directionActivities: DictDirectionActivity[] = [];
  public statuses: DictAchievementStatus[] = [];
  public types: WorkType[] = [];
  public subTypes: WorkType[] = [];
  public achievementStatusEnum = AchievementStatusEnum;
  public mark: number = 0;
  public markWithCoefficient: number = 0;
  public coefficient: number = 1;

  //#region File

  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: environment.contingent.maxFileSize,
    allowedExtensions: [".pdf"],
  };
  public maxFileSizeMessage: string = `Размер файла должен быть не более ${Math.round(environment.contingent.maxFileSize/1024/1024)} МБ`;
  public fileUploaded = false;
  public fileUploadedList: AchievementFile[] = [];
  public fileUploads?: Array<File>;

  //#endregion

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    name: new UntypedFormControl(),
    dictWorkTypeId: new UntypedFormControl(),
    coefficient: new UntypedFormControl(1),
    dateEnd: new UntypedFormControl(),
    dateStart: new UntypedFormControl(),
    markWithCoefficient: new UntypedFormControl(),
    mark: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    dictAchievementStatusName: new UntypedFormControl(),
    directionActivityId: new UntypedFormControl(),
    files: new UntypedFormControl(),
  });

  //#endregion

  constructor(private portfolioService: PortfolioService,
              private dictService: DictService,
              private activateRoute: ActivatedRoute,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private studService: StudentService
              )
  {
    if(activateRoute.snapshot.parent !== null)
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
  }

  //#region Lifecycle methods

  ngOnInit(): void {
    this.getDirectionActivities();
    this.getAchievements();
    this.getWorkTypes();
    this.getStatuses();
    this.getAccess();
  }

  //#endregion

  //#region Data

  public getAchievements() {
    this.loading = true;
    this.portfolioService.getAchievements({ studentId: this.studentId }).subscribe({
      next: response => {
        this.achievements = response?.map((item: Achievement) => {
          item.hideAchievements = item.achievementList.filter((achievement: AchievementList) =>
            achievement.dateEnd && achievement.dateEnd < item.achievementPeriod.pretendentStartDate)?.length > 0 ? false : undefined;
          item.achievementList.map((listItem: AchievementList) => {
            listItem.hide = listItem.dateEnd != undefined && listItem.dateEnd <= item.achievementPeriod.pretendentStartDate;
            listItem.dateEnd = DateFromUTCAsLocal(listItem.dateEnd);
            listItem.dateStart = DateFromUTCAsLocal(listItem.dateStart);
            return listItem;
          });
          return item;
      });
        this.filteredAchievements = this.achievements;
      },
      error: err => {
        this.notificationService.showError(getErrorMessage(err));
      },
      complete: () => this.loading = false
    });
  }

  public getDirectionActivities() {
    this.dictService.getDirectionActivities().subscribe(
      response => this.directionActivities = response
    );
  }

  public getStatuses() {
    this.dictService.getStatuses().subscribe(
      response => {
        this.statuses = response;
      })
  }

  public getWorkTypes() {
    this.dictService.getTypes().subscribe(
      response => {
        this.types = response ?? [];
      })
  }

  private getAccess() {
    this.studService.getByIdstudent(this.studentId).subscribe(
      (response) => {
        this.editable = !!response.achievementEditable;
        this.addable = !!response.achievementAddable;
      });
  }

  //#endregion

  //#region Handlers

  public removeAchievement({ dataItem }: any): void {
    if (!this.editable) return;
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.name}`);
    dialog.result.subscribe((result ) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if (result.text == "Да") {
          this.portfolioService.deleteAchievement(dataItem.id).subscribe({
            next: () => {
              this.notificationService.showSuccess('Успешно')
              this.getAchievements();
            },
            error: err => this.notificationService.showError(getErrorMessage(err))
          })
        }
      }
    });
  }
  //#endregion

  //#region Download

  public downloadFile(file: { id: string, name: string }) {
    this.portfolioService.downloadAchievementFile(file.id).subscribe({
      next: response => {
        let blob:any = new Blob([response], { type: `application/pdf; charset=utf-8` });

        saveAs(blob, file.name);
      },
      error: err => this.notificationService.showError(err.status == 404 ? 'Файл не найден' : getErrorMessage(err))
    });
  }

  //#endregion

  //#region EditForm

  public addAchievement() {
    if (!this.editable) return;

    this.editMode = true;
    this.resetForm();
  }

  public closeEditForm() {
    this.editMode = false;
    this.resetForm();
  }

  public formChanged()
  {
    this.edited = true;
  }

  public saveAchievement() {
    if (!this.editable) return;

    if (!this.fileUploaded || !this.editForm.valid) {
      this.notificationService.showError('Заполните обязательные поля');
      return;
    }

    let formData = new FormData();

    if (this.editForm.value.id) formData.append(`Id`, this.editForm.value.id);
    formData.append(`Name`, this.editForm.value.name);
    formData.append(`StudentId`, this.studentId);
    formData.append(`DirectionActivityId`, this.editForm.value.directionActivityId);
    formData.append(`DictWorkTypeId`, this.editForm.value.dictWorkTypeId ?? '');
    formData.append(`Coefficient`, this.editForm.value.coefficient);
    formData.append(`DateStart`, TimeZoneFix(this.editForm.value.dateStart).toISOString());
    formData.append(`DateEnd`, TimeZoneFix(this.editForm.value.dateEnd).toISOString());
    formData.append(`Comment`, this.editForm.value.comment ?? '');

    this.fileUploads?.forEach((_) => {
      formData.append(`Files`, _, _.name);
    });

    this.editForm.value.id
      ? this.portfolioService.putAchievement(formData).subscribe({
        next: () => {
          this.getAchievements();
          this.closeEditForm()
          this.notificationService.showSuccess("Обновлено");
          },
        error: () => this.notificationService.showError('Не удалось обновить достижение')
      })
      : this.portfolioService.postAchievement(formData).subscribe({
          next:() => {
            this.getAchievements();
            this.closeEditForm()
            this.notificationService.showSuccess("Сохранено");
          },
          error:() => this.notificationService.showError('Не удалось сохранить изменения')
      });
  }

  public changeStatus(statusEnum: number) {
    if(this.edited) {
      this.notificationService.showError('Необходимо сохранить изменения!');
    } else {
      const status = {
        dictAchievementStatusId: this.statuses.find((item:any) => item.statusEnum===statusEnum)?.id,
        achievementId: this.editForm.get('id')?.value,
        achievementStatusDate: null,
        isMigrationData: false
      };
      this.portfolioService.postStatus(status).subscribe({
        next: () => {
          this.getAchievements();
          this.closeEditForm()
          this.notificationService.showSuccess("Статус изменен");
          },
        error: () => this.notificationService.showError('Не удалось изменить статус')
      })
    }
  }

  public resetForm() {
    this.edited = false;
    this.fileUploaded = false;
    this.fileUploadedList = [];
    this.fileUploads = [];
    this.editForm.reset();
  }

  //#region File

  public fileSelected() {
    this.edited = true;
    this.fileUploaded = true;
    this.fileUploadedList = [];
  }

  public fileRemoved() {
    this.edited = true;
    this.fileUploaded = false;
  }

  //#endregion

  public valueChangeType(value: string) {
    this.edited = true;
    this.subTypes = this.types.filter((el: WorkType) => el.parentId == value);
    this.getTree(this.subTypes);
    this.mark = this.types.find((el: WorkType) => el.id == value)?.mark || 0;
    this.calculateMark();
  }

  private getTree(array: any) {
    array.forEach((el: any) => {
      el.items = this.types.filter((item: WorkType) => item.parentId == el.id);
      this.getTree(el.items);
    })
  }

  public onChangeCoefficient(): void {
    this.calculateMark()
    this.edited = true;
  }

  public achivement = {
    dateEnd: new Date(),
    dateStart: new Date(),
    dictAchievementStatusName: "",
    coefficient: 1,
    mark: 0,
  };

  public viewHandler({dataItem}: CellClickEvent): void {
    if (dataItem.files && dataItem.files.length) {
      this.fileUploaded = true;
      this.fileUploadedList = dataItem.files;
    }
    this.subTypes = this.types.filter((el) => el.parentId == dataItem.worksTypeId);
    this.getTree(this.subTypes);
    this.editMode = true;
    this.achivement = JSON.parse(JSON.stringify(dataItem));
    if (dataItem.dictAchievementStatusName) {
      const date = dataItem.dictAchievementStatusDate ? `(${DateToString(dataItem.dictAchievementStatusDate, 'dd.MM.yyyy HH:mm')})`: '';
      this.achivement.dictAchievementStatusName = `${dataItem.dictAchievementStatusName} ${date}`;
    }
    this.achivement.dateEnd = new Date(dataItem.dateEnd);
    this.achivement.dateStart = new Date(dataItem.dateStart);
    this.coefficient = this.achivement.coefficient;
    this.mark = this.achivement.mark;
    this.calculateMark();
    this.editForm.reset(this.achivement);
  }

  public calculateMark() {
    this.markWithCoefficient = Math.round(this.mark * this.coefficient * 100) / 100;
  }

  public cancelEdit() {
    this.editForm.reset();
    this.editMode = false;
  }

  public hidePast(directionActivityId: any, hideAchievements: boolean) {
    this.achievements.map((item: Achievement) => {
      if (item.directionActivityId == directionActivityId)
        item.hideAchievements = !hideAchievements;
      return item;
    });
    this.filteredAchievements = deepCopy(this.achievements);
    this.filteredAchievements = this.filteredAchievements.map((item: Achievement) => {
        if (item.hideAchievements)
          item.achievementList = item.achievementList.filter((listItem: AchievementList) => !listItem.hide) ?? [];
      return item;
    });
  }

  public getDate(value: string) {
    const date = new Date(value);
    return date.getUTCDate() + " " + monthWord(date.getMonth()) + " " + date.getUTCFullYear();
  }
}

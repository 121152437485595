import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {DictEmployerPost} from "../../models/contingent/dictemployerpost.model";

@Injectable({
  providedIn: 'root'
})
export class DictemployerpostService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.employerpost}`;

  constructor(private http: HttpClient) { }

  public getAllEmployerPost(): Observable<DictEmployerPost[]> {
    return this.http.get<DictEmployerPost[]>(this.baseUrl);
  }

  public addEmployerPost(employerPost: DictEmployerPost): Observable<DictEmployerPost> {
    return this.http.post<DictEmployerPost>(this.baseUrl, employerPost)
      .pipe(catchError(this.handleError));
  }

  public deleteEmployerPost(id: string): Observable<DictEmployerPost> {
    return this.http.delete<DictEmployerPost>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  public updateEmployerPost(employerPost: DictEmployerPost): Observable<DictEmployerPost> {
    return this.http
      .put<DictEmployerPost>(this.baseUrl + '/' + employerPost.externalId, employerPost)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

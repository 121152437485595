import {Injectable} from '@angular/core';
import {ApplicationList, ApplicationOptions} from '../../models/mfc/home/application.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {handleError} from '../../helpers/publications/errorHandle-helper';
import {TimeZoneFix} from '../../helpers/date-helper';


@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.application}/GetAll`;

  constructor(private http: HttpClient) { }

  private timeZoneFix(options: ApplicationOptions) {
    if (options.filter?.createdAt) {
      if (options.filter.createdAt.start) options.filter.createdAt.start = TimeZoneFix(options.filter.createdAt.start);
      if (options.filter.createdAt.end) options.filter.createdAt.end = TimeZoneFix(options.filter.createdAt.end);
    }

    if (options.filter?.statusUpdatedAt) {
      if (options.filter.statusUpdatedAt.start) options.filter.statusUpdatedAt.start = TimeZoneFix(options.filter.statusUpdatedAt.start);
      if (options.filter.statusUpdatedAt.end) options.filter.statusUpdatedAt.end = TimeZoneFix(options.filter.statusUpdatedAt.end);
    }
  }

  public getApplications(filter: ApplicationOptions, waitingOnly: boolean = false): Observable<ApplicationList> {
    this.timeZoneFix(filter);
    return this.http.post<ApplicationList>(`${this.baseUrl}${waitingOnly ? 'Waiting' : ''}`, filter)
      .pipe(catchError(handleError));
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {Person, PersonDto, PPSGet} from "../../../models/disciplineworkload/externals.model";
import {handleError} from "../../../helpers/errorHandle-helper";
import { Guid } from 'guid-typescript';
import { CreateQuery } from '../../../helpers/createQuery-helper';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.person}`;
  constructor(private http: HttpClient) { }

  getAllPersons(): Observable<Person[]> {
    return this.http.get<Person[]>(this.baseUrl + '/GetPersons')
      .pipe(
        catchError(handleError));
  }

  // Get persons
  getPersons(filter: { kafedraId: string }): Observable<Person[]> {
    let query = CreateQuery(filter);

    return this.http.get<Person[]>(this.baseUrl + '/GetPersons', {params: query})
        .pipe(
            catchError(handleError));
  }

  // Get persons
  getPPSPersons(kafedraFilter: {
    kafedraId: string,
    studyYear: number | null,
    withLoad: boolean
  }): Observable<PPSGet[]> {
    let query = new HttpParams();

    if(kafedraFilter?.kafedraId != null) {
      query = query.appendAll({"KafedraId": kafedraFilter.kafedraId });
    }
    if(kafedraFilter?.studyYear != null) {
      query = query.appendAll({"StudyYear": kafedraFilter.studyYear});
    }
    query = query.appendAll({'WithLoad': kafedraFilter.withLoad})

    return this.http.get<PPSGet[]>(this.baseUrl + '/GetPPSPersons', {params: query})
        .pipe(
            catchError(handleError));
  }

  // Get person by id
  getPersonsById(id: string): Observable<Person> {
    return this.http.get<Person>(this.baseUrl + '/GetPersonsById/' + id)
        .pipe(
            catchError(handleError));
  }

  hasPps(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + '/HasPPS')
      .pipe(catchError(handleError));
  }

  isPps(cathedraId: Guid | string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + '/HasPPS/' + cathedraId)
      .pipe(catchError(handleError));
  }

  addPerson(person: PersonDto): Observable<PersonDto> {
    if (person.salary?.postId == '') person.salary.postId = null;

    return this.http.post<PersonDto>(this.baseUrl, person);
  }

  updatePerson(person: PersonDto): Observable<PersonDto> {
    if (person.salary?.postId == '') person.salary.postId = null;

    return this.http.put<PersonDto>(this.baseUrl, person);
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + id);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { environment } from '../../../environments/environment';
import { DictCodeArticle } from '../../../app/models/contingent/dictcodearticle.model';

@Injectable({
  providedIn: 'root'
})
export class DictCodeArticleService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictcodearticle}`;

  constructor(private http: HttpClient) { }

  //Get all CodeArticle
  public getAllDictCodeArticles(): Observable<DictCodeArticle[]> {
    return this.http.get<DictCodeArticle[]>(this.baseUrl);
  }

  //Add CodeArticle
  addDictCodeArticle(dto: DictCodeArticle): Observable<DictCodeArticle> {
    return this.http.post<DictCodeArticle>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete CodeArticle
  deleteDictCodeArticle(id: string): Observable<DictCodeArticle> {
    return this.http.delete<DictCodeArticle>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit CodeArticle
  updateDictCodeArticle(dto: DictCodeArticle): Observable<DictCodeArticle> {
    return this.http.put<DictCodeArticle>(this.baseUrl + '/' + dto.externalId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
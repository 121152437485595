import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TrainingLevel } from '../../models/dicts/traininglevel.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class TrainingLevelService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.traininglevel}`;

  constructor(private http: HttpClient) { }

  //Get all TrainingLevel
  public getAllTrainingLevel(): Observable<TrainingLevel[]> {
    return this.http.get<TrainingLevel[]>(this.baseUrl);
  }

  //Add TrainingLevel
  addTrainingLevel(traininglevel: TrainingLevel): Observable<TrainingLevel> {
    return this.http.post<TrainingLevel>(this.baseUrl, traininglevel)
      .pipe(catchError(this.handleError));
  }

  //Delete TrainingLevel
  deleteTrainingLevel(id: string): Observable<TrainingLevel> {
    return this.http.delete<TrainingLevel>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit TrainingLevel
  updateTrainingLevel(traininglevel: TrainingLevel): Observable<TrainingLevel> {
    return this.http.put<TrainingLevel>(this.baseUrl + '/' + traininglevel.dictTrainingLevelExternalId, traininglevel)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { PersonService } from 'src/app/services/person/lkperson.service';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { FoundStudentsService } from 'src/app/services/announcement/found-students.service';
import { FoundPersonsService } from 'src/app/services/announcement/found-persons.service';
import { AnnouncementUserAccessService } from 'src/app/services/useraccess/announcement-user-access.service';
import { AnnouncementUserAccess } from 'src/app/models/useraccess/announcement/announcementuseraccess.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { checkRole } from "../../../helpers/token/role-check";
import { Role } from 'src/app/models/useraccess/role';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DistribuitionsService } from 'src/app/services/announcement/distribuitions.service';

@Component({
  selector: 'app-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.scss'],
})

export class ModifyComponent implements OnInit{
  VisibleSendStudents: boolean = true;
  VisibleSendPersons: boolean = false;
  VisibleRecipients: boolean = false;

  changestudents: boolean = true;
  nchangestudents: boolean = false;
  changepersons: boolean = false;
  nchangepersons: boolean = true;
  recipientlist: boolean = false;
  nrecipientlist: boolean = true;

  public selectedStudentsCount: number = 0;
  public selectedPersonsCount: number = 0;
  public selectedDistrStudentsCount: number = 0;
  public selectedDistrPersonsCount: number = 0;
  public selectedDistrCount: number = 0;
  private subscriptions: Subscription[] = [];
  
  public send_students(): void {
    this.VisibleSendStudents = true;
    this.VisibleSendPersons = false;
    this.VisibleRecipients = false;

    this.changestudents = true;
    this.nchangestudents = false;

    this.nchangepersons = true;
    this.changepersons = false;

    this.recipientlist = false;
    this.nrecipientlist = true;

    this.distribuitionsService.getNewList(false);
  }
  
  public send_persons(): void {
    this.VisibleSendStudents = false;
    this.VisibleSendPersons = true;
    this. VisibleRecipients = false;

    this.changestudents = false;
    this.nchangestudents = true;

    this.changepersons = true;
    this.nchangepersons = false;

    this.recipientlist = false;
    this.nrecipientlist = true;

    this.distribuitionsService.getNewList(false);
  }

  public select_recipients(): void {        
    this.VisibleSendStudents = false;
    this.VisibleSendPersons = false;
    this.VisibleRecipients = true;

    this.changestudents = false;
    this.nchangestudents = true;

    this.changepersons = false;
    this.nchangepersons = true;

    this.recipientlist = true;
    this.nrecipientlist = false;

    this.announcementService.currentAnnouncement.next({
      deliveries: [],
      announcement: [],
    });

    //Очищает первые две вкладки
    //this.foundpersonsService.getSelectPersons([], []);
    //this.foundStudentsService.getSelectStudents([], []);
  }

  VisiblePopUp: boolean = false;
  
  onChanged(value: boolean) {
    if (value == true){
      this.VisiblePopUp = true;
    }
  }

  public cancel(){
    this.VisiblePopUp = false;
  }
    
  public isAdmin: boolean = false;
  public personId: string = "";
  public accessLevel: boolean = false;
  public accesses: AnnouncementUserAccess[] = [];


  constructor(
    private router: Router,
    private personService: PersonService,
    private announcementService: AnnouncementService,
    private foundpersonsService: FoundPersonsService,
    private foundStudentsService: FoundStudentsService,
    private activatedroute: ActivatedRoute,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: AnnouncementUserAccessService,
    private distribuitionsService: DistribuitionsService,
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }
  
  public authorFio: string = "";

  public getCurrentPerson(){
    this.personService.getCurrentPerson()
      .subscribe((response: { fullName: string; }) => {
        this.authorFio = response.fullName;
      });
  }
  
  public getAccess (){
    this.userAccessService.getAccess(this.personId)
      .subscribe((res: AnnouncementUserAccess[]) => {
        this.accesses = res;
          }
      );
    this.accessLevel = this.accesses.length > 0 ? this.accesses[0].userAccessLevel : false; 
  }

  public getAnnouncement(id: number) {
    if (id){
      this.announcementService.getAnnouncement(id)
        .subscribe((response: any) => {
          let message: any = response;
      
          let data = this.announcementService.currentAnnouncement.getValue();
          let back = data.back;
          let announcement = data.announcement;

          if (!back) {
            this.announcementService.currentAnnouncement.next({
              deliveries: [],
              announcement: {messageId: id, deliveries: message.deliveries, tags: message.tags, messageType: message.messageType, isDraft: message.isDraft,
                              postpone: message.postpone, title: message.title, content: message.content, attachments: message.attachments, post: null},
            });
          } 
          else {
            this.announcementService.currentAnnouncement.next({
              deliveries: [],
              announcement: announcement,
            }); 
          }
      });
    }
  }

  public getPattern(){
    this.subscriptions.push(
      this.announcementService.SendPattern.subscribe((state: { messageId: undefined; pattern: any; }) => {
       // console.log('state.messageId',state.messageId);
        if (state.messageId) {
          this.announcementService.getAnnouncement(state.messageId)
            .subscribe( (response: any) => {
              let message: any = response;
             // console.log('шаблон',message);
              this.announcementService.currentAnnouncement.next({
                deliveries: [],
                announcement: {messageId: 0, deliveries: message.deliveries, tags: message.tags, messageType: message.messageType, isDraft: message.isDraft,
                              postpone: message.postpone, title: message.title, content: message.content, attachments: message.attachments, post: null},
                /*announcement: {messageId: 0, tags: message.tags, messageType: message.messageType, isDraft: message.isDraft,
                  postpone: message.postpone, title: message.title, content: message.content, attachments: message.attachments, post: null},
                pattern: state.pattern,*/
              });
            })
        }
      })
    );
  }

  ngOnInit() {
    this.getCurrentPerson();
    this.getAccess();

    this.subscriptions.push(
      this.foundStudentsService.selectedStudentsCount$.subscribe((count: number) => {
        this.selectedStudentsCount = count;
      })
    );
    this.subscriptions.push(
      this.foundpersonsService.selectedPersonsCount$.subscribe((count: number) => {
        this.selectedPersonsCount = count;
      })
    );
    this.subscriptions.push(
      this.foundStudentsService.selectDistrStudents$.subscribe((data: any[]) => {
        this.selectedDistrStudentsCount = data.length;
        this.selectedDistrCount = this.selectedDistrPersonsCount + data.length;;
      })
    );
    this.subscriptions.push(
      this.foundpersonsService.selectDistrPersons$.subscribe((data: any[]) => {
        this.selectedDistrPersonsCount = data.length;;
        this.selectedDistrCount = this.selectedDistrStudentsCount + data.length;;
      })
    );

    let patternMessage = this.announcementService.SendPattern.getValue();
    if (patternMessage.pattern == true){
        this.getPattern();
    }
    
    let editMessageId = this.activatedroute.snapshot.params['id'];
    if (editMessageId){
        this.getAnnouncement(this.activatedroute.snapshot.params['id']);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { Router } from '@angular/router';
import { PrintTemplateService } from '../../../services/gia/printTemplate.service';
import { PrintTemplateModel, PrintTemplateTrainingLevels } from '../../../models/gia/printtemplate/printTemplate.model';
import { isDefaultPrintTemplateEnum } from '../../../models/gia/enums/isDefaultPrintTemplate.enum';
import { DictPrintTemplateCategoryEnum } from '../../../models/gia/enums/dictPrintTemplateCategory.enum';
import { saveAs } from '@progress/kendo-file-saver';
import { DictDto, StudyYearDto } from '../../../models/gia/dicts/dicts.model';
import { DictsService } from '../../../services/gia/dicts.service';
import { GiaUserAccessService } from '../../../services/useraccess/gia-user-access.service';
import { GridDisplayList } from '../../../helpers/grid-helper';
import { ColumnComponent, PagerSettings } from '@progress/kendo-angular-grid';
import { dictPagerSettings } from '../../../models/dicts/pagerSettings.model';

@Component({
  selector: 'app-print-template',
  templateUrl: './print-template.component.html',
  styleUrls: ['./print-template.component.scss'],
})
export class PrintTemplateComponent implements OnInit {
  public allFilial: DictDto[] = [];
  public yearAdmissionList: StudyYearDto[] = [];
  public editable?: boolean;
  public filial: string | null = '';
  public yearAdmission!: number;
  public printTemplateFilters: { yearAdmission: number; filial: string } = {
    yearAdmission: 0,
    filial: '',
  };
  public filters: any;
  public printTemplates: PrintTemplateModel[] = [];
  public printTemplateList: PrintTemplateModel[] = [];

  public pagerSettings: PagerSettings = dictPagerSettings;

  public pageSize = 20;
  public loading: boolean = false;
  public dictPrintTemplateCategories: Array<{
    dictPrintTemplateCategoryId: string;
    dictPrintTemplateCategoryName: string | undefined;
  }> = [];
  public dictTrainingLevels: Array<{
    dictTrainingLevelId: string;
    dictTrainingLevelName: string | undefined;
  }> = [];
  public departments: Array<{
    departmentId: string;
    departmentName: string | undefined;
  }> = [];
  constructor(
    private dictService: DictsService,
    private notificationService: NotificationsService,
    private userAccessService: GiaUserAccessService,
    private router: Router,
    private printTemplateService: PrintTemplateService,
  ) {}

  ngOnInit(): void {
    this.getAccessLevel();
    this.getLocalFilters();
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        this.editable = response.printTemplateAccess;
        this.getAllFilial();
      },
      error: () => {},
    });
  }

  public getAllFilial() {
    this.dictService.getFilials().subscribe((response) => {
      this.allFilial = response;
      this.allFilial.unshift({ id: '', name: 'Все', shortName: 'Все' });
      if (this.filters == null) this.filial = this.allFilial[0].id!.toString();
      this.getYearAdmissionList();
    });
  }

  public getYearAdmissionList() {
    this.dictService.getStudyYears().subscribe((response) => {
      this.yearAdmissionList = response;
      if (this.filters == null) this.yearAdmission = this.yearAdmissionList[0].studyYear;

      this.onValueChange();
    });
  }

  public onValueChange(value?: string | number | null) {
    const object = { filial: this.filial, yearAdmission: this.yearAdmission };
    localStorage.setItem('printTemplateFilters', JSON.stringify(object));
    this.getPrintTemplates();
  }

  public getLocalFilters() {
    this.filters = localStorage.getItem('printTemplateFilters');
    if (this.filters) {
      this.printTemplateFilters = JSON.parse(this.filters);
      this.yearAdmission = this.printTemplateFilters.yearAdmission;
      this.filial = this.printTemplateFilters.filial;
    }
  }

  public addPrintTemplate() {
    this.router.navigate(['/gia/printtemplate/add']);
  }

  public getPrintTemplates() {
    this.loading = true;
    this.printTemplateService.getList(this.yearAdmission, this.filial).subscribe((response) => {
      if (!response){
        this.printTemplates = [];
        this.loading = false;
        return;
      }

      let printTemplatesResponse = response.map((template) => {
        if (template.departmentId === null) {
          template.departmentId = '0';
          template.departmentName = 'Все';
        }
        return template;
      });

      this.printTemplates = printTemplatesResponse;
      this.printTemplateList = printTemplatesResponse;
      this.loading = false;

      if (this.printTemplates) {
        this.loadFilterValuesFromDropdown();
      }
    });
  }

  getFilterValues(array: any[], filterProperty: keyof any, resultProperty: keyof any): Array<any> {
    const uniqueValues = Array.from(new Set(array.map((item) => item[filterProperty])));
    const sortedUniqueValues = uniqueValues.sort();

    const result = sortedUniqueValues.map((value) => {
      const element = array.find((el) => el[filterProperty] === value);
      return {
        [filterProperty]: value,
        [resultProperty]: element ? element[resultProperty] : null,
      };
    });
    return result;
  }

  getIsDefaultPrintTemplate(value: boolean) {
    return isDefaultPrintTemplateEnum.find((el) => el.isDefaultPrintTemplateValue === value)?.isDefaultPrintTemplateName;
  }

  loadFilterValuesFromDropdown() {
    let flattenedArray: PrintTemplateTrainingLevels[] = this.printTemplates
      .flatMap((x) => x.printTemplateTrainingLevels)
      .map((item) => {
        if (item.id === null) {
          item.id = '';
        }
        return item;
      });

    let uniqueFlattenedArray = [...new Set(flattenedArray)];

    this.dictPrintTemplateCategories = this.getFilterValues(this.printTemplates, 'dictPrintTemplateCategoryId', 'dictPrintTemplateCategoryName');
    this.dictTrainingLevels = this.getFilterValues(uniqueFlattenedArray, 'id', 'name');
    this.departments = this.getFilterValues(this.printTemplates, 'departmentId', 'departmentName');
  }

  public onRowClick(event: any): void {
    if (this.editable) this.router.navigate([`/gia/printtemplate/add/${event.dataItem.id}`]);
  }

  public downloadFile(id: string, dictPrintTemplateCategoryValue: number, fileName: string, printTemplateName: string) {
    if (dictPrintTemplateCategoryValue == DictPrintTemplateCategoryEnum.Дипломы && id) {
      this.printTemplateService.downloadArchiveByPrintTemplateId(id).subscribe({
        next: (value) => {
          const blob = new Blob([value], { type: 'application/zip' });
          saveAs(blob, printTemplateName as string);
        },
        error: () => {
          this.notificationService.showError('Не удалось скачать файл');
        },
      });
    } else {
      this.printTemplateService.getFileByPrintTemplateId(id).subscribe({
        next: (value) => {
          saveAs(value, fileName as string);
        },
        error: () => {
          this.notificationService.showError('Не удалось скачать файл');
        },
      });
    }
  }

  public getList(dataItem: any, key: any) {
    return GridDisplayList(dataItem, key);
  }

  public async onFilterChange(value: any, column: ColumnComponent) {
    let foundElements: PrintTemplateModel[] = this.printTemplateList.filter((arr) => {
      return arr.printTemplateTrainingLevels.some((item) => value.includes(item.id));
    });

    this.printTemplates = value != null && value.length > 0 ? Array.from(new Set(foundElements)) : this.printTemplateList;
  }
}

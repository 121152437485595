import {Component, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AddEvent, EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { CommonDictionariesUserAccess } from 'src/app/models/useraccess/commondictionaries/commondictionariesuseraccess.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { CommonDictionariesUserAccessService } from 'src/app/services/useraccess/common-dictionaries-user-access.service';
import {PersonService} from "../../../services/dicts/person.service";
import {CommonDictPerson} from "../../../models/dicts/person.model";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {SortDescriptor} from "@progress/kendo-data-query";
import {isChildOf} from "../../../helpers/elementRef-helper";

@Component({
  selector: 'app-common-dictionaries-access',
  templateUrl: './common-dictionaries-access.component.html',
  styleUrls: ['./common-dictionaries-access.component.scss']
})
export class CommonDictionariesAccessComponent implements OnInit {
  public isAdmin: boolean;
  public virtual: any = {
    itemHeight: 28,
  };

  public persons: CommonDictPerson[] = [];
  public accesses: CommonDictionariesUserAccess[] = [];

  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]

  public dropdownOptions: any[] = [
    {id: 0, text: 'Запрещено'},
    {id: 1, text: 'Чтение' },
    {id: 2, text: 'Запись' }
  ]

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;
  public sort: SortDescriptor[] = [{ field: "personName", dir: "asc" }];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;
  public loading = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: any[] = [];

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  constructor(private renderer: Renderer2,
              private dialogService: DialogService,
              private personService: PersonService,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  ngOnInit(): void {
    this.getAccess();
    this.getPersons();
  }

  // Get access
  public getAccess() {
    this.loading = true;
    this.userAccessService.getAccess()
      .subscribe(
        response => {
      //    console.log(response);
          this.loading = false;
          this.accesses = response;
          this.gridData = this.accesses;
        }, error => {
          this.loading = false;
          }
      );
  }

  // Get persons
  public getPersons() {
    this.personService.getAllPersom()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if(this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }
      this.userAccessService.saveAccess(this.formGroup.value)
      .subscribe(
        response => {
          this.getAccess();
          this.notificationService.showSuccess("Добавлено");
        },
        error => {
          this.notificationService.showError("Не удалось добавить запись");
        }
      );
    }
    this.closeEditor(this.grid);
  }

  //Start Editing
  public editHandler({sender, rowIndex, dataItem}: EditEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      //this.saveCurrent();
      return;
    }

    this.isLine = true;
    //this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста, подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.personName} из настроек доступа?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.userAccessService.deleteAccess(dataItem.id)
          .subscribe(
            (response: any) => {
              this.getAccess();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      id: 0,
      personId: '',
      accessLevelQualification: 1,
      accessLevelOrganization:1,
      accessLevelEducation: 1,
      accessLevelDiscipline: 1,
      accessLevelSignatoryRole: 1,
      accessLevelFilial: 1,
      accessLevelEducationalOrganizationName: 1,
      userAccessLevel: false,
      signatoryAccessLevel: false
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  clearData() {
    this.gridData = this.accesses;
    this.searchFIO = ''
  }

  filterData(dataItem: any) {
    this.gridData = this.accesses.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

}

const formGroup = (dataItem:
  {
    id?: any;
    personId?: any;
    accessLevelQualification?: any;
    accessLevelOrganization?:any;
    accessLevelEducation?: any;
    accessLevelDiscipline?: any;
    accessLevelSignatoryRole?: any,
    accessLevelFilial?: any,
    accessLevelEducationalOrganizationName?: number,
    userAccessLevel?: boolean,
    signatoryAccessLevel?: boolean
  }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    accessLevelQualification: new FormControl(dataItem.accessLevelQualification, Validators.required),
    accessLevelOrganization: new FormControl(dataItem.accessLevelOrganization, Validators.required),
    accessLevelEducation: new FormControl(dataItem.accessLevelEducation, Validators.required),
    accessLevelDiscipline: new FormControl(dataItem.accessLevelDiscipline, Validators.required),
    accessLevelSignatoryRole: new FormControl(dataItem.accessLevelSignatoryRole, Validators.required),
    accessLevelFilial: new FormControl(dataItem.accessLevelFilial, Validators.required),
    accessLevelEducationalOrganizationName: new FormControl(dataItem.accessLevelEducationalOrganizationName, Validators.required),
    userAccessLevel: new FormControl(dataItem.userAccessLevel, Validators.required),
    signatoryAccessLevel: new FormControl(dataItem.signatoryAccessLevel, Validators.required)
  });

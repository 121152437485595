<contingent-header></contingent-header>
<kendo-loader class="loader" *ngIf="loaderVisible" [type]="type" size="medium"> </kendo-loader>
<div kendoDialogContainer></div>

<kendo-dialog title="Пожалуйста, подтвердите" *ngIf="opened" (close)="closeDialog()" [minWidth]="200" [width]="450">
  <p style="margin: 30px; text-align: center;">
    {{showDeleteMessage(deleteForever)}}
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Нет</button>
    <button kendoButton (click)="deleteStudent(deleteForever)" themeColor="success">
      Да
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Пожалуйста, подтвердите" *ngIf="openedManyDelete" (close)="closeDialogManyDelete()" [minWidth]="200" [width]="450">
  <p style="margin: 30px; text-align: center;">
    За данным физ. лицом закреплено несколько студентов. Удалить всех связанных студентов или только текущего?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="deleteAllStudentForever()">Всех</button>
    <button kendoButton (click)="deleteStudentForever()" themeColor="success">
      Текущего
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<div class="example-wrapper">
  <div class="page">
    <div class="content">
      <!--Grid-->

      <kendo-gridlayout [cols]="[250, 300]">

        <!--Column for Main information-->

        <kendo-gridlayout-item [col]="2" [row]="1" [colSpan]="2" [rowSpan]="1" class="box">

          <!--Title-->
          <h1>
            Основная информация

            <button kendoButton
                    fillMode="outline"
                    size="small"
                    *ngIf="!editMode&&contingent.editable"
                    rounded="none" style="margin-left:40px"
                    (click)="edit()">
              Редактировать
              <span class="k-icon k-i-edit" style="padding-bottom: 3px;"></span>
            </button>

            <button kendoButton
                    fillMode="outline"
                    size="small"
                    rounded="none" style="float: right;"
                    (click)="printStudentCard()">
              Карточка студента
              <span class="k-icon k-i-file-report" style="padding-bottom: 3px;"></span>
            </button>
          </h1>

          <!--Buttons in Edit Mode-->

          <div *ngIf="editMode" class="functionButtons" style="margin-top:20px">
            <button kendoButton size="small" *ngIf="editMode" (click)="close()">
              Отмена
            </button>

            <button kendoButton themeColor="primary" size="small" *ngIf="editMode" [disabled]="deleted" (click)="updateStudent()">
              Сохранить
            </button>

            <button kendoButton size="small" class="student-close-button student-right-buttons" *ngIf="isAdmin" (click)="openDialog(true)">
              Удалить навсегда
            </button>

            <button kendoButton size="small" [ngClass]="{'student-close-button': !isAdmin}" class="student-right-buttons" *ngIf="editMode&&!deleted" (click)="openDialog(false)">
              Архивировать
            </button>

            <button kendoButton size="small" [ngClass]="{'student-close-button': !isAdmin}" class="student-close-button student-right-buttons" *ngIf="editMode&&deleted" (click)="recoverStudent()">
              Восстановить студента
            </button>
          </div>

          <!--View Mode-->
          <div class="info">
            <div class="main-block">
              <h5 *ngIf="!editMode;">Личные данные</h5>
              <dl *ngIf="!editMode; else editForm" class="about">
                <div *ngIf="!isHidden(enum.fio)" class="content-row">
                  <kendo-label text="ФИО"></kendo-label>
                  <dd>
                    {{ (contingent.studPersons.lastName == null ? "" : contingent.studPersons.lastName) + " " + (contingent.studPersons.firstName == null ? "" : contingent.studPersons.firstName) + " " + (contingent.studPersons.middleName == null ? "" : contingent.studPersons.middleName) }}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.fio_latin)" class="content-row">
                  <kendo-label text="ФИО (латиница)"></kendo-label>
                  <dd>
                    {{ (contingent.studPersons.lastNameLatin == null ? "-" : contingent.studPersons.lastNameLatin) + " " + (contingent.studPersons.firstNameLatin == null ? "" : contingent.studPersons.firstNameLatin) + " " + (contingent.studPersons.middleNameLatin == null ? "" : contingent.studPersons.middleNameLatin) }}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.gender)" class="content-row">
                  <kendo-label text="Пол"></kendo-label>
                  <dd>{{(contingent.studPersons.isMale == true ? "Мужской" : "Женский")}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.citizenship)" class="content-row">
                  <kendo-label text="Гражданство"></kendo-label>
                  <dd>{{(contingent.dictCitizenship.country == null ? "-" : contingent.dictCitizenship.country)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.oksm)" class="content-row">
                  <kendo-label text="Код ОКСМ"></kendo-label>
                  <dd>{{(contingent.dictCitizenship.code == null ? "-" : contingent.dictCitizenship.code)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.compatriot)" class="content-row">
                  <kendo-label text="Соотечественник"></kendo-label>
                  <dd>{{(contingent.compatriot == null ? "-" : (contingent.compatriot.value === "true" ? "Да" : "Нет"))}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.citizenship_type)" class="content-row">
                  <kendo-label text="Тип гражданства"></kendo-label>
                  <dd>{{(contingent.citizenshipType == null ? "-" : nameCitizenshipType(contingent.citizenshipType.value))}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.snils)" class="content-row">
                  <kendo-label text="СНИЛС"></kendo-label>
                  <dd>{{(contingent.studPersons.snils == null ? "-" : contingent.studPersons.snils)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.inn)" class="content-row">
                  <kendo-label text="ИНН"></kendo-label>
                  <dd>{{(contingent.studPersons.inn == null ? "-" : contingent.studPersons.inn)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.birthdate)" class="content-row">
                  <kendo-label text="Дата рождения"></kendo-label>
                  <dd>{{(contingent.studPersons.birthday == null ? "-" : contingent.studPersons.birthday| date: "dd.MM.yyyy")}}</dd>
                </div>

                <!--<kendo-label text="Место рождения"></kendo-label>
              <dd>{{(contingent.identifications == null ? "" : contingent.identifications.birthPlace)}}</dd>-->

                <div *ngIf="!isHidden(enum.marital_status)" class="content-row">
                  <kendo-label text="Семейное положение"></kendo-label>
                  <dd>{{(contingent.familyState?.value == null ? "-" : contingent.familyState?.value)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.contacts)" class="content-row">
                  <kendo-label text="E-mail"></kendo-label>
                  <dd>{{(contingent.email == null ? "-" : contingent.email)}}</dd>

                  <kendo-label text="Телефон"></kendo-label>
                  <dd>{{(contingent.phone == null ? "-" : contingent.phoneFormatted)}}</dd>
                </div>
              </dl>

              <div *ngIf="!isHidden(enum.family_member)" class="content-row">
                <h5 *ngIf="!editMode;">Информация о родственниках</h5>
                <dl *ngIf="!editMode;" class="about">
                  <kendo-label text="Степень родства"></kendo-label>
                  <dd>{{(contingent.familyMember == null ? "-" :contingent.familyMember.dictFamilyMemberStatusId==null? "": nameFamilyMemberState(contingent.familyMember.dictFamilyMemberStatusId)?.familyMemberStatusName)}}</dd>
                  <kendo-label text="ФИО"></kendo-label>
                  <dd>{{(contingent.familyMember == null ? "-" : contingent.familyMember.fio)}}</dd>
                  <kendo-label text="Место работы"></kendo-label>
                  <dd>{{(contingent.familyMember == null ? "-" : contingent.familyMember.workPlace)}}</dd>
                  <kendo-label text="Должность"></kendo-label>
                  <dd>{{(contingent.familyMember == null ? "-" : contingent.familyMember.post)}}</dd>
                  <kendo-label text="Адрес проживания"></kendo-label>
                  <dd>{{(contingent.familyMember == null ? "-" : contingent.familyMember.address)}}</dd>
                  <kendo-label text="Телефон"></kendo-label>
                  <dd>{{(contingent.familyMember == null ? "-" : contingent.familyMember.phoneFormatted)}}</dd>
                  <kendo-label text="E-mail"></kendo-label>
                  <dd>{{(contingent.familyMember == null ? "-" : contingent.familyMember.email)}}</dd>

                  <!-- Additional field
                <kendo-label text="Кампусный номер"></kendo-label>
                <dd>{{(contingent?.campusN == null ? "" : contingent.campusN)}}</dd> -->
                </dl>
              </div>
            </div>
            <div class="main-block" style="margin-left: auto">
              <h5 *ngIf="!editMode;">Информация об обучении</h5>
              <dl *ngIf="!editMode;" class="about">

                <div *ngIf="!isHidden(enum.id)" class="content-row">
                  <kendo-label text="Идентификатор студента"></kendo-label>
                  <dd>{{(contingent.studPersons.login == null ? "-" : contingent.studPersons.login)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.student_number)" class="content-row">
                  <kendo-label text="Номер зачетной книжки"></kendo-label>
                  <dd>{{(contingent.studentNumber == null ? "-" : contingent.studentNumber)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.budget)" class="content-row">
                  <kendo-label text="Источник финансирования"></kendo-label>
                  <dd>
                    {{(contingent.studentBudgetState == null ? "-" : contingent.studentBudgetState.studentBudgetName)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.budget_category) && contingent.studentBudgetState.budgetCategoryName" class="content-row">
                  <kendo-label text="Категория финансирования"></kendo-label>
                  <dd>
                    {{(contingent.studentBudgetState.budgetCategoryName)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.budget_sub_category) && contingent.studentBudgetState.budgetSubCategoryName" class="content-row">
                  <kendo-label text="Подкатегория финансирования"></kendo-label>
                  <dd>
                    {{(contingent.studentBudgetState.budgetSubCategoryName)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.study_form)" class="content-row">
                  <kendo-label text="Форма обучения"></kendo-label>
                  <dd>{{(educationPlan?.dictStudyFormId == null ? "" : nameStudyForm(educationPlan?.dictStudyFormId))}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.training_level)" class="content-row">
                  <kendo-label text="Уровень подготовки"></kendo-label>
                  <dd>{{((educationPlan?.educationProgram == null ? "-" : nameTrainingLevel(educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId)))}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.faculty)" class="content-row">
                  <kendo-label text="Институт/факультет"></kendo-label>
                  <dd>{{(contingent.eduGroup == null ? "-" : nameFaculty(contingent.eduGroup.facultyId)?.name)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.course)" class="content-row">
                  <kendo-label text="Курс"></kendo-label>
                  <dd>{{(contingent.courseNum==null
                    ? (contingent.eduGroup == null ? "-" : contingent.eduGroup.courseNum)
                    : contingent.courseNum)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.group)" class="content-row">
                  <kendo-label text="Группа"></kendo-label>
                  <dd>{{(contingent.eduGroup == null ? "-" : contingent.eduGroup.groupName)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.education_standard)" class="content-row">
                  <kendo-label text="Направление подготовки"></kendo-label>
                  <dd>
                    {{contingent.studPlan == null ? "-" :nameEducationStandard(contingent.studPlan.planId
                    ? contingent.studPlan.planId
                    : contingent.eduGroup?.planId)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.education_program)" class="content-row">
                  <kendo-label text="Профиль (программа)"></kendo-label>
                  <dd> {{contingent.studPlan == null ? "-" :nameEducationProgram(contingent.studPlan.planId
                    ? contingent.studPlan.planId
                    : contingent.eduGroup?.planId)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.department)" class="content-row">
                  <kendo-label text="Кафедра"></kendo-label>
                  <dd>{{contingent.studPlan == null ? "-" : nameDepartment(contingent.studPlan.planId
                    ? contingent.studPlan.planId
                    : contingent.eduGroup?.planId)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.education_plan)" class="content-row">
                  <kendo-label text="Учебный план"></kendo-label>
                  <dd>{{contingent.studPlan == null ? "-" : nameEducationPlan(contingent.studPlan.planId
                    ? contingent.studPlan.planId
                    : contingent.eduGroup?.planId)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.scientific_adviser_tutor)" class="content-row">
                  <kendo-label text="Научный руководитель"></kendo-label>
                  <dd>{{(contingent.studPlan?.scientificPersonName ?? "-")}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.pedagogical_head)" class="content-row">
                  <kendo-label text="Руководитель педагогической практики	"></kendo-label>
                  <dd>{{(contingent.studPlan?.practicePersonName ?? "-")}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.academic_state)" class="content-row">
                  <kendo-label text="Академический статус"></kendo-label>
                  <dd>{{(contingent.studentAcademicState == null ? "-" : nameAcademicStatus(contingent.studentAcademicState!.dictStudentAcademicStateId))}}</dd>
                </div>

                <!--ToDo-->
                <div *ngIf="!isHidden(enum.customer_targeted_training)" class="content-row">
                  <kendo-label *ngIf="this.isTargetBudget(contingent.studentBudgetState?.studentBudgetName)" text="Заказчик целевого обучения"></kendo-label>
                  <dd *ngIf="this.isTargetBudget(contingent.studentBudgetState?.studentBudgetName)">
                    {{(contingent.studentBudgetState == null || contingent.studentBudgetState.dictOrganizationId == null ? "" : contingent.studentBudgetState.organizationName)}}
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.score_number)" class="content-row">
                  <kendo-label text="Номер счета"></kendo-label>
                  <dd>{{("-")}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.languages)" class="content-row">
                  <kendo-label text="Изучаемые языки"></kendo-label>
                  <dd>
                    <p class="lang" *ngFor='let language of contingent.languages;let i=index'>{{(language.languageName == null? "-" :language.languageName + (i+1!=contingent.languages.length? ', ':" " ))}}</p>
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.average_score_total)" class="content-row">
                  <kendo-label text="Средний балл"></kendo-label>
                  <dd>{{(contingent.ball == null ? "-" : contingent.ball)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.average_score_semester)" class="content-row">
                  <kendo-label text="Средний балл по итогам семестра"></kendo-label>
                  <dd>{{(contingent.semesterResult == null ? "-" : contingent.semesterResult)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.external_id)" class="content-row">
                  <kendo-label text="Внутренний номер"></kendo-label>
                  <dd>{{(contingent.studentId == null ? "-" : contingent.studentId)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.admission_year)" class="content-row">
                  <kendo-label text="Год начала обучения"></kendo-label>
                  <dd>{{(contingent.ordersStudyPeriod == null ? "-" : contingent.ordersStudyPeriod.dateStart| date:'yyyy')}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.graduate_year)" class="content-row">
                  <kendo-label text="Год выпуска"></kendo-label>
                  <dd>{{(contingent.ordersStudyPeriod == null ? "-" : contingent.ordersStudyPeriod.dateFinish| date:'yyyy')}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.elder)" class="content-row">
                  <kendo-label text="Староста"></kendo-label>
                  <dd>{{(contingent.elder ? "Да" : "Нет")}}</dd>
                </div>
              </dl>

              <h5 *ngIf="!editMode;">Прочее</h5>
              <dl *ngIf="!editMode;" class="about">

                <div *ngIf="!isHidden(enum.benefit)" class="content-row">
                  <kendo-label text="Льготы"></kendo-label>
                  <dd>{{(contingent.benefit == null ? "Нет льготы" : nameBenefit(contingent.benefit.value)?.benefitName)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.health)" class="content-row">
                  <kendo-label text="Группа здоровья"></kendo-label>
                  <dd>{{(contingent.health == null ? "-" : nameHealth(contingent.health.value)?.healthName)}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.military)" class="content-row">
                  <kendo-label text="Категория воинского учета"></kendo-label>
                  <dd>{{(contingent.military == null ? "-" : (nameMilitaryStatus(contingent.military.dictMilitaryStatusId)?.militaryStatusName))}}</dd>

                  <kendo-label text="Военкомат"></kendo-label>
                  <dd>{{nameMilitaryCommissariat(contingent.military?.dictMilitaryCommissariatId)?.commissariatName ?? "-"}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.sport_type)" class="content-row">
                  <kendo-label text="Вид спорта"></kendo-label>
                  <dd>{{(!contingent.sportType||contingent.sportType.value===""? "-" : (nameSportType(contingent.sportType.value)?.sportTypeName))}}</dd>
                </div>

                <div *ngIf="!isHidden(enum.sport_category)" class="content-row">
                  <kendo-label text="Спортивный разряд"></kendo-label>
                  <dd>{{(contingent.sportCategory == null ? "-" : (nameSportCategory(contingent.sportCategory.value)?.sportCategoryName))}}</dd>
                </div>
              </dl>

              <h5 *ngIf="!editMode && contingent.parallelStudy != null">Параллельное обучение</h5>
              <div *ngIf="!editMode && contingent.parallelStudy != null">
                <dl class="about" *ngFor="let item of contingent.parallelStudy!;let i=index">
                  <div *ngIf="!isHidden(enum.group)" class="content-row">
                    <kendo-label text="Группа"></kendo-label>
                    <dd>{{(contingent.parallelStudy == null ? "-" : contingent.parallelStudy[i].groupName)}}</dd>
                  </div>
                  <div *ngIf="!isHidden(enum.academic_state)" class="content-row">
                    <kendo-label text="Академический статус"></kendo-label>
                    <dd>{{(contingent.parallelStudy == null ? "-" : contingent.parallelStudy[i].academicState)}}</dd>
                  </div>
                  <kendo-label *ngIf="(i+1) !== contingent.parallelStudy.length" text=""></kendo-label>
                  <dd *ngIf="(i+1) !== contingent.parallelStudy.length"></dd>
                </dl>
              </div>
            </div>
          </div>

          <!------------------------------------------------------------------------------>
          <!----------------------Edit Mode Student Main Information---------------------->
          <!------------------------------------------------------------------------------>

          <ng-template #editForm>
            <div class="ngForm">
              <form class="k-form" [formGroup]="formStudPerson">
                <dl class="editForm">

                  <h5>Личные данные</h5>
                  <dd></dd>

                  <div *ngIf="!isHidden(enum.fio)" class="content-row">
                    <div class="label-sticky-top">
                      <kendo-label [for]="lastName" text="ФИО"></kendo-label>
                    </div>
                    <dd class="container-fio">
                      <kendo-formfield>
                        <kendo-textbox formControlName="lastName" class="input-main-fio" size="small" [clearButton]="true" [disabled]="deleted" placeholder="Фамилия" #lastName required pattern='[-a-zA-Zа-яА-ЯёЁ ]*'></kendo-textbox>
                        <span class="required">*</span>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['lastName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['lastName']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>

                      <kendo-formfield>
                        <kendo-textbox formControlName="firstName" class="input-main-fio" [style.margin-left.px]="-1" size="small" [clearButton]="true" [disabled]="deleted" placeholder="Имя" required pattern='[-a-zA-Zа-яА-ЯёЁ ]*'></kendo-textbox>
                        <span class="required">*</span>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['firstName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['firstName']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>

                      <div class="line-break"></div>

                      <kendo-formfield>
                        <kendo-textbox formControlName="middleName" class="input-main-fio" style="margin-right: 20px;" size="small" [clearButton]="true" [disabled]="deleted" placeholder="Отчество" pattern='[-a-zA-Zа-яА-ЯёЁ ]*'></kendo-textbox>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['middleName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                      </kendo-formfield>

                      <kendo-formfield>
                        <kendo-textbox formControlName="maidenName" class="input-main-fio" size="small" [clearButton]="true" [disabled]="deleted" placeholder="{{maidenNamePlaceholder}}" pattern='[-a-zA-Zа-яА-ЯёЁ ]*'></kendo-textbox>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['maidenName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <!--FIO Latin-->
                  <div *ngIf="!isHidden(enum.fio_latin)" class="content-row">
                    <div class="label-sticky-top">
                      <kendo-label [for]="lastNameLatin" text="ФИО (латиница)"></kendo-label>
                    </div>
                    <dd class="container-fio">

                      <div style="margin-bottom: 5px">
                        <kendo-formfield>
                          <kendo-textbox formControlName="lastNameLatin" class="input-main-fio" style="margin-right: 20px;" size="small" [clearButton]="true" [disabled]="deleted" placeholder="Last Name" #lastNameLatin pattern='[-a-zA-Z ]*'></kendo-textbox>
                          <kendo-formerror *ngIf="formStudPerson.controls?.['lastNameLatin']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                        </kendo-formfield>
                      </div>

                      <kendo-formfield>
                        <kendo-textbox formControlName="firstNameLatin" class="input-main-fio" style="margin-right: 20px;" size="small" [clearButton]="true" [disabled]="deleted" placeholder="First Name" pattern='[-a-zA-Z ]*'></kendo-textbox>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['firstNameLatin']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                      </kendo-formfield>

                      <div class="line-break"></div>

                      <kendo-formfield>
                        <kendo-textbox formControlName="middleNameLatin" class="input-main-fio" size="small" [clearButton]="true" [disabled]="deleted" placeholder="Middle Name" pattern='[-a-zA-Z ]*'></kendo-textbox>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['middleNameLatin']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <!--Gender -->
                  <div *ngIf="!isHidden(enum.gender)" class="content-row">
                    <kendo-label text="Пол"></kendo-label>
                    <dd *ngIf="deleted">{{(contingent.studPersons.isMale == true ? "Мужской" : "Женский")}}</dd>
                    <dd *ngIf="!deleted">
                      <kendo-formfield>
                        <input type="radio" name="isMale" [value]="true" [style.margin-left.px]="0" formControlName="isMale" required (change)="onChangeMale()" kendoRadioButton />
                        <!--<label class="k-label" style="margin-right:15px" [for]="male">Мужской</label> -->
                      </kendo-formfield>
                      Мужской
                      <kendo-formfield>
                        <input type="radio" name="isMale" [value]="false" formControlName="isMale" required (change)="onChangeMale()" kendoRadioButton />
                        <!-- <label class="k-label" [for]="female">Женский</label> -->
                      </kendo-formfield>
                      Женский
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.citizenship)" class="content-row">
                    <kendo-label [for]="citizenshipId" text="Гражданство"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="citizenships"
                                        class="input-main-addition-data"
                                        textField="country"
                                        valueField="citizenshipExternalId"
                                        [valuePrimitive]="true"
                                        #citizenshipId
                                        [disabled]="deleted"
                                        [openOnFocus]="citizenshipId"
                                        [kendoDropDownFilter]="filterSettings"
                                        size="small"
                                        (valueChange)="citizenshipValueChange($event)"
                                        placeholder="Гражданство..."
                                        [formControl]="$any(formStudPerson).controls['citizenshipId']"
                                        formControlName="citizenshipId"
                                        required>
                        </kendo-combobox>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.oksm)" class="content-row">
                    <kendo-label text="Код ОКСМ"></kendo-label>
                    <dd>{{oksm}}</dd>
                  </div>

                </dl>
              </form>

              <dl class="editForm">
                <div *ngIf="!isHidden(enum.compatriot)" class="content-row">
                  <kendo-label [for]="isCompatriot" text="Соотечественник"></kendo-label>
                  <dd>
                    <input type="checkbox" kendoCheckBox [(ngModel)]="isCompatriotId" #isCompatriot name="compatriot" />
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.citizenship_type)" class="content-row">
                  <kendo-label text="Тип гражданства"></kendo-label>
                  <dd >
                    <kendo-combobox [data]="citizenshipTypes"
                                    class="input-main-addition-data"
                                    textField="citizenshipTypeName"
                                    valueField="dictCitizenshipTypeExternalId"
                                    [valuePrimitive]="true"
                                    [disabled]="deleted"
                                    [openOnFocus]="citizenshipType"
                                    #citizenshipType
                                    name='citizenshipType'
                                    [kendoDropDownFilter]="filterSettings"
                                    [(ngModel)]="citizenshipTypeId">
                    </kendo-combobox>
                  </dd>
                </div>
              </dl>

              <form class="k-form" [formGroup]="formStudPerson">
                <dl class="editForm">
                  <!--СНИЛС-->
                  <div *ngIf="!isHidden(enum.snils)" class="content-row">
                    <kendo-label [for]="snils" text="СНИЛС"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-maskedtextbox class="input-main-addition-data"
                                             [mask]="mask" size="small"
                                             [disabled]="deleted"
                                             #snils
                                             formControlName="snils"
                                             [required]="snilsRequired">
                        </kendo-maskedtextbox>
                        <span class="required" *ngIf="snilsRequired">*</span>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['snils']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
                        <kendo-formerror *ngIf="formStudPerson.controls?.['snils']?.errors?.['notCorrect']">Контрольная сумма не верна. Измените введённое значение</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>
                  <!--ИНН-->
                  <div *ngIf="!isHidden(enum.inn)" class="content-row">
                    <kendo-label [for]="inn" text="ИНН"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-textbox formControlName="inn" class="input-main-addition-data" size="small" #inn [maxlength]=12 [disabled]="deleted" [clearButton]="true" placeholder="ИНН"></kendo-textbox>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <!--Дата рождения-->
                  <div *ngIf="!isHidden(enum.birthdate)" class="content-row">
                    <kendo-label [for]="birthday" text="Дата рождения"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-datepicker format="dd.MM.yyyy" #birthday class="input-main-addition-data" [openOnFocus]="birthday" (focus)="onFocus()" [disabled]="deleted" formControlName="birthday" required>
                        </kendo-datepicker>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>
              <!--
            <form class="k-form" [formGroup]="formIdentification">
              <dl class="editForm">
                Место рождения
                <kendo-label [for]="birthPlace" text="Место рождения"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="birthPlace"
                      [style.width.px]="250"
                      size="small"
                      [clearButton]="true"
                      placeholder="Место рождения"
                      #birthPlace
                    ></kendo-textbox>
                  </kendo-formfield>
                </dd>
              </dl>
            </form>-->

              <dl class="other">
                <!--Семейное положение-->
                <div *ngIf="!isHidden(enum.marital_status)" class="content-row">
                  <kendo-label [for]="marital" text="Семейное положение"></kendo-label>
                  <dd class="ddinline">
                    <div>
                      <kendo-dropdownlist [data]="listFamilyState"
                                          textField="maritalStatus"
                                          valueField="maritalStatus"
                                          [valuePrimitive]="true"
                                          #marital
                                          class="input-main-addition-data"
                                          [disabled]="deleted"
                                          [(ngModel)]="familyStatusId">
                      </kendo-dropdownlist>
                    </div>
                  </dd>
                </div>
              </dl>

              <dl class="other">
                <h5>Информация об обучении</h5>
                <dd></dd>
              </dl>

              <form class="k-form" [formGroup]="formStudPerson">
                <dl class="editForm">
                  <div *ngIf="!isHidden(enum.id)" class="content-row">
                    <kendo-label text="Идентификатор студента" *ngIf="isAdmin"></kendo-label>
                    <dd *ngIf="isAdmin">
                      <kendo-formfield>
                        <kendo-textbox
                          formControlName="login"
                          class="input-main-addition-data"
                          size="small"
                          required
                        ></kendo-textbox>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formStudent">
                <dl class="editForm">
                  <!--Номер зачетной книжки-->
                  <div *ngIf="!isHidden(enum.student_number)" class="content-row">
                    <kendo-label [for]="studentNumber" text="Номер зачётной книжки"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-textbox formControlName="studentNumber" class="input-main-addition-data" size="small" [disabled]="deleted" [clearButton]="true" placeholder="Номер зачётной книжки" #studentNumber></kendo-textbox>
                        <!--<kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>-->
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formBudgetState">
                <dl class="editForm">
                  <div *ngIf="!isHidden(enum.budget)" class="content-row">
                    <kendo-label class="label" text="Источник финансирования"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-dropdownlist [data]="budgets"
                                            class="input-main-addition-data"
                                            textField="studentBudgetName"
                                            valueField="dictStudentBudgetExternalId"
                                            (selectionChange)="onBudgetChange($event)"
                                            [defaultItem]="defaultItemBudget"
                                            [valuePrimitive]="true"
                                            [disabled]="deleted"
                                            #dictStudentBudgetId
                                            [formControl]="$any(formBudgetState).controls['dictStudentBudgetId']"
                                            formControlName="dictStudentBudgetId"
                                            required>
                        </kendo-dropdownlist>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.budget_category) && selectedBudgetCategories.length !== 0" class="content-row">
                    <kendo-label class="budget_label" [for]="dictStudentBudgetId" text="Категория финансирования"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="selectedBudgetCategories"
                                        class="input-main-addition-data"
                                        textField="categoryName"
                                        valueField="dictStudentBudgetCategoryExternalId"
                                        (valueChange)="onBudgetCategoryChange($event)"
                                        [valuePrimitive]="true"
                                        #dictStudentBudgetId
                                        [formControl]="$any(formBudgetState).controls['dictStudentBudgetCategoryId']"
                                        formControlName="dictStudentBudgetCategoryId">
                        </kendo-combobox>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.budget_sub_category) && selectedBudgetSubCategories.length !== 0" class="content-row">
                    <kendo-label class="budget_label" [for]="dictStudentBudgetId" text="Подкатегория финансирования"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="selectedBudgetSubCategories"
                                        class="input-main-addition-data"
                                        textField="budgetSubCategoryName"
                                        valueField="dictStudentBudgetSubCategoryExternalId"
                                        [valuePrimitive]="true"
                                        #dictStudentBudgetId
                                        [formControl]="$any(formBudgetState).controls['dictStudentBudgetSubCategoryId']"
                                        formControlName="dictStudentBudgetSubCategoryId">
                        </kendo-combobox>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formBudgetState">
                <dl class="editForm" [hidden]="!isBudgetOrganizationSelected">
                  <!--Заказчик целевого обучения-->
                  <div *ngIf="!isHidden(enum.customer_targeted_training)" class="content-row">
                    <kendo-label [for]="dictOrganizationId" class="label" text="Заказчик целевого обучения"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="targetOrganizations"
                                        [kendoDropDownFilter]="filterSettings"
                                        class="input-main-addition-data"
                                        [disabled]="!isBudgetOrganizationSelected || deleted"
                                        textField="organizationName"
                                        valueField="dictOrganizationExternalId"
                                        [valuePrimitive]="true"
                                        #dictOrganizationId
                                        [openOnFocus]="dictOrganizationId"
                                        placeholder="Заказчик целевого обучения..."
                                        [formControl]="$any(formBudgetState).controls['dictOrganizationId']"
                                        formControlName="dictOrganizationId">
                        </kendo-combobox>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <!--Работодатель-->
                  <div *ngIf="!isHidden(enum.employer)" class="content-row">
                    <kendo-label [for]="dictOrganizationEmployerId" text="Работодатель"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="organizations"
                                        [kendoDropDownFilter]="filterSettings"
                                        class="input-main-addition-data"
                                        textField="organizationName"
                                        valueField="dictOrganizationExternalId"
                                        [valuePrimitive]="true"
                                        [disabled]="!isBudgetOrganizationSelected || deleted"
                                        [openOnFocus]="dictOrganizationEmployerId"
                                        placeholder="Работодатель..."
                                        #dictOrganizationEmployerId
                                        [formControl]="$any(formBudgetState).controls['dictOrganizationEmployerId']"
                                        formControlName="dictOrganizationEmployerId">
                        </kendo-combobox>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
                <dl class="editForm" [hidden]="isBudgetSelected != false">
                  <!--Договор на платное обучение-->
                  <div *ngIf="!isHidden(enum.paid_training_agreement)" class="content-row">
                    <kendo-label [for]="paidContractNum" class="budget_label" text="Договор на платное обучение"></kendo-label>
                    <dd class="paid">
                      <kendo-formfield>
                        <kendo-textbox
                          [formControl]="$any(formBudgetState).controls['paidContractNum']"
                          class="input-main-addition-data input-paid"
                          size="small"
                          [disabled]="isBudgetSelected || !contingent.editable"
                          [style.margin-left.px]="-1"
                          placeholder="№"
                          #paidContractNum
                        ></kendo-textbox>
                      </kendo-formfield>
                      <kendo-formfield>
                        <kendo-datepicker
                          format="dd.MM.yyyy"
                          #paidContractDate
                          [disabled]="isBudgetSelected||!contingent.editable"
                          class="input-main-addition-data input-paid"
                          [openOnFocus]="paidContractDate"
                          [formControl]="$any(formBudgetState).controls['paidContractDate']">
                        </kendo-datepicker>
                      </kendo-formfield>
                      <kendo-formfield>
                        <kendo-numerictextbox
                          [formControl]="$any(formBudgetState).controls['paidContractCostFull']"
                          [decimals]="decimals"
                          [maxlength]="maxlength"
                          [format]="format"
                          class="input-main-addition-data input-paid"
                          size="small"
                          [disabled]="isBudgetSelected||!contingent.editable"
                          placeholder="Стоим. в год, руб."
                        ></kendo-numerictextbox>
                      </kendo-formfield>
                      <kendo-formfield>
                        <kendo-numerictextbox
                          [formControl]="$any(formBudgetState).controls['paidContractCostYear']"
                          [decimals]="decimals"
                          [format]="format"
                          [maxlength]="maxlength"
                          class="input-main-addition-data input-paid paid-cost"
                          size="small"
                          [disabled]="isBudgetSelected||!contingent.editable"
                          placeholder="Стоим. в сем., руб."
                        ></kendo-numerictextbox>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formEduGroup">
                <dl class="editForm">
                  <!--Факультет-->
                  <div *ngIf="!isHidden(enum.faculty)" class="content-row">
                    <div class="label-sticky-top">
                      <kendo-label [for]="dictDepartmentId" text="Институт/факультет"></kendo-label>
                    </div>
                    <dd>
                      <kendo-formfield>
                        <kendo-dropdownlist [data]="faculties"
                                            class="input-main-addition-data"
                                            textField="shortName"
                                            valueField="facultyExternalId"
                                            [valuePrimitive]="true"
                                            #dictDepartmentId
                                            [disabled]="deleted"
                                            (valueChange)="onDepartmentChange($event)"
                                            [formControl]="$any(formEduGroup).controls['facultyId']"
                                            [defaultItem]="defaultItemFaculty"
                                            formControlName="facultyId"
                                            required>
                        </kendo-dropdownlist>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <!--Курс-->
                  <div *ngIf="!isHidden(enum.course)" class="content-row">
                    <div class="label-sticky-top">
                      <kendo-label [for]="course" text="Курс"></kendo-label>
                    </div>
                    <dd>
                      <kendo-formfield>
                        <kendo-dropdownlist [data]="courses"
                                            class="input-main-addition-data"
                                            textField="course"
                                            valueField="courseNum"
                                            [valuePrimitive]="true"
                                            #course
                                            [disabled]="deleted"
                                            [defaultItem]="defaultItemCourse"
                                            (valueChange)="onCourseChange($event)"
                                            [formControl]="$any(formEduGroup).controls['courseNum']"
                                            formControlName="courseNum"
                                            required>
                        </kendo-dropdownlist>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formStudEduGroup">
                <dl class="editForm">
                  <!--Группа-->
                  <div *ngIf="!isHidden(enum.group)" class="content-row">
                    <div class="label-sticky-top">
                      <kendo-label [for]="groupe" text="Группа"></kendo-label>
                    </div>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="courseSelectEduGroup"
                                        class="input-main-addition-data"
                                        textField="groupName"
                                        valueField="eduGroupExternalId"
                                        [valuePrimitive]="true"
                                        [kendoDropDownFilter]="filterSettings"
                                        #groupe
                                        [disabled]="deleted"
                                        (valueChange)="onGroupChange($event)"
                                        [(ngModel)]="eduGroupId"
                                        formControlName="eduGroupId"
                                        required>
                        </kendo-combobox>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formStudPlan">
                <dl class="editForm">
                  <!--Направление подготовки-->
                  <div *ngIf="!isHidden(enum.education_standard)" class="content-row">
                    <kendo-label text="Направление подготовки"></kendo-label>
                    <dd class="main-info-label">
                      {{(educationPlan?.educationProgram == null ? "" : ("("+educationPlan?.educationProgram?.educationStandard?.cipher+") " + educationPlan?.educationProgram?.educationStandard?.name))}}
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.study_form)" class="content-row">
                    <kendo-label text="Форма обучения"></kendo-label>
                    <dd class="main-info-label">
                      {{(educationPlan?.dictStudyFormId == null ? "" : nameStudyForm(educationPlan?.dictStudyFormId))}}
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.training_level)" class="content-row">
                    <kendo-label text="Уровень подготовки"></kendo-label>
                    <dd class="main-info-label">{{((educationPlan?.educationProgram == null ? "-" : nameTrainingLevel(educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId)))}}</dd>
                  </div>

                  <div *ngIf="!isHidden(enum.department)" class="content-row">
                    <kendo-label text="Кафедра"></kendo-label>
                    <dd class="main-info-label">
                      {{(educationPlan?.educationProgram?.graduatingDepartmentId == null ? "" : nameDepartment(educationPlan?.educationProgram?.graduatingDepartmentId))}}
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.education_plan)" class="content-row">
                    <div class="label-sticky-top">
                      <kendo-label [for]="plan" text="Учебный план"></kendo-label>
                    </div>
                    <dd>
                      <kendo-formfield>
                        <kendo-dropdownlist [data]="educationPlanList"
                                            class="input-main-addition-data"
                                            textField="name"
                                            [disabled]="deleted"
                                            valueField="planId"
                                            [valuePrimitive]="true"
                                            (valueChange)="onEducationPlanChange($event)"
                                            #plan
                                            [formControl]="$any(formStudPlan).controls['planId']"
                                            formControlName="planId"
                                            required>
                        </kendo-dropdownlist>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.education_program)" class="content-row">
                    <kendo-label text="Профиль (программа)"></kendo-label>
                    <dd> {{contingent.studPlan == null ? "-" :nameEducationProgram(contingent.studPlan.planId
                      ? contingent.studPlan.planId
                      : contingent.eduGroup?.planId)}}
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formStudPlan">
                <dl class="editForm">
                  <div *ngIf="!isHidden(enum.scientific_adviser_tutor)" class="content-row">
                    <kendo-label [for]="scientificPersonId" text="Научный руководитель"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="persons"
                                        [openOnFocus]="scientificPersonId"
                                        textField="fio"
                                        class="input-main-addition-data"
                                        valueField="personExternalId"
                                        [valuePrimitive]="true"
                                        [disabled]="!contingent.editable"
                                        [kendoDropDownFilter]="filterSettings"
                                        #scientificPersonId
                                        [formControl]="$any(formStudPlan).controls['scientificPersonId']"
                                        formControlName="scientificPersonId">
                        </kendo-combobox>
                      </kendo-formfield>
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.pedagogical_head)" class="content-row">
                    <kendo-label [for]="practicePersonId" text="Руководитель педагогической практики"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-combobox [data]="persons"
                                        textField="fio"
                                        class="input-main-addition-data"
                                        valueField="personExternalId"
                                        [openOnFocus]="practicePersonId"
                                        [valuePrimitive]="true"
                                        [disabled]="!contingent.editable"
                                        [kendoDropDownFilter]="filterSettings"
                                        #practicePersonId
                                        [formControl]="$any(formStudPlan).controls['practicePersonId']"
                                        formControlName="practicePersonId">
                        </kendo-combobox>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <form class="k-form" [formGroup]="formAcademicState">
                <dl class="editForm">
                  <div *ngIf="!isHidden(enum.academic_state)" class="content-row">
                    <div class="label-sticky-top">
                      <kendo-label text="Академический статус"></kendo-label>
                    </div>
                    <dd *ngIf="deleted" style="padding-top: 6px">{{(contingent.studentAcademicState == null ? "-" : nameAcademicStatus(contingent.studentAcademicState!.dictStudentAcademicStateId))}}</dd>
                    <dd *ngIf="!deleted">
                      <kendo-formfield>
                        <kendo-dropdownlist [data]="viewDictStudentAcademicStates"
                                            class="input-main-addition-data"
                                            textField="academicStateName"
                                            valueField="dictStudentAcademicStateExternalId"
                                            [valuePrimitive]="true"
                                            [disabled]="deleted"
                                            [defaultItem]="defaultItemStatus"
                                            [formControl]="$any(formAcademicState).controls['dictStudentAcademicStateId']"
                                            (valueChange)="onAcademicStateChange($event)"
                                            formControlName="dictStudentAcademicStateId"
                                            required>
                        </kendo-dropdownlist>
                        <span class="required">*</span>
                        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </form>

              <div class="k-form">
                <dl class="editForm">
                  <div *ngIf="!isHidden(enum.languages)  && isHidden(enum.languages_level)" class="content-row">
                    <kendo-label text="Изучаемые языки"></kendo-label>
                    <dd class="langdd">
                      <kendo-multiselect [(ngModel)]="selectLanguages" [data]="dictLanguages" textField="languageName" valueField="dictLanguageExternalId" class="input-main-addition-data" [disabled]="deleted" #multiselect (valueChange)="valueChange($event)">
                      </kendo-multiselect>
                      <span class="k-button-icon k-icon k-i-arrow-s" style="right: 22px; cursor: pointer;" (click)="openMultiSelect()" (mousedown)="$event.preventDefault()" *ngIf="!hideArrow"></span>
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.languages) && !isHidden(enum.languages_level)" class="content-row">
                    <kendo-label text="Изучаемые языки"></kendo-label>
                    <dd class="languages">
                      <kendo-label text="Изучаемый язык"></kendo-label>
                      <kendo-label class="languages-level" text="Степень знания"></kendo-label>
                    </dd>
                    <dd>
                    </dd>
                    <ng-container class="ddinline" *ngFor="let item of languages!;let i=index">
                      <dd class="dd-languages">
                        <kendo-combobox [data]="dictLanguages"
                                        textField="languageName"
                                        valueField="dictLanguageExternalId"
                                        class="languages-textbox"
                                        #languagesOpen
                                        [openOnFocus]="languagesOpen"
                                        [clearButton]=false
                                        [(ngModel)]="languages[i].dictLanguageId"
                                        [kendoDropDownFilter]="filterSettings"
                                        [valuePrimitive]="true">
                        </kendo-combobox>

                        <kendo-combobox [data]="languageLevels"
                                        textField="levelName"
                                        valueField="dictLanguageLevelExternalId"
                                        class="languages-textbox level"
                                        #languagesLevelOpen
                                        [openOnFocus]="languagesLevelOpen"
                                        [disabled]="languages[i].dictLanguageId === undefined"
                                        [(ngModel)]="languages[i].dictLanguageLevelId"
                                        [kendoDropDownFilter]="filterSettings"
                                        [valuePrimitive]="true">
                        </kendo-combobox>
                        <button kendoButton
                                icon="delete"
                                class="languages-button"
                                fillMode="flat"
                                size="small"
                                rounded="full"
                                (click)="removeLanguages(i)">
                        </button>
                      </dd>
                      <dd></dd>
                    </ng-container>

                    <dd>
                      <button kendoButton
                              size="small"
                              (click)="addLanguages()">
                        Добавить
                      </button>
                    </dd>
                  </div>

                  <div *ngIf="!isHidden(enum.external_id)" class="content-row">
                    <kendo-label text="Внутренний номер"></kendo-label>
                    <dd>{{(contingent.studentId)}}</dd>
                  </div>

                  <div *ngIf="!isHidden(enum.elder)" class="content-row">
                    <kendo-label text="Староста"></kendo-label>
                    <dd>{{(contingent.elder ? "Да" : "Нет")}}</dd>
                  </div>
                </dl>
              </div>

              <dl class="editForm">
                <h5>Прочее</h5>
                <dd></dd>
                <div *ngIf="!isHidden(enum.benefit)" class="content-row">
                  <kendo-label class="label" text="Льготы"></kendo-label>
                  <dd class="ddinline">
                    <div>
                      <kendo-combobox
                        [kendoDropDownFilter]="filterSettings"
                        class="input-main-addition-data"
                        [data]="benefits"
                        textField="benefitName"
                        valueField="dictBenefitExternalId"
                        [valuePrimitive]="true"
                        [disabled]="deleted"
                        name="dictBenefitId"
                        [(ngModel)]="benefitId">
                      </kendo-combobox>
                    </div>
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.health)" class="content-row">
                  <kendo-label [for]="health" class="label" text="Группа здоровья"></kendo-label>
                  <dd class="ddinline">
                    <div>
                      <kendo-dropdownlist class="input-main-addition-data" [data]="healths" #health textField="healthName" valueField="dictHealthExternalId" [valuePrimitive]="true" [disabled]="deleted" name="healthId" [(ngModel)]="healthId">
                      </kendo-dropdownlist>
                    </div>
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.sport_type)" class="content-row">
                  <kendo-label [for]="sportTypeId" text="Вид спорта"></kendo-label>
                  <dd class="ddinline">
                    <div>
                      <kendo-combobox [data]="sportTypes" class="input-main-addition-data" textField="sportTypeName" valueField="dictSportTypeExternalId" [valuePrimitive]="true" #sportType [openOnFocus]="sportType" [disabled]="deleted" [kendoDropDownFilter]="filterSettings" size="small" [(ngModel)]="sportTypeId">
                      </kendo-combobox>
                    </div>
                  </dd>
                </div>

                <div *ngIf="!isHidden(enum.sport_category)" class="content-row">
                  <kendo-label class="label" text="Спортивный разряд"></kendo-label>
                  <dd class="ddinline">
                    <div>
                      <kendo-dropdownlist class="input-main-addition-data" [data]="sportCategories" textField="sportCategoryName" valueField="dictSportCategoryExternalId" [disabled]="deleted" [valuePrimitive]="true" name="sportCategoryId" [(ngModel)]="sportCategoryId">
                      </kendo-dropdownlist>
                    </div>
                  </dd>
                </div>
              </dl>

            </div>
          </ng-template>

          <div class="functionButtons" *ngIf="editMode">

            <button kendoButton size="small" *ngIf="editMode" (click)="close()">
              Отмена
            </button>

            <button kendoButton size="small" themeColor="primary" *ngIf="editMode" [disabled]="deleted" (click)="updateStudent()">
              Сохранить
            </button>

          </div>

        </kendo-gridlayout-item>

        <!--Column for avatar-->
        <kendo-gridlayout-item [col]="1" [row]="1" [colSpan]="1" class="box rect">

          <div class="left-column">
            <kendo-avatar width="200px" height="200px" [imageSrc]="thumbnailSrc" rounded="none" style="margin: 0 0 30px 0"></kendo-avatar>
            <h3 style="text-align: center;">
              {{
              (contingent.studPersons.lastName == null ? "" : contingent.studPersons.lastName) + " " +
              (contingent.studPersons.firstName == null ? "" : contingent.studPersons.firstName) + " " +
              (contingent.studPersons.middleName == null ? "" : contingent.studPersons.middleName)
              }}
            </h3>
          </div>

          <div class="tilesselectedfilters tabs">
            <button kendoButton size="small"
                    *ngFor="let button of buttons"
                    [disabled]="!!button.disable"
                    [themeColor]="button.color"
                    [style]="button.style"
                    (click)="onSelectTabs(button.value)"
                    [hidden]="isHidden(button.display)">
              {{button.titleSecondLine ? button.titleFirstLine : button.title}}
              <br>{{button.titleSecondLine}}
            </button>
          </div>

        </kendo-gridlayout-item>

      </kendo-gridlayout>
    </div>
  </div>

  <kendo-tabstrip *ngIf="!editMode" (tabSelect)="onSelect($event)" style="position: relative; z-index: 0; outline: 0">
    <kendo-tabstrip-tab [title]="tab" *ngFor="let tab of tabs; let i = index" [selected]="i === 0">
      <ng-template kendoTabContent>
        <router-outlet></router-outlet>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

  <kendo-dialog
    title="Пожалуйста, подтвердите"
    *ngIf="planChangedModal"
    (close)="closePlanChange()"
    [minWidth]="250"
    [width]="450">
    <p class="confirmationText">
      Сменить учебные планы у студентов на учебный план выбранной группы?<br>
      ВНИМАНИЕ! При смене учебного плана у студентов, сменивших план, необходимо перезачесть оценки!
    </p>
    <p class="confirmationText red-notification" *ngIf="studyFormChanged">
      Форма обучения студента не соответствует форме обучения группы
    </p>
    <kendo-dialog-actions>
      <button kendoButton (click)="changePlan(false)">Не менять учебный план студентам</button>
      <button kendoButton (click)="changePlan(true)" themeColor="success">
        Сменить учебный план студентам
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>

</div>

import { Component, OnInit } from '@angular/core';
import { dataIcon} from "@progress/kendo-svg-icons";
import { Router} from "@angular/router";
import { AlertAnnouncement } from 'src/app/models/announcement/alert.model';
import { AlertService } from 'src/app/services/announcement/alert.service';
import { AlertStatistics } from 'src/app/models/announcement/alert-statistics.model';
import { AlertStatisticsService } from 'src/app/services/announcement/alert-statistics.service';
import { SearchStudentsService } from 'src/app/services/announcement/search-students.service';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { FoundStudentsService} from 'src/app/services/announcement/found-students.service';
import { FoundPersonsService} from 'src/app/services/announcement/found-persons.service';
import { AnnouncementUserAccessService } from '../../../services/useraccess/announcement-user-access.service';
import { AnnouncementUserAccess } from '../../../models/useraccess/announcement/announcementuseraccess.model';
import { PagerModule, PageChangeEvent, PagerType } from "@progress/kendo-angular-pager";
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import { checkRole } from '../../../helpers/token/role-check';

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit{
    public expandedState = false;
    public press: boolean = true;
    public presstatus: boolean = true;

    public alerts: AlertAnnouncement["messages"] = [];
    public alert:  Array<any> = [];
    public messageCount: number = 0;
    public total: AlertStatistics["total"] = [] ;
    public firstParametr: AlertStatistics["firstParametr"] = [] ;
    public calculatedParametr: AlertStatistics["calculatedParametr"] = [] ;

    public skip = 0;
    public pageSize = 10;
    public pageSizes = false;
    public info = false;
    public isAdmin: boolean = false;
    public personId: string = "";
    public accessLevel: boolean = false;
    public accesses: AnnouncementUserAccess[] = [];

    constructor(
        private router: Router,
        private alertService: AlertService,
        private alertStatisticsServise: AlertStatisticsService,
        private searchStudentsService: SearchStudentsService,
        private jwtHelper: JwtHelperService,
        private tokenStore: TokenStorageService,
        private announcementService: AnnouncementService,
        private foundpersonsService: FoundPersonsService,
        private foundStudentsService: FoundStudentsService,
        private userAccessService: AnnouncementUserAccessService,
    )
    {
        this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
    }

    public getAlert() {
        this.alertService.getAlert()
        .subscribe(
            response => {
                this.alert = response["messages"];
                this.messageCount = response["messageCount"];

                for (let i = 0; i < this.alerts.length; i++) {
                this.alert.push(this.alerts[i]);  
                }
            }
        );
    }
        
    public getAlertStatistics() {
        this.alertStatisticsServise.getAlertStatistics()
        .subscribe(response => {
            this.total = response["total"];
            this.firstParametr = response["firstParametr"];
            this.calculatedParametr = response["calculatedParametr"];
        });
    }

    public page: number = 1;
    public type: number = 2;
    public status: number = 0;
    public query: string = "";
    public tag: string = "";
    public start: Date | undefined;
    public finish: Date | undefined;

    public notpress(){
        this.press = false;
    }

    public notpresstatus(){
        this.presstatus = false;
    }

public clearAllFilters(){
    this.page = 1;
    this.skip = 0;
    this.press = true;
    this.presstatus = true;
    this.type = 2;
    this.status = 0;
    this.query = "";
    this.tag = "";
    this.start = undefined;
    this.finish = undefined;
    this.getAlert();
}

public startdate: string = "";
public finishdate: string = "";
public authorFio: string = "";

public findMessage(page?: number){
    
    if (this.start != undefined ){
        this.startdate =  this.start.toISOString();
    }
    if (this.finish != undefined ){
        this.finishdate =  this.finish.toISOString();
    }
    if(page != undefined){
        this.page = page;
    }
    this.alertService.getSearchMessages(this.page, this.type, this.status, this.query, this.tag, this.startdate, this.finishdate)
        .subscribe({next:(data:any) => {
        if (data !== null){
          this.alerts = data["messages"];
          this.alert = [];
          
          for (let i = 0; i < this.alerts.length; i++) {
          this.alert.push(this.alerts[i]);  
          }
          this.authorFio = this.alert[0].authorFio;
          this.messageCount = data["messageCount"];

          for (let element of this.alert){
            let str = "";
            if (element.content !== null){
                while (element.content.indexOf('<img') !== -1){
                    let i1 = element.content.indexOf('<img');
                    let i2 = element.content.indexOf('>', i1);
                    if (i1 !== -1) {
                        str = element.content.slice(0, i1) + '[ Фотография ]'+ element.content.slice(i2 + 1);
                        element.content = str;
                    }
                }
            }
          }

          for (let element of this.alert){
            let str = "";
            if (element.content !== null){
                while (element.content.indexOf('<table') !== -1){
                    let i1 = element.content.indexOf('<table');
                    let i2 = element.content.indexOf('/table>', i1);
                    if (i1 !== -1) {
                        str = element.content.slice(0, i1) + '[ Таблица ]' + element.content.slice(i2 + 7);
                        element.content = str;
                    }
                }
            }
          }

        }
        else {
            this.page = 1;
            this.alert = [];
            this.messageCount = 0;
        }
        this.skip = (this.page -1) * this.pageSize;
    }});
}

public createAnnouncement(){
    this.searchStudentsService.SearchParams("", 0, []);
    this.announcementService.currentAnnouncement.next({
        deliveries: [],
        announcement: [],
    });

    this.foundpersonsService.getSelectPersons([], []);
    this.foundStudentsService.getSelectStudents([], []);
    this.foundStudentsService.getDistributionStudents([]);
    this.foundpersonsService.getDistributionPersons([]);

    this.announcementService.SendPattern.next({
        messageId: 0,
        pattern  : false,
      });

    this.router.navigate(['author/modify']);
}

public onPageChange(e: any): void {
    this.skip = e.skip;
    //this.pageSize = e.take;
    this.page = (e.skip + this.pageSize) / this.pageSize;
    this.findMessage();
}

public getAccess (){
    this.userAccessService.getAccess(this.personId)
        .subscribe(res => {
                this.accesses = res;
            }
        );
    this.accessLevel = this.accesses.length > 0 ? this.accesses[0].userAccessLevel : false; 
}

ngOnInit() {
    this.findMessage();
    this.getAlertStatistics();
    this.getAccess();
    }
}

<kendo-grid
  [data]="group"
  class="table"
  scrollable="virtual"
  [loading]="isLoading"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="table__loader">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column
    title="№"
    [width]="50"
    [class]="'table__cell'"
    [headerClass]="'table__header'"
  >
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">{{
      rowIndex + 1
    }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="ФИО"
    [width]="522"
    [class]="'table__cell table__cell--text table__cell--fio'"
    [headerClass]="'table__header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <p (click)="navigateTo(dataItem.studentId)">
        {{ dataItem.fio }}
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    title="№ зачетной книжки"
    field="studentNumber"
    [class]="'table__cell table__cell--text table__cell--studentNumber'"
    [headerClass]="'table__header'"
    [width]="110"
  ></kendo-grid-column>
  <ng-template ngFor let-attestation [ngForOf]="dictAttestations">
    <kendo-grid-column-group
      *ngIf="toggleStep === attestation.id || toggleStep === null"
      title="{{ attestation.name }}"
      [headerClass]="'table__header table__header--subheader'"
    >
      <kendo-grid-column
        title="Баллы"
        [width]="130"
        [class]="'table__cell'"
        [headerClass]="'table__header table__header--subheader'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-numerictextbox
              [disabled]="!editable"
              [maxlength]="2"
              [max]="50"
              [min]="0"
              [decimals]="0"
              format="false"
              [(ngModel)]="dataItem.ballsByAttestation[attestation.id].ball"
              class="text-center"
              (blur)="saveInfo(dataItem.studentId, attestation.id)"
            ></kendo-numerictextbox>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="Пропущенные
      часы"
        [width]="130"
        [class]="'table__cell'"
        [headerClass]="'table__header table__header--subheader'"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="table__cell table__cell--custom table__cell--input">
            <kendo-numerictextbox
              [disabled]="!editable"
              [(ngModel)]="
                dataItem.ballsByAttestation[attestation.id].hoursAbsent
              "
              class="text-center"
              [decimals]="0"
              format="false"
              (blur)="saveInfo(dataItem.studentId, attestation.id)"
            ></kendo-numerictextbox>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>
  </ng-template>
</kendo-grid>
<div class="buttons">
  <button kendoButton (click)="closeTable()" class="buttons__actionButton">Закрыть</button>
</div>
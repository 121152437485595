<dicts-header></dicts-header>
<h1>Дисциплина</h1>

<div class="grid-container">
  <!--Grid-->
  <kendo-grid [kendoGridBinding]="disciplines" (remove)="removeHandler($event)"
              
              [pageable]="{
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: positionPager
              }"
              [pageSize]="20"
              (pageChange)="pageChange($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [filterable]="true" [filter]="filter" (filterChange)="filterChange($event)"
              (dataStateChange)="onStateChange($event)"
              [loading]="loading" [skip]="skip">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid&&formGroup?.get('disciplineType')!=undefined"
                themeColor="primary"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictDisciplineId" title="Id"> </kendo-grid-column> -->
    <kendo-grid-column field="disciplineName"
                       title="Название дисциплины"
                       [width]="270"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.disciplineName}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="disciplineSName"
                       title="Сокр. название"
                       [width]="170"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.disciplineSName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dictDisciplineTypeExternalId" title="Тип дисциплины"
                       [width]="170"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="disciplinetypes"
          textField="name"
          valueField="dictDisciplineTypeExternalId"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ namedisciplinetype(dataItem.disciplineType)?.name }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="disciplinetypes"
                            textField="name"
                            valueField="dictDisciplineTypeExternalId"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('disciplineType')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Использование" [width]="60" headerClass="gridHeader dictionaries" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          <div class="tooltip k-icon k-i-info k-icon-md" (click)="moveToInfo(dataItem.dictDisciplineExternalId)">
        </div>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" title="" [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

    
  </kendo-grid>

</div>

<div kendoDialogContainer></div>

<dicts-header></dicts-header>
<h1>Оценки</h1>

<div class="grid-container">
  <!--Grid-->
  <kendo-grid [data]="gridView" (remove)="removeHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column field="markName"
                       title="Название оценки"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.markName}}
                        </span>
                      </ng-template>
                      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-textbox
                          [clearButton]="true"
                          [formControl]="formGroup.get('markName')"
                        >
                        </kendo-textbox>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="markSName"
                       title="Короткое название оценки"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.markSName}}
                        </span>
                      </ng-template>
                      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-textbox
                          [clearButton]="true"
                          [formControl]="formGroup.get('markSName')"
                        >
                        </kendo-textbox>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="markValue"
                       title="Значение оценки"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{ dataItem.markValue }}
                        </span>
                      </ng-template>
                       <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox 
                            [formControl]="formGroup.get('markValue')"
                            [decimals]="0"
                            format="n0"
                            >
                        </kendo-numerictextbox>
                       </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="orderNumber"
                       title="Порядковый номер"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{ dataItem.orderNumber }}
                        </span>
                      </ng-template>
                       <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox 
                            [formControl]="formGroup.get('orderNumber')"
                            [decimals]="0"
                            [min]="1"
                            format="n0"
                            >
                        </kendo-numerictextbox>
                       </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="markColor" title="Цвет оценки" [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="k-display-flex k-justify-content-center">
            <div class="color-block" [style.background]="dataItem.markColor"></div>
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-colorpicker [formControl]="formGroup.get('markColor')" format="hex">
          </kendo-colorpicker>
        </ng-template>
      </kendo-grid-column>

    <kendo-grid-column field="markIcon"
                       title="Иконка оценки"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{ dataItem.markIcon }}
                        </span>
                       </ng-template>
                       <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-textbox
                          [maxlength]="10"
                          [clearButton]="true"
                          [formControl]="formGroup.get('markIcon')"
                        >
                        </kendo-textbox>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>
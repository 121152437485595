import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {UserSignatureBrief} from "../../../models/oferta/usersignature/userSignatureBrief.model";
import {UserSignatureService} from "../../../services/oferta/user-signature.service";
import {CreateQuery} from "../../../helpers/createQuery-helper";
import {UserSignaturesQuery} from "../../../models/oferta/usersignature/query/userSignatures.model";
import {UserSignature} from "../../../models/oferta/usersignature/userSignature.model";
import {DateFromUTCAsLocal, DateToString, TimeZoneFix} from "../../../helpers/date-helper";
import {DictSignatureTypeService} from "../../../services/oferta/dictSignatureType.service";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {UserSignaturesCommand} from "../../../models/oferta/usersignature/command/UserSignaturesCommand";
import {DictRegulationType} from "../../../models/oferta/dicts/dictRegulationType.model";
import {AccessRights} from "../../../models/oferta/enums/accessRights.enum";
import {OfertaUserAccessService} from "../../../services/useraccess/oferta-user-access.service";

@Component({
  selector: 'app-editusersignature',
  templateUrl: './editusersignature.component.html',
  styleUrls: ['./editusersignature.component.scss']
})
export class EditusersignatureComponent implements OnInit {

  protected editable = false;
  private userSignatureQuery!: UserSignaturesQuery;
  protected loading = false;
  protected userMayBeBlocked = false;

  public tomorrow = DateFromUTCAsLocal(new Date());
  public skip = 0;
  public user!: UserSignatureBrief;
  public userTypeName: string = "";
  public userSignatures: UserSignature[] = [];
  public regulationtypes: DictRegulationType[] = [];
  private editedRowIndex: number | undefined;
  private isLine = false;

  private isNew = false;
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  public position: "top" | "bottom" | "both" = "bottom";

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private userSignatureService: UserSignatureService,
    private dictSignatureTypeService: DictSignatureTypeService,
    private notificationService: NotificationsService,
    private userAccessService: OfertaUserAccessService,
  ) { }

  ngOnInit(): void {
    this.getAccess();
    const raw = JSON.parse(localStorage.getItem('user') || '{}');
    this.user = new UserSignatureBrief(raw.userId, raw.fio, raw.userTypeEnum, raw.departmentName);
    this.userTypeName = JSON.parse(localStorage.getItem('user_type_name')!)
    this.getAllUserSignatures();
    this.getAllsignatureTypes();
    this.tomorrow.setDate(this.tomorrow.getDate()+1)
  }

  private calculateUserMayBeBlockedStatus() {
    this.userMayBeBlocked = this.userSignatures.some(signature => signature.dateUntilBlocking !== null || signature.confirmedDate !== null || signature.usageConfirmedDate !== null);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: number | undefined = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private MapFormGroupToUpdateCommand(userSignature: UserSignature): UserSignaturesCommand {
    let command : UserSignaturesCommand = {
      userId: this.user.id,
      userTypeEnum: this.user.userTypeEnum,
      dictRegulationTypeId: userSignature.dictRegulationTypeId,
      usageConfirmedDate: userSignature.usageConfirmedDate?.toISOString(),
      dateUntilBlocking: userSignature.dateUntilBlocking?.toISOString(),
      confirmedDate: userSignature.confirmedDate?.toISOString()
    }
    return command;
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Update UserSignature
      if (this.formGroup !== undefined) {
        this.userSignatureService.editUserSignature(this.MapFormGroupToUpdateCommand(this.formGroup.value))
          .subscribe({
            next:() => {
              this.getAllUserSignatures();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError("Не удалось изменить запись");
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  private getAllUserSignatures(){

    this.userSignatureQuery = {
      userId: this.user.id,
      userTypeEnum: this.user.userTypeEnum
    }

    const query = CreateQuery(this.userSignatureQuery);

    this.userSignatureService.getUserSignatures(query)
      .subscribe(
        response => {
          this.userSignatures = response;
          this.calculateUserMayBeBlockedStatus();
          this.userSignatures.map((item) => {
            item.joinDate = new Date(item.joinDate);
            item.confirmedDate = item.confirmedDate != null ? new Date(item.confirmedDate) : DateFromUTCAsLocal(item.confirmedDate);
            item.usageConfirmedDate = item.usageConfirmedDate != null ? new Date(item.usageConfirmedDate) : DateFromUTCAsLocal(item.usageConfirmedDate);
            item.dateUntilBlocking = item.dateUntilBlocking != null ? new Date(item.dateUntilBlocking) : DateFromUTCAsLocal(item.dateUntilBlocking);
          })
        }
      );
  }

  public toLocalDatetime(dt: Date) {
    return dt != undefined ? DateToString(dt,'dd.MM.yyyy HH:mm') : '-';
  }

  public calculateDateUntilBlocking(endDate: Date | null) {
    const startDate = new Date(); // Текущее время

    if (!endDate) {
      return '0';
    }

    // Преобразуем строки в объекты Date, если они еще не являются объектами Date
    const endDateObj = typeof endDate === 'string' ? new Date(Date.parse(endDate)) : endDate;

    const differenceInTime = endDateObj.getTime() - startDate.getTime();
    const millisecondsInADay = 1000 * 3600 * 24;
    const formattedEndDate = `(${DateToString(endDateObj, 'dd.MM.yyyy HH:mm')})`;

    // Если разница во времени отрицательная, возвращаем 0
    if (differenceInTime < 0) {
      return `0 ${formattedEndDate}`;
    }

    // Если разница во времени меньше 24 часов (в миллисекундах), возвращаем '<1'
    if (differenceInTime < millisecondsInADay) {
      return `< 1 ${formattedEndDate}`;
    }

    // Если разница во времени больше или равна 24 часам, возвращаем количество дней
    const differenceInDays = Math.ceil(differenceInTime / millisecondsInADay);
    return `${differenceInDays.toString()} ${formattedEndDate}`;
  }

  public nameDict(regulationId?: string | undefined) {
    return this.regulationtypes.find((x) => x.id === regulationId)?.name ?? "";
  }

  public getAllsignatureTypes() {
    this.dictSignatureTypeService.getAllRegulationTypes()
      .subscribe(
        response => {
          this.regulationtypes = response;
        }
      );
  };

  private getAccess() {
    this.userAccessService.getCurrentUserAccess().subscribe((response) => {
      this.editable = response?.userAccessRight.userSignature === AccessRights.Write
    });
  }
}

const formGroup = (dataItem: UserSignature) =>
  new FormGroup({
    dictRegulationTypeId: new FormControl(dataItem.dictRegulationTypeId),
    joinDate: new FormControl(dataItem.joinDate),
    confirmedDate: new FormControl(dataItem.confirmedDate),
    usageConfirmedDate: new FormControl(dataItem.usageConfirmedDate),
    dateUntilBlocking: new FormControl(dataItem.dateUntilBlocking),
  });



import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/mfc/user.model';
import {map, tap} from 'rxjs/operators';
import {catchError} from 'rxjs';
import {handleError} from '../../helpers/errorHandle-helper';
import {NotificationsService} from '../notifications/notifications.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.user}`;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationsService
  ) { }

  public getAllUsers() {
    return this.http.get<User[]>(this.baseUrl).pipe(
      map((data) => data
        ? data.map(user =>
          ({...user, fio: `${user.lastName} ${user.firstName}${user.middleName ? ` ${user.middleName}` : ''}`}))
        : []));
  }

  public delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`)
      .pipe(
        tap(() => this.notificationService.showSuccess('Удалён')),
        catchError(err => {
          this.notificationService.showError(err);
          return handleError(err);
        })
      );
  }
}

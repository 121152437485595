import {Component, OnInit} from "@angular/core";
import {pagerSettings} from 'src/app/models/oferta/pagerSettings.model';
import {UserSignatureService} from "../../../services/oferta/user-signature.service";
import {UserSignatureBrief} from "../../../models/oferta/usersignature/userSignatureBrief.model";
import {UserTypeEnum} from "../../../models/oferta/usersignature/userType.model";
import {CellClickEvent, RowClassArgs} from "@progress/kendo-angular-grid";
import {Router} from "@angular/router";
import {UserStatus} from "../../../models/oferta/enums/UserStatus";
import {UserStatusModel} from "../../../models/oferta/usersignature/userStatus.model";

@Component({
  selector   : 'oferta-home',
  templateUrl: './oferta-home.component.html',
  styleUrls  : ['./oferta-home.component.scss']
})
export class OfertaHomeComponent  implements OnInit {

  public componentName: string = 'ofertaHomePage'
  public gridStyle: string = 'grid-default';
  protected editable = true;
  protected loading = false;
  public skip = 0;
  protected pageSize = 10;
  protected readonly pagerSettings = pagerSettings;

  public userSignaturesBrief: UserSignatureBrief[] = [];
  public userTypeEnums: UserTypeEnum[] = [];

  public status: UserStatusModel[] = [
    {id: 1, status: UserStatus.Active, name: "Активен"},
    {id: 2, status: UserStatus.Blocked, name: "Заблокирован"}
  ];
  public StateEnum = UserStatus;

  constructor(
    private userSignatureService: UserSignatureService,
    private router: Router
  ) { }

  public nameEnum(enums?: number) {
    return this.userTypeEnums.find((x) => x.value === enums)?.name ?? "";
  }

  private getAllUserSignatures() {
    this.userSignatureService.getAllUserSignatures()
      .subscribe(
        response => {
          this.userSignaturesBrief = response;
        }
      );
  }

  private getUserTypeCategories() {
    this.userSignatureService.getUserTypeCategories()
      .subscribe(
        (response) => {
          this.userTypeEnums = response;
        }
      );
  }

  ngOnInit(): void {
    this.getAllUserSignatures();
    this.getUserTypeCategories();
  }

  public moveToInfo({dataItem} : CellClickEvent) {
    localStorage.setItem('user', JSON.stringify({
      userId : dataItem.userId,
      fio: dataItem.fio,
      userTypeEnum: dataItem.userTypeEnum,
      departmentName: dataItem.departmentName
    }));
    localStorage.setItem('user_type_name', JSON.stringify(this.nameEnum(dataItem.userTypeEnum)));
    this.router.navigate(['oferta/usersignature/info', dataItem.userId]);
  }

  // Change status cell color depending on value
  public rowClassCallback = (context: RowClassArgs) => {
    switch (context.dataItem.status) {
      case UserStatus.Active:
        return {green: true};
      case UserStatus.Blocked:
        return {red: true};
      default:
        return {};
    }
  }
}

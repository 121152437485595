import {Component, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import {UntypedFormControl, UntypedFormGroup, UntypedFormBuilder} from "@angular/forms";
import { EditEvent, GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { StudpersonService } from 'src/app/services/contingent/studperson.service';
import { StudentService } from 'src/app/services/contingent/student.service';
import * as moment from 'moment';
import { Student } from 'src/app/models/contingent/student.model';
import { DictFilial } from 'src/app/models/contingent/dictfilial.model';
import { StudPerson } from 'src/app/models/contingent/studperson.model';
import { Citizenship } from 'src/app/models/contingent/citizenship.model';
import { ContingentService } from 'src/app/services/contingent/contingent.service';
import {
  Contingent,
  ContingentRequest,
  Filter,
  Page
} from 'src/app/models/contingent/contingent.model';
import { CitizenshipService } from 'src/app/services/contingent/citizenship.service';
import { Identifications } from 'src/app/models/contingent/identifications.model';
import { Budget } from 'src/app/models/contingent/budget.model';
import { BudgetSubCategory } from 'src/app/models/contingent/budgetsubcategory.model';
import { BudgetService } from 'src/app/services/contingent/budget.service';
import { BudgetsubcategoryService } from 'src/app/services/contingent/budgetsubcategory.service';
import { StudentBudgetState } from 'src/app/models/contingent/studentbudgetstate.model';
import { StudentAcademicState } from 'src/app/models/contingent/studentacademicstate.model';
import { AcademicState } from 'src/app/models/contingent/academicstate.model';
import { AcademicStateService } from 'src/app/services/contingent/academicstate.service';
import {ContingentOrganization} from 'src/app/models/contingent/organization.model';
import { Languages } from 'src/app/models/contingent/languages.model';
import { DictLanguages } from 'src/app/models/contingent/dictlanguages.model';
import { LanguageLevels } from 'src/app/models/contingent/languagelevels.model';
import { DictLanguagesService } from 'src/app/services/contingent/dictlanguages.service';
import { DepartmentName, Faculty } from 'src/app/models/contingent/departmentname.model';
import { DepartmentService } from 'src/app/services/contingent/department.service';
import { EduGroup } from 'src/app/models/contingent/edugroup.model';
import { EdugroupService } from 'src/app/services/contingent/edugroup.service';
import { StudEduGroup } from 'src/app/models/contingent/studedugroup.model';
import { Person } from 'src/app/models/contingent/person.model';
import { PersonService } from 'src/app/services/contingent/person.service';
import {EducationPlan, GroupEducationPlanList} from 'src/app/models/contingent/educationplan.model';
import { EducationProgram } from 'src/app/models/contingent/educationprogram.model';
import { DictStudyForm } from 'src/app/models/contingent/dictstudyform.model';
import { EducationService } from 'src/app/services/contingent/education.service';
import { Guid } from 'guid-typescript';
import { SortDescriptor } from '@progress/kendo-data-query';
import {environment} from "../../../../environments/environment";
import {StudentSemestr} from "../../../models/contingent/studentsemestr.model";
import {StudPlan} from "../../../models/contingent/studplan.model";
import {TrainingLevel} from "../../../models/dicts/traininglevel.model";
import {CommondictService} from "../../../services/contingent/commondict.service";
import {DictmaritalstatusService} from "../../../services/contingent/dictmaritalstatus.service";
import {DictMaritalStatus} from "../../../models/contingent/maritalstatus.model";
import {DateFromUTCAsLocal} from "../../../helpers/date-helper";
import {CitizenshipEnum} from "../../../models/contingent/enums/citizenship.enum";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {ContingentOrganizationService} from "../../../services/contingent/contingentOrganization.service";
import {validateSnils} from "../../../helpers/snils-helper";
import {StudentcarddisplaysettingService} from "../../../services/contingent/studentcarddisplaysetting.service";
import {DisplaySettingEnum} from "../../../models/contingent/enums/display-setting.enum";
import {CitizenshiptypeService} from "../../../services/contingent/citizenshiptype.service";
import {CitizenshipType} from "../../../models/contingent/citizenshiptype.model";
import {BudgetcategoryService} from "../../../services/contingent/budgetcategory.service";
import {BudgetCategory} from "../../../models/contingent/budgetcategory.model";
import {ContingentsettingService} from "../../../services/contingent/contingentsetting.service";
import {ContingentSettingModel} from "../../../models/contingent/contingentsetting.model";
import {ContingentSettingCategoryEnum} from "../../../models/contingent/enums/contingent-setting-category.enum";
import {ContingentSettingEnum} from "../../../models/contingent/enums/contingent-setting.enum";
import {LanguagelevelsService} from "../../../services/contingent/languagelevels.service";
import { DictFilialService } from '../../../services/contingent/dictfilial.service';

@Component({
  selector: 'app-addstudent',
  templateUrl: './addstudent.component.html',
  styleUrls: ['./addstudent.component.scss']
})
export class AddstudentComponent implements OnInit {

  @ViewChild("multiselect", { static: true }) public multiselect: any;

  constructor(private router: Router,
              private activateRoute: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private studPersonService: StudpersonService,
              private studService: StudentService,
              private contingentService: ContingentService,
              private citizenshipService: CitizenshipService,
              private budgetService: BudgetService,
              private budgetsubcategoryService:BudgetsubcategoryService,
              private budgetCategoryService: BudgetcategoryService,
              private notificationService: NotificationsService,
              private academicstateService: AcademicStateService,
              private organizationService: ContingentOrganizationService,
              private languagesService: DictLanguagesService,
              private languageLevelsService: LanguagelevelsService,
              private departmentService: DepartmentService,
              private edugroupService: EdugroupService,
              private personsService: PersonService,
              private educationPlanService: EducationService,
              private commondictService: CommondictService,
              private maritalStatusService: DictmaritalstatusService,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService,
              private displaySettingService: StudentcarddisplaysettingService,
              private citizenshipTypeService: CitizenshiptypeService,
              private contingentSettingService: ContingentsettingService,
              private dictFilialService: DictFilialService,)
  {
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  ngOnInit(): void {
    this.getAllFilials();
    this.getCitizenship();
    this.getContingentSetting();
    this.getAllCitizenshipType();
    this.getAllMaritalStatus()
    this.getBudget();
    this.getAllBudgetCategories();
    this.getBudgetSubCategory();
    this.getAllAcademicState();
    this.getOrganization();
    this.getTargetOrganization();
    this.getAllLanguages();
    this.getAllFaculty(1);
    this.getAllEduGroup(1);
    this.getAllPersons();
    this.getAllEducationPlan();
    this.getAllTrainingLevel();
    this.getAllStudyForm();
    this.getDisplaySettings()
    this.getAllLanguageLevels();
  }

  public enum = DisplaySettingEnum;
  public isAdmin: boolean = false;
  public addNewStudent: boolean = false;
  public thumbnailSrc: any;
  public isDisabledBudgetSub = true;
  public isDisabledCourse = true;
  public isDisabledGroup = true;
  public isDisabledPlan = true;
  public newStudPerson = false;
  public femaleStatus = false;
  public isNew = true;
  public studentGrid = false;
  public studentGridError = false;
  public familyStatusId?: string|undefined;
  public deleted = false;
  public snilsRequired = false;
  public budgetSubCategoryRequired = false;
  public maidenPlaceholder = environment.maidenName;
  public citizenshipEnum: any = CitizenshipEnum;
  public newLogin = ""
  public oksm = "-"
  public citizenshipTypes: CitizenshipType[] = [];
  public contingentSetting: ContingentSettingModel[] = [];

  public onestudent: Student = {
    studentId:0,
    studentNumber:'',
    dictFilialId:undefined,
    dictFilials:new DictFilial,
    studPersonId:undefined,
    studPersons: new StudPerson,
  }

  public citizenships: Citizenship[] = [];
  public citizenship: Citizenship = {
    citizenshipId: 0,
    country: '',
    countryPhoneCode: '',
    code:'',
    rCountry: '',
    dCountry: '',
    vCountry: '',
    tCountry: '',
    pCountry: '',
  }

  public persons: Person[] = [];
  public identifications: Identifications[] = [];
  public studPersons: StudPerson[] = [];
  public studPerson:StudPerson={
    studPersonId: 0,
    firstName: '',
    lastName: '',
    buhCode:'',
    isMale: false,
    birthday:new Date,
    citizenshipId: undefined,
    dictCitizenships: new Citizenship,
    login: '',
    lastEdited: new Date,
  }

  public pageSize = 10;
  public sizes = [10, 20, 50];
  public skip = 0;

  public page: Page = {
    count: 0,
    pageNumber: 0,
    pageSize: 0,
  }
  public sort?: SortDescriptor[] = [];
  public filter: Filter = {
    fio: ""
  }

  public contingentRequest: ContingentRequest = {
    filter: this.filter,
    page: this.page,
    sort: this.sort,
  }

  public budgets: Budget[]=[];
  public budget: Budget = {
    dictStudentBudgetId: 0,
    studentBudgetName: '',
    studentBudgetSName: '',
    isBudget: false,
    filialId: ''
  }
  public budgetCategories: BudgetCategory[] = [];
  public selectedBudgetCategories: BudgetCategory[] = [];
  public budgetsubcategories: BudgetSubCategory[] = [];
  public selectedbudgetsubcategories: BudgetSubCategory[] = [];
  public budgetsubcategory: BudgetSubCategory = {
    dictStudentBudgetSubCategoryId: 0,
    dictStudentBudget: this.budget,
    budgetSubCategoryName: '',
    filialId: ''
  }

  public organizations: ContingentOrganization[] = [];
  public targetOrganizations: ContingentOrganization[] = [];
  public organization: ContingentOrganization = {
    dictOrganizationId: 0,
    organizationName: '',
    ogrn: '',
    inn: '',
    kpp: '',
    isTarget: false,
    isWorkPlace: false,
  }

  public edugroups: EduGroup[] = [];
  public departselectedugroup: EduGroup[] = [];
  public courseselectedugroup: EduGroup[] = [];
  public edugroup: EduGroup = {
    eduGroupId: 0,
    groupName:"",
    courseNum: 0,
    dateBegin: new Date,
    dateEnd: new Date,
    facultyId: Guid.create(),
  }
  public settingCategory = ContingentSettingCategoryEnum;
  public setting = ContingentSettingEnum;
  public dictStudentAcademicStates: AcademicState[] = [];
  public dictStudentAcademicState: AcademicState = {
    dictStudentAcademicStateId: 0,
    dictStudentAcademicStateExternalId:Guid.createEmpty(),
    academicStateName: "",
    academicStateSName: ""
  }
  public studentAcademicState: StudentAcademicState = {
    studentAcademicStateId: 0,
    studentId: undefined,
    student: this.onestudent,
    dictStudentAcademicStateId: undefined,
    dictStudentAcademicStates: this.dictStudentAcademicState,
  }

  public languages: Languages[] = [];
  public selectLanguages: DictLanguages[] = [];

  public languagelevels: LanguageLevels[] = [];
  public departments: DepartmentName[] = [];
  public faculties: Faculty[] = [];

  public filials: DictFilial[] = []
  public filteredBudgets: Budget[] = [];
  public filteredFaculties: Faculty[] = [];
  public isFilialSelected: boolean = false;

  public dictlanguages: DictLanguages[] = [];
  public languageLevels: LanguageLevels[] = [];
  public contingent: Contingent={
    studentId: 0,
    studentNumber: "",
    studPersonId: 0,
    course: 0,
    groupName: "",
    studentBudget: "",
    ball: "",
    studPersons: new StudPerson,
    studentBudgetState: new StudentBudgetState,
    studentSemestr: new StudentSemestr,
    identifications: new Identifications,
    dictCitizenship: new Citizenship,
    studEduGroup: new StudEduGroup,
    studentAcademicState: new StudentAcademicState,
    languages: [],
    studPlan: new StudPlan,
    editable: true
  }

  public hideArrow = false;

  public openMultiSelect() {
    this.multiselect.toggle(true);
  }

  public valueChange(value: any): void {
    this.hideArrow = value.length != 0;
  }

  public citizenshipValueChange(value: any): void {
    this.oksm = this.citizenships.find((x) => x.citizenshipExternalId == value)?.code ?? "-"
    this.snilsRequired = this.oksm === this.citizenshipEnum.rf;

    if(!this.snilsRequired && this.contingentSetting.find((x) => x.category == this.settingCategory.login)?.value == this.setting.snils)
      this.getNewLogin(null, null);
    else if(this.formStudPerson.value.snils)
      this.snilsValueChange(this.formStudPerson.value.snils)
  }

  public snilsValueChange(value: any): void {
    if(this.snilsRequired && this.contingentSetting.find((x) => x.category == this.settingCategory.login)?.value == this.setting.snils)
      this.studPerson.login = value.replace(/,/g,/ /g, '');
  }

  ngAfterViewInit() {
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  public getNewStudentNumber(trainingLevel: string|undefined, faculty: string|null, yearAdmission: number|null) {
    this.studService.getNewStudentNumber(trainingLevel, faculty,
        yearAdmission?? (new Date()).getFullYear())
      .subscribe(
      response => {
        this.onestudent.studentNumber = response;
      }
    );
  }

  public getNewLogin(dictStudentBudgetId: string|null, yearAdmission: number|null) {
    this.studPersonService.getNewLogin(
      dictStudentBudgetId,
      yearAdmission ?? (new Date()).getFullYear()// - ((new Date()).getMonth() < 6 ? 1 : 0)
    ).subscribe(
      response => {
        this.newLogin = response;
        this.studPerson.login = response;
        if(this.contingentSetting.find((x) => x.category == this.settingCategory.studentNumber)?.value == this.setting.equal_login)
          this.onestudent.studentNumber = response;
      }
    );
  }

  public addStudPerson() {
    this.getAllFaculty(5)
    this.getAllEduGroup(5)
    this.newStudPerson=true;
  }

  public femaleStatusChange() {
    this.femaleStatus=true;
  }

  public getAllEducationPlan() {
    this.educationPlanService.getAllEducationPlans()
      .subscribe(
        response => {
          this.educationPlans = response;
        }
      );
  }

  /// <summary>
  /// Get data for dict
  /// </summary>
  //Citizenship
  public getCitizenship(): void {
    this.citizenshipService.getAllCitizenship()
      .subscribe(
        response => {
          this.citizenships = response;
          for(let i = 0; i < this.citizenships.length; i++){
            if(this.citizenships[i].country=="Российская Федерация"||this.citizenships[i].country=="Россия")
            {this.citizenships.splice(0, 0, this.citizenships.splice(i, 1)[0]);
              return}
          }
        }
      );
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public getAllCitizenshipType() {
    this.citizenshipTypeService.getAllCitizenshipType()
      .subscribe(
        response => {
          this.citizenshipTypes = response;
        }
      );
  }

  public getDisplaySettings() {
    this.displaySettingService.getStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettingService.displaySettings$.next(response);
        }
      );
  }

  public getContingentSetting() {
    this.contingentSettingService.getContingentSettings()
      .subscribe(
        response => {
          this.contingentSetting = response;

          if(this.contingentSetting.find((x) => x.category == this.settingCategory.login)?.value == this.setting.study_form)
            this.getNewLogin(null, null);
        }
      );
  }

  //Budget
  public getBudget(): void {
    this.budgetService.getAllBudget()
      .subscribe(
        response => {
          this.budgets = response;
          this.filteredBudgets = response;
        }
      );
  }

  public getAllBudgetCategories() {
    this.budgetCategoryService.getAllBudgetCategory()
      .subscribe(
        response => {
          this.budgetCategories = response;
        });
  }

  public getAllMaritalStatus() {
    this.maritalStatusService.getAllMaritalStatus()
      .subscribe(
        response => {
          this.listFamilyStateMale = response.filter((x)=>x.isMale === true).slice()
          this.listFamilyStateFemale = response.filter((x)=>x.isMale === false).slice()
          this.listFamilyState=this.listFamilyStateMale.slice()
        }
      );
  }
  //BudgetSubCategory
  public getBudgetSubCategory(): void {
    this.budgetsubcategoryService.getAllBudgetSubCategory()
      .subscribe(
        response => {
          this.budgetsubcategories = response;
        }
      );
  }
  //AcademicState
  getAllAcademicState() {
    this.academicstateService.getAllAcademicState()
      .subscribe(
        response => {
          this.dictStudentAcademicStates = response;

          //удаляем Академический статус Удален из возможных вариантов
          let index = this.dictStudentAcademicStates.findIndex((x)=>x.deleted===true)
          this.dictStudentAcademicStates.splice(index,1)
        }
      );
  }

  //Getting all entries from dictionary
  public getOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.organizations = response;
        }
      );
  }

  public getTargetOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.targetOrganizations = response.filter(org=>org.isTarget === true);
        }
      );
  }

  public getAllLanguages() {
    this.languagesService.getAllLanguages()
      .subscribe(
        response => {
          this.dictlanguages = response;
        }
      );
  }

  public getAllLanguageLevels() {
    this.languageLevelsService.getAlllanguagelevels()
      .subscribe(
        response => {
          this.languageLevels = response;
        }
      );
  }

  public getAllFaculty(option: number) {
    this.departmentService.getAllFaculty(option)
      .subscribe(
        response => {
          this.faculties = response;
          this.filteredFaculties = response;
        }
      );
  }

  public getDepartment(id: any) {
    this.departmentService.getDepartmentbyId(id)
      .subscribe(
        response => {
          this.department = response;
        }
      );
  }

  public getEducationPlan(id: any) {
    this.educationPlanService.getEducationPlans(id)
      .subscribe(
        response => {
          this.educationPlan = response;
          if(this.contingentSetting.find((x) => x.category == this.settingCategory.studentNumber)?.value == this.setting.residency)
            this.getNewStudentNumber(this.educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId, this.formEduGroup.value.facultyId, null);
          this.getDepartment(this.educationPlan?.educationProgram?.graduatingDepartmentId)
        }
      );
  }

  public addLanguages():void{
    this.languages!.push(new Languages());
  }

  public removeLanguages(i: number):void{
    if (i > -1) {
      this.languages!.splice(i, 1);
    }
  }

  //Persons
  public getAllPersons() {
    this.personsService.getAllperson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  public getAllEduGroup(option: number) {
    this.edugroupService.getAlledugroup(option)
      .subscribe(
        response => {
          this.edugroups = response;
        }
      );
  }
  public formGroup: UntypedFormGroup | undefined;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public studPersonExternalId!: Guid;
  public educationPlans: EducationPlan[] = [];
  public selectedEducationPlans: GroupEducationPlanList[] = [];
  public educationPrograms: EducationProgram[] = [];
  public educationPlan: EducationPlan|undefined={
    dictStudyForm: new DictStudyForm,
    yearAdmission:0,
    educationPlanId:0,
    developmentPeriod:"",
    acceleratedSign: true,
    dictAcceleratedStudyBaseId: 0,
  };

  public languagesNumber: Guid[] = [];

  public isHidden(field: number): boolean {
    return this.displaySettingService.isHidden(field);
  }

  /// <summary>
  /// Save Student
  /// </summary>
  saveStudent() {
    //Validate form
    if (!this.formStudPerson.valid || !this.formBudgetState.valid || !this.formEduGroup.valid ||
        !this.formStudEduGroup.valid || !this.formAcademicState.valid) {
      this.formStudPerson.markAllAsTouched();
      this.formEduGroup.markAllAsTouched();
      this.formBudgetState.markAllAsTouched();
      this.formEduGroup.markAllAsTouched();
      this.formStudEduGroup.markAllAsTouched();
      this.formAcademicState.markAllAsTouched();
      this.notificationService.showError("Не все обязательные поля заполнены");
      return;
    }

    if((this.snilsRequired || this.formStudPerson.value.snils) && !validateSnils(this.formStudPerson.value.snils)){
      const control = this.formStudPerson.controls['snils'];
      control.setErrors({notCorrect: true});
      this.formStudPerson.markAllAsTouched();
      return;
    }

    if(this.selectLanguages.length != 0)
      this.languages = this.selectLanguages.map(a => {return {dictLanguageId : a.dictLanguageExternalId}})

    this.languages = this.languages.filter(x => x.dictLanguageId)

    if(this.formStudPerson.value.snils && this.formStudPerson.value.snils.length === 11){
      this.formStudPerson.value.snils = this.formStudPerson.value.snils.replace(/(.{3})(.{3})(.{3})(.{2})/, '$1-$2-$3 $4')
    }

    //очистка доп. сведений по финансированию, если выбран другой пункт
    if(this.isBudgetSelected==true){
      this.formBudgetState.value.paidContractNum = null;
      this.formBudgetState.value.paidContractDate = null;
      this.formBudgetState.value.paidContractCostFull = null;
      this.formBudgetState.value.paidContractCostYear = null;
    }
    if(this.isBudgetOrganizationSelected==false){
      this.formBudgetState.value.dictOrganizationId = null;
      this.formBudgetState.value.dictOrganizationEmployerId = null;
    }

    this.formBudgetState.value.studentId = Guid.createEmpty().toString()
    this.formStudPlan.value.studentId = Guid.createEmpty().toString()

    if(!this.isNew) this.formStudPerson.value.studPersonId = this.studPersonExternalId
    this.formGroup = formGroup({
      students: this.formStudent.value,
      studPersons: this.formStudPerson.value,
      studentBudgetState: this.formBudgetState.value,
      studentAcademicState: this.formAcademicState.value,
      languages: this.languages,
      studEduGroup: this.formStudEduGroup.value,
      studPlan: this.formStudPlan.value,
      studPlanId: this.formStudPlan.value.studPlanId,
      studentId: this.contingent.studentId,
      studPersonId: this.isNew? null: this.contingent.studPersons.studPersonExternalId
    });

    if(this.familyStatusId !== this.contingent.familyState?.value){
      this.formGroup.value.familyStatus = this.familyStatusId;
      this.formGroup.value.familyStatusId = this.contingent.familyState?.additionalFieldExternalId;
    }
    if (this.formAdditionalField.value.isCompatriot  !== this.contingent.compatriot?.value) {
      this.formGroup.value.isCompatriot = this.formAdditionalField.value.isCompatriot  == true ? "true" : "false";
      this.formGroup.value.isCompatriotId = this.contingent.compatriot?.additionalFieldExternalId;
    }
    if (this.formAdditionalField.value.citizenshipTypeId !== this.contingent.citizenshipType?.value) {
      this.formGroup.value.citizenshipType = this.formAdditionalField.value.citizenshipTypeId;
      this.formGroup.value.citizenshipTypeId = this.contingent.citizenshipType?.additionalFieldExternalId;
    }

    this.timeZoneFix();
    //if(this.isNew){
      this.contingentService.addcontingent(this.formGroup.value)
        .subscribe({
          next: (response) => {
            this.notificationService.showSuccess("Добавлено");
            this.router.navigateByUrl(
              `/contingent/studentmaininfo/${response.studPlan.studentId}/personaldata/${response.studPersonId}`)
              .then(() => window.scroll(0, 0));
          },
          error:(error) => {
            this.notificationService.showError(error.error);
          }});
    /*}
    else{
      this.contingentService.updatecontingent(this.formGroup.value)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Сохранено");
            this.router.navigateByUrl(`/contingent`).then();
          },
          error:(error) => {
            this.notificationService.showError(error.error);
          }});
    }*/

    /*this.formStudPerson.reset();*/
    // this.router.navigateByUrl(`/home`);
  }



  public transitionHandler(args: EditEvent): void {

    this.studService.getByIdstudent(args.dataItem.studentExternalId!)
      .subscribe({
        next:(response) => {
          this.onestudent = response;
          this.onestudent.studentNumber = this.newLogin
          this.newStudPerson = true;
        },
        error:() => {
          this.notificationService.showError("Отстутсвуют права доступа к данному студенту");
        }}
      );

    this.contingentService.getByIdstudent(args.dataItem.studentExternalId!)
      .subscribe(
        response => {
          this.contingent = response;
          this.isNew=false
          if(!this.contingent.editable)
            this.getEducationPlan(this.contingent.studPlan?.planId
              ? this.contingent.studPlan.planId
              : this.contingent.eduGroup!.planId)
          this.citizenshipValueChange(this.contingent.studPersons.citizenshipId)
          if(this.snilsRequired && this.contingent.studPersons.snils && this.contingent.studPersons.snils.length !== 14)
            this.contingent.studPersons.snils = undefined
          //this.onestudent.studentNumber = '';
          this.deleted=this.contingent.deletedStudent!
          this.studPersonExternalId = response.studPersons.studPersonExternalId!;
          this.thumbnailSrc = `${environment.apiEndpoint}contingent/getphoto/${response.studPersons.studPersonExternalId}`

          this.familyStatusId = this.contingent.familyState?.value;

          this.edugroup = this.contingent.eduGroup!;
          //this.getEduGroupByFaculty(this.edugroup.facultyId)

          /*this.onDepartmentChange(this.contingent.studEduGroup.eduGroups!.facultyId);
          this.onCourseChange(this.contingent.studEduGroup.eduGroups!.courseNum);
          this.onGroupChange(this.contingent.studEduGroup.eduGroupId!);*/

          if(this.contingent.studPersons.isMale)this.listFamilyState=this.listFamilyStateMale
          else this.listFamilyState=this.listFamilyStateFemale

          if(this.contingent.compatriot || this.contingent.citizenshipType)
            this.formAdditionalField.reset(
              {
                      isCompatriot : this.contingent.compatriot?.value == "true",
                      citizenshipTypeId : this.contingent.citizenshipType?.value
                    });
          if(this.contingent.studPersons != null) {
            this.contingent.studPersons.studPersonId = this.contingent.studPersonId;
            this.contingent.studPersons.birthday = DateFromUTCAsLocal(this.contingent.studPersons.birthday)
            this.formStudPerson.reset(this.contingent.studPersons);
          }

          if(this.isHidden(this.enum.languages_level))
            this.selectLanguages = this.contingent.languages.map(a => {return {dictLanguageExternalId : a.dictLanguageId, languageName : a.languageName }})

          this.languages = this.contingent.languages;
          this.valueChange(this.languages)

          if(!this.contingent.editable) {
            if(this.contingent.studentAcademicState != null) this.formAcademicState.reset(this.contingent.studentAcademicState);
            if(this.contingent.studentBudgetState != null) this.formBudgetState.reset(this.contingent.studentBudgetState);
            if(this.onestudent != null) this.formStudent.reset(this.onestudent);

            if(this.contingent.studEduGroup != null) this.formStudEduGroup.reset(this.contingent.studEduGroup);
            if(this.contingent.eduGroup != null) this.formEduGroup.reset(this.contingent.eduGroup);
            if(this.contingent.studPlan != null) this.formStudPlan.reset(this.contingent.studPlan);
          }
        }
      );

    //this.contingentStudent = args.dataItem
    //this.contingentStudent.studPersons!.birthday = new Date(this.contingentStudent.studPersons!.birthday )
    //this.formStudPerson.reset(this.contingentStudent.studPersons)
  }

  public onChangeMale(): void {
    if(this.listFamilyState==this.listFamilyStateFemale)this.listFamilyState=this.listFamilyStateMale
    else this.listFamilyState=this.listFamilyStateFemale
  }

  public loading = false;
  public gridView!: GridDataResult;
  public listFamilyState: DictMaritalStatus[] = [];
  public listFamilyStateMale: DictMaritalStatus[] = [];
  public listFamilyStateFemale: DictMaritalStatus[] = [];

  public getContingentPage(page: number) {
    this.loading = true;
    this.page.pageNumber = page;
    this.page.pageSize = this.pageSize;
    this.contingentRequest.sort = this.sort;
    this.contingentRequest.filter = this.filter

    this.contingentService.searchStudents(this.contingentRequest)
      .subscribe({
        next:(response) => {
          this.addNewStudent = true
          //this.allContingentStudent = response.items;
          // this.count = response.count;
          if(response.items.length==0) {
            this.studentGrid=false
            this.studentGridError=true
          }
          else {
            this.studentGrid=true
            this.studentGridError=false
          }
          this.gridView = {
            data: response.items,
            total: response.count,
          };
          this.loading = false;
        },
        error:(error) => {
          console.log(error.error);
          this.notificationService.showError("Отсутствует подключение к серверу");
          this.loading = false;
        }});
  }

  public dataStateChange(state: SortDescriptor[]): void {
    this.sort = state;
    this.getContingentPage(1);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getContingentPage(event.skip / event.take + 1);
  }

  public isBudgetOrganizationSelected:boolean|null = null;
  public isBudgetSelected:boolean|null = null;

  public onBudgetChange(dataItem:any): void {
    this.isDisabledBudgetSub = false;
    this.selectedBudgetCategories = this.budgetCategories.filter((s) => s.dictStudentBudgetId == dataItem.dictStudentBudgetExternalId);
    this.isBudgetOrganizationSelected = dataItem.studentBudgetName && dataItem.studentBudgetName?.toLowerCase().includes("целев");
    this.isBudgetSelected = dataItem.isBudget;
    if (this.selectedbudgetsubcategories == undefined) this.isDisabledBudgetSub = true;
    this.formBudgetState.value.dictStudentBudgetCategoryId = undefined;
    this.formBudgetState.value.dictStudentBudgetSubCategoryId = undefined;
    this.selectedbudgetsubcategories = [];
    this.budgetStateReset();
    //this.getNewLogin(dataItem.dictStudentBudgetId, null);
  }

  public onBudgetCategoryChange(categoryId: string): void {
    this.selectedbudgetsubcategories = this.budgetsubcategories.filter((s) => s.dictStudentBudgetCategoryId === categoryId);
    this.formBudgetState.value.dictStudentBudgetSubCategoryId = undefined;
    this.budgetStateReset();
  }

  public budgetStateReset(){
    this.formBudgetState.reset(this.formBudgetState.value);
  }

  /// <summary>
  /// EduGroup
  /// </summary>
  public onDepartmentChange(id:Guid): void {
    this.departselectedugroup = this.edugroups.filter((s) => s.facultyId == id);
    this.isDisabledCourse = false;
    if(this.formEduGroup.value.courseNum != undefined)
      this.onCourseChange(this.formEduGroup.value.courseNum);
  }

  public onCourseChange(id: number): void {
    this.courseselectedugroup = this.departselectedugroup.filter((s) => s.courseNum == id);
    this.isDisabledGroup = false;
    this.formStudEduGroup.value.eduGroupId = undefined;
    this.formStudEduGroup.reset(this.formStudEduGroup.value);
    this.formStudPlan.reset();
    this.isDisabledPlan = true;
  }

  public onGroupChange(id: Guid): void {
    const idPlan =  this.courseselectedugroup.find((s) => s.eduGroupExternalId == id)?.planId;
    this.getEducationPlan(idPlan)

    this.getEducationPlanByGroup(id.toString());
    this.isDisabledPlan = false
  }

  public onEducationPlanChange(id: string): void {
    this.getEducationPlan(id);
  }

  public getEducationPlanByGroup(eduGroupId: string){
    this.educationPlanService.getEducationPlanByGroup(eduGroupId)
      .subscribe({
        next: (response) => {
          this.selectedEducationPlans = response;
        }}
      );
  }

  public onFilialChange(value: string): void {
    this.isFilialSelected = value !== undefined;
    this.filteredFaculties = this.faculties.filter((item: Faculty) => item.filialId == value);
    this.filteredBudgets = this.budgets.filter((item: Budget) => item.filialId == value);
    this.formBudgetState.reset();
    this.formEduGroup.reset();
    this.formStudPlan.reset();
  }

  public dictstudyforms: DictStudyForm[] = [];
  public traininglevels: TrainingLevel[]=[];
  public department: DepartmentName = {};

  public getAllTrainingLevel() {
    this.commondictService.getAllTrainingLevel()
      .subscribe(
        response => {
          this.traininglevels = response;
        }
      );
  }

  public getAllStudyForm() {
    this.commondictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictstudyforms = response;
        }
      );
  }

  public namestudyform(id: any): string {
    if(id != undefined){
      return this.dictstudyforms.find((x) => x.dictStudyFormExternalId === id )!.studyFormName
    }
    else return "-"
  }

  public nametraininglevel(id: any): string|undefined {
      return id ? this.traininglevels.find((x)=> x.dictTrainingLevelExternalId === id)?.trainingLevelName : "-"
  }

//Профиль
  public nameEducationProgram(id: string | undefined): string {
    if(id != undefined)
      return this.educationPlan?.educationProgram?.name!
    else return "-"
  }

//Кафедра
  public nameDepartment(id: string | undefined): string | undefined {
    if(id != undefined)
      return this.department?.name
    else return "-"
  }

  /// <summary>
  /// Time Zone Fix
  /// </summary>
  public timeZoneFix()
  {
    const utcDate1 = moment(this.formStudPerson.value.birthday).utc(true);
    this.formStudPerson.value.birthday = utcDate1;

    const utcDate2 = moment(this.formStudPerson.value.lastEdited).utc(true);
    this.formStudPerson.value.lastEdited = utcDate2;

    const utcDate8 = moment(this.formBudgetState.value.dateBegin).utc(true);
    this.formBudgetState.value.dateBegin = utcDate8;

    const utcDate9 = moment(this.formBudgetState.value.dateEnd).utc(true);
    this.formBudgetState.value.dateEnd = utcDate9;

    const utcDate10 = moment(this.formAcademicState.value.dateBegin).utc(true);
    this.formAcademicState.value.dateBegin = utcDate10;

    const utcDate11 = moment(this.formAcademicState.value.dateEnd).utc(true);
    this.formAcademicState.value.dateEnd = utcDate11;

    const utcDate12 = moment(this.formBudgetState.value.paidContractDate).utc(true);
    this.formBudgetState.value.paidContractDate = utcDate12;
  }

  /// <summary>
  /// Forms
  /// </summary>
  public form: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl(),
    middleName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    maidenName: new UntypedFormControl(),
    firstNameL: new UntypedFormControl(),
    middleNameL: new UntypedFormControl(),
    lastNameL: new UntypedFormControl(),
    gender: new UntypedFormControl(),
    studentN: new UntypedFormControl(),
    snils: new UntypedFormControl(),
    birthDay: new UntypedFormControl(),
    birthPlace: new UntypedFormControl(),
    marital: new UntypedFormControl(),
    citizenship: new UntypedFormControl(),
    compatriot: new UntypedFormControl(),
    typecitizenship: new UntypedFormControl(),
    dataBudget: new UntypedFormControl(),
    dataResultBudgetCategory: new UntypedFormControl(),
    dataResultOrders: new UntypedFormControl(),
    customer: new UntypedFormControl(),
    employer: new UntypedFormControl(),
    faculty: new UntypedFormControl(),
    course: new UntypedFormControl(),
    group: new UntypedFormControl(),
    direction: new UntypedFormControl(),
    scientificSupervisor: new UntypedFormControl(),
    headPedagogicalPractice: new UntypedFormControl(),
    department: new UntypedFormControl(),
    academicstatus: new UntypedFormControl(),
    languages: new UntypedFormControl(),
  });

  public formStudPerson: UntypedFormGroup = new UntypedFormGroup({
    studPersonId:  new UntypedFormControl(),
    firstName:  new UntypedFormControl(""),
    lastName:  new UntypedFormControl(""),
    fio:  new UntypedFormControl(""),
    middleName:  new UntypedFormControl(),
    maidenName: new UntypedFormControl(),
    buhCode: new UntypedFormControl(""),
    isMale:  new UntypedFormControl(true),
    birthday: new UntypedFormControl(""),
    citizenshipId:  new UntypedFormControl(),
    dictCitizenships:  new UntypedFormControl(),
    inn: new UntypedFormControl(),
    snils: new UntypedFormControl(),
    login:  new UntypedFormControl(""),
    lkPhoto: new UntypedFormControl(),
    firstNameLatin: new UntypedFormControl(),
    lastNameLatin: new UntypedFormControl(),
    middleNameLatin: new UntypedFormControl(),
    lastEdited: new UntypedFormControl(new Date()),
  });

  public formStudent: UntypedFormGroup = new UntypedFormGroup({
    studentId:new UntypedFormControl(),
    studentNumber:new UntypedFormControl(""),
    dictFilialId:new UntypedFormControl(),
    dictFilials:new UntypedFormControl(),
    studPersonId:new UntypedFormControl('00000000-0000-0000-0000-000000000000'),
    studPersons:new UntypedFormControl(),
  });

  public formBudgetState: UntypedFormGroup = new UntypedFormGroup({
    studentBudgetStateId: new UntypedFormControl(),
    studentId: new UntypedFormControl("00000000-0000-0000-0000-000000000000"),
    student: new UntypedFormControl(),
    dictStudentBudgetId: new UntypedFormControl(),
    dictStudentBudgets: new UntypedFormControl(),
    dateBegin: new UntypedFormControl(new Date),
    dateEnd: new UntypedFormControl(new Date),
    dictOrganizationId: new UntypedFormControl(),
    dictOrganizations: new UntypedFormControl(),
    dictStudentBudgetSubCategoryId: new UntypedFormControl(),
    dictStudentBudgetSubCategories: new UntypedFormControl(),
    dictStudentBudgetCategoryId: new UntypedFormControl(),
    dictOrganizationEmployerId: new UntypedFormControl(),
    dictOrganizationEmployers: new UntypedFormControl(),
    paidContractNum: new UntypedFormControl(),
    paidContractDate:  new UntypedFormControl(),
    paidContractCostFull:  new UntypedFormControl(),
    paidContractCostYear:  new UntypedFormControl(),
  });

  public formAcademicState: UntypedFormGroup = new UntypedFormGroup({
    studentAcademicStateId: new UntypedFormControl(),
    studentId: new UntypedFormControl("00000000-0000-0000-0000-000000000000"),
    student: new UntypedFormControl(),
    dictStudentAcademicStateId: new UntypedFormControl(),
    dictStudentAcademicStates: new UntypedFormControl(),
    dateBegin: new UntypedFormControl(new Date()),
    dateEnd: new UntypedFormControl(new Date()),
  });

  public formEduGroup: UntypedFormGroup = new UntypedFormGroup({
    eduGroupId: new UntypedFormControl(),
    groupName: new UntypedFormControl(""),
    courseNum: new UntypedFormControl(),
    dateBegin: new UntypedFormControl(new Date()),
    dateEnd: new UntypedFormControl(new Date()),
    facultyId: new UntypedFormControl(),
  });

  public formAdditionalField: UntypedFormGroup = new UntypedFormGroup({
    isCompatriot: new UntypedFormControl(),
    citizenshipTypeId: new UntypedFormControl(),
  });

  public formStudPlan: UntypedFormGroup = new UntypedFormGroup({
    studPlanId:new UntypedFormControl(),
    studentId:new UntypedFormControl("00000000-0000-0000-0000-000000000000"),
    student:new UntypedFormControl(),
    planId:new UntypedFormControl(),
    scientificPersonId:new UntypedFormControl(),
    scientificPerson:new UntypedFormControl(),
    departmentId:new UntypedFormControl(),
    dessertationTitle:new UntypedFormControl(),
    practicePersonId:new UntypedFormControl(),
    practicePerson:new UntypedFormControl(),
  });

  public formStudEduGroup: UntypedFormGroup = new UntypedFormGroup({
    studEduGroupId: new UntypedFormControl(),
    studentId: new UntypedFormControl("00000000-0000-0000-0000-000000000000"),
    eduGroupId: new UntypedFormControl(),
  });

  public mask = "000-000-000 00";
  public value = "74353264341";
  public courses: Array<{ course: string; courseNum: number }> = [
    { course: "0 Курс", courseNum: 0 },
    { course: "1 Курс", courseNum: 1 },
    { course: "2 Курс", courseNum: 2 },
    { course: "3 Курс", courseNum: 3 },
    { course: "4 Курс", courseNum: 4 },
    { course: "5 Курс", courseNum: 5 },
    { course: "6 Курс", courseNum: 6 },
  ];
  public allowCustom = true;

  public defaultItemfaculty: { shortName: string; facultyExternalId?: any } = {
    shortName: "Институт/факультет...",
    facultyExternalId: null,
  };

  public defaultItemBudget: { studentBudgetName: string; dictStudentBudgetId?: any } = {
    studentBudgetName: "Источник финансирования",
    dictStudentBudgetId: null,
  };
  public defaultItemCourse: { course: string; courseNum?: any } = {
    course: "Курс...",
    courseNum: null,
  };
  public defaultItemGroupe: { groupName: string; eduGroupId?: any } = {
    groupName: "Группа...",
    eduGroupId: null,
  };
  public defaultItemStatus: { academicStateName: string; dictStudentAcademicStateId?: any } = {
    academicStateName: "Академический статус...",
    dictStudentAcademicStateId: null,
  };
  public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
    filialSName: "Филиал...",
    dictFilialExternalId: null,
  };
  protected readonly DisplaySettingEnum = DisplaySettingEnum;
}

const formGroup = (dataItem: { students?: any; studPersons?: any;  studentBudgetState?: any;
  studentAcademicState?: any; languages?:any; studedugroup?:AnalyserNode; studPlan?:any; studPlanId?:any; isCompatriot?: string, isCompatriotId?: string,
  familyStatus?:any, familyStatusId?:any, studentId?:any, studEduGroup?:any, studPersonId?: any, citizenshipTypeId?: string, citizenshipType?: string }) =>
  new UntypedFormGroup({
    students: new UntypedFormControl(dataItem.students),
    studPersons: new UntypedFormControl(dataItem.studPersons),
    studentBudgetState: new UntypedFormControl(dataItem.studentBudgetState),
    studentAcademicState: new UntypedFormControl(dataItem.studentAcademicState),
    languages: new UntypedFormControl(dataItem.languages),
    studedugroup: new UntypedFormControl(dataItem.studedugroup),
    studPlan: new UntypedFormControl(dataItem.studPlan),
    studPlanId: new UntypedFormControl(dataItem.studPlanId),
    isCompatriot: new UntypedFormControl(dataItem.isCompatriot),
    isCompatriotId: new UntypedFormControl(dataItem.isCompatriotId),
    citizenshipTypeId: new UntypedFormControl(dataItem.citizenshipTypeId),
    citizenshipType: new UntypedFormControl(dataItem.citizenshipType),
    familyStatus: new UntypedFormControl(dataItem.familyStatus),
    familyStatusId: new UntypedFormControl(dataItem.familyStatusId),
    studentId: new UntypedFormControl(dataItem.studentId),
    studEduGroup: new UntypedFormControl(dataItem.studEduGroup),
    studPersonId: new UntypedFormControl(dataItem.studPersonId),
  });

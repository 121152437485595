export class StateExamCommissionModel
{
  id!: string;
  yearAdmission!: number;
  number!: string;
  date!: Date;
  stateExamCommissionTypeId!: string;
  stateExamCommissionTypeName!: string;
  filialId!: string;
  dictTrainingLevelId!: string;
  printTemplateId!: string;
  childId?: string;
  childNumber?: string;
  commissions!: CommissionModel[];
}

export class PreviousStateExamCommissionModel
{
  id!: string;
  numberAndDate!: string;
}

export class ShortListStateExamCommissionModel
{
  id!: string;
  numberAndDate!: string;
}

export class CommissionModel
{
  id!: string;
  studyForms?: SECStudyFormModel[];
  // studyForms?: any;
  // studyForms?: string[];
  // studyForms!: SECStudyFormModel[];
  members!: MemberModel[];
  standards!: StandardModel[];
  expelledMembers!: ExpelledMemberModel[];
}

export class SECStudyFormModel
{
  id?: string | null;
  stateExamCommissionStudyFormId!: string;
  name!: string;
}

export class MemberModel
{
  id!: string;
  fio!: string;
  fioAndRoleName!: string;
  post!: string;
  academicStatus!: string;
  personId!: string;
  workPlace!: number;
  dictRoleId!: string;
  fioInitialsAfter!: string;
  scientificDegreeName!: string;
  scientificDegreeShortName!: string;
}

export class StandardModel
{
  id!: string;
  eduPrograms!: SECEduProgramModel[];
  educationStandardId!: string;
}

export class SECEduProgramModel
{
  exId!: string;
  programName!: string;
  id?: string | null;
}

export class ExpelledMemberModel
{
  id!: string;
  stateExamCommissionMemberId!: string;
  reason?: string;
}

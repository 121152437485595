import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DictInstitution } from '../../models/contingent/dictinstitution.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DictinstitutionService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictinstitution}`;

  constructor(private http: HttpClient) { }

  //Get all state
  public getAllstate(): Observable<DictInstitution[]> {
    return this.http.get<DictInstitution[]>(this.baseUrl);
  }

  //Add state
  addstate(state: DictInstitution): Observable<DictInstitution> {
    return this.http.post<DictInstitution>(this.baseUrl, state)
      .pipe(catchError(this.handleError));
  }

  //Delete state
  deletestate(id: string): Observable<DictInstitution> {
    return this.http.delete<DictInstitution>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit state
  updatestate(state: DictInstitution): Observable<DictInstitution> {
    return this.http.put<DictInstitution>(this.baseUrl + '/' + state.dictInstitutionId, state)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

import {Component, OnInit} from '@angular/core';
import {pagerSettings} from 'src/app/models/mfc/pagerSettings.model';
import {
  Application,
  ApplicationOptions,
  Filter,
  Sort,
  sortModeValues
} from '../../../models/mfc/home/application.model';
import {
  CellClickEvent,
  GridComponent,
  GridDataResult,
  MultipleSortSettings,
  PageChangeEvent
} from '@progress/kendo-angular-grid';
import {ApplicationService} from '../../../services/mfc/application.service';
import {ApplicationStatusesEnum} from '../../../models/mfc/enums/application-statuses.enum';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import {Router} from '@angular/router';
import {ApplicationCategoryService} from '../../../services/mfc/dicts/application-category.service';
import {ApplicationTypeService} from '../../../services/mfc/dicts/application-type.service';
import {UserAccessService} from '../../../services/mfc/userAccess.service';
import {AccessRights} from '../../../models/mfc/enums/access-rights.enum';
import {TrainingLevelService} from '../../../services/mfc/dicts/training-level.service';
import {FilialTreeItem} from '../../../models/mfc/dicts/filial.model';
import {TrainingLevel} from '../../../models/mfc/dicts/training-level.model';
import {ApplicationCategory} from '../../../models/mfc/dicts/application-category.model';
import {ApplicationType} from '../../../models/mfc/dicts/application-type.model';
import {ApplicationStatusService} from '../../../services/mfc/dicts/application-status.service';
import {ApplicationStatus} from '../../../models/mfc/dicts/application-status.model';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {FacultyService} from '../../../services/mfc/dicts/faculty.service';
import {Faculty} from '../../../models/mfc/dicts/faculty.model';
import {ExcelExportData} from '@progress/kendo-angular-excel-export';
import {cellOptions, headerCellOptions} from '../../../models/common/export-excel-settings.model';
import {DateToString} from '../../../helpers/date-helper';


@Component({
  selector: 'mfc-home',
  templateUrl: './mfc-home.component.html',
  styleUrls: ['./mfc-home.component.scss']
})
export class MFCHomeComponent implements OnInit {

  protected editable = false;
  protected loading = false;
  protected loaderExcelExport = false;

  protected pageSize = 10;
  protected skip = 0;
  protected state: State = {
    skip: this.skip,
    take: this.pageSize,
  };
  protected sort: SortDescriptor[] = [
    {
      field: 'createdAt',
      dir: 'desc',
    },
  ];

  protected waitingOnly = false;
  protected options: ApplicationOptions = {
    page: {
      number: 1,
      size: this.pageSize
    },
    sortParameters: this.patchSort(this.sort[0]),
    filter: {
      applicationNumber: null,
      studentName: null,
      filials: null,
      faculties: null,
      trainingLevels: null,
      groupName: null,
      createdAt: null,
      applicationCategories: null,
      applicationTypes: null,
      applicationStatuses: null,
      applicationApprovalStatuses: null,
      statusUpdatedAt: null
    }
  };

  // TODO
  // protected sortSettings: MultipleSortSettings = {
  //   mode: 'multiple',
  //   initialDirection: 'desc',
  //   allowUnsort: true,
  //   showIndexes: true,
  // };

  protected filialTree: FilialTreeItem[] = [];
  protected faculties: Faculty[] = [];
  protected trainingLevels: TrainingLevel[] = [];
  protected applicationCategories: ApplicationCategory[] = [];
  protected applicationTypes: ApplicationType[] = [];
  protected filterApplicationTypes: ApplicationType[] = [];
  protected applicationStatuses: ApplicationStatus[] = [];

  protected totalCount = 0;
  protected gridData: GridDataResult = {
    data: [],
    total: this.totalCount
  };
  protected excelExportData: Application[] = [];

  protected readonly pagerSettings = pagerSettings;
  protected readonly StatusEnum = ApplicationStatusesEnum;
  protected readonly headerCellOptions = headerCellOptions;
  protected readonly cellOptions = cellOptions;
  //TODO: Убрать после подключения изменений на беке
  protected statusMap = new Map<ApplicationStatusesEnum, string>([]);

  constructor(
    private router: Router,
    private notificationService: NotificationsService,
    private applicationCategoryService: ApplicationCategoryService,
    private applicationTypeService: ApplicationTypeService,
    private trainingLevelService: TrainingLevelService,
    private facultyService: FacultyService,
    private applicationStatusService: ApplicationStatusService,
    private applicationService: ApplicationService,
    private userAccessService: UserAccessService,
  ) { }

  ngOnInit() {
    this.getDicts();
    this.getApplications();
    this.getAccess();
  }

  private getDicts() {
    this.facultyService.getFilialsAndFaculties().subscribe((response) => {
      this.filialTree = response;
      this.faculties = this.filterFaculties(response);
    });
    this.trainingLevelService.getTrainingLevels().subscribe((response) => this.trainingLevels = response);
    this.applicationCategoryService.getApplicationCategories().subscribe((response) =>
      this.applicationCategories = response);
    this.applicationTypeService.getApplicationTypes().subscribe((response) => {
      this.applicationTypes = response;
      this.filterApplicationTypes = response;
    });
    this.applicationStatusService.getAll().subscribe((response) => {
      this.applicationStatuses = response.filter(item =>
        item.applicationStatusEnum != ApplicationStatusesEnum.Draft
        && item.applicationStatusEnum != ApplicationStatusesEnum.Withdrawn);
      this.applicationStatuses.forEach((item) => {
        this.statusMap.set(item.applicationStatusEnum, item.name);
      });
    });
  }

  private getAccess() {
    const applicationAccess = this.userAccessService.currentUserAccess$.value?.user
      .userAccessMainSettings.applicationAccess;
    this.editable = applicationAccess === AccessRights.Write;
  }

  protected getApplications(toExcel: boolean = false, grid?: GridComponent) {
    if (toExcel) {
      this.loaderExcelExport = true;
      this.options = {...this.options, page: {number: 1, size: this.totalCount}};
    } else {
      this.loading = true;
    }

    this.applicationService.getApplications(this.options, this.waitingOnly).subscribe({
      next: (response) => {
        this.totalCount = response.totalApplicationCount;
        const data = {
          data: response.applications,
          total: this.totalCount
        };

        if (toExcel) {
          this.excelExportData = data.data;
          this.mapExportData = this.mapExportData.bind(this);
          grid?.saveAsExcel();
          this.loaderExcelExport = false;
        } else {
          this.gridData = data;
          this.loading = false;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.error.Message);
        toExcel ? this.loaderExcelExport = false : this.loading = false;
      }
    });
  }

  private filterFaculties(filials: FilialTreeItem[]) {
    return filials.map((item) => item.faculties).flat();
  }

  protected pageChange(event: PageChangeEvent) {
    this.state = {
      skip: event.skip,
      take: event.take
    }

    this.options.page.number = event.skip / event.take + 1;
    this.options.page.size = event.take;
    this.getApplications();
  }

  private patchSort(sort: SortDescriptor): Sort {
    return {
      columnName: `${sort.field[0].toUpperCase()}${sort.field.slice(1)}`,
      mode: sortModeValues.get(sort.dir ?? '')
    } as Sort;
  }

  protected sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.options.sortParameters = this.sort
      .map((item) => this.patchSort(item))
      .find((item) => !!item.mode) ?? null;
    this.getApplications();
  }

  protected filterChange(value: unknown, filter: keyof Filter) {
    this.options.filter[filter] = Array.isArray(value) && !value.length ? null : value;

    this.state = {
      skip: this.skip,
      take: this.pageSize
    }
    this.pageChange({skip: this.skip, take: this.pageSize});
  }

  protected filialChange(value: string[]) {
    if (value.length) {
      this.faculties = this.filterFaculties(this.filialTree.filter((item) => value.includes(item.id)));
      this.options.filter.faculties = this.options.filter.faculties?.filter((id) =>
        this.faculties.some((item) => item.id === id)) ?? null;
    } else {
      this.faculties = this.filterFaculties(this.filialTree);
    }
    this.filterChange(value, 'filials');
  }

  protected categoryChange(value: string[]) {
    if (value.length) {
      const categoryIds = this.applicationCategories.filter((item) =>
        value.includes(item.id)).map((item) => item.id);
      this.filterApplicationTypes = this.applicationTypes.filter((item) =>
        categoryIds.includes(item.dictApplicationCategoryId));
      this.options.filter.applicationTypes = this.options.filter.applicationTypes?.filter((id) =>
        this.filterApplicationTypes.some((item) => item.id === id)) ?? null;
    } else {
      this.filterApplicationTypes = this.applicationTypes;
    }
    this.filterChange(value, 'applicationCategories');
  }

  protected addApplication() {
    this.router.navigateByUrl('/mfc/applicationForm');
  }

  protected navigateToSelectedApplication({dataItem}: CellClickEvent) {
    window.open(`mfc/applicationForm/${dataItem.id}`, '_blank');
  }

  protected export(grid: GridComponent) {
    this.getApplications(true, grid);
  }

  protected mapExportData = (): ExcelExportData => {
    const data = this.excelExportData.map((item, index) => {
      return {
        ...item,
        rowIndex: ++index,
        filial: item.filial.name,
        faculty: item.faculty.name,
        trainingLevel: item.trainingLevel.name,
        applicationCategory: item.applicationCategory.name,
        applicationType: item.applicationType.name,
        applicationStatus: this.statusMap.get(item.applicationStatus),
        createdAt: DateToString(item.createdAt),
        statusUpdatedAt: DateToString(item.statusUpdatedAt),
      };
    });
    return {data};
  }
}

import { DictStudyFormType } from "./dictstudyformtype.model";
import {Guid} from "guid-typescript";

export class DictStudyForm{
  constructor() {}
  public dictStudyFormExternalId?: Guid | string;
  public dictStudyFormId!: number;
  public studyFormName!: string;
  public studyFormSName?: string;
  public studyFormType!: number;
  public type?: number;
  public orderNum?: number;
  public dictStudyFormType!: DictStudyFormType;
  public forAdmission?: boolean;
  public studyFormCategory!: number;
}

import {Component, OnInit, ViewChild} from "@angular/core";
import {
  Faculty,
} from "src/app/models/contingent/departmentname.model";
import { DepartmentService } from "src/app/services/contingent/department.service";
import {
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { EducationService } from "src/app/services/contingent/education.service";
import { EducationPlan } from "src/app/models/contingent/educationplan.model";
import { EdugroupService } from "src/app/services/contingent/edugroup.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import {
  DialogCloseResult,
  DialogRef,
  DialogService,
} from "@progress/kendo-angular-dialog";
import { Router } from "@angular/router";
import {
  EduGroupNameRequest,
  EduGroupPrecreatedDto,
  EduGroupWithAllStudyPeriodDto,
} from "src/app/models/contingent/edugroup.model";
import { AcademicGroupStudents } from "src/app/models/contingent/studedugroup.model";
import { StudedugroupService } from "src/app/services/contingent/studedugroup.service";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { LoaderType } from "@progress/kendo-angular-indicators";
import {CommondictService} from "../../../../services/contingent/commondict.service";
import {DictStudyForm} from "../../../../models/contingent/dictstudyform.model";
import { CreateQuery } from "src/app/helpers/createQuery-helper";
import {groupBy, GroupDescriptor} from "@progress/kendo-data-query";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../../helpers/date-helper";
import {PlanStatusTypeEnum} from "../../../../models/contingent/enums/plan-status-type.enum";
import { DictFilial } from "src/app/models/contingent/dictfilial.model";
import { DictFilialService } from "src/app/services/contingent/dictfilial.service";

@Component({
  selector: "app-addacademicgroups",
  templateUrl: "./addacademicgroups.component.html",
  styleUrls: ["./addacademicgroups.component.scss"],
})
export class AddacademicgroupsComponent implements OnInit {

  constructor(private departmentService: DepartmentService,
              private router: Router,
              private educationPlanService: EducationService,
              private eduGroupService: EdugroupService,
              private studEduGroupService: StudedugroupService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private commonDictService: CommondictService,
              private dictFilialService: DictFilialService) {
    if (history.state.academicGroupFilters != undefined) {
      if (history.state.academicGroupFilters.yearBegin != undefined)
        this.getYearAdmissionList(history.state.academicGroupFilters.yearBegin);
      else this.getYearAdmissionList(this.currentYear);
      this.formEduGroup.reset(history.state.academicGroupFilters);
    }
    else this.getYearAdmissionList(this.currentYear);
  }

  @ViewChild('semesters') public form: NgForm | undefined;
  public academicGroup: any = {};
  public currentYear: number = new Date().getFullYear()
  public applyPlanChanged: boolean | undefined = undefined;

  public semesterRequest?: EduGroupPrecreatedDto;
  public planSemesters?: EduGroupWithAllStudyPeriodDto;
  public groupedPlanSemesters?: any;
  public group: GroupDescriptor[] = [{ field: "courseNum" }];
  public nameRequest: EduGroupNameRequest = {
    planId: undefined,
    eduGroupId: undefined,
  };
  public planStatus: any = PlanStatusTypeEnum;
  public academicGroupStudents: AcademicGroupStudents[] = [];
  public faculties: Faculty[] = [];
  public selectedFaculties: Faculty[] = [];
  public disable = true;
  public academicGroupsId?: string;
  public educationPlans: EducationPlan[] = [];
  public selectedEducationPlans: EducationPlan[] = [];
  public filials: DictFilial[] = []
  public pageSize = 10;
  public sizes = [10, 20, 30];
  public maxCourse = 0;
  public newStudyYearMonth = 7;

  ngOnInit(): void {
    this.getAllEducationPlan();
    this.getAllFaculty(2);
    this.editable = true;
    this.getAllStudyForm();
    this.getAllFilials();
  }

  public dictStudyForms: DictStudyForm[] = [];

  public getAllStudyForm() {
    this.commonDictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictStudyForms = response;
        }
      );
  }

  public getYearAdmissionList(num: number) {
    for (let i = num - 6; i < num + 6; i++) {
      this.startYears.push({ year: i });
      this.years.push({
        text: i.toString() + "-" + (i + 1).toString(),
        value: i,
      });
    }
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public getAllFaculty(option: number) {
    this.departmentService.getAllFaculty(option).subscribe((response) => {
      this.faculties = response;
      this.selectedFaculties = this.faculties;
    });
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public getAllEducationPlan() {
    this.educationPlanService.getAllEducationPlans().subscribe((response) => {
      this.educationPlans = response.filter((x) => x.statusId !== this.planStatus.Closed);
      //this.selectedEducationPlans = this.educationPlans;
      this.filterEducationPlan();
    });
  }

  public getPlanMaxCourse(){
    this.eduGroupService.getMaxCourseByEduPlanId(this.formEduGroup.value.planId).subscribe({
      next: (response: any) => {
        this.maxCourse = response
      },
      error: () => {
        this.notificationService.showError("Не удалось получить максимальный курс");
      }
    })
  }

  public getPlanSemesters(){
    this.semesterRequest = {
      groupName: this.formEduGroup.value.groupName,
      admissionYear: this.formEduGroup.value.admissionYear,
      planId: this.formEduGroup.value.planId,
      facultyId: this.formEduGroup.value.facultyId,
    }

    const query = CreateQuery(this.semesterRequest);

    this.eduGroupService.getPrecreatedSemestersGroup(query).subscribe({
      next: (response) => {
        this.planSemesters = response
        this.semesterData = []
        if(this.planSemesters.semesters != undefined) {
          this.groupedPlanSemesters = groupBy(this.planSemesters.semesters, this.group)
          this.groupedPlanSemesters.forEach((element:any) =>{
            const row = {
              groupName: element.items[0].groupName,
              courseNum: element.items[0].courseNum,
              firstSemester: element.items[0].semesterNum,
              firstSemesterStart: DateFromUTCAsLocal(element.items[0].semesterStart),
              firstSemesterEnd: element.items[0].semesterEnd != null ? DateFromUTCAsLocal(element.items[0].semesterEnd) : null,
              secondSemester: element.items.length > 1 ? element.items[1].semesterNum : null,
              secondSemesterStart: element.items.length > 1 && element.items[1].semesterStart != null ? DateFromUTCAsLocal(element.items[1].semesterStart) : null,
              secondSemesterEnd: element.items.length > 1 && element.items[1].semesterEnd != null ? DateFromUTCAsLocal(element.items[1].semesterEnd) : null,
              thirdSemester: element.items.length > 2 ? element.items[2].semesterNum : null,
              thirdSemesterStart: element.items.length > 2 && element.items[2].semesterStart != null ? DateFromUTCAsLocal(element.items[2].semesterStart) : null,
              thirdSemesterEnd: element.items.length > 2 && element.items[2].semesterEnd != null ? DateFromUTCAsLocal(element.items[2].semesterEnd) : null,
            }
            this.semesterData.push(row)
          })
        }
        this.firstStage = false

        setTimeout(() =>
        {
          if (this.form != null)
            this.form.form.markAllAsTouched()
        }, 100);
      },
      error: (error) => {
        this.firstStage = true
        this.notificationService.showError(error.error);
      }
    }
    )
  }

  public editable: boolean = false;
  public firstStage: boolean = true;

  public filterEducationPlan() {
    //this.formEduGroup.reset(this.formEduGroup.value);
    //this.selectedEducationPlans = this.educationPlans;
    if(this.formEduGroup.value.facultyId!=null)
      this.selectedEducationPlans = this.educationPlans.filter((s) => s.facultyId == this.formEduGroup.value.facultyId)
    this.courseChange();
  }

  public onFilialChange() {
    if (this.formEduGroup.value.filialId!=null) {
      this.formEduGroup.value.planId = null;
      this.formEduGroup.value.facultyId = null;
      this.formEduGroup.reset(this.formEduGroup.value);
      this.selectedFaculties = this.faculties.filter((s) => s.filialId == this.formEduGroup.value.filialId);
    }
  }

  public educationPlanChange(id: any): void {
    this.getPlanMaxCourse()
    let educationPlan = this.educationPlans.find((x) => x.externalId == id);
    if (educationPlan != null) {
      this.courseChange();
      this.academicGroup.standardName = educationPlan?.standardName;
      this.academicGroup.programName = educationPlan?.programName;
      this.academicGroup.studyFormName = this.dictStudyForms.find((x) => x.dictStudyFormExternalId === educationPlan?.dictStudyFormId)?.studyFormName
    }
  }

  public admissionYearChange(){
    this.courseChange();
  }

  courseChange(){
    if(this.formEduGroup.value.admissionYear !== null)
    {
      this.academicGroup.courseNum = this.currentYear - this.formEduGroup.value.admissionYear;
      if(new Date().getMonth() > this.newStudyYearMonth)
        this.academicGroup.courseNum ++;
    }

  }

  public loaderVisible = false;
  public type: LoaderType = "converging-spinner";

  public getEduGroupName(course?: number) {
    this.loaderVisible = true;

    this.nameRequest = {
      planId: this.formEduGroup.value.planId,
      eduGroupId: this.academicGroupsId,
      course: course!= undefined ? (course + 1) : (this.academicGroup.courseNum <= 0 ? 1 : this.academicGroup.courseNum),
      facultyId: this.formEduGroup.value.facultyId,
      admissionYear: this.formEduGroup.value.admissionYear,
      studyYear: this.formEduGroup.value.yearBegin,
    };
    this.eduGroupService.getEduGroupName(this.nameRequest).subscribe({
      next: (response: any) => {
        if (response !== "")
          if(course!=undefined) this.semesterData[course].groupName = response
          else this.formEduGroup.value.groupName = response;
        this.loaderVisible = false;
      },
      error: () => {
        this.notificationService.showError("Не удалось обновить название");
        this.loaderVisible = false;
      }
    });
  }

  public index(id: any): number {
    return this.academicGroupStudents.findIndex((x) => x.planId === id) + 1;
  }

  public getTrainingDuration(){
    let year = (y: any) => /\d*1\d$/.test(y) || /[05-9]$/.test(y) ? ' лет ' : ( /1$/.test(y) ? ' год ' : ' года ');
    let month = (y: any) => /\d*1\d$/.test(y) || /[05-9]$/.test(y) ? ' месяцев' : ( /1$/.test(y) ? ' месяц' : ' месяца');

    return (this.planSemesters?.yearLengthApprenticeship? this.planSemesters?.yearLengthApprenticeship + year(this.planSemesters?.yearLengthApprenticeship):"") +
    (this.planSemesters?.monthLengthApprenticeship? this.planSemesters?.monthLengthApprenticeship + month(this.planSemesters?.monthLengthApprenticeship):"")
  }

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.saveEduGroup();
  }

  public nextStep(){
    if(this.academicGroup.courseNum > this.maxCourse){
      this.notificationService.showError("Текущий курс превышает срок обучения по учебному плану");
      return
    }
    this.getPlanSemesters()
  }

  public previousStep(){
    this.firstStage = true
  }

  public saveEduGroup() {

    this.addSemesterToSave()
    this.formEduGroup.value.courseNum = this.academicGroup.courseNum <= 0 ? 0 : this.academicGroup.courseNum
    this.eduGroupService.addedugroup(this.formEduGroup?.value).subscribe({
      next:() => {
        this.formEduGroup.reset();
        this.notificationService.showSuccess(
          "Группа была успешно добавлена!"
        );
        this.router.navigateByUrl(`/contingent/academicgroupshome`).then();
      },
      error:(error) => {
        this.notificationService.showError(error.error);
      }}
    );

    this.applyPlanChanged = false;
  }

  public educationPlanStatusName(id?: any) {
    switch (id) {
      case 1:
        return "В разработке";
        break;
      case 2:
        return "Утвержден"
        break;
      case 3:
        return "Закрыт"
        break;
      default:
        return ""
        break;
    }
  }

  public addSemesterToSave(){
    let semesters: any[] = []
    if(this.semesterData!= undefined){
      this.semesterData.forEach((el) => {
        semesters.push({
          groupName: el.groupName,
          semesterNum: el.firstSemester,
          courseNum: el.courseNum,
          semesterStart: TimeZoneFix(el.firstSemesterStart),
          semesterEnd: TimeZoneFix(el.firstSemesterEnd)
        })
        if(el.secondSemester != null)
          semesters.push({
            groupName: el.groupName,
            semesterNum: el.secondSemester,
            courseNum: el.courseNum,
            semesterStart: el.secondSemesterStart != null ? TimeZoneFix(el.secondSemesterStart): null,
            semesterEnd: el.secondSemesterEnd != null ? TimeZoneFix(el.secondSemesterEnd): null,
          })
        if(el.thirdSemester!=null)
          semesters.push({
            groupName: el.groupName,
            semesterNum: el.thirdSemester,
            courseNum: el.courseNum,
            semesterStart: el.thirdSemesterStart != null ? TimeZoneFix(el.thirdSemesterStart): null,
            semesterEnd: el.thirdSemesterEnd != null ? TimeZoneFix(el.thirdSemesterEnd): null,
          })
      })
    }
    this.formEduGroup.value.eduGroupSemesters = semesters
  }

  public openPlan() {
    if(this.formEduGroup.value.planId) {
      let plan = this.educationPlans.find((x) => x.externalId === this.formEduGroup.value.planId)
      let planRoute = `/education/${plan?.educationProgramId}/plans`;
      if (plan?.externalId) planRoute += '/plan/' + plan.externalId;
      window.open(planRoute, '_blank')
    }
  }

  public remove(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content:
        "Вы действительно хотите удалить группу: " +
        this.academicGroup.groupName +
        "?",
      actions: [{ text: "Нет" }, { text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text == "Да") {
          this.eduGroupService
            .deleteedugroup(this.academicGroup.eduGroupExternalId)
            .subscribe(() => {
              this.notificationService.showSuccess("Удалено");
              this.router.navigateByUrl(`/contingent/academicgroupshome`).then();
            });
        }
      }
    });
  }

  public transitionHandler({ dataItem }: CellClickEvent): void {
    window.open(
      `/contingent/studentmaininfo/${dataItem.studentId}/personaldata/${dataItem.studPersonId}`
    );
  }

  public years: Array<{ text: string; value: number }> = [];

  public startYears: Array<{ year: number }> = [];

  public trimester: boolean = false
  public semesterData: any[] = [];

  public formEduGroup: UntypedFormGroup = new UntypedFormGroup({
    groupName: new UntypedFormControl(),
    admissionYear: new UntypedFormControl(),
    planId: new UntypedFormControl(),
    facultyId: new UntypedFormControl(),
    eduGroupSemesters: new UntypedFormControl(),
    budgetQuotesStudentAdmissionBenchmarks: new UntypedFormControl(),
    commerceQuotesStudentAdmissionBenchmarks: new UntypedFormControl(),
    applyPlanChanged: new UntypedFormControl(),
    notice: new UntypedFormControl(),
    filialId: new UntypedFormControl(),
  });
}

<div class="container">
  <div class="centered">
    <p>{{userSignatureInfo.banText}}</p>
    <p>Для восстановления доступа вам необходимо предоставить в управление кадрами оригинал данного Заявления с рукописной подписью.</p>
    <div class="userSignatureItem userRegulation">
      <p>{{userSignatureInfo.regulationName}}</p>
      <button kendoButton icon="download"
              (click)="getRegulationFile(userSignatureInfo.regulationFileDto)" size="small">
        Скачать
      </button>
    </div>
    <div class="userSignatureItem userRegulation">
      <p>{{userSignatureInfo.statementText}}</p>
      <button kendoButton icon="download"
              (click)="getRegulationFile(userSignatureInfo.statementFileDto)" size="small">
        Скачать
      </button>
    </div>
    <div class="buttonExit">
      <button kendoButton size="small" class="close-button" (click)="Close()">Закрыть</button>
    </div>
  </div>
</div>

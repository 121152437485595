import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable, Subject} from 'rxjs';
import {Role} from '../models/useraccess/role';
import {TokenStorageService} from '../services/token.service';
import {map} from "rxjs/operators";
import {ContingentUserAccessService} from "../services/useraccess/contingent-user-access.service";
import {ContingentUserAccess} from "../models/useraccess/contingent/contingentuseraccess.model";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ContingentRoleGuard implements CanActivate {
  constructor(private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private userAccessService: ContingentUserAccessService,
              private location: Location,
              private router: Router) {
    }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    // Checking if token is not null
    if(token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin))
        return true;
      // Check if user has contingent role
      if(roles?.includes(Role.Contingent)) {
          return this.userAccessService.getAccessLevel().pipe(
              map(value => {
                  const signatory = value.localSignatoryAccessLevel;
                  // Signatory case
                  if(path === 'contingent/signatory') {
                      return signatory;
                  }
                  return true;
              }))
      }
    }
    alert('Недостаточно прав для доступа на эту страницу');
    localStorage.setItem('last_url', this.location.path())
    this.router.navigate([`/profile`]);
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import {UserSignatureInfo} from "../../../models/oferta/usersignature/userSignatureInfo.model";
import {UserSignatureService} from "../../../services/oferta/user-signature.service";
import {RegulationFileService} from "../../../services/oferta/regulationFile.service";
import {saveAs} from "@progress/kendo-file-saver";
import {GetRegulationFile} from "../../../models/oferta/regulationFile/getRegulationFile.model";
import {CreateQuery} from "../../../helpers/createQuery-helper";
import {SignatureTypeEnum} from "../../../models/oferta/enums/dictSignatureType.enum";
import {JwtHelperService} from "@auth0/angular-jwt";
import {TokenStorageService} from "../../../services/token.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {GetPersonRegulationQuery} from "../../../models/oferta/usersignature/query/getPersonRegulationQuery";

@Component({
  selector: 'person-blocked-notification',
  templateUrl: './person-blocked-notification.component.html',
  styleUrls: ['./person-blocked-notification.component.css']
})
export class PersonBlockedNotificationComponent implements OnInit {

  public userSignaturesInfo!: UserSignatureInfo;
  private getPersonSimpleRegulationQuery?: GetPersonRegulationQuery;

  constructor(private userSignatureService: UserSignatureService,
              private notificationsService: NotificationsService,
              private regulationFileService: RegulationFileService,
              private tokenService: TokenStorageService,
              private tokenStore: TokenStorageService,
              private jwt: JwtHelperService) { }

  private getPersonSimpleRegulation(){
    const token = this.jwt.decodeToken(this.tokenService.getToken().accessToken);

    this.getPersonSimpleRegulationQuery = {
      UserSignatureTypeEnum: SignatureTypeEnum.Simple
    }

    const query = CreateQuery(this.getPersonSimpleRegulationQuery);

    this.userSignatureService.getPersonRegulation(query)
      .subscribe(
        response => {
          this.userSignaturesInfo = response;
        }
      );
  }

  private getFileObserver(fileName?: string) {
    return {
      next: (response: Blob) => fileName && saveAs(response, fileName),
      error: (error: unknown) => this.notificationsService.showError(error)
    };
  }

  public getRegulationFile(file?: GetRegulationFile) {
    file && this.regulationFileService.getRegulationFile(file.id)
      .subscribe(this.getFileObserver(file.fileName));
  }

  public logOut = () => {
    localStorage.removeItem('switchPerson');
    localStorage.removeItem('last_url');
    window.location.reload();
    this.tokenStore.deleteToken()
  }

  ngOnInit(): void {
    this.getPersonSimpleRegulation();
  }

}

<card-header *ngIf="!isContingentSection"></card-header>

<h1 class="header">Карты</h1>

<button kendoButton icon="plus" size="small" themeColor="success" (click)="toAddRequest()" class="btn-add-to-list-forming">
  ДОБАВИТЬ В СПИСОК НА ФОРМИРОВАНИЕ
</button>

<kendo-grid
  [kendoGridBinding]="gridData"
  [pageable]="pagerSettings"
  [filterable]="isFilterable"
  [pageSize]="pageSize"
  scrollable="none"
>
  <kendo-grid-column field="rowIndex" title="№ п/п" headerClass="gridHeader" [width]="70" [class]="{ 'text-center': true }" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <p class="cell-table-student-info">{{(rowIndex+1)}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="studentFullName" title="ФИО" headerClass="gridHeader" [width]="317" [class]="{ 'text-center': true }">
    <ng-template kendoGridCellTemplate let-dataItem style="text-align: center">
      <p class="cell-table-student-info">{{dataItem?.studentFullName}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="cardDesign" title="Дизайн карты" headerClass="gridHeader" [width]="263" [class]="{ 'text-center': true }">
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="cell-table-student-info">{{dataItem?.cardDesign}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="salaryProjectName" title="Зарплатный проект" headerClass="gridHeader" [width]="353" [class]="{ 'text-center': true }">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <filter-combobox [useVirtual]='false' [filter]="filter" [data]="salaryProjects" textField="salaryProjectName" valueField="salaryProjectName">
      </filter-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="cell-table-student-info">{{dataItem?.salaryProjectName}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="dateAdd" filter="date" title="Дата добавления" headerClass="gridHeader" [width]="180" [class]="{ 'text-center': true }" format="dd.MM.yyyy">
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="cell-table-student-info">{{dataItem?.dateAdd | date: 'dd.MM.yyyy'}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="reestrNameStatus" title="Реестр" headerClass="gridHeader" [width]="340" [class]="{ 'text-center': true }">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <filter-combobox [useVirtual]='false' [filter]="filter" [data]="reestrs" textField="reestrNameStatus" valueField="reestrNameStatus">
      </filter-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="cell-table-student-info" (click)="toReestr(dataItem?.reestrId)" style="cursor: pointer">{{dataItem?.reestrNameStatus}}</p>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

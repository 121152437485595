import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { QualificationDegree } from '../../models/dicts/qualificationdegree.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class QualificationdegreeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.qualification}`;

  constructor(private http: HttpClient) { }

  //Get all QualificationDegree
  public getAllQualificationDegree(): Observable<QualificationDegree[]> {
    return this.http.get<QualificationDegree[]>(this.baseUrl);
  }

  //Add QualificationDegree
  addQualificationDegree(qualificationdegree: QualificationDegree): Observable<QualificationDegree> {
    return this.http.post<QualificationDegree>(this.baseUrl, qualificationdegree)
      .pipe(catchError(this.handleError));
  }

  //Delete QualificationDegree
  deleteQualificationDegree(id: string): Observable<QualificationDegree> {
    return this.http.delete<QualificationDegree>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit Qualification
  updateQualificationDegree(qualificationdegree: QualificationDegree): Observable<QualificationDegree> {
    return this.http.put<QualificationDegree>(this.baseUrl + '/' + qualificationdegree.dictQualificationExternalId, qualificationdegree)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

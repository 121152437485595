
<div class="grid-container">

  <h1>Список выбранных студентов</h1>

  <h2 class="operation-type">{{"Тип операции: " + (massOperationJournal?.typeName ? massOperationJournal?.typeName : "")}}</h2>

  <h2 class="operation-details" *ngIf="massOperationJournal?.academicState != null">{{"Смена академического статуса: " + massOperationJournal?.academicState}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.militaryStatus != null">{{"Категория воинского учета: " + massOperationJournal?.militaryStatus}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.benefit != null">{{"Льгота: " + massOperationJournal?.benefit}}</h2>

  <h2 class="operation-details" *ngIf="massOperationJournal?.budgetState != null">{{"Источник финансирования: " + massOperationJournal?.budgetState}}</h2>

  <h2 class="operation-details" *ngIf="massOperationJournal?.group != null">{{"Группа: " + massOperationJournal?.group?.groupName}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.group != null">{{"Направление подготовки: " + massOperationJournal?.group?.standard}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.group != null">{{"Форма обучения: " + massOperationJournal?.group?.studyForm}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.group != null">{{"Профиль (программа): " + massOperationJournal?.group?.program}}</h2>

  <h2 class="operation-details" *ngIf="massOperationJournal?.plan != null">{{"Направление подготовки: " + massOperationJournal?.plan?.standard}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.plan != null">{{"Профиль (программа): " + massOperationJournal?.plan?.program}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.plan != null">{{"Форма обучения: " + massOperationJournal?.plan?.studyForm}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.plan != null">{{"Год поступления: " + massOperationJournal?.plan?.admissionYear}}</h2>

  <h2 class="operation-details" *ngIf="massOperationJournal?.studyPeriod != null">{{"Период обучения: " + massOperationJournal?.studyPeriod}}</h2>

  <h2 class="operation-details" *ngIf="massOperationJournal?.order != null">{{"Номер: " + massOperationJournal?.order?.number}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.order != null">{{"Дата: " + massOperationJournal?.order?.orderDate}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.order != null">{{"Тип: " + massOperationJournal?.order?.typeName}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.order != null">{{"Категория: " + massOperationJournal?.order?.categoryName}}</h2>
  <h2 class="operation-details" *ngIf="massOperationJournal?.order != null && massOperationJournal?.order?.period">{{"Срок действия: " + massOperationJournal?.order?.period}}</h2>
  <div class="sedDiv" *ngIf="massOperationJournal?.order != null && massOperationJournal?.order?.orderSed" >
         <p class="operation-details">{{"СЭД: "}}</p>
         <p class="operation-details url" *ngIf="massOperationJournal?.order?.orderSed != null" (click)="opensed(massOperationJournal!.order!.orderSed)">{{massOperationJournal?.order?.orderSed}}</p>
  </div>
  <h2 class="operation-details" *ngIf="massOperationJournal?.order != null && massOperationJournal?.order?.comment">{{"Примечание: " + massOperationJournal?.order?.comment}}</h2>
  <div *ngIf="massOperationJournal?.order != null">
    <h2 class="operation-details">{{"Файл приказа: "}}
      <span class="uploadedFile pointer"
        *ngIf='massOperationJournal?.order?.fileName'
        (click)="getOrderFile()">
        <span class="k-icon k-i-file"></span>
        {{massOperationJournal?.order?.fileName}}
      </span>
      <span
        *ngIf='massOperationJournal?.order?.fileName && !files'
        class="k-icon k-i-x pointer"
        (click)="deleteOrderFile()"></span>
    </h2>
    <div>
      <kendo-fileselect
          class="input-files"
          [restrictions]="restrictions"
          [multiple]="false"
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="files">
        <kendo-fileselect-messages
          invalidFileExtension="Недопустимый формат файла.">
        </kendo-fileselect-messages>
      </kendo-fileselect>
    </div>
    <button
      class="saveButton"
      kendoButton
      (click)="saveOrderFile()"
      [disabled]="!files">
      Сохранить</button>
  </div>
  <div class="functionButtons journal-details-button">
    <button kendoButton
            size="small"
            [routerLink]="['/contingent/contingentjournal']" >
      Назад
    </button>
    <button kendoButton
            class="right-float"
            size="small"
            *ngIf="massOperationJournal?.cancelable && !allChanged"
            (click)="removeHandler($event)"
            >
      Отменить операцию
    </button>
  </div>

  <h2 class="operation-details" *ngIf="anyChanged">{{"Серым отмечены студенты, данные которых были изменены вручную в карточке студента. Для таких студентов отменить операцию невозможно"}}</h2>

  <kendo-grid [kendoGridBinding]="massOperationStudents"
              (remove)="removeHandler($event)"
              [pageable]="true"  [pageSize]="pageSize"
              [reorderable]="true"
              [resizable]="true"
              [sortable]="true"
              [loading]="loading"
              [rowClass]="rowCallback">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <!--Тип операции-->
    <kendo-grid-column field="fio"
                       title="№ п/п"
                       headerClass="leftAlign"
                       [width]="40">
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        {{(rowIndex+1)}}
      </ng-template>
    </kendo-grid-column>
    <!--Дата операции-->
    <kendo-grid-column field="fio"
                       title="ФИО"
                       headerClass="leftAlign"
                       [width]="100">
    </kendo-grid-column>
    <!--ФИО автора изменений-->
    <kendo-grid-column field="studentNumber"
                       title="Номер зачетной книжки"
                       headerClass="leftAlign"
                       [width]="100">
    </kendo-grid-column>
    <!--Количество студентов-->
    <kendo-grid-column field="course"
                       title="Курс"
                       headerClass="leftAlign"
                       [width]="50">
    </kendo-grid-column>


    <kendo-grid-column field="group"
                       title="Группа"
                       headerClass="leftAlign"
                       [width]="80">
    </kendo-grid-column>

    <kendo-grid-column field="budgetState"
                       title="Источник финансирования"
                       headerClass="leftAlign"
                       [width]="120">
    </kendo-grid-column>


    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>

  <!--Buttons-->
  <div class="functionButtons journal-button">
    <button kendoButton
            size="small"
            [routerLink]="['/contingent/contingentjournal']" >
      Назад
    </button>
  </div>
</div>
<div kendoDialogContainer></div>

<mfc-settings></mfc-settings>

<h1>Шаблоны справок</h1>

<div class="container">
    <kendo-grid
            class="grid-data borderless"
            [data]="gridView"
            [pageable]="pagerSettings"
            [sortable]="true"
            [sort]="sort"
            [skip]="skip"
            [pageSize]="pageSize"
            [rowClass]="rowCallback"
            (pageChange)="pageChange($event)"
            (sortChange)="sortChange($event)"
            (cellClick)="openEditMode($event)"
            [loading]="loading"
            [filterable]="true">
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <kendo-grid-column [hidden]="true" field="dictApplicationCategory.name">
            <ng-template kendoGridGroupHeaderTemplate let-group="group">
                <span>{{group.value}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dictApplicationType" title="Тип">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect (onFilterChange)="filterChange($event, column)"
                                    [data]="dictApplicationTypes"
                                    gridField="dictApplicationType" textField="name" valueField="id">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                {{dataItem[column.field].name}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="400" field="name" title="Наименование шаблона формы заявки">
            <ng-template kendoGridFilterCellTemplate>
                <kendo-textbox [clearButton]="true" (valueChange)="filterData()" [(ngModel)]="templateFormNameFilter"></kendo-textbox>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="status" title="Статус шаблона формы заявки">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect (onFilterChange)="filterChange($event, column)"
                                    [data]="constructorStatuses"
                                    [ngModel]="filters.status"
                                    ngDefaultControl
                                    gridField="status"
                                    textField="name"
                                    valueField="enum">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                {{dataItem[column.field].name}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="trainingLevels" title="Уровень подготовки">
            <ng-template kendoGridFilterCellTemplate let-column="column">
                <filter-multiselect (onFilterChange)="filterChange($event, column)"
                                    [data]="dictTrainingLevels"
                                    gridField="trainingLevels" textField="name" valueField="id">
                </filter-multiselect>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                <ng-container *ngIf="!dataItem.isAllTrainingLevelAvailable; else allTemplate">
                    <span *ngFor="let item of dataItem[column.field]; let isLast=last">
                       {{item.name}}{{isLast ? '' : ', '}}
                    </span>
                </ng-container>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="faculties" title="Институт/факультет">
            <ng-template kendoGridFilterCellTemplate let-column="column">
              <filter-multiselect-tree
                [data]="filialTree"
                (onFilterChange)="filterChange($event, column)"
                gridField="faculties"
                childrenField="faculties"
                valueField="id"
                textField="name">
              </filter-multiselect-tree>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
                <ng-container *ngIf="!dataItem.isAllFacultyAvailable; else allTemplate">
                    <span *ngFor="let item of dataItem[column.field]; let isLast=last">
                       {{item.name}}{{isLast ? '' : ', '}}
                    </span>
                </ng-container>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

<ng-template #allTemplate>
    Все
</ng-template>
import {StaticPropertyEnum} from "./enums/settings.enum";

export class StaticProperty {
  name!: string;
  value!: string;
  title!: string;
  description!: string;
}

export var getStaticPropertyValue = (properties: StaticProperty[], name: string) => {
  return properties.find(p => p.name.toLowerCase() === name.toLowerCase())?.value;
};

export var getStaticProperty = (properties: StaticProperty[], name: string) => {
  return properties.find(p => p.name.toLowerCase() === name.toLowerCase());
};

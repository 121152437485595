<kendo-stacklayout orientation="vertical">
  <kendo-stacklayout [gap]="20" orientation="horizontal" [align]="{horizontal: 'start', vertical: 'middle'}" style="margin-bottom: 14px" >
    <p class="label">Условные обозначения</p>
    <button
      *ngIf="editable"
      kendoButton
      (click)="toggleEdit()"
      icon="edit"
      fillMode="outline"
      class="button"
    >
      Редактировать
    </button>
  </kendo-stacklayout>
  <kendo-grid [data]="dictWorkScheduleStudy" class="legend">
    <kendo-grid-column title="Вид работы" [width]=400>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="legend__cell">
          <p style="text-align: left">{{dataItem.name}}</p>
        </div>
      </ng-template>
    </kendo-grid-column>
    <!--<kendo-grid-column
      title="Обозначение"
      [width]=150
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="legend__cell">
          <p>{{dataItem.symbol}}</p>
        </div>
      </ng-template>
    </kendo-grid-column>-->
    <kendo-grid-column
      title="Обозначение"
      [width]=150
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="legend__cell" style="background-color:{{dataItem.color}}">
          <p>{{dataItem.symbol}}</p>
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div class="doplegend" *ngIf="isInstallationSession">
    <div class="session_text"> Установочная сессия</div>
    <div class="session"></div>
  </div>
</kendo-stacklayout>

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../../helpers/publications/errorHandle-helper";
import {ExternalAuthor} from "../../../models/publications/authors/authors.model";
import {Person} from "../../../models/publications/person.model";
import {AuthorType} from "../../../models/publications/authorTypes.model";

@Injectable({
    providedIn: 'root'
})
export class AuthorsService {
    private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.person}`;

    constructor(private http: HttpClient) {}


    getExternalAuthor(id: string): Observable<ExternalAuthor> {
      return this.http.get<ExternalAuthor>(`${this.baseUrl}/${id}`);
    }

    getExternalAuthors(fio?: string): Observable<Person[]> {
        const filter = fio ? `&FIO=${fio}` :'';
        return this.http.get<Person[]>(`${this.baseUrl}/?Type=${AuthorType.ExternalAuthor}${filter}`);
    }

    addExternalAuthor(author: ExternalAuthor): Observable<ExternalAuthor> {
        return this.http.post<ExternalAuthor>(this.baseUrl, author)
            .pipe(catchError(handleError));
    }

    updateExternalAuthor(author: ExternalAuthor): Observable<ExternalAuthor> {
        return this.http.put<ExternalAuthor>(this.baseUrl, author)
            .pipe(catchError(handleError));
    }

    removeExternalAuthor(id: string): Observable<ExternalAuthor> {
        return this.http.delete<ExternalAuthor>( `${this.baseUrl}/${id}`)
            .pipe(catchError(handleError));
    }

}

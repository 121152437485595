import { Component, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { FoundStudentsService} from 'src/app/services/announcement/found-students.service';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { difference } from 'lodash'

@Component({
  selector: 'app-selected-students-new-list',
  templateUrl: './selected-students-new-list.component.html',
  styleUrls: ['./selected-students-new-list.component.scss']
})

export class SelectedStudentNewListComponent implements OnInit {
  public gridView!: GridDataResult;
  public selection: any[] = [];
  public selectedStudents: any[] = [];
  public quantitySelectedStudents: number = 0;
  private subscriptions: Subscription[] = [];
  
  constructor (
    private foundStudentsService: FoundStudentsService,
  ) { }

  public deleteSelectedElemens() {
    this.foundStudentsService.getNewListStudents(difference(this.selectedStudents, this.selection));
    this.selection = [];
  }

  public mySelectionKey(context: RowArgs): string {
    return context.dataItem;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.foundStudentsService.selectStudentsNewList$.subscribe((data: any[]) => {
          this.selectedStudents = data;
          //console.log('в новом списке',this.selectedStudents);
          this.gridView = {
            data: this.selectedStudents,
            total: this.selectedStudents.length
          };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

import {Injectable} from "@angular/core";
import {Stamp, StampForm} from '../../models/mfc/stamp/stamp.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {handleError} from '../../helpers/publications/errorHandle-helper';
import {SignTypes} from '../../models/mfc/enums/sign-types.enum';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class StampService {

  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.stamp}/`;

  constructor(private http: HttpClient) { }

  private getFormData(stamp: StampForm) {
    let formData = new FormData();
    formData.append('signStampType', stamp.signStampType.toString());
    formData.append('file', stamp.file, stamp.file.name);
    return formData;
  }

  public getStamps(): Observable<Stamp[]> {
    return this.http.get<Stamp[]>(this.baseUrl).pipe(map((s) => s ?? []));
  }

  public getStamp(type: SignTypes) {
    return this.http.get(`${this.baseUrl}${type}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }

  public createStamp(stamp: StampForm) {
    return this.http.post(this.baseUrl, this.getFormData(stamp))
      .pipe(catchError(handleError));
  }

  public deleteStamp(type: SignTypes) {
    return this.http.delete(`${this.baseUrl}?signType=${type}`)
      .pipe(catchError(handleError));
  }
}

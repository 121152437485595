import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class FoundPersonsService {
   
  public selectPersons$ = new BehaviorSubject<any>([]);
  public selectDistrPersons$ = new BehaviorSubject<any>([]);
  public selectedPersonsCount$ = new BehaviorSubject<number>(0);
  public selectNewListPersons$ = new BehaviorSubject<any>([]);

	public getSelectPersons(persons: any, existingPersons?: any) {
		let totalList: Array<any> = persons;
		if (existingPersons) {
			totalList = [];
			//TODO объединить отднотипную логику
			totalList = this.addPersons(persons);
			totalList = this.addExistingPersons(totalList, existingPersons);
		}
		this.selectPersons$.next(totalList);
		this.selectedPersonsCount$.next(totalList.length);
  	}

	public getDistributionPersons(persons: any, existingPersons?: any) {

		let totalList: Array<any> = persons;
		if (existingPersons) {
			totalList = [];
			totalList = this.addPersons(persons);
			totalList = this.addExistingPersons(totalList, existingPersons);
		}
		this.selectDistrPersons$.next(totalList);
	}

	public getNewListPersons(persons: any, existingPersons?: any) {
		let totalList: Array<any> = persons;
		if (existingPersons) {
			totalList = [];
			totalList = this.addPersons(persons);
			totalList = this.addExistingPersons(totalList, existingPersons);
		}
		this.selectNewListPersons$.next(totalList);
  	}

	public addPersons(persons: any){
		let totalList: Array<any> = [];
		for (let i = 0; i < persons.length; i++) {
			if (totalList.includes(persons[i]) == false && totalList.some(e => e.userExternalId === persons[i].userExternalId) == false) {
				totalList.push(persons[i])
			}
		}
		return totalList;
	}

	public addExistingPersons(totalList: Array<any>, existingPersons: any){
		for (let i = 0; i < existingPersons.length; i++) {
			if (totalList.includes(existingPersons[i]) == false && totalList.some(e => e.userExternalId === existingPersons[i].userExternalId) == false) {
				totalList.push(existingPersons[i])
			}
		}
		return totalList;
	}
}

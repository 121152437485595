import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { AchievementsStatuses } from 'src/app/models/contingent/achievementsstatuses.model';

@Injectable({
    providedIn: 'root'
  })

export class AchievementsStatusesService {
    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.portfolio.dict}`;
  
    constructor(private http: HttpClient) { }

    public getAchievementsStatuses() {
        return this.http.get<AchievementsStatuses[]>(this.baseUrl + '/DictAchievementStatus');
    }

    public addAchievementsStatuses(data: AchievementsStatuses) {
        return this.http.post(this.baseUrl + '/DictAchievementStatus', data);
    }

    public deleteAchievementsStatuses(id: string) {
        return this.http.delete(this.baseUrl + '/DictAchievementStatus/' + id);
    }

    public updateAchievementsStatuses(data: AchievementsStatuses, id: string) {
        return this.http.put(this.baseUrl + '/DictAchievementStatus/' + id, data);
    }
}
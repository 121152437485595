import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictSummary} from "../../../models/publications/dict/summary.model";

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/GetDictSummary`;

  constructor(private http: HttpClient) {
  }

  public getSummary(): Observable<DictSummary[]> {
    return this.http.get<DictSummary[]>(this.baseUrl);
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DictComponent } from 'src/app/models/education/dictcomponent.model';
import { DictCycleUsableInfo } from 'src/app/models/education/dictcycle.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictComponentService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictComponent}`;

  constructor(private http: HttpClient) { }

  //Get all components
  public getAllComponents(): Observable<DictComponent[]> {
    return this.http.get<DictComponent[]>(this.baseUrl + '/GetComponents/');
  }

  // Add Component
  addComponent(dictComponent: DictComponent): Observable<DictComponent> {
    return this.http.post<DictComponent>(this.baseUrl + '/CreateComponent/' , dictComponent)
      .pipe(catchError(this.handleError));
  }

  // Edit Component
  updateComponent(dictComponent: DictComponent): Observable<DictComponent> {
    return this.http.put<DictComponent>(this.baseUrl + '/UpdateComponent/' + dictComponent.dictComponentId, dictComponent)
      .pipe(catchError(this.handleError));
  }

  // Remove Component
  deleteComponent(id: string): Observable<DictCycleUsableInfo[]> {
    return this.http.delete<DictCycleUsableInfo[]>(this.baseUrl + '/DeleteComponent/' + id);
  }

  // Get plan components
  public getEduPlanComponents(educationPlanId: string): Observable<DictComponent[]> {
    return this.http.get<DictComponent[]>(this.baseUrl + `/GetEduPlanComponents/${educationPlanId}/`);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {ApplicationConstructorService} from '../../../../services/mfc/application-constructor.service';
import {saveAs} from '@progress/kendo-file-saver';
import {ApplicationConstructorDocumentService} from '../../../../services/mfc/application-constructor-document.service';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {DocumentTypes} from '../../../../models/mfc/enums/document-types.enum';
import {ConstructorDocument} from '../../../../models/mfc/applicationConstructor/constructor-document.model';
import {
  ApplicationConstructorSettings
} from '../../../../models/mfc/applicationConstructor/settings/application-constructor-settings.model';
import {TabsSettings} from '../../../../models/mfc/applicationConstructor/settings/constructor-tabs-settings.model';
import {Tab, tabs} from '../../../../models/mfc/applicationForm/application-tabs.model';
import {ApplicationTabsEnum} from '../../../../models/mfc/enums/application-tabs.enum';
import {StudentService} from '../../../../services/mfc/student.service';
import {
  ApplicationDto,
  ApplicationForm,
  CreateApplicationMainForm, EditApplicationMainForm
} from '../../../../models/mfc/applicationForm/applicationForm.model';
import {ApplicationStatusService} from '../../../../services/mfc/dicts/application-status.service';
import {
  AllowedStatus,
  allowedStatuses,
  ApplicationStatus, displayFIOStatuses,
  editableStatuses
} from '../../../../models/mfc/dicts/application-status.model';
import {ApplicationStatusesEnum} from '../../../../models/mfc/enums/application-statuses.enum';
import {ApplicationFormService} from '../../../../services/mfc/application-form.service';
import {SelectEvent} from '@progress/kendo-angular-layout';
import {openDialog} from '../../../../helpers/dialogHelper';
import {DialogCloseResult, DialogService} from '@progress/kendo-angular-dialog';
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {ApplicationTypeSelectService} from '../../../../services/mfc/application-type-select.service';
import {ApplicationCategoryTree} from '../../../../models/mfc/application-categories-tree.model';
import {AccessRights} from '../../../../models/mfc/enums/access-rights.enum';
import {TypeReceipt} from "../../../../models/mfc/dicts/type-receipt.model";
import {TypeReceiptService} from "../../../../services/mfc/dicts/type-receipt.service";
import {DocumentsService} from '../../../../services/mfc/documents.service';
import {ApplicationDocumentList} from '../../../../models/mfc/applicationForm/document.model';
import {UserAccessService} from '../../../../services/mfc/userAccess.service';
import {ApplicationApprovalSettings} from '../../../../models/mfc/applicationForm/approvalList.model';
import {ReceiptTypesEnum} from '../../../../models/mfc/enums/receipt-types.enum';


@Component({
  selector: 'mfc-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFormHomeComponent implements OnInit, OnDestroy {

  private id = this.activatedRoute.snapshot.params['id'];
  protected editable = false;
  protected isAdd = !this.id;

  private hasChanges = false;
  protected currentTab = 0;
  protected displayInProgressButton = false;

  private studentId = '';
  protected allowedActions: AllowedStatus[] = [];
  protected statusText = '';
  protected tabs: Tab[] = [];
  protected application?: ApplicationDto;
  protected applicationConstructor?: ApplicationConstructorSettings;
  protected documents: ApplicationDocumentList = new ApplicationDocumentList();
  protected typeTooltip = '';
  protected readonly typeReceiptDescription = 'По готовности справка будет доступна на скачивание в данной ' +
    'заявке. Справка будет подписана усиленной квалифицированной электронной подписью. ';
  protected typeReceiptName?: string;

  protected readonly dictPerson = this.studentService.getAllStudents();
  protected applicationCategoriesTree: ApplicationCategoryTree[] = [];
  protected applicationCategory?: ApplicationCategoryTree;
  protected statuses: ApplicationStatus[] = [];
  protected dictTypeReceipt: TypeReceipt[] = [];
  protected filteredTypeReceipt: TypeReceipt[] = [];

  protected formGroup = new FormGroup({});
  protected filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  protected readonly DocumentTypes = DocumentTypes;
  protected readonly ApplicationTabsEnum = ApplicationTabsEnum;
  protected readonly ReceiptTypesEnum = ReceiptTypesEnum;
  protected readonly displayFIOStatuses = displayFIOStatuses;

  protected applicationSubscription$?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private applicationTypeSelectService: ApplicationTypeSelectService,
    private applicationStatusService: ApplicationStatusService,
    private studentService: StudentService,
    private applicationConstructorService: ApplicationConstructorService,
    private constructorDocumentService: ApplicationConstructorDocumentService,
    private editFormService: ApplicationEditFormService,
    private applicationFormService: ApplicationFormService,
    private typeReceiptService: TypeReceiptService,
    private documentsService: DocumentsService,
    private userAccessService: UserAccessService
  ) { }

  async ngOnInit() {
    if (this.isAdd) {
      this.getTypeReceipts();
      this.editable = true;
      this.formGroup = this.createAddFormGroup();
    } else {
      await this.getStatuses();
      this.applicationSubscription$ = this.applicationFormService.refresh$.subscribe(() => this.getApplication());
    }
  }

  private getTypeReceipts() {
    this.typeReceiptService.getAll().subscribe(data => {
      this.dictTypeReceipt = data;
      this.filteredTypeReceipt = data;
    });
  }

  private async getCategoriesTree() {
    await lastValueFrom(this.applicationTypeSelectService.getCategoriesTree(this.studentId)).then((response) => {
      this.applicationCategoriesTree = response;
    });
  }

  private async getStatuses() {
    await lastValueFrom(this.applicationStatusService.getAll()).then((response) => {
      this.statuses = response;
    });
  }

  private getApplication() {
    this.applicationFormService.getApplicationById(this.id).subscribe({
      next: (response) => {
        this.application = response.application;
        this.statusText = this.statuses.find((item) =>
          item.applicationStatusEnum === this.application?.statusEnum)?.name ?? '';
        this.studentId = this.application.student.id ?? '';
        this.typeReceiptName = response.application.dictReceiptType.name;

        const userRoles = this.userAccessService.currentUserAccess$.value?.user.roles
          .map((item) => item.id) ?? [];
        this.editable = this.isApplicationEditable(response.personAccessRight, userRoles, this.application);
        this.displayInProgressButton =this.application.applicationStatusInfo.statusChangePersonId
          === this.userAccessService.currentUserAccess$.value?.user.personId;
        this.allowedActions = allowedStatuses.get(this.application.statusEnum) ?? [];

        this.formGroup = this.createEditFormGroup();

        this.displayRegistrationLitera();

        this.patchTabs();
        const dynamicStartIndex = this.tabs.findIndex((tab) => tab.selector === ApplicationTabsEnum.Dynamic);
        this.editFormService.setTabsData(this.application.tabs, dynamicStartIndex);
        this.cdRef.detectChanges();
      },
      error: (error) => {
        this.notificationsService.showError(error.error);
        this.router.navigateByUrl('/mfc');
      },
    });
  }

  private displayRegistrationLitera() {
    if (this.application?.registrationNumber?.registrationNumberLitera) {
      const root = document.querySelector(':root') as HTMLElement;
      root.style.setProperty('--var-content', `'/${this.application.registrationNumber.registrationNumberLitera}'`);
    }
  }

  private isApplicationEditable(access: AccessRights, roles: string[], application: ApplicationDto) {
    return access === AccessRights.Write
      && editableStatuses.includes(application.statusEnum)
      && this.getCurrentStepAccess(roles, application.approvalSettings);
  }

  private getCurrentStepAccess(roles: string[], settings: ApplicationApprovalSettings[]) {
    const currentStages = this.editFormService.mapApprovalSettings(settings, roles)
      .filter((item) => item.isCurrentStage && !item.isSignatoryRequired);

    return currentStages.some((item) => item.hasAccessToApprove);
  }

  private getApplicationConstructor(typeId: string, studentId: string) {
    this.applicationConstructorService.getApplicationConstructorByStudentId(typeId, studentId).subscribe({
      next: (response) => {
        this.applicationConstructor = response;
        const dictTypeReceiptIds = response.basicSettings.dictTypeReceiptIds;
        if (dictTypeReceiptIds.length === 1) {
          (<AbstractControl>this.formGroup.get('dictTypeReceiptId')).patchValue(dictTypeReceiptIds[0]);
        }
        this.filteredTypeReceipt = this.dictTypeReceipt.filter((item) => dictTypeReceiptIds.includes(item.id));
        this.typeReceiptName = this.filteredTypeReceipt[0]?.name;
        this.patchTabs();
        this.cdRef.detectChanges();
      },
      error: (error) => this.notificationsService.showError(error.error),
    });
  }

  private getTooltip(typeId?: string) {
    return this.applicationCategory?.dictApplicationTypes.find(a => a.externalId === typeId)?.tooltip ?? '';
  }

  private createAddFormGroup() {
    const form = new CreateApplicationMainForm();
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(form).forEach((key) => {
      arr[key] = new FormControl({
        value: form[key as keyof typeof form],
        disabled: !this.isAdd
      });
    });
    return new FormGroup(arr);
  }

  private createEditFormGroup() {
    const form = new EditApplicationMainForm();

    if (this.application?.registrationNumber?.registrationNumberLitera) {
      form.registrationNumber = this.application.registrationNumber.registrationNumber;
      form.issueDate = new Date(this.application?.issueDate);
    }
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(form).forEach((key) => {
      arr[key] = new FormControl(form[key as keyof typeof form], Validators.required);
    });
    return new FormGroup(arr);
  }

  private patchTabs() {
    let tabsSettings;
    if (this.isAdd) {
      this.tabs = [];
      tabsSettings = this.applicationConstructor?.tabs;
    } else {
      if (this.application?.documents) {
        const approvalDocuments = this.application.approvalSettings.map((item) =>
          item.applicationConstructorDocumentId).filter((id) => id) ?? [];
        this.documents.signature = this.application.documents.filter((doc) =>
          approvalDocuments.includes(doc.externalId)) ?? [];
        this.documents.ready = this.application.documents.filter((doc) =>
          doc.documentType === DocumentTypes.Document &&
          !approvalDocuments.includes(doc.externalId)) ?? [];
      }

      this.tabs = [...tabs.filter((tab) =>
        (tab.selector !== ApplicationTabsEnum.Documents || this.documents.signature.length) &&
        (tab.selector !== ApplicationTabsEnum.ReadyDocuments || this.documents.ready.length))
      ];
      tabsSettings = this.application?.tabs;
    }

    tabsSettings?.forEach((tab: TabsSettings, index) => {
      const commentsIndex = this.tabs.findIndex((tab) => tab.selector === ApplicationTabsEnum.Comments);
      this.tabs.splice(index + commentsIndex, 0,
        {
          id: tab.externalId,
          name: tab.name,
          selector: ApplicationTabsEnum.Dynamic,
          displayInAddForm: true
        });
    });
  }

  private resetField(resetCategory: boolean = false) {
    this.formGroup.get(resetCategory ? 'category' : 'type')?.reset();
    resetCategory ? this.categoryChange() : this.typeChange();
  }

  protected studentChange(value?: string) {
    this.studentId = value ?? '';
    if (value) {
      this.getCategoriesTree();
    } else {
      this.applicationCategoriesTree = [];
    }
    this.resetField(true);
  }

  protected typeChange(id?: string) {
    this.typeTooltip = this.getTooltip(id);

    if (id) {
      this.getApplicationConstructor(id, this.studentId);
    } else {
      this.typeReceiptName = undefined;
      this.applicationConstructor = undefined;
      this.patchTabs();
    }
  }

  protected categoryChange(id?: string) {
    this.applicationCategory = this.applicationCategoriesTree.find((item) => item.externalId === id);
    const typeMatch = this.applicationCategory?.dictApplicationTypes.some((item) =>
      item.externalId === this.formGroup.get('type')?.value);
    if (!typeMatch) {
      this.resetField();
    }
  }

  protected downloadFile(document: ConstructorDocument) {
    this.constructorDocumentService.getDocument(
      this.applicationConstructor?.externalId ?? this.application?.applicationConstructorId ?? '',
      document.externalId
    ).subscribe({
      next: (response: Blob) => saveAs(response, document.name),
      error: (error) => this.notificationsService.showError(error.error.Message ?? error),
    });
  }

  protected hyperlinkNavigate() {
    window.open(`/contingent/studentmaininfo/${this.application?.student.id}`, '_blank');
  }

  protected goBack() {
    const isSaved = !(this.hasChanges || this.editFormService.hasFormChanges(this.currentTab));
    if (this.isAdd || isSaved) {
      this.editFormService.clearTabsData();
      this.router.navigateByUrl('/mfc');
    } else {
      const dialog = openDialog(this.dialogService, `Вы хотите закрыть форму без сохранения изменений?`);
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.editFormService.clearTabsData();
          this.editFormService.resetHasChanges();
          this.router.navigateByUrl('/mfc');
        }
      });
    }
  }

  protected onTabChange(source: SelectEvent) {
    if (this.currentTab === source.index) {
      return;
    }

    if (this.tabs[this.currentTab].selector === ApplicationTabsEnum.Dynamic) {
      this.editFormService.save$.next(this.currentTab);
    }
    this.currentTab = source.index;
  }

  protected onSave() {
    const receiptType = this.formGroup.get('dictTypeReceiptId')?.value;
    if (this.isAdd && !receiptType) {
      this.notificationsService.showError('Выберите вариант получения');
      return;
    }

    this.editFormService.save$.next(this.currentTab);
    const form: ApplicationForm = {applicationPropertiesValueDto: this.editFormService.getTabsValues()};
    if (this.isAdd) {
      form.dictTypeReceiptId = receiptType;
      form.applicationConstructorId = this.applicationConstructor?.externalId;
    } else {
      form.registrationNumber = this.formGroup.get('registrationNumber')?.value;
      form.issueDate = this.formGroup.get('issueDate')?.value;
    }

    console.log(form)

    this.applicationFormService.saveApplication(this.formGroup.get('student')?.value ?? this.studentId, form, this.id).subscribe({
      next: (response) => {
        this.notificationsService.showSuccess('Успешно');
        this.editFormService.resetHasChanges();
        if (this.isAdd) {
          this.router.navigateByUrl(`/mfc/applicationForm/${response}`);
        } else {
          this.getApplication();
        }
      },
      error: (error) => this.notificationsService.showError(error.error.Message ?? error.error),
    });
  }

  protected changeStatus(status: ApplicationStatusesEnum) {
    const isSaved = !(this.hasChanges || this.editFormService.hasFormChanges(this.currentTab));
    if (isSaved) {
      this.applicationFormService.changeStatus(this.id, status).subscribe({
        next: () => {
          this.notificationsService.showSuccess('Успешно');
          this.getApplication();
        },
        error: (error) => this.notificationsService.showError(error.error.Message)
      });
    } else {
      this.notificationsService.showError('Сохраните изменения');
    }
  }

  ngOnDestroy(): void {
    this.applicationSubscription$?.unsubscribe();
  }
}

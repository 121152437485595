export class DictAttestation {
  public id!: string;
  public name!: string;
  public shortName!: string;
}

export class DictCriterialMark {
  public id!: string;
  public name!: string;
  public shortName!: string;
  public markName!: string;
}

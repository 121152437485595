<gia-settings></gia-settings>

<h1>Создание справочников</h1>

<kendo-grid
  [kendoGridBinding]="dicts"
  [pageable]="pagerSettings"
  (cellClick)="editRow($event)"
  (remove)="onRemove($event)"
  (add)="addRow()"
  [filterable]="!isLine"
  [pageSize]="10"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
    <button
      kendoGridAddCommand
      themeColor="success"
      *ngIf="!isLine"
      icon="plus"
    >
      Добавить
    </button>
    <div class="toolbarButtons" *ngIf="isLine">
      <button kendoButton (click)="onCancel()">Отмена</button>
      <button
        kendoButton
        (click)="saveRow()"
        themeColor="primary"
        [disabled]="formGroup!.invalid"
      >
        Сохранить
      </button>
    </div>
  </ng-template>
  <kendo-grid-column
    [width]="400"
    headerClass="gridHeader dictionaries"
    field="name"
    title="Название"
  >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell
        [column]="column"
        [filter]="filter"
        [showOperators]="false"
        operator="contains"
      >
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">
        {{ dataItem.name }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="250"
                     headerClass="gridHeader dictionaries"
                     field="isHidden"
                     title="Скрыть">
    <ng-template kendoGridFilterCellTemplate></ng-template>
    <ng-template kendoGridEditTemplate>
      <div class="table__cell table__cell--custom table__cell--input alignCells" [formGroup]="formGroup">
        <input formControlName="isHidden" type="checkbox" kendoCheckBox />
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div class="alignCells">
        <input type="checkbox" kendoCheckBox [(ngModel)]='dataItem.isHidden' disabled />
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="250"
    headerClass="gridHeader dictionaries"
    field="sortNumber"
    title="Порядковый номер для сортировки при отображении"
  >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell
        [column]="column"
        [filter]="filter"
        format="n0"
        [showOperators]="false"
        operator="eq"
      >
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
      </kendo-grid-numeric-filter-cell>
    </ng-template>
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <div class="table__cell table__cell--custom table__cell--input">
        <kendo-numerictextbox
          [min]="1"
          format="n0"
          [decimals]="0"
          [formControl]="formGroup.get('sortNumber')"
        >
        </kendo-numerictextbox>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ dataItem.sortNumber }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="editable"
    headerClass="gridHeader dictionaries"
    [width]="160"
  >
    <ng-template kendoGridCellTemplate>
      <span class="alignCells">
        <button
          kendoGridRemoveCommand
          themeColor="secondary"
          icon="delete"
          fillMode="flat"
          size="none"
          class="custom-size"
          rounded="full"
        ></button>
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

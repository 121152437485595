<publications-settings></publications-settings>
<div class="container">
    <h1>Добавление автора</h1>
    <div class="buttons">
        <button (click)="onCancel()" kendoButton>Отмена</button>
        <button (click)="onSave()" [disabled]="!currentAuthor.valid" kendoButton themeColor="primary">Сохранить</button>
    </div>
    <div [formGroup]="currentAuthor" class="k-mt-8 editForm">
        <span class="row">
            <h2>Тип автора</h2>
            <kendo-textbox formControlName="type">
            </kendo-textbox>
        </span>
        <span class="row">
            <h2>Фамилия</h2>
            <kendo-textbox formControlName="lastName"></kendo-textbox>
            <span class="required">*</span>
        </span>
        <span class="row">
            <h2>Имя</h2>
            <kendo-textbox formControlName="firstName">
            </kendo-textbox>
            <span class="required">*</span>
        </span>
        <span class="row">
            <h2>Отчество</h2>
            <kendo-textbox formControlName="middleName">
            </kendo-textbox>
        </span>
        <span class="row">
            <h2>Дата рождения</h2>
            <kendo-datepicker formControlName="birthday">
            </kendo-datepicker>
        </span>
        <span class="row">
            <h2>Идентификатор</h2>
            <kendo-maskedtextbox [mask]="mask" formControlName="identificator">
            </kendo-maskedtextbox>
        </span>
    </div>
</div>

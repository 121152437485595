import { Person } from "./person.model";
import { Student } from "./student.model";
import {Guid} from "guid-typescript";

export class StudPlan{
  public studPlanId?: Guid;
  public studentId?: Guid;
  public student?: Student;
  public planId?: string;
  public scientificPersonId?: Guid;
  public scientificPersonName?: string;
  public departmentId?: Guid;
  public dessertationTitle?: string;
  public practicePersonId?: Guid;
  public practicePersonName?: string;
}

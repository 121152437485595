export const items: any[] = [
    {
      text: "Настройки",
      icon: "cog",
      items: [
        
        {
          text: "Настройки доступа",
          path: "/alert/useraccess",
          admin: true
        },
      ],
    },
  ];

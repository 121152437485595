import {Component, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {FormationDiplomaService} from '../../../../services/gia/formationdiploma.service';
import {TypeDisciplineModel, DisciplineModel, ControlActionModel, saveDisciplineModel} from '../../../../models/gia/formationdiploma.model';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PagerPosition, GridComponent, GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {PagerType} from '@progress/kendo-angular-pager';

@Component({
    selector : 'app-adddiscipline',
    templateUrl: './adddiscipline.component.html',
    styleUrls : ['./adddiscipline.component.scss']
})

export class AddDisciplineComponent implements OnInit{

    public disciplinesType: TypeDisciplineModel[] = [];

    public disciplines: DisciplineModel[] = [];
    public filterDisciplines: DisciplineModel[] = [];
    public loading: boolean = false;

    public pageSize = 10;
    public type: PagerType = 'numeric';
    public buttonCount = 3;
    public previousNext = true;
    public position: PagerPosition = 'bottom';
    public info = true;
    public skip = 0;

    public selectionDisciplines: saveDisciplineModel[] = [];

    public typeDiscipline: string = "";
    public discipline: string = "";

    public controlActions: ControlActionModel[] = [];

    public studentId: string = "";
    public editDiscipline: boolean = false;
    public transferDiscipline: any = new Object;
    public deletedDisciplineId: string | null = null;

    @ViewChild('gridDiscipline') private gridDiscipline!: GridComponent;
    @ViewChild('selectGrid') private selectGrid!: GridComponent;
    public gridView!: GridDataResult;

    public electivePhysicalTypeId = '4c25e4c9-dec4-484d-85a3-3df1b522d1e0';

    constructor(
        private location: Location,
        private formationDiplomaService: FormationDiplomaService,
        private notificationService: NotificationsService,
    ) {}

    ngOnInit() {
        this.getControlAction();
        this.getURLStudentId();
        
        this.transferDiscipline = this.formationDiplomaService.sendDiscipline.getValue().discipline;
        if (this.transferDiscipline.disciplineId) {
            this.editDiscipline = true;
            if (this.transferDiscipline.disciplineType !== null)  {
                this.typeDiscipline = this.transferDiscipline.disciplineType;
                this.discipline = this.transferDiscipline.disciplineName;
            }
            this.changeTransferDiscipline();
            this.getDiscipline()
        }
        this.getTypesDiscipline();
    }

    private getURLStudentId() {
        const url = window.location.href;
        const indexStart = url.indexOf('formatdiploma');
        const indexEnd = url.lastIndexOf('/');
        this.studentId = url.slice(indexStart + 14, indexEnd);
        this.formationDiplomaService.getStudentId.next({
            studentId: this.studentId
        });
    }

    public getTypesDiscipline() {
        this.formationDiplomaService.getTypesDiscipline()
            .subscribe(response => {
                this.disciplinesType = response;
            })
    }

    public getDiscipline() {
        this.loading = true;
        this.formationDiplomaService.getDisciplines(this.typeDiscipline, this.discipline)
          .subscribe(response => {
            this.disciplines = response;
            this.loading = false;
            if (this.disciplines) {
                this.filterDisciplines = this.disciplines.slice();
                this.filterDisciplines.sort((a, b) => a.name.localeCompare(b.name));
            }
    
            this.getFilterDisciplines();
          })
    }

    public getControlAction() {
        this.formationDiplomaService.getControlAction()
            .subscribe(response => {
                this.controlActions = response;
            })
    }

    public editHandler(dataItem: DisciplineModel) {
        if (this.editDiscipline && this.selectionDisciplines.length == 1) {
            this.notificationService.showError('При редактировании в Списке выбранных дисциплин может быть только одна дисциплина');
            return;
        }

        const object = {'disciplineName': dataItem.name,
                        'disciplineId': dataItem.id,
                        'attachmentDisciplineName': null,
                        'controlActionEnum': null ,
                        'isFacultative': false,
                        'isCourseWork': false,
                        'hours': null,
                        'creditUnit': null,
                        'hoursContactWork': null,
                        'disciplineType': this.typeDiscipline};

        this.selectionDisciplines.push(object);

        const index = this.filterDisciplines.findIndex(el => el.id == dataItem.id);
        this.filterDisciplines.splice(index, 1);
        this.pageChange({skip: this.skip, take: this.pageSize});
    }

    public deleteDiscipline(dataItem: saveDisciplineModel) {
        if (this.editDiscipline && dataItem.isAddedManually !== undefined) this.deletedDisciplineId = dataItem.disciplineId;

        if (this.disciplines.some(el => el.name == dataItem.disciplineName) &&  dataItem.disciplineId) {
            this.filterDisciplines.push({'id': dataItem.disciplineId, 'name': dataItem.disciplineName});
            this.filterDisciplines.sort((a, b) => a.name.localeCompare(b.name));
        }
        
        const index = this.selectionDisciplines.findIndex(el => el.disciplineId == dataItem.disciplineId);
        this.selectionDisciplines.splice(index, 1);
        this.pageChange({skip: this.skip, take: this.pageSize});
    }

    private getFilterDisciplines() {
        const names = [...new Set(this.selectionDisciplines.map(el => el.disciplineName))];
        this.filterDisciplines = this.filterDisciplines.filter(el => !names.includes(el.name));
        this.pageChange({skip: 0, take: this.pageSize});
    }

    public back() {
        this.formationDiplomaService.tabApplication.next({
            application: true
        });
        this.location.back();
    }

    public clickCheckbox(dataItem: saveDisciplineModel, value: string) {
        switch(value) {
            case 'elective':
                dataItem.isFacultative = !dataItem.isFacultative;
                if (dataItem.isFacultative) dataItem.hoursContactWork = null;
                break;
            case 'courseWork':
                dataItem.isCourseWork = !dataItem.isCourseWork;
                break;
        }
    }

    public checkField(value: string) {
        return value == null;
    }

    public checkValidate() {
        return this.selectionDisciplines.some(el => el.controlActionEnum == null || el.creditUnit && el.creditUnit < 0 || el.hours && el.hours < 0
               || !el.creditUnit || !el.hours || el.disciplineType !== this.electivePhysicalTypeId && !el.isFacultative && !el.hoursContactWork) || 
               !this.editDiscipline && this.selectionDisciplines.length == 0;
    }

    public saveDiscipline() {
        this.getURLStudentId();

        this.formationDiplomaService.addDisciplines(this.studentId, this.selectionDisciplines) 
            .subscribe(response => {
              if (response == true) {
                this.notificationService.showSuccess('Дисциплины успешно сохранены');
                this.back();
              }
            },
            error => {
                this.notificationService.showError(error.error);
            })
    }

    public changeTransferDiscipline() {
        this.selectionDisciplines.push(this.transferDiscipline);
    }

    public onChangeControlAction(value: number, dataItem: saveDisciplineModel) {
        if (this.editDiscipline) dataItem.markId = null;
    }

    public saveEditedDiscipline() {
        const data = {
            deletedDisciplineId: this.deletedDisciplineId,
            editedDiscipline: this.selectionDisciplines.length !== 0 ? this.selectionDisciplines[0] : null
        }
        this.formationDiplomaService.editDiscipline(this.studentId, data)
            .subscribe(response => {
              if (response == true) {
                this.notificationService.showSuccess('Данные изменены');
                this.back();
              }
            },
            error => {
              this.notificationService.showError(error.error)
            })
    }

    public pageChange(value: PageChangeEvent): void {
      this.skip = value.skip;
      const arrayDiscipline = this.filterDisciplines.slice(this.skip, this.skip + this.pageSize);
      this.gridView = {
        data: arrayDiscipline,
        total: this.filterDisciplines.length,
      };
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PhoneTypes } from '../../models/contingent/phonetypes.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class PhonetypesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.phonetypes}`;

  constructor(private http: HttpClient) { }

  //Get all PhoneTypes
  public getAllPhoneTypes(): Observable<PhoneTypes[]> {
    return this.http.get<PhoneTypes[]>(this.baseUrl);
  }

  //Add PhoneTypes
  addPhoneTypes(phoneTypes: PhoneTypes): Observable<PhoneTypes> {
    return this.http.post<PhoneTypes>(this.baseUrl, phoneTypes)
      .pipe(catchError(this.handleError));
  }

  //Delete PhoneTypes
  deletePhoneTypes(id: string): Observable<PhoneTypes> {
    return this.http.delete<PhoneTypes>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit PhoneTypes
  updatePhoneTypes(phoneTypes: PhoneTypes): Observable<PhoneTypes> {
    return this.http.put<PhoneTypes>(this.baseUrl + '/' + phoneTypes.dictPhoneTypeExternalId, phoneTypes)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

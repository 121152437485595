import {DictDisciplineGet} from "./externals.model";
import {FlowGet, GroupGet} from "./departmentworkload.model";

export class TeacherLoadGet {
  teacherLoads!: TeacherLoadGetDto[];
}

export class TeacherLoadGetDto {
  discipline!: DictDisciplineGet;
  flows!: FlowGet[];
  groups!: GroupGet[];
}

export class AvailablePersonGet {
  persons!: TeacherLoadPersonGet[];
}

export class TeacherLoadPersonGet {
  id!: string;
  fio!: string;
}

export class AvailableKathedrasGet {
  kathedras!: TeacherLoadKathedrasGet[];
}

export class TeacherLoadKathedrasGet {
  id!: string;
  name!: string;
  sName!: string;
}

export class AvailableFacultyGet {
  faculties!: TeacherLoadFacultyGet[];
}

export class TeacherLoadFacultyGet {
  id!: string;
  name!: string;
  sName!: string;
}

export class AvailableTrainingLevelsGet {
  trainingLevels!: TeacherLoadTrainingLevelsGet[];
}

export class TeacherLoadTrainingLevelsGet {
  id!: string;
  name!: string;
}

export class AvailableStudyFormsGet {
  studyForms!: TeacherLoadStudyFormsGet[];
}

export class TeacherLoadStudyFormsGet {
  id!: string;
  studyFormName!: string;
}

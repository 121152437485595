import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { StudyformtypeService } from 'src/app/services/dicts/studyformtype.service';
import { StudyFormType } from 'src/app/models/dicts/studyformtype.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { Router} from "@angular/router";

@Component({
  selector: 'app-studyformtype',
  templateUrl: './studyformtype.component.html',
  styleUrls: ['./studyformtype.component.css']
})
export class StudyformtypeComponent implements OnInit {

  public studyformtypes: StudyFormType[] = [];
  public studyformtype: StudyFormType = {
    dictStudyFormTypeId: 0,
    studyFormTypeName: '',
    studyFormTypeSName: '',
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private studyformtypeService: StudyformtypeService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService,
              private router: Router,
  ) {

  }

  public ngOnInit(): void {
    this.getAccessLevel()
   // this.getAllStudyFormType();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllStudyFormType();
  }


  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      academicStateName: "",
      academicStateSName: "",
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.studyformtypeService.addStudyFormType(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllStudyFormType();
              this.notificationService.showSuccess("Добавлено");
              this.studyformtype = {
                dictStudyFormTypeId: 0,
                studyFormTypeName: '',
                studyFormTypeSName: ''
              }
            },
            error:() => {
              this.notificationService.showError("Не удалось сохранить запись");
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.studyformtypeService.updateStudyFormType(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllStudyFormType();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError("Не удалось изменить запись");
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllStudyFormType() {
    this.studyformtypeService.getAllStudyFormType()
      .subscribe(
        response => {
          this.studyformtypes = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelEducation == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelEducation != 1;
            this.getAllStudyFormType();
          }
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.studyFormTypeName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {

        if(result.text == "Да"){
          this.opened = false;
          this.studyformtypeService.deleteStudyFormType(dataItem.dictStudyFormTypeExternalId)
          .subscribe({
            next:() => {
              this.getAllStudyFormType();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { academicStateName?: string; academicStateSName?: string; dictStudyFormTypeExternalId?: string; dictStudyFormTypeId?: any; studyFormTypeName?: any; studyFormTypeSName?: any; }) =>
  new FormGroup({
    dictStudyFormTypeId: new FormControl(dataItem.dictStudyFormTypeId),
    dictStudyFormTypeExternalId: new FormControl(dataItem.dictStudyFormTypeExternalId),
    studyFormTypeName: new FormControl(dataItem.studyFormTypeName, Validators.required),
    studyFormTypeSName: new FormControl(dataItem.studyFormTypeSName, Validators.required),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

<gia-settings></gia-settings>

<h1>Конструктор протокола ГИА</h1>

<kendo-tabstrip class="custom-tabs">
    <kendo-tabstrip-tab title="Протокол ГИА" [selected]="true" [disabled]="changeStateExam && selectionDisciplines.length !== 0" >
        <ng-template kendoTabContent>
            <div class="selectContent w-850 m-t20">
                <dd class="list">
                  <span class="selectText">
                    Филиал
                  </span>
                  <kendo-dropdownlist class="w-600"
                                      [ngClass]="{filterdisabled: changeTab}"
                                      [data]="allFilial"
                                      textField="shortName"
                                      valueField="id"
                                      [valuePrimitive]="true"
                                      (valueChange)="onChangeGia($event)"
                                      [(ngModel)]="filial"
                                     >
                  </kendo-dropdownlist>
                </dd>
            
                <dd class="list">
                  <span class="selectText">
                    Уровень подготовки
                  </span>
                  <kendo-dropdownlist class="w-600"
                                      [ngClass]="{filterdisabled: changeTab}"
                                      [data]="trainingLevels"
                                      textField="name"
                                      valueField="id"
                                      [valuePrimitive]="true"
                                      (valueChange)="onChangeGia($event)"
                                      [(ngModel)]="trainingLevel"
                                      >
                  </kendo-dropdownlist>
                </dd>
            </div>

            <p class="note">Примечание: для отображения конструктора протокола ГИА требуется выбрать филиал и уровень подготовки</p>

            <div class="consrucor m-t20" *ngIf="filterGia">
                <div *ngIf="tabsList.length !== 0">
                    <button kendoButton (click)="cancelProtocol()">Отмена</button>
                    <button kendoButton themeColor="primary" class="m-l15" (click)="saveStructure()">Сохранить</button>
                </div>
                <div class="m-t20 flex">
                      <span class="text w-230">
                        Вкладка
                      </span>
                      <kendo-dropdownlist class="w-600 m-l20"
                                          *ngIf="tabsList.length !== 0"
                                          [ngClass]="{filterdisabled: changeTab}"
                                          [data]="tabsList"
                                          textField="tabName"
                                          valueField="tabName"
                                          [valuePrimitive]="true"
                                          (valueChange)="onChangeTab($event, true)"
                                          [(ngModel)]="tabName"
                                        >
                      </kendo-dropdownlist>
                      <button kendoButton icon="edit" class="m-l15" (click)="editTabName()" *ngIf="tabsList.length !== 0"></button>
                      <button kendoButton themeColor="success" icon="plus" class="m-l15" (click)="addTab()">Добавить вкладку</button>
                </div>

                <div *ngIf="tabsList.length !== 0">
                  <button kendoButton themeColor="success" icon="plus" class="m-t20" (click)="addGroupField()">Добавить группу полей</button>
                  
                  <div *ngFor="let group of tabData.displayPropertyGroups">
                    <div class="m-t30 flex">
                      <span class="text w-230">
                        Наименование группы полей
                      </span>
                      <kendo-textbox class="w-600 m-l20" [ngClass]="{redborder: checkField(group.name)}"
                                     (valueChange)="onChange($event)"
                                     [clearButton]="true"
                                     [(ngModel)]="group.name">
                      </kendo-textbox>
                      <button kendoButton class="m-l15" (click)="deleteGroup(group.order)">Удалить группу</button>
                    </div>

                    <div class="selectContent w-850 m-t20">
                      <dd class="list">
                        <span class="selectText">
                          Порядок расположения групп полей
                        </span>
                        <kendo-numerictextbox class="w-600" [ngClass]="{'redborder': !group.order || checkField(group.order)}"
                            [min]="1"
                            [spinners]="false"
                            [decimals]="0"
                            format="n0"
                            (valueChange)="onChange($event)"
                            [(ngModel)]="group.order"
                        ></kendo-numerictextbox>
                      </dd>
                      <p class="note red" *ngIf="validateOrder(group.order)">Этот порядковый номер уже занят. Пожалуйста, выберите другой.</p>
                    </div>
                    <p class="textfield m-t20 m-l10">Список полей для отображения</p>

                    <kendo-grid class="groupfield m-t20"
                                [kendoGridBinding]="group.displayProperties"
                                (remove)="removeField(group.order, $event)"
                    >

                        <kendo-grid-column [width]="60" *ngIf="group.displayProperties.length > 1"
                                          headerClass="gridHeader dictionaries">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <span class="displaygrid">
                                  <button kendoButton icon="arrow-60-up" fillMode="flat" size="none" class="custom-size" rounded="full" (click)="fieldUp(dataItem, rowIndex, group.displayProperties)">
                                  </button>
                                  <button kendoButton icon="arrow-60-down" fillMode="flat" size="none" class="custom-size" rounded="full" (click)="fieldDown(dataItem, rowIndex, group.displayProperties)">
                                  </button>
                                </span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="name"
                                          title="Наименование поля"
                                          [width]="200"
                                          headerClass="gridHeader dictionaries">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="alignCells">
                                  <kendo-textbox [ngClass]="{redborder: checkField(dataItem.name)}"
                                                 [clearButton]="true"
                                                 (valueChange)="onChange($event)"
                                                 [(ngModel)]="dataItem.name">
                                  </kendo-textbox>
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        
                        <kendo-grid-column field="printFormName"
                                          [width]="250"
                                          headerClass="gridHeader dictionaries">
                            <ng-template kendoGridHeaderTemplate let-column>
                              <div> Название метки для печатной формы
                                    <span kendoTooltip class="tooltip k-icon k-i-info k-icon-md" [title]="tooltip"></span>
                              </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="alignCells">
                                  <kendo-textbox [ngClass]="{redborder: checkField(dataItem.printFormName) || checkEnglish(dataItem.printFormName)}"
                                                  [clearButton]="true"
                                                  (valueChange)="onChange($event)"
                                                  [(ngModel)]="dataItem.printFormName">
                                  </kendo-textbox>
                                </span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="dataTypeEnum"
                                          title="Тип данных"
                                          [width]="180"
                                          headerClass="gridHeader dictionaries">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="alignCells">
                                  <kendo-dropdownlist [ngClass]="{redborder: checkField(dataItem.dataTypeEnum)}"
                                                      [data]="dataTypes"
                                                      textField="text"
                                                      valueField="id"
                                                      [valuePrimitive]="true"
                                                      (valueChange)="onChange($event, dataItem, columnName)"
                                                      [(ngModel)]="dataItem.dataTypeEnum">
                                  </kendo-dropdownlist>
                                </span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="isRequired"
                                          title="Обязательность заполнения"
                                          [width]="150"
                                          headerClass="gridHeader dictionaries">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="alignCells">
                                  <kendo-dropdownlist [disabled]="dataItem.dataTypeEnum == types.title"
                                                      [data]="boolOptions"
                                                      textField="text"
                                                      valueField="id"
                                                      [valuePrimitive]="true"
                                                      (valueChange)="onChange($event)"
                                                      [(ngModel)]="dataItem.isRequired">
                                  </kendo-dropdownlist>
                                </span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="dictionaryId"
                                          title="Справочник"
                                          [width]="200"
                                          headerClass="gridHeader dictionaries">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="alignCells">
                                  <kendo-dropdownlist [disabled]="dataItem.dataTypeEnum !== types.dict"
                                                      [ngClass]="{redborder: dataItem.dataTypeEnum == types.dict && checkField(dataItem.dictionaryId)}"
                                                      [data]="dicts"
                                                      textField="name"
                                                      valueField="id"
                                                      [valuePrimitive]="true"
                                                      (valueChange)="onChange($event, dataItem, typeDict)"
                                                      [(ngModel)]="dataItem.dictionaryId">
                                  </kendo-dropdownlist>
                                </span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column [width]="60" *ngIf="group.displayProperties.length > 1">
                          <ng-template kendoGridCellTemplate>
                            <span class="alignCells">
                              <button kendoGridRemoveCommand icon="close" fillMode="flat" size="none" class="custom-size" rounded="full">
                              </button>
                            </span>
                          </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>

                    <button kendoButton fillMode="outline" size="small" icon="plus" class="m-t20 addfield" (click)="addField(group.order)">Добавить поле</button>
                    <hr class="line"/>
                  </div>

                  <p class="textfield m-t20 m-l10">Оценка</p>
                  <div class="m-t20 m-l20">
                    <input type="checkbox" kendoCheckBox (change)="onChange(tabData.isMarkIncluded)" [(ngModel)]="tabData.isMarkIncluded"/>
                    <kendo-label class="m-l10"
                        text="Включить выбор итоговой оценки"
                    ></kendo-label>
                  </div>
                  <p class="note m-t20 m-l20">Примечание: 
                              <br>Отметьте поле, чтобы включить блок "Оценка" в форму протокола ГИА для выбора итоговой оценки.
                              <br>Если дисциплины ГИА выбраны на вкладке “Государственные экзамены”, список этих дисциплин появится в блоке "Оценка" для проставления оценок по каждой из них.
                  </p>

                  <div class="m-t30 flex">
                    <span class="text w-230">
                      Скрыть вкладку
                    </span>
                    <kendo-dropdownlist class="w-600 m-l15"
                                        [data]="boolOptions"
                                        textField="text"
                                        valueField="id"
                                        [valuePrimitive]="true"
                                        (valueChange)="onChange($event)"
                                        [(ngModel)]="tabData.isHidden"
                                      >
                    </kendo-dropdownlist>
                    <button kendoButton themeColor="primary" class="redbutton m-l15" (click)="deleteTab()">Удалить вкладку</button>
                  </div>
                
                  <div class="m-t20">
                    <button kendoButton (click)="cancelProtocol()">Отмена</button>
                    <button kendoButton themeColor="primary" class="m-l15" (click)="saveStructure()">Сохранить</button>
                  </div>
                </div>
            </div>

            <kendo-dialog *ngIf="opened"
                          title="Введите название новой вкладки"
                          (close)="close('cancel')"
                          [minWidth]="250"
                          [width]="450"
                          class="popup"
            >
              <kendo-textbox [(ngModel)]="newTabName">
              </kendo-textbox>
              <kendo-dialog-actions>
                <button kendoButton (click)="close('no')">Отмена</button>
                <button kendoButton [disabled]="!newTabName || newTabName.trim() == ''"
                        (click)="close('yes')"
                        themeColor="primary">
                  {{ editTab ? "Сохранить" : "Добавить вкладку" }}
                </button>
              </kendo-dialog-actions>
            </kendo-dialog>

            <div kendoDialogContainer></div>

        </ng-template>
    </kendo-tabstrip-tab>

<!-- Государственные экзамены -->

    <kendo-tabstrip-tab title="Государственные экзамены" [selected]="false" [disabled]="changeTab">
        <ng-template kendoTabContent>
          <div class="selectContent w-850 m-t20">
            <dd class="list">
              <span class="selectText">
                Филиал
              </span>
              <kendo-dropdownlist class="w-600"
                                  [ngClass]="{filterdisabled: changeStateExam && selectionDisciplines.length !== 0}"
                                  [data]="allFilial"
                                  textField="shortName"
                                  valueField="id"
                                  [valuePrimitive]="true"
                                  (valueChange)="onChangeStateExam($event)"
                                  [(ngModel)]="filialStateExam"
                                 >
              </kendo-dropdownlist>
            </dd>
        
            <dd class="list">
              <span class="selectText">
                Уровень подготовки
              </span>
              <kendo-dropdownlist class="w-600"
                                  [ngClass]="{filterdisabled: changeStateExam && selectionDisciplines.length !== 0}"
                                  [data]="trainingLevels"
                                  textField="name"
                                  valueField="id"
                                  [valuePrimitive]="true"
                                  (valueChange)="onChangeStateExam($event, filterName.level)"
                                  [(ngModel)]="levelStateExam"
                                  >
              </kendo-dropdownlist>
            </dd>

            <dd class="list">
              <span class="selectText">
                Учебный год
              </span>
              <kendo-dropdownlist class="w-600"
                                  [ngClass]="{filterdisabled: changeStateExam && selectionDisciplines.length !== 0}"
                                  [data]="yearAdmissionList"
                                  textField="studyYearString"
                                  valueField="studyYear"
                                  [valuePrimitive]="true"
                                  (valueChange)="onChangeStateExam($event, filterName.year)"
                                  [(ngModel)]="studyYear"
                                 >
              </kendo-dropdownlist>
            </dd>

            <dd class="list">
              <span class="selectText">
                Институт / факультет
              </span>
              <kendo-dropdownlist class="w-600"
                                  [ngClass]="{filterdisabled: changeStateExam && selectionDisciplines.length !== 0}"
                                  [data]="departments"
                                  textField="shortName"
                                  valueField="id"
                                  [valuePrimitive]="true"
                                  (valueChange)="onChangeStateExam($event)"
                                  [(ngModel)]="faculty"
                                 >
              </kendo-dropdownlist>
            </dd>

            <dd class="list">
              <span class="selectText">
                Направление подготовки
              </span>
              <kendo-dropdownlist class="w-600"
                                  [ngClass]="{filterdisabled: changeStateExam && selectionDisciplines.length !== 0}"
                                  [data]="standards"
                                  textField="standardName"
                                  valueField="id"
                                  [valuePrimitive]="true"
                                  (valueChange)="onChangeStateExam($event)"
                                  [(ngModel)]="standard"
                                 >
              </kendo-dropdownlist>
            </dd>
          </div>

          <p class="note">Примечание: для отображения списка государственных экзаменов в протоколе ГИА требуется выбрать значения в фильтре</p>

          <div *ngIf="filterStateExam">
            <div class="alignend m-t20">
              <button kendoButton (click)="cancelProtocol()">Отмена</button>
              <button kendoButton themeColor="primary" class="m-l15" (click)="saveDiscipline()">Сохранить</button>
            </div>

            <div class="m-t20 flex">
              <p class="text w-150">Тип дисциплины</p>
              <kendo-dropdownlist class="w-600 m-l15"
                                  [data]="disciplinesType"
                                  textField="name"
                                  valueField="id"
                                  [valuePrimitive]="true"
                                  [(ngModel)]="typeDiscipline">
              </kendo-dropdownlist>
          </div>
          
          <div class="m-t20 flex">
              <p class="text w-150">Дисциплина</p>
              <kendo-textbox class="w-500 m-l15" [(ngModel)]="discipline">
              </kendo-textbox>
              <button kendoButton class="m-l15" (click)="getDiscipline()">Поиск</button>
          </div>
          
          <p class="text m-t20 title">Список дисциплин</p>
          
          <kendo-grid
              class="m-t20"
              [data]="gridView"
              [loading]="loading"
              [pageable]="true"
              [pageSize]="pageSize"
              [skip]="skip"
              [pageable]="{
                  buttonCount: buttonCount,
                  info: info,
                  type: type,
                  previousNext: previousNext,
                  position: position
              }"
              (pageChange)="pageChange($event)"
              >
              <ng-template kendoGridLoadingTemplate>
                  <div class="loading-container">
                    <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
                  </div>
              </ng-template>
              
              <kendo-grid-column field="name"
                                title="Наименование дисциплины"
                                headerClass="gridHeader dictionaries">
                  <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells leftAlignment">
                          {{ dataItem.name }}
                      </span>
                  </ng-template>
              </kendo-grid-column>
          
              <kendo-grid-command-column title="" headerClass="gridHeader dictionaries" [width]="140">
                  <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                      <button kendoButton type="button" (click)="editHandler(dataItem)" class="editButton">Выбрать</button>
                      </span>
                  </ng-template>
              </kendo-grid-command-column>
          </kendo-grid>

          <p class="text m-t30 title">Список выбранных дисциплин</p>

          <kendo-grid class="m-t20"
                      [kendoGridBinding]="selectionDisciplines"
                >
                <kendo-grid-column field="disciplineName"
                                  title="Наименование дисциплины"
                                  headerClass="gridHeader dictionaries">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                            {{ dataItem.disciplineName }}
                        </span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-command-column title="" headerClass="gridHeader dictionaries" [width]="140">
                  <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                      <button kendoButton type="button" (click)="deleteDiscipline(dataItem)" class="editButton">Удалить</button>
                      </span>
                  </ng-template>
              </kendo-grid-command-column>
          </kendo-grid>
          
          <div class="alignend m-t20">
              <button kendoButton (click)="cancelProtocol()">Отмена</button>
              <button kendoButton themeColor="primary" class="m-l15" (click)="saveDiscipline()">Сохранить</button>
          </div>
        </div>

        <div kendoDialogContainer></div>
    
        </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>
<kendo-grid class="competenceGrid"
    [kendoGridBinding]="gridData"
    [height]="600"
    [group]="groups">
    <kendo-grid-column field="code" title="Шифр" [hidden]="true">
        <ng-template kendoGridGroupHeaderTemplate let-group="group">
            <span class="groupHeader leftAlignment">
              {{ "Компетенция: " + group.value}} {{findCompetenceDescription(group.value)}}
            </span>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="disciplines" title="">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="alignCells leftAlignment" *ngFor="let item of dataItem.disciplines">
                <div>{{item}}</div>
            </div>
        </ng-template>

    </kendo-grid-column>
</kendo-grid>
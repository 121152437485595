import {Component, OnInit} from '@angular/core';
import {ContingentOrganizationService} from '../../../services/contingent/contingentOrganization.service';
import {ContingentOrganization} from '../../../models/contingent/organization.model';
import {EmploymentStatuses} from '../../../models/contingent/enums/employment-statuses.enum';
import {AcademicStateEnum} from '../../../models/contingent/enums/academic-state.enum';
import {
  ComplaintGet,
  Customer,
  Employer,
  ExpulsionInfo, FileData,
  Informing,
  LabourActivity, LabourActivityGet,
  LabourActivitySubstantiationGet
} from '../../../models/contingent/targeted-training.model';
import {ActivatedRoute} from '@angular/router';
import {TargetedTrainingService} from '../../../services/contingent/targeted-training.service';
import {DateFromUTCAsLocal, DateToString, getDateDiff, TimeZoneFix} from '../../../helpers/date-helper';
import {EditMode, OrganizationFields} from '../../../models/contingent/enums/targeted-training.enum';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {DictStateService} from '../../../services/contingent/dictstate.service';
import {DictState} from '../../../models/contingent/dictstate.model';
import {OrdersService} from '../../../services/contingent/orders.service';
import {Orders} from '../../../models/contingent/orders.model';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {ContingentService} from '../../../services/contingent/contingent.service';
import {AcademicStateService} from '../../../services/contingent/academicstate.service';
import {lastValueFrom} from 'rxjs';
import {FileRestrictions} from '@progress/kendo-angular-upload';
import {environment} from '../../../../environments/environment';
import {saveAs} from '@progress/kendo-file-saver';
import {DownloadFile} from '../../../helpers/downloadFile-helper';
import {PaymentStatesEnum} from '../../../models/contingent/enums/payment-states.enum';
import {ComplaintmanagementstatusService} from '../../../services/contingent/complaintmanagementstatus.service';
import {PaymentstateService} from '../../../services/contingent/paymentstate.service';
import {EmploymentstatusService} from '../../../services/contingent/employmentstatus.service';
import {EmploymentStatus} from '../../../models/contingent/employmentstatus.model';
import {PaymentState} from '../../../models/contingent/paymentstate.model';
import {ComplaintManagementStatus} from '../../../models/contingent/complaintmanagementstatus.model';
import {boolOptions} from 'src/app/models/contingent/options';
import {StudentService} from '../../../services/contingent/student.service';
import {NonEmploymentReasonsService} from '../../../services/contingent/nonemploymentreasons.service';
import {NonEmploymentReasonModel} from '../../../models/contingent/nonemploymentreason.model';

@Component({
  selector: 'app-targetedtraining',
  templateUrl: './targeted-training.component.html',
  styleUrls: ['./targeted-training.component.scss']
})
export class TargetedTrainingComponent implements OnInit {
  public editable = true;
  public editMode = new Map([
    [EditMode.customer, false],
    [EditMode.employer, false],
    [EditMode.expulsionInfo, false],
    [EditMode.labourActivity, false],
    [EditMode.complaint, false],
  ]);

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public restrictions: FileRestrictions = {
    maxFileSize: environment.contingent.maxFileSize,
    allowedExtensions: ['.pdf'],
  };

  public boolOptions = boolOptions;

  public targetOrganizations: ContingentOrganization[] = [];
  public employerOrganizations: ContingentOrganization[] = [];
  public dictStates: DictState[] = [];
  public dictPaymentStates: PaymentState[] = [];
  public dictComplaintStatuses: ComplaintManagementStatus[] = [];
  public dictEmploymentStatuses: EmploymentStatus[] = [];
  public dictReasons: NonEmploymentReasonModel[] = [];

  private readonly studentId!: string;
  public academicState!: typeof AcademicStateEnum;

  public expulsionOrder?: Orders;
  public actualEmploymentStatus?: EmploymentStatuses;

  public customer?: Customer;
  public employer?: Employer;
  public expulsionInfo?: ExpulsionInfo;
  public complaint?: ComplaintGet;
  public substantiation?: LabourActivitySubstantiationGet;

  public customerForm!: FormGroup;
  public employerForm!: FormGroup;
  public informCustomerForm!: FormGroup;
  public informEmployerForm!: FormGroup;
  public complaintForm!: FormGroup;
  public substantiationForm!: FormGroup;

  protected readonly EmploymentStatuses = EmploymentStatuses;
  protected readonly AcademicStateEnum = AcademicStateEnum;
  protected readonly PaymentStatesEnum = PaymentStatesEnum;
  protected readonly EditMode = EditMode;
  protected readonly OrganizationFields = OrganizationFields;
  protected readonly DateToString = DateToString;

  constructor(
    private activateRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    private academicStateService: AcademicStateService,
    private contingentService: ContingentService,
    private organizationService: ContingentOrganizationService,
    private dictStateService: DictStateService,
    private complaintStatusService: ComplaintmanagementstatusService,
    private paymentStateService: PaymentstateService,
    private employmentStatusService: EmploymentstatusService,
    private nonEmploymentReasonsService: NonEmploymentReasonsService,
    private ordersService: OrdersService,
    private studService: StudentService,
    private trainingService: TargetedTrainingService,
  ) {
    if (activateRoute.snapshot.parent) {
      this.studentId = activateRoute.snapshot.parent.params['studentId'];
    }
  }

  ngOnInit(): void {
    this.getAccess();
    this.getDicts();
    this.getData();
  }

  private getAccess(): void {
    this.studService.getByIdstudent(this.studentId).subscribe((response) => {
      this.editable = !!response?.targetEditable;
    });
  }

  private getDicts() {
    this.getOrganization();
    this.getStates();
    this.getPaymentStates();
    this.getComplaintStatuses();
    this.getLabourActivityStatuses();
    this.getReasons();
  }

  private getOrganization(): void {
    this.organizationService.getAllorganization().subscribe((response) => {
      this.targetOrganizations = response.filter((org) => org.isTarget);
      this.employerOrganizations = this.targetOrganizations.filter((org) => org.isWorkPlace);
    });
  }

  private getStates() {
    this.dictStateService.getAllstate().subscribe((response) => this.dictStates = response);
  }

  private getPaymentStates() {
    this.paymentStateService.getAllPaymentState().subscribe((response) => this.dictPaymentStates = response);
  }

  private getComplaintStatuses() {
    this.complaintStatusService.getAllComplaintManagementStatus().subscribe((response) =>
      this.dictComplaintStatuses = response);
  }

  private getLabourActivityStatuses() {
    this.employmentStatusService.getAllEmploymentStatus().subscribe((response) =>
      this.dictEmploymentStatuses = response);
  }

  private getReasons() {
    this.nonEmploymentReasonsService.getNonEmploymentReasons().subscribe((response) =>
      this.dictReasons = response);
  }

  private async getData() {
    await this.getAcademicState();
    this.getCustomer();
    this.getEmployer();

    if (this.academicState === AcademicStateEnum.expelled) {
      this.getExpulsionInfo();
      this.getExpulsionOrder();
      this.getComplaintInfo();
    }

    if (this.academicState === AcademicStateEnum.graduated) {
      this.getLabourActivity();
    }
  }

  private async getAcademicState() {
    await lastValueFrom(this.contingentService.getByIdstudent(this.studentId)).then(async (contingent) => {
      await lastValueFrom(this.academicStateService.getAllAcademicState()).then((response) => {
        this.academicState = response.find((state) =>
          state.dictStudentAcademicStateExternalId === contingent.studentAcademicState.dictStudentAcademicStateId)?.academicStateCategory;
      });
    });
  }

  private getCustomer() {
    this.trainingService.getCustomer(this.studentId).subscribe((response) => {
      this.customer = response;
      if (this.customer) this.customer.contractDate = DateFromUTCAsLocal(this.customer.contractDate);
    });
  }

  private getEmployer() {
    this.trainingService.getEmployer(this.studentId).subscribe((response) => {
      this.employer = response;
    });
  }

  private getExpulsionInfo() {
    this.trainingService.getExpulsionInfo(this.studentId).subscribe((response) => {
      this.expulsionInfo = response;
      if (this.expulsionInfo?.informingCustomer)
        this.expulsionInfo.informingCustomer.date = DateFromUTCAsLocal(this.expulsionInfo.informingCustomer.date);
      if (this.expulsionInfo?.informingEmployer)
        this.expulsionInfo.informingEmployer.date = DateFromUTCAsLocal(this.expulsionInfo.informingEmployer.date);
    });
  }

  private getExpulsionOrder() {
    this.ordersService.getByIdorders(this.studentId).subscribe((response) => {
      this.expulsionOrder = response.pop();
    });
  }

  private getComplaintInfo() {
    this.trainingService.getComplaint(this.studentId).subscribe((response) => {
      this.complaint = response;
      if (this.complaint) {
        this.complaint.beginDate = DateFromUTCAsLocal(this.complaint.beginDate);
        this.complaint.endDate = DateFromUTCAsLocal(this.complaint.endDate);
        this.complaint.notificationDate = DateFromUTCAsLocal(this.complaint.notificationDate);
      }
    });
  }

  private getLabourActivity() {
    this.trainingService.getLabourActivity(this.studentId).subscribe((response) => {
      this.substantiation = response;

      if (this.substantiation) {
        this.substantiation.hiredDate = DateFromUTCAsLocal(this.substantiation.hiredDate);
        this.substantiation?.labourActivity?.forEach((item) => {
          item.beginDate = DateFromUTCAsLocal(item.beginDate);
          item.endDate = DateFromUTCAsLocal(item.endDate);
          item.recoveryDate = DateFromUTCAsLocal(item.recoveryDate);
          item.dismissalDate = DateFromUTCAsLocal(item.dismissalDate);
        });
      }

      this.actualEmploymentStatus = this.substantiation?.labourActivity?.at(-1)?.employmentStatus;
      if (this.substantiation?.isHired !== undefined && !this.substantiation?.isHired ||
        this.substantiation?.labourActivity?.find(
          (item) => item.employmentStatus === EmploymentStatuses.Dismissal)) {
        this.getComplaintInfo();
      }
    });
  }

  private getFileObserver(fileName?: string) {
    return {
      next: (response: Blob) => fileName && saveAs(response, fileName),
      error: (error: unknown) => this.notificationsService.showError(error),
    };
  }

  public getOrderFile() {
    if (this.expulsionOrder?.ordersExternalId) {
      this.ordersService.getOrderFile(this.expulsionOrder.ordersExternalId)
        .subscribe(this.getFileObserver(this.expulsionOrder.fileFullName));
    }
  }

  public getInformingFile(id?: string, fileName?: string) {
    id && this.trainingService.getInformingFile(this.studentId, id).subscribe(this.getFileObserver(fileName));
  }

  public getComplaintFile(file?: FileData) {
    file && this.trainingService.getComplaintFile(this.studentId, file.id)
      .subscribe(this.getFileObserver(file.fileFullName));
  }

  public getSubstantiationFile(id?: string, fileName?: string) {
    id && this.trainingService.getActivitySubstantiationFile(this.studentId, id)
      .subscribe(this.getFileObserver(fileName));
  }

  public getActivityPeriodFile(file?: FileData) {
    file && this.trainingService.getActivityFile(this.studentId, file.id)
      .subscribe(this.getFileObserver(file.fileFullName));
  }

  public downloadFile(files?: File[]) {
    files?.length && DownloadFile(files[0]);
  }

  public removeFile(form: AbstractControl, propertyName: string) {
    form.get(propertyName)?.patchValue(null);
  }

  public getOrganizationFieldInfo(field: keyof ContingentOrganization, id?: string) {
    const organization = id && this.targetOrganizations.find((item) => item.dictOrganizationExternalId === id);
    return organization ? organization[field] ?? '-' : '-';
  }

  public getStateName(id?: string) {
    const state = id && this.dictStates.find((item) => item.dictStateExternalId === id);
    return state ? state.stateName : '-';
  }

  public formatDate(date?: Date, format?: string) {
    return date ? DateToString(date, format) : '-';
  }

  public getBoolOption(value?: boolean) {
    return value || value === false
      ? this.boolOptions.find((item) => item.value === value)?.text ?? '-'
      : '-';
  }

  public getPaymentPeriod(date?: Date) {
    let period;
    if (date) {
      period = new Date(date.toDateString());
      period.setFullYear(date.getFullYear() + 1);
    }
    return period;
  }

  public getDebt(penalty?: number, paid?: number) {
    return (penalty || penalty === 0) && (paid || paid === 0) ? penalty - paid : undefined;
  }

  public getPaymentStatusName(value?: PaymentStatesEnum) {
    return value ? this.dictPaymentStates.find((item) =>
      item.paymentStateNumber === value)?.name ?? '-' : '-';
  }

  public getComplaintStatusName(value?: string) {
    return value ? this.dictComplaintStatuses.find((item) =>
      item.externalId === value)?.name ?? '-' : '-';
  }

  public getEmploymentStatusName(value?: EmploymentStatuses) {
    return value ? this.dictEmploymentStatuses.find((item) =>
      item.employmentStatusNumber === value)?.name ?? '-' : '-';
  }

  public getReasonName(value?: string) {
    return value ? this.dictReasons.find((item) => item.externalId === value)?.name ?? '-' : '-';
  }

  private getMonths(periods?: (LabourActivity | LabourActivityGet)[]) {
    return periods?.reduce((acc, item) =>
        acc + (item.beginDate && item.endDate ? getDateDiff(item.beginDate, item.endDate, 'months') : 0),
        0
      ) ?? 0;
  }

  public  getExperience(periods?: (LabourActivity | LabourActivityGet)[]) {
    const workPeriods = periods && periods.filter((item) =>
      item.employmentStatus === EmploymentStatuses.Continuation);
    const months = this.getMonths(workPeriods);
    const techName = Math.floor((months % 100) / 10) === 1 || months % 10 === 0 || months % 10 >= 5
      ? 'месяцев'
      : months % 10 === 1
        ? 'месяц'
        : 'месяца';
    return `${months} ${techName}`;
  }

  public editCustomer() {
    this.editHandler(EditMode.customer);
    this.customerForm = this.getCustomerFormGroup();
  }

  public saveCustomer() {
    if (this.customerForm.invalid) {
      this.notificationsService.showError('Заполните обязательные поля');
      return;
    }

    if (this.customerForm.value.contractDate)
      this.customerForm.value.contractDate = TimeZoneFix(this.customerForm.value.contractDate);
    this.trainingService.setCustomer(this.studentId, this.customerForm.value).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Сведения сохранены');
        this.getCustomer();
        this.closeHandler();
      },
      error: (error) => this.notificationsService.showError(error),
    });
  }

  public editEmployer() {
    this.editHandler(EditMode.employer);
    this.employerForm = this.getEmployerFormGroup();
  }

  public saveEmployer() {
    if (this.employerForm.invalid) {
      this.notificationsService.showError('Заполните обязательные поля');
      return;
    }

    this.trainingService.setEmployer(this.studentId, this.employerForm.value).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Сведения сохранены');
        this.getEmployer();
        this.closeHandler();
      },
      error: (error) => this.notificationsService.showError(error),
    });
  }

  public editExpulsionInfo() {
    this.editHandler(EditMode.expulsionInfo);
    this.informCustomerForm = this.getInformFormGroup(this.expulsionInfo?.informingCustomer);
    this.informEmployerForm = this.getInformFormGroup(this.expulsionInfo?.informingEmployer);
  }

  private informingIsEmpty(value: Informing) {
    return !(value.id || value.date || value.number || value.file);
  }

  private processExpulsionInfo() {
    if (this.informCustomerForm.value.date)
      this.informCustomerForm.value.date = TimeZoneFix(this.informCustomerForm.value.date).toISOString();
    if (this.informEmployerForm.value.date)
      this.informEmployerForm.value.date = TimeZoneFix(this.informEmployerForm.value.date).toISOString();

    if (this.informCustomerForm.value.file) {
      this.informCustomerForm.value.file = this.informCustomerForm.value.file[0];
      this.informCustomerForm.value.fileFullName = this.informCustomerForm.value.file.name;
    }
    if (this.informEmployerForm.value.file) {
      this.informEmployerForm.value.file = this.informEmployerForm.value.file[0];
      this.informEmployerForm.value.fileFullName = this.informEmployerForm.value.file.name;
    }

    const expulsionInfo: ExpulsionInfo = {};
    if (!this.informingIsEmpty(this.informCustomerForm.value))
      expulsionInfo.informingCustomer = this.informCustomerForm.value;
    if (!this.informingIsEmpty(this.informEmployerForm.value))
      expulsionInfo.informingEmployer = this.informEmployerForm.value;
    return expulsionInfo;
  }

  public saveExpulsionInfo() {
    const info = this.processExpulsionInfo();
    if (!(info.informingCustomer || info.informingEmployer)) {
      this.closeHandler();
      return;
    }

    this.trainingService.setExpulsionInfo(this.studentId, info).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Сведения сохранены');
        this.getExpulsionInfo();
        this.closeHandler();
      },
      error: (error) => this.notificationsService.showError(error),
    });
  }

  public editComplaint() {
    this.editHandler(EditMode.complaint);
    this.complaintForm = this.getComplaintFormGroup();
  }

  private getPaymentStatus(penaltyAmount?: number, paidAmount?: number, term?: Date) {
    const debt = this.getDebt(penaltyAmount, paidAmount);

    return debt && debt > 0
      ? !term || term > new Date()
        ? PaymentStatesEnum.PartialPayment
        : PaymentStatesEnum.Debt
      : debt === 0 || debt && debt < 0
        ? PaymentStatesEnum.FullPayment
        : undefined;
  }

  public paymentStatusChange(penaltyAmount?: number, paidAmount?: number, penaltyDate?: Date) {
    const term = this.getPaymentPeriod(penaltyDate);
    const paymentState = this.getPaymentStatus(penaltyAmount, paidAmount, term);
    this.complaintForm.get('endDate')?.patchValue(term);
    this.complaintForm.get('dictPaymentState')?.patchValue(paymentState);
  }

  private processComplaintInfo() {
    if (this.complaintForm.value.beginDate) {
      this.complaintForm.value.endDate = this.getPaymentPeriod(this.complaintForm.value.beginDate);
      this.complaintForm.value.beginDate = TimeZoneFix(this.complaintForm.value.beginDate).toISOString();
    }
    if (this.complaintForm.value.endDate)
      this.complaintForm.value.endDate = TimeZoneFix(this.complaintForm.value.endDate).toISOString();
    if (this.complaintForm.value.notificationDate)
      this.complaintForm.value.notificationDate = TimeZoneFix(this.complaintForm.value.notificationDate).toISOString();

    if (this.complaintForm.value.fineCopy?.length) {
      this.complaintForm.value.fineCopy = this.complaintForm.value.fineCopy[0];
      this.complaintForm.value.fileCopyFullName = this.complaintForm.value.fineCopy.name;
    }
    if (this.complaintForm.value.fineDemand?.length) {
      this.complaintForm.value.fineDemand = this.complaintForm.value.fineDemand[0];
      this.complaintForm.value.fileDemandFullName = this.complaintForm.value.fineDemand.name;
    }
  }

  public saveComplaint() {
    this.processComplaintInfo();

    this.trainingService.setComplaint(this.studentId, this.complaintForm.value).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Сведения сохранены');
        this.getComplaintInfo();
        this.closeHandler();
      },
      error: (error) => this.notificationsService.showError(error),
    });
  }

  public editLabourActivity() {
    this.editHandler(EditMode.labourActivity);
    this.substantiationForm = this.getLabourActivityFormGroup();
  }

  public isHiredChange(value: boolean) {
    this.labourActivities.clear();
    this.fieldChange(value, this.substantiationForm, 'isHired');
  }

  private processLabourActivity() {
    if (this.substantiationForm.value.hiredDate)
      this.substantiationForm.value.hiredDate = TimeZoneFix(this.substantiationForm.value.hiredDate).toISOString();

    if (this.substantiationForm.value.substantiation?.length) {
      this.substantiationForm.value.substantiation = this.substantiationForm.value.substantiation[0];
      this.substantiationForm.value.fileFullName = this.substantiationForm.value.substantiation.name;
    }

    this.labourActivities.controls.forEach((item) => {
      item.value.beginDate && (item.value.beginDate = TimeZoneFix(item.value.beginDate).toISOString());
      item.value.endDate && (item.value.endDate = TimeZoneFix(item.value.endDate).toISOString());
      item.value.recoveryDate && (item.value.recoveryDate = TimeZoneFix(item.value.recoveryDate).toISOString());
      item.value.dismissalDate && (item.value.dismissalDate = TimeZoneFix(item.value.dismissalDate).toISOString());

      if (item.value.activitySubstantiation?.length) {
        item.value.activitySubstantiation = item.value.activitySubstantiation[0];
        item.value.activityFileFullName = item.value.activitySubstantiation.name;
      }

      if (item.value.dismissalSubstantiation?.length) {
        item.value.dismissalSubstantiation = item.value.dismissalSubstantiation[0];
        item.value.dismissalFileFullName = item.value.dismissalSubstantiation.name;
      }
    });
  }

  public saveLabourActivity() {
    if (this.substantiationForm.invalid) {
      this.notificationsService.showError('Заполните обязательные поля');
      return;
    }

    this.processLabourActivity();

    this.trainingService.setLabourActivity(this.studentId, this.substantiationForm.value).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Сведения сохранены');
        this.getLabourActivity();
        this.closeHandler();
      },
      error: (error) => this.notificationsService.showError(error),
    });
  }

  public editHandler(key: EditMode) {
    this.closeHandler();
    this.editMode.set(key, true);
  }

  public closeHandler() {
    this.editMode.forEach((value, key) => this.editMode.set(key, false));
  }

  public addStatus() {
    this.labourActivities.push(this.getLabourActivityPeriodFormGroup());
  }

  public removeStatus(index: number) {
    this.labourActivities.removeAt(index);
  }

  public fieldChange(value: unknown, form: AbstractControl, fieldName: string) {
    const formId = form.get('id')?.value;
    Object.values((form as FormGroup).controls).forEach((item) => item.patchValue(null));
    form.get('id')?.patchValue(formId);
    form.get(fieldName)?.patchValue(value);
  }

  private getCustomerFormGroup() {
    return new FormGroup({
      customerExternalId: new FormControl<string|null>(this.customer?.customerExternalId ?? null),
      contractNumber: new FormControl<string|null>(this.customer?.contractNumber ?? null),
      contractDate: new FormControl<Date|null>(this.customer?.contractDate ?? null),
      dictOrganizationId: new FormControl<string|null>(this.customer?.dictOrganizationId ?? null, Validators.required),
    });
  }

  private getEmployerFormGroup() {
    return new FormGroup({
      employerExternalId: new FormControl<string|null>(this.employer?.employerExternalId ?? null),
      dictOrganizationId: new FormControl<string|null>(this.employer?.dictOrganizationId ?? null, Validators.required),
      dictStateId: new FormControl<string|null>(this.employer?.dictStateId ?? null),
    });
  }

  private getInformFormGroup(inform?: Informing) {
    return new FormGroup({
      id: new FormControl<string|null>(inform?.id ?? null),
      date: new FormControl<Date|null>(inform?.date ?? null),
      number: new FormControl<string|null>(inform?.number ?? null),
      fileFullName: new FormControl<string|null>(inform?.fileFullName ?? null),
      file: new FormControl(null),
    });
  }

  private getComplaintFormGroup() {
    return new FormGroup({
      id: new FormControl<string|null>(this.complaint?.id ?? null),
      isComplaintManagementWork: new FormControl<boolean|null>(
        this.complaint?.isComplaintManagementWork || this.complaint?.isComplaintManagementWork === false
          ? this.complaint.isComplaintManagementWork
          : null),
      reason: new FormControl<string|null>(this.complaint?.reason ?? null),
      notificationDate: new FormControl<Date|null>(this.complaint?.notificationDate ?? null),
      notificationNumber: new FormControl<string|null>(this.complaint?.notificationNumber ?? null),
      complaintNumber: new FormControl<string|null>(this.complaint?.complaintNumber ?? null),
      beginDate: new FormControl<Date|null>(this.complaint?.beginDate ?? null),
      endDate: new FormControl<Date|null>(this.complaint?.endDate ?? null),
      fileCopyFullName: new FormControl(this.complaint?.fineCopy?.fileFullName ?? null),
      fineCopy: new FormControl(null),
      paidAmount: new FormControl<number|null>(this.complaint?.paidAmount ?? null),
      penaltyAmount: new FormControl<number|null>(
        this.complaint?.penaltyAmount || this.complaint?.penaltyAmount === 0 ? this.complaint.penaltyAmount : null),
      fileDemandFullName: new FormControl(this.complaint?.fineDemand?.fileFullName ?? null),
      fineDemand: new FormControl(null),
      dictPaymentState: new FormControl<PaymentStatesEnum|null>(this.complaint?.dictPaymentState ?? null),
      dictComplaintManagementStatusId: new FormControl<string|null>(
        this.complaint?.dictComplaintManagementStatusId ?? null),
    });
  }

  private getLabourActivityFormGroup() {
    const periods: FormGroup[] = [];
    this.substantiation?.labourActivity?.forEach((item) =>
      periods.push(this.getLabourActivityPeriodFormGroup(item)));

    return new FormGroup({
      id: new FormControl<string|null>(this.substantiation?.id ?? null),
      isHired: new FormControl<boolean|null>(
        this.substantiation?.isHired || this.substantiation?.isHired === false
          ? this.substantiation.isHired
          : null),
      dictNonEmploymentReasonId: new FormControl<string|null>(this.substantiation?.dictNonEmploymentReasonId ?? null),
      dictOrganizationId: new FormControl<string|null>(this.substantiation?.dictOrganizationId ?? null),
      hiredDate: new FormControl<Date|null>(this.substantiation?.hiredDate ?? null),
      fileFullName: new FormControl<string|null>(this.substantiation?.fileFullName ?? null),
      substantiation: new FormControl(null),
      labourActivity: new FormArray(periods),
    });
  }

  public get labourActivities(): FormArray {
    return <FormArray> this.substantiationForm.get('labourActivity');
  }

  private getLabourActivityPeriodFormGroup(period?: LabourActivityGet) {
    return new FormGroup({
      id: new FormControl<string|null>(period?.id ?? null),
      employmentStatus: new FormControl<EmploymentStatuses|null>(
        period?.employmentStatus ?? null, Validators.required),
      labourActivitySubstantiationId: new FormControl<string|null>(this.substantiation?.id ?? null),
      beginDate: new FormControl<Date|null>(period?.beginDate ?? null),
      endDate: new FormControl<Date|null>(period?.endDate ?? null),
      recoveryDate: new FormControl<Date|null>(period?.recoveryDate ?? null),
      dismissalDate: new FormControl<Date|null>(period?.dismissalDate ?? null),
      reason: new FormControl<string|null>(period?.reason ?? null),
      activityFileFullName: new FormControl<string|null>(period?.activitySubstantiation?.fileFullName ?? null),
      activitySubstantiation: new FormControl(period?.activitySubstantiation ?? null),
      dismissalFileFullName: new FormControl<string|null>(period?.dismissalSubstantiation?.fileFullName ?? null),
      dismissalSubstantiation: new FormControl(period?.dismissalSubstantiation ?? null),
    });
  }
}

<gia-settings></gia-settings>
<h1>Настройки ГИА</h1>

<div class="content-container">
  <div *ngFor="let settings of giaSettingModel; let i = index">
    <div class="title">
      <h3>{{ settings[i].sectionTitle }}</h3>
    </div>
    <div *ngFor="let setting of settings; let j = index">
      <div class="content">
        <h2>{{ settings[j].name }}</h2>
        <div class="grid-box">
          <kendo-multiselect
            [data]="dictTrainingLevels"
            [ngModel]="this.giaSettingModel[i][j].trainingLevelSettings"
            class="w-600"
            textField="name"
            valueField="id"
            (valueChange)="valueChangeStudyForm($event, i, j)"
          ></kendo-multiselect>
        </div>
      </div>
    </div>
  </div>

  <!--Buttons-->
  <div class="functionButtons editModeButtons">
    <button kendoButton size="small" (click)="closeHandler()">Закрыть</button>
    <button kendoButton size="small" themeColor="primary" (click)="applySetting()">Применить</button>
  </div>
</div>

<h1>Настройки выбора бланка</h1>

<div class="content-container">
  <div *ngFor="let settings of giaBlankSettingModel; let i = index">
    <div class="title">
      <h3>{{ settings[i].sectionTitle }}</h3>
    </div>
    <div class="content-inline-block" *ngFor="let setting of settings; let j = index">
        <h2>{{ setting.blankTypeTitle }}</h2>
        <div class="radio-button">
          <div class="button-block">
            <input type="radio" [value]="1" [(ngModel)]="setting.valueEnum" name="radioName_{{i}}_{{j}}" />
            <p class="text-area">Вручную</p>
          </div>
          <div class="button-block">
            <input type="radio" [value]="2" [(ngModel)]="setting.valueEnum" name="radioName_{{i}}_{{j}}" />
            <p class="text-area">Мои бланки</p>
          </div>
          <div class="button-block">
            <input type="radio" [value]="3" [(ngModel)]="setting.valueEnum" name="radioName_{{i}}_{{j}}" />
            <p class="text-area">Общие бланки</p>
          </div>
        </div>
      </div>
  </div>


  <!--Buttons-->
  <div class="functionButtons editModeButtons">
    <button kendoButton size="small" (click)="closeHandler()">Закрыть</button>
    <button kendoButton size="small" themeColor="primary" (click)="applyBlankSetting()">Применить</button>
  </div>
</div>

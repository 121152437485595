import {FacultyTreeItem} from '../../faculties-tree.model';
import {NumericApplicationConstructorStatusesEnum} from '../../enums/application-constructor-statuses.enum';
import {ApplicationStatusesEnum} from '../../enums/application-statuses.enum';
import {DocumentTypes} from "../../enums/document-types.enum";

export interface CreateApplicationConstructorForm {
  applicationConstructor: MainSettings;
  filter: ConstructorFilter;
  documentNames?: string[];
  parentConstructorId?: string;
}

export interface MainSettings {
  dictApplicationTypeId: string;
  name: string;
  dictTypeReceiptIds: string[];
  showOnStudentCard: boolean;
  allowedAddInsteadOfStudents: boolean;
  allowRegistrationNumber: boolean;
  isAllTrainingLevelAvailable: boolean;
  isAllFacultyAvailable: boolean;
  isAllStudyFormAvailable: boolean;
  isAllCitizenshipAvailable: boolean;
  systemNotificationSettings: SystemNotificationSetting[];
}

export interface SystemNotificationSetting {
  applicationStatus: ApplicationStatusesEnum;
  dictTypeReceiptId: string;
  text: string;
}

export interface ConstructorFilter {
  facultyIds: string[];
  studyFormIds: string[];
  citizenShipIds: string[];
  trainingLevelIds: string[];
}

export interface UpdateApplicationConstructorForm {
  externalId: string;
  applicationConstructor: MainSettings;
  filter: ConstructorFilter;
  documents?: ConstructorDocument[];
}

export interface ConstructorDocument {
  externalId?: string;
  name: string;
  documentType?: number;
}

export interface ApplicationConstructorForm {
  externalId: string;
  status: NumericApplicationConstructorStatusesEnum;
  filter: ConstructorFilter;
  basicSettings: MainSettings;
  applicationConstructorDocuments: ConstructorDocument[]|null;
}

export class ConstructorForm {
  externalId: string|null = null;
  category: string|null = null;
  type: string|null = null;
  name: string|null = null;
  trainingLevels: string[] = [];
  isAllTrainingLevelAvailable: boolean = false;
  faculties: FacultyTreeItem[] = [];
  isAllFacultyAvailable: boolean = false;
  studyForms: string[] = [];
  isAllStudyFormAvailable: boolean = false;
  citizenShips: string[] = [];
  isAllCitizenshipAvailable: boolean = false;
  typeReceipts: string[] = [];
  allowedAddInsteadOfStudents: boolean = true;
  showOnStudentCard: boolean = true;
  hasDocuments: boolean = false;
  hasStatements: boolean = false;
  documents?: ConstructorDocument[] = [{name: '', documentType: DocumentTypes.Document}];
  statements?: ConstructorDocument[] = [{name: '', documentType: DocumentTypes.Statement}];
  allowRegistrationNumber: boolean = false;
  systemNotificationSettings: SystemNotificationSetting[] = [];
}

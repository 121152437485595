import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {OfertaUserAccessService} from "../services/useraccess/oferta-user-access.service";
import {Location} from "@angular/common";
import {firstValueFrom, lastValueFrom} from "rxjs";
import {Role} from "../models/useraccess/role";
import {AccessRights} from "../models/oferta/enums/accessRights.enum";
import {UserSignatureService} from "../services/oferta/user-signature.service";
import {CreateQuery} from "../helpers/createQuery-helper";
import {SignatureTypeEnum} from "../models/oferta/enums/dictSignatureType.enum";

@Injectable({
  providedIn: 'root'
})
export class OfertaNotificationGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userSignatureService: UserSignatureService,
    private location: Location,
    private router: Router) {
  }

  private returnValue(value: boolean): boolean {
    if (!value) {
      this.router.navigate([`/profile`]).then();
    }
    return value;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise <boolean> {
    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;


      /*if (roles?.includes(Role.Admin)) {
        this.router.navigate([`/profile`]).then();
        return false;
      }*/

      if (localStorage.getItem('switchPerson') === 'true')
        return this.returnValue(false);

      // Чтобы не вылезало окно, когда специально переходим на эту страницу и есть подписка
      if (path === 'oferta/joiningToReinforcedRegulation') {
        return this.returnValue(!(await firstValueFrom(this.userSignatureService.personHasSignature(CreateQuery({signatureTypeEnum: SignatureTypeEnum.Reinforced})))));
      }

      // Чтобы не вылезало окно, когда специально переходим на эту страницу и не заблокирован доступ
      if (path === 'oferta/reinforcedmodulenotification') {
        return this.returnValue(!(await this.userSignatureService.personHasRightsForReinforcedModule(CreateQuery({UserSignatureTypeEnum: SignatureTypeEnum.Reinforced}))));
      }
    }
    return this.returnValue(false);
  }
}

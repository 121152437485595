import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Identifications } from '../../models/contingent/identifications.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class IdentificationsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.identifications}`;

  constructor(private http: HttpClient) { }

  //Get all identifications
  public getAllidentifications(): Observable<Identifications[]> {
    return this.http.get<Identifications[]>(this.baseUrl);
  }

  //Get By Id identifications
  getByIdidentifications(id: number|undefined): Observable<Identifications> {
    return this.http.get<Identifications>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId
  getStudentIdentifications(id: string): Observable<Identifications[]> {
    return this.http.get<Identifications[]>(this.baseUrl + '/getidentificationsbystudpersonid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add identifications
  addidentifications(identifications: Identifications, studentId: string): Observable<Identifications> {
    return this.http.post<Identifications>(this.baseUrl + '/' + studentId, identifications)
      .pipe(catchError(this.handleError));
  }

  //Delete identifications
  deleteidentifications(id: string, studentId: string): Observable<Identifications> {
    return this.http.delete<Identifications>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit identifications
  updateidentifications(identifications: Identifications, studentId: string): Observable<Identifications> {
    return this.http.put<Identifications>(this.baseUrl + '/' + identifications.identificationExternalId + '/' + studentId, identifications)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

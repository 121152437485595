import { Component, OnInit } from '@angular/core';
import { items } from '../../../models/gia/menu-items';
import { GiaUserAccessService } from '../../../services/useraccess/gia-user-access.service';
import { DictsService } from '../../../services/gia/dicts.service';
import { GiaCurrentUserAccess } from '../../../models/useraccess/gia/giaUserAccess';
import { Dicts } from '../../../models/gia/dicts/dicts.model';

@Component({
  selector: 'gia-settings',
  templateUrl: './gia-settings.component.html',
  styleUrls: ['./gia-settings.component.scss'],
})
export class GiaSettingsComponent implements OnInit {
  public items: any[] = items;
  public dictItems: any[] = [];
  private currentUser: GiaCurrentUserAccess = this.userAccessService.currentUserAccess$.value;

  constructor(
    private dictService: DictsService,
    private userAccessService: GiaUserAccessService,
  ) {}

  async ngOnInit() {
    await this.getUserAccess();
    this.getDicts();
  }

  private async getUserAccess() {
    if (!this.userAccessService.currentUserAccess$.value.isAdmin) {
      this.items[0].items = this.items[0].items.filter((item: any) => {
        return (
          (this.currentUser.dict ? item : !item.dicts) &&
          (this.currentUser.accessSettings ? item : !item.accessSettings) &&
          (this.currentUser.dictCreating ? item : !item.dictCreating) &&
          (this.currentUser.localSignatory ? item : !item.localSignatory) &&
          (this.currentUser.printTemplateAccess ? item : !item.printTemplates) &&
          (this.currentUser.isAdmin ? item : !item.admin) &&
          (this.currentUser.giaProtocolDesigner ? item : !item.protocolDesigner)
        );
      });
    }
  }

  private getDicts() {
    if (
      !this.dictService.dictsLoaded$.value &&
      (this.userAccessService.currentUserAccess$.value.isAdmin || this.userAccessService.currentUserAccess$.value.dict)
    ) {
      this.dictService.dictsLoaded$.next(true);
      this.dictService.getDynamicDicts().subscribe((value) => {
        this.dictService.dicts$.next(value);
        this.dictItems = this.mapDictItems(value);
        this.addDictItems();
      });
    }
  }

  private mapDictItems(dicts: Dicts[]) {
    return dicts.map((item) => ({
      text: item.name,
      path: `/gia/dict/${item.dictId}`,
    }));
  }

  private addDictItems() {
    const dictIndex = this.items[0].items.findIndex((item: any) => item.dicts);
    this.items[0].items[dictIndex].items = this.items[0].items[dictIndex].items.concat(this.dictItems);
  }
}

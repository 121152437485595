import {Component} from "@angular/core";
import {breadcrumbItemsMap} from "src/app/models/home/breadcrumb-items.model";
import {boolOptions} from "src/app/models/mfc/enums/bool-options.enum";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CompensationGuarantee} from "../../../../models/mfc/dicts/compensation-guarantee.model";
import {CompensationGuaranteeService} from "../../../../services/mfc/dicts/compensation-guarantee.service";

@Component({
  selector: 'mfc-dict-compensation-guarantee',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="compensationGuarantees"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      (getMethod)="get()"
      (addMethod)="add($event)"
      (updateMethod)="update($event)"
      (removeMethod)="remove($event)">
    </app-dict>
  `
})
export class CompensationGuaranteeComponent {

  protected compensationGuarantees: CompensationGuarantee[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly info: FieldInfo<CompensationGuarantee>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название типа',
      width: 350,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    }
  ];

  protected formGroup(dataItem?: CompensationGuarantee): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      name: new FormControl(dataItem?.name, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber),
    });
  }

  constructor(private compensationGuaranteeService: CompensationGuaranteeService) {}

  protected get() {
    this.compensationGuaranteeService.getCompensationGuarantees().subscribe(data =>
      this.compensationGuarantees = data
    );
  }

  protected add({value, observer}: EventData<CompensationGuarantee>) {
    this.compensationGuaranteeService.addCompensationGuarantee(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<CompensationGuarantee>) {
    this.compensationGuaranteeService.updateCompensationGuarantee(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<CompensationGuarantee>) {
    this.compensationGuaranteeService.removeCompensationGuarantee(value.id).subscribe(observer);
  }
}

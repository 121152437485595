import { Component, Input } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { ImageInfo } from "./upload.component";

@Component({
    selector: 'my-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})

export class DialogComponent implements HttpInterceptor {
    @Input() public editor: any ;
  
    public opened = false;
    public src: any = '';
    public height: any = 0;
    public width: any = 0;
  
    public get canInsert(): boolean {
      return !this.src;
    }
  
    public uploadImage(): void {
      // Invoking the insertImage command of the Editor.
      this.editor.exec("insertImage", this.imageInfo);
  
      // Closing the Dialog.
      this.close();
    }
  
    public get imageInfo(): ImageInfo {
      return {
        src: this.src,
        height: this.height,
        width: this.width,
      };
    }
  
    public setImageInfo(value: ImageInfo) {
      if (value) {
        this.src = value.src;
        this.height = value.height;
        this.width = value.width;
      } else {
        this.resetData();
      }
    }
  
    public open(): void {
      this.opened = true;
    }
  
    public close(): void {
      this.opened = false;
      this.resetData();
    }
  
    public resetData(): void {
      this.src = null;
      this.width = null;
      this.height = null;
    }
  
    /*
          Mocked backend service.
          For further details, check
          https://angular.io/guide/http#writing-an-interceptor
     */
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
      if (req.url === "saveUrl" || req.url === "removeUrl") {
        return of(new HttpResponse({ status: 200 }));
      }
  
      return next.handle(req);
    }
  }
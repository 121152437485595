import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';
import { Guid } from 'guid-typescript';
import { DictDisciplineType } from 'src/app/models/education/dictdisciplinetype.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DictDisciplineTypeService } from 'src/app/services/education/External/dictdisciplinetype.service';
import { CommonDictionariesUserAccessService } from 'src/app/services/useraccess/common-dictionaries-user-access.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'app-dict-discipline-type',
  templateUrl: './dict-discipline-type.component.html',
  styleUrls: ['./dict-discipline-type.component.css']
})
export class DictDisciplineTypeComponent implements OnInit {
  public emptyGuid: any = Guid.createEmpty();
  public disciplinetypes: DictDisciplineType[] = [];
  public enumModels: EnumModel[] = [];
  public disciplinetype: DictDisciplineType = {
    id: this.emptyGuid.value,
    name: '',
    isPractice: false,
    usedInStudyProcess: false,
    hiddenCipher: false,
    hiddenLaborIntensity: false,
    typeEnum: 0,
    filial: ''
  }

  public enumTypes = {
    1: 'Дисциплина',
    2: 'Учебная практика',
    3: 'Производственная практика',
    4: 'ГИА',
    5: 'Выпускная квалификационная работа',
    6: 'Элективная физическая культура',
    7: 'Преддипломная практика',
    8: 'Дополнительные дисциплины',
    9: 'Научно исследовательская деятельность',
    10: 'Научно исследовательская работа',
  };

  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private disciplinetypeService: DictDisciplineTypeService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService
  ) {

  }

  public ngOnInit(): void {
    this.getAccessLevel();

    for (var i = 1; i <= Object.values(this.enumTypes).length; i++) {
      this.enumModels.push(new EnumModel(Object.values(this.enumTypes)[i - 1], i))
    }
    this.getAllDisciplineType();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public getNameTypeById(id: number) {
  //  return this.enumModels.find((x) => x.enumId == id)!.name;
    if (id !== null) return this.enumModels.find((x) => x.enumId == id)!.name;
    else return ""
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      // this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      if (this.isNew == true && this.formGroup !== undefined) {
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.disciplinetypeService.updateDisciplineType(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllDisciplineType();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllDisciplineType() {
    this.disciplinetypeService.getAllDisciplineTypes()
      .subscribe(
        response => {
          this.disciplinetypes = response;
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(
        response => {
          this.editable = response.accessLevelDiscipline==1?false:true
        },error => {});
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { id?: any; name?: any; isPractice?: any; usedInStudyProcess?: any; hiddenCipher?: any; hiddenLaborIntensity?: any; typeEnum?: any; }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    name: new FormControl(dataItem.name),
    isPractice: new FormControl(dataItem.isPractice),
    usedInStudyProcess: new FormControl(dataItem.usedInStudyProcess),
    typeEnum: new FormControl(dataItem.typeEnum, Validators.required),
    hiddenCipher: new FormControl(dataItem.hiddenCipher),
    hiddenLaborIntensity: new FormControl(dataItem.hiddenLaborIntensity),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

export class EnumModel {
  name: string = '';
  enumId: number = 0;

  constructor(thisname: string, thisenumId: number) {
    this.name = thisname,
    this.enumId = thisenumId
  }
}
export const items: any[] = [
  {
    text: "Настройки",
    icon: "cog",
    items: [
      {
        text: "Справочники",
        items: [{
          text: "Квалификации",
          items: [
            {
              text: "Уровень подготовки",
              path: "/dicts/traininglevel"
            },
            {
              text: "Квалификация",
              path: "/dicts/qualificationdegree",
            }
            ],
            accessLevelQualification: true
        },
          {
            text: "Образование",
            accessLevelEducation: true,
            items: [
              {
              text: "Уровень образования",
              path: "/dicts/studylevel"
            },
              {
                text: "Технология обучения",
                path: "/dicts/studytechnology"
              },
              {
                text: "Контрольные акции",
                path: "/dicts/controlaction"
              },
              {
                text: "Оценки",
                path: "/dicts/mark"
              },
              {
                text: "Формы обучения",
                items: [
                  {
                    text: "Форма обучения",
                    path: "/dicts/studyform"
                  },
                  {
                    text: "Тип формы обучения",
                    path: "/dicts/studyformtype"
                  }
                ],
                path: "/dicts/studyform"
              },
            ],
          },
          {
            text: "Дисциплины",
            accessLevelDiscipline: true,
            items: [
              {
              text: "Дисциплина",
              path: "/dicts/discipline"
            },
              {
              text: "Тип дисциплины",
                path: "/dicts/disciplinetype"
              },
            ],
          },
          {
            text: "Категория подписантов",
            path: "/dicts/signatoryrole",
            accessLevelSignatoryRole: true,
          },
          {
            text: "Организации",
            path: "/dicts/organization",
            accessLevelOrganization: true,
          },
          {
            text: "Наименования образовательной организации",
            path: "/dicts/educationalorganizationname",
            accessLevelEducationalOrganizationName: true,
            //admin: true
          },
        ],
      },
      {
        text: "Настройки доступа",
        path: "/dicts/useraccess",
        admin: true
      },
      { text: "Настройки cписка подписантов", path: "/dicts/signatory", signatory: true},
     // { text: "Настройки отображения" },
      { text: "Праздники и выходные", path: "/dicts/holidaysandweekends" },
      {
        text: "Филиалы", path: "/dicts/filial", accessLevelFilial: true},
    ],
  },
];

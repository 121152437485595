import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { DictAdditionalStudentFieldsService } from 'src/app/services/contingent/dictadditionalstudentfields.service';
import { DictAdditionalStudentFields } from 'src/app/models/contingent/dictadditionalstudentfields.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {DataTypeEnum} from "../../../models/contingent/enums/data-type.enum";

@Component({
  selector: 'app-additionalstudentfields',
  templateUrl: './additionalstudentfields.component.html',
  styleUrls: ['./additionalstudentfields.component.scss']
})
export class AdditionalstudentfieldsComponent implements OnInit {

  public allAdditionalfields: DictAdditionalStudentFields[] = [];
  public Additionalfields: DictAdditionalStudentFields = {
    dictAdditionalStudentFieldId: 0,
    additionalStudentFieldName: '',
    fieldGroupName:'',
    dataType:''
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private AdditionalfieldsService: DictAdditionalStudentFieldsService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
  ) {

  }


  public ngOnInit(): void {
    this.getAllAdditionalfields();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllAdditionalfields();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      additionalStudentFieldName: "",
      fieldGroupName:"",
      dataType:""
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.AdditionalfieldsService.addadditionalstudentfields(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllAdditionalfields();
              this.notificationService.showSuccess("Добавлено");
              this.Additionalfields = {
                dictAdditionalStudentFieldId: 0,
                additionalStudentFieldName: '',
                fieldGroupName:'',
                dataType:''
              }
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить дополнительное поле')
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.AdditionalfieldsService.updateadditionalstudentfields(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllAdditionalfields();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения')
            }});
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllAdditionalfields() {
    this.AdditionalfieldsService.getAlladditionalstudentfields()
      .subscribe(
        response => {
          this.allAdditionalfields = response;
        });
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.additionalStudentFieldName + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if(result.text == "Да"){
          this.opened = false;
          this.AdditionalfieldsService.deleteadditionalstudentfields(dataItem.dictAdditionalStudentFieldId)
            .subscribe(
              () => {
                this.getAllAdditionalfields();
                this.notificationService.showSuccess("Удалено");
              });
        }
        else this.opened = false;
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

  public dataTypeName(value: string): string
  {
    switch (value) {
      case DataTypeEnum.string: return "строка";
      case DataTypeEnum.int: return "целое число";
      case DataTypeEnum.bool: return "логический";
      case DataTypeEnum.datetime: return "дата и время";
      default : return value;
    }
  }
}

const formGroup = (dataItem: { additionalStudentFieldName: any; fieldGroupName: any; dictAdditionalStudentFieldId?: any; dataType?: any; }) =>
  new UntypedFormGroup({
    dictAdditionalStudentFieldId: new UntypedFormControl(dataItem.dictAdditionalStudentFieldId),
    additionalStudentFieldName: new UntypedFormControl(dataItem.additionalStudentFieldName, Validators.required),
    fieldGroupName: new UntypedFormControl(dataItem.fieldGroupName),
    dataType: new UntypedFormControl(dataItem.dataType),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};


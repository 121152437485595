import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  DictAdditionalWork,
  DictControllingActionGet,
  DictDisciplineGet,
  DictExamTypeParameter,
  DictIncreasedCoefficient,
  DictPost, DictPracticeType,
  DictSalaryType,
  DictStudyForm,
  DictStudyLevel,
  DictTrainingLevel
} from '../../../models/disciplineworkload/externals.model';
import {DictTypeWorkGet} from "../../../models/disciplineworkload/assignements.model";

@Injectable({
  providedIn: 'root'
})
export class DictService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.dict}`;
  constructor(private http: HttpClient) { }

  // Get studylevels
  getStudyLevels(): Observable<DictStudyLevel[]> {
    return this.http.get<DictStudyLevel[]>(this.baseUrl + '/StudyLevels/');
  }

  // Get studyforms
  getStudyForms(): Observable<DictStudyForm[]> {
    return this.http.get<DictStudyForm[]>(this.baseUrl + '/StudyForm/');
  }

  // Get trainingLevels
  getTrainingLevels(): Observable<DictTrainingLevel[]> {
    return this.http.get<DictTrainingLevel[]>(this.baseUrl + '/TrainingLevel/');
  }

  // Get additional works
  getAdditionalWorks(type: number | null = null): Observable<DictAdditionalWork[]> {
    return this.http.get<DictAdditionalWork[]>(this.baseUrl + '/AdditionalWorks' + (type ? '?type=' + type : ''));
  }

  // Список дисциплин
  getDisciplines(): Observable<DictDisciplineGet[]> {
    return this.http.get<DictDisciplineGet[]>(this.baseUrl + '/Disciplines/');
  }

  // Контрольные акции
  getControllingActions(): Observable<DictControllingActionGet[]> {
    return this.http.get<DictControllingActionGet[]>(this.baseUrl + '/ControllingActions/');
  }

  // Типы ставок
  getSalaryTypes(): Observable<DictSalaryType[]> {
    return this.http.get<DictSalaryType[]>(this.baseUrl + '/SalaryTypes/');
  }

  // Должности
  getPosts(): Observable<DictPost[]> {
    return this.http.get<DictPost[]>(this.baseUrl + '/Posts/');
  }

  getDictTypeWorks(): Observable<DictTypeWorkGet[]> {
    return this.http.get<DictTypeWorkGet[]>(this.baseUrl + '/TypeWorks/');
  }

  getIncreasedCoefficients(): Observable<DictIncreasedCoefficient[]> {
    return this.http.get<DictIncreasedCoefficient[]>(this.baseUrl + '/IncreasedCoefficients');
  }

  getExamTypeParameters(): Observable<DictExamTypeParameter[]> {
    return this.http.get<DictExamTypeParameter[]>(this.baseUrl + '/ExamTypeParameters');
  }

  getPracticeTypes(): Observable<DictPracticeType[]> {
    return this.http.get<DictPracticeType[]>(this.baseUrl + '/PracticeTypes');
  }
}

<announcement-header *ngIf="isAdmin || accessLevel"></announcement-header>
<div class="grid-layout-container container">
    <div class="title-message m-b15">
        <div class="alerts">
        Объявления и новости
        </div>
        <button kendoButton class="blue-button m-r10"
                [routerLink]="['/alert']"
        >
          Для меня
        </button>
        <button kendoButton class="white-button"
                  [routerLink]="['/author']">
            Мои
        </button>
    </div>

    <div class="messages">
      <div class="m-r30"> Всего объявлений и новостей для меня: {{ total }} </div>
      <div class="m-r30 read"> Прочитано: {{ firstParametr }}</div>
      <div class="blue-color"> Не прочитано: {{ calculatedParametr }} </div>
    </div>

    <div class="filter-container">
      <kendo-expansionpanel subtitle="Поиск новостей и объявлений" class="drop-down-panel" [(expanded)]="expandedState">
          <ng-template kendoExpansionPanelTitleDirective>
              <h2 class="search-title">Поиск новостей и объявлений</h2>
          </ng-template>

          <div class="search-block">
              <div class="search">
                  <div class="search-explanation">Тип</div>
                  <div>
                      <div class="search-information">
                          <div>
                              <label>
                                  <input type="radio" name="messages" [checked]="press" class="real-radio not-display" [value]= 2 [(ngModel)]="type">
                                  <span class="custom-radio"></span>
                              Все
                              </label>
                          </div>
                          <div>
                              <label>
                                  <input type="radio" name="messages" (click)="notpress()" class="real-radio not-display" [value]= 1 [(ngModel)]="type">
                                  <span class="custom-radio"></span>
                              Новости
                              </label>
                          </div>
                          <div>
                              <label>
                                  <input type="radio" name="messages"  (click)="notpress()" class="real-radio not-display" [value]= 0  [(ngModel)]="type">
                                  <span class="custom-radio"></span>
                              Объявления
                              </label>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="search">
                  <div class="search-explanation"> Статус прочтения </div>
                  <div>
                    <div class="search-information">
                          <div>
                              <label>
                                  <input type="radio" name="statusmessages" [checked]="presstatus" class="real-radio not-display" [value]= 0  [(ngModel)]="status">
                                  <span class="custom-radio"></span>
                              Все
                              </label>
                          </div>
                          <div>
                              <label>
                                  <input type="radio" name="statusmessages" (click)="notpresstatus()" class="real-radio not-display" [value]= 1 [(ngModel)]="status">
                                  <span class="custom-radio"></span>
                              Непрочитанные
                              </label>
                          </div>
                          <div>
                              <label>
                                <input type="radio" name="statusmessages" (click)="notpresstatus()" class="real-radio not-display" [value]= 2 [(ngModel)]="status">
                                  <span class="custom-radio"></span>
                              Прочитанные
                              </label>
                          </div>
                    </div>
                </div>
            </div>
            <div class="search">
                <div class="search-explanation">Запрос</div>
                <div class="search-information">
                    <kendo-textbox  class="search-input"
                                    placeholder="Введите запрос"
                                    [clearButton]="true"
                                    name="query"
                                    [(ngModel)]="query"
                    >
                    </kendo-textbox>
                </div>
            </div>

            <div class="search">
                <div class="search-explanation"> Тег для поиска </div>
                <div class="search-information">
                    <kendo-textbox  class="search-input"
                                    placeholder="Введите тег без #"
                                    [clearButton]="true"
                                    name="tag"
                                    [(ngModel)]="tag"
                    >
                    </kendo-textbox>
                </div>
            </div>

            <div class="search">
                <div class="search-explanation"> Период публикации </div>
                <div class="search-information publication">
                    <div>
                            <kendo-datepicker
                                class="m-r10 m-l0"
                                format="dd.MM.yyyy"
                                placeholder="с"
                                [(ngModel)]="start"
                                >
                                <kendo-datetimepicker-messages today="Сегодня"></kendo-datetimepicker-messages>
                            </kendo-datepicker>
                            -
                            <kendo-datepicker
                                class="m-r10"
                                format="dd.MM.yyyy"
                                placeholder="по"
                                [(ngModel)]="finish"
                                >
                                <kendo-datetimepicker-messages today="Сегодня"></kendo-datetimepicker-messages>
                            </kendo-datepicker>
                        </div>
                        <div>
                            <button kendoButton
                                    class="white m-r10"
                                    (click) = "clearAllFilters()"
                            >
                            Очистить
                            </button>
                            <button kendoButton
                                    class="blue"
                                    (click)="findMessage(1)"
                            >
                            Применить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </kendo-expansionpanel>
    </div>

    <div class="m-b30 m-t25">
        <button *ngIf="accesses.length > 0" class="k-button k-button-sm k-rounded-md k-button-solid-success k-button-solid ng-star-inserted"
                kendoButton
                icon="plus"
                (click)="createAnnouncement()"
            >
        Добавить новое
        </button>
    </div>

    <div class="message-list">

        <div [ngClass]="{message:  element.isOpened , message_notread: !element.isOpened}" *ngFor="let element of alert" >
            <div [ngClass]="{panel: element.isOpened, panel_notread: !element.isOpened}"></div>
            <div class="data">
                <div class="title-organization">
                    <div class="title-news" [routerLink]="['/show/' , element.id]"> {{ element.title}} </div>
                </div>
            <!--    <div class="text-news" [routerLink]="['/show/' , element.id]" [innerHTML]="element.content"></div>-->
            <div [routerLink]="['/show/' , element.id]" style="padding: 5px;">
                <kendo-editor  [(ngModel)]="element.content" [iframe]="false" [disabled]="true"
                                style="border: none; opacity: inherit; height: 80px; overflow: hidden; background-color: transparent; font-family: 'Montserrat SemiBold';" >
                    <kendo-toolbar style="display: none; height: 0px; width: 0px;"></kendo-toolbar>
                </kendo-editor>
            </div>
                <div class="from-date-news"> {{ element.authorFio}} </div>
                <div class="from-date-news"> {{ element.published | date:'dd.MM.yyyy HH:mm'}} </div>
            </div>
        </div>

    </div>
    <div class="pages">
        <kendo-datapager *ngIf="total/pageSize > 1"
        [total]="messageCount"
        [pageSize]="pageSize"
        [pageSizeValues]="pageSizes"
        [info]="info"
        [skip]="skip"
        (pageChange)="onPageChange($event)"
        >
        </kendo-datapager>
    </div>
</div>


<dicts-header></dicts-header>
<h1>Квалификация</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="qualificationdegrees" (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: qualificationdegrees.length < 8}"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [filterable]="true" [filter]="filter" (filterChange)="filterChange($event)"
              >

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictQualificationDegreeId" title="Id"> </kendo-grid-column> -->
    <kendo-grid-column field="qualificationName"
                       title="Название"
                       [width]="350"
                       headerClass="gridHeader">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.qualificationName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="qualificationSName"
                       title="Сокр. название"
                       [width]="200"
                       headerClass="gridHeader">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.qualificationSName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dictTrainingLevelId" title="Название уровня подготовки"
                       [width]="300"
                       headerClass="gridHeader">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown
            [filter]="filter"
            [data]="traininglevels"
            textField="trainingLevelName"
            valueField="dictTrainingLevelExternalId"
          >
          </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ nameTrainingLevel(dataItem.dictTrainingLevelId)?.trainingLevelName }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="traininglevels"
                            textField="trainingLevelName"
                            valueField="dictTrainingLevelExternalId"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('dictTrainingLevelId')">
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Сокр. название"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.dictTrainingLevel.trainingLevelSName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>


  </kendo-grid>

</div>

<div kendoDialogContainer></div>

import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Budget} from "../../../models/contingent/budget.model";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AddEvent, CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {BudgetService} from "../../../services/contingent/budget.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {BudgetcategoryService} from "../../../services/contingent/budgetcategory.service";
import {BudgetCategory} from "../../../models/contingent/budgetcategory.model";
import { DictFilial } from '../../../models/contingent/dictfilial.model';
import { DictFilter } from '../../..//models/contingent/filter.model';
import { DictFilialService } from '../../../services/contingent/dictfilial.service';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-budgetcategory',
  templateUrl: './budgetcategory.component.html',
  styleUrls: ['./budgetcategory.component.scss']
})
export class BudgetcategoryComponent implements OnInit {

  public budgets: Budget[] = [];
  public budgetCategories: BudgetCategory[] = [];
  public filials: DictFilial[] = [];
  public secondFilter: DictFilter = {
    dictFilialExternalId: undefined
  }
  public budgetsByFilter: Budget[] = [];
  public budgetCategoriesByFilter: BudgetCategory[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private budgetService: BudgetService,
    private budgetCategoryService: BudgetcategoryService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: ContingentUserAccessService,
    private dictFilialService: DictFilialService,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getAllFilials();
    this.getAllBudget();
    this.getAllBudgetCategories();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllBudgetCategories();
  }

  public nameBudget(value: string) {
    return this.budgets.find((x) => x.dictStudentBudgetExternalId === value)?.studentBudgetName ?? "";
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.formGroup = formGroup({
      categoryName: "",
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({ sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: number | undefined = this.editedRowIndex): void
  {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.budgetCategoryService.addBudgetCategory(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllBudgetCategories();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить бюджет');
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.budgetCategoryService.updateBudgetCategory(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllBudgetCategories();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllBudget() {
    this.budgetService.getAllBudget()
      .subscribe(
        response => {
          this.budgets = response;
          this.saveCurrentFilter();
        });
  }

  public getAllBudgetCategories() {
    this.budgetCategoryService.getAllBudgetCategory()
      .subscribe(
        response => {
          this.budgetCategories = response;
          this.budgetCategoriesByFilter = response;
          this.saveCurrentFilter();
        });
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
    filialSName: "Не выбрано",
    dictFilialExternalId: null,
  };

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public onValueChange(value: any): void {
    this.saveCurrentFilter();
  }

  saveCurrentFilter() {
    this.budgetsByFilter = [];
    this.budgetsByFilter = this.budgets.filter(x => Guid.parse(x.filialId) == this.secondFilter.dictFilialExternalId);
    this.budgetCategoriesByFilter = [];
    this.budgetCategoriesByFilter = this.budgetCategories.filter(x => Guid.parse(x.filialId) == this.secondFilter.dictFilialExternalId);
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.categoryName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.budgetCategoryService.deleteBudgetCategory(dataItem.dictStudentBudgetCategoryExternalId)
            .subscribe({
              next:() => {
                this.getAllBudgetCategories();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }




  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

}

const formGroup = (dataItem: { dictStudentBudgetCategoryExternalId?: string; categoryName?: string;
  categorySName?: string; dictStudentBudgetId?: string;  order?: number;}) =>
  new UntypedFormGroup({
    dictStudentBudgetCategoryExternalId: new UntypedFormControl(dataItem.dictStudentBudgetCategoryExternalId),
    categoryName: new UntypedFormControl(dataItem.categoryName, Validators.required),
    categorySName: new UntypedFormControl(dataItem.categorySName),
    dictStudentBudgetId: new UntypedFormControl(dataItem.dictStudentBudgetId, Validators.required),
    order: new UntypedFormControl(dataItem.order),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

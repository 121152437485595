import {Guid} from "guid-typescript";

export class Citizenship {
  public citizenshipId!: number;
  public citizenshipExternalId?: Guid;
  public country!: string;
  public countryPhoneCode?: string;
  public code?: string;
  public rCountry?: string;
  public dCountry?: string;
  public vCountry?: string;
  public tCountry?: string;
  public pCountry?: string;
  public order?:string;
}

import {AfterViewInit, Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Affiliation, Author} from "../../../../../models/publications/editForm/author.model";
import {CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {hasClass, isChildOf} from '../../../../../helpers/elementRef-helper';
import {DictPost} from "../../../../../models/publications/dict/post.model";
import {DictLaborRelation} from "../../../../../models/publications/dict/laborRelation.model";
import {DictScienceDegree} from "../../../../../models/publications/dict/scienceDegree.model";
import {ActivatedRoute} from "@angular/router";
import {Person} from "../../../../../models/publications/person.model";
import {AuthorTypesService} from "../../../../../services/science/publications/authorTypes.service";
import {DictAuthorType} from "../../../../../models/publications/dict/authorType.model";
import {DictAuthorRole} from "../../../../../models/publications/dict/authorRole.model";
import {AuthorRolesService} from "../../../../../services/science/publications/authorRoles.service";
import {ScienceDegreesService} from "../../../../../services/science/publications/scienceDegrees.service";
import {PublicationsPostService} from "../../../../../services/science/publications/post.service";
import {LaborRelationsService} from "../../../../../services/science/publications/laborRelations.service";
import {PublicationsPersonService} from "../../../../../services/science/publications/person.service";
import {AuthorsService} from "../../../../../services/science/publications/authors.service";
import {PublicationAuthorsService} from "../../../../../services/science/publications/publication-authors.service";
import {DateFromUTCAsLocal, DateToString, DisableWheelEvent, TimeZoneFix} from '../../../../../helpers/date-helper';
import {Location} from "@angular/common";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {ExternalAuthor} from "../../../../../models/publications/authors/authors.model";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {PublicationsEditFormService} from "../../../../../services/science/publications/editForm.service";
import {lastValueFrom} from 'rxjs';
import {VirtualizationSettings} from "@progress/kendo-angular-dropdowns";
import {environment} from "../../../../../../environments/environment";
import {PublicationsUserAccessService} from '../../../../../services/useraccess/publications-user-access.service';
import {AccessRights} from '../../../../../models/useraccess/publications/publicationsUserAccess';
import {DictOrganization} from "../../../../../models/publications/dict/organization.model";
import {OrganizationsService} from "../../../../../services/science/publications/organizations.service";
import {StructureSubdivisionsService} from '../../../../../services/science/publications/structureSubdivisions.service';
import {DictStructureSubdivision} from '../../../../../models/publications/dict/structureSubdivision.model';

@Component({
  selector: 'publications-edit-form-addAuthor',
  templateUrl: './publications-edit-form-add-author.component.html',
  styleUrls: ['./publications-edit-form-add-author.component.scss']
})
export class PublicationsEditFormAddAuthorComponent implements OnInit, AfterViewInit {

  @ViewChild(GridComponent) private grid!: GridComponent;
  private authorId!: string;
  private index!: string;
  public currentAuthor: FormGroup = this.defaultAuthor();
  public organizationGroup: FormGroup = this.getOrganizationFormGroup({});
  public currentAuthorType? = '';
  public currentPerson: Person | undefined = new Person();

  public organizations: Affiliation[] = [];
  public dictAuthorTypes: DictAuthorType[] = [];
  public dictPosts: DictPost[] = [];
  public dictLaborRelations: DictLaborRelation[] = [];
  public dictScienceDegrees: DictScienceDegree[] = [];
  public dictAuthorRoles: DictAuthorRole[] = [];
  public dictOrganizations: DictOrganization[] = [];
  public structureSubdivisions: DictStructureSubdivision[] = [];

  private editedRowIndex: number | undefined;
  private isNew = false;
  public autocompleteData: Person[] = [];
  public organizationData: DictOrganization[] = [];
  private authorsData: Person[] = [];
  public mask = "000-000-000 00";
  public filterVirtualization: boolean | VirtualizationSettings = {
    itemHeight: 28
  };
  public loading: boolean = false;
  public isAutoFilled = false;

  private defaultOrganization: Affiliation = {
    name: 'Нижегородский государственный технический университет им. Р.Е. Алексеева',
    city: 'Нижний Новгород',
    country: 'Российская Федерация'
  }

  public currentUserAccess = this.userAccessService.currentUserAccess$.value;
  protected readonly AccessRights = AccessRights;

  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private publicationAuthorsService: PublicationAuthorsService,
    private authorTypesService: AuthorTypesService,
    private personService: PublicationsPersonService,
    private authorService: AuthorsService,
    private laborRelationsService: LaborRelationsService,
    private postsService: PublicationsPostService,
    private scienceDegreesService: ScienceDegreesService,
    private authorRolesService: AuthorRolesService,
    private organizationsService: OrganizationsService,
    private editFormService: PublicationsEditFormService,
    private userAccessService: PublicationsUserAccessService,
    private structureSubdivisionsService: StructureSubdivisionsService,
    private location: Location
  ) {}

  async ngOnInit() {
    this.authorId = this.activatedRoute.snapshot.params['authorId'];
    this.index = this.activatedRoute.snapshot.params['index'];
    await this.getDicts();

    if (this.authorId || this.index) {
      await this.getCurrentAuthor();
    }

    this.offTableClick();
  }

  ngAfterViewInit() {
    DisableWheelEvent();
  }

  private async getCurrentAuthor() {
    const data = this.editFormService.getAuthorData();
    this.currentAuthor = this.defaultAuthor(data?.typeId, data);
    this.currentPerson = this.currentAuthor.getRawValue();
    this.organizations = data?.affiliations ?? [];
    await this.changeAuthorsData();
    this.isAutoFilled = this.authorsData.some((item) => item.id === this.currentPerson?.personId || item.fio === this.getFIO(item));
    this.checkAccessRights();
  }

  private async getDicts() {
    await lastValueFrom(this.authorTypesService.getAuthorTypes()).then(
      value => this.dictAuthorTypes = value.filter(type => type.name !== 'Студент'));
    await lastValueFrom(this.laborRelationsService.getLaborRelations()).then(value => this.dictLaborRelations = value);
    await lastValueFrom(this.postsService.getPosts()).then(value => this.dictPosts = value);
    await lastValueFrom(this.scienceDegreesService.getScienceDegrees()).then(
      value => this.dictScienceDegrees = value);
    await lastValueFrom(this.authorRolesService.getAuthorRoles()).then(value => this.dictAuthorRoles = value);
    await lastValueFrom(this.organizationsService.getOrganizations()).then(value => this.dictOrganizations = value ?? []);
    await lastValueFrom(this.structureSubdivisionsService.getStructureSubdivisions()).then(value => this.structureSubdivisions = value);
  }

  private getFIO(person: Person | ExternalAuthor) {
    return `${person.lastName} ${person.firstName} ${person.middleName}`;
  }

  private async getEmployeeAuthors() {
    await lastValueFrom(this.personService.getPersons()).then(value => {
      this.authorsData = value.map(
          item => ({...item, fio: this.getFIO(item)})
      );
    });
  }

  private async getExternalAuthors() {
    await lastValueFrom(this.authorService.getExternalAuthors()).then(value => {
      if (value) {
        this.authorsData = value.map(item => ({...item, fio: this.getFIO(item)}));
        this.editFormService.externalAuthors$.next(this.authorsData);
      }
    });
  }

  private defaultAuthor(typeId?: string | null, author?: Author) {
    return new FormGroup({
      id: new FormControl<string | null>(this.authorId ?? null),
      personId: new FormControl<string | null>(author?.personId ?? null),
      firstName: new FormControl(author?.firstName ?? '', Validators.required),
      lastName: new FormControl(author?.lastName ?? '', Validators.required),
      middleName: new FormControl(author?.middleName ?? '', Validators.required),
      typeId: new FormControl<string | null>(typeId ?? null, Validators.required),
      birthday: new FormControl<Date | null>(DateFromUTCAsLocal(author?.birthday) ?? null),
      identificator: new FormControl<string | null>(author?.identificator ?? null),
      laborRelationId: new FormControl<string | null>(author?.laborRelationId ?? null, Validators.required),
      postId: new FormControl<string | null>(author?.postId ?? null),
      academicDegreeId: new FormControl<string | null>(author?.academicDegreeId ?? null),
      roleId: new FormControl<string | null>(author?.roleId ?? null, Validators.required),
      departmentId: new FormControl<string | null>(author?.departmentId ?? null)
    });
  }

  private getOrganizationFormGroup(dataItem: {name?: string, country?: string, city?: string}) {
    return new FormGroup({
      name: new FormControl(dataItem.name ?? '', Validators.required),
      city: new FormControl(dataItem.city ?? '', Validators.required),
      country: new FormControl(dataItem.country ?? '', Validators.required)
    });
  }

  private offTableClick() {
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid") &&
        !isChildOf(target, "addButton") &&
        !hasClass(target, "addButton") &&
        this.editedRowIndex !== undefined) {
        this.saveOrganization();
      }
    });
  }

  public toggleField(field: string, toggle: boolean) {
    return toggle
      ? this.currentAuthor.controls[field].enable()
      : this.currentAuthor.controls[field].disable();
  }

  public async onAuthorTypeChange(id: string) {
    this.toggleField('birthday', true);
    this.toggleField('identificator', true);
    await this.changeAuthorsData();
    this.currentAuthor = this.defaultAuthor(id);
    this.isAutoFilled = false;
  }

  private autofillDefaultOrganization() {
    if (!(this.authorId || this.index) && this.organizations.length === 0 && environment.headerTitle.full === this.defaultOrganization.name) {
      this.organizations.push(this.defaultOrganization);
    }
  }

  private async changeAuthorsData() {
    this.currentAuthorType = this.dictAuthorTypes.find(item => item.id === this.currentAuthor.get('typeId')?.value)?.name;
    switch (this.currentAuthorType) {
      case 'Сотрудник НГТУ':
        await this.getEmployeeAuthors();
        this.autofillDefaultOrganization();
        break;
      case 'Внешний автор':
        await this.getExternalAuthors();
        break;
    }
  }

  public addOrganization() {
    if (this.editedRowIndex !== undefined) {
      this.saveOrganization();
    }

    this.organizationGroup = this.getOrganizationFormGroup({});

    this.editedRowIndex = this.organizations.length;
    this.isNew = true;
    this.grid.addRow(this.organizationGroup);
  }

  private saveOrganization() {
    if (this.organizationGroup.invalid &&
      Object.values(this.organizationGroup.getRawValue()).some((item) => !!item)) {
      return;
    }
    if (this.organizationGroup.valid) {
      this.organizations[this.editedRowIndex!] = this.organizationGroup.value;
    }

    this.closeEditor();
  }

  private closeEditor() {
    for (let i = -1; i < this.organizations.length; i++) {
      this.grid.closeRow(i);
    }
    this.editedRowIndex = undefined;
    this.isNew = false;
  }

  public async editOrganization({ rowIndex, columnIndex, sender, dataItem}: CellClickEvent) {
    if (this.isNew || this.dictOrganizations.find((item) => item.name === dataItem.name)) {
      return;
    }

    if (this.editedRowIndex !== undefined) {
      if (this.organizationGroup.valid) {
        this.saveOrganization();
      }
      if (this.editedRowIndex === undefined) {
        return;
      }
    }

    this.organizationGroup = this.getOrganizationFormGroup(dataItem);

    if (dataItem.id) {
      this.organizationGroup.setControl('id', new FormControl(dataItem.id));
    }

    if (this.organizationGroup.invalid) {
      return;
    }

    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.organizationGroup, {columnIndex});
  }

  public removeOrganization({rowIndex, dataItem}: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить организацию ${dataItem.name}?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.organizations = this.organizations.filter((elem, i) => i !== rowIndex);
      }
    });
  }

  public filterOrganizations(value: string) {
    this.organizationData = this.dictOrganizations.filter((item) =>
      item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public fillOrganization(value: string) {
    if (!value) {
      this.organizationGroup = this.getOrganizationFormGroup({});
      return;
    }

    const target = this.dictOrganizations.find((item) => item.name === value);
    if (target) {
      this.organizationGroup.controls['name'].setValue(target.name);
      this.organizationGroup.controls['city'].setValue(target.city);
      this.organizationGroup.controls['country'].setValue(target.country);

      this.saveOrganization();
    }
  }

  public filterAutomplete(value: string) {
    this.autocompleteData = this.authorsData.filter((s) => s.fio.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public checkAccessRights() {
    const fields = ['birthday', 'identificator'];
    fields.forEach((field) => {
      const enable =
        (this.userAccessService.currentUserAccess$.value.allPublication === AccessRights.Write || !this.currentAuthor.getRawValue()[field])
        && (this.currentAuthorType !== 'Сотрудник НГТУ');
      this.toggleField(field, enable);
    });
  }

  public fillFields(value: any) {
    const target = this.authorsData.find((item) => item.fio === value);
    this.currentAuthorType = this.dictAuthorTypes.find(item => item.id === this.currentAuthor.value.typeId)?.name;

    if (this.currentAuthorType === 'Внешний автор') this.currentPerson = target;

    this.isAutoFilled = target !== undefined;

    if (target) {
      this.currentAuthor.controls['firstName'].setValue(target.firstName);
      this.currentAuthor.controls['lastName'].setValue(target.lastName);
      this.currentAuthor.controls['middleName'].setValue(target.middleName);
      this.currentAuthor.controls['birthday'].setValue(DateFromUTCAsLocal(target.birthday));
      this.currentAuthor.controls['identificator'].setValue(target.identificator);
      this.currentAuthor.controls['personId'].setValue(target.id);
    }
    if (!value) {
      this.currentPerson = undefined;
      this.currentAuthor = this.defaultAuthor(this.currentAuthor.value.typeId);
    }
    this.checkAccessRights();
  }

  private saveExternalAuthor() {
    const author: ExternalAuthor = {
      firstName: this.currentAuthor.value.firstName,
      lastName: this.currentAuthor.value.lastName,
      middleName: this.currentAuthor.value.middleName,
      birthday: this.currentAuthor.getRawValue().birthday?.toISOString(),
      identificator: this.currentAuthor.getRawValue().identificator
    };

    const match = this.authorsData.find((item) =>
      item.fio === this.getFIO(author)
    );

    if (!match) {
      this.authorService.addExternalAuthor(author).subscribe({
        next: () => {
          this.notificationsService.showSuccess('Успешно');
        },
        error: (error) => {
          this.notificationsService.showError(error);
        }
      });
    }
  }

  public async onSave() {
    if (this.currentAuthor.invalid) {
      this.notificationsService.showError('Заполните все поля формы');
      return;
    }

    if (this.editedRowIndex !== undefined) {
      this.saveOrganization();

      if (this.organizationGroup.invalid && this.organizations.length) {
        this.notificationsService.showError('Дополните данные организации');
        return;
      }
    }

    if (!this.organizations.length) {
      this.notificationsService.showError('Добавьте организацию');
      return;
    }

    switch (this.currentAuthorType) {
      case 'Сотрудник НГТУ':
        const match = this.authorsData.find((item) =>
          item.fio === this.getFIO(this.currentAuthor.value) &&
          item.identificator === this.currentAuthor.getRawValue().identificator
        );
        if (!match) {
          this.notificationsService.showError('Такого сотрудника не существует');
          return;
        }
        break;
      case 'Внешний автор':
        this.saveExternalAuthor();
        break;
    }

    let authorData = {
      ...this.currentAuthor.getRawValue(),
      affiliations: this.organizations,
      birthday: this.currentAuthor.getRawValue().birthday
        ? TimeZoneFix(DateToString(this.currentAuthor.getRawValue().birthday, 'yyyy-MM-dd'))
        : null
    };

    if (this.index) {
      authorData = {
        index: this.index,
        author: authorData
      };
    }

    this.editFormService.setAuthorData(authorData);
    this.location.back();
  }

  public onCancel() {
    this.location.back();
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DictCycle, DictCycleUsableInfo } from 'src/app/models/education/dictcycle.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictCycleService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictcycle}`;

  constructor(private http: HttpClient) { }

  //Get all Cycles
  public getAllCycles(): Observable<DictCycle[]> {
    return this.http.get<DictCycle[]>(this.baseUrl + '/GetCycles/');
  }

  //Get plan Cycles
  public getPlanCycles(educationPlanId: string): Observable<DictCycle[]> {
    return this.http.get<DictCycle[]>(this.baseUrl + '/GetEduPlanCycles/' + educationPlanId);
  }

  // Add Cycle
  addCycle(dictCycle: DictCycle): Observable<DictCycle> {
    return this.http.post<DictCycle>(this.baseUrl + '/CreateCycle/' , dictCycle)
      .pipe(catchError(this.handleError));
  }

  // Edit Cycle
  updateCycle(dictCycle: DictCycle): Observable<DictCycle> {
    return this.http.put<DictCycle>(this.baseUrl + '/UpdateCycle/' + dictCycle.dictCycleId, dictCycle)
      .pipe(catchError(this.handleError));
  }

  // Remove Cycle
  deleteCycle(id: string): Observable<DictCycleUsableInfo[]> {
    return this.http.delete<DictCycleUsableInfo[]>(this.baseUrl + '/DeleteCycle/' + id);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

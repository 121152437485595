import {Injectable} from "@angular/core";
import {catchError, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictPublicationType} from "../../../models/publications/dict/publicationType.model";
import {handleError} from "../../../helpers/publications/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class TypesService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/Type/`;

  constructor(private http: HttpClient) {
  }

  public getTypes(): Observable<DictPublicationType[]> {
    return this.http.get<DictPublicationType[]>(this.baseUrl);
  }

  public addType(type: DictPublicationType): Observable<DictPublicationType> {
    return this.http.post<DictPublicationType>(this.baseUrl, type)
      .pipe(catchError(handleError));
  }

  public removeType(id: string): Observable<DictPublicationType> {
    return this.http.delete<DictPublicationType>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }

  public updateType(type: DictPublicationType): Observable<DictPublicationType> {
    return this.http.put<DictPublicationType>(this.baseUrl, type)
      .pipe(catchError(handleError));
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StudPlan } from '../../models/contingent/studplan.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {StudentEducationPlan} from "../../models/contingent/educationplandiscipline.model";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class StudplanService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studplan}`;

  constructor(private http: HttpClient) { }

  getStudPlanByStudentId(id: Guid): Observable<StudPlan> {
    return this.http.get<StudPlan>(this.baseUrl + '/GetStudPlanByStudentId/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add studplan
  addstudplan(studplan: StudPlan): Observable<StudPlan> {
    return this.http.post<StudPlan>(this.baseUrl, studplan)
      .pipe(catchError(this.handleError));
  }

  async getEducationPlanDiscipline(id: Guid): Promise<Observable<StudentEducationPlan>> {
    return this.http.get<StudentEducationPlan>(this.baseUrl + '/GetEducationPlanDisciplineByStudentId/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

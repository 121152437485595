<kendo-stacklayout [gap]="30" [orientation]="'vertical'">
  <kendo-stacklayout
    [gap]="15"
    [orientation]="'horizontal'"
    [align]="{ horizontal: 'end' }"
  >
    <button kendoButton icon="gear" *ngIf="editable" (click)="handleClickSettings()">
      Настройки графика уп
    </button>
    <button
      kendoButton
      *ngIf="editable"
      themeColor="primary"
      (click)="saveTable()"
      [disabled]="isBlockedAction"
    >
      Сохранить
    </button>
  </kendo-stacklayout>
  <kendo-grid [data]="tableData" class="table" [loading]="isLoading">
    <ng-template kendoGridLoadingTemplate>
      <div class="table__loader">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <kendo-grid-column
      [width]="70"
      [class]="'table__cell'"
      [headerClass]="'table__header'"
    >
      <ng-template kendoGridHeaderTemplate>
        <div [ngClass]="{
          'table__header table__header--column':true,
          'table__header--column-short': settings?.showDatesInGraph==false
          }">
          <div class="table__header table__header--title table__header--item">
            Мес.
          </div>
          <div class="table__header table__header--grow table__header--item table__header--days">
            Числа
          </div>
          <div class="table__header table__header--title table__header--item">
            Нед.
          </div>
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.courseNumber }} курс
      </ng-template>
    </kendo-grid-column>
    <ng-template ngFor let-column [ngForOf]="tableHeaderData">
      <kendo-grid-column-group
        title="{{ dictMonth[column.month] }}"
        *ngIf="!!column.month"
        [headerClass]="'table__header table__header--title'"
      >
        <kendo-grid-column
          [width]="40"
          *ngFor="let day of column.weeks"
          [class]="'table__cell'"
          [headerClass]="'table__header'"
        >
          <ng-template kendoGridHeaderTemplate>
            <div [ngClass]="{
                'table__subheader':true,
                'table__subheader--short': settings?.showDatesInGraph==false
              }">
              <div class="table__week-header table__week-header--number">
                {{ day.title }}
              </div>
              <div class ="table__week-header table__week-header--days">{{ day.days }}</div>
            </div>
          </ng-template>
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            let-columnIndex="columnIndex"
            let-rowIndex="rowIndex"
          >
            <educational-process-schedule-cell
              [weekInfo]="dataItem.weeksNumber[columnIndex - 1]"
              [currentWeekNumber]="columnIndex"
              (cellOpenMenu)="openPopup($event)"
              (selectedCell)="selectCell($event)"
              (startDrag)="draggedCellActive($event)"
              [course]="rowIndex"
              [isChoosedCells]="isChoosedCells"
              [isDisplayDot]="!choosedCells.length"
              [isBlockedAction]="isBlockedAction"
            ></educational-process-schedule-cell>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column
        *ngIf="!column.month"
        [width]="40"
        [class]="'table__cell'"
        [headerClass]="'table__header'"
      >
        <ng-template kendoGridHeaderTemplate>
          <div [ngClass]="{
                'table__subheader':true,
                'table__subheader--long': !(settings?.showDatesInGraph==false),
                'table__subheader--short': settings?.showDatesInGraph==false
              }">
            <div class="table__week-header table__week-header--number">
              {{ column.title }}
            </div>
            <div class="table__week-header table__week-header--days">{{ column.days }}</div>
          </div>
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-columnIndex="columnIndex"
          let-rowIndex="rowIndex"
        >
          <educational-process-schedule-cell
            [weekInfo]="dataItem.weeksNumber[columnIndex - 1]"
            [currentWeekNumber]="columnIndex"
            [course]="rowIndex"
            (cellOpenMenu)="openPopup($event)"
            (selectedCell)="selectCell($event)"
            (startDrag)="draggedCellActive($event)"
            [isChoosedCells]="isChoosedCells"
            [isDisplayDot]="!choosedCells.length"
            [isBlockedAction]="isBlockedAction"
          ></educational-process-schedule-cell>
        </ng-template>
      </kendo-grid-column>
    </ng-template>
  </kendo-grid>
  <div class="frame" #cellFrame></div>
  <ng-template #popupTemplate>
    <ul class="list">
      <li (click)="copyCell()" class="list__element">Копировать</li>
      <li
        (click)="pasteCell()"
        [class]="{
          list__element: true,
          'list__element--disabled': !bufferCell
        }"
      >
        Вставить
      </li>
      <li class="list__element" (click)="separateCell()">
        Разбить по дням / объединить в неделю
      </li>
      <li class="list__element" (click)="clearCell()">Очистить</li>
    </ul>
    <kendo-listview
      [data]="dictScheduleStudyProcessTypes"
      [class]="'list-view'"
    >
      <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
        <div
          class="list-view__row list-view__element"
          (click)="applyDictSymbol(dataItem)"
        >
          <div class="list-view__symbol">{{ dataItem.symbol }}</div>
          <p>{{ dataItem.name }}</p>
        </div>
      </ng-template>
    </kendo-listview>
  </ng-template>
</kendo-stacklayout>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { DirectionActivity } from 'src/app/models/contingent/portfolio.model';
import { DocumentGet, DocumentPost, DocumentPut } from '../../models/portfolio/document.model';
import { CreateQuery } from '../../helpers/createQuery-helper';
import { AchievementGet, AchievementPost, AchievementPut, Achievement } from '../../models/portfolio/achievement.model';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
  documentUrl = `${environment.apiEndpoint}${environment.apiPaths.portfolio.document}`;
  achievementUrl = `${environment.apiEndpoint}${environment.apiPaths.portfolio.achievement}`;

  constructor(private http: HttpClient) { }

  //#region Documents

  /**
   * Документы
   * @param filter Фильтры
   */
  getDocuments(filter: { studentId?: string | null; typeId?: string | null }): Observable<DocumentGet[]> {
    const query = CreateQuery(filter);

    return this.http.get<DocumentGet[]>(this.documentUrl, { params: query });
  }

  /**
   * Сохранение документа
   * @param data Информауия о документе
   */
  postDocument(data: FormData): Observable<string> {
    return this.http.post<string>(this.documentUrl, data);
  }

  /**
   * Обновление документа
   * @param data Информауия о документе
   */
  putDocument(data: FormData): Observable<boolean> {
    return this.http.put<boolean>(this.documentUrl, data);
  }

  /**
   * Удаление документа
   * @param id ID документа
   */
  deleteDocument(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.documentUrl + '/' + id);
  }

  //Download file
  downloadDocumentFile(id: string) {
    return this.http.get(this.documentUrl + '/File/' + id, { responseType: 'blob' } )
  }

  //#endregion

  //#region Achievements

  /**
   * Документы
   * @param filter Фильтры
   */
  getAchievements(filter: { studentId?: string | null; directionActivityId?: string | null }): Observable<Achievement[]> {
    const query = CreateQuery(filter);

    return this.http.get<Achievement[]>(this.achievementUrl, { params: query });
  }

  /**
   * Сохранение достижения
   * @param data Информауия о документе
   */
  postAchievement(data: FormData): Observable<string> {
    return this.http.post<string>(this.achievementUrl, data);
  }

  /**
   * Обновление достижения
   * @param data Информауия о достижении
   */
  putAchievement(data: FormData): Observable<boolean> {
    return this.http.put<boolean>(this.achievementUrl, data);
  }

  /**
   * Удаление достижения
   * @param id ID документа
   */
  deleteAchievement(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.achievementUrl + '/' + id);
  }

  downloadAchievementFile(id: string) {
    return this.http.get(this.achievementUrl + '/File/' + id, { responseType: 'blob' } )
  }

  //#endregion

  //#region status

  //Сохранение достижения
  postStatus(status: any): Observable<boolean> {
    return this.http.post<boolean>(this.achievementUrl + '/AddStatus', status);
  }

  //#endregion
}

import {Guid} from "guid-typescript";
import {Department} from "./department.model";
import {DepartmentName} from "./departmentname.model";
import {EducationPlan} from "./educationplan.model";

export class EduGroup {
  public createdAt?: Date;
  public eduGroupId!: number;
  public eduGroupExternalId?: Guid;
  public educationStandardId?: Guid;
  public groupName?: string;
  public studyFormName?: string;
  public courseNum!: number;
  public dateBegin!: Date;
  public dateEnd!: Date;
  public facultyId!: Guid;
  public departments?: Department;
  public planId?: string;
  public closed?: boolean;
  public closedNewYear?: boolean;
  public educationPlan?: EducationPlan;
  public studyFormExternalId?: Guid;
  public trainingLevelId?:Guid;
  public budgetQuotesStudentAdmissionBenchmarks?:number;
  public commerceQuotesStudentAdmissionBenchmarks?:number;
  public editable?: boolean;
  public editElder?: boolean;
  public applyPlanChanged?: boolean;
}

export class EduGroupRequest {
  public groupName?: string;
  public courseNum?: number;
  public dateBegin?: Date;
  public dateEnd?: Date;
  public facultyId?: Guid;
  public planId?: Guid;
}

export class EduGroupNameRequest {
  public planId?: Guid;
  public facultyId?: Guid;
  public eduGroupId?: Guid | string;
  public admissionYear?: number;
  public studyYear?: number;
  public course?: number;
}

export class AcademicGroup {
  public eduGroupId!: number;
  public eduGroupExternalId!: Guid;
  public groupName?: string;
  public plannedGroupName?: string;
  public planName?: string;
  public trainingLevelName?: string;
  public standardName?: string;
  public programName?: string;
  public studyFormName?: string;
  public courseNum!: number;
  public yearBegin!: number;
  public studyYear?: number;
  public dateBegin!: Date;
  public dateEnd?: Date;
  public facultyId!: Guid;
  public planId?: Guid;
  public studentNumbers!: number;
  public admissionYear!: number;
  public closed?: boolean;
  public closedNewYear?: boolean;
  public planned?: boolean;
  public totalStudentAdmissionBenchmarks?: number;
  public studentsPlanCount?: number;
}

export class AcademicGroupFilters {
  public yearBegin?: number;
  public facultyId?: Guid;
  public planned?: boolean
}

export class EduGroupCont {
  public groupName?: string;
  public groupExternalId?: Guid;
  public courseNum!: number;
  public facultyId!: Guid;
  public facultyName?: string;
  public educationProgramId!: Guid;
  public educationProgramName?: string;
  public educationStandardId!: Guid;
  public educationStandardName?: string;
  public studyForm?: string;
  public studyLevel?: string;
  public trainingLevel?: string;
  public profilingCourseNumber?: number;
}

export class EduGroupua {
  //public eduGroupId!: number;
  public eduGroupExternalId?: Guid | string;
  public groupName?: string;
  public facultyId!: Guid;
}

export class GroupChangeHistory {
  public fio?: string;
  public dateTime?: Date;
  public activityName?: string
  public activityType?: number
}

export class EduGroupPrecreatedDto {
  public groupName? : string;
  public admissionYear? : number;
  public planId? : string;
  public facultyId? : string;
}

export class AcademicGroupQuery {
  public year? : number;
  public facultyId? : string;
  public filialIds? : any[];
  public status? : boolean;
}

export class EduGroupWithAllStudyPeriodDto {
  public yearLengthApprenticeship? : number;
  public monthLengthApprenticeship? : number;
  public profilisation? : number;
  public semesters? : EduGroupSemestersDto[];
}

export class EduGroupSemestersDto {
  public groupName? : string;
  public senesterNum? : number;
  public courseNum? : number;
  public semesterStart? : Date;
  public semesterEnd? : Date;
}

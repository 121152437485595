import { Component, OnInit } from '@angular/core';
import {FileRestrictions} from "@progress/kendo-angular-upload";
import {ActivatedRoute} from "@angular/router";
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {ScanFile} from "../../../models/contingent/portfolio.model";
import {saveAs} from "@progress/kendo-file-saver";
import {DocumentModel} from "../../../models/contingent/document.model";
import {DocumentService} from "../../../services/contingent/document.service";
import {DictDocumentNames} from "../../../models/contingent/dictdocumentnames.model";
import {DictdocumentnamesService} from "../../../services/contingent/dictdocumentnames.service";
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {environment, getData$} from "../../../../environments/environment";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {StudentService} from "../../../services/contingent/student.service";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public studentId!: any;
  public loading = false;
  public editMode = false;
  public fileUploaded = false;
  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: environment.contingent.maxFileSize,
    allowedExtensions: [".pdf"],
  };
  public maxFileSizeMessage: string = `Размер файла должен быть не более ${Math.round(environment.contingent.maxFileSize/1024/1024)} МБ`;
  public uploads?: Array<any>;
  public opened = false;
  public isNew = false;
  public fileName!: string;

  constructor(private documentService: DocumentService,
              private activateRoute: ActivatedRoute,
              private dictDocumentNamesService: DictdocumentnamesService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private studService:StudentService,)
  {
    if(activateRoute.snapshot.parent!==null)
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
  }

  ngOnInit(): void {
    this.getStudent()
    this.getAllDocumentNames()
    this.getDocuments();
    getData$.subscribe(() => { this.getDocuments();})
  }

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    documentId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    documentNameId: new UntypedFormControl(),
    scanFileId: new UntypedFormControl(),
    scanFile: new UntypedFormGroup({
      file: new UntypedFormControl(),
      fileName: new UntypedFormControl()
    })
  });

  public scanFiles!: ScanFile[];
  public scanFile: ScanFile = {
    fileName: ""
  }
  public document: DocumentModel = {
    documentId: "",
    studentId: "",
    documentNameId: "",
    scanFile: this.scanFiles
  }
  public documents: DocumentModel[] = []
  public dictDocumentNames: DictDocumentNames[] = [];


  public getDocuments() {
    this.loading = true;
    this.documentService.getStudentDocuments(this.studentId)
      .subscribe(
        response => {
          this.documents = response;
          this.loading = false;
        }
      )
  }

  public getAllDocumentNames() {
    this.dictDocumentNamesService.getAllDocumentNames()
      .subscribe(
        response => {
          this.dictDocumentNames = response;
        }
      );
  }

  //Переключение на форму редактирования
  public documentAddForm(){
    this.editMode = true;
    this.isNew = true
  }

  onCancel() {
    this.editMode = false
    this.editForm.reset()
    this.uploads = undefined
    this.fileRemoved()
  }

  //Действия в форме редактирования
  public fileSelected() {
    this.fileUploaded = true;
  }

  public fileRemoved() {
    this.fileUploaded = false;
  }

  public editHandler(document: any): void {
    if(!this.editable) return
    this.editForm = new FormGroup({
      documentId: new FormControl(document.dataItem.documentId),
      studentId: new FormControl(document.dataItem.studentId),
      documentNameId: new FormControl(document.dataItem.documentNameId),
      scanFileId: new FormControl(document.dataItem.scanFileId),
      scanFile: new FormControl(document.dataItem.scanFile)
    });
    this.fileName = document.dataItem.scanFile.fileName
    this.isNew = false
    this.editMode = true;
    this.fileSelected()
  }

  // Сохранить заявку
  public saveDocument() {
    let formData = new FormData();
    formData.append(`StudentId`, this.studentId)
    formData.append(`DocumentNameId`, this.editForm.value.documentNameId)
    if (this.uploads) {
      formData.append(`ScanFile.FileName`, this.uploads[0].name);
      formData.append(`ScanFile.File`, this.uploads[0], this.uploads[0].name);
    }
    if(this.isNew)
      this.documentService.addDocument(formData)
        .subscribe({
          next:() => {
            this.getDocuments();
            this.onCancel()
            this.notificationService.showSuccess("Добавлено");
          },
          error:() => {
            this.notificationService.showError('Не удалось добавить документ');
          }}
        );
    else
      this.documentService.updateDocument(formData,this.editForm.value.documentId)
        .subscribe({
          next:() => {
            this.getDocuments();
            this.onCancel()
            this.notificationService.showSuccess("Сохранено");
          },
          error:() => {
            this.notificationService.showError('Не удалось сохранить изменения');
          }}
        );
  }

  public downloadFile(values: any) {
    this.documentService.downloadFile(`${values.documentId}`, this.studentId).subscribe(
      response => {
        let blob:any = new Blob([response], { type: `application/pdf; charset=utf-8` });
        saveAs(blob, values.scanFile.fileName);
      }
    );
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.name}`);
    this.opened = dialogResultHelper(dialog.result, this.documentService.deleteDocument(dataItem.documentId, this.studentId), this.notificationService);
  }

  public editable?: boolean

  public getStudent(): void {
    this.studService.getByIdstudent(this.studentId!)
      .subscribe(
        response => {
          if(response!=null){
            this.editable = response.documentEditable
          }
        }
      );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../models/useraccess/role";
import {MiddleControlUserAccessService} from "../services/useraccess/middle-control-user-access.service";
import {map} from "rxjs/operators";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SessionUserAccessGuard implements CanActivate {

  constructor(private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private userAccessService: MiddleControlUserAccessService,
              private location: Location,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const token = this.tokenStore.getAuthToken()
      // Checking if token is not null
      if(token !== null) {
          const user = this.jwtHelper.decodeToken(token!);
          const roles = user?.role;
          // Admin accesses page without any restrictions
          if (roles?.includes(Role.Admin))
              return true;
          else {
              return this.userAccessService.getUserAccessLevel().pipe(
                  map( response => {
                      if (response)
                          return true;
                      alert('Недостаточно прав для доступа на эту страницу');
                      localStorage.setItem('last_url', this.location.path());
                      this.router.navigate([`/profile`]);
                      return false;
                  })
              );
          }
      }
      localStorage.setItem('last_url', this.location.path());
      return false;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";
import { DisciplineType } from 'src/app/models/dicts/disciplinetype.model';

@Injectable({
    providedIn: 'root'
  })
export class DisciplineTypeService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.disciplinetype}`;

  constructor(private http: HttpClient) { }

  //Get all DisciplineType
  public getAllDisciplineType(): Observable<DisciplineType[]> {
    return this.http.get<DisciplineType[]>(this.baseUrl);
  }

  //Add DisciplineType
  addDisciplineType(disciplineType: DisciplineType): Observable<DisciplineType> {
    return this.http.post<DisciplineType>(this.baseUrl, disciplineType)
      .pipe(catchError(this.handleError));
  }

  //Delete DisciplineType
  deleteDisciplineType(id: string): Observable<DisciplineType> {
    return this.http.delete<DisciplineType>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit DisciplineType
  updateDisciplineType(disciplineType: DisciplineType): Observable<DisciplineType> {
    return this.http.put<DisciplineType>(this.baseUrl + '/' + disciplineType.dictDisciplineTypeExternalId, disciplineType)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  CellClickEvent,
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  PagerSettings,
  RowClassArgs
} from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { GiaUserAccessService } from '../../../../services/useraccess/gia-user-access.service';
import { GiaTabsEnum } from '../../../../models/gia/enums/giaTabs.enum';
import { dictPagerSettings } from '../../../../models/dicts/pagerSettings.model';
import { EducationService } from '../../../../services/gia/education.service';
import { TrainingLevelEnum } from '../../../../models/gia/enums/training-level.enum';
import { ReleaseOrderService } from '../../../../services/gia/release-order.service';
import { ReleaseOrderList, ReleaseOrderStudentList } from '../../../../models/gia/orders/release-order.model';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { DownloadFile } from '../../../../helpers/downloadFile-helper';
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";

@Component({
  selector: 'app-release-order-table',
  templateUrl: './release-order-table.component.html',
  styleUrls: ['./release-order-table.component.scss'],
})
export class ReleaseOrderTableComponent implements OnInit {
  public standardId: string = '';

  public students: ReleaseOrderStudentList[] = [];
  public editable: boolean = false;

  public loading: boolean = false;
  public opened = false;

  public allItemsChecked: boolean = false;
  public arrayStudents: string[] = [];

  public pagerSettings: PagerSettings = dictPagerSettings;
  public pageSize = 20;
  public skip = 0;

  public standardName: string = '';
  public trainingLevel?: number;
  public graduateYear: number = 0;
  public studyFormId: string = '';
  public facultyId: string = '';
  public releaseOrderSettingString: string = 'release_order_grid_settings';

  public orderId: string = '';
  public releaseOrders: ReleaseOrderList[] = [];

  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  public gridView!: GridDataResult;

  @ViewChild(GridComponent) private grid!: GridComponent;

  constructor(
    private activateRoute: ActivatedRoute,
    private releaseOrderService: ReleaseOrderService,
    private userAccessService: GiaUserAccessService,
    private notificationService: NotificationsService,
    private educationService: EducationService,
    private router: Router,
  ) {
    this.activateRoute.params.subscribe((params) => {
      this.standardId = params['standardId'];
      this.graduateYear = params['graduateYear'];
      this.studyFormId = params['studyFormId'];
      this.facultyId = params['facultyId'];
      const storage = localStorage.getItem('diplomas_settings');
      if (storage !== null) {
        this.standardName = JSON.parse(storage).changeGroup;
      }
      this.getOrderStudents(this.standardId);
    });
  }

  async ngOnInit() {
    await this.getAccessLevel();
    this.getStandardInfo();
    this.getReleaseOrders();
  }

  public getReleaseOrders() {
    this.releaseOrderService.getOrderList(this.standardId, this.facultyId, this.graduateYear).subscribe((response) => {
      this.releaseOrders = response;
    });
  }

  public getOrderStudents(standardId: string) {
    this.releaseOrderService.getStudents(standardId, this.graduateYear, this.studyFormId, this.facultyId).subscribe((response) => {
      this.students = response;
      this.getSettings();
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.sort = sort;
    this.loadItems();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.skip = 0;
    this.filter = filter;
    this.loadItems();
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.loadItems();
  }

  public loadItems() {
    this.saveSettings();
    this.gridView = {
      data: orderBy(filterBy(this.students.slice(0),this.filter),this.sort).slice(this.skip, this.skip + this.pageSize),
      total: orderBy(filterBy(this.students.slice(0),this.filter),this.sort).slice().length//this.filteredAcademicGroups.length
    };
  }

  public getStandardInfo() {
    this.educationService.getStandardInfo(this.standardId).subscribe((response) => {
      if (response !== null) {
        this.standardName = response.standardName;
        this.trainingLevel = response.trainingLevelEnum;
      }
    });
  }

  public async getAccessLevel() {
    this.editable = await this.userAccessService.hasEditingGia({
      educationStandardId: this.standardId,
      dictStudyFormId: this.studyFormId,
      facultyId: this.facultyId,
      section: GiaTabsEnum.приказы_о_выпуске,
    });
  }

  public selectAll(checked: boolean) {
    this.allItemsChecked = !this.allItemsChecked;
    this.allItemsChecked ? (this.arrayStudents = this.students.filter(s => !s.releaseOrderId).map((el) => el.studentId)) : (this.arrayStudents = []);
  }

  public checked(studentId: string) {
    return this.arrayStudents.includes(studentId);
  }

  public checkedChange(studentId: string) {
    this.getArraysForMassChange(studentId, this.arrayStudents);
    if (this.arrayStudents.length < this.students.filter(s => !s.releaseOrderId).length) this.allItemsChecked = false;
    if (this.arrayStudents.length == this.students.filter(s => !s.releaseOrderId).length) this.allItemsChecked = true;
  }

  private getArraysForMassChange(studentId: string, array: string[]) {
    const index = array.indexOf(studentId);
    index == -1 ? array.push(studentId) : array.splice(index, 1);
  }

  public cellClickHandler({ dataItem }: CellClickEvent): void {
    if (dataItem.releaseOrderId) this.openForm(dataItem.releaseOrderId);

    if (!dataItem.releaseOrderId && this.editable) this.checkedChange(dataItem.studentId);
  }

  public openForm(orderId?: string) {
    this.saveSettings();

    this.router.navigate([`gia/releaseorderform` + (orderId ? `/${orderId}` : '')], {
      state: { graduateYear: this.graduateYear, standard: this.standardId, students: this.students.filter((s) => this.arrayStudents.includes(s.studentId)) },
    });
  }

  public openDialog() {
    this.opened = true;
  }

  public closeDialog(): void {
    this.opened = false;
  }

  public rowCallback(context: RowClassArgs) {
    return context.dataItem.releaseOrderId ? { green: true } : context.dataItem.isBadProtocolResult ? { gray: true } : { green: false };
  }

  public printOrder(orderId: string) {
    this.releaseOrderService.printOrder(orderId).subscribe({
      next: (value) => {
        DownloadFile(value);
      },
      error: (error) => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }

  protected readonly TrainingLevelEnum = TrainingLevelEnum;

  private saveSettings() {
    let admissionOrderSaves = {
      sort: this.sort,
      skip: this.skip,
      pageSize: this.pageSize,
      filter: this.filter,
    }
    localStorage.setItem(this.releaseOrderSettingString, JSON.stringify(admissionOrderSaves));
  }

  private getSettings() {
    const release_order_settings = localStorage.getItem(this.releaseOrderSettingString);
    if (release_order_settings !== null) {
      let storage = JSON.parse(release_order_settings)
      this.skip = storage.skip
      this.sort = storage.sort
      this.pageSize = storage.pageSize
      this.filter = storage.filter
    }
    this.loadItems();
  }

  public addToOrder(): void {
    const order = {
      releaseOrderId: this.orderId,
      students: this.arrayStudents.map(function (v) {
        return { studentId: v };
      }),
    };

    this.releaseOrderService.addOrderStudents(order).subscribe({
      next: () => {
        this.notificationService.showSuccess('Сохранено');
        this.getOrderStudents(this.standardId);
        this.arrayStudents = [];
      },
      error: (error) => {
        this.notificationService.showError(error.error);
      },
    });

    this.opened = false;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ParametersAuditorium } from 'src/app/models/classroom/parameters-auditorium.model';
import { catchError, Observable, throwError, BehaviorSubject } from 'rxjs';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

export class ParametersAuditoriumService {
    
    baseUrl = urlJoin(<string>environment.classroomApiEndpoint, environment.apiPaths.classroom.base);

    constructor(private http: HttpClient) { }

    public getParameters(id: number){
        return this.http.get<ParametersAuditorium>(this.baseUrl + '/id/' + id);
    }

    public addClass(body: any){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Credentials','true');
        return this.http.post(this.baseUrl, body, { headers: headers })
        .pipe(catchError(this.handleError));
    }

    public deleteClass(id: string){
        return this.http.delete(this.baseUrl + '/id/' + id,  {responseType: 'text' as 'text'})
    }

    public editClass(body: any, id: string){
        return this.http.put(this.baseUrl + '/id/' + id, body)
    }

    public transferLevel = new BehaviorSubject<any>({
        level: 0,
    })

     // Error
     handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
        // Handle client error
        errorMessage = error.error.message;
        } else {
        // Handle server error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
        errorMessage;
        });
    }
}
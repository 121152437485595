import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Addresses } from '../../models/contingent/addresses.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.addresses}`;
  url = `${environment.apiEndpoint}${environment.apiPaths.externalAddressSearch}`;

  constructor(private http: HttpClient) { }

  //Get all addresses
  public getAlladdresses(): Observable<Addresses[]> {
    return this.http.get<Addresses[]>(this.baseUrl);
  }

  //Get By Id addresses
  getByIdaddresses(id: string): Observable<Addresses> {
    return this.http.get<Addresses>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId
  getStudentAddresses(id: string): Observable<Addresses[]> {
    return this.http.get<Addresses[]>(this.baseUrl + '/getaddressesbystudpersonid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getRegAddress(id: string): Observable<Addresses> {
    return this.http.get<Addresses>(this.baseUrl + '/GetStudPersonRegisteredAddress' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add addresses
  addaddresses(addresses: Addresses, studentId: string): Observable<Addresses> {
    return this.http.post<Addresses>(this.baseUrl + "/" + studentId, addresses)
      .pipe(catchError(this.handleError));
  }

  //Delete addresses
  deleteaddresses(id: string, studentId : Guid|undefined): Observable<Addresses> {
    return this.http.delete<Addresses>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit addresses
  updateaddresses(addresses: Addresses, studentId: string): Observable<Addresses> {
    return this.http.put<Addresses>(this.baseUrl + '/' + addresses.addressExternalId + "/" + studentId, addresses)
      .pipe(catchError(this.handleError));
  }

  //Get Address Hint
  public GetAddressHint(searchString: string): any {
    return this.http.get(this.url + "/GetAddressHint?searchString=" + searchString);
  }

  //Get Address by address string
  public GetAddressItemById(objectId: string): Observable<Addresses> {
    return this.http.get<Addresses>(this.url + "/GetAddressItemById?objectId=" + objectId);
  }

   //Get Address by Id
   public GetAddressItemByGuid(objectGuid: string): Observable<Addresses> {
    return this.http.get<Addresses>(this.url + "/GetAddressItemByGuid?objectGuid=" + objectGuid);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {DictCertificatePurpose} from "../../models/contingent/dictcertificatepurpose.model";

@Injectable({
  providedIn: 'root'
})
export class DictcertificatepurposeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictcertificatepurpose}`;

  constructor(private http: HttpClient) { }

  public getAll(): Observable<DictCertificatePurpose[]> {
    return this.http.get<DictCertificatePurpose[]>(this.baseUrl + '/GetDictCertificatePurpose');
  }

  add(dto: DictCertificatePurpose): Observable<DictCertificatePurpose> {
    return this.http.post<DictCertificatePurpose>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  delete(id: string): Observable<DictCertificatePurpose> {
    return this.http.delete<DictCertificatePurpose>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  update(dto: DictCertificatePurpose): Observable<DictCertificatePurpose> {
    return this.http.put<DictCertificatePurpose>(this.baseUrl + '/' + dto.dictCertificatePurposeId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

<h5 class="order-title">Приказы и распоряжения</h5>

<form novalidate ="ngForm" class="ngForm">
<!--
  <div>
    <form class="k-form-order" [formGroup]="formStudyPeriod" *ngIf="!editMode">
      <dl class="aboutorders">
        <div class="label-sticky-top" *ngIf="editable">
          <kendo-label text="Период обучения:"></kendo-label>
        </div>
        <dd class="ddinline" *ngIf="!editable">
          <kendo-label text="Период обучения: " style="margin-right: 5px"></kendo-label>
          <kendo-label> c {{orderStudent.dateStart==orderStudent.dateFinish? "-":orderStudent.dateStart| kendoDate: "dd.MM.yyyy"}}
            по {{orderStudent.dateStart==orderStudent.dateFinish? "-":orderStudent.dateFinish| kendoDate: "dd.MM.yyyy"}}
          </kendo-label>
        </dd>
        <dd class="ddinline" *ngIf="editable">
          <kendo-formfield style="margin-right:10px">
            <kendo-datepicker
              format="dd.MM.yyyy"
              style="width: 220px;"
              #dateStart
              [openOnFocus]="dateStart"
              (focus)="onFocus()"
              [disabled]="studentmaininfoComponent.deleted"
              placeholder="с"
              formControlName="dateStart"
              required>
            </kendo-datepicker>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield style="margin-right:10px">
            <kendo-datepicker
              format="dd.MM.yyyy"
              style="width: 220px;"
              #dateFinish
              [disabled]="studentmaininfoComponent.deleted"
              [openOnFocus]="dateFinish"
              (focus)="onFocus()"
              placeholder="по"
              formControlName="dateFinish">
            </kendo-datepicker>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
          <div>
            <button kendoButton
                    size="small"
                    *ngIf="editable"
                    [disabled]="formStudyPeriod!.invalid||studentmaininfoComponent.deleted"
                    themeColor="primary"
                    (click)="onSaveStudyPeriod($event)"
                    style="margin-bottom: 5px">
              Сохранить
            </button>
          </div>
        </dd>
      </dl>
    </form>
  </div>
-->

  <kendo-grid [kendoGridBinding]="orders"
              *ngIf="!editMode; else editForm"
              #grid="kendoGrid"
              (remove)="removeHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (add)="addHandler($event)"
              (edit)="transitionHandler($event)"
              (dataStateChange)="onStateChange($event)"
              (sortChange)="sortChange($event)"
              [loading]="loading">

    <ng-template kendoGridToolbarTemplate>
      <button kendoGridAddCommand
              [disabled]="studentMainInfoComponent.deleted"
              *ngIf="editable"
              size="small"
              themeColor="success"
              icon="plus">
        Добавить приказ/распоряжение
      </button>
      <kendo-grid-spacer></kendo-grid-spacer>
      <button kendoButton
              icon="print"
              size="small"
              (click)="exportToPDF(grid)">
        Печать
      </button>
    </ng-template>

    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column field="index"
                       headerClass="gridHeader dictionaries"
                       title="№ п\п"
                       editor ="numeric"
                       [width]="90">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{ dataItem.index }}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="number"
                       headerClass="gridHeader dictionaries"
                       title="Номер"
                       [width]="130">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{ dataItem.number }}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="orderDate"
                       headerClass="gridHeader dictionaries"
                       title="Дата"
                       [width]="140">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{(dataItem?.orderDate == null ? "" : dataItem.orderDate| date: "dd.MM.yyyy")}}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dictOrdersCategories.dictOrderTypeId"
                       headerClass="gridHeader dictionaries"
                       title="Тип"
                       [width]="160">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{ nameOrderTypes(dataItem.dictOrdersCategories.dictOrderTypeId) }}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dictOrdersCategories.categoryName"
                       headerClass="gridHeader dictionaries"
                       title="Категория"
                       kendoGridFocusable
                       [width]="300">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
      {{ dataItem.dictOrdersCategories.categoryName }}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dateBegin"
                       headerClass="gridHeader dictionaries"
                       title="Срок действия"
                       [width]="200">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
      <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
      <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
    </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{(dataItem?.dateBegin == null ? "" : "с " +  (dataItem.dateBegin| date: "dd.MM.yyyy"))}}
        {{(dataItem?.dateEnd == null ? "" :  " по " + (dataItem.dateEnd| date: "dd.MM.yyyy"))}}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="orderSed" *ngIf="!isHidden(enum.sed)"
                       headerClass="gridHeader dictionaries"
                       title="СЭД"
                       [width]="200">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span style="cursor: pointer" class="alignCells" (click)="openSed(dataItem.orderSed)">
         <h2 style="text-decoration: underline; color: #42A7DD">{{dataItem.orderSed?"Открыть":""}}</h2>
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="comment"
                       headerClass="gridHeader dictionaries"
                       title="Примечание"
                       [width]="180">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{ dataItem.comment }}
      </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastEdit"
                       headerClass="gridHeader dictionaries"
                       title="Последнее изменение"
                       [width]="170">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{(dataItem?.lastEdit == null ? "" : dataItem.lastEdit| date: "dd.MM.yy HH:mm")}}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="fio"
                       headerClass="gridHeader dictionaries"
                       title="Пользователь"
                       [width]="190">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
        <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{(dataItem.fio)}}
      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dictOrdersCategories.categoryName" *ngIf="!isHidden(enum.order_file)"
      headerClass="gridHeader dictionaries"
      title="Файл приказа"
      kendoGridFocusable
      [width]="300">
      <ng-template kendoGridHeaderTemplate let-column>
        {{ column.title }}
          <span *ngIf="sort[0].field === column.field">
            <span *ngIf="sort[0].dir === 'asc'" class="k-icon k-i-sort-asc-sm"></span>
            <span *ngIf="sort[0].dir === 'desc'" class="k-icon k-i-sort-desc-sm"></span>
        </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="k-display-flex k-justify-content-center">
          <span class="k-icon k-i-file pointer"
            *ngIf="dataItem.fileFullName"
            (click)="getOrderFile(dataItem.ordersExternalId)">
          </span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column
      *ngIf="!studentMainInfoComponent.deleted"
      title="" [width]="120">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand type="button"
                icon="edit"
                *ngIf="editable"
                fillMode="flat"
                rounded="full"
                size="small"
                style="margin-right: -10px">
        </button>
        <button kendoGridRemoveCommand
                icon="delete"
                *ngIf="editable"
                fillMode="flat"
                size="small"
                rounded="full">
        </button>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-pdf
      fileName="orders.pdf"
      paperSize="A4"
      [scale]="0.5"
      [repeatHeaders]="true"
      [autoPrint]=true>
      <kendo-grid-pdf-margin
        top="3cm"
        left="1cm"
        right="1cm"
        bottom="2cm"
      ></kendo-grid-pdf-margin>
      <ng-template kendoGridPDFTemplate>
        <div class="page-template">
          <div class="header">
            ФИО: {{orderStudent.fio}}<br>
            Дата рождения: {{orderStudent.birthday| date: "dd.MM.yyyy":'GMT'}}<br>
            Группа: {{orderStudent.groupName}}<br><br>
            Период обучения: c {{orderStudent.dateStart==orderStudent.dateFinish? " ":orderStudent.dateStart| date: "dd.MM.yyyy":'GMT'}}
            по {{orderStudent.dateStart==orderStudent.dateFinish? " ":orderStudent.dateFinish| date: "dd.MM.yyyy":'GMT'}}<br>
          </div>
        </div>
      </ng-template>
      <kendo-grid-column field="ordersId" title= "№ п/п" >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ index(dataItem.ordersId) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="number" [width]="120" title="Номер"></kendo-grid-column>
      <kendo-grid-column field="orderDate" [width]="120"
                         title="Дата">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{(dataItem?.orderDate == null ? "" : dataItem.orderDate| date: "dd.MM.yyyy":'GMT')}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictOrdersCategories.dictOrderTypeId" [width]="130" title="Тип">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ nameOrderTypes(dataItem.dictOrdersCategories.dictOrderTypeId) }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictOrdersCategories.categoryName" title="Категория" [width]="300"></kendo-grid-column>
      <kendo-grid-column field="orderSed" title="СЭД" [width]="120" *ngIf="!isHidden(enum.sed)"></kendo-grid-column>
      <kendo-grid-column field="comment" title="Примечание" [width]="150"></kendo-grid-column>
      <kendo-grid-column field="lastEdit" title="Последнее изменение" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{(dataItem?.lastEdit == null ? "" : dataItem.lastEdit| date: "dd.MM.yy HH:mm")}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-pdf>
  </kendo-grid>
  <ng-template #editForm >
    <form class="k-form-order" [formGroup]="form">
      <dl class="aboutorders">
        <kendo-label [for]="orderDate" text="Дата"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              class="input-orders"
              (focus)="onFocus()"
              [openOnFocus]="orderDate"
              #orderDate
              formControlName="orderDate"
              required>
            </kendo-datepicker>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
        <kendo-label [for]="number" text="Номер"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="number"
              class="input-orders"
              size="small"
              [clearButton]="true"
              placeholder="Номер"
              #number
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
        <kendo-label [for]="dictOrderTypeId" text="Тип"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-combobox [data]="visibleOrderTypes"
                            textField="typeName"
                            valueField="dictOrderTypeExternalId"
                            #dictOrderTypeId
                            [valuePrimitive]="true"
                            placeholder="Тип"
                            (selectionChange)="onTypeChange($event)"
                            [kendoDropDownFilter]="filterSettings"
                            class="input-orders"
                            [openOnFocus]="dictOrderTypeId"
                            formControlName="dictOrderTypeId"
                            [formControl]="$any(form).controls['dictOrderTypeId']"
                            required>
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
        <kendo-label [for]="dictOrdersCategoryId" text="Категория"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-combobox [data]="selectOrderCategories"
                            textField="categoryName"
                            valueField="dictOrdersCategoryExternalId"
                            #dictOrdersCategoryId
                            [valuePrimitive]="true"
                            placeholder="Категория"
                            [kendoDropDownFilter]="filterSettings"
                            class="input-orders"
                            [openOnFocus]="dictOrdersCategoryId"
                            formControlName="dictOrdersCategoryId"
                            [formControl]="$any(form).controls['dictOrdersCategoryId']"
                            required>
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
        <kendo-label [for]="dateBegin" text="Срок действия"></kendo-label>
        <dd class="ddinline">
          <kendo-label class="order-from-to" text="с"></kendo-label>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              style="margin-right:40px; width: 202px;"
              #dateBegin
              [openOnFocus]="dateBegin"
              placeholder="с"
              (focus)="onFocus()"
              formControlName="dateBegin">
            </kendo-datepicker>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
          <kendo-label class="order-from-to" text="по"></kendo-label>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              style="width: 202px;"
              #dateEnd
              [openOnFocus]="dateEnd"
              (focus)="onFocus()"
              placeholder="по"
              formControlName="dateEnd">
            </kendo-datepicker>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
          <kendo-label text="СЭД" *ngIf="!isHidden(enum.sed)"></kendo-label>
          <dd *ngIf="!isHidden(enum.sed)">
            <kendo-formfield>
              <kendo-textbox
                formControlName="orderSed"
                class="input-orders"
                [clearButton]="true"
                placeholder="{{orderSedPlaceholder}}"
                #orderSed
              ></kendo-textbox>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
        <kendo-label [for]="comment" text="Примечание"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="comment"
              class="input-orders"
              [clearButton]="true"
              placeholder="Примечание"
              #comment
            ></kendo-textbox>
          </kendo-formfield>
        </dd>
        <kendo-label text="Файл" *ngIf="!isHidden(enum.order_file)"></kendo-label>
        <dd *ngIf="!isHidden(enum.order_file)">
          <kendo-formfield>
              <div>
                <kendo-fileselect 
                class="input-files"
                    [restrictions]="restrictions"
                    [multiple]="false"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="files">
                  <kendo-fileselect-messages
                    invalidFileExtension="Недопустимый формат файла.">
                  </kendo-fileselect-messages>
                </kendo-fileselect>
              </div>
              <span class="uploadedFile"
                *ngIf='order.fileFullName && (!files || files.length==0)'
                (click)="getOrderFile(order.ordersExternalId)">
                <span class="k-icon k-i-file"></span>
                <h2 class="uploadedFile__name">{{order.fileFullName}}</h2>
              </span>
          </kendo-formfield>
          <br>
        </dd>
      </dl>
    </form>
    <div class="editModeButtons">
      <button kendoButton
              size="small"
              *ngIf="editMode"
              (click)="close()">
        Отмена
      </button>
      <button kendoButton
              size="small"
              themeColor="primary"
              *ngIf="editMode"
              [disabled]="!form.valid"
              (click)="onSave($event)">
        Сохранить
      </button>
    </div>
  </ng-template>
</form>
<div kendoDialogContainer></div>

import {Component, OnInit} from '@angular/core';
import {pagerSettings} from "../../../../models/mfc/pagerSettings.model";
import {
  CellClickEvent,
  ColumnComponent,
  GridDataResult,
  PageChangeEvent,
  PagerSettings, RowClassArgs
} from "@progress/kendo-angular-grid";
import {GroupDescriptor, process, SortDescriptor, State} from '@progress/kendo-data-query';
import {TemplateService} from "../../../../services/mfc/template.service";
import {TemplateFilters, TemplateList} from '../../../../models/mfc/templates/template.model';
import {Router} from "@angular/router";
import {ApplicationTypeService} from '../../../../services/mfc/dicts/application-type.service';
import {TrainingLevelService} from '../../../../services/mfc/dicts/training-level.service';
import {ApplicationCategoryService} from '../../../../services/mfc/dicts/application-category.service';
import {ApplicationCategory} from '../../../../models/mfc/dicts/application-category.model';
import {ApplicationType} from '../../../../models/mfc/dicts/application-type.model';
import {TrainingLevel} from '../../../../models/mfc/dicts/training-level.model';
import {FilialTreeItem} from "../../../../models/mfc/dicts/filial.model";
import {FacultyService} from "../../../../services/mfc/dicts/faculty.service";
import {
  constructorStatuses
} from '../../../../models/mfc/applicationConstructor/application-constructor-statuses.model';
import {
  NumericApplicationConstructorStatusesEnum
} from '../../../../models/mfc/enums/application-constructor-statuses.enum';
import {Dict} from '../../../../models/mfc/dict.model';
import {
  ApplicationConstructorFiltersEnum,
  applicationConstructorFiltersMap
} from '../../../../models/mfc/enums/application-constructor-filters.enum';


@Component({
  selector: 'mfc-templates-home',
  templateUrl: './templates-home.component.html',
  styleUrls: ['./templates-home.component.scss']
})
export class TemplatesHomeComponent implements OnInit {

  protected loading = false;
  protected pagerSettings: PagerSettings = pagerSettings;
  protected skip = 0;
  protected pageSize = 10;
  protected groups: GroupDescriptor[] = [{field: 'dictApplicationCategory.name'}];
  protected state: State = {
    skip: 0,
    take: this.pageSize,
    group: this.groups
  };
  protected sort: SortDescriptor[] = [];

  protected gridView!: GridDataResult;
  protected gridData: TemplateList[] = [];
  protected templates: TemplateList[] = [];
  protected filters: TemplateFilters = {
    trainingLevels: [],
    faculties: [],
    dictApplicationType: [],
    status: [NumericApplicationConstructorStatusesEnum.Published],
  };
  protected templateFormNameFilter = '';

  protected dictApplicationCategories: ApplicationCategory[] = [];
  protected dictApplicationTypes: ApplicationType[] = [];
  protected dictTrainingLevels: TrainingLevel[] = [];
  protected filialTree: FilialTreeItem[] = [];

  protected readonly constructorStatuses = constructorStatuses;

  constructor(
    private router: Router,
    private templateService: TemplateService,
    private applicationCategoryService: ApplicationCategoryService,
    private applicationTypeService: ApplicationTypeService,
    private trainingLevelService: TrainingLevelService,
    private facultyService: FacultyService
  ) { }

  ngOnInit(): void {
    this.getDicts();
    this.getTemplates();
  }

  private getDicts() {
    this.applicationCategoryService.getApplicationCategories().subscribe((response) =>
      this.dictApplicationCategories = response ?? []);
    this.applicationTypeService.getApplicationTypes().subscribe((response) =>
      this.dictApplicationTypes = response ?? []);
    this.trainingLevelService.getTrainingLevels().subscribe((response) => {
      this.dictTrainingLevels = response ?? [];
      this.addAllValue(this.dictTrainingLevels);
    });
    this.facultyService.getFilialsAndFaculties().subscribe((response) => {
      this.filialTree = response ?? [];
      this.addAllValue(this.filialTree);
    });
  }

  private addAllValue(dict: Dict[]) {
    dict.unshift({id: '', name: 'Все'});
  }

  private getTemplates() {
    this.loading = true;
    this.templateService.getTemplates().subscribe((response) => {
      this.templates = response;
      this.filterData();
      this.loading = false;
    });
  }

  protected pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.state = {
      ...this.state,
      skip: event.skip,
      take: event.take,
    };

    this.gridView = process(this.gridData, this.state);
  }

  protected sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.state = { ...this.state, sort};
    this.gridView = process(this.gridData, this.state);
  }

  protected filterData() {
    this.skip = 0;
    this.state = {
      ...this.state,
      skip: this.skip,
      take: this.pageSize
    };

    this.gridData = this.templates.filter(a => {
      return this.filterByConstructorFilters(ApplicationConstructorFiltersEnum.isAllFacultyAvailable, a)
        && this.filterByConstructorFilters(ApplicationConstructorFiltersEnum.isAllTrainingLevelAvailable, a)
        && (this.filters.dictApplicationType.length
          ? this.filters.dictApplicationType.includes(a.dictApplicationType.id) : a.dictApplicationType.id)
        && (this.templateFormNameFilter
          ? a.name.toLowerCase().includes(this.templateFormNameFilter.toLowerCase()) : a.name)
        && (this.filters.status.length ? this.filters.status.includes(a.applicationConstructorStatus) : a.applicationConstructorStatus)
    });
    this.gridView = process(this.gridData, this.state);
  }

  private filterByConstructorFilters(filter: ApplicationConstructorFiltersEnum, template: TemplateList) {
    const field = filter as keyof TemplateFilters;
    return this.filters[field].length
      ? (template[field] as Dict[]).some((item) => (this.filters[field] as string[]).includes(item.id))
      || template[applicationConstructorFiltersMap.get(filter) as keyof TemplateList]
      : template[field];
  }

  protected filterChange(value: string[] & NumericApplicationConstructorStatusesEnum[], {field}: ColumnComponent) {
    if (field === 'faculties') {
      value = this.filterParentIds(value) as string[] & NumericApplicationConstructorStatusesEnum[];
    }

    this.filters[field as keyof typeof this.filters] = value;
    this.filterData();
  }

  private filterParentIds(value: string[]) {
    return value.filter(item => !this.filialTree.some(treeItem => treeItem.id === item) || item === '');
  }

  protected rowCallback = (context: RowClassArgs) => {
    const dataItem: TemplateList = context.dataItem;
    return {
      "not-uploaded": !dataItem.templatesUploaded
    };
  }

  protected openEditMode({dataItem}: CellClickEvent) {
    this.router.navigate([`mfc/templates/templateForm/${dataItem.applicationConstructorId}`]);
  }
}

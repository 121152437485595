<education-header></education-header>
<div class="dictEducation__container">
    <h1 *ngIf="!isOpened">Укрупненные группы специальностей</h1>
    <div class="container">
        <button *ngIf="!isOpened" class="addButton" (click)="addFormNavigate()"  kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
        <kendo-grid
                *ngIf="!isOpened"
                [height]="600"
                [loading]="loading"
                [sortable]="true"
                [sort]="sort"
                [filterable]="true"
                (cellClick)="editFormNavigate($event)"
                [kendoGridBinding]="enlargedGroupSpecializations">
            <ng-template kendoGridLoadingTemplate>
                <div class="k-loading-color"></div>
                <div class="loading-container">
                    <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
                </div>
            </ng-template>
            <kendo-grid-column headerClass="gridHeader dictionaries" title="Шифр" field="cipher">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{dataItem.cipher}}
                </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column headerClass="gridHeader dictionaries" title="Название" field="name">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells leftAlignment">
                    {{dataItem.name}}
                </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column headerClass="gridHeader dictionaries" title="Тип стандарта" field="dictStandardTypeId">
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <filter-dropdown gridField="dictStandardTypeId" [filter]="filter" [data]="standardTypes" textField="standardTypeShortName"
                                     valueField="standardTypeExternalId">
                    </filter-dropdown>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{dataItem.dictStandardType.standardTypeShortName}}
                </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" *ngIf="editable">
                <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    <button kendoButton class="editButton" (click)="editFormNavigate(dataItem)">Изменить</button>
                </span>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>

    <div class="editForm" *ngIf="isOpened">
        <h1>Новая укрупненная группа специальностей</h1>
        <form novalidate class="k-form" [formGroup]="editForm">
            <kendo-formfield>
                <div class="formRow required">
                    <h2>Название</h2>
                    <kendo-textbox formControlName="name"></kendo-textbox>
                </div>
                <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
                <div class="formRow required">
                    <h2>Шифр</h2>
                    <kendo-textbox formControlName="cipher" required></kendo-textbox>
                </div>
                <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
                <div class="formRow required">
                    <h2>Тип стандарта</h2>
                    <kendo-dropdownlist
                            [data]="standardTypes"
                            textField="standardTypeShortName"
                            valueField="standardTypeExternalId"
                            [valuePrimitive]="true"
                            formControlName="dictStandardTypeId" required>
                    </kendo-dropdownlist>
                </div>
                <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
            <div class="buttons">
                <button kendoButton type="button" (click)="onCancel()">Отменить</button>
                <button kendoButton themeColor="primary" (click)="saveHandler()" [disabled]="!editForm.valid">Сохранить</button>
            </div>
        </form>
    </div>
</div>
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from "rxjs/operators";
import {MilitaryFormType} from '../../models/contingent/militaryformtypes.model';
import {handleError} from '../../helpers/errorHandle-helper';

@Injectable({
  providedIn: 'root'
})
export class MilitaryFormTypesService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.militaryformtypes}`;

  constructor(private http: HttpClient) { }

  public getMilitaryFormTypes(): Observable<MilitaryFormType[]> {
    return this.http.get<MilitaryFormType[]>(this.baseUrl);
  }

  public updateMilitaryFormType(militaryFormType: MilitaryFormType): Observable<MilitaryFormType> {
    const {externalId, formType, ...form} = militaryFormType;
    return this.http.put<MilitaryFormType>(`${this.baseUrl}/Update/${externalId}`, form)
      .pipe(catchError(handleError));
  }
}

export interface ApprovalSettingsForm {
  dictApprovalTypeId: string;
  dictRoleId?: string;
  applicationConstructorDocumentId?: string;
  dictSignatoryRoleId?: string;
  numberInApprovalSequence: number;
  numberToUseForReWork?: number;
  dictApprovalStatusId: string;
  departmentType?: number;
}

export class CreateApprovalSettingsForm {
  dictApprovalTypeId: string|null = null;
  dictRoleId?: string = undefined;
  applicationConstructorDocumentId?: string = undefined;
  dictSignatoryRoleId?: string = undefined;
  numberInApprovalSequence: number|null = null;
  numberToUseForReWork?: number = undefined;
  dictApprovalStatusId: string|null = null;
  departmentType?: number = undefined;
}

import {Component, OnInit, Renderer2} from '@angular/core';
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {MassOperationJournal} from "../../../../models/contingent/massoperationjournal.model";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {StudentService} from "../../../../services/contingent/student.service";
import {MassoperationjournalService} from "../../../../services/contingent/massoperationjournal.service";
import {PageSizeItem, RemoveEvent} from "@progress/kendo-angular-grid";
import {DateFromUTCAsLocal} from "../../../../helpers/date-helper";
import {DictmassoperationtypesService} from "../../../../services/contingent/dictmassoperationtypes.service";
import {DictMassOperationTypes} from "../../../../models/contingent/dictmassoperationtypes.model";
import {Faculty} from "../../../../models/contingent/departmentname.model";
import {DepartmentService} from "../../../../services/contingent/department.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-contingentjournal',
  templateUrl: './contingentjournal.component.html',
  styleUrls: ['./contingentjournal.component.scss']
})
export class ContingentjournalComponent implements OnInit {

  constructor(
    private massoperationjournalService: MassoperationjournalService,
    private studentService: StudentService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private massOperationTypesService: DictmassoperationtypesService,
    private departmentService: DepartmentService,
  ) { }

  ngOnInit(): void {
    this.getAllMassOperationJournal()
    this.getAllMassOperationTypes()
    this.getAllDepartment()
  }

  public massOperationJournals: MassOperationJournal[]=[]
  public massOperationTypes: DictMassOperationTypes[]=[]
  public loading = false;
  public pageSize = 10;
  public sizes:(number | PageSizeItem)[] = [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}, {text: 'Все', value : 'all'}];
  public ownChecked = false;
  public selectedFaculty?: any[] =  undefined;
  public faculties: Faculty[] = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public getAllMassOperationJournal() {
    this.loading = true;

    let request = {
      own: this.ownChecked,
      faculties: this.selectedFaculty
    }

    this.massoperationjournalService.getAllMassOperationJournal(request)
      .subscribe(
        response => {
          this.massOperationJournals = response;
          this.massOperationJournals.forEach((x) => x.date = new Date(x.date!))
          this.massOperationJournals.forEach((x) => x.dateWithoutTime = DateFromUTCAsLocal(x.dateWithoutTime!))
          this.loading = false;
        }
      );
  }

  public getAllMassOperationTypes() {
    this.loading = true;
    this.massOperationTypesService.getAllMassOperationTypes()
      .subscribe(
        response => {
          this.massOperationTypes = response;
        }
      );
  }

  public getAllDepartment() {
    this.departmentService.getAllFaculty(2)
      .subscribe(
        response => {
          this.faculties = response;
        }
      );
  }

  public ownChange(): void {
    this.getAllMassOperationJournal();
  }

  public onFacultyChange(): void {
    if(this.selectedFaculty?.length === 0)  this.selectedFaculty = undefined
    this.getAllMassOperationJournal();
  }

  public openDetails(dataItem: any) {
    window.open(`/contingent/contingentjournal/${dataItem.massOperationJournalExternalId}`);
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите отменить: "+ dataItem.typeName + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.massoperationjournalService.deleteMassOperationJournal(dataItem.massOperationJournalExternalId)
            .subscribe({
              next:() => {
                this.getAllMassOperationJournal();
                this.notificationService.showSuccess("Отменено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }});
        }
      }
    });
  }

}

<kendo-loader class="loader" *ngIf="loaderVisible" [type]="type" size="medium"> </kendo-loader>
<div class="alignend">
  <button kendoButton themeColor="primary" class="m-l15" [disabled]="loaderVisible" (click)="saveInformation()" *ngIf="allowEdit">Сохранить</button>
</div>

<p class="selectContent bold">Сведения об обучающемся</p>

<div class="selectContent m-t25">
  <dd class="list">
    <span class="selectText parameters">Срок освоения</span>
    <p class="w-300">{{ period(basicStudentInfo.educationProgramLength) }}</p>
  </dd>

  <dd class="list">
    <span class="selectText parameters">Форма обучения</span>
    <p class="w-300">{{ basicStudentInfo.dictStudyFormName ?? "-" }}</p>
  </dd>

  <dd class="list">
    <span class="selectText parameters">Период обучения</span>
    <p class="w-300">
      c <span class="m-l15 m-r15">{{ (basicStudentInfo.dateStart | date: "dd.MM.yyyy") ?? "-" }}</span> по
      <span class="m-l15">{{ (basicStudentInfo.dateFinish | date: "dd.MM.yyyy") ?? "-" }}</span>
    </p>
  </dd>

  <dd class="list">
    <span class="selectText parameters">Дата рождения</span>
    <p class="w-300">{{ (basicStudentInfo.birthday | date: "dd.MM.yyyy") ?? "-" }}</p>
  </dd>

  <dd class="list">
    <span class="selectText parameters">Пол</span>
    <p class="w-300">{{ basicStudentInfo.isMale ? "Мужской" : "Женский" }}</p>
  </dd>

  <dd class="list">
    <span class="selectText parameters">Гражданство</span>
    <p class="w-300">{{ basicStudentInfo.citizenshipName ?? "-" }}</p>
  </dd>
</div>

<p class="selectContent bold">Диплом</p>

<div class="selectContent m-t25 w-900">
  <form [formGroup]="Form" class="selectContent w-900" novalidate>
    <dd class="list">
      <span class="selectText parameters">Тип диплома</span>
      <div class="w-650 flex">
        <kendo-dropdownlist
          class="w-300"
          [data]="typesDiploma"
          textField="name"
          valueField="id"
          [disabled]="!allowEdit"
          [valuePrimitive]="true"
          name="typeDiploma"
          (valueChange)="changeForm($event)"
          formControlName="diplomaTypeId"
          [(ngModel)]="basicInformation.diplomaTypeId"
        >
        </kendo-dropdownlist>
        <span class="required m-l5">*</span>
      </div>
    </dd>

    <dd class="list">
      <span class="selectText parameters">Ср. балл (из приложения к диплому):</span>
      <span class="w-600">{{ formatNumber(basicInformation.averageGrade) ?? "-" }}</span>
    </dd>

    <dd class="list">
      <span class="selectText parameters">Рег. номер и дата выдачи</span>
      <div class="w-650 flex">
        <span class="parameters w-90">Рег. номер</span>
        <kendo-textbox
          class="w-200"
          (valueChange)="changeForm($event)"
          formControlName="diplomaRegNumber"
          [disabled]="!allowEdit"
          [(ngModel)]="basicInformation.diplomaRegNumber"
        >
        </kendo-textbox>
        <span class="required m-l5">*</span>

        <span class="parameters w-50">Дата</span>
        <kendo-datepicker
          class="w-200"
          format="dd.MM.yyyy"
          formControlName="diplomaIssueDate"
          [disabled]="!allowEdit"
          (valueChange)="changeForm($event)"
          [(ngModel)]="diplomaIssueDate"
        >
        </kendo-datepicker>
        <span class="required">*</span>
      </div>
    </dd>

    <!-- <dd class="list">
        <span class="selectText parameters">Статус диплома</span>
        <div class="w-600">
            <kendo-dropdownlist class="w-280"
                        [data]="statuses"
                        textField="name"
                        valueField="id"
                        [disabled] = !allowEdit
                        [valuePrimitive]="true"
                        name="statuses"
                        formControlName="diplomaStatusId"
                        [(ngModel)]="basicInformation.diplomaStatusId">
            </kendo-dropdownlist>
            <span class="required m-l15">*</span>
        </div>
      </dd>-->

    <dd class="list">
      <span class="selectText parameters">Диплом, бланк</span>
      <div class="w-650 flex" *ngIf="getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnum.diploma) == BlankSettingValueEnum.manually">
        <span class="w-90 parameters">Серия</span>
        <kendo-textbox class="w-200" formControlName="diplomaSeries" [disabled]="!allowEdit" [(ngModel)]="basicInformation.diplomaSeries"> </kendo-textbox>
        <!--      <span class="required">*</span>-->

        <span class="parameters w-50 m-l30">Номер</span>
        <kendo-textbox class="w-200" formControlName="diplomaNumber" [disabled]="!allowEdit" [(ngModel)]="basicInformation.diplomaNumber"> </kendo-textbox>
        <!--      <span class="required">*</span>-->
      </div>

      <div
        class="w-650 flex"
        *ngIf="
          getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnum.diploma) == BlankSettingValueEnum.general ||
          getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnumList.diploma) == BlankSettingValueEnum.my_blanks
        "
      >
        <span class="w-90 parameters">Серия</span>
        <span *ngIf="!showDiplomaBlankSeries && basicInformation.diplomaSeries !== null && basicInformation.diplomaSeries !== ''" class="w-200 show showDiplomaBlankSeries" style="cursor: pointer" (click)="showDropDownList(SeriesAndNumbersBlanksEnum.diplomaBlankSeries)">
          {{ basicInformation.diplomaSeries }}
        </span>
        <kendo-dropdownlist
          *ngIf="showDiplomaBlankSeries || basicInformation.diplomaSeries === null || basicInformation.diplomaSeries === ''"
          class="w-200 showDiplomaBlankSeries"
          [data]="diplomaBlankSeries"
          textField="series"
          valueField="series"
          [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
          [valuePrimitive]="true"
          formControlName="diplomaSeries"
          [(ngModel)]="basicInformation.diplomaSeries"
          (valueChange)="clearDiplomaNumber(true, true)"
        >
        </kendo-dropdownlist>

        <span class="parameters w-50 m-l30">Номер</span>
        <span *ngIf="!showDiplomaBlankNumbers && basicInformation.diplomaNumber !== null && basicInformation.diplomaNumber !== ''" class="w-200 show showDiplomaBlankNumbers" style="cursor: pointer" (click)="showDropDownList(SeriesAndNumbersBlanksEnum.diplomaBlankNumbers)">
          {{ basicInformation.diplomaNumber }}
        </span>
        <kendo-dropdownlist
          *ngIf="showDiplomaBlankNumbers || basicInformation.diplomaNumber === null || basicInformation.diplomaNumber === ''"
          class="w-200 showDiplomaBlankNumbers"
          [data]="diplomaBlankNumbers"
          textField="number"
          valueField="number"
          [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
          [valuePrimitive]="true"
          formControlName="diplomaNumber"
          [(ngModel)]="basicInformation.diplomaNumber"
        >
        </kendo-dropdownlist>
      </div>
    </dd>

    <!--   <dd class="list">
        <span class="selectText parameters">Статус приложения</span>
        <div class="w-600">
            <kendo-dropdownlist class="w-280"
                        [data]="statuses"
                        textField="name"
                        valueField="id"
                        [disabled] = !allowEdit
                        [valuePrimitive]="true"
                        formControlName="diplomaAttachmentStatusId"
                        [(ngModel)]="basicInformation.diplomaAttachmentStatusId">
            </kendo-dropdownlist>
            <span class="required m-l15">*</span>
        </div>
      </dd>-->

    <dd class="list">
      <span class="selectText parameters">Приложение, бланк</span>
      <div class="w-650 flex" *ngIf="getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnum.application) == BlankSettingValueEnum.manually">
        <span class="w-90 parameters">Серия</span>
        <kendo-textbox class="w-200" formControlName="diplomaAttachmentSeries" [disabled]="!allowEdit" [(ngModel)]="basicInformation.diplomaAttachmentSeries">
        </kendo-textbox>

        <span class="parameters w-50 m-l30">Номер</span>
        <kendo-textbox class="w-200" formControlName="diplomaAttachmentNumber" [disabled]="!allowEdit" [(ngModel)]="basicInformation.diplomaAttachmentNumber">
        </kendo-textbox>
      </div>

      <div
        class="w-650 flex"
        *ngIf="
          getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnumList.application) == BlankSettingValueEnum.general ||
          getBlankSettingValue(BlankSettingSectionEnum.original, BlankTypeEnumList.application) == BlankSettingValueEnum.my_blanks
        "
      >
        <span class="w-90 parameters">Серия</span>
        <span *ngIf="!showDiplomaBlankAttachmentSeries && basicInformation.diplomaAttachmentSeries !== null && basicInformation.diplomaAttachmentSeries !== ''" class="w-200 show showDiplomaBlankAttachmentSeries" style="cursor: pointer" (click)="showDropDownList(SeriesAndNumbersBlanksEnum.diplomaBlankAttachmentSeries)">
          {{ basicInformation.diplomaAttachmentSeries }}
        </span>
        <kendo-dropdownlist
          *ngIf="showDiplomaBlankAttachmentSeries || basicInformation.diplomaAttachmentSeries === null || basicInformation.diplomaAttachmentSeries === ''"
          class="w-200 showDiplomaBlankAttachmentSeries"
          [data]="diplomaBlankAttachmentSeries"
          textField="series"
          valueField="series"
          [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
          [valuePrimitive]="true"
          formControlName="diplomaAttachmentSeries"
          [(ngModel)]="basicInformation.diplomaAttachmentSeries"
          (valueChange)="clearDiplomaNumber(false, true)"
        >
        </kendo-dropdownlist>

        <span class="parameters w-50 m-l30">Номер</span>
        <span *ngIf="!showDiplomaBlankAttachmentNumber && basicInformation.diplomaAttachmentNumber !== null && basicInformation.diplomaAttachmentNumber !== ''" class="w-200 show showDiplomaBlankAttachmentNumber" style="cursor: pointer" (click)="showDropDownList(SeriesAndNumbersBlanksEnum.diplomaBlankAttachmentNumber)">
          {{ basicInformation.diplomaAttachmentNumber }}
        </span>
        <kendo-dropdownlist
          *ngIf="showDiplomaBlankAttachmentNumber || basicInformation.diplomaAttachmentNumber === null || basicInformation.diplomaAttachmentNumber === ''"
          class="w-200 showDiplomaBlankAttachmentNumber"
          [data]="diplomaBlankAttachmentNumber"
          textField="number"
          valueField="number"
          [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
          [valuePrimitive]="true"
          formControlName="diplomaAttachmentNumber"
          [(ngModel)]="basicInformation.diplomaAttachmentNumber"
        >
        </kendo-dropdownlist>
      </div>
    </dd>

    <dd class="list">
      <span class="selectText parameters">Протокол ГИА</span>
      <div class="w-650 flex">
        <span class="parameters w-90">Номер</span>
        <!-- <span *ngIf="basicInformation.giaProtocol !== null">{{ basicInformation.giaProtocol }}</span>-->
        <!--     <kendo-numerictextbox
                  class="w-200"
                  formControlName="giaProtocol"
                  [min]="1"
                  [decimals]="0"
                  [spinners]="false"
                  format="n0"
                  [disabled] = !allowEdit
                  [(ngModel)]="basicInformation.giaProtocol"
            ></kendo-numerictextbox> -->
        <kendo-textbox class="w-200" formControlName="giaProtocol" [(ngModel)]="basicInformation.giaProtocol" [disabled]="!allowEdit"> </kendo-textbox>
        <!--   <span class="required">*</span>-->

        <span class="parameters w-50 m-l30">от</span>
        <!-- <span *ngIf="basicInformation.giaProtocolDate !== null">{{ basicInformation.giaProtocolDate }}</span>-->
        <kendo-datepicker class="w-200" format="dd.MM.yyyy" formControlName="giaProtocolDate" [disabled]="!allowEdit" [(ngModel)]="giaProtocolDate">
        </kendo-datepicker>
        <!--     <span class="required">*</span>-->
      </div>
    </dd>

    <dd class="list">
      <span class="selectText parameters">Председатель ГЭК</span>
      <!--  <p class="w-600">{{ basicInformation.secChairman ?? "-" }}</p> -->
      <p class="w-650">
        <kendo-textbox class="w-300" [disabled]="!allowEdit" formControlName="secChairman" [(ngModel)]="basicInformation.secChairman"> </kendo-textbox>
        <!--    <span class="required m-l15">*</span>-->
      </p>
    </dd>
  </form>
</div>

<p class="selectContent bold">Предыдущий документ об образовании</p>

<div class="selectContent m-t25 w-850">
  <dd class="list">
    <span class="selectText parameters">Тип документа</span>
    <p class="w-600">{{ basicStudentInfo.educationDocTypeName ?? "-" }}</p>
  </dd>

  <dd class="list">
    <span class="selectText parameters">Год выдачи</span>
    <p class="w-600">{{ basicStudentInfo.educationDocYear ?? "-" }}</p>
  </dd>
</div>

<p class="selectContent bold">Выдача дубликата</p>

<div class="selectContent m-t25 w-850">
  <dd class="list">
    <span class="selectText parameters">Причина замены</span>
    <div class="w-600 flex">
      <kendo-combobox
        class="w-600"
        [data]="reasonsReplacement"
        textField="name"
        valueField="dictId"
        [disabled]="!allowEdit"
        [valuePrimitive]="true"
        [(ngModel)]="basicInformation.diplomaReplacementReasonId"
      >
      </kendo-combobox>
    </div>
  </dd>

  <dd class="list">
    <span class="selectText parameters">Рег. номер и дата замены</span>
    <div class="w-600 flex">
      <span class="parameters w-90">Рег. номер</span>
      <kendo-textbox class="w-200" [disabled]="!allowEdit" [(ngModel)]="basicInformation.duplicateDiplomaRegNumber"> </kendo-textbox>
      <span class="parameters w-50">Дата</span>
      <kendo-datepicker class="w-200" format="dd.MM.yyyy" [disabled]="!allowEdit" [(ngModel)]="replacementDate"> </kendo-datepicker>
    </div>
  </dd>

  <dd class="list">
    <span class="selectText parameters">ФИО получателя</span>
    <div class="w-600">
      <kendo-textbox class="w-300" [disabled]="!allowEdit" [(ngModel)]="basicInformation.duplicateRecipient"> </kendo-textbox>
    </div>
  </dd>

  <!--  <dd class="list">
        <span class="selectText parameters">Статус диплома</span>
        <div class="w-600">
            <kendo-dropdownlist class="w-300"
                        [data]="statuses"
                        textField="name"
                        valueField="id"
                        [disabled] = !allowEdit
                        [valuePrimitive]="true"
                        [(ngModel)]="basicInformation.duplicateDiplomaStatusId">
            </kendo-dropdownlist>
        </div>
    </dd>-->

  <dd class="list">
    <span class="selectText parameters">Диплом, бланк</span>
    <div class="w-600 flex" *ngIf="getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnum.diploma) == BlankSettingValueEnum.manually">
      <span class="w-90 parameters">Серия</span>
      <kendo-textbox class="w-200" [disabled]="!allowEdit" [(ngModel)]="basicInformation.duplicateDiplomaSeries"> </kendo-textbox>
      <span class="parameters w-50">Номер</span>
      <kendo-textbox class="w-200" [disabled]="!allowEdit" [(ngModel)]="basicInformation.duplicateDiplomaNumber"> </kendo-textbox>
    </div>

    <div
      class="w-600 flex"
      *ngIf="
        getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnum.diploma) == BlankSettingValueEnum.general ||
        getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnumList.diploma) == BlankSettingValueEnum.my_blanks
      "
    >
      <span class="w-90 parameters">Серия</span>
      <span *ngIf="!showDuplicateDiplomaBlankSeries && basicInformation.duplicateDiplomaSeries !== null && basicInformation.duplicateDiplomaSeries !== ''"
            class="w-200 show showDuplicateDiplomaBlankSeries"
            (click)="showDropDownList(SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankSeries)">
          {{ basicInformation.duplicateDiplomaSeries }}
        </span>
      <kendo-dropdownlist
        *ngIf="showDuplicateDiplomaBlankSeries || basicInformation.duplicateDiplomaSeries === null || basicInformation.duplicateDiplomaSeries === ''"
        class="w-200 showDuplicateDiplomaBlankSeries"
        [data]="duplicateDiplomaBlankSeries"
        textField="series"
        valueField="series"
        [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
        [valuePrimitive]="true"
        [(ngModel)]="basicInformation.duplicateDiplomaSeries"
        (valueChange)="clearDiplomaNumber(true, false)"
      >
      </kendo-dropdownlist>

      <span class="parameters w-50 m-l30">Номер</span>
      <span *ngIf="!showDuplicateDiplomaBlankNumber && basicInformation.duplicateDiplomaNumber !== null && basicInformation.duplicateDiplomaNumber !== ''"
            class="w-200 show showDuplicateDiplomaBlankNumber"
            (click)="showDropDownList(SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankNumbers)">
          {{ basicInformation.duplicateDiplomaNumber }}
        </span>
      <kendo-dropdownlist
        *ngIf="showDuplicateDiplomaBlankNumber || basicInformation.duplicateDiplomaNumber === null || basicInformation.duplicateDiplomaNumber === ''"
        class="w-200 showDuplicateDiplomaBlankNumber"
        [data]="duplicateDiplomaBlankNumbers"
        textField="number"
        valueField="number"
        [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
        [valuePrimitive]="true"
        [(ngModel)]="basicInformation.duplicateDiplomaNumber"
      >
      </kendo-dropdownlist>
    </div>
  </dd>

  <!-- <dd class="list">
        <span class="selectText parameters">Статус приложения</span>
        <div class="w-600">
            <kendo-dropdownlist class="w-300"
                        [data]="statuses"
                        textField="name"
                        valueField="id"
                        [disabled] = !allowEdit
                        [valuePrimitive]="true"
                        [(ngModel)]="basicInformation.duplicateDiplomaAttachmentStatusId">
            </kendo-dropdownlist>
        </div>
    </dd>-->

  <dd class="list">
    <span class="selectText parameters">Приложение, бланк</span>
    <div class="w-600 flex" *ngIf="getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnum.application) == BlankSettingValueEnum.manually">
      <span class="w-90 parameters">Серия</span>
      <kendo-textbox class="w-200" [disabled]="!allowEdit" [(ngModel)]="basicInformation.duplicateDiplomaAttachmentSeries"> </kendo-textbox>
      <span class="parameters w-50">Номер</span>
      <kendo-textbox class="w-200" [disabled]="!allowEdit" [(ngModel)]="basicInformation.duplicateDiplomaAttachmentNumber"> </kendo-textbox>
    </div>

    <div
      class="w-600 flex"
      *ngIf="
        getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnumList.application) == BlankSettingValueEnum.general ||
        getBlankSettingValue(BlankSettingSectionEnum.duplicate, BlankTypeEnumList.application) == BlankSettingValueEnum.my_blanks
      "
    >
      <span class="w-90 parameters">Серия</span>
      <span *ngIf="!showDuplicateDiplomaBlankAttachmentSeries && basicInformation.duplicateDiplomaAttachmentSeries !== null && basicInformation.duplicateDiplomaAttachmentSeries !== ''"
            class="w-200 show showDuplicateDiplomaBlankAttachmentSeries"
            (click)="showDropDownList(SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankAttachmentSeries)">
          {{ basicInformation.duplicateDiplomaAttachmentSeries }}
        </span>
      <kendo-dropdownlist
        *ngIf="showDuplicateDiplomaBlankAttachmentSeries || basicInformation.duplicateDiplomaAttachmentSeries === null || basicInformation.duplicateDiplomaAttachmentSeries === ''"
        class="w-200 showDuplicateDiplomaBlankAttachmentSeries"
        [data]="duplicateDiplomaBlankAttachmentSeries"
        textField="series"
        valueField="series"
        [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
        [valuePrimitive]="true"
        [(ngModel)]="basicInformation.duplicateDiplomaAttachmentSeries"
        (valueChange)="clearDiplomaNumber(false, false)"
      >
      </kendo-dropdownlist>

      <span class="parameters w-50 m-l30">Номер</span>
      <span *ngIf="!showDuplicateDiplomaBlankAttachmentNumber && basicInformation.duplicateDiplomaAttachmentNumber !== null && basicInformation.duplicateDiplomaAttachmentNumber !== ''"
            class="w-200 show showDuplicateDiplomaBlankAttachmentNumber"
            (click)="showDropDownList(SeriesAndNumbersBlanksEnum.duplicateDiplomaBlankAttachmentNumber)">
          {{ basicInformation.duplicateDiplomaAttachmentNumber }}
        </span>
      <kendo-dropdownlist
        *ngIf="showDuplicateDiplomaBlankAttachmentNumber || basicInformation.duplicateDiplomaAttachmentNumber === null || basicInformation.duplicateDiplomaAttachmentNumber === ''"
        class="w-200 showDuplicateDiplomaBlankAttachmentNumber"
        [data]="duplicateDiplomaBlankAttachmentNumber"
        textField="number"
        valueField="number"
        [disabled]="!allowEdit || this.basicInformation.diplomaTypeId == null"
        [valuePrimitive]="true"
        [(ngModel)]="basicInformation.duplicateDiplomaAttachmentNumber"
      >
      </kendo-dropdownlist>
    </div>
  </dd>
</div>

<div class="alignend">
  <button kendoButton themeColor="primary" class="m-l15" [disabled]="loaderVisible" (click)="saveInformation()" *ngIf="allowEdit">Сохранить</button>
</div>

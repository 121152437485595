export const items: any[] =
[
    {
      text: "Настройки",
      icon: "cog",
      items: [
        {
          text: "Настройки доступа",
          path: "/middlecontrol/useraccess",
          useraccess:true
        },
        {
          text: "Настройки списка подписантов",
          path: "/middlecontrol/signatory",
          signers:true
        },
        {
          text: "Настройки ведомости промежуточного контроля",
          path: "/middlecontrol/statementparams",
          signers:true
        },
        {
          text: "Справочники",
          dicts: true,
          items: [
            {
              text: "Причина изменения оценки",
              path: "/middlecontrol/dictmarkchangereason",
            },
            {
              text: "Общая ведомость по дисциплине",
              path: "/middlecontrol/dictgeneralsheet",
            },
            {
              text: "Роли локальных подписантов",
              path: "/middlecontrol/localsignatoryrole",
            },
          ]
        },
        {
          text: "Внешние источники данных",
          dicts: true,
          items:[{
              text: "Контрольные акции",
              path: "/middlecontrol/dictcontrolaction",
            }
          ]
        },
        {
          text: "Отчеты",
          path: "/middlecontrol/report-list"
        },
      ],
    },
];

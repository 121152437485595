import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ApprovalListService} from "../../../../services/mfc/approvalList.service";
import {ApprovalStatusService} from "../../../../services/mfc/dicts/approval-status.service";
import {ApprovalStatus} from "../../../../models/mfc/dicts/approval-status.model";
import {ApplicationApprovalSettings, CompleteApprovalStepRequest, MoveToApprovalStepRequest} from "../../../../models/mfc/applicationForm/approvalList.model";
import {RoleService} from "../../../../services/mfc/dicts/role.service";
import {createMap} from "../../../../helpers/map-helper";
import {SignatoryRoleService} from "../../../../services/mfc/dicts/signatory-role.service";
import {lastValueFrom} from "rxjs";
import {ApplicationFormService} from "../../../../services/mfc/application-form.service";
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';
import {UserAccessService} from '../../../../services/mfc/userAccess.service';

@Component({
  selector: 'mfc-application-form-approval-list',
  templateUrl: './approval-list.component.html',
  styleUrls: ['./approval-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFormApprovalListComponent implements OnInit, OnChanges {

  @Input() public approvalSettings: ApplicationApprovalSettings[] = [];
  @Input() public applicationId: string = '';
  @Input() public editable = false;

  protected dictApprovalStatuses: ApprovalStatus[] = [];

  protected dictRolesMap: Map<unknown, unknown> = new Map([]);
  protected dictSignatoryRolesMap: Map<unknown, unknown> = new Map([]);

  protected loading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private approvalListService: ApprovalListService,
    private approvalStatusService: ApprovalStatusService,
    private dictRoleService: RoleService,
    private dictSignatoryRoleService: SignatoryRoleService,
    private applicationFormService: ApplicationFormService,
    private editFormService: ApplicationEditFormService,
    private userAccessService: UserAccessService
  ) { }

  async ngOnInit() {
    await this.getRoles();
    await this.getSignatoryRoles();
    this.getApprovalStatuses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const userRoles = this.userAccessService.currentUserAccess$.value?.user.roles
      .map((item) => item.id) ?? [];
    this.approvalSettings = this.editFormService.mapApprovalSettings(this.approvalSettings, userRoles);
  }

  private async getRoles() {
    await lastValueFrom(this.dictRoleService.getRoles()).then(response => {
      this.dictRolesMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private async getSignatoryRoles() {
    await lastValueFrom(this.dictSignatoryRoleService.getSignatoryRoles()).then(response => {
      this.dictSignatoryRolesMap = createMap({value: response, valueField: 'externalId', textField: 'name'});
    });
  }

  protected getApprovalStatuses() {
    this.approvalStatusService.getApprovalStatuses().subscribe(response => {
      this.dictApprovalStatuses = response;
      this.cdr.detectChanges();
    });
  }

  protected completeApprovalStep(request: CompleteApprovalStepRequest) {
    this.approvalListService.completeApprovalStep(this.applicationId, request).subscribe(() => {
      this.applicationFormService.refresh$.next(true);
    });
  }

  protected moveToApprovalStep(request: MoveToApprovalStepRequest) {
    this.approvalListService.moveToApprovalStep(this.applicationId, request).subscribe(() => {
      this.applicationFormService.refresh$.next(true);
    });
  }

  protected deleteCompletedApprovalStep(request: MoveToApprovalStepRequest) {
    this.approvalListService.deleteCompletedApprovalStep(this.applicationId, request).subscribe(() => {
      this.applicationFormService.refresh$.next(true);
    });
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DictComponentType } from 'src/app/models/education/dictcomponenttype.model';

@Injectable({
  providedIn: 'root'
})
export class DictComponentTypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictComponentType}`;

  constructor(private http: HttpClient) { }

  //Get all component types
  public getAllComponentTypes(): Observable<DictComponentType[]> {
    return this.http.get<DictComponentType[]>(this.baseUrl + '/GetComponentTypes/');
  }

  // Add Component Type
  addComponentType(dictComponentType: DictComponentType): Observable<DictComponentType> {
    return this.http.post<DictComponentType>(this.baseUrl + '/CreateComponentType/' , dictComponentType)
      .pipe(catchError(this.handleError));
  }

  // Edit Component Type
  updateComponentType(dictComponentType: DictComponentType): Observable<DictComponentType> {
    return this.http.put<DictComponentType>(this.baseUrl + '/UpdateComponentType/' + dictComponentType.dictComponentTypeId, dictComponentType)
      .pipe(catchError(this.handleError));
  }

  // Remove Component Type
  deleteComponentType(id: string): Observable<DictComponentType> {
    return this.http.delete<DictComponentType>(this.baseUrl + '/DeleteComponentType/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {Component, OnInit} from '@angular/core';
import {AddComment, Comment} from "../../../../../models/publications/editForm/comment.model";
import {DateToString} from "../../../../../helpers/date-helper";
import {ActivatedRoute} from "@angular/router";
import {CommentsService} from "../../../../../services/science/publications/comments.service";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {PersonService} from "../../../../../services/person/lkperson.service";
import {lastValueFrom} from "rxjs";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {PublicationsService} from "../../../../../services/science/publications/publications.service";
import {PublicationForm} from "../../../../../models/publications/editForm/publication.model";

@Component({
  selector: 'publications-edit-form-comments',
  templateUrl: './publications-edit-form-comments.component.html',
  styleUrls: ['./publications-edit-form-comments.component.scss']
})
export class PublicationsEditFormCommentsComponent implements OnInit {
  public comments: Comment[] = [];
  public currentPublication: PublicationForm = new PublicationForm();
  public commentText: string = "";
  private publicationId!: string;
  private personId!: string;
  public editable: boolean = this.userAccessService.currentUserAccess$.value.myPublication
    || this.userAccessService.currentUserAccess$.value.allPublication === AccessRights.Write;
  public loading: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private personService: PersonService,
    private commentsService: CommentsService,
    private userAccessService: PublicationsUserAccessService,
    private publicationsService: PublicationsService
  ) { }

  async ngOnInit() {
    this.loading = true;
    this.publicationId = this.activatedRoute.snapshot.params['publicationId'];
    await this.getPublication();
    this.getCurrentPerson();
    this.getComments();
  }

  private async getPublication() {
    await lastValueFrom(this.publicationsService.getPublication(this.publicationId))
        .then((value) => {
          this.currentPublication = value;
        });
  }

  private getComments() {
    this.commentsService.getComments(this.publicationId).subscribe({
      next: (value) => {
        this.comments = value ?? [];
        this.loading = false;
      },
      error: () => this.loading = false
    });
  }

  private getCurrentPerson() {
    this.personService.getCurrentPerson()
      .subscribe(response => {
        this.personId = response.personExternalId;
      });
  }

  public isAuthor(id: string) {
    return this.personId === id;
  }

  public formatDate(date: Date) {
    return DateToString(date, "dd.MM.yyyy HH:mm");
  }

  public addComment() {
    const comment: AddComment = {
      publicationId: this.publicationId,
      personId: this.personId,
      text: this.commentText
    };

    this.commentsService.addComment(comment).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Успешно');
        this.getComments();
      },
      error: (error) => this.notificationsService.showError(error)
    });

    this.commentText = "";
  }

  public removeComment(id: string) {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить комментарий?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.commentsService.removeComment(id).subscribe({
          next: () => {
            this.notificationsService.showSuccess('Успешно');
            this.getComments();
          },
          error: (error) => this.notificationsService.showError(error)
        });
      }
    });
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {FileUploadRestrictions, PropertyFileList} from "../../../models/publications/editForm/property.model";
import {handleError} from "../../../helpers/publications/errorHandle-helper";

@Injectable({
    providedIn: 'root'
})

export class PublicationsFileService {
    private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.publications.file}`;

    constructor(private http: HttpClient) {}

    public getFileUploadRestrictions(): Observable<FileUploadRestrictions> {
        return this.http.get<FileUploadRestrictions>(`${this.baseUrl}/Restrictions`)
            .pipe(catchError(handleError));
    }

    public getPublicationFile(publicationFileId: string) {
        return this.http.get(`${this.baseUrl}/PublicationFile/${publicationFileId}`, {
            responseType: 'blob',
            observe: 'response'
        }).pipe(catchError(handleError));
    }

    public getPropertyFile(propertyFileId: string) {
      return this.http.get(`${this.baseUrl}/PropertyFile/${propertyFileId}`, {
        responseType: 'blob',
        observe: 'response'
      }).pipe(catchError(handleError));
    }

    public createPublicationFiles(publicationId: string, formData: FormData): Observable<boolean> {
        return this.http.post<boolean>(`${this.baseUrl}/PublicationFile/${publicationId}`, formData)
            .pipe(catchError(handleError));
    }

    public deletePublicationFile(publicationFileId: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.baseUrl}/PublicationFile/${publicationFileId}`)
            .pipe(catchError(handleError));
    }

    public getPropertyFileList(publicationId: string, propertyId: string): Observable<PropertyFileList[]> {
        return this.http.get<PropertyFileList[]>(`${this.baseUrl}/PropertyFile/${publicationId}/${propertyId}`)
            .pipe(catchError(handleError));
    }
}

<middle-control-menu-access></middle-control-menu-access>
<h1>Причина смены оценки</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="changeReasons"
              [height]="500"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictDisciplineTypeId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="name"
                       title="Название причины"
                       [width]="600"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.name}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isRetake"
                       editor="boolean"
                       title="Пересдача"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.isRetake == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isError"
                       editor="boolean"
                       title="Ошибка"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.isError == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

  <!--  <kendo-grid-column [width]="60" *ngIf="editable" >
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" >
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>-->

    <kendo-grid-column 
                       [width]="60"
                       headerClass="gridHeader dictionaries">
                       <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="alignCells leftAlignment" *ngIf="dataItem.id !== constRetakeId && editedRowIndex !== rowIndex" >
                          <button kendoButton themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
                            rounded="full" (click)="removeHandler(dataItem)">
                          </button>
                        </span>
                      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>


<!--<div class="back-bt">-->
<!--  <button kendoButton [routerLink]="['/contingent']" *ngIf="!isMainPage" icon="undo" fillMode="flat">Назад</button>-->
<!--</div>-->
  <!--Menu-->
  <div class="setup-bt">
    <div class="setup">
      <kendo-menu class="educationMenu" [items]="items" [openOnClick]="{ toggle: 'click' } " >
        <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
          <a [kendoMenuItemLink]="index" [routerLink]="item.path">
            {{ item.text }}
            <span *ngIf="item.items && item.items.length"
                  [kendoMenuExpandArrow]="index"></span>
            <span class="k-icon k-i-{{ item.icon }} k-icon-md"></span>
          </a>
        </ng-template>
      </kendo-menu>
    </div>
  </div>




<gia-settings></gia-settings>

<h1>Шаблон {{ name }}</h1>
<form class="k-form" [formGroup]="printTemplateForm">
  <div class="selectContent">
    <dd class="filter">
      <kendo-label class="selectText">Филиал</kendo-label>
      <kendo-dropdownlist
        [data]="allFilial"
        class="w-600"
        textField="shortName"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="filialId"
        (valueChange)="onValueChange($event)"
        [(ngModel)]="filial"
      >
      </kendo-dropdownlist>
      <span class="required"></span>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Учебный год</kendo-label>
      <kendo-dropdownlist
        [data]="yearAdmissionList"
        class="w-600"
        textField="studyYearString"
        valueField="studyYear"
        [valuePrimitive]="true"
        formControlName="yearAdmission"
        (valueChange)="onValueChange($event)"
        [(ngModel)]="yearAdmission"
      >
      </kendo-dropdownlist>
      <span class="required"></span>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Категория шаблона</kendo-label>
      <kendo-dropdownlist
        [data]="dictPrintTemplateCategoryModels"
        class="w-600"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="dictPrintTemplateCategoryId"
      >
      </kendo-dropdownlist>
      <span class="required"></span>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Наименование шаблона</kendo-label>
      <kendo-textbox class="w-600" formControlName="name" [maxlength]="255" required></kendo-textbox>
      <span class="required"></span>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Уровень подготовки</kendo-label>
      <kendo-multiselect
        [data]="dictTrainingLevels"
        [ngModel]="trainingLevels"
        class="w-600"
        textField="name"
        valueField="id"
        (valueChange)="valueChangeTrainingLevel($event)"
        formControlName="printTemplateTrainingLevels"
      ></kendo-multiselect>

      <span class="required"></span>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Институт/Факультет</kendo-label>
      <kendo-dropdownlist
        [ngModel]="department"
        [data]="departments"
        class="w-600"
        textField="shortName"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="departmentId"
      >
      </kendo-dropdownlist>
      <span class="required"></span>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Наименование принтера</kendo-label>
      <kendo-textbox class="w-600" formControlName="printerName" [maxlength]="255"></kendo-textbox>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Шаблон по умолчанию</kendo-label>
      <div class="w-600">
        <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="isDefault" />
        <br />
        <p class="error" *ngIf="error">{{ error }}</p>
      </div>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText h-54">Шаблон</kendo-label>
      <kendo-formfield class="inputFile">
        <kendo-fileselect formControlName="printTemplateFirstFile" [multiple]="false" [(ngModel)]="file" [restrictions]="restrictions">
          <kendo-fileselect-messages
            dropFilesHere="Перетащите сюда файл для загрузки"
            invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}"
          >
          </kendo-fileselect-messages>
        </kendo-fileselect>
        <span class="selectText">
          <a class="link" *ngIf="firstFile.id" (click)="downloadFile(firstFile.name, firstFile.id)"> Скачать {{ firstFile.name }} </a>
        </span>
      </kendo-formfield>
      <div kendoTooltip class="tooltip position k-icon k-i-info k-icon-md" [title]="tooltip" position="right" [tooltipWidth]="200"></div>
    </dd>

    <div class="selectContent" *ngIf="isDiploma(printTemplateForm.value.dictPrintTemplateCategoryId)">
      <dd class="filter">
        <kendo-label class="selectText h-54">Шаблон приложения 1-4</kendo-label>
        <kendo-formfield class="inputFile">
          <kendo-fileselect formControlName="printTemplateSecondFile" [multiple]="false" [restrictions]="restrictions">
            <kendo-fileselect-messages
              dropFilesHere="Перетащите сюда файл для загрузки"
              invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}"
            >
            </kendo-fileselect-messages>
          </kendo-fileselect>
          <span class="selectText">
            <a class="link" *ngIf="firstFile.id" (click)="downloadFile(secondFile!.name, secondFile?.id)"> Скачать {{ secondFile?.name }} </a>
          </span>
        </kendo-formfield>
        <div kendoTooltip class="tooltip position k-icon k-i-info k-icon-md" [title]="tooltip"></div>
      </dd>

      <dd class="filter">
        <kendo-label class="selectText h-54">Шаблон приложения 2-3</kendo-label>
        <kendo-formfield class="inputFile">
          <kendo-fileselect formControlName="printTemplateThirdFile" [multiple]="false" [restrictions]="restrictions">
            <kendo-fileselect-messages
              dropFilesHere="Перетащите сюда файл для загрузки"
              invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}"
            >
            </kendo-fileselect-messages>
          </kendo-fileselect>
          <span class="selectText">
            <a class="link" *ngIf="firstFile.id" (click)="downloadFile(thirdFile!.name, thirdFile?.id)"> Скачать {{ thirdFile?.name }} </a>
          </span>
        </kendo-formfield>
        <div kendoTooltip class="tooltip position k-icon k-i-info k-icon-md" [title]="tooltip"></div>
      </dd>
    </div>
    <div class="actionButtons">
      <button (click)="onCancel()" kendoButton>Отмена</button>
      <button kendoButton themeColor="primary" [disabled]="!printTemplateForm.valid || isSaved" (click)="save()">Сохранить</button>
      <button class="right" *ngIf="printTemplateId" kendoButton themeColor="error" (click)="delete()">Удалить</button>
    </div>
  </div>
</form>
<div kendoDialogContainer></div>

import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { OrderscategoryService } from 'src/app/services/contingent/orderscategory.service';
import { CommondictService } from 'src/app/services/contingent/commondict.service';
import { OrdertypesService } from 'src/app/services/contingent/ordertypes.service';
import {OrderCategoryEnum, OrdersCategory} from 'src/app/models/contingent/orderscategory.model';
import { StudyLevel } from 'src/app/models/contingent/studylevel.model';
import { OrderTypes } from 'src/app/models/contingent/ordertypes.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import {Guid} from "guid-typescript";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import { DictFilialService } from 'src/app/services/contingent/dictfilial.service';
import { DictFilial } from 'src/app/models/contingent/dictfilial.model';
import { DictFilter } from 'src/app/models/contingent/filter.model';
import {OrderCategoriesEnum} from '../../../models/contingent/enums/order-categories.enum';

@Component({
  selector: 'app-orderscategory',
  templateUrl: './orderscategory.component.html',
  styleUrls: ['./orderscategory.component.scss']
})
export class OrderscategoryComponent implements OnInit {

  public studyLevels!: StudyLevel[];
  public dictStudyLevels: number[]=[];
  public studyLevel: StudyLevel = {
    dictStudyLevelId: 0,
    studyLevelName: '',
    studyLevelSName: ''
  }

  public pageSize = 10;
  public sizes = [10, 20, 50];

  public allOrderTypes: OrderTypes[] = [];
  public visibleOrderTypes: OrderTypes[] = [];
  public filials: DictFilial[] = [];
  public orderTypes: OrderTypes = {
    dictOrderTypeId: 0,
    typeName: '',
    filialId: ''
  }
  public editMode: boolean = false;
  public allOrdersCategory: OrdersCategory[] = [];
  public orderCategoriesByFilter: OrdersCategory[] = [];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;
  public names: string[] | undefined;
  public group = [{ field: "categoryName"}];
  public opened = false;
  public secondFilter: DictFilter = {
    dictFilialExternalId: undefined
  }

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public expandedKeys: { field: string; value: string }[] = [

  ];

  public listOrderEnums!: OrderCategoryEnum[];

  constructor(private ordersCategoryService: OrderscategoryService,
              private commonDictService: CommondictService,
              private orderTypesService: OrdertypesService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: ContingentUserAccessService,
              private dictFilialService: DictFilialService,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getOrderCategoryEnum();
    this.getAllStudyLevel();
    this.getAllOrdersCategory();
    this.getAllOrderTypes();
    this.getAllFilials();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Get studyLevel name for id
  public nameStudyLevel(id: Guid): StudyLevel | undefined {
    return this.studyLevels.find((x) => x.dictStudyLevelExternalId === id);
  }

  public nameOrderTypes(id: Guid): string {
    let order = this.allOrderTypes.find((x) => x.dictOrderTypeExternalId === id);
    if(order?.typeName!=null) return order.typeName
    else return ""
  }

  private getOrderCategoryEnum() {
    this.ordersCategoryService.getServiceCategories().subscribe((response) => this.listOrderEnums = response);
  }

  public nameEnum(enums: OrderCategoriesEnum[]) {
    return !enums ? '' : this.listOrderEnums.filter((x) => enums.includes(x.value))
      .map((item) => item.name).filter((x) => !!x).join(', ');
  }

  public onStateChange(): void {
    this.getAllOrdersCategory();
  }

  //Start adding
  public addHandler({ }: AddEvent): void {
    this.editMode = true;
    this.form = formGroup({
      dictOrdersCategoryId: 0,
      categoryName: undefined,
      dictStudyLevelId: [],
      dictOrderTypeId: undefined,
    });
    this.formGroup = formGroup({
      dictOrdersCategoryId: 0,
      categoryName: '',
      dictStudyLevelId: 0,
      dictStudyLevel: this.studyLevel,
      dictOrderTypeId: 0,
      dictOrderType: this.orderTypes,
    });
  }

  //Start Editing
  public editHandler({
                       sender,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    let data = dataItem
    if(!dataItem.dictOrderType.actual) {
      data.dictOrderTypeId = undefined
      data.dictOrderType = undefined
    }
    this.formGroup = formGroup(data);
    this.dictStudyLevels.push(dataItem.dictStudyLevelId)
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    if(grid)
      grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      /*if (this.isNew == true && this.formGroup !== undefined) {
        this.orderscategoryservice.addorderscategory(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllorderscategory();

              this.notificationService.showSuccess("Добавлено");
              this.orderscategory = {
                dictOrdersCategoryId: 0,
                categoryName: '',
                dictStudyLevelId: 0,
                dictStudyLevel: {
                  dictStudyLevelId: 0,
                  studyLevelName: '',
                  studyLevelSName: ''
                },
                dictOrderTypeId: 0,
                dictOrderType: {
                  dictOrderTypeId: 0,
                  typeName: ''
                },
              }
            },
            error => {
              this.notificationService.showError('Не удалось добавить категорию');
            }
          );
      }*/
      //Update dictionary
      if (this.formGroup !== undefined) {
        this.ordersCategoryService.updateorderscategory(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllOrdersCategory();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить категорию');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllOrdersCategory() {
    this.ordersCategoryService.getAllorderscategory(true)
      .subscribe(
        response => {
          this.allOrdersCategory = response;
          if (this.secondFilter.dictFilialExternalId)
            this.orderCategoriesByFilter = this.allOrdersCategory.filter(t => t.filialId == this.secondFilter.dictFilialExternalId);
        }
      );
  }

  //Getting all entries from dictionary
  public getAllStudyLevel() {
    this.commonDictService.getAllStudyLevel()
      .subscribe(
        response => {
          this.studyLevels = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllOrderTypes() {
    this.orderTypesService.getAllordertypes(true)
      .subscribe(
        response => {
          this.allOrderTypes = response;
          this.visibleOrderTypes = this.allOrderTypes.filter((x) => x.actual === true)
        }
      );
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
          this.applyFilter();
        }
      );
  }

  public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
    filialSName: "Не выбрано",
    dictFilialExternalId: null,
  };

  public applyFilter() {
    this.orderCategoriesByFilter = this.allOrdersCategory.filter(t => t.filialId === this.secondFilter.dictFilialExternalId);
    this.visibleOrderTypes = this.allOrderTypes.filter((x) => x.actual === true && x.filialId === this.secondFilter.dictFilialExternalId);
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.categoryName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.ordersCategoryService.deleteorderscategory(dataItem.dictOrdersCategoryExternalId)
            .subscribe({
              next:() => {
                this.getAllOrdersCategory();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public closeAddForm(): void {
    this.editMode = false;
    this.form = formGroup({
      dictOrdersCategoryId: 0,
      categoryName: undefined,
      dictStudyLevelId: [],
      dictOrderTypeId: undefined,
    });
    this.cancelHandler();
  }

  //Save Order
  public onSave() {
    if (this.form !== undefined) {
      this.ordersCategoryService.addorderscategorywitharray(this.form.value)
        .subscribe({
          next:() => {
            this.getAllOrdersCategory();
            this.notificationService.showSuccess("Категория приказа " + this.form.value.categoryName + " добавлена.");
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить категорию приказа " + this.form.value.categoryName);
          }}
        );
    }
    this.cancelHandler();
    this.editMode = false;
  }

  public open(): void {
    this.opened = true;
  }

  //Form
  public form: UntypedFormGroup = new UntypedFormGroup({
    dictOrdersCategoryId: new UntypedFormControl(),
    categoryName: new UntypedFormControl(),
    dictStudyLevelId: new UntypedFormControl([]),
    dictOrderTypeId: new UntypedFormControl(),
    enums: new UntypedFormControl(),
  });
}

const formGroup = (dataItem: { dictOrdersCategoryId: any;dictOrdersCategoryExternalId?: any; categoryName: any; dictStudyLevelId: any; dictStudyLevel?:
    any; dictOrderTypeId: any; dictOrderType?: any; actual?: any; enums?: number[]; }) =>
  new UntypedFormGroup({
    dictOrdersCategoryId: new UntypedFormControl(dataItem.dictOrdersCategoryId),
    dictOrdersCategoryExternalId: new UntypedFormControl(dataItem.dictOrdersCategoryExternalId),
    categoryName: new UntypedFormControl(dataItem.categoryName, Validators.required),
    dictStudyLevelId: new UntypedFormControl(dataItem.dictStudyLevelId, Validators.required),
    dictStudyLevel: new UntypedFormControl(dataItem.dictStudyLevel),
    dictOrderTypeId: new UntypedFormControl(dataItem.dictOrderTypeId, Validators.required),
    dictOrderType: new UntypedFormControl(dataItem.dictOrderType),
    actual: new UntypedFormControl(dataItem.actual),
    enums: new UntypedFormControl(dataItem.enums),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

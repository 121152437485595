<workload-settings></workload-settings>

<h1> Настройки доступа </h1>

  <span class="filter">
      <h3>Поиск по ФИО</h3>
      <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
      <button kendoButton (click)="clearData()">Очистить</button>
      <button kendoButton themeColor="primary" (click)="filterData(searchFIO)">Применить</button>
  </span>

  <kendo-grid class="technical"
              [kendoGridBinding]="gridData"
              [sortable]="true"
              [sort]="sort"
              (remove)="removeHandler($event)"
              (add)="addHandler($event)"
              (edit)="editHandler($event)">
    <ng-template kendoGridToolbarTemplate>
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton (click)="cancelHandler()">Отмена</button>
        <button kendoButton themeColor="primary" [disabled]="formGroup!.invalid"  (click)="saveCurrent()">Сохранить</button>
      </div>
    </ng-template>
    <kendo-grid-column [sticky]="true" title="" [width]="35">
      <ng-template kendoGridCellTemplate>
          <span class="alignCells">
            <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size " rounded="full">
            </button>
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sticky]="true" title="" [width]="35">
      <ng-template kendoGridCellTemplate>
          <span class="alignCells">
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full">
            </button>
          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [sticky]="true" [width]="250" headerClass="gridHeader dictionaries" field="personFullName" title="ФИО">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [kendoDropDownFilter]="filterSettings"
        [data]="persons"
        textField="fio"
        valueField="id"
        [valuePrimitive]="true"
        [filterable]="true"
        [virtual]="virtual"
        [formControl]="formGroup.get('personId')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.personFullName }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" [width]="195" headerClass="gridHeader dictionaries" field="trainingLevels" title="Уровень подготовки">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"  let-dataItem="dataItem">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.trainingLevels"
        class="trainingLevels"
        [(ngModel)]="trainingLevelsEdit"
        [valuePrimitive]="true"
        [filterable]="true"
        [kendoDropDownFilter]="filterSettings"
        textField="name"
        valueField="id"
        [virtual]="virtual"
        [formControl]="formGroup.get('trainingLevels')"
        (valueChange)="valueChange($event, 'trainingLevelsEdit')">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'trainingLevels')">
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ displayList(dataItem.trainingLevels, 'name') }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" [width]="195" headerClass="gridHeader dictionaries" field="faculties" title="Институт/Факультет">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"  let-dataItem="dataItem">
        <kendo-multiselect
        [data]="filteredData.faculties"
        class="faculties"
        kendoMultiSelectSummaryTag
        [(ngModel)]="facultiesEdit"
        [valuePrimitive]="true"
        [filterable]="true"
        [kendoDropDownFilter]="filterSettings"
        textField="sName"
        valueField="id"
        [formControl]="formGroup.get('faculties')"
        (valueChange)="valueChange($event, 'facultiesEdit')">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span (click)="onTagClick(dataItems, 'faculties')">
              {{ dataItems.length }} выбрано
            </span>
          </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ displayList(dataItem.faculties, 'sName') }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" [width]="400" headerClass="gridHeader dictionaries" field="kafedras" title="Кафедра">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"  let-dataItem="dataItem">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.kafedras"
        class="kafedras"
        [(ngModel)]="kafedrasEdit"
        [valuePrimitive]="true"
        textField="name"
        valueField="id"
        [filterable]="true"
        [kendoDropDownFilter]="filterSettings"
        [formControl]="formGroup.get('kafedras')"
        (valueChange)="valueChange($event, 'kafedrasEdit')">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span (click)="onTagClick(dataItems, 'kafedras')">
              {{ dataItems.length }} выбрано
            </span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ displayList(dataItem.kafedras, 'name') }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" [width]="195" headerClass="gridHeader dictionaries" field="studyForms" title="Форма обучения">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"  let-dataItem="dataItem">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.studyForms"
        class="studyForms"
        [(ngModel)]="studyFormsEdit"
        [valuePrimitive]="true"
        [filterable]="true"
        [kendoDropDownFilter]="filterSettings"
        textField="studyFormName"
        valueField="id"
        [virtual]="virtual"
        [formControl]="formGroup.get('studyForms')"
        (valueChange)="valueChange($event, 'studyFormsEdit')">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span (click)="onTagClick(dataItems, 'studyForms')">
              {{ dataItems.length }} выбрано
            </span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ displayList(dataItem.studyForms, 'studyFormName') }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="contingentLoadAccess" title="Контингент в нагрузку">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('contingentLoadAccess')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dropdownOptions[dataItem.contingentLoadAccess]['text'] }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="errandsAccess" title="Поручения">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('errandsAccess')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dropdownOptions[dataItem.errandsAccess]['text'] }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="subdepartmentLoadAccess" title="Нагрузка кафедры">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('subdepartmentLoadAccess')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dropdownOptions[dataItem.subdepartmentLoadAccess]['text'] }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="teacherLoadAccess" title="Нагрузка преподавателя">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          [data]="dropdownOptions"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('teacherLoadAccess')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dropdownOptions[dataItem.teacherLoadAccess]['text'] }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="reportsAccess" title="Отчеты">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('reportsAccess')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dropdownOptions[dataItem.reportsAccess]['text'] }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="assignmentMonitoringAccess" title="Мониторинг">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          [data]="dropdownMonitoringOptions"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('assignmentMonitoringAccess')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dropdownMonitoringOptions[dataItem.assignmentMonitoringAccess]['text'] }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="isModerator" title="Настройки доступа"
                      *ngIf="isAdmin">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('isModerator')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.isModerator ? 'Да' : 'Нет' }}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <div kendoDialogContainer></div>

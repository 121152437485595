<kendo-stacklayout [gap]="40" orientation="vertical" class="wrapper">
  <app-middlecontrolhometree
    [checkable]="true"
    [checkGroup]="false"
    [treeType]="'student'"
    [checkedItems]="checkedStudents"
    (selectedTrainingLevelChange)="onChangeTrainingLevel($event)"
    (onChangeChecked)="onChangeCheckedStudents($event)"
  ></app-middlecontrolhometree>
  <div class="signer" *ngIf="showSigner">
    <app-middlecontrolsigneredit
      [(signer)]="signer"
      [trainingLevelId]="trainingLevelId"
      (signerPostChange)="onChangeSignerPost($event)"
      [enableSync]="false"
      [facultyId]="facultyId"
    ></app-middlecontrolsigneredit>
  </div>
  <kendo-stacklayout [gap]="11" orientation="vertical">
    <span class="print--label">Печать</span>
    <button
      kendoButton
      icon="print"
      (click)="onPrintAllSessionResult()"
      class="button button--action button--icon"
    >
      результатов всех сессий
      <kendo-loader *ngIf="loadingAllSessions" size="small"></kendo-loader>
    </button>
    <button
      kendoButton
      icon="print"
      (click)="onPrintLastSessionResult()"
      class="button button--action button--icon"
    >
      результатов последней сессии
      <kendo-loader *ngIf="loadingLastSession" size="small"></kendo-loader>
    </button>
  </kendo-stacklayout>
  <button
    kendoButton
    (click)="onCancel()"
    class="button button--action"
    style="margin-top: 38px"
  >
    отмена
  </button>
</kendo-stacklayout>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DictAttestation } from 'src/app/models/currentcontrol/dict.model';
import { DictsService } from 'src/app/services/currentcontrol/dicts.service';

@Component({
  selector: 'app-togglestep',
  templateUrl: './togglestep.component.html',
  styleUrls: ['./togglestep.component.scss'],
})
export class TogglestepComponent implements OnInit {
  @Input() toggleStep: string | null = null;
  @Output() toggleStepChange: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  public dictAttestations: DictAttestation[] = [];

  constructor(private dictService: DictsService) {}

  ngOnInit(): void {
    this.dictService.dictAttestations$.subscribe(
      (attestations) => (this.dictAttestations = attestations)
    );
  }

  onToggleClick(value: string | null) {
    const current_control_settings = localStorage.getItem('current_control_settings');
    if (current_control_settings !== null){
      let storage = JSON.parse(current_control_settings)
      {
        let currentControlSaveSettings: any = {
          event: storage.event,
          discipline:storage.discipline,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: storage.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          disciplineId: storage.disciplineId,
          electiveDisciplineId: storage.electiveDisciplineId,
          week: value,
          contingentDate: storage.contingentDate
        }
        localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));
      }
    }

    this.toggleStep = value;
    this.toggleStepChange.emit(this.toggleStep);
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {DepartmentService} from "../../../../services/contingent/department.service";
import {ActivatedRoute, Router} from "@angular/router";
import {EducationService} from "../../../../services/contingent/education.service";
import {EdugroupService} from "../../../../services/contingent/edugroup.service";
import {StudedugroupService} from "../../../../services/contingent/studedugroup.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {CommondictService} from "../../../../services/contingent/commondict.service";
import {
  EduGroupNameRequest, EduGroupSemestersDto,
  EduGroupWithAllStudyPeriodDto,
  GroupChangeHistory
} from "../../../../models/contingent/edugroup.model";
import {Guid} from "guid-typescript";
import {AcademicGroupStudents} from "../../../../models/contingent/studedugroup.model";
import {Faculty} from "../../../../models/contingent/departmentname.model";
import {EducationPlan} from "../../../../models/contingent/educationplan.model";
import {DictStudyForm} from "../../../../models/contingent/dictstudyform.model";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {LoaderType} from "@progress/kendo-angular-indicators";
import {saveAs} from "@progress/kendo-file-saver";
import {CellClickEvent} from "@progress/kendo-angular-grid";
import {NgForm, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {CreateQuery} from "../../../../helpers/createQuery-helper";
import {groupBy, GroupDescriptor, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../../helpers/date-helper";
import {getErrorMessage} from "../../../../helpers/errorHandle-helper";
import {DatePipe} from "@angular/common";
import {AcademicStateEnum} from "../../../../models/contingent/enums/academic-state.enum";
import {ContingentUserAccessService} from "../../../../services/useraccess/contingent-user-access.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {ChangeHistoryEnum} from "../../../../models/contingent/enums/group-change-history.enum";
import { DictFilial } from "src/app/models/contingent/dictfilial.model";
import { DictFilialService } from "src/app/services/contingent/dictfilial.service";
import {Moment} from "moment";

@Component({
  selector: 'app-editacademicgroups',
  templateUrl: './editacademicgroups.component.html',
  styleUrls: ['./editacademicgroups.component.scss']
})
export class EditacademicgroupsComponent implements OnInit {

  constructor(private departmentService: DepartmentService,
              private router: Router,
              private educationPlanService: EducationService,
              private eduGroupService: EdugroupService,
              private studEduGroupService: StudedugroupService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private activateRoute: ActivatedRoute,
              private userAccessService: ContingentUserAccessService,
              private commondictService: CommondictService,
              private dictFilialService: DictFilialService) {
    this.academicGroupsId = activateRoute.snapshot.params["academicgroupsId"];
  }

  public academicState: any = AcademicStateEnum;
  public academicGroup: any = {};
  public elderId: any;
  public selectedElder: any = null;
  public currentYear: number = new Date().getFullYear()
  public updateCount = 0;
  public maxCourse = 0;
  public selected = 0;
  public groupCreateDate: Date = new Date();
  public tabItems = [
    {
      title: "Общие сведения",
    },
    {
      title: "Сроки семестров",
    },
    {
      title: "История изменений",
    },
  ];

  @ViewChild('semesters') public form: NgForm | undefined;
  public groupSemesters?: EduGroupWithAllStudyPeriodDto;
  public planSemesters?: EduGroupWithAllStudyPeriodDto;
  public planChangedModal: boolean = false;
  public applyPlanChanged: boolean | undefined = undefined;
  public prevPlanId: Guid | undefined = undefined;
  public group: GroupDescriptor[] = [{ field: "courseNum" }];
  public sort: SortDescriptor[] = [
    {
      field: "semesterNum",
      dir: "asc",
    },
  ];
  public nameRequest: EduGroupNameRequest = {
    planId: undefined,
    eduGroupId: undefined,
  };
  public academicGroupStudents: AcademicGroupStudents[] = [];
  public groupChangeHistory: GroupChangeHistory[] = [];
  public createDate?: Date;
  public faculties: Faculty[] = [];
  public selectedFaculties: Faculty[] = [];
  public filials: DictFilial[] = [];
  public disable = true;
  public academicGroupsId?: string;
  public educationPlans: EducationPlan[] = [];
  public selectedEducationPlans: EducationPlan[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 30];
  private datePipe: DatePipe = new DatePipe('ru-Ru');
  public historyEnum: any = ChangeHistoryEnum;

  ngOnInit(): void {
    //this.getAccessLevel()
    this.getAllEducationPlan();
    this.getAllFaculty(2);
    this.getAllFilials();
    this.editable = true;
    this.getAllStudyForm();
    //this.prevPlanId = this.academicGroup.planId;
    //if(this.academicGroup.planId != undefined) this.getEducationPlan(this.academicGroup.planId)
    this.getGroup(this.academicGroupsId);
    this.getAllAcademicGroupStudents(this.academicGroupsId);
    this.getGroupChangeHistory(this.academicGroupsId);
    this.getGroupSemesters(this.academicGroupsId);
  }

  public dictstudyforms: DictStudyForm[] = [];

  public getAllStudyForm() {
    this.commondictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictstudyforms = response;
        }
      );
  }

  public elderSelectionChange(value: any) {
    this.selectedElder = value
  }

  public getEducationPlan(id: Guid){
    this.educationPlanService.getEducationPlans(id.toString())
      .subscribe(
        response => {
          this.educationPlanChange(this.prevPlanId)
          if(this.selectedEducationPlans.length === 0) {
            this.educationPlans.push(response)
          }
          this.filterEducationPlan();
        }
      );
  }
  public getYearAdmissionList(num: number) {
    for (let i = num - 6; i < num + 6; i++) {
      this.startYears.push({ year: i });
    }
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public getAllFaculty(option: number) {
    this.departmentService.getAllFaculty(option).subscribe((response) => {
      this.faculties = response;
      this.selectedFaculties = this.faculties;
    });
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public getAllEducationPlan() {
    this.educationPlanService.getAllEducationPlans().subscribe((response) => {
      this.educationPlans = response;
      this.selectedEducationPlans = this.educationPlans;
      this.filterEducationPlan();
    });
  }

  public openPlan() {
    if(this.formEduGroup.value.planId) {
      let plan = this.educationPlans.find((x) => x.externalId === this.formEduGroup.value.planId)
      let planRoute = `/education/${plan?.educationProgramId}/plans`;
      if (plan?.externalId) planRoute += '/plan/' + plan.externalId;
      window.open(planRoute, '_blank')
    }
  }

  public getPlanMaxCourse(){
    this.eduGroupService.getMaxCourseByEduPlanId(this.formEduGroup.value.planId).subscribe({
      next: (response: any) => {
        this.maxCourse = response
      },
      error: () => {
        this.notificationService.showError("Не удалось получить максимальный курс");
      }
    })
  }

  public editable: boolean = false;
  public editElder: boolean = false;
  public noPlan: boolean = false;

  public filterEducationPlan() {
    this.selectedEducationPlans = this.educationPlans;
    if(this.formEduGroup.value.facultyId == null) this.formEduGroup.value.planId = null
    if(this.formEduGroup.value.facultyId && this.educationPlans.length > 1)
      this.selectedEducationPlans = this.selectedEducationPlans.filter((s) => s.facultyId == this.formEduGroup.value.facultyId)
    this.formEduGroup.reset(this.formEduGroup.value);
  }

  public educationPlanChange(id: any): void {
    if(id) this.getPlanMaxCourse()
    let educationPlan = this.educationPlans.find((x) => x.externalId == id);
    if (educationPlan != null) {
      //this.courseChange();
      this.academicGroup.standardName = educationPlan?.standardName;
      this.academicGroup.programName = educationPlan?.programName;
      this.academicGroup.studyFormName = this.dictstudyforms.find((x) => x.dictStudyFormExternalId === educationPlan?.dictStudyFormId)?.studyFormName
    }
  }

  public onFilialChange() {
    if (this.formEduGroup.value.filialId != null) {
      if (this.formEduGroup.value.filialId != this.selectedFaculties[0].filialId) {
        this.formEduGroup.value.planId = null;
        this.formEduGroup.value.facultyId = null;
        this.formEduGroup.reset(this.formEduGroup.value);
        this.selectedEducationPlans = [];
      }
      this.selectedFaculties = this.faculties.filter((s) => s.filialId == this.formEduGroup.value.filialId);
    }
  }

  public educationPlanStatusName(id?: any) {
    switch (id) {
      case 1:
        return "В разработке";
        break;
      case 2:
        return "Утвержден"
        break;
      case 3:
        return "Закрыт"
        break;
      default:
        return ""
        break;
    }
  }

  public admissionYearChange(){
    this.courseChange();
  }

  courseChange(){
    if(this.formEduGroup.value.admissionYear !== null && this.currentYear >= this.formEduGroup.value.admissionYear)
      this.academicGroup.courseNum =
        this.currentYear - this.formEduGroup.value.admissionYear
    else this.academicGroup.courseNum = 0;
  }

  public getGroup(id: any) {
    this.eduGroupService.getByIdedugroup(id).subscribe((response) => {
      this.editable = response.editable!;
      this.groupCreateDate = response.createdAt ? DateFromUTCAsLocal(response.createdAt): new Date()
      this.editElder = response.editElder!;
      this.academicGroup = response
      this.getYearAdmissionList(this.academicGroup.admissionYear!)
      this.elderId = this.academicGroup.starostaId
      this.formEduGroup.reset(this.academicGroup);
      this.prevPlanId = this.formEduGroup.value.planId
      this.getEducationPlan(this.formEduGroup.value.planId)
      if((response.planId===null || response.studyFormExternalId === Guid.createEmpty()) && this.editable)
        this.noPlan = true
      if (this.editable)
        this.getAllFaculty(4);
      else this.getAllFaculty(2);
    });
  }

  public getGroupSemesters(id: any){
    this.eduGroupService.getEduGroupSemesters(id).subscribe(
      response => {
        this.groupSemesters = response
        this.semesterData = []
        if(this.groupSemesters?.semesters)
          this.groupingSemesters(this.groupSemesters.semesters, false)
      },
    )
  }

  public getPlanSemesters(){
    let semesterRequest = {
      groupName: this.formEduGroup.value.groupName,
      admissionYear: this.formEduGroup.value.admissionYear,
      planId: this.formEduGroup.value.planId,
      facultyId: this.formEduGroup.value.facultyId,
      edit: true
    }

    const query = CreateQuery(semesterRequest);

    this.eduGroupService.getPrecreatedSemestersGroup(query).subscribe(
      response => {
        this.planSemesters = response
        if (this.planSemesters != undefined && this.planSemesters.semesters != undefined) this.groupingSemesters(this.planSemesters.semesters, true)

        if (this.form != null)
          this.form.form.markAllAsTouched()
      },
    )
  }

  public groupingSemesters(semesters: EduGroupSemestersDto[], fromPlan: boolean){
    if(!fromPlan) this.semesterData = []
    let groupedPlanSemesters = groupBy(orderBy(semesters, this.sort), this.group)
    groupedPlanSemesters.forEach((element:any) =>{
      if(fromPlan){
        let semester = this.semesterData.find((x) => x.firstSemester === element.items[0].semesterNum)
        if(semester != undefined){
          semester.courseNum = element.items[0].courseNum;
          semester.firstSemester = element.items[0].semesterNum;
          semester.firstSemesterStart = DateFromUTCAsLocal(element.items[0].semesterStart);
          semester.firstSemesterEnd = element.items[0].semesterEnd != null ? DateFromUTCAsLocal(element.items[0].semesterEnd): null;
          semester.secondSemester = element.items.length > 1? element.items[1].semesterNum: null;
          semester.secondSemesterStart = element.items.length > 1 && element.items[1].semesterStart != null ? DateFromUTCAsLocal(element.items[1].semesterStart): null;
          semester.secondSemesterEnd = element.items.length > 1 && element.items[1].semesterEnd != null ? DateFromUTCAsLocal(element.items[1].semesterEnd): null;
          semester.thirdSemester = element.items.length > 2? element.items[2].semesterNum: null;
          semester.thirdSemesterStart = element.items.length > 2 && element.items[2].semesterStart != null ? DateFromUTCAsLocal(element.items[2].semesterStart): null;
          semester.thirdSemesterEnd = element.items.length > 2 && element.items[2].semesterEnd != null ? DateFromUTCAsLocal(element.items[2].semesterEnd): null;
          semester.closed = element.items[0].closed;
          semester.secondClosed = element.items.length > 1 ? element.items[1].closed: false}}
      else{
        const row = {
          groupName: element.items[0].groupName,
          courseNum: element.items[0].courseNum,
          firstSemester: element.items[0].semesterNum,
          firstSemesterStart: DateFromUTCAsLocal(element.items[0].semesterStart),
          firstSemesterEnd: element.items[0].semesterEnd != null ? DateFromUTCAsLocal(element.items[0].semesterEnd): null,
          secondSemester: element.items.length > 1? element.items[1].semesterNum: null,
          secondSemesterStart: element.items.length > 1 && element.items[1].semesterStart != null ? DateFromUTCAsLocal(element.items[1].semesterStart): null,
          secondSemesterEnd: element.items.length > 1 && element.items[1].semesterEnd != null ? DateFromUTCAsLocal(element.items[1].semesterEnd): null,
          thirdSemester: element.items.length > 2? element.items[2].semesterNum: null,
          thirdSemesterStart: element.items.length > 2 && element.items[2].semesterStart != null ? DateFromUTCAsLocal(element.items[2].semesterStart): null,
          thirdSemesterEnd: element.items.length > 2 && element.items[2].semesterEnd != null ? DateFromUTCAsLocal(element.items[2].semesterEnd): null,
          closed: element.items[0].closed,
          secondClosed: element.items.length > 1 ? element.items[1].closed: false,
        }
        this.semesterData.push(row)}
    })
  }

  public getAllAcademicGroupStudents(id: any) {
    this.studEduGroupService
      .getAllAcademicGroupStudents(id)
      .subscribe((response) => {
        this.academicGroupStudents = response;
        if (this.academicGroupStudents.length == 0) this.disable = false;
        else {
          for (let i = 0; i < this.academicGroupStudents.length; i++) {
            this.academicGroupStudents[i].index = i + 1;
          }
        }
      });
  }

  public getGroupChangeHistory(id: any) {
    this.eduGroupService
      .getGroupChangeHistory(id)
      .subscribe((response) => {
        this.groupChangeHistory = response;
        if(this.groupChangeHistory.length > 0)
          this.createDate = this.groupChangeHistory.find((x) => x.activityType == this.historyEnum.created)?.dateTime
      });
  }

  public getTrainingDuration(){
    let year = (y: any) => /\d*1\d$/.test(y) || /[05-9]$/.test(y) ? ' лет ' : ( /1$/.test(y) ? ' год ' : ' года ');
    let month = (y: any) => /\d*1\d$/.test(y) || /[05-9]$/.test(y) ? ' месяцев' : ( /1$/.test(y) ? ' месяц' : ' месяца');

    return (this.groupSemesters?.yearLengthApprenticeship? this.groupSemesters?.yearLengthApprenticeship + year(this.groupSemesters?.yearLengthApprenticeship):"") +
      (this.groupSemesters?.monthLengthApprenticeship? this.groupSemesters?.monthLengthApprenticeship + month(this.groupSemesters?.monthLengthApprenticeship):"")
  }

  public getTrainingTerms(){
    return this.formEduGroup.value.admissionYear && this.semesterData.length > 0
      ? "c " + this.getDateStart(new Date(this.semesterData[0]?.firstSemesterStart))  +
      (this.groupSemesters?.monthLengthApprenticeship || this.groupSemesters?.yearLengthApprenticeship
        ? " по " + this.getDateStart(new Date(this.semesterData[(this.semesterData.length)-1].secondSemesterEnd)) : "") : ""
  }

  public getDateStart(date?: Date){
    return this.datePipe.transform(date,'dd.MM.yyyy');
  }

  // public getDateFinish(date: any){
  //   date.setDate(date.getDate() - 1)
  //   date.setMonth(date.getMonth() + this.groupSemesters?.monthLengthApprenticeship)
  //   date.setFullYear(date.getFullYear() + this.groupSemesters?.yearLengthApprenticeship)
  //   return this.getDateStart(date)
  // }


  public loaderVisible = false;
  public type: LoaderType = "converging-spinner";

  public getEduGroupName(course?: number) {
    this.loaderVisible = true;

    this.nameRequest = {
      planId: this.formEduGroup.value.planId,
      eduGroupId: this.academicGroupsId,
      facultyId: this.formEduGroup.value.facultyId,
      admissionYear: this.formEduGroup.value.admissionYear,
      studyYear: this.formEduGroup.value.yearBegin,
      course: course!= undefined ? (course + 1) : (this.academicGroup.courseNum <= 0 ? 1 : this.academicGroup.courseNum)
    };
    this.eduGroupService.getEduGroupName(this.nameRequest).subscribe({
      next: (response: any) => {
        if (response !== "")
          if(course!=undefined) this.semesterData[course].groupName = response
          else this.formEduGroup.value.groupName = response;
        this.loaderVisible = false;
      },
      error: () => {
        this.notificationService.showError("Не удалось обновить название");
        this.loaderVisible = false;
      }
    });
  }

  public index(id: any): number {
    return this.academicGroupStudents.findIndex((x) => x.planId === id) + 1;
  }

  public closeGroup(e: PointerEvent): void {
    e.preventDefault();
    this.archiveEduGroup()
  }

  public recoverGroup(e: PointerEvent): void {
    e.preventDefault();
    this.deArchiveEduGroup()
  }

  public onSave(e: PointerEvent): void {
    if(this.academicGroup.courseNum > this.maxCourse){
      this.notificationService.showError("Текущий курс превышает срок обучения по учебному плану");
      return
    }
    e.preventDefault();

    if(this.editElder && this.selectedElder !== null && this.selectedElder !== (this.academicGroup.starostaId != null ? this.academicGroup.starostaId : undefined)){
      if(this.selectedElder === undefined || this.selectedElder.academicStateCategory === this.academicState.study || this.selectedElder.academicStateCategory === this.academicState.transferred){
        this.saveEduGroupElder();
      }
      else {
        this.notificationService.showError("Невозможно назначить старосту, т.к. его академический статус не соответствует требованиям");
        return
      }
    }
    if(this.editable) this.saveEduGroup();
  }

  public exportStudentList(full: boolean): void {
    this.studEduGroupService
      .getExportStudentList(this.academicGroup.eduGroupExternalId, full)
      .subscribe({
        next: (response) => {
          let blob:any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, `Список студентов`);
        },
      })
  }

  public archiveEduGroup() {
    this.eduGroupService.archiveEduGroup(this.formEduGroup.value.eduGroupExternalId).subscribe({
      next:() => {
        this.formEduGroup.reset();
        this.notificationService.showSuccess("Группа архивирована!");
        this.router.navigateByUrl(`/contingent/academicgroupshome`).then();
      },
      error:(error) => {
        this.notificationService.showError(error.error);
      }}
    );
  }

  public deArchiveEduGroup() {
    this.eduGroupService.deArchiveEduGroup(this.formEduGroup.value.eduGroupExternalId).subscribe({
      next:() => {
        this.formEduGroup.reset();
        this.notificationService.showSuccess("Группа восстановлена!");
        this.router.navigateByUrl(`/contingent/academicgroupshome`).then();
      },
      error:(error) => {
        this.notificationService.showError(error.error);
      }}
    );
  }

  public saveEduGroup() {
    //запрос подтверждения смены плана группы, если ранее не запрашивался
    if(this.prevPlanId != this.formEduGroup.value.planId && this.applyPlanChanged == undefined) {
      this.planChangedModal = true;
      return;
    }

    this.addSemesterToSave()
    this.formEduGroup.value.courseNum = this.academicGroup.courseNum <= 0 ? 0 : this.academicGroup.courseNum
    this.formEduGroup.value.eduGroupId = this.formEduGroup.value.eduGroupExternalId
    this.formEduGroup.value.starostaId = !this.selectedElder ? null : this.selectedElder.studentId
    //применять изменение плана для всех студентов
    this.formEduGroup.value.applyPlanChanged = this.applyPlanChanged;
    //проверить что все названия группы заполнены
    if(this.formEduGroup.value.eduGroupSemesters.find((item: { groupName: string; }) => item.groupName == '')) {
      this.notificationService.showError('Заполните название группы', 5000);
      return;
    }
    this.updateCount++;
    this.eduGroupService.updateEduGroup(this.formEduGroup?.value).subscribe({
      next:() => {
        this.getUpdatedEduGroup()
      },
      error:(error) => {
        this.updateCount--;
        this.notificationService.showError(getErrorMessage(error), 5000);
      }}
    );
  }

  public saveEduGroupElder() {
    this.updateCount++;
    let eduGroup = {
      eduGroupId: this.academicGroupsId,
      starostaId: !this.selectedElder ? null : this.selectedElder.studentId
    }
    this.eduGroupService.updateEduGroupElder(eduGroup).subscribe({
      next:() => {
        this.getUpdatedEduGroup()
      },
      error:(error) => {
        this.updateCount--;
        this.notificationService.showError(getErrorMessage(error), 5000);
      }}
    );
  }

  public getUpdatedEduGroup(){
    this.updateCount--;
    if(this.updateCount === 0){
      this.notificationService.showSuccess("Изменения сохранены!");
      this.formEduGroup.reset();
      this.termsEdit = false;
      this.getGroup(this.academicGroupsId);
      this.getAllAcademicGroupStudents(this.academicGroupsId);
      this.getGroupChangeHistory(this.academicGroupsId);
      this.getGroupSemesters(this.academicGroupsId);
    }
  }

  public addSemesterToSave(){
    let semesters: any[] = []
    if(this.semesterData != undefined){
      this.semesterData.forEach((el) => {
        semesters.push({
          groupName: el.groupName,
          semesterNum: el.firstSemester,
          courseNum: el.courseNum,
          semesterStart: el.firstSemesterStart != null ? TimeZoneFix(el.firstSemesterStart): null,
          semesterEnd: el.firstSemesterEnd != null ? TimeZoneFix(el.firstSemesterEnd): null,
        })
        if(el.secondSemester!=null)
          semesters.push({
            groupName: el.groupName,
            semesterNum: el.secondSemester,
            courseNum: el.courseNum,
            semesterStart: el.secondSemesterStart != null ? TimeZoneFix(el.secondSemesterStart): null,
            semesterEnd: el.secondSemesterEnd != null ? TimeZoneFix(el.secondSemesterEnd): null,
          })
        if(el.thirdSemester!=null)
          semesters.push({
            groupName: el.groupName,
            semesterNum: el.thirdSemester,
            courseNum: el.courseNum,
            semesterStart: el.thirdSemesterStart != null ? TimeZoneFix(el.thirdSemesterStart): null,
            semesterEnd: el.thirdSemesterEnd != null ? TimeZoneFix(el.thirdSemesterEnd): null,
          })
      })
    }
    this.formEduGroup.value.eduGroupSemesters = semesters
  }

  public remove(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content:
        "Вы действительно хотите удалить группу: " +
        this.academicGroup.groupName +
        "?",
      actions: [{ text: "Нет" }, { text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text == "Да") {
          this.eduGroupService
            .deleteedugroup(this.academicGroup.eduGroupExternalId)
            .subscribe(() => {
              this.notificationService.showSuccess("Удалено");
              this.router.navigateByUrl(`/contingent/academicgroupshome`).then();
            });
        }
      }
    });
  }

  public transitionHandler({ dataItem }: CellClickEvent): void {
    window.open(
      `/contingent/studentmaininfo/${dataItem.studentId}/personaldata/${dataItem.studPersonId}`
    );
  }

  public termsEdit: boolean = false

  public termsOpen(){
    this.termsEdit = true
    setTimeout(() =>
    {
      if (this.form != null)
        this.form.form.markAllAsTouched()
    }, 100);

  }

  public termsClose(){
    if(this.groupSemesters!= undefined && this.groupSemesters.semesters!=undefined) this.groupingSemesters(this.groupSemesters.semesters, false)
    this.termsEdit = false
  }

  public startYears: Array<{ year: number }> = [];

  public trimester: boolean = false
  public semesterData: any[] = [];

  public formEduGroup: UntypedFormGroup = new UntypedFormGroup({
    eduGroupId: new UntypedFormControl(),
    eduGroupExternalId: new UntypedFormControl(),
    groupName: new UntypedFormControl(),
    admissionYear: new UntypedFormControl(),
    planId: new UntypedFormControl(),
    facultyId: new UntypedFormControl(),
    budgetQuotesStudentAdmissionBenchmarks: new UntypedFormControl(),
    commerceQuotesStudentAdmissionBenchmarks: new UntypedFormControl(),
    applyPlanChanged: new UntypedFormControl(),
    notice: new UntypedFormControl(),
    filialId: new UntypedFormControl(),
  });

  public close(){
    this.planChangedModal = false;
  }
  public save(changePlan: boolean){
    this.applyPlanChanged = changePlan;
    this.planChangedModal = false;
    this.saveEduGroup();
  }
}

enum DisplaySettingCategoryEnumList {
  persona_data = 1,
  training_info,
  other ,
  card_tabs,
  personal_tabs,
  order_tabs,
  pd_identification,
  pd_education_doc,
  pd_contacts,
  pd_address,
  pd_family_member,
  military
}

export const DisplaySettingCategoryEnum = DisplaySettingCategoryEnumList

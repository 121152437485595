<div *ngIf="isAuthorInfo">
  <publications-settings></publications-settings>
  <h1>Сводная информация</h1>
  <button kendoButton (click)="goBack()" class="k-button k-rounded-md backButton">Назад</button>
  <div class="author-info">
    <div class="info">
      <h2>ФИО автора</h2>
      <h2>Дата рождения</h2>
      <h2>Должность</h2>
      <h2>Структурное подразделение</h2>
      <h2>Идентификатор</h2>
    </div>
    <div *ngIf="summaryInfo?.person">
      <h2>{{summaryInfo.person?.fio}}</h2>
      <h2>{{getDateString(summaryInfo.person?.birthday, "dd.MM.yyyy")}}</h2>
      <h2>{{summaryInfo.person?.post}}</h2>
      <h2>{{summaryInfo.person?.department}}</h2>
      <h2>{{summaryInfo.person?.identificator}}</h2>
    </div>
  </div>
</div>

<div class="buttons">
  <button *ngIf="editable && !isEditingMode" kendoButton fillMode="outline" class="edit"
          (click)="onEdit()" icon="pencil">Редактировать</button>
  <button kendoButton *ngIf="isEditingMode" (click)="onCancel()">Отмена</button>
  <button kendoButton *ngIf="isEditingMode" (click)="onSave()" themeColor="primary">Сохранить</button>
</div>
<div class="grid-container">
  <kendo-grid [data]="gridData"
              [loading]="loading">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column headerClass="gridHeader dictionaries"
                       [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem>
                                  <span class="alignCells centerAlignment">
                                    {{getRowName(dataItem.property)}}
                                  </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngFor="let column of columns"
                       headerClass="gridHeader dictionaries"
                       [title]="getColumnName(column)"
                       [width]="50">
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-numerictextbox [disabled]="!isEditingMode" format="n"
                              [(ngModel)]="dataItem[column]"
                              (focusout)="changeInfo(dataItem[column], dataItem.property, column)">
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div class="info" *ngIf="this.summaryInfo?.updateAt && this.summaryInfo?.addedBy">
  <h2>Дата изменения: {{getDateString(summaryInfo.updateAt, "dd.MM.yyyy HH:mm")}}</h2>
  <h2>Автор изменения: {{summaryInfo.addedBy.fio}}</h2>
</div>

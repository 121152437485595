import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import {
  AddEvent,
  GridComponent,
  RemoveEvent,
  CellClickEvent,
  CancelEvent,
  PagerPosition
} from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CommonDictionariesUserAccessService } from "../../../services/useraccess/common-dictionaries-user-access.service";
import {GlobalSignatory} from "../../../models/dicts/global-signatory";
import {GlobalSignatoryService} from "../../../services/dicts/global-signatory.service";
import {PersonService} from "../../../services/dicts/person.service";
import {DepartmentService} from "../../../services/dicts/department.service";
import {DictSignatoryRoleService} from "../../../services/dicts/signatory-role.service";
import {DictPersonStatusService} from "../../../services/dicts/person-status.service";
import {checkRole} from "../../../helpers/token/role-check";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../../../models/useraccess/role";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {CommonDictPerson} from "../../../models/dicts/person.model";
import {DictPost} from "../../../models/dicts/post.model";
import { DictSignatoryRoleRead } from "../../../models/dicts/global-signatory-role";
import {DictPersonStatus} from "../../../models/dicts/person-status.model";
import {PagerType} from "@progress/kendo-angular-pager";
import {Department} from "../../../models/dicts/department.model";
import {Guid} from "guid-typescript";
import {Faculty} from "../../../models/contingent/departmentname.model";

@Component({
  selector: 'global-signatory',
  templateUrl: './global-signatory.component.html',
  styleUrls: ['./global-signatory.component.scss']
})
export class GlobalSignatoryComponent implements OnInit {
  public signatories: GlobalSignatory[] = [];
  public workingSignatories: GlobalSignatory[] = [];
  public persons: CommonDictPerson[] = [];
  public dictPosts: DictPost[] = [];
  public departments: Department[] = [];
  public dictSignatoryRoles: DictSignatoryRoleRead[] = [];
  public dictPersonStatuses: DictPersonStatus[] = [];

  public formGroup: FormGroup | undefined;
  public editable: boolean = false;
  public isAdmin: boolean = false;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  //#region Grid controls
  public position: "top" | "bottom" | "both" = "both";
  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;
  public loading = false;
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20, 50, 100];
  public previousNext = true;
  public positionPager: PagerPosition = 'bottom';
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  //#endregion

  constructor(private globalSignatoryService: GlobalSignatoryService,
              private personService: PersonService,
              private departmentService: DepartmentService,
              private signatoryRoleService: DictSignatoryRoleService,
              private personStatusService: DictPersonStatusService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService
    ) {
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getPersons();
    this.getDictPersonStatuses();
    this.getDictSignatoryRoles();
    this.getDepartments();
    this.getSignatories();
  }

  //#region Get Data

  private getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(response => this.editable = response.signatoryAccessLevel || this.isAdmin);
  }

  private getPersons() {
    this.personService.getAllPersom()
      .subscribe(response => this.persons = response);
  }

  private getDictPersonStatuses() {
    this.personStatusService.get()
      .subscribe(response => {
        this.dictPersonStatuses = response;
      });
  }

  private getDictSignatoryRoles() {
    this.signatoryRoleService.get()
      .subscribe(response => this.dictSignatoryRoles = response);
  }

  private getDepartments() {
    this.departmentService.getDepartments().subscribe(
      response => {
        this.departments = response;
      });
  }

  private getSignatories() {
    this.loading = true;
    this.globalSignatoryService.get()
      .subscribe(
        response => {
          this.signatories = response;
          this.workingSignatories = this.signatories.filter((x) => x.personStatusWork === true)
          this.loading = false;
        }, error => {
          console.log(error.error);
          this.notificationService.showError("Не удалось получить список подписантов");
          this.loading = false;
        });
  }

  //#endregion

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Save data to dictionary
  private saveRow(): void {
    if (!this.isInEditingMode) {
      this.closeEditor(this.grid);
      return;
    }

    let dataItem = this.formGroup?.value;
    if (!dataItem) return;

    let data = {} as any;
    if (dataItem.id)
      data.id = dataItem.id;
    data.personId = dataItem.person.id;
    data.postName = dataItem.postName;
    data.postSName = dataItem.postSName;
    data.printPostName = dataItem.printPostName;
    data.signatoryRoleId = dataItem.signatoryRole.id;
    if (dataItem.vacationStart)
      data.vacationStart = dataItem.vacationStart;
     if (dataItem.vacationEnd)
      data.vacationEnd = dataItem.vacationEnd;
    if (dataItem.department?.id)
      data.departmentId = dataItem.department.id;
    if (dataItem.personStatus?.id)
      data.personStatusId = dataItem.personStatus.id;
    if (dataItem.interimPostName)
      data.interimPostName = dataItem.interimPostName;
    if (dataItem.interimSignatoryId)
      data.interimSignatoryId = dataItem.interimSignatoryId;

    if (this.isNew == true && this.formGroup !== undefined) {
      this.globalSignatoryService.add(data)
        .subscribe(
          response => {
            this.getSignatories();
            this.notificationService.showSuccess("Добавлено");
          },
          error => {
            console.log(error);
            this.notificationService.showError("Не удалось добавить запись");
          }
        );
    }
    else if (this.formGroup !== undefined) {
      this.globalSignatoryService.update(data)
        .subscribe(
          response => {
            this.getSignatories();
            this.notificationService.showSuccess("Обновлено");
          },
          error => {
            console.log(error);
            this.notificationService.showError("Не удалось изменить запись");
          }
        );
    }

    this.closeEditor(this.grid);
  }

  public nameSignatory(id: Guid | undefined): string | undefined {
    return this.signatories.find((x) => x.id === id)?.person.fio;
  }

  //#region Grid Event Handlers

  //Start Adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup(new GlobalSignatory());
    this.isLine = true;
    this.isNew = true;

    sender.addRow(this.formGroup);
  }
  //Start Editing
  public editHandler({sender, isEdited, rowIndex, dataItem}: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }
  //Cancel
  public cancelHandler({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }
  public btnCancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }
  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.person?.fio}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.globalSignatoryService.delete(dataItem.id)
          .subscribe(
            ( response: any) => {
              this.getSignatories();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
        } else{
          this.opened = false;
        }
      }
    });
  }
  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  public saveHandler(event: any) {
    //console.log('save', event);
  }
  //#endregion

  //#region Helper methods

  public enableWriteVrio(personStatusName: string): boolean {
    //console.log(personStatus);
    return personStatusName !== 'В отпуске';
  }

  public personStatusColor(personStatusName: string): string {
    switch (personStatusName) {
      case 'Работает':
        return 'color: #9DD878;';
      case 'В отпуске':
        return 'color: #FF9900;';
      case 'Уволен':
        return 'color: #FF0000;';
      default: return '';
    }
  }

  //#endregion
}

const formGroup = (dataItem: GlobalSignatory) => new FormGroup({
  id: new FormControl(dataItem.id),
  person: new FormControl(dataItem.person, Validators.required),
  postName: new FormControl(dataItem.postName, Validators.required),
  postSName: new FormControl(dataItem.postSName, Validators.required),
  printPostName: new FormControl(dataItem.printPostName, Validators.required),
  department: new FormControl(dataItem.department),
  signatoryRole: new FormControl(dataItem.signatoryRole, Validators.required),
  personStatus: new FormControl(dataItem.personStatus),
  vacationStart: new FormControl(dataItem.vacationStart),
  vacationEnd: new FormControl(dataItem.vacationEnd),
  interimSignatoryId: new FormControl(dataItem.interimSignatoryId),
  interimPostName: new FormControl(dataItem.interimPostName)
});


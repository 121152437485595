import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector : 'app-giapage',
    templateUrl: './giapage.component.html',
    styleUrls : ['./giapage.component.scss']
})

export class GiaPageComponent implements OnInit{

    constructor(
        private router: Router,
    ) {}

    ngOnInit() {
        this.router.navigate([`gia/diploma`]);
    }
}
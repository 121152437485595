export const items: any[] =
[
    {
      text: "Настройки",
      icon: "cog",
      items: [
        {
          text: "Настройки доступа",
          path: "/currentcontrol/useraccess",
          useraccess:true
        },
        {
          text: "Настройки cписка подписантов",
          path: "/currentcontrol/signatory",
          signers:true
        },
        {
          text: "Справочники",
          dicts: true,
          items: [
            {
              text: "Справочник уровня критериев оценок",
              path: "/currentcontrol/dictmarkcriterialevel"
            }
          ],
        },
      ],
    },
];

import {Component, OnInit, ViewChild, Renderer2} from '@angular/core';
import {GridComponent, CellClickEvent, PagerSettings} from '@progress/kendo-angular-grid';
import {Validators, FormControl, FormGroup} from '@angular/forms';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {ContingentUserAccessService} from '../../../services/useraccess/contingent-user-access.service';
import {isChildOf} from '../../../helpers/elementRef-helper';
import {MilitaryFormType} from '../../../models/contingent/militaryformtypes.model';
import {MilitaryFormTypesService} from '../../../services/contingent/militaryformtypes.service';

@Component({
  selector: 'app-militaryformtypes',
  templateUrl: './military-form-types.component.html',
  styleUrls: ['./military-form-types.component.scss']
})
export class MilitaryFormTypesComponent implements OnInit {

  public militaryFormTypes: MilitaryFormType[] = [];
  public pagerSettings: PagerSettings = {
    type: 'numeric',
    buttonCount: 5,
    pageSizes: [10, 20, 50],
    info: true,
    previousNext: true,
    position: 'bottom'
  };
  public pageSize = 10;
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex?: number;
  public editable?: boolean;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined;
  }

  constructor(
    private militaryFormTypesService: MilitaryFormTypesService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private userAccessService: ContingentUserAccessService,
  ) { }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getMilitaryFormTypes();
    this.renderer.listen('document', 'click', ({ target }) => {
      if (!isChildOf(target, 'k-grid')) {
        this.saveCurrent();
      }
    });
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = response.dictAccessLevel !== 1;
    });
  }

  public getMilitaryFormTypes() {
    this.militaryFormTypesService.getMilitaryFormTypes().subscribe((response) => {
      this.militaryFormTypes = response;
    });
  }

  public editHandler({sender, columnIndex, rowIndex, dataItem}: CellClickEvent): void {
    if (this.isInEditingMode || (this.formGroup && this.formGroup.invalid)) {
      this.saveCurrent();
      return;
    }
    if (!this.editable) return;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public saveCurrent(): void {
    if (this.formGroup && this.formGroup.invalid) {
      return;
    }
    this.saveRow();
  }

  private closeEditor(grid: GridComponent, rowIndex= this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      this.militaryFormTypesService.updateMilitaryFormType(this.formGroup.value).subscribe({
        next: () => {
          this.getMilitaryFormTypes();
          this.notificationService.showSuccess('Сохранено');
        },
        error: () => {
          this.notificationService.showError('Не удалось сохранить изменения');
        }
      });
    }
    this.closeEditor(this.grid);
  }
}

const formGroup = (dataItem: MilitaryFormType) =>
  new FormGroup({
    externalId: new FormControl<string>(dataItem.externalId, Validators.required),
    formName: new FormControl<string>(dataItem.formName, Validators.required),
    order: new FormControl<number|null>(dataItem.order ?? null),
  });



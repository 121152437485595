import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DictLessonType } from 'src/app/models/education/dictLessonType.model';
import { DictLessonTypeService } from 'src/app/services/education/DictLessonType/dict-lesson-type.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { Guid } from 'guid-typescript';
import { Filter } from '../../../models/education/levelandstandardfilter.model';
import { FilialService } from '../../../services/education/Filial/filial.service';
import { Filial } from '../../../models/education/filial.model';

@Component({
  selector: 'app-dict-lesson-type',
  templateUrl: './dict-lesson-type.component.html',
  styleUrls: ['./dict-lesson-type.component.scss']
})
export class DictLessonTypeComponent implements OnInit {

  public gridStyle = 'grid-default';
  public isOpened: boolean = false;
  public loading: boolean = false;
  public editable?: boolean;
  public isNew: boolean = false;
  public lessonTypes!: DictLessonType[];
  public lessonsByFilter: DictLessonType[] = [];
  public traininglevels!: TrainingLevel[];
  public standardTypes!: StandardType[];
  public filials!: Filial[];
  public secondFilter: Filter = {
    standardTypeExternalId: undefined,
    trainingLevelExternalId: undefined,
    filialExternalId: undefined
  }

  constructor(private dictLessonTypeService: DictLessonTypeService,
    private notificationService: NotificationsService,
    private userAccessService:EducationUserAccessService,
    private trainingLevelService: TrainingLevelService,
    private standardTypeService: StandardtypeService,
    private filialService: FilialService) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getAllLessonTypes();
    this.getAllStandardTypes();
    this.getAllTrainingLevels();
    this.getAllFilials();
  }

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictLessonTypeId: new FormControl(0),
    dictLessonTypeExternalId: new FormControl(""),
    dictLessonTypeName: new FormControl(""),
    dictLessonTypeShortName: new FormControl(""),
    dictLessonTypeIsActual: new FormControl(""),
  });

  //Getting all entries from LessonType
  public getAllLessonTypes() {
    this.loading = true;
    this.dictLessonTypeService.getAllLessonTypes()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.lessonTypes = response;
          this.applyFilter();
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить типы проведения занятий")
        }
      });
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public saveLessonType() {
    // Add new LessonType
    if (this.isNew) {
      this.dictLessonTypeService.addLessonType({ ...this.editForm?.value, ...this.secondFilter })
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Тип проведения занятий был успешно добавлен!");
              this.getAllLessonTypes();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить тип проведения занятий");
              this.onCancel();
            }
          });
    }
    // Edit LessonType
    else {
      this.dictLessonTypeService.updateLessonType({ ...this.editForm?.value, ...this.secondFilter })
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Тип проведения занятий был успешно сохранен!");
              this.getAllLessonTypes();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось изменить тип проведения занятий');
              this.onCancel();
            }
          })
    }
  }

  public defaultItemTrainingLevel: { trainingLevelName: string; trainingLevelExternalId?: any } = {
    trainingLevelName: "Не выбрано",
    trainingLevelExternalId: null,
  };

  public defaultItemStandardType: { standardTypeShortName: string; standardTypeExternalId?: any } = {
    standardTypeShortName: "Не выбрано",
    standardTypeExternalId: null,
  };

  public defaultItemFilial: { filialShortName: string; filialExternalId?: any } = {
    filialShortName: "Не выбрано",
    filialExternalId: null,
  };

  applyFilter() {
    this.lessonsByFilter = [];
    this.lessonsByFilter = this.lessonTypes.filter(x => Guid.parse(x.standardTypeExternalId) == this.secondFilter.standardTypeExternalId &&
    Guid.parse(x.trainingLevelExternalId) == this.secondFilter.trainingLevelExternalId &&
    Guid.parse(x.filialExternalId) == this.secondFilter.filialExternalId);
  }

  //Getting all entries from standard types
  public getAllStandardTypes() {
    this.standardTypeService.getAllStandardTypes()
      .subscribe(
        response => {
          this.standardTypes = response;
        }
      );
  }

  //Getting all entries from study levels
  public getAllTrainingLevels() {
    this.trainingLevelService.getAllTrainingLevels()
      .subscribe(
        response => {
          this.traininglevels = response;
        }
      );
  }

    //Getting all entries from filials
    public getAllFilials() {
      this.filialService.getAllFilials()
        .subscribe(
          response => {
            this.filials = response;
          }
        );
    }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }

}

const formGroup = (dataItem: {
  dictLessonTypeId?: any;
  dictLessonTypeExternalId?: any;
  dictLessonTypeName?: any;
  dictLessonTypeShortName?: any;
  dictLessonTypeIsActual?: any; }) =>
    new FormGroup({
      dictLessonTypeId: new FormControl(dataItem.dictLessonTypeId),
      dictLessonTypeExternalId: new FormControl(dataItem.dictLessonTypeExternalId),
      dictLessonTypeName: new FormControl(dataItem.dictLessonTypeName),
      dictLessonTypeShortName: new FormControl(dataItem.dictLessonTypeShortName),
      dictLessonTypeIsActual: new FormControl(dataItem.dictLessonTypeIsActual),
});
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DictAchievements } from "../../models/contingent/dictachievement.model";

@Injectable({
    providedIn: 'root'
  })

export class DictAchievementService {
    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.portfolio.dict}`;
  
    constructor(private http: HttpClient) { }
  
    public getCategoryAchievement() {
        return this.http.get<DictAchievements[]>(this.baseUrl + '/DirectionActivity');
    }

    public addCategoryAchievement(data: DictAchievements) {
        return this.http.post(this.baseUrl + '/DirectionActivity', data);
    }

    public updateCategoryAchievement(data: DictAchievements, id: string) {
        data.id = id;
        return this.http.put(this.baseUrl + '/DirectionActivity', data);
    }

    public deleteCategoryAchievement(id: string) {
        return this.http.delete(this.baseUrl + '/DirectionActivity/' + id);
    }
}
import { Component, OnInit } from '@angular/core';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { FormGroup, FormControl} from "@angular/forms";
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'standardtype',
  templateUrl: './standardtype.component.html',
  styleUrls: ['./standardtype.component.scss']
})
export class StandardtypeComponent implements OnInit {

    public gridStyle = 'grid-default';
    public loading = false;
    public standardTypes!: StandardType [];
    public isNew = false;
    public editable?: boolean;
    public isOpened: boolean = false;

    constructor(private standardTypeService: StandardtypeService,
                private notificationService: NotificationsService,
                private userAccessService: EducationUserAccessService) {
    }

    ngOnInit(): void {
        this.getAllStandardTypes();
        this.getAccessLevel();
    }

    // Edit Form
    public editForm: FormGroup = new FormGroup({
        standardTypeId: new FormControl(0),
        standardTypeExternalId: new FormControl(),
        standardTypeName: new FormControl(""),
        standardTypePrintName: new FormControl(""),
        standardTypeShortName: new FormControl("")
    });

    //Getting all entries from standard
    public getAllStandardTypes() {
        this.loading = true;
        this.standardTypeService.getAllStandardTypes()
            .subscribe({
                next: (response) => {
                    this.loading = false;
                    this.standardTypes = response;
                },
                error: () => {
                    this.loading = false;
                }
            });
    }

    public getAccessLevel() {
        this.userAccessService.getAccessLevel()
            .subscribe({
                next: (response) => {
                    this.editable = !response.dictAccessLevel;
                },
                error: () => {
                }
            })
    }

    public saveStandardType() {
        // Add new standard type
        if (this.isNew) {
            this.standardTypeService.addStandardType(this.editForm?.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess("Тип стандарта был успешно добавлен!");
                    this.getAllStandardTypes();
                    this.onCancel();
                },
                error: () => {
                    this.notificationService.showError("Не удалось добавить тип стандарта");
                    this.onCancel();
                }
            });
        }
        // Edit standard type
        else {
            this.standardTypeService.updateStandardType(this.editForm?.value)
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess("Тип стандарта был успешно сохранен!");
                        this.getAllStandardTypes();
                        this.onCancel();
                    },
                    error: () => {
                        this.notificationService.showError("Не удалось изменить тип стандарта");
                        this.onCancel();
                    }
                })
        }
    }

    public addHandler(): void {
        this.isNew = true;
        this.isOpened = true;
        this.gridStyle = 'grid-hidden';
    }

    public editHandler(dataItem: any): void {
        this.editForm = formGroup(dataItem);
        this.isNew = false;
        this.gridStyle = 'grid-hidden';
        this.isOpened = true;
    }

    public onCancel(): void {
        this.editForm.reset();
        this.gridStyle = 'grid-default';
        this.isOpened = false;
    }
}

const formGroup =
    (dataItem: {
        standardTypeId?: any;
        standardTypeExternalId?: any,
        standardTypeName?: any;
        standardTypePrintName?: any;
        standardTypeShortName?: any;
    }) =>
        new FormGroup({
            standardTypeId: new FormControl(dataItem.standardTypeId),
            standardTypeExternalId: new FormControl(dataItem.standardTypeExternalId),
            standardTypeName: new FormControl(dataItem.standardTypeName),
            standardTypePrintName: new FormControl(dataItem.standardTypePrintName),
            standardTypeShortName: new FormControl(dataItem.standardTypeShortName),
        });
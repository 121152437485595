<gia-settings></gia-settings>
<kendo-stacklayout [gap]="15" orientation="horizontal" class="wrapper">
  <div class="params">
    <div class="params-list">
      <kendo-label text="Уровень подготовки" class="params-list__label">
        <kendo-dropdownlist
          class="params-list__field"
          [data]="trainingLevels"
          textField="name"
          valueField="id"
          (valueChange)="valueChange($event, true)"
          [valuePrimitive]="true"
          [(ngModel)]="trainingLevelId"
        >
        </kendo-dropdownlist>
      </kendo-label>

      <kendo-label text="Учебный год" class="params-list__label">
        <kendo-dropdownlist
          class="params-list__field"
          [data]="yearAdmissions"
          textField="studyYearString"
          valueField="studyYear"
          (valueChange)="valueChange($event, true)"
          [valuePrimitive]="true"
          [(ngModel)]="year"
        >
        </kendo-dropdownlist>
      </kendo-label>

      <kendo-label text="Институт/факультет" class="params-list__label">
        <kendo-dropdownlist
          class="params-list__field"
          [data]="departments"
          textField="shortName"
          valueField="id"
          (valueChange)="valueChange($event)"
          [valuePrimitive]="true"
          [(ngModel)]="facultyId"
        >
        </kendo-dropdownlist>
      </kendo-label>

      <kendo-label text="Направления подготовки:" class="params-list__label"></kendo-label>
      <kendo-treeview
        class="tree"
        [nodes]="dataTree"
        textField="text"
        [kendoTreeViewCheckable]="false"
        kendoTreeViewExpandable
        kendoTreeViewSelectable
        [expandedKeys]="expandedKeys"
        [isExpanded]="isExpanded"
        [children]="fetchChildren"
        [hasChildren]="hasChildren"
        checkBy="id"
        (nodeClick)="onNodeClick($event)"
        (selectionChange)="onSelectionTreeChange($event)"
      >
      </kendo-treeview>

      <div *ngIf="pageDiploma()">
        <kendo-label text="Массовая печать:" class="params-list__label"></kendo-label>
        <button kendoButton [disabled]="studyFormId === '' && !isGraduateTrainingLevel(trainingLevelId)" (click)="getDiplomasBook()" class="w-160 m-t10">
          Книга выдачи
        </button>
      </div>
    </div>
  </div>
  <div>
    <router-outlet></router-outlet>
  </div>
</kendo-stacklayout>

import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { Guid } from 'guid-typescript';
import { DictInstitution } from 'src/app/models/contingent/dictinstitution.model';
import { DictinstitutionService } from 'src/app/services/contingent/dictinstitution.service';

@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html',
  styleUrls: ['./institution.component.scss']
})
export class InstitutionComponent implements OnInit {

  public dictinstitutions: DictInstitution[] = [];
  public dictinstitution: DictInstitution = {
    dictInstitutionExternalId: Guid.create(),
    dictInstitutionId: 0,
    institutionName:''
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private dictinstitutionService: DictinstitutionService,
              private renderer: Renderer2, private formBuilder: UntypedFormBuilder,
              private notificationService: NotificationsService,
              private dialogService: DialogService
  ) {

  }


  public ngOnInit(): void {
    this.getAllInstitution();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllInstitution();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      institutionName: ""
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.dictinstitutionService.addstate(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllInstitution();
              this.notificationService.showSuccess("Добавлено");
              this.dictinstitution = {
                dictInstitutionExternalId: Guid.create(),
                dictInstitutionId: 0,
                institutionName:''
              }
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить учебное заведение');
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.dictinstitutionService.updatestate(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllInstitution();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllInstitution() {
    this.dictinstitutionService.getAllstate()
      .subscribe(
        response => {
          this.dictinstitutions = response;
        }
      );
  }


  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.institutionName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.dictinstitutionService.deletestate(dataItem.dictInstitutionExternalId)
            .subscribe({
              next:() => {
                this.getAllInstitution();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }});
        }else this.opened = false;
      }});
  }


  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { dictInstitutionExternalId?: any; institutionName: any; }) =>
  new UntypedFormGroup({
    dictInstitutionExternalId: new UntypedFormControl(dataItem.dictInstitutionExternalId),
    institutionName: new UntypedFormControl(dataItem.institutionName, Validators.required),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};


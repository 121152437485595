<current-control-menu-access></current-control-menu-access>
<kendo-stacklayout [gap]="15" orientation="horizontal" class="wrapper">
  <aside class="params">
    <app-currentcontrolhometree
      [checkGroup]="true"
      [treeType]="'department'"
      [week]="columns"
      (onChangeGroup)="onSelectionTreeChange($event)"
      (onEducationSlices)="onChangeSemester($event)"
      (onChangeContingentDate)="onChangeContingentDate($event)"
    ></app-currentcontrolhometree>
    <div class="buttons">
      <p kendoTypography variant="p" class="buttons__title">Массовая печать</p>
      <button
        kendoButton
        icon="print"
        (click)="onNavigateTo('printstatement')"
        class="buttons__action buttons__action--icon"
      >
        Ведомостей
      </button>
    </div>
  </aside>
  <div [ngSwitch]="state">
    <section *ngSwitchCase="'main'" class="session-wrapper">
      <kendo-stacklayout [gap]="40" orientation="vertical">
        <app-currentcontrolfilters
          [group]="selectedGroup"
          [contingentDate]="contingentDate"
          [(weekFilter)]="columns"
          [semester]="semester"
          (onChangeEditable)="onChangeEditable($event)"
          (stateChange)="onStateChange($event)"
          (weekFilterChange)="onChangeColumns($event)"
          (semesterChange)="onChangeSemester($event)"
        ></app-currentcontrolfilters>
        <app-currentsessiontable
          [eduGroupId]="eduGroupId"
          [contingentDate]="contingentDate"
          [semesterNumber]="semester.semesterNum || 0"
          [week]="columns"
          (onChangeDiscipline)="onChangeDiscipline($event)"
          (onChangeState)="onStateChange($event)"
        ></app-currentsessiontable>
        <app-currentsessionlegend></app-currentsessionlegend>
      </kendo-stacklayout>
    </section>
    <section *ngSwitchCase="'discipline'" class="discipline-wrapper">
      <app-currentstatementsettings
        [groupName]="selectedGroup?.text || ''"
        [eduGroupId]="eduGroupId"
        [contingentDate]="contingentDate"
        (onChangeEditable)="onChangeEditableDiscipline($event)"
        [facultyId]="facultyId"
        [semesterNumber]="semester.semesterNum || 0"
        [disciplineId]="disciplineId"
        (onBack)="onStateChange($event)"
        [(toggleStep)]="columns"
      ></app-currentstatementsettings>
      <app-currentdisciplinetable
        [eduGroupId]="eduGroupId"
        [editable]="editableDiscipline"
        [contingentDate]="contingentDate"
        [semesterNumber]="semester.semesterNum || 0"
        [disciplineId]="disciplineId"
        [electiveDisciplineId]="electiveDisciplineId"
        [toggleStep]="columns"
        (onBack)="onStateChange($event)"
      ></app-currentdisciplinetable>
    </section>
  </div>
</kendo-stacklayout>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { OrdersStudyPeriod } from '../../models/contingent/ordersstudyperiod.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class OrdersstudyperiodService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.ordersstudyperiod}`;

  constructor(private http: HttpClient) { }

  //Get all ordersstudyperiod
  public getAllordersstudyperiod(): Observable<OrdersStudyPeriod[]> {
    return this.http.get<OrdersStudyPeriod[]>(this.baseUrl);
  }

  //Get By Id ordersstudyperiod
  getByIdordersstudyperiod(id: number|undefined): Observable<OrdersStudyPeriod> {
    return this.http.get<OrdersStudyPeriod>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId
  getStudentStudyPeriod(id: Guid): Observable<OrdersStudyPeriod> {
    return this.http.get<OrdersStudyPeriod>(this.baseUrl + '/getordersstudyperiodbystudentid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add ordersstudyperiod
  addordersstudyperiod(ordersstudyperiod: OrdersStudyPeriod): Observable<OrdersStudyPeriod> {
    return this.http.post<OrdersStudyPeriod>(this.baseUrl, ordersstudyperiod)
      .pipe(catchError(this.handleError));
  }

  //Delete ordersstudyperiod
  deleteordersstudyperiod(id: string, studentId : Guid| undefined): Observable<OrdersStudyPeriod> {
    return this.http.delete<OrdersStudyPeriod>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit ordersstudyperiod
  updateordersstudyperiod(ordersstudyperiod: OrdersStudyPeriod): Observable<OrdersStudyPeriod> {
    return this.http.put<OrdersStudyPeriod>(this.baseUrl + '/' + ordersstudyperiod.ordersStudyPeriodExternalId, ordersstudyperiod)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

import {Component, OnInit, ViewChild} from "@angular/core";
import {BlankTypesModel} from '../../../../models/diplomablanks/dicts.model';
import {BlankTemplateModel, DepartmentsModel, PersonsModel} from '../../../../models/diplomablanks/dicts.model';
import {Router} from "@angular/router";
import {GeneralDictsService} from '../../../../services/diplomablanks/general-dicts.service';
import {DictBlankTypeService} from '../../../../services/diplomablanks/dict-blanktypes.service';
import {DictBlankTemplateService} from '../../../../services/diplomablanks/dict-blanktemplate.service';
import {BlanksAvailableService} from '../../../../services/diplomablanks/blanks-available.service';
import {BlankModel, BlanksAvailableModel, blanksPersonDataModel} from '../../../../models/diplomablanks/blanks-available.model';
import {Validators, FormGroup, FormControl} from "@angular/forms";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import {TokenStorageService} from 'src/app/services/token.service';
import {JwtHelperService} from '@auth0/angular-jwt';


@Component({
    selector: 'app-issueblanks',
    templateUrl: './issue-blanks.component.html',
    styleUrls: ['./issue-blanks.component.scss']
  })
  
export class IssueBlanksComponent implements OnInit {

  public departments: DepartmentsModel[] = [];
  public persons: PersonsModel[] = [];
  public filterPersons: PersonsModel[] = [];

  public blankTypes: BlankTypesModel[] = [];
  public blankTemplates: BlankTemplateModel[] = [];
  public series: string[] = [];
  public numberBlanks: BlankModel[] = [];
  public filterNumberBlanks: BlankModel[] = [];

  public departmentId: string = "";
  public personId: string | null = null;
  public dictBlankTypeId: string | null = null;
  public dictBlankTemplateId: string | null = null;
  public seriesName: string | null = null;
  public numberFirstBlank: string | null = null;
  public count: number | null = null;
  public blanksPersonData: blanksPersonDataModel = new blanksPersonDataModel();

  public notInformation: boolean = false;

  public allBlanks: BlanksAvailableModel[] = [];
  public filterAllBlanks: BlanksAvailableModel[] = [];
  public countBlanks: number = 0;
  public exceeds: boolean = false;

  public next: boolean = false;

  public issueBlanks: BlanksAvailableModel[] = [];
  public userId: string = "";
  public allItemsChecked: boolean = true;
  public countIssueBlanks: number = 0;

  Form: FormGroup = new FormGroup({
    departmentId: new FormControl(''),
    personId: new FormControl('', [Validators.required]),
    dictBlankTypeId: new FormControl('', [Validators.required]),
    dictBlankTemplateId: new FormControl('', [Validators.required]),
    seriesName: new FormControl('', [Validators.required]),
    numberFirstBlank: new FormControl('', [Validators.required]),
    count: new FormControl('', [Validators.required]),
  });
    
  constructor(
    private dictBlankTypeService: DictBlankTypeService,
    private dictBlankTemplateService: DictBlankTemplateService,
    private blanksAvailableService: BlanksAvailableService,
    private notificationService: NotificationsService,
    private generalDictsService: GeneralDictsService,
    private router: Router,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,

  ) {}

  public ngOnInit(): void {
    this.getDepartments();
    this.getBlankTypes();
    this.getAllBlanks();
    const token = this.tokenStore.getAuthToken();
    if (token !== null) this.userId = this.jwtHelper.decodeToken(token).person_id;
  }

  public getDepartments() {
    this.generalDictsService.getDepartments()
      .subscribe(response => {
        this.departments = response;
        this.getPersons();
      })
  }

  public getPersons(refresh?: boolean) {
    if (!refresh) {
      this.persons = [];
      this.personId = null;
    }
    this.generalDictsService.getPersons(this.departmentId)
      .subscribe(response => {
        this.persons = response;
        this.filterPersons = this.persons;
        if (refresh && this.personId) this.changePerson(this.personId);;
      })
  }

  public getBlankTypes() {
    this.generalDictsService.getExistBlankTypes()
      .subscribe(response => {
        if (response) this.blankTypes = response;
        this.getBlankTemplates();
      })
  }

  public getBlankTemplates() {
    this.blankTemplates = [];
    this.dictBlankTemplateId = null;
    if (this.dictBlankTypeId)
      this.blanksAvailableService.getTemplate(this.dictBlankTypeId)
        .subscribe(response => {
          if (response) {
            this.blankTemplates = response;
            this.blankTemplates.forEach(el => el.name = el.name + ' ' + el.graduateYear);
            if (this.blankTemplates.length == 1) this.dictBlankTemplateId = this.blankTemplates[0].externalId;
            this. getBlanksSeries();
          }
        })
  }

  public getBlanksSeries() {
    this.series = [];
    this.seriesName = null;
    if (this.dictBlankTypeId && this.dictBlankTemplateId) 
      this.blanksAvailableService.getSeries(this.dictBlankTypeId, this.dictBlankTemplateId)
        .subscribe(response => {
            if (response) this.series = response;
            if (this.series.length == 1) this.seriesName = this.series[0];
            this.getBlanksNumbers();
        })
    else {
      this.getBlanksNumbers();
    }
  }

  public getBlanksNumbers() {
    this.numberBlanks = [];
    this.filterNumberBlanks = [];
    this.numberFirstBlank = null;
    if (this.dictBlankTypeId && this.dictBlankTemplateId && this.seriesName)
      this.blanksAvailableService.getBlanksNumbers(this.seriesName, this.dictBlankTypeId, this.dictBlankTemplateId)
        .subscribe(response => {
            this.numberBlanks = response;
            this.filterNumberBlanks = this.numberBlanks;
            this.numberFirstBlank = this.filterNumberBlanks[0].number;

            this.filterBlanks();
        })
  }

  public getAllBlanks() {
    this.blanksAvailableService.getAllBlank([null])
    .subscribe(response => {
        this.allBlanks = response;
    })
  }

  public changeDepartment(value: string) {
    value == undefined ? this.departmentId = "" : this.departmentId = value;
    this.getPersons();
  }

  public changePerson(value: string) {
    const person = this.persons.find(el => el.externalId == value);
    this.blanksPersonData = {availableBlanks: person?.availableBlanks, usedBlanks: person?.usedBlanks, spoiledBlanks: person?.spoiledBlanks};
    this.blanksPersonData.availableBlanks == 0 && this.blanksPersonData.usedBlanks == 0 && this.blanksPersonData.spoiledBlanks == 0 ? this.notInformation = true : this.notInformation = false;
    if (person?.departmentId) this.departmentId = person.departmentId;
  }

  handleFilter(value: string) {
    this.filterPersons = this.persons.filter(
      (s) => s.fio.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public onChangeTemplate(value: string) {
    this.getBlanksSeries();
    this.filterBlanks();
  }

  public onChangeType(value: string) {
    this.getBlankTemplates();
    this.filterBlanks();
  }

  public onChangeSeries(value: string) {
    this.getBlanksNumbers();
   // this.filterBlanks();
  }

  filterNumber(value: string) {
    this.filterNumberBlanks = this.numberBlanks.filter(
      (s) => s.number.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  private filterBlanks() {
    this.filterAllBlanks = this.allBlanks;
    if (this.dictBlankTypeId) this.filterAllBlanks = this.filterAllBlanks.filter(el => el.blankTypeId == this.dictBlankTypeId);
    if (this.dictBlankTemplateId) this.filterAllBlanks = this.filterAllBlanks.filter(el =>  el.blankTemplateId == this.dictBlankTemplateId);
    if (this.seriesName) this.filterAllBlanks = this.filterAllBlanks.filter(el => el.series == this.seriesName);
    if (this.numberFirstBlank) this.filterAllBlanks = this.filterAllBlanks.filter(el => this.numberFirstBlank && parseInt(el.number) >= parseInt(this.numberFirstBlank));
    this.countBlanks = this.filterAllBlanks.length;
    this.onChangeCount();
  }

  public onChangeNumber(value: string) {
    this.filterBlanks();
  }

  public onChangeCount(value?: number) {
    this.count && this.count > this.countBlanks ? this.exceeds = true : this.exceeds = false;
  }

  public cancel() {
    this.router.navigate(['/diplomablanks']);
  }

  public nextStep() {
    this.next = true;
    const index = this.filterAllBlanks.findIndex(el => el.number == this.numberFirstBlank);
    if (this.count) this.issueBlanks = this.filterAllBlanks.slice(index, this.count);
    this.issueBlanks.map(el => el.checked = true);
    if (this.count !== null) this.countIssueBlanks = this.count;
  }

  public edit() {
    this.next = false;
    this.issueBlanks = [];
  }

  public checkedChange(dataItem: BlanksAvailableModel) {
    dataItem.checked = !dataItem.checked;
    dataItem.checked ? this.countIssueBlanks = this.countIssueBlanks + 1 : this.countIssueBlanks = this.countIssueBlanks - 1;
    this.allItemsChecked = this.issueBlanks.length == this.countIssueBlanks;
  }

  public checkedBlanks() {
    return this.issueBlanks.filter(el => !el.checked).length == this.issueBlanks.length;
  }

  public selectAll(value: boolean) {
    this.allItemsChecked = !this.allItemsChecked;
    this.issueBlanks.map(el => this.allItemsChecked ? el.checked = true : el.checked = false);
    this.allItemsChecked ? this.countIssueBlanks = this.issueBlanks.filter(el => el.checked).length : this.countIssueBlanks = 0;
  }

  public issue() {
    const data = {
      issuerPersonId: this.userId,
      recipientPersonId: this.personId,
      ids: this.issueBlanks.filter(el => el.checked).map(el => el.id),
    }

    this.blanksAvailableService.issueBlanks(data)
      .subscribe(response => {
        this.notificationService.showSuccess('Успешно');
        this.downloadReceipt(response);
        if (this.personId) this.getPersons(true);
        this.next = false;
        this.dictBlankTypeId = null;
        this.dictBlankTemplateId = null;
        this.seriesName = null;
        this.numberFirstBlank = null;
        this.count = null;
        this.countBlanks = 0;
      })
  }

  private downloadReceipt(date: any) {
    const data = {
      issuerPersonId: this.userId,
      recipientPersonId: this.personId,
      issueDate: date,
      dictBlankTypeId: this.dictBlankTypeId,
    }

    this.blanksAvailableService.getReceipt(data).subscribe({
      next: (value) => {
        DownloadFile(value);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }
}
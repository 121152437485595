import { Citizenship } from "./citizenship.model";
import {Guid} from "guid-typescript";

export class StudPerson {
  public studPersonId!: number;
  public studPersonExternalId?:Guid;
  public firstName!: string;
  public lastName?: string;
  public middleName?: string;
  public maidenName?: string;
  public buhCode?: string;
  public isMale!: boolean;
  public birthday!: Date;
  public citizenshipId?: Guid;
  public dictCitizenships!: Citizenship;
  public inn?: string;
  public snils?: string;
  public login!: string;
  public lkPhoto?: string;
  public firstNameLatin?: string;
  public lastNameLatin?: string;
  public middleNameLatin?: string;
  public lastEdited?: Date;
}


export class StudPersonCont {
  public studPersonExternalId!: string;
  public firstName!: string;
  public lastName?: string;
  public middleName?: string;
  public maidenName?: string;
  public login?: string;
  public fullNameLatin?: string;
  public firstNameLatin!: string;
  public lastNameLatin?: string;
  public middleNameLatin?: string;
  public inn?: string;
  public fullName?: string;
  public isMale!: boolean;
  public isMaleName!: string;
  public birthday!: Date;
  public birthdayFormated?: string;
  public snils?: string;
  public country?: string;
  public phone?: string;
  public email?: string;
  public socialNetwork?: string;
  public medicalCertificate?:string;
}

export class NameStudPerson {
  public studPersonId?: Guid;
  public fullName: string = "Имя не найдено";
}

<gia-settings></gia-settings>
<form class="k-form" [formGroup]="stateExamCommissionForm">
  <h1>{{ name }}</h1>
  <div class="selectContent">
    <dd class="filter">
      <kendo-label class="selectText">Филиал</kendo-label>
      <kendo-dropdownlist
        [data]="allFilial"
        (valueChange)="editFilialId($event)"
        class="w-600"
        textField="shortName"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="filialId"
        [(ngModel)]="filial"
        [disabled]="!editable"
      >
      </kendo-dropdownlist>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Учебный год</kendo-label>
      <kendo-dropdownlist
        [data]="yearAdmissionList"
        (valueChange)="editYearAdmission($event)"
        class="w-600"
        textField="studyYearString"
        valueField="studyYear"
        [valuePrimitive]="true"
        formControlName="yearAdmission"
        [(ngModel)]="yearAdmission"
        [disabled]="!editable"
      >
      </kendo-dropdownlist>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Уровень подготовки</kendo-label>
      <kendo-dropdownlist
        [data]="trainingLevels"
        (valueChange)="editTrainingLevels($event)"
        class="w-600"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="dictTrainingLevelId"
        [(ngModel)]="trainingLevel"
        [disabled]="!editable"
      >
      </kendo-dropdownlist>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Тип приказа</kendo-label>
      <kendo-dropdownlist
        [data]="dictStateExamCommissionTypes"
        (valueChange)="getStateExamCommissionTypeName($event, true)"
        class="w-600"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="stateExamCommissionTypeId"
        [disabled]="!editable"
      >
      </kendo-dropdownlist>
      <span class="required"></span>
    </dd>

    <dd class="filter" *ngIf="isEditCategoryOrder">
      <kendo-label class="selectText">Приказ</kendo-label>
      <kendo-dropdownlist
        [data]="childrens"
        (valueChange)="getChildrenStateExamCommissionById($event)"
        class="w-600"
        textField="numberAndDate"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="childId"
        [disabled]="!editable"
      >
      </kendo-dropdownlist>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Шаблон</kendo-label>
      <kendo-dropdownlist
        [disabled]="!editable"
        [data]="printTemplates"
        class="w-600"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        formControlName="printTemplateId"
      >
      </kendo-dropdownlist>
      <span class="required"></span>
    </dd>

    <dd class="filter">
      <kendo-label class="selectText">Номер и дата приказа</kendo-label>
      <div class="w-620 flex">
        <span class="k-mr-5">№</span>
        <kendo-textbox [disabled]="!editable" formControlName="number" class="w-250"></kendo-textbox>
        <span class="required"></span>
        <span class="k-ml-7 k-mr-3">Дата</span>
        <kendo-datepicker [disabled]="!editable" format="dd.MM.yyyy" formControlName="date" class="w-250"></kendo-datepicker>
        <span class="required"></span>
      </div>
    </dd>
  </div>
  <div class="form-buttons">
    <div>
      <button kendoButton size="small" (click)="openpopup()">Отмена</button>
      <button kendoButton class="save-button" size="small" themeColor="primary" [disabled]="!stateExamCommissionForm.valid || !editable || saving" (click)="saveOrder()">
        Сохранить
      </button>
    </div>
    <button kendoButton size="small" themeColor="primary" icon="print" [disabled]="!stateExamCommissionForm.valid || !editable || saving" (click)="saveOrder(true)">
      Сохранить и распечатать
    </button>
  </div>
  <div class="sec" formArrayName="commissions">
    <ng-container *ngFor="let commission of commissionList; let i = index">
      <div class="sec" [formGroupName]="i">
        <kendo-label class="k-mb-3 k-pivotgrid-total">Состав экзаменационной комиссии</kendo-label>
        <hr />
        <div class="d-flex align-items-center justify-content-between">
          <kendo-label class="k-mb-3 k-pivotgrid-total"> Комиссия по следующим направлениям подготовки </kendo-label>
          <div class="k-text-right" *ngIf="!isEditCategoryOrder">
            <button kendoButton *ngIf="commissionList.length > 1" [disabled]="!editable" class="edit-button" icon="x" (click)="removeCommission(i)">
              Удалить комиссию
            </button>
          </div>
        </div>

        <div class="selectContent">
          <kendo-formfield showHints="always">
            <dd class="filter">
              <kendo-label class="selectText">Форма обучения</kendo-label>
              <kendo-multiselect
                [data]="dictStudyForms"
                [ngModel]="this.commissionList[i].studyForms"
                class="w-600"
                textField="name"
                valueField="id"
                (valueChange)="valueChangeStudyForm($event, i)"
                [disabled]="isEditCategoryOrder || !editable"
                formControlName="studyForms"
              ></kendo-multiselect>

              <span *ngIf="!isEditCategoryOrder && editable" class="required"></span>
            </dd>
          </kendo-formfield>
        </div>
        <div formArrayName="standards">
          <kendo-grid #grid [kendoGridBinding]="commission.standards" (remove)="removeHandler($event, i, standards)">
            <kendo-grid-column title="Направление подготовки" headerClass="gridHeader dictionaries">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-combobox
                    [kendoDropDownFilter]="filterSettings"
                    [data]="eduStandards"
                    textField="standardName"
                    valueField="id"
                    [valuePrimitive]="true"
                    formControlName="educationStandardId"
                    [disabled]="isEditCategoryOrder || !editable"
                    (valueChange)="clearProgram(i, rowIndex)"
                  >
                  </kendo-combobox>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Направленность/профиль" headerClass="gridHeader dictionaries">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-formfield class="m-t0" showHints="always">
                    <kendo-multiselect
                      [data]="getEduProgramByStandard(i, rowIndex)"
                      [ngModel]="this.commissionList[i].standards[rowIndex].eduPrograms"
                      textField="programName"
                      valueField="id"
                      (valueChange)="valueChangeEduPrograms($event, i, rowIndex)"
                      [disabled]="isEditCategoryOrder || !editable"
                      formControlName="eduPrograms"
                    ></kendo-multiselect>
                  </kendo-formfield>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" [width]="60" *ngIf="!isEditCategoryOrder && editable">
              <ng-template kendoGridCellTemplate>
                <span class="alignCells">
                  <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full"></button>
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
          <div class="k-text-right">
            <button *ngIf="!isEditCategoryOrder && editable" kendoButton class="edit-button k-mt-3" icon="plus" (click)="addFormGroup(i, standards)">
              Добавить направление подготовки
            </button>
          </div>
        </div>

        <div class="k-mt-5" formArrayName="expelledMembers" *ngIf="isEditCategoryOrder && childrenStateExamCommission.commissions">
          <kendo-label class="k-mb-1 k-pivotgrid-total"> Вывести из состава комиссии </kendo-label>
          <kendo-grid #grid [kendoGridBinding]="commission.expelledMembers" (remove)="removeHandler($event, i, expelledMembers)">
            <kendo-grid-column title="ФИО" headerClass="gridHeader dictionaries">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-combobox
                    [kendoDropDownFilter]="filterSettings"
                    [data]="getChildOrderPersons(i)"
                    textField="fioAndRoleName"
                    valueField="id"
                    [valuePrimitive]="true"
                    formControlName="stateExamCommissionMemberId"
                    [disabled]="!editable"
                  >
                  </kendo-combobox>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Причина" headerClass="gridHeader dictionaries">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-textbox [disabled]="!editable" formControlName="reason"> </kendo-textbox>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" [width]="60">
              <ng-template kendoGridCellTemplate>
                <span class="alignCells">
                  <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full"></button>
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
          <div class="k-text-right">
            <button kendoButton [disabled]="!editable" class="edit-button k-mt-3" icon="plus" (click)="addFormGroup(i, expelledMembers)">
              Добавить члена комиссии
            </button>
          </div>
        </div>

        <kendo-label class="k-mb-1 k-pivotgrid-total"> {{ isEditCategoryOrder ? "Ввести в состав комиссии" : "Состав комиссии" }} </kendo-label>
        <div formArrayName="members">
          <kendo-grid #grid [kendoGridBinding]="commission.members" (remove)="removeHandler($event, i, members)">
            <kendo-grid-column *ngIf="!isExamOrder" title="Роль" headerClass="gridHeader dictionaries">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-dropdownlist
                    [disabled]="!editable"
                    [data]="roles"
                    textField="name"
                    valueField="id"
                    [valuePrimitive]="true"
                    formControlName="dictRoleId"
                  >
                  </kendo-dropdownlist>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Место работы" headerClass="gridHeader dictionaries">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-dropdownlist
                    [data]="workPlaces"
                    textField="name"
                    (valueChange)="workPlaceChange($event)"
                    valueField="id"
                    [valuePrimitive]="true"
                    formControlName="workPlace"
                    [disabled]="!editable"
                  >
                  </kendo-dropdownlist>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="ФИО" headerClass="gridHeader dictionaries">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-combobox
                    *ngIf="isEmployee(i, rowIndex)"
                    (valueChange)="updateEmployeeInformation(i, rowIndex, $event)"
                    [kendoDropDownFilter]="filterSettings"
                    [data]="persons"
                    textField="fio"
                    valueField="id"
                    [valuePrimitive]="true"
                    formControlName="personId"
                    [disabled]="!editable"
                  >
                  </kendo-combobox>
                  <kendo-textbox
                    *ngIf="!isEmployee(i, rowIndex)"
                    (valueChange)="changeFioValue($event, i, rowIndex)"
                    (blur)="handleBlurEvent()"
                    formControlName="fio"
                    [disabled]="!editable"
                  >
                  </kendo-textbox>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Фамилия И.О." headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-textbox [disabled]="!editable" formControlName="fioInitialsAfter"> </kendo-textbox>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Должность" headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-textbox [disabled]="!editable" formControlName="post"> </kendo-textbox>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Ученая степень" headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-textbox [disabled]="!editable" formControlName="scientificDegreeName"> </kendo-textbox>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Сокр. уч. ст." headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-textbox [disabled]="!editable" formControlName="scientificDegreeShortName"> </kendo-textbox>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Ученое звание" headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div [formGroupName]="rowIndex">
                  <kendo-textbox [disabled]="!editable" formControlName="academicStatus"> </kendo-textbox>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" [width]="60" *ngIf="editable">
              <ng-template kendoGridCellTemplate>
                <span class="alignCells">
                  <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full"></button>
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
          <div class="k-text-right">
            <button kendoButton [disabled]="!editable" class="edit-button k-mt-3" icon="plus" (click)="addFormGroup(i, members)">
              Добавить члена комиссии
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <button *ngIf="!isEditCategoryOrder && editable" class="w-240" kendoButton themeColor="success" (click)="addCommission()" icon="plus">
      Добавить комиссию
    </button>
  </div>
  <div class="form-buttons">
    <div class="w-240">
      <button kendoButton class="w-100" size="small" (click)="openpopup()">Отмена</button>
      <button
        kendoButton
        class="save-button w-130"
        size="small"
        themeColor="primary"
        [disabled]="!stateExamCommissionForm.valid || !editable || saving"
        (click)="saveOrder()"
      >
        Сохранить
      </button>
    </div>
    <button kendoButton size="small" themeColor="primary" icon="print" [disabled]="!stateExamCommissionForm.valid || !editable || saving" (click)="saveOrder(true)">
      Сохранить и распечатать
    </button>
  </div>
</form>
<div kendoDialogContainer></div>

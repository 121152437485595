<blanks-settings></blanks-settings>
<h1>Выдача бланков</h1>

<form [formGroup]="Form" novalidate>
    <div class="selectContent w-700 m-t20">
        <dd class="list">
        <span class="selectText">Структурное подразделение</span>
        <kendo-combobox class="w-400 m-r20"
            [disabled]="next"
            [data]="departments"
            textField="name"
            valueField="externalId"
            [valuePrimitive]="true"
            (valueChange)="changeDepartment($event)"
            formControlName="departmentId"
            [(ngModel)]="departmentId"
                            >
        </kendo-combobox>
        </dd>
    
        <dd class="list">
        <span class="selectText">Ответственное лицо</span>
        <kendo-dropdownlist class="w-400"
            [disabled]="next"
            [data]="filterPersons"
            textField="fio"
            valueField="externalId"
            [filterable]="true"
            (filterChange)="handleFilter($event)"
            [valuePrimitive]="true"
            (valueChange)="changePerson($event)"
            formControlName="personId"
            [(ngModel)]="personId"
                            >
        </kendo-dropdownlist>
        <span class="required m-l5">*</span>
        </dd>
    </div>

    <div class="label m-l300 m-t10" *ngIf="!notInformation && personId">
        <p>Бланков на руках: {{ blanksPersonData.availableBlanks}}</p>
        <p>в т.ч. использовано: {{ blanksPersonData.usedBlanks}}</p>
        <p>испорчено: {{ blanksPersonData.spoiledBlanks}}</p>
    </div>
    <div class="label m-l300 m-t10" *ngIf="notInformation && personId">
        Нет информации
    </div>


    <p class="blanks m-t20">Бланки</p>

    <div class="selectContent w-700 m-t20">
        <div class="selectContent w-700 m-t20">
            <dd class="list">
            <span class="selectText">Тип бланка</span>
            <kendo-dropdownlist class="w-400"
                [disabled]="next || !personId"
                [data]="blankTypes"
                textField="name"
                valueField="externalId"
                [valuePrimitive]="true"
                (valueChange)="onChangeType($event)"
                formControlName="dictBlankTypeId"
                [(ngModel)]="dictBlankTypeId"
                                >
            </kendo-dropdownlist>
            <span class="required m-l5">*</span>
            </dd>

            <dd class="list" *ngIf="countBlanks !== 0">
                <span class="selectText"></span>
                <span class="label w-400">В наличии: {{ countBlanks }} </span>
            </dd>
        
            <dd class="list">
            <span class="selectText">Шаблон</span>
            <kendo-dropdownlist class="w-400"
                [disabled]="next || !personId"
                [data]="blankTemplates"
                textField="name"
                valueField="externalId"
                [valuePrimitive]="true"
                (valueChange)="onChangeTemplate($event)"
                formControlName="dictBlankTemplateId"
                [(ngModel)]="dictBlankTemplateId"
                                >
            </kendo-dropdownlist>
            <span class="required m-l5">*</span>
            </dd>
        
            <dd class="list">
            <span class="selectText">Серия бланков</span>
            <kendo-dropdownlist class="w-400"
                [disabled]="next || !personId"
                [data]="series"
                (valueChange)="onChangeSeries($event)"
                formControlName="seriesName"
                [(ngModel)]="seriesName"
                                >
            </kendo-dropdownlist>
            <span class="required m-l5">*</span>
            </dd>
        
            <dd class="list">
            <span class="selectText">Номер бланка / первого бланка</span>
            <kendo-dropdownlist class="w-400"
                [disabled]="next || !personId"
                [data]="filterNumberBlanks"
                textField="number"
                valueField="number"
                [filterable]="true"
                (filterChange)="filterNumber($event)"
                [valuePrimitive]="true"
                (valueChange)="onChangeNumber($event)"
                formControlName="numberFirstBlank"
                [(ngModel)]="numberFirstBlank"
                                >
            </kendo-dropdownlist>
            <span class="required m-l5">*</span>
            </dd>
        
            <dd class="list">
            <span class="selectText">Количество бланков</span>
            <kendo-numerictextbox [ngClass]="{borderred: exceeds}"
                [disabled]="next || !personId"
                class="w-400"
                [min]="1"
                format="n0"
                [decimals]="0"
                (valueChange)="onChangeCount($event)"
                formControlName="count"
                [(ngModel)]="count">
            </kendo-numerictextbox>
            <span class="required m-l5">*</span>
            </dd>

            <dd class="list" *ngIf="exceeds">
                <span class="selectText"></span>
                <span class="error w-400 m-r20">Недостаточно бланков</span>
            </dd>
        </div>
    </div>
</form>

<div class="buttons m-t20 w-680" [ngClass]="{align: next}">
    <button kendoButton *ngIf="next" (click)="edit()">Изменить</button>
    <div>
        <button kendoButton (click)="cancel()">Отмена</button>
        <button kendoButton *ngIf="!next"
                themeColor="primary"
                class="m-l15"
                [disabled]="Form!.invalid || exceeds"
                (click)="nextStep()">
            Далее
        </button>
        <button kendoButton *ngIf="next"
                [disabled]="checkedBlanks()"
                themeColor="primary"
                class="m-l15"
                (click)="issue()">
            Выдать
        </button>
    </div>
</div>
<div class="parameters margins" *ngIf="next" >Будет закреплено бланков: {{countIssueBlanks}} шт
</div>

<div *ngIf="next">
    <kendo-grid
        class="m-t20"
        [kendoGridBinding]="issueBlanks"
    >

      <kendo-grid-column [width]="70"
                         headerClass="gridHeader dictionaries">
        <ng-template kendoGridHeaderTemplate>
          <div headerClass="gridHeader">
                <input
                type="checkbox"
                kendoCheckBox
                [checked]="allItemsChecked"
                (click)="selectAll(allItemsChecked)"
                />
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                [checked]="dataItem.checked"
                (change)="checkedChange(dataItem)"
                />
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="series"
            [width]="150"
            title="Серия"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.series }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="number"
            [width]="150"
            title="Номер"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.number }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="blankTypeName"
            [width]="400"
            title="Тип бланка"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{ dataItem.blankTypeName }}
          </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
</div>

<div class="buttons m-t20 w-700" [ngClass]="{align: next}" *ngIf="next">
    <button kendoButton *ngIf="next" (click)="edit()">Изменить</button>
    <div>
        <button kendoButton (click)="cancel()">Отмена</button>
        <button kendoButton *ngIf="next"
                [disabled]="checkedBlanks()"
                themeColor="primary"
                class="m-l15"
                (click)="issue()">
            Выдать
        </button>
    </div>
</div>
<div class="parameters margins" *ngIf="next" >Будет закреплено бланков: {{countIssueBlanks}} шт
</div>
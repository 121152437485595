import {Property} from "./property.model";

export class PublicationForm {
  id!: string;
  typeId!: string;
  subTypeId!: string;
  departments!: string[];
  name!: string;
  year!: number;
  categories!: string[];
  properties!: Property[];
  addedBy!: string;
  workFormId?: string;
  currentStatus!: number;
}

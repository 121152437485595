<div [ngSwitch]="tabSelector">
    <mfc-main-settings *ngSwitchCase="ApplicationConstructorTabsEnum.Main"
                       [editable]="editable"
                       [settings]="settings">
    </mfc-main-settings>
    <mfc-tabs-settings *ngSwitchCase="ApplicationConstructorTabsEnum.Tabs"
                       [editable]="editable">
    </mfc-tabs-settings>
    <mfc-approval-settings *ngSwitchCase="ApplicationConstructorTabsEnum.Approval"
                           [editable]="editable"
                           [documents]="settings?.applicationConstructorDocuments ?? []">
    </mfc-approval-settings>
</div>

import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  CellClickEvent,
  RowClassArgs
} from '@progress/kendo-angular-grid';
import {
  EducationPlanDetailsModels
} from 'src/app/models/education/education-plan-details-model';
import {
  EducationPlanDetailsService
} from 'src/app/services/education/EducationPlan/education-plan-details.service';
import {
  EducationUserAccessService
} from "../../../../services/useraccess/education-user-access.service";
import { DictComponentService } from 'src/app/services/education/DictComponent/dict-component.service';
interface ITableItem {
  title: string;
  field: string;
}
@Component({
  selector: 'base-education-plan',
  templateUrl: './base-education-plan.component.html',
  styleUrls: ['./base-education-plan.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class BaseEducationPlanComponent implements OnInit {
  @Input() educationPlanId: string | null = null;
  @Input() eduProgramId: string | null = null;

  constructor(
    private router: Router,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private userAccessService: EducationUserAccessService,
    private dictComponentService: DictComponentService,
  ) {}

  public planComponents: any = [];
  public typeDiscipline: any = [];

  ngOnInit(): void {}

  public disciplines: EducationPlanDetailsModels.EDiscipline[] = [];
  public summary: EducationPlanDetailsModels.EPlanSummary = {
    errorList: [],
    studentSummary: {
      rows: [] as EducationPlanDetailsModels.EStudentSummaryRow[],
    },
    hoursSummary: [],
  };
  public studentSummarySemesters: any[] = [];
  public typeWorksItems: ITableItem[] = [];
  public isLoading = false;
  public isErrorsVisible = false;
  public disciplineSelected: string[] = [];

  ngOnChanges() {
    if (!this.educationPlanId) {
      this.disciplines = [];
      return;
    }

    this.getAccessLevel(this.educationPlanId);

    this.isLoading = true;
    this.educationPlanDetailsService
      .getEduPlanDisciplines(this.educationPlanId)
      .subscribe((data) => {
        this.parseDisciplineTypeWorks(data.items);
      });
    this.educationPlanDetailsService
      .getEduPlanSummary(this.educationPlanId)
      .subscribe((summary: EducationPlanDetailsModels.EPlanSummary) => {
        this.summary = summary;
        this.studentSummarySemesters = summary.studentSummary.rows[0]?.semesters ?
          summary.studentSummary.rows[0].semesters.map(
            (semester) => semester.semestr
          ) :
          [];
          this.summary.studentSummary.rows.map((row: EducationPlanDetailsModels.EStudentSummaryRow) => {
            const sum = row.semesters.reduce((sum: number, current: { value: number; }) => sum + current.value, 0);
            row['total'] = sum === 0 ? '' : sum;
            if (sum !== 0 ) {
              if (Number.isInteger(sum as number)) {
                row['total'] = sum;
              } else {
                row['total'] = sum.toFixed(1);
              }
            }
          });
      });
  }

  public disciplinesClassCallback = (context: RowClassArgs) => {
    const dataLevel = context.dataItem.level;
    return {
      level0: dataLevel === 0,
      level1: dataLevel === 1,
      level2: dataLevel > 1,
      noActive: context.dataItem.active == false,
    };
  };

  insertTypeWorks(discipline: any) {
    if (discipline.typeWorks?.length) {
      discipline.typeWorks.map(
        (typeWork: EducationPlanDetailsModels.ETypeWork, index: number) => {
          discipline[`typeWork${index}`] = typeWork.value;
        }
      );
    }
    return {
      ...discipline,
      dictCycleId: discipline.cycleId,
      dictComponentId: discipline.componentId,
    };
  }

  parseDisciplineTypeWorks(
    items: EducationPlanDetailsModels.EPlanDiscipline[]
  ) {
    this.typeWorksItems = [];
    this.isLoading = false;
    if (!items || items.length === 0) {
      this.disciplines = [];
      return;
    }
    const disciplineExample = items[0];
    if (disciplineExample.typeWorks?.length) {
      const dynamicTableItems: ITableItem[] = [];
      disciplineExample.typeWorks.map((typeWork, index) => {
        dynamicTableItems.push({
          title: typeWork.shortName,
          field: `typeWork${index}`,
        });
      });
      this.typeWorksItems = dynamicTableItems;
    } else {
      this.typeWorksItems = [];
    }
    const disciplines: EducationPlanDetailsModels.EDiscipline[] = [];
    items.forEach((item) => {
      disciplines.push(this.insertTypeWorks(item));
    });
    this.disciplines = disciplines;

    if (this.educationPlanId) {
      this.dictComponentService.getEduPlanComponents(this.educationPlanId)
      .subscribe(
        (response) => {
          this.planComponents = response;
          let planComponent = this.planComponents;
          this.typeDiscipline = this.disciplines.filter(function(element, index, array) {
            return (element.level == 1 && planComponent.some((el:any) => el.dictComponentExternalId == element.dictComponentId) == false);
          });
      })    
    }

    if (this.educationPlanDetailsService.currentDiscipline.id) {
      this.disciplineSelected = [
        this.educationPlanDetailsService.currentDiscipline.id,
      ];
      setTimeout(() => {
        const selected = document.querySelectorAll('tr.k-state-selected');
        if (selected.length) {
          selected[0].scrollIntoView({
            block: 'center'
          });
        }
      }, 500);
    } else if (this.educationPlanDetailsService.currentDiscipline.dictComponentId){
        let text = this.educationPlanDetailsService.currentDiscipline.name;
        setTimeout(() => {
          let td = document.getElementsByTagName("td");
          let arraySelector = Array.from(td);
          let index = arraySelector.findIndex((el:any) => el.innerText == text);
          td[index].scrollIntoView({
            block: 'center'
          });
        }, 500);
    }
  }

  addDiscipline(discipline: EducationPlanDetailsModels.EDiscipline) {
    this.educationPlanDetailsService.setDiscipline(discipline);
    this.router.navigate([
      `/education/${this.eduProgramId}/plans/${this.educationPlanId}/add-discipline/${discipline.dictCycleId}/${discipline.dictComponentId}`,
    ]);
  }

  onEditDiscipline(event: CellClickEvent) {
    const discipline = event.dataItem;
    if (discipline.level < 2) return;
    this.educationPlanDetailsService.setDiscipline(discipline);
    this.router.navigate([
      `/education/${this.eduProgramId}/plans/${this.educationPlanId}/edit-discipline/${discipline.id}`,
    ]);
  }

  public editable ? : boolean;
  public editableFacultative ? : boolean;

  public getAccessLevel(educationPlanId: string): any {
    this.userAccessService.getPlanEditAccessLevel(educationPlanId)
      .subscribe(
        response => {
          this.editable = response.accessLevel;
          this.editableFacultative = response.electiveAccessLevel;
        }
      );
  }

  public ExistsDiscipline(value:any){
    let exists = true
    if (this.typeDiscipline.some((el: any) => el.dictComponentId == value)) exists = false
    return exists;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PagerSettings, RemoveEvent } from '@progress/kendo-angular-grid';
import { dictPagerSettings } from '../../../../models/dicts/pagerSettings.model';
import { ReleaseOrderService } from '../../../../services/gia/release-order.service';
import { DictReleaseOrderTypeEnum } from '../../../../models/gia/enums/dictReleaseOrderType.enum';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReleaseOrder, ReleaseOrderStudent } from '../../../../models/gia/orders/release-order.model';
import { DateFromUTCAsLocal, getDayEnd, TimeZoneFix } from '../../../../helpers/date-helper';
import { EducationService } from '../../../../services/gia/education.service';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { openDialog } from '../../../../helpers/dialogHelper';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { DownloadFile } from '../../../../helpers/downloadFile-helper';
import { TrainingLevelEnum } from '../../../../models/gia/enums/training-level.enum';
import { GiaTabsEnum } from '../../../../models/gia/enums/giaTabs.enum';
import { GiaUserAccessService } from '../../../../services/useraccess/gia-user-access.service';
import { GiaSettingService } from '../../../../services/gia/giaSetting.service';
import { settingsCategoryEnum } from '../../../../models/gia/enums/settingsCategory.enum';

@Component({
  selector: 'app-release-order-form',
  templateUrl: './release-order-form.component.html',
  styleUrls: ['./release-order-form.component.scss'],
})
export class ReleaseOrderFormComponent implements OnInit {
  public pagerSettings: PagerSettings = dictPagerSettings;

  public orderId: string = '';
  public trainingLevelSettings: (number | null)[] | null = null;
  public isNew: boolean = true;
  public pageSize = 20;
  public loading: boolean = false;
  public isReleaseChanges: boolean = false;
  public releaseOrder?: ReleaseOrder;
  public students: ReleaseOrderStudent[] = [];
  public trainingLevelEnum?: number;
  // public sort: SortDescriptor[] = [{ field: "studentFio", dir: "asc", }];
  public group: GroupDescriptor[] = [];
  public editable: boolean = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private releaseOrderService: ReleaseOrderService,
    private notificationService: NotificationsService,
    private educationService: EducationService,
    private userAccessService: GiaUserAccessService,
    private giaSettingService: GiaSettingService,
    private dialogService: DialogService,
  ) {
    this.orderId = activateRoute.snapshot.params['orderId'];
    this.isNew = !this.orderId;
    const storage = localStorage.getItem('diplomas_settings');
    if (storage !== null && this.isNew) {
      const data = JSON.parse(storage);
      this.releaseOrderForm.reset({
        graduateYear: data.yearAdmission,
        educationStandardId: data.standard,
        facultyId: data.faculty,
      });
    }

    this.students = history.state.students;
    this.students?.forEach((s) => (s.genitiveCaseFullName = null));
  }

  ngOnInit(): void {
    if (this.isNew) this.getStandardInfo();
    this.getOrder();
    this.getAccessLevel();
    this.getTrainingLevelSettingsByCategory(settingsCategoryEnum.genitiveCaseFullNameDisplay);
  }

  public getTrainingLevelSettingsByCategory(category: number) {
    this.giaSettingService.getTrainingLevelSettingsByCategory(category).subscribe({
      next: (response) => {
        this.trainingLevelSettings = response;
      },
    });
  }

  public checkIncludeTrainingLevelInGiaSetting(trainingLevel: number) {
    return this.trainingLevelSettings?.includes(null) || this.trainingLevelSettings == null || this.trainingLevelSettings?.includes(trainingLevel);
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        this.editable = response.giaSections?.some((_) => _ === GiaTabsEnum.приказы_о_выпуске || _ === null);
      },
      error: () => {},
    });
  }

  public addStudent() {
    this.router.navigateByUrl(`/gia/releaseorderform/${this.orderId}/add`).then();
  }

  public back() {
    if (
      (this.isNew && (this.releaseOrderForm.value.orderNumber || this.releaseOrderForm.value.orderDate || (this.students && this.students.length !== 0))) ||
      (!this.isNew &&
        (this.releaseOrderForm.value.orderNumber !== this.releaseOrder?.orderNumber ||
          this.students.length !== this.releaseOrderForm.value?.students.length ||
          +getDayEnd(DateFromUTCAsLocal(this.releaseOrder!.orderDate)) !== +getDayEnd(this.releaseOrderForm.value.orderDate)))
    ) {
      const dialog: DialogRef = openDialog(this.dialogService, 'Имеются несохраненные изменения, вы действительно хотите покинуть страницу?', 450, 200, 250);
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.navigate();
        }
      });
      return;
    }

    this.navigate();
  }

  public getStandardInfo() {
    this.educationService.getStandardInfo(this.releaseOrderForm.value.educationStandardId).subscribe((response) => {
      if (response !== null) this.trainingLevelEnum = response.trainingLevelEnum;
    });
  }

  public getOrder() {
    if (this.orderId)
      this.releaseOrderService.getOrder(this.orderId).subscribe((response) => {
        this.releaseOrder = response;

        this.releaseOrderForm.reset({
          releaseOrderId: this.orderId,
          orderDate: DateFromUTCAsLocal(response.orderDate),
          orderNumber: response.orderNumber,
          students: response.students,
        });
        this.trainingLevelEnum = response.trainingLevelEnum;
        this.students = response.students;
      });
  }

  public orderTypeChange(value: number) {
    this.isReleaseChanges = value === DictReleaseOrderTypeEnum.Changes;
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, 'Вы действительно хотите удалить обучающегося из приказа о выпуске?', 450, 200, 250);

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.students = this.students.filter((s) => s.studentId !== dataItem.studentId);
        this.students?.forEach((s, index) => s.assignedNumber = index + 1);
      }
    });
  }

  public releaseOrderForm: FormGroup = new FormGroup({
    releaseOrderId: new FormControl(null),
    graduateYear: new FormControl(0),
    educationStandardId: new FormControl(''),
    facultyId: new FormControl(''),
    orderNumber: new FormControl('', Validators.required),
    orderDate: new FormControl<Date | null>(null, Validators.required),
    students: new FormControl<[] | null>(null),
  });

  public save(print?: boolean) {

    if (this.checkIncludeTrainingLevelInGiaSetting(this.trainingLevelEnum!) && this.students.length !== 0 && this.students.some(x => !x.genitiveCaseFullName)) {
      this.notificationService.showError("Не все ФИО в родительном падеже заполнены!");
      return;
    }

    if (this.releaseOrderForm.value.orderDate)
      this.releaseOrderForm.value.orderDate = new Date(TimeZoneFix(this.releaseOrderForm.value.orderDate).toISOString());
    this.releaseOrderForm.value.students = this.students;
    if (this.isNew) {
      this.releaseOrderService.addAdmissionOrder(this.releaseOrderForm.value).subscribe({
        next: (response) => {
          this.notificationService.showSuccess('Сохранено');
          if (print) this.printOrder(response);
          this.navigate();
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        },
      });
    } else {
      this.releaseOrderService.updateAdmissionOrder(this.releaseOrderForm.value).subscribe({
        next: (response) => {
          this.notificationService.showSuccess('Сохранено');
          if (print) this.printOrder(response);
          this.navigate();
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        },
      });
    }
  }

  private printOrder(orderId: string) {
    this.releaseOrderService.printOrder(orderId).subscribe({
      next: (value) => {
        DownloadFile(value);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }

  public navigate() {
    const storage = localStorage.getItem('diplomas_settings');
    if (storage !== null) {
      const data = JSON.parse(storage);
      this.router.navigateByUrl(`/gia/releaseorder/${data.standard}/${data.faculty}/${data.yearAdmission}/${data.studyForm}`).then();
      return;
    }
    this.router.navigateByUrl(`/gia/releaseorder`).then();
  }

  protected readonly TrainingLevelEnum = TrainingLevelEnum;
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DictWorkStudyPlanType } from 'src/app/models/education/dictWorkStudyPlanType.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictWorkStudyPlanTypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictWorkStudyPlanType}`;

  constructor(private http: HttpClient) { }

  //Get all WorkStudyPlanTypes
  public getAllWorkStudyPlanTypes(): Observable<DictWorkStudyPlanType[]> {
    return this.http.get<DictWorkStudyPlanType[]>(this.baseUrl + '/GetWorkStudyPlanTypes/');
  }

  // Add WorkStudyPlanType
  addWorkStudyPlanType(dictWorkStudyPlanType: DictWorkStudyPlanType): Observable<DictWorkStudyPlanType> {
    return this.http.post<DictWorkStudyPlanType>(this.baseUrl + '/CreateWorkStudyPlanType/' , dictWorkStudyPlanType)
      .pipe(catchError(this.handleError));
  }

  // Edit WorkStudyPlanType
  updateWorkStudyPlanType(dictWorkStudyPlanType: DictWorkStudyPlanType): Observable<DictWorkStudyPlanType> {
    return this.http.put<DictWorkStudyPlanType>(this.baseUrl + '/UpdateWorkStudyPlanType/' + dictWorkStudyPlanType.dictWorkStudyPlanTypeExternalId, dictWorkStudyPlanType)
      .pipe(catchError(this.handleError));
  }

  // Remove WorkStudyPlanType
  deleteWorkStudyPlanType(id: string): Observable<DictWorkStudyPlanType> {
    return this.http.delete<DictWorkStudyPlanType>(this.baseUrl + '/DeleteWorkStudyPlanType/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

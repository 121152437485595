import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from '../services/token.service';
import { Location } from '@angular/common';
import {firstValueFrom, lastValueFrom} from "rxjs";
import {RegulationService} from "../services/oferta/regulation.service";
import {UserSignatureService} from "../services/oferta/user-signature.service";
import {CreateQuery} from "../helpers/createQuery-helper";
import {SignatureTypeEnum} from "../models/oferta/enums/dictSignatureType.enum";

@Injectable()
export class AuthGuard implements CanActivate {

  private userHasSignature: boolean = true;
  private personHasRightsForReinforced: boolean = true;

  constructor(
    private jwtHelper: JwtHelperService,
    private router: Router,
    private location: Location,
    private tokenStore: TokenStorageService,
    private regulationService: RegulationService,
    private userSignatureService: UserSignatureService
  ) {}

  private containsUrl(url: string|undefined): boolean {
    if (!url || !this.regulationService.reinforcedServiceUrls.value) {
      return false;
    }
    var serviceUrl = url?.split('/');
    return this.regulationService.reinforcedServiceUrls.value.some(path => path.includes(serviceUrl[0]));
  }

  private async personHasRainforcedSignature() {
    this.userHasSignature = await firstValueFrom(this.userSignatureService.personHasSignature(
      CreateQuery({signatureTypeEnum: SignatureTypeEnum.Reinforced})
    ));
  }

  private async personHasRightsForReinforcedModule() {
    this.personHasRightsForReinforced = await this.userSignatureService.personHasRightsForReinforcedModule(
      CreateQuery({UserSignatureTypeEnum: SignatureTypeEnum.Reinforced})
    );
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
  ): Promise<boolean> {
    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    let user;
    if (token !== null && !this.jwtHelper.isTokenExpired(token)) {
      user = this.jwtHelper.decodeToken(token);

      if (!this.regulationService.reinforcedServiceUrls.value) {
        try {
          const value = await lastValueFrom(this.regulationService.getAllReinforcedModulesUrl());
          this.regulationService.reinforcedServiceUrls.next(value);
        } catch (error) {
          console.log('Error in component', error);
          return true;
        }
      }

      if (this.containsUrl(path)) {

        if (localStorage.getItem('switchPerson') === 'true')
          return true;

        try {
          await this.personHasRainforcedSignature();
          if (this.userHasSignature === false) {
            localStorage.setItem('reinforced_path', path!);
            this.router.navigate(['/oferta/joiningToReinforcedRegulation']).then();
            return false;
          }

          await this.personHasRightsForReinforcedModule();

        } catch (error) {
          return true;
        }
        // Если предоставил документы вовремя или изменили дату присоединения вручную или еще есть время для того, чтобы принести документы
        if (!this.personHasRightsForReinforced){
          //Редирект на страницу с блокировкой
          localStorage.setItem('last_url', this.location.path());
          this.router.navigate([`/oferta/reinforcedmodulenotification`]).then();
        }

        return true;
      }

      if (token && !this.jwtHelper.isTokenExpired(token) && user.person_id !== undefined) {
        return true;
      }

      if (!token || this.jwtHelper.isTokenExpired(token) || !user.person_id) {
        localStorage.setItem('last_url', this.location.path());
      }

      if (user.person_id == null) {
        alert('Ошибка входа, либо нет доступа');
      }

      this.router.navigateByUrl('/login');
      return false;
    }

    // Ensure that we return false if no valid token or user is present
    this.router.navigateByUrl('/login');
    return false;
  }
}

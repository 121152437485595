<contingent-header></contingent-header>
<h1>Дополнительные поля</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="allAdditionalfields" (remove)="removeHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true">

    <ng-template kendoGridToolbarTemplate position="both">
      <button kendoGridAddCommand size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()"
                size="small">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup?.invalid!"
                themeColor="primary"
                (click)="saveCurrent()"
                size="small">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictAdditionalFieldId" title="dictAdditionalFieldId"> </kendo-grid-column> -->
    <kendo-grid-column field="additionalFieldName"
                       title="Название поля"
                       [width]="300">
    </kendo-grid-column>

    <kendo-grid-column field="fieldGroupName"
                       title="Название группы полей"
                       [width]="300">
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="60">
      <ng-template kendoGridCellTemplate>
        <button kendoGridRemoveCommand
                themeColor="secondary"

                icon="delete"
                fillMode="flat"
                size="small"
                rounded="full">
        </button>

      </ng-template>
    </kendo-grid-column>

  </kendo-grid>

</div>
<div kendoDialogContainer></div>

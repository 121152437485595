import {GiaTabsEnumList} from "../enums/giaTabs.enum";

export interface AccessRightSection {
  title: string,
  value?: GiaTabsEnumList | string,
}

export const accessRightSections: AccessRightSection[] = [
  {
    title: "Состав ГЭК",
    value: GiaTabsEnumList.состав_ГЭК
  },
  {
    title: "Приказы о допуске",
    value: GiaTabsEnumList.приказы_о_допуске
  },
  {
    title: "Приказы о выпуске",
    value: GiaTabsEnumList.приказы_о_выпуске
  },
  {
    title: "Дипломы",
    value: GiaTabsEnumList.дипломы
  },
  {
    title: "Протокол ГИА",
    value: GiaTabsEnumList.протокол_ГИА
  },
];

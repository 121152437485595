<blanks-settings></blanks-settings>
<h1>Статусы</h1>

<kendo-grid [kendoGridBinding]="blankStatuses"
            [loading]="loading"
            [pageable]="pagerSettings"
            [pageSize]="pageSize"
            (cellClick)="editRow($event)"
            >

    <ng-template *ngIf="isInEditingMode" kendoGridToolbarTemplate position="both">
        <button kendoButton
                (click)="onCancel()">
            Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveRow()">
            Сохранить
        </button>
    </ng-template>

    <ng-template kendoGridLoadingTemplate>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column 
        [width]="400"
        headerClass="gridHeader dictionaries"
        field="name"
        title="Наименование статуса"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ dataItem.name }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-textbox [formControl]="formGroup.get('name')">
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="80" headerClass="gridHeader dictionaries" field="color" title="Цвет статуса">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="k-display-flex k-justify-content-center">
            <div class="color-block" [style.background]="dataItem.color"></div>
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-colorpicker [formControl]="formGroup.get('color')" format="hex">
          </kendo-colorpicker>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        [width]="300"
        headerClass="gridHeader dictionaries"
        field="order"
        title="Порядковый номер"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.order }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-numerictextbox
            [value]="dataItem.order"
            [min]="1"
            format="n0"
            [decimals]="0"
            [formControl]="formGroup.get('order')">
          </kendo-numerictextbox>
        </span>
      </ng-template>
    </kendo-grid-column>
    
</kendo-grid>
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CaseName } from '../../models/contingent/casename.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class CasenameService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.casename}`;

  constructor(private http: HttpClient) { }

  //Get all casename
  public getAllcasename(): Observable<CaseName[]> {
    return this.http.get<CaseName[]>(this.baseUrl);
  }

  //Get By Id casename
  getByIdcasename(id: string): Observable<CaseName> {
    return this.http.get<CaseName>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getStudentCaseName(id: any): Observable<CaseName> {
    return this.http.get<CaseName>(this.baseUrl + '/getcasenamebystudpersonid/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add casename
  addcasename(casename: CaseName): Observable<CaseName> {
    return this.http.post<CaseName>(this.baseUrl, casename)
      .pipe(catchError(this.handleError));
  }

  //Delete casename
  deletecasename(id: string, studPersonId : Guid|undefined): Observable<CaseName> {
    return this.http.delete<CaseName>(this.baseUrl + '/' + id+ '/' + studPersonId)
      .pipe(catchError(this.handleError));
  }

  //Edit casename
  updatecasename(casename: CaseName): Observable<CaseName> {
    return this.http.put<CaseName>(this.baseUrl + '/' + casename.caseNameExternalId, casename)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

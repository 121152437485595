<mfc-settings></mfc-settings>
<h1>Форма заявки</h1>

<div class="buttons">
  <div class="group">
    <button kendoButton (click)="goBack()">Закрыть</button>
    <ng-container [ngSwitch]="status?.enum">
      <ng-container *ngSwitchCase="NumericApplicationConstructorStatusesEnum.Draft">
        <button kendoButton (click)="onCopy()">Скопировать в новое</button>
        <button kendoButton [primary]="true"
                (click)="changeStatus(NumericApplicationConstructorStatusesEnum.Published)">
          Опубликовать
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="NumericApplicationConstructorStatusesEnum.Published" [ngSwitch]="hasApplications">
        <button kendoButton themeColor="primary"
                *ngSwitchCase="false"
                (click)="changeStatus(NumericApplicationConstructorStatusesEnum.Draft)">
          Снять с публикации
        </button>
        <ng-container *ngSwitchCase="true">
          <button kendoButton themeColor="primary"
                  (click)="createDraft()">
            Создать черновик
          </button>
          <button kendoButton themeColor="primary"
                  (click)="changeStatus(NumericApplicationConstructorStatusesEnum.Archive)">
            Архивировать
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <button class="deny delete-button" kendoButton *ngIf="allowDelete" (click)="onDelete()">
    Удалить
  </button>
</div>

<span *ngIf="!isAdd" class="status">
  <h3>Статус</h3>
  <h3><span class="status-name">{{ status?.name }}</span> ({{statusDate | date:'dd.MM.yyyy HH:mm'}})</h3>
</span>

<kendo-tabstrip class="custom-tabs" (tabSelect)="onTabChange($event)">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <kendo-tabstrip-tab [selected]="tab.selector === currentTab" [title]="tab.name" [disabled]="isAdd && i > 0">
      <ng-template kendoTabContent>
        <mfc-application-constructor-tab
                [tabSelector]="tab.selector"
                [tabName]="tab.name"
                [editable]="editable"
                [settings]="applicationConstructor">
        </mfc-application-constructor-tab>
      </ng-template>
    </kendo-tabstrip-tab>
  </ng-container>
</kendo-tabstrip>

<div kendoDialogContainer></div>

<h1>{{ standardName }}</h1>
<div class="date">
    <span>Дата выдачи дипломов:</span>
    <div *ngIf="!dateEdit" class="date">
        <span class="blue m-l15">{{ issueDate | date:'dd.MM.yyyy'}}</span>
        <button kendoButton icon="edit" class="change m-l15" fillMode="outline" (click)="changeDate()" *ngIf="editable">Изменить</button>
    </div>
    <div *ngIf="dateEdit">
        <kendo-datepicker class="datepicker m-l15" format="dd.MM.yyyy" [(ngModel)]="issueDateSave"></kendo-datepicker>
        <button kendoButton class="change m-l15" fillMode="outline" (click)="saveDate()">Сохранить</button>
    </div>
</div>
<button kendoButton class="m-t20" *ngIf="!massData && editable" (click)="changeMassData()">Массовая смена данных</button>
<button kendoButton class="m-t20" *ngIf="massData" (click)="dataDiploms()">Закрыть форму массовой смены данных</button>

<kendo-grid #grid
    class="m-t20"
    [kendoGridBinding]="diploms"
    [loading]="loading"
    [filterable]="!massData"
    [sortable]="!massData"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip"
    (cellClick)="cellClickHandler($event)"
    [pageable]="{
        buttonCount: buttonCount,
        info: info,
        type: type,
        pageSizes: pageSizes,
        previousNext: previousNext,
        position: position
    }"
>
    <ng-template kendoGridLoadingTemplate>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>

    <kendo-grid-column field="serialNumber"
                title="№ п/п"
                [width]="90"
                [filterable]="false"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.serialNumber}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studentFio"
                title="ФИО обучающегося"
                [width]="200"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells leftAlignment">
                {{ dataItem.studentFio }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="educationGroupName"
                title="Ак. группа"
                [width]="250"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.educationGroupName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="admissionYear"
                title="Год поступления"
                [width]="150"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.admissionYear }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column-group headerClass="gridHeader" title="Предыдущее образование" *ngIf="!massData">
        <kendo-grid-column field="previousEducationIssueYear"
                    title="Год выдачи документа"
                    [width]="120"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-cell>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.previousEducationIssueYear}}
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="previousEducationDocTypeName"
                    title="Тип документа"
                    [width]="150"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="educationDocTypes" textField="previousEducationDocTypeName" valueField="previousEducationDocTypeName">
                </filter-dropdown>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.previousEducationDocTypeName }}
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>

    <kendo-grid-column-group headerClass="gridHeader dictionaries" title="Диплом" *ngIf="!massData">
        <kendo-grid-column field="diplomaTypeName"
                    title="Тип"
                    [width]="150"
                    headerClass="gridHeader dictionaries aligncenter">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="diplomTypes" textField="diplomaTypeName" valueField="diplomaTypeName">
                </filter-dropdown>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.diplomaTypeName }}
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="diplomaIssueDate"
                    title="Дата выдачи"
                    [width]="180"
                    filter="date" format="dd.MM.yyyy"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.diplomaIssueDate | date:'dd.MM.yyyy' }}
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="diplomaRegisterNumber"
                    title="Рег. номер"
                    [width]="100"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-cell>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.diplomaRegisterNumber }}
                </span>
            </ng-template>
        </kendo-grid-column>

   <!--     <kendo-grid-column field="dictStatusName"
                    title="Статус"
                    [width]="150"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="statuses" textField="dictStatusName" valueField="dictStatusName">
                </filter-dropdown>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.dictStatusName }}
                </span>
            </ng-template>
        </kendo-grid-column>-->

        <kendo-grid-column field="dictFillingDiplomaStatusName"
                    title="Статус заполнения"
                    [width]="150"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="fillingStatuses" textField="dictFillingDiplomaStatusName" valueField="dictFillingDiplomaStatusName">
                </filter-dropdown>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.dictFillingDiplomaStatusName }}
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>

    <kendo-grid-column field="averageGrade"
                title="Средний балл"
                [width]="160"
                headerClass="gridHeader dictionaries"
                >
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-numeric-filter-cell              
              changeValueOnScroll="false"
              [format]="getFormat(filter)"
              [decimals]="2"
              [column]="column"
              [filter]="filter">
            </kendo-grid-numeric-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ formatNumber(dataItem.averageGrade) }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <!--Массовая смена данных-->

    <kendo-grid-column field="massDate"
        *ngIf="massData"
        [width]="180"
        [resizable]="false"
        headerClass="gridHeader dictionaries">
        <ng-template kendoGridHeaderTemplate>
        <div headerClass="gridHeader">
            <p>Массовая смена даты выдачи</p>
            <div class="m-t10">
                <input
                type="checkbox"
                kendoCheckBox
                [checked]="allItemsCheckedDate"
                (click)="selectAll(allItemsCheckedDate, massDataKey.date)"
                />
                <span class="label m-l15">Выбрать всех</span>
            </div>
        </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                [checked]="checked(dataItem.studentId, massDataKey.date)"
                (change)="checkedChange(dataItem.studentId, massDataKey.date)"
                />
            </span>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
        <div class="footer">
            <kendo-label text="Выберите значение">
                <kendo-datepicker class="datepicker m-t10 w-145" format="dd.MM.yyyy" [(ngModel)]="issueDateAll">
                </kendo-datepicker>
            </kendo-label>
            <button kendoButton class="apply m-t10" [disabled]="this.arrayDate.length ==0 || !issueDateAll" (click)="applyToAll(massDataKey.date)">
                Применить
            </button>
        </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
            *ngIf="massData"
            [width]="180"
            [resizable]="false"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridHeaderTemplate>
        <div headerClass="gridHeader">
            <p>Массовая смена статуса</p>
            <div class="m-t10">
                <input
                type="checkbox"
                kendoCheckBox
                [checked]="allItemsCheckedStatus"
                (click)="selectAll(allItemsCheckedStatus, massDataKey.status)"
                />
                <span class="label m-l15">Выбрать всех</span>
            </div>
        </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                [checked]="checked(dataItem.studentId, massDataKey.status)"
                (change)="checkedChange(dataItem.studentId, massDataKey.status)"
                />
            </span>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
        <div class="footer">
            <kendo-label text="Выберите значение">
                <kendo-dropdownlist class="m-t10 w-145"
                    [data]="massFillingStatuses"
                    textField="name"
                    valueField="id"
                    [valuePrimitive]="true"
                    [(ngModel)]="fillingStatusAll">
                </kendo-dropdownlist>
            </kendo-label>
            <button kendoButton class="apply m-t10" [disabled]="this.arrayStatus.length ==0 || !fillingStatusAll" (click)="applyToAll(massDataKey.status)">
                Применить
            </button>
        </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
            *ngIf="massData"
            [width]="180"
            [resizable]="false"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridHeaderTemplate>
        <div headerClass="gridHeader">
            <p>Массовая смена серии</p>
            <div class="m-t10">
                <input
                type="checkbox"
                kendoCheckBox
                [checked]="allItemsCheckedSeries"
                (click)="selectAll(allItemsCheckedSeries, massDataKey.series)"
                />
                <span class="label m-l15">Выбрать всех</span>
            </div>
        </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                [checked]="checked(dataItem.studentId, massDataKey.series)"
                (change)="checkedChange(dataItem.studentId, massDataKey.series)"
                />
            </span>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
        <div class="footer">
            <kendo-label text="Внесите значение">
                <kendo-textbox class="m-t10 w-145" [(ngModel)]="seriesAll"></kendo-textbox>
            </kendo-label>
            <button kendoButton class="apply m-t10" [disabled]="this.arraySeries.length ==0 || !seriesAll" (click)="applyToAll(massDataKey.series)">
                Применить
            </button>
        </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
            *ngIf="massData"
            [width]="180"
            [resizable]="false"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridHeaderTemplate>
        <div headerClass="gridHeader">
            <p>Массовая смена номера</p>
            <div class="m-t10">
                <input
                type="checkbox"
                kendoCheckBox
                [checked]="allItemsCheckedNumber"
                (click)="selectAll(allItemsCheckedNumber, massDataKey.number)"
                />
                <span class="label m-l15">Выбрать всех</span>
            </div>
        </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                [checked]="checked(dataItem.studentId, massDataKey.number)"
                (change)="checkedChange(dataItem.studentId, massDataKey.number)"
                />
            </span>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
        <div class="footer">
            <kendo-label text="Внесите значение">
                <kendo-textbox class="m-t10 w-145" [(ngModel)]="numberAll"></kendo-textbox>
            </kendo-label>
            <button kendoButton class="apply m-t10"  [disabled]="this.arrayNumber.length ==0 || !numberAll" (click)="applyToAll(massDataKey.number)">
                Применить
            </button>
        </div>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
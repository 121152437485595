import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RetakeModel } from '../../../models/middlecontrol/retake.model';
import { RetakeService } from '../../../services/middlecontrol/retake.service';
import { SemesterYearsModel } from '../../../models/middlecontrol/semesterYears.model';

@Component({
  selector: 'app-retake',
  templateUrl: './retake.component.html',
  styleUrls: ['./retake.component.scss'],
})
export class RetakeComponent implements OnInit {
  @Input() studentName: string = '';
  @Input() studentId: string = '';
  @Input() semester: SemesterYearsModel | null = null;
  @Output() onComeback: EventEmitter<void> = new EventEmitter();
  @Output() studentIdChange: EventEmitter<string> = new EventEmitter();
  @Output() studentNameChange: EventEmitter<string> = new EventEmitter();

  public dataGrid: RetakeModel[] = [];
  public isLoading = false;
  constructor(private retakeService: RetakeService) {}

  ngOnInit(): void {
    if (this.studentId && this.semester?.semesterNum) {
      this.isLoading = true;
      this.retakeService
        .getDisciplineData(this.studentId, this.semester.semesterNum)
        .subscribe((response) => {
          response.forEach(
            (retake) =>
              (retake.markDate = this.retakeService.formateDateStringFromUTC(
                retake.markDate
              ))
          );
          this.dataGrid = response;
          this.isLoading = false;
        });
    }
  }

  ngOnDestroy() {
    this.studentIdChange.emit('');
    this.studentNameChange.emit('');
  }

  public onGoBack(): void {
    this.onComeback.emit();
  }
}

import {Guid} from "guid-typescript";

export class CardReestr {
  reestrId!: Guid;
  status: string = '';
  bankDocId?: Guid;
  dateBunkRequest?: Date;
  reestrNumber?: number;
  reestrDate: Date = new Date();
  salaryProjectId!: Guid;
  isFormingReestr?: boolean = true;
}

export class UpdateCardReestr {
  cardReestrId!: Guid;
  reestrNumber!: number;
  reestrDate!: Date;
  salaryProjectId!: Guid;
}

import {Component, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AddEvent, EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { Faculty } from 'src/app/models/education/faculty.model';
import { Kafedra } from 'src/app/models/education/kafedra.model';
import { EducationUserAccess } from 'src/app/models/useraccess/education/educationuseraccess.model';
import { FacultyService } from 'src/app/services/education/Faculty/faculty.service';
import { KafedraService } from 'src/app/services/education/Kafedra/kafedra.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';
import {PersonService} from "../../../services/education/Person/person.service";
import {Persons} from "../../../models/education/person.model";
import {TrainingLevelService} from "../../../services/education/TrainingLevel/traininglevel.service";
import {TrainingLevel} from "../../../models/education/traininglevel.model";
import {arrayRewrite, DisplayValuesInEditMode, valueChange, valueChangeBySourceName} from "../../../helpers/multiselect-helper";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {SortDescriptor} from "@progress/kendo-data-query";
import {isChildOf} from "../../../helpers/elementRef-helper";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {getData$} from "../../../../environments/environment";
import {DisplayShortNameForDuplicateKafedras} from "../../../helpers/array-helper";
import {FilialService} from "../../../services/education/Filial/filial.service";
import {Filial} from "../../../models/education/filial.model";

@Component({
  selector: 'app-education-access',
  templateUrl: './education-access.component.html',
  styleUrls: ['./education-access.component.scss']
})
export class EducationAccessComponent implements OnInit {
  public isAdmin: boolean;
  public virtual: any = {
    itemHeight: 28,
  };

  public dataSources: any[] = ['trainingLevels', 'faculties', 'kafedras', 'educationPlanStatuses', 'filials'];

  public trainingLevelsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public kafedrasEdit: any[] = [];
  public educationPlanStatusesEdit: any[] = [];
  public filialsEdit: any[] = [];

  public trainingLevels: TrainingLevel[] = [];
  public faculties: Faculty[] = [];
  public kafedras: Kafedra[] = [];
  public persons: Persons[] = [];
  public filials: Filial[] = [];
  public accesses: EducationUserAccess[] = [];

  // Filters
  public filteredData: any = {}

  public educationPlanStatuses: any[] = [
    {educationPlanStatusId: "", educationPlanStatusName: 'Все'},
    {educationPlanStatusId: 1, educationPlanStatusName: 'В разработке'},
    {educationPlanStatusId: 2, educationPlanStatusName: 'Утверждён'},
    {educationPlanStatusId: 3, educationPlanStatusName: 'Закрыт'},
  ]

  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]

  public dropdownOptions: any[] = [
    {id: 1, text: 'Чтение' },
    {id: 2, text: 'Запись' }
  ]

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;
  public sort: SortDescriptor[] = [{ field: "personName", dir: "asc" }];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public loading = false;

  public opened = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: any[] = [];

  constructor(private renderer: Renderer2,
              private dialogService: DialogService,
              private traininglevelService: TrainingLevelService,
              private kafedraService: KafedraService,
              private facultyService: FacultyService,
              private personService: PersonService,
              private notificationService: NotificationsService,
              private userAccessService: EducationUserAccessService,
              private filialService: FilialService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  ngOnInit(): void {
    getData$.subscribe(() => { this.getAccess();})
    this.getPersons();
    this.getAllFaculties();
    this.getAllKafedras();
    this.getAllTrainingLevels();
    this.getAllFilials();
  }

  // Get access
  public getAccess() {
    this.loading = true;
    this.userAccessService.getAccess()
      .subscribe(
        response => {
          this.loading = false;
          this.accesses = response;
          if (this.accesses) {
            for (let i = 0; i < this.accesses.length; i++) {
              valueChangeBySourceName(this.accesses[i].trainingLevels,
                "trainingLevelExternalId", null, "");
              valueChangeBySourceName(this.accesses[i].trainingLevels,
                "trainingLevelName", null, "Все");
              valueChangeBySourceName(this.accesses[i].educationPlanStatuses,
                "educationPlanStatusId", null, "");
              valueChangeBySourceName(this.accesses[i].educationPlanStatuses,
                "educationPlanStatusName", null, "Все");
              valueChangeBySourceName(this.accesses[i].kafedras,
                "kafedraId", null, "");
              valueChangeBySourceName(this.accesses[i].kafedras,
                "kafedraName", null, "Все");
              valueChangeBySourceName(this.accesses[i].faculties,
                "facultyId",  null, "");
              valueChangeBySourceName(this.accesses[i].faculties,
                "facultyShortName",  null, "Все");
              valueChangeBySourceName(this.accesses[i].filials,
                "filialId",  null, "");
              valueChangeBySourceName(this.accesses[i].filials,
                "filialShortName",  null, "Все");
              DisplayShortNameForDuplicateKafedras(this.accesses[i].kafedras);
            }
          }
          this.gridData = this.accesses;
        }, error => {
          this.loading = false;
          }
      );
  }

  // Faculties
  public getAllFaculties() {
    this.facultyService.getAllFaculties()
    .subscribe(
      response => {
        this.faculties = response;
        if (this.faculties) {
          let item = new Faculty();
          item.facultyId = "";
          item.facultyName = 'Все';
          item.facultyShortName = 'Все';
          item.facultyFullName = 'Все';
          this.faculties.unshift(item);
        }
      }
    );
  }

  // Kafedra
  public getAllKafedras() {
    this.kafedraService.getAllKafedras()
    .subscribe(
      response => {
        this.kafedras = DisplayShortNameForDuplicateKafedras(response);
        if (this.kafedras) {
          let item = new Kafedra();
          item.kafedraId = "";
          item.kafedraName = 'Все';
          this.kafedras.unshift(item);
        }
      }
    );
  }

  //DictTrainingLevels
  public getAllTrainingLevels() {
    this.traininglevelService.getAllTrainingLevels()
      .subscribe(
        response => {
          this.trainingLevels = response;
          if (this.trainingLevels) {
            let item = new TrainingLevel();
            item.trainingLevelExternalId = "";
            item.trainingLevelName = 'Все';
            this.trainingLevels.unshift(item);
          }
        }
      );
  }

  // Get persons
  public getPersons() {
    this.personService.getAllPerson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  //Get all filials
  public getAllFilials() {
    this.filialService.getAllFilials()
      .subscribe(
        response => {
          this.filials = response;
          if (this.filials) {
            let item = new Filial();
            item.filialId = "";
            item.filialShortName = 'Все';
            this.filials.unshift(item);
          }
        }
      );
  }

  getList(dataItem: any, key: any, source?: any) {
    let string = "";
    for(let i=0; i < dataItem.length; i++) {
      // Случай для статусов УП
      if(source === 'plan') {
        switch(dataItem[i][key]) {
          case 'Все':
            string += 'Все' + ', ';
            break;
          case 'InDeveloping':
            string += 'В разработке' + ', ';
            break;
          case 'Approved':
            string += 'Утверждён' + ', ';
            break;
          case 'Closed':
            string += 'Закрыт' + ', ';
            break;
        }
      }
      else {
        string += dataItem[i][key] + ', ';
      }
    }
    return string.slice(0, -2);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.trainingLevelsEdit = [];
    this.facultiesEdit = [];
    this.educationPlanStatusesEdit = [];
    this.kafedrasEdit = [];
    this.filialsEdit = [];

    this.formGroup = formGroup({
      id: 0,
      personId: '',
      educationPlanStatuses: '',
      filials: '',
      trainingLevels: '',
      kafedras: '',
      faculties: '',
      accessLevel: 1,
      userAccessLevel: false,
      localSignatoryAccessLevel: false,
      dictAccessLevel: false,
      electiveAccessLevel: false,
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Start Editing
  public editHandler({sender, rowIndex, dataItem}: EditEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      //this.saveCurrent();
      return;
    }

    const targetKey: any[] = ['trainingLevelExternalId', 'facultyId','kafedraId', 'educationPlanStatusId', 'filialId'];
    // Вывод данных в редактирование
    for(let i = 0; i < this.dataSources.length; i++) {
      this.filteredData[this.dataSources[i]] = (this as any)[this.dataSources[i]];
      (this as any)[`${this.dataSources[i]}Edit`] =
          DisplayValuesInEditMode((dataItem[this.dataSources[i]]), [targetKey[i]]);
    }

    this.isLine = true;
    //this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if(this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }

      for(let i=0; i < this.dataSources.length; i++) {
        valueChange(this.formGroup.value[this.dataSources[i]], "", null);
      }
      this.userAccessService.saveAccess(this.formGroup.value)
      .subscribe(
        response => {
          this.getAccess();
          this.notificationService.showSuccess("Добавлено");
        },
        error => {
          this.notificationService.showError("Не удалось добавить запись");
        }
      );
    }
    this.closeEditor(this.grid);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  clearData() {
    this.gridData = this.accesses;
    this.searchFIO = ''
  }

  filterData(dataItem: any) {
    this.gridData = this.accesses.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.personName} из настроек доступа`);
    this.opened = dialogResultHelper(dialog.result, this.userAccessService.deleteAccess(dataItem.id), this.notificationService);
  }

  public valueChange(value: any, editItem: any) {
    (this as any)[`${editItem}`] = arrayRewrite(value);
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    let element = (event as any).target;
    this.dataSources.forEach((item) => {
      if(isChildOf(element, 'faculties')) {
        this.filteredData.faculties = (this.isInEditingMode && this.filialsEdit.length > 0 && !this.filialsEdit.includes("")) ?
          this.faculties.filter(x => x.filialId == "" || this.filialsEdit.includes(x.filialId?.toString())) :
          this.faculties;
      } else if(isChildOf(element, item)) {
        this.filteredData[item] = (this as any)[item];
      }
    })
  }

  public onTagClick(items: any, sourceName: string) {
    this.filteredData[sourceName] = items;
  }
}

const formGroup = (dataItem:
  {
    id?: any;
    personId?: any;
    educationPlanStatuses?: any;
    filials?: any;
    trainingLevels?: any;
    kafedras?: any;
    faculties?: any;
    accessLevel?: any;
    userAccessLevel?: boolean
    dictAccessLevel?: boolean
    electiveAccessLevel?: boolean
    localSignatoryAccessLevel?: boolean;
  }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    educationPlanStatuses: new FormControl(dataItem.educationPlanStatuses, Validators.required),
    filials: new FormControl(dataItem.filials, Validators.required),
    trainingLevels: new FormControl(dataItem.trainingLevels, Validators.required),
    kafedras: new FormControl(dataItem.kafedras, Validators.required),
    faculties: new FormControl(dataItem.faculties, Validators.required),
    accessLevel: new FormControl(dataItem.accessLevel, Validators.required),
    userAccessLevel: new FormControl(dataItem.userAccessLevel, Validators.required),
    dictAccessLevel: new FormControl(dataItem.dictAccessLevel, Validators.required),
    electiveAccessLevel: new FormControl(dataItem.electiveAccessLevel, Validators.required),
    localSignatoryAccessLevel: new FormControl(dataItem.localSignatoryAccessLevel, Validators.required)
  });

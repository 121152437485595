import {Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {hasClass, isChildOf} from '../../../../../helpers/elementRef-helper';
import {openDialog} from "../../../../../helpers/dialogHelper";
import {lastValueFrom, Subscription} from "rxjs";
import {PublicationsEditFormService} from "../../../../../services/science/publications/editForm.service";
import {AllowedProperty, Property} from "../../../../../models/publications/editForm/property.model";
import {
  KendoProperties,
  PublicationEditFormTabs,
  PublicationProperties
} from "../../../../../models/publications/editForm/editForm.enums";
import {PublicationsService} from "../../../../../services/science/publications/publications.service";
import {PublicationsDictsService} from "../../../../../services/science/publications/dicts.service";
import {PropertiesService} from "../../../../../services/science/publications/properties.service";
import {ActivatedRoute} from "@angular/router";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {ConditionalRole} from "../../../../../models/useraccess/publications/publicationsUserAccess";
import {PublicationForm} from "../../../../../models/publications/editForm/publication.model";
import {StatusesService} from "../../../../../services/science/publications/statuses.service";
import {CodeMasks, Codes} from '../../../../../models/publications/editForm/codes.enums';

@Component({
  selector: 'publications-edit-form-codes',
  templateUrl: './publications-edit-form-codes.component.html',
  styleUrls: ['./publications-edit-form-codes.component.scss']
})
export class PublicationsEditFormCodesComponent implements OnInit, OnDestroy {
  public loading: boolean = false;
  public isEditingMode: boolean = false;
  private isNew: boolean = false;
  private isUnsaved: boolean = false;
  private isUnique: boolean = true;
  private editedRowIndex: number | undefined;

  private publicationId!: string;
  private properties!: Property[];
  public formPropertiesGroup: FormGroup = this.getFormPropertiesGroup();
  public isModerator: boolean = this.userAccessService.currentUserAccess$.value.conditionalRole === ConditionalRole.Moderator;
  public currentPublication: PublicationForm = new PublicationForm();
  public currentCode = '';

  @ViewChild(GridComponent) private grid!: GridComponent;
  public formGroup: FormGroup = new FormGroup({});
  private saveSubscription$!: Subscription;
  private changeSubscription$!: Subscription;
  private statusSubscription$!: Subscription;

  constructor(
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private editFormService: PublicationsEditFormService,
    private statusesService: StatusesService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private publicationsService: PublicationsService,
    private dictService: PublicationsDictsService,
    private propertiesService: PropertiesService,
    private userAccessService: PublicationsUserAccessService
  ) { }

  async ngOnInit() {
    this.publicationId = this.activatedRoute.snapshot.params['publicationId'];
    this.loading = true;
    this.getData();

    this.offTableClick();

    this.subscribe();
  }

  private subscribe() {
    this.saveSubscription$ = this.editFormService.save$.subscribe(value => {
      if (PublicationEditFormTabs.Codes === value?.name) this.saveChanges();
    });
    this.changeSubscription$ = this.editFormService.change$.subscribe(value => {
      if (value === PublicationEditFormTabs.Codes) {
        this.editFormService.setIsSaved(!this.isUnsaved);
      }
    });
    this.statusSubscription$ = this.statusesService.statusData$.subscribe((value) => {
      if (value === PublicationEditFormTabs.Codes) {
        this.getPublication();
      }
    });
  }

  private getData() {
    this.propertiesService
      .getProperties({publicationId: this.publicationId, categoryId: PublicationProperties.Codes})
      .subscribe({
        next: value => {
          this.properties = value;
          this.getPublication();
        },
        error: () => this.loading = false
      });
  }

  private getPublication() {
    this.publicationsService.getPublication(this.publicationId)
      .subscribe({
        next: value => {
          this.currentPublication = value;
          this.getProperties(value.typeId);
        },
        error: () => this.loading = false
      });
  }

  private getProperties(typeId: string) {
    this.propertiesService.getAllowedProperties({typeId: typeId, categoryId: PublicationProperties.Codes})
      .subscribe({
        next: async value => {
          this.formPropertiesGroup = this.getFormPropertiesGroup();
          this.mapFormArray(value);
          await this.handleFormArrayValues(value);
          this.loading = false;
        },
        error: () => this.loading = false
      });
  }

  private getFormPropertiesGroup() {
    return new FormGroup({
      publicationId: new FormControl(this.publicationId),
      categoryId: new FormControl(PublicationProperties.Codes),
      properties: new FormArray([])
    });
  }

  get formProperties(): FormArray {
    return <FormArray> this.formPropertiesGroup.get('properties');
  }

  public formChildProperties(property: AbstractControl<any>): FormArray {
    return <FormArray> property.get('childProperties');
  }

  get tableProperty() {
    return this.formProperties.controls.find(property =>
      property.value.type?.enum === KendoProperties.Table);
  }

  private mapFormArray(properties: AllowedProperty[]) {
    properties.forEach(item => {
      const disabled = this.isDynamicPropertyDisabled(item.allowModeratorOnly);
      this.formProperties.push(this.propertiesService.patchDynamicValues(item, disabled));
    });
  }

  private async getDictData(formArray: FormArray, item: AllowedProperty, index: number) {
    if (item.dictId) {
      await lastValueFrom(this.dictService.getDictData(item.dictId, true)).then((dictData) => {
        item.data = dictData;
        formArray.at(index).patchValue({data: dictData});
      });
    }
  }

  private async handleFormArrayValues(properties: AllowedProperty[]) {
    for (const item of properties) {
      const itemIndex: number = properties.indexOf(item);
      await this.getDictData(this.formProperties, item, itemIndex);

      if (item.childProperties) {
        for (const child of item.childProperties) {
          const childIndex: number = item.childProperties.indexOf(child);
          await this.getDictData(<FormArray> this.formProperties.at(itemIndex).get('childProperties'), child, childIndex);
        }
      }

      if (item.toolTip) {
        this.formProperties.at(itemIndex).patchValue({tooltip: JSON.parse(item.toolTip)});
      }

      if (this.properties.length) {
        const property = this.properties.find(property => property.id === item.id);
        await this.propertiesService.convertFormGroupValuesForView(this.formProperties, item, itemIndex, property);
      }
    }
  }

  private closeEditor() {
    for (let i = -1; i < this.tableProperty!.value.value.length; i++) {
      this.grid.closeRow(i);
    }
    this.isEditingMode = false;
    this.editedRowIndex = undefined;
    this.isNew = false;
    this.currentCode = '';
  }

  public addRow(childProperties: AbstractControl[]) {
    if (this.isEditingMode) {
      this.saveRow();
    }

    const controls: {[key: string]: FormControl} = {};
    childProperties.forEach(child =>
      controls[child.value.id] = new FormControl('', child.value.required ? Validators.required : null)
    );
    this.formGroup = new FormGroup(controls);

    this.isEditingMode = true;
    this.isUnsaved = true;
    this.editedRowIndex = this.tableProperty!.value.value.length;
    this.isNew = true;
    this.grid.addRow(this.formGroup);
  }

  public async onEdit(
    childProperties: AbstractControl[],
    isDisabled: boolean,
    { rowIndex, columnIndex, sender, dataItem, isEdited}: CellClickEvent
  ) {
    if (this.isNew || isDisabled || isEdited) {
      return;
    }

    if (this.isEditingMode) {
      if (this.formGroup!.valid) {
        this.saveRow();
      }
      if (!this.isEditingMode || !this.isUnique) {
        return;
      }
    }

    const controls: {[key: string]: FormControl} = {};
    childProperties.forEach(child =>
      controls[child.value.id] = new FormControl(dataItem[child.value.id], child.value.required ? Validators.required : null)
    );
    this.formGroup = new FormGroup(controls);

    const [code, value] = [...Object.entries(this.formGroup.value)];
    this.currentCode = code[1] as string;
    if (this.currentCode === Codes.DOI) {
      this.formGroup.patchValue({
        ...this.formGroup.value,
        [value[0]]: `${(value[1] as string).slice(CodeMasks.DOI.length)}`
      });
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.isEditingMode = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public onRemove(childProperties: AbstractControl[], {dataItem, rowIndex}: RemoveEvent) {
    const dialog: DialogRef = openDialog(
      this.dialogService,
      `Удалить ${dataItem[childProperties[0].value.id]} ${dataItem[childProperties[1].value.id]}?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.tableProperty!.get('value')!.patchValue(
          this.tableProperty!.get('value')!.value.filter((item: any, index: number) => index !== rowIndex));
        this.isUnsaved = true;
      }
    });
  }

  private saveRow() {
    if (this.formGroup.invalid) {
      if (!Object.values(this.formGroup.getRawValue()).some((item) => !!item)) {
        this.closeEditor();
      }
      this.formGroup.reset();
      this.currentCode = '';
      return;
    }

    this.isUnsaved = true;
    if (!this.tableProperty!.get('value')!.value) {
      this.tableProperty!.get('value')!.patchValue([]);
    } else {
      this.isUnique = true;
      this.tableProperty!.get('value')!.value.forEach((row: {[key: string]: string}, index: number) => {
        if (index !== this.editedRowIndex) {
          if (!this.isUnique) {
            return;
          }
          this.isUnique = Object.values(row)[0] !== Object.values(this.formGroup.value)[0];
        }
      });
      if (!this.isUnique) {
        this.notificationService.showError('Может быть только один уникальный идентификатор с таким названием');
        return;
      }
    }

    if (this.currentCode === Codes.DOI) {
      const value = [...Object.entries(this.formGroup.value)][1];
      this.formGroup.patchValue({
        ...this.formGroup.value,
        [value[0]]: `${CodeMasks.DOI}${value[1]}`
      });
    }
    this.tableProperty!.get('value')!.value[this.editedRowIndex!] = this.formGroup.value;
    this.closeEditor();
  }

  public onFieldChange() {
    this.isUnsaved = true;
  }

  public codeChange(value: string) {
    const val = [...Object.entries(this.formGroup.value)][1];
    this.formGroup.patchValue({
      ...this.formGroup.value,
      [val[0]]: ''
    });
    this.currentCode = value;
  }

  private saveChanges() {
    if (this.formPropertiesGroup.valid) {
      if (this.isEditingMode) {
        this.saveRow();
        if (this.formGroup.invalid || !this.isUnique) {
          return;
        }
      }

      const formGroup: FormGroup = this.propertiesService.convertFormGroupValuesForRequest(this.formPropertiesGroup);
      this.propertiesService.saveProperties(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          this.isUnsaved = false;
          this.editFormService.currentTab$.next(PublicationEditFormTabs.About);
        },
        error: (error) => this.notificationService.showError(error)
      });
    } else {
      this.notificationService.showError('Заполните все необходимые поля');
    }
  }

  private offTableClick() {
    this.renderer.listen("document", "click", ({ target }) => {
      if (this.isEditingMode
        && isChildOf(target, "container")
        && !isChildOf(target, "k-grid")
        && !isChildOf(target, "k-tabstrip-items-wrapper")
        && !isChildOf(target, "actionButtons")
        && !isChildOf(target, "addButton")
        && !hasClass(target, "addButton")
        && !isChildOf(target, "menu")) {
        this.saveRow();
      }
    });
  }

  public isDynamicPropertyDisabled(allowModeratorOnly: boolean): boolean {
    return this.propertiesService.disableDynamicProperty(this.currentPublication, this.isModerator, allowModeratorOnly);
  }

  private unsubscribe() {
    this.saveSubscription$.unsubscribe();
    this.changeSubscription$.unsubscribe();
    this.statusSubscription$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.isEditingMode = false;
    this.editFormService.save$.next(null);
    this.unsubscribe();
  }

  protected readonly KendoProperties = KendoProperties;
  protected readonly String = String;
  protected readonly Codes = Codes;
}

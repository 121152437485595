import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable, throwError} from "rxjs";
import {Guid} from "guid-typescript";
import {
  LocalSignatoryAdd, LocalSignatoryCertificate,
  LocalSignatoryRead,
  LocalSignatoryUpdate
} from "../../models/contingent/localsignatory.model";

@Injectable({
  providedIn: "root"
})
export class LocalSignatoryService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.contingentPath.localSignatory}`;

  constructor(private http: HttpClient) {
  }

  public get(): Observable<LocalSignatoryRead[]> {
    return this.http.get<LocalSignatoryRead[]>(this.baseUrl);
  }

  public getCertificateSigners(studentId?: Guid | string): Observable<LocalSignatoryCertificate[]> {
    return this.http.get<LocalSignatoryCertificate[]>(this.baseUrl + '/GetCertificateLocalSignatory/' + studentId);
  }

  public add(data: LocalSignatoryAdd): Observable<Guid | string> {
    return this.http.post<Guid | string>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public update(data: LocalSignatoryUpdate): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public delete(signatoryId: Guid | string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + signatoryId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

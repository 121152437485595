<education-header></education-header>
<div class="dictEducation__container">
  <h1>Кафедра</h1>
  <div [class]="[gridStyle]">
    <button kendoButton (click)="addHandler()" [primary]="true" iconClass="k-icon k-i-plus" style="margin-bottom: 30px">Добавить кафедру</button>
    <kendo-grid #grid
                [data]="kafedras"
                [kendoGridBinding]="kafedras"
                [navigable]="true"
                [sortable]="true"
                [filterable]="true"
                [height]="600"
                (edit)="editHandler($event)"
                (add)="addHandler()"
                (dataStateChange)="onStateChange($event)">
      <kendo-grid-column headerClass="gridHeader dictionaries" field="outerDataKafedraName" title="Название кафедры">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="outerDataKafedraSName" title="Сокр.название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="outerDataFacultyForm.outerDataFacultyName" editor="text" title="Факультет">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown
                  [filter]="filter"
                  [data]="faculties"
                  textField="outerDataFacultyName"
                  valueField="outerDataFacultyId">
          </filter-dropdown>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="" [width]="200">
        <ng-template kendoGridCellTemplate >
          <button kendoGridEditCommand themeColor="primary" class="buttons">Изменить</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
</div>

<kendo-grid-kafedra-edit-form [model]="editDataItem!" [isNew]="isNew" (save)="saveHandler($event)" (cancel)="cancelHandler()">
</kendo-grid-kafedra-edit-form>

import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import {Validators, UntypedFormGroup, UntypedFormControl, FormControl} from "@angular/forms";
import { OrganizationService } from 'src/app/services/dicts/organization.service';
import {Organization} from "../../../models/dicts/organization.model";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { Router} from "@angular/router";

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  public organizations: Organization[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]

  constructor(
    private organizationService: OrganizationService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: CommonDictionariesUserAccessService,
    private router: Router,
    ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    //this.getAllorganization();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")/*&&this.orderValid*/) {
        this.saveCurrent();
      }
    });
  }

 // public orderValid: boolean=true;

  public orderChange(value: any): void {
   // this.orderValid = value === null || (value > 0 && this.organizations.find(_ => _.order === value) == undefined);
  }

  public onStateChange(): void {
    this.getAllorganization();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      isTarget: true,
      isWorkPlace: true
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
  //  if(!this.orderValid) return;
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  //  this.orderValid = true
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.organizationService.addorganization(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllorganization();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить организацию');
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.organizationService.updateorganization(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllorganization();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllorganization() {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.organizations = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelOrganization == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelOrganization != 1;
            this.getAllorganization();
          }
        },error:() => {}});
  }


          //Deleting an entry from dictionary
          public removeHandler({ dataItem }: RemoveEvent): void {
            const dialog: DialogRef = this.dialogService.open({
              title: "Пожалуйста подтвердите",
              content: "Вы действительно хотите удалить: "+ dataItem.organizationName + "?",
              actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
              width: 450,
              height: 200,
              minWidth: 250,
            });

            dialog.result.subscribe((result) => {
              if (result instanceof DialogCloseResult) {
              } else {
                if(result.text == "Да"){
                  this.opened = false;
                  this.organizationService.deleteorganization(dataItem.dictOrganizationExternalId)
                  .subscribe({
                    next:() => {
                      this.getAllorganization();
                      this.notificationService.showSuccess("Удалено");
                    },
                    error:(error) => {
                    this.notificationService.showError(error.error);
                  }});
                    }
                  else{
                    this.opened = false;
                  }
              }
            });
          }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

}

const formGroup = (dataItem: { dictOrganizationId?: any;dictOrganizationExternalId?: any; organizationName?: any; ogrn?: any; inn?: any; kpp?: any; order?: any; isTarget?:any; isWorkPlace?:any;}) =>
  new UntypedFormGroup({
    dictOrganizationId: new UntypedFormControl(dataItem.dictOrganizationId),
    dictOrganizationExternalId: new UntypedFormControl(dataItem.dictOrganizationExternalId),
    organizationName: new UntypedFormControl(dataItem.organizationName, Validators.required),
    ogrn: new UntypedFormControl(dataItem.ogrn),
    inn: new UntypedFormControl(dataItem.inn),
    kpp: new UntypedFormControl(dataItem.kpp),
    order: new UntypedFormControl(dataItem.order),
    isTarget: new FormControl(dataItem.isTarget),
    isWorkPlace: new FormControl(dataItem.isWorkPlace),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ScienceBranch } from '../../../models/education/scienceBranch.model';

@Injectable({
  providedIn: 'root'
})
export class ScienceBranchService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.scienceBranch}`;

  constructor(private http: HttpClient) { }

  //Get all science branches
  public getAllScienceBranches(): Observable<ScienceBranch[]> {
    return this.http.get<ScienceBranch[]>(this.baseUrl);
  }

  //Add science branches
  public addScienceBranch(body: any): Observable<ScienceBranch> {
    return this.http.post<ScienceBranch>(this.baseUrl, body);
  }

  //Update science branches
  public updateScienceBranch(body: any): Observable<ScienceBranch> {
    return this.http.put<ScienceBranch>(this.baseUrl + '/' + body.scienceBranchExternalId, body);
  }

  //Delete science branches
  public deleteScienceBranch(id: string): Observable<ScienceBranch> {
    return this.http.delete<ScienceBranch>(this.baseUrl + '/' + id);
  }
}

<kendo-multiselecttree
  kendoMultiSelectTreeExpandable
  kendoMultiSelectTreeSummaryTag
  [kendoMultiSelectTreeHierarchyBinding]="data"
  [popupSettings]="{width: 'auto'}"
  [textField]="textField"
  [valueField]="valueField"
  [childrenField]="childrenField"
  [valuePrimitive]="true"
  [value]="selectedValue"
  (valueChange)="onChange($event)">
  <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
    {{dataItem.length}} выбрано
  </ng-template>
</kendo-multiselecttree>

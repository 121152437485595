<workload-settings></workload-settings>
<h1>Отчеты по учебной нагрузке</h1>
<span class="workloadDropdownContainer">
    <h3>Выберите отчет</h3>
    <kendo-dropdownlist class="workloadDropdown" valueField="value" textField="name"
                        [data]="reports"
                        [(ngModel)]="reportEdit"
                        (valueChange)="onReportChange($event)"
                        [valuePrimitive]="true">
    </kendo-dropdownlist>
</span>
<div *ngIf="selectedReport">
    <span class="workloadDropdownContainer">
    <h3>Параметры отчета</h3>
    <h3 class="params"></h3>
    </span>
    <span class="workloadDropdownContainer"
          *ngIf="(selectedReport == 1 || selectedReport == 2 || selectedReport == 3)">
        <h4>Учебный год</h4>
        <kendo-dropdownlist class="workloadDropdown" valueField="name" textField="name"
                            [data]="years"
                            [(ngModel)]="yearEdit"
                            (valueChange)="filterValueChange($event, 'years', 'yearEdit')"
                            [valuePrimitive]="false">
        </kendo-dropdownlist>
    </span>
    <span class="workloadDropdownContainer" *ngIf="!selectedReport">
        <h4>Семестры</h4>
        <kendo-dropdownlist class="workloadDropdown" valueField="name" textField="name"
                            [data]="semesters"
                            [(ngModel)]="semesterEdit"
                            [valuePrimitive]="false">
        </kendo-dropdownlist>
    </span>
    <span class="workloadDropdownContainer"
          *ngIf="selectedReport == 1 || selectedReport == 2 || selectedReport == 3">
        <h4>Кафедра</h4>
        <kendo-combobox #comboboxKafedra class="workloadDropdown" textField="name" valueField="id"
                        [data]="kafedras"
                        [kendoDropDownFilter]="filterSettings"
                        [(ngModel)]="kafedraEdit"
                        [openOnFocus]="comboboxKafedra"
                        [valuePrimitive]="false"
                        (valueChange)="filterValueChange($event, 'kafedras', 'kafedraEdit')"
                        [virtual]="virtual">
        </kendo-combobox>
    </span>
    <span class="workloadDropdownContainer"
          *ngIf="selectedReport == 2">
        <h4>Преподаватель</h4>
        <kendo-combobox #comboboxTeacher class="workloadDropdown" textField="fio" valueField="id"
                        [data]="persons"
                        [kendoDropDownFilter]="filterSettings"
                        [openOnFocus]="comboboxTeacher"
                        [(ngModel)]="teacherEdit"
                        [valuePrimitive]="false">
        </kendo-combobox>
    </span>
    <span class="workloadDropdownContainer"
          *ngIf="selectedReport == 2">
        <h4>Тип нагрузки</h4>
        <kendo-dropdownlist class="workloadDropdown" textField="name" valueField="id"
                            [data]="workloadTypes"
                            [(ngModel)]="workloadTypesEdit">
        </kendo-dropdownlist>
    </span>
    <span class="workloadDropdownContainer" *ngIf="!selectedReport">
        <h4>Уровень подготовки</h4>
        <kendo-multiselect class="workloadDropdown" textField="name" valueField="id"
                           [data]="trainingLevels"
                           [kendoDropDownFilter]="filterSettings"
                           [valuePrimitive]="true"
                           (valueChange)="filterValueChange($event, 'trainingLevel', 'trainingLevelsEdit')"
                           [value]="trainingLevelsEdit">
        </kendo-multiselect>
    </span>
    <span class="workloadDropdownContainer"
          *ngIf="selectedReport == 1">
        <h4>Институт (факультет)</h4>
        <kendo-dropdownlist class="workloadDropdown" valueField="id" textField="sName"
                            [data]="faculties"
                            [valuePrimitive]="false"
                            (valueChange)="filterValueChange($event, 'faculties', 'facultiesEdit')"
                            [(ngModel)]="facultiesEdit">
        </kendo-dropdownlist>
    </span>
    <span class="workloadDropdownContainer" *ngIf="!selectedReport">
        <h4>Форма обучения</h4>
        <kendo-multiselect class="workloadDropdown" textField="studyFormName" valueField="id"
                           [value]="studyFormEdit"
                           [valuePrimitive]="true"
                           (valueChange)="filterValueChange($event, 'studyForms', 'studyFormEdit')"
                           [data]="studyForms">
        </kendo-multiselect>
    </span>
    <span class="buttons">
      <button [disabled]="selectedReport == 2 && !teacherEdit" (click)="onExport()" kendoButton icon="download">Скачать отчет</button>
    </span>
</div>
<!--<kendo-listview [hidden]="true" [ngClass]="{noData: reports.length < 6}" class="reportsList" [data]="reports" [height]="600">-->
<!--    <ng-template kendoListViewItemTemplate let-dataItem="dataItem">-->
<!--        <kendo-tilelayout class="layout">-->
<!--            <kendo-tilelayout-item [col]="1" [colSpan]="4">-->
<!--                <kendo-tilelayout-item-body>-->
<!--                    <div class="item">-->
<!--                        <kendo-icon name="file"></kendo-icon>-->
<!--                        <div class="info">-->
<!--                            <h2>{{dataItem.name}}</h2>-->
<!--                            <h3><span class="titleText">Выгрузка таблицы в Excel.</span>-->
<!--                                Необходимые параметры для формирования отчета: учебный год, кафедра (одиночный выбор определенной кафедры), факультет /-->
<!--                                институт (одиночный выбор определенного факультета).-->
<!--                            </h3>-->
<!--                            <div class="attachment">-->
<!--                                <kendo-icon name="attachment"></kendo-icon>-->
<!--                                <h2>{{dataItem.fileName + '.pdf'}}</h2>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </kendo-tilelayout-item-body>-->
<!--            </kendo-tilelayout-item>-->
<!--        </kendo-tilelayout>-->
<!--    </ng-template>-->
<!--</kendo-listview>-->

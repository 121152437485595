import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Person} from '../../models/mfc/person.model';


@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.person}/`;

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<Person[]>(`${this.baseUrl}GetAllPerson`)
      .pipe(map((d) => d ?? []));
  }
}

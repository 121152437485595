export const items: any[] = [
  {
    text: "Настройки",
    icon: "cog",
    items: [
      {
        text: "Справочники",
        items: [{
          text: "Зарплатные проекты",
          path: "/cards/salaryproject",
        },
        ],
      },
      {
        text: "Настройки доступа",
        path: "/cards/useraccess",
        access: true
      }
    ]
  }
]

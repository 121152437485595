import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../../helpers/publications/errorHandle-helper";
import {DictPublicationSubType} from "../../../models/publications/dict/publicationSubType.model";

@Injectable({
    providedIn: 'root'
})
export class SubTypeService {
    baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/SubType/`;
    constructor(private http: HttpClient) { }

    getSubTypes(): Observable<DictPublicationSubType[]> {
        return this.http.get<DictPublicationSubType[]>(this.baseUrl);
    }

    addSubType(subType: DictPublicationSubType): Observable<DictPublicationSubType> {
        return this.http.post<DictPublicationSubType>(this.baseUrl, subType)
            .pipe(catchError(handleError));
    }

    updateSubType(subType: DictPublicationSubType): Observable<DictPublicationSubType> {
        return this.http.put<DictPublicationSubType>(this.baseUrl, subType)
            .pipe(catchError(handleError));
    }

    removeSubType(id: string): Observable<DictPublicationSubType> {
        return this.http.delete<DictPublicationSubType>(this.baseUrl + id)
            .pipe(catchError(handleError));
    }


}

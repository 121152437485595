import { Injectable } from '@angular/core';
import { TokenStorageService } from './token.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private tokenStoreService: TokenStorageService,
    protected jwtHelper: JwtHelperService
  ) {}

  public isUserAuthenticated() {
    const token = this.tokenStoreService.getAuthToken();
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    } else {
      return false;
    }
  }

  public decodeToken() {
    const token = this.tokenStoreService.getAuthToken();
    if (token) return this.jwtHelper.decodeToken(token);
    return {};
  }
}

import {Component, OnInit} from '@angular/core';
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {IReportTemplateInfo} from "../../../models/report-designer/ITemplateInfo";
import {ReportDesignerService} from "../../../services/report-designer/report-designer.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  private serviceName = 'Session';
  private sessionBaseUrl: string = environment.apiEndpoint.toString().endsWith('/')
    ? environment.apiEndpoint.toString().slice(0, -1) : environment.apiEndpoint.toString();
  public reportPath: string = "";
  public reportTemplatesList: IReportTemplateInfo[] = [];

  constructor(private notificationService: NotificationsService,
              private reportDesignerService: ReportDesignerService,
              private router: Router) { }

  ngOnInit(): void {
    this.getTemplatesByServiceName();
  }

  public getTemplatesByServiceName() {
    this.reportDesignerService.getTemplatesByServiceName(this.serviceName).subscribe({
        next:response => this.reportTemplatesList = response,
        error:err => this.notificationService.showError(err)
      })
  }

  public openReport() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['report-viewer'], {
        queryParams: {
          report: this.reportPath,
          sessionBaseUrl: this.sessionBaseUrl
        }
      })
    );

    window.open(url, '_blank');
  }
}

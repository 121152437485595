import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { QualificationDegree } from '../../models/dicts/qualificationdegree.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";
import {CommonDictPerson} from "../../models/dicts/person.model";

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.cdPerson}`;

  constructor(private http: HttpClient) {
  }

  public getAllPersom(): Observable<CommonDictPerson[]> {
    return this.http.get<CommonDictPerson[]>(this.baseUrl);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

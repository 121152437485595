<education-header></education-header>
<title>Образовательные стандарты</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Справочник образовательных стандартов</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новый образовательный стандарт' : 'Изменить стандарт' }}
  </h1>

  <div [class]="[gridStyle]">
    <button class="addButton" kendoButton (click)="addHandler()" [primary]="true" iconClass="k-icon k-i-plus" *ngIf="!isOpened&&editable">Добавить стандарт</button>
    <kendo-grid #StandardGrid
                [data]="standards"
                [kendoGridBinding]="standards"
                [pageable]="{
      buttonCount: buttonCount,
      info: info,
      type: type,
      pageSizes: pageSizes,
      previousNext: previousNext,
      position: position
    }"
                [height]="600"
                [pageSize]="20"
                [navigable]="true"
                [sortable]="true"
                [sort]="sort"
                [filterable]="true"
                (cellClick)="getSelectedValues($event)"
                (dataStateChange)="onStateChange($event)"
                [rowClass]="rowClassCallback"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="standardCipher" [width]="130" editor="text" title="Шифр ОП">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.standardCipher}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="standardName" title="Направление подготовки" [width]="250" class="statusColumn">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.standardName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="educationLevel.educationLevelName" [width]="250" editor="text" title="Уровень образования">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [componentName]="componentName" [data]="studylevels" textField="educationLevelName"
                           valueField="educationLevelExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.educationLevel.educationLevelName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="trainingLevel.trainingLevelName" [width]="200" editor="text"
                         title="Уровень подготовки">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [componentName]="componentName" [data]="trainingLevels" textField="trainingLevelName"
                           valueField="trainingLevelExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.trainingLevel.trainingLevelName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictStandardType.standardTypeShortName" [width]="140" editor="text" title="Тип стандарта">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [filter]="filter" [componentName]="componentName" [data]="standardTypes" textField="standardTypeShortName"
                           valueField="standardTypeExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.dictStandardType.standardTypeShortName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="status" editor="text" title="Статус" [width]="120" [filterable]="true" [class]="{ codeColumn: true }">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [filter]="filter" [componentName]="componentName" [data]="status" textField="name"
                           valueField="status">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.status">
            <div *ngSwitchCase="StateEnum.Active">{{"Действует"}}</div>
            <div *ngSwitchCase="StateEnum.Closed">{{"Закрыт"}}</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column *ngIf="editable" title="" headerClass="gridHeader dictionaries" [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          <button kendoButton type="button" (click)="editHandler(dataItem)" class="editButton">Изменить</button>
        </span>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" title="Ограничения стандарта" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" style="cursor: pointer" (click)="goToLimitations()">
          <h2 style="text-decoration: underline; color: #42A7DD">Ограничения</h2>
        </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow">
          <h2>Шифр</h2>
          <kendo-textbox formControlName="standardCipher" showErrorIcon="initial" [maxlength]="50" #standardCipher required></kendo-textbox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield >
        <div class="formRow">
          <h2>Название</h2>
          <kendo-textbox formControlName="standardName"  showErrorIcon="initial" [maxlength]="255" #standardName required>
          </kendo-textbox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="standardShortName" showErrorIcon="initial" [maxlength]="50" #standardShortName required></kendo-textbox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Тип стандарта</h2>
          <kendo-dropdownlist
                  [data]="standardTypes"
                  textField="standardTypeName"
                  valueField="standardTypeExternalId"
                  [valuePrimitive]="true"
                  (valueChange)="onStandardTypeChange($event)"
                  [formControl]="$any(editForm).controls['standardTypeExternalId']"
                  formControlName="standardTypeExternalId" required>
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Уровень образования</h2>
          <kendo-dropdownlist
                  [data]="studylevels"
                  textField="educationLevelName"
                  valueField="educationLevelExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['educationLevelExternalId']"
                  formControlName="educationLevelExternalId" #educationLevelExternalId required>
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Уровень подготовки</h2>
          <kendo-dropdownlist
                  [data]="trainingLevels"
                  textField="trainingLevelName"
                  valueField="trainingLevelExternalId"
                  [valuePrimitive]="true"
                  (valueChange)="onTrainingLevelChange($event)"
                  [formControl]="$any(editForm).controls['dictTrainingLevelId']"
                  formControlName="dictTrainingLevelId" required>
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield *ngIf="showScienceBranch">
        <div class="formRow">
          <h2>{{standardTypeDictName}}</h2>
          <kendo-dropdownlist
                  [data]="filteredScienceBranch"
                  textField="scienceBranchFullName"
                  valueField="scienceBranchExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['dictScienceBranchExternalId']"
                  formControlName="dictScienceBranchExternalId">
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>УГС</h2>
          <kendo-dropdownlist
                  [data]="filteredEnlargedGroupSpecializations"
                  textField="fullName"
                  [defaultItem]="defaultItem"
                  valueField="externalId"
                  [valuePrimitive]="true"
                  [kendoDropDownFilter]="filterSettings"
                  formControlName="dictEnlargedGroupSpecializationId">
          </kendo-dropdownlist>
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Трудоемкость</h2>
          <kendo-numerictextbox formControlName="laboriousness" format="n0" [min]="0" #laboriousness></kendo-numerictextbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Дата утверждения стандарта</h2>
          <kendo-datepicker
                  format="dd.MM.yyyy"
                  formControlName="dateFGOS"
                  [max]="max">
          </kendo-datepicker>
        </div>
        <kendo-formerror>Ошибка: Дата выходит за пределы диапазона</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Номер приказа</h2>
          <kendo-textbox formControlName="number" [maxlength]="50"></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Ссылка на стандарт</h2>
          <kendo-textbox formControlName="link"></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Статус</h2>
          <kendo-dropdownlist
                  [data]="educationStatus"
                  textField="text"
                  valueField="value"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['status']"
                  formControlName="status" required>
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton type="button" (click)="onCancel()">Отменить</button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="open($event)">Сохранить</button>
      </div>
    </form>
    <kendo-dialog
            title="Пожалуйста, подтвердите"
            *ngIf="dialogOpened"
            (close)="close()"
            [minWidth]="250"
            [width]="450"
    >
      <p style="margin: 30px; text-align: center;">
        Вы уверены что хотите изменить статус стандарта?
      </p>
      <kendo-dialog-actions>
        <button kendoButton (click)="close()">Нет</button>
        <button kendoButton  (click)="saveStandard()" [primary]="true">
          Да
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </div>

</div>

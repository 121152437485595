import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { DisciplineStatement } from 'src/app/models/currentcontrol/groupCommon.model';
import {
  PersonsModel,
  Signer,
} from 'src/app/models/currentcontrol/persons.model';
import { SignatoriesModel } from 'src/app/models/currentcontrol/sheets.model';
import { CurrentControlService } from 'src/app/services/currentcontrol/currentcontrol.service';
import { DictsService } from 'src/app/services/currentcontrol/dicts.service';
import { GetDisciplinePrintFormData } from '../../../models/currentcontrol/discipline.model';
import { SheetService } from '../../../services/currentcontrol/sheet.service';
import { CurrentControlUserAccessService } from '../../../services/useraccess/current-control-user-access.service';
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {CurrentDayEnd} from "../../../helpers/date-helper";

@Component({
  selector: 'app-currentstatementsettings',
  templateUrl: './currentstatementsettings.component.html',
  styleUrls: ['./currentstatementsettings.component.scss'],
})
export class CurrentstatementsettingsComponent implements OnInit {
  public teacher: string = '';
  public disciplineInfo: Partial<DisciplineStatement> = {};
  public personsToFilter: PersonsModel[] = [];
  public examiner: PersonsModel | null = null;
  public signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'должность',
    personId: Guid.create(),
  };
  public loadingStatementEmpty = false;
  public loadingStatementFull = false;
  private disciplineInfoSubscription$!: Subscription;
  private personsSubscription$!: Subscription;
  private groupInfoSubscription$!: Subscription;

  @Input() toggleStep: string | null = null;
  @Input() groupName: string = '';
  @Input() eduGroupId = '';
  @Input() facultyId = '';
  @Output() onChangeEditable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() contingentDate: Date = CurrentDayEnd();

  @Input() semesterNumber = 0;
  @Input() disciplineId = '';
  @Output() onBack: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleStepChange: EventEmitter<string | null> = new EventEmitter();

  constructor(
    private currentControlService: CurrentControlService,
    private dictService: DictsService,
    private sheetService: SheetService,
    private userAccessService: CurrentControlUserAccessService
  ) {}

  public editable?: boolean;
  public trainingLevelId?: string;

  ngOnInit(): void {
    this.disciplineInfoSubscription$ =
      this.currentControlService.disciplineInfo$.subscribe((info) => {
        this.disciplineInfo = info;
        this.trainingLevelId = this.disciplineInfo.trainingLevelId
        this.editable=this.disciplineInfo.editable
        this.onChangeEditable.emit(this.editable)
        this.examiner = info.teacher || null;
        if (info.signatory)
          this.signer = {
            fio: info.signatory.fio,
            postName: info.signatory.postName,
            personId: Guid.parse(info.signatory.personId || ''),
          };
      });
    this.personsSubscription$ = this.dictService.persons$.subscribe(
      (response) => {
        this.personsToFilter = response;
      }
    );
    this.groupInfoSubscription$ = this.sheetService.groupInfo$.subscribe(
      (info) => {
        if (this.signer.fio === 'Фамилия Имя Отчество' && info.signatory) {
          this.signer = {
            fio: info.signatory.fio,
            postName: info.signatory.postName,
            personId: Guid.parse(info.signatory.personId),
          };
        }
      }
    );
  }

  onChangeSignerPost(post: string) {
    this.signer.postName = post;
  }

  onChangeSigner(signer: SignatoriesModel){
    this.signer = signer
  }

  ngOnDestroy() {
    this.disciplineInfoSubscription$?.unsubscribe();
    this.personsSubscription$?.unsubscribe();
    this.groupInfoSubscription$?.unsubscribe();
  }

  public onPrintStatement(empty: boolean) {
    const data: GetDisciplinePrintFormData = {
      eduGroupId: this.eduGroupId,
      disciplineId: this.disciplineId,
      electiveDisciplineId: this.disciplineInfo.electiveDisciplineId || null,
      dictAttestationId: this.toggleStep,
      fio: this.signer.fio==="Фамилия Имя Отчество"?null:this.signer.fio,
      postName: this.signer.fio==="Фамилия Имя Отчество"?null:this.signer.postName,
      personId: this.signer.personId.toString(),
      empty: empty,
      contingentDate: this.contingentDate
    };
    if(empty) this.loadingStatementEmpty = true;
    else this.loadingStatementFull = true;
    this.sheetService.printDisciplineForm(data).subscribe({
      next:(response) => {
        DownloadFile(response);
        this.loadingStatementEmpty = false;
        this.loadingStatementFull = false;
      },
      error:() => (this.loadingStatementEmpty = false, this.loadingStatementFull = false )}
    );
  }

  public onToggleClick(value: string | null) {
    this.toggleStep = value;
    this.toggleStepChange.emit(value);
  }

  public closeTable() {
    const current_control_settings = localStorage.getItem('current_control_settings');
    if (current_control_settings !== null){
      let storage = JSON.parse(current_control_settings)
      {
        let currentControlSaveSettings: any = {
          event: storage.event,
          discipline:false,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: storage.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          week: storage.week,
          contingentDate: storage.contingentDate
        }
        localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));
      }
    }
    this.onBack.emit('main');
  }

  public handleFilter(value: string) {
    if (value.length > 1) this.dictService.updatePersons(value);
  }

  public onChangeFindSigner(name: string): void {
    if (name.length) {
      const examiner =
        this.personsToFilter?.find((person) => person.fio === name) || null;

      if (this.examiner === null) {
        if (examiner != null)this.currentControlService
          .addExaminer({
            fio: examiner.fio,
            postname: examiner.postName,
            personId: examiner.id,
            eduGroupId: this.eduGroupId,
            semesterNum: this.semesterNumber,
            electiveDisciplineId:
              this.disciplineInfo.electiveDisciplineId || null,
            disciplineId: this.disciplineId,
          })
          .subscribe((response) => (this.examiner = response));
      } else {
        this.currentControlService
          .changeExaminer(this.examiner.id.toString(), {
            fio: examiner?.fio,
            postname: examiner?.postName,
            personId: examiner?.id,
            eduGroupId: this.eduGroupId,
            semesterNum: this.semesterNumber,
            electiveDisciplineId:
              this.disciplineInfo.electiveDisciplineId || null,
            disciplineId: this.disciplineId,
          })
          .subscribe((response) => (this.examiner = response));
      }
    } else {
      if (this.examiner)
        this.currentControlService
          .deleteExaminer(this.examiner?.id.toString())
          .subscribe(() => (this.examiner = null));
    }
  }

}

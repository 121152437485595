<kendo-stacklayout [gap]="20" orientation="vertical" class="filters">
  <h2 class="group">Группа {{ group.text }}</h2>
  <kendo-dropdownlist
    class="params__input"
    [data]="semesterList"
    [value]="selectedSemester"
    textField="semesterName"
    valueField="semesterNum"
    (selectionChange)="onSelectSemester($event)"
    [disabled]="+currentDate !== +contingentDate"
  >
    <ng-template kendoDropDownListItemTemplate let-dataItem>
      <span>{{ dataItem.semesterName }}</span>
    </ng-template>
  </kendo-dropdownlist>
  <div class="filters__graphic">
    <span
      class="filters__graphic--label"
      [ngClass]="{ filters__graphic__active_text: isEditSessionDates }"
      >График сессии:</span
    >
    <ng-template [ngIf]="!isEditSessionDates">
      <span
        class="filters__graphic--dates"
        [class.filters--red]="
          groupInfo.id && (startDate === newDate || endDate === newDate)
        "
        >{{
          startDate === newDate ? "н/у" : (startDate | date : "dd.MM.yyyy")
        }}
        -
        {{
          endDate === newDate ? "н/у" : (endDate | date : "dd.MM.yyyy")
        }}</span
      >
      <button
        *ngIf="editableSession"
        kendoButton
        (click)="onChangeGraphicClick()"
        icon="edit"
        class="button"
      >
        Изменить
      </button>
    </ng-template>
    <ng-template [ngIf]="isEditSessionDates">
      <span class="filters__graphic--active_text">с</span>
      <kendo-datepicker [(value)]="startDate"></kendo-datepicker>
      <span class="filters__graphic--active_text">по</span>
      <kendo-datepicker [(value)]="endDate"></kendo-datepicker>
      <button kendoButton (click)="onSaveSessionDates()" class="button">
        Сохранить
      </button>
    </ng-template>
  </div>
  <p
    *ngIf="groupInfo.id && (startDate === newDate || endDate === newDate)"
    class="filters--red"
  >
    Не заполнен график сессии!
  </p>
  <kendo-stacklayout
    orientation="vertical"
    [gap]="20"
    class="filters__print_list"
  >
    <span class="filters__print_list--label">Печать сводной ведомости:</span>

    <app-signerparams *ngIf="showSigner"
      [signer]="signer"
      [editable]="editable"
      [facultyId]="groupInfo.facultyId"
      [trainingLevelId]="groupInfo.trainingLevelId"
      [eduGroupId]="eduGroupId"
      [semesterNum]="selectedSemester.semesterNum"
      (signerPostChange)="onChangeSignerPost($event)"
      (signerChange)="onChangeSigner($event)"
    ></app-signerparams>

    <div class="filters__print_list__buttons">
      <button
        kendoButton
        size="small"
        (click)="onPrintEmptyList()"
        style="position: relative; float: right"
        class="no-margin filters__print_list__buttons--item"
      >
        пустая
        <kendo-loader *ngIf="loadingEmptyList" size="small"></kendo-loader>
      </button>
      <button
        kendoButton
        size="small"
        (click)="onPrintFilledList()"
        style="position: relative; float: right"
        class="no-margin filters__print_list__buttons--item"
      >
        заполненная
        <kendo-loader *ngIf="loadingFilledList" size="small"></kendo-loader>
      </button>
    </div>
  </kendo-stacklayout>
</kendo-stacklayout>

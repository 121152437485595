
import { Student } from "./student.model";
import { AcademicState } from "./academicstate.model";
import {Guid} from "guid-typescript";

export class StudentAcademicState{
  public studentAcademicStateId!: number;
  public studentAcademicStateExternalId?: Guid;
  public studentId?: Guid;
  public student!:Student;
  public dictStudentAcademicStateId?: Guid;
  public dictStudentAcademicStates?:AcademicState;
}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Person} from "../../models/gia/person.model";

@Injectable({
  providedIn: 'root'
})
export class GiaPersonService {  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.person}`;
  constructor(private http: HttpClient) { }

  // Получить всех сотрудников
  getPersons(fio?: string): Observable<Person[]> {
    const filter = fio ? `&FIO=${fio}` :'';
    return this.http.get<Person[]>(this.baseUrl + `/${filter}`);
  }
}

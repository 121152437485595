import {Injectable} from '@angular/core';
import {catchError, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApplicationType} from '../../../models/mfc/dicts/application-type.model';
import {handleError} from '../../../helpers/errorHandle-helper';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApplicationTypeService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/ApplicationType/`;

  constructor(private http: HttpClient) { }

  public getApplicationTypes(): Observable<ApplicationType[]> {
    return this.http.get<ApplicationType[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }

  public getApplicationType(id: string): Observable<ApplicationType> {
    return this.http.get<ApplicationType>(`${this.baseUrl}${id}`);
  }

  public addApplicationType(type: ApplicationType): Observable<ApplicationType> {
    return this.http.post<ApplicationType>(this.baseUrl, type)
      .pipe(catchError(handleError));
  }

  public updateApplicationType(type: ApplicationType): Observable<ApplicationType> {
    return this.http.put<ApplicationType>(this.baseUrl, type)
      .pipe(catchError(handleError));
  }

  public removeApplicationType(id: string): Observable<ApplicationType> {
    return this.http.delete<ApplicationType>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/errorHandle-helper";
import {
  MemberModel,
  PreviousStateExamCommissionModel, ShortListStateExamCommissionModel,
  StateExamCommissionModel
} from "../../models/gia/stateexamcommission/stateexamcommission.model";

@Injectable({
  providedIn: 'root'
})
export class StateExamCommissionService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.stateExamCommission}`;
  constructor(private http: HttpClient) { }

  public getList (filialId: string, trainingLevelId: string, yearAdmission: number): Observable<StateExamCommissionModel[]> {
    return this.http.get<StateExamCommissionModel[]>(`${this.baseUrl}/GetAll/${filialId}/${trainingLevelId}/${yearAdmission}`);
  }

  getPreviousList (filialId: string, trainingLevelId: string, yearAdmission: number): Observable<PreviousStateExamCommissionModel[]> {
    return this.http.get<PreviousStateExamCommissionModel[]>(`${this.baseUrl}/GetPreviousOrders/${filialId}/${trainingLevelId}/${yearAdmission}`);
  }

  getShortList (filialId: string, trainingLevelId: string, yearAdmission: number, type?: number): Observable<ShortListStateExamCommissionModel[]> {
    const filter = type ? `&type=${type}` :'';
    return this.http.get<ShortListStateExamCommissionModel[]>(`${this.baseUrl}/GetShortOrders/${filialId}/${trainingLevelId}/${yearAdmission}?${filter}`);
  }

  get(stateExamCommissionId: string): Observable<StateExamCommissionModel> {
    return this.http.get<StateExamCommissionModel>(`${this.baseUrl}/${stateExamCommissionId}`);
  }

  public getExternalReviewer(fio?: string): Observable<MemberModel> {
    const filter = fio ? `&Fio=${fio}` :'';
    return this.http.get<MemberModel>(this.baseUrl + `/GetExternalReviewer?${filter}`);
  }

  public getFile(stateExamCommissionId: string) {
    return this.http.get(`${this.baseUrl}/PrintById/${stateExamCommissionId}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }

  public updateStateExamCommission(model: StateExamCommissionModel): Observable<boolean> {
    return this.http.put<boolean>(`${this.baseUrl}`, model)
      .pipe(catchError(handleError));
  }

  public addStateExamCommission(model: StateExamCommissionModel): Observable<string> {
    return this.http.post<string>(this.baseUrl, model)
      .pipe(catchError(handleError));
  }

  public addPreviousOrder(stateExamCommissionId: string): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/AddPreviousOrder/${stateExamCommissionId}`, null);
  }
}

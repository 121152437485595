<middle-control-menu-access></middle-control-menu-access>
<h1>Настройки ведомости промежуточного контроля</h1>

<div class="content-container">
    <div class="title">
        <h3>Выбор полей для отображения</h3>
    </div>
    <div class="content" *ngFor = "let category of statementCategory; let c = index">
      <h2>{{category.name}}</h2>
      <div class="grid-box">
        <ng-container *ngFor = "let item of statementSettings.statementSettings; let i = index">
          <div class="child" *ngIf="category.externalId==item.dictStatementSettingsCategoryId">
            <input type="checkbox" kendoCheckBox [(ngModel)]="item.value" class="input-check"/>
            <kendo-label class="input-label">{{item.name}}</kendo-label>
          </div>
        </ng-container>
      </div>


        <ng-container *ngFor = "let item of statementSettings.statementRadioSettings; let c = index">
          <div class="child" *ngIf="category.externalId==item.dictStatementSettingsCategoryId">
            <div class="radio-button" *ngIf="item.statementSettingsEnum==statementSettingsEnum.vybor_prepodavatelya">
              <div class="button-block">
                <input
                  type="radio"
                  name="prepod"
                  value="select_teacher"
                  #select_teacher
                  [(ngModel)]="statementSettings.statementRadioSettings[c].value"
                  kendoRadioButton
                />
                <p class="text-area">Выбор преподавателя</p>
              </div>
              <div class="button-block">
                <input
                  type="radio"
                  name="prepod"
                  value="show_examenator"
                  #show_examenator
                  [(ngModel)]="statementSettings.statementRadioSettings[c].value"
                  kendoRadioButton
                />
                <p class="text-area">Отображение столбца "Экзаменатор"</p>
              </div>
            </div>

            <div class="radio-button"  *ngIf="item.statementSettingsEnum==statementSettingsEnum.vybor_podpisanta_vedomost_po_discipline">
              <div class="button-block">
                <input
                  type="radio"
                  name="signer"
                  value="select_signer"
                  #select_signer
                  [(ngModel)]="statementSettings.statementRadioSettings[c].value"
                  kendoRadioButton
                />
                <p class="text-area">Выбор подписанта</p>
              </div>
              <div class="button-block">
                <input
                  type="radio"
                  name="signer"
                  value="head_of_department"
                  #head_of_department
                  [(ngModel)]="statementSettings.statementRadioSettings[c].value"
                  kendoRadioButton
                />
                <p class="text-area">Подписант – заведующий кафедрой</p>
              </div>
            </div>
          </div>
        </ng-container>

    </div>

    <!--Buttons-->
    <div class="functionButtons editModeButtons">
      <button kendoButton
              size="small"
              (click)="closeHandler()">
        Закрыть
      </button>
      <button kendoButton
              size="small"
              themeColor="primary"
              (click)="applySetting()">
        Применить
      </button>
    </div>
</div>

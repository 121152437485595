export enum TrainingLevelEnum {
  Specialty = 1,
  Bachelor,
  Master,
  Graduate,
  Residency,
  Listener,
  College,
}

export const studentPosts = new Map<TrainingLevelEnum, string>([
  [TrainingLevelEnum.Specialty, 'Студент'],
  [TrainingLevelEnum.Bachelor, 'Студент'],
  [TrainingLevelEnum.Master, 'Студент'],
  [TrainingLevelEnum.Graduate, 'Аспирант'],
  [TrainingLevelEnum.Residency, 'Ординатор'],
  [TrainingLevelEnum.Listener, 'Слушатель'],
  [TrainingLevelEnum.College, 'Студент'],
]);

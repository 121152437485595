import {Dict} from './dict.model';
import {AccessRights} from './enums/access-rights.enum';
import {ApplicationType} from './dicts/application-type.model';
import {ApplicationCategory} from './dicts/application-category.model';
import {FacultyTreeItem} from './faculties-tree.model';

export class UserAccessForm {
  // ID пользователя
  personId?: string = undefined;
  // Роль оператора
  roles: string[] = [];
  // Шаблоны справок
  isApplicationConstructorTemplateAvailable: boolean = false;
  // Конструктор заявок
  isApplicationConstructorAvailable: boolean = false;
  // Настройки штампов ЭП
  isSignStampSettingsAvailable: boolean = false;
  // Создание справочника
  isDictCreateAvailable: boolean = false;
  // Справочники
  isDictAvailable: boolean = false;
  // Отчеты
  isReportAvailable: boolean = false;
  // Настройки доступа
  isAccessSettingsAvailable: boolean = false;
  // Доступ к заявкам
  userAccessAccessRights: UserAccessRightsForm[] = [new UserAccessRightsForm()];
}

export class UserAccessRightsForm {
  // Уровень доступа
  userAccessRight: AccessRights = AccessRights.Read;
  // Уровень подготовки
  trainingLevels: string[] = [];
  // Факультет
  faculties: FacultyTreeItem[] = [];
  // Форма обучения
  studyForms: string[] = [];
  // Типы заявок
  applicationTypes: (ApplicationType | ApplicationCategory)[] = [];
  // Все типы заявок
  isAllApplicationTypesAvailable: boolean = false;
  // Все факультеты
  isAllFacultiesAvailable: boolean = false;
  // Все формы обучения
  isAllStudyFormsAvailable: boolean = false;
  // Все уровни подготовки
  isAllTrainingLevelsAvailable: boolean = false;
}

export class UserAccessRightsList {
  personId: string = '';
  fio: string = '';
  userAccessAccessRights: ApplicationAccessRights[] = [new ApplicationAccessRights()];
  userAccessMainSettings: MainAccessRights = new MainAccessRights();
}

export class AccessMainSettings {
  isApplicationConstructorAvailable: boolean = false;
  isApplicationConstructorTemplateAvailable: boolean = false;
  isDictCreateAvailable: boolean = false;
  isDictAvailable: boolean = false;
  isReportAvailable: boolean = false;
  isSignStampSettingsAvailable: boolean = false;
  isAccessSettingsAvailable: boolean = false;
}

export class MainAccessRights extends AccessMainSettings {
  roles: Dict[] = [];
}

export interface CurrentUserAccess {
  isAdmin: boolean;
  user: CurrentUserAccessRight;
}

export interface CurrentUserAccessRight extends UserAccessRight {
  userAccessMainSettings: CurrentUserAccessMainSettings;
}

export interface CurrentUserAccessMainSettings extends AccessMainSettings {
  applicationAccess: AccessRights;
}

export interface UserAccessRight {
  personId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  roles: Dict[];
  userAccessMainSettings: AccessMainSettings;
  userAccessAccessRights: ApplicationAccessRights[];
}

export interface UpdateAccessRights {
  personId: string;
  roles: string[];
  userAccessAccessRights: UpdateApplicationAccess[];
  userAccessMainSettingsDto: AccessMainSettings;
}

export class ApplicationAccess {
  userAccessRight: AccessRights = AccessRights.Read;
  isAllApplicationTypesAvailable: boolean = false;
  isAllFacultiesAvailable: boolean = false;
  isAllStudyFormsAvailable: boolean = false;
  isAllTrainingLevelsAvailable: boolean = false;
}

export class ApplicationAccessRights extends ApplicationAccess {
  applicationTypes: Dict[] = [];
  faculties: Dict[] = [];
  studyForms: Dict[] = [];
  trainingLevels: Dict[] = [];
}

export class UpdateApplicationAccess extends ApplicationAccess {
  trainingLevels: string[] = [];
  faculties: string[] = [];
  studyForms: string[] = [];
  applicationTypes: string[] = [];
}

export interface ErrorInfo {
  fieldName: string;
  isDuplicate: boolean;
  duplicateRowIndex?: number;
  hasIntersection: boolean;
  intersectionRowIndex?: number;
}

export const filterKeyMap = new Map<string, string>([
  ['trainingLevels', 'isAllTrainingLevelsAvailable'],
  ['faculties', 'isAllFacultiesAvailable'],
  ['studyForms', 'isAllStudyFormsAvailable'],
  ['applicationTypes', 'isAllApplicationTypesAvailable'],
]);

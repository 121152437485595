import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { Observable } from "rxjs";
import { Mark } from "src/app/models/dicts/mark.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MarkService {
    private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.mark}`;

    constructor(private http: HttpClient) {
    }

    public updateMark(mark: Mark): Observable<Mark> {
        return this.http.put<Mark>(this.baseUrl + '/' + mark.id, mark);
    }

    public getAllMarks(): Observable<Mark[]> {
        return this.http.get<Mark[]>(this.baseUrl);
    }

    public addMark(mark: Mark): Observable<Mark> {
        return this.http.post<Mark>(this.baseUrl, mark);
    }

    public deleteMark(markId: Guid): Observable<Mark> {
        return this.http.delete<Mark>(this.baseUrl + '/' + markId);
    }
}
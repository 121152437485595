import { Component, OnInit, ViewChild, Output} from '@angular/core';
import { EditorModule, EditorComponent } from "@progress/kendo-angular-editor";
import { Router, RouterLink } from "@angular/router";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, Subscription, throwError } from 'rxjs';
import { Announcement } from 'src/app/models/announcement/announcement.model';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { FoundStudentsService} from 'src/app/services/announcement/found-students.service';
import { FoundPersonsService} from 'src/app/services/announcement/found-persons.service';
import { AnnouncementUserAccessService } from 'src/app/services/useraccess/announcement-user-access.service';
import { AnnouncementUserAccess } from 'src/app/models/useraccess/announcement/announcementuseraccess.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import { FileRestrictions, SelectEvent, FileSelectComponent } from "@progress/kendo-angular-upload";
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import urlJoin from 'url-join';
import { Location } from '@angular/common'
import { DialogComponent } from "./dialog/dialog.component";
import { checkRole } from '../../../../helpers/token/role-check';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

export class Teg{
    constructor( public title: string)
    {}
}

@Component({
    selector: 'app-announcement',
    templateUrl: './announcement.component.html',
    styleUrls: ['./announcement.component.scss'],
})

export class AnnouncementComponent implements OnInit{
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);

    SendNews: boolean = false;
    SpecifyDate: boolean = false;
    isCheckedDate: boolean = false;

    public myRestrictions: FileRestrictions = {
        maxFileSize: environment.announcement.maxFileSize,
        allowedExtensions: [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".jpeg", ".gif", ".tif", ".bmp", ".png", ".zip", ".txt"],
    };

    public TitleMessage: string = "";
    public Content: string = "";
    public Type: number = 0;
    public Draft: boolean = false;
    public Postpone: Date | undefined;
    public Published: Date = new Date();
    public Attachments: Array<any> = [];
    public Deliveries: Array<any> = [];
    public Tags: {} = {};
    public messageId: number = 0;
    public externalId: Array<any> = [];

    public title: string = "";
    public tegs: Teg[] = [];

    public pattern: boolean = false;
    private subscriptions: Subscription[] = [];

    public name: [] = [];

    public isAdmin: boolean = false;
    public personId: string = "";
    public accessLevel: boolean = false;
    public accesses: AnnouncementUserAccess[] = [];

    public deliveriesFromList: any = [];

    public refreshBadge: number = 0;
    public checkTitleContent: boolean = true;

    countFiles: number = 5;

    public getAccess (){
        this.userAccessService.getAccess(this.personId)
            .subscribe(res => {
                this.accesses = res;
            });
        this.accessLevel = this.accesses.length > 0 ? this.accesses[0].userAccessLevel : false;
    }

    addTeg(){
        if (this.title !== ""){
            this.tegs.push(new Teg(this.title));
        }
        this.title = "";
    }

    public deleteTeg(deletetitle: string){
       this.tegs = this.tegs.filter(value => value.title !== deletetitle);
    }

    public removeFile(i : number) {
        this.Attachments.splice(i, 1);
        return false;
    }

    public removeOldFile(i : number) {
        this.oldAttachments.splice(i, 1);
        return false;
    }

    private zeroingOut(){
        this.announcementService.currentAnnouncement.next({
            deliveries: [],
            deliveriesFromList: [],
            announcement: [],
        });

        this.foundpersonsService.getSelectPersons([], []);
        this.foundStudentsService.getSelectStudents([], []);
        this.foundStudentsService.getDistributionStudents([]);
        this.foundpersonsService.getDistributionPersons([]);

        this.announcementService.SendPattern.next({
            messageId: 0,
            pattern  : false,
          });
    }

    public addDeleveriesFromList(){
        for(let i = 0; i < this.deliveriesFromList.length; i++){
            if (this.Deliveries.findIndex((item: any) => item.userExternalId == this.deliveriesFromList[i].userExternalId) == -1) this.Deliveries.push(this.deliveriesFromList[i])
         }
    }

    public getFormData(){
        var formData = new FormData();
        this.addDeleveriesFromList();

        for(var i =0; i < this.tegs.length; i++) {
            formData.append('tags['+i+'][title]', this.tegs[i]['title']);
        }

        let arrayOfDeliveries = "";
        arrayOfDeliveries = this.Deliveries.reduce(
            (stringDeliveries, curr) => stringDeliveries + (curr.userId == undefined ? curr.userRecipientId : curr.userId) + ',',
            arrayOfDeliveries
        );
        arrayOfDeliveries = arrayOfDeliveries.slice(0, -1);
        formData.append('deliveries', arrayOfDeliveries.toString());

        formData.append('messageType', this.Type.toString());
        if (this.TitleMessage !== null) formData.append('title', this.TitleMessage.trim());

        if (this.Content !== null) formData.append('content', this.Content.trim());

        this.Attachments.forEach(element => {
            formData.append('attachments', element);
        });

        return formData;
    }

    public publish(messageId: number){
        if (this.TitleMessage == null || this.Content == null || this.TitleMessage.trim() === "" || this.Content.trim() === "" || this.Content == '<p></p>') {
            this.notificationService.showError("Заполните обязательные поля");
            this.undefinedFilds();
        }
        else {
            var formData = new FormData();
            formData = this.getFormData();
            formData.append('isDraft', 'false');

            //действующий вариант отправки файлов//
            formData  = this.appendOldAttachments(formData);
            ///

             //  новый вариант отправки файлов//
            /*   this.oldAttachments.forEach(element => {
                console.log('element', JSON.stringify(element));
                formData.append('oldAttachments', JSON.stringify(element));
            });*/

            let headers = this.getHeaders();
            if (messageId == 0){
                this.http.post<Announcement>(this.baseUrl + '/message', formData, { headers: headers })
                    .subscribe((res) => {
                        this.announcementService.Badge(this.refreshBadge + 1);
                        this.zeroingOut();
                    });
            }
            else {
               /* for(var i = 0; i < this.oldAttachments.length; i++) {
                    formData.append('oldAttachments['+i+'][id]', this.oldAttachments[i]['id']);
                }*/
                formData.append('published', new Date().toISOString());
                this.http.put(this.baseUrl + '/messages/' + messageId, formData, { headers: headers })
                    .subscribe((res) => {
                        this.zeroingOut();
                    });
            }
            this.notificationService.showSuccess("Оповещение опубликовано");
            this.router.navigate(['/alert']);
        }
    }

    public postponedate: string = "";

   /* public publication_later(messageId: number){
        if (this.TitleMessage == null || this.Content == null || this.TitleMessage.trim() === "" || this.Content.trim() === "" ) {
            this.notificationService.showError("Заполните заголовок и текст новости/объявления");
        }
        else {
            var formData = new FormData();
            formData = this.getFormData();
            if(this.Postpone !== undefined) {
                this.postponedate = this.Postpone.toISOString();
                formData.append('postpone', this.postponedate);
            }
            formData.append('isDraft', this.Draft.toString());

            let headers = this.getHeaders();
            if (messageId == 0) {
                formData  = this.appendOldAttachments(formData);
                this.http.post<Announcement>(this.baseUrl + '/message', formData, { headers: headers })
                    .subscribe((res) => this.zeroingOut());
            }
            else {
                for(var i =0; i < this.oldAttachments.length; i++) {
                    formData.append('oldAttachments['+i+'][id]', this.oldAttachments[i]['id']);
                }
                this.http.put(this.baseUrl + '/messages/' + messageId, formData, { headers: headers })
                    .subscribe((res) => this.zeroingOut());
            }

            this.router.navigate(['/alert']);
        }
    }

    public inDraft(messageId: number){

        var formData = new FormData();
        formData = this.getFormData();
        if (this.Postpone !== undefined && this.Postpone !== null) {
            this.postponedate = this.Postpone.toISOString();
            formData.append('postpone', this.postponedate);
        }
        formData.append('isDraft', 'true');

        let headers = this.getHeaders();
        if (messageId == 0){
            formData  = this.appendOldAttachments(formData);
            this.http.post<Announcement>(this.baseUrl + '/message', formData, { headers: headers })
                .subscribe((res) => {
                    this.zeroingOut();
            });
        }
        else {
            for(var i =0; i < this.oldAttachments.length; i++) {
                formData.append('oldAttachments['+i+'][id]', this.oldAttachments[i]['id']);
            }
            this.http.put(this.baseUrl + '/messages/' + messageId, formData, { headers: headers })
                .subscribe((res) => {
                    this.zeroingOut();
            });
        }

        this.router.navigate(['/alert']);
    }*/

    public borderTitleRed: boolean = false;
    public borderEditorRed: boolean = false;
    public borderDateRed: boolean = false;

    public place(messageId: number, draft: boolean, checkTitleContent?: boolean){
        if (checkTitleContent == true && (this.Postpone == undefined || this.TitleMessage == null || this.Content == null || this.TitleMessage.trim() === "" || this.Content.trim() === "" || this.Content == '<p></p>')) {
            this.notificationService.showError("Заполните обязательные поля");
            if (this.Postpone == undefined) this.borderDateRed = true;
            this.undefinedFilds();
        }
        else {
            var formData = new FormData();
            formData = this.getFormData();

            if (this.Postpone !== undefined && this.Postpone !== null) {
                this.postponedate = this.Postpone.toISOString();
                formData.append('postpone', this.postponedate);
            }
            formData.append('isDraft', draft.toString());
            formData  = this.appendOldAttachments(formData);
            let headers = this.getHeaders();

            if (messageId == 0){
              //  formData  = this.appendOldAttachments(formData);
                this.http.post<Announcement>(this.baseUrl + '/message', formData, { headers: headers })
                    .subscribe((res) => this.zeroingOut());
            }
            else {
              /*  for(var i =0; i < this.oldAttachments.length; i++) {
                    formData.append('oldAttachments['+i+'][id]', this.oldAttachments[i]['id']);
                }*/
                this.http.put(this.baseUrl + '/messages/' + messageId, formData, { headers: headers })
                .subscribe((res) => this.zeroingOut());
            }

            this.router.navigate(['/alert']);
        }
    }

    public appendOldAttachments(formData: any){
        for(var i = 0; i < this.oldAttachments.length; i++) {
            formData.append('oldAttachments['+i+'][id]', this.oldAttachments[i]['id']);
            formData.append('oldAttachments['+i+'][name]',  this.oldAttachments[i]['name']);
            formData.append('oldAttachments['+i+'][externalId]', this.oldAttachments[i]['externalId'] );
        }
        return formData;
    }

    public getHeaders(){
        let headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Credentials','true');
        return headers;
    }

    constructor(
        private router: Router,
        private http: HttpClient,
        private announcementService: AnnouncementService,
        private activatedroute: ActivatedRoute,
        private foundpersonsService: FoundPersonsService,
        private foundStudentsService: FoundStudentsService,
        private location: Location,
        private tokenStore: TokenStorageService,
        private jwtHelper: JwtHelperService,
        private userAccessService: AnnouncementUserAccessService,
        private notificationService: NotificationsService,
        private dialogService: DialogService,
        )
    {
        this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
    }

    public ShowNews(){
        this.SendNews = !this.SendNews;
    }

    public ShowDate(){
        this.SpecifyDate = !this.SpecifyDate;
        this.isCheckedDate = (this.isCheckedDate === true )? false : true;
        if (this.isCheckedDate == false) this.Postpone = undefined;
    }

    public message: any = [];
    public oldAttachments: Array<any> = [];

    public getAnnouncement(id: number) {
        if (id !== undefined){
            this.announcementService.getAnnouncement(id)
            .subscribe(response => {
                this.message = response;
                this.messageId = id;
                this.Draft = this.message.isDraft;
                if  (this.Draft == false) {
                    this.getDataToEdit(this.message);
                    for (let i = 0; i < this.message.deliveries.length; i++) {
                        this.Deliveries.push(this.message.deliveries[i]);
                    }
                }
                if  (this.Draft == true) {
                    let state = this.announcementService.currentAnnouncement.getValue();
                    this.message = state.announcement;
                    this.getDataToEdit(this.message);

                    for (let i = 0; i < state.deliveries.length; i++) {
                        this.Deliveries.push(state.deliveries[i]);
                    }
                }
            });
        }
    }

    private getDataToEdit(message: any){
        this.TitleMessage = message.title;
        this.Content      = message.content;
        this.tegs         = message.tags;
        this.Draft        = message.isDraft;
        this.Published    = message.published;

        message.attachments.forEach((element: any) => {
           if (element.lastModified === undefined){
              this.oldAttachments.push(element);
           } else {
              this.Attachments.push(element);
           }
        });

        this.Type = message.messageType;
        if (this.Type == 1){
            this.SendNews = true;
        }

        this.Postpone = message.postpone;
        if (this.Postpone !== null){
            this.SpecifyDate = true;
            this.isCheckedDate = true;
            this.Postpone = new Date(message.postpone);
        }
    }

    public BackToList(){
       /* if (this.Draft == true){
          //  console.log('back',this.announcementService.currentAnnouncement.getValue());
            this.announcementService.currentAnnouncement.next({
                ...this.announcementService.currentAnnouncement.getValue(),
                announcement: {messageId: this.messageId, deliveries: this.Deliveries, tags: this.tegs, messageType: this.Type, isDraft: this.Draft,
                               postpone: this.Postpone, title: this.TitleMessage, content: this.Content, attachments: this.Attachments.concat(this.oldAttachments), userPost: null},
                back: true,
                pattern: false,
            });
          this.location.back();
        } else{*/
            this.announcementService.SendPattern.next({
                messageId: 0,
                pattern:   false,
            });

            this.announcementService.currentAnnouncement.next({
            ...this.announcementService.currentAnnouncement.getValue(),
            announcement: {messageId: this.messageId, deliveries: this.Deliveries, tags: this.tegs, messageType: this.Type, isDraft: this.Draft,
                postpone: this.Postpone, title: this.TitleMessage, content: this.Content, attachments: this.Attachments.concat(this.oldAttachments), userPost: null},
            back: true,
            pattern: false,
            });
            this.location.back();
      //  }
    }

    public openpopup(){
        const dialog: DialogRef = this.dialogService.open({
            title: "Пожалуйста подтвердите",
            content: `Вы действительно хотите закрыть форму добавления оповещения без сохранения изменений?`,
            actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
            width: 450,
            height: 200,
            minWidth: 250,
          });
          dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
                if(result.text == "Да"){
                    this.Undo();
                }
              }
            })
    }

    public Undo(){
        this.zeroingOut();
        this.router.navigate(['/alert']);
    }

    @ViewChild("upload") public dialog: DialogComponent = new DialogComponent;
    @Output() @ViewChild("editor") public editor: any;

    public open(){
        this.dialog.open();
    }

    ngOnInit() {
        this.getAccess();

        let editMessageId = this.activatedroute.snapshot.params['id'];

        let state = this.announcementService.currentAnnouncement.getValue();

        this.deliveriesFromList = state.deliveriesFromList;
     //   console.log('из спииска',this.deliveriesFromList);
        if ( +editMessageId > 0) {
            // Ред - есть editmessageId -> грузим
            this.getAnnouncement(this.activatedroute.snapshot.params['id']);
        }
        else {
            // Созд - нет ни того ни другого и не было возврата -> восстанавливаем
            // Возв - есть возврат -> восстанавливаем

            if (state.announcement && state.announcement.title !== undefined) {
                this.TitleMessage = state.announcement.title;
                this.Content = state.announcement.content;
                this.Draft = state.announcement.isDraft;
                if (state.announcement.messageType !== undefined){
                    this.Type = state.announcement.messageType;
                }
                if (this.Type == 1){
                    this.SendNews = true;
                }
                if (state.announcement.tags !== undefined){
                    for (let i=0; i<state.announcement.tags.length; i++){
                        this.tegs.push(state.announcement.tags[i])
                    }
                }

                state.announcement.attachments.forEach((element: any) => {
                    if (element.lastModified === undefined){
                       this.oldAttachments.push(element);
                    } else {
                       this.Attachments.push(element);
                    }
                 });
          //    console.log('attach',this.Attachments);
          //    console.log('old',this.oldAttachments);
                this.Postpone = state.announcement.postpone;
                if (this.Postpone !== null && this.Postpone !== undefined){
                    this.SpecifyDate = true;
                    this.isCheckedDate = true;
                    this.Postpone = new Date(state.announcement.postpone);
                }
            }

            let MailingList: any[] = state.deliveries;

            for (let i=0; i < MailingList.length; i++){
                if (this.Deliveries.some(e => e.userRecipientId === MailingList[i].userId) == false){
                    this.Deliveries.push(MailingList[i]);
                }
                if (this.Deliveries.some(e => e.userRecipientId === MailingList[i].userRecipientId) == false){
                    this.Deliveries.push(MailingList[i]);
                }
            }
        }
    }

    /*public valueChange() {
         if(this.Attachments !== null && (this.Attachments.length + this.oldAttachments.length > 5))
            this.Attachments.length = 5 - this.oldAttachments.length;
    }*/

    public select(e: SelectEvent){
       // console.log('e',e.files);
        for (let i = 0; i < e.files.length; i++){
            if(e.files[i].validationErrors == undefined) this.Attachments.push(e.files[i].rawFile)
            else this.notificationService.showError("Файл данного формата добавить нельзя");
        }
       // console.log('select attach',this.Attachments);
        if(this.Attachments !== null && (this.Attachments.length + this.oldAttachments.length > this.countFiles)){
            this.notificationService.showError("Можно добавить не более 5 файлов");
            this.Attachments.length = this.countFiles - this.oldAttachments.length;
        }
    }

    public undefinedFilds(){
        if (this.TitleMessage == null || this.TitleMessage.trim() === "") this.borderTitleRed = true;
        if (this.Content == null || this.Content.trim() === "" || this.Content == '<p></p>') this.borderEditorRed = true;
    }

    public onChange(value: string): void {
        if (value !== "" || this.TitleMessage !== null) this.borderTitleRed = false;
    }

    public valueChangeEditor(value: any){
        if (!(this.Content == null || this.Content.trim() === "" || this.Content == '<p></p>')) this.borderEditorRed = false
    }

    public onChangeDate(value:any){
        if (value !== undefined) this.borderDateRed = false;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StudenthistoryService} from "../../../../services/contingent/studenthistory.service";
import {StudentHistory} from "../../../../models/contingent/studenthistory.model";
import {ChangeHistoryEnum} from "../../../../models/contingent/enums/group-change-history.enum";
import {Role} from "../../../../models/useraccess/role";
import {TokenStorageService} from "../../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {Guid} from "guid-typescript";

@Component({
  selector: 'app-studenthistorylist',
  templateUrl: './studenthistorylist.component.html',
  styleUrls: ['./studenthistorylist.component.scss']
})
export class StudenthistorylistComponent implements OnInit {

  public studentId!: any;
  public loading = false;
  public editMode = false;
  public opened = false;
  public isNew = false;
  public editable = false;
  public isCheckDateAll = false;
  public checkedDateDefault: { [key: string]: boolean } = {};
  public checkedDate: { [key: string]: boolean } = {};
  public selectedEntries: Guid[] = [];

  constructor(private historyService: StudenthistoryService,
              private activateRoute: ActivatedRoute,
              private router: Router,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,)
  {
    if(activateRoute.snapshot.parent!==null)
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
  }

  ngOnInit(): void {
    this.admin()
    this.getHistory();
  }

  public history: StudentHistory[] = []
  public historyEnum: any = ChangeHistoryEnum;

  public getHistory() {
    this.loading = true;
    this.historyService.getStudentHistory(this.studentId)
      .subscribe(
        response => {
          this.history = response;
          this.history.forEach((x) => x.date = new Date(x.date!))
          this.checkedDateDefault = this.history.reduce((checked, entry) => {
            checked[entry.id.toString()] = false;
            return checked;
          }, {} as { [key: string]: boolean });

          this.checkedDate = { ...this.checkedDateDefault };
          this.loading = false;
        }
      )
  }

  private admin(){
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null) user = this.jwtHelper.decodeToken(token);
    if(user.role.includes(Role.Admin)) {
      this.editable = true
    }
  }

  public openDialog(dataItem: any, mass: boolean){
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста, подтвердите",
      content: "Вы действительно хотите удалить "  + (mass ? "записи из истории" : dataItem.typeName) + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    return dialog
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog = this.openDialog(dataItem, false)

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) { }
      else {
        if(result.text == "Да"){
          this.opened = false;
          this.historyService.deleteStudentHistory(dataItem.id)
            .subscribe({
              next:() => {
                this.getHistory();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public selectAll() {
    this.selectedEntries =
      this.selectedEntries.length === this.history.length
        ? []
        : this.history.map((entry) => entry.id);
    if(this.selectedEntries.length != 0)
      this.selectedEntries.shift()
    this.selectAllDateChecked()
  }

  public selectAllDateChecked() {
    if (this.isCheckDateAll) {
      this.checkedDate = { ...this.checkedDateDefault };
    }
    else {
      for (let id in this.checkedDate) {
        this.checkedDate[id] = true;
      }
      this.checkedDate[this.history[0].id.toString()] = false;
    }
    this.isCheckDateAll = !this.isCheckDateAll;
  }

  public checkedChange(event: any, id: Guid) {
    this.checkedDate[id.toString()] = event.target?.checked;
    this.isCheckDateAll = Object.values(this.checkedDate).every(
      (checked) => checked
    );
  }

  get disableMassDelete(): boolean{
    return Object.values(this.checkedDate).filter(
      (checked) => checked
    ).length == 0;
  }

  public deleteSelected() {
    const checked =   Object.keys(this.checkedDate).filter(
      (key) => this.checkedDate[key]
    );

    const dialog = this.openDialog(checked, true)

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) { }
      else {
        if(result.text == "Да"){
          this.opened = false;
          this.historyService.deleteStudentHistoryList(checked)
            .subscribe({
              next:() => {
                this.getHistory();
                this.checkedDate = { ...this.checkedDateDefault };
                this.selectedEntries = []
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public openDetails(dataItem: any) {
    this.router.navigateByUrl(`/contingent/studentmaininfo/history/${this.studentId}/${dataItem.id}`).then();
  }

}

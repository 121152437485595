import { Component, OnInit } from '@angular/core';
import { items } from 'src/app/models/publications/menu-items';
import {PublicationsDictsService} from "../../../../services/science/publications/dicts.service";
import {Dicts} from "../../../../models/publications/dicts.model";
import {PublicationsUserAccessService} from "../../../../services/useraccess/publications-user-access.service";
import {PublicationCurrentUserAccess} from "../../../../models/useraccess/publications/publicationsUserAccess";

@Component({
  selector: 'publications-settings',
  templateUrl: './publications-settings.component.html',
  styleUrls: ['./publications-settings.component.scss']
})
export class PublicationsSettingsComponent implements OnInit {
  //Menu items
  public items: any[] = items;
  public dictItems: any[] = [];
  private currentUser: PublicationCurrentUserAccess = this.userAccessService.currentUserAccess$.value;

  constructor(private dictService: PublicationsDictsService,
              private userAccessService: PublicationsUserAccessService) {}

  async ngOnInit() {
    await this.getUserAccess();
    this.getDicts();
  }

  private async getUserAccess() {
    if (!this.userAccessService.currentUserAccess$.value.isAdmin) {
      this.items[0].items = this.items[0].items.filter((item: any) => {
        return (this.currentUser.dict ? item : !item.dicts)
          && (this.currentUser.accessSettings ? item : !item.accessSettings)
          && (this.currentUser.author ? item : !item.authors)
          && (this.currentUser.report ? item : !item.reports)
          && ((this.currentUser.myPublication || this.currentUser.allPublication) ? item : !item.publications)
      });
    }
  }

  private getDicts() {
    if (!this.dictService.dictsLoaded$.value && (this.userAccessService.currentUserAccess$.value.isAdmin || this.userAccessService.currentUserAccess$.value.dict)) {
      this.dictService.dictsLoaded$.next(true);
      this.dictService.getDynamicDicts().subscribe((value) => {
        this.dictService.dicts$.next(value);
        this.dictItems = this.mapDictItems(value);
        this.addDictItems();
      });
    }
  }

  private mapDictItems(dicts: Dicts[]) {
    return dicts.map((item) => ({
      text: item.name,
      path: `/publications/dict/${item.dictId}`
    }))
  }

  private addDictItems() {
    const dictIndex = this.items[0].items.findIndex((item: any) => item.dicts);
    this.items[0].items[dictIndex].items = this.items[0].items[dictIndex].items.concat(this.dictItems);
  }

}

<div class="footer">
  <div class="footer__container__header">
    <h5 class="footer__title">Итого оценок:</h5>
    <div class="footer__buttons">
      <discipline-action-buttons
              (onComeback)="handleClose()"
      ></discipline-action-buttons>
    </div>
  </div>
  <div class="footer__container">
    <div class="footer__total">
      <p class="footer__text" *ngFor="let item of gradeLevelList">
        {{ item.markName }}:
        <span class="footer__text__number">{{ marksStatistic[item.id.toString()] || 0 }}</span>
      </p>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {CurrentControlMark, StudentMark, StudentMarkRequest} from "../../models/contingent/studentmark.model";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class StudentmarkService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studentmark}`;

  constructor(private http: HttpClient) { }

  //Get all StudentMark
  async getStudentMark(request: StudentMarkRequest): Promise<Observable<StudentMark[]>> {
    return this.http.post<StudentMark[]>(this.baseUrl + '/GetStudentMark/', request)
      .pipe(catchError(this.handleError));
  }

  async getCurrentControlMark(request: StudentMarkRequest): Promise<Observable<CurrentControlMark[]>> {
    return this.http.post<CurrentControlMark[]>(this.baseUrl + '/GetCurrentStudentMark/', request)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

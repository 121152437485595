import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {KendoProperties} from "../../../../models/mfc/enums/kendo-properties.enum";
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Column, TableValue} from "../../../../models/mfc/applicationForm/properties.model";
import {AddEvent, CancelEvent, EditEvent, RemoveEvent, SaveEvent} from "@progress/kendo-angular-grid";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {
  GroupProperty,
  Property
} from '../../../../models/mfc/applicationConstructor/settings/constructor-tabs-settings.model';
import {DownloadFile} from '../../../../helpers/downloadFile-helper';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {PropertiesService} from '../../../../services/mfc/properties.service';
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';
import {Subscription} from 'rxjs';
import {GroupPropertyForm} from '../../../../models/mfc/applicationForm/applicationForm.model';
import {studentFieldsMap} from "../../../../models/mfc/student-fields";
import {UserAccessService} from '../../../../services/mfc/userAccess.service';
import {ApplicationFormService} from '../../../../services/mfc/application-form.service';
import {ExternalFile} from '../../../../models/mfc/applicationForm/application-property-value.model';
import {saveAs} from '@progress/kendo-file-saver';


@Component({
  selector: 'mfc-dynamic-properties',
  templateUrl: './dynamic-properties.component.html',
  styleUrls: ['./dynamic-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicPropertiesComponent implements OnInit, OnDestroy {
  @Input() groups?: GroupProperty[] = [];
  @Input() editable = false;
  @Input() index = 0;
  @Input() isAdd = false;

  private hasChanges = false;
  protected editMode = false;
  protected userRoles: string[] = [];

  protected readonly KendoProperties = KendoProperties;
  protected readonly tableTemplate?: {property: TableValue };
  protected readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  private allowedExtensions = ['.docx', '.doc', '.pdf', '.gif', '.jpg', '.png', '.tif', '.xls', '.xlsx'];
  protected readonly fileRestrictions = {
    allowedExtensions: this.allowedExtensions,
    accept: this.allowedExtensions.join(', '),
    maxCount: 10
  };

  private saveSubscription$!: Subscription;
  private checkChangesSubscription$!: Subscription;

  protected groupsFormGroup = this.createGroupFormGroup();
  protected readonly studentFieldsMap = studentFieldsMap;

  constructor(
    private notificationsService: NotificationsService,
    private propertiesService: PropertiesService,
    private editFormService: ApplicationEditFormService,
    private applicationFormService: ApplicationFormService,
    private userAccessService: UserAccessService
  ) { }

  ngOnInit(): void {
    this.userRoles = this.userAccessService.currentUserAccess$.value?.user.roles
      .map((item) => item.id) ?? [];
    this.groupsFormGroup = this.createGroupFormGroup(this.groups);
    this.patchFormValues();
    this.subscribe();
  }

  private subscribe() {
    this.saveSubscription$ = this.editFormService.save$.subscribe(value => {
      if (this.index === value) {
        this.editFormService.setHasChanges(this.hasChanges);
        this.saveChanges();
      }
    });
    this.checkChangesSubscription$ = this.editFormService.checkChanges$.subscribe(value => {
      if (this.index === value) {
        this.editFormService.setHasChanges(this.hasChanges);
      }
    });
  }

  private createGroupFormGroup(groups?: GroupProperty[]) {
    return new FormGroup({
      groups: this.mapGroupFormArray(groups)
    });
  }

  private mapGroupFormArray(groups?: GroupProperty[]) {
    const formArr = new FormArray<FormGroup>([]);
    groups?.forEach((item) => {
      formArr.push(this.createFormGroup(item));
    });
    return formArr;
  }

  private createFormGroup(group: GroupProperty) {
    const properties = [...group.standardProperties, ...group.additionalProperties];
    return new FormGroup({
      properties: this.mapFormArrayProperties(properties)
    });
  }

  private mapFormArrayProperties(properties: Property[]) {
    const formArr = new FormArray<FormGroup>([]);
    properties.forEach((item) => {
      formArr.push(this.patchFormArrayData(item));
    });
    return formArr;
  }

  private patchFormArrayData(item: Property) {
    const arr: { [key: string]: AbstractControl<unknown> } = {};
    Object.keys(item).forEach((key) => {
      arr[key] = new FormControl(item[key as keyof Property]);
    });

    const editable  = this.editable && this.getPropertyAccess(item);
    arr['propertyValue'] = new FormControl({
      value: item.value ? this.propertiesService.valueViewConverter(item) : null,
      disabled: !editable
    }, (item.required ? Validators.required : null));

    arr['editable'] = new FormControl(editable);

    return new FormGroup(arr);
  }

  private getPropertyAccess(item: Property) {
    return item.isEditableForAll || (this.isAdd
      ? item.isEditableForInitiator
      : this.userRoles.some((role) => item.roleIds.includes(role)));
  }

  private patchFormValues() {
    const data = this.editFormService.getTabValues(this.index);
    if (data) {
      this.groupsFormGroup.controls.groups.controls.forEach((group) => {
        (<FormArray>group.controls['properties']).controls.forEach((property) => {
          const value = data.find((item) =>
            item.applicationConstructorPropertyId === property.value.externalId)?.value;
          property.get('propertyValue')?.patchValue(value ?? null);
        });
      });
    }
  }

  private saveChanges() {
    const formValues = this.propertiesService.mapFormValues(<GroupPropertyForm[]>this.groupsFormGroup.value.groups);
    this.editFormService.saveTabData(this.index, formValues);
  }

  protected formProperties(index: number) {
    return <FormArray>this.groupsFormGroup.get('groups')?.get(`${index}`)?.get('properties');
  }

  protected valueChange() {
    this.hasChanges = true;
  }

  protected fileChange(filesControl: AbstractControl|null, files?: File[]) {
    if (files?.length && files?.length > this.fileRestrictions.maxCount) {
      this.notificationsService.showError('Нельзя загрузить больше 10 файлов');
      if (filesControl) {
        filesControl.value.splice(this.fileRestrictions.maxCount, files.length - this.fileRestrictions.maxCount);
        filesControl.patchValue(filesControl.value);
      }
    }
    this.valueChange();
  }

  protected downloadFiles(files?: ExternalFile[]) {
    files?.forEach((file) => {
      if (file.id) {
        this.applicationFormService.downloadPropertyFile(file.id).subscribe((response) => {
          saveAs(response, file.name);
        });
      } else {
        DownloadFile(file);
      }
    });
  }

  protected addRow({sender}: AddEvent, columns: Column[]) {
    const item = columns.reduce((obj, item) => ({
      ...obj,
      [item.field]: null,
    }), {});
    const formGroup = this.createTableFormGroup(item);
    this.editMode = true;
    sender.addRow(formGroup);
  }

  protected editRow({dataItem, sender, rowIndex}: EditEvent) {
    if (this.editMode) {
      return;
    }
    this.editMode = true;
    const formGroup = this.createTableFormGroup(dataItem);
    sender.editRow(rowIndex, formGroup);
  }

  protected saveRow({formGroup, sender, rowIndex, isNew}: SaveEvent, data: unknown[]) {
    const item = formGroup.getRawValue();
    isNew ? data.push(item) : data[rowIndex] = item;
    sender.closeRow(rowIndex);
    this.editMode = false;
    this.valueChange();
  }

  protected cancelRowEditing({sender, rowIndex}: CancelEvent) {
    sender.closeRow(rowIndex);
    this.editMode = false;
  }

  protected removeRow({rowIndex}: RemoveEvent, data: unknown[]) {
    data.splice(rowIndex, 1);
  }

  private createTableFormGroup(item: Object) {
    const arr: { [key: string]: AbstractControl<unknown> } = {};
    Object.keys(item).forEach((key) => {
      arr[key] = new FormControl(item[key as keyof typeof item]);
    });
    return new FormGroup(arr);
  }

  private unsubscribe() {
    this.saveSubscription$.unsubscribe();
    this.checkChangesSubscription$.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}

<div class="department-workload-container">
  <workload-settings></workload-settings>
  <h1>Нагрузка для кафедры</h1>
  <span class="workloadDropdownContainer">
      <h3>Учебный год</h3>
      <kendo-dropdownlist
        [data]="yearAndSemestrs"
        [(ngModel)]="yearAndSemestrsEdit"
        (valueChange)="yearAndSemestrsValueChange($event)"
        [valuePrimitive]="false"
        valueField="name"
        textField="name"
        class="workloadDropdown">
      </kendo-dropdownlist>
  </span>
  <span class="workloadDropdownContainer">
      <h3>Кафедра</h3>
      <kendo-combobox #comboboxKafedra class="workloadDropdown"
                      textField="name" valueField="id"
                      [data]="kafedras"
                      [(ngModel)]="kafedraEdit"
                      [kendoDropDownFilter]="filterSettings"
                      [openOnFocus]="comboboxKafedra"
                      [valuePrimitive]="false"
                      [virtual]="filterVirtualization"
                      [filterable]="true"
                      (valueChange)="kafedraValueChange($event)">
      </kendo-combobox>
  </span>
  <span class="workloadDropdownContainer">
      <h3>Уровень подготовки</h3>
      <kendo-multiselect class="workloadDropdown" textField="name" valueField="id"
                         [data]="trainingLevels"
                         [kendoDropDownFilter]="filterSettings"
                         [valuePrimitive]="true"
                         [(ngModel)]="trainingLevelsEdit"
                         (valueChange)="trainingLevelValueChange($event)">
      </kendo-multiselect>
  </span>
  <span class="workloadDropdownContainer">
      <h3>Институт (факультет)</h3>
      <kendo-dropdownlist class="workloadDropdown"
                          [data]="faculties"
                          [(ngModel)]="facultiesEdit"
                          (valueChange)="facultiesValueChange($event)"
                          valueField="id"
                          textField="shortName">
      </kendo-dropdownlist>
  </span>
  <span class="workloadDropdownContainer">
      <h3>Форма обучения</h3>
      <kendo-dropdownlist (valueChange)="studyFormValueChange($event)"
                          [data]="studyForms"
                          [(ngModel)]="studyFormEdit"
                          [virtual]="filterVirtualization"
                          class="workloadDropdown" textField="studyFormName" valueField="id">
    </kendo-dropdownlist>
  </span>
  <span class="no-assignments" *ngIf="!mainGridOptions.hasAssignments && !mainGridOptions.loading">
    На выбранный год и семестр нет сформированных поручений
  </span>
  <span class="buttons k-mt-10">
    <button kendoButton icon="download" (click)="exportStudyWork()">Учебная работа</button>
    <button kendoButton icon="download" (click)="exportPlanFact()">План-факт</button>
    <button kendoButton icon="download" (click)="exportSummaryPps()">Сводный отчет по ППС</button>
  </span>

  <kendo-grid #mainLoad class="grid main-load"
              [kendoGridBinding]="mainGridData"
              [rowClass]="rowCallback"
              [loading]="mainGridOptions.loading"
              [height]="600"
              (cellClick)="editDepartmentLoad($event)">
    <kendo-grid-column headerClass="gridHeader" field="groups" [width]="90" title="Семестр">
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" *ngIf="dataItem.isFlow || dataItem.isGroup">
            {{dataItem.semesterNumber}}
          </span>
          <span *ngIf="dataItem.isDiscipline" class="alignCells leftAlignment bold disciplineGroup">
              {{ dataItem.isDiscipline!=undefined ? dataItem.discipline.name : "" }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="type" [width]="320"
                       title="Академические группы (кол-во студентов факт, кол-во подгрупп, чел)">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment groupFlowGroup" *ngIf="dataItem.isFlow || dataItem.isGroup">
          <span class="bold">{{ displayGroupFlowName(dataItem) }}</span> {{ displayGroupFlowInfo(dataItem) }}
        </span>
        <span class="alignCells leftAlignment groupFlowGroup" *ngIf="dataItem.classesBySubGroups">
          <span class="bold">{{ (dataItem.subGroupNumber ? "Подгруппа " +  dataItem.subGroupNumber : "") }}</span>
        </span>
        <span class="alignCells leftAlignment groupPadding" *ngIf="dataItem.isGroupWorkType || dataItem.isFlowWorkType">
          {{ dataItem.typeWorks.typeWork.name }}
        </span>
        <span class="alignCells leftAlignment subGroupGroup" *ngIf="dataItem.isSubGroup">
          {{ "Подгруппа " + dataItem.subGroup.number }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="studentCalc" [width]="150" title="Студ. / гр. в расчет">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" *ngIf="(dataItem.isGroupWorkType || dataItem.isFlowWorkType)">
          {{dataItem.typeWorks !== undefined ? dataItem.typeWorks.coefficient : ""}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <div *ngIf='(dataItem.isGroupWorkType || dataItem.isFlowWorkType)'>
          <kendo-numerictextbox format="false"
                                *ngIf='mainGridOptions.row.allowEditCoefficient'
                                [formControl]="formGroup.get('coefficient')"
                                [min]="0" [changeValueOnScroll]="false"
          ></kendo-numerictextbox>
          <span class="alignCells" *ngIf='!mainGridOptions.row.allowEditCoefficient'>
            {{dataItem.typeWorks !== undefined ? dataItem.typeWorks.coefficient : ""}}
          </span>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="workloadStandard" [width]="150" title="Норма нагрузки">
      <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells" *ngIf="(dataItem.isGroupWorkType || dataItem.isFlowWorkType)">
                 {{ (dataItem.typeWorks.typeWork.hoursInsteadOfTypeWorkCoefficient) ? dataItem.typeWorks.typeWorkHours
                  : dataItem.typeWorks.typeWork.coefficient}}
              </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="worloadHours" [width]="150" title="Нагрузка, час.">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells bold">
          {{(dataItem.isGroupWorkType || dataItem.isFlowWorkType) ? dataItem.typeWorks.hours : dataItem.isSubGroup ? dataItem.subGroup.hours : ""}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup *ngIf="mainGridOptions.row.isSubGroup">
        <span class="alignCells">
          <kendo-numerictextbox format="false"
                                [formControl]="formGroup.get('hours')"
                                [min]="0"
                                [format]='numberFormatOptions'
                                [decimals]='2'
                                [changeValueOnScroll]="false"
          ></kendo-numerictextbox>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="courseNum" [width]="150" title="Параметры">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{displayParameters(dataItem)}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem"
                   *ngIf="mainGridOptions.row.isSubGroupDivided">
        <kendo-combobox textField="text" valueField="value"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dataItem.subGroupList"
                        [valuePrimitive]="true"
                        [value]="dataItem.typeWorks?.subGroups?.length"
                        [clearButton]="false"
                        [virtual]="filterVirtualization"
                        (valueChange)="onSubGroupCountChange($event)"
        ></kendo-combobox>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" *ngIf="mainGridOptions.row.isExamWork">
        <ul class="k-radio-list">
          <li class="k-radio-item" style='padding: 0;'
              *ngFor='let examType of dictExamTypeParameters; let i = index'>
            <label class='k-radio-label' style='align-items: center;'>
              <input kendoRadioButton type="radio"
                     [value]='examType.id'
                     [formControl]="formGroup.get('examType')"
              />
              {{ examType.name }}
            </label>
          </li>
        </ul>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="lecturer" [width]="300" title="Закрепленный преподаватель">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" *ngIf="!(dataItem.isGroupWorkType && dataItem.typeWorks.subGroups?.length > 1)">
          {{((dataItem.isGroupWorkType || dataItem.isFlowWorkType) ? dataItem.typeWorks.person?.fio : dataItem.isSubGroup ? dataItem.subGroup.person?.fio : "")}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"
                   *ngIf="!mainGridOptions.row.isFlow">
        <kendo-combobox
          [kendoDropDownFilter]="filterSettings"
          [data]="persons"
          textField="fio"
          valueField="id"
          (valueChange)="personValueChange($event)"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('personId')"
          [virtual]="filterVirtualization">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="completedHours" [width]="200" title="Выполнено, час.">
      <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells" [style.color]="colorCode((dataItem.isGroupWorkType || dataItem.isFlowWorkType) ? {completedHours:dataItem.typeWorks.completedHours, loadHours: dataItem.typeWorks.hours}
              : dataItem.isSubGroup ? {completedHours:dataItem.subGroup.completedHours, loadHours: dataItem.subGroup.hours} : {})">
                {{(dataItem.isGroupWorkType || dataItem.isFlowWorkType) ? dataItem.typeWorks.completedHours : dataItem.isSubGroup ? dataItem.subGroup.completedHours : ""}}
              </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <h1>Дополнительные работы</h1>
  <kendo-grid #extraGrid class="extraGrid"
              [height]="600"
              [ngClass]="{noData: extraWork.length < 4}"
              [kendoGridBinding]="extraWork"
              (add)="addAdditionalWork($event)"
              (cellClick)="editAdditionalWork($event)">
    <ng-template kendoGridToolbarTemplate>
      <button *ngIf="!extraGridState.isEdit" kendoGridAddCommand [primary]="true" icon="plus" style="margin-bottom: 20px">Доп. работа</button>
      <span class="handlerButtons" *ngIf="extraGridState.isEdit">
              <button themeColor="primary" [disabled]="!formGroupAdditional?.valid" (click)="saveAdditionalWork()" kendoButton>Сохранить</button>
              <button class="cancelButton" kendoButton (click)="cancelExtraHandler($event)">Отмена</button>
          </span>
    </ng-template>
    <kendo-grid-column headerClass="gridHeader" field="dictAdditionalWork" [width]="400" title="Дополнительная работа">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.dictAdditionalWork.name}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroupAdditional="formGroup"
                   *ngIf="mainGridOptions.row.iskafedraWork">
        <kendo-combobox [kendoDropDownFilter]="filterSettings"
                        [data]="dictAdditionalWorks"
                        textField="name" valueField="id"
                        [formControl]="formGroupAdditional.get('dictAdditionalWorksId')"
                        [valuePrimitive]="true"
                        [popupSettings]="{
                          width: 500
                        }"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="eduGroup.name" [width]="250" title="Группа">
      <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem?.eduGroup ? dataItem.eduGroup.name + " (" + dataItem.eduGroup.studentCount + ") чел." : ""}}            </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroupAdditional="formGroup"
                   *ngIf="mainGridOptions.row.iskafedraWork">
        <kendo-combobox textField="name" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="eduGroups"
                        [valuePrimitive]="true"
                        [formControl]="formGroupAdditional.get('eduGroupId')"
                        [virtual]="filterVirtualization"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="totalStudentsLoad" [width]="150" title="Дн./студ./гр. в расчет">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.totalStudentsLoad}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroupAdditional="formGroup">
        <span class="alignCells">
          <kendo-numerictextbox format="false" *ngIf="mainGridOptions.row.iskafedraWork"
                                [min]='0' [changeValueOnScroll]='false'
                                [formControl]="formGroupAdditional.get('totalStudentsLoad')"
          ></kendo-numerictextbox>
          <span *ngIf="!mainGridOptions.row.iskafedraWork">{{ dataItem.totalStudentsLoad }}</span>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader bold" field="loadHours" [width]="100" title="Нагрузка, час.">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{dataItem.loadHours}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="person" [width]="300" title="Закрепленный преподаватель">
      <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.person != undefined ? dataItem.person.fio : ""}}
              </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroupAdditional="formGroup">
        <kendo-combobox textField="fio" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="persons"
                        [valuePrimitive]="true"
                        [formControl]="formGroupAdditional.get('personId')"
                        [virtual]="filterVirtualization">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="completedHours" [width]="100" title="Выполнено, час.">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span [style.color]="colorCode(dataItem)">
              {{ dataItem.completedHours }}
            </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" [width]="80" title="">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" *ngIf="!extraGridState.isAdd"
              [hidden]="dataItem.dictAdditionalWork.dictAdditionalWorkType.name === typeWork.faculty">
          <button class="red" kendoButton themeColor="secondary" icon="delete"
                  fillMode="flat" size="medium" rounded="full" style="text-overflow: unset;"
                  (click)="openExtraConfirmWindow(dataItem)">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <span class="handlerButtons top" *ngIf="extraGridState.isEdit">
          <button themeColor="primary" [disabled]="!formGroupAdditional?.valid" (click)="saveAdditionalWork()" kendoButton>Сохранить</button>
          <button class="cancelButton" kendoButton (click)="cancelExtraHandler($event)">Отмена</button>
      </span>

  <kendo-dialog [width]="400" *ngIf="extraGridState.opened" (close)="closeExtraConfirmWindow()">
    <p style="margin: 30px; text-align: center;">
      Вы уверены что хотите удалить: {{extraGridState.itemToDelete}}?
    </p>
    <kendo-dialog-actions>
      <button kendoButton (click)="closeExtraConfirmWindow()">Нет</button>
      <button kendoButton
              themeColor="primary"
              (click)="removeAdditionalWork()"
              [primary]="true">
        Да
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>

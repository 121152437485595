import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {dictPagerSettings} from "../../../../models/dicts/pagerSettings.model";
import {PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AdmissionOrderService} from "../../../../services/gia/admission-order.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {
  AdmissionOrder, AdmissionOrderStudent, StudentBasis
} from "../../../../models/gia/orders/admission-order.model";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../helpers/dialogHelper";
import {EducationService} from "../../../../services/gia/education.service";
import {TrainingLevelEnum} from "../../../../models/gia/enums/training-level.enum";
import {DateFromUTCAsLocal, getDayEnd, TimeZoneFix} from "../../../../helpers/date-helper";
import {GroupDescriptor, SortDescriptor} from "@progress/kendo-data-query";
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import {GiaTabsEnum} from "../../../../models/gia/enums/giaTabs.enum";
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";

@Component({
  selector: 'app-admission-order-form',
  templateUrl: './admission-order-form.component.html',
  styleUrls: ['./admission-order-form.component.scss']
})
export class AdmissionOrderFormComponent implements OnInit {

  public orderId?: string;
  public isNew: boolean = true;
  public pageSize = 20;
  public loading: boolean = false;
  public pagerSettings: PagerSettings = dictPagerSettings;
  public students: AdmissionOrderStudent[] = [];
  public basisArray: StudentBasis[] = [];
  public admissionOrder?: AdmissionOrder;
  public trainingLevelEnum?: number;
  public group: GroupDescriptor[] = [];
  public sort: SortDescriptor[] = [{ field: "studentFio", dir: "asc", }];

  public editable: boolean = false;

  constructor(private activateRoute: ActivatedRoute,
              private router: Router,
              private admissionOrderService: AdmissionOrderService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: GiaUserAccessService,
              private educationService: EducationService,) {
    this.orderId = activateRoute.snapshot.params["orderId"];
    this.isNew = !this.orderId;
    const storage = localStorage.getItem('diplomas_settings');
    if (storage !== null && this.isNew ) {
      const data = JSON.parse(storage);
      this.admissionOrderForm.reset({
        graduateYear: data.yearAdmission,
        educationStandardId: data.standard,
        facultyId: data.faculty
      })
    }

    this.students = history.state.students;
    this.students?.forEach(s => s.basis = null)
  }

  ngOnInit(): void {
    if (this.isNew) this.getStandardInfo();
    this.getAccessLevel();
    this.getOrder();
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        this.editable = response.giaSections?.some((_) => _ === GiaTabsEnum.приказы_о_допуске || _ === null);
      },
      error: () => {},
    });
  }

  public getStandardInfo() {
    this.educationService.getStandardInfo(this.admissionOrderForm.value.educationStandardId)
      .subscribe(response => {
        if (response !== null) this.setTrainingLevel(response.trainingLevelEnum);
      })
  }

  public getOrder() {
    if (this.orderId)
      this.admissionOrderService.getOrder(this.orderId)
        .subscribe(response => {
          this.admissionOrder = response;
          this.basisArray = [...response.students.map(s => (
            {
              studentId: s.studentId,
              basis: s.basis
            }))
          ];
          this.admissionOrderForm.reset({
            admissionOrderId: this.orderId,
            orderDate: DateFromUTCAsLocal(this.admissionOrder.orderDate),
            orderNumber: this.admissionOrder.orderNumber,
            students: response.students,
          })
          this.setTrainingLevel(response.trainingLevelEnum);
          this.students = response.students;
        })
  }

  private setTrainingLevel(trainingLevel: number | undefined){
    this.trainingLevelEnum = trainingLevel;
    if (this.trainingLevelEnum === TrainingLevelEnum.Residency)
      this.group = [{ field: "specialization"}];
  }

  public save(print?: boolean){
    if (this.admissionOrderForm.value.orderDate) this.admissionOrderForm.value.orderDate = new Date(TimeZoneFix(this.admissionOrderForm.value.orderDate).toISOString());
    this.admissionOrderForm.value.students = this.students;
    if (this.isNew){
      this.admissionOrderService.addAdmissionOrder(this.admissionOrderForm.value)
        .subscribe({
        next:(response) => {
          this.notificationService.showSuccess("Сохранено");
          if (print)
            this.printOrder(response);
          this.navigate();
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }}
      );
    }
    else {
      this.admissionOrderService.updateAdmissionOrder(this.admissionOrderForm.value)
        .subscribe({
          next:(response) => {
            this.notificationService.showSuccess("Сохранено");
            if (print)
              this.printOrder(response);
            this.navigate();
          },
          error:(error) => {
            this.notificationService.showError(error.error);
          }}
        );
    }
  }

  private printOrder(orderId: string){
    this.admissionOrderService.printOrder(orderId).subscribe({
      next: (value) => {
        DownloadFile(value);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }

  public addStudent(){
    this.router.navigateByUrl(`/gia/admissionorderform/${this.orderId}/add`).then();
  }

  public back(){
    if (this.isNew && (this.admissionOrderForm.value.orderNumber || this.admissionOrderForm.value.orderDate
                        || this.students && this.students.length !== 0) ||
        !this.isNew && (this.admissionOrderForm.value.orderNumber !== this.admissionOrder?.orderNumber
                        || this.students.length !==  this.admissionOrderForm.value?.students.length
                        || +getDayEnd(DateFromUTCAsLocal(this.admissionOrder!.orderDate)) !== +getDayEnd(this.admissionOrderForm.value.orderDate)
                        || this.students.some(s => this.basisArray.find(a => a.studentId === s.studentId)?.basis !== s.basis)))
    {
      const dialog: DialogRef = openDialog(this.dialogService, "Имеются несохраненные изменения, вы действительно хотите покинуть страницу?", 450, 200, 250);
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.navigate();
        }
      });
      return;
    }

    this.navigate();
  }

  public navigate(){
    const storage = localStorage.getItem('diplomas_settings');
    if (storage !== null) {
      const data = JSON.parse(storage);
      this.router.navigateByUrl(`/gia/admissionorder/${data.standard}/${data.faculty}/${data.yearAdmission}/${data.studyForm}`).then();
      return;
    }
    this.router.navigateByUrl(`/gia/admissionorder`).then();
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, "Вы действительно хотите удалить обучающегося из приказа о допуске?", 450, 200, 250);

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.students = this.students.filter(s => s.studentId !== dataItem.studentId);
        this.students?.forEach((s, index) => s.assignedNumber = index + 1);
      }
    });
  }

  public admissionOrderForm: FormGroup = new FormGroup({
    admissionOrderId: new FormControl(null),
    graduateYear: new FormControl(0),
    educationStandardId: new FormControl(''),
    facultyId: new FormControl(''),
    orderNumber: new FormControl('', Validators.required),
    orderDate: new FormControl<Date | null>(null, Validators.required),
    students: new FormControl<[] | null>(null),
  });
  protected readonly TrainingLevelEnum = TrainingLevelEnum;
}

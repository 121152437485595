<h5 class="personal-title">Персональные данные/документы</h5>
<!---------------------------------------------------------->
<!----------------------Identification---------------------->
<!---------------------------------------------------------->
<div *ngIf="!isHidden(enum.identification)">
  <h4 class="title-gray-color">Удостоверения личности</h4>

  <div class="grid-container">
    <!--Grid-->
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridBinding]="identifications"
                  *ngIf="!editModeIdentification; else editIdentifications"
                  (add)="addHandlerIdentification($event)"
                  (remove)="removeHandlerIdentification($event)"
                  (edit)="transitionHandler($event)"
                  [sortable]="true"
                  scrollable="none"
                  [loading]="loadingIdentification">

        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>

        <ng-template kendoGridToolbarTemplate *ngIf="editable" [position]="position">
          <button kendoGridAddCommand
                  [disabled]="studentMainInfoComponent.deleted"
                  size="small"
                  themeColor="success"
                  icon="plus"
          >Добавить документ</button>
        </ng-template>

        <kendo-grid-column field="dictIdentificationTypeId"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="350">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{ nameIdentificationTypes(dataItem.dictIdentificationTypeId) }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="serial"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="700">

          <ng-template kendoGridCellTemplate let-dataItem>
            Серия: {{(dataItem?.serial == null ? "" :  dataItem.serial)}}
            Номер: {{(dataItem?.number == null ? "" :  dataItem.number)}}
            Выдан: {{(dataItem?.issueOrganization == null ? "" :  dataItem.issueOrganization)}}
            Дата выдачи: {{(dataItem?.issueDate == null ? "" :  dataItem.issueDate| date: "dd.MM.yyyy")}}
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column  [headerStyle]="{ 'display': 'none' }"
                            *ngIf="!studentMainInfoComponent.deleted"
                            [width]="150">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand type="button"
                    icon="edit"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    *ngIf="editable">
            </button>

            <button kendoGridRemoveCommand
                    type="button"
                    icon="delete"
                    fillMode="flat"
                    size="small"
                    rounded="full"
                    *ngIf="editable">
            </button>

            <button
              kendoGridSaveCommand
              type="button"
              [disabled]="myForm.invalid!"
              size="small"
              icon="k-i-save k-i-floppy"
              themeColor="primary">
              {{ isNew ? "Добавить" : "Обновить" }}
            </button>
            <button kendoGridCancelCommand icon="k-i-close k-i-x" type="button" size="small">
              {{ "Отмена" }}
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>

  <!------------Edit Identification------------>

  <ng-template #editIdentifications >
    <form class="k-form" [formGroup]="formIdentification" class="ngForm">

      <dl class="about">
        <!--Тип документа-->
        <div class="label-sticky-top">
          <kendo-label [for]="dictIdentificationTypeId" text="Тип документа"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist [data]="dictIdentificationTypes"
                                textField="identificationTypeName"
                                valueField="dictIdentificationTypeExternalId"
                                #dictIdentificationTypeId
                                [valuePrimitive]="true"
                                class="input-main-identity"
                                formControlName="dictIdentificationTypeId"
                                [formControl]="$any(formIdentification).controls['dictIdentificationTypeId']"
                                required>
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Серия-->
        <div class="label-sticky-top">
          <kendo-label [for]="serial" text="Серия"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="serial"
              class="input-main-identity"
              size="small"

              placeholder="Серия"
              #serial
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Номер-->
        <div class="label-sticky-top">
          <kendo-label [for]="number" text="Номер"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="number"
              class="input-main-identity"
              [maxlength]=30
              size="small"
              [clearButton]="true"
              placeholder="Номер"
              #number
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Дата выдачи-->
        <div class="label-sticky-top">
          <kendo-label [for]="issueDate" text="Дата выдачи"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #issueDate
              class="input-main-identity"
              [openOnFocus]="issueDate"
              (focus)="onFocus()"
              formControlName="issueDate"
              required>

            </kendo-datepicker>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Срок действия-->
        <kendo-label [for]="issueDateEnd" text="Срок действия"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #issueDateEnd
              class="input-main-identity"
              [openOnFocus]="issueDateEnd"
              (focus)="onFocus()"
              formControlName="issueDateEnd">

            </kendo-datepicker>
          </kendo-formfield>
        </dd>

        <!--Срок действия визы-->
        <kendo-label [for]="issueDateEnd" text="Срок действия визы"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #visaDate
              class="input-main-identity"
              [openOnFocus]="visaDate"
              (focus)="onFocus()"
              formControlName="visaDateEnd">

            </kendo-datepicker>
          </kendo-formfield>
        </dd>

        <!--
          <kendo-label [for]="dateBegin" text="Дата добавления записи"></kendo-label>
          <dd>
              <kendo-formfield>
                    <kendo-datepicker
                        format="dd.MM.yyyy"
                        #dateBegin
                        formControlName="dateBegin"
                        required>
                    </kendo-datepicker>
                  <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
              </kendo-formfield>
          </dd>

          <kendo-label [for]="dateEnd" text="Дата окончания записи (для лога, если пустая - запись активна)"></kendo-label>
          <dd>
              <kendo-formfield>
                    <kendo-datepicker
                        format="dd.MM.yyyy"
                        #dateEnd
                        formControlName="dateEnd"
                        required>
                    </kendo-datepicker>
                  <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
              </kendo-formfield>
          </dd>
          -->

        <!--Контрактер-->
        <kendo-label text="Контрактер" *ngIf="!isHidden(enum.pd_contractor)"></kendo-label>
        <dd *ngIf="!isHidden(enum.pd_contractor)">
          <kendo-formfield>
            <kendo-textbox
              formControlName="contractor"
              class="input-main-identity"
              size="small"
              [clearButton]="true"
              placeholder="Контрактер"
              #contractor
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <!--Кем выдано-->
        <div class="label-sticky-top">
          <kendo-label [for]="issueOrganization" text="Кем выдано"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="issueOrganization"
              class="input-main-identity"
              size="small"
              [maxlength]=200
              [clearButton]="true"
              placeholder="Кем выдано"
              #issueOrganization
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label [for]="issueCode" text="Код подразделения"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="issueCode"
              class="input-main-identity"
              [maxlength]=50
              size="small"
              [clearButton]="true"
              placeholder="Код подразделения"
              #issueCode
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <!--Место рождения-->
        <kendo-label [for]="birthPlace" text="Место рождения"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="birthPlace"
              class="input-main-identity"
              size="small"
              [maxlength]=300
              [clearButton]="true"
              placeholder="Место рождения"
              #birthPlace
            ></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

      </dl>
    </form>
    <div class="editModeButtons">
      <button kendoButton
              themeColor="primary"
              size="small"
              *ngIf="editModeIdentification"
              [disabled]="!formIdentification.valid"
              (click)="onSaveIdentification($event)">
        Сохранить
      </button>

      <button kendoButton
              size="small"
              *ngIf="editModeIdentification"
              (click)="closeIdentification()">
        Отмена
      </button>
    </div>
  </ng-template>
</div>

<!----------------------------------------------->
<!-----------------Education Doc----------------->
<!----------------------------------------------->
<div *ngIf="!isHidden(enum.education_doc)">
  <h4 class="title-gray-color">Документы об образовании</h4>

  <div class="grid-container">
    <!--Grid-->
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridBinding]="educationDocs"
                  *ngIf="!editModeEducationDoc; else editEducationdoc"
                  (add)="addHandlerEducationDoc($event)"
                  (remove)="removeHandlerEducationDoc($event)"
                  (edit)="transitionHandlerEducationDoc($event)"
                  [sortable]="true"
                  scrollable="none"
                  [loading]="loadingEducation">

        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>

        <ng-template kendoGridToolbarTemplate *ngIf="editable" [position]="position">
          <button [disabled]="studentMainInfoComponent.deleted"
                  kendoGridAddCommand
                  size="small"
                  themeColor="success"
                  icon="plus"
          >Добавить документ</button>
        </ng-template>

        <kendo-grid-column field="dictEducationDocTypeId"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="350">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{ nameEducationDocTypes(dataItem.dictEducationDocTypeId) }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="serial"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="700">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{(dataItem?.serial != null ? "Серия: " + dataItem.serial: "")}}
            Номер: {{(dataItem?.number == null ? "" :  dataItem.number)}}
            Выдан: {{(dataItem?.issueOrganization == null ? "" :  dataItem.issueOrganization)}}
            Дата выдачи: {{(dataItem?.issueDate == null ? "" :  dataItem.issueDate| date: "dd.MM.yyyy")}}
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column  [headerStyle]="{ 'display': 'none' }"
                            [width]="150"
                            *ngIf="!studentMainInfoComponent.deleted">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand type="button"
                    icon="edit"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    *ngIf="editable">
            </button>

            <button kendoGridRemoveCommand
                    type="button"
                    icon="delete"
                    fillMode="flat"
                    size="small"
                    rounded="full"
                    *ngIf="editable">
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>

  <!------------Edit Educationdoc------------>

  <ng-template #editEducationdoc >
    <form class="k-form" [formGroup]="formEducationDoc" class="ngForm">

      <dl class="dl-education">
        <!--Тип документа-->
        <div class="label-sticky-top">
          <kendo-label [for]="dictEducationDocTypeId" text="Тип документа"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist [data]="educationDocTypes"
                                textField="educationDocTypeName"
                                valueField="dictEducationDocTypeExternalId"
                                class="input-main-identity"
                                #dictEducationDocTypeId
                                [valuePrimitive]="true"
                                formControlName="dictEducationDocTypeId"
                                [formControl]="$any(formEducationDoc).controls['dictEducationDocTypeId']"
                                required>
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Серия-->
        <div class="label-sticky-top">
          <kendo-label [for]="serial" text="Серия"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="serial"
              class="input-main-identity"
              size="small"
              placeholder="Серия"
              #serial
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Номер-->
        <div class="label-sticky-top">
          <kendo-label [for]="number" text="Номер"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="number"
              class="input-main-identity"
              size="small"
              [clearButton]="true"
              placeholder="Номер"
              #number
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Дата выдачи-->
        <div class="label-sticky-top">
          <kendo-label [for]="issueDate" text="Дата выдачи"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #issueDate
              class="input-main-identity"
              [openOnFocus]="issueDate"
              (focus)="onFocus()"
              formControlName="issueDate"
              required>

            </kendo-datepicker>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
        <!--
         <div class="label-sticky-top">
           <kendo-label [for]="comment" text="Предыдущее образование"></kendo-label>
         </div>
         <dd>
           <kendo-formfield>
             <kendo-textbox
               formControlName="comment"
               class="input-main-identity"
               size="small"
               [clearButton]="true"
               placeholder="Предыдущее образование"
               #comment
             ></kendo-textbox>
           </kendo-formfield>
         </dd>-->

        <div class="label-sticky-top">
          <kendo-label text="Тип учебного заведения"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-combobox [data]="dictInstitutionTypes"
                            textField="institutionTypeName"
                            valueField="dictInstitutionTypeExternalId"
                            class="input-main-identity"
                            #institution
                            [openOnFocus]="institution"
                            [kendoDropDownFilter]="filterSettings"
                            [valuePrimitive]="true"
                            formControlName="dictInstitutionTypeId"
                            [formControl]="$any(formEducationDoc).controls['dictInstitutionTypeId']"
                            required>
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Кем выдано-->
        <kendo-label text="Название учебного заведения"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="issueOrganization"
              class="input-main-identity"
              size="small"
              [clearButton]="true"
              placeholder="Название учебного заведения"
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Страна выдачи-->
        <kendo-label [for]="issueCountry" text="Страна выдачи"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-combobox [data]="citizenship" #combo
                            textField="country"
                            valueField="country"
                            formControlName="issueCountry"
                            class="input-main-identity"
                            #issueCountry
                            [openOnFocus]="issueCountry"
                            [kendoDropDownFilter]="filterSettings"
                            placeholder="Страна"
                            [valuePrimitive]="true"
                            required
                            >
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Предыдущее-->
        <kendo-label text='Предыдущее образование получено в этом учебном заведении' class="previous-study" *ngIf="!isHidden(enum.pd_this_graduate)"></kendo-label>
        <div *ngIf="!isHidden(enum.pd_this_graduate)">
          <kendo-formfield>
            <input type="checkbox" #previousStudy kendoCheckBox formControlName="graduateThis" [(ngModel)]="graduateThis" (change)="showFaculties()" *ngIf="!isHidden(enum.pd_this_graduate)"/>
          </kendo-formfield>
        </div>


        <!--Факультет-->

        <kendo-label text="Факультет" *ngIf="graduateThis && !isHidden(enum.pd_faculty)"></kendo-label>
        <dd *ngIf="graduateThis && !isHidden(enum.pd_faculty)">
            <kendo-formfield>
              <kendo-dropdownlist
                  [data]="faculties"
                  class="input-main-identity"
                  textField="shortName"
                  valueField="facultyExternalId"
                  [valuePrimitive]="true"
                  formControlName="facultyId"
                  [(ngModel)]="facultyId"
              >
              </kendo-dropdownlist>
            </kendo-formfield>
        </dd>

        <!--Специальность-->
        <kendo-label [for]="graduateSpecial" text="Специальность"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="graduateSpecial"
              class="input-main-identity"
              size="small"
              [clearButton]="true"
              placeholder="Специальность"
              #graduateSpecial
            ></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Форма обучения-->
        <kendo-label text="Форма обучения" *ngIf="!isHidden(enum.pd_study_form)"></kendo-label>
        <dd *ngIf="!isHidden(enum.pd_study_form)">
          <kendo-formfield>
            <kendo-dropdownlist
                #formEducation
                class="input-main-identity"
                [data]="dictStudyForms"
                textField="studyFormName"
                valueField="dictStudyFormExternalId"
                [valuePrimitive]="true"
                formControlName="dictStudyFormId"
                required
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

         <!--Квалификация-->
          <kendo-label text="Квалификация" *ngIf="!isHidden(enum.pd_qualification)"></kendo-label>
          <dd *ngIf="!isHidden(enum.pd_qualification)">
            <kendo-formfield>
              <kendo-dropdownlist
                  #qualification
                  class="input-main-identity"
                  [data]="qualifications"
                  textField="qualificationName"
                  valueField="externalId"
                  [valuePrimitive]="true"
                  formControlName="dictQualificationId"
              >
              </kendo-dropdownlist>
            </kendo-formfield>
          </dd>

        <!--Год окончания-->
        <kendo-label [for]="graduateYear" text="Год окончания"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-maskedtextbox
              formControlName="graduateYear"
              class="input-main-identity"
              size="small"
              placeholder="Год окончания"
              [mask]="serialIdentificationMask"
              #graduateYear
            ></kendo-maskedtextbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

         <!--Год защиты диссертации-->
        <kendo-label text="Год защиты диссертации" *ngIf="visibleDissertationYear && !isHidden(enum.pd_dissertation_year)"></kendo-label>
        <dd *ngIf="visibleDissertationYear && !isHidden(enum.pd_dissertation_year)">
            <kendo-formfield>
              <kendo-maskedtextbox
                formControlName="dissertationYear"
                class="input-main-identity"
                size="small"
                placeholder="Год защиты диссертации"
                [mask]="serialIdentificationMask"
                #dissertationsYear
              ></kendo-maskedtextbox>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
        </dd>

        <div class="previous-study" *ngIf="visibleDissertationYear && !isHidden(enum.pd_kandidate_disser_date)">
          <kendo-label text="Дата защиты кандидатской диссертации" ></kendo-label>
        </div>
        <dd *ngIf="visibleDissertationYear && !isHidden(enum.pd_kandidate_disser_date)">
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #kandidateDisserDate
              class="input-main-identity"
              [openOnFocus]="kandidateDisserDate"
              (focus)="onFocus()"
              formControlName="kandidateDisserDate">
            </kendo-datepicker>
          </kendo-formfield>
        </dd>

        <div class="label-sticky-top" *ngIf="visibleDissertationYear && !isHidden(enum.pd_approve_vak_date)">
          <kendo-label text="Дата утверждения ВАК"></kendo-label>
        </div>
        <dd *ngIf="visibleDissertationYear && !isHidden(enum.pd_approve_vak_date)">
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #approveVAKDate
              class="input-main-identity"
              [openOnFocus]="approveVAKDate"
              (focus)="onFocus()"
              formControlName="approveVAKDate">
            </kendo-datepicker>
          </kendo-formfield>
        </dd>

       <kendo-label  text="Уровень образования" *ngIf="!isHidden(enum.pd_study_level)"></kendo-label>
        <dd *ngIf="!isHidden(enum.pd_study_level)">
          <kendo-formfield>
            <kendo-combobox [data]="studyLevels"
                            textField="studyLevelName"
                            valueField="dictStudyLevelExternalId"
                            class="input-main-identity"
                            #studyLevel
                            [openOnFocus]="studyLevel"
                            [kendoDropDownFilter]="filterSettings"
                            [valuePrimitive]="true"
                            formControlName="dictStudyLevelId"
                            [formControl]="$any(formEducationDoc).controls['dictStudyLevelId']"
                            required>
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--ВУЗ окончания-->
        <kendo-label text="ВУЗ окончания" *ngIf="!isHidden(enum.pd_graduate_university)"></kendo-label>
        <dd *ngIf="!isHidden(enum.pd_graduate_university)">
          <kendo-formfield>
            <kendo-textbox
              formControlName="graduatorName"
              class="input-main-identity"
              size="small"
              [clearButton]="true"
              placeholder="ВУЗ окончания"
              #graduatorName
            ></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
      </dl>
    </form>

    <dl class="about" class="dl-education-language">
      <kendo-label class="language-label" text="Изучаемые ранее языки"></kendo-label>
      <dd>
        <kendo-label text="Изучаемый язык"></kendo-label>
        <kendo-label text="Оценка" style="margin-left: 80px" *ngIf="!isHidden(enum.pd_mark)"></kendo-label>
        <kendo-label text="Степень знания" style="margin-left: 80px" *ngIf="!isHidden(enum.pd_knowledge)"></kendo-label>
      </dd>
      <dd>
      </dd>
      <ng-container class="ddinline" *ngFor="let item of educationDoc.educationDocLanguage!;let i=index">
        <dd>
          <kendo-combobox [data]="dictLanguages" #combo
                          textField="languageName"
                          valueField="languageName"
                          class="education-textbox"
                          #educationLanguage
                          [openOnFocus]="educationLanguage"
                          [kendoDropDownFilter]="filterSettings"
                          [valuePrimitive]="true"
                          [(ngModel)]="selectEducationDocLanguages[i]">
          </kendo-combobox>

         <kendo-numerictextbox [style.width.px]="120" *ngIf="!isHidden(enum.pd_mark)"
                                [(ngModel)]="selectEducationDocBall[i]"
                                [min]="1"
                                format="n0"
                                [max]="5"
          >
          </kendo-numerictextbox>

          <kendo-dropdownlist class="level-languages" *ngIf="!isHidden(enum.pd_knowledge)"
                [data]="languageLevels"
                textField="levelName"
                valueField="dictLanguageLevelExternalId"
                [valuePrimitive]="true"
                [(ngModel)]="selectLevelLanguages[i]"
            >
          </kendo-dropdownlist>

          <button kendoButton
                  icon="delete"
                  class="languages-button"
                  fillMode="flat"
                  size="small"
                  rounded="full"
                  (click)="removeLanguages(i)">
          </button>
        </dd>
        <dd></dd>
      </ng-container>

      <dd>
        <button kendoButton
                size="small"
                (click)="addLang()">
          Добавить
        </button>
      </dd>

    </dl>

    <div class="editModeButtons">
      <button kendoButton
              themeColor="primary"
              size="small"
              *ngIf="editModeEducationDoc"
              [disabled]="!formEducationDoc.valid"
              (click)="onSaveEducationDoc($event)">
        Сохранить
      </button>

      <button kendoButton
              size="small"
              *ngIf="editModeEducationDoc"
              (click)="closeEducationDoc()">
        Отмена
      </button>
    </div>
  </ng-template>
</div>

<!--------------------------------------->
<!-------------Family Member------------->
<!--------------------------------------->
<div *ngIf="!isHidden(enum.family_member)">
  <h4 class="title-gray-color">Информация о родственниках</h4>

  <div class="grid-container">
    <!--Grid-->
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridBinding]="familyMembers"
                  *ngIf="!editModeFamily; else editFamily"
                  (add)="addHandlerFamily($event)"
                  (remove)="removeHandlerFamily($event)"
                  (edit)="transitionHandlerFamily($event)"
                  [sortable]="true"
                  scrollable="none"
                  [loading]="loadingFamily">

        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>

        <ng-template kendoGridToolbarTemplate *ngIf="editable" [position]="position">
          <button kendoGridAddCommand
                  [disabled]="studentMainInfoComponent.deleted"
                  size="small"
                  themeColor="success"
                  icon="plus">Добавить родственника</button>
        </ng-template>

        <kendo-grid-column field="dictFamilyMemberStatusId"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ nameFamilyMemberStatus(dataItem.dictFamilyMemberStatusId) }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="fio"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="175">
        </kendo-grid-column>

        <kendo-grid-column field="birthday"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="110">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.birthday | date: "dd.MM.yyyy" }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="workPlace"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="125">
        </kendo-grid-column>

        <kendo-grid-column field="post"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="125">
        </kendo-grid-column>

        <kendo-grid-column field="address"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="125">
        </kendo-grid-column>

        <kendo-grid-column field="phoneFormated"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="200">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ phoneFormat(dataItem.phoneFormated) }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="email"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="100">
        </kendo-grid-column>

        <kendo-grid-column  [headerStyle]="{ 'display': 'none' }"
                            *ngIf="!studentMainInfoComponent.deleted"
                            [width]="150">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand type="button"
                    icon="edit"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    *ngIf="editable">
            </button>

            <button kendoGridRemoveCommand
                    type="button"
                    icon="delete"
                    fillMode="flat"
                    size="small"
                    rounded="full"
                    *ngIf="editable">
            </button>

          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>

  <ng-template #editFamily >
    <form class="k-form" [formGroup]="formFamilyMember" class="ngForm">
      <dl class="about">
        <!--Степень родства-->
        <div class="label-sticky-top">
          <kendo-label [for]="familymember" text="Степень родства"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-combobox [data]="familyMemberStatuses"
                            [kendoDropDownFilter]="filterSettings"
                            textField="familyMemberStatusName"
                            valueField="dictFamilyMemberStatusExternalId"
                            placeholder="Степень родства"
                            [valuePrimitive]="true"
                            class="input-main-family-members"
                            #familymember
                            [formControl]="$any(formFamilyMember).controls['dictFamilyMemberStatusId']"
                            formControlName="dictFamilyMemberStatusId"
                            required
            >
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--ФИО-->
        <div class="label-sticky-top">
          <kendo-label [for]="fiofamily" text="ФИО"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="fio"
              class="input-main-family-members"
              size="small"
              [clearButton]="true"
              placeholder="ФИО"
              #fiofamily
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Дата рождения-->
        <kendo-label text="Дата рождения" *ngIf="!isHidden(enum.pd_birthday)"></kendo-label>
        <dd *ngIf="!isHidden(enum.pd_birthday)">
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #birthday
              class="input-main-family-members"
              [openOnFocus]="birthday"
              (focus)="onFocus()"
              formControlName="birthday"
            >
            </kendo-datepicker>
          </kendo-formfield>
        </dd>

        <!--Место работы родственника-->
        <kendo-label [for]="fiofamily" text="Место работы"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="workPlace"
              class="input-main-family-members"
              [maxlength]=200
              size="small"
              [clearButton]="true"
              placeholder="Место работы"
              #fiofamily
            ></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Должность родственника-->
        <kendo-label text="Должность"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="post"
              class="input-main-family-members"
              [maxlength]=200
              size="small"
              [clearButton]="true"
              placeholder="Должность"
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <!--Адрес проживания-->
        <kendo-label text="Адрес проживания"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="address"
              class="input-main-family-members"
              [maxlength]=200
              size="small"
              [clearButton]="true"
              placeholder="Адрес проживания"
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <!--Номер телефона-->
        <kendo-label [for]="phoneNumber" text="Номер телефона"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-maskedtextbox
              formControlName="phoneNumber"
              class="input-main-family-members"
              size="small"
              [mask]="phoneNumberMask"
              placeholder="phoneNumber"
              #phoneNumber
            ></kendo-maskedtextbox>
            <kendo-formerror *ngIf="formFamilyMember?.controls?.['phoneNumber']?.errors">Ошибка: неверный формат</kendo-formerror          >

          </kendo-formfield>
        </dd>

        <!--Email Family-->
        <kendo-label [for]="email" text="E-mail"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="email"
              class="input-main-family-members"
              size="small"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              [clearButton]="true"
              placeholder="E-mail"
              #email
            ></kendo-textbox>
            <kendo-formerror *ngIf="formFamilyMember.controls?.['email']?.errors">Ошибка: Неверный формат Email</kendo-formerror>
            <!--<kendo-formerror *ngIf="formFamilyMember?.controls?.['email']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>-->
          </kendo-formfield>
        </dd>
      </dl>
    </form>
    <div class="editModeButtons">
      <button kendoButton
              size="small"
              themeColor="primary"
              *ngIf="editModeFamily"
              [disabled]="!formFamilyMember.valid"
              (click)="onSaveFamily($event)">
        {{ isNewEmail ? "Добавить" : "Сохранить" }}
      </button>

      <button kendoButton
              size="small"
              *ngIf="editModeFamily"
              (click)="closeFamily()">
        Отмена
      </button>
    </div>
  </ng-template>
</div>

<!--------------------------------------->
<!-----------------Contacts-------------->
<!--------------------------------------->
<div *ngIf="!isHidden(enum.contacts)">
  <h4 class="title-gray-color">Контакты</h4>

  <div class="grid-container">
  <!--Grid-->
  <form novalidate #myForm="ngForm">
    <kendo-grid [kendoGridBinding]="contactsSort()"
                *ngIf="!editModePhone; else editPhone"
                (add)="addHandlerContact($event)"
                (remove)="removeHandlerContact($event)"
                (edit)="transitionHandlerContact($event)"
                [sortable]="true"
                scrollable="none"
                [loading]="loadingContacts">

        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>

        <ng-template kendoGridToolbarTemplate *ngIf="editable" [position]="position">
          <button kendoGridAddCommand
                  [disabled]="studentMainInfoComponent.deleted"
                  size="small"
                  themeColor="success"
                  icon="plus">Добавить контакт</button>
        </ng-template>

      <kendo-grid-column field="contactType"
                         [headerStyle]="{ 'display': 'none' }"
                         [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ nameContactType(dataItem.contactType) }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="contactTypeName"
                         [headerStyle]="{ 'display': 'none' }"
                         [width]="250"
                         *ngIf="!isHidden(this.enum.pd_email_type) || !isHidden(this.enum.pd_phone_type)"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ nameContactSubType(dataItem.externalId, dataItem.contactType) }}
        </ng-template>
      </kendo-grid-column>

        <kendo-grid-column field="content"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="400">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.contactType == 1 ? phoneFormat(dataItem.content) : dataItem.content }}
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="comment"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="(!isHidden(this.enum.pd_phone_type) || !isHidden(this.enum.pd_email_type)) ? 150 : 400">
        </kendo-grid-column>

        <kendo-grid-column  [headerStyle]="{ 'display': 'none' }"
                            *ngIf="!studentMainInfoComponent.deleted"
                            [width]="150">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand type="button"
                    icon="edit"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    *ngIf="editable">
            </button>

            <button kendoGridRemoveCommand
                    type="button"
                    icon="delete"
                    fillMode="flat"
                    size="small"
                    rounded="full"
                    *ngIf="editable">
            </button>

          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>

  <!------Edit Phone------>
  <ng-template #editPhone >
    <form class="k-form" [formGroup]="formContacts" class="ngForm">
      <dl class="about">

        <!--тип телефона-->
        <div class="label-sticky-top">
          <kendo-label text="Тип контакта"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist [data]="filterContactsList"
                                textField="typeName"
                                valueField="type"
                                [valuePrimitive]="true"
                                formControlName="contactType"
                                (valueChange)="onContactTypeChange($event)"
                                [formControl]="$any(formContacts).controls['contactType']"
                                required
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Phone-->

        <kendo-label *ngIf="phoneMode" text="Мобильный телефон"></kendo-label>
        <dd *ngIf="phoneMode">
          <div *ngIf="!isHidden(enum.pd_phone_type)">
            <kendo-label text="Тип номера"></kendo-label>
          </div>
          <kendo-formfield *ngIf="!isHidden(enum.pd_phone_type)">
            <kendo-dropdownlist
              [data]="phoneTypes"
              textField="phoneTypeName"
              valueField="dictPhoneTypeExternalId"
              [valuePrimitive]="true"
              formControlName="phoneTypeId"
              [(ngModel)]="phoneTypeId"
              required
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>

          <div>
            <kendo-label text="Номер телефона"></kendo-label>
          </div>
          <kendo-formfield>
            <kendo-maskedtextbox
              formControlName="content"
              [style.width.px]="250"
              size="small"
              [mask]="phoneNumberMask"
              placeholder="Мобильный телефон"
              required
            ></kendo-maskedtextbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            <kendo-formerror *ngIf="formPhone?.controls?.['phoneNumber']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label *ngIf="phoneCityMode" text="Городской телефон"></kendo-label>
        <dd *ngIf="phoneCityMode">
          <kendo-formfield>
            <kendo-textbox formControlName="content"
                           [style.width.px]="250"
                           [clearButton]="true"
                           placeholder="Городской телефон"
                           pattern='[0-9 +()-]*'></kendo-textbox>
            <kendo-formerror *ngIf="formPhone?.controls?.['phoneNumber']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
            <kendo-formerror *ngIf="formPhone.controls?.['phoneNumber']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label *ngIf="emailMode" text="E-mail"></kendo-label>
        <dd *ngIf="emailMode">
          <div *ngIf="!isHidden(enum.pd_email_type)">
            <kendo-label text="Тип email"></kendo-label>
          </div>
          <kendo-formfield *ngIf="!isHidden(enum.pd_email_type)">
            <kendo-dropdownlist
              class="m-b15"
              [data]="emailTypes"
              textField="emailTypeName"
              valueField="dictEmailTypeExternalId"
              [valuePrimitive]="true"
              formControlName="emailTypeId"
              [(ngModel)]="emailTypeId"
              required
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>

          <kendo-formfield>
            <kendo-textbox
              formControlName="content"
              [style.width.px]="250"
              size="small"
              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              [clearButton]="true"
              placeholder="E-mail"
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror *ngIf="formEmail.controls?.['email']?.errors">Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Социальная сеть-->
        <kendo-label *ngIf="socialMode" text="Ссылка на соц. сеть"></kendo-label>
        <dd *ngIf="socialMode">
          <kendo-formfield>
            <kendo-textbox
              formControlName="content"
              [style.width.px]="250"
              size="small"
              [clearButton]="true"
              placeholder="Соц. сеть"
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror *ngIf="formSocialNetworks.controls?.['socialNetwork']?.errors">Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Комментарий
        <kendo-label [for]="comment" text="Комментарий"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="comment"
              [style.width.px]="250"
              size="small"
              [clearButton]="true"
              placeholder="Комментарий"
              #comment
            ></kendo-textbox>

          </kendo-formfield>
        </dd>-->

      </dl>
    </form>
    <div class="editModeButtons">
      <button kendoButton
              size="small"
              themeColor="primary"
              *ngIf="editModePhone"
              [disabled]="!formContacts.valid"
              (click)="onSaveContact($event)">
        {{ isNewEmail ? "Добавить" : "Сохранить" }}
      </button>

      <button kendoButton
              size="small"
              *ngIf="editModePhone"
              (click)="closeContact()">
        Отмена
      </button>
    </div>
  </ng-template>
</div>

<!----------------------------------------->
<!-----------------Address----------------->
<!----------------------------------------->
<div *ngIf="!isHidden(enum.addresses)">
  <h4 class="title-gray-color">Адреса</h4>

  <div class="grid-container">
    <!--Grid-->
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridBinding]="addresses"
                  *ngIf="!editModeAddressRegistration; else editAddresreg"
                  (add)="addHandlerAddressReg($event)"
                  (remove)="removeHandlerAddress($event)"
                  (edit)="transitionHandlerAddressReg($event)"
                  [sortable]="true"
                  scrollable="none"
                  [loading]="loadingAddress">

        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>

        <ng-template kendoGridToolbarTemplate *ngIf="editable" [position]="position">
          <button kendoGridAddCommand
                  [disabled]="studentMainInfoComponent.deleted"
                  size="small"
                  themeColor="success"
                  icon="plus"
          >Добавить адрес</button>
        </ng-template>

        <kendo-grid-column field="isRegistered"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="150">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.isRegistered ? "Адрес по прописке" : "Фактический адрес проживания"}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="dictCityTypeId"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="100">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{livesInHostel(dataItem)}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="zipCode"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="50">
        </kendo-grid-column>

        <kendo-grid-column field="dictCityTypeId"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="150">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{ nameCityTypes(dataItem.dictCityTypeId) }}
            {{dataItem.city}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="state"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="150">
        </kendo-grid-column>

        <kendo-grid-column field="dictStreetTypeId"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="150">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{ nameStreetTypes(dataItem.dictStreetTypeId) }}
            {{dataItem.street}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="house"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="50">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{(!dataItem?.house ? "" :  "д. " + dataItem.house)}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="building"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="50">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{(!dataItem?.building ? "" : " стр. " + dataItem.building)}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="flat"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="50">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{livesInHostel(dataItem)==""?(!dataItem?.flat ? "" :  "кв. " + dataItem.flat):
            (studentHostelRoomFields!=undefined&&studentHostelRoomFields.value!=""?"комн. " + studentHostelRoomFields!.value:"")}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="corpus"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="50">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{(!dataItem?.corpus ? "" : "корп. " +  dataItem.corpus)}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column  [headerStyle]="{ 'display': 'none' }"
                            *ngIf="!studentMainInfoComponent.deleted"
                            [width]="150">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand type="button"
                    icon="edit"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    *ngIf="editable">
            </button>

            <button kendoGridRemoveCommand
                    type="button"
                    icon="delete"
                    fillMode="flat"
                    size="small"
                    rounded="full"
                    *ngIf="editable">
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>

  <!------------Edit Addres Reg------------>

  <ng-template #editAddresreg >
    <form class="k-form" [formGroup]="formAddresses" class="ngForm">

      <dl class="about">
        <div class="label-sticky-top">
          <kendo-label [for]="zipCode" text="Тип адреса"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist [data]="addressType"
                                class="input-main"
                                textField="typeName"
                                valueField="isRegistered"
                                [valuePrimitive]="true"
                                (valueChange)="valueAddressTypeChange($event)"
                                formControlName="isRegistered"
                                [formControl]="$any(formAddresses).controls['isRegistered']"
                                required>
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label text="Проживает в общежитии"></kendo-label>
        <dd>
          <input id="ac" type="checkbox" kendoCheckBox
                 name="hostel"
                 [formControl]="$any(formAddresses).controls['studentHostel']">
        </dd>

        <kendo-label *ngIf="formAddresses.value!.studentHostel" text="Общежитие"></kendo-label>
        <dd  *ngIf="formAddresses.value!.studentHostel">
          <kendo-dropdownlist
            [data]="dictHostels"
            textField="hostelNum"
            valueField="hostelNum"
            (selectionChange)="hostelChange($event)"
            [valuePrimitive]="true"
            [formControl]="$any(formAddresses).controls['hostelNum']"
            name="hostelNumber"
            class="address-hostel">
          </kendo-dropdownlist>

          <kendo-label class="address-hostel-label" text="Номер комнаты"></kendo-label>
          <kendo-textbox
            [maxlength]=5
            size="small"
            [clearButton]="true"
            placeholder="Номер комнаты"
            [formControl]="$any(formAddresses).controls['hostelRoom']"
            name="hostelRoom"
            class="address-hostel"
          ></kendo-textbox>
        </dd>

        <!--Индекс-->
        <kendo-label [for]="zipCode" text="Индекс"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="zipCode"
              class="input-main"
              size="small"
              [clearButton]="true"
              [disabled]="formAddresses.value!.studentHostel"
              placeholder="Индекс"
              #zipCode
            ></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Страна-->
        <div class="label-sticky-top">
          <kendo-label [for]="country" text="Страна"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-combobox
              [data]="citizenship"
              [disabled]="formAddresses.value!.studentHostel"
              textField="country"
              valueField="country"
              formControlName="country"
              class="input-main"
              #country
              (valueChange)="onChange($event)"
              [openOnFocus]="country"
              [kendoDropDownFilter]="filterSettings"
              placeholder="Страна"
              [valuePrimitive]="true" required>
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Поиск в классификаторе адресов-->
        <div class="label-sticky-top" *ngIf="showExternalAddressSearch">
          <kendo-label text="Поиск адреса"></kendo-label>
        </div>
        <dd *ngIf="showExternalAddressSearch">
          <kendo-formfield>
            <kendo-combobox
              [data]="addressHints"
              textField="full_name"
              valueField="object_id"
              index="0"
              [filterable]="true"
              [disabled]="formAddresses.value!.studentHostel"
              formControlName="addressSearch"
              class="input-main"
              (valueChange)="onAddressChange($event)"
              (filterChange)="onAddressFilterChange($event)">
            </kendo-combobox>
          </kendo-formfield>
        </dd>

        <div class="label-sticky-top">
          <kendo-label text="Регион"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-combobox [data]="selectDictStates"
                            [disabled]="formAddresses.value!.studentHostel"
                            *ngIf="stateSelect"
                            textField="stateName"
                            valueField="stateName"
                            [valuePrimitive]="true"
                            formControlName="state"
                            class="input-main"
                            [openOnFocus]="stateAutoComplete"
                            [kendoDropDownFilter]="filterSettings"
                            placeholder="Регион"
                            #stateAutoComplete
                            required>
            </kendo-combobox>
            <kendo-textbox
              formControlName="state"
              [disabled]="formAddresses.value!.studentHostel"
              *ngIf="!stateSelect"
              class="input-main"
              size="small"
              [clearButton]="true"
              placeholder="Регион"
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label [for]="country" text="Район"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="district"
              [disabled]="formAddresses.value!.studentHostel"
              class="input-main"
              size="small"
              [clearButton]="true"
              placeholder="Район"
              #district
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <div class="label-sticky-top">
          <kendo-label [for]="dictCityTypeId" text="Населенный пункт"></kendo-label>
        </div>
        <dd class="ddinline">
          <kendo-formfield>
            <kendo-combobox [data]="cityTypes"
                            class="address-drop-down"
                            [kendoDropDownFilter]="filterSettings"
                            textField="cityTypeName"
                            valueField="dictCityTypeExternalId"
                            [openOnFocus]="dictCityTypeId"
                            #dictCityTypeId
                            [disabled]="formAddresses.value!.studentHostel"
                            [valuePrimitive]="true"
                            formControlName="dictCityTypeId"
                            [formControl]="$any(formAddresses).controls['dictCityTypeId']"
                            required>
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>

          <kendo-formfield>
            <kendo-textbox
              formControlName="city"
              [style.width.px]="250"
              [clearButton]="true"
              placeholder="Название"
              [disabled]="formAddresses.value!.studentHostel"
              #city
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <div class="label-sticky-top">
          <kendo-label [for]="dictStreetTypeId" text="Улица"></kendo-label>
        </div>
        <dd class="ddinline">
          <kendo-formfield>
            <kendo-combobox [data]="streetTypes"
                            class="address-drop-down"
                            [kendoDropDownFilter]="filterSettings"
                            textField="streetTypeName"
                            valueField="dictStreetTypeExternalId"
                            [openOnFocus]="dictStreetTypeId"
                            #dictStreetTypeId
                            [disabled]="formAddresses.value!.studentHostel"
                            [valuePrimitive]="true"
                            formControlName="dictStreetTypeId"
                            [formControl]="$any(formAddresses).controls['dictStreetTypeId']"
                            required>
            </kendo-combobox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>

          <kendo-formfield>
            <kendo-textbox
              formControlName="street"
              [style.width.px]="250"
              [clearButton]="true"
              placeholder="Название"
              [disabled]="formAddresses.value!.studentHostel"
              #street
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <div class="label-sticky-top">
          <kendo-label [for]="house" text="Дом"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="house"
              class="input-main"
              size="small"
              [disabled]="formAddresses.value!.studentHostel"
              [clearButton]="true"
              placeholder="Дом"
              #house
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label [for]="building" text="Строение"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="building"
              class="input-main"
              size="small"
              [disabled]="formAddresses.value!.studentHostel"
              [clearButton]="true"
              placeholder="Строение"
              #building
            ></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label [for]="flat" text="Квартира"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="flat"
              class="input-main"
              size="small"
              [disabled]="formAddresses.value!.studentHostel"
              [clearButton]="true"
              placeholder="Квартира"
              #flat
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <kendo-label [for]="corpus" text="Корпус"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="corpus"
              class="input-main"
              size="small"
              [disabled]="formAddresses.value!.studentHostel"
              [clearButton]="true"
              placeholder="Корпус"
              #corpus
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <kendo-label text="Полный адрес"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textarea
              class="input-address"
              resizable="none"
              [rows]="3"
              formControlName="comment"
              [formControl]="$any(formAddresses).controls['comment']">
            </kendo-textarea>
          </kendo-formfield>
        </dd>

        <kendo-label text="Дата регистрации" *ngIf="registeredAddress && !isHidden(enum.pd_address)"></kendo-label>
        <kendo-label text="Дата начала проживания" *ngIf="!registeredAddress && !isHidden(enum.pd_address)"></kendo-label>
        <dd *ngIf="!isHidden(enum.pd_address)">
          <kendo-formfield>
            <kendo-datepicker
              #registrationDate
              format="dd.MM.yyyy"
              class="input-address"
              [openOnFocus]="registrationDate"
              (focus)="onFocus()"
              formControlName="dateRegistered"
            >
            </kendo-datepicker>
          </kendo-formfield>
        </dd>

      </dl>
    </form>
    <div class="buttonsAddress">
      <div>
        <button kendoButton
                themeColor="primary"
                size="small"
                *ngIf="editModeAddressRegistration"
                [disabled]="!formAddresses.valid && !formAddresses.value.studentHostel"
                (click)="onSaveAddressReg($event)">
          Сохранить
        </button>

        <button kendoButton
                size="small"
                *ngIf="editModeAddressRegistration"
                (click)="closeAddressReg()">
          Отмена
        </button>
      </div>

      <button kendoButton
              size="small"
              class="fill-from-res"
              *ngIf="editModeAddressRegistration && hasRegistered"
              [disabled]="addresses == undefined || addresses.length == 0 || !hasRegistered || formAddresses.value!.studentHostel"
              (click)="getAddressReg()">
        Совпадает с адресом регистрации
      </button>
    </div>
  </ng-template>
</div>

<!--Additional information-->
<div *ngIf="!isHidden(enum.references)">
  <h4 class="title-gray-color">Справки</h4>

  <div class="grid-container">
    <!--Grid-->
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridBinding]="medicalCertificates"
                  *ngIf="!editModeDop; else editFormDop"
                  (add)="addHandlerMedicalCertificate($event)"
                  (remove)="removeHandlerMedicalCertificate($event)"
                  (edit)="transitionHandlerMedicalCertificate($event)"
                  [sortable]="true"
                  scrollable="none"
                  [loading]="loadingAdditional">

        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>

        <ng-template kendoGridToolbarTemplate *ngIf="editable" [position]="position">
          <button kendoGridAddCommand
                  [disabled]="studentMainInfoComponent.deleted"
                  size="small"
                  themeColor="success"
                  icon="plus"
                  ico
          >Добавить справку</button>
        </ng-template>

        <kendo-grid-column field="name"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="350">
        </kendo-grid-column>

        <kendo-grid-column field="number"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="400">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.number!=undefined? "Номер: " + (dataItem?.number == null ? "" :  dataItem.number) + ", ":""}}
            Дата выдачи: {{(dataItem?.date == null ? "" :  dataItem.date| date: "dd.MM.yyyy")}}
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="note"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="300">

          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.note}}
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column  [headerStyle]="{ 'display': 'none' }"
                            *ngIf="!studentMainInfoComponent.deleted"
                            [width]="150">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand type="button"
                    icon="edit"
                    fillMode="flat"
                    rounded="full"
                    size="small"
                    *ngIf="editable">
            </button>

            <button kendoGridRemoveCommand
                    type="button"
                    icon="delete"
                    fillMode="flat"
                    size="small"
                    rounded="full"
                    *ngIf="editable">
            </button>

            <button
              kendoGridSaveCommand
              type="button"
              [disabled]="myForm.invalid!"
              size="small"
              icon="k-i-save k-i-floppy"
              themeColor="primary">
              {{ isNew ? "Добавить" : "Обновить" }}
            </button>
            <button kendoGridCancelCommand icon="k-i-close k-i-x" type="button" size="small">
              {{ "Отмена" }}
            </button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>

  <div class="additionalEditModeButtons">
    <button kendoButton
            size="small"
            themeColor="primary"
            *ngIf="editModeDop"
            [disabled]="!formMedicalCertificate.valid"
            (click)="onSaveMedicalCertificate($event)">
      Сохранить
    </button>

    <button kendoButton
            size="small"
            *ngIf="editModeDop"
            (click)="closeDop()">
      Отмена
    </button>
  </div>

  <ng-template #editFormDop >
    <form class="k-form" [formGroup]="formMedicalCertificate" class="ngForm">
      <dl class="about">
        <kendo-label text="Название справки"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              size="small"
              class="med-name-width"
              [clearButton]="true"
              placeholder=""
              name="medicalName"
              formControlName="name"
              [formControl]="$any(formMedicalCertificate).controls['name']"
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <kendo-label text="Номер"></kendo-label>
        <dd class="med-container">
          <kendo-formfield>
            <kendo-textbox
              [maxlength]=5
              size="small"
              class="med-num-width"
              [clearButton]="true"
              placeholder=""
              name="medicalNumber"
              formControlName="number"
              [formControl]="$any(formMedicalCertificate).controls['number']"
            ></kendo-textbox>
          </kendo-formfield>

          <kendo-label text="Дата выдачи" class="med-num-label"></kendo-label>
          <kendo-formfield>
            <kendo-datepicker
              style="height:31px"
              format="dd.MM.yyyy"
              class="med-num-width"
              #medicalCertificate
              [openOnFocus]="medicalCertificate"
              (focus)="onFocus()"
              formControlName="date"
              [formControl]="$any(formMedicalCertificate).controls['date']"
              required>
            </kendo-datepicker>
            <span class="required">*</span>
            <!--<kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>-->
          </kendo-formfield>
        </dd>

        <kendo-label text="Примечание"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textarea
              class="med-name-width"
              resizable="none"
              [rows]="3"
              formControlName="note"
              [formControl]="$any(formMedicalCertificate).controls['note']">
            </kendo-textarea>
          </kendo-formfield>
        </dd>
      </dl>
    </form>
  </ng-template>
</div>

<div *ngIf="!isHidden(enum.info_admissions_committee)">
  <h4 class="title-gray-color">Информация приемной комиссии</h4>

  <div class="grid-container">
    <!--Grid-->
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridBinding]="allStudentAdditional"
                  [sortable]="true"
                  scrollable="none"
                  [loading]="loadingStudentAdditional">

        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>

        <kendo-grid-column field="dictAdditionalStudentField.additionalStudentFieldName"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="350">
        </kendo-grid-column>
        <kendo-grid-column field="value"
                           [headerStyle]="{ 'display': 'none' }"
                           [width]="400">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{formatAdditionalValue(dataItem.value, dataItem.dictAdditionalStudentField?.dataType)}}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>
</div>

<!------Дополнительная информация----->
<div *ngIf="!isHidden(enum.additional_info)">

  <div class="otherinfo">
    <h4 class="title-gray-color">Дополнительная информация</h4>
    <button kendoButton *ngIf="!editDateDopInformation"
        icon="edit"
        fillMode="flat"
        rounded="full"
        size="small"
        (click)="editDopInformation()">
    </button>
  </div>

  <div class="container">
    <dl class="about">
        <kendo-label text="Срок действия справки о медицинском осмотре" [ngClass]="{'fontRoboto': !editDateDopInformation}"></kendo-label>
        <dd>
          <span *ngIf="!editDateDopInformation" [ngClass]="{fontRoboto: !editDateDopInformation}">
            {{ medicalInspectionCertificate?.value | date:'dd.MM.yyyy'}}
          </span>
          <kendo-datepicker *ngIf="editDateDopInformation"
              #medicalDate
              format="dd.MM.yyyy"
              class="w-250"
              [openOnFocus]="medicalDate"
              (focus)="onFocus()"
              [(ngModel)]="medicalCertificateDate"
          >
          </kendo-datepicker>
        </dd>

        <kendo-label text="Срок действия справки об отсутствии судимости" [ngClass]="{'fontRoboto': !editDateDopInformation}"></kendo-label>
        <dd>
          <span *ngIf="!editDateDopInformation" [ngClass]="{'fontRoboto': !editDateDopInformation}">
            {{ criminalCertificate?.value | date:'dd.MM.yyyy' }}
          </span>
          <kendo-datepicker *ngIf="editDateDopInformation"
              #criminallDate
              format="dd.MM.yyyy"
              class="w-250"
              [openOnFocus]="criminallDate"
              (focus)="onFocus()"
              [(ngModel)]="criminalCertificateDate"
          >
          </kendo-datepicker>
        </dd>
    </dl>
  </div>

  <div class="editModeButtons" *ngIf="editDateDopInformation">
    <button kendoButton
            size="small"
            themeColor="primary"
            (click)="saveDopInformation()">
      Сохранить
    </button>

    <button kendoButton
            size="small"
            (click)="closeDopInformation()">
      Отмена
    </button>
  </div>
</div>

<!-------Трудовая деятельность------->
<div *ngIf="!isHidden(enum.work)">

  <h4 class="title-gray-color">Трудовая деятельность</h4>

  <div class="m-t15 align-r">
    <button kendoButton *ngIf="employment.length > 0"
            type="button"
            icon="print"
            size="small"
            (click)="exportToPDF()">
      Печать списка
    </button>
  </div>

  <div class="grid-container">
    <kendo-grid #gridEmployment
      [data]="employment"
      >
      <kendo-grid-column field="organizationName" title="Наименование организации работодателя" [width]="230" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p class="leftAlignment"> {{ dataItem.organizationName}} </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="postName" title="Должность" [width]="160" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p> {{ dataItem.postName}} </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="dateStart" title="Дата трудоустройства" [width]="120" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p> {{ dataItem.dateStart | date:'dd.MM.yyyy'}} </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="dateFinish" title="Дата увольнения" [width]="120" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p> {{ dataItem.dateFinish | date:'dd.MM.yyyy'}} </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="isCurrent" title="Текущее место работы" [width]="100" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <p class="leftAlignment"> {{ dataItem.isCurrent ? "Да" : "Нет"}} </p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="document" title="Подтверждающий документ" [width]="130" headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
            <button kendoButton icon="save" class="button-save" *ngIf="dataItem.scanFileId" (click)="loadFile(dataItem.scanFileId)">
            </button>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-pdf
          fileName="Трудовая деятельность.pdf"
          [allPages]="true"
          paperSize="A4"
          [scale]="0.7"
          [repeatHeaders]="true"
          [landscape]="true"
          [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }"
        >
      </kendo-grid-pdf>
    </kendo-grid>
  </div>
</div>

<!------Прочие документы------>
<div *ngIf="!isHidden(enum.other_documents)">
  <h4 class="title-gray-color">Прочие документы</h4>

  <div class="grid-container">
    <!--Grid-->
    <form novalidate #myForm="ngForm">
      <kendo-grid [kendoGridBinding]="otherDocuments"
                  *ngIf="!editOtherDocuments; else editDocs"
                  (add)="addHandlerOtherDocuments()"
                  (remove)="removeOtherDocuments($event)"
                  (edit)="transitionHandlerOtherDocuments($event)"
                  [sortable]="true"
                  scrollable="none"
                  [loading]="loadingOtherDocuments">

          <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
              <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
          </ng-template>

          <ng-template kendoGridToolbarTemplate *ngIf="editable" [position]="position">
            <button kendoGridAddCommand
                    [disabled]="studentMainInfoComponent.deleted"
                    size="small"
                    themeColor="success"
                    icon="plus">Добавить документ</button>
          </ng-template>

          <kendo-grid-column field="dictDocumentName"
                            [headerStyle]="{ 'display': 'none' }"
                            [width]="400">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.typeName }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="serial"
                [headerStyle]="{ 'display': 'none' }"
                [width]="700">
                <ng-template kendoGridCellTemplate let-dataItem>
                Серия: {{(!dataItem?.serial ? "" :  dataItem.serial)}}
                Номер: {{(!dataItem?.number ? "" :  dataItem.number)}}
                Выдан: {{(!dataItem?.issueOrganization ? "" :  dataItem.issueOrganization)}}
                Дата выдачи: {{(!dataItem?.issueDate ? "" :  dataItem.issueDate| date: "dd.MM.yyyy")}}
                </ng-template>
          </kendo-grid-column>

          <kendo-grid-column  [headerStyle]="{ 'display': 'none' }"
                              [width]="150">
            <ng-template kendoGridCellTemplate let-isNew="isNew">
              <button kendoGridEditCommand type="button"
                      icon="edit"
                      fillMode="flat"
                      rounded="full"
                      size="small"
                      *ngIf="editable">
              </button>

              <button kendoGridRemoveCommand
                      type="button"
                      icon="delete"
                      fillMode="flat"
                      size="small"
                      rounded="full"
                      *ngIf="editable">
              </button>

            </ng-template>
          </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>

  <!------Edit OtherDocuments------>

  <ng-template #editDocs>
    <form class="k-form" [formGroup]="formOtherDocuments" class="ngForm">

      <dl class="about">
        <div class="label-sticky-top">
          <kendo-label text="Тип документа"></kendo-label>
        </div>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist
                [data]="otherDocumentTypes"
                class="input-main"
                textField="typeName"
                valueField="externalId"
                [valuePrimitive]="true"
                formControlName="dictOtherDocumentTypeId"
                [formControl]="$any(formOtherDocuments).controls['dictOtherDocumentTypeId']"
                required
            >
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Серия документа-->
        <kendo-label text="Серия документа"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="serial"
              class="input-main"
              size="small"
              [clearButton]="true"
              placeholder="Серия документа"
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Номер документа-->
        <kendo-label text="Номер документа"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="number"
              class="input-main"
              size="small"
              [clearButton]="true"
              placeholder="Номер документа"
              required
            ></kendo-textbox>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>

        <!--Кем выдано-->
        <kendo-label text="Кем выдано"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox
              formControlName="issueOrganization"
              class="input-main"
              size="small"
              [clearButton]="true"
              placeholder="Кем выдано"
            ></kendo-textbox>
          </kendo-formfield>
        </dd>

        <kendo-label text="Дата выдачи"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-datepicker
              #issueDate
              format="dd.MM.yyyy"
              class="input-address"
              [openOnFocus]="issueDate"
              (focus)="onFocus()"
              formControlName="issueDate"
              required
            >
            </kendo-datepicker>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </dd>
      </dl>
    </form>

    <div class="editModeButtons">
      <button kendoButton
              size="small"
              themeColor="primary"
              *ngIf="editOtherDocuments"
              [disabled]="!formOtherDocuments.valid"
              (click)="onSaveOtherDocuments($event)">
        {{ isNewDocument ? "Добавить" : "Сохранить" }}
      </button>

      <button kendoButton
              size="small"
              *ngIf="editOtherDocuments"
              (click)="closeOtherDocuments()">
        Отмена
      </button>
    </div>
  </ng-template>
</div>

<div kendoDialogContainer></div>

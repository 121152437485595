import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StudyForm } from '../../models/dicts/studyform.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class StudyformService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studyform}`;

  constructor(private http: HttpClient) { }

  //Get all StudyForm
  public getAllStudyForm(): Observable<StudyForm[]> {
    return this.http.get<StudyForm[]>(this.baseUrl);
  }

  //Add StudyForm
  addStudyForm(studyForm: StudyForm): Observable<StudyForm> {
    return this.http.post<StudyForm>(this.baseUrl, studyForm)
      .pipe(catchError(this.handleError));
  }

  //Delete StudyForm
  deleteStudyForm(id: string): Observable<StudyForm> {
    return this.http.delete<StudyForm>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit StudyForm
  updateStudyForm(studyForm: StudyForm): Observable<StudyForm> {
    return this.http.put<StudyForm>(this.baseUrl + '/' + studyForm.externalId, studyForm)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DictFormDesignerDataType } from 'src/app/models/education/dictFormDesignerDataType.model';
import { DictWorkStudyPlanType } from 'src/app/models/education/dictWorkStudyPlanType.model';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { DictFormDesignerDataTypeService } from 'src/app/services/education/DictFormDesignerDataType/dict-form-designer-data-type.service';
import { DictWorkStudyPlanTypeService } from 'src/app/services/education/DictWorkStudyPlanType/dict-work-study-plan-type.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';
import { Filter } from '../../../models/education/levelandstandardfilter.model';
import { Guid } from 'guid-typescript';
import { FilialService } from '../../../services/education/Filial/filial.service';
import { Filial } from '../../../models/education/filial.model';


@Component({
  selector: 'dict-work-study-plan-type',
  templateUrl: './dict-work-study-plan-type.component.html',
  styleUrls: ['./dict-work-study-plan-type.component.scss']
})
export class DictWorkStudyPlanTypeComponent implements OnInit {

    public gridStyle: string = 'grid-default';
    public isOpened: boolean = false;
    public loading: boolean = false;
    public editable?: boolean;
    public isNew: boolean = false;

    public classroomWork: any[] = [
        {id: 1, classroomWork: true, name: "Да"},
        {id: 2, classroomWork: false, name: "Нет"}
    ];

    public isRecognizingAmounts: any[] = [
        {id: 1, isRecognizingAmounts: true, name: "Да"},
        {id: 2, isRecognizingAmounts: false, name: "Нет"}
    ];

    public formDesignerDataTypes!: DictFormDesignerDataType[];
    public workStudyPlanTypesByFilter: DictWorkStudyPlanType[] = [];
    public traininglevels!: TrainingLevel[];
    public standardTypes!: StandardType[];
    public filials!: Filial[];
    public secondFilter: Filter = {
      standardTypeExternalId: undefined,
      trainingLevelExternalId: undefined,
      filialExternalId: undefined
    }
    public workStudyPlanTypes!: DictWorkStudyPlanType[];

    constructor(private workStudyPlanTypeService: DictWorkStudyPlanTypeService,
                private notificationService: NotificationsService,
                private formDesignerDataTypeService: DictFormDesignerDataTypeService,
                private userAccessService: EducationUserAccessService,
                private trainingLevelService: TrainingLevelService,
                private standardTypeService: StandardtypeService,
                private filialService: FilialService
    ) {
    }

    ngOnInit(): void {
        this.getAccessLevel();
        this.getAllWorkStudyPlanTypes();
        this.getAllTrainingLevels();
        this.getAllStandardTypes();
        this.getAllFormDesignerDataTypes();
        this.getAllFilials();
    }

    // Edit Form
    public editForm: FormGroup = new FormGroup({
        dictWorkStudyPlanTypeId: new FormControl(0),
        dictWorkStudyPlanTypeExternalId: new FormControl(),
        dictWorkStudyPlanTypeName: new FormControl(),
        dictWorkStudyPlanTypeShortName: new FormControl(),
        dataType: new FormControl(),
        dictFormDesignerDataType: new FormControl(),
        classroomWork: new FormControl(),
        isRecognizingAmounts: new FormControl(),
        serialNumber: new FormControl(),
    });

    //Getting all entries from WorkStudyPlanTypes
    public getAllWorkStudyPlanTypes() {
        this.loading = true;
        this.workStudyPlanTypeService.getAllWorkStudyPlanTypes()
            .subscribe({
                next: (response) => {
                    this.loading = false;
                    this.workStudyPlanTypes = response;
                    this.workStudyPlanTypes.forEach(x => x.dictFormDesignerDataType = this.formDesignerDataTypes.find(f => f.dataType == x.dataType));
                    this.applyFilter();
                },
                error: () => {
                    this.loading = false;
                    this.notificationService.showError("Не удалось загрузить виды работ учебного плана")
                }
            });
    }

    public defaultItemTrainingLevel: { trainingLevelName: string; trainingLevelExternalId?: any } = {
        trainingLevelName: "Не выбрано",
        trainingLevelExternalId: null,
      };
    
      public defaultItemStandardType: { standardTypeShortName: string; standardTypeExternalId?: any } = {
        standardTypeShortName: "Не выбрано",
        standardTypeExternalId: null,
      };

      public defaultItemFilial: { filialShortName: string; filialExternalId?: any } = {
        filialShortName: "Не выбрано",
        filialExternalId: null,
      };
    
      applyFilter() {
        this.workStudyPlanTypesByFilter = [];
        this.workStudyPlanTypesByFilter = this.workStudyPlanTypes.filter(x => Guid.parse(x.standardTypeExternalId) == this.secondFilter.standardTypeExternalId &&
        Guid.parse(x.trainingLevelExternalId) == this.secondFilter.trainingLevelExternalId &&
        Guid.parse(x.filialExternalId) == this.secondFilter.filialExternalId);
      }
    
      //Getting all entries from standard types
      public getAllStandardTypes() {
        this.standardTypeService.getAllStandardTypes()
          .subscribe(
            response => {
              this.standardTypes = response;
            }
          );
      }
    
      //Getting all entries from study levels
      public getAllTrainingLevels() {
        this.trainingLevelService.getAllTrainingLevels()
          .subscribe(
            response => {
              this.traininglevels = response;
            }
          );
      }

      //Getting all entries from filials
      public getAllFilials() {
        this.filialService.getAllFilials()
          .subscribe(
            response => {
              this.filials = response;
            }
          );
      }

    //Getting all entries from FormDesignerDataType
    public getAllFormDesignerDataTypes() {
        this.formDesignerDataTypeService.getAllFormDesignerDataTypes()
            .subscribe(
                response => {
                    this.formDesignerDataTypes = response;
                }
            );
    }

    public saveWorkStudyPlanType() {
        // Add new FormDesignerDataType
        if (this.isNew) {
            this.checkBoxReturnsNullCheck();
            this.workStudyPlanTypeService.addWorkStudyPlanType({ ...this.editForm?.value, ...this.secondFilter })
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess("Вид работы учебного плана был успешно добавлен!");
                        this.getAllWorkStudyPlanTypes();
                        this.onCancel();
                    },
                    error: () => {
                        this.notificationService.showError("Не удалось добавить вид работы учебного плана");
                        this.onCancel();
                    }
                });
        }
        // Edit FormDesignerDataType
        else {
            this.checkBoxReturnsNullCheck();
            this.workStudyPlanTypeService.updateWorkStudyPlanType({ ...this.editForm?.value, ...this.secondFilter })
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess("Вид работы учебного плана был успешно сохранен!");
                        this.getAllWorkStudyPlanTypes();
                        this.onCancel();
                    },
                    error: () => {
                        this.notificationService.showError('Не удалось изменить вид работы учебного плана');
                        this.onCancel();
                    }
                })
        }
    }

    public checkBoxReturnsNullCheck() {
        const booleans = ['classroomWork', 'isRecognizingAmounts'];
        for (let i = 0; i < booleans.length; i++) {
            const value = this.editForm.get(`${booleans[i]}`)?.value;
            if (value === null)
                this.editForm.get(`${booleans[i]}`)?.setValue(false);
        }
    }

    public getAccessLevel() {
        this.userAccessService.getAccessLevel().subscribe({
            next: (response) => {
                this.editable = !response.dictAccessLevel;
            },
            error: () => {
            }
        })
    }

    public addHandler(): void {
        this.isNew = true;
        this.isOpened = true;
        this.gridStyle = 'grid-hidden';
    }

    public editHandler(dataItem: any): void {
        this.editForm = formGroup(dataItem);
        this.isNew = false;
        this.gridStyle = 'grid-hidden';
        this.isOpened = true;
    }

    public onCancel(): void {
        this.editForm.reset();
        this.gridStyle = 'grid-default';
        this.isOpened = false;
    }
}

const formGroup =
    (dataItem: {
      dictWorkStudyPlanTypeId?: any;
      dictWorkStudyPlanTypeExternalId?: any;
      dictWorkStudyPlanTypeName?: any;
      dictWorkStudyPlanTypeShortName?: any;
      dataType?: any;
      dictFormDesignerDataType?: any;
      classroomWork?: any;
      isRecognizingAmounts?: any;
      serialNumber?: any;
    }) =>
        new FormGroup({
          dictWorkStudyPlanTypeId: new FormControl(dataItem.dictWorkStudyPlanTypeId),
          dictWorkStudyPlanTypeExternalId: new FormControl(dataItem.dictWorkStudyPlanTypeExternalId),
          dictWorkStudyPlanTypeName: new FormControl(dataItem.dictWorkStudyPlanTypeName),
          dictWorkStudyPlanTypeShortName: new FormControl(dataItem.dictWorkStudyPlanTypeShortName),
          dataType: new FormControl(dataItem.dataType),
          dictFormDesignerDataType: new FormControl(dataItem.dictFormDesignerDataType),
          classroomWork: new FormControl(dataItem.classroomWork),
          isRecognizingAmounts: new FormControl(dataItem.isRecognizingAmounts),
          serialNumber: new FormControl(dataItem.serialNumber),
        });

import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Notification} from "../../../models/publications/notification.model";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.main}/GetNotification`;

  private observer = new Subject();
  public subscriber$ = this.observer.asObservable();

  constructor(private http: HttpClient) {
  }

  public refreshBadge(){
    this.observer.next(0);
  }

  public getNotification(): Observable<Notification> {
    return this.http.get<Notification>(this.baseUrl);
  }
}

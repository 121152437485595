import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CheckableSettings,
  NodeClickEvent,
  TreeItem,
} from '@progress/kendo-angular-treeview';

import { DictsService } from 'src/app/services/currentcontrol/dicts.service';
import { TreesService } from 'src/app/services/currentcontrol/trees.service';
import { Observable, of} from 'rxjs';
import { SemesterYearsModel } from 'src/app/models/currentcontrol/semesterYears.model';
import { TrainingLevelsModel } from 'src/app/models/currentcontrol/trainingLevels.model';
import { TreeCommonModel } from 'src/app/models/currentcontrol/trees.model';
import {CurrentDayEnd, getDayEnd} from "../../../helpers/date-helper";
import {Guid} from "guid-typescript";
import * as moment from "moment";
import {MultiSelectEnum} from "../../../models/contingent/enums/multiselect.enum";

@Component({
  selector: 'app-currentcontrolhometree',
  templateUrl: './currentcontrolhometree.component.html',
  styleUrls: ['./currentcontrolhometree.component.scss'],
})
export class CurrentcontrolhometreeComponent implements OnInit {
  @Output() onChangeGroup: EventEmitter<TreeCommonModel> =
    new EventEmitter<TreeCommonModel>();
  @Output() onEducationSlices: EventEmitter<SemesterYearsModel> =
    new EventEmitter<SemesterYearsModel>();
  @Output() onChangeChecked: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output() onChangeTreeData: EventEmitter<TreeCommonModel[]> =
    new EventEmitter<TreeCommonModel[]>();
  @Output() selectedTrainingLevelChange: EventEmitter<string[] | null> =
    new EventEmitter();
  @Output() expandedKeysChange: EventEmitter<string[]> = new EventEmitter();
  @Output() selectedKeysChange: EventEmitter<string[]> = new EventEmitter();

  @Output() onChangeContingentDate: EventEmitter<Date> =
    new EventEmitter<Date>();
  @Input() week: string | null = null;
  @Input() treeType: string = 'department';
  @Input() checkable: boolean = false;
  @Input() checkGroup: boolean = false;
  @Input() checkedItems: string[] = [];
  @Input() selectedKeys: string[] = [];

  public dataTree: TreeCommonModel[] = [];
  public traininglevels!: TrainingLevelsModel[];
  public selectedTrainingLevel?: string[] = undefined;
  public showContingentCalendar = false;
  public currentContingentDate: Date = CurrentDayEnd();
  public contingentDate: Date = CurrentDayEnd();
  public currentDate: Date = CurrentDayEnd();
  public showRaitingCalendar = false;
  public currentRaitingDate: Date = CurrentDayEnd();
  public raitingDate: Date = CurrentDayEnd();
  public educationSlices: SemesterYearsModel[] = [];
  public selectedSemester: SemesterYearsModel = {} as SemesterYearsModel;
  public currentSemester: SemesterYearsModel = {} as SemesterYearsModel;
  public treeSettings: CheckableSettings = { enabled: false };
  public selectedGroup: string = '';
  public isCheckGroup: boolean = false;
  public isEditDate: boolean = false;
  public expandedKeys: string[] = [];
  public discipline = false;
  public loadingTreeData: boolean = false;
  public changeGroup = "";

  constructor(
    private dictsService: DictsService,
    private treesService: TreesService
  ) {}

  async ngOnInit() {
    await this.getTrainingLevel();
    this.treeSettings = { enabled: this.checkable };
    await this.dictsService.updateDictSemesterYears();

    if (this.treeType === 'department') {
      this.treesService.departmentTree$.subscribe((response) => {
        this.dataTree = response;
        const storage =  this.getLocalStorage()
        if (response.length > 0 && storage !== null) {
          this.expandedKeys = storage.expandedKeys
        }
        this.loadingTreeData = false;
      });
    } else if (this.treeType === 'student') {
      this.treesService.studentTree$.subscribe((response) => {
        this.dataTree = response;
        this.loadingTreeData = false;
      });
    } else if (this.treeType === 'discipline') {
      this.treesService.disciplineTree$.subscribe((response) => {
        this.dataTree = response;
        this.loadingTreeData = false;
        this.onChangeTreeData.emit(response);
      });
    } else if (this.treeType === 'trainingLevel') {
      this.treesService.trainingLevelTree$.subscribe((response) => {
        this.dataTree = response;
        this.loadingTreeData = false;
      });
    }
    this.dictsService.semesterYears$.subscribe((response) => {
      if (response.length) {
        response.forEach(
          (item) =>
            (item.semesterNumberString =
              item.semesterInYear + ' семестр ' + item.semesterYearString)
        );

        let semesterYear = response.find((x)=> x.isCurrent)!.semesterYear;
        this.educationSlices = response.filter((x)=> x.semesterYear <= semesterYear);
        const defaultValue =
          response.find((item) => item.isCurrent) || response[0];
        this.currentSemester = defaultValue;
        this.selectedSemester = defaultValue;
        if (this.selectedTrainingLevel) {
          this.changeTreeData(this.selectedTrainingLevel && this.selectedTrainingLevel.length != null ? this.selectedTrainingLevel : null, this.selectedSemester.semesterInYear!,
            this.selectedSemester.semesterYear!, this.contingentDate);
        }
      }
    });
  }

  public async getTrainingLevel() {
    await this.dictsService.updateTrainingLevels();
    this.dictsService.trainingLevels$.subscribe((response) => {
      this.traininglevels = response;
      this.selectedTrainingLevel = Array.from(this.traininglevels, (x: TrainingLevelsModel) => x.id!);
      /*if (response.length) {
        this.selectedTrainingLevel = response[0];
      }
      // Add "All" option in traininglevel list
      const all: boolean = this.traininglevels.filter(value => value.name === 'Все').length > 0;
      if(!all && this.traininglevels.length > 1) {
        let item = new TrainingLevelsModel();
        item.id = '';
        item.name = 'Все';
        this.traininglevels.unshift(item);
        this.selectedTrainingLevel = this.traininglevels[0];
      }*/

      const storage =  this.getLocalStorage()
      if (storage !== null) {
        this.selectedSemester = storage.selectedSemester
        this.checkable = storage.checkable
        this.checkedItems = storage.checkedItems
        this.selectedTrainingLevel = storage.selectedTrainingLevel
        this.discipline = storage.discipline
        this.week = storage.week
        this.contingentDate = new Date(storage.contingentDate)
        this.acceptDate(false)
        if (storage.event)
          this.onSelectionTreeChange(storage.event, true)
        this.changeGroup = storage.changeGroup
        //localStorage.removeItem("middle_control_settings")
      }
      this.discipline = false
    });
  }

  public onBlur() {
    this.onChangeChecked.emit(this.checkedItems);
  }

  public onChangeEducationSlices(value: SemesterYearsModel): void {
    this.isEditDate = false
    this.selectedSemester = value;
    this.onEducationSlices.emit(value);
    this.closeExpandedTree();
  }

  public onSelectedFacultyChanged(trainingLevel: string[]) {
    this.selectedTrainingLevel = trainingLevel;

    if (this.selectedTrainingLevel)
      this.isCheckedMultiSelectTrainingLevel = this.selectedTrainingLevel.length === this.traininglevels.length;

    this.selectedTrainingLevelChange.emit(trainingLevel);

    this.closeExpandedTree();
  }

  public blurLevels() {
    if (!this.selectedTrainingLevel || this.selectedTrainingLevel.length == 0) {
      this.selectedTrainingLevel = Array.from(this.traininglevels, (x: TrainingLevelsModel) => x.id!) ;
      this.isCheckedMultiSelectTrainingLevel = true;
      this.onSelectedFacultyChanged(this.selectedTrainingLevel);
    }
  }

  public reloadTree(){
    this.changeTreeData(this.selectedTrainingLevel && this.selectedTrainingLevel.length != 0  ? this.selectedTrainingLevel: null, this.selectedSemester.semesterInYear!,
      this.selectedSemester.semesterYear!, this.contingentDate);
  }

  private async changeTreeData(
    trainingLevelId: (string|Guid)[] | null,
    semesterInYear: number,
    year: number,
    contingentDate: Date
  ) {
    this.loadingTreeData = true;
    if (this.treeType === 'department') {
      await this.treesService.updateDepartmentTree({semesterInYear, year, trainingLevelId, contingentDate, isCurrentSemester: this.currentSemester === this.selectedSemester}
      );
    } else if (this.treeType === 'student') {
      await this.treesService.updateStudentTree(
        this.selectedTrainingLevel,
        this.selectedSemester.semesterInYear!,
        this.selectedSemester.semesterYear!
      );
    } else if (this.treeType === 'discipline') {
      await this.treesService.updateDisciplineTree({semesterInYear, year, trainingLevelId, contingentDate, isCurrentSemester: this.currentSemester === this.selectedSemester});
    } else if (this.treeType === 'trainingLevel') {
      await this.treesService.updateTrainingLevelTree();
    }
  }

  public getToday(date: Date): string {
    date = new Date(date)
    let dateToday = date.getDate();
    return `${dateToday < 10 ? '0' : ''}${dateToday}.${
      ((date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
    }.${date.getFullYear()}`;
  }

  public onChangeDate(date: any){
    if(date)
      this.contingentDate = getDayEnd(date)
  }

  public onChangeContingentCalendar(): void {
    this.showContingentCalendar = false;
  }

  public onChangeRaitingCalendar(): void {
    this.showRaitingCalendar = false;
  }

  public disabledDates(date: Date): boolean {
    return date.getTime() > Date.now();
  }

  public onSelectionTreeChange(event: TreeItem, saved: boolean) {

    if(!saved)
      this.selectedSemester = this.educationSlices.find((item) => item.semesterNumberString === this.selectedSemester?.semesterNumberString)!

    if (event.index.length > 5) this.changeGroup = event.dataItem.text;
    const storage = this.getLocalStorage()
    let currentControlSaveSettings: any = {
      event: event,
      discipline:this.discipline,
      selectedSemester:this.selectedSemester,
      checkable: this.checkable,
      checkedItems: this.checkedItems,
      selectedTrainingLevel: this.selectedTrainingLevel,
      contingentDate: this.contingentDate,
      changeGroup: this.changeGroup
    }
    if (storage !== null){
      currentControlSaveSettings.week = storage.week
      currentControlSaveSettings.disciplineId = storage.disciplineId
      currentControlSaveSettings.electiveDisciplineId = storage.electiveDisciplineId
      currentControlSaveSettings.expandedKeys = storage.expandedKeys
      currentControlSaveSettings.contingentDate = storage.contingentDate
    }
    localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));

    if (event.dataItem.isGroup) {
      this.selectedGroup = event.dataItem.text;
      if (event.dataItem.text.trim()) {
        this.selectedKeysChange.emit([event.index]);
        this.onEducationSlices.emit({
          ...this.selectedSemester,
          semesterNum: event.dataItem.semesterNum,
        });
        this.onChangeGroup.emit(event.dataItem as TreeCommonModel);
      }
    }
    if (this.checkable && !event.dataItem.value) {
      const isExist = this.checkedItems.includes(event.dataItem.id);
      if (isExist) {
        const parentId = event.dataItem.id
          .split('_')
          .filter((item: string) => item.trim())
          .slice(0, -1)
          .join('_');
        this.onChangeChecked.emit(
          this.checkedItems.filter(
            (id) => id !== event.dataItem.id && id !== parentId
          )
        );
      } else {
        this.onChangeChecked.emit([...this.checkedItems, event.dataItem.id]);
      }
    }
  }

  public fetchChildren(node: any): Observable<any[]> {
    //Return the items collection of the parent node as children.
    return of(node.value);
  }

  public hasChildren(node: any): boolean {
    //Check if the parent node has children.
    return Boolean(node.value);
  }

  public hasChildrenGroup(node: any): boolean {
    //Check if the parent node has children.
    return (
      Boolean(node.value) &&
      !(Object.keys(node).includes('isGroup') && node.isGroup)
    );
  }

  public toggleEditDate() {
    this.isEditDate = !this.isEditDate;
    this.contingentDate = this.currentContingentDate;
    this.raitingDate = this.currentRaitingDate;
  }

  public acceptDate(editMode: boolean) {
    //TODO: add service get Tree
    this.currentContingentDate = this.contingentDate;
    this.currentRaitingDate = this.raitingDate;

    this.onChangeContingentDate.emit(this.currentContingentDate)

    if(+this.contingentDate !== +this.currentDate)
    {
      const storage =  this.getLocalStorage()
      if (storage && storage.event)
        this.onSelectionTreeChange(storage.event, true)
    }

    if(editMode)
    {
      const storage = this.getLocalStorage()
      if (storage !== null){
        let currentControlSaveSettings: any = {
          event: storage.event,
          discipline:storage.discipline,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: storage.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          disciplineId: storage.disciplineId,
          electiveDisciplineId: storage.electiveDisciplineId,
          week: storage.week,
          contingentDate: this.currentContingentDate,
        }
        localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));
      }
      this.changeTreeData(this.selectedTrainingLevel && this.selectedTrainingLevel.length != 0  ? this.selectedTrainingLevel: null, this.selectedSemester.semesterInYear!,
        this.selectedSemester.semesterYear!, this.contingentDate);
      this.toggleEditDate();
    }
  }

  public onNodeClick(event: NodeClickEvent): void {
    if (event.item) {
      const index = this.expandedKeys.indexOf(event.item.index);
      if (index > -1) this.expandedKeys.splice(index, 1);
      else this.expandedKeys.push(event.item.index);

      const storage = this.getLocalStorage()
      if (storage !== null){
        let currentControlSaveSettings: any = {
          event: storage.event,
          discipline:storage.discipline,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: this.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          disciplineId: storage.disciplineId,
          electiveDisciplineId: storage.electiveDisciplineId,
          week: storage.week,
          contingentDate: storage.contingentDate,
          changeGroup: this.changeGroup
        }
        localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));
      }
    }
  }


  public getLocalStorage(){
    let storage = localStorage.getItem('current_control_settings');
    if (storage !== null) return JSON.parse(storage)
    else return null
  }


  public isExpanded = (dataItem: any, index: string) => {
    const storage = this.getLocalStorage();
    if (storage !== null){
      let text = storage.changeGroup;
      let all = document.querySelectorAll("span");
      let arraySelector = Array.from(all);
      let span = arraySelector.findIndex(el => el.innerText == text);
      if (span > -1) all[span].classList.add('k-selected');
    }
    return this.expandedKeys.indexOf(index) > -1;
  };

  private closeExpandedTree() {
    this.changeGroup = ''
    this.expandedKeys = [];
    const storage = this.getLocalStorage()
    if (storage !== null){
      let currentControlSaveSettings: any = {
        event: undefined,
        discipline: storage.discipline,
        selectedSemester: this.selectedSemester,
        checkable: storage.checkable,
        checkedItems: storage.checkedItems,
        expandedKeys: this.expandedKeys,
        selectedTrainingLevel: this.selectedTrainingLevel,
        disciplineId: storage.disciplineId,
        electiveDisciplineId: storage.electiveDisciplineId,
        week: storage.week,
        contingentDate: storage.contingentDate,
        changeGroup: this.changeGroup
      }
      localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));
    }

    this.changeTreeData(this.selectedTrainingLevel && this.selectedTrainingLevel.length != 0 ? this.selectedTrainingLevel: null, this.selectedSemester.semesterInYear!,
      this.selectedSemester.semesterYear!, this.contingentDate).then(() => {
      this.expandedKeys = [];
    });
  }

  public isCheckedMultiSelectTrainingLevel = true;

  public isIndet() {
    if(this.selectedTrainingLevel)
      return (
        this.selectedTrainingLevel.length !== 0 && this.selectedTrainingLevel.length !== this.traininglevels.length
      );
    return false
  }

  public onClick() {
      this.isCheckedMultiSelectTrainingLevel = !this.isCheckedMultiSelectTrainingLevel;
      this.selectedTrainingLevel = this.isCheckedMultiSelectTrainingLevel ? Array.from(this.traininglevels.slice().reverse(), x => x.id!) : undefined;
  }

  public isItemSelected(item: TrainingLevelsModel) {
    if(this.selectedTrainingLevel)
      return this.selectedTrainingLevel.some((x) => x === item.id);
    return false
  }
}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {DictSportCategory} from "../../models/contingent/dictsportcategory.model";
import {catchError} from "rxjs/operators";
import {DictMaritalStatus} from "../../models/contingent/maritalstatus.model";

@Injectable({
  providedIn: 'root'
})
export class DictmaritalstatusService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictmaritalstatus}`;

  constructor(private http: HttpClient) { }

  //Get all maritalStatusId
  public getAllMaritalStatus(): Observable<DictMaritalStatus[]> {
    return this.http.get<DictMaritalStatus[]>(this.baseUrl + '/GetDictMaritalStatus');
  }

  //Add MaritalStatus
  addMaritalStatus(dto: DictMaritalStatus): Observable<DictMaritalStatus> {
    return this.http.post<DictMaritalStatus>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete MaritalStatus
  deleteMaritalStatus(id: string): Observable<DictMaritalStatus> {
    return this.http.delete<DictMaritalStatus>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit MaritalStatus
  updateMaritalStatus(dto: DictMaritalStatus): Observable<DictMaritalStatus> {
    return this.http.put<DictMaritalStatus>(this.baseUrl + '/' + dto.maritalStatusId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

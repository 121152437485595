import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKPersonEmail } from 'src/app/models/person/lkPersonEmail.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKPersonEmailService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.lkPersonEmail}`;

  constructor(private http: HttpClient) { }

  //Get Emails
  public getEmailsByPersonId(personId: string): Observable<LKPersonEmail[]> {
    return this.http.get<LKPersonEmail[]>(this.baseUrl + '/GetEmailsByPersonId/' + personId)
      .pipe(
        catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {Component, ViewChild} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {DepartmentLitera} from "../../../../models/mfc/dicts/department-litera.model";
import {DepartmentLiteraService} from "../../../../services/mfc/dicts/department-litera.service";
import {TrainingLevelService} from "../../../../services/mfc/dicts/training-level.service";
import {TrainingLevel} from "../../../../models/mfc/dicts/training-level.model";
import {FacultyService} from "../../../../services/mfc/dicts/faculty.service";
import {AddEvent, CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {FilialTreeItem} from "../../../../models/mfc/dicts/filial.model";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../helpers/dialogHelper";
import {Faculty} from "../../../../models/mfc/dicts/faculty.model";
import {arrayRewrite} from "../../../../helpers/multiselect-helper";
import {DropDownListComponent} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'mfc-dict-type-receipt',
  templateUrl: './department-litera.component.html',
  styleUrls: ['./department-litera.component.scss']
})
export class DepartmentLiteraComponent {

  @ViewChild(GridComponent) private grid!: GridComponent;
  @ViewChild('facutlyDropdown') private facultyDropdown!: DropDownListComponent;

  protected departmentLitera: DepartmentLitera[] = [];
  protected dictTrainingLevels: TrainingLevel[] = [];
  protected dictFaculties: Faculty[] = [];
  protected dictFilials: FilialTreeItem[] = [];

  protected filteredFaculties: Faculty[] = [];

  protected isEditMode = false;
  protected editable = true;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  protected readonly pagerSettings = pagerSettings;
  protected form = new FormGroup({
    filial: new FormControl('', {nonNullable: true, validators: [Validators.required]}),
    id: new FormControl(),
    facultyId: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    trainingLevels: new FormControl(),
    name: new FormControl<string>('', {nonNullable: true, validators: [Validators.required]}),
    isAllTrainingLevelsAvailable: new FormControl()
  });

  constructor(
    private departmentLiteraService: DepartmentLiteraService,
    private trainingLevelService: TrainingLevelService,
    private facultyService: FacultyService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService
  ) {
    this.getFilialsAndFaculties();
    this.getTrainingLevels();
    this.get();
  }

  protected patchFormGroup(dataItem?: DepartmentLitera) {
    this.form.patchValue({
      filial: dataItem?.filial.id ?? '',
      id: dataItem?.id,
      facultyId: dataItem?.faculty.id ?? '',
      trainingLevels: dataItem?.trainingLevels.length ? dataItem?.trainingLevels.flatMap(a => a.id) : [''],
      name: dataItem?.name ?? '',
      isAllTrainingLevelsAvailable: !dataItem?.trainingLevels.length
    });
  }

  protected get() {
    this.departmentLiteraService.getAll().subscribe(data => this.departmentLitera = data);
  }

  private getFilialsAndFaculties() {
    this.facultyService.getFilialsAndFaculties().subscribe(data => {
      this.dictFilials = data;
      this.dictFaculties = data.flatMap(a => a.faculties);
      this.filteredFaculties = [...this.dictFaculties];
    });
  }

  private getTrainingLevels() {
    this.trainingLevelService.getTrainingLevels().subscribe(data => {
      this.dictTrainingLevels = [{id: '', name: 'Все', shortName: 'Все'}, ...data];
    });
  }

  protected saveCurrent() {
    if (this.trainingLevelsControl?.value.includes('')) {
      this.trainingLevelsControl?.patchValue([]);
    }

    const observable = this.departmentLiteraService
      [this.isNew ? 'addDepartmentLitera' : 'updateDepartmentLitera'](this.form.getRawValue());

    observable.subscribe(() => {
      this.notificationsService.showSuccess('Успешно');
      this.closeEditor();
      this.get();
    });
  }

  protected addHandler({sender}: AddEvent): void {
    this.patchFormGroup();
    this.form.controls.facultyId.enable();
    this.form.controls.filial.enable();
    this.isNew = true;
    this.isEditMode = true;
    sender.addRow(this.form);
  }

  protected editHandler({sender, rowIndex, dataItem, columnIndex}: CellClickEvent): void {
    if (this.isNew || !this.editable || this.isLine) {
      return;
    }

    this.patchFormGroup(dataItem);

    if (this.form.invalid) {
      return;
    }

    this.form.controls.facultyId.disable();
    this.form.controls.filial.disable();

    this.isLine = true;
    this.editedRowIndex = rowIndex;
    this.isEditMode = true;
    sender.editRow(rowIndex, this.form, {columnIndex});
  }

  protected removeHandler({ dataItem }: RemoveEvent) {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}?"`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.departmentLiteraService.removeDepartmentLitera(dataItem.id)
          .subscribe({
            next: () => {
              this.get();
              this.closeEditor();
              this.notificationsService.showSuccess('Успешно');
            }
          })
      }
    });
  }

  private get trainingLevelsControl() {
    return this.form.controls.trainingLevels;
  }

  protected onFilialChange(id: string) {
    this.filteredFaculties = this.dictFilials
      .filter(filial => filial.id === id)
      .flatMap(item => item.faculties);

    const isValidFaculty = this.filteredFaculties.some(a => a.id === this.form.controls.facultyId.value)
    if (!isValidFaculty) {
      this.form.controls.facultyId.patchValue('');
      this.facultyDropdown.reset();
    }
  }

  protected onTrainingLevelsChange(values: string[]) {
    const isAllControl = this.form.controls.isAllTrainingLevelsAvailable;
    const filteredValues = arrayRewrite(values);
    this.trainingLevelsControl?.patchValue(filteredValues);
    isAllControl?.patchValue(filteredValues.includes(''));
  }

  protected closeEditor() {
    this.isNew = false;
    this.isEditMode = false;
    this.isLine = false;
    this.form.reset();
    this.grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
  }
}

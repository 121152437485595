<h1>Настройки доступа</h1>
<education-header></education-header>

<span class="filter">
    <h3>Поиск по ФИО</h3>
    <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
    <button kendoButton (click)="clearData()">Очистить</button>
    <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">Применить</button>
</span>

<kendo-grid class="technical"
            [loading]="loading"
            [sortable]="true"
            [sort]="sort"
            [kendoGridBinding]="gridData"
            (remove)="removeHandler($event)"
            (edit)="editHandler($event)"
            (add)="addHandler($event)">
    <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
        <div *ngIf="isInEditingMode">
          <button kendoButton (click)="cancelHandler()">Отмена</button>
          <button kendoButton themeColor="primary" [disabled]="formGroup!.invalid"  (click)="saveCurrent()">Сохранить</button>
        </div>
      </ng-template>
      <kendo-grid-column [sticky]="true" title="" [width]="35">
        <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full">
          </button>
        </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sticky]="true" title="" [width]="35">
        <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
                  rounded="full">
          </button>
        </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sticky]="true" [width]="250" field="personName" headerClass="gridHeader dictionaries" title="ФИО">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [kendoDropDownFilter]="filterSettings"
        [data]="persons"
        textField="fio"
        valueField="id"
        [valuePrimitive]="true"
        [virtual]="virtual"
        [formControl]="formGroup.get('personId')">
      </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.personName }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="educationPlanStatuses" headerClass="gridHeader dictionaries" title="Статус УП">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.educationPlanStatuses"
        class="educationPlanStatuses"
        [kendoDropDownFilter]="filterSettings"
        textField="educationPlanStatusName"
        [(ngModel)]="educationPlanStatusesEdit"
        valueField="educationPlanStatusId"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('educationPlanStatuses')"
        (valueChange)="valueChange($event, 'educationPlanStatusesEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'educationPlanStatuses')">
              {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.educationPlanStatuses, 'educationPlanStatusName', 'plan') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="filials" headerClass="gridHeader dictionaries" title="Филиал">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.filials"
        class="filials"
        [kendoDropDownFilter]="filterSettings"
        textField="filialShortName"
        [(ngModel)]="filialsEdit"
        valueField="filialId"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('filials')"
        (valueChange)="valueChange($event, 'filialsEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'filials')">
              {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.filials, 'filialShortName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="trainingLevels" headerClass="gridHeader dictionaries" title="Уровень подготовки">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="filteredData.trainingLevels"
        class="trainingLevels"
        textField="trainingLevelName"
        [(ngModel)]="trainingLevelsEdit"
        valueField="trainingLevelExternalId"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('trainingLevels')"
        (valueChange)="valueChange($event, 'trainingLevelsEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'trainingLevels')">
              {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.trainingLevels, 'trainingLevelName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="400" field="kafedras" headerClass="gridHeader dictionaries" title="Выпускающая кафедра">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.kafedras"
        class="kafedras"
        [(ngModel)]="kafedrasEdit"
        [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="true"
        textField="kafedraName"
        valueField="kafedraId"
        [formControl]="formGroup.get('kafedras')"
        (valueChange)="valueChange($event, 'kafedrasEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'kafedras')">
              {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.kafedras, 'kafedraName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="faculties" headerClass="gridHeader dictionaries" title="Институт/факультет">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.faculties"
        class="faculties"
        [(ngModel)]="facultiesEdit"
        [kendoDropDownFilter]="filterSettings"
        textField="facultyShortName"
        [valuePrimitive]="true"
        valueField="facultyId"
        [formControl]="formGroup.get('faculties')"
        (valueChange)="valueChange($event, 'facultiesEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'faculties')">{{ dataItems.length }} выбрано</span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.faculties, 'facultyShortName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="accessLevel" headerClass="gridHeader dictionaries" title="Уровень доступа">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        [kendoDropDownFilter]="filterSettings"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevel')"></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.accessLevelName }}
          </span>
      </ng-template>
    </kendo-grid-column>
  <kendo-grid-column [width]="140" field="dictAccessLevel" headerClass="gridHeader dictionaries"
                     title="Редактирование настроек и справочников">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        [kendoDropDownFilter]="filterSettings"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('dictAccessLevel')"></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.dictAccessLevel ? 'Да' : 'Нет' }}
          </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" field="electiveAccessLevel" headerClass="gridHeader dictionaries"
                     title="Редактирование факультативов в утвержденных УП">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        [kendoDropDownFilter]="filterSettings"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('electiveAccessLevel')"></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.electiveAccessLevel ? 'Да' : 'Нет' }}
          </span>
    </ng-template>
  </kendo-grid-column>
    <kendo-grid-column [width]="140" field="userAccessLevel" headerClass="gridHeader dictionaries" title="Настройки доступа"
                       *ngIf="isAdmin">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="boolOptions"
        [kendoDropDownFilter]="filterSettings"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('userAccessLevel')"></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.userAccessLevel ? 'Да' : 'Нет' }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="localSignatoryAccessLevel" headerClass="gridHeader dictionaries" title="Настройки локального списка подписантов"
                       *ngIf="isAdmin">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          [data]="boolOptions"
          [kendoDropDownFilter]="filterSettings"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('localSignatoryAccessLevel')"></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
              {{ dataItem.localSignatoryAccessLevel ? 'Да' : 'Нет' }}
            </span>
      </ng-template>
    </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

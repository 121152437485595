<contingent-header></contingent-header>
<h1>Категории достижений</h1>

<div class="selectContent" *ngIf="!opened">
  <dd style="display: flex; align-items: center">
    <span class="selectText">
      Филиал
    </span>
    <kendo-dropdownlist [data]="filials"
                        textField="filialSName"
                        valueField="dictFilialExternalId"
                        [valuePrimitive]="true"
                        name="filial"
                        (valueChange)="applyFilter()"
                        [(ngModel)]="secondFilter.dictFilialExternalId"
                        [defaultItem]="defaultItemFilial"
                        style="width: 400px;">
    </kendo-dropdownlist>
  </dd>
</div>

<div class="grid-container">

    <!--Grid-->
    <kendo-grid [kendoGridBinding]="categoryAchievementsByFilter" (remove)="removeHandler($event)"
                (cellClick)="editHandler($event)"
                [pageable]="true"  [pageSize]="pageSize"
                (add)="addHandler($event)"
                [sortable]="true"
                >
  
      <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
        <button kendoGridAddCommand size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
        <div *ngIf="isInEditingMode">
          <button kendoButton
                  (click)="cancelHandler()"
                  size="small">
            Отмена
          </button>
          <button kendoButton
                  [disabled]="formGroup!.invalid"
                  themeColor="primary"
                  (click)="saveCurrent()"
                  size="small">
            Сохранить
          </button>
        </div>
      </ng-template>
  
      <kendo-grid-column field="name"
                         title="Название категории"
                         headerClass="gridHeader"
                         [width]="500">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-textbox
            size="small"
            [formControl]="formGroup.get('name')"
            [clearButton]="true"
          ></kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.name}}
                        </span>
        </ng-template>
      </kendo-grid-column>
  
      <kendo-grid-column field="order"
                         title="Порядковый номер для сортировки при отображении"
                         headerClass="gridHeader"
                         [width]="200">
        <ng-template kendoGridEditTemplate
                     let-dataItem="dataItem"
                     let-formGroup="formGroup">
                             <span class="alignCells">
                                 <kendo-numerictextbox
                                   [style.width.px]="170"
                                   [value]="dataItem.order"
                                   [min]="1"
                                   format="n0"
                                   [decimals]="0"
                                   [formControl]="formGroup.get('order')">
                                 </kendo-numerictextbox>
                             </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.order}}
              </span>
        </ng-template>
      </kendo-grid-column>
  
      <kendo-grid-column title="" [width]="70" *ngIf="editable">
        <ng-template kendoGridCellTemplate>
          <span class="alignCells">
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="small" rounded="full">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>
  
      <ng-template kendoPagerTemplate>
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="5"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-info></kendo-pager-info>
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>

</div>
<div kendoDialogContainer></div>
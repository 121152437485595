import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Keys} from '@progress/kendo-angular-common';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {
  CellClickEvent,
  CellCloseEvent,
  GridComponent,
  GridDataResult,
  PagerPosition, RowClassArgs
} from '@progress/kendo-angular-grid';
import {PagerType} from '@progress/kendo-angular-pager';
import {GroupDescriptor} from '@progress/kendo-data-query';
import {Observable} from 'rxjs';
import {detectTypeForRewrite} from 'src/app/helpers/multiselect-helper';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {
  DisciplineWorkloadContingentService
} from "../../../services/disciplineworkload/discipline-workload-contingent.service";
import {YearAndSemestr} from "../../../models/disciplineworkload/yearAndSemestrs.model";
import {
  ContingentLoadAddDto,
  ContingentLoadData, ContingentLoadUpdateDto,
  ContingentLoadUpdateRange
} from "../../../models/disciplineworkload/disciplineworkloadContingent.model";
import {DictService} from "../../../services/disciplineworkload/externals/dict.service";
import {DictStudyForm, DictTrainingLevel, Faculty} from "../../../models/disciplineworkload/externals.model";
import {DepartmentService} from "../../../services/disciplineworkload/externals/department.service";
import {Router} from "@angular/router";
import { ContingentLoadQuery } from 'src/app/models/disciplineworkload/query/contingentLoadQuery.model';
import { CreateQuery } from 'src/app/helpers/createQuery-helper';
import {DisciplineWorkloadUserAccessService} from "../../../services/useraccess/disciplineworkload-user-access.service";
import {LoaderType} from "@progress/kendo-angular-indicators";
import { getParentElement, isChildOf } from 'src/app/helpers/elementRef-helper';
import { TokenStorageService } from '../../../services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'discipline-workload-contingent',
  templateUrl: './discipline-workload-contingent.component.html',
  styleUrls: ['./discipline-workload-contingent.component.scss']
})

// Контингент для расчета нагрузки
export class DisciplineWorkloadContingentComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  public studyForms: DictStudyForm[] = [];
  public faculties: Faculty[] = [];
  public trainingLevels: DictTrainingLevel[] = [];
  public yearAndSemestrs: YearAndSemestr[] = [];
  public contingentLoadData: ContingentLoadData[] = [];
  public contingentLoadUpdateRange: ContingentLoadUpdateRange = {semestr: 0, year: 0};
  public contingentLoadAddDto: ContingentLoadAddDto = {eduGroupId: "", semestr: 0, year: 0};
  public contingentLoadUpdateDto: ContingentLoadUpdateDto = {contingentLoadId: ""};
  public editingRights: boolean = false;
  public gridData: any[] = [];

  public loaderType: LoaderType = 'converging-spinner';
  public type: PagerType = 'numeric';
  public buttonCount: number = 5;
  public info: boolean = true;
  public pageSizes: number[] = [10, 30, 50, 100, 200];
  public previousNext: boolean = true;
  public position: PagerPosition = 'bottom';

  public trainingLevelsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public studyFormEdit: any[] = [];
  public studyYearsEdit: any;

  // Filters
  public filterStudyForms: any[] = [];
  public filterTrainingLevels: any[] = [];

  public loading: boolean = false;
  public updateLoading: boolean = false;

  public view!: Observable<GridDataResult>;
  public formGroup!: FormGroup | undefined;
  public groups: GroupDescriptor[] = [{ field: "educationProgram.name" }];
  public updateContingentLoadState: {
    update: boolean,
    onlyStudents: boolean
  } = {
    update: false,
    onlyStudents: false
  };
  private contingentLoadQuery!: ContingentLoadQuery;
  private editedRowIndex!: number | undefined;

  constructor(
    private departmentService: DepartmentService,
    private dictService: DictService,
    private renderer: Renderer2,
    private router: Router,
    private notificationService: NotificationsService,
    private contingentLoadService: DisciplineWorkloadContingentService,
    private userAccess: DisciplineWorkloadUserAccessService,
    private tokenService: TokenStorageService,
    private jwt: JwtHelperService) {
    }

  async ngOnInit() {
    this.getEditingRights();

    let needSetEditModel = !this.getFilterOptions();

    await this.getAllYearAndSemestrs(needSetEditModel);
    await this.getFaculties(needSetEditModel);
    await this.getStudyForms(needSetEditModel);
    await this.getTrainingLevels(needSetEditModel);

    this.getContingentLoad(this.studyYearsEdit, this.facultiesEdit, this.trainingLevelsEdit, this.studyFormEdit);

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveRow();
      }
      if (isChildOf(target, "k-grouping-row") && isChildOf(target, "workload-contingent")) {
        let htmlElement = getParentElement(target, "k-grouping-row");

        if (htmlElement) {
          let child =
            htmlElement.getElementsByClassName('k-i-collapse')[0] ??
            htmlElement.getElementsByClassName('k-i-expand')[0]

          if (!child) return;

          (child as HTMLElement).click();
        }
      }
    });
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public addHandler(): void {
    this.closeEditor();

    this.formGroup = createFormGroup({
      id: 0,
      eduGroup: '',
    });

    this.grid.addRow(this.formGroup);
  }

  // Событие на сохранение данных
  public saveRow(): void {
    // Formgroup is valid
    if (this.formGroup && this.formGroup.valid) {
      // ContingentLoadId is not null
      if(this.formGroup.value.eduGroup.contingentLoadId) {
        this.contingentLoadUpdateDto.contingentLoadId = this.formGroup.value.eduGroup.contingentLoadId;
        this.contingentLoadUpdateDto.totalStudentsLoad = this.formGroup.value.eduGroup?.expectedTotalStudents;
        this.contingentLoadUpdateDto.totalSubgroupsLoad = this.formGroup.value.eduGroup?.expectedTotalStudentSubgroups;

        this.contingentLoadService.updateContingentLoad(this.contingentLoadUpdateDto).subscribe({
          next: () => {
            this.contingentLoadUpdateDto = {contingentLoadId: ""}
            this.notificationService.showSuccess('Изменено');
            this.closeEditor();
            this.getContingentLoad(this.studyYearsEdit, this.facultiesEdit, this.trainingLevelsEdit, this.studyFormEdit);
          },
          error: () => {
            this.notificationService.showError('Введенные значения ниже нуля');
            this.closeEditor();
            this.getContingentLoad(this.studyYearsEdit, this.facultiesEdit, this.trainingLevelsEdit, this.studyFormEdit);
            this.contingentLoadUpdateDto = {contingentLoadId: ""}
          }
        });
      }
      // Добавляем новые данные если ContingentLoadId - пустой
      else {
        this.contingentLoadAddDto.totalStudentsLoad = this.formGroup.value.eduGroup?.expectedTotalStudents;
        this.contingentLoadAddDto.eduGroupId = this.formGroup.value.eduGroup?.id;
        this.contingentLoadAddDto.year = this.studyYearsEdit.year;
        this.contingentLoadAddDto.semestr = this.studyYearsEdit.semestr;
        this.contingentLoadAddDto.totalSubgroupsLoad = this.formGroup.value.eduGroup?.expectedTotalStudentSubgroups;

        this.contingentLoadService.addContingentLoad(this.contingentLoadAddDto).subscribe({
          next: () => {
            this.notificationService.showSuccess('Добавлено');
            this.closeEditor();
            this.contingentLoadAddDto = {eduGroupId: "", semestr: 0, year: 0};
            this.getContingentLoad(this.studyYearsEdit, this.facultiesEdit, this.trainingLevelsEdit, this.studyFormEdit);
          },
          error: (err) => {
            this.notificationService.showError(err);
            this.closeEditor();
            this.contingentLoadAddDto = {eduGroupId: "", semestr: 0, year: 0};
          }

        });
      }
      this.closeEditor();
    }
  }

  // Событие на отмену
  public cancelHandler(): void {
    this.closeEditor();
  }

  // Событие на клик по строке в таблице
  public cellClickHandler(e: CellClickEvent): void {
    if (e.isEdited || (this.formGroup && !this.formGroup.valid) || e.column.title === "Учебный план группы") {
      return;
    }
    if (!this.editingRights) {
      return;
    }
    // сохраним данные, если кликнули на другую строку
    if(this.editedRowIndex != undefined) {
      if (e.rowIndex !== this.editedRowIndex) {
        this.saveRow();
        return;
      }
    }
    this.saveRow();
    this.formGroup = createFormGroup(e.dataItem);
    this.editedRowIndex = e.rowIndex;
    this.grid.editRow(e.rowIndex, this.formGroup);
  }

  // Событие на закрытие режима редактирования строки
  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }
    }
    this.closeEditor();
  }

  // Закрыть режим редактирования
  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // Получение списка учебных годов
  private async getAllYearAndSemestrs(setEditModel: boolean = true) {
    await this.contingentLoadService.getAllYearAndSemestrs().toPromise().then(
      (response) => {
        if (response == null) return;

        this.yearAndSemestrs = response.yearAndSemestr;
        if (setEditModel) this.studyYearsEdit = response.yearAndSemestr[0];
      }
    )
  }

  // Получение списка факультетов
  private async getFaculties(setEditModel: boolean = true) {
    await this.departmentService.getFaculties().toPromise().then(
      (response) => {
        this.faculties = response as Faculty[];
        if (this.faculties && this.faculties.length>1) {
          let item = new Faculty();
          item.id = "";
          item.sName = 'Все';

          this.faculties.unshift(item);
          if (setEditModel) this.facultiesEdit = [ this.faculties[0].id ];
        }
    })
  }

  // Получение списка форм обучения
  private async getStudyForms(setEditModel: boolean = true) {
    await this.dictService.getStudyForms().toPromise().then(
      (response) => {
        if(response == undefined) return;

        this.studyForms = response;
        this.filterStudyForms = response.filter((item) => item.studyFormName !== 'Все');
        if (this.studyForms) {
          let item = new DictStudyForm();
          item.id = "";
          item.studyFormName = 'Все';

          this.studyForms.unshift(item);
          if (setEditModel) this.studyFormEdit = [ this.studyForms[0].id ];
        }
    })
  }

  // Получение списка уровней подготовки
  private async getTrainingLevels(setEditModel: boolean = true) {
    await this.dictService.getTrainingLevels().toPromise().then(
      (response) => {
        if(response == undefined) return

        this.trainingLevels = response;
        this.filterTrainingLevels = response.filter((item) => item.name !== 'Все');
        if (this.trainingLevels) {
          let item = new DictTrainingLevel();
          item.id = "";
          item.name = 'Все';

          this.trainingLevels.unshift(item);
          if (setEditModel) this.trainingLevelsEdit = [ this.trainingLevels[0].id ];
        }
    })
  }

  // Событие на заполнение обучающихся в нагрузку из контингента
  public updateRangeContingentLoad() {
    this.updateLoading = true;

    this.contingentLoadUpdateRange.semestr = this.studyYearsEdit.semestr;
    this.contingentLoadUpdateRange.year = this.studyYearsEdit.year;
    // Check faculties for null value
    if(this.facultiesEdit.length > 0 && !this.facultiesEdit.includes(''))
      this.contingentLoadUpdateRange.faculties = this.facultiesEdit;
    // Check traininglevels for null value
    if(this.trainingLevelsEdit.length > 0 && !this.trainingLevelsEdit.includes(''))
      this.contingentLoadUpdateRange.studyLevels = this.trainingLevelsEdit;
    // Check studyForm for null value
    if(this.studyFormEdit.length > 0 && !this.studyFormEdit.includes(''))
      this.contingentLoadUpdateRange.studyForms = this.studyFormEdit;
    this.contingentLoadUpdateRange.updateOnlyStudents = this.updateContingentLoadState.onlyStudents;

    this.contingentLoadService.updateRangeContingentLoad(this.contingentLoadUpdateRange).subscribe({
      next: () => {
        this.getContingentLoad(this.studyYearsEdit, this.facultiesEdit, this.trainingLevelsEdit, this.studyFormEdit);
        this.updateLoading = false;
        this.notificationService.showSuccess('Успешно');
        this.contingentLoadUpdateRange = {semestr: 0, year: 0};
        this.updateContingentLoadClose();
      },
      error: (err) => {
        this.notificationService.showError(`${err}`);
        this.contingentLoadUpdateRange = {semestr: 0, year: 0};
        this.updateContingentLoadClose();
        console.log(err);
      }
    })
  }

  // Получение списка данных контингента в нагрузку
  private getContingentLoad(yearAndSemestr: any, faculties?: any, studyLevels?: any, studyForms?: any) {
    this.loading = true;
    this.contingentLoadQuery = {
      year: yearAndSemestr.year,
      semestr: yearAndSemestr.semestr,
      faculties: faculties.length == 0 || faculties.includes("") ? null : faculties,
      studyLevels: studyLevels.length == 0 || studyLevels.includes("") ? null : studyLevels,
      studyForms: studyForms.length == 0 || studyForms.includes("") ? null : studyForms
    }
    const query = CreateQuery(this.contingentLoadQuery);
    // Subscribe to service
    this.contingentLoadService.getContingentLoad(query).subscribe({
      next: (response) => {
        this.loading = false;
        this.contingentLoadData = response.contingentLoadData;
        this.gridData = this.contingentLoadData;
      },
      error: () => {
        this.notificationService.showError('Произошла ошибка');
        this.loading = false;
      }
    })
  }

  // Событие на изменение значения в выпадающем списке
  public async valueChange(value: any, editItemName: any) {
    (this as any)[`${editItemName}`] = detectTypeForRewrite((this as any)[`${editItemName}`], value);

    await this.filterGrid()
  }

  public updateContingentLoad(updateOnlyStudents: boolean = false): void {
    this.updateContingentLoadState = {
      update: true,
      onlyStudents: updateOnlyStudents
    };
  }

  public updateContingentLoadClose(): void {
    this.updateContingentLoadState = {
      update: false,
      onlyStudents: false
    };
  }

  // Navigate to education plan page
  public hyperlinkClick(dataItem: any) {
    if (!dataItem?.educationProgram?.id) return;

    let planRoute = `/education/${dataItem.educationProgram.id}/plans`;

    if(dataItem.educationPlan?.id)
      planRoute += '/plan/' + dataItem.educationPlan.id;

    window.open(planRoute, '_blank')
  }

  // Change style if row archived
  public isRowArchived = (context: RowClassArgs) => {
    if(context.dataItem.eduGroup.isArchived === true) {
      return {archived: true};
    }
    return {}
  };

  private getEditingRights() {
    const token = this.jwt.decodeToken(this.tokenService.getToken().accessToken);

    this.userAccess.getAccess().subscribe({
      next: (response) => {
        let accesses = response.userAccesses.filter((item: { personId: any; }) => item.personId === token.person_id);

        const contingentLoadAccessLevel = accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.contingentLoadAccess))
          : 0;

        this.editingRights = contingentLoadAccessLevel > 1;
      }
    });
    /*this.userAccess.getEditingRightInContingentLoad({
      facultyId:
    }).subscribe({
      next: (response) => {
        this.editingRights = response.isApproved;
      }
    })*/
  }

  public filterGrid() {
    this.getContingentLoad(this.studyYearsEdit, this.facultiesEdit, this.trainingLevelsEdit, this.studyFormEdit);

    this.setFilterOptions();
  }

  private storage_filter_key: string = 'discipline_workload_contingent-filter';

  private setFilterOptions() {
    localStorage.removeItem(this.storage_filter_key);
    localStorage.setItem(this.storage_filter_key, JSON.stringify({
      studyYearsEdit: this.studyYearsEdit,
      trainingLevelsEdit: this.trainingLevelsEdit,
      studyFormEdit: this.studyFormEdit,
      facultiesEdit: this.facultiesEdit
    }));
  }

  private getFilterOptions(): boolean {
    const json = localStorage.getItem(this.storage_filter_key);

    if (json != null) {
      try {
        const filters = JSON.parse(json);

        this.studyYearsEdit = filters.studyYearsEdit;
        this.trainingLevelsEdit = filters.trainingLevelsEdit;
        this.studyFormEdit = filters.studyFormEdit;
        this.facultiesEdit = filters.facultiesEdit;

        return true;
      }
      catch (e) {
        localStorage.removeItem(this.storage_filter_key);

        return false;
      }
    }

    return false;
  }
}

const createFormGroup = (dataItem: {
  id: any;
  eduGroup: any;
}) => new FormGroup({
  id: new FormControl(dataItem.id),
  eduGroup: new FormControl(dataItem.eduGroup, Validators.required),
});

<middle-control-menu-access></middle-control-menu-access>
<h1>Общая ведомость по дисциплине</h1>

<div class="selectContent" *ngIf="!opened">
  <dd class="dd-flex">
    <span class="selectText">
      Филиал
    </span>
    <kendo-dropdownlist [data]="filials"
                        textField="filialSName"
                        valueField="id"
                        [valuePrimitive]="true"
                        name="filial"
                        (valueChange)="onValueChange($event)"
                        [(ngModel)]="filialId"
                        [defaultItem]="defaultItemFilial"
                        class="filial-width">
    </kendo-dropdownlist>
  </dd>
</div>

<div class="grid-container">
  <kendo-grid [kendoGridBinding]="filteredGeneralSheets"
              [pageable]="true"  [pageSize]="pageSize"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success"
              *ngIf="!isInEditingMode"
              [disabled]="filialId == null"
              icon="plus">
        Добавить
      </button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column field="trainingLevelName"
                       title="Уровень подготовки"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ nameTrainingLevel(dataItem.trainingLevelId) }}
            </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-combobox [data]="trainingLevels"
                        textField="name"
                        valueField="id"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('trainingLevelId')">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studyFormName"
                       title="Форма обучения"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ nameStudyForm(dataItem.studyFormId) }}
            </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-combobox [data]="studyForms"
                        textField="name"
                        valueField="id"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('studyFormId')">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="semesterSort"
                       title="Семестр"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.semesterInYear + " семестр " + dataItem.semesterYear +"-" + (dataItem.semesterYear + 1) }}
            </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-combobox
          [data]="educationSlices"
          textField="semesterNumberString"
          valueField="semesterNumberString"
          class="params-list__field"
          [clearButton]=false
          [value]="selectedSemester"
          (valueChange)="onChangeEducationSlices($event)">
          <ng-template kendoDropDownListItemTemplate let-dataItem>
          <span>{{
            dataItem.semesterInYear + " семестр " + dataItem.semesterYearString
            }}</span>
          </ng-template>
          <ng-template kendoDropDownListNoDataTemplate>
            <h3>Нет данных</h3>
          </ng-template>
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="courseNum"
                       title="Номер курса"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.courseNum + " курс" }}
            </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-combobox [data]="courses"
                        textField="course"
                        valueField="courseNum"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('courseNum')">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [width]="60"
      headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="alignCells leftAlignment" *ngIf="editedRowIndex !== rowIndex" >
                          <button kendoButton themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
                                  rounded="full" (click)="removeHandler(dataItem)">
                          </button>
                        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>
</div>

<div kendoDialogContainer></div>

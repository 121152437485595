import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ChangeEvent, ColumnsInfo} from '../../../../../models/mfc/constructor-listbox.model';
import {FieldType} from '../../../../../models/mfc/enums/constructor-listbox.enum';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {CellClickEvent} from '@progress/kendo-angular-grid';
import {arrayRewrite} from "../../../../../helpers/multiselect-helper";


@Component({
  selector: 'mfc-constructor-listbox',
  templateUrl: './constructor-listbox.component.html',
  styleUrls: ['./constructor-listbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConstructorListBoxComponent<Hidden, Visible> implements OnInit, OnChanges {

  @Input() hiddenData: Hidden[] = [];
  @Input() visibleData: Visible[] = [];
  @Input() info!: ColumnsInfo<Hidden, Visible>;
  @Input() visibleToHidden?: (dataItem: Visible) => Hidden;
  @Input() hiddenToVisible?: (dataItem: Hidden) => Visible;

  @Input() gridMaxWidth?: number;
  @Input() movable = true;
  @Input() editable = false;

  @Output() value = new EventEmitter<ChangeEvent<Visible>>();

  protected hiddenColumns: Hidden[] = [];
  protected visibleColumns: Visible[] = [];

  protected selectedRow?: {rowIndex: number, visible: boolean};

  protected filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  protected readonly FieldType = FieldType;

  constructor(
  ) { }

  ngOnInit(): void {
    this.listboxReset();
  }

  private listboxReset() {
    this.hiddenColumns = JSON.parse(JSON.stringify(this.hiddenData));
    this.visibleColumns = JSON.parse(JSON.stringify(this.visibleData));
  }

  protected onChange(item?: {field: keyof Visible, rowIndex: number}) {
    if (item && Array.isArray(this.visibleColumns[item.rowIndex][item.field])) {
      const dataItem = this.visibleColumns[item.rowIndex][item.field];
      this.visibleColumns[item.rowIndex][item.field] = arrayRewrite(dataItem);
    }

    let hasEmptyFields = false;
    this.visibleColumns.forEach((item) => {
      if (hasEmptyFields) return;
      Object.entries(item).forEach(([key, value]) => {
        if (hasEmptyFields) return;
        const fieldInfo = this.info.visible.find((item) => item.field === key);
        hasEmptyFields = !!fieldInfo?.required && (value == null || (Array.isArray(value) && !value?.length));
      });
    });

    this.value.emit({value: this.visibleColumns, invalid: hasEmptyFields});
  }

  private setSelectedRow(rowIndex: number, visible: boolean = true) {
    this.selectedRow = {rowIndex, visible};
  }

  protected get transferButtonDisabled() {
    return !this.editable || this.selectedRow === undefined || !!this.selectedRow?.visible;
  }

  protected get moveButtonDisabled() {
    return !this.editable || !this.selectedRow?.visible;
  }

  protected onItemClick({rowIndex, dataItem}: CellClickEvent, visible: boolean = true) {
    this.setSelectedRow(rowIndex, visible);
  }

  protected transferFrom() {
    if (this.selectedRow) {
      const item = this.hiddenColumns[this.selectedRow.rowIndex];
      this.visibleColumns.push(this.hiddenToVisible ? this.hiddenToVisible(item) : <Visible><unknown>item);
      this.hiddenColumns.splice(this.selectedRow.rowIndex, 1);
      this.setSelectedRow(this.visibleColumns.length - 1);
      this.onChange();
    }
  }

  protected transferAllTo() {
    this.hiddenColumns.forEach((item) => {
      this.visibleColumns.push(this.hiddenToVisible ? this.hiddenToVisible(item) : <Visible><unknown>item);
    });
    this.hiddenColumns = [];
    this.setSelectedRow(this.visibleColumns.length - 1);
    this.onChange();
  }

  protected transferTo(dataItem: Visible, index: number) {
    const hiddenItem = this.visibleToHidden ? this.visibleToHidden(dataItem) : <Hidden><unknown>dataItem;
    const comparisonField = this.info.hidden[0].field;
    const rowIndex = this.hiddenData.findIndex((item) => item[comparisonField] === hiddenItem[comparisonField]);
    if (rowIndex >= 0) {
      this.hiddenColumns.splice(rowIndex, 0, hiddenItem);
    } else {
      this.hiddenColumns.unshift(hiddenItem);
    }
    this.visibleColumns.splice(index, 1);
    this.setSelectedRow(rowIndex > 0 ? rowIndex : 0, false);
    this.onChange();
  }

  protected move(up: boolean = false) {
    if (this.selectedRow) {
      const item = this.visibleColumns.splice(this.selectedRow.rowIndex, 1)[0];
      const rowIndex = up ? --this.selectedRow.rowIndex : ++this.selectedRow.rowIndex;
      this.visibleColumns.splice(rowIndex, 0, item);
      this.onChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // TODO: Предыдущие значения сохраняются при передачи новых
    this.listboxReset();
  }
}

import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormationDiplomaService} from '../../../../services/gia/formationdiploma.service';
import {additionalDataModel} from '../../../../models/gia/formationdiploma.model';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DictsService} from '../../../../services/gia/dicts.service';
import {DictDto} from '../../../../models/gia/dicts/dicts.model';
import {LoaderType} from "@progress/kendo-angular-indicators";

@Component({
    selector : 'app-additionaldata',
    templateUrl: './additionaldata.component.html',
    styleUrls : ['./additionaldata.component.scss']
})

export class AdditionalDataComponent implements OnInit{
    @Input() studentId: string = "";
    @Input() error: boolean = false;
    @Input() allowEdit: boolean = false;

    @Output() saveGeneralInfo = new EventEmitter();

    public additionalData: additionalDataModel = new additionalDataModel;
    public type: LoaderType = "converging-spinner";
    public loaderVisible: boolean = false;

    public studyForms: DictDto[] = [];

    constructor(
        private formationDiplomaService: FormationDiplomaService,
        private notificationService: NotificationsService,
        private dictsService: DictsService,
    ) {}

    ngOnInit() {
        this.getAdditionalInfo();
        this.getStudyForm();

        this.formationDiplomaService.tabApplication.next({
            application: false
        });
    }

    public getAdditionalInfo() {
        this.loaderVisible = true;

        this.formationDiplomaService.getAdditionalData(this.studentId)
            .subscribe(response => {
                this.additionalData = response;
                this.loaderVisible = false;
            })
    }

    public getStudyForm() {
        this.dictsService.getStudyForms()
            .subscribe(response => {
                this.studyForms = response;
            })
    }

    public clickCheckbox(value: string) {
        switch(value) {
            case 'checkStudyForm':
                this.additionalData.includeStudyFormInfo = !this.additionalData.includeStudyFormInfo;
                break;
            case 'otherInstitution':
                this.additionalData.isPartMasteredAtAnotherUniversity = !this.additionalData.isPartMasteredAtAnotherUniversity;
                break;
            case 'includeAccelerated':
                this.additionalData.includeAccelerated = !this.additionalData.includeAccelerated;
                break;
        }
    }

    public saveInformation() {
        this.saveGeneralInfo.emit();
    }

    public saveData(diplomaInfoId: string) {
        if (!this.allowEdit)
          return;

        this.additionalData.diplomaInfoId = diplomaInfoId;

        this.formationDiplomaService.saveAdditionalData(this.additionalData)
        .subscribe(response => {
                this.notificationService.showSuccess('Дополнительные сведения диплома успешно сохранены');
                this.getAdditionalInfo();
            },
            error => {
                this.notificationService.showError(error.error);
                this.getAdditionalInfo();
            }
        )
    }

}

<mfc-settings></mfc-settings>
<h1>Шаблон</h1>

<div class="content" [formGroup]="formGroup" *ngIf="formGroup.getRawValue">
  <span class="field field_text">
    <h4>Категория</h4>
    <h6>{{template?.dictApplicationCategory?.name}}</h6>
  </span>
  <span class="field field_text">
    <h4>Тип</h4>
    <h6>{{template?.dictApplicationType?.name}}</h6>
  </span>
  <span class="field field_text">
    <h4>Шаблон формы заявки</h4>
    <h6>{{template?.applicationConstructorName}}</h6>
  </span>
  <span class="field field_text">
    <h4>Статус шаблона формы заявки</h4>
    <h6>{{template?.status?.name}}</h6>
  </span>
  <span class="field field_text">
    <h4>Уровень подготовки</h4>
    <h6>{{template?.isAllTrainingLevelAvailable ? 'Все' : template?.trainingLevelsName}}</h6>
  </span>
  <span class="field field_text">
    <h4>Институт/факультет</h4>
    <h6>{{template?.isAllFacultyAvailable ? 'Все' : template?.facultiesName}}</h6>
  </span>
  <ng-container *ngIf="formGroup.get(DocumentTypeStrings.Document)?.getRawValue().length"
                [ngTemplateOutlet]="templates"
                [ngTemplateOutletContext]="{form: formGroup, type: DocumentTypeStrings.Document}">

  </ng-container>
  <ng-container *ngIf="formGroup.get(DocumentTypeStrings.Statement)?.getRawValue().length"
                [ngTemplateOutlet]="templates"
                [ngTemplateOutletContext]="{form: formGroup, type: DocumentTypeStrings.Statement}">

  </ng-container>
</div>
<span class="buttons">
  <button (click)="onCancel()" kendoButton>Закрыть</button>
</span>

<ng-template [typedTemplate]="documentsTemplate" #templates let-form="form" let-type="type">
  <span class="field title-template">
    <h4 class="name">{{type === DocumentTypeStrings.Document ? 'Печатная форма документа' : 'Печатная форма заявления'}}</h4>
  </span>
  <span class="files" [formGroup]="form">
    <span [formArrayName]="type" class="files-container" *ngFor="let file of $any(form).controls[type].controls; let i = index">
      <span class="group" [formGroupName]="i">
        <h4>{{ file.value.name }}</h4>
        <span class="bar">
          <kendo-fileselect
            [multiple]="false"
            [restrictions]="fileRestrictions"
            [accept]="allowedExtensions"
            (valueChange)="uploadFile(file, $event)"
            (remove)="removeFile(file)"
            formControlName="file">
            <ng-template kendoFileSelectFileInfoTemplate let-files>
              <div>{{ files[0].name }}</div>
            </ng-template>
          </kendo-fileselect>
          <h6 class="required">*</h6>
          <kendo-icon kendoTooltip
                      [tooltipWidth]="320"
                      [title]="tooltip"
                      name="info"
                      tooltipClass="mfc-tooltip"
                      position="right"
                      size="medium">
          </kendo-icon>
          <h6 class="file-requirement">До 1 файла формата .doc или .docx</h6>
          <a *ngIf="file.get('file')?.value" class="file-link"
             (click)="downloadFile(file)">
            Скачать
          </a>
        </span>
      </span>
    </span>
  </span>
</ng-template>

<div kendoDialogContainer></div>

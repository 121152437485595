import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Location} from "@angular/common";
import {lastValueFrom} from "rxjs";
import {Role} from "../models/useraccess/role";
import {OfertaUserAccessService} from "../services/useraccess/oferta-user-access.service";
import {AccessRights} from "../models/oferta/enums/accessRights.enum";

@Injectable({
  providedIn: 'root'
})
export class OfertaRoleGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: OfertaUserAccessService,
    private location: Location,
    private router: Router) {
  }

  private returnValue(value: boolean): boolean {
    if (!value) {
      alert('Недостаточно прав для доступа на эту страницу');
      localStorage.setItem('last_url', this.location.path());
      this.router.navigate([`/profile`]).then();
    }
    return value;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise <boolean> {
    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;

      if (!this.userAccessService.currentUserAccess$.value) {
        await lastValueFrom(this.userAccessService.getCurrentUserAccess()).then(value => {
          this.userAccessService.currentUserAccess$.next(value);
        });
      }

      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)) {
        return true;
      }
      // Check if user has oferta role
      if (roles?.includes(Role.Oferta)) {
        const accessRights = this.userAccessService.currentUserAccess$.value?.userAccessRight;
        if (path === 'oferta') {
          return true;
        }
        if (path === 'oferta/regulationsettings') {
          return this.returnValue(accessRights?.regulation !== AccessRights.No);
        }
        if (path?.startsWith('oferta/usersignature/info')) {
          return this.returnValue(accessRights?.userSignature !== AccessRights.No);
        }
        // Dict access
        if (path === 'oferta/dictregulationtype') {
          return this.returnValue(accessRights?.dict !== AccessRights.No);
        }
      }
    }
    return this.returnValue(false);
  }
}

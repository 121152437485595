export const items: any[] = [
    {
        text: "Настройки",
        icon: "cog",
        items: [
            {
                text: "Образовательные стандарты",
                path: "/education/standard",
                dictsetting: true
            },
            {
                text: "Образовательные программы",
                path: "/education/oop",
                dictsetting: true
            },
            {
                text: "Учебные планы",
                path: "/education"
            },
            {
                text: "Настройка графика учебного процесса",
                path: "/education/global-schedule-settings",
                dictsetting: true
            },
            /*{
                text: "Экспорт графиков учебного процесса",
                path: "/education/export-schedule",
                dictsetting: true
            },*/
            {
                text: "Настройки доступа",
                path: "/education/useraccess",
                admin: true
            },
            {
                text: "Настройка списка подписантов",
                path: "/education/signatory",
                signatory: true
            },
            {
                text: "Справочники",
                dictsetting: true,
                items: [{
                    text: "Компонент",
                    path: "/education/dictcomponent"
                },
                    {
                        text: "Тип компонента",
                        path: "/education/dictcomponenttype"
                    },
                    {
                        text: "Циклы",
                        path: "/education/dictcycle"
                    },
                    {
                        text: "Тип цикла",
                        path: "/education/dictcycletype"
                    },
                    {
                        text: "Тип стандарта",
                        path: "/education/standardtype"
                    },
                    {
                        text: "Области и отрасли науки",
                        path: "/education/sciencebranch"
                    },
                    {
                        text: "Категории работ графика",
                        path: "/education/dictWorkCategorySchedule"
                    },
                    {
                        text: "Виды работ учебного плана",
                        path: "/education/dictWorkStudyPlanType"
                    },
                    {
                        text: "Тип проведения занятий",
                        path: "/education/dictLessonType"
                    },
                    {
                        text: "Форма проведения занятий",
                        path: "/education/dictLessonForm"
                    },
                    {
                        text: "База ускоренного обучения",
                        path: "/education/dictAcceleratedStudyBase"
                    },
                    {
                        text: "Группы ограничений стандарта",
                        path: "/education/dictgroupstandardlimitation"
                    },
                    {
                        text: "Ограничения стандартов",
                        path: "/education/dictstandardlimitationsetting"
                    },
                    {
                        text: "Укрупненные группы специальностей",
                        path: "/education/dictEnlargedGroupSpecialization"
                    },
                    {
                        text: "Дополнительные работы по дисциплине",
                        path: "/education/dictTypeWorkDisciplineAdditional"
                    },
                ]
            },
            {
                text: "Внешние источники данных",
                dictsetting: true,
                items: [{
                    text: "Типы дисциплин",
                    path: "/education/dictdisciplinetype"
                },
                    {
                        text: "Кафедры",
                        path: "/education/department",
                        signatory: true
                    },
                ]
            }
        ],
    },
];

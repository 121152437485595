export const DataTypeEnum = [
    { id: 1, text: 'Текст' },
    { id: 2, text: 'Дата' },
    { id: 3, text: 'Число' },
    { id: 4, text: 'Целое число' },
    { id: 5, text: 'Выбор из справочника' },
    { id: 6, text: 'Заголовок' },
    { id: 7, text: 'Вопросы'},
];

export enum TypeEnum 
{
  text = 1,
  date = 2,
  number = 3,
  numberint = 4,
  dict = 5,
  title = 6,
  questions = 7,
}

export const Types = TypeEnum;

export const typeDict = 'dict';
export const columnName = 'dataTypeEnum';

export enum filterNameEnum {
  level = 'level',
  year = 'year'
}

export const filterName = filterNameEnum;
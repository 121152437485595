<education-header></education-header>
<kendo-tilelayout class="k-mt-4" [style.padding.px]="8" [columns]="4">
  <kendo-tilelayout-item [col]="1" [colSpan]="4">
    <kendo-tilelayout-item-header class="form-header">
      <span class="title">{{educationPlanDetailsService.currentEducationStandard.directionTraining}}</span>
      <kendo-dropdownlist [style.max-width.px]="300"
        [data]="educationPlanDetailsService.currentEducationStandard.educationPrograms"
        [(value)]="educationPlanDetailsService.currentEducationProgram" textField="educationProgramName"
        valueField="educationProgramId" (valueChange)="onChangeEduProgram($event)">
        <ng-template kendoDropDownListNoDataTemplate></ng-template>
      </kendo-dropdownlist>
      <!-- <kendo-dropdownlist [style.max-width.px]="150" [data]="educationPlanDetailsService.studyForms"
        [value]="educationPlanDetailsService.studyForms[0]">
        <ng-template kendoDropDownListNoDataTemplate></ng-template>
      </kendo-dropdownlist> -->
      <button kendoButton themeColor="primary" (click)="goToEduProgramList()">К списку ОП</button>
    </kendo-tilelayout-item-header>
    <kendo-tilelayout-item-body [style.padding-left.px]="36">
      <education-plan-edit
        [isNew]="true"
        [eProgram]="educationPlanDetailsService.currentEducationProgram"
        (onSave)="onSave($event)" (onCancel)="goBack()">
      </education-plan-edit>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>

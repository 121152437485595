import {Guid} from "guid-typescript";

export class DictFilial {
  public filialId!: number;
  public dictFilialExternalId?:  Guid | string;
  public filialName!: string;
  public filialSName!: string;
  public city!: string;
  public order?: number;
}

export const items: any[] = [
  {
    text: 'Настройки',
    icon: 'cog',
    items: [
      {
        text: 'Состав ГЭК',
        path: '/gia/stateExamCommission',
      },
      {
        text: 'Шаблоны печатных форм',
        path: '/gia/printtemplate',
        printTemplates: true,
      },
      {
        text: 'Настройки доступа',
        path: '/gia/useraccess',
        accessSettings: true,
      },
      {
        text: 'Настройки списка подписантов',
        path: '/gia/signatory',
        localSignatory: true,
      },
      {
        text: 'Создание справочников',
        path: '/gia/dictdesigner',
        dictCreating: true,
      },
      {
        text: 'Справочники',
        dicts: true,
        items: [
          {
            text: 'Тип диплома',
            path: '/gia/dictdiplomatype',
          },
          {
            text: 'Категории шаблонов печатных форм',
            path: '/gia/dictprinttemplatecategory',
          },
          {
            text: 'Роли локальных подписантов',
            path: '/gia/localsignatoryrole',
          },
          {
            text: 'Типы приказов ГЭК',
            path: '/gia/dictstateexamcommissiontype',
          },
          {
            text: 'Роли членов ГЭК',
            path: '/gia/dictstateexamcommissionmemberrole',
          },
        ],
      },
      {
        text: 'Конструктор протокола ГИА',
        path: '/gia/constructorgia',
        protocolDesigner: true,
      },
      {
        text: 'Приказ о допуске',
        path: '/gia/admissionorder',
      },
      {
        text: 'Приказ о выпуске',
        path: '/gia/releaseorder',
      },
      {
        text: 'Протокол ГИА',
        path: '/gia/giaprotocol',
      },
      {
        text: 'Отчеты',
        path: '/gia/report-list',
      },
      {
        text: 'Настройки ГИА',
        path: '/gia/settings',
        admin: true,
      }
    ],
  },
];

import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent, PagerPosition, PageChangeEvent, } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { Filter } from "src/app/models/classroom/classroom-fund.model";
import { ClassroomFundService } from 'src/app/services/classroom/classroom-fund.service';
import { TypesAuditoriumService } from 'src/app/services/classroom/types-auditorium.service';
import { AssignAuditoriumService } from 'src/app/services/classroom/assign-auditorium.service';
import { BuildingService } from 'src/app/services/classroom/building.service';
import { ParametersAuditoriumService } from 'src/app/services/classroom/parameters-auditorium.service';
import { DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import { Router} from "@angular/router";
import { ClassRoomUserAccessService } from '../../services/useraccess/classroom-user-access.service';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { checkRole } from "../../helpers/token/role-check";
import { TokenStorageService } from "../../services/token.service";
import { JwtHelperService}  from "@auth0/angular-jwt";
import { Role } from "../../models/useraccess/role";

@Component({
    selector: 'app-classroom-fund',
    templateUrl: './classroom-fund.component.html',
    styleUrls: ['./classroom-fund.component.scss']
})

export class ClassroomFundComponent implements OnInit{

    public titleClass: string = "";
    public numberClass: string = "";

    public classrooms: any = [];

    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public info = true;
    public sizes = [10, 20, 30];
    public pageSize = 10;
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';
    public count: number = 0;

    public loading = false;

    public buildings: any = [];
    public types: any = [];
    public owners: any = [];
    public assigns: any = [];

    public arrayOfBuildings: any = [];
    public arrayOfTypes: any = [];
    public arrayOfOwners: any = [];
    public arrayOfAssigns: any = [];

    public filters: boolean = false;
    public filtersNumber: number = 0;
    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
      };

    public filter: Filter = {
        numberClass: "",
        titleClass: "",
        arrayOfBuildings: undefined,
        arrayOfTypes: undefined,
        arrayOfOwners: undefined,
        arrayOfAssigns: undefined,
    }

    public loaderExcelExport = false;

    public expandedState: boolean = false;

    public accessLevel: boolean = false;
    public accesses: any = [];
    public personId: string = "";
    public isAdmin: boolean;
    public numberId: number = 0;
    public arrayOfIndex: any = [];

    public skip = 0;

    public filterTypes: any = [];
    public filterOwners: any = [];
    public filterAssigns: any = [];

constructor(
    private classroomFundService: ClassroomFundService,
    private typesAuditoriumService: TypesAuditoriumService,
    private assignAuditoriumService: AssignAuditoriumService,
    private buildingService: BuildingService,
    private router: Router,
    private zone: NgZone,
    private userAccessService: ClassRoomUserAccessService,
    private personService: PersonService,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private parametersAuditoriumService: ParametersAuditoriumService,
  )
  { this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);}

ngOnInit() {
    this.getAllClassroom();
    this.getAllBuilding();
    this.getAllTypesAuditorium();
    this.getAllAssigns();
    this.getAllOwners();
    this.getAccess();
}

    public getAllClassroom() {
        this.classroomFundService.getAllClassroom()
        .subscribe(response => {
          this.classrooms = response;
          this.count = this.classrooms.length;
          let i = 1;
          this.classrooms.forEach((element: any) => {
            element['index'] = i;
            i = i + 1;
           });
           this.parametersAuditoriumService.transferLevel.next({
            level: 0,
          });
        });
    }

    public getAllBuilding() {
        this.buildingService.getAllBuildings()
          .subscribe(response => {
              this.buildings = response;
            },
            error => {
              console.log(error.error);
            });
    }

    public getAllTypesAuditorium() {
        this.typesAuditoriumService.getAllTypesAuditorium()
        .subscribe(response => {
            this.types = response;
            this.filterTypes = this.types;
            },
            error => {
            console.log(error.error);
            });
    }

    public getAllAssigns() {
        this.assignAuditoriumService.getAllAssigns()
        .subscribe(response => {
            this.assigns = response;
            this.filterAssigns = this.assigns;
            },
            error => {
            console.log(error.error);
            });
    }

    public getAllOwners() {
        this.classroomFundService.getAllOwners()
        .subscribe(response => {
            this.owners = response;
            this.filterOwners = this.owners;
            },
            error => {
            console.log(error.error);
            });
    }

    public oneFilterClean(e: any) {
        switch (e) {
          case 1:
            this.filter.titleClass = "";
            break;
          case 2:
            this.filter.arrayOfBuildings = undefined;
            break;
          case 3:
            this.filter.arrayOfTypes = undefined;
            break;
          case 4:
            this.filter.arrayOfOwners = undefined;
            break;
          case 5:
            this.filter.arrayOfAssigns = undefined;
            break;
          case 6:
            this.filter.numberClass = "";
            break;
        }

        if (this.filter.titleClass == "" && this.filter.arrayOfBuildings == undefined && this.filter.arrayOfTypes == undefined
           && this.filter.arrayOfAssigns == undefined && this.filter.numberClass == "") {
          this.filters = false;
          this.expandedState = false;
        }

        this.filterHandler();
    }

    public filterClean() {
        this.filter = {
          titleClass: "",
          arrayOfBuildings: undefined,
          arrayOfTypes: undefined,
          arrayOfOwners: undefined,
          arrayOfAssigns: undefined,
          numberClass: "",
        }
        this.expandedState = false;
        this.filters = false;
        this.getAllClassroom();
    }

    public selectionChange(value: any){
      if (this.filter.arrayOfBuildings!=undefined && this.filter.arrayOfBuildings.length!= 0 ||
          this.filter.arrayOfTypes!=undefined && this.filter.arrayOfTypes.length!=0 ||
          this.filter.arrayOfOwners!=undefined && this.filter.arrayOfOwners.length!=0 ||
          this.filter.arrayOfAssigns!=undefined && this.filter.arrayOfAssigns.length!=0 ||
          this.filter.titleClass?.length!=0 || this.filter.numberClass?.length!=0)
        {
          this.filters = true;
        } else {
          this.filters = false;
        //  this.expandedState = false;
        }
    }

    public editHandler({
        sender,
        isEdited,
        rowIndex,
        dataItem,
    }: CellClickEvent): void {

     window.open(`classroom/parameters/${dataItem.id}`);
    }

    public filterHandler() {
        this.classroomFundService.getFilters(this.filter.numberClass, this.filter.titleClass, this.filter.arrayOfBuildings, this.filter.arrayOfTypes,
                                            this.filter.arrayOfAssigns, this.filter.arrayOfOwners)
            .subscribe({next:(data: any) => {
              this.classrooms = data;
              let i = 1;
              this.classrooms.forEach((element: any) => {
                element['index'] = i;
                i = i + 1;
              });
              this.count = this.classrooms.length;
              this.pageChange({skip: 0, take: this.pageSize});
             }});
        this.expandedState = false;
    }

    public pageChange({ skip, take }: PageChangeEvent): void {
      this.skip = skip;
    }

    public exportToExcel(grid: GridComponent): void {
      this.loaderExcelExport = true
      this.allData = this.allData.bind(this);
      if (grid != undefined) grid.saveAsExcel();
    }

    public allData = (): any => {
      let response = {
        data: this.classrooms,
        total: this.classrooms.length
      };
      this.loaderExcelExport = false
      return response;
    };

  public getAccess()  {
    this.userAccessService.getAccessLevel()
    .subscribe(response => {
 //     console.log(response);
      if (response.canEdit == true){
        this.accessLevel = true;
      }
    })
  }

  public typeFilter(value: any) {
    this.filterTypes = this.types.filter(
      (s:any) => s.classRoomTypeName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public ownerFilter(value: any) {
    this.filterOwners = this.owners.filter(
      (s:any) => s.departmentName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public assignFilter(value: any) {
    this.filterAssigns = this.assigns.filter(
      (s:any) => s.classRoomPurposeName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  public addClassroom(){   
    this.router.navigate(['/classroom/parameters']);
  //  [routerLink]="['/classroom/parameters']"
  }

}
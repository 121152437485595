import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";
import { OtherDocuments } from "src/app/models/contingent/otherdocuments.model";

@Injectable({
    providedIn: 'root'
  })

export class OtherDocumentService {

    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.otherDocument}`;

    constructor(private http: HttpClient) { }

    public getOtherDocuments(studPersonId:  string | undefined) {
        return this.http.get<OtherDocuments[]>(this.baseUrl + '/GetOtherDocumentByStudPersonId/' + studPersonId)
            .pipe(catchError(this.handleError));
    }

    public addOtherDocuments(data: OtherDocuments, studentId: string) {
        return this.http.post(this.baseUrl + '/' + studentId, data)
            .pipe(catchError(this.handleError));
    }

    public updateOtherDocuments(data: OtherDocuments, documentId: string, studentId: string) {
        return this.http.put(this.baseUrl + '/' + documentId + '/' + studentId, data)
            .pipe(catchError(this.handleError));
    }

    public deleteOtherDocuments(id: string, studentId: string) {
        return this.http.delete(this.baseUrl + '/' + id + '/' + studentId)
            .pipe(catchError(this.handleError));
    }

      // Error
    handleError(error: HttpErrorResponse) {
      return throwError(error);}
}

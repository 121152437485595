import {ApplicationTabsEnum} from '../enums/application-tabs.enum';

export interface Tab {
  id?: string;
  name: string;
  selector: ApplicationTabsEnum;
  displayInAddForm: boolean;
}

export const tabs: Tab[] = [
  {name: 'Лист согласования', selector: ApplicationTabsEnum.List, displayInAddForm: false},
  {name: 'Документы на подпись', selector: ApplicationTabsEnum.Documents, displayInAddForm: false},
  {name: 'Документы', selector: ApplicationTabsEnum.ReadyDocuments, displayInAddForm: false},
  {name: 'Комментарии', selector: ApplicationTabsEnum.Comments, displayInAddForm: false},
];

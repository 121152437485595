import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {Subscription} from "rxjs";
import {AcademicStateService} from './services/contingent/academicstate.service';
import {NavigationEnd, Router} from "@angular/router";
import {DrawerItemExpandedFn, DrawerSelectEvent} from '@progress/kendo-angular-layout';
import {BreadCrumbCollapseMode} from '@progress/kendo-angular-navigation';
import {breadcrumbItemsMap} from "./models/home/breadcrumb-items.model";
import { LKPerson } from './models/person/lkPerson.model';
import { PersonService } from './services/person/lkperson.service';
import {JwtHelperService} from "@auth0/angular-jwt";
import {TokenStorageService} from "./services/token.service";
import {AuthService} from "./services/auth.service";
import {environment} from "../environments/environment";
import {isBoolean} from "lodash";
import { Role } from './models/useraccess/role';
import { AlertStatisticsService } from 'src/app/services/announcement/alert-statistics.service';
import { AlertStatistics } from 'src/app/models/announcement/alert-statistics.model';
import { tokenStore } from 'src/options/settings';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { ShowService } from 'src/app/services/announcement/show.service';
import {Location} from "@angular/common";
import {NotificationService} from "./services/science/publications/notification.service";
import {UserSignatureService} from "./services/oferta/user-signature.service";
import {SignatureTypeEnum} from "./models/oferta/enums/dictSignatureType.enum";
import {PersonIsBlockedQuery} from "./models/oferta/usersignature/query/personIsBlockedQuery";
import {CreateQuery} from "./helpers/createQuery-helper";



interface Item {
  text?: string;
  icon?: string;
  path?: string;
  selected?: boolean,
  separator?: boolean,
  id?: string,
  parentId?: string,
  url?: string,
  return?: boolean,
  padding?: string;
  default?: boolean;
}
const menuExpanded: string  = "menuExpanded"
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, OnDestroy {
  public fullTitle = environment.headerTitle.full;
  public shortTitle = environment.headerTitle.short;

  public hidden: boolean = true;
  public isAdmin: boolean = false;
  public isSwitchActive: boolean = false;
  public isBlocked:boolean = false;
  public userHasSimpleSignature: boolean = true;

  public photoUrl = ''
  public hasSwitchUser = environment.has_switch_user;
  public selected = "Inbox";
  public expanded: boolean =  (localStorage.getItem(menuExpanded) ?? "true") == "true";
  private routesData: Subscription = new Subscription;
  private services: Array<Item> = [
    {text: 'Новости и объявления', icon: "k-i-parameter-date-time", url: "/alert", path: "/alert", id: Role.Announcements, default: true, return: false},
    {text: "Контингент", icon: "k-i-user", url: "/contingent", path: "/contingent", id: Role.Contingent, return: false},
    {text: "Промежуточный контроль", icon: "k-i-chart-pie", url:"/middlecontrol", path: "/middlecontrol", id: Role.Session, return: false},
    {text: "Текущий контроль", icon: "k-i-chart-doughnut", url: "/currentcontrol", path: "/currentcontrol", id: Role.CurrentControl, return: false},
    {text: "ГИА", icon: "k-i-tell-a-friend", url: "/gia", path: "/gia", id: Role.GIA, return: false},
    {text: "Учет бланков", icon: "k-i-file", url: "/diplomablanks", path: "/diplomablanks", id: Role.Diplomablanks, return: false},
    {text: "Образование", icon: "k-i-delicious", url: "/education", path: "/education", id: Role.Projecting, return: false},
    {text: "Сопряжение и нагрузка", icon: "k-i-gear", url: "/disciplineworkload", path: "/disciplineworkload", id: Role.Disciplineworkload, return: false},
    {text: "Справочники", icon: "k-i-dictionary-add", url:"/dicts", path: "/dicts", id: Role.Dictionaries, return: false},
    {text: "Публикации", icon: "k-i-document-manager", url: "/publication", id: Role.Publication, path: "/publications", return: false},
    {text: "Аудиторный фонд", icon: "k-i-pane-freeze", url: "/classroom",  id: Role.Classroom,  path: "/classroom", return: false},
    {text: "Зарплатный лист", icon: "k-i-calculator", url: "/payslip", id: Role.LkPersonPayslip, path: "/payslip", default: true, return: false},
    {text: "Карты", icon: "k-i-detail-section", url: "/cards", id: Role.Cards, path: "/cards", return: false},
    {text: "Конструктор отчетов", icon: "k-i-file-report", url: "/report-designer", id: Role.ReportDesigner, path: "/report-designer", return: false},
    {text: "Мой деканат", icon: "k-i-file-txt", url: "/mfc", id: Role.MFC, path: "/mfc", return: false},
    {text: "Присоединение к положениям", icon: "k-i-track-changes-accept-all", url: "/oferta", id: Role.Oferta, path: "/oferta", return: false},
  ];
  private externalLinks: Array<Item> = environment.menuItems.map((item: any) => ({...item, return: true, default: true}));
  public items: Array<Item> = [
    {icon: "k-i-menu",  return: false, padding: "30px", default: true, selected: false},
    {text: "Главная", icon: "k-i-home", selected: true, path: "/profile", id: "home", url: "/profile", default: true, return: false},
    {text: 'Технические администраторы', icon: "k-i-myspace-box", url: "/technicaladministators", path: '/technicaladministators', return: false},
  ];

  public collapseMode: BreadCrumbCollapseMode = "auto";

  public persons: LKPerson[] = [];
  public person: LKPerson = {
    personId: 0,
    login: "",
    personExternalId: "personExternalId",
    fullName: "fullName",
    firstName: "fistName",
    lastName: "lastName",
    middleName: "middleName",
    birthday: new Date(),
    hasPps: false,
    remainingVacationDaysNumber: null,
  }
  public personIsBlockedQuery?: PersonIsBlockedQuery;

  public onSelect(ev: DrawerSelectEvent): void {
    localStorage.setItem(menuExpanded, ev.sender.expanded ? "false" : "true")
    if (ev.item.icon == "k-i-menu") {
      ev.preventDefault();
      this.expanded = !this.expanded;
      //localStorage.setItem(menuExpanded, this.expanded ? "true" : "false");
    } else if (ev.item.path != undefined) {
      this.router.navigate([ev.item.path]);
    }
      localStorage.removeItem("middle_control_settings")
      localStorage.removeItem("current_control_settings")
  }

  public menuClick(value: any) {
    return isBoolean(value.return) ? value.return : false;
  }

  public menuVisible(value: any) {
    let role = [] as string[];
    const token = this.tokenStore.getAuthToken();
    if(token !== null)
    {
      const user = this.jwtHelper.decodeToken(token);
      if (user.role)
        role = user.role;
    }

    if(role.includes(Role.Admin))
      this.isAdmin = true;

    return role.includes(Role.Admin) || role.includes(value.id) || value.default ||
      this.person.hasPps && value.id == Role.Disciplineworkload;
  }

  public breadcrumbitems: any = []//BreadCrumbItem[] = [];

  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private academicstateService: AcademicStateService,
              private router: Router,
              private personService: PersonService,
              private jwtHelper: JwtHelperService,
              private tokenStore: TokenStorageService,
              public authService : AuthService,
              private alertStatisticsServise: AlertStatisticsService,
              private notificationService: NotificationService,
              private cdRef : ChangeDetectorRef,
              private announcementService: AnnouncementService,
              private showService: ShowService,
              private location: Location,
              private userSignatureService: UserSignatureService

  ) {
    this.items.splice(2, 0, ...this.externalLinks);
    this.items.splice(2, 0, ...this.services.filter(item => environment.services.includes(item.id)));
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const splitURL = e.url.split('/', 3);
        const prevSelected = this.items.find((i) => i.selected);
        if (prevSelected) {
          prevSelected.selected = false;
        }
        const newSelected = this.items.find((i) =>
            i.path === `/${splitURL[1]}` ||  i.path === `/${splitURL[1]}/${splitURL[2]}`);
        if (newSelected && newSelected.icon !== 'k-i-menu') {
          newSelected.selected = true;
        } else {
          this.items[0].selected = true;
        }
        this.items = [...this.items];
      }
    });
    this.initRoutes();
  }

  public isAlertEnabled(text: string) {
    return text === "Новости и объявления" && this.calculatedParametr !== 0
      || text === "Публикации" && this.newPublicationCount !== 0;
  }

  public getBadgeCount(text: string) {
    switch(text) {
      case "Новости и объявления":
        return this.calculatedParametr;
      case "Публикации":
        return this.newPublicationCount;
    }
  }

  public calculatedParametr: AlertStatistics["calculatedParametr"] = 0;
  public getAlertStatistics() {
    this.alertStatisticsServise.getAlertStatistics()
    .subscribe(
        response => {
            this.calculatedParametr = response["calculatedParametr"];
        }
    );
  }

  public newPublicationCount: number = 0;
  public getNewPublicationCount() {
    this.notificationService.getNotification()
      .subscribe(response => {
          this.newPublicationCount = response.unreadMyPublications + response.unreadAllPublications;
      });
  }

  ngOnInit(): void {
    if (this.authService.isUserAuthenticated()) {
      this.personIsBlockedRequest();
      this.personHasSimpleSignature();
      this.getCurrentPerson();

      if (this.items.find(item => item.id === Role.Announcements)) {
        this.getAlertStatistics();
        this.announcementService.subscriber$.subscribe(() => {
          this.getAlertStatistics();
        });
      }

      if (this.items.find(item => item.id === Role.Publication)) {
        this.getNewPublicationCount();
        this.notificationService.subscriber$.subscribe(() => {
          this.getNewPublicationCount();
        });
      }
    }

    const switchUser = localStorage.getItem('switchPerson');
    if(switchUser === 'true')
      this.isSwitchActive = true;
    this.cdRef.detectChanges();
  }

  private personHasSimpleSignature() {

    if (localStorage.getItem('switchPerson') === 'true')
      return;

    const query = CreateQuery({
      signatureTypeEnum: SignatureTypeEnum.Simple
    });

    this.userSignatureService.personHasSignature(query)
      .subscribe(response => {
        this.userHasSimpleSignature = response;
      })
  }

  public getCurrentPerson() {
    this.personService.getCurrentPerson()
      .subscribe(
        response => {
          this.person = response;
          this.photoUrl = `url('${environment.apiEndpoint}lkperson/profile/GetPersonPhoto/${response.personExternalId}')`;

          //console.log(response);
        }
      );
  }

  public onItemClick(item: any): void {
    const selectedItemIndex = this.breadcrumbitems.findIndex((i: any) => i.adress === item.adress);
    const url = this.breadcrumbitems
        .slice(0, selectedItemIndex + 1)
        .map((i: any) => `${i.adress}` );
    // Починка хлебных крошек для УП
    if(this.router.url.includes('edit-discipline') || this.router.url.includes('add-plan') || this.router.url.includes('add-discipline')) {
      // const splitURL = this.router.url.split('/', 5);
      // this.router.navigate([`education/${splitURL[2]}/plans/plan/${splitURL[4]}/`]);
      this.location.back();
      return;
    }
    this.router.navigate(url);
  }

  public ngOnDestroy(): void {
    this.routesData.unsubscribe();
  }

  // public onItemClick(item: BreadCrumbItem): void {
  //   const selectedItemIndex = this.breadcrumbitems.findIndex((i) => i.title === item.title);
  //   const url = this.breadcrumbitems
  //     .slice(0, selectedItemIndex + 1)
  //     .map((i) => `${i.title?.toLowerCase()}`);
  //   this.router.navigate(url);
  // }

  private initRoutes(): void {
    this.routesData = this.router.events.subscribe(() => {
      // Exclude query parameters from URL
      const route = this.router.url;
      this.breadcrumbitems = route
        .substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length)
        .split('/')
        .filter((segment) => breadcrumbItemsMap.has(segment))
        .map((segment) => {
          return {
            text: breadcrumbItemsMap.get(segment),
            adress: segment
          };
        });
      this.breadcrumbitems = [
        // {
        //   text: 'Главная',
        //   title: ''
        // },
        ...this.breadcrumbitems
      ];
    });
  }

  personIsBlockedRequest() {

    this.personIsBlockedQuery = {
      UserSignatureTypeEnum: SignatureTypeEnum.Simple
    }

    const query = CreateQuery(this.personIsBlockedQuery);

    this.userSignatureService.personIsBlocked(query)
      .subscribe(
        response => {
          this.isBlocked = response;
        }
      )
  }

  showLoginPage(){
    let route = window.location.pathname//this.router.url
    return !this.authService.isUserAuthenticated() && route != "/callback" // route.substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length) != "/callback"
  }

  showCallbackPage(){
    let route = window.location.pathname//this.router.url
    return !this.authService.isUserAuthenticated() && route == "/callback"// route.substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length) == "/callback"
  }

  switchUser() {
    this.router.navigate(['/switchuser']);
  }

  stopUserPreview() {
    let currentToken = localStorage.getItem('currentToken');
    localStorage.setItem('switchPerson', 'false');
    if(currentToken !== null)
    {
      localStorage.setItem(tokenStore, currentToken);
      localStorage.removeItem('currentToken');
      window.location.reload();
    }
  }

  public logOut = () => {
    localStorage.removeItem('switchPerson');
    localStorage.removeItem('last_url');
    window.location.reload();
    this.tokenStore.deleteToken()
  }
}

import {ApplicationStatusesEnum} from '../enums/application-statuses.enum';
import {Dict} from '../dict.model';
import {Moment} from 'moment';

export interface ApplicationMock {
  //TODO: не окончательный вариант
  externalId: string;
  number: number;
  fio: string;
  filial: string;
  trainingLevel: string;
  faculty: string;
  eduGroup: string;
  date: Date;
  category: string;
  type: string;
  status: ApplicationStatusesEnum;
  statusText?: string;
  numberNotifications: number;
  statusDate: Date;
}

export interface ApplicationList {
  applications: Application[];
  totalApplicationCount: number;
}

export interface Application {
  id: string;
  applicationNumber: string;
  studentName: string;
  faculty: Dict;
  trainingLevel: Dict;
  filial: Dict;
  groupName: string;
  createdAt: Date;
  applicationCategory: Dict;
  applicationType: Dict;
  applicationStatus: ApplicationStatusesEnum;
  applicationApprovalStatus: string; //?
  statusUpdatedAt: Date;
  notificationCount: number;
}

export interface ApplicationOptions {
  page: Page;
  sortParameters: Sort|null;
  // sortParameters: Sort[]|null; //TODO
  filter: Filter;
}

export interface Filter {
  [key: string]: unknown;
  applicationNumber: string|null;
  studentName: string|null;
  filials: string[]|null;
  faculties: string[]|null;
  trainingLevels: string[]|null;
  groupName: string|null;
  createdAt: DateRange|null;
  applicationCategories: string[]|null;
  applicationTypes: string[]|null;
  applicationStatuses: ApplicationStatusesEnum[]|null;
  applicationApprovalStatuses: string[]|null; //?
  statusUpdatedAt: DateRange|null;
}

export interface Page {
  number: number;
  size: number;
}

export type SortMode = 'ASC' | 'DESC';

export const sortModeValues = new Map<string, SortMode>([
  ['asc', 'ASC'],
  ['desc', 'DESC'],
]);

export interface Sort {
  columnName: string;
  mode: SortMode;
}

export interface DateRange {
  start?: Date|Moment;
  end?: Date|Moment;
}

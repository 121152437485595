import {Component, OnInit} from '@angular/core';
import {
    TeacherLoadStudyFormsGet,
    TeacherLoadTrainingLevelsGet
} from "../../../models/disciplineworkload/teachetworkload.model";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {DictService} from "../../../services/disciplineworkload/externals/dict.service";
import {EducationPlanService} from "../../../services/disciplineworkload/externals/education-plan.service";
import {getErrorMessage} from "../../../helpers/errorHandle-helper";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DictStudyForm, DictTrainingLevel} from "../../../models/disciplineworkload/externals.model";
import {lastValueFrom} from "rxjs";
import {detectTypeForRewrite} from "../../../helpers/multiselect-helper";
import {ReportsService} from "../../../services/disciplineworkload/reports.service";
import {DepartmentService} from "../../../services/disciplineworkload/externals/department.service";
import { PersonService } from '../../../services/disciplineworkload/externals/person.service';

@Component({
    selector: 'reportsWorkload',
    templateUrl: './reports-workload.component.html',
    styleUrls: ['./reports-workload.component.scss']
})
export class ReportsWorkloadComponent implements OnInit {

    // Filters
    public years: any[] = [];
    public semesters: any[] = [];
    public kafedras: any[] = [];
    public persons: any[] = [];
    public trainingLevels: TeacherLoadTrainingLevelsGet[] = [];
    public faculties: any[] = [];
    public studyForms: TeacherLoadStudyFormsGet[] = [];
    public workloadTypes: any[] = [{name: 'План', id: 1}, {name: 'Факт', id: 2}];
    public selectedReport = 0;

    //Filters ng models

    public kafedraEdit: any;
    public teacherEdit: any;
    public yearEdit: any;
    public semesterEdit: any;
    public reportEdit: any;
    public trainingLevelsEdit: any[] = [];
    public studyFormEdit: any[] = [];
    public facultiesEdit: any;
    public workloadTypesEdit: any = [];

    // Data sources
    public reports: any[] =
    [
        {
            name: 'Отчет "Учебная нагрузка кафедры по институту"',
            value: 1
        },
        {
            name: 'Отчет "Учебная нагрузка кафедры по преподавателю"',
            value: 2
        },
        {
            name: 'Отчет "Учебная нагрузка кафедры"',
            value: 3
        }
    ];

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
    };

    public virtual: any = {
        itemHeight: 28,
    };

    constructor(
        private dictService: DictService,
        private educationPlanService: EducationPlanService,
        private departmentService: DepartmentService,
        private notificationService: NotificationsService,
        private personService: PersonService,
        private reportsService: ReportsService) {
    }

    private async getAllYearAndSemestrs() {
        await lastValueFrom(this.educationPlanService.getAvailableSemesters())
            .then((response) => {
                response!.forEach((item) => {
                    this.years.push({name: `${item.year} - ${item.year + 1}`, year: item.year, semester: item.semester});
                    this.semesters.push({name: `${item.semester} семестр`})
                })
                this.years = [...new Set(
                    this.years.filter(
                        (value, index, self) =>
                            index === self.findIndex((t) => t.name === value.name)
                    ))];
                this.semesters = [...new Set(
                    this.semesters.filter(
                        (value, index, self) =>
                            index === self.findIndex((t) => t.name === value.name)
                    ))];
                this.yearEdit = this.years[0];
                this.semesterEdit = this.semesters[0];
            })
    }

    private async getDepartments() {
        await lastValueFrom(this.departmentService.getKafedras()).then(
            (response) => {
                this.kafedras = response!;
                this.kafedraEdit = response![0];
            },
            error => this.notificationService.showError(getErrorMessage(error)))

        await this.getPersons({
          kathedraId: this.kafedraEdit?.id,
          studyYear: this.yearEdit?.year
        });
    }

    private async getStudyForms() {
        await lastValueFrom(this.dictService.getStudyForms()).then(
            (response) => {
                this.studyForms = response!;

                if (this.studyForms && this.studyForms.length > 0) {
                    let item = new DictStudyForm();
                    item.id = "";
                    item.studyFormName = 'Все';
                    item.studyFormSName = 'Все';

                    this.studyForms.unshift(item);

                    this.studyFormEdit = [response![0].id];
                }
            })
    }

    private async getTrainingLevels(){
        await lastValueFrom(this.dictService.getTrainingLevels()).then(
            (response) => {
                this.trainingLevels = response!;
                if (this.trainingLevels) {
                    let item = new DictTrainingLevel();
                    item.id = "";
                    item.name = 'Все';
                    this.trainingLevels.unshift(item);
                    this.trainingLevelsEdit = [ this.trainingLevels[0].id ];
                }
            },
            reason => this.notificationService.showError(getErrorMessage(reason), 5000))
    }

    private async getFaculties() {
        this.faculties = [];
        this.facultiesEdit = null;

        await lastValueFrom(this.departmentService.getFaculties()).then(response => {
            this.faculties = response!;
            this.facultiesEdit = this.faculties[0];
        })
    }

    private async getPersons(filters: {
      kathedraId: string,
      studyYear: number
    }) {
      this.persons = [];
      this.teacherEdit = null;

      if (!filters.kathedraId) return;

      await lastValueFrom(this.personService.getPPSPersons({
        kafedraId: filters.kathedraId,
        studyYear: filters.studyYear,
        withLoad: false
      })).then(
        response => {
          this.persons = response;
          this.teacherEdit = response[0];
        });
    }

    public async filterValueChange(value: any, dataSourceName: any, editItemName: string) {
        (this as any)[`${editItemName}`] = detectTypeForRewrite((this as any)[`${editItemName}`], value);
        if(dataSourceName === 'kafedras' || dataSourceName === 'years') {
            await this.getPersons({
              kathedraId: this.kafedraEdit?.id,
              studyYear: this.yearEdit?.year
            });
        }
    }

    // Выбор отчета
    public onReportChange(value: any) {
        this.selectedReport = value;
    }

    // Кнопка экспорта
    public onExport() {
        if (!this.yearEdit || !this.kafedraEdit || !this.facultiesEdit) return;

        switch (this.selectedReport) {
          case 1:
            this.reportsService.getExcelReportTeachingLoadOfSubdepartmentByInstitute({
              studyYear: this.yearEdit.year,
              kathedraId: this.kafedraEdit.id,
              facultyId: this.facultiesEdit.id
            })
            break;
          case 2:
            this.reportsService.getExcelReportPersonLoadOfSubdepartment({
              studyYear: this.yearEdit.year,
              kafedraId: this.kafedraEdit.id,
              personId: this.teacherEdit.id,
              loadTypeEnum: this.workloadTypesEdit.id
            })
            break;
          case 3:
            this.reportsService.getExcelReportTeachersLoadOfSubdepartment({
              studyYear: this.yearEdit.year,
              kafedraId: this.kafedraEdit.id,
            })
            break;
        }
    }

    async ngOnInit() {
        this.workloadTypesEdit = this.workloadTypes[0];
        await this.getAllYearAndSemestrs();
        await this.getDepartments();
        await this.getStudyForms();
        await this.getTrainingLevels();
        await this.getFaculties();
    }
}

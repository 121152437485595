import { Component, OnInit, Input } from '@angular/core';
import { SearchStudentsService } from 'src/app/services/announcement/search-students.service';
import { FoundStudentsService } from 'src/app/services/announcement/found-students.service';
import { Subscription } from 'rxjs';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-found-students',
  templateUrl: './found-students.component.html',
  styleUrls: ['./found-students.component.scss']
})

export class FoundStudentsComponent implements OnInit{
  @Input() isNewList: boolean = false;

  public gridView!: GridDataResult;
  public selection: any[] = [];
  public students: any = [];
  public quantityStudents: number = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private searchStudentsService: SearchStudentsService,
    private foundStudentsService: FoundStudentsService,
  ) { }

  public addListStudent() {
    if (!this.isNewList){
      this.foundStudentsService.getSelectStudents(
        this.selection,
        this.foundStudentsService.selectStudents$.getValue()
      );
      this.selection = [];
    } else {
     // console.log('нового списка');
      this.foundStudentsService.getNewListStudents(
        this.selection,
        this.foundStudentsService.selectStudentsNewList$.getValue()
      );
      this.selection = [];
    }
  }

  public mySelectionKey(context: RowArgs): string {
    return context.dataItem;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.searchStudentsService.subscriber$.subscribe(data => {
        this.students = data;
        this.quantityStudents = this.students.length;
        this.gridView = {
          data: this.students,
          total: this.students.length
        };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

<announcement-header *ngIf="isAdmin || accessLevel"></announcement-header>
<div class="m-t30">
    <div class="notice"> Создание оповещения</div>
    <div class="message">
        <div class="author"> Заголовок </div>
        <div class="text-author" [ngClass]="{border_red: borderTitleRed, border_none: !borderTitleRed}">
            <kendo-textbox
                        class="textbox"
                        placeholder="Текст заголовка"
                        [clearButton]="true"
                        name="TitleMessage"
                        (valueChange)="onChange($event)"
                        [(ngModel)]="TitleMessage"
            >
            </kendo-textbox>
        </div>
    </div>

    <div class="announcement">
        <div class="author m-b30">  Текст объявления или новости </div>


        <kendo-editor #editor class="m-b30 h-400 montserrat" [(ngModel)]="Content" [iframe]="false"
                      [ngClass]="{border_red: borderEditorRed, border_none: !borderEditorRed}"
                      (valueChange)="valueChangeEditor($event)">
            <kendo-toolbar>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorSubscriptButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSuperscriptButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                <kendo-toolbar-colorpicker  kendoEditorForeColor view="gradient"></kendo-toolbar-colorpicker>
                <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
            </kendo-toolbar>
            <kendo-toolbar>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorAddColumnBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddColumnAfterButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowBeforeButton></kendo-toolbar-button>
                  <kendo-toolbar-button kendoEditorAddRowAfterButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorDeleteColumnButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteRowButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorDeleteTableButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorMergeCellsButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorSplitCellButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
                <kendo-toolbar-buttongroup>
                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                    <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                </kendo-toolbar-buttongroup>
         <!--       <kendo-toolbar-button kendoEditorViewSourceButton  ></kendo-toolbar-button>-->
                <kendo-toolbar-button
                    icon="image"
                    (click)="open()"
                ></kendo-toolbar-button>
            </kendo-toolbar>
        </kendo-editor>

        <my-dialog #upload [editor]="editor" class="w-500"></my-dialog>

        <div class="select-news m-b11">
            <label>
                <input type="radio" name="messages" checked class="real-radio not-display" (change) = "ShowNews()" [value]=0  [(ngModel)]="Type" >
                <span class="custom-radio"></span>
            Отправить как объявление
            </label>
        </div>

        <div class="select-news m-b30">
            <label>
            <input type="radio" name="messages" class="real-radio not-display" (change) = "ShowNews()" [value]=1 [(ngModel)]="Type">
            <span class="custom-radio"></span>
            Отправить как новость
            </label>
        </div>

        <div *ngIf="SendNews == true">
            <div class="unit">
                <div class="search-explanation"> Новый тег</div>
                <div>
                    <kendo-textbox
                        class="input-search m-r15"
                        placeholder="Введите тег"
                        [clearButton]="true"
                        name="title"
                        (keyup.enter)="addTeg()"
                        [(ngModel)]="title"
                        >
                    </kendo-textbox>
                </div>
                <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted white m-r20"
                        size="small"
                        (click) = "addTeg()"
                    >
                добавить
                </button>
            </div>
            <div class="delete-teg">
                <div class="blue_button" *ngFor="let element of tegs" (click)="deleteTeg(element.title)"> {{ element.title }}  x</div>
            </div>
        </div>

        <div class="author m-b20"> Прикрепленный файл </div>

        <kendo-fileselect  #myFileSelect="kendoFileSelect" class="files"
            [restrictions]="myRestrictions"
            [showFileList]="false"
            [multiplie]="true"
            (select)="select($event)"
            >
        </kendo-fileselect>
        <p class="file-properties">До 5 файлов формата .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .gif, .tif, .bmp, .png, .zip, .txt (максимум 20Мб)</p>
        <div class="m-b10">
            <ng-container *ngFor="let file of oldAttachments; let i = index">
                <span class="attachment">
                    {{ file['name'] }} <a href="#!" class="remove-file" (click)="removeOldFile(i)">x</a>
                </span>
            </ng-container>
            <ng-container *ngFor="let file of Attachments; let i = index">
                <span class="attachment">
                    {{ file['name'] }} <a href="#!" class="remove-file" (click)="removeFile(i)">x</a>
                </span>
            </ng-container>
        </div>

        <div class="author m-b11"> Отложить публикацию</div>
        <div class="select-news m-b20 flex center">
            <input type="checkbox" (change) = "ShowDate()" [checked] = "isCheckedDate" />
            <label class="m-l10" (click) = ShowDate()> Указать дату публикации </label>
            <div  [ngClass]="{border_red: borderDateRed, border_none: !borderDateRed}">
                <kendo-datetimepicker *ngIf="SpecifyDate"
                    class="datepicker"
                    format="dd.MM.yyyy HH:mm"
                    (valueChange)="onChangeDate($event)"
                    [(ngModel)]="Postpone"
                    >
                    <kendo-datetimepicker-messages></kendo-datetimepicker-messages>
                </kendo-datetimepicker>
            </div>
        </div>

        <div class="button-control">
            <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted blue"
                    size="small"
                    (click) = "place(messageId, true)"
                   >
            в черновики
            </button>
            <div>
                <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted white m-r20"
                    size="small"
                    (click)="openpopup()"
                   >
                отмена
                </button>
                <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted white m-r20"
                *ngIf="Draft == true || messageId == 0"
                size="small"
                (click)="BackToList()"
               >
                назад
                </button>
                 <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted blue"
                        size="small"
                        *ngIf="SpecifyDate"
                        (click) = "place(messageId, Draft, checkTitleContent)"
                    >
                запланировать публикацию
                </button>
                <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted blue"
                        size="small"
                        *ngIf="!SpecifyDate"
                        (click) = "publish(messageId)"
                    >
                опубликовать
                </button>
            </div>
        </div>
    </div>

    <div kendoDialogContainer></div>
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {Filial} from '../../../models/mfc/dicts/filial.model';


@Injectable({
  providedIn: 'root'
})
export class FilialService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.externalDict}/Filial/`;

  constructor(private http: HttpClient) { }

  public getFilials(): Observable<Filial[]> {
    return this.http.get<Filial[]>(this.baseUrl)
      .pipe(map((data) => data ?? []));
  }

  public getFilial(id: string): Observable<Filial> {
    return this.http.get<Filial>(`${this.baseUrl}${id}`);
  }
}

import {
  DictControllingActionGet,
  DictDisciplineGet,
  DictStudyForm,
  DictTrainingLevel,
  KafedraGet,
  Person
} from "./externals.model";
import {ContingentLoad} from "./disciplineworkloadContingent.model";
import { Guid } from 'guid-typescript';

export class AdditionalWork {
    id!: string;
    eduGroup!: EduGroup;
    dictAdditionalWork!: DictAdditionalWork;
    person?: Person;
    totalStudentsLoad?: number;
}

export class AdditionalWorkAddDto {
    assignmentDepartment!: AssignmentDepartmentAdd;
    eduGroupId!: string;
    dictAdditionalWorksId!: string;
    totalStudentsLoad?: number;
}

export class AdditionalWorkWriteHours {
  additionalWorkId!: string;
  completedHours?: number;
}

export class AssignmentDepartmentAdd {
    id?: string;
    year!: number;
    semestr!: number;
    kafedraId!: string;
}

export class EduGroup {
    id!: string;
    name: string = "";
    studentCount: number | null = null;
    yearAdmission: number = 0;
}

export class DictAdditionalWork {
    id!: string;
    name!: string;
    dictAdditionalWorkType?: DictAdditionalWorkType
}

export class DictAdditionalWorkType {
  id!: string;
  name!: string;
}

export class AvailableDisciplineFilter {
    studyYear?: number;
    semester?: number;
    dictStudyFormId?: string;
    dictTrainingLevelId?: string;
    kafedraId?: string;
}

export class AvailableDiscipline {
    id!: string;
    electiveId?: string;
    name!: string;
    sName!: string
}

export class AssignmentDiscipline {
    id!: string;
    assignmentDepartment!: AssignmentDepartment;
    onAssignment!: boolean;
    principleCalculation!: PrincipleCalculation;
    updatedAt: any;
}

export class AssignmentDepartment {
    id!: string;
    year!: number;
    semestr!: number;
    dictStudyFormId!: string;
    dictTrainingLevel!: string;
    kafedraId!: string;
}

export class PrincipleCalculation {
  id?: string;
  // Повышенный коэффициент на зачёт
  increasedCoefficients!: string[];
  practiceTypeId?: string;
  customSubGroups?: boolean;
  classesBySubGroups?: boolean;
  calculationMethods!: CalculationMethod[];
  calculationMethodCathedralWorks!: CalculationMethodCathedralWorkAdd[];
  amountFlows!: AmountFlow[];
}

export class PrincipleCalculationAdd {
  id?: string;
  // Повышенный коэффициент на зачёт
  increasedCoefficients!: string[];
  // Выездная, плавательная практика
  practiceTypeId?: string | null;
  customSubGroups?: boolean;
  classesBySubGroups?: boolean;
  calculationMethods!: CalculationMethodAdd[];
  calculationMethodCathedralWorks!: CalculationMethodCathedralWorkAdd[];
  amountFlows!: AmountFlowAdd[];
}

export class CalculationMethod {
    id!: string;
    dictTypeWork!: DictTypeWorkGet;
    method!: number;
}

export class CalculationMethodAdd {
    id?: string;
    dictTypeWorkId!: string;
    method!: number;
}

export class CalculationMethodCathedralWorkAdd {
  dictCathedralWorkId!: string;
  method!: number;
}

export class DisciplineAssignment {
    id!: string;
    // Дисциплина
    dictDisciplineId!: string;
    // Выборная дисциплина
    dictElectiveDisciplineId?: string;
    // Дисциплина в сопряжении
    mainDisciplineAssignmentId?: string;
    groups!: DisciplineGroup[];
    disciplines!: AssignmentDepartmentGet[];
    principleCalculation!: PrincipleCalculation;
    isManualAddition!: boolean;
    onAssignment!: boolean;
    studyForm!: DictStudyForm;
    updatedAt!: Date;
}

export class DisciplineAssignmentAdd {
  id?: string;
  // Дисциплина
  educationPlanDisciplineId!: string;
  inConjugation!: boolean;
  semesterNumber!: number;
  dictDisciplineId!: string | Guid;
  electiveDictDisciplineId!: string | Guid | null;
  numberWeek!: number;
  controlActionId!: string | Guid;
  trainingLevelId!: string | Guid | null;
  groups!: DisciplineGroupAdd[];
  studyLoad!: StudyLoadAdd;
}

export class AssignmentDisciplineAdd {
    id?: string;
    assignmentDepartment!: AssignmentDepartmentAdd;
    dictStudyFormId!: string;
    //dictTrainingLevelId!: string;
    dictDisciplineId!: string;
    onAssignment!: boolean;
    principleCalculation!: PrincipleCalculationAdd;
    isManualAddition!: boolean;
    disciplines!: DisciplineAssignmentAdd[];
}

export class DisciplineGroup {
    id?: string;
    eduGroup!: EduGroup;
    groupFlows!: GroupFlow;
}

export class DisciplineGroupAdd {
  id?: string;
  eduGroupId!: string;
  studentCount!: number;
  subGroupCount!: number | null;
  groupFlows!: GroupFlow[];
}

export class SubEduGroup {
    id!: string;
    number!: number
}

export class GroupFlow {
  id?: string;
  dictTypeWorkId: string = "";
  flowNumber: number = 0;
}

export class AmountFlow {
    dictTypeWork!: DictTypeWorkGet
    id?: string;
    amountFlow?: number;
}

export class AmountFlowAdd {
    id?: string;
    dictTypeWorkId!: string;
    amountFlow?: number;
}

export class RecallAssignmentDiscipline {
    assignmentDisciplineId!: string;
}

export class AssignmentDepartmentGet {
  id!: number;
  educationPlan: EducationPlanShortGet = new EducationPlanShortGet();
  educationPlanDiscipline: EducationPlanDisciplineGet = new EducationPlanDisciplineGet();
  disciplineConjugations: string[] | null = null;
  eduGroup: EduGroup = new EduGroup();
  contingentLoad: ContingentLoadShortGet | null = null;
  assignmentDiscipline: AssignmentDisciplineGet | null = null;
  disciplineGroup: DisciplineGroupGet = new DisciplineGroupGet();
  onAssignment: boolean = false;
  groupFlows: GroupFlow | null = null;
  disciplineAssignmentLoad: StudyLoadGet | null = null;

  groupBySemester: number | null = null;
  groupByDiscipline: string | null = null;
  updatedAt: any = null;
}

export class TypeWorkGet {
    dictTypeWork!: DictTypeWorkGet;
    hours!: number;
}

export class StudyLoadGet {
    id!: string;
    hasCourseWork!: boolean;
    hasCourseProject!: boolean;
    numberWeek: number = 0;
    hours!: StudyLoadHourGet[];
}

export class StudyLoadAdd {
    id?: string;
    hasCourseWork!: boolean;
    hasCourseProject!: boolean;
    hours!: StudyLoadHourAdd[];
}

export class StudyLoadHourAdd {
    id?: string;
    dictTypeWorkId!: string;
    numberWorks?: number;
    numberHours?: number;
}

export class StudyLoadHourGet {
    numberWorks?: number;
    numberHours?: number;
    dictTypeWork!: DictTypeWorkGet;
}

export class DictTypeWorkGet {
    id!: string;
    name!: string;
    sName!: string;
}

export class AssignmentDisciplineGet {
  id!: string;
  onAssignment: boolean = false;
  assignmentStatus: number = 0;
  assignmentStatusName: string = "";
  updatedAt: any;
}

export class DisciplineGroupGet {
  id!: string;
  onAssignment: boolean = false;
  students!: number;
  subEduGroups!: number;
}

export class EducationPlanShortGet {
    id!: string;
    name!: string;
    year!: number;
    studyFormId!: DictStudyForm;
    trainingLevelId!: DictTrainingLevel;
}

// Дисциплина учебного плана
export class EducationPlanDisciplineGet {
  id!: string | null;
  dictDiscipline: DictDisciplineGet = new DictDisciplineGet();
  electiveDictDiscipline: DictDisciplineGet | null = null;
  disciplineFullName: string | null = null;
  dictControllingAction: DictControllingActionGet | null = new DictControllingActionGet();
  yearAdmission?: number = 0;
  semesterNumber: number | null = 0;
  kafedra: KafedraGet = new KafedraGet();
  hasCourseWork: boolean = false;
  hasCourseProject: boolean = false;
  numberWeek: number = 0;
  trainingLevel: DictTrainingLevel = new DictTrainingLevel();
  typeWorks: TypeWorkGet[] = [];
}

// Mass operations
export class MassOperationAssignmentAdd {
    educationPlanDisciplineId!: string | null;
    eduGroupId!: string;
    assignmentDisciplineId?: string;
}

export class ContingentLoadShortGet {
  id!: string;
  subGroupCount!: number;
  plannedStudentCount!: number;
  actualStudentCount!: number;
}

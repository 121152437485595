<kendo-dialog *ngIf="active" [width]="500" [height]="600" (close)="closeForm()">
    <kendo-dialog-titlebar>
        {{ isNew ? 'Добавить новую кафедру' : 'Изменить кафедру' }}
    </kendo-dialog-titlebar>

    <form novalidate class="k-form" [formGroup]="editForm">
        <kendo-formfield>
            <kendo-label [for]="outerDataKafedraName" text="Название кафедры"></kendo-label>
            <kendo-textbox formControlName="outerDataKafedraName" #outerDataKafedraName required></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="outerDataKafedraSName" text="Сокращенное название"></kendo-label>
            <kendo-textbox formControlName="outerDataKafedraSName" #outerDataKafedraSName required></kendo-textbox>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label text="Факультет"></kendo-label>
            <kendo-dropdownlist 
            [data]="faculties"
            textField="outerDataFacultyName"
            valueField="outerDataFacultyId"
            [valuePrimitive]="true"
            [formControl]="$any(editForm).controls['outerDataFacultyId']"
            formControlName="outerDataFacultyId" #outerDataFacultyId required>
            </kendo-dropdownlist>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
    </form>

    <kendo-dialog-actions>
        <button kendoButton (click)="onCancel($event)">Отменить</button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave($event)">Сохранить</button>
    </kendo-dialog-actions>
</kendo-dialog>
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SemesterYearsModel } from '../../../models/currentcontrol/semesterYears.model';
import { MassPrintSheetService } from '../../../services/currentcontrol/massPrintSheet.service';
import { Discipline, GetMassPrintSheetData, StatementData, statementStringsData } from '../../../models/currentcontrol/massPrintSheet.model';
import { DictAttestation } from 'src/app/models/currentcontrol/dict.model';
import { DictsService } from 'src/app/services/currentcontrol/dicts.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import { SignatoriesModel } from 'src/app/models/currentcontrol/sheets.model';
import { TreeCommonModel } from "../../../models/currentcontrol/trees.model";
import { TrainingLevelsModel } from "../../../models/currentcontrol/trainingLevels.model";
import { DownloadFile } from "../../../helpers/downloadFile-helper";
import {DateModel} from "../../../models/middlecontrol/date.model";
import {Moment} from "moment";

@Component({
  selector: 'app-currentcontrolprintlist',
  templateUrl: './currentcontrolprintstatement.component.html',
  styleUrls: ['./currentcontrolprintstatement.component.scss'],
})
export class CurrentcontrolprintstatementComponent implements OnInit {
  public signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'Должность',
  } as SignatoriesModel;

  public signerPost: string = 'Должность';
  public semester: SemesterYearsModel | null = null;
  public checkedStatements: string[] = [];
  public contingentDate: Date = new Date;
  public facultyId: string = '';
  public loadingStatement = false;
  private groupList: TreeCommonModel[] = [];
  public dictAttestations: DictAttestation[] = [];
  public attestation: DictAttestation | null = null;
  public statementStrings = statementStringsData;
  public statement = statementStringsData[0];

  constructor(
    private location: Location,
    private massPrintSheetService: MassPrintSheetService,
    private dictsService: DictsService,
    private notificationService: NotificationsService
  ) {}

  async ngOnInit() {
    await this.getAttestations();
  }

  public async getAttestations() {
    await this.dictsService.getAttestations();
    this.dictsService.dictAttestations$.subscribe(
      (attestations) => {
        this.dictAttestations = [...attestations];

        let item = new DictAttestation();
        item.name = '1 и 2 контрольные недели';
        this.dictAttestations.unshift(item);
        this.attestation = this.dictAttestations[0];
    });
  }

  public setGroupList(treeData: TreeCommonModel[]) {
    const findGroup = (value: TreeCommonModel[] | null): TreeCommonModel[] => {
      return value!.reduce((list: TreeCommonModel[], item: TreeCommonModel) => item.isGroup ? [...list, item] : [...list, ...findGroup(item.value)], [])
    }
    this.groupList = findGroup(treeData);
  }

  onChangeSignerPost(post: string) {
    this.signer.postName = post;
  }

  public onPrintStatement(param: number): void {
    const data = this.getData(true);
    if (
      data.disciplines.length &&
      data.signatory.fio !== 'Фамилия Имя Отчество'
    ) {
      this.loadingStatement = true;
      this.massPrintSheetService
        .getMassPrintSheet(param, data)
        .subscribe((response) => {
          response.body?.size && DownloadFile(response);
          this.loadingStatement = false;
        });
    }
  }

  private getSemesterNumByGroupId(groupId: string): number {
    return this.groupList.find(item => String(item.id) === groupId)!.semesterNum!;
  }

  private getData(enableSemesterNum: boolean): GetMassPrintSheetData {
    const disciplines = this.checkedStatements
      .filter((item) => item.split('_').length === 6)
      .map((item) => {
        let discipline: Discipline = {
          eduGroupId: item.split('_').slice(-3)[0],
          disciplineId: item.split('_').slice(-2)[0],
          electiveDisciplineId: item.split('_').slice(-1)[0].trim() || null,
        };
        if (enableSemesterNum) {
          const groupId = item.split('_').slice(0, 4).join('_');
          const semesterNum = this.getSemesterNumByGroupId(groupId);
          discipline = {...discipline, semesterNum};
        }
        return discipline;
      });

    if (!disciplines.length)
      this.notificationService.showError('Ведомости не выбраны');

    const signatory = {
      personId: this.signer.personId,
      fio: this.signer.fio,
      postName: this.signer.postName,
    };

    if (signatory.fio === 'Фамилия Имя Отчество') {
      this.notificationService.showError('Подписант не выбран');
    }

    const contingentDate = this.contingentDate

    return { signatory, disciplines, dictAttestationId: this.attestation!.id, contingentDate};
  }

  public onCancel(): void {
    this.location.back();
  }

  public trainingLevelId?: string = ""

  public onChangeTrainingLevel(list: string[] | null) {
    if(list && list.length == 1)
      this.trainingLevelId = list[0]?.toString();
  }

  public onChangeSemester(value: SemesterYearsModel | null) {
    this.semester = value;
  }

  public onChangeDate(value: Date) {
    this.contingentDate = value;
  }

  public onChangeCheckedStatements(list: string[]) {
    this.checkedStatements = list;
    if (list.length) this.facultyId = list[0].split('_')[0];
  }

  public onChangeAttestation(value: DictAttestation | null) {
    this.attestation = value;
  }

  public onChangeStatement(value: StatementData) {
    this.statement = value;
  }
}

<div class="grid-container">

  <h1>Выбранные студенты</h1>

  <kendo-grid (remove)="removeHandler($event)"
              *ngIf="disableSave"
              [data]="allContingent"
              [reorderable]="true"
              [resizable]="true"
              [sortable]="true"
              style="margin-bottom:10px">
    <!--Номер студенческого-->
    <kendo-grid-column field="studPersons.login"
                       title="Идентификатор студента"
                       [width]="150">
    </kendo-grid-column>
    <!--ФИО-->
    <kendo-grid-column field="studPersons.lastName"
                       title="ФИО"
                       [width]="250">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
        (dataItem?.studPersons.lastName == null ? "" : dataItem.studPersons.lastName) + " " +
        (dataItem?.studPersons.firstName == null ? "" : dataItem.studPersons.firstName) + " " +
        (dataItem?.studPersons.middleName == null ? "" : dataItem?.studPersons.middleName)
        }}
      </ng-template>
    </kendo-grid-column>
    <!--Номер курса-->
    <kendo-grid-column field="courseNum"
                       title="Курс"
                       [width]="90">
    </kendo-grid-column>
    <!--Группа-->
    <kendo-grid-column field="groupName"
                       title="Группа"
                       [width]="150">
    </kendo-grid-column>
    <!--Основа обучения-->
    <kendo-grid-column field="studentBudgetState.isBudget"
                       title="Основа обучения"
                       [width]="170">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
        (dataItem?.studentBudgetState == null ? "" : dataItem.studentBudgetState.isBudget? "Бюджет" : "Договор")
        }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="studentAcademicState"
                       title="Академический статус"
                       [width]="170">
    </kendo-grid-column>
    <!--Средний балл-->
    <kendo-grid-column field="ball"
                       title="Средний балл"
                       [width]="150">
    </kendo-grid-column>
    <kendo-grid-column title="" [width]="70">
      <ng-template kendoGridCellTemplate>
         <span class="alignCells">
         <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="small" rounded="full" class="selection-red-button">
         </button>
         </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <!--DropDown Select Editor-->
  <kendo-dropdownlist [data]="massOperationTypes"
                      [defaultItem]="defaultActionItem"
                      textField="typeName"
                      valueField="enum"
                      [popupSettings]="{popupClass: 'dropdownPopup'}"
                      class="input-selection-main"
                      (valueChange)="valueChange($event)"
                      style="margin-right: 10px;">
  </kendo-dropdownlist>
  <!-------Editors------->
  <!--Академический статус-->
  <dl class="about" *ngIf="editAcademicStates && disableSave">
    <h3>Академический статус</h3>
    <dd>
      <kendo-dropdownlist [data]="dictStudentAcademicStates"
                          textField="academicStateName"
                          valueField="dictStudentAcademicStateExternalId"
                          class="input-selection-academicstate"
                          [(ngModel)]="academicStateId"
                          [valuePrimitive]="true"
                          required>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-dropdownlist>
      <span class="required left">*</span>
    </dd>
  </dl>

  <form [formGroup]="formOrders" class="form-order" *ngIf="editOrders && disableSave">
    <dl class="about">
      <h3>Номер</h3>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="number"
            class="input-selection-data"
            size="small"
            [clearButton]="true"
            placeholder="Номер"
            required
          ></kendo-textbox>
          <span class="required left">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <h3>Дата</h3>
      <dd>
        <kendo-formfield>
          <kendo-datepicker
            format="dd.MM.yyyy"
            class="input-selection-data"
            #orderDate
            [openOnFocus]="orderDate"
            (focus)="onFocus()"
            formControlName="orderDate"
            required>
          </kendo-datepicker>
          <span class="required left">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <h3>Тип</h3>
      <dd>
        <kendo-formfield>
          <kendo-combobox [data]="visibleOrderTypes"
                          textField="typeName"
                          valueField="dictOrderTypeExternalId"
                          #dictOrdersTypeId
                          class="input-selection-data"
                          [valuePrimitive]="true"
                          (selectionChange)="onTypeChange($event)"
                          [kendoDropDownFilter]="filterSettings"
                          [openOnFocus]="dictOrdersTypeId"
                          formControlName="dictOrdersTypeId"
                          required>
          </kendo-combobox>
          <span class="required left">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <h3>Категория</h3>
      <dd>
        <kendo-formfield>
          <kendo-combobox [data]="selectOrderCategories"
                          textField="categoryName"
                          valueField="dictOrdersCategoryExternalId"
                          class="input-selection-data"
                          [valuePrimitive]="true"
                          [kendoDropDownFilter]="filterSettings"
                          #comboboxComponent
                          [openOnFocus]="comboboxComponent"
                          formControlName="dictOrdersCategoryId"
                          required>
          </kendo-combobox>
          <span class="required left">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <h3 style="width: 175px">Срок действия</h3>
      <dd class="ddinline">
        <div class="datebox">
          <kendo-label class="mass-from-to" text="с"></kendo-label>
          <kendo-formfield style="margin-right: 40px;">
            <kendo-datepicker
              format="dd.MM.yyyy"
              placeholder="с"
              #dateBegin
              class="order-date-period"
              [openOnFocus]="dateBegin"
              (focus)="onFocus()"
              formControlName="dateBegin">
            </kendo-datepicker>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
          <kendo-label class="mass-from-to" text="по"></kendo-label>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              placeholder="по"
              #dateEnd
              class="order-date-period"
              [openOnFocus]="dateEnd"
              (focus)="onFocus()"
              formControlName="dateEnd">
            </kendo-datepicker>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
        </div>
        <kendo-formerror *ngIf="dateBegin.value > dateEnd.value">Ошибка: Указана некорректная дата</kendo-formerror>
      </dd>
      <h3 *ngIf="!isHidden(enum.sed)">СЭД</h3>
      <dd *ngIf="!isHidden(enum.sed)">
        <kendo-formfield>
          <kendo-textbox
            formControlName="orderSed"
            class="input-selection-data"
            size="small"
            [clearButton]="true"
            placeholder="{{orderSedPlaceholder}}"
          ></kendo-textbox>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <!--Примечание-->
      <h3>Примечание</h3>
      <dd class="selection-blue-text">
        <kendo-formfield>
          <kendo-textbox
            formControlName="comment"
            class="input-selection-data"
            size="small"
            [clearButton]="true"
            placeholder="Примечание"
          ></kendo-textbox>
        </kendo-formfield>
        <br>
      </dd>
      <h3>Файл приказа</h3>
      <dd class="selection-blue-text">
        <kendo-formfield>
          <div>
            <kendo-fileselect
                class="input-selection-data"
                [restrictions]="restrictions"
                [multiple]="false"
                [ngModelOptions]="{standalone: true}"
                [(ngModel)]="orderFiles">
              <kendo-fileselect-messages
                invalidFileExtension="Недопустимый формат файла.">
              </kendo-fileselect-messages>
            </kendo-fileselect>
          </div>
          <!--<span class="uploadedFile"
            *ngIf='order.fileFullName && !files'
            (click)="getOrderFile(order.ordersExternalId)">
            <span class="k-icon k-i-file"></span>
            <h2 class="uploadedFile__name">{{order.fileFullName}}</h2>
          </span>-->
        </kendo-formfield>
        <br>
      </dd>
    </dl>
    <dd *ngIf="studyLevelError" class="selection-red-text last">
      Добавление информации о приказе возможно в случае, если все студенты находятся на одном уровне образоавния
    </dd>
  </form>
  <!--Смена группы-->
  <dl class="about" *ngIf="editGroups && disableSave">
    <h3>Группа</h3>
    <dd>
      <kendo-combobox [data]="eduGroups"
                      textField="groupName"
                      valueField="eduGroupExternalId"
                      [valuePrimitive]="true"
                      #comboboxGroup
                      placeholder="Выбрать группу"
                      [openOnFocus]="comboboxGroup"
                      class="input-selection-edugroup"
                      [kendoDropDownFilter]="filterSettings"
                      (valueChange)="onGroupChange()"
                      [(ngModel)]="groupId"
                      required>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-combobox>
      <span class="required left">*</span>
    </dd>
    <h3 *ngIf="!isHidden(enum.education_plan)">Учебный план</h3>
    <dd *ngIf="!isHidden(enum.education_plan)">
      <kendo-combobox [data]="educationPlanGroupList"
                      class="input-selection-edugroup"
                      textField="name"
                      valueField="planId"
                      [valuePrimitive]="true"
                      #eduGroupPlan
                      [openOnFocus]="eduGroupPlan"
                      [kendoDropDownFilter]="filterSettings"
                      (valueChange)="planValueChange($event)"
                      placeholder="Учебный план"
                      [(ngModel)]="groupPlanId"
                      required>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-combobox>
      <span class="required left required-position">*</span>
    </dd>
    <h3 style="width: 207px">Направление подготовки</h3>
    <kendo-label class="input-selection-edugroup"> {{(educationPlan?.standardName == null ? "" : educationPlan?.standardName)}} </kendo-label>
    <h3>Форма обучения</h3>
    <kendo-label class="input-selection-edugroup"> {{(educationPlan?.dictStudyFormId == null ? "" : nameStudyForm(educationPlan?.dictStudyFormId))}} </kendo-label>
    <h3>Профиль (программа)</h3>
    <kendo-label *ngIf="!isHidden(enum.education_plan)" class="input-selection-edugroup"> {{(selectedPlan?.educationProgramId == null ? "" : nameEducationProgram(selectedPlan?.educationProgramId))}} </kendo-label>
    <kendo-label *ngIf="isHidden(enum.education_plan)" class="input-selection-edugroup"> {{(educationPlan?.educationProgramId == null ? "" : nameEducationProgram(educationPlan?.educationProgramId))}} </kendo-label>
    <dd *ngIf="sameFacultyError" class="selection-red-text">
      Смена группы возможна в случае, если все студенты находятся на одном факультете
    </dd>
  </dl>

  <!--Смена учебного плана-->
  <div  *ngIf="editPlan && disableSave" class="input-selection-main block">
    <dl class="about">
      <h3>Учебный план</h3>
      <dd>
        <kendo-combobox [data]="educationPlanList"
                        class="input-selection-plan"
                        textField="name"
                        valueField="planId"
                        [valuePrimitive]="true"
                        #plan
                        [openOnFocus]="plan"
                        [kendoDropDownFilter]="filterSettings"
                        (valueChange)="planValueChange($event)"
                        placeholder="Учебный план"
                        [(ngModel)]="planId"
                        required>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-combobox>
        <span class="required left required-position">*</span>
      </dd>
    </dl>
    <div *ngIf="selectedPlan !== undefined">
      <h2 class="plan-detail-label">{{"Направление подготовки: " + (selectedPlan.educationProgram?.educationStandard?.name ? selectedPlan.educationProgram?.educationStandard?.name : "")}}</h2>
      <h2 class="plan-detail-label">{{"Профиль (программа): " + (selectedPlan.educationProgram?.name ? selectedPlan.educationProgram?.name : "") + (selectedPlan.educationProgram?.facultyName ? " (" + selectedPlan.educationProgram?.facultyName + ")" : "")}}</h2>
      <h2 class="plan-detail-label">{{"Форма обучения: " + (selectedPlan.studyFormName ? selectedPlan?.studyFormName : "")}}</h2>
      <h2 class="plan-detail-label last">{{"Год поступления: " + (selectedPlan.yearAdmission ? selectedPlan?.yearAdmission : "")}}</h2>
    </div>

    <dd *ngIf="educationStandardError" class="selection-red-text last">
      Невозможно сменить учебный план. Студенты в выборке учатся на разных направлениях подготовки.
    </dd>

    <dd class="selection-blue-text">Примечание:</dd>
    <dd class="selection-blue-text">Массовая смена учебного плана доступна только в случае, если у всех студентов в выборке одинаковое направление подготовки.</dd><br>

  </div>

  <!--StudyPeriod-->
  <dl class="about" *ngIf="editStudyPeriod && disableSave">
    <h3>Период обучения</h3>
    <dd>
      <kendo-datepicker
        format="dd.MM.yyyy"
        placeholder="с"
        (focus)="onFocus()"
        class="input-selection-studyperiod"
        #dateS
        [openOnFocus]="dateS"
        [(ngModel)]="dateStart">
      </kendo-datepicker>
      <kendo-datepicker
        format="dd.MM.yyyy"
        placeholder="по"
        (focus)="onFocus()"
        class="input-selection-studyperiod"
        #dateF
        [openOnFocus]="dateF"
        [(ngModel)]="dateFinish">
      </kendo-datepicker>
    </dd>
    <dd></dd>
    <dd *ngIf="studyLevelError" class="selection-red-text">
      Изменение периода обучения возможно в случае, если все студенты находятся на одном уровне образования
    </dd>
    <dd *ngIf="!studyLevelError" class="selection-blue-text">Необходимо заполнить хотя бы одно поле</dd>
  </dl>
  <!--Научный руководитель-->
  <dl class="about" *ngIf="editScientificSupervisor && disableSave">
    <h3>Источник финансирования</h3>
    <dd>
      <kendo-dropdownlist
        [data]="budgets"
        textField="studentBudgetName"
        valueField="dictStudentBudgetExternalId"
        name="isBudgetSelected"
        (selectionChange)="onBudgetChange($event)"
        class="input-selection-budget"
        [(ngModel)]="budgetId"
        [valuePrimitive]="true"
        required>
      </kendo-dropdownlist>
      <span class="required left">*</span>
    </dd>
    <h3 *ngIf="isBudgetOrganizationSelected" class="organization-title-budget">Заказчик целевого обучения</h3>
    <dd *ngIf="isBudgetOrganizationSelected">
      <kendo-dropdownlist [data]="organizations"
                          class="input-selection-budget"
                          textField="organizationName"
                          valueField="dictOrganizationExternalId"
                          [valuePrimitive]="true"
                          name="isBudgetOrganization"
                          [(ngModel)]="organizationId">
      </kendo-dropdownlist>
    </dd>
  </dl>
  <!--Перевод с курса на курс-->
  <form [formGroup]="formCourse" class="form-order" *ngIf="editCourseChange && disableSave">
    <dl class="about">
      <dd *ngIf="academicStateError" class="selection-red-text">
        Перевод на следующий курс возможен в случае, если академический статус студента «Учится»
      </dd>
      <dd *ngIf="academicStateError"></dd>
      <dd *ngIf="lastCourseError" class="selection-red-text">
        Перевод на следующий курс возможен в случае, если текущий курс не является последним
      </dd>
      <dd *ngIf="lastCourseError"></dd>
      <dd class="selection-blue-text">
        Примечание
      </dd><dd></dd>
      <dd class="selection-blue-text">
        Если следующий курс предусматривает профилизацию группы, то название группы автоматически изменится при наступлении нового учебного года
      </dd><dd></dd>
    </dl>
  </form>

  <div  *ngIf="editMilitaryStatus && disableSave" class="input-selection-main block">
    <dl class="about">
      <h3>Категория воинского учёта</h3>
      <dd>
        <kendo-combobox [data]="militaryStatuses"
                        class="input-selection-plan"
                        textField="militaryStatusName"
                        valueField="dictMilitaryStatusExternalId"
                        [valuePrimitive]="true"
                        #military
                        [openOnFocus]="military"
                        [kendoDropDownFilter]="filterSettings"
                        placeholder=""
                        [(ngModel)]="militaryStatusId"
                        required>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-combobox>
        <span class="required left required-position">*</span>
      </dd>
    </dl>
  </div>

  <div  *ngIf="editBenefit && disableSave" class="input-selection-main block">
    <dl class="about">
      <h3>Льготы</h3>
      <dd>
        <kendo-combobox [data]="benefits"
                        class="input-selection-plan"
                        textField="benefitName"
                        valueField="dictBenefitExternalId"
                        [valuePrimitive]="true"
                        #benefit
                        [openOnFocus]="benefit"
                        [kendoDropDownFilter]="filterSettings"
                        placeholder=""
                        [(ngModel)]="dictBenefitId"
                        required>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-combobox>
        <span class="required left required-position">*</span>
      </dd>
    </dl>
  </div>

  <div  *ngIf="editScientificPerson && disableSave" class="input-selection-main block">
    <dl class="about">
      <h3>Научный руководитель (куратор)</h3>
      <dd>
        <kendo-combobox [data]="persons"
                        class="input-selection-plan"
                        textField="fio"
                        valueField="personExternalId"
                        [valuePrimitive]="true"
                        #scientificPerson
                        [openOnFocus]="scientificPerson"
                        [kendoDropDownFilter]="filterSettings"
                        placeholder=""
                        [(ngModel)]="scientificPersonId"
                        required>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-combobox>
        <span class="required left required-position">*</span>
      </dd>
    </dl>
  </div>

  <div *ngIf="editTargetEducationCustomer && disableSave" class="input-selection-main block">
    <dl class="about">
      <h3>Заказчик целевого обучения</h3>
      <dd>
        <kendo-combobox [data]="organizations"
                        class="input-selection-plan"
                        textField="organizationName"
                        valueField="dictOrganizationExternalId"
                        [valuePrimitive]="true"
                        #customerOrganization
                        [openOnFocus]="customerOrganization"
                        (valueChange)="onCustomerStudentChange()"
                        [kendoDropDownFilter]="filterSettings"
                        placeholder=""
                        [(ngModel)]="customerOrganizationId"
                        required>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-combobox>
        <span class="required left required-position">*</span>
      </dd>
      <h3 style="width: 207px">ОРГН</h3>
      <kendo-label class="input-selection-edugroup"> {{(organization?.ogrn == null ? "" : organization?.ogrn)}} </kendo-label>
      <h3>ИНН</h3>
      <kendo-label class="input-selection-edugroup"> {{(organization?.inn == null ? "" : organization?.inn)}} </kendo-label>
      <h3>КПП</h3>
      <kendo-label class="input-selection-edugroup"> {{(organization?.kpp == null ? "" : organization?.kpp)}} </kendo-label>
    </dl>
    <dd *ngIf="targetTrainingError" class="selection-red-text last">
      Невозможно сменить заказчика целевого договора. Студенты в выборке должны находиться на целевом обучении.
    </dd>
  </div>

  <div *ngIf="editTargetEducationEmployer && disableSave" class="input-selection-main block">
    <dl class="about">
      <h3>Работодатель по целевому договору</h3>
      <dd>
        <kendo-combobox [data]="organizations"
                        class="input-selection-plan"
                        textField="organizationName"
                        valueField="dictOrganizationExternalId"
                        [valuePrimitive]="true"
                        #organizationEmployer
                        [openOnFocus]="organizationEmployer"
                        (valueChange)="onEmployerStudentChange()"
                        [kendoDropDownFilter]="filterSettings"
                        placeholder="Работодатель"
                        [(ngModel)]="employerOrganizationId"
                        required>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-combobox>
        <span class="required left required-position">*</span>
      </dd>
      <h3>Субъект РФ</h3>
      <dd>
        <kendo-combobox [data]="dictStates"
                        class="input-selection-plan"
                        textField="stateName"
                        valueField="dictStateExternalId"
                        [valuePrimitive]="true"
                        #organizationState
                        [openOnFocus]="organizationState"
                        placeholder="Субъект РФ"
                        [(ngModel)]="dictStateId"
                        required>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-combobox>
        <span class="required left required-position">*</span>
      </dd>
      <h3 style="width: 207px">ОРГН</h3>
      <kendo-label class="input-selection-edugroup"> {{(organization?.ogrn == null ? "" : organization?.ogrn)}} </kendo-label>
      <h3>ИНН</h3>
      <kendo-label class="input-selection-edugroup"> {{(organization?.inn == null ? "" : organization?.inn)}} </kendo-label>
      <h3>КПП</h3>
      <kendo-label class="input-selection-edugroup"> {{(organization?.kpp == null ? "" : organization?.kpp)}} </kendo-label>
    </dl>
    <dd *ngIf="targetTrainingError" class="selection-red-text last">
      Невозможно сменить работодателя по целевому договору. Студенты в выборке должны находиться на целевом обучении.
    </dd>
  </div>

  <button kendoButton
          size="small"
          themeColor="primary"
          [disabled]="!disableSave
                      || (editCourseChange && (academicStateError || lastCourseError))
                      || (editPlan && (educationStandardError || planId == undefined))
                      || (editOrders && studyLevelError)
                      || (editStudyPeriod && studyLevelError)
                      || (editGroups && sameFacultyError)
                      || (editTargetEducationCustomer && targetTrainingError)
                      || (editTargetEducationEmployer && targetTrainingError)"
          (click)="editPlan ? onSavePlanNotification(itemListRef): saveHandler()">
    Применить изменения
  </button>
  <button kendoButton
          size="small"
          [routerLink]="['/contingent']">
    Отмена
  </button>
  <button kendoButton
          size="small"
          class="selection-right-button"
          [routerLink]="['/contingent']"
          [state]="{back}">
    Назад
  </button>
</div>

<kendo-dialog
  title="Пожалуйста, подтвердите"
  *ngIf="planChangedModal"
  (close)="close()"
  [minWidth]="250"
  [width]="450">
  <p class="confirmationText">
    Сменить учебные планы у студентов на выбранный учебный план (за исключением студентов на индивидуальных планах)?<br>
    ВНИМАНИЕ! При смене учебного плана у студентов, сменивших план, необходимо перезачесть оценки!
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="changePlan(false)">Не менять учебный план студентам</button>
    <button kendoButton (click)="changePlan(true)" [primary]="true">
      Сменить учебный план студентам
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<ng-template #itemListRef>
  <p class="dialog-text">Вы действительно хотите сменить учебный план студентов?</p>
  <p class="dialog-text red">Выбранный учебный план является планом ускоренного обучения!</p>
</ng-template>

<div kendoDialogContainer></div>

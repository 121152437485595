import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MilitaryStates } from '../../models/contingent/militarystates.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class MilitarystatesService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.militarystates}`;

  constructor(private http: HttpClient) { }

  //Get all MilitaryStates
  public getAllMilitaryStates(): Observable<MilitaryStates[]> {
    return this.http.get<MilitaryStates[]>(this.baseUrl);
  }

  //Add MilitaryStates
  addMilitaryStates(militarystates: MilitaryStates): Observable<MilitaryStates> {
    return this.http.post<MilitaryStates>(this.baseUrl, militarystates)
      .pipe(catchError(this.handleError));
  }

  //Delete MilitaryStates
  deleteMilitaryStates(id: string): Observable<MilitaryStates> {
    return this.http.delete<MilitaryStates>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit MilitaryStates
  updateMilitaryStates(militarystates: MilitaryStates): Observable<MilitaryStates> {
    return this.http
      .put<MilitaryStates>(this.baseUrl + '/' + militarystates.dictMilitaryStateExternalId, militarystates)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

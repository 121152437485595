import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {
  AllStudentCardDisplaySetting,
  StudentCardDisplaySetting
} from "../../models/contingent/studentcarddisplaysetting.model";

@Injectable({
  providedIn: 'root'
})
export class StudentcarddisplaysettingService {

  public displaySettings$= new BehaviorSubject<StudentCardDisplaySetting[]>([]);

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studentcarddisplaysetting}`;

  constructor(private http: HttpClient) { }

  public getStudentCardDisplaySettings(): Observable<StudentCardDisplaySetting[]> {
    return this.http.get<StudentCardDisplaySetting[]>(this.baseUrl + '/GetStudentCardDisplaySettings');
  }

  public getAllStudentCardDisplaySettings(): Observable<AllStudentCardDisplaySetting[]> {
    return this.http.get<AllStudentCardDisplaySetting[]>(this.baseUrl + '/GetAllStudentCardDisplaySettings');
  }

  public editStudentCardDisplaySetting(request: AllStudentCardDisplaySetting[]): Observable<AllStudentCardDisplaySetting[]> {
    return this.http.post<AllStudentCardDisplaySetting[]>(this.baseUrl + '/EditDisplaySetting/', request)
      .pipe(catchError(this.handleError));
  }

  public isHidden(field: number): boolean {
    return !this.displaySettings$.value.find((x) => x.type === field);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

<education-header></education-header>
<title>Области и отрасли науки</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Область / отрасль науки</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новая область / отрасль науки' : 'Изменить область / отрасль науки' }}
  </h1>

  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" class="addButton" (click)="addHandler()" kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid
            [kendoGridBinding]="scienceBranches"
            [navigable]="true"
            [sortable]="true"
            [filterable]="true"
            [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="scienceBranchCipher" title="Шифр">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.scienceBranchCipher}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="scienceBranchName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
            [column]="column"
            [filter]="filter"
            [showOperators]="false"
            operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.scienceBranchName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" title="Тип стандарта" field="standardTypeShortName">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [data]="standardTypes"
                           [filter]="filter"
                           textField="standardTypeShortName"
                           valueField="standardTypeShortName">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{dataItem.standardTypeShortName}}
        </span>
        </ng-template>
    </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton (click)="editHandler(dataItem)" class="editButton">Изменить</button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="scienceBranchName" [maxlength]="255" #scienceBranchName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Шифр</h2>
          <kendo-textbox formControlName="scienceBranchCipher" [maxlength]="50" #scienceBranchCipher required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
            <h2>Тип стандарта</h2>
            <kendo-dropdownlist
                    [data]="standardTypes"
                    textField="standardTypeShortName"
                    valueField="standardTypeExternalId"
                    [valuePrimitive]="true"
                    formControlName="dictStandardTypeId" required>
            </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>
      <div class="buttons">
        <button kendoButton (click)="onCancel()" type="button" class="cancelButton">Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveScienceBranch()">Сохранить</button>
      </div>
    </form>
  </div>
</div>

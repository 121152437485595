<education-header></education-header>
<title>Тип проведения занятий</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Тип проведения занятий</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новый тип проведения занятий' : 'Изменить тип проведения занятий' }}
  </h1>

  <div class="selectContent" *ngIf="!isOpened">
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Филиал
      </span>
      <kendo-dropdownlist [data]="filials"
                          textField="filialShortName"
                          valueField="filialId"
                          [valuePrimitive]="true"
                          name="filial"
                          (valueChange)="applyFilter()"
                          [(ngModel)]="secondFilter.filialExternalId"
                          [defaultItem]="defaultItemFilial"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
    
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Уровень подготовки
      </span>
      <kendo-dropdownlist [data]="traininglevels"
                          textField="trainingLevelName"
                          valueField="trainingLevelExternalId"
                          [valuePrimitive]="true"
                          name="trainingLevel"
                          [(ngModel)]="secondFilter.trainingLevelExternalId"
                          [defaultItem]="defaultItemTrainingLevel"
                          (valueChange)="applyFilter()"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
    
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Тип стандарта
      </span>
      <kendo-dropdownlist [data]="standardTypes"
                          textField="standardTypeShortName"
                          valueField="standardTypeExternalId"
                          [valuePrimitive]="true"
                          name="standardType"
                          (valueChange)="applyFilter()"
                          [(ngModel)]="secondFilter.standardTypeExternalId"
                          [defaultItem]="defaultItemStandardType"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
  </div>

  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" class="addButton" (click)="addHandler()" kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid
            [data]="lessonsByFilter"
            [kendoGridBinding]="lessonsByFilter"
            [navigable]="true"
            [sortable]="true"
            [filterable]="true"
            [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictLessonTypeName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.dictLessonTypeName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictLessonTypeShortName" title="Сокр.название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictLessonTypeShortName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton (click)="editHandler(dataItem)" class="editButton">Изменить</button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictLessonTypeName" [maxlength]="255" #dictLessonTypeName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="dictLessonTypeShortName" [maxlength]="50" #dictLessonTypeShortName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Актуально</h2>
          <input type="checkbox" kendoCheckBox formControlName="dictLessonTypeIsActual" [maxlength]="200" 
          #dictLessonTypeIsActual />
        </div>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton (click)="onCancel()" type="button" class="cancelButton">Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveLessonType()">Сохранить</button>
      </div>
    </form>
  </div>
</div>
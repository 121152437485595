// TODO: Модель может поменяться
export class LocalSignatory {
  id = '';
  // Категория
  categories = [''];
  // Филиал
  filial = [''];
  // Уровень подготовки
  trainingLevels = [''];
  // Институт/факультет
  faculties = [''];
  // Тип заявки
  applicationTypes = [''];
  // Роль
  roles = [''];
  // Наименование документа
  document = [''];
}
import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { BudgetService } from 'src/app/services/contingent/budget.service';
import { Budget } from 'src/app/models/contingent/budget.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import { DictFilial } from 'src/app/models/contingent/dictfilial.model';
import { DictFilter } from 'src/app/models/contingent/filter.model';
import { DictFilialService } from 'src/app/services/contingent/dictfilial.service';
import { Guid } from 'guid-typescript';
import {budgetTypeEnumList} from "../../../models/contingent/enums/budget-type.enum";

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss']
})
export class BudgetComponent implements OnInit {

  public budgets: Budget[] = [];
  public budgetsByFilter: Budget[] = [];
  public filials: DictFilial[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;
  public secondFilter: DictFilter = {
    dictFilialExternalId: undefined
  }

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  protected readonly budgetTypesEnumList = budgetTypeEnumList;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private budgetService: BudgetService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: ContingentUserAccessService,
    private dictFilialService: DictFilialService,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllBudget()
    this.getAllFilials();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllBudget();
  }

  public nameEnum(enums?: number) {
    return this.budgetTypesEnumList.find((x) => x.value === enums)?.text ?? "";
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.formGroup = formGroup({
      studentBudgetName: "",
      studentBudgetSName: "",
      rName: "",
      isBudget:false
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({ sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.budgetService.addBudget({ ...this.formGroup?.value, ...this.secondFilter })
          .subscribe({
            next:() => {
              this.getAllBudget();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить бюджет');
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.budgetService.updateBudget({ ...this.formGroup?.value, ...this.secondFilter })
          .subscribe({
            next:() => {
              this.getAllBudget();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllBudget() {
    this.budgetService.getAllBudget()
      .subscribe(
        response => {
          this.budgets = response;
          this.budgetsByFilter = response;
          this.saveCurrentFilter();
        });
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
    filialSName: "Не выбрано",
    dictFilialExternalId: null,
  };

  public onValueChange(value: any): void {
    this.saveCurrentFilter();
  }

  saveCurrentFilter() {
    this.budgetsByFilter = [];
    this.budgetsByFilter = this.budgets.filter(x => Guid.parse(x.filialId) == this.secondFilter.dictFilialExternalId);
  }

  public editable?: boolean = true;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.studentBudgetName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.budgetService.deleteBudget(dataItem.dictStudentBudgetExternalId)
            .subscribe({
              next:() => {
                this.getAllBudget();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { dictStudentBudgetId?: any;dictStudentBudgetExternalId?: any; studentBudgetSName?: any; rName?: any;
  studentBudgetName?: any; isBudget?: any;  order?: any; budgetType?: number;}) =>
  new UntypedFormGroup({
    dictStudentBudgetId: new UntypedFormControl(dataItem.dictStudentBudgetId),
    dictStudentBudgetExternalId: new UntypedFormControl(dataItem.dictStudentBudgetExternalId),
    studentBudgetName: new UntypedFormControl(dataItem.studentBudgetName, Validators.required),
    studentBudgetSName: new UntypedFormControl(dataItem.studentBudgetSName),
    rName: new UntypedFormControl(dataItem.rName),
    isBudget: new UntypedFormControl(dataItem.isBudget),
    order: new UntypedFormControl(dataItem.order),
    budgetType: new UntypedFormControl(dataItem.budgetType, Validators.required),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

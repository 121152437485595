<div class="content" [ngSwitch]="tabSelector">
  <mfc-documents *ngSwitchCase="ApplicationTabsEnum.Documents"
                 [documents]="documents.signature"
                 [approvalSettings]="application?.approvalSettings ?? []"
                 [applicationId]="application?.externalId ?? ''">
  </mfc-documents>
  <mfc-documents *ngSwitchCase="ApplicationTabsEnum.ReadyDocuments"
                 [documents]="documents.ready"
                 [applicationId]="application?.externalId ?? ''">
  </mfc-documents>
  <mfc-comments *ngSwitchCase="ApplicationTabsEnum.Comments"></mfc-comments>
  <mfc-application-form-approval-list *ngSwitchCase="ApplicationTabsEnum.List"
                                      [applicationId]="application?.externalId ?? ''"
                                      [approvalSettings]="application?.approvalSettings ?? []"
                                      [editable]="editable">
  </mfc-application-form-approval-list>
  <mfc-dynamic-properties *ngSwitchCase="ApplicationTabsEnum.Dynamic"
                          [groups]="dynamicTab?.groupProperties"
                          [isAdd]="!status"
                          [index]="index"
                          [editable]="editable">
  </mfc-dynamic-properties>
</div>

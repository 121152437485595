<mfc-settings></mfc-settings>

<div>
  <h1>Литеры подразделений</h1>
  <kendo-grid
    [kendoGridBinding]="departmentLitera"
    [pageable]="pagerSettings"
    [pageSize]="10"
    [sortable]="true"
    (add)="addHandler($event)"
    (cellClick)="editHandler($event)"
    (remove)="removeHandler($event)"
    class="grid-data">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand size="small" themeColor="success" *ngIf="!isEditMode" icon="plus">Добавить</button>
      <div *ngIf="isEditMode">
        <button kendoButton size="small" (click)="closeEditor()">Отмена</button>
        <button kendoButton [disabled]="!form?.valid" size="small" themeColor="primary" (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column title="№" [width]="100">
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        {{ rowIndex > -1 ? rowIndex + 1 : '' }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="dictionaries" field="filial.name" title="Филиал">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-dropdownlist
          [data]="dictFilials"
          [valuePrimitive]="true"
          (valueChange)="onFilialChange($event)"
          [formControl]="formGroup.get('filial')"
          textField="name"
          valueField="id">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
            {{dataItem.length}} выбрано
          </ng-template>
        </kendo-dropdownlist>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <filter-combobox [filter]="filter"
                         [data]="dictFilials"
                         [gridField]="column.field"
                         textField="name"
                         valueField="name">
        </filter-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="dictionaries" field="trainingLevels" title="Уровень подготовки">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
        <ng-container *ngIf="dataItem[column.field].length; else allTemplate">
          <span *ngFor="let item of dataItem[column.field]; let isLast=last">
            {{ item.name }}{{ isLast ? '' : ', ' }}
          </span>
        </ng-container>
        <ng-template #allTemplate>
          Все
        </ng-template>
      </ng-template>
      <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup">
        <kendo-multiselect
          kendoMultiSelectSummaryTag
          [formControl]="formGroup.get(column.field)"
          [data]="dictTrainingLevels"
          [valuePrimitive]="true"
          (valueChange)="onTrainingLevelsChange($event)"
          textField="name"
          valueField="id">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
            {{dataItem.length}} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <!--TODO: Фильтр мультиселекта не работает-->
      <ng-template kendoGridFilterCellTemplate let-column="column">
        <filter-multiselect [data]="dictTrainingLevels"
                            [gridField]="column.field"
                            textField="name"
                            valueField="name">
        </filter-multiselect>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="dictionaries" field="faculty.name" title="Факультет">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-dropdownlist
          [formControl]="formGroup.get('facultyId')"
          [data]="filteredFaculties"
          [valuePrimitive]="true"
          #facutlyDropdown
          valueField="id"
          textField="name">
        </kendo-dropdownlist>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <filter-combobox [filter]="filter"
                         [data]="dictFaculties"
                         [gridField]="column.field"
                         textField="name"
                         valueField="name">
        </filter-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="dictionaries" field="name" title="Название">
      <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup">
        <kendo-textbox [formControl]="formGroup.get(column.field)"
                       [clearButton]="true">
        </kendo-textbox>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="editable" headerClass="dictionaries" [width]="70">
      <ng-template kendoGridCellTemplate>
        <button kendoGridRemoveCommand
                themeColor="secondary"
                icon="delete"
                fillMode="flat"
                size="small"
                rounded="full">
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

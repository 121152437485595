import {Component, OnInit} from "@angular/core";

@Component({
    selector: 'app-blankshome',
    templateUrl: './blanks-home.component.html',
    styleUrls: ['./blanks-home.component.scss']
  })
  
export class BlanksHomeComponent implements OnInit {

  constructor(

  ) { }

  public ngOnInit(): void {
  }
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {BehaviorSubject} from "rxjs";

export interface Environment{
  production: Window|boolean|string;
  demo: Window|boolean|string;
  searchBotDisallow: Window|boolean;
  apiEndpoint: Window|string;
  allowedDomains: any;
  trustedNetClientId: Window|string;
  authEndPoint: Window|string;
  isDayOffEndpoint: string;
  educationApiEndpoint: Window|string;
  lkPersonApiEndpoint: Window|string;
  lkStudentApiEndpoint: Window|string,
  fileServerEndpoint: Window|string,
  announcementApiEndpoint: Window|string,
  classroomApiEndpoint: Window|string,
  cardsApiEndpoint: Window|string,
  daysInWeek: number,
  creditUnitsValue: number,
  supportEmail: Window|string,
  maidenName: Window|string,
  orderSed: Window|string,
  headerTitle: any;
  apiPaths: any;
  services: any;
  menuItems: any;
  announcement: any;
  contingent: any;
  oferta: any;
  publications: any;
  loginGenerationFirst: Window|string,
  loginGenerationSecond: Window|string,
  studNumberGenerationFirst: Window|string,
  studNumberGenerationSecond: Window|string,
  has_switch_user: Window|boolean,
}

export const environment:Environment = {
  production: window[<any>"env"][<any>"prod"] || false,
  demo: window[<any>"env"][<any>"demo"] || false,
  searchBotDisallow: window[<any>"env"][<any>"searchBotDisallow"] || false,
  apiEndpoint: window[<any>"env"][<any>"apiUrl"],
  allowedDomains: [window[<any>"env"][<any>"allowedDomains"]],
  trustedNetClientId: window[<any>"env"][<any>"clientId"],
  authEndPoint: window[<any>"env"][<any>"authUrl"],
  educationApiEndpoint: window[<any>"env"][<any>"apiUrl"],
  lkPersonApiEndpoint: window[<any>"env"][<any>"apiUrl"],
  lkStudentApiEndpoint: window[<any>"env"][<any>"apiUrlLk"],
  fileServerEndpoint: window[<any>"env"][<any>"apiUrlFile"],
  announcementApiEndpoint: window[<any>"env"][<any>"apiUrl"],
  classroomApiEndpoint: window[<any>"env"][<any>"apiUrl"],
  cardsApiEndpoint: window[<any>"env"][<any>"apiUrl"],
  isDayOffEndpoint: `https://isdayoff.ru/api/getdata?year=`,
  daysInWeek: 6,
  creditUnitsValue: 1.5,
  supportEmail: window[<any>"env"][<any>"supportEmail"],
  maidenName: window[<any>"env"][<any>"maidenName"],
  orderSed: window[<any>"env"][<any>"orderSed"],
  has_switch_user: window[<any>"env"][<any>"has_switch_user"] || true,

  loginGenerationFirst: window[<any>"env"][<any>"loginGenerationFirst"],
  loginGenerationSecond: window[<any>"env"][<any>"loginGenerationSecond"],
  studNumberGenerationFirst: window[<any>"env"][<any>"studNumberGenerationFirst"],
  studNumberGenerationSecond: window[<any>"env"][<any>"studNumberGenerationSecond"],

  headerTitle: {
    full: window[<any>"env"][<any>"fullHeader"],
    short: window[<any>"env"][<any>"shortHeader"]
  },
  services: window[<any>"env"][<any>"services"],
  menuItems: window[<any>"env"][<any>"menuItems"],
  announcement: {
    base: "announcement",
    maxFileSize: window[<any>"env"][<any>"announcement_maxFileSize"] || 20971520,//20971520,
    commentMaxFileSize: window[<any>"env"][<any>"announcement_commentMaxFileSize"] || 5242880,//5242880,
  },
  contingent: {
    maxFileSize: window[<any>"env"][<any>"contingent_maxFileSize"] || 4194304//4194304,
  },
  oferta: {
    ofertaFileAllowedExtensions: window[<any>"env"][<any>"oferta_file_allowed_extensions"] || [".pdf", ".doc", ".docx"],
  },
  publications: {
    maxFileSize: window[<any>"env"][<any>"publications_maxFileSize"] || 10485760
  },
  apiPaths: {
    academicState: 'contingent/d/studentacademicstate',
    budget: 'contingent/d/studentbudget',
    budgetsubcategory: 'contingent/d/studentbudgetsubcategory',
    budgetcategory: 'contingent/d/studentbudgetcategory',
    militarydoctypes: 'contingent/d/militarydoctypes',
    militaryranks: 'contingent/d/militaryranks',
    militarystates: 'contingent/d/militarystates',
    militarystatuses: 'contingent/d/militarystatuses',
    militarystockcategories: 'contingent/d/militarystockcategories',
    militarycommissariat: 'contingent/d/militarycommissariat',
    militaryformtypes: 'contingent/d/militaryformtypes',
    militaryformprinttemplate: 'contingent/militaryformprinttemplate',
    identificationtype: 'contingent/d/identificationtype',
    dictlanguages: 'contingent/d/languages',
    dictstate: 'contingent/d/state',
    massoperationtypes: 'contingent/d/massoperationtypes',
    massoperationjournal: 'contingent/massoperationjournal',
    dictinstitution: 'contingent/d/institution',
    dictinstitutiontype: 'contingent/d/institutiontype',
    dictsportcategory: 'contingent/d/sportcategory',
    dictsporttype: 'contingent/d/sporttype',
    dicthealth: 'contingent/d/health',
    otherdocumenttype: 'contingent/d/otherdocumenttype',
	  dictcodearticle: 'contingent/d/codearticle',
    dictbenefit: 'contingent/d/benefit',
    phonetypes: 'contingent/d/phonetypes',
    commondict: 'contingent/d/common',
    educationdoctypes: 'contingent/d/educationdoctypes',
    citizenship: 'contingent/d/citizenship',
    citizenshiptype: 'contingent/d/citizenshiptype',
    familymemberstatuses: 'contingent/d/familymemberstatuses',
    citytypes: 'contingent/d/citytypes',
    emailtypes: 'contingent/d/emailtypes',
    employmentstatus: 'contingent/d/employmentstatus',
    complaintmanagementstatus: 'contingent/d/complaintmanagementstatus',
    dicttraininglevels: 'contingent/d/traininglevels',
    faculty: 'contingent/d/faculty',
    paymentstate: 'contingent/d/paymentstate',
    streettypes: 'contingent/d/streettypes',
    ordertypes: 'contingent/d/ordertypes',
    contingentOrganization: 'contingent/d/organization',
    dictadditionalfields: 'contingent/d/additionalfields',
    dictadditionalstudentfields: 'contingent/d/additionalstudentfields',
    languagelevels: 'contingent/d/languagelevels',
    studyformcont: 'contingent/d/studyform',
    dicthostel: 'contingent/d/hostel',
    studperson: 'contingent/studperson',
    filial: 'contingent/d/filial',
    orderscategory: 'contingent/d/orderscategory',
    dictspravkatype: 'contingent/d/spravkatype',
    dictmaritalstatus: 'contingent/d/maritalstatus',
    dictcertificatepurpose: 'contingent/d/certificatepurpose',
    dictdocumentnames: 'contingent/d/documentnames',
    displaysettingcategory: 'contingent/d/displaysettingcategory',
    employerpost: 'contingent/d/employerpost',
    localSignatoryRole: 'contingent/d/localsignatoryrole',
    document: 'contingent/document',
    additionalfields: 'contingent/additionalfields',
    additionalstudentfields: 'contingent/additionalstudentfields',
    student: 'contingent/student',
    studenthistory: 'contingent/studenthistory',
    spravka: 'contingent/spravka',
    medicalcertificate: 'contingent/medicalcertificate',
    orders: 'contingent/orders',
    educations: 'contingent/education',
    contPortfolio: 'contingent/portfolio',
    studentsemestr: 'contingent/studentsemestr',
    edugroupsemester: 'contingent/edugroupsemester',
    casename: 'contingent/casename',
    ordersstudyperiod: 'contingent/ordersstudyperiod',
    studentacademicstate: 'contingent/studentacademicstate',
    studentbudgetstate: 'contingent/studentbudgetstate',
    person: 'contingent/person',
    studplan: 'contingent/studplan',
    edugroup: 'contingent/edugroup',
    studedugroup: 'contingent/studedugroup',
    addresses: 'contingent/addresses',
    emails: 'contingent/emails',
    languages: 'contingent/languages',
    educationdocs: 'contingent/educationdocs',
    educationdoclanguages: 'contingent/educationdoclanguages',
    identifications: 'contingent/identifications',
    military: 'contingent/military',
    militaryenlistment: 'contingent/militaryenlistment',
    militarydocuments: 'contingent/militaryregistrationdocument',
    militaryform: 'contingent/militaryform',
    militarystudentinfo: 'contingent/studentinfo',
    militaryformtwo: 'contingent/militaryformtwo',
    militaryformfour: 'contingent/militaryformfour',
    militaryformfive: 'contingent/militaryformfive',
    militaryformten: 'contingent/militaryformten',
    phones: 'contingent/phones',
    socialnetworks: 'contingent/socialnetworks',
    familymembers: 'contingent/familymembers',
    contingent: 'contingent',
    contingentpage: 'contingent/page',
    department: 'contingent/department',
    studentmark: 'contingent/studentmark',
    contingentUserAccess: 'contingent/useraccess',
    cardsUserAccess: 'cards/useraccess',
    studentcarddisplaysetting: 'contingent/displaysetting',
    targetedtraining: 'contingent/targettraining',
    contingentsetting: 'contingent/setting',
    dict: 'dict',
    otherDocument: 'contingent/otherdocument',

    controlaction: 'd/controlaction',
    discipline: 'd/discipline',
    disciplinetype: 'd/disciplinetype',
    studytechnology: 'd/studytechnology',
    qualification: 'd/qualification',
    studyleveldict: 'd/studylevel',
    studyformtype: 'd/studyformtype',
    studyform: 'd/studyform',
    traininglevel: 'd/traininglevel',
    standardType: 'd/standardType',
    cdPerson:'d/person',
    globalSignatory:'d/signatory',
    dictPost: 'd/post',
    kafedra: 'd/kafedra',
    signatoryRole: 'd/signrole',
    personStatus: 'd/employeestatus',
    holidaysandweekends: 'd/holidaysandweekends',
    mark: 'd/mark',
    dictfilial: 'd/filial',
    organization: 'd/organization',
    educationalorganizationname: 'd/educationalorganizationname',

    dictIdentificationNumber: 'lkperson/dictidentificationnumber',
    dictSocialNetwork: 'lkperson/dictsocialnetwork',
    lkPerson: 'lkperson/profile',
    lkPersonEducation: 'lkperson/education',
    lkPersonQualificationAdvancement: 'lkperson/qualificationadvancement',
    lkPersonScientificDegree: 'lkperson/scientificdegree',
    lkPersonSalary: 'lkperson/salary',
    lkPersonPhoneNumber: 'lkperson/phonenumbers',
    lkPersonEmail: 'lkperson/email',
    lkPersonAcademicStatus: 'lkperson/academicstatus',
    lkPersonExperience: 'lkperson/experience',
    lkSocialNetworks: 'lkperson/social',
    lkPersonIdentificationNumber: 'lkperson/authorcodes',
    lkPersonPayslip: 'lkperson/payslip',
    admin: 'lkperson/admin',

    externalAddressSearch: 'address',

    //Portfolio: 'portfolio',

    session: {
      marks: 'session/d/marks',
      studyforms: 'session/d/studyforms',
      studylevels: 'session/d/studylevels',
      traininglevels: 'session/d/traininglevels',
      persons: 'session/d/persons',
      faculty: 'session/d/faculty',
      kafedra: 'session/d/kafedra',
      personPosts: 'session/d/personposts',
      semesteryears: 'session/d/semesteryears',
      sheetinfo: 'session/sheetinfo',
      signatories: 'session/signatories',
      edugroupsession: 'session/edugroupsession',
      signatory: 'session/signatory',
      sheetheader: 'session/sheetheader',
      sheetstudentlist: 'session/sheetstudentlist',
      trees: 'session/trees',
      empty: 'session/sheetprint/empty',
      filled: 'session/sheetprint/filled',
      filleddiscipline: 'session/sheetprint/discipline/filled',
      info: 'session/sheetdiscipline/info',
      students: 'session/sheetdiscipline/students',
      save: 'session/sheetdiscipline/save',
      changestatus: 'session/sheetdiscipline/changestatus',
      printexamlist: 'session/sheetdiscipline/printexamlist',
      changemarkdate: 'session/mark/massmarkdatechange',
      addmark: 'session/mark/addstudentmark',
      changeprojectname: 'session/mark/markprojectnamechange',
      retakes: 'session/mark/getstudentmarksretake',
      studentmarks: 'session/mark/getstudentmarks',
      updatestudentmarks: 'session/mark/updatestudentmark',
      examiner: 'session/examiner',
      reasons: 'session/d/markchangereasons',
      generalSheet: 'session/d/generalsheet',
      session: 'session/studentsession/getstudentsessions',
      savesession: 'session/studentsession/savestudentsession',
      massprint: 'session/massprintsheet/getmassprintsheet',
      massprintlist: 'session/massprintsheet/getmassprintsheetlist',
      lastsession: 'session/massprintsheet/getlastsessionresult',
      allsession: 'session/massprintsheet/getallsessionresult',
      universityreport: 'session/massprintsheet/getuniversityreport',
      useraccess: 'session/useraccess',
      globalSignatory: 'session/globalsignatory',
      localSignatory: 'session/localsignatory',
      controlaction: 'session/d/controlaction',
      statementSettingsCategory: 'session/d/statementSettingsCategory',
      settings: 'session/settings',
      localSignatoryRole: 'session/d/localsignatoryrole',
      filials: 'session/d/filial'
    },
    currentcontrol: {
      marks: 'currentcontrol/d/marks',
      studyforms: 'currentcontrol/d/studyforms',
      studylevels: 'currentcontrol/d/studylevels',
      traininglevels: 'currentcontrol/d/traininglevels',
      persons: 'currentcontrol/d/persons',
      faculty: 'currentcontrol/d/faculty',
      kafedra: 'currentcontrol/d/kafedra',
      personPosts: 'currentcontrol/d/personposts',
      semesteryears: 'currentcontrol/d/semesteryears',
      sheetinfo: 'currentcontrol/sheetinfo',
      signatories: 'currentcontrol/signatories',
      edugroupsession: 'currentcontrol/edugroupsession',
      signatory: 'currentcontrol/signatory',
      sheetheader: 'currentcontrol/sheetheader',
      sheetstudentlist: 'currentcontrol/sheetstudentlist',
      trees: 'currentcontrol/trees',
      empty: 'currentcontrol/sheetprint/empty',
      filled: 'currentcontrol/sheetprint/filled',
      filleddiscipline: 'currentcontrol/sheetprint/discipline/filled',
      info: 'currentcontrol/sheetdiscipline/info',
      students: 'currentcontrol/sheetdiscipline/students',
      save: 'currentcontrol/sheetdiscipline/save',
      changestatus: 'currentcontrol/sheetdiscipline/changestatus',
      changemarkdate: 'currentcontrol/mark/massmarkdatechange',
      addmark: 'currentcontrol/mark/addstudentmark',
      changeprojectname: 'currentcontrol/mark/markprojectnamechange',
      retakes: 'currentcontrol/mark/getstudentmarksretake',
      studentmarks: 'currentcontrol/mark/getstudentmarks',
      updatestudentmarks: 'currentcontrol/mark/updatestudentmark',
      examiner: 'currentcontrol/examiner',
      reasons: 'currentcontrol/d/markchangereasons',
      attestations: 'currentcontrol/d/attestations',
      criterialmark: 'currentcontrol/d/criterialevels',
      useraccess: 'currentcontrol/useraccess',
      massprint: 'currentcontrol/massprintsheet/getmassprintsheet',
      globalSignatory: 'currentcontrol/globalsignatory',
      localSignatory: 'currentcontrol/localsignatory'
    },
    education: {
      controlaction: 'projecting/d/controlaction',
      standard: 'projecting/estandard',
      oop: 'projecting/eprogram',
      educationPrograms: 'projecting/eprogram',
      educationPlan: 'projecting/eplan',
      planDiscipline: 'projecting/eplan/discipline',
      discipline: 'projecting/d/disciplines',
      disciplineTypes: 'projecting/d/disciplinetypes',
      competenceMatrix: 'projecting/matrix',
      processSchedule: 'projecting/schedule',
      standardtype: 'projecting/d/EducationStandardTypes',
      studylevel: 'projecting/d/EducationLevels',
      studyform: 'projecting/d/StudyForms',
      studytechnology: 'projecting/d/studytechnologies',
      qualification: 'projecting/d/Qualifications',
      faculty: 'projecting/d/Faculty',
      kafedra: 'projecting/d/Department',
      trainingLevel: 'projecting/d/TrainingLevels',
      scienceBranch: 'projecting/d/sciencebranch',
      dictTypeWorkDisciplineAdditional: 'projecting/d/dicttypeworkdisciplineadditional',
      dictcycle: 'projecting/d/DictCycle',
      dictCycleType: 'projecting/d/DictCycleType',
      dictComponent: 'projecting/d/DictComponent',
      dictComponentType: 'projecting/d/DictComponentType',
      dictControlAction: 'projecting/d/controlactions',
      dictLessonType: 'projecting/d/DictLessonType',
      dictLessonForm: 'projecting/d/DictLessonForm',
      dictAcceleratedStudyBase: 'projecting/d/DictAcceleratedStudyBase',
      dictFormDesignerDataType: 'projecting/d/DictFormDesignerDataType',
      dictWorkCategorySchedule: 'projecting/d/DictWorkCategorySchedule',
      dictWorkScheduleStudyProcessType:
        'projecting/d/DictWorkScheduleStudyProcessType',
      standardLimitation: 'projecting/d/standardlimitation',
      dictStandardLimitation: 'projecting/d/dictstandardlimitation',
      dictGroupStandardLimitation: 'projecting/d/dictgroupstandardlimitation',
      dictWorkStudyPlanType: 'projecting/d/DictWorkStudyPlanType',
      dictOrganization: 'projecting/d/organization',
      eStandardTypes: 'dict/educationStandardTypes',
      dictEducationLevel: 'dict/EducationLevels',
      dictQualification: 'dict/qualifications',
      dictTrainingLevel: 'dict/trainingLevels',
      educationSchedule: 'projecting/schedule',
      person: 'projecting/d/Person',
      useraccess: 'projecting/useraccess',
      dictUserAccess: 'd/useraccess',
      globalSignatory: 'projecting/globalsignatory',
      localSignatory: 'projecting/localsignatory',
      filial: 'projecting/filial',
      dictEnlargedGroupSpecialization: 'projecting/d/DictEnlargedGroupSpecialization',
    },
    announcement: {
      base: "announcement",
    },
    classroom: {
      base: "classroom",
      dict: "classroom/d",
      userAccess: "classroom/useraccess"
    },
    contingentPath: {
      globalSignatory: 'cont/globalsignatory',
      localSignatory: 'cont/localsignatory'
    },
    dicts: {
      departmens: 'dicts/department'
    },
    disciplineWorkload: {
      contingentLoad: 'disciplineworkload/contingentload',
      assignments: 'disciplineworkload/assignment',
      department: 'disciplineworkload/department',
      dict: 'disciplineworkload/dict',
      edugroup: 'disciplineworkload/edugroup',
      person: 'disciplineworkload/person',
      personSalary: 'disciplineworkload/personsalary',
      educationPlan: 'disciplineworkload/educationplan',
      additionalWork: 'disciplineworkload/additionalwork',
      cathedralLoad: 'disciplineworkload/cathedralload',
      teacherLoad: 'disciplineworkload/teacherload',
      userAccess: 'disciplineworkload/useraccess',
      reports: 'disciplineworkload/reports',
      settings: 'disciplineworkload/settings',
    },
    portfolio: {
      dict: "portfolio/dict",
      document: "portfolio/document",
      achievement: "portfolio/achievement"
    },
    publications: {
      main: 'publications',
      author: 'publications/author',
      summaryInfo: 'publications/summaryInfo',
      reports: 'publications/reports',
      dict: 'publications/dict',
      userAccess: 'publications/useraccess',
      person: 'publications/person',
      department: 'publications/department',
      file: 'publications/file'
    },
    cards: {
      cardRequest: 'cardrequest',
      cardReestr: 'cardreestr',
      salaryProject: 'salaryproject',
    },
    reportDesigner: {
      base: "reportdesigner"
    },
    reportViewer: {
      base: "reportviewer",
      paths: {
        templates: "template",
      }
    },
    gia: {
      dictPrintTemplateCategory: 'gia/d/dictprinttemplatecategory',
      printTemplate: 'gia/printtemplate',
      stateExamCommission: 'gia/stateexamcommission',
      userAccess: 'gia/useraccess',
      dict: 'gia/dict',
      person: 'gia/person',
      department: 'gia/department',
      dictStateExamCommissionType: 'gia/dictstateexamcommissiontype',
      dictStateExamCommissionMemberRole: 'gia/d/dictstateexamcommissionmemberrole',
      localSignatoryRole: 'gia/d/localsignatoryrole',
      signatory: 'gia/signatory',
      education: 'gia/education',
      diploma: 'gia/diploma',
      dictdiploma: 'gia/dictdiploma',
      diplomaPrint: 'gia/diplomaprint',
      diplomaBlank: 'gia/blank',
      admissionOrder: 'gia/admissionorder',
      releaseOrder: 'gia/releaseorder',
      student: 'gia/student',
      settings: 'gia/settings'
    },
    oferta: {
      userSignature: 'oferta/usersignature',
      person: 'oferta/person',
      accessRight: 'oferta/accessright',
      dictRegulationType: 'oferta/d/dictregulationtype',
      regulation: 'oferta/regulation',
      regulationFile: 'oferta/regulationfile',
    },
    license: {
      license: 'license/license',
    },
    mfc: {
      applicationDoc: 'mfc/applicationdoc',
      dict: 'mfc/dict',
      externalDict: 'mfc/dict/external',
      application: 'mfc/application',
      personApplication: 'mfc/application',
      applicationConstructor: 'mfc/applicationConstructor',
      commonApplicationConstructor: 'mfc/common/applicationconstructor',
      userAccess: 'mfc/accessRight',
      user: 'mfc/user',
      stamp: 'mfc/stamp',
      person: 'mfc',
      applicationComments: 'mfc/application/comments',
      cryptoArm: 'mfc/CryptoArm'
    },
    diplomablanks: {
      home: 'diplomablanks',
      dict: 'diplomablanks/d',
      blank: 'diplomablanks/blanks',
      access: 'diplomablanks/accessright',
    }
  }
};

// Для вызова события на получение данных в Grid
export const getData$ = new BehaviorSubject<boolean>(false);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

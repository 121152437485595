export const staticDictEnum = [
    {id: 1, name: 'Оценки (для зачетов)', isHidden: false},
    {id: 2, name: 'Оценки (для экзаменов)', isHidden: false},
    {id: 3, name: 'Типы дипломов', isHidden: false},
    {id: 4, name: 'Квалификация', isHidden: false},
  //  {id: 5, name: 'Решение', isHidden: false},
]

export enum TypeDictsEnum
{
  testMark = 1,
  examMarks = 2,
  typeDiplomas = 3,
  qualification = 4,
}

export const TypesDicts = TypeDictsEnum;
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { Faculty } from 'src/app/models/education/faculty.model';
import { Kafedra } from 'src/app/models/education/kafedra.model';
import { FacultyService } from 'src/app/services/education/Faculty/faculty.service';
import { KafedraService } from 'src/app/services/education/Kafedra/kafedra.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-kafedra',
  templateUrl: './kafedra.component.html',
  styleUrls: ['./kafedra.component.css']
})
export class KafedraComponent implements OnInit {

  public view: Observable<GridDataResult> | undefined;
  public gridStyle: string = 'grid-default';
  public faculties!: Faculty[];
  public kafedras!: Kafedra[];

  constructor(private kafedraService: KafedraService,
    private notificationService: NotificationsService,
    private facultyService: FacultyService) { }


  ngOnInit(): void {
    this.getAllKafedras();
    this.getAllFaculties();
  }

  public formGroup: FormGroup | undefined;
  public active = false;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;

  public editDataItem!: Kafedra | undefined;

  @Input() public isNew = false;
  @Input() public set model(kafedra: Kafedra) {
    this.editForm.reset(kafedra);

    // toggle the Dialog visibility
    this.active = kafedra !== undefined;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<Kafedra> = new EventEmitter();

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    outerDataKafedraId: new FormControl(),
    outerDataKafedraName: new FormControl(),
    outerDataKafedraSName: new FormControl(),
    outerDataFacultyId: new FormControl(),
    outerDataFacultyForm: new FormControl(),
  });

  //Getting all entries from kafedra
  public getAllKafedras() {
    this.kafedraService.getAllKafedras()
      .subscribe(
        response => {
          this.kafedras = response;
        },
        error => {
          this.notificationService.showError("Не удалось загрузить список кафедр")
        }
      );
  }

  // Getting all entries from Faculty
  public getAllFaculties() {
    this.facultyService.getAllFaculties()
      .subscribe(
        response => {
          this.faculties = response;
        }
      );
  }
  public addHandler(): void {
    this.editDataItem = new Kafedra();
    this.isNew = true;
  }

  public editHandler(args: AddEvent): void {
    this.editDataItem = args.dataItem;
    this.isNew = false;
  }

  public onCancel(e: PointerEvent): void {
      e.preventDefault();
      this.closeForm();
  }

  public closeForm(): void {
      this.active = false;
      this.cancel.emit();
  }

  public saveHandler(kafedra: Kafedra): void {
    this.kafedraService.updateKafedra(kafedra);
    this.editDataItem = undefined;
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.getAllKafedras();
  }

  public cancelHandler(): void {
    this.formGroup = undefined;
  }

  // Refresh grid data
  public update() {
    this.getAllKafedras();
  }

}

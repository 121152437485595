<education-header></education-header>
<h1>Настройки списка подписантов</h1>

<div class="grid-container">
  <kendo-grid [data]="localSignatories"
              (remove)="removeHandler($event)"
              (cancel)="cancelHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              (save)="saveHandler($event)"
              [loading]="loading"
              [pageable]="{
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: positionPager
              }"
              [pageSize]="pageSizes[0]"
              [sortable]="true"
              [filterable]="true"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand
              themeColor="success"
              icon="plus"
              *ngIf="!isInEditingMode"
      >Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="btnCancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>
    <!--kendo-grid-column title="№ п/п"
                       headerClass="gridHeader dictionaries"
                       [width]="100"
    >
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        <span class="alignCells">
          {{rowIndex + 1}}
        </span>
      </ng-template>
    </kendo-grid-column-->
    <kendo-grid-column field="globalSignatory.postName" title="Должность"
                       headerClass="gridHeader dictionaries"
                       [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.globalSignatory?.postName}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-combobox textField="postName" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="globalSignatories"
                        [formControl]="formGroup.get('globalSignatory')"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="globalSignatory.postSName" title="Сокращенное название должности"
                       headerClass="gridHeader dictionaries"
                       [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.globalSignatory?.postSName}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <span class="alignCells leftAlignment">
          {{formGroup.value?.globalSignatory?.postSName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="globalSignatory.printPostName" title="Печатное название должности"
                       headerClass="gridHeader dictionaries"
                       [width]="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" leftAlignment>
          {{dataItem.globalSignatory.printPostName}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <span class="alignCells leftAlignment">
          {{formGroup.value?.globalSignatory?.printPostName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="filials" title="Филиал"
                        headerClass="gridHeader dictionaries"
                        [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter"
                          [data]="filials"
                          textField="filialSName" valueField="filialId"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getFilialName(dataItem.filials) }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect kendoMultiSelectSummaryTag textField="filialSName" valueField="filialId"
                [data]="filials"
                [(ngModel)]="filialsEdit"
                [formControl]="formGroup.get('filials')"
                (valueChange)="filialValueChange($event, $any(dataItem))"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span></span>
          {{ dataItems.length }} выбрано
        </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="trainingLevels" title="Уровень подготовки"
                       headerClass="gridHeader dictionaries"
                       [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter"
                         [data]="trainingLevels"
                         textField="trainingLevelName" valueField="trainingLevelId"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getTrainingLevelName(dataItem.trainingLevels) }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect kendoMultiSelectSummaryTag textField="trainingLevelName" valueField="trainingLevelId"
                           [data]="trainingLevels"
                           [(ngModel)]="trainingLevelsEdit"
                           [formControl]="formGroup.get('trainingLevels')"
                           (valueChange)="trainingLevelValueChange($event, $any(dataItem))"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span>
            {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="faculties" title="Институт/Факультет"
                       headerClass="gridHeader dictionaries"
                       [width]="350" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter"
                         [data]="faculties"
                         textField="facultyShortName" valueField="facultyId"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getFacultyName(dataItem.faculties) }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect kendoMultiSelectSummaryTag textField="facultyShortName" valueField="facultyId"
                           [data]="faculties"
                           [(ngModel)]="facultiesEdit"
                           [formControl]="formGroup.get('faculties')"
                           (valueChange)="facultyValueChange($event, $any(dataItem))"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span>
            {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="localSignatoryRole" title="Роль"
                       [width]="200" headerClass="gridHeader dictionaries"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter"
                         [data]="localSignatoryRole"
                         textField="text" valueField="id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox textField="text" valueField="id"
                        [data]="localSignatoryRole"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('localSignatoryRole')"
        ></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getLocalSignatoryRoleName(dataItem.localSignatoryRole) }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="graduationKafedra" title="Выпускающая кафедра"
                       headerClass="gridHeader dictionaries"
                       [width]="250">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter"
                         [data]="kafedras"
                         textField="name" valueField="id" gridField="graduationKafedra.id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.graduationKafedra?.name}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox textField="name" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="kafedras"
                        [formControl]="formGroup.get('graduationKafedra')"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="ordinal" title="Порядковый номер отображения согласующих"
                       [width]="200" headerClass="gridHeader dictionaries"
                       [filterable]="false"
    >
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-numerictextbox [formControl]="formGroup.get('ordinal')"
                              [disabled]="formGroup.value.localSignatoryRole != 1"
                              format="n"
        ></kendo-numerictextbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.ordinal }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" [width]="60" *ngIf="editable"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { MilitaryCategories } from 'src/app/models/contingent/militarycategories.model';

@Injectable({
    providedIn: 'root'
  })

export class MilitaryCategoriesService {
    baseUrl = `${environment.apiEndpoint}`;
  
    constructor(private http: HttpClient) { }

    public getCategories() {
        return this.http.get<MilitaryCategories[]>(this.baseUrl + 'contingent/d/militarycategories');
    }

    public addCategories(data: MilitaryCategories) {
        return this.http.post(this.baseUrl + 'contingent/d/militarycategories', data);
    }

    public deleteCategories(id: string) {
        return this.http.delete(this.baseUrl + 'contingent/d/militarycategories/' + id);
    }

    public updateCategories(data: MilitaryCategories, id: string) {
        return this.http.put(this.baseUrl + 'contingent/d/militarycategories/' + id, data);
    }
}
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Guid } from 'guid-typescript';
import { SignatoriesModel } from 'src/app/models/middlecontrol/sheets.model';
import {MiddleControlUserAccessService} from "../../../services/useraccess/middle-control-user-access.service";

@Component({
  selector: 'app-signerparams',
  templateUrl: './signerparams.component.html',
  styleUrls: ['./signerparams.component.scss'],
})
export class SignerparamsComponent implements OnInit {
  @Input() signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'Должность',
    personId: Guid.create(),
  };
  @Output() signerPostChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() signerChange: EventEmitter<SignatoriesModel> = new EventEmitter<SignatoriesModel>();
  @Input() facultyId: string = '';
  @Input() trainingLevelId: string | undefined = '';
  @Input() editable?: boolean;
  @Input() eduGroupId: string = '';
  @Input() semesterNum: number = 0;
  @Input() disciplineId: string = '';
  @Input() electiveDisciplineId: string = '';

  public defaultFIO = 'Фамилия Имя Отчество'
  public isEditSigner: boolean = false;

  constructor(private userAccessService: MiddleControlUserAccessService,) {}

  ngOnInit(): void {}

  ngOnChanges() {}

  toggleEdit(): void {
    this.isEditSigner = !this.isEditSigner;
  }

  onChangeSigner(name: SignatoriesModel) {
    this.signer = name;
    this.signerChange.emit(name);
  }

  onChangeSignerPost(post: string) {
    this.signer.postName = post;
    this.signerPostChange.emit(post);
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import { Standard } from 'src/app/models/education/standard.model';
import { StandardType } from 'src/app/models/education/standardtype.model';
import {RowClassArgs, CellClickEvent, GridComponent} from '@progress/kendo-angular-grid';
import { FormGroup, FormControl } from "@angular/forms";
import { StandardService } from 'src/app/services/education/Standard/standard.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { StudyLevel } from 'src/app/models/education/studylevel.model';
import { IntlService } from "@progress/kendo-angular-intl";
import { CompositeFilterDescriptor, filterBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { QualificationService } from 'src/app/services/education/Qualification/qualification.service';
import { StudylevelService } from 'src/app/services/education/StudyLevel/studylevel.service';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { StandardStatus } from 'src/app/models/education/standardStatus.model';
import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';
import {EducationUserAccessService} from "../../../services/useraccess/education-user-access.service";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {DictEnlargedGroupSpecialization} from "../../../models/education/dictEnlargedGroupSpecialization.model";
import {
    DictEnlargedGroupSpecializationService
} from "../../../services/education/DictEnlargedGroupSpecialization/dict-enlarged-group-specialization.service";
import { VirtualizationSettings, DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { ScienceBranch } from '../../../models/education/scienceBranch.model';
import { ScienceBranchService } from '../../../services/education/DictScienceBranch/scienceBranches.service';
import { TrainingLevelEnum } from "../../../models/education/enums/training-level.enum";
import { StandardTypeEnum } from "../../../models/education/enums/standard-type.enum";

@Component({
  selector: 'app-standard',
  templateUrl: './standard.component.html',
  styleUrls: ['./standard.component.scss']
})
export class StandardComponent implements OnInit {

    public gridStyle: string = 'grid-default';
    public componentName: string = 'educationStandardPage'
    public loading: boolean = false;
    public currentStatusValue: any;
    public isOpened: boolean = false;
    public dialogOpened: boolean = false;
    public editable?: boolean;
    public isNew: boolean = false;
    public showScienceBranch: boolean = false;
    public standardTypeDictName: string = 'Отрасль науки';

    // pager settings
    public type: PagerType = 'numeric';
    public buttonCount: number = 5;
    public info: boolean = true;
    public pageSizes: number[] = [20, 50, 100];
    public previousNext: boolean = true;
    public position: PagerPosition = 'bottom';
    public max: Date = new Date();

    public selectedStandardId = Guid.createEmpty();
    public StateEnum = StandardStatus;
    public status: any[] = [
        {id: 1, status: 1, name: "Действует"},
        {id: 2, status: 2, name: "Закрыт"}
    ];
    public educationStatus: Array<{ text: string; value: number }> = [
        {text: "Действует", value: 1},
        {text: "Закрыт", value: 2},
    ];
    public defaultItem: { text: string; value?: string } = {
        text: "",
        value: undefined,
    };

    public studylevels!: StudyLevel[];
    public trainingLevels!: TrainingLevel[];
    public standardTypes!: StandardType [];
    public standards: Standard[] = [];
    public enlargedGroupSpecializations: DictEnlargedGroupSpecialization[] = [];
    public filteredEnlargedGroupSpecializations: any[] = [];
    public scienceBranch: ScienceBranch[] = [];
    public filteredScienceBranch: ScienceBranch[] = [];

    public filter: CompositeFilterDescriptor = {logic: 'or', filters: []};
    public gridData: Standard[] = this.standards;

    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10,
    };

    constructor(private standardService: StandardService,
                private notificationService: NotificationsService,
                private qualificationService: QualificationService,
                private studylevelService: StudylevelService,
                private standardTypeService: StandardtypeService,
                public intl: IntlService,
                private trainingLevelService: TrainingLevelService,
                private scienceBranchService: ScienceBranchService,
                private dictEnlargedGroupSpecializationService: DictEnlargedGroupSpecializationService,
                private router: Router,
                private userAccessService: EducationUserAccessService) {
    }

    ngOnInit(): void {
        this.getAccessLevel();
        this.getAllStandards();
        this.getAllStudyLevel();
        this.getAllStandardTypes();
        this.getAllTrainingLevels();
        this.getAllScienceBranches();
        this.getEnlargedGroupSpecializations();
    }

    // Edit Form
    public editForm: FormGroup = new FormGroup({
        standardId: new FormControl(),
        standardExternalId: new FormControl(),
        standardCipher: new FormControl(),
        standardName: new FormControl(),
        standardShortName: new FormControl(),
        standardTypeExternalId: new FormControl(),
        educationLevelExternalId: new FormControl(),
        dictTrainingLevelId: new FormControl(),
        dictScienceBranchExternalId: new FormControl(),
        laboriousness: new FormControl(),
        dictEnlargedGroupSpecializationId: new FormControl(),
        link: new FormControl(),
        number: new FormControl(),
        dateFGOS: new FormControl(),
        status: new FormControl()
    });

    //Getting all entries from standard
    private getAllStandards() {
        this.loading = true;
        this.standardService.getAllStandards()
            .subscribe({
                next: (response) => {
                    this.standards = response;
                    this.standards.forEach((standard) => {
                        standard['trainingLevelExternalId'] = standard.trainingLevel.trainingLevelExternalId;
                    })
                },
                error: () => {
                    this.loading = false;
                    this.notificationService.showError("Не удалось загрузить список стандартов")
                },
                complete: () => {
                    this.loading = false;
                }
            });
    }

    //Getting all entries from standard
    private getAllStandardTypes() {
        this.standardTypeService.getAllStandardTypes()
            .subscribe({
                next: (response) => this.standardTypes = response,
                error: () => this.notificationService.showError("Не удалось загрузить список типов стандарта")
            })
    }

    public getSelectedValues(cell: CellClickEvent): void {
        this.selectedStandardId = cell.dataItem.standardId;
    }

    //Getting all entries from training levels
    private getAllTrainingLevels() {
        this.trainingLevelService.getAllTrainingLevels()
            .subscribe({
                next: (response) => this.trainingLevels = response
            });
    }

    //Getting all entries from dictionary
    private getAllStudyLevel() {
        this.studylevelService.getAllStudyLevel()
            .subscribe({
                next: (response) => this.studylevels = response,
                error: () => this.notificationService.showError("Не удалось загрузить список уровней образования")
            });
    }

    //Getting all entries from science branches
    private getAllScienceBranches() {
        this.scienceBranchService.getAllScienceBranches()
            .subscribe({
                next: (response) => this.scienceBranch = response
            });
    }

    private getAccessLevel() {
        this.userAccessService.getAccessLevel().subscribe(
            {
                next: (response) => {
                    this.editable = !response.dictAccessLevel;
                },
                error: () => {
                }
            })
    }

    private getEnlargedGroupSpecializations() {
        this.dictEnlargedGroupSpecializationService.getEnlargedGroupSpecialization().subscribe({
            next: (response) => {
                this.enlargedGroupSpecializations = response;
            }
        })
    }

    public saveStandard() {
        if(this.editForm?.value.dateFGOS)
          this.editForm.value.dateFGOS = TimeZoneFix(this.editForm.value.dateFGOS)
        // Add new standard
        if (this.isNew) {
            this.standardService.addStandard(this.editForm?.value)
                .subscribe(
                    {
                        next: () => {
                            this.notificationService.showSuccess("Стандарт был успешно добавлен!");
                            this.getAllStandards();
                            this.onCancel();
                        },
                        error: () => {
                            this.notificationService.showError("Не удалось добавить стандарт");
                            this.onCancel();
                        }
                    });
        }
        // Edit standard
        else {
            this.standardService.updateStandard(this.editForm?.value)
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess("Стандарт был успешно сохранен!");
                        this.getAllStandards();
                        this.onCancel();
                    },
                    error: () => {
                        this.notificationService.showError('Не удалось изменить стандарт');
                        this.onCancel();
                    }
                })
        }
    }

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        this.gridData = filterBy(this.standards, filter);
    }

    public onStateChange(state: State): void {
        this.gridState = state;
        this.getAllStandards();
    }

    public addHandler(): void {
        this.isNew = true;
        this.isOpened = true;
        this.gridStyle = 'grid-hidden';
    }

    public editHandler(dataItem: any): void {
        this.filteredEnlargedGroupSpecializations =
            [...this.enlargedGroupSpecializations.filter((item) => item.dictStandardTypeId === dataItem.standardTypeExternalId)];
        this.filteredScienceBranch = [...this.scienceBranch.filter((item) => item.dictStandardTypeId === dataItem.standardTypeExternalId)];
        this.checkTrainingLevel(dataItem.trainingLevelExternalId);
        this.checkStandardType(dataItem.standardTypeExternalId);
        this.currentStatusValue = dataItem.status;
        this.editForm = formGroup(dataItem);
        if (this.editForm.value.dateFGOS) this.editForm.value.dateFGOS = DateFromUTCAsLocal(this.editForm.value.dateFGOS);
        this.isNew = false;
        this.gridStyle = 'grid-hidden';
        this.isOpened = true;
    }

    public onCancel(): void {
        this.editForm.reset();
        this.showScienceBranch = false;
        this.gridStyle = 'grid-default';
        this.isOpened = false;
        this.dialogOpened = false;
    }

    public onStandardTypeChange(dataItem: any) {
        this.filteredEnlargedGroupSpecializations
            = [...this.enlargedGroupSpecializations.filter((item) => item.dictStandardTypeId === dataItem)];
        this.filteredScienceBranch = [...this.scienceBranch.filter((item) => item.dictStandardTypeId === dataItem)];
        this.checkStandardType(dataItem);
    }

    // Сортировка
    public sort: SortDescriptor[] = [
        {
            field: "dictStandardType.standardTypeShortName",
            dir: "asc"
        },
        {
            field: "trainingLevel.trainingLevelName",
            dir: "asc"
        },
        {
            field: "standardCipher",
            dir: "asc",
        },
    ];

    // Change status cell color depending on value
    public rowClassCallback = (context: RowClassArgs) => {
        switch (context.dataItem.status) {
            case 1:
                return {green: true};
            case 2:
                return {red: true};
            default:
                return {};
        }
    }

    public close(): void {
        this.dialogOpened = false;
    }

    public open(e: PointerEvent): void {
        // Check for status changes on edit form
        if (this.currentStatusValue !== this.editForm.value.status && !this.isNew) {
            this.dialogOpened = true;
        } else {
            this.saveStandard();
        }
    }

    public goToLimitations() {
        setTimeout(() => window.open('education/dictstandardlimitation/' + this.selectedStandardId), 50);
    }

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
    }

    public onTrainingLevelChange(value: string) {
        this.checkTrainingLevel(value);
    }

    private checkTrainingLevel(id: string) {
        if (id) this.showScienceBranch = this.trainingLevels.find((item: TrainingLevel) =>
            item.trainingLevelExternalId == id)?.trainingLevelId == TrainingLevelEnum.Graduate;
    }

    private checkStandardType(id: string) {
        if (id) this.standardTypeDictName = this.standardTypes.find((item: StandardType) =>
            item.standardTypeExternalId == id)?.standardTypeId == StandardTypeEnum.FGT ? 'Область науки' : 'Отрасль науки'
    }
}

const formGroup =
    (dataItem: {
        standardId?: any;
        standardExternalId?: any,
        standardCipher?: any;
        standardName?: any;
        standardShortName?: any;
        standardTypeExternalId?: any;
        educationLevelExternalId?: any,
        dictTrainingLevelId?: any;
        dictScienceBranchExternalId?: any;
        laboriousness?: any;
        dictEnlargedGroupSpecializationId?: any;
        link?: any;
        number?: any;
        dateFGOS?: any;
        status?: any;
        gender?: any;
    }) =>
        new FormGroup({
            standardId: new FormControl(dataItem.standardId),
            standardExternalId: new FormControl(dataItem.standardExternalId),
            standardCipher: new FormControl(dataItem.standardCipher),
            standardName: new FormControl(dataItem.standardName),
            standardShortName: new FormControl(dataItem.standardShortName),
            standardTypeExternalId: new FormControl(dataItem.standardTypeExternalId),
            educationLevelExternalId: new FormControl(dataItem.educationLevelExternalId),
            dictTrainingLevelId: new FormControl(dataItem.dictTrainingLevelId),
            dictScienceBranchExternalId: new FormControl(dataItem.dictScienceBranchExternalId),
            laboriousness: new FormControl(dataItem.laboriousness),
            dictEnlargedGroupSpecializationId: new FormControl(dataItem.dictEnlargedGroupSpecializationId),
            link: new FormControl(dataItem.link),
            number: new FormControl(dataItem.number),
            dateFGOS: new FormControl(dataItem.dateFGOS == null ? null : new Date(dataItem.dateFGOS)),
            status: new FormControl(dataItem.status)
        });

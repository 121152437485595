import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {StudyForm} from "../../../models/education/External/studyform.model";
import urlJoin from "url-join";

@Injectable({
  providedIn: 'root'
})
export class StudyFormService {

  baseUrl = urlJoin(<string>environment.apiEndpoint, environment.apiPaths.education.studyform);

  constructor(private http: HttpClient) { }

  public getList(): Observable<StudyForm[]> {
    return this.http.get<StudyForm[]>(this.baseUrl);
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Faculty } from 'src/app/models/education/faculty.model';

@Injectable({
  providedIn: 'root'
})
export class FacultyService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.faculty}`;

  constructor(private http: HttpClient) { }

  //Get all faculties
  public getAllFaculties(): Observable<Faculty[]> {
    return this.http.get<Faculty[]>(this.baseUrl + '/GetFaculties/');
  }

  // Add Faculty
  addFaculty(faculty: Faculty): Observable<Faculty> {
    return this.http.post<Faculty>(this.baseUrl + '/CreateFaculty/' , faculty)
      .pipe(catchError(this.handleError));
  }

  // Edit Faculty
  updateFaculty(faculty: Faculty): Observable<Faculty> {
    return this.http.put<Faculty>(this.baseUrl + '/UpdateFaculty/' + faculty.facultyId, faculty)
      .pipe(catchError(this.handleError));
  }

  // Remove Faculty
  deleteFaculty(id: string): Observable<Faculty> {
    return this.http.delete<Faculty>(this.baseUrl + '/DeleteFaculty/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

<div class="header">
  <h1>Перезакрепление за другой ОП</h1>
</div>
<div class="educationProgram">
  <span class="row">
    <h2>Направление подготовки</h2>
    <h2>{{educationPlanDetailsService.currentEducationStandard.directionTraining}}</h2>
  </span>
  <span class="row">
    <h2>Образовательная программа</h2>
    <h2>{{educationPlanDetailsService.currentEducationProgram.educationProgramName}}</h2>
  </span>
  <span class="row">
    <h2>Форма обучения</h2>
    <h2>{{educationPlanDetailsService.currentEducationPlan.studyFormName || "-"}}</h2>
  </span>
  <span class="row">
    <h2>Год поступления</h2>
    <h2>{{educationPlanDetailsService.currentEducationPlan.yearAdmission || "-"}}</h2>
  </span>
</div>
<div class="header">
  <h1>Выберите ОП для перезакрепления</h1>
  <button (click)="onReattachmentCancel()" kendoButton>Отмена</button>
</div>
<kendo-grid [kendoGridBinding]="gridData"
            [navigable]="true"
            (cellClick)="showReattachmentConfirmation($event)"
            [sortable]="true"
            [filterable]="true">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column headerClass="gridHeader dictionaries" field="standard.trainingProgram" [width]="260" editor="text" title="Направление подготовки">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.standard.trainingProgram}}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader dictionaries" field="oopNameAndCipher" editor="text" title="Образовательная программа" [width]="250" >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.oopNameAndCipher}}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader dictionaries" field="dictTrainingLevelId" [width]="170" editor="text" title="Уровень подготовки">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <filter-dropdown [filter]="filter" gridField="dictTrainingLevelId" [data]="dataSources.trainingLevels" textField="trainingLevelName"
                       valueField="trainingLevelId">
      </filter-dropdown>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.standard.trainingLevel.trainingLevelName}}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader dictionaries" field="qualificationExternalId" [width]="170" editor="text" title="Квалификация">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <filter-dropdown gridField="qualificationExternalId" [filter]="filter" [data]="dataSources.qualifications" textField="qualificationName"
                       valueField="qualificationId">
      </filter-dropdown>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.qualification.qualificationName}}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader dictionaries" [width]="150" field="faculty.facultyShortName" editor="text" title="Филиал">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <filter-dropdown gridField="faculty.facultyShortName" [filter]="filter" [data]="dataSources.faculties" textField="facultyShortName"
                       valueField="facultyShortName">
      </filter-dropdown>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.faculty?.facultyShortName}}
        </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
<div kendoDialogContainer></div>
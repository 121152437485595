<education-header></education-header>
<title>Дополнительные работы по дисциплине</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Дополнительные работы по дисциплине</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новая дополнительная работа по дисциплине' : 'Изменить дополнительную работу по дисциплине' }}
  </h1>

  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" class="addButton" (click)="addHandler()" kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid
            [data]="dictTypeWorkDisciplineAdditionals"
            [navigable]="true"
            [sortable]="true"
            [filterable]="true"
            [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictTypeWorkDisciplineAdditionalName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.dictTypeWorkDisciplineAdditionalName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictTypeWorkDisciplineAdditionalShortName" title="Короткое название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictTypeWorkDisciplineAdditionalShortName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictTypeWorkDisciplineAdditionalClassroomWork"
                       editor="boolean"
                       title="Контактная работа"
                       headerClass="gridHeader"
                       [width]="150"
                       [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{(dataItem.dictTypeWorkDisciplineAdditionalClassroomWork == true ? "Да" : "Нет")}}
                </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictTypeWorkDisciplineAdditionalIsRecognizingAmounts"
                       editor="boolean"
                       title="Учитывать в суммах"
                       headerClass="gridHeader"
                       [width]="150"
                       [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{(dataItem.dictTypeWorkDisciplineAdditionalIsRecognizingAmounts == true ? "Да" : "Нет")}}
                </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictTypeWorkDisciplineAdditionalIsActual"
                       editor="boolean"
                       title="Актуально"
                       headerClass="gridHeader"
                       [width]="150"
                       [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{(dataItem.dictTypeWorkDisciplineAdditionalIsActual == true ? "Да" : "Нет")}}
                </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton (click)="editHandler(dataItem)" class="editButton">Изменить</button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictTypeWorkDisciplineAdditionalName" [maxlength]="200" 
          #dictTypeWorkDisciplineAdditionalName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="dictTypeWorkDisciplineAdditionalShortName" [maxlength]="100" 
          #dictTypeWorkDisciplineAdditionalShortName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Контактная работа</h2>
          <input type="checkbox" kendoCheckBox formControlName="dictTypeWorkDisciplineAdditionalClassroomWork" [maxlength]="200" 
          #dictTypeWorkDisciplineAdditionalClassroomWork />
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Учитывать в суммах</h2>
          <input type="checkbox" kendoCheckBox formControlName="dictTypeWorkDisciplineAdditionalIsRecognizingAmounts" [maxlength]="200" 
          #dictTypeWorkDisciplineAdditionalIsRecognizingAmounts />
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Актуально</h2>
          <input type="checkbox" kendoCheckBox formControlName="dictTypeWorkDisciplineAdditionalIsActual" [maxlength]="200" 
          #dictTypeWorkDisciplineAdditionalIsActual />
        </div>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton (click)="onCancel()" type="button" class="cancelButton">Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveDictTypeWorkDisciplineAdditional()">Сохранить</button>
      </div>
    </form>
  </div>
</div>
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  DialogCloseResult,
  DialogResult,
} from '@progress/kendo-angular-dialog';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { getData$ } from '../../../../../environments/environment';
import {DictDiplomaTypeService} from "../../../../services/gia/dict-diploma-type.service";
import {AddDiplomaTypeDto} from "../../../../models/gia/dicts/dictDiplomaType.model";

@Injectable({
  providedIn: 'root',
})
export class DictDiplomaTypeMethods {
  constructor(
    private dictService: DictDiplomaTypeService,
    private notificationService: NotificationsService,
  ) {}

  async saveDict(isNew: boolean, formGroup: FormGroup) {
    if (isNew) {
      const dictAddDto: AddDiplomaTypeDto = {
        name: formGroup.value.name,
        trainingLevelId: formGroup.value.trainingLevelId,
        value: formGroup.value.value,
      };
      this.dictService.addDiplomaType(dictAddDto).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: () => this.notificationService.showError('Произошла ошибка'),
      });
    } else {
      this.dictService.editDiplomaType(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: () => this.notificationService.showError('Произошла ошибка'),
      });
    }
  }

  remove(dialogResult: Observable<DialogResult>, id: string) {
    dialogResult.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да')
        this.dictService.removeDiplomaType(id).subscribe({
          next: () => {
            getData$.next(true);
            this.notificationService.showSuccess('Успешно');
          },
          error: () => this.notificationService.showError('Произошла ошибка'),
        });
    });
  }
}

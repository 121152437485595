import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AdditionalFields } from '../../models/contingent/additionalfields.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';


@Injectable({
  providedIn: 'root'
})
export class AdditionalfieldsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.additionalfields}`;

  constructor(private http: HttpClient) { }

  //Get all additionalfields
  public getAlladditionalfields(): Observable<AdditionalFields[]> {
    return this.http.get<AdditionalFields[]>(this.baseUrl);
  }

  //Get By Id additionalfields
  getByIdadditionalfields(id: string): Observable<AdditionalFields> {
    return this.http.get<AdditionalFields>(this.baseUrl + '/getadditionalfieldsbyid' + '/' + id)
      .pipe(catchError(this.handleError));
  }


  //Get By Id additionalfields
  getStudentadditionalfields(id: string | undefined): Observable<AdditionalFields[]> {
    return this.http.get<AdditionalFields[]>(this.baseUrl + '/getadditionalfieldsbystudpersonid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add additionalfields
  addadditionalfields(additionalfields: AdditionalFields, studentId: string): Observable<AdditionalFields> {
    return this.http.post<AdditionalFields>(this.baseUrl + '/' + studentId, additionalfields)
      .pipe(catchError(this.handleError));
  }

  //Delete additionalfields
  deleteadditionalfields(id: Guid|undefined, studentId: string): Observable<AdditionalFields> {
    return this.http.delete<AdditionalFields>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit additionalfields
  updateadditionalfields(additionalfields: AdditionalFields, studentId: string): Observable<AdditionalFields> {
    return this.http.put<AdditionalFields>(this.baseUrl + '/' + additionalfields.additionalFieldExternalId + '/' + studentId, additionalfields)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment} from '../../../environments/environment';
import { Structural } from '../../models/classroom/structural.model';
import { Department } from '../../models/announcement/department.model';

@Injectable({
  providedIn: 'root'
})

export class StructuralService {
  baseUrl = `${environment.classroomApiEndpoint}${environment.apiPaths.classroom.dict}`;

  constructor(private http: HttpClient) { }

  //Get all Departments
  public getAllStructural(): Observable<Department[]> {
    return this.http.get<Department[]>(this.baseUrl + '/departments/all');
  }
  
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

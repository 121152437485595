<education-header></education-header>
<kendo-tilelayout style="padding: 20px 0 0 0">
  <kendo-tilelayout-item [col]="1">
    <div class="trainingProgram">
      <h2>Направление подготовки</h2>
      <h2 class="name" (ngModel)="currentStandardName">{{ currentStandardName }}</h2>
      <div class="headerButtons" *ngIf="editable">
        <button *ngIf="!isEdited" kendoButton themeColor="primary" (click)="editHandler()">
          Изменить всё
        </button>
        <button *ngIf="isEdited" kendoButton (click)="cancelHandler()">
          Отмена
        </button>
        <button *ngIf="isEdited" kendoButton themeColor="primary" class="headerButton" (click)="saveHandler()">
          Сохранить
        </button>
      </div>
    </div>
    <kendo-tilelayout-item-body style="padding: 0">
      <div class="grid-content">
        <kendo-listview [data]="groupedDictStandardLimitations" style="border: 0;">
          <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
            <div class="header">
              <div class="headerCell">
                <p>{{dataItem.value}}</p>
              </div>
              <div class="headerCell">
                <p>Минимум</p>
              </div>
              <div></div>
              <div class="headerCell">
                <p>Максимум</p>
              </div>
              <div></div>
              <div class="headerCell">
                <p>Единица измерения</p>
              </div>
              <div></div>
              <div class="headerCell">
                <p>Описание</p>
              </div>
            </div>
            <kendo-listview class="listView" [data]="dataItem.items">
              <ng-template kendoListViewItemTemplate let-dataItem="dataItem">
                <div [ngClass]="{editing: isEdited, last: isLastItem(dataItem)}" class="wrap">
                  <div class="tableCellName">
                    <p class="align" style="margin-left: 40px;">{{dataItem.name}}</p>
                  </div>

                  <div class="table" *ngIf="isEdited">
                    <kendo-numerictextbox
                    class="numTextBoxMin"
                    *ngIf=!isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)
                    [(ngModel)]="dataItem.minValue"
                    [min]="0"
                    [decimals]="0"
                    format="n0">
                    </kendo-numerictextbox>
                    <input *ngIf=isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)
                    type="checkbox" kendoCheckBox  [(ngModel)]="dataItem.logicalVariable"/>
                  </div>
                  <div *ngIf="isEdited"></div>
                  <div *ngIf="isEdited" style="margin: auto 0 auto 0">
                    <kendo-numerictextbox
                            class="numTextBoxMax"
                            *ngIf=!isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)
                            [(ngModel)]="dataItem.maxValue"
                            [min]="0"
                            [decimals]="0"
                            format="n0">
                    </kendo-numerictextbox>
                  </div>
                    <div class="tableCellMin" *ngIf="!isEdited && isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)">
                      <p class="align">{{dataItem.logicalVariable ? "Да" : "Нет"}}</p>
                    </div>
                    <div class="tableCellMin" *ngIf="!isEdited && !isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)">
                      <p class="align">{{dataItem.minValue}}</p>
                    </div>
                    <div></div>
                    <div class="tableCellMax" *ngIf="!isEdited && !isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)">
                      <p class="align">{{dataItem.maxValue}}</p>
                    </div>
                    <div *ngIf="!isEdited"></div>
                    <div class="tableCellUnit" *ngIf=!isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)>
                      <p class="align">{{dataItem.unit}}</p>
                    </div>
                    <div class="tableCellMax" *ngIf="!isEdited && isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)">
                      <p></p>
                    </div>
                    <div></div>
                    <div class="tableCellUnit" *ngIf=isLimitationHasBoolType(dataItem.dictStandardLimitationExternalId)>
                      <p></p>
                    </div>
                    <div class="tooltip" *ngIf="dataItem.tooltip != null && dataItem.tooltip != ''" kendoTooltip class="tooltip k-icon k-i-info k-icon-md" [title]="dataItem.tooltip"></div>
                    <div class="emptyTooltip" *ngIf="dataItem.tooltip == null || dataItem.tooltip == ''" kendoTooltip></div>
                </div>
              </ng-template>
            </kendo-listview>
          </ng-template>
        </kendo-listview>
      </div>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
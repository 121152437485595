import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";
import { ControlAction } from 'src/app/models/dicts/controlaction.model';

@Injectable({
    providedIn: 'root'
  })
export class ControlActionService {
    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.controlaction}`;

  constructor(private http: HttpClient) { }

  //Get all ControlAction
  public getAllControlAction(): Observable<ControlAction[]> {
    return this.http.get<ControlAction[]>(this.baseUrl);
  }

  //Add ControlAction
  addControlAction(controlAction: ControlAction): Observable<ControlAction> {
    return this.http.post<ControlAction>(this.baseUrl, controlAction)
      .pipe(catchError(this.handleError));
  }

  //Delete ControlAction
  deleteControlAction(id: string): Observable<ControlAction> {
    return this.http.delete<ControlAction>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit ControlAction
  updateControlAction(controlAction: ControlAction): Observable<ControlAction> {
    return this.http.put<ControlAction>(this.baseUrl + '/' + controlAction.dictControlActionExternalId, controlAction)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}
import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import { AddEvent, CellClickEvent, GridComponent, PageSizeItem, RemoveEvent } from "@progress/kendo-angular-grid";
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, FormGroup } from "@angular/forms";
import { MilitaryCategoriesService } from 'src/app/services/contingent/militarycategories.service';
import { MilitaryCategories } from 'src/app/models/contingent/militarycategories.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { openRemoveDialog } from 'src/app/helpers/dialogHelper';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { isChildOf } from "src/app/helpers/elementRef-helper";

@Component({
    selector: 'app-militarycategories',
    templateUrl: './militarycategories.component.html',
    styleUrls: ['./militarycategories.component.scss']
  })

export class MilitaryCategoriesComponent implements OnInit {

    public militarycategories: MilitaryCategories[] = [];

    public pageSize = 10;
    public sizes:(number | PageSizeItem)[] = [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50}, {text: 'Все', value : 'all'}];

    public formGroup: UntypedFormGroup | undefined;
    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean {
        return this.editedRowIndex !== undefined || this.isNew;
    }
    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    public dictMilitaryCategoryExternalId: string = "";

    constructor(
        private militaryCategoriesService: MilitaryCategoriesService,
        private notificationService: NotificationsService,
        private dialogService: DialogService,
        private renderer: Renderer2,
    ) {}

    public ngOnInit(): void {
        this.getCategories();

        this.renderer.listen("document", "click", ({ target }) => {
            if (!isChildOf(target, "k-grid")) {
              this.saveCurrent();
            }
        });
    }

    public getCategories() {
        this.militaryCategoriesService.getCategories()
            .subscribe(response =>{
                this.militarycategories = response;
            })
    }

    public editHandler({
            sender,
            columnIndex,
            rowIndex,
            dataItem,
        }: CellClickEvent): void {
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {       
             return;
        }

        this.isLine = true;
   //     this.saveRow();
        this.formGroup = formGroup(dataItem);
        this.editedRowIndex = rowIndex;
        this.dictMilitaryCategoryExternalId = dataItem.dictMilitaryCategoryExternalId;

        sender.editRow(rowIndex, this.formGroup);
    }

    public addHandler ({ sender }: AddEvent): void {
        this.closeEditor(sender);
    
        this.formGroup = formGroup({
          militaryCategoryName: "",          
        });  
    
        this.isLine = true;
        this.isNew = true;
        sender.addRow(this.formGroup);
    }
   
    public removeHandler({ dataItem }: RemoveEvent): void {
        const dialog: DialogRef = openRemoveDialog(this.dialogService, "категорию " + dataItem.militaryCategoryName.toLowerCase(), 450, 200, 250);

        dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
              if(result.text == "Да"){
                this.militaryCategoriesService.deleteCategories(dataItem.dictMilitaryCategoryExternalId)
                .subscribe(
                  (response) => {
                    this.getCategories();
                    this.notificationService.showSuccess("Удалено");                    
                  },
                  (error) => {
                    this.notificationService.showError(error.error);
                  }
                );
              }
            }
        });       
    }   

    public saveCurrent() {
        if (this.formGroup && !this.formGroup.valid) return;
          
        this.isLine = false;
        this.saveRow();
    }

    private saveRow(): void {
        if (this.formGroup == undefined) return;

        if (this.isInEditingMode) {
            //Adding new entry to dictionary
            if (this.isNew == true) {
                this.militaryCategoriesService.addCategories(this.formGroup.value)
                .subscribe(
                    response => {
                        this.getCategories();
                        this.notificationService.showSuccess("Добавлено");
                    },
                    error => {
                        this.notificationService.showError("Не удалось сохранить запись");
                    }
                );
            }
            else {
                //Update
                this.militaryCategoriesService.updateCategories(this.formGroup.value, this.dictMilitaryCategoryExternalId)
                    .subscribe(
                    response => {
                        this.getCategories();
                        this.notificationService.showSuccess("Сохранено");
                    },
                    error => {
                        this.notificationService.showError("Не удалось изменить запись");
                    }
                    );
            }
        }        

        this.closeEditor(this.grid, this.editedRowIndex)
    }

    public cancelHandler() {
        this.closeEditor(this.grid);
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        grid.closeRow(rowIndex);
        this.isNew = false;
        this.isLine = false;
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }
      
}

const formGroup = (dataItem: { militaryCategoryName: string; order?: number;}) =>
  new UntypedFormGroup({
    order: new UntypedFormControl(dataItem.order),    
    militaryCategoryName: new UntypedFormControl(dataItem.militaryCategoryName, Validators.required),
  });
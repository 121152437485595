<div class="grid-container">

  <h1>Детали операции</h1>
  <dl>
    <h2>{{"Действие: " + (history?.typeName ? history?.typeName : "")}}</h2>

    <h3 *ngIf="history?.semesterChanged" class="history-semester-title">Изменены сроки семестров!</h3>

    <h2 class="history-value">{{"Предыдущее значение: "}}</h2>
    <dl class="history-list">
      <kendo-label class="history-label" *ngIf="history?.previousValue != ''">{{history?.previousValue}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.planName">{{"Учебный план: " + history?.detailedOldData?.planName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.facultyName">{{"Институт/факультет: " + history?.detailedOldData?.facultyName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.courseNum">{{"Курс: " + history?.detailedOldData?.courseNum}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.groupName">{{"Группа: " + history?.detailedOldData?.groupName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.standardName">{{"Направление подготовки: " + history?.detailedOldData?.standardName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.studyForm">{{"Форма обучения: " + history?.detailedOldData?.studyForm}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.trainingLevel">{{"Уровень подготовки: " + history?.detailedOldData?.trainingLevel}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.kafedraName">{{"Кафедра: " + history?.detailedOldData?.kafedraName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedOldData?.programName">{{"Профиль: " + history?.detailedOldData?.programName}}</kendo-label>
    </dl>
    <h2 class="history-value">{{"Новое значение: "}}</h2>
    <dl class="history-list">
      <kendo-label class="history-label" *ngIf="history?.newValue != ''">{{history?.newValue}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.planName">{{"Учебный план: " + history?.detailedNewData?.planName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.facultyName">{{"Институт/факультет: " + history?.detailedNewData?.facultyName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.courseNum">{{"Курс: " + history?.detailedNewData?.courseNum}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.groupName">{{"Группа: " + history?.detailedNewData?.groupName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.standardName">{{"Направление подготовки: " + history?.detailedNewData?.standardName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.studyForm">{{"Форма обучения: " + history?.detailedNewData?.studyForm}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.trainingLevel">{{"Уровень подготовки: " + history?.detailedNewData?.trainingLevel}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.kafedraName">{{"Кафедра: " + history?.detailedNewData?.kafedraName}}</kendo-label>
      <kendo-label class="history-label" *ngIf="history?.detailedNewData?.programName">{{"Профиль: " + history?.detailedNewData?.programName}}</kendo-label>
    </dl>
  </dl>

  <button kendoButton
          class="history-back-button"
          size="small"
          (click)="closeDetails()">
    Назад
  </button>
</div>

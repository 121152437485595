import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { TokenStorageService } from '../services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Location } from '@angular/common';
import { Role } from '../models/useraccess/role';

@Injectable({
  providedIn: 'root',
})
export class DiplomablanksUserAccessGuard implements CanActivate {
  private access: boolean = false;
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private location: Location,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = this.tokenStore.getAuthToken();
    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)) {
        return true;
      } else {
          if(roles?.includes(Role.Diplomablanks)) {
           return true;
          }
        
          alert('Недостаточно прав для доступа на эту страницу');
          localStorage.setItem('last_url', this.location.path());
          this.router.navigate([`/profile`]).then( );
          return false;
      }
    }
    localStorage.setItem('last_url', this.location.path());
    return false;
  }
}

<div class="context">
  <h1>Справочник компетенций образовательной программы</h1>
    <kendo-tilelayout style="padding: 0">
        <kendo-tilelayout-item [col]="1" [colSpan]="4">
            <kendo-tilelayout-item-body>
                <table class="table">
                    <tr>
                        <th>Направление подготовки</th>
                        <td>{{competence.directionTraining}}</td>
                    </tr>
                    <tr>
                        <th>Образовательная программа</th>
                        <td>{{competence.educationProgram}}</td>
                    </tr>
                </table>
                <div class="buttons" style="margin-top: 30px; padding-bottom: 10px;" *ngIf="!copyOOP && !addForm">
                    <button kendoButton (click)="oopReturn()">Вернуться к списку ОП</button>
                    <button kendoButton style="margin-left: 20px" (click)="showOOP()" *ngIf="editable">Скопировать из другой ОП</button>
                </div>
            </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
    </kendo-tilelayout>
    <!-- Типы компетенций -->
    <kendo-listview [data]="typeCompetencies" *ngIf="!copyOOP && !addForm" [loading]="loading">
      <ng-template kendoListViewLoaderTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
        <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
            <!--<kendo-tilelayout style="padding: 20px 0 0 0" *ngIf="dataItem.competence.length !== 0">--> 
            <kendo-tilelayout style="padding: 20px 0 0 0">
                <kendo-tilelayout-item [col]="1" [colSpan]="4">
                    <kendo-tilelayout-item-body>
                        <div>
                            <h4>{{dataItem.name}}</h4>
                            <kendo-grid
                                    class="extraGrid"
                                    [data]="dataItem.competence"
                                    (remove)="removeHandler($event)"
                                    (cancel)="cancelHandler()"
                                    (cellClick)="getSelectedValues($event)"
                                    (add)="addHandler($event, dataItem.competenceExternalId)"
                                    (save)="saveCurrent()"
                                    [sortable]="true"
                                    style="margin-top: 20px">
                             <ng-template kendoGridToolbarTemplate position="bottom">
                                 <button *ngIf="editable" class="addButton" [primary]="true" [disabled]="isAdd || isEdit" icon="plus" kendoGridAddCommand>Добавить компетенцию</button>
                                 <span class="actionButtons" *ngIf="isAdd">
                                     <button kendoButton [disabled]="blockSaveButtons(dataItem.competence)" (click)="cancelHandler()">Отмена</button>
                                     <button kendoButton [disabled]="blockSaveButtons(dataItem.competence)"  themeColor="primary" (click)="saveCurrent()">Сохранить</button>
                                 </span>
                             </ng-template>
                                <kendo-grid-column headerClass="gridHeader" field="code" title="Код компетенции" [width]="190" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                                        <kendo-textbox
                                                [maxlength]="10"
                                                size="small"
                                                [formControl]="formGroup.get('code')"
                                                [clearButton]="true"
                                        ></kendo-textbox>
                                    </ng-template>
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells">
                                            {{ dataItem.code}}
                                          </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column headerClass="gridHeader" field="description" title="Описание компетенции" [headerStyle]="{'text-align': 'center'}">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="alignCells" style="text-align: left; justify-content: left">
                                            {{ dataItem.description}}
                                          </span>
                                    </ng-template>
                                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                                        <kendo-textbox
                                                size="small"
                                                [formControl]="formGroup.get('description')"
                                                [clearButton]="true"
                                        ></kendo-textbox>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="" [width]="130" *ngIf="editable">
                                  <ng-template kendoGridCellTemplate let-dataItem>
                                      <span *ngIf="!isEdit" style="cursor: pointer" (click)="open(dataItem)">
                                          <kendo-icon name="close"></kendo-icon>
                                      </span>
                                      <span *ngIf="!isAdd" class="buttons">
                                          <button icon="check" class="saveButton green" kendoGridSaveCommand></button>
                                          <button icon="cancel" class="cancelButton red" kendoGridCancelCommand></button>
                                      </span>
                                  </ng-template>
                                </kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </kendo-tilelayout-item-body>
                </kendo-tilelayout-item>
            </kendo-tilelayout>
        </ng-template>
    </kendo-listview>

    <!-- Образовательная программа -->

    <kendo-tilelayout style="padding: 20px 0 0 0" *ngIf="copyOOP">
      <kendo-tilelayout-item [col]="1" [colSpan]="4">
        <kendo-tilelayout-item-body>
          <div style="display: flex; align-items: center; margin-bottom: 20px">
            <h4>Выберите источник копирования</h4>
            <button kendoButton style="margin-left: auto; order: 2;" (click)="showOOP()">Отмена</button>
          </div>
            <oop (onCopyComptence)="onStateChange()" [isCompetence]="true"></oop>
        </kendo-tilelayout-item-body>
      </kendo-tilelayout-item>
    </kendo-tilelayout>
    </div>

<kendo-dialog
title="Пожалуйста, подтвердите"
*ngIf="opened"
(close)="close()"
[minWidth]="250"
[width]="450"
>
<p style="margin: 30px; text-align: center;">
  Вы уверены что хотите удалить компетенцию?
</p>
<kendo-dialog-actions>
  <button kendoButton (click)="close()">Нет</button>
  <button kendoButton (click)="deleteCompetence()" [primary]="true">
    Да
  </button>
</kendo-dialog-actions>
</kendo-dialog>
import {Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {groupBy, GroupDescriptor, GroupResult} from '@progress/kendo-data-query';
import {AssignmentsService} from "../../../../services/disciplineworkload/assignments.service";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  CellClickEvent,
  GridComponent,
  RemoveEvent,
  RowArgs
} from "@progress/kendo-angular-grid";
import {EducationPlanService} from "../../../../services/disciplineworkload/externals/education-plan.service";
import {
  AvailableEducationDisciplineGet, DictCalculationMethod,
  DictControllingActionGet,
  DictDisciplineGet, DictIncreasedCoefficient, DictPracticeType, DictTrainingLevel,
  DictTrainingLevelGroup,
  EduGroup
} from '../../../../models/disciplineworkload/externals.model';
import {
  AmountFlowAdd,
  AssignmentDepartmentGet,
  AssignmentDisciplineAdd, CalculationMethodCathedralWorkAdd,
  DictTypeWorkGet, DisciplineAssignment,
  DisciplineAssignmentAdd,
  DisciplineGroupAdd,
  EducationPlanDisciplineGet,
  TypeWorkGet
} from '../../../../models/disciplineworkload/assignements.model';
import {DictService} from "../../../../services/disciplineworkload/externals/dict.service";
import {DictDisciplinesQuery} from "../../../../models/disciplineworkload/query/dictDisciplinesQuery.model";
import {AvailableEducationDisciplinesQuery} from "../../../../models/disciplineworkload/query/availableEducationDisciplinesQuery.model";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {getErrorMessage} from "../../../../helpers/errorHandle-helper";
import {DropDownFilterSettings, DropDownListComponent} from "@progress/kendo-angular-dropdowns";
import {AssignmentDisciplineQuery} from "../../../../models/disciplineworkload/query/assignmentDisciplineQuery.model";
import { DateToString } from '../../../../helpers/date-helper';
import {EduGroupService} from "../../../../services/disciplineworkload/externals/edu-group.service";
import {CreateQuery} from "../../../../helpers/createQuery-helper";
import {isChildOf} from "../../../../helpers/elementRef-helper";
import {lastValueFrom} from "rxjs";
import { SelectionEvent } from '@progress/kendo-angular-grid/selection/types';
import {openDialog} from 'src/app/helpers/dialogHelper';
import {AssignmentSettingsService} from "../../../../services/disciplineworkload/assignment-settings.service";
import {StaticPropertyEnum} from "../../../../models/disciplineworkload/enums/settings.enum";
import {getStaticPropertyValue, StaticProperty} from "../../../../models/disciplineworkload/settings.model";

@Component({
  selector: 'disciplineAssignment',
  templateUrl: './disciplineAssignment.component.html',
  styleUrls: ['./disciplineAssignment.component.scss']
})

export class DisciplineAssignmentComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;
  @ViewChild('lectureFlowDropdown') private lectureFlowDropDown!: DropDownListComponent;
  @ViewChild('practiceFlowDropdown') private practiceFlowDropDown!: DropDownListComponent;

  @Input() yearAndSemestrs: any;
  @Input() faculty: any;
  @Input() trainingLevel: DictTrainingLevelGroup | null = null;
  @Input() studyForm: any;
  @Input() kafedra: any;
  @Input() editingRights: any;

  @Output() onBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  public staticPropertyEnum = StaticPropertyEnum;
  public staticProperties: StaticProperty[] = [];

  public assignmentDisciplineId: string | null = null;
  public onAssignment: boolean = false;
  public isPractice: boolean = false;
  public isAddDiscipline: boolean = false;
  public disciplineAssignment: DisciplineAssignment = new DisciplineAssignment();
  public loading: boolean = false;
  public increasedCoefficients: string[] = [];
  public customSubGroups: boolean = false;
  public classesBySubGroups: boolean = false;
  public practiceTypeId: string | null = null;
  public dictPracticeTypes: DictPracticeType[] = [];
  public dictDisciplines: any = [];
  public currentEducationDisciplines: AvailableEducationDisciplineGet[] = [];
  public amountFlowTypeWorks: DictTypeWorkGet[] = [];
  public selectedItems: any[] = [];
  public dictDisciplineOnAssignment: DictDisciplineGet = new DictDisciplineGet();

  public semesters: any[] = [];
  public isAddGroup: boolean = false;
  public eduGroups: EduGroup[] = [];
  public controllingActions: DictControllingActionGet[] = [];
  public typeWorks: TypeWorkGet[] = [];

  private editedRowIndex!: number | undefined;

  public isEditGroup: boolean = false;

  public lectureFlows: any[] = [];
  public lectureFlowItem: any = null;
  public practiceFlowItem: any = null;
  public practiceFlows: any[] = [];
  public updatedAt: any = '';
  public gridData: any[] = [];
  public editing: boolean = false;
  public groupFlows: any[] = [];
  public practiceFlowAmount: any[] = [];
  public lectureFlowAmount: any[] = [];
  public selection: { disciplineId: string | null, groupId: string }[] = [];
  public flowsDefault = {
    dictTypeWorkId: null,
    flowNumber: null
  };

  public practiceFlowToggle = false;
  public isLine: boolean = false;
  public selectedDictDiscipline: any;
  private hasRecalled: boolean = false;

  public options: any[] = [{value: true, text: 'Да'}, {value: false, text: 'Нет'}]

  //Queries
  private dictDisciplinesQuery!: DictDisciplinesQuery;
  private availableEducationDisciplinesQuery!: AvailableEducationDisciplinesQuery;
  private assignmentDisciplineQuery!: AssignmentDisciplineQuery;

  // Grouping
  public groups: GroupDescriptor[] = [{ field: "groupByDiscipline" }];
  public groupResult: GroupResult[] | any[] = [];
  public virtual: any = {
    itemHeight: 35,
  };
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public dataValidation = new FormGroup({
    practiceCalc: new FormControl(false),
    labCalc: new FormControl(false)
  })

  public calcalulationMethods = new FormGroup({
    practiceMethod: new FormControl({value: 0, disabled: false}, {nonNullable: true}),
    labMethod: new FormControl({value: 0, disabled: false}, {nonNullable: true}),
    lectureFlowNumber: new FormControl(0),
    practiceFlowNumber: new FormControl(0)
  });
  public calculationMethodCathedralWorks: CalculationMethodCathedralWorkAdd[] = [];

  public newGroupDiscipline = this.resetNewGroupDiscipline();
  public selectedEduGroup: EduGroup = new EduGroup();

  public dictTrainingLevels: DictTrainingLevel[] = [];
  public dictIncreasedCoefficients: DictIncreasedCoefficient[] = [];
  public dictCalculationMethodTypeWorks: DictCalculationMethod[] = [];
  public dictCalculationMethodCathedralWorks: DictCalculationMethod[] = [];

  public rowClass = () => ({
    "k-disabled": this.onAssignment,
  });

  public isRowSelected = (e: RowArgs): boolean => {

    return this.selection.find((_) =>
      _.disciplineId == e.dataItem.educationPlanDiscipline?.id &&
      _.groupId == e.dataItem.eduGroup?.id) != null;
  };

  public onCancel() {
    this.onBack.emit(false);
    this.closeEditor();

    this.editing = false;
    this.isEditGroup = false;

    this.assignmentDisciplineId = null;
    this.onAssignment = false;
    this.isPractice = false;
    this.practiceTypeId = null;
    this.increasedCoefficients = [];
    this.customSubGroups = false;
    this.classesBySubGroups = false;
    this.hasRecalled = false;
    this.practiceFlowItem = this.flowsDefault;
    this.lectureFlowItem = this.flowsDefault;
    this.lectureFlowAmount = [];
    this.practiceFlowAmount = [];
    this.selectedItems = [];
    this.dictDisciplineOnAssignment = new DictDisciplineGet();

    this.gridData = [];
    this.groupResult = [];

    this.editedRowIndex = undefined;
    this.dataValidation.reset();
    this.newGroupDiscipline = this.resetNewGroupDiscipline();

    this.calcalulationMethods.controls.practiceFlowNumber.setValue(0);
    this.calcalulationMethods.controls.lectureFlowNumber.setValue(0);
    this.calcalulationMethods.controls.practiceMethod.setValue(0);
    this.calcalulationMethods.controls.labMethod.setValue(0);
  }

  constructor(private assignmentsService: AssignmentsService,
              private educationPlanService: EducationPlanService,
              private dictService: DictService,
              private eduGroupService: EduGroupService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private settings: AssignmentSettingsService,
              private renderer: Renderer2) {
    this.lectureFlowItem = this.flowsDefault;
    this.practiceFlowItem = this.flowsDefault;
  }

  // Получение поручения по дисциплине
  public getAssignmentDiscipline(id: string) {
    this.loading = true;
    this.assignmentDisciplineQuery = {
      id: id,
      year: this.yearAndSemestrs.year,
      semester: this.yearAndSemestrs.semester
    }
    this.assignmentsService.getAssignmentDiscipline(this.assignmentDisciplineQuery).subscribe({
      next: (response) => {
        this.disciplineAssignment = response;
        this.studyForm = this.disciplineAssignment.studyForm;
        this.onAssignment = this.disciplineAssignment.onAssignment;
        this.isPractice = this.disciplineAssignment.disciplines[0]!.educationPlanDiscipline!.dictDiscipline!.disciplineType!.isPractice;
        this.practiceTypeId = this.disciplineAssignment.principleCalculation.practiceTypeId ?? null;
        this.increasedCoefficients = this.disciplineAssignment.principleCalculation.increasedCoefficients;
        this.updatedAt = this.disciplineAssignment.updatedAt != null ? DateToString(this.disciplineAssignment.updatedAt,'dd.MM.yyyy HH:mm') : '';
        this.isAddDiscipline = this.disciplineAssignment.isManualAddition;
        this.customSubGroups = this.disciplineAssignment.principleCalculation.customSubGroups ?? false;
        this.classesBySubGroups = this.disciplineAssignment.principleCalculation.classesBySubGroups ?? false;
        this.calculationMethodCathedralWorks = this.disciplineAssignment.principleCalculation.calculationMethodCathedralWorks;

        // Display flows
        this.lectureFlowItem = this.disciplineAssignment.principleCalculation.amountFlows
          .find((item) => item.dictTypeWork.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.lectionId))?.amountFlow;
        this.practiceFlowItem = this.disciplineAssignment.principleCalculation.amountFlows
          .find((item) => item.dictTypeWork.sName == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.practiceId))?.amountFlow;

        // Calculation method values
        const practiceMethod = this.disciplineAssignment.principleCalculation.calculationMethods
          .find((item) => item.dictTypeWork.sName == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.practiceId))?.method;
        const labMethod = this.disciplineAssignment.principleCalculation.calculationMethods
          .find((item) => item.dictTypeWork.sName == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.laboratoryId))?.method;
        if(practiceMethod != null && labMethod != null) {
          this.calcalulationMethods.controls['practiceMethod'].setValue(practiceMethod);
          this.calcalulationMethods.controls['labMethod'].setValue(labMethod);
        }

        this.disciplineAssignment.disciplines.forEach((item: AssignmentDepartmentGet) => {
          item.groupByDiscipline = item.educationPlanDiscipline.semesterNumber + '. ' +
            item.educationPlanDiscipline.disciplineFullName;
          if (this.onAssignment && this.customSubGroups && item.contingentLoad)
            item.contingentLoad.subGroupCount = item.disciplineGroup?.subEduGroups ?? 0;
        });

        // Display data on page
        this.gridData = this.disciplineAssignment.disciplines;

        this.gridData.forEach((item) => {
          // Lecture flows
          if(this.lectureFlowItem > 0) {
            item.lecturesFlows = [];
            for(let i=0; i < this.lectureFlowItem; i++) {
              item.lecturesFlows.push(i+1);
            }
          }

          // Practice flows
          if(this.practiceFlowItem > 0) {
            item.practiceFlows = [];
            for(let i=0; i < this.practiceFlowItem; i++) {
              item.practiceFlows.push(i+1);
            }
          }
        })

        this.groupResult = groupBy(this.gridData, this.groups);
        this.loading = false;
      },
      error: () => {this.loading = false;}
    })
  }

  public getDictDisciplines() {
    if (this.isAddDiscipline) {
      this.getDisciplines();
    }
    else {
      this.dictDisciplinesQuery = {
        studyYear: this.yearAndSemestrs.year,
        semester: this.yearAndSemestrs.semester,
        facultyId: this.faculty.id,
        dictStudyFormId: this.studyForm.id,
        dictTrainingLevelId: this.trainingLevel!.ids,
        kafedraId: this.kafedra.id,
        dictDisciplineTypeId: this.dictDisciplineOnAssignment.disciplineType.id
      }

      this.educationPlanService.getAvailableDictDisciplines(this.dictDisciplinesQuery).subscribe({
        next:async (response) => {
          this.updatedAt = '';
          response.forEach((_) => {
            _.fullName = _.name + ' (' + _.semesterNumber + ' семестр)';
          });

          let selectedSemester = this.selectedItems[0].educationPlanDiscipline.semesterNumber;

          this.dictDisciplines = response.filter(_ =>
            _.id != this.dictDisciplineOnAssignment.id ||
            _.id == this.dictDisciplineOnAssignment.id && _.semesterNumber != selectedSemester
          );
          this.selectedDictDiscipline = response.find((_) =>
            _.id == this.dictDisciplineOnAssignment.id && _.semesterNumber == selectedSemester
          );

          await this.addHandler();
        }
      })
    }
  }

  public async getStaticProperties() {
    await this.settings.getStaticProperties().toPromise().then(value => this.staticProperties = value!);
  }

  private async fillDropdownValues(practiceTypeWork: any, lectureTypeWork: any) {
    let flows: any = {lectureFlows: this.gridData[0].lecturesFlows, practiceFlows: this.gridData[0].practiceFlows};
    this.lectureFlowItem = {
      dictTypeWorkId: lectureTypeWork?.id,
      flowNumber: this.lectureFlowItem
    };
    this.practiceFlowItem = {
      dictTypeWorkId: practiceTypeWork?.id,
      flowNumber: this.practiceFlowItem
    }
    if(flows.lectureFlows) this.lectureFlowAmount = [...flows.lectureFlows];
    if(flows.practiceFlows) this.practiceFlowAmount = [...flows.practiceFlows];
  }

  private async checkEditingRights() {
    if(!this.editingRights) {
      this.onCancel();
      return;
    }
    this.hasRecalled = true;

    const {practiceTypeWork, lectureTypeWork} = this.findFlow();

    await this.fillDropdownValues(practiceTypeWork, lectureTypeWork);

    if (this.isAddDiscipline) {
      this.selection = [];
      this.gridData.forEach((item, index) => {
        item.groupByDiscipline = item.groupByDiscipline.split(".").pop().trim();
        item.educationPlanDiscipline.id = `${index + 1}`;
        item.disciplineGroup = null;
        item.lecturesFlows = null;
        item.practiceFlows = null;
        this.selection.push({disciplineId: `${index + 1}`, groupId: item.eduGroup.id})
      })
    }
    else {
      this.gridData.forEach((item) => {
        item.lecturesFlows = null;
        item.practiceFlows = null;
      })

      this.dictDisciplineOnAssignment = this.gridData[0].educationPlanDiscipline.electiveDictDiscipline
        ?? this.gridData[0].educationPlanDiscipline.dictDiscipline;
      this.isPractice = this.gridData[0].educationPlanDiscipline.electiveDictDiscipline?.disciplineType?.isPractice
        ?? this.gridData[0].educationPlanDiscipline.dictDiscipline.disciplineType.isPractice;

      await this.enableEditing();

      return;
    }

    this.groupResult = groupBy(this.gridData, this.groups);
    this.checkDiscipline(null, null);
  }

  // Отозвать поручение
  public recallAssignmentDisciplines() {
    const dialog: DialogRef = openDialog(this.dialogService, `Вы действительно хотите отозвать поручения?`);
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          let query = this.disciplineAssignment.id;
          this.assignmentsService.recallAssignmentDisciplineById(query).subscribe({
            next: async () => {
              this.notificationService.showSuccess('Успешно');
              // Возвращаемся на страницу поручений если у пользователя права на чтение
              await this.checkEditingRights();
            },
            error: (reason) => this.notificationService.showError(getErrorMessage(reason), 5000)
          })
        }
        else{
        }
      }
    });
  }

  mismatchStyle(dataItem: any , typework: string) {
    if (!this.onAssignment)
      return '';

    let typeWorkId = getStaticPropertyValue(this.staticProperties, typework);

    //console.log('typework', typework);
    //console.log('typeWorkId', typeWorkId);

    const typeWorks: any = dataItem.educationPlanDiscipline.typeWorks
      ?.find((item: { dictTypeWork: { id: string; }; }) => item.dictTypeWork.id == typeWorkId)
      ?.hours;
    const disciplineTypeWorks: any = dataItem.disciplineAssignmentLoad?.hours
      ?.find((item: { dictTypeWork: { id: string; }; }) => item.dictTypeWork.id == typeWorkId)
      ?.numberHours;

    return typeWorks != disciplineTypeWorks ? 'red' : '';
  }

  mismatchStudentStyle(dataItem: any) {
    if (!this.onAssignment)
      return '';

    return (dataItem?.contingentLoad?.plannedStudentCount ?? 0) != (dataItem?.disciplineGroup?.students ?? 0) ? 'red' : '';
  }

  mismatchSubGroupStyle(dataItem: any) {
    if (!this.onAssignment || this.customSubGroups)
      return '';

    return dataItem.contingentLoad?.subGroupCount != dataItem.disciplineGroup?.subEduGroups ? 'red' : '';
  }

  // Display type works on table
  public displayTypeWorks(dataItem: any, typeWorkName: string) {
    if (!dataItem)
      return '';

    let typeWorkId = getStaticPropertyValue(this.staticProperties, typeWorkName);

    //console.log('displayTypeWorks', typeWorkId);
    //console.log('typeWorks', dataItem.educationPlanDiscipline.typeWorks);
    //console.log('disciplineTypeWorkHours', dataItem.disciplineAssignmentLoad?.hours);

    const typeWorkHours: any = dataItem.educationPlanDiscipline.typeWorks
      ?.find((item: { dictTypeWork: { id: string; } }) => item.dictTypeWork.id === typeWorkId)
      ?.hours;

    if (!this.onAssignment)
      return typeWorkHours ?? 0;

    let disciplineTypeWorkHours;
    if(dataItem.disciplineAssignmentLoad) {
      disciplineTypeWorkHours = dataItem.disciplineAssignmentLoad?.hours
        .find((item: { dictTypeWork: { id: string; } }) => item.dictTypeWork.id === typeWorkId)
        ?.numberHours;
    }

    if (disciplineTypeWorkHours == typeWorkHours)
      return disciplineTypeWorkHours ?? 0;

    return (disciplineTypeWorkHours ?? '-') + ' (' + (typeWorkHours ?? '-') + ')';
  }

  public displayGroupName(group: any) {
    return group?.items?.length > 0 ? group.items[0].educationPlanDiscipline.disciplineFullName : "";
  }

  public displaySubGroup(dataItem: any): string {
    if (!dataItem) return '';

    let subGroup = dataItem.contingentLoad?.subGroupCount ?? 0;
    let subGroupFixed = dataItem.disciplineGroup?.subEduGroups ?? 0;

    if (this.customSubGroups) return this.onAssignment ? subGroupFixed : subGroup;

    if (subGroup == subGroupFixed || !this.onAssignment)
      return subGroup;

    return (subGroupFixed ?? 0) + ' (' + (subGroup ?? 0) + ')';
  }

  public displayStudentCount(dataItem: any): string {
    if (!dataItem) return '';

    let students = dataItem.contingentLoad?.plannedStudentCount ?? 0;
    let studentsFixed = dataItem.disciplineGroup?.students ?? 0;

    if (students == studentsFixed || !this.onAssignment)
      return students;

    return (studentsFixed ?? 0) + ' (' + (students ?? 0) + ')';
  }

  // Получение списка типа потоков
  public getAmountFlowTypeWorks() {
    this.settings.amountFlowTypeWorks().subscribe({
      next: (response) => {
        this.amountFlowTypeWorks = response;
        const lectureTypeWork = response.find((item) => item.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.lectionId))?.id;
        const practiceTypeWork = response.find((item) => item.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.practiceId))?.id;

        // Null check
        if(practiceTypeWork && lectureTypeWork) {
          // Create practice and lecture flows
          for(let i=1; i < 11; i++) {
            this.practiceFlows.push({
              dictTypeWorkId: practiceTypeWork,
              flowNumber: i,
            });
            this.lectureFlows.push({
              dictTypeWorkId: lectureTypeWork,
              flowNumber: i,
            })
          }
        }
        this.practiceFlows.unshift(this.flowsDefault);
        this.lectureFlows.unshift(this.flowsDefault);
      }
    })
  }

  // Получение способов расчета
  public getCalculationMethodTypeWorks() {
    this.settings.calculationMethodTypeWorks().subscribe({
      next: (response) => {
        this.dictCalculationMethodTypeWorks = response;
      }
    })
  }

  public getCalculationMethodCathedralWorks() {
    this.assignmentsService.calculationMethodCathedralWorks().subscribe({
      next: (response) => {
        this.dictCalculationMethodCathedralWorks = response;
      }
    })
  }

  private getIncreasedCoefficients() {
    this.dictService.getIncreasedCoefficients().subscribe({
      next: (value) => this.dictIncreasedCoefficients = value
    })
  }

  public displayCourseWork(dataItem: any): string {
    if (!dataItem) return '';

    let hasCourseWork = dataItem.educationPlanDiscipline.hasCourseWork;
    let hasCourseWorkFixed = dataItem.disciplineAssignmentLoad?.hasCourseWork;

    if (hasCourseWorkFixed == null || hasCourseWork == hasCourseWorkFixed)
      return hasCourseWork ? 'Да' : 'Нет';

    return (hasCourseWorkFixed ? 'Да' : 'Нет') + ' (' + (hasCourseWork ? 'Да' : 'Нет') + ')';
  }

  public displayCourseProject(dataItem: any): string {
    if (!dataItem) return '';

    let hasCourseProject = dataItem.educationPlanDiscipline.hasCourseProject;
    let hasCourseProjectFixed = dataItem.disciplineAssignmentLoad?.hasCourseProject;

    if (hasCourseProjectFixed == null || hasCourseProject == hasCourseProjectFixed)
      return hasCourseProject ? 'Да' : 'Нет';

    return (hasCourseProjectFixed ? 'Да' : 'Нет') + ' (' + (hasCourseProject ? 'Да' : 'Нет') + ')';
  }

  private async enableEditing() {
    this.editing = true;
    this.calcalulationMethods.controls.practiceMethod.enable();
    this.calcalulationMethods.controls.labMethod.enable();
    this.dataValidation.controls.practiceCalc.setValue(true);
    this.dataValidation.controls.labCalc.setValue(true);
    this.dataValidation.reset();
    this.onAssignment = false;
    this.assignmentDisciplineId = null;
    this.isLine = true;
  }

  // Проверить на наличии дисциплины в поручении
  public async checkDiscipline(id: any, selectedItems: any, addDiscipline?: boolean) {
    //console.log(this.yearAndSemestrs);
    //console.log(this.faculty);
    //console.log(this.trainingLevel);
    //console.log(this.studyForm);
    //console.log(this.kafedra);
    //console.log('dictDisciplineId', selectedItems[0].dictDisciplineId);
    //console.log('dictDisciplineTypeId', selectedItems[0].dictDisciplineTypeId);
    this.selectedItems = selectedItems;
    if(!this.onAssignment) this.isAddDiscipline = addDiscipline == true;

    // Режим конструктора
    if(!id) {
      await this.enableEditing();

      if (!this.hasRecalled) {
        this.calcalulationMethods.controls.practiceMethod.setValue(1);
        this.calcalulationMethods.controls.labMethod.setValue(2);
      }

      if(this.isAddDiscipline) {
        this.getTrainingLevels();
        this.getSemesters();
        this.getEduGroups();
        this.getControllingActions();
        this.getDisciplines();
        await this.getTypeWorks();
        return;
      }

      if (selectedItems?.length > 0) {
        this.dictDisciplineOnAssignment = selectedItems[0].educationPlanDiscipline.electiveDictDiscipline
          ?? selectedItems[0].educationPlanDiscipline.dictDiscipline;
        this.isPractice = selectedItems[0].educationPlanDiscipline.electiveDictDiscipline?.disciplineType?.isPractice
          ?? selectedItems[0].educationPlanDiscipline.dictDiscipline.disciplineType.isPractice;

        this.getDictDisciplines();
      }

      return;
    }

    // Disable editing for view
    this.calcalulationMethods.controls.practiceMethod.disable();
    this.calcalulationMethods.controls.labMethod.disable();

    this.assignmentDisciplineId = id;

    this.getAssignmentDiscipline(id);
  }

  // Добавить дисциплину в сопряжение
  public async addHandler() {
    if(this.isLine) {
      this.closeEditor();
    }

    if (this.isAddDiscipline) {
      await this.addGroup();
      this.closeAllRows(this.grid);
      return;
    }


    // Убираем выбранные текущие группы
    if(this.currentEducationDisciplines) {
      this.currentEducationDisciplines = [];
    }

    if (!this.selectedDictDiscipline) return;

    this.availableEducationDisciplinesQuery = this.createEducationDisciplineQuery();

    this.educationPlanService.getAvailableEducationDisciplines(this.availableEducationDisciplinesQuery).subscribe({
      next: async (response) => {
        response.forEach((item) => {
          item.groupFlows = [];

          const {practiceTypeWork, lectureTypeWork} = this.findFlow();

          if (practiceTypeWork) {
            item.groupFlows.push({dictTypeWorkId: practiceTypeWork.id, flowNumber: 0});
          }

          if (lectureTypeWork) {
            item.groupFlows.push({dictTypeWorkId: lectureTypeWork.id, flowNumber: 0});
          }

          item.groupByDiscipline = item.educationPlanDiscipline.semesterNumber + '. ' +
              item.educationPlanDiscipline.disciplineFullName;

          this.gridData.push(item);
          this.selection.push({
            disciplineId: item.educationPlanDiscipline.id,
            groupId: item.eduGroup.id
          });
        });

        this.groupResult = groupBy(this.gridData, this.groups);
        this.selectedDictDiscipline = null;
      }
    })
    this.isLine = true;
  }

  // Закрыть режим редактирования
  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  // Событие на смену числа потоков
  public flowChange(dataItem: any, type: string) {
    if(!dataItem) {
      return;
    }
    switch(type) {
      case 'lecture':
        this.lectureFlowAmount = [];
        // Обнуляем выставленное число потоков
        if(!dataItem.flowNumber) {
          this.lectureFlowItem = dataItem;
          this.calcalulationMethods.controls.lectureFlowNumber.setValue(0);
          return;
        }
        for (let i=0; i < dataItem.flowNumber; i++) {
          this.lectureFlowAmount.push(i+1);
        }
        this.lectureFlowItem = dataItem;
        break;
      case 'practice':
        this.practiceFlowAmount = [];
        // Обнуляем выставленное число потоков
        if(!dataItem.flowNumber) {
          this.practiceFlowItem = dataItem;
          this.calcalulationMethods.controls.practiceFlowNumber.setValue(0);
          return;
        }
        for (let i=0; i < dataItem.flowNumber; i++) {
          this.practiceFlowAmount.push(i+1);
        }
        this.practiceFlowItem = dataItem;
        //console.log("Practice flow")
        break;
    }
  }

  // Убрать добавленную дисциплину из таблицы
  public deleteItem(group: any) {
    this.gridData = [...this.gridData.filter((item) => item.groupByDiscipline != group.value)];
    this.groupResult = groupBy(this.gridData, this.groups);
    this.selectedDictDiscipline = this.dictDisciplines
      .find((item: any) => item.fullName == group.value);
  }

  private async disciplineDataValidation(): Promise<boolean> {
    if(this.gridData.find((item) => item.contingentLoad === null)) {
      this.notificationService.showError('Заполните студентов в расчет и подгруппы')
      return false;
    }

    if(this.gridData.find((item) => !item.eduGroup.id)) {
      this.notificationService.showError('Заполните группу')
      return false;
    }
    return true;
  }

  // Создать поручение
  public async saveAssignmentDiscipline() {
    if(this.isAddDiscipline) {
      const dataValidation = await this.disciplineDataValidation();
      if(!dataValidation) return;
    }

    let assignmentRequestBody = new AssignmentDisciplineAdd();
    assignmentRequestBody.dictDisciplineId =
        this.isAddDiscipline
            ? this.selectedDictDiscipline.id
            : this.dictDisciplineOnAssignment.id;
    assignmentRequestBody.dictStudyFormId = this.studyForm.id;
    assignmentRequestBody.assignmentDepartment = {
      year: this.yearAndSemestrs.year,
      semestr: this.yearAndSemestrs.semester,
      kafedraId: this.kafedra.id
    };
    assignmentRequestBody.onAssignment = true;
    assignmentRequestBody.disciplines = [];
    assignmentRequestBody.isManualAddition = this.isAddDiscipline;
    assignmentRequestBody.principleCalculation = {
      increasedCoefficients: this.increasedCoefficients,
      practiceTypeId: this.practiceTypeId,
      customSubGroups: this.customSubGroups,
      classesBySubGroups: this.classesBySubGroups,
      calculationMethods: [],
      calculationMethodCathedralWorks: this.calculationMethodCathedralWorks,
      amountFlows: []
    }

    // сгруппируем по ID дисциплине УП
    let disciplines = groupBy(
      this.gridData.filter((_) => this.selection.find((x) =>
        _.educationPlanDiscipline.id == x.disciplineId &&
        _.eduGroup.id == x.groupId) != null),
      [{ field: "educationPlanDiscipline.id" }]
    );
    //disciplines = disciplines.filter((item) => this.selection.includes(item.value));

    if (!disciplines || disciplines.length == 0) return;

    // Собираем часы по УП
    disciplines.forEach((discipline) => {
      if (!discipline) return;

      let disciplineAssignmentAdd: DisciplineAssignmentAdd = {
        educationPlanDisciplineId: this.isAddDiscipline ? null : discipline.value,
        inConjugation: discipline.items[0].educationPlanDiscipline.dictDiscipline.id != this.dictDisciplineOnAssignment.id,
        groups: [],
        studyLoad: {
          hasCourseProject: discipline.items[0].educationPlanDiscipline.hasCourseProject,
          hasCourseWork: discipline.items[0].educationPlanDiscipline.hasCourseWork,
          hours: []
        },
        dictDisciplineId: discipline.items[0].educationPlanDiscipline.dictDiscipline?.id,
        electiveDictDisciplineId: discipline.items[0].educationPlanDiscipline.electiveDictDiscipline?.id,
        semesterNumber: discipline.items[0].educationPlanDiscipline.semesterNumber,
        controlActionId: discipline.items[0].educationPlanDiscipline.dictControllingAction?.id,
        numberWeek: discipline.items[0].educationPlanDiscipline.numberWeek,
        trainingLevelId: discipline.items[0].educationPlanDiscipline.trainingLevel?.id,
      }

      discipline.items[0].educationPlanDiscipline.typeWorks.forEach((typeWork: any) => {
        disciplineAssignmentAdd.studyLoad.hours.push({
          dictTypeWorkId: typeWork.dictTypeWork.id,
          numberHours: typeWork.hours
        })
      });

      discipline.items.forEach((item: any) => {
        let group: DisciplineGroupAdd = {
          eduGroupId: item.eduGroup.id,
          studentCount: item.contingentLoad?.plannedStudentCount,
          subGroupCount: item.contingentLoad?.subGroupCount,
          groupFlows: []
        };

        item.groupFlows?.forEach((groupFlow: any) => {
          group.groupFlows.push({
            id: groupFlow.id,
            dictTypeWorkId: groupFlow.dictTypeWorkId,
            flowNumber: groupFlow.flowNumber
          })
        });

        disciplineAssignmentAdd.groups.push(group);
      });

      assignmentRequestBody.disciplines.push(disciplineAssignmentAdd);
    })

    // Calculation methods
    const practiceTypeWork = this.dictCalculationMethodTypeWorks.find((item) => item.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.practiceId))?.id;
    const labTypeWork = this.dictCalculationMethodTypeWorks.find((item) => item.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.laboratoryId))?.id;
    // Flow amounts
    const lectureTypeWork = this.amountFlowTypeWorks.find((item) => item.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.lectionId))?.id;
    // Получаем значения для способов расчета
    const practiceMethod = this.calcalulationMethods.value.practiceMethod;
    const labMethod = this.calcalulationMethods.value.labMethod;

    // Create request body
    if(practiceMethod && labMethod && practiceTypeWork && labTypeWork && lectureTypeWork) {
      // Условие с потоками
      let amountFlowArr: AmountFlowAdd[];
      // Число практических занятий пришло пустым
      if(this.practiceFlowItem == null) {
        this.practiceFlowItem = {
          dictTypeWorkId: practiceTypeWork,
          flowNumber: 0
        }
      }
      // Передаем только потоки по лекциям, если способ расчета практический занятий - подгруппа
      if(practiceMethod === 2) {
        amountFlowArr = [{dictTypeWorkId: <string>lectureTypeWork, amountFlow: this.lectureFlowItem.flowNumber}];
      }
      else {
        amountFlowArr = [
          // Количество потоков по лекциям
          {dictTypeWorkId: <string>lectureTypeWork, amountFlow: this.lectureFlowItem.flowNumber},
          // Количество потоков по практ.занятиям
          {dictTypeWorkId: <string>practiceTypeWork, amountFlow: this.practiceFlowItem.flowNumber},
        ]
      }

      assignmentRequestBody.principleCalculation.calculationMethods = [
        // Cпособ расчета практических занятий
        {dictTypeWorkId: <string>practiceTypeWork, method: <number>practiceMethod },
        // Способ расчета лабораторных занятий
        {dictTypeWorkId: <string>labTypeWork, method: <number>labMethod }
      ];
      assignmentRequestBody.principleCalculation.amountFlows = amountFlowArr;
    }

    this.assignmentsService.saveAssignmentDiscipline(assignmentRequestBody).subscribe({
      next: () => {
        this.notificationService.showSuccess("Успешно");

        this.onCancel();
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);
      }
    })
  }

  // Событие на смену галочки в потоках
  public checkBoxChange(dataItem: any, flowNumber: number, typeWorkName: string, checkbox?: any) {
    const typeWork = this.amountFlowTypeWorks.find((item) => item.id == getStaticPropertyValue(this.staticProperties, typeWorkName))?.id;
    const row = this.gridData.find(e => e.educationPlanDiscipline.id === dataItem.educationPlanDiscipline.id && e.eduGroup.name === dataItem.eduGroup.name);

    if(!row || !typeWork) return;

    const flowIndex = row.groupFlows.findIndex((item: { dictTypeWorkId: string | undefined; })=> item.dictTypeWorkId === typeWork);

    // Для потоков лекций = 1
    if((this.lectureFlowDropDown.getText() === 1 && row.groupFlows[flowIndex]?.flowNumber === 1 && typeWorkName === this.staticPropertyEnum.lectionId) ||
      (this.practiceFlowDropDown.getText() === 1 && row.groupFlows[flowIndex]?.flowNumber === 1 && typeWorkName === this.staticPropertyEnum.practiceId)) {
      row.groupFlows.splice(flowIndex, 1);
      checkbox.target.checked = false;
    }
    else {
      if (flowIndex > -1) row.groupFlows[flowIndex].flowNumber = flowNumber;
      else row.groupFlows.push({
        dictTypeWorkId: typeWork,
        flowNumber: flowNumber
      });
    }

    if(typeWorkName === this.staticPropertyEnum.practiceId && !this.calcalulationMethods.value.practiceMethod) {
      this.calcalulationMethods.controls.practiceMethod.setValue(1);
      this.dataValidation.controls.practiceCalc.setValue(true);
    }
  }

  // Событие клика по радио кнопке для валидации данных
  public radioButtonSet(formControlName: string, value: number) {
    switch (formControlName) {
      case 'labCalc':
        this.dataValidation.controls['labCalc'].setValue(true);
        if(value == 2) this.classesBySubGroups = false;
        break;
      case 'practiceCalc':
        this.dataValidation.controls['practiceCalc'].setValue(true);
        this.practiceFlowToggle = value === 2;
        // Обнуляем потоки практик, если способ расчета практических занятий - подгруппа
        if(this.practiceFlowToggle) {
          this.practiceFlowItem = this.flowsDefault;
          this.practiceFlowAmount = [];
          this.calcalulationMethods.controls['practiceFlowNumber'].setValue(0);
        }
        break;
    }
  }

  // Проставить галочки для просмотра
  public viewonlyCheckbox(groupFlows: any, index: number, typework: string) {
    const typeWorkId = this.amountFlowTypeWorks.find((item) => item.id == getStaticPropertyValue(this.staticProperties, typework))?.id;
    const flowNumber = groupFlows.find((item: { dictTypeWorkId: string | undefined; }) => item.dictTypeWorkId == typeWorkId)?.flowNumber;
    return flowNumber === index;
  }

  public selectionKey(context: RowArgs): { disciplineId: string | null, groupId: string } {
    return {
      disciplineId: context.dataItem?.educationPlanDiscipline?.id,
      groupId: context.dataItem?.eduGroup?.id
    };
  }

  private createEducationDisciplineQuery(): AvailableEducationDisciplinesQuery {
    return {
      studyYear: this.yearAndSemestrs.year,
      semester: this.yearAndSemestrs.semester,
      facultyId: this.faculty.id,
      dictStudyFormId: this.studyForm.id,
      dictTrainingLevelId: this.trainingLevel!.ids,
      kafedraId: this.kafedra.id,
      dictDisicplineId: this.selectedDictDiscipline.id,
      disciplineSemesterNumber: this.selectedDictDiscipline.semesterNumber
    }
  }

  public changeDictDiscipline(dataItem: any) {
    this.selectedDictDiscipline = dataItem;
    this.editedRowIndex = undefined;
  }

  public updateAssignmentDiscipline() {
    if (!this.assignmentDisciplineId) return;

    this.assignmentsService.updateAssignmentDiscipline(this.assignmentDisciplineId).subscribe({
      next: () => {
        this.notificationService.showSuccess("Успешно");
        this.checkDiscipline(this.assignmentDisciplineId, this.selectedItems);
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);
      }
    })
  }

  public getDisciplines() {
    this.dictService.getDisciplines().subscribe({
      next: value => {
        value.forEach((item) => {
          item.fullName = item.name;
        });
        this.dictDisciplines = value;
      }
    });
  }

  public getSemesters() {
    switch(this.yearAndSemestrs.semester) {
      case 1:
        this.semesters = [1, 3, 5, 7, 9, 11];
        break;
      case 2:
        this.semesters = [2, 4, 6, 8, 10, 12];
        break;
    }
  }

  private closeAllRows(sender: GridComponent) {
    for(let i = 0; i < this.gridData.length; i++) {
      sender.closeRow(i);
    }
    this.isAddGroup = false;
    this.isEditGroup = false;
    this.editedRowIndex = undefined;
  }

  public async addGroup() {
    if(this.isEditGroup) {
      return;
    }

    this.newGroupDiscipline = this.resetNewGroupDiscipline();
    await this.resetTypeWorkValues(this.typeWorks);

    this.isAddGroup = true;
    this.gridData.push(await this.addGroupDiscipline());
    //this.editedRowIndex = rowIndex;
    await this.closeAllRows(this.grid);
    this.groupResult = groupBy(this.gridData, this.groups);
  }

  public lastOrderNum: number | undefined = 0;

  public async editDisciplineGroup({ isEdited, rowIndex, sender, dataItem, columnIndex }: CellClickEvent) {
    console.log('isEdited', isEdited);
    console.log('onAssignment', this.onAssignment);
    console.log('isEditGroup', this.isEditGroup);

    // если поручение создано, то запретим редактирование
    if(isEdited || this.onAssignment || this.isEditGroup) return;

    if(this.editedRowIndex && this.lastOrderNum) {
      await this.saveGroupDisciplineValues(this.editedRowIndex, this.lastOrderNum);
    }

    await this.closeAllRows(sender);

    this.newGroupDiscipline = new FormGroup({
      educationPlanDiscipline: new FormGroup({
        numberWeek: new FormControl(dataItem.educationPlanDiscipline.numberWeek),
        hasCourseWork: new FormControl(dataItem.educationPlanDiscipline.hasCourseWork),
        hasCourseProject: new FormControl(dataItem.educationPlanDiscipline.hasCourseProject),
        semesterNumber: new FormControl(dataItem.educationPlanDiscipline.semesterNumber),
        yearAdmission: new FormControl(dataItem.educationPlanDiscipline.yearAdmission),
        electiveDictDiscipline: new FormControl(dataItem.educationPlanDiscipline.electiveDictDiscipline),
        id: new FormControl(dataItem.educationPlanDiscipline.id),
        dictDiscipline: new FormControl(dataItem.educationPlanDiscipline.dictDiscipline),
        disciplineFullName: new FormControl(dataItem.educationPlanDiscipline.disciplineFullName),
        dictControllingAction: new FormControl(dataItem.educationPlanDiscipline.dictControllingAction, Validators.required),
        trainingLevel: new FormControl(dataItem.educationPlanDiscipline.trainingLevel),
        typeWorks: new FormArray([])
      }),
      eduGroup: new FormControl(dataItem.eduGroup, Validators.required),
      contingentLoad: new FormGroup({
        plannedStudentCount: new FormControl(dataItem.contingentLoad?.plannedStudentCount),
        subGroupCount: new FormControl(dataItem.contingentLoad?.subGroupCount, Validators.required)
      }),
    })

    await this.resetTypeWorkValues(dataItem.educationPlanDiscipline.typeWorks);
    this.isEditGroup = true;
    this.lastOrderNum = dataItem.orderNum;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.newGroupDiscipline, {columnIndex});
  }

  private getEduGroups() {
    let query = CreateQuery({
      studyYear: this.yearAndSemestrs.year,
      semester: this.yearAndSemestrs.semester,
      studyFormId: this.studyForm?.id,
      trainingLevelId: this.trainingLevel?.ids,
      facultyId: this.faculty?.id ? this.faculty?.id : null
    });
    this.eduGroupService.getEduGroups(query).subscribe({
      next: value => {
        value.forEach((item) => {
          item.fullName = item.name + ` (${item.studentCount} чел.)`
        })
        this.eduGroups = value;
      }
    })
  }

  private getControllingActions() {
    this.dictService.getControllingActions().subscribe({
      next: value => this.controllingActions = value
    })
  }

  private async saveGroupDisciplineValues(index: number, orderNum?: number) {
    if(orderNum) {
      index = this.gridData.findIndex((item: AvailableEducationDisciplineGet) => item.orderNum === orderNum)
    }
    this.gridData[index].educationPlanDiscipline = this.newGroupDiscipline.value.educationPlanDiscipline;
    this.gridData[index].eduGroup = this.newGroupDiscipline.value.eduGroup;
    this.gridData[index].contingentLoad = this.newGroupDiscipline.value.contingentLoad;
    this.isEditGroup = false;
  }

  private async addGroupDiscipline() {
    const educationPlanDisciplineItem: EducationPlanDisciplineGet = {
      dictControllingAction: null,
      dictDiscipline: this.selectedDictDiscipline,
      disciplineFullName: this.selectedDictDiscipline.name,
      electiveDictDiscipline: null,
      trainingLevel: this.dictTrainingLevels.find((item) => item.id === this.eduGroups[0].trainingLevelId) ?? new DictTrainingLevel(),
      hasCourseProject: false,
      hasCourseWork: false,
      id: `${this.gridData.length + 1}`,
      kafedra: this.kafedra,
      numberWeek: 1,
      semesterNumber: null,
      typeWorks: [...this.typeWorks],
    }

    const {lectureTypeWork, practiceTypeWork} = this.findFlow();

    const availableEducationItem: AvailableEducationDisciplineGet = {
      contingentLoad: null,
      eduGroup: new EduGroup(),
      educationPlanDiscipline: educationPlanDisciplineItem,
      groupByDiscipline: this.selectedDictDiscipline.name,
      groupFlows: [
        {dictTypeWorkId: lectureTypeWork!.id, flowNumber: 0},
        {dictTypeWorkId: practiceTypeWork!.id, flowNumber: 0}
      ],
      orderNum: this.gridData.length + 1
    }

    this.selection.push({
      disciplineId: educationPlanDisciplineItem.id,
      groupId: availableEducationItem.eduGroup?.id
    });

    return availableEducationItem;
  }

  private findFlow() {
    const practiceTypeWork = this.amountFlowTypeWorks.find((item) => item.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.practiceId));
    const lectureTypeWork = this.amountFlowTypeWorks.find((item) => item.id == getStaticPropertyValue(this.staticProperties, this.staticPropertyEnum.lectionId));
    return {practiceTypeWork, lectureTypeWork}
  }

  public onEduGroupChange(value: EduGroup, educationPlanDisciplineId: string, rowIndex: number) {
    this.newGroupDiscipline.get('educationPlanDiscipline.trainingLevel')?.setValue(this.dictTrainingLevels
        .find((item) => item.id === value?.trainingLevelId) ?? null);
    this.gridData[rowIndex].eduGroup = value;
    const selectionIndex = this.selection.findIndex((item) => item.disciplineId === educationPlanDisciplineId);
    this.selection[selectionIndex] = {disciplineId: educationPlanDisciplineId, groupId: value.id};
    this.selectedEduGroup = value;
  }

  private getTrainingLevels() {
    this.dictService.getTrainingLevels().subscribe({
      next: (value) => this.dictTrainingLevels = value
    })
  }

  public removeGroupDiscipline({rowIndex }: RemoveEvent): void {
    this.gridData = this.gridData.filter((elem, i) => i !== rowIndex);
    this.groupResult = groupBy(this.gridData, this.groups);

    this.closeAllRows(this.grid);
  }

  private async getTypeWorks() {
   await lastValueFrom(this.dictService.getDictTypeWorks()).then(
       (response) => {
        response.forEach((item) => {
          this.typeWorks.push({
            dictTypeWork: item,
            hours: 0
          })
        })
    })
    await this.resetTypeWorkValues(this.typeWorks);
  }

  private async resetTypeWorkValues(typeWorks: TypeWorkGet[]) {
    const control = <FormArray>this.newGroupDiscipline.get('educationPlanDiscipline.typeWorks');
    typeWorks.forEach((item) => {
      control.push(this.patchTypeWorkValues(item));
    })
  }

  private patchTypeWorkValues(item: TypeWorkGet) {
    return new FormGroup({
      hours: new FormControl(item.hours),
      dictTypeWork: new FormControl(item.dictTypeWork)
    })
  }

  public getTypeWorksFormGroupIndex(typeWork: string) {
    return this.newGroupDiscipline.get('educationPlanDiscipline.typeWorks')?.value
        ?.findIndex((item: { dictTypeWork: { id: string; }; }) => item.dictTypeWork.id == getStaticPropertyValue(this.staticProperties, typeWork)) ?? 0
  }

  private resetNewGroupDiscipline() {
    return new FormGroup({
      educationPlanDiscipline: new FormGroup({
        numberWeek: new FormControl(0),
        hasCourseWork: new FormControl(false),
        hasCourseProject: new FormControl(false),
        semesterNumber: new FormControl(null, Validators.required),
        dictControllingAction: new FormControl(),
        dictDiscipline: new FormControl(),
        disciplineFullName: new FormControl(),
        id: new FormControl(),
        yearAdmission: new FormControl(),
        electiveDictDiscipline: new FormControl(),
        trainingLevel: new FormControl(),
        typeWorks: new FormArray([])
      }),
      eduGroup: new FormControl(),
      contingentLoad: new FormGroup({
        plannedStudentCount: new FormControl(),
        subGroupCount: new FormControl()
      }),
    })
  }

  public groupSelectionChange(e: SelectionEvent) {
    e.deselectedRows?.forEach((item: {
      dataItem: {
        educationPlanDiscipline: { id: string },
        eduGroup: { id: string }
      }
    }) => {
      this.selection = this.selection.filter((_) =>
        _.disciplineId != item.dataItem.educationPlanDiscipline.id &&
        _.groupId != item.dataItem.eduGroup.id);
    });

    e.selectedRows?.forEach((item: {
      dataItem: {
        educationPlanDiscipline: { id: string },
        eduGroup: { id: string }
      }
    }) => {
      if (!this.selection) this.selection = [];
      this.selection.push({
        disciplineId: item.dataItem.educationPlanDiscipline.id,
        groupId: item.dataItem.eduGroup.id
      });
    });
  }

  public increasedCoefficientCheck = (id: string): boolean => {
    return this.increasedCoefficients.find((_) => id == _) != null;
  }

  public increasedCoefficientChange(e: any, id: string) {
    var coef = this.increasedCoefficients.find(_ => _ == id);

    if(e && coef == null) {
      this.increasedCoefficients.push(id);
    } else if (!e && coef != null) {
      this.increasedCoefficients = this.increasedCoefficients.filter(_ => _ != id);
    }
  }

  private async getPracticeTypes() {
    this.dictService.getPracticeTypes().subscribe((value) => this.dictPracticeTypes = value);
  }

  public practiceTypeChange(e: any, id: string) {
    this.practiceTypeId = e ? id : null;
  }

  public calculationMethodCathedralWorkCheck(item: DictCalculationMethod, value: number) {
    let method = this.calculationMethodCathedralWorks.find(_ => _.dictCathedralWorkId == item.id);
    return method == null && item.defaultMethod == value || method != null && method.method == value;
  }

  public calculationMethodCathedralWorkChange(id: string, value: number) {
    let method = this.calculationMethodCathedralWorks
      .find(_ => _.dictCathedralWorkId == id);

    if (method == null) {
      method = new CalculationMethodCathedralWorkAdd();
      method.dictCathedralWorkId = id;
      this.calculationMethodCathedralWorks.push(method);
    }

    method.method = value;
  }

  public classesBySubGroupsCheck(e: any) {
    if (e) this.calcalulationMethods.controls.labMethod.setValue(1);
  }

  async ngOnInit() {
    this.getAmountFlowTypeWorks();
    this.getCalculationMethodTypeWorks();
    this.getCalculationMethodCathedralWorks();
    this.getIncreasedCoefficients();
    await this.getStaticProperties();
    await this.getPracticeTypes();

    this.renderer.listen("document", "click", ({ target }) => {
      //console.log('editedRowIndex', this.editedRowIndex);
      //console.log('newGroupDiscipline.valid', this.newGroupDiscipline.valid);
      //console.log('newGroupDiscipline.value', this.newGroupDiscipline.value);

      if (!isChildOf(target, "k-grid-edit-row") && this.editedRowIndex != undefined && this.newGroupDiscipline.valid) {
        this.saveGroupDisciplineValues(this.editedRowIndex, this.lastOrderNum);
        this.closeAllRows(this.grid);
      }
    });
  }
}

<blanks-settings></blanks-settings>
<h1>Шаблоны</h1>

<kendo-grid [kendoGridBinding]="blankTemplates"
            [loading]="loading"
            [pageable]="pagerSettings"
            [pageSize]="pageSize"
            (cellClick)="editRow($event)"
            (remove)="onRemove($event)"
            (add)="addRow()"
            [sortable]="true">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
        <button
            kendoGridAddCommand
            themeColor="success"
            *ngIf="!isLine"
            icon="plus"
        >
            Добавить
        </button>
        <div class="toolbarButtons" *ngIf="isLine">
            <button kendoButton (click)="onCancel()">Отмена</button>
            <button
                kendoButton
                (click)="saveRow()"
                themeColor="primary"
                [disabled]="formGroup!.invalid"
            >
            Сохранить
            </button>
        </div>
    </ng-template>

    <ng-template kendoGridLoadingTemplate>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column 
        [width]="600"
        headerClass="gridHeader dictionaries"
        field="name"
        title="Изготовитель"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ dataItem.name }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-textbox [formControl]="formGroup.get('name')" class="editRecord">
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
        [width]="200"
        headerClass="gridHeader dictionaries"
        field="graduateYear"
        title="Год выпуска"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.graduateYear }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-numerictextbox
          [value]="dataItem.graduateYear"
          [min]="2000"
          [max]="9999"
          format="n0"
          [decimals]="0"
          [maxlength]="4"
          [formControl]="formGroup.get('graduateYear')">
      </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        [width]="300"
        headerClass="gridHeader dictionaries"
        field="order"
        title="Порядковый номер"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.order }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-numerictextbox
            [value]="dataItem.order"
            [min]="1"
            format="n0"
            [decimals]="0"
            [formControl]="formGroup.get('order')">
          </kendo-numerictextbox>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" [width]="100" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button
            kendoGridRemoveCommand
            themeColor="secondary"
            icon="delete"
            fillMode="flat"
            size="none"
            class="custom-size"
            rounded="full"
          ></button>
        </span>
      </ng-template>
    </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>
import { Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-send-students',
    templateUrl: './students.component.html',
    styleUrls: ['./students.component.scss']
})

export class StudentsComponent implements OnInit{
    
    VisibleAdvancedSearch: boolean = true;

   public advanced_search(): void {
        this.VisibleAdvancedSearch = !this.VisibleAdvancedSearch;
    }
    
    constructor(
        private router: Router,
    ) { }

    ngOnInit() {
    }
}
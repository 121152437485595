import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent, PagerPosition, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { DisciplineType } from 'src/app/models/dicts/disciplinetype.model';
import { Discipline } from 'src/app/models/dicts/discipline.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DisciplineTypeService } from 'src/app/services/dicts/disciplinetype.service';
import { DisciplineService } from 'src/app/services/dicts/discipline.service';
import { Guid } from 'guid-typescript';
import { PagerType } from '@progress/kendo-angular-pager';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { Router } from '@angular/router';
import { isChildOf } from 'src/app/helpers/elementRef-helper';

@Component({
  selector: 'app-discipline',
  templateUrl: './discipline.component.html',
  styleUrls: ['./discipline.component.css']
})
export class DisciplineComponent implements OnInit {

  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20, 50, 100];
  public previousNext = true;
  public positionPager: PagerPosition = 'bottom';
  public skip = 0;

  public loading = false;
  public disciplinetypes!: DisciplineType[];
  public disciplinetype: DisciplineType | {} = {}
  public disciplines: Discipline[] = [];
  public discipline: Discipline | {} = {}
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;
  public names: string[] | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private disciplinetypeService: DisciplineTypeService,
              private disciplineService: DisciplineService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService,
              private router: Router
    ) {
  }

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllDisciplineType();
    //this.getAllDiscipline();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.skip = 0
    this.filter = filter;
  }

  public pageChange({ skip }: PageChangeEvent): void {
    this.skip = skip;
  }

  //Move to info about education plans of current discipline
   public moveToInfo(disciplineId: Guid) {
    window.open('dicts/discipline/info/' + disciplineId, '_blank')
  }

  //Get discipline name for id
  public namedisciplinetype(id: Guid): DisciplineType | undefined {
    return this.disciplinetypes.find((x) => x.dictDisciplineTypeExternalId === id);
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.getAllDiscipline();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      dictDisciplineId: 0,
      disciplineName: '',
      disciplineSName: '',
      disciplineType: undefined,
      dictDisciplineType: this.disciplinetype,
      dictDisciplineExternalId: Guid.create()
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.disciplineService.addDiscipline(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllDiscipline();
              this.notificationService.showSuccess("Добавлено");
              this.discipline = {};
            },
            error:(error) => {
              this.notificationService.showError(error.error);
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.disciplineService.updateDiscipline(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllDiscipline();
              this.notificationService.showSuccess("Сохранено");
            },
            error:(error) => {
              this.notificationService.showError(error.error);
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllDisciplineType() {
    this.disciplinetypeService.getAllDisciplineType()
      .subscribe(
        response => {
          this.disciplinetypes = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllDiscipline() {
    this.loading = true;
    this.disciplineService.getAllDiscipline()
      .subscribe({
        next:(response) => {
          this.disciplines = response;
          this.loading = false;
        },
        error:(error) => {
          console.log(error.error);
          this.notificationService.showError("Отсутствует подключение к серверу");
          this.loading = false;
        }});
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelDiscipline == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelDiscipline != 1;
            this.getAllDiscipline();
          }
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.disciplineName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.disciplineService.deleteDiscipline(dataItem.dictDisciplineExternalId)
          .subscribe({
            next:() => {
              this.getAllDiscipline();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
}

const formGroup = (dataItem: { dictDisciplineId?: any; disciplineName?: any; disciplineSName?: any; disciplineType?: any; dictDisciplineType?: any; dictDisciplineExternalId?: any}) =>
  new FormGroup({
    dictdisciplineId: new FormControl(dataItem.dictDisciplineId),
    disciplineName: new FormControl(dataItem.disciplineName, Validators.required),
    disciplineSName: new FormControl(dataItem.disciplineSName),
    disciplineType: new FormControl(dataItem.disciplineType, Validators.required),
    dictDisciplineType: new FormControl(dataItem.dictDisciplineType, Validators.required),
    dictDisciplineExternalId: new FormControl(dataItem.dictDisciplineExternalId)
  });
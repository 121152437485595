import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';

@Injectable({
  providedIn: 'root'
})
export class TrainingLevelService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.trainingLevel}`;

  constructor(private http: HttpClient) { }

  //Get all trainingLevels
  public getAllTrainingLevels(): Observable<TrainingLevel[]> {
    return this.http.get<TrainingLevel[]>(this.baseUrl + '/GetAllTrainingLevels');
  }

}

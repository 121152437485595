import {Guid} from "guid-typescript";
import {GlobalSignatoryMCRead} from "./globalsignatory.model";


export class LocalSignatoryMCRead {
  public id!: Guid | string;
  public dictSignatoryRoleId!: Guid | string;
  public trainingLevels!: LocalSignatoryTrainingLevelMCReadDto[];
  public faculties!: LocalSignatoryFacultyMCReadDto[];
  public filials!: FilialReadDto[];
  public dictLocalSignatoryRoleId!: Guid | string;
}

export class LocalSignatoryTrainingLevelMCReadDto {
  public id!: string;
  public trainingLevelId!: Guid | string;
  public trainingLevelName!: string;
}

export class LocalSignatoryFacultyMCReadDto {
  public id!: string;
  public facultyId!: Guid | string;
  public facultyName!: string;
  public facultyShortName!: string;
}

export class FilialReadDto {
  public id!: string;
  public filialId!: string;
  public filialSName!: string;
}

export class LocalSignatoryMCAdd {
  public globalSignatoryId!: Guid | string;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public localSignatoryRole!: number;
}

export class LocalSignatoryMCUpdate {
  public id!: Guid | string;
  public globalSignatoryId!: Guid | string;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public localSignatoryRole!: number;
}

export class LocalSignatoryRole {
  public externalId!: Guid | string;
  public name!: string;
  public order?: number;
  public value!: number;
}

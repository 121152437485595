import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {dictPagerSettings} from "../../../../models/dicts/pagerSettings.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {getData$} from "../../../../../environments/environment";
import {openDialog} from "../../../../helpers/dialogHelper";
import {DictsService} from "../../../../services/gia/dicts.service";
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";
import {GiaDictMethods} from "./gia-dict.methods";
import {GiaDictValue} from "../../../../models/gia/dicts/dicts.model";
import {AccessRights} from "../../../../models/useraccess/gia/giaUserAccess";
import {SortDescriptor} from "@progress/kendo-data-query";

@Component({
  selector: 'gia-dict',
  templateUrl: './gia-dict.component.html',
  styleUrls: ['./gia-dict.component.scss']
})
export class GiaDictComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  // Pager settings
  public pagerSettings: PagerSettings = dictPagerSettings;
  public sort: SortDescriptor[] = [{ field: "", dir: "asc", }];

  public isNew: boolean = false;
  public isLine: boolean = false;
  private editedRowIndex: number | undefined;

  private dictId: string = '';
  public dictTitle: string = '';
  public dictData: GiaDictValue[] = [];

  public formGroup: FormGroup = this.resetFormGroup;
  public editable = this.userAccessService.currentUserAccess$.value.dict === AccessRights.Write;

  constructor(private activateRoute: ActivatedRoute,
              private dialogService: DialogService,
              private dictMethods: GiaDictMethods,
              private userAccessService: GiaUserAccessService,
              private dictService: DictsService) {
    this.getValuesFromRoute();
    this.getAllDicts();
  }

  async ngOnInit() {}

  private getAllDicts() {
    this.dictService.dicts$.subscribe(() => {
      this.getDictTitle();
    })
  }

  private getDictTitle() {
    const dicts = this.dictService.dicts$.value;
    if (dicts.length > 0) this.dictTitle = dicts.find((item) => item.dictId === this.dictId)?.name ?? '';
  }

  private getValuesFromRoute() {
    this.activateRoute.params.subscribe(params => {
      this.resetPreviousValues();
      this.dictId = params['dictId'];
      getData$.subscribe(() => this.getDictData())
      this.getDictTitle();
    });
  }

  private resetPreviousValues() {
    this.dictData = [];
    setTimeout(() => this.onCancel(), 100);
  }

  private getDictData() {
    this.dictService.getDictData(this.dictId).subscribe((value) => {
      this.dictData = value;
    })
  }

  private get resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      sortNumber: new FormControl(null),
      isActive: new FormControl(true, Validators.required)
    })
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  public addRow() {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      sortNumber: new FormControl(null),
      isActive: new FormControl(true, Validators.required)
    })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public async saveRow()  {
    if (this.formGroup) await this.dictMethods.saveDict(this.isNew, this.formGroup, this.dictId);
    this.onCancel();
  }

  public async editRow({ sender, rowIndex, dataItem, columnIndex }: CellClickEvent) {
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name),
      sortNumber: new FormControl(dataItem.sortNumber),
      isActive: new FormControl(dataItem.isActive)
    })

    if (this.isNew || !this.editable || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.onCancel();
    this.isLine = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}?"`);
    this.dictMethods.removeDict(dialog.result, dataItem.id);
  }

}

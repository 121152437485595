import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {trainingLevelModel, DepartmentsModel, PersonsModel, BlankTypesModel} from '../../models/diplomablanks/dicts.model';

@Injectable({
    providedIn: 'root'
})

export class GeneralDictsService {

  private baseDictUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.dict}`;
  private baseHomeUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.home}`;

  constructor(private http: HttpClient) {}

  public getTrainingLevel() {
    return this.http.get<trainingLevelModel[]>(this.baseDictUrl + '/trainingLevel');
  }

  public getDepartments() {
    return this.http.get<DepartmentsModel[]>(this.baseHomeUrl + '/department');
  }

  public getPersons(departmentId?: string) {
    let params = new HttpParams();
    if (departmentId) params = params.append('departmentId', departmentId);
    return this.http.get<PersonsModel[]>(this.baseHomeUrl + '/person', {params});
  }

  public getYear() {
    return this.http.get<number[]>(this.baseHomeUrl + '/blanks/GetIssueYears');
  }

  public getExistBlankTypes(dictTrainingLevelId?: string[] | [null], withIssued: boolean = false) {
    const data = {'dictTrainingLevelIds': dictTrainingLevelId,
                  'withBlanks': true, 'withIssued': withIssued};
    return this.http.post<BlankTypesModel[]>(this.baseDictUrl + '/blanktype/getAll', data);
  }

  public getExistTrainingLevel() {
    let params = new HttpParams();
    params = params.append('WithBlanks', true);
    return this.http.get<trainingLevelModel[]>(this.baseDictUrl + '/trainingLevel', {params});
  }
}

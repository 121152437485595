export const items = [
  {
    text: 'Настройки',
    icon: 'cog',
    items: [
      {
        text: 'Конструктор заявок',
        path: '/mfc/applicationConstructor/',
        applicationConstructor: true
      },
      {
        text: 'Отчеты',
        path: '/mfc/reports/',
        reports: true
      },
      {
        text: 'Шаблоны справок',
        path: '/mfc/templates/',
        templates: true
      },
      {
        text: 'Создание справочников',
        path: '/mfc/dictCreator/',
        dictCreator: true
      },
      {
        text: 'Справочники',
        dicts: true,
        items: [
          {
            text: 'Категории заявок',
            path: '/mfc/dictApplicationCategory/',
          },
          {
            text: 'Типы заявок',
            path: '/mfc/dictApplicationType/',
          },
          {
            text: 'Варианты получения справок',
            path: '/mfc/dictTypeReceipt/',
          },
          {
            text: 'Типы согласования заявок',
            path: '/mfc/dictApprovalType/',
          },
          {
            text: 'Роли операторов',
            path: '/mfc/dictOperatorRole/',
          },
          {
            text: 'Статусы согласования',
            path: '/mfc/dictApprovalStatus/',
          },
          {
            text: 'Литеры подразделений',
            path: '/mfc/dictDepartmentLitera/',
          },
          {
            text: 'Контактные данные подразделений',
            path: '/mfc/dictDepartmentContactDetails/',
          },
          {
            text: 'Статусы заявок',
            path: '/mfc/dictApplicationStatus/',
          },
          {
            text: 'Гарантии компенсации для справки-вызов',
            path: '/mfc/dictCompensationGuarantee/',
          },
          {
            text: 'Предполагаемые должности выпускников',
            path: '/mfc/dictWorkProfile/',
          },
        ],
      },
      {
        text: 'Настройки штампов ЭП',
        path: '/mfc/stamp/',
        stamp: true
      },
      {
        text: 'Настройки доступа',
        path: '/mfc/userAccess/',
        accessSettings: true
      },
    ]
  }
];

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AddEvent, CellClickEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { Guid } from 'guid-typescript';
import { Mark } from 'src/app/models/dicts/mark.model';
import { MarkService } from 'src/app/services/dicts/mark.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { CommonDictionariesUserAccessService } from 'src/app/services/useraccess/common-dictionaries-user-access.service';
import { Router} from "@angular/router";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-mark',
  templateUrl: './mark.component.html',
  styleUrls: ['./mark.component.css']
})
export class MarkComponent implements OnInit {
  public emptyGuid: any = Guid.createEmpty();
  public marks: Mark[] = [];
  public mark: Mark = {
    id: this.emptyGuid.value,
    markName: '',
    markSName: '',
    orderNumber: null,
    markValue: 0,
    markColor: '',
    markIcon: ''
  };
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public isAdmin: boolean = false;
  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  public gridView: any | GridDataResult;

  constructor(private markService: MarkService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: CommonDictionariesUserAccessService,
              private router: Router,
  ) {}

  public ngOnInit(): void {
    this.getAccessLevel();
   // this.getAllMarks();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadMarks();
  }

  private loadMarks(): void {
    this.gridView = {
      data: orderBy(this.marks, this.sort),
      total: this.marks.length,
    };
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      id: this.emptyGuid.value,
      markName: '',
      markSName: '',
      orderNumber: null,
      markValue: 0,
      markColor: '',
      markIcon: ''
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    this.isLine = false;
    grid.closeRow(rowIndex);
    this.formGroup = undefined;
    this.editedRowIndex = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup) {
        this.markService.addMark(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllMarks();
              this.notificationService.showSuccess("Добавлено");
              this.mark = {
                id: this.emptyGuid.value,
                markName: '',
                markSName: '',
                orderNumber: null,
                markValue: 0,
                markColor: '',
                markIcon: ''
              }
            },
            error => {
              this.notificationService.showError("Не удалось добавить запись");
            }
          );
      }
      //Update dictionary
      else if (this.formGroup) {
        this.markService.updateMark(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllMarks();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllMarks() {
    this.markService.getAllMarks()
      .subscribe(
        response => {
          this.marks = response;
          this.loadMarks();
        },
        error => {
          this.notificationService.showError("Не удалось получить оценки");
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(
        response => {
          if (response.accessLevelEducation == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelEducation==1?false:true;
            this.getAllMarks();
          }
        },error => {});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.markName.toLowerCase()} ?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.markService.deleteMark(dataItem.id)
          .subscribe(
            ( response: any) => {
              this.getAllMarks();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { id?: any; markName?: any; markSName?: any; orderNumber?: any; markValue?: any; markColor?: any; markIcon?: any }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    markName: new FormControl(dataItem.markName, Validators.required),
    markSName: new FormControl(dataItem.markSName, Validators.required),
    orderNumber: new FormControl(dataItem.orderNumber),
    markValue: new FormControl(dataItem.markValue, Validators.required),
    markColor: new FormControl(dataItem.markColor),
    markIcon: new FormControl(dataItem.markIcon),
  });

  const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

  const isChildOf = (el: any, className: any) => {
    while (el && el.parentElement) {
      if (hasClass(el.parentElement, className)) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  };

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  CellClickEvent,
  GridComponent, PagerSettings,
} from '@progress/kendo-angular-grid';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { DictLocalSignatoryRoleService } from '../../../../services/gia/dict-local-signatory-role.service';
import { GiaUserAccessService } from '../../../../services/useraccess/gia-user-access.service';
import {LocalSignatoryRole} from "../../../../models/gia/signatory/localsignatory.model";
import {dictPagerSettings} from "../../../../models/dicts/pagerSettings.model";
import {GiaDictLocalSignatoryRoleMethods} from "./gia-dict-local-signatory-role.methods";
import {getData$} from "../../../../../environments/environment";

@Component({
  selector: 'app-gia-dict-local-signatory-role',
  templateUrl: './gia-dict-local-signatory-role.component.html',
  styleUrls: ['./gia-dict-local-signatory-role.component.scss'],
})
export class GiaDictLocalSignatoryRoleComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  // Pager settings
  public pagerSettings: PagerSettings = dictPagerSettings;

  public dictLocalSignatoryRole: LocalSignatoryRole[] = [];
  public editable?: boolean;
  private editedRowIndex: number | undefined;
  private isLine = false;
  public formGroup: FormGroup = this.resetFormGroup;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined; /*|| this.isNew;*/
  }

  constructor(
    private dictLocalSignatoryRoleService: DictLocalSignatoryRoleService,
    private dialogService: DialogService,
    private notificationService: NotificationsService,
    private userAccessService: GiaUserAccessService,
    private dictMethods: GiaDictLocalSignatoryRoleMethods
  ) {}

  public ngOnInit(): void {
    this.getAccessLevel();
    getData$.subscribe(() => this.getListLocalSignatoryRole())
  }

  public onStateChange(): void {
    this.getListLocalSignatoryRole();
  }

  public editHandler({ sender, rowIndex, dataItem }: CellClickEvent) {
    if (!this.editable) return;

    this.isLine = true;
    this.onCancel();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public async saveRow()  {
    if (this.formGroup) await this.dictMethods.saveDict(this.formGroup);
    this.onCancel();
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  public getListLocalSignatoryRole() {
    this.dictLocalSignatoryRoleService.getLocalSignatoryRoles().subscribe(value => this.dictLocalSignatoryRole = value);
  }

  private getAccessLevel() {
    this.userAccessService
      .getCurrentUserAccess()
      .subscribe((response) => (this.editable = response.localSignatory));
  }

  private get resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      order: new FormControl(null, Validators.required)
    })
  }

}

const formGroup = (dataItem: {
  id?: string;
  name?: string;
  order?: number;
}) =>
  new UntypedFormGroup({
    id: new UntypedFormControl(dataItem.id),
    name: new UntypedFormControl(dataItem.name, Validators.required),
    order: new UntypedFormControl(dataItem.order, Validators.required),
  });

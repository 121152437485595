import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/publications/errorHandle-helper";
import {DiplomaBlankModel} from "../../models/gia/diplomaBlank.model";

@Injectable({
  providedIn: 'root'
})
export class DiplomaBlanksService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.diplomaBlank}`;
  constructor(private http: HttpClient) { }

  public getBlanks(studentId: string, dictDiplomaTypeId: string) {
    return this.http.get<DiplomaBlankModel[]>(`${this.baseUrl}/GetBlanks/${studentId}/${dictDiplomaTypeId}`).pipe(catchError(handleError));
  }
}

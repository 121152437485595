import {UserTypeEnum} from "./userType.model";
import {UserTypesEnum} from "../enums/userTypes.enum";
import {UserStatus} from "../enums/UserStatus";

export  class UserSignatureBrief {

  constructor(id: string, fio: string, userTypeEnum: UserTypesEnum, departmentName: string) {
    this.id = id;
    this.fio = fio;
    this.userTypeEnum = userTypeEnum;
    this.departmentName = departmentName
  }

  public id!: string;
  public fio!: string;
  public userTypeEnum!: UserTypesEnum;
  public departmentName!: string;
  public status!: number;
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CompetenceList, Competence, CompetencePayload } from 'src/app/models/education/oopCompetences.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EducationProgramCompetenciesService {
  baseUrl = `${environment.educationApiEndpoint}${environment.apiPaths.education.oop}`;

  constructor(private http: HttpClient) { }

  //Get all Competence by ProgramId
  public getAllCompetenceByProgramId(programId: string) {
    return this.http.get<CompetencePayload>(this.baseUrl + '/' + programId + '/competencies/')
      .pipe(
        catchError(this.handleError));
  }

  // Add Competence
  addCompetence(competence: CompetenceList, programId: string): Observable<CompetenceList> {
    return this.http.post<CompetenceList>(this.baseUrl + '/' + programId + '/competencies/', competence)
      .pipe(catchError(this.handleError));
  }

  // Edit OOPMatrixCompetence
  updateCompetence(competence: CompetenceList, programId: string, competenceId: string): Observable<CompetenceList> {
    return this.http.put<CompetenceList>(this.baseUrl + '/' + programId + '/competencies/' + competenceId + '/', competence)
      .pipe(catchError(this.handleError));
  }
  // Remove OOPMatrixCompetence
  deleteCompetence(programId: string, competenceId: string): Observable<CompetenceList> {
    return this.http.delete<CompetenceList>(this.baseUrl + '/' + programId + '/competencies/' + competenceId)
      .pipe(catchError(this.handleError));
  }

  // Copy comptetence
  copyCompetence(competence: CompetenceList, destinationEducationProgramId: string, sourceEducationProgramId: string): Observable<CompetenceList> {
    return this.http.post<CompetenceList>(this.baseUrl + '/copyCompetence/' + destinationEducationProgramId + '/' + sourceEducationProgramId, competence)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

<div class="m-t30">
  <kendo-grid [data]="gridData">
    <kendo-grid-column title="Расчетные листы" headerClass="gridHeader">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="row-table-payslips" (click)="downloadPayslipFile(dataItem.nameFile)">{{dataItem.nameFile}}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoButton
          class="row-table-payslips"
          icon="save"
          (click)="downloadPayslipFile(dataItem.nameFile)">
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div>
    <p class="warning-delete-paysleps">Расчетные листы хранятся 3 года, по истечению срока хранения они удаляются и становятся недоступны для скачивания в данном разделе.
      При необходимости их получения обращайтесь в бухгалтерию.</p>
  </div>
</div>



import {Guid} from "guid-typescript";

export class LocalSignatoryRole {
  public id!: string;
  public name!: string;
  public order?: number;
  public value!: number;
}

export class SignatoryRole {
  public id!: string;
  public name!: string;
  public serialNumber?: number;
}

export class LocalSignatoryDto {
  public id!: string;
  public dictSignatoryRoleId!: string;
  public trainingLevels!: LocalSignatoryDictDto[];
  public departments!: LocalSignatoryDictDto[];
  public filials!: LocalSignatoryDictDto[];
  public sections!: LocalSignatoryDictDto[];
  public dictLocalSignatoryRoleId?: string;
}

export class LocalSignatoryDict {
  trainingLevels!: LocalSignatoryDictDto[];
  departments!: LocalSignatoryDictDto[];
  filials!: LocalSignatoryDictDto[];
  sections!: LocalSignatoryDictDto[];
}

export class LocalSignatoryDictDto {
  public id?: string;
  public name?: string;
  public shortName?: string;
}

export class LocalSignatoryAdd {
  public globalSignatoryId!: string;
  public trainingLevels!: string[];
  public faculties!: string[];
  public localSignatoryRole!: number;
}

export class LocalSignatoryUpdate {
  public id!: string;
  public globalSignatoryId!: string;
  public trainingLevels!: string[];
  public faculties!: string[];
  public localSignatoryRole!: number;
}

export class BlanksAvailableModel {
    blankTypeId!: string;
    blankTypeName!: string;
    externalId!: string;
    id!: number;
    series!: string;
    number!: string;
    blankTemplateId!: string;
    blankTemplateName!: string;
    addDate!: string | Date;
    checked?: boolean;
}

export class BlankModel {
    externalId!: string;
    id!: string;
    number!: string;
    series!: string;
    typeName!: string;
}

export class blanksPersonDataModel {
    availableBlanks?: number;
    usedBlanks?: number;
    spoiledBlanks?: number;
}

export class IssueBlanksModel {
    issuerPersonId!: string | null;
    recipientPersonId!: string | null;
    ids!: number[];
}

export class ReceiptModel {
    issuerPersonId!: string | null;
    recipientPersonId!: string | null;
    issueDate!: Date | string;
    dictBlankTypeId!: string | null;
}

export class AddBlanksModel {
    dictBlankTypeId!: string;
    dictBlankTemplateId!: string;
    series!: string;
    numberFirstBlank!: string;
    count!: number;
}

export class reportModel {
    dictTrainingLevelIds!: string[] | null;
    dictBlankTemplateId?: string | null;
    addDate!: Date | string | null;
}
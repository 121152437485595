import {Routes} from '@angular/router';
import {MFCHomeComponent} from '../components/mfc/home/mfc-home.component';
import {MFCSettingsComponent} from '../components/mfc/settings/mfc-settings.component';
import {Type} from '@angular/core';
import {ApplicationConstructorComponent} from "../components/mfc/applicationConstructor/home/application-constructor.component";
import {UserAccessComponent} from "../components/mfc/userAccess/userAccess.component";
import {LocalSignatoryComponent} from "../components/mfc/localSignatory/localSignatory.component";
import {ApplicationFormHomeComponent} from '../components/mfc/applicationForm/home/application-form.component';
import {StampHomeComponent} from "../components/mfc/stamp/home/stamp-home.component";
import {MFCReportsComponent} from "../components/mfc/reports/reports.component";
import {StampFormComponent} from "../components/mfc/stamp/form/stamp-form.component";
import {TemplatesHomeComponent} from "../components/mfc/templates/home/templates-home.component";
import {TemplateFormComponent} from "../components/mfc/templates/templateForm/template-form.component";
import {ApplicationFormTabComponent} from "../components/mfc/applicationForm/tab/application-form-tab.component";
import {DynamicPropertiesComponent} from "../components/mfc/applicationForm/dynamicProperties/dynamic-properties.component";
import {ApplicationFormApprovalListComponent} from "../components/mfc/applicationForm/approvalList/approval-list.component";
import {AsPipe} from "../components/mfc/pipes/aspipe.pipe";
import {ApplicationFormCommentsComponent} from "../components/mfc/applicationForm/comments/comments.component";
import {ApplicationFormDocumentsComponent} from "../components/mfc/applicationForm/documents/documents.component";
import {TypedTemplateDirective} from "../directives/typed-template.directive";
import {
  ApplicationConstructorFormComponent
} from '../components/mfc/applicationConstructor/form/application-constructor-form.component';
import {
  ApplicationConstructorTabComponent
} from '../components/mfc/applicationConstructor/tab/application-constructor-tab.component';
import {
  ApplicationConstructorMainSettingsComponent
} from '../components/mfc/applicationConstructor/settings/main/main-settings.component';
import {
  ApplicationConstructorTabsSettingsComponent
} from '../components/mfc/applicationConstructor/settings/tabs/tabs-settings.component';
import {
  ApplicationConstructorApprovalSettingsComponent
} from '../components/mfc/applicationConstructor/settings/approval/approval-settings.component';
import {MfcUserAccessGuard} from '../guards/mfc-user-access.guard';
import {MfcRoleGuard} from '../guards/mfc-role.guard';
import {
  ApplicationConstructorAdditionalFiltersComponent
} from '../components/mfc/applicationConstructor/settings/filters/additional-filters.component';
import {ApplicationCategoryComponent} from "../components/mfc/dicts/applicationCategory/application-category.component";
import {ApplicationTypeComponent} from "../components/mfc/dicts/applicationType/application-type.component";
import {TypeReceiptComponent} from "../components/mfc/dicts/typeReceipt/type-receipt.component";
import {ApprovalTypeComponent} from "../components/mfc/dicts/approvalType/approval-type.component";
import {RoleComponent} from "../components/mfc/dicts/role/role.component";
import {ApprovalStatusComponent} from "../components/mfc/dicts/approvalStatus/approval-status.component";
import {
  DepartmentContactDetailsComponent
} from "../components/mfc/dicts/departmentContactDetails/department-contact-details.component";
import {DepartmentLiteraComponent} from "../components/mfc/dicts/departmentLitera/department-litera.component";
import {ApplicationStatusComponent} from "../components/mfc/dicts/applicationStatus/application-status.component";
import {
  CompensationGuaranteeComponent
} from "../components/mfc/dicts/compensationGuarantee/compensation-guarantee.component";
import {DynamicDictComponent} from "../components/mfc/dicts/dynamic/dynamic-dict.component";
import {DictCreatorComponent} from "../components/mfc/dictCreator/dict-creator.component";
import {
  ConstructorListBoxComponent
} from '../components/mfc/applicationConstructor/settings/listbox/constructor-listbox.component';
import {WorkProfileComponent} from "../components/mfc/dicts/workProfile/work-profile.component";

export const MFCRoutes: Routes = [
  {path: 'mfc', component: MFCHomeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/applicationConstructor', component: ApplicationConstructorComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/applicationConstructor/constructorForm', component: ApplicationConstructorFormComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/applicationConstructor/constructorForm/:id', component: ApplicationConstructorFormComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/applicationConstructor/constructorForm/:id/additionalFilters/:index', component: ApplicationConstructorAdditionalFiltersComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/userAccess', component: UserAccessComponent, canActivate: [MfcUserAccessGuard]},
  {path: 'mfc/applicationForm', component: ApplicationFormHomeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/applicationForm/:id', component: ApplicationFormHomeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/stamp', component: StampHomeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/stamp/stampForm/:type', component: StampFormComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/reports', component: MFCReportsComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/templates', component: TemplatesHomeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/templates/templateForm/:id', component: TemplateFormComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictApplicationCategory', component: ApplicationCategoryComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictWorkProfile', component: WorkProfileComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictApplicationType', component: ApplicationTypeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictTypeReceipt', component: TypeReceiptComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictApprovalType', component: ApprovalTypeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictOperatorRole', component: RoleComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictApprovalStatus', component: ApprovalStatusComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictDepartmentContactDetails', component: DepartmentContactDetailsComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictDepartmentLitera', component: DepartmentLiteraComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictApplicationStatus', component: ApplicationStatusComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictCompensationGuarantee', component: CompensationGuaranteeComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dict/:dictId', component: DynamicDictComponent, canActivate: [MfcRoleGuard]},
  {path: 'mfc/dictCreator', component: DictCreatorComponent, canActivate: [MfcRoleGuard]},
];

export const MFCComponents: Type<unknown>[] = [
  MFCHomeComponent,
  MFCSettingsComponent,
  ApplicationConstructorComponent,
  ApplicationConstructorFormComponent,
  ApplicationConstructorTabComponent,
  ApplicationConstructorMainSettingsComponent,
  ApplicationConstructorTabsSettingsComponent,
  ApplicationConstructorApprovalSettingsComponent,
  ApplicationConstructorAdditionalFiltersComponent,
  UserAccessComponent,
  LocalSignatoryComponent,
  ApplicationFormHomeComponent,
  StampHomeComponent,
  MFCReportsComponent,
  StampFormComponent,
  TemplatesHomeComponent,
  TemplateFormComponent,
  ApplicationFormTabComponent,
  DynamicPropertiesComponent,
  ApplicationFormApprovalListComponent,
  ApplicationFormCommentsComponent,
  ApplicationFormDocumentsComponent,
  ApplicationCategoryComponent,
  ApplicationTypeComponent,
  TypeReceiptComponent,
  ApprovalTypeComponent,
  RoleComponent,
  ApprovalStatusComponent,
  DepartmentContactDetailsComponent,
  DepartmentLiteraComponent,
  ApplicationStatusComponent,
  CompensationGuaranteeComponent,
  DynamicDictComponent,
  DictCreatorComponent,
  ConstructorListBoxComponent,
  TypedTemplateDirective,
  WorkProfileComponent,
  AsPipe
];

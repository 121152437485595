import {Component, OnInit} from '@angular/core';
import {AvailableSemesters, DictStudyForm} from "../../../models/disciplineworkload/externals.model";
import {Subject} from "rxjs";
import {groupBy, GroupDescriptor} from "@progress/kendo-data-query";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {AdditionalWorkService} from "../../../services/disciplineworkload/additional-work.service";
import {arrayRewrite} from "../../../helpers/multiselect-helper";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {EducationPlanService} from "../../../services/disciplineworkload/externals/education-plan.service";
import {TeacherWorkloadService} from "../../../services/disciplineworkload/teacher-workload.service";
import {CreateQuery} from "../../../helpers/createQuery-helper";
import {
  TeacherLoadFacultyGet,
  TeacherLoadStudyFormsGet,
  TeacherLoadTrainingLevelsGet
} from '../../../models/disciplineworkload/teachetworkload.model';
import {TypeWorkEnum} from "../../../models/disciplineworkload/enums/type-work.enum";
import {getErrorMessage} from "../../../helpers/errorHandle-helper";
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { saveAs } from '@progress/kendo-file-saver';
import { ReportsService } from '../../../services/disciplineworkload/reports.service';
import { DepartmentWorkloadService } from '../../../services/disciplineworkload/department-workload.service';
import {
  DisciplineWorkloadUserAccessService
} from '../../../services/useraccess/disciplineworkload-user-access.service';

@Component({
  selector: 'app-teacher-workload',
  templateUrl: './teacher-workload.component.html',
  styleUrls: ['./teacher-workload.component.scss']
})
export class TeacherWorkloadComponent implements OnInit {
  //#region Global var

  public typeWorks: any = TypeWorkEnum;

  public userEditing: boolean = false;

  //#region Filters

  public yearAndSemestrs: AvailableSemesters[] = [];
  public kafedras: any[] = [];
  public persons: any[] = [];
  public trainingLevels: TeacherLoadTrainingLevelsGet[] = [];
  public faculties: any[] = [];
  public studyForms: TeacherLoadStudyFormsGet[] = [];

  //#region Filter ng models

  public kafedraEdit: any;
  public teacherEdit: any;
  public yearAndSemestrsEdit: any;
  public trainingLevelsEdit: any[] = [];
  public studyFormEdit: any[] = [];
  public facultiesEdit: any;

  //#endregion

  //#region Settings

  public setFiltersFromLocalStorage: boolean = false;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public virtual: any = {
    itemHeight: 28,
  };

  public numberFormatOptions: NumberFormatOptions = {
   style: 'decimal'
  };

  //#endregion

  //#endregion

  //#region Enter main load

  public isEditTeacherLoad: boolean = false;

  public enteredLoadGroupTypeWork: any[] = [];
  public enteredLoadSubGroupTypeWork: any[] = [];
  public enteredLoadFlowTypeWork: any[] = [];

  //#endregion

  //#region Enter additional load

  public isEditAdditionalWork: boolean = false;

  public enteredLoadAdditionalWork: any[] = [];

  //#endregion

  //#region Confirm dialog

  public removeConfirmationSubject: Subject<boolean> = new Subject<boolean>();
  public itemToRemove: any;

  //#endregion

  //#region Teacher load

  public gridTeacherLoads: any[] = [];
  public teacherLoads: any[] = [];

  //#region Custom row properties

  public isGroupFlow?: boolean;
  public isSubGroup?: boolean;
  public isDiscipline?: boolean;

  //#endregion

  //#region Group settings

  public groupsData: GroupDescriptor[] = [
    { field: "semesterNumber" },
    { field: "discipline.name" },
    { field: "groupByGroupId" },
    { field: "groupBySubGroupNumber" },
    { field: "value" },
    { field: "typeWorks.typeWork.name" }
  ];

  //#endregion

  //#endregion

  //#region Additional work

  public extraWork: any[] = [];

  //#endregion

  //#endregion

  constructor(
    private additonalWorkService: AdditionalWorkService,
    private notificationService: NotificationsService,
    private sanitizer: DomSanitizer,
    private educationPlanService: EducationPlanService,
    private teacherLoadService: TeacherWorkloadService,
    private reportService: ReportsService,
    private departmentWorkloadService: DepartmentWorkloadService,
    private userAccess: DisciplineWorkloadUserAccessService) { }

  //#region Data

  private async getDepartments(setEditModel: boolean = true) {
    this.kafedras = [];
    if (setEditModel) this.kafedraEdit = null;

    await this.teacherLoadService.getAvailableKathedras().toPromise().then(
      response => {
        this.kafedras = response!.kathedras;

        if (this.kafedras?.length > 0 && setEditModel) {
          this.kafedraEdit = response!.kathedras[0];
        }
      });
  }

  private async getStudyForms(setEditModel: boolean = true) {
    this.studyForms = [];
    if (setEditModel) this.studyFormEdit = [];

    let query = CreateQuery({
      studyYear: this.yearAndSemestrsEdit?.year,
      semestr: this.yearAndSemestrsEdit?.semester,
      kathedraId: this.kafedraEdit?.id,
      personId: this.teacherEdit?.id
    });

    await this.teacherLoadService.getAvailableStudyForms(query).toPromise().then(
      response => {
        this.studyForms = response!.studyForms;

        if (this.studyForms?.length > 0) {
          let item = new DictStudyForm();
          item.id = "";
          item.studyFormName = 'Все';

          this.studyForms.unshift(item);
          if (setEditModel) this.studyFormEdit = [this.studyForms[0].id];
        }
      })
  }

  private async getTrainingLevels(setEditModel: boolean = true) {
    this.trainingLevels = [];
    if (setEditModel) this.trainingLevelsEdit = [];

    let query = CreateQuery({
      studyYear: this.yearAndSemestrsEdit?.year,
      semestr: this.yearAndSemestrsEdit?.semester,
      kathedraId: this.kafedraEdit?.id,
      personId: this.teacherEdit?.id
    });

    await this.teacherLoadService.getAvailableTrainingLevels(query).toPromise().then(
      response => {
        this.trainingLevels = response!.trainingLevels;

        if (this.trainingLevels?.length > 0) {
          let item = new TeacherLoadTrainingLevelsGet();
          item.id = "";
          item.name = 'Все';

          this.trainingLevels.unshift(item);
          if (setEditModel) this.trainingLevelsEdit = [this.trainingLevels[0].id];
        }
      })
  }

  private async getFaculties(setEditModel: boolean = true) {
    this.faculties = [];
    if (setEditModel) this.facultiesEdit = [];

    let query = CreateQuery({
      studyYear: this.yearAndSemestrsEdit?.year,
      semestr: this.yearAndSemestrsEdit?.semester,
      kathedraId: this.kafedraEdit?.id,
      personId: this.teacherEdit?.id
    });

    await this.teacherLoadService.getAvailableFaculties(query).subscribe(
      (response) => {
        this.faculties = response.faculties;

        if (this.faculties?.length > 0) {
          let item = new TeacherLoadFacultyGet();
          item.id = "";
          item.name = 'Все';
          item.sName = 'Все';

          this.faculties.unshift(item);
          if (setEditModel) this.facultiesEdit = this.faculties[0];
        }
      })
  }

  private async getAllYearAndSemestrs(setEditModel: boolean = true) {
    this.yearAndSemestrs = [];
    if (setEditModel) this.yearAndSemestrsEdit = null;

    await this.educationPlanService.getAvailableSemesters().toPromise().then(response => {
      response!.forEach((item) => {
        item.name = `${item.year} - ${item.year + 1}, ${item.semester} семестр`;
      })

      this.yearAndSemestrs = response!;
      if (setEditModel) this.yearAndSemestrsEdit = response![0];
    })
  }

  private async getPersons(filters: { kathedraId: string, studyYear: number }, setEditModel: boolean = true) {
    this.persons = [];
    if (setEditModel) this.teacherEdit = null;

    if (!filters.kathedraId) return;

    await this.teacherLoadService.getAvailablePersons(filters).toPromise().then(
      response => {
        this.persons = response!.persons;
        if (setEditModel) this.teacherEdit = response!.persons[0];
      })
  }

  private getAdditionalWorks() {
    this.extraWork = [];

    if(this.yearAndSemestrsEdit == null || this.kafedraEdit == null || this.teacherEdit == null) return;

    let query = CreateQuery({
      studyYear: this.yearAndSemestrsEdit.year,
      semester: this.yearAndSemestrsEdit.semester,
      kafedraId: this.kafedraEdit.id,
      personId: this.teacherEdit.id,
      dictTrainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      dictStudyFormId: this.studyFormEdit,
      facultyId : this.facultiesEdit?.id
    });

    this.teacherLoadService.getAdditionalWorks(query).subscribe(
      (response:any) => this.extraWork = response.additionalWorks)
  }

  private getTeacherLoad() {
    this.gridTeacherLoads = [];
    this.teacherLoads = [];

    //console.log(this.yearAndSemestrsEdit == null || this.kafedraEdit == null || this.teacherEdit == null)

    if(this.yearAndSemestrsEdit == null || this.kafedraEdit == null || this.teacherEdit == null) return;

    const query = CreateQuery({
      personId: this.teacherEdit.id,
      studyYear: this.yearAndSemestrsEdit?.year,
      semester: this.yearAndSemestrsEdit?.semester,
      kafedraId: this.kafedraEdit?.id,
      trainingLevels: this.trainingLevelsEdit,
      studyForms: this.studyFormEdit,
      faculties: this.facultiesEdit.id
    });

    this.teacherLoadService.getTeacherLoad(query).subscribe(
      (response) => {
        this.teacherLoads = response.teacherLoads;

        this.teacherLoads.forEach((item) => {
          if (item.disciplineConjugations == null || item.disciplineConjugations.length == 0)
            return;

          item.discipline.name = item.discipline.name + ' (+' + item.disciplineConjugations.join(', ') + ')';
        });

        const groups = this.teacherLoads
          .reduce(function (r, a) {
            return r.concat(a.groups.map(function (b: any) {
              return {
                semesterNumber: b.semesterNumber,
                discipline: a.discipline,
                groupByGroupId: b.id,
                isGroup: true,
                groups: b,
              };
            }));
          }, []);
        const subGroupNumber = groups.reduce(function (r:any, a:any) {
          return r.concat(a.groups.typeWorks.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              groupBySubGroupNumber: a.groupByGroupId + "_подгруппа" + b.subGroupNumber,
              subGroupNumber: b.subGroupNumber > 0 ? b.subGroupNumber : null,
              classesBySubGroups: true,
              emptyGroupRow: b.subGroupNumber == 0 || b.subGroupNumber == null
            };
          }));
        }, []);
        const uniqueSubGroupNumber = [
          ...new Map(subGroupNumber.map((item: any) => [item["groupBySubGroupNumber"], item])).values(),
        ] as any[];
        const groupsWorkTypes = groups.reduce(function (r:any, a:any) {
          return r.concat(a.groups.typeWorks.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              groupBySubGroupNumber: a.groupByGroupId + "_подгруппа" + b.subGroupNumber,
              typeWorks: b,
              id: b.id,
              isGroupWorkType: true,
              hasSubGroups: b.subGroups?.length > 0 };
          }));
        }, []);
        const groupsSubGroups = groupsWorkTypes.reduce(function (r:any, a:any) {
          return r.concat(a.typeWorks.subGroups.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              groupBySubGroupNumber: a.groupBySubGroupNumber,
              typeWorks: a.typeWorks,
              id: a.id,
              subGroup: b,
              isSubGroup: true
            }
          }))
        }, []);
        const flows = this.teacherLoads
          .reduce(function (r, a) {
            return r.concat(a.flows.map(function (b: any) {
              return {
                semesterNumber: b.groups.reduce((prev: any, curr: any) => prev.semesterNumber < curr.semesterNumber ? prev : curr)?.semesterNumber,
                discipline: a.discipline,
                groupByGroupId: b.id,
                flows: b,
                isFlow: true
              }
            }));
          }, []);
        const flowsWorkTypes = flows.reduce(function (r: any, a: any) {
          return r.concat(a.flows.typeWorks.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              typeWorks: b,
              id: b.id,
              isFlowWorkType: true
            }
          }))
        }, []);

        const discipline = [...groups, ...flows].map(function(x: any) {
          return {
            uniqueDisciplineName: x.discipline.name + '_' + x.semesterNumber,
            semesterNumber: x.semesterNumber,
            discipline: x.discipline,
            isDiscipline: true
          }
        }).sort((prev: any, curr: any) =>
          prev.semesterNumber === curr.semesterNumber
            ? 0
            : prev.semesterNumber > curr.semesterNumber
              ? 1
              : -1);
        const uniqueDiscipline = [
          ...new Map(discipline.map((item: any) => [item["uniqueDisciplineName"], item])).values(),
        ] as any[];

        let gridDataNotGrouped = [
          ...uniqueDiscipline, ...flows, ...flowsWorkTypes, ...groups,
          ...uniqueSubGroupNumber, ...groupsWorkTypes,
          ...groupsSubGroups.sort(function(a: any, b: any) {return a.subGroup.number - b.subGroup.number;})
        ].slice();

        this.gridTeacherLoads = groupBy(gridDataNotGrouped, this.groupsData);

        //console.log(this.gridTeacherLoads);
      });
  }

  //#endregion

  //#region Teacher load

  //#region Display

  public rowCallback = (args: any) => ({
    'hide-row': args.dataItem.Discontinued || args.dataItem.emptyGroupRow
  });

  public colorCode(dataItem: any): SafeStyle {
    //console.log(dataItem);

    let result = '#000000';

    if(!dataItem.loadHours) return this.sanitizer.bypassSecurityTrustStyle(result);
    if(dataItem.completedHours > dataItem.loadHours) result =  '#FF0000';
    if(dataItem.completedHours == dataItem.loadHours) result =  '#539A47';

    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  public displayGroupFlowInfo(dataItem: any){
    //console.log(dataItem);

    let text = " ";
    if(dataItem.groups != undefined) {
      text = dataItem.groups?.eduGroup?.studentCount + " чел.";

      if (dataItem.groups?.students > 0)
        text += ", " + dataItem.groups?.students + " чел. в расчет"
      if (dataItem.groups?.subGroups)
        text += ", " + dataItem.groups?.subGroups + " п/гр."

      text = " (" + text + ")";
    }
    if(dataItem.flows !== undefined && dataItem.flows.groups.length > 0)
    {
      text += " ("
      dataItem.flows.groups.forEach(function(item: any, index: any) {
        text += item.eduGroup.name;
        if (index != (dataItem.flows.groups.length - 1)) {
          text += ', ';
        }
      })
      text += ")"
    }
    return text;
  }

  public displayParameters(dataItem: any){
    if (!dataItem?.typeWorks) return "";

    if(dataItem.isGroupWorkType && (
      dataItem.typeWorks.typeWork?.name === this.typeWorks.practicalLesson ||
      dataItem.typeWorks.typeWork?.name === this.typeWorks.lab
    ) && dataItem.typeWorks.parameters?.subGroupCount)
      return dataItem.typeWorks.parameters.subGroupCount + " п/г"

    if(dataItem.typeWorks.parameters?.examType)
      return dataItem.typeWorks.parameters.examType == 1? "Устно": "Письменно"

    return "";
  }

  public displayGroupFlowName(dataItem: any){
    //console.log(dataItem);

    return dataItem?.groups
      ? dataItem.groups.eduGroup.name
      : dataItem?.flows
        ? 'Поток ' + dataItem.flows.number
        : '';
  }

  //#endregion

  //#region Enter load

  public enterLoad() {
    this.isEditTeacherLoad = true;
    this.enteredLoadGroupTypeWork = [];
    this.enteredLoadSubGroupTypeWork = [];
    this.enteredLoadFlowTypeWork = [];
  }

  public async saveLoad() {
    if (this.enteredLoadGroupTypeWork.length > 0) {
      for (let i = 0; i < this.enteredLoadGroupTypeWork.length; i++) {
        let work = this.enteredLoadGroupTypeWork[i];

        await this.teacherLoadService.writeOffGroupTypeWorkHours({
          id: work.id,
          completedHours: work.completedHours
        }).toPromise().then(
          result => this.notificationService.showSuccess('Сохранено'),
          reason => this.notificationService.showError(getErrorMessage(reason))
        );
      }

      this.enteredLoadGroupTypeWork = [];
    }
    if (this.enteredLoadSubGroupTypeWork.length > 0) {
      for (let i = 0; i < this.enteredLoadSubGroupTypeWork.length; i++) {
        let work = this.enteredLoadSubGroupTypeWork[i];

        await this.teacherLoadService.writeOffSubGroupTypeWorkHours({
          id: work.id,
          completedHours: work.completedHours
        }).toPromise().then(
          result => this.notificationService.showSuccess('Сохранено'),
          reason => this.notificationService.showError(getErrorMessage(reason))
        );
      }

      this.enteredLoadSubGroupTypeWork = [];
    }
    if (this.enteredLoadFlowTypeWork.length > 0) {
      for (let i = 0; i < this.enteredLoadFlowTypeWork.length; i++) {
        let work = this.enteredLoadFlowTypeWork[i];

        await this.teacherLoadService.writeOffFlowTypeWorkHours({
          id: work.id,
          completedHours: work.completedHours
        }).toPromise().then(
          result => this.notificationService.showSuccess('Сохранено'),
          reason => this.notificationService.showError(getErrorMessage(reason))
        );
      }

      this.enteredLoadFlowTypeWork = [];
    }

    this.isEditTeacherLoad = false;

    this.getTeacherLoad();
  }

  public cancelInputLoad() {
    this.isEditTeacherLoad = false;
    this.enteredLoadGroupTypeWork = [];
    this.enteredLoadSubGroupTypeWork = [];
    this.enteredLoadFlowTypeWork = [];
  }

  public loadChange(event: any, dataItem: any) {
    //console.log('event', event);
    //console.log('dataItem', dataItem);

    let newLoad;

    if (dataItem.isGroupWorkType) {
      newLoad = this.enteredLoadGroupTypeWork.find(_ => _.id === dataItem.typeWorks.id);

      if (!newLoad) {
        newLoad = {
          id: dataItem.typeWorks.id,
          completedHours: event
        };

        this.enteredLoadGroupTypeWork.push(newLoad);
      }

      newLoad.completedHours = event;
    } else if (dataItem.isSubGroup) {
      newLoad = this.enteredLoadSubGroupTypeWork.find(_ => _.id === dataItem.subGroup.id);

      if (!newLoad) {
        newLoad = {
          id: dataItem.subGroup.id,
          completedHours: event
        };

        this.enteredLoadSubGroupTypeWork.push(newLoad);
      }

      newLoad.completedHours = event;
    } else if (dataItem.isFlowWorkType) {
      newLoad = this.enteredLoadFlowTypeWork.find(_ => _.id === dataItem.typeWorks.id);

      if (!newLoad) {
        newLoad = {
          id: dataItem.typeWorks.id,
          completedHours: event
        };

        this.enteredLoadFlowTypeWork.push(newLoad);
      }

      newLoad.completedHours = event;
    }
  }

  //#endregion

  //#endregion

  //#region Additional works

  //#region Enter load

  public enterAdditionalLoad() {
    this.isEditAdditionalWork = true;
  }

  public async saveAdditionalLoad() {
    if (this.enteredLoadAdditionalWork == null || this.enteredLoadAdditionalWork.length == 0) return;

    for (let i = 0; i < this.enteredLoadAdditionalWork.length; i++) {
      let work = this.enteredLoadAdditionalWork[i];

      await this.additonalWorkService.writeOffHours({
        additionalWorkId: work.id,
        completedHours: work.completedHours
      }).toPromise().then(
        () => {},
        reason => this.notificationService.showError(getErrorMessage(reason))
      );
    }

    this.notificationService.showSuccess('Сохранено');

    this.enteredLoadAdditionalWork = [];
    this.isEditAdditionalWork = false;

    this.getAdditionalWorks();
  }

  public cancelInputAdditionalLoad() {
    this.isEditAdditionalWork = false;
    this.enteredLoadAdditionalWork = [];
  }

  public additionalLoadChange(event: any, dataItem: any) {
    //console.log('event', event);
    //console.log('dataItem', dataItem);

    let newLoad = this.enteredLoadAdditionalWork.find(_ => _.id === dataItem.id);

    if (!newLoad) {
      newLoad = {
        id: dataItem.id,
        completedHours: event
      };

      this.enteredLoadAdditionalWork.push(newLoad);
    }

    newLoad.completedHours = event;
  }

  //#endregion

  //#endregion

  //#region Confirm dialog

  public confirmRemove(shouldRemove: boolean): void {
    this.removeConfirmationSubject.next(shouldRemove);
    this.itemToRemove = null;
  }

  public removeConfirmation(dataItem: any): Subject<boolean> {
    this.itemToRemove = dataItem;
    return this.removeConfirmationSubject;
  }

  //#endregion

  //#region Filters

  public async yearAndSemestrsValueChange(value: any) {
    this.yearAndSemestrsEdit = value;

    await this.getPersons({ kathedraId: this.kafedraEdit?.id, studyYear: this.yearAndSemestrsEdit?.year });
    await this.getTrainingLevels();
    await this.getFaculties();
    await this.getStudyForms();

    this.filterGrid();
  }

  public async kafedraValueChange(value: any) {
    this.kafedraEdit = value;

    await this.getPersons({ kathedraId: this.kafedraEdit?.id, studyYear: this.yearAndSemestrsEdit?.year });
    await this.getTrainingLevels();
    await this.getFaculties();
    await this.getStudyForms();

    this.filterGrid();
  }

  public async teacherValueChange(value: any) {
    this.teacherEdit = value;

    await this.getTrainingLevels();
    await this.getFaculties();
    await this.getStudyForms();

    this.filterGrid();
  }

  public facultiesValueChange(value: any) {
    this.facultiesEdit = value;

    this.filterGrid();
  }

  public studyFormValueChange(value: any) {
    this.studyFormEdit = arrayRewrite(value);

    this.filterGrid();
  }

  public trainingLevelValueChange(value: any) {
    this.trainingLevelsEdit = arrayRewrite(value);

    this.filterGrid();
  }

  public filterGrid() {
    this.hasEditingRightsInTeacherLoad();

    this.getTeacherLoad();
    this.getAdditionalWorks();

    this.setFilterOptions();
  }

  //#endregion

  //#region Export

  public exportStudyWork() {
    if (!this.yearAndSemestrsEdit || !this.kafedraEdit) return;

    this.reportService.getExcelReportTeachersLoadOfSubdepartment({
      studyYear: this.yearAndSemestrsEdit.year,
      kafedraId: this.kafedraEdit.id,
      personId: this.teacherEdit?.id,
      trainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      studyFormId: this.studyFormEdit.includes('') ? null : this.studyFormEdit,
      facultyId: this.facultiesEdit?.id == '' ? null : this.facultiesEdit?.id
    });
  }

  public exportPlanFact() {
    if (!this.yearAndSemestrsEdit && !this.kafedraEdit) return;

    this.departmentWorkloadService.getExportPlanFact({
      studyYear: this.yearAndSemestrsEdit.year,
      kafedraId: this.kafedraEdit.id,
      personId: this.teacherEdit?.id,
      trainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      studyFormId: this.studyFormEdit.includes('') ? null : this.studyFormEdit,
      facultyId: this.facultiesEdit?.id == '' ? null : this.facultiesEdit?.id
    }).subscribe({
      next: (response) => {
        let blob:any = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        saveAs(blob, `План учебной нагрузки на ${this.yearAndSemestrsEdit.year} / ${this.yearAndSemestrsEdit.year + 1} учебный год кафедры ${this.kafedraEdit.name}`);
      },
      error: err => this.notificationService.showError(getErrorMessage(err), 5000)
    })
  }

  //#endregion

  //#region Helper methods

  private storage_filter_key: string = 'teacher_workload-filter';

  private setFilterOptions() {
    localStorage.removeItem(this.storage_filter_key);
    localStorage.setItem(this.storage_filter_key, JSON.stringify({
      yearAndSemestrsEdit: this.yearAndSemestrsEdit,
      kafedraEdit: this.kafedraEdit,
      trainingLevelsEdit: this.trainingLevelsEdit,
      studyFormEdit: this.studyFormEdit,
      facultiesEdit: this.facultiesEdit,
      teacherEdit: this.teacherEdit
    }));
  }

  private getFilterOptions(): boolean {
    const json = localStorage.getItem(this.storage_filter_key);

    if (json != null) {
      try {
        let filters = JSON.parse(json);

        this.yearAndSemestrsEdit = filters.yearAndSemestrsEdit;
        this.kafedraEdit = filters.kafedraEdit;
        this.trainingLevelsEdit = filters.trainingLevelsEdit;
        this.studyFormEdit = filters.studyFormEdit;
        this.facultiesEdit = filters.facultiesEdit;
        this.teacherEdit = filters.teacherEdit;

        return true;
      }
      catch (e) {
        localStorage.removeItem(this.storage_filter_key);

        return false;
      }
    }

    return false;
  }

  // Проверяем есть ли у пользователя права на редактирования
  private async hasEditingRightsInTeacherLoad() {
    this.userEditing = false;

    if(!this.kafedraEdit?.id) return;

    this.userEditing = await this.userAccess.hasEditingRightsInTeacherLoad({
      cathedraId: this.kafedraEdit?.id
    });
  }

  //#endregion

  //#region Lifecycle events

  async ngOnInit() {
    const needSetEditModel = !this.getFilterOptions();

    await this.getAllYearAndSemestrs(needSetEditModel);
    await this.getDepartments(needSetEditModel);
    await this.getTrainingLevels(needSetEditModel);
    await this.getFaculties(needSetEditModel);
    await this.getStudyForms(needSetEditModel);
    await this.getPersons({ kathedraId: this.kafedraEdit?.id, studyYear: this.yearAndSemestrsEdit?.year }, needSetEditModel);

    await this.hasEditingRightsInTeacherLoad();

    this.getTeacherLoad();
    this.getAdditionalWorks();
  }

  //#endregion
}

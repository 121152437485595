import {Component, OnInit} from '@angular/core';
import {DisplaySettingEnum} from '../../../models/contingent/enums/display-setting.enum';
import {StudentcarddisplaysettingService} from '../../../services/contingent/studentcarddisplaysetting.service';


@Component({
  selector: 'app-military',
  templateUrl: './military.component.html',
  styleUrls: ['./military.component.scss']
})
export class MilitaryComponent implements OnInit {

  protected readonly DisplaySettingEnum = DisplaySettingEnum;

  constructor(
    private displaySettingService: StudentcarddisplaysettingService,
  ) { }

  ngOnInit(): void {

  }

  public isHidden(field: number): boolean {
    return this.displaySettingService.isHidden(field);
  }
}

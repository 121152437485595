import {Component, OnInit, ViewChild, Renderer2} from "@angular/core";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {pagerSettings} from 'src/app/models/diplomablanks/pagerSettings.model';
import {openDialog} from "../../../../helpers/dialogHelper";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {DialogCloseResult} from '@progress/kendo-angular-dialog';
import {DictBlankTemplateService} from '../../../../services/diplomablanks/dict-blanktemplate.service';
import {BlankTemplateModel} from '../../../../models/diplomablanks/dicts.model';
import {DiplomablanksUserAccessService} from '../../../../services/useraccess/diplomablanks-user-access.service';
import {levelAccess} from '../../../../models/diplomablanks/enum/boolOptions.enum';
import {isChildOf} from 'src/app/helpers/elementRef-helper';

@Component({
    selector: 'dict-blanktemplate',
    templateUrl: './dict-blanktemplate.component.html',
    styleUrls: ['./dict-blanktemplate.component.scss']
  })

export class DictBlankTemplateComponent implements OnInit {

  public blankTemplates: BlankTemplateModel[] = [];

  protected pageSize = 20;
  protected readonly pagerSettings = pagerSettings;
  public loading: boolean = false;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  public isLine = false;
  public isNew = false;

  public formGroup: UntypedFormGroup | undefined;

  public editable: boolean = false;

  constructor(
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private dictBlankTemplateService: DictBlankTemplateService,
    private diplomablanksUserAccessService: DiplomablanksUserAccessService,
    private renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    this.getBlankTemplates();
    this.getUserAccess();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid") && !this.isNew) {
        this.saveRow();
      }
    });
  }

  public getUserAccess() {
    this.diplomablanksUserAccessService.getUserAccess()
    .subscribe(response => {
      this.editable = response.dict == levelAccess.write;
    })
  }

  public getBlankTemplates() {
    this.loading = true;
    this.dictBlankTemplateService.getBlankTemplate()
      .subscribe(response => {
        if (response) this.blankTemplates = response;
        this.loading = false;
      })
  }

  public editRow({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isNew || !this.editable) return;

    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveRow();
      return;
    }

    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public onCancel(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public addRow() {
    this.formGroup = new FormGroup({
        name: new FormControl(null, Validators.required),
        graduateYear: new FormControl(null, Validators.required, ),
        order: new FormControl(null, Validators.required)
      })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public saveRow(): void {
    if (!this.formGroup || this.formGroup && !this.formGroup.valid) {
        return;
    }

    if (this.isNew) {
       this.dictBlankTemplateService.addBlankTemplate(this.formGroup?.value).subscribe(
        response => {
            this.getBlankTemplates();
            this.notificationService.showSuccess('Добавлено');
          },
          error => {
            this.notificationService.showError(error.error);
          })
      } else {
        this.dictBlankTemplateService.updateBlankTemplate(this.formGroup?.value).subscribe(response => {
          this.getBlankTemplates();
          this.notificationService.showSuccess('Сохранено');
        },
        error => {
          this.notificationService.showError(error.error);
        });
      }
      this.onCancel();
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name} ${dataItem.graduateYear}?"`);
    dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.dictBlankTemplateService.deleteBlankTemplate(dataItem.externalId).subscribe({
            next: () => {
              this.notificationService.showSuccess('Удалено');
              this.getBlankTemplates();
            },
            error: () => this.notificationService.showError('Произошла ошибка'),
          });
        }
    });
    this.onCancel();
  }
}

const formGroup = (dataItem: BlankTemplateModel) =>
  new FormGroup({
    externalId: new FormControl(dataItem.externalId),
    name: new FormControl(dataItem.name, Validators.required),
    order: new FormControl(dataItem.order, Validators.required),
    graduateYear: new FormControl(dataItem.graduateYear, Validators.required),
  });

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Building } from '../../models/classroom/building.model';

@Injectable({
    providedIn: 'root'
  })

export class BuildingService {
    baseUrl = `${environment.classroomApiEndpoint}${environment.apiPaths.classroom.dict}`;

    constructor(private http: HttpClient) { }

    public getAllBuildings(): Observable<Building[]> {
        return this.http.get<Building[]>(this.baseUrl + '/buildings/all');
    }

    public getAllowedBuildings(){
        return this.http.get<Building[]>(this.baseUrl + '/buildings/');
    }

    public addBuilding(building: any){
        let data = {};
        if (building.buildingNumber == null || building.buildingNumber.length == 0){
            data = {name: building.buildingName, sName: building.buildingSName};
        } else  data = {name: building.buildingName, sName: building.buildingSName, number: building.buildingNumber};  
  //      let data = {name: building.buildingName, sName: building.buildingSName, number: building.buildingNumber};
        return this.http.post(this.baseUrl + '/building', data)
        .pipe(catchError(this.handleError));
    }

    public updateBuilding(building: any, id: string){
        let data = {};
        if (building.buildingNumber !== ""){
            data = {name: building.buildingName, sName: building.buildingSName, number: building.buildingNumber};
        } else data = {name: building.buildingName, sName: building.buildingSName};
       // let data = {name: building.buildingName, sName: building.buildingSName, number: building.buildingNumber};
        return this.http.put(this.baseUrl + '/building/' + id, data)
      .pipe(catchError(this.handleError));
    }

    public deleteBuilding(id: string){
        return this.http.delete(this.baseUrl + '/building/' + id)
        .pipe(catchError(this.handleError));
    }

    // Error
  /*  handleError(error: HttpErrorResponse) {
        console.log('ошибка',error);
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
        // Handle client error
        errorMessage = error.error.message;
        } else {
        // Handle server error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
        errorMessage;
        });
    }*/
    handleError(error: HttpErrorResponse) {
        console.log('ошибка',error);
        return throwError(error);
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AllMilitaryCertificates,
  MilitaryDocument,
  MilitaryDocumentForm,
  MilitaryEnlistment,
  MilitaryEnlistmentForm
} from '../../models/contingent/military.model';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from "rxjs/operators";
import {handleError} from '../../helpers/errorHandle-helper';

@Injectable({
  providedIn: 'root'
})
export class MilitaryDocumentsService {

  private baseUrl = environment.apiEndpoint;
  private enlistmentUrl = `${this.baseUrl}${environment.apiPaths.militaryenlistment}`;
  private documentsUrl = `${this.baseUrl}${environment.apiPaths.militarydocuments}`;
  private certificatesUrl = `${this.baseUrl}${environment.apiPaths.militaryform}`;

  constructor(private http: HttpClient) { }

  getMilitaryEnlistment(studPersonId: string): Observable<MilitaryEnlistment> {
    return this.http.get<MilitaryEnlistment>(`${this.enlistmentUrl}/GetMilitaryEnlistment/${studPersonId}`)
      .pipe(catchError(handleError));
  }

  addMilitaryEnlistment(info: MilitaryEnlistmentForm, studPersonId: string): Observable<MilitaryEnlistmentForm> {
    return this.http.post<MilitaryEnlistmentForm>(`${this.enlistmentUrl}/Add/${studPersonId}`, info)
      .pipe(catchError(handleError));
  }

  updateMilitaryEnlistment(info: MilitaryEnlistmentForm, studPersonId: string): Observable<MilitaryEnlistmentForm> {
    return this.http.put<MilitaryEnlistmentForm>(`${this.enlistmentUrl}/Update/${studPersonId}`, info)
      .pipe(catchError(handleError));
  }

  getMilitaryDocuments(studPersonId: string): Observable<MilitaryDocument[]> {
    return this.http.get<MilitaryDocument[]>(`${this.documentsUrl}/GetMilitaryRegistrationDocuments/${studPersonId}`)
      .pipe(catchError(handleError));
  }

  addMilitaryDocument(document: MilitaryDocumentForm, studPersonId: string): Observable<MilitaryDocumentForm> {
    return this.http.post<MilitaryDocumentForm>(`${this.documentsUrl}/Add/${studPersonId}`, document)
      .pipe(catchError(handleError));
  }

  updateMilitaryDocument(document: MilitaryDocumentForm, studPersonId: string): Observable<MilitaryDocumentForm> {
    return this.http.put<MilitaryDocumentForm>(`${this.documentsUrl}/Update/${studPersonId}`, document)
      .pipe(catchError(handleError));
  }

  deleteMilitaryDocument(studPersonId: string, documentId: string): Observable<MilitaryDocumentForm> {
    return this.http.delete<MilitaryDocumentForm>(`${this.documentsUrl}/Delete/${studPersonId}/${documentId}`)
      .pipe(catchError(handleError));
  }

  getMilitaryCertificates(studPersonId: string): Observable<AllMilitaryCertificates> {
    return this.http.get<AllMilitaryCertificates>(`${this.certificatesUrl}/GetByStudent/${studPersonId}`)
      .pipe(catchError(handleError));
  }
}


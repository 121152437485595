<dicts-header></dicts-header>
<h1>Уровень образования</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="studylevels" (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: studylevels.length < 8}"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)">

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictStudyLevelId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="studyLevelName"
                       title="Название уровня"
                       required
                       [width]="350"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.studyLevelName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studyLevelSName"
                       title="Сокр. название"
                       required
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.studyLevelSName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studyLevelEnum" title="Категория образования"
                       headerClass="gridHeader"
                       [width]="200"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
            {{ nameEnum(dataItem.studyLevelEnum) }}
         </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-combobox [data]="studyLevelEnumList"
                        textField="text"
                        valueField="value"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('studyLevelEnum')">
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="60" headerClass="gridHeader" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {ContingentUserAccessService} from "../services/useraccess/contingent-user-access.service";
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../models/useraccess/role";
import {map} from "rxjs/operators";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ContingentMilitaryCertificateGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: ContingentUserAccessService,
    private location: Location,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    if (!token) {
      localStorage.setItem('last_url', this.location.path());
      return false;
    }
    else {
      const user = this.jwtHelper.decodeToken(token);
      const roles = user?.role;
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)) return true;
      // Check if user has contingent role
      return this.userAccessService.getAccessLevel().pipe(
        map((value) => {
          if (value.militaryCertificateAccessLevel != 0) {
            return true;
          }
          else {
            alert('Недостаточно прав для доступа на эту страницу');
            localStorage.setItem('last_url', this.location.path());
            this.router.navigate([`/profile`]);
            return false;
          }
        })
      );
    }

  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { TokenStorageService } from '../services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Location } from '@angular/common';
import { Role } from '../models/useraccess/role';
import { GiaUserAccessService } from '../services/useraccess/gia-user-access.service';
import { AccessRights, GiaCurrentUserAccess } from '../models/useraccess/gia/giaUserAccess';

@Injectable({
  providedIn: 'root',
})
export class GiaRoleGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: GiaUserAccessService,
    private location: Location,
    private router: Router,
  ) {}

  private routes: { name: string; route: string }[] = [{ name: 'accessSettings', route: 'gia/useraccess' }];
  private studentId?: string;

  private returnValue(value: boolean): boolean {
    if (!value) {
      alert('Недостаточно прав для доступа на эту страницу');
      localStorage.setItem('last_url', this.location.path());
      this.router.navigate([`/profile`]).then();
    }
    return value;
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    if (path?.includes('gia/formatdiploma'))
      this.studentId = route.params["id"];

    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;
      if (Object.keys(this.userAccessService.currentUserAccess$.value).length === 0) {
        await lastValueFrom(this.userAccessService.getCurrentUserAccess(this.studentId)).then((value) => {
          this.userAccessService.currentUserAccess$.next(value);
        });
      }
      // Add PrintTemplate access
      if (path?.includes('gia/printtemplate/add')) {
        return this.userAccessService.currentUserAccess$.value.printTemplateAccess;
      }
      if (path?.includes('gia/formatdiploma') && path?.includes('adddiscipline')) {
        return this.returnValue(this.userAccessService.currentUserAccess$.value.diploma === AccessRights.Write);
      }
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)) {
        return true;
      }
      // Check if user has GIA role
      if (roles?.includes(Role.GIA)) {
        // My and all GIA access
        if (path === 'gia') {
          return true;
        }
        // Dict Creating access
        if (path === 'gia/dictdesigner') {
          return this.returnValue(this.userAccessService.currentUserAccess$.value.dictCreating);
        }
        if (path === 'gia/constructorgia') {
          return this.returnValue(this.userAccessService.currentUserAccess$.value.giaProtocolDesigner);
        }
        // Dictionaries access
        if (path?.includes('dict')) {
          return this.returnValue(this.userAccessService.currentUserAccess$.value.dict !== AccessRights.Forbidden);
        }
        // Local Signatory access
        if (path === 'gia/signatory') {
          return this.returnValue(this.userAccessService.currentUserAccess$.value.localSignatory);
        }
        // PrintTemplate access
        if (path === 'gia/printtemplate') {
          return this.returnValue(this.userAccessService.currentUserAccess$.value.printTemplateAccess);
        }
        if (path?.includes('gia/formatdiploma')) {
          return this.returnValue(this.userAccessService.currentUserAccess$.value.diploma !== AccessRights.Forbidden);
        }
        return true;
      }
    }
    return this.returnValue(false);
  }
}

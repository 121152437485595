import {Component, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CellClickEvent, EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { AnnouncementUserAccessService } from '../../../services/useraccess/announcement-user-access.service';
import { SearchPersonsService } from "../../../services/announcement/search-persons.service";
import { FacultyService } from '../../../services/announcement/faculty.service';
import { DepartmentService } from '../../../services/announcement/department.service';
import { EduGroupService } from "../../../services/announcement/eduGroup.service";
import { AnnouncementUserAccess } from '../../../models/useraccess/announcement/announcementuseraccess.model';
import { Faculty } from '../../../models/announcement/faculty.model';
import { EduGroup } from '../../../models/announcement/eduGroup.model';
import { Department } from '../../../models/announcement/department.model';
import { Persons } from "../../../models/announcement/person.model";

import { NotificationsService } from '../../../services/notifications/notifications.service';

import {
  arrayRewrite,
  DisplayValuesInEditMode,
  valueChange,
  valueChangeBySourceName
} from "../../../helpers/multiselect-helper";
import { TokenStorageService } from "../../../services/token.service";
import { JwtHelperService}  from "@auth0/angular-jwt";
import { checkRole } from "../../../helpers/token/role-check";
import { Role } from "../../../models/useraccess/role";
import {sortBy} from "lodash";
import {isChildOf} from "../../../helpers/elementRef-helper";
import {GridDisplayList} from "../../../helpers/grid-helper";

@Component({
  selector: 'app-announcement-access',
  templateUrl: './announcement-access.component.html',
  styleUrls: ['./announcement-access.component.scss']
})
export class AnnouncementAccessComponent implements OnInit {
  public isAdmin: boolean;
  public virtual: any = {
    itemHeight: 28,
  };

  public eduGroupsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public departmentsEdit: any[] = [];
  public coursesEdit: any[] = [];

  public accesses: AnnouncementUserAccess[] = [];
  public persons: Persons[] = [];
  public eduGroups: EduGroup[] = [];
  public faculties: Faculty[] = [];
  public departments: Department[] = [];
  public courses: any[] = [
    {courseNumber: '', name: 'Все'},
    {courseNumber: '1', name: '1'},
    {courseNumber: '2', name: '2'},
    {courseNumber: '3', name: '3'},
    {courseNumber: '4', name: '4'},
    {courseNumber: '5', name: '5'},
    {courseNumber: '6', name: '6'},
  ]

  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  private dataSources: any[] = ['eduGroups', 'courses', 'faculties', 'departments'];

  public opened = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: any[] = [];

  constructor(private renderer: Renderer2,
              private dialogService: DialogService,
              private eduGroupService: EduGroupService,
              private departmentService: DepartmentService,
              private facultyService: FacultyService,
              private personService: SearchPersonsService,
              private notificationService: NotificationsService,
              private userAccessService: AnnouncementUserAccessService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  ngOnInit(): void {
    this.getAccess();
    this.getPersons();
    this.getAllFaculties();
    this.getAllDepartments();
    this.getAllEduGroups();
  }

  // Get access
  public getAccess() {
    this.userAccessService.getAccess()
      .subscribe(
        response => {
          this.accesses = response;
          if (this.accesses) {
            for (let i = 0; i < this.accesses.length; i++) {
              valueChangeBySourceName(this.accesses[i].eduGroups,
                "eduGroupId", null, "");
              valueChangeBySourceName(this.accesses[i].eduGroups,
                "eduGroupName", null, "Все");
              valueChangeBySourceName(this.accesses[i].courses,
                "id", null, "");
              valueChangeBySourceName(this.accesses[i].courses,
                "courseNumber", null, "");
              valueChangeBySourceName(this.accesses[i].departments,
                "departmentId", null, "");
              valueChangeBySourceName(this.accesses[i].departments,
                "departmentName", null, "Все");
              valueChangeBySourceName(this.accesses[i].faculties,
                "facultyId",  null, "");
              valueChangeBySourceName(this.accesses[i].faculties,
                "facultySName",  null, "Все");
            }
          }
          this.gridData = this.accesses;
        }
      );
  }

  // Faculties
  public getAllFaculties() {
    this.facultyService.getAllFaculties()
    .subscribe(
      response => {
        this.faculties = response;
        this.faculties = this.faculties;
        if (this.faculties) {
          let item = new Faculty();
          item.facultyId = "";
          item.facultyName = 'Все';
          item.facultySName = 'Все';
          this.faculties.unshift(item);
        }
      }
    );
  }

  // Departments
  public getAllDepartments() {
    this.departmentService.getAllDepartments()
    .subscribe(
      response => {
        this.departments = response;
        this.departments = this.departments.sort((a, b) => a.departmentName.localeCompare(b.departmentName));
        if (this.departments) {
          let item = new Department();
          item.departmentId = "";
          item.departmentName = 'Все';
          this.departments.unshift(item);
        }
      }
    );
  }

    // EduGroups
    public getAllEduGroups() {
      this.eduGroupService.getAllEduGroups(this.facultiesEdit, this.coursesEdit)
      .subscribe(
        response => {
          this.eduGroups = response;
          this.eduGroups = this.eduGroups.sort((a, b) => a.eduGroupName.localeCompare(b.eduGroupName));
          if (this.departments) {
            let item = new EduGroup();
            item.eduGroupId = "";
            item.eduGroupName = 'Все';
            this.eduGroups.unshift(item);
          }
        }
      );
    }

  // Get persons
  public getPersons() {
    this.personService.getAllPerson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  getList(dataItem: any, key: any) {
    dataItem = sortBy(dataItem, key);
    if(key === 'courseNumber' && dataItem.find((item: any) => item.courseNumber === '')) {return 'Все'}
    return GridDisplayList(dataItem, key);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.eduGroupsEdit = [];
    this.facultiesEdit = [];
    this.coursesEdit = [];
    this.departmentsEdit = [];

    this.formGroup = formGroup({
      id: 0,
      personId: '',
      faculties: '',
      courses: '',
      eduGroups: '',
      departments: '',
      userAccessLevel: false,
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Start Editing***
  public editHandler({sender, rowIndex, dataItem}: EditEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      //this.saveCurrent();
      return;
    }

    const key: any[] = ['eduGroupId', 'courseNumber','facultyId', 'departmentId'];

    // Вывод данных в редактирование
    for(let i = 0; i < this.dataSources.length; i++) {
      (this as any)[`${this.dataSources[i]}Edit`] =
          DisplayValuesInEditMode((dataItem[this.dataSources[i]]), [key[i]]);
    }

    this.isLine = true;
    //this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if(this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }

      for(let i=0; i < this.dataSources.length; i++ ) {
        valueChange(this.formGroup.value[this.dataSources[i]], "", null);
      }

      this.userAccessService.saveAccess(this.formGroup.value)
      .subscribe(
        response => {
          this.getAccess();
          this.notificationService.showSuccess("Добавлено");
        },
        error => {
          this.notificationService.showError("Не удалось добавить запись");
        }
      );
    }
    this.closeEditor(this.grid);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  clearData() {
    this.gridData = this.accesses;
    this.searchFIO = ''
  }

  filterData(dataItem: any) {
    this.gridData = this.accesses.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  //Deleting an entry***
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста, подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.personName} из настроек доступа?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.userAccessService.deleteAccess(dataItem.id)
          .subscribe(
            (response: any) => {
              this.getAccess();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public valueChange(value: any, editItem: any) {
    (this as any)[editItem] = arrayRewrite(value);
    if(editItem === 'facultiesEdit') this.getAllEduGroups();
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }
}

const formGroup = (dataItem:
  {
    id?: any;
    personId?: any;
    faculties?: any;
    courses?: any;
    eduGroups?: any;
    departments?: any;
    userAccessLevel?: boolean;
  }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    faculties: new FormControl(dataItem.faculties, Validators.required),
    courses: new FormControl(dataItem.courses, Validators.required),
    eduGroups: new FormControl(dataItem.eduGroups, Validators.required),
    departments: new FormControl(dataItem.departments, Validators.required),
    userAccessLevel: new FormControl(dataItem.userAccessLevel, Validators.required)
  });

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IdentificationType } from '../../models/contingent/identificationtype.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class IdentificationtypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.identificationtype}`;

  constructor(private http: HttpClient) { }

  //Get all IdentificationType
  public getAllIdentificationType(): Observable<IdentificationType[]> {
    return this.http.get<IdentificationType[]>(this.baseUrl);
  }

  //Add IdentificationType
  addIdentificationType(dto: IdentificationType): Observable<IdentificationType> {
    return this.http.post<IdentificationType>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete IdentificationType
  deleteIdentificationType(id: string): Observable<IdentificationType> {
    return this.http.delete<IdentificationType>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit IdentificationType
  updateIdentificationType(identificationType: IdentificationType): Observable<IdentificationType> {
    return this.http.put<IdentificationType>(this.baseUrl + '/' + identificationType.dictIdentificationTypeExternalId, identificationType)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

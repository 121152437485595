import { Component, ViewChild, Renderer2, OnInit } from '@angular/core';
import { AddEvent, GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { State } from "@progress/kendo-data-query";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogService } from '@progress/kendo-angular-dialog';
import { Guid } from 'guid-typescript';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { DictGroupStandardLimitation } from 'src/app/models/education/dictGroupStandardLimitation.model';
import { DictStandardLimitation } from 'src/app/models/education/dictstandardlimitation.model';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';
import { StandardLimitation } from 'src/app/models/education/standardlimitation.model';
import { DictStandardLimitationSettingService } from 'src/app/services/education/StandardLimitations/standard-limitation-setting.service';
import { DictGroupStandardLimitationService } from 'src/app/services/education/StandardLimitations/dict-group-standard-limitation.service';
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-standard-limitation-setting',
  templateUrl: './standard-limitation-setting.component.html',
  styleUrls: ['./standard-limitation-setting.component.css']
})
export class StandardLimitationSettingComponent implements OnInit{
  public dictStandardLimitations!: DictStandardLimitation[];
  public trainingLevels!: TrainingLevel[];
  public standardTypes!: StandardType[];
  public unitTypes: string[] = [];
  public dictGroups!: DictGroupStandardLimitation[];
  public dictStandardLimitationsByFilter!: DictStandardLimitation[];
  public filter: Filter = {
    standardTypeId: undefined,
    trainingLevelId: undefined
  }

  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public sort: SortDescriptor[] = [
    {
      field: "orderNumber",
      dir: "asc",
    },
  ];
  public gridView: any | GridDataResult;

  constructor(
      private dictStandardLimitationSettingService: DictStandardLimitationSettingService,
      private dictGroupStandardLimitationService: DictGroupStandardLimitationService,
      private standardTypeService: StandardtypeService,
      private trainingLevelService: TrainingLevelService,
      private renderer: Renderer2,
      private notificationService: NotificationsService,
      private userAccessService:EducationUserAccessService
    ) {
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadStandardLimitationsByFilters();
  }

  private loadStandardLimitationsByFilters(): void {
    this.gridView = {
      data: orderBy(this.dictStandardLimitationsByFilter, this.sort),
      total: this.dictStandardLimitationsByFilter.length,
    };
  }

  public UnitType = {
    "з.ед." : "з.ед.",
    "ак.час." : "ак.час.",
    "шт." : "шт.",
    "%" : "%"
  }

  public IsVerifiable = {
    true : "Да",
    false : "Нет"
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getAllStandardType();
    this.getAllTrainingLevel();
    this.getAllDictStandardLimitation();
    this.getAllDictGroupStandardLimitation();

    this.unitTypes.push(this.UnitType['з.ед.']);
    this.unitTypes.push(this.UnitType['ак.час.']);
    this.unitTypes.push(this.UnitType['шт.']);
    this.unitTypes.push(this.UnitType['%']);

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid") && !(target.outerText === 'СОХРАНИТЬ')) {
        this.saveCurrent();
      }
    });
  }

  //Get StandardLimitationSetting name for id
  // public nameStandardLimitationSettingtype(id: Guid): StandardLimitationSettingType | undefined {
  //   return this.StandardLimitationSettingtypes.find((x) => x.dictStandardLimitationSettingTypeExternalId === id);
  // }

  public onStateChange(state: State): void {
    this.getAllDictStandardLimitation();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    if(!this.editable)return
    this.closeEditor(sender);

    this.formGroup = formGroup({
      name: undefined,
      orderNumber: undefined,
      unit: undefined,
      dictGroupStandardLimitationId: undefined,
      isVerifiable: undefined,
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    isEdited,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if(!this.editable)return
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public defaultItemTrainingLevel: { trainingLevelName: string; dictTrainingLevelExternalId?: any } = {
    trainingLevelName: "Не выбрано",
    dictTrainingLevelExternalId: null,
  };

  public defaultItemStandardType: { standardTypeShortName: string; standardTypeExternalId?: any } = {
    standardTypeShortName: "Не выбрано",
    standardTypeExternalId: null,
  };

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public cancelFilterHandler(): void {
    this.filter.standardTypeId = undefined;
    this.filter.trainingLevelId = undefined;
    this.dictStandardLimitationsByFilter = [];
    this.gridView = this.dictStandardLimitationsByFilter;
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
    this.saveCurrentFilter();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Update setting
      if (this.formGroup !== undefined && this.formGroup.value.dictStandardLimitationName != '') {
        if (this.formGroup?.value.standardLimitationDataType === 1 && this.formGroup?.value.unit != null) {
          this.notificationService.showError("У логического значения не может быть единицы измерения");
          return;
        }
        this.dictStandardLimitationSettingService.updateDictStandardLimitation(this.formGroup?.value)
          .subscribe(
            response => {
              let currentDict = this.dictStandardLimitationsByFilter.find((x) => x.dictStandardLimitationExternalId === response.result.dictStandardLimitationExternalId)!;
              let currentIndex = this.dictStandardLimitationsByFilter.indexOf(currentDict);
              this.dictStandardLimitationsByFilter[currentIndex].dictStandardLimitationExternalId = response.result.dictStandardLimitationExternalId;
              this.dictStandardLimitationsByFilter[currentIndex].dictGroupStandardLimitationId = response.result.dictGroupStandardLimitationId;
              this.dictStandardLimitationsByFilter[currentIndex].isVerifiable = response.result.isVerifiable;
              this.dictStandardLimitationsByFilter[currentIndex].name = response.result.name;
              this.dictStandardLimitationsByFilter[currentIndex].orderNumber = response.result.orderNumber;
              this.dictStandardLimitationsByFilter[currentIndex].unit = response.result.unit;

              this.loadStandardLimitationsByFilters();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }

  saveCurrentFilter() {
    this.dictStandardLimitationsByFilter = [];
    this.gridView = this.dictStandardLimitationsByFilter;
    if(!this.dictStandardLimitations) return;
    this.dictStandardLimitations.forEach(dict => {
      if (dict.dictStandardTypeId === this.filter.standardTypeId &&
        dict.dictTrainingLevelId === this.filter.trainingLevelId) {
          this.dictStandardLimitationsByFilter.push(dict);
          this.gridView = this.dictStandardLimitationsByFilter;
        }
    });
  }

  public getTrainingLevelById(id: string) {
    return this.trainingLevels.find((x) => x.trainingLevelExternalId === id)
  }

  public getStandardTypeById(id: string) {
    return this.standardTypes.find((x) => x.standardTypeExternalId === id);
  }

  public getDictStandardLimitationById(id: Guid) {
    return this.dictStandardLimitations.find((x) => x.dictStandardLimitationExternalId === id);
  }

  public getDictGroupStandardLimitationById(id: Guid) {
    return this.dictGroups.find((x) => x.dictGroupStandardLimitationExternalId === id);
  }

  //Getting all entries from dictionary
  public getAllTrainingLevel() {
    this.trainingLevelService.getAllTrainingLevels()
      .subscribe(
        response => {
          this.trainingLevels = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllDictStandardLimitation() {
    this.dictStandardLimitationSettingService.getAllDictStandardLimitations()
      .subscribe(
        response => {
          this.dictStandardLimitations = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllStandardType() {
    this.standardTypeService.getAllStandardTypes()
      .subscribe(
        response => {
          this.standardTypes = response;
        }
      );
  }

  public getAllDictGroupStandardLimitation() {
    this.dictGroupStandardLimitationService.getAllDictGroupStandardLimitation()
      .subscribe(
        response => {
          this.dictGroups = response;
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = !response.dictAccessLevel;
    },error => {});
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

  public onValueChange(): void {
    this.saveCurrentFilter();
  }

}

const formGroup = (dataItem: { dictStandardLimitationExternalId?: any; name?: any; orderNumber?: any; unit?: any; dictGroupStandardLimitationId?: any; isVerifiable?: any; dictStandardTypeId?: any; standardLimitationDataType?: any; tooltip?: any; }) =>
  new FormGroup({
    name: new FormControl(dataItem.name, Validators.required),
    orderNumber: new FormControl(dataItem.orderNumber, [Validators.min(1), Validators.pattern("^[0-9]+$")]),
    unit: new FormControl(dataItem.unit),
    isVerifiable: new FormControl(dataItem.isVerifiable, Validators.required),
    dictGroupStandardLimitationId: new FormControl(dataItem.dictGroupStandardLimitationId),
    dictStandardLimitationExternalId: new FormControl(dataItem.dictStandardLimitationExternalId),
    standardLimitationDataType: new FormControl(dataItem.standardLimitationDataType),
    tooltip: new FormControl(dataItem.tooltip),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

export class Filter {
  public trainingLevelId!: Guid | undefined;
  public standardTypeId!: Guid | undefined;
}

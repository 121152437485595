import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {handleError} from "../../../helpers/errorHandle-helper";
import {map} from "rxjs/operators";
import {
  AvailableDiscipline,
  AvailableEducationDisciplineGet, AvailableKafedra,
  AvailableSemesters, KafedraGet
} from "../../../models/disciplineworkload/externals.model";
import { DictDisciplinesQuery } from 'src/app/models/disciplineworkload/query/dictDisciplinesQuery.model';
import { AvailableEducationDisciplinesQuery } from 'src/app/models/disciplineworkload/query/availableEducationDisciplinesQuery.model';
import {AvailableKafedraFilter} from "../../../models/disciplineworkload/query/available_kafedra_query.model";

@Injectable({
  providedIn: 'root'
})
export class EducationPlanService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.educationPlan}`;
  constructor(private http: HttpClient) { }

  // Получение доступных семестров с учебными годами
  getAvailableSemesters(): Observable<AvailableSemesters[]> {
    // @ts-ignore
    return this.http.get<AvailableSemesters[]>(this.baseUrl + '/AvailableSemesters/');
  }

  // Получение списка доступных дисциплин по фильтрам
  getAvailableDictDisciplines(dictDisciplineFilter: DictDisciplinesQuery): Observable<AvailableDiscipline[]> {
    return this.http.post<AvailableDiscipline[]>(this.baseUrl + '/AvailableDictDisciplines/', dictDisciplineFilter);
  }

  // Получение списка доступных дисциплин по фильтрам
  getAvailableEducationDisciplines(disciplineFilter: AvailableEducationDisciplinesQuery): Observable<AvailableEducationDisciplineGet[]> {
    return this.http.post<AvailableEducationDisciplineGet[]>(this.baseUrl + '/AvailableEducationDisciplines/', disciplineFilter);
  }

  // Получение списка доступных кафедр
  getAvailableKafedras(filter: AvailableKafedraFilter): Observable<KafedraGet[]> {
    return this.http.post<KafedraGet[]>(this.baseUrl + '/AvailableKafedras/', filter);
  }
}

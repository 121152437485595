import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {OrderTypeEnum, OrderTypes} from '../../models/contingent/ordertypes.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OrdertypesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.ordertypes}`;

  constructor(private http: HttpClient) { }

  //Get all ordertypes
  public getAllordertypes(visible: boolean): Observable<OrderTypes[]> {
    return this.http.get<OrderTypes[]>(this.baseUrl + "/GetAllDictOrderTypes/" + visible);
  }

  //Add ordertypes
  addordertypes(ordertypes: OrderTypes): Observable<OrderTypes> {
    return this.http.post<OrderTypes>(this.baseUrl, ordertypes)
      .pipe(catchError(this.handleError));
  }

  //Delete ordertypes
  deleteordertypes(id: string): Observable<OrderTypes> {
    return this.http.delete<OrderTypes>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit ordertypes
  updateordertypes(ordertypes: OrderTypes): Observable<OrderTypes> {
    return this.http.put<OrderTypes>(this.baseUrl + '/' + ordertypes.dictOrderTypeExternalId, ordertypes)
      .pipe(catchError(this.handleError));
  }

  public getServiceCategories(): Observable<OrderTypeEnum[]> {
    return this.http.get<OrderTypeEnum[]>(`${this.baseUrl}/ServiceCategory/`);
  }

  // Error
  handleError(error: HttpErrorResponse) {
      return throwError(error);
  }

}

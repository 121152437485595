import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AddEvent, CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {MiddleControlUserAccessService} from "../../../services/useraccess/middle-control-user-access.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {DictGeneralSheet} from "../../../models/middlecontrol/dictGeneralSheet.model";
import {DictgeneralsheetService} from "../../../services/middlecontrol/dictgeneralsheet.service";
import {TrainingLevelsModel} from "../../../models/middlecontrol/trainingLevels.model";
import {DictsService} from "../../../services/middlecontrol/dicts.service";
import {StudyFormModel} from "../../../models/middlecontrol/studyform.model";
import {SemesterYearsModel} from "../../../models/middlecontrol/semesterYears.model";
import {openRemoveDialog} from "../../../helpers/dialogHelper";
import {TrainingLevelEnum} from "../../../models/middlecontrol/enums/training-level.enum";
import {FilialReadDto} from "../../../models/middlecontrol/localsignatory.model";
import {Guid} from "guid-typescript";
import {DictFilial} from "../../../models/middlecontrol/dictfilial.model";

@Component({
  selector: 'app-dictgeneralsheet',
  templateUrl: './dictgeneralsheet.component.html',
  styleUrls: ['./dictgeneralsheet.component.scss']
})
export class DictgeneralsheetComponent implements OnInit {

  public generalSheets: DictGeneralSheet[] = [];
  public filteredGeneralSheets: DictGeneralSheet[] = [];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;
  public trainingLevels!: TrainingLevelsModel[];
  public studyForms!: StudyFormModel[];
  public educationSlices: SemesterYearsModel[] = [];
  public selectedSemester: SemesterYearsModel | null = null;
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public filials: DictFilial[] = []
  public filialId: string|null = null

  @ViewChild(GridComponent) private grid!: GridComponent;
  public editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public isAdmin: boolean = false;
  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private generalSheetService: DictgeneralsheetService,
              private notificationService: NotificationsService,
              private userAccessService: MiddleControlUserAccessService,
              private dialogService: DialogService,
              private renderer: Renderer2,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService,
              private dictService: DictsService,
  ) {
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  public async ngOnInit() {
    this.getAccessLevel()
    await this.getTrainingLevel();
    await this.getStudyForm();
    await this.getSemesterYear();
    this.getAllGeneralSheets();
    this.getAllFilials();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({ filialId: this.filialId });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  public defaultItemFilial: { filialSName: string; id: string | null } = {
    filialSName: "Не выбрано",
    id: null,
  };

  public onValueChange(value: any): void {
    this.filterGeneralSheets();
  }

  public filterGeneralSheets(){
    this.filteredGeneralSheets = [];
    this.filteredGeneralSheets = this.generalSheets.filter(x => x.filialId === this.filialId);
  }


  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.selectedSemester = this.educationSlices.find(semester => semester.semesterInYear === dataItem.semesterInYear && semester.semesterYear === dataItem.semesterYear) ?? null
    this.isLine = true;
    dataItem.filialId = this.filialId;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public onChangeEducationSlices(value: SemesterYearsModel): void {
    this.selectedSemester = value;
    this.formGroup!.value.semesterInYear = value.semesterInYear;
    this.formGroup!.value.semesterYear = value.semesterYear;
    this.formGroup?.reset(this.formGroup?.value);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex
  ): void {
    this.isNew = false;
    this.selectedSemester = null;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.generalSheetService.addGeneralSheet(this.formGroup.value)
          .subscribe({
            next: () => {
              this.getAllGeneralSheets();
              this.notificationService.showSuccess("Добавлено");
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить запись");
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.generalSheetService.updateGeneralSheet(this.formGroup.value)
          .subscribe({
            next: () => {
              this.getAllGeneralSheets();
              this.notificationService.showSuccess("Сохранено");
            },
            error: (error) => {
              this.notificationService.showError("Не удалось изменить запись. " + error.error);
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllGeneralSheets() {
    this.generalSheetService.getGeneralSheet()
      .subscribe({
        next: (response) => {
          this.generalSheets = response;
          this.filterGeneralSheets();
        },
        error: () => {
          this.notificationService.showError("Не удалось получить общую ведомость по дисциплине");
        }}
      );
  }

  public getAllFilials() {
    this.filials = [];
    this.dictService.getFilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public editable?: boolean;
  private trainingLevel = TrainingLevelEnum;

  public async getTrainingLevel() {
    await this.dictService.updateTrainingLevels();
    this.dictService.trainingLevels$.subscribe((response) => {
      this.trainingLevels = response.filter(level =>
        level.name == this.trainingLevel.aspirant ||
        level.name == this.trainingLevel.ordinat);
    });
  }

  public async getStudyForm() {
    await this.dictService.updateStudyForms();
    this.dictService.studyForms$.subscribe((response) => {
      this.studyForms = response;
    });
  }

  public async getSemesterYear(){
    await this.dictService.updateDictSemesterYears();
    this.dictService.semesterYears$.subscribe((response) => {
      if (response.length) {
        response.forEach((item) => {
          item.semesterNumberString =
            item.semesterInYear + ' семестр ' + item.semesterYearString;
        });

        let semesterYear = response.find((x) => x.isCurrent)!.semesterYear;
        this.educationSlices = response.filter((x)=> x.semesterYear <= semesterYear);
    }});
  }

  private getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(response => this.editable = response.localSignatoryAccessLevel || this.isAdmin);
  }

  public nameTrainingLevel(value: string) {
    return this.trainingLevels.find((x) => x.id === value)?.name ?? "";
  }

  public nameStudyForm(value: string) {
    return this.studyForms.find((x) => x.id === value)?.name ?? "";
  }

  //Deleting an entry from dictionary
  public removeHandler(dataItem: DictGeneralSheet): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, this.nameTrainingLevel(dataItem.trainingLevelId)
      + " " + this.nameStudyForm(dataItem.studyFormId) + " " + dataItem.semesterInYear + " семестр " + dataItem.semesterYear +"-" + (dataItem.semesterYear + 1))

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.generalSheetService.deleteGeneralSheet(dataItem.id)
            .subscribe({
              next: () => {
                this.getAllGeneralSheets();
                this.notificationService.showSuccess("Удалено");
              },
              error: (error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public courses: Array <{course: string; courseNum: number}> =
    [
      { course: "1 курс", courseNum: 1 },
      { course: "2 курс", courseNum: 2 },
      { course: "3 курс", courseNum: 3 },
      { course: "4 курс", courseNum: 4 },
      { course: "5 курс", courseNum: 5 },
      { course: "6 курс", courseNum: 6 },
    ];

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { id?: string; trainingLevelId?: string; studyFormId?: boolean; semesterYear?: number;
  semesterInYear?: number, courseNum?: number, filialId: string | null }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    trainingLevelId: new FormControl(dataItem.trainingLevelId, Validators.required),
    studyFormId: new FormControl(dataItem.studyFormId, Validators.required),
    semesterYear: new FormControl(dataItem.semesterYear, Validators.required),
    semesterInYear: new FormControl(dataItem.semesterInYear, Validators.required),
    courseNum: new FormControl(dataItem.courseNum, Validators.required),
    filialId: new FormControl(dataItem.filialId, Validators.required),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

<contingent-header></contingent-header>
<h1>Контингент</h1>
<div *ngIf="!newStudPerson" class="content newstudent">
  <div class="functionButtons">
    <button kendoButton
            size="small"
            [routerLink]="['/contingent']">
      Отмена
    </button>
    <button kendoButton
            icon="plus"
            style="float: right;"
            *ngIf="addNewStudent"
            size="small"
            themeColor="success"
            (click)="addStudPerson()">
      Добавить студента
    </button>
  </div>
  <div class="ngForm about">
    <form class="k-form" [formGroup]="formStudPerson">
      <h4>Поиск физ. лица</h4>
      <h3>Перед добавлением нового физического лица следует убедиться, что оно отсутствует в системе!</h3>
      <div class="container-fio-search">
        <kendo-label text="ФИО"></kendo-label>
        <kendo-formfield>
          <kendo-textbox
            formControlName="fio"
            size="small"
            [(ngModel)]="this.filter.fio"
            [clearButton]="true"
            placeholder="Введите ФИО"
            #fio
          ></kendo-textbox>
        </kendo-formfield>
        <button kendoButton
                size="small"
                (click)="getContingentPage(1)">
          Поиск
        </button>
        <h3 class="student-search-error" *ngIf="!studentGrid && studentGridError">Физическое лицо не найдено. Добавьте новое физическое лицо.</h3>
      </div>

      <div class="loader-container">
        <kendo-loader type="converging-spinner" size="medium" *ngIf="loading && !studentGrid"></kendo-loader>
      </div>

      <div *ngIf="studentGrid" class="content-container" id="grid" name="grid">
        <kendo-grid #grid="kendoGrid"
                    [pageSize]="pageSize"
                    [pageable]="true"
                    [reorderable]="false"
                    [resizable]="true"
                    [data]="gridView"
                    [sortable]="true"
                    style="height: 400px; max-width:1140px"
                    [sort]="sort"
                    [skip]="skip"
                    (edit)="transitionHandler($event)"
                    (pageChange)="pageChange($event)"
                    (sortChange)="dataStateChange($event)"
                    [loading]="loading"
        >
          <kendo-grid-column field="studentId"
                             title="№ п/п"
                             [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{(rowIndex+1)}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="fullName"
                             title="ФИО"
                             [width]="200">
          </kendo-grid-column>
          <kendo-grid-column field="birthday"
                             title="Дата рождения"
                             [width]="150">
          </kendo-grid-column>
          <kendo-grid-column field="educationStandardName"
                             title="Профиль (программа)"
                             [width]="200">
          </kendo-grid-column>
          <kendo-grid-column field="trainingLevel"
                             title="Уровень подготовки"
                             [width]="120">
          </kendo-grid-column>
          <kendo-grid-column field="studentAcademicState"
                             title="Академический статус"
                             [width]="150">
          </kendo-grid-column>
          <kendo-grid-column title="" [width]="120">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button kendoGridEditCommand
                      size="small"
                      [hidden]="dataItem.editable == false"
                      rounded="full">
                Выбрать
              </button>
            </ng-template>
          </kendo-grid-column>
          <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
              <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
          </ng-template>
          <ng-template kendoGriаdNoRecordsTemplate>
            <p style="text-align:left">Нет записей для отображения.</p>
          </ng-template>

          <ng-template kendoPagerTemplate>
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="3"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-info></kendo-pager-info>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
          </ng-template>

        </kendo-grid>
      </div>
    </form>
  </div>
</div>

<div *ngIf="newStudPerson" class="content">
  <!--Grid-->
  <kendo-gridlayout
    [cols]="[620, 300]">
    <kendo-gridlayout-item [col]="1" [row]="1" [colSpan]="2" [rowSpan]="2" class="addStudent-box">
      <div *ngIf="!isNew" class="left-column-student">
        <kendo-avatar width="200px"
                      height="200px"
                      [imageSrc]="thumbnailSrc"
                      rounded="none"
                      style="margin: 0 30px 30px 0"></kendo-avatar>
        <h3 style="text-align: center;">
          {{
          (contingent.studPersons.lastName == null ? "" : contingent.studPersons.lastName) + " " +
          (contingent.studPersons.firstName == null ? "" : contingent.studPersons.firstName) + " " +
          (contingent.studPersons.middleName == null ? "" : contingent.studPersons.middleName)
          }}
        </h3>
      </div>
      <div>
        <!--Information in Edit Mode-->
        <div id="profile" class="ngFormAddStudent">
          <form class="k-form" [formGroup]="formStudPerson">
            <dl class="about">
              <h4>Личные данные</h4>
              <dd></dd>
              <!--FIO-->
              <div *ngIf="!isHidden(enum.fio)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="lastName" text="ФИО"></kendo-label>
                </div>
                <dd class="container-fio">
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="lastName"
                      class="input-main-fio"
                      size="small"
                      [disabled]="!isNew&&!femaleStatus||!contingent.editable"
                      [clearButton]="true"
                      placeholder="Фамилия"
                      #lastName
                      required
                      pattern='[-a-zA-Zа-яА-ЯёЁ ]*'
                    ></kendo-textbox>
                    <span class="required">*</span>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['lastName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['lastName']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                  <div style="margin-bottom: 5px">
                    <kendo-formfield>
                      <kendo-textbox
                        formControlName="firstName"
                        class="input-main-fio"
                        size="small"
                        [disabled]="!isNew||!contingent.editable"
                        [style.margin-left.px]="-1"
                        [clearButton]="true"
                        placeholder="Имя"
                        #firstName
                        required
                        pattern='[-a-zA-Zа-яА-ЯёЁ ]*'
                      ></kendo-textbox>
                      <span class="required">*</span>
                      <kendo-formerror *ngIf="formStudPerson.controls?.['firstName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                      <kendo-formerror *ngIf="formStudPerson.controls?.['firstName']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
                    </kendo-formfield>
                  </div>
                  <div class="line-break"></div>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="middleName"
                      class="input-main-fio"
                      style="margin-right: 20px;"
                      [disabled]="!isNew||!contingent.editable"
                      size="small"
                      [clearButton]="true"
                      placeholder="Отчество"
                      pattern='[-a-zA-Zа-яА-ЯёЁ ]*'
                    ></kendo-textbox>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['middleName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                  </kendo-formfield>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="maidenName"
                      class="input-main-fio"
                      size="small"
                      [disabled]="!isNew&&!femaleStatus||!contingent.editable"
                      [clearButton]="true"
                      placeholder="{{maidenPlaceholder}}"
                      pattern='[-a-zA-Zа-яА-ЯёЁ ]*'
                    ></kendo-textbox>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['maidenName']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
              <!--FIO Latin-->
              <div *ngIf="!isHidden(enum.fio_latin)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="lastNameLatin" text="ФИО (латиница)"></kendo-label>
                </div>
                <dd class="container-fio">
                  <div style="margin-bottom: 5px">
                    <kendo-formfield>
                      <kendo-textbox
                        formControlName="lastNameLatin"
                        style="margin-right: 20px;"
                        [disabled]="(!isNew&&!femaleStatus)||!contingent.editable"
                        class="input-main-fio"
                        size="small"
                        [clearButton]="true"
                        placeholder="Last Name"
                        #lastNameLatin
                        pattern='[-a-zA-Z ]*'
                      ></kendo-textbox>
                      <kendo-formerror *ngIf="formStudPerson.controls?.['lastNameLatin']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                    </kendo-formfield>
                  </div>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="firstNameLatin"
                      class="input-main-fio"
                      size="small"
                      [disabled]="!isNew||!contingent.editable"
                      [clearButton]="true"
                      placeholder="First Name"
                      #firstNameLatin
                      pattern='[-a-zA-Z ]*'
                    ></kendo-textbox>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['firstNameLatin']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                  </kendo-formfield>
                  <div class="line-break"></div>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="middleNameLatin"
                      class="input-main-fio"
                      size="small"
                      [disabled]="!isNew||!contingent.editable"
                      [clearButton]="true"
                      placeholder="Middle Name"
                      pattern='[-a-zA-Z ]*'
                    ></kendo-textbox>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['middleNameLatin']?.errors?.['pattern']">В поле присутствуют недопустимые символы</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
              <!--Gender -->
              <div *ngIf="!isHidden(enum.gender)" class="content-row">
                <kendo-label text="Пол"></kendo-label>
                <dd *ngIf="!contingent.editable" style="margin-top: 5px" class="input-main-addition-data">{{(contingent.studPersons.isMale?"Мужской": "Женский")}}</dd>
                <dd *ngIf="contingent.editable" class="male">
                  <kendo-formfield>
                    <input type="radio"
                           name="isMale"
                           [value]="true"
                           [style.margin-left.px]="0"
                           formControlName="isMale"
                           (change)="onChangeMale()"
                           #male
                           required
                           kendoRadioButton />
                    <!--<label class="k-label" style="margin-right:15px" [for]="male">Мужской</label>-->
                  </kendo-formfield>
                  Мужской
                  <kendo-formfield>
                    <input type="radio"
                           name="isMale"
                           [value]="false"
                           (change)="onChangeMale()"
                           formControlName="isMale"
                           #female
                           required
                           kendoRadioButton />
                    <!-- <label class="k-label" [for]="female">Женский</label> -->
                  </kendo-formfield>
                  Женский
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.citizenship)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="citizenshipId" text="Гражданство"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-combobox [data]="citizenships"
                                    class="input-main-addition-data"
                                    textField="country"
                                    valueField="citizenshipExternalId"
                                    [valuePrimitive]="true"
                                    [disabled]="!contingent.editable"
                                    #citizenshipId
                                    placeholder="Гражданство"
                                    (valueChange)="citizenshipValueChange($event)"
                                    [openOnFocus]="citizenshipId"
                                    [kendoDropDownFilter]="filterSettings"
                                    [formControl]="$any(formStudPerson).controls['citizenshipId']"
                                    formControlName="citizenshipId"
                                    required>
                    </kendo-combobox>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.oksm)" class="content-row">
                <kendo-label text="Код ОКСМ"></kendo-label>
                <dd class="input-main-addition-data">{{oksm}}</dd>
              </div>
            </dl>
          </form>


          <form class="k-form"  [formGroup]="formAdditionalField">
            <dl class="about">
              <div *ngIf="!isHidden(enum.compatriot)" class="content-row">
                <kendo-label [for]="isCompatriot" text="Соотечественник"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <input type="checkbox" kendoCheckBox formControlName="isCompatriot" #isCompatriot />
                  </kendo-formfield>
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.citizenship_type)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="citizenshipType" text="Тип гражданства"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-combobox [data]="citizenshipTypes"
                                    class="input-main-addition-data"
                                    textField="citizenshipTypeName"
                                    valueField="dictCitizenshipTypeExternalId"
                                    [valuePrimitive]="true"
                                    [disabled]="!contingent.editable"
                                    #citizenshipType
                                    placeholder="Тип гражданства"
                                    [openOnFocus]="citizenshipType"
                                    [kendoDropDownFilter]="filterSettings"
                                    [formControl]="$any(formAdditionalField).controls['citizenshipTypeId']"
                                    formControlName="citizenshipTypeId">
                    </kendo-combobox>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </form>

          <form class="k-form" [formGroup]="formStudPerson">
            <dl class="about">
              <!--СНИЛС-->
              <div *ngIf="!isHidden(enum.snils)" class="content-row">
                <kendo-label text="СНИЛС"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-maskedtextbox class="input-main-addition-data"
                                         [mask]="mask"
                                         [disabled]="!contingent.editable"
                                         size="small"
                                         formControlName="snils"
                                         (valueChange)="snilsValueChange($event)"
                                         [required]="snilsRequired">
                    </kendo-maskedtextbox>
                    <span class="required" *ngIf="snilsRequired">*</span>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['snils']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
                    <kendo-formerror *ngIf="formStudPerson.controls?.['snils']?.errors?.['notCorrect']">Контрольная сумма не верна. Измените введённое значение</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
              <!--ИНН-->
              <div *ngIf="!isHidden(enum.inn)" class="content-row">
                <kendo-label [for]="inn" text="ИНН"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="inn"
                      class="input-main-addition-data"
                      size="small"
                      [disabled]="!contingent.editable"
                      [maxlength]=12
                      [clearButton]="true"
                      #inn
                      placeholder="ИНН"
                    ></kendo-textbox>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
              <!--Дата рождения-->
              <div *ngIf="!isHidden(enum.birthdate)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="birthday" text="Дата рождения"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-datepicker
                      format="dd.MM.yyyy"
                      #birthday
                      [disabled]="!contingent.editable"
                      class="input-main-addition-data"
                      [openOnFocus]="birthday"
                      formControlName="birthday"
                      required>
                    </kendo-datepicker>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </form>
          <dl class="other">
            <!--Семейное положение-->
            <div *ngIf="!isHidden(enum.marital_status)" class="content-row">
              <kendo-label [for]="marital" text="Семейное положение"></kendo-label>
              <dd class="ddinline">
                <div>
                  <kendo-dropdownlist [data]="listFamilyState"
                                      textField="maritalStatus"
                                      valueField="maritalStatus"
                                      [valuePrimitive]="true"
                                      #marital
                                      [disabled]="!contingent.editable"
                                      (valueChange)="femaleStatusChange()"
                                      class="input-main-addition-data"
                                      [(ngModel)]="familyStatusId">
                  </kendo-dropdownlist>
                </div>
              </dd>
            </div>
          </dl>
          <dl class="other">
            <h4>Информация об обучении</h4>
            <dd></dd>
          </dl>
          <form class="k-form" [formGroup]="formStudPerson">
            <dl class="about">
              <!--Филиал-->
              <div class="content-row">
                <kendo-label class="budget_label" [for]="filialId" text="Филиал"></kendo-label>
                <dd>
                  <kendo-combobox [data]="filials"
                                  class="input-main-addition-data"
                                  textField="filialSName"
                                  valueField="dictFilialExternalId"
                                  (valueChange)="onFilialChange($event)"
                                  [valuePrimitive]="true"
                                  [defaultItem]="defaultItemFilial"
                                  #filialId
                  >
                  </kendo-combobox>
                </dd>
              </div>
            </dl>
          </form>
          <form class="k-form" [formGroup]="formStudPerson">
            <dl class="about">
              <!--Идентификатор студента-->
              <div *ngIf="!isHidden(enum.id)" class="content-row">
                <kendo-label [for]="login" text="Идентификатор студента"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="login"
                      class="input-main-addition-data"
                      size="small"
                      [disabled]="!isAdmin"
                      [(ngModel)]="this.studPerson.login"
                      #login
                    ></kendo-textbox>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </form>
          <form class="k-form" [formGroup]="formStudent">
            <dl class="about">
              <!--Номер зачетной книжки-->
              <div *ngIf="!isHidden(enum.student_number)" class="content-row">
                <kendo-label [for]="studentNumber" text="Номер зачётной книжки"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-textbox
                      formControlName="studentNumber"
                      class="input-main-addition-data"
                      size="small"
                      [disabled]="!contingent.editable"
                      [clearButton]="true"
                      [(ngModel)]="this.onestudent.studentNumber"
                      placeholder="Номер зачётной книжки"
                      #studentNumber
                    ></kendo-textbox>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </form>

          <form class="budget" [formGroup]="formBudgetState">
            <dl class="about">
              <div *ngIf="!isHidden(enum.budget)" class="content-row">
                <kendo-label class="budget_label" [for]="dictStudentBudgetId" text="Источник финансирования"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="filteredBudgets"
                                        class="input-main-addition-data"
                                        textField="studentBudgetName"
                                        valueField="dictStudentBudgetExternalId"
                                        [disabled]="!isFilialSelected || !contingent.editable"
                                        (selectionChange)="onBudgetChange($event)"
                                        [defaultItem]="defaultItemBudget"
                                        [valuePrimitive]="true"
                                        #dictStudentBudgetId
                                        [formControl]="$any(formBudgetState).controls['dictStudentBudgetId']"
                                        formControlName="dictStudentBudgetId"
                                        required
                    >
                    </kendo-dropdownlist>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.budget_category) && selectedBudgetCategories.length !== 0" class="content-row">
                <kendo-label class="budget_label" [for]="dictStudentBudgetId" text="Категория финансирования"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="selectedBudgetCategories"
                                        class="input-main-addition-data"
                                        textField="categoryName"
                                        valueField="dictStudentBudgetCategoryExternalId"
                                        (valueChange)="onBudgetCategoryChange($event)"
                                        [valuePrimitive]="true"
                                        #dictStudentBudgetId
                                        [formControl]="$any(formBudgetState).controls['dictStudentBudgetCategoryId']"
                                        formControlName="dictStudentBudgetCategoryId">
                    </kendo-dropdownlist>
                  </kendo-formfield>
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.budget_sub_category) && selectedbudgetsubcategories.length !== 0" class="content-row">
                <kendo-label class="budget_label" [for]="dictStudentBudgetId" text="Подкатегория финансирования"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="selectedbudgetsubcategories"
                                        class="input-main-addition-data"
                                        textField="budgetSubCategoryName"
                                        valueField="dictStudentBudgetSubCategoryExternalId"
                                        [valuePrimitive]="true"
                                        #dictStudentBudgetId
                                        [formControl]="$any(formBudgetState).controls['dictStudentBudgetSubCategoryId']"
                                        formControlName="dictStudentBudgetSubCategoryId"
                                        [required]="budgetSubCategoryRequired">
                    </kendo-dropdownlist>
                    <span class="required" *ngIf="budgetSubCategoryRequired">*</span>
                    <kendo-formerror *ngIf="formBudgetState.controls?.['dictStudentBudgetSubCategoryId']?.errors?.['required']">Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>

            </dl>
          </form>

          <form class="k-form" [formGroup]="formBudgetState">
            <dl class="about" [hidden]="isBudgetOrganizationSelected != true">
              <!--Заказчик целевого обучения-->
              <div *ngIf="!isHidden(enum.customer_targeted_training)" class="content-row">
                <kendo-label [for]="dictOrganizationId" class="budget_label" text="Заказчик целевого обучения"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-combobox [data]="targetOrganizations"
                                    [disabled]="!isBudgetOrganizationSelected||!contingent.editable"
                                    class="input-main-addition-data"
                                    textField="organizationName"
                                    valueField="dictOrganizationExternalId"
                                    [valuePrimitive]="true"
                                    #dictOrganizationId
                                    [kendoDropDownFilter]="filterSettings"
                                    placeholder="Заказчик целевого обучения..."
                                    [formControl]="$any(formBudgetState).controls['dictOrganizationId']"
                                    formControlName="dictOrganizationId"
                    >
                    </kendo-combobox>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
            <dl class="about" [hidden]="isBudgetOrganizationSelected != true">
              <!--Работодатель-->
              <div *ngIf="!isHidden(enum.employer)" class="content-row">
                <kendo-label [for]="dictOrganizationEmployerId" text="Работодатель"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-combobox [data]="organizations"
                                    class="input-main-addition-data"
                                    textField="organizationName"
                                    valueField="dictOrganizationExternalId"
                                    [kendoDropDownFilter]="filterSettings"
                                    [disabled]="!contingent.editable"
                                    [valuePrimitive]="true"
                                    placeholder="Работодатель..."
                                    #dictOrganizationEmployerId
                                    [formControl]="$any(formBudgetState).controls['dictOrganizationEmployerId']"
                                    formControlName="dictOrganizationEmployerId"
                    >
                    </kendo-combobox>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
            <dl class="about" [hidden]="isBudgetSelected != false">
              <!--Договор на платное обучение-->
              <div *ngIf="!isHidden(enum.paid_training_agreement)" class="content-row">
                <kendo-label [for]="paidContractNum" class="budget_label" text="Договор на платное обучение"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-textbox
                      [formControl]="$any(formBudgetState).controls['paidContractNum']"
                      class="input-main-addition-data input-paid"
                      size="small"
                      [disabled]="isBudgetSelected||!contingent.editable"
                      [style.margin-left.px]="-1"
                      placeholder="№"
                      #paidContractNum
                    ></kendo-textbox>
                  </kendo-formfield>
                  <kendo-formfield>
                    <kendo-datepicker
                      format="dd.MM.yyyy"
                      #paidContractDate
                      [disabled]="isBudgetSelected||!contingent.editable"
                      class="input-main-addition-data input-paid"
                      [openOnFocus]="paidContractDate"
                      [formControl]="$any(formBudgetState).controls['paidContractDate']">
                    </kendo-datepicker>
                  </kendo-formfield>
                  <kendo-formfield>
                    <kendo-numerictextbox
                      [formControl]="$any(formBudgetState).controls['paidContractCostFull']"
                      class="input-main-addition-data input-paid"
                      size="small"
                      [disabled]="isBudgetSelected||!contingent.editable"
                      placeholder="Стоим. в год, руб."
                      #paidContractCostFull
                    ></kendo-numerictextbox>
                  </kendo-formfield>
                  <kendo-formfield>
                    <kendo-numerictextbox
                      [formControl]="$any(formBudgetState).controls['paidContractCostYear']"
                      class="input-main-addition-data input-paid"
                      size="small"
                      [disabled]="isBudgetSelected||!contingent.editable"
                      placeholder="Стоим. в сем., руб."
                      #paidContractCostYear
                    ></kendo-numerictextbox>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </form>
          <form class="k-form" [formGroup]="formEduGroup">
            <dl class="about">
              <!--Факультет-->
              <div *ngIf="!isHidden(enum.faculty)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="dictDepartmentId" text="Институт/факультет"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="filteredFaculties"
                                        textField="shortName"
                                        valueField="facultyExternalId"
                                        [valuePrimitive]="true"
                                        #dictDepartmentId
                                        class="input-main-addition-data"
                                        [disabled]="!isFilialSelected || !contingent.editable"
                                        (valueChange)="onDepartmentChange($event)"
                                        [formControl]="$any(formEduGroup).controls['facultyId']"
                                        [defaultItem]="defaultItemfaculty"
                                        formControlName="facultyId"
                                        required
                    >
                    </kendo-dropdownlist>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
              <!--Курс-->
              <div *ngIf="!isHidden(enum.course)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="course" text="Курс"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="courses"
                                        class="input-main-addition-data"
                                        textField="course"
                                        valueField="courseNum"
                                        [disabled]="isDisabledCourse||!contingent.editable"
                                        [valuePrimitive]="true"
                                        #course
                                        [defaultItem]="defaultItemCourse"
                                        (valueChange)="onCourseChange($event)"
                                        [formControl]="$any(formEduGroup).controls['courseNum']"
                                        formControlName="courseNum"
                                        required
                    >
                    </kendo-dropdownlist>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </form>
          <form class="k-form" [formGroup]="formStudEduGroup">
            <dl class="about">
              <!--Группа-->
              <div *ngIf="!isHidden(enum.group)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="groupe" text="Группа"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="courseselectedugroup"
                                        class="input-main-addition-data"
                                        textField="groupName"
                                        [disabled]="isDisabledGroup || !contingent.editable"
                                        valueField="eduGroupExternalId"
                                        [valuePrimitive]="true"
                                        #groupe
                                        [defaultItem]="defaultItemGroupe"
                                        (valueChange)="onGroupChange($event)"
                                        [formControl]="$any(formStudEduGroup).controls['eduGroupId']"
                                        formControlName="eduGroupId"
                                        required
                    >
                    </kendo-dropdownlist>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </form>

          <form class="k-form" [formGroup]="formStudPlan">
            <dl class="about">
              <!--Направление подготовки-->
              <div *ngIf="!isHidden(enum.education_standard)" class="content-row">
                <kendo-label text="Направление подготовки"></kendo-label>
                <dd class="input-main-addition-data">
                  {{(educationPlan?.educationProgram == null ? "" :
                    ("(" + educationPlan?.educationProgram?.educationStandard?.cipher+ ") " + educationPlan?.educationProgram?.educationStandard?.name))}}
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.study_form)" class="content-row">
                <kendo-label text="Форма обучения"></kendo-label>
                <dd class="input-main-addition-data">{{(educationPlan?.dictStudyFormId == null ? "" : namestudyform(educationPlan?.dictStudyFormId))}}</dd>
              </div>

              <div *ngIf="!isHidden(enum.training_level)" class="content-row">
                <kendo-label text="Уровень подготовки"></kendo-label>
                <dd class="input-main-addition-data">{{((educationPlan?.educationProgram == null ? "-" : nametraininglevel(educationPlan?.educationProgram?.educationStandard?.dictTrainingLevelId)))}}</dd>
              </div>

              <div *ngIf="!isHidden(enum.department)" class="content-row">
                <kendo-label text="Кафедра"></kendo-label>
                <dd class="input-main-addition-data">{{(educationPlan?.educationProgram?.graduatingDepartmentId == null ? "" : nameDepartment(educationPlan?.educationProgram?.graduatingDepartmentId))}}</dd>
              </div>

              <div *ngIf="!isHidden(enum.education_plan)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="plan" text="Учебный план"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="selectedEducationPlans"
                                        class="input-main-addition-data"
                                        textField="name"
                                        [disabled]="isDisabledPlan || !contingent.editable"
                                        valueField="planId"
                                        [valuePrimitive]="true"
                                        (valueChange)="onEducationPlanChange($event)"
                                        #plan
                                        [formControl]="$any(formStudPlan).controls['planId']"
                                        formControlName="planId"
                                        required>
                    </kendo-dropdownlist>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.education_program)" class="content-row">
                <kendo-label text="Профиль (программа)"></kendo-label>
                <dd class="input-main-addition-data"> {{contingent.eduGroup == null
                  ? (formStudPlan.value?.planId || isHidden(enum.education_plan) ? educationPlan?.educationProgram?.name : "-")
                  : nameEducationProgram(contingent.studPlan?.planId
                    ? contingent.studPlan?.planId
                    : contingent.eduGroup.planId)}}
                </dd>
              </div>
            </dl>
          </form>

          <form class="k-form" [formGroup]="formStudPlan">
             <dl class="about">
               <div *ngIf="!isHidden(enum.scientific_adviser_tutor)" class="content-row">
                 <kendo-label [for]="scientificPersonId" text="Научный руководитель"></kendo-label>
                 <dd>
                   <kendo-formfield>
                     <kendo-combobox [data]="persons"
                                     [openOnFocus]="scientificPersonId"
                                     textField="fio"
                                     class="input-main-addition-data"
                                     valueField="personExternalId"
                                     [valuePrimitive]="true"
                                     [disabled]="!contingent.editable"
                                     [kendoDropDownFilter]="filterSettings"
                                     #scientificPersonId
                                     [formControl]="$any(formStudPlan).controls['scientificPersonId']"
                                     formControlName="scientificPersonId">
                     </kendo-combobox>
                   </kendo-formfield>
                 </dd>
               </div>

               <div *ngIf="!isHidden(enum.pedagogical_head)" class="content-row">
                 <kendo-label [for]="practicePersonId" text="Руководитель педагогической практики"></kendo-label>
                 <dd>
                   <kendo-formfield>
                     <kendo-combobox [data]="persons"
                                     textField="fio"
                                     class="input-main-addition-data"
                                     valueField="personExternalId"
                                     [openOnFocus]="practicePersonId"
                                     [valuePrimitive]="true"
                                     [disabled]="!contingent.editable"
                                     [kendoDropDownFilter]="filterSettings"
                                     #practicePersonId
                                     [formControl]="$any(formStudPlan).controls['practicePersonId']"
                                     formControlName="practicePersonId">
                     </kendo-combobox>
                   </kendo-formfield>
                 </dd>
               </div>
             </dl>
             </form>
          <form class="k-form" [formGroup]="formAcademicState">
            <dl class="about">
              <!--Академический статус-->
              <div *ngIf="!isHidden(enum.academic_state)" class="content-row">
                <div class="label-sticky-top">
                  <kendo-label [for]="dictStudentAcademicStateId" text="Академический статус"></kendo-label>
                </div>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="dictStudentAcademicStates"
                                        textField="academicStateName"
                                        class="input-main-addition-data"
                                        valueField="dictStudentAcademicStateExternalId"
                                        [disabled]="!contingent.editable"
                                        [valuePrimitive]="true"
                                        #dictStudentAcademicStateId
                                        [defaultItem]="defaultItemStatus"
                                        [formControl]="$any(formAcademicState).controls['dictStudentAcademicStateId']"
                                        formControlName="dictStudentAcademicStateId"
                                        required
                    >
                    </kendo-dropdownlist>
                    <span class="required">*</span>
                    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
                  </kendo-formfield>
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.score_number)" class="content-row">
                <kendo-label text="Номер счета"></kendo-label>
                <dd class="input-main-addition-data">{{("-")}}</dd>
              </div>
            </dl>
          </form>
          <div class="k-form">
            <dl class="editForm languages-bottom">
              <div *ngIf="!isHidden(enum.languages) && isHidden(enum.languages_level)" class="content-row">
                <kendo-label text="Изучаемые языки"></kendo-label>
                <dd class="langdd">
                  <kendo-multiselect
                    [(ngModel)]="selectLanguages"
                    [data]="dictlanguages"
                    textField="languageName"
                    valueField="dictLanguageExternalId"
                    class="input-main-addition-data"
                    [disabled]="!contingent.editable"
                    #multiselect
                    (valueChange)="valueChange($event)"
                  >
                  </kendo-multiselect>
                  <span class="k-button-icon k-icon k-i-arrow-s" style="right: 22px; cursor: pointer;" (click)="openMultiSelect()" (mousedown)="$event.preventDefault()" *ngIf="!hideArrow"></span>
                </dd>
              </div>

              <div *ngIf="!isHidden(enum.languages) && !isHidden(enum.languages_level)" class="content-row">
                <kendo-label text="Изучаемые языки"></kendo-label>
                <dd class="languages">
                  <kendo-label text="Изучаемый язык"></kendo-label>
                  <kendo-label class="languages-level" text="Степень знания"></kendo-label>
                </dd>
                <dd>
                </dd>
                <ng-container class="ddinline" *ngFor="let item of languages!; let i=index">
                  <dd class="dd-languages">
                    <kendo-combobox [data]="dictlanguages"
                                    textField="languageName"
                                    valueField="dictLanguageExternalId"
                                    class="languages-textbox"
                                    #languagesOpen
                                    [disabled]="!contingent.editable"
                                    [openOnFocus]="languagesOpen"
                                    [clearButton]=false
                                    [(ngModel)]="languages[i].dictLanguageId"
                                    [kendoDropDownFilter]="filterSettings"
                                    [valuePrimitive]="true">
                    </kendo-combobox>

                    <kendo-combobox [data]="languageLevels"
                                    textField="levelName"
                                    valueField="dictLanguageLevelExternalId"
                                    class="languages-textbox level"
                                    #languagesLevelOpen
                                    [disabled]="!contingent.editable || languages[i].dictLanguageId === undefined"
                                    [openOnFocus]="languagesLevelOpen"
                                    [(ngModel)]="languages[i].dictLanguageLevelId"
                                    [kendoDropDownFilter]="filterSettings"
                                    [valuePrimitive]="true">
                    </kendo-combobox>
                    <button kendoButton
                            icon="delete"
                            class="languages-button"
                            fillMode="flat"
                            *ngIf="contingent.editable"
                            size="small"
                            rounded="full"
                            (click)="removeLanguages(i)">
                    </button>
                  </dd>
                  <dd></dd>
                </ng-container>

                <dd>
                  <button kendoButton
                          size="small"
                          *ngIf="contingent.editable"
                          (click)="addLanguages()">
                    Добавить
                  </button>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <!--Buttons in Edit Mode-->
        <div class="functionButtons">
          <button kendoButton
                  size="small"
                  [routerLink]="['/contingent']">
            Отмена
          </button>
          <button kendoButton
                  *ngIf="contingent.editable"
                  themeColor="primary"
                  size="small"
                  (click)="saveStudent()">
            Добавить студента
          </button>
        </div>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DictAcceleratedStudyBase } from 'src/app/models/education/dictAcceleratedStudyBase.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictAcceleratedStudyBaseService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictAcceleratedStudyBase}`;

  constructor(private http: HttpClient) { }

  //Get all AcceleratedStudyBases
  public getAllAcceleratedStudyBases(): Observable<DictAcceleratedStudyBase[]> {
    return this.http.get<DictAcceleratedStudyBase[]>(this.baseUrl + '/GetAcceleratedStudyBases/');
  }

  // Add AcceleratedStudyBase
  addAcceleratedStudyBase(dictAcceleratedStudyBase: DictAcceleratedStudyBase): Observable<DictAcceleratedStudyBase> {
    return this.http.post<DictAcceleratedStudyBase>(this.baseUrl + '/CreateAcceleratedStudyBase/' , dictAcceleratedStudyBase)
      .pipe(catchError(this.handleError));
  }

  // Edit AcceleratedStudyBase
  updateAcceleratedStudyBase(dictAcceleratedStudyBase: DictAcceleratedStudyBase): Observable<DictAcceleratedStudyBase> {
    return this.http.put<DictAcceleratedStudyBase>(this.baseUrl + '/UpdateAcceleratedStudyBase/' + dictAcceleratedStudyBase.dictAcceleratedStudyBaseId, dictAcceleratedStudyBase)
      .pipe(catchError(this.handleError));
  }

  // Remove AcceleratedStudyBase
  deleteAcceleratedStudyBase(id: string): Observable<DictAcceleratedStudyBase> {
    return this.http.delete<DictAcceleratedStudyBase>(this.baseUrl + '/DeleteAcceleratedStudyBase/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

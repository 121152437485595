<h5 class="achievements-title">Документы</h5>

<div *ngIf="!editMode">
  <button kendoButton class="achievements-title" themeColor="success" iconClass="k-icon k-i-plus" *ngIf="editable" (click)="documentAddForm()">Добавить</button>
  <kendo-grid [kendoGridBinding]="documents"
              (remove)="removeHandler($event)"
              (edit)="editHandler($event)"
              [sortable]="true"
              scrollable="none"
              [loading]="loading">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <kendo-grid-column field="name"
                       headerClass="gridHeader dictionaries"
                       title="Наименование документа">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{ dataItem.name }}
            </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="review" title="Сканированная копия" [width]="250" headerClass="gridHeader">
      <ng-template kendoGridCellTemplate let-dataItem>
                    <span style="display: block; cursor: pointer; margin: 0 auto" class="k-icon k-i-file" (click)="downloadFile(dataItem)">
                    </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" [headerStyle]="{'text-align': 'center'}" [width]="140">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand
                *ngIf="editable"
                themeColor="secondary"
                icon="edit"
                fillMode="flat"
                size="medium"
                rounded="full">
        </button>
        <button kendoGridRemoveCommand
                *ngIf="editable"
                themeColor="secondary"
                icon="delete"
                fillMode="flat"
                size="medium"
                rounded="full">
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

</div>


<div class="document-container" *ngIf="editMode">
  <form novalidate class="k-form" [formGroup]="editForm">
    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Наименование документа</h2>
        <kendo-dropdownlist
          [data]="dictDocumentNames"
          textField="name"
          valueField="dictDocumentNameId"
          [valuePrimitive]="true"
          [formControl]="$any(editForm).controls['documentNameId']"
          formControlName="documentNameId" required>
        </kendo-dropdownlist>
      </div>
      <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <div class="portfolioField">
        <h2 class="portfolioField__title">Сканированная копия</h2>
        <kendo-fileselect
          (select)="fileSelected()" (remove)="fileRemoved()"
          style="width: 100%" [restrictions]="restrictions"
          [multiple]="false"
          [(ngModel)]="uploads"
          [ngModelOptions]="{standalone: true}">
          <kendo-fileselect-messages
            invalidFileExtension="Выбранный файл должен быть в формате .pdf"
            [invalidMaxFileSize]="maxFileSizeMessage">
          </kendo-fileselect-messages>
        </kendo-fileselect>
      </div>
      <span class="uploadedFile" *ngIf="!isNew" (click)="downloadFile(editForm.value)">
                <span class="k-icon k-i-file"></span>
                <h2 class="uploadedFile__name">{{fileName}}</h2>
            </span>
      <h2 class="warning" *ngIf="!fileUploaded && !isNew">* Загрузите новую копию для изменения достижения</h2>
    </kendo-formfield>
    <h2 class="warning" *ngIf="!fileUploaded && isNew">* Загрузите скан.копию для добавления нового достижения</h2>
    <div class="buttons">
      <button kendoButton (click)="onCancel()">Отмена</button>
      <button kendoButton themeColor="primary"
              [disabled]="!editForm.valid || !fileUploaded"
              class="saveButton"
              (click)="saveDocument()">Сохранить</button>
    </div>
  </form>
</div>

<div kendoDialogContainer></div>


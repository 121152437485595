<mfc-settings></mfc-settings>
<h1>Мой деканат</h1>

<div class="buttons">
  <button *ngIf="editable"
          (click)="addApplication()"
          kendoButton
          class="add-button k-button-solid-success"
          icon="plus">
    Добавить заявку
  </button>
  <button *ngIf="totalCount"
          (click)="export(grid)"
          kendoButton
          icon="download">
    <kendo-loader *ngIf="loaderExcelExport"
                  size="small"
                  type="converging-spinner">
    </kendo-loader>
    Экспорт в Excel
  </button>
</div>

<div class="switch">
  <kendo-switch [(ngModel)]="waitingOnly"
                (valueChange)="getApplications()">
  </kendo-switch>
  <h3>Требующие согласования/подписания</h3>
</div>

<kendo-grid class="grid-data borderless"
            #grid
            [data]="gridData"
            [pageable]="pagerSettings"
            [pageSize]="state.take"
            [filterable]="true"
            [sortable]="true"
            (sortChange)="sortChange($event)"
            [sort]='sort'
            [loading]="loading"
            [skip]="state.skip"
            (pageChange)="pageChange($event)"
            (cellClick)="navigateToSelectedApplication($event)">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="applicationNumber"
                     title="№ заявки"
                     [width]="130">
    <ng-template kendoGridFilterCellTemplate>
      <kendo-textbox (afterValueChanged)="filterChange($event, 'applicationNumber')"
                     [clearButton]="true">
      </kendo-textbox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field] }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="applicationStatus"
                     title="Текущий статус"
                     [width]="200">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="applicationStatuses"
                          (onFilterChange)="filterChange($event, 'applicationStatuses')"
                          textField="name"
                          valueField="applicationStatusEnum">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <div class="alignCells leftAlignment">
        <div [ngClass]="{
                status: true,
                red: dataItem[column.field] === StatusEnum.Rejected,
                green: dataItem[column.field] === StatusEnum.Ready
              }">
          <span class="badge">
            <kendo-badge *ngIf="dataItem.notificationCount > 0">
              {{ dataItem.notificationCount }}
            </kendo-badge>
          </span>
          {{statusMap.get(dataItem[column.field])}}
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>

<!--  <kendo-grid-column field="approvalStatus"-->
<!--                     title="Статус согласования"-->
<!--                     [width]="200">-->
<!--    <ng-template kendoGridFilterCellTemplate>-->
<!--      <filter-multiselect [data]="approvalStatuses"-->
<!--                          (onFilterChange)="filterChange($event, 'applicationApprovalStatuses')"-->
<!--                          gridField="approvalStatus"-->
<!--                          textField="name"-->
<!--                          valueField="name">-->
<!--      </filter-multiselect>-->
<!--    </ng-template>-->
<!--    <ng-template kendoGridCellTemplate let-dataItem>-->
<!--      <div class="alignCells leftAlignment">-->
<!--        <div [ngClass]="{-->
<!--                status: true,-->
<!--                red: dataItem.status === StatusEnum.Rejected,-->
<!--                green: dataItem.status === StatusEnum.Ready-->
<!--              }">-->
<!--          {{ dataItem.statusText }}-->
<!--        </div>-->
<!--      </div>-->
<!--    </ng-template>-->
<!--  </kendo-grid-column>-->

  <kendo-grid-column field="studentName"
                     title="ФИО обучающегося"
                     [width]="250">
    <ng-template kendoGridFilterCellTemplate>
      <kendo-textbox (afterValueChanged)="filterChange($event, 'studentName')"
                     [clearButton]="true">
      </kendo-textbox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <span class="alignCells leftAlignment">
        {{ dataItem[column.field] }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="filial"
                     title="Филиал"
                     [width]="180">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="filialTree"
                          (onFilterChange)="filialChange($event)"
                          gridField="filial"
                          textField="name"
                          valueField="id">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <span class="alignCells leftAlignment">
        {{ dataItem[column.field].name }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="trainingLevel"
                     title="Уровень подготовки"
                     [width]="180">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="trainingLevels"
                          (onFilterChange)="filterChange($event, 'trainingLevels')"
                          gridField="trainingLevel"
                          textField="name"
                          valueField="id">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <span class="alignCells leftAlignment">
        {{ dataItem[column.field].name }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="faculty"
                     title="Институт/ факультет"
                     [width]="180">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="faculties"
                          (onFilterChange)="filterChange($event, 'faculties')"
                          [ngModel]="options.filter.faculties"
                          ngDefaultControl
                          gridField="faculty"
                          textField="name"
                          valueField="id">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <span class="alignCells leftAlignment">
        {{ dataItem[column.field].name }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="groupName"
                     title="Группа"
                     [width]="150">
    <ng-template kendoGridFilterCellTemplate>
      <kendo-textbox (afterValueChanged)="filterChange($event, 'groupName')"
                     [clearButton]="true">
      </kendo-textbox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <span class="alignCells leftAlignment">
        {{ dataItem[column.field] }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="createdAt"
                     title="Дата заявки"
                     [width]="320">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <date-range-filter [field]="column.field"
                         [filter]="filter"
                         (onFilterChange)="filterChange($event, 'createdAt')">
      </date-range-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field] | date:'dd.MM.yyyy HH:mm' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="applicationCategory"
                     title="Категория"
                     [width]="200">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="applicationCategories"
                          gridField="category"
                          textField="name"
                          valueField="id"
                          (onFilterChange)="categoryChange($event)">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <span class="alignCells leftAlignment">
        {{ dataItem[column.field].name }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="applicationType"
                     title="Тип"
                     [width]="300">
    <ng-template kendoGridFilterCellTemplate>
      <filter-multiselect [data]="filterApplicationTypes"
                          (onFilterChange)="filterChange($event, 'applicationTypes')"
                          [ngModel]="options.filter.applicationTypes"
                          ngDefaultControl
                          gridField="type"
                          textField="name"
                          valueField="id">
      </filter-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      <span class="alignCells leftAlignment">
        {{ dataItem[column.field].name }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="statusUpdatedAt"
                     title="Дата установки текущего статуса"
                     [width]="320">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <date-range-filter [field]="column.field"
                         [filter]="filter"
                         (onFilterChange)="filterChange($event, 'statusUpdatedAt')">
      </date-range-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field] | date:'dd.MM.yyyy HH:mm' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="Список заявок.xlsx" [fetchData]="mapExportData">
    <kendo-excelexport-column field="faculty"
                              title="Факультет"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="150">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="rowIndex"
                              title="№ п/п"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="70">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="applicationNumber"
                              title="№ заявки"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="100">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="studentName"
                              title="ФИО обучающегося"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="300">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="groupName"
                              title="Группа"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="100">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="filial"
                              title="Филиал"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="150">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="trainingLevel"
                              title="Уровень подготовки"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="150">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="createdAt"
                              title="Дата заявки"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="100">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="applicationCategory"
                              title="Категория"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="170">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="applicationType"
                              title="Тип"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="300">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="applicationStatus"
                              title="Текущий статус"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="150">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="statusUpdatedAt"
                              title="Дата установки текущего статуса"
                              [headerCellOptions]="headerCellOptions"
                              [cellOptions]="cellOptions"
                              [width]="220">
    </kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>

<div kendoDialogContainer></div>

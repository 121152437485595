import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ScienceDegreesService {

    baseUrl = `${environment.lkPersonApiEndpoint}`;

    constructor(private http: HttpClient) { }

    public getDegrees(){
        return this.http.get(this.baseUrl + 'sDegrees/getScientificDegrees' );
    }

    public addDegrees(data: any){
        return this.http.post(this.baseUrl + 'sDegrees/addScientificDegress', data)
    }

    public updateDegree(data: any, id: any){
        data.externalId = id;
        //console.log(data)
        return this.http.put(this.baseUrl + 'sDegrees/updateScientificDegress', data)
    }
}
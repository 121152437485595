import {Component, OnInit, ViewChild} from "@angular/core";
import {trainingLevelModel, BlankStatusModel, BlankTypesModel} from '../../../../models/diplomablanks/dicts.model';
import {GeneralDictsService} from '../../../../services/diplomablanks/general-dicts.service';
import {arrayRewrite} from "../../../../helpers/multiselect-helper";
import {pagerSettings} from 'src/app/models/diplomablanks/pagerSettings.model';
import {GivenBlanksService} from  '../../../../services/diplomablanks/given-blanks.service';
import {GivenBlanksModel} from  '../../../../models/diplomablanks/given-blanks.model';
import {DictBlankStatusesService} from '../../../../services/diplomablanks/dict-blankstatuses.service';
import {CompositeFilterDescriptor} from "@progress/kendo-data-query";
import {filterBy} from "@progress/kendo-data-query";
import {DateFromUTCAsLocal, TimeZoneFix} from '../../../../helpers/date-helper';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {blankStatus} from '../../../../models/diplomablanks/enum/blankStatus.enum';
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {DiplomablanksUserAccessService} from '../../../../services/useraccess/diplomablanks-user-access.service';
import {levelAccess} from '../../../../models/diplomablanks/enum/boolOptions.enum';
import {isDuplicate} from '../../../../models/diplomablanks/enum/diploma.enum';

@Component({
    selector: 'app-givenblanks',
    templateUrl: './given-blanks.component.html',
    styleUrls: ['./given-blanks.component.scss']
  })

export class GivenBlanksComponent implements OnInit {

  public trainingLevel: trainingLevelModel[] = [];
  public trainingLevelList: string[] = [];
  public years: number[] = [];
  public year: number | null = null;
  public blankTypes: BlankTypesModel[] = [];
  public blankTypeList: string[] = [];

  public findBlanks: number = 0;

  public givenBlanks: GivenBlanksModel[] = [];
  public dataReturnBlanks: GivenBlanksModel[] = [];

  public loading: boolean = false;
  protected pageSize = 20;
  protected readonly pagerSettings = pagerSettings;

  public blankSeries: Array<{series: string}> = [];
  public blankTemplates: Array<{blankTemplateName: string}> = [];
  public blankStatuses: Array<{blankStatusName: string}> = [];
  public recipientPerson: Array<{recipientPersonFioInitialsAfter: string}> = [];

  public allStatuses: BlankStatusModel[] = [];
  public status: string = "";
  @ViewChild(GridComponent) private grid!: GridComponent;

  public graduateYears: Array<{graduateYear: number}> = [];
  public isDuplicate = isDuplicate;

  public toReturn: boolean = false;
  public allItemsChecked: boolean = false;
  public checkedBlanks: number[] = [];
  public opened: boolean = false;
  public isLine: boolean = false;
  public editRow = 0;

  public editable: boolean = false;

  constructor(
    private generalDictsService: GeneralDictsService,
    private givenBlanksService: GivenBlanksService,
    private dictBlankStatusesService: DictBlankStatusesService,
    private notificationService: NotificationsService,
    private diplomablanksUserAccessService: DiplomablanksUserAccessService,
  ) { }

  public ngOnInit(): void {
    this.getUserAccess();
    this.getTraininglevel();
    this.getStatuses();
  }

  public getUserAccess() {
    this.diplomablanksUserAccessService.getUserAccess()
    .subscribe(response => {
      this.editable = response.userAccessRight == levelAccess.write;
    })
  }

  public getTraininglevel() {
    this.generalDictsService.getExistTrainingLevel()
      .subscribe(response => {
        this.trainingLevel = response;
        if (this.trainingLevel) {
          this.trainingLevel.unshift({externalId: "", name: 'Все'});
          this.trainingLevelList = [""];
        }
        this.getYears();
      })
  }

  public onChangeLevel(value: string[]) {
    this.trainingLevelList = arrayRewrite(value);
    this.clearFilters();
    this.getBlankTypes();
  //  this.getBlanks();
  }

  public onChangeType(value: string[]) {
    this.blankTypeList = arrayRewrite(value);
    this.clearFilters();
    this.getBlanks();
  }

  public onChangeYear(value: number) {
    this.clearFilters();
    this.getBlanks();
  }

  public blur() {
    this.getBlanks();
  }

  public getValue(dataItem: trainingLevelModel[]) {
    return dataItem.some(el => el.externalId == "") ? "Все" : dataItem.length + " выбрано";
  }

  public getYears() {
    this.generalDictsService.getYear()
      .subscribe(response => {
        if (response) this.years = response;
        this.year = this.years[0];

        this.getBlankTypes();
      })
  }

  public getBlankTypes() {
    this.blankTypes = [];
    this.blankTypeList = [];
    this.generalDictsService.getExistBlankTypes(this.trainingLevelList.some(el => el == "") ? [null] : this.trainingLevelList, true)
      .subscribe(response => {
        if (response) this.blankTypes = response;
        if (this.trainingLevelList.some(el => el == "")) {
          this.blankTypes.unshift({externalId: "", name: 'Все'});
          this.blankTypeList = [""];
        } else {
          this.blankTypeList = [...new Set(this.blankTypes.map(el => el.externalId))];
        }

        this.getBlanks();
      })
  }

  public getBlanks() {
    this.loading = true;
    const data = {trainingLevelIds: this.trainingLevelList.some(el => el == "") ? [null] : this.trainingLevelList,
                  dictBlankTypeIds: this.blankTypeList.some(el => el == "") ? [null] : this.blankTypeList,
                  issueYear: this.year};
    this.givenBlanksService.getIssueBlanks(data)
      .subscribe(response => {
        response ? this.givenBlanks = response : this.givenBlanks = [];

        this.givenBlanks.map((item) => {
          item.diplomaIssueDate = DateFromUTCAsLocal(item.diplomaIssueDate);
          item.issueDate = new Date(item.issueDate);
        });

        const gridData = filterBy(this.toReturn ? this.dataReturnBlanks : this.givenBlanks, this.filter);
        // this.findBlanks = this.givenBlanks.length;
        this.findBlanks = gridData.length;

        if (this.toReturn) this.returnBlanks();

        this.getFiltersTable();
      })
      this.loading = false;
  }

  private getFiltersTable() {
    const series = [...new Set(this.givenBlanks.map(el => el.series))];
    this.blankSeries = series.map(function(value) {
        return {series: value};
    });

    const template = [...new Set(this.givenBlanks.map(el => el.blankTemplateName))];
    this.blankTemplates = template.map(function(value) {
        return {blankTemplateName: value};
    });

    const recipient = [...new Set(this.givenBlanks.map(el => el.recipientPersonFioInitialsAfter))];
    this.recipientPerson = recipient.map(function(value) {
        return {recipientPersonFioInitialsAfter: value};
    });

    const status = [...new Set(this.givenBlanks.map(el => el.blankStatusName))];
    this.blankStatuses = status.map(function(value) {
        return {blankStatusName: value};
    });

    const year = [...new Set(this.givenBlanks.filter(item => item.graduateYear !== null).map(el => el.graduateYear))];
    this.graduateYears = year.map(function(value) {
        return {graduateYear: value};
    });
  }

  public getStatuses() {
    this.dictBlankStatusesService.getBlankStatuses()
      .subscribe(response => {
        if (response) this.allStatuses = response;
      })
  }

  public clearFilters() {
    this.filter = {filters: [], logic: "and"};
    this.toReturn ? this.findBlanks = this.dataReturnBlanks.length : this.findBlanks = this.givenBlanks.length
  }

  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    const gridData = filterBy(this.toReturn ? this.dataReturnBlanks : this.givenBlanks, this.filter);
    this.findBlanks = gridData.length;

    this.resetData();
  }

  public returnBlanks() {
    this.toReturn = true;
    this.dataReturnBlanks = this.givenBlanks.filter(el => el.blankStatusEnum == blankStatus.notused);
    this.filterChange(this.filter)
  }

  public cancelReturnBlanks() {
    this.toReturn = false;
    this.checkedBlanks = [];
    this.allItemsChecked = false;
    this.filterChange(this.filter)
  }

  public selectAll(value: boolean) {
    this.allItemsChecked = !this.allItemsChecked;
    const filterData: any = this.grid.data;
    this.allItemsChecked ? this.checkedBlanks = filterData.data.map((el: any) => el.id) : this.checkedBlanks = [];
  }

  public checked(id: number) {
    return this.checkedBlanks.includes(id);
  }

  public checkedChange(id: number) {
    const index = this.checkedBlanks.indexOf(id);
    index == -1 ? this.checkedBlanks.push(id) : this.checkedBlanks.splice(index, 1);

    const filterData: any = this.grid.data;
    this.checkedBlanks.length == filterData.data.length ? this.allItemsChecked = true : this.allItemsChecked = false;
  }

  public return() {
    this.opened = true;
  }

  public close(status: string): void {
    if (status == 'yes') {
      this.givenBlanksService.returnBlanks(this.checkedBlanks)
        .subscribe(response => {
          this.notificationService.showSuccess('Успешно');
          this.getBlanks();
          this.dataReturnBlanks = [];
          this.filter = {filters: [], logic: "and"};
          this.resetData();
        })
    }
    this.opened = false;
  }

  public pageChange(event: PageChangeEvent): void {
    this.resetData();
  }

  private resetData() {
    if (this.toReturn) {
      this.allItemsChecked = false;
      this.checkedBlanks = [];
    }
  }

  public report() {
    const startDate: any = this.filter.filters.find((el: any) => el.operator == 'gte');
    const endDate: any = this.filter.filters.find((el: any) => el.operator == 'lte');
    const data = {dictTrainingLevelIds: this.trainingLevelList.some(el => el == "") ? [null] : this.trainingLevelList,
                  dictBlankTypeIds: this.blankTypeList.some(el => el == "") ? [null] : this.blankTypeList,
                  issueYear: this.year,
                  dateStart: startDate ? TimeZoneFix(startDate.value).toISOString() : null,
                  dateEnd: endDate ? TimeZoneFix(endDate.value).toISOString() : null};
    this.givenBlanksService.report(data)
    .subscribe({
        next: (value) => {
          DownloadFile(value);
        },
        error: () => {
          this.notificationService.showError('Не удалось скачать файл');
        },
      });
  }

  public editStatus(args: CellClickEvent) {
    if (this.editable) {
      if (this.isLine) args.sender.closeRow(this.editRow);

      this.editRow = args.rowIndex;
      this.status = args.dataItem.blankStatusId;
      args.sender.editCell(args.rowIndex, args.columnIndex);
    }
  }

  public changeStatus(value: string, dataItem: GivenBlanksModel) {
    this.givenBlanksService.changeStatus(dataItem.externalId, value)
      .subscribe(response => {
        this.getBlanks();
        this.grid.closeRow(this.editRow);
      })
  }

  public getStatus(dataItem: GivenBlanksModel) {
    switch (dataItem.blankStatusEnum) {
      case blankStatus.notused:
        return this.allStatuses.filter(el => el.enum !== blankStatus.used);
      case blankStatus.used:
        return this.allStatuses.filter(el => el.enum == blankStatus.used || el.enum == blankStatus.defective);
      case blankStatus.notdiploma:
        return this.allStatuses.filter(el => el.enum !== blankStatus.used);
      case blankStatus.defective:
        return dataItem.graduateYear || dataItem.diplomaIssueDate || dataItem.diplomaRegNumber || dataItem.isDuplicate !== null
               ? this.allStatuses.filter(el => el.enum == blankStatus.used || el.enum == blankStatus.defective)
               : this.allStatuses.filter(el => el.enum == blankStatus.notused || el.enum == blankStatus.defective);
      default:
        return this.allStatuses;
    }
  }

  public getValueDuplicate(value: boolean) {
    return isDuplicate.find(el => el.isDuplicate == value)?.text;
  }
}

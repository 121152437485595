<card-header></card-header>
<h1>Зарплатные проекты</h1>

<div class="selectContent" *ngIf="!isInEditingMode">
    <dd class="filial">
      <span class="selectText">
        Филиал
      </span>
      <kendo-dropdownlist [data]="filials"
                          textField="filialSName"
                          valueField="dictFilialExternalId"
                          [valuePrimitive]="true"
                          name="filial"
                          (valueChange)="onValueChange($event)"
                          [(ngModel)]="secondFilter.dictFilialExternalId"
                          [defaultItem]="defaultItemFilial"
                          class="filialSelect">
      </kendo-dropdownlist>
    </dd>
  </div>

<div class="grid-container">

    <kendo-grid [kendoGridBinding]="salaryByFilter"
                [pageable]="true"  [pageSize]="pageSize"
                (cellClick)="editHandler($event)"
                (add)="addHandler($event)"
                (remove)="removeHandler($event)"
                [sortable]="true"
                [filterable]="true">

        <ng-template kendoGridToolbarTemplate position="top">
            <button kendoGridAddCommand size="small" themeColor="success" icon="plus" *ngIf="!isInEditingMode">Добавить</button>
            <div *ngIf="isInEditingMode">
                <button kendoButton
                        (click)="cancelHandler()"
                        size="small">
                  Отмена
                </button>
                <button kendoButton
                        [disabled]="formGroup!.invalid"
                        themeColor="primary"
                        (click)="saveCurrent()"
                        size="small">
                  Сохранить
                </button>
              </div>
        </ng-template>


        <kendo-grid-column field="bankName"
                title="Наименование банка"
                headerClass="gridHeader"
                [width]="250">
           <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-textbox
                    size="small"
                    [formControl]="formGroup.get('bankName')"
                    [clearButton]="true"
                ></kendo-textbox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells leftAlignment">
                {{dataItem.bankName}}
              </span>
            </ng-template>

            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-cell>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="bankNum"
                title="Номер отделения банка"
                headerClass="gridHeader"
                [width]="150">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('bankNum')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                      {{dataItem.bankNum}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="filial"
                title="Филиал отделения банка"
                headerClass="gridHeader"
                [width]="150">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('filial')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                      {{dataItem.filial}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="contractNum"
                title="Номер договора"
                headerClass="gridHeader"
                [width]="130">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('contractNum')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                      {{dataItem.contractNum}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="contractDate"
                title="Дата договора"
                headerClass="gridHeader"
                filter="date" format="dd.MM.yyyy"
                [width]="150">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-datepicker
                        format="dd.MM.yyyy"
                        [formControl]="formGroup.get('contractDate')"
                        >
                    </kendo-datepicker>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                    {{dataItem.contractDate | date: 'dd.MM.yyyy'}}
                    </span>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="contractCurrency"
                title="Валюта договора"
                headerClass="gridHeader"
                [width]="130">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('contractCurrency')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                      {{dataItem.contractCurrency}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="codeVkladVid"
                title="Вид вклада"
                headerClass="gridHeader"
                [width]="130">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('codeVkladVid')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                    {{dataItem.codeVkladVid}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="codeVklad"
                title="Код вида вклада"
                headerClass="gridHeader"
                [width]="130">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('codeVklad')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                    {{dataItem.codeVklad}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="codeVkladPodvid"
                title="Код подвида вклада"
                headerClass="gridHeader"
                [width]="130">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('codeVkladPodvid')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                    {{dataItem.codeVkladPodvid}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="categoryPeople"
                title="Категория населения "
                headerClass="gridHeader"
                [width]="150">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('categoryPeople')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                    {{dataItem.categoryPeople}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="crn"
                title="CRN"
                headerClass="gridHeader"
                [width]="130">
                <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                    <kendo-textbox
                        size="small"
                        [formControl]="formGroup.get('crn')"
                        [clearButton]="true"
                    ></kendo-textbox>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                    {{dataItem.crn}}
                    </span>
                </ng-template>

                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                    </kendo-grid-string-filter-cell>
                </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="order"
                title="Порядковый номер для сортировки при отображении"
                headerClass="gridHeader"
                [width]="200">
        <ng-template kendoGridEditTemplate
            let-dataItem="dataItem"
            let-formGroup="formGroup">
                    <span class="alignCells">
                        <kendo-numerictextbox
                        [style.width.px]="170"
                        [value]="dataItem.order"
                        [min]="1"
                        format="n0"
                        [decimals]="0"
                        [formControl]="formGroup.get('order')">
                        </kendo-numerictextbox>
                    </span>
        </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.order}}
              </span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-cell>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="" [width]="70">
            <ng-template kendoGridCellTemplate>
                <span class="alignCells">
                    <button kendoGridRemoveCommand
                        themeColor="secondary"
                        icon="delete"
                        fillMode="flat"
                        size="small"
                        rounded="full">
                    </button>
                </span>
            </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate>
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                <kendo-pager-numeric-buttons
                [buttonCount]="5"
                ></kendo-pager-numeric-buttons>
                <kendo-pager-next-buttons></kendo-pager-next-buttons>
                <kendo-grid-spacer></kendo-grid-spacer>
                <kendo-pager-info></kendo-pager-info>
                <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
        </ng-template>

    </kendo-grid>
</div>

<div kendoDialogContainer></div>

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { Guid } from "guid-typescript";
import { catchError, Observable, throwError } from "rxjs";
import { DictGroupStandardLimitation } from "src/app/models/education/dictGroupStandardLimitation.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DictGroupStandardLimitationService {
    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictGroupStandardLimitation}`;

    constructor(private http: HttpClient) { }

    public getAllDictGroupStandardLimitation() : Observable<DictGroupStandardLimitation[]>
    {
        return this.http.get<DictGroupStandardLimitation[]>(this.baseUrl);
    }

    public addDictGroupStandardLimitation(group: DictGroupStandardLimitation): Observable<DictGroupStandardLimitation> {
        return this.http.post<DictGroupStandardLimitation>(this.baseUrl, group)
        .pipe(catchError(this.handleError));
    }

    public updateDictGroupStandardLimitation(group: DictGroupStandardLimitation) {
        return this.http.put<DictGroupStandardLimitation>(this.baseUrl, group)
        .pipe(catchError(this.handleError));
    }

    public deleteDictGroupStandardLimitation(id: Guid) {
        return this.http.delete<DictGroupStandardLimitation>(this.baseUrl + '/' + id)
        .pipe(catchError(this.handleError));
    }

    // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable, throwError} from "rxjs";
import { DictSignatoryRoleRead } from "../../models/dicts/global-signatory-role";
import {Guid} from "guid-typescript";
import { DictSignatoryRoleAdd, DictSignatoryRoleUpdate } from "../../models/dicts/global-signatory-role";

@Injectable({
  providedIn: 'root'
})
export class DictSignatoryRoleService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.signatoryRole}`;

  constructor(private http: HttpClient) {
  }

  public get(): Observable<DictSignatoryRoleRead[]> {
    return this.http.get<DictSignatoryRoleRead[]>(this.baseUrl);
  }

  public getById(signatoryRoleId: Guid | string): Observable<DictSignatoryRoleRead> {
    return this.http.get<DictSignatoryRoleRead>(this.baseUrl + '/' + signatoryRoleId)
      .pipe(catchError(this.handleError));;
  }

  public add(data: DictSignatoryRoleAdd): Observable<Guid | string> {
    return this.http.post<Guid | string>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public update(data: DictSignatoryRoleUpdate): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public delete(signatoryRoleId: Guid | string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + signatoryRoleId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {IReportTemplateInfo} from "../../../models/report-designer/ITemplateInfo";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {ReportDesignerService} from "../../../services/report-designer/report-designer.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  private serviceName = 'GIA';
  private giaBaseUrl: string = environment.apiEndpoint.toString().endsWith('/')
    ? environment.apiEndpoint.toString().slice(0, -1) : environment.apiEndpoint.toString();
  public reportPath: string = "";
  public reportTemplatesList: IReportTemplateInfo[] = [];

  constructor(private notificationService: NotificationsService,
              private reportDesignerService: ReportDesignerService,
              private router: Router) { }

  ngOnInit(): void {
    this.getTemplatesByServiceName();
  }

  public getTemplatesByServiceName() {
    this.reportDesignerService.getTemplatesByServiceName(this.serviceName).subscribe({
      next:response => this.reportTemplatesList = response,
      error:err => this.notificationService.showError(err)
    })
  }

  public openReport() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['report-viewer'], {
        queryParams: {
          report: this.reportPath,
          giaBaseUrl: this.giaBaseUrl
        }
      })
    );

    window.open(url, '_blank');
  }
}

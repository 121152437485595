<div class="container" [formGroup]="formPropertiesGroup">
  <kendo-loader *ngIf="loading" class="loader" type="converging-spinner" size="medium"></kendo-loader>
  <div class="dynamicComponents" formArrayName="properties">
    <div *ngFor="let property of formProperties.controls; index as i;">
      <div [formGroupName]="i" class="property">
        <div class="row row__title">
          <h3 *ngIf="property.value.type?.enum === KendoProperties.Table">{{property.value.name}}</h3>
          <span *ngIf="property.value.type?.enum !== KendoProperties.Table">{{property.value.name}}</span>
          <span *ngIf="property.value.required" class="required">*</span>
        </div>
        <button class="addButton" kendoButton *ngIf="(property.value.type?.enum === KendoProperties.Table)
                                                && !isDynamicPropertyDisabled(property.value.allowModeratorOnly)"
                (click)="addRow(formChildProperties(property).controls)" themeColor="success" icon="plus">
          Добавить
        </button>
        <div class="row">
          <span [ngSwitch]="property.value.type?.enum"
                [class]="{table: property.value.type?.enum === KendoProperties.Table}">
            <kendo-textbox class="dynamicTextBox"
                           [required]="property.value.required"
                           formControlName="value"
                           *ngSwitchCase="KendoProperties.Textbox"
                           (valueChange)="onFieldChange()">
            </kendo-textbox>
            <kendo-grid [kendoGridBinding]="property.get('value')?.value"
                        (remove)="onRemove(formChildProperties(property).controls, $event)"
                        (cellClick)="onEdit(formChildProperties(property).controls,
                          isDynamicPropertyDisabled(property.value.allowModeratorOnly), $event)"
                        [formGroup]="formGroup"
                        *ngSwitchCase="KendoProperties.Table">

              <ng-template kendoGridLoadingTemplate>
                <div class="k-loading-color"></div>
                <div class="loading-container">
                  <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
                </div>
              </ng-template>

              <kendo-grid-column field="number" [width]="15" headerClass="gridHeader" title="">
                <ng-template kendoGridCellTemplate let-rowIndex='rowIndex'>
                      <span class="alignCells">
                        {{rowIndex >= 0 ? rowIndex + 1 : null}}
                      </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column *ngFor="let childProperty of formChildProperties(property).controls"
                                 [field]="String(childProperty.value.id)"
                                 headerClass="gridHeader"
                                 [title]="childProperty.value.name"
                                 [width]="childProperty.value.data ? 100 : 200">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    {{dataItem[childProperty.value.id]}}
                  </span>
                </ng-template>
                <ng-template kendoGridEditTemplate>
                  <div [ngSwitch]="childProperty.value.type?.enum" class="table__cell table__cell--custom table__cell--input">
                    <kendo-textbox [class]="{doiMask: currentCode === Codes.DOI}"
                                   *ngSwitchCase="KendoProperties.Textbox"
                                   [formControlName]="childProperty.value.id">
                    </kendo-textbox>
                    <kendo-dropdownlist *ngSwitchCase="KendoProperties.Dropdown"
                                        [data]="childProperty.value.data"
                                        [valuePrimitive]="true"
                                        [formControlName]="childProperty.value.id"
                                        (valueChange)="codeChange($event)"
                                        textField="name"
                                        valueField="name">
                    </kendo-dropdownlist>
                  </div>
                </ng-template>
              </kendo-grid-column>


              <kendo-grid-column *ngIf="!isDynamicPropertyDisabled(property.value.allowModeratorOnly) && !isEditingMode" headerClass="gridHeader" [width]="30">
                <ng-template kendoGridCellTemplate>
                  <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size alignCells"
                          rounded="full">
                  </button>
                </ng-template>
              </kendo-grid-column>

            </kendo-grid>
          </span>
          <a *ngIf="property.value?.tooltip" href="{{property.value.tooltip.url}}" target="_blank">
            {{property.value.tooltip.text}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  CurrentSemester,
  SemesterFromPlanRequest, SemesterRequest,
  StudentSemesterEditDto,
  StudentSemestr
} from '../../models/contingent/studentsemestr.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class StudentsemestrService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studentsemestr}`;

  constructor(private http: HttpClient) { }

  public getCurrentSemesterList(): Observable<CurrentSemester[]> {
    return this.http.get<CurrentSemester[]>(this.baseUrl + "/CurrentSemesterList");
  }

  async getSemesterByStudentId(request: SemesterRequest): Promise<Observable<StudentSemestr[]>> {
    return this.http.post<StudentSemestr[]>(this.baseUrl + '/GetSemestrByStudentId/', request)
      .pipe(catchError(this.handleError));
  }

  editSemesterList(studentSemestr: StudentSemesterEditDto[]): Observable<StudentSemestr> {
    return this.http.post<StudentSemestr>(this.baseUrl+ '/EditSemesterList/', studentSemestr)
      .pipe(catchError(this.handleError));
  }

  setSemesterFromCurrentPlan(request: SemesterFromPlanRequest): Observable<StudentSemestr> {
    return this.http.post<StudentSemestr>(this.baseUrl+ '/SetSemesterFromCurrentPlan/', request)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

export const items: any[] = [
    {
        text: "Настройки",
        icon: "cog",
        items: [
            {
                text: "Публикации",
                path: "/publications",
                publications: true,
            },
            {
                text: "Авторы",
                path: "/publications/authors",
                authors: true,
            },
            {
                text: "Отчеты",
                path: "/publications/reports",
                reports: true,
            },
            {
                text: "Настройки доступа",
                path: "/publications/userAccess",
                accessSettings: true,
            },
            {
                text: "Справочники",
                dicts: true,
                items: [
                    {
                        text: "Индексация",
                        path: "/publications/dictScientometricBases",
                    },
                    {
                        text: "Роли автора",
                        path: "/publications/dictAuthorRoles",
                    },
                    {
                        text: "Типы публикаций",
                        path: "/publications/dictPublicationTypes",
                    },
                    {
                        text: "Виды публикаций",
                        path: "/publications/dictPublicationSubTypes",
                    },
                    {
                        text: "Должности",
                        path: "/publications/dictPosts",
                    },
                    {
                        text: "Ученые степени",
                        path: "/publications/dictScienceDegrees",
                    },
                    {
                      text: "Организации",
                      path: "/publications/dictOrganizations",
                    },
                ],
            },
        ],
    },
];

<persondepartment-header></persondepartment-header>
<h1>Кадровая база</h1>

<div class="m-b15 m-t18 align-r">
    <button kendoButton type="button" icon="download" size="small"  (click)="exportToExcel(grid)">
      <kendo-loader *ngIf="loaderExcelExport" size="small" type="converging-spinner"> </kendo-loader>
        Экспорт в Excel
    </button>
</div>

<kendo-grid #grid="kendoGrid"
        [kendoGridBinding]="persons"
        [loading]="loading"
        (add)="addHandler($event)"
        (edit)="editHandler($event)"
        (cellClick)="viewHandler($event)"
        [sortable]="true"
        (sortChange)="sortChange($event)"
        [filterable]="true"
        (filterChange)="filterChange($event)"
        [pageable]="true"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="{
            buttonCount: buttonCount,
            info: info,
            type: type,
            pageSizes: pageSizes,
            previousNext: previousNext,
            position: position
          }"
        >
    <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand themeColor="success" icon="plus">Добавить</button>
    </ng-template>

    <kendo-grid-command-column  [sticky]="true" title="" [width]="50" >
        <ng-template kendoGridCellTemplate>
            <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full">
            </button>
        </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column field="fio"
                       title="ФИО"
                       [width]="130"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.fio }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="postName"
                       title="Должность"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-combobox [useVirtual]='false' [filter]="filter" [data]="posts" textField="postName" valueField="postName">
            </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.postName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="departamentName"
                       title="Структурное подразделение"
                       [width]="180"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-combobox [useVirtual]='false' [filter]="filter" [data]="departments" textField="departamentName" valueField="departamentName">
            </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.departamentName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="externalId"
                       title="ID физ. лица"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.externalId }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="createdAt"
                       title="Дата создания"
                       [width]="200"
                       filter="date" format="dd.MM.yyyy"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.createdAt? (dataItem.createdAt | date: 'dd.MM.yyyy') : '' }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="updatedAt"
                       title="Дата обновления"
                       [width]="200"
                       filter="date" format="dd.MM.yyyy"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.updatedAt? (dataItem.updatedAt | date:'dd.MM.yyyy') : '' }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="loginName"
                       title="Логин"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.loginName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="recordSourceName"
                       title="Способ добавления"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [filter]="filter" [data]="recordSource" textField="recordSourceName" valueField="recordSourceName">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.recordSourceName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="status"
                       title="Статус"
                       [width]="120"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [useVirtual]='false'  [filter]="filter" [data]="statuses" textField="statusName" valueField="commonStatus">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment" >
                {{ dataItem.status }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-excel fileName="Кадровая база.xlsx" [fetchData]="allData"></kendo-grid-excel>
</kendo-grid>

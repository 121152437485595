import {Component, OnInit} from '@angular/core';
import {DictspravkatypeService} from "../../../services/contingent/dictspravkatype.service";
import {StudentmaininfoComponent} from "../studentmaininfo/studentmaininfo.component";
import {SpravkaService} from "../../../services/contingent/spravka.service";
import {ActivatedRoute} from "@angular/router";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {PersonService} from "../../../services/contingent/person.service";
import {CasenameService} from "../../../services/contingent/casename.service";
import {StudentService} from "../../../services/contingent/student.service";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {LocalSignatoryService} from "../../../services/contingent/local-signatory.service";
import {MilitaryCommissariatService} from "../../../services/contingent/military-commissariat.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {Guid} from "guid-typescript";
import {Person} from "../../../models/contingent/person.model";
import {LocalSignatoryCertificate} from "../../../models/contingent/localsignatory.model";
import {Observable} from "rxjs";
import {AddEvent, EditEvent, GridDataResult, RemoveEvent} from "@progress/kendo-angular-grid";
import {SpravkaTypes} from "../../../models/contingent/spravkatypes.model";
import {Spravka, SpravkaPerson, SpravkaStudyPeriod} from "../../../models/contingent/spravka.model";
import {MilitaryCommissariat} from "../../../models/contingent/militarycommissariat.model";
import {CaseName} from "../../../models/contingent/casename.model";
import {CertificateTypeEnum} from "../../../models/contingent/enums/certificate-type.enum";
import {StudyFormTypeEnum} from "../../../models/contingent/enums/study-form-type.enum";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {Role} from "../../../models/useraccess/role";
import {saveAs} from "@progress/kendo-file-saver";
import {Align} from "@progress/kendo-angular-popup";
import {DatePipe} from "@angular/common";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {MilitaryService} from "../../../services/contingent/military.service";
import {MilitaryStatuses} from "../../../models/contingent/militarystatuses.model";
import {MilitarystatusesService} from "../../../services/contingent/militarystatuses.service";
import {CommondictService} from "../../../services/contingent/commondict.service";
import {DictStudyForm} from "../../../models/contingent/dictstudyform.model";
import {OrdersstudyperiodService} from "../../../services/contingent/ordersstudyperiod.service";
import {OrdersStudyPeriod} from "../../../models/contingent/ordersstudyperiod.model";
import {ContingentOrganizationService} from "../../../services/contingent/contingentOrganization.service";
import {ContingentOrganization} from "../../../models/contingent/organization.model";

@Component({
  selector: 'app-militarycertificate',
  templateUrl: './militarycertificate.component.html',
  styleUrls: ['./militarycertificate.component.scss']
})
export class MilitarycertificateComponent implements OnInit {

  element: any;

  constructor(private dictCertificateTypeService: DictspravkatypeService,
              public studentMainInfoComponent:StudentmaininfoComponent,
              private spravkaService:SpravkaService,
              private activateRoute: ActivatedRoute,
              private notificationService: NotificationsService,
              private militaryService: MilitaryService,
              private dialogService: DialogService,
              private organizationService: ContingentOrganizationService,
              private personsService: PersonService,
              private caseNameService: CasenameService,
              private militarystatusesService: MilitarystatusesService,
              private studService:StudentService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private localSignatoryService: LocalSignatoryService,
              private militaryCommissariatService: MilitaryCommissariatService,
              private commondictService: CommondictService,
              private orderStudyPeriodService:OrdersstudyperiodService,
  ) {
    if(activateRoute.snapshot.parent!==null){
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
    }
    this.admin()
  }

  ngOnInit(): void {
    this.getAllSpravkaTypes()
    this.getAllSpravksStudyPeriod()
    this.getAllOrderStudyPeriod()
    this.getAllSpravka()
    this.getOrganization()
    this.getAllMilitaryCommissariat()
    this.getAllPersons()
    this.getStudents()
    this.getLocalSignatories()
    this.getAllMilitaryStatuses()
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public dictstudyforms: DictStudyForm[] = [];
  public loading = false;
  public position: "top" | "bottom" | "both" = "both";
  public editMode: boolean = false;
  public isNew:boolean = false;
  public isNewCaseName:boolean = false;
  public isMilitary:boolean = true;
  public addSignerHide:boolean = false;
  public saveDisable:boolean = true;
  public signerEditOpen:boolean[]=[];
  public numberFormatted?: string;
  public studentId?: any;
  public persons: Person[] = [];
  public localSignatories: LocalSignatoryCertificate[] = []
  public organizations: ContingentOrganization[] = [];
  public view: Observable<GridDataResult> | undefined;
  public spravkaTypes: SpravkaTypes[] = [];
  public spravkas: Spravka[] = [];
  public militaryStatuses: MilitaryStatuses[] = [];
  public militaryCommissariats: MilitaryCommissariat[] = [];
  public spravkaStudyPeriod: SpravkaStudyPeriod = {}
  public studyForm?: any;
  public studyFormId?: Guid;
  public spravka:Spravka={
    spravkaId: 0,
    number:0,
    date:new Date
  }
  public caseName:CaseName={}

  public spravkaPersons: SpravkaPerson[]=[];
  public personPosts: string[]=[];

  public studentMainInfo: any
  public fio: string = ""
  public newDate: Date = new Date()
  public certificateType: any = CertificateTypeEnum;
  public studyFormType: any = StudyFormTypeEnum;
  public orderStudyPeriod?: OrdersStudyPeriod = {}

  public addHandler({ }: AddEvent): void {
    this.availableEdit()
    this.getNewNumber()
    this.form.reset();
    this.formCaseName.reset(this.caseName);
    this.newDate = DateFromUTCAsLocal(new Date())
    this.form.value.date = this.newDate
    this.form.value.code = "09-14"
    if(this.studentMainInfo.contingent.ordersStudyPeriod !== null && this.studentMainInfo.contingent.ordersStudyPeriod.dateFinish !== null)
      this.form.value.employerDateEnd = DateFromUTCAsLocal(this.studentMainInfo.contingent.ordersStudyPeriod.dateFinish)
    this.editMode = true;
    this.isNew =true;
    this.spravkaPersons=[]
    this.personPosts=[]
    this.loadData()
    this.spravkaTypeValueChange(this.certificateType.military)
  }

  public commissariat?: string
  public editable: boolean = false
  public disableMilitary: boolean = true
  public adminedit?: boolean
  public disabled?: boolean

  public getStudents(): void {
    this.studService.getCertificateStudent(this.studentId!)
      .subscribe(
        response => {
          if(response!=null){
            this.editable = response.militaryEditable!
            this.commissariat = response.commissariat
            this.studyFormId = response.studyFormId
            //this.admin()
            this.getMilitary(response.studPersonId!)
            this.getStudentCaseName(response.studPersonId!)
          }
        }
      );
  }

  private getLocalSignatories() {
    this.localSignatoryService.getCertificateSigners(this.studentId!)
      .subscribe({
        next:(response) => {
          this.localSignatories = response;
        }, error:() => {
          this.notificationService.showError("Не удалось получить список локальных подписантов");
        }});
  }

  public getAllMilitaryCommissariat() {
    this.militaryCommissariatService.getAllMilitaryCommissariat()
      .subscribe(
        response => {
          this.militaryCommissariats = response;
        }
      );
  }

  public getAllMilitaryStatuses() {
    this.militarystatusesService.getAllMilitaryStatuses()
      .subscribe(
        response => {
          this.militaryStatuses = response;
        }
      );
  }

  private admin(){
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null) user = this.jwtHelper.decodeToken(token);
    if(user.role.includes(Role.Admin)) {
      this.adminedit=true
    }
  }

  public transitionHandler({dataItem}: EditEvent): void {
    this.spravkaTypeValueChange(dataItem.dictSpravkaTypeId)
    this.saveDisable=true
    this.newDate = DateFromUTCAsLocal(dataItem.date)
    dataItem.date = DateFromUTCAsLocal(dataItem.date)
    if(dataItem.requestDate!=null)
      dataItem.requestDate = DateFromUTCAsLocal(dataItem.requestDate)
    if(dataItem.employerDateBegin!=null)
      dataItem.employerDateBegin = DateFromUTCAsLocal(dataItem.employerDateBegin)
    if(dataItem.employerDateEnd!=null)
      dataItem.employerDateEnd = DateFromUTCAsLocal(dataItem.employerDateEnd)
    if(dataItem.employerDateList!=null)
    {
      let array: any[] = []
      dataItem.employerDateList.forEach((date:any) =>{
        array.push(DateFromUTCAsLocal(date))
      })
      dataItem.employerDateList = array
    }
    this.form.reset(dataItem)
    if(this.form.value.employerDateList != null)
      this.onChange(this.form.value.employerDateList)

    this.formCaseName.reset(this.caseName);
    if(dataItem.isIssued)this.disableEdit()
    if(this.adminedit) this.form.controls['isIssued'].enable()
    this.numberFormatted=dataItem.numberFormatted
    this.spravkaPersons=dataItem.spravkaPersons
    this.personPosts=dataItem.approvePost
    for (let i=0; i<this.personPosts.length; i++){
      this.closeSigner(i)
    }
    //this.signerEditOpen
    this.editMode = true;
    this.isNew = false;
  }

  public disableEdit(){
    this.disabled=true
    this.form.controls['dictSpravkaTypeId'].disable()
    this.form.controls['purpose'].disable()
    this.form.controls['additionalComment'].disable()
    this.form.controls['employerName'].disable()
    this.form.controls['employerFIO'].disable()
    this.form.controls['vacationReason'].disable()
    this.form.controls['article'].disable()
    this.form.controls['employerDateBegin'].disable()
    this.form.controls['employerDateEnd'].disable()
    this.form.controls['employerDate'].disable()
    this.form.controls['employerDateList'].disable()
    this.form.controls['requestNumber'].disable()
    this.form.controls['requestDate'].disable()
    this.form.controls['firstTime'].disable()
    this.form.controls['sendComment'].disable()
    if(!this.adminedit)this.form.controls['isIssued'].disable()
    this.form.controls['instancesNumber'].disable()
    this.formCaseName.controls['rLastName'].disable()
    this.formCaseName.controls['rFirstName'].disable()
    this.formCaseName.controls['rMiddleName'].disable()
    this.formCaseName.controls['dLastName'].disable()
    this.formCaseName.controls['dFirstName'].disable()
    this.formCaseName.controls['dMiddleName'].disable()
  }

  public availableEdit(){
    this.disabled=false
    this.form.controls['dictSpravkaTypeId'].enable()
    this.form.controls['purpose'].enable()
    this.form.controls['additionalComment'].enable()
    this.form.controls['employerName'].enable()
    this.form.controls['employerFIO'].enable()
    this.form.controls['vacationReason'].enable()
    this.form.controls['article'].enable()
    this.form.controls['employerDateBegin'].enable()
    this.form.controls['employerDateEnd'].enable()
    this.form.controls['employerDate'].enable()
    this.form.controls['employerDateList'].enable()
    this.form.controls['requestNumber'].enable()
    this.form.controls['requestDate'].enable()
    this.form.controls['firstTime'].enable()
    this.form.controls['sendComment'].enable()
    this.form.controls['isIssued'].enable()
    this.form.controls['instancesNumber'].enable()
    this.formCaseName.controls['rLastName'].enable()
    this.formCaseName.controls['rFirstName'].enable()
    this.formCaseName.controls['rMiddleName'].enable()
    this.formCaseName.controls['dLastName'].enable()
    this.formCaseName.controls['dFirstName'].enable()
    this.formCaseName.controls['dMiddleName'].enable()
  }

  public toggleEditForm(): void{
    if(this.adminedit&&!this.isNew) this.availableEdit()
  }

  public loadData(){
    this.studentMainInfo=this.studentMainInfoComponent
    this.fio =   (this.studentMainInfoComponent.contingent.studPersons.lastName == null ? "" : this.studentMainInfoComponent.contingent.studPersons.lastName) + " " +
      (this.studentMainInfoComponent.contingent.studPersons.firstName == null ? "" : this.studentMainInfoComponent.contingent.studPersons.firstName) + " " +
      (this.studentMainInfoComponent.contingent.studPersons.middleName == null ? "" : this.studentMainInfoComponent.contingent.studPersons.middleName)+ " " +
      (this.studentMainInfoComponent.contingent.studPersons.maidenName == null ? "" : this.studentMainInfoComponent.contingent.studPersons.maidenName)
    this.getStudyForm()
  }

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.onPrint=false
    this.save()
  }

  public onPrint:Boolean = false;

  public onSaveAndPrint(e: PointerEvent): void {
    e.preventDefault();
    this.onPrint=true
    this.save()
  }

  public closeIdentification(): void {
    this.editMode = false;
    this.form.reset();
    this.stringEmployerDateList = ""
    this.availableEdit()
  }

  public save() {
    this.form.value.spravkaPersons=this.spravkaPersons
    this.form.value.approvePost=this.personPosts
    if(this.form.value.employerDateList && this.form.value.employerDateList.length === 0)
      this.form.value.employerDateList = undefined

    this.saveCaseName()

    if(this.isNew)
    {
      this.form.value.number = this.spravkas.length+1
      this.form.value.studentId = this.studentId
      this.timeZoneFix();
      this.spravkaService.addCertificateMilitary(this.form?.value)
        .subscribe({
          next: (response) => {
            this.notificationService.showSuccess("Справка была успешно добавлена!");
            this.updateGrid();
            if(this.onPrint) this.downloadFile(response)
            this.closeEditForm();
          },
          error: (error) => {
            this.getNewNumber()
            this.notificationService.showError("Не удалось добавить справку. " + error.error);
          }
        });
    }
    else {
      this.timeZoneFix();
      this.spravkaService.updateCertificateMilitary(this.form?.value)
        .subscribe({
          next: (response) => {
            this.notificationService.showSuccess("Справка была успешно сохранено!");
            this.updateGrid();
            if (this.onPrint) this.downloadFile(response)
            this.closeEditForm();
          },
          error: (error) => {
            this.notificationService.showError('Не удалось изменить справку. ' + error.error);
          }
        })
    }
  }


  public closeEditForm(){
    this.availableEdit()
    this.editMode = false;
    this.onPrint = false
    this.stringEmployerDateList = ""
    this.form.reset();
    this.getAllSpravka();
  }

  public saveCaseName() {
    if(this.isNewCaseName)
    {
      this.formCaseName.value.studPersonId = this.studentMainInfoComponent.studPersonExternalId
      this.caseNameService.addcasename(this.formCaseName?.value).subscribe({next:() => {
          this.getStudentCaseName(this.studentMainInfoComponent.studPersonExternalId)
        }});
    }
    else {
      this.caseNameService.updatecasename(this.formCaseName?.value).subscribe({next:() => {
          this.getStudentCaseName(this.studentMainInfoComponent.studPersonExternalId)
        }})
    }
    this.formCaseName.reset()
  }

  public saveMilitary() {
    let commisariatMilitary: any = this.militaryCommissariats.find((item:any) => item.id == this.formMilitary.value.dictMilitaryCommissariatId);
    this.commissariat = commisariatMilitary?.commissariatName;
    if(!this.formMilitary.value.militaryExternalId)
    {
      this.formMilitary.value.studPersonId = this.studentMainInfoComponent.studPersonExternalId
      this.militaryService.addmilitary(this.formMilitary.value, this.studentId).subscribe({next:() => {
          this.getMilitary(this.studentMainInfoComponent.studPersonExternalId)
          this.studentMainInfoComponent.getContingent()
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }});
    }
    else {
      this.militaryService.updatemilitary(this.formMilitary.value, this.studentId).subscribe({next:() => {
          this.getMilitary(this.studentMainInfoComponent.studPersonExternalId)
          this.studentMainInfoComponent.getContingent()
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }})
    }
    this.formMilitary.reset()
  }

  public updateGrid() {
    this.getAllSpravka();
  }

  public nameperson(id: any): string  {
    return this.persons.find((x) => x.personExternalId === id)!.fio;
  }

  public spravkaTypeValueChange(id:any): void {
    this.spravkaPersons=[]
    this.signerEditOpen=[]
    this.personPosts=[]
    const item=this.form.value
    this.addSignerHide=false
    this.saveDisable=false

    switch(id.toString()){
      case this.certificateType.military: {
        this.isMilitary = true
        this.addSignerHide = true
        this.spravka.purpose = this.commissariat
        this.spravkaPersons.push({personId:undefined})
        this.signerEditOpen.push(true)
        this.personPosts.push("")
        this.spravkaPersons.push({personId:undefined})
        this.signerEditOpen.push(true)
        this.personPosts.push("")
        this.spravka.vacationReason = undefined
        this.openSigner(0)
        this.openSigner(1)
        break;
      }
    }

    this.spravka.employerFIO = ""
    this.spravka.vacationReason = ""
    this.spravka.employerName = ""
    this.spravka.spravkaId=item.spravkaId
    this.spravka.spravkaExternalId=item.spravkaExternalId
    this.spravka.studentId=item.studentId
    this.spravka.number=item.number
    this.spravka.employerDateEnd=item.employerDateEnd
    this.spravka.code = item.code

    this.spravka.dictSpravkaTypeId=id
    this.spravka.numberFormatted=item.numberFormatted

    this.form.reset(this.spravka)
    this.spravka.purpose=undefined
    this.spravka.dictSpravkaTypeId=undefined
    this.spravka.article = undefined
  }

  public personValueChange(i:number,id:any) {
    this.saveDisable=false
    this.form.reset(this.form.value)
    this.personPosts[i]=this.localSignatories.find((x) => x.personExternalId === id)!.postName!
    this.checkStudentValid()
  }

  public checkStudentValid(){
    if(this.spravkaPersons[0].personId!=undefined&&this.spravkaPersons[1].personId!=undefined)this.saveDisable=true
    else if(this.spravkaPersons[0].personId&&(this.spravkaPersons[1]?this.spravkaPersons[1].personId!=undefined:true)&&(this.spravkaPersons[2]?this.spravkaPersons[2].personId!=undefined:true))this.saveDisable=true
  }

  public onChangeFindSigner(i:number,name:any){
    const user = this.persons.find((person) => person.fio === name);

    if(user) {
      if(this.localSignatories.find((x) => x.personExternalId === user.personExternalId) === undefined){
        this.localSignatories.push( {
          personExternalId : user.personExternalId,
          fio: user.fio,
          postName: user.post
        })
      }
      this.spravkaPersons[i].personId = user.personExternalId
      this.personValueChange(i, user.personExternalId)
    }
  }

  public getAllStudyForm() {
    this.commondictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictstudyforms = response;
          this.loadData()
        }
      );
  }

  public getStudyForm() {
    if(!this.studyForm && this.dictstudyforms.length > 0 && this.studyFormId){
      this.studyForm = this.dictstudyforms.find((x) => x.dictStudyFormExternalId === this.studyFormId)
      this.disableMilitary = this.studyForm.type !== this.studyFormType.fullTime;
    }
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + dataItem.numberFormatted + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){

          this.spravkaService.deleteCertificateMilitary(dataItem.spravkaExternalId, this.studentId)
            .subscribe({
              next: () => {
                this.getAllSpravka();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }});
        }
        else{

        }
      }
    });
  }

  public downloadFile(values: any)
  {
    this.spravkaService.downloadFile(`${values.spravkaExternalId}/${values.studentId}`).subscribe(
      response => {
        let blob:any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        let saveName: string
        switch (values.dictSpravkaTypeId){
          case this.certificateType.study:
            saveName="Справка об обучении.docx"
            break;
          case this.certificateType.call:
            saveName="Справка-вызов.docx"
            break;
          default:
            saveName = "Справка " + this.fio
            break;
        }
        saveAs(blob, saveName);
      }
    );
  }

  public addSigner():void{
    this.saveDisable=false
    this.spravkaPersons.push({personId:undefined})
    this.signerEditOpen.push(false)
    this.personPosts.push("")
    if(this.spravkaPersons.length==3)this.addSignerHide=true
  }

  public getNewNumber() {
    this.spravkaService.getNewNumber(true).subscribe(
      (response:any) => {
        if(response!=="")this.numberFormatted= response;
      }
    );
  }

  public removeSigner(i:number):void{
    this.addSignerHide=false
    if (i > -1) {
      this.spravkaPersons.splice(i, 1);
      this.signerEditOpen.splice(i, 1);
      this.personPosts.splice(i, 1);
    }
    this.checkStudentValid()
  }

  public closeSigner(i:number):void {
    this.signerEditOpen[i]=true
  }

  public openSigner(i:number):void {
    if(this.disabled)return
    this.signerEditOpen[i]=false;

    if(this.localSignatories.find((person) => person.personExternalId === this.spravkaPersons[i].personId) === undefined) {
      const user = this.persons.find((x) => x.personExternalId === this.spravkaPersons[i].personId)
      if(user)this.localSignatories.push({
        personExternalId: user.personExternalId,
        fio: user.fio,
        postName: user.post
      })

    }
  }

  public nametype(id: Guid): string {
    let type = this.spravkaTypes.find((x) => x.dictSpravkaTypeExternalId === id);
    if(type?.typeName!=null) return type.typeName
    else return ""
  }

  public getAllSpravkaTypes() {
    this.dictCertificateTypeService.getAllspravkatypes()
      .subscribe(
        response => {
          this.spravkaTypes = response;
        }
      );
  }
  public getStudentCaseName(id: Guid) {
    this.caseNameService.getStudentCaseName(id)
      .subscribe(
        response => {
          if(response.caseNameId!=0)
          {
            this.caseName = response;
            this.isNewCaseName = false
          }
          else
          {
            this.caseName = response
            this.isNewCaseName = true
          }
          this.formCaseName.reset(this.caseName)
          this.getAllStudyForm()
        }
      );
  }

  public getMilitary(id: any) {
    this.militaryService.getByStudPersonIdMilitary(id)
      .subscribe(
        response => {
          if(response != null)
            this.formMilitary.reset(response);
          this.loadData()
        })
  }

  public getAllSpravka() {
    this.loading = true;
    this.spravkaService.getStudentSpravka(this.studentId!)
      .subscribe(
        response => {
          this.spravkas = response.filter((x)=>x.dictSpravkaTypeId === this.certificateType.military);
          this.loading = false;
        }
      );
  }

  public getAllOrderStudyPeriod() {
    this.orderStudyPeriodService.getStudentStudyPeriod(this.studentId!)
      .subscribe(
        response => {
          this.orderStudyPeriod = response;
          if (this.orderStudyPeriod!=undefined){
            if (this.orderStudyPeriod.dateStart)this.orderStudyPeriod.dateStart = DateFromUTCAsLocal(this.orderStudyPeriod.dateStart);
            if (this.orderStudyPeriod.dateFinish)this.orderStudyPeriod.dateFinish = DateFromUTCAsLocal(this.orderStudyPeriod.dateFinish);
          }
        }
      );
  }

  public isSpravkaCourse=false;

  public getAllSpravksStudyPeriod(){
    this.spravkaService.getStudyPeriod(this.studentId!)
      .subscribe(
        response => {
          this.spravkaStudyPeriod = response;
          this.isSpravkaCourse = this.spravkaStudyPeriod.orderCourse == undefined;
        }
      );
  }

  public getAllPersons() {
    this.personsService.getAllperson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  public getOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.organizations = response;
        }
      );
  }

  public popupAlign: Align = { horizontal: "right", vertical: "top" };
  public anchorAlign: Align = { horizontal: "right", vertical: "bottom" };
  public show = false;
  public stringEmployerDateList: string|undefined = "";
  public array: any[] = [];

  public onChange(value: Date[]) {
    if(value.length == 0){
      this.form.value.employerDateList = null
      this.stringEmployerDateList = ""
    }
    else {
      let arrayDate: any[] = []
      value.forEach((date: any) => {
        arrayDate.push(this.datepipe.transform(date, 'dd.MM.yyyy'))
      })
      this.stringEmployerDateList = arrayDate.join(", ")
    }
    this.form.value.employerDate = this.stringEmployerDateList
  }

  private datepipe: DatePipe = new DatePipe('ru-Ru');

  public timeZoneFix() {
    if (this.form.value.date != undefined)
      this.form.value.date = TimeZoneFix(this.form.value.date);
    if (this.form.value.requestDate != undefined)
      this.form.value.requestDate = TimeZoneFix(this.form.value.requestDate);
    if (this.form.value.employerDateBegin != undefined)
      this.form.value.employerDateBegin = TimeZoneFix(this.form.value.employerDateBegin);
    if (this.form.value.employerDateEnd != undefined)
      this.form.value.employerDateEnd = TimeZoneFix(this.form.value.employerDateEnd);
    if(this.form.value.employerDateList != undefined){
      let array: any[] = []
      this.form.value.employerDateList.forEach((date:any) =>{
        array.push(TimeZoneFix(date))
      })
      this.form.value.employerDateList = array
      if(this.form.value.employerDateList.length === 0) this.form.value.employerDateList.length = undefined
    }
  }

  public form: UntypedFormGroup = new UntypedFormGroup({
    spravkaId: new UntypedFormControl(),
    spravkaExternalId: new UntypedFormControl(),
    dictSpravkaTypeId:new UntypedFormControl(),
    code:new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    departmentId: new UntypedFormControl(),
    dictStudyLevelId:new UntypedFormControl(),
    number:new UntypedFormControl(),
    numberFormatted:new UntypedFormControl(),
    date:new UntypedFormControl(),
    requestNumber: new UntypedFormControl(),
    requestDate: new UntypedFormControl(),
    sendComment: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    personId:new UntypedFormControl(),
    employerDateBegin:new UntypedFormControl(),
    employerDateEnd:new UntypedFormControl(),
    employerDate:new UntypedFormControl(),
    employerDateList:new UntypedFormControl(),
    employerName:new UntypedFormControl(),
    purpose:new UntypedFormControl(),
    employerFIO:new UntypedFormControl(),
    spravkaPersons:new UntypedFormControl(),
    approvePost:new UntypedFormControl([]),
    instancesNumber:new UntypedFormControl(),
    additionalComment:new UntypedFormControl(),
    vacationReason:new UntypedFormControl(),
    performerNumber:new UntypedFormControl(),
    article:new UntypedFormControl(),
    isIssued:new UntypedFormControl(),
    firstTime:new UntypedFormControl(),
  });

  public formCaseName: UntypedFormGroup = new UntypedFormGroup({
    caseNameId: new UntypedFormControl(),
    caseNameExternalId: new UntypedFormControl(),
    studPersonId:new UntypedFormControl(),
    rLastName: new UntypedFormControl(),
    rFirstName: new UntypedFormControl(),
    rMiddleName:new UntypedFormControl(),
    dLastName:new UntypedFormControl(),
    dFirstName:new UntypedFormControl(),
    dMiddleName:new UntypedFormControl(),
  });

  public formMilitary: UntypedFormGroup = new UntypedFormGroup({
    militaryExternalId: new UntypedFormControl(),
    studPersonId: new UntypedFormControl(),
    dictMilitaryStatusId: new UntypedFormControl(),
    dictMilitaryDocTypeId: new UntypedFormControl(),
    number: new UntypedFormControl(),
    issueDate: new UntypedFormControl(),
    issueOrganization: new UntypedFormControl(),
    dictMilitaryRankId: new UntypedFormControl(),
    dictMilitaryStockCategoryId: new UntypedFormControl(),
    dictMilitaryCommissariatId: new UntypedFormControl(),
    militarySpecialtyNumber: new UntypedFormControl(),
    issueMilitaryStartDate: new UntypedFormControl(),
  });
}

import { Component, OnInit } from '@angular/core';
import { DictCriterialMark } from 'src/app/models/currentcontrol/dict.model';
import { DictsService } from 'src/app/services/currentcontrol/dicts.service';

@Component({
  selector: 'app-currentsessionlegend',
  templateUrl: './currentsessionlegend.component.html',
  styleUrls: ['./currentsessionlegend.component.scss'],
})
export class CurrentsessionlegendComponent implements OnInit {
  public legendData: DictCriterialMark[] = [];

  constructor(private dict: DictsService) {}

  ngOnInit(): void {
    this.dict.dictCrierialMarks$.subscribe(
      (criterialMarks) => (this.legendData = criterialMarks)
    );
  }
}

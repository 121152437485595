<publications-settings></publications-settings>
<div class="title-container">
  <h1>Публикации</h1>
  <kendo-tabstrip class="custom-tabs" (tabSelect)="onTabChange($event)">
    <kendo-tabstrip-tab *ngIf="myPublicationUserAccess" title="Мои" [selected]="selectedTab === 'Мои'">
      <ng-template kendoTabTitle>
        <span>Мои</span>
        <kendo-badge *ngIf="myPublicationsAlertCount">{{myPublicationsAlertCount}}</kendo-badge>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab *ngIf="allPublicationUserAccessLevel !== AccessRights.No" title="Все" [selected]="selectedTab === 'Все'">
      <ng-template kendoTabTitle>
        <span>Все</span>
        <kendo-badge *ngIf="allPublicationsAlertCount">{{allPublicationsAlertCount}}</kendo-badge>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab *ngIf="myPublicationUserAccess" title="Моя сводная информация">
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<publications-summaryInfo [authorId]="authorId" [isAuthorInfo]="false"  *ngIf="IsSummaryInfo"></publications-summaryInfo>

<div [ngClass]="{userAccess: allPublicationUserAccessLevel !== AccessRights.Write && !myPublicationUserAccess}" class="buttons" *ngIf="!IsSummaryInfo">
  <button *ngIf="myPublicationUserAccess || allPublicationUserAccessLevel === AccessRights.Write" kendoButton (click)="addPublication()" class="k-button k-button-sm k-rounded-md k-button-solid-success k-button-solid ng-star-inserted" icon="plus">
    Добавить публикацию
  </button>
  <div class='form16' *ngIf="(myPublicationUserAccess || allPublicationUserAccessLevel === AccessRights.Write) && selectedTab === 'Мои'">
    <h6>Период</h6>
    <kendo-datepicker [formatPlaceholder]="periodPlaceholder" [(ngModel)]='form16.periodBegin'></kendo-datepicker>
    <h6 class='dash'>—</h6>
    <kendo-datepicker [formatPlaceholder]="periodPlaceholder" [(ngModel)]='form16.periodEnd'></kendo-datepicker>
    <button kendoButton (click)="exportForm16()" class="k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted">
      Сформировать отчет по форме №16
    </button>
  </div>
</div>

<div class="grid-container" *ngIf="!IsSummaryInfo">
  <kendo-grid [data]="gridData"
              [pageable]="pagerSettings"
              [skip]="skip"
              [pageSize]="pageSize"
              (pageChange)="pageChange($event)"
              [filterable]="true"
              [loading]="loading"
              (cellClick)="navigateToSelectedPublication($event)">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column title="№" class="!k-text-center serialNumber" headerClass="gridHeader dictionaries" [width]="70">
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        {{rowIndex+1}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="status"
                       title="Статус"
                       [width]="220"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-column="column">
        <kendo-multiselect
          [(ngModel)]="filters.status"
          [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
          kendoMultiSelectSummaryTag
          [autoClose]="false"
          [checkboxes]="true"
          [listHeight]="230"
          [data]="statuses"
          textField="key"
          [valuePrimitive]="true"
          (valueChange)="onFilterChange($event, column)"
          valueField="value">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>{{ dataItems.length }} выбрано</span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <div class="alignCells leftAlignment"
                [ngClass]="{
                red: dataItem.status === StatusEnum.CHECKING ||
                  dataItem.status === StatusEnum.REVISION,
                green: dataItem.status === StatusEnum.APPROVED
              }">
            {{dataItem.status}}
            <kendo-badge class="publicationBadge" *ngIf="dataItem.numberNotifications > 0">
              {{dataItem.numberNotifications}}
            </kendo-badge>
          </div>
          <div *ngIf="dataItem.status === StatusEnum.DRAFT">
            <span class="k-icon icon-exclamation yellow"></span>
            <span class="yellow notification">
            {{getNotificationText(dataItem)}}
          </span>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="type"
                       title="Тип публикации"
                       [width]="300"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-multiselect [data]="types"
                           textField="name"
                           [checkboxes]="true"
                           [listHeight]="230"
                           kendoMultiSelectSummaryTag
                           [valuePrimitive]="true"
                           [autoClose]="false"
                           [(ngModel)]="filters.type"
                           [kendoDropDownFilter]="{
                              operator: 'contains'
                           }"
                           (valueChange)="onFilterChange($event, column)"
                           valueField="name">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>{{ dataItems.length }} выбрано</span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.type}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="subType"
                       title="Вид публикации"
                       [width]="310"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-column="column">
        <kendo-multiselect [data]="subTypes"
                           [kendoDropDownFilter]="{
                             operator: 'contains'
                           }"
                           kendoMultiSelectSummaryTag
                           [checkboxes]="true"
                           [listHeight]="230"
                           [(ngModel)]="filters.subType"
                           (valueChange)="onFilterChange($event, column)"
                           [autoClose]="false"
                           [valuePrimitive]="true"
                           textField="name"
                           valueField="name">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>{{ dataItems.length }} выбрано</span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.subType}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="authors"
                       title="Авторы"
                       [width]="240"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-textbox [clearButton]="true" [(ngModel)]="filters.authors" (valueChange)="onFilterChange($event, column)"></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment rows" *ngFor="let author of dataItem.authors; let last = last">
          {{author}}{{ !last ? ',' : ''}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="name"
                       title="Название"
                       [width]="400"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-column="column">
        <kendo-textbox [clearButton]="true" [(ngModel)]="filters.name" (valueChange)="onFilterChange($event, column)"></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.name}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="source"
                       title="Источник"
                       [width]="200"
                       *ngIf="showSource"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-column="column">
        <kendo-textbox [clearButton]="true" [(ngModel)]="filters.source" (valueChange)="onFilterChange($event, column)"></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.source}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="code.field" [filterable]="true" headerClass="gridHeader dictionaries"
                       [width]="200" *ngFor="let code of codeColumns"
                       [title]="code.name">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        {{findCodes(dataItem.codes, code.name)}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-textbox [ngModel]="getDynamicFilters(code.field)" (valueChange)="onFilterChange($event, column)"></kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="year"
                       title="Год"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-column="column">
        <kendo-combobox
                [(ngModel)]="filters.year"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                [data]="years"
                [listHeight]="250"
                textField="year"
                [valuePrimitive]="true"
                (valueChange)="onFilterChange($event, column)"
                valueField="year">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.year}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="categories"
                       title="Индексация"
                       [width]="220"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-multiselect [data]="scientometricBases"
                           [kendoDropDownFilter]="{
                             operator: 'contains'
                           }"
                           kendoMultiSelectSummaryTag
                           [checkboxes]="true"
                           [listHeight]="230"
                           [(ngModel)]="filters.categories"
                           (valueChange)="onFilterChange($event, column)"
                           [autoClose]="false"
                           [valuePrimitive]="true"
                           textField="name"
                           valueField="name">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>{{ dataItems.length }} выбрано</span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.categories.join(", ")}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="department"
                       title="Структурное подразделение"
                       [width]="240"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-column="column">
        <kendo-multiselect
                [(ngModel)]="filters.departments"
                [data]="subdivisions"
                [kendoDropDownFilter]="{
                  operator: 'contains'
                }"
                kendoMultiSelectSummaryTag
                [checkboxes]="true"
                [listHeight]="230"
                textField="name"
                [autoClose]="false"
                [valuePrimitive]="true"
                (valueChange)="onFilterChange($event, column)"
                valueField="name">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>{{ dataItems.length }} выбрано</span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment rows" *ngFor="let department of dataItem.departments; let last = last">
          {{department}}{{ !last ? ',' : ''}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="fileId"
                       title="Заключение о возможности открытой публ."
                       [width]="170"
                       headerClass="gridHeader dictionaries"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="alignCells centerAlignment">
          <input *ngIf="!dataItem.fileId"
                 #fileSelect
                 [ngClass]="{fileselect: (selectedTab === 'Все' && allPublicationUserAccessLevel === AccessRights.Write) || (selectedTab === 'Мои' && myPublicationUserAccess)}"
                 class="fileSelect"
                 title=" "
                 accept="{{fileUploadRestrictions.extensions}}"
                 [disabled]="!(allPublicationUserAccessLevel !== AccessRights.Write || myPublicationUserAccess)"
                 (change)="onFileChange($event, dataItem)"
                 type="file">
          <button *ngIf="!dataItem.fileId" class="k-icon k-icon-lg k-i-upload" (click)="fileSelect()">
          </button>
          <span *ngIf="dataItem.fileId"
                class="k-icon k-icon-lg k-i-download fileselect"
                (click)="getPublicationFile(dataItem)">
          </span>
          <span (click)="onDeletePublicationFile(dataItem)" *ngIf="dataItem.fileId" class="k-icon icon-removeFile"></span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="addedBy"
                       title="Кто добавил"
                       [width]="240"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-textbox [clearButton]="true" [(ngModel)]="filters.addedBy" (valueChange)="onFilterChange($event, column)"></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{dataItem.addedBy}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="createdAt"
                       title="Дата добавления"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <span class="dateFilter">
          <kendo-datepicker [(ngModel)]="filters.createdAt"
                            (valueChange)="onFilterChange($event, column)">
          </kendo-datepicker>
          <span class="k-clear-value">
            <span (click)="clearDate(column)" *ngIf="filters.createdAt" class="k-icon k-i-x"></span>
          </span>
        </span>

      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{DateToString(dataItem.createdAt, "dd.MM.yyyy HH:mm:ss")}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="addedBy"
                       title="Кто отредактировал"
                       [width]="335"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate></ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells leftAlignment">
                            {{
                              dataItem.updatedBy + ', ' +
                                DateToString(dataItem.updatedAt, "dd.MM.yyyy HH:mm:ss")
                            }}
                          </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

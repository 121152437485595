import {Component, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AddEvent, EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { MiddleControlUserAccess } from 'src/app/models/useraccess/middlecontrol/middlecontroluseraccess.model';
import { FacultyService } from 'src/app/services/education/Faculty/faculty.service';
import { KafedraService } from 'src/app/services/education/Kafedra/kafedra.service';
import { MiddleControlUserAccessService } from 'src/app/services/useraccess/middle-control-user-access.service';
import {DictsService} from "../../../services/middlecontrol/dicts.service";
import {PersonsModel} from "../../../models/middlecontrol/persons.model";
import {TrainingLevelsModel} from "../../../models/middlecontrol/trainingLevels.model";
import {StudyFormModel} from "../../../models/middlecontrol/studyform.model";
import {FacultyModel} from "../../../models/middlecontrol/faculty.model";
import {KafedraModel} from "../../../models/middlecontrol/kafedra.model";
import {arrayRewrite, DisplayValuesInEditMode, valueChange, valueChangeBySourceName} from "../../../helpers/multiselect-helper";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {SortDescriptor} from "@progress/kendo-data-query";
import {isChildOf} from "../../../helpers/elementRef-helper";
import {GridDisplayList} from "../../../helpers/grid-helper";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {getData$} from "../../../../environments/environment";
import {DisplaySNameForDuplicateKafedras} from "../../../helpers/array-helper";
import {KafedraFields} from "../../../models/useraccess/enums/kafedra.enum";
import {DictFilial} from "../../../models/middlecontrol/dictfilial.model";

@Component({
  selector: 'app-middle-control-access',
  templateUrl: './middle-control-access.component.html',
  styleUrls: ['./middle-control-access.component.scss']
})
export class MiddleControlAccessComponent implements OnInit {
  public isAdmin: boolean;
  public virtual: any = {
    itemHeight: 28,
  };

  public studyForms: StudyFormModel[] = [];
  public trainingLevels: TrainingLevelsModel[] = [];
  public faculties: FacultyModel[] = [];
  public allFaculties: FacultyModel[] = [];
  public kafedras: KafedraModel[] = [];
  public allKafedras: KafedraModel[] = [];
  public persons: PersonsModel[] = [];
  public accesses: MiddleControlUserAccess[] = [];
  public filials: DictFilial[] = [];
  public access: MiddleControlUserAccess = {
    id: '',
    personId: '',
    personName: '',
    trainingLevels: [],
    studyForms: [],
    faculties: [],
    filials: [],
    kafedras: [],
    accessLevel: 0,
    accessLevelName: '',
    sessionDatesAccessLevel: 0,
    sessionDatesAccessLevelName: '',
    studentSessionAccessLevel: 0,
    studentSessionAccessLevelName: '',
    sheetApproveAccessLevel: false,
    userAccessLevel: false,
    localSignatoryAccessLevel: false,
    dictAccessLevel: false
  }

  public trainingLevelsEdit: any[] = [];
  public facultiesEdit: any[] = [];
  public studyFormsEdit: any[] = [];
  public kafedrasEdit: any[] = [];
  public filialsEdit: (string|undefined)[] = [];

  public dataSources: any[] = ['trainingLevels', 'faculties', 'kafedras', 'studyForms', 'filials'];

  public filteredData: any = {};

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]

  public dropdownOptions: any[] = [
    {id: 1, text: 'Чтение' },
    {id: 2, text: 'Запись' }
  ]

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;
  public sort: SortDescriptor[] = [{ field: "personName", dir: "asc" }];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;
  public loading = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: any[] = [];

  constructor(private renderer: Renderer2,
              private dialogService: DialogService,
              private kafedraService: KafedraService,
              private facultyService: FacultyService,
              private notificationService: NotificationsService,
              private userAccessService: MiddleControlUserAccessService,
              private dictService: DictsService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  ngOnInit(): void {
    this.getPersons();
    getData$.subscribe(() => { this.getAccess();})
    this.getAllFaculties();
    this.getAllKafedras();
    this.getAllStudyForm();
    this.getTrainingLevels();
    this.getAllFilials();
  }

  // Get persons
  public getPersons() {
    this.dictService.getPersons("")
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  // Get access
  public getAccess() {
    this.loading = true;
    this.userAccessService.getAccess()
      .subscribe(
        response => {
          this.loading = false;
          this.accesses = response;
          if (this.accesses) {
            for (let i = 0; i < this.accesses.length; i++) {
              valueChangeBySourceName(this.accesses[i].trainingLevels,
                "trainingLevelId", null, "");
              valueChangeBySourceName(this.accesses[i].trainingLevels,
                "trainingLevelName", null, "Все");
              valueChangeBySourceName(this.accesses[i].studyForms,
                "studyFormId", null, "");
              valueChangeBySourceName(this.accesses[i].studyForms,
                "studyFormName", null, "Все");
              valueChangeBySourceName(this.accesses[i].faculties,
                "facultyId",  null, "");
              valueChangeBySourceName(this.accesses[i].faculties,
                "facultyShortName",  null, "Все");
              valueChangeBySourceName(this.accesses[i].kafedras,
                "kafedraId",  null, "");
              valueChangeBySourceName(this.accesses[i].kafedras,
                "kafedraName",  null, "Все");
              valueChangeBySourceName(this.accesses[i].filials,
                "filialId", null, "");
              valueChangeBySourceName(this.accesses[i].filials,
                "filialSName", null, "Все");
              DisplaySNameForDuplicateKafedras(this.accesses[i].kafedras, KafedraFields.kafedraName, KafedraFields.facultySName);
            }
          }
          this.gridData = this.accesses;
        }, error => {
          this.loading = false;
          }
      );
  }

  getList(dataItem: any, key: any) {
    return GridDisplayList(dataItem, key);
  }

  // Faculties
  public getAllFaculties() {
    this.dictService.getFaculty()
    .subscribe(
      response => {
        this.faculties = response;
        if (this.faculties) {
          let item = new FacultyModel();
          item.id = "";
          item.name = 'Все';
          item.shortName = 'Все';
          item.filialId = '';
          this.faculties.unshift(item);
        }
        this.allFaculties = this.faculties.slice();
      }
    );
  }

  // Kafedra
  public getAllKafedras() {
    this.dictService.getKafedra()
    .subscribe(
      response => {
        this.kafedras = DisplaySNameForDuplicateKafedras(response, KafedraFields.kafedraName, KafedraFields.facultySName);
        if (this.kafedras) {
          let item = new KafedraModel();
          item.id = "";
          item.kafedraName = 'Все';
          item.filialId = '';
          this.kafedras.unshift(item);
        }
        this.allKafedras = this.kafedras.slice()
      }
    );
  }

  //DictStudyForm
  public getAllStudyForm() {
    this.dictService.getStudyForms()
      .subscribe(
        response => {
          this.studyForms = response;
          if (this.studyForms) {
            let item = new StudyFormModel();
            item.id = "";
            item.name = 'Все';
            this.studyForms.unshift(item);
          }
        }
      );
  }

  //DictTrainingLevel
  public getTrainingLevels() {
    this.dictService.getTrainingLevels()
      .subscribe(
        response => {
          this.trainingLevels = response;
          if (this.trainingLevels) {
            let item = new TrainingLevelsModel();
            item.id = "";
            item.name = 'Все';
            this.trainingLevels.unshift(item);
          }
        }
      );
  }

  public getAllFilials() {
    this.dictService.getFilial()
      .subscribe(
        response => {
          this.filials = response;
          if (this.filials) {
            let item = new DictFilial();
            item.id = "";
            item.filialSName = 'Все';
            this.filials.unshift(item);
          }
        }
      );
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if(this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }

      for(let i=0; i < this.dataSources.length; i++) {
        valueChange(this.formGroup.value[this.dataSources[i]], "", null);
      }

      this.userAccessService.saveAccess(this.formGroup.value)
      .subscribe(
        response => {
          this.getAccess();
          this.notificationService.showSuccess("Добавлено");
        },
        error => {
          this.notificationService.showError("Не удалось добавить запись");
        }
      );
    }
    this.closeEditor(this.grid);
  }

  //Start Editing
  public editHandler({sender, rowIndex, dataItem}: EditEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      //this.saveCurrent();
      return;
    }

    const targetKey: any[] = ['trainingLevelId', 'facultyId','kafedraId', 'studyFormId', 'filialId'];
    // Вывод данных в редактирование
    for(let i = 0; i < this.dataSources.length; i++) {
      this.filteredData[this.dataSources[i]] = (this as any)[this.dataSources[i]];
      (this as any)[`${this.dataSources[i]}Edit`] =
          DisplayValuesInEditMode((dataItem[this.dataSources[i]]), [targetKey[i]]);
    }

    this.isLine = true;
    //this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.filialFilter(this.filialsEdit);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.trainingLevelsEdit = [];
    this.facultiesEdit = [];
    this.studyFormsEdit = [];
    this.kafedrasEdit = [];
    this.filialsEdit = [];

    this.formGroup = formGroup({
      id: 0,
      personId: '',
      trainingLevels: '',
      faculties: '',
      studyForms: '',
      kafedras: '',
      filials: '',
      accessLevel: 1,
      sessionDatesAccessLevel: 1,
      studentSessionAccessLevel: 1,
      sheetApproveAccessLevel: false,
      dictAccessLevel: false,
      userAccessLevel: false,
      localSignatoryAccessLevel: false
    });

    this.isLine = true;
    this.isNew = true;
    this.filialFilterClean();
    sender.addRow(this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${dataItem.personName} из настроек доступа`);
    this.opened = dialogResultHelper(dialog.result, this.userAccessService.deleteAccess(dataItem.id), this.notificationService);
  }

  public clearData() {
    this.gridData = this.accesses;
    this.searchFIO = ''
  }

  public filterData(dataItem: any) {
    this.gridData = this.accesses.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  public valueChange(value: any, editItem: any) {
    (this as any)[`${editItem}`] = arrayRewrite(value);

    if(editItem == 'filialsEdit'){
      this.filialFilter(this.filialsEdit);
    }
  }

  public filialFilter(value: (string | undefined)[]) {

    if(value.includes('') || value.length === 0){
      this.filialFilterClean();
      return;
    }

    this.faculties = [...this.allFaculties.filter(x => x.filialId == '' || value.includes(x.filialId))]
    this.kafedras = [...this.allKafedras.filter(x => x.filialId == '' || value.includes(x.filialId)
      || this.faculties.map(x => x.id).includes(x.facultyId))]
  }

  public filialFilterClean() {
    this.faculties = [...this.allFaculties.slice()]
    this.kafedras = [...this.allKafedras.slice()]
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    let element = (event as any).target;
    this.dataSources.forEach((item) => {
      if(isChildOf(element, item)) {
        this.filteredData[item] = (this as any)[item];
      }
    })
  }

  public onTagClick(items: any, sourceName: string) {
    this.filteredData[sourceName] = items;
    console.log(this.filteredData);
  }

}

const formGroup = (dataItem:
  {
    id?: any;
    personId?: any;
    trainingLevels?: any;
    faculties?: any;
    studyForms?: any;
    kafedras?: any;
    filials?: any;
    accessLevel?: any;
    dictAccessLevel?: boolean;
    sessionDatesAccessLevel?: any;
    studentSessionAccessLevel?: any;
    sheetApproveAccessLevel?: boolean;
    userAccessLevel?: boolean;
    localSignatoryAccessLevel?: boolean;
}) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    trainingLevels: new FormControl(dataItem.trainingLevels, Validators.required),
    studyForms: new FormControl(dataItem.studyForms, Validators.required),
    faculties: new FormControl(dataItem.faculties, Validators.required),
    kafedras: new FormControl(dataItem.kafedras),
    filials: new FormControl(dataItem.filials, Validators.required),
    accessLevel: new FormControl(dataItem.accessLevel, Validators.required),
    dictAccessLevel: new FormControl(dataItem.dictAccessLevel, Validators.required),
    sessionDatesAccessLevel: new FormControl(dataItem.sessionDatesAccessLevel, Validators.required),
    studentSessionAccessLevel: new FormControl(dataItem.studentSessionAccessLevel, Validators.required),
    sheetApproveAccessLevel: new FormControl(dataItem.sheetApproveAccessLevel, Validators.required),
    userAccessLevel: new FormControl(dataItem.userAccessLevel, Validators.required),
    localSignatoryAccessLevel: new FormControl(dataItem.localSignatoryAccessLevel, Validators.required)
  });

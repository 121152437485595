<kendo-stacklayout [gap]="25" orientation="vertical" class="retake_edit">
  <h2>История оценок</h2>
  <kendo-grid
    [data]="dataGrid"
    class="retake_edit__table"
    [loading]="isLoading"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="retake_edit__loader">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <kendo-grid-column
      title="Дата занесения"
      field="createdAt"
      [width]="110"
      [style]="{
        'text-align': 'center',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center',
        'white-space': 'normal'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="retake_edit__cell">
          {{ dataItem.createdAt }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      title="Дата окончания действия"
      field="expiresDate"
      [width]="110"
      [style]="{
        'text-align': 'center',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center',
        'white-space': 'normal'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="retake_edit__cell">
          {{ dataItem.updatedAt }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      title="Дата оценки"
      field="ratingDate"
      [width]="110"
      [style]="{
        'text-align': 'center',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center',
        'white-space': 'normal'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="retake_edit__cell">
          {{ dataItem.markDate }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      title="Сотрудник"
      field="employee"
      [width]="230"
      [style]="{
        'text-align': 'left',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="retake_edit__cell">
          {{ dataItem.fio }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      title="Оценка"
      field="rating"
      [width]="150"
      [style]="{
        'text-align': 'left',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="retake_edit__cell">
          {{ dataItem.markName }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      title="Причина смены оценки"
      field="cause"
      [width]="200"
      [style]="{
        'text-align': 'left',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center',
        'white-space': 'normal'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="retake_edit__cell">
          {{ dataItem.markChangeReasonName }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="enableRating"
      [width]="160"
      [style]="{
        'text-align': 'center',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center'
      }"
    >
      <ng-template kendoGridHeaderTemplate>
        <div class="retake_edit__header_cell">
          <span>Учитывать оценку</span>
          <input
            class="retake_edit__checkbox"
            type="checkbox"
            kendoCheckBox
            [checked]="checkedAll"
            (change)="onToggleCheckAll($event)"
          />
        </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input
          class="retake_edit__checkbox"
          type="checkbox"
          disabled="{{ rowIndex === dataGrid.length - 1 }}"
          kendoCheckBox
          [(ngModel)]="dataItem.isVisible"
          (ngModelChange)="isCheckedAll()"
        />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      title="Оценка получена в срок"
      field="enableRating"
      [width]="200"
      [style]="{
        'text-align': 'center',
        padding: '8px 10px'
      }"
      [headerStyle]="{
        padding: '16px 10px',
        'text-align': 'center',
        'white-space': 'normal'
      }"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-dropdownlist
          class="params__input"
          [data]="['Да', 'Нет']"
          [value]="dataItem.isInTime ? 'Да' : 'Нет'"
          (selectionChange)="onChangeDropdownList($event, dataItem.id)"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div class="retake_edit__buttons">
    <button kendoButton (click)="onGoBack()" class="button button--action">
      отмена
    </button>
    <button
      kendoButton
      (click)="onSave()"
      themeColor="primary"
      class="button button--action"
      [disabled]="!dataGrid.length"
    >
      сохранить
    </button>
  </div>
</kendo-stacklayout>

<kendo-dropdownlist
  [data]="data"
  (valueChange)="onChange($event)"
  [defaultItem]="defaultItem"
  [value]="selectedValue"
  [valuePrimitive]="true"
  [virtual]="useVirtual ? filterVirtualization : false"
  [textField]="textField"
  [valueField]="valueField">
</kendo-dropdownlist>

<education-header></education-header>
<h1>Группы ограничений стандарта</h1>

<div class="grid-container">
  <kendo-grid [data]="dictGroups" (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: dictGroups ? dictGroups.length < 9 : false}"
              [loading]="loading"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()" style="margin-left: 20px">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="groupStandardLimitationName" title="Название группы ограничений"
                       [width]="600">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getDictGroupById(dataItem.dictGroupStandardLimitationExternalId)?.groupStandardLimitationName }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="dictTrainingLevelId" title="Уровень подготовки"
                       [width]="170">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" *ngIf="dataItem.dictTrainingLevelId !== null">
          {{ getTrainingLevelById(dataItem.dictTrainingLevelId)?.trainingLevelName }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="trainingLevels"
                            [defaultItem]="defaultTrainingLevel"
                            textField="trainingLevelName"
                            valueField="trainingLevelExternalId"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('dictTrainingLevelId')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" field="dictStandardTypeId" title="Тип стандарта"
                       [width]="170">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" *ngIf="dataItem.dictStandardTypeId !== null">
          {{ getStandardTypeById(dataItem.dictStandardTypeId)?.standardTypeShortName }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="standardTypes"
                            [defaultItem]="defaultStandardType"
                            textField="standardTypeShortName"
                            valueField="standardTypeExternalId"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('dictStandardTypeId')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" title="" [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <button kendoGridRemoveCommand
                themeColor="secondary"
                icon="delete"
                fillMode="flat"
                size="none" class="custom-size"
                rounded="full" style="text-overflow: unset;">
        </button>
      </ng-template>
    </kendo-grid-column>


  </kendo-grid>

</div>

<div kendoDialogContainer></div>
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {GivenBlanksModel, DataModel, DataReportModel} from '../../models/diplomablanks/given-blanks.model';

@Injectable({
    providedIn: 'root'
})

export class GivenBlanksService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.blank}`;

  constructor(private http: HttpClient) {}

  public getIssueBlanks(data: DataModel) {
    return this.http.post<GivenBlanksModel[]>(this.baseUrl + '/getIssueBlanks', data);
  }

  public returnBlanks(data: number[]) {
    return this.http.put(this.baseUrl + '/return', data);
  }

  public report(data: DataReportModel) {
    return this.http.post(this.baseUrl + '/exportIssuedBlanks ', data, {responseType: 'blob', observe: 'response'});
  }

  public changeStatus(externalId: string, dictBlankStatusId: string) {
    const data = {externalId: externalId, dictBlankStatusId: dictBlankStatusId};
    return this.http.put(this.baseUrl + '/ChangeStatus', data);
  }
}
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {AllowedProperty, PublicationProperty, Property} from "../../../models/publications/editForm/property.model";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {KendoProperties} from "../../../models/publications/editForm/editForm.enums";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {isArray, isBoolean, isDate, isNumber, isString} from "lodash";
import {catchError} from "rxjs/operators";
import {handleError} from "../../../helpers/publications/errorHandle-helper";
import {PublicationsFileService} from "./file.service";
import {NumericStatusEnum} from "../../../models/publications/statuses.model";
import {PublicationForm} from "../../../models/publications/editForm/publication.model";

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.publications.main}`;

  constructor(
    private http: HttpClient,
    private fileService: PublicationsFileService
  ) { }

  public getAllowedProperties(query: { typeId: string, categoryId: number, subTypeId?: string }): Observable<AllowedProperty[]> {
    const filterSubTypeId = query.subTypeId ? `&SubTypeId=${query.subTypeId}` : '';
    return this.http.get<AllowedProperty[]>(`${this.baseUrl}/AllowedProperties/?TypeId=${query.typeId}&CategoryId=${query.categoryId}${filterSubTypeId}`);
  }

  public getProperties(query: { publicationId: string, categoryId: number}): Observable<Property[]> {
    return this.http.get<Property[]>(`${this.baseUrl}/Property/?PublicationId=${query.publicationId}&CategoryId=${query.categoryId}`);
  }

  public saveProperties(body: PublicationProperty): Observable<string> {
    return this.http.put<string>(`${this.baseUrl}/Property`, this.getFormData(body))
      .pipe(catchError(handleError));
  }

  private getFormData(body: PublicationProperty) {
    const data = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((item, i) => {
          if (isString(item)) {
            data.append(`${key}[${i}]`, item);
          } else {
            Object.entries<string | File>(item).forEach(([itemKey, itemValue]) => {
              if (itemValue instanceof File) {
                data.append(`files[${i}].file`, itemValue, itemValue.name);
              } else {
                data.append(`${key}[${i}].${itemKey}`, itemValue);
              }
            });
          }
        });
      } else {
        data.append(key, value);
      }
    });
    return data;
  }

  public async convertFormGroupValuesForView(
    formArray: FormArray,
    item: AllowedProperty,
    index: number,
    currentProperty?: Property,
    publicationId?: string
  ) {
    const propertyValue = currentProperty?.value ?? null;
    switch (item.type.enum) {
      case KendoProperties.Number:
      case KendoProperties.Float:
        formArray.at(index).get('value')?.patchValue(propertyValue ?  Number(propertyValue) : null);
        break;
      case KendoProperties.Date:
        formArray.at(index).get('value')?.patchValue(DateFromUTCAsLocal(propertyValue));
        break;
      case KendoProperties.Boolean:
        formArray.at(index).get('value')?.patchValue(propertyValue === 'true');
        break;
      case KendoProperties.Table:
        const value: any[] = propertyValue ? JSON.parse(propertyValue) : [];
        const rows = value.map(item => {
          const row: {[key: string]: string} = {};
          item.forEach((property: Property) => row[property.id] = property.value!);
          return row;
        });
        formArray.at(index).get('value')?.patchValue(rows);
        break;
      case KendoProperties.File:
        this.fileService.getPropertyFileList(publicationId!, item.id).subscribe(value => {
          formArray.at(index).get('value')?.patchValue(value.map(file =>
            ({id: file.id, name: file.name, size: file.length})));
        });
        break;
      default:
        formArray.at(index).get('value')?.patchValue(propertyValue);
    }
    if (currentProperty?.languageCode) {
      formArray.at(index).get('languageCode')?.patchValue(currentProperty.languageCode);
    }
  }

  public convertFormGroupValuesForRequest(request: FormGroup) {
    const formGroup: FormGroup = request;
    formGroup.value.properties = formGroup.value.properties.map((item: any) => ({id: item.id, value: item.value, languageCode: item.languageCode}));

    formGroup.value.properties.forEach((item: any) => {
      if (!(item.value || isBoolean(item.value) || item.value === '')) {
        formGroup.value.properties = formGroup.value.properties.filter((value: any) => value !== item);
        return;
      }
      if (isNumber(item.value) || isBoolean(item.value)) {
        item.value = String(item.value);
      }
      if (isDate(item.value)) {
        item.value = TimeZoneFix(item.value);
      }
      if (isArray(item.value)) {
        if (item.value.length > 0 && (item.value[0].id || item.value[0] instanceof File)) {
          item.value.forEach((file: any) => {
            if (file instanceof File) {
              formGroup.value.files.push({propertyId: item.id, file: file});
            }
          });
          item.value = null;
        } else {
          const rows: any[] = [];
          item.value.forEach((row: any) => {
            const value: Property[] = [];
            Object.keys(row).forEach(id => {
              value.push({id: id, value: row[id]});
            });
            rows.push(value);
          });
          item.value = JSON.stringify(rows);
        }
      }
    });

    return formGroup;
  }

  public patchDynamicValues(item: any, disabled: boolean, isLast?: boolean, isNewLanguage?: boolean) {
    const form = new FormGroup({
      id: new FormControl(item.id),
      name: new FormControl(item.name),
      required: new FormControl(item.required),
      allowModeratorOnly: new FormControl(item.allowModeratorOnly),
      tooltip: new FormControl(item.toolTip),
      data: new FormControl(item.data),
      languageData: new FormControl(item.languageData),
      multipleByLanguage: new FormControl(item.multipleByLanguage),
      type: new FormGroup({
        id: new FormControl(item.type.id),
        enum: new FormControl(item.type?.enum),
        name: new FormControl(item.type.name)
      }),
      languageCode: new FormControl({value: !item.multipleByLanguage || isNewLanguage ? '' : item.letterCode ?? 'Русский', disabled: disabled}),
      childProperties: new FormArray([]),
      value: new FormControl<any>(
        {value: item.value ?? '', disabled: disabled && item.type?.enum !== KendoProperties.Textarea},
        item.required ? Validators.required : null),
      isLast: new FormControl(isLast)
    });

    if (item.childProperties) {
      const childProperties = <FormArray> form.get('childProperties');
      item.childProperties.forEach((child: any) => {
        const form = this.patchDynamicValues(child, disabled);
        form.setControl('value', new FormControl<any>(child.value));
        childProperties.push(form);
      });
    }
    return form;
  }

  public disableDynamicProperty(currentPublication: PublicationForm, isModerator: boolean, isModeratorProperty: boolean): boolean {
    return currentPublication?.currentStatus === NumericStatusEnum.APPROVED
      || currentPublication?.currentStatus === NumericStatusEnum.ARCHIVE
      || (currentPublication?.currentStatus !== NumericStatusEnum.DRAFT
        && currentPublication?.currentStatus !== NumericStatusEnum.REVISION
        && !isModerator)
      || (!isModerator && isModeratorProperty)
  }

  public disableStaticProperty(currentPublication: PublicationForm, isModerator: boolean): boolean {
    return currentPublication?.currentStatus === NumericStatusEnum.APPROVED
      || currentPublication?.currentStatus === NumericStatusEnum.ARCHIVE
      || (currentPublication?.currentStatus !== NumericStatusEnum.DRAFT
        && currentPublication?.currentStatus !== NumericStatusEnum.REVISION
        && !isModerator)
  }

}

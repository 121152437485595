import {Injectable} from "@angular/core";
import {Comment, CommentRequest} from "../../models/mfc/applicationForm/comment.model";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NotificationsService} from "../notifications/notifications.service";
import {catchError} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.applicationComments}/`;

  constructor(private http: HttpClient, private notificationsService: NotificationsService) {}

  getComments(applicationId: string) {
    return this.http.get<Comment[]>(`${this.baseUrl}${applicationId}`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  getCommentFile(commentId: string, commentFileId: string) {
    return this.http.get(`${this.baseUrl}${commentId}/${commentFileId}`, {responseType: 'blob'})
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  addComment(comment: FormData) {
    return this.http.post<CommentRequest>(this.baseUrl, comment)
      .pipe(
        tap(() => this.notificationsService.showSuccess('Успешно')),
        catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  deleteComment(commentId: string) {
    return this.http.delete<CommentRequest>(`${this.baseUrl}${commentId}`)
      .pipe(
        tap(() => this.notificationsService.showSuccess('Успешно')),
        catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }
}
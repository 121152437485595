import { Component, OnInit} from '@angular/core';
import { SearchPosts } from 'src/app/models/announcement/search-persons.model';
import { SearchDepatments } from 'src/app/models/announcement/search-persons.model';
import { FoundPersons } from 'src/app/models/announcement/search-persons.model';
import { SearchPersonsService } from 'src/app/services/announcement/search-persons.service';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';

@Component({
    selector: 'app-search-persons',
    templateUrl: './search-persons.component.html',
    styleUrls: ['./search-persons.component.scss']
})

export class SearchPersonsComponent implements OnInit{
    
    initials: string = "";

    public clearfilde(){
        this.initials = "";
     }
    
    public posts: SearchPosts[] = [];
    public departments: SearchDepatments[] = [];
    
    arrayOfPosts: any = [];
    arrayOfDepartments: any = [];

    constructor(
        private searchService: SearchPersonsService,
    ) {    }

    public filterSettings: DropDownFilterSettings = {
      caseSensitive: false,
      operator: "contains",
    };
    
// Выбор всех профессий
    public isCheckedMultiSelectPosts = false;

    public get isIndetPosts() {
      if(this.arrayOfPosts != undefined)
      return (
        this.arrayOfPosts.length !== 0 && this.arrayOfPosts.length !== this.posts.length
      );
      else return false
    }

    public onClickPosts() {
      this.isCheckedMultiSelectPosts = !this.isCheckedMultiSelectPosts;
      this.arrayOfPosts = this.isCheckedMultiSelectPosts ? Array.from(this.posts, x=>x.id!) : undefined;
    }

    public get toggleAllTextPosts() {
      return this.isCheckedMultiSelectPosts ? "Снять выбор" : "Выбрать все";
    }

    public getPosts() {
        this.searchService.getPosts()
          .subscribe(
            response => {
             this.posts = response;
            }
          );
      }
// Выбор всех Отделов
    public isCheckedMultiSelectDepartments = false;

    public get isIndetDepartments() {
      if(this.arrayOfDepartments != undefined)
      return (
        this.arrayOfDepartments.length !== 0 && this.arrayOfDepartments.length !== this.departments.length
      );
      else return false
    }

    public onClickDepartments() {
      this.isCheckedMultiSelectDepartments = !this.isCheckedMultiSelectDepartments;
      this.arrayOfDepartments = this.isCheckedMultiSelectDepartments? Array.from(this.departments, x=>x.id!) : undefined;
    }

    public get toggleAllTextDepartments() {
      return this.isCheckedMultiSelectPosts ? "Снять выбор" : "Выбрать все";
    }

    public getDepartments() {
        this.searchService.getDepartments()
          .subscribe(
            response => {
             this.departments = response;
            }
          );
    }

    users: {} = [];

    public FindPersons(){
        this.searchService.getPersons(this.initials, this.arrayOfPosts, this.arrayOfDepartments)
          .subscribe({next:(data: any) => {
          // this.users = data.result;
      //      console.log(data);
        }});
    }

    ngOnInit() {
        this.getPosts();
        this.getDepartments();
    }
}
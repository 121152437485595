export class DictFormDesignerDataType {
  constructor();
  constructor(value: string, name: string);
  constructor(value?: string, name?: string){
      this.dataType = value ?? "";
      this.dictFormDesignerDataTypeName = name ?? "";
    }
    //public dictFormDesignerDataTypeId!: number;
    //public dictFormDesignerDataTypeExternalId!: string;
    //public dictFormDesignerDataTypeName!: string
    public dataType!: string
    public dictFormDesignerDataTypeName!: string
}

<dicts-header></dicts-header>
<h1>Настройки списка подписантов</h1>

<div class="grid-container">
  <kendo-grid [kendoGridBinding]="signatories"
              (remove)="removeHandler($event)"
              (cancel)="cancelHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              (save)="saveHandler($event)"
              [loading]="loading"
              [pageable]="{
                buttonCount: buttonCount,
                info: info,
                type: type,
                pageSizes: pageSizes,
                previousNext: previousNext,
                position: positionPager
              }"
              [pageSize]="pageSizes[0]"
              [sortable]="true"
              [filterable]="true"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand
              themeColor="success"
              icon="plus"
              *ngIf="!isInEditingMode"
      >Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="btnCancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>
    <!--kendo-grid-column title="№ п/п"
                       headerClass="gridHeader dictionaries"
                       [width]="100"
    >
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        <span class="alignCells">
          {{rowIndex + 1}}
        </span>
      </ng-template>
    </kendo-grid-column-->
    <kendo-grid-column field="postName" title="Должность"
                       headerClass="gridHeader dictionaries"
                       [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.postName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="postSName" title="Сокращенное название должности"
                       headerClass="gridHeader dictionaries"
                       [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.postSName}}
        </span>
      </ng-template>
    </kendo-grid-column >
    <kendo-grid-column field="printPostName" title="Печатное название должности"
                       headerClass="gridHeader dictionaries"
                       [width]="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.printPostName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="person.fio" title="ФИО"
                       headerClass="gridHeader dictionaries"
                       [width]="350">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains" >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ dataItem.person?.fio ?? '' }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-combobox textField="fio" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="persons"
                        [formControl]="formGroup.get('person')"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="department.name" title="Структурное подразделение"
                       headerClass="gridHeader dictionaries"
                       [width]="350">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ dataItem.department?.name ?? '' }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox textField="name" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="departments"
                        [formControl]="formGroup.get('department')"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="signatoryRole.id" title="Категория"
                       headerClass="gridHeader dictionaries"
                       [width]="250">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown #fdSignatoryRole
                         [filter]="filter"
                         [data]="dictSignatoryRoles"
                         textField="name" valueField="id" gridField="signatoryRole.id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ dataItem.signatoryRole?.name ?? '' }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox textField="name" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictSignatoryRoles"
                        [formControl]="formGroup.get('signatoryRole')"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="personStatus.id" title="Статус"
                       headerClass="gridHeader dictionaries"
                       [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter" [data]="dictPersonStatuses"
                         textField="name" valueField="id" gridField="personStatus.id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment" [style]="personStatusColor(dataItem.personStatus?.name)">
          {{ dataItem.personStatus?.name ?? '' }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox textField="name" valueField="id"
                        [kendoDropDownFilter]="filterSettings"
                        [data]="dictPersonStatuses"
                        [formControl]="formGroup.get('personStatus')"
        ></kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vacationStart" title="Дата начала отпуска"
                       headerClass="gridHeader dictionaries"
                       [width]="200"
                       filter="date" format="{0:d}"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.vacationStart ? (dataItem.vacationStart | date: 'dd.MM.yyyy') : '' }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datepicker [formControl]="formGroup.get('vacationStart')"
                          [disabled]="enableWriteVrio(formGroup.value?.personStatus?.name)"
        ></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vacationEnd" title="Дата окончания отпуска"
                       headerClass="gridHeader dictionaries"
                       [width]="200"
                       filter="date" format="{0:d}"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.vacationEnd ? (dataItem.vacationEnd | date: 'dd.MM.yyyy') : '' }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datepicker [formControl]="formGroup.get('vacationEnd')"
                          [disabled]="enableWriteVrio(formGroup.value?.personStatus?.name)"
        ></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="interimSignatoryId" title="ВРИО"
                       headerClass="gridHeader dictionaries"
                       [width]="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ nameSignatory(dataItem.interimSignatoryId) }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-dropdownlist [data]="workingSignatories"
                            [disabled]="enableWriteVrio(formGroup.value?.personStatus?.name)"
                            textField="person.fio"
                            valueField="id"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('interimSignatoryId')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="interimPostName" title="Должность ВРИО"
                       headerClass="gridHeader dictionaries"
                       [width]="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column"
                                       [filter]="filter"
                                       [showOperators]="false"
                                       operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.interimPostName }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox [disabled]="enableWriteVrio(formGroup.value?.personStatus?.name)"
                       [formControl]="formGroup.get('interimPostName')"
        ></kendo-textbox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" [width]="60" *ngIf="editable"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

import {Injectable} from "@angular/core";
import {PublicationsPostService} from "../../../../../services/science/publications/post.service";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {FormGroup} from "@angular/forms";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {Observable} from "rxjs";
import {getData$} from "../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PublicationsPostsMethods {
    constructor(private postService: PublicationsPostService,
                private notificationService: NotificationsService) {
    }

    async savePost(isNew: boolean, formGroup: FormGroup) {
        if (isNew) {
            this.postService.addPost(formGroup.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: () => this.notificationService.showError('Произошла ошибка')
            })
        }
        else {
            this.postService.updatePost(formGroup.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: () => this.notificationService.showError('Произошла ошибка')
            })
        }
    }

    removePost(dialogResult: Observable<DialogResult>, dataItem: any) {
        dialogResult.subscribe((result) => {
            if (result instanceof DialogCloseResult) {}
            else {
                if (result.text == "Да") {
                    this.postService.removePost(dataItem.id)
                        .subscribe({
                            next: () => {
                                getData$.next(true);
                                this.notificationService.showSuccess('Успешно');
                            },
                            error: () => this.notificationService.showError('Произошла ошибка')
                        })
                } else {}
            }
        });
    }
}
import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Department} from "../../models/gia/dicts/department.model";
import {PrintTemplateTrainingLevels} from "../../models/gia/printtemplate/printTemplate.model";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.gia.department}`;

  constructor(private http: HttpClient) {}

  getDepartments(type?: number, name?: string | null, tLevels?: PrintTemplateTrainingLevels[] | undefined, filialId?: string | null): Observable<Department[]> {
    const filter = (name ? `&Name=${name}` : '') + (type ? `&DepartmentType=${type}` : '') + (filialId ? `&FilialId=${filialId}` : '');
    const trainingLevels = tLevels?.map(x => `TrainingLevels=${x.id}`).join('&');
    return this.http.get<Department[]>(this.baseUrl + `/?${filter}&${trainingLevels}`);
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../../helpers/publications/errorHandle-helper";
import {Author, AuthorsAndRubricators} from "../../../models/publications/editForm/author.model";

@Injectable({
  providedIn: 'root'
})
export class PublicationAuthorsService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.author}`;

  constructor(private http: HttpClient) {}

  getAll(publicationId: string): Observable<AuthorsAndRubricators> {
    return this.http.get<AuthorsAndRubricators>(`${this.baseUrl}/?PublicationId=${publicationId}`);
  }

  getAuthor(id: string): Observable<Author> {
    return this.http.get<Author>(`${this.baseUrl}/${id}`);
  }

  updateAll(body: AuthorsAndRubricators): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl, body)
      .pipe(catchError(handleError));
  }
}

export class Person {
    id!: string;
    // ФИО
    fio!: string;
    // Имя автора
    firstName!: string;
    // Отчество автора
    middleName!: string;
    // Фамилия автора
    lastName!: string
    // Дата рождения
    birthday!: Date;
    // Должность
    post!: string;
    // Структурное подразделение
    department!: string;
    // СНИЛС
    identificator!: string;
    // Для авторов в публикации
    personId?: string;
    // Указан ли в публикациях
    hasPublication!: boolean;
}

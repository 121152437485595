export const items: any[] = [
    {
        text: "Настройки",
        icon: "cog",
        items: [
            {
                text: "Поручение",
                path: "/disciplineworkload/assignments",
                assignments: true
            },
            {
                text: "Кафедральная нагрузка",
                path: "/disciplineworkload/departmentworkload",
                cathedralLoad: true
            },
            {
                text: "Контингент для расчета нагрузки",
                path: "/disciplineworkload/contingentworkload",
                contingentLoad: true
            },
            {
                text: "Нагрузка преподавателя",
                path: "/disciplineworkload/teacherworkload",
                teacherWorkload: true
            },
            {
                text: "Отчеты по учебной нагрузке",
                path: "/disciplineworkload/reportsWorkload",
                reportsWorkload: true
            },
            {
                text: "Преподаватели",
                path: "/disciplineworkload/dictTeachers",
                cathedralLoad: true
            },
            {
                text: "Мониторинг поручений",
                path: "/disciplineworkload/assignmentsMonitoring",
                assignmentsMonitoring: true
            },
            {
                text: "Настройки доступа",
                path: "/disciplineworkload/useraccess",
                userAccess: true
            },
        ],
    },
];

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { items } from "../../../models/dicts/menu-items";
import { DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { TokenStorageService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from 'src/app/models/useraccess/role';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { ClassRoomUserAccessService } from "../../../services/useraccess/classroom-user-access.service";

@Component({
  selector: 'dicts-header',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})

export class DictsLayoutComponent implements OnInit{
  //title = 'ContingentWebApp';

  //Menu items
  public items: any[] = items;
  public isMainPage: boolean = this.router.url.endsWith('/dicts');

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };
  public personId: string = "";
  public classroom: boolean = false;

  public access: any = {};

  constructor(private router: Router,
              private jwtHelper: JwtHelperService,
              private userAccessService: CommonDictionariesUserAccessService,
              private tokenStore: TokenStorageService,
              private userClassroomAccessService: ClassRoomUserAccessService,) {

  }

    ngOnInit(): void {
        this.getAccessLevel()
    }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
       //  console.log('menu',response);
          this.personId = response.personId;
          this.access = response;

             this.accessSetting(!response.userAccessLevel, !response.signatoryAccessLevel);

        },
        error:() => {
          this.accessSetting(true, true)
        }}
      );
  }

  public accessSetting(response:boolean, signatory: boolean){
    // Hide admin items
    const token = this.tokenStore.getAuthToken();
    let user;

    if(token !== null) {
      user = this.jwtHelper.decodeToken(token);
    }
    let role = user.role;
    if(!role.includes(Role.Admin)&&response) {
      this.items[0].items = this.items[0].items.filter((a: { admin: boolean; }) => a.admin==null);
    }
    if(!role.includes(Role.Admin)&&signatory) {
      this.items[0].items = this.items[0].items.filter((a: { signatory: boolean; }) => a.signatory==null);
    }
  
    let entry: any;
    for (let key in this.access){
      if (typeof this.access[key] == 'number') {
        if (this.access[key] !== 0){
         entry = false;
        } else  entry = true;
      } else entry = undefined;

      if(entry) {
          this.items[0].items = this.items[0].items.filter((a: any) => a[key]==null);
          this.items[0].items[0].items = this.items[0].items[0].items.filter((a: any) => a[key]==null);
      } 
    }
  }

}

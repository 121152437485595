import {Component} from '@angular/core';
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApplicationCategoryService} from "../../../../services/mfc/dicts/application-category.service";
import {ApplicationCategory} from "../../../../models/mfc/dicts/application-category.model";
import {boolOptions} from "../../../../models/mfc/enums/bool-options.enum";
import {breadcrumbItemsMap} from "../../../../models/home/breadcrumb-items.model";
import {pagerSettings} from 'src/app/models/mfc/pagerSettings.model';

@Component({
  selector: 'mfc-dict-application-category',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="applicationCategories"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      [deletableField]="deletableField"
      (getMethod)="get()"
      (addMethod)="add($event)"
      (updateMethod)="update($event)"
      (removeMethod)="remove($event)">
    </app-dict>
  `
})
export class ApplicationCategoryComponent {

  protected applicationCategories: ApplicationCategory[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly deletableField = 'deletable';
  protected readonly info: FieldInfo<ApplicationCategory>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название',
      width: 350,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    },
    {
      field: 'tooltip',
      type: FieldType.Textbox,
      title: 'Текст пояснения',
      width: 200,
    },
    {
      field: 'hidden',
      type: FieldType.Dropdown,
      listOptions: {
        data: this.boolOptions,
        textField: 'text',
        valueField: 'id'
      },
      title: 'Скрыть категорию',
      width: 200,
    }
  ];

  protected formGroup(dataItem?: ApplicationCategory): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      name: new FormControl(dataItem?.name, Validators.required),
      tooltip: new FormControl(dataItem?.tooltip),
      hidden: new FormControl(dataItem?.hidden ?? false, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber),
    });
  }

  constructor(private applicationCategoryService: ApplicationCategoryService) {}

  protected get() {
    this.applicationCategoryService.getApplicationCategories(true).subscribe(data =>
      this.applicationCategories = data
    );
  }

  protected add({value, observer}: EventData<ApplicationCategory>) {
    this.applicationCategoryService.addApplicationCategory(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<ApplicationCategory>) {
    this.applicationCategoryService.updateApplicationCategory(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<ApplicationCategory>) {
    this.applicationCategoryService.removeApplicationCategory(value.id).subscribe(observer);
  }
}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";
import {
  AvailableFacultyGet,
  AvailableKathedrasGet,
  AvailablePersonGet, AvailableStudyFormsGet, AvailableTrainingLevelsGet,
  TeacherLoadGet
} from "../../models/disciplineworkload/teachetworkload.model";
import {DictTypeWorkGet} from "../../models/disciplineworkload/assignements.model";
import {CreateQuery} from "../../helpers/createQuery-helper";

@Injectable({
  providedIn: 'root'
})
export class TeacherWorkloadService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.teacherLoad}`;
  constructor(private http: HttpClient) { }

  // Получение нагрузки преподавателя
  getTeacherLoad(filter: HttpParams): Observable<TeacherLoadGet> {
    return this.http.get<TeacherLoadGet>(this.baseUrl + '/GetTeacherLoad', {params: filter})
      .pipe(
        catchError(handleError));
  }

  getAdditionalWorks(filter: HttpParams): Observable<TeacherLoadGet> {
    return this.http.get<TeacherLoadGet>(this.baseUrl + '/GetAdditionalWorks', {params: filter})
      .pipe(catchError(handleError));
  }

  // Получение доступных ППС
  getAvailablePersons(filters: { kathedraId: string }): Observable<AvailablePersonGet> {
    const queryParams = CreateQuery(filters);

    return this.http.get<AvailablePersonGet>(this.baseUrl + '/GetAvailablePersons', {
      params: queryParams
    }).pipe(catchError(handleError));
  }

  // Получение списка доступных каферд ППС
  getAvailableKathedras(): Observable<AvailableKathedrasGet> {
    return this.http.get<AvailableKathedrasGet>(this.baseUrl + '/GetAvailableKathedras/')
      .pipe(catchError(handleError));
  }

  // Получение списка доступных факультетов ППС
  getAvailableFaculties(filter: HttpParams): Observable<AvailableFacultyGet> {
    return this.http.get<AvailableFacultyGet>(this.baseUrl + '/GetAvailableFaculties/', { params: filter })
      .pipe(catchError(handleError));
  }

  // Получение списка доступных уровней подготовки
  getAvailableTrainingLevels(filter: HttpParams): Observable<AvailableTrainingLevelsGet> {
    return this.http.get<AvailableTrainingLevelsGet>(this.baseUrl + '/GetAvailableTrainingLevels/', {params: filter})
      .pipe(
        catchError(handleError));
  }

  // Получение списка доступных форм обучения
  getAvailableStudyForms(filter: HttpParams): Observable<AvailableStudyFormsGet> {
    return this.http.get<AvailableStudyFormsGet>(this.baseUrl + '/GetAvailableStudyForms/', {params: filter})
      .pipe(
        catchError(handleError));
  }

  //#region Write off hours

  writeOffGroupTypeWorkHours(data: {
    id: string,
    completedHours: number | null
  }): Observable<number> {
    return this.http.put<number>(this.baseUrl + '/WriteOffGroupTypeWorkHours', data);
  }

  writeOffSubGroupTypeWorkHours(data: {
    id: string,
    completedHours: number | null
  }): Observable<number> {
    return this.http.put<number>(this.baseUrl + '/WriteOffSubGroupTypeWorkHours', data)
      .pipe(catchError(handleError));
  }

  writeOffFlowTypeWorkHours(data: {
    id: string,
    completedHours: number | null
  }): Observable<number> {
    return this.http.put<number>(this.baseUrl + '/WriteOffFlowTypeWorkHours', data)
      .pipe(catchError(handleError));
  }

  //#endregion
}

import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {CardReestr, UpdateCardReestr} from "../../models/cards/cardreeestr";
import {Guid} from "guid-typescript";
import {StudentInfoAddToReestr} from "../../models/cards/studentinfocards";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class ReestrService {
  baseUrl: string = `${environment.cardsApiEndpoint}${environment.apiPaths.cards.cardReestr}`;

  constructor(private http: HttpClient) { }

  public getReestrInfo(reestrId: Guid): Observable<CardReestr> {
    return this.http.get<CardReestr>(`${this.baseUrl}/GetReestrInfo/${reestrId}`)
      .pipe(
        catchError(handleError));
  }

  public updateReestr(updateCardReestr: UpdateCardReestr) : Observable<CardReestr> {
    return this.http.put<CardReestr>(`${this.baseUrl}/UpdateCardReestr/${updateCardReestr.cardReestrId}`, updateCardReestr)
      .pipe(
        catchError(handleError));
  }

  public getCardsReestrStudentInfo(studPersonId: Guid, reestrId: Guid | undefined){
    return this.http.get<StudentInfoAddToReestr>(`${this.baseUrl}/GetCardsReestrStudentInfo/${studPersonId}?reestrId=${reestrId ?? ''}`)
      .pipe(
        catchError(handleError));
  }

  public getCardsReestrStudentInfoByStudentId(studentId: Guid, reestrId: Guid | null){
    return this.http.get<StudentInfoAddToReestr>(`${this.baseUrl}/GetCardsReestrStudentInfoByStudentId?studentId=${studentId}&reestrId=${reestrId ?? ''}`)
      .pipe(
        catchError(handleError));
  }

  public formCardReestr(reestrId: Guid) : Observable<CardReestr> {
    return this.http.put<CardReestr>(`${this.baseUrl}/FormCardReestr/${reestrId}`, null)
      .pipe(
        catchError(handleError));
  }

  public downloadUploadFileXml(reestrId: Guid) : Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.baseUrl}/DownloadUploadFileXml/${reestrId}`, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(catchError(handleError));
  }

  public downloadUploadFileXlsx(reestrId: Guid) : Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.baseUrl}/DownloadUploadFileXlsx/${reestrId}`, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(catchError(handleError));
  }
}

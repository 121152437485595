import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {StudentInfo} from '../../models/contingent/military-student-info.model';
import {MilitaryCertificateTypes} from '../../models/contingent/enums/military-certificate-types.enum';

@Injectable({
  providedIn: 'root'
})
export class MilitaryStudentInfoService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.militarystudentinfo}`;

  constructor(private http: HttpClient) { }

  private getQueryString(type: MilitaryCertificateTypes) {
    switch (type) {
      case MilitaryCertificateTypes.appendix4:
        return 'GetForMilitaryFourthForm';
      case MilitaryCertificateTypes.appendix5:
        return 'GetForMilitaryFifthForm';
      case MilitaryCertificateTypes.appendix2:
        return 'GetForMilitarySecondForm';
      case MilitaryCertificateTypes.form10:
        return 'GetForMilitaryTenthForm';
    }
  }

  public getInfoForForm(type: MilitaryCertificateTypes, studentId: string): Observable<StudentInfo> {
    return this.http.get<StudentInfo>(`${this.baseUrl}/${this.getQueryString(type)}/${studentId}`);
  }
}





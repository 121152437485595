import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { DictGroupStandardLimitation } from 'src/app/models/education/dictGroupStandardLimitation.model';
import { DictStandardLimitation } from 'src/app/models/education/dictstandardlimitation.model';
import { Standard } from 'src/app/models/education/standard.model';
import { StandardLimitation } from 'src/app/models/education/standardlimitation.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { StandardService } from 'src/app/services/education/Standard/standard.service';
import { DictGroupStandardLimitationService } from 'src/app/services/education/StandardLimitations/dict-group-standard-limitation.service';
import { StandardLimitationService } from 'src/app/services/education/StandardLimitations/standard-limitation.service';
import { EducationUserAccessService } from "../../../services/useraccess/education-user-access.service";

@Component({
  selector: 'app-standard-limitation',
  templateUrl: './standard-limitation.component.html',
  styleUrls: ['./standard-limitation.component.scss']
})
export class StandardLimitationComponent implements OnInit {
  currentStandardName?: string;
  public standardExternalId!: any;
  public standards: Standard[] = [];
  public dictGroups: DictGroupStandardLimitation[] = [];

  public isEdited = false;
  public educationStandardId: any = this.route.params;
  public defaultGuid: any = Guid.createEmpty();

  public groups: GroupDescriptor[] = [{ field: 'dictGroupStandardLimitation.groupStandardLimitationName' }];
  public dictStandardLimitations: DictStandardLimitation[] = [];
  public groupedDictStandardLimitations!: any;

  public standardLimitation: StandardLimitation = {
    externalId: null,
    dictStandardLimitationExternalId: null,
    educationStandardId: null,
    minValue: 0,
    maxValue: 0,
  }

  constructor(private standardLimitationService: StandardLimitationService,
    private notificationService: NotificationsService,
    private route: ActivatedRoute,
    private standardService: StandardService,
    private userAccessService: EducationUserAccessService,
    private dictGroupStandardLimitationService: DictGroupStandardLimitationService) {
  }

  //Getting current entry from standard
  public getNecessaryDictStandartLimitations() {
    this.standardLimitationService.getAllNessesaryDictStandardLimitations(this.route.params)
      .subscribe(
        response => {
          this.dictStandardLimitations = response;
          this.groupingDicts();
        });
  }

  public closeAllEditors() {
    this.isEdited = false;
  }

  private groupingDicts() {
    this.groupedDictStandardLimitations = groupBy(this.dictStandardLimitations, this.groups);
  }

  public isLastItem(dataItem: any) {
    return (this.groupedDictStandardLimitations[this.groupedDictStandardLimitations.length -1].items.length - 1
        === this.groupedDictStandardLimitations[this.groupedDictStandardLimitations.length -1].items.findIndex((item: { name: any; }) => item.name
        === dataItem.name));
  }

  public saveHandler(): void {
    this.isEdited = false;
    this.saveChanges();
  }

  public editHandler(): void {
    this.isEdited = true;
  }

  public cancelHandler(): void {
    this.getNecessaryDictStandartLimitations();
    this.isEdited = false;
  }

  public saveStandardLimitations(limitations: any[]): StandardLimitation[] {
    let standardLimitations: StandardLimitation[] = [];

    limitations.forEach(limitation => {
      let standardLimitation: StandardLimitation = {
        externalId: this.defaultGuid.value,
        dictStandardLimitationExternalId: null,
        educationStandardId: null,
        minValue: undefined,
        maxValue: undefined,
        logicalVariable: null
      }

      standardLimitation.externalId = limitation.standardLimitationExternalId;

      standardLimitation.logicalVariable = limitation.logicalVariable;
      standardLimitation.minValue = limitation.minValue;
      standardLimitation.maxValue = limitation.maxValue;

      standardLimitation.educationStandardId = this.educationStandardId.value.education_standard_id;
      standardLimitation.dictStandardLimitationExternalId = limitation.dictStandardLimitationExternalId
      standardLimitations.push(standardLimitation);
    });

    return standardLimitations;
  }

  private fillRows(data: any): any[] {
    let allRows: any[] = [];
    let i = 0;

    data.forEach((group: any) => {
      let rows: any = group.items;
      rows.forEach((row: any) => {
        allRows[i] = row
        i += 1;
      });
    });
    return allRows;
  }

  public saveChanges() {
    let standardLimitations: StandardLimitation[] = [];
    let groups: any = this.groupedDictStandardLimitations
    let allRows: any[] = this.fillRows(groups);

    standardLimitations = this.saveStandardLimitations(allRows)
    this.closeAllEditors();
    this.standardLimitationService.updateStandardLimitation(standardLimitations)
      .subscribe(
        response => {
          this.getNecessaryDictStandartLimitations();
          this.notificationService.showSuccess("Ограничения стандарта было успешно изменёно!");
        },
        error => {
          this.notificationService.showError("Не удалось изменить ограничения стандарта");
        }
      )
  }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getNecessaryDictStandartLimitations();
    this.getAllStandards();
    this.getAllDictGroupStandardLimitation();

    this.standardExternalId = this.route.params;
    setTimeout(() => {
      this.currentStandardName = this.getStandard(this.standardExternalId)!.fullTrainingProgram;
    }, 1000);
  }

  public getDictStandardLimitation(id: Guid) {
    return this.dictStandardLimitations.find((x) => x.dictStandardLimitationExternalId == id);
  }

  public isLimitationHasBoolType(id: Guid) {
    return this.getDictStandardLimitation(id)?.standardLimitationDataType === 1;
  }

  public getStandard(id: any) {
    return this.standards.find((x) => x.standardId === id.value.education_standard_id)
  }

  //Getting all entries from dictionary
  public getAllDictGroupStandardLimitation() {
    this.dictGroupStandardLimitationService.getAllDictGroupStandardLimitation()
      .subscribe(
        response => {
          this.dictGroups = response;
        }
      );
  }

  //Getting all entries from standard
  public getAllStandards() {
    this.standardService.getAllStandards()
      .subscribe(
        response => {
          this.standards = response;
        },
        error => {
          this.notificationService.showError("Не удалось загрузить список стандартов")
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = !response.dictAccessLevel;
    }, error => { });
  }
}
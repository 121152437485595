import {DisplaySettingEnum} from "./enums/display-setting.enum";

export const listbox: string[] = [
  "Адрес по прописке",
  "Адрес электронной почты",
  "Академический статус",
  "Вид спорта",
  "Военная категория",
  "Военно-учетная специальность",
  "Военный комиссариат",
  "Воинское звание",

  "Год начала обучения",
  "Год окончания обучения",
  "Год получения документа о предыдущем образовании",
  "Годность к военной службе",
  "Гражданство",
  "Группа здоровья",
  "Данные о справках",
  "Дата выдачи документа (удостоверение личности)",
  "Дата выдачи документа воинского учета",
  "Дата выдачи документа об образовании",
  "Дата защиты диссертации",

  "Дата договора на платное обучение",
  "Дата начала обучения",
  "Дата окончания обучения",
  "Дата приказа о зачислении",
  "Дата регистрации сведений, направленных в военный комиссариат",
  "Дата рождения",
  "Дата снятия с воинского учета",
  "Дата утверждения ВАК",
  "Договор на платное обучение",
  "Документ об образовании",
  "Должность",
  "Дом (адрес по прописке)",
  "Дом (фактический адрес проживания)",
  "Дополнительные сведения (ВУС)",
  "Заказчик целевого обучения",
  "Идентификатор студента",
  "Идентификатор студента в ПК",
  "Изучаемые языки",
  "Имя",
  "Имя (латиница)",
  "Индекс (адрес по прописке)",
  "Индекс (фактический адрес проживания)",
  "ИНН",
  "ИНН заказчика целевого обучения",
  "Институт/факультет",
  "Информация о родственниках",
  "Информация о родственниках (адрес проживания)",
  "Информация о родственниках (номер телефона)",
  "Информация о родственниках (степень родства)",
  "Информация о родственниках (ФИО)",
  //"ИНН заказчика целевого обучения",
  "Исходящий регистрационный номер сведений, направленных в военный комиссариат",
  //"Кампусный номер",
  "Категория воинского учета",
  "Категория финансирования",
  "Кафедра",
  "Квартира (адрес по прописке)",
  "Квартира (фактический адрес проживания)",
  "Кем выдан документ (удостоверение личности)",
  "Кем выдан документ воинского учета",
  "Кем выдан документ об образовании",
  "Код направления подготовки",
  "Код ОКСМ",
  "Код подразделения документа (удостоверение личности)",
  "Кол-во достижений",
  "Корпус (адрес по прописке)",
  "Корпус (фактический адрес проживания)",
  "КПП заказчика целевого обучения",
  "КПП работодателя по договору о целевом обучении",
  "Льготы",
  "Место работы",
  "Место рождения",
  //"КПП заказчика целевого обучения",
  //"Место работы",
  "Название предыдущего учебного заведения",
  //"Наличие военного комиссариата",

  "Наименование направления подготовки",
  "Наличие медицинской справки",
  "Направление подготовки",
  "Населённый пункт (адрес по прописке)",
  "Населённый пункт (фактический адрес проживания)",
  "Научный руководитель (куратор)",
  "Необходимость в общежитии",
  "Номер диплома выпускника (дубликат)",
  "Номер диплома выпускника (оригинал)",
  "Номер договора на платное обучение",
  "Номер договора о целевом обучении",
  "Номер документа (удостоверение личности)",
  "Номер документа воинского учета",
  "Номер документа об образовании",
  "Номер комнаты в общежитии",
  "Номер общежития",
  "Номер реестра выгрузки в банк",
  "ОГРН заказчика целевого обучения",
  "ОГРН работодателя по договору о целевом обучении",
  //"Источник финансирования",
  "Отношение к военной службе",
  "Отчество",
  "Отчество (латиница)",
  "Подкатегория финансирования",
  "Пол",
  "Получает второе высшее",
  "Почтовый адрес военного комиссариата",
  "Предыдущее образование получено в этом учебном заведении",
  "Прежняя фамилия",
  "Приказ о зачислении",
  "Приказ о зачислении (дата)",
  "Приказ о зачислении (начало действия)",
  "Приказ о зачислении (номер)",
  "Приказ об отчислении",
  "Причина снятия с воинского учета",
  "Проживает в общежитии",
  "Профиль (программа)",
  "Работодатель",
  "Район (адрес по прописке)",
  "Район (фактический адрес проживания)",
  "Рег. номер диплома выпускника (дубликат)",
  "Рег. номер диплома выпускника (оригинал)",
  "Регион (адрес по прописке)",
  "Регион (фактический адрес проживания)",
  "Семейное положение",
  "Серия диплома выпускника (дубликат)",
  "Серия диплома выпускника (оригинал)",
  "Серия документа (удостоверение личности)",
  "Серия документа воинского учета",
  "Серия документа об образовании",
  "СНИЛС",
  "Соотечественник",
  "Состав (ВУС)",
  "Состоит на воинском учете",
  "Социальная сеть",
  "Специальность по диплому",
  //"Специальность по диплому (предыдущее образование)",
  "Спортивный разряд",
  "Справка о медицинском осмотре действительна до",
  "Средний балл",
  "Срок действия визы",
  "Стоимость обучения в год, руб.",
  //"Стоим. в сем., руб.",
  "Страна (адрес по прописке)",
  "Страна (фактический адрес проживания)",
  "Строение (адрес по прописке)",
  "Строение (фактический адрес проживания)",
  "Телефон",
  "Тип гражданства",
  "Тип документа об образовании",
  "Тип документа удостоверения личности",
  "Удостоверение личности",
  "Укрупненная группа специальностей",
  "Улица (адрес по прописке)",
  "Улица (фактический адрес проживания)",
  "Уровень образования",
  "Уровень подготовки",
  "Уровень предыдущего образования",

  "Фактический адрес проживания",
  "Фамилия",
  "Фамилия (латиница)",
  "Форма обучения",
  "ФИО (латиница)",
];

export const selectlistbox: string[] = [
  "№ п/п",
  "Группа",
  "Источник финансирования",
  "Курс",
  "Номер зачетной книжки",
  "ФИО",
];


export const dependentColumns: Array<{field: string; visEnum: number; isHiddenStudentCard: boolean }> = [
  {
    field: "Военный комиссариат",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: true
  },
  {
    field: "Категория воинского учета",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: true
  },
  {
    field: "Исходящий регистрационный номер сведений, направленных в военный комиссариат",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: false
  },
  {
    field: "Годность к военной службе",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: false
  },
  {
    field: "Отношение к военной службе",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: false
  },
  {
    field: "Причина снятия с воинского учета",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: false
  },
  {
    field: "Состав (ВУС)",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: false
  },
  {
    field: "Состоит на воинском учете",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: false
  },
  {
    field: "Серия документа воинского учета",
    visEnum: DisplaySettingEnum.military,
    isHiddenStudentCard: false
  },
];

import {Component, OnInit, ViewChild} from "@angular/core";
import {BlankTypesModel} from '../../../../models/diplomablanks/dicts.model';
import {DictBlankTypeService} from '../../../../services/diplomablanks/dict-blanktypes.service';
import {DictBlankTemplateService} from '../../../../services/diplomablanks/dict-blanktemplate.service';
import {BlankTemplateModel} from '../../../../models/diplomablanks/dicts.model';
import {Validators, FormGroup, FormControl} from "@angular/forms";
import {BlanksAvailableService} from '../../../../services/diplomablanks/blanks-available.service';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-addblanks',
    templateUrl: './add-blanks.component.html',
    styleUrls: ['./add-blanks.component.scss']
  })
  
export class AddBlanksComponent implements OnInit {

  public blankTypes: BlankTypesModel[] = [];
  public blankTemplates: BlankTemplateModel[] = [];

  public dictBlankTypeId: string | null = null;
  public dictBlankTemplateId: string | null = null;
  public series: number | null = null;
  public numberFirstBlank: string = "";
  public count: number = 1;

  public existBlanks: string = "";

  public tooltip: string = "";

  Form : FormGroup = new FormGroup({
    dictBlankTypeId: new FormControl('', [Validators.required]),
    dictBlankTemplateId: new FormControl('', [Validators.required]),
    series: new FormControl('', [Validators.required]),
    numberFirstBlank: new FormControl('', [Validators.required, Validators.pattern("^[0-9]+$")]),
    count: new FormControl('', [Validators.required]),
  });

  constructor(
    private dictBlankTypeService: DictBlankTypeService,
    private dictBlankTemplateService: DictBlankTemplateService,
    private blanksAvailableService: BlanksAvailableService,
    private notificationService: NotificationsService,
    private router: Router,
  ) { }
    
  public ngOnInit(): void {
    this.getBlankTypes();
    this.getBlankTemplates();
  }

  public getBlankTypes() {
    this.dictBlankTypeService.getBlankTypes()
      .subscribe(response => {
        if (response) this.blankTypes = response;
      })
  }

  public getBlankTemplates() {
    this.dictBlankTemplateService.getBlankTemplate()
      .subscribe(response => {
        if (response) {
          this.blankTemplates = response;
          this.blankTemplates.forEach(el => el.name = el.name + ' ' + el.graduateYear);
        }
      })
  }

  public addBlanks() {
    this.Form.value.series = this.Form.value.series.toString();
    this.blanksAvailableService.addBlanks(this.Form.value)
      .subscribe(response => {
        this.notificationService.showSuccess("Успешно");
        this.dictBlankTypeId = null;
        this.dictBlankTemplateId = null;
        this.numberFirstBlank = "";
        this.count = 1;
      },
      error => {
        const string = JSON.stringify(Object.values(error.error)[0]);
        this.tooltip = string.slice(2, string.length - 2);
        this.existBlanks = JSON.stringify(Object.keys(error.error)[0]);
      })
  }

  public cancel() {
    this.router.navigate(['/diplomablanks']);
  }

  public onChange(value: string | number) {
    this.existBlanks = '';
  }
}

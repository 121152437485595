import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EmailTypes } from '../../models/contingent/emailtypes.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EmailtypesService {


  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.emailtypes}`;

  constructor(private http: HttpClient) { }

  //Get all emailtypes
  public getAllemailtypes(): Observable<EmailTypes[]> {
    return this.http.get<EmailTypes[]>(this.baseUrl);
  }

  //Add emailtypes
  addemailtypes(emailtypes: EmailTypes): Observable<EmailTypes> {
    return this.http.post<EmailTypes>(this.baseUrl, emailtypes)
      .pipe(catchError(this.handleError));
  }

  //Delete emailtypes
  deleteemailtypes(id: string): Observable<EmailTypes> {
    return this.http.delete<EmailTypes>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit emailtypes
  updateemailtypes(emailtypes: EmailTypes): Observable<EmailTypes> {
    return this.http.put<EmailTypes>(this.baseUrl + '/' + emailtypes.dictEmailTypeExternalId, emailtypes)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'workload-commissions',
  templateUrl: './assignmentHome.component.html',
  styleUrls: ['./assignmentHome.component.scss']
})
export class AssignmentHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentSearchQuery} from "../../../models/gia/orders/student-search-query.model";
import {Department, DepartmentType} from "../../../models/gia/dicts/department.model";
import {DictsService} from "../../../services/gia/dicts.service";
import {EducationService} from "../../../services/gia/education.service";
import {AdmissionOrderService} from "../../../services/gia/admission-order.service";
import {EduStandardModel} from "../../../models/gia/education/standard.model";
import {ReleaseOrderService} from "../../../services/gia/release-order.service";
import {DiplomHomeService} from "../../../services/gia/diplomhome.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-order-student-search',
  templateUrl: './order-student-search.component.html',
  styleUrls: ['./order-student-search.component.scss']
})
export class OrderStudentSearchComponent implements OnInit {

  @Input() admissionOrderId?: string;
  @Input() releaseOrderId?: string;
  @Output() save: EventEmitter<StudentSearchQuery> = new EventEmitter();

  public trainingLevelId?: string;
  public facultyId?: string;
  public studyFormId?: string;
  public educationStandardId?: string;
  public studentFio: string = '';
  public filialId?: string;
  public standardNameFilter?: string;
  public graduateYear?: number;
  public educationStandard: EduStandardModel[] = [];
  public faculties?: Department[];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  constructor(
    private departmentService: DiplomHomeService,
    private dictService: DictsService,
    private educationService: EducationService,
    private admissionOrderService: AdmissionOrderService,
    private releaseOrderService: ReleaseOrderService,
  ) { }

  ngOnInit(): void {
    if (this.admissionOrderId)
      this.getAdmissionOrderInfo(this.admissionOrderId);
    if (this.releaseOrderId)
      this.getReleaseOrderInfo(this.releaseOrderId);
  }

  public onSearch(): void {
    this.save.emit({
      facultyId: this.facultyId,
      studyFormId: this.studyFormId,
      educationStandardId: this.educationStandardId,
      studentFio: this.studentFio,
      trainingLevelId: this.trainingLevelId!,
      graduateYear: this.graduateYear,
      filialId: this.filialId,
      standardName: this.standardNameFilter
    });
  }

  public getAdmissionOrderInfo(orderId: string) {
    this.admissionOrderService.getOrderInfo(orderId)
      .subscribe(response => {
        this.trainingLevelId = response.trainingLevelId;
        this.graduateYear = response.graduateYear;
        this.filialId = response.filialId;
        this.standardNameFilter = response.standardNameFilter;
        this.getEducationStandards();
        this.getDepartments();
      })
  }

  public getReleaseOrderInfo(orderId: string) {
    this.releaseOrderService.getOrderInfo(orderId)
      .subscribe(response => {
        this.trainingLevelId = response.trainingLevelId;
        this.graduateYear = response.graduateYear;
        this.filialId = response.filialId;
        this.standardNameFilter = response.standardNameFilter;
        this.getEducationStandards();
        this.getDepartments();
      })
  }

  public getEducationStandards() {
    this.educationService.getStudentStandards(this.standardNameFilter, this.trainingLevelId, this.filialId)
      .subscribe(response => {
        this.educationStandard = response;
      })
  }

  public getDepartments() {
    this.departmentService.getDepartmentsByStudents(DepartmentType.Факультет, this.trainingLevelId, this.graduateYear, undefined, this.filialId, this.standardNameFilter)
      .subscribe(response => {
        this.faculties = response;
      })
  }

  protected readonly studyForms = this.dictService.getStudyForms();

}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {StudyForm} from "../../../models/mfc/dicts/study-form.model";

@Injectable({
  providedIn: 'root'
})
export class StudyFormService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.externalDict}/StudyForm/`;

  constructor(private http: HttpClient) { }

  public getStudyForms(): Observable<StudyForm[]> {
    return this.http.get<StudyForm[]>(this.baseUrl);
  }

  public getStudyForm(id: string): Observable<StudyForm> {
    return this.http.get<StudyForm>(`${this.baseUrl}${id}`);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { NonEmploymentReasonModel } from "src/app/models/contingent/nonemploymentreason.model";

@Injectable({
  providedIn: 'root'
})

export class NonEmploymentReasonsService {

  baseUrl = `${environment.apiEndpoint}`;
  nonemploymentreason = 'contingent/d/nonemploymentreason/';

    constructor(private http: HttpClient) { }

    public getNonEmploymentReasons() {
        return this.http.get<NonEmploymentReasonModel[]>(this.baseUrl + this.nonemploymentreason + 'GetList')
    }

    public addNonEmploymentReasons(data: NonEmploymentReasonModel) {
        return this.http.post(this.baseUrl + this.nonemploymentreason, data)
    }

    public updateNonEmploymentReasons(data: NonEmploymentReasonModel, id: string) {
        return this.http.put(this.baseUrl + this.nonemploymentreason + id, data)
    }

    public deleteNonEmploymentReasons(id: string) {
        return this.http.delete(this.baseUrl + this.nonemploymentreason + id)
    }

}
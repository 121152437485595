import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {
  DropDownFilterSettings,
  MultiSelectComponent,
  MultiSelectTreeComponent
} from '@progress/kendo-angular-dropdowns';
import {ApplicationConstructor} from '../../../../../models/mfc/applicationConstructor/application-constructor.model';
import {ApplicationCategoryService} from '../../../../../services/mfc/dicts/application-category.service';
import {ApplicationTypeService} from '../../../../../services/mfc/dicts/application-type.service';
import {TrainingLevelService} from '../../../../../services/mfc/dicts/training-level.service';
import {DepartmentService} from '../../../../../services/mfc/dicts/department.service';
import {StudyFormService} from '../../../../../services/mfc/dicts/study-form.service';
import {CitizenshipService} from '../../../../../services/mfc/dicts/citizenship.service';
import {TypeReceiptService} from '../../../../../services/mfc/dicts/type-receipt.service';
import {ApplicationCategory} from '../../../../../models/mfc/dicts/application-category.model';
import {ApplicationType} from '../../../../../models/mfc/dicts/application-type.model';
import {TypeReceipt} from '../../../../../models/mfc/dicts/type-receipt.model';
import {
  ApplicationConstructorForm,
  ConstructorDocument,
  ConstructorForm,
  SystemNotificationSetting
} from '../../../../../models/mfc/applicationConstructor/form/constructor-main-settings.model';
import {FilialService} from '../../../../../services/mfc/dicts/filial.service';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {openDialog} from '../../../../../helpers/dialogHelper';
import {NotificationsService} from '../../../../../services/notifications/notifications.service';
import {ApplicationConstructorService} from '../../../../../services/mfc/application-constructor.service';
import {Router} from '@angular/router';
import {DocumentTypes, DocumentTypeStrings} from '../../../../../models/mfc/enums/document-types.enum';
import {boolOptions} from '../../../../../models/mfc/enums/bool-options.enum';
import {FacultyService} from '../../../../../services/mfc/dicts/faculty.service';
import {FilialTreeItem} from '../../../../../models/mfc/dicts/filial.model';
import {TrainingLevel} from '../../../../../models/mfc/dicts/training-level.model';
import {StudyForm} from '../../../../../models/mfc/dicts/study-form.model';
import {Citizenship} from '../../../../../models/mfc/dicts/citizenship.model';
import {FacultyTreeItem} from '../../../../../models/mfc/faculties-tree.model';
import {Dict} from '../../../../../models/mfc/dict.model';
import {lastValueFrom, Subscription} from 'rxjs';
import {ApplicationStatus} from '../../../../../models/mfc/dicts/application-status.model';
import {ApplicationStatusService} from '../../../../../services/mfc/dicts/application-status.service';
import {AddEvent, EditEvent, GridComponent, RemoveEvent, SaveEvent} from '@progress/kendo-angular-grid';
import {ApplicationStatusesEnum} from '../../../../../models/mfc/enums/application-statuses.enum';
import {
  ApplicationConstructorEditFormService
} from '../../../../../services/mfc/application-constructor-edit-form.service';
import {ApplicationConstructorTabsEnum} from '../../../../../models/mfc/enums/application-constructor-tabs.enum';
import {ReceiptTypesEnum} from '../../../../../models/mfc/enums/receipt-types.enum';


@Component({
  selector: 'mfc-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrls: ['./main-settings.component.scss', '../../../styles/application-constructor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationConstructorMainSettingsComponent implements OnInit, OnChanges {

  @Input() editable = false;
  @Input() settings?: ApplicationConstructor;

  private isChanged = false;
  private dictsLoading = false;

  protected applicationConstructor?: ConstructorForm;
  protected formGroup!: FormGroup;

  private showMockData = false;
  // mockData
  protected typeReceiptDescriptions = !this.showMockData ? [] : [
    {
      typeReceipt: 'В Личном Кабинете',
      description: `По готовности справка будет доступна на скачивание в данной заявке.
        Справка будет подписана усиленной квалифицированной электронной подписью.`
    },
    {
      typeReceipt: 'В деканате',
      description: `По готовности справка будет доступна для получения в деканате вашего факультета.
      Справка будет подписана физической подписью на бумажном носителе`
    },
  ];

  protected filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  protected categories: ApplicationCategory[] = [];
  protected types: ApplicationType[] = [];
  protected filteredTypes: ApplicationType[] = [];
  protected receiptTypes: TypeReceipt[] = [];
  protected filials: FilialTreeItem[] = [];
  protected dictTrainingLevels: TrainingLevel[] = [];
  protected dictFaculties: FacultyTreeItem[] = [];
  protected dictStudyForms: StudyForm[] = [];
  protected dictCitizenships: Citizenship[] = [];
  protected applicationStatuses: ApplicationStatus[] = [];

  protected notificationsEditMode = false;
  protected lastRowIndex?: number = undefined;
  protected isNew = false;
  protected gridFormGroup: FormGroup = new FormGroup({});

  protected statusMap = new Map<ApplicationStatusesEnum, string>([]);
  protected receiptTypesMap = new Map<string, string>([]);

  private checkChangesSubscription$!: Subscription;

  protected readonly DocumentTypeStrings = DocumentTypeStrings;
  protected readonly boolOptions = boolOptions;
  protected readonly DocumentTypes = DocumentTypes;

  private applicationStatusesText: Map<ApplicationStatusesEnum, string> = new Map([
    [ApplicationStatusesEnum.Sent, 'Заявка отправлена'],
    [ApplicationStatusesEnum.Revision, 'Заявка возвращена на доработку'],
    [ApplicationStatusesEnum.Rejected, 'Заявка отклонена'],
    [ApplicationStatusesEnum.Withdrawn, 'Заявка отозвана'],
    [ApplicationStatusesEnum.InProgress, 'Заявка в работе'],
    [ApplicationStatusesEnum.Ready, 'Заявка выполнена'],
  ]);

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private applicationCategoryService: ApplicationCategoryService,
    private applicationTypeService: ApplicationTypeService,
    private applicationStatusService: ApplicationStatusService,
    private filialService: FilialService,
    private trainingLevelService: TrainingLevelService,
    private departmentService: DepartmentService,
    private studyFormService: StudyFormService,
    private citizenShipService: CitizenshipService,
    private typeReceiptService: TypeReceiptService,
    private applicationConstructorService: ApplicationConstructorService,
    private facultyService: FacultyService,
    private editFormService: ApplicationConstructorEditFormService
  ) { }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.checkChangesSubscription$ = this.editFormService.checkChanges$.subscribe(value => {
      if (value === ApplicationConstructorTabsEnum.Main) {
        this.editFormService.setHasChanges(this.isChanged);
      }
    });
  }

  async ngOnChanges() {
    if (!this.dictsLoading) await this.getDicts();
    if (this.settings) {
      this.applyConstructorData(this.settings);
    }
  }

  private async getDicts() {
    this.dictsLoading = true;
    await this.getCategories();
    await this.getTypes();
    await this.getApplicationStatuses();
    await this.getReceiptTypes();
    await this.getFaculties();
    await this.getTrainingLevels();
    await this.getStudyForms();
    await this.getCitizenships();
    this.formGroup = this.createFormGroup();
    this.cdRef.detectChanges();
  }

  private async getCategories() {
    await lastValueFrom(this.applicationCategoryService.getApplicationCategories()).then((response) =>
      this.categories = response);
  }

  private async getTypes() {
    await lastValueFrom(this.applicationTypeService.getApplicationTypes()).then((response) => {
      this.types = response;
      this.filterTypes(this.applicationConstructor?.category);
    });
  }

  private async getApplicationStatuses() {
    await lastValueFrom(this.applicationStatusService.getAll()).then((response) => {
      this.applicationStatuses = response;
      this.applicationStatuses.forEach((item) => {
        this.statusMap.set(item.applicationStatusEnum, item.name);
      });
    });
  }

  private async getReceiptTypes() {
    await lastValueFrom(this.typeReceiptService.getAll()).then((response) => {
      this.receiptTypes = response;
      this.receiptTypes.forEach((item) => {
        this.receiptTypesMap.set(item.id, item.name);
      });
    });
  }

  private async getFaculties() {
    await lastValueFrom(this.facultyService.getFilialsAndFaculties()).then((response) => {
      const filials = response.map((filial) => ({id: filial.id, name: filial.name}));
      this.dictFaculties = response.map((filial) => filial.faculties.map((faculty) =>
        ({id: faculty.id, name: faculty.name, filialId: filial.id}))).flat();
      this.dictFaculties.unshift(...filials);
    });
  }

  private async getTrainingLevels() {
    await lastValueFrom(this.trainingLevelService.getTrainingLevels()).then((response) => {
      this.dictTrainingLevels = response;
    });
  }

  private async getStudyForms() {
    await lastValueFrom(this.studyFormService.getStudyForms()).then((response) => {
      this.dictStudyForms = response;
    });
  }

  private async getCitizenships() {
    await lastValueFrom(this.citizenShipService.getCitizenships()).then((response) => {
      this.dictCitizenships = response;
    });
  }

  private applyConstructorData(data: ApplicationConstructor | ApplicationConstructorForm) {
    this.applicationConstructor = this.mapConstructorData(data as ApplicationConstructor & ApplicationConstructorForm);
    this.formGroup = this.createFormGroup();
    this.cdRef.detectChanges();
  }

  private createFormGroup() {
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    const form = new ConstructorForm();

    Object.keys(form).forEach((key) => {
      const fieldData = this.applicationConstructor
        ? this.applicationConstructor[key as keyof ConstructorForm]
        : null;
      arr[key] = new FormControl(fieldData !== null ? fieldData : form[key as keyof ConstructorForm],
        key !== 'externalId' ? Validators.required : null);
    });
    arr[DocumentTypeStrings.Document] = this.getDocumentFormArray(
      this.applicationConstructor?.documents);
    arr[DocumentTypeStrings.Statement] = this.getDocumentFormArray(
      this.applicationConstructor?.statements);
    arr['systemNotificationSettings'] = this.getNotificationFormArray(
      this.applicationConstructor?.systemNotificationSettings ?? form['systemNotificationSettings']);

    return new FormGroup(arr);
  }

  private getDocumentFormArray(docs?: ConstructorDocument[]) {
    const formArr = new FormArray<FormGroup>([]);
    docs?.forEach((item) => {
      formArr.push(this.patchDocuments({item: item}))
    });
    return formArr;
  }

  private patchDocuments(input?: {item?: ConstructorDocument, type?: DocumentTypes}) {
    return new FormGroup({
      externalId: new FormControl(input?.item?.externalId),
      name: new FormControl(input?.item?.name ?? null, Validators.required),
      documentType: new FormControl(input?.item?.documentType ?? input?.type)
    });
  }

  private getNotificationFormArray(settings?: SystemNotificationSetting[]) {
    const formArr = new FormArray<FormGroup>([]);
    if (!settings?.length && !this.applicationConstructor) {
      const systemNotificationSetting = this.applicationStatuses.filter(
        item => this.applicationStatusesText.get(item.applicationStatusEnum)
      );
      systemNotificationSetting.forEach(
        applicationStatus => this.receiptTypes.forEach(
          receiptType => formArr.push(this.patchNotificationSettings(
            {applicationStatus: applicationStatus.applicationStatusEnum,
                  dictTypeReceiptId: receiptType.id,
                  text: this.applicationStatusesText.get(applicationStatus.applicationStatusEnum) ?? ''}))));
    } else {
      settings?.forEach((item) => {
        formArr.push(this.patchNotificationSettings(item))
      });
    }
    return formArr;
  }

  private patchNotificationSettings(item?: SystemNotificationSetting) {
    return new FormGroup({
      applicationStatus: new FormControl(item?.applicationStatus ?? null, Validators.required),
      dictTypeReceiptId: new FormControl(item?.dictTypeReceiptId ?? null, Validators.required),
      text: new FormControl(item?.text ?? null, Validators.required),
    });
  }

  private mapConstructorData(data: ApplicationConstructor & ApplicationConstructorForm): ConstructorForm {
    const isAllTrainingLevels = data.basicSettings.isAllTrainingLevelAvailable;
    const isAllFaculties = data.basicSettings.isAllFacultyAvailable;
    const isAllStudyForms = data.basicSettings.isAllStudyFormAvailable;
    const isAllCitizenships = data.basicSettings.isAllCitizenshipAvailable;

    const trainingLevels = isAllTrainingLevels
      ? this.dictTrainingLevels.map((item) => item.id)
      : data.filter.trainingLevelIds ?? data.filter.trainingLevelDtos.map((item) => item.id);
    const studyForms = isAllStudyForms
      ? this.dictStudyForms.map((item) => item.id)
      : data.filter.studyFormIds ?? data.filter.studyFormDtos.map((item) => item.id);
    const citizenships = isAllCitizenships
      ? this.dictCitizenships.map((item) => item.id)
      : data.filter.citizenShipIds ?? data.filter.citizenShipDtos.map((item) => item.id);
    const faculties = isAllFaculties
      ? this.dictFaculties
      : (data.filter.facultyIds?.map((item) => this.dictFaculties.find((faculty) => faculty.id === item))
        ?? data.filter.facultyDtos?.map((item) => this.dictFaculties.find((faculty) => faculty.id === item.id)))
        .filter((item) => !!item);

    const category = data.basicSettings.dictApplicationCategoryDto?.id
      ?? this.types.find((item) =>
        item.id === data.basicSettings.dictApplicationTypeId)?.dictApplicationCategoryId;

    return {
      externalId: data.externalId,
      category: category,
      type: data.basicSettings.dictApplicationTypeId ?? data.basicSettings.dictApplicationTypeDto.id,
      name: data.basicSettings.name,
      trainingLevels: trainingLevels,
      isAllTrainingLevelAvailable: isAllTrainingLevels,
      faculties: faculties as FacultyTreeItem[],
      isAllFacultyAvailable: isAllFaculties,
      studyForms: studyForms,
      isAllStudyFormAvailable: isAllStudyForms,
      citizenShips: citizenships,
      isAllCitizenshipAvailable: isAllCitizenships,
      typeReceipts: data.basicSettings.dictTypeReceiptIds
        ?? data.basicSettings.dictTypeReceiptDtos.map((item) => item.id),
      allowedAddInsteadOfStudents: data.basicSettings.allowedAddInsteadOfStudents,
      showOnStudentCard: data.basicSettings.showOnStudentCard,
      allowRegistrationNumber: data.basicSettings.allowRegistrationNumber,
      hasDocuments: (data.applicationConstructorDocuments ?? []).length > 0,
      documents: data.applicationConstructorDocuments?.filter(item => item.documentType === DocumentTypes.Document) ?? [],
      systemNotificationSettings: data.basicSettings.systemNotificationSettings ?? [],
      hasStatements: data.applicationConstructorDocuments?.some(a => a.documentType === DocumentTypes.Statement) ?? false,
      statements: data.applicationConstructorDocuments?.filter(item => item.documentType === DocumentTypes.Statement),
    };
  }

  private filterTypes(categoryId?: string|null) {
    this.filteredTypes = !categoryId
      ? this.types
      : this.types.filter((item) => item.dictApplicationCategoryId === categoryId);
  }

  protected fieldChange() {
    this.isChanged = true;
  }

  protected categoryChange(id?: string) {
    this.filterTypes(id);
    if (id && this.formGroup.get('type')?.value) {
      const type = this.types.find((item) =>
        item.id === this.formGroup.get('type')?.value);
      if (type?.dictApplicationCategoryId !== id)
        this.formGroup.get('type')?.reset();
    }
    this.fieldChange();
  }

  protected typeChange(id?: string) {
    if (id && !this.formGroup.get('category')?.value) {
      const categoryId = this.types.find((item) =>
        item.id === id)?.dictApplicationCategoryId;
      this.patchCategory(categoryId);
    }
    const type = this.types.find((item) => item.id === id)?.name;
    this.formGroup.get('name')?.patchValue(type);
    this.fieldChange();
  }

  private patchCategory(id?: string) {
    this.formGroup.get('category')?.patchValue(id);
    this.categoryChange(id);
  }

  protected typeReceiptChange(id?: string[]) {
    if (id) {
      const notProvided = this.receiptTypes.find((item) =>
        item.typeReceipt === ReceiptTypesEnum.NotProvided)?.id ?? '';
      if (id?.includes(notProvided)) {
        this.formGroup.get('typeReceipts')?.patchValue([notProvided]);
      }
    }
    this.fieldChange();
  }

  protected isIndet(dictLength: number, valueLength: number) {
    return valueLength > 0 && valueLength < dictLength;
  }

  protected onIsAllClick(
    component: Object,
    dictValues: Dict[],
    isAll: AbstractControl|null,
    value?: AbstractControl|null,
    valuePrimitive: boolean = true
  ) {
    isAll?.patchValue(!isAll.value);
    const values = valuePrimitive ? dictValues.map((item) => item.id) : dictValues;
    value?.patchValue(isAll?.value ? values : []);

    const multiselect = <MultiSelectComponent|MultiSelectTreeComponent>component;
    multiselect.valueChange.emit();
    multiselect.toggle(false);
  }

  protected multiselectValueChange(value: AbstractControl|null, dictValues: Dict[], isAll: AbstractControl|null) {
    isAll?.patchValue(value?.value.length === dictValues.length);
    this.fieldChange();
  }

  protected tagMapper(tags: Dict[]) {
    return tags.length > 1 ? [tags] : tags;
  }

  protected get facultiesList() {
    return this.dictFaculties.filter((item) => item.filialId);
  }

  protected facultiesTagMapper(tags: FacultyTreeItem[]) {
    const faculties = tags.filter((item) => item.filialId);
    return faculties.length > 1 ? [faculties] : faculties;
  }

  protected documentsChange(value: boolean, field: DocumentTypeStrings, type: DocumentTypes) {
    if (value) {
      this.addDocument(field, type);
    } else if ((<FormArray>this.formGroup.get(field)).length) {
      (<FormArray>this.formGroup.get(field)).clear();
    }
    this.fieldChange();
  }

  protected addDocument(field: DocumentTypeStrings, type: DocumentTypes) {
    const typeName = this.types.find((item) =>
      item.id === this.formGroup.get('type')?.value)?.name ?? '';
    (<FormArray>this.formGroup.get(field)).push(this.patchDocuments({item: {name: typeName}, type: type}));
    this.fieldChange();
  }

  protected removeDocument(field: DocumentTypeStrings, index: number) {
    (<FormArray>this.formGroup.get(field)).removeAt(index);
    this.fieldChange();
  }

  protected onCancel() {
    if (!this.isChanged) return;
    const dialog: DialogRef = openDialog(
      this.dialogService, 'Вы уверены, что хотите отменить изменения?');
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.formGroup = this.createFormGroup();
        this.isChanged = false;
        this.cdRef.detectChanges();
      }
    });
  }

  protected onSave() {
    if (this.formGroup.invalid) {
      this.notificationsService.showError('Необходимо заполнить обязательные поля');
      return;
    }

    this.formGroup.value.documents = [
      ...this.formGroup.value.documents,
      ...this.formGroup.value.statements
    ];

    this.applicationConstructorService.saveApplicationConstructor(this.formGroup.value).subscribe({
      next: (response) => {
        this.isChanged = false;
        this.notificationsService.showSuccess('Сохранено');
        if (!this.applicationConstructor) {
          this.router.navigateByUrl(`mfc/applicationConstructor/constructorForm/${response.externalId}`);
        } else {
          //TODO: fix dto
          this.applyConstructorData(response);
        }
      },
      error: (error) => this.notificationsService.showError(error.error.Message ?? error),
    });
  }

  protected get systemNotificationsFormArray() {
    return <FormArray>this.formGroup.get('systemNotificationSettings');
  }

  protected addRow({sender}: AddEvent) {
    this.lastRowIndex = -1;
    this.isNew = true;
    this.editRow({
      sender,
      rowIndex: this.lastRowIndex,
      dataItem: undefined,
      isNew: this.isNew
    });
  }

  protected editRow({sender, dataItem, rowIndex, isNew}: EditEvent) {
    if (this.notificationsEditMode) {
      return;
    }
    this.notificationsEditMode = true;
    this.gridFormGroup = this.patchNotificationSettings(dataItem);

    this.lastRowIndex = rowIndex;
    isNew
      ? sender.addRow(this.gridFormGroup)
      : sender.editRow(this.lastRowIndex, this.gridFormGroup);
  }

  protected saveRow({sender, isNew}: SaveEvent) {
    isNew
      ? this.systemNotificationsFormArray.push(this.gridFormGroup)
      : this.systemNotificationsFormArray.get(`${this.lastRowIndex}`)?.patchValue(this.gridFormGroup.value);
    this.notificationsEditMode = false;

    sender.closeRow(this.lastRowIndex);
    this.isNew = false;
    this.lastRowIndex = undefined;
    this.gridFormGroup = new FormGroup({});
    this.fieldChange();
  }

  protected removeRow({rowIndex}: RemoveEvent) {
    this.systemNotificationsFormArray.removeAt(rowIndex);

    this.fieldChange();
  }

  protected cancelChanges(sender: GridComponent) {
    sender.closeRow(this.lastRowIndex);
    this.isNew = false;
    this.lastRowIndex = undefined;
    this.gridFormGroup = new FormGroup({});
    this.notificationsEditMode = false;
  }

  ngOnDestroy() {
    this.checkChangesSubscription$.unsubscribe();
  }
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { StateExamCommissionModel } from '../../../models/gia/stateexamcommission/stateexamcommission.model';
import { StateExamCommissionService } from '../../../services/gia/stateExamCommission.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { Router } from '@angular/router';
import { saveAs } from '@progress/kendo-file-saver';
import { DictDto, StudyYearDto } from '../../../models/gia/dicts/dicts.model';
import { DictsService } from '../../../services/gia/dicts.service';
import { GiaUserAccessService } from '../../../services/useraccess/gia-user-access.service';
import { GiaTabsEnum } from '../../../models/gia/enums/giaTabs.enum';
import { PreviousStateExamCommissionModel } from '../../../models/gia/stateexamcommission/stateexamcommission.model';
import moment from 'moment';
import { PagerSettings } from '@progress/kendo-angular-grid';
import { dictPagerSettings } from '../../../models/dicts/pagerSettings.model';

@Component({
  selector: 'app-state-exam-commission',
  templateUrl: './state-exam-commission.component.html',
  styleUrls: ['./state-exam-commission.component.scss'],
})
export class StateExamCommissionComponent implements OnInit {
  public allFilial: DictDto[] = [];
  public trainingLevels: DictDto[] = [];
  public filial: string | null = '';
  public yearAdmissionList: StudyYearDto[] = [];
  public editable?: boolean;
  public trainingLevel: string | null = '';
  public yearAdmission?: number;
  public stateExamCommissionFilters: { yearAdmission: number; trainingLevel: string; filial: string } = { yearAdmission: 0, trainingLevel: '', filial: '' };

  public filters: any;
  public stateExamCommissionList: StateExamCommissionModel[] = [];
  public pageSize = 20;
  public loading: boolean = false;
  public opened = false;
  public previousOrders: PreviousStateExamCommissionModel[] = [];
  public previousOrderId: string = '';

  public pagerSettings: PagerSettings = dictPagerSettings;

  constructor(
    private dictService: DictsService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: GiaUserAccessService,
    private router: Router,
    private stateExamCommissionService: StateExamCommissionService,
  ) {}

  ngOnInit(): void {
    this.getAccessLevel();
    this.getLocalFilters();
  }

  public getLocalFilters() {
    this.filters = localStorage.getItem('stateExamCommissionFilters');
    if (this.filters) {
      this.stateExamCommissionFilters = JSON.parse(this.filters);
      this.yearAdmission = this.stateExamCommissionFilters.yearAdmission;
      this.trainingLevel = this.stateExamCommissionFilters.trainingLevel;
      this.filial = this.stateExamCommissionFilters.filial;
    }
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        this.editable = response.giaSections?.some((_) => _ === GiaTabsEnum.состав_ГЭК || _ === null);
        this.getAllFilial();
      },
      error: () => {},
    });
  }

  public getAllFilial() {
    this.dictService.getFilials().subscribe((response) => {
      this.allFilial = response;
      if (this.filters == null) this.filial = this.allFilial[0].id!.toString();
      this.getAllTrainingLevel();
    });
  }

  public getAllTrainingLevel() {
    this.dictService.getStudentTrainingLevels().subscribe((response) => {
      this.trainingLevels = response;
      if (this.filters == null) this.trainingLevel = this.trainingLevels[0].id!.toString();
      this.getYearAdmissionList();
    });
  }

  public getYearAdmissionList() {
    this.dictService.getStudyYears().subscribe((response) => {
      this.yearAdmissionList = response;
      if (this.filters == null) this.yearAdmission = this.yearAdmissionList[0].studyYear;
      this.onValueChange();
    });
  }

  public onValueChange(value?: string | number | null) {
    const object = {
      filial: this.filial,
      trainingLevel: this.trainingLevel,
      yearAdmission: this.yearAdmission,
    };
    localStorage.setItem('stateExamCommissionFilters', JSON.stringify(object));
    this.getStateExamCommissionList();
  }

  onPreviousOrderValueChange(value: string) {
    this.previousOrderId = value;
  }

  public getStateExamCommissionList() {
    if (this.filial && this.trainingLevel && this.yearAdmission) {
      this.loading = true;
      this.stateExamCommissionService.getList(this.filial, this.trainingLevel, this.yearAdmission).subscribe((response) => {
        this.stateExamCommissionList = response;
        this.loading = false;
      });
    }
  }

  public addStateExamCommission() {
    this.router.navigate([`/gia/stateExamCommission/add/`]);
  }

  public onRowClick(event: any): void {
    this.router.navigate([`/gia/stateExamCommission/add/${event.dataItem.id}`]);
  }

  public downloadFile(id: string, orderNumber: string, orderDate: Date, orderType: string): void {
    const date = moment(orderDate).format('DD-MM-yyyy');
    const fileName = `${orderType} № ${orderNumber} от ${date}`;
    this.stateExamCommissionService.getFile(id).subscribe({
      next: (value) => {
        saveAs(value, fileName as string);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }

  public copy() {
    this.stateExamCommissionService.addPreviousOrder(this.previousOrderId).subscribe({
      next: () => {
        this.getStateExamCommissionList();
        this.notificationService.showSuccess('Сохранено');
      },
      error: () => {
        this.notificationService.showError('Не удалось изменить запись');
      },
    });
    this.opened = false;
  }

  public openDialog() {
    this.opened = true;
    if (this.filial && this.trainingLevel && this.yearAdmission) {
      this.stateExamCommissionService.getPreviousList(this.filial, this.trainingLevel, this.yearAdmission).subscribe((response) => {
        this.previousOrders = response;
      });
    }
  }

  public closeDialog(): void {
    this.opened = false;
  }
}

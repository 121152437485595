import {Component, OnDestroy, OnInit} from '@angular/core';
import {DictPublicationSubType} from "../../../../../models/publications/dict/publicationSubType.model";
import {DictPublicationType} from "../../../../../models/publications/dict/publicationType.model";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {PublicationsEditFormService} from "../../../../../services/science/publications/editForm.service";
import {DictScientometricBase} from "../../../../../models/publications/dict/scientometricBase.model";
import {ActivatedRoute, Router} from "@angular/router";
import {PublicationsService} from "../../../../../services/science/publications/publications.service";
import {PublicationForm} from "../../../../../models/publications/editForm/publication.model";
import {AllowedProperty} from "../../../../../models/publications/editForm/property.model";
import {TypesService} from "../../../../../services/science/publications/types.service";
import {SubTypeService} from "../../../../../services/science/publications/subTypes.service";
import {ScientometricBasesService} from "../../../../../services/science/publications/scientometric-bases.service";
import {StructureSubdivisionsService} from "../../../../../services/science/publications/structureSubdivisions.service";
import {DictStructureSubdivision} from "../../../../../models/publications/dict/structureSubdivision.model";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {
  ArticlesDigestDataSources,
  DissertationDataSources,
  KendoProperties,
  PublicationEditFormTabs,
  PublicationProperties
} from "../../../../../models/publications/editForm/editForm.enums";
import {PublicationsDictsService} from "../../../../../services/science/publications/dicts.service";
import {DropDownFilterSettings, VirtualizationSettings} from "@progress/kendo-angular-dropdowns";
import {lastValueFrom, Subscription} from 'rxjs';
import {PropertiesService} from "../../../../../services/science/publications/properties.service";
import {PublicationsDict} from "../../../../../models/publications/dict/dict.model";
import {PublicationsWorkFormService} from "../../../../../services/science/publications/workform.service";
import {DictWorkForm} from "../../../../../models/publications/dict/workForm.model";
import {AccessRights, ConditionalRole} from "../../../../../models/useraccess/publications/publicationsUserAccess";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {StatusesService} from "../../../../../services/science/publications/statuses.service";

@Component({
  selector: 'publications-edit-form-mainInfo',
  templateUrl: './publications-edit-form-main-info.component.html',
  styleUrls: ['./publications-edit-form-main-info.component.scss']
})
export class PublicationsEditFormMainInfoComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    private publicationsService: PublicationsService,
    private typesService: TypesService,
    private subTypesService: SubTypeService,
    private scientometricBasesService: ScientometricBasesService,
    private structureSubdivisionsService: StructureSubdivisionsService,
    private editFormService: PublicationsEditFormService,
    private statusesService: StatusesService,
    private propertiesService: PropertiesService,
    private dictService: PublicationsDictsService,
    private workFormService: PublicationsWorkFormService,
    private userAccessService: PublicationsUserAccessService
  ) { }

  public years = this.getYears();
  public types: DictPublicationType[] = [];
  public subTypes: DictPublicationSubType[] = [];
  public allowedSubTypes: DictPublicationSubType[] = [];
  public scientometricBases: DictScientometricBase[] = [];
  public subdivisions: DictStructureSubdivision[] = [];
  public degreeSpecialities: PublicationsDict[] = [];
  public workForms: DictWorkForm[] = [];

  private publicationId!: string;
  public currentPublication!: PublicationForm;
  public isNew: boolean = false;
  private isUnsaved: boolean = false;
  public isModerator: boolean = this.userAccessService.currentUserAccess$.value.conditionalRole === ConditionalRole.Moderator;
  private degreeSpeciality: AllowedProperty = new AllowedProperty();
  private edition: AllowedProperty = new AllowedProperty();

  public formGroup: FormGroup = this.getFormGroup();
  private saveSubscription$!: Subscription;
  private changeSubscription$!: Subscription;
  private statusSubscription$!: Subscription;
  public loading: boolean = false;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public filterVirtualization: boolean | VirtualizationSettings = {
    itemHeight: 28
  };
  protected readonly KendoProperties = KendoProperties;

  async ngOnInit() {
    this.publicationId = this.activatedRoute.snapshot.params['publicationId'];
    this.isNew = this.publicationId === undefined;

    if (!this.isNew) {
      await this.getPublication();
    }

    this.getDicts();
    this.getWorkForms();

    this.subscribe();
  }

  private getYears() {
    const startYear = 1933;
    const currentYear = new Date().getFullYear();
    return Array.from({length: currentYear - startYear}, (_, i) => currentYear - i);
  }

  private subscribe() {
    this.saveSubscription$ = this.editFormService.save$.subscribe(value => {
      if (PublicationEditFormTabs.MainInfo === value?.name) this.saveChanges();
    });
    this.changeSubscription$ = this.editFormService.change$.subscribe(value => {
      if (value === PublicationEditFormTabs.MainInfo) {
        this.editFormService.setIsSaved(!this.isUnsaved);
      }
    });
    this.statusSubscription$ = this.statusesService.statusData$.subscribe((value) => {
      if (value === PublicationEditFormTabs.MainInfo) {
        this.getPublication();
      }
    });
  }

  private getFormGroup() {
    return new FormGroup({
      id: new FormControl(this.currentPublication?.id),
      typeId: new FormControl(this.currentPublication?.typeId, Validators.required),
      subTypeId: new FormControl(this.currentPublication?.subTypeId, Validators.required),
      departmentId: new FormControl(this.currentPublication?.departments, Validators.required),
      name: new FormControl(this.currentPublication?.name, Validators.required),
      year: new FormControl(this.currentPublication?.year, Validators.required),
      categories: new FormControl(this.currentPublication?.categories, Validators.required),
      workFormId: new FormControl(this.currentPublication?.workFormId, Validators.required),
      properties: new FormArray([])
    });
  }

  private async getPublication() {
    this.loading = true;
    await lastValueFrom(this.publicationsService.getPublication(this.publicationId))
      .then((value) => {
        this.currentPublication = value;
        this.formGroup = this.getFormGroup();
        this.getProperties();
      });
    this.loading = false;
  }

  private getDicts() {
    this.typesService.getTypes()
      .subscribe(value => this.types = value);
    this.subTypesService.getSubTypes().subscribe(value => {
      this.subTypes = value;
      this.allowedSubTypes = value.filter(item => this.currentPublication && item.typeId === this.currentPublication.typeId);
    });
    this.scientometricBasesService.getScientometricBases()
      .subscribe(value => this.scientometricBases = value);
    this.structureSubdivisionsService.getStructureSubdivisions()
      .subscribe(value => this.subdivisions = value);
  }

  private saveChanges() {
    if (this.formGroup.valid) {
      const formGroup: FormGroup = this.propertiesService.convertFormGroupValuesForRequest(this.formGroup);
      this.publicationsService.savePublication(formGroup.value).subscribe({
        next: (value) => {
          this.notificationsService.showSuccess('Успешно');
          this.isUnsaved = false;
          if (value !== true) {
            this.router.navigate([`publications/newPublication/${value}`]);
          }
          this.editFormService.currentTab$.next(PublicationEditFormTabs.AuthorsAndThematicRubrications);
        },
        error: (error) => {
          this.notificationsService.showError(error)
        }
      });
    }
    else {
      this.notificationsService.showError('Заполните все необходимые поля')
    }
  }

  public onTypeChange(id: string) {
    this.isUnsaved = true;
    this.formGroup.get('subTypeId')?.patchValue(null);
    this.allowedSubTypes = this.subTypes.filter(item => item.typeId === id);
    this.editFormService.currentType$.next(id);
    this.getProperties(true);
  }

  public onSubTypeChange() {
    this.isUnsaved = true;
    this.getProperties(true);
  }

  public onFieldChange() {
    this.isUnsaved = true;
  }

  public getProperties(typeChanged?: boolean) {
    const formArray: FormArray = <FormArray>this.formGroup.get('properties');
    const currentFormArrayValue: any[] = formArray.value;
    if (this.formGroup.value.typeId && this.formGroup.value.subTypeId) {
      this.propertiesService.getAllowedProperties({typeId: this.formGroup.value.typeId, subTypeId: this.formGroup.value.subTypeId, categoryId: PublicationProperties.MainInfo})
        .subscribe(async value => {
          formArray.clear();
          this.mapFormArray(value, formArray, currentFormArrayValue);
          await this.handleFormArrayValues(value, formArray, typeChanged);
        });
    }
  }

  private mapFormArray(properties: AllowedProperty[], formArray: FormArray, currentFormArrayValues: any[]) {
    properties.forEach((item: AllowedProperty) => {
      const disabled = !this.isNew && this.isDynamicPropertyDisabled(item.allowModeratorOnly);
      item.value = currentFormArrayValues.find((currentProperty) => currentProperty.id === item.id)?.value;
      formArray.push(this.propertiesService.patchDynamicValues(item, disabled));
    });
  }

  private async handleFormArrayValues(properties: AllowedProperty[], formArray: FormArray, typeChanged?: boolean) {
    for (const item of properties) {
      const index: number = properties.indexOf(item);

      switch(item.name) {
        case DissertationDataSources.DegreeSpeciality:
          this.degreeSpeciality = item;
          await this.onYearChange(this.formGroup.value.year, true);
          item.data = this.degreeSpecialities;
          break;

        case ArticlesDigestDataSources.Edition:
          this.edition = item;
          this.onWorkFormChange(this.formGroup.value.workFormId, true);
          break;
      }

      if (item.dictId && item.name !== DissertationDataSources.DegreeSpeciality) {
        await lastValueFrom(this.dictService.getDictData(item.dictId, true)).then(async (dictData) => {
          item.data = dictData;
          formArray.at(index).patchValue({data: dictData});
        });
      }

      if (!this.isNew && !typeChanged && this.currentPublication.properties.length > 0) {
        const property = this.currentPublication.properties.find(property => property.id === item.id);
        await this.propertiesService.convertFormGroupValuesForView(formArray, item, index, property);
      }
    }
  }

  public get properties(): FormArray {
    return <FormArray> this.formGroup.get('properties');
  }

  public async onYearChange(year: any, changeType = false) {
    this.isUnsaved = changeType ? this.isUnsaved : true;
    const formArray: FormArray = <FormArray>this.formGroup.get('properties');
    const formArrayValue: any[] = this.formGroup.get('properties')?.value;
    const degreeSpecialityIndex = formArrayValue.findIndex((item) => item.id === this.degreeSpeciality.id);

    if (degreeSpecialityIndex < 0) return;

    if (year <= 2021) {
      await this.changeDegreeSpecialities(2021, formArray, degreeSpecialityIndex, this.degreeSpeciality.dictId);
    }
    else if (year > 2021 || year === null) {
      await this.changeDegreeSpecialities(2022, formArray, degreeSpecialityIndex, this.degreeSpeciality.dictId);
    }
  }

  public async changeDegreeSpecialities(year: number, formArray: FormArray, degreeSpecialityIndex: number, dictId: string = '') {
    await lastValueFrom(this.dictService.getDegreeSpecialities(dictId, year)).then((response) => {
      this.degreeSpecialities = response;
      formArray.at(degreeSpecialityIndex).patchValue({data: this.degreeSpecialities});
    })
  }

  public onWorkFormChange(workFormId: string, changeType = false) {
    this.isUnsaved = changeType ? this.isUnsaved : true;
    const formArray: FormArray = <FormArray>this.formGroup.get('properties');
    const formArrayValue: any[] = this.formGroup.get('properties')?.value;
    const editionIndex = formArrayValue.findIndex((item) => item.id === this.edition.id);

    if (this.workForms.find(item => item.id === workFormId)?.name === 'Печатная') {
      formArray.at(editionIndex).get('value')?.setValidators(Validators.required);
      formArray.at(editionIndex).get('required')?.setValue(true);
    } else {
      formArray.at(editionIndex).get('value')?.setValidators(null);
      formArray.at(editionIndex).get('required')?.setValue(false);
    }
  }

  public getWorkForms() {
    this.workFormService.getWorkForms().subscribe((value) => this.workForms = value)
  }

  public isDynamicPropertyDisabled(allowModeratorOnly: boolean): boolean {
    return this.propertiesService.disableDynamicProperty(this.currentPublication, this.isModerator, allowModeratorOnly);
  }

  public isStaticPropertyDisabled(): boolean {
    return this.propertiesService.disableStaticProperty(this.currentPublication, this.isModerator);
  }

  public convertTextCase() {
    const name: string = this.formGroup.get('name')?.value;
    if (name.match(/((?<=.{1})([А-Я]|[A-Z]))+/g)) {
      this.formGroup.get('name')?.patchValue(name[0].toUpperCase() + name.slice(1).toLowerCase());
      this.isUnsaved = true;
    }
  }

  private unsubscribe() {
    this.saveSubscription$.unsubscribe();
    this.changeSubscription$.unsubscribe();
    this.statusSubscription$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.editFormService.save$.next(null);
    this.unsubscribe();
  }

  protected readonly AccessRights = AccessRights;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DepartmentName, Faculty } from '../../models/contingent/departmentname.model';
import {BehaviorSubject, Observable, skip, switchMap, throwError} from 'rxjs';
import { environment } from '../../../environments/environment';
import {DepartmentTree, DepartmentTreeRequest} from 'src/app/models/contingent/departmenttree';
import {EducationPlan} from "../../models/contingent/educationplan.model";
import {catchError} from "rxjs/operators";
import {Addresses} from "../../models/contingent/addresses.model";
import {TreeCommonModel} from "../../models/middlecontrol/trees.model";
import {MiddleControlTreeQuery} from "../../models/middlecontrol/query.model";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.department}`;

  public departmentTree$ = new BehaviorSubject<DepartmentTree[]>([]);
  protected triggerTreeFetch$ = new BehaviorSubject<DepartmentTreeRequest>(new DepartmentTreeRequest);

  constructor(private http: HttpClient) {
    this.triggerTreeFetch$.pipe(
      skip(1),
      switchMap((request) => {
        return this.getAllTreeDepartment(request)
      }))
      .subscribe((response) => {
        this.departmentTree$.next(response);
      });
  }

  //Get all StudyLevel
  public getAllDepartment(): Observable<DepartmentName[]> {
    return this.http.get<DepartmentName[]>(this.baseUrl+ '/GetAllDepartment');
  }

  public getDepartmentbyId(id: string): Observable<DepartmentName> {
    return this.http.get<DepartmentName>(this.baseUrl + '/GetDepartmentById/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get all StudyLevel
  public getAllFaculty(editable:number): Observable<Faculty[]> {
    return this.http.get<Faculty[]>(this.baseUrl+ '/GetAllFaculty/' + editable);
  }

  //Get all TreeDepartment
  public getAllTreeDepartment(request: DepartmentTreeRequest): Observable<DepartmentTree[]> {
    return this.http.post<DepartmentTree[]>(this.baseUrl + '/GetAllDepartmentTree/', request)
      .pipe(catchError(this.handleError));
  }

  public updateDepartmentTree(request: DepartmentTreeRequest) {
    this.triggerTreeFetch$.next(request);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {lastValueFrom} from 'rxjs';
import {Role} from '../models/useraccess/role';
import {TokenStorageService} from '../services/token.service';
import {PublicationsUserAccessService} from "../services/useraccess/publications-user-access.service";
import {AccessRights, PublicationCurrentUserAccess} from "../models/useraccess/publications/publicationsUserAccess";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class PublicationsRoleGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: PublicationsUserAccessService,
    private location: Location,
    private router: Router) {
  }

  private routes: ({ name: string, route: string })[] = [
    {name: 'author', route: 'publications/authors'},
    {name: 'report', route: 'publications/reports'},
    {name: 'dicts', route: 'publications/dictPosts'},
    {name: 'accessSettings', route: 'publications/userAccess'}
  ];

  private redirect(value: PublicationCurrentUserAccess): boolean {
    if ((value.myPublication || value.allPublication !== AccessRights.No)) {
      return true;
    }
    const availableModule = Object.entries(value).find(([key, value]) => {
      if ((value > AccessRights.No || value === true) && key !== 'isAdmin') {
        return key;
      }
      return null;
    });
    if (availableModule) {
      const path = this.routes.find((item) => item.name === availableModule[0])?.route;
      this.router.navigate([path]);
      return true;
    }
    localStorage.setItem('last_url', this.location.path())
    return false;
  }

 async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise <boolean> {
   const token = this.tokenStore.getAuthToken();
   const path = route.routeConfig?.path;

   // Checking if token is not null
   if (token !== null) {
     const user = this.jwtHelper.decodeToken(token!);
     const roles = user?.role;
     if (Object.keys(this.userAccessService.currentUserAccess$.value).length === 0) {
       await lastValueFrom(this.userAccessService.getCurrentUserAccess()).then(value => {
         this.userAccessService.currentUserAccess$.next(value);
       });
     }
     // Admin accesses page without any restrictions
     if (roles?.includes(Role.Admin)) {
       return true;
     }
     // Check if user has publications role
     if (roles?.includes(Role.Publication)) {
       // My and all publications access
       if (path === 'publications') {
         return this.redirect(this.userAccessService.currentUserAccess$.value);
       }
       // Report access
       if (path === 'publications/reports') {
         return this.userAccessService.currentUserAccess$.value.report;
       }
       // New publication and author for publication adding access
       if (path === 'publications/newPublication' || path?.includes('addAuthorForPublication')) {
         return this.userAccessService.currentUserAccess$.value.myPublication || this.userAccessService.currentUserAccess$.value.allPublication === AccessRights.Write;
       }
       // Publications access
       if (path?.includes('newPublication')) {
         return this.userAccessService.currentUserAccess$.value.myPublication || this.userAccessService.currentUserAccess$.value.allPublication !== AccessRights.No;
       }
       // Authors access
       if (path === 'publications/authors') {
         return this.userAccessService.currentUserAccess$.value.author !== AccessRights.No;
       }
       // Authors modifying access
       if (path === 'publications/authors/addAuthor' ||
         path?.includes('authorCard') ||
         path?.includes('summaryInfo')) {
         return this.userAccessService.currentUserAccess$.value.author === AccessRights.Write;
       }
       // Dictionaries access
       if (path?.includes('dict')) {
         return this.userAccessService.currentUserAccess$.value.dict !== AccessRights.No;
       }
       return true;
     }
   }
   alert('Недостаточно прав для доступа на эту страницу');
   localStorage.setItem('last_url', this.location.path())
   this.router.navigate([`/profile`]);
   return false;
  }
}

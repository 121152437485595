import {Component, OnInit} from '@angular/core';
import {DictTrainingLevel, DepartmentsModel} from 'src/app/models/gia/diplomhome.model';
import {TypeDisciplineModel, DisciplineModel} from '../../../models/gia/formationdiploma.model';
import {RemoveEvent} from '@progress/kendo-angular-grid';
import {FormationDiplomaService} from '../../../services/gia/formationdiploma.service';
import {DiplomHomeService} from 'src/app/services/gia/diplomhome.service';
import {DictDto, StudyYearDto} from "../../../models/gia/dicts/dicts.model";
import {DictsService} from "../../../services/gia/dicts.service";
import {ConstructorGiaProtocolService} from '../../../services/gia/constructorgiaprotocol.service';
import {StandardModel,
        StructTabDto,
        StructPropertyDto,
        StructTabShortModel,
        DictsModel,
        StructDisciplineDto,
        GiaDisciplineListModel} from '../../../models/gia/constructorgiaprotocol.model';
import {boolOptions} from '../../../models/gia/enums/booloptions.enum';
import {DepartmentType} from '../../../models/gia/enums/departmentType.enum';
import {DataTypeEnum} from '../../../models/gia/enums/dataType.enum';
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {openDialog} from "../../../helpers/dialogHelper";
import {Router} from "@angular/router";
import {SelectEvent} from "@progress/kendo-angular-layout";
import {Types, typeDict, filterName, columnName} from '../../../models/gia/enums/dataType.enum';
import {staticDictEnum} from '../../../models/gia/enums/dictsStatic.enum';
import { makeVerticalSpacingEqualIcon } from '@progress/kendo-svg-icons';
import {PagerPosition, GridComponent, GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {PagerType} from '@progress/kendo-angular-pager';

@Component({
    selector : 'app-constructorgiaprotocol',
    templateUrl: './constructor-giaprotocol.component.html',
    styleUrls : ['./constructor-giaprotocol.component.scss']
})

export class ConstructorGiaProtocolComponent implements OnInit{

    public trainingLevels: DictTrainingLevel[] = [];
    public allFilial: DictDto[] = [];
    public yearAdmissionList: StudyYearDto[] = [];
    public departments: DepartmentsModel[] = [];
    public standards: StandardModel[] = [];

    public tabsList: StructTabShortModel[] = [];
    public protocolGIA: boolean = true;

    public disciplines: DisciplineModel[] = [];
    public filterDisciplines: DisciplineModel[] = [];
    public selectionDisciplines: StructDisciplineDto[] = [];

    public loading: boolean = false;
    public opened: boolean = false;

    public disciplinesType: TypeDisciplineModel[] = [];
    public typeDiscipline: string = "";
    public discipline: string = "";
    public changeStateExam: boolean = false;

    public boolOptions = boolOptions;
    public dataTypes = DataTypeEnum;
    public types = Types;

    public tabData: StructTabDto = new StructTabDto;
    public newTabName: string = "";
    public tabName: string = "";
    public changeTab: boolean = false;

    public filial: string = "";
    public trainingLevel: string = "";
    public filialStateExam: string = "";
    public levelStateExam: string = "";
    public studyYear: number | null = null;
    public faculty: string | null = null;
    public standard: string | null = null;

    public filterStateExam: boolean = false;
    public filterGia: boolean = false;
    public validate: boolean = false;

    public dicts: DictsModel[] = [];
    public dynamicDicts: DictsModel[] = [];
    public dictsStatic: DictsModel[] = staticDictEnum;
    public typeDict = typeDict;

    public pageSize = 10;
    public type: PagerType = 'numeric';
    public buttonCount = 3;
    public previousNext = true;
    public position: PagerPosition = 'bottom';
    public info = true;
    public skip = 0;

    public gridView!: GridDataResult;
    public filterName = filterName;
    public columnName = columnName;

    public editTab: boolean = false;

    public tooltip = 'Поле может содержать только латинские буквы, знаки "-", "_" и цифры.'
    private filtersExam: string | null = null;

    constructor(
        private formationDiplomaService: FormationDiplomaService,
        private diplomHomeService: DiplomHomeService,
        private dictService: DictsService,
        private constructorGiaService: ConstructorGiaProtocolService,
        private notificationService: NotificationsService,
        private dialogService: DialogService,

        private router: Router,
    ) {}

    ngOnInit() {
        this.getAllFilial();
        this.getTrainingLevels();
        this.getAllDicts();

        this.getYearAdmissionList();
        this.getTypesDiscipline();

        this.getLocalStorage();
        document.addEventListener('click', this.stopClick);        
    }

    public ngOnDestroy() {
        document.removeEventListener('click', this.stopClick);
    }

    public stopClick(event: any) {
        const target = event.target;
        if (target.outerHTML.includes('filterdisabled')) {
            alert("Сохраните изменения");
        }
    }

    private getLocalStorage() {
        const getFilterGia = localStorage.getItem('protocolGIA');
        if (getFilterGia) {
            this.filial = JSON.parse(getFilterGia).filial;
            this.trainingLevel = JSON.parse(getFilterGia).trainingLevel;
            this.onChangeGia();
        }
        this.filtersExam = localStorage.getItem('constructorExams');
        if (this.filtersExam) {
            const exam = JSON.parse(this.filtersExam);
            this.filialStateExam = exam.filialStateExam;
            this.levelStateExam = exam.levelStateExam;
            this.studyYear = exam.studyYear;
            this.faculty = exam.faculty;
            this.standard = exam.standard;
            this.getFaculties(true);
            this.getStandards(true);
        }
    }

    public getAllFilial() {
        this.dictService.getFilials()
          .subscribe(response => {
              this.allFilial = response;
           });
    }

    public getTrainingLevels() {
        this.diplomHomeService.getTrainingLevel()
            .subscribe(response => {
                this.trainingLevels = response;
            })
    }

    public getAllDicts() {
        this.constructorGiaService.getDicts()
            .subscribe(response => {
                this.dynamicDicts = response;
                this.dicts = this.dictsStatic.concat(response);
            })
    }

    public getTabs(tabId?: string) {
      this.constructorGiaService.getTabs(this.trainingLevel, this.filial)
        .subscribe(response => {
          response == null ? this.tabsList = [] : this.tabsList = response;
          if (this.tabsList.length) {
            let tab = this.tabsList.find(el => el.id == tabId);
            if (!tab)
                tab = this.tabsList[0];

            this.tabName = tab.tabName;
            if (tab.id) this.onChangeTab(tab.id);
          }
          this.filterGia = true;
        })
    }

    public addTab() {
        if (this.changeTab) {
            this.notificationService.showError('Имеются несохраненные изменения.');
            return;
        }
        this.newTabName = "";
        this.opened = true;
    }
    
    public editTabName() {        
        this.editTab = true;
        const id = this.tabsList.find(el => el.tabName == this.tabName)?.id;
        if (id && this.changeTab) {
            this.notificationService.showError('Имеются несохраненные изменения.');
            this.editTab = false;
            return;            
        } else {
            this.newTabName = this.tabName;
            this.opened = true;
        }
    }

    private updateTabName() {        
      const data = this.tabsList.find(el => el.tabName == this.tabName);
      const id = data?.id;
      if (id) {
        data.tabName = this.newTabName;
        this.constructorGiaService.renameTab(data)
          .subscribe(response => {
            this.notificationService.showSuccess('Успешно.');
            this.getTabs(id);
          },
          error => {
            this.notificationService.showError(error);
          })
      } else {
           this.tabsList[this.tabsList.length - 1].tabName = this.newTabName;
           this.tabName = this.newTabName;
           this.tabData.name = this.newTabName;
        }
      this.editTab = false;
    }

    public onChange(value: string | number | boolean, dataItem?: StructPropertyDto, field?: string) {
        this.changeTab = true;
        if (field == columnName && dataItem) {
            if (value == this.types.title) dataItem.isRequired = false;
            if (value !== this.types.dict) {
                dataItem.dictionaryId = null;
                dataItem.staticDictEnum = null;
            }
        }

        if (dataItem && field == this.typeDict) {
          typeof value == 'number' ? dataItem.staticDictEnum = value : dataItem.staticDictEnum = null;
        }
    }

    public checkEnglish(value: string) {
        let char = /[a-zA-Z0-9\-\_]/;
        let array = Array.from(value)
        let test = array.some((el: any) => !char.test(el));
        return test;
    }

    public close(status: string): void {
        if (status == 'yes') {
          if (this.editTab) {
            this.updateTabName();
          } else {
                this.tabsList.push({id: null, tabName: this.newTabName});
                this.tabData = {
                    id: null,
                    name: this.newTabName,
                    isHidden: false,
                    isMarkIncluded: false,
                    displayPropertyGroups: []
                }
                this.addGroupField();
                this.notificationService.showSuccess('Добавлено');
                this.tabName = this.newTabName;
                this.changeTab = true;
          }
        }

        this.opened = false;
        this.newTabName = "";
        this.editTab = false;
    }

    public removeField(order: number, { dataItem }: RemoveEvent): void {
        const error = this.warning(order);
        if (!error) {
            const dialog: DialogRef = openDialog(this.dialogService, "Вы действительно хотите удалить данное поле?");
            dialog.result.subscribe((result) => {
                if (!(result instanceof DialogCloseResult) && result.text == "Да"){
                    const indexGroup = this.tabData.displayPropertyGroups.findIndex(el => el.order == order);
                    const indexField = this.tabData.displayPropertyGroups[indexGroup].displayProperties.findIndex(el => el.order == dataItem.order);
                    this.tabData.displayPropertyGroups[indexGroup].displayProperties.splice(indexField, 1);
                    this.changeTab = true;
                    this.notificationService.showSuccess('Поле удалено');
                }
            });
        }
    }

    public fieldUp(dataItem: StructPropertyDto, rowIndex: number, table: StructPropertyDto[]) {
        if (rowIndex == 0) return;
        const element = table.splice(rowIndex, 1);
        table.splice(rowIndex - 1, 0, element[0]);
        this.changeTab = true;
    }

    public fieldDown(dataItem: StructPropertyDto, rowIndex: number, table: StructPropertyDto[]) {
        if (rowIndex == table.length - 1) return;
        const element = table.splice(rowIndex, 1);
        table.splice(rowIndex + 1, 0, element[0]);
        this.changeTab = true;
    }

    public addGroupField() {
        let index = 0;
        this.tabData.displayPropertyGroups.length == 0 ? index = 0 : index = Math.max(...this.tabData.displayPropertyGroups.map(el => el.order));
        this.tabData.displayPropertyGroups.push({
            name: "",
            order: index + 1,
            displayProperties: [],
         })
        this.tabData.displayPropertyGroups[this.tabData.displayPropertyGroups.length - 1].displayProperties.push({
            name: "",
            printFormName: "",
            isRequired: false,
            dataTypeEnum: null,
            dictionaryId: null,
            order: 1,
        })
        this.changeTab = true;
    }

    public addField(order: number) {
        const error = this.warning(order);
        if (!error) {
            const indexGroup = this.tabData.displayPropertyGroups.findIndex(el => el.order == order);
            let indexField = 0;
            this.tabData.displayPropertyGroups[indexGroup].displayProperties.length == 1 ? indexField = 1 : indexField = Math.max(...this.tabData.displayPropertyGroups[indexGroup].displayProperties.map(el => el.order));
            this.tabData.displayPropertyGroups[indexGroup].displayProperties.push({
                name: "",
                printFormName: "",
                isRequired: false,
                dataTypeEnum: null,
                dictionaryId: null,
                order: indexField + 1,
            })
            this.changeTab = true;
        }
    }

    public deleteGroup(order: number) {
        const error = this.warning(order);
        if (this.tabData.displayPropertyGroups.length == 1) {
            this.notificationService.showError('Невозможно удалить. Вкладка должна содержать хотя бы одну группу полей.');
            return;
        }

        if (!error) {
            const dialog: DialogRef = openDialog(this.dialogService, "Вы действительно хотите удалить группу полей?");
            dialog.result.subscribe((result) => {
                if (!(result instanceof DialogCloseResult) && result.text == "Да"){
                    const indexGroup = this.tabData.displayPropertyGroups.findIndex(el => el.order == order);
                    this.tabData.displayPropertyGroups.splice(indexGroup, 1);
                    this.changeTab = true;
                }
            })
        }
    }

    private warning(order: number) {
        if (!order || order < 0) {
            this.notificationService.showError('Введите корректный порядковый номер группы.');
            return true;
        }

        const orders = [...new Set(this.tabData.displayPropertyGroups.map(item => item.order))];
        if  (orders.length !== this.tabData.displayPropertyGroups.length) {
            this.notificationService.showError('Порядковые номера групп не должны повторяться.');
            return true;
        }

        return false;
    }

    public validateOrder(order: number) {
        return this.tabData.displayPropertyGroups.filter(el => el.order == order).length > 1;
    }

    public saveStructure() {
        if (this.tabData.displayPropertyGroups.some(el => el.name.trim() == "") ||
            this.tabData.displayPropertyGroups.some(el => el.order == null || el.order < 0) ||
            this.tabData.displayPropertyGroups.some(el => el.displayProperties.some(item => item.name.trim() == "" || item.printFormName.trim() == "" || this.checkEnglish(item.printFormName)
                                            || item.dataTypeEnum == null || item.dataTypeEnum == this.types.dict && item.dictionaryId == null && item.staticDictEnum == null))) {
            this.notificationService.showError('Заполните обязательные поля корректно.');
            this.validate = true;
            return;
        }

        this.tabData.displayPropertyGroups.forEach(el => {
            el.displayProperties.filter(item => item.staticDictEnum).forEach(item => item.dictionaryId = null);
        });

        const data = {
            id: this.tabData.id,
            filialId: this.filial,
            trainingLevelId: this.trainingLevel,
            name: this.tabData.name,
            isHidden: this.tabData.isHidden,
            isMarkIncluded: this.tabData.isMarkIncluded,
            displayPropertyGroups: this.tabData.displayPropertyGroups,
        }
        this.changeTab = false;
        this.validate = false;

        this.constructorGiaService.saveTab(data)
            .subscribe(response => {
                this.validate = false;
                this.notificationService.showSuccess('Вкладка сохранена.')
                this.onChangeTab(response.toString());
                this.getTabs(response.toString());
            },
            error => {
                this.notificationService.showError(error);
                const tabId = this.tabsList.find(el => el.tabName == this.tabName)?.id;
                if (tabId) this.onChangeTab(tabId);
            })
    }

    public cancelProtocol() {
        if (this.changeTab || this.changeStateExam) {
            const dialog: DialogRef = openDialog(this.dialogService, "Имеются несохраненные изменения, вы действительно хотите покинуть страницу?", 450, 220, 250);
            dialog.result.subscribe((result) => {
                if (!(result instanceof DialogCloseResult) && result.text == "Да"){
                  this.router.navigateByUrl(`/gia/diploma`);
                }
            })
        } else this.router.navigateByUrl(`/gia/diploma`);
    }

    public onChangeGia(value?: string) {
        if (this.filial !== "" && this.trainingLevel !== "") {
          this.getTabs();       
          
          let filters= {filial: this.filial, trainingLevel: this.trainingLevel};          
          localStorage.setItem('protocolGIA', JSON.stringify(filters));
        }
    }

    public onChangeTab(value: string, inList?: boolean) {
        let tabId: string | null | undefined = "";
        inList ? tabId = this.tabsList.find(el => el.tabName == value)?.id : tabId = value;

        if (tabId) {
          this.constructorGiaService.getStructureProtocol(tabId)
            .subscribe(response => {
                this.tabData = response;
                this.tabData.displayPropertyGroups.forEach(el => el.displayProperties.forEach(item => {
                  if (item.staticDictEnum) item.dictionaryId = item.staticDictEnum}
                ));
                this.tabName = this.tabData.name;
            })
        }

    }

    public deleteTab() {
        const dialog: DialogRef = openDialog(this.dialogService, "Вы действительно хотите удалить вкладку? После удаления ее невозможно восстановить.", 450, 220, 250);
        dialog.result.subscribe((result) => {
          if (!(result instanceof DialogCloseResult) && result.text == "Да"){
            const id = this.tabsList.find(el => el.tabName == this.tabName)?.id;
            if (id) {
                this.constructorGiaService.deleteTab(id)
                .subscribe(response => {
                  this.notificationService.showSuccess('Удалено');
                  this.onChangeGia();
                })
            }
            if (id == null) {
              const index = this.tabsList.findIndex(el => el.id == null);
              this.tabsList.splice(index, 1);
              this.notificationService.showSuccess('Удалено');
            }
            if (this.tabsList.length !== 0) this.tabName = this.tabsList[0].tabName;
            this.changeTab = false;
          }
        })
    }

    public checkField(value: string | number) {
        return (value == null || value.toString().trim() == "") && this.validate;
    }

    //-------------------Государственные экзамены-----------------

    public getYearAdmissionList() {
        this.dictService.getStudyYears()
          .subscribe(response => {
            this.yearAdmissionList = response;
          });
    }

    public getFaculties(filter?: boolean) {
        this.faculty = null;
        this.departments = [];
        if (this.studyYear) this.diplomHomeService.getDepartmentsByStudents(DepartmentType.facultie, this.levelStateExam, this.studyYear)
            .subscribe(response => {
              this.departments = response;
              if (this.filtersExam && filter) this.faculty = JSON.parse(this.filtersExam).faculty;
              this.getSavedDisciplines();
            })
    }

    public getStandards(filter?: boolean) {
        this.standards = [];
        this.standard = null;
        this.constructorGiaService.getAllStandards(this.levelStateExam)
            .subscribe(response => {
              this.standards = response;
              if (this.filtersExam && filter) this.standard = JSON.parse(this.filtersExam).standard;
              this.getSavedDisciplines();
            })
    }

    public getTypesDiscipline() {
        this.formationDiplomaService.getTypesDiscipline()
            .subscribe(response => {
              this.disciplinesType = response;
            })
    }

    public getDiscipline() {
        this.loading = true;
        this.formationDiplomaService.getDisciplines(this.typeDiscipline, this.discipline)
            .subscribe(response => {
              this.disciplines = response;
              this.loading = false;
              this.filterDisciplines = this.disciplines.slice();
              this.filterDisciplines.sort((a, b) => a.name.localeCompare(b.name));
              this.getFilterDisciplines();
            })
    }

    private getFilterDisciplines() {
        const names = [...new Set(this.selectionDisciplines.map(el => el.disciplineName))];
        this.filterDisciplines = this.filterDisciplines.filter(el => !names.includes(el.name));
        this.pageChange({skip: 0, take: this.pageSize});
    }

    public editHandler(dataItem: DisciplineModel) {
        this.selectionDisciplines.push({'dictDisciplineId': dataItem.id, 'disciplineName': dataItem.name});

        const index = this.filterDisciplines.findIndex(el => el.id == dataItem.id);
        this.filterDisciplines.splice(index, 1);
        this.changeStateExam = true;
        this.pageChange({skip: this.skip, take: this.pageSize});
    }


    public deleteDiscipline(dataItem: StructDisciplineDto) {
        if (this.disciplines.some(el => el.name == dataItem.disciplineName)) {
            this.filterDisciplines.push({'id': dataItem.dictDisciplineId, 'name': dataItem.disciplineName});
            this.filterDisciplines.sort((a, b) => a.name.localeCompare(b.name));
        }

        const index = this.selectionDisciplines.findIndex(el => el.dictDisciplineId == dataItem.dictDisciplineId);
        this.selectionDisciplines.splice(index, 1);
        this.changeStateExam = true;
        this.pageChange({skip: this.skip, take: this.pageSize});
    }

    public saveDiscipline() {
        let data: GiaDisciplineListModel = new GiaDisciplineListModel;
        if (this.studyYear && this.standard) {
          data = {
            filialId: this.filialStateExam,
            graduateYear: this.studyYear,
            educationStandardId: this.standard,
            departmentId: this.faculty,
            trainingLevelId: this.levelStateExam,
            disciplineData: this.selectionDisciplines,
          }
        }

        this.constructorGiaService.saveDisciplines(data)
          .subscribe(response => {
            this.notificationService.showSuccess('Сохранено');
            this.getSavedDisciplines();
            this.changeStateExam = false;
          })
    }

    public onChangeStateExam(value: string | number, list?: string) {
        if (this.levelStateExam !== "" && this.studyYear && (list == filterName.level || list == filterName.year)) this.getFaculties();
        if (this.levelStateExam !== "" && list == filterName.level) this.getStandards();
        this.setLocalStorage();
        this.getSavedDisciplines();
    }

    public getSavedDisciplines() {
      if (this.filialStateExam !== "" && this.levelStateExam !== "" && this.studyYear && this.faculty && this.standard) {
        this.selectionDisciplines = [];
        this.constructorGiaService.getDisciplinesStateExam(this.filialStateExam, this.studyYear, this.faculty, this.levelStateExam, this.standard)
          .subscribe(response => {
            this.filterStateExam = true;
            if (response) {
              this.selectionDisciplines = response;
              this.getFilterDisciplines();
            }
          },
          error => {
            this.notificationService.showError(error);
            this.filterStateExam = false;
          })
      } else {
        this.selectionDisciplines = [];
        this.filterStateExam = false;
      }
    }

    public pageChange(value: PageChangeEvent): void {
        this.skip = value.skip;
        const arrayDiscipline = this.filterDisciplines.slice(this.skip, this.skip + this.pageSize);
        this.gridView = {
          data: arrayDiscipline,
          total: this.filterDisciplines.length,
        };
    }
    
    private setLocalStorage() {
        const filters= {filialStateExam: this.filialStateExam, levelStateExam: this.levelStateExam, studyYear: this.studyYear, faculty: this.faculty, standard: this.standard};
        localStorage.setItem('constructorExams', JSON.stringify(filters));
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { DictsService } from 'src/app/services/middlecontrol/dicts.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { SessionService } from 'src/app/services/middlecontrol/session.service';
import {MiddleControlUserAccessService} from "../../../services/useraccess/middle-control-user-access.service";

@Component({
  selector: 'discipline-action-buttons',
  templateUrl: './discipline-action-button.component.html',
  styleUrls: ['./discipline-action-button.component.scss'],
})
export class DisciplineActionButtonComponent implements OnInit {
  @Output() onComeback: EventEmitter<void> = new EventEmitter();
  @Input() editableSheetApprove?: boolean;

  constructor(
    private sessionService: SessionService,
    private notificationService: NotificationsService,
    private dictService: DictsService,
    private userAccessService: MiddleControlUserAccessService,
  ) {}

  ngOnInit(): void {
  }

  public handleClose() {
    const middle_control_settings = localStorage.getItem('middle_control_settings');
    if (middle_control_settings !== null){
      let storage = JSON.parse(middle_control_settings)
      {
        let middleControlSaveSettings: any = {
          event: storage.event,
          discipline:false,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: storage.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          contingentDate: storage.contingentDate,
          markDate: storage.markDate
        }
        localStorage.setItem('middle_control_settings', JSON.stringify(middleControlSaveSettings));
      }
    }
    this.onComeback.emit();
  }

  public changeStatus() {
    const data = {
      sheetId: this.sessionService.disciplineInfo$.getValue().sheetId || '',
      dictSheetStatusId: this.dictService.dictSheetStatus.Approved.id,
    };
    this.sessionService
      .changeDisciplineSheetStatus(data)
      .subscribe((response) => {
        this.notificationService.showSuccess('Статус успешно обновлен');
        if (response) this.handleClose();
      });
  }

}

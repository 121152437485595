import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  NumericApplicationConstructorStatusesEnum
} from '../../../../models/mfc/enums/application-constructor-statuses.enum';
import {
  ApplicationConstructorStatus,
  constructorStatuses
} from '../../../../models/mfc/applicationConstructor/application-constructor-statuses.model';
import {ApplicationConstructor} from '../../../../models/mfc/applicationConstructor/application-constructor.model';
import {ApplicationConstructorService} from '../../../../services/mfc/application-constructor.service';
import {tabs} from 'src/app/models/mfc/applicationConstructor/form/application-constructor-tabs.model';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {openDialog} from '../../../../helpers/dialogHelper';
import {ApprovalSettingsService} from "../../../../services/mfc/constructor-approval-settings.service";
import {
  ApplicationConstructorEditFormService
} from '../../../../services/mfc/application-constructor-edit-form.service';
import {ApplicationConstructorTabsEnum} from '../../../../models/mfc/enums/application-constructor-tabs.enum';
import {SelectEvent} from '@progress/kendo-angular-layout';


@Component({
  selector: 'mfc-application-constructor-form',
  templateUrl: './application-constructor-form.component.html',
  styleUrls: ['./application-constructor-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationConstructorFormComponent implements OnInit {

  private id = this.activatedRoute.snapshot.params['id'];
  protected isAdd = !this.id;
  protected editable = false;
  protected currentTab = ApplicationConstructorTabsEnum.Main;

  protected status?: ApplicationConstructorStatus;
  protected hasApplications = false;
  protected allowDelete = false;
  //TODO: временно, будет в dto
  protected statusDate = new Date();

  protected applicationConstructor?: ApplicationConstructor;

  protected readonly tabs = tabs;
  protected readonly NumericApplicationConstructorStatusesEnum = NumericApplicationConstructorStatusesEnum;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private applicationConstructorService: ApplicationConstructorService,
    private approvalSettingsService: ApprovalSettingsService,
    private editFormService: ApplicationConstructorEditFormService
  ) { }

  ngOnInit() {
    if (this.isAdd) {
      this.editable = true;
    } else {
      this.getConstructor();
      this.getApprovalSettings();
    }
  }

  private getConstructor() {
    this.applicationConstructorService.getApplicationConstructor(this.id).subscribe((response) => {
      this.applicationConstructor = response;
      this.status = constructorStatuses.find((item) =>
        item.enum === this.applicationConstructor?.status) ?? <ApplicationConstructorStatus>{};
      this.hasApplications = this.applicationConstructor?.basicSettings.hasCreatedApplications;
      this.getAllowedActions();
      this.cdRef.detectChanges();
    });
  }

  private getAllowedActions() {
    this.editable = this.status?.enum === NumericApplicationConstructorStatusesEnum.Draft;
    this.allowDelete = this.editable
      || this.status?.enum === NumericApplicationConstructorStatusesEnum.Published && !this.hasApplications;
  }

  private getApprovalSettings() {
    this.approvalSettingsService.getSettings(this.id).subscribe();
  }

  protected goBack() {
    const isSaved = !this.editFormService.hasFormChanges(this.currentTab);
    if (isSaved) {
      this.router.navigateByUrl('/mfc/applicationConstructor');
    }
    const dialog: DialogRef = openDialog(this.dialogService, 'Имеются несохраненные изменения. Выйти без сохранения?');
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.editFormService.setHasChanges();
        this.router.navigateByUrl('/mfc/applicationConstructor');
      }
    });
  }

  protected onCopy() {
    // Сделать копию
  }

  protected createDraft() {
    // Создать черновик
  }

  protected onTabChange(source: SelectEvent) {
    const tab = tabs[source.index].selector;

    if (this.currentTab === tab) {
      return;
    }

    const isSaved = !this.editFormService.hasFormChanges(this.currentTab);
    if (isSaved) {
      if (tab !== ApplicationConstructorTabsEnum.Tabs) this.getConstructor();
      this.currentTab = tab;
    } else {
      source.preventDefault();
      this.notificationsService.showError('Сохраните изменения');
    }
  }

  protected changeStatus(status: NumericApplicationConstructorStatusesEnum) {
    const isSaved = !this.editFormService.hasFormChanges(this.currentTab);
    if (isSaved) {
      if (status === NumericApplicationConstructorStatusesEnum.Published && !this.approvalSettingsService.approvalSettings$.value.length) {
        this.notificationsService.showError('Необходимо добавить этапы согласования');
        return;
      }
      this.applicationConstructorService.changeStatus(this.id, status).subscribe({
        next: () => {
          this.getConstructor();
          this.notificationsService.showSuccess('Успешно');
        },
        error: (error) => this.notificationsService.showError(error.error.Message ?? error.error.error),
      });
    } else {
      this.notificationsService.showError('Сохраните изменения');
    }
  }

  protected onDelete() {
    const dialog: DialogRef = openDialog(this.dialogService, 'Вы действительно хотите удалить шаблон формы заявки?');
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        // TODO
        // this.applicationConstructorService.delete(this.id).subscribe({
        //   next: () => {
        //     this.notificationsService.showSuccess('Успешно');
        //     this.router.navigateByUrl('/mfc/applicationConstructor');
        //   },
        //   error: (error) => this.notificationsService.showError(error.error.Message),
        // });
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import {DepartmentService} from "../../../../services/disciplineworkload/externals/department.service";
import {EducationPlanService} from "../../../../services/disciplineworkload/externals/education-plan.service";
import {DictService} from "../../../../services/disciplineworkload/externals/dict.service";
import {lastValueFrom} from "rxjs";
import {MonitoringQuery} from "../../../../models/disciplineworkload/query/monitoringQuery.model";
import {AssignmentsService} from "../../../../services/disciplineworkload/assignments.service";
import {CreateQuery} from "../../../../helpers/createQuery-helper";
import {AvailableSemesters, DictStudyForm, DictTrainingLevelGroup, Faculty, KafedraGet} from "../../../../models/disciplineworkload/externals.model";
import {Department, Discipline, Semester, StudyForm} from "../../../../models/disciplineworkload/monitoringAssignmentDepartmentGet.model";
import {AssignmentMonitoringStatus, AssignmentMonitoringStyles} from "../../../../models/disciplineworkload/enums/assignmentMonitoringStatus.enum";

@Component({
  selector: 'assignmentsMonitoring',
  templateUrl: './assignments-monitoring.component.html',
  styleUrls: ['./assignments-monitoring.component.scss']
})
export class AssignmentsMonitoringComponent implements OnInit {

  // Dropdown elements
  public faculties: Faculty[] = [];
  public studyYears: AvailableSemesters[] = [];
  public trainingLevels: DictTrainingLevelGroup[] = [];

  // Selected items
  public facultiesEdit: Faculty = new Faculty();
  public studyYearsEdit: AvailableSemesters = new AvailableSemesters();
  public trainingLevelsEdit: DictTrainingLevelGroup = new DictTrainingLevelGroup();

  // External
  public kafedras: KafedraGet[] = [];
  public studyForms: DictStudyForm[] = [];

  // Queries
  private assignmentsMonitoringQuery: MonitoringQuery = new MonitoringQuery();

  public gridData: Department[] = [];
  public loading: boolean = false;

  constructor(private departmentService: DepartmentService,
              private educationPlanService: EducationPlanService,
              private dictService: DictService,
              private assignmentsService: AssignmentsService) { }

  async ngOnInit() {
    await this.getFaculties();
    await this.getStudyYears();
    await this.getTrainingLevels();
    await this.getStudyForms();
    await this.getMonitoring();
  }

  private async getFaculties() {
    await lastValueFrom(this.departmentService.getFaculties()).then(
      (value) => {
          this.faculties = value;
          this.facultiesEdit = value[0];
      })
  }

  private async getStudyYears() {
    await lastValueFrom(this.educationPlanService.getAvailableSemesters()).then(
      (response) => {
        response!.forEach((item) => {
          item.name = `${item.year} - ${item.year + 1}`;
        })
        this.studyYears = response.filter((value, index, self) =>
            index === self.findIndex((t) => t.name === value.name));
        this.studyYearsEdit = this.studyYears[0];
      })
  }

  private async getTrainingLevels() {
    await lastValueFrom(this.dictService.getTrainingLevels()).then(
        (value) => {
          //TODO: нужно настроить группировки на бэке
          let trainingLevels = ['Бакалавриат', 'Специалитет'];
          let item = new DictTrainingLevelGroup();
          let items = value!.filter(_ => trainingLevels.includes(_.name));
          for (let i = 0; i < items.length; i++) {
            item.ids.push(items[i].id);
            item.name += items[i].name + (i + 1 < items.length ? ' / ' : '');
            item.shortName += items[i].shortName + (i + 1 < items.length ? ' / ' : '');
          }
          this.trainingLevels.push(item);

          items = value!.filter(_ => !trainingLevels.includes(_.name));
          for (let i = 0; i < items.length; i++) {
            item = new DictTrainingLevelGroup();
            item.ids.push(items[i].id);
            item.name = items[i].name;
            item.shortName = items[i].shortName;

            this.trainingLevels.push(item);
          }

          this.trainingLevelsEdit = this.trainingLevels ? this.trainingLevels[0] : new DictTrainingLevelGroup();
      })
  }

  private async getStudyForms() {
    await lastValueFrom(this.dictService.getStudyForms()).then(
        (value) => {
          this.studyForms = value;
        })
  }

  private async getMonitoring() {
    this.loading = true;
    this.assignmentsMonitoringQuery = {
      //cathedraId: "",
      facultyId: this.facultiesEdit.id,
      //semesterPerYear: this.studyYearsEdit.semester,
      //studyFormId: "",
      studyYear: this.studyYearsEdit.year,
      trainingLevels: this.trainingLevelsEdit.ids
    }
    const query = CreateQuery(this.assignmentsMonitoringQuery);
    await lastValueFrom(this.assignmentsService.getMonitoring(query)).then(
      (value) => {
        this.gridData = value.departments.sort((a, b) => a.name.localeCompare(b.name));
        this.loading = false;
      })
  }

  public async onFilterChange() {
    await this.getMonitoring();
  }

  public checkStudyForm(dataItem: Department, semesterNumber: number, studyFormName: string) {
    const studyForms = this.getStudyFormValues(dataItem.disciplines, semesterNumber).filter((item) => item.name === studyFormName);
    return this.getAssignmentStatus(studyForms, false);
  }

  public getOnAssignmentValue(dataItem: Department, style: boolean): string {
    const studyForms = this.getStudyFormValues(dataItem.disciplines, 'all');
    return this.getAssignmentStatus(studyForms, style);
  }

  private getAssignmentStatus(studyForms: StudyForm[], isStyle: boolean) {
    const studyFormsLength = studyForms.length;
    const onAssignmentlength = studyForms.filter((item) => item.onAssignment).length;

    if(studyFormsLength === 0) {
      return isStyle ? '' : ''
    }

    if(studyFormsLength === onAssignmentlength) {
      return isStyle ? AssignmentMonitoringStyles.full : AssignmentMonitoringStatus.full
    }
    else if (studyFormsLength > onAssignmentlength && onAssignmentlength > 0) {
      return isStyle ? AssignmentMonitoringStyles.partly : AssignmentMonitoringStatus.partly
    }
    return isStyle ? AssignmentMonitoringStyles.none : AssignmentMonitoringStatus.empty
  }

  private getStudyFormsForAllSemesters(disciplines: Discipline[]) {
    let studyForms: StudyForm[] = [];
    disciplines.forEach((item: Discipline) => {
      const studyForm = item.semesters.map((semester: Semester) => semester.studyForms[0]);
      studyForm.forEach((form: StudyForm) => {
        studyForms.push(form);
      })
    })
    return studyForms;
  }

  public getStudyFormValues(disciplines: Discipline[], semesterNumber: number | 'all') {
    const studyForms: StudyForm[] = semesterNumber !== 'all'
        ? disciplines.flatMap(s => {
          const studyForms = s.semesters.find((semester: Semester) => semester.number === semesterNumber)?.studyForms[0];
          return studyForms ? { name: studyForms.name, onAssignment: studyForms.onAssignment } : []} )
        : this.getStudyFormsForAllSemesters(disciplines)
    return studyForms;
  }

  public navigateToAssignments(kafedraName: string) {
    localStorage.setItem('assignmentFilters', JSON.stringify({
      studyYear: this.studyYearsEdit.year,
      faculty: this.facultiesEdit.name,
      trainingLevel: this.trainingLevelsEdit.name,
      kafedra: kafedraName
    }
    ))
    window.open(`/disciplineworkload/assignments`);
  }

}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {EmploymentStatus} from "../../models/contingent/employmentstatus.model";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class EmploymentstatusService {


  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.employmentstatus}`;

  constructor(private http: HttpClient) { }

  //Get all employment status
  public getAllEmploymentStatus(): Observable<EmploymentStatus[]> {
    return this.http.get<EmploymentStatus[]>(this.baseUrl + '/GetList');
  }

  //Add employment status
  addEmploymentStatus(employmentStatus: EmploymentStatus): Observable<EmploymentStatus> {
    return this.http.post<EmploymentStatus>(this.baseUrl, employmentStatus)
      .pipe(catchError(this.handleError));
  }

  //Delete employment status
  deleteEmploymentStatus(id: Guid): Observable<EmploymentStatus> {
    return this.http.delete<EmploymentStatus>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Update employment status
  updateEmploymentStatus(employmentStatus: EmploymentStatus): Observable<EmploymentStatus> {
    return this.http.put<EmploymentStatus>(this.baseUrl + '/' + employmentStatus.externalId, employmentStatus)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

import { Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Comments } from 'src/app/models/announcement/comments.model';
import { CommentsService } from 'src/app/services/announcement/comments.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { PostComment } from 'src/app/models/announcement/post-comment.model';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { paperclipIcon } from "@progress/kendo-svg-icons";
import { environment } from 'src/environments/environment';
import urlJoin from 'url-join';

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})


export class CommentsComponent  implements OnInit{
    @Input() refreshComments : number = 0;
    @Input() authorId : number = 0;
    @Input() userId : number = 0;

    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);

    public comments: Comments[] = [];
    public authorMessageId: number = 0;
    public messageId: number = 0;

    public post: string = "";
    public commentAttachments: [] = [];
    public quantityComments: number  = 0;

    public show: any = [];
    
    constructor(
        private activatedroute: ActivatedRoute,
        private commentsService: CommentsService,
        private http: HttpClient,
    ) { }

    ngOnInit() {
      this.getComments(this.activatedroute.snapshot.params['id']);
    }

    public getComments(id: number) {
        this.commentsService.getComments(id)
          .subscribe(
            response => {
                this.comments = response;
                this.quantityComments = this.comments.length ;

                this.messageId = +id;
            }
          );
    }

    public deleteComment(commentId: number){
       this.commentsService.deleteComments(commentId)
        .subscribe(comment => {
          this.getComments(this.activatedroute.snapshot.params['id']);
        });
    }

    public getFile(fileid: string, filename: string){
      var url = this.baseUrl + "/getfile?fileid=" + fileid + "&filename=" + filename;
      this.http.get(url, { responseType: 'blob' }).subscribe(
        (response) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename) {
            downloadLink.setAttribute('download', filename);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink)
        }
      )
    }

    ngOnChanges(){
        this.getComments(this.activatedroute.snapshot.params['id']);
    }
}

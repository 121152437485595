<dicts-header></dicts-header>
<h1>Наименования образовательных организаций</h1>

<div class="grid-container">
  <!--Grid-->
  <kendo-grid
    [kendoGridBinding]="allNames"
    (remove)="removeHandler($event)"
    [pageable]="true"
    [pageSize]="pageSize"
    (cellClick)="editHandler($event)"
    (add)="addHandler($event)"
    [sortable]="true"
  >
    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton (click)="cancelHandler()">Отмена</button>
        <button kendoButton [disabled]="formGroup!.invalid || disableSave" themeColor="primary" (click)="saveCurrent()">Сохранить</button>
      </div>
    </ng-template>

    <kendo-grid-column field="name" title="Название образовательной организации" headerClass="gridHeader" [width]="500">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-textbox size="small" [formControl]="formGroup.get('name')" [clearButton]="true"> </kendo-textbox>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="leftAlign">
          {{ dataItem.name }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="rName" title="Название в род. падеже" headerClass="gridHeader" [width]="500">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-textbox size="small" [formControl]="formGroup.get('rName')" [clearButton]="true"> </kendo-textbox>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="leftAlign">
          {{ dataItem.rName }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shortName" title="Сокр. название" headerClass="gridHeader" [width]="200">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-textbox size="small" [maxlength]="50" [formControl]="formGroup.get('shortName')" [clearButton]="true"> </kendo-textbox>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.shortName }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dateBegin" title="Период «с»" headerClass="gridHeader dictionaries" [width]="200" format="{0:d}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.dateBegin ? (dataItem.dateBegin | date: "dd.MM.yyyy") : "" }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datepicker [formControl]="formGroup.get('dateBegin')" (valueChange)="validDate()"></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dateEnd" title="Период «по»" headerClass="gridHeader dictionaries" [width]="200" format="{0:d}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.dateEnd ? (dataItem.dateEnd | date: "dd.MM.yyyy") : "" }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datepicker [formControl]="formGroup.get('dateEnd')" (valueChange)="validDate()"></kendo-datepicker>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="small" rounded="full"></button>
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

import { Component, Input, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { lastValueFrom } from 'rxjs';
import { CompetenceMatrixModel } from 'src/app/models/education/competence-matrix.model';
import {CompetenceList, TypeCompetenceList} from 'src/app/models/education/oopCompetences.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { CompetenceMatrixService } from 'src/app/services/education/CompetenceMatrix/competence-matrix.service';
import { EducationProgramCompetenciesService } from 'src/app/services/education/OOP/Competencies/education-program-competencies.service';
import {EducationUserAccessService} from "../../../../services/useraccess/education-user-access.service";
import {competenceTypesOrder, typeCompetenceSort} from '../../../../services/education/type-competence-sort';

@Component({
  selector: 'competence-matrix',
  templateUrl: './competence-matrix.component.html',
  styleUrls: ['./competence-matrix.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})

export class CompetenceMatrixComponent implements OnInit {
  @Input() educationPlanId: string = '';
  @Input() eduProgramId: string = '';
  @Input() canEdit: boolean = false;

  public competenceMatrix: CompetenceMatrixModel.CompetenceMatrix[] = [];
  public activeCompetence: CompetenceMatrixModel.CompetenceMatrix | null = null;
  public loadingCompetenceMatrix = true;
  public allCompetencies: CompetenceList[] = [];
  public typeCompetences: TypeCompetenceList[] = [];
  public selectedCompetencies: CompetenceList[] = [];
  public selectedCompetenciesIds: string[] = [];
  public isSaving = false

  constructor(
    public router: Router,
    private notificationService: NotificationsService,
    public competenceMatrixService: CompetenceMatrixService,
    public educationProgramCompetenciesService: EducationProgramCompetenciesService,
    private userAccessService:EducationUserAccessService
  ) {
  }

  ngOnInit(): void {
    this.getAccessLevel(this.educationPlanId);
    this.educationProgramCompetenciesService.getAllCompetenceByProgramId(this.eduProgramId).subscribe((data) => {
      this.typeCompetences = typeCompetenceSort(data.payload.typeCompetences, 'competenceId');
      this.allCompetencies = this.typeCompetences.map((item) =>
        item.competence.sort((a, b) => a.code.localeCompare(b.code))).flat();
      this.competenceMatrix.map((discipline) => {
        discipline.competenciesList.map((competence) => {
          const item = this.allCompetencies.find((i) => i.externalId === competence.id);
          competence.type = this.typeCompetences.find((type) => type.competenceExternalId === item?.typeCompetenceId)?.competenceId;
        });
        discipline.competenciesList = typeCompetenceSort(discipline.competenciesList
          .sort((a, b) => a.code.localeCompare(b.code)), 'type');
      });
    });
  }

  ngOnChanges() {
    if (!this.educationPlanId) {
      this.competenceMatrix = []
      return
    }
    this.loadMatrix()
  }

  loadMatrix() {
    this.loadingCompetenceMatrix = true
    this.competenceMatrixService.getCompetenceMatrix(this.educationPlanId).subscribe(
      data => {
        this.loadingCompetenceMatrix = false
        this.competenceMatrix = data
        // console.log('this.competenceMatrix', this.competenceMatrix)
      }
    );
  }

  onEdit(event: CellClickEvent) {
    this.activeCompetence = event.dataItem
    this.selectedCompetencies = event.dataItem.competenciesList.map((selected: any) => {
      return this.allCompetencies.find(item => item.externalId === selected.id)
    })
    this.selectedCompetenciesIds = this.selectedCompetencies.map(item => item.externalId)
  }

  onSelectCompetence(competence: CompetenceList) {
    const competenceId = competence.externalId
    const foundIndex = this.selectedCompetencies.findIndex(item => item.externalId === competenceId)
    if (foundIndex >= 0) {
      this.selectedCompetencies.splice(foundIndex, 1)
    } else {
      let insertIndex = 0;
      const insertedType = this.typeCompetences.find((type) =>
        type.competenceExternalId === competence.typeCompetenceId)?.competenceId;

      for (const item of this.selectedCompetencies) {
        const selectedType = this.typeCompetences.find((type) =>
          type.competenceExternalId === item.typeCompetenceId)?.competenceId;

        if (selectedType && insertedType && (competenceTypesOrder.indexOf(insertedType) > competenceTypesOrder.indexOf(selectedType)
          || competenceTypesOrder.indexOf(insertedType) === competenceTypesOrder.indexOf(selectedType)
          && competence.code.localeCompare(item.code) > 0)) insertIndex++;
        else break;
      }
      this.selectedCompetencies.splice(insertIndex, 0, competence);
    }
    this.selectedCompetenciesIds = this.selectedCompetencies.map(item => item.externalId)
  }

  onCancel() {
    this.activeCompetence = null
  }

  async onSave() {
    if (!this.activeCompetence) return
    this.isSaving = true
    const saveCompetenceMatrixObject: CompetenceMatrixModel.CompetenceMatrixSaveObject = {
      disciplineId: this.activeCompetence.disciplineId,
      electiveDisciplineId: this.activeCompetence.electiveDisciplineId,
      competencies: this.selectedCompetenciesIds.map(competenceId => ({competenceId, value: 0}))
    }
    await lastValueFrom(this.competenceMatrixService.saveCompetenceMatrix(this.educationPlanId, saveCompetenceMatrixObject))
    this.activeCompetence = null
    this.isSaving = false
    this.notificationService.showSuccess('Компетенции сохранены');
    this.loadMatrix()
  }

  public editable?: boolean;

  public getAccessLevel(educationPlanId:string):any{
    this.userAccessService.getPlanEditAccessLevel(educationPlanId)
      .subscribe(
        response => {
          this.editable = response.accessLevel;
        }
      );
  }
}

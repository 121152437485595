import {Routes} from '@angular/router';
import {Type} from "@angular/core";
import {AuthGuard} from "../guards/authguard";
import {DictBlankTypesComponent} from '../components/diplomablanks/dicts/dict-blanktypes/dict-blanktypes.component';
import {DictBlankStatusesComponent} from '../components/diplomablanks/dicts/dict-blankstatuses/dict-blankstatuses.component';
import {DictBlankTemplateComponent} from '../components/diplomablanks/dicts/dict-blanktemplate/dict-blanktemplate.component';
import {BlanksAvailableComponent} from '../components/diplomablanks/blanks/blanks-available/blanks-available.component';
import {BlanksSettingsComponent}  from '../components/diplomablanks/settings/blanks-settings.component';
import {AddBlanksComponent} from '../components/diplomablanks/blanks/add-blanks/add-blanks.component';
import {IssueBlanksComponent} from '../components/diplomablanks/blanks/issue-blanks/issue-blanks.component';
import {GivenBlanksComponent} from '../components/diplomablanks/blanks/given-blanks/given-blanks.component';
import {BlanksHomeComponent} from '../components/diplomablanks/blanks-home/blanks-home.component';
import {JournalBlanksComponent} from '../components/diplomablanks/blanks/journal-blanks/journal-blanks.component';
import {DiplomablanksAccessComponent} from '../components/useraccess/diplomablanks-access/diplomablanks-access.component';
import {DiplomablanksUserAccessGuard} from '../guards/diplomablanks-user-access.guard';

export const DiplomablanksRoutes: Routes = [
    { path: 'diplomablanks/dictblanktypes', component: DictBlankTypesComponent, canActivate: [DiplomablanksUserAccessGuard] },
    { path: 'diplomablanks/dictblankstatuses', component: DictBlankStatusesComponent, canActivate: [DiplomablanksUserAccessGuard] },
    { path: 'diplomablanks/dictblanktemplate', component: DictBlankTemplateComponent, canActivate: [DiplomablanksUserAccessGuard] },
    { path: 'diplomablanks', component: BlanksHomeComponent, canActivate: [DiplomablanksUserAccessGuard]},
    { path: 'diplomablanks/addblanks', component: AddBlanksComponent, canActivate: [DiplomablanksUserAccessGuard]},
    { path: 'diplomablanks/issueblanks', component: IssueBlanksComponent, canActivate: [DiplomablanksUserAccessGuard]},
    { path: 'diplomablanks/diplomablanksaccess', component: DiplomablanksAccessComponent, canActivate: [DiplomablanksUserAccessGuard]},
]

export const DiplomablanksComponents: Type<unknown>[] = [
    DictBlankTypesComponent,
    DictBlankStatusesComponent,
    DictBlankTemplateComponent,
    BlanksAvailableComponent,
    BlanksSettingsComponent,
    AddBlanksComponent,
    IssueBlanksComponent,
    GivenBlanksComponent,
    BlanksHomeComponent,
    JournalBlanksComponent,
    DiplomablanksAccessComponent,
];
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BlankTemplateModel} from '../../models/diplomablanks/dicts.model';

@Injectable({
    providedIn: 'root'
})

export class DictBlankTemplateService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.dict}`;

  constructor(private http: HttpClient) {}

  public getBlankTemplate() {
    return this.http.get<BlankTemplateModel[]>(this.baseUrl + '/blanktemplate');
  }

  public addBlankTemplate(data: BlankTemplateModel) {
    return this.http.post(this.baseUrl + '/blanktemplate', data);
  }

  public updateBlankTemplate(data: BlankTemplateModel) {
    return this.http.put(this.baseUrl + '/blanktemplate', data);
  }

  public deleteBlankTemplate(id: string) {
    return this.http.delete(this.baseUrl + '/blanktemplate/' + id);
  }
}
import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: "root"
  })
  export class ExportSheduleEducationService {

    baseUrl  = `${environment.educationApiEndpoint}v1/${environment.apiPaths.education.educationSchedule}`;

    constructor(private http: HttpClient) { }

    public exportFile(data: any): Observable<any> {
        let request = {StudyYear: data.StudyYear,
                        DictTrainingLevelId: data.DictTrainingLevelId,
                        DictStudyFormId: data.DictStudyFormId,
                        FacultyId: data.FacultyId,
                        CourseNumber: data.CourseNumber,
                        }
        return this.http.post(this.baseUrl + '/MassPrintExport', request, { observe: 'response', responseType: 'blob' });
    }

    public getData(){
        return this.http.get(this.baseUrl + '/MassPrintFilters' );
    }
  }
import {Injectable} from "@angular/core";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {SubTypeService} from "../../../../../services/science/publications/subTypes.service";
import {getData$} from "../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PublicationsSubTypesMethods {

    constructor(private subTypeService: SubTypeService,
                private notificationService: NotificationsService) {
    }

    async saveSubType(isNew: boolean, formGroup: FormGroup) {
        if (isNew) {
            this.subTypeService.addSubType(formGroup.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: (error) => this.notificationService.showError(error)
            })
        }
        else {
            this.subTypeService.updateSubType(formGroup.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: (error) => this.notificationService.showError(error)
            })
        }
    }

    removeSubType(dialogResult: Observable<DialogResult>, dataItem: any) {
        dialogResult.subscribe((result) => {
            if (result instanceof DialogCloseResult) {}
            else {
                if (result.text == "Да") {
                    this.subTypeService.removeSubType(dataItem.id)
                        .subscribe({
                            next: () => {
                                this.notificationService.showSuccess('Успешно');
                                getData$.next(true);
                            },
                            error: (error) => this.notificationService.showError(error)
                        })
                } else {}
            }
        });
    }
}

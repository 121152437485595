<education-header></education-header>
<div kendoDialogContainer></div>
<kendo-tilelayout
  class="k-mt-4"
  style="padding: 5px 0 0 0"
  *ngIf="educationPlanDetailsService.currentEducationStandard"
  [columns]="4"
>
  <kendo-tilelayout-item class="plan__tilelayout" [col]="1" [colSpan]="4">
    <kendo-tilelayout-item-header class="form-header stretch-column">
      <span class="title">{{
        educationPlanDetailsService.currentEducationStandard.directionTraining
      }}</span>
      <kendo-dropdownlist
        [style.max-width.px]="300"
        [disabled]="eduPlanEdit"
        class="k-ml-4"
        [data]="
          educationPlanDetailsService.currentEducationStandard.educationPrograms
        "
        [value]="educationPlanDetailsService.currentEducationProgram"
        textField="educationProgramNameWithCipher"
        valueField="educationProgramId"
        (valueChange)="onChangeEduProgram($event)"
      >
        <ng-template kendoDropDownListNoDataTemplate></ng-template>
      </kendo-dropdownlist>
      <kendo-dropdownlist
        [style.max-width.px]="150"
        [disabled]="eduPlanEdit"
        class="k-ml-2"
        [data]="educationPlanDetailsService.eStudyForms"
        textField="studyFormName"
        valueField="studyFormName"
        [value]="educationPlanDetailsService.currentStudyForm"
        [valuePrimitive]="true"
        (valueChange)="
          educationPlanDetailsService.filterEducationPlansByStudyForm($event)
        "
      >
        <ng-template kendoDropDownListNoDataTemplate></ng-template>
      </kendo-dropdownlist>
      <button
        kendoButton
        themeColor="primary"
        class="k-ml-4"
        (click)="goToEduProgramList()"
      >
        К списку ОП
      </button>
    </kendo-tilelayout-item-header>
    <kendo-tilelayout-item-body class="edu-plan-params">
      <div class="stretch-column k-mt-2 k-pl-4">
        <span class="form-label">Учебный план</span>
        <kendo-dropdownlist
          [data]="educationPlanDetailsService.educationPlans"
          [disabled]="eduPlanEdit"
          [value]="educationPlanDetailsService.currentEducationPlan"
          textField="longName"
          valueField="educationPlanId"
          (valueChange)="onChangeEducationPlan($event)"
          (open)="openEducationPlan()"
          #dropdownlist
        >
          <ng-template kendoDropDownListNoDataTemplate></ng-template>
        </kendo-dropdownlist>
        <button
          *ngIf="editable"
          class="k-ml-4"
          kendoButton
          (click)="onDeleteEduPlan()"
          [disabled]="
            (eduPlanEdit || !educationPlanDetailsService.currentEducationPlan.educationPlanId)
          "
        >
          Удалить УП
        </button>
      </div>
      <div class="stretch-column k-mt-2 k-pl-4">
        <span class="form-label">Статус</span>
        <kendo-dropdownlist
          [style.max-width.px]="300"
          [data]="educationPlanDetailsService.eduPlanStatuses"
          [disabled]="eduPlanEdit||!editable"
          [value]="educationPlanDetailsService.currentEducationPlan.status"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
          (valueChange)="onChangeEduPlanStatus($event)"
        >
          <ng-template kendoDropDownListNoDataTemplate></ng-template>
        </kendo-dropdownlist>
        <span class="form-label k-ml-6">Комментарий</span>
        <input
          kendoTextBox
          placeholder="Комментарий к УП"
          [disabled]="eduPlanEdit||!editable"
          [(ngModel)]="
            educationPlanDetailsService.currentEducationPlan.description
          "
          (blur)="onBlurEduPlanDescription()"
        />
      </div>
    </kendo-tilelayout-item-body>
    <kendo-tilelayout-item-body *ngIf="!eduPlanEdit" class="k-p-0 k-mt-3">
      <kendo-expansionpanel
        [expanded]="detailsExtended"
        (expandedChange)="onChange($event)"
        style="border: 0; outline: 0; box-shadow: none"
      >
        <ng-template kendoExpansionPanelTitleDirective>
          <label class="expander-label"
            >{{ detailsExtended ? "Cкрыть" : "Все" }} характеристики</label
          >
        </ng-template>
        <div class="k-display-flex k-pl-2">
          <div>
            <h4 class="title k-mb-3">Образовательная программа</h4>
            <ul>
              <li>
                <div class="stretch-column">
                  <span class="form-label">{{
                    educationPlanDetailsService.currentEducationStandard
                      .printStandardTitle
                  }}</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationStandard
                        .directionTraining
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">{{
                    educationPlanDetailsService.currentEducationStandard
                      .printProfileTitle
                  }}</span>
                  <span class="value">
                    {{educationPlanDetailsService.currentEducationProgram.educationProgramCipher
                  + ' - ' + educationPlanDetailsService.currentEducationProgram.educationProgramName
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Квалификация</span>
                  <span class="value">
                    {{
                    educationPlanDetailsService.currentEducationProgram
                            .qualificationName
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Трудоемкость стандарта</span>
                  <span class="value">
                    {{
                    educationPlanDetailsService.currentEducationStandard
                            .laboriousness || 0
                    }}
                    з.ед
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Образовательный стандарт</span>
                  <span class="value">
                    №{{
                      educationPlanDetailsService.currentEducationStandard
                        .number
                    }}
                    от
                    {{
                      educationPlanDetailsService.currentEducationStandard
                        .dateFGOS | date : "dd.MM.YYYY"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Индустриальный партнёр</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationProgram
                        .industrialPartner || "нет"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Выпускающая кафедра</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationProgram
                        .graduatingDepartmentName || "нет"
                    }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="k-pl-6"
            *ngIf="educationPlanDetailsService.currentEducationPlan.status"
          >
            <h4 class="title k-mb-3">
              Учебный план
              <button
                kendoButton
                class="edit-button k-ml-2"
                icon="edit"
                *ngIf="
                  educationPlanDetailsService.currentEducationPlan.status === 1&&editable
                "
                (click)="showEduPlanEditForm(true)"
              >
                Параметры УП
              </button>
            </h4>
            <ul>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Название учебного плана</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationPlan
                        .shortName || "-"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Год поступления</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationPlan
                        .yearAdmission || "-"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Форма обучения</span>
                  <span class="value">
                    {{
                    educationPlanDetailsService.currentEducationPlan
                            .studyFormName || "-"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Срок обучения</span>
                  <span class="value">
                    {{
                    educationPlanDetailsService.currentEducationPlan
                            .developmentPeriodYears
                            ? educationPlanDetailsService.currentEducationPlan
                            .developmentPeriodYears + " г."
                            : ""
                    }}
                    {{
                    educationPlanDetailsService.currentEducationPlan
                            .developmentPeriodMonths
                            ? educationPlanDetailsService.currentEducationPlan
                            .developmentPeriodMonths + " мес."
                            : ""
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Ускоренное</span>
                  <span class="value">
                    {{
                    educationPlanDetailsService.currentEducationPlan
                            .acceleratedSign != null
                            ? educationPlanDetailsService.currentEducationPlan
                                    .acceleratedSign
                                    ? "да"
                                    : "нет"
                            : "-"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Технология обучения</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationPlan
                        .studyTechnologyName || "-"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Факультет</span>
                  <span class="value">
                    {{educationPlanDetailsService.currentEducationPlan.facultyShortName}}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Кафедра</span>
                  <span class="value">
                    {{educationPlanDetailsService.currentEducationPlan.kafedraName}}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Протокол</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationPlan.protocol || "-"
                    }}
                  </span>
                </div>
              </li>
              <li>
                <div class="stretch-column">
                  <span class="form-label">Виды проф. деятельности</span>
                  <span class="value">
                    {{
                      educationPlanDetailsService.currentEducationPlan.professionalActivities || "-"
                    }}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </kendo-expansionpanel>
    </kendo-tilelayout-item-body>
    <kendo-tilelayout-item-body
      *ngIf="eduPlanEdit"
      [style.padding-left.px]="36"
    >
      <education-plan-edit
        [eduPlan]="educationPlanDetailsService.currentEducationPlan"
        (onSave)="onUpdateEduPlan($event)"
        (onCancel)="showEduPlanEditForm(false)"
      >
      </education-plan-edit>
    </kendo-tilelayout-item-body>
    <kendo-tilelayout-item-body class="plan__tilelayout">
      <div class="k-display-flex k-pb-4 k-pl-4 k-justify-content-between eduPadding">
        <div class="btn-group">
          <button kendoButton themeColor="success" class="k-mr-3" *ngIf="editable || allowNew" (click)="onCreateNewPlan()">
            НОВЫЙ УП +
          </button>
          <button kendoButton class="k-mr-3" (click)="onCopyPlan()" *ngIf="editable" [disabled]="educationPlanDetailsService.currentEducationPlan.status !== 1">
            ЗАПОЛНИТЬ ИЗ ДРУГОГО УП
          </button>
          <button kendoButton *ngIf="editable" (click)="onReattachmentPlan()">
            ПЕРЕЗАКРЕПИТЬ ЗА ДРУГОЙ ОП
          </button>
        </div>
        <div class="btn-group" *ngIf="educationPlanDetailsService.currentEducationPlan.educationPlanId">
          <button kendoButton (click)="onExportEduPlan()" [disabled]="isExporting">
            <kendo-loader *ngIf="isExporting" size="small"> </kendo-loader>
            ЭКСПОРТ УП
          </button>
        </div>
      </div>
      <kendo-tabstrip class="custom-tabs k-mt-4" style="padding-left: 16px;" *ngIf="educationPlanDetailsService.currentEducationPlan.educationPlanId">
        <kendo-tabstrip-tab title="График учебного процесса" [selected]=isModifyShedule>
          <ng-template kendoTabContent>
            <educational-process-schedule
              [yearAdmission]="educationPlanDetailsService.currentEducationPlan.yearAdmission"
              [educationPlanId]="educationPlanDetailsService.currentEducationPlan.educationPlanId"
              [educationPlanStatus]="educationPlanDetailsService.currentEducationPlan.status"
            ></educational-process-schedule>

            <!-- <base-education-plan></base-education-plan> -->
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Учебный план" [selected]=!isModifyShedule>
          <ng-template kendoTabContent>
            <base-education-plan
              [educationPlanId]="educationPlanDetailsService.currentEducationPlan.educationPlanId"
              [eduProgramId]="eduProgramId"
            >
            </base-education-plan>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Матрица компетенций" [selected]="false">
          <ng-template kendoTabContent>
            <kendo-tabstrip class="competences">
              <kendo-tabstrip-tab title="Распределение компетенций" [selected]="true">
                <ng-template kendoTabContent>
                  <competence-matrix
                  [canEdit]="
                    educationPlanDetailsService.currentEducationPlan.status === 1
                  "
                  [educationPlanId]="educationPlanDetailsService.currentEducationPlan.educationPlanId"
                  [eduProgramId]="eduProgramId"
                >
                </competence-matrix>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab title="Справочник компетенций" [selected]="false">
                <ng-template kendoTabContent>
                  <dict-competences
                  [educationPlanId]="educationPlanDetailsService.currentEducationPlan.educationPlanId">
                  </dict-competences>
                </ng-template>
              </kendo-tabstrip-tab>

            </kendo-tabstrip>
          </ng-template>
        </kendo-tabstrip-tab>
        <!-- <kendo-tabstrip-tab title="Дисциплины ИОТ" [hide]="true">
          <ng-template kendoTabContent>
            <base-education-plan></base-education-plan>
          </ng-template>
        </kendo-tabstrip-tab> -->
      </kendo-tabstrip>
    </kendo-tilelayout-item-body>
  </kendo-tilelayout-item>
</kendo-tilelayout>
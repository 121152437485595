import {Injectable} from "@angular/core";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {OrganizationsService} from "../../../../../services/science/publications/organizations.service";
import {getData$} from "../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PublicationsOrganizationsMethods {

  constructor(
    private organizationService: OrganizationsService,
    private notificationService: NotificationsService
  ) { }

  async saveOrganization(isNew: boolean, formGroup: FormGroup) {
    if (isNew) {
      this.organizationService.addOrganization(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: (error) => this.notificationService.showError(error)
      });
    } else {
      this.organizationService.updateOrganization(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: (error) => this.notificationService.showError(error)
      });
    }
  }

  removeOrganization(dialogResult: Observable<DialogResult>, dataItem: any) {
    dialogResult.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.organizationService.removeOrganization(dataItem.id).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            getData$.next(true);
          },
          error: (error) => this.notificationService.showError(error)
        });
      }
    });
  }
}

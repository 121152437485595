import { Component, OnInit, ViewChild, Renderer2} from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent, PagerPosition } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { TypesAuditorium } from 'src/app/models/classroom/types-auditorium.model';
import { TypesAuditoriumService } from 'src/app/services/classroom/types-auditorium.service';
import { ClassroomFundService } from 'src/app/services/classroom/classroom-fund.service';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { ClassRoomUserAccessService } from '../../../services/useraccess/classroom-user-access.service';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { checkRole } from "../../../helpers/token/role-check";
import { TokenStorageService } from "../../../services/token.service";
import { JwtHelperService}  from "@auth0/angular-jwt";
import { Role } from "../../../models/useraccess/role";
import { Guid } from 'guid-typescript';
import { Router} from "@angular/router";

@Component({
    selector: 'app-types-auditorium',
    templateUrl: './types-auditorium.component.html',
    styleUrls: ['./types-auditorium.component.scss']
})

export class TypesAuditoriumComponent implements OnInit{ 
  
  typesAuditoriums: any = [];
 
    public typesAuditorium: any = {
        classRoomTypeId: '',
        classRoomTypeName: '',
        classRoomTypeNumber: 0,
    }

    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public info = true;
    public pageSizes = [20, 50, 100];
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';
    public skip = 0;

    public loading = false;
    public opened = false;

    public position: "top" | "bottom" | "both" = "both";
    public formGroup: FormGroup | undefined;
    public editable?: boolean;

    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean{
        return this.editedRowIndex !== undefined || this.isNew;
    }

    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10,
    };

    public classRoomTypeId: string = '';

    public accessLevel: boolean = false;
    public accesses: any = [];
    public personId: string = "";
    public isAdmin: boolean;

    public findType: boolean = false;

    public exists: boolean = false;
    public typesForEdit: any = [];

constructor(
    private typesAuditoriumService: TypesAuditoriumService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private userAccessService: ClassRoomUserAccessService,
    private personService: PersonService,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private classroomFundService: ClassroomFundService,
)
    { this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin); }

ngOnInit() {
    this.getAllTypesAuditorium();
    this.renderer.listen("document", "click", ({ target }) => {
        if (!isChildOf(target, "k-grid")) {
          this.saveCurrent();
        }
      });
      this.getAccess();
}

    public getAllTypesAuditorium() {
        this.loading = true;
        this.typesAuditoriumService.getAllTypesAuditorium()
        .subscribe(response => {
            this.typesAuditoriums = response;
      //      console.log(this.typesAuditoriums);
            this.loading = false;
          },
          error => {
            console.log(error.error);
            this.loading = false;
          });
    }

    public removeHandler({ dataItem }: RemoveEvent): void {
        const dialog: DialogRef = this.dialogService.open({
            title: "Пожалуйста подтвердите",
            content: `Вы действительно хотите удалить: ${dataItem.classRoomTypeName.toLowerCase()}?`,
            actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
            width: 450,
            height: 200,
            minWidth: 250,
          });
          dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
                if(result.text == "Да"){
                
                  this.opened = false;
                  this.typesAuditoriumService.deleteTypeAuditorium(dataItem.classRoomTypeId)
                  .subscribe(
                    ( response: any) => {
                      if (response == null){
                        this.notificationService.showError("Невозможно удалить тип аудитории, так как он используется");
                      }
                      else {
                        this.getAllTypesAuditorium();
                        this.notificationService.showSuccess("Удалено");
                      }
                    },
                    error => {
                      if ( error.error.Error[0] == "Запись используется"){
                        this.notificationService.showError("Невозможно удалить тип аудитории, так как он используется");
                      }
                      else this.notificationService.showError(error.error);
                    }
                  );
                }
                else{
                  this.opened = false;
                }
            }
          });
    }

    public editHandler({
        sender,
        isEdited,
        rowIndex,
        dataItem,
    }: CellClickEvent): void {
      if (this.accessLevel == true){
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
            return;
          }
      
          this.isLine = true;
          this.classRoomTypeId = dataItem.classRoomTypeId;
          this.saveRow();
          this.formGroup = formGroup(dataItem);
          this.editedRowIndex = rowIndex;
      
          this.typesForEdit = [];
          let name = this.formGroup.value.classRoomTypeName;
          this.typesForEdit = this.typesAuditoriums.filter((item: any) => !(item.classRoomTypeName == name));

          sender.editRow(rowIndex, this.formGroup);
        }
    }

    public addHandler({ sender }: AddEvent): void {
        this.closeEditor(sender);

        this.formGroup = formGroup({
          classRoomTypeId: 0,
          classRoomTypeName: '',
          classRoomTypeNumber: undefined,
    //    dictAuditoriumExternalId: Guid.create()
        });

        this.isLine = true;
        this.isNew = true;
        sender.addRow(this.formGroup);
    }

    public cancelHandler(): void {
        this.closeEditor(this.grid, this.editedRowIndex);
    }

    private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
        ): void {
        this.isNew = false;
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

    public onStateChange(state: any): void {
        this.gridState = state;
        this.getAllTypesAuditorium();
    }

    public saveCurrent(){
        if (this.formGroup && !this.formGroup.valid) {
            return;
          }
          this.isLine = false;
          this.saveRow();
    }

    private saveRow(): void {
        if (this.isInEditingMode) {
          //Adding new entry to dictionary
          if (this.isNew == true && this.formGroup !== undefined) {

            this.exists = false;

            let name = this.formGroup.value.classRoomTypeName.toLowerCase().trim();
            if (this.typesAuditoriums.findIndex((item:any) => item.classRoomTypeName.toLowerCase() == name ) !== -1){
              this.notificationService.showError("Невозможно сохранить тип аудитории, так как он уже существует")
              this.exists = true;
            }

            if (this.exists == false){
              this.typesAuditoriumService.addTypesAuditorium(this.formGroup.value)
                .subscribe(
                  response => {
                    this. getAllTypesAuditorium();
                    this.notificationService.showSuccess("Добавлено");
                    this.typesAuditorium = {
                      name: '',
                      number: undefined,
                    }
                  },
                  error => {
                    this.notificationService.showError("Не удалось сохранить запись");
                  }
                );
            }
          }
          //Update dictionary
          else if (this.formGroup !== undefined) {
            this.exists = false;

            let name = this.formGroup.value.classRoomTypeName.toLowerCase().trim();
            if (this.typesForEdit.findIndex((item:any) => item.classRoomTypeName.toLowerCase() == name ) !== -1){
              this.notificationService.showError("Невозможно сохранить тип аудитории, так как он уже существует");
              this.exists = true;
            }

            if (this.exists == false){
              this.typesAuditoriumService.updateTypesAuditorium(this.formGroup.value, this.classRoomTypeId)
                .subscribe(
                  response => {
                    this. getAllTypesAuditorium();
                    this.notificationService.showSuccess("Сохранено");
                  },
                  error => {
                    this.notificationService.showError("Не удалось изменить запись");
                  }
                );
            }
          }
        }
        this.closeEditor(this.grid);
    }

    public getAccess()  {
      this.userAccessService.getAccessLevel()
      .subscribe(response => {
        if (response.dictionaryAccessLevel == 2){
          this.accessLevel = true;
        }
      })
    }
      
}

const formGroup = (dataItem: { classRoomTypeId?: any; classRoomTypeName?: any; classRoomTypeNumber?: any; dictAuditoriumExternalId?: any}) =>
  new FormGroup({
   // id: new FormControl(dataItem.id),
   classRoomTypeName: new FormControl(dataItem.classRoomTypeName, Validators.required),
   classRoomTypeNumber: new FormControl(dataItem.classRoomTypeNumber, [Validators.min(1), Validators.pattern("^[0-9]+$")]),
//    dictAuditoriumExternalId: new FormControl(dataItem.dictAuditoriumExternalId)
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
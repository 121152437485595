<kendo-stacklayout [gap]="20" orientation="vertical" class="legend">
  <p class="legend__text">
    В скобках указывается количество пропущенных часов.<br><br>
    Текущая успеваемость студентов оценивается по пятидесятибалльной шкале.<br>
    Соотношение между пятидесятибалльной и четырёхбалльной шкалами оценок:
  </p>
  <!--<h3 class="legend__header">Интервал баллов</h3>-->
  <kendo-grid [data]="legendData" class="legend__table">
    <kendo-grid-column field="name" title="Интервал"> </kendo-grid-column>
    <kendo-grid-column field="markName" title="Оценка"> </kendo-grid-column>
  </kendo-grid>
</kendo-stacklayout>

import {PublicationsDict} from "../dict/dict.model";

export class Property {
  id!: string;
  value?: string;
  publicationPropertyId?: string;
  childProperties?: Property[];
  languageCode?: string;
}

export class AllowedProperty {
  id!: string;
  name!: string;
  allowModeratorOnly!: boolean;
  type!: PropertyType;
  required!: boolean;
  toolTip?: string;
  serialNumber!: number;
  dictId?: string;
  childProperties?: AllowedProperty[];
  dictLanguageId?: string;
  multipleByLanguage!: boolean;
  // Для справочников
  data?: PublicationsDict[];
  languageData?: PublicationsDict[];
  // Для FormArray
  value?: any;
  languageCode?: string;
}

export class PublicationProperty {
  publicationId!: string;
  categoryId!: number;
  properties!: Property[];
  files?: PropertyFile[];
  propertyFilesToDelete?: string[];
}

export class PropertyFile {
  propertyId!: string;
  file!: File;
}

export class PropertyType {
  id!: string;
  name!: string;
  enum?: number;
}

export class PropertyFileList {
  id!: string;
  name!: string;
  length!: number;
}

export class FileUploadRestrictions {
  extensions?: string[];
  maximumNumberOfFiles?: number;
  maximumFileSize?: number;
  maximumFileSizeInMb?: number;
}

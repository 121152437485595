import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {Role} from "../models/useraccess/role";
import {map} from "rxjs/operators";
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {ContingentUserAccessService} from "../services/useraccess/contingent-user-access.service";
import {Location} from "@angular/common";
import {StudentcarddisplaysettingService} from "../services/contingent/studentcarddisplaysetting.service";
import {DisplaySettingEnumList} from "../models/contingent/enums/display-setting.enum";
import {AccessRights, PublicationCurrentUserAccess} from "../models/useraccess/publications/publicationsUserAccess";

@Injectable({
  providedIn: 'root'
})
export class ContingentDisplaySettingGuard implements CanActivate {

  constructor(private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private userAccessService: ContingentUserAccessService,
              private location: Location,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private displaySettingService: StudentcarddisplaysettingService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;
    const studentId = state.root.children[0].params["studentId"];

    // Checking if token is not null
    if(token) {
      return this.displaySettingService.getStudentCardDisplaySettings().pipe(
        map(value => {

          const enumIndex = Object.values(DisplaySettingEnumList).find((item) => path?.includes(item.toString()));
          const type = DisplaySettingEnumList[enumIndex as keyof typeof DisplaySettingEnumList];

          if (type &&!value.find((x) => x.type === type)) {
            localStorage.setItem('last_url', this.location.path())
            this.router.navigateByUrl(`/contingent/studentmaininfo/${studentId}`).then();
            return false;
          }
          return true;
        }))
    }

    localStorage.setItem('last_url', this.location.path())
    this.router.navigateByUrl(`/profile`).then();
    return false;
  }
}

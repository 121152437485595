import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FamilyMemberStatuses } from '../../models/contingent/familymemberstatuses.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FamilymemberstatusesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.familymemberstatuses}`;

  constructor(private http: HttpClient) { }

  //Get all FamilyMemberStatuses
  public getAllFamilyMemberStatuses(): Observable<FamilyMemberStatuses[]> {
    return this.http.get<FamilyMemberStatuses[]>(this.baseUrl);
  }

  //Add FamilyMemberStatuses
  addFamilyMemberStatuses(familymemberstatuses: FamilyMemberStatuses): Observable<FamilyMemberStatuses> {
    return this.http.post<FamilyMemberStatuses>(this.baseUrl, familymemberstatuses)
      .pipe(catchError(this.handleError));
  }

  //Delete FamilyMemberStatuses
  deleteFamilyMemberStatuses(id: string): Observable<FamilyMemberStatuses> {
    return this.http.delete<FamilyMemberStatuses>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit FamilyMemberStatuses
  updateFamilyMemberStatuses(familymemberstatuses: FamilyMemberStatuses): Observable<FamilyMemberStatuses> {
    return this.http.put<FamilyMemberStatuses>(this.baseUrl + '/' + familymemberstatuses.dictFamilyMemberStatusExternalId, familymemberstatuses)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

<div class="legend">
  <h4 class="legend__title">Легенда</h4>
  <div class="legend__content">
    <ul class="list">
      <li class="list__element" *ngFor="let mark of marks">
        <div class="list__symbol text" style="color:{{mark.markColor}}">{{mark.markIcon}}</div>
        <div class="list__description">{{mark.markName}}</div>
      </li>
    </ul>

    <ul class="list">
      <li class="list__element">
        <div class="list__symbol text text--red text--bold">н/д</div>
        <div class="list__description">
          Нет допуска к сдаче дисциплины
        </div>
      </li>
      <li class="list__element">
        <div class="list__symbol text text--aqua text--bold">4</div>
        <div class="list__description">
          Оценка получена вне сроков сессии (жирным)
        </div>
      </li>
      <li class="list__element">
        <div class="list__symbol text">?</div>
        <div class="list__description">Неявка без уважительной причины</div>
      </li>
      <li class="list__element">
        <div class="list__symbol">!</div>
        <div class="list__description">Неявка по уважительной причине</div>
      </li>
      <li class="list__element">
        <div class="list__symbol text text--red">ФИО</div>
        <div class="list__description">
          Студент имеет неудовлетворительную оценку
        </div>
      </li>
    </ul>

    <ul class="list list--clear">
      <li class="list__element list__element--clear">
        <div class="list__symbol list__symbol--clear">
          <div class="point"></div>
        </div>
        <div class="list__description">
          Дисциплина соответствует другому профилю
        </div>
      </li>
      <li class="list__element list__element--clear">
        <div class="list__symbol list__symbol--clear">
          <div class="point point--green"></div>
        </div>
        <div class="list__description">Ведомость утверждена</div>
      </li>
    </ul>
  </div>
</div>

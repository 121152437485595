import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Author} from "../../../models/publications/editForm/author.model";
import {PublicationEditFormTabs} from "../../../models/publications/editForm/editForm.enums";
import { Person } from '../../../models/publications/person.model';

@Injectable({
    providedIn: 'root'
})
export class PublicationsEditFormService {
  public currentType$ = new BehaviorSubject<string>('');
  public change$ = new BehaviorSubject<string>('');
  public currentTab$ = new BehaviorSubject<string>(PublicationEditFormTabs.MainInfo);
  public externalAuthors$ = new BehaviorSubject<Person[]>([]);
  public save$ = new BehaviorSubject<{name: string, disableCodes: boolean} | null>(null);
  public clear$ = new BehaviorSubject<boolean>(false);

  private authorData!: any;
  private authors?: Author[];
  private rubricators?: string[];
  private isSaved: boolean = false;

  public getAuthorData() {
    const data = this.authorData;
    this.clearAuthorData();
    return data;
  }

  public setAuthorData(data: any) {
    if (this.authorData !== data) {
      this.authorData = data;
    }
  }

  public getAuthors() {
    const data = this.authors;
    this.clearAuthors();
    return data;
  }

  public saveAuthors(authors: Author[]) {
    this.authors = authors;
  }

  public getRubricators() {
    const data = this.rubricators;
    this.clearRubricators();
    return data;
  }

  public saveRubricators(ids: string[]) {
    this.rubricators = ids;
  }

  private clearAuthorData() {
    this.authorData = undefined;
  }

  private clearRubricators() {
    this.rubricators = undefined;
  }

  private clearAuthors() {
    this.authors = undefined;
  }

  public setIsSaved(value: boolean) {
    this.isSaved = value;
  }

  public isFormSaved(title: string) {
    this.change$.next(title);
    return this.isSaved;
  }
}

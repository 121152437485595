import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {
  AdditionalWork, AdditionalWorkAddDto, AssignmentDepartmentGet, AssignmentDisciplineAdd,
  AvailableDiscipline, DictTypeWorkGet, DisciplineAssignment, MassOperationAssignmentAdd, RecallAssignmentDiscipline
} from "../../models/disciplineworkload/assignements.model";
import {handleError} from "../../helpers/errorHandle-helper";
import { AssignmentDepartmentQuery } from 'src/app/models/disciplineworkload/query/assignmentDepartmentQuery.model';
import {
  AssignmentDepartmentExportQuery
} from "../../models/disciplineworkload/query/exportAssignmentDepartmentQuery.model";
import {MonitoringQuery} from "../../models/disciplineworkload/query/monitoringQuery.model";
import {MonitoringAssignmentDepartment} from "../../models/disciplineworkload/monitoringAssignmentDepartmentGet.model";
import { DictCalculationMethod } from '../../models/disciplineworkload/externals.model';

@Injectable({
  providedIn: 'root'
})
export class AssignmentsService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.assignments}`;
  constructor(private http: HttpClient) { }

  // Get available disciplines
  availableDisciplines(disciplineFilter: HttpParams): Observable<AvailableDiscipline> {
    return this.http.get<AvailableDiscipline>(this.baseUrl + '/GetAdditionalWorks/', {params: disciplineFilter});
  }


  // Get department assignments
  getAssignmentDepartments(assignmentFilter: AssignmentDepartmentQuery): Observable<AssignmentDepartmentGet[]> {
    return this.http.post<AssignmentDepartmentGet[]>(this.baseUrl + '/GetAssignmentDepartments/', assignmentFilter);
  }


  // Export department assignments
  getExportAssignmentDepartments(assignmentFilter: AssignmentDepartmentExportQuery) {
    return this.http.post(this.baseUrl + '/GetExportAssignmentDepartments/', assignmentFilter, {
      responseType: 'blob'
    });
  }

  // Get assignment discipline
  getAssignmentDiscipline(query: any): Observable<DisciplineAssignment> {
    //return this.http.get<DisciplineAssignment>(this.baseUrl + '/GetAssignmentDiscipline/?' +
    //    `year=${query.year}&semester=${query.semester}&id=${query.id}`);
    return this.http.get<DisciplineAssignment>(this.baseUrl + '/GetAssignmentDiscipline/' + query.id);
  }

  //Save assignment discipline
  saveAssignmentDiscipline(assignmentDiscipline: AssignmentDisciplineAdd) {
    return this.http.post(this.baseUrl + '/SaveAssignmentDiscipline/', assignmentDiscipline);
  }

  // Получение списка доступных видов работ для способа расчета
  calculationMethodCathedralWorks(): Observable<DictCalculationMethod[]> {
    return this.http.get<DictCalculationMethod[]>(this.baseUrl + '/CalculationMethodCathedralWorks/');
  }

  // Отозвать поручение по дисциплине
  recallAssignmentDisciplineById(assignmentDisciplineId: string): Observable<any> {
    return this.http.post(this.baseUrl + '/RecallAssignmentDisciplineById/', {assignmentDisciplineId});
  }

  ///// MASS OPERATIONS //////
  // Массовое аннулирование поручений по дисциплине
  recallAssignmentDisciplines(assignmentIds: string[]) {
    return this.http.post(this.baseUrl + '/RecallAssignmentDisciplines/', assignmentIds);
  }

  // Массовое создание поручений
  createAssignmentDisciplines(massOperationAssignments: any, query: any): Observable<MassOperationAssignmentAdd[]> {
    return this.http.post<MassOperationAssignmentAdd[]>(this.baseUrl + '/CreateAssignmentDisciplines?' +
      `year=${query.year}&semestr=${query.semestr}&kafedraId=${query.kafedraId}`, massOperationAssignments);
  }

  /**
   * Обновление учебного плана
   * @param assignmentDisciplineIds ID поручений
   */
  updateEducationPlanes(assignmentDisciplineIds: string[]): Observable<number> {
    return this.http.post<number>(this.baseUrl + '/UpdateEducationPlanes', assignmentDisciplineIds);
  }

  /**
   * Обновление контингента
   * @param assignmentDisciplineIds ID поручений
   */
  updateContingent(assignmentDisciplineIds: string[]): Observable<number> {
    return this.http.post<number>(this.baseUrl + '/UpdateContingent', assignmentDisciplineIds);
  }

  /** Обновление поручения
  * @param assignmentDisciplineId ID поручения
  */
  updateAssignmentDiscipline(assignmentDisciplineId: string): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + '/UpdateAssignmentDiscipline/' + assignmentDisciplineId, null);
  }

  /** Обновление контингента и учебных планов по выбранному факультету
  * @param facultyId ID факультета
  */
  UpdateContingentAndPlans(facultyId: string): Observable<number> {
    return this.http.post<number>(this.baseUrl + '/UpdateContingentAndPlans/' + facultyId, null);
  }

  getMonitoring(monitoringQuery: HttpParams): Observable<MonitoringAssignmentDepartment> {
    return this.http.get<MonitoringAssignmentDepartment>(this.baseUrl + '/Monitoring/', {params: monitoringQuery});
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import urlJoin from "url-join";
import {StudyTechnology} from "../../../models/education/External/studytechnologies.model";

@Injectable({
  providedIn: 'root'
})
export class StudyTechnologyService {

  baseUrl = urlJoin(<string>environment.apiEndpoint, environment.apiPaths.education.studytechnology);

  constructor(private http: HttpClient) { }

  public getList(): Observable<StudyTechnology[]> {
    return this.http.get<StudyTechnology[]>(this.baseUrl);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Department} from '../../../models/mfc/dicts/department.model';


@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.externalDict}/Department/`;

  constructor(private http: HttpClient) { }

  public getDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(this.baseUrl);
  }

  public getDepartment(id: string): Observable<Department> {
    return this.http.get<Department>(`${this.baseUrl}${id}`);
  }
}

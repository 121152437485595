<mfc-settings></mfc-settings>
<h1>Настройки доступа</h1>

<div class="search">
  <h6>Поиск по ФИО</h6>
  <kendo-textbox [(value)]="searchValue"></kendo-textbox>
  <button kendoButton [disabled]="editMode" (click)="clearSearch()">Очистить</button>
  <button kendoButton themeColor="primary" [disabled]="editMode" (click)="searchByFIO()">Применить</button>
</div>

<div [formGroup]="formGroup" class="container">
  <kendo-grid class="grid-data borderless"
              #grid
              [data]="gridView"
              (add)="addRow($event)"
              (edit)="editRow($event)"
              (remove)="onRemove($event)"
              [pageable]="pagerSettings"
              [pageSize]="pageSize"
              [skip]="skip"
              (pageChange)="pageChange($event)"
              [loading]="loading"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              (cellClick)="onCellClick($event.dataItem)"
              [kendoGridExpandDetailsBy]="expandDetailsBy"
              [(expandedDetailKeys)]="expandedDetailKeys">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate>
      <span *ngIf="editMode; else view">
        <button (click)="onCancel()" kendoButton>Отмена</button>
        <button [disabled]="formGroup.invalid || (personExist && isNew) || errors.size > 0" kendoButton themeColor="primary"
                (click)="onSave({dataItem: formGroup.value, isNew: isNew, rowIndex: (lastRowIndex ?? -1), formGroup: formGroup, sender: grid})">
          Сохранить
        </button>
      </span>
      <ng-template #view>
        <button kendoGridAddCommand themeColor="success" icon="plus">Добавить</button>
      </ng-template>
    </ng-template>
    <kendo-grid-column [width]="110" title="">
      <ng-template kendoGridCellTemplate>
        <span class="action-buttons" *ngIf="!editMode">
          <button kendoGridEditCommand
                  themeColor="secondary"
                  icon="edit"
                  fillMode="flat"
                  size="medium"
                  rounded="full">
          </button>
          <button kendoGridRemoveCommand
                  themeColor="secondary"
                  icon="delete"
                  fillMode="flat"
                  size="medium"
                  rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="alignCells leftAlign" field="personId" title="ФИО">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.fio}}
      </ng-template>
      <ng-template kendoGridEditTemplate>
        <kendo-combobox
          [data]="persons"
          [valuePrimitive]="true"
          [kendoDropDownFilter]="filterSettings"
          [virtual]="filterVirtualization"
          (valueChange)="personChange($event)"
          formControlName="personId"
          valueField="id"
          textField="fio">
          <ng-template kendoComboBoxItemTemplate let-dataItem>
            <span class="comboboxItem">
              <span class="fio">{{dataItem.fio}}</span>
              <span class="post">{{dataItem.post ? ' ' + dataItem.post : ''}}</span>
            </span>
          </ng-template>
        </kendo-combobox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="60" title="">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <a [ngClass]="{
            'k-icon': true,
            'k-i-arrow-chevron-down': !expandedDetailKeys.includes(dataItem.personId),
            'k-i-arrow-chevron-up': expandedDetailKeys.includes(dataItem.personId)
          }" *ngIf="!editMode" (click)="onCellClick(dataItem)">
        </a>
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridDetailTemplate let-dataItem>
      <kendo-grid [data]="dataItem.userAccessAccessRights" #applicationAccessGrid>
        <ng-template kendoGridToolbarTemplate>
          <span class="grid-title">Настройки прав доступа</span>
        </ng-template>
        <kendo-grid-column [width]="50" title="">
          <ng-template kendoGridHeaderTemplate>
            <span *ngIf="editMode" class="add-button">
              <button kendoButton
                      themeColor="success"
                      icon="plus"
                      (click)="addAccessRights(dataItem.userAccessAccessRights)">
              </button>
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-rowIndex="rowIndex">
            <span class="action-buttons">
              <button kendoButton
                      themeColor="secondary"
                      icon="delete"
                      fillMode="flat"
                      size="medium"
                      rounded="full"
                      (click)="deleteAccessRights(dataItem.userAccessAccessRights, rowIndex)">
              </button>
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="№" [width]="50">
          <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
            <span>
              {{ rowIndex > -1 ? rowIndex + 1 : '' }}
            </span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="200" field="userAccessRight" title="Уровень доступа">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{userAccessText[dataItem[column.field]]}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-column="column" let-formGroup="formGroup">
            <div class="edit-cell">
              <kendo-dropdownlist [ngClass]="{
                                    error: errors.get(rowIndex)?.fieldName === column.field || hasIntersection(rowIndex)
                                  }"
                                  [data]="accessOptions"
                                  [valuePrimitive]="true"
                                  [formControl]="formGroup.get(column.field)"
                                  (valueChange)="accessValueChange($event, rowIndex, column.field)"
                                  textField="text"
                                  valueField="id">
              </kendo-dropdownlist>
              <span class="error-text" *ngIf="errors.get(rowIndex)?.fieldName === column.field">
                Ошибка: {{ errors.get(rowIndex)?.isDuplicate
                  ? 'Запись уже существует'
                  : 'Пересечение с Записью №' + ((errors.get(rowIndex)?.intersectionRowIndex ?? 0) + 1)}}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="200" field="trainingLevels" title="Уровень подготовки">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem.isAllTrainingLevelsAvailable ? 'Все' : ''}}
            <span *ngFor="let item of dataItem[column.field]; let isLast=last">
              {{item.name}}{{isLast ? '' : ', '}}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-column="column" let-formGroup="formGroup">
            <div class="edit-cell">
              <kendo-multiselect
                [ngClass]="{
                  error: errors.get(rowIndex)?.fieldName === column.field
                }"
                #trainingLevels
                [data]="dictTrainingLevels"
                [kendoDropDownFilter]="filterSettings"
                [valuePrimitive]="true"
                [autoClose]="false"
                [checkboxes]="true"
                [formControl]="formGroup.get(column.field)"
                (valueChange)="multiselectValueChange(
                  formGroup.get(column.field),
                  dictTrainingLevels,
                  formGroup.get('isAllTrainingLevelsAvailable'),
                  rowIndex,
                  column.field)"
                kendoMultiSelectSummaryTag
                valueField="id"
                textField="name">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                  {{dataItem.length}} выбрано
                </ng-template>
                <ng-template kendoMultiSelectHeaderTemplate>
                  <span class="multiselect-all">
                    <input type="checkbox"
                           kendoCheckBox
                           [checked]="formGroup.get('isAllTrainingLevelsAvailable').value"
                           [indeterminate]="isIndet(dictTrainingLevels.length, formGroup.get(column.field).value.length)"
                           (click)="onIsAllClick(
                             trainingLevels,
                             formGroup.get('isAllTrainingLevelsAvailable'),
                             formGroup.get(column.field),
                             dictTrainingLevels)"/>
                    <kendo-label>Все</kendo-label>
                  </span>
                </ng-template>
              </kendo-multiselect>
              <span class="error-text" *ngIf="errors.get(rowIndex)?.fieldName === column.field">
                Ошибка: {{ errors.get(rowIndex)?.isDuplicate
                ? 'Запись уже существует'
                : 'Пересечение с Записью №' + ((errors.get(rowIndex)?.intersectionRowIndex ?? 0) + 1)}}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="200" field="faculties" title="Институт/Факультет">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem.isAllFacultiesAvailable ? 'Все' : ''}}
            <span *ngFor="let item of dataItem[column.field]; let isLast=last">
              {{item.name}}{{isLast ? '' : ', '}}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-column="column" let-formGroup="formGroup">
            <div class="edit-cell">
              <kendo-multiselecttree
                [ngClass]="{
                  error: errors.get(rowIndex)?.fieldName === column.field
                }"
                kendoMultiSelectTreeExpandable
                #faculties
                [kendoMultiSelectTreeFlatBinding]="dictFaculties"
                [popupSettings]="{width: 'auto'}"
                [formControl]="formGroup.get(column.field)"
                (valueChange)="multiselectValueChange(
                  formGroup.get(column.field),
                  dictFaculties,
                  formGroup.get('isAllFacultiesAvailable'),
                  rowIndex,
                  column.field)"
                [tagMapper]="facultiesTagMapper"
                parentIdField="filialId"
                valueField="id"
                textField="name">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                  {{dataItem.length}} выбрано
                </ng-template>
                <ng-template kendoMultiSelectHeaderTemplate>
                  <span class="multiselect-all_tree">
                    <input type="checkbox"
                           kendoCheckBox
                           [checked]="formGroup.get('isAllFacultiesAvailable').value"
                           [indeterminate]="isIndet(dictFaculties.length, formGroup.get(column.field).value.length)"
                           (click)="onIsAllClick(
                             faculties,
                             formGroup.get('isAllFacultiesAvailable'),
                             formGroup.get(column.field),
                             dictFaculties,
                             false)"/>
                    <kendo-label>Все</kendo-label>
                  </span>
                </ng-template>
              </kendo-multiselecttree>
              <span class="error-text" *ngIf="errors.get(rowIndex)?.fieldName === column.field">
                Ошибка: {{ errors.get(rowIndex)?.isDuplicate
                ? 'Запись уже существует'
                : 'Пересечение с Записью №' + ((errors.get(rowIndex)?.intersectionRowIndex ?? 0) + 1)}}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="200" field="studyForms" title="Форма обучения">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem.isAllStudyFormsAvailable ? 'Все' : ''}}
            <span *ngFor="let item of dataItem[column.field]; let isLast=last">
              {{item.name}}{{isLast ? '' : ', '}}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-column="column" let-formGroup="formGroup">
            <div class="edit-cell">
              <kendo-multiselect
                [ngClass]="{
                  error: errors.get(rowIndex)?.fieldName === column.field
                }"
                #studyForms
                [data]="dictStudyForms"
                [kendoDropDownFilter]="filterSettings"
                [valuePrimitive]="true"
                [autoClose]="false"
                [checkboxes]="true"
                [formControl]="formGroup.get(column.field)"
                (valueChange)="multiselectValueChange(
                  formGroup.get(column.field),
                  dictStudyForms,
                  formGroup.get('isAllStudyFormsAvailable'),
                  rowIndex,
                  column.field)"
                kendoMultiSelectSummaryTag
                valueField="id"
                textField="name">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                  {{dataItem.length}} выбрано
                </ng-template>
                <ng-template kendoMultiSelectHeaderTemplate>
                  <span class="multiselect-all">
                    <input type="checkbox"
                           kendoCheckBox
                           [checked]="formGroup.get('isAllStudyFormsAvailable').value"
                           [indeterminate]="isIndet(dictStudyForms.length, formGroup.get(column.field).value.length)"
                           (click)="onIsAllClick(
                             studyForms,
                             formGroup.get('isAllStudyFormsAvailable'),
                             formGroup.get(column.field),
                             dictStudyForms)"/>
                    <kendo-label>Все</kendo-label>
                  </span>
                </ng-template>
              </kendo-multiselect>
              <span class="error-text" *ngIf="errors.get(rowIndex)?.fieldName === column.field">
                Ошибка: {{ errors.get(rowIndex)?.isDuplicate
                ? 'Запись уже существует'
                : 'Пересечение с Записью №' + ((errors.get(rowIndex)?.intersectionRowIndex ?? 0) + 1)}}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="450" field="applicationTypes" title="Типы заявок">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem.isAllApplicationTypesAvailable ? 'Все' : ''}}
            <span *ngFor="let item of dataItem[column.field]; let isLast=last">
              {{item.name}}{{isLast ? '' : ', '}}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-column="column" let-formGroup="formGroup">
            <div class="edit-cell">
              <kendo-multiselecttree
                [ngClass]="{
                  error: errors.get(rowIndex)?.fieldName === column.field
                }"
                kendoMultiSelectTreeExpandable
                #applicationTypes
                [kendoMultiSelectTreeFlatBinding]="typesView"
                [popupSettings]="{width: 'auto'}"
                parentIdField="dictApplicationCategoryId"
                textField="name"
                valueField="id"
                [formControl]="formGroup.get(column.field)"
                (valueChange)="multiselectValueChange(
                  formGroup.get(column.field),
                  typesView,
                  formGroup.get('isAllApplicationTypesAvailable'),
                  rowIndex,
                  column.field)"
                [tagMapper]="typesTagMapper">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                  Количество выбранных типов: {{dataItem.length}} выбрано
                </ng-template>
                <ng-template kendoMultiSelectHeaderTemplate>
                  <span class="multiselect-all_tree">
                    <input type="checkbox"
                           kendoCheckBox
                           [checked]="formGroup.get('isAllApplicationTypesAvailable').value"
                           [indeterminate]="isIndet(typesView.length, formGroup.get(column.field).value.length)"
                           (click)="onIsAllClick(
                             applicationTypes,
                             formGroup.get('isAllApplicationTypesAvailable'),
                             formGroup.get(column.field),
                             typesView,
                             false)"/>
                    <kendo-label>Все</kendo-label>
                  </span>
                </ng-template>
              </kendo-multiselecttree>
              <span class="error-text" *ngIf="errors.get(rowIndex)?.fieldName === column.field">
                Ошибка: {{ errors.get(rowIndex)?.isDuplicate
                ? 'Запись уже существует'
                : 'Пересечение с Записью №' + ((errors.get(rowIndex)?.intersectionRowIndex ?? 0) + 1)}}
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <kendo-grid [data]="[dataItem.userAccessMainSettings]" #mainSettingsGrid>
        <ng-template kendoGridToolbarTemplate>
          <span class="grid-title">Общие права доступа</span>
        </ng-template>
        <kendo-grid-column [width]="200" field="roles" title="Роль оператора">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            <span *ngFor="let item of dataItem[column.field]; let isLast=last">
              {{item.name}}{{isLast ? '' : ', '}}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-multiselect
              kendoMultiSelectSummaryTag
              [kendoDropDownFilter]="filterSettings"
              [data]="dictRoles"
              [valuePrimitive]="true"
              [formControlName]="column.field"
              valueField="id"
              textField="name">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                {{dataItem.length}} выбрано
              </ng-template>
            </kendo-multiselect>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="160" field="isApplicationConstructorTemplateAvailable" title="Шаблоны справок">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem[column.field] ? 'Да' : 'Нет'}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-dropdownlist [data]="boolOptions"
                                [valuePrimitive]="true"
                                [formControlName]="column.field"
                                valueField="id"
                                textField="text">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="170" field="isApplicationConstructorAvailable" title="Конструктор заявок">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem[column.field] ? 'Да' : 'Нет'}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-dropdownlist [data]="boolOptions"
                                [valuePrimitive]="true"
                                [formControlName]="column.field"
                                valueField="id"
                                textField="text">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="160" field="isSignStampSettingsAvailable" title="Настройки штампов ЭП">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem[column.field] ? 'Да' : 'Нет'}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-dropdownlist [data]="boolOptions"
                                [valuePrimitive]="true"
                                [formControlName]="column.field"
                                valueField="id"
                                textField="text">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="160" field="isDictCreateAvailable" title="Создание справочников">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem[column.field] ? 'Да' : 'Нет'}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-dropdownlist [data]="boolOptions"
                                [valuePrimitive]="true"
                                [formControlName]="column.field"
                                valueField="id"
                                textField="text">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="160" field="isDictAvailable" title="Справочники">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem[column.field] ? 'Да' : 'Нет'}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-dropdownlist [data]="boolOptions"
                                [valuePrimitive]="true"
                                [formControlName]="column.field"
                                valueField="id"
                                textField="text">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="160" field="isReportAvailable" title="Отчеты">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem[column.field] ? 'Да' : 'Нет'}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-dropdownlist [data]="boolOptions"
                                [valuePrimitive]="true"
                                [formControlName]="column.field"
                                valueField="id"
                                textField="text">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="170" field="isAccessSettingsAvailable" title="Настройки доступа">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
            {{dataItem[column.field] ? 'Да' : 'Нет'}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-column="column">
            <kendo-dropdownlist [data]="boolOptions"
                                [valuePrimitive]="true"
                                [formControlName]="column.field"
                                valueField="id"
                                textField="text">
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </ng-template>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

<div class="container">
    <!--Поиск сотрудников-->
    <div  class="search-data">
          <app-search-persons> </app-search-persons>
    </div> 
    <!--Найденные сотрудники-->
    
    <app-found-persons> </app-found-persons>


    <!--Выбранные сотрудники-->    
    
    <app-selected-persons> </app-selected-persons> 

</div>
export const items: any[] = [
  {
    text: 'Настройки',
    icon: 'cog',
    items: [
      {
        text: 'Настройки доступа',
        path: '/diplomablanks/diplomablanksaccess',
        accessSettings: true,
      },
      {
        text: 'Справочники',
        items: [
          {
            text: 'Тип бланков',
            path: '/diplomablanks/dictblanktypes',
          },
          {
            text: 'Статусы',
            path: '/diplomablanks/dictblankstatuses',
          },
          {
            text: 'Шаблоны',
            path: '/diplomablanks/dictblanktemplate',
          },
        ],
      },
    ],
  },
];

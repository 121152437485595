import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { Standard } from 'src/app/models/education/standard.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StandardService {

  baseUrl = `${environment.educationApiEndpoint}${environment.apiPaths.education.standard}`;

  constructor(private http: HttpClient) { }

  //Get all standards
  public getAllStandards(): Observable<Standard[]> {
    return this.http.get<Standard[]>(this.baseUrl + '/GetAllStandards/');
  }

  //Add Standard
  addStandard(standard: Standard): Observable<Standard> {
    return this.http.post<Standard>(this.baseUrl + '/CreateStandard/', standard)
      .pipe(catchError(this.handleError));
  }

  //Delete Standard
  deleteStandard(standardId: number | string): Observable<Standard> {
    return this.http.delete<Standard>(this.baseUrl + '/GetStandardByID/' + standardId)
      .pipe(catchError(this.handleError));
  }

  //Edit Standard
  updateStandard(standard: Standard): Observable<Standard> {
    return this.http.put<Standard>(this.baseUrl + '/UpdateStandard/' + standard.standardId, standard)
      .pipe(catchError(this.handleError));
  }
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

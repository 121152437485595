import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Person} from "../../models/gia/person.model";
import {EducationStandardInfo, EduStandardModel} from "../../models/gia/education/standard.model";
import {EduProgramModel} from "../../models/gia/education/program.model";
import {PrintTemplateModel} from "../../models/gia/printtemplate/printTemplate.model";
import {GeneralInformationModel} from "../../models/gia/formationdiploma.model";

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.education}`;
  constructor(private http: HttpClient) { }

  // Получить всех сотрудников
  public getStandards(name?: string, trainingLevelId?: string): Observable<EduStandardModel[]> {
    let filter = '';

    if (name !== null && name !== undefined) filter += `name=${name}`;

    if (trainingLevelId) filter += `${filter ? '&' : ''}trainingLevelId=${trainingLevelId}`;

    const queryString = filter ? `?${filter}` : '';

    return this.http.get<EduStandardModel[]>(this.baseUrl + `/Standard/${queryString}`);
  }

  public getStudentStandards(name?: string, trainingLevelId?: string, filialId?: string): Observable<EduStandardModel[]> {
    let filter = '';

    if (name !== null && name !== undefined) filter += `name=${name}`;

    if (trainingLevelId) filter += `${filter ? '&' : ''}trainingLevelId=${trainingLevelId}`;
    if (filialId) filter += `${filter ? '&' : ''}filialId=${filialId}`;

    const queryString = filter ? `?${filter}` : '';

    return this.http.get<EduStandardModel[]>(this.baseUrl + `/StudentStandards/${queryString}`);
  }

  public getStandardInfo(standardId: string) {
    return this.http.get<EducationStandardInfo>(this.baseUrl +'/StandardInfo/' + standardId);
  }

  public getPrograms(name?: string, standardId?: string): Observable<EduProgramModel[]> {
    let filter = '';

    if (name !== null && name !== undefined) filter += `name=${name}`;

    if (standardId) filter += `${filter ? '&' : ''}standardId=${standardId}`;

    const queryString = filter ? `?${filter}` : '';

    return this.http.get<EduProgramModel[]>(this.baseUrl + `/Program/${queryString}`);
  }

  public getStudentPrograms(name?: string, standardId?: string): Observable<EduProgramModel[]> {
    let filter = '';

    if (name !== null && name !== undefined) filter += `name=${name}`;

    if (standardId) filter += `${filter ? '&' : ''}standardId=${standardId}`;

    const queryString = filter ? `?${filter}` : '';

    return this.http.get<EduProgramModel[]>(this.baseUrl + `/StudentPrograms/${queryString}`);
  }
}

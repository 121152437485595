import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Qualification } from 'src/app/models/contingent/qualification.model';

@Injectable({
    providedIn: 'root'
  })

export class DictQualificationService {
  
    baseUrl = `${environment.apiEndpoint}`;
  
    constructor(private http: HttpClient) { }

    public getQualification() {
      return this.http.get<Qualification[]>(this.baseUrl + 'contingent/d/qualification')
        .pipe(catchError(this.handleError));
    }

      // Error
    handleError(error: HttpErrorResponse) {
      return throwError(error);}
}
export enum SeriesAndNumbersBlanksEnumList
{
  diplomaBlankSeries = 1,
  diplomaBlankNumbers,
  diplomaBlankAttachmentSeries,
  diplomaBlankAttachmentNumber,
  duplicateDiplomaBlankSeries,
  duplicateDiplomaBlankNumbers,
  duplicateDiplomaBlankAttachmentSeries,
  duplicateDiplomaBlankAttachmentNumber
}

export const SeriesAndNumbersBlanksEnum = SeriesAndNumbersBlanksEnumList

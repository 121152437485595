<gia-settings></gia-settings>
<h1>Задолженности обучающегося</h1>

<div class="student-info">
  <kendo-label>
        Обучающийся: {{studentInfo?.studentFio}}
  </kendo-label>
  <kendo-label>
        Группа: {{studentInfo?.groupName}}
  </kendo-label>
</div>

<div class="right-buttons m-b20">
  <button kendoButton size="small" (click)="back()">Назад</button>
</div>

<kendo-grid
  class="m-t15"
  [kendoGridBinding]="studentDebts"
  [loading]="loading"
  [sortable]="true"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="studyYearString" format="dd.MM.yyyy" title="Учебный год" headerClass="gridHeader" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.studyYearString }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="semesterNumber" title="Семестр" [width]="100" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.semesterNumber }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="disciplineName" title="Дисциплина" [width]="200" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="leftAlign">{{ dataItem.disciplineName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="controlActionName" title="Контрольная акция" [width]="120" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.controlActionName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="markName" title="Оценка" [width]="100" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.markName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="markDate" format="dd.MM.yyyy" title="Дата" headerClass="gridHeader" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.markDate | date: "dd.MM.yyyy" }}</span>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<div class="right-buttons m-t20">
  <button kendoButton size="small" (click)="back()">Назад</button>
</div>

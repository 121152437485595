import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthorsService} from "../../../../../services/science/publications/authors.service";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {Location} from "@angular/common";
import {TimeZoneFix} from "../../../../../helpers/date-helper";

@Component({
  selector: 'publications-authors-editform',
  templateUrl: './publications-authors-edit-form.component.html',
  styleUrls: ['./publications-authors-edit-form.component.scss']
})
export class PublicationsAuthorsEditFormComponent implements OnInit {

  public mask = "000-000-000 00";

  public currentAuthor: FormGroup = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    middleName: new FormControl(''),
    type: new FormControl({value: 'Внешний автор', disabled: true}),
    birthday: new FormControl<Date | null>(null),
    identificator: new FormControl(null)
  })

  constructor(private router: Router,
              private notificationsService: NotificationsService,
              private authorsService: AuthorsService,
              private location: Location) { }

  ngOnInit(): void {}

  public onCancel() {
    this.location.back();
  }

  public onSave() {
    if(this.currentAuthor?.value.birthday)
      this.currentAuthor.value.birthday = TimeZoneFix(this.currentAuthor.value.birthday);

    this.authorsService.addExternalAuthor(this.currentAuthor.value).subscribe({
      next: () => {
        this.notificationsService.showSuccess('Успешно');
        this.location.back();
      },
      error: (error) => {
        this.notificationsService.showError(error);
      }
    })
  }

}

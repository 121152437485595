import { DictFilial } from "./dictfilial.model";
import { StudPerson } from "./studperson.model";
import {Guid} from "guid-typescript";
import {StudyCategoryEnum} from './enums/study-category.enum';

export class Student{
  public studentId!: number;
  public studentExternalId?: Guid;
  public studentNumber?:string;
  public dictFilialId?: Guid;
  public dictFilials!: DictFilial;
  public studPersonId?: Guid;
  public studPersons!:StudPerson;
  public editable?:boolean;
  public personalDataEditable?:boolean;
  public documentEditable?:boolean;
  public achievementEditable?:boolean;
  public militaryEditable?: boolean;
  public targetEditable?: boolean;
  public achievementAddable?: boolean;
}

export class CertificateStudent{
  public studentId?: Guid;
  public studPersonId?: Guid;
  public trainingLevelId?:Guid;
  public facultyId?:Guid;
  public studyFormId?:Guid;
  public commissariat?:string;
  public editable?:boolean;
  public militaryEditable?:boolean;
}


export class StudentName{
  public studentId!: number;
  public fullName?: string;
}

export class StudentFilter {
  public fio?: string;
  public studyCategory?: StudyCategoryEnum;
}

export class AllStudent {
  studentId!: string;
  studPersonId!: string;
  firstName!: string;
  lastName!: string;
  middleName?: string;
  groups!: string;
  fullName!: string;
  fullNameWithGroup!: string;
}

export enum BlankSettingSectionEnumList
{
  original = 1,
  duplicate
}

export enum BlankTypeEnumList
{
  diploma = 1,
  application
}

export enum BlankSettingValueEnumList
{
  // Вручную
  manually = 1,
  // мои_бланки
  my_blanks,
  // общие_бланки
  general
}

export const BlankSettingSectionEnum = BlankSettingSectionEnumList
export const BlankTypeEnum = BlankTypeEnumList
export const BlankSettingValueEnum = BlankSettingValueEnumList

import { Guid } from "guid-typescript";

export class Holiday {
    constructor(studyYear: number, currentDate: Date, weekType: number, id: number, externalId: Guid) {
        this.studyYear = studyYear,
        this.currentDate = currentDate,
        this.weekType = weekType,
        this.id = id,
        this.externalId = externalId
    }

    public id!: number;
    public externalId!: Guid;
    public studyYear!: number;
    public currentDate!: Date;
    public weekType!: number;
}
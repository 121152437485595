import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {GeneralInformationModel,
        basicInformationModel, 
        additionalDataModel, 
        FillStatusesModel, 
        DiplomaStatusesModel,
        ReplacingReasonsModel,
        MarksModel,
        DisciplineModel,
        ControlActionModel,
        TypeDiplomaModel,
        ApplicationDataModel,
        PrintTemplateDiplomaModel,
        saveDisciplineModel,
        basicStudentInformationModel,
        disciplineForApplication,
        editDisciplineModel} from '../../models/gia/formationdiploma.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class FormationDiplomaService {

    baseUrl = `${environment.apiEndpoint}`;
    diploma = `${environment.apiPaths.gia.diploma}`;

    constructor(private http: HttpClient) { }

    public getDiplomaInformation(studentId: string) {
        return this.http.get<GeneralInformationModel>(this.baseUrl + this.diploma + '/GetDiplomaById/' + studentId);
    }

    public getFillStatuses() {
        return this.http.get<FillStatusesModel[]>(this.baseUrl + 'gia/dictdiploma/GetAllDiplomaFillStatuses');
    }

    public getPrintTemplateDiploma(studentId: string) {
        return this.http.get<PrintTemplateDiplomaModel[]>(this.baseUrl + 'gia/dictdiploma/GetDiplomaTemplatesByStudentId/' + studentId);
    }

//Основные сведения

    public getBasicStudentInfo(studentId: string) {
        return this.http.get<basicStudentInformationModel>(this.baseUrl + this.diploma + '/GetBasicStudentInfoById/' + studentId);
    }

    public getBasicDiplomaInfo(studentId: string) {
        return this.http.get<basicInformationModel>(this.baseUrl + this.diploma + '/GetDiplomaBasicById/' + studentId);
    }

    public getDiplomaStatuses() {
        return this.http.get<DiplomaStatusesModel[]>(this.baseUrl + 'gia/dictdiploma/GetAllDiplomaStatuses');
    }

    public getDiplomaReplacingReasons() {
        return this.http.get<ReplacingReasonsModel[]>(this.baseUrl + 'gia/dict/DiplomaReplacingReasons');
    }

    public getTypesDiploma(studentId: string) {
        return this.http.get<TypeDiplomaModel[]>(this.baseUrl + 'gia/dictdiploma/GetDiplomaTypes/' + studentId);
    }

    public addDiplioma(data: GeneralInformationModel) {
        return this.http.post(this.baseUrl + this.diploma, data);
    }

    public addBasicInfo(data: basicInformationModel) {
        return this.http.post(this.baseUrl + this.diploma + '/SaveBasicInfo', data);
    }

//Приложение

    public getApplicationData(studentId: string) {
        return this.http.get<ApplicationDataModel>(this.baseUrl + this.diploma + '/GetDiplomaAttachmentById/' + studentId);
    }

    public getMarks() {
        return this.http.get<MarksModel[]>(this.baseUrl + 'gia/dict/Mark');
    }

    public clearDisciplines(studentId: string) {
        return this.http.delete(this.baseUrl + this.diploma + '/RemoveAttachmentDisciplines/' + studentId);
    }

    public fillingStudentStudyPlan(studentId: string) {
        return this.http.get<[]>(this.baseUrl + this.diploma + '/GetDiplomaAttachmentDisciplines/' + studentId);
    }

    public saveApplicationData(data: ApplicationDataModel) {
        return this.http.post(this.baseUrl + this.diploma + '/SaveAttachmentInfo', data);
    }

    public sendDiscipline = new BehaviorSubject<{discipline: disciplineForApplication}> ({
        discipline: new disciplineForApplication
    })

//Дополнительные сведения
    public getAdditionalData(studentId: string) {
        return this.http.get<additionalDataModel>(this.baseUrl + this.diploma + '/GetDiplomaAdditionalById/' + studentId);
    }

    public saveAdditionalData(data: additionalDataModel) {
        return this.http.post(this.baseUrl + this.diploma + '/SaveAdditionalInfo', data);
    }

//Добавление дисциплины

    public getTypesDiscipline() {
        return this.http.get<[]>(this.baseUrl + 'gia/dict/DisciplineType');
    }

    public getDisciplines(typeDiscipline: string, discipline: string) {
        let params = new HttpParams();
        params = params.append('name', discipline);
        params = params.append('DisciplineType', typeDiscipline);
        return this.http.get<DisciplineModel[]>(this.baseUrl + 'gia/dict/Discipline', {params});
    }

    public getControlAction() {
        return this.http.get<ControlActionModel[]>(this.baseUrl + 'gia/dict/ControlAction');
    }

    public tabApplication = new BehaviorSubject<{application: boolean}> ({
        application: false,
    });

    public getStudentId = new BehaviorSubject<{studentId: string}> ({
        studentId: "",
    });

    public addDisciplines(studentId: string, data: saveDisciplineModel[]) {
        return this.http.put(this.baseUrl + this.diploma + '/AddAttachmentDisciplines/' + studentId, data);
    }

    public editDiscipline(studentId: string, data: editDisciplineModel) {
        return this.http.put(this.baseUrl + this.diploma + '/UpdateAttachmentDisciplines/' + studentId, data);
    }
}
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MassPrintSheetService } from '../../../services/middlecontrol/massPrintSheet.service';
import { GetSessionResultData } from '../../../models/middlecontrol/massPrintSheet.model';
import { SheetService } from '../../../services/middlecontrol/sheet.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import { SignatoriesModel } from 'src/app/models/middlecontrol/sheets.model';
import {TrainingLevelsModel} from "../../../models/middlecontrol/trainingLevels.model";
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {StatementSettings} from "../../../models/middlecontrol/statementSetting.model";
import {StatementSettingsService} from "../../../services/middlecontrol/statement-settings.service";
import {StatementSettingsEnum} from "../../../models/middlecontrol/enums/statement-setting.enum";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {DateModel} from "../../../models/middlecontrol/date.model";

@Component({
  selector: 'middlecontrol-print-debtors-by-course',
  templateUrl: './print-debtors-by-course.component.html',
  styleUrls: ['./print-debtors-by-course.component.scss'],
})
export class PrintDebtorsByCourseComponent implements OnInit {
  public checkedNodes: string[] = [];
  public checkedSemesterNodes: string[] = [];
  public trainingLevelName: string = ""
  private sessionBaseUrl: string = environment.apiEndpoint.toString().endsWith('/')
    ? environment.apiEndpoint.toString().slice(0, -1) : environment.apiEndpoint.toString();
  private changeDate: DateModel | null = null;

  constructor(
    private location: Location,
    private router: Router) {}

  ngOnInit(): void { }

  public openReport() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['report-viewer'], {
        queryParams: {
          report: this.trainingLevelName.toLowerCase().includes('аспирант')
            ? 'Session/DebtorsByCourseAsp.trdp'
            : this.trainingLevelName.toLowerCase().includes('ординат')
              ? 'Session/DebtorsByCourseOrd.trdp'
              : 'Session/DebtorsByCourse.trdp',
          sessionBaseUrl: this.sessionBaseUrl,
          groupSemesters: this.checkedSemesterNodes.join(';'),
          contingentOnDate: this.changeDate?.contingentDate ?? new Date().toISOString().slice(0, 10),
          markOnDate: this.changeDate?.markDate ?? new Date().toISOString().slice(0, 10)
        }
      })
    );

    window.open(url, '_blank');
  }

  public onChangeTrainingLevelName(list: string[] | null) {
    if(!list || list.length == 0) return;

    this.trainingLevelName = list[0];
  }

  public onChangeCheckedNodes(list: string[]) {
    this.checkedNodes = list;
  }

  public onChangeCheckedSemesterNodes(list: string[]) {
    this.checkedSemesterNodes = list;
  }

  public onChangeDate(item: DateModel) {
    this.changeDate = item;

    console.log(item);
  }

  public onCancel(): void {
    this.location.back();
  }
}

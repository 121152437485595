<middle-control-menu-access></middle-control-menu-access>
<h1>Контрольные акции</h1>

<div class="grid-container">
  <!--Grid-->
  <kendo-grid [kendoGridBinding]="controlactions"
              [height]="500"
              (cellClick)="editHandler($event)"
              [sortable]="true">

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictControlActionId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="controlActionName"
                       [editable]="false"
                       title="Название акций"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.controlActionName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="controlActionSName"
                       [editable]="false"
                       title="Сокр. название"
                       [width]="150"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.controlActionSName}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="trainingLevelName"
                       [editable]="false"
                       title="Уровень подготовки"
                       [width]="150"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.trainingLevelName}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="standardTypeName"
                       [editable]="false"
                       title="Тип стандарта"
                       [width]="150"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.standardTypeName}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="serialNumber"
                       [editable]="false"
                       title="Порядковый номер"
                       [width]="100"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.serialNumber}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isNotUsed"
                       [editable]="false"
                       title="Не учитывать оценки"
                       [width]="100"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.isNotUsed ? "Да" : "Нет"}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isInternalRecord"
                       title="Только для этого проекта"
                       editor="boolean"
                       [width]="100"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.isInternalRecord ? "Да" : "Нет"}}
                          </span>
                        </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

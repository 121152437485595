import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {studentFieldKeys, studentFieldsMap} from '../../../../../models/mfc/student-fields';
import {ChangeEvent, ColumnsInfo} from '../../../../../models/mfc/constructor-listbox.model';
import {FieldType} from '../../../../../models/mfc/enums/constructor-listbox.enum';
import {
  ApplicationConstructorEditFormService
} from '../../../../../services/mfc/application-constructor-edit-form.service';


@Component({
  selector: 'mfc-additional-filters',
  templateUrl: './additional-filters.component.html',
  styleUrls: ['./additional-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationConstructorAdditionalFiltersComponent implements OnInit {

  protected editable = true;
  protected info: ColumnsInfo<{code: string}, {code: string, param: string}> = {
    hidden: [
      {
        field: 'code',
        type: FieldType.Text,
        title: 'Наименование',
        width: 380,
        map: studentFieldsMap,
      },
    ],
    visible: [
      {
        field: 'code',
        type: FieldType.Text,
        title: 'Наименование',
        width: 250,
        map: studentFieldsMap,
      },
      {
        field: 'param',
        type: FieldType.Textbox,
        title: 'Параметры',
        width: 250,
        tooltip: 'В параметрах необходимо прописать конкретные значения выбранных фильтров',
      },
    ],
  };

  //TODO: подставлять данные из настроек согласования
  protected hiddenData: {code: string}[] = studentFieldKeys.map((item) => ({code: item}));
  protected visibleData: {code: string, param: string}[] = [];
  //TODO: отправляемые данные
  protected data: {code: string, param: string}[] = [];

  constructor(
    private location: Location,
    private editFormService: ApplicationConstructorEditFormService
  ) { }

  ngOnInit(): void {

  }

  protected goBack() {
    this.location.back();
  }

  protected toVisible(item: {code: string}): {code: string, param: string} {
    return {code: item.code, param: ''};
  }

  protected toHidden(item: {code: string, param: string}): {code: string} {
    return {code: item.code};
  }

  protected getData({value}: ChangeEvent<{code: string, param: string}>) {
    this.data = value;
  }

  protected onSave() {
    this.editFormService.setHasChanges(true);
    //TODO
  }
}

import { Component, OnInit} from '@angular/core';
import {dataIcon} from "@progress/kendo-svg-icons";
import {ButtonThemeColor, ChipRemoveEvent} from '@progress/kendo-angular-buttons';
import {PageSizeChangeEvent} from '@progress/kendo-angular-pager';
import {SortDescriptor, State} from '@progress/kendo-data-query';
import { AuthorAnnouncement } from 'src/app/models/announcement/author.model';
import { AuthorService } from 'src/app/services/announcement/author.service';
import { Router } from "@angular/router";
import { truncate } from 'lodash';
import { AuthorStatistics } from 'src/app/models/announcement/author-statistics.model';
import { AuthorStatisticsService } from 'src/app/services/announcement/author-statistics.service';
import { SearchStudentsService } from 'src/app/services/announcement/search-students.service';
import { AnnouncementUserAccessService } from '../../../services/useraccess/announcement-user-access.service';
import { AnnouncementUserAccess } from '../../../models/useraccess/announcement/announcementuseraccess.model';
import { PagerModule, PageChangeEvent, PagerType } from "@progress/kendo-angular-pager";
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import { checkRole } from '../../../helpers/token/role-check';



@Component({
    selector: 'app-author',
    templateUrl: './author.component.html',
    styleUrls: ['./author.component.scss']
})

export class AuthorComponent  implements OnInit{

    public expandedState = false;
    public press: boolean = true;
    
    public authors: AuthorAnnouncement["messages"] = [] ;
    public author:  Array<any> = [];
    public messageCount: number = 0;
    public total: AuthorStatistics["total"] = [] ;
    public firstParametr: AuthorStatistics["firstParametr"] = [] ;
    public calculatedParametr: AuthorStatistics["calculatedParametr"] = [] ;

    public skip = 0;
    public pageSize = 10;
    public pageSizes = false;
    public info = false;

    public isAdmin: boolean = false;
    public personId: string = "";
    public accessLevel: boolean = false;
    public accesses: AnnouncementUserAccess[] = [];

    constructor(
        private router: Router,
        private authorService: AuthorService,
        private authorStatisticsServise: AuthorStatisticsService,
        private searchStudentsService: SearchStudentsService,
        private jwtHelper: JwtHelperService,
        private tokenStore: TokenStorageService,
        private userAccessService: AnnouncementUserAccessService,
    )
     {
      this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
     }

     public getAuthor() {
        this.authorService.getAuthor()
          .subscribe(
            response => {
            this.authors = response["messages"];
            this.messageCount = response["messageCount"];
            this.author = [];
            for (let i = 0; i < this.authors.length; i++) {
              this.author.push(this.authors[i]);  
            }
            }
          );
      }

      public  getAuthorStatistics() {
        this.authorStatisticsServise.getAuthorStatistics()
          .subscribe(
            response => {
             this.total = response["total"];
             this.firstParametr = response["firstParametr"];
             this.calculatedParametr = response["calculatedParametr"];
            }
          );
      }

      public page: number = 1;
      public type: number = 2;
      public query: string = "";
      public tag  : string = "";
      public start: Date | undefined;
      public finish: Date | undefined;

    public notpress(){
        this.press = false;
    }

    public clearAllFilters(){
        this.page = 1;
        this.skip = 0;
        this.press = true;
        this.type = 2;
        this.query = "";
        this.tag = "";
        this.start = undefined;
        this.finish = undefined;
        this.findMessages();
    }

    public startdate: string = "";
    public finishdate: string = "";

    public findMessages(page?: number){
      if (this.start != undefined ){
        this.startdate =  this.start.toISOString();
    }
    if (this.finish != undefined ){
        this.finishdate =  this.finish.toISOString();
    }
    if(page != undefined){
      this.page = page;
    }
      this.authorService.getSearchMessages(this.page, this.type, this.query, this.tag, this.startdate, this.finishdate)
        .subscribe({next:(data:any) => {
          if (data != undefined){
          this.authors = data["messages"];
          this.author = [];
          for (let i = 0; i < this.authors.length; i++) {
            this.author.push(this.authors[i]);  
          }
          this.messageCount = data["messageCount"];
         
          for (let element of this.author){
            let str = "";
            if (element.content !== null){
              while (element.content.indexOf('<img') !== -1){
                let i1 = element.content.indexOf('<img');
                let i2 = element.content.indexOf('>', i1);
                if (i1 !== -1) {
                    str = element.content.slice(0, i1) + '[ Фотография ]'+ element.content.slice(i2 + 1);
                    element.content = str;
                }
              }
            } 
          }

          for (let element of this.author){
            let str = "";
            if (element.content !== null){
              while (element.content.indexOf('<table') !== -1){
                let i1 = element.content.indexOf('<table');
                let i2 = element.content.indexOf('/table>', i1);
                if (i1 !== -1) {
                    str = element.content.slice(0, i1) + '[ Таблица ]' + element.content.slice(i2 + 7);
                    element.content = str;
                }
              }
            }
          }
        } else{
          this.page = 1;
          this.author = [];
          this.messageCount = 0;
        }
        this.skip = (this.page -1) * this.pageSize;
    }});

    }

    public onPageChange(e: any): void {
      this.skip = e.skip;
      this.page = (e.skip + this.pageSize) / this.pageSize;
      this.findMessages();
    }

    public createAnnouncement(){
      this.searchStudentsService.SearchParams("", 0, []);
      this.router.navigate(['author/modify']);
  }

  public getAccess (){
    this.userAccessService.getAccess(this.personId)
      .subscribe(res => {
        this.accesses = res;
        }
      );
    this.accessLevel = this.accesses.length > 0 ? this.accesses[0].userAccessLevel : false; 
  }
    ngOnInit(): void {
      this.getAuthorStatistics();
      this.findMessages();
      this.getAccess();
    }    
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {PublicationsOrganizationsMethods} from "./publications-organizations.methods";
import {OrganizationsService} from "../../../../../services/science/publications/organizations.service";
import {dictPagerSettings} from "../../../../../models/dicts/pagerSettings.model";
import {getData$} from "../../../../../../environments/environment";
import {DictOrganization} from "../../../../../models/publications/dict/organization.model";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";
import {DictCountry} from "../../../../../models/publications/dict/country.model";
import {PublicationsDictsService} from "../../../../../services/science/publications/dicts.service";

@Component({
  selector: 'publications-organizations',
  templateUrl: './publications-organizations.component.html',
  styleUrls: ['./publications-organizations.component.scss']
})
export class PublicationsOrganizationsComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  // Pager settings
  public pagerSettings: PagerSettings = dictPagerSettings;

  public isNew: boolean = false;
  public isLine: boolean = false;
  private editedRowIndex: number | undefined;
  public editable: boolean = this.userAccessService.currentUserAccess$.value.dict === AccessRights.Write;

  public countries: DictCountry[] = [];
  public organizations: DictOrganization[] = [];

  public formGroup: FormGroup = this.resetFormGroup;

  constructor(
    private organizationsService: OrganizationsService,
    private dictsService: PublicationsDictsService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private organizationMethods: PublicationsOrganizationsMethods,
    private userAccessService: PublicationsUserAccessService
  ) { }

  async ngOnInit() {
    getData$.subscribe(() => this.getOrganizations());
    this.getCountries();
  }

  private get resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required)
    })
  }

  private getOrganizations() {
    this.organizationsService.getOrganizations().subscribe(value => this.organizations = value);
  }

  private getCountries() {
    this.dictsService.getCountries().subscribe(value => {
      this.countries = value ?? [];
      // Россия вверху списка
      const index = this.countries.findIndex(item => item.name.toLowerCase().includes('росс'));
      const item = this.countries[index];
      this.countries.splice(index, 1);
      this.countries.unshift(item);
    });
  }

  public addRow() {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required)
    })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  public async editRow({sender, rowIndex, dataItem, columnIndex}: CellClickEvent) {
    if (this.isNew || this.isLine) {
      return;
    }

    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name),
      country: new FormControl(dataItem.country),
      city: new FormControl(dataItem.city)
    })

    if (!this.editable || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.onCancel();

    this.isLine = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public async saveRow()  {
    if (this.formGroup) await this.organizationMethods.saveOrganization(this.isNew, this.formGroup);
    this.onCancel();
  }

  public onRemove({dataItem}: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}?"`);
    this.organizationMethods.removeOrganization(dialog.result, dataItem);
  }
}

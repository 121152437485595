import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EducationPlanDetailsService } from "../../../../services/education/EducationPlan/education-plan-details.service";
import { DictCycle } from 'src/app/models/education/dictcycle.model';
import { DictCycleService } from 'src/app/services/education/DictCycle/dict-cycle.service';
import {RowClassArgs, SelectionEvent} from '@progress/kendo-angular-grid';
import { EducationPlanDetailsModels } from 'src/app/models/education/education-plan-details-model';
import { DictComponent } from 'src/app/models/education/dictcomponent.model';
import { DictComponentService } from 'src/app/services/education/DictComponent/dict-component.service';
import { DictWorkStudyPlanTypeService } from 'src/app/services/education/DictWorkStudyPlanType/dict-work-study-plan-type.service';
import { DictWorkStudyPlanType } from 'src/app/models/education/dictWorkStudyPlanType.model';
import { orderBy, findIndex, find, keyBy, merge, values, filter, sortBy } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { DictLessonTypeService } from 'src/app/services/education/DictLessonType/dict-lesson-type.service';
import { DictLessonType } from 'src/app/models/education/dictLessonType.model';
import { KafedraService } from 'src/app/services/education/Kafedra/kafedra.service';
import { Kafedra } from 'src/app/models/education/kafedra.model';
import { Discipline, DisciplineType } from 'src/app/models/education/discipline.model';
import { DictControlAction } from 'src/app/models/education/controlaction.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import {EducationUserAccessService} from "../../../../services/useraccess/education-user-access.service";
import {Location} from "@angular/common";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'discipline-form',
  templateUrl: './discipline-form.component.html',
  styleUrls: ['./discipline-form.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class DisciplineFormComponent implements OnInit {
  public isLoading = true
  public optionalMark = false
  public eduProgramId: string = '';
  public educationPlanId: string = '';
  public disciplineId: string | undefined = undefined;
  public disciplineFormGroup: FormGroup = new FormGroup({
    active: new FormControl(true, [Validators.required]),
    dictDisciplineTypeId: new FormControl('', [Validators.required]),
    dictDisciplineId: new FormControl('', [Validators.required]),
    dictDepartmentId: new FormControl('', [Validators.required]),
    dictCycleId: new FormControl('', [Validators.required]),
    dictComponentId: new FormControl('', [Validators.required]),
    isModularDiscipline: new FormControl(false),
    parentDisciplineId: new FormControl(),
    serialNumber: new FormControl(''),
  })
  public lessonTypeFormGroup: FormGroup = new FormGroup({
    dictLessonTypeId: new FormControl('', [Validators.required]),
  })
  public departments: Kafedra[] = []
  public disciplineTypes: DisciplineType[] = []
  public cycles: DictCycle[] = []
  public planComponents: DictComponent[] = []
  public filteredComponents: DictComponent[] = []
  public disciplines: Discipline[] = []
  public loadingDictDisciplines = true
  public filteredDisciplines: Discipline[] = []
  public filteredForElectiveDisciplines: Discipline[] = []
  public controlActions: DictControlAction[] = []
  public planDisciplines: EducationPlanDetailsModels.EPlanDiscipline[] = []
  public workStudyPlanTypes: DictWorkStudyPlanType[] = []
  public semesters: EducationPlanDetailsModels.EPlanSemester[] = []
  public controllingActions: EducationPlanDetailsModels.ControllingAction[] = []
  public isModularDiscipline = false
  public laborIntensities: any[] = []
  public laborHours: any[] = []
  public activeSemesters: any = []
  public dictLessonTypes: DictLessonType[] = []
  public defaultLessonType: DictLessonType | null = null
  public workStudyClassroomTypes: any[] = []
  public workStudyOtherTypes: any[] = []
  public workStudyIncount: string[] = []
  public typeWorks: any[] = []
  public typeWorksAdditionalsByKey: any = {}
  public modulDisciplines: EducationPlanDetailsModels.EDiscipline[] = [];
  public electiveDisciplines: Partial<EducationPlanDetailsModels.ElectiveDiscipline>[] = []
  public activeElectiveDiscipline: Partial<EducationPlanDetailsModels.ElectiveDiscipline> = {}
  public searchDisciplineByName = ''
  public searchModularDisciplineByName = ''
  public disciplineSelected: number[] = []
  public isFacultative: boolean = false;
  public typeWorksDisciplineAdditionals: any[] = [];

  constructor(
    public activateRoute: ActivatedRoute,
    private router: Router,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private kafedraService: KafedraService,
    private dictCycleService: DictCycleService,
    private dictComponentService: DictComponentService,
    private workStudyPlanTypeService: DictWorkStudyPlanTypeService,
    private dictLessonTypeService: DictLessonTypeService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService:EducationUserAccessService,
    private location:Location,
  ) {
    activateRoute.params.subscribe(params => {
      this.eduProgramId = params['education_program_id'];
      this.educationPlanId = params['education_plan_id'];
      this.disciplineId = params['discipline_id'];
      this.educationPlanDetailsService.currentDiscipline.dictCycleId = params['cycle_id'];
      this.educationPlanDetailsService.currentDiscipline.dictComponentId = params['component_id'];


      if (!this.eduProgramId || !this.educationPlanId) {
        this.router.navigate([`/education/`]);
      }
    });
  }

  async ngOnInit() {
    window.scrollTo(0, 0);
    await lastValueFrom(this.educationPlanDetailsService.getPlanInfo(this.educationPlanId))
    await this.getAccessLevel(this.educationPlanId);
    this.kafedraService.getAllKafedras().subscribe(
      departments => {
        this.departments = sortBy(departments, 'kafedraName');
        this.departments.forEach((item) => {
          item.mmisCode
              ? item.kafedraWithCode = item.mmisCode + ' - ' + item.kafedraName
              : item.kafedraWithCode = item.kafedraName;
        })
      }
    )
    this.dictCycleService.getPlanCycles(this.educationPlanId).subscribe(
      cycles => {
        this.cycles = orderBy(cycles, 'dictCycleExternalId');
      }
    )

    this.dictLessonTypeService.getAllLessonTypes().subscribe(
      _ => {
        this.dictLessonTypes = _.filter(item => item.standardTypeExternalId == this.educationPlanDetailsService.currentEducationPlan.dictStandardTypeId &&
          item.trainingLevelExternalId == this.educationPlanDetailsService.currentEducationPlan.dictTrainingLevelId &&
          item.dictLessonTypeIsActual == true);
        this.defaultLessonType = this.dictLessonTypes[0];
      }
    )
    this.educationPlanDetailsService.getControlActions().subscribe(
      controlActions => {
        this.controlActions = orderBy(controlActions, 'internalCode', 'desc').filter(item => item.standardTypeExternalId == this.educationPlanDetailsService.currentEducationPlan.dictStandardTypeId &&
          item.trainingLevelExternalId == this.educationPlanDetailsService.currentEducationPlan.dictTrainingLevelId);
      }
    )
    this.educationPlanDetailsService.getEduPlanDisciplines(this.educationPlanId).subscribe(
      (data) => {
        this.planDisciplines = data.items.filter(item => !!item.isModularDiscipline)
      }
    );
    this.dictComponentService.getEduPlanComponents(this.educationPlanId).subscribe(
      (planComponents) => {
        this.planComponents = planComponents
        // this.filteredComponents = planComponents
      }
    )
    this.disciplineTypes = await lastValueFrom(this.educationPlanDetailsService.getDisciplineTypes());
    this.disciplineTypes.sort(function(a: any, b: any) {
      if (a.orderNum === null) return b.orderNum === null ? -1 : b.orderNum;
      if (b.orderNum === null) return a.orderNum ? -1 : a.orderNum;

      return a.orderNum - b.orderNum
    });

    this.disciplines = await lastValueFrom(this.educationPlanDetailsService.getAllDisciplines())

    this.loadingDictDisciplines = false
    this.workStudyPlanTypes = await lastValueFrom(this.workStudyPlanTypeService.getAllWorkStudyPlanTypes())
    this.workStudyPlanTypes = this.workStudyPlanTypes.filter(item => item.standardTypeExternalId == this.educationPlanDetailsService.currentEducationPlan.dictStandardTypeId &&
      item.trainingLevelExternalId == this.educationPlanDetailsService.currentEducationPlan.dictTrainingLevelId);
    this.parseWorkStudyPlanTypes()
    if (this.disciplineId) {
      this.educationPlanDetailsService.getPlanDisciplineById(this.educationPlanId, this.disciplineId).subscribe(
        (discipline) => {
          this.isLoading = false
          this.disciplineFormGroup.patchValue(discipline)
          this.optionalMark = discipline.optionalMarks
          if(this.editable&&this.optionalMark&&this.educationPlanDetailsService.currentEducationPlan.status !== 1) this.disabled = false
          this.controllingActions = discipline.controllingActions
          this.electiveDisciplines = discipline.electiveDisciplines
          if(discipline.typeWorksDisciplineAdditionals.length > 0) {
            this.parseTypeWorksAdditionals(discipline.typeWorksDisciplineAdditionals[0].typeWorksAdditionals);
            this.typeWorksDisciplineAdditionals = discipline.typeWorksDisciplineAdditionals;
          }
          this.searchDisciplineByName = discipline.dictDisciplineName || ''
          this.onSearchDiscipline(this.searchDisciplineByName)
          this.onSearchModularDiscipline()
          this.onModularDisciplineToggle()
          this.parseControllingActions()
          this.filterPlanComponents(discipline.dictCycleId);
        }
      )
    } else {
      if (this.educationPlanDetailsService.currentDiscipline) {
        const { dictCycleId, dictComponentId } = this.educationPlanDetailsService.currentDiscipline
        if (dictCycleId) {
          this.filterPlanComponents(dictCycleId, dictComponentId)
        }
        this.disciplineFormGroup.patchValue({
          dictCycleId,
          dictComponentId: dictComponentId !== 'null' ? dictComponentId : this.filteredComponents[0].dictComponentExternalId,
          dictDisciplineTypeId: this.disciplineTypes.find((item) => item.name === 'Дисциплина')?.id,
        });
      }
      this.isLoading = false
      this.filteredDisciplines = this.disciplines
      this.filteredForElectiveDisciplines = this.disciplines
      this.parseTypeWorksAdditionals([]);
      this.parseControllingActions();
      this.onSearchDiscipline();
    }
  }

  onChangedisciplineType(dictDisciplineTypeId: string) {
    this.onSearchDiscipline()
  }

  goToDisciplineDict() {
    this.router.navigate(['dicts/discipline'])
  }

  onSelectDiscipline(event: SelectionEvent) {
    if(this.disabled)return
    const {id: dictDisciplineId, name} = event.selectedRows![0].dataItem
    this.searchDisciplineByName = name
    this.disciplineSelected = [this.filteredDisciplines.findIndex(item => item.name === name)]
    this.disciplineFormGroup.patchValue({ dictDisciplineId })
    this.educationPlanDetailsService.getSubjectPrevRelation(this.educationPlanId, dictDisciplineId).subscribe(
      (data: any) => {
        for (const key in data) {
          if (data[key]) {
            this.disciplineFormGroup.patchValue({key: data[key]})
          }
        }
      }
    )
  }

  onSearchDiscipline(name?: string): void {
    const searchBy = (name || this.searchDisciplineByName).toLowerCase()
    this.loadingDictDisciplines = true
    this.filteredDisciplines = filter(this.disciplines, (discipline) => {
      return discipline.name.toLowerCase().indexOf(searchBy) >= 0 &&
        discipline.dictDisciplineTypeId === this.disciplineFormGroup.value.dictDisciplineTypeId
    })
    if (name) {
      this.disciplineSelected = [this.filteredDisciplines.findIndex(item => item.name === name)]
    } else {
      this.disciplineSelected = []
    }
    setTimeout(() => {
      this.loadingDictDisciplines = false
    }, 300)
  }

  onSearchModularDiscipline(name?: string): void {
    const searchBy = (name || this.searchModularDisciplineByName).toLowerCase()
    this.loadingDictDisciplines = true
    this.filteredForElectiveDisciplines = filter(this.disciplines, (discipline) => {
      return discipline.name.toLowerCase().indexOf(searchBy) >= 0 &&
        discipline.dictDisciplineTypeId === this.disciplineFormGroup.value.dictDisciplineTypeId
    })
    setTimeout(() => {
      this.loadingDictDisciplines = false
    }, 300)
  }

  public onFocus(departmentcombobox: { isOpen: boolean; toggle: (arg0: boolean) => void; }) {
    if (!departmentcombobox.isOpen) {
      departmentcombobox.toggle(true);
    }
  }

  filterPlanComponents(cycleId: string, componentId?: string) {
    this.filteredComponents = this.planComponents.filter(item => item.dictCycleExternalId === cycleId);
    this.filteredComponents.sort((a: any, b: any) => parseFloat(a.serialNumber) - parseFloat(b.serialNumber));
    this.isFacultative = this.cycles.find((item) => item.dictCycleExternalId === cycleId)?.optionalMarks!;
    if (this.router.url.includes('add-discipline') && this.filteredComponents[0]?.dictComponentExternalId) {
      this.disciplineFormGroup.controls['dictComponentId'].setValue(
        componentId !== 'null' ? componentId : this.filteredComponents[0].dictComponentExternalId
      );
    }
  }

  getSemesterTitle(semester: EducationPlanDetailsModels.EPlanSemester) {
    return `${semester.number}(${semester.semesterDurationOrNumberSessions})`
  }

  parseWorkStudyPlanTypes() {
    this.workStudyPlanTypes.map((workStudyPlanType, index) => {
      if (workStudyPlanType.isRecognizingAmounts) {
        this.workStudyIncount.push(workStudyPlanType.dictWorkStudyPlanTypeExternalId)
      }
      if (workStudyPlanType.classroomWork) {
        this.workStudyClassroomTypes.push({
          ...workStudyPlanType,
          index,
          name: workStudyPlanType.dictWorkStudyPlanTypeName,
          type: 'typeWorkInput',
          hasPractice: true,
          total: 0,
        })
      } else {
        this.workStudyOtherTypes.push({
          ...workStudyPlanType,
          index,
          name: workStudyPlanType.dictWorkStudyPlanTypeName,
          type: 'typeWorkInput',
          total: 0,
        })
      }
    })
  }

  parseTypeWorksAdditionals(typeWorksDisciplineAdditionals: EducationPlanDetailsModels.TypeWork[]) {
    const allTypeWorksAdditionals = this.workStudyClassroomTypes.map(workStudyClassroomType => ({
      dictWorkStudyPlanTypeId: workStudyClassroomType.dictWorkStudyPlanTypeExternalId,
      filialId: workStudyClassroomType.filialExternalId,
      value: 0,
    }))
    this.typeWorksAdditionalsByKey = merge(
      keyBy(allTypeWorksAdditionals, 'dictWorkStudyPlanTypeId'),
      keyBy(typeWorksDisciplineAdditionals, 'dictWorkStudyPlanTypeId')
    )
  }

  parseControllingActions() {
    this.semesters = orderBy(this.educationPlanDetailsService.currentEducationPlan.semesterDuration, 'number')
    this.controllingActions.map(controllingAction => {
      const activeSemesterIndex = findIndex(this.semesters, { id: controllingAction.semesterId })
      if (activeSemesterIndex >= 0) {
        this.semesters[activeSemesterIndex].controlAction = controllingAction
      }
    })
    this.getActiveSemesters()
  }

  onCheckControlAction(semester: EducationPlanDetailsModels.EPlanSemester, controlAction: DictControlAction) {
    if(this.disabled)return
    if (semester.controlAction?.dictControlActionId === controlAction.id) {
      delete semester.controlAction
      const index = findIndex(this.activeSemesters, { number: semester.number })
      this.activeSemesters.splice(index, 1)
    } else {
      if (semester.controlAction) {
        const index = findIndex(this.activeSemesters, { number: semester.number })
        if (index >= 0) this.activeSemesters.splice(index, 1)
      }
      semester.controlAction = {
        dictControlActionId: controlAction.id,
        semesterId: semester.id,
        totalHours: 0,
        typeWorks: this.workStudyPlanTypes.map(workStudyPlanType => ({
          dictWorkStudyPlanTypeId: workStudyPlanType.dictWorkStudyPlanTypeExternalId,
          filialId: workStudyPlanType.filialExternalId,
          value: 0,
        })),
      }
      this.activeSemesters = orderBy([...this.activeSemesters, semester], 'number')
    }
    this.prepareLaborHours();
  }

  onExtraWorkToggle(semester: EducationPlanDetailsModels.EPlanSemester, type: 'hasCourseProject' | 'hasCourseWork') {
    if (!semester.controlAction) return
    semester.controlAction[type] = semester.controlAction[type] ? false : true
  }
public allHours: number = 0;
public finalSumHours: number = 0;

  getActiveSemesters() {
    const activeSemesters: any = []
    this.semesters.map((semester: any) => {
      if (semester.controlAction) {
        activeSemesters.push(semester)
      }
    })
    this.activeSemesters = orderBy(activeSemesters, 'number')
    this.prepareLaborHours()
  }

  calculateTotalTypeWorkHoursById(id: string) {
    let total = 0
    this.activeSemesters.map((activeSemester: any) => {
      total += activeSemester.controlAction.typeWorksByKey[id]?.value || 0
    })
    return total
  }

  allSemestersCalculateTotalByParam(param: string) {
    let total = 0
    this.activeSemesters.map((activeSemester: any) => {
      total += activeSemester.controlAction[param] || 0
    })
    return total
  }

  calculateTotalAdditionalTypeWorkHours() {
    let total = 0
    for (const key in this.typeWorksAdditionalsByKey) {
      total += this.typeWorksAdditionalsByKey[key].value || 0
    }
    return total
  }

  prepareLaborHours() {
    this.activeSemesters = this.activeSemesters.map((activeSemester: any) => {
      const controlAction = activeSemester.controlAction

      let hoursContactWork = 0
      const typeWorksByKey: any = keyBy(controlAction.typeWorks, 'dictWorkStudyPlanTypeId')
      for (const key in typeWorksByKey) {
        if (this.workStudyIncount.includes(key)) {
          hoursContactWork = hoursContactWork + typeWorksByKey[key].value
        }
      }
      const control = controlAction.control || 0 //часы на контроль
      const hoursCredited = controlAction.hoursCredited || 0 //перезачтенные часы
      const totalLaborHours = controlAction.totalHours// + control //всего часов
      let hoursSelfWork = controlAction.totalHours - hoursContactWork - control - hoursCredited //сам. работа (не меньше нуля)
      if (hoursSelfWork < 0) hoursSelfWork = 0

      const disciplineType = this.disciplineTypes.filter(_ => _.id == this.disciplineFormGroup.value.dictDisciplineTypeId);
      //дисциплины без трудоемкости, например элективная физическая культура
      const skipLabourIntensity = disciplineType && disciplineType.length>0 && disciplineType[0].hiddenLaborIntensity;

      const dictLessonTypeId = controlAction.dictLessonTypeId || this.defaultLessonType?.dictLessonTypeExternalId
      const totalLaborIntensity = skipLabourIntensity ? 0 : Math.round((totalLaborHours / 36) * 100) / 100
      const selfTaught = skipLabourIntensity ? 0 : Math.round(((totalLaborHours - hoursCredited) / 36) * 100) / 100
      return {
        ...activeSemester,
        controlAction: {
          ...controlAction,
          control,
          dictLessonTypeId,
          hoursCredited,
          hoursContactWork,
          hoursSelfWork,
          typeWorksByKey,
          totalLaborHours,
          totalLaborIntensity,
          selfTaught,
        }
      }
    })
    this.workStudyClassroomTypes = this.workStudyClassroomTypes
      .filter(f => f.filialExternalId === this.educationPlanDetailsService.currentEducationPlan.filialId)
      .map(workStudyClassroomType => {
      const id = workStudyClassroomType.dictWorkStudyPlanTypeExternalId
      return {
        ...workStudyClassroomType,
        total: this.calculateTotalTypeWorkHoursById(id)
      }
    })
    this.workStudyOtherTypes = this.workStudyOtherTypes
      .filter(f => f.filialExternalId === this.educationPlanDetailsService.currentEducationPlan.filialId)
      .map(workStudyOtherType => {
      return {
        ...workStudyOtherType,
        total: this.calculateTotalTypeWorkHoursById(workStudyOtherType.dictWorkStudyPlanTypeExternalId)
      }
    })
    this.laborHours = [
      {
        name: 'Всего', type: 'input', value: 'totalHours',
        total: this.allSemestersCalculateTotalByParam('totalHours'),
        isBold: true,
        hasPractice: true,
        totalPractice: this.calculateTotalAdditionalTypeWorkHours(),
      },
      ...this.workStudyClassroomTypes,
      { name: 'Контактная работа, ч.', type: 'text', value: 'hoursContactWork', total: this.allSemestersCalculateTotalByParam('hoursContactWork'), isBold: true },
      { name: 'Самостоятельная работа, ч.', type: 'text', value: 'hoursSelfWork', total: this.allSemestersCalculateTotalByParam('hoursSelfWork'), isBold: true },
      { name: 'Перезачтенные часы, ч.', type: 'input', value: 'hoursCredited', total: this.allSemestersCalculateTotalByParam('hoursCredited'), isBold: true },
      { name: 'Контроль, ч.', type: 'input', value: 'control', total: this.allSemestersCalculateTotalByParam('control'), isBold: true },
      ...this.workStudyOtherTypes,
      //{ items: this.dictLessonTypes, type: 'radio', name: 'dictLessonTypeName', model: 'dictLessonTypeExternalId', value: 'dictLessonTypeId' },
      { name: 'Распределенная практика', type: 'checkbox', value: 'isDistributed' },
    ]

    if (this.dictLessonTypes.length > 0) {
      this.laborHours.push({ items: this.dictLessonTypes, type: 'radio', name: 'dictLessonTypeName', model: 'dictLessonTypeExternalId', value: 'dictLessonTypeId'})
    }

    this.allHours = this.allSemestersCalculateTotalByParam('totalHours');
    this.finalSumHours = this.allSemestersCalculateTotalByParam('hoursContactWork') + this.allSemestersCalculateTotalByParam('hoursSelfWork') +
                         this.allSemestersCalculateTotalByParam('hoursCredited') + this.allSemestersCalculateTotalByParam('control');

    this.laborIntensities = [
      {
        name: 'Трудоемкость, ч',
        value: 'totalLaborHours',
        total: this.allSemestersCalculateTotalByParam('totalLaborHours')
      },
      {
        name: 'Трудоемкость, з.ед',
        value: 'totalLaborIntensity',
        total: this.allSemestersCalculateTotalByParam('totalLaborIntensity')
      },
      {
        name: 'К освоению, з.ед',
        value: 'selfTaught',
        total: this.allSemestersCalculateTotalByParam('selfTaught')
      }
    ]
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  onLaborHoursChange(dataItem: any) {
    this.prepareLaborHours()
  }

  onModularDisciplineToggle() {
    const isModularDiscipline = this.disciplineFormGroup.value.isModularDiscipline
    this.isModularDiscipline = isModularDiscipline
    if (isModularDiscipline) {
      this.disciplineFormGroup.controls['dictDepartmentId'].disable()
      this.disciplineFormGroup.controls['parentDisciplineId'].disable()
      if (this.disciplineId) {
        const modulDiscipline = find(this.planDisciplines, { id: this.disciplineId })
        this.modulDisciplines = modulDiscipline ? modulDiscipline.subDisciplines : []
      }
    } else {
      this.disciplineFormGroup.controls['dictDepartmentId'].enable()
      this.disciplineFormGroup.controls['parentDisciplineId'].enable()
    }

    if(this.disabled){
      this.disciplineFormGroup.controls['dictDepartmentId'].disable()
      this.disciplineFormGroup.controls['parentDisciplineId'].disable()
    }
  }

  onSelectElectiveDiscipline(event: SelectionEvent) {
    const { id, name } = event.selectedRows![0].dataItem
    this.activeElectiveDiscipline = {
      active: true,
      dictDisciplineId: id,
      dictDisciplineName: name,
      serialNumber: this.electiveDisciplines.length + 1,
      semesterWorkElectives: [],
    }

    this.educationPlanDetailsService.getSubjectPrevRelation(this.educationPlanId, id).subscribe(
      ({ dictDepartmentId }) => {
        this.activeElectiveDiscipline.departmentId = dictDepartmentId
      }
    )
  }

  onAddElectiveDiscipline() {
    if (!this.activeElectiveDiscipline.dictDisciplineId) {
      return this.notificationService.showError("Необходимо выбрать дисциплину");
    }
    if (this.activeElectiveDiscipline.departmentId) {
      this.electiveDisciplines.push({ ...this.activeElectiveDiscipline })
      // this.activeElectiveDiscipline = {}
    } else {
      this.notificationService.showError("Необходимо выбрать кафедру");
    }
  }

  onDeleteElectiveDiscipline(index: number) {
    this.electiveDisciplines.splice(index, 1)
  }

  goBackToPlan() {
    this.location.back();
  }

  saveSuccess() {
    this.notificationService.showSuccess("Дисциплина сохранена");
    this.goBackToPlan()
  }

  async onSave() {
    if (!this.disciplineFormGroup.valid) {
      this.disciplineFormGroup.markAllAsTouched();
      this.notificationService.showError("Не все обязательные поля заполнены");
      return;
    }
    if (!this.isModularDiscipline && this.activeSemesters.length === 0) {
      this.notificationService.showError("Необходимо добавить семестр");
      return;
    }
    if (this.finalSumHours > this.allHours ) return this.notificationService.showError('Введенное количество часов превышает суммарное количество часов по дисциплине.');
    // Fix values of course projects and course works
    this.semesters.forEach((semester: any, index) => {
      if (semester.controlAction) {
        const activeSemesterIndex = this.activeSemesters.findIndex((item: { controlAction: { id: any; }; }) => item.controlAction.id === semester.controlAction.id);
        this.activeSemesters[activeSemesterIndex].controlAction.hasCourseProject = semester.controlAction.hasCourseProject;
        this.activeSemesters[activeSemesterIndex].controlAction.hasCourseWork = semester.controlAction.hasCourseWork;
      }
    })

    const controllingActions = this.activeSemesters.map((activeSemester: any) => {
      const typeWorks = values(activeSemester.controlAction.typeWorksByKey).filter(f => f.filialId === this.educationPlanDetailsService.currentEducationPlan.filialId)
      return {
        ...activeSemester.controlAction,
        typeWorks,
        typeWorksByKey: null
      }
    })
    const typeWorkAdditionals = values(this.typeWorksAdditionalsByKey).filter(f => f.filialId === this.educationPlanDetailsService.currentEducationPlan.filialId)
    const typeWorksDisciplineAdditionals = [{
      dictTypeWorkDisciplineAdditionalId: "15bb1c2e-4bc4-4158-b7ea-bde4b4eeac98", // hardcoded Practice
      typeWorkAdditionals
    }]
    const saveDisciplineObject = {
      educationPlanId: this.educationPlanId,
      ...this.disciplineFormGroup.value,
      controllingActions,
      typeWorksDisciplineAdditionals,
      electiveDisciplines: this.electiveDisciplines,
    }

    this.isLoading = true
    try {
      if (!this.disciplineId) {
        await lastValueFrom(this.educationPlanDetailsService.addDiscipline(this.educationPlanId, saveDisciplineObject))
      } else {
        await lastValueFrom(this.educationPlanDetailsService.updateDiscipline(this.educationPlanId, {
          ...saveDisciplineObject,
          id: this.disciplineId,
        }))
      }
      this.saveSuccess()
    } catch {
      this.isLoading = false
    }
  }

  public disabled: boolean = false;
  public editable?: boolean;

  public async getAccessLevel(educationPlanId:string) {
    await lastValueFrom(this.userAccessService.getPlanEditAccessLevel(educationPlanId))
      .then(
        response => {
          this.editable = response.electiveAccessLevel;
          this.disabled = !response.accessLevel||this.educationPlanDetailsService.currentEducationPlan.status !== 1;
          if(this.disabled) {
            this.disciplineFormGroup.controls['active'].disable()
            this.disciplineFormGroup.controls['isModularDiscipline'].disable()
            this.disciplineFormGroup.controls['dictComponentId'].disable()
          }
        }
      );
  }



  onDelete(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить дисциплину?",
      actions: [{ text: "Нет" }, { text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) { }
      else {
        if (result.text == "Да") {
          this.educationPlanDetailsService.deleteDiscipline(this.educationPlanId, this.disciplineId!).subscribe(() => {
            this.notificationService.showSuccess("Дисциплина удалена");
            this.goBackToPlan()
          })
        }
      }
    });

  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
}

import {Injectable} from "@angular/core";
import {catchError, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictAuthorRole} from "../../../models/publications/dict/authorRole.model";
import {handleError} from "../../../helpers/publications/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class AuthorRolesService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/AuthorRole/`;

  constructor(private http: HttpClient) {
  }

  public getAuthorRoles(): Observable<DictAuthorRole[]> {
    return this.http.get<DictAuthorRole[]>(this.baseUrl);
  }

  public addAuthorRole(authorRole: DictAuthorRole): Observable<DictAuthorRole> {
    return this.http.post<DictAuthorRole>(this.baseUrl, authorRole)
      .pipe(catchError(handleError));
  }

  public removeAuthorRole(id: string): Observable<DictAuthorRole> {
    return this.http.delete<DictAuthorRole>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }

  public updateAuthorRole(authorRole: DictAuthorRole): Observable<DictAuthorRole> {
    return this.http.put<DictAuthorRole>(this.baseUrl, authorRole)
      .pipe(catchError(handleError));
  }
}

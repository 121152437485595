import { Guid } from "guid-typescript";
import { Faculty } from "./faculty.model";

export class Kafedra {
    public id!: number;
    public kafedraId!: Guid | string;
    public kafedraName!: string;
    public kafedraShortName!: string;
    public facultyId!: number;
    public mmisCode?: string;
    public faculty!: Faculty;
    // Filters
    public kafedraWithCode?: string;
}

<kendo-grid [data]="activeCompetence ? [activeCompetence] : competenceMatrix" [height]="activeCompetence ? 110 : 600"
  (cellClick)="onEdit($event)" [loading]="loadingCompetenceMatrix">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoGridNoRecordsTemplate></ng-template>
  <kendo-grid-column field="cipher" title="Шифр" [width]="125"></kendo-grid-column>
  <kendo-grid-column field="disciplineName" title="Дисциплина" class="text-left">
  </kendo-grid-column>
  <kendo-grid-column field="departmentName" title="Кафедра" [width]="300">
  </kendo-grid-column>
  <kendo-grid-column *ngIf="false" field="totalLaborIntensity" title="Трудоемкость" [width]="180">
  </kendo-grid-column>
  <kendo-grid-column field="competencies" title="Компетенции" [width]="180">
  </kendo-grid-column>
</kendo-grid>

<div *ngIf="activeCompetence">
  <h4 class="title k-mt-7 k-mb-5">Список компетенций</h4>
  <kendo-grid *ngIf="canEdit" [data]="allCompetencies" [height]="373" [hideHeader]="true" class="with-outborder">
    <ng-template kendoGridNoRecordsTemplate></ng-template>
    <kendo-grid-column [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span (click)="onSelectCompetence(dataItem)">
          <input type="checkbox" kendoCheckBox
            [checked]="selectedCompetenciesIds.includes(dataItem.externalId)" />
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column class="text-left">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>
          {{dataItem.code}} - {{dataItem.description}}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div *ngIf="canEdit" class="k-display-flex k-justify-content-end k-mt-5 k-mb-6">
    <button kendoButton (click)="onCancel()" class="k-mr-3">Отмена</button>
    <button kendoButton *ngIf="editable" themeColor="primary" (click)="onSave()" [disabled]="isSaving">Сохранить</button>
  </div>

  <kendo-grid [data]="selectedCompetencies">
    <ng-template kendoGridNoRecordsTemplate></ng-template>
    <kendo-grid-column title="Код" field="code" [width]="70"></kendo-grid-column>
    <kendo-grid-column title="Описание" field="description" class="text-left">
    </kendo-grid-column>
  </kendo-grid>
  <div class="k-display-flex k-justify-content-end k-mt-5 k-mb-6">
    <button kendoButton (click)="onCancel()" class="k-mr-3">Отмена</button>
    <button kendoButton themeColor="primary" *ngIf="canEdit&&editable" (click)="onSave()" [disabled]="isSaving">Сохранить</button>
  </div>
</div>

import {Component, OnInit} from "@angular/core";
import {GeneralDictsService} from '../../../../services/diplomablanks/general-dicts.service';
import {pagerSettings} from 'src/app/models/diplomablanks/pagerSettings.model';
import {JournalBlanksService} from '../../../../services/diplomablanks/journal-blanks.service';
import {journalBlanksModel} from '../../../../models/diplomablanks/journal-blanks.model';
import {DateFromUTCAsLocal, TimeZoneFix} from '../../../../helpers/date-helper';
import {BlanksAvailableService} from '../../../../services/diplomablanks/blanks-available.service';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DownloadFile} from "../../../../helpers/downloadFile-helper";

@Component({
    selector: 'app-journalblanks',
    templateUrl: './journal-blanks.component.html',
    styleUrls: ['./journal-blanks.component.scss']
  })

export class JournalBlanksComponent implements OnInit {

  public years: number[] = [];
  public year: number | null = null;

  public blanks: journalBlanksModel[] = [];
  public loading: boolean = false;
  protected pageSize = 20;
  protected readonly pagerSettings = pagerSettings;

  public blanksType: Array<{dictBlankTypeName: string}> = [];
  public issuePersons: Array<{issuerPersonFio: string}> = [];
  public recipientPersons: Array<{recipientPersonFio: string}> = [];

  constructor(
    private generalDictsService: GeneralDictsService,
    private journalBlanksService: JournalBlanksService,
    private blanksAvailableService: BlanksAvailableService,
    private notificationService: NotificationsService,
  ) { }

  public ngOnInit(): void {
    this.getYears();
  }

  public getYears() {
    this.generalDictsService.getYear()
      .subscribe(response => {
        this.years = response;
        this.year = this.years[0];

        this.getBlanks();
    })
  }

  public getBlanks() {
    if (this.year) {
      this.journalBlanksService.getJournalBlanks(this.year)
        .subscribe(response => {
          if (response) this.blanks = response;

          this.blanks.map((item) => {
            item.issueFilterDate = new Date(item.issueDate);
          });

          this.getFiltersTable();
        })
      }
  }

  private getFiltersTable() {
    const issuePerson = [...new Set(this.blanks.map(el => el.issuerPersonFio))];
    this.issuePersons = issuePerson.map(function(value) {
        return {issuerPersonFio: value};
    });

    const recipientPerson = [...new Set(this.blanks.map(el => el.recipientPersonFio))];
    this.recipientPersons = recipientPerson.map(function(value) {
        return {recipientPersonFio: value};
    });

    const blankType = [...new Set(this.blanks.map(el => el.dictBlankTypeName))];
    this.blanksType = blankType.map(function(value) {
        return {dictBlankTypeName: value};
    });
  }

  public onChange(value: number) {
    this.getBlanks();
  }

  public downloadReceipt(dataItem: journalBlanksModel) {
    const data = {
      issuerPersonId: dataItem.issuerPersonId,
      recipientPersonId: dataItem.recipientPersonId,
      issueDate: dataItem.issueDate,
      dictBlankTypeId: dataItem.dictBlankTypeId,
    }

    this.blanksAvailableService.getReceipt(data).subscribe({
      next: (value) => {
        DownloadFile(value);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }

}

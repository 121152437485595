import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {Filial} from "../../models/persondepartment/filial.model";

@Injectable({
  providedIn: 'root'
})
export class FilialService {

  baseUrl = `${environment.lkPersonApiEndpoint}`;

  constructor(private http: HttpClient) { }

  //Get all filials
  public getAllFilials(): Observable<Filial[]> {
    return this.http.get<Filial[]>(this.baseUrl + 'filial');
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(() => {
      errorMessage;
    });
  }
}

import {Injectable} from "@angular/core";
import {BehaviorSubject, catchError, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {handleError} from "../../../helpers/publications/errorHandle-helper";
import {PublicationForm} from "../../../models/publications/editForm/publication.model";
import {PublicationsGet} from "../../../models/publications/mainPage/publication.model";

@Injectable({
  providedIn: 'root'
})

export class PublicationsService {
    private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.publications.main}`;

    public DOIPublicationTypes: string[] = ['Статья в журнале', 'Препринт', 'Статья в сборнике или глава в книге', 'Диссертация'];
    public MPKPublicationTypes: string[] = ['Патент'];
    public ISBNPublicationTypes: string[] = ['Книга или сборник статей', 'Сборник трудов конференции', 'Статья в сборнике трудов конференции'];
    public sourcePublicationTypes: string[] = ['Статья в журнале', 'Статья в сборнике трудов конференции'];

    public filters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private http: HttpClient) {
    }

    public getPublications(query: {allPublication: boolean,
        properties?: string[],
        page?: {size?: number, index?: number},
        filterBy?: any[], orderBy?: any[]}): Observable<PublicationsGet> {
        return this.http.post<PublicationsGet>(`${this.baseUrl}/Get/`, query);
    }

    public getPublication(id: string): Observable<PublicationForm> {
        return this.http.get<PublicationForm>(`${this.baseUrl}/${id}`);
    }

    public getPublicationCopy(id: string): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/Copy/${id}`);
    }

    public savePublication(publication: PublicationForm): Observable<string | boolean> {
        if (publication.id) {
            return this.http.put<string | boolean>(`${this.baseUrl}/Update`, publication)
                .pipe(catchError(handleError));
        } else {
            return this.http.post<string | boolean>(`${this.baseUrl}/Create`, publication)
                .pipe(catchError(handleError));
        }
    }

    public removePublication(publicationId: string): Observable<PublicationForm> {
        return this.http.delete<PublicationForm>(`${this.baseUrl}/Delete/${publicationId}`)
            .pipe(catchError(handleError));
    }

    public getYears(): Observable<number[]> {
        return this.http.get<number[]>(`${this.baseUrl}/ListOfPublicationYears`);
    }
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { handleError } from '../../helpers/publications/errorHandle-helper';
import { LocalSignatoryRole } from '../../models/gia/signatory/localsignatory.model';

@Injectable({
  providedIn: 'root',
})
export class DictLocalSignatoryRoleService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.gia.localSignatoryRole}`;

  constructor(private http: HttpClient) {}

  public getLocalSignatoryRoles(): Observable<LocalSignatoryRole[]> {
    return this.http
      .get<LocalSignatoryRole[]>(this.baseUrl)
      .pipe(catchError(handleError));
  }

  public updateLocalSignatoryRole(
    dictLocalSignatoryRole: LocalSignatoryRole,
  ): Observable<LocalSignatoryRole> {
    return this.http.put<LocalSignatoryRole>(
      this.baseUrl,
      dictLocalSignatoryRole,
    );
  }
}

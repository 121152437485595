import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, tap, throwError, mergeMap } from 'rxjs';
import { ErrorService } from 'src/app/services/education/error.service';
import { environment } from 'src/environments/environment';
import urlJoin from 'url-join';

import { EducationPlanSelectModel } from 'src/app/models/education/education-program-select.model';

@Injectable({
  providedIn: 'root',
})
export class EducationProgramSelectService {
  baseUrl = urlJoin(<string>environment.apiEndpoint, "v1", environment.apiPaths.education.educationPlan);

  constructor(private httpClient: HttpClient, private errorService: ErrorService) {}

  ePrograms: EducationPlanSelectModel.EducationProgram[] = [];

  select(): Observable<EducationPlanSelectModel.EducationProgram[]> {
    return this.httpClient
      .get<EducationPlanSelectModel.EducationProgram[]>(
        urlJoin(this.baseUrl, 'educationProgram'),
        {}
      )
      .pipe(
        retry(2),
        tap((ePrograms) => (this.ePrograms = ePrograms)),
        catchError(this.errorHandler.bind(this))
      );
  }

  private errorHandler(error: HttpErrorResponse) {
    this.errorService.handle(error.message);
    return throwError(() => error.message);
  }
}

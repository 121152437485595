import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Observable, throwError } from 'rxjs';
import { EmploymentRead } from 'src/app/models/contingent/employment.model';

@Injectable({
    providedIn: 'root'
  })

export class EmploymentService {

    baseUrl = `${environment.apiEndpoint}`;

    constructor(private http: HttpClient) { }

    public getEmployment(studentId: string) {
        return this.http.get<EmploymentRead[]>(this.baseUrl + 'contingent/employment/' + studentId)
          .pipe(catchError(this.handleError));
    }

      // Error
    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
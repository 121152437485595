import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { State } from "@progress/kendo-data-query";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Guid } from 'guid-typescript';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { DictGroupStandardLimitation } from 'src/app/models/education/dictGroupStandardLimitation.model';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';
import { DictGroupStandardLimitationService } from 'src/app/services/education/StandardLimitations/dict-group-standard-limitation.service';

@Component({
  selector: 'dict-group-standard-limitation.component',
  templateUrl: './dict-group-standard-limitation.component.html',
  styleUrls: ['./dict-group-standard-limitation.component.css']
})
export class DictGroupStandardLimitationComponent {
  public dictGroups!: DictGroupStandardLimitation[];
  public trainingLevels!: TrainingLevel[];
  public standardTypes!: StandardType[];
  public defaultGuidValue: any = Guid.createEmpty();

  public defaultStandardType: StandardType = {
    standardTypeName: "Не выбрано",
    standardTypeExternalId: null,
    standardTypeId: 0,
    standardTypeShortName: "Не выбрано",
    standardTypePrintName: "Не выбрано"
  }

  public defaultTrainingLevel: TrainingLevel = {
    trainingLevelName: "Не выбрано",
    trainingLevelExternalId: null,
    trainingLevelId: 0,
    trainingLevelShortName: "Не выбрано",
    trainingLevelProfileName: "Не выбрано"
  }

  public dictGroupStandardLimitation: DictGroupStandardLimitation = {
    dictGroupStandardLimitationExternalId: null,
    groupStandardLimitationName: '',
    dictTrainingLevelId: null,
    trainingLevel: null,
    dictStandardTypeId: null,
    standardType: null
  };

  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;
  public loading = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
      private dictGroupStandardLimitationService: DictGroupStandardLimitationService,
      private standardTypeService: StandardtypeService,
      private trainingLevelService: TrainingLevelService,
      private renderer: Renderer2,
      private notificationService: NotificationsService,
      private dialogService: DialogService,
      private userAccessService:EducationUserAccessService
    ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getAllStandardType();
    this.getAllTrainingLevel();
    this.getAllDictGroupStandardLimitation();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Get StandardLimitationSetting name for id
  // public nameStandardLimitationSettingtype(id: Guid): StandardLimitationSettingType | undefined {
  //   return this.StandardLimitationSettingtypes.find((x) => x.dictStandardLimitationSettingTypeExternalId === id);
  // }

  public onStateChange(state: State): void {
    this.getAllDictGroupStandardLimitation();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      groupStandardLimitationName: undefined,
      dictTrainingLevelId: undefined,
      dictStandardTypeId: undefined,
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    isEdited,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if(!this.editable)return
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew == true && this.formGroup !== undefined) {

        this.dictGroupStandardLimitationService.addDictGroupStandardLimitation(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllDictGroupStandardLimitation();
              this.notificationService.showSuccess("Добавлено");
              this.dictGroupStandardLimitation = {
                dictGroupStandardLimitationExternalId: null,
                groupStandardLimitationName: '',
                dictTrainingLevelId: null,
                trainingLevel: null,
                dictStandardTypeId: null,
                standardType: null
              }
            },
            error => {
              this.notificationService.showError("Эта запись уже существует");
            }
          );
      }
      //Update group
      else if (this.formGroup !== undefined && this.formGroup.value.groupStandardLimitationName != '') {
        if (this.getDictGroupById(this.formGroup.value.dictGroupStandardLimitationExternalId)?.groupStandardLimitationName ==
          this.formGroup.value.groupStandardLimitationName) {
            this.formGroup.value.groupStandardLimitationName = undefined
        }

        this.dictGroupStandardLimitationService.updateDictGroupStandardLimitation(this.formGroup?.value)
          .subscribe(
            response => {
              this.getAllDictGroupStandardLimitation();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }
 
  public getTrainingLevelById(id: string) {
    return this.trainingLevels.find((x) => x.trainingLevelExternalId === id)
  }

  public getStandardTypeById(id: string) {
    return this.standardTypes.find((x) => x.standardTypeExternalId === id);
  }

  public getDictGroupById(id: Guid) {
    return this.dictGroups.find((x) => x.dictGroupStandardLimitationExternalId === id);
  }

  //Getting all entries from dictionary
  public getAllTrainingLevel() {
    this.trainingLevelService.getAllTrainingLevels()
      .subscribe(
        response => {
          this.trainingLevels = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllDictGroupStandardLimitation() {
    this.loading = true;
    this.dictGroupStandardLimitationService.getAllDictGroupStandardLimitation()
      .subscribe(
        response => {
          this.loading = false;
          this.dictGroups = response;
        },
        error => {
          this.loading = false;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllStandardType() {
    this.standardTypeService.getAllStandardTypes()
      .subscribe(
        response => {
          this.standardTypes = response;
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = !response.dictAccessLevel;
    },error => {});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    ;
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить?",
      actions: [ { text: "Нет" }, { text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        this.close();
      } else if(result.text == "Да") {
        this.opened = false;
        this.dictGroupStandardLimitationService.deleteDictGroupStandardLimitation(dataItem.dictGroupStandardLimitationExternalId)
        .subscribe(
          response => {
            this.getAllDictGroupStandardLimitation();
            this.notificationService.showSuccess("Удалено");
          },
          error => {
            this.notificationService.showError("Группа используется справочником");
          }
        );
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

}


const formGroup = (dataItem: { groupStandardLimitationName?: any; dictTrainingLevelId?: any; dictStandardTypeId?: any; dictGroupStandardLimitationExternalId?: any; }) =>
  new FormGroup({
    groupStandardLimitationName: new FormControl(dataItem.groupStandardLimitationName, Validators.required),
    dictTrainingLevelId: new FormControl(dataItem.dictTrainingLevelId),
    dictStandardTypeId: new FormControl(dataItem.dictStandardTypeId),
    dictGroupStandardLimitationExternalId: new FormControl(dataItem.dictGroupStandardLimitationExternalId)
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

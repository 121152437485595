<classroom-header></classroom-header>
<div class="grid-layout-container m-t20"> 
    <div class="main-title"> Аудиторный фонд </div>

    <div class="line-filter"> 
        <div kendoTooltip class="tilesselectedfilters">

        <!--FilterView-->
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(1)" *ngIf="filter.titleClass?.length!=0 && filters">
          Название: {{filter.titleClass}}
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(2)" *ngIf="filter.arrayOfBuildings!=undefined && 
        filter.arrayOfBuildings.length!= 0 && filters">
          Корпус: {{ filter.arrayOfBuildings.length }} выбрано
        </button>
       <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(3)" *ngIf="filter.arrayOfTypes!=undefined && 
       filter.arrayOfTypes.length!=0 && filters">
          Тип аудитории: {{ filter.arrayOfTypes.length }} выбрано
        </button>
         <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(4)" *ngIf="filter.arrayOfOwners!=undefined && 
         filter.arrayOfOwners.length!=0 && filters">
          Владелец: {{filter.arrayOfOwners.length }} выбрано
        </button>
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(5)" *ngIf="filter.arrayOfAssigns!=undefined && 
        filter.arrayOfAssigns.length!=0 && filters">
          Назначение аудитории: {{filter.arrayOfAssigns.length}} выбрано
        </button> 
        <button kendoButton icon="x" size="small" themeColor="info" rounded="full" (click)="oneFilterClean(6)" *ngIf="filter.numberClass?.length!=0 && filters">
          Номер: {{filter.numberClass }}
        </button>
    
        <button kendoButton icon="x" size="small" themeColor="light" rounded="full" (click)="filterClean()" *ngIf="filters">
          Очистить фильтры
        </button>
      </div>
    </div>

    <div class="filter-container">
        <kendo-expansionpanel subtitle="Все фильтры " class="panel" [(expanded)]="expandedState">
            <ng-template kendoExpansionPanelTitleDirective>
                <h2>Все фильтры</h2>
                <kendo-label class="count">Аудиторий найдено: {{(count == null? "0" : count)}}</kendo-label>
            </ng-template>

            <div class="search-block">
                <div class="filter-fund">
                    <div>
                        <div class="m-b20"> Корпус </div>
                        <kendo-multiselect
                                class="max-w350"
                                [data]="buildings"
                                kendoMultiSelectSummaryTag
                                placeholder="Все"
                                textField="buildingName"
                                valueField="buildingId"                           
                                [autoClose]="false"
                                [sortable]="true"
                                [filterable]="true"
                                [kendoDropDownFilter]="filterSettings"
                                [checkboxes]="true"
                                (valueChange)="selectionChange($event)"                          
                                [valuePrimitive]="true"
                                name="building"
                                [(ngModel)]="filter.arrayOfBuildings"                           
                                >
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                    <span></span>
                                        {{ dataItems.length }} выбрано
                                    </ng-template>
                        </kendo-multiselect>                          
                    </div>
                    <div>
                        <div class="m-b20"> Тип аудитории </div>
                        <kendo-multiselect
                                class="max-w350"
                                [data]="filterTypes"
                                kendoMultiSelectSummaryTag
                                placeholder="Все"
                                textField="classRoomTypeName"
                                valueField="classRoomTypeId"                           
                                [autoClose]="false"
                                [filterable]="true"
                                (filterChange)="typeFilter($event)"
                                [checkboxes]="true"
                                (valueChange)="selectionChange($event)"                           
                                [valuePrimitive]="true"
                                name="type"
                                [(ngModel)]="filter.arrayOfTypes"                           
                                >
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                    <span></span>
                                        {{ dataItems.length }} выбрано
                                    </ng-template>
                        </kendo-multiselect> 
                    </div>
                    <div>
                        <div class="m-b20"> Название </div>
                        <kendo-textbox class="search-input max-w350"
                                       placeholder="Название аудитории"
                                       [clearButton]="true"
                                       name="title-class"
                                       (valueChange)="selectionChange($event)"                                                 
                                       [(ngModel)]="filter.titleClass"
                        >
                        </kendo-textbox>
                    </div>
                    <div>
                        <div class="m-b20"> Номер </div>
                        <kendo-textbox class="search-input max-w350"
                                       placeholder="Номер аудитории"
                                       [clearButton]="true"
                                       (valueChange)="selectionChange($event)"
                                       name="number"                                                        
                                       [(ngModel)]="filter.numberClass"
                        >
                        </kendo-textbox>
                    </div>
                    <div>
                        <div class="m-b20"> Владелец </div>
                        <kendo-multiselect
                                class="max-w350"
                                [data]="filterOwners"
                                kendoMultiSelectSummaryTag
                                placeholder="Все"
                                textField="departmentName"
                                valueField="departmentId"                           
                                [autoClose]="false"
                                [checkboxes]="true"
                                [filterable]="true"
                                (filterChange)="ownerFilter($event)"
                                (valueChange)="selectionChange($event)"                           
                                [valuePrimitive]="true"
                                name="owner"
                                [(ngModel)]="filter.arrayOfOwners"                           
                                >
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                    <span></span>
                                        {{ dataItems.length }} выбрано
                                    </ng-template>
                        </kendo-multiselect> 
                    </div>
                    <div>
                        <div class="m-b20"> Назначение аудитории </div>
                        <kendo-multiselect
                                class="max-w350"
                                [data]="filterAssigns"
                                kendoMultiSelectSummaryTag
                                placeholder="Все"
                                textField="classRoomPurposeName"
                                valueField="classRoomPurposeId"                           
                                [autoClose]="false"
                                [checkboxes]="true"
                                [filterable]="true"
                                (filterChange)="assignFilter($event)"
                                (valueChange)="selectionChange($event)"                         
                                [valuePrimitive]="true"
                                name="assign"
                                [(ngModel)]="filter.arrayOfAssigns"                           
                                >
                                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                    <span></span>
                                        {{ dataItems.length }} выбрано
                                    </ng-template>
                        </kendo-multiselect> 
                    </div>
                </div>
            </div>

            <div class="buttons">
                <button kendoButton
                        class="white-button"
                        (click)="filterClean()">
                Очистить
                </button>
                <button kendoButton
                        class="blue-button"                      
                        (click)="filterHandler()">
                Применить
                </button>
            </div>
        </kendo-expansionpanel>
    </div>

    <div class="m-b15 m-t18">
        <button class="k-button k-button-sm k-rounded-md k-button-solid-success k-button-solid ng-star-inserted m-r8 h-34"
                kendoButton
                *ngIf="accessLevel"
                icon="plus"
                (click)="addClassroom()"
            >
        Добавить аудиторию
        </button>
        <button kendoButton type="button" icon="download" size="small"  (click)="exportToExcel(grid)">
          <kendo-loader *ngIf="loaderExcelExport" size="small" type="converging-spinner"> </kendo-loader> 
            Экспорт в Excel
        </button>
    </div>

    <kendo-grid #grid="kendoGrid" [kendoGridBinding]="classrooms"
                [sortable]="true"
                [pageable]="true"
                [pageSize]="pageSize"
                [skip]="skip"
                (pageChange)="pageChange($event)"
                (cellClick)="editHandler($event)"
                [loading]="loading">

      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column field="index"
                       title="№ п/п"
                       [width]="90"
                       headerClass="gridHeader dictionaries">
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells centerAlignment" >
                    {{ dataItem.index}}
                </span>
            </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="building"
            title="Корпус"
            [width]="150"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              {{ dataItem.building}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="type"
            title="Тип аудитории"
            [width]="200"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              {{ dataItem.type}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="number"
            title="Номер"
            [width]="100"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              {{ dataItem.number}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="purpose"
            title="Назначение аудитории"
            [width]="300"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              {{ dataItem.purpose}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="name"
            title="Название"
            [width]="150"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
                {{ dataItem.name }}
            </span>
        </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="department"
            title="Владелец"
            [width]="250"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
                {{ dataItem.department}}
            </span>
        </ng-template>
        </kendo-grid-column>

      <ng-template kendoPagerTemplate>
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-info></kendo-pager-info>
        <kendo-grid-spacer></kendo-grid-spacer>
        <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
      </ng-template>
      <kendo-grid-excel fileName="Auditorium.xlsx" [fetchData]="allData"></kendo-grid-excel>
      
    </kendo-grid>

</div>
<contingent-header></contingent-header>
<h1 *ngIf="editable">Редактирование группы</h1>
<h1 *ngIf="!editable">Просмотр группы</h1>

<kendo-tabstrip class="custom-tabs k-mt-4">
  <kendo-tabstrip-tab *ngFor="let item of tabItems; let i = index"
                      [title]="item.title"
                      [selected]="i === selected">
    <ng-template kendoTabContent>

      <form [formGroup]="formEduGroup" [hidden]="i != 0">
        <dl class="about">
          <kendo-label class="academic-label" text="Филиал" for="filial"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox [data]="filials"
                              textField="filialSName"
                              valueField="dictFilialExternalId"
                              class="add-edugroup"
                              [disabled]="!editable"
                              [valuePrimitive]="true"
                              #filial
                              placeholder="Выберите филиал"
                              [openOnFocus]="filial"
                              [kendoDropDownFilter]="filterSettings"
                              formControlName="filialId"
                              (valueChange)="onFilialChange()">
              </kendo-combobox>
            </kendo-formfield>
          </dd>
          <kendo-label class="academic-label" text="Институт/факультет" for="faculty"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox [data]="selectedFaculties"
                              textField="shortName"
                              valueField="facultyExternalId"
                              class="add-edugroup"
                              [disabled]="!editable"
                              [valuePrimitive]="true"
                              #institut name="faculty"
                              placeholder="Выберите институт/факультет"
                              [openOnFocus]="institut"
                              [kendoDropDownFilter]="filterSettings"
                              formControlName="facultyId"
                              (valueChange)="filterEducationPlan()">
              </kendo-combobox>
              <span *ngIf="editable" class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
          <kendo-label class="academic-label" text="Год поступления"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox [data]="startYears"
                              textField="year"
                              valueField="year"
                              class="add-edugroup"
                              [disabled]="!editable"
                              [valuePrimitive]="true"
                              #course name="course"
                              placeholder="Выберите "
                              (valueChange)="admissionYearChange()"
                              [openOnFocus]="course"
                              [kendoDropDownFilter]="filterSettings"
                              formControlName="admissionYear">
              </kendo-combobox>
              <span *ngIf="editable" class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
          <kendo-label class="academic-label" text="Учебный план группы"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox [data]="selectedEducationPlans"
                              textField="name"
                              valueField="externalId"
                              class="add-edugroup"
                              [valuePrimitive]="true"
                              [disabled]="!editable"
                              #plan name="plan"
                              placeholder="Выберите учебный план"
                              [openOnFocus]="plan"
                              [kendoDropDownFilter]="filterSettings"
                              (valueChange)="educationPlanChange($event)"
                              formControlName="planId">
                <ng-template kendoComboBoxItemTemplate let-dataItem>
                  {{ dataItem.name + " (" + educationPlanStatusName(dataItem.statusId) +")" }}
                </ng-template>
              </kendo-combobox>
              <span *ngIf="editable" class="required">*</span>
              <button kendoButton *ngIf="!loaderVisible" icon="hyperlink-open" fillMode="flat" size="none" class="custom-size" rounded="full"
                      title="Перейти к учебному плану" [disabled]="formEduGroup.value.planId == undefined"
                      (click)="openPlan()">
              </button>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>

          <kendo-label class="academic-label" text="Курс"></kendo-label>
          <dd class="edit-edugroup">
            <kendo-label>{{academicGroup.courseNum==null? "-" : academicGroup.courseNum + " курс"}}</kendo-label>
          </dd>
          <kendo-label class="academic-label" text="Направление подготовки"></kendo-label>
          <dd class="edit-edugroup">
            <kendo-label>{{academicGroup.standardName==null?"-":academicGroup.standardName}}</kendo-label>
          </dd>
          <kendo-label class="academic-label" text="Профиль"></kendo-label>
          <dd class="edit-edugroup">
            <kendo-label>{{academicGroup.programName==null?"-":academicGroup.programName}}</kendo-label>
          </dd>
          <kendo-label class="academic-label" text="Форма обучения"></kendo-label>
          <dd class="edit-edugroup">
            <kendo-label>{{academicGroup.studyFormName==null?"-":academicGroup.studyFormName}}</kendo-label>
          </dd>
          <kendo-label class="academic-label" text="Идентифика́тор группы"></kendo-label>
          <dd class="edit-edugroup">
            <kendo-label>{{academicGroup.eduGroupExternalId==null?"-":academicGroup.eduGroupExternalId}}</kendo-label>
          </dd>

          <kendo-label class="academic-label" text="Численность группы, чел"></kendo-label>
          <dd>
            <kendo-label class="admission-plan">{{"План приема: " + (formEduGroup.value.budgetQuotesStudentAdmissionBenchmarks
              + formEduGroup.value.commerceQuotesStudentAdmissionBenchmarks) + "  Факт: "
            + (academicGroup.commerceQuotesStudentsFact + academicGroup.budgetQuotesStudentsFact)}}
            </kendo-label>
          </dd>

          <kendo-label class="academic-label academic-student-count-label" text="КЦП (бюджет)"></kendo-label>
          <dd class="admission-plan-dd">
            <kendo-formfield>
              <kendo-numerictextbox
                class="add-edugroup-count"
                [min]="0"
                [disabled]="!editable"
                format="n0"
                formControlName="budgetQuotesStudentAdmissionBenchmarks">
              </kendo-numerictextbox>
            </kendo-formfield>
            <kendo-label class="academic-label">{{ " Факт: " + academicGroup.budgetQuotesStudentsFact}}</kendo-label>
          </dd>

          <kendo-label class="academic-label academic-student-count-label" text="коммерция"></kendo-label>
          <dd class="admission-plan-dd">
            <kendo-formfield>
              <kendo-numerictextbox
                class="add-edugroup-count"
                [min]="0"
                [disabled]="!editable"
                format="n0"
                formControlName="commerceQuotesStudentAdmissionBenchmarks">
              </kendo-numerictextbox>
            </kendo-formfield>
            <kendo-label class="academic-label">{{ " Факт: " + academicGroup.commerceQuotesStudentsFact}}</kendo-label>
          </dd>

          <kendo-label class="academic-label" text="Название группы"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox class="add-edugroup-name"
                             [(ngModel)]="this.formEduGroup.value.groupName"
                             formControlName="groupName"
                             [disabled]="!editable"
                             [clearButton]="true"
                             required>
              </kendo-textbox>
              <span *ngIf="editable" class="required">*</span>
              <div kendoTooltip style="display:contents;" position="right" *ngIf="editable">
                <kendo-loader *ngIf="loaderVisible" [type]="type" size="small"> </kendo-loader>
                <button kendoButton *ngIf="!loaderVisible" icon="reload" fillMode="flat" size="none" class="custom-size" rounded="full"
                        title="Обновить название группы по правилам именования групп"
                        (click)="getEduGroupName()">
                </button>
              </div>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>

          <kendo-label class="academic-label" text="Примечание"></kendo-label>
          <dd>
            <kendo-textarea
              class="add-edugroup"
              resizable="none"
              [disabled]="!editable"
              [rows]="3"
              formControlName="notice">
            </kendo-textarea>
          </dd>

        </dl>
      </form>

      <dl class="about" [hidden]="i != 1">
        <kendo-label class="academic-label" text="Профилизация"></kendo-label>
        <dd class="edit-edugroup">
          <kendo-label>{{ groupSemesters?.profilisation ? groupSemesters?.profilisation + " курс" : ""}}</kendo-label>
        </dd>

        <kendo-label class="academic-label" text="Продолжительность обучения"></kendo-label>
        <dd class="edit-edugroup">
          <kendo-label>{{getTrainingDuration()}}</kendo-label>
        </dd>

        <kendo-label class="academic-label" text="Планируемый срок обучения"></kendo-label>
        <dd class="edit-edugroup">
          <kendo-label>{{getTrainingTerms()}}</kendo-label>
        </dd>
      </dl>

      <form #semesters = "ngForm">
      <div [ngClass]="{edit: termsEdit, read: !termsEdit}" class="semester-box" [hidden]="i != 1">
        <div class="alignCells">
          <kendo-label class="academic-label"  text=""></kendo-label>
        </div>
        <div class="alignCells">
          <kendo-label class="academic-label"  text="Название группы"></kendo-label>
        </div>
        <div class="alignCells">
          <kendo-label class="academic-label">{{"Сроки 1го " + (trimester? "триместра" : "семестра")}}</kendo-label>
        </div>
        <div class="alignCells">
          <kendo-label class="academic-label">{{"Сроки 2го " + (trimester? "триместра" : "семестра")}}</kendo-label>
        </div>
        <div class="alignCells" [ngClass]="{editBtnColumn: (!termsEdit && editable)}">
          <kendo-label [hidden]="!trimester" class="academic-label" text="Сроки 3го триместра"></kendo-label>
          <button kendoButton fillMode="outline" size="small" class="group-outline-buttons group-right-alignment" (click)="termsOpen()" *ngIf="!termsEdit && editable">
            Редактировать
            <span class="k-icon k-i-edit" style="padding-bottom: 2px;"></span>
          </button>
        </div>

        <div class="semester-row" *ngFor="let item of semesterData; let i=index">
          <div>
            <kendo-label class="academic-label academic-course-label">{{(i + 1) + " курс"}}</kendo-label>
          </div>
          <div [ngClass]="{edit: (editable && termsEdit)}" class="archived-group">
            <kendo-label class="academic-label terms-view" *ngIf="!termsEdit">{{item.groupName}}</kendo-label>
            <kendo-textbox class="semester-group-name" name="groupName{{i}}"
                           [(ngModel)]="item.groupName"
                           [disabled]="!editable"
                           *ngIf="termsEdit"
                           required
                           [clearButton]="true">
            </kendo-textbox>
            <div kendoTooltip style="display:contents;" position="right" *ngIf="termsEdit">
              <button kendoButton *ngIf="!loaderVisible" icon="reload" fillMode="flat" size="none" class="custom-size" rounded="full"
                      title="Обновить название группы по правилам именования групп"
                      (click)="getEduGroupName( i )">
              </button>
            </div>
          </div>
          <div class="archived-group">
            <div *ngIf="!item.closed">
              <kendo-label class="academic-label" text="с"></kendo-label>
              <kendo-label class="academic-label terms-view" *ngIf="!termsEdit">{{item.firstSemesterStart| date: "dd.MM.yyyy"}}</kendo-label>
              <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.firstSemesterStart" name="firstSemesterStart{{i}}" [max]="item.firstSemesterEnd"  *ngIf="termsEdit" required></kendo-datepicker>
              <kendo-label class="academic-label" text="по"></kendo-label>
              <kendo-label class="academic-label terms-view" *ngIf="!termsEdit">{{item.firstSemesterEnd| date: "dd.MM.yyyy"}}</kendo-label>
              <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.firstSemesterEnd" name="firstSemesterEnd{{i}}" *ngIf="termsEdit" required></kendo-datepicker>
            </div>
            <div *ngIf="item.closed">
              <kendo-label class="academic-label" text="архивирована"></kendo-label>
            </div>
          </div>
          <div class="archived-group">
            <div  *ngIf="!item.secondClosed">
              <kendo-label class="academic-label" text="с" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)"></kendo-label>
              <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.secondSemesterStart && !item.secondSemesterEnd">{{item.secondSemesterStart| date: "dd.MM.yyyy"}}</kendo-label>
              <kendo-datepicker class="academic-datepicker" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)"  name="secondSemesterStart{{i}}" [(ngModel)]="item.secondSemesterStart" [max]="item.secondSemesterEnd" *ngIf="termsEdit" required></kendo-datepicker>
              <kendo-label class="academic-label" text="по" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)"></kendo-label>
              <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.secondSemesterStart && !item.secondSemesterEnd">{{item.secondSemesterEnd| date: "dd.MM.yyyy"}}</kendo-label>
              <kendo-datepicker class="academic-datepicker" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)" name="secondSemesterEnd{{i}}" [(ngModel)]="item.secondSemesterEnd" *ngIf="termsEdit" required></kendo-datepicker>
            </div>
            <div *ngIf="item.secondClosed">
              <kendo-label class="academic-label" text="архивирована"></kendo-label>
            </div>
          </div>
          <div>
            <kendo-label class="academic-label" text="с" [hidden]="!item.thirdSemesterStart"></kendo-label>
            <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.thirdSemesterStart">{{item.thirdSemesterStart| date: "dd.MM.yyyy"}}</kendo-label>
            <kendo-datepicker class="academic-datepicker" [hidden]="!item.thirdSemesterStart" [(ngModel)]="item.thirdSemesterStart" [required]="item.thirdSemesterStart > item.thirdSemesterEnd" *ngIf="termsEdit" name="thirdSemesterBegin{{i}}"></kendo-datepicker>
            <kendo-label class="academic-label" text="по" [hidden]="!item.thirdSemesterStart"></kendo-label>
            <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.thirdSemesterStart">{{item.thirdSemesterEnd| date: "dd.MM.yyyy"}}</kendo-label>
            <kendo-datepicker class="academic-datepicker" [hidden]="!item.thirdSemesterStart" [(ngModel)]="item.thirdSemesterEnd" *ngIf="termsEdit" name="thirdSemesterEnd{{i}}"></kendo-datepicker>
          </div>
        </div>

      </div>

      <dl class="about" [hidden]="i != 2">
        <kendo-label class="academic-label">{{"Создана: " + (createDate != undefined ? (createDate| date: "HH:mm dd.MM.yyyy") + "г" : "-")}}</kendo-label>
        <dd class="edit-edugroup"></dd>

        <kendo-label class="academic-label">{{"Последнее изменение: " + (groupChangeHistory.length != 0 ? (groupChangeHistory[groupChangeHistory.length - 1].dateTime| date: "HH:mm dd.MM.yyyy") + "г" : "-")}}</kendo-label>
        <dd class="edit-edugroup"></dd>
      </dl>

      <div class="editModeButtons edugroupButtons" *ngIf="!editable">
        <button kendoButton size="small" [routerLink]="['/contingent/academicgroupshome']">
          Вернуться к списку групп
        </button>
        <button kendoButton size="small" *ngIf="editElder" themeColor="primary" [disabled]="!formEduGroup.valid" (click)="onSave($event)">
          Сохранить
        </button>
        <button kendoButton *ngIf="noPlan" class="group-right-alignment" size="small" (click)="remove()">
          Удалить группу
        </button>
      </div>

      <div class="editModeButtons deadlinesButtons" [hidden]="i != 1" *ngIf="editable && termsEdit">
        <button kendoButton size="small" class="group-right-alignment" themeColor="primary" [disabled]="!formEduGroup.valid || (semesters.invalid != null && semesters.invalid)" (click)="onSave($event)">
          Сохранить
        </button>
        <button kendoButton size="small" class="group-right-alignment" (click)="termsClose()">
          Отмена
        </button>
        <button kendoButton size="small" class="group-right-alignment" (click)="getPlanSemesters()">
          Заполнить из графика УП
        </button>
      </div>

      <div [ngClass]="{info: i===0, deadlines: i===1}" class="editModeButtons edugroupButtons" *ngIf="editable">
        <button kendoButton *ngIf="!disable" [disabled]="disable" size="small" (click)="remove()">
          Удалить группу
        </button>
        <button kendoButton *ngIf="!(academicGroup.closed||academicGroup.closedNewYear)" size="small" (click)="closeGroup($event)">
          Архивировать группу
        </button>
        <button kendoButton *ngIf="(academicGroup.closed||academicGroup.closedNewYear)" size="small" (click)="recoverGroup($event)">
          Восстановить группу
        </button>
        <button kendoButton size="small" class="group-right-alignment" themeColor="primary" [disabled]="!formEduGroup.valid || (semesters.invalid != null && semesters.invalid)" (click)="onSave($event)">
          Сохранить
        </button>
        <button kendoButton size="small" class="group-right-alignment" [routerLink]="['/contingent/academicgroupshome']">
          Отмена
        </button>
      </div>

      </form>

      <div class="groupStudentsHeader" [hidden]="i != 0">
        <h5>Обучающиеся в группе</h5>
      </div>
      <div [hidden]="i != 0">
        <dl class="about">
          <kendo-label class="academic-label elder" *ngIf="editElder" text="Староста" for="faculty"></kendo-label>
          <dd *ngIf="!editElder"></dd>
          <dd>
              <kendo-combobox [data]="academicGroupStudents"
                              textField="fio"
                              valueField="studentId"
                              class="add-edugroup"
                              [valuePrimitive]="true"
                              *ngIf="editElder"
                              #elder
                              placeholder="Выберите старосту"
                              [openOnFocus]="elder"
                              (selectionChange)="elderSelectionChange($event)"
                              [kendoDropDownFilter]="filterSettings"
                              [(ngModel)]="elderId">
              </kendo-combobox>

              <button kendoButton size="small" icon="download" class="group-right-alignment" *ngIf="disable" (click)="exportStudentList(false)">
                Список студентов  (со статусом "учится")
              </button>
              <button kendoButton size="small" icon="download" class="group-right-alignment right-margin" *ngIf="disable" (click)="exportStudentList(true)">
                Список студентов (полный)
              </button>
          </dd>
        </dl>
      </div>

      <div class="grid-container" [hidden]="i != 0">
        <kendo-grid [kendoGridBinding]="academicGroupStudents"
                    [pageSize]="pageSize"
                    (cellClick)="transitionHandler($event)"
                    [pageable]="true"
                    [sortable]="true"
                    [resizable]="true"
                    class="academic-group-edit-cursor">
          <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
              <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
          </ng-template>

          <kendo-grid-column field="index" title="№ п/п" [width]="90">
            <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
              {{(rowIndex+1)}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="fio" title="ФИО" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem class="academic-group-cursor">
              <div [ngClass]="{elderColor: dataItem.studentId === academicGroup.starostaId}">
                {{(dataItem?.fio)}}
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="academicState" title="Академический статус" [width]="150">
          </kendo-grid-column>
          <kendo-grid-column field="planName" title="УП обучающегося" [width]="150">
          </kendo-grid-column>
          <kendo-grid-column field="standardName" title="Направление подготовки" [width]="300">
          </kendo-grid-column>
          <kendo-grid-column field="programName" title="Профиль" [width]="200">
          </kendo-grid-column>
          <kendo-grid-column field="studyFormName" title="Форма обучения" [width]="150">
          </kendo-grid-column>

          <ng-template *ngIf="disable" kendoPagerTemplate>
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-info></kendo-pager-info>
            <kendo-grid-spacer></kendo-grid-spacer>
            <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
          </ng-template>
        </kendo-grid>
      </div>

      <div class="grid-container" [hidden]="i != 2">
        <kendo-grid [kendoGridBinding]="groupChangeHistory"
                    [sortable]="true"
                    [resizable]="true">
          <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
              <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
          </ng-template>

          <kendo-grid-column field="dateTime" title="Дата и время"  headerClass="gridHeader" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.dateTime| date: "HH:mm dd.MM.yyyy"}}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="activityName" title="Действие"  headerClass="gridHeader" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.activityName}}
              </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="fio" title="Пользователь"  headerClass="gridHeader" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.fio ?? "Система"}}
              </span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>

    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>

<kendo-dialog
  title="Пожалуйста, подтвердите"
  *ngIf="planChangedModal"
  (close)="close()"
  [minWidth]="250"
  [width]="450">
  <p class="confirmationText">
    Учебный план группы был изменен, сменить учебные планы у студентов группы (за исключением студентов на индивидуальных планах)?<br>
    ВНИМАНИЕ! При смене учебного плана у студентов, сменивших план, необходимо перезачесть оценки!
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="save(false)">Не менять учебный план студентам</button>
    <button kendoButton (click)="save(true)" [primary]="true">
      Сменить учебный план студентам
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<div kendoDialogContainer></div>

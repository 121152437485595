<contingent-header></contingent-header>
<h1>Военные комиссариаты</h1>

<div class="grid-container"  *ngIf="!opened" class="selectContent">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="militaryCommissariats" (remove)="removeHandler($event)"
              [pageable]="true"  [pageSize]="pageSize"
              (cellClick)="editHandler($event)"
              (add)="addHandler()"
              [sortable]="true"
              [filterable]="true">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand size="small" themeColor="success" icon="plus">Добавить</button>
    </ng-template>

    <!--<kendo-grid-column field="dictMilitaryRankId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="commissariatName"
                       title="Название"
                       headerClass="gridHeader"
                       [width]="350">
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.commissariatName}}
                      </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="address"
                       title="Адрес"
                       headerClass="gridHeader"
                       [width]="350">
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.address}}
                      </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="order"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader"
                       [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.order}}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand
                  themeColor="secondary"
                  icon="delete"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>

</div>

<div kendoDialogContainer></div>

<div class="editForm" *ngIf="opened">
  <form class="k-form" [formGroup]="commissariatForm" class="ngForm">
    <div class="label-sticky-top">
      <kendo-label text="Название"></kendo-label>
    </div>
    <dl class="label-sticky-top">
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="commissariatName"
            class="input-main"
            size="small"
            [clearButton]="true"
            #comissariatName
          ></kendo-textbox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Поиск адреса"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-combobox
            [data]="addressHints"
            textField="full_name"
            valueField="object_id"
            [filterable]="true"
            [valuePrimitive]="true"
            formControlName="addressSearch"
            class="input-main"
            (valueChange)="onAddressChange($event)"
            (filterChange)="onAddressFilterChange($event)">
          </kendo-combobox>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Индекс"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="zipCode"
            class="input-main"
            size="small"
            [clearButton]="true"
            #zipCode
          ></kendo-textbox>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Регион"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-combobox [data]="selectDictStates"
                          textField="stateName"
                          valueField="stateName"
                          [valuePrimitive]="true"
                          formControlName="state"
                          class="input-main"
                          [openOnFocus]="stateAutoComplete"
                          [kendoDropDownFilter]="filterSettings"
                          #stateAutoComplete
                          required>
          </kendo-combobox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Район"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="district"
            class="input-main"
            size="small"
            [clearButton]="true"
            #district
          ></kendo-textbox>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Населенный пункт"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="city"
            class="input-main"
            [clearButton]="true"
            #city
            required
          ></kendo-textbox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Улица"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="street"
            class="input-main"
            [clearButton]="true"
            #street
            required
          ></kendo-textbox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Дом"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="house"
            class="input-main"
            size="small"
            [clearButton]="true"
            #house
            required
          ></kendo-textbox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
      </dd>
      <div class="label-sticky-top">
        <kendo-label text="Порядковый номер"></kendo-label>
      </div>
      <dd>
        <kendo-formfield>
          <kendo-textbox
            formControlName="order"
            class="input-main"
            size="small"
            [clearButton]="true"
            #order
          ></kendo-textbox>
        </kendo-formfield>
      </dd>
    </dl>
  </form>
  <div class="buttons">
    <button kendoButton (click)="onCancel()" type="button" class="cancelButton">Отменить</button>
    <button kendoButton themeColor="primary" type="button"  (click)="saveCurrent()">Сохранить</button>
  </div>
</div>

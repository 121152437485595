import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { Role } from '../models/useraccess/role';
import { TokenStorageService } from '../services/token.service';
import {map} from "rxjs/operators";
import {ClassRoomUserAccessService} from "../services/useraccess/classroom-user-access.service";

@Injectable({
  providedIn: 'root'
})
export class ClassroomGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: ClassRoomUserAccessService,
    private router: Router) 
    {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    // Checking if token is not null
    if(token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)){
        return true;
      }
       else {
        if(roles?.includes(Role.Classroom)) {
          return this.userAccessService.getAccessLevel().pipe(
              map(response => {
                const useraccess = response.settingsAccess
                if (path === 'classroom/useraccess'){
                  if (useraccess == false) {
                    alert('Недостаточно прав для доступа на эту страницу');
                    this.router.navigate([`/profile`]);
                  }
                  return useraccess;
                }
              }))
        }
       } 
    }
    alert('Недостаточно прав для доступа на эту страницу');
    this.router.navigate([`/profile`]);
    return false;
  }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class FoundStudentsService {
   
  public selectStudents$ = new BehaviorSubject<any>([]);
  public selectDistrStudents$ = new BehaviorSubject<any>([]);
	public selectedStudentsCount$ = new BehaviorSubject<number>(0);
  public selectStudentsNewList$ = new BehaviorSubject<any>([]);

	public getSelectStudents(students: any, existingStudents?: any) {
    let totalList: Array<any> = students;
    if (existingStudents) {
      totalList = [];
      totalList = this.addStudents(students);
      totalList = this.addExistingStudent(totalList, existingStudents);
    }
    this.selectStudents$.next(totalList);
    this.selectedStudentsCount$.next(totalList.length);
  }

  public getDistributionStudents(students: any, existingStudents?: any) {
    let totalList: Array<any> = students;
    if (existingStudents) {
      totalList = [];
      totalList = this.addStudents(students);
      totalList = this.addExistingStudent(totalList, existingStudents);
    }
    this.selectDistrStudents$.next(totalList);
  }

  public getNewListStudents(students: any, existingStudents?: any){
    let totalList: Array<any> = students;
    if (existingStudents) {
      totalList = [];
      totalList = this.addStudents(students);
      totalList = this.addExistingStudent(totalList, existingStudents);
    }
    this.selectStudentsNewList$.next(totalList);
  }

  public addStudents(students: any){
    let totalList: Array<any> = [];
    for (let i = 0; i < students.length; i++) {
      if (totalList.includes(students[i]) == false &&  totalList.some(e => e.userExternalId === students[i].userExternalId) == false) {
        totalList.push(students[i])
      }
    }
    return totalList;
  }

  public addExistingStudent(totalList: Array<any>, existingStudents: any){
    for (let i = 0; i < existingStudents.length; i++) {
      if (totalList.includes(existingStudents[i]) == false && totalList.some(e => e.userExternalId === existingStudents[i].userExternalId) == false) {
        totalList.push(existingStudents[i])
      }
    }
    return totalList;
  }
}
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BlankStatusModel} from '../../models/diplomablanks/dicts.model';

@Injectable({
    providedIn: 'root'
})

export class DictBlankStatusesService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.dict}`;

  constructor(private http: HttpClient) {}

  public getBlankStatuses() {
    return this.http.get<BlankStatusModel[]>(this.baseUrl + '/blankstatus');
  }

  public updateBlankStatuses(data: BlankStatusModel) {
    return this.http.put(this.baseUrl + '/blankstatus', data);
  }

}
<publications-settings></publications-settings>
<div class="container">
    <h1>Добавление автора</h1>
    <div [formGroup]="currentAuthor" class="k-mt-4 editForm">
        <kendo-loader *ngIf="loading" class="loader" type="converging-spinner" size="medium"></kendo-loader>
        <span class="row">
            <h2>Тип автора</h2>
            <kendo-dropdownlist (valueChange)="onAuthorTypeChange($event)"
                                formControlName="typeId"
                                [data]="dictAuthorTypes"
                                [valuePrimitive]="true"
                                textField="name"
                                valueField="id">
            </kendo-dropdownlist>
            <span class="required">*</span>
        </span>
        <span [ngSwitch]="true" class="autoComplete">
            <span class="row">
                <h2>Фамилия</h2>
                <kendo-autocomplete *ngSwitchCase="true" [filterable]="true"
                                    (filterChange)="filterAutomplete($event)"
                                    (valueChange)="fillFields($event)"
                                    valueField="fio"
                                    [virtual]="filterVirtualization"
                                    [data]="autocompleteData"
                                    formControlName="lastName">
                </kendo-autocomplete>
                <kendo-textbox *ngSwitchCase="false" formControlName="lastName"></kendo-textbox>
                <span class="required">*</span>
            </span>
            <span class="row">
                <h2>Имя</h2>
                <kendo-autocomplete *ngSwitchCase="true" [filterable]="true"
                                    (filterChange)="filterAutomplete($event)"
                                    [virtual]="filterVirtualization"
                                    (valueChange)="fillFields($event)"
                                    valueField="fio"
                                    [data]="autocompleteData"
                                    formControlName="firstName">
                </kendo-autocomplete>
                <kendo-textbox *ngSwitchCase="false" formControlName="firstName">
                </kendo-textbox>
                <span class="required">*</span>
            </span>
            <span class="row">
                <h2>Отчество</h2>
                <kendo-autocomplete *ngSwitchCase="true" [filterable]="true"
                                    (filterChange)="filterAutomplete($event)"
                                    [virtual]="filterVirtualization"
                                    (valueChange)="fillFields($event)"
                                    valueField="fio"
                                    [data]="autocompleteData"
                                    formControlName="middleName">
                </kendo-autocomplete>
                <kendo-textbox *ngSwitchCase="false" formControlName="middleName">
                </kendo-textbox>
                <span class="required">*</span>
            </span>
        </span>
        <span class="row" *ngIf='currentUserAccess?.allPublication === AccessRights.Write ||
        (!isAutoFilled && currentAuthorType !== "Сотрудник НГТУ")'>
            <h2>Дата рождения</h2>
            <kendo-datepicker formControlName="birthday"></kendo-datepicker>
        </span>
        <span class="row" *ngIf='currentUserAccess?.allPublication === AccessRights.Write ||
        (!isAutoFilled && currentAuthorType !== "Сотрудник НГТУ")'>
            <h2>Идентификатор СНИЛС</h2>
            <kendo-maskedtextbox [mask]="mask"
                                 size="small"
                                 formControlName="identificator">
            </kendo-maskedtextbox>
        </span>
        <span class="row">
            <h2>Тип трудовых отношений</h2>
            <kendo-dropdownlist
                    formControlName="laborRelationId"
                    [data]="dictLaborRelations"
                    [valuePrimitive]="true"
                    valueField="id"
                    textField="name">
            </kendo-dropdownlist>
            <span class="required">*</span>
        </span>
        <span class="row">
            <h2>Должность</h2>
            <kendo-dropdownlist
                    formControlName="postId"
                    [data]="dictPosts"
                    [valuePrimitive]="true"
                    textField="name"
                    valueField="id">
            </kendo-dropdownlist>
        </span>
        <span class="row">
            <h2>Ученая степень</h2>
            <kendo-dropdownlist
                    formControlName="academicDegreeId"
                    [data]="dictScienceDegrees"
                    [valuePrimitive]="true"
                    textField="name"
                    valueField="id"
            ></kendo-dropdownlist>
        </span>
        <span class="row">
            <h2>Роль в публикации</h2>
            <kendo-dropdownlist
                    formControlName="roleId"
                    [data]="dictAuthorRoles"
                    [valuePrimitive]="true"
                    textField="name"
                    valueField="id">
            </kendo-dropdownlist>
            <span class="required">*</span>
        </span>
        <span class="row" *ngIf='currentAuthorType === "Сотрудник НГТУ"'>
            <h2>Структурное подразделение</h2>
            <kendo-dropdownlist
              formControlName="departmentId"
              [data]="structureSubdivisions"
              [valuePrimitive]="true"
              textField="name"
              valueField="id">
            </kendo-dropdownlist>
        </span>
    </div>
    <div class="tableSection">
        <span class="row">
            <h1>Организации</h1>
            <span class="required">*</span>
        </span>
        <button class="addButton" (click)="addOrganization()" [primary]="true" icon="plus" kendoButton>Добавить организацию</button>
        <kendo-grid (remove)="removeOrganization($event)"
                    (cellClick)="editOrganization($event)"
                    [kendoGridBinding]="organizations"
                    [formGroup]="organizationGroup">
            <kendo-grid-column field="number" [width]="30" headerClass="gridHeader" title="">
                <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
                    <span class="alignCells">
                        {{rowIndex >= 0 ? rowIndex + 1 : null}}
                    </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]='800' field="name" headerClass="gridHeader" title="Название">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        {{dataItem.name}}
                    </span>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem='dataItem'>
                    <kendo-combobox [filterable]="true"
                                    formControlName="name"
                                    (filterChange)="filterOrganizations($event)"
                                    (valueChange)="fillOrganization($event)"
                                    [virtual]="filterVirtualization"
                                    valueField="name"
                                    textField="name"
                                    [valuePrimitive]="true"
                                    placeholder="Введите название организации для поиска"
                                    [data]="organizationData">
                    </kendo-combobox>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="city" [width]="400" headerClass="gridHeader" title="Город">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        {{dataItem.city}}
                    </span>
                </ng-template>
                <ng-template kendoGridEditTemplate></ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="country" [width]="400" headerClass="gridHeader" title="Страна">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells">
                        {{dataItem.country}}
                    </span>
                </ng-template>
                <ng-template kendoGridEditTemplate></ng-template>
            </kendo-grid-column>
            <kendo-grid-column headerClass="gridHeader dictionaries" [width]="100">
                <ng-template kendoGridCellTemplate>
                    <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="large" class="removeButton"
                            rounded="full">
                    </button>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <div class="buttons">
        <button (click)="onCancel()" kendoButton>Отмена</button>
        <button (click)="onSave()" kendoButton themeColor="primary">Сохранить</button>
    </div>
</div>

<div kendoDialogContainer></div>

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AddEvent, CellClickEvent, GridComponent, PagerPosition, RemoveEvent } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { Guid } from 'guid-typescript';
import { DictMarkCriteriaLevel } from 'src/app/models/currentcontrol/dictMarkCriteriaLevel.model';
import { Mark } from 'src/app/models/dicts/mark.model'; 
import { MarkService } from 'src/app/services/dicts/mark.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DictsService } from 'src/app/services/currentcontrol/dicts.service';
import { CurrentControlUserAccessService } from 'src/app/services/useraccess/current-control-user-access.service';

@Component({
  selector: 'app-dictmarkcriterialevel',
  templateUrl: './dictmarkcriterialevel.component.html',
  styleUrls: ['./dictmarkcriterialevel.component.css']
})
export class DictmarkcriterialevelComponent implements OnInit {
  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20, 50, 100];
  public previousNext = true;
  public positionPager: PagerPosition = 'bottom';
  public skip = 0;
  public emptyGuid: any = Guid.createEmpty();

  public loading = false;
  public marks: Mark[] = [];
  public criteriaLevels!: DictMarkCriteriaLevel[];
  public criteriaLevel: DictMarkCriteriaLevel = {
    id: this.emptyGuid.value,
    name: '',
    shortName: '',
    minValue: null,
    maxValue: null,
    numOrder: null,
    criteriaDictMarkId: null,
    criteriaDictMark: null
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;
  public names: string[] | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private dictService: DictsService,
              private markService: MarkService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private userAccessService: CurrentControlUserAccessService,
              private dialogService: DialogService,
              private router: Router
    ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getAllDictMarkCriteriaLevel();
    this.getAllMarks();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Get discipline name for id
  public nameMark(id: Guid): Mark | undefined {
    return this.marks.find((x) => x.id === id);
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      id: this.emptyGuid.value,
      name: '',
      shortName: '',
      minValue: null,
      maxValue: null,
      numOrder: null,
      criteriaDictMarkId: null,
      criteriaDictMark: null
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    isEdited,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew == true && this.formGroup !== undefined) {
        this.dictService.addMarkCriteriaLevel(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllDictMarkCriteriaLevel();
              this.notificationService.showSuccess("Добавлено");
              this.criteriaLevel = {
                id: this.emptyGuid.value,
                name: '',
                shortName: '',
                minValue: null,
                maxValue: null,
                numOrder: null,
                criteriaDictMarkId: null,
                criteriaDictMark: null
              }
            },
            error => {
              this.notificationService.showError("Не удалось сохранить запись");
            }
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.dictService.updateDictMarkCriteriaLevel(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllDictMarkCriteriaLevel();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllMarks() {
    this.markService.getAllMarks()
      .subscribe(
        response => {
          this.marks = response;
        }
      );
  }

  //Getting all entries from dictionary
  public getAllDictMarkCriteriaLevel() {
    this.loading = true;
    this.dictService.getAllDictMarkCriteriaLevels()
      .subscribe(
        response => {
          this.criteriaLevels = response;
          this.loading = false;
        },
        error => {
          console.log(error.error);
          this.notificationService.showError("Отсутствует подключение к серверу");
          this.loading = false;
        });
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(
        response => {
          this.editable = !response.dictAccessLevel
        },error => {});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.name.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.dictService.deleteMarkCriteriaLevel(dataItem.id)
          .subscribe(
            ( response: any) => {
              this.getAllDictMarkCriteriaLevel();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { id?: any; name?: any; shortName?: any; minValue?: any; maxValue?: any; numOrder?: any; criteriaDictMarkId?: any; criteriaDictMark?: any; }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    name: new FormControl(dataItem.name, Validators.required),
    shortName: new FormControl(dataItem.shortName, Validators.required),
    minValue: new FormControl(dataItem.minValue),
    maxValue: new FormControl(dataItem.maxValue),
    numOrder: new FormControl(dataItem.numOrder),
    criteriaDictMarkId: new FormControl(dataItem.criteriaDictMarkId),
    criteriaDictMark: new FormControl(dataItem.criteriaDictMark)
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};


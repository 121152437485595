import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DictTrainingLevelNameForStudentNumber} from "../../../models/contingent/dicttraininglevel.model";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {DictTrainingLevelService} from "../../../services/contingent/dicttraininglevels.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogService} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {Guid} from "guid-typescript";
import {DictFacultyModel} from "../../../models/contingent/faculty.model";
import {DictFacultyService} from "../../../services/contingent/dictfaculty.service";

@Component({
  selector: 'app-dictfaculty',
  templateUrl: './dictfaculty.component.html',
  styleUrls: ['./dictfaculty.component.scss']
})
export class DictfacultyComponent implements OnInit {
  public dictFaculty: DictFacultyModel[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  constructor(
    private dictFacultyService: DictFacultyService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: ContingentUserAccessService,
  ) { }

  ngOnInit(): void {
    this.getAccessLevel()
    this.getDictTrainingLevel();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getDictTrainingLevel();
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    //Update dictionary
    if (this.formGroup !== undefined) {
      this.dictFacultyService.updateDictFaculty(this.formGroup.value)
        .subscribe({
          next:() => {
            this.getDictTrainingLevel();
            this.notificationService.showSuccess("Сохранено");
          },
          error:() => {
            this.notificationService.showError('Запись уже существует');
          }}
        );
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getDictTrainingLevel() {
    this.dictFacultyService.getDictFaculty()
      .subscribe(
        response => {
          this.dictFaculty = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { externalId?: Guid; nameForStudentNumber?: string }) =>
  new UntypedFormGroup({
    externalId: new UntypedFormControl(dataItem.externalId),
    nameForStudentNumber: new UntypedFormControl(dataItem.nameForStudentNumber),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;

}

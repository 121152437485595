import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseFilterCellComponent, FilterService} from "@progress/kendo-angular-grid";
import {VirtualizationSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'filter-multiselect',
  templateUrl: './filter-multiselect.component.html',
  styleUrls: ['./filter-multiselect.component.scss']
})
export class FilterMultiselectComponent extends BaseFilterCellComponent {
  constructor(filterService: FilterService) {
    super(filterService);
  }

  @Input() public data: any[] = [];
  @Input() public textField!: string;
  @Input() public valueField!: string;
  @Input() public checkboxes: boolean = false;
  @Input() public listHeight: number = 200;
  @Input() public gridField!: string;
  @Input() public ngModel!: unknown;

  @Output() onFilterChange = new EventEmitter<any[]>();

  public get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  public onChange(values: any[]): void {
    this.onFilterChange.emit(values);
    this.filterService.filter({
      filters: values.map(value => ({
        field: this.gridField ?? this.valueField,
        operator: 'contains',
        value
      })),
      logic: 'or'
    });
  }
}

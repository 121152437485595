import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StandardType } from '../../models/dicts/standardtype.model';

@Injectable({
  providedIn: 'root'
})
export class StandardtypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.standardType}`;

  constructor(private http: HttpClient) { }

  //Get all StandardType
  public getAllStandardType(): Observable<StandardType[]> {
    return this.http.get<StandardType[]>(this.baseUrl);
  }
}

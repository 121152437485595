import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {Router} from "@angular/router";
import { FoundStudentsService} from 'src/app/services/announcement/found-students.service';
import { FoundPersonsService} from 'src/app/services/announcement/found-persons.service';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { Announcement } from 'src/app/models/announcement/announcement.model';
import { SearchStudentsService } from 'src/app/services/announcement/search-students.service';
import { SearchPersonsService } from 'src/app/services/announcement/search-persons.service';
import { ShowService } from 'src/app/services/announcement/show.service';
import { environment } from 'src/environments/environment';
import urlJoin from 'url-join';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DistribuitionsService } from 'src/app/services/announcement/distribuitions.service';

@Component({
    selector: 'app-panel-control',
    templateUrl: './panel-control.component.html',
    styleUrls: ['./panel-control.component.scss']
})

export class PanelControlComponent implements OnInit{
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);
    @Output() emitCount = new EventEmitter<number>();
    @Output() onChanged = new EventEmitter<boolean>()
   
    change(increased:any) {
        this.onChanged.emit(increased);
    }

    public selectedStudents: Array<any> = [];
    public selectedPersons: Array<any> = [];
    public selectedDistrStudents: Array<any> = [];
    public selectedDistrPersons: Array<any> = [];
    public Deliveries: any = [];
    public resultat: any = [];
    public announcement: any = [];
    private subscriptions: Subscription[] = [];
    public allStudents: Array<any> = [];
    public allPersons: Array<any> = [];
    public isDraft: boolean = false;
    public messageId: number = 0;

    public newList: boolean = false;

    constructor(
        private router: Router,
        private foundStudentsService: FoundStudentsService,
        private foundpersonsService: FoundPersonsService,
        private http: HttpClient,
        private announcementService: AnnouncementService,
        private searchStudentsService: SearchStudentsService,
        private showService: ShowService,
        private dialogService: DialogService,
        private notificationService: NotificationsService,
        private distribuitionsService: DistribuitionsService,
    ) { }

    public Announcement(){
        this.Deliveries = [];
        /*for(let i = 0; i < this.selectedStudents.length; i++){
            this.Deliveries.push(this.selectedStudents[i])
        }
        for(let i = 0; i < this.selectedPersons.length; i++){
            this.Deliveries.push(this.selectedPersons[i])
        }
        for(let i = 0; i < this.selectedDistrStudents.length; i++){
            this.Deliveries.push(this.selectedDistrStudents[i])
        }
        for(let i = 0; i < this.selectedDistrPersons.length; i++){
            this.Deliveries.push(this.selectedDistrPersons[i])
        }*/

    /*    let dataSources = ['selectedStudents', 'selectedPersons', 'selectedDistrStudents', 'selectedDistrPersons'];
        for(let i = 0; i < dataSources.length; i++) {
            for(let t =0; t < (this as any)[dataSources[i]].length; t++) {
                this.Deliveries.push((this as any)[dataSources[i]][t])
            }
        }*/

        let deliveriesFromList = [];

        let dataSources = ['selectedStudents', 'selectedPersons'];
        for(let i = 0; i < dataSources.length; i++) {
            for(let t =0; t < (this as any)[dataSources[i]].length; t++) {
                this.Deliveries.push((this as any)[dataSources[i]][t])
            }
        }
        let dataSourcesList = ['selectedDistrStudents', 'selectedDistrPersons'];
        for(let i = 0; i < dataSourcesList.length; i++) {
            for(let t =0; t < (this as any)[dataSourcesList[i]].length; t++) {
                deliveriesFromList .push((this as any)[dataSourcesList[i]][t])
            }
        }
      //  console.log('из списка',deliveriesFromList);
      //  console.log('просто',this.Deliveries);
        this.emitCount.emit(this.selectedDistrStudents.length + this.selectedDistrPersons.length);
      //  this.foundStudentsService.getDistributionStudents([]);
      //  this.foundpersonsService.getDistributionPersons([]);
        
        if (this.Deliveries.length == 0 && deliveriesFromList.length == 0){
            this.notificationService.showError("Получатели не выбраны");
        }
        else {
            this.announcementService.currentAnnouncement.next({
                deliveries: this.Deliveries,
                deliveriesFromList: deliveriesFromList,
                announcement: this.announcement,
            });
            if (this.isDraft == true){ 
                this.router.navigate(['author/announcement/', this.messageId]);
            }
            else {
                this.router.navigate(['author/announcement']);
            }
        }
    }

    public zeroingOut(){
        this.announcementService.currentAnnouncement.next({
            deliveries: [],
            deliveriesFromList: [],
            announcement: [],
        });

        this.foundpersonsService.getSelectPersons([], []);
        this.foundStudentsService.getSelectStudents([], []);
        this.foundStudentsService.getDistributionStudents([]);
        this.foundpersonsService.getDistributionPersons([]);
        this.foundStudentsService.getNewListStudents([], []);
        this.foundpersonsService.getNewListPersons([], []);

        this.announcementService.SendPattern.next({
            messageId: undefined,
            pattern  : false,
          });
    }

    public getDeliveries(){
        this.Deliveries = [];
        for(let i = 0; i < this.selectedStudents.length; i++){
            this.Deliveries.push(this.selectedStudents[i])
        }
        for(let i = 0; i < this.selectedPersons.length; i++){
            this.Deliveries.push(this.selectedPersons[i])
        }

        for(let i = 0; i < this.selectedDistrStudents.length; i++){
            if (this.Deliveries.findIndex((item: any) => item.userExternalId == this.selectedDistrStudents[i].userExternalId) == -1)
                this.Deliveries.push(this.selectedDistrStudents[i])
        }
        for(let i = 0; i < this.selectedDistrPersons.length; i++){
            if (this.Deliveries.findIndex((item: any) => item.userExternalId == this.selectedDistrPersons[i].userExternalId) == -1)
                this.Deliveries.push(this.selectedDistrPersons[i])
        }
    }

    public getFormData(title:any, data?: any){
        var formData = new FormData();
        formData.append('isDraft', 'true');
      
        for(var i =0; i < this.Deliveries.length; i++) {
            if (this.Deliveries[i].userId == undefined){
                formData.append('deliveries['+i+'][userRecipientId]', this.Deliveries[i]['userRecipientId'].toString());
                formData.append('deliveries['+i+'][userExternalId]', this.Deliveries[i]['userExternalId'].toString());               
            }
            else {
                formData.append('deliveries['+i+'][userRecipientId]', this.Deliveries[i]['userId'].toString());
                formData.append('deliveries['+i+'][userExternalId]', this.Deliveries[i]['userExternalId'].toString());
            }
        }
        
        let arrayOfDeliveries = "";
        for(let i =0; i < this.Deliveries.length; i++) {
            if (this.Deliveries[i].userId == undefined){
                arrayOfDeliveries = arrayOfDeliveries + this.Deliveries[i]['userRecipientId'] + ",";
            }
            else {
                arrayOfDeliveries = arrayOfDeliveries + this.Deliveries[i]['userId'] + ",";     
            }
        }
        arrayOfDeliveries = arrayOfDeliveries.slice(0, -1);
        formData.append('deliveries', arrayOfDeliveries.toString());

        if (title !== undefined){
            formData.append('messageType', data.messageType.toString());
       
            if (data.title !== null) {
                formData.append('title', data.title.trim());
            }
            if (data.content !== null) {
                formData.append('content', data.content.trim());
            }

            
            if (data.postpone !== undefined && data.postpone !== null) {
                let dateString = data.postpone.toString();
                if ( dateString.indexOf('Z') > -1) {
                    formData.append('postpone', data.postpone);
                } else {
                    let postponedate = data.postpone.toISOString();
                    formData.append('postpone', postponedate);
                }                   
            }
           
            for(var i =0; i < data.tags.length; i++) {
                formData.append('tags['+i+'][title]', data.tags[i]['title']);
            }
        }

        return formData;
    }

    public inDraft(){
   
        this.getDeliveries();


        if (this.Deliveries.length == 0){
            this.notificationService.showError("Получатели не выбраны");
        }
        else {
            let data: any =  this.announcement;
           
            var formData = new FormData();
            
            let headers = new HttpHeaders();
             headers.append('Access-Control-Allow-Credentials','true');
    
            if (data.title === undefined) {
                formData = this.getFormData(data.title);
                this.http.post<Announcement>(this.baseUrl + '/message', formData, { headers: headers })
                    .subscribe((res) => { this.zeroingOut();
                });
            }
            else {
                formData = this.getFormData(data.title, data);
             
                if (data.messageId == 0 ){
                    let j = 0;
                    data.attachments.forEach((element: any) => {
                        if (element.lastModified === undefined){
                            formData.append('oldAttachments['+j+'][name]',  element.name);
                            formData.append('oldAttachments['+j+'][externalId]', element.externalId );
                            j++;
                        } else {
                            formData.append('attachments', element);
                        }
                     });
                 //   console.log('formdata',formData);
                    this.http.post<Announcement>(this.baseUrl + '/message', formData, { headers: headers })
                        .subscribe((res) => { this.zeroingOut();
                    });
                }
                else {
                    let j = 0;
                    data.attachments.forEach((element: any) => {
                        if (element.lastModified === undefined){
                            formData.append('oldAttachments['+j+'][id]', element.id);
                            j++;
                        } else {
                            formData.append('attachments', element);
                        }
                     });
                   
                    this.http.put(this.baseUrl + '/messages/' + data.messageId, formData, { headers: headers })
                        .subscribe((res) => { this.zeroingOut();
                    });
                }
            }   
            this.router.navigate(['/author']);
        }       
    }

  /*  public appendAttachments(){

    }*/

    public transferDataBack(){
              
        let data = this.announcementService.currentAnnouncement.getValue(); 
        this.announcement = data.announcement;
     //   console.log('назад data',data);
        let listId = this.announcement.deliveries;
     //console.log('listid',listId);
        if (listId != undefined && listId.length !== 0 && data.deliveries.length == 0){
           for (let i=0; i < listId.length; i++){
                if (listId[i].studentNumber !== null &&  this.selectedStudents.some(e => e.userRecipientId === listId[i].userRecipientId) == false){
                    this.selectedStudents.push(listId[i]);
                }
                if  (listId[i].studentNumber == null &&  this.selectedPersons.some(e => e.userRecipientId === listId[i].userRecipientId) == false) {
                    this.selectedPersons.push(listId[i]);
                }
            } 
        }

        if (data.deliveries.length !== 0) {
           this.Deliveries = data.deliveries;
            for (let i=0; i < this.Deliveries.length; i++){
                if (this.Deliveries[i].studentNumber == null && this.selectedPersons.some(e => e.userId === this.Deliveries[i].userId) == false){
                    this.selectedPersons.push(this.Deliveries[i]);
                }
                if (this.Deliveries[i].studentNumber !== null && this.selectedStudents.some(e => e.userId === this.Deliveries[i].userId) == false) {
                    this.selectedStudents.push(this.Deliveries[i]);
                }
            }
        }

        if (data.deliveriesFromList != undefined && data.deliveriesFromList.length !== 0){
            let deliveriesFromList = data.deliveriesFromList;
          //  console.log(deliveriesFromList);
            for (let i=0; i < deliveriesFromList.length; i++){
                if (deliveriesFromList[i].studentNumber !== null && this.selectedDistrStudents.some(e => e.userId === deliveriesFromList[i].userId) == false){
                    this.selectedDistrStudents.push(deliveriesFromList[i]);
                }
                if (deliveriesFromList[i].studentNumber == null && this.selectedDistrPersons.some(e => e.userId === deliveriesFromList[i].userId) == false){
                    this.selectedDistrPersons.push(deliveriesFromList[i]);
                }
            }
          //  console.log('студенты из списка', this.selectedDistrStudents);
        }
        this.foundStudentsService.getDistributionStudents(this.selectedDistrStudents);
        this.foundpersonsService.getDistributionPersons(this.selectedDistrPersons);

        this.foundpersonsService.getSelectPersons(this.selectedPersons);
        this.foundStudentsService.getSelectStudents(this.selectedStudents);
    }

    public getRecipient(){
        this.searchStudentsService.getAllStudents()
        .subscribe((data: any) => {
     
        let state = this.announcementService.currentAnnouncement.getValue();
      //  console.log('state',state);
        this.isDraft = state.announcement.isDraft;
        this.messageId = state.announcement.messageId;

        if (state.announcement.title !== undefined) {
            this.transferDataBack();
        }
       });
    }

    openpopup(){
        const dialog: DialogRef = this.dialogService.open({
            title: "Пожалуйста подтвердите",
            content: `Вы действительно хотите закрыть форму добавления оповещения без сохранения изменений?`,
            actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
            width: 450,
            height: 200,
            minWidth: 250,
        });
        dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
                if(result.text == "Да"){
                  this.Undo();
                }
              }
        })
    }

    public Undo(){
        this.zeroingOut();
      
        this.router.navigate(['/alert']);
    }

    public openpopupDelete(){
        const dialog: DialogRef = this.dialogService.open({
            title: "Пожалуйста подтвердите",
            content: `Вы действительно хотите удалить данное оповещение?`,
            actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
            width: 450,
            height: 200,
            minWidth: 250,
        });
        dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
                if(result.text == "Да"){
                  this.deleteMessage();
                }
              }
        })
    }

    public deleteMessage(){
        this.showService.deleteMessage(this.messageId)
        .subscribe(message => {
            this.notificationService.showSuccess("Оповещение удалено");
            this.router.navigate(['/alert']);           
        });    
    }

    ngOnInit() {
        this.getRecipient();

        this.distribuitionsService.subscriber$.subscribe((data: any) => {
            this.newList = data;
        })
     /*   let state = this.announcementService.currentAnnouncement.getValue();
        this.isDraft = state.announcement.isDraft;
        this.messageId = state.announcement.messageId;
        if (state.announcement.title !== undefined) {
            this.transferDataBack();
        }*/

        this.subscriptions.push(
            this.foundStudentsService.selectStudents$.subscribe((data) => {
               this.selectedStudents = data;
            })  
        );

        this.subscriptions.push(
            this.foundpersonsService.selectPersons$.subscribe((data) => {
                this.selectedPersons = data;
            })
        );
        this.subscriptions.push(
            this.foundStudentsService.selectDistrStudents$.subscribe((data) => {
               this.selectedDistrStudents = data;
            })  
        );

        this.subscriptions.push(
            this.foundpersonsService.selectDistrPersons$.subscribe((data) => {
                this.selectedDistrPersons = data;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}

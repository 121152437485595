import { MilitaryStatuses } from "./militarystatuses.model";
import { MilitaryDocTypes } from "./militarydoctypes.model";
import { MilitaryRanks } from "./militaryranks.model";
import { StudPerson } from "./studperson.model";
import {Guid} from "guid-typescript";
import {MilitaryCertificateTypes} from './enums/military-certificate-types.enum';

export class Military{
  public militaryId!: number;
  public militaryExternalId?: Guid;
  public studPersonId!: Guid;
  public studPersons!:StudPerson;
  public dictMilitaryStatusId!: Guid;
  public dictMilitaryStatuse!: MilitaryStatuses;
  public dictMilitaryDocTypeId!: Guid;
  public dictMilitaryDocType!: MilitaryDocTypes;
  public dictMilitaryCommissariatId?: Guid;
  public number!:string;
  public issueDate!:Date;
  public issueOrganization!:string;
  public dictMilitaryRankId!: Guid;
  public dictMilitaryRank!: MilitaryRanks;
  public dictMilitaryStockCategoryId!: Guid;
  public dictMilitaryStockCategory!: MilitaryDocTypes;
  public militarySpecialtyNumber?:string;
  public issueMilitaryStartDate?:Date;
}

export class MilitaryCont{
  public dictMilitaryStatusId!: string;
  public militaryStatusName!: string;
  public militaryDocTypeName!: string;
  public number!:string;
  public issueDate!:Date;
  public issueOrganization!:string;
  public militaryRankName!: string;
  public militaryStockCategoryName!: string;
}

export class MilitaryEnlistment {
  externalId!: string;
  dictMilitaryStatusId!: string;
  dictMilitaryStatusName!: string;
  dictMilitaryCategoryId!: string;
  dictMilitaryCategoryName!: string;
  dictMilitaryCommissariatId!: string;
  dictMilitaryCommissariatName!: string;
  dictMilitaryCommissariatAddress!: string;
  dictMilitaryStateId?: string;
  dictMilitaryStateName?: string;
}

export class MilitaryEnlistmentForm {
  externalId?: string;
  dictMilitaryStatusId!: string;
  dictMilitaryCategoryId!: string;
  dictMilitaryCommissariatId!: string;
  dictMilitaryStateId?: string;
}

export class MilitaryDocument {
  externalId!: string;
  dictMilitaryDocTypeId!: string;
  militaryDocTypeName!: string;
  serial!: string;
  number!: string;
  issueOrganization!: string;
  issueDate!: Date;
  dictMilitaryStockCategoryId?: string;
  militaryStockCategoryName?: string;
  dictMilitaryRankId?: string;
  militaryRankName?: string;
  dictMilitaryProfileId?: string;
  militaryProfileName?: string;
  militarySpecialtyNumber?: string;
  comment?: string;
  outgoingRegistryNumber?: string;
  issueMilitaryStartDate?: Date;
  issueMilitaryFinishDate?: Date;
  reasonForDeregistration?: Date;
}

export class MilitaryDocumentForm {
  externalId?: string;
  dictMilitaryDocTypeId!: string;
  serial!: string;
  number!: string;
  issueOrganization!: string;
  issueDate!: Date;
  dictMilitaryStockCategoryId?: string;
  dictMilitaryRankId?: string;
  dictMilitaryProfileId?: string;
  militarySpecialtyNumber?: string;
  comment?: string;
  outgoingRegistryNumber?: string;
  issueMilitaryStartDate?: Date;
  issueMilitaryFinishDate?: Date;
  reasonForDeregistration?: Date;
}

export class AllMilitaryCertificates {
  militaryFormTen?: MilitaryCertificate;
  militaryForms!: MilitaryCertificate[];
}

export class MilitaryCertificate {
  externalId!: string;
  type!: MilitaryCertificateTypes;
  number?: string;
  dictMilitaryCommissariatName!: string;
  updateAt!: Date;
  personFio?: string;
}

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, forkJoin, Observable, switchMap, throwError} from 'rxjs';
import { DisciplineWorkloadUserAccess } from 'src/app/models/useraccess/disciplineworkload/disciplineworkloaduseraccess.model';
import {environment, getData$} from '../../../environments/environment';
import { DictStudyForm, DictTrainingLevel, Faculty, KafedraGet } from '../../models/disciplineworkload/externals.model';
import {ContingentLoadUserAccess} from "../../models/useraccess/disciplineworkload/contingentLoadUserAccess.model";
import {map} from "rxjs/operators";
import {NotificationsService} from "../notifications/notifications.service";
import {DisplaySNameForDuplicateKafedras} from "../../helpers/array-helper";
import {KafedraFields} from "../../models/useraccess/enums/kafedra.enum";

@Injectable({
  providedIn: 'root'
})
export class DisciplineWorkloadUserAccessService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.userAccess}`;

  constructor(private http: HttpClient,
              private notificationService: NotificationsService) { }

  //#region CRUD

  //Get access
  public getAccess(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/GetAllUserAccesses/')
      .pipe(catchError(this.handleError));
  }

  //Save access
  public saveAccess(useraccess: DisciplineWorkloadUserAccess) {
    this.http.post<DisciplineWorkloadUserAccess>(this.baseUrl + '/AddUserAccess/', useraccess)
      .pipe(catchError(this.handleError)).subscribe(
        response => {
          getData$.next(true);
          this.notificationService.showSuccess("Добавлено");
        },
        error => {
          this.notificationService.showError("Не удалось добавить запись");
        }
    );
  }

  //Update access
  public updateAccess(useraccess: DisciplineWorkloadUserAccess) {
    this.http.put<DisciplineWorkloadUserAccess>(this.baseUrl + '/UpdateUserAccess/', useraccess)
      .pipe(catchError(this.handleError)).subscribe(
            response => {
              getData$.next(true);
              this.notificationService.showSuccess("Успешно");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
        );
  }

  //Delete access
  public deleteAccess(id: string): Observable<DisciplineWorkloadUserAccess> {
    return this.http.delete<DisciplineWorkloadUserAccess>(this.baseUrl + '/DeleteUserAccess?id=' + id)
      .pipe(catchError(this.handleError));
  }

  //#endregion

  // Проверка прав на редактирование в модуле "Контингент в нагрузку"
  public getEditingRightInContingentLoad(filter: {
    facultyId: string
  }): Observable<ContingentLoadUserAccess> {
    let params = new HttpParams();
    params = params.append("facultyId", filter.facultyId);
    //params.append("studyFormId", filter.studyFormId);
    //params.append("trainingLevelId", filter.trainingLevelId);

    return this.http.get<ContingentLoadUserAccess>(this.baseUrl + '/GetEditingRightsInContingentLoad',
      { params })
      .pipe(catchError(this.handleError));
  }

  // Проверка прав на редактирование в модуле "Поручения"
  public getEditingRightsInErrands(filter: {
    kafedraId: string
  }): Observable<ContingentLoadUserAccess> {
    let params = new HttpParams();
    params = params.appendAll({ "kafedraId": filter.kafedraId });

    return this.http.get<ContingentLoadUserAccess>(this.baseUrl + '/GetEditingRightsInErrands',
      { params }).pipe(catchError(this.handleError));
  }

  public async hasEditingRightsInPps(filter: { cathedraId: string }): Promise<boolean> {
    let params = new HttpParams();
    params = params.appendAll({"cathedraId": filter.cathedraId});

    let allowEdit = false;

    await this.http.get<any>(this.baseUrl + '/HasEditingRightsInPps', { params })
      .toPromise().then(value => allowEdit = value.isApproved);

    return allowEdit;
  }

  public async hasEditingRightsInTeacherLoad(filter: { cathedraId: string }): Promise<boolean> {
    let params = new HttpParams();
    params = params.appendAll({"kafedraId": filter.cathedraId});

    let allowEdit = false;

    await this.http.get<any>(this.baseUrl + '/HasEditingRightsInTeacherLoad', { params })
      .toPromise().then(value => allowEdit = value.isApproved);

    return allowEdit;
  }

  // Наличие прав на редактирование во всех разделах
  public getAllRights() {
    const contingentLoad = this.http.get<ContingentLoadUserAccess>(this.baseUrl + '/GetEditingRightsInContingentLoad');
    const reports = this.http.get<ContingentLoadUserAccess>(this.baseUrl + '/GetEditingRightsInReports');
    const userAccessControl = this.http.get<ContingentLoadUserAccess>(this.baseUrl + '/GetEditingRightsInRuleSettings');
    const assignments = this.http.get<ContingentLoadUserAccess>(this.baseUrl + '/GetEditingRightsInRuleSettings');
    const cathedralLoad = this.http.get<ContingentLoadUserAccess>(this.baseUrl + '/GetEditingRightsInSubdepartmentLoad');
    return forkJoin([
      contingentLoad,
      reports,
      userAccessControl,
      assignments,
      cathedralLoad
    ]).pipe(map(([contingentLoad, reports, userAccessControl, assignments, cathedralLoad]) => ({
      contingentLoad, reports, userAccessControl, assignments, cathedralLoad
    })))
  }

  // Get all kafedras
  getAllKafedras(): Observable<KafedraGet[]> {
    return this.http.get<KafedraGet[]>(this.baseUrl + '/GetAllKathedras/')
        .pipe(
            map( response => {
              response = (response as { [key: string]: any })['kathedras'] as KafedraGet[];
              if (response) {
                DisplaySNameForDuplicateKafedras(response, KafedraFields.name, KafedraFields.sName);
                let item = new KafedraGet();
                item.id = "";
                item.name = 'Все';
                response.unshift(item);
              }
              return response;
            }),
            catchError(this.handleError))
  }

  // Get all faculties
  getAllFaculties(): Observable<Faculty[]> {
    return this.http.get<Faculty[]>(this.baseUrl + '/GetAllFaculties/')
        .pipe(
            map( response => {
              response = (response as { [key: string]: any })['faculties'] as Faculty[];
              if (response) {
                let item = new Faculty();
                item.id = "";
                item.name = 'Все';
                item.fullName = 'Все';
                item.sName = 'Все';
                response.unshift(item);
              }
              return response;
            }),
            catchError(this.handleError))
  }

  // Get all studyforms
  getAllStudyForms(): Observable<DictStudyForm[]> {
    return this.http.get<DictStudyForm[]>(this.baseUrl + '/GetAllStudyForms/')
        .pipe(
            map( response => {
              response = (response as { [key: string]: any })['studyForms'] as DictStudyForm[];
              if (response) {
                let item = new DictStudyForm();
                item.id = "";
                item.studyFormName = 'Все';
                response.unshift(item);
              }
              return response;
            }),
            catchError(this.handleError))
  }

  // Get all trainingLevels
  getAllTrainingLevels(): Observable<DictTrainingLevel[]> {
    return this.http.get<any[]>(this.baseUrl + '/GetAllTrainingLevels/')
        .pipe(
            map( response => {
              response = (response as { [key: string]: any })['trainingLevels'] as DictTrainingLevel[];
              if (response) {
                response.unshift({id: "", name: 'Все'});
              }
              return response;
            }),
            catchError(this.handleError))
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {Component, OnInit} from '@angular/core';
import {StructTabDto, StructPropertyDto, StructPropertyGroupDto,StructTabStudentModel} from '../../../../models/gia/giaprotocol.model';
import {Location} from '@angular/common';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {openDialog} from "../../../../helpers/dialogHelper";
import {StudentInfo} from '../../../../models/gia/student/student-info.model';
import {ProtocolGiaService} from '../../../../services/gia/protocol-gia.service';
import {DictsService} from '../../../../services/gia/dicts.service';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {ActivatedRoute, Router} from '@angular/router';
import {FormationDiplomaService} from '../../../../services/gia/formationdiploma.service';
import {ControlAction, mark} from '../../../../models/gia/enums/controlAction.enum';
import {DictDiplomaTypeService} from '../../../../services/gia/dict-diploma-type.service';
import {Types} from '../../../../models/gia/enums/dataType.enum';
import {ConstructorGiaProtocolService} from '../../../../services/gia/constructorgiaprotocol.service';
import {DateFromUTCAsLocal, TimeZoneFix, DateToString, getDayStart} from '../../../../helpers/date-helper';
import {SelectEvent} from "@progress/kendo-angular-layout";
import {TypesDicts} from '../../../../models/gia/enums/dictsStatic.enum';
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import {GiaTabsEnum} from "../../../../models/gia/enums/giaTabs.enum";
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";

@Component({
    selector : 'app-studentprotocol',
    templateUrl: './student-protocol.component.html',
    styleUrls : ['./student-protocol.component.scss']
})

export class StudentProtocolComponent implements OnInit {

    public studentData: StudentInfo = new StudentInfo;
    public studentId: string = "";
    public tabId: string = "";
    public tabsList: StructTabStudentModel[] = [];
    public filterTabsList: StructTabStudentModel[] = [];
    public selected = 0;
    public allowEdit: boolean = false;

    public structure: StructTabDto = {
      protocolNumber: '',
      protocolDate: '',
      id: '',
      studentId: '',
      name: '',
      isHidden: false,
      isMarkIncluded: false,
      displayTabId: '',
      protocolGroups: []};

    public types = Types;

    public dataChange: boolean = false;
    public selectedDicts: Array<{id: string | number, data: any}> = [];
    public loading: boolean = false;

    public trainingLevelId: string = "";
    public standardId: string = "";
    public facultyId: string = "";
    private filial = undefined;

    public dateProtocol: Date | null = null;
    private protocolId: string = "";

    private validation: boolean = false;
    public needSave: boolean = false;

    constructor(
        private location: Location,
        private dialogService: DialogService,
        private protocolGiaService: ProtocolGiaService,
        private dictsService: DictsService,
        private notificationService: NotificationsService,
        private activatedroute: ActivatedRoute,
        private formationDiplomaService: FormationDiplomaService,
        private dictDiplomaTypeService: DictDiplomaTypeService,
        private constructorGiaService: ConstructorGiaProtocolService,
        private userAccessService: GiaUserAccessService,
    ) {
        this.activatedroute.params.subscribe(params => {
            this.studentId = params['studentid'];
            this.getStudentInfo();
            const storage = localStorage.getItem('diplomas_settings');
            if (storage !== null) {
                this.trainingLevelId = JSON.parse(storage).trainingLevel;
                this.standardId = JSON.parse(storage).standard;
                this.facultyId = JSON.parse(storage).faculty;
                this.getTabsList()
            }
        });
    }

    async ngOnInit() {
        await this.getAccessLevel();
        this.getAllDicts();
        this.getMarks();
        this.getDiplomaTypes();
        this.getQualification();
    }

    public getTabsList() {
      this.constructorGiaService.getTabs(this.trainingLevelId, this.filial, this.facultyId)
      .subscribe(response => {
          this.tabsList = response;

          this.filterTabsList = this.tabsList.filter(el => !el.isHidden);
          if (this.filterTabsList.length !== 0) this.getTabData(this.filterTabsList[0].id);
      })
    }

    public getStudentInfo() {
        this.protocolGiaService.getStudentInfo(this.studentId)
          .subscribe(response => {
            this.studentData = response;
          })
    }

    public async getAccessLevel(){
      this.allowEdit = await this.userAccessService.hasEditingGia({
        studentId: this.studentId,
        section: GiaTabsEnum.протокол_ГИА
      });
    }
    
    public getTabData(tabId: string) {
      this.protocolGiaService.getStudentProtocolInfo(tabId, this.studentId)
        .subscribe(response => {

          this.structure = response;

          if (this.structure.protocolDate) this.dateProtocol = DateFromUTCAsLocal(this.structure.protocolDate);

          this.structure.protocolGroups.forEach(el => {
            el.protocolProperties.filter(item => item.dataTypeEnum == this.types.date && item.value !== null).forEach(item => item.value = new Date(item.value));
            el.protocolProperties.filter(item => (item.dataTypeEnum == this.types.numberint || item.dataTypeEnum == this.types.number) && item.value !== null).forEach(item => item.value = +item.value);
          })
          this.goTab();
          this.structure.protocolGroups.sort((a: StructPropertyGroupDto, b: StructPropertyGroupDto) => a.order - b.order);
        })
    }

    public getAllDicts() {
      this.constructorGiaService.getDicts()
        .subscribe(response => {
           const dinamicDicts = response;
           dinamicDicts.forEach((el: any) => {
            this.getDictConstructor(el.id);
           })
        })
    }

    private getDictConstructor(dictId: string) {
      this.dictsService.getDictData(dictId)
        .subscribe(response => {
            this.selectedDicts.push({id: dictId, data: response});
        })
    }

    private getMarks() {
        this.formationDiplomaService.getMarks()
        .subscribe(response => { 
          this.selectedDicts.push({id: TypesDicts.testMark, data: response.filter(el => (el.value <= mark.isTest || el.value == mark.failed) && el.value !== mark.notAllowed)})

          this.selectedDicts.push({id: TypesDicts.examMarks, data: response.filter(el => el.value < mark.failed && el.value !== mark.isTest && el.value !== mark.notAllowed)});
        })
    }

    private getDiplomaTypes() {
        this.dictDiplomaTypeService.getDiplomaTypes()
          .subscribe(response => {
            this.selectedDicts.push({id: TypesDicts.typeDiplomas, data: response});
          })
    }

    private getQualification() {
      this.protocolGiaService.getQualification(this.standardId)
        .subscribe(response => {
            this.selectedDicts.push({id: TypesDicts.qualification, data: response});
        })
    }

    public back() {
        if (this.dataChange) {
            const dialog: DialogRef = openDialog(this.dialogService, "Имеются несохраненные изменения, вы действительно хотите покинуть страницу?", 450, 220, 250);
            dialog.result.subscribe((result) => {
                if (!(result instanceof DialogCloseResult) && result.text == "Да"){
                    this.location.back();
                }
            })
        } else this.location.back();
    }

    public saveProtocol(print?: boolean) {
        if (this.validationCheck()) {
            this.notificationService.showError('Не все обязательные поля заполнены');
            this.validation = true;
            return;
        }

        if (this.dateProtocol) this.structure.protocolDate = new Date(TimeZoneFix(this.dateProtocol).toISOString());

        const data: StructTabDto = JSON.parse(JSON.stringify(this.structure));

        data.protocolGroups.forEach(el => {
          el.protocolProperties.filter(item => (item.dataTypeEnum == this.types.numberint || item.dataTypeEnum == this.types.number)
            && item.value !== null).forEach(item => item.value = item.value.toString());
        })

        this.protocolGiaService.saveGiaProtocol(data)
          .subscribe(response => {
            this.protocolId = response.toString();
            this.notificationService.showSuccess('Сохранено');
            this.needSave = false
            this.dataChange = false;
            if (print) this.printProtocol();
          })
    }

    private validationCheck() {
      return this.structure.protocolGroups.some(el => el.protocolProperties.some(item => item.isRequired && (item.value == null || item.value.toString().trim() == ""))) || 
      this.structure.protocolNumber == "" || this.structure.protocolNumber == null || this.dateProtocol == null 
    }

    public printProtocol() {
      this.protocolGiaService.printProtocol(this.protocolId).subscribe({
        next: (value) => {
          DownloadFile(value);
        },
        error: () => {
          this.notificationService.showError('Не удалось скачать файл');
        },
      });
    }

    public onChange(value: string | number | Date) {
        this.dataChange = true;
        this.goTab();
    }

    public getDictData(dictId: string | number | null | undefined) {
      if (dictId) return this.selectedDicts.find(el => el.id == dictId)?.data;
    }

    public onTabSelect(value: SelectEvent) {
        const tabId = this.filterTabsList.find(el => el.tabName == value.title)?.id;
        if (tabId) this.getTabData(tabId);
    }

    public validationField(dataItem: StructPropertyDto) {
      return dataItem.isRequired && !dataItem.value && this.validation;
    }

    public getFormat(value: number) {
      let format = "n2";
      let decimals: number | undefined = 0;
      if (typeof value == 'number') {
        value.toString().includes('.') ? decimals = (value.toString().split('.').pop()?.length) : decimals = 0;
        switch (decimals) {
          case 0:
            format = "n0";
            break;
          case 1:
            format = "n1";
            break;
          case 2:
              format = "n2";
              break;
          }
      }
      return format;
    }

    public goTab() {
      this.needSave = this.validationCheck() || this.dataChange;
    }
}

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AddEvent, CellClickEvent, GridComponent, PageSizeItem, RemoveEvent } from "@progress/kendo-angular-grid";
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, FormGroup } from "@angular/forms";
import { SalaryProject } from "src/app/models/cards/salaryproject";
import { SalaryProjectService } from "src/app/services/cards/salary-project.service";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { openRemoveDialog } from 'src/app/helpers/dialogHelper';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { getDayStart } from "src/app/helpers/date-helper";
import { isChildOf } from "src/app/helpers/elementRef-helper";
import { DictFilter } from 'src/app/models/contingent/filter.model';
import { DictFilial } from 'src/app/models/contingent/dictfilial.model';
import { DictFilialService } from 'src/app/services/contingent/dictfilial.service';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-salaryproject',
    templateUrl: './salaryproject.component.html',
    styleUrls: ['./salaryproject.component.scss']
  })

export class SalaryProjectComponent implements OnInit {

    public salary: SalaryProject[] = [];
    public salaryByFilter: SalaryProject[] = [];
    public pageSize = 10;
    public sizes = [10, 20, 50];
    public formGroup: UntypedFormGroup | undefined;
    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean {
        return this.editedRowIndex !== undefined || this.isNew;
    }
    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    public salaryProjectId: string = "";

    public filials: DictFilial[] = [];
    public secondFilter: DictFilter = {
        dictFilialExternalId: undefined
    }

    constructor(
        private salaryProjectService: SalaryProjectService,
        private notificationService: NotificationsService,
        private dialogService: DialogService,
        private renderer: Renderer2,
        private dictFilialService: DictFilialService,
    ) {}

    public ngOnInit(): void {
        this.getAllFilials();
        this.getSalaries();
        this.renderer.listen("document", "click", ({ target }) => {
            if (!isChildOf(target, "k-grid")) {
            this.saveCurrent();
            }
        });
    }

    public getSalaries() {
        this.salaryProjectService.getSalaryProjects()
            .subscribe(response => {                
               this.salary = response;
               this.salary.forEach(el => {
                    if (el.contractDate) el.contractDate = getDayStart(el.contractDate); 
               });
               this.saveCurrentFilter();
            })
    }

    public getAllFilials() {
        this.dictFilialService.getAllfilial()
          .subscribe(
            response => {
              this.filials = response;
            }
          );
      }
    
      public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
        filialSName: "Не выбрано",
        dictFilialExternalId: null,
      };
    
      public onValueChange(value: any): void {
        this.saveCurrentFilter();
      }
    
      saveCurrentFilter() {
        this.salaryByFilter = [];
        this.salaryByFilter = this.salary.filter(x => Guid.parse(x.filialId) == this.secondFilter.dictFilialExternalId);
      }
    
    public editHandler({
        sender,
        rowIndex,
        dataItem,
    }: CellClickEvent): void {
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {       
            return;
        }

       this.isLine = true;
       this.formGroup = formGroup(dataItem);
       this.editedRowIndex = rowIndex;
       this.salaryProjectId = dataItem.dictSalaryProjectExternalId;

       sender.editRow(rowIndex, this.formGroup);
    }

    public addHandler ({ sender }: AddEvent): void {
        this.closeEditor(sender);
    
        this.formGroup = formGroup({
            bankName: "",
            bankNum: "",
            filial: "",
            contractNum: "",
            contractDate: undefined,
            contractCurrency: "",
            codeVklad: "",
            codeVkladVid: "",
            codeVkladPodvid: "",
            categoryPeople: "" ,
            crn: "",
            order: null,
        });  
    
        this.isLine = true;
        this.isNew = true;
        sender.addRow(this.formGroup);
    }

    public removeHandler({ dataItem }: RemoveEvent): void {
        const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.bankName.toLowerCase(), 450, 200, 250);

        dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
              if(result.text == "Да"){
                this.salaryProjectService.deleteSalaryProjects(dataItem.dictSalaryProjectExternalId)
                .subscribe(
                  (response) => {
                    this.getSalaries();
                    this.notificationService.showSuccess("Удалено");                    
                  },
                  (error) => {
                    this.notificationService.showError(error.error);
                  }
                );
              }
            }
        });  
    }

    public saveCurrent() {
        if (this.formGroup && !this.formGroup.valid) return;
          
        this.isLine = false;       
        this.saveRow();
    }

    private saveRow(): void {
        if (this.formGroup == undefined) return;

        if (this.isInEditingMode) {
            //Adding new entry to dictionary

            if (this.isNew == true) {
                this.salaryProjectService.addSalaryProjects({ ...this.formGroup.value, ...this.secondFilter })
                .subscribe(
                    response => {
                        this.getSalaries();
                        this.notificationService.showSuccess("Добавлено");
                    },
                    error => {
                        this.notificationService.showError("Не удалось сохранить запись");
                    }
                );
            } else {
                //Update
                this.salaryProjectService.updateSalaryProjects({ ...this.formGroup.value, ...this.secondFilter }, this.salaryProjectId)
                    .subscribe(
                    response => {
                        this.getSalaries();
                        this.notificationService.showSuccess("Сохранено");
                    },
                    error => {
                        this.notificationService.showError("Не удалось изменить запись");
                    }
                    );
            }
        }
        this.closeEditor(this.grid, this.editedRowIndex)
    }

    public cancelHandler() {
        this.closeEditor(this.grid);
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        grid.closeRow(rowIndex);
        this.isNew = false;
        this.isLine = false;
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

}

const formGroup = (dataItem: {bankName: string;
                              bankNum: string;
                              filial: string;
                              contractNum: string;
                              contractDate: Date | undefined;
                              contractCurrency: string;
                              codeVklad: string;
                              codeVkladVid: string;
                              codeVkladPodvid: string;
                              categoryPeople: string;
                              crn: string;
                              order: number | null;
                            }) =>
  new UntypedFormGroup({   
    bankName: new UntypedFormControl(dataItem.bankName, Validators.required),
    bankNum: new UntypedFormControl(dataItem.bankNum),
    filial: new UntypedFormControl(dataItem.filial),
    contractNum: new UntypedFormControl(dataItem.contractNum),
    contractDate: new UntypedFormControl(dataItem.contractDate),
    contractCurrency: new UntypedFormControl(dataItem.contractCurrency),
    codeVklad: new UntypedFormControl(dataItem.codeVklad),
    codeVkladVid: new UntypedFormControl(dataItem.codeVkladVid),
    codeVkladPodvid: new UntypedFormControl(dataItem.codeVkladPodvid),
    categoryPeople: new UntypedFormControl(dataItem.categoryPeople),
    crn: new UntypedFormControl(dataItem.crn, Validators.required),
    order: new UntypedFormControl(dataItem.order),
});
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {
  Complaint, ComplaintGet,
  Customer,
  Employer,
  ExpulsionInfo,
  LabourActivitySubstantiation, LabourActivitySubstantiationGet
} from '../../models/contingent/targeted-training.model';
import {catchError} from 'rxjs/operators';
import {handleError} from '../../helpers/errorHandle-helper';

@Injectable({
  providedIn: 'root'
})
export class TargetedTrainingService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.targetedtraining}`;
  customerUrl = `${this.baseUrl}/customer`;
  employerUrl = `${this.baseUrl}/employer`;
  informingUrl = `${this.baseUrl}/informing`;
  complaintUrl = `${this.baseUrl}/complaintwork`;
  substantiationUrl = `${this.baseUrl}/labouractivitysubstantiation`;
  labourActivityUrl = `${this.baseUrl}/labouractivity`;

  constructor(private http: HttpClient) { }

  private getFormData(body: ExpulsionInfo | Complaint | LabourActivitySubstantiation) {
    const data = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.length
          ? value.forEach((item, i) => {
            Object.entries(item).forEach(([itemKey, itemValue]) => {
              data.append(`${key}[${i}].${itemKey}`, <string | File>itemValue ?? '');
            });
          })
          : data.append(key, '');
      } else if (value && typeof value === 'object' && !(value instanceof File)) {
        Object.entries(value).forEach(([itemKey, itemValue]) => {
          data.append(`${key}.${itemKey}`, <string|File>itemValue ?? '');
        });
      } else {
        data.append(key, <string|File>value ?? '');
      }
    });
    return data;
  }

  public getCustomer(studentId: string) {
    return this.http.get<Customer>(`${this.customerUrl}/GetCustomerByStudentId/${studentId}`)
      .pipe(catchError(handleError));
  }

  public setCustomer(studentId: string, customer: Customer) {
    if (customer.customerExternalId) {
      return this.http.put<Customer>(`${this.customerUrl}/UpdateCustomer/${studentId}`, customer)
        .pipe(catchError(handleError));
    } else {
      return this.http.post<Customer>(`${this.customerUrl}/CreateCustomer/${studentId}`, customer)
        .pipe(catchError(handleError));
    }
  }

  public getEmployer(studentId: string) {
    return this.http.get<Employer>(`${this.employerUrl}/GetEmployerByStudentId/${studentId}`)
      .pipe(catchError(handleError));
  }

  public setEmployer(studentId: string, employer: Employer) {
    if (employer.employerExternalId) {
      return this.http.put<Employer>(`${this.employerUrl}/UpdateEmployer/${studentId}`, employer)
        .pipe(catchError(handleError));
    } else {
      return this.http.post<Employer>(`${this.employerUrl}/CreateEmployer/${studentId}`, employer)
        .pipe(catchError(handleError));
    }
  }

  public getExpulsionInfo(studentId: string) {
    return this.http.get<ExpulsionInfo>(`${this.informingUrl}/GetStudentInformingAll/${studentId}`)
      .pipe(catchError(handleError));
  }

  public setExpulsionInfo(studentId: string, expulsionInfo: ExpulsionInfo) {
    if (expulsionInfo.informingCustomer?.id || expulsionInfo.informingEmployer?.id) {
      return this.http.put<ExpulsionInfo>(
        `${this.informingUrl}/EditInforming/${studentId}`, this.getFormData(expulsionInfo))
        .pipe(catchError(handleError));
    } else {
      return this.http.post<ExpulsionInfo>(
        `${this.informingUrl}/CreateInforming/${studentId}`, this.getFormData(expulsionInfo))
        .pipe(catchError(handleError));
    }
  }

  public getInformingFile(studentId: string, informingId: string) {
    return this.http.get(`${this.informingUrl}/GetInformingFile/${studentId}/${informingId}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }

  public getComplaint(studentId: string) {
    return this.http.get<ComplaintGet>(`${this.complaintUrl}/${studentId}`)
      .pipe(catchError(handleError));
  }

  public setComplaint(studentId: string, complaint: Complaint) {
    if (complaint.id) {
      return this.http.put<ExpulsionInfo>(`${this.complaintUrl}/UpdateComplaintWork/${studentId}`, this.getFormData(complaint))
        .pipe(catchError(handleError));
    } else {
      return this.http.post<ExpulsionInfo>(`${this.complaintUrl}/CreateComplaintWork/${studentId}`, this.getFormData(complaint))
        .pipe(catchError(handleError));
    }
  }

  public getComplaintFile(studentId: string, fileId: string) {
    return this.http.get(`${this.complaintUrl}/GetComplaintWorkFile/${studentId}/${fileId}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }

  public getLabourActivity(studentId: string) {
    return this.http.get<LabourActivitySubstantiationGet>(`${this.substantiationUrl}/${studentId}`)
      .pipe(catchError(handleError));
  }

  public setLabourActivity(studentId: string, substantiation: LabourActivitySubstantiation) {
    if (substantiation.id) {
      return this.http.put<LabourActivitySubstantiation>(
        `${this.substantiationUrl}/${studentId}`, this.getFormData(substantiation))
        .pipe(catchError(handleError));
    } else {
      return this.http.post<LabourActivitySubstantiation>(
        `${this.substantiationUrl}/${studentId}`, this.getFormData(substantiation))
        .pipe(catchError(handleError));
    }
  }

  public getActivitySubstantiationFile(studentId: string, substantiationId: string) {
    return this.http.get(
      `${this.substantiationUrl}/GetLabourActivitySubstantiationFile/${studentId}/${substantiationId}`,
      {responseType: 'blob'})
      .pipe(catchError(handleError));
  }

  public getActivityFile(studentId: string, fileId: string) {
    return this.http.get(
      `${this.labourActivityUrl}/GetLabActivityFileById/${studentId}/${fileId}`,
      {responseType: 'blob'})
      .pipe(catchError(handleError));
  }
}

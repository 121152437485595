import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Department } from "src/app/models/education/department.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class DepartmentService {
  
    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.kafedra}`;
  
    constructor(private http: HttpClient) { }
  
    public getAllDepartments(): Observable<Department[]> {
      return this.http.get<Department[]>(this.baseUrl + '/GetDepartments');
    }

    public updateDepartment(dto: Department): Observable<Department> {
        return this.http.put<Department>(this.baseUrl + '/UpdateDepartment/' + dto.kafedraId, dto);
    }
}
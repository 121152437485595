export class PrintTemplateModel {
  id!: string;
  filialId!: string;
  yearAdmission!: number;
  name?: string;
  dictPrintTemplateCategoryId!: string;
  dictPrintTemplateCategoryName!: string;
  dictPrintTemplateCategoryValue!: number;
  printTemplateTrainingLevels!: PrintTemplateTrainingLevels[];
  departmentId!: string;
  departmentName!: string;
  printerName?: string;
  isDefault!: boolean;
  printTemplateFirstFile!: PrintTemplateFile;
  printTemplateSecondFile?: PrintTemplateFile;
  printTemplateThirdFile?: PrintTemplateFile;
  fileName!: string;
}

export class PrintTemplateTrainingLevels {
  id?: string | null;
  name!: string;
}

export class PrintTemplateFile {
  id!: string;
  name!: string;
  updateAt!: Date;
}

export class PrintTemplateForm {
  id?: string;
  yearAdmission!: number;
  name?: string;
  dictPrintTemplateCategoryId!: string;
  printTemplateTrainingLevels!: PrintTemplateTrainingLevels[];
  departmentId!: string;
  printerName?: string;
  isDefault!: boolean;
  printTemplateFirstFile!: File;
  printTemplateSecondFile?: File;
  printTemplateThirdFile?: File;
}

<education-header></education-header>
<h1>Кафедры</h1>

<div class="grid-container">
  <!--Grid-->
  <kendo-grid [data]="departments"
              [height]="500"
              (cellClick)="editHandler($event)">

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictControlActionId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="kafedraName"
                       [editable]="false"
                       title="Название кафедры"
                       [width]="350"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.kafedraName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="filialSName"
        [editable]="false"
        title="Филиал"
        [width]="350"
        headerClass="gridHeader">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.filialSName}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="faculty.facultyName"
                       [editable]="false"
                       title="Название факультета"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.faculty?.facultyShortName}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="mmisCode"
                       title="Код"
                       [width]="100"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.mmisCode}}
                          </span>
                        </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { StatementSettings } from 'src/app/models/middlecontrol/statementSetting.model';

@Injectable({
  providedIn: 'root'
})
export class StatementSettingsService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.session.settings}`;

  constructor(private http: HttpClient) { }

  public GetStatementSettings(): Observable<StatementSettings> {
    return this.http.get<StatementSettings>(this.baseUrl + '/GetStatementSettings');
  }

  public EditStatementSettings(request: StatementSettings): Observable<StatementSettings> {
    return this.http.put<StatementSettings>(this.baseUrl + '/EditStatementSettings/', request)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

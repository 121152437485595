import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { WorkTypesModel } from "src/app/models/contingent/worktypes.model";
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
export class WorkTypesService {

    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.portfolio.dict}`;
    workType = '/WorkType';

    constructor(private http: HttpClient) { }

    public getWorkTypes() {   
        return this.http.get<WorkTypesModel[]>(this.baseUrl + this.workType); 
    }

    public addWorkTypes(data: WorkTypesModel) {
        return this.http.post(this.baseUrl + this.workType, data);
    }

    public updateWorkTypes(data: WorkTypesModel) {
        return this.http.put(this.baseUrl + this.workType, data);
    }

    public deleteWorkTypes(id: string) {
        return this.http.delete(this.baseUrl + this.workType + '/'+ id);
    }

}
import {Component, ViewChild} from "@angular/core";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DepartmentContactDetails} from "../../../../models/mfc/dicts/department-contact-details.model";
import {DepartmentContactDetailsService} from "../../../../services/mfc/dicts/department-contact-details.service";
import {TrainingLevel} from "../../../../models/mfc/dicts/training-level.model";
import {FilialTreeItem} from "../../../../models/mfc/dicts/filial.model";
import {TrainingLevelService} from "../../../../services/mfc/dicts/training-level.service";
import {FacultyService} from "../../../../services/mfc/dicts/faculty.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {AddEvent, CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {openDialog} from "../../../../helpers/dialogHelper";
import {Faculty} from "../../../../models/mfc/dicts/faculty.model";
import {arrayRewrite} from "../../../../helpers/multiselect-helper";

@Component({
  selector: 'mfc-dict-application-type',
  templateUrl: './department-contact-details.component.html',
  styleUrls: ['./department-contact-details.component.scss']
})
export class DepartmentContactDetailsComponent {

  @ViewChild(GridComponent) private grid!: GridComponent;

  protected departmentContactDetails: DepartmentContactDetails[] = [];
  protected dictTrainingLevels: TrainingLevel[] = [];
  protected dictFilials: FilialTreeItem[] = [];
  protected dictFaculties: Faculty[] = [];

  protected filteredFaculties: Faculty[] = [];

  protected isEditMode = false;
  protected editable = true;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  protected readonly pagerSettings = pagerSettings;
  protected form = this.createFormGroup();

  protected createFormGroup(dataItem?: DepartmentContactDetails): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      filial: new FormControl(dataItem?.filial?.id),
      facultyId: new FormControl(dataItem?.faculty.id, Validators.required),
      trainingLevels: new FormControl(dataItem?.trainingLevels.length ? dataItem?.trainingLevels.flatMap(a => a.id) : ['']),
      phone: new FormControl(dataItem?.phone, Validators.required),
      email: new FormControl(dataItem?.email, Validators.required),
      isAllTrainingLevelsAvailable: new FormControl(dataItem?.isAllTrainingLevelsAvailable ?? true)
    });
  }

  constructor(
    private departmentContactDetailsService: DepartmentContactDetailsService,
    private trainingLevelService: TrainingLevelService,
    private facultyService: FacultyService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService
  ) {
    this.getFilialsAndFaculties();
    this.getTrainingLevels();
    this.get();
  }

  protected get() {
    this.departmentContactDetailsService.getDepartmentContactDetails()
      .subscribe(data => this.departmentContactDetails = data);
  }

  private get trainingLevelsControl() {
    return this.form.controls['trainingLevels'];
  }

  private getFilialsAndFaculties() {
    this.facultyService.getFilialsAndFaculties().subscribe(data => {
      this.dictFilials = data;
      this.dictFaculties = data.flatMap(a => a.faculties);
      this.filteredFaculties = [...this.dictFaculties];
    });
  }

  private getTrainingLevels() {
    this.trainingLevelService.getTrainingLevels().subscribe(data => {
      this.dictTrainingLevels = [{id: '', name: 'Все', shortName: 'Все'}, ...data];
    });
  }

  protected saveCurrent() {
    const observable = this.departmentContactDetailsService
      [this.isNew ? 'addDepartmentContactDetails' : 'updateDepartmentContactDetails'](this.form.value);

    observable.subscribe(() => {
      this.notificationsService.showSuccess('Успешно');
      this.closeEditor();
      this.get();
      this.filteredFaculties = [...this.dictFaculties];
    });
  }

  protected addHandler({sender}: AddEvent): void {
    this.form = this.createFormGroup();
    this.isNew = true;
    this.isEditMode = true;
    sender.addRow(this.form);
  }

  protected editHandler({sender, rowIndex, dataItem, columnIndex}: CellClickEvent): void {
    if (this.isNew || !this.editable || this.isLine) {
      return;
    }

    this.form = this.createFormGroup(dataItem);

    if (this.form.invalid) {
      return;
    }

    this.editedRowIndex = rowIndex;
    this.isEditMode = true;
    this.isLine = true;
    sender.editRow(rowIndex, this.form, {columnIndex});
  }

  protected removeHandler({ dataItem }: RemoveEvent) {
    const dialog: DialogRef = openDialog(this.dialogService, `Вы действительно хотите удалить значение?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.departmentContactDetailsService.removeDepartmentContactDetails(dataItem.id)
          .subscribe({
            next: () => {
              this.get();
              this.closeEditor();
              this.notificationsService.showSuccess('Успешно');
            },
            error: (err) => this.notificationsService.showError(err)
          })
      }
    });
  }

  protected onFilialChange(id: string) {
    this.filteredFaculties = this.dictFilials
      .filter(filial => filial.id === id)
      .flatMap(item => item.faculties);
  }

  protected onTrainingLevelsChange(values: string[]) {
    if (!values.length) {
      values = [''];
    }
    const isAllControl = this.form.controls['isAllTrainingLevelsAvailable'];
    const filteredValues = arrayRewrite(values);
    this.trainingLevelsControl?.patchValue(filteredValues);
    isAllControl?.patchValue(filteredValues.includes(''));
  }

  protected closeEditor() {
    this.isNew = false;
    this.isEditMode = false;
    this.isLine = false;
    this.form.reset();
    this.grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
  }
}

<gia-settings></gia-settings>
<h1>Приказ о допуске к ГИА</h1>

<div class="selectContent w-850">
  <form [formGroup]="admissionOrderForm" class="selectContent w-850">
    <dd class="list">
      <span class="selectText">
        Номер и дата приказа
      </span>
      <div class="list w-600">
        <span class="selectText">
          №
        </span>
        <kendo-textbox [disabled]="!editable" class="m-l15"
                       formControlName="orderNumber">
        </kendo-textbox>
        <span *ngIf="editable" class="required m-l10">*</span>

        <span class="selectText m-l30">
          Дата
        </span>
        <kendo-datepicker [disabled]="!editable" class="m-l15"
                          formControlName="orderDate">
        </kendo-datepicker>
      </div>
      <span *ngIf="editable" class="required m-l10">*</span>
    </dd>

  </form>
</div>

<div class="form-buttons">
  <div>
    <button kendoButton size="small" (click)="back()">Отмена</button>
    <button kendoButton class="save-button" size="small" themeColor="primary" [disabled]="admissionOrderForm.invalid" *ngIf="editable" (click)="save()">
      Сохранить
    </button>
  </div>
  <button kendoButton size="small" themeColor="primary" icon="print" [disabled]="admissionOrderForm.invalid" *ngIf="editable" (click)="save(true)">
    Сохранить и распечатать
  </button>
</div>

<div class="form-buttons" *ngIf="!isNew && editable">
  <div></div>
  <button kendoButton size="small" themeColor="success" icon="plus" (click)="addStudent()">
    Добавить обучающихся
  </button>
</div>

<h1 class="f-s17">Список обучающихся</h1>

<kendo-grid
  class="m-t15"
  (remove)="removeHandler($event)"
  [kendoGridBinding]="students"
  [pageable]="pagerSettings"
  [pageSize]="pageSize"
  [loading]="loading"
  [group]="group"
  [sort]="sort"
  [sortable]="true"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="assignedNumber" title="№ п/п" [width]="100" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="alignCells">{{ isNew ? (rowIndex + 1) : dataItem.assignedNumber }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="studentFio" format="dd.MM.yyyy" title="ФИО обучающегося" headerClass="gridHeader" [width]="250">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">{{ dataItem.studentFio }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="groupName" title="Академическая группа" [width]="250" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.groupName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="basis" title="Основание" [width]="250" headerClass="gridHeader dictionaries" *ngIf="trainingLevelEnum === TrainingLevelEnum.Graduate">
    <ng-template kendoGridCellTemplate let-dataItem>
      <kendo-textbox
        size="small"
        [(ngModel)]="dataItem.basis"
      ></kendo-textbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="specialization" title="Специализация"
                     headerClass="gridHeader dictionaries"
                     [width]="350"
                     [hidden]="true"
                     [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.specialization }}
        </span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group="group">
        <span>
          {{ group.value ? "Специализация" : "" }}: {{ group.value }}
        </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column
    *ngIf="editable"
    title="" [width]="50">
    <ng-template kendoGridCellTemplate>
      <button kendoGridRemoveCommand
              icon="delete"
              fillMode="flat"
              size="small"
              rounded="full">
      </button>
    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>

<div class="form-buttons">
  <div>
    <button kendoButton size="small" (click)="back()">Отмена</button>
    <button kendoButton class="save-button" size="small" themeColor="primary" [disabled]="admissionOrderForm.invalid" *ngIf="editable" (click)="save()">
      Сохранить
    </button>
  </div>
  <button kendoButton size="small" themeColor="primary" icon="print" [disabled]="admissionOrderForm.invalid" *ngIf="editable" (click)="save(true)">
    Сохранить и распечатать
  </button>
</div>

<div kendoDialogContainer></div>


<education-header></education-header>
<title>Компонент</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Компонент</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новый компонент' : 'Изменить компонент' }}
  </h1>

  <div class="selectContent" *ngIf="!isOpened">
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Уровень подготовки
      </span>
      <kendo-dropdownlist [data]="traininglevels"
                          textField="trainingLevelName"
                          valueField="trainingLevelExternalId"
                          [valuePrimitive]="true"
                          name="trainingLevel"
                          [(ngModel)]="secondFilter.trainingLevelId"
                          [defaultItem]="defaultItemTrainingLevel"
                          (valueChange)="onValueChange($event)"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>

    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Тип стандарта
      </span>
      <kendo-dropdownlist [data]="standardTypes"
                          textField="standardTypeShortName"
                          valueField="standardTypeExternalId"
                          [valuePrimitive]="true"
                          name="standardType"
                          (valueChange)="onValueChange($event)"
                          [(ngModel)]="secondFilter.standardTypeId"
                          [defaultItem]="defaultItemStandardType"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
  </div>

  <div [class]="[gridStyle]">
    <button kendoButton [primary]="true" (click)="addHandler()" class="addButton" iconClass="k-icon k-i-plus" *ngIf="editable">Добавить</button>
    <kendo-grid
            (remove)="removeHandler($event)"
            [data]="dictComponentsByFilter"
            [kendoGridBinding]="dictComponentsByFilter"
            [navigable]="true"
            [sortable]="true"
            [filterable]="true"
            [filter]="filter"
            (filterChange)="filterChange($event)"
            [pageable]="
              {
                  buttonCount: buttonCount,
                  info: info,
                  type: type,
                  pageSizes: pageSizes,
                  previousNext: previousNext,
                  position: position
              }"
            [pageSize]="10"
            [height]="600"
            [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <!-- <kendo-grid-column headerClass="gridHeader dictionaries" [width]="175" field="dictCycle.cycleInfo" title="Уровень">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains"
                  ignoreCase="true">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictCycle.cycleInfo}}
          </span>
        </ng-template>
      </kendo-grid-column> -->
      <kendo-grid-column headerClass="gridHeader dictionaries" [width]="300" field="dictCycle.nameFormatted" title="Цикл">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="dictCyclesByFilter" textField="nameFormatted"
                           valueField="dictCycleExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictCycle.nameFormatted}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" [width]="170" field="dictComponentShortName" title="Сокр.название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains"
                  ignoreCase="true">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictComponentShortName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" [width]="200" field="dictComponentName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains"
                  ignoreCase="true">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" style="text-align: left; justify-content: left;">
            {{dataItem.dictComponentName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <!-- <kendo-grid-column headerClass="gridHeader dictionaries" [width]="200" field="dictComponentPrintName" title="Печатное название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains"
                  ignoreCase="true">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictComponentPrintName}}
          </span>
        </ng-template>
      </kendo-grid-column> -->
      <!-- <kendo-grid-column headerClass="gridHeader dictionaries" [width]="200" field="dictComponentNameGenitiveCase" title="Название в род.падеже">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains"
                  ignoreCase="true">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictComponentNameGenitiveCase}}
          </span>
        </ng-template>
      </kendo-grid-column> -->
      <kendo-grid-column headerClass="gridHeader dictionaries" [width]="250" field="dictComponentType.dictComponentTypeName" title="Тип компонента">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [filter]="filter" [data]="componentTypes" textField="dictComponentTypeName"
                           valueField="dictComponentTypeExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictComponentType.dictComponentTypeName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="parentDictComponentName" title="Родительский элемент" [filterable]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.parentDictComponentName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="serialNumber" title="Порядок сортировки" [filterable]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.serialNumber}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="hiddenTitle" title="Скрыть название в структуре плана" [filterable]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.hiddenTitle">
            <div *ngSwitchCase="true">Да</div>
            <div *ngSwitchCase="false">Нет</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="hiddenCipher" title="Скрыть шифр в структуре плана" [filterable]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.hiddenCipher">
            <div *ngSwitchCase="true">Да</div>
            <div *ngSwitchCase="false">Нет</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="hiddenLaborIntensity" title="Не учитывать в суммарной трудоемкости" [filterable]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.hiddenLaborIntensity">
            <div *ngSwitchCase="true">Да</div>
            <div *ngSwitchCase="false">Нет</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="hiddenDisciplineNumber" title="Скрывать порядковый номер дисциплин" [filterable]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.hiddenDisciplineNumber">
            <div *ngSwitchCase="true">Да</div>
            <div *ngSwitchCase="false">Нет</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="enumerateDisciplinesByTypeInCycle" title="Сквозная нумерация по типам дисциплин" [filterable]="false" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.enumerateDisciplinesByTypeInCycle">
            <div *ngSwitchCase="true">Да</div>
            <div *ngSwitchCase="false">Нет</div>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="170">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton class="editButton" (click)="editHandler(dataItem)" type="button" >Изменить</button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="170">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictComponentName" [maxlength]="255" #dictComponentName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="dictComponentShortName" [maxlength]="50" #dictComponentShortName></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Печатное название</h2>
          <kendo-textbox formControlName="dictComponentPrintName" [maxlength]="255" #dictComponentPrintName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название в род.падеже</h2>
          <kendo-textbox formControlName="dictComponentNameGenitiveCase" [maxlength]="255" #dictComponentNameGenitiveCase required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Цикл</h2>
          <kendo-dropdownlist
                  [data]="dictCyclesByFilter"
                  textField="nameFormatted"
                  valueField="dictCycleExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['dictCycleExternalId']"
                  (valueChange)="cyclesChange($event)"
                  formControlName="dictCycleExternalId" #dictCycleExternalId required>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              {{ dataItem.cycleInfo }} {{ dataItem.nameFormatted }}
            </ng-template>
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Тип компонента</h2>
          <kendo-dropdownlist
                  [data]="componentTypes"
                  textField="dictComponentTypeName"
                  valueField="dictComponentTypeExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['dictComponentTypeExternalId']"
                  formControlName="dictComponentTypeExternalId" #dictComponentTypeExternalId required>
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Родительский элемент</h2>
          <kendo-dropdownlist
                  [data]="dictComponentsByCycleFilter"
                  textField="dictComponentName"
                  valueField="dictComponentExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['parentDictComponentId']"
                  [defaultItem]="defaultItemParentElement"
                  formControlName="parentDictComponentId" #parentDictComponentId>
            <ng-template kendoDropDownListNoDataTemplate>
              <h3>Нет данных</h3>
            </ng-template>
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Порядок сортировки</h2>
          <kendo-numerictextbox formControlName="serialNumber" [min]="1" format="n0" [decimals]="0" #serialNumber></kendo-numerictextbox>
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2 style="width: 50%">
            Скрыть название в структуре плана
          </h2>
          <div style="width: 50%">
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="hiddenTitle" #hiddenTitle/>
          </div>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2 style="width: 50%">
            Скрыть шифр в структуре плана
          </h2>
          <div style="width: 50%">
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="hiddenCipher" #hiddenCipher/>
          </div>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2 style="width: 50%">
            Не учитывать в суммарной трудоемкости
          </h2>
          <div style="width: 50%">
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="hiddenLaborIntensity" #hiddenLaborIntensity/>
          </div>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2 style="width: 50%">
            Скрывать порядковый номер дисциплин
          </h2>
          <div style="width: 50%">
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="hiddenDisciplineNumber" #hiddenDisciplineNumber/>
          </div>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2 style="width: 50%">
            Сквозная нумерация по типам дисциплин
          </h2>
          <div style="width: 50%">
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="enumerateDisciplinesByTypeInCycle" #enumerateDisciplinesByTypeInCycle/>
          </div>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton type="button" (click)="onCancel()">Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveComponent()">Сохранить</button>
      </div>
    </form>
  </div>
</div>

<div kendoDialogContainer></div>

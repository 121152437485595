import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-release-order-home',
  templateUrl: './release-order-home.component.html',
  styleUrls: ['./release-order-home.component.scss']
})
export class ReleaseOrderHomeComponent implements OnInit {

  constructor() {}

  ngOnInit() {}
}

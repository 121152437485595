<div class="container" [hidden]="assignmentDiscipline">
  <h1>Поручение кафедре</h1>
  <span class="workloadDropdownContainer">
    <h3>Учебный год</h3>
    <kendo-dropdownlist valueField="name" textField="name" class="workloadDropdown"
                        [data]="yearAndSemestrs"
                        [(ngModel)]="yearAndSemestrsEdit"
                        (valueChange)="valueChange($event, 'yearAndSemestrsEdit')"
                        [valuePrimitive]="false">
    </kendo-dropdownlist>
  </span>
  <span class="workloadDropdownContainer">
    <h3>Институт/факультет</h3>
    <kendo-dropdownlist class="workloadDropdown" valueField="id" textField="sName"
                        [data]="faculties"
                        [(ngModel)]="facultiesEdit"
                        (valueChange)="valueChange($event, 'facultiesEdit')">
    </kendo-dropdownlist>
  </span>
  <span class="workloadDropdownContainer">
    <h3>Уровень подготовки</h3>
    <kendo-dropdownlist class="workloadDropdown" textField="name" valueField="ids"
                        [data]="trainingLevels"
                        [(ngModel)]="trainingLevelsEdit"
                        (valueChange)="valueChange($event, 'trainingLevelsEdit')">
    </kendo-dropdownlist>
  </span>
  <span class="workloadDropdownContainer">
    <h3>Форма обучения</h3>
    <kendo-dropdownlist (valueChange)="valueChange($event, 'studyFormEdit')"
                        [data]="studyForms"
                        [(ngModel)]="studyFormEdit"
                        [virtual]="virtual"
                        class="workloadDropdown" textField="studyFormName" valueField="id">
    </kendo-dropdownlist>
  </span>
  <span class="workloadDropdownContainer last">
    <h3>Кафедра</h3>
    <kendo-dropdownlist [data]="kafedras"
                        (valueChange)="valueChange($event, 'kafedraEdit')"
                        [(ngModel)]="kafedraEdit"
                        [valuePrimitive]="false"
                        [virtual]="virtual"
                        textField="name" valueField="id" class="workloadDropdown"></kendo-dropdownlist>
  </span>
  <span *ngIf="userEditing" class="buttons">
    <h3>Пересчитать поручения:</h3>
    <button class="updateContingent" kendoButton icon="reload"
            [disabled]='assignmentUpdate != null'
            [ngClass]='(assignmentUpdate === "contingent" ? "spinner" : "")'
            (click)="updateContingent()"
    >Обновить контингент</button>
    <button class="updateEducationPlans" kendoButton icon="reload"
            [disabled]='assignmentUpdate != null'
            [ngClass]='(assignmentUpdate === "edu-plan" ? "spinner" : "")'
            (click)="updateEducationPlanes()"
    >Обновить учебные планы</button>
    <button class="UpdateContingentAndPlans" kendoButton icon="reload"
            [disabled]='assignmentUpdate != null'
            [ngClass]='(assignmentUpdate === "all-cathedras" ? "spinner" : "")'
            (click)="UpdateContingentAndPlans()"
    >Обновить все кафедры</button>
  </span>
  <span class="lastUpdate">Последнее обновление: {{lastAssignmentsUpdate != '' ? toLocalDatetime(lastAssignmentsUpdate) : ' -'}}</span>
  <span *ngIf="userEditing" [ngClass]="{edit: editable}" class="buttons massOperations">
  <h3>Массовая работа с поручениями:</h3>
  <button kendoButton *ngIf="!editable" (click)="toggleMassOperations()">Режим массовых операций</button>
  <button kendoButton [primary]="true" (click)="addDisciplineNavigate()" *ngIf="!editable" icon="plus">Добавить дисциплину</button>
  <button [disabled]="groupSelection.length == 0" themeColor="primary" kendoButton (click)="massOperationCreateAssignmentDisciplines()" *ngIf="editable">Создать поручения</button>
  <button kendoButton [disabled]="groupSelection.length == 0" (click)="massOperationRecallAssignmentDisciplines()" *ngIf="editable">Отозвать поручения</button>
  <button kendoButton *ngIf="editable" icon="cancel" (click)="toggleMassOperations()">Закрыть</button>
  <button class="export" kendoButton icon="download" (click)="exportAssignmentDepartments()">Поручение по учебной работе</button>
</span>
  <kendo-grid #grid class="list-assignments" kendoGridSelectBy="id"
              [kendoGridBinding]="mainGridData"
              [height]="600"
              [loading]="loading"
              [(selectedKeys)]="groupSelection"
              [selectable]="{ enabled: true, checkboxOnly: true }"
              [sortable]='true'
              (cellClick)="disciplineAssignmentNavigate($event, false)">
    <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    <kendo-grid-checkbox-column *ngIf="editable"
                                [width]="50"
                                headerClass="gridHeader hideborder"
                                [showSelectAll]="true">
      <ng-template kendoGridHeaderTemplate>
        <span class="alignCells">
          <input (click)="checkmarkSelectAll($event)" kendoCheckBox id="selectAllCheckboxId" kendoGridSelectAllCheckbox >
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-idx="rowIndex">
        <span class="alignCells">
          <input kendoCheckBox [kendoGridSelectionCheckbox]="idx" />
        </span>
      </ng-template>
    </kendo-grid-checkbox-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="groupByDiscipline" title="Дисциплина"
                       [width]="150" [hidden]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem?.educationPlanDiscipline?.disciplineFullName}}
        </span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group="group">
        <input kendoCheckBox style="margin-left: 9px;" type="checkbox" *ngIf="editable" (click)="selectGroup($event, group)">
        <span (click)="onClickGroupDiscipline($event, group)" class="alignCells bold leftAlignment groupingRow__left">{{ displayDisciplineName(group) }}</span>
        <div (click)="onClickGroupDiscipline($event, group)" class="alignCells leftAlignment groupingRow"> {{ displayConjurations(group) }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="educationPlanDiscipline.semester" [width]="50" title="Сем.">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.educationPlanDiscipline.semesterNumber}}
          </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="eduGroup.name" [width]="115" title="Ак. группы (студ. факт)">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{displayGroupName(dataItem)}}
        </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="contingentLoadStudent" [width]="85" title="Студ. в расч.">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" [ngClass]="mismatchStudentStyle(dataItem)">
          {{displayStudentCount(dataItem)}}
        </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="subEduGroup" [width]="85" title="П/г">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" [ngClass]="mismatchSubGroupStyle(dataItem)">
          {{displaySubGroup(dataItem)}}
        </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="controlAction" [width]="100" title="Контроль">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.educationPlanDiscipline.dictControllingAction.name}}
        </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="onAssignment" [width]="73" title="Поруч.">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [ngSwitch]="dataItem.disciplineGroup && dataItem.disciplineGroup.onAssignment" class="alignCells">
            <span class="errandsCell empty" *ngSwitchCase="false">Нет</span>
            <span class="errandsCell included" *ngSwitchCase="true">Да</span>
            <span class="errandsCell empty" *ngSwitchDefault>Нет</span>
          </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="groupFlows" [width]="70" title="Поток">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <span class="alignCells">
            <span>
                {{displayGroupFlows(dataItem.groupFlows)}}
            </span>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="numberWeek" [width]="55" title="Нед.">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.educationPlanDiscipline.numberWeek}}
        </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group headerClass="gridHeader hideborder" title="Контактная работа, ч"x>
        <kendo-grid-column headerClass="gridHeader hideborder" field="lectureHours" [width]="60" title="лек.">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <span [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.lectionId)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.lectionId)}}
                </span>
            </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column headerClass="gridHeader hideborder" field="labHours" [width]="60" title="лаб.">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <span [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.laboratoryId)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.laboratoryId)}}
                </span>
            </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column headerClass="gridHeader hideborder" field="practiceHours" [width]="60" title="практ.">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <span [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.practiceId)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.practiceId)}}
                </span>
            </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column headerClass="gridHeader hideborder" field="ksrHours" [width]="60" title="КСР">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <span [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.CSR)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.CSR)}}
                </span>
            </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column headerClass="gridHeader hideborder" field="courseWork" [width]="50" title="КР">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <span class="alignCells">
          <span>
            {{displayCourseWork(dataItem)}}
          </span>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="courseProject" [width]="50" title="КП">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <span class="alignCells">
          <span>
            {{displayCourseProject(dataItem)}}
          </span>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group headerClass="gridHeader hideborder" field="tests" title="Работы (контр.работы, РГР, реф., эссе), шт">
        <kendo-grid-column headerClass="gridHeader hideborder" field="testNum" [width]="60" title="контр.">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <span [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.ControlWork)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.ControlWork)}}
                </span>
            </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column headerClass="gridHeader hideborder" field="rgrNum" [width]="60" title="РГР">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <span [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.RGR)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.RGR)}}
                </span>
            </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column headerClass="gridHeader hideborder" field="refNum" [width]="60" title="реф.">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <span [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.Referat)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.Referat)}}
                </span>
            </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column headerClass="gridHeader hideborder" field="assignmentUpdate" [width]="90" title="Дата выдачи">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{toLocalDatetime(dataItem.assignmentDiscipline?.updatedAt)}}
            </span>
          </ng-template>
      </kendo-grid-column>
  </kendo-grid>
  <span class="warning">
    <h3>
      <span class="red">Красным</span>
      подсвечены ячейки, имеющие расхождение текущих значений со значениями в поручении. В скобках указано текущее значение
    </h3>
  </span>

  <h1>Дополнительные работы</h1>
  <kendo-grid #extraGrid class="extraGrid"
              [ngClass]="{noData: extraWork.length < 4, edit: userEditing }"
              [kendoGridBinding]="extraWork"
              [height]="300"
              (add)="addAdditionalWorkHandler($event)"
              (cellClick)="editAdditionalWork($event)">
      <ng-template kendoGridToolbarTemplate *ngIf="userEditing">
          <button *ngIf="!isEdit" kendoGridAddCommand [primary]="true" icon="plus" style="margin-bottom: 20px">Доп. работа</button>
          <span class="handlerButtons" *ngIf="isEdit">
          <button themeColor="primary" [disabled]="!formGroup?.valid" (click)="saveAdditionalWork()" kendoButton>Сохранить</button>
          <button class="cancelButton" kendoButton (click)="cancelHandler($event)">Отмена</button>
      </span>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader" field="dictAdditionalWork.name" [width]="400" title="Дополнительная работа">
          <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.dictAdditionalWork.name}}
          </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
              <kendo-combobox
                      [kendoDropDownFilter]="filterSettings"
                      [data]="dictAdditionalWorks"
                      textField="name"
                      valueField="id"
                      [formControl]="formGroup.get('dictAdditionalWorksId')"
                      [valuePrimitive]="true"
                      [virtual]="virtual">
              </kendo-combobox>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader" field="eduGroup.name" [width]="150" title="Группа">
          <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.eduGroup.name}} ({{dataItem.eduGroup.studentCount}} чел.)
          </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
              <kendo-combobox
                  #eduGroup
                  [kendoDropDownFilter]="filterSettings"
                  [data]="eduGroups"
                  textField="name"
                  valueField="id"
                  [valuePrimitive]="true"
                  [formControl]="formGroup.get('eduGroupId')"
                  [virtual]="virtual">
              </kendo-combobox>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader" field="totalStudentsLoad" [width]="150" title="Дн./студ./гр. в расчет">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{dataItem.totalStudentsLoad}}
              </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem let-formGroup>
              <span class="alignCells">
                  <kendo-numerictextbox format="false"
                                        [min]='0' [changeValueOnScroll]='false'
                                        [formControl]="formGroup.get('totalStudentsLoad')"
                  ></kendo-numerictextbox>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader" [width]="80" title="" [hidden]='!userEditing'>
          <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" *ngIf="!isAdd" >
            <button class="red" kendoButton (click)="open(dataItem)" themeColor="secondary" icon="delete" fillMode="flat" size="medium"
                    rounded="full" style="text-overflow: unset;">
            </button>
          </span>
          </ng-template>
      </kendo-grid-column>
  </kendo-grid>
  <span class="handlerButtons top" *ngIf="isEdit && userEditing">
    <button themeColor="primary" [disabled]="!formGroup?.valid" (click)="saveAdditionalWork()" kendoButton>Сохранить</button>
    <button class="cancelButton" kendoButton (click)="cancelHandler($event)">Отмена</button>
  </span>

  <kendo-dialog [width]="400" *ngIf="opened" (close)="close()">
      <p style="margin: 30px; text-align: center;">
          Вы уверены что хотите удалить: {{itemToDelete}}?
      </p>
      <kendo-dialog-actions>
          <button kendoButton (click)="close()">Нет</button>
          <button kendoButton
                  themeColor="primary"
                  (click)="removeAdditionalWork()"
                  [primary]="true">
              Да
          </button>
      </kendo-dialog-actions>
  </kendo-dialog>
</div>

<disciplineAssignment
        #child
        [hidden]="!assignmentDiscipline"
        [editingRights]="userEditing"
        (onBack)="onStateChange($event)"
        [yearAndSemestrs]="yearAndSemestrsEdit"
        [faculty]="facultiesEdit"
        [trainingLevel]="trainingLevelsEdit"
        [studyForm]="studyFormEdit"
        [kafedra]="kafedraEdit"
></disciplineAssignment>

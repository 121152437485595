import { Guid } from "guid-typescript";

export class DepartmentName {
  public id?: number;
  public externalId?: string;
  public departmentId?: Guid;
  public name?: string;
  public shortName?: string;
}

export class Faculty {
  public facultyId!: number;
  public externalId?:Guid;
  public facultyExternalId?: Guid | string;
  public name?: string;
  public shortName?: string;
  public facultyFullName?: string;
  public filialId?: Guid | string;
}


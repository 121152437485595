import {Guid} from "guid-typescript";

export class DictSignatoryRoleRead {
  public id!: Guid | string;
  public name!: string;
  public serialNumber!: number | null;
}

export class DictSignatoryRoleAdd {
  public name!: string;
  public serialNumber!: number | null;
}

export class DictSignatoryRoleUpdate {
  public id!: Guid | string;
  public name!: string;
  public serialNumber!: number | null;
}

<h1>{{ editDiscipline ? 'Редактирование дисциплины' : 'Добавление дисциплины'}}</h1>

<div class="m-t15 display-flex">
    <p class="selectContent parameters w-150">Тип дисциплины</p>
    <kendo-dropdownlist class="w-600 m-l15"
                        [data]="disciplinesType"
                        textField="name"
                        valueField="id"
                        [valuePrimitive]="true"
                        [(ngModel)]="typeDiscipline">
    </kendo-dropdownlist>
</div>

<div class="m-t15 display-flex">
    <p class="selectContent parameters w-150">Дисциплина</p>
    <kendo-textbox class="w-500 m-l15" [(ngModel)]="discipline">
    </kendo-textbox>
    <button kendoButton class="m-l15" (click)="getDiscipline()">Поиск</button>
</div>

<p class="selectContent parameters m-t15 title">Список дисциплин</p>
<kendo-grid #gridDiscipline
    class="m-t15"
    [data]="gridView"
    [loading]="loading"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip"
    [pageable]="{
        buttonCount: buttonCount,
        info: info,
        type: type,
        previousNext: previousNext,
        position: position
    }"
    (pageChange)="pageChange($event)"
    >
    <ng-template kendoGridLoadingTemplate>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    
    <kendo-grid-column field="name"
                       title="Наименование дисциплины"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                {{ dataItem.name }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" headerClass="gridHeader dictionaries" [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            <button kendoButton type="button" (click)="editHandler(dataItem)" class="editButton">Выбрать</button>
            </span>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<p class="selectContent parameters m-t25 title">Список выбранных дисциплин</p>
<p class="selectContent m-t15">Количество выбранных дисциплин: {{ selectionDisciplines.length }}</p>

<kendo-grid #selectGrid
    class="m-t15"
    [kendoGridBinding]="selectionDisciplines"
    >
    <kendo-grid-column field="disciplineName"
                       title="Наименование дисциплины"
                       [width]="250"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                {{ dataItem.disciplineName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="attachmentDisciplineName"
                       title="Наименование дисциплины в приложении"
                       [width]="300"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-textbox [(ngModel)]="dataItem.attachmentDisciplineName">
            </kendo-textbox>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="controlAction"
                       title="Тип промежуточного контроля"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-dropdownlist class="w-300" [ngClass]="{'borderred': checkField(dataItem.controlActionEnum)}"
                        [data]="controlActions"
                        textField="name"
                        valueField="controlActionEnum"
                        [valuePrimitive]="true"
                        (valueChange)="onChangeControlAction($event, dataItem)"
                        [(ngModel)]="dataItem.controlActionEnum">
            </kendo-dropdownlist>
            
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isFacultative"
                       title="Факультатив"
                       [width]="140"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox [checked]="dataItem.isFacultative" (change)="clickCheckbox(dataItem, 'elective')"/>
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isCourseWork"
                       title="Курсовая работа/проект"
                       [width]="180"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox [checked]="dataItem.isCourseWork" (change)="clickCheckbox(dataItem, 'courseWork')"/>
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="creditUnit"
                       title="З.ед."
                       [width]="140"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox
                [ngClass]="{borderred: !dataItem.creditUnit}"
                [min]="0"
                [spinners]="false"
                format="n2"
                [(ngModel)]="dataItem.creditUnit"
            ></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hours"
                        title="Часы"
                        [width]="140"
                        headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox
                [ngClass]="{borderred: !dataItem.hours}"
                [min]="1"
                [spinners]="false"
                format="n0"
                [(ngModel)]="dataItem.hours"
            ></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hoursContactWork"
                        title="Аудиторные часы"
                        [width]="140"
                        headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox
                [disabled]="dataItem.disciplineType == electivePhysicalTypeId || dataItem.isFacultative"
                [ngClass]="{borderred: dataItem.disciplineType !== electivePhysicalTypeId && !dataItem.isFacultative && !dataItem.hoursContactWork}"
                [min]="1"
                [spinners]="false"
                format="n0"
                [(ngModel)]="dataItem.hoursContactWork"
            ></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="" headerClass="gridHeader dictionaries" [width]="140">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            <button kendoButton type="button" (click)="deleteDiscipline(dataItem)" class="editButton">Удалить</button>
            </span>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<div class="alignend m-t15">
    <button kendoButton (click)="back()">Отмена</button>
    <button kendoButton themeColor="primary" class="m-l15" [disabled]="checkValidate()" *ngIf="!editDiscipline" (click)="saveDiscipline()">Сохранить</button>
    <button kendoButton themeColor="primary" class="m-l15" [disabled]="checkValidate()" *ngIf="editDiscipline" (click)="saveEditedDiscipline()">Сохранить</button>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ParametersPersonService {

    baseUrl = `${environment.lkPersonApiEndpoint}`;

    constructor(private http: HttpClient) { }

    public getParametersPerson(id: any){
        return this.http.get(this.baseUrl + 'person/' + id);
    }

    public addParametersPerson(data: any){
        return this.http.post(this.baseUrl + 'person/add', data);
    }

    public updateParametersPerson(data: any, id: string){
        data.externalId = id;
        return this.http.put(this.baseUrl + 'person/ManualUpdatePerson', data);
    }

    public getPosts(){
        return this.http.get(this.baseUrl + 'd/getPostList');
    }

    public getDepartments(){
       return this.http.get(this.baseUrl + 'department/getDepartments');
    }

    public getScienceDegrees(){
        return this.http.get(this.baseUrl + 'd/getScientDegreesList');
    }

    public getTypes(){
        return this.http.get(this.baseUrl + 'd/getSalaryTypesList');
    }

    public checkLogin(data: string){
        return this.http.post(this.baseUrl + 'person/CheckLogin/' + data, {} );
    }

}
<education-header></education-header>
<div class="grid-layout-container m-t20"> 
    <div class="main-title"> Экспорт графиков учебного процесса </div>

    <div class="parameters">
        <div class="unit">
            <kendo-label class="search-explanation" text="Учебный год"></kendo-label>
            <div class="input-search">
                <kendo-combobox
                    placeholder="учебный год"
                    [data]="studyYears"
                    textField="studyYearFormatted"
                    valueField="studyYear"
                    [valuePrimitive]="true"
                    (valueChange)="valueChangeYears($event)"
                    name="year"
                    [(ngModel)]="year"
                    >
                </kendo-combobox>
            </div>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" text="Филиал"></kendo-label>
            <div class="input-search">
                <kendo-multiselect
                    [data]="filials"
                    placeholder="Все"
                    kendoMultiSelectSummaryTag
                    textField="filialSName"
                    valueField="filialId"                           
                    [autoClose]="false"
                    [checkboxes]="true"
                    [filterable]="true"                        
                    [valuePrimitive]="true"
                    (valueChange)="valueChangeFilial($event)"
                    (blur)="blur()"
                    name="filial"
                    [(ngModel)]="filial"                           
                    >
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span>{{ dataItems.length }} выбрано</span>
                    </ng-template>
                </kendo-multiselect>    
            </div>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" text="Уровень подготовки"></kendo-label>
            <div class="input-search">
                <kendo-multiselect
                    [data]="trainingLevels"
                    placeholder="Все"
                    kendoMultiSelectSummaryTag
                    textField="dictTrainingLevelName"
                    valueField="dictTrainingLevelId"                           
                    [autoClose]="false"
                    [checkboxes]="true"
                    [filterable]="true"                        
                    [valuePrimitive]="true"
                    (valueChange)="valueChangeLevel($event)"
                    (blur)="blur()"
                    name="trainingLevel"
                    [(ngModel)]="trainingLevel"                           
                    >
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span>{{ dataItems.length }} выбрано</span>
                    </ng-template>
                </kendo-multiselect>    
            </div>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" text="Форма обучения"></kendo-label>
            <div class="input-search">
                <kendo-multiselect       
                    placeholder="Все"
                    [data]="studyForms"
                    kendoMultiSelectSummaryTag
                    textField="dictStudyFormName"
                    valueField="dictStudyFormId"
                    [autoClose]="false"
                    [checkboxes]="true"
                    [filterable]="true" 
                    [valuePrimitive]="true"
                    (valueChange)="valueChangeForms($event)"
                    (blur)="blur()"
                    name="forms"
                    [(ngModel)]="forms"
                    >
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span>{{ dataItems.length }} выбрано</span>
                    </ng-template>
                </kendo-multiselect>
            </div>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" text="Институт/факультет"></kendo-label>
            <div class="input-search">
                <kendo-multiselect
                    placeholder="Все"
                    [data]="facultyFilter"
                    kendoMultiSelectSummaryTag
                    textField="facultyName"
                    valueField="facultyId"
                    [autoClose]="false"
                    [checkboxes]="true"
                    [valuePrimitive]="true"
                    [filterable]="true"
                    (filterChange)="handleFilter($event)"
                    (valueChange)="valueChangeFaculty($event)"
                    (blur)="blur()"
                    name="faculty"
                    [(ngModel)]="faculty"
                    >
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span>{{ dataItems.length }} выбрано</span>
                    </ng-template>
                </kendo-multiselect>
            </div>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" text="Курс"></kendo-label>
            <div class="input-search">
                <kendo-multiselect
                    [data]="allCourses"
                    placeholder="Все"
                    kendoMultiSelectSummaryTag
                    textField="courseNumber"
                    valueField="courseNumber"                           
                    [autoClose]="false"
                    [checkboxes]="true"
                    [filterable]="true"                        
                    [valuePrimitive]="true"
                    (valueChange)="valueChangeCourse($event)"
                    (blur)="blur()"
                    name="course"
                    [(ngModel)]="courses"                           
                    >
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span>{{ dataItems.length }} выбрано</span>
                    </ng-template>
                </kendo-multiselect>                
            </div>
        </div>
        <div style="text-align: right;">
            <button kendoButton icon="download"
                    class="white"
                    [disabled] = "export"                  
                    (click)="exportForExcel()"
            >
            Экспорт графика
            </button>
        </div>    
    </div>
</div>
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ClassRoomUserAccess } from '../../models/useraccess/classroom/classroomuseraccess.model';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class ClassRoomUserAccessService {
  baseUrl = `${environment.classroomApiEndpoint}${environment.apiPaths.classroom.userAccess}`;

  constructor(private http: HttpClient) { }

  //Get access
  public getAccess(): Observable<ClassRoomUserAccess[]> {
    return this.http.get<ClassRoomUserAccess[]>(this.baseUrl + '/GetAccess')
      .pipe(catchError(this.handleError));
  }

  public getUserAccessLevel() {
    return this.http.get(this.baseUrl + '/GetAccessLevel')
      .pipe(map(res => !!res));
  }

  public getAccessLevel(){
    return this.http.get<any>(this.baseUrl + '/GetAccessLevel')
    .pipe(catchError(this.handleError));
  }

  //Save access
  public saveAccess(useraccess: ClassRoomUserAccess): Observable<ClassRoomUserAccess> {
    return this.http.post<ClassRoomUserAccess>(this.baseUrl + '/SaveAccess/', useraccess)
      .pipe(catchError(this.handleError));
  }

  //Delete access
  public deleteAccess(id: string): Observable<ClassRoomUserAccess> {
    return this.http.delete<ClassRoomUserAccess>(this.baseUrl + '/DeleteAccess/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

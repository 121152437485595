import {Routes} from "@angular/router";
import {OfertaHomeComponent} from "../components/oferta/home/oferta-home.component";
import {AuthGuard} from "../guards/authguard";
import {Type} from "@angular/core";
import {OfertaSettingsComponent} from "../components/oferta/settings/oferta-settings.component";
import {DictRegulationtypeComponent} from "../components/oferta/dicts/dictsignaturetype/dictregulationtype.component";
import {RegulationSettingsComponent} from "../components/oferta/regulationsettings/regulation-settings.component";
import {EditusersignatureComponent} from "../components/oferta/editusersignature/editusersignature.component";
import {OfertaAccessRightComponent} from "../components/useraccess/oferta-access/oferta-access.component";
import {OfertaRoleGuard} from "../guards/oferta-role.guard";
import {OfertaUserAccessGuard} from "../guards/oferta-user-access.guard";
import {ReinforcedModuleNotificationComponent} from "../components/oferta/reinforcedmodulenotification/reinforced-module-notification.component";
import {PersonBlockedNotificationComponent} from "../components/oferta/personblockednotification/person-blocked-notification.component";
import {JoiningPersonToRegulationComponent} from "../components/oferta/joiningpersontoregulation/joining-person-to-regulation.component";
import {JoiningPersonToReinforcedRegulationComponent} from "../components/oferta/joiningpersontoreinforcedregulation/joining-person-to-reinforced-regulation.component";
import {OfertaNotificationGuard} from "../guards/oferta-notification.guard";

export const OfertaRoutes: Routes = [
  {path: 'oferta', component: OfertaHomeComponent, canActivate: [OfertaRoleGuard]},
  {path: 'oferta/dictregulationtype', component: DictRegulationtypeComponent, canActivate: [OfertaRoleGuard]},
  {path: 'oferta/regulationsettings', component: RegulationSettingsComponent, canActivate: [OfertaRoleGuard]},
  {path: 'oferta/ofertaUserAccess', component: OfertaAccessRightComponent, canActivate: [OfertaUserAccessGuard]},
  {path: 'oferta/usersignature/info/:userId', component: EditusersignatureComponent, canActivate: [OfertaRoleGuard]},
  {path: 'oferta/joiningToReinforcedRegulation', component: JoiningPersonToReinforcedRegulationComponent, canActivate: [OfertaNotificationGuard]},
  {path: 'oferta/reinforcedmodulenotification', component: ReinforcedModuleNotificationComponent, canActivate: [OfertaNotificationGuard]},
]

export const OfertaComponents: Type<unknown>[] = [
  OfertaHomeComponent,
  EditusersignatureComponent,
  OfertaSettingsComponent,
  DictRegulationtypeComponent,
  RegulationSettingsComponent,
  OfertaAccessRightComponent,
  ReinforcedModuleNotificationComponent,
  PersonBlockedNotificationComponent,
  JoiningPersonToRegulationComponent,
  JoiningPersonToReinforcedRegulationComponent
];

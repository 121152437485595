import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { DictBenefit } from 'src/app/models/contingent/dictbenefit.model';

@Injectable({
  providedIn: 'root'
})
export class DictbenefitService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictbenefit}`;

  constructor(private http: HttpClient) { }

  //Get all Benefit
  public getAllBenefit(): Observable<DictBenefit[]> {
    return this.http.get<DictBenefit[]>(this.baseUrl);
  }

  //Add Benefit
  addBenefit(benefit: DictBenefit): Observable<DictBenefit> {
    return this.http.post<DictBenefit>(this.baseUrl, benefit)
      .pipe(catchError(this.handleError));
  }

  //Delete Benefit
  deleteBenefit(id: string): Observable<DictBenefit> {
    return this.http.delete<DictBenefit>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit Benefit
  updateBenefit(benefit: DictBenefit): Observable<DictBenefit> {
    return this.http.put<DictBenefit>(this.baseUrl + '/' + benefit.dictBenefitExternalId, benefit)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

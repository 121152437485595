import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CellClickEvent,
  GridComponent,
  GridDataResult,
} from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { DictStateExamCommissionTypeService } from '../../../../services/gia/dictStateExamCommissionType.service';
import { DictStateExamCommissionTypeModel } from '../../../../models/gia/dicts/dictStateExamCommissionType.model';
import { GiaUserAccessService } from '../../../../services/useraccess/gia-user-access.service';
import { AccessRights } from '../../../../models/useraccess/gia/giaUserAccess';

@Component({
  selector: 'app-dict-state-exam-commission-type',
  templateUrl: './dict-state-exam-commission-type.component.html',
  styleUrls: ['./dict-state-exam-commission-type.component.scss'],
})
export class DictStateExamCommissionTypeComponent implements OnInit {
  public dictStateExamCommissionTypeModels: DictStateExamCommissionTypeModel[] =
    [];
  public position: 'top' | 'bottom' | 'both' = 'bottom';
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isLine = false;
  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined;
  }

  public sort: SortDescriptor[] = [
    {
      field: '',
      dir: 'asc',
    },
  ];
  public gridView: any | GridDataResult;

  constructor(
    private dictStateExamCommissionTypeService: DictStateExamCommissionTypeService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: GiaUserAccessService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.getAccessLevel();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadDictPrintTemplateCategory();
  }

  private loadDictPrintTemplateCategory(): void {
    this.gridView = {
      data: orderBy(this.dictStateExamCommissionTypeModels, this.sort),
      total: this.dictStateExamCommissionTypeModels.length,
    };
  }

  //Start Editing
  public editHandler({ sender, rowIndex, dataItem }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if (!this.editable) return;
    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(
    grid: GridComponent,
    rowIndex: any = this.editedRowIndex,
  ): void {
    this.isLine = false;
    grid.closeRow(rowIndex);
    this.formGroup = undefined;
    this.editedRowIndex = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup) {
      this.dictStateExamCommissionTypeService
        .update(this.formGroup.value)
        .subscribe({
          next: () => {
            this.getAllDictPrintTemplateCategories();
            this.notificationService.showSuccess('Сохранено');
          },
          error: () => {
            this.notificationService.showError('Не удалось изменить запись');
          },
        });
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllDictPrintTemplateCategories() {
    this.dictStateExamCommissionTypeService.getList().subscribe({
      next: (response) => {
        this.dictStateExamCommissionTypeModels = response;
        this.loadDictPrintTemplateCategory();
      },
      error: () => {
        this.notificationService.showError(
          'Не удалось получить справочник типов приказа ГЭК',
        );
      },
    });
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        if (response.dict === AccessRights.Forbidden) {
          this.notificationService.showError(
            'У вас нет прав для просмотра данной страницы',
          );
          this.router.navigate(['/profile']).then();
        } else {
          this.editable = response.dict === AccessRights.Write;
          this.getAllDictPrintTemplateCategories();
        }
      },
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { id?: string; name?: string; order?: number }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    name: new FormControl(dataItem.name, Validators.required),
    order: new FormControl(dataItem.order),
  });

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { DictSportType } from 'src/app/models/contingent/dictsporttype.model';

@Injectable({
  providedIn: 'root'
})
export class DictsporttypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictsporttype}`;

  constructor(private http: HttpClient) { }

  //Get all SportType
  public getAllSportType(): Observable<DictSportType[]> {
    return this.http.get<DictSportType[]>(this.baseUrl);
  }

  //Add SportType
  addSportType(dto: DictSportType): Observable<DictSportType> {
    return this.http.post<DictSportType>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete SportType
  deleteSportType(id: string): Observable<DictSportType> {
    return this.http.delete<DictSportType>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit SportType
  updateSportType(dto: DictSportType): Observable<DictSportType> {
    return this.http.put<DictSportType>(this.baseUrl + '/' + dto.dictSportTypeExternalId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}
<blanks-settings></blanks-settings>

<h1>Настройки доступа</h1>

<span class="filter">
  <h3>Поиск по ФИО</h3>
  <kendo-textbox class="w-300" [(ngModel)]="searchFIO"></kendo-textbox>
  <button kendoButton (click)="clearData()">Очистить</button>
  <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">
    Применить
  </button>
</span>

<kendo-grid
  class="technical"
  [kendoGridBinding]="gridData"
  [loading]="loading"
  [sortable]="true"
  (remove)="removeHandler($event)"
  (edit)="editHandler($event)"
  (add)="addHandler($event)"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <ng-template kendoGridToolbarTemplate>
    <button
      kendoGridAddCommand
      themeColor="success"
      *ngIf="!isInEditingMode"
      icon="plus"
    >
      Добавить
    </button>
    <div *ngIf="isInEditingMode">
      <button kendoButton (click)="cancelHandler()">Отмена</button>
      <button
        kendoButton
        themeColor="primary"
        [disabled]="formGroup!.invalid"
        (click)="saveCurrent()"
      >
        Сохранить
      </button>
    </div>
  </ng-template>

  <kendo-grid-column [sticky]="true" title="" [width]="35">
    <ng-template kendoGridCellTemplate>
      <span class="alignCells">
        <button
          kendoGridEditCommand
          themeColor="secondary"
          icon="edit"
          fillMode="flat"
          size="none"
          class="custom-size"
          rounded="full"
        ></button>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [sticky]="true" title="" [width]="35">
    <ng-template kendoGridCellTemplate>
      <span class="alignCells">
        <button
          kendoGridRemoveCommand
          themeColor="secondary"
          icon="delete"
          fillMode="flat"
          size="none"
          class="custom-size"
          rounded="full"
        ></button>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [width]="400"
    [sticky]="true"
    field="personFullName"
    headerClass="gridHeader dictionaries"
    title="ФИО"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [kendoDropDownFilter]="filterSettings"
        [data]="persons"
        textField="fio"
        valueField="externalId"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('personId')"        
      >
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">
        {{ (dataItem.personFullName) }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [width]="200"
    field="userAccessRight"
    headerClass="gridHeader dictionaries"
    title="Уровень доступа"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('userAccessRight')"
      >
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getData(dataItem.userAccessRight) }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [width]="200"
    field="dict"
    headerClass="gridHeader dictionaries"
    title="Справочники"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('dict')"
      >
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getData(dataItem.dict) }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [width]="200"
    field="isAccessSettings"
    headerClass="gridHeader dictionaries"
    title="Настройка доступа"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('isAccessSettings')"
      >
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ dataItem.isAccessSettings ? "Да" : "Нет"}}
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {AcademicGroup, EduGroup, EduGroupNameRequest, EduGroupPrecreatedDto, EduGroupRequest, EduGroupua, EduGroupWithAllStudyPeriodDto, GroupChangeHistory} from '../../models/contingent/edugroup.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {catchError, map} from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class EdugroupService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.edugroup}`;

  constructor(private http: HttpClient) { }

  //Get all edugroup
  public getAlledugroup(editable: number): Observable<EduGroup[]> {
    return this.http.get<EduGroup[]>(this.baseUrl + '/GetAllEduGroup/' + editable);
  }

  //Get all edugroup
  public getAllAcademicGroup(request: HttpParams): Observable<AcademicGroup[]> {
    return this.http.get<AcademicGroup[]>(this.baseUrl + '/GetAllAcademicGroup/', {params: request});
  }

  //Get By Id edugroup
  getByIdedugroup(id: string): Observable<EduGroup> {
    return this.http.get<EduGroup>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getGroupChangeHistory(id: string): Observable<GroupChangeHistory[]> {
    return this.http.get<GroupChangeHistory[]>(this.baseUrl + '/GetGroupChangeHistory/' + id)
      .pipe(catchError(this.handleError));
  }

  getEduGroupByFacultyUA(ids: Guid[]): Observable<EduGroupua[]> {
    return this.http.post<EduGroupua[]>(this.baseUrl + '/GetEduGroupByFaculty/', ids)
      .pipe(
          map( response => {
            if (response) {
              let item = new EduGroupua();
              item.eduGroupExternalId = "";
              item.groupName = 'Все';
              response.unshift(item);
            }
            return response;
          }),
          catchError(this.handleError));
  }

  getEduGroupByFaculty(id: string): Observable<EduGroup[]> {
    return this.http.get<EduGroup[]>(this.baseUrl + '/GetEduGroupByFaculty/' + id)
      .pipe(catchError(this.handleError));
  }

  getEduGroupName(dto: EduGroupNameRequest): Observable<any> {
    return this.http.post(this.baseUrl + '/GetNewGroupName',dto,{responseType: 'text'})
      .pipe(catchError(this.handleError));
  }

  createNewStudyYearGroups(dto: EduGroupRequest[]): Observable<EduGroup[]> {
    return this.http.post<EduGroup[]>(this.baseUrl + '/CreateNewStudyYearGroups',dto)
      .pipe(catchError(this.handleError));
  }

  getNewStudyYearGroups(dto: AcademicGroup[], year: number, planed: boolean): Observable<AcademicGroup[]> {
    return this.http.post<AcademicGroup[]>(this.baseUrl + '/GetStudyYearGroups/' + year + '/' +  planed,dto)
      .pipe(catchError(this.handleError));
  }

  massCreateNewGroupName(dto: EduGroupNameRequest[]): Observable<EduGroup[]> {
    return this.http.post<EduGroup[]>(this.baseUrl + '/MassCreateNewGroupName',dto)
      .pipe(catchError(this.handleError));
  }

  //Add edugroup
  addedugroup(edugroup: EduGroup): Observable<EduGroup> {
    return this.http.post<EduGroup>(this.baseUrl, edugroup)
      .pipe(catchError(this.handleError));
  }

  //Delete edugroup
  deleteedugroup(id: Guid): Observable<EduGroup> {
    return this.http.delete<EduGroup>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit edugroup
  updateEduGroup(eduGroup: EduGroup): Observable<EduGroup> {
    return this.http.put<EduGroup>(this.baseUrl + '/EditEduGroup/', eduGroup)
      .pipe(catchError(this.handleError));
  }

  updateEduGroupElder(eduGroup: any): Observable<EduGroup> {
    return this.http.put<EduGroup>(this.baseUrl + '/EditEduGroupElder/', eduGroup)
      .pipe(catchError(this.handleError));
  }

  archiveEduGroup(id: string): Observable<EduGroup> {
    return this.http.put<EduGroup>(this.baseUrl + '/ArchiveEduGroup/' + id, null)
      .pipe(catchError(this.handleError));
  }

  deArchiveEduGroup(id: string): Observable<EduGroup> {
    return this.http.put<EduGroup>(this.baseUrl + '/DeArchiveEduGroup/' + id, null)
      .pipe(catchError(this.handleError));
  }

  getEduGroupSemesters(id: string): Observable<EduGroupWithAllStudyPeriodDto> {
    return this.http.get<EduGroupWithAllStudyPeriodDto>(this.baseUrl + '/GetEduGroupSemesters/' + id)
      .pipe(catchError(this.handleError));
  }

  getMaxCourseByEduPlanId(id: string): Observable<number> {
    return this.http.get<number>(this.baseUrl + '/GetMaxCourseByEduPlanId/' + id)
      .pipe(catchError(this.handleError));
  }

  getMaxCourseByEduGroupId(id: string): Observable<number> {
    return this.http.get<number>(this.baseUrl + '/GetMaxCourseByEduGroupId/' + id)
      .pipe(catchError(this.handleError));
  }

  getPrecreatedSemestersGroup(request: HttpParams): Observable<EduGroupWithAllStudyPeriodDto> {
    return this.http.get<EduGroupWithAllStudyPeriodDto>(this.baseUrl + '/GetPrecreatedSemestersGroup/', {params: request})
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}

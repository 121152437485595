<card-header *ngIf="!isContingentSection"></card-header>

<h1 class="title">Реестр</h1>

<div [formGroup]="reestrForm">
  <dd class="row-blocks row-reestr-info">
    <span>
      <h3 class="student-info-title-text">Статус</h3>
    </span>
    <p class="student-info-description-text">{{reestr.status}}</p>
  </dd>
  <dd class="row-blocks row-reestr-info">
    <span>
      <h3 class="student-info-title-text">ИД документа</h3>
    </span>
    <p class="student-info-description-text">{{reestr.bankDocId}}</p>
  </dd>
  <dd class="row-blocks row-reestr-info">
    <span>
      <h3 class="student-info-title-text">Дата ответа банка</h3>
    </span>
    <p class="student-info-description-text">{{reestr.dateBunkRequest | date: 'dd.MM.yyyy'}}</p>
  </dd>
  <div class="rows-conteiner-reestr-info">
    <dd class="row-blocks">
      <span>
        <h3 class="student-info-title-text">Номер реестра</h3>
      </span>
      <kendo-formfield>
        <kendo-textbox
          formControlName="reestrNumber"
          [clearButton]="true"
          class="reestr-info-input"
          [formControl]="$any(reestrForm).controls['reestrNumber']"
          [disabled]="isContingentSection">
        ></kendo-textbox>
      </kendo-formfield>
    </dd>
    <dd class="row-blocks">
      <span>
        <h3 class="student-info-title-data-reestr">Дата реестра</h3>
      </span>
      <kendo-formfield>
        <kendo-datepicker
          [formControl]="$any(reestrForm).controls['reestrDate']"
          format="dd.MM.yyyy"
          class="reestr-info-input"
          [disabled]="isContingentSection">
        </kendo-datepicker>
      </kendo-formfield>
    </dd>
  </div>
  <div class="last-row-reestr-info">
    <dd class="row-blocks">
      <span>
        <h3 class="student-info-title-text">Зарплатный проект</h3>
      </span>
      <kendo-formfield>
        <kendo-combobox
          [kendoDropDownFilter]="filterSettings"
          [data]="salaryProjects"
          textField="salaryProjectName"
          valueField="salaryProjectId"
          [valuePrimitive]="true"
          [formControl]="$any(reestrForm).controls['salaryProjectId']"
          formControlName="salaryProjectId"
          [disabled]="!reestr.isFormingReestr || isContingentSection"
          class="salary-project-combobox"
        ></kendo-combobox>
      </kendo-formfield>
    </dd>
    <dd class="row-blocks hide-row-reestr-info" *ngIf="!reestr.isFormingReestr">
      <span>
        <h3 class="student-info-title-text">Файлы выгрузки</h3>
      </span>
      <span>
        <p class="student-info-description-text student-info-description-text_file" (click)="downloadUploadFileXml()">реестр.xml</p>
        <p class="student-info-description-text student-info-description-text_file" (click)="downloadUploadFileXlsx()">реестр.xlsx</p>
      </span>
    </dd>
  </div>
</div>

<button (click)="onCancel()" kendoButton>
  Отмена
</button>

<button
  kendoButton
  themeColor="primary"
  class="k-ml-4"
  (click)="updateReestr()"
  *ngIf="!isContingentSection"
>
  Сохранить
</button>

<button
  *ngIf="!isContingentSection"
  kendoButton
  themeColor="primary"
  class="k-ml-4"
  (click)="formReestr()"
  [disabled]="!reestr.isFormingReestr"
>
  Сформировать выгрузку
</button>

<kendo-grid
  [kendoGridBinding]="gridData"
  class="table-requests"
  [pageSize]="pageSize"
  [pageable]="true"
  [pageable]="pagerSettings"
  [filterable]="true"
  (remove)="deleteCardRequest($event)"
>
  <kendo-grid-column field="rowIndex" title="№ п/п" headerClass="gridHeader" [width]="70" [minResizableWidth]="70" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <p class="cell-table-student-info">{{(rowIndex+1)}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="studentFullName" title="ФИО" headerClass="gridHeader" [width]="579 ">
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="cell-table-student-info">{{dataItem?.studentFullName}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="cardDesign" title="Дизайн карты" headerClass="gridHeader" [width]="480">
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="cell-table-student-info">{{dataItem?.cardDesign}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="dateAdd" filter="date" format="dd.MM.yyyy" title="Дата добавления" headerClass="gridHeader" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="cell-table-student-info">{{dataItem?.dateAdd  | date: 'dd.MM.yyyy'}}</p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="reestr.isFormingReestr" field="edit" title="" headerClass="gridHeader" [width]="60" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        <button
          kendoGridEditCommand
          themeColor="primary"
          icon="edit"
          class="cell-table-btn"
          (click)="toUpdateRequest(dataItem?.studPersonId)">
        </button>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="reestr.isFormingReestr" field="delete" title="" headerClass="gridHeader" [width]="60" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        <button
          kendoGridRemoveCommand
          themeColor="primary"
          icon="delete"
          class="cell-table-btn">
        </button>
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

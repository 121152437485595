<mfc-settings></mfc-settings>
<h1>Дополнительные фильтры</h1>

<div class="buttons">
  <button kendoButton (click)="goBack()">Закрыть</button>
  <button *ngIf="editable" (click)="onSave()" themeColor="primary" kendoButton>Сохранить</button>
</div>

<mfc-constructor-listbox [hiddenData]="hiddenData"
                         [visibleData]="visibleData" [info]="info"
                         (value)="getData($event)"
                         [hiddenToVisible]="toVisible"
                         [visibleToHidden]="toHidden"
                         [editable]="editable"
                         [gridMaxWidth]="560">
</mfc-constructor-listbox>

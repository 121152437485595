<workload-settings></workload-settings>
<div class="container" *ngIf="!isForm">
  <h1>Преподаватели</h1>
  <div class="dropdowns">
    <span class="workloadDropdownContainer">
      <h3>Кафедра</h3>
      <kendo-combobox
              [data]="kafedras"
              [(ngModel)]="kafedrasEdit"
              [valuePrimitive]="false"
              [virtual]="filterVirtualization"
              (valueChange)="onFilterChange()"
              [filterable]="true"
              [kendoDropDownFilter]="filterSettings"
              valueField="name"
              textField="name"
              class="workloadDropdown">
      </kendo-combobox>
    </span>
    <span class="workloadDropdownContainer">
      <h3>Учебный год</h3>
      <kendo-dropdownlist
              [data]="studyYears"
              (valueChange)="onFilterChange()"
              [(ngModel)]="studyYearsEdit"
              [valuePrimitive]="false"
              [virtual]="filterVirtualization"
              valueField="name"
              textField="name"
              class="workloadDropdown">
      </kendo-dropdownlist>
    </span>
  </div>
  <button kendoButton class="button" icon="plus"
          *ngIf='allowEdit'
          [primary]="true"
          (click)="addHandler()"
  >Добавить преподавателя-почасовика</button>
  <button kendoButton class="button k-ml-2" icon="plus"
          *ngIf='allowEdit'
          [primary]="true"
          (click)="addHandler(true)"
  >Добавить преподавателя из другой кафедры</button>
  <kendo-grid [kendoGridBinding]="gridData"
              [filterable]="true"
              [ngClass]="{
                edit: allowEdit
              }"
              (cancel)="onCancel($event)"
              (remove)="onRemove($event)">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <kendo-grid-column title="" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells commandButtons"
              *ngIf='(dataItem.isManualAddition || dataItem.salaryIsManualAddition) && allowEdit'>
          <button (click)="editHandler(dataItem)" kendoButton themeColor="secondary" icon="edit" fillMode="flat"
                  size="none" class="custom-size" rounded="full"></button>
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none"
                  class="custom-size" rounded="full"></button>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="fio" title="Преподаватель">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
            {{ dataItem.fio }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="postName" title="Должность">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.postName }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="salaryType" title="Тип занятости">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown gridField="salaryType" [filter]="filter"  [data]="dictSalaryTypes" textField="name"
                         valueField="name">
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.salaryType }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group headerClass="gridHeader" title="Плановая нагрузка, ч.">
      <kendo-grid-column [filterable]="false" headerClass="gridHeader" [width]="150"  field="planLoad.total" title="Всего">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ dataItem.load?.total ? (dataItem.load.total | number: '1.0-2') : 0 }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [filterable]="false" headerClass="gridHeader" [width]="150"  field="planLoad.study" title="Учебная">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.load?.study }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [filterable]="false" headerClass="gridHeader" [width]="150" field="planLoad.extra" title="Доп.">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.load?.additional }}
          </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column *ngIf="false" [width]="150" headerClass="gridHeader" field="isOrder1C" title="Приказ в 1C">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <filter-dropdown gridField="isOrder1C" [filter]="filter" [data]="options" textField="name"
                         valueField="value">
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.isOrder1C ? 'Да':'Нет' }}
          </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<!--Add form-->
<div class="form" *ngIf="isForm">
  <h1>{{ isNew
    ? (existingPerson ? 'Добавить преподавателя из другой кафедры' : 'Добавить преподавателя-почасовика')
    : (existingPerson ? 'Изменить преподавателя из другой кафедры' : 'Изменить преподавателя-почасовика')
    }}</h1>
  <span class="workloadDropdownContainer" *ngIf='false'>
      <h3>Добавить на кафедру</h3>
      <kendo-combobox
              [formControl]="$any(editForm).get('salary.departmentId')"
              [data]="kafedras"
              [kendoDropDownFilter]="filterSettings"
              [valuePrimitive]="true"
              valueField="id"
              textField="name"
              class="workloadDropdown">
      </kendo-combobox>
      <h3 class="red">*</h3>
  </span>
  <span class="workloadDropdownContainer">
      <h3>Учебный год</h3>
      <kendo-dropdownlist
              [formControl]="$any(editForm).get('studyYear')"
              [data]="studyYears"
              [valuePrimitive]="true"
              valueField="year"
              textField="name"
              class="workloadDropdown">
      </kendo-dropdownlist>
      <h3 class="red">*</h3>
  </span>
  <span class='workloadDropdownContainer' *ngIf='existingPerson'>
    <h3>Преподаватель</h3>
    <kendo-combobox class="workloadDropdown" textField="fio" valueField="id"
                    [kendoDropDownFilter]="filterSettings"
                    [data]="persons"
                    [valuePrimitive]="true"
                    [filterable]="true"
                    [formControl]="$any(editForm).get('id')">
    </kendo-combobox>
    <h3 class="red">*</h3>
  </span>
  <span class="workloadDropdownContainer" *ngIf='!existingPerson'>
    <h3>Фамилия</h3>
    <kendo-textbox [formControl]="$any(editForm).get('lastName')" class="textbox"></kendo-textbox>
    <h3 class="red">*</h3>
  </span>
  <span class="workloadDropdownContainer" *ngIf='!existingPerson'>
    <h3>Имя</h3>
    <kendo-textbox [formControl]="$any(editForm).get('firstName')" class="textbox"></kendo-textbox>
    <h3 class="red">*</h3>
  </span>
  <span class="workloadDropdownContainer" *ngIf='!existingPerson'>
    <h3>Отчество</h3>
    <kendo-textbox [formControl]="$any(editForm).get('middleName')" class="textbox"></kendo-textbox>
  </span>
  <span class="workloadDropdownContainer">
    <h3>Должность</h3>
    <kendo-combobox
            [formControl]="$any(editForm).get('salary.postId')"
            [kendoDropDownFilter]="filterSettings"
            [data]="dictPosts"
            [valuePrimitive]="true"
            [virtual]="filterVirtualization"
            valueField="id"
            textField="name"
            class="textbox">
    </kendo-combobox>
    <h3 class="red" *ngIf='filteredSalaryTypes'>*</h3>
  </span>
  <span class="workloadDropdownContainer">
    <h3>Тип занятости</h3>
    <kendo-combobox class="textbox"
                    valueField="id" textField="name"
                    [formControl]="$any(editForm).get('salary.salaryTypeId')"
                    [kendoDropDownFilter]="filterSettings"
                    [data]="filteredSalaryTypes"
                    [valuePrimitive]="true"
                    [virtual]="filterVirtualization"
    ></kendo-combobox>
    <h3 class="red">*</h3>
  </span>
  <span class="workloadDropdownContainer">
    <h3>Размер ставки</h3>
    <kendo-numerictextbox [formControl]="$any(editForm).get('salary.salary')"  class="textbox"></kendo-numerictextbox>
  </span>
  <div class="addButtons">
    <button (click)="closeEditing()" kendoButton class="button">Отмена</button>
    <button (click)="onSave()" [disabled]="!editForm.valid" kendoButton class="button" themeColor="primary">Сохранить</button>
  </div>
</div>

<div kendoDialogContainer></div>

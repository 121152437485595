import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {DictAuthorRole} from "../../../../../models/publications/dict/authorRole.model";
import {AuthorRolesService} from "../../../../../services/science/publications/authorRoles.service";
import {dictPagerSettings} from "../../../../../models/dicts/pagerSettings.model";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {getData$} from "../../../../../../environments/environment";
import {PublicationsAuthorRolesMethods} from "./publications-author-roles-methods.service";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";

@Component({
  selector: 'publications-author-roles',
  templateUrl: './publications-author-roles.component.html',
  styleUrls: ['./publications-author-roles.component.scss']
})
export class PublicationsAuthorRolesComponent implements OnInit {

  public pagerSettings: PagerSettings = dictPagerSettings;
  public pageSize = 10;
  public loading = false;

  public authorRoles: DictAuthorRole[] = [];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;

  public formGroup: FormGroup | undefined;
  public editable: boolean = this.userAccessService.currentUserAccess$.value.dict === AccessRights.Write;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  constructor(
    private authorRolesService: AuthorRolesService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private authorRolesMethods: PublicationsAuthorRolesMethods,
    private userAccessService: PublicationsUserAccessService
  ) { }

  async ngOnInit() {
    getData$.subscribe(() => this.getAuthorRoles());
  }

  public getAuthorRoles() {
    this.loading = true;
    this.authorRolesService.getAuthorRoles().subscribe({
      next: (response) => {
        this.authorRoles = response;
      },
      error: (error) => {
        this.notificationService.showError(error);
      }
    });
    this.loading = false;
  }

  private closeEditor(rowIndex: any = this.editedRowIndex): void {
    this.grid.closeRow(rowIndex);
    this.isNew = false;
    this.editedRowIndex = undefined;
  }

  public onCansel(): void {
    this.closeEditor();
  }

  public addRow() {
    this.formGroup = this.getFormGroup({
      name: ""
    });

    this.isNew = true;
    this.grid.addRow(this.formGroup);
  }

  private getFormGroup(dataItem: { id?: any; name?: any;}): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name, Validators.required)
    });
  }

  public async onSave() {
    if (this.formGroup) {
      await this.authorRolesMethods.saveAuthorRole(this.isNew, this.formGroup);
    }
    this.closeEditor();
  }

  public async onEdit({sender, rowIndex, dataItem, columnIndex}: CellClickEvent) {
    this.formGroup = this.getFormGroup({
      id: dataItem.id,
      name: dataItem.name
    });

    if (this.isNew || !this.editable || (this.formGroup && this.formGroup.invalid)) {
      return;

    }

    this.closeEditor();
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public onRemove({dataItem}: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}"?`);
    this.authorRolesMethods.removeAuthorRole(dialog.result, dataItem);
  }
}

<div class="container">
  <div class="centered">
    <p>{{userSignaturesInfo.banText}}</p>
    <div class="userSignatureItem userRegulation">
      <p>{{userSignaturesInfo.regulationName}}</p>
      <button kendoButton icon="download"
              (click)="getRegulationFile(userSignaturesInfo.regulationFileDto)" size="small">
        Скачать
      </button>
    </div>
    <div class="userSignatureItem userRegulation">
      <p>{{userSignaturesInfo.statementText}}</p>
      <button kendoButton icon="download"
              (click)="getRegulationFile(userSignaturesInfo.statementFileDto)" size="small">
        Скачать
      </button>
    </div>
    <div class="buttonExit">
      <button kendoButton size="small" class="close-button" (click)="logOut()">Закрыть</button>
    </div>
  </div>
</div>

import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {
  AddEvent, CellClickEvent,
  EditEvent, GridComponent,
  GridDataResult,
  RemoveEvent
} from '@progress/kendo-angular-grid';
import {SpravkaTypes} from "../../../models/contingent/spravkatypes.model";
import {DictspravkatypeService} from "../../../services/contingent/dictspravkatype.service";
import {StudentmaininfoComponent} from "../studentmaininfo/studentmaininfo.component";
import {Guid} from "guid-typescript";
import {SpravkaService} from "../../../services/contingent/spravka.service";
import {Spravka, SpravkaPerson, SpravkaStudyPeriod} from "../../../models/contingent/spravka.model";
import {ActivatedRoute} from "@angular/router";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {Observable} from "rxjs";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import { PersonService } from 'src/app/services/contingent/person.service';
import { Person } from 'src/app/models/contingent/person.model';
import {CasenameService} from "../../../services/contingent/casename.service";
import {CaseName} from "../../../models/contingent/casename.model";
import {saveAs} from "@progress/kendo-file-saver";
import { StudentService } from 'src/app/services/contingent/student.service';
import { TokenStorageService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Role } from 'src/app/models/useraccess/role';
import {LocalSignatoryService} from "../../../services/contingent/local-signatory.service";
import {LocalSignatoryCertificate} from "../../../models/contingent/localsignatory.model";
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {Align} from "@progress/kendo-angular-popup";
import {DatePipe} from "@angular/common";
import {MilitaryCommissariatService} from "../../../services/contingent/military-commissariat.service";
import {MilitaryCommissariat} from "../../../models/contingent/militarycommissariat.model";
import {CertificateTypeEnum} from "../../../models/contingent/enums/certificate-type.enum";
import { DictCodeArticle } from '../../../models/contingent/dictcodearticle.model';
import { DictCodeArticleService } from '../../../services/contingent/dictcodearticle.service';
import {OrdersstudyperiodService} from "../../../services/contingent/ordersstudyperiod.service";
import {OrdersStudyPeriod} from "../../../models/contingent/ordersstudyperiod.model";
import {ContingentOrganizationService} from "../../../services/contingent/contingentOrganization.service";
import {ContingentOrganization} from "../../../models/contingent/organization.model";
import {DictcertificatepurposeService} from "../../../services/contingent/dictcertificatepurpose.service";
import {DictCertificatePurpose} from "../../../models/contingent/dictcertificatepurpose.model";

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  element: any;

  constructor(private dictCertificateTypeService: DictspravkatypeService,
              public studentmaininfoComponent:StudentmaininfoComponent,
              private spravkaService:SpravkaService,
              private activateRoute: ActivatedRoute,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private organizationService: ContingentOrganizationService,
              private personsService: PersonService,
              private casenameService: CasenameService,
              private studService:StudentService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService,
              private localSignatoryService: LocalSignatoryService,
              private militaryCommissariatService: MilitaryCommissariatService,
              private dictCodeArticleService: DictCodeArticleService,
              private orderStudyPeriodService:OrdersstudyperiodService,
              private purposeService: DictcertificatepurposeService,
  ) {
    if(activateRoute.snapshot.parent!==null){
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
    }
    this.admin()
  }

  ngOnInit(): void {
    this.getAllSpravkaTypes()
    this.getAllSpravksStudyPeriod()
    this.getAllOrderStudyPeriod()
    this.getAllSpravka()
    this.getOrganization()
    this.getAllMilitaryCommissariat()
    this.getAllPersons()
    this.getStudents()
    this.getLocalSignatories()
    this.getAllDictCodeArticles()
    this.getAllCertificatePurpose()
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public loading = false;
  public position: "top" | "bottom" | "both" = "both";
  public editMode: boolean = false;
  public purposes: DictCertificatePurpose[] = [];
  public isNew:boolean = false;
  public isGridEdit :boolean = false;
  public isNewCaseName:boolean = false;
  public isCall:boolean = false;
  public isMilitary:boolean = false;
  public addSignerHide:boolean = false;
  public saveDisable:boolean = true;
  public signerEditOpen:boolean[]=[];
  public numberFormatted?: string;
  public studentId?: Guid;
  public persons: Person[] = [];
  public localSignatories: LocalSignatoryCertificate[] = []
  public organizations: ContingentOrganization[] = [];
  public view: Observable<GridDataResult> | undefined;
  public spravkaTypes: SpravkaTypes[] = [];
  public spravkas: Spravka[] = [];
  public militaryCommissariats: MilitaryCommissariat[] = [];
  public spravkaStudyPeriod: SpravkaStudyPeriod = {}
  public studyForm?: any;
  public spravka: Spravka = {
    spravkaId: 0,
    number:0,
    date: new Date
  }
  public caseName:CaseName={}

  public spravkaPersons: SpravkaPerson[]=[];
  public personPosts: string[]=[];
  public dictCodeArticles: DictCodeArticle[] = [];
  public sortedArticles: DictCodeArticle[] = [];
  public studentMainInfo: any
  public fio: string = ""
  public newDate: Date = new Date()
  public certificateType: any = CertificateTypeEnum;
  public orderStudyPeriod?: OrdersStudyPeriod = {}

  public addHandler({ }: AddEvent): void {
    this.availableEdit()
    this.getNewNumber()
    this.form.reset();
    this.formCaseName.reset(this.caseName);
    this.newDate = DateFromUTCAsLocal(new Date())
    this.editMode = true;
    this.isNew =true;
    this.spravkaPersons=[]
    this.personPosts=[]
    this.loadData()
  }

  public commissariat?: string
  public editable?: boolean
  public adminedit?: boolean
  public disabled?: boolean
  private lastRowIndex: number | undefined;

  @ViewChild('certificateGrid') private grid!: GridComponent;

  public getStudents(): void {
    this.studService.getCertificateStudent(this.studentId!)
      .subscribe(
        response => {
          if(response!=null){
            this.editable = response.editable
            this.commissariat = response.commissariat
            //this.admin()
            this.getStudentCaseName(response.studPersonId!)
          }
        }
      );
  }

  public getAllDictCodeArticles() {
    this.dictCodeArticleService.getAllDictCodeArticles()
      .subscribe(
        response => {
          this.dictCodeArticles = response;
        }
      );
  }

  private getLocalSignatories() {
    this.localSignatoryService.getCertificateSigners(this.studentId!)
      .subscribe({
        next:(response) => {
          this.localSignatories = response;
        }, error:() => {
          this.notificationService.showError("Не удалось получить список локальных подписантов");
        }});
  }

  public getAllMilitaryCommissariat() {
    this.militaryCommissariatService.getAllMilitaryCommissariat()
      .subscribe(
        response => {
          this.militaryCommissariats = response;
        }
      );
  }

  private admin(){
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null) user = this.jwtHelper.decodeToken(token);
    if(user.role.includes(Role.Admin)) {
      this.adminedit=true
    }
  }

  public transitionHandler({dataItem}: EditEvent): void {
    this.loadData()
    this.spravkaTypeValueChange(dataItem.dictSpravkaTypeId)
    this.saveDisable=true
    this.newDate = DateFromUTCAsLocal(dataItem.date)
    dataItem.date = DateFromUTCAsLocal(dataItem.date)
    if(dataItem.requestDate!=null)
      dataItem.requestDate = DateFromUTCAsLocal(dataItem.requestDate)
    if(dataItem.employerDateBegin!=null)
      dataItem.employerDateBegin = DateFromUTCAsLocal(dataItem.employerDateBegin)
    if(dataItem.employerDateEnd!=null)
      dataItem.employerDateEnd = DateFromUTCAsLocal(dataItem.employerDateEnd)
    if(dataItem.employerDateList!=null)
    {
      let array: any[] = []
      dataItem.employerDateList.forEach((date:any) =>{
        array.push(DateFromUTCAsLocal(date))
      })
      dataItem.employerDateList = array
    }
    this.form.reset(dataItem)
    if(this.form.value.employerDateList != null)
      this.onChange(this.form.value.employerDateList)

    this.formCaseName.reset(this.caseName);
    if(dataItem.isIssued)this.disableEdit()
    if(this.adminedit) this.form.controls['isIssued'].enable()
    this.numberFormatted=dataItem.numberFormatted
    this.spravkaPersons=dataItem.spravkaPersons
    this.personPosts=dataItem.approvePost
    for (let i=0; i<this.personPosts.length; i++){
      this.closeSigner(i)
    }
     if(dataItem.spravkaId != 0)
      this.sortedArticles = this.dictCodeArticles.filter(x => x.reason == this.dictCodeArticles.find(f => f.externalId === dataItem.dictCodeArticleId)?.reason);
    //this.signerEditOpen
    this.editMode = true;
    this.isNew = false;
  }

  public onCellClick({sender, rowIndex, columnIndex, dataItem}: CellClickEvent): void {
    if(!this.editable) {
      return;
    }
    // Подставляем значения в formGroup
    for (const [key] of Object.entries(this.form.value)) {
      this.form.controls[key].setValue(dataItem[key])
    }
    const formGroup = new FormGroup({
      isIssued: new FormControl(dataItem.isIssued)
    })
    this.isGridEdit = true;
    this.lastRowIndex = rowIndex;
    sender.editRow(rowIndex, formGroup, { columnIndex });
  }

  public onCancel() {
    this.isGridEdit = false;
    this.grid.closeRow(this.lastRowIndex);
  }

  public disableEdit(){
    this.disabled=true
    this.form.controls['dictSpravkaTypeId'].disable()
    this.form.controls['purpose'].disable()
    this.form.controls['additionalComment'].disable()
    this.form.controls['employerName'].disable()
    this.form.controls['employerFIO'].disable()
    this.form.controls['dictCertificatePurposeId'].disable()
    //this.form.controls['vacationReason'].disable()
    //this.form.controls['article'].disable()
    this.form.controls['dictCodeArticleId'].disable()
    this.form.controls['employerDateBegin'].disable()
    this.form.controls['employerDateEnd'].disable()
    this.form.controls['employerDate'].disable()
    this.form.controls['employerDateList'].disable()
    this.form.controls['requestNumber'].disable()
    this.form.controls['requestDate'].disable()
    this.form.controls['firstTime'].disable()
    this.form.controls['sendComment'].disable()
    if(!this.adminedit)this.form.controls['isIssued'].disable()
    this.form.controls['instancesNumber'].disable()
    this.formCaseName.controls['rLastName'].disable()
    this.formCaseName.controls['rFirstName'].disable()
    this.formCaseName.controls['rMiddleName'].disable()
    this.formCaseName.controls['dLastName'].disable()
    this.formCaseName.controls['dFirstName'].disable()
    this.formCaseName.controls['dMiddleName'].disable()
  }

  public availableEdit(){
    this.disabled=false
    this.form.controls['dictSpravkaTypeId'].enable()
    this.form.controls['purpose'].enable()
    this.form.controls['additionalComment'].enable()
    this.form.controls['employerName'].enable()
    this.form.controls['employerFIO'].enable()
    this.form.controls['dictCertificatePurposeId'].enable()
    //this.form.controls['vacationReason'].enable()
    //this.form.controls['article'].enable()
    this.form.controls['dictCodeArticleId'].enable()
    this.form.controls['employerDateBegin'].enable()
    this.form.controls['employerDateEnd'].enable()
    this.form.controls['employerDate'].enable()
    this.form.controls['employerDateList'].enable()
    this.form.controls['requestNumber'].enable()
    this.form.controls['requestDate'].enable()
    this.form.controls['firstTime'].enable()
    this.form.controls['sendComment'].enable()
    this.form.controls['isIssued'].enable()
    this.form.controls['instancesNumber'].enable()
    this.formCaseName.controls['rLastName'].enable()
    this.formCaseName.controls['rFirstName'].enable()
    this.formCaseName.controls['rMiddleName'].enable()
    this.formCaseName.controls['dLastName'].enable()
    this.formCaseName.controls['dFirstName'].enable()
    this.formCaseName.controls['dMiddleName'].enable()
  }

  public toggleEditForm(): void{
    if(this.adminedit&&!this.isNew) this.availableEdit()
  }

  public loadData(){
    this.studentMainInfo=this.studentmaininfoComponent
    this.fio =   (this.studentmaininfoComponent.contingent.studPersons.lastName == null ? "" : this.studentmaininfoComponent.contingent.studPersons.lastName) + " " +
      (this.studentmaininfoComponent.contingent.studPersons.firstName == null ? "" : this.studentmaininfoComponent.contingent.studPersons.firstName) + " " +
      (this.studentmaininfoComponent.contingent.studPersons.middleName == null ? "" : this.studentmaininfoComponent.contingent.studPersons.middleName)+ " " +
      (this.studentmaininfoComponent.contingent.studPersons.maidenName == null ? "" : this.studentmaininfoComponent.contingent.studPersons.maidenName)
    this.getStudyForm()
  }

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.onPrint=false
    this.save()
  }

  public onPrint:Boolean = false;

  public onSaveAndPrint(e: PointerEvent): void {
    e.preventDefault();
    this.onPrint=true
    this.save()
  }

  public closeIdentification(): void {
    this.editMode = false;
    this.form.reset();
    this.stringEmployerDateList = ""
    this.availableEdit()
  }

  public save() {
    this.form.value.spravkaPersons=this.spravkaPersons
    this.form.value.approvePost=this.personPosts
    if(this.form.value.employerDateList && this.form.value.employerDateList.length === 0)
      this.form.value.employerDateList = undefined

    this.saveCaseName()

    if(this.isNew)
    {
      this.form.value.date = new Date()
      this.form.value.number = this.spravkas.length+1
      this.form.value.studentId = this.studentId
      this.timeZoneFix();
      this.spravkaService.addCertificate(this.form?.value)
        .subscribe({
          next: (response) => {
            this.notificationService.showSuccess("Справка была успешно добавлена!");
            this.updateGrid();
            if(this.onPrint) this.downloadFile(response)
            this.closeEditForm();
          },
          error: (error) => {
            this.getNewNumber()
            this.notificationService.showError("Не удалось добавить справку. " + error.error);
          }
        });
    }
    else {
      this.timeZoneFix();
      this.spravkaService.updateCertificate(this.form?.value)
        .subscribe({
          next: (response) => {
            this.notificationService.showSuccess("Справка была успешно сохранено!");
            this.updateGrid();
            if(this.grid) this.onCancel();
            if (this.onPrint) this.downloadFile(response)
            this.closeEditForm();
          },
          error: (error) => {
            this.notificationService.showError('Не удалось изменить справку. ' + error.error);
          }
        })
    }
  }


  public closeEditForm(){
    this.availableEdit()
    this.editMode = false;
    this.isNew = false;
    this.onPrint = false
    this.stringEmployerDateList = ""
    this.form.reset();
    this.getAllSpravka();
  }

  public saveCaseName() {
    if(this.isNewCaseName)
    {
      this.formCaseName.value.studPersonId = this.studentmaininfoComponent.studPersonExternalId
      this.casenameService.addcasename(this.formCaseName?.value).subscribe({next:() => {
          this.getStudentCaseName(this.studentmaininfoComponent.studPersonExternalId)
        }});
    }
    else {
      this.casenameService.updatecasename(this.formCaseName?.value).subscribe({next:() => {
          this.getStudentCaseName(this.studentmaininfoComponent.studPersonExternalId)
        }})
    }
    this.formCaseName.reset()
  }

  public updateGrid() {
    this.getAllSpravka();
  }

  public nameperson(id: any): string  {
    return this.persons.find((x) => x.personExternalId === id)!.fio;
  }

  public openOrganization(): void {
    window.open(`/contingent/organization`);
  }

  public spravkaTypeValueChange(id:any): void {
    this.spravkaPersons = []
    this.signerEditOpen = []
    this.personPosts = []
    const item = this.form.value
    this.addSignerHide = false
    this.saveDisable = false
    this.spravka.dictCertificatePurposeId = undefined

    switch(id.toString()){
      case this.certificateType.call: {
        this.isCall=true
        this.isMilitary = false
        this.addSignerHide = true
        this.spravka.purpose=" "
        this.spravkaPersons.push({personId:undefined})
        this.signerEditOpen.push(true)
        this.personPosts.push("")
        this.spravkaPersons.push({personId:undefined})
        this.signerEditOpen.push(true)
        this.personPosts.push("")
        this.spravka.vacationReason = undefined
        //this.spravka.article="173"
        break;
      }
      case this.certificateType.military: {
        this.isCall=false
        this.isMilitary = true
        this.addSignerHide = true
        this.spravka.purpose = this.commissariat
        this.spravkaPersons.push({personId:undefined})
        this.signerEditOpen.push(true)
        this.personPosts.push("")
        this.spravkaPersons.push({personId:undefined})
        this.signerEditOpen.push(true)
        this.personPosts.push("")
        this.spravka.vacationReason = undefined
        this.openSigner(0)
        this.openSigner(1)
        break;
      }
      case this.certificateType.study: {
        this.isCall = false
        this.isMilitary = false
        this.spravkaPersons.push({personId:undefined})
        this.signerEditOpen.push(false)
        this.personPosts.push("")
        this.spravka.approvePost = []
        this.spravka.spravkaPersons = []
        this.spravka.vacationReason = ""
        this.spravka.firstTime=false
        this.form.reset(this.spravka)
        break;
      }
    }

    //this.saveDisable=true
    this.spravka.employerFIO = ""
    this.spravka.vacationReason = ""
    this.spravka.employerName = ""
    this.spravka.spravkaId=item.spravkaId
    this.spravka.spravkaExternalId=item.spravkaExternalId
    this.spravka.studentId=item.studentId
    this.spravka.number=item.number

    this.spravka.dictSpravkaTypeId=id
    this.spravka.numberFormatted=item.numberFormatted

    this.form.reset(this.spravka)
    this.spravka.purpose=undefined
    this.spravka.dictSpravkaTypeId=undefined
    this.spravka.dictCodeArticleId=undefined
    //this.spravka.article = undefined
  }

  public personValueChange(i:number,id:any) {
    this.saveDisable=false
    this.form.reset(this.form.value)
    this.personPosts[i]=this.localSignatories.find((x) => x.personExternalId === id)!.postName!
    this.checkStudentValid()
  }

  public checkStudentValid(){
    if(this.isCall&&this.spravkaPersons[0].personId!=undefined&&this.spravkaPersons[1].personId!=undefined)this.saveDisable=true
    else if(this.spravkaPersons[0].personId&&(this.spravkaPersons[1]?this.spravkaPersons[1].personId!=undefined:true)&&(this.spravkaPersons[2]?this.spravkaPersons[2].personId!=undefined:true))this.saveDisable=true
  }

  public onChangeFindSigner(i:number,name:any){
    const user = this.persons.find((person) => person.fio === name);

    if(user) {
      if(this.localSignatories.find((x) => x.personExternalId === user.personExternalId) === undefined){
        this.localSignatories.push( {
          personExternalId : user.personExternalId,
          fio: user.fio,
          postName: user.post
        })
      }
      this.spravkaPersons[i].personId = user.personExternalId
      this.personValueChange(i, user.personExternalId)
    }
  }

  public getStudyForm() {
    if(!this.studyForm) {
      this.studyForm = this.studentmaininfoComponent.dictStudyForms.find((x) => x.dictStudyFormExternalId === this.studentMainInfo?.educationPlan?.dictStudyFormId)
      //if(this.studyForm.type !== this.studyFormType.fullTime)
        this.spravkaTypes = this.spravkaTypes.filter(el => el.dictSpravkaTypeExternalId !== this.certificateType.military)
    }
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: " + dataItem.numberFormatted + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){

          this.spravkaService.deleteCertificate(dataItem.spravkaExternalId, this.studentId)
            .subscribe({
              next: () => {
                this.getAllSpravka();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
            this.notificationService.showError(error.error);
          }});
        }
        else{

        }
      }
    });
  }

  public selectionChange(value: any): void {
    this.sortedArticles = this.dictCodeArticles.filter(x => x.reason == value.reason)
  }

  public downloadFile(values: any)
  {
    this.spravkaService.downloadFile(`${values.spravkaExternalId}/${values.studentId}`).subscribe(
      response => {
        let blob:any = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        let saveName = ""
        switch (values.dictSpravkaTypeId){
          case this.certificateType.study:
            saveName="Справка об обучении.docx"
            break;
          case this.certificateType.call:
            saveName="Справка-вызов.docx"
            break;
          default:
            saveName = "Справка " + this.fio
            break;
        }
        saveAs(blob, saveName);
        this.closeEditForm();
      }
    );
  }

  public addSigner():void{
    //this.spravkaPersons.length+=1;
    this.saveDisable=false
    this.spravkaPersons.push({personId:undefined})
    this.signerEditOpen.push(false)
    this.personPosts.push("")
    //this.form.value.spravkaPersons=this.personPosts
    if(this.spravkaPersons.length==3)this.addSignerHide=true
  }

  public getNewNumber() {
    this.spravkaService.getNewNumber(false).subscribe(
      (response:any) => {
        if(response!=="")this.numberFormatted= response;
      }
    );
  }

  public removeSigner(i:number):void{
    this.addSignerHide=false
    if (i > -1) {
      this.spravkaPersons.splice(i, 1);
      this.signerEditOpen.splice(i, 1);
      this.personPosts.splice(i, 1);
    }
    this.checkStudentValid()
  }

  public closeSigner(i:number):void {
    this.signerEditOpen[i]=true
  }

  public openSigner(i:number):void {
    if(this.disabled)return
    this.signerEditOpen[i]=false;

    if(this.localSignatories.find((person) => person.personExternalId === this.spravkaPersons[i].personId) === undefined) {
      const user = this.persons.find((x) => x.personExternalId === this.spravkaPersons[i].personId)
        if(user)this.localSignatories.push({
          personExternalId: user.personExternalId,
          fio: user.fio,
          postName: user.post
        })

    }
  }

  public nametype(id: Guid): string {
    let type = this.spravkaTypes.find((x) => x.dictSpravkaTypeExternalId === id);
    if(type?.typeName!=null) return type.typeName
    else return ""
  }

  public getAllSpravkaTypes() {
    this.dictCertificateTypeService.getAllspravkatypes()
      .subscribe(
        response => {
          this.spravkaTypes = response;
        }
      );
  }
  public getStudentCaseName(id: Guid) {
    this.casenameService.getStudentCaseName(id)
      .subscribe(
        response => {
          if(response.caseNameId!=0)
          {
            this.caseName = response;
            this.isNewCaseName = false
          }
          else
          {
            this.caseName = response
            this.isNewCaseName = true
          }
          this.formCaseName.reset(this.caseName)
        }
      );
  }
  public getAllSpravka() {
    this.loading = true;
    this.spravkaService.getStudentSpravka(this.studentId!)
      .subscribe(
        response => {
          this.spravkas = response.filter((x)=>x.dictSpravkaTypeId !== this.certificateType.military);
          this.loading = false;
        }
      );
  }

  public getAllOrderStudyPeriod() {
    this.orderStudyPeriodService.getStudentStudyPeriod(this.studentId!)
      .subscribe(
        response => {
          this.orderStudyPeriod = response;
          if (this.orderStudyPeriod!=undefined){
            if (this.orderStudyPeriod.dateStart)this.orderStudyPeriod.dateStart = DateFromUTCAsLocal(this.orderStudyPeriod.dateStart);
            if (this.orderStudyPeriod.dateFinish)this.orderStudyPeriod.dateFinish = DateFromUTCAsLocal(this.orderStudyPeriod.dateFinish);
          }
        }
      );
  }

  public getAllCertificatePurpose() {
    this.purposeService.getAll()
      .subscribe(
        response => {
          this.purposes = response;
        }
      );
  }

  public isSpravkaCourse=false;

  public getAllSpravksStudyPeriod(){
    this.spravkaService.getStudyPeriod(this.studentId!)
      .subscribe(
        response => {
          this.spravkaStudyPeriod = response;
          this.isSpravkaCourse = this.spravkaStudyPeriod.orderCourse == undefined;
        }
      );
  }

  public getAllPersons() {
    this.personsService.getAllperson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  public getOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.organizations = response;
        }
      );
  }

  public popupAlign: Align = { horizontal: "right", vertical: "top" };
  public anchorAlign: Align = { horizontal: "right", vertical: "bottom" };
  public show = false;
  public stringEmployerDateList: string|undefined = "";
  public array: any[] = [];

  @ViewChild("anchor", { read: ElementRef }) public anchor!: ElementRef;
  @ViewChild("popup", { read: ElementRef }) public popup!: ElementRef;

  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.toggle(false);
    }
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if (event.target && !this.contains(event.target)) {
      this.toggle(false);
    }
  }

  public toggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
  }

  private contains(target: EventTarget): boolean {
    if(!this.anchor) return false;
    return (
      this.anchor.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  public onChange(value: Date[]) {
    if(value.length == 0){
      this.form.value.employerDateList = null
      this.stringEmployerDateList = ""
    }
    else {
      let arrayDate: any[] = []
      value.forEach((date: any) => {
        arrayDate.push(this.datepipe.transform(date, 'dd.MM.yyyy'))
      })
      this.stringEmployerDateList = arrayDate.join(", ")
    }
    this.form.value.employerDate = this.stringEmployerDateList
  }

  private datepipe: DatePipe = new DatePipe('ru-Ru');

  public timeZoneFix() {
    if (this.form.value.date != undefined)
      this.form.value.date = TimeZoneFix(this.form.value.date);
    if (this.form.value.requestDate != undefined)
      this.form.value.requestDate = TimeZoneFix(this.form.value.requestDate);
    if (this.form.value.employerDateBegin != undefined)
      this.form.value.employerDateBegin = TimeZoneFix(this.form.value.employerDateBegin);
    if (this.form.value.employerDateEnd != undefined)
      this.form.value.employerDateEnd = TimeZoneFix(this.form.value.employerDateEnd);
    if(this.form.value.employerDateList != undefined){
      let array: any[] = []
      this.form.value.employerDateList.forEach((date:any) =>{
        array.push(TimeZoneFix(date))
      })
      this.form.value.employerDateList = array
      if(this.form.value.employerDateList.length === 0) this.form.value.employerDateList.length = undefined
    }
  }

  public form: UntypedFormGroup = new UntypedFormGroup({
    spravkaId: new UntypedFormControl(),
    spravkaExternalId: new UntypedFormControl(),
    dictSpravkaTypeId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    departmentId: new UntypedFormControl(),
    dictStudyLevelId: new UntypedFormControl(),
    number: new UntypedFormControl(),
    numberFormatted: new UntypedFormControl(),
    date: new UntypedFormControl(),
    requestNumber: new UntypedFormControl(),
    requestDate: new UntypedFormControl(),
    sendComment: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    personId: new UntypedFormControl(),
    employerDateBegin: new UntypedFormControl(),
    employerDateEnd: new UntypedFormControl(),
    employerDate: new UntypedFormControl(),
    employerDateList: new UntypedFormControl(),
    employerName: new UntypedFormControl(),
    purpose: new UntypedFormControl(),
    dictCertificatePurposeId: new UntypedFormControl(),
    employerFIO: new UntypedFormControl(),
    spravkaPersons: new UntypedFormControl(),
    approvePost: new UntypedFormControl([]),
    instancesNumber: new UntypedFormControl(),
    additionalComment: new UntypedFormControl(),
    vacationReason: new UntypedFormControl(),
    performerNumber: new UntypedFormControl(),
    //article:new UntypedFormControl(),
    dictCodeArticleId: new UntypedFormControl(),
    isIssued: new UntypedFormControl(),
    firstTime: new UntypedFormControl(),
  });

  public formCaseName: UntypedFormGroup = new UntypedFormGroup({
    caseNameId: new UntypedFormControl(),
    caseNameExternalId: new UntypedFormControl(),
    studPersonId:new UntypedFormControl(),
    rLastName: new UntypedFormControl(),
    rFirstName: new UntypedFormControl(),
    rMiddleName:new UntypedFormControl(),
    dLastName:new UntypedFormControl(),
    dFirstName:new UntypedFormControl(),
    dMiddleName:new UntypedFormControl(),
  });
}

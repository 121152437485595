<classroom-header></classroom-header>
<div class="grid-layout-container" style="margin-top: 20px;"> 
    <div class="main-title"> Назначения аудиторий </div>

    <kendo-grid [kendoGridBinding]="assignsAuditorium" (remove)="removeHandler($event)"
                [pageable]="{
                    buttonCount: buttonCount,
                    info: info,
                    type: type,
                    pageSizes: pageSizes,
                    previousNext: previousNext,
                    position: positionPager
                }"
                [pageSize]="10"
                [sortable]="true"
                (cellClick)="editHandler($event)"
                (add)="addHandler($event)"
                (dataStateChange)="onStateChange($event)">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="accessLevel">
        <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">
           Добавить
        </button>
        <div *ngIf="isInEditingMode">
          <button kendoButton
                  (click)="cancelHandler()">
            Отмена
          </button>
          <button kendoButton
                  themeColor="primary"
                  [disabled]="formGroup!.invalid&&formGroup?.get('classRoomPurposeName')!=undefined"
                  (click)="saveCurrent()"
                  >
            Сохранить
          </button>
        </div>
      </ng-template>

      <kendo-grid-column field="order"
                       title="№ п/п"
                       [width]="100"
                       headerClass="gridHeader dictionaries">
    
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells centerAlignment" *ngIf="rowIndex+1 > 0">
              {{(rowIndex+1)}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="classRoomPurposeName"
            title="Назначения аудиторий"
            [width]="300"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              {{ dataItem.classRoomPurposeName }}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="classRoomPurposeNumber"
            title="Порядковый номер отображения назначений аудиторий"
            [width]="150"
            headerClass="gridHeader dictionaries">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
            <kendo-textbox class="border"
              [formControl]="formGroup.get('classRoomPurposeNumber')"
            >
            </kendo-textbox>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells centerAlignment">
                {{ dataItem.classRoomPurposeNumber }}
              </span>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-column headerClass="gridHeader dictionaries" title="" [width]="70" *ngIf="accessLevel">
        <ng-template kendoGridCellTemplate>
          <span class="alignCells centerAlignment">
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
              rounded="full" style="text-overflow: unset;">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>

</div>

<div kendoDialogContainer></div>
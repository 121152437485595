import {Injectable} from "@angular/core";
import {catchError, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictScienceDegree} from "../../../models/publications/dict/scienceDegree.model";
import {handleError} from "../../../helpers/publications/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class ScienceDegreesService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/AcademicDegree/`;

  constructor(private http: HttpClient) {
  }

  public getScienceDegrees(): Observable<DictScienceDegree[]> {
    return this.http.get<DictScienceDegree[]>(this.baseUrl);
  }

  public addScienceDegree(scienceDegree: DictScienceDegree): Observable<DictScienceDegree> {
    return this.http.post<DictScienceDegree>(this.baseUrl, scienceDegree)
      .pipe(catchError(handleError));
  }

  public removeScienceDegree(id: string): Observable<DictScienceDegree> {
    return this.http.delete<DictScienceDegree>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }

  public updateScienceDegree(scienceDegree: DictScienceDegree): Observable<DictScienceDegree> {
    return this.http.put<DictScienceDegree>(this.baseUrl, scienceDegree)
      .pipe(catchError(handleError));
  }
}

<h1>Просмотр от другого лица</h1>
<button (click)="onCancel()" kendoButton>
    Отмена
</button>
<kendo-tilelayout style="padding: 16px 16px 16px 0">
    <kendo-tilelayout-item>
        <kendo-tilelayout-item-body>
            <h2>Поиск работника</h2>
            <div class="item">
                <h3>ФИО</h3>
                <kendo-combobox
                  [kendoDropDownFilter]="filterSettings"
                  [data]="persons"
                  textField="fullName"
                  valueField="personExternalId"
                  style="width: 40%"
                  [formControl]="$any(formGroup).controls['personId']"
                  [valuePrimitive]="true"
                >
                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                        <span class="comboboxItem">
                            <span class="comboChild fullName">{{ dataItem.fullName + " "}}</span>
                            <span class="comboChild post">{{findPost(dataItem.personExternalId)}}</span>
                        </span>
                    </ng-template>
                </kendo-combobox>
                <button kendoButton [disabled]="!formGroup.valid" (click)="switchPerson()">Перейти к просмотру</button>
            </div>
        </kendo-tilelayout-item-body>
    </kendo-tilelayout-item>
</kendo-tilelayout>

<publications-settings></publications-settings>
<h1>Отчеты</h1>

<div class="wrapper">
  <h4>1. Отчет в ЕГИСУ НИОКТР</h4>
  <div class="content">
    <div class="row">
      <span class="label">Год</span>
      <kendo-dropdownlist class="list"
                          [data]="years"
                          [(ngModel)]="usisYear">
      </kendo-dropdownlist>
    </div>
    <button kendoButton themeColor="primary" (click)="exportUsis()">
      Сформировать
    </button>
  </div>
</div>

<div>
  <h4>2. Полная выгрузка</h4>
  <div class="content">
    <div class="row">
      <span class="label">Год</span>
      <kendo-dropdownlist class="list"
                          [data]="years"
                          [(ngModel)]="reportYear">
      </kendo-dropdownlist>
    </div>
    <div class="row">
      <span class="label">Тип публикации</span>
      <kendo-dropdownlist class="list"
                          [data]="types"
                          [valuePrimitive]="true"
                          textField="name"
                          valueField="id"
                          [(ngModel)]="typeId">
      </kendo-dropdownlist>
    </div>
    <div class="row">
      <span class="label">Индексация</span>
      <kendo-multiselect class="list"
                         [data]="scientometricBases"
                         [checkboxes]="true"
                         [valuePrimitive]="true"
                         kendoMultiSelectSummaryTag
                         textField="name"
                         valueField="id"
                         [(ngModel)]="scientometricBaseIds">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span>{{ dataItems.length }} выбрано</span>
        </ng-template>
      </kendo-multiselect>
    </div>
    <button kendoButton themeColor="primary" (click)="exportFullReport()">
      Сформировать
    </button>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StudentService} from "../../../../services/gia/student.service";
import {StudentDebt} from "../../../../models/gia/student/student-debt.model";
import {StudentInfo} from "../../../../models/gia/student/student-info.model";

@Component({
  selector: 'app-student-debt',
  templateUrl: './student-debt.component.html',
  styleUrls: ['./student-debt.component.scss']
})
export class StudentDebtComponent implements OnInit {

  constructor(private router: Router,
              private activateRoute: ActivatedRoute,
              private studentService: StudentService) {
    this.studentId = activateRoute.snapshot.params["studentId"];
  }

  ngOnInit(): void {
    this.getStudentInfo();
    this.getStudentDebts();
  }

  public loading: boolean = true;
  public studentId: string = "";
  public studentDebts: StudentDebt[] = [];
  public studentInfo?: StudentInfo;

  public getStudentDebts() {
    this.studentService.getStudentDebt(this.studentId)
      .subscribe(response => {
        this.studentDebts = response;
        this.loading = false;
      })
  }

  public getStudentInfo() {
    this.studentService.getStudentInfo(this.studentId)
      .subscribe(response => {
        this.studentInfo = response;
      })
  }

  public back(){
    const storage = localStorage.getItem('diplomas_settings');
    if (storage !== null) {
      const data = JSON.parse(storage);
      this.router.navigateByUrl(`/gia/admissionorder/${data.standard}/${data.faculty}/${data.yearAdmission}/${data.studyForm}`).then();
      return;
    }
    this.router.navigateByUrl(`/gia/admissionorder`).then();
  }
}

<h5>Справки</h5>
<div class="functionButtons certificate-buttons"  *ngIf="!editMode&&!studentmaininfoComponent.deleted&&editable">
  <button kendoButton size="small" themeColor="success"
          (click)="addHandler($event)" icon="plus">
    Добавить справку
  </button>
  <button *ngIf="isGridEdit" (click)="onCancel()" class="cancelButton" kendoButton size="small">
    Отмена
  </button>
  <button (click)="save()" *ngIf="isGridEdit"  kendoButton size="small" themeColor="primary">
    Сохранить
  </button>
</div>
<div class="grid-container">
  <!--Grid-->
  <form novalidate #myForm="ngForm">
    <kendo-grid [kendoGridBinding]="spravkas"
                *ngIf="!editMode; else edit"
                (save)="downloadFile($event)"
                (edit)="transitionHandler($event)"
                (remove)="removeHandler($event)"
                (cellClick)="onCellClick($event)"
                [sortable]="true"
                #certificateGrid
                scrollable="none"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column field="numberFormatted"
                         headerClass="gridHeader dictionaries"
                         title="Рег. номер"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{ dataItem.numberFormatted }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="date"
                         headerClass="gridHeader dictionaries"
                         title="Дата выдачи"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{ (dataItem?.date == null ? "" : dataItem?.date| date: "dd.MM.yyyy") }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictSpravkaTypeId"
                         headerClass="gridHeader dictionaries"
                         title="Справка"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{ nametype(dataItem.dictSpravkaTypeId) }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="purpose"
                         headerClass="gridHeader dictionaries"
                         title="Куда выдана"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{dataItem.purpose!=" "?dataItem.purpose:dataItem.employerName}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="sendComment"
                         headerClass="gridHeader dictionaries"
                         title="Отметка об отправлении"
                         [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{dataItem.sendComment}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="isIssued"
                         editor="boolean"
                         title="Отметка о вручении"
                         headerClass="gridHeader"
                         [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{(dataItem.isIssued == true ? "Да" : "Нет")}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <span class="alignCells" [formGroup]="form">
            <input formControlName="isIssued" type="checkbox" kendoCheckBox />
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fio"
                         title="Пользователь"
                         headerClass="gridHeader"
                         [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{dataItem.fio}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column *ngIf="!studentmaininfoComponent.deleted"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
          <button kendoButton type="button"
                  icon="save"
                  class="certificate-green-button"
                  fillMode="flat"
                  rounded="full"
                  size="small"
                  *ngIf="!isGridEdit"
                  (click)="downloadFile(dataItem)">
          </button>
          <button kendoGridEditCommand type="button"
                  icon="edit"
                  *ngIf="editable"
                  fillMode="flat"
                  rounded="full"
                  size="small">
          </button>
          <button kendoGridRemoveCommand
                  type="button"
                  *ngIf="editable"
                  [disabled]="dataItem.isIssued == true"
                  [hidden]="dataItem.isIssued == true"
                  class="certificate-red-button"
                  icon="close"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <div class="functionButtons certificate-buttons"  *ngIf="isGridEdit&&!studentmaininfoComponent.deleted&&editable">
      <button (click)="onCancel()" class="cancelButton" kendoButton size="small">
        Отмена
      </button>
      <button (click)="save()"  kendoButton size="small" themeColor="primary">
        Сохранить
      </button>
    </div>
  </form>
</div>
<!------------Edit Identification------------>
<ng-template #edit >
  <div class="certificate-edit-block">
    <div [ngClass]="{call: isCall}" class="certificate ngForm">
      <form class="k-form" [formGroup]="form">
        <dl class="dl-certificate">
          <h3>Справка</h3>
          <dd>
            <kendo-formfield>
              <kendo-dropdownlist [data]="spravkaTypes"
                                  textField="typeName"
                                  valueField="dictSpravkaTypeExternalId"
                                  size="small"
                                  class="certificate-field"
                                  [valuePrimitive]="true"
                                  (valueChange)="spravkaTypeValueChange($event)"
                                  formControlName="dictSpravkaTypeId"
                                  #dictSpravkaTypeId
                                  [formControl]="$any(form).controls['dictSpravkaTypeId']"
                                  required>
              </kendo-dropdownlist>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
          <h3>Рег.номер</h3>
          <dd class="certificate-inline">
            <kendo-formfield>
              <kendo-textbox
                formControlName="numberFormatted"
                [(ngModel)]="this.numberFormatted"
                [style.width.px]="200"
                class="margin-eight"
                size="small"
                [disabled]="true"
                placeholder="Рег.номер"
                required
              ></kendo-textbox>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
            <h3 class="margin-thirty">Дата выдачи</h3>
            <h3>{{(newDate == null ? "--.--.----" : newDate| date:"dd.MM.yyyy")}}</h3>
          </dd>
          <h3 *ngIf="!isCall">Выдана</h3>
          <dd *ngIf="!isCall">
            <kendo-formfield>
              <kendo-dropdownlist [data]="purposes"
                                  *ngIf="!isMilitary"
                                  textField="name"
                                  valueField="dictCertificatePurposeId"
                                  size="small"
                                  class="certificate-field"
                                  [valuePrimitive]="true"
                                  formControlName="dictCertificatePurposeId"
                                  required>
              </kendo-dropdownlist>
              <kendo-autocomplete
                [data]="militaryCommissariats"
                *ngIf="isMilitary"
                textField="commissariatName"
                valueField="commissariatName"
                formControlName="purpose"
                class="certificate-field"
                #commissariat
                [openOnFocus]="commissariat"
                size="small"
                placeholder=""
                required
              ></kendo-autocomplete>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
          <h3 *ngIf="!isCall && !isMilitary">Доп. поле</h3>
          <dd *ngIf="!isCall && !isMilitary" class="certificate-inline">
            <kendo-formfield>
              <kendo-textbox
                formControlName="additionalComment"
                class="certificate-field"
                size="small"
                placeholder=""
              ></kendo-textbox>
            </kendo-formfield>
          </dd>
          <h4 class="certificate-title">Обучающийся</h4>
          <dd></dd>
        </dl>
      </form>
      <dl class="dl-certificate row-gap-eighteen padding-eighteen-twenty">
        <dd class="certificate-inline">
          <h3 class="margin-thirty">ФИО</h3>
          <h3>{{fio == null ? "-" :fio}}</h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-thirty">Направление подготовки</h3>
          <h3 class="certificate-education-way">{{studentMainInfo.contingent.eduGroup == null ? "(--.--.--) -" : studentMainInfo.nameEducationStandard(studentMainInfo.contingent.eduGroup?.planId
            ? studentMainInfo.contingent.eduGroup?.planId
            : studentMainInfo.contingent.studPlan.planId)}}</h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-thirty">Профиль (программа)</h3>
          <h3 class="certificate-education-way">{{studentMainInfo.contingent.eduGroup == null ? "-" :studentMainInfo.nameEducationProgram(studentMainInfo.contingent.eduGroup?.planId
            ? studentMainInfo.contingent.eduGroup?.planId
            : studentMainInfo.contingent.studPlan.planId)}}</h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-eighteen">Форма обучения</h3>
          <h3 class="margin-fifty">{{(!studyForm ? "" : studyForm.studyFormName) }}</h3>
          <h3 class="margin-eighteen"> Курс</h3>
          <h3 class="margin-fifty">{{(studentMainInfo.contingent.courseNum==null
            ? (studentMainInfo.contingent.eduGroup == null ? "-" : studentMainInfo.contingent.eduGroup?.courseNum)
            : studentMainInfo.contingent.courseNum)}}</h3>
          <h3 class="margin-eighteen">Группа</h3>
          <h3 class="margin-thirty">{{(studentMainInfo.contingent.eduGroup == null ? "-" : studentMainInfo.contingent.eduGroup?.groupName)}}</h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-eighteen">Источник финансирования</h3>
          <h3>{{(studentMainInfo.contingent.studentBudgetState == null ? "-" : studentMainInfo.contingent.studentBudgetState.studentBudgetName)}}</h3>
        </dd>
        <dd></dd>
      </dl>
      <form class="k-form" [formGroup]="formCaseName">
        <dl *ngIf="isCall" class="dl-certificate">
          <h4>Склонение ФИО</h4>
          <dd></dd>
          <h3>(Родительный падеж)</h3>
          <dd>
          </dd>
        </dl>
        <dl *ngIf="isCall" class="dl-certificate-inline padding-ten-twenty">
          <dd>
            <h3 style="margin-bottom: 11px;">Фамилия</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="rLastName"
              placeholder="Фамилия"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Имя</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="rFirstName"
              placeholder="Имя"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Отчество</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="rMiddleName"
              placeholder="Отчество"
            ></kendo-textbox>
          </dd>
        </dl>
        <dl *ngIf="isCall" class="dl-certificate">
          <h3>(Дательный падеж)</h3>
          <dd>
          </dd>
        </dl>
        <dl *ngIf="isCall" class="dl-certificate-inline padding-ten-twenty">
          <dd>
            <h3 style="margin-bottom: 11px;">Фамилия</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="dLastName"
              placeholder="Фамилия"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Имя</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="dFirstName"
              placeholder="Имя"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Отчество</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="dMiddleName"
              placeholder="Отчество"
            ></kendo-textbox>
          </dd>
        </dl>
      </form>
      <form class="k-form" [formGroup]="form">
        <dl *ngIf="isCall" class="dl-certificate column-gap-twenty" style="padding-bottom: 20px;">
          <h4>Работодатель</h4>
          <dd></dd>
          <h3>Наименование организации</h3>
          <dd>
            <kendo-formfield>
              <kendo-autocomplete
                [data]="organizations"
                [kendoDropDownFilter]="filterSettings"
                textField="organizationName"
                valueField="organizationName"
                formControlName="employerName"
                class="employer-field"
                size="small"
                placeholder=""
              ></kendo-autocomplete>
              <!--<kendo-combobox [data]="organizations"
                 textField="organizationName"
                 valueField="organizationName"
                 [valuePrimitive]="true"
                 size="small"
                 class="employer-field"
                 #comboboxComponent
                 [openOnFocus]="comboboxComponent"
                 [kendoDropDownFilter]="filterSettings"
                 formControlName="employerName"
                 required>
                 </kendo-combobox>
                 <span class="required">*</span>
                 <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>-->
            </kendo-formfield>
          </dd>
          <dd></dd>
          <a *ngIf="isCall" class="organization-href href-pointer" (click)="openOrganization()">Перейти к справочнику работодателей</a>
          <h3>ФИО руководителя</h3>
          <dd>
            <kendo-formfield>
              <kendo-textbox
                formControlName="employerFIO"
                class="employer-field"
                size="small"
                placeholder=""
              ></kendo-textbox>
              <!--<span class="required">*</span>-->
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
          <h3 class="response-title">Причина предоставления отпуска</h3>
          <kendo-formfield>
            <kendo-dropdownlist [data]="dictCodeArticles"
                                textField="reason"
                                valueField="externalId"
                                size="small"
                                class="employer-field"
                                [valuePrimitive]="true"
                                (selectionChange)="selectionChange($event)"
                                [formControl]="$any(form).controls['dictCodeArticleId']"
                                required>
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
          <h3 class="response-title">Статья ТК РФ</h3>
          <kendo-formfield>
            <kendo-dropdownlist [data]="sortedArticles"
                                textField="name"
                                valueField="externalId"
                                size="small"
                                class="employer-field"
                                [valuePrimitive]="true"
                                [formControl]="$any(form).controls['dictCodeArticleId']"
                                required>
            </kendo-dropdownlist>
            <span class="required">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
          <h3 *ngIf="isCall">Продолжительность отпуска</h3>
          <dd *ngIf="isCall" class="certificate-inline dateArray">
            <h3 class="margin-eight">с</h3>
            <kendo-formfield>
              <kendo-datepicker
                format="dd.MM.yyyy"
                #requestDateWith
                [disabled]="form.value.employerDateList && form.value.employerDateList.length > 0"
                class="input-main-identity margin-thirty"
                [openOnFocus]="requestDateWith"
                formControlName="employerDateBegin"
                [style.width.px]="173">
              </kendo-datepicker>
            </kendo-formfield>
            <h3 class="margin-eight">по</h3>
            <kendo-formfield>
              <kendo-datepicker
                format="dd.MM.yyyy"
                #requestDate
                [disabled]="form.value.employerDateList && form.value.employerDateList.length > 0"
                class="input-main-identity"
                [openOnFocus]="requestDate"
                formControlName="employerDateEnd"
                [style.width.px]="173"
              >
              </kendo-datepicker>
            </kendo-formfield>
          </dd>
          <h3 *ngIf="isCall">Перечислить даты</h3>
          <dd *ngIf="isCall" class="dateArrayDd">
            <kendo-formfield>
              <kendo-textarea
                formControlName="employerDate"
                [ngClass]="{white: !(form.value.employerDateBegin||form.value.employerDateEnd)}"
                [(ngModel)]="stringEmployerDateList"
                [disabled] = true
                class="date-list-area"
                size="small"
                placeholder=""
                resizable="auto"
              ></kendo-textarea>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
              <button kendoButton
                      [disabled] = "form.value.employerDateBegin||form.value.employerDateEnd"
                      class="date-list-button"
                      fillMode="flat"
                      size="small"
                      icon="calendar"
                      #anchor
                      (click)="toggle()"
              ></button>
              <kendo-popup [anchor]="anchor"
                           #popup
                           [hidden]="!show"
                           [popupAlign]="popupAlign"
                           [anchorAlign]="anchorAlign">
                <kendo-calendar
                  formControlName="employerDateList"
                  selection="multiple"
                  (valueChange)="onChange($event)"
                ></kendo-calendar>
              </kendo-popup>
            </kendo-formfield>
            <!--[disabled] = "form.value.employerDateBegin||form.value.employerDateEnd"-->
            <p class="helpArrayDate">
              Выбирайте несколько дат, удерживая Shift и кликая на первую и последнюю в интервале.
              Для отдельных дат используйте Ctrl и кликайте на каждую по отдельности.
            </p>
          </dd>
        </dl>
        <dl *ngIf="!isCall||isMilitary" class="dl-certificate row-gap-eighteen">
          <h4>Срок обучения</h4>
          <dd></dd>
          <dd class="certificate-inline">
            <h3 class="margin-eighteen">Период обучения</h3>
            <h3 class="margin-eighteen">с</h3>
            <h3 class="margin-eighteen">{{(orderStudyPeriod == null || orderStudyPeriod.dateStart == null ? "--.--.----" : orderStudyPeriod.dateStart| date:"dd.MM.yyyy")}}</h3>
            <h3 class="margin-eighteen">по</h3>
            <h3 class="margin-eighteen">{{(orderStudyPeriod == null || orderStudyPeriod.dateFinish == null? "--.--.----" : orderStudyPeriod.dateFinish| date:"dd.MM.yyyy")}}</h3>
          </dd>
          <dd></dd>
          <dd class="certificate-inline">
            <h3 class="margin-eighteen">Срок обучения</h3>
            <h3 class="margin-eighteen">
              {{(spravkaStudyPeriod.studyPeriodYear == null ? "-" : spravkaStudyPeriod.studyPeriodYear)}}
              {{(spravkaStudyPeriod.studyPeriodYear != null&&spravkaStudyPeriod.studyPeriodMonth != null ? ", " : " ")}}
              {{(spravkaStudyPeriod.studyPeriodMonth == null ? " " : spravkaStudyPeriod.studyPeriodMonth)}}
            </h3>
          </dd>
          <dd></dd>
        </dl>
        <dl *ngIf="!isCall" class="dl-certificate row-gap-eighteen padding-eighteen-twenty" >
          <ng-container class="ddinline" *ngFor="let item of spravkaStudyPeriod.orders;let i=index">
            <dd class="certificate-inline">
              <h3 class="margin-eighteen">{{item.typeName}}: </h3>
              <h3 class="margin-eighteen">Приказ от
                {{(item.orderDate == null ? "--.--.---- " : item.orderDate| date:"dd.MM.yyyy")}}
                г. №
                {{(item.number == null ? " " : item.number)}}
              </h3>
            </dd>
            <dd></dd>
          </ng-container>
          <dd class="certificate-inline" *ngIf="!isSpravkaCourse">
            <h3 class="margin-eighteen">{{spravkaStudyPeriod.orderCourse==null?"":spravkaStudyPeriod.orderCourse.typeName + ": "}}</h3>
            <h3 class="margin-eighteen">от
              {{(spravkaStudyPeriod.orderCourse==null||spravkaStudyPeriod.orderCourse.orderDate == null ? "--.--.---- " : spravkaStudyPeriod.orderCourse.orderDate| date:"dd.MM.yyyy")}}
              г. №
              {{(spravkaStudyPeriod.orderCourse==null|| spravkaStudyPeriod.orderCourse.number == null ? " " : spravkaStudyPeriod.orderCourse.number)}}
            </h3>
          </dd>
          <dd></dd>
        </dl>
        <dl class="dl-certificate" *ngIf="!isMilitary">
          <h4>Подписанты</h4>
          <dd></dd>
        </dl>
        <ng-container class="ddinline padding-five-five" *ngFor="let item of spravkaPersons;let i=index">
          <dl class="dl-certificate" *ngIf="isMilitary">
            <h4>{{i == 0 ? "Подписант" : "Исполнитель"}}</h4>
            <dd></dd>
          </dl>
          <ng-container *ngIf="!signerEditOpen[i]">
            <dl class="dl-certificate padding-five-five">
              <h3>{{i == 1  && isMilitary? "Исполнитель" : "Подписант"}}</h3>
              <dd>
                <kendo-combobox [data]="localSignatories" #combo
                                textField="fio"
                                valueField="personExternalId"
                                #person
                                [openOnFocus]="person"
                                size="small"
                                class="signer-field"
                                (valueChange)="personValueChange(i,$event)"
                                [kendoDropDownFilter]="filterSettings"
                                [valuePrimitive]="true"
                                [(ngModel)]="spravkaPersons[i].personId"
                                [ngModelOptions]="{standalone: true}">
                </kendo-combobox>
                <span class="required">*</span>
              </dd>
              <h3 class="certificate-signer">{{i == 1  && isMilitary? "Телефон" : "Должность подписанта"}}</h3>
              <dd>
                <kendo-formfield>
                  <kendo-textbox
                    *ngIf="!(isMilitary && i == 1)"
                    [ngModelOptions]="{standalone: true}"
                    size="small"
                    class="signer-field"
                    placeholder=""
                    [(ngModel)]="this.personPosts[i]"
                  ></kendo-textbox>
                  <kendo-textbox
                    *ngIf="isMilitary && i == 1"
                    size="small"
                    class="signer-field"
                    placeholder=""
                    formControlName="performerNumber"
                  ></kendo-textbox>
                </kendo-formfield>
              </dd>
              <h3>{{i == 1  && isMilitary? "Поиск исполнителя" : "Поиск подписанта"}}</h3>
              <dd>
                <kendo-autocomplete
                  [data]="persons"
                  class="signer-field"
                  valueField="fio"
                  size="small"
                  placeholder="Введите ФИО сотрудника..."
                  [clearButton]="true"
                  [filterable]="true"
                  [kendoDropDownFilter]="filterSettings"
                  (valueChange)="onChangeFindSigner(i,$event)">
                  <ng-template kendoAutoCompleteNoDataTemplate>
                    <p class="signer-edit__template-not-found">Подписант не найден</p>
                  </ng-template>
                </kendo-autocomplete>
              </dd>
              <dd></dd>
              <dd><button kendoButton
                          class="certificate-close-button"
                          size="small"
                          *ngIf="editMode"
                          (click)="closeSigner(i)">
                Закрыть
              </button>
              </dd>
            </dl>
          </ng-container>
          <ng-container *ngIf="signerEditOpen[i]">
            <dl class="dl-certificate-inline">
              <dd class="signer-certificate certificate-signer-name">
                <h3 (click)="openSigner(i)" style="display: contents; margin-right:20px;">
                  {{(spravkaPersons[i].personId == null ? "Фамилия Имя Отчество" :
                  nameperson(spravkaPersons[i].personId))}}  {{personPosts[i] == null||personPosts[i] == "" ? "Должность" : ", " + personPosts[i]}}
                </h3>
              </dd>
              <dd class="signer-certificate padding-five-five">
                <button kendoButton
                        themeColor="error"
                        fillMode="outline"
                        class="certificate-outline-buttons certificate-button-red"
                        size="small"
                        [disabled]="spravkaPersons.length<2"
                        *ngIf="spravkaPersons.length!=1&&!isCall&&!isMilitary&&!disabled"
                        (click)="removeSigner(i)">
                  Удалить
                  <span class="k-icon k-i-close certificate-button-red"></span>
                </button>
                <button kendoButton
                        *ngIf="!disabled"
                        class="certificate-right-button"
                        fillMode="outline"
                        size="small"
                        icon="edit"
                        (click)="openSigner(i)"
                ></button>
                <span class="required">*</span>
              </dd>
            </dl>
          </ng-container>
        </ng-container>
        <dd class="outlineButtons organization-href padding-ten-twenty">
          <button kendoButton *ngIf="!addSignerHide&&!disabled"
                  fillMode="outline" class="certificate-add-button"
                  size="small"
                  (click)="addSigner()">
            Добавить подписанта
            <span class="k-icon k-i-plus"></span>
          </button>
        </dd>
        <dl class="dl-certificate column-gap-twenty">
          <h4>Примечание</h4>
          <dd></dd>
          <h3 *ngIf="!isCall&&!isMilitary">Ответ на официальный запрос</h3>
          <dd *ngIf="!isCall&&!isMilitary" class="certificate-inline">
            <h3 class="margin-eight">Номер</h3>
            <kendo-textbox
              class="margin-eighteen"
              [style.width.px]="145"
              size="small"
              formControlName="requestNumber"
            ></kendo-textbox>
            <h3 class="margin-eight">Дата</h3>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #requestDate
              class="input-main-identity"
              [openOnFocus]="requestDate"
              formControlName="requestDate"
              [style.width.px]="155"
            >
            </kendo-datepicker>
          </dd>
        </dl>
        <dl *ngIf="!isCall&&!isMilitary" class="dl-certificate column-gap-twenty padding-sixteen-zero">
          <h3>Образование соответствующего уровня получается впервые</h3>
          <dd>
            <input id="ft" type="checkbox" kendoCheckBox
                   name="firstTimeCheck"
                   formControlName="firstTime">
          </dd>
        </dl>
        <dl *ngIf="!isCall&&!isMilitary" class="dl-certificate column-gap-twenty padding-eighteen-twenty">
          <h3 class="response-title">Отметка об отправлении</h3>
          <dd>
            <kendo-textarea
              [style.width.px]="420"
              size="none"
              formControlName="sendComment"
              class="custom-size"
              resizable="none"
            >
            </kendo-textarea>
          </dd>
          <!-- <h3>Примечание</h3>
             <dd>
               <kendo-textarea
                 [style.width.px]="420"
                 size="none"
                 formControlName="comment"
                 class="custom-size"
                 resizable="none"
               >
               </kendo-textarea>
             </dd> -->
          <h3 style="margin-right: 33px;">Отметка о вручении</h3>
          <dd>
            <input id="ac" type="checkbox" kendoCheckBox
                   name="issued"
                   formControlName="isIssued"
                   (change)="toggleEditForm()">
          </dd>
          <h3 style="margin-right: 33px;">Количество экземпляров</h3>
          <dd>
            <kendo-numerictextbox
              [min]="1"
              [max]="20"
              format="n0"
              [style.width.px]="145"
              style="width:300px"
              [maxlength]=4
              formControlName="instancesNumber">
            </kendo-numerictextbox>
          </dd>
        </dl>
        <dl *ngIf="isCall||isMilitary" class="dl-certificate row-gap-eighteen" style="margin-top: 10px">
          <!--<h3 class="margin-eighteen">Дата предъявления</h3>
             <h3 class="margin-thirty">по месту требования</h3>-->
          <h3 style="margin-right: 33px;">Отметка о вручении</h3>
          <dd>
            <input id="ac" type="checkbox" kendoCheckBox
                   name="issued"
                   formControlName="isIssued"
                   (change)="toggleEditForm()">
          </dd>
          <!--<h3 style="margin-right: 33px;">Количество экземпляров</h3>
             <dd>
               <kendo-numerictextbox
                 [min]="1"
                 [max]="20"
                 format="n0"
                 [style.width.px]="145"
                 style="width:300px"
                 [maxlength]=4
                 formControlName="instancesNumber">
               </kendo-numerictextbox>
             </dd>-->
        </dl>
      </form>
    </div>
    <div class="editModeButtons">
      <button kendoButton
              size="small"
              *ngIf="editMode"
              (click)="closeIdentification()">
        Отмена
      </button>
      <button kendoButton
              size="small"
              *ngIf="editMode&&!disabled"
              themeColor="primary"
              [disabled]="!form.valid||!saveDisable"
              (click)="onSave($event)"
              style="margin-left: 15px">
        Сохранить
      </button>
      <button kendoButton
              size="small"
              size="small"
              *ngIf="editMode&&!disabled"
              themeColor="primary"
              style="float:right; margin: 0;"
              [disabled]="!form.valid||!saveDisable"
              (click)="onSaveAndPrint($event)"
              icon="print">
        Сохранить и распечатать
      </button>
      <button kendoButton
              size="small"
              size="small"
              *ngIf="editMode&&disabled"
              themeColor="primary"
              style="float:right; margin: 0;"
              (click)="downloadFile(form.value)"
              icon="print">
        Распечатать
      </button>
    </div>
  </div>
</ng-template>
<div kendoDialogContainer></div>

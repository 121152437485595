import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AddEvent, CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import {Router} from "@angular/router";
import {EducationalOrganizationName} from "../../../models/dicts/educationalorganizationname.model";
import {EducationalorganizationnameService} from "../../../services/dicts/educationalorganizationname.service";
import {AccessLevelEnum} from "../../../models/dicts/enums/access-level.enum";
import {TimeZoneFix} from "../../../helpers/date-helper";
import { openRemoveDialog } from 'src/app/helpers/dialogHelper';

@Component({
  selector: 'app-educationalorganizationname',
  templateUrl: './educationalorganizationname.component.html',
  styleUrls: ['./educationalorganizationname.component.scss']
})
export class EducationalorganizationnameComponent implements OnInit {

  public allNames: EducationalOrganizationName[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private nameService: EducationalorganizationnameService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: CommonDictionariesUserAccessService,
    private router: Router,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    //  this.getAllFilial();

  }

  public onStateChange(): void {
    this.getAllEducationalOrganizationName();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.formGroup = formGroup({
      name: "",
    });
    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid) || this.disableSave) {
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.validDate();

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid || this.disableSave) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex
  ): void {
    this.isNew = false;
    this.disableSave = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {

    if (this.isInEditingMode && this.formGroup) {
      this.timeZoneFix(this.formGroup);
      if (this.isNew) {
        this.nameService.add(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllEducationalOrganizationName();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить филиал')
            }}
          );
      }
      else  {
        this.nameService.update(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllEducationalOrganizationName();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения')
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  public timeZoneFix(formGroup: UntypedFormGroup)
  {
    if(formGroup.value.dateBegin) formGroup.value.dateBegin = TimeZoneFix(formGroup.value.dateBegin);
    if(formGroup.value.dateEnd) formGroup.value.dateEnd = TimeZoneFix(formGroup.value.dateEnd);
  }

  public getAllEducationalOrganizationName() {
    this.nameService.get()
      .subscribe(
        response => {
          this.allNames = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelEducationalOrganizationName == AccessLevelEnum.forbidden) {
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          }
          this.editable = response.accessLevelEducationalOrganizationName == AccessLevelEnum.write;
          this.getAllEducationalOrganizationName();
        },
      });
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.name.toLowerCase(), 450, 200, 250);

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да'){
          this.nameService.delete(dataItem.id)
            .subscribe({
              next:() => {
                this.getAllEducationalOrganizationName();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
      }
      this.opened = false;
    });
  }

  public disableSave = false;

  public validDate(){
    if (this.formGroup?.value.dateEnd && this.formGroup?.value.dateBegin){
      this.disableSave = !(+this.formGroup?.value.dateBegin < +this.formGroup?.value.dateEnd);
    }
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { name: string; rName?: string; shortName?: string; dateBegin?: Date; dateEnd?: Date; id?: string;}) =>
  new UntypedFormGroup({
    id: new UntypedFormControl(dataItem.id),
    name: new UntypedFormControl(dataItem.name, Validators.required),
    dateBegin: new UntypedFormControl(dataItem.dateBegin, Validators.required),
    rName: new UntypedFormControl(dataItem.rName),
    shortName: new UntypedFormControl(dataItem.shortName),
    dateEnd: new UntypedFormControl(dataItem.dateEnd),
  });

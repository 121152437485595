export const items: any[] = [
    {
      text: "Настройки",
      icon: "cog",
      items: [
        {
          text: "Кадровая база",
          path: "/technicaladministators/cadrebase",
        },
        {
          text: "Подразделения",
          path: "/technicaladministators/departments",

        },
        {
          text: "Справочники",
          items: [
            {
            text: "Типы подразделений",
            path: "/technicaladministators/departmentstype"
            },
            {
              text: "Названия подразделений",
              path: "/technicaladministators/departmentsname"
            },
            {
            text: "Должности",
            path: "/technicaladministators/posts"
            },
            {
              text: "Ученые степени",
              path: "/technicaladministators/sciencedegree"
            },
          ],
        },   
      ],
    },
  ];
  
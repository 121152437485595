import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {EduGroup} from "../../../models/disciplineworkload/externals.model";
import {handleError} from "../../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class EduGroupService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.edugroup}`;
  constructor(private http: HttpClient) { }

  // Get edugroups
  getEduGroups(filter: HttpParams): Observable<EduGroup[]> {
    return this.http.get<EduGroup[]>(this.baseUrl, {params: filter});
  }

  // Get edugroup by id
  getEduGroupById(id: string): Observable<EduGroup> {
    return this.http.get<EduGroup>(this.baseUrl + '/GetEduGroupById/' + id)
        .pipe(
            catchError(handleError));
  }
}

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { DictControlAction } from "src/app/models/education/External/controlaction.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
  })
  export class DictControlActionService {
    baseUrl = `${environment.apiEndpoint}`;

    constructor(
        private http: HttpClient,
      ) {}

    //Edit DictMarkCriteriaLevel
    public updateDictControlAction(dto: DictControlAction): Observable<DictControlAction> {
        return this.http.put<DictControlAction>(this.baseUrl + environment.apiPaths.session.controlaction + '/' + dto.id, dto)
        .pipe(catchError(this.handleError));
    }

    public getAllDictControlActions(): Observable<DictControlAction[]> {
        return this.http.get<DictControlAction[]>(this.baseUrl + environment.apiPaths.session.controlaction)
        .pipe(catchError(this.handleError));
    }

    // Error
    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
import {Component, OnInit} from "@angular/core";
import {items} from 'src/app/models/oferta/menu-items';
import {OfertaUserAccessService} from "../../../services/useraccess/oferta-user-access.service";

@Component({
  selector: 'oferta-settings',
  templateUrl: './oferta-settings.component.html',
  styleUrls: ['./oferta-settings.component.scss']
})
export class OfertaSettingsComponent implements OnInit {
  //Menu items
  public items = items;
  private currentUserAccess = this.userAccessService.currentUserAccess$.value;

  constructor(
    private userAccessService: OfertaUserAccessService
  ) { }

  ngOnInit() {
    this.getUserAccess();
  }

  private getUserAccess() {
    if (!this.currentUserAccess?.isAdmin) {
      const accessRights = this.currentUserAccess?.userAccessRight;
      this.items[0].items = items[0].items.filter((item: any) => {
        return (accessRights?.accessSettings ? item : !item.accessSettings)
          && (accessRights?.dict ? item : !item.dict)
          && (accessRights?.regulation ? item : !item.regulation)
      });
    }
  }
}

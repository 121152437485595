import { Component, OnInit } from '@angular/core';
import { MarksModel } from "../../../models/middlecontrol/marks.model";
import { DictsService } from "../../../services/middlecontrol/dicts.service";

@Component({
  selector: 'app-sessiontablelegend',
  templateUrl: './sessiontablelegend.component.html',
  styleUrls: ['./sessiontablelegend.component.scss'],
})
export class SessiontablelegendComponent implements OnInit {

  public marks: MarksModel[] = [];

  constructor(
    private dictService: DictsService,
  ) {}

  ngOnInit(): void {
    this.dictService.updateMarks();
    this.dictService.marks$.subscribe(response => {
      this.marks = response
        .filter(item => item.markValue >= 0)
        .sort((a, b) => a.markValue > b.markValue ? -1 : 1);
    })
  }
}

import {Component, OnInit, ViewChild} from "@angular/core";
import {pagerSettings} from 'src/app/models/oferta/pagerSettings.model';
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {DictRegulationType} from "../../../../models/oferta/dicts/dictRegulationType.model";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DictRegulationTypeService} from "../../../../services/oferta/dictRegulationType.service";
import {OfertaUserAccessService} from "../../../../services/useraccess/oferta-user-access.service";
import {AccessRights} from "../../../../models/oferta/enums/accessRights.enum";

@Component({
  selector: 'dictregulationtype',
  templateUrl: './dictregulationtype.component.html',
  styleUrls: ['./dictregulationtype.component.scss']
})

export class DictRegulationtypeComponent implements OnInit {

  protected editable = false;

  public regulationTypes: DictRegulationType[] = [];

  protected pageSize = 10;
  protected readonly pagerSettings = pagerSettings;

  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isLine = false;

  //public editable?: boolean
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined;
  }

  constructor(
    private regulationTypeService: DictRegulationTypeService,
    private notificationService: NotificationsService,
    private userAccessService: OfertaUserAccessService,
  ) { }
  public ngOnInit(): void {
    this.getAccess()
    this.getAllRegulationTypes();
  }

  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return;
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup) {
      this.regulationTypeService.updateRegulationType(this.formGroup.value)
        .subscribe({
          next:() => {
            this.getAllRegulationTypes();
            this.notificationService.showSuccess("Сохранено");
          },
          error:(error) => {
            this.notificationService.showError(error.error);
          }}
        );
    }
    this.closeEditor(this.grid);
  }

  public getAllRegulationTypes() {
    this.regulationTypeService.getAllRegulationTypes()
      .subscribe(
        response => {
          this.regulationTypes = response;
        }
      );
  }

  private getAccess() {
    this.userAccessService.getCurrentUserAccess().subscribe((response) =>
      this.editable = response?.userAccessRight.dict === AccessRights.Write);
  }
}

const formGroup = (dataItem: DictRegulationType) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    name: new FormControl(dataItem.name, Validators.required),
    order: new FormControl(dataItem.order),
    userSignatureTypeEnum: new FormControl(dataItem.userSignatureTypeEnum, Validators.required),
  });

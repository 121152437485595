import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { Guid } from 'guid-typescript';
import { handleError } from '../../helpers/errorHandle-helper';
import { GlobalSignatoryDto } from '../../models/gia/signatory/globalsignatory.model';
import {
  LocalSignatoryAdd,
  LocalSignatoryUpdate,
  LocalSignatoryDto, SignatoryRole,
} from '../../models/gia/signatory/localsignatory.model';

@Injectable({
  providedIn: 'root',
})
export class SignatoryService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.gia.signatory}`;

  constructor(private http: HttpClient) {}

  public getLocalSignatories(): Observable<LocalSignatoryDto[]> {
    return this.http.get<LocalSignatoryDto[]>(this.baseUrl);
  }

  public getGlobalSignatories(): Observable<GlobalSignatoryDto[]> {
    return this.http.get<GlobalSignatoryDto[]>(
      this.baseUrl + '/GlobalSignatory',
    );
  }

  public getSignatoryRoles(): Observable<SignatoryRole[]> {
    return this.http
      .get<SignatoryRole[]>(this.baseUrl + '/DictSignatoryRole',)
      .pipe(catchError(handleError));
  }

  public addLocalSignatory(data: LocalSignatoryAdd): Observable<Guid | string> {
    return this.http
      .post<Guid | string>(this.baseUrl, data)
      .pipe(catchError(handleError));
  }

  public updateLocalSignatory(data: LocalSignatoryUpdate): Observable<boolean> {
    return this.http
      .put<boolean>(this.baseUrl, data)
      .pipe(catchError(handleError));
  }

  public deleteLocalSignatory(localSignatoryId: Guid | string): Observable<boolean> {
    return this.http
      .delete<boolean>(this.baseUrl + '/' + localSignatoryId)
      .pipe(catchError(handleError));
  }
}

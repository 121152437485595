import {ChangeDetectorRef, Component, HostListener, Input, OnInit, Renderer2, ViewChild} from "@angular/core";
import {AddEvent, EditEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UserAccess} from "../../../models/oferta/userAccess.model";
import {SortDescriptor} from "@progress/kendo-data-query";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {checkRole} from "../../../helpers/token/role-check";
import {Role} from "../../../models/useraccess/role";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {PersonService} from "../../../services/oferta/person.service";
import {Person} from "../../../models/oferta/person.model";
import {OfertaUserAccessService} from "../../../services/useraccess/oferta-user-access.service";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {isChildOf} from "../../../helpers/elementRef-helper";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {getData$} from "../../../../environments/environment";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-oferta-access',
  templateUrl: './oferta-access.component.html',
  styleUrls: ['./oferta-access.component.scss'],
})
export class OfertaAccessRightComponent implements OnInit {

  public isAdmin: boolean;
  public virtual = {
    itemHeight: 28,
  };

  public userAccesses: UserAccess[] = [];
  public persons: Person[] = [];

  public boolOptions = [
    { id: true, text: 'Да' },
    { id: false, text: 'Нет' },
  ];

  public dropdownOptions = [
    {id: 0, text: 'Нет' },
    {id: 1, text: 'Чтение' },
    {id: 2, text: 'Запись' }
  ]

  public formGroup: FormGroup | undefined;
  public sort: SortDescriptor[] = [{ field: 'fio', dir: 'asc' }];

  public filteredData: any = {};

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;
  public loading = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: UserAccess[] = [];

  constructor(
    private personService: PersonService,
    private renderer: Renderer2,
    private dialogService: DialogService,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private notificationService: NotificationsService,
    private userAccessService: OfertaUserAccessService,
    private cdr: ChangeDetectorRef
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  async ngOnInit() {
    await this.getPersons();
    getData$.subscribe(() => this.getAccesses());
  }

  private getAccesses() {
    this.loading = true;
    this.userAccessService.getAccess().subscribe((items) => {
      if (items) {
        this.userAccesses = items.map(a => ({
          ...a,
          fio: this.persons.find(b => b.id === a.personId)?.fio,
        }));
      } else {
        this.userAccesses = [];
      }
      this.gridData = this.userAccesses;
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

  public clearData() {
    this.searchFIO = '';
    this.filterData();
  }

  public filterData() {
    this.gridData = this.userAccesses.filter((a) => a.fio?.toLowerCase().includes(this.searchFIO.toLowerCase()));
  }

  private async getPersons() {
    await lastValueFrom(this.personService.getAllPersons()).then((response) => {
      this.persons = response;
    });
  }

  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if (this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }

      this.formGroup.value.id === ''
        ? this.userAccessService.createAccess(this.formGroup.value)
        : this.userAccessService.updateAccess(this.formGroup.value);
    }
    this.closeEditor(this.grid);
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    const targetKey: string = 'id';

    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Finish editing
  private closeEditor(
    grid: GridComponent,
    rowIndex = this.editedRowIndex,
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      id: '',
      personId: '',
      dict: 1,
      accessSettings: false,
      regulation: 1,
      userSignature: 1
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService,`${dataItem.fio} из настроек доступа`);
    this.opened = dialogResultHelper(dialog.result, this.userAccessService.deleteAccess(dataItem.id), this.notificationService);
  }

  public getAccessLevelName(accessLevel: number): string {
    return this.dropdownOptions.find((value) => value.id === accessLevel)?.text ?? '';
  }

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData();
    }
  }
}

const formGroup = (dataItem: {
  id?: string;
  personId?: string;
  dict?: number;
  accessSettings?: boolean;
  regulation?: number;
  userSignature?: number
}) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    dict: new FormControl(dataItem.dict, Validators.required),
    accessSettings: new FormControl(dataItem.accessSettings, Validators.required),
    regulation: new FormControl(dataItem.regulation, Validators.required),
    userSignature: new FormControl(dataItem.userSignature, Validators.required),
  });

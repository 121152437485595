<education-header></education-header>
<title>Виды работ учебного плана</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Виды работ учебного плана</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Добавить новый вид работы учебного плана' : 'Изменить вид работы учебного плана' }}
  </h1>

  <div class="selectContent" *ngIf="!isOpened">
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Филиал
      </span>
      <kendo-dropdownlist [data]="filials"
                          textField="filialShortName"
                          valueField="filialId"
                          [valuePrimitive]="true"
                          name="filial"
                          (valueChange)="applyFilter()"
                          [(ngModel)]="secondFilter.filialExternalId"
                          [defaultItem]="defaultItemFilial"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
    
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Уровень подготовки
      </span>
      <kendo-dropdownlist [data]="traininglevels"
                          textField="trainingLevelName"
                          valueField="trainingLevelExternalId"
                          [valuePrimitive]="true"
                          name="trainingLevel"
                          [(ngModel)]="secondFilter.trainingLevelExternalId"
                          [defaultItem]="defaultItemTrainingLevel"
                          (valueChange)="applyFilter()"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
    
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Тип стандарта
      </span>
      <kendo-dropdownlist [data]="standardTypes"
                          textField="standardTypeShortName"
                          valueField="standardTypeExternalId"
                          [valuePrimitive]="true"
                          name="standardType"
                          (valueChange)="applyFilter()"
                          [(ngModel)]="secondFilter.standardTypeExternalId"
                          [defaultItem]="defaultItemStandardType"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
  </div>

  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" class="addButton" (click)="addHandler()" kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid
            [data]="workStudyPlanTypesByFilter"
            [kendoGridBinding]="workStudyPlanTypesByFilter"
            [navigable]="true"
            [sortable]="true"
            [filterable]="true"
            [height]="600"
            [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictWorkStudyPlanTypeName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" style="text-align: left; justify-content: left;">
            {{dataItem.dictWorkStudyPlanTypeName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictWorkStudyPlanTypeShortName" title="Сокр.название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictWorkStudyPlanTypeShortName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictFormDesignerDataType.dictFormDesignerDataTypeName" title="Тип данных">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="formDesignerDataTypes" textField="dictFormDesignerDataTypeName"
                           valueField="dataType">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.dictFormDesignerDataType?.dictFormDesignerDataTypeName}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="classroomWork" title="Аудиторная работа">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="classroomWork" textField="name"
                           valueField="classroomWork">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.classroomWork">
            <div *ngSwitchCase="true">Да</div>
            <div *ngSwitchCase="false">Нет</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="isRecognizingAmounts" title="Учитывать в суммах">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="isRecognizingAmounts" textField="name"
                           valueField="isRecognizingAmounts">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.isRecognizingAmounts">
            <div *ngSwitchCase="true">Да</div>
            <div *ngSwitchCase="false">Нет</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <!--      <kendo-grid-column [hidden]="true" headerClass="gridHeader dictionaries" field="dictTrainingLevel.trainingLevelName" title="Уровень подготовки">-->
      <!--        <ng-template kendoGridFilterCellTemplate let-filter>-->
      <!--          <filter-dropdown [filter]="filter" [data]="traininglevels" textField="trainingLevelName"-->
      <!--            valueField="trainingLevelExternalId">-->
      <!--          </filter-dropdown>-->
      <!--        </ng-template>-->
      <!--        <ng-template kendoGridCellTemplate let-dataItem>-->
      <!--          <span class="alignCells">-->
      <!--            {{dataItem.dictTrainingLevel?.trainingLevelName}}-->
      <!--          </span>-->
      <!--        </ng-template>-->
      <!--      </kendo-grid-column>-->

      <kendo-grid-column headerClass="gridHeader dictionaries" field="serialNumber" title="Порядковый номер">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
            [column]="column"
            [filter]="filter"
            [showOperators]="false"
            operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.serialNumber}}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton (click)="editHandler(dataItem)" type="button" class="editButton">Изменить</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictWorkStudyPlanTypeName" #dictWorkStudyPlanTypeName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="dictWorkStudyPlanTypeShortName" #dictWorkStudyPlanTypeShortName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Тип данных</h2>
          <kendo-dropdownlist
                  [data]="formDesignerDataTypes"
                  textField="dictFormDesignerDataTypeName"
                  valueField="dataType"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['dataType']"
                  formControlName="dataType" #dataType required>
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <!--    <kendo-formfield>-->
      <!--      <div class="formRow">-->
      <!--        <h2>Уровень подготовки</h2>-->
      <!--        <kendo-dropdownlist-->
      <!--                [data]="traininglevels"-->
      <!--                textField="trainingLevelName"-->
      <!--                valueField="trainingLevelExternalId"-->
      <!--                [valuePrimitive]="true"-->
      <!--                [formControl]="$any(editForm).controls['trainingLevelExternalId']"-->
      <!--                formControlName="trainingLevelExternalId" #trainingLevelExternalId required>-->
      <!--        </kendo-dropdownlist>-->
      <!--      </div>-->
      <!--      <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>-->
      <!--    </kendo-formfield>-->
      <kendo-formfield>
        <div class="formRow">
          <h2 class="checkbox">
            Аудиторная работа
          </h2>
          <div>
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="classroomWork" #classroomWork/>
          </div>
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2 class="checkbox">
            Учитывать в суммах
          </h2>
          <div>
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="isRecognizingAmounts" #isRecognizingAmounts/>
          </div>
        </div>
      </kendo-formfield>

      <kendo-formfield>
        <div class="formRow">
          <h2>Порядковый номер</h2>
          <kendo-numerictextbox formControlName="serialNumber" format="n0" [min]="1" [decimals]="0" #dictCycleTypeSerialNumber></kendo-numerictextbox>
        </div>
      </kendo-formfield>

      <div class="buttons">
        <button kendoButton (click)="onCancel()" type="button" class="cancelButton">Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveWorkStudyPlanType()">Сохранить</button>
      </div>
    </form>
  </div>
</div>

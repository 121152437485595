<kendo-stacklayout [gap]="35" orientation="vertical">
  <educational-process-schedule-table
    *ngIf="!showTableSettings"
    (toggleShowSettingsChange)="toggleShowTableSettings($event)"
    [educationPlanId]="educationPlanId"
    [educationPlanStatus]="educationPlanStatus"
    [isLoading]="isLoading"
  ></educational-process-schedule-table>
  <educational-process-schedule-settings
    *ngIf="showTableSettings"
    [educationPlanId]="educationPlanId"
    [yearAdmission]="yearAdmission"
    (onHide)="toggleShowTableSettings(false)"
  >
  </educational-process-schedule-settings>
  <app-educational-process-schedule-statistic
    *ngIf="!showTableSettings"
  ></app-educational-process-schedule-statistic>
  <app-educational-process-schedule-legend
    *ngIf="!showTableSettings"
    [educationPlanId]="educationPlanId"
  ></app-educational-process-schedule-legend>
</kendo-stacklayout>

<education-header></education-header>
<title>Тип компонента</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Тип компонента</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новый тип компонента' : 'Изменить тип компонента' }}
  </h1>
  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" kendoButton class="addButton" (click)="addHandler()" [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid #grid
                [data]="componentTypes"
                [kendoGridBinding]="componentTypes"
                [navigable]="true"
                [sortable]="true"
                [filterable]="true"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictComponentTypeName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" style="text-align: left; justify-content: left;">
            {{dataItem.dictComponentTypeName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictComponentTypeSerialNumber" title="Порядковый номер">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictComponentTypeSerialNumber}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="Команды" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton (click)="editHandler(dataItem)" class="editButton">Изменить</button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictComponentTypeName" [maxlength]="255" #dictComponentTypeName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Порядковый номер</h2>
          <kendo-numerictextbox formControlName="dictComponentTypeSerialNumber" format="n0" [min]="1" [decimals]="0" #dictComponentTypeSerialNumber></kendo-numerictextbox>
        </div>
    <!--    <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>-->
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton type="button" (click)="onCancel()">Отменить</button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="saveComponentType()">Сохранить</button>
      </div>
    </form>
  </div>
</div>
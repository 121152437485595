import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Location} from "@angular/common";
import {lastValueFrom} from "rxjs";
import {Role} from "../models/useraccess/role";
import {OfertaUserAccessService} from "../services/useraccess/oferta-user-access.service";

@Injectable({
  providedIn: 'root'
})
export class OfertaUserAccessGuard implements CanActivate {

  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: OfertaUserAccessService,
    private location: Location,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const token = this.tokenStore.getAuthToken()
    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;

      if (!this.userAccessService.currentUserAccess$.value) {
        await lastValueFrom(this.userAccessService.getCurrentUserAccess()).then(value => {
          this.userAccessService.currentUserAccess$.next(value);
        });
      }

      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)) {
        return true;
      } else {
        if (this.userAccessService.currentUserAccess$.value?.userAccessRight.accessSettings) {
          return true;
        }
        alert('Недостаточно прав для доступа на эту страницу');
        localStorage.setItem('last_url', this.location.path());
        this.router.navigate(['/profile']);
        return false;
      }
    }
    localStorage.setItem('last_url', this.location.path());
    return false;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import { environment } from '../../../../environments/environment';
import {Persons} from "../../../models/education/person.model";

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.person}`;

  constructor(private http: HttpClient) {
  }

  public getAllPerson(): Observable<Persons[]> {
    return this.http.get<Persons[]>(this.baseUrl)      .pipe(
      catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Faculty } from 'src/app/models/education/faculty.model';
import { Kafedra } from 'src/app/models/education/kafedra.model';
import { FacultyService } from 'src/app/services/education/Faculty/faculty.service';
import { KafedraService } from 'src/app/services/education/Kafedra/kafedra.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { KafedraComponent } from '../kafedra.component';

@Component({
  selector: 'kendo-grid-kafedra-edit-form',
  templateUrl: './kafedra-edit-form.component.html',
  styleUrls: ['./kafedra-edit-form.component.css']
})
export class KafedraEditFormComponent implements OnInit {

  public faculties!: Faculty[];
  public faculty: Faculty = {
    id: 0,
    facultyName: '',
    facultyShortName: '',
    facultyId: ''
  }

  public kafedras!: Kafedra[];
  public kafedra: Kafedra = {
    id: 0,
    kafedraName: '',
    kafedraShortName: '',
    facultyId: 0,
    faculty: this.faculty,
    kafedraId: ''
  }

  public active = false;

  // Edit Form
  public editForm: FormGroup = new FormGroup({
    outerDataKafedraId: new FormControl(),
    outerDataKafedraName: new FormControl(),
    outerDataKafedraSName: new FormControl(),
    outerDataFacultyId: new FormControl()
  });

  @Input() public isNew = false;

  @Input() public set model(kafedra: Kafedra) {
    this.editForm.reset(kafedra);

    // toggle the Dialog visibility
    this.active = kafedra !== undefined;
  }

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<Kafedra> = new EventEmitter();

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.save.emit(this.editForm.value);
    this.saveKafedra();
    this.active = false;
  }

  constructor(private kafedraService: KafedraService,
    private kafedraComponent: KafedraComponent,
    private notificationService: NotificationsService,
    private facultyService: FacultyService) { }

  //Getting all entries from kafedra
  public getAllKafedras() {
    this.kafedraService.getAllKafedras()
      .subscribe(
        response => {
          this.kafedras = response;
        }
      );
  }

  //Getting all entries from kafedra
  public getAllFaculties() {
    this.facultyService.getAllFaculties()
      .subscribe(
        response => {
          this.faculties = response;
        }
      );
  }

  public saveKafedra() {
    // Add new kafedra
    if (this.isNew == true) {
      this.kafedraService.addKafedra(this.editForm?.value)
        .subscribe(
          response => {
            this.notificationService.showSuccess("Кафедра была успешно добавлена!")
            this.kafedraComponent.update();
          },
          error => {
            this.notificationService.showError("Не удалось добавить кафедру");
          }
        );

    }
    // Edit kafedra
    else {
      this.kafedraService.updateKafedra(this.editForm?.value)
        .subscribe(
          response => {
            this.notificationService.showSuccess("Кафедра была успешна сохранена!")
            this.kafedraComponent.update();
          },
          error => {
            this.notificationService.showError('Не удалось изменить кафедру');
          }
        )

    }
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
    this.kafedraComponent.update();
  }

  ngOnInit(): void {
    this.getAllKafedras();
    this.getAllFaculties();
  }

}

export const items = [
  {
    text: "Настройки",
    icon: "cog",
    items: [
      {
        text: "Настройки присоединения к положениям",
        path: "/oferta/regulationsettings",
        regulation: true
      },
      {
        text: "Справочники",
        items: [
          {
            text: "Типы положений",
            path: "/oferta/dictregulationtype",
          }
        ],
        dict: true
      },
      {
        text: "Настройки доступа",
        path: "/oferta/ofertaUserAccess",
        accessSettings: true
      }
    ]
  }
];

import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { StudyTechnologyService } from 'src/app/services/dicts/studytechnology.service';
import { StudyTechnology } from 'src/app/models/dicts/studytechnology.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Guid } from 'guid-typescript';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { Router} from "@angular/router";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-studytechnology',
  templateUrl: './studytechnology.component.html',
  styleUrls: ['./studytechnology.component.css']
})
export class StudytechnologyComponent implements OnInit {

  public studytechnologies: StudyTechnology[] = [];
  public studytechnology: StudyTechnology = {
    dictStudyTechnologyId: 0,
    studyTechnologyName: '',
    studyTechnologySName: '',
    semesterName: '',
    semesterSName: '',
    semesterNumberPerYear: 0,
    dictStudyTechnologyExternalId: Guid.createEmpty()
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  public gridView: any | GridDataResult;

  constructor(private studytechnologyService: StudyTechnologyService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private userAccessService: CommonDictionariesUserAccessService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private router: Router,
  ) {

  }

  public ngOnInit(): void {
    this.getAccessLevel()
   // this.getAllStudyTechnology();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadTechnology();
  }

  private loadTechnology(): void {
    this.gridView = {
      data: orderBy(this.studytechnologies, this.sort),
      total: this.studytechnologies.length,
    };
  }

  public onStateChange(): void {
    this.getAllStudyTechnology();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      dictStudyTechnologyId: 0,
      studyTechnologyName: '',
      studyTechnologySName: '',
      semesterName: '',
      semesterSName: '',
      semesterNumberPerYear: 0,
      orderNum: null,
      dictStudyTechnologyExternalId: Guid.createEmpty()
    });

    this.isNew = true;
    this.isLine = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        if (this.formGroup.value.orderNum == "") this.formGroup.value.orderNum = null;
        this.studytechnologyService.addStudyTechnology(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllStudyTechnology();
              this.notificationService.showSuccess("Добавлено");
              this.studytechnology = {
                dictStudyTechnologyId: 0,
                studyTechnologyName: '',
                studyTechnologySName: '',
                semesterName: '',
                semesterSName: '',
                semesterNumberPerYear: 0,
                dictStudyTechnologyExternalId: Guid.createEmpty()
              }
            },
            error:() => {
              this.notificationService.showError("Не удалось добавить запись");
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        if (this.formGroup.value.orderNum == "") this.formGroup.value.orderNum = null;
        this.studytechnologyService.updateStudyTechnology(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllStudyTechnology();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError("Не удалось изменить запись");
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllStudyTechnology() {
    this.studytechnologyService.getAllStudyTechnology()
      .subscribe(
        response => {
          this.studytechnologies = response;
          this.loadTechnology();
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelEducation == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelEducation != 1;
            this.getAllStudyTechnology();
          }
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.studyTechnologyName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {

        if(result.text == "Да"){
          this.opened = false;
          this.studytechnologyService.deleteStudyTechnology(dataItem.dictStudyTechnologyExternalId)
          .subscribe({
            next:() => {
              this.getAllStudyTechnology();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { dictStudyTechnologyId?: any;
                                studyTechnologyName?: any;
                                studyTechnologySName?: any; 
                                semesterName?: any; 
                                semesterSName?: any; 
                                semesterNumberPerYear?: any; 
                                dictStudyTechnologyExternalId?: any;
                                orderNum?: any;}) =>
  new FormGroup({
    dictStudyTechnologyId: new FormControl(dataItem.dictStudyTechnologyId),
    studyTechnologyName: new FormControl(dataItem.studyTechnologyName, Validators.required),
    studyTechnologySName: new FormControl(dataItem.studyTechnologySName, Validators.required),
    semesterName: new FormControl(dataItem.semesterName, Validators.required),
    semesterSName: new FormControl(dataItem.semesterSName),
    semesterNumberPerYear: new FormControl(dataItem.semesterNumberPerYear, Validators.required),
    dictStudyTechnologyExternalId: new FormControl(dataItem.dictStudyTechnologyExternalId),
    orderNum: new FormControl(dataItem.orderNum, [Validators.min(1), Validators.pattern("^[0-9]+$")]),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

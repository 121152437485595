import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, map } from "rxjs/operators";
import { Qualification } from 'src/app/models/education/qualification.model';

@Injectable({
  providedIn: 'root'
})
export class QualificationService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.qualification}`;

  constructor(private http: HttpClient) { }

  //Get all qualifications
  public getAllQualifications(): Observable<Qualification[]> {
    return this.http.get<Qualification[]>(this.baseUrl + '/GetQualifications');
  }
}

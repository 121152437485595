import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Faculty} from "../../../models/mfc/dicts/faculty.model";
import {map} from "rxjs/operators";
import {FilialTree} from "../../../models/mfc/dicts/filial.model";

@Injectable({
  providedIn: 'root'
})
export class FacultyService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.externalDict}/Faculty/`;

  constructor(private http: HttpClient) {}

  public getFaculties() {
    return this.http.get<Faculty[]>(this.baseUrl);
  }

  public getFaculty(id: string) {
    return this.http.get<Faculty>(`${this.baseUrl}${id}`);
  }

  // Получить все факультеты, отсортированные по филиалам
  public getFilialsAndFaculties() {
    return this.http.get<FilialTree>(`${this.baseUrl}GetFilialsAndFaculties`)
      .pipe(map(data => data.filials));
  }
}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AlignSettings} from '@progress/kendo-angular-layout';
import {DictIdentificationNumber} from 'src/app/models/person/dicts/dictIdentificationNumber.model';
import {DictSocialNetwork} from 'src/app/models/person/dicts/dictSocialNetwork.model';
import {LKPerson} from 'src/app/models/person/lkPerson.model';
import {LKPersonAcademicStatus} from 'src/app/models/person/lkPersonAcademicStatus.model';
import {LKPersonEducation} from 'src/app/models/person/lkPersonEducation.model';
import {LKPersonEmail} from 'src/app/models/person/lkPersonEmail.model';
import {LKPersonExperience} from 'src/app/models/person/lkPersonExperience.model';
import {LKPersonIdentificationNumber} from 'src/app/models/person/lkPersonIdentificationNumber.model';
import {LKPersonPhoneNumber} from 'src/app/models/person/lkPersonPhoneNumber.model';
import {LKPersonQualificationAdvancement} from 'src/app/models/person/lkPersonQualificationAdvancement.model';
import {LKPersonSalary} from 'src/app/models/person/lkPersonSalary.model';
import {LKPersonScientificDegree} from 'src/app/models/person/lkPersonScientificDegree.model';
import {LKPersonSocialNetworks} from 'src/app/models/person/lkPersonSocialNetworks.model';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {DictIdentificationNumberService} from 'src/app/services/person/dicts/dict-identification-number.service';
import {DictSocialNetworkService} from 'src/app/services/person/dicts/dict-social-network.service';
import {LKPersonAcademicStatusService} from 'src/app/services/person/lkperson-academic-status.service';
import {LKPersonEducationService} from 'src/app/services/person/lkperson-education.service';
import {LKPersonEmailService} from 'src/app/services/person/lkperson-email.service';
import {LKPersonExperienceService} from 'src/app/services/person/lkperson-experience.service';
import {LKPersonIdentificationNumberService} from 'src/app/services/person/lkperson-identification-number.service';
import {LKPersonPhoneNumberService} from 'src/app/services/person/lkperson-phone-number.service';
import {
    LKPersonQualificationAdvancementService
} from 'src/app/services/person/lkperson-qualification-advancement.service';
import {LKPersonSalaryService} from 'src/app/services/person/lkperson-salary.service';
import {LKPersonScientificDegreeService} from 'src/app/services/person/lkperson-scientific-degree.service';
import {LKPersonSocialNetworksService} from 'src/app/services/person/lkperson-social-networks.service';
import {PersonService} from 'src/app/services/person/lkperson.service';
import {AuthService} from "../../../services/auth.service";
import {DatePipe} from "@angular/common";
import {environment} from "../../../../environments/environment";
import {GridDataResult} from '@progress/kendo-angular-grid';
import {DateFromUTCAsLocal, DateToString} from "../../../helpers/date-helper";
import {UserSignatureService} from "../../../services/oferta/user-signature.service";
import {UserSignatureInfo} from "../../../models/oferta/usersignature/userSignatureInfo.model";
import {RegulationFileService} from "../../../services/oferta/regulationFile.service";
import {GetRegulationFile} from "../../../models/oferta/regulationFile/getRegulationFile.model";
import {saveAs} from "@progress/kendo-file-saver";

@Component({
  selector: 'app-person-profile',
  templateUrl: './person-profile.component.html',
  styleUrls: ['./person-profile.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PersonProfileComponent implements OnInit {

  yearConversion: any = {
     1: 'год',  2: 'года',  3: 'года',  4: 'года',
    21: 'год', 22: 'года', 23: 'года', 24: 'года',
    31: 'год', 32: 'года', 33: 'года', 34: 'года',
    41: 'год', 42: 'года', 43: 'года', 44: 'года',
    51: 'год', 52: 'года', 53: 'года', 54: 'года',  };
  monthConversion: any = {1: 'месяц', 2: 'месяца', 3: 'месяца', 4: 'месяца', 5: 'месяцев', 6: 'месяцев', 7: 'месяцев', 8: 'месяцев', 9: 'месяцев',
   10: 'месяцев', 11: 'месяцев', 12: 'месяцев'}

  public datepipe: DatePipe = new DatePipe('ru-Ru')
  // Для удаления
  public opened = false;
  public identificationOpened = false;
  private socialNetworkId = 0;
  private identificationNumberId = 0;

  public qualificationsPageSize = 5;
  public educationsPageSize = 5;
  public skip = 0;
  public allQualifications = false;
  public allEducations = false;

  public photoUrl = ''
  // Режим редактирования
  public socialNetworkEditMode = false;
  public identificationNumberEditMode = false;

  // Режим добавления
  public socialNetworkAddMode = false;
  public identificationNumberAddMode = false;

  public supportEmail = environment.supportEmail;

  // Должности
  public posts = ""

  public align: AlignSettings = {vertical: "top"}
  public personId = "";

  public academicStatuses: LKPersonAcademicStatus[] = [];
  public emails: LKPersonEmail[] = [];
  public phoneNumbers: LKPersonPhoneNumber[] = [];
  public salaries: LKPersonSalary[] = [];
  public salary?: LKPersonSalary;
  public scientificDegrees: LKPersonScientificDegree[] = [];
  public experiences: LKPersonExperience[] = [];

  public qualificationsGridView!: GridDataResult;
  public educationGridView!: GridDataResult;

  public person: LKPerson = {
    personId: 0,
    login: "",
    personExternalId: "personExternalId",
    fullName: "fullName",
    firstName: "fistName",
    lastName: "lastName",
    middleName: "middleName",
    birthday: new Date(),
    hasPps: false,
    remainingVacationDaysNumber: null,
  }

  public dictIdentificationNumbers?: DictIdentificationNumber[];
  public identificationNumbers: LKPersonIdentificationNumber[] = [];
  public dictSocialNetworks!: DictSocialNetwork[];
  public socialNetworks!: LKPersonSocialNetworks[];
  public educations!: LKPersonEducation[];
  public userSignaturesInfo!: UserSignatureInfo[];
  public qualifications!: LKPersonQualificationAdvancement[];

  public deleteForm: FormGroup = new FormGroup({
    personId: new FormControl(),
    socialNetWork: new FormControl(),
    socialNetworkName: new FormControl()
  });

  public socialNetworksForm: FormGroup = new FormGroup({
    socialNetworkId: new FormControl(),
    socialNetworkExternalId: new FormControl(),
    personId: new FormControl(),
    person: new FormControl(),
    dictSocialNetworkId: new FormControl('', Validators.required),
    dictSocialNetwork: new FormControl(),
    socialNetwork: new FormControl('', Validators.required)
  });

  public identificationNumberForm: FormGroup = new FormGroup({
    id: new FormControl(),
    externalId: new FormControl(),
    personId: new FormControl(),
    person: new FormControl(),
    dictIdentificationNumberId: new FormControl('', Validators.required),
    dictIdentificationNumber: new FormControl(),
    identificationValue: new FormControl('', Validators.required)
  });

  constructor(private personService: PersonService,
    private personEducationService: LKPersonEducationService,
    private userSignatureService: UserSignatureService,
    private regulationFileService: RegulationFileService,
    private personQualificationService: LKPersonQualificationAdvancementService,
    private personAcademicStatusService: LKPersonAcademicStatusService,
    private personEmailService: LKPersonEmailService,
    private personPhoneNumberService: LKPersonPhoneNumberService,
    private personSalaryService: LKPersonSalaryService,
    private personScientificDegreeService: LKPersonScientificDegreeService,
    private personExperienceService: LKPersonExperienceService,
    private socialNetworksService: LKPersonSocialNetworksService,
              private authService :AuthService,
              private dictSocialNetworksService: DictSocialNetworkService,
              private dictIdentificationNumberService: DictIdentificationNumberService,
              private identificationNumberService: LKPersonIdentificationNumberService,
              private notificationsService: NotificationsService) {}

  //Getting person info by login
  public getCurrentPerson() {
    this.personService.getCurrentPerson()
      .subscribe(
        response => {
          this.person = response;
          this.personId = this.person.personExternalId;
          this.photoUrl = `url('${environment.apiEndpoint}lkperson/profile/GetPersonPhoto/${this.person.personExternalId}')`;
          this.getQualificationsByPersonId(`${this.personId}`);
          this.getEducationsByPersonId(`${this.personId}`);
          this.getAcademicStatusesByPersonId(`${this.personId}`);
          this.getEmailsByPersonId(`${this.personId}`);
          this.getPhoneNumbersByPersonId(`${this.personId}`);
          this.getSalariesByPersonId(`${this.personId}`);
          this.getScientificDegreesByPersonId(`${this.personId}`);
          this.getExperiencesByPersonId(`${this.personId}`);
          this.getSocialNetworksByPersonId(`${this.personId}`);
          this.getIdentificationNumbersByPersonId(`${this.personId}`);
          this.getAllUserSignaturesInfo(`${this.personId}`);
        }
      );
  }

  public formatSalaryInfo(item: any) {
    const postDepartment = `${item.post}, ${item.department}`;
    const employmentDate = DateToString(item.employmentDate, 'dd.MM.yyyy')
    const contractTerm = DateToString(item.contractTerm, 'dd.MM.yyyy')
    let dateStr = "";
    if (item.employmentDate != null)
      dateStr+=`c ${employmentDate}`
    if (item.contractTerm != null)
      dateStr+=` по ${contractTerm}`

    if(dateStr.trim() != "")
      dateStr = " (" + dateStr.trim() + ")"

    return postDepartment + dateStr;
  }

  public getAllUserSignaturesInfo(personId: string) {
    this.userSignatureService.getAllUserSignaturesInfo(personId)
      .subscribe((
        response => {
          this.userSignaturesInfo = response;
          this.userSignaturesInfo.map((item) => {
            item.dateUntilBlocking = item.dateUntilBlocking != null ? new Date(item.dateUntilBlocking) : DateFromUTCAsLocal(item.dateUntilBlocking);
            item.joinDate = item.joinDate != null ? new Date(item.joinDate) : DateFromUTCAsLocal(item.joinDate);
          })
        }
      ))
  }

  public toLocalDatetime(dt: Date) {
    return dt != undefined ? DateToString(dt,'dd.MM.yyyy HH:mm') : '-';
  }

  public calculateDateUntilBlocking(endDate: Date | null) {
    const startDate = new Date(); // Текущее время

    if (!endDate) {
      return '0';
    }

    // Преобразуем строки в объекты Date, если они еще не являются объектами Date
    const endDateObj = typeof endDate === 'string' ? new Date(Date.parse(endDate)) : endDate;

    const differenceInTime = endDateObj.getTime() - startDate.getTime();
    const millisecondsInADay = 1000 * 3600 * 24;
    const formattedEndDate = `(${DateToString(endDateObj, 'dd.MM.yyyy HH:mm')})`;

    // Если разница во времени отрицательная, возвращаем 0
    if (differenceInTime < 0) {
      return `0 ${formattedEndDate}`;
    }

    // Если разница во времени меньше 24 часов (в миллисекундах), возвращаем '<1'
    if (differenceInTime < millisecondsInADay) {
      return `< 1 ${formattedEndDate}`;
    }

    // Если разница во времени больше или равна 24 часам, возвращаем количество дней
    const differenceInDays = Math.ceil(differenceInTime / millisecondsInADay);
    return `${differenceInDays.toString()} ${formattedEndDate}`;
  }

  private getFileObserver(fileName?: string) {
    return {
      next: (response: Blob) => fileName && saveAs(response, fileName),
      error: (error: unknown) => this.notificationsService.showError(error),
    };
  }

  public getRegulationFile(file?: GetRegulationFile) {
    file && this.regulationFileService.getRegulationFile(file.id)
      .subscribe(this.getFileObserver(file.fileName));
  }

  //Getting educations info by personID
  public getEducationsByPersonId(personId: string) {
    this.personEducationService.getLKPersonEducationByPersonId(personId)
      .subscribe(
        response => {
          this.educations = response;
          if(this.educations.length < this.educationsPageSize) {
            this.allEducations = true;
          }
          this.educationGridView = {
            data: this.educations.slice(this.skip, this.skip + this.educationsPageSize),
            total: this.educations.length,
          };
        }
      );
  }

  //Getting qualification info by personID
  public getQualificationsByPersonId(personId: string) {
    this.personQualificationService.getQualificationAdvancementsByPersonID(personId)
      .subscribe(
        response => {
          this.qualifications = response;
          if(this.qualifications.length < this.qualificationsPageSize) {
            this.allQualifications = true;
          }
          this.qualificationsGridView = {
            data: this.qualifications.slice(this.skip, this.skip + this.qualificationsPageSize),
            total: this.qualifications.length,
          };
          //console.log("Qualifications", this.qualifications);
        },
        error => {
          this.allQualifications = true;
        }
      );
  }

  //Getting academic statuses by personID
  public getAcademicStatusesByPersonId(personId: string) {
    this.personAcademicStatusService.getAcademicStatusesByPersonId(personId)
      .subscribe(
        response => {
          this.academicStatuses = response;
        }
      );
  }

  //Getting emails by personID
  public getEmailsByPersonId(personId: string) {
    this.personEmailService.getEmailsByPersonId(personId)
      .subscribe(
        response => {
          this.emails = response;
        }
      );
  }

  //Getting phone numbers by personID
  public getPhoneNumbersByPersonId(personId: string) {
    this.personPhoneNumberService.getPhoneNumbersByPersonId(personId)
      .subscribe(
        response => {
          this.phoneNumbers = response;
        }
      );
  }

  //Getting salaries by personID
  public getSalariesByPersonId(personId: string) {
    this.personSalaryService.getSalariesByPersonId(personId)
      .subscribe(
        response => {
          this.salary = response[0];
          this.salaries = response;
          this.salaries.forEach(element => {
            this.posts += element.post + ", ";
          });
          this.posts = this.posts.slice(0, -2)
        }
      );
  }

  //Getting scientific degrees by personID
  public getScientificDegreesByPersonId(personId: string) {
    this.personScientificDegreeService.getScientificDegreesByPersonId(personId)
      .subscribe(
        response => {
          this.scientificDegrees = response;
        }
      );
  }

  //Getting experiences by personID
  public getExperiencesByPersonId(personId: string) {
    this.personExperienceService.getExperiencesByPersonId(personId)
      .subscribe(
        response => {
          this.experiences = response;
        }
      );
  }

  //Getting social networks by personID
  public getSocialNetworksByPersonId(personId: string) {
    this.socialNetworksService.getSocialNetworksByPersonId(personId)
      .subscribe(
        response => {
          this.socialNetworks = response;
        }
      );
  }

  //Getting identification numbers by personID
  public getIdentificationNumbersByPersonId(personId: string) {
    this.identificationNumberService.getIdentificationNumbersByPersonId(personId)
      .subscribe(
        response => {
          this.identificationNumbers = response;
        }
      );
  }

  // Getting dict social networks
  public getDictSocialNetworks() {
    this.dictSocialNetworksService.getDictSocialNetwork()
    .subscribe(
      response => {
        this.dictSocialNetworks = response;
      }
    );
  }

  public getDictIdentificationNumbers() {
    this.dictIdentificationNumberService.getDictIdentificationNumbers()
    .subscribe(
      response => {
        this.dictIdentificationNumbers = response;
      }
    );
  }

  //Add social network
  public addSocialNetwork() {
    if(this.urlIsValid(this.socialNetworksForm.value.socialNetwork))
    {
      this.socialNetworksForm.value.personId = this.person.personExternalId;
      this.socialNetworksService.addSocialNetwork(this.socialNetworksForm?.value)
        .subscribe(
          response => {
            this.notificationsService.showSuccess("Социальная сеть успешно сохранена!");
            this.socialNetworksForm.reset();
            this.getSocialNetworksByPersonId(`${this.personId}`);
            this.socialNetworkAddMode = false;
          },
          error => {
            this.notificationsService.showError("Не удалось сохранить социальную сеть");
            this.socialNetworkAddMode = false;
          }
        )
    }
    else {
      this.notificationsService.showError("Неверный формат ссылки");
    }
  }

  //Add identification number
  public addIdentificationNumber() {
    if(this.urlIsValid(this.identificationNumberForm.value.identificationValue)) {
      this.identificationNumberForm.value.personId = this.person.personExternalId;
      this.identificationNumberService.addIdentificationNumber(this.identificationNumberForm?.value)
        .subscribe(
          response => {
            this.notificationsService.showSuccess("Идентификационный номер успешно сохранен!");
            this.identificationNumberForm.reset();
            this.getIdentificationNumbersByPersonId(`${this.personId}`);
            this.identificationNumberAddMode = false;
          },
          error => {
            this.notificationsService.showError("Не удалось сохранить индентификационный номер");
            this.identificationNumberAddMode = false;
          }
        )
    }
    else {
      this.notificationsService.showError("Неверный формат ссылки");
    }
  }

  // Update identification number
  public updateIdentificationNumber() {
    this.identificationNumberForm.value.personId = this.person.personExternalId;
    for (const i of this.identificationNumbers)
    {
      this.identificationNumberService.updateIdentificationNumber(i)
      .subscribe(
        response => {
          this.getIdentificationNumbersByPersonId(`${this.personId}`);
        }
      )
    };
    //this.notificationsService.showSuccess("Идентификационные номера успешно изменены!");
  }

  public urlIsValid(url: string): boolean {
    const reg = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return reg.test(url);
  }

  // Update social networks
  public updateSocialNetworks() {
    for (var i of this.socialNetworks)
    {
      this.socialNetworksService.updateSocialNetwork(i)
      .subscribe(
        response => {
          this.getSocialNetworksByPersonId(`${this.personId}`);
        }
      )
    };
    //this.notificationsService.showSuccess("Социальные сети успешно изменены!");
  }

  // Delete social network
  public deleteSocialNetwork() {
    this.socialNetworksService.deleteSocialNetwork(this.socialNetworkId)
    .subscribe(
      response => {
        this.notificationsService.showSuccess("Социальная сеть успешно удалена!");
        this.getSocialNetworksByPersonId(`${this.personId}`);
        this.opened = false;
      },
      error => {
        this.notificationsService.showError("Не удалось удалить социальную сеть");
        this.opened = false;
      }
    )
  }

  // Delete identification number
  public deleteIdentificationNumber() {
    this.identificationNumberService.deleteIdentificationNumber(this.identificationNumberId)
    .subscribe(
      response => {
        this.notificationsService.showSuccess("Идентификационный номер успешно удален!");
        this.getIdentificationNumbersByPersonId(`${this.personId}`);
        this.identificationOpened = false;
      },
      error => {
        this.notificationsService.showError("Не удалось удалить идентификационный номер");
        this.identificationOpened = false;
      }
    )
  }

  public socialNetworkAddModeEvent() {
    this.socialNetworkAddMode = !this.socialNetworkAddMode;
  }

  public identificationNumberAddModeEvent() {
    this.identificationNumberAddMode = !this.identificationNumberAddMode;
  }

  public open(item: any) {
    //console.log("Opened:", item);
    this.socialNetworkId = item.socialNetworkId;
    this.opened = true;
  }

  public openIdentification(item: any) {
    this.identificationNumberId = item.id;
    this.identificationOpened = true;
    //console.log("Opened:", item);
  }

  public close() {
    this.opened = false;
    this.identificationOpened = false;
    this.identificationNumberEditMode = false;
  }

  public editMode() {
    this.socialNetworkEditMode = !this.socialNetworkEditMode;
    this.identificationNumberEditMode = !this.identificationNumberEditMode;
  }

  public cancelEvent() {
    this.socialNetworkEditMode = false;
    this.identificationNumberEditMode = false;
    this.socialNetworkAddMode = false;
    this.identificationNumberAddMode = false;
    this.identificationNumberForm.reset();
    this.socialNetworksForm.reset();
    this.getIdentificationNumbersByPersonId(`${this.personId}`);
    this.getSocialNetworksByPersonId(`${this.personId}`);
  }

  public cancelSocialAddForm() {
    this.socialNetworkAddMode = false;
    this.socialNetworksForm.reset();
  }

  public cancelIdentificationAddForm() {
    this.identificationNumberAddMode = false;
    this.identificationNumberForm.reset();
  }

  public saveItems() {
    if (this.identificationNumbers.every(i => this.urlIsValid(i.identificationValue))
        && this.socialNetworks.every(i => this.urlIsValid(i.socialNetwork!)))
    {
      this.updateIdentificationNumber();
      this.updateSocialNetworks();
      this.identificationNumberEditMode = false;
      this.socialNetworkEditMode = false;
      this.identificationNumberAddMode = false;
      this.socialNetworkAddMode = false;
      this.notificationsService.showSuccess("Данные успешно сохранены!");
    }
    else
    {
      this.notificationsService.showError("Неверный формат ссылки");
    }
  }

  public mask: any = {
    mask: '+{7} (000) 000-00-00',
    lazy: false
  };

  showAllQualificationData() {
    this.allQualifications = true;
    this.qualificationsGridView = {
      data: this.qualifications,
      total: this.qualifications.length,
    };
  }

  showAllEducationData() {
    this.allEducations = true;
    this.educationGridView = {
      data: this.educations,
      total: this.educations.length
    }
  }

  groupQualificationDocument(qualificationAdvancementDocument: any, docSerial: any, docNumber: any, docDate: any) {
      let str = '';
      if(qualificationAdvancementDocument != null)
          str += qualificationAdvancementDocument;
      if(docSerial !== '')
          str += ", cерия " + docSerial;
      if(docNumber !== '')
          str += ", номер " + docNumber;
      if(docDate !== '')
          str += ", дата выдачи " + DateToString(docDate);
      return str;
  }

  groupQualificationPeriod(periodBegin: any, periodEnd: any) {
      let str = '';
      if (periodBegin != null)
          str += DateToString(periodBegin) + ' - ';
      else {
          str += 'н/у' + ' - '
      }
      if(periodEnd)
          str += DateToString(periodEnd);
      else {
          str += 'н/у'
      }
      return str;
  }

  phoneFormat(input:any) {
    if(input.startsWith(9))
    {
     input = "+7" + input;
    }
    if(input.startsWith(8))
    {
     input = input.substring(1);
     input = "+7" + input;
    }
    return input;
  }

  ngOnInit(): void {
    if (this.authService.isUserAuthenticated()) {
      this.getCurrentPerson()
      this.getDictSocialNetworks();
      this.getDictIdentificationNumbers();
    }
  }
}

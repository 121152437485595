import { Component, Input, OnInit } from '@angular/core';
import { PagerPosition } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { groupBy, GroupDescriptor, GroupResult } from '@progress/kendo-data-query';
import { CompetenceMatrixSummary } from 'src/app/models/dicts/competence.model';
import { CompetenceMatrixService } from 'src/app/services/education/CompetenceMatrix/competence-matrix.service';

@Component({
  selector: 'dict-competences',
  templateUrl: './dict-competences.component.html',
  styleUrls: ['./dict-competences.component.scss']
})
export class DictCompetencesComponent implements OnInit {
  @Input() educationPlanId: string = '';

  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20, 50, 100];
  public previousNext = true;
  public position: PagerPosition = 'bottom';
  public gridData: any[] = [];

  public groups: GroupDescriptor[] = [{ field: "code" }];
  public gridView: GroupResult[] | CompetenceMatrixSummary[] = [];

  public dictCompetencies: CompetenceMatrixSummary[] = [];

  constructor(
    public competenceMatrixService: CompetenceMatrixService) { }

  ngOnInit(): void {
    this.competenceMatrixService.getSummaryCompetences(this.educationPlanId).subscribe(
      data => {
        //this.dictCompetencies = data;
        data.forEach(competence => {
          if(competence.disciplines.length) {
            this.dictCompetencies.push({
              code: competence.code,
              description: competence.description,
              disciplines: competence.disciplines
            } as CompetenceMatrixSummary)

          }
        })
        this.gridData = this.dictCompetencies;
      }
    );
    //console.log('this.competencies', this.dictCompetencies)
  }

  findCompetenceDescription(group: any) {
      let competence = this.dictCompetencies.find((x: any) => x.code === group)
      return competence?.description ?? ''
  }
}

import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AccessRightModel} from '../../models/useraccess/diplomablanks/accessright.model';
import {UserSettingsModel} from '../../models/diplomablanks/settings.model';

@Injectable({
    providedIn: 'root'
  })

export class DiplomablanksUserAccessService {

    private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.access}`;

    constructor(private http: HttpClient) { }

    public getAllAccessList() {
        return this.http.get<AccessRightModel[]>(this.baseUrl);
    }

    public addPersonAccess(data: AccessRightModel) {
        return this.http.post(this.baseUrl, data);
    }

    public updateAccess(data: AccessRightModel) {
        return this.http.put(this.baseUrl, data);
    }

    public deleteAccess(id: string) {
        return this.http.delete(this.baseUrl + '/' +id);
    }

    public getUserAccess() {
        return this.http.get<UserSettingsModel>(this.baseUrl + '/GetAccess');
    }
}
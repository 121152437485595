import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AddEvent, CellClickEvent, GridComponent, PageSizeItem, RemoveEvent } from "@progress/kendo-angular-grid";
import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, FormGroup } from "@angular/forms";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { openRemoveDialog } from 'src/app/helpers/dialogHelper';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { isChildOf } from "src/app/helpers/elementRef-helper";
import { NonEmploymentReasonsService } from "src/app/services/contingent/nonemploymentreasons.service";
import { NonEmploymentReasonModel } from "src/app/models/contingent/nonemploymentreason.model";

@Component({
    selector: 'app-nonemploymentreasons',
    templateUrl: './nonemploymentreasons.component.html',
    styleUrls: ['./nonemploymentreasons.component.scss']
  })

export class NonEmploymentReasonsComponent implements OnInit {

  public reasons: NonEmploymentReasonModel[] = [];

  public pageSize = 10;
  public sizes = [10, 20, 50];

  public formGroup: UntypedFormGroup | undefined;
    @ViewChild(GridComponent) private grid!: GridComponent;

  public get isInEditingMode(): boolean {
      return this.editedRowIndex !== undefined || this.isNew;
  }
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  private nonEmploymentReasonsId: string = "";

  constructor(
    private nonEmploymentReasonsService: NonEmploymentReasonsService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private renderer: Renderer2,
  )
    {}

  public ngOnInit(): void {
    this.getReasons();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public getReasons() {
    this.nonEmploymentReasonsService.getNonEmploymentReasons()
      .subscribe(response => {
        this.reasons = response;
      })
  }

  public editHandler({
      sender,
      rowIndex,
      dataItem,
  }: CellClickEvent): void {
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {       
          return;
    }

    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.nonEmploymentReasonsId = dataItem.externalId;

    sender.editRow(rowIndex, this.formGroup);
  }

  public addHandler ({ sender }: AddEvent): void {
    this.closeEditor(sender);
    
    this.formGroup = formGroup({
      name: "",          
    });  

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, dataItem.name.toLowerCase(), 450, 200, 250);

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.nonEmploymentReasonsService.deleteNonEmploymentReasons(dataItem.externalId)
          .subscribe(
            (response) => {
              this.getReasons();
              this.notificationService.showSuccess("Удалено");
            },
            (error) => {
              this.notificationService.showError(error.error);
            }
          );
        }
      }
    });
  }

  public saveCurrent() {
    if (this.formGroup && !this.formGroup.valid) return;
      
    this.isLine = false;
    this.saveRow();
  }

  private saveRow(): void {
    if (this.formGroup == undefined) return;

    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew == true) {
        this.nonEmploymentReasonsService.addNonEmploymentReasons(this.formGroup.value)
        .subscribe(
            response => {
                this.getReasons();
                this.notificationService.showSuccess("Добавлено");
            },
            error => {
                this.notificationService.showError("Не удалось сохранить запись");
            }
        );
      }
      else {
          //Update
          this.nonEmploymentReasonsService.updateNonEmploymentReasons(this.formGroup.value, this.nonEmploymentReasonsId)
              .subscribe(
              response => {
                  this.getReasons();
                  this.notificationService.showSuccess("Сохранено");
              },
              error => {
                  this.notificationService.showError("Не удалось изменить запись");
              }
              );
      }
    }
    this.closeEditor(this.grid, this.editedRowIndex)
  }

  public cancelHandler() {
        this.closeEditor(this.grid);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        grid.closeRow(rowIndex);
        this.isNew = false;
        this.isLine = false;
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
  }

}

const formGroup = (dataItem: {name: string; order?: number;}) =>
  new UntypedFormGroup({
    order: new UntypedFormControl(dataItem.order),
    name: new UntypedFormControl(dataItem.name, Validators.required),
  });
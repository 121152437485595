<education-header></education-header>
<div kendoDialogContainer></div>

<h1>Копирование учебного плана</h1>
<div>
  <div class="stretch-column">
    <span class="form-label">Направление подготовки</span>
    <span class="value form-value">
      {{educationPlanDetailsService.currentEducationStandard.directionTraining}}
    </span>
  </div>
  <div class="stretch-column">
    <span class="form-label">Образовательная программа</span>
    <span class="value form-value">
      {{ educationPlanDetailsService.currentEducationProgram.educationProgramName }}
    </span>
  </div>
  <div class="stretch-column">
    <span class="form-label">Форма обучения</span>
    <span class="value">
      {{ educationPlanDetailsService.currentEducationPlan.studyFormName || '-' }}
    </span>
  </div>
  <div class="stretch-column">
    <span class="form-label">Год поступления</span>
    <span class="value">
      {{ educationPlanDetailsService.currentEducationPlan.yearAdmission || '-' }}
    </span>
  </div>
</div>

<div class="k-display-flex k-justify-content-between k-align-items-center">
  <h1 class="k-mt-5">Выберите источник копирования</h1>
  <button kendoButton (click)="goBack()">Отмена</button>
</div>

<kendo-grid [data]="copyPlanList" [selectable]="false" (cellClick)="onChoosePlan($event)" [skip]="skip" [pageSize]="20"
  [height]="600" [navigable]="true" [sortable]="true" [filterable]="true" [loading]="isLoading"
  [kendoGridBinding]="copyPlanList" [pageable]="{
    buttonCount: buttonCount,
    info: info,
    type: type,
    pageSizes: pageSizes,
    previousNext: previousNext,
    position: position
  }">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column class="left" field="standard" [width]="250" title="Направление подготовки">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="educationProgramCipher" [width]="70" title="Шифр ОП">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column class="left" field="educationProgramName" [width]="250" title="Образовательная программа">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="studyFormName" [width]="100" title="Форма обучения">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <filter-dropdown [filter]="filter" [data]="studyForms" textField="studyFormName" valueField="studyFormName">
      </filter-dropdown>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column class="left" field="educationPlanName" [width]="150" title="Учебный план">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="laboriousness" [width]="100" title="Трудоемкость">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column"></ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="status" [width]="100" title="Статус БУП">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>
          {{statusesMap[dataItem.status]?.text}}
        </span>
      </ng-template>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {AdditionalWork, AdditionalWorkAddDto} from "../../models/disciplineworkload/assignements.model";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class AdditionalWorkService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.additionalWork}`;
  constructor(private http: HttpClient) { }

  //Get additional works
  getAdditionalWorks(filter: HttpParams): Observable<AdditionalWork[]> {
    return this.http.get<AdditionalWork[]>(this.baseUrl + '/GetAdditionalWorks/', {params: filter});
  }

  //Save additional work
  createAdditionalWork(additionalWork: AdditionalWorkAddDto): Observable<AdditionalWorkAddDto> {
    return this.http.post<AdditionalWorkAddDto>(this.baseUrl + '/CreateAdditionalWork/', additionalWork);
  }

  // Update additional work
  updateAdditionalWork(additionalWork: AdditionalWork): Observable<AdditionalWork> {
    return this.http.put<AdditionalWork>(this.baseUrl + '/UpdateAdditionalWork/', additionalWork);
  }

  // Delete additional work
  deleteAdditionalWork(id: string): Observable<AdditionalWork> {
    return this.http.delete<AdditionalWork>(this.baseUrl + '/DeleteAdditionalWork/' + id);
  }

  writeOffHours(data: { additionalWorkId: string, completedHours: number | null }): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl + '/WriteOffHours', data);
  }
}

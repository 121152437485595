export const items: any[] = [
    {
      text: "Настройки",
      icon: "cog",
      items: [
        {
          text: "Справочники",
          items: [
            {
            text: "Корпуса",
            path: "/classroom/building"
            },
            {
              text: "Типы аудиторий",
              path: "/classroom/auditorium"
            },
            {
            text: "Назначения аудиторий",
            path: "/classroom/assign"
            },
          ],
        },
        {
          text: "Настройки доступа",
          path: "/classroom/useraccess",
          admin: true,
        },
      ],
    },
  ];
  
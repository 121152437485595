<blanks-settings></blanks-settings>

<kendo-tabstrip class="custom-tabs m-t20">
    <kendo-tabstrip-tab title="Бланки в наличии" [selected]=true>
        <ng-template kendoTabContent>           
           <app-blanksavailable></app-blanksavailable>
        </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="Выданные бланки" [selected]=false>
        <ng-template kendoTabContent>           
           <app-givenblanks></app-givenblanks>
        </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="Журнал расписок" [selected]=false>
        <ng-template kendoTabContent>           
          <app-journalblanks></app-journalblanks>
        </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError, of } from 'rxjs';
import { DictFormDesignerDataType } from 'src/app/models/education/dictFormDesignerDataType.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictFormDesignerDataTypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictFormDesignerDataType}`;

  constructor(private http: HttpClient) { }

  //Get all FormDesignerDataTypes
  public getAllFormDesignerDataTypes(): Observable<DictFormDesignerDataType[]> {
    return of([new DictFormDesignerDataType("int", "число")]);
    //return this.http.get<DictFormDesignerDataType[]>(this.baseUrl + '/GetFormDesignerDataTypes/');
  }

  // Add FormDesignerDataType
  addFormDesignerDataType(dictFormDesignerDataType: DictFormDesignerDataType): Observable<DictFormDesignerDataType> {
    return this.http.post<DictFormDesignerDataType>(this.baseUrl + '/CreateFormDesignerDataType/' , dictFormDesignerDataType)
      .pipe(catchError(this.handleError));
  }

  // Edit FormDesignerDataType
  updateFormDesignerDataType(dictFormDesignerDataType: DictFormDesignerDataType): Observable<DictFormDesignerDataType> {
    return this.http.put<DictFormDesignerDataType>(this.baseUrl + '/UpdateFormDesignerDataType/' + dictFormDesignerDataType.dataType, dictFormDesignerDataType)
      .pipe(catchError(this.handleError));
  }

  // Remove FormDesignerDataType
  deleteFormDesignerDataType(id: string): Observable<DictFormDesignerDataType> {
    return this.http.delete<DictFormDesignerDataType>(this.baseUrl + '/DeleteFormDesignerDataType/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CitizenshipType } from '../../models/contingent/citizenshiptype.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CitizenshiptypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.citizenshiptype}`;

  constructor(private http: HttpClient) { }

  //Get all CitizenshipType
  public getAllCitizenshipType(): Observable<CitizenshipType[]> {
    return this.http.get<CitizenshipType[]>(this.baseUrl);
  }

  //Add CitizenshipType
  addCitizenshipType(citizenshiptype: CitizenshipType): Observable<CitizenshipType> {
    return this.http.post<CitizenshipType>(this.baseUrl, citizenshiptype)
      .pipe(catchError(this.handleError));
  }

  //Delete CitizenshipType
  deleteCitizenshipType(id: string): Observable<CitizenshipType> {
    return this.http.delete<CitizenshipType>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit CitizenshipType
  updateCitizenshipType(citizenshiptype: CitizenshipType): Observable<CitizenshipType> {
    return this.http.put<CitizenshipType>(this.baseUrl + '/' + citizenshiptype.dictCitizenshipTypeExternalId, citizenshiptype)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {handleError} from '../../helpers/publications/errorHandle-helper';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConstructorDocumentService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.commonApplicationConstructor}/`;

  constructor(private http: HttpClient) { }

  private getFormData(file: File) {
    let formData = new FormData();
    formData.append(`template`, file, file.name);
    return formData;
  }

  public getDocument(constructorId: string, documentId: string) {
    return this.http.get(`${this.baseUrl}${constructorId}/Document/${documentId}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }

  public updateDocument(constructorId: string, documentId: string, file: File) {
    return this.http.put(`${this.baseUrl}${constructorId}/Document/${documentId}`, this.getFormData(file));
  }

  public deleteDocument(constructorId: string, documentId: string) {
    return this.http.delete(`${this.baseUrl}${constructorId}/Document/${documentId}`);
  }
}

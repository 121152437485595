import {Component, OnInit, TemplateRef} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {AcademicState} from 'src/app/models/contingent/academicstate.model';
import {Budget} from 'src/app/models/contingent/budget.model';
import {ContingentAllStudent, ContingentBack, ContingentSelection,} from 'src/app/models/contingent/contingent.model';
import {DictStudyForm} from 'src/app/models/contingent/dictstudyform.model';
import {
  EducationPlan,
  GroupEducationPlanList,
  StudentEducationPlanList
} from 'src/app/models/contingent/educationplan.model';
import {EducationProgram} from 'src/app/models/contingent/educationprogram.model';
import {EduGroup} from 'src/app/models/contingent/edugroup.model';
import {OrdersCategory} from 'src/app/models/contingent/orderscategory.model';
import {OrdersStudyPeriod} from 'src/app/models/contingent/ordersstudyperiod.model';
import {OrderTypes} from 'src/app/models/contingent/ordertypes.model';
import {Person} from 'src/app/models/contingent/person.model';
import {StudEduGroup} from 'src/app/models/contingent/studedugroup.model';
import {AcademicStateService} from 'src/app/services/contingent/academicstate.service';
import {BudgetService} from 'src/app/services/contingent/budget.service';
import {ContingentService} from 'src/app/services/contingent/contingent.service';
import {EducationService} from 'src/app/services/contingent/education.service';
import {EdugroupService} from 'src/app/services/contingent/edugroup.service';
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {OrderscategoryService} from 'src/app/services/contingent/orderscategory.service';
import {OrdertypesService} from 'src/app/services/contingent/ordertypes.service';
import {Guid} from "guid-typescript";
import {LKPerson} from "../../../models/person/lkPerson.model";
import {PersonService} from 'src/app/services/contingent/person.service';
import {RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {TimeZoneFix} from "../../../helpers/date-helper";
import {StudentsemestrService} from "../../../services/contingent/studentsemestr.service";
import {CurrentSemester} from "../../../models/contingent/studentsemestr.model";
import {DepartmentService} from "../../../services/contingent/department.service";
import {Faculty} from "../../../models/contingent/departmentname.model";
import {environment} from "../../../../environments/environment";
import {CommondictService} from "../../../services/contingent/commondict.service";
import {ContingentOrganizationService} from "../../../services/contingent/contingentOrganization.service";
import {ContingentOrganization} from "../../../models/contingent/organization.model";
import {openDialog} from "../../../helpers/dialogHelper";
import {MilitaryStatuses} from "../../../models/contingent/militarystatuses.model";
import {MilitarystatusesService} from "../../../services/contingent/militarystatuses.service";
import {DictmassoperationtypesService} from "../../../services/contingent/dictmassoperationtypes.service";
import {DictMassOperationTypes} from "../../../models/contingent/dictmassoperationtypes.model";
import {DictbenefitService} from "../../../services/contingent/dictbenefit.service";
import {DictBenefit} from "../../../models/contingent/dictbenefit.model";
import {FileRestrictions} from '@progress/kendo-angular-upload';
import {DisplaySettingEnum} from "../../../models/contingent/enums/display-setting.enum";
import {StudentcarddisplaysettingService} from "../../../services/contingent/studentcarddisplaysetting.service";
import {DictStateService} from "../../../services/contingent/dictstate.service";
import {DictState} from "../../../models/contingent/dictstate.model";
import {BudgetTypeEnum} from "../../../models/contingent/enums/budget-type.enum";

@Component({
  selector: 'app-contingentselection',
  templateUrl: './contingentselection.component.html',
  styleUrls: ['./contingentselection.component.scss']
})

export class ContingentselectionComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,
              private academicStateService: AcademicStateService,
              private orderCategoriesService:OrderscategoryService,
              private eduGroupService: EdugroupService,
              private contingentService:ContingentService,
              private notificationService: NotificationsService,
              private router: Router,
              private educationPlanService: EducationService,
              private budgetService:BudgetService,
              private orderTypesService:OrdertypesService,
              private organizationService: ContingentOrganizationService,
              private personService: PersonService,
              private studentSemesterService: StudentsemestrService,
              private departmentService: DepartmentService,
              private dialogService: DialogService,
              private commonDictService: CommondictService,
              private educationService: EducationService,
              private militaryStatusesService: MilitarystatusesService,
              private massOperationTypesService: DictmassoperationtypesService,
              private dictBenefitService: DictbenefitService,
              private displaySettingService: StudentcarddisplaysettingService,
              private dictStateService: DictStateService,
              )
  {
    this.allContingent = history.state.markedStudentSelection;
    if(this.allContingent == undefined || this.allContingent.length == 0)
      this.disableSave = false;
  }

  ngOnInit(): void {
    this.getAllMassOperationTypes()
    this.getAllAcademicState();
    this.getAllOrderCategories();
    this.getAllEduGroup();
    this.getAllOrderTypes();
    this.getAllEducationPlan();
    this.getAllEducationProgram();
    this.getBudget();
    this.getOrganization();
    this.getCurrentSemester()
    this.getAllFaculty()
    this.getAllStudyForm()
    this.getAllMilitaryStatuses()
    this.getAllBenefits()
    this.getAllPersons();
    this.getStates();
    this.getDisplaySettings();
    if (this.allContingent) {
      this.checkStudentsLastCourse();
      this.studyLevelCheck();
      this.checkStandard();
    }
    if (this.allContingent.length > 1)
      this.getEducationPlanByStudent(this.allContingent[0].studentExternalId.toString());
  }

  public enum = DisplaySettingEnum;
  public massOperationTypes: DictMassOperationTypes[] = []
  public planChangedModal: boolean = false;
  public allContingent: ContingentAllStudent[] = [];
  public dictStudentAcademicStates: AcademicState[] = [];
  public persons: Person[] = [];
  public budgets: Budget[] = [];
  public orderTypes: OrderTypes[] = [];
  public visibleOrderTypes: OrderTypes[] = [];
  public orderCategories: OrdersCategory[] = [];
  public visibleOrderCategories: OrdersCategory[] = [];
  public selectOrderCategories: OrdersCategory[] = [];
  public eduGroups: EduGroup[] = [];
  public eduGroup?: EduGroup;
  public benefits: DictBenefit[] = [];
  public planSelectedEduGroups: EduGroup[] = [];
  public academicStateId?: Guid;
  public groupId?: Guid;
  public groupPlanId?: Guid;
  public planId?: string;
  public militaryStatusId?: string;
  public dictBenefitId?: string;
  public budgetId?: Guid;
  public organizationId?: Guid;
  public customerOrganizationId?: Guid;
  public employerOrganizationId?: Guid;
  public dictStateId?: Guid;
  public scientificPersonId?: Guid;
  public dateStart?: any;
  public dateFinish?: any;
  public isBudgetOrganizationSelected = false;
  public orderSedPlaceholder = environment.orderSed;
  public dictStudyForms: DictStudyForm[] = [];
  public educationPlans: EducationPlan[] = [];
  public selectedPlan: EducationPlan | undefined = undefined;
  public organization?: ContingentOrganization;
  public facultySelectedEducationPlans: EducationPlan[] = [];
  public educationPrograms: EducationProgram[] = [];
  public educationProfilePlans: EducationProgram[] = [];
  public organizations: ContingentOrganization[] = [];
  public dictStates: DictState[] = [];
  public currentSemesters: CurrentSemester[] = [];
  public profilingError = false
  public lastCourseError = false
  public academicStateError = false
  public studyLevelError = false
  public educationStandardError = false
  public sameFacultyError = false
  public targetTrainingError = false
  public applyPlanChanged: boolean | undefined = undefined;
  public educationPlanList: StudentEducationPlanList[] = []
  public educationPlanGroupList: GroupEducationPlanList[] = []
  public militaryStatuses: MilitaryStatuses[] = [];
  public orderFiles: Array<File> = [];
  public restrictions: FileRestrictions = {
    allowedExtensions: [".docx", ".pdf"],
  };

  public educationPlan: EducationPlan | undefined={
    dictStudyForm: new DictStudyForm,
    yearAdmission:0,
    educationPlanId:0,
    developmentPeriod:"",
    acceleratedSign: true,
    dictAcceleratedStudyBaseId: 0,
  };

  public contingentSelection: ContingentSelection = {
    studentId: undefined,
    studentAcademicStateId: undefined,
    studentAcademicState: undefined,
    studEduGroup: new StudEduGroup,
    studEduGroupId: undefined,
    ordersStudyPeriod: undefined,
    ordersStudyPeriodId: undefined,
    customerOrganizationId: undefined,
    employerOrganizationId: undefined,
    dictStateId: undefined,
    orders: undefined,
    budgetId: undefined,
    organizationId: undefined,
    scientificPersonId:undefined,
    author:undefined,
    oldDictStudentAcademicStateId:undefined,
    oldEduGroupId:undefined,
    oldBudgetId:undefined,
    oldOrganizationId:undefined,
    oldOrdersStudyPeriodId:undefined,
    oldDateBegin:undefined,
    oldDateEnd:undefined,
    oldScientificPersonId:undefined,
    oldCustomerOrganizationId: undefined,
    oldEmployerOrganizationId: undefined,
    oldDictStateId: undefined,
    course:undefined,
  }
  public contingentSelectionSet: ContingentSelection[]=[];
  public orderStudyPeriod?: OrdersStudyPeriod={
    ordersStudyPeriodId: 0,
    studentId: undefined,
  }
  public faculties: Faculty[] = [];
  public editAcademicStates: boolean = false;
  public editOrders: boolean = false;
  public editGroups: boolean = false;
  public editPlan: boolean = false;
  public editMilitaryStatus: boolean = false;
  public editStudyPeriod: boolean = false;
  public editScientificSupervisor: boolean = false;
  public editCourseChange: boolean = false;
  public editCourseChangeProf: boolean = false;
  public editBenefit: boolean = false;
  public editScientificPerson: boolean = false;
  public editTargetEducationCustomer: boolean = false;
  public editTargetEducationEmployer: boolean = false;
  public disableSave: boolean = true;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  onFocus(){
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  public getAllStudyForm() {
    this.commonDictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictStudyForms = response;
        }
      );
  }

  public getDisplaySettings() {
    this.displaySettingService.getStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettingService.displaySettings$.next(response);
        }
      );
  }

  public getAllMilitaryStatuses() {
    this.militaryStatusesService.getAllMilitaryStatuses()
      .subscribe(
        response => {
          this.militaryStatuses = response;
        }
      );
  }

  private getAllAcademicState() {
    this.academicStateService.getAllAcademicState()
      .subscribe(
        response => {
          this.dictStudentAcademicStates = response;
        }
      );
  }

  private getAllOrderCategories() {
    this.orderCategoriesService.getAllorderscategory(false)
      .subscribe(
        response => {
          this.orderCategories = response;
          this.visibleOrderCategories = this.orderCategories.filter((x) => x.actual === true);
          for(let i of this.orderCategories){
            i.fullCategory = i.categoryName;
          }
          for(let i of this.visibleOrderCategories){
            i.fullCategory = i.categoryName;
          }
          this.selectOrderCategories = this.visibleOrderCategories
        }
      );
  }

  private getAllBenefits() {
    this.dictBenefitService.getAllBenefit()
      .subscribe(
        response => {
          this.benefits = response;
        }
      );
  }

  public isHidden(field: number): boolean {
    return this.displaySettingService.isHidden(field);
  }

  public getAllEduGroup() {
    this.eduGroupService.getAlledugroup(1)
      .subscribe(
        response => {
          this.eduGroups = response;
          this.planSelectedEduGroups = this.eduGroups
        }
      );
  }

  checkStandard() {
    if(this.allContingent.length == 0)
      return;

    const firstValue = this.allContingent[0].educationStandardName;
    const isSame = this.allContingent.every((x) => x.educationStandardName === firstValue);

    this.educationStandardError = !isSame

    if(isSame)
      this.getEducationPlanByStudent(this.allContingent[0].studentExternalId.toString())
    else
      this.educationPlanList = []
  }

  getEducationPlanByStudent(studentId: string){
    this.educationService.getEducationPlanByStudent(studentId)
      .subscribe({
        next: (response) => {
          this.educationPlanList = response;
        },
        error: () => {
          this.notificationService.showError('Не удалось получить текущий учебный план');
        }}
      );
  }

  public getAllEducationPlan() {
    this.educationPlanService.getAllEducationPlans()
      .subscribe(
        response => {
          this.educationPlans = response;
          this.facultySelectedEducationPlans = this.educationPlans.slice()
        }
      );
  }

  public checkStudentsLastCourse() {
    this.educationPlanService.checkStudentsLastCourse(this.allContingent)
      .subscribe(
        response => {
          this.lastCourseError = response
        }
      );
  }

  public getAllEducationProgram() {
    this.educationPlanService.getAllEducationProgram()
      .subscribe(
        response => {
          this.educationPrograms = response;
        }
      );
  }

  private getStates() {
    this.dictStateService.getAllstate().subscribe((response) => this.dictStates = response);
  }

  public getBudget(): void {
    this.budgetService.getAllBudget()
      .subscribe(
        response => {
          this.budgets = response;
        }
      );
  }

  public getAllOrderTypes() {
    this.orderTypesService.getAllordertypes(true)
      .subscribe(
        response => {
          this.orderTypes = response;
          this.visibleOrderTypes = this.orderTypes.filter((x) => x.actual === true && Guid.parse(x.filialId) == this.allContingent[0].filialId)
        }
      );
  }

  public getOrganization(): void {
    this.organizationService.getAllorganization()
      .subscribe(
        response => {
          this.organizations = response.filter((x) => x.isTarget);
        }
      );
  }

  public getCurrentSemester(): void {
    this.studentSemesterService.getCurrentSemesterList()
      .subscribe(
        response => {
          this.currentSemesters = response;
          let currentEndYear = new Date(this.currentSemesters?.find((x)=>x.isCurrent===true)?.semestrEnd!).getFullYear()
          this.currentSemesters = this.currentSemesters.filter((x)=>new Date(x.semestrEnd!).getFullYear()<=currentEndYear).slice()
        }
      );
  }

  public getAllFaculty() {
    this.departmentService.getAllFaculty(1)
      .subscribe(
        response => {
          this.faculties = response;
        }
      );
  }

  public onBudgetChange(dataItem: Budget): void {
    this.isBudgetOrganizationSelected = dataItem.studentBudgetName?.toLowerCase().includes("целев");
  }

  public getAllMassOperationTypes() {
    this.massOperationTypesService.getAllMassOperationTypes()
      .subscribe(
        response => {
          this.massOperationTypes = response;
        }
      );
  }

  public getAllPersons() {
    this.personService.getAllperson()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  public defaultActionItem: { typeName: string; enum: number|null } = {
    typeName: "Выберите действие...",
    enum: null,
  };

  //Editor Change
  public valueChange(value: { typename: string; enum: number }): void {
    this.editAcademicStates= false;
    this.editOrders = false;
    this.editGroups = false;
    this.editPlan = false;
    this.editStudyPeriod = false;
    this.editScientificSupervisor = false;
    this.editCourseChange = false;
    this.editCourseChangeProf = false;
    this.editMilitaryStatus = false;
    this.editBenefit = false;
    this.editScientificPerson = false;
    this.editTargetEducationCustomer = false;
    this.editTargetEducationEmployer = false;

    switch (value?.enum)
    {
      case 1:
        this.editAcademicStates= true;
        break;
      case 2:
        this.studyLevelCheck()
        this.editOrders = true;
        break;
      case 3:
        this.sameFacultyCheck();
        this.editGroups = true;
        break;
      case 4:
        this.editStudyPeriod = true;
        break;
      case 5:
        this.editScientificSupervisor = true;
        break;
      case 6:
        this.profilingCourseCheck()
        this.academicStateCheck()
        this.editCourseChange = true;
        break;
      case 7:
        this.editPlan = true;
        break;
      case 8:
        this.editMilitaryStatus = true;
        break;
      case 9:
        this.editBenefit = true;
        break;
      case 10:
        this.editScientificPerson = true;
        break;
      case 11:
        this.targetTrainingCheck();
        this.editTargetEducationCustomer = true;
        break;
      case 12:
        this.targetTrainingCheck();
        this.editTargetEducationEmployer = true;
        break;
    }
  }

  public onTypeChange(dataItem:any): void {
    this.selectOrderCategories = this.visibleOrderCategories.filter((s) =>
      s.dictOrderTypeId === dataItem.dictOrderTypeExternalId &&
      s.dictStudyLevelId == this.allContingent[0].studyLevelId
    ).slice();
  }

  public back: ContingentBack={
    back:true
  }

  public close(){
    this.planChangedModal = false;
  }

  public changePlan(changePlan: boolean){
    this.applyPlanChanged = changePlan;
    this.planChangedModal = false;
    if (changePlan) this.saveHandler();
  }

  onSavePlanNotification(template: TemplateRef<unknown>){
    let accelerated = this.educationPlanList.find((x: StudentEducationPlanList) => x.planId == this.planId)?.acceleratedSign
    if(this.allContingent[0].educationPlanId === this.planId)
      this.notificationService.showError("Выбранный учебный план совпадает с текущим", 5000)
    else this.dialog(template, accelerated)
  }

  //Save data
  public saveHandler() {
    this.contingentSelectionSet = []
    if (!this.formOrders.valid && this.editOrders) {
      this.formOrders.markAllAsTouched();
      this.notificationService.showError("Не все обязательные поля заполнены");
      return;
    }
    if (!this.formCourse.valid && (this.editCourseChange || this.editCourseChangeProf)) {
      this.formCourse.markAllAsTouched();
      this.notificationService.showError("Не все обязательные поля заполнены");
      return;
    }
    if (!this.dateStart && !this.dateFinish && this.editStudyPeriod) {
      this.notificationService.showError("Период обучения не выставлен");
      return;
    }
    if (!this.academicStateId && this.editAcademicStates) {
      this.notificationService.showError("Академический статус не выбран");
      return;
    }
    if (!this.budgetId && this.editScientificSupervisor) {
      this.notificationService.showError("Источник финансирования не выбран");
      return;
    }
    if (!this.groupId && this.editGroups) {
      this.notificationService.showError("Группа не выбрана");
      return;
    }
    if (!this.planId && this.editPlan || (!this.isHidden(this.enum.education_plan) && !this.groupPlanId && this.editGroups)) {
      this.notificationService.showError("Учебный план не выбран");
      return;
    }
    if (this.editMilitaryStatus && !this.militaryStatusId) {
      this.notificationService.showError("Категория воинского учета не выбрана");
      return;
    }
    if (this.editBenefit && !this.dictBenefitId) {
      this.notificationService.showError("Льгота не выбрана");
      return;
    }
    if (this.editScientificPerson && !this.scientificPersonId) {
      this.notificationService.showError("Научный руководитель не выбран");
      return;
    }

    this.profilingCourseCheck()
    this.academicStateCheck()
    if(this.editCourseChange && (this.academicStateError)) return;
    if(this.editCourseChangeProf && this.academicStateError) return;
    if (this.formCourse.value.firstSemesterBegin != null && (this.currentSemesters.filter((_) => new Date(_.semestrEnd!) > this.formCourse.value.firstSemesterBegin).length > 0)) {
      this.notificationService.showError("Указанные даты 1 семестра накладываются на текущий семестр");
      return;
    }
    if (this.formCourse.value.secondSemesterBegin != null && (this.currentSemesters.filter((_) => new Date(_.semestrEnd!) > this.formCourse.value.secondSemesterBegin).length > 0)) {
      this.notificationService.showError("Указанные даты 2 семестра накладываются на текущий семестр");
      return;
    }
    this.timeZoneFix();

    if(this.groupId && this.allContingent.every( (val) => val.groupPlanId !== this.eduGroup!.planId )
      && (this.applyPlanChanged === undefined || !this.applyPlanChanged))
    {
      this.planChangedModal = true;
      return;
    }

    for (let i = 0; i < this.allContingent.length; i++)
    {
      this.contingentSelection = {
        studentAcademicState:undefined,
        studentAcademicStateId:undefined,
        scientificPersonId: undefined,
        studEduGroup: new StudEduGroup,
        studEduGroupId: undefined,
        ordersStudyPeriodId: undefined,
        ordersStudyPeriod: undefined,
        orders:undefined,
        budgetId: undefined,
        organizationId: undefined,
        customerOrganizationId: undefined,
        employerOrganizationId: undefined,
        dictStateId: undefined,
        oldDictStudentAcademicStateId:undefined,
        oldEduGroupId:undefined,
        oldBudgetId:undefined,
        oldOrdersStudyPeriodId:undefined,
        oldDateBegin:undefined,
        oldDateEnd:undefined,
        oldScientificPersonId:undefined,
        oldCustomerOrganizationId: undefined,
        oldEmployerOrganizationId: undefined,
        author:undefined,
        studPlanId: undefined,
        course: undefined,
        courseNum: undefined,
        oldDictStateId: undefined
      }
      this.contingentSelection.studentId = this.allContingent[i].studentExternalId;
      this.contingentSelection.studPersonId = this.allContingent[i].studPersons?.studPersonExternalId;

      //academicState
      if(this.academicStateId != null){
        this.contingentSelection.studentAcademicStateId = this.allContingent[i].studentAcademicStates!.studentAcademicStateExternalId;
        this.contingentSelection.studentAcademicState = this.allContingent[i].studentAcademicStates;
        let temp = this.contingentSelection.studentAcademicState!.dictStudentAcademicStateId;
        this.contingentSelection.studentAcademicState!.dictStudentAcademicStateId = this.academicStateId
        this.contingentSelection.studentAcademicState!.studentId = this.allContingent[i].studentExternalId;
        //Old AcademicStateType
        this.contingentSelection.oldDictStudentAcademicStateId = temp;
      }
      //Groups
      if(this.groupId != null) {
        this.contingentSelection.studEduGroupId = this.allContingent[i].studEduGroupExternalId;
        this.contingentSelection.studEduGroup.studentId = this.allContingent[i].studentExternalId;
        this.contingentSelection.studEduGroup.eduGroupId = this.groupId;
        this.contingentSelection.studEduGroup.courseNum = this.allContingent[i].courseNum;
        this.contingentSelection.oldEduGroupId = this.allContingent[i].groupExternalId;
        if (this.applyPlanChanged) {
          if (this.groupPlanId) {
            this.contingentSelection.studPlanId = this.allContingent[i].studPlanId;
            this.contingentSelection.planId = this.groupPlanId!.toString()
            this.contingentSelection.oldEduGroupId = this.allContingent[i].groupExternalId;
          }
          else {
            if (this.allContingent[i].educationPlanId === this.allContingent[i].groupPlanId)
              this.contingentSelection.planId = this.eduGroup!.planId
          }
          this.contingentSelection.oldEducationPlanId = this.allContingent[i].educationPlanId
        }
      }
      //Изменение учебного плана
      if(this.planId != null){
        this.contingentSelection.planId = this.planId
        this.contingentSelection.oldEducationPlanId = this.allContingent[i].educationPlanId
      }
      //StudyPeriod
      if((this.dateStart != null || this.dateFinish != null) && this.allContingent[i].ordersStudyPeriod != undefined){
        //Old StudyPeriod
        this.contingentSelection.oldOrdersStudyPeriodId = this.allContingent[i].ordersStudyPeriod?.ordersStudyPeriodExternalId;
        this.contingentSelection.oldDateBegin = this.allContingent[i].ordersStudyPeriod!.dateStart;
        this.contingentSelection.oldDateEnd = this.allContingent[i].ordersStudyPeriod!.dateFinish;
        this.contingentSelection.ordersStudyPeriodId = this.allContingent[i].ordersStudyPeriod?.ordersStudyPeriodExternalId;
        this.contingentSelection.ordersStudyPeriod = this.allContingent[i].ordersStudyPeriod;
        this.contingentSelection.studyPeriodStart = this.dateStart
        this.contingentSelection.studyPeriodFinish = this.dateFinish
        if(this.contingentSelection.ordersStudyPeriod != undefined)
        {
          this.contingentSelection.ordersStudyPeriod.dateFinish = this.dateFinish ? this.dateFinish: TimeZoneFix(this.allContingent[i].ordersStudyPeriod!.dateFinish).toISOString();
          this.contingentSelection.ordersStudyPeriod.dateStart = this.dateStart? this.dateStart: TimeZoneFix(this.allContingent[i].ordersStudyPeriod!.dateStart).toISOString();
          this.contingentSelection.ordersStudyPeriod.studentId = this.allContingent[i].studentExternalId;
        }
      }
      else if(this.dateStart != null || this.dateFinish != null)
      {
        //Old StudyPeriod
        this.contingentSelection.oldOrdersStudyPeriodId = this.allContingent[i].ordersStudyPeriod?.ordersStudyPeriodExternalId;
        this.contingentSelection.oldDateBegin = this.allContingent[i].ordersStudyPeriod?.dateStart;
        this.contingentSelection.oldDateEnd = this.allContingent[i].ordersStudyPeriod?.dateFinish;
        this.contingentSelection.studyPeriodStart = this.dateStart
        this.contingentSelection.studyPeriodFinish = this.dateFinish
        this.orderStudyPeriod = {
          ordersStudyPeriodId: 0,
          studentId: this.allContingent[i].studentExternalId,
          dateStart: this.dateStart,
          dateFinish: this.dateFinish,
        }
        this.contingentSelection.ordersStudyPeriodId = undefined;
        this.contingentSelection.ordersStudyPeriod = this.orderStudyPeriod;
      }
      this.timeZoneFix();
      //Orders
      if(this.formOrders.value.number != null){
        this.formOrders.value.dictOrderTypeId = undefined;
        this.formOrders.value.studentId = this.allContingent[i].studentExternalId;
        this.formOrders.value.fileFullName = this.orderFiles && this.orderFiles.length > 0 ? this.orderFiles[0].name : undefined;
        this.formOrders.value.file = this.orderFiles && this.orderFiles.length > 0 ? this.orderFiles[0] : undefined;
        this.contingentSelection.orders = this.formOrders.value;
      }
      //Перевод с курса на курс
      if(this.editCourseChange){
        this.contingentSelection.course = this.formCourse.value;
        this.contingentSelection.oldEduGroupId = this.allContingent[i].groupExternalId;
        this.contingentSelection.courseNum = this.allContingent[i].courseNum! + 1;
      }

      //Смена источника финансирования
      if(this.budgetId != null)
      {
        this.contingentSelection.budgetId = this.budgetId;
        //Old BudgetId
        this.contingentSelection.oldBudgetId = this.allContingent[i].studentBudgetState?.dictStudentBudgetId
      }
      if(this.organizationId != null)
      {
        this.contingentSelection.organizationId = this.organizationId;
        //Old OrganizationId
        this.contingentSelection.oldOrganizationId = this.allContingent[i].studentBudgetState?.dictOrganizationId
      }

      //Смена научного руководителя (куратора)
      if (this.scientificPersonId != null)
      {
        this.contingentSelection.scientificPersonId = this.scientificPersonId;
        //Old ScientificPersonId
        this.contingentSelection.oldScientificPersonId = this.allContingent[i].scientificPersonId;
      }

      //Смена категории воинского учета
      if(this.militaryStatusId != null){
        this.contingentSelection.militaryStatusId = this.militaryStatusId
        this.contingentSelection.oldMilitaryStatusId = this.allContingent[i].military?.dictMilitaryStatusId
      }

      if(this.customerOrganizationId != null)
      {
        this.contingentSelection.customerOrganizationId = this.customerOrganizationId;
        this.contingentSelection.oldCustomerOrganizationId = this.allContingent[i].customerOrganizationId;
      }

      if(this.employerOrganizationId != null)
      {
        this.contingentSelection.employerOrganizationId = this.employerOrganizationId;
        this.contingentSelection.oldEmployerOrganizationId = this.allContingent[i].employerOrganizationId;
        this.contingentSelection.dictStateId = this.dictStateId;
        this.contingentSelection.oldDictStateId = this.allContingent[i].dictStateId;
      }

      //смена льготы
      if(this.dictBenefitId != null){
        this.contingentSelection.dictBenefitId = this.dictBenefitId
        this.contingentSelection.oldBenefitId = this.allContingent[i]?.benefitId
      }

      this.contingentSelectionSet.push(this.contingentSelection)
      if(this.person.fullName != undefined)
        this.contingentSelection.author = this.person.fullName
      else
        this.contingentSelection.author = undefined
    }

    this.contingentService.updateSelectedStudents(this.contingentSelectionSet)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.router.navigateByUrl(`/contingent`).then();
        },
        error:(error) => {
          console.log(error.error);
          this.notificationService.showError(error.status === 403
            ? "Недостаточно прав доступа. В выборке присутствуют студенты, данные которых не могут быть изменены."
            : error.error);
        }});
  }

  public nameStudyForm(id: Guid | undefined): string {
    if (id != undefined) {
      return this.dictStudyForms.find((x) => x.dictStudyFormExternalId === id)!.studyFormName
    } else return ""
  }

  public nameEducationProgram(id: Guid | undefined): string {
    if (id != undefined) {
      return this.educationPlan?.programName!
    } else return ""
  }

  public person: LKPerson = {
    personId: 0,
    login: "",
    personExternalId: "",
    fullName: "",
    firstName: "",
    lastName: "",
    middleName: "",
    birthday: new Date(),
    hasPps: false,
    remainingVacationDaysNumber: null,
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить студента: "+ dataItem.studPersons.fullName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if(result.text == "Да"){
          this.allContingent=this.allContingent.filter(function(f) { return f.studentExternalId !== dataItem.studentExternalId }).slice();
          this.checkStandard()
          this.profilingCourseCheck()
          this.academicStateCheck()
          this.checkStudentsLastCourse()
          const contingent_settings = localStorage.getItem('contingent_settings');
          if (contingent_settings !== null) {
            let storage = JSON.parse(contingent_settings)
            let contingentSaveSettings = storage
            contingentSaveSettings.studentSelection = storage.studentSelection.filter((x: never)=> x !== dataItem.studentExternalId).slice()
            contingentSaveSettings.markedstudentSelection = this.allContingent
            localStorage.setItem('contingent_settings', JSON.stringify(contingentSaveSettings));
          }
        }
      }
    });
  }

  public profilingCourseCheck(){
    this.profilingError = this.allContingent.find((x) => x.profilingCourseNumber === (x.courseNum! + 1)) != null;
  }

  public academicStateCheck(){
    this.academicStateError = this.allContingent.find((x) => x.studentAcademicState !== "Учится") != null;
  }

  public sameFacultyCheck() {
    if(this.allContingent.length == 0)
      return;

    const firstValue = this.allContingent[0].facultyName;
    const isSame = this.allContingent.every(_ => _.facultyName === firstValue);
    this.sameFacultyError = !isSame
  }

  public targetTrainingCheck() {
    this.targetTrainingError = !this.allContingent.every(_ => _.studentBudgetState?.budgetType == BudgetTypeEnum.TargetTraining)
  }

  public studyLevelCheck() {
    if(this.allContingent.length == 0)
      return;

    const firstValue = this.allContingent[0].studyLevelId;
    const isSame = this.allContingent.every(_ => _.studyLevelId === firstValue);
    this.studyLevelError = !isSame
  }

  public onGroupChange(): void {
    this.eduGroup =  this.eduGroups.find((s) => s.eduGroupExternalId == this.groupId);
    this.educationPlan = this.educationPlans.find((s) => s.externalId == this.eduGroup?.planId);
    this.educationProfilePlans = this.educationPrograms.filter((s) => s.educationStandardId == this.educationPlan?.educationStandardId);
    if (this.eduGroup?.eduGroupExternalId)
      this.getEducationPlanByGroup(this.eduGroup.eduGroupExternalId.toString());
  }

  public getEducationPlanByGroup(id: any): void {
    this.educationService.getEducationPlanByGroup(id)
      .subscribe(
        response => {
          this.educationPlanGroupList = response;
        }
      );
  }

  public onCustomerStudentChange(): void {
    this.organization = this.organizations.find((o) => o.dictOrganizationExternalId == this.customerOrganizationId);
  }

  public onEmployerStudentChange(): void {
    this.organization = this.organizations.find((o) => o.dictOrganizationExternalId == this.employerOrganizationId);
  }

  public planValueChange(id: string | undefined){
    if(id)
      this.getEducationPlan(id)
    else
      this.selectedPlan = undefined
  }

  dialog(template: TemplateRef<unknown>, accelerated?: boolean){
    const dialog: DialogRef = accelerated == true ?
      this.dialogService.open({
        title: "Пожалуйста подтвердите",
        content: template,
        actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
        width: 450,
        height: 210,
        minWidth: 250,
      })
      : openDialog(this.dialogService, `Вы действительно хотите сменить учебный план студентов?`);

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {}
      else {
        if(result.text == "Да")
          this.saveHandler()
      }
    });
  }

  public getEducationPlan(id: string) {
    this.educationService.getEducationPlans(id)
      .subscribe(
        response => {
          this.selectedPlan = response;
        }
      );
  }

  public timeZoneFix()
  {
    this.formOrders.value.orderDate = TimeZoneFix(this.formOrders.value.orderDate).toISOString();
    if(this.formOrders.value.dateBegin)
      this.formOrders.value.dateBegin = TimeZoneFix(this.formOrders.value.dateBegin).toISOString();
    if(this.formOrders.value.dateEnd)
      this.formOrders.value.dateEnd = TimeZoneFix(this.formOrders.value.dateEnd).toISOString();
    if(this.dateStart)
      this.dateStart = TimeZoneFix(this.dateStart).toISOString();
    if(this.dateFinish)
      this.dateFinish = TimeZoneFix(this.dateFinish).toISOString();
  }

  public formOrders: UntypedFormGroup = new UntypedFormGroup({
    ordersId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    student: new UntypedFormControl(),
    dictOrdersCategoryId: new UntypedFormControl(),
    dictOrdersCategories: new UntypedFormControl(),
    dictOrdersTypeId:new UntypedFormControl(),
    number: new UntypedFormControl(),
    orderDate: new UntypedFormControl(),
    dateBegin: new UntypedFormControl(),
    dateEnd: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    orderSed: new UntypedFormControl(),
    file: new UntypedFormControl(),
    fileFullName: new UntypedFormControl(),
  });

  public formCourse: UntypedFormGroup = new UntypedFormGroup({
    firstSemesterBegin: new UntypedFormControl(),
    firstSemesterEnd: new UntypedFormControl(),
    secondSemesterBegin: new UntypedFormControl(),
    secondSemesterEnd: new UntypedFormControl(),
    educationPlanId: new UntypedFormControl(),
    eduGroupId: new UntypedFormControl(),
    facultyId: new UntypedFormControl(),
  });
}

import { Guid } from 'guid-typescript';
import {Moment} from "moment";

export class CurrentControlSheetListQuery {
  public eduGroupId!: string;
  public semester!: number;
  public attestationId!: string | null;
  public contingentDate!: Date;
  public markDate?: Date;
  public isCurrentSemester?: boolean;
}

export class CurrentControlTreeQuery {
  public semesterInYear!: number;
  public year!: Number;
  public trainingLevelId!: (string|Guid)[] | null;
  public contingentDate!: Date;
  public isCurrentSemester!: boolean;
}

export class CurrentControlStudentTreeQuery {
  public trainingLevel: (string | Guid)[] | undefined;
  public semesterInYear!: number;
  public studyYear!: number;
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Admin } from 'src/app/models/useraccess/admin.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.admin}`;

  constructor(private http: HttpClient) { }

  //Get Admins
  public getAdmins(): Observable<Admin[]> {
    return this.http.get<Admin[]>(this.baseUrl + '/GetAdmins/')
      .pipe(
        catchError(this.handleError));
  }

  //Save admin
  public saveAdmin(admin: Admin): Observable<Admin> {
    return this.http.post<Admin>(this.baseUrl + '/SaveAdmin/', admin)
      .pipe(catchError(this.handleError));
  }

  //Delete admin
  public deleteAdmin(id: string): Observable<Admin> {
    return this.http.delete<Admin>(this.baseUrl + '/DeleteAdmin/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
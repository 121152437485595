import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DictAdditionalStudentFields } from '../../models/contingent/dictadditionalstudentfields.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DictAdditionalStudentFieldsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictadditionalstudentfields}`;

  constructor(private http: HttpClient) { }

  //Get all additionalstudentfields
  public getAlladditionalstudentfields(): Observable<DictAdditionalStudentFields[]> {
    return this.http.get<DictAdditionalStudentFields[]>(this.baseUrl);
  }

  //Add additionalstudentfields
  addadditionalstudentfields(additionalstudentfields: DictAdditionalStudentFields): Observable<DictAdditionalStudentFields> {
    return this.http.post<DictAdditionalStudentFields>(this.baseUrl, additionalstudentfields)
      .pipe(catchError(this.handleError));
  }

  //Delete additionalstudentfields
  deleteadditionalstudentfields(id: string): Observable<DictAdditionalStudentFields> {
    return this.http.delete<DictAdditionalStudentFields>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit additionalstudentfields
  updateadditionalstudentfields(additionalstudentfields: DictAdditionalStudentFields): Observable<DictAdditionalStudentFields> {
    return this.http.put<DictAdditionalStudentFields>(this.baseUrl + '/' + additionalstudentfields.dictadditionalStudentFieldExternalId, additionalstudentfields)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

<div class="setup-bt" style="position:absolute;">
    <div class="setup">
      <kendo-menu class="workloadMenu" [items]="items" [openOnClick]="{ toggle: 'click' } ">
        <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
          <a [kendoMenuItemLink]="index" [routerLink]="item.path">
            {{ item.text }}
            <span *ngIf="item.items && item.items.length" [kendoMenuExpandArrow]="index"></span>
            <span class="k-icon k-i-{{ item.icon }} k-icon-md"></span>
          </a>
        </ng-template>
      </kendo-menu>
    </div>
  </div>
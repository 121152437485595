import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StudenthistoryService} from "../../../../services/contingent/studenthistory.service";
import {StudentHistory} from "../../../../models/contingent/studenthistory.model";

@Component({
  selector: 'app-studenthistorydetails',
  templateUrl: './studenthistorydetails.component.html',
  styleUrls: ['./studenthistorydetails.component.scss']
})
export class StudenthistorydetailsComponent implements OnInit {

  public studentId!: any;
  public id!: any;

  constructor(private activateRoute: ActivatedRoute,
              private router: Router,
              private historyService: StudenthistoryService,
  ) {
    this.studentId = activateRoute.snapshot.params["studentId"];
    this.id = activateRoute.snapshot.params["id"];
  }

  ngOnInit(): void {
    this.getHistoryDetails()
  }

  public history?: StudentHistory
  public loading = false;

  public closeDetails(){
    this.router.navigateByUrl(`/contingent/studentmaininfo/${this.studentId}/history`).then();
  }

  public getHistoryDetails() {
    this.loading = true;
    this.historyService.getHistoryDetails({id:this.id, studentId: this.studentId})
      .subscribe({
          next:(response) => {
            this.history = response;
            this.history.date = new Date(this.history.date!)
            this.loading = false;
          },
          error:() => {
            this.router.navigateByUrl(`/contingent`).then();
          }
        }
      )
  }

}

import { Component, OnInit, ViewChild,  Renderer2} from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent, PagerPosition } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { Building } from 'src/app/models/classroom/building.model';
import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { BuildingService } from 'src/app/services/classroom/building.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ClassRoomUserAccessService } from '../../../services/useraccess/classroom-user-access.service';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { checkRole } from "../../../helpers/token/role-check";
import { TokenStorageService } from "../../../services/token.service";
import { JwtHelperService}  from "@auth0/angular-jwt";
import { Role } from "../../../models/useraccess/role";
import { Guid } from 'guid-typescript';
import { Router} from "@angular/router";

@Component({
    selector: 'app-building',
    templateUrl: './building.component.html',
    styleUrls: ['./building.component.scss']
})

export class BuildingComponent implements OnInit{

    public buildings: any = [];
   
    public building: any = {
      buildingId: '',
      buildingName: '',
      buildingSName: '',
      buildingNumber: 0,
  //    dictBuildingExternalId: Guid.create(),
    }

    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public info = true;
    public pageSizes = [20, 50, 100];
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';
    public skip = 0;

    public loading = false;
    public opened = false;

    public position: "top" | "bottom" | "both" = "both";
    public formGroup: FormGroup | undefined;
    public editable?: boolean;

    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean{
        return this.editedRowIndex !== undefined || this.isNew;
    }

    public gridState: State = {
      sort: [],
      skip: 0,
      take: 10,
    };

    public buildingId: string = "";

    public accessLevel: boolean = false;
    public accesses: any = [];
    public personId: string = "";
    public isAdmin: boolean;

    public exists: boolean = false;
    public buildingForEdit: any = [];

constructor(
    private buildingService: BuildingService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private userAccessService: ClassRoomUserAccessService,
    private personService: PersonService,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
)
    { this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);}

ngOnInit() {
    this.getAllBuilding();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
    this.getAccess();
}

public getAllBuilding() {
    this.loading = true;
    this.buildingService.getAllBuildings()
      .subscribe(
        response => {
          this.buildings = response;
          //console.log(this.buildings);
          this.loading = false;
        },
        error => {
          console.log(error.error);
          this.loading = false;
        });
}

public removeHandler({ dataItem }: RemoveEvent): void {
  const dialog: DialogRef = this.dialogService.open({
    title: "Пожалуйста подтвердите",
    content: `Вы действительно хотите удалить: ${dataItem.buildingName.toLowerCase()}?`,
    actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
    width: 450,
    height: 200,
    minWidth: 250,
  });
  dialog.result.subscribe((result) => {
    if (result instanceof DialogCloseResult) {
    } else {
      if(result.text == "Да"){
        this.opened = false;
        this.buildingService.deleteBuilding(dataItem.buildingId)
        .subscribe(
          ( response: any) => {
            if (response == null){
              this.notificationService.showError("Невозможно удалить корпус, так как он используется");
            }
            else {
              this.getAllBuilding();
              this.notificationService.showSuccess("Удалено");
            }
          },
          (error: any) => {
            if ( error.error.Error[0] == "Запись используется"){
              this.notificationService.showError("Невозможно удалить корпус, так как он используется");
            }
            else this.notificationService.showError(error.error);
          }
        );
      }
        else{
          this.opened = false;
        }
    }
  });
}

  public editHandler({
      sender,
      isEdited,
      rowIndex,
      dataItem,
    }: CellClickEvent): void {
      if (this.accessLevel == true){
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
          return;
        }
        
        this.isLine = true;
        this.buildingId = dataItem.buildingId;
        this.saveRow();
        this.formGroup = formGroup(dataItem);
        this.editedRowIndex = rowIndex;

        this.buildingForEdit = [];
        let name = this.formGroup.value.buildingName;
        this.buildingForEdit = this.buildings.filter((item: any) => !(item.buildingName == name));

        sender.editRow(rowIndex, this.formGroup);
    }
  }

  public addHandler({ sender }: AddEvent): void {
      this.closeEditor(sender);

      this.formGroup = formGroup({
        buildingId: '',
        buildingName: '',
        buildingSName: '',
        buildingNumber: undefined,
    /*   dictBuildingExternalId: Guid.create()*/
      });

      this.isLine = true;
      this.isNew = true;
      sender.addRow(this.formGroup);
  }

  public cancelHandler(): void {
      this.closeEditor(this.grid, this.editedRowIndex);
  }

  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
      ): void {
        this.isNew = false;
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
  }

  public onStateChange(state: any): void {
    this.gridState = state;
    this.getAllBuilding();
  }

  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew == true && this.formGroup !== undefined) {
        
        this.exists = false;

        let name = this.formGroup.value.buildingName.toLowerCase().trim();

        if (this.buildings.findIndex((item:any) => item.buildingName.toLowerCase() == name ) !== -1){
            this.notificationService.showError("Невозможно сохранить корпус, так как он уже существует");
            this.exists = true;
        }

        if (this.exists == false){
          this.buildingService.addBuilding(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllBuilding();
              this.notificationService.showSuccess("Добавлено");
              this.building = {
                buildingName: '',
                buildingSName: '',
                buildingNumber: undefined,
              //  dictBuildingExternalId: Guid.create(),
              }
            },
            error => {
              this.notificationService.showError("Не удалось сохранить запись");
            }
          );
        }
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        
        this.exists = false;

        let name = this.formGroup.value.buildingName.toLowerCase().trim();

        if (this.buildingForEdit.findIndex((item:any) => item.buildingName.toLowerCase() == name ) !== -1){
          this.notificationService.showError("Невозможно сохранить корпус, так как он уже существует");
          this.exists = true;
        }

        if (this.exists == false){
          this.buildingService.updateBuilding(this.formGroup.value, this.buildingId)
            .subscribe(
              response => {
                this.getAllBuilding();
                this.notificationService.showSuccess("Сохранено");
              },
              error => {
                this.notificationService.showError("Не удалось изменить запись");
              }
            );
        }
      }
    }
    this.closeEditor(this.grid);
  }

  public getAccess()  {
    this.userAccessService.getAccessLevel()
    .subscribe(response => {
      if (response.dictionaryAccessLevel == 2){
        this.accessLevel = true;
      }
    })
  }

}

const formGroup = (dataItem: { buildingId?: any; buildingName?: any; buildingSName?: any; buildingNumber?: any; dictBuildingExternalId?: any}) =>
  new FormGroup({
 //   buildingId: new FormControl(dataItem.buildingId),
 //   id: new FormControl(dataItem.id, Validators.required),
    buildingName: new FormControl(dataItem.buildingName, Validators.required),
    buildingSName: new FormControl(dataItem.buildingSName),
    buildingNumber: new FormControl(dataItem.buildingNumber, [Validators.min(1), Validators.pattern("^[0-9]+$")]),
 //   dictBuildingExternalId: new FormControl(dataItem.dictBuildingExternalId)
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
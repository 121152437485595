import {Component} from "@angular/core";
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {breadcrumbItemsMap} from "src/app/models/home/breadcrumb-items.model";
import {boolOptions} from "src/app/models/mfc/enums/bool-options.enum";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {TypeReceipt} from "../../../../models/mfc/dicts/type-receipt.model";
import {TypeReceiptService} from "../../../../services/mfc/dicts/type-receipt.service";
import {ApprovalTypeService} from "../../../../services/mfc/dicts/approval-type.service";
import {ApprovalType} from "../../../../models/mfc/dicts/approval-type.model";

@Component({
  selector: 'mfc-dict-type-receipt',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="typeReceipts"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      [deletableField]="deletableField"
      (getMethod)="get()"
      (addMethod)="add($event)"
      (updateMethod)="update($event)"
      (removeMethod)="remove($event)">
    </app-dict>
  `
})
export class TypeReceiptComponent {

  protected typeReceipts: TypeReceipt[] = [];
  protected approvalTypes: ApprovalType[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly deletableField = 'deletable';
  protected readonly info: FieldInfo<TypeReceipt>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название',
      width: 350,
    },
    {
      field: 'approvalTypes',
      type: FieldType.Multiselect,
      title: 'Типы согласования',
      width: 200,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    },
    {
      field: 'isHidden',
      type: FieldType.Dropdown,
      title: 'Скрыть',
      width: 200,
      listOptions: {
        data: this.boolOptions,
        textField: 'text',
        valueField: 'id'
      },
    },
  ];

  protected formGroup(dataItem?: TypeReceipt): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      description: new FormControl(dataItem?.description),
      name: new FormControl(dataItem?.name, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber),
      approvalTypes: new FormControl(dataItem?.approvalTypes),
      isHidden: new FormControl(dataItem?.isHidden ?? false),
    });
  }

  constructor(
    private typeReceiptService: TypeReceiptService,
    private approvalTypeService: ApprovalTypeService
  ) {
    this.getApprovalTypes();
  }

  private getApprovalTypes() {
    this.approvalTypeService.getApprovalTypes().subscribe(data => {
      this.approvalTypes = data;
      const approvalTypes = this.info.find(
        (item) => item.field === 'approvalTypes'
      );

      if (approvalTypes) {
        approvalTypes.listOptions = {
          data: data,
          textField: 'name',
          valueField: 'name',
        }
      }
    });
  }

  private convertValuesForRequest(value: TypeReceipt) {
    value.approvalTypes = this.approvalTypes
      .filter(item => value.approvalTypes.includes(item.name))
      .flatMap(item => item.id);
    return value;
  }

  protected get() {
    this.typeReceiptService.getAll().subscribe(data => this.typeReceipts = data);
  }

  protected add({value, observer}: EventData<TypeReceipt>) {
    value = this.convertValuesForRequest(value);
    this.typeReceiptService.addTypeReceipt(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<TypeReceipt>) {
    value = this.convertValuesForRequest(value);
    this.typeReceiptService.updateTypeReceipt(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<TypeReceipt>) {
    this.typeReceiptService.removeTypeReceipt(value.id).subscribe(observer);
  }

}

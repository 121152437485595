<education-header></education-header>
<title>Образовательные программы</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened && !isCompetence">Справочник образовательных программ</h1>
  <div [class]="[gridStyle]">
    <button kendoButton [primary]="true" iconClass="k-icon k-i-plus" (click)="addHandler()" *ngIf="!isOpened&&editable&&!isCompetence" style="margin-bottom: 30px">Добавить ОП</button>
    <kendo-grid class="oopGrid" [data]="ooprograms" [kendoGridBinding]="ooprograms"
                [pageable]="{
        buttonCount: buttonCount,
        info: info,
        type: type,
        pageSizes: pageSizes,
        previousNext: previousNext,
        position: position
      }"
                [pageSize]="20"
                [height]="600"
                [navigable]="true"
                [sortable]="true"
                [sort]="sort"
                (sortChange)="sortChange($event)"
                [filterable]="true"
                (dataStateChange)="onStateChange($event)"
                (cellClick)="getSelectedValues($event)"
                [selectable]="selectableSettings"
                [rowClass]="rowClassCallback"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="standard.trainingProgram" [width]="260" editor="text" title="Направление подготовки">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.standard.trainingProgram}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="oopNameAndCipher" editor="text" title="Образовательная программа" [width]="250" >
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.oopNameAndCipher}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="standard.trainingLevel.trainingLevelExternalId" [width]="170" editor="text" title="Уровень подготовки">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="trainingLevels" textField="trainingLevelName"
                           valueField="dictTrainingLevelId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.standard.trainingLevel.trainingLevelName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="qualification.qualificationName" [width]="170" editor="text" title="Квалификация">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="qualifications" textField="qualificationName"
                           valueField="qualificationExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.qualification.qualificationName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" [width]="150" field="standard.dictStandardType.standardTypeShortName" editor="text" title="Тип стандарта">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="standardTypes" textField="standardTypeShortName"
                           valueField="standardTypeExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.standard.dictStandardType.standardTypeShortName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" [width]="150" field="filial.filialShortName" editor="text" title="Филиал">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="filials" textField="filialShortName"
                           valueField="filialId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.filial?.filialShortName}}
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="oopStatus" editor="text" title="Статус" [width]="180" [filterable]="true" [class]="{ codeColumn: true }">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="status" textField="name"
                           valueField="oopStatus">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" [ngSwitch]="dataItem.oopStatus">
            <div *ngSwitchCase="StateEnum.Active">{{"Действует"}}</div>
            <div *ngSwitchCase="StateEnum.Closed">{{"Закрыт"}}</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" editor="text" title="Справочник компетенций" [width]="190" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" style="cursor: pointer">
          <h2 style="text-decoration: underline; color: #42A7DD">
            <a href="{{'education/oop/competence/' + dataItem.externalId}}" target="_blank">Компетенции ({{dataItem.competencesNumber}})</a>
          </h2>
        </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="" headerClass="gridHeader dictionaries" [width]="140" *ngIf="editable&&!isCompetence">
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells competence">
          <button kendoButton type="button" (click)="editHandler(dataItem)" class="editButton">Изменить</button>
        </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <h1>
        {{ isNew ? 'Новая образовательная программа' : 'Изменить образовательную программу' }}
      </h1>
      <kendo-formfield style="margin-top: unset">
        <div class="formRow">
          <h2>Образовательный стандарт</h2>
          <kendo-combobox
                  #standardComboBox
                  [data]="standards"
                  [openOnFocus]="standardComboBox"
                  textField="fullTrainingProgram"
                  valueField="standardId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['educationStandardId']"
                  formControlName="educationStandardId" #educationStandardId required
          >
          </kendo-combobox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Шифр ОП</h2>
          <kendo-textbox formControlName="oopCipher" [maxlength]="50" #oopCihper required></kendo-textbox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Образовательная программа</h2>
          <kendo-textbox formControlName="oopName" [maxlength]="255" #oopName required></kendo-textbox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="oopShortName" [maxlength]="50" #oopShortName></kendo-textbox>
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Квалификация</h2>
          <kendo-combobox
                  #qualificationBox
                  [openOnFocus]="qualificationBox"
                  [data]="qualifications"
                  textField="qualificationName"
                  valueField="qualificationExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['qualificationExternalId']"
                  formControlName="qualificationExternalId" #qualificationExternalId required>
          </kendo-combobox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Выпускающая кафедра</h2>
          <kendo-dropdownlist
                  [data]="kafedras"
                  textField="kafedraName"
                  valueField="kafedraId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['kafedraId']"
                  formControlName="kafedraId" #kafedraId required>
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Институт/факультет</h2>
          <kendo-dropdownlist
                  [data]="faculties"
                  textField="facultyName"
                  valueField="facultyId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['facultyId']"
                  formControlName="facultyId" #facultyId required>
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Филиал</h2>
          <kendo-combobox
            #filialComboBox
            [data]="filials"
            [openOnFocus]="filialComboBox"
            textField="filialShortName"
            valueField="filialId"
            [valuePrimitive]="true"
            [formControl]="$any(editForm).controls['filialId']"
            formControlName="filialId" #filialId required
          >
          </kendo-combobox>
          <h3 class="red">*</h3>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Номер курса профилизации</h2>
          <kendo-numerictextbox formControlName="profilingCourseNumber" [min]="0" format="n0" #oopCourseProfilizationNumber></kendo-numerictextbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Индустриальный партнер</h2>
          <kendo-dropdownlist
                  [data]="organizations"
                  textField="organizationName"
                  valueField="dictOrganizationExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['dictOrganizationId']"
                  formControlName="dictOrganizationId" #dictIndustrialPartnerId>
          </kendo-dropdownlist>
          <!-- <kendo-textbox formControlName="industrialPartner" #oopIndustrialPartner></kendo-textbox> -->
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Статус</h2>
          <kendo-dropdownlist
                  [data]="educationProgramStatus"
                  textField="text"
                  valueField="value"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['oopStatus']"
                  formControlName="oopStatus" #oopStatus required>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-dropdownlist>
          <h3 class="red">*</h3>
        </div>
      </kendo-formfield>
      <div [ngClass]="{view: !isNew}" class="competenceContainer">
        <div style="width: 50%"></div>
        <div style="width: 50%">
          <button kendoButton class="competenceButton" (click)="openCompetence(editForm.value)" icon="page-header-section" *ngIf="!isNew">Справочник компетенций</button>
        </div>
      </div>
      <div class="buttons">
        <button kendoButton type="button" (click)="onCancel()">Отмена</button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="openDialog($event)">Сохранить</button>
      </div>
    </form>
    <kendo-dialog
            title="Пожалуйста, подтвердите"
            *ngIf="dialogOpened"
            (close)="onCancel()"
            [minWidth]="250"
            [width]="450"
    >
      <p style="margin: 30px; text-align: center;">
        Вы уверены что хотите изменить статус образовательной программы?
      </p>
      <kendo-dialog-actions>
        <button kendoButton (click)="closeDialog()">Нет</button>
        <button kendoButton (click)="saveOOP()" [primary]="true">
          Да
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </div>
</div>

<kendo-dialog
        title="Пожалуйста, подтвердите"
        *ngIf="copyPopup"
        (close)="closeDialog()"
        [minWidth]="250"
        [width]="450"
>
  <p style="margin: 30px; text-align: center;">
    Вы уверены что хотите скопировать компетенции?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Нет</button>
    <button kendoButton (click)="copySelectedCompetence()" [primary]="true">
      Да
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

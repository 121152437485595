import {Guid} from "guid-typescript";
import {GlobalSignatoryEduRead} from "./globalsignatory.model";

export class LocalSignatoryEduRead {
  public id!: Guid | string;
  public globalSignatory!: GlobalSignatoryEduRead;
  public graduationKafedra!: LocalSignatoryKafedraReadDto | null;
  public trainingLevels!: LocalSignatoryTrainingLevelEduReadDto[];
  public faculties!: LocalSignatoryFacultyEduReadDto[];
  public filials!: LocalSignatoryFilialEduReadDto[];
  public localSignatoryRole!: number;
  public ordinal!: number | null;
}

export class LocalSignatoryTrainingLevelEduReadDto {
  public id!: string;
  public trainingLevelId!: string;
  public trainingLevelName!: string;
}

export class LocalSignatoryFilialEduReadDto {
  public id!: string;
  public filialId!: string;
  public filialSName!: string;
}

export class LocalSignatoryFacultyEduReadDto {
  public id!: string;
  public facultyId!: string | Guid;
  public facultyName!: string;
  public facultyShortName!: string;
}

export class LocalSignatoryKafedraReadDto {
  public id!: Guid | string;
  public name!: string;
}

export class LocalSignatoryEduAdd {
  public globalSignatoryId!: Guid | string;
  public graduationKafedraId!: Guid | string | null;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public localSignatoryRole!: number;
  public ordinal!: number | null;
}

export class LocalSignatoryEduUpdate {
  public id!: Guid | string;
  public globalSignatoryId!: Guid | string;
  public graduationKafedraId!: Guid | string | null;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public localSignatoryRole!: number;
  public ordinal!: number | null;
}

<gia-settings></gia-settings>

<h1>Типы приказов ГЭК</h1>

<div class="grid-container">
  <!--Grid-->
  <kendo-grid [data]="gridView"
              (cellClick)="editHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column field="name"
                       title="Название типа"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
              {{dataItem.name}}
          </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-textbox
          [clearButton]="true"
          [formControl]="formGroup.get('name')"
        >
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="order"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
              {{ dataItem.order }}
          </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItemS="dataItem" let-formGroup="formGroup">
        <kendo-numerictextbox
          [formControl]="formGroup.get('order')"
          [decimals]="0"
          [min]="1"
          format="n0"
        >
        </kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

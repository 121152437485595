import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Guid} from "guid-typescript";
import {HttpClient} from "@angular/common/http";
import {DictGeneralSheet} from "../../models/middlecontrol/dictGeneralSheet.model";

@Injectable({
  providedIn: 'root'
})
export class DictgeneralsheetService {

  baseUrl = `${environment.apiEndpoint}`;

  constructor(
    private httpClient: HttpClient,
  ) {}

  public addGeneralSheet(dto: DictGeneralSheet) {
    return this.httpClient
      .post<DictGeneralSheet>(
        this.baseUrl + environment.apiPaths.session.generalSheet,
        dto
      );
  }

  public updateGeneralSheet(dto: DictGeneralSheet) {
    return this.httpClient
      .put<DictGeneralSheet>(
        this.baseUrl + environment.apiPaths.session.generalSheet,
        dto
      );
  }

  public deleteGeneralSheet(id: string | undefined) {
    return this.httpClient
      .delete<DictGeneralSheet>(
        this.baseUrl + environment.apiPaths.session.generalSheet + `/${id}`
      );
  }

  public getGeneralSheet() {
    return this.httpClient
      .get<DictGeneralSheet[]>(
        this.baseUrl + environment.apiPaths.session.generalSheet
      );
  }
}

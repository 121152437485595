import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment} from '../../../environments/environment';
import { Department } from '../../models/announcement/department.model';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

    baseUrl = `${environment.announcementApiEndpoint}${environment.apiPaths.announcement.base}`;

  constructor(private http: HttpClient) { }

  //Get all Departments
  public getAllDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(this.baseUrl + '/GetDepartments/');
  }

  // Add Department
  addDepartment(department: Department): Observable<Department> {
    return this.http.post<Department>(this.baseUrl + '/CreateDepartment/', department)
      .pipe(catchError(this.handleError));
  }

  // Edit Department
  updateDepartment(department: Department): Observable<Department> {
    return this.http.put<Department>(this.baseUrl + '/UpdateDepartment/' + department.departmentId, department)
      .pipe(catchError(this.handleError));
  }

  // Remove Department
  deleteDepartment(id: string): Observable<Department> {
    return this.http.delete<Department>(this.baseUrl + '/DeleteDepartment/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

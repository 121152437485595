import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DictFilial } from '../../models/contingent/dictfilial.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DictFilialService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.filial}`;

  constructor(private http: HttpClient) { }

  //Get all filial
  public getAllfilial(): Observable<DictFilial[]> {
    return this.http.get<DictFilial[]>(this.baseUrl);
  }
}

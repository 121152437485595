import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {NotificationsService} from '../notifications/notifications.service';
import {ApplicationCategoriesTree, ApplicationCategoryTree} from '../../models/mfc/application-categories-tree.model';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApplicationTypeSelectService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.person}/GetCategoriesAndApplicationTypes`;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationsService
  ) { }

  getCategoriesTree(studentId: string): Observable<ApplicationCategoryTree[]> {
    return this.http.get<ApplicationCategoriesTree>(`${this.baseUrl}?studentId=${studentId}`)
      .pipe(
        tap((response) => {
          if (!response || !response.categories.length) {
            this.notificationService.showError('Не удалось найти доступные категории и типы заявок');
          }
        }),
        map((d) => d.categories)
      );
  }
}

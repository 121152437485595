import { Student } from "./student.model";
import { Budget } from "./budget.model";
import {ContingentOrganization} from "./organization.model";
import { BudgetSubCategory } from "./budgetsubcategory.model";
import {Guid} from "guid-typescript";
import {BudgetTypeEnum} from "./enums/budget-type.enum";

export class StudentBudgetState{
  public studentBudgetStateExternalId?: string
  public studentBudgetStateId?: string;
  public studentId!: number;
  public student!:Student;
  public dictStudentBudgetId?: string;
  public dictStudentBudgets?: Budget;
  public dictOrganizationId!: number;
  public dictOrganizations!:ContingentOrganization;
  public dictStudentBudgetSubCategoryId!: number;
  public dictStudentBudgetSubCategories!:BudgetSubCategory;
  public dictOrganizationEmployerId!: number;
  public dictOrganizationEmployers!:ContingentOrganization;
  public paidContractNum?:string;
  public paidContractDate?:Date;
  public paidContractCostFull?: number;
  public paidContractCostYear?: number;

  public studentBudgetName?: string;
  public organizationName?: string;
  public isBudget?: boolean;

  public budgetCategoryName?: string;
  public budgetSubCategoryName?: string;
  public dictStudentBudgetCategoryId?: string;
}

export class StudentBudgetStateCont{
  public isBudget?: boolean;
  public budget?: string;
  public studentBudgetName?: string;
  public budgetSubCategoryName?:string;
  public dictStudentBudgetId?:Guid;
  public dictOrganizationId?:Guid;
  public dictOrganizations?:ContingentOrganization;
  public organizationEmployerName?: string;
  public budgetType?: BudgetTypeEnum;
}

<education-header></education-header>
<div kendoDialogContainer></div>
<kendo-tabstrip class="custom-tabs k-mt-4">
  <kendo-tabstrip-tab title="Распределение учебной нагрузки" [selected]="true">
    <ng-template kendoTabContent>
      <form class="k-form k-form-horizontal" [formGroup]="disciplineFormGroup">
        <div class="k-display-flex">
          <div class="k-flex-1 k-mr-15">
            <kendo-formfield orientation="horizontal">
              <kendo-label class="form-label">Тип дисциплины</kendo-label>
              <kendo-dropdownlist (valueChange)="onChangedisciplineType($event)" formControlName="dictDisciplineTypeId" [valuePrimitive]="true"
                                  [disabled]="disabled" [data]="disciplineTypes" valueField="id" textField="name">
                <ng-template kendoDropDownListNoDataTemplate></ng-template>
              </kendo-dropdownlist>
              <kendo-formerror>* обязательное поле</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield orientation="horizontal">
              <kendo-label class="form-label">Дисциплина</kendo-label>
              <div>
                <kendo-textbox [(ngModel)]="searchDisciplineByName" size="small" [clearButton]="true" [disabled]="disabled"
                  (valueChange)="onSearchDiscipline()" placeholder="" [ngModelOptions]="{standalone: true}">
                </kendo-textbox>
              </div>
            </kendo-formfield>
            <kendo-formfield [hidden]="!isFacultative" orientation="horizontal">
              <kendo-label class="form-label"></kendo-label>
              <div>
                <kendo-label text="Дисциплина активна" class="custom-checkbox">
                  <input type="checkbox" kendoCheckBox formControlName="active" [disabled]="disabled" />
                </kendo-label>
              </div>
            </kendo-formfield>
            <kendo-grid [hideHeader]="true" [data]="filteredDisciplines" [selectable]="true"
              (selectionChange)="onSelectDiscipline($event)" [height]="296" class="k-mt-5 discipline-grid"
              [loading]="loadingDictDisciplines" [(selectedKeys)]="disciplineSelected" kendoGridSelectBy>
              <ng-template kendoGridLoadingTemplate>
                <div class="k-loading-color"></div>
                <div class="loading-container">
                  <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
                </div>
              </ng-template>
              <ng-template kendoGridNoRecordsTemplate></ng-template>
              <kendo-grid-column field="name"></kendo-grid-column>
            </kendo-grid>
            <div class="k-display-flex k-justify-content-end k-mt-2">
              <a routerLink="/dicts/discipline" target="_blank">
                <button type="button" kendoButton class="edit-button" icon="dictionary-add">Справочник
                  дисциплин</button>
              </a>
            </div>
            <div *ngIf="!isModularDiscipline">
              <h4 class="k-mt-10">Распределение контрольных акций</h4>
              <kendo-grid class="k-mt-5 centered-grid" [data]="semesters">
                <ng-template kendoGridNoRecordsTemplate></ng-template>
                <kendo-grid-column field="number" title="Семестр" [width]="80"></kendo-grid-column>
                <kendo-grid-column *ngFor="let controlAction of controlActions" [title]="controlAction.shortName">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span (click)="onCheckControlAction(dataItem, controlAction)">
                      <input type="checkbox" kendoCheckBox [disabled]="disabled"
                        [checked]="dataItem.controlAction?.dictControlActionId === controlAction.id" />
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="КП">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span (click)="onExtraWorkToggle(dataItem, 'hasCourseProject')">
                      <input type="checkbox" kendoCheckBox [disabled]="!dataItem.controlAction||disabled"
                        [checked]="dataItem.controlAction?.hasCourseProject" />
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="КР">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span (click)="onExtraWorkToggle(dataItem, 'hasCourseWork')">
                      <input type="checkbox" kendoCheckBox [disabled]="!dataItem.controlAction||disabled"
                        [checked]="dataItem.controlAction?.hasCourseWork" />
                    </span>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
          <div class="k-flex-1">
            <kendo-formfield orientation="horizontal">
              <kendo-label class="form-label mini-form-label">Кафедра</kendo-label>
              <kendo-combobox formControlName="dictDepartmentId" [valuePrimitive]="true" [data]="departments" [disabled]="disabled"
                                  valueField="kafedraId" [kendoDropDownFilter]="filterSettings" textField="kafedraWithCode"
                                  #departmentcombobox (focus)="onFocus(departmentcombobox)">
                <ng-template kendoDropDownListNoDataTemplate></ng-template>
              </kendo-combobox>
              <kendo-formerror>* обязательное поле</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield orientation="horizontal">
              <kendo-label class="form-label mini-form-label">Цикл</kendo-label>
              <kendo-dropdownlist formControlName="dictCycleId" [valuePrimitive]="true" [disabled]="disabled||(editable!&&optionalMark&&educationPlanDetailsService.currentEducationPlan.status !== 1)" [data]="cycles"
                valueField="dictCycleExternalId" textField="dictCycleName" (valueChange)="filterPlanComponents($event)">
                <ng-template kendoDropDownListNoDataTemplate></ng-template>
              </kendo-dropdownlist>
              <kendo-formerror>* обязательное поле</kendo-formerror>
            </kendo-formfield>
            <div class="k-display-flex k-mt-5">
              <kendo-label class="form-label mini-form-label"></kendo-label>
              <div class="k-pl-3">
                <kendo-label *ngFor="let component of filteredComponents" [text]="component.dictComponentName"
                  class="custom-radio">
                  <input type="radio" name="dictComponentId" [value]="component.dictComponentExternalId" [disabled]="disabled"
                    formControlName="dictComponentId" kendoRadioButton  #disabledBinding/>
                </kendo-label>
              </div>
            </div>
            <kendo-formfield>
              <kendo-label text="Модуль дисциплин" class="custom-checkbox">
                <input type="checkbox" kendoCheckBox formControlName="isModularDiscipline" id="disabledBinding" [disabled]="disabled"
                  (change)="onModularDisciplineToggle()" />
              </kendo-label>
            </kendo-formfield>
            <kendo-formfield class="k-flex-column">
              <kendo-label class="form-label inline-label">Входит в состав модуля дисциплин</kendo-label>
              <kendo-dropdownlist formControlName="parentDisciplineId" [valuePrimitive]="true" [data]="planDisciplines" [disabled]="disabled"
                valueField="id" textField="name">
                <ng-template kendoDropDownListNoDataTemplate></ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield class="k-flex-column k-mt-0">
              <kendo-label class="form-label inline-label">№ п/п</kendo-label>
              <kendo-numerictextbox formControlName="serialNumber" format="n" [min]="0" [disabled]="disabled"></kendo-numerictextbox>
            </kendo-formfield>
            <div *ngIf="!isModularDiscipline">
              <h4 class="k-mt-10">Распределение нагрузки (в часах)</h4>
              <kendo-grid class="k-mt-5 centered-grid" [data]="laborHours">
                <ng-template kendoGridNoRecordsTemplate></ng-template>
                <kendo-grid-column title="Семестр" [width]="280">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div *ngIf="dataItem.type!=='radio'" [ngClass]="{'bold': dataItem.isBold}" class="k-text-left">
                      {{dataItem.name}}
                    </div>
                    <span *ngIf="dataItem.type==='radio'">
                      <kendo-label *ngFor="let item of dataItem.items" [text]="item[dataItem.name]"
                        class="custom-radio"></kendo-label>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column *ngFor="let activeSemester of activeSemesters; index as i; trackBy: trackByFn"
                  [title]="getSemesterTitle(activeSemester)" [width]="90" class="k-text-center">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <kendo-numerictextbox
                      *ngIf="dataItem.type==='typeWorkInput' && activeSemester.controlAction.typeWorksByKey[dataItem.dictWorkStudyPlanTypeExternalId]"
                      format="n" [min]="0" [disabled]="disabled"
                      [(ngModel)]="activeSemester.controlAction.typeWorksByKey[dataItem.dictWorkStudyPlanTypeExternalId].value"
                      [ngModelOptions]="{standalone: true}" (ngModelChange)="onLaborHoursChange(dataItem)">
                    </kendo-numerictextbox>
                    <kendo-numerictextbox *ngIf="dataItem.type==='input'" format="n" [min]="0" [disabled]="disabled"
                      [(ngModel)]="activeSemester.controlAction[dataItem.value]" [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onLaborHoursChange(dataItem)">
                    </kendo-numerictextbox>
                    <div *ngIf="dataItem.type==='text'" [ngClass]="{'bold': dataItem.isBold}">
                      {{activeSemester.controlAction[dataItem.value]}}
                    </div>
                    <span *ngIf="dataItem.type==='radio'" class="k-display-flex k-flex-column k-align-items-center">
                      <input type="radio" *ngFor="let item of dataItem.items" [disabled]="disabled"
                        [name]="'dictLessonType' + activeSemester.id" style="display: block; margin-bottom: 12px;"
                        [value]="item[dataItem.model]" [(ngModel)]="activeSemester.controlAction[dataItem.value]"
                        [ngModelOptions]="{standalone: true}" kendoRadioButton />
                    </span>
                    <span *ngIf="dataItem.type==='checkbox'">
                      <input type="checkbox" kendoCheckBox [(ngModel)]="activeSemester.controlAction.isDistributed" [disabled]="disabled"
                        [ngModelOptions]="{standalone: true}" />
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Практическая подготовка" [width]="120" *ngIf="typeWorksDisciplineAdditionals.length>0">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div *ngIf="rowIndex === 0" [ngClass]="{'bold': dataItem.isBold}">
                      {{dataItem.totalPractice}}
                    </div>
                    <kendo-numerictextbox
                      *ngIf="dataItem.hasPractice && rowIndex !== 0 && typeWorksAdditionalsByKey[dataItem.dictWorkStudyPlanTypeExternalId]"
                      format="n" [min]="0" class="k-text-center" (valueChange)="onLaborHoursChange(dataItem)" [disabled]="disabled"
                      [(ngModel)]="typeWorksAdditionalsByKey[dataItem.dictWorkStudyPlanTypeExternalId].value"
                      [ngModelOptions]="{standalone: true}"></kendo-numerictextbox>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Всего" [width]="90">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span *ngIf="dataItem.total">
                      {{dataItem.total}}
                    </span>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
              <h4 class="k-mt-10">Итоговая трудоемкость</h4>
              <kendo-grid class="k-mt-5 centered-grid" [data]="laborIntensities">
                <ng-template kendoGridNoRecordsTemplate></ng-template>
                <kendo-grid-column title="Семестр" [width]="280" class="k-text-left">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="k-text-left">
                      {{dataItem.name}}
                    </div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column *ngFor="let activeSemester of activeSemesters"
                  [title]="getSemesterTitle(activeSemester)" [width]="90">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span>
                      {{activeSemester.controlAction[dataItem.value] | number: '1.0-2'}}
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="total" title="Всего" [width]="90">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span>
                      {{dataItem.total | number: '1.0-2'}}
                    </span>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid>
            </div>
          </div>
        </div>
        <div *ngIf="isModularDiscipline">
          <h4 class="k-mt-10">Дисциплины, входящие в состав модуля</h4>
          <kendo-grid class="k-mt-5" [data]="modulDisciplines">
            <ng-template kendoGridLoadingTemplate>
              <div class="k-loading-color"></div>
              <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
              </div>
            </ng-template>
            <ng-template kendoGridNoRecordsTemplate></ng-template>
            <kendo-grid-column field="cipher" title="Шифр" [width]="120"></kendo-grid-column>
            <kendo-grid-column field="name" title="Дисциплина" [style]="{ 'text-align': 'left' }"></kendo-grid-column>
            <kendo-grid-column field="departmentName" title="Кафедра" [width]="200">
            </kendo-grid-column>
            <kendo-grid-column field="semesterName" title="Семестр" [width]="120">
            </kendo-grid-column>
            <kendo-grid-column field="totalLaborIntensity" title="З.ед." [width]="120">
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </form>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="Дисциплины по выбору" [selected]="false">
    <ng-template kendoTabContent>
      <div class="k-display-flex k-justify-content-between">
        <div>
          <kendo-label class="form-label">Дисциплина</kendo-label>
          <kendo-textbox [(ngModel)]="searchModularDisciplineByName" size="small" [clearButton]="true" [disabled]="disabled"
            (valueChange)="onSearchModularDiscipline()" placeholder="" [style.width.px]="370">
          </kendo-textbox>
          <!-- <button kendoButton (click)="onSearchDiscipline()" class="k-ml-3">Поиск</button> -->
        </div>

        <div class="k-display-flex k-align-self-center">
          <a routerLink="/dicts/discipline" target="_blank">
            <button kendoButton class="edit-button" icon="dictionary-add">Справочник дисциплин</button>
          </a>
        </div>
      </div>

      <kendo-grid [hideHeader]="true" [data]="filteredForElectiveDisciplines" [selectable]="true"
        (selectionChange)="onSelectElectiveDiscipline($event)" [height]="296" class="k-mt-5 discipline-grid"
        [loading]="loadingDictDisciplines">
        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>
        <ng-template kendoGridNoRecordsTemplate></ng-template>
        <kendo-grid-column field="name"></kendo-grid-column>
      </kendo-grid>

      <div class="k-display-flex k-justify-content-between k-mt-5">
        <div>
          <kendo-label class="form-label mini-form-label">Кафедра</kendo-label>
          <kendo-combobox [(ngModel)]="activeElectiveDiscipline.departmentId" [valuePrimitive]="true" [disabled]="disabled"
            [data]="departments" [kendoDropDownFilter]="filterSettings" valueField="kafedraId" textField="kafedraWithCode" [style.width.px]="496"
            #departmentcombobox (focus)="onFocus(departmentcombobox)">
            <ng-template kendoDropDownListNoDataTemplate></ng-template>
          </kendo-combobox>
        </div>
        <button kendoButton icon="plus" themeColor="success" (click)="onAddElectiveDiscipline()" *ngIf="!disabled">
          Добавить дисциплину по выбору</button>
      </div>

      <kendo-grid [data]="electiveDisciplines" class="k-mt-5 centered-grid">
        <ng-template kendoGridNoRecordsTemplate></ng-template>
        <kendo-grid-column field="dictDisciplineName" title="Дисциплина">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="k-text-left">
              {{dataItem.dictDisciplineName}}
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Кафедра">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-combobox [(ngModel)]="dataItem.departmentId" [valuePrimitive]="true" [data]="departments"  [disabled]="disabled"
              [kendoDropDownFilter]="filterSettings" valueField="kafedraId" textField="kafedraWithCode"
              #departmentcombobox (focus)="onFocus(departmentcombobox)">
                <ng-template kendoDropDownListNoDataTemplate></ng-template>
            </kendo-combobox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Номер" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox [(ngModel)]="dataItem.serialNumber" format="n" [min]="0" [disabled]="disabled">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Активна" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" kendoCheckBox class="k-mt-1" [(ngModel)]="dataItem.active" [disabled]="disabled" />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="200" *ngIf="!disabled">
          <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
            <button kendoButton (click)="onDeleteElectiveDiscipline(rowIndex)">удалить</button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>

      <!-- <kendo-label text="Вариативное распределение трудоемкости" class="custom-checkbox k-mt-5">
        <input type="checkbox" kendoCheckBox />
      </kendo-label> -->
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<div class="k-display-flex k-justify-content-end k-mt-3">
  <div *ngIf="!disabled" class="k-display-flex k-justify-content-between" [style.width]="'50%'">
    <div>
      <button kendoButton *ngIf="disciplineId" class="k-ml-7" [disabled]="isLoading"
        (click)="onDelete()">удалить</button>
    </div>
    <div class="k-pr-4">
      <button kendoButton (click)="goBackToPlan()" class="k-mr-3">Отмена</button>
      <button kendoButton themeColor="primary" (click)="onSave()" [disabled]="isLoading">Сохранить</button>
    </div>
  </div>
  <button *ngIf="disabled" kendoButton (click)="goBackToPlan()" class="k-mr-3">Отмена</button>
</div>

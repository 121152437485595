import { Injectable } from '@angular/core';
import {Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {ContingentSettingModel} from "../../models/contingent/contingentsetting.model";

@Injectable({
  providedIn: 'root'
})
export class ContingentsettingService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.contingentsetting}`;

  constructor(private http: HttpClient) { }

  public getContingentSettings(): Observable<ContingentSettingModel[]> {
    return this.http.get<ContingentSettingModel[]>(this.baseUrl + '/GetContingentSettings');
  }

  public editContingentSetting(request: ContingentSettingModel[]): Observable<ContingentSettingModel[]> {
    return this.http.post<ContingentSettingModel[]>(this.baseUrl + '/EditContingentSetting/', request)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

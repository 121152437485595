<workload-settings></workload-settings>
<h1>Моя учебная нагрузка</h1>
<span class="workloadDropdownContainer">
    <h3>Учебный год</h3>
    <kendo-dropdownlist class="workloadDropdown" valueField="name" textField="name"
                        [data]="yearAndSemestrs"
                        [(ngModel)]="yearAndSemestrsEdit"
                        (valueChange)="yearAndSemestrsValueChange($event)"
                        [valuePrimitive]="false">
    </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
    <h3>Кафедра</h3>
    <kendo-combobox #comboboxKafedra class="workloadDropdown" textField="name" valueField="id"
                    [data]="kafedras"
                    [openOnFocus]="comboboxKafedra"
                    [valuePrimitive]="false"
                    [virtual]="virtual"
                    [filterable]="true"
                    [kendoDropDownFilter]="filterSettings"
                    [(ngModel)]="kafedraEdit"
                    (valueChange)="kafedraValueChange($event)">
    </kendo-combobox>
</span>
<span class="workloadDropdownContainer">
    <h3>Преподаватель</h3>
    <kendo-combobox #comboboxTeacher class="workloadDropdown" textField="fio" valueField="id"
                    [data]="persons"
                    [kendoDropDownFilter]="filterSettings"
                    [openOnFocus]="comboboxTeacher"
                    [(ngModel)]="teacherEdit"
                    [valuePrimitive]="false"
                    (valueChange)="teacherValueChange($event)">
    </kendo-combobox>
</span>
<span class="workloadDropdownContainer">
    <h3>Уровень подготовки</h3>
    <kendo-multiselect class="workloadDropdown" textField="name" valueField="id"
                       [data]="trainingLevels"
                       [kendoDropDownFilter]="filterSettings"
                       [valuePrimitive]="true"
                       [value]="trainingLevelsEdit"
                       (valueChange)="trainingLevelValueChange($event)">
    </kendo-multiselect>
</span>
<span class="workloadDropdownContainer">
    <h3>Институт (факультет)</h3>
    <kendo-dropdownlist class="workloadDropdown" valueField="id" textField="sName"
                        [data]="faculties"
                        [valuePrimitive]="false"
                        [(ngModel)]="facultiesEdit"
                        (valueChange)="facultiesValueChange($event)">
    </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer last">
    <h3>Форма обучения</h3>
    <kendo-multiselect class="workloadDropdown" textField="studyFormName" valueField="id"
                       (valueChange)="studyFormValueChange($event)"
                       [value]="studyFormEdit"
                       [valuePrimitive]="true"
                       [data]="studyForms">
    </kendo-multiselect>
</span>
<span class="buttons" *ngIf='userEditing'>
  <button kendoButton
          *ngIf="!isEditTeacherLoad"
          [primary]="true"
          (click)="enterLoad()"
  >Ввести нагрузку</button>
    <span class="handlerButtons" style="margin-bottom: 0;" *ngIf="isEditTeacherLoad">
      <button themeColor="primary" kendoButton (click)="saveLoad()">Сохранить</button>
      <button class="cancelButton" kendoButton (click)="cancelInputLoad()">Отмена</button>
    </span>
    <button kendoButton class="export" icon="download" (click)='exportStudyWork()'>Моя учебная работа</button>
    <button kendoButton icon="download" (click)='exportPlanFact()'>План-факт</button>
</span>

<kendo-grid #grid class="grid"
            [height]="600"
            [kendoGridBinding]="gridTeacherLoads"
            [rowClass]="rowCallback">
  <kendo-grid-column headerClass="gridHeader" field="groups" [width]="90" title="Семестр">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment bold disciplineGroup" *ngIf="dataItem.isDiscipline">
        {{ dataItem.isDiscipline!=undefined ? dataItem.discipline.name : "" }}
      </span>
      <span class="alignCells" *ngIf="dataItem.isFlow || dataItem.isGroup">
        {{dataItem.semesterNumber}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="type" [width]="320" title="Академические группы (кол-во студентов факт, кол-во подгрупп, чел)">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment groupFlowGroup" *ngIf="dataItem.isFlow || dataItem.isGroup">
        <span class="bold">{{ displayGroupFlowName(dataItem) }}</span> {{ displayGroupFlowInfo(dataItem) }}
      </span>
      <span class="alignCells leftAlignment groupFlowGroup" *ngIf="dataItem.classesBySubGroups">
        <span class="bold">{{ (dataItem.subGroupNumber ? "Подгруппа " +  dataItem.subGroupNumber : "") }}</span>
      </span>
      <span class="alignCells leftAlignment" *ngIf="dataItem.isGroupWorkType || dataItem.isFlowWorkType">
        {{ dataItem.typeWorks.typeWork.name }}
      </span>
      <span class="alignCells leftAlignment subGroupGroup" *ngIf="dataItem.isSubGroup">
        {{ "Подгруппа " + dataItem.subGroup.number }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="studentCalc" [width]="150" title="Студ. / гр. в расчет">
    <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells" *ngIf="(dataItem.isGroupWorkType || dataItem.isFlowWorkType)">
              {{dataItem.typeWorks !== undefined ? dataItem.typeWorks.coefficient : ""}}
            </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="workloadStandard" [width]="150" title="Норма нагрузки">
    <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells" *ngIf="(dataItem.isGroupWorkType || dataItem.isFlowWorkType)">
              {{ dataItem.typeWorks?.typeWork?.hoursInsteadOfTypeWorkCoefficient
              ? dataItem.typeWorks?.typeWorkHours : dataItem.typeWorks?.typeWork?.coefficient}}
            </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="worloadHours" [width]="150" title="Нагрузка, час.">
    <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells bold">
              {{(dataItem.isGroupWorkType || dataItem.isFlowWorkType) ? dataItem.typeWorks.hours : dataItem.isSubGroup ? dataItem.subGroup.hours : ""}}
            </span>
    </ng-template>
    <ng-template kendoGridEditTemplate let-formGroup *ngIf="isSubGroup">
                <span class="alignCells">
                    <kendo-numerictextbox format="false" [formControl]="formGroup.get('hours')"></kendo-numerictextbox>
                </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="courseNum" [width]="150" title="Параметры">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{displayParameters(dataItem)}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="completedHours" [width]="200" title="Выполнено, час.">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells" *ngIf="!isEditTeacherLoad" [style.color]="
        colorCode((dataItem.isGroupWorkType || dataItem.isFlowWorkType)
          ? {completedHours:dataItem.typeWorks.completedHours, loadHours: dataItem.typeWorks.hours}
          : dataItem.isSubGroup
            ? {completedHours:dataItem.subGroup.completedHours, loadHours: dataItem.subGroup.hours}
            : {})">
        {{(dataItem.isGroupWorkType || dataItem.isFlowWorkType)
          ? dataItem.typeWorks.completedHours
          : dataItem.isSubGroup
            ? dataItem.subGroup.completedHours
            : ""}}
      </span>
      <kendo-numerictextbox *ngIf="isEditTeacherLoad && (dataItem.isGroupWorkType && !dataItem.hasSubGroups || dataItem.isFlowWorkType || dataItem.isSubGroup)"
                            [min]="0.0" [max]="999.0"
                            [changeValueOnScroll]="false"
                            [decimals]='2'
                            [format]='numberFormatOptions'
                            [value]="(dataItem.isGroupWorkType || dataItem.isFlowWorkType)
                              ? dataItem.typeWorks.completedHours
                              : dataItem.isSubGroup ? dataItem.subGroup.completedHours : 0"
                            (valueChange)="loadChange($event, $any(dataItem))"
      ></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<h1>Дополнительные работы</h1>
<kendo-grid #extraGrid
            [ngClass]="{noData: extraWork.length < 4}"
            class="extraGrid"
            [height]="300"
            [kendoGridBinding]="extraWork">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand style="margin-bottom: 20px" *ngIf="!isEditAdditionalWork"
            [primary]="true"
            (click)="enterAdditionalLoad()"
    >Списать нагрузку</button>
    <span class="handlerButtons" *ngIf="isEditAdditionalWork">
      <button themeColor="primary" kendoButton (click)="saveAdditionalLoad()">Сохранить</button>
      <button class="cancelButton" kendoButton (click)="cancelInputAdditionalLoad()">Отмена</button>
    </span>
  </ng-template>
  <kendo-grid-column headerClass="gridHeader" field="dictAdditionalWork" [width]="400" title="Дополнительная работа">
    <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
              {{dataItem.dictAdditionalWork.name}}
            </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="eduGroup.name" [width]="150" title="Группа">
    <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.eduGroup.name}} ({{dataItem.eduGroup.studentCount}} чел.)
            </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="totalStudentsLoad" [width]="150" title="Кол-во в расчет">
    <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                  {{dataItem.totalStudentsLoad}}
                </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader bold" field="loadHours" [width]="100" title="Нагрузка, час.">
    <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells bold">
              {{dataItem.loadHours}}
            </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column headerClass="gridHeader" field="completedHours" [width]="100" title="Выполнено, час.">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells" *ngIf='!isEditAdditionalWork' [style.color]="colorCode(dataItem)">
        {{dataItem.completedHours}}
      </span>
      <kendo-numerictextbox format="false" *ngIf="isEditAdditionalWork"
                            [min]="0" [max]="999"
                            [changeValueOnScroll]="false"
                            [format]='numberFormatOptions'
                            [decimals]='2'
                            [value]="dataItem.completedHours"
                            (valueChange)="additionalLoadChange($event, $any(dataItem))"
      ></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<kendo-dialog
  title="Please confirm"
  *ngIf="itemToRemove"
  (close)="confirmRemove(false)">
  <p style="margin: 30px; text-align: center;">
    Are you sure you want to delete product {{ itemToRemove.ProductName }}?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="confirmRemove(false)">No</button>
    <button
      kendoButton
      themeColor="primary"
      (click)="confirmRemove(true)"
      [primary]="true">
      Yes
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

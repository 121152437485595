<ng-container>
  <form [class]="{
                   'k-form': true,
                   'k-form_with-grid': type === MilitaryCertificateTypes.form10
                 }"
        [formGroup]="certificateForm">
    <dl class="edit-form">
      <div class="content-row">
        <kendo-label text="ФИО обучающегося"></kendo-label>
        <dd>
          <span *ngIf="studentId; else fioField">
            {{ certificateForm.get('fio')?.value }}
          </span>
          <ng-template #fioField>
            <kendo-formfield>
              <kendo-combobox [kendoDropDownFilter]="filterSettings"
                              [data]="students"
                              textField="fullNameWithGroup"
                              valueField="studentId"
                              (valueChange)="studentChange($event)"
                              [valuePrimitive]="true"
                              [virtual]="true"
                              formControlName="studentId"
                              [disabled]="!!studentId"
                              [clearButton]="true">
              </kendo-combobox>
              <span class="required">*</span>
            </kendo-formfield>
          </ng-template>
        </dd>
      </div>
      <div class="content-row">
        <kendo-label text="Шаблон"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist [data]="templates"
                                textField="name"
                                valueField="externalId"
                                [valuePrimitive]="true"
                                formControlName="militaryFormPrintTemplateId">
            </kendo-dropdownlist>
            <span class="required">*</span>
          </kendo-formfield>
        </dd>
      </div>
    </dl>

    <h4 class="edit-form">Основные сведения</h4>

    <dl class="edit-form" *ngIf="type === MilitaryCertificateTypes.appendix4
                              || type === MilitaryCertificateTypes.appendix5
                              || type === MilitaryCertificateTypes.appendix2">
      <div class="content-row">
        <kendo-label text="Регистрационный номер"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-textbox formControlName="number" [clearButton]="true"></kendo-textbox>
            <span class="required">*</span>
          </kendo-formfield>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Дата регистрации"></kendo-label>
        <dd>
          {{ certificateForm?.value.registrationDate | date: "dd.MM.yyyy" }}
        </dd>
      </div>
    </dl>

    <dl class="edit-form" *ngIf="type === MilitaryCertificateTypes.form10
                              || type === MilitaryCertificateTypes.appendix2">
      <div class="content-row">
        <kendo-label text="Дата рождения"></kendo-label>
        <dd class="edit-form edit-form_nested">
          {{ !birthday ? '-' : birthday | date: "dd.MM.yyyy" }}
          <dl class="edit-form edit-form_nested" *ngIf="type === MilitaryCertificateTypes.form10">
            <div class="content-row">
              <kendo-label class="additional" text="Место рождения"></kendo-label>
              <dd>
                <span>
                  {{ studentInfo?.birthPlace ?? formData?.studentInfo?.birthPlace ?? '-' }}
                </span>
              </dd>
            </div>
          </dl>
        </dd>
      </div>
    </dl>

    <ng-container *ngIf="type === MilitaryCertificateTypes.form10">
      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Семейное положение"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.familyStateId ?? formData?.studentInfo?.familyState ?? '-' }}
            </span>
          </dd>
        </div>
      </dl>

      <kendo-label class="edit-form" text="Информация о родственниках"></kendo-label>
      <dl class="edit-form">
        <dd class="grid-container grid">
          <kendo-grid [data]="studentInfo?.familyMembers ?? formData?.studentInfo?.familyMembers ?? []" scrollable="none">
            <ng-template kendoGridLoadingTemplate>
              <div class="k-loading-color"></div>
              <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
              </div>
            </ng-template>

            <kendo-grid-column field="fio"
                               headerClass="gridHeader dictionaries"
                               title="ФИО" [width]="500">
              <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{ dataItem.fio }}
              </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="relationDegree"
                               headerClass="gridHeader dictionaries"
                               title="Степень родства" [width]="300">
              <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{ dataItem?.familyMemberStatusName }}
              </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="birthDay"
                               headerClass="gridHeader dictionaries"
                               title="Дата рождения" [width]="230">
              <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{ dataItem.birthday | date: "dd.MM.yyyy" }}
              </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </dd>
      </dl>

      <kendo-label class="edit-form" text="Иностранные языки"></kendo-label>
      <dl class="edit-form">
        <dd class="grid-container grid">
          <kendo-grid [data]="studentInfo?.foreignLanguages ?? formData?.studentInfo?.militaryFormForeignLanguages ?? []" scrollable="none">
            <ng-template kendoGridLoadingTemplate>
              <div class="k-loading-color"></div>
              <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
              </div>
            </ng-template>

            <kendo-grid-column field="name"
                               headerClass="gridHeader dictionaries"
                               title="Наименование языка" [width]="515">
              <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{ dataItem?.languageName }}
              </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="knowledgeDegree"
                               headerClass="gridHeader dictionaries"
                               title="Степень знания" [width]="515">
              <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells">
                {{ dataItem?.levelName }}
              </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </dd>
      </dl>

      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Паспорт"></kendo-label>
          <dd>
            Серия: {{ studentInfo?.identification?.serial ?? formData?.studentInfo?.passport?.serial ?? '-' }}
            Номер: {{ studentInfo?.identification?.number ?? formData?.studentInfo?.passport?.number ?? '-' }}
            Дата выдачи: {{
              studentInfo?.identification?.issueDate ?? formData?.studentInfo?.passport?.issueDate | date: "dd.MM.yyyy"
            }}
            Кем выдан: {{ studentInfo?.identification?.issueOrganization ?? formData?.studentInfo?.passport?.issueOrganization }}
          </dd>
        </div>
      </dl>
    </ng-container>

    <dl class="edit-form" *ngIf="type === MilitaryCertificateTypes.appendix4
                              || type === MilitaryCertificateTypes.appendix5">
      <div class="content-row">
        <kendo-label text="Год рождения"></kendo-label>
        <dd>
          {{ !birthYear ? '-' : birthYear }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Военный комиссариат"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-combobox [kendoDropDownFilter]="filterSettings"
                            [data]="militaryCommissariats"
                            textField="commissariatName"
                            valueField="id"
                            [openOnFocus]="commissariat"
                            #commissariat
                            [valuePrimitive]="true"
                            formControlName="dictMilitaryCommissariatId"
                            [clearButton]="true">
            </kendo-combobox>
            <span class="required">*</span>
          </kendo-formfield>
        </dd>
      </div>
    </dl>

    <dl class="edit-form" *ngIf="type === MilitaryCertificateTypes.appendix2">
      <div class="content-row">
        <kendo-label text="На основании приказа"></kendo-label>
        <dd>
          <kendo-formfield>
            <kendo-dropdownlist [data]="orders"
                                textField="text"
                                valueField="id"
                                [valuePrimitive]="true"
                                formControlName="orderId">
            </kendo-dropdownlist>
          </kendo-formfield>
        </dd>
      </div>
    </dl>

    <ng-container *ngIf="type === MilitaryCertificateTypes.appendix2">
      <h4 class="edit-form">Образование</h4>

      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Состояние студента"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-dropdownlist [data]="militaryAcademicStates"
                                  textField="text"
                                  valueField="value"
                                  [valuePrimitive]="true"
                                  formControlName="isEnrolled">
              </kendo-dropdownlist>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Должность"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox formControlName="employerPost" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>
      </dl>
    </ng-container>

    <ng-container *ngIf="type === MilitaryCertificateTypes.appendix4
                      || type === MilitaryCertificateTypes.appendix5">
      <h4 class="edit-form">Склонение ФИО</h4>
      <h3 class="edit-form">(Дательный падеж)</h3>

      <dl class="edit-form edit-form_row">
        <div>
          <kendo-label text="Фамилия"></kendo-label>
          <dd>
            <kendo-formfield class="additional">
              <kendo-textbox formControlName="dlastName" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>
        <div>
          <kendo-label text="Имя"></kendo-label>
          <dd>
            <kendo-formfield class="additional">
              <kendo-textbox formControlName="dfirstName" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>
        <div>
          <kendo-label text="Отчество"></kendo-label>
          <dd>
            <kendo-formfield class="additional">
              <kendo-textbox formControlName="dmiddleName" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>
      </dl>

      <h4 class="edit-form">Информация об обучении</h4>

      <dl class="edit-form">
        <div class="content-row" *ngIf="type === MilitaryCertificateTypes.appendix4">
          <kendo-label text="Год поступления"></kendo-label>
          <dd>
            <span *ngIf="formData?.studyPeriodStartYear || studentInfo?.studyPeriodStartYear; else yearField">
              {{ certificateForm.get('studyPeriodStartYear')?.value }}
            </span>
            <ng-template #yearField>
              <kendo-formfield>
                <kendo-numerictextbox formControlName="studyPeriodStartYear" format="n0" [min]="1950" [decimals]="0"></kendo-numerictextbox>
                <span class="required">*</span>
              </kendo-formfield>
            </ng-template>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Уровень образования, на основании которого гражданин принят на обучение"></kendo-label>
          <dd>
            <span *ngIf="formData?.previousDictStudyLevelId || studentInfo?.previousDictStudyLevelId; else levelField">
              {{ getStudyLevelName(certificateForm.get('previousDictStudyLevelId')?.value) }}
            </span>
            <ng-template #levelField>
              <kendo-formfield>
                <kendo-combobox [data]="studyLevels"
                                textField="studyLevelName"
                                valueField="dictStudyLevelExternalId"
                                [valuePrimitive]="true"
                                #educationLevel
                                [openOnFocus]="educationLevel"
                                [kendoDropDownFilter]="filterSettings"
                                formControlName="previousDictStudyLevelId">
                </kendo-combobox>
              </kendo-formfield>
            </ng-template>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Образовательная организация"></kendo-label>
          <dd>
            <span *ngIf="formData?.educationOrganization || studentInfo?.universityName; else filialField">
              {{ certificateForm.get('educationOrganization')?.value }}
            </span>
            <ng-template #filialField>
              <kendo-formfield>
                <kendo-combobox [data]="filials"
                                textField="filialName"
                                valueField="filialName"
                                [valuePrimitive]="true"
                                #filial
                                [openOnFocus]="filial"
                                [kendoDropDownFilter]="filterSettings"
                                formControlName="educationOrganization">
                </kendo-combobox>
                <span class="required">*</span>
              </kendo-formfield>
            </ng-template>
          </dd>
        </div>

        <ng-container *ngIf="type === MilitaryCertificateTypes.appendix4">
          <div class="content-row">
            <kendo-label text="Код и наименование направления подготовки или специальности"></kendo-label>
            <dd>
              <span *ngIf="formData?.educationStandard || studentInfo?.educationStandard; else standardField">
                {{ certificateForm.get('educationStandard')?.value }}
              </span>
              <ng-template #standardField>
                <kendo-formfield>
                  <kendo-textbox formControlName="educationStandard" [clearButton]="true"></kendo-textbox>
                  <span class="required">*</span>
                </kendo-formfield>
              </ng-template>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Курс"></kendo-label>
            <dd>
              <span *ngIf="formData?.courseNum || studentInfo?.courseNum; else courseField">
                {{ certificateForm.get('courseNum')?.value }}
              </span>
              <ng-template #courseField>
                <kendo-formfield>
                  <kendo-dropdownlist [data]="courses"
                                      textField="course"
                                      valueField="courseNum"
                                      [valuePrimitive]="true"
                                      formControlName="courseNum">
                  </kendo-dropdownlist>
                  <span class="required">*</span>
                </kendo-formfield>
              </ng-template>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Наименование профессии, специальности, направления подготовки"></kendo-label>
            <dd>
              <span *ngIf="formData?.profession || studentInfo?.profession; else professionField">
                {{ certificateForm.get('profession')?.value }}
              </span>
              <ng-template #professionField>
                <kendo-formfield>
                  <kendo-textbox formControlName="profession" [clearButton]="true"></kendo-textbox>
                  <span class="required">*</span>
                </kendo-formfield>
              </ng-template>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Срок получения образования по образовательной программе"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-datepicker formControlName="educationTerm"></kendo-datepicker>
              </kendo-formfield>
            </dd>
          </div>
        </ng-container>

        <ng-container *ngIf="type === MilitaryCertificateTypes.appendix5">
          <div class="content-row">
            <kendo-label text="Наименование и код программы подготовки научно-педагогических кадров в аспирантуре, программы ординатуры">
            </kendo-label>
            <dd>
              <span *ngIf="formData?.educationStandard || studentInfo?.educationProgramData; else programField">
                {{ certificateForm.get('educationStandard')?.value }}
              </span>
              <ng-template #programField>
                <kendo-formfield>
                  <kendo-textbox formControlName="educationStandard" [clearButton]="true"></kendo-textbox>
                  <span class="required">*</span>
                </kendo-formfield>
              </ng-template>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата и номер свидетельства о государственной аккредитации, срок действия, наименование аккредитационного органа, выдавшего свидетельство">
            </kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textarea formControlName="stateAccreditationCertificateData"></kendo-textarea>
                <span class="required">*</span>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата и номер приказа о зачислении на обучение"></kendo-label>
            <dd>
              {{ certificateForm.get('orderData')?.value ?? '-' }}
            </dd>
          </div>
        </ng-container>

        <div class="content-row">
          <kendo-label text="Дата окончания обучения"></kendo-label>
          <dd>
            <span *ngIf="formData?.studyPeriodEndDate
                      || studentInfo?.studyPeriodEndDate
                      || studentInfo?.educationEndDate; else periodField">
              {{ certificateForm.get('studyPeriodEndDate')?.value | date: "dd.MM.yyyy" }}
            </span>
            <ng-template #periodField>
              <kendo-formfield>
                <kendo-datepicker formControlName="studyPeriodEndDate"></kendo-datepicker>
              </kendo-formfield>
            </ng-template>
          </dd>
        </div>

        <ng-container *ngIf="type === MilitaryCertificateTypes.appendix5">
          <div class="content-row">
            <kendo-label text="Шифр совета по защите диссертаций"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="dissertationDefenseCouncilCode" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Наименование организации, на базе которой создан совет по защите диссертации на соискание ученой степени кандидата наук, на соискание ученой степени доктора наук">
            </kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-combobox [data]="organizations"
                                textField="organizationName"
                                valueField="dictOrganizationExternalId"
                                (valueChange)="organizationChange($event)"
                                [valuePrimitive]="true"
                                #organization
                                [openOnFocus]="organization"
                                [kendoDropDownFilter]="filterSettings"
                                formControlName="councilBaseOrganizationId">
                </kendo-combobox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Номер приказа о создании совета по защите диссертации на соискание ученой степени кандидата наук, на соискание ученой степени доктора наук, наименование органа, (организации), издавшего (ей) приказ">
            </kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-textbox formControlName="councilOrderData" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата приказа о создании совета по защите диссертации на соискание ученой степени кандидата наук, на соискание ученой степени доктора наук, наименование органа, (организации), издавшего (ей) приказ">
            </kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-datepicker formControlName="councilOrderDate"></kendo-datepicker>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата защиты"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-datepicker formControlName="dissertationDefenseDate"></kendo-datepicker>
              </kendo-formfield>
            </dd>
          </div>
        </ng-container>
      </dl>
    </ng-container>

    <ng-container *ngIf="type === MilitaryCertificateTypes.form10">
      <h4 class="edit-form">Предыдущее образование</h4>

      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Уровень подготовки"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.diplomaInformation?.studyLevelName ?? formData?.studentInfo?.previousEducation?.studyLevelName ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Наименование образовательной организации"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.diplomaInformation?.issueOrganization ?? formData?.studentInfo?.previousEducation?.issueOrganization ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Квалификация"></kendo-label>
          <dd class="edit-form edit-form_nested">
            <span>
              {{ studentInfo?.diplomaInformation?.qualificationName ?? formData?.studentInfo?.previousEducation?.qualificationName ?? '-' }}
            </span>
            <dl class="edit-form edit-form_nested">
              <div class="content-row">
                <kendo-label class="additional" text="Специальность"></kendo-label>
                <dd>
                  <span>
                    {{ studentInfo?.diplomaInformation?.graduateSpecial ?? formData?.studentInfo?.previousEducation?.graduateSpecial ?? '-' }}
                  </span>
                </dd>
              </div>
            </dl>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Серия и номер диплома"></kendo-label>
          <dd class="edit-form edit-form_nested">
            <span>
              {{ studentInfo?.diplomaInformation?.serialNumber ?? formData?.studentInfo?.previousEducation?.serialNumber ?? '-' }}
            </span>
            <dl class="edit-form edit-form_nested">
              <div class="content-row">
                <kendo-label class="additional" text="Год окончания"></kendo-label>
                <dd>
                  <span>
                    {{ studentInfo?.diplomaInformation?.graduateYear ?? formData?.studentInfo?.previousEducation?.graduateYear ?? '-' }}
                  </span>
                </dd>
              </div>
            </dl>
          </dd>
        </div>
      </dl>

      <h4 class="edit-form">Профессия</h4>

      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Основная"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.mainProfession ?? formData?.studentInfo?.mainProfession ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Дополнительная"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox formControlName="additionalProfession" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>
      </dl>

      <h4 class="edit-form">Водительское удостоверение</h4>

      <dl class="edit-form" formGroupName="driverLicense">
        <div class="content-row">
          <kendo-label text="Серия"></kendo-label>
          <dd class="edit-form edit-form_nested">
            <kendo-formfield class="additional">
              <kendo-textbox formControlName="licenseSerial" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
            <dl class="edit-form edit-form_nested">
              <div class="content-row">
                <kendo-label class="additional" text="Номер"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-textbox formControlName="licenseNumber" [clearButton]="true"></kendo-textbox>
                  </kendo-formfield>
                </dd>
              </div>
            </dl>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Категория ТС"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox formControlName="vehicleCategory" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Дата выдачи"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-datepicker formControlName="licenseIssueDate"></kendo-datepicker>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Срок окончания действия"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-datepicker formControlName="licenseEndDate"></kendo-datepicker>
            </kendo-formfield>
          </dd>
        </div>
      </dl>
    </ng-container>

    <ng-container *ngIf="type === MilitaryCertificateTypes.form10 || type === MilitaryCertificateTypes.appendix2">
      <h4 class="edit-form">Адрес места жительства</h4>

      <dl class="edit-form">
        <ng-container *ngIf="type === MilitaryCertificateTypes.form10">
          <div class="content-row">
            <kendo-label text="Адрес регистрации"></kendo-label>
            <dd>
              <span>
                {{ studentInfo?.registrationAddress ?? formData?.studentInfo?.registrationAddress ?? '-' }}
              </span>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Дата регистрации"></kendo-label>
            <dd>
              <span>
                {{ (studentInfo?.registrationDate ?? formData?.studentInfo?.registrationDate | date: "dd.MM.yyyy") ?? '-' }}
              </span>
            </dd>
          </div>
        </ng-container>

        <div class="content-row">
          <kendo-label text="Фактический адрес проживания"></kendo-label>
          <dd>
            <span *ngIf="type === MilitaryCertificateTypes.form10; else appendix2">
              {{ studentInfo?.residenceAddress ?? formData?.studentInfo?.residenceAddress ?? '-' }}
            </span>
            <ng-template #appendix2>
              <span *ngIf="formData?.actualAddress || studentInfo?.residenceAddress; else addressField">
                {{ certificateForm.get('actualAddress')?.value }}
              </span>
              <ng-template #addressField>
                <kendo-formfield>
                  <kendo-textbox formControlName="actualAddress" [clearButton]="true"></kendo-textbox>
                </kendo-formfield>
              </ng-template>
            </ng-template>
          </dd>
        </div>

        <div class="content-row" *ngIf="type === MilitaryCertificateTypes.form10">
          <kendo-label text="Дата начала проживания"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.residenceDate ?? formData?.studentInfo?.residenceDate | date: "dd.MM.yyyy" }}
            </span>
          </dd>
        </div>
      </dl>
    </ng-container>

    <ng-container *ngIf="type === MilitaryCertificateTypes.form10">
      <h4 class="edit-form">Номера телефонов</h4>

      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Рабочий"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.workPhone ?? formData?.studentInfo?.workPhone ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Сотовый"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.mobilePhone ?? formData?.studentInfo?.mobilePhone ?? '-' }}
            </span>
          </dd>
        </div>
      </dl>
    </ng-container>

    <ng-container *ngIf="type === MilitaryCertificateTypes.form10 || type === MilitaryCertificateTypes.appendix2">
      <h4 class="edit-form">Сведения о воинском учете</h4>

      <dl class="edit-form" *ngIf="type === MilitaryCertificateTypes.appendix2">
        <div class="content-row">
          <kendo-label text="Военный комиссариат"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox [kendoDropDownFilter]="filterSettings"
                              [data]="militaryCommissariats"
                              textField="commissariatName"
                              valueField="id"
                              [openOnFocus]="commissariat"
                              #commissariat
                              [valuePrimitive]="true"
                              (valueChange)="changeCommissariatAddress($event)"
                              formControlName="dictMilitaryCommissariatId"
                              [clearButton]="true">
              </kendo-combobox>
              <span class="required">*</span>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Адрес военного комиссариата"></kendo-label>
          <dd>
            {{ certificateForm?.value.militaryCommissariatAddress ?? '-' }}
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Воинское звание"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-combobox [data]="militaryRanks"
                              textField="militaryRankName"
                              valueField="dictMilitaryRankExternalId"
                              [valuePrimitive]="true"
                              #militaryRank
                              [openOnFocus]="militaryRank"
                              [kendoDropDownFilter]="filterSettings"
                              formControlName="dictMilitaryRankId">
              </kendo-combobox>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Номер военно-учетной специальности"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox formControlName="militarySpecialtyNumber" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>
      </dl>

      <dl class="edit-form" *ngIf="type === MilitaryCertificateTypes.form10">
        <div class="content-row">
          <kendo-label text="Категория запаса"></kendo-label>
          <dd>
            <span>
              {{ getStockCategoryName(studentInfo?.dictMilitaryStockCategoryExternalId) ?? formData?.studentInfo?.dictMilitaryStockCategoryName ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Воинское звание"></kendo-label>
          <dd>
            <span>
              {{ getMilitaryRankName(studentInfo?.dictMilitaryRankExternalId) ?? formData?.studentInfo?.dictMilitaryRankName ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Состав (профиль)"></kendo-label>
          <dd>
            <span>
              {{ getMilitaryProfileName(studentInfo?.dictMilitaryProfileExternalId) ?? formData?.studentInfo?.dictMilitaryProfileName ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Полное кодовое обозначение ВУС"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox formControlName="militarySpecialtyNumber" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Категория годности к военной службе"></kendo-label>
          <dd>
            <span>
              {{ getMilitaryStatusName(studentInfo?.dictMilitaryStatusExternalId) ?? formData?.studentInfo?.dictMilitaryStatusName ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Военный комиссариат"></kendo-label>
          <dd>
            <span>
              {{ getCommissariatName(studentInfo?.dictMilitaryCommissariatId) ?? formData?.studentInfo?.dictMilitaryCommissariatName ?? '-' }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Документ воинского учета"></kendo-label>
          <dd>
            <span>
              {{ getMilitaryDocTypeName(studentInfo?.dictMilitaryDocTypeExternalId) ?? formData?.studentInfo?.dictMilitaryDocTypeName ?? '-' }}
            </span>
          </dd>
        </div>
      </dl>
    </ng-container>

    <ng-container *ngIf="type === MilitaryCertificateTypes.form10">
      <h4 class="edit-form">Сведения о приеме и увольнении (переводе)</h4>

      <dl class="edit-form">
        <dd class="grid-container grid">
          <kendo-grid [data]="studentInfo?.studentOrders ?? formData?.studentInfo?.orders ?? []">
            <ng-template kendoGridLoadingTemplate>
              <div class="k-loading-color"></div>
              <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
              </div>
            </ng-template>

            <kendo-grid-column field="name"
                               headerClass="gridHeader dictionaries"
                               title="Дата и номер приказа (распоряжения)">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                  {{ getDateNumberText(dataItem.date, dataItem.number) }}
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="postName"
                               headerClass="gridHeader dictionaries"
                               title="Наименование должности, на которую принят (переведен) или с которой уволен">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                  {{ dataItem?.title }}
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="militaryCommissariat"
                               headerClass="gridHeader dictionaries"
                               title="Военный комиссариат, в который направлены сведения">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                  {{ dataItem?.dictMilitaryCommissariatName }}
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="knowledgeDegree"
                               headerClass="gridHeader dictionaries"
                               title="Дата и исходящий номер сведений гражданина">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                  {{ getDateNumberText(dataItem?.militaryFormTwoDate, dataItem?.militaryFormTwoNumber) }}
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </dd>
      </dl>

      <h4 class="edit-form">Отметка о снятии с воинского учета</h4>

      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Дата"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.dateRemoveFromMilitaryEnlistment ?? formData?.studentInfo?.dateRemoveFromMilitaryEnlistment | date: "dd.MM.yyyy" }}
            </span>
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Причина"></kendo-label>
          <dd>
            <span>
              {{ studentInfo?.reasonOfRemoveFromMilitaryEnlistment ?? formData?.studentInfo?.reasonOfRemoveFromMilitaryEnlistment ?? '-' }}
            </span>
          </dd>
        </div>
      </dl>

      <h4 class="edit-form">Примечание</h4>

      <dl class="edit-form">
        <div class="content-row">
          <kendo-label text="Примечание"></kendo-label>
          <dd>
            <kendo-formfield>
              <kendo-textbox formControlName="comment" [clearButton]="true"></kendo-textbox>
            </kendo-formfield>
          </dd>
        </div>
      </dl>
    </ng-container>

    <ng-container *ngIf="type === MilitaryCertificateTypes.appendix4
                              || type === MilitaryCertificateTypes.appendix5
                              || type === MilitaryCertificateTypes.appendix2">
      <h4 class="edit-form">Подписанты</h4>

      <ng-container *ngFor="let item of signatories?.controls; let i = index" formArrayName="signatories">
        <dl class="edit-form" *ngIf="!signatoryEditMode[i]">
          <span class="signatory">
            <h3>
              {{ getSignatory(item.value) }}
            </h3>
            <span class="required">*</span>
          </span>
          <dd class="outlineButtons">
            <button (click)="editSignatory(i)"
                    kendoButton
                    size="small"
                    class="certificate-right-button"
                    fillMode="outline"
                    icon="edit">
            </button>
          </dd>
        </dl>

        <ng-template [ngIf]="signatoryEditMode[i]">
          <dl class="edit-form" [formGroupName]="i">
            <div class="content-row">
              <kendo-label text="Должность подписанта"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-combobox [data]="personPosts"
                                  #post
                                  [openOnFocus]="post"
                                  (valueChange)="postChange(i, $event)"
                                  [kendoDropDownFilter]="filterSettings"
                                  [valuePrimitive]="true"
                                  formControlName="postName">
                  </kendo-combobox>
                  <span class="required">*</span>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="ФИО подписанта"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-combobox [data]="signatoriesList"
                                  textField="fio"
                                  valueField="fio"
                                  #person
                                  [openOnFocus]="person"
                                  [kendoDropDownFilter]="filterSettings"
                                  (valueChange)="fioChange(i, $event)"
                                  formControlName="fio"
                                  [valuePrimitive]="true">
                  </kendo-combobox>
                  <span class="required">*</span>
                </kendo-formfield>
              </dd>
            </div>
          </dl>

          <div class="edit-form close-button">
            <button kendoButton (click)="closeSignatory()">
              Закрыть
            </button>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>

    <div class="form-buttons">
      <div>
        <button kendoButton
                size="small"
                (click)="closeHandler()">
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                (click)="saveCertificate()">
          Сохранить
        </button>
      </div>
      <button kendoButton
              size="small"
              themeColor="primary"
              icon="print"
              (click)="saveCertificate(true)">
        Сохранить и распечатать
      </button>
    </div>
  </form>
</ng-container>

<div class="buttons" *ngIf="editable">
  <button kendoButton (click)="cancelChanges()">Отмена</button>
  <button (click)="saveTabChanges()" themeColor="primary" kendoButton>Сохранить</button>
</div>

<div class="form">
  <span class="field-tab">
    <h3>Вкладка</h3>
    <span class="input">
      <kendo-dropdownlist
              [data]="tabsSettings"
              (valueChange)="onTabChange($event)"
              #tabDropdown
              valueField="name"
              textField="name">
      </kendo-dropdownlist>
      <ng-container *ngIf="editable">
        <button (click)="openDialog(true)" [primary]="true" icon="plus" kendoButton>Добавить вкладку</button>
        <button *ngIf="formGroup" (click)="deleteTab(tabDropdown)" class="deny" kendoButton>
          Удалить вкладку
        </button>
      </ng-container>
    </span>
  </span>

  <span [formGroup]="formGroup" *ngIf="formGroup" class="tab-content form">
    <span class="field">
      <h3>Наименование вкладки</h3>
      <span class="input">
        <kendo-textbox formControlName="name"></kendo-textbox>
        <h6 *ngIf="editable" class="required">*</h6>
      </span>
    </span>
    <span class="field">
      <h3>Порядковый номер для сортировки при отображении</h3>
      <span class="input">
         <kendo-numerictextbox [decimals]="0"
                               [changeValueOnScroll]="false"
                               [min]="0"
                               (afterValueChanged)="fieldChange()"
                               format="false"
                               formControlName="sortNumber">
        </kendo-numerictextbox>
        <h6 *ngIf="editable" class="required">*</h6>
      </span>
    </span>

    <span class="field-tab">
      <h3>Группа полей</h3>
      <span class="input">
        <kendo-dropdownlist
                #groupFieldsDrodpdown
                [data]="groupProperties"
                (valueChange)="onGroupPropertiesChange($event)"
                textField="name"
                valueField="name">
        </kendo-dropdownlist>
        <ng-container *ngIf="editable">
          <button (click)="openDialog()" [primary]="true" icon="plus" kendoButton>Добавить группу полей</button>
          <button (click)="deleteGroupProperty(groupFieldsDrodpdown)" *ngIf="groupPropertiesFormGroup" class="deny" kendoButton>
            Удалить группу полей
          </button>
        </ng-container>
      </span>
    </span>

    <span [formGroup]="groupPropertiesFormGroup" *ngIf="groupPropertiesFormGroup && groupFieldsDrodpdown.value" class="group-fields form">
      <span class="field">
        <h3>Наименование группы полей</h3>
        <span class="input">
          <kendo-textbox formControlName="name" (afterValueChanged)="fieldChange()"></kendo-textbox>
          <h6 *ngIf="editable" class="required">*</h6>
        </span>
      </span>
      <span class="field">
        <h3>Порядковый номер для сортировки при отображении</h3>
        <span class="input">
          <kendo-numerictextbox [decimals]="0"
                                [changeValueOnScroll]="false"
                                [min]="0"
                                (afterValueChanged)="fieldChange()"
                                format="false"
                                formControlName="sortNumber">
          </kendo-numerictextbox>
          <h6 *ngIf="editable" class="required">*</h6>
        </span>
      </span>

      <h3>Поля с информацией по обучающемуся</h3>
      <span class="field-codes">
        <mfc-constructor-listbox [hiddenData]="fieldCodesHiddenData"
                                 [visibleData]="fieldCodesVisibleData"
                                 [info]="fieldCodesInfo"
                                 (value)="getFieldCodesData($event)"
                                 [hiddenToVisible]="fieldCodesToVisible"
                                 [visibleToHidden]="fieldCodesToHidden"
                                 [editable]="editable"
                                 [gridMaxWidth]="510">
        </mfc-constructor-listbox>
      </span>


      <h3>Доступные поля заявки</h3>
      <span class="available-fields">
        <mfc-constructor-listbox [hiddenData]="propertiesHiddenData"
                                 [visibleData]="propertiesVisibleData"
                                 [info]="propertiesInfo"
                                 (value)="getPropertiesData($event)"
                                 [hiddenToVisible]="propertiesToVisible"
                                 [visibleToHidden]="propertiesToHidden"
                                 [editable]="editable">
        </mfc-constructor-listbox>
      </span>

      <h3>Дополнительные поля заявки</h3>
      <span [ngClass]="{view: !editable}" class="additional-properties">
        <span class="headers">
          <h3>Наименование поля</h3>
          <h3>Тип данных</h3>
          <h3 class="tooltip-header">
            Название метки для печатной формы
            <kendo-icon kendoTooltip
                        [tooltipWidth]="450"
                        [title]="additionalPropertyTooltip"
                        name="info"
                        tooltipClass="mfc-tooltip"
                        position="top"
                        size="medium">
            </kendo-icon>
          </h3>

          <h3>Обязательность заполнения</h3>
          <h3>Справочник</h3>
          <h3>Доступ к заполнению</h3>
          <h3>Подсказка к полю</h3>
        </span>
        <span class="field" *ngFor="let additionalProperty of additionalProperties; let i = index">
          <span class="arrows" *ngIf="editable">
            <kendo-icon (click)="additionalPropertyMove(i, true)" name="arrow-60-up"></kendo-icon>
            <kendo-icon (click)="additionalPropertyMove(i)" name="arrow-60-down"></kendo-icon>
          </span>
          <span class="input">
            <kendo-textbox [ngClass]="{error: !additionalProperty.additionalDictProperty.name}"
                           [readonly]="!editable"
                           [(value)]="additionalProperty.additionalDictProperty.name"
                           (afterValueChanged)="fieldChange()">
            </kendo-textbox>
            <h6 *ngIf="editable" class="required">*</h6>
          </span>
          <span class="input">
            <kendo-dropdownlist [ngClass]="{error: !additionalProperty.additionalDictProperty.propertyTypeEnum}"
                                [data]="dictPropetyTypes"
                                [valuePrimitive]="true"
                                [(value)]="additionalProperty.additionalDictProperty.propertyTypeEnum"
                                [readonly]="!editable"
                                (valueChange)="fieldChange()"
                                textField="name"
                                valueField="enum">
            </kendo-dropdownlist>
            <h6 *ngIf="editable" class="required">*</h6>
          </span>
          <kendo-textbox [readonly]="!editable"
                         [(value)]="additionalProperty.additionalDictProperty.printKey"
                         (afterValueChanged)="fieldChange()">
          </kendo-textbox>
          <span class="input">
            <kendo-dropdownlist [ngClass]="{
                                  error: !additionalProperty.required
                                  && additionalProperty.required !== false
                                }"
                                [data]="boolOptions"
                                [valuePrimitive]="true"
                                [(value)]="additionalProperty.required"
                                [readonly]="!editable"
                                (valueChange)="fieldChange()"
                                textField="text"
                                valueField="id">
            </kendo-dropdownlist>
            <h6 *ngIf="editable" class="required">*</h6>
          </span>
          <kendo-dropdownlist [data]="dicts"
                              [valuePrimitive]="true"
                              [(value)]="additionalProperty.additionalDictProperty.dictId"
                              [readonly]="!editable"
                              (valueChange)="fieldChange()"
                              textField="name"
                              valueField="id">
          </kendo-dropdownlist>
          <span class="input">
            <kendo-multiselect [ngClass]="{
                                  error: !additionalProperty.roleIds.length
                                    && !additionalProperty.isEditableForAll
                                    && !additionalProperty.isEditableForInitiator
                                }"
                               [data]="dictRoles"
                               [valuePrimitive]="true"
                               [(value)]="additionalProperty.roleIds"
                               (valueChange)="onRoleChange(additionalProperty, $event)"
                               [autoClose]="false"
                               [readonly]="!editable"
                               valueField="id"
                               textField="name">
            </kendo-multiselect>
            <h6 *ngIf="editable" class="required">*</h6>
          </span>
          <kendo-textbox [readonly]="!editable"
                         [(value)]="additionalProperty.tooltip"
                         (afterValueChanged)="fieldChange()">
          </kendo-textbox>
          <kendo-icon *ngIf="editable" (click)="deleteAdditionalProperty(i)" name="close"></kendo-icon>
        </span>
        <button *ngIf="editable" (click)="addAdditionalProperty()" class="additional-btn" icon="plus" kendoButton>Добавить поле</button>
      </span>
    </span>
  </span>
</div>

<kendo-dialog class="dialog"
              title="Введите название новой {{isTabDialog ? 'вкладки' : 'группы полей'}}"
              *ngIf="showDialog"
              [minWidth]="250"
              [width]="630">
  <div class="field">
    <h3>Новая {{isTabDialog ? 'вкладка' : 'группа полей'}}</h3>
    <span class="input">
      <kendo-textbox #textbox></kendo-textbox>
    </span>
  </div>
  <kendo-dialog-actions>
    <button (click)="cancelDialog()" kendoButton>Отмена</button>
    <button (click)="confirmDialog(textbox)" kendoButton themeColor="primary">
      Добавить {{isTabDialog ? 'вкладку' : 'группу полей'}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<div kendoDialogContainer></div>

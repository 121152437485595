import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EducationDocs } from '../../models/contingent/educationdocs.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class EducationdocsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.educationdocs}`;

  constructor(private http: HttpClient) { }

  //Get all educationdocs
  public getAlleducationdocs(): Observable<EducationDocs[]> {
    return this.http.get<EducationDocs[]>(this.baseUrl);
  }

  //Get By Id educationdocs
  getByIdeducationdocs(id: string): Observable<EducationDocs> {
    return this.http.get<EducationDocs>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId
  getStudentEducationDocs(id: string | undefined): Observable<EducationDocs[]> {
    return this.http.get<EducationDocs[]>(this.baseUrl + '/geteducationdocsbystudpersonid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add educationdocs
  addeducationdocs(educationdocs: EducationDocs, studentId: string): Observable<EducationDocs> {
    return this.http.post<EducationDocs>(this.baseUrl + '/' + studentId, educationdocs)
      .pipe(catchError(this.handleError));
  }

  //Delete educationdocs
  deleteeducationdocs(id: string, studentId: string): Observable<EducationDocs> {
    return this.http.delete<EducationDocs>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit educationdocs
  updateeducationdocs(educationdocs: EducationDocs, studentId: string): Observable<EducationDocs> {
    return this.http.put<EducationDocs>(this.baseUrl + '/' + educationdocs.educationDocExternalId + '/' + studentId, educationdocs)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DictDisciplineType } from "src/app/models/education/dictdisciplinetype.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class DictDisciplineTypeService {
  
    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.disciplineTypes}`;
  
    constructor(private http: HttpClient) { }
  
    public getAllDisciplineTypes(): Observable<DictDisciplineType[]> {
      return this.http.get<DictDisciplineType[]>(this.baseUrl + '/GetAllDictDisciplineTypes');
    }

    public updateDisciplineType(dto: DictDisciplineType): Observable<DictDisciplineType> {
        return this.http.put<DictDisciplineType>(this.baseUrl + '/UpdateDictDisciplineType/' + dto.id, dto);
    }
}
import {Component, HostListener, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {CellClickEvent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {PublicationsAuthorsHomeMethods} from "./publications-authors-home-methods";
import {dictPagerSettings} from "../../../../../models/dicts/pagerSettings.model";
import {SelectEvent} from "@progress/kendo-angular-layout";
import {isChildOf} from "../../../../../helpers/elementRef-helper";
import {PublicationsPersonService} from "../../../../../services/science/publications/person.service";
import {lastValueFrom} from "rxjs";
import {AuthorsService} from "../../../../../services/science/publications/authors.service";
import {getData$} from "../../../../../../environments/environment";
import {DateFromUTCAsLocal} from "../../../../../helpers/date-helper";
import {StructureSubdivisionsService} from "../../../../../services/science/publications/structureSubdivisions.service";
import {DictStructureSubdivision} from "../../../../../models/publications/dict/structureSubdivision.model";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";

@Component({
  selector: 'publications-authors-home',
  templateUrl: './publications-authors-home.component.html',
  styleUrls: ['./publications-authors-home.component.scss']
})
export class PublicationsAuthorsHomeComponent implements OnInit {

  public pagerSettings: PagerSettings = dictPagerSettings;
  public searchInput: string = '';
  public isEmployee: boolean = false;
  public currentTab: string | null = 'Внешние';
  public loading: boolean = false;

  public externalAuthors: {id: string, fio: string, hasPublication: boolean}[] = [];
  public employeeAuthors: {id: string, fio: string, birthday?: Date, post: string, department: string}[] = [];
  public gridData: any[] = [];
  public posts: string[] = [];
  public subdivisions: DictStructureSubdivision[] = [];

  public editable: boolean = this.userAccessService.currentUserAccess$.value.author === AccessRights.Write;

  protected readonly DateFromUTCAsLocal = DateFromUTCAsLocal;

  constructor(private router: Router,
              private authorMethods: PublicationsAuthorsHomeMethods,
              private personService: PublicationsPersonService,
              private authorService: AuthorsService,
              private structureSubdivisionsService: StructureSubdivisionsService,
              private userAccessService: PublicationsUserAccessService,
              private dialogService: DialogService) {}

  public addAuthor() {
    this.router.navigate(['publications/authors/addAuthor']);
  }

  public onCellClick({dataItem}: CellClickEvent) {
    if (!this.editable) return;

    if (this.isEmployee) {
      localStorage.setItem('showAuthorInfo', 'true');
      localStorage.setItem('currentTab', 'Сотрудники');
      this.router.navigate(['publications/authors/summaryInfo', dataItem.id]);
    } else {
      this.router.navigate(['publications/authors/authorCard', dataItem.id]);
    }
  }

  public async searchFIO(fio: string) {
    await this.getExternalAuthors(fio);
    this.gridData = this.externalAuthors;
  }

  public async resetGrid(value: any) {
    if (!value) {
      await this.getExternalAuthors();
      this.gridData = this.externalAuthors;
    }
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const text = dataItem.hasPublication
      ? 'Автор указан в публикациях. Вы уверены, что хотите удалить автора'
      : 'Удалить';
    const dialog: DialogRef = openDialog(this.dialogService, `${text} "${dataItem.fio}?"`);
    this.authorMethods.removeExternalAuthor(dialog.result, dataItem.id);
  }

  public async replaceTableData(source: SelectEvent) {
    this.isEmployee = source.title === 'Сотрудники';
    await this.changeSource();
  }

  private async changeSource() {
    this.isEmployee
        ? this.gridData = this.employeeAuthors
        : this.gridData = this.externalAuthors;
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'searchByName')) {
      this.searchFIO(element.value);
    }
  }

  private async getExternalAuthors(fio?: string) {
    await lastValueFrom(this.authorService.getExternalAuthors(fio)).then((value) => {
      this.externalAuthors = this.authorMethods.mapExternalAuthors(value);
    });
  }

  private async getEmployeeAuthors(fio?: string) {
    await lastValueFrom(this.personService.getPersons(fio)).then((value) => {
      this.employeeAuthors = this.authorMethods.mapEmployeeAuthors(value);
      this.getPosts();
    });
  }

  private getPosts() {
    this.posts = [...new Set(
        this.employeeAuthors.map(item => item.post).filter((item) => item !== null)
    )];
  }

  private getStructureSubdivisions() {
    this.structureSubdivisionsService.getStructureSubdivisions()
        .subscribe(value => this.subdivisions = value);
  }

  private async getData() {
    this.loading = true;
    await this.getExternalAuthors();
    if (this.editable) await this.getEmployeeAuthors();

    if (this.isEmployee) {
      this.gridData = this.employeeAuthors;
    } else {
      this.gridData = this.externalAuthors;
    }
    this.loading = false;
  }

  async ngOnInit() {
    this.currentTab = localStorage.getItem('currentTab');
    if (this.currentTab) {
      this.isEmployee = true;
      localStorage.removeItem('currentTab');
    }
    this.getStructureSubdivisions();
    getData$.subscribe(async () => await this.getData());
  }
}

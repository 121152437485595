<kendo-stacklayout [gap]="30" orientation="vertical" class="wrapper">
  <app-currentcontrolhometree
    [checkable]="true"
    [checkGroup]="false"
    [treeType]="'discipline'"
    [checkedItems]="checkedStatements"
    (selectedTrainingLevelChange)="onChangeTrainingLevel($event)"
    (onEducationSlices)="onChangeSemester($event)"
    (onChangeChecked)="onChangeCheckedStatements($event)"
    (onChangeTreeData)="setGroupList($event)"
    (onChangeContingentDate)="onChangeDate($event)"
  ></app-currentcontrolhometree>
  <div class="signer">
    <app-currentcontrolsigneredit
      [(signer)]="signer"
      [trainingLevelId]="trainingLevelId"
      [enableSync]="false"
      [facultyId]="facultyId"
      (signerPostChange)="onChangeSignerPost($event)"
    ></app-currentcontrolsigneredit>
  </div>
  <kendo-stacklayout [gap]="11" orientation="vertical">
    <span class="print--label">Контрольная неделя</span>
    <kendo-dropdownlist
      class="print--list"
      [data]="dictAttestations"
      textField="name"
      valueField="id"
      (valueChange)="onChangeAttestation($event)"
      [(ngModel)]="attestation"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p class="print--list__template-not-found">Не найдено</p>
      </ng-template>
    </kendo-dropdownlist>
  </kendo-stacklayout>
  <kendo-stacklayout [gap]="11" orientation="vertical">
    <span class="print--label">Печать</span>
    <kendo-dropdownlist
      class="print--list"
      [data]="statementStrings"
      textField="text"
      valueField="value"
      (valueChange)="onChangeStatement($event)"
      [(ngModel)]="statement"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p class="print--list__template-not-found">Не найдено</p>
      </ng-template>
    </kendo-dropdownlist>
    <button
      kendoButton
      icon="print"
      (click)="onPrintStatement(statement['value'])"
      class="button button--action button--icon"
    >
      печать
      <kendo-loader *ngIf="loadingStatement" size="small"></kendo-loader>
    </button>
  </kendo-stacklayout>
  <button
    kendoButton
    (click)="onCancel()"
    class="button button--action"
    style="margin-top: 38px"
  >
    отмена
  </button>
</kendo-stacklayout>

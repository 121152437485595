import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {GlobalSignatoryRead} from "../../models/contingent/globalsignatory.model";

@Injectable({
  providedIn: "root"
})
export class GlobalSignatoryService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.contingentPath.globalSignatory}`;

  constructor(private http: HttpClient) {
  }

  public get(): Observable<GlobalSignatoryRead[]> {
    return this.http.get<GlobalSignatoryRead[]>(this.baseUrl);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

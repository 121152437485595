import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  GetMassPrintSheetData,
  GetSessionResultData,
  GetUniversityReportData,
} from '../../models/middlecontrol/massPrintSheet.model';
import { catchError, of } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'

@Injectable({
  providedIn: 'root',
})
export class MassPrintSheetService {
  baseUrl = `${environment.apiEndpoint}`;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) {}

  public getMassPrintSheet(param: number, data: GetMassPrintSheetData) {
    return this.http
      .post(
        this.baseUrl + environment.apiPaths.session.massprint + `/${param}`,
        data,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }

  public getMassPrintSheetList(
    semesterNum: number,
    semesterYear: number,
    data: GetMassPrintSheetData
  ) {
    return this.http
      .post(
        this.baseUrl +
          environment.apiPaths.session.massprintlist +
          `/${semesterNum}/${semesterYear}`,
        data,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }

  public getLastSessionResult(data: GetSessionResultData) {
    return this.http
      .post(this.baseUrl + environment.apiPaths.session.lastsession, data, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }

  public getAllSessionResult(data: GetSessionResultData) {
    return this.http
      .post(this.baseUrl + environment.apiPaths.session.allsession, data, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }

  public getUniversityReport(data: GetUniversityReportData) {
    return this.http
      .post(
        this.baseUrl + environment.apiPaths.session.universityreport,
        data,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }
}

export enum GiaTabsEnumList {
  нет = 0,
  состав_ГЭК = 1,
  приказы_о_допуске = 2,
  приказы_о_выпуске = 3,
  дипломы = 4,
  протокол_ГИА = 5
}

export const GiaTabsEnum = GiaTabsEnumList

<div
  [class]="{
    week: true,
    'week--bold': isSemesterBegin,
    'week--selected': isSelectedCell && isWholeWeek,
    'week--gray': isSelectedCell && !isWholeWeek
  }"
  (mouseenter)="selectCells()"
  (mousedown)="selectCells()"
>
  <div
    *ngIf="weekInfo.days.length === 1 && isWholeWeek"
    style="background-color:{{ weekInfo.days[0].backgroundColor }}"
    [class]="{
      week__day: true,
      'week--whole': true,
      'week--hatching': isInstallationSession
          && weekInfo.days[0].dictWorkScheduleStudyProcessType?.dictWorkCategoryScheduleId != holidaysEnum.holiday
          && weekInfo.days[0].dictWorkScheduleStudyProcessType?.dictWorkCategoryScheduleId != holidaysEnum.weekend
    }"
    (contextmenu)="openMenu($event, 0)"
    (click)="selectCell(0)"
  >
    {{ weekInfo.days[0].symbol }}
  </div>
  <ng-template [ngIf]="weekInfo.days.length !== 1 || !isWholeWeek">
    <ng-container *ngFor="let day of days; let idx = index">
      <div
        *ngIf="!isSemesterBegin || !weekInfo.semesterBeginDayNumber || idx < weekInfo.semesterBeginDayNumber"
        [class]="{
          week__day: true,
          'week--selected': isSelectedCell && idx === currentDay,
          'week__day--hatching': (isInstallationSessionBegin && idx >= weekInfo.installationSessionBeginDayNumber
            || isInstallationSessionEnd && idx <= weekInfo.installationSessionEndDayNumber
            || isInstallationSession && !isInstallationSessionBegin && !isInstallationSessionEnd)
            && day.dictWorkScheduleStudyProcessType?.dictWorkCategoryScheduleId != holidaysEnum.holiday
            && day.dictWorkScheduleStudyProcessType?.dictWorkCategoryScheduleId != holidaysEnum.weekend
        }"
        style="background-color:{{ day.backgroundColor }}"
        (contextmenu)="openMenu($event, idx)"
        (click)="selectCell(idx)"
      >
        {{ day.symbol }}
      </div>
    </ng-container>
    <div
      *ngIf="isSemesterBegin && weekInfo.semesterBeginDayNumber"
      class="week__part"
    >
      <ng-container *ngFor="let day of days; let idx = index">
        <div
          *ngIf="idx >= weekInfo.semesterBeginDayNumber"
          [class]="{
            week__day: true,
            'week--selected': isSelectedCell && idx === currentDay,
            'week__day--hatching': (isInstallationSessionBegin && idx >= weekInfo.installationSessionBeginDayNumber
            || isInstallationSessionEnd && idx <= weekInfo.installationSessionEndDayNumber
            || isInstallationSession && !isInstallationSessionBegin && !isInstallationSessionEnd)
            && day.dictWorkScheduleStudyProcessType?.dictWorkCategoryScheduleId != holidaysEnum.holiday
            && day.dictWorkScheduleStudyProcessType?.dictWorkCategoryScheduleId != holidaysEnum.weekend
          }"
          style="background-color:{{ day.backgroundColor }}"
          (contextmenu)="openMenu($event, idx)"
          (click)="selectCell(idx)"
        >
          {{ day.symbol }}
        </div>
      </ng-container>
    </div>
  </ng-template>
  <div
    class="dot"
    *ngIf="isSelectedCell && !isChoosedCells && isDisplayDot"
    (mousedown)="startDragFunction($event)"
  ></div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StudyFormType } from '../../models/dicts/studyformtype.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class StudyformtypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studyformtype}`;

  constructor(private http: HttpClient) { }

  //Get all StudyFormType
  public getAllStudyFormType(): Observable<StudyFormType[]> {
    return this.http.get<StudyFormType[]>(this.baseUrl);
  }

  //Add StudyFormType
  addStudyFormType(studyFormType: StudyFormType): Observable<StudyFormType> {
    return this.http.post<StudyFormType>(this.baseUrl, studyFormType)
      .pipe(catchError(this.handleError));
  }

  //Delete StudyFormType
  deleteStudyFormType(id: string): Observable<StudyFormType> {
    return this.http.delete<StudyFormType>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit StudyFormType
  updateStudyFormType(studyFormType: StudyFormType): Observable<StudyFormType> {
    return this.http.put<StudyFormType>(this.baseUrl + '/' + studyFormType.dictStudyFormTypeExternalId, studyFormType)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

<div class="container">
  <kendo-loader *ngIf="loading" class="loader" type="converging-spinner" size="medium"></kendo-loader>
  <div class="new-comment" *ngIf="editable">
    <h5>Добавить комментарий</h5>
    <kendo-textarea [(ngModel)]="commentText">
    </kendo-textarea>
    <button kendoButton themeColor="primary" (click)="addComment()">Отправить</button>
  </div>
  <div class="comments" *ngIf="comments.length">
    <h5>Комментарии</h5>
    <div [class]="{
      comment: true,
      'align-right': isAuthor(comment.personId)
    }" *ngFor="let comment of comments">
      <div class="content">
        <p class="text">
          {{comment.text}}
        </p>
        <span role="button" class="k-clear-value"
              *ngIf="isAuthor(comment.personId)"
              (click)="removeComment(comment.commentId)">
          Удалить
          <span class="k-icon k-i-close"></span>
        </span>
      </div>
      <span class="info">{{comment.fio}}, {{formatDate(comment.createdAt)}}</span>
    </div>
  </div>
</div>

export enum Codes {
    MPK = 'МПК',
    DOI = 'DOI',
    ISBN = 'ISBN'
}

export enum Properties {
    // Коды
    CodesId = '00000012-999d-111a-1111-889d79b753f0',
    // Сборник трудов конференции
    ConferenceCollectionId = '00000302-999d-111a-1111-889d79b753f0',
    // Журнал
    JournalId = '00000303-999d-111a-1111-889d79b753f0'
}
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKPersonSocialNetworks } from 'src/app/models/person/lkPersonSocialNetworks.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKPersonSocialNetworksService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.lkSocialNetworks}`;

  constructor(private http: HttpClient) { }

  //Get social networks
  public getSocialNetworksByPersonId(personId: string): Observable<LKPersonSocialNetworks[]> {
    return this.http.get<LKPersonSocialNetworks[]>(this.baseUrl + '/GetSocialNetworksByPersonId/' + personId)
      .pipe(
        catchError(this.handleError));
  }

  //Add social network
  public addSocialNetwork(socialNetwork: LKPersonSocialNetworks): Observable<LKPersonSocialNetworks> {
    return this.http.post<LKPersonSocialNetworks>(this.baseUrl + '/CreateSocialNetwork/', socialNetwork)
      .pipe(catchError(this.handleError));
  }

  //Update social network
  public updateSocialNetwork(socialNetwork: LKPersonSocialNetworks): Observable<LKPersonSocialNetworks> {
    return this.http.put<LKPersonSocialNetworks>(this.baseUrl + '/UpdateSocialNetwork/' + socialNetwork.socialNetworkId, socialNetwork)
      .pipe(catchError(this.handleError));
  }

  //Delete social network
  public deleteSocialNetwork(id: number): Observable<LKPersonSocialNetworks> {
    return this.http.delete<LKPersonSocialNetworks>(this.baseUrl + '/DeleteSocialNetwork/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
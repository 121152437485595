import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictStructureSubdivision} from "../../../models/publications/dict/structureSubdivision.model";

@Injectable({
  providedIn: 'root'
})
export class StructureSubdivisionsService {
  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.publications.department}`;

  constructor(private http: HttpClient) {
  }

  public getStructureSubdivisions(): Observable<DictStructureSubdivision[]> {
    return this.http.get<DictStructureSubdivision[]>(this.baseUrl);
  }
}

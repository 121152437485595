import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { DictAcceleratedStudyBase } from 'src/app/models/education/dictAcceleratedStudyBase.model';
import { DictAcceleratedStudyBaseService } from 'src/app/services/education/DictAcceleratedStudyBase/dict-accelerated-study-base.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'app-dict-accelerated-study-base',
  templateUrl: './dict-accelerated-study-base.component.html',
  styleUrls: ['./dict-accelerated-study-base.component.scss']
})
export class DictAcceleratedStudyBaseComponent implements OnInit {

  public gridStyle = 'grid-default';
  public isOpened: boolean = false;
  public loading: boolean = false;
  public acceleratedStudyBases!: DictAcceleratedStudyBase[];
  public isNew: boolean = false;
  public editable?: boolean;

  constructor(private dictAcceleratedStudyBaseService: DictAcceleratedStudyBaseService,
    private notificationService: NotificationsService,
    private userAccessService:EducationUserAccessService) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getAllAcceleratedStudyBases();
  }

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictAcceleratedStudyBaseId: new FormControl(0),
    dictAcceleratedStudyBaseExternalId: new FormControl(""),
    dictAcceleratedStudyBaseName: new FormControl("")
  });

  //Getting all entries from LessonForm
  public getAllAcceleratedStudyBases() {
    this.loading = true;
    this.dictAcceleratedStudyBaseService.getAllAcceleratedStudyBases()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.acceleratedStudyBases = response;
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить базу ускоренного обучения");
        }
      });
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public onSave() {
    // Add new AcceleratedStudyBase
    if (this.isNew) {
      this.dictAcceleratedStudyBaseService.addAcceleratedStudyBase(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("База ускоренного обучения была успешно добавлена!");
              this.getAllAcceleratedStudyBases();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить базу ускоренного обучения");
              this.onCancel();
            }
          });
    }
    // Edit AcceleratedStudyBase
    else {
      this.dictAcceleratedStudyBaseService.updateAcceleratedStudyBase(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("База ускоренного обучения была успешно сохранена!")
              this.getAllAcceleratedStudyBases();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось изменить базу ускоренного обучения');
              this.onCancel();
            }
          })
    }
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }
}

const formGroup = (dataItem: {
  dictAcceleratedStudyBaseId?: any;
  dictAcceleratedStudyBaseExternalId?: any;
  dictAcceleratedStudyBaseName?: any; }) =>
    new FormGroup({
      dictAcceleratedStudyBaseId: new FormControl(dataItem.dictAcceleratedStudyBaseId),
      dictAcceleratedStudyBaseExternalId: new FormControl(dataItem.dictAcceleratedStudyBaseExternalId),
      dictAcceleratedStudyBaseName: new FormControl(dataItem.dictAcceleratedStudyBaseName, Validators.required),
    });


export const breadcrumbItemsMap = new Map<string, string>([
  ["contingent", "Контингент"],
  ["", "Главная"],
  ["addstudent", "Добавление студента"],
  ["contingentselection", "Массовые операции"],
  ["contingentsettings", "Настройки отображения"],
  ["studentcardsettings", "Настройки карточки студента"],
  ["contingentjournal",""],
  ["academicstate","Академические статусы"],
  ["militarydoctypes","Документы воинского учета"],
  ["militaryranks","Воинские звания"],
  ["militarystatuses", "Категории воинского учёта"],
  ["militarystockcategories", "Категории запаса"],
  ["militarycommissariat", "Военные комиссариаты"],
  ["militarycertificates", "Справки по воинскому учету"],
  ["budget","Источники финансирования"],
  ["budgetcategory", "Категории финансирования"],
  ["budgetsubcategory","Подкатегория финансирования"],
  ["identificationtype","Документы удостоверяющие личность"],
  ["languages","Изучаемые иностранные языки"],
  ["phonetypes", "Типы телефонов"],
  ["educationdoctypes","Типы документов об образовании"],
  ["citizenship","Гражданство"],
  ["citizenshiptype","Типы гражданства"],
  ["familymemberstatuses","Статусы членов семьи"],
  ["dictmaritalstatus","Семейное положение"],
  ["emailtypes","Типы email"],
  ["streettypes","Типы улиц"],
  ["documentnames","Наименования документов"],
  ["institutiontype","Типы учебных заведений"],
  ["health","Группы здоровья"],
  ["sportcategory","Спортивные разряды"],
  ["sporttype","Виды спорта"],
  ["spravkatype","Тип справки"],
  ["benefits","Льготы"],
  ["citytypes","Виды населенных пунктов"],
  ["ordertypes","Тип категории приказов"],
  ["additionalfields","Дополнительные поля"],
  ["languagelevels","Уровни владения языком"],
  ["filial","Филиалы"],
  ["organization","Сторонние организации"],
  ["orderscategory","Категории приказов"],
  ["progress","Успеваемость"],
  ["achievements","Достижения"],
  ["orders","Приказы"],
  ["personaldata","Персональные данные"],
  ["certificate","Справки"],
  ["military","Воинский учет"],
  ["academicgroupshome","Академические группы"],
  ["addacademicgroups","Создание академической группы"],
  ["editacademicgroups","Редактирование академической группы"],
  ["middlecontrol","Промежуточный контроль"],
  ["currentcontrol","Текущий контроль"],
  ["contingentjournal","Журнал"],
  ["studplan","Смена учебного плана"],
  ["documents","Документы"],
  ["history","История изменений"],
  ['dictachievement', 'Категории достижений'],
  ['dictmarkcriterialevel', 'Справочник уровня критериев оценок'],
  ['dictcontrolaction', 'Контрольные акции'],
  ['codearticle', 'Статьи ТК РФ для справки-вызов'],
  ['dictmarkchangereason', 'Причина смены оценки'],
  ['militarycategories', 'Категории годности к военной службе'],
  ['achievementsstatuses', 'Статусы достижений'],
  ['militaryformtypes', 'Типы справок по воинскому учёту'],
  ['militaryprofiles', 'Составы (профили)'],
  ['militarystates', 'Состояния воинского учета'],
  ['nonemploymentreasons', 'Причины нетрудоустройства'],
  ['dictgeneralsheet', 'Общая ведомость по дисциплине'],
  ['worktypes', 'Типы работ'],
  ['localsignatoryrole', 'Роли локальных подписантов'],
  ['gia', 'ГИА'],
  ['dictdesigner', 'Создание справочников'],

  ['dicts',  'Справочники'],
  ['traininglevel',  'Уровень подготовки'],
  ['studylevel',  'Уровень образования'],
  ['studyformtype',  'Тип формы обучения'],
  ['studyform',  'Форма обучения'],
  ['qualificationdegree',  'Квалификации'],
  ['studytechnology', 'Технология обучения'],
  ['controlaction', 'Контрольные акции'],
  ['mark', 'Оценки'],
  ['discipline', 'Дисциплина'],
  ['disciplinetype', 'Тип дисциплины'],
  ['dictcertificatepurpose', 'Места для предоставления справки'],

  ['education', 'Образование'],
  ['plans', 'Учебные планы'],
  ['add-plan', 'Добавление плана'],
  ['copy-plan', 'Копирование учебного плана'],
  ['add-discipline', 'Добавление дисциплины'],
  ['edit-discipline', 'Редактирование дисциплины'],
  ['reattach-plan', 'Перезакрепление за другой ОП'],
  ['global-schedule-settings', 'Настройка графика учебного процесса'],
  ['export-shedule', 'Экспорт графиков учебного процесса'],
  ['dictdisciplinetype', 'Тип дисциплины'],
  ['department', 'Кафедры'],

  ['profile', 'Личный кабинет сотрудника'],
  ['useraccess', 'Настройки доступа'],

  ['standard', 'Образовательные стандарты'],
  ['oop', 'Образовательные программы'],
  ['matrixcompetence', 'Матрица компетенций'],
  ['competence', 'Компетенции'],
  ['dictcomponent', 'Компонент'],
  ['dictcomponenttype', 'Тип компонента'],
  ['standard', 'Образовательные стандарты'],
  ['dictotherdocumenttypes', 'Типы прочих документов'],
  ['dictfaculty', 'Факультеты'],
  ['complaintmanagementstatus', 'Статусы управления жалобами'],
  ['paymentstate', 'Статусы состояния платежа'],
  ['employmentstatus', 'Статусы занятости работников'],
  ['dictcycle', 'Циклы'],
  ['dictcycletype', 'Тип цикла'],
  ['dicttraininglevels', 'Уровни подготовки'],
  ['sciencebranch', 'Отрасль науки'],
  ['dictTypeWorkDisciplineAdditional', 'Дополнительные работы по дисциплине'],
  ['standardtype', 'Тип стандарта'],
  ['dictWorkCategorySchedule', 'Категории работ графика'],
  ['dictWorkScheduleStudyProcessType', 'Виды работ графика учебного процесса'],
  ['dictIndustrialPartner', 'Индустриальный партнер'],
  ['dictgroupstandardlimitation', 'Группы ограничений стандарта'],
  ['dictstandardlimitationsetting', 'Ограничения стандарта'],
  ['dictWorkStudyPlanType', 'Виды работ учебного плана'],
  ['dictLessonType', 'Тип проведения занятий'],
  ['dictLessonForm', 'Форма проведения занятий'],
  ['dictAcceleratedStudyBase', 'База ускоренного обучения'],
  ['dictstandardlimitation', 'Ограничения стандарта'],
  ['dictEnlargedGroupSpecialization', 'Укрупненные группы специальностей'],

  ['switchuser', 'Просмотр от другого лица'],

  //#region Signatory
  ['signatory', 'Настройки списка подписантов'],
  ['signatoryrole', 'Категория подписантов'],
  //#endregion

  ['alert', "Объявления и новости"],
  ['author', "Объявления и новости"],
  ['modify', "Создать новое оповещение"],
  ['show', "Объявления и новости"],
  ['announcement', "Создать новое оповещение"],

  ['contingentworkload', "Контингент для расчета нагрузки"],
  ['assignments', "Поручения"],
  ['departmentworkload', "Кафедральная нагрузка"],
  ['teacherworkload', "Моя нагрузка"],
  ['reportsWorkload', "Отчеты по нагрузке"],
  ['dictTeachers', "Преподаватели"],
  ['assignmentsMonitoring', "Мониторинг поручений"],

  ['classroom', "Аудиторный фонд"],
  ['building', "Корпуса"],
  ['auditorium', "Типы аудиторий "],
  ['assign', "Назначения аудиторий"],
  ['access', "Настройки доступа"],
  ['parameters', "Аудитория"],

  ['export-schedule', "Экспорт графиков учебного процесса"],

  ['technicaladministators', 'Технические администраторы'],
  ['cadrebase', "Кадровая база"],
  ['paramperson', 'Сведения о сотруднике'],
  ['personcard', 'Карточка сотрудника'],
  ['posts', 'Должности'],
  ['sciencedegree', 'Ученые степени'],
  ['departments', 'Подразделения'],
  ['departmentsname', 'Названия подразделений'],
  ['departmentstype', 'Типы подразделений'],

  ['publications', "Публикации"],
  ['authors', "Авторы"],
  ['authorCard', "Автор"],
  ['addAuthor', "Добавление автора"],
  ['addAuthorForPublication', "Добавление автора"],
  ['summaryInfo', "Сводная информация"],
  ['reports', "Отчеты"],
  ['newPublication', "Новая публикация"],
  ['dictPosts', "Должности"],
  ['dictQuartiles', "Квартили"],
  ['dictScienceDegrees', "Ученые степени"],
  ['dictPublicationTypes', "Типы публикаций"],
  ['dictPublicationSubTypes', "Виды публикаций"],
  ['dictScientometricBases', "Индексация"],
  ['dictCodes', "Коды"],
  ['dictAuthorRoles', "Роли автора"],
  ['dictOrganizations', "Организации"],
  ['educationalorganizationname', "Наименования образовательной организации"],
  ['userAccess', "Настройки доступа"],

  ['payslip', "Расчетные листы"],

  ['cards', 'Карты'],
  ['reestr', 'Реестр'],
  ['addtolistforming', 'Добавление студента'],
  ['salaryproject', 'Зарплатные проекты'],

  ['stateExamCommission', 'Состав ГЭК'],
  ['printtemplate', 'Шаблоны печатных форм'],
  ['dictprinttemplatecategory', 'Категории шаблонов печатных форм'],
  ['dictstateexamcommissiontype', 'Типы приказов ГЭК'],
  ['dictstateexamcommissionmemberrole', 'Роли членов ГЭК'],
  ['dict', 'Справочник'],
  ['dictdiplomatype', 'Тип диплома'],
  ['diploma', 'Дипломы'],
  ['formatdiploma', 'Формирование диплома'],
  ['adddiscipline', 'Добавление дисциплины'],
  ['constructorgia', 'Конструктор протокола ГИА'],
  ['giaprotocol', 'Протокол ГИА'],
  ['admissionorder', 'Приказы о допуске'],
  ['admissionorderform', 'Приказы о допуске'],
  ['releaseorder', 'Приказы о выпуске'],
  ['releaseorderform', 'Приказы о выпуске'],
  ['studentdebt', 'Задолженности обучающегося'],
  ['settings', 'Настройки ГИА'],

  ['oferta', 'Присоединение к положениям'],
  ['usersignature', 'Присоединившиеся к Положениям пользователи'],
  ['dictregulationtype', 'Типы положений'],
  ['regulationsettings', 'Настройки присоединения к положениям'],
  ['ofertaUserAccess', 'Настройки доступа'],

  ['mfc', 'Мой деканат'],
  ['applicationConstructor', 'Конструктор заявок'],
  ['constructorForm', 'Форма заявки'],
  ['applicationTypeSelect', 'Заявка'],
  ['applicationForm', 'Заявка'],
  ['stamp', 'Настройки штампов ЭП'],
  ['stampForm', 'Штамп ЭП'],
  ['templates', 'Шаблоны справок'],
  ['templateForm', 'Шаблон'],
  ['additionalFilters', 'Дополнительные фильтры'],
  ['dictApplicationCategory', 'Категории заявок'],
  ['dictApplicationType', 'Типы заявок'],
  ['dictTypeReceipt', 'Варианты получения справок'],
  ['dictApprovalType', 'Типы согласования заявок'],
  ['dictOperatorRole', 'Роли операторов'],
  ['dictApprovalStatus', 'Статусы согласования'],
  ['dictDepartmentContactDetails', 'Контактные данные подразделений'],
  ['dictDepartmentLitera', 'Литеры подразделений'],
  ['dictApplicationStatus', 'Статусы заявок'],
  ['dictCompensationGuarantee', 'Гарантии компенсации для справки-вызов'],
  ['dictCreator', 'Создание справочников'],
  ['dictWorkProfile', 'Предполагаемые должности выпускников'],

  ['report-list', 'Отчеты'],

  ['diplomablanks', 'Учет бланков'],
  ['dictblanktypes', 'Типы бланков'],
  ['dictblankstatuses', 'Статусы'],
  ['dictblanktemplate', 'Шаблоны'],
  ['addblanks', 'Добавление бланков'],
  ['issueblanks', 'Выдача бланков'],
  ['diplomablanksaccess', 'Настройки доступа'],
])

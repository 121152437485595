<h1>{{ standardName }}</h1>
<button kendoButton class="m-r15" *ngIf="editable" (click)="openForm()">Создать новый приказ</button>
<button kendoButton *ngIf="editable" [disabled]="arrayStudents.length === 0 || admissionOrders?.length === 0" (click)="openDialog()">Добавить в приказ</button>

<h6 class="info-label">Для создания приказа выберите обучающихся и нажмите “Создать новый приказ”. Для добавления обучающихся к уже существующему приказу выберите обучающихся и нажмите “Добавить в приказ”</h6>

<kendo-grid #grid
            class="m-t10"
            [data]="gridView"
            [selectable]="{checkboxOnly: true}"
            kendoGridSelectBy="studentId"
            [loading]="loading"
            [filterable]="true"
            [filter]="filter"
            (filterChange)="filterChange($event)"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [pageSize]="pageSize"
            (pageChange)="pageChange($event)"
            (cellClick)="cellClickHandler($event)"
            [pageable]="pagerSettings"
            [skip]="skip"
            [rowClass]="rowCallback"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="studentId"
                     [width]="60"
                     [sortable]="false"
                     [resizable]="false"
                     [filterable]="false"
                     *ngIf="editable"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridHeaderTemplate>
      <div headerClass="gridHeader">
        <div class="m-t10">
          <input
            type="checkbox"
            kendoCheckBox
            [checked]="allItemsChecked"
            (click)="selectAll()"
          />
        </div>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox *ngIf="!dataItem.admissionOrderId && dataItem.notEnteredGradeCount === 0 && dataItem.unsatisfactoryGrades === 0"
                       [checked]="checked(dataItem.studentId)"
                       (change)="checkedChange(dataItem.studentId)"
                />
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="studentFio"
                     title="ФИО обучающегося"
                     [width]="200"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells leftAlignment">
                {{ dataItem.studentFio }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="groupName"
                     title="Академическая группа"
                     [width]="180"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.groupName }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="admissionYear"
                     title="Год поступления"
                     [width]="140"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.admissionYear }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column-group headerClass="gridHeader" title="Количество оценок">
    <kendo-grid-column field="planGradeCount"
                       title="по плану"
                       [width]="100"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.planGradeCount}}
                </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="receivedGradeCount"
                       title="получено"
                       [width]="110"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.receivedGradeCount }}
                </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="unsatisfactoryGrades"
                       title="неуд."
                       [width]="90"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.unsatisfactoryGrades }}
                </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="notEnteredGradeCount"
                       title="не проставленно"
                       [width]="140"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.notEnteredGradeCount }}
                </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column field="studentId"
                     title="Список задолженностей"
                     [filterable]="false"
                     [width]="180"
                     headerClass="gridHeader dictionaries"
                     filter="numeric">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells details-painter" (click)="studentDebt(dataItem.studentId)" *ngIf="dataItem.notEnteredGradeCount !== 0 || dataItem.unsatisfactoryGrades !== 0">
          <h2>Посмотреть детали</h2>
        </span>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="admissionOrder"
                     title="Приказ о допуске"
                     [width]="150"
                     headerClass="gridHeader dictionaries"
                     filter="numeric">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.admissionOrder }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column headerClass="gridHeader dictionaries" [width]="50">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" *ngIf="dataItem.admissionOrderId">
          <span class="k-icon k-i-download pointer" (click)="printOrder(dataItem.admissionOrderId)"></span>
        </span>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<kendo-dialog title="Пожалуйста, выберите приказ" *ngIf="opened" (close)="closeDialog()" [minWidth]="200" [width]="450">
  <div class="filter">
    <span class="selectText k-mr-3">Приказ</span>
    <kendo-dropdownlist
      [data]="admissionOrders"
      textField="name"
      valueField="id"
      [valuePrimitive]="true"
      [(ngModel)]="orderId"
    >
    </kendo-dropdownlist>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Отмена</button>
    <button kendoButton themeColor="primary" (click)="addToOrder()">Добавить в приказ</button>
  </kendo-dialog-actions>
</kendo-dialog>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { DisciplineStatement } from 'src/app/models/middlecontrol/groupInSession.model';
import { PersonsModel } from 'src/app/models/middlecontrol/persons.model';
import {SignatoriesModel, Signatory} from 'src/app/models/middlecontrol/sheets.model';
import { DictsService } from 'src/app/services/middlecontrol/dicts.service';
import { SessionService } from 'src/app/services/middlecontrol/session.service';
import { SheetService } from '../../../services/middlecontrol/sheet.service';
import { MiddleControlUserAccessService } from '../../../services/useraccess/middle-control-user-access.service';
import {CurrentDayEnd, DateFromUTCAsLocal} from "../../../helpers/date-helper";
import {DownloadFile} from "../../../helpers/downloadFile-helper";

@Component({
  selector: 'app-statementsettings',
  templateUrl: './statementsettings.component.html',
  styleUrls: ['./statementsettings.component.scss'],
})
export class StatementsettingsComponent implements OnInit {
  @Input() groupName: string = '';
  @Input() eduGroupId = '';
  @Input() planId: string | undefined = undefined;
  @Input() facultyId = '';
  @Input() semesterNumber = 0;
  @Input() contingentDate: Date = CurrentDayEnd();
  @Input() selectedMarkDate: Date = CurrentDayEnd();
  @Input() disciplineId = '';
  @Input() choiceOfTeacher: boolean = true;
  @Input() choiceOfSigner: boolean = true;
  @Output() onComeback: EventEmitter<void> = new EventEmitter();
  @Output() onChangeEditableDiscipline: EventEmitter<boolean> = new EventEmitter<boolean>();

  public personsToFilter: PersonsModel[] = [];
  public examiner: PersonsModel | null = null;
  public signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'должность',
    personId: Guid.create(),
  };
  public sheetSigner: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'Должность',
    personId: Guid.create(),
  };

  public signerRequest: Signatory = {};
  public markDate: Date | null = null;
  public examDate: Date | null = null;
  public trainingLevelId?: string;
  public disciplineInfo: Partial<DisciplineStatement> = {};
  public loadingEmptyList = false;
  public loadingFilledList = false;
  public loadingAdditionalSession = false;
  private disciplineInfoSubscription$!: Subscription;
  private personsSubscription$!: Subscription;
  private groupInfoSubscription$!: Subscription;

  constructor(
    private sessionService: SessionService,
    private dictsService: DictsService,
    private sheetService: SheetService,
    private userAccessService: MiddleControlUserAccessService
  ) {}

  public editableSheetApprove?: boolean;
  public editable?: boolean;

  ngOnInit(): void {
    this.disciplineInfoSubscription$ =
      this.sessionService.disciplineInfo$.subscribe((info) => {
        this.disciplineInfo = info;
        this.trainingLevelId = this.disciplineInfo.trainingLevelId
        this.editableSheetApprove=this.disciplineInfo.editableSheetApprove && this.disciplineInfo.sheetStatus != "проверена";
        this.editable = this.disciplineInfo.editable
        this.onChangeEditableDiscipline.emit(this.editable)
        this.examiner = info.teacher || null;
        if(info.examDate || this.examDate) this.examDate = DateFromUTCAsLocal(info.examDate || this.examDate);
        if(info.estimationDate!=undefined)this.changeMarkDate(DateFromUTCAsLocal(info.estimationDate));
        else if(this.markDate) this.markDate = DateFromUTCAsLocal(this.markDate);
        if (info.signatory)
          this.signer = {
            fio: info.signatory?.fio,
            postName: info.signatory?.postName,
            personId: info.signatory?.personId,
          };
      });
    this.personsSubscription$ = this.dictsService.persons$.subscribe(
      (response) => {
        this.personsToFilter = response;
      }
    );
    if (this.markDate) {
      this.sessionService.markDate$.next(this.markDate);
    }
    this.groupInfoSubscription$ = this.sheetService.groupInfo$.subscribe(
      (info) => {
        if (this.signer.fio === 'Фамилия Имя Отчество' && info.signatory) {
          this.signer = {
            fio: info.signatory.fio,
            postName: info.signatory.postName,
            personId: Guid.parse(info.signatory.personId),
          };
        }
      }
    );
    if(this.signer.fio === 'Фамилия Имя Отчество'){
      this.sheetService.groupInfo$.subscribe((response) => {
        if (response.signatory) {
            this.signer = {
              fio: response.signatory.fio,
              postName: response.signatory.postName,
              personId: Guid.parse(response.signatory.personId),
            };}
      })
    }
  }

  ngOnDestroy() {
    this.disciplineInfoSubscription$?.unsubscribe();
    this.personsSubscription$?.unsubscribe();
    this.groupInfoSubscription$?.unsubscribe();
  }

  public getControlAction(type: string) {
    switch (type) {
      case 'Экзамен':
        return 'экзамена по расписанию';
      case 'Курсовой проект':
      case 'Курсовая работа':
        return 'сдачи по расписанию';
      case 'Зачет':
      case 'Зачет с оценкой':
      default:
        return 'зачета по расписанию';
    }
  }

  private setLoading(param: number, value: boolean) {
    switch (param) {
      case 0: {
        this.loadingEmptyList = value;
        break;
      }
      case 1: {
        this.loadingFilledList = value;
        break;
      }
      case 2: {
        this.loadingAdditionalSession = value;
        break;
      }
      default:
        break;
    }
  }

  public onPrintForm(param: number) {
    this.setLoading(param, true);
    this.onPrintPersonPost()
    this.sheetService
      .printDisciplineForm({
        eduGroupId: this.eduGroupId,
        semester: this.semesterNumber,
        disciplineId: this.disciplineId,
        electiveDisciplineId: this.disciplineInfo.electiveDisciplineId || null,
        contingentDate: this.contingentDate,
        markDate: this.selectedMarkDate,
        planId: this.planId,
        parameter: param,
        signer: this.signerRequest
      }
      )
      .subscribe(
        (response) => {
          DownloadFile(response);
          this.setLoading(param, false);
        },
        () => this.setLoading(param, false)
      );
  }

  onPrintPersonPost(): void{
    if(this.signer.postName === "должность")
    {
      this.signerRequest.postName = ""
      this.signerRequest.personId = null
    }
    else this.signerRequest = this.signer
    this.signerRequest.personId = this.signerRequest.personId?.toString()
  }

  public changeMarkDate(date: Date) {
    this.sessionService.markDate$.next(date ? date: new Date());
    this.markDate = date;
    this.saveSheet();
  }

  public changeExamDate(date: Date) {
    this.examDate = date;
    this.saveSheet();
  }

  public saveSheet() {
    const saveData = {
      disciplineId: this.disciplineId,
      controllingActionDate: this.examDate !== null ? this.sessionService.convertDateToUTCDateOnly(this.examDate) : null,
      sheetDate: this.markDate !== null ? this.sessionService.convertDateToUTCDateOnly(this.markDate) : null,
      semesterNum: this.semesterNumber,
      eduGroupId: this.eduGroupId,
      electiveDisciplineId: this.disciplineInfo.electiveDisciplineId || null,
    };

    this.sessionService.saveDisciplineSheet(saveData).subscribe((response) => {
      //this.sessionService.disciplineInfo$.next({...this.sessionService.disciplineInfo$.value, estimationDate: response.sheetDate})
    });
  }

  onChangeSignerPost(post: string) {
    this.signer.postName = post;
  }

  onChangeSigner(signer: SignatoriesModel) {
    this.signer = signer;
  }


  public handleFilter(value: string) {
    if (value.length > 1) this.dictsService.updatePersons(value);
  }

  public onChangeFindSigner(name: string): void {
    if (name.length) {
      const examiner =
        this.personsToFilter?.find((person) => person.fio === name) || null;

      if (this.examiner === null) {
        this.sessionService
          .addExaminer({
            fio: examiner?.fio,
            postname: examiner?.postName,
            personId: examiner?.id,
            eduGroupId: this.eduGroupId,
            semesterNum: this.semesterNumber,
            electiveDisciplineId:
              this.disciplineInfo.electiveDisciplineId || null,
            disciplineId: this.disciplineId,
          })
          .subscribe((response) => {
            this.examiner = response;
            this.sessionService.disciplineInfo$.next({
              ...this.disciplineInfo,
              teacher: response,
            });
          });
      } else {
        this.sessionService
          .changeExaminer(this.examiner.id.toString(), {
            fio: examiner?.fio,
            postname: examiner?.postName,
            personId: examiner?.id,
            eduGroupId: this.eduGroupId,
            semesterNum: this.semesterNumber,
            electiveDisciplineId:
              this.disciplineInfo.electiveDisciplineId || null,
            disciplineId: this.disciplineId,
          })
          .subscribe((response) => {
            this.examiner = response;
            this.sessionService.disciplineInfo$.next({
              ...this.disciplineInfo,
              teacher: response,
            });
          });
      }
    } else {
      if (this.examiner)
        this.sessionService
          .deleteExaminer(this.examiner?.id.toString())
          .subscribe(() => {
            this.examiner = null;
            this.sessionService.disciplineInfo$.next({
              ...this.disciplineInfo,
              teacher: null,
            });
          });
    }
  }

  public handleClose() {
    this.onComeback.emit();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { DictHealth } from 'src/app/models/contingent/dicthealth.model';
import {DictHostel} from "../../models/contingent/dicthostel";

@Injectable({
  providedIn: 'root'
})
export class DicthostelService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dicthostel}`;

  constructor(private http: HttpClient) { }

  //Get all Health
  public getAllHostel(): Observable<DictHostel[]> {
    return this.http.get<DictHostel[]>(this.baseUrl + "/GetHostels");
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

<persondepartment-header></persondepartment-header>
<h1>Названия подразделений</h1>

<div class="m-b15 m-t18 align-r">
    <button kendoButton type="button" icon="download" size="small"  (click)="exportToExcel(grid)">
      <kendo-loader *ngIf="loaderExcelExport" size="small" type="converging-spinner"> </kendo-loader>
        Экспорт в Excel
    </button>
</div>

<kendo-grid #grid="kendoGrid"
                [kendoGridBinding]="departmentsName"
                [pageable]="true"
                [pageable]="{
                    buttonCount: buttonCount,
                    info: info,
                    type: type,
                    pageSizes: pageSizes,
                    previousNext: previousNext,
                    position: positionPager
                  }"
                [pageSize]="pageSize"
                [sortable]="true"
                (sortChange)="sortChange($event)"
                [filterable]="true"
                (filterChange)="filterChange($event)"
                [loading]="loading"
                [skip]="skip"
                (edit)="editHandler($event)">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <ng-template kendoGridToolbarTemplate [position]="positionEdit" *ngIf="isInEditingMode">
        <div *ngIf="isInEditingMode">
            <button kendoButton class="m-r10"
                    (click)="cancelHandler()">
            Отмена
            </button>
            <button kendoButton
                    themeColor="primary"
                    [disabled]="formGroup!.invalid || empty == true"
                    class="editRecord"
                    (click)="saveCurrent()"
                    >
            Сохранить
            </button>
        </div>
    </ng-template>

    <kendo-grid-command-column  [sticky]="true" title="" [width]="50" >
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
            <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full" *ngIf="rowIndex !== editedRowIndex">
            </button>
        </ng-template>
    </kendo-grid-command-column>

      <kendo-grid-column field="typeName"
                       title="Тип подразделения"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-combobox [useVirtual]='false' [filter]="filter" [data]="typeDepartment" textField="typeName" valueField="typeName">
            </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells centerAlignment reset">
               {{dataItem.typeName}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="name"
                       title="Название подразделения"
                       [width]="300"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells centerAlignment reset">
               {{dataItem.name}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="sName"
            title="Сокр.назв."
            required
            [width]="150"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-textbox [formControl]="formGroup.get('sName')" class="editRecord" [(ngModel)]="sNameEdit" (valueChange)="onChange($event)">
            </kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
                {{dataItem.sName}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="rName"
                         title="Название в родительном падеже"
                         required
                         [width]="300"
                         headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
          <kendo-textbox [formControl]="formGroup.get('rName')" class="editRecord" [(ngModel)]="rNameEdit" (valueChange)="onChange($event)">
          </kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells centerAlignment">
                    {{dataItem.rName}}
                </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="externalId"
            title="ID"
            required
            [width]="200"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{dataItem.externalId}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="createdAt"
            title="Дата создания"
            required
            [width]="200"
            filter="date" format="dd.MM.yyyy"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{dataItem.createdAt | date: 'dd.MM.yyyy'}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="updatedAt"
            title="Дата изменения"
            required
            [width]="200"
            filter="date" format="dd.MM.yyyy"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{dataItem.updatedAt | date: 'dd.MM.yyyy'}}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="commonStatus"
            title="Статус"
            required
            [width]="180"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [useVirtual]='false'  [filter]="filter" [data]="statuses" textField="statusName" valueField="commonStatus">
            </filter-dropdown>
        </ng-template>
    <!--   <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-combobox
            [data]="statuses"
            textField="statusName"
            valueField="commonStatus"
            [valuePrimitive]="true"
            [formControl]="formGroup.get('commonStatus')"
            class="editRecord">
            </kendo-combobox>
        </ng-template>-->
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
                {{ Status(dataItem.commonStatus) }}
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-excel fileName="Названия подразделений.xlsx" [fetchData]="allData"></kendo-grid-excel>
</kendo-grid>

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../models/useraccess/role";
import {PublicationsUserAccessService} from "../services/useraccess/publications-user-access.service";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class PublicationsUserAccessGuard implements CanActivate {

  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: PublicationsUserAccessService,
    private location: Location,
    private router: Router
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const token = this.tokenStore.getAuthToken()
    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;
      if (Object.keys(this.userAccessService.currentUserAccess$.value).length === 0) {
        await lastValueFrom(this.userAccessService.getCurrentUserAccess()).then(value => {
          this.userAccessService.currentUserAccess$.next(value);
        });
      }
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)) {
        return true;
      } else {
        if (this.userAccessService.currentUserAccess$.value.accessSettings) {
          return true;
        }
        alert('Недостаточно прав для доступа на эту страницу');
        localStorage.setItem('last_url', this.location.path())
        this.router.navigate([`/profile`]);
        return false;
      }
    }
    localStorage.setItem('last_url', this.location.path())
    return false;
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {handleError} from "../../../helpers/errorHandle-helper";
import {ApprovalType} from "../../../models/mfc/dicts/approval-type.model";

@Injectable({
  providedIn: 'root'
})
export class ApprovalTypeService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/ApprovalType/`;

  constructor(private http: HttpClient) { }

  public getApprovalTypes(): Observable<ApprovalType[]> {
    return this.http.get<ApprovalType[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }

  public getApplicationType(id: string): Observable<ApprovalType> {
    return this.http.get<ApprovalType>(`${this.baseUrl}${id}`);
  }

  public addApprovalType(type: ApprovalType): Observable<ApprovalType> {
    return this.http.post<ApprovalType>(this.baseUrl, type)
      .pipe(catchError(handleError));
  }

  public updateApprovalType(type: ApprovalType): Observable<ApprovalType> {
    return this.http.put<ApprovalType>(this.baseUrl, type)
      .pipe(catchError(handleError));
  }

  public removeApprovalType(id: string): Observable<ApprovalType> {
    return this.http.delete<ApprovalType>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

<kendo-stacklayout [gap]="30" orientation="vertical" class="wrapper">
  <kendo-label text="Учебный год" class="wrapper__label">
    <kendo-dropdownlist
      [data]="yearData"
      [value]="selectedYear"
      textField="semesterYearString"
      valueField="semesterYear"
      (valueChange)="onSelectYear($event)"
      class="wrapper__field"
    ><ng-template kendoDropDownListNoDataTemplate>
      <h3>Нет данных</h3>
    </ng-template>
    </kendo-dropdownlist>
  </kendo-label>
  <kendo-label text="Семестр" class="wrapper__label">
    <kendo-dropdownlist
      [data]="semesterData"
      [value]="selectedSemester"
      textField="semesterInYear"
      valueField="semesterNum"
      (valueChange)="onSelectSemester($event)"
      class="wrapper__field"
    >
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        {{dataItem?.semesterInYear + ' семестр'}}
      </ng-template>
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        {{dataItem?.semesterInYear + ' семестр'}}
      </ng-template>
      <ng-template kendoDropDownListNoDataTemplate>
        <h3>Нет данных</h3>
      </ng-template>
    </kendo-dropdownlist>
  </kendo-label>
  <kendo-stacklayout [align]="{ horizontal: 'center', vertical: 'middle' }" *ngIf="loadingTreeData">
    <kendo-loader size="medium" type="converging-spinner"></kendo-loader>
  </kendo-stacklayout>
  <kendo-treeview
    *ngIf="!loadingTreeData"
    [nodes]="dataTree"
    [loadOnDemand]="false"
    textField="text"
    kendoTreeViewHierarchyBinding
    childrenField="value"
    kendoTreeViewExpandable
    class="tree"
    [kendoTreeViewCheckable]="treeSettings"
    [(checkedKeys)]="checkedStudyForm"
    checkBy="id"
    [(expandedKeys)]="expandedKeys"
    (nodeClick)="onNodeClick($event)"
    (selectionChange)="onSelectTreeElem($event)"
    [isExpanded]="isExpanded"
  >
  </kendo-treeview>
  <button
    kendoButton
    (click)="onCreateReport()"
    class="button button--action"
    style="margin-top: 38px"
  >
    сформировать отчет
    <kendo-loader *ngIf="loadingReport" size="small"></kendo-loader>
  </button>
  <button
    kendoButton
    (click)="onCancel()"
    class="button button--action"
    style="margin-top: 38px"
  >
    отмена
  </button>
</kendo-stacklayout>

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {DictMassOperationTypes} from "../../models/contingent/dictmassoperationtypes.model";

@Injectable({
  providedIn: 'root'
})
export class DictmassoperationtypesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.massoperationtypes}`;

  constructor(private http: HttpClient) { }

  //Get all maritalStatusId
  public getAllMassOperationTypes(): Observable<DictMassOperationTypes[]> {
    return this.http.get<DictMassOperationTypes[]>(this.baseUrl + '/GetAllDictMassOperationTypes');
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

import {Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Author, AuthorsAndRubricators} from "../../../../../models/publications/editForm/author.model";
import {DictThematicRubricator, ThematicRubricatorTree} from "../../../../../models/publications/dict/thematicRubricator.model";
import {EditEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PublicationsEditFormService} from "../../../../../services/science/publications/editForm.service";
import {AuthorTypesService} from "../../../../../services/science/publications/authorTypes.service";
import {AuthorRolesService} from "../../../../../services/science/publications/authorRoles.service";
import {DictAuthorType} from "../../../../../models/publications/dict/authorType.model";
import {DictAuthorRole} from "../../../../../models/publications/dict/authorRole.model";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {ThematicRubricatorsService} from "../../../../../services/science/publications/thematicRubricators.service";
import {DropDownTreeComponent, PreventableEvent} from "@progress/kendo-angular-dropdowns";
import {isChildOf} from "../../../../../helpers/elementRef-helper";
import {lastValueFrom, Observable, of, Subscription} from "rxjs";
import {PublicationAuthorsService} from "../../../../../services/science/publications/publication-authors.service";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {PublicationEditFormTabs} from "../../../../../models/publications/editForm/editForm.enums";
import {AccessRights, ConditionalRole} from "../../../../../models/useraccess/publications/publicationsUserAccess";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {PublicationsService} from "../../../../../services/science/publications/publications.service";
import {PublicationForm} from "../../../../../models/publications/editForm/publication.model";
import {PropertiesService} from "../../../../../services/science/publications/properties.service";
import {StatusesService} from "../../../../../services/science/publications/statuses.service";
import { AuthorsService } from '../../../../../services/science/publications/authors.service';
import { DateToString } from '../../../../../helpers/date-helper';
import moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import {TreeItem} from "@progress/kendo-angular-treeview";

@Component({
  selector: 'publications-authors-and-thematic-rubricators',
  templateUrl: './publications-authors-and-thematic-rubricators.component.html',
  styleUrls: ['./publications-authors-and-thematic-rubricators.component.scss']
})
export class PublicationsAuthorsAndThematicRubricatorsComponent implements OnInit, OnDestroy {
  private publicationId!: string;
  public authorsView: Author[] = [];
  private authors: Author[] = [];
  public thematicRubricatorsView: string[] = [];
  private thematicRubricators: string[] = [];
  private dictThematicRubricators: DictThematicRubricator[] = [];
  public thematicRubricatorsTree: ThematicRubricatorTree[] = [];
  private dictAuthorTypes: DictAuthorType[] = [];
  private dictAuthorRoles: DictAuthorRole[] = [];
  public currentPublication!: PublicationForm;

  public loading: boolean = false;
  public formGroup!: FormGroup;

  private isUnsaved: boolean = false;
  private saveSubscription$!: Subscription;
  private changeSubscription$!: Subscription;
  private statusSubscription$!: Subscription;
  public isModerator: boolean = this.userAccessService.currentUserAccess$.value.conditionalRole === ConditionalRole.Moderator;

  @ViewChild('authorsGrid') public authorsGrid!: GridComponent;
  @ViewChild('rubricatorsGrid') public rubricatorsGrid!: GridComponent;
  @ViewChild('dropDownTree') public dropDownTree!: DropDownTreeComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private notificationsService: NotificationsService,
    private editFormService: PublicationsEditFormService,
    private statusesService: StatusesService,
    private authorTypesService: AuthorTypesService,
    private authorRolesService: AuthorRolesService,
    private thematicRubricatorsService: ThematicRubricatorsService,
    private authorsService: PublicationAuthorsService,
    private userAccessService: PublicationsUserAccessService,
    private publicationsService: PublicationsService,
    private propertiesService: PropertiesService,
    private externalAuthorsService: AuthorsService
  ) { }

  async ngOnInit() {
    this.publicationId = this.activatedRoute.snapshot.params['publicationId'];
    this.getDicts();
    this.getData();

    await this.getPublication();

    this.offDropDownTreeClick();
    this.onDropDownTreeItemClick();
    this.subscribe();
  }

  private subscribe() {
    this.saveSubscription$ = this.editFormService.save$.subscribe(value => {
      if (PublicationEditFormTabs.AuthorsAndThematicRubrications === value?.name) this.saveChanges();
    });

    this.changeSubscription$ = this.editFormService.change$.subscribe(value => {
      if (value === PublicationEditFormTabs.AuthorsAndThematicRubrications) {
        this.editFormService.setIsSaved(!this.isUnsaved);
      }
    });

    this.statusSubscription$ = this.statusesService.statusData$.subscribe((value) => {
      if (value === PublicationEditFormTabs.AuthorsAndThematicRubrications) {
        this.getPublication();
      }
    });
  }

  private getDicts() {
    this.authorTypesService.getAuthorTypes().subscribe(
      value => this.dictAuthorTypes = value.filter(type => type.name !== 'Студент'));
    this.authorRolesService.getAuthorRoles().subscribe(value => this.dictAuthorRoles = value);
    this.thematicRubricatorsService.getThematicRubricators().subscribe(value => {
      this.dictThematicRubricators = value.sort((a, b) => this.compareFlatData(a, b));
      this.thematicRubricatorsTree = this.processFlatData();
    });
  }

  private compareFlatData(a: DictThematicRubricator, b: DictThematicRubricator) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }

    return 0;
  }

  private processFlatData(id: string | null = null): ThematicRubricatorTree[] {
    const tree: ThematicRubricatorTree[] = [];
    this.dictThematicRubricators.filter(item => item.parentId === id)
      .forEach(child => {
        const data: ThematicRubricatorTree = {
          id: child.id,
          name: child.name
        };
        if (this.dictThematicRubricators.find(item => item.parentId === child.id)) {
          data.items = this.processFlatData(data.id);
        } else {
          child.isLeaf = true;
        }

        if (id || data.items?.length) {
          tree.push(data);
        }
      });
    return tree;
  }

  private getData() {
    this.loading = true;
    this.authorsService.getAll(this.publicationId).subscribe({
      next: (value) => {
        if (value) {
          this.authors = value.authors;
          this.authorsView = [...this.authors];
          this.thematicRubricators = value.thematicRubricatorIds;
          this.thematicRubricatorsView = [...this.thematicRubricators];
        }
        this.getUnsavedData();
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.notificationsService.showError(error);
      }
    })
  }

  private getUnsavedData() {
    const authors = this.editFormService.getAuthors();
    if (authors) {
      this.authorsView = authors;
      this.isUnsaved = true;
    }

    const rubricators = this.editFormService.getRubricators();
    if (rubricators) {
      this.thematicRubricatorsView = rubricators;
      this.isUnsaved = true;
    }

    const data = this.editFormService.getAuthorData();
    if (data) {
      if (data.id) {
        const index = this.authorsView.findIndex(author => author.id === data.id);
        this.authorsView[index] = data;
      } else if (data.index) {
        this.authorsView[data.index] = data.author;
      } else {
        this.authorsView.push(data);
      }
      this.isUnsaved = true;
    }
  }

  public getAuthorTypeName(id: string) {
    return this.dictAuthorTypes.find(type => type.id === id)?.name ?? '';
  }

  public getAuthorRoleName(id: string) {
    return this.dictAuthorRoles.find(type => type.id === id)?.name ?? '';
  }

  public getFIO(author: Author) {
    return `${author.lastName} ${author.firstName} ${author.middleName}`;
  }

  private async saveChanges() {
    const data: AuthorsAndRubricators = {
      publicationId: this.publicationId,
      authors: this.authorsView,
      thematicRubricatorIds: this.thematicRubricatorsView
    };

    const changedExternalAuthors = this.authorsView.filter((authorView) => {
      if (!moment.isMoment(authorView.birthday)) {
        return;
      }
      const authorViewDate = `${moment(authorView.birthday).format('DD.MM.YYYY')}`;

      return this.editFormService.externalAuthors$.value.some(( value) => value.id === authorView.personId
        && (moment.isMoment(authorView.birthday) && authorViewDate !== DateToString(value.birthday) || value.identificator !== authorView.identificator));
    });

    if (data.thematicRubricatorIds.length) {
      this.authorsService.updateAll(data).subscribe({
        next: () => {
          this.notificationsService.showSuccess('Успешно');
          this.isUnsaved = false;
          this.editFormService.currentTab$.next(this.editFormService.save$.value?.disableCodes
            ? PublicationEditFormTabs.About
            : PublicationEditFormTabs.Codes);
        },
        error: (error) => this.notificationsService.showError(error)
      });

      let errMsg: HttpErrorResponse | null = null;
      for (const externalAuthor of changedExternalAuthors) {
        await lastValueFrom(this.externalAuthorsService.updateExternalAuthor({
          birthday: externalAuthor.birthday,
          firstName: externalAuthor.firstName,
          identificator: externalAuthor.identificator,
          lastName: externalAuthor.lastName,
          middleName: externalAuthor.middleName,
          id: externalAuthor.personId
        })).catch((error: HttpErrorResponse) => errMsg = error);
      }
      if (errMsg) {
        this.notificationsService.showError(errMsg);
      }
    } else {
      this.notificationsService.showError('Заполните все обязательные разделы');
    }
  }

  public getThematicRubricatorName(id: string) {
    return this.dictThematicRubricators.find(item => item.id === id)?.name ?? '';
  }

  private closeEditor() {
    this.rubricatorsGrid.closeRow();
  }

  private getFormGroup() {
    return new FormGroup({
      item: new FormControl<ThematicRubricatorTree | null>(null, Validators.required)
    });
  }

  public onRubricatorChange(value: ThematicRubricatorTree) {
    if (!value.items) {
      this.formGroup.value.item = value;
      this.saveRow();
    } else {
      this.formGroup = this.getFormGroup();
    }
  }

  private onDropDownTreeItemClick() {
    this.renderer.listen("document", "click", ({target}) => {
      if (isChildOf(target, "k-treeview-item") && target.innerText && this.dropDownTree?.nodes) {
        this.onItemClick(target.innerText);
      }
    });
  }

  private offDropDownTreeClick() {
    this.renderer.listen("document", "click", ({target}) => {
      if (this.dropDownTree && this.dropDownTree.isOpen
        && !isChildOf(target, "k-popup-dropdowntree")
        && !isChildOf(target, "table__cell")) {
        this.closeEditor();
      }
    });
  }

  public onFilterChange(e: string) {
    if (e) {
      this.dropDownTree.data = this.dictThematicRubricators.filter(item =>
        item.isLeaf && item.name.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.dropDownTree.data = [...this.thematicRubricatorsTree];
    }
  }

  public onDropDownTreeClose(e: PreventableEvent) {
    e.preventDefault();
  }

  public children = (dataItem: any): Observable<any[]> => of(dataItem.items);

  public hasChildren = (dataItem: any): boolean => !!dataItem.items;

  public onFilterClearBtnClick() {
    this.dropDownTree.filterChange.next('');
    // fixes bug with filter clearing
    this.dropDownTree.toggle();
    this.dropDownTree.toggle();
  }

  private onItemClick(value: string) {
    const item = this.searchItem(this.dropDownTree.nodes, value);
    if (item?.dataItem.items) {
      if (this.dropDownTree.isNodeExpanded(item.dataItem, item.index)) {
        this.dropDownTree.collapse.next(item);
      } else {
        this.dropDownTree.expand.next(item);
      }
    }
  }

  private searchItem(
    tree: ThematicRubricatorTree[] | undefined,
    value: string,
    index: string = '',
    searchStrLength: number = 3
  ): TreeItem | null {
    if (!tree) return null;

    const item = tree.find(item =>
      item.name.slice(0, searchStrLength) === value.slice(0, searchStrLength));
    const itemIndex = tree.findIndex(node => node.id === item?.id).toString();
    index = index ? `${index}_${itemIndex}` : itemIndex;

    if (item?.name.includes(value)) {
      return {index: index, dataItem: item};
    } else {
      searchStrLength += 3;
      return this.searchItem(item?.items, value, index, searchStrLength);
    }
  }

  private saveRow()  {
    if (this.formGroup.invalid) {
      return;
    }

    let isUnique = true;
    this.thematicRubricatorsView.forEach(item => {
      if (!isUnique) {
        return;
      }
      isUnique = item !== this.formGroup.value.item.id;
    });
    if (!isUnique) {
      this.notificationsService.showError('Данный рубрикатор уже добавлен');
      return;
    }

    this.thematicRubricatorsView.push(this.formGroup.value.item.id);
    this.isUnsaved = true;
    this.closeEditor();
  }

  public addRow() {
    this.formGroup = this.getFormGroup();
    this.rubricatorsGrid.addRow(this.formGroup);
  }

  public onRemoveAuthor({dataItem, rowIndex}: RemoveEvent) {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить ${this.getFIO(dataItem)}?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.authorsView.splice(rowIndex, 1);
        this.isUnsaved = this.authors.length > 0 || this.authorsView.length > 0;
      }
    });
  }

  public onRemoveRubricator({dataItem}: RemoveEvent) {
    const dialog: DialogRef = openDialog(this.dialogService,
      `Удалить ${this.getThematicRubricatorName(dataItem)}?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.thematicRubricatorsView = this.thematicRubricatorsView.filter(
          (item) => item !== dataItem);
        this.isUnsaved = this.thematicRubricators.length > 0 || this.thematicRubricatorsView.length > 0;
      }
    });
  }

  private saveUnsavedInfo() {
    this.editFormService.saveAuthors(this.authorsView);
    this.editFormService.saveRubricators(this.thematicRubricatorsView);
  }

  private saveCurrentTab() {
    this.editFormService.currentTab$.next(PublicationEditFormTabs.AuthorsAndThematicRubrications);
  }

  public addAuthor() {
    if (this.isUnsaved) this.saveUnsavedInfo();
    this.saveCurrentTab();
    this.router.navigate(['publications/authors/addAuthorForPublication']);
  }

  public onEditAuthor({dataItem, rowIndex}: EditEvent) {
    if (this.isUnsaved) this.saveUnsavedInfo();
    this.saveCurrentTab();
    if (dataItem.id) {
      this.editFormService.setAuthorData(this.authorsView[rowIndex]);
      this.router.navigate([`publications/authors/${dataItem.id}/addAuthorForPublication`]);
    } else {
      this.editFormService.setAuthorData(this.authorsView[rowIndex]);
      this.router.navigate([`publications/authors/addAuthorForPublication/${rowIndex}`]);
    }
  }

  public reorderRow(from: number, to: number) {
    if (to >= 0 || to < this.authorsView.length) {
      const item = this.authorsView.splice(from, 1)[0];
      this.authorsView.splice(to, 0, item);
      this.isUnsaved = true;
    }
  }

  private async getPublication() {
    await lastValueFrom(this.publicationsService.getPublication(this.publicationId))
        .then((value) => {
          this.currentPublication = value;
        });
  }
  public isStaticPropertyDisabled(): boolean {
    return this.propertiesService.disableStaticProperty(this.currentPublication, this.isModerator);
  }

  private unsubscribe() {
    this.saveSubscription$.unsubscribe();
    this.changeSubscription$.unsubscribe();
    this.statusSubscription$.unsubscribe();
  }

  ngOnDestroy(): void {
    this.editFormService.save$.next(null);
    this.unsubscribe();
  }

  protected readonly AccessRights = AccessRights;
}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PrintTemplateForm, PrintTemplateModel } from '../../models/gia/printtemplate/printTemplate.model';
import { isArray } from 'lodash';
import { catchError } from 'rxjs/operators';
import { handleError } from '../../helpers/errorHandle-helper';

@Injectable({
  providedIn: 'root',
})
export class PrintTemplateService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.printTemplate}`;
  constructor(private http: HttpClient) {}

  getList(yearAdmission: number, filialId?: string | null, category?: number, trainingLevelId?: string | null, departmentId?: string): Observable<PrintTemplateModel[]> {
    let filter = '';

    if (filialId !== null && filialId !== undefined) filter += `filialId=${filialId}`;

    if (category) filter += `${filter ? '&' : ''}category=${category}`;

    if (departmentId) filter += `${filter ? '&' : ''}departmentId=${departmentId}`;

    if (trainingLevelId) filter += `${filter ? '&' : ''}trainingLevelId=${trainingLevelId}`;

    const queryString = filter ? `?${filter}` : '';

    return this.http.get<PrintTemplateModel[]>(`${this.baseUrl}/GetAll/${yearAdmission}${queryString}`);
  }

  get(printTemplateId: string): Observable<PrintTemplateModel> {
    return this.http.get<PrintTemplateModel>(`${this.baseUrl}/${printTemplateId}`).pipe(catchError(this.handleError));
  }

  public getPrintTemplateFile(printTemplateId: string) {
    return this.http.get(`${this.baseUrl}/GetFileById/${printTemplateId}`, { responseType: 'blob' }).pipe(catchError(handleError));
  }

  public getFileByPrintTemplateId(printTemplateId: string) {
    return this.http.get(`${this.baseUrl}/GetFileByPrintTemplateId/${printTemplateId}`, { responseType: 'blob' }).pipe(catchError(handleError));
  }

  public downloadArchiveByPrintTemplateId(printTemplateId: string) {
    return this.http.get(`${this.baseUrl}/GetFileByPrintTemplateId/${printTemplateId}`, { responseType: 'arraybuffer' }).pipe(catchError(handleError));
  }

  private getFormData(body: PrintTemplateForm) {
    const data = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      const hasKeyTemplate = key === 'printTemplateFirstFile' || key === 'printTemplateSecondFile' || key === 'printTemplateThirdFile';
      data.append(key, hasKeyTemplate && isArray(value) ? value[0] : value);
    });
    return data;
  }

  public update(printTemplate: PrintTemplateForm): Observable<boolean> {
    const formData = this.getFormData(printTemplate);

    const tLevels = printTemplate.printTemplateTrainingLevels.map(x => `tLevels=${x.id}`).join('&');

    return this.http.put<boolean>(`${this.baseUrl}?${tLevels}`, formData).pipe(catchError(handleError));
  }

  add(printTemplate: PrintTemplateForm): Observable<string> {
    const formData = this.getFormData(printTemplate);

    const tLevels = printTemplate.printTemplateTrainingLevels.map(x => `tLevels=${x.id}`).join('&');

    return this.http.post<string>(`${this.baseUrl}?${tLevels}`, formData).pipe(catchError(handleError));
  }

  delete(printTemplateId: string): Observable<PrintTemplateModel> {
    return this.http.delete<PrintTemplateModel>(`${this.baseUrl}/${printTemplateId}`).pipe(catchError(handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

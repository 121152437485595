<contingent-header></contingent-header>
<h1>Добавление группы</h1>

<form [formGroup]="formEduGroup">
  <dl class="about">
    <kendo-label class="academic-label" text="Филиал" for="filial"></kendo-label>
    <dd>
      <kendo-formfield>
        <kendo-combobox [data]="filials"
                        textField="filialSName"
                        valueField="dictFilialExternalId"
                        class="add-edugroup"
                        [disabled]="!firstStage"
                        [valuePrimitive]="true"
                        #filial
                        placeholder="Выберите филиал"
                        [openOnFocus]="filial"
                        [kendoDropDownFilter]="filterSettings"
                        formControlName="filialId"
                        (valueChange)="onFilialChange()">
        </kendo-combobox>
      </kendo-formfield>
    </dd>
    <kendo-label class="academic-label" text="Институт/факультет" for="faculty"></kendo-label>
    <dd>
      <kendo-formfield>
        <kendo-combobox [data]="selectedFaculties"
                        textField="shortName"
                        valueField="facultyExternalId"
                        class="add-edugroup"
                        [disabled]="!firstStage"
                        [valuePrimitive]="true"
                        #institute
                        placeholder="Выберите институт/факультет"
                        [openOnFocus]="institute"
                        [kendoDropDownFilter]="filterSettings"
                        formControlName="facultyId"
                        (valueChange)="filterEducationPlan()">
        </kendo-combobox>
        <span *ngIf="editable" class="required">*</span>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
    </dd>
    <kendo-label class="academic-label" text="Год поступления"></kendo-label>
    <dd>
      <kendo-formfield>
        <kendo-combobox [data]="startYears"
                        textField="year"
                        valueField="year"
                        class="add-edugroup"
                        [disabled]="!firstStage"
                        [valuePrimitive]="true"
                        #course
                        placeholder="Выберите "
                        (valueChange)="admissionYearChange()"
                        [openOnFocus]="course"
                        [kendoDropDownFilter]="filterSettings"
                        formControlName="admissionYear">
        </kendo-combobox>
        <span *ngIf="editable" class="required">*</span>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
    </dd>
    <kendo-label class="academic-label" text="Учебный план группы"></kendo-label>
    <dd>
      <kendo-formfield>
        <kendo-combobox [data]="selectedEducationPlans"
                        textField="name"
                        valueField="externalId"
                        class="add-edugroup"
                        [valuePrimitive]="true"
                        [disabled]="!firstStage"
                        #plan
                        placeholder="Выберите учебный план"
                        [openOnFocus]="plan"
                        [kendoDropDownFilter]="filterSettings"
                        (valueChange)="educationPlanChange($event)"
                        formControlName="planId">
          <ng-template kendoComboBoxItemTemplate let-dataItem>
            {{ dataItem.name + " (" + educationPlanStatusName(dataItem.statusId) +")" }}
          </ng-template>
        </kendo-combobox>
        <span *ngIf="editable" class="required">*</span>
        <button kendoButton icon="hyperlink-open" fillMode="flat" size="none" class="custom-size" rounded="full"
                title="Перейти к учебному плану" [disabled]="formEduGroup.value.planId == undefined"
                (click)="openPlan()">
        </button>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
    </dd>

    <kendo-label class="academic-label" text="Курс"></kendo-label>
    <dd class="edit-edugroup">
      <kendo-label>{{academicGroup.courseNum == null || academicGroup.courseNum < 0 ? "-" : academicGroup.courseNum + " курс"}}</kendo-label>
    </dd>
    <kendo-label class="academic-label" text="Направление подготовки"></kendo-label>
    <dd class="edit-edugroup">
      <kendo-label>{{academicGroup.standardName == null ? "-" : academicGroup.standardName}}</kendo-label>
    </dd>
    <kendo-label class="academic-label" text="Профиль"></kendo-label>
    <dd class="edit-edugroup">
      <kendo-label>{{academicGroup.programName == null ? "-" : academicGroup.programName}}</kendo-label>
    </dd>
    <kendo-label class="academic-label" text="Форма обучения"></kendo-label>
    <dd class="edit-edugroup">
      <kendo-label>{{academicGroup.studyFormName == null ? "-" : academicGroup.studyFormName}}</kendo-label>
    </dd>
    <kendo-label class="academic-label" text="Численность группы, чел"></kendo-label>
    <dd>
      <kendo-label class="admission-plan">{{"План приема: " + (formEduGroup.value.budgetQuotesStudentAdmissionBenchmarks
        + formEduGroup.value.commerceQuotesStudentAdmissionBenchmarks)}}</kendo-label>
    </dd>

    <kendo-label class="academic-label academic-student-count-label" text="КЦП (бюджет)"></kendo-label>
    <dd>
      <kendo-formfield>
        <kendo-numerictextbox
          class="add-edugroup-count"
          [min]="0"
          format="n0"
          [disabled]="!firstStage"
          formControlName="budgetQuotesStudentAdmissionBenchmarks">
        </kendo-numerictextbox>
      </kendo-formfield>
    </dd>

    <kendo-label class="academic-label academic-student-count-label" text="коммерция"></kendo-label>
    <dd>
      <kendo-formfield>
        <kendo-numerictextbox
          class="add-edugroup-count"
          [min]="0"
          format="n0"
          [disabled]="!firstStage"
          formControlName="commerceQuotesStudentAdmissionBenchmarks">
        </kendo-numerictextbox>
      </kendo-formfield>
    </dd>

    <kendo-label class="academic-label" text="Название группы"></kendo-label>
    <dd>
      <kendo-formfield>
        <kendo-textbox class="add-edugroup-name"
                       [(ngModel)]="this.formEduGroup.value.groupName"
                       formControlName="groupName"
                       [disabled]="!firstStage"
                       [clearButton]="true"
                       required>
        </kendo-textbox>
        <span *ngIf="editable" class="required">*</span>
        <div kendoTooltip style="display:contents;" position="right" *ngIf="editable">
          <kendo-loader *ngIf="loaderVisible" [type]="type" size="small"> </kendo-loader>
          <button kendoButton *ngIf="!loaderVisible" icon="reload" fillMode="flat" size="none" class="custom-size" rounded="full"
                  [disabled]="!firstStage"
                  title="Обновить название группы по правилам именования групп"
                  (click)="getEduGroupName()">
          </button>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
    </dd>

    <kendo-label class="academic-label" text="Примечание"></kendo-label>
    <dd>
      <kendo-textarea
        class="add-edugroup"
        resizable="none"
        [disabled]="!firstStage"
        [rows]="3"
        formControlName="notice">
      </kendo-textarea>
    </dd>

  </dl>
</form>

<div class="editModeButtons eduGroupButtons firstStage" *ngIf="firstStage">
  <button kendoButton size="small" themeColor="primary" [disabled]="formEduGroup.invalid" (click)="nextStep()">
    Следующий шаг
  </button>

  <button kendoButton size="small" [routerLink]="['/contingent/academicgroupshome']">
    Отмена
  </button>
</div>

<form #semesters = "ngForm">
  <div *ngIf="!firstStage">
    <h5 class="groupSemestersHeader">Проверьте сроки семестров группы</h5>
    <dl class="dl-deadlines">
      <kendo-label class="academic-label" text="Продолжительность обучения"></kendo-label>
      <dd class="edit-edugroup">
        <kendo-label>{{getTrainingDuration()}}</kendo-label>
      </dd>

      <kendo-label class="academic-label" text="Профилизация"></kendo-label>
      <dd class="edit-edugroup">
        <kendo-label>{{ planSemesters?.profilisation + " курс"}}</kendo-label>
      </dd>
    </dl>

    <div class="semester-box">
      <div class="alignCells" style="min-width: 375px">
        <kendo-label class="academic-label"  text="Название группы"></kendo-label>
      </div>
      <div class="alignCells">
        <kendo-label class="academic-label">{{"Сроки 1го " + (trimester? "триместра" : "семестра")}}</kendo-label>
      </div>
      <div class="alignCells">
        <kendo-label class="academic-label">{{"Сроки 2го " + (trimester? "триместра" : "семестра")}}</kendo-label>
      </div>
      <div class="alignCells">
        <kendo-label [hidden]="!trimester" class="academic-label" text="Сроки 3го триместра"></kendo-label>
      </div>
      <div class="semester-row" *ngFor="let item of semesterData;let i = index">
        <div class="centerAlignment">
          <kendo-label class="academic-label academic-course-label">{{(i + 1) + " курс"}}</kendo-label>
          <kendo-textbox class="semester-group-name"
                         name="groupName{{i}}"
                         [(ngModel)]="item.groupName"
                         [disabled]="!editable"
                         [clearButton]="true"
                         required>
          </kendo-textbox>
          <div kendoTooltip style="display:contents;" position="right" *ngIf="editable">
            <button kendoButton *ngIf="!loaderVisible" icon="reload" fillMode="flat" size="none" class="custom-size" rounded="full"
                    title="Обновить название группы по правилам именования групп"
                    (click)="getEduGroupName(i)">
            </button>
          </div>
        </div>
        <div class="centerAlignment">
          <kendo-label class="academic-label" text="с"></kendo-label>
          <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.firstSemesterStart" name="firstSemesterStart{{i}}" [max]="item.firstSemesterEnd" required></kendo-datepicker>
          <kendo-label class="academic-label" text="по"></kendo-label>
          <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.firstSemesterEnd" name="firstSemesterEnd{{i}}" required></kendo-datepicker>
        </div>
        <div class="centerAlignment">
          <kendo-label class="academic-label" text="с"></kendo-label>
          <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.secondSemesterStart" name="secondSemesterStart{{i}}" [max]="item.secondSemesterEnd" required></kendo-datepicker>
          <kendo-label class="academic-label" text="по"></kendo-label>
          <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.secondSemesterEnd" name="secondSemesterEnd{{i}}" required></kendo-datepicker>
        </div>
        <div class="centerAlignment">
          <kendo-label class="academic-label" text="с" [hidden]="!item.thirdSemesterBegin"></kendo-label>
          <kendo-datepicker class="academic-datepicker" [hidden]="!item.thirdSemesterBegin" [(ngModel)]="item.thirdSemesterBegin" name="thirdSemesterBegin{{i}}"></kendo-datepicker>
          <kendo-label class="academic-label" text="по" [hidden]="!item.thirdSemesterBegin"></kendo-label>
          <kendo-datepicker class="academic-datepicker" [hidden]="!item.thirdSemesterBegin" [(ngModel)]="item.thirdSemesterEnd" name="thirdSemesterEnd{{i}}"></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>

  <div class="editModeButtons eduGroupButtons secondStage" *ngIf="!firstStage">
    <button kendoButton size="small" themeColor="primary" [disabled]="!formEduGroup.valid || (semesters.invalid != null && semesters.invalid)" (click)="onSave($event)">
      Создать группу
    </button>

    <button kendoButton size="small" (click)="previousStep()">
      Предыдущий шаг
    </button>

    <button kendoButton size="small" [routerLink]="['/contingent/academicgroupshome']">
      Отмена
    </button>
  </div>
</form>

<div kendoDialogContainer></div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DictDirectionActivity, DictDocumentType, DictAchievementStatus, WorkType } from '../../models/portfolio/dict.model';

@Injectable({
  providedIn: 'root'
})
export class DictService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.portfolio.dict}`;

  constructor(private http: HttpClient) { }

  getDirectionActivities(): Observable<DictDirectionActivity[]> {
    return this.http.get<DictDirectionActivity[]>(this.baseUrl + '/DirectionActivity');
  }

  getDocumentTypes(): Observable<DictDocumentType[]> {
    return this.http.get<DictDocumentType[]>(this.baseUrl + '/DocumentType');
  }

  getStatuses(): Observable<DictAchievementStatus[]> {
    return this.http.get<DictAchievementStatus[]>(this.baseUrl + '/DictAchievementStatus');
  }

  getTypes(): Observable<WorkType[]> {
    return this.http.get<WorkType[]>(this.baseUrl + '/WorkType');
  }
}

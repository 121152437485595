enum AcademicStateEnumList {
  study = 1,
  academic = 2,
  expelled = 3,
  transferred = 4,
  graduated = 5,

  wait = 10
}

export var AcademicStateEnum: any = AcademicStateEnumList

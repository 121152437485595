import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {TrainingLevel} from '../../../models/mfc/dicts/training-level.model';


@Injectable({
  providedIn: 'root'
})
export class TrainingLevelService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.externalDict}/TrainingLevel/`;

  constructor(private http: HttpClient) { }

  public getTrainingLevels(): Observable<TrainingLevel[]> {
    return this.http.get<TrainingLevel[]>(this.baseUrl);
  }

  public getTrainingLevel(id: string): Observable<TrainingLevel> {
    return this.http.get<TrainingLevel>(`${this.baseUrl}${id}`);
  }
}

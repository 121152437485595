import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {studentFieldKeys, studentFieldsMap} from "../../../../../models/mfc/student-fields";
import {ChangeEvent, ColumnsInfo} from '../../../../../models/mfc/constructor-listbox.model';
import {FieldType} from "../../../../../models/mfc/enums/constructor-listbox.enum";
import {AbstractControl, FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {AdditionalPropertyDto, GroupPropertyDto, PropertyDto, TabsSettingsDto} from "../../../../../models/mfc/applicationConstructor/settings/constructor-tabs-settings.model";
import {ApplicationConstructorTabsService} from "../../../../../services/mfc/application-constructor-tabs.service";
import {ActivatedRoute} from "@angular/router";
import {TextBoxComponent} from "@progress/kendo-angular-inputs";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {DropDownListComponent} from "@progress/kendo-angular-dropdowns";
import {boolOptions} from "../../../../../models/useraccess/options";
import {Role, roleAccess, RoleType} from "../../../../../models/mfc/dicts/role.model";
import {RoleService} from "../../../../../services/mfc/dicts/role.service";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {arrayRewrite} from "../../../../../helpers/multiselect-helper";
import {DictPropertyService} from "../../../../../services/mfc/dicts/property.service";
import {DictProperty} from "../../../../../models/mfc/dicts/property.model";
import {dictPropertyTypes} from "../../../../../models/mfc/property-types";
import {DictCreatorService} from "../../../../../services/mfc/dict-creator.service";
import {DynamicDict} from "../../../../../models/mfc/dicts/dynamic-dict.model";
import {ApplicationConstructorTabsEnum} from '../../../../../models/mfc/enums/application-constructor-tabs.enum';
import {Subscription} from 'rxjs';
import {
  ApplicationConstructorEditFormService
} from '../../../../../services/mfc/application-constructor-edit-form.service';
import {TabsRequiredFields} from '../../../../../models/mfc/enums/tabs-required-fields.enum';

@Component({
  selector: 'mfc-tabs-settings',
  templateUrl: './tabs-settings.component.html',
  styleUrls: ['./tabs-settings.component.scss', '../../../styles/application-constructor.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationConstructorTabsSettingsComponent implements OnInit {

  @ViewChild('tabDropdown') private tabDropdown!: DropDownListComponent;
  @ViewChild('groupFieldsDrodpdown') private groupFieldsDrodpdown?: DropDownListComponent;
  @Input() editable = false;

  private constructorId = this.activatedRoute.snapshot.params['id'];
  private isChanged = false;
  private invalidTabs: {tab: string, groups: string[]}[] = [];

  protected fieldCodesHiddenData: {code: string}[] = [];
  protected fieldCodesVisibleData: {code: string, param: string}[] = [];
  protected fieldCodesData: {code: string, param: string}[] = [];
  protected propertiesHiddenData: DictProperty[] = [];
  protected propertiesVisibleData: PropertyDto[] = [];
  protected propertiesData: PropertyDto[] = [];
  protected groupProperties: GroupPropertyDto[] = [];
  protected additionalProperties: AdditionalPropertyDto[] = [];

  protected dictRoles: Role[] = [];
  protected dictProperties: DictProperty[] = [];
  protected dictPropetyTypes = dictPropertyTypes;
  protected dicts: DynamicDict[] = this.dictCreatorService.dicts$.value;

  protected readonly unusedRoleIds: string[] = Object.values(RoleType);

  protected tabsSettings: TabsSettingsDto[] = [];
  protected readonly boolOptions = boolOptions;
  protected readonly excludedKeys = ['id', 'groupProperties'];
  protected readonly additionalPropertyTooltip = `Метки необходимо подставить
  в печатную форму шаблона справки для автоматической подстановки данных в шаблон `;

  protected showDialog = false;
  protected isTabDialog = false;

  private lastGroupPropertyIndex?: number = 0;
  private lastTabIndex?: number = 0;

  protected fieldCodesInfo: ColumnsInfo<{code: string}, {code: string, param: string}> = {
    hidden: [
      {
        field: 'code',
        type: FieldType.Text,
        title: 'Наименование',
        width: 200,
        map: studentFieldsMap,
      },
    ],
    visible: [
      {
        field: 'code',
        type: FieldType.Text,
        title: 'Наименование',
        width: 200,
        map: studentFieldsMap,
      },
      {
        field: 'param',
        type: FieldType.Text,
        title: 'Название метки для печатной формы',
        width: 250,
        tooltip: 'В параметрах необходимо прописать конкретные значения выбранных фильтров',
      },
    ],
  };

  protected propertiesInfo: ColumnsInfo<DictProperty, PropertyDto> = {
    hidden: [
      {
        field: 'name',
        type: FieldType.Text,
        title: 'Наименование',
        width: 200,
      },
      {
        field: 'dictPropertyTypeName',
        type: FieldType.Text,
        title: 'Тип данных',
        width: 200,
      },
    ],
    visible: [
      {
        field: 'dictProperty',
        type: FieldType.Text,
        title: 'Наименование',
        width: 200,
        objectField: 'name'
      },
      {
        field: 'dictProperty',
        type: FieldType.Text,
        title: 'Тип данных',
        width: 200,
        objectField: 'dictPropertyTypeName'
      },
      {
        field: 'dictProperty',
        type: FieldType.Text,
        title: 'Название метки для печатной формы',
        width: 200,
        tooltip: 'Метки необходимо подставить в печатную форму шаблона справки для автоматической подстановки данных в шаблон',
        objectField: 'printKey'
      },
      {
        field: 'required',
        type: FieldType.Dropdown,
        title: 'Обязательность заполнения',
        width: 150,
        required: true,
        listOptions: {
          data: this.boolOptions,
          textField: 'text',
          valueField: 'id'
        },
      },
      {
        field: 'roleIds',
        type: FieldType.Multiselect,
        title: 'Доступ к заполнению',
        required: true,
        width: 190,
        listOptions: {
          data: [],
          textField: 'name',
          valueField: 'id'
        },
      },
      {
        field: 'tooltip',
        type: FieldType.Textbox,
        title: 'Подсказка к полю',
        width: 200,
      },
    ],
  };

  protected formGroup?: UntypedFormGroup = undefined;
  protected groupPropertiesFormGroup?: UntypedFormGroup = undefined;

  private checkChangesSubscription$!: Subscription;

  constructor(
    private tabsSettingsService: ApplicationConstructorTabsService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private roleService: RoleService,
    private notificationsService: NotificationsService,
    private propertyService: DictPropertyService,
    private dictCreatorService: DictCreatorService,
    private editFormService: ApplicationConstructorEditFormService
  ) { }

  ngOnInit(): void {
    this.getRoles();
    this.getDictProperties();
    this.getTabSettings();
    this.checkChangesSubscription$ = this.editFormService.checkChanges$.subscribe(value => {
      if (value === ApplicationConstructorTabsEnum.Tabs) {
        this.editFormService.setHasChanges(this.isChanged);
      }
    });
  }

  private getDictProperties() {
    this.propertyService.getAll().subscribe(data => {
      this.dictProperties = data;
    });
  }

  private getRoles() {
    this.roleService.getRoles().subscribe(data => {
      this.dictRoles = [...roleAccess, ...data];
      const role = this.propertiesInfo.visible.find(
        (item) => item.field === 'roleIds' && item.type === FieldType.Multiselect
      );
      if (role) {
        role.listOptions = {
          data: this.dictRoles,
          textField: 'name',
          valueField: 'id',
        };
      }
    });
  }

  private getTabSettings() {
    this.tabsSettingsService.getTabSettings(this.constructorId).subscribe((response) => {
      this.tabsSettings = response;
      if (this.lastTabIndex !== undefined && this.lastTabIndex < this.tabsSettings.length) {
        this.onTabChange(this.tabsSettings[this.lastTabIndex], false);
        this.tabDropdown.value = this.tabsSettings[this.lastTabIndex];
        this.cdRef.detectChanges();

        if (this.lastGroupPropertyIndex !== undefined && this.lastGroupPropertyIndex < this.groupProperties.length) {
          this.onGroupPropertiesChange(this.groupProperties[this.lastGroupPropertyIndex], false);
          if (this.groupFieldsDrodpdown)
            this.groupFieldsDrodpdown.value = this.groupProperties[this.lastGroupPropertyIndex];
        } else {
          this.lastGroupPropertyIndex = undefined;
          this.groupFieldsDrodpdown?.reset();
        }
      } else {
        this.cancelHandler();
      }
      this.cdRef.detectChanges();
    });
  }

  private createFormGroup(dataItem: TabsSettingsDto | GroupPropertyDto) {
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(dataItem).filter(a => !this.excludedKeys.includes(a)).forEach((key) => {
      let value = dataItem[key as keyof typeof dataItem];
      if (key === 'sortNumber') {
        const lastNumber = ((<TabsSettingsDto & GroupPropertyDto>dataItem).groupProperties
          ? this.tabsSettings
          : this.groupProperties).length;
        value = value < 0 ? lastNumber : value;
      }
      arr[key] = new FormControl(
        {value, disabled: !this.editable},
        (<string[]>Object.values(TabsRequiredFields)).includes(key) ? Validators.required : null);
    });
    return new FormGroup(arr);
  }

  protected fieldCodesToVisible(item: {code: string}): {code: string, param: string} {
    return {code: item.code, param: item.code};
  }

  protected fieldCodesToHidden(item: {code: string, param: string}): {code: string} {
    return {code: item.code};
  }

  protected propertiesToVisible(item: DictProperty): PropertyDto {
    return {...new PropertyDto(), dictPropertyId: item.externalId, dictProperty: item};
  }

  protected propertiesToHidden(item: PropertyDto) {
    return item.dictProperty ?? new DictProperty();
  }

  protected getFieldCodesData({value}: ChangeEvent<{code: string, param: string}>) {
    this.fieldCodesData = value;
    this.groupPropertiesFormGroup?.get('fieldCodes')?.patchValue(this.fieldCodesData.map(a => a.code));
    this.fieldChange();
  }

  protected getPropertiesData({value, invalid}: ChangeEvent<PropertyDto>) {
    this.propertiesData = value.map((a, index) => {
      const roleIds = arrayRewrite(a.roleIds);
      return {
        ...a,
        sortNumber: index + 1,
        dictProperty: undefined,
        roleIds: a.roleIds.filter(item => !this.unusedRoleIds.includes(item)),
        isEditableForAll: roleIds.includes(RoleType.All),
        IsEditableForInitiator: roleIds.includes(RoleType.Initiator)
      }
    });
    this.groupPropertiesFormGroup?.get('properties')?.patchValue(this.propertiesData);
    this.fieldChange(invalid);
  }

  protected saveTab() {
    this.saveGroupProperty();

    if (this.formGroup && this.lastTabIndex !== undefined && this.lastTabIndex >= 0) {
      this.tabsSettings[this.lastTabIndex] = {
        ...this.tabsSettings[this.lastTabIndex],
        ...this.formGroup.getRawValue(),
        groupProperties: this.groupProperties
      };
    }
  }

  protected saveGroupProperty() {
    if (this.groupPropertiesFormGroup && this.lastGroupPropertyIndex !== undefined && this.lastGroupPropertyIndex >= 0) {
      this.groupProperties[this.lastGroupPropertyIndex] = {
        ...this.groupPropertiesFormGroup.getRawValue(),
        additionalProperties: this.mapAdditionalProperties(this.additionalProperties)
      };
    }
  }

  protected onTabChange(tab: TabsSettingsDto, allowSave: boolean = true) {
    if (allowSave) {
      this.saveTab();
      this.groupFieldsDrodpdown?.reset();
    }

    this.formGroup = this.createFormGroup(tab);
    this.lastTabIndex = this.tabsSettings.findIndex(item => item === tab);

    this.groupProperties = tab.groupProperties;
    this.groupPropertiesFormGroup = undefined;
  }

  private handleRoles(item: PropertyDto | AdditionalPropertyDto) {
    const roles: string[] = [];
    if (item.isEditableForInitiator) {
      roles.push(RoleType.Initiator);
    }
    else if (item.isEditableForAll) {
      roles.push(RoleType.All);
    }
    return roles;
  }

  protected onGroupPropertiesChange(groupProperty: GroupPropertyDto, allowSave: boolean = true) {
    if (allowSave) this.saveGroupProperty();

    this.lastGroupPropertyIndex = this.groupProperties.findIndex(a => a === groupProperty);
    this.groupPropertiesFormGroup = this.createFormGroup(groupProperty);
    this.fieldCodesHiddenData = studentFieldKeys
      .filter(item => !groupProperty.fieldCodes?.includes(item))
      .sort((a, b) => (studentFieldsMap.get(a) ?? '').localeCompare(studentFieldsMap.get(b) ?? ''))
      .map((item) => ({code: item}));

    this.fieldCodesVisibleData = groupProperty.fieldCodes?.map((item) => ({code: item, param: item})) ?? [];
    this.propertiesVisibleData = groupProperty.properties?.map(item => {
      const roles = this.handleRoles(item);
      return {
        ...item,
        dictProperty: this.dictProperties.find(a => a.externalId === item.dictPropertyId),
        roleIds: [...roles, ...item.roleIds]
      }
    }) ?? [];
    this.propertiesHiddenData = [...this.dictProperties];

    this.additionalProperties = groupProperty.additionalProperties.map(item => {
      const roles = this.handleRoles(item);
      return {
        ...item,
        roleIds: [...roles, ...item.roleIds]
      }
    });
  }

  protected openDialog(isTab?: boolean) {
    this.showDialog = true;
    this.isTabDialog = !!isTab;
  }

  protected cancelDialog() {
    this.showDialog = false;
  }

  protected cancelHandler() {
    this.tabDropdown?.reset();
    this.groupPropertiesFormGroup = undefined;
    this.formGroup = undefined;
    this.groupProperties = [];
    this.additionalProperties = [];
    this.lastTabIndex = undefined;
    this.lastGroupPropertyIndex = undefined;
  }

  protected confirmDialog(textbox: TextBoxComponent) {
    this.isTabDialog ? this.addTab(textbox) : this.addGroupProperty(textbox);
  }

  protected trimExtraSpaces(value: string|null) {
    return value?.trim().replace(/\s+/g, ' ') ?? '';
  }

  protected addTab(component: TextBoxComponent) {
    component.value = this.trimExtraSpaces(component.value);
    const isDuble = this.tabsSettings.filter(item => item.name === component.value).length > 0;
    if (isDuble || !component.value) {
      this.notificationsService.showError(isDuble
        ? 'Вкладка с таким названием уже существует'
        : 'Введите наименование');
      return;
    }
    this.tabsSettings.push({...new TabsSettingsDto(), name: component.value});
    this.cancelDialog();
    this.onTabChange(this.tabsSettings[this.tabsSettings.length - 1]);
    this.tabDropdown.value = this.tabsSettings[this.lastTabIndex ?? 0];
    this.fieldChange();
  }

  protected deleteTab({value}: DropDownListComponent) {
    const dialog: DialogRef = openDialog(this.dialogService, `Вы действительно хотите удалить вкладку "${value.name}" из формы заявки?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        // Одинаковые названия вкладок могут быть?
        this.tabsSettings = this.tabsSettings.filter(item => item.name !== value.name);
        this.cancelHandler();
        this.fieldChange();
      }
    });
  }

  protected addGroupProperty(component: TextBoxComponent) {
    component.value = this.trimExtraSpaces(component.value);
    const isDuble = this.groupProperties.filter(item => item.name === component.value).length > 0;
    if (isDuble || !component.value) {
      this.notificationsService.showError(isDuble
        ? 'Группа полей с таким названием уже существует'
        : 'Введите наименование');
      return;
    }
    this.groupProperties.push({...new GroupPropertyDto(), name: component.value})
    this.cancelDialog();
    this.onGroupPropertiesChange(this.groupProperties[this.groupProperties.length - 1]);
    if (this.groupFieldsDrodpdown) {
      this.groupFieldsDrodpdown.value = this.groupProperties[this.lastGroupPropertyIndex ?? 0];
    }
    this.fieldChange();
  }

  protected deleteGroupProperty({value}: DropDownListComponent) {
    const dialog: DialogRef = openDialog(this.dialogService, `Вы действительно хотите удалить группу поле "${value.name}" из формы заявки?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        // Одинаковые названия вкладок могут быть?
        this.groupProperties = this.groupProperties.filter(item => item.name !== value.name);
        this.groupPropertiesFormGroup = undefined;
        this.lastGroupPropertyIndex = undefined;
        this.groupFieldsDrodpdown?.reset();
        this.fieldChange();
      }
    });
  }

  protected addAdditionalProperty() {
    this.additionalProperties.push({...new AdditionalPropertyDto(), sortNumber: this.additionalProperties.length + 1});
    this.fieldChange();
  }

  protected deleteAdditionalProperty(index: number) {
    this.additionalProperties.splice(index, 1);
    this.fieldChange();
  }

  protected additionalPropertyMove(index: number, up: boolean = false) {
    const item = this.additionalProperties.splice(index, 1)[0];
    const rowIndex = up ? --index : ++index;
    this.additionalProperties.splice(rowIndex, 0, item);
    this.fieldChange();
  }

  protected onRoleChange(additionalProperty: AdditionalPropertyDto, values: string[]) {
    additionalProperty.roleIds = arrayRewrite(values);
    additionalProperty.isEditableForAll = additionalProperty.roleIds.includes(RoleType.All);
    additionalProperty.isEditableForInitiator = additionalProperty.roleIds.includes(RoleType.Initiator);
    this.fieldChange();
  }

  protected mapAdditionalProperties(additionalProperties: AdditionalPropertyDto[]) {
    return additionalProperties.map((item, propertyIndex) => {
      return {
        ...item,
        sortNumber: propertyIndex + 1,
        roleIds: item.roleIds.filter(a => !this.unusedRoleIds.includes(a))
      }
    });
  }

  protected saveTabChanges() {
    if (this.invalidTabs.length) {
      //TODO: в будущем можно конкретизировать сообщение
      this.notificationsService.showError('Заполните все обязательные поля');
      return;
    }

    this.saveTab();
    this.tabsSettingsService.saveTabSettings(this.constructorId, this.tabsSettings).subscribe(() => {
      this.notificationsService.showSuccess('Успешно');
      this.isChanged = false;
      this.getTabSettings();
    });
  }

  private addPropertiesAreInvalid() {
    let hasEmptyFields = false;
    this.additionalProperties.forEach((item) => {
      if (hasEmptyFields) return;

      hasEmptyFields = !item.additionalDictProperty.name
        || !item.additionalDictProperty.propertyTypeEnum
        || item.required === null
        || (!item.roleIds.length && !item.isEditableForAll && !item.isEditableForInitiator);
    });

    return hasEmptyFields;
  }

  private checkErrors(tabIsInvalid?: boolean, groupIsInvalid?: boolean) {
    const currentTab = this.formGroup?.get('name')?.value;
    const currentGroup = this.groupPropertiesFormGroup?.get('name')?.value;
    const tabError = this.invalidTabs.findIndex((item) =>
      item.tab === currentTab);

    const requireUpdateGroupsError = tabError >= 0 && (tabIsInvalid || groupIsInvalid);
    const requireRemoveTabError = tabError >= 0 && !(tabIsInvalid || groupIsInvalid);
    const requireAddTabError = tabError < 0 && (tabIsInvalid || groupIsInvalid);

    if (requireUpdateGroupsError) {
      const groupError = this.invalidTabs[tabError].groups.findIndex((item) => item === currentGroup);
      if (groupError < 0 && groupIsInvalid) {
        this.invalidTabs[tabError].groups.push(currentGroup);
      } else if (groupError >= 0 && !groupIsInvalid) {
        this.invalidTabs[tabError].groups.splice(groupError, 1);
      }
    } else if (requireRemoveTabError) {
      this.invalidTabs.splice(tabError, 1);
    } else if (requireAddTabError) {
      const error: {tab: string, groups: string[]} = {tab: currentTab, groups: []};
      if (groupIsInvalid) error.groups.push(currentGroup);

      this.invalidTabs.push(error);
    }
  }

  protected fieldChange(invalid?: boolean) {
    const groupIsInvalid = invalid
      || this.addPropertiesAreInvalid()
      || this.groupPropertiesFormGroup?.invalid;
    const tabIsInvalid = this.formGroup?.invalid;

    this.checkErrors(tabIsInvalid, groupIsInvalid);
    this.isChanged = true;
  }

  protected cancelChanges() {
    if (!this.isChanged) return;
    const dialog: DialogRef = openDialog(
      this.dialogService, ' Вы уверены, что хотите отменить изменения?');
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.isChanged = false;
        this.invalidTabs = [];
        this.getTabSettings();
      }
    });
  }

  ngOnDestroy() {
    this.checkChangesSubscription$.unsubscribe();
  }
}

<div class="buttons" *ngIf="editable">
  <button kendoButton (click)="cancelChanges()">Отмена</button>
  <button [disabled]="editMode" (click)="saveChanges()" themeColor="primary" kendoButton>Сохранить</button>
</div>

<div class="buttons add-button" *ngIf="editable">
  <button kendoButton themeColor="success" icon="plus" (click)="addRow()">Добавить</button>
</div>

<kendo-grid class="grid-data borderless" [formGroup]="formGroup"
            #grid
            (edit)="editRow($event)"
            (remove)="onRemove($event)"
            (cancel)="onCancel()"
            (save)="onSave($event)"
            [loading]="loading"
            [kendoGridBinding]="gridData">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoGridToolbarTemplate>
    <span *ngIf="editMode">
      <button (click)="onCancel()" kendoButton>Отмена</button>
      <button [disabled]="!formGroup.valid" kendoButton themeColor="primary"
              (click)="onSave({
                dataItem: formGroup.value,
                isNew: isNew,
                rowIndex: (lastRowIndex ?? -1),
                formGroup: formGroup,
                sender: grid
              })">
        Сохранить
      </button>
    </span>
  </ng-template>
  <kendo-grid-column [sticky]="true" [width]="170" title="" *ngIf="editable">
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
      <span class="action-buttons">
        <span class="reorder-buttons" *ngIf="gridData.length > 1">
          <span class="button_up"
               (click)="reorderRow(rowIndex, rowIndex - 1)">
          </span>
          <span class="button_down"
               (click)="reorderRow(rowIndex, rowIndex + 1)">
          </span>
        </span>
        <button kendoGridEditCommand
                themeColor="secondary"
                icon="edit"
                fillMode="flat"
                size="medium"
                rounded="full">
        </button>
        <button kendoGridRemoveCommand
                themeColor="secondary"
                icon="delete"
                fillMode="flat"
                size="medium"
                rounded="full">
        </button>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="250" title="Тип согласования" field="dictApprovalTypeId">
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-dropdownlist
        [data]="approvalTypes"
        [valuePrimitive]="true"
        (valueChange)="approvalTypeChange()"
        [formControlName]="column.field"
        valueField="id"
        textField="name">
      </kendo-dropdownlist>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{approvalTypesMap.get(dataItem[column.field])}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="200" title="Роль" field="dictRoleId">
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-combobox
        [data]="roles"
        [valuePrimitive]="true"
        [formControlName]="column.field"
        valueField="id"
        textField="name">
      </kendo-combobox>
  </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{rolesMap.get(dataItem[column.field])}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="200" title="Документ для согласования" field="applicationConstructorDocumentId">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{documentsMap.get(dataItem[column.field])}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-dropdownlist
        [data]="filteredDocuments"
        [valuePrimitive]="true"
        [formControlName]="column.field"
        valueField="externalId"
        textField="name">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="250" title="Категория подписантов" field="dictSignatoryRoleId">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{signatoryRolesMap.get(dataItem[column.field])}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-combobox
        [data]="signatoryRoles"
        [valuePrimitive]="true"
        [formControlName]="column.field"
        valueField="externalId"
        textField="name">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="250" title="Тип подразделения подписантов" field="departmentType">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{departmentTypesMap.get(dataItem[column.field])}}
    </ng-template>
    <ng-template kendoGridHeaderTemplate let-column='column'>
      <div class="tooltip-header">
        {{column.title}}
        <kendo-icon kendoTooltip
                    [tooltipWidth]="450"
                    [title]="departmentTypeTooltip"
                    name="info"
                    tooltipClass="mfc-tooltip"
                    position="top"
                    size="medium">
        </kendo-icon>
      </div>
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-combobox
              [data]="departmentTypes"
              [valuePrimitive]="true"
              [formControlName]="column.field"
              valueField="enum"
              textField="name">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="150" title="Порядок согласования" field="numberInApprovalSequence">
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-numerictextbox [formControlName]="column.field"
                            #numberInApprovalSequence
                            (blur)="onNumberInApprovalSequenceBlur(numberInApprovalSequence)"
                            [decimals]="0" [min]="1" format="n0">
      </kendo-numerictextbox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{dataItem[column.field]}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="200" title="Статус согласования" field="dictApprovalStatusId">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{approvalStatusesMap.get(dataItem[column.field])}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-dropdownlist
        [data]="approvalStatuses"
        [valuePrimitive]="true"
        [formControlName]="column.field"
        valueField="id"
        textField="name">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
          {{dataItem.length}} выбрано
        </ng-template>
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="180" title="Дополнительные фильтры">
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" *ngIf="!editMode">
      <button [disabled]="true" kendoButton (click)="filterSelect(rowIndex)">Перейти</button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="150" title="Ответственный за доработку" field="numberToUseForReWork">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-column="column">
      {{dataItem[column.field]}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-column="column">
      <kendo-combobox
              [data]="reworkNumbers"
              [valuePrimitive]="true"
              [formControlName]="column.field">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScienceBranch } from '../../../models/education/scienceBranch.model';
import { StandardType } from '../../../models/education/standardtype.model';
import { ScienceBranchService } from '../../../services/education/DictScienceBranch/scienceBranches.service';
import { StandardtypeService } from '../../../services/education/StandardType/standardtype.service';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { EducationUserAccessService } from '../../../services/useraccess/education-user-access.service';

@Component({
  selector: 'app-dict-science-branch',
  templateUrl: './dict-science-branch.component.html',
  styleUrls: ['./dict-science-branch.component.scss']
})
export class DictScienceBranchComponent implements OnInit {

  public gridStyle = 'grid-default';
  public isOpened: boolean = false;
  public loading: boolean = false;
  public editable?: boolean;
  public isNew: boolean = false;
  public scienceBranches!: ScienceBranch[];
  public standardTypes: StandardType[] = [];

  constructor(private scienceBranchService: ScienceBranchService,
    private notificationService: NotificationsService,
    private userAccessService:EducationUserAccessService,
    private standardTypeService: StandardtypeService) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getStandardTypes();
    this.getAllScienceBranches();
  }

  public editForm: FormGroup = new FormGroup ({
    scienceBranchExternalId: new FormControl(""),
    scienceBranchName: new FormControl(""),
    scienceBranchCipher: new FormControl(""),
    dictStandardTypeId: new FormControl(""),
  });

  public getAllScienceBranches() {
    this.loading = true;
    this.scienceBranchService.getAllScienceBranches()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.scienceBranches = response;
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить типы проведения занятий")
        }
      });
  }

  private getStandardTypes() {
    this.standardTypeService.getAllStandardTypes().subscribe((response) => {
      this.standardTypes = response;
    });
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = !response.dictAccessLevel;
    });
  }

  public saveScienceBranch() {
    // Add new ScienceBranch
    if (this.isNew) {
      this.scienceBranchService.addScienceBranch(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Отрасль науки успешно добавлена!");
              this.getAllScienceBranches();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить Отрасль науки");
              this.onCancel();
            }
          });
    }
    // Edit ScienceBranch
    else {
      this.scienceBranchService.updateScienceBranch(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Отрасль науки успешно сохранена!");
              this.getAllScienceBranches();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось изменить Отрасль науки');
              this.onCancel();
            }
          })
    }
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }
}

const formGroup = (dataItem: {
    dictStandardTypeId?: any;
    scienceBranchExternalId?: any;
    scienceBranchName?: any;
    scienceBranchCipher?: any; }) =>
    new FormGroup({
        dictStandardTypeId: new FormControl(dataItem.dictStandardTypeId, Validators.required),
        scienceBranchExternalId: new FormControl(dataItem.scienceBranchExternalId),
        scienceBranchName: new FormControl(dataItem.scienceBranchName, Validators.required),
        scienceBranchCipher: new FormControl(dataItem.scienceBranchCipher, Validators.required),
});

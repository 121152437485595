import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentInfoCards} from "../../models/cards/studentinfocards";
import {dictPagerSettings} from "../../models/dicts/pagerSettings.model";
import {PagerSettings} from "@progress/kendo-angular-grid";
import {CardsService} from "../../services/cards/cards.service";
import {Guid} from "guid-typescript";
import {StudentInfoCardsEnum} from "./studentinfocards/studentinfocardsenum";
import {Router} from "@angular/router";

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  public isFilterable: boolean = true;
  public pageSize: number = 10;
  public pagerSettings: PagerSettings = dictPagerSettings;

  public gridData: StudentInfoCards[] = [];
  public salaryProjects: string[] = [''];
  public reestrs: string[] = [''];

  public isContingentSection: boolean = false;
  @Input() studentId?: Guid;
  @Output() changeSection = new EventEmitter<StudentInfoCardsEnum>();
  @Output() reestrId = new EventEmitter<Guid>();

  constructor(
    private cardsService: CardsService,
    private router: Router,) { }

  ngOnInit(): void {
    this.isContingentSection = !!this.studentId;
    if (this.isContingentSection) {
      this.isFilterable = false;
      this.getCardRequest()
      return;
    }
    this.getCardsRequests();
  }

  public getCardsRequests() {
    this.cardsService.getCardsRequests()
      .subscribe(
        response => {
          this.gridData = response;

          this.salaryProjects = [... new Set(response.map(r => r.salaryProjectName))];
          this.reestrs = [... new Set(response.map(r => r.reestrNameStatus))];
        }
      )
  }

  public getCardRequest() {
    if (!this.studentId) return;

    this.cardsService.getCardRequest(this.studentId)
      .subscribe(
        response => {
          if (response)
            this.gridData = response;
        }
      )
  }

  public toAddRequest() {
    if (this.isContingentSection) {
      this.changeSection.emit(StudentInfoCardsEnum.AddToListForming);
      return;
    }
    this.router.navigate(['/cards/addtolistforming']);
  }

  public toReestr(reestrId: Guid){
    if (this.isContingentSection) {
      this.changeSection.emit(StudentInfoCardsEnum.Reestr);
      this.reestrId.emit(reestrId);
      return;
    }
    this.router.navigate(['/cards/reestr/', reestrId]);
  }

  protected readonly dictPagerSettings = dictPagerSettings;
}


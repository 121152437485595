export class ContingentOrganization {
  public dictOrganizationId!: number;
  public dictOrganizationExternalId?: string;
  public organizationName!: string;
  public ogrn!: string;
  public inn!: string;
  public kpp!: string;
  public order?: number;
  public isTarget!: boolean;
  public isWorkPlace!: boolean;
}

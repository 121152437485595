import {Injectable} from "@angular/core";
import {BehaviorSubject, catchError, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {StatusInfo} from "../../../models/publications/statuses.model";
import {handleError} from "../../../helpers/publications/errorHandle-helper";


@Injectable({
  providedIn: 'root'
})
export class StatusesService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.main}`;
  public statusData$ = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) {
  }

  public getStatusInfo(publicationId?: string): Observable<StatusInfo> {
    const path = publicationId ? `/${publicationId}` : '';
    return this.http.get<StatusInfo>(`${this.baseUrl}${path}/Status`);
  }

  public updateStatus(publicationId: string, statusId: number): Observable<{id: number, publicationId: string}> {
    const status = {
      id: statusId,
      publicationId: publicationId
    };
    return this.http.post<typeof status>(`${this.baseUrl}/${publicationId}/Status/${statusId}`, status)
      .pipe(catchError(handleError));
  }
}

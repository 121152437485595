export class DiplomsListModel {
    id!: string;
    serialNumber!: number;
    studentId!: string;
    studentFio!: string;
    educationGroupName!: string;
    admissionYear!: string;
    previousEducationIssueYear!: string;
    previousEducationDocTypeName!: string;
    diplomaTypeName!: string;
    diplomaIssueDate!: Date | string;
    diplomaRegisterNumber!: string;
    dictStatusName!: string;
    dictFillingDiplomaStatusName!: string;
    averageGrade!: number;
}

export class saveMassData {
    studentIds!: string[];
    paramName!: string;
    paramValue!: string | Date;
}
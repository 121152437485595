import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AddEvent, CellClickEvent, GridComponent } from "@progress/kendo-angular-grid";
import { DialogService } from '@progress/kendo-angular-dialog';
import { LocalSignatoryRole } from "../../../../models/contingent/localsignatory.model";
import { DictLocalSignatoryRoleService } from '../../../../services/contingent/dictLocalSignatoryRole.service';
import { NotificationsService } from "../../../../services/notifications/notifications.service";
import { ContingentUserAccessService } from 'src/app/services/useraccess/contingent-user-access.service';

@Component({
  selector: 'app-localsignatoryrole',
  templateUrl: './localsignatoryrole.component.html',
  styleUrls: ['./localsignatoryrole.component.scss']
})
export class DictLocalSignatoryRoleComponent implements OnInit {

  public dictLocalSignatoryRole: LocalSignatoryRole[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;
  public editable?: boolean;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  // private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined /*|| this.isNew;*/
  }

  constructor(
    private dictLocalSignatoryRoleService : DictLocalSignatoryRoleService,
    private renderer: Renderer2,
    private dialogService: DialogService,
    private notificationService: NotificationsService,
    private userAccessService: ContingentUserAccessService,
  ) {}

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getListLocalSignatoryRole();
  }

  public onStateChange(): void {
    this.getListLocalSignatoryRole();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.isLine = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex
  ): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      // //Adding new entry to dictionary
      // if (this.isNew && this.formGroup !== undefined) {
      //   this.languageLevelsService.addlanguagelevels(this.formGroup.value)
      //     .subscribe({
      //       next:() => {
      //         this.getListLocalSignatoryRole();
      //         this.notificationService.showSuccess("Добавлено");
      //       },
      //       error:() => {
      //         this.notificationService.showError('Не удалось добавить уровень владения');
      //       }}
      //     );
      // }
      //Update dictionary
      if (this.formGroup !== undefined) {
        this.dictLocalSignatoryRoleService.updateLocalSignatoryRole(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getListLocalSignatoryRole();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getListLocalSignatoryRole() {
    this.dictLocalSignatoryRoleService.getLocalSignatoryRoles()
      .subscribe(
        response => {
          this.dictLocalSignatoryRole = response;
        }
      );
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = response.dictAccessLevel == 2;
      }
    })
  }

  // //Deleting an entry from dictionary
  // public removeHandler({ dataItem }: RemoveEvent): void {
  //   const dialog: DialogRef = this.dialogService.open({
  //     title: "Пожалуйста подтвердите",
  //     content: "Вы действительно хотите удалить: "+ dataItem.levelName + "?",
  //     actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
  //     width: 450,
  //     height: 200,
  //     minWidth: 250,
  //   });
  //
  //   dialog.result.subscribe((result) => {
  //     if (result instanceof DialogCloseResult) {
  //     } else {
  //       if(result.text == "Да"){
  //         this.opened = false;
  //         this.languageLevelsService.deletelanguagelevels(dataItem.dictLanguageLevelExternalId)
  //           .subscribe({
  //             next:() => {
  //               this.getListLocalSignatoryRole();
  //               this.notificationService.showSuccess("Удалено");
  //             },
  //             error:(error) => {
  //               this.notificationService.showError(error.error);
  //             }}
  //           );
  //       }
  //       else this.opened = false;
  //     }
  //   });
  // }


  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { externalId?: string; name?: string; order?: number; }) =>
  new UntypedFormGroup({
    externalId: new UntypedFormControl(dataItem.externalId),
    name: new UntypedFormControl(dataItem.name,Validators.required),
    order: new UntypedFormControl(dataItem.order,Validators.required),
  });


import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Phones } from '../../models/contingent/phones.model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class PhonesService {


  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.phones}`;
  public phoneNumber$ = new BehaviorSubject<string | null>(null);

  constructor(private http: HttpClient) { }

  //Get all phones
  public getAllphones(): Observable<Phones[]> {
    return this.http.get<Phones[]>(this.baseUrl);
  }

  //Get By Id phones
  getByIdphones(id: string): Observable<Phones> {
    return this.http.get<Phones>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId
  getStudentPhones(id: string): Observable<Phones[]> {
    return this.http.get<Phones[]>(this.baseUrl + '/getphonesbystudpersonid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add phones
  addphones(phones: Phones, studentId: string): Observable<Phones> {
    return this.http.post<Phones>(this.baseUrl + "/" + studentId, phones)
      .pipe(catchError(this.handleError));
  }

  //Delete phones
  deletephones(id: string, studentId : Guid| undefined): Observable<Phones> {
    return this.http.delete<Phones>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit phones
  updatephones(phones: Phones, studentId: string): Observable<Phones> {
    return this.http.put<Phones>(this.baseUrl + '/' + phones.phoneExternalId + "/" + studentId, phones)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}


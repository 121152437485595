import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AssignAuditorium } from '../../models/classroom/assign-auditorium.model';

@Injectable({
    providedIn: 'root'
})

export class AssignAuditoriumService {
    baseUrl = `${environment.classroomApiEndpoint}${environment.apiPaths.classroom.dict}`;

    constructor(private http: HttpClient) { }

    public getAllAssigns(): Observable<AssignAuditorium[]> {
        return this.http.get<AssignAuditorium[]>(this.baseUrl + '/classroompurposes/all');
    }

    public getAllowedAssigns(){
        return this.http.get<AssignAuditorium[]>(this.baseUrl + '/classroompurposes');
    }

    public addAssignAuditorium(assign: any){
      //  let data = {name: assign.classRoomPurposeName, number: assign.classRoomPurposeNumber};
      console.log(assign.classRoomPurposeNumber);
        let data = {};
        if (assign.classRoomPurposeNumber == null || assign.classRoomPurposeNumber.length == 0){
           data = {name: assign.classRoomPurposeName};
        } else  data = {name: assign.classRoomPurposeName, number: assign.classRoomPurposeNumber};
        return this.http.post(this.baseUrl + '/classroompurpose', data)
        .pipe(catchError(this.handleError));
    }

    public updateAssignAuditorium(assign: any, id: string){
        let data = {};
        if ( assign.classRoomPurposeNumber !== ""){
            data = {name: assign.classRoomPurposeName, number: assign.classRoomPurposeNumber};;
        } else data = {name: assign.classRoomPurposeName};
      //  let data = {name: assign.classRoomPurposeName, number: assign.classRoomPurposeNumber};
        return this.http.put(this.baseUrl + '/classroompurpose/' + id, data)
        .pipe(catchError(this.handleError));
    }

    public deleteAssignAuditorium(id: string){
        return this.http.delete(this.baseUrl + '/classroompurpose/' + id)
        .pipe(catchError(this.handleError));
    }

    // Error
   /* handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
        // Handle client error
        errorMessage = error.error.message;
        } else {
        // Handle server error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
        errorMessage;
        });
    }*/

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DictTypeWorkDisciplineAdditional } from '../../../models/education/dictTypeWorkDisciplineAdditional.model';

@Injectable({
  providedIn: 'root'
})
export class DictTypeWorkDisciplineAdditionalService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictTypeWorkDisciplineAdditional}`;

  constructor(private http: HttpClient) { }

  //Get all
  public getAll(): Observable<DictTypeWorkDisciplineAdditional[]> {
    return this.http.get<DictTypeWorkDisciplineAdditional[]>(this.baseUrl);
  }

  //Add
  public add(body: any): Observable<DictTypeWorkDisciplineAdditional> {
    return this.http.post<DictTypeWorkDisciplineAdditional>(this.baseUrl, body);
  }

  //Update
  public update(body: any): Observable<DictTypeWorkDisciplineAdditional> {
    return this.http.put<DictTypeWorkDisciplineAdditional>(this.baseUrl + '/' + body.dictTypeWorkDisciplineAdditionalExternalId, body);
  }
}

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AddEvent, CellClickEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { Guid } from 'guid-typescript';
import { SessionUserAccessGuard } from 'src/app/guards/session-user-access.guard';
import { checkRole } from 'src/app/helpers/token/role-check';
import { DictMarkChangeReason } from 'src/app/models/middlecontrol/dictMarkChangeReason.model';
import { Role } from 'src/app/models/useraccess/role';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { SessionService } from 'src/app/services/middlecontrol/session.service';
import { TokenStorageService } from 'src/app/services/token.service';
import { MiddleControlUserAccessService } from 'src/app/services/useraccess/middle-control-user-access.service';

@Component({
  selector: 'app-dictmarkchangereason',
  templateUrl: './dictmarkchangereason.component.html',
  styleUrls: ['./dictmarkchangereason.component.css']
})
export class DictmarkchangereasonComponent implements OnInit {
  public emptyGuid: any = Guid.createEmpty();
  public changeReasons: DictMarkChangeReason[] = [];
  public changeReason: DictMarkChangeReason = {
    id: this.emptyGuid.value,
    name: '',
    isRetake: false,
    isError: false,
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  public editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public isAdmin: boolean = false;
  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private sessionService: SessionService,
              private notificationService: NotificationsService,
              private userAccessService: MiddleControlUserAccessService,
              private dialogService: DialogService,
              private renderer: Renderer2,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllChangeMarkReasons();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      id: this.emptyGuid.value,
      name: '',
      isRetake: false,
      isError: false
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew == true && this.formGroup !== undefined) {
        this.sessionService.addMarkChangeReason(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllChangeMarkReasons();
              this.notificationService.showSuccess("Добавлено");
              this.changeReason = {
                id: this.emptyGuid.value,
                name: '',
                isRetake: false,
                isError: false
              }
            },
            error => {
              this.notificationService.showError("Не удалось добавить запись");
            }
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.sessionService.updateMarkChangeReason(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllChangeMarkReasons();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }
  public constRetakeId = "286e7065-93a7-41ad-a2c9-9cf64039612a";
  //Getting all entries from dictionary
  getAllChangeMarkReasons() {
    this.sessionService.getMarkChangeReason()
      .subscribe(
        response => {
          this.changeReasons = response;
        },
        error => {
          this.notificationService.showError("Не удалось получить причины смены оценки");
        }
      );
  }

  public editable?: boolean;

  private getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(response => this.editable = response.localSignatoryAccessLevel || this.isAdmin);
  }

  //Deleting an entry from dictionary
  public removeHandler(dataItem: any): void {
      const dialog: DialogRef = this.dialogService.open({
        title: "Пожалуйста подтвердите",
        content: `Вы действительно хотите удалить: ${dataItem.name.toLowerCase()} ?`,
        actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
        width: 450,
        height: 200,
        minWidth: 250,
      });
      dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
        } else {
          if(result.text == "Да"){
            this.opened = false;
            this.sessionService.deleteMarkChangeReason(dataItem.id)
            .subscribe(
              ( response: any) => {
                this.getAllChangeMarkReasons();
                this.notificationService.showSuccess("Удалено");
              },
              error => {
              this.notificationService.showError(error.error);
            }
            );
              }
            else{
              this.opened = false;
            }
        }
      });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { id?: any; name?: any; isRetake?: boolean; isError?: boolean }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    name: new FormControl(dataItem.name, Validators.required),
    isRetake: new FormControl(dataItem.isRetake, Validators.required),
    isError: new FormControl(dataItem.isError, Validators.required),
  });

  const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

  const isChildOf = (el: any, className: any) => {
    while (el && el.parentElement) {
      if (hasClass(el.parentElement, className)) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  };

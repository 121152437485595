import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { Role } from '../models/useraccess/role';
import { TokenStorageService } from '../services/token.service';
import {Location} from "@angular/common";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AdminRoleGuard implements CanActivate {
  constructor(private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private location: Location,
    private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    const token = this.tokenStore.getAuthToken();
    if(token !== null)
    {
      const user = this.jwtHelper.decodeToken(token);
      const roles = user?.role;

      if (roles?.includes(Role.Admin)) {
        if (route.routeConfig?.path === 'switchuser') {
          return !!environment.has_switch_user;
        }

        return true;
      }

      alert('Недостаточно прав для доступа на эту страницу');
    }
    localStorage.setItem('last_url', this.location.path())
    this.router.navigate([`/profile`]);
    return false;
  }

}

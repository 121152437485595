import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject, catchError, lastValueFrom, Observable, of, throwError} from 'rxjs';
import { environment } from '../../../environments/environment';
import { SemesterYearsModel } from '../../models/currentcontrol/semesterYears.model';
import { StudyLevelsModel } from '../../models/currentcontrol/studyLevels.model';
import { TrainingLevelsModel } from '../../models/currentcontrol/trainingLevels.model';
import { PersonsModel } from '../../models/currentcontrol/persons.model';
import {
  DictAttestation,
  DictCriterialMark,
} from 'src/app/models/currentcontrol/dict.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import {KafedraModel} from "../../models/currentcontrol/kafedra.model";
import {FacultyModel} from "../../models/currentcontrol/faculty.model";
import {StudyFormModel} from "../../models/currentcontrol/studyform.model";
import { DictMarkCriteriaLevel } from 'src/app/models/currentcontrol/dictMarkCriteriaLevel.model';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})
export class DictsService {
  baseUrl = `${environment.apiEndpoint}`;

  public semesterYears$ = new BehaviorSubject<SemesterYearsModel[]>([]);
  public studyLevels$ = new BehaviorSubject<StudyLevelsModel[]>([]);
  public studyForms$ = new BehaviorSubject<StudyFormModel[]>([]);
  public trainingLevels$ = new BehaviorSubject<TrainingLevelsModel[]>([]);
  public persons$ = new BehaviorSubject<PersonsModel[]>([]);
  public faculty$ = new BehaviorSubject<FacultyModel[]>([]);
  public kafedra$ = new BehaviorSubject<KafedraModel[]>([]);
  public dictAttestations$ = new BehaviorSubject<DictAttestation[]>([]);
  public dictCrierialMarks$ = new BehaviorSubject<DictCriterialMark[]>([]);

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService
  ) {}

  public getSemesterYears(): Observable<SemesterYearsModel[]> {
    return this.http
      .get<SemesterYearsModel[]>(
        this.baseUrl + environment.apiPaths.currentcontrol.semesteryears
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось получить список семестров');
          return of([]);
        })
      );
  }

  public async updateDictSemesterYears() {
    await lastValueFrom(this.getSemesterYears()).then((response) => {
      this.semesterYears$.next(response);
    });
  }

  public getStudyLevels(): Observable<StudyLevelsModel[]> {
    return this.http
      .get<StudyLevelsModel[]>(
        this.baseUrl + environment.apiPaths.currentcontrol.studylevels
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список уровней образования'
          );
          return of([]);
        })
      );
  }

  public updateStudyLevels() {
    this.getStudyLevels().subscribe((response) => {
      this.studyLevels$.next(response);
    });
  }

  public getTrainingLevels(): Observable<TrainingLevelsModel[]> {
    return this.http
      .get<TrainingLevelsModel[]>(
        this.baseUrl + environment.apiPaths.currentcontrol.traininglevels
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список уровней подготовки'
          );
          return of([]);
        })
      );
  }

  public async updateTrainingLevels() {
    await lastValueFrom(this.getTrainingLevels()).then((response) => {
      this.trainingLevels$.next(response);
    });
  }

  public getFaculty(): Observable<FacultyModel[]> {
    return this.http.get<FacultyModel[]>(
      this.baseUrl + environment.apiPaths.currentcontrol.faculty
    );
  }

  public updateFAculty() {
    this.getFaculty().subscribe((response) => {
      this.faculty$.next(response);
    });
  }

  public getKafedra(): Observable<KafedraModel[]> {
    return this.http.get<KafedraModel[]>(
      this.baseUrl + environment.apiPaths.currentcontrol.kafedra
    );
  }

  public updateKafedra() {
    this.getKafedra().subscribe((response) => {
      this.kafedra$.next(response);
    });
  }

  public getStudyForms(): Observable<StudyFormModel[]> {
    return this.http.get<StudyFormModel[]>(
      this.baseUrl + environment.apiPaths.currentcontrol.studyforms
    );
  }

  public updateStudyForms() {
    this.getStudyForms().subscribe((response) => {
      this.studyForms$.next(response);
    });
  }

  public getPersons(filterValue: string): Observable<PersonsModel[]> {
    return this.http
      .get<PersonsModel[]>(
        this.baseUrl +
          environment.apiPaths.currentcontrol.persons +
          `?searchString=${filterValue}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список сотрудников'
          );
          return of([]);
        })
      );
  }

  public updatePersons(filterValue: string) {
    this.getPersons(filterValue).subscribe((response) => {
      this.persons$.next(response);
    });
  }

  public getAllPostsPerson(personId: string) {
    return this.http
      .get<string[]>(
        this.baseUrl +
          environment.apiPaths.currentcontrol.personPosts +
          `/${personId}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить должность сотрудника'
          );
          return of([]);
        })
      );
  }

  public getAttestations() {
    this.http
      .get<DictAttestation[]>(
        this.baseUrl + environment.apiPaths.currentcontrol.attestations
      )
      .subscribe((response) => this.dictAttestations$.next(response));
  }

  public getCriterialMarks() {
    this.http
      .get<DictCriterialMark[]>(
        this.baseUrl + environment.apiPaths.currentcontrol.criterialmark
      )
      .subscribe((response) => this.dictCrierialMarks$.next(response));
  }

  //Add DictMarkCriterialLevel
  public addMarkCriteriaLevel(dto: DictMarkCriteriaLevel): Observable<DictMarkCriteriaLevel> {
    return this.http.post<DictMarkCriteriaLevel>(this.baseUrl + environment.apiPaths.currentcontrol.criterialmark, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete DictMarkCriteriaLevel
  public deleteMarkCriteriaLevel(id: Guid): Observable<DictMarkCriteriaLevel> {
    return this.http.delete<DictMarkCriteriaLevel>(this.baseUrl + environment.apiPaths.currentcontrol.criterialmark + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit DictMarkCriteriaLevel
  public updateDictMarkCriteriaLevel(dto: DictMarkCriteriaLevel): Observable<DictMarkCriteriaLevel> {
    return this.http.put<DictMarkCriteriaLevel>(this.baseUrl + environment.apiPaths.currentcontrol.criterialmark + '/' + dto.id, dto)
      .pipe(catchError(this.handleError));
  }

  public getAllDictMarkCriteriaLevels(): Observable<DictMarkCriteriaLevel[]> {
    return this.http.get<DictMarkCriteriaLevel[]>(this.baseUrl + environment.apiPaths.currentcontrol.criterialmark)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DictLanguages } from '../../models/contingent/dictlanguages.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DictLanguagesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictlanguages}`;

  constructor(private http: HttpClient) { }

  //Get all Languages
  public getAllLanguages(): Observable<DictLanguages[]> {
    return this.http.get<DictLanguages[]>(this.baseUrl);
  }

  //Add Languages
  addLanguages(languages: DictLanguages): Observable<DictLanguages> {
    return this.http.post<DictLanguages>(this.baseUrl, languages)
      .pipe(catchError(this.handleError));
  }

  //Delete Languages
  deleteLanguages(id: string): Observable<DictLanguages> {
    return this.http.delete<DictLanguages>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit Languages
  updateLanguages(languages: DictLanguages): Observable<DictLanguages> {
    return this.http.put<DictLanguages>(this.baseUrl + '/' + languages.dictLanguageExternalId, languages)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

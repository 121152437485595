<dicts-header></dicts-header>
<h1>Категория подписантов</h1>
<div class="grid-container">
  <kendo-grid [data]="gridView"
              (remove)="removeHandler($event)"
              (cancel)="cancelHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              (save)="saveHandler($event)"
              [loading]="loading"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand
              themeColor="success"
              icon="plus"
              *ngIf="!isInEditingMode"
      >Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="btnCancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>
    <!--kendo-grid-column title="№ п/п"
                       headerClass="gridHeader"
                       [width]="70"
    >
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        <span class="alignCells">
          {{rowIndex + 1}}
        </span>
      </ng-template>
    </kendo-grid-column-->
    <kendo-grid-column field="name" title="Роли подписантов"
                       headerClass="gridHeader dictionaries"
                       [width]="250"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.name}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="serialNumber" title="Порядковый номер для отображения"
                       headerClass="gridHeader dictionaries"
                       [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.serialNumber}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-numerictextbox [formControl]="formGroup.get('serialNumber')"
                              format="n"
                              [decimals]="0"
                              [min]="1"
        ></kendo-numerictextbox>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

<education-header></education-header>
<title>Тип цикла</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Тип цикла</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новый тип цикла' : 'Изменить тип цикла' }}
  </h1>

  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" (click)="addHandler()" class="addButton" kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid #grid
                [data]="cycleTypes"
                [kendoGridBinding]="cycleTypes"
                [navigable]="true"
                [sortable]="true"
                [filterable]="true"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCycleTypeName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" style="text-align: left; justify-content: left;">
            {{dataItem.dictCycleTypeName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCycleTypeSerialNumber" title="Порядковый номер">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictCycleTypeSerialNumber}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="enum" title="Слукебная категория">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{ nameEnum(dataItem.enum) }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column *ngIf="editable" headerClass="gridHeader dictionaries" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton type="button" (click)="editHandler(dataItem)" class="editButton">Изменить</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictCycleTypeName" #dictCycleTypeName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Порядковый номер</h2>
          <kendo-numerictextbox formControlName="dictCycleTypeSerialNumber" format="n0" [min]="1" [decimals]="0" #dictCycleTypeSerialNumber></kendo-numerictextbox>
        </div>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Служебная категория</h2>
          <kendo-dropdownlist formControlName="enum"
                              [data]="cycleTypeEnums"
                              textField="name"
                              valueField="value"
                              [valuePrimitive]="true"
          >
          </kendo-dropdownlist>
        </div>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton (click)="onCancel()" class="cancelButton">Отменить</button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="saveCycleType()">Сохранить</button>
      </div>
    </form>
  </div>
</div>

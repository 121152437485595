import {CompetenceTypesEnum} from '../../models/education/enums/competence-types.enum';

export const competenceTypesOrder = [
  CompetenceTypesEnum.Cultural,
  CompetenceTypesEnum.Universal,
  CompetenceTypesEnum.General,
  CompetenceTypesEnum.Professional,
  CompetenceTypesEnum.Special,
  CompetenceTypesEnum.Additional
];

export function typeCompetenceSort<T extends object, K extends keyof {[P in keyof T as T[P] extends CompetenceTypesEnum ? P : never]: unknown}>(list: T[], field: keyof T) {
  return list.sort((a, b) => {
    const indexA = competenceTypesOrder.indexOf(a[field as K] as unknown as CompetenceTypesEnum);
    const indexB = competenceTypesOrder.indexOf(b[field as K] as unknown as CompetenceTypesEnum);
    return indexA < 0 ? 1 : indexB < 0 ? -1 : indexA - indexB;
  });
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, UntypedFormControl} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import { CellClickEvent, GridDataResult, RowClassArgs, SelectableMode, SelectableSettings } from '@progress/kendo-angular-grid';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { orderBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { Faculty } from 'src/app/models/education/faculty.model';
import { Kafedra } from 'src/app/models/education/kafedra.model';
import { OOProgram } from 'src/app/models/education/oop.model';
import { OOPStatus } from 'src/app/models/education/oopStatus.model';
import { Qualification } from 'src/app/models/education/qualification.model';
import { Filial } from 'src/app/models/education/filial.model';
import { Standard } from 'src/app/models/education/standard.model';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { StudyLevel } from 'src/app/models/education/studylevel.model';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { FacultyService } from 'src/app/services/education/Faculty/faculty.service';
import { KafedraService } from 'src/app/services/education/Kafedra/kafedra.service';
import { OopService } from 'src/app/services/education/OOP/oop.service';
import { QualificationService } from 'src/app/services/education/Qualification/qualification.service';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {EducationUserAccessService} from "../../../services/useraccess/education-user-access.service";
import {StandardService} from "../../../services/education/Standard/standard.service";
import {FilialService} from "../../../services/education/Filial/filial.service";
import {
    EducationProgramCompetenciesService
} from "../../../services/education/OOP/Competencies/education-program-competencies.service";
import {DictOrganizationService} from "../../../services/education/DictOrganization/dictOrganization.service";
import {DictOrganization} from "../../../models/education/dictOrganization.model";

@Component({
  selector: 'oop',
  templateUrl: './oop.component.html',
  styleUrls: ['./oop.component.scss']
})
export class OopComponent implements OnInit {

    @Input() isCompetence: boolean = false;
    @Output() onCopyComptence: EventEmitter<boolean> = new EventEmitter<boolean>();

    private destinationEducationProgramId = "";
    public copyPopup: boolean = false;

    /* Выделить строку при выборе компетенции */
    public selectableSettings!: SelectableSettings;

    public checkboxOnly = false;
    public mode: SelectableMode = "multiple";
    public drag = false;

    public setSelectableSettings(): void {
        if (this.checkboxOnly || this.mode === "single") {
            this.drag = false;
        }

        this.selectableSettings = {
            checkboxOnly: this.checkboxOnly,
            mode: this.mode,
            drag: this.drag,
        };
    }

    public gridStyle: string = 'grid-default';
    public gridView!: GridDataResult;
    public editable?: boolean;
    public StateEnum = OOPStatus;
    public selectedOOPId = "";
    public status: any[] = [
        {id: 1, oopStatus: 1, name: "Действует"},
        {id: 2, oopStatus: 2, name: "Закрыт"}
    ];
    public dialogOpened: boolean = false;
    public currentStatus: any;

    public type: PagerType = 'numeric';
    public buttonCount: number = 5;
    public info: boolean = true;
    public pageSizes = [20, 50, 100];
    public previousNext: boolean = true;
    public position: PagerPosition = 'bottom';
    public isOpened: boolean = false;

    public isNew: boolean = false;
    public loading: boolean = false;

    public studylevels!: StudyLevel[];
    public trainingLevels!: any[];
    public faculties!: Faculty[];
    public kafedras!: Kafedra[];
    public qualifications!: Qualification[];
    public standardTypes!: StandardType [];
    public filials: Filial[] = [];
    public standards: Standard[] = [];
    public ooprograms: OOProgram[] = [];
    public organizations: DictOrganization[] = [];
    public organization: DictOrganization = {
      dictOrganizationId: 0,
      organizationName: '',
      ogrn: '',
      inn: '',
      kpp: '',
      isTarget: false,
    }
    // public industrialPartners: DictIndustrialPartner[] = [];
    public educationProgramStatus: Array<{ text: string; value: number }> = [
        {text: "Действует", value: 1},
        {text: "Закрыт", value: 2},
    ];

    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10,
    };

    // public defaultItem: { name: any; dictIndustrialPartnerId: any } = {
    //     name: null,
    //     dictIndustrialPartnerId: null,
    // };

    // Сортировка
    public sort: SortDescriptor[] = [
        {
            field: "standard.trainingProgram",
            dir: "asc",
        },
    ];

    public formGroup: FormGroup | undefined;

    constructor(private oopService: OopService,
                private facultyService: FacultyService,
                private kafedraService: KafedraService,
                private qualificationService: QualificationService,
                private sanitizer: DomSanitizer,
                private notificationService: NotificationsService,
                private router: Router,
                private route: ActivatedRoute,
                // private dictIndustrialPartnerService: DictIndustrialPartnerService,
                private organizationService: DictOrganizationService,
                private standardService: StandardService,
                private standardTypeService: StandardtypeService,
                private filialService: FilialService,
                private trainingLevelService: TrainingLevelService,
                private competenceService: EducationProgramCompetenciesService,
                private userAccessService: EducationUserAccessService) {
        this.setSelectableSettings();
    }

    ngOnInit(): void {
        this.getAccessLevel();
        this.getAllOOP();
        this.getAllStandard();
        this.getAllFilials();
        this.getAllKafedras();
        this.getAllFaculties();
        // this.getIndustrialPartners();
        this.getOrganization();
        this.getAllQualifications();
        this.getStandardTypes();
        this.getAllTrainingLevels();
    }

    public editForm: FormGroup = new FormGroup({
        oopId: new FormControl(),
        externalId: new FormControl(),
        educationStandardId: new FormControl(),
        filial: new FormControl(),
        filialId: new FormControl(),
        oopCipher: new FormControl(),
        oopName: new FormControl(),
        oopShortName: new FormControl(),
        kafedraId: new FormControl(),
        kafedra: new FormControl(),
        facultyId: new FormControl(),
        faculty: new FormControl(),
        profilingCourseNumber: new FormControl(),
        qualification: new FormControl(),
        qualificationExternalId: new FormControl(),
        // dictIndustrialPartnerId: new FormControl(),
        // dictIndustrialPartner: new FormControl(),
        dictOrganizationId:  new FormControl(),
        dictOrganizations:  new FormControl(),
        oopStatus: new FormControl(),
        trainingProgram: new FormControl(),
        fullTrainingProgram: new FormControl()
    });

    public onStateChange(state: State): void {
        this.gridState = state;
        this.getAllOOP();
    }

    public openCompetence(dataItem: any) {
        const educationProgramId = dataItem.externalId;
        const url = this.router.serializeUrl(
            this.router.createUrlTree(['education/oop/competence/' + educationProgramId])
        )
        window.open(url, '_blank')
    }

    //Getting all entries from OOP
    public getAllOOP() {
        if (this.isCompetence) {
            this.destinationEducationProgramId = this.route.snapshot.params["id"];
        }
        this.loading = true;
        this.oopService.getAllOOP()
            .subscribe({
                next: (response) => {
                    this.ooprograms = response;
                    this.loading = false;
                },
                error: (error) => {
                    console.log(error.error);
                    this.notificationService.showError("Отсутствует подключение к серверу");
                    this.loading = false;
                }
            })
    }

    // Getting all entries from Faculty
    public getAllFaculties() {
        this.facultyService.getAllFaculties()
            .subscribe(
                response => {
                    this.faculties = response;
                }
            );
    }

    // Getting all entries from Kafedra
    public getAllKafedras() {
        this.kafedraService.getAllKafedras()
            .subscribe(
                response => {
                    this.kafedras = response;
                }
            )
    }

    //Getting all entries from dictionary
    public getOrganization(): void {
      this.organizationService.getAllorganization()
        .subscribe(
          response => {
            this.organizations = response.filter(org=>org.isTarget === true);
          }
        );
    }

    // Getting all entries from Qualifications
    public getAllQualifications() {
        this.qualificationService.getAllQualifications()
            .subscribe(
                response => {
                    this.qualifications = response;
                }
            )
    }

    public getStandardTypes() {
        this.standardTypeService.getAllStandardTypes()
            .subscribe(
                response => {
                    this.standardTypes = response;
                }
            )
    }

    //Getting all entries from Standard
    public getAllStandard() {
        this.standardService.getAllStandards()
            .subscribe(
                response => {
                    this.standards = response;
                }
            );
    }

    //Getting all entries from filial
    public getAllFilials() {
        this.filialService.getAllFilials()
            .subscribe(
                response => {
                    this.filials = response;
                }
            );
    }

    //Getting all entries from training levels
    public getAllTrainingLevels() {
        this.trainingLevelService.getAllTrainingLevels()
            .subscribe(
                response => {
                    this.trainingLevels = response;
                    // Для фильтра
                    this.trainingLevels.forEach((item) => {
                        item.dictTrainingLevelId = item.trainingLevelExternalId;
                    });
                }
            );
    }

    public saveOOP() {
        // Add new oop
        if (this.isNew) {
            if (this.editForm.value.oopShortName == null) {
                this.editForm.value.oopShortName = " "
            }
            this.oopService.addOOP(this.editForm?.value)
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess("Образовательная программа была успешно добавлена!");
                        this.getAllOOP();
                        this.onCancel();
                    },
                    error: () => {
                        this.notificationService.showError("Не удалось добавить образовательную программу");
                        this.onCancel();
                    }
                });
        }
        // Edit oop
        else {
            if (this.editForm.value.oopShortName == null) {
                this.editForm.value.oopShortName = " "
            }
            this.oopService.updateOOP(this.editForm?.value)
                .subscribe({
                    next: () => {
                        this.notificationService.showSuccess("Образовательная программа была успешно сохранена!");
                        this.getAllOOP();
                        this.onCancel();
                    },
                    error: () => {
                        this.notificationService.showError("Не удалось изменить образовательную программу");
                        this.onCancel();
                    }
                })
        }
    }


    public addHandler(): void {
        this.isNew = true;
        this.isOpened = true;
        this.gridStyle = 'grid-hidden';
    }

    public editHandler(dataItem: any): void {
        this.currentStatus = dataItem.oopStatus;
        this.editForm = formGroup(dataItem);
        this.isNew = false;
        this.gridStyle = 'grid-hidden';
        this.isOpened = true;
    }

    public onCancel(): void {
        this.editForm.reset();
        this.gridStyle = 'grid-default';
        this.isOpened = false;
        this.dialogOpened = false;
    }

    private loadProducts(): void {
        this.gridView = {
            data: orderBy(this.ooprograms, this.sort),
            total: this.ooprograms.length,
        };
    }

    public getAccessLevel() {
        this.userAccessService.getAccessLevel().subscribe({
            next: (response) => {
                this.editable = !response.dictAccessLevel;
            },
            error: () => {
            }
        });
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadProducts();
    }

    public isHyperLink(originalEvent: any): boolean {
        return originalEvent.target.querySelectorAll('a').length > 0;
    }

    public getSelectedValues({dataItem, originalEvent}: CellClickEvent): void {
        this.selectedOOPId = dataItem.externalId;
        if(this.isHyperLink(originalEvent)) return;
        if (this.isCompetence) this.copyPopup = true;
    }

    //Change cell color of status depending on value
    public rowClassCallback = (context: RowClassArgs) => {
        switch (context.dataItem.oopStatus) {
            case 1:
                return {green: true};
            case 2:
                return {red: true};
            default:
                return {};
        }
    }

    //Open dialog confirmation if status value has changed
    public openDialog(e: PointerEvent): void {
        if (this.editForm.value.oopStatus !== this.currentStatus && !this.isNew) {
            this.dialogOpened = true;
        } else {
            this.saveOOP();
        }
    }

    public closeDialog(): void {
        this.dialogOpened = false;
        this.copyPopup = false;
    }

    public copySelectedCompetence() {
        this.competenceService.copyCompetence(this.editForm.value, this.destinationEducationProgramId, this.selectedOOPId)
            .subscribe({
                next: () => {
                    this.notificationService.showSuccess("Копирование компетенций завершено");
                    this.copyPopup = false;
                    this.onCopyComptence.emit(true);
                },
                error: (error) => {
                    console.log(error.error);
                    this.notificationService.showError("Отсутствует подключение к серверу");
                    this.loading = false;
                }
            })
    }
}

const formGroup =
    (dataItem: {
      oopId?: any;
      externalId?: any,
      educationStandardId?: any;
      filialId?: any;
      oopCipher?: any;
      oopName?: any;
      oopShortName?: any;
      kafedraId?: any,
      facultyId?: any;
      profilingCourseNumber?: any;
      qualificationExternalId?: any;
      dictOrganizationId?: any;
      oopStatus?: any;
      trainingProgram?: any;
      fullTrainingProgram?: any;
    }) =>
        new FormGroup({
          oopId: new FormControl(dataItem.oopId),
          externalId: new FormControl(dataItem.externalId),
          educationStandardId: new FormControl(dataItem.educationStandardId),
          filialId: new FormControl(dataItem.filialId),
          oopCipher: new FormControl(dataItem.oopCipher),
          oopName: new FormControl(dataItem.oopName),
          oopShortName: new FormControl(dataItem.oopShortName),
          kafedraId: new FormControl(dataItem.kafedraId),
          facultyId: new FormControl(dataItem.facultyId),
          profilingCourseNumber: new FormControl(dataItem.profilingCourseNumber),
          qualificationExternalId: new FormControl(dataItem.qualificationExternalId),
          dictOrganizationId: new FormControl(dataItem.dictOrganizationId),
          oopStatus: new FormControl(dataItem.oopStatus),
          trainingProgram: new FormControl(dataItem.trainingProgram),
          fullTrainingProgram: new FormControl(dataItem.fullTrainingProgram)
        });

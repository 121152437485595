// Отображение списка в таблицу
export function GridDisplayList(dataItem: any, key: any) {
    let string = "";
    if(dataItem.length === 0) {
        return 'Все';
    }
    for(let i=0; i < dataItem.length; i++) {
        string += dataItem[i][key] + ', ';
    }
    return string.slice(0, -2);
}

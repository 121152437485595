import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError, Subject } from 'rxjs';
import { Distribuitions } from '../../models/announcement/distribuitions.model';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

  export class DistribuitionsService {
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);


  constructor(private http: HttpClient) { }

  public getDistribuitions() {
    return this.http.get<Distribuitions[]>(this.baseUrl + '/distributions' )
      .pipe(
        catchError(this.handleError));
  }

  public getDistribuitionId(id: number){
    return this.http.get(this.baseUrl + '/distributions/' + id)
  }
  
  public deleteList(Listid: number){
      return this.http.delete(this.baseUrl + '/distributions/' + Listid,  {responseType: 'text' as 'text'});
  }

  observer = new Subject();
  public subscriber$ = this.observer.asObservable();
  public getNewList(newList: boolean){
    this.observer.next(newList);
  }
  
// Error
handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
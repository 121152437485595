import {Component, OnInit, ViewChild} from '@angular/core';
import {PagerType} from '@progress/kendo-angular-pager';
import {PagerPosition, GridComponent, CellClickEvent, GridModule} from '@progress/kendo-angular-grid';
import {RowClassArgs} from "@progress/kendo-angular-grid";
import {Router} from "@angular/router";
import {ActivatedRoute} from "@angular/router";
import {ProtocolGiaService} from '../../../../services/gia/protocol-gia.service';
import {StructTabStudentModel, protocolTabs} from '../../../../models/gia/giaprotocol.model';
import {ConstructorGiaProtocolService} from '../../../../services/gia/constructorgiaprotocol.service';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DownloadFile} from "../../../../helpers/downloadFile-helper";

@Component({
    selector : 'app-giaprotocoltable',
    templateUrl: './giaprotocoltable.component.html',
    styleUrls : ['./giaprotocoltable.component.scss']
})

export class GiaProtocolTableComponent implements OnInit {

    public standardName: string = "";
    public groupId: string = "";

    public protocols: any = [];

    public loading: boolean = false;
    public pageSizes = [10, 20, 50];
    public pageSize = 20;
    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public previousNext = true;
    public position: PagerPosition = 'bottom';
    public info = true;
    public skip = 0;

    public tabsList: StructTabStudentModel[] = [];
    public educationStandardId: string = "";
    public graduateYear: string = "";
    public studyFormId: string = "";
    public facultyId: string = "";
    public trainingLevelId: string = "";
    private filial = undefined;

    @ViewChild(GridComponent) private grid!: GridComponent;

    private tabsId: string[] = [];

    constructor(
      private router: Router,
      private activateRoute: ActivatedRoute,
      private protocolGiaService: ProtocolGiaService,
      private constructorGiaService: ConstructorGiaProtocolService,
      private notificationService: NotificationsService,
    ) {
        this.activateRoute.params.subscribe(params => {
          this.groupId = params["id"];
          const storage = localStorage.getItem('diplomas_settings');
          if (storage !== null) {
            this.standardName = JSON.parse(storage).changeGroup;
            this.facultyId = JSON.parse(storage).faculty;
            this.studyFormId = JSON.parse(storage).studyForm;
            this.educationStandardId = JSON.parse(storage).standard;
            this.graduateYear = JSON.parse(storage).yearAdmission;
            this.trainingLevelId = JSON.parse(storage).trainingLevel;
            this.getTabsList();
            this.getProtocols();
          }
        })
    }

    ngOnInit() {
      const data = localStorage.getItem('protocol_paging');
      if (data && JSON.parse(data).viewProtocol) {
        this.skip = JSON.parse(data).skip;
        this.pageSize = JSON.parse(data).pageSize;
      }   
    }

    public cellClickHandler({
        sender,
        rowIndex,
        dataItem,
        columnIndex,
        }: CellClickEvent): void {
          localStorage.setItem('protocol_paging', JSON.stringify({skip: sender.skip, pageSize: sender.pageSize, viewProtocol: true}));
          this.router.navigateByUrl(`/gia/giaprotocol/${this.groupId}/student/${dataItem.studentId}`).then();
    }

    public getTabsList() {
      this.constructorGiaService.getTabs(this.trainingLevelId, this.filial, this.facultyId)
        .subscribe(response => {
          const tabs: StructTabStudentModel[] = response;
          this.tabsList = tabs.filter(el => !el.isHidden);
          this.tabsId = this.tabsList.map(el => el.id);
          this.tabsList.forEach(el => {
            let pos = 0;
            let foundPos = 0;
            let id = "_";
            while (foundPos !== -1) {
              foundPos = el.id.indexOf('-', pos);
              id = id + el.id.slice(pos, foundPos) + '_';
              pos = foundPos + 1;
            }
            el.id = id;
          })
        })
    }

    public getProtocols() {
      this.protocolGiaService.getStudentsProtocol(this.educationStandardId, this.graduateYear, this.studyFormId, this.facultyId, this.trainingLevelId)
        .subscribe(response => {
            if (response) this.protocols = response;

            this.protocols.forEach((element: any) => {
              let savedProtocol = element.protocolTabs.filter((item: protocolTabs) => item.protocolTabId && this.tabsId.includes(item.displayTabId));
              if (this.tabsList.length !== 0 && savedProtocol.length == this.tabsList.length) element.green = true;
            });

            this.protocols.forEach((el: any) => {
              el.protocolTabs.forEach((item: protocolTabs) => {
                  let pos = 0;
                  let foundPos = 0;
                  let id = "_";
                  while (foundPos !== -1) {
                    foundPos = item.displayTabId.indexOf('-', pos);
                    id = id + item.displayTabId.slice(pos, foundPos) + '_';
                    pos = foundPos + 1;
                  }
                  item.displayTabId = id;
                  el[id] = item.protocolTabName;
                })
            })
        })
    }

    public showButton(studentId: string, field: string) {
        const index = this.protocols.findIndex((el: any) => el.studentId == studentId);
        return this.protocols[index].protocolTabs.find((el: protocolTabs) => el.displayTabId == field)?.protocolTabId;
    }

    public rowCallback(context: RowClassArgs) {
      return context.dataItem.green ? {green: true} : {green: false};
    }

    public downloadProtocol(studentId: string, fieldId: string) {
      const index = this.protocols.findIndex((el: any) => el.studentId == studentId);
      const protocolId = this.protocols[index].protocolTabs.find((el: protocolTabs) => el.displayTabId == fieldId)?.protocolTabId;
      if (protocolId) {
        this.protocolGiaService.printProtocol(protocolId).subscribe({
            next: (value) => {
              DownloadFile(value);
            },
            error: () => {
              this.notificationService.showError('Не удалось скачать файл');
            },
          });
      }     
    }
}

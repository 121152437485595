import { Guid } from "guid-typescript";
import { EducationDocTypes } from "./educationdoctypes.model";
import { StudPerson } from "./studperson.model";

export class EducationDocs{
  public educationDocId?: number;
  public educationDocExternalId?: Guid;
  public studPersonId!: Guid;
  public studPersons!:StudPerson;
  public dictEducationDocTypeId!: Guid;
  public dictInstitutionTypeId!: Guid;
  public dictStudyLevelId!: Guid;
  public dictEducationDocType!: EducationDocTypes;
  public serial?: string;
  public number!: string;
  public issueDate!: Date;
  public issueOrganization!: string;
  public comment?: string;
  public graduateYear?: number|string;
  public graduateSpecial?: string;
  public graduatorName?: string;
  public dissertationYear?: number|string;
  public kandidateDisserDate?: Date;
  public approveVAKDate?: Date;
  public issueCountry?: string;
  public organizationNumber?: string;
  public educationDocLanguage?: EducationDocLanguages[];
  public graduateThis!: boolean;
  public facultyId!: string;
}

export class EducationDocCont{
  public educationDocTypeName!: string;
  public serial?:string;
  public number!:string;
  public issueDate!:Date;
  public issueDateFormated?:string;
  public issueOrganization?:string;
  public graduateYear?:number|string;
  public graduateSpecial?:string;
  public graduatorName?:string;
  public dissertationYear?: number|string;
  public kandidateDisserDate!:Date;
  public approveVAKDate!:Date;
  public issueCountry?:string;
  public studyLevelName?:string;
  public graduateThis!: boolean;
}

export class EducationDocLanguages{
  public id?:number;
  public externalId?:Guid;
  public educationDocLanguageId?:number;
  public educationDocLanguageExternalId?:Guid;
  public educationDocId?:Guid;
  public languages!:string;
  public dictLanguageId?: string;
  public dictLanguageLevelId!: string;
  public ball?:number;
}

import {EventData, FieldInfo} from "../../../models/common/common-dict.model";
import {FieldType} from "../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Component} from "@angular/core";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {DynamicDict} from "../../../models/mfc/dicts/dynamic-dict.model";
import {DynamicDictService} from "../../../services/mfc/dicts/dynamic-dict.sevice";
import {breadcrumbItemsMap} from "src/app/models/home/breadcrumb-items.model";
import { boolOptions } from "src/app/models/mfc/enums/bool-options.enum";
import {DictCreatorService} from "../../../services/mfc/dict-creator.service";

@Component({
  selector: 'mfc-dict-create',
  template: `
      <mfc-settings></mfc-settings>
      <app-dict
              [data]="dictData"
              [fieldsInfo]="info"
              [editable]="true"
              [sortable]="true"
              [getFormGroup]="formGroup"
              [pageable]="pagerSettings"
              [breadcrumbs]="breadcrumbItemsMap"
              [deletableField]="deletableField"
              [editableField]="editableField"
              (getMethod)="get()"
              (addMethod)="add($event)"
              (updateMethod)="update($event)"
              (removeMethod)="remove($event)">
      </app-dict>
  `
})
export class DictCreatorComponent {

  protected dictData: DynamicDict[] = this.dictCreatorService.dicts$.value;

  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly deletableField = 'editable';
  protected readonly editableField = 'editable';
  protected readonly info: FieldInfo<DynamicDict>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Наименование справочника',
      width: 350,
    },
    {
      field: 'hidden',
      type: FieldType.Dropdown,
      listOptions: {
        data: this.boolOptions,
        textField: 'text',
        valueField: 'id'
      },
      title: 'Скрыть',
      width: 200,
    }
  ];

  protected formGroup(dataItem?: DynamicDict): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      name: new FormControl(dataItem?.name, Validators.required),
      hidden: new FormControl(dataItem?.hidden ?? false)
    });
  }

  constructor(private dictCreatorService: DictCreatorService) {}

  protected get() {
    if (!this.dictCreatorService.dicts$.value.length) {
      this.dictCreatorService.getAllDicts().subscribe(data =>
        this.dictData = data
      );
    }
  }

  protected add({value, observer}: EventData<DynamicDict>) {
    this.dictCreatorService.addDict(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<DynamicDict>) {
    this.dictCreatorService.updateDict(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<DynamicDict>) {
    this.dictCreatorService.removeDict(value.id).subscribe(observer);
  }
}

import { StudyLevel } from "./studylevel.model";
import {Guid} from "guid-typescript";

export class EducationDocTypes {
  public dictEducationDocTypeId!: number;
  public dictEducationDocTypeExternalId?:Guid;
  public educationDocTypeName!: string;
  public dictStudyLevelId?: Guid;
  public dictStudyLevel!: StudyLevel;
  public order?: number;
}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {MilitaryStockCategories} from "../../models/contingent/militarystockcategories.model";
import {catchError} from "rxjs/operators";
import {MilitaryCommissariat} from "../../models/contingent/militarycommissariat.model";

@Injectable({
  providedIn: 'root'
})
export class MilitaryCommissariatService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.militarycommissariat}`;

  constructor(private http: HttpClient) { }

  //Get all MilitaryCommissariat
  public getAllMilitaryCommissariat(): Observable<MilitaryCommissariat[]> {
    return this.http.get<MilitaryCommissariat[]>(this.baseUrl);
  }

  //Add MilitaryCommissariat
  addMilitaryCommissariat(militarycommissariat: MilitaryStockCategories): Observable<MilitaryCommissariat> {
    return this.http.post<MilitaryCommissariat>(this.baseUrl, militarycommissariat)
      .pipe(catchError(this.handleError));
  }

  //Delete MilitaryCommissariat
  deleteMilitaryCommissariat(id: string): Observable<MilitaryCommissariat> {
    return this.http.delete<MilitaryCommissariat>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit MilitaryCommissariat
  updateMilitaryCommissariat(militarycommissariat: MilitaryCommissariat): Observable<MilitaryCommissariat> {
    return this.http
      .put<MilitaryCommissariat>(this.baseUrl + '/' + militarycommissariat.id, militarycommissariat)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

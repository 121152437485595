import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {PublicationsDict, PublicationsDictAdd} from "../../../models/publications/dict/dict.model";
import {Dicts} from "../../../models/publications/dicts.model";

@Injectable({
    providedIn: 'root'
})
export class PublicationsDictsService {
    public dictsLoaded$ = new BehaviorSubject<boolean>(false);
    public dicts$ = new BehaviorSubject<Dicts[]>([]);

    private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.publications.dict}`;

    constructor(private http: HttpClient) {}

    getDynamicDicts(): Observable<Dicts[]> {
        return this.http.get<Dicts[]>(`${this.baseUrl}/GetDynamicDictNames/`);
    }

    getDictData(dictId: string, isActive?: boolean): Observable<PublicationsDict[]> {
        const query = isActive ? '?isActive=true' : '';
        return this.http.get<PublicationsDict[]>(`${this.baseUrl}/${dictId}${query}`);
    }

    addDictData(dict: PublicationsDictAdd): Observable<PublicationsDictAdd> {
        return this.http.post<PublicationsDictAdd>(this.baseUrl, dict);
    }

    editDictData(dict: PublicationsDict): Observable<PublicationsDict> {
        return this.http.put<PublicationsDict>(this.baseUrl, dict);
    }

    removeDictData(id: string): Observable<PublicationsDict> {
        return this.http.delete<PublicationsDict>(`${this.baseUrl}/${id}`);
    }

    getDegreeSpecialities(dictId: string, year: number): Observable<PublicationsDict[]> {
        return this.http.get<PublicationsDict[]>(`${this.baseUrl}/${dictId}?year=${year}`);
    }

    getCountries(): Observable<PublicationsDict[]> {
      return this.http.get<PublicationsDict[]>(`${this.baseUrl}/DynamicDict/Country`);
    }
}

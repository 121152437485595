<oferta-settings></oferta-settings>
<h1>{{user.fio}} ({{userTypeName}})</h1>

<p class="title">Присоединение к положениям</p>

<div class="grid-data">
  <kendo-grid
    [kendoGridBinding]="userSignatures"
    [loading]="loading"
    [skip]="skip"
    (cellClick)="editHandler($event)"
  >
    <ng-template *ngIf="editable" kendoGridToolbarTemplate [position]="position">
      <div *ngIf="isInEditingMode">
        <button kendoButton (click)="cancelHandler()">Отмена</button>
        <button kendoButton themeColor="primary" (click)="saveCurrent()">Сохранить</button>
      </div>
    </ng-template>
    <kendo-grid-column field="dictRegulationTypeId" [editable]="false" title="Тип положения" [width]="360" headerClass="gridHeader">
      <ng-template kendoGridCellTemplate let-dataItem>
        <p class="alignCells leftAlignment">{{ nameDict(dataItem.dictRegulationTypeId) }}</p>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="joinDate" [editable]="false" title="Дата присоединения" [width]="200" headerClass="gridHeader">
      <ng-template kendoGridCellTemplate let-dataItem>
        <p>{{ toLocalDatetime(dataItem.joinDate) }}</p>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="confirmedDate" [editable]="false" title="Дата заявления (из 1C)" *ngIf="userMayBeBlocked" [width]="200" headerClass="gridHeader">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datetimepicker
          format="dd.MM.yyyy HH:mm"
          [formControl]="formGroup.get('confirmedDate')
          "
        >
        </kendo-datetimepicker>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <p>{{ toLocalDatetime(dataItem.confirmedDate) }}</p>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="usageConfirmedDate" [width]="200" headerClass="gridHeader" *ngIf="userMayBeBlocked" [editable]="editable">
      <ng-template kendoGridHeaderTemplate>
        <div class="headerFlex">
          <span>Дата заявления (вручную)</span>
          <span class="tooltip k-icon k-i-info k-icon-md" kendoTooltip
                title="Дата в данном поле доступна на редактирование. При выборе даты подсчет дней до блокировки останавливается.">
        </span>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datetimepicker
          format="dd.MM.yyyy HH:mm"
          [formControl]="formGroup.get('usageConfirmedDate')"
        >
        </kendo-datetimepicker>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <p>{{ toLocalDatetime(dataItem.usageConfirmedDate) }}</p>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dateUntilBlocking" title="Количество дней до блокировки" *ngIf="userMayBeBlocked" [width]="300" headerClass="gridHeader" [editable]="editable">
      <ng-template kendoGridHeaderTemplate>
        <span>Количество дней до блокировки</span>
        <span class="tooltip k-icon k-i-info k-icon-md" kendoTooltip
              title="По истечении количества дней до блокировки при отсутствии заявления доступ к учетной записи/модулям блокируется. Доступ к учетной записи/модулям можно восстановить редактированием количества дней в данном столбце.">
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-datetimepicker
          format="dd.MM.yyyy HH:mm"
          [formControl]="formGroup.get('dateUntilBlocking')"
        >
        </kendo-datetimepicker>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <p class="alignCells">{{ calculateDateUntilBlocking(dataItem.dateUntilBlocking) }}</p>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DictLessonForm } from 'src/app/models/education/dictLessonForm.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictLessonFormService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictLessonForm}`;

  constructor(private http: HttpClient) { }

  //Get all LessonForms
  public getAllLessonForms(): Observable<DictLessonForm[]> {
    return this.http.get<DictLessonForm[]>(this.baseUrl + '/GetLessonForms/');
  }

  // Add LessonForm
  addLessonForm(dictLessonForm: DictLessonForm): Observable<DictLessonForm> {
    return this.http.post<DictLessonForm>(this.baseUrl + '/CreateLessonForm/' , dictLessonForm)
      .pipe(catchError(this.handleError));
  }

  // Edit LessonForm
  updateLessonForm(dictLessonForm: DictLessonForm): Observable<DictLessonForm> {
    return this.http.put<DictLessonForm>(this.baseUrl + '/UpdateLessonForm/' + dictLessonForm.dictLessonFormId, dictLessonForm)
      .pipe(catchError(this.handleError));
  }

  // Remove LessonForm
  deleteLessonForm(id: string): Observable<DictLessonForm> {
    return this.http.delete<DictLessonForm>(this.baseUrl + '/DeleteLessonForm/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {Injectable} from "@angular/core";
import {
  ApplicationConstructor
} from '../../models/mfc/applicationConstructor/application-constructor.model';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  ApplicationConstructorSettings
} from '../../models/mfc/applicationConstructor/settings/application-constructor-settings.model';
import {handleError} from '../../helpers/publications/errorHandle-helper';
import {
  ApplicationConstructorForm,
  ConstructorFilter,
  ConstructorForm,
  CreateApplicationConstructorForm, MainSettings, UpdateApplicationConstructorForm
} from '../../models/mfc/applicationConstructor/form/constructor-main-settings.model';
import {Property} from '../../models/mfc/applicationConstructor/settings/constructor-tabs-settings.model';
import {NumericApplicationConstructorStatusesEnum} from '../../models/mfc/enums/application-constructor-statuses.enum';
import {constructorStatusActions} from '../../models/mfc/applicationConstructor/application-constructor-statuses.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConstructorService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.applicationConstructor}/`;

  constructor(private http: HttpClient) { }

  private mapConstructorForm(form: ConstructorForm)
    : CreateApplicationConstructorForm | UpdateApplicationConstructorForm {
    const applicationConstructor: MainSettings = {
      dictApplicationTypeId: <string>form.type,
      name: <string>form.name,
      dictTypeReceiptIds: form.typeReceipts,
      showOnStudentCard: form.showOnStudentCard,
      allowedAddInsteadOfStudents: form.allowedAddInsteadOfStudents,
      allowRegistrationNumber: form.allowRegistrationNumber,
      isAllTrainingLevelAvailable: form.isAllTrainingLevelAvailable,
      isAllFacultyAvailable: form.isAllFacultyAvailable,
      isAllStudyFormAvailable: form.isAllStudyFormAvailable,
      isAllCitizenshipAvailable: form.isAllCitizenshipAvailable,
      systemNotificationSettings: form.systemNotificationSettings
    };

    const facultyIds = form.isAllFacultyAvailable ? [] :
      form.faculties.filter((item) => item.filialId).map((item) => item.id);
    const filter: ConstructorFilter =  {
      facultyIds: facultyIds,
      studyFormIds: form.isAllStudyFormAvailable ? [] : form.studyForms,
      citizenShipIds: form.isAllCitizenshipAvailable ? [] : form.citizenShips,
      trainingLevelIds: form.isAllTrainingLevelAvailable ? [] : form.trainingLevels
    };

    if (form.externalId) {
      return {
        externalId: form.externalId,
        applicationConstructor,
        filter,
        documents: form.documents
      }
    } else {
      return {
        applicationConstructor,
        filter,
        documentNames: form.documents?.map((item) => item.name),
      }
    }
  }

  //TODO: временно
  private mapData(data: any): ApplicationConstructorSettings {
    const tabs = data.tabs.map((tab: any) => ({...tab, externalId: tab.id}));
      tabs.forEach((tab: any) =>
        tab.groupProperties.forEach((group: any) => {
          group.externalId = group.id;
          group.standardProperties = group.properties.map((property: any) => this.mapProperty(property));
          group.additionalProperties = group.additionalProperties.map((property: any) => this.mapProperty(property));
      }));
    return {...data, tabs: tabs};
  }

  private mapProperty(property: any): Property {
    const dictProperty = property.dictProperty;
    const prop = {
      ...property,
      externalId: property.id,
      name: dictProperty.name,
      propertyTypeEnum: dictProperty.propertyTypeEnum,
    };
    if (dictProperty.dict?.dictValues) prop.dict = dictProperty.dict?.dictValues;
    return prop;
  }

  public getApplicationConstructors(): Observable<ApplicationConstructor[]> {
    return this.http.get<ApplicationConstructor[]>(this.baseUrl)
      .pipe(map((data) => data ?? []));
  }

  public getApplicationConstructor(id: string): Observable<ApplicationConstructor> {
    return this.http.get<ApplicationConstructor>(`${this.baseUrl}${id}`);
  }

  public saveApplicationConstructor(constructor: ConstructorForm) {
    const data = this.mapConstructorForm(constructor);
    if (constructor.externalId) {
      return this.updateApplicationConstructor(<UpdateApplicationConstructorForm>data);
    } else {
      return this.createApplicationConstructor(data);
    }
  }

  public createApplicationConstructor(constructor: CreateApplicationConstructorForm) {
    return this.http.post<ApplicationConstructorForm>(this.baseUrl, constructor)
      .pipe(catchError(handleError));
  }

  public updateApplicationConstructor(constructor: UpdateApplicationConstructorForm) {
    return this.http.put<ApplicationConstructorForm>(this.baseUrl, constructor)
      .pipe(catchError(handleError));
  }

  public changeStatus(id: string, status: NumericApplicationConstructorStatusesEnum) {
    return this.http.put(`${this.baseUrl}${id}/${constructorStatusActions.get(status)}`, {})
      .pipe(catchError(handleError));
  }

  public getApplicationConstructorByStudentId(typeId: string, studentId: string): Observable<ApplicationConstructorSettings> {
    return this.http.get<ApplicationConstructorSettings>(`${this.baseUrl}GetByStudentId/${typeId}/${studentId}`)
      .pipe(map((data) => this.mapData(data)), catchError(handleError));
  }
}

import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {journalBlanksModel} from '../../models/diplomablanks/journal-blanks.model';

@Injectable({
    providedIn: 'root'
})

export class JournalBlanksService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.blank}`;

  constructor(private http: HttpClient) {}

  public getJournalBlanks(year: number) {
    return this.http.get<journalBlanksModel[]>(this.baseUrl + '/GetLogs/' + year);
  }
}
<gia-settings></gia-settings>

<h1>Тип диплома</h1>

<kendo-grid
  [kendoGridBinding]="diplomaTypes"
  [pageable]="pagerSettings"
  (cellClick)="editRow($event)"
  (remove)="onRemove($event)"
  (add)="addRow()"
  [group]="group"
  [sortable]="true"
  [sort]="sort"
  [filterable]="!isLine"
  [pageSize]="10"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
    <button
      kendoGridAddCommand
      themeColor="success"
      *ngIf="!isLine"
      icon="plus"
    >
      Добавить
    </button>
    <div class="toolbarButtons" *ngIf="isLine">
      <button kendoButton (click)="onCancel()">Отмена</button>
      <button
        kendoButton
        (click)="saveRow()"
        themeColor="primary"
        [disabled]="formGroup!.invalid"
      >
        Сохранить
      </button>
    </div>
  </ng-template>
  <kendo-grid-column
    [width]="400"
    headerClass="gridHeader dictionaries"
    field="name"
    title="Название"
  >
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell
        [column]="column"
        [filter]="filter"
        [showOperators]="false"
        operator="contains"
      >
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">
        {{ dataItem.name }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="trainingLevelName" title="Уровень подготовки"
                     headerClass="gridHeader dictionaries"
                     [width]="350"
                     [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.trainingLevelName }}
        </span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group="group">
        <span>
          {{ group.value ? "Уровень подготовки" : "" }} : {{ group.value }}
        </span>
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-formGroup="formGroup">
      <kendo-combobox [data]="trainingLevels"
                      textField="name"
                      valueField="id"
                      [valuePrimitive]="true"
                      #trainingLevel
                      [openOnFocus]="trainingLevel"
                      [kendoDropDownFilter]="filterSettings"
                      [formControl]="formGroup.get('trainingLevelId')">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="value" title="Служебная категория"
                     headerClass="gridHeader dictionaries"
                     [width]="200"
                     [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
            {{ nameEnum(dataItem.value) }}
         </span>
    </ng-template>
    <ng-template kendoGridEditTemplate
                 let-formGroup="formGroup">
      <kendo-combobox [data]="diplomaTypeEnumList"
                      textField="text"
                      valueField="value"
                      [valuePrimitive]="true"
                      [formControl]="formGroup.get('value')">
      </kendo-combobox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngIf="editable"
    headerClass="gridHeader dictionaries"
    [width]="160"
  >
    <ng-template kendoGridCellTemplate>
      <span class="alignCells">
        <button
          kendoGridRemoveCommand
          themeColor="secondary"
          icon="delete"
          fillMode="flat"
          size="none"
          class="custom-size"
          rounded="full"
        ></button>
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

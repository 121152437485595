<classroom-header></classroom-header>
<div class="grid-layout-container margin-t-20" > 
    <div class="main-title" *ngIf="id.length > 0" > Характеристики аудитории </div>
    <div class="main-title" *ngIf="id == '' "> Добавление аудитории </div>

    <div class="delete">
        <button kendoButton
                class="white" 
                *ngIf="id.length > 0 && accessLevel == true"
                (click)="deleteClass(id)"
        >
        Удалить аудиторию
        </button>
    </div>

    <div class="parameters">
        <div class="unit">
            <kendo-label class="search-explanation" [for]="buildingId" text="Корпус"></kendo-label>
            <div class="input-search width-200">
                <kendo-combobox
                     #buildingId
                    [openOnFocus]="buildingId"
                    [data]="buildings"
                    textField="buildingName"
                    valueField="buildingId"
                    [valuePrimitive]="true"

                    [disabled] = "!accessLevel"
                    [kendoDropDownFilter]="filterSettings"
                    name="building"
                    [(ngModel)]="building"
                    >
                </kendo-combobox>
            </div>
            <span class="required m-l5 m-r12">*</span>

            <div class="search-explanation params"> Номер аудитории </div>
            <div class="input-search width-200">
               <kendo-textbox
                    [clearButton]="true"
                    [disabled] = "!accessLevel"
                    [(ngModel)]="number"
                    >
                </kendo-textbox>
            </div>
            <span class="required m-l5">*</span>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" [for]="classRoomTypeId" text="Тип аудитории"></kendo-label>
            <div class="input-search">
                <kendo-combobox
                    #classRoomTypeId
                    [openOnFocus]="classRoomTypeId"
                    [data]="filterTypes"
                    textField="classRoomTypeName"
                    valueField="classRoomTypeId"
                    [disabled] = "!accessLevel"
                    [filterable]="true"
                    (filterChange)="typeFilter($event)"
                    [valuePrimitive]="true"
                    name="type"
                    [(ngModel)]="type"
                    >
                </kendo-combobox>
            </div>
            <span class="required m-l5">*</span>
        </div>
        <div class="unit">
            <div class="search-explanation"> Название </div>
            <div class="input-search">
               <kendo-textbox
                    [clearButton]="true"
                    [disabled] = "!accessLevel"
                    [(ngModel)]="name"
                    >
                </kendo-textbox>
            </div>
            <span class="required m-l5">*</span>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" [for]="departmentId" text="Владелец"></kendo-label>
            <div class="input-search">
                <kendo-combobox
                    #departmentId
                    [openOnFocus]="departmentId"
                    [data]="owners"
                    textField="departmentName"
                    valueField="departmentId"
                    [kendoDropDownFilter]="filterSettings"
                    [disabled] = "!accessLevel"
                    [valuePrimitive]="true"
                    name="owner"
                    [(ngModel)]="owner"
                    >
                </kendo-combobox>
            </div>
        </div>
        <div class="unit">
            <kendo-label class="search-explanation" [for]="classRoomPurposeId" text="Назначение аудитории"></kendo-label>
            <div class="input-search">
                <kendo-combobox
                    #classRoomPurposeId
                    [openOnFocus]="classRoomPurposeId"
                    [data]="filterAssigns"
                    textField="classRoomPurposeName"
                    valueField="classRoomPurposeId"
                    [disabled] = "!accessLevel"
                    [filterable]="true"
                    (filterChange)="assignFilter($event)"
                    [valuePrimitive]="true"Характеристики аудитории
                    name="assign"
                    [(ngModel)]="assign"
                    >
                </kendo-combobox>
            </div>
        </div>
    </div>

    <div>
        <button kendoButton
                class="white m-r22" 
                [routerLink]="['/classroom']"                       
        >
        Назад
        </button>
        <button kendoButton
                themeColor="primary"
                *ngIf="id == '' && accessLevel == true"
                [disabled]='(building == "")||(name == "")||(number == "")||(type == "") || (building == undefined)'
                (click)="addClass()"
        >
        Добавить аудиторию
        </button>
        <button kendoButton
                themeColor="primary"
                *ngIf="id.length > 0 && accessLevel == true"
                [disabled]='(building == "")||(name == "")||(number == "")||(type == "") || (building == undefined)'
                (click)="editClass(id)"
        >
        Применить
        </button>
    </div>

</div>

<div kendoDialogContainer></div>
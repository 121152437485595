import {KendoProperties} from "./enums/kendo-properties.enum";

export const dictPropertyTypes = [
  {enum: KendoProperties.Textbox, name: 'Строка'},
  {enum: KendoProperties.Textarea, name: 'Текстовый блок'},
  {enum: KendoProperties.Number, name: 'Целочисленный тип'},
  {enum: KendoProperties.Date, name: 'Дата'},
  {enum: KendoProperties.Boolean, name: 'Да/Нет'},
  {enum: KendoProperties.File, name: 'Файл'},
  {enum: KendoProperties.Dropdown, name: 'Выпадающий список'},
  {enum: KendoProperties.Multiselect, name: 'Множественный выбор'},
  {enum: KendoProperties.Combobox, name: 'Поле со списком'},
  {enum: KendoProperties.Table, name: 'Таблица'},
  {enum: KendoProperties.Files, name: 'Несколько файлов'},
]

export const dictPropertiesTypeMap = new Map(Object.values(dictPropertyTypes)
  .map(value => [value.enum, value.name]));

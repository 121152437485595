<workload-settings></workload-settings>
<div class="container">
    <h1>Мониторинг поручений</h1>
    <div class="dropdowns">
        <span class="workloadDropdownContainer">
            <h3>Учебный год</h3>
            <kendo-dropdownlist
                    [data]="studyYears"
                    [(ngModel)]="studyYearsEdit"
                    [valuePrimitive]="false"
                    (valueChange)="onFilterChange()"
                    valueField="name"
                    textField="name"
                    class="workloadDropdown">
            </kendo-dropdownlist>
        </span>
        <span class="workloadDropdownContainer">
            <h3>Институт (факультет)</h3>
            <kendo-dropdownlist
                    [data]="faculties"
                    [(ngModel)]="facultiesEdit"
                    (valueChange)="onFilterChange()"
                    [valuePrimitive]="false"
                    valueField="id"
                    textField="sName"
                    class="workloadDropdown">
            </kendo-dropdownlist>
        </span>
        <span class="workloadDropdownContainer">
            <h3>Уровень подготовки</h3>
            <kendo-dropdownlist
                    [data]="trainingLevels"
                    [(ngModel)]="trainingLevelsEdit"
                    (valueChange)="onFilterChange()"
                    [valuePrimitive]="false"
                    valueField="name"
                    textField="name"
                    class="workloadDropdown">
            </kendo-dropdownlist>
        </span>
    </div>
    <kendo-grid
            [loading]="loading"
            [kendoGridBinding]="gridData">
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <kendo-grid-column [width]="600" headerClass="gridHeader" field="kafedraName" title="Кафедра">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span (click)="navigateToAssignments(dataItem.name)" class="alignCells leftAlignment clickableCell">
                    {{ dataItem.name }}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" headerClass="gridHeader" field="isOrder1C" title="Поручение">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span [ngClass]="getOnAssignmentValue(dataItem, true)" class="alignCells">
                    {{ getOnAssignmentValue(dataItem, false) === '-' ? 'Нет': getOnAssignmentValue(dataItem, false) }}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column-group headerClass="gridHeader" title="1 семестр">
            <kendo-grid-column [width]="item.studyFormName.length > 15 ? 150:100" headerClass="gridHeader" *ngFor="let item of studyForms" [title]="item.studyFormName">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells" [ngSwitch]="checkStudyForm(dataItem, 1, item.studyFormName)">
                        <kendo-icon *ngSwitchCase="'Выдано'" size="medium" name="check-circle" themeColor="success"></kendo-icon>
                        <kendo-icon style="color: #1274AC" *ngSwitchCase="'Частично'" size="medium" name="check"></kendo-icon>
                        <kendo-icon *ngSwitchCase="'-'" size="medium" name="minus"></kendo-icon>
                    </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="true"></kendo-grid-column>
        </kendo-grid-column-group>
        <kendo-grid-column-group headerClass="gridHeader" title="2 семестр">
            <kendo-grid-column [width]="item.studyFormName.length > 15 ? 150:100" headerClass="gridHeader" *ngFor="let item of studyForms" [title]="item.studyFormName">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="alignCells" [ngSwitch]="checkStudyForm(dataItem, 2, item.studyFormName)">
                        <kendo-icon *ngSwitchCase="'Выдано'" size="medium" name="check-circle" themeColor="success"></kendo-icon>
                        <kendo-icon style="color: #1274AC" *ngSwitchCase="'Частично'" size="medium" name="check"></kendo-icon>
                        <kendo-icon *ngSwitchCase="'-'" size="medium" name="minus"></kendo-icon>
                    </span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [hidden]="true"></kendo-grid-column>
        </kendo-grid-column-group>
    </kendo-grid>
</div>
import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {DictPost} from "../../../models/publications/dict/post.model";
import {catchError} from "rxjs/operators";
import {handleError} from "../../../helpers/publications/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class PublicationsPostService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/Post/`;
  constructor(private http: HttpClient) { }

  getPosts(): Observable<DictPost[]> {
    return this.http.get<DictPost[]>(this.baseUrl);
  }

  addPost(post: DictPost): Observable<DictPost> {
    return this.http.post<DictPost>(this.baseUrl, post)
        .pipe(catchError(handleError));
  }

  updatePost(post: DictPost): Observable<DictPost> {
    return this.http.put<DictPost>(this.baseUrl, post)
        .pipe(catchError(handleError));
  }

  removePost(id: string): Observable<DictPost> {
    return this.http.delete<DictPost>(this.baseUrl + id)
        .pipe(catchError(handleError));
  }
}

import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CellClickEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { LKPerson } from 'src/app/models/person/lkPerson.model';
import { Admin } from 'src/app/models/useraccess/admin.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { AdminService } from 'src/app/services/useraccess/admin.service';

@Component({
  selector: 'app-technical-administrators',
  templateUrl: './technical-administrators.component.html',
  styleUrls: ['./technical-administrators.component.scss']
})
export class TechnicalAdministratorsComponent implements OnInit {

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;

  public virtual: any = {
    itemHeight: 28,
  };

  public admins: Admin[] = [];
  public admin: Admin = {
    id: '',
    personId: '',
    personName: ''
  }

  public persons: LKPerson[] = [];
  public person: LKPerson = {
    personId: 0,
    personExternalId: '',
    fullName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    login: '',
    birthday: new Date(),
    hasPps: false,
    remainingVacationDaysNumber: null,
  }

  @Input() searchFIO: string = '';

  public gridData: any[] = [];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;
  public loading = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private adminService: AdminService,
    private personService: PersonService) { }

  ngOnInit(): void {
    this.getPersons();
    this.getAdmins();
    //this.gridData = this.admins;
    /*this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });*/
  }

  clearData() {
    this.gridData = this.admins;
    this.searchFIO = ''
  }

  filterData(dataItem: any) {
    this.gridData = this.admins.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  // Get admins
  public getAdmins() {
    this.loading = true;
    this.adminService.getAdmins()
      .subscribe(
        response => {
          this.loading = false;
          this.admins = response;
          this.gridData = this.admins;
        }, error => {
          this.loading = false;
          }
      );
  }

  // Get persons
  public getPersons() {
    this.personService.getPersons()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      id: 0,
      personId: '',
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start Editing
  public editHandler({sender, columnIndex, rowIndex, dataItem}: CellClickEvent): void {

    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.isLine = true;
    //this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if(this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }
      //console.log(this.formGroup.value);
      this.adminService.saveAdmin(this.formGroup.value)
      .subscribe(
        response => {
          this.getAdmins();
          this.notificationService.showSuccess("Добавлено");
          this.admin = {
            id: '',
            personId: '',
            personName: ''
          }
        },
        error => {
          this.notificationService.showError("Не удалось добавить запись");
        }
      );
    }
    this.closeEditor(this.grid);
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста, подтвердите",
      content: `Вы действительно хотите удалить: '${dataItem.personName}' из технических администраторов?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.adminService.deleteAdmin(dataItem.id)
          .subscribe(
            (response: any) => {
              this.getAdmins();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }
}

const formGroup = (dataItem: { id?: any; personName?: any; personId?: any; }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    //personName: new FormControl(dataItem.personName, Validators.required),
    personId: new FormControl(dataItem.personId, Validators.required),
  });

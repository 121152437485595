import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StudyLevel } from '../../models/dicts/studylevel.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class StudylevelService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studyleveldict}`;

  constructor(private http: HttpClient) { }

  //Get all StudyLevel
  public getAllStudyLevel(): Observable<StudyLevel[]> {
    return this.http.get<StudyLevel[]>(this.baseUrl);
  }

  //Add StudyLevel
  addStudyLevel(studyLevel: StudyLevel): Observable<StudyLevel> {
    return this.http.post<StudyLevel>(this.baseUrl, studyLevel)
      .pipe(catchError(this.handleError));
  }

  //Delete StudyLevel
  deleteStudyLevel(id: string): Observable<StudyLevel> {
    return this.http.delete<StudyLevel>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit StudyLevel
  updateStudyLevel(studyLevel: StudyLevel): Observable<StudyLevel> {
    return this.http.put<StudyLevel>(this.baseUrl + '/' + studyLevel.dictStudyLevelExternalId, studyLevel)
      .pipe(catchError(this.handleError));
  }


  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

<persondepartment-header></persondepartment-header>
<h1>Должности</h1>

<kendo-grid [kendoGridBinding]="posts"
                id="postsGrid"
                [pageable]="true"
                [pageable]="{
                    buttonCount: buttonCount,
                    info: info,
                    type: type,
                    pageSizes: pageSizes,
                    previousNext: previousNext,
                    position: positionPager
                  }"
                [pageSize]="pageSize"
                [sortable]="true"
                [filterable]="true"
                (filterChange)="filterChange($event)"
                (add)="addHandler($event)"
                (edit)="editHandler($event)"
                
                [loading]="loading"
                [skip]="skip">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <ng-template kendoGridToolbarTemplate [position]="position">
        <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus" class="editRecord">
           Добавить
        </button>
        <div *ngIf="isInEditingMode">
          <button kendoButton class="m-r10"
                  (click)="cancelHandler()">
            Отмена
          </button>
          <button kendoButton
                  themeColor="primary"
                  [disabled]="formGroup!.invalid"
                  (click)="saveCurrent()"
                  >
            Сохранить
          </button>
        </div>
      </ng-template>

    <kendo-grid-command-column  [sticky]="true" title="" [width]="50" >
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" >
            <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full" *ngIf="rowIndex !== editedRowIndex">
            </button>
        </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column field="postName"
                       title="Наименование должности"
                       [width]="300"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-combobox [useVirtual]='false' [filter]="filter" [data]="posts" textField="postName" valueField="postName">
            </filter-combobox>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-textbox [formControl]="formGroup.get('postName')" class="editRecord">
            </kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells centerAlignment reset">
               {{dataItem.postName}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="postSName"
            title="Сокр.наим"
            required
            [width]="150"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-textbox [formControl]="formGroup.get('postSName')" class="editRecord">
            </kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{dataItem.postSName}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="externalId"
            title="ID"
            required
            [width]="200"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{dataItem.externalId}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="createdAt"
            title="Дата создания"
            required
            [width]="150"
            filter="date" format="dd.MM.yyyy"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{dataItem.createdAt | date: 'dd.MM.yyyy'}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="updatedAt"
            title="Дата изменения"
            required
            [width]="150"
            filter="date" format="dd.MM.yyyy"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{dataItem.updatedAt | date: 'dd.MM.yyyy'}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="recordSource"
            title="Способ добавления"
            required
            [width]="150"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [filter]="filter" [data]="method" textField="methodName" valueField="recordSource">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset" *ngIf="dataItem.recordSource == 0"> 1C </span>
            <span class="alignCells centerAlignment reset" *ngIf="dataItem.recordSource == 1"> Вручную </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="commonStatus"
            title="Статус"
            required
            [width]="150"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [useVirtual]='false'  [filter]="filter" [data]="statuses" textField="statusName" valueField="commonStatus">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-dropdownlist
            [data]="statuses"
            textField="statusName"
            valueField="commonStatus"
            [valuePrimitive]="true"
            [formControl]="formGroup.get('commonStatus')"
            class="editRecord">
            </kendo-dropdownlist>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment reset">
                {{ Status(dataItem.commonStatus) }}
            </span>
        </ng-template>
    </kendo-grid-column>

</kendo-grid>
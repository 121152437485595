<blanks-settings></blanks-settings>
<h1>Типы бланков</h1>

<kendo-grid [kendoGridBinding]="blankTypes"
            [loading]="loading"
            [pageable]="pagerSettings"
            [pageSize]="pageSize"
            (cellClick)="editRow($event)"
            (remove)="onRemove($event)"
            (add)="addRow()"
            [sortable]="true"
            [filterable]="!isNew">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
        <button
            kendoGridAddCommand
            themeColor="success"
            *ngIf="!isLine"
            icon="plus"
        >
            Добавить
        </button>
        <div class="toolbarButtons" *ngIf="isLine">
            <button kendoButton (click)="onCancel()">Отмена</button>
            <button
                kendoButton
                (click)="saveRow()"
                themeColor="primary"
                [disabled]="formGroup!.invalid || notUniqueName"
            >
            Сохранить
            </button>
        </div>
    </ng-template>

    <ng-template kendoGridLoadingTemplate>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column
        headerClass="gridHeader dictionaries"
        field="name"
        title="Наименование"
    >
    <ng-template kendoGridFilterCellTemplate let-filter>
      <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="filterBlankTypes" textField="name" valueField="name">
      </filter-dropdown>
    </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ dataItem.name }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-textbox [formControl]="formGroup.get('name')"
                       (valueChange)="valueChangeName($event, dataItem)"
                       [ngClass]="{red: notUniqueName}">
        </kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
        [width]="150"
        headerClass="gridHeader dictionaries"
        field="diplomaIsRed"
        title="Красный диплом"
        [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.diplomaIsRed ? "Да" : "Нет"}}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-dropdownlist
          [data]="boolOptions"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('diplomaIsRed')"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
        [width]="300"
        headerClass="gridHeader dictionaries"
        field="dictTrainingLevelId"
        title="Уровень подготовки"
        [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getTrainingLevelsList(dataItem.blankTypeTrainingLevels, 'name') }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect
          kendoMultiSelectSummaryTag
          [autoClose]="false"
          [data]="trainingLevel"
          textField="name"
          valueField="externalId"
          [formControl]="formGroup.get('blankTypeTrainingLevels')"
          (valueChange)="valueChangeLevel($event)"
          [(ngModel)]="trainingLevelEdit"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span>
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
        [width]="180"
        headerClass="gridHeader dictionaries"
        field="isDiploma"
        title="Тип бланка"
        [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
        {{ getText(dataItem.isDiploma) }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-dropdownlist
          [data]="isDiploma"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('isDiploma')"
        >
        </kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        [width]="180"
        headerClass="gridHeader dictionaries"
        field="order"
        title="Порядковый номер"
        [filterable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.order }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-numerictextbox
            [value]="dataItem.order"
            [min]="1"
            format="n0"
            [decimals]="0"
            [formControl]="formGroup.get('order')">
          </kendo-numerictextbox>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" [width]="100" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button
            kendoGridRemoveCommand
            themeColor="secondary"
            icon="delete"
            fillMode="flat"
            size="none"
            class="custom-size"
            rounded="full"
          ></button>
        </span>
      </ng-template>
    </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>
export const boolOptionsEnum = [
    { id: true, text: 'Да' },
    { id: false, text: 'Нет' },
]

export enum levelAccessEnum {
    read = 1,
    write = 2,
  }
  
export const levelAccess = levelAccessEnum;
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, Observable, throwError } from "rxjs";
import { LocalSignatoryRole } from "../../models/contingent/localsignatory.model";

@Injectable({
  providedIn: "root"
})
export class DictLocalSignatoryRoleService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.localSignatoryRole}`;

  constructor(private http: HttpClient) {
  }

  public getLocalSignatoryRoles(): Observable<LocalSignatoryRole[]> {
    return this.http.get<LocalSignatoryRole[]>(this.baseUrl)
      .pipe(catchError(this.handleError));
  }

  public updateLocalSignatoryRole(dictLocalSignatoryRole: LocalSignatoryRole): Observable<LocalSignatoryRole> {
    return this.http.put<LocalSignatoryRole>(this.baseUrl + "/" + dictLocalSignatoryRole.externalId, dictLocalSignatoryRole)
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
 }
}
import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../../helpers/publications/errorHandle-helper";
import {DictScientometricBase} from "../../../models/publications/dict/scientometricBase.model";

@Injectable({
    providedIn: 'root'
})
export class ScientometricBasesService {
    baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/ScientometricBase/`;

    constructor(private http: HttpClient) {}

    getScientometricBases(): Observable<DictScientometricBase[]> {
        return this.http.get<DictScientometricBase[]>(this.baseUrl);
    }

    addScientometricBase(scientometricBase: DictScientometricBase): Observable<DictScientometricBase> {
        return this.http.post<DictScientometricBase>(this.baseUrl, scientometricBase)
            .pipe(catchError(handleError));
    }

    updateScientometricBase(scientometricBase: DictScientometricBase): Observable<DictScientometricBase> {
        return this.http.put<DictScientometricBase>(this.baseUrl, scientometricBase)
            .pipe(catchError(handleError));
    }

    removeScientometricBase(id: string): Observable<DictScientometricBase> {
        return this.http.delete<DictScientometricBase>(this.baseUrl + id)
            .pipe(catchError(handleError));
    }

}

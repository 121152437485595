import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EducationDocTypes } from '../../models/contingent/educationdoctypes.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EducationdoctypesService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.educationdoctypes}`;

  constructor(private http: HttpClient) { }

  //Get all EducationDocTypes
  public getAllEducationDocTypes(): Observable<EducationDocTypes[]> {
    return this.http.get<EducationDocTypes[]>(this.baseUrl);
  }

  //Add EducationDocTypes
  addEducationDocTypes(educationdoctypes: EducationDocTypes): Observable<EducationDocTypes> {
    return this.http.post<EducationDocTypes>(this.baseUrl, educationdoctypes)
      .pipe(catchError(this.handleError));
  }

  //Delete EducationDocTypes
  deleteEducationDocTypes(id: string): Observable<EducationDocTypes> {
    return this.http.delete<EducationDocTypes>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit EducationDocTypes
  updateEducationDocTypes(educationdoctypes: EducationDocTypes): Observable<EducationDocTypes> {
    return this.http
      .put<EducationDocTypes>(this.baseUrl + '/' + educationdoctypes.dictEducationDocTypeExternalId, educationdoctypes)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
      return throwError(error);
  }
}

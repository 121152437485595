import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class PostsService {

    baseUrl = `${environment.lkPersonApiEndpoint}`;

    constructor(private http: HttpClient) { }

    public getPosts(){
        return this.http.get(this.baseUrl + 'post/getPosts' );
    }

    public addPost(data: any){
        return this.http.post(this.baseUrl + 'post/addPost', data)
    }

    public updatePost(data: any, id: any){
        data.externalId = id;
     //   console.log(data);
        return this.http.put(this.baseUrl + 'post/updatePost', data)
    }
}
import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Person} from "../../../models/publications/person.model";
import {AuthorType} from "../../../models/publications/authorTypes.model";

@Injectable({
    providedIn: 'root'
})
export class PublicationsPersonService {
    baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.person}`;
    constructor(private http: HttpClient) { }

    // Получить всех сотрудников
    getPersons(fio?: string): Observable<Person[]> {
        const filter = fio ? `&FIO=${fio}` :'';
        return this.http.get<Person[]>(this.baseUrl + `/?Type=${AuthorType.Employee}${filter}`);
    }
}
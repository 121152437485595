import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { DictStandardLimitation } from "src/app/models/education/dictstandardlimitation.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DictStandardLimitationSettingService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictStandardLimitation}`;

  constructor(private http: HttpClient) { }

  public getAllDictStandardLimitations(): Observable<DictStandardLimitation[]> {
    return this.http.get<DictStandardLimitation[]>(this.baseUrl + '/AllDict/');
  }

  public updateDictStandardLimitation(dictStandardLimitation: DictStandardLimitation) {
    return this.http.put<any>(this.baseUrl + '/Dict/' + dictStandardLimitation.dictStandardLimitationExternalId, dictStandardLimitation)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

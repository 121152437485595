import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {DisciplineWorkloadUserAccessService} from "../services/useraccess/disciplineworkload-user-access.service";
import {Location} from "@angular/common";
import { PersonService } from '../services/disciplineworkload/externals/person.service';
import { Role } from '../models/useraccess/role';

@Injectable({
  providedIn: 'root'
})
export class DisciplineWorkloadRedirectGuard implements CanActivate {
  constructor(
      private route: Router,
      private tokenService: TokenStorageService,
      private jwt: JwtHelperService,
      private userAccess: DisciplineWorkloadUserAccessService,
      private location: Location,
      private personService: PersonService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    let hasPps: boolean = false;
    await this.personService.hasPps().toPromise().then(value => {
      hasPps = value == true;
    })

    const token = this.jwt.decodeToken(this.tokenService.getToken().accessToken);
    const roles = token?.role;
    const isAdmin = roles?.includes(Role.Admin);

    if(isAdmin) {
      this.route.navigate(['disciplineworkload/assignments']);
      return true;
    }

    this.userAccess.getAccess().subscribe({
      next: (response) => {
        if(response.userAccesses.length === 0 && !hasPps) {
          this.route.navigate(['/']);
          return false;
        }
        let accesses = response.userAccesses.filter((item: { personId: any; }) => item.personId === token.person_id);

        //console.log(accesses);

        const contingentLoadAccessLevel = accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.contingentLoadAccess))
          : 0;
        const assignementsAccessLevel= accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.errandsAccess))
          : 0;
        const cathedralLoadAccessLevel= accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.subdepartmentLoadAccess))
          : 0;
        const userAccessSettings= accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.isModerator ? 1 : 0))
          : 0;
        let teacherLoadAccessLevel= accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.teacherLoadAccess))
          : 0;
        const reportsAccessLevel= accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.reportsAccess))
          : 0;
        const assignmentMonitoringAccess = accesses?.length > 0
          ? Math.max(...accesses.map((_: any) => _.assignmentMonitoringAccess))
          : 0;

        //console.log('contingentLoadAccessLevel', contingentLoadAccessLevel);
        //console.log('assignementsAccessLevel', assignementsAccessLevel);
        //console.log('cathedralLoadAccessLevel', cathedralLoadAccessLevel);
        //console.log('userAccessSettings', userAccessSettings);
        //console.log('teacherLoadAccessLevel', teacherLoadAccessLevel);
        //console.log('reportsAccessLevel', reportsAccessLevel);
        //console.log('assignmentMonitoringAccess', assignmentMonitoringAccess);

        // Проверка на доступ в поручения
        if(assignementsAccessLevel > 0 ) {
          this.route.navigate(['disciplineworkload/assignments']);
          return true;
        }

        // Проверка на доступ в кафедральную нагрузку
        if(cathedralLoadAccessLevel > 0 ) {
          this.route.navigate(['disciplineworkload/departmentworkload']);
          return true;
        }

        // Проверка на доступ в контингент в нагрузку
        if(contingentLoadAccessLevel > 0) {
          this.route.navigate(['disciplineworkload/contingentworkload'])
          return true;
        }

        // Проверка на доступ в нагрузку преподавателя
        if(teacherLoadAccessLevel > 0 || hasPps) {
          this.route.navigate(['disciplineworkload/teacherworkload'])
          return true;
        }

        // Проверка на доступ в контингент в нагрузку
        if(userAccessSettings > 0) {
          this.route.navigate(['disciplineworkload/useraccess'])
          return true;
        }

        // Проверка на доступ в контингент в нагрузку
        if(reportsAccessLevel > 0) {
          this.route.navigate(['disciplineworkload/reportsWorkload'])
          return true;
        }

        // Проверка на доступ в контингент в нагрузку
        if(assignmentMonitoringAccess > 0) {
          this.route.navigate(['disciplineworkload/assignmentsMonitoring'])
          return true;
        }

        alert("У Вас нет доступа к сервису \"Сопряжение и нагрузка\"");
        localStorage.setItem('last_url', this.location.path())
        this.route.navigate(['/']);

        return false;
      }
    })
    localStorage.setItem('last_url', this.location.path())
    return false;
  }

}

import { Component, OnInit} from '@angular/core';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { difference } from 'lodash';
import { Subscription } from 'rxjs';
import { FoundPersonsService} from 'src/app/services/announcement/found-persons.service';

@Component({
  selector: 'app-selected-persons-new-list',
  templateUrl: './selected-persons-new-list.component.html',
  styleUrls: ['./selected-persons-new-list.component.scss']
})

export class SelectedPersonsNewListComponent implements OnInit{
  public gridView!: GridDataResult;
  public selection: any[] = [];  
  public selectedPersons: any[] = [];
  public quantitySelectedPersons: number = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private foundpersonsService: FoundPersonsService,
  ) { }

  public deleteSelectedElemens(){
    this.foundpersonsService.getNewListPersons(difference(this.selectedPersons, this.selection));
    this.selection = [];
  }

  public mySelectionKey(context: RowArgs): string {
    return context.dataItem;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.foundpersonsService.selectNewListPersons$.subscribe((data: any[]) => {
        this.selectedPersons = data;
        this.gridView = {
          data: this.selectedPersons,
          total: this.selectedPersons.length
        };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

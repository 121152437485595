import {Component, OnDestroy, OnInit} from "@angular/core";
import {NotificationsService} from "../../services/notifications/notifications.service";
import {environment} from "../../../environments/environment";
import {ReportDesignerScriptService} from "../../services/report-designer/report-designer-scripts.store";
import {Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'report-list',
  templateUrl: './report-designer.component.html',
  styleUrls: ['./report-designer.component.scss'],
  providers: [
    ReportDesignerScriptService
  ]
})
export class ReportDesignerComponent implements OnInit, OnDestroy {

  public baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.reportDesigner.base}`;
  public designer: any;

  constructor(private notificationService: NotificationsService,
              private reportDesignerScriptStore: ReportDesignerScriptService) {
    //TODO: пока что будет перенаправление до тех пор, пока конструктор не будет переписан на angular
    window.location.href = this.baseUrl + '/index';
  }

  /*public loadJQuery() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/js/jquery-3.3.1.min.js';
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public loadKendoAllJs() {
    let body = <HTMLDivElement> document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'assets/js/kendo.all.min.js';
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  public loadDesignerJs() {
    let body = <HTMLDivElement> document.body;

    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = this.baseUrl + '/resources/js/telerikReportViewer/';
    script.async = false;
    script.defer = true;

    body.appendChild(script);

    script = document.createElement('script');
    script.innerHTML = '';
    script.src = this.baseUrl + '/designerresources/js/webReportDesigner/';

    body.appendChild(script);

    script = document.createElement('script');
    script.innerHTML =
      '$(document).ready(function () {\n' +
      '    $("#webReportDesigner").telerik_WebReportDesigner({\n' +
      '      toolboxArea: {\n' +
      '        layout: "list" //Change to "grid" to display the contents of the Components area in a flow grid layout.\n' +
      '      },\n' +
      '      serviceUrl: "' + this.baseUrl + '"\n' +
      '    }).data("telerik_WebDesigner");\n' +
      '  });';
    script.src = '';
    script.async = true;
    script.defer = true;

    body.appendChild(script);
  }
  */

  async ngOnInit() {
    /*await this.reportDesignerScriptStore.scriptPrep(this.baseUrl);
    await this.reportDesignerScriptStore
      .load('telerikReportViewer', 'webReportDesigner')
      .then(data => {console.log('script loaded ', data);})
      .catch(error => console.log(error));

    this.designer = $("#webReportDesigner").telerik_WebReportDesigner({
      serviceUrl: this.baseUrl,
      toolboxArea: {
        layout: "list"
      },
    }).data("telerik_WebDesigner");*/
  }

  ngOnDestroy(): void {
    /*var webReportDesignerTheme = $("link[href*='ext_styles/webReportDesignerTheme']");
    var webReportDesigner = $("link[href*='styles/webReportDesigner']");
    var fonticons = $("link[href*='font/fonticons']");

    //Telerik Web Report Designer loads all required styles when the widget is loaded.
    //In order to prevent them from dublication, remove the last instance
    if (webReportDesignerTheme.length > 1) {
      webReportDesignerTheme.last().remove();
    }

    if (webReportDesigner.length > 1) {
      webReportDesigner.last().remove();
    }

    if (fonticons.length > 1) {
      fonticons.last().remove();
    }*/
  }
}

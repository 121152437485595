<persondepartment-header></persondepartment-header>
<h1>Подразделения</h1>

<div class="m-b15 m-t18 align-r">
    <button kendoButton type="button" icon="download" size="small"  (click)="exportToExcel(grid)">
      <kendo-loader *ngIf="loaderExcelExport" size="small" type="converging-spinner"> </kendo-loader>
        Экспорт в Excel
    </button>
</div>

<kendo-grid #grid="kendoGrid"
        [kendoGridBinding]="departments"
        [loading]="loading"
        [sortable]="true"
        [filterable]="true"
        (filterChange)="filterChange($event)"
        (sortChange)="sortChange($event)"
        [pageable]="true"
        [pageSize]="pageSize"
        [skip]="skip"
        [pageable]="{
            buttonCount: buttonCount,
            info: info,
            type: type,
            pageSizes: pageSizes,
            previousNext: previousNext,
            position: position
          }"
        (edit)="editHandler($event)"
        >

    <ng-template kendoGridToolbarTemplate [position]="positionEdit" *ngIf="isInEditingMode">
        <div *ngIf="isInEditingMode">
            <button kendoButton class="m-r10"
                    (click)="cancelHandler()">
            Отмена
            </button>
            <button kendoButton
                    themeColor="primary"
                    [disabled]="formGroup!.invalid || empty == true"
                    (click)="saveCurrent()"
                    >
            Сохранить
            </button>
        </div>
    </ng-template>

    <kendo-grid-command-column  [sticky]="true" title="" [width]="50" >
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
            <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full" *ngIf="rowIndex !== editedRowIndex">
            </button>
        </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column field="name"
                       title="Название подразделения"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.name }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="sName"
                       title="Сокр.назв."
                       [width]="150"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-textbox [formControl]="formGroup.get('sName')" class="editRecord" [(ngModel)]="sNameEdit" (valueChange)="onChange($event)">
            </kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.sName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="typeName"
                       title="Тип подразделения"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="filterTypes" textField="typeName" valueField="typeName">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-combobox
            [data]="types"
            textField="typeName"
            valueField="externalId"
            [valuePrimitive]="true"
            [formControl]="formGroup.get('typeId')"
            class="editRecord"
            [(ngModel)]="typeNameEdit">
            </kendo-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.typeName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="externalId"
                       title="ID"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.externalId }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="createdAt"
                       title="Дата создания"
                       [width]="200"
                       filter="date" format="dd.MM.yyyy"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.createdAt? (dataItem.createdAt | date: 'dd.MM.yyyy') : '' }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="updatedAt"
                       title="Дата изменения"
                       [width]="200"
                       filter="date" format="dd.MM.yyyy"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.updatedAt? (dataItem.updatedAt | date:'dd.MM.yyyy') : '' }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="commonStatus"
                       title="Статус"
                       [width]="150"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [useVirtual]='false'  [filter]="filter" [data]="statuses" textField="statusName" valueField="commonStatus">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-combobox
            [data]="statuses"
            textField="statusName"
            valueField="commonStatus"
            [valuePrimitive]="true"
            [formControl]="formGroup.get('commonStatus')"
            class="editRecord">
            </kendo-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                <div >{{ Status(dataItem.commonStatus) }} </div>
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="parentDepartmentName"
                       title="Входит в подразделение"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="parentDepartment" textField="parentDepartmentName" valueField="parentDepartmentName">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-combobox
            [data]="parentDepartment"
            textField="parentDepartmentName"
            valueField="parentDepartmentId"
            [valuePrimitive]="true"
            [formControl]="formGroup.get('parentDepartmentId')"
            class="editRecord"
            [(ngModel)]="parentDepartmentEdit">
            </kendo-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.parentDepartmentName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="filial"
            title="Филиал"
            [width]="200"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="filials" textField="filialShortName" valueField="filialShortName">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-combobox
                    [data]="filials"
                    textField="filialShortName"
                    valueField="filialId"
                    [valuePrimitive]="true"
                    [formControl]="formGroup.get('filialId')"
                    class="editRecord"
                    [(ngModel)]="filialIdEdit">
            </kendo-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells centerAlignment reset" >
                {{ dataItem.filialShortName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="serialNumber"
                       title="Порядковый номер для сортировки при отображении"
                       [width]="200"
                       headerClass="gridHeader dictionaries">

        <ng-template kendoGridEditTemplate
                     let-dataItem="dataItem"
                     let-formGroup="formGroup">
            <span class="alignCells">
                <kendo-numerictextbox
                    [style.width.px]="170"
                    [value]="dataItem.serialNumber"
                    [min]="1"
                    format="n0"
                    [decimals]="0"
                    [formControl]="formGroup.get('serialNumber')">
                </kendo-numerictextbox>
            </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{dataItem.serialNumber}}
                  </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-excel fileName="Подразделения.xlsx" [fetchData]="allData"></kendo-grid-excel>
</kendo-grid>

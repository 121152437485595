<kendo-stacklayout [gap]="20" orientation="vertical" class="settings">
  <h2 class="settings__title">
    {{ disciplineInfo.disciplineName }}
  </h2>
  <div class="settings__item">
    <p class="settings__text--bold">Группа {{ groupName }}</p>
    <p class="settings__text">
      Профиль (программа): {{ disciplineInfo.profileDiscipline }}</p>
  </div>
  <div class="settings__item">
    <p class="settings__text">Отображать сводную ведомость за:</p>
    <app-togglestep
      [toggleStep]="toggleStep"
      (toggleStepChange)="onToggleClick($event)"
    ></app-togglestep>
  </div>
  <div class="settings__item">
    <p class="settings__text">Печать ведомости:</p>
    <app-currentcontrolsignerparams
      [signer]="signer"
      [semesterNum]="semesterNumber"
      [eduGroupId]="eduGroupId"
      [editable]="editable"
      [facultyId]="facultyId"
      [trainingLevelId]="trainingLevelId"
      [disciplineId]="disciplineId"
      [electiveDisciplineId]="disciplineInfo.electiveDisciplineId || ''"
      (signerPostChange)="onChangeSignerPost($event)"
      (signerChange)="onChangeSigner($event)"
    ></app-currentcontrolsignerparams>
  </div>
  <div class="settings__item">
    <kendo-label text="Преподаватель" class="settings__text">
      <kendo-autocomplete
        [disabled]="!editable"
        [data]="personsToFilter"
        valueField="fio"
        placeholder="Введите ФИО сотрудника..."
        [clearButton]="true"
        [filterable]="true"
        [value]="examiner?.fio || ''"
        (filterChange)="handleFilter($event)"
        (valueChange)="onChangeFindSigner($event)"
        class="settings__input settings__input--large"
      >
      </kendo-autocomplete>
    </kendo-label>
  </div>
  <div class="settings__item">
    <div class="buttons">
      <button kendoButton (click)="onPrintStatement(true)" class="buttons__action">
        Пустая
        <kendo-loader *ngIf="loadingStatementEmpty" size="small"></kendo-loader>
      </button>
      <button kendoButton (click)="onPrintStatement(false)" class="buttons__action">
        Заполненная
        <kendo-loader *ngIf="loadingStatementFull" size="small"></kendo-loader>
      </button>

      <button
        kendoButton
        (click)="closeTable()"
        class="buttons__action buttons__action--right"
      >
        Закрыть
      </button>
    </div>
  </div>
</kendo-stacklayout>

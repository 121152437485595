import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DictWorkCategorySchedule } from 'src/app/models/education/dictWorkCategorySchedule.model';
import { DictWorkCategoryScheduleService } from 'src/app/services/education/DictWorkCategorySchedule/dict-work-category-schedule.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'dict-work-category-schedule',
  templateUrl: './dict-work-category-schedule.component.html',
  styleUrls: ['./dict-work-category-schedule.component.scss']
})
export class DictWorkCategoryScheduleComponent implements OnInit {
  public enumModels: EnumModel[] = [];
  public gridStyle = 'grid-default';
  public editable?: boolean;
  public isOpened: boolean = false;
  public loading: boolean = false;
  public isNew: boolean = false;
  public workCategorySchedules!: DictWorkCategorySchedule[];

  constructor(private workCategoryScheduleService: DictWorkCategoryScheduleService,
    private notificationService: NotificationsService,
    private userAccessService:EducationUserAccessService) { }

  ngOnInit(): void {
    this.getAccessLevel();
    for (var i = 1; i <= Object.values(this.enumTypes).length; i++) {
      this.enumModels.push(new EnumModel(Object.values(this.enumTypes)[i - 1], i))
    }
    this.getAllWorkCategorySchedules();
  }

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictWorkCategoryScheduleId: new FormControl(0),
    dictWorkCategoryScheduleExternalId: new FormControl(""),
    dictWorkCategoryScheduleName: new FormControl(""),
    dictWorkCategoryScheduleShortName: new FormControl(""),
    dictWorkCategoryScheduleInternalName: new FormControl(""),
    dictWorkCategoryScheduleInternalNameCode: new FormControl()
  });

  public enumTypes = {
    1: 'Учебные занятия по дисциплинам',
    2: 'Промежуточная аттестация',
    3: 'Учебная практика',
    4: 'Производственная практика, в т.ч. преддипломная',
    5: 'Каникулы и нерабочие праздничные дни',
    6: 'Государственная  итоговая аттестация',
    7: 'Распределенная учебная практика',
    8: 'Распределенная производственная практика',
    9: 'Праздничная неделя',
    10: 'Научно-исследовательская работа',
  };

  //Getting all entries from DictWorkCategorySchedules
  public getAllWorkCategorySchedules() {
    this.loading = true;
    this.workCategoryScheduleService.getAllWorkCategorySchedules()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.workCategorySchedules = response;
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить категории работ графика");
        }
      });
  }

  public getNameTypeById(id: number) {
    return this.enumModels.find((x) => x.enumId == id)?.name;
  }

  public saveWorkCategorySchedule() {
    // Add new WorkCategorySchedule
    if (this.isNew) {
      this.workCategoryScheduleService.addWorkCategorySchedule(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Категория работ графика была успешно добавлена!");
              this.getAllWorkCategorySchedules();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError("Не удалось добавить категорию работ графика");
              this.onCancel();
            }
          });
    }
    // Edit WorkCategorySchedule
    else {
      this.workCategoryScheduleService.updateWorkCategorySchedule(this.editForm?.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Категория работ графика была успешно сохранена!");
              this.getAllWorkCategorySchedules();
              this.onCancel();
            },
            error: () => {
              this.notificationService.showError('Не удалось добавить категорию работ графика');
              this.onCancel();
            }
          })
    }
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }
}

const formGroup =
    (dataItem: {
      dictWorkCategoryScheduleId?: any;
      dictWorkCategoryScheduleExternalId?: any;
      dictWorkCategoryScheduleName?: any;
      dictWorkCategoryScheduleShortName?: any;
      dictWorkCategoryScheduleInternalName?: any;
      dictWorkCategoryScheduleInternalNameCode?: any
    }) =>
    new FormGroup({
      dictWorkCategoryScheduleId: new FormControl(dataItem.dictWorkCategoryScheduleId),
      dictWorkCategoryScheduleExternalId: new FormControl(dataItem.dictWorkCategoryScheduleExternalId),
      dictWorkCategoryScheduleName: new FormControl(dataItem.dictWorkCategoryScheduleName),
      dictWorkCategoryScheduleShortName: new FormControl(dataItem.dictWorkCategoryScheduleShortName),
      dictWorkCategoryScheduleInternalName: new FormControl(dataItem.dictWorkCategoryScheduleInternalName),
      dictWorkCategoryScheduleInternalNameCode: new FormControl(dataItem.dictWorkCategoryScheduleInternalNameCode),
    });

export class EnumModel {
  name: string = '';
  enumId: number = 0;

  constructor(thisname: string, thisenumId: number) {
    this.name = thisname,
    this.enumId = thisenumId
  }
}
<div class="container">
  <div class="section">
    <h3>Авторы</h3>
    <button class="addButton" *ngIf="!isStaticPropertyDisabled()" kendoButton (click)="addAuthor()" themeColor="success" icon="plus">
      Добавить
    </button>
    <kendo-grid [kendoGridBinding]="authorsView"
                #authorsGrid
                [loading]="loading"
                (edit)="onEditAuthor($event)"
                (remove)="onRemoveAuthor($event)">

      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column field="number" [width]="5" headerClass="gridHeader" title="">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <span class="alignCells">
            {{rowIndex + 1}}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [width]="5" headerClass="gridHeader" title="">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" *ngIf="authorsView.length > 1">
          <div class="reorderButton reorderButton_up"
               (click)="reorderRow(rowIndex, rowIndex - 1)">
          </div>
          <div class="reorderButton reorderButton_down"
               (click)="reorderRow(rowIndex, rowIndex + 1)">
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="fio" [width]="35" headerClass="gridHeader" title="ФИО">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{getFIO(dataItem)}}
          </span>
        </ng-template>

      </kendo-grid-column>

      <kendo-grid-column field="type" [width]="25" headerClass="gridHeader" title="Тип автора">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{getAuthorTypeName(dataItem.typeId)}}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="affiliation" [width]="25" headerClass="gridHeader" title="Аффилиация">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells rows" *ngFor="let affiliation of dataItem.affiliations">
            {{affiliation.name}}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="role" [width]="30" headerClass="gridHeader" title="Роль в публикации">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{getAuthorRoleName(dataItem.roleId)}}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="!isStaticPropertyDisabled()" headerClass="gridHeader" [width]="20">
        <ng-template kendoGridCellTemplate>
          <span class='alignCells rightAlignment'>
            <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="large" class="custom-size" rounded="full">
            </button>
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="large" class="custom-size"
                    rounded="full">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>
  </div>

  <div class="section">
    <div class="row">
      <h3>Разделы тематического рубрикатора ГРНТИ</h3>
      <span class="required">*</span>
    </div>
    <button class="addButton" *ngIf="!isStaticPropertyDisabled()" kendoButton (click)="addRow()" themeColor="success" icon="plus">
      Добавить
    </button>
    <kendo-grid [kendoGridBinding]="thematicRubricatorsView"
                #rubricatorsGrid
                [loading]="loading"
                (remove)="onRemoveRubricator($event)">

      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <kendo-grid-column field="number" [width]="10" headerClass="gridHeader" title="">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <span class="alignCells">
            {{rowIndex >= 0 ? rowIndex + 1 : null}}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="name" headerClass="gridHeader" title="Рубрика" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{getThematicRubricatorName(dataItem)}}
          </span>
        </ng-template>

        <ng-template kendoGridEditTemplate>
          <div class="table__cell table__cell--custom table__cell--input" [formGroup]="formGroup">
            <kendo-dropdowntree kendoDropDownTreeExpandable
                                #dropDownTree
                                [data]="thematicRubricatorsTree"
                                formControlName="item"
                                [filterable]="true"
                                (valueChange)="onRubricatorChange($event)"
                                (close)="onDropDownTreeClose($event)"
                                [listHeight]="550"
                                textField="name"
                                valueField="id"
                                placeholder="Нажмите и выберите рубрикатор ГРНТИ из выпадающего списка"
                                [fetchChildren]="children"
                                [hasChildren]="hasChildren"
                                (filterChange)="onFilterChange($event)">
              <ng-template kendoDropDownTreeHeaderTemplate>
                <span [class]="{
                                  'k-clear-value': true,
                                  'k-display-none': dropDownTree.filter === ''
                              }"
                      (click)="onFilterClearBtnClick()">
                  <span class="k-icon k-i-x"></span>
                </span>
              </ng-template>
            </kendo-dropdowntree>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngIf="!isStaticPropertyDisabled()" headerClass="gridHeader" [width]="15">
        <ng-template kendoGridCellTemplate>
          <span class='alignCells rightAlignment'>
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="large" class="custom-size"
                    rounded="full">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>
  </div>
</div>

<h1 *ngIf="!id">Добавление сотрудника</h1>
<h1 *ngIf="id">Редактирование сотрудника</h1>

<!-- step1-->
<div>
    <div>
        <div class="unit" *ngIf="id !== ''">
            <div class="lable h-34"> ID </div>
            <div class="lable h-34 w-500">
                {{ id }}
            </div>
        </div>

        <form [formGroup]="Form1" novalidate>
        <div class="unit">
            <div class="lable"> ФИО </div>
            <div class="input-data w-230">
                <kendo-textbox
                    [disabled]="step2 || method1C"
                    [clearButton]="true"
                    placeholder="Фамилия"
                    formControlName="lastName"
                    [(ngModel)]="personName.lastName"
                    >
                </kendo-textbox>
            </div>
            <span class="required m-l5">*</span>

            <div class="input-data w-230 m-l20">
                <kendo-textbox
                    [disabled]="step2 || method1C"
                    [clearButton]="true"
                    placeholder="Имя"
                    formControlName="firstName"
                    [(ngModel)]="personName.firstName"
                    >
                </kendo-textbox>
            </div>
            <span class="required m-l5">*</span>
        </div>
        </form>

        <div class="unit">
            <div class="lable"> </div>
            <div class="input-data w-230">
                <kendo-textbox
                    [disabled]="step2 || method1C"
                    [clearButton]="true"
                    placeholder="Отчество"
                    [(ngModel)]="personName.middleName"
                    >
                </kendo-textbox>
            </div>
        </div>

        <div class="unit h-42">
            <div class="lable"> Пол </div>
            <div class="w-230 male">
                <input class="m-r10"
                    [disabled]="step2 || method1C"
                    type="radio"
                    name="gender"
                    value="true"
                    #man
                    [(ngModel)]="male.gender"
                    kendoRadioButton
                />
                <kendo-label class="m-r15" [for]="man" text="Мужской"></kendo-label>

                <input class="m-r10"
                    [disabled]="step2 || method1C"
                    type="radio"
                    name="gender"
                    value="false"
                    #female
                    [(ngModel)]="male.gender"
                    kendoRadioButton
                />
                <kendo-label [for]="female" text="Женский"></kendo-label>
            </div>
        </div>

      <div class="unit" *ngIf="method1C">
        <div class="lable h-34"> СНИЛС </div>
        <div class="lable h-34 w-500">
          {{snils ? snils: "-"}}
        </div>
      </div>

        <form [formGroup]="Form1" novalidate>
        <div class="unit">
            <div class="lable"> Должность </div>
            <div class="input-data w-500">
                <kendo-combobox
                    [data]="filterPosts"
                    textField="postName"
                    valueField="postName"
                    [disabled]="step2 || method1C"
                    [filterable]="true"
                    (filterChange)="postFilter($event)"
                    [valuePrimitive]="true"
                    (open)="changePost()"
                    formControlName="post"
                    name="post"
                    [(ngModel)]="personSalaries.post"
                >
                </kendo-combobox>
            </div>
            <span class="required m-l5">*</span>
        </div>

        <div class="unit h-42">
            <div class="lable"> Структурное подразделение </div>
            <div class="input-data w-500">
                <kendo-combobox
                    [data]="filterDepartments"
                    textField="name"
                    valueField="name"
                    [disabled]="step2 || method1C"
                    [filterable]="true"
                    (filterChange)="departmentFilter($event)"
                    [valuePrimitive]="true"
                    formControlName="department"
                    name="department"
                    [(ngModel)]="personSalaries.department"
                >
                </kendo-combobox>
            </div>
            <span class="required m-l5">*</span>
        </div>

        <div class="unit">
            <div class="lable"> Логин </div>
            <div class="input-data w-500">
                <kendo-textbox
                    [disabled]="step2"
                    [clearButton]="true"
                    formControlName="login"
                    [(ngModel)]="login"
                    >
                </kendo-textbox>
            </div>
            <span class="required m-l5">*</span>
        </div>
        </form>

        <div class="unit">
            <div class="lable h-34"> Способ добавления </div>
            <div class="lable h-34 w-500" *ngIf="id == ''">
                Вручную
            </div>
            <div class="lable h-34 w-500" *ngIf="id !== ''">
                {{ recordSourceName }}
            </div>
        </div>

        <form [formGroup]="Form1" novalidate *ngIf="id">
        <div class="unit">
            <div class="lable"> Статус </div>
            <div class="input-data w-500">
                <kendo-dropdownlist
                    [data]="statuses"
                    textField="statusName"
                    valueField="statusId"
                    [disabled]="step2 || method1C"
                    [valuePrimitive]="true"
                    name="status"
                    formControlName="commonStatus"
                    (valueChange)="valueChangeStatus($event)"
                    [(ngModel)]="commonStatus"
                >
                </kendo-dropdownlist>
            </div>
            <span class="required m-l5">*</span>
        </div>
        </form>
    </div>
    <div class="buttons m-t20" *ngIf="!step2 && id == ''">
        <button kendoButton
                class="white m-r20"
                [routerLink]="['/technicaladministators/cadrebase']"
        >
        Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]='Form1!.invalid'
                (click)="stepTow()"
        >
        Далее
        </button>
    </div>
</div>

<!-- step2-->

<div *ngIf="step2 || (id !== '' && method1C == false)">
    <h1>Основные сведения</h1>
    <div>
        <div class="unit">
            <div class="lable"> Дата рождения </div>
            <form [formGroup]="Form2" novalidate>
            <div class="input-data w-166">
                <kendo-datepicker
                    class="datepicker"
                    formControlName="birthday"
                    format="dd.MM.yyyy"
                    [(ngModel)]="birthday"
                    >
                </kendo-datepicker>
            </div>
            </form>

            <span class="required m-l5">*</span>

            <div class="lable w-136 m-r10"> Табельный номер </div>
            <div class="input-data w-166">
                <kendo-textbox
                    [clearButton]="true"
                    [(ngModel)]="buhCode"
                    >
                </kendo-textbox>
            </div>
        </div>

      <div class="unit">
        <div class="lable"> СНИЛС </div>
        <form [formGroup]="Form2" novalidate>
          <div class="input-data w-500">
            <kendo-maskedtextbox [mask]="mask"
                                 formControlName="snils"
                                 name="snils"
                                 [(ngModel)]="snils">
            </kendo-maskedtextbox>
          </div>
        </form>
      </div>

        <div class="unit">
            <div class="lable"> Ученая степень </div>
            <div class="input-data w-500">
                <kendo-combobox
                    [data]="filterDegrees"
                    textField="scientificDegreeName"
                    valueField="scientificDegreeName"
                    [valuePrimitive]="true"
                    [filterable]="true"
                    (filterChange)="degreeFilter($event)"
                    name="scientificDegree"
                    [(ngModel)]="personScientificDegrees.scientificDegree"
                >
            </kendo-combobox>
            </div>
        </div>

        <div class="unit"  *ngFor="let item of experiences; let i = index">
            <div class="lable"> {{item.experienceType}} </div>
            <div class="experience w-500">
                <kendo-numerictextbox class="m-r10 w-107"
                    [min]="0" format="0"
                    [decimals]="0"
                    [max]="100"
                    [step]="1"
                    (valueChange)="onChangeYears($event, i)"
                    name="years"
                    [(ngModel)]="item.years">
                </kendo-numerictextbox>
                <kendo-label class="m-r15" text="лет"></kendo-label>

                <kendo-numerictextbox class="m-r10 w-107"
                    [min]="0" format="0"
                    [decimals]="0"
                    [max]="11"
                    [step]="1"
                    (valueChange)="onChangeMonths($event, i)"
                    name="months"
                    [(ngModel)]="item.months">
                </kendo-numerictextbox>
                <kendo-label class="m-r15" text="месяцев"></kendo-label>

                <kendo-numerictextbox class="m-r10 w-107"
                    [min]="0" format="0"
                    [decimals]="0"
                    [max]="30"
                    [step]="1"
                    (valueChange)="onChangeDays($event, i)"
                    name="days"
                    [(ngModel)]="item.days">
                </kendo-numerictextbox>
                <kendo-label class="m-r15" text="дней"></kendo-label>
            </div>
        </div>

        <form [formGroup]="Form2" novalidate>
        <div class="unit">
            <div class="lable"> Тип ставки </div>
            <div class="input-data w-500">
                <kendo-dropdownlist
                    [data]="types"
                    textField="salaryTypeName"
                    valueField="salaryTypeName"
                    [valuePrimitive]="true"
                    formControlName="salaryType"
                    name="type"
                    [(ngModel)]="personSalaries.salaryType"
                >
                </kendo-dropdownlist>
            </div>
            <span class="required m-l5">*</span>
        </div>

        <div class="unit">
            <div class="lable"> Ставка </div>
            <div class="input-data w-500">
                <kendo-numerictextbox
                    [min]="0"
                    [spinners]="false"
                    format="n2"
                    [max]="1"
                    formControlName="salary"
                    [(ngModel)]="personSalaries.salary"
                ></kendo-numerictextbox>
            </div>
            <span class="required m-l5">*</span>
        </div>
        </form>

        <div class="unit">
            <div class="lable"> Основное место работы </div>
            <div class="w-500">
                <kendo-label
                    class="k-checkbox-label"
                    [for]="job"
                ></kendo-label>
                <input type="checkbox" #job kendoCheckBox [(ngModel)]="personSalaries.isMainJob" />
            </div>
        </div>

        <div class="unit">
            <div class="lable"> Основная ставка </div>
            <div class="w-500">
                <kendo-label
                    class="k-checkbox-label"
                    [for]="basicRate"
                ></kendo-label>
                <input type="checkbox" #basicRate kendoCheckBox [(ngModel)]="personSalaries.isOurPerson" />
            </div>
        </div>
    </div>
</div>

<div class="buttons m-t20 m-b30" *ngIf="step2 || id !== ''">
    <button kendoButton
            class="white m-r20"
            [routerLink]="['/technicaladministators/cadrebase']"
    >
    Отмена
    </button>
    <button kendoButton *ngIf="id == ''"
            class="white m-r20"
            (click)="stepOne()"
    >
    Предыдущий шаг
    </button>
    <!--вручную-->
    <button kendoButton *ngIf="!method1C"
            themeColor="primary"
            [disabled]="Form1!.invalid || Form2!.invalid"
            (click)="save()"
    >
    Сохранить
    </button>
    <!--1C-->
    <button kendoButton *ngIf="method1C"
            themeColor="primary"
            [disabled]="Form1!.invalid"
            (click)="save()"
    >
    Сохранить
    </button>
</div>

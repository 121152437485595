import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PagerType} from '@progress/kendo-angular-pager';
import {PagerPosition, GridComponent, CellClickEvent, GridModule} from '@progress/kendo-angular-grid';
import {DateFromUTCAsLocal, getDayStart, TimeZoneFix, DateToString} from 'src/app/helpers/date-helper';
import {DiplomsListModel, saveMassData} from 'src/app/models/gia/diploms.model';
import {DiplomaTableService} from 'src/app/services/gia/diplomatable.service';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {FormationDiplomaService} from '../../../../services/gia/formationdiploma.service';
import {FillStatusesModel} from '../../../../models/gia/formationdiploma.model';
import {fillingStatusEnum} from '../../../../models/gia/enums/fillingStatus.enum';
import {PrintTemplateDiplomaModel} from '../../../../models/gia/formationdiploma.model';

import {massDataEnum} from 'src/app/models/gia/enums/massData.enum';
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";
import {GiaTabsEnum} from "../../../../models/gia/enums/giaTabs.enum";
import {GiaSectionFilter} from "../../../../models/useraccess/gia/giaUserAccess";
import { type } from 'jquery';

@Component({
    selector : 'app-diplomatable',
    templateUrl: './diplomatable.component.html',
    styleUrls : ['./diplomatable.component.scss']
})

export class DiplomTableComponent implements OnInit{

    public standardId: string = "";

    public diploms: DiplomsListModel[] = [];
    public editable: boolean = false;

    public loading: boolean = false;
    public dateEdit: boolean = false;

    public educationDocTypes: Array<{previousEducationDocTypeName: string}> = [];
    public diplomTypes: Array<{diplomaTypeName: string}> = [];
    public statuses: Array<{dictStatusName: string}> = [];
    public fillingStatuses: Array<{dictFillingDiplomaStatusName: string}> = [];

    public massData: boolean = false;
    public allItemsCheckedDate: boolean = false;
    public allItemsCheckedStatus:boolean = false;
    public allItemsCheckedSeries: boolean = false;
    public allItemsCheckedNumber:boolean = false;

    public arrayDate: string[] = [];
    public arrayStatus: string[] = [];
    public arraySeries: string[] = [];
    public arrayNumber: string[] = [];

    public pageSizes = [10, 20, 50];
    public pageSize = 20;
    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public previousNext = true;
    public position: PagerPosition = 'bottom';
    public info = true;
    public skip = 0;

    public massDataKey = massDataEnum;
    public issueDate: Date | null = null;
    public issueDateSave: Date | null = null;

    public standardName: string = "";
    public graduateYear: number = 0;
    public studyFormId: string = "";
    public facultyId: string = "";
    public trainingLevelId: string = "";

    public massFillingStatuses: FillStatusesModel[] = [];
    public issueDateAll: Date | null = null;
    public fillingStatusAll: number | null = null;
    public seriesAll: string = "";
    public numberAll: string = "";

    public patternPrints: PrintTemplateDiplomaModel[] = [];
    @ViewChild(GridComponent) private grid!: GridComponent;

    constructor(
        private activateRoute: ActivatedRoute,
        private diplomaTableService: DiplomaTableService,
        private notificationService: NotificationsService,
        private formationDiplomaService: FormationDiplomaService,
        private userAccessService: GiaUserAccessService
    ) {
        this.activateRoute.params.subscribe(params => {
            this.standardId = params["standardId"];
            const storage = localStorage.getItem('diplomas_settings');
            if (storage !== null) {
                this.standardName = JSON.parse(storage).changeGroup;
                this.graduateYear = JSON.parse(storage).yearAdmission;
                this.studyFormId = JSON.parse(storage).studyForm;
                this.facultyId = JSON.parse(storage).faculty;
                this. trainingLevelId = JSON.parse(storage).trainingLevel;
            }
            this.getDiplomas(this.standardId);
        });
    }

    async ngOnInit() {
        await this.getAccessLevel();
        this.getDiplomaIssueDate();
        this.getFillStatuses();
    }

    public getDiplomas(standardId: string) {
        this.diploms = [];
        this.loading = true;
        this.diplomaTableService.getDiploms(standardId, this.graduateYear, this.studyFormId, this.facultyId, this. trainingLevelId, this.massData)
          .subscribe(response => {
            if (response) this.diploms = response;
            this.loading = false;

            for (let i=0; i< this.diploms.length; i++) {
                if (this.diploms[i].diplomaIssueDate) this.diploms[i].diplomaIssueDate = getDayStart(DateFromUTCAsLocal(this.diploms[i].diplomaIssueDate));
            }

            if (!this.massData && this.diploms.length !== 0) this.getListsForFilter();
          })
    }

    public async getAccessLevel() {
      this.editable = await this.userAccessService.hasEditingGia( {
        educationStandardId: this.standardId,
        dictStudyFormId: this.studyFormId,
        facultyId: this.facultyId,
        section: GiaTabsEnum.дипломы
      });
    }

    public getListsForFilter() {
        const docType = [...new Set(this.diploms.filter(el => el.previousEducationDocTypeName !== null).map(item => item.previousEducationDocTypeName))];
        this.educationDocTypes = docType.map(function(value) {
            return {previousEducationDocTypeName: value};
        });

        const type = [...new Set(this.diploms.filter(el => el.diplomaTypeName !== null).map(item => item.diplomaTypeName))];
        this.diplomTypes = type.map(function(value) {
            return {diplomaTypeName: value};
        });

        const status = [...new Set(this.diploms.filter(el => el.dictStatusName !== null).map(item => item.dictStatusName))];
        this.statuses = status.map(function(value) {
            return {dictStatusName: value};
        });

        const fillingStatus = [...new Set(this.diploms.filter(el => el.dictFillingDiplomaStatusName !== null).map(item => item.dictFillingDiplomaStatusName))];
        this.fillingStatuses = fillingStatus.map(function(value) {
            return {dictFillingDiplomaStatusName: value};
        });
    }

    public getDiplomaIssueDate() {
        this.diplomaTableService.getDiplomaIssueDate(this.standardId)
            .subscribe(response => {
                if (response !== null) this.issueDate = DateFromUTCAsLocal(response);
            })
    }

    public getFillStatuses() {
        this.formationDiplomaService.getFillStatuses()
            .subscribe(response => {
                this.massFillingStatuses = response;
            })
    }

    public changeDate() {
        this.dateEdit = true;
        this.issueDate ? this.issueDateSave = DateFromUTCAsLocal(this.issueDate) : null;
    }

    public saveDate() {
        if (!this.issueDateSave) {
            this.notificationService.showError('Выберите дату выдачи дипломов');
            return;
        }

        this.diplomaTableService.saveDiplomsIssueDate(this.standardId, new Date(TimeZoneFix(this.issueDateSave).toISOString()))
            .subscribe(response => {
                this.dateEdit = false;
                this.notificationService.showSuccess('Успешно');
                this.getDiplomaIssueDate();
                this.getDiplomas(this.standardId);
            })
    }

    public changeMassData() {
        if (this.grid.filter && this.grid.filter.filters.length !== 0 ) {
            this.notificationService.showError('Для перехода к массовой смене данных отмените фильтрацию в таблице.');
            return;
        }

        this.massData = true;
        this.getDiplomas(this.standardId);
    }

    public dataDiploms() {
        this.massData = false;
        this.getDiplomas(this.standardId);

        this.arrayDate = [];
        this.arrayStatus = [];
        this.arraySeries = [];
        this.arrayNumber = [];
        this.allItemsCheckedDate = false;
        this.allItemsCheckedStatus = false;
        this.allItemsCheckedSeries = false;
        this.allItemsCheckedNumber = false;
        this.issueDateAll = null;
        this.fillingStatusAll = null;
        this.seriesAll = "";
        this.numberAll = "";
    }

    public selectAll(checked: boolean, fieldName: string) {
        switch(fieldName) {
            case this.massDataKey.date:
                this.allItemsCheckedDate = !this.allItemsCheckedDate;
                this.allItemsCheckedDate ? this.arrayDate = this.diploms.map(el => el.studentId) : this.arrayDate = [];
                break;
            case this.massDataKey.status:
                this.allItemsCheckedStatus = !this.allItemsCheckedStatus;
                this.allItemsCheckedStatus ? this.arrayStatus = this.diploms.map(el => el.studentId) : this.arrayStatus = [];
                break;
            case this.massDataKey.series:
                this.allItemsCheckedSeries = !this.allItemsCheckedSeries;
                this.allItemsCheckedSeries ? this.arraySeries = this.diploms.map(el => el.studentId) : this.arraySeries = [];
                break;
            case this.massDataKey.number:
                this.allItemsCheckedNumber = !this.allItemsCheckedNumber;
                this.allItemsCheckedNumber ? this.arrayNumber = this.diploms.map(el => el.studentId) : this.arrayNumber = [];
                break;
        }
    }


    public checked(studentId: string, fieldName: string) {
        switch(fieldName) {
            case this.massDataKey.date:
                return this.arrayDate.includes(studentId);
            case this.massDataKey.status:
                return this.arrayStatus.includes(studentId);
            case this.massDataKey.series:
                return this.arraySeries.includes(studentId);
            case this.massDataKey.number:
                return this.arrayNumber.includes(studentId);
        }
        return false;
    }

    public checkedChange(studentId: string, fieldName: string) {
        switch(fieldName) {
            case this.massDataKey.date:
                this.getArraysForMassChange(studentId, this.arrayDate);
                if (this.arrayDate.length < this.diploms.length) this.allItemsCheckedDate = false;
                if (this.arrayDate.length == this.diploms.length) this.allItemsCheckedDate = true;
                break;
            case this.massDataKey.status:
                this.getArraysForMassChange(studentId, this.arrayStatus);
                if (this.arrayStatus.length < this.diploms.length) this.allItemsCheckedStatus = false;
                if (this.arrayStatus.length == this.diploms.length) this.allItemsCheckedStatus = true;
                break;
            case this.massDataKey.series:
                this.getArraysForMassChange(studentId, this.arraySeries);
                if (this.arraySeries.length < this.diploms.length) this.allItemsCheckedSeries = false;
                if (this.arraySeries.length == this.diploms.length) this.allItemsCheckedSeries = true;
                break;
            case this.massDataKey.number:
                this.getArraysForMassChange(studentId, this.arrayNumber);
                if (this.arrayNumber.length < this.diploms.length) this.allItemsCheckedNumber = false;
                if (this.arrayNumber.length == this.diploms.length) this.allItemsCheckedNumber = true;
                break;
        }
    }

    private getArraysForMassChange(studentId: string, array: string[]) {
        const index = array.indexOf(studentId);
        index == -1 ? array.push(studentId) : array.splice(index, 1);
    }

    public cellClickHandler({
        sender,
        rowIndex,
        dataItem,
        columnIndex,
        }: CellClickEvent): void {
            if (this.massData) return;
            this.getPrintTemplateDiploma(dataItem.studentId);
    }

    public applyToAll(fieldName: string) {
        switch(fieldName) {
            case this.massDataKey.date:
                if (this.issueDateAll !== null) this.saveMassData(this.arrayDate, this.issueDateAll, this.massDataKey.date);
                break;
            case this.massDataKey.status:
                if (this.fillingStatusAll !== null) this.saveMassData(this.arrayStatus, this.fillingStatusAll.toString(), this.massDataKey.status);
                break;
            case this.massDataKey.series:
                this.saveMassData(this.arraySeries, this.seriesAll, this.massDataKey.series)
                break;
            case this.massDataKey.number:
                this.saveMassData(this.arrayNumber, this.numberAll, this.massDataKey.number)
                break;
        }
    }

    private saveMassData(arrayIds: string[], value: string | Date, paramName: string) {
        if (typeof value == 'object') value = new Date(TimeZoneFix(value).toISOString());
        const data: saveMassData = new saveMassData;
        data.studentIds = arrayIds;
        data.paramName = paramName;
        data.paramValue = value;

        this.diplomaTableService.saveMassData(data)
            .subscribe(response => {
                this.notificationService.showSuccess('Данные успешно обновлены');
                this.clearChecked(paramName);
            },
            error => {
                this.notificationService.showError(error.error);
            })
    }

    public getPrintTemplateDiploma(studentId: string) {
        this.formationDiplomaService.getPrintTemplateDiploma(studentId)
            .subscribe(response => {
                this.patternPrints = response;

                if (!this.patternPrints || this.patternPrints.length == 0) {
                    this.notificationService.showError('Для формирования диплома требуется загрузить шаблон диплома');
                    return;
                } else {
                    window.open(`gia/formatdiploma/${studentId}`);
                }
            })
    }

    public formatNumber(value: number) {
        return Math.round(value * 100) / 100;
    }

    private clearChecked(fieldName: string) {
        switch(fieldName) {
            case this.massDataKey.date:
                this.allItemsCheckedDate = false;
                this.arrayDate = [];
                break;
            case this.massDataKey.status:
                this.allItemsCheckedStatus = false;
                this.arrayStatus = [];
                break;
            case this.massDataKey.series:
                this.allItemsCheckedSeries = false;
                this.arraySeries = [];
                break;
            case this.massDataKey.number:
                this.allItemsCheckedNumber = false;
                this.arrayNumber = [];
                break;
        }
    }

    public getFormat(value: any) {
      let number: number = 0;
      let decimals: number | undefined = 0;
      let format = "";
      if (value !== undefined && value.filters.length !== 0) {
        number = value.filters[0].value;
        (number.toString().includes('.')) ? decimals = (number.toString().split('.').pop()?.length) : decimals = 0;
      }
      switch (decimals) {
        case 0:
          format = "n0";
          break;
        case 1:
          format = "n1";
          break;
        case 2:
           format = "n2";
           break;
        default:
           format = "n2";
           break;
        }
        return format;
    }
}

<contingent-header></contingent-header>
<h1>Типы справок по воинскому учёту</h1>

<div class="grid-container">
  <kendo-grid [kendoGridBinding]="militaryFormTypes"
              [pageable]="pagerSettings" [pageSize]="pageSize"
              scrollable="none"
              (cellClick)="editHandler($event)">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <div *ngIf="isInEditingMode">
        <button kendoButton
                size="small"
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()"
                size="small">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column title="№" headerClass="gridHeader dictionaries" [width]="50">
      <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
        <span class="alignCells">
          {{ rowIndex + 1 }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="name"
                       title="Тип справки"
                       headerClass="gridHeader"
                       [width]="550">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox [formControl]="formGroup.get('formName')"
                       [clearButton]="true">
        </kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ dataItem.formName }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="order"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader dictionaries"
                       [width]="200">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-numerictextbox [value]="dataItem.order"
                                [min]="1"
                                format="n0"
                                [decimals]="0"
                                [formControl]="formGroup.get('order')">
          </kendo-numerictextbox>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.order }}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

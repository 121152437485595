import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment} from 'src/environments/environment';
import { Kafedra } from 'src/app/models/education/kafedra.model';

@Injectable({
  providedIn: 'root'
})
export class KafedraService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.kafedra}`;

  constructor(private http: HttpClient) { }

  //Get all faculties
  public getAllKafedras(): Observable<Kafedra[]> {
    return this.http.get<Kafedra[]>(this.baseUrl + '/GetDepartments/');
  }

  // Add Kafedra
  addKafedra(kafedra: Kafedra): Observable<Kafedra> {
    return this.http.post<Kafedra>(this.baseUrl + '/CreateDepartment/', kafedra)
      .pipe(catchError(this.handleError));
  }

  // Edit Kafedra
  updateKafedra(kafedra: Kafedra): Observable<Kafedra> {
    return this.http.put<Kafedra>(this.baseUrl + '/UpdateDepartment/' + kafedra.kafedraId, kafedra)
      .pipe(catchError(this.handleError));
  }

  // Remove Kafedra
  deleteKafedra(id: string): Observable<Kafedra> {
    return this.http.delete<Kafedra>(this.baseUrl + '/DeleteDepartment/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

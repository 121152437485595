import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { StudentSearchQuery } from "../../../../models/gia/orders/student-search-query.model";
import { CellClickEvent, PagerSettings } from "@progress/kendo-angular-grid";
import { dictPagerSettings } from "../../../../models/dicts/pagerSettings.model";
import { ReleaseOrderService } from "../../../../services/gia/release-order.service";
import { ReleaseSearchStudents } from "../../../../models/gia/orders/release-order.model";
import { NotificationsService } from "../../../../services/notifications/notifications.service";
import { TrainingLevelEnum } from "../../../../models/gia/enums/training-level.enum";
import { GiaTabsEnum } from "../../../../models/gia/enums/giaTabs.enum";
import { GiaUserAccessService } from "../../../../services/useraccess/gia-user-access.service";

@Component({
  selector: 'app-release-order-add-student',
  templateUrl: './release-order-add-student.component.html',
  styleUrls: ['./release-order-add-student.component.scss']
})
export class ReleaseOrderAddStudentComponent implements OnInit {

  public orderId: string = "";
  public pageSize = 20;
  public loading: boolean = false;
  public pagerSettings: PagerSettings = dictPagerSettings;
  public students: ReleaseSearchStudents[] = [];
  public allItemsChecked: boolean = false;
  public arrayStudents: string[] = [];
  public trainingLevelEnum?: number;

  constructor(private activateRoute: ActivatedRoute,
              private router: Router,
              private userAccessService: GiaUserAccessService,
              private releaseOrderService: ReleaseOrderService,
              private notificationService: NotificationsService,) {
    this.orderId = activateRoute.snapshot.params["orderId"];
  }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getReleaseOrderInfo(this.orderId);
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        if (!response.giaSections?.some((_) => _ === GiaTabsEnum.приказы_о_выпуске || _ === null) && !response.isAdmin) {
          alert('Недостаточно прав для доступа на эту страницу');
          this.router.navigate([`/profile`]).then( );
        }
      },
      error: () => {},
    });
  }

  public studentSearch(query: StudentSearchQuery): void {
    this.loading = true;
    this.releaseOrderService.studentSearch(query)
      .subscribe({
        next:(response) => {
          if (response == null) {
            this.students = [];
          }
          else {
            this.students = response;
          }
          this.loading = false;
        },
        error: () =>
          this.loading = false,
      })
  }

  public getReleaseOrderInfo(orderId: string) {
    this.releaseOrderService.getOrderInfo(orderId)
      .subscribe(response => {
        this.trainingLevelEnum = response.trainingLevelEnum;
      })
  }

  public selectAll() {
    this.allItemsChecked = !this.allItemsChecked;
    this.allItemsChecked ? this.arrayStudents = this.students.map(el => el.studentId) : this.arrayStudents = [];
  }

  public cellClickHandler({ dataItem }: CellClickEvent): void {
    this.checkedChange(dataItem.studentId);
  }

  public checked(studentId: string) {
    return this.arrayStudents.includes(studentId);
  }

  public checkedChange(studentId: string) {
    this.getArraysForMassChange(studentId, this.arrayStudents);
    if (this.arrayStudents.length < this.students.length) this.allItemsChecked = false;
    if (this.arrayStudents.length == this.students.length) this.allItemsChecked = true;
  }

  private getArraysForMassChange(studentId: string, array: string[]) {
    const index = array.indexOf(studentId);
    index == -1 ? array.push(studentId) : array.splice(index, 1);
  }

  public cancel(){
    this.router.navigateByUrl(`/gia/releaseorderform/${this.orderId}`).then();
  }

  public save(){
    if (this.arrayStudents.length === 0) {
      this.notificationService.showError("Не выбран ни один обучающийся");
      return;
    }

    const order = {
      releaseOrderId: this.orderId,
      students: this.arrayStudents.map(function(v){
        return {studentId: v};
      })
    };

    this.releaseOrderService.addOrderStudents(order)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.cancel();
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }}
      );
  }

  protected readonly TrainingLevelEnum = TrainingLevelEnum;
}

import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { DateToString } from '../../../helpers/date-helper';

@Injectable({
    providedIn: 'root'
})
export class PublicationsReportsService {
    private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.publications.reports}`;

    constructor(private http: HttpClient) {}

    exportForm16(query: {periodBegin: Date | null, periodEnd: Date | null}) {
      const periodBeginString = query.periodBegin ? `?PeriodBegin=${DateToString(query.periodBegin, 'yyyy-MM-dd')}` : '';
      const periodEndString = query.periodEnd ? `PeriodEnd=${DateToString(query.periodEnd, 'yyyy-MM-dd')}` : '';
      const queryString = periodBeginString ? `${periodBeginString}&${periodEndString}`.replace(/&\s*$/, "") : `?${periodEndString}`;

        return this.http.get(`${this.baseUrl}/Form16/${queryString}`,{
            responseType: 'blob',
            observe: 'response'
        });
    }

    exportUsis(year: number) {
      return this.http.get(`${this.baseUrl}/Usis/${year}`,{
        responseType: 'blob',
        observe: 'response'
      });
    }

    exportFullReport(typeId: string, year: number, scientometricBaseIds: string[]) {
      let queryString = typeId ? `/?typeId=${typeId}` : '';
      if (scientometricBaseIds && scientometricBaseIds.length) {
        queryString = !typeId ? `${queryString}/?` : `${queryString}&`;
        queryString = `${queryString}indexes=${scientometricBaseIds.join('&indexes=')}`;
      }

      return this.http.get(`${this.baseUrl}/FullReport/${year}${queryString}`,{
        responseType: 'blob',
        observe: 'response'
      });
    }
}

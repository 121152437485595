<education-header></education-header>
<title>Категории работ графика</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Категории работ графика</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новая категория работ графика' : 'Изменить категорию работ графика' }}
  </h1>

  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" class="addButton" kendoButton [primary]="true" (click)="addHandler()" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid
            [data]="workCategorySchedules"
            [kendoGridBinding]="workCategorySchedules"
            [navigable]="true"
            [sortable]="true"
            [filterable]="true"
            [loading]="loading"
            [height]="600">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictWorkCategoryScheduleName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" style="text-align: left; justify-content: left;">
            {{dataItem.dictWorkCategoryScheduleName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictWorkCategoryScheduleShortName" title="Сокр. название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictWorkCategoryScheduleShortName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <!-- <kendo-grid-column headerClass="gridHeader dictionaries" field="dictWorkCategoryScheduleInternalName" title="Внутреннее название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictWorkCategoryScheduleInternalName}}
          </span>
        </ng-template>
      </kendo-grid-column> -->
      <kendo-grid-column field="dictWorkCategoryScheduleInternalNameCode"
                       title="Внутреннее название"
                       [width]="200"
                       headerClass="gridHeader dictionaries"
                       [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getNameTypeById(dataItem.dictWorkCategoryScheduleInternalNameCode) }}
        </span>
      </ng-template>
    </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton (click)="editHandler(dataItem)" type="button" class="editButton">Изменить</button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictWorkCategoryScheduleName" [maxlength]="255" #dictWorkCategoryScheduleName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="dictWorkCategoryScheduleShortName" [maxlength]="50" #dictWorkCategoryScheduleShortName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Внутреннее название</h2>
          <kendo-dropdownlist [data]="enumModels"
                            textField="name"
                            valueField="enumId"
                            [valuePrimitive]="true"
                            #dictWorkCategoryScheduleInternalNameCode required
                            formControlName="dictWorkCategoryScheduleInternalNameCode"></kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton (click)="onCancel()" type="button" class="cancelButton">Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveWorkCategorySchedule()">Сохранить</button>
      </div>
    </form>
  </div>
</div>
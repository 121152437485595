import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {DictPublicationSubType} from "../../../../../models/publications/dict/publicationSubType.model";
import {PublicationsSubTypesMethods} from "./publications-sub-types.methods";
import {SubTypeService} from "../../../../../services/science/publications/subTypes.service";
import {dictPagerSettings} from "../../../../../models/dicts/pagerSettings.model";
import {getData$} from "../../../../../../environments/environment";
import {DictPublicationType} from "../../../../../models/publications/dict/publicationType.model";
import {TypesService} from "../../../../../services/science/publications/types.service";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";

@Component({
  selector: 'publications-sub-types',
  templateUrl: './publications-sub-types.component.html',
  styleUrls: ['./publications-sub-types.component.scss']
})
export class PublicationsSubTypesComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  // Pager settings
  public pagerSettings: PagerSettings = dictPagerSettings;

  public isNew: boolean = false;
  public isLine: boolean = false;
  private editedRowIndex: number | undefined;
  public editable: boolean = this.userAccessService.currentUserAccess$.value.dict === AccessRights.Write;

  public publicationTypes: DictPublicationType[] = [];
  public publicationSubTypes: DictPublicationSubType[] = [];

  public formGroup: FormGroup = this.resetFormGroup;

  constructor(
    private typeService: TypesService,
    private subTypeService: SubTypeService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private subTypeMethods: PublicationsSubTypesMethods,
    private userAccessService: PublicationsUserAccessService
  ) { }

  async ngOnInit() {
    getData$.subscribe(() => this.getSubTypes())
    this.getTypes();
  }

  private get resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      typeId: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
    })
  }

  private getTypes() {
    this.typeService.getTypes().subscribe(value => this.publicationTypes = value);
  }

  private getSubTypes() {
    this.subTypeService.getSubTypes().subscribe(value => this.publicationSubTypes = value);
  }

  public getTypeName(id: string) {
    return this.publicationTypes.find((item) => item.id === id)?.name ?? "";
  }

  public onTypeChange(value: string) {
    this.formGroup.value.typeId = value;
  }

  public addRow() {
    this.formGroup = new FormGroup({
      typeId: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
    })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  public async editRow({ sender, rowIndex, dataItem, columnIndex }: CellClickEvent) {
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      typeId: new FormControl(dataItem.typeId),
      name: new FormControl(dataItem.name),
    })

    if (this.isNew || !this.editable || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.onCancel();

    this.isLine = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public async saveRow()  {
    if (this.formGroup) await this.subTypeMethods.saveSubType(this.isNew, this.formGroup);
    this.onCancel();
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}?"`);
    this.subTypeMethods.removeSubType(dialog.result, dataItem);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from "rxjs/operators";
import { StandardLimitation } from 'src/app/models/education/standardlimitation.model';
import { DictStandardLimitation } from 'src/app/models/education/dictstandardlimitation.model';

@Injectable({
  providedIn: 'root'
})
export class StandardLimitationService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.standardLimitation}`;

  constructor(private http: HttpClient) { }

  //Edit Dict Standard Limitation
  updateStandardLimitation(standardLimitations: StandardLimitation[]): Observable<StandardLimitation> {
    return this.http.put<StandardLimitation>(this.baseUrl + '/EditStandardLimitations', standardLimitations)
      .pipe(catchError(this.handleError));
  }

  public getAllNessesaryDictStandardLimitations(id: any): Observable<DictStandardLimitation[]> {
    return this.http.get<DictStandardLimitation[]>(this.baseUrl + '/GetSortedStandardLimitationsForGrouping/' + id.value.education_standard_id);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

export function Status(value:any){
    let status = "";
    if (value == 1) status="Действует";
    if (value == 2) status="Удален";
    if (value == 3) status="Неактивен";
    return status;
}

export const statuses: Array<{ statusName: string; commonStatus: number }> = [
    {statusName: "Действует", commonStatus: 1},
    {statusName: "Удален", commonStatus: 2},
    {statusName: "Неактивен", commonStatus: 3},
]
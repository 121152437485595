export enum StudyLevel {
  HighestEducation = 1,
  VocationalEducation,
  SecondaryEducation,
  BasicEducation,
  PrimaryEducation,
}

export const studyLevelEnumList: {text: string, value: StudyLevel}[] = [
  {text: 'Высшее образование', value: StudyLevel.HighestEducation},
  {text: 'Среднее профессиональное образование', value: StudyLevel.VocationalEducation},
  {text: 'Среднее общее образование', value: StudyLevel.SecondaryEducation},
  {text: 'Основное общее образование', value: StudyLevel.BasicEducation},
  {text: 'Начальное общее образование', value: StudyLevel.PrimaryEducation},
];

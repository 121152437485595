<publications-settings></publications-settings>
<h1 *ngIf="isNew">Новая публикация</h1>
<div class="status" *ngIf="!isNew">
    <h2 class="title">Статус публикации</h2>
    <h3 *ngIf="status?.name">{{status.name}}</h3>
</div>
<kendo-tabstrip (tabSelect)="onTabChange($event)" class="custom-tabs">
    <kendo-tabstrip-tab title="{{PublicationEditFormTabs.MainInfo}}"
                        [selected]="currentTab === PublicationEditFormTabs.MainInfo">
        <ng-template kendoTabContent>
            <publications-edit-form-mainInfo></publications-edit-form-mainInfo>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{PublicationEditFormTabs.AuthorsAndThematicRubrications}}"
                        [disabled]="isNew"
                        [selected]="currentTab === PublicationEditFormTabs.AuthorsAndThematicRubrications">
        <ng-template kendoTabContent>
            <publications-authors-and-thematic-rubricators></publications-authors-and-thematic-rubricators>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{PublicationEditFormTabs.Codes}}"
                        [disabled]="isNew || disableCodes"
                        [selected]="currentTab === PublicationEditFormTabs.Codes">
        <ng-template kendoTabContent>
            <publications-edit-form-codes></publications-edit-form-codes>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{PublicationEditFormTabs.About}}"
                        [disabled]="isNew"
                        [selected]="currentTab === PublicationEditFormTabs.About">
        <ng-template kendoTabContent>
            <publications-edit-form-about></publications-edit-form-about>
        </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{PublicationEditFormTabs.Comments}}"
                        [disabled]="isNew"
                        [selected]="currentTab === PublicationEditFormTabs.Comments">
        <ng-template kendoTabContent>
            <publications-edit-form-comments></publications-edit-form-comments>
        </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>
<div class="actionButtons" *ngIf="status">
    <button kendoButton (click)="onDelete()" *ngIf="status.allowDelete && !isNew">Удалить публикацию</button>
    <button kendoButton (click)="onClear()" *ngIf="currentTab === PublicationEditFormTabs.About">Очистить страницу</button>
    <button kendoButton (click)="onCopy()" *ngIf="status.allowCopy">Скопировать</button>
    <button kendoButton (click)="onClose()">Закрыть</button>
    <button class="saveButton" kendoButton (click)="onSave()"
            *ngIf="status.allowSave && !isComments"
            [themeColor]="getThemeColor(status, StatusEnum.CHECKING)">
        Сохранить и продолжить
    </button>
    <ng-container *ngFor="let info of allowedStatuses">
        <button kendoButton (click)="onChangeStatus(info.status.id)"
                *ngIf="!isNew"
                [themeColor]="getThemeColor(info.status, StatusEnum.DISAPPROVED)"
                [class]="{
                    first: info.status.name === StatusEnum.APPROVED &&
                           status.name !== StatusEnum.CHECKING &&
                           status.name !== StatusEnum.DRAFT
                }">
          {{info.description}}
        </button>
    </ng-container>
</div>

<div kendoDialogContainer></div>

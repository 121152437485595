import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AddEvent, CellClickEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { Guid } from 'guid-typescript';
import { ControlAction } from 'src/app/models/dicts/controlaction.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ControlActionService } from 'src/app/services/dicts/controlaction.service';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { isChildOf } from 'src/app/helpers/elementRef-helper';
import { Router} from "@angular/router";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { StandardType } from '../../../models/dicts/standardtype.model';
import { TrainingLevel } from '../../../models/dicts/traininglevel.model';
import { StandardtypeService } from '../../../services/dicts/standardtype.service';
import { TrainingLevelService } from '../../../services/dicts/traininglevel.service';

@Component({
  selector: 'app-controlaction',
  templateUrl: './controlaction.component.html',
  styleUrls: ['./controlaction.component.css']
})
export class ControlactionComponent implements OnInit {

  public controlActions: ControlAction[] = [];
  public controlActionsByFilter: ControlAction[] = [];
  public traininglevels!: TrainingLevel[];
  public standardTypes!: StandardType[];
  public secondFilter: any = {
    dictStandardTypeId: undefined,
    dictTrainingLevelId: undefined
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  public gridView: any | GridDataResult;

  constructor(private controlActionService: ControlActionService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private userAccessService: CommonDictionariesUserAccessService,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private router: Router,
              private trainingLevelService: TrainingLevelService,
              private standardTypeService: StandardtypeService
  ) { }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadControlAction();
  }

  private loadControlAction(): void {
    this.gridView = {
      data: orderBy(this.controlActionsByFilter, this.sort),
      total: this.controlActionsByFilter.length,
    };
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      dictControlActionId: 0,
      controlActionName: '',
      controlActionSName: '',
      controlActionAbbreviation: '',
      dictControlActionExternalId: Guid.createEmpty(),
      serialNumber: null,
      isNotUsed: false,
    });

    this.isNew = true;
    this.isLine = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem
  }: CellClickEvent): void {

    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.controlActionService.addControlAction({...this.formGroup.value, ...this.secondFilter})
          .subscribe({
            next:() => {
              this.getAllControlAction();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError("Не удалось добавить запись");
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.controlActionService.updateControlAction(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllControlAction();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError("Не удалось изменить запись");
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllControlAction() {
    this.controlActionService.getAllControlAction()
      .subscribe(
        response => {
          this.controlActions = response;
          this.applyFilter();
          this.loadControlAction();
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelEducation == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelEducation != 1;
            this.getAllStandardType();
            this.getAllTrainingLevel();
            this.getAllControlAction();
          }
        },error:() => {}});
  }

  public defaultItemTrainingLevel: { trainingLevelName: string; dictTrainingLevelExternalId?: any } = {
    trainingLevelName: "Не выбрано",
    dictTrainingLevelExternalId: null,
  };

  public defaultItemStandardType: { standardTypeShortName: string; standardTypeExternalId?: any } = {
    standardTypeShortName: "Не выбрано",
    standardTypeExternalId: null,
  };

  public applyFilter() {
    this.controlActionsByFilter = [];
    this.controlActionsByFilter = this.controlActions.filter(x => Guid.parse(x.standardTypeExternalId) == this.secondFilter.dictStandardTypeId &&
    Guid.parse(x.trainingLevelExternalId) == this.secondFilter.dictTrainingLevelId);
  }

  //Getting all entries from standard types
  public getAllStandardType() {
    this.standardTypeService.getAllStandardType()
      .subscribe(
        response => {
          this.standardTypes = response;
        }
      );
  }

  //Getting all entries from study levels
  public getAllTrainingLevel() {
    this.trainingLevelService.getAllTrainingLevel()
      .subscribe(
        response => {
          this.traininglevels = response;
        }
      );
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.controlActionName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.controlActionService.deleteControlAction(dataItem.dictControlActionExternalId)
          .subscribe({
            next:() => {
              this.getAllControlAction();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { dictControlActionId?: any; controlActionName?: any; controlActionSName?: any; controlActionAbbreviation?: any; dictControlActionExternalId?: any; serialNumber?: any; isNotUsed?: any }) =>
  new FormGroup({
    dictControlActionId: new FormControl(dataItem.dictControlActionId),
    controlActionName: new FormControl(dataItem.controlActionName, Validators.required),
    controlActionSName: new FormControl(dataItem.controlActionSName, Validators.required),
    controlActionAbbreviation: new FormControl(dataItem.controlActionAbbreviation),
    dictControlActionExternalId: new FormControl(dataItem.dictControlActionExternalId),
    serialNumber: new FormControl(dataItem.serialNumber, [Validators.min(1), Validators.pattern("^[0-9]+$")]),
    isNotUsed: new FormControl(dataItem.isNotUsed, Validators.required),
  });

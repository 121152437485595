import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EduGroup } from '../../models/announcement/eduGroup.model';

@Injectable({
  providedIn: 'root'
})
export class EduGroupService {

  baseUrl = `${environment.announcementApiEndpoint}${environment.apiPaths.announcement.base}`;

  constructor(private http: HttpClient) { }

  //Get all groups
  public getAllEduGroups(departmentIds: string[], courses: number[]): Observable<EduGroup[]> {
    let params = new HttpParams();
    departmentIds.forEach((departmentId: any)=> {
      if(departmentId != '') params = params.append('departmentIds', departmentId);
    })
    courses.forEach((course: any)=> {
      if(course != '')params = params.append('courses', course);
    })
    return this.http.get<EduGroup[]>(this.baseUrl + '/GetEduGroups', { params });
  }

  // Add group
  addEduGroups(eduGroup: EduGroup): Observable<EduGroup> {
    return this.http.post<EduGroup>(this.baseUrl + '/CreateEduGroup/', eduGroup)
      .pipe(catchError(this.handleError));
  }

  // Edit group
  updateEduGroups(eduGroup: EduGroup): Observable<EduGroup> {
    return this.http.put<EduGroup>(this.baseUrl + '/UpdateEduGroup/' + eduGroup.eduGroupId, eduGroup)
      .pipe(catchError(this.handleError));
  }

  // Remove group
  deleteEduGroups(id: string): Observable<EduGroup> {
    return this.http.delete<EduGroup>(this.baseUrl + '/DeleteEduGroup/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }

}

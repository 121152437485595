<contingent-header></contingent-header>
<h1>Тип справки</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="spravkaTypes" (remove)="removeHandler($event)"
              [pageable]="true"  [pageSize]="pageSize"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true">
    <ng-template kendoGridToolbarTemplate position="both"  *ngIf="isInEditingMode&&editable">
      <!--<button kendoGridAddCommand size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>-->
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()"
                size="small">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()"
                size="small">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictEmailTypeId" title="dictEmailTypeId"> </kendo-grid-column> -->
    <kendo-grid-column field="typeName"
                       title="Тип справки"
                       headerClass="gridHeader"
                       [width]="300">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [formControl]="formGroup.get('typeName')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.typeName}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="numOrder"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader"
                       [width]="200">
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-numerictextbox
            [style.width.px]="170"
            [value]="dataItem.numOrder"
            [min]="1"
            format="n0"
            [decimals]="0"
            [formControl]="formGroup.get('numOrder')">
          </kendo-numerictextbox>
        </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.numOrder}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <!--<kendo-grid-column title="" [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
                  rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>-->

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AcademicStateService } from '../../../services/contingent/academicstate.service';
import { Router } from "@angular/router";
import { items } from "../../../models/contingent/menu-items";
import { DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";

@Component({
  selector: 'contingent-header',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})

export class LayoutComponent implements OnInit{
  //title = 'ContingentWebApp';

  //Menu items
  public items: any[] = items;
  public isMainPage: boolean = this.router.url.endsWith('/contingent');

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private academicstateService: AcademicStateService,
              private router: Router,
              private jwtHelper: JwtHelperService,
              private tokenStore: TokenStorageService,
              private userAccessService: ContingentUserAccessService
  ) {
  }

    ngOnInit(): void {
      this.getAccessLevel()
    }

  public onSelect( item:any ): void {
    //if (item.path==="/contingent/academicgroupshome") {
      localStorage.removeItem('group_settings')
   //}
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.accessSetting(
            !response.userAccessLevel,
            !response.localSignatoryAccessLevel,
            response.militaryCertificateAccessLevel
          )
        },
        error:() => {
          this.accessSetting(false, false, 0)
        }}
      );
  }

  public accessSetting(response: boolean, localSignatory: boolean, militaryCertificate: number){
    // Hide admin items
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null)
    {
      user = this.jwtHelper.decodeToken(token);
    }
    let role = user.role;
    if(!role.includes(Role.Admin)) {
      this.items[0].items = this.items[0].items.filter((a: { admin: boolean; access: boolean; signatory: boolean; }) => a.admin == null
        && (response ? a.access == null : true)
        && (localSignatory ? a.signatory == null : true)
      );
      this.items[0].items[0].items = this.items[0].items[0].items.filter((a: { admin: boolean; access: boolean; signatory: boolean; }) => a.admin == null);
    }
    if (!role.includes(Role.Admin) && militaryCertificate === 0)
      this.items[0].items = this.items[0].items.filter((a: { militaryCertificate: boolean;}) => a.militaryCertificate == null);
  }
}

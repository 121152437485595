import {Component, OnInit, ViewChild} from "@angular/core";
import {GeneralDictsService} from '../../../../services/diplomablanks/general-dicts.service';
import {trainingLevelModel} from '../../../../models/diplomablanks/dicts.model';
import {pagerSettings} from 'src/app/models/diplomablanks/pagerSettings.model';
import {BlanksAvailableService} from '../../../../services/diplomablanks/blanks-available.service';
import {BlanksAvailableModel} from '../../../../models/diplomablanks/blanks-available.model';
import {DateFromUTCAsLocal, getDayStart, TimeZoneFix} from 'src/app/helpers/date-helper';
import {CompositeFilterDescriptor} from "@progress/kendo-data-query";
import {GridComponent} from '@progress/kendo-angular-grid';
import {filterBy} from "@progress/kendo-data-query";
import {arrayRewrite} from "../../../../helpers/multiselect-helper";
import {Router} from "@angular/router";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {PageChangeEvent} from "@progress/kendo-angular-grid";
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import {DiplomablanksUserAccessService} from '../../../../services/useraccess/diplomablanks-user-access.service';
import {levelAccess} from '../../../../models/diplomablanks/enum/boolOptions.enum';

@Component({
    selector: 'app-blanksavailable',
    templateUrl: './blanks-available.component.html',
    styleUrls: ['./blanks-available.component.scss']
  })

export class BlanksAvailableComponent implements OnInit {

  public availableBlanks: BlanksAvailableModel[] = [];

  public loading: boolean = false;
  protected pageSize = 20;
  protected readonly pagerSettings = pagerSettings;

  public blanksType: Array<{blankTypeName: string}> = [];
  public blankSeries: Array<{series: string}> = [];
  public blankTemplates: Array<{blankTemplateName: string}> = [];

  public trainingLevel: trainingLevelModel[] = [];
  public trainingLevelList: string[] = [];

  public findBlanks: number = 0;
  @ViewChild(GridComponent) private grid!: GridComponent;

  public deleting: boolean = false;
  public allItemsChecked: boolean = false;
  public checkedBlanks: number[] = [];
  public opened: boolean = false;

  public editable: boolean = false;

  constructor(
    private generalDictsService: GeneralDictsService,
    private blanksAvailableService: BlanksAvailableService,
    private router: Router,
    private notificationService: NotificationsService,
    private diplomablanksUserAccessService: DiplomablanksUserAccessService,
  ) { }

  public ngOnInit(): void {
    this.getUserAccess();
    this.getTraininglevel();
  }

  public getUserAccess() {
    this.diplomablanksUserAccessService.getUserAccess()
    .subscribe(response => {
      this.editable = response.userAccessRight == levelAccess.write;
    })
  }

  public getTraininglevel() {
    this.generalDictsService.getExistTrainingLevel()
      .subscribe(response => {
        this.trainingLevel = response;
        if (this.trainingLevel) {
          this.trainingLevel.unshift({externalId: "", name: 'Все'});
          this.trainingLevelList = [""];
        }
        this.getAllBlanks();
      })
  }

  public getAllBlanks() {
    this.loading = true;
    this.blanksAvailableService.getAllBlank(this.trainingLevelList.some(el => el == "") ? [null] : this.trainingLevelList)
      .subscribe(response => {
        this.loading = false;

        if (response) {
          this.availableBlanks = response;
          for (let i=0; i< this.availableBlanks.length; i++) {
            if (this.availableBlanks[i].addDate) this.availableBlanks[i].addDate = getDayStart(DateFromUTCAsLocal(this.availableBlanks[i].addDate));
          }

          const gridData = filterBy(this.availableBlanks, this.filter);
          this.findBlanks = gridData.length;
          // this.findBlanks = this.availableBlanks.length;
          this.getFiltersTable();
        } else {
          this.availableBlanks = [];
          this.findBlanks = 0;
        }
      })
  }

  private getFiltersTable() {
    const type = [...new Set(this.availableBlanks.map(el => el.blankTypeName))];
    this.blanksType = type.map(function(value) {
        return {blankTypeName: value};
    });

    const series = [...new Set(this.availableBlanks.map(el => el.series))];
    this.blankSeries = series.map(function(value) {
        return {series: value};
    });

    const template = [...new Set(this.availableBlanks.map(el => el.blankTemplateName))];
    this.blankTemplates = template.map(function(value) {
        return {blankTemplateName: value};
    });
  }


  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  public clearFilters() {
    this.filter = {filters: [], logic: "and"};
    this.findBlanks = this.availableBlanks.length;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    const gridData = filterBy(this.availableBlanks, this.filter);
    this.findBlanks = gridData.length;

    this.resetData();
  }

  public onChange(value: string[]) {
    this.trainingLevelList = arrayRewrite(value);
    this.getAllBlanks();
  }

  public blur() {
    this.getAllBlanks();
  }

  public getValue(dataItem: trainingLevelModel[]) {
    return dataItem.some(el => el.externalId == "") ? "Все" : dataItem.length + " выбрано";
  }

  public addBlanks() {
    this.router.navigate(['/diplomablanks/addblanks']);
  }

  public issueBlanks() {
    this.router.navigate(['/diplomablanks/issueblanks']);
  }

  public deleteBlanks() {
    this.deleting = true;
  }

  public cancelDeleting() {
    this.deleting = false;
    this.checkedBlanks = [];
    this.allItemsChecked = false;
  }

  public checkedChange(id: number) {
    const index = this.checkedBlanks.indexOf(id);
    index == -1 ? this.checkedBlanks.push(id) : this.checkedBlanks.splice(index, 1);

    const filterData: any = this.grid.data;
    this.checkedBlanks.length == filterData.data.length ? this.allItemsChecked = true : this.allItemsChecked = false;
  }

  public checked(id: number) {
    return this.checkedBlanks.includes(id);
  }

  public selectAll(value: boolean) {
    this.allItemsChecked = !this.allItemsChecked;
    const filterData: any = this.grid.data;
    this.allItemsChecked ? this.checkedBlanks = filterData.data.map((el: any) => el.id) : this.checkedBlanks = [];
  }

  public close(status: string): void {
    if (status == 'yes') {
      this.blanksAvailableService.deleteBlanks(this.checkedBlanks)
        .subscribe(response => {
          this.notificationService.showSuccess('Успешно');
          this.getAllBlanks();
          this.filter = {filters: [], logic: "and"};
          this.resetData();
        })
    }
    this.opened = false;
  }

  public removeBlanks() {
    this.opened = true;
  }

  public pageChange(event: PageChangeEvent): void {
    this.resetData();
  }

  private resetData() {
    if (this.deleting) {
      this.allItemsChecked = false;
      this.checkedBlanks = [];
    }
  }

  public exportList() {
    const dictBlankTemplate: any = this.filter.filters.find((el: any) => el.field == 'blankTemplateName');
    const addDate: any = this.filter.filters.find((el: any) => el.field == 'addDate');
    const data = {
      dictTrainingLevelIds: this.trainingLevelList.some(el => el == "") ? null : this.trainingLevelList,
      dictBlankTemplateId: dictBlankTemplate ? this.availableBlanks.find(el => el.blankTemplateName == dictBlankTemplate.value)?.blankTemplateId : null,
      addDate: addDate ? TimeZoneFix(addDate.value).toISOString() : null,
    }
   this.blanksAvailableService.exportList(data).subscribe({
      next: (value) => {
        DownloadFile(value);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }
}

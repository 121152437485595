import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Guid } from 'guid-typescript';
import { DictsService } from '../../../services/currentcontrol/dicts.service';
import { PersonsModel } from '../../../models/currentcontrol/persons.model';
import { SheetService } from '../../../services/currentcontrol/sheet.service';
import {
  SaveSignatoryModel,
  SignatoriesModel,
} from '../../../models/currentcontrol/sheets.model';

@Component({
  selector: 'app-currentcontrolsigneredit',
  templateUrl: './currentcontrolsigneredit.component.html',
  styleUrls: ['./currentcontrolsigneredit.component.scss'],
})
export class CurrentcontrolsignereditComponent implements OnInit {
  @Output() signerChange: EventEmitter<SignatoriesModel> =
    new EventEmitter<SignatoriesModel>();
  @Output() signerPostChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'Должность',
    personId: Guid.create(),
  } as SignatoriesModel;
  @Input() facultyId: string = '';
  @Input() trainingLevelId: string | undefined = '';
  @Input() eduGroupId: string = '';
  @Input() disciplineId: string = '';
  @Input() electiveDisciplineId: string = '';
  @Input() semesterNum: number = 0;
  @Input() personId: string = '';
  @Input() enableSync: boolean = true;

  public userList: SignatoriesModel[] = [];
  public postList: string[] = [];
  public searchSignerValue: string = '';
  public allPersons: PersonsModel[] = [];
  public personsToFilter: PersonsModel[] = [];
  public selectedPerson: PersonsModel | null = null;

  constructor(
    private dictsService: DictsService,
    private sheetService: SheetService
  ) {}

  ngOnInit(): void {
    this.dictsService.persons$.subscribe((response) => {
      this.allPersons = response;
      this.personsToFilter = response;
    });
    this.sheetService.signatories$.subscribe((response) => {
      this.userList = response;
    });
    if (this.personId) {
      this.getPersonPosts();
    }
    if (this.facultyId.trimEnd()) {
      this.sheetService.updateSignatories(this.trainingLevelId, this.facultyId);
    }
    if (this.personId) {
      this.selectedPerson = {
        id: Guid.parse(this.personId),
        fio: this.signer.fio,
        postName: this.signer.postName,
        eduPostName: this.signer.postName,
        login: '',
      };
    }
  }

  getPersonPosts() {
    this.selectedPerson &&
      this.dictsService
        .getAllPostsPerson(this.selectedPerson.id.toString())
        .subscribe((response) => {
          this.postList = response;
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      Object.keys(changes).includes('signerPost') &&
      !Object.keys(changes).includes('signer') &&
      this.selectedPerson
    ) {
      const signer = {
        ...this.selectedPerson,
        postName: changes['signerPost'].currentValue as string,
        personId: Guid.parse(this.selectedPerson.personId || ''),
      };
      this.saveSigner(signer);
    } else if (
      Object.keys(changes).includes('signerPost') &&
      Object.keys(changes).includes('signer')
    ) {
      this.selectedPerson = {
        ...this.signer,
        eduPostName: this.signer.postName,
        login: '',
        personId: this.signer.personId.toString(),
        id: this.signer.personId,
      };
      if (
        Object.keys(changes).includes('personId') &&
        changes['personId'].currentValue
      ) {
        this.selectedPerson.id = Guid.parse(this.personId);
      }
    }
    if (
      Object.keys(changes).includes('personId') &&
      changes['personId'].currentValue
    ) {
      this.getPersonPosts();
    }
    if (Object.keys(changes).includes('eduGroupId')) {
      this.sheetService.signatories$.subscribe((response) => {
        this.userList = response;
      });
    }
    if (this.facultyId.trimEnd()) {
      this.sheetService.updateSignatories(this.trainingLevelId, this.facultyId);
    }
  }

  onChangeFindSigner(name: string): void {
    const user = this.allPersons.find((person) => person.fio === name);

    if (user)
      this.saveSigner({
        personId: user.id,
        fio: user.fio,
        postName: user.postName,
      });
  }

  onChangeSigner(signer: SignatoriesModel): void {
    this.personId = signer.personId.toString();
    this.saveSigner(signer);
  }

  saveSigner(signer: SignatoriesModel) {
    if (this.enableSync) {
      const data: SaveSignatoryModel = {
        fio: signer.fio,
        postName: signer.postName,
        personId: signer.personId.toString() || '',
        eduGroupId: this.eduGroupId,
        semesterNumber: this.semesterNum,
        disciplineId: this.disciplineId.trim() ? this.disciplineId : null,
        electiveDisciplineId: this.electiveDisciplineId.trim()
          ? this.electiveDisciplineId
          : null,
      };
      this.sheetService.saveSignatory(data).subscribe((response) => {
        if (this.facultyId.trimEnd() && response.personId) {
          if (
            this.selectedPerson?.id !== signer.personId ||
            this.selectedPerson?.postName !== signer.postName
          )
            this.handleChangeSigner(signer);
          this.sheetService.updateSignatories(this.trainingLevelId,this.facultyId);
          this.getPersonPosts();
        }
      });
    } else this.handleChangeSigner(signer);
  }

  onChangeSignerPost(e: string): void {
    this.signerPostChange.emit(e);
  }

  handleFilter(value: string) {
    if (value.length > 1) this.dictsService.updatePersons(value);
  }

  handleChangeSigner(user: SignatoriesModel) {
    this.selectedPerson = {
      ...user,
      eduPostName: user.postName,
      login: '',
      personId: user.personId.toString(),
      id: user.personId,
    };

    this.signerChange.emit(user);
    this.postList = [user.postName];
    this.signerPostChange.emit(user.postName);
  }
}

import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from '../../helpers/errorHandle-helper';
import {
  AllMilitaryForm,
  EditParams,
  GetParams,
  MilitaryForm,
  MilitaryFormGet
} from '../../models/contingent/military-form.model';
import {MilitaryCertificateTypes} from '../../models/contingent/enums/military-certificate-types.enum';

@Injectable({
  providedIn: 'root'
})
export class MilitaryFormService {
  baseUrl = environment.apiEndpoint;
  formTwoUrl = `${this.baseUrl}${environment.apiPaths.militaryformtwo}`;
  formFourUrl = `${this.baseUrl}${environment.apiPaths.militaryformfour}`;
  formFiveUrl = `${this.baseUrl}${environment.apiPaths.militaryformfive}`;
  formTenUrl = `${this.baseUrl}${environment.apiPaths.militaryformten}`;

  constructor(private http: HttpClient) { }

  private getUrl(type: MilitaryCertificateTypes) {
    switch (type) {
      case MilitaryCertificateTypes.appendix4:
        return this.formFourUrl;
      case MilitaryCertificateTypes.appendix5:
        return this.formFiveUrl;
      case MilitaryCertificateTypes.appendix2:
        return this.formTwoUrl;
      case MilitaryCertificateTypes.form10:
        return this.formTenUrl;
    }
  }

  public getCertificates(type: MilitaryCertificateTypes): Observable<AllMilitaryForm[]> {
    return this.http.get<AllMilitaryForm[]>(`${this.getUrl(type)}/GetAll`);
  }

  public getCertificateById({type, studentId, formId}: GetParams): Observable<MilitaryFormGet> {
    return this.http.get<MilitaryFormGet>(`${this.getUrl(type)}/GetById/${formId}/${studentId}`);
  }

  public addCertificate({type, form}: EditParams): Observable<MilitaryForm> {
    return this.http.post<MilitaryForm>(`${this.getUrl(type)}/Add`, form)
      .pipe(catchError(handleError));
  }

  public updateCertificate({type, formId, form}: EditParams): Observable<MilitaryForm> {
    return this.http.put<MilitaryForm>(`${this.getUrl(type)}/Update/${formId}`, form)
      .pipe(catchError(handleError));
  }

  public deleteCertificate({type, studentId, formId}: GetParams): Observable<MilitaryForm> {
    return this.http.delete<MilitaryForm>(`${this.getUrl(type)}/Delete/${formId}/${studentId}`)
      .pipe(catchError(handleError));
  }

  public getPrintForm({type, studentId, formId}: GetParams) {
    return this.http.get(`${this.getUrl(type)}/Print/${formId}/${studentId}`,
      {responseType: 'blob'})
      .pipe(catchError(handleError));
  }
}





import {Guid} from "guid-typescript";

export class GetMassPrintSheetData {
  public signatory!: Signatory | undefined;
  public disciplines!: Discipline[]

  public contingentDate?: Date;
  public markDate?: Date
}

export class GetSessionResultData {
  public signatory?: Signatory;
  public students!: string[];
}

export class GetUniversityReportData {
  public studyYearBegin!: number;
  public semesterNum!: number;
  public trainingLevels!: TrainingLevels[];
}

export class TrainingLevels {
  public trainingLevelId!: string;
  public studyFormIds!: string[];
}

class Signatory {
  public fio!: string;
  public postName!: string;
  public personId?: Guid;
}

export class Discipline {
  public eduGroupId!: string;
  public disciplineId!: string;
  public electiveDisciplineId!: string | null;
  public semesterNum?: number;
  public planId?: string;
}

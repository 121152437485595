import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Orders, OrdersStudent } from 'src/app/models/contingent/orders.model';
import { OrdersService } from 'src/app/services/contingent/orders.service';
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { OrderTypes } from 'src/app/models/contingent/ordertypes.model';
import { OrdertypesService } from 'src/app/services/contingent/ordertypes.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Student } from 'src/app/models/contingent/student.model';
import { OrdersCategory } from 'src/app/models/contingent/orderscategory.model';
import { StudentmaininfoComponent } from '../studentmaininfo/studentmaininfo.component';
import { AddEvent, EditEvent, GridComponent, GridDataResult, RemoveEvent } from '@progress/kendo-angular-grid';
import { OrderscategoryService } from 'src/app/services/contingent/orderscategory.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { SortDescriptor, State } from '@progress/kendo-data-query';
import { lastValueFrom, Observable } from 'rxjs';
import { OrdersstudyperiodService } from 'src/app/services/contingent/ordersstudyperiod.service';
import { OrdersStudyPeriod } from 'src/app/models/contingent/ordersstudyperiod.model';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { StudyLevel } from 'src/app/models/contingent/studylevel.model';
import { CommondictService } from 'src/app/services/contingent/commondict.service';
import { Guid } from 'guid-typescript';
import {DateFromUTCAsLocal, TimeZoneFix} from "../../../helpers/date-helper";
import {environment} from "../../../../environments/environment";
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { saveAs } from '@progress/kendo-file-saver';
import {StudentcarddisplaysettingService} from "../../../services/contingent/studentcarddisplaysetting.service";
import {DisplaySettingEnum} from "../../../models/contingent/enums/display-setting.enum";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrdersComponent implements OnInit {

  public enum = DisplaySettingEnum;
  public orderTypes: OrderTypes[] = [];
  public visibleOrderTypes: OrderTypes[] = [];
  public orderCategories: OrdersCategory[] = [];
  public visibleOrderCategories: OrdersCategory[] = [];
  public selectOrderCategories: OrdersCategory[] = [];
  public studentId!: Guid;
  public isNew:boolean = false;
  public editMode: boolean = false;
  public studyLevels: StudyLevel[] =[];
  public spec: string = '\u2103'
  public loading = false;
  public orderSedPlaceholder = environment.orderSed;

  public view: Observable<GridDataResult> | undefined;
  @ViewChild(GridComponent) private grid!: GridComponent;

  public orders: Orders[]=[];
  public order: Orders={
    ordersId: 0,
    studentId: 0,
    student: new Student,
    dictOrdersCategoryId: undefined,
    dictOrdersCategories: new OrdersCategory,
    number: '',
    orderDate: new Date,
    dateBegin: new Date,
    dateEnd: new Date,
    comment: '',
    orderSed: '',
    dictOrderTypeId: 0,
    fileFullName: ''
  }

  public orderStudent: OrdersStudent={
    studentId: undefined,
    fio: undefined,
    birthday: new Date,
    groupName: undefined,
    dateStart: new Date,
    dateFinish: undefined,
    studyLevelId: undefined,
    filialId: undefined,
  }

  public orderStudyPeriod?: OrdersStudyPeriod={
    ordersStudyPeriodId: 0,
    studentId: undefined,
    student: new Student,
    dateStart: new Date,
    dateFinish: new Date,
  }
  public restrictions: FileRestrictions = {
    allowedExtensions: [".docx", ".pdf"],
  };
  public files?: Array<File>;

  constructor(
    private activateRoute: ActivatedRoute,
    private dialogService: DialogService,
    private formBuilder:UntypedFormBuilder,
    public studentMainInfoComponent:StudentmaininfoComponent,
    private ordersService: OrdersService,
    private orderTypesService:OrdertypesService,
    private orderCategoriesService:OrderscategoryService,
    private notificationService: NotificationsService,
    private orderStudyPeriodService:OrdersstudyperiodService,
    private commonDictService: CommondictService,
    private displaySettingService: StudentcarddisplaysettingService,)
  {

    if(activateRoute.snapshot.parent!==null){
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
    }
  }

  async ngOnInit() {
    await this.getOrdersStudent();
    this.getAllOrders();
    this.getAllOrderCategories();
    this.getAllOrderStudyPeriod();
    this.getAllStudyLevel();
    this.getDisplaySettings()
  }


  ngAfterViewInit() {
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  public sort: SortDescriptor[] = [
    {
      field: "orderDate",
      dir: "asc",
    },
  ];

  //Get budget name for id
  public nameOrderTypes(id: Guid): string|undefined {
    return this.orderTypes.find((x) => x.dictOrderTypeExternalId === id)?.typeName;
  }

  onFocus(){
    let elements = document.querySelectorAll('.k-datepicker input');
    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('wheel', (e) => {
        e.stopImmediatePropagation();
      });
    }
  }

  //Getting all entries from dictionary
  public getAllOrders() {
    this.loading = true;
    this.ordersService.getByIdorders(this.studentId)
      .subscribe(
        response => {
          this.orders = response;
          for(let i = 0; i<this.orders.length; i++)
          {
            this.orders[i].index=i+1
          }
          this.loading = false
        }
      );
  }

  //Getting all entries from dictionary
  public getAllOrderStudyPeriod() {
    this.orderStudyPeriodService.getStudentStudyPeriod(this.studentId)
      .subscribe(
        response => {
          this.orderStudyPeriod = response;
          if (this.orderStudyPeriod != undefined){
            if (this.orderStudyPeriod.dateStart)
              this.orderStudyPeriod.dateStart = DateFromUTCAsLocal(this.orderStudyPeriod.dateStart);
            if (this.orderStudyPeriod.dateFinish)
              this.orderStudyPeriod.dateFinish = DateFromUTCAsLocal(this.orderStudyPeriod.dateFinish);
            this.formStudyPeriod.reset(this.orderStudyPeriod);
            this.studentMainInfoComponent.contingent.ordersStudyPeriod = this.formStudyPeriod.value;
          }
          else{
            this.formStudyPeriod.reset();
          }
        }
      );
  }

  public index(id: number): number {
    return this.orders.findIndex(x => x.ordersId === id)+1;
  }

  public getDisplaySettings() {
    this.displaySettingService.getStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettingService.displaySettings$.next(response);
        }
      );
  }

  //StudyLevel
  public getAllStudyLevel() {
    this.commonDictService.getAllStudyLevel()
      .subscribe(
        response => {
          this.studyLevels = response;
        }
      );
  }

  public async getOrdersStudent() {
    await lastValueFrom(this.ordersService.getByIdOrderStudent(this.studentId))
      .then(
        response => {
          this.orderStudent = response;
          this.editable=this.orderStudent.editable!;
          this.getAllOrderTypes();
        }
      );
  }

  public editable:boolean=false

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  //Getting all entries from dictionary
  public getAllOrderTypes() {
    this.orderTypesService.getAllordertypes(true)
      .subscribe(
        response => {
          this.orderTypes = response;
          this.visibleOrderTypes = this.orderTypes.filter((x) => x.actual === true && Guid.parse(x.filialId) == this.orderStudent.filialId)
        }
      );
  }

  //Getting all entries from dictionary
  public getAllOrderCategories() {
    this.orderCategoriesService.getAllorderscategory(false)
      .subscribe(
        response => {
          this.orderCategories = response;
          this.visibleOrderCategories = this.orderCategories.filter((x) => x.actual === true)
          for(let i of this.orderCategories){
            i.fullCategory = i.categoryName;
          }
          for(let i of this.visibleOrderCategories){
            i.fullCategory = i.categoryName;
          }
          this.selectOrderCategories = this.visibleOrderCategories
        }
      );
  }

  //Start Editing
  public transitionHandler({
                             dataItem,
                           }: EditEvent): void {

    this.order=dataItem;
    if(dataItem.dictOrdersCategories!=null) {
      this.order.dictOrderTypeId=dataItem.dictOrdersCategories.dictOrderTypeId;
      this.order.dictOrderType=dataItem.dictOrdersCategories.dictOrderType;
    }
    this.order.orderDate= DateFromUTCAsLocal (this.order.orderDate);
    if(this.order.dateBegin!=null)this.order.dateBegin = DateFromUTCAsLocal(this.order.dateBegin);
    if(this.order.dateEnd!=null)this.order.dateEnd = DateFromUTCAsLocal(this.order.dateEnd);
    //this.getOrder(dataItem.ordersId);

    if(!this.order.dictOrdersCategories.actual) {
      this.order.dictOrdersCategoryId = undefined
    }

    if(!this.order.dictOrderType.actual) {
      this.order.dictOrderTypeId = undefined
    }

    this.form.reset(this.order);
    this.form.value.dictOrdersCategoryId=this.order.dictOrdersCategoryId;
    this.selectOrderCategories=this.orderCategories.filter((s) => s.dictOrderTypeId === this.order.dictOrdersCategories.dictOrderTypeId).slice();
    //this.form.value.dictOrderTypeId=this.order.dictOrdersCategories.dictOrderTypeId;

    this.editMode = true;
    this.isNew = false;
    this.orderCategoryForStudent();
  }

  //Start adding
  public addHandler({ }: AddEvent): void {

    this.form.reset();
    this.editMode = true;
    this.isNew =true;
    this.form.value.studentId=this.studentId;
    this.orderCategoryForStudent();
  }

  public opened = false;
  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить приказ: "+ dataItem.number + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.ordersService.deleteorders(dataItem.ordersExternalId, this.studentId)
            .subscribe(
              ( ) => {
                this.getAllOrders();
                this.notificationService.showSuccess("Удалено");
              }
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  //Form
  public form: UntypedFormGroup = new UntypedFormGroup({
    ordersId: new UntypedFormControl(),
    ordersExternalId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    student: new UntypedFormControl(),
    dictOrdersCategoryId: new UntypedFormControl(),
    dictOrdersCategories: new UntypedFormControl(),
    dictOrderTypeId: new UntypedFormControl(),
    number: new UntypedFormControl(),
    orderDate: new UntypedFormControl(),
    dateBegin: new UntypedFormControl(),
    dateEnd: new UntypedFormControl(),
    comment: new UntypedFormControl(),
    orderSed: new UntypedFormControl(),
    file: new UntypedFormControl(),
    fileFullName: new UntypedFormControl(),

  });

  public formStudyPeriod: UntypedFormGroup = new UntypedFormGroup({
    ordersStudyPeriodId: new UntypedFormControl(),
    ordersStudyPeriodExternalId: new UntypedFormControl(),
    studentId: new UntypedFormControl(),
    student: new UntypedFormControl(),
    dateBegin:  new UntypedFormControl(),
    dateEnd:  new UntypedFormControl(),
    dateStart: new UntypedFormControl(),
    dateFinish: new UntypedFormControl(),
  });

  //Close edit form
  public close(): void {
    this.editMode = false;
    this.updateGrid();
    this.clearForm();
  }


  //Close edit form
  public update(): void {
    this.editMode = false;
  }

  public orderCategoryForStudent():void {
    const studyLevelId = this.studyLevels.find((x) => x.dictStudyLevelExternalId === this.orderStudent?.studyLevelId)?.dictStudyLevelExternalId;
    this.orderCategories = this.orderCategories.filter((x) => x.dictStudyLevelId === studyLevelId);
  }

  //Close edit form
  public updateGrid() {
    this.getAllOrders();
    this.getAllOrderTypes();
    this.getAllOrderCategories();
    this.getAllOrderStudyPeriod();
    this.formStudyPeriod.reset();
    this.formStudyPeriod.reset(this.orderStudyPeriod);
    this.view;
  }

  //Save Order
  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.saveOrder();
  }

  public buttonClicked = false

  public onStateChange(state: State): void {
    this.getAllOrders();
    this.getAllOrderTypes();
    this.getAllOrderCategories();
    this.view;
  }

  public onTypeChange(dataItem:any): void {
    this.selectOrderCategories = this.visibleOrderCategories.filter((s) => 
      s.dictOrderTypeId === dataItem.dictOrderTypeExternalId &&
      s.dictStudyLevelId == this.orderStudent.studyLevelId
    ).slice();
  }

  //cleat form
  public clearForm(): void {
    this.form.reset();
    this.files = [];
  }


  public saveOrder() {
    // Add new order
    this.timeZoneFix();
    if (this.form?.value.dateBegin &&
      this.form?.value.dateEnd &&
      this.form?.value.dateBegin > this.form?.value.dateEnd) {
      this.notificationService.showError("Дата начала должна быть меньше даты окончания.");
    } else {
      this.form.value.studentId = this.studentId;
      let formData = new FormData();
      formData.append(`StudentId`, this.form?.value.studentId)
      formData.append(`DictOrdersCategoryId`, this.form?.value.dictOrdersCategoryId)
      formData.append(`Number`, this.form?.value.number)
      formData.append(`OrderDate`, this.form?.value.orderDate?.toISOString())
      formData.append(`DateBegin`, this.form?.value.dateBegin ? this.form?.value.dateBegin?.toISOString() : '')
      formData.append(`DateEnd`, this.form?.value.dateEnd ? this.form?.value.dateEnd?.toISOString() : '')
      formData.append(`Comment`, this.form?.value.comment || '')
      formData.append(`OrderSed`, this.form?.value.orderSed || '')
      if (this.files && this.files.length > 0) {
        formData.append(`FileFullName`, this.files[0].name);
        formData.append(`File`, this.files[0], this.files[0].name);
      }

      this.form.value.dictOrderTypeId=undefined;
      if(this.isNew)
      {
        this.ordersService.addorders(formData)
        .subscribe({
          next:() => {
            this.notificationService.showSuccess("Приказ был успешно добавлен!");
            this.updateGrid();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить приказ");
          }}
        );
      } else {
        this.ordersService.updateorders(formData, this.form?.value.ordersExternalId)
          .subscribe({
            next:() => {
              this.notificationService.showSuccess("Приказ был успешно сохранен!");
              this.updateGrid();
            },
            error:() => {
              this.notificationService.showError('Не удалось изменить приказ');
            }}
          )
      }
      this.clearForm();
      this.editMode = false;
    }
  }


  public saveStudyPeriod() {
    // Add new order
    if(this.buttonClicked){
      this.studentMainInfoComponent.contingent.ordersStudyPeriod=this.formStudyPeriod.value;
    }
    if(this.orderStudyPeriod?.studentId == null)
    {
      this.formStudyPeriod.value.studentId = this.studentId;
      this.timeZoneFix();
      this.orderStudyPeriodService.addordersstudyperiod(this.formStudyPeriod?.value)
        .subscribe({
          next:() => {
            if(this.buttonClicked){
              this.notificationService.showSuccess("Период обучения был успешно добавлен!");
              this.buttonClicked = false
            }
            this.updateGrid();
          },
          error:() => {
            this.notificationService.showError("Не удалось добавить период обучения");
          }}
        );
    }
    // Edit standard
    else {
      this.timeZoneFix();

      this.orderStudyPeriodService.updateordersstudyperiod(this.formStudyPeriod?.value)
        .subscribe({
          next:() => {
            if(this.buttonClicked){
              this.notificationService.showSuccess("Период обучения был успешно сохранен!");
              this.buttonClicked = false
            }
            this.updateGrid();
          },
          error:() => {
            this.notificationService.showError('Не удалось изменить период обучения');
          }}
        )
    }
  }

  public openSed(url:any) {
    setTimeout(() => window.open(url), 50);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
  }

  // Convert date to UTC
  public timeZoneFix()
  {
    this.form.value.orderDate = TimeZoneFix(this.form.value.orderDate);
    if(this.form.value.dateBegin) this.form.value.dateBegin = TimeZoneFix(this.form.value.dateBegin);
    if(this.form.value.dateEnd) this.form.value.dateEnd = TimeZoneFix(this.form.value.dateEnd);
    if(this.formStudyPeriod.value.dateBegin) this.formStudyPeriod.value.dateBegin = TimeZoneFix(this.formStudyPeriod.value.dateBegin);
    if(this.formStudyPeriod.value.dateEnd) this.formStudyPeriod.value.dateEnd = TimeZoneFix(this.formStudyPeriod.value.dateEnd);
    this.formStudyPeriod.value.dateStart = TimeZoneFix(this.formStudyPeriod.value.dateStart);
    if(this.formStudyPeriod.value.dateFinish!=null)
      this.formStudyPeriod.value.dateFinish = TimeZoneFix(this.formStudyPeriod.value.dateFinish);
  }

  public exportToPDF(grid: GridComponent): void {
    grid.saveAsPDF();
  }

  public isHidden(field: number): boolean {
    return this.displaySettingService.isHidden(field);
  }

  public getOrderFile(e: any) {
    const fileName = this.orders.find((order: any) => order.ordersExternalId == e)?.fileFullName;
    if(fileName) {
      this.ordersService.getOrderFile(e).subscribe(
        (response: BlobPart) => {
          const blob: any = new Blob([response], { type: `Content-Type', 'application/octet-stream` });
          saveAs(blob, fileName);
        }
      );
    }
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, Observable, throwError} from "rxjs";
import {SalaryProjectName, SalaryProject} from "../../models/cards/salaryproject";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class SalaryProjectService {
  baseUrl: string = `${environment.cardsApiEndpoint}${environment.apiPaths.cards.salaryProject}`;

  constructor(private http: HttpClient) { }

  public getSalaryProjectsNames(): Observable<SalaryProjectName[]> {
    return this.http.get<SalaryProjectName[]>(`${this.baseUrl}/GetSalaryProjectsNames`)
      .pipe(catchError(handleError));
  }

  public getSalaryProjects() {
    return this.http.get<SalaryProject[]>(this.baseUrl);
  }

  public addSalaryProjects(data: SalaryProject) {
    return this.http.post(this.baseUrl, data);
  }

  public updateSalaryProjects(data: SalaryProject, id: string) {
    return this.http.put(this.baseUrl + '/' + id, data);
  }

  public deleteSalaryProjects(id: string) {
    return this.http.delete(this.baseUrl + '/' + id);
  }
}

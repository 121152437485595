import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './dictshome.component.html',
  styleUrls: ['./dictshome.component.css']
})
export class DictsHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileRestrictions} from '@progress/kendo-angular-upload';
import {environment} from '../../../../environments/environment';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {MilitaryCertificateTypes} from '../../../models/contingent/enums/military-certificate-types.enum';
import {MilitaryTemplate} from '../../../models/contingent/militarytemplate.model';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {MilitaryCommissariat} from '../../../models/contingent/militarycommissariat.model';
import {MilitaryCommissariatService} from '../../../services/contingent/military-commissariat.service';
import {StudyLevel} from '../../../models/contingent/studylevel.model';
import {CommondictService} from '../../../services/contingent/commondict.service';
import {MilitaryRanks} from '../../../models/contingent/militaryranks.model';
import {MilitaryranksService} from '../../../services/contingent/militaryranks.service';
import {StudentService} from '../../../services/contingent/student.service';
import {Guid} from 'guid-typescript';
import {ContingentService} from '../../../services/contingent/contingent.service';
import {AllStudent} from '../../../models/contingent/student.model';
import {lastValueFrom} from 'rxjs';
import {courses} from '../../../models/contingent/course-numbers';
import {LocalSignatoryCertificate} from '../../../models/contingent/localsignatory.model';
import {LocalSignatoryService} from '../../../services/contingent/local-signatory.service';
import {DateFromUTCAsLocal, DateToString} from '../../../helpers/date-helper';
import {ContingentOrganization} from '../../../models/contingent/organization.model';
import {ContingentOrganizationService} from '../../../services/contingent/contingentOrganization.service';
import {militaryAcademicStates} from '../../../models/contingent/military-academic-states';
import {OrdersService} from '../../../services/contingent/orders.service';
import {MilitaryTemplateService} from '../../../services/contingent/military-template.service';
import {MilitaryStudentInfoService} from '../../../services/contingent/military-student-info.service';
import {MilitaryFormTenOrder, StudentInfo, StudentOrder} from '../../../models/contingent/military-student-info.model';
import {
  DriverLicense,
  LocalSignatory,
  MilitaryFormFive,
  MilitaryFormFour, MilitaryFormGet,
  MilitaryFormTenGet,
  MilitaryFormTwo
} from '../../../models/contingent/military-form.model';
import {Orders} from '../../../models/contingent/orders.model';
import {DictFilialService} from '../../../services/contingent/dictfilial.service';
import {DictFilial} from '../../../models/contingent/dictfilial.model';
import {MilitaryStatusesEnum} from '../../../models/contingent/enums/military-statuses.enum';
import {studentPosts, TrainingLevelEnum} from '../../../models/contingent/enums/training-level.enum';
import {MilitaryStockCategories} from '../../../models/contingent/militarystockcategories.model';
import {MilitaryCategories} from '../../../models/contingent/militarycategories.model';
import {MilitaryProfiles} from '../../../models/contingent/militaryprofiles.model';
import {MilitaryDocTypes} from '../../../models/contingent/militarydoctypes.model';
import {MilitarystockcategoriesService} from '../../../services/contingent/militarystockcategories.service';
import {MilitaryProfilesService} from '../../../services/contingent/militaryprofiles.service';
import {MilitarydoctypesService} from '../../../services/contingent/militarydoctypes.service';
import {MilitaryCategoriesService} from '../../../services/contingent/militarycategories.service';
import {OrderTypesEnum} from '../../../models/contingent/enums/order-types.enum';


@Component({
  selector: 'app-military-certificate-form',
  templateUrl: './military-certificate-form.component.html',
  styleUrls: ['./military-certificate-form.component.scss']
})
export class MilitaryCertificateFormComponent implements OnInit {
  @Input() type?: MilitaryCertificateTypes | null;
  @Input() studentId?: Guid | string;
  @Input() data?: MilitaryFormGet;
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter<{form: FormGroup, print?: boolean}>();

  public filterSettings: DropDownFilterSettings = {
    operator: 'contains',
  };

  public restrictions: FileRestrictions = {
    maxFileSize: environment.contingent.maxFileSize,
    allowedExtensions: ['.docx'],
  };

  public phoneNumberMask = "+9 (999) 000-00-00";

  public students: AllStudent[] = [];
  public templates: MilitaryTemplate[] = [];
  public defaultTemplateId?: string;
  public localSignatories: LocalSignatoryCertificate[] = [];
  public signatoriesList: LocalSignatoryCertificate[] = [];
  public orders: {id?: string, text?: string}[] = [];
  public personPosts: (string|undefined)[] = [];
  public studentInfo?: StudentInfo;
  public formData?: MilitaryFormGet;
  public birthday?: Date;
  public birthYear?: number;

  public studyLevels: StudyLevel[] = [];
  public organizations: ContingentOrganization[] = [];
  public filials: DictFilial[] = [];
  public militaryCommissariats: MilitaryCommissariat[] = [];
  public militaryStockCategories: MilitaryStockCategories[] = [];
  public militaryRanks: MilitaryRanks[] = [];
  public militaryProfiles: MilitaryProfiles[] = [];
  public militaryCategories: MilitaryCategories[] = [];
  public militaryDocTypes: MilitaryDocTypes[] = [];

  public certificateForm!: FormGroup;
  public signatoryEditMode: boolean[] = [];

  protected readonly MilitaryCertificateTypes = MilitaryCertificateTypes;
  protected readonly courses = courses;
  protected readonly militaryAcademicStates = militaryAcademicStates;

  constructor(
    private notificationService: NotificationsService,
    private militaryCommissariatService: MilitaryCommissariatService,
    private militaryStockCategoriesService: MilitarystockcategoriesService,
    private militaryRanksService: MilitaryranksService,
    private militaryProfilesService: MilitaryProfilesService,
    private militaryCategoriesService: MilitaryCategoriesService,
    private militaryDocTypesService: MilitarydoctypesService,
    private militaryTemplateService: MilitaryTemplateService,
    private organizationService: ContingentOrganizationService,
    private dictFilialService: DictFilialService,
    private commonDictService: CommondictService,
    private studService: StudentService,
    private contingentService: ContingentService,
    private ordersService: OrdersService,
    private localSignatoryService: LocalSignatoryService,
    private studentInfoService: MilitaryStudentInfoService,
  ) { }

  ngOnInit() {
    this.certificateForm = this.getForm();
    this.getDicts();
    this.getData();
  }

  private getForm(value?: MilitaryFormGet, studentId?: string) {
    switch (this.type) {
      case MilitaryCertificateTypes.appendix4:
        return this.getAppendix4FormGroup(studentId, value as MilitaryFormFour);
      case MilitaryCertificateTypes.appendix5:
        return this.getAppendix5FormGroup(studentId, value as MilitaryFormFive);
      case MilitaryCertificateTypes.appendix2:
        return this.getAppendix2FormGroup(studentId, value as MilitaryFormTwo);
      case MilitaryCertificateTypes.form10:
        return this.getForm10Group(studentId, value as MilitaryFormTenGet);
      default:
        return new FormGroup({});
    }
  }

  private getDicts() {
    this.getCertificateTemplates();
    this.getMilitaryCommissariats();

    if (this.type === MilitaryCertificateTypes.form10) {
      this.getMilitaryStockCategories();
      this.getMilitaryProfiles();
      this.getMilitaryCategories();
      this.getMilitaryDocTypes();
    }

    if (this.type === MilitaryCertificateTypes.appendix2
      || this.type === MilitaryCertificateTypes.form10) {
      this.getMilitaryRanks();
    } else {
      this.getFilials();
    }

    if (this.type === MilitaryCertificateTypes.appendix5) {
      this.getOrganization();
    }

    if (this.type !== MilitaryCertificateTypes.appendix2) {
      this.getStudyLevels();
    }
  }

  private getCertificateTemplates() {
    this.militaryTemplateService.getMilitaryTemplates().subscribe((response) => {
      this.templates = response.filter((item) => item.militaryFormType === this.type);
      this.defaultTemplateId = this.templates.find((item) => item.isDefault)?.externalId;
      this.defaultTemplateId && this.certificateForm.get('militaryFormPrintTemplateId')?.patchValue(this.defaultTemplateId);
    });
  }

  private getStudyLevels() {
    this.commonDictService.getAllStudyLevel().subscribe((response) => {
      this.studyLevels = response;
    });
  }

  private getMilitaryCommissariats() {
    this.militaryCommissariatService.getAllMilitaryCommissariat().subscribe((response) => {
      this.militaryCommissariats = response;
    });
  }

  private getMilitaryStockCategories() {
    this.militaryStockCategoriesService.getAllMilitaryStockCategories().subscribe((response) => {
      this.militaryStockCategories = response;
    });
  }

  private getMilitaryRanks() {
    this.militaryRanksService.getAllMilitaryRanks().subscribe((response) => {
      this.militaryRanks = response;
    });
  }

  private getMilitaryProfiles() {
    this.militaryProfilesService.getMilitaryProfiles().subscribe((response) => {
      this.militaryProfiles = response;
    });
  }

  private getMilitaryCategories() {
    this.militaryCategoriesService.getCategories().subscribe((response) => {
      this.militaryCategories = response;
    });
  }

  private getMilitaryDocTypes() {
    this.militaryDocTypesService.getAllMilitaryDocTypes().subscribe((response) => {
      this.militaryDocTypes = response;
    })
  }

  private getOrganization() {
    this.organizationService.getAllorganization().subscribe((response) => {
      this.organizations = response;
    });
  }

  private getFilials() {
    this.dictFilialService.getAllfilial().subscribe((response) => {
      this.filials = response;
    });
  }

  private getOrders() {
    this.ordersService.getByIdorders(this.studentId as string).subscribe((response) => {
      this.orders = this.mapOrders(this.filterOrders(response));
    });
  }

  private async getData() {
    if (this.data) {
      this.getFormData(this.data);
    } else {
      await this.getStudentsList();
      this.studentId && this.studentChange(this.studentId as string);
    }
  }

  private getFormData(response: MilitaryFormGet) {
    this.mapFormData(response);
    this.certificateForm = this.getForm(this.formData, response.studentId);
    this.getLocalSignatories(this.certificateForm.value.studentId);
  }

  private mapFormData(value: MilitaryFormGet) {
    this.formData = value;
    switch (this.type) {
      case MilitaryCertificateTypes.appendix4:
        this.formData.registrationDate = DateFromUTCAsLocal(this.formData.registrationDate);
        this.birthYear = this.formData.birthYear;
        this.formData.educationTerm = DateFromUTCAsLocal(this.formData.educationTerm);
        this.formData.studyPeriodEndDate = DateFromUTCAsLocal(this.formData.studyPeriodEndDate);
        break;
      case MilitaryCertificateTypes.appendix5:
        this.formData.registrationDate = DateFromUTCAsLocal(this.formData.registrationDate);
        this.formData.studyPeriodEndDate = DateFromUTCAsLocal(this.formData.studyPeriodEndDate);
        this.formData.councilOrderDate = DateFromUTCAsLocal(this.formData.councilOrderDate);
        this.formData.dissertationDefenseDate = DateFromUTCAsLocal(this.formData.dissertationDefenseDate);
        this.birthYear = this.formData.birthYear;
        break;
      case MilitaryCertificateTypes.appendix2:
        this.formData.registrationDate = DateFromUTCAsLocal(this.formData.registrationDate);
        this.birthday = DateFromUTCAsLocal(this.formData.birthday);
        this.getOrders();
        break;
      case MilitaryCertificateTypes.form10:
        if (this.formData.driverLicense) {
          this.formData.driverLicense.licenseIssueDate = DateFromUTCAsLocal(this.formData.driverLicense.licenseIssueDate);
          this.formData.driverLicense.licenseEndDate = DateFromUTCAsLocal(this.formData.driverLicense.licenseEndDate);
        }

        const studentInfo = this.formData.studentInfo;
        this.birthday = DateFromUTCAsLocal(studentInfo.birthday);
        studentInfo.familyMembers.map((item) =>
          item.birthday = DateFromUTCAsLocal(item.birthday));
        studentInfo.orders.map((item) => {
          item.date = DateFromUTCAsLocal(item.date);
          item.militaryFormTwoDate = DateFromUTCAsLocal(item.militaryFormTwoDate);
        });
        if (studentInfo.passport) studentInfo.passport.issueDate = DateFromUTCAsLocal(studentInfo.passport.issueDate);
        studentInfo.registrationDate = DateFromUTCAsLocal(studentInfo.registrationDate);
        studentInfo.residenceDate = DateFromUTCAsLocal(studentInfo.residenceDate);
        studentInfo.dateRemoveFromMilitaryEnlistment = DateFromUTCAsLocal(studentInfo.dateRemoveFromMilitaryEnlistment);
        this.formData.studentInfo = studentInfo;
        break;
    }
  }

  public studentChange(studentId: string) {
    if (studentId) {
      this.getLocalSignatories(studentId);
      this.getStudentInfo(studentId);
    } else {
      this.clearForm();
    }
  }

  private clearForm() {
    this.studentInfo = undefined;
    this.birthday && (this.birthday = undefined);
    this.birthYear && (this.birthYear = undefined);
    this.localSignatories.length && (this.localSignatories = []);
    this.personPosts.length && (this.personPosts = []);
    this.orders.length && (this.orders = []);
    this.certificateForm = this.getForm();
  }

  private async getStudentsList() {
    await lastValueFrom(this.studService.getAllStudents()).then((response) => {
      this.students = response;
    });
  }

  private getStudentInfo(studentId: string) {
    this.studentInfoService.getInfoForForm(this.type as MilitaryCertificateTypes, studentId).subscribe((response) => {
      this.mapStudentInfo(response);
      this.certificateForm = this.getForm(undefined, studentId);
      this.studentInfoIsValid();
    });
  }

  private mapStudentInfo(value: StudentInfo) {
    this.studentInfo = value;
    switch (this.type) {
      case MilitaryCertificateTypes.appendix4:
        this.birthYear = DateFromUTCAsLocal(this.studentInfo.birthday).getFullYear();
        this.studentInfo.studyPeriodEndDate = DateFromUTCAsLocal(this.studentInfo.studyPeriodEndDate);
        break;
      case MilitaryCertificateTypes.appendix5:
        this.birthYear = DateFromUTCAsLocal(this.studentInfo.birthday).getFullYear();
        this.studentInfo.educationEndDate = DateFromUTCAsLocal(this.studentInfo.educationEndDate);
        break;
      case MilitaryCertificateTypes.appendix2:
        this.birthday = DateFromUTCAsLocal(this.studentInfo.birthDay);
        this.orders = this.mapOrders(this.filterStudentOrders(this.studentInfo.studentOrders));
        break;
      case MilitaryCertificateTypes.form10:
        this.birthday = DateFromUTCAsLocal(this.studentInfo.birthday);
        this.studentInfo.familyMembers?.map((item) =>
          item.birthday = DateFromUTCAsLocal(item.birthday));
        this.studentInfo.studentOrders.map((item: MilitaryFormTenOrder) => {
          item.date = DateFromUTCAsLocal(item.date);
          item.militaryFormTwoDate = DateFromUTCAsLocal(item.militaryFormTwoDate);
        });
        if (this.studentInfo.identification)
          this.studentInfo.identification.issueDate = DateFromUTCAsLocal(this.studentInfo.identification.issueDate);
        this.studentInfo.registrationDate = DateFromUTCAsLocal(this.studentInfo.registrationDate);
        this.studentInfo.residenceDate = DateFromUTCAsLocal(this.studentInfo.residenceDate);
        this.studentInfo.dateRemoveFromMilitaryEnlistment = DateFromUTCAsLocal(this.studentInfo.dateRemoveFromMilitaryEnlistment);
        this.studentInfo.studentOrders?.map((item) =>
          item.date = DateFromUTCAsLocal(item.date));
        break;
    }
  }

  private studentInfoIsValid() {
    if (this.type === MilitaryCertificateTypes.form10 && this.studentInfo?.alreadyExists) {
      this.notificationService.showError('Для данного студента справка уже существует');
      return false;
    }

    if (this.type === MilitaryCertificateTypes.appendix5
      && this.studentInfo?.dictTrainingLevelEnum !== TrainingLevelEnum.Graduate
      && this.studentInfo?.dictTrainingLevelEnum !== TrainingLevelEnum.Residency) {
      this.notificationService.showError('Данная справка может быть создана только для ординаторов или аспирантов');
      return false;
    }

    if (!this.studentInfo?.dictMilitaryCommissariatId) {
      this.notificationService.showError('Необходимо заполнить данные воинского учёта в карточке студента');
      return false;
    }

    if ((this.type === MilitaryCertificateTypes.appendix4
      || this.type === MilitaryCertificateTypes.appendix5)
      && this.studentInfo.dictMilitaryStatusesEnum !== MilitaryStatusesEnum.Conscript) {
      this.notificationService.showError('Данная справка может быть создана только для призывников');
      return false;
    }

    return true;
  }

  private getLocalSignatories(studentId: string) {
    if (this.type !== MilitaryCertificateTypes.form10) {
      this.localSignatoryService.getCertificateSigners(studentId).subscribe({
        next: (response) => {
          this.localSignatories = response;
          this.personPosts = [...new Set(response.map((item) => item.postName).filter((post) => !!post))];
        },
        error: () => this.notificationService.showError('Не удалось получить список локальных подписантов'),
      });
    }
  }

  private filterOrders(orders: Orders[]) {
    return orders.filter((item) => item.dictOrdersCategories.dictOrderType.enums?.length
      && (item.dictOrdersCategories.dictOrderType.enums.includes(OrderTypesEnum.Enrollment)
        || item.dictOrdersCategories.dictOrderType.enums.includes(OrderTypesEnum.Expulsion)));
  }

  private filterStudentOrders(orders: StudentOrder[]) {
    return orders.filter((item) => item.enums?.length
      && (item.enums.includes(OrderTypesEnum.Enrollment) || item.enums.includes(OrderTypesEnum.Expulsion)));
  }

  private mapOrders(orders: StudentOrder[] | Orders[]) {
    return orders.map((item) => this.mapOrder(item));
  }

  private mapOrder(order?: StudentOrder | Orders) {
    if (!order) return {};
    const categoryName = (order as StudentOrder)?.categoryName ?? (order as Orders).dictOrdersCategories.categoryName;
    const date = DateToString(DateFromUTCAsLocal((order as StudentOrder)?.date ?? (order as Orders).orderDate));
    return {
      id: (order as StudentOrder)?.id ?? (order as Orders).ordersExternalId,
      text: `${categoryName}, №${order.number} от ${date}`,
    }
  }

  public saveCertificate(print?: boolean) {
    if (this.studentInfo && !this.studentInfoIsValid()) return;

    if (this.certificateForm.invalid) {
      this.notificationService.showError('Заполните обязательные поля');
      return;
    }

    if (this.getSignatoriesMaxCount() === 2
      && this.signatories.getRawValue().length
      && this.signatories.getRawValue()[0].globalSignatoryId === this.signatories.getRawValue()[1].globalSignatoryId) {
        this.notificationService.showError('Справка не может содержать одинаковых подписантов');
        return;
    }

    this.save.emit({form: this.certificateForm, print});
  }

  public closeHandler() {
    this.close.emit();
  }

  public editSignatory(index: number) {
    this.closeSignatory();
    this.signatoryEditMode[index] = true;
    if (this.signatories.value[index].postName) {
      this.signatoriesList = this.localSignatories.filter((item) =>
        item.postName === this.signatories.value[index].postName);
    } else {
      this.signatoriesList = [];
    }
  }

  public closeSignatory() {
    this.signatoryEditMode.fill(false);
  }

  public postChange(index: number, value?: string) {
    this.signatories.get(`${index}`)?.patchValue({globalSignatoryId: null, fio: null, postName: value});
    this.signatoriesList = this.localSignatories.filter((item) => item.postName === value);
  }

  public fioChange(index: number, value?: string) {
    const id = this.localSignatories.find((item) => item.fio === value)?.globalSignatoryId;
    this.signatories.get(`${index}`)?.get('globalSignatoryId')?.patchValue(id);
  }

  public getSignatoriesMaxCount(): number {
    switch (this.type) {
      case MilitaryCertificateTypes.appendix4:
      case MilitaryCertificateTypes.appendix5:
        return 1;
      case MilitaryCertificateTypes.appendix2:
        return 2;
      default:
        return 0;
    }
  }

  public getSignatory(signatory: LocalSignatory) {
    return `${signatory.fio ?? 'Фамилия Имя Отчество'}, ${signatory.postName ?? 'Должность'}`;
  }

  public get signatories(): FormArray {
    return <FormArray> this.certificateForm.get('signatories');
  }

  private getFormSignatories(value?: LocalSignatory[]) {
    return !value || !value.length
      ? Array.from({length: this.getSignatoriesMaxCount()}, () => this.getSignatoryFormGroup())
      : value.map((item) => this.getSignatoryFormGroup(item));
  }

  private getSignatoryFormGroup(value?: LocalSignatory) {
    return new FormGroup({
      externalId: new FormControl<string|null>(value?.externalId ?? null),
      globalSignatoryId: new FormControl<string|null>(value?.globalSignatoryId ?? null, Validators.required),
      fio: new FormControl<string|null>(value?.fio ?? null, Validators.required),
      postName: new FormControl<string|null>(value?.postName ?? null, Validators.required),
    });
  }

  private getStudentFullName(studentId?: string) {
    return this.students.find((item) => item.studentId === studentId)?.fullName;
  }

  private getCommissariatAddress(value?: string) {
    return this.militaryCommissariats.find((item) => item.id === value)?.address;
  }

  public changeCommissariatAddress(value?: string) {
    this.certificateForm.get('militaryCommissariatAddress')?.patchValue(this.getCommissariatAddress(value));
  }

  public getStudyLevelName(value?: string) {
    return this.studyLevels.find((item) => item.dictStudyLevelExternalId === value)?.studyLevelName;
  }

  public getPostName(trainingLevel?: TrainingLevelEnum, course?: number) {
    return trainingLevel ? `${studentPosts.get(trainingLevel)}${course ? ` ${course} курса` : ''}` : '';
  }

  public getDateNumberText(date?: Date, number?: string) {
    return date && number ? `№${number} от ${DateToString(date)}` : '';
  }

  public getStockCategoryName(value?: string) {
    return this.militaryStockCategories.find((item) =>
      item.dictMilitaryStockCategoryExternalId === value)?.militaryStockCategoryName;
  }

  public getMilitaryRankName(value?: string) {
    return this.militaryRanks.find((item) =>
      item.dictMilitaryRankExternalId === value)?.militaryRankName;
  }

  public getMilitaryProfileName(value?: string) {
    return this.militaryProfiles.find((item) =>
      item.dictMilitaryProfileExternalId === value)?.militaryProfileName;
  }

  public getMilitaryStatusName(value?: string) {
    return this.militaryCategories.find((item) =>
      item.dictMilitaryCategoryExternalId === value)?.militaryCategoryName;
  }

  public getCommissariatName(value?: string) {
    return this.militaryCommissariats.find((item) => item.id === value)?.commissariatName;
  }

  public getMilitaryDocTypeName(value?: string) {
    return this.militaryDocTypes.find((item) =>
      item.dictMilitaryDocTypeExternalId === value)?.militaryDocTypeName;
  }

  public organizationChange(value?: string) {
    const name = this.organizations.find((item) =>
      item.dictOrganizationExternalId === value)?.organizationName;
    this.certificateForm.get('councilBaseOrganizationName')?.patchValue(name);
  }

  private getAppendix4FormGroup(studentId?: string, value?: MilitaryFormFour) {
    return new FormGroup({
      externalId: new FormControl<string|null>(value?.externalId ?? null),
      studentId: new FormControl<string|null>(studentId ?? null, Validators.required),
      fio: new FormControl<string|null>(value?.fio ?? this.getStudentFullName(studentId) ?? null, Validators.required),
      militaryFormPrintTemplateId: new FormControl<string|null>(
        value?.militaryFormPrintTemplateId ?? this.defaultTemplateId ?? null,
        Validators.required
      ),
      //autocomplete
      registrationDate: new FormControl<Date>(value?.registrationDate ?? new Date(), Validators.required),
      studyPeriodStartYear: new FormControl<number|null>(
        value?.studyPeriodStartYear ?? this.studentInfo?.studyPeriodStartYear ?? null, Validators.required),
      previousDictStudyLevelId: new FormControl<string|null>(
        value?.previousDictStudyLevelId ?? this.studentInfo?.previousDictStudyLevelId ?? null),
      educationOrganization: new FormControl<string|null>(
        value?.educationOrganization ?? this.studentInfo?.universityName ?? null, Validators.required),
      educationStandard: new FormControl<string|null>(
        value?.educationStandard ?? this.studentInfo?.educationStandard ?? null, Validators.required),
      courseNum: new FormControl<number|null>(
        value?.courseNum ?? this.studentInfo?.courseNum ?? null, Validators.required),
      profession: new FormControl<string|null>(
        value?.profession ?? this.studentInfo?.profession ?? null, Validators.required),
      studyPeriodEndDate: new FormControl<Date|null>(
        value?.studyPeriodEndDate ?? this.studentInfo?.studyPeriodEndDate ?? null),
      //manual
      number: new FormControl<string|null>(value?.number ?? null, Validators.required),
      dictMilitaryCommissariatId: new FormControl<string|null>(
        value?.dictMilitaryCommissariatId ?? this.studentInfo?.dictMilitaryCommissariatId ?? null, Validators.required),
      dlastName: new FormControl<string|null>(value?.dlastName ?? null),
      dfirstName: new FormControl<string|null>(value?.dfirstName ?? null),
      dmiddleName: new FormControl<string|null>(value?.dmiddleName ?? null),
      educationTerm: new FormControl<Date|null>(value?.educationTerm ?? null),
      signatories: new FormArray(this.getFormSignatories(
        value
          ? [{
            globalSignatoryId: value.globalSignatoryId,
            fio: value.globalSignatoryFio,
            postName: value.globalSignatoryPostName
          }]
          : undefined)),
    });
  }

  private getAppendix5FormGroup(studentId?: string, value?: MilitaryFormFive) {
    return new FormGroup({
      externalId: new FormControl<string|null>(value?.externalId ?? null),
      studentId: new FormControl<string|null>(studentId ?? null, Validators.required),
      fio: new FormControl<string|null>(value?.fio ?? this.getStudentFullName(studentId) ?? null, Validators.required),
      militaryFormPrintTemplateId: new FormControl<string|null>(
        value?.militaryFormPrintTemplateId ?? this.defaultTemplateId ?? null,
        Validators.required
      ),
      //autocomplete
      registrationDate: new FormControl<Date>(value?.registrationDate ?? new Date(), Validators.required),
      previousDictStudyLevelId: new FormControl<string|null>(
        value?.previousDictStudyLevelId ?? this.studentInfo?.previousDictStudyLevelId ?? null),
      educationOrganization: new FormControl<string|null>(
        value?.educationOrganization ?? this.studentInfo?.universityName ?? null, Validators.required),
      educationStandard: new FormControl<string|null>(
        value?.educationStandard ?? this.studentInfo?.educationProgramData ?? null, Validators.required),
      orderId: new FormControl<string|null>(
        value?.orderId ?? this.studentInfo?.studentOrder?.id ?? null),
      orderData: new FormControl<string|null>(
        value?.orderData ?? this.mapOrder(this.studentInfo?.studentOrder)?.text ?? null),
      studyPeriodEndDate: new FormControl<Date|null>(value?.studyPeriodEndDate  ?? this.studentInfo?.educationEndDate ?? null),
      //manual
      number: new FormControl<string|null>(value?.number ?? null, Validators.required),
      dictMilitaryCommissariatId: new FormControl<string|null>(
        value?.dictMilitaryCommissariatId ?? this.studentInfo?.dictMilitaryCommissariatId ?? null, Validators.required),
      dlastName: new FormControl<string|null>(value?.dlastName ?? null),
      dfirstName: new FormControl<string|null>(value?.dfirstName ?? null),
      dmiddleName: new FormControl<string|null>(value?.dmiddleName ?? null),
      stateAccreditationCertificateData: new FormControl<string|null>(
        value?.stateAccreditationCertificateData ?? null, Validators.required),
      dissertationDefenseCouncilCode: new FormControl<string|null>(value?.dissertationDefenseCouncilCode ?? null),
      councilBaseOrganizationId: new FormControl<string|null>(value?.councilBaseOrganizationId ?? null),
      councilBaseOrganizationName: new FormControl<string|null>(value?.councilBaseOrganizationName ?? null),
      councilOrderData: new FormControl<string|null>(value?.councilOrderData ?? null),
      councilOrderDate: new FormControl<Date|null>(value?.councilOrderDate ?? null),
      dissertationDefenseDate: new FormControl<Date|null>(value?.dissertationDefenseDate ?? null),
      signatories: new FormArray(this.getFormSignatories(
        value
          ? [{
            globalSignatoryId: value.globalSignatoryId,
            fio: value.globalSignatoryFio,
            postName: value.globalSignatoryPostName
          }]
          : undefined)),
    });
  }

  private getAppendix2FormGroup(studentId?: string, value?: MilitaryFormTwo) {
    return new FormGroup({
      externalId: new FormControl<string|null>(value?.externalId ?? null),
      studentId: new FormControl<string|null>(studentId ?? null, Validators.required),
      fio: new FormControl<string|null>(value?.fio ?? this.getStudentFullName(studentId) ?? null, Validators.required),
      militaryFormPrintTemplateId: new FormControl<string|null>(
        value?.militaryFormPrintTemplateId ?? this.defaultTemplateId ?? null,
        Validators.required
      ),
      //autocomplete
      registrationDate: new FormControl<Date>(value?.registrationDate ?? new Date(), Validators.required),
      employerPost: new FormControl<string|null>(
        value?.employerPost ?? this.getPostName(this.studentInfo?.trainingLevelEnum, this.studentInfo?.courseNum) ?? null),
      actualAddress: new FormControl<string|null>(
        value?.actualAddress ?? this.studentInfo?.residenceAddress ?? null),
      dictMilitaryRankId: new FormControl<string|null>(
        value?.dictMilitaryRankId ?? this.studentInfo?.dictMilitaryRankExternalId ?? null),
      //manual
      number: new FormControl<string|null>(value?.number ?? null, Validators.required),
      orderId: new FormControl<string|null>(value?.orderId ?? null),
      isEnrolled: new FormControl<boolean|null>(value?.isEnrolled ?? null),
      dictMilitaryCommissariatId: new FormControl<string|null>(
        value?.dictMilitaryCommissariatId ?? this.studentInfo?.dictMilitaryCommissariatId ?? null, Validators.required),
      militaryCommissariatAddress: new FormControl<string|null>(
        value?.militaryCommissariatAddress ?? this.getCommissariatAddress(this.studentInfo?.dictMilitaryCommissariatId) ?? null),
      militarySpecialtyNumber: new FormControl<string|null>(value?.militarySpecialtyNumber ?? null),
      signatories: new FormArray(this.getFormSignatories(value?.signatories)),
    });
  }

  private getDriverLicenseGroup(value?: DriverLicense) {
    return new FormGroup({
      licenseSerial: new FormControl<string|null>(value?.licenseSerial ?? null),
      licenseNumber: new FormControl<string|null>(value?.licenseNumber ?? null),
      vehicleCategory: new FormControl<string|null>(value?.vehicleCategory ?? null),
      licenseIssueDate: new FormControl<Date|null>(value?.licenseIssueDate ?? null),
      licenseEndDate: new FormControl<Date|null>(value?.licenseEndDate ?? null),
    });
  }

  private getForm10Group(studentId?: string, value?: MilitaryFormTenGet) {
    return new FormGroup({
      externalId: new FormControl<string|null>(value?.externalId ?? null),
      studentId: new FormControl<string|null>(studentId ?? null, Validators.required),
      fio: new FormControl<string|null>(value?.studentInfo?.fio ?? this.getStudentFullName(studentId) ?? null),
      militaryFormPrintTemplateId: new FormControl<string|null>(
        value?.militaryFormPrintTemplateId ?? this.defaultTemplateId ?? null,
        Validators.required
      ),
      // manual
      additionalProfession: new FormControl<string|null>(value?.additionalProfession ?? null),
      driverLicense: this.getDriverLicenseGroup(value?.driverLicense),
      militarySpecialtyNumber: new FormControl<string|null>(value?.militarySpecialtyNumber ?? null),
      comment: new FormControl<string|null>(value?.comment ?? null),
    });
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {Person, PersonDto, PPSGet} from "../../../models/disciplineworkload/externals.model";
import {handleError} from "../../../helpers/errorHandle-helper";
import { Guid } from 'guid-typescript';
import { CreateQuery } from '../../../helpers/createQuery-helper';

@Injectable({
  providedIn: 'root'
})
export class PersonSalaryService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.personSalary}`;
  constructor(private http: HttpClient) { }

  add(salary: {
    departmentId: string;
    personId: string;
    postId: string | null;
    salaryTypeId: string;
    salary: number | null;
  }): Observable<Guid> {
    return this.http.post<Guid>(this.baseUrl, salary);
  }

  update(salary: {
    id: string;
    departmentId: string;
    personId: string;
    postId: string | null;
    salaryTypeId: string;
    salary: number | null;
  }): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, salary);
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + id);
  }
}

<announcement-header *ngIf="isAdmin || accessLevel"></announcement-header>
<div class="grid-layout-container container">
    <div class="title-message m-b15">
        <div class="alerts">
        Объявления и новости
        </div>
        <button kendoButton class="white-button m-r10"
                [routerLink]="['/alert']">
          Для меня
        </button>
        <button kendoButton class="blue-button"
                [routerLink]="['/author']">
            Мои
        </button>
    </div>

    <div class="messages">
      <div class="m-r30"> Всего отправленных мной: {{ total }} </div>
      <div class="m-r30 font-view"> Уникальных просмотров: {{ firstParametr}} </div>
      <div class="font-view"> В среднем просмотров: {{ calculatedParametr}} </div>
    </div>

    <div class="filter-container">
        <kendo-expansionpanel subtitle="Поиск новостей и объявлений " class="drop-down-panel" [(expanded)]="expandedState">
            <ng-template kendoExpansionPanelTitleDirective>
                <h2 class="search-title">Поиск новостей и объявлений</h2>
            </ng-template>

            <div class="search-block">
                <div class="search">
                    <div class="search-explanation">Тип</div>
                    <div>
                        <div class="search-information">
                            <div>
                                <label>
                                    <input type="radio" name="messages" [checked]="press" [value]=2 checked class="real-radio not-display" [(ngModel)]="type">
                                    <span class="custom-radio"></span>
                                Все
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" name="messages" (click)="notpress()" [value]=1 class="real-radio not-display" [(ngModel)]="type">
                                    <span class="custom-radio"></span>
                                Новости
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input type="radio" name="messages" (click)="notpress()" [value]=0 class="real-radio not-display"  [(ngModel)]="type">
                                    <span class="custom-radio"></span>
                                Объявления
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            <div class="search">
                <div class="search-explanation">Запрос</div>
                <div class="search-information">
                    <kendo-textbox  class="search-input"
                                    placeholder="Введите запрос"
                                    [clearButton]="true"
                                    name="query"
                                    [(ngModel)]="query"
                    >
                    </kendo-textbox>
                </div>
            </div>

            <div class="search">
                <div class="search-explanation"> Тег для поиска </div>
                <div class="search-information">
                     <kendo-textbox  class="search-input"
                                    placeholder="Введите тег без #"
                                    [clearButton]="true"
                                    name="tag"
                                    [(ngModel)]="tag"
                    >
                    </kendo-textbox>
                </div>
            </div>

            <div class="search">
                <div class="search-explanation"> Период публикации </div>
                <div class="search-information publication">
                    <div>
                            <kendo-datepicker
                                style="margin-right:10px; margin-left: 0;"
                                format="dd.MM.yyyy"
                                placeholder="с"
                                [(ngModel)]="start"
                                >
                                <kendo-datetimepicker-messages today="Сегодня"></kendo-datetimepicker-messages>
                            </kendo-datepicker>
                            -
                            <kendo-datepicker
                                style="margin-right:10px"
                                format="dd.MM.yyyy"
                                placeholder="по"
                                [(ngModel)]="finish"
                                >
                                <kendo-datetimepicker-messages today="Сегодня"></kendo-datetimepicker-messages>
                            </kendo-datepicker>
                        </div>
                        <div>
                            <button kendoButton
                                    class="white m-r10"
                                    (click) = "clearAllFilters()"
                            >
                            Очистить
                            </button>
                            <button kendoButton
                                    class="blue"
                            (click)="findMessages(1)"
                            >
                            Применить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </kendo-expansionpanel>
    </div>



        <div class="m-b30 m-t25">
            <button  *ngIf="accesses.length > 0" class="k-button k-button-sm k-rounded-md k-button-solid-success k-button-solid ng-star-inserted"
                    kendoButton
                    icon="plus"
                    size="small"
                    (click)="createAnnouncement()"
                >
            Добавить новое
            </button>
        </div>


        <div class="message-list">

            <div [ngClass]="{message_draft:  element.isDraft == true, message: element.isDraft == false}" *ngFor="let element of author" >
                <div [ngClass]="{panel_draft: element.isDraft, panel: !element.isDraft}"></div>
                <div class="data" [routerLink]="['/show/' , element.id]">
                    <div class="title-organization">
                        <div class="title-news"> {{ element.title}} </div>
                        <div class="display-flex">
                            <div class="draft" *ngIf="element.isDraft"> черновик </div>
                            <div> <span class="k-button-icon k-icon k-i-pencil m-r10"> </span></div>
                        </div>
                    </div>
               <!--    <div class="text-news" [innerHTML]="element.content"></div> -->
               <div style="padding: 5px;">
                    <kendo-editor  [(ngModel)]="element.content" [iframe]="false" [disabled]="true"
                                    style="border: none; opacity: inherit; height: 80px; overflow: hidden; background-color: transparent; font-family: 'Montserrat SemiBold';" >
                        <kendo-toolbar style="display: none; height: 0px; width: 0px;"></kendo-toolbar>
                    </kendo-editor>
                </div>
                    <div class="from-date-news" > {{ element.authorFio}}  </div>
                    <div class="from-date-news" > {{ element.published | date:'dd.MM.yyyy HH:mm'}} </div>
                </div>
            </div>
        </div>

        <div class="pages">
            <kendo-datapager *ngIf="total/pageSize > 1"
            [total]="messageCount"
            [pageSize]="pageSize"
            [pageSizeValues]="pageSizes"
            [info]="info"
            [skip]="skip"
            (pageChange)="onPageChange($event)"
            >
            </kendo-datapager>
        </div>
</div>



import {
  Injectable,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import {
  DialogCloseResult,
  DialogResult,
} from '@progress/kendo-angular-dialog';
import { SignatoryService } from '../../../services/gia/signatory.service';
import { getData$ } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GiaLocalSignatoryMethods {
  constructor(
    private localSignatoryService: SignatoryService,
    private notificationService: NotificationsService,
  ) {}

  async save(isNew: boolean, formGroup: FormGroup) {
    if (isNew) {
      this.localSignatoryService.addLocalSignatory(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: () => this.notificationService.showError('Произошла ошибка'),
      });
    } else {
      this.localSignatoryService
        .updateLocalSignatory(formGroup.value)
        .subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            getData$.next(true);
          },
          error: () => this.notificationService.showError('Произошла ошибка'),
        });
    }
  }

  remove(dialogResult: Observable<DialogResult>, id: string) {
    dialogResult.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да')
        this.localSignatoryService.deleteLocalSignatory(id).subscribe({
          next: () => {
            getData$.next(true);
            this.notificationService.showSuccess('Успешно');
          },
          error: () => this.notificationService.showError('Произошла ошибка'),
        });
    });
  }
}

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';
import { Guid } from 'guid-typescript';
import { DictControlAction } from 'src/app/models/education/External/controlaction.model'
import { DictControlActionService } from 'src/app/services/education/controlaction.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { CommonDictionariesUserAccessService } from 'src/app/services/useraccess/common-dictionaries-user-access.service';

@Component({
  selector: 'app-dictcontrolaction',
  templateUrl: './dictcontrolaction.component.html',
  styleUrls: ['./dictcontrolaction.component.css']
})
export class DictcontrolactionComponent implements OnInit {
  public emptyGuid: any = Guid.createEmpty();
  public controlactions: DictControlAction[] = [];
  public controlaction: DictControlAction = {
    id: this.emptyGuid.value,
    name: '',
    sName: '',
    isNotUsed: false,
    isInternalRecord: false
  }
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private controlactionService: DictControlActionService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private userAccessService: CommonDictionariesUserAccessService,
              private notificationService: NotificationsService
  ) {}

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getAllControlAction();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.controlactionService.updateDictControlAction(this.formGroup.value)
          .subscribe(
            response => {
              this.getAllControlAction();
              this.notificationService.showSuccess("Сохранено");
            },
            error => {
              this.notificationService.showError("Не удалось изменить запись");
            }
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllControlAction() {
    this.controlactionService.getAllDictControlActions()
      .subscribe(
        response => {
          this.controlactions = response;
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(
        response => {
          this.editable = response.accessLevelEducation != 1
        },error => {});
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { id?: any; isNotUsed?: any; isInternalRecord?: any; name?: any; sName?: any; serialNumber?: any; }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    isNotUsed: new FormControl(dataItem.isNotUsed, Validators.required),
    isInternalRecord: new FormControl(dataItem.isInternalRecord, Validators.required),
    name: new FormControl(dataItem.name),
    sName: new FormControl(dataItem.sName),
    serialNumber: new FormControl(dataItem.serialNumber)
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

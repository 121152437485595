<h1>Академические группы</h1>
<contingent-header></contingent-header>

<kendo-dialog title="Пожалуйста подтвердите" *ngIf="opened" (close)="closeDialog()" [minWidth]="200" [width]="450">
  <p style="margin: 30px; text-align: center;">
    Вы действительно хотите обновить названия групп? Данные будут перезаписаны
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Нет</button>
    <button kendoButton (click)="updateNameGroups()" themeColor="success">
      Да
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<div class="filter-container">

  <div id="profile" class="main-block">

    <dl class="about">
      <kendo-label class="academic-label" text="Учебный год"></kendo-label>
      <dd>
        <kendo-dropdownlist [data]="years"
                            textField="text"
                            class="dropdown"
                            valueField="value"
                            name="studyYear"
                            [(ngModel)]="year"
                            (valueChange)="onValueYearChange($event)"
                            [valuePrimitive]="true"
                            [loading]="loading">
        </kendo-dropdownlist>
        <ng-template kendoGridLoadingTemplate>
          <div class="k-loading-color"></div>
          <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
          </div>
        </ng-template>
      </dd>

      <kendo-label class="academic-label" text="Филиал"></kendo-label>
      <dd>
        <kendo-multiselect [data]="filials"
                            textField="filialSName"
                            valueField="dictFilialExternalId"
                            [valuePrimitive]="true"
                            name="filial"
                            class="dropdown"
                            (valueChange)="onFilialChange($event)"
                            [(ngModel)]="selectFilials"
                            [defaultItem]="defaultItemFilial">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span> {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </dd>

      <dd *ngIf="nextYear"></dd>
      <dd *ngIf="nextYear"><span class="required">отображаются плановые академические группы</span></dd>
      <kendo-label class="academic-label" text="Факультет (институт)"></kendo-label>
      <dd>
        <kendo-dropdownlist [data]="filteredFaculties"
                            textField="shortName"
                            valueField="facultyExternalId"
                            [valuePrimitive]="true"
                            name="studyFaculty"
                            class="dropdown"
                            (valueChange)="onFilterChange()"
                            [(ngModel)]="selectDepartment"
                            [defaultItem]="defaultItemFaculty">
        </kendo-dropdownlist>
      </dd>

      <kendo-label class="academic-label" text="Статус группы"></kendo-label>
      <dd>
        <kendo-dropdownlist [data]="groupStatus"
                            textField="name"
                            valueField="id"
                            [valuePrimitive]="true"
                            name="studyFaculty"
                            class="dropdown"
                            (valueChange)="onFilterChange()"
                            [(ngModel)]="selectStatus"
                            [defaultItem]="defaultItemFaculty">
        </kendo-dropdownlist>
      </dd>
    </dl>
  </div>
</div>

<div class="functionButtons academic-group-buttons" *ngIf="editable">
  <button kendoButton size="small" themeColor="success" (click)="addAcademicGroup()" icon="plus">Добавить группу</button>
  <!--  <button kendoButton size="small" class="right-buttons" themeColor="light"
          (click)="massOperation()"
          *ngIf="!isInOperationMode">Массовое создание групп</button>-->
  <!--<button *ngIf="nextYear" kendoButton size="small" themeColor="light" (click)="checkGroupName()" icon="refresh">Обновить названия групп</button>-->
  <button kendoButton type="button" icon="download" size="small" class="right-buttons" (click)="exportToExcel(grid)">
    <kendo-loader *ngIf="loaderExcelExport" size="small" type="converging-spinner"> </kendo-loader>
    Экспорт в Excel
  </button>
</div>

<!--<div *ngIf="nextYear" class="academic-group-buttons">
  <span class="required">
  {{!haveNames?"На выбранный учебный год уже сгенерированы названия групп. При обновлении названий групп данные будут перезаписаны"
    :"На выбранный учебный год еще не сгенерированы названия групп. Обновите названия групп"}}
</span>
</div>-->

<div class="grid-container">

  <!-- [group]="group" -->
  <kendo-grid [data]="gridView" #grid="kendoGrid"  class="workloadGrid workload-contingent"
              [pageable]="true" [pageSize]="pageSize" (pageChange)="pageChange($event)"
              [skip]="skip" [kendoGridSelectBy]="mySelectionKey"
              [groupable]="true" [group]="group" (groupChange)="onGroupChange($event)"
              [selectable]="selectableSettings" [(selectedKeys)]="selection"
              [sortable]="true" [sort]="sort" (sortChange)="sortChange($event)"
              [filterable]="true" [filter]="filter" (filterChange)="filterChange($event)"
              [resizable]="true" [loading]="loading"
              [rowClass]="rowCallback"
              (cellClick)="cellClick($event)">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-checkbox-column [showSelectAll]="selectableSettings" *ngIf="selectableSettings" [width]="80" [locked]="true"></kendo-grid-checkbox-column>

    <kendo-grid-column field="courseNum" headerClass="gridHeader" title="Курс" [width]="104">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.courseNum }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="groupName" title="Название группы" headerClass="gridHeader" class="academic-group-cursor" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells bold" [ngClass]="{bold :!dataItem.closed, open: !dataItem.closed, archived: dataItem.closed}">
              {{ dataItem.groupName }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="studentNumbers" title="Обучающихся" headerClass="gridHeader" [width]="160">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.studentNumbers }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="planName" title="УП группы" headerClass="gridHeader" class="academic-group-cursor" [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells bold" [ngClass]="{bold :!dataItem.closed, open: !dataItem.closed, archived: dataItem.closed}">
              {{ dataItem.planName }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="filialSName" title="Филиал" headerClass="gridHeader" [width]="140">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.filialSName }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter" [componentName]="componentName" [data]="filials" textField="filialSName" valueField="filialSName">
        </filter-dropdown>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="trainingLevelName" title="Уровень подготовки" headerClass="gridHeader" [width]="140">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.trainingLevelName }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter" [componentName]="componentName" [data]="trainingLevels" textField="trainingLevelName" valueField="trainingLevelName">
        </filter-dropdown>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="standardName" title="Направление подготовки" headerClass="gridHeader" [width]="250">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.standardName }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="programName" title="Профиль" headerClass="gridHeader" [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.programName }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="studyFormName" title="Форма обучения" headerClass="gridHeader" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.studyFormName }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter" [componentName]="componentName" [data]="dictStudyForms" textField="studyFormName" valueField="studyFormName">
        </filter-dropdown>
      </ng-template>
      <!-- <ng-template kendoGridCellTemplate let-dataItem>
                          {{ nameStudyForm(dataItem?.educationPlan?.dictStudyFormId)?.studyFormName }}
                        </ng-template> -->
    </kendo-grid-column>
    <kendo-grid-column field="admissionYear" title="Год поступления" headerClass="gridHeader" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.admissionYear }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="totalStudentAdmissionBenchmarks" title="План приема" headerClass="gridHeader" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
         <span class="alignCells">
              {{ dataItem.totalStudentAdmissionBenchmarks }}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <!-- <kendo-grid-column field="studEduGroup"
                         title="Удалена"
                         [width]="100">
      </kendo-grid-column> -->
    <!-- <kendo-grid-column field="facultyId" [hidden]="true">
        <ng-template kendoGridGroupHeaderTemplate let-group="group" let-dataItem>
          <span>
            {{ group.value ? "Факультет":""}} : {{(nameFaculty(group.value )?.name)}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="courseNum" [hidden]="true">
        <ng-template kendoGridGroupHeaderTemplate let-group="group" let-dataItem>
          <span>
            {{ group.value ? "Курс":""}} : {{(group.value)}} Курс
          </span>
        </ng-template>
      </kendo-grid-column> -->
    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
    <kendo-grid-excel fileName="Groups.xlsx" [fetchData]="allData"></kendo-grid-excel>
  </kendo-grid>

</div>

// Для образования
import {KafedraFields} from "../models/useraccess/enums/kafedra.enum";

export function DisplayShortNameForDuplicateKafedras(kafedras: any) {
    return kafedras.sort(function(a: any, b: any) {
        if(a.kafedraName.includes(b.kafedraName) || b.kafedraName.includes(a.kafedraName)) {
            const [aValue, bValue] = handleKafedraNames(kafedras, a, b, KafedraFields.kafedraName, KafedraFields.facultySName);
            return aValue || bValue
        }
        return a.kafedraName.localeCompare(b.kafedraName);
    })
}

// For SName field (для текущего, промежуточного контроля)
export function DisplaySNameForDuplicateKafedras(kafedras: any, nameField: KafedraFields, sNameField: KafedraFields) {
    return kafedras.sort(function(a: any, b: any) {
        if(a[nameField] === b[nameField] || b[nameField] === a[nameField]) {
            const [aValue, bValue] = handleKafedraNames(kafedras, a, b, nameField, sNameField);
            return aValue || bValue
        }
        return a[nameField].localeCompare(b[nameField]);
    })
}

function handleKafedraNames(kafedras: any, a: any, b: any, nameField: string, sNameField: string) {
    const indexA = kafedras.findIndex((item: any) => item[nameField] === a[nameField]
        && item.facultyId === a.facultyId
        && item[sNameField] === a[sNameField]);
    const indexB = kafedras.findIndex((item: any) => item[nameField] === b[nameField]
        && item.facultyId === b.facultyId
        && item[sNameField] === b[sNameField]);

    if(!kafedras[indexA][sNameField]?.includes('Кафедра')) {
        kafedras[indexA][nameField] = a[nameField] + ` (${a[sNameField]})`;
    }

    if(!kafedras[indexB][sNameField]?.includes('Кафедра')) {
        kafedras[indexB][nameField] = b[nameField] + ` (${b[sNameField]})`;
    }
    return [kafedras[indexA][nameField], kafedras[indexB][nameField]];
}

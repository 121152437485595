import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { DictAttestation } from 'src/app/models/currentcontrol/dict.model';
import { StudentByDiscipline } from 'src/app/models/currentcontrol/groupCommon.model';
import { AuthService } from 'src/app/services/auth.service';
import { CurrentControlService } from 'src/app/services/currentcontrol/currentcontrol.service';
import { DictsService } from 'src/app/services/currentcontrol/dicts.service';
import {CurrentControlUserAccessService} from "../../../services/useraccess/current-control-user-access.service";
import {CurrentDayEnd} from "../../../helpers/date-helper";

@Component({
  selector: 'app-currentdisciplinetable',
  templateUrl: './currentdisciplinetable.component.html',
  styleUrls: ['./currentdisciplinetable.component.scss'],
})
export class CurrentdisciplinetableComponent implements OnInit {
  @Input() toggleStep: string | null = null;
  @Input() eduGroupId: string = '';
  @Input() semesterNumber: number | null = null;
  @Input() electiveDisciplineId: string | null = null;
  @Input() disciplineId: string = '';
  @Input() editable?: boolean;
  @Input() contingentDate: Date = CurrentDayEnd();

  @Output() onBack: EventEmitter<string> = new EventEmitter<string>();

  public currentDate: Date = CurrentDayEnd();
  group: StudentByDiscipline[] = [];
  isLoading = false;
  dictAttestations: DictAttestation[] = [];

  constructor(
    private currentControlService: CurrentControlService,
    private dictService: DictsService,
    private authService: AuthService,
    private router: Router,
    private userAccessService:CurrentControlUserAccessService
  ) {}

  ngOnInit(): void {
    this.getGroupByDiscipline();
    this.dictService.dictAttestations$.subscribe(
      (attestations) => (this.dictAttestations = attestations)
    );
  }

  public getGroupByDiscipline() {
    if (this.semesterNumber) {
      this.isLoading = true;
      this.currentControlService
        .getSheetStudentsByDiscipline({
          disciplineId: this.disciplineId,
          eduGroupId: this.eduGroupId,
          semester: this.semesterNumber,
          electiveDisciplineId: this.electiveDisciplineId,
          contingentDate: this.contingentDate,
          isCurrentSemester: +this.currentDate !== +this.contingentDate
        })
        .subscribe((response) => {
          response.forEach((student) => {
            student.ballsByAttestation = student.disciplineMark?.reduce(
              (marks, item) => {
                if (marks) marks[item.dictAttestationId] = item;
                return marks;
              },
              {} as StudentByDiscipline['ballsByAttestation']
            );
            this.group = response;
            this.isLoading = false;
          });
        });
    }
  }

  public saveInfo(studentId: string, dictAttestationId: string) {
    const { user_login } = this.authService.decodeToken();
    const student = this.group.find(
      (student) => student.studentId === studentId
    ) as StudentByDiscipline;
    let { ball = null, hoursAbsent = null } =
      student?.ballsByAttestation?.[dictAttestationId] || {};

    if(ball?.toString() !== '' && ball?.toString() !== undefined) {
      ball = Number(ball);
      if(ball > 50 || ball < 0) ball = null;
    }
    else {
      ball = null;
    }

    if(hoursAbsent?.toString() !== '' && hoursAbsent?.toString() !== undefined) {
      hoursAbsent = Number(hoursAbsent);
    }
    else {
      hoursAbsent = null;
    }

    this.currentControlService
      .changeMarksDiscipline({
        disciplineId: this.disciplineId,
        studentId,
        login: user_login,
        electiveDisciplineId: this.electiveDisciplineId,
        dictAttestationId,
        ball: ball,
        hoursAbsent: hoursAbsent,
      })
      .subscribe((response) => {
        if (
          (!response.ball || !response.hoursAbsent) &&
          student?.ballsByAttestation
        ) {
          student.ballsByAttestation[dictAttestationId].ball =
            response.ball;
          student.ballsByAttestation[dictAttestationId].hoursAbsent =
            response.hoursAbsent;
        }
      });
  }

  public closeTable() {
    const current_control_settings = localStorage.getItem('current_control_settings');
    if (current_control_settings !== null){
      let storage = JSON.parse(current_control_settings)
      {
        let currentControlSaveSettings: any = {
          event: storage.event,
          discipline:false,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: storage.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          week: storage.week,
          contingentDate: storage.contingentDate
        }
        localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));
      }
    }
    this.onBack.emit('main');
  }

  public navigateTo(studentId: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/contingent/studentmaininfo/${studentId}`])
    );

    window.open(url, '_blank');
  }

}

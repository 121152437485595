import {AccessRights} from "./enums/accessRights.enum";

export interface UserAccess {
  // Id
  id: string;
  // Id сотрудника
  personId?: string;
  // ФИО сотрудника
  fio?: string;
  // Уровень доступа
  userAccessRight: number;
  // Справочники
  dict: number;
  // Уровень доступа к настройкам положений
  regulation: number;
  // Уровень доступа к разделу «Настройки доступа»
  accessSettings: boolean;
  // Уровень доступа к настройкам подписей
  userSignature: number;
}

export class UserAccessCreate {
  // Id сотрудника
  personId?: string = undefined;
  // Уровень доступа
  userAccessRight: number = 0;
  // Справочники
  dict: number = 0;
  // Уровень доступа к настройкам положений
  regulation: number = 0;
  // Уровень доступа к разделу «Настройки доступа»
  accessSettings: boolean = false;
  // Уровень доступа к настройкам подписей
  userSignature: number = 0;
}

export class CurrentUserAccess {
  // Администратор
  isAdmin!: boolean;
  // Права пользователя
  userAccessRight!: UserAccessRight;
}

export interface UserAccessRight {
  // Id сотрудника
  personId: string;
  // Справочники
  dict: AccessRights;
  // Уровень доступа к настройкам положений
  regulation: AccessRights;
  // Уровень доступа к настройкам подписей
  userSignature: AccessRights;
  // Уровень доступа к разделу «Настройки доступа»
  accessSettings: boolean;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";
import {PaymentState} from "../../models/contingent/paymentstate.model";

@Injectable({
  providedIn: 'root'
})
export class PaymentstateService {


  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.paymentstate}`;

  constructor(private http: HttpClient) { }

  //Get all payment state
  public getAllPaymentState(): Observable<PaymentState[]> {
    return this.http.get<PaymentState[]>(this.baseUrl + '/GetList');
  }

  //Add payment state
  addPaymentState(paymentState: PaymentState): Observable<PaymentState> {
    return this.http.post<PaymentState>(this.baseUrl, paymentState)
      .pipe(catchError(this.handleError));
  }

  //Delete payment state
  deletePaymentState(id: Guid): Observable<PaymentState> {
    return this.http.delete<PaymentState>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Update payment state
  updatePaymentState(paymentState: PaymentState): Observable<PaymentState> {
    return this.http.put<PaymentState>(this.baseUrl + '/' + paymentState.externalId, paymentState)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

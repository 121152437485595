import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AddEvent, CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {DictDocumentNames} from "../../../models/contingent/dictdocumentnames.model";
import {DictdocumentnamesService} from "../../../services/contingent/dictdocumentnames.service";
import { DictFilial } from '../../../models/contingent/dictfilial.model';
import { DictFilialService } from '../../../services/contingent/dictfilial.service';
import { DictFilter } from '../../../models/contingent/filter.model';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-documentnames',
  templateUrl: './documentnames.component.html',
  styleUrls: ['./documentnames.component.css']
})
export class DocumentnamesComponent implements OnInit {

  public dictDocumentNames: DictDocumentNames[] = [];
  public documentsByFilter: DictDocumentNames[] = [];
  public filials: DictFilial[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;
  public secondFilter: DictFilter = {
    dictFilialExternalId: undefined
  }

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private dictDocumentNamesService: DictdocumentnamesService,
              private renderer: Renderer2, private formBuilder: UntypedFormBuilder,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: ContingentUserAccessService,
              private dictFilialService: DictFilialService,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllDocumentNames();
    this.getAllFilials();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllDocumentNames();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      name: ""
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({ rowIndex,
                       columnIndex,
                       sender,
                       dataItem}: CellClickEvent): void {
    if ((this.formGroup && !this.formGroup.valid) || this.isLine) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.dictDocumentNamesService.addDocumentNames({ ...this.formGroup?.value, ...this.secondFilter })
          .subscribe({
            next:() => {
              this.getAllDocumentNames();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить название документа');
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.dictDocumentNamesService.updateDocumentNames({ ...this.formGroup?.value, ...this.secondFilter })
          .subscribe({
            next:() => {
              this.getAllDocumentNames();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllDocumentNames() {
    this.dictDocumentNamesService.getAllDocumentNames()
      .subscribe(
        response => {
          this.dictDocumentNames = response;
          this.saveCurrentFilter();
        }
      );
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
    filialSName: "Не выбрано",
    dictFilialExternalId: null,
  };

  public onValueChange(value: any): void {
    this.saveCurrentFilter();
  }

  saveCurrentFilter() {
    this.documentsByFilter = [];
    this.documentsByFilter = this.dictDocumentNames.filter(x => Guid.parse(x.filialId) == this.secondFilter.dictFilialExternalId);
  }

  public editable?: boolean = true;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          //this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.name + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.dictDocumentNamesService.deleteDocumentNames(dataItem.dictDocumentNameId)
            .subscribe({
              next:() => {
                this.getAllDocumentNames();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }


  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

}

const formGroup = (dataItem: { dictDocumentNameId?: any; name: any; order?: any; }) =>
  new UntypedFormGroup({
    dictDocumentNameId: new UntypedFormControl(dataItem.dictDocumentNameId),
    order: new UntypedFormControl(dataItem.order),
    name: new UntypedFormControl(dataItem.name, Validators.required)
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

import { Component, OnInit } from '@angular/core';
import {ExportSheduleEducationService} from 'src/app/services/education/export-schedule-education-process.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {DownloadFile} from "src/app/helpers/downloadFile-helper";

@Component({
    selector   : 'export-schedule-education-process',
    templateUrl: './export-schedule-education-process.component.html',
    styleUrls  : ['./export-schedule-education-process.component.scss'],
  })

export class ExportScheduleEducationComponent implements OnInit {

    public Data: any = [];
    public filterData: any = [];
   
    public studyYears    : any = [];
    public trainingLevels: any = [];
    public filials       : any = [];
    public studyForms    : any = [];
    public faculties     : any = [];
    public facultyFilter : any = [];
    public allCourses    : any = [];

    public year         : any | undefined;
    public trainingLevel: any = [];
    public filial       : any = [];
    public forms        : any = [];
    public faculty      : any = [];
    public courses      : any = [];
    
    public filterForm   : any = [];
    public filterYear   : any | undefined;
    public filterFaculty: any = [];
    public filterCourse : any = [];
    public filterLevel  : any = [];
    public filterFilial : any = [];

    constructor(
      private exportSheduleEducationService: ExportSheduleEducationService,
      private notificationService: NotificationsService,
    ) {}

    ngOnInit(){
      this.getData();
    }

    public getData(){
      this.exportSheduleEducationService.getData()
      .subscribe(response => {
        this.Data = response;
        this.filterData = this.Data;

        let date = new Date();
        let thisYear = date.getFullYear();
        let lastDate = new Date(thisYear, 7, 31);

        if (date <= lastDate) this.year = thisYear-1
        else this.year = thisYear;

        this.trainingLevel = [...new Set(this.Data.map((n:any) => n.dictTrainingLevelId))];
        this.filial = [...new Set(this.Data.map((n:any) => n.filialId))];
        this.forms    = [...new Set(this.Data.map((n:any) => n.dictStudyFormId))];
        this.faculty     = [...new Set(this.Data.map((n:any) => n.facultyId))];
        this.courses    = [...new Set(this.Data.map((n:any) => n.courseNumber))];

        this.getArrraysOfData();
      })
    }

    public getArrraysOfData(){
      this.allCourses = [...new Set(this.filterData.map((n:any) => n.courseNumber))];
      if (this.filterCourse.length == 0) this.courses = [...new Set(this.filterData.map((n:any) => n.courseNumber))];

      let years = [...new Set(this.filterData.map((n:any) => n.studyYear))];
      this.studyYears = years.map((studyYear, index) => {
        return {
          studyYear         : studyYear,
          studyYearFormatted: this.filterData.find((el:any) => el.studyYear ==  years[index]).studyYearFormatted,
        }
      });
     
      let dictTrainingLevelId = [...new Set(this.filterData.map((n:any) => n.dictTrainingLevelId))];
      this.trainingLevels = dictTrainingLevelId.map((dictTrainingLevelId , index) => {
        return {
          dictTrainingLevelId  : dictTrainingLevelId,
          dictTrainingLevelName: this.filterData.find((el:any) => el.dictTrainingLevelId ==  dictTrainingLevelId).dictTrainingLevelName,
        }
      });
      if (this.filterLevel.length == 0) this.trainingLevel = [...new Set(this.filterData.map((n:any) => n.dictTrainingLevelId))];
     
      let dictStudyFormId  = [...new Set(this.filterData.map((n:any) => n.dictStudyFormId))];
      this.studyForms = dictStudyFormId.map((dictStudyFormId, index) => {
        return {
          dictStudyFormId  : dictStudyFormId,
          dictStudyFormName: this.filterData.find((el:any) => el.dictStudyFormId ==  dictStudyFormId).dictStudyFormName,
        }
      });
      if (this.filterForm.length == 0) this.forms = [...new Set(this.filterData.map((n:any) => n.dictStudyFormId))];
      
      let facultyId   = [...new Set(this.filterData.map((n:any) => n.facultyId))];
      this.faculties = facultyId.map((facultyId, index) => {
        return {
          facultyId  : facultyId,
          facultyName: this.filterData.find((el:any) => el.facultyId ==  facultyId).facultyName,
        }
      });
      if (this.filterFaculty.length == 0) this.faculty = [...new Set(this.filterData.map((n:any) => n.facultyId))];
      this.facultyFilter = this.faculties;

      const filialId   = [...new Set(this.filterData.map((n: any) => n.filialId).filter((id: any) => !!id))];
      this.filials = filialId.map((filialId, index) => {
        return {
          filialId  : filialId,
          filialSName: this.filterData.find((el:any) => el.filialId ==  filialId).filialSName,
        }
      });
      if (this.filterFilial.length === 0) this.filial = filialId;
      this.filterFilial = this.filials;
    }

    public handleFilter(value: any) {
      this.facultyFilter = this.faculties.filter(
        (s: any) => s.facultyName.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    }

    public export = false;
    
    public exportForExcel(){
      
        let data = {'StudyYear': this.year, 'DictTrainingLevelId': this.trainingLevel, 'DictStudyFormId': this.forms, 'FacultyId': this.faculty, 'CourseNumber': this.courses, 'FilialId': this.filial};
      
        this.exportSheduleEducationService.exportFile(data)
          .subscribe(response =>{
              DownloadFile(response);
          })  
    }

    public valueChangeYears(value: any): void{
        if (value == undefined) {
          this.notificationService.showError('Поле "Учебный год" должно быть заполнено');
          this.export = true;
          this.filterYear = undefined;
        }
        else {
          this.filterYear = [value];
          this.export = false;
        }
        this.getfilterData(this.filterYear, this.filterForm, this.filterFaculty, this.filterCourse, this.filterLevel);  
    }

    public valueChangeForms(value: any): void{
      if (value.length == 0) this.filterForm = []
      else  this.filterForm = value;
    }

    public valueChangeFaculty(value: any): void{
      if (value.length == 0) this.filterFaculty = []
      else  this.filterFaculty = value;
    }

    public valueChangeCourse(value: any): void{
      if (value.length == 0) this.filterCourse = []
      else  this.filterCourse = value;
    }

    public valueChangeFilial(value: any): void{
      if (value.length == 0) this.filterFilial = []
      else  this.filterFilial = value;
    }

    public blur(){
      this.getfilterData(this.filterYear, this.filterForm, this.filterFaculty, this.filterCourse, this.filterLevel, this.filial );
    }

    public valueChangeLevel(value: any): void{
      if (value.length == 0) this.filterLevel = []
      else  this.filterLevel = value;
    }

    public getfilterData(filterYear?: any, filterForm?: any, filterFaculty?: any, filterCourse?: any, filterLevel?: any, filterFilial?: any){
      
      if (filterYear == undefined) filterYear = [...new Set(this.Data.map((n:any) => n.studyYear))];
      if (filterForm.length == 0) filterForm = [...new Set(this.Data.map((n:any) => n.dictStudyFormId))];
      if (filterFaculty.length == 0) filterFaculty = [...new Set(this.Data.map((n:any) => n.facultyId))];
      if (filterCourse.length == 0) filterCourse = [...new Set(this.Data.map((n:any) => n.courseNumber))];
      if (filterLevel.length == 0) filterLevel =  [...new Set(this.Data.map((n:any) => n.dictTrainingLevelId))];
      if (filterFilial.length == 0) filterFilial =  [...new Set(this.Data.map((n:any) => n.filialId))];

      this.filterData = this.Data.filter(function(item:any) {
        return (filterYear.indexOf(item.studyYear) !== -1 && filterForm.indexOf(item.dictStudyFormId) !== -1 && filterFaculty.indexOf(item.facultyId) !== -1
                && filterCourse.indexOf(item.courseNumber) !== -1 && filterLevel.indexOf(item.dictTrainingLevelId) !== -1 && filterFilial.indexOf(item.filialId) !== -1);
      })
      this.getArrraysOfData();
    }
}
import {Component, OnInit} from '@angular/core';
import {FileRestrictions} from "@progress/kendo-angular-upload";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogCloseResult, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../helpers/dialogHelper";
import {TemplateService} from "../../../../services/mfc/template.service";
import {
  Document,
  TemplateForm,
  TemplateFormDto
} from '../../../../models/mfc/templates/template.model';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {ApplicationCategoryService} from '../../../../services/mfc/dicts/application-category.service';
import {ApplicationTypeService} from '../../../../services/mfc/dicts/application-type.service';
import {FilialService} from '../../../../services/mfc/dicts/filial.service';
import {TrainingLevelService} from '../../../../services/mfc/dicts/training-level.service';
import {DepartmentService} from '../../../../services/mfc/dicts/department.service';
import {lastValueFrom} from 'rxjs';
import {ApplicationConstructorDocumentService} from '../../../../services/mfc/application-constructor-document.service';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {saveAs} from '@progress/kendo-file-saver';
import {DownloadFile} from '../../../../helpers/downloadFile-helper';
import {DocumentTypes, DocumentTypeStrings} from '../../../../models/mfc/enums/document-types.enum';


@Component({
  selector: 'mfc-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],
})
export class TemplateFormComponent implements OnInit {

  public tooltip = `Для изменения шаблона печатной формы нужно скачать файл шаблона,
    внести необходимые изменения и загрузить файл обратно в данное поле.`;
  private id = this.activatedRoute.snapshot.params['id'];
  private hasChanged = false;
  protected allowedExtensions: string = '.doc, .docx';
  protected filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  protected fileRestrictions: FileRestrictions = {
    allowedExtensions: this.allowedExtensions.split(', ')
  };

  protected template?: TemplateForm;
  protected formGroup = new FormGroup({});

  // Ng-Template
  protected readonly documentsTemplate?: {form: FormGroup, type: DocumentTypeStrings};

  protected readonly DocumentTypeStrings = DocumentTypeStrings;
  protected readonly DocumentTypes = DocumentTypes;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private applicationCategoryService: ApplicationCategoryService,
    private applicationTypeService: ApplicationTypeService,
    private filialService: FilialService,
    private trainingLevelService: TrainingLevelService,
    private departmentService: DepartmentService,
    private templateService: TemplateService,
    private documentService: ApplicationConstructorDocumentService
  ) { }

  async ngOnInit() {
    await this.getTemplateForm();
    if (this.template) this.formGroup = this.createFormGroup(this.template);
  }

  private async getTemplateForm() {
    await lastValueFrom(this.templateService.getTemplate(this.id)).then((response) => {
      this.template = this.mapTemplateData(response);
    });
  }

  private mapTemplateData(data: TemplateFormDto) {
    const trainingLevelsName = data.trainingLevels.map((item) => item.name).join(', ');
    const facultiesName = data.faculties.map((item) => item.name).join(', ');
    return {...data, trainingLevelsName, facultiesName};
  }

  private createFormGroup(template: TemplateForm) {
    const arr: { [key: string]: AbstractControl<unknown> } = {};
    arr[DocumentTypeStrings.Document] =
      this.filesFormArray(this.filterDocuments(template.documents, DocumentTypes.Document));
    arr[DocumentTypeStrings.Statement] =
      this.filesFormArray(this.filterDocuments(template.documents, DocumentTypes.Statement));

    return new FormGroup(arr);
  }

  private filterDocuments(docs: Document[], documentType: DocumentTypes) {
    return docs.filter((item) => (item.documentType ?? DocumentTypes.Document) === documentType);
  }

  private filesFormArray(files: Document[]) {
    const formArr = new FormArray<FormGroup>([]);
    files.forEach((item) => {
      formArr.push(this.patchFiles(item))
    });
    return formArr;
  }

  private patchFiles(item: Document) {
    return new FormGroup({
      documentId: new FormControl(item.documentId),
      name: new FormControl(item.name),
      fileName: new FormControl(item.fileName),
      file: new FormControl(item.fileName ? [new File([''], item.fileName)] : null),
    });
  }

  protected downloadFile(document: AbstractControl) {
    if (document.get('file')?.value[0].size) {
      DownloadFile(document.get('file')?.value[0]);
    } else if (document.value.fileName) {
      this.documentService.getDocument(this.id, document.value.documentId).subscribe({
        next: (response: Blob) => saveAs(response, document.value.fileName),
        error: (error) => this.notificationsService.showError(error),
      });
    }
  }

  protected uploadFile(document: AbstractControl, files: File[]) {
    if (!files) return;
    this.documentService.updateDocument(this.id, document.value.documentId, files[0]).subscribe({
      next: () => {
        document.get('fileName')?.patchValue(files[0].name);
      },
      error: (error) => {
        this.notificationsService.showError(error.error.Message ?? error);
        document.get('file')?.reset();
      },
    });
  }

  protected removeFile(document: AbstractControl) {
    this.documentService.deleteDocument(this.id, document.value.documentId).subscribe({
      next: () => {
        document.get('fileName')?.reset();
      },
      error: (error) => {
        this.notificationsService.showError(error.error.Message ?? error);
      },
    });
  }

  protected onValueChange() {
    this.hasChanged = true;
  }

  protected onCancel() {
    if (this.hasChanged) {
      const dialog = openDialog(this.dialogService, `Вы хотите закрыть форму без сохранения изменений?`);
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.router.navigate(['mfc/templates']);
        }
      });
    } else {
      this.router.navigate(['mfc/templates']);
    }
  }
}

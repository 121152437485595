import {Component, OnInit, ViewChild} from "@angular/core";
import {
    AddEvent,
    EditEvent,
    GridComponent,
    RemoveEvent,
    } from '@progress/kendo-angular-grid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {boolOptionsEnum} from '../../../models/diplomablanks/enum/boolOptions.enum';
import {AccessRightModel} from '../../../models/useraccess/diplomablanks/accessright.model';
import {DiplomablanksUserAccessService} from '../../../services/useraccess/diplomablanks-user-access.service';
import {GeneralDictsService} from '../../../services/diplomablanks/general-dicts.service';
import {PersonsModel} from '../../../models/diplomablanks/dicts.model';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {dialogResultHelper, openRemoveDialog} from '../../../helpers/dialogHelper';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {getData$} from '../../../../environments/environment';

@Component({
    selector: 'app-diplomablanksaccess',
    templateUrl: './diplomablanks-access.component.html',
    styleUrls: ['./diplomablanks-access.component.scss']
  })

export class DiplomablanksAccessComponent implements OnInit {

  public searchFIO: string = '';

  public accessList:  AccessRightModel[] = [];
  public gridData: AccessRightModel[] = [];
  public loading = false;
  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public position: 'top' | 'bottom' | 'both' = 'top';
  public formGroup: FormGroup | undefined;

  public persons: PersonsModel[] = [];
  public personId: string = "";

  public dropdownOptions = [
    { id: 1, text: 'Чтение' },
    { id: 2, text: 'Запись' },
  ];

  public boolOptions = boolOptionsEnum;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  constructor(
    private diplomablanksUserAccessService: DiplomablanksUserAccessService,
    private generalDictsService: GeneralDictsService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
  ) {

  }

  public ngOnInit(): void {
    getData$.subscribe(() => this.getAccessList());
    this.getAccessList();
    this.getPersons();
  }

  public getAccessList() {
    this.diplomablanksUserAccessService.getAllAccessList()
      .subscribe(response => {
        response ? this.accessList = response : this.accessList = [];
        this.gridData = this.accessList;
      })
  }

  public getPersons() {
    this.generalDictsService.getPersons()
      .subscribe(response => {
        this.persons = response;
      })
  }

  public clearData() {
    this.searchFIO = '';
    this.filterData(this.searchFIO);
  }

  public filterData(fio: string) {
    this.gridData = this.accessList.filter((el) => el.personFullName.toLowerCase().includes(fio.toLowerCase()));
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService,`${dataItem.personFullName} из настроек доступа`);
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.diplomablanksUserAccessService.deleteAccess(dataItem.externalId)
            .subscribe(response => {
                this.notificationsService.showSuccess("Удалено");
                this.getAccessList();
              },
              error => {
                this.notificationsService.showError(error);
              })
        }
      }
    });
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public deleteAccess(externalId: string) {
    this.diplomablanksUserAccessService.deleteAccess(externalId)
      .subscribe(response => {
        this.notificationsService.showSuccess("Удалено");
        this.getAccessList();
      },
      error => {
        this.notificationsService.showError(error);
      })
  }

  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      personId: '',
      personFullName: '',
      userAccessRight: 2,
      dict: 1,
      isAccessSettings: false,
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor(this.grid);
  }

  public saveCurrent(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      this.formGroup.value.personFullName = this.persons.find(el => el.externalId == this.formGroup?.value.personId)?.fio;

      if (this.isNew) {
        this.diplomablanksUserAccessService.addPersonAccess(this.formGroup.value)
            .subscribe(response => {
              this.notificationsService.showSuccess("Добавлено");
              this.getAccessList();
            },
            error => {
              this.notificationsService.showError(error);
            })
      } else {
        this.diplomablanksUserAccessService.updateAccess(this.formGroup.value)
          .subscribe(response => {
            this.notificationsService.showSuccess("Сохранено");
            this.getAccessList();
          },
          error => {
            this.notificationsService.showError(error);
          } )
      }
    }
    this.closeEditor(this.grid);
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    this.isNew = false;
    this.isLine = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public getData(value: number) {
    return this.dropdownOptions.find(el => el.id == value)?.text;
  }
}

const formGroup = (dataItem: {
    externalId?: string;
    personId?: string;
    personFullName?: string;
    userAccessRight?: number;
    dict?: number;
    isAccessSettings?: boolean;
  }) =>
    new FormGroup({
      externalId: new FormControl(dataItem.externalId),
      personId: new FormControl(dataItem.personId, Validators.required),
      personFullName: new FormControl(dataItem.personFullName),
      userAccessRight: new FormControl(dataItem.userAccessRight, Validators.required),
      dict: new FormControl(dataItem.dict, Validators.required),
      isAccessSettings: new FormControl(dataItem.isAccessSettings, Validators.required),
    });

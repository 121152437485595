export enum KendoProperties {
  Textbox = 1,
  Textarea,
  Number,
  Date,
  Boolean,
  File,
  Dropdown,
  Multiselect,
  Combobox,
  Table,
  Files,
//  Text,
//  Float
}

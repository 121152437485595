<div class="container" [formGroup]="formGroup">
  <kendo-loader *ngIf="loading" class="loader" type="converging-spinner" size="medium"></kendo-loader>
  <div class="dynamicComponents" formArrayName="properties">
    <div [style.order]="property.value | changePropertyOrder : formProperties" class="property" *ngFor="let property of formProperties.controls; index as i;">
      <div [class]="{
             row: property?.value.type?.enum !== KendoProperties.Boolean,
             checkbox: property?.value.type?.enum === KendoProperties.Boolean
           }"
           [formGroupName]="i">
        <h2 [class]="{hidden: (property.value | changePropertyOrder : formProperties) !== i}">{{property.value.name}}</h2>
        <span class='field'>
          <kendo-combobox [data]='property.value.languageData'
                          formControlName='languageCode'
                          textField='name'
                          valueField='name'
                          #combobox
                          (valueChange)='onLanguageChange($event, i, combobox)'
                          [kendoDropDownFilter]='filterSettings'
                          [valuePrimitive]='true'
                          [openOnFocus]='combobox'
                          *ngIf='property?.value.multipleByLanguage'>
          </kendo-combobox>
          <span class='dynamicField' [ngSwitch]="property?.value.type?.enum">
            <input formControlName="value" type="checkbox" kendoCheckBox
                   *ngSwitchCase="KendoProperties.Boolean"
                   (change)="onFieldChange()"/>
            <kendo-textarea [required]="property.value.required"
                            [placeholder]='property.value.multipleByLanguage ? property.value.name : ""'
                            [rows]="5" formControlName="value"
                            [readonly]="isDynamicPropertyDisabled(property.value.allowModeratorOnly)"
                            *ngSwitchCase="KendoProperties.Textarea"
                            [class]='{multipleFields: property?.value.multipleByLanguage, readOnly: isDynamicPropertyDisabled(property.value.allowModeratorOnly)}'
                            (valueChange)="onFieldChange()">
            </kendo-textarea>
            <kendo-numerictextbox [required]="property.value.required"
                                  [class]='{multipleFields: property?.value.multipleByLanguage}'
                                  formControlName="value"
                                  [changeValueOnScroll]="false" format="#.###"
                                  *ngSwitchCase="KendoProperties.Float"
                                  (valueChange)="onFieldChange()">
            </kendo-numerictextbox>
            <kendo-dropdownlist
              [data]="property.value.data!"
              textField="name"
              valueField="name"
              [valuePrimitive]="true"
              formControlName="value"
              [required]="property.value.required"
              *ngSwitchCase="KendoProperties.Dropdown"
              (valueChange)="onFieldChange()">
            </kendo-dropdownlist>
            <kendo-fileselect [required]="property.value.required"
                              [restrictions]="fileRestrictions"
                              formControlName="value"
                              *ngSwitchCase="KendoProperties.File"
                              (remove)="onFileRemove($event)"
                              (select)="onFileSelect($event, property.value.value)"
                              (valueChange)="onFieldChange()">
              <kendo-fileselect-messages
                dropFilesHere="Перетащите файлы сюда"
                select="Выберите файлы">
              </kendo-fileselect-messages>
            </kendo-fileselect>
          </span>
          <span *ngIf='property?.value.multipleByLanguage && !isDynamicPropertyDisabled(property?.value.allowModeratorOnly)' class='languageActionBtns'>
            <button (click)="convertTextCase(i)" class="convertRegBtn" kendoButton>A - a</button>
            <button rounded='full' fillMode='none' kendoButton *ngIf='!isFirstField(property.value.id, i)' (click)='deleteLanguageField(i)' icon='delete'></button>
          </span>
          <button [primary]='true' (click)='addLanguageField(property, i)' class='addLanguage' kendoButton
                  *ngIf='property?.value.multipleByLanguage && property?.value.isLast && !(isDynamicPropertyDisabled(property?.value.allowModeratorOnly))' icon='plus'>
            Добавить
          </button>
        </span>
        <span *ngIf="property.value.required" class="required">*</span>
        <a [class]="{checkbox_tooltip: property.value.type?.enum === KendoProperties.Boolean}"
           *ngIf="property.value.tooltip?.url" href="{{property.value.tooltip.url}}" target="_blank">
          {{property.value.tooltip.text}}
        </a>
      </div>
      <div class="fileSelect file-restrictions" *ngIf="property?.value.type?.enum === KendoProperties.File">
        <span></span>
        <p>До {{maxFileCount}} файлов {{getFileExtensionsString()}} (максимум {{maxFileSizeInMb}}Мб)</p>
      </div>
      <div class="row__info" *ngIf="property.value.tooltip && !property.value.tooltip?.url">
        <span></span>
        <h2>*{{property.value.tooltip}}</h2>
      </div>
      <div class="fileSelect" *ngIf="property?.value.type?.enum === KendoProperties.File && filePropertyValue(property)?.length">
        <span></span>
        <button (click)="onFilesDownload(filePropertyValue(property))"
                kendoButton
                themeColor="primary">
          Скачать все
        </button>
      </div>
    </div>
  </div>
</div>

<kendo-stacklayout [gap]="40" orientation="vertical" class="wrapper">
  <app-middlecontrolhometree [checkable]="true"
                             [checkGroup]="false"
                             [treeType]="'department'"
                             [checkedItems]="checkedNodes"
                             (selectedTrainingLevelNameChange)="onChangeTrainingLevelName($event)"
                             (onChangeChecked)="onChangeCheckedNodes($event)"
                             (onChangeCheckedSemesters)="onChangeCheckedSemesterNodes($event)"
                             (onChangeDate)="onChangeDate($event)"
                             [trainingLevelMode]="'single'"
  ></app-middlecontrolhometree>
  <kendo-stacklayout [gap]="11" orientation="vertical">
    <span class="print--label">Печать</span>
    <button
      kendoButton
      icon="print"
      (click)="openReport()"
      class="button button--action button--icon"
    >списка должников по курсу</button>
  </kendo-stacklayout>
  <button
    kendoButton
    (click)="onCancel()"
    class="button button--action"
    style="margin-top: 38px"
  >отмена</button>
</kendo-stacklayout>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ContingentOrganization } from '../../models/contingent/organization.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContingentOrganizationService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.contingentOrganization}`;

  constructor(private http: HttpClient) { }

  //Get all organization
  public getAllorganization(): Observable<ContingentOrganization[]> {
    return this.http.get<ContingentOrganization[]>(this.baseUrl);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

export class Dicts {
    dictId!: string;
    name!: string;
    dictType?: number;
}

export class GiaDictValue {
  dictId!: string;
  dictType?: string;
  name!: string;
  sortNumber?: number;
  isActive!: boolean;
}

export class GiaDictValueAdd {
  dictId!: string;
  name!: string;
  sortNumber?: number;
  isActive!: boolean;
}

export class DictDto {
  id?: string | null;
  name!: string;
  shortName?: string;
}

export class GiaDict {
  id!: string;
  name!: string;
  isHidden!: boolean;
}

export class GiaDictAdd {
  name!: string;
  isHidden!: boolean;
}

export class StudyYearDto {
  studyYear!: number;
  studyYearString!: string;
}

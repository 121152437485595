import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable, of, throwError} from "rxjs";
import {Guid} from "guid-typescript";
import {
  LocalSignatoryMCAdd,
  LocalSignatoryMCRead,
  LocalSignatoryMCUpdate
} from "../../models/middlecontrol/localsignatory.model";
import {LocalSignatoryCertificate} from "../../models/contingent/localsignatory.model";
import {SignatoriesModel} from "../../models/middlecontrol/sheets.model";
import {NotificationsService} from 'src/app/services/notifications/notifications.service'

@Injectable({
  providedIn: "root"
})
export class LocalSignatoryMCService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.session.localSignatory}`;

  constructor(private http: HttpClient,
              private notifications: NotificationsService,) {
  }

  public getSigners(trainingLevelId: string| undefined, facultyId: string): Observable<SignatoriesModel[]> {
    return this.http.get<SignatoriesModel[]>(this.baseUrl + '/GetFacultySignatory/' + facultyId + '/' + trainingLevelId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список подписантов'
          );
          return of([] as SignatoriesModel[]);
        })
      );
  }

  public get(): Observable<LocalSignatoryMCRead[]> {
    return this.http.get<LocalSignatoryMCRead[]>(this.baseUrl);
  }

  public add(data: LocalSignatoryMCAdd): Observable<Guid | string> {
    return this.http.post<Guid | string>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public update(data: LocalSignatoryMCUpdate): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public delete(localSignatoryId: Guid | string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + localSignatoryId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

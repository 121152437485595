import {ApplicationConstructorTabsEnum} from '../../enums/application-constructor-tabs.enum';

export interface Tab {
  name: string;
  selector: ApplicationConstructorTabsEnum;
  displayInAddForm: boolean;
}

export const tabs: Tab[] = [
  {name: 'Основные настройки', selector: ApplicationConstructorTabsEnum.Main, displayInAddForm: false},
  {name: 'Настройка вкладок', selector: ApplicationConstructorTabsEnum.Tabs, displayInAddForm: false},
  {name: 'Настройка согласования', selector: ApplicationConstructorTabsEnum.Approval, displayInAddForm: false},
];

<h1>Настройки доступа</h1>
<announcement-header></announcement-header>

<span class="filter">
    <h3>Поиск по ФИО</h3>
    <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
    <button kendoButton (click)="clearData()">Очистить</button>
    <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">Применить</button>
</span>

<kendo-grid class="technical"
            [kendoGridBinding]="gridData"
            [sortable]="true"
            (remove)="removeHandler($event)"
            (edit)="editHandler($event)"
            (add)="addHandler($event)">
    <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
        <div *ngIf="isInEditingMode">
          <button kendoButton (click)="cancelHandler()">Отмена</button>
          <button kendoButton themeColor="primary" [disabled]="formGroup!.invalid"  (click)="saveCurrent()">Сохранить</button>
        </div>
      </ng-template>
    <kendo-grid-column [sticky]="true" title="" [width]="35">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full"></button>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sticky]="true" title="" [width]="35">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sticky]="true" [width]="250" field="personName" headerClass="gridHeader dictionaries" title="ФИО">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [kendoDropDownFilter]="filterSettings"
        [data]="persons"
        textField="personName"
        valueField="personId"
        [valuePrimitive]="true"
        [virtual]="virtual"
        [formControl]="formGroup.get('personId')">
      </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.personName }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="faculties" headerClass="gridHeader dictionaries" title="Институт/Факультет">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="faculties"
        [(ngModel)]="facultiesEdit"
        textField="facultySName"
        [valuePrimitive]="true"
        valueField="facultyId"
        [formControl]="formGroup.get('faculties')"
        (valueChange)="valueChange($event, 'facultiesEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span></span>
          {{ dataItems.length }} выбрано
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.faculties, 'facultySName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="courses" headerClass="gridHeader dictionaries" title="Курс">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="courses"
        textField="name"
        [(ngModel)]="coursesEdit"
        valueField="courseNumber"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('courses')"
        (valueChange)="valueChange($event, 'coursesEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span></span>
          {{ dataItems.length }} выбрано
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.courses, 'courseNumber') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="195" field="eduGroups" headerClass="gridHeader dictionaries" title="Группа">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="eduGroups"
        textField="eduGroupName"
        [(ngModel)]="eduGroupsEdit"
        valueField="eduGroupId"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('eduGroups')"
        (valueChange)="valueChange($event, 'eduGroupsEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span></span>
          {{ dataItems.length }} выбрано
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.eduGroups, 'eduGroupName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [sortable]="false" [width]="400" field="departments" headerClass="gridHeader dictionaries" title="Подразделение">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="departments"
        [(ngModel)]="departmentsEdit"
        [valuePrimitive]="true"
        textField="departmentName"
        valueField="departmentId"
        [formControl]="formGroup.get('departments')"
        (valueChange)="valueChange($event, 'departmentsEdit')"
        >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span></span>
          {{ dataItems.length }} выбрано
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ getList(dataItem.departments, 'departmentName') }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="140" field="userAccessLevel" headerClass="gridHeader dictionaries" title="Настройки доступа"
                       *ngIf="isAdmin">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('userAccessLevel')"></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.userAccessLevel ? 'Да' : 'Нет' }}
          </span>
      </ng-template>
    </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

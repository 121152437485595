import { Component, OnInit } from '@angular/core';
import { CheckableSettings, NodeClickEvent, TreeItem } from '@progress/kendo-angular-treeview';
import { Location } from '@angular/common';
import { TreeCommonModel } from '../../../models/middlecontrol/trees.model';
import { TreesService } from '../../../services/middlecontrol/trees.service';
import { DictsService } from "../../../services/middlecontrol/dicts.service";
import { SemesterYearsModel } from "../../../models/middlecontrol/semesterYears.model";
import {GetUniversityReportData, TrainingLevels} from "../../../models/middlecontrol/massPrintSheet.model";
import { MassPrintSheetService } from "../../../services/middlecontrol/massPrintSheet.service";
import { SheetService } from "../../../services/middlecontrol/sheet.service";
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {forEach} from "lodash";

@Component({
  selector: 'app-middlecontrolprintreport',
  templateUrl: './middlecontrolprintreport.component.html',
  styleUrls: ['./middlecontrolprintreport.component.scss'],
})
export class MiddlecontrolprintreportComponent implements OnInit {
  public data: SemesterYearsModel[] = [];
  public yearData: SemesterYearsModel[] = [];
  public semesterData: SemesterYearsModel[] = [];
  public selectedYear: SemesterYearsModel = {} as SemesterYearsModel;
  public selectedSemester: SemesterYearsModel = {} as SemesterYearsModel;
  public dataTree: TreeCommonModel[] = [];
  public treeSettings: CheckableSettings = { enabled: true, mode: "multiple" };
  public checkedStudyForm: string[] = [];
  public expandedKeys: string[] = [];
  public loadingReport = false;
  public loadingTreeData = false;

  constructor(
    private location: Location,
    private treesService: TreesService,
    private dictsService: DictsService,
    private massPrintService: MassPrintSheetService,
    private sheetService: SheetService,
    private notificationService: NotificationsService,
  ) {}

  ngOnInit(): void {
    this.loadingTreeData = true;
    this.treesService.trainingLevelTree$.subscribe((response) => {
      this.dataTree = response;
      if (response.length) this.loadingTreeData = false;
    });
    this.treesService.updateTrainingLevelTree();
    this.dictsService.semesterYears$.subscribe(response => {
      this.data = response;
      this.yearData = response.filter(item => item.semesterInYear === 1);
      this.selectedYear = response.find(item => item.isCurrent) || response[0];
      this.semesterData = response.filter(item => item.semesterYear === this.selectedYear.semesterYear);
      this.selectedSemester = response.find(item => item.isCurrent) || response[0];
    });
    this.dictsService.updateDictSemesterYears();
  }

  public onSelectYear(year: SemesterYearsModel): void {
    this.selectedYear = year;
    this.semesterData = this.data.filter(item => item.semesterYear === year.semesterYear);
  }

  public onSelectSemester(semester: SemesterYearsModel): void {
    this.selectedSemester = semester;
  }

  public onChangeCheckedStudyForm(list: string[]) {
    this.checkedStudyForm = list;
  }

  public onCreateReport(): void {
    const selectedStudyForm = this.checkedStudyForm.filter(item => item.split('_').length === 2);
    if (selectedStudyForm.length) {
      this.loadingReport = true;
      const data = this.getData(selectedStudyForm);
      this.massPrintService.getUniversityReport(data)
        .subscribe(response => {
          DownloadFile(response);
          this.loadingReport = false;
        },
          () => this.loadingReport = false);
    } else {
      this.notificationService.showError('Форма образования не выбрана');
    }
  }

  private getData(selectedStudyForms: string[]): GetUniversityReportData {
    let trainingLevelIds:string[] = [];
    let trainingLevels: TrainingLevels[] = [];

    selectedStudyForms.forEach(x => {
      trainingLevelIds.push(x.split('_')[0]);
    });

    const uniqueTrainingLevelIds = Array.from(new Set(trainingLevelIds));

    uniqueTrainingLevelIds.forEach(i => {
      let studyFormIds:string[] = []
      selectedStudyForms.forEach(x => {
        if (i == x.split('_')[0]){
          studyFormIds.push(x.split('_')[1])
        }
      })
      let trainingLevel: TrainingLevels =
        {
          trainingLevelId: i,
          studyFormIds: studyFormIds
        };

      trainingLevels.push(trainingLevel)

    });

    const reportData = new GetUniversityReportData();
    reportData.studyYearBegin = this.selectedYear.semesterYear;
    reportData.semesterNum = this.selectedSemester.semesterInYear;
    reportData.trainingLevels = trainingLevels;
    return reportData
  }

  public onCancel(): void {
    this.location.back();
  }

  public onNodeClick(event: NodeClickEvent): void {
    if (event.item) {
      const index = this.expandedKeys.indexOf(event.item.index);
      if (index > -1) this.expandedKeys.splice(index, 1);
      else this.expandedKeys.push(event.item.index);
    }
  }
  public getLocalStorage(){
    let storage = localStorage.getItem('middle_control_settings');
    if (storage !== null) return JSON.parse(storage)
    else return null
  }
  public isExpanded = (dataItem: any, index: string) => {
    const storage = this.getLocalStorage();
    if (storage !== null){
      let text = storage.changeGroup;
      let all = document.querySelectorAll("span");
      let arraySelector = Array.from(all);
      let span = arraySelector.findIndex(el => el.innerText == text);
      if (span > -1) all[span].classList.add('k-selected');
    }
    return this.expandedKeys.indexOf(index) > -1;
  };

  public onSelectTreeElem(event: TreeItem) {
    if (!event.dataItem.value) {
      const checkedIndex = this.checkedStudyForm.indexOf(event.dataItem.id);
      if (checkedIndex > -1) {
        this.checkedStudyForm = this.checkedStudyForm.filter(id => id !== event.dataItem.id);
      } else {
        this.checkedStudyForm = [...this.checkedStudyForm, event.dataItem.id];
      }
    }
  }

}

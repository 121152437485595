export enum EditMode {
  customer,
  employer,
  expulsionInfo,
  labourActivity,
  complaint,
}

export enum OrganizationFields {
  organizationName = 'organizationName',
  ogrn = 'ogrn',
  inn = 'inn',
  kpp = 'kpp',
}

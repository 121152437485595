
<contingent-header></contingent-header>
<h1>Семейное положение</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="statuses" (remove)="removeHandler($event)"
              [pageable]="true"  [pageSize]="pageSize"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand  size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()"
                size="small">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()"
                size="small">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column field="maritalStatus"
                       title="Семейное положение"
                       headerClass="gridHeader"
                       [width]="350">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-textbox size="small"
                                              [formControl]="formGroup.get('maritalStatus')"
                                              [clearButton]="true">
                               </kendo-textbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.maritalStatus}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isMale"
                       editor="boolean"
                       title="Пол"
                       headerClass="gridHeader"
                       [width]="150">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          [data]="dropdownOptions"
          textField="text"
          valueField="value"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('isMale')"></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.isMale?"Мужской":"Женский" }}
          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="order"
                       title="Порядковый номер для сортировки при отображении"
                       headerClass="gridHeader"
                       [width]="200">
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
                           <span class="alignCells">
                               <kendo-numerictextbox [style.width.px]="170"
                                                     [value]="dataItem.order"
                                                     [min]="1"
                                                     format="n0"
                                                     [decimals]="0"
                                                     [formControl]="formGroup.get('order')">
                               </kendo-numerictextbox>
                           </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.order}}
            </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand
                  themeColor="secondary"
                  icon="delete"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>
</div>
<div kendoDialogContainer></div>

export interface Role {
  id: string;
  name: string;
  sortNumber?: number;
  allowDelete: boolean;
}

export enum RoleType {
  // Все
  All = 'all',
  // Инициатор
  Initiator = 'initiator'
}

export const roleAccess: Role[] = [
  {id: RoleType.All, name: 'Все', allowDelete: false},
  {id: RoleType.Initiator, name: 'Инициатор', allowDelete: false}
]

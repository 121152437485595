<contingent-header></contingent-header>
<h1>Учебные заведения</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="dictinstitutions" (remove)="removeHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true">

    <ng-template kendoGridToolbarTemplate position="both">
      <button kendoGridAddCommand size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()"
                size="small">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()"
                size="small">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictMilitaryRankId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="institutionName"
                       title="Наименование учебного заведения"
                       [width]="500">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [formControl]="formGroup.get('institutionName')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="70">
      <ng-template kendoGridCellTemplate>
        <button kendoGridRemoveCommand
                themeColor="secondary"

                icon="delete"
                fillMode="flat"
                size="small"
                rounded="full">
        </button>

      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {openDialog, openRemoveDialog} from '../../../../../helpers/dialogHelper';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {NotificationsService} from '../../../../../services/notifications/notifications.service';
import {EditEvent, GridComponent, RemoveEvent, SaveEvent} from '@progress/kendo-angular-grid';
import {ApprovalSettingsService} from '../../../../../services/mfc/constructor-approval-settings.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApprovalSettingsForm, CreateApprovalSettingsForm} from '../../../../../models/mfc/applicationConstructor/form/constructor-approval-settings.model';
import {RoleService} from '../../../../../services/mfc/dicts/role.service';
import {Role} from '../../../../../models/mfc/dicts/role.model';
import {ConstructorDocument} from '../../../../../models/mfc/applicationConstructor/constructor-document.model';
import {boolOptions} from '../../../../../models/mfc/enums/bool-options.enum';
import {ApprovalType} from "../../../../../models/mfc/dicts/approval-type.model";
import {ApprovalStatus} from "../../../../../models/mfc/dicts/approval-status.model";
import {departmentTypes, departmentTypesMap} from "../../../../../models/mfc/enums/department-type.enum";
import {ApprovalTypeService} from "../../../../../services/mfc/dicts/approval-type.service";
import {ApprovalStatusService} from "../../../../../services/mfc/dicts/approval-status.service";
import {SignatoryRoleService} from "../../../../../services/mfc/dicts/signatory-role.service";
import {DictSignatoryRole} from "../../../../../models/mfc/dicts/signatory-role.model";
import {NumericTextBoxComponent} from "@progress/kendo-angular-inputs";
import {createMap} from "../../../../../helpers/map-helper";
import {lastValueFrom, Subscription} from 'rxjs';
import {
  ApplicationConstructorEditFormService
} from '../../../../../services/mfc/application-constructor-edit-form.service';
import {ApplicationConstructorTabsEnum} from '../../../../../models/mfc/enums/application-constructor-tabs.enum';
import {DocumentTypes} from '../../../../../models/mfc/enums/document-types.enum';

@Component({
  selector: 'mfc-approval-settings',
  templateUrl: './approval-settings.component.html',
  styleUrls: ['./approval-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationConstructorApprovalSettingsComponent implements OnInit {

  @Input() editable = false;
  @Input() documents: ConstructorDocument[] = [];
  @Input() typeReceiptIds: string[] = [];

  @ViewChild('grid') protected readonly grid?: GridComponent;

  private id = this.activatedRoute.snapshot.params['id'];
  private url = this.activatedRoute.snapshot.url.join('/');
  private isChanged = false;

  protected loading = false;
  protected editMode = false;
  protected lastRowIndex?: number;
  protected isNew = false;

  protected formGroup = new FormGroup({});
  protected gridData: ApprovalSettingsForm[] = this.approvalSettingsService.approvalSettings$.value;
  protected filteredDocuments: ConstructorDocument[] = [];

  protected approvalTypes: ApprovalType[] = [];
  protected roles: Role[] = [];
  protected signatoryRoles: DictSignatoryRole[] = [];
  protected approvalStatuses: ApprovalStatus[] = [];
  protected readonly departmentTypes = departmentTypes;
  protected readonly departmentTypesMap = departmentTypesMap;
  protected reworkNumbers: number[] = [];

  protected readonly departmentTypeTooltip = `В столбце необходимо выбрать тип подразделения,
  к которому относятся подписанты выбранной в столбце “Категории подписантов” категории. По выбранному типу будет идентифицироваться подразделение,
  подписанты которого должны подписать документ в заявке.`;
  protected readonly boolOptions = boolOptions;

  protected rolesMap = new Map<unknown, unknown>([]);
  protected approvalTypesMap = new Map<unknown, unknown>([]);
  protected approvalStatusesMap = new Map<unknown, unknown>([]);
  protected signatoryRolesMap = new Map<unknown, unknown>([]);
  protected documentsMap = new Map<unknown, unknown>([]);

  private checkChangesSubscription$!: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private roleService: RoleService,
    private approvalSettingsService: ApprovalSettingsService,
    private approvalTypeService: ApprovalTypeService,
    private approvalStatusService: ApprovalStatusService,
    private signatoryRoleService: SignatoryRoleService,
    private editFormService: ApplicationConstructorEditFormService
  ) { }

  async ngOnInit() {
    await this.getDicts();
    this.getApprovalSettings();
    this.filteredDocuments = this.documents.filter((item) => item.documentType === DocumentTypes.Document);
    this.documentsMap = createMap({value: this.filteredDocuments, valueField: 'externalId', textField: 'name'});
    this.checkChangesSubscription$ = this.editFormService.checkChanges$.subscribe(value => {
      if (value === ApplicationConstructorTabsEnum.Approval) {
        this.editFormService.setHasChanges(this.isChanged);
      }
    });
  }

  private checkSignatoryRequirements() {
    const approvalTypeId = this.formGroup.get('dictApprovalTypeId')?.value;

    const signatoryRequired = !!this.approvalTypes.find(type => type.id === approvalTypeId)?.isSignatoryRequired;

    if (signatoryRequired) {
      this.formGroup.get('dictRoleId')?.disable();
      this.formGroup.get('applicationConstructorDocumentId')?.enable();
      this.formGroup.get('dictSignatoryRoleId')?.enable();
      this.formGroup.get('departmentType')?.enable();
    } else {
      this.formGroup.get('dictRoleId')?.enable();
      this.formGroup.get('applicationConstructorDocumentId')?.disable();
      this.formGroup.get('dictSignatoryRoleId')?.disable();
      this.formGroup.get('departmentType')?.disable();
    }
  }

  private async getDicts() {
    await this.getRoles();
    await this.getApprovalTypes();
    await this.getSignatoryRoles();
    await this.getApprovalStatuses();
  }

  private async getSignatoryRoles() {
    await lastValueFrom(this.signatoryRoleService.getSignatoryRoles()).then(response => {
      this.signatoryRoles = response;
      this.signatoryRolesMap = createMap({value: response, valueField: 'externalId', textField: 'name'})
    });
  }

  private async getRoles() {
    await lastValueFrom(this.roleService.getRoles()).then((response) => {
      this.roles = response;
      this.rolesMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private async getApprovalTypes() {
    await lastValueFrom(this.approvalTypeService.getApprovalTypes()).then(response => {
      this.approvalTypes = response;
      this.approvalTypesMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private async getApprovalStatuses() {
    await lastValueFrom(this.approvalStatusService.getApprovalStatuses()).then(response => {
      this.approvalStatuses = response;
      this.approvalStatusesMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private getApprovalSettings() {
    this.loading = true;
    this.approvalSettingsService.getSettings(this.id).subscribe({
      next: (response) => {
        this.gridData = response;
        this.loading = false;
        this.cdRef.detectChanges();
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  protected addRow() {
    this.lastRowIndex = -1;
    this.editMode = true;
    this.isNew = true;
    this.formGroup = this.createFormGroup();
    this.grid?.addRow(this.formGroup);
  }

  private getReworkNumbers(value: number) {
    if (value < 1) {
      return [];
    }
    return Array.from({length: value - 1}, (_, i) => i + 1);
  }

  protected onNumberInApprovalSequenceBlur({value}: NumericTextBoxComponent) {
    this.reworkNumbers = this.getReworkNumbers(value);
  }

  protected editRow({sender, rowIndex, dataItem}: EditEvent) {
    if (this.editMode) return;
    this.reworkNumbers = this.getReworkNumbers(dataItem.numberInApprovalSequence);
    this.editMode = true;
    this.lastRowIndex = rowIndex;
    this.formGroup = this.createFormGroup(dataItem);
    this.checkSignatoryRequirements();
    sender.editRow(rowIndex, this.formGroup);
  }

  private createFormGroup(dataItem?: ApprovalSettingsForm) {
    const form = new CreateApprovalSettingsForm();
    const arr: {[key: string]: AbstractControl<unknown>} = {};
    Object.keys(form).forEach((key) => {
      const value = form[key as keyof typeof form];
      arr[key] = new FormControl(
        key === 'numberInApprovalSequence' && this.isNew ? this.gridData.length + 1 : (value ?? dataItem?.[key as keyof typeof dataItem]),
        value === null ? Validators.required : null
      );
    });
    return new FormGroup(arr);
  }

  protected onCancel() {
    this.grid?.closeRow(this.lastRowIndex);
    this.lastRowIndex = undefined;
    this.editMode = false;
    this.isNew = false;
    this.formGroup = new FormGroup({});
  }

  protected onSave({dataItem, isNew, rowIndex}: SaveEvent) {
    isNew ? this.gridData.push(dataItem) : this.gridData[rowIndex] = dataItem;
    this.isChanged = true;
    this.onCancel();
  }

  protected onRemove({rowIndex}: RemoveEvent) {
    if (this.editMode) return;
    openRemoveDialog(this.dialogService, `${rowIndex + 1}й этап согласования`).result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.gridData.splice(rowIndex, 1);
        this.isChanged = true;
        this.cdRef.detectChanges();
      }
    });
  }

  protected reorderRow(from: number, to: number) {
    if (this.editMode) return;
    if (to >= 0 || to < this.gridData.length) {
      const item = this.gridData.splice(from, 1)[0];
      this.gridData.splice(to, 0, item);
      this.isChanged = true;
    }
  }

  protected approvalTypeChange() {
    this.checkSignatoryRequirements();
  }

  protected filterSelect(rowIndex: number) {
    this.router.navigateByUrl(`${this.url}/additionalFilters/${rowIndex}`);
  }

  protected cancelChanges() {
    if (!this.isChanged) return;
    const dialog: DialogRef = openDialog(
      this.dialogService, ' Вы уверены, что хотите отменить изменения?');
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.isChanged = false;
        this.getApprovalSettings();
        this.cdRef.detectChanges();
      }
    });
  }

  protected saveChanges() {
    this.approvalSettingsService.updateSettings(this.id, this.gridData).subscribe({
      next: () => {
        this.isChanged = false;
        this.notificationsService.showSuccess('Успешно');
        this.getApprovalSettings();
        this.cdRef.detectChanges();
      },
      error: (error) => this.notificationsService.showError(error.error)
    });
  }

  ngOnDestroy() {
    this.checkChangesSubscription$.unsubscribe();
  }
}

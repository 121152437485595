import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { LKPersonIdentificationNumber } from 'src/app/models/person/lkPersonIdentificationNumber.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LKPersonIdentificationNumberService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.lkPersonIdentificationNumber}`;

  constructor(private http: HttpClient) { }

  //Get IdentificationNumbers
  public getIdentificationNumbers(): Observable<LKPersonIdentificationNumber[]> {
    return this.http.get<LKPersonIdentificationNumber[]>(this.baseUrl + '/GetIdentificationNumbers/')
      .pipe(
        catchError(this.handleError));
  }


  //Get IdentificationNumbers
  public getIdentificationNumbersByPersonId(personId: string): Observable<LKPersonIdentificationNumber[]> {
    return this.http.get<LKPersonIdentificationNumber[]>(this.baseUrl + '/GetIdentificationNumbersByPersonId/' + personId)
      .pipe(
        catchError(this.handleError));
  }

  //Add IdentificationNumber
  public addIdentificationNumber(identificationNumber: LKPersonIdentificationNumber): Observable<LKPersonIdentificationNumber> {
    return this.http.post<LKPersonIdentificationNumber>(this.baseUrl + '/CreateIdentificationNumber/', identificationNumber)
      .pipe(catchError(this.handleError));
  }

  //Update IdentificationNumber
  public updateIdentificationNumber(identificationNumber: LKPersonIdentificationNumber): Observable<LKPersonIdentificationNumber> {
    return this.http.put<LKPersonIdentificationNumber>(this.baseUrl + '/UpdateIdentificationNumber/' + identificationNumber.id, identificationNumber)
      .pipe(catchError(this.handleError));
  }

  //Delete IdentificationNumber
  public deleteIdentificationNumber(id: number): Observable<LKPersonIdentificationNumber> {
    return this.http.delete<LKPersonIdentificationNumber>(this.baseUrl + '/DeleteIdentificationNumber/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {ApplicationConstructor} from '../../../../models/mfc/applicationConstructor/application-constructor.model';
import {ApplicationConstructorTabsEnum} from '../../../../models/mfc/enums/application-constructor-tabs.enum';

@Component({
  selector: 'mfc-application-constructor-tab',
  templateUrl: './application-constructor-tab.component.html',
  styleUrls: ['./application-constructor-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationConstructorTabComponent implements OnInit, OnChanges {

  @Input() tabName = '';
  @Input() tabSelector = '';
  @Input() editable = false;
  @Input() settings?: ApplicationConstructor;

  protected readonly ApplicationConstructorTabsEnum = ApplicationConstructorTabsEnum;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges() {

  }
}

import { Component, OnInit } from '@angular/core';
import { ParametersAuditorium } from 'src/app/models/classroom/parameters-auditorium.model';
import { ParametersAuditoriumService } from 'src/app/services/classroom/parameters-auditorium.service';
import { TypesAuditoriumService } from 'src/app/services/classroom/types-auditorium.service';
import { AssignAuditoriumService } from 'src/app/services/classroom/assign-auditorium.service';
import { BuildingService } from 'src/app/services/classroom/building.service';
import { ClassroomFundService } from 'src/app/services/classroom/classroom-fund.service';
import { ActivatedRoute} from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ClassRoomUserAccessService } from '../../../services/useraccess/classroom-user-access.service';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { Router} from "@angular/router";
import { checkRole } from "../../../helpers/token/role-check";
import { TokenStorageService } from "../../../services/token.service";
import { JwtHelperService}  from "@auth0/angular-jwt";
import { Role } from "../../../models/useraccess/role";
import { TableBodyComponent } from '@progress/kendo-angular-grid';
import { invokeMap } from 'lodash';
import { items } from 'src/app/models/contingent/menu-items';

@Component({
    selector: 'app-parameters-auditorium',
    templateUrl: './parameters-auditorium.component.html',
    styleUrls: ['./parameters-auditorium.component.scss']
})

export class ParametersAuditoriumComponent implements OnInit{

    public parameters: any = [];
    public id: string = "";
    public classrooms: any = [];
    public classroomForEdit: any = [];

    public buildings: any = [];
    public types: any = [];
    public owners: any = [];
    public assigns: any = [];

    public building: string = "";
    public type: string = "";
    public owner: any = null;
    public assign: any = null;
    public number: string = "";
    public name: string = "";

    public exists: boolean = false;
    public accessLevel: boolean = false;
    public accesses: any = [];
    public personId: string = "";
    public isAdmin: boolean;

    public buildingName: string = "";
    public assignName: string = "";
    public ownerName: string = "";

    public filterTypes: any = [];
    public filterAssigns: any = [];

constructor(
    private parametersAuditoriumService: ParametersAuditoriumService,
    private activatedroute: ActivatedRoute,
    private typesAuditoriumService: TypesAuditoriumService,
    private assignAuditoriumService: AssignAuditoriumService,
    private buildingService: BuildingService,
    private classroomFundService: ClassroomFundService,
    private router: Router,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: ClassRoomUserAccessService,
    private personService: PersonService,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
)
    { this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);}

ngOnInit() {
    let parameter = this.activatedroute.snapshot.params['id'];
   
    if (parameter !== undefined){
        this.getParameters(this.activatedroute.snapshot.params['id']);
    }
    else {
        this.getAccess();   
    }   
    
}

    public getParameters(id: number){
        this.parametersAuditoriumService.getParameters(id)
        .subscribe( response => {
           
            this.parameters = response;
            this.id = this.parameters.id;
            this.number = this.parameters.number;
            this.name = this.parameters.name;
            this.building = this.parameters.buildingId;
            this.type = this.parameters.typeId;
            this.assign = this.parameters.purposeId;
            this.owner = this.parameters.departmentId;
            this.buildingName = this.parameters.building;
            this.accessLevel = this.parameters.canEdit;
            this.assignName = this.parameters.purpose;
            this.ownerName = this.parameters.department;
           
            this.getAllowedBuilding();
            this.getAllTypesAuditorium();
            this.getAllowedAssigns();
            this.getAllowedOwners();
            this.getAllClassroom();
        });
    }

    public getAllowedBuilding() {
        this.buildingService.getAllowedBuildings()
          .subscribe(response => {
              this.buildings = response;
             
              if (this.accessLevel == false){
                this.buildings = [];
                var element: any = new Object();
                element.buildingId = this.building;
                element.buildingName = this.buildingName;
                this.buildings.push(element);  
              }
           },
           error => {
              console.log(error.error);
           });
    }

    public getAllTypesAuditorium() {
        this.typesAuditoriumService.getAllTypesAuditorium()
        .subscribe(response => {
            this.types = response;
            this.filterTypes = this.types;
        },
        error => {
        console.log(error.error);
        });
    }

    public getAllowedAssigns() {
        this.assignAuditoriumService.getAllowedAssigns()
        .subscribe(response => {
            this.assigns = response;
            if (this.accessLevel == false){
                this.assigns = [];
                var element: any = new Object();
                element.classRoomPurposeId = this.assign;
                element.classRoomPurposeName = this.assignName;
                this.assigns.push(element);
            }
            this.filterAssigns = this.assigns;
        },
        error => {
        console.log(error.error);
        });
    }

    public getAllowedOwners() {
        this.classroomFundService.getAllowedOwners()
        .subscribe(response => {
            this.owners = response;
            if (this.accessLevel == false){
                this.owners = [];
                var element: any = new Object();
                element.departmentId = this.owner;
                element.departmentName = this.ownerName;
                this.owners.push(element);
            }     
        },
        error => {
        console.log(error.error);
        });
    }

    public getAllClassroom() {
        this.classroomFundService.getAllClassroom()
        .subscribe(response => {
          this.classrooms = response;

            this.classroomForEdit = [];
            for (let i=0; i < this.classrooms.length; i++){
                if (!(this.number == this.classrooms[i].number && this.buildingName == this.classrooms[i].building)){
                    this.classroomForEdit.push(this.classrooms[i]);
                }
            }
        });
    }

    public addClass(){
        if (this.assign == undefined) this.assign = null;
        if (this.owner == undefined) this.owner = null;
        let body = {'number': this.number, 'name': this.name, 'buildingId': this.building, 'classRoomTypeId': this.type, 'classRoomPurposeId': this.assign, 'departmentId': this.owner};
       
        this.exists = false;
        let buildingName = "";     

        buildingName = this.buildings.find((item: any) => item.buildingId == this.building).buildingName;
    
        this.classroomFundService.getAllClassroom()
        .subscribe(response => {
          this.classrooms = response;
            for (let i=0; i < this.classrooms.length; i++){
                if (this.number == this.classrooms[i].number && buildingName == this.classrooms[i].building){
                    this.notificationService.showError("Невозможно сохранить аудиторию, так как она уже существует");
                    this.exists = true ;
                }
            }

            if (this.exists == false){
                this.parametersAuditoriumService.addClass(body)
                .subscribe(
                response => {
                    this.notificationService.showSuccess("Сохранено");
                    this.router.navigate(['/classroom']);
                })
            }
        });     
    }

    public deleteClass(id: string){
        const dialog: DialogRef = this.dialogService.open({
            title: "Пожалуйста подтвердите",
            content: `Вы действительно хотите удалить аудиторию ${this.name.toLowerCase()}?`,
            actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
            width: 450,
            height: 200,
            minWidth: 250,
          });

        dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
                if(result.text == "Да"){
                    this.parametersAuditoriumService.deleteClass(id)
                    .subscribe( response => {
                        this.notificationService.showSuccess("Удалено");
                        this.router.navigate(['/classroom']); 
                    });
                }
            }
        });
    }

    public editClass(id: string){
        if (this.assign == undefined) this.assign = null;
        if (this.owner == undefined) this.owner = null;
        let body = {'number': this.number, 'name': this.name, 'buildingId': this.building, 'classRoomTypeId': this.type, 'classRoomPurposeId': this.assign, 'departmentId': this.owner};

        this.exists = false;
        let buildingName = "";

        buildingName = this.buildings.find((item: any) => item.buildingId == this.building).buildingName;
     
        for (let i=0; i < this.classroomForEdit.length; i++){
            if (this.number == this.classroomForEdit[i].number && buildingName == this.classroomForEdit[i].building){
                this.notificationService.showError("Невозможно сохранить аудиторию, так как она уже существует");
                this.exists = true ;
                break;
            }
        }
        if (this.exists == false){
            this.parametersAuditoriumService.editClass(body, id)
            .subscribe(
                response => {
                    this.notificationService.showSuccess("Сохранено");
                    this.router.navigate(['/classroom']);
            }) 
        }
    }

    public close(){
        this.exists = false;
    }

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
    };

    public getAccess()  {
        this.userAccessService.getAccessLevel()
        .subscribe(response => {
         
          if (response.canEdit == true){
            this.accessLevel = true;
          }
          this.getAllowedBuilding();
          this.getAllTypesAuditorium();
          this.getAllowedAssigns();
          this.getAllowedOwners(); 
        })
    }

    public typeFilter(value: any) {
        this.filterTypes = this.types.filter(
          (s:any) => s.classRoomTypeName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    public assignFilter(value: any) {
        this.filterAssigns = this.assigns.filter(
          (s:any) => s.classRoomPurposeName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DictAdditionalFields } from '../../models/contingent/dictadditionalfields.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DictAdditionalfieldsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictadditionalfields}`;

  constructor(private http: HttpClient) { }

  //Get all additionalfields
  public getAlladditionalfields(): Observable<DictAdditionalFields[]> {
    return this.http.get<DictAdditionalFields[]>(this.baseUrl);
  }

  //Add additionalfields
  addadditionalfields(additionalfields: DictAdditionalFields): Observable<DictAdditionalFields> {
    return this.http.post<DictAdditionalFields>(this.baseUrl, additionalfields)
      .pipe(catchError(this.handleError));
  }

  //Delete additionalfields
  deleteadditionalfields(id: string): Observable<DictAdditionalFields> {
    return this.http.delete<DictAdditionalFields>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit additionalfields
  updateadditionalfields(additionalfields: DictAdditionalFields): Observable<DictAdditionalFields> {
    return this.http.put<DictAdditionalFields>(this.baseUrl + '/' + additionalfields.dictadditionalFieldExternalId, additionalfields)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AddEvent, CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {DialogService} from "@progress/kendo-angular-dialog";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DictlocalsignatoryroleService} from "../../../services/middlecontrol/dictlocalsignatoryrole.service";
import {LocalSignatoryRole} from "../../../models/middlecontrol/localsignatory.model";
import {MiddleControlUserAccessService} from "../../../services/useraccess/middle-control-user-access.service";

@Component({
  selector: 'app-dictlocalsignatoryrole',
  templateUrl: './dictlocalsignatoryrole.component.html',
  styleUrls: ['./dictlocalsignatoryrole.component.css']
})
export class DictlocalsignatoryroleComponent implements OnInit {

  public dictLocalSignatoryRole: LocalSignatoryRole[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;
  public editable?: boolean;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  // private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined /*|| this.isNew;*/
  }

  constructor(
    private dictLocalSignatoryRoleService : DictlocalsignatoryroleService,
    private renderer: Renderer2,
    private dialogService: DialogService,
    private notificationService: NotificationsService,
    private userAccessService: MiddleControlUserAccessService,
  ) {}

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getListLocalSignatoryRole();
  }

  public onStateChange(): void {
    this.getListLocalSignatoryRole();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.isLine = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex
  ): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      if (this.formGroup !== undefined) {
        this.dictLocalSignatoryRoleService.updateLocalSignatoryRole(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getListLocalSignatoryRole();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getListLocalSignatoryRole() {
    this.dictLocalSignatoryRoleService.getLocalSignatoryRoles()
      .subscribe(
        response => {
          this.dictLocalSignatoryRole = response;
        }
      );
  }

  private getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(response => this.editable = response.localSignatoryAccessLevel);
  }


  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { externalId?: string; name?: string; order?: number; }) =>
  new UntypedFormGroup({
    externalId: new UntypedFormControl(dataItem.externalId),
    name: new UntypedFormControl(dataItem.name,Validators.required),
    order: new UntypedFormControl(dataItem.order,Validators.required),
  });


import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { DictLessonType } from 'src/app/models/education/dictLessonType.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DictLessonTypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictLessonType}`;

  constructor(private http: HttpClient) { }

  //Get all LessonTypes
  public getAllLessonTypes(): Observable<DictLessonType[]> {
    return this.http.get<DictLessonType[]>(this.baseUrl + '/GetLessonTypes/');
  }

  // Add LessonType
  addLessonType(dictLessonType: DictLessonType): Observable<DictLessonType> {
    return this.http.post<DictLessonType>(this.baseUrl + '/CreateLessonType/' , dictLessonType)
      .pipe(catchError(this.handleError));
  }

  // Edit LessonType
  updateLessonType(dictLessonType: DictLessonType): Observable<DictLessonType> {
    return this.http.put<DictLessonType>(this.baseUrl + '/UpdateLessonType/' + dictLessonType.dictLessonTypeId, dictLessonType)
      .pipe(catchError(this.handleError));
  }

  // Remove LessonType
  deleteLessonType(id: string): Observable<DictLessonType> {
    return this.http.delete<DictLessonType>(this.baseUrl + '/DeleteLessonType/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Emails } from '../../models/contingent/emails.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.emails}`;

  constructor(private http: HttpClient) { }

  //Get all emails
  public getAllemails(): Observable<Emails[]> {
    return this.http.get<Emails[]>(this.baseUrl);
  }

  //Get By Id emails
  getByIdemails(id: string): Observable<Emails> {
    return this.http.get<Emails>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId
  getStudentEmails(id: string): Observable<Emails[]> {
    return this.http.get<Emails[]>(this.baseUrl + '/getemailsbystudpersonid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add emails
  addemails(emails: Emails, studentId: string): Observable<Emails> {
    return this.http.post<Emails>(this.baseUrl + "/" + studentId, emails)
      .pipe(catchError(this.handleError));
  }

  //Delete emails
  deleteemails(id: string, studentId: string): Observable<Emails> {
    return this.http.delete<Emails>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit emails
  updateemails(emails: Emails, studentId: string): Observable<Emails> {
    return this.http.put<Emails>(this.baseUrl + '/' + emails.emailExternalId + "/" + studentId, emails)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

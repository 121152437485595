import {ContingentLoadShortGet, EducationPlanDisciplineGet, GroupFlow, SubEduGroup} from "./assignements.model";

export class EduGroup {
  id!: string;
  name!: string;
  studentCount!: string;
  facultyShortName!: string;
  yearAdmission!: number;
  fullName?: string;
  trainingLevelId?: string;
}

export class DictStudyLevel {
    id!: string;
    name!: string;
    sName!: string;
}

export class DictStudyForm {
    id!: string;
    studyFormName!: string;
    studyFormSName!: string;
    studyFormTypeId!: string;
}

export class DictTrainingLevel {
    id!: string;
    name!: string;
    shortName!: string;
    profileName!: string;
}

export class DictTrainingLevelGroup {
  constructor() {
    this.ids = [];
    this.name = "";
    this.shortName = "";
    this.profileName = "";
  }
  ids: string[];
  name: string;
  shortName: string;
  profileName: string;
}

export class DictAdditionalWork {
    id!: string;
    name!: string;
    dictAdditionalWorkType!: DictAdditionalWorkType
}

export class DictDisciplineGet {
    id!: string;
    disciplineType!: DictDisciplineTypeGet;
    name!: string;
    sName!: string;

    //For filters
    fullName?: string;
}

export class DictDisciplineTypeGet {
  id!: string;
  name!: string;
  isPractice!: boolean
}

export class DictAdditionalWorkType {
    id!: string;
    name!: string;
}

// Контрольная акция
export class DictControllingActionGet {
    id!: string;
    name!: string;
    sName!: string;
}

// Кафедра
export class KafedraGet {
    id!: string;
    facultyId!: string;
    name!: string;
    sName!: string;
}

export class Person {
    id!: string;
    fio!: string;
    login?: string;
    postName!: string;
    eduPostName!: string;
}

export class PPSGet {
    id!: string;
    firstName!: string;
    middleName?: string;
    lastName!: string;
    fio!: string;
    login?: string;
    isManualAddition!: boolean;
    salaryIsManualAddition!: boolean;
    salaryId!: string;
    postName?: string;
    salaryType?: string;
    salary?: number;
    load!: TeacherLoadTotal;
}

export class TeacherLoadTotal {
    total!: number;
    study!: number;
    additional!: number;
}


export class PersonDto {
    studyYear?: number;
    firstName!: string;
    middleName?: string;
    lastName!: string;
    salary?: SalaryDto;
}

export class SalaryDto {
    departmentId!: string;
    postId!: string | null;
    salaryTypeId!: string | null;
    salary!: number;
}

export class Faculty {
    id!: string;
    name!: string;
    sName!: string;
    fullName?: string;
}

export class AvailableSemesters {
    year!: number;
    semester!: number;
    // Для фильтров
    name?: string;
    fullYear?: string;
    semesterName?: string;
}

export class AvailableDiscipline {
  id!: string;
  dictDisciplineTypeId!: string;
  name!: string;
  sName!: string;
  semesterNumber!: number;
  fullName!: string;
}

export class AvailableKafedra {
  id!: string;
  name!: string;
  facultyId!: string;
}

export class AvailableEducationDisciplineGet {
  educationPlanDiscipline: EducationPlanDisciplineGet = new EducationPlanDisciplineGet();
  eduGroup: EduGroup = new EduGroup();
  contingentLoad: ContingentLoadShortGet | null = null;
  groupFlows!: GroupFlow[];
  groupByDiscipline!: string;
  // Для новых групп
  orderNum?: number;
}

export class DictSalaryType {
    id!: string;
    name!: string;
    measure?: string;
}

export class DictPost {
    id!: string;
    name!: string;
    sName?: string;
    order?: number;
    smallName?: string;
}

export class DictIncreasedCoefficient {
  id!: string;
  name!: string;
}

export class DictExamTypeParameter {
  id!: string;
  name!: string;
  groupCoefficient!: number;
  studentCoefficient!: number;
}

export class DictPracticeType {
  id!: string;
  name!: string;
}

export class DictCalculationMethod {
  id!: string;
  name!: string;
  sName!: string;
  defaultMethod!: number;
}

import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import {AddEvent, GridComponent, RemoveEvent, CellClickEvent, CancelEvent} from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CommonDictionariesUserAccessService } from "../../../services/useraccess/common-dictionaries-user-access.service";
import {DictSignatoryRoleService} from "../../../services/dicts/signatory-role.service";
import {checkRole} from "../../../helpers/token/role-check";
import {TokenStorageService} from "../../../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Role} from "../../../models/useraccess/role";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {DictSignatoryRoleRead} from "../../../models/dicts/global-signatory-role";
import { isChildOf } from 'src/app/helpers/elementRef-helper';
import { Router} from "@angular/router";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";

@Component({
  selector: 'global-signatory-role',
  templateUrl: './global-signatory-role.component.html',
  styleUrls: ['./global-signatory-role.component.scss']
})
export class GlobalSignatoryRoleComponent implements OnInit {
  public dictSignatoryRoles: DictSignatoryRoleRead[] = [];
  public formGroup: FormGroup | undefined;
  public editable: boolean = false;
  public isAdmin: boolean = false;
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  //#region Grid controls
  public position: "top" | "bottom" | "both" = "both";
  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public opened = false;
  public loading = false;
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  //#endregion
  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  public gridView: any | GridDataResult;

  constructor(private signatoryRoleService: DictSignatoryRoleService,
              private userAccessService: CommonDictionariesUserAccessService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private tokenService: TokenStorageService,
              private jwtService: JwtHelperService,
              private router: Router,
    ) {
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  public ngOnInit(): void {
    this.getAccessLevel();
   // this.getDictSignatoryRoles();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadSignatoryRoles();
  }

  private loadSignatoryRoles(): void {
    this.gridView = {
      data: orderBy(this.dictSignatoryRoles, this.sort),
      total: this.dictSignatoryRoles.length,
    };
  }

  //#region Get Data

  private getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(response => {
        if (response.accessLevelSignatoryRole == 0){
          this.notificationService.showError("У вас нет прав для просмотра данной страницы");
          this.router.navigate(['/profile']);
        } else {
          this.editable = response.accessLevelSignatoryRole !== 1;
          this.getDictSignatoryRoles();
        }
      }); 
  }

  private getDictSignatoryRoles() {
    this.loading = true;
    this.signatoryRoleService.get()
      .subscribe(
        response => {
          this.dictSignatoryRoles = response;
          this.loading = false;
          this.loadSignatoryRoles();
        }, error => {
          //console.log(error.error);
          this.notificationService.showError("Не удалось получить список ролей");
          this.loading = false;
        });
  }

  //#endregion

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Save data to dictionary
  private saveRow(): void {
    if (!this.isInEditingMode) {
      this.closeEditor(this.grid);
      return;
    }

    let dataItem = this.formGroup?.value;
    if (!dataItem) return;

    let data = {} as any;
    if (dataItem.id)
      data.id = dataItem.id;
    data.name = dataItem.name;
    data.serialNumber = dataItem.serialNumber;

    if (this.isNew == true && this.formGroup !== undefined) {
      this.signatoryRoleService.add(data)
        .subscribe(
          response => {
            this.getDictSignatoryRoles();
            this.notificationService.showSuccess("Добавлено");
          },
          error => {
          //  console.log(error);
            this.notificationService.showError(error?.error ?? "Не удалось добавить запись");
          }
        );
    }
    else if (this.formGroup !== undefined) {
      this.signatoryRoleService.update(data)
        .subscribe(
          response => {
            this.getDictSignatoryRoles();
            this.notificationService.showSuccess("Обновлено");
          },
          error => {
           // console.log(error);
            this.notificationService.showError(error?.error ?? "Не удалось обновить запись");
          }
        );
    }

    this.closeEditor(this.grid);
  }

  //#region Grid Event Handlers

  //Start Adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup(new DictSignatoryRoleRead());
    this.isLine = true;
    this.isNew = true;

    sender.addRow(this.formGroup);
  }
  //Start Editing
  public editHandler({sender, isEdited, rowIndex, dataItem}: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    if(!this.editable) return
    if (this.isNew) {
      rowIndex += 1;
    }

    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }
  //Cancel
  public cancelHandler({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }
  public btnCancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }
  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.name}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.signatoryRoleService.delete(dataItem.id)
          .subscribe(
            ( response: any) => {
              this.getDictSignatoryRoles();
              this.notificationService.showSuccess("Удалено");
          },
          error => {
             // console.log(error);
              this.notificationService.showError(error.error ?? 'Не удалось удалить роль подписанта');
          });
        } else{
          this.opened = false;
        }
      }
    });
  }
  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  public saveHandler(event: any) {
    //console.log('save', event);
  }
  //#endregion
}

const formGroup = (dataItem: DictSignatoryRoleRead) => new FormGroup({
  id: new FormControl(dataItem.id),
  name: new FormControl(dataItem.name, Validators.required),
  serialNumber: new FormControl(dataItem.serialNumber)
});

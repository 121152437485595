import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { StateExamCommissionService } from '../../../../services/gia/stateExamCommission.service';
import { DictStateExamCommissionTypeService } from '../../../../services/gia/dictStateExamCommissionType.service';
import {
  CommissionModel,
  ExpelledMemberModel,
  MemberModel,
  ShortListStateExamCommissionModel,
  StandardModel,
  StateExamCommissionModel,
} from '../../../../models/gia/stateexamcommission/stateexamcommission.model';
import { DictStateExamCommissionTypeModel } from '../../../../models/gia/dicts/dictStateExamCommissionType.model';
import { PrintTemplateModel } from '../../../../models/gia/printtemplate/printTemplate.model';
import { PrintTemplateService } from '../../../../services/gia/printTemplate.service';
import { DateFromUTCAsLocal, TimeZoneFix } from '../../../../helpers/date-helper';
import { DictsService } from '../../../../services/gia/dicts.service';
import { DictDto, Dicts, StudyYearDto } from '../../../../models/gia/dicts/dicts.model';
import { GiaPersonService } from '../../../../services/gia/person.service';
import { Person } from '../../../../models/gia/person.model';
import { workPlaceEnum } from '../../../../models/gia/enums/workPlace.Enum';
import { GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { EducationService } from '../../../../services/gia/education.service';
import { EduStandardModel } from '../../../../models/gia/education/standard.model';
import { EduProgramModel } from '../../../../models/gia/education/program.model';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { Guid } from 'guid-typescript';
import { GiaUserAccessService } from '../../../../services/useraccess/gia-user-access.service';
import { GiaTabsEnum } from '../../../../models/gia/enums/giaTabs.enum';
import { openDialog } from '../../../../helpers/dialogHelper';
import { DictStateExamCommissionTypeEnum } from '../../../../models/gia/enums/dictStateExamCommissionType.enum';
import { DictStateExamCommissionMemberRoleService } from '../../../../services/gia/dictStateExamCommissionMemberRole.service';
import { DictStateExamCommissionMemberRoleModel } from '../../../../models/gia/dicts/dictStateExamCommissionMemberRole.model';
import { objectRewrite } from '../../../../helpers/multiselect-helper';
import moment from 'moment/moment';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'app-add-state-exam-commission',
  templateUrl: './add-state-exam-commission.component.html',
  styleUrls: ['./add-state-exam-commission.component.scss'],
})
export class AddStateExamCommissionComponent implements OnInit {
  @ViewChild('grid', { static: true }) grid!: GridComponent;

  public name: string | undefined = 'Добавление приказа';
  public category: number | undefined = 0;
  public commissionList: CommissionModel[] = [];
  public stateExamCommissionId: string | null = null;
  public filial: string | null = '';
  public dictStudyFormId: string | null = '';
  public allFilial: DictDto[] = [];
  public dictStudyForms: DictDto[] = [];
  public yearAdmissionList: StudyYearDto[] = [];
  public trainingLevels: DictDto[] = [];
  public trainingLevel: string | null = '';
  public yearAdmission!: number;
  public dictStateExamCommissionTypes: DictStateExamCommissionTypeModel[] = [];
  public childrenStateExamCommission: StateExamCommissionModel = new StateExamCommissionModel();
  public childrens: ShortListStateExamCommissionModel[] = [];
  public editable = false;
  public filters: any;
  public stateExamCommissionFilters: {
    yearAdmission: number;
    trainingLevel: string;
    filial: string;
  } = { yearAdmission: 0, trainingLevel: '', filial: '' };
  public printTemplates: PrintTemplateModel[] = [];
  public dicts: Dicts[] = [];
  public roles: DictStateExamCommissionMemberRoleModel[] = [];
  public workPlaces = [
    { id: 1, name: 'Работник ВУЗа' },
    { id: 2, name: 'Внешний' },
  ];
  public persons: Person[] = [];
  public workPlaceValue: number = 0;
  public fioExternalReviewer = { fio: '', commissionIndex: 0, memberIndex: 0 };
  public eduStandards: EduStandardModel[] = [];
  public eduPrograms: EduProgramModel[] = [];

  public readonly commissions: string = 'commissions';
  public readonly standards: string = 'standards';
  public readonly expelledMembers: string = 'expelledMembers';
  public readonly members: string = 'members';

  public isEditCategoryOrder = false;
  public childId: string | undefined = undefined;

  public isExamOrder = false; // Тип приказа об экзаменаторах или об изменении состаава экзаменаторов

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  public saving = false;

  public virtual = {
    itemHeight: 28,
  };

  public stateExamCommissionForm: FormGroup = new FormGroup({
    filialId: new FormControl(''),
    id: new FormControl(null),
    yearAdmission: new FormControl(0),
    number: new FormControl(''),
    stateExamCommissionTypeId: new FormControl(''),
    printTemplateId: new FormControl(''),
    dictTrainingLevelId: new FormControl(''),
    departmentId: new FormControl(''),
    printerName: new FormControl(''),
    date: new FormControl<Date | null>(null),
    childId: new FormControl(null),
    commissions: new FormArray([
      new FormGroup({
        id: new FormControl(''),
        studyForms: new FormArray([]),
        members: new FormArray([]),
        standards: new FormArray([]),
        expelledMembers: new FormArray([]),
      }),
    ]),
  });

  public defaultStateExamCommissionForm: FormGroup = new FormGroup({});

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: GiaUserAccessService,
    private router: Router,
    private stateExamCommissionService: StateExamCommissionService,
    private dictStateExamCommissionTypeService: DictStateExamCommissionTypeService,
    private printTemplateService: PrintTemplateService,
    private formBuilder: FormBuilder,
    private dictsService: DictsService,
    private giaPersonService: GiaPersonService,
    private educationService: EducationService,
    private dictStateExamCommissionMemberRoleService: DictStateExamCommissionMemberRoleService,
  ) {}

  ngOnInit(): void {
    this.getAccessLevel();
    this.getLocalFilters();
  }

  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        this.editable = response.giaSections?.some((_) => _ === GiaTabsEnum.состав_ГЭК || _ === null);

        this.getAllFilial();
        this.getAllDictStateExamCommissionTypes();
        this.getAllTrainingLevel();
        this.getStudyForms();
        this.getRoles();
        this.getOrder();
        this.getPersons();
        this.getStandards(this.trainingLevel!, this.filial!);
        this.getPrograms();
      },
      error: () => {},
    });
  }

  public getStandards(trainingLevelId?: string, filialId?: string, name?: string) {
    this.educationService.getStudentStandards(name, trainingLevelId, filialId).subscribe((response) => {
      this.eduStandards = [{ name: '', id: '', standardName: 'Все', cipher: '' }, ...response];
    });
  }

  public getEduProgramByStandard(index: number, rowIndex: number): EduProgramModel[] {
    const standards = this.getFormArray(index, this.standards);
    const standardGroup = standards.at(rowIndex) as FormGroup;

    return this.eduPrograms.filter((x: EduProgramModel) => x.standardId === standardGroup.value.educationStandardId);
  }

  public getPrograms(standardId?: string, index?: number, rowIndex?: number, name?: string) {
    this.eduPrograms = [];
    this.educationService.getStudentPrograms(name, standardId).subscribe({
      next: (response) => {
        if (response.length === 1) {
          this.eduPrograms = response;
        } else {
          this.eduPrograms = [{ name: '', id: '', programName: 'Все', cipher: '', standardId: '' }, ...response];
        }
      },
      error: (error) => {
        this.notificationService.showError(error.error);
      },
    });
    if (index != null && rowIndex != null) {
      this.commissionList[index].standards[rowIndex].eduPrograms = [];
    }
  }

  public getPersons() {
    this.giaPersonService.getPersons().subscribe((response) => (this.persons = response));
  }

  public getRoles(type?: number) {
    this.dictStateExamCommissionMemberRoleService.getList(type).subscribe((response) => {
      this.roles = response;
    });
  }

  public getOrder() {
    this.route.params.subscribe((params) => {
      this.stateExamCommissionId = params['id'];
      if (this.stateExamCommissionId) {
        this.getStateExamCommissionById(this.stateExamCommissionId);
        this.getShortListStateExamCommission();
      } else {
        this.stateExamCommissionForm = this.getStateExamCommissionFormGroup(this.stateExamCommissionForm.value);
        const commissions = this.stateExamCommissionForm.get(this.commissions) as FormArray;
        this.commissionList = commissions.value;
      }
    });
  }

  public getStudyForms(name?: string) {
    this.dictsService.getStudyForms(name).subscribe((response) => {
      this.dictStudyForms = [{ name: 'Все', id: '' }, ...response];
    });
  }
  public getPrintTemplates() {
    this.printTemplateService.getList(this.yearAdmission, this.filial, this.category, this.trainingLevel).subscribe((response) => {
      this.printTemplates = response;
    });
  }
  public getLocalFilters() {
    this.filters = localStorage.getItem('stateExamCommissionFilters');
    if (this.filters) {
      this.stateExamCommissionFilters = JSON.parse(this.filters);
      this.yearAdmission = this.stateExamCommissionFilters.yearAdmission;
      this.trainingLevel = this.stateExamCommissionFilters.trainingLevel;
      this.filial = this.stateExamCommissionFilters.filial;
    }
    this.getPrintTemplates();
  }

  public getStateExamCommissionById(id: string) {
    this.stateExamCommissionService.get(id).subscribe({
      next: (response) => {
        this.stateExamCommissionForm = this.getStateExamCommissionFormGroup(response);
        this.childId = response.childId;
        this.getStateExamCommissionTypeName(response.stateExamCommissionTypeId, false);
        if (response.childId) this.getChildrenStateExamCommissionById(response.childId);
        const commissions = this.stateExamCommissionForm.get(this.commissions) as FormArray;
        this.commissionList = commissions.value;
        this.defaultStateExamCommissionForm = this.cloneFormGroup(this.stateExamCommissionForm);
      },
      error: (error) => {
        this.notificationService.showError(error.error);
      },
    });
  }

  private cloneFormGroup(form: FormGroup): FormGroup {
    const formGroup = new FormGroup({});
    const controls = form.controls;

    for (const controlName in controls) formGroup.addControl(controlName, new FormControl(controls[controlName].value));

    return formGroup;
  }

  public getShortListStateExamCommission() {
    if (this.filial && this.trainingLevel && this.yearAdmission) {
      this.stateExamCommissionService.getShortList(this.filial, this.trainingLevel, this.yearAdmission, this.category).subscribe((response) => {
        this.childrens = response;
      });
    }
  }

  public getChildrenStateExamCommissionById(id: string) {
    this.stateExamCommissionService.get(id).subscribe({
      next: (response) => {
        this.childrenStateExamCommission = response;
        this.stateExamCommissionForm = this.getStateExamCommissionFormGroup(this.stateExamCommissionForm.value, response);
        this.commissionList = this.stateExamCommissionForm.get(this.commissions)?.value;
      },
      error: (error) => {
        this.notificationService.showError(error.error);
      },
    });
  }

  public getAllFilial() {
    this.dictsService.getFilials().subscribe((response) => {
      this.allFilial = response;
      this.getYearAdmissionList();
    });
  }

  public getYearAdmissionList() {
    this.dictsService.getStudyYears().subscribe((response) => {
      this.yearAdmissionList = response;
    });
  }

  public getAllDictStateExamCommissionTypes() {
    this.dictStateExamCommissionTypeService.getList().subscribe(
      (response) => {
        this.dictStateExamCommissionTypes = response;
      },
      (error) => {
        this.notificationService.showError('Не удалось получить категории шаблонов');
      },
    );
  }

  public getAllTrainingLevel() {
    this.dictsService.getStudentTrainingLevels().subscribe((response) => {
      this.trainingLevels = response;
      if (this.filters == null) this.trainingLevel = this.trainingLevels[0].id!.toString();
      this.getYearAdmissionList();
    });
  }

  onCancel(): void {
    this.router.navigate(['/gia/printtemplate']);
  }

  private getStateExamCommissionFormGroup(value?: StateExamCommissionModel, childValue?: StateExamCommissionModel) {
    return new FormGroup({
      id: new FormControl(value?.id ?? null),
      filialId: new FormControl<string | null>(value?.filialId ?? null, Validators.required),
      yearAdmission: new FormControl<number | null>(value?.yearAdmission ?? 0, Validators.required),
      stateExamCommissionTypeId: new FormControl<string | null>(value?.stateExamCommissionTypeId ?? null, Validators.required),
      dictTrainingLevelId: new FormControl<string | null>(value?.dictTrainingLevelId ?? null, Validators.required),
      date: new FormControl<Date | null>(DateFromUTCAsLocal(value?.date) ?? null, Validators.required),
      printTemplateId: new FormControl<string | null>(value?.printTemplateId ?? null, Validators.required),
      number: new FormControl<string | null>(value?.number ?? null, Validators.required),
      childId: new FormControl<string | null>(value?.childId ?? null),
      commissions: childValue
        ? this.formBuilder.array(
            (childValue?.commissions || []).map((commission, index) =>
              this.getCommissionFormGroup(value?.commissions[index], commission, this.childId == childValue?.id),
            ),
          )
        : this.formBuilder.array((value?.commissions || []).map((commission) => this.getCommissionFormGroup(commission))),
    });
  }

  private getCommissionFormGroup(value?: CommissionModel, childValue?: CommissionModel, isSameChild?: boolean) {
    const data = childValue ? childValue : value;
    data?.studyForms?.map((item) => {
      if (item.id === null) {
        item.id = '';
        return item;
      }
      return item;
    });
    return new FormGroup({
      studyForms: new FormControl(data?.studyForms ?? null, Validators.required),
      id: new FormControl(value?.id ?? null),
      members: this.formBuilder.array((value?.members || []).map((member, index) => this.getMemberFormGroup(member))),
      standards: childValue
        ? this.formBuilder.array((childValue?.standards || []).map((standard, index) => this.getStandardsFormGroup(value?.standards[index], standard)))
        : this.formBuilder.array((value?.standards || []).map((standard, index) => this.getStandardsFormGroup(standard))),
      expelledMembers:
        isSameChild || isSameChild === undefined
          ? this.formBuilder.array((value?.expelledMembers || []).map((expelledMember, index) => this.getExpelledMemberFormGroup(expelledMember)))
          : new FormArray([]),
    });
  }

  private getMemberFormGroup(value?: MemberModel) {
    return new FormGroup({
      id: new FormControl(value?.id ?? null),
      fio: new FormControl<string | null>(value?.fio ?? null),
      post: new FormControl<string | null>(value?.post ?? null),
      academicStatus: new FormControl<string | null>(value?.academicStatus ?? null),
      personId: new FormControl<string | null>(value?.personId ?? null),
      workPlace: new FormControl<number | null>(value?.workPlace ?? null, Validators.required),
      dictRoleId: new FormControl<string | null>(value?.dictRoleId ?? null),
      fioInitialsAfter: new FormControl<string | null>(value?.fioInitialsAfter ?? null),
      scientificDegreeName: new FormControl<string | null>(value?.scientificDegreeName ?? null),
      scientificDegreeShortName: new FormControl<string | null>(value?.scientificDegreeShortName ?? null),
    });
  }

  private getExpelledMemberFormGroup(value?: ExpelledMemberModel) {
    return new FormGroup({
      id: new FormControl(value?.id ?? null),
      stateExamCommissionMemberId: new FormControl<string | null>(value?.stateExamCommissionMemberId ?? null),
      reason: new FormControl<string | null>(value?.reason ?? null),
    });
  }

  private getStandardsFormGroup(value?: StandardModel, childValue?: StandardModel) {
    const data = childValue ? childValue : value;
    data?.eduPrograms?.map((item) => {
      if (item.id === null) {
        item.id = '';
        return item;
      }
      return item;
    });
    return new FormGroup({
      id: new FormControl(value?.id ?? null),
      educationStandardId: new FormControl(data?.educationStandardId ?? ''),
      eduPrograms: new FormControl(data?.eduPrograms ?? null, Validators.required),
    });
  }

  public getStateExamCommissionTypeName(id: string, clickEvent: boolean) {
    this.name = this.dictStateExamCommissionTypes.find((item) => item.id == id)?.name;
    this.category = this.dictStateExamCommissionTypes.find((item) => item.id == id)?.value;
    this.isExamOrder =
      this.category == DictStateExamCommissionTypeEnum.Приказ_о_внесении_изменений_в_состав_экзаменаторов ||
      this.category == DictStateExamCommissionTypeEnum.Приказ_об_экзаменаторах;
    if (clickEvent) {
      this.getRoles(this.category);
    }

    this.isEditCategoryOrder =
      this.category == DictStateExamCommissionTypeEnum.Приказ_о_внесении_изменений_в_состав_ГЭК ||
      this.category == DictStateExamCommissionTypeEnum.Приказ_о_внесении_изменений_в_состав_экзаменаторов ||
      this.category == DictStateExamCommissionTypeEnum.Приказ_о_внесении_изменений_в_состав_апелляционной_комиссии;
    if (this.isEditCategoryOrder) {
      this.getShortListStateExamCommission();
    }
    this.getPrintTemplates();
    if (clickEvent) this.stateExamCommissionForm.get('childId')?.reset(null);
  }

  public editFilialId(id: string) {
    this.filial = id;
    this.setLocalStorage();
    this.getPrintTemplates();
    this.updateOrderList();
    this.getStandards(this.trainingLevel!, id);
  }

  public editYearAdmission(id: number) {
    this.yearAdmission = id;
    this.setLocalStorage();
    this.updateOrderList();
    this.getPrintTemplates();
  }

  public editTrainingLevels(id: string) {
    this.trainingLevel = id;
    this.setLocalStorage();
    this.getPrintTemplates();
    this.updateOrderList();
    this.getStandards(id, this.filial!);
  }

  public updateOrderList(){
    if (this.isEditCategoryOrder){
      this.getShortListStateExamCommission();
      this.stateExamCommissionForm.get('childId')?.reset(null);
    }
  }

  public setLocalStorage() {
    const object = {
      filial: this.filial,
      trainingLevel: this.trainingLevel,
      yearAdmission: this.yearAdmission,
    };
    localStorage.setItem('stateExamCommissionFilters', JSON.stringify(object));
  }

  public workPlaceChange(value: number) {
    this.workPlaceValue = value;
  }
  isEmployee(commissionIndex: number, memberIndex: number) {
    const members = this.getFormArray(commissionIndex, this.members);
    const memberGroup = members.at(memberIndex) as FormGroup;
    return memberGroup.value?.workPlace == workPlaceEnum.Работник_ВУЗа;
  }

  public updateControlValue(formGroup: FormGroup, controlName: string, value: any) {
    const control = formGroup.get(controlName) as FormControl;
    control.setValue(value);
    control.updateValueAndValidity();
  }

  public updateEmployeeInformation(commissionIndex: number, memberIndex: number, event?: string, member?: MemberModel) {
    const person = member ? member : this.persons.find((person) => person.id == event);
    if (person) {
      const members = this.getFormArray(commissionIndex, this.members);
      const memberGroup = members.at(memberIndex) as FormGroup;

      this.updateControlValue(memberGroup, 'post', person.post);
      this.updateControlValue(memberGroup, 'fio', person.fio);
      this.updateControlValue(memberGroup, 'fioInitialsAfter', person.fioInitialsAfter);
      this.updateControlValue(memberGroup, 'scientificDegreeName', person.scientificDegreeName);
      this.updateControlValue(memberGroup, 'scientificDegreeShortName', person.scientificDegreeShortName);
      this.updateControlValue(memberGroup, 'academicStatus', person.academicStatus);
    }
  }

  public addCommission() {
    const uniqueId = Guid.create().toString();

    const members = this.generateFormGroup(this.members);
    const standards = this.generateFormGroup(this.standards);

    if (members && standards) {
      const newCommission = new FormGroup({
        id: new FormControl(uniqueId),
        studyForms: new FormControl(null),
        members: new FormArray([members]),
        standards: new FormArray([standards]),
      });
      const commissions = this.stateExamCommissionForm.get(this.commissions) as FormArray;
      commissions.push(newCommission);
      this.commissionList = commissions.value;
    }
  }

  public generateFormGroup(name: string) {
    const uniqueId = Guid.create().toString();

    if (name === this.members) {
      return new FormGroup({
        fio: new FormControl(null),
        id: new FormControl(uniqueId),
        post: new FormControl(null),
        academicStatus: new FormControl(null),
        personId: new FormControl(null),
        workPlace: new FormControl(1),
        dictRoleId: new FormControl(),
        fioInitialsAfter: new FormControl(null),
        scientificDegreeName: new FormControl(null),
        scientificDegreeShortName: new FormControl(null),
      });
    }

    if (name === this.standards) {
      return new FormGroup({
        id: new FormControl(uniqueId),
        educationStandardId: new FormControl(null),
        eduPrograms: new FormControl(null, Validators.required),
      });
    }

    if (name === this.expelledMembers) {
      return new FormGroup({
        id: new FormControl(uniqueId),
        stateExamCommissionMemberId: new FormControl(null, Validators.required),
        reason: new FormControl(null),
      });
    }

    return null;
  }

  public addFormGroup(commissionIndex: number, formGroupName: string) {
    const newFormGroup = this.generateFormGroup(formGroupName);
    const formArray = this.getFormArray(commissionIndex, formGroupName);
    formArray.push(newFormGroup);
    switch (formGroupName) {
      case this.members:
        this.commissionList[commissionIndex].members = formArray.value;
        break;
      case this.standards:
        this.commissionList[commissionIndex].standards = formArray.value;
        break;
      case this.expelledMembers:
        this.commissionList[commissionIndex].expelledMembers = formArray.value;
        break;
    }
  }

  public removeCommission(commissionIndex: number) {
    const dialog: DialogRef = openDialog(this.dialogService, `Вы действительно хотите удалить коммиссию?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        const commissions = this.stateExamCommissionForm.get(this.commissions) as FormArray;
        commissions.removeAt(commissionIndex);
        this.commissionList = commissions.value;
      }
    });
  }

  public removeHandler({ dataItem }: RemoveEvent, commissionIndex: number, formArrayName: string): void {
    const dialog: DialogRef = openDialog(
      this.dialogService,
      formArrayName === this.standards
        ? `Вы действительно хотите удалить направление подготовки?`
        : dataItem.fio
          ? `Вы действительно хотите удалить: '${dataItem.fio}' из состава комиссии?`
          : `Вы действительно хотите удалить из состава комиссии?`,
    );

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        const array = this.getFormArray(commissionIndex, formArrayName);
        const removeIndex = this.findInArrayById(array, dataItem.id);

        if (removeIndex !== null) {
          array.removeAt(removeIndex);
          array.updateValueAndValidity();
        }

        const commissions = this.stateExamCommissionForm.get(this.commissions) as FormArray;
        this.commissionList = commissions.value;
      }
    });
  }

  public getFormArray(commissionIndex: number, childElement: string) {
    const commissions = this.stateExamCommissionForm.get(this.commissions) as FormArray;
    const commissionGroup = commissions.at(commissionIndex) as FormGroup;
    return commissionGroup.get(childElement) as FormArray;
  }

  public findInArrayById(formArray: FormArray, id: string): number | null {
    for (let i = 0; i < formArray.length; i++) {
      const group = formArray.at(i) as FormGroup;
      const control = group.get('id') as FormControl;
      if (control && control.value === id) {
        return i;
      }
    }
    return null;
  }

  public changeFioValue(fio: string, commissionIndex: number, memberIndex: number) {
    this.fioExternalReviewer = {
      fio: fio,
      commissionIndex: commissionIndex,
      memberIndex: memberIndex,
    };
  }

  handleBlurEvent() {
    if (this.fioExternalReviewer.fio)
      this.stateExamCommissionService.getExternalReviewer(this.fioExternalReviewer.fio).subscribe((response) => {
        this.updateEmployeeInformation(this.fioExternalReviewer.commissionIndex, this.fioExternalReviewer.memberIndex, undefined, response);
      });
  }
  public closeOrder() {
    this.router.navigate([`/gia/stateExamCommission`]);
  }

  public saveOrder(print?: boolean) {
    this.saving = true;
    if (this.stateExamCommissionForm.value.date != undefined) this.stateExamCommissionForm.value.date = TimeZoneFix(this.stateExamCommissionForm.value.date);
    const orderType = this.dictStateExamCommissionTypes.find((item) => item.id == this.stateExamCommissionForm.value.stateExamCommissionTypeId)?.name;
    let orderId = this.stateExamCommissionId;
    for (let i = 0; i < this.stateExamCommissionForm.value.commissions.length; i++) {
      const commission = this.stateExamCommissionForm.get(this.commissions)?.value[i];
      for (let x = 0; x < commission.studyForms.length; x++) {
        const studyForm = commission.studyForms[x];

        if (studyForm['id'] === '') {
          studyForm['id'] = null;
        }
      }
      for (let j = 0; j < commission.standards.length; j++) {
        const standard = commission.standards[j];

        if (standard['educationStandardId'] === '') {
          standard['educationStandardId'] = null;
        }

        for (let z = 0; z < standard.eduPrograms.length; z++) {
          const eduPrograms = standard.eduPrograms[z];

          if (eduPrograms['id'] === '') {
            eduPrograms['id'] = null;
          }
        }
      }
    }
    this.stateExamCommissionId
      ? this.stateExamCommissionService.updateStateExamCommission(this.stateExamCommissionForm.value).subscribe({
          next: () => {
            this.notificationService.showSuccess('Сохранено');
            if (print) this.downloadFile(orderId!, this.stateExamCommissionForm.value.number, this.stateExamCommissionForm.value.date, orderType!);
            this.closeOrder();
          },
          error: (err) => {
            this.notificationService.showError(err.error);
            this.saving = false;
          },
        })
      : this.stateExamCommissionService.addStateExamCommission(this.stateExamCommissionForm.value).subscribe({
          next: (response) => {
            this.notificationService.showSuccess('Сохранено');
            orderId = response;
            if (print) this.downloadFile(orderId!, this.stateExamCommissionForm.value.number, this.stateExamCommissionForm.value.date, orderType!);
            this.closeOrder();
          },
          error: (err) => {
            this.notificationService.showError(err.error);
            this.saving = false;
          },
        });
  }

  public downloadFile(id: string, orderNumber: string, orderDate: Date, orderType: string): void {
    const date = moment(orderDate).format('DD-MM-yyyy');
    const fileName = `${orderType} № ${orderNumber} от ${date}`;
    this.stateExamCommissionService.getFile(id).subscribe({
      next: (value) => {
        saveAs(value, fileName as string);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }

  public getChildOrderPersons(i: number) {
    return this.childrenStateExamCommission.commissions[i].members;
  }

  public openpopup() {
    if (JSON.stringify(this.defaultStateExamCommissionForm.value) !== JSON.stringify(this.stateExamCommissionForm.value)) {
      const dialog: DialogRef = openDialog(
        this.dialogService,
        this.stateExamCommissionId ? `Вы действительно хотите отменить редактирование приказа?` : `Вы действительно хотите отменить добавление приказа?`,
      );
      dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') this.closeOrder();
      });
    } else this.closeOrder();
  }

  public valueChangeStudyForm(value: any, index: number) {
    this.commissionList[index].studyForms = objectRewrite(value);
  }

  public valueChangeEduPrograms(value: any, index: number, rowIndex: number) {
    this.commissionList[index].standards[rowIndex].eduPrograms = objectRewrite(value);
  }

  public clearProgram(index: number, rowIndex: number) {
    const standards = this.getFormArray(index, this.standards);
    const standardGroup = standards.at(rowIndex) as FormGroup;
    standardGroup.value.eduPrograms = new FormArray([]);
    this.commissionList[index].standards[rowIndex].eduPrograms = [];
  }
}

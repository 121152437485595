import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {ScientometricBasesService} from "../../../../../services/science/publications/scientometric-bases.service";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {getData$} from "../../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class PublicationsScientometricBasesMethods {

    constructor(private scientometicBaseService: ScientometricBasesService,
                private notificationService: NotificationsService) {
    }

    async savecientometricBase(isNew: boolean, formGroup: FormGroup) {
        if (isNew) {
            this.scientometicBaseService.addScientometricBase(formGroup.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: () => this.notificationService.showError('Произошла ошибка')
            })
        }
        else {
            this.scientometicBaseService.updateScientometricBase(formGroup.value).subscribe({
                next: () => {
                    this.notificationService.showSuccess('Успешно');
                    getData$.next(true);
                },
                error: () => this.notificationService.showError('Произошла ошибка')
            })
        }
    }

    removeQuartile(dialogResult: Observable<DialogResult>, dataItem: any) {
        dialogResult.subscribe((result) => {
            if (result instanceof DialogCloseResult) {}
            else {
                if (result.text == "Да") {
                    this.scientometicBaseService.removeScientometricBase(dataItem.id)
                        .subscribe({
                            next: () => {
                                this.notificationService.showSuccess('Успешно');
                                getData$.next(true);
                            },
                            error: () => this.notificationService.showError('Произошла ошибка')
                        })
                } else {}
            }
        });
    }

}
import { Component, OnInit} from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { SearchFaculty } from 'src/app/models/announcement/search-students.model';
import { SearchGroups } from 'src/app/models/announcement/search-students.model';
import { SearchDirections } from 'src/app/models/announcement/search-students.model';
import { SearchSpecialties } from 'src/app/models/announcement/search-students.model';
import { SearchBudgets } from 'src/app/models/announcement/search-students.model';
import { SearchForms } from 'src/app/models/announcement/search-students.model';
import { SearchLevels } from 'src/app/models/announcement/search-students.model';
import { SearchSupervisors } from 'src/app/models/announcement/search-students.model';
import { SearchDepartments } from 'src/app/models/announcement/search-students.model';
import { SearchStudentsService } from 'src/app/services/announcement/search-students.service';
import {DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';

@Component({
    selector: 'app-expand-students',
    templateUrl: './expand-students.component.html',
    styleUrls: ['./expand-students.component.scss']
})

export class ExpandStudentsComponent implements OnInit{
    
    @Output() buttonClick = new EventEmitter();

    initials: string = "";

    public advanced_search(): void {
        this.buttonClick.emit();
        this.searchStudentsService.SearchParams(this.initials, this.faculty, this.arrayOfCourse);

    }
    
    public facultys: SearchFaculty[] = [];
    public groups: SearchGroups[] = [];
    public directions: SearchDirections[] = [];
    public specialties: SearchSpecialties[] = [];
    public budgets: SearchBudgets[] = [];
    public forms: SearchForms[] = [];
    public levels: SearchLevels[] = [];
    public departments: SearchDepartments[] = [];
    public supervisors: SearchSupervisors[] = [];
    

    public defaultItemIsMale: { text: string; value?: boolean } = {
        text: "Все",
        value: undefined,
      };
   
    public listIsMale: Array<{ text: string; value: boolean }> = [
        {text: "Мужской", value: true},
        {text: "Женский", value: false},
    ];

    public allCourses: Array<{ course: string; courseNum: number }> = [
        {course: "1 курс", courseNum: 1},
        {course: "2 курс", courseNum: 2},
        {course: "3 курс", courseNum: 3},
        {course: "4 курс", courseNum: 4},
        {course: "5 курс", courseNum: 5},
        {course: "6 курс", courseNum: 6},
      ];
    
    public courses: any = [];

    public faculty           : number = 0;
    public arrayOfCourse     : [] = [];
    public Male              : any ;
    public arrayofGroupe     : any = [];
    public arrayofDirections : any = [];
    public arrayofSpecialitys: any = [];
    public arrayofBudget     : [] = [];
    public arrayofForms      : [] = [];
    public arrayofLevels     : [] = [];
    public arrayofDepartments: any = [];
    public arrayofSupervisors: [] = [];

    public students   : [] = [];
    public levelsId   : [] = [];
    public directionId: any = [];
    public specialtieId: [] = [];
    public departmentsId: [] = [];
    public arrayOfFaculty: any = [];

    public filterSettings: DropDownFilterSettings = {
      caseSensitive: false,
      operator: "contains",
    };

    constructor(
        private searchStudentsService: SearchStudentsService,
    ) { }
   
    public getFaculty() {
        this.searchStudentsService.getFaculty()
          .subscribe(response => {
             this.facultys = response;
             if (this.faculty == 0){
              this.faculty =  this.facultys["0"]["id"];
             }
             this.arrayOfFaculty[0] = this.faculty;
             this.getGroups();
             this.getDirections();
             this.getSpecialties();
          });
      }

    public onFacultySelectionChange(value: any) {
        this.faculty = value;
        this.arrayOfFaculty[0] = this.faculty;
        this.getGroups();
        this.getDirections();
        this.getSpecialties();
    }
//выбор всех групп
    public isCheckedMultiSelect = false;

    public get isIndet() {
      if(this.arrayofGroupe!=undefined)
      return (
        this.arrayofGroupe.length !== 0 && this.arrayofGroupe.length !== this.groups.length
      );
      else return false
    }

    public onClick() {
      this.isCheckedMultiSelect = !this.isCheckedMultiSelect;
      this.arrayofGroupe = this.isCheckedMultiSelect ? Array.from(this.groups, x=>x.id!) : undefined;
    }

    public get toggleAllText() {
      return this.isCheckedMultiSelect ? "Снять выбор" : "Выбрать все";
    }

    public dependentGroupe: any = [];
    
    public getGroups() {
      this.searchStudentsService.getGroups(this.faculty)
        .subscribe(response => {
            this.groups = response;
            this.dependentGroupe = [];
            for(let i=0; i<this.groups.length; i++) {
              this.dependentGroupe.push(this.groups[i].id);
            }
        });
    }

    public quantityGroup: number = 0;
    public getAllGroups() {
      this.searchStudentsService.getAllGroups()
        .subscribe(response => {
            this.quantityGroup = response.length;
        });
    }
// Выбор всех Направлений
    public isMultiSelectDirections = false;

    public get isIndetDirections() {
      if(this.arrayofDirections != undefined)
      return (
        this.arrayofDirections.length !== 0 && this.arrayofDirections.length !== this.directions.length
      );
      else return false
    }

    public onClickDirections() {
      this.isMultiSelectDirections = !this.isMultiSelectDirections;
      this.arrayofDirections = this.isMultiSelectDirections ? Array.from(this.directions, x=>x.id!) : undefined;
    }

    public get toggleAllTextDirections() {
      return this.isMultiSelectDirections ? "Снять выбор" : "Выбрать все";
    }

    public dependentDirection: any = [];

    public getDirections() {
      this.searchStudentsService.getDirections(this.faculty, this.departmentsId)
        .subscribe(response => {
            this.directions = response;
            this.dependentDirection = [];
            for(let i=0; i<this.directions.length; i++) {
              this.dependentDirection.push(this.directions[i].name);
            }
        });
    }

    public quantityDirections: number = 0;
    public getAllDirections() {
      this.searchStudentsService.getAllDirections()
        .subscribe(response => {
            this.quantityDirections = response.length;
        });    
    }

    public onDirectionSelectionChange(value: any){
        this.directionId = value;
 //       this.getSpecialties();
    }
// Выбор всех специальностей
    public isMultiSelectSpecialties= false;

    public get isIndetSpecialties() {
      if(this.arrayofSpecialitys != undefined)
      return (
        this.arrayofSpecialitys.length !== 0 && this.arrayofSpecialitys.length !== this.specialties.length
      );
      else return false
    }

    public onClickSpecialties() {
      this.isMultiSelectSpecialties = !this.isMultiSelectSpecialties;
      this.arrayofSpecialitys = this.isMultiSelectSpecialties ? Array.from(this.specialties, x=>x.id!) : undefined;
    }

    public get toggleAllTextSpecialties() {
      return this.isMultiSelectSpecialties ? "Снять выбор" : "Выбрать все";
    }

    public dependentSpecialties: any = [];
     
    public getSpecialties() {
        this.searchStudentsService.getSpecialties(this.directionId, this.faculty)
          .subscribe(response => {
             this.specialties = response;
             this.dependentSpecialties = [];
            for(let i=0; i<this.specialties.length; i++) {
              this.dependentSpecialties.push(this.specialties[i].id);
            }
          });
    }
    public quantitySpecialties: number = 0;

    public getAllSpecialties() {
      this.searchStudentsService.getAllSpecialties()
        .subscribe(response => {
           this.quantitySpecialties = response.length;
        });
    }

    public getBudgets() {
        this.searchStudentsService.getBudgets()
          .subscribe(response => {
             this.budgets = response;
          });
      }  

    public getForms() {
        this.searchStudentsService.getForms()
          .subscribe(response => {
            this.forms = response;    
          });
    }  

    public getLevels() {
        this.searchStudentsService.getLevels()
            .subscribe(response => {
                this.levels = response;
            });
    }

    public getCourses() {
      this.searchStudentsService.getCourses()
          .subscribe(response => {
            if(response[0].courseNum === null) {
              this.courses = this.allCourses;
            }
            else{
              this.courses = response;
            }
          });
  }

// Выбор всех кафедр
    public isMultiSelectDepartments = false;

    public get isIndetDepartments() {
      if(this.arrayofDepartments!=undefined)
      return (
        this.arrayofDepartments.length !== 0 && this.arrayofDepartments.length !== this.departments.length
      );
      else return false
    }

    public onClickDepartments() {
      this.isMultiSelectDepartments = !this.isMultiSelectDepartments;
      this.arrayofDepartments = this.isMultiSelectDepartments ? Array.from(this.departments, x=>x.id!) : undefined;
    }

    public get toggleAllTextDepartments() {
      return this.isMultiSelectDepartments ? "Снять выбор" : "Выбрать все";
    }

    public getDepartments() {
      this.searchStudentsService.getDepartments()
        .subscribe( response => {
          this.departments = response;
        });
    }

    public onDepartmentsSelectionChange(value: any){
       this.departmentsId = value;
       this.getDirections();
       this.getSpecialties();
    }

 /*   public getSupervisors() {
        this.searchStudentsService.getSupervisors()
          .subscribe(response => {
              this.supervisors = response;
          });
    }*/

   public groupe: any = [];
   public direction: any = [];
   public specialtie: any = [];

    public FindStudents(){  
      if (this.arrayofGroupe.length == 0 && this.dependentGroupe.length < this.quantityGroup) {
        this.groupe = this.dependentGroupe;
      } else {
      this.groupe = this.arrayofGroupe;
      }
      if (this.arrayofDirections.length == 0 && this.dependentDirection.length < this.quantityDirections) {
        this.direction = this.dependentDirection;
      } else {
      this.direction = this.arrayofDirections;
      }
      if (this.arrayofSpecialitys.length == 0 && this.dependentSpecialties.length < this.quantitySpecialties) {
        this.specialtie = this.dependentSpecialties;
      } else {
      this.specialtie = this.arrayofSpecialitys;
      }
      this.searchStudentsService.getExpandStudents(this.initials, this.arrayOfFaculty, this.arrayOfCourse, this.Male, this.groupe, this.arrayofBudget,
        this.direction, this.specialtie, this.arrayofForms, this.arrayofLevels, this.arrayofDepartments, this.arrayofSupervisors)
        .subscribe({next:(data: any) => {
          this.students = data;
       }});
      }  

    public getParams(){
      let data = this.searchStudentsService.getParams();
      this.initials = data["0"].initials;
      this.faculty = data["0"].faculty;
      if (data["0"].faculty !== 0){
        this.arrayOfFaculty[0] = data["0"].faculty;
      }
      this.arrayOfCourse = data["0"].courses;

    }
        
    ngOnInit() {
        this.getParams();

        this.getFaculty();
        this.getGroups();
        this.getDirections();
        this.getSpecialties();
        this.getBudgets();
        this.getForms();
        this.getLevels();
        this.getDepartments();
        this.getCourses();
     //   this.getSupervisors();

        this.getAllGroups();
        this.getAllDirections();
        this.getAllSpecialties();
    }
}
import { Component, OnInit, Input} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Output } from '@angular/core';
import { SearchFaculty } from 'src/app/models/announcement/search-students.model';
import { SearchStudentsService } from 'src/app/services/announcement/search-students.service';


@Component({
    selector: 'app-search-students',
    templateUrl: './search-students.component.html',
    styleUrls: ['./search-students.component.scss']
})

export class SearchStudentsComponent implements OnInit{
    
  //  VisibleAdvancedSearch: boolean = true;
    initials: string = "";

    @Output() buttonClick = new EventEmitter();


    public advanced_search(): void {
      this.searchStudentsService.SearchParams(this.initials, this.departments, this.arrayOfCourse);
      this.buttonClick.emit();        
    }
     
    public facultys: SearchFaculty[] = [];

    public allCourses: Array<{ course: string; courseNum: number }> = [
        {course: "1 курс", courseNum: 1},
        {course: "2 курс", courseNum: 2},
        {course: "3 курс", courseNum: 3},
        {course: "4 курс", courseNum: 4},
        {course: "5 курс", courseNum: 5},
        {course: "6 курс", courseNum: 6},
      ];

    public courses: any = [];

    public departments: number = 0;
    public arrayOfCourse: [] = [];
    public arrayOfFaculty: any = [];
    
    constructor(
        private searchStudentsService: SearchStudentsService,
        private http: HttpClient,
    ) { }

    public getFaculty() {
        this.searchStudentsService.getFaculty()
          .subscribe(response => {
             this.facultys = response;
             if (this.departments == 0){
             this.departments =  this.facultys["0"]["id"];
             }
             this.arrayOfFaculty[0] = this.departments;
          });
    }

    public onFacultySelectionChange(value: any) {
      this.departments = value;
      this.arrayOfFaculty[0] = this.departments;
    }

    public students: [] = [];
    public departmentsId: [] = [];

    public FindStudents(){
      this.searchStudentsService.getStudents(this.initials, this.arrayOfFaculty, this.arrayOfCourse)
         .subscribe({next:(data: any) => {
            this.students = data;
        }});
    }
   
    public getParams(){
      let data = this.searchStudentsService.getParams();
      if (data[0] !== undefined){
          this.initials = data["0"].initials;
          this.departments = data["0"].faculty;
          if (data["0"].faculty !== 0){
            this.arrayOfFaculty[0] = data["0"].faculty;
          }
          this.arrayOfCourse = data["0"].courses;
      }
    }

    public getCourses() {
      this.searchStudentsService.getCourses()
          .subscribe(response => {
            if(response[0].courseNum === null) {
              this.courses = this.allCourses;
            }
            else{
              this.courses = response;
            }
          });
  }

    ngOnInit() {
        this.getFaculty();
        this.getParams();
        this.getCourses();
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataFromRemoteService } from "src/app/components/dicts/holidays-and-weekends/holidays-and-weekends/holidays-and-weekends.component";
import { Holiday } from "src/app/models/dicts/holiday.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HolidaysAndWeekendsService {
    private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.holidaysandweekends}`;
    private isDayOffBaseURL: string = `${environment.isDayOffEndpoint}`;

    constructor(private http: HttpClient) {
    }

    public getAllHolidaysByRemoteService(year: number, daysInWeek: number, dataFromService: DataFromRemoteService): Observable<Holiday[]> {
        this.getDataFromRemoteService(year, daysInWeek);
        return this.http.post<Holiday[]>(this.baseUrl + '/' + year + '/' + daysInWeek, dataFromService);
    }

    public ChangeCurrentHolidays(holidays: Holiday[]): Observable<Holiday[]> {
        return this.http.post<Holiday[]>(this.baseUrl + '/ChangeHolidaysInStudyYear', holidays);
    }

    public getDataFromRemoteService(year: number, daysInWeek: number) {
        return this.http.get(this.isDayOffBaseURL + year + '&sd=' + daysInWeek, {responseType: 'text'});
    }

    public getAllHolidays(year: number, daysInWeek: number): Observable<Holiday[]> {
        return this.http.get<Holiday[]>(this.baseUrl + '/' + year + '/' + daysInWeek);
    }
}
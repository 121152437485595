
    <div class="title"> Поиск сотрудника </div>
    <div >
        <div class="unit">
            <div class="search-explanation"> ФИО </div>
            <div class="input-search">
                <kendo-textbox
                    [clearButton]="true"
                    name="fio"                                                        
                    [(ngModel)]="initials"
                    >
                </kendo-textbox>
            </div>
        </div>
        <div class="unit">
            <div class="search-explanation"> Должность</div>
            <div>
                <kendo-multiselect
                class="w-520"                         
                [data]=" posts"
                kendoMultiSelectSummaryTag
                [kendoDropDownFilter]="filterSettings"
                placeholder="Должность"
                textField="name"                            
                valueField="id"
                [autoClose]="false"
                [checkboxes]="true"
                [valuePrimitive]="true"
                [(ngModel)]="arrayOfPosts"
                name="group">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span></span>
                     {{ dataItems.length }} выбрано
                    </ng-template>
                    <ng-template kendoMultiSelectHeaderTemplate>
                        <span class="m-l11">
                          <input
                            type="checkbox"
                            id="chk"
                            kendoCheckBox
                            [checked]="isCheckedMultiSelectPosts"
                            [indeterminate]="isIndetPosts"
                            (click)="onClickPosts()"
                          />
                          <kendo-label for="chk">{{ toggleAllTextPosts }}</kendo-label>
                        </span>
                      </ng-template>
            </kendo-multiselect>
            </div>
        </div>
        <div class="unit m-b25">
            <div class="search-explanation"> Отдел</div>
            <div>
                <kendo-multiselect  
                class="w-520"                          
                [data]=" departments"
                kendoMultiSelectSummaryTag
                [kendoDropDownFilter]="filterSettings"
                placeholder="Отдел"
                textField="name"                            
                valueField="id"
                [autoClose]="false"
                [checkboxes]="true"
                [valuePrimitive]="true"
                [(ngModel)]="arrayOfDepartments"
                name="group">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span></span>
                     {{ dataItems.length }} выбрано
                    </ng-template>
                    <ng-template kendoMultiSelectHeaderTemplate>
                        <span class="m-l11">
                          <input
                            type="checkbox"
                            id="chk"
                            kendoCheckBox
                            [checked]="isCheckedMultiSelectDepartments"
                            [indeterminate]="isIndetDepartments"
                            (click)="onClickDepartments()"
                          />
                          <kendo-label for="chk">{{ toggleAllTextDepartments }}</kendo-label>
                        </span>
                      </ng-template>
            </kendo-multiselect>
            </div>
        </div>
        <div class="unit">
            <div class="search-explanation"></div>
            <div class="w-520 flex end" >
                <button kendoButton class="blue-button"
                        (click)="FindPersons()">
                    Найти сотрудников
                </button>
            </div>
        </div>
    </div>

import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {GridComponent, PageSizeItem, RemoveEvent} from "@progress/kendo-angular-grid";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {MilitaryCommissariat} from "../../../models/contingent/militarycommissariat.model";
import {MilitaryCommissariatService} from "../../../services/contingent/military-commissariat.service";
import { Addresses } from '../../../models/contingent/addresses.model';
import { AddressesService } from '../../../services/contingent/addresses.service';
import { Guid } from 'guid-typescript';
import { StudPerson } from '../../../models/contingent/studperson.model';
import { DictStateService } from '../../../services/contingent/dictstate.service';
import { DictState } from '../../../models/contingent/dictstate.model';
import { CityTypes } from '../../../models/contingent/citytypes.model';
import { StreetTypes } from '../../../models/contingent/streettypes.model';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-militarycommissariat',
  templateUrl: './militarycommissariat.component.html',
  styleUrls: ['./militarycommissariat.component.scss']
})
export class MilitarycommissariatComponent implements OnInit {

  public militaryCommissariats: MilitaryCommissariat[] = [];
  public pageSize = 10;
  public sizes:(number | PageSizeItem)[] = [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}, {text: 'Все', value : 'all'}];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  public opened = false;
  public editable?: boolean
  public addressHints: any = [];
  public selectedAddressItem: any;
  public addresses: Addresses[] = [];
  public address: Addresses={
    addressId: 0,
    addressExternalId: Guid.createEmpty(),
    studPersonId: Guid.createEmpty(),
    studPersons: new StudPerson,
    country: "",
    state: "",
    city: "",
    street: "",
    house: "",
    building: "",
    flat: "",
    district: "",
    corpus: "",
    zipCode: "",
    isRegistered: false,
    comment: "",
    dateRegistered: new Date(),
    dictCityTypeId: Guid.createEmpty(),
    dictCityType: new CityTypes,
    dictStreetTypeId: Guid.createEmpty(),
    dictStreetType: new StreetTypes,
    objectGuid: undefined,
    cityFullName: "",
    streetFullName: "",
    houseFullName: ""
  }
  public dictStates: DictState[] = [];
  public selectDictStates: DictState[] = [];
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private militaryCommissariatService: MilitaryCommissariatService,
              private renderer: Renderer2, private formBuilder: UntypedFormBuilder,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: ContingentUserAccessService,
              private addressesService: AddressesService,
              private dictStateService:DictStateService,
  ) { }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllMilitaryCommissariat();
    this.getDictState();
  }

  public commissariatForm: UntypedFormGroup = new UntypedFormGroup({
    id:  new UntypedFormControl(),
    state: new UntypedFormControl(),
    city: new UntypedFormControl(),
    street: new UntypedFormControl(),
    house: new UntypedFormControl(),
    zipCode: new UntypedFormControl(),
    district: new UntypedFormControl(),
    address: new UntypedFormControl(),
    order: new UntypedFormControl(),
    addressSearch: new UntypedFormControl(),
    objectGuid: new UntypedFormControl(),
    commissariatName: new UntypedFormControl(),
  });

  public onAddressFilterChange(e: any) {
    if(e.length < 3) return;
    this.addressesService.GetAddressHint(e).subscribe((response: any) => {
        this.addressHints = response.hints;
      }
    );
  }

  public onAddressChange(e: any) {
    if(e) {
      this.addressesService.GetAddressItemById(e).subscribe((response: any) => {
        this.selectedAddressItem = response;
        let address = this.commissariatForm.value;
        address.zipCode = this.selectedAddressItem.zipCode;
        address.district = this.selectedAddressItem.district;
        address.city = this.selectedAddressItem.cityFullName;
        address.street = this.selectedAddressItem.streetFullName;
        if (this.selectedAddressItem.houseFullName) address.house = this.selectedAddressItem.houseFullName;
        address.state = this.selectDictStates.find(
          item => item.stateName
                      .toLocaleLowerCase()
                      .includes(this.selectedAddressItem.state?.toLocaleLowerCase())
        )?.stateName;
        address.objectGuid = this.selectedAddressItem.objectGuid;
        this.commissariatForm.reset(address);
      });
    }
  }

  public getDictState() {
    this.dictStateService.getAllstate()
      .subscribe(
        response => {
          this.dictStates = response;
          this.selectDictStates = this.dictStates
        }
      );
  }

  public addHandler(): void {
    this.isNew = true;
    this.opened = true;
  }

  public editHandler(e: any): void {
    this.commissariatForm = formGroup(e.dataItem);
    this.isNew = false;
    this.opened = true;
  }

  public onCancel(): void {
    this.commissariatForm.reset();
    this.opened = false;
  }

  public saveCurrent(): void {
    if (this.commissariatForm && this.commissariatForm.valid) {
      if (this.isNew) {
        this.militaryCommissariatService.addMilitaryCommissariat(this.commissariatForm.value)
              .subscribe({
                next:() => {
                  this.getAllMilitaryCommissariat();
                  this.notificationService.showSuccess("Добавлено");
                },
                error:() => {
                  this.notificationService.showError('Не удалось добавить комиссариат');
                }}
              );
      } else {
        this.militaryCommissariatService.updateMilitaryCommissariat(this.commissariatForm.value)
          .subscribe({
            next:() => {
              this.getAllMilitaryCommissariat();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
          this
      }
      this.opened = false;
    }
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.commissariatName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.militaryCommissariatService.deleteMilitaryCommissariat(dataItem.id)
            .subscribe({
              next:() => {
                this.getAllMilitaryCommissariat();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else this.opened = false;
      }
    });
  }

  public getAllMilitaryCommissariat() {
    this.militaryCommissariatService.getAllMilitaryCommissariat()
      .subscribe(
        response => {
          this.militaryCommissariats = response;
        }
      );
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }
}

const formGroup = (dataItem: {
  id?: any;
  commissariatName: any;
  state: any;
  city: any;
  street: any;
  house: any;
  zipCode?: any;
  district?: any;
  address?: any;
  order?: any;
  addressSearch?: any;
  objectGuid?: any; }) =>
new UntypedFormGroup({
  id:  new UntypedFormControl(dataItem.id),
  commissariatName: new UntypedFormControl(dataItem.commissariatName, Validators.required),
  state: new UntypedFormControl(dataItem.state, Validators.required),
  city: new UntypedFormControl(dataItem.city, Validators.required),
  street: new UntypedFormControl(dataItem.street, Validators.required),
  house: new UntypedFormControl(dataItem.house, Validators.required),
  zipCode: new UntypedFormControl(dataItem.zipCode),
  district: new UntypedFormControl(dataItem.district),
  address: new UntypedFormControl(dataItem.address),
  order: new UntypedFormControl(dataItem.order),
  addressSearch: new UntypedFormControl(dataItem.addressSearch),
  objectGuid: new UntypedFormControl(dataItem.objectGuid),
});

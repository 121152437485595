import {
  ApplicationConstructorStatusesEnum,
  NumericApplicationConstructorStatusesEnum
} from '../enums/application-constructor-statuses.enum';

export interface ApplicationConstructorStatus {
  enum: NumericApplicationConstructorStatusesEnum;
  name: string;
}
export const constructorStatuses: ApplicationConstructorStatus[] = [
  {enum: NumericApplicationConstructorStatusesEnum.Draft, name: ApplicationConstructorStatusesEnum.Draft},
  {enum: NumericApplicationConstructorStatusesEnum.Published, name: ApplicationConstructorStatusesEnum.Published},
  {enum: NumericApplicationConstructorStatusesEnum.Archive, name: ApplicationConstructorStatusesEnum.Archive},
];

export const constructorStatusActions = new Map<NumericApplicationConstructorStatusesEnum, string>([
  [NumericApplicationConstructorStatusesEnum.Draft, 'ToDraft'],
  [NumericApplicationConstructorStatusesEnum.Published, 'ToPublished'],
  [NumericApplicationConstructorStatusesEnum.Archive, 'ToArchive'],
]);

import {Component, Input, OnInit} from '@angular/core';
import {FilterService, BaseFilterCellComponent} from '@progress/kendo-angular-grid';
import {CompositeFilterDescriptor} from '@progress/kendo-data-query';
import {VirtualizationSettings} from "@progress/kendo-angular-dropdowns";
import { lastValueFrom } from 'rxjs';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.css']
})
export class FilterDropdownComponent extends BaseFilterCellComponent implements OnInit {

  public get selectedValue(): unknown {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  public defaultValue = "";

  @Input() public override filter!: CompositeFilterDescriptor;
  @Input() public data!: unknown[];
  @Input() public textField!: string;
  @Input() public valueField!: string;
  @Input() public componentName!: string;
  @Input() public gridField!: string;
  @Input() public useVirtual: boolean = true;
  @Input() public userAccessValue!: boolean;
  @Input() public userAccessSource!: string;

  public filterVirtualization: boolean | VirtualizationSettings = {
    itemHeight: 28
  };

  public get defaultItem(): { [Key: string]: unknown } {
    return {
      [this.textField]: '',
      [this.valueField]: null
    };
  }

  constructor(
    filterService: FilterService) {
    super(filterService);
  }

  ngOnInit(): void {
    if(this.userAccessValue) this.applyEducationUserAccessFilters();
  }

  private applyEducationUserAccessFilters() {
    setTimeout(() => {
      if(Array.isArray(this.data) && this.data.length === 1) {
        switch(this.userAccessSource) {
          case 'trainingLevel':
            this.onChange(this.data[0].trainingLevelId)
            break;
          case 'faculties':
            this.onChange(this.data[0].facultyName)
            break;
        }
      }
    }, 100);
  }

  // Загружаем фильтр из локального хранилища при загрузке

  // applyFilterOnLoad(): void {
  //   const test = localStorage.getItem(`filter.${this.componentName}.${this.valueField}`);
  //   if (test !== null) {
  //     const string = JSON.parse(test);
  //     if(string !== null)
  //     {
  //       this.applyFilter(
  //           this.updateFilter({ // add a filter for the field with the value
  //             field: this.valueField,
  //             operator: 'eq',
  //             value: string
  //           })
  //       ); // update the root filter
  //       this.defaultValue = string;
  //       console.log(`Passed filter.${this.componentName}.${this.valueField}`)
  //     }
  //   }
  // }

  public onChange(value: unknown): void {
    this.applyFilter(
      value === null ? // value of the default item
        this.removeFilter(this.gridField ?? this.valueField) : // remove the filter
        this.updateFilter({ // add a filter for the field with the value
          field: this.gridField ?? this.valueField,
          operator: 'eq',
          value: value
        })
    );
    //this.defaultValue = `${value}`; // update the root filter
    //localStorage.setItem(`filter.${this.componentName}.${this.valueField}`, JSON.stringify(value))
  }
}

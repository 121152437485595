import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintTemplateService } from '../../../../services/gia/printTemplate.service';
import {
  PrintTemplateFile,
  PrintTemplateModel,
  PrintTemplateTrainingLevels
} from '../../../../models/gia/printtemplate/printTemplate.model';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DictPrintTemplateCategoryModel } from '../../../../models/gia/dicts/dictPrintTemplateCategory.model';
import { DictPrintTemplateCategoryService } from '../../../../services/gia/dictPrintTemplateCategory.service';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from '../../../../../environments/environment';
import { saveAs } from '@progress/kendo-file-saver';
import { DownloadFile } from '../../../../helpers/downloadFile-helper';
import { DictPrintTemplateCategoryEnum } from '../../../../models/gia/enums/dictPrintTemplateCategory.enum';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DictDto, StudyYearDto } from '../../../../models/gia/dicts/dicts.model';
import { DictsService } from '../../../../services/gia/dicts.service';
import { DepartmentService } from '../../../../services/gia/department.service';
import { Department, DepartmentType } from '../../../../models/gia/dicts/department.model';
import { GiaUserAccessService } from '../../../../services/useraccess/gia-user-access.service';
import { openDialog } from '../../../../helpers/dialogHelper';
import { objectRewrite } from '../../../../helpers/multiselect-helper';

@Component({
  selector: 'app-add-print-template',
  templateUrl: './add-print-template.component.html',
  styleUrls: ['./add-print-template.component.scss'],
})
export class AddPrintTemplateComponent implements OnInit {
  public name: string | undefined = '';
  public printTemplateId: string | null = null;
  public filial: string | null = '';
  public allFilial: DictDto[] = [];
  public yearAdmissionList: StudyYearDto[] = [];
  public dictTrainingLevels: DictDto[] = [];
  public trainingLevels: DictDto[] | null = [];
  public yearAdmission?: number;
  public dictPrintTemplateCategoryModels: DictPrintTemplateCategoryModel[] = [];
  public departments: Department[] = [];
  public editable = false;
  public filters: any;
  public printTemplateFilters: { yearAdmission: number; filial: string } = {
    yearAdmission: 0,
    filial: '',
  };
  public restrictions: FileRestrictions = {
    maxFileSize: environment.contingent.maxFileSize,
    allowedExtensions: ['.docx'],
  };
  public tooltip: string = 'Для изменения шаблона печатной формы нужно скачать файл шаблона, внести необходимые изменения и загрузить файл обратно.';
  public firstFile: PrintTemplateFile = new PrintTemplateFile();
  public secondFile: PrintTemplateFile | undefined = new PrintTemplateFile();
  public thirdFile: PrintTemplateFile | undefined = new PrintTemplateFile();

  public isSaved = false;
  public file: any;
  public error: string | null = null;

  public department: string = '';

  public printTemplateForm: FormGroup = new FormGroup({
    filialId: new FormControl(),
    yearAdmission: new FormControl(0),
    dictPrintTemplateCategoryId: new FormControl(null),
    name: new FormControl(null),
    printTemplateTrainingLevels: new FormArray([]),
    departmentId: new FormControl(),
    printerName: new FormControl(),
    isDefault: new FormControl(false),
    printTemplateFirstFile: new FormControl(null),
    printTemplateSecondFile: new FormControl(null),
    printTemplateThirdFile: new FormControl(null),
  });

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private dictService: DictsService,
    private userAccessService: GiaUserAccessService,
    private departmentService: DepartmentService,
    private printTemplateService: PrintTemplateService,
    private router: Router,
    private dictPrintTemplateCategoryService: DictPrintTemplateCategoryService,
  ) {}

  ngOnInit(): void {
    this.getAccessLevel();
    this.route.params.subscribe((params) => {
      this.printTemplateId = params['id'];
      this.printTemplateId
        ? this.getPrintTemplateById(this.printTemplateId)
        : (this.printTemplateForm = this.getPrintTemplateFormGroup(this.printTemplateForm.value));
    });
    this.getLocalFilters();
  }
  public getLocalFilters() {
    this.filters = localStorage.getItem('printTemplateFilters');
    if (this.filters) {
      this.printTemplateFilters = JSON.parse(this.filters);
      this.yearAdmission = this.printTemplateFilters.yearAdmission;
      this.filial = this.printTemplateFilters.filial;
    }
  }

  public getPrintTemplateById(id: string) {
    this.printTemplateService.get(id).subscribe({
      next: (response) => {
        this.name = response?.name;
        this.firstFile = response.printTemplateFirstFile;
        this.secondFile = response?.printTemplateSecondFile;
        this.thirdFile = response?.printTemplateThirdFile;
        this.department = response.departmentId ?? '';

        this.printTemplateForm = this.getPrintTemplateFormGroup(response);
      },
      error: (error) => {
        this.notificationService.showError(error.error);
      },
    });
  }
  public getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe({
      next: (response) => {
        this.editable = response.printTemplateAccess;
        this.getAllFilial();
        this.getAllDictPrintTemplateCategories();
        this.getAllTrainingLevel();
        this.getAllDepartments();
      },
    });
  }

  public getAllFilial() {
    this.dictService.getFilials().subscribe((response) => {
      this.allFilial = response;
      this.allFilial.unshift({ id: '', name: 'Все', shortName: 'Все' });
      this.getYearAdmissionList();
    });
  }

  public getYearAdmissionList() {
    this.dictService.getStudyYears().subscribe((response) => {
      this.yearAdmissionList = response;
    });
  }

  public getAllDictPrintTemplateCategories() {
    this.dictPrintTemplateCategoryService.getList().subscribe(
      (response) => {
        this.dictPrintTemplateCategoryModels = response;
      },
      (error) => {
        this.notificationService.showError('Не удалось получить категории шаблонов');
      },
    );
  }

  public getAllTrainingLevel() {
    this.dictService.getStudentTrainingLevels().subscribe({
      next: (response) => {
        this.dictTrainingLevels = response;
        this.dictTrainingLevels = [{ name: 'Все', id: '' }, ...response];
      },
      error: () => {
        this.notificationService.showError('Не удалось получить уровни подготовки');
      },
    });
  }

  public getAllDepartments(trainingLevels?: PrintTemplateTrainingLevels[] | undefined) {
    this.departmentService.getDepartments(DepartmentType.Факультет, null, trainingLevels).subscribe({
      next: (response) => {
        this.departments = response;
        this.departments.unshift({ id: '', name: 'Все', shortName: 'Все' });
      },
      error: () => {
        this.notificationService.showError('Не удалось получить институты/факультеты');
      },
    });
  }

  public isDiploma(dictPrintTemplateCategoryId: string): boolean {
    return this.dictPrintTemplateCategoryModels.find((item) => item.id == dictPrintTemplateCategoryId)?.value === DictPrintTemplateCategoryEnum.Дипломы;
  }

  public downloadFile(file: File | string, printTemplateId?: string) {
    if (printTemplateId) {
      this.printTemplateService.getPrintTemplateFile(printTemplateId).subscribe({
        next: (value) => {
          saveAs(value, file as string);
        },
        error: () => {
          this.notificationService.showError('Не удалось скачать файл');
        },
      });
    } else {
      DownloadFile(file as File);
    }
  }

  public async save() {
    this.isSaved = true;
    const observer = {
      next: () => {
        this.notificationService.showSuccess(`Шаблон ${this.printTemplateForm.value.id ? 'изменён' : 'сохранён'}`);
        this.isSaved = false;
        this.onCancel();
      },
      error: (error: any) => {
        this.notificationService.showError(error.error);
        this.error = error.error;
        this.isSaved = false;
      },
    };

    this.printTemplateForm.value.id
      ? this.printTemplateService.update(this.printTemplateForm.value).subscribe(observer)
      : this.printTemplateService.add(this.printTemplateForm.value).subscribe(observer);
  }

  public async delete() {
    const dialog: DialogRef = openDialog(this.dialogService, 'Вы действительно хотите удалить шаблон?');
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.printTemplateService.delete(this.printTemplateId!).subscribe({
          next: () => {
            this.notificationService.showSuccess(`Шаблон удален`);
            this.onCancel();
          },
          error: (err) => {
            this.notificationService.showError(err.error);
          },
        });
      }
    });
  }

  onCancel(): void {
    this.router.navigate(['/gia/printtemplate']);
  }

  public onValueChange(value?: string | number | null) {
    const object = { filial: this.filial, yearAdmission: this.yearAdmission };
    localStorage.setItem('printTemplateFilters', JSON.stringify(object));
  }

  private getPrintTemplateFormGroup(value?: PrintTemplateModel) {
    let data = value?.printTemplateTrainingLevels?.map((item) => {
      if (item.id === null) {
        item.id = '';
        return item;
      }
      return item;
    });

    this.trainingLevels = data ?? null;

    return new FormGroup({
      id: new FormControl(value?.id ?? null),
      name: new FormControl<string | null>(value?.name ?? null, Validators.required),
      filialId: new FormControl<string | null>(value?.filialId ?? ''),
      yearAdmission: new FormControl<number | null>(value?.yearAdmission ?? 0, Validators.required),
      dictPrintTemplateCategoryId: new FormControl<string | null>(value?.dictPrintTemplateCategoryId ?? null, Validators.required),
      printTemplateTrainingLevels: new FormControl(data ?? null, Validators.required),
      departmentId: new FormControl<string | null>(value?.departmentId ?? ''),
      printerName: new FormControl<string | null>(value?.printerName ?? ''),
      isDefault: new FormControl<boolean | null>(value?.isDefault ?? false),
      printTemplateFirstFile: new FormControl(value?.printTemplateFirstFile?.name ?? null, Validators.required),
      printTemplateSecondFile: new FormControl(value?.printTemplateSecondFile?.name ?? null),
      printTemplateThirdFile: new FormControl(value?.printTemplateThirdFile?.name ?? null),
    });
  }

  public valueChangeTrainingLevel(value: any) {
    this.trainingLevels = objectRewrite(value);
    this.getAllDepartments(this.trainingLevels);
    this.department = '';
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Student} from '../../models/mfc/student.model';
import {NotificationsService} from '../notifications/notifications.service';


@Injectable({
  providedIn: 'root'
})
export class StudentService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.person}/GetAllStudents`;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationsService
  ) { }

  getAllStudents() {
    return this.http.get<Student[]>(this.baseUrl)
      .pipe(
        tap((response) => {
          if (!response) {
            this.notificationService.showError('Не удалось найти студентов, за которых вы можете создать заявку');
          }
        }),
        map((d) => !d ? [] :
          d.map((item) => ({id: item.id, fio: `${item.fio} (гр. ${item.groupName})`})))
      );
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {dictPagerSettings} from "../../../../models/dicts/pagerSettings.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AccessRights} from "../../../../models/useraccess/gia/giaUserAccess";
import {ActivatedRoute} from "@angular/router";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";
import {DictsService} from "../../../../services/gia/dicts.service";
import {getData$} from "../../../../../environments/environment";
import {openDialog} from "../../../../helpers/dialogHelper";
import {GiaDict} from "../../../../models/gia/dicts/dicts.model";
import {GiaDictDesignerMethods} from "./dict-designer.methods";

@Component({
  selector: 'app-dict-designer',
  templateUrl: './dict-designer.component.html',
  styleUrls: ['./dict-designer.component.scss']
})
export class DictDesignerComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  // Pager settings
  public pagerSettings: PagerSettings = dictPagerSettings;

  public isNew: boolean = false;
  public isLine: boolean = false;
  private editedRowIndex: number | undefined;

  private dictId: string = '';
  public dicts: GiaDict[] = [];

  public formGroup: FormGroup = this.resetFormGroup;
  public editable = this.userAccessService.currentUserAccess$.value.dictCreating;

  constructor(private activateRoute: ActivatedRoute,
              private dialogService: DialogService,
              private dictMethods: GiaDictDesignerMethods,
              private userAccessService: GiaUserAccessService,
              private dictService: DictsService) {
  }

  async ngOnInit() {
    getData$.subscribe(() => this.getDicts())
  }

  private getDicts() {
    this.dictService.getDicts().subscribe(value => this.dicts = value);
  }

  private get resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      isHidden: new FormControl(false, Validators.required),
      sortNumber: new FormControl(null)
    })
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  public addRow() {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      isHidden: new FormControl(false, Validators.required),
      sortNumber: new FormControl(null),
    })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public async saveRow()  {
    if (this.formGroup) await this.dictMethods.saveDict(this.isNew, this.formGroup, this.dictId);
    this.onCancel();
  }

  public async editRow({ sender, rowIndex, dataItem, columnIndex }: CellClickEvent) {
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name),
      isHidden: new FormControl(dataItem.isHidden),
      sortNumber: new FormControl(dataItem.sortNumber)
    })

    if (this.isNew || !this.editable || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.onCancel();
    this.isLine = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}?"`);
    this.dictMethods.removeDict(dialog.result, dataItem.id);
  }

}

<contingent-header></contingent-header>
<h1>Типы работ</h1>

<div class="grid-container">

    <kendo-treelist
        [kendoTreeListFlatBinding]="worktypes"
        kendoTreeListExpandable
        idField="id"
        parentIdField="parentId"
        [pageable]="{
            buttonCount: buttonCount,
            info: info,
            pageSizes: sizes
          }"
        [pageSize]="pageSize"
        
        (add)="addHandler($event)"
        (edit)="editHandler($event)"
        (remove)="removeHandler($event)"
        (save)="saveHandler($event)"
        (cancel)="cancelHandler($event)"
    >
    <ng-template kendoTreeListToolbarTemplate>
        <button kendoTreeListAddCommand type="button" size="small" themeColor="success" icon="plus">Добавить</button>
    </ng-template>

    <kendo-treelist-column [expandable]="true" field="name" title="Название" [width]="400" headerClass="gridHeader">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <span class="name">
                {{dataItem.name}}
            </span>
        </ng-template>

        <ng-template kendoTreeListEditTemplate let-formGroup="formGroup">
            <kendo-textbox
                size="small"
                [formControl]="formGroup.get('name')"
                [clearButton]="true"
            ></kendo-textbox>
        </ng-template>
    </kendo-treelist-column>
    
    <kendo-treelist-column field="mark" title="Количество баллов" [width]="120" headerClass="gridHeader">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.parentId ? dataItem.mark : ""}}
            </span>           
        </ng-template>

        <ng-template kendoTreeListEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
            <span class="alignCells" *ngIf="dataItem.parentId || isParent">
                <kendo-numerictextbox
                    [style.width.px]="120"
                    [value]="dataItem.mark"
                    [min]="0"
                    format="n0"
                    [decimals]="0"
                    [formControl]="formGroup.get('mark')">
                </kendo-numerictextbox>
            </span>
        </ng-template>
    </kendo-treelist-column>

    <kendo-treelist-column field="directionActivityId" title="Категория достижений" [width]="250" headerClass="gridHeader">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <span class="alignCells">
                {{ getActivityName(dataItem.directionActivityId) }}
            </span>
        </ng-template>

        <ng-template kendoTreeListEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <kendo-dropdownlist
            [disabled]="dataItem.parentId || isParent"
            [data]="categoryAchievements"
            textField="name"
            valueField="id"
            [valuePrimitive]="true"
            (valueChange)="valueChangeDirectionActivity($event)"
            [formControl]="formGroup.get('directionActivityId')"
            >
            </kendo-dropdownlist>
        </ng-template>
    </kendo-treelist-column>

    <kendo-treelist-column field="workValidity" title="Срок актуальности" [width]="120" headerClass="gridHeader">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <span class="alignCells">
                {{dataItem.workValidity}}
            </span>
        </ng-template>

        <ng-template kendoTreeListEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
            <span class="alignCells">
                <kendo-numerictextbox
                    [style.width.px]="120"
                    [value]="dataItem.workValidity"
                    [min]="0"
                    format="n0"
                    [decimals]="0"
                    [formControl]="formGroup.get('workValidity')">
                </kendo-numerictextbox>
            </span>
        </ng-template>
    </kendo-treelist-column>

    <kendo-treelist-column field="tooltip" title="Подсказка" [width]="300" headerClass="gridHeader">
        <ng-template kendoTreeListCellTemplate let-dataItem>
            <span class="alignCells">
                {{dataItem.tooltip}}
            </span>
        </ng-template>
    </kendo-treelist-column>

   <kendo-treelist-command-column [width]="180">
        <ng-template
          kendoTreeListCellTemplate
          let-isNew="isNew"
          let-cellContext="cellContext"
          let-dataItem="dataItem"
        >          
           <button
                [kendoTreeListAddCommand]="cellContext"
                icon="plus"
                rounded="full"
                title="Добавить подтип"
            ></button>

            <button
                [kendoTreeListEditCommand]="cellContext"
                icon="edit"
                rounded="full"
                title="Изменить"                
            ></button>
            
            <button
                [kendoTreeListRemoveCommand]="cellContext"
                icon="delete"
                rounded="full"
                title="Удалить"
            ></button>
            
            <button
                    [kendoTreeListSaveCommand]="cellContext"
                    [disabled]="formGroup!.invalid"
                    icon="save"
                    rounded="full"
                    title="Сохранить"
                ></button>

            <button
                [kendoTreeListCancelCommand]="cellContext"
                icon="cancel"
                rounded="full"
                title="Отменить"
            ></button>

        </ng-template>
    </kendo-treelist-command-column>

  </kendo-treelist>  
</div>

<div kendoDialogContainer></div>
<publications-settings></publications-settings>
<div class="container">
    <h1>Настройки доступа</h1>
    <span class="filter">
        <h3>Поиск по ФИО</h3>
        <kendo-textbox [(ngModel)]="searchFIO"></kendo-textbox>
        <button kendoButton (click)="clearData()">Очистить</button>
        <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">Применить</button>
    </span>

    <kendo-grid [kendoGridBinding]="userAccesses"
                [pageable]="pagerSettings"
                [skip]="skip"
                [pageSize]="pageSize"
                (pageChange)="pageChange($event)"
                [sortable]="true"
                (edit)="editHandler($event)"
                (remove)="removeHandler($event)"
                (add)="addHandler($event)">
        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>
        <ng-template kendoGridToolbarTemplate>
            <span [ngSwitch]="isInEditingMode">
            <button *ngSwitchCase="false" kendoGridAddCommand themeColor="success" icon="plus">Добавить</button>
            <div *ngSwitchCase="true">
                <button (click)="cancelHandler()" kendoButton>Отмена</button>
                <button (click)="onSave()" [disabled]="!formGroup.valid" kendoButton themeColor="primary">Сохранить</button>
            </div>
            </span>
        </ng-template>
        <kendo-grid-command-column title="" [width]="35">
            <ng-template kendoGridCellTemplate>
                <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full">
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-command-column title="" [width]="35">
            <ng-template kendoGridCellTemplate>
                <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size" rounded="full">
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column [width]="250" field="fio" headerClass="gridHeader" title="ФИО">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        [kendoDropDownFilter]="filterSettings"
                        [virtual]="virtual"
                        textField="fio"
                        valueField="id"
                        [valuePrimitive]="true"
                        [data]="persons"
                        [formControl]="formGroup.get('personId')">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ getPersonName(dataItem) }}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="140" field="report" headerClass="gridHeader" title="Отчеты">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        textField="text"
                        valueField="id"
                        [data]="boolOptions"
                        [formControl]="formGroup.get('report')"
                        [valuePrimitive]="true">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.report ? 'Да' : 'Нет'}}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="140" field="myPublication" headerClass="gridHeader" title="Мои публикации">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        textField="text"
                        valueField="id"
                        [data]="boolOptions"
                        [formControl]="formGroup.get('myPublication')"
                        [valuePrimitive]="true">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.myPublication ? 'Да' : 'Нет'}}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="140" field="allPublication" headerClass="gridHeader" title="Все публикации">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        textField="text"
                        valueField="id"
                        [formControl]="formGroup.get('allPublication')"
                        [data]="dropdownOptions"
                        [valuePrimitive]="true">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dropdownOptions[dataItem.allPublication].text }}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="140" field="author" headerClass="gridHeader" title="Справочник 'Авторы'">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        textField="text"
                        valueField="id"
                        [formControl]="formGroup.get('author')"
                        [data]="dropdownOptions"
                        [valuePrimitive]="true">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dropdownOptions[dataItem.author].text}}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="140" field="dict" headerClass="gridHeader" title="Справочники">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        textField="text"
                        valueField="id"
                        [formControl]="formGroup.get('dict')"
                        [data]="dropdownOptions"
                        [valuePrimitive]="true">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dropdownOptions[dataItem.dict].text }}
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="140" field="accessSettings" headerClass="gridHeader" title="Настройки доступа">
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-combobox
                        textField="text"
                        valueField="id"
                        [formControl]="formGroup.get('accessSettings')"
                        [data]="boolOptions"
                        [valuePrimitive]="true">
                </kendo-combobox>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.accessSettings ? 'Да':'Нет' }}
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>

<div kendoDialogContainer></div>

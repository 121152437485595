import { Guid } from 'guid-typescript';
import {Moment} from "moment";
import {Signatory} from "./sheets.model";

export class MiddleControlSheetListQuery {
  public eduGroupId!: string;
  public semester!: number;
  public contingentDate!: Date|Moment;
  public markDate!: Date|Moment;
  public isCurrentSemester?: boolean;
  public planId?: string;

  public signer?: Signatory;
}

export class MiddleControlSheetHeaderQuery {
  public eduGroupId!: string;
  public semester!: number;
  public planId?: string;

}

export class MiddleControlSheetDisciplineQuery {
  public eduGroupId!: string;
  public disciplineId!: string;
  public electiveDisciplineId!: string | null;
  public semester!: number;
  public contingentDate!: Date|Moment;
  public markDate!: Date|Moment;
  public studentId?: string;
  public isCurrentSemester?: boolean;
  public planId?: string;

  public signer?: Signatory;
}

export class MiddleControlDisciplineExportQuery {
  public eduGroupId!: string;
  public disciplineId!: string;
  public electiveDisciplineId!: string | null;
  public semester!: number;
  public contingentDate!: Date|Moment;
  public markDate!: Date|Moment;
  public parameter!: number;
  public planId?: string;

  public signer?: Signatory;
}

export class MiddleControlTreeQuery {
  public semesterInYear!: number;
  public year!: Number;
  public trainingLevelId!: string[] | null;
  public contingentDate!: Date;
  public isCurrentSemester!: boolean;
  public useContingentDate!: boolean;
}

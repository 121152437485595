import {OOProgram} from "../../../../models/education/oop.model";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {EducationPlanDetailsService} from "../../../../services/education/EducationPlan/education-plan-details.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {Location} from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class EducationPlanReattachmentService {
    constructor(private educationPlanDetailsService: EducationPlanDetailsService,
                private notificationService: NotificationsService,
                private location: Location) { }

    public mapDataSources(value: OOProgram[]) {
        const trainingLevels = value
            .map((elem) => ({
                trainingLevelId: elem.standard.trainingLevel.trainingLevelExternalId,
                trainingLevelName: elem.standard.trainingLevel.trainingLevelName,
            }))
            .filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.trainingLevelId === value.trainingLevelId)
            );
        const qualifications = value
            .map((elem) => ({
                qualificationId: elem.qualification.qualificationExternalId,
                qualificationName: elem.qualification.qualificationName,
            }))
            .filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.qualificationId === value.qualificationId)
            );
        const faculties = value
            .map(elem => ({facultyShortName: elem.faculty.facultyShortName}))
            .filter(
                (value, index, self) =>
                    index === self.findIndex((t) => t.facultyShortName === value.facultyShortName)
            );

        return  {trainingLevels, faculties, qualifications}
    }

    public reattachPlan(dialogResult: Observable<DialogResult>, dataItem: any, educationPlanId: string) {
        dialogResult.subscribe((result) => {
            if (result instanceof DialogCloseResult) {}
            else {
                if (result.text == "Да") {
                    educationPlanId && this.educationPlanDetailsService.reattachPlan(
                        {educationPlanId: educationPlanId, educationProgramId: dataItem.externalId})
                        .subscribe({
                            next: () => {
                                this.notificationService.showSuccess('Успешно');
                                this.location.back();
                            },
                            error: () => this.notificationService.showError('Произошла ошибка')
                        })
                } else {}
            }
        });
    }
}
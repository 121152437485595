import {Guid} from "guid-typescript";
import {GlobalSignatoryCCRead} from "./globalsignatory.model";

export class LocalSignatoryCCRead {
  public id!: Guid | string;
  public globalSignatory!: GlobalSignatoryCCRead;
  public trainingLevels!: LocalSignatoryTrainingLevelCCReadDto[];
  public faculties!: LocalSignatoryFacultyCCReadDto[];
  public localSignatoryRole!: number;
}

export class LocalSignatoryTrainingLevelCCReadDto {
  public id!: string;
  public trainingLevelId!: Guid | string;
  public trainingLevelName!: string;
}

export class LocalSignatoryFacultyCCReadDto {
  public id!: string;
  public facultyId!: string | Guid;
  public facultyName!: string;
  public facultyShortName!: string;
}

export class LocalSignatoryCCAdd {
  public globalSignatoryId!: Guid | string;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public localSignatoryRole!: number;
}

export class LocalSignatoryCCUpdate {
  public id!: Guid | string;
  public globalSignatoryId!: Guid | string;
  public trainingLevels!: string[] | Guid[];
  public faculties!: string[] | Guid[];
  public localSignatoryRole!: number;
}

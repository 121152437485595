import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MassPrintSheetService } from '../../../services/middlecontrol/massPrintSheet.service';
import { GetSessionResultData } from '../../../models/middlecontrol/massPrintSheet.model';
import { SheetService } from '../../../services/middlecontrol/sheet.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import { SignatoriesModel } from 'src/app/models/middlecontrol/sheets.model';
import {TrainingLevelsModel} from "../../../models/middlecontrol/trainingLevels.model";
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {StatementSettings} from "../../../models/middlecontrol/statementSetting.model";
import {StatementSettingsService} from "../../../services/middlecontrol/statement-settings.service";
import {StatementSettingsEnum} from "../../../models/middlecontrol/enums/statement-setting.enum";

@Component({
  selector: 'app-middlecontrolprintsessionresult',
  templateUrl: './middlecontrolprintsessionresult.component.html',
  styleUrls: ['./middlecontrolprintsessionresult.component.scss'],
})
export class MiddlecontrolprintsessionresultComponent implements OnInit {
  public signer: SignatoriesModel = {
    fio: 'Фамилия Имя Отчество',
    postName: 'Должность',
  } as SignatoriesModel;
  public signerPost: string = 'Должность';
  public checkedStudents: string[] = [];
  public facultyId: string = '';
  public loadingAllSessions = false;
  public loadingLastSession = false;
  public showSigner: boolean = true;
  public statementSettingsEnum = StatementSettingsEnum;

  constructor(
    private location: Location,
    private massPrintSheetService: MassPrintSheetService,
    private sheetService: SheetService,
    private notificationService: NotificationsService,
    private statementSettingsService: StatementSettingsService,
  ) {}

  ngOnInit(): void {
    this.getStatementSettings();
  }

  onChangeSignerPost(post: string) {
    this.signer.postName = post;
  }

  public onPrintLastSessionResult() {
    const data = this.getData();
    if (data.students.length && data.signatory?.fio !== 'Фамилия Имя Отчество') {
      this.loadingLastSession = true;
      this.massPrintSheetService
        .getLastSessionResult(data)
        .subscribe((response) => {
          DownloadFile(response);
          this.loadingLastSession = false;
        });
    }
  }

  public onPrintAllSessionResult() {
    const data = this.getData();
    if (data.students.length && data.signatory?.fio !== 'Фамилия Имя Отчество') {
      this.loadingAllSessions = true;
      this.massPrintSheetService
        .getAllSessionResult(data)
        .subscribe((response) => {
          DownloadFile(response);
          this.loadingAllSessions = false;
        });
    }
  }

  private getData(): GetSessionResultData {
    const students = this.checkedStudents
      .filter((item) => item.split('_').length === 5)
      .map((item) => item.split('_').at(-1)!);

    if (!students.length)
      this.notificationService.showError('Ведомости не выбраны');

    const signer = {
      fio: this.signer.fio,
      postName: this.signer.postName,
      personId: this.signer.personId
    };

    if (signer.fio === 'Фамилия Имя Отчество' && this.showSigner) {
      this.notificationService.showError('Подписант не выбран');
    }

    const signatory = this.showSigner ? signer : undefined

    return { signatory, students };
  }

  public trainingLevelId?: string = ""

  public onChangeTrainingLevel(list: string[] | null) {
    if(list && list.length == 1)
      this.trainingLevelId = list[0]?.toString();
  }

  public onChangeCheckedStudents(list: string[]) {
    this.checkedStudents = list;
    if (list.length) this.facultyId = list[0].split('_')[0];
  }

  public onCancel(): void {
    this.location.back();
  }

  public getStatementSettings() {
    this.statementSettingsService.GetStatementSettings().subscribe(
      (response : StatementSettings) => {
        this.showSigner = response.statementSettings
          .find((item: any) => item.statementSettingsEnum === this.statementSettingsEnum.vybor_podpisanta_svodnaya_vedomost)?.value;
      }
    );
  }
}

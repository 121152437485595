import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {DictScienceDegree} from "../../../../../models/publications/dict/scienceDegree.model";
import {ScienceDegreesService} from "../../../../../services/science/publications/scienceDegrees.service";
import {dictPagerSettings} from "../../../../../models/dicts/pagerSettings.model";
import {getData$} from "../../../../../../environments/environment";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {PublicationsScienceDegreesMethods} from "./publications-science-degrees-methods.service";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";

@Component({
  selector: 'publications-science-degrees',
  templateUrl: './publications-science-degrees.component.html',
  styleUrls: ['./publications-science-degrees.component.scss']
})
export class PublicationsScienceDegreesComponent implements OnInit {

  public pagerSettings: PagerSettings = dictPagerSettings;
  public pageSize = 10;
  public loading = false;

  public scienceDegrees: DictScienceDegree[] = [];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;

  public formGroup: FormGroup | undefined;
  public editable: boolean = this.userAccessService.currentUserAccess$.value.dict === AccessRights.Write;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }
  constructor(
    private scienceDegreesService: ScienceDegreesService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private scienceDegreeMethods: PublicationsScienceDegreesMethods,
    private userAccessService: PublicationsUserAccessService
  ) { }

  async ngOnInit() {
    getData$.subscribe(() => this.getScienceDegrees())
  }

  private getScienceDegrees() {
    this.loading = true;
    this.scienceDegreesService.getScienceDegrees().subscribe({
      next: (response) => {
        this.scienceDegrees = response;
      },
      error: (error) => {
        this.notificationService.showError(error);
      }
    });
    this.loading = false;
  }

  private closeEditor(rowIndex: any = this.editedRowIndex): void {
    this.grid.closeRow(rowIndex);
    this.isNew = false;
    this.editedRowIndex = undefined;
  }

  public onCansel(): void {
    this.closeEditor();
  }

  public addRow() {
    this.formGroup = this.getFormGroup({
      name: "",
      shortName: ""
    });

    this.isNew = true;
    this.grid.addRow(this.formGroup);
  }

  public async onSave()  {
    if (this.formGroup) {
      await this.scienceDegreeMethods.saveScienceDegree(this.isNew, this.formGroup);
    }
    this.closeEditor();
  }

  private getFormGroup(dataItem: { id?: any; name?: any; shortName?: any}): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name, Validators.required),
      shortName: new FormControl(dataItem.shortName)
    });
  }

  public async onEdit({sender, rowIndex, dataItem, columnIndex}: CellClickEvent) {
    this.formGroup = this.getFormGroup({
      id: dataItem.id,
      name: dataItem.name,
      shortName: dataItem.shortName
    });

    if (this.isNew || !this.editable || (this.formGroup && this.formGroup.invalid)) {
      return;
    }

    this.closeEditor();
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public onRemove({dataItem}: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}"?`);
    this.scienceDegreeMethods.removeScienceDegree(dialog.result, dataItem);
  }
}

import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {
  AllStudentCardDisplaySetting,
} from "../../../models/contingent/studentcarddisplaysetting.model";
import {StudentcarddisplaysettingService} from "../../../services/contingent/studentcarddisplaysetting.service";
import {DictDisplaySettingCategory} from "../../../models/contingent/dictdisplaysettingcategory.model";
import {DictdisplaysettingcategoryService} from "../../../services/contingent/dictdisplaysettingcategory.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DisplaySettingEnum} from "../../../models/contingent/enums/display-setting.enum";
import {DisplaySettingCategoryEnum} from "../../../models/contingent/enums/display-setting-category.enum";
import {ContingentsettingService} from "../../../services/contingent/contingentsetting.service";
import {ContingentSettingModel} from "../../../models/contingent/contingentsetting.model";
import {ContingentSettingCategoryEnum} from "../../../models/contingent/enums/contingent-setting-category.enum";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-studentcardsettings',
  templateUrl: './studentcardsettings.component.html',
  styleUrls: ['./studentcardsettings.component.scss']
})
export class StudentcardsettingsComponent implements OnInit {

  constructor(private router: Router,
              private displaySettingService: StudentcarddisplaysettingService,
              private displaySettingCategoryService: DictdisplaysettingcategoryService,
              private notificationService: NotificationsService,
              private contingentSettingService: ContingentsettingService,
              )
  {}

  ngOnInit(): void {
    this.getDisplaySettings();
    this.getDisplaySettingCategory();
    this.getContingentSetting();
  }

  public displaySettings: AllStudentCardDisplaySetting[] = [];
  public displayCategory: DictDisplaySettingCategory[] = [];
  public contingentSetting: ContingentSettingModel[] = [];
  public enumCategory = DisplaySettingCategoryEnum;
  public settingCategory = ContingentSettingCategoryEnum;
  public enum = DisplaySettingEnum;
  public selected = 0;
  public tabItems = [
    { title: "Поля для отображения", },
    { title: "Настройки системы", },
  ];
  public loginGenerationFirst = environment.loginGenerationFirst;
  public loginGenerationSecond = environment.loginGenerationSecond;
  public studNumberGenerationFirst = environment.studNumberGenerationFirst;
  public studNumberGenerationSecond = environment.studNumberGenerationSecond;

  public getDisplaySettings() {
    this.displaySettingService.getAllStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettings = response;
        }
      );
  }

  public getDisplaySettingCategory() {
    this.displaySettingCategoryService.getDisplaySettingCategories()
      .subscribe(
        response => {
          this.displayCategory = response;
        }
      );
  }

  public getContingentSetting() {
    this.contingentSettingService.getContingentSettings()
      .subscribe(
        response => {
          this.contingentSetting = response;
        }
      );
  }

  public applyHandlerDisplaySetting(){
    this.displaySettingService.editStudentCardDisplaySetting(this.displaySettings)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.closeHandler();
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }});
  }

  public applyHandlerSystemSetting(){
    this.contingentSettingService.editContingentSetting(this.contingentSetting)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.closeHandler();
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }});
  }

  public closeHandler(){
    this.router.navigateByUrl(`/contingent`).then();
  };

  public valueChange(field: AllStudentCardDisplaySetting){
    if(field.type == this.enum.personaldata)
      this.displaySettings.filter(x => x.category === this.enumCategory.personal_tabs).map( item => {
        item.isDisplay = field.isDisplay
        return item;
      });

      this.displaySettings.filter(x => x.type === this.enum.personaldata).map( item => {
        item.isDisplay = this.displaySettings.find(x => x.category === this.enumCategory.personal_tabs && x.isDisplay) != undefined
        return item;
      });
  };

}

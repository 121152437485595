import {Component, OnInit, ViewChild, Renderer2} from "@angular/core";
import {pagerSettings} from 'src/app/models/diplomablanks/pagerSettings.model';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {DictBlankStatusesService} from '../../../../services/diplomablanks/dict-blankstatuses.service';
import {BlankStatusModel} from '../../../../models/diplomablanks/dicts.model';
import {DiplomablanksUserAccessService} from '../../../../services/useraccess/diplomablanks-user-access.service';
import {levelAccess} from '../../../../models/diplomablanks/enum/boolOptions.enum';
import {isChildOf} from 'src/app/helpers/elementRef-helper';

@Component({
    selector: 'dict-blankstatuses',
    templateUrl: './dict-blankstatuses.component.html',
    styleUrls: ['./dict-blankstatuses.component.scss']
  })

export class DictBlankStatusesComponent implements OnInit {

  public blankStatuses: BlankStatusModel[] = [];

  protected pageSize = 10;
  protected readonly pagerSettings = pagerSettings;
  public loading: boolean = false;

  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isLine = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined;
  }

  public editable: boolean = false;

  constructor(
    private notificationService: NotificationsService,
    private dictBlankStatusesService: DictBlankStatusesService,
    private renderer: Renderer2,
    private diplomablanksUserAccessService: DiplomablanksUserAccessService,
  ) { }

  public ngOnInit(): void {
    this.getBlankStatuses();
    this.getUserAccess();

    // this.renderer.listen("document", "click", ({ target }) => {
    //   console.log(target)
    //   if (!isChildOf(target, "k-grid") && !target.outerHTML.includes("k-hsv-draghandle")) {
    //     this.saveRow();
    //   }
    // });
  }

  public getUserAccess() {
    this.diplomablanksUserAccessService.getUserAccess()
    .subscribe(response => {
      this.editable = response.dict == levelAccess.write;
    })
  }

  public getBlankStatuses() {
    this.loading = true;
    this.dictBlankStatusesService.getBlankStatuses()
      .subscribe(response => {
        if (response) this.blankStatuses = response;
        this.loading = false;
      })
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isLine = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public editRow({sender, rowIndex, dataItem}: CellClickEvent): void {
    if (!this.editable) return;

    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveRow();
      return;
    }

    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public saveRow() {
    if (!this.formGroup || this.formGroup && !this.formGroup.valid) {
        return;
    }

    this.dictBlankStatusesService.updateBlankStatuses(this.formGroup?.value).subscribe({
      next: () => {
          this.notificationService.showSuccess('Сохранено');
          this.getBlankStatuses();
        },
      error: () => this.notificationService.showError('Произошла ошибка'),
    });
      this.onCancel();
  }

}

const formGroup = (dataItem: BlankStatusModel) =>
  new FormGroup({
    externalId: new FormControl(dataItem.externalId),
    name: new FormControl(dataItem.name, Validators.required),
    order: new FormControl(dataItem.order, Validators.required),
    color: new FormControl(dataItem.color, Validators.required),
    enum: new FormControl(dataItem.enum),
  });

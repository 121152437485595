import {Injectable} from "@angular/core";
import {LocalSignatory} from "../../models/mfc/localSignatory/localSignatory.model";

@Injectable({
  providedIn: 'root'
})

export class LocalSignatoryService {
  getLocalSignatoryData() {
    const data: LocalSignatory[] = [];
    for (let i = 1; i <= 50; i++) {
      const randomNum = Math.floor(Math.random() * 10) + 1;
      data.push({
        id: '',
        categories: [`Категория ${randomNum}`],
        applicationTypes: [`Тип ${randomNum}`],
        filial: [`Филиал ${randomNum}`],
        roles: [`Роль ${randomNum}`],
        trainingLevels: [`Уровень подготовки ${randomNum}`],
        faculties: [`Факультет ${randomNum}`],
        document: [`Документ ${randomNum}`]
      });
    }
    return data;
  }
}
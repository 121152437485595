import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LanguageLevels } from '../../models/contingent/languagelevels.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {
  MassOperationInfo,
  MassOperationJournal, MassOperationJRequest,
  MassOperationStudents
} from "../../models/contingent/massoperationjournal.model";
import {CaseName} from "../../models/contingent/casename.model";

@Injectable({
  providedIn: 'root'
})
export class MassoperationjournalService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.massoperationjournal}`;

  constructor(private http: HttpClient) { }

  //Get all MassOperationJournal
  public getAllMassOperationJournal(request: MassOperationJRequest): Observable<MassOperationJournal[]> {
    return this.http.post<MassOperationJournal[]>(this.baseUrl + '/GetAllMassOperationJournal', request)
      .pipe(catchError(this.handleError));
  }

  //Add MassOperationJournal
  addMassOperationJournal(dto: MassOperationJournal): Observable<MassOperationJournal> {
    return this.http.post<MassOperationJournal>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  getByIdMassOperation(id: string): Observable<MassOperationInfo> {
    return this.http.get<MassOperationInfo>(this.baseUrl + '/GetMassOperationJournalById/' + id)
      .pipe(catchError(this.handleError));
  }

  getMassOperationStudents(id: string): Observable<MassOperationStudents[]> {
    return this.http.get<MassOperationStudents[]>(this.baseUrl + '/GetMassOperationStudents/' + id)
      .pipe(catchError(this.handleError));
  }

  //Delete MassOperationJournal
  deleteMassOperationJournal(id: string): Observable<MassOperationJournal> {
    return this.http.delete<MassOperationJournal>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit MassOperationJournal
  updateMassOperationJournal(dto: MassOperationJournal): Observable<MassOperationJournal> {
    return this.http.put<MassOperationJournal>(this.baseUrl + '/' + dto.massOperationJournalExternalId, dto)
      .pipe(catchError(this.handleError));
  }

  //Save order file for all students in MassOperationJournal
  saveOrderFile(file: FormData, id: string) {
    return this.http.post(this.baseUrl + '/SaveOrderFile/' + id, file)
      .pipe(catchError(this.handleError));
  }

  //Delete order file
  deleteOrderFile(id: string) {
    return this.http.delete(this.baseUrl + '/DeleteOrderFile/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}


<gia-settings></gia-settings>
<h1>Приказ о выпуске обучающихся</h1>

<div class="selectContent w-850">
  <form [formGroup]="releaseOrderForm" class="selectContent w-850">
    <dd class="list">
      <span class="selectText">
        Номер и дата приказа
      </span>
      <div class="list w-600">
        <span class="selectText">
          №
        </span>
        <kendo-textbox [disabled]="!editable" class="m-l15"
                       formControlName="orderNumber">
        </kendo-textbox>
        <span *ngIf="editable" class="required m-l10">*</span>

        <span class="selectText m-l30">
          Дата
        </span>
        <kendo-datepicker [disabled]="!editable" class="m-l15"
                          formControlName="orderDate">
        </kendo-datepicker>
      </div>
      <span *ngIf="editable" class="required m-l10">*</span>
    </dd>

  </form>
</div>

<div class="form-buttons">
  <div>
    <button kendoButton size="small" (click)="back()">Отмена</button>
    <button kendoButton class="save-button" size="small" themeColor="primary" [disabled]="releaseOrderForm.invalid" *ngIf="editable" (click)="save()">
      Сохранить
    </button>
  </div>
  <button kendoButton size="small" themeColor="primary" icon="print" [disabled]="releaseOrderForm.invalid" *ngIf="editable" (click)="save(true)">
    Сохранить и распечатать
  </button>
</div>

<div class="form-buttons" *ngIf="!isNew && editable">
  <div></div>
  <button kendoButton size="small" themeColor="success" icon="plus" (click)="addStudent()">
    Добавить обучающихся
  </button>
</div>

<h1 class="f-s17">Список обучающихся</h1>

<kendo-grid
  class="m-t15"
  (remove)="removeHandler($event)"
  [kendoGridBinding]="students"
  [loading]="loading"
  [sortable]="true"
  [pageable]="pagerSettings"
  [pageSize]="pageSize"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="number" title="№ п/п" [width]="100" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span class="alignCells">{{ isNew ? (rowIndex + 1) : dataItem.assignedNumber }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="studentFio" format="dd.MM.yyyy" title="ФИО обучающегося" headerClass="gridHeader" [width]="300">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells leftAlignment">{{ dataItem.studentFio }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="genitiveCaseFullName" title="ФИО в родит. падеже" [width]="250" *ngIf="checkIncludeTrainingLevelInGiaSetting(trainingLevelEnum!)" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <kendo-textbox
        size="small"
        [disabled]="!editable"
        [(ngModel)]="dataItem.genitiveCaseFullName"
        required
      ></kendo-textbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="trainingLevelEnum !== TrainingLevelEnum.Graduate && trainingLevelEnum !== TrainingLevelEnum.Residency" field="groupName" title="Академическая группа" [width]="300" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.groupName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="standardName" title="Специальность" [width]="300" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.standardName }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="diplomaType" title="Тип диплома" [width]="300" headerClass="gridHeader dictionaries">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">{{ dataItem.diplomaType }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column
    *ngIf="editable"
    title="" [width]="50">
    <ng-template kendoGridCellTemplate>
      <button kendoGridRemoveCommand
              icon="delete"
              fillMode="flat"
              size="small"
              rounded="full">
      </button>
    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>

<div class="form-buttons">
  <div>
    <button kendoButton size="small" (click)="back()">Отмена</button>
    <button kendoButton class="save-button" size="small" themeColor="primary" [disabled]="releaseOrderForm.invalid" *ngIf="editable" (click)="save()">
      Сохранить
    </button>
  </div>
  <button kendoButton size="small" themeColor="primary" icon="print" [disabled]="releaseOrderForm.invalid" *ngIf="editable" (click)="save(true)">
    Сохранить и распечатать
  </button>
</div>

<div kendoDialogContainer></div>

import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {DictPersonStatus} from "../../models/dicts/person-status.model";

@Injectable({
  providedIn: 'root'
})
export class DictPersonStatusService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.personStatus}`;

  constructor(private http: HttpClient) {
  }

  public get(): Observable<DictPersonStatus[]> {
    return this.http.get<DictPersonStatus[]>(this.baseUrl);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

<div class="signer">
  <div class="signer__info" *ngIf="!isEditSigner">
    <p class="signer__text">Подписант</p>
    <p class="signer__text">{{ signer.fio === defaultFIO ? "не указан" :signer.fio + ", " + signer.postName }}</p>
    <button
      kendoButton
      *ngIf="editable"
      (click)="toggleEdit()"
      icon="edit"
      fillMode="outline"
      class="button"
    >
      Редактировать
    </button>
  </div>
  <div *ngIf="isEditSigner" class="signer__edit">
    <app-middlecontrolsigneredit
      [(signer)]="signer"
      [personId]="signer.personId!.toString()"
      [facultyId]="facultyId"
      [trainingLevelId]="trainingLevelId"
      [eduGroupId]="eduGroupId"
      [semesterNum]="semesterNum"
      [disciplineId]="disciplineId"
      [electiveDisciplineId]="electiveDisciplineId"
      (signerChange)="onChangeSigner($event)"
      (signerPostChange)="onChangeSignerPost($event)"
      class="signer__edit-wrapper"
    ></app-middlecontrolsigneredit>
    <button kendoButton (click)="toggleEdit()" class="button-close">
      Закрыть
    </button>
  </div>
</div>

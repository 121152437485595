import { Component, OnInit } from '@angular/core';
import { ParametersPersonService } from 'src/app/services/persondepartment/parameters-person.service';
import { ActivatedRoute} from '@angular/router';
import {AlignSettings} from '@progress/kendo-angular-layout';
import {environment} from "../../../../environments/environment";
import {DateToString} from "../../../helpers/date-helper";
import { PersonCardService } from "src/app/services/persondepartment/person-card.service";
import {saveAs} from "@progress/kendo-file-saver";

@Component({
    selector   : 'app-person-card',
    templateUrl: './person-card.component.html',
    styleUrls  : ['./person-card.component.scss']
})

export class PersonCardComponent implements OnInit{

    public personsData: any = [];

    public fullName: string = "";
    public buhCode: string = "";
    public photoUrl = '';
    public phoneNumbers:any = [];
    public emails: any = [];
    public personSalaries: any = [];
    public personScientificDegrees: any = [];
    public experiences: any = [];
    public educations: any = [];
    public personTraineeships: any = [];
    public academicStatuses: any = [];
    public achievements: any = [];
    public personName: any = [];
    public assistents: any = [];
    public socialNet: any = [];
    public contacts: any = [];
    public personLanguages: any = [];
    public personSocialMedias: any = [];

    public align: AlignSettings = {vertical: "top"};

    public mask: any = {
        mask: '+{7} (000) 000-00-00',
        lazy: false
    };

    public supportEmail = environment.supportEmail;

    yearConversion: any = {
        1: 'год',  2: 'года',  3: 'года',  4: 'года',
       21: 'год', 22: 'года', 23: 'года', 24: 'года',
       31: 'год', 32: 'года', 33: 'года', 34: 'года',
       41: 'год', 42: 'года', 43: 'года', 44: 'года',
       51: 'год', 52: 'года', 53: 'года', 54: 'года',  };
    monthConversion: any = {1: 'месяц', 2: 'месяца', 3: 'месяца', 4: 'месяца', 5: 'месяцев', 6: 'месяцев', 7: 'месяцев', 8: 'месяцев', 9: 'месяцев',
      10: 'месяцев', 11: 'месяцев', 12: 'месяцев'};

    dayConversion: any = {
        1: 'день',  2: 'дня',  3: 'дня',  4: 'дня',
        21: 'день', 22: 'дня', 23: 'дня', 24: 'дня',
    }

    public skip = 0;
    public qualificationsPageSize = 5;
    public allQualifications = false;
    public allEducations = false;
    public educationsPageSize = 5;
    public allAchievements = false;
    public achievementsPageSize = 5;

    public qualificationsGridView: any = [];
    public educationGridView: any = [];
    public achievementsGridView: any = [];

    public addinformation: boolean = false;
    public methodAdd: boolean = false;

    public id: string = "";

constructor(
    private parametersPersonService: ParametersPersonService,
    private activatedroute: ActivatedRoute,
    private personCardService: PersonCardService,
){}

ngOnInit() {
    this.getParametersPerson(this.activatedroute.snapshot.params['id']);
}
    public formatSalaryInfo(item: any) {
      const postDepartment = `${item.post}, ${item.department}`;
      const employmentDate = DateToString(item.employmentDate, 'dd.MM.yyyy')
      const contractTerm = DateToString(item.contractTerm, 'dd.MM.yyyy')
      let dateStr = "";
      if (item.employmentDate != null)
        dateStr+=`c ${employmentDate}`
      if (item.contractTerm != null)
        dateStr+=` по ${contractTerm}`

      if(dateStr.trim() != "")
        dateStr = " (" + dateStr.trim() + ")"

      return postDepartment + dateStr;
    }
    public getParametersPerson(id: any){
        this.parametersPersonService.getParametersPerson(id)
        .subscribe(response =>{
            this.personsData = response;

            this.id = this.personsData.externalId;
          //  console.log(this.personsData);
            if (this.personsData.personSalaries[0].recordSource == 0) this.methodAdd = true;
            this.photoUrl = `url('${environment.apiEndpoint}lkperson/profile/GetPersonPhoto/${this.personsData.externalId}')`;

            this.fullName = this.personsData.personName.lastName + ' ' + this.personsData.personName.firstName + ' ' + this.personsData.personName.middleName;
            this.phoneNumbers = this.personsData.personPhones;
            this.emails = this.personsData.emails;
            this.personSalaries = this.personsData.personSalaries;
            this.buhCode = this.personSalaries[0].buhCode.buhCode;
            this.personScientificDegrees = this.personsData.personScientificDegrees;
            this.experiences = this.personsData.personExperiences;
            this.educations = this.personsData.educations;

            if(this.educations.length < this.educationsPageSize) {
                this.allEducations = true;
            }
            this.educationGridView = {
                data: this.educations.slice(this.skip, this.skip + this.educationsPageSize),
                total: this.educations.length,
            }

            this.personTraineeships = this.personsData.personTraineeships;
            if(this.personTraineeships.length < this.qualificationsPageSize) {
                this.allQualifications = true;
            }
            this.qualificationsGridView = {
                data: this.personTraineeships.slice(this.skip, this.skip + this.qualificationsPageSize),
                total: this.personTraineeships.length,
            };

            this.academicStatuses = this.personsData.academicStatuses;

            this.achievements = this.personsData.achievements;
            if(this.achievements.length < this.achievementsPageSize) {
                this.allAchievements = true;
            }
            this.achievementsGridView = {
                data: this.achievements.slice(this.skip, this.skip + this.achievementsPageSize),
                total: this.achievements.length,
            };

            this.personName = [this.personsData.personName];
            this.contacts = this.personsData.contacts;
            this.personLanguages = this.personsData.personLanguages;
            this.personSocialMedias = this.personsData.personSocialMedias;
            this.assistents = this.personsData.personAssistants;

        })
    }

    phoneFormat(input:any) {
        if(input.startsWith(9))
        {
        input = "+7" + input;
        }
        if(input.startsWith(8))
        {
        input = input.substring(1);
        input = "+7" + input;
        }
        return input;
    }

    groupQualificationPeriod(periodBegin: any, periodEnd: any) {
        let str = '';
        if (periodBegin != null)
            str += DateToString(periodBegin) + ' - ';
        else {
            str += 'н/у' + ' - '
        }
        if(periodEnd)
            str += DateToString(periodEnd);
        else {
            str += 'н/у'
        }
        return str;
    }

    groupQualificationDocument(docName: any, docSerial: any, docNumber: any, docDate: any) {
        let str = '';
        if(docName != null)
            str += docName;
        if(docSerial !== '')
            str += ", cерия " + docSerial;
        if(docNumber !== '')
            str += ", номер " + docNumber;
        if(docDate !== '')
            str += ", дата выдачи " + DateToString(docDate);
        return str;
    }

    groupDiplom(diplom: any, date: any, shifr: any) {
        let str = "";
        if (diplom != null) str += diplom;
        str += ", выдан " +  DateToString(date);
        if (shifr != "-") str += ", шифр " + shifr;
        return str;
    }

    showAllQualificationData() {
        this.allQualifications = true;
        this.qualificationsGridView = {
          data: this.personTraineeships,
          total: this.personTraineeships.length,
        };
    }

    showAllEducationData() {
        this.allEducations = true;
        this.educationGridView = {
          data: this.educations,
          total: this.educations.length
        }
    }

    showAllAchievementsData(){
        this.allAchievements = true;
        this.achievementsGridView = {
          data: this.achievements,
          total: this.achievements.length
        }
    }

    public hide(){
        this.addinformation = false;
    }

    public display(){
        this.addinformation = true;
    }

    Status(value:any){
        let status = "";
        if (value == 1) status="Действует";
        if (value == 2) status="Удален";
        if (value == 3) status="Неактивен";
        return status;
    }

    public loadFile(externalId: string, value: any){
        let fileName = value.slice(value.lastIndexOf('/') + 1);
        let fileType = value.slice(value.lastIndexOf('.') + 1);

        this.personCardService.downloadFile(externalId)
          .subscribe(
            response => {
              let blob:any = new Blob([response], { type: fileType });
              saveAs(blob, fileName);
            }
        );
    }

    public loadAchievementsFile(externalId: string, value: any){
        let fileName = value.slice(value.lastIndexOf('/') + 1);
        let fileType = value.slice(value.lastIndexOf('.') + 1);

        this.personCardService.downloadAchievementsFile(externalId)
          .subscribe(
            response => {
              let blob:any = new Blob([response], { type: fileType});
              saveAs(blob, fileName);
            }
        );
    }

}

import {Injectable} from '@angular/core';
import {catchError, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {handleError} from '../../../helpers/errorHandle-helper';
import {map} from 'rxjs/operators';
import {WorkProfile} from "../../../models/mfc/dicts/work-profile.model";


@Injectable({
  providedIn: 'root'
})
export class WorkProfileService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/WorkProfile/`;

  constructor(private http: HttpClient) { }

  public getWorkProfiles(): Observable<WorkProfile[]> {
    return this.http.get<WorkProfile[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }

  public addWorkProfile(category: WorkProfile): Observable<WorkProfile> {
    return this.http.post<WorkProfile>(this.baseUrl, category)
      .pipe(catchError(handleError));
  }

  public updateWorkProfile(category: WorkProfile): Observable<WorkProfile> {
    return this.http.put<WorkProfile>(this.baseUrl, category)
      .pipe(catchError(handleError));
  }

  public removeWorkProfile(id: string): Observable<WorkProfile> {
    return this.http.delete<WorkProfile>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

<kendo-stacklayout [gap]="20" orientation="vertical" class="filters">
  <h2 class="group">Группа {{ group.text }}</h2>
  <kendo-dropdownlist
    class="params__input"
    [data]="semesterList"
    [value]="selectedSemester"
    textField="semesterName"
    valueField="semesterNum"
    (valueChange)="onChangeSelectedSemester($event)"
    [disabled]="+currentDate !== +contingentDate"
  >
  </kendo-dropdownlist>
  <span class="filters__print_list--label"
    >Отображать сводную ведомость за:</span
  >
  <app-togglestep
    [toggleStep]="weekFilter"
    (toggleStepChange)="onChangeWeekFilter($event)"
  ></app-togglestep>
  <kendo-stacklayout
    orientation="vertical"
    [gap]="20"
    class="filters__print_list"
  >
    <span class="filters__print_list--label">Печать сводной ведомости:</span>

<!--    <app-currentcontrolsignerparams
      [signer]="signer"
      [editable]="editable"
      [eduGroupId]="eduGroupId"
      [semesterNum]="selectedSemester.semesterNum"
      [facultyId]="groupInfo.facultyId"
    ></app-currentcontrolsignerparams>-->

    <div class="filters__print_list__buttons">
      <button
        kendoButton
        size="small"
        (click)="onPrintEmptyList()"
        style="position: relative; float: right"
        class="no-margin filters__print_list__buttons--item"
      >
        пустая
        <kendo-loader *ngIf="loadingEmptyList" size="small"></kendo-loader>
      </button>
      <button
        kendoButton
        size="small"
        (click)="onPrintFilledList(0)"
        style="position: relative; float: right"
        class="no-margin filters__print_list__buttons--item"
      >
        заполненная
        <kendo-loader *ngIf="loadingFilledList" size="small"></kendo-loader>
      </button>
<!--      <button-->
<!--        kendoButton-->
<!--        size="small"-->
<!--        (click)="onPrintFilledList(2)"-->
<!--        style="position: relative; float: right"-->
<!--        class="no-margin filters__print_list__buttons&#45;&#45;item"-->
<!--      >-->
<!--        только успеваемость-->
<!--        <kendo-loader *ngIf="loadingGradeOnly" size="small"></kendo-loader>-->
<!--      </button>-->
<!--      <button-->
<!--        kendoButton-->
<!--        size="small"-->
<!--        (click)="onPrintFilledList(3)"-->
<!--        style="position: relative; float: right"-->
<!--        class="no-margin filters__print_list__buttons&#45;&#45;item"-->
<!--      >-->
<!--        только посещаемость-->
<!--        <kendo-loader *ngIf="loadingAttendanceOnly" size="small"></kendo-loader>-->
<!--      </button>-->
<!--      <button-->
<!--        kendoButton-->
<!--        size="small"-->
<!--        (click)="onPrintFilledList(1)"-->
<!--        style="position: relative; float: right"-->
<!--        class="no-margin filters__print_list__buttons&#45;&#45;item"-->
<!--      >-->
<!--        успеваемость и посещаемость-->
<!--        <kendo-loader *ngIf="loadingFullList" size="small"></kendo-loader>-->
<!--      </button>-->
    </div>
  </kendo-stacklayout>
</kendo-stacklayout>

<contingent-header></contingent-header>
<h1>Смена учебного плана</h1>

<div class="plan-container">

  <dl class="stud-plan-dl plan-width">
    <kendo-label class="plan-label" text="Учебный план: "></kendo-label>
    <dd>
      <kendo-combobox [data]="educationPlanList"
                      class="progress-combobox"
                      textField="name"
                      valueField="planId"
                      [valuePrimitive]="true"
                      #plan
                      [openOnFocus]="plan"
                      [kendoDropDownFilter]="filterSettings"
                      (valueChange)="planValueChange($event)"
                      placeholder="Учебный план"
                      [(ngModel)]="planId"
                      required>
      </kendo-combobox>
    </dd>
  </dl>

  <div *ngIf="educationPlan !== undefined">
    <h2 class="plan-detail-label">{{"Направление подготовки: " + (educationPlan.educationProgram?.educationStandard?.name ? educationPlan.educationProgram?.educationStandard?.name : "")}}</h2>
    <h2 class="plan-detail-label">{{"Профиль (программа): " + (educationPlan.educationProgram?.name ? educationPlan.educationProgram?.name : "") + (educationPlan.educationProgram?.facultyName ? " (" + educationPlan.educationProgram?.facultyName + ")" : "")}}</h2>
    <h2 class="plan-detail-label">{{"Форма обучения: " + (educationPlan.studyFormName ? educationPlan.studyFormName : "")}}</h2>
    <h2 class="plan-detail-label last">{{"Год поступления: " + (educationPlan.yearAdmission ? educationPlan.yearAdmission : "")}}</h2>
  </div>

  <kendo-label class="plan-label-blue" text="Примечание:"></kendo-label><br>
  <kendo-label class="plan-label-blue" text="Список доступных учебных планов соответствует направлению подготовки студента"></kendo-label>

  <div class="plan-buttons plan-width">
    <button kendoButton size="small" themeColor="primary" class="interval right" [disabled]="planId === undefined" (click)="onSave(itemListRef)">
      Сохранить
    </button>
    <button kendoButton class="right" size="small" (click)="close()">
      Отмена
    </button>
  </div>
</div>

<ng-template #itemListRef>
  <p class="dialog-text">Вы действительно хотите сменить учебный план студента?</p>
  <p class="dialog-text red">Выбранный учебный план является планом ускоренного обучения!</p>
</ng-template>

<div kendoDialogContainer></div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MilitaryDocTypes } from '../../models/contingent/militarydoctypes.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MilitarydoctypesService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.militarydoctypes}`;

  constructor(private http: HttpClient) { }

  //Get all MilitaryDocTypes
  public getAllMilitaryDocTypes(): Observable<MilitaryDocTypes[]> {
    return this.http.get<MilitaryDocTypes[]>(this.baseUrl);
  }

  //Add MilitaryDocTypes
  addMilitaryDocTypes(militarydoctypes: MilitaryDocTypes): Observable<MilitaryDocTypes> {
    return this.http.post<MilitaryDocTypes>(this.baseUrl, militarydoctypes)
      .pipe(catchError(this.handleError));
  }

  //Delete MilitaryDocTypes
  deleteMilitaryDocTypes(id: string): Observable<MilitaryDocTypes> {
    return this.http.delete<MilitaryDocTypes>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit MilitaryDocTypes
  updateMilitaryDocTypes(militarydoctypes: MilitaryDocTypes): Observable<MilitaryDocTypes> {
    return this.http
      .put<MilitaryDocTypes>(this.baseUrl + '/' + militarydoctypes.dictMilitaryDocTypeExternalId, militarydoctypes)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
      return throwError(error);
  }
}

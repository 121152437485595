import {Injectable} from "@angular/core";

declare var document: any;

@Injectable()
export class ReportDesignerScriptService {

  private scripts: any = {};

  constructor() {
  }

  async scriptPrep(baseUrl: string) {
    ReportDesignerScriptStore.forEach((script: ReportDesignerScript) => {
      this.scripts[script.name] = {
        loaded: false,
        src: (script.fullPath ? "" : baseUrl) + script.src,
      };
    });
  }

  async load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({script: name, loaded: true, status: 'Already Loaded'});
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({script: name, loaded: true, status: 'Loaded'});
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}

interface ReportDesignerScript {
  name: string;
  src: string;
  fullPath: boolean
}
export const ReportDesignerScriptStore: ReportDesignerScript[] = [
  {name: 'jquery-3.3.1', src: 'assets/js/jquery-3.3.1.min.js', fullPath: true},
  {name: 'kendo-all', src: 'assets/js/kendo.all.min.js', fullPath: true},
  {name: 'telerikReportViewer', src: '/resources/js/telerikReportViewer/', fullPath: false},
  {name: 'webReportDesigner', src: '/designerresources/js/webReportDesigner/', fullPath: false}
];

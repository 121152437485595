import { Component, OnInit } from '@angular/core';
import {EducationPlanDetailsService} from "../../../../services/education/EducationPlan/education-plan-details.service";
import {ActivatedRoute} from "@angular/router";
import {OopService} from "../../../../services/education/OOP/oop.service";
import {lastValueFrom} from "rxjs";
import {OOProgram} from "../../../../models/education/oop.model";
import {Location} from "@angular/common";
import {EducationPlanService} from "../../../../services/disciplineworkload/externals/education-plan.service";
import {CellClickEvent} from "@progress/kendo-angular-grid";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../helpers/dialogHelper";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {EducationPlanReattachmentService} from "./education-plan-reattachment.service";
import {ReattachPlanDataSources} from "../../../../models/education/education-plan-details-model";

@Component({
  selector: 'education-plan-reattachment',
  templateUrl: './education-plan-reattachment.component.html',
  styleUrls: ['./education-plan-reattachment.component.scss']
})
export class EducationPlanReattachmentComponent implements OnInit {

    public eduProgramId: string = '';
    public educationPlanId?: string;

    public gridData: OOProgram[] = [];
    public dataSources: ReattachPlanDataSources = new ReattachPlanDataSources();

    constructor(
        public educationPlanDetailsService: EducationPlanDetailsService,
        private educationPlanService: EducationPlanService,
        private educationProgramService: OopService,
        private dialogService: DialogService,
        private notificationService: NotificationsService,
        private reattachmentService: EducationPlanReattachmentService,
        private location: Location,
        public activateRoute: ActivatedRoute) {
        activateRoute.params.subscribe(params => {
            this.eduProgramId = params['education_program_id'] || this.educationPlanDetailsService.currentEducationProgram.educationProgramId;
            this.educationPlanId = params['destination_plan_id'];
        });
    }

    async ngOnInit() {
        await this.getPlanDetails();
        await this.getReattachmentEducationPrograms(this.educationPlanDetailsService.currentEducationStandard.educationStandardId);
    }

    private async getPlanDetails() {
        this.eduProgramId && await lastValueFrom(this.educationPlanDetailsService.getDetails(this.eduProgramId));
        //this.educationPlanId && this.educationPlanDetailsService.getPlanInfo(this.educationPlanId).subscribe();
    }

    public onReattachmentCancel() {
        this.location.back();
    }

    public showReattachmentConfirmation({dataItem}: CellClickEvent): void {
        const dialog: DialogRef = openDialog(this.dialogService, `Перезакрепить "${dataItem.oopNameAndCipher}?"`);
        this.reattachmentService.reattachPlan(dialog.result, dataItem, this.educationPlanId!);
    }

    private async getReattachmentEducationPrograms(id: string) {
        await lastValueFrom(this.educationProgramService.getEducationProgramsByStandardId(id)).then(value => {
            this.gridData = value;
            this.dataSources = this.reattachmentService.mapDataSources(value);
        })
    }
}
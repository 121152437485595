import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CellDataType,
  CellDayType,
} from 'src/app/models/education/education-plan-schedule.model';
import { environment } from 'src/environments/environment';
import {dictWorkCategoryScheduleEnum} from "../../../models/education/dictWorkCategorySchedule.enum";

@Component({
  selector: 'educational-process-schedule-cell',
  templateUrl: './educational-process-schedule-cell.component.html',
  styleUrls: ['./educational-process-schedule-cell.component.scss'],
})
export class EducationalProcessScheduleCellComponent implements OnInit {
  @Input() weekInfo: CellDataType = {
    semestrBeginWeekNumber: [],
    semesterBeginDayNumber: 0,
    installationSessionBeginDayNumber: 0,
    installationSessionEndDayNumber: 0,
    days: [],
    weekNumber: 0,
  };
  @Input() currentWeekNumber: number = 0;
  @Input() course: number = 0;
  @Input() isChoosedCells: boolean = false;
  @Input() isDisplayDot: boolean = false;
  @Input() isBlockedAction: boolean = false;
  @Output()
  cellOpenMenu: EventEmitter<{
    anchorPopup: ElementRef | null;
    cell: EducationalProcessScheduleCellComponent;
  }> = new EventEmitter<{
    anchorPopup: ElementRef | null;
    cell: EducationalProcessScheduleCellComponent;
  }>();
  @Output()
  selectedCell: EventEmitter<EducationalProcessScheduleCellComponent> =
    new EventEmitter<EducationalProcessScheduleCellComponent>();
  @Output()
  startDrag: EventEmitter<{
    x: number;
    y: number;
    course: number;
    currentWeekNumber: number;
  }> = new EventEmitter<{
    x: number;
    y: number;
    course: number;
    currentWeekNumber: number;
  }>();

  public holidaysEnum: any = dictWorkCategoryScheduleEnum;
  public anchorPopup?: ElementRef;

  days: CellDayType[] = this.getDefaultDays(this.weekInfo.days[0] || {});

  isWholeWeek: boolean = true;
  isSelectedCell: boolean = false;
  isSemesterBegin: boolean = false;
  isInstallationSession: boolean = false;
  isInstallationSessionBegin: boolean = false;
  isInstallationSessionEnd: boolean = false;
  currentDay: number = 0;

  constructor() {}

  ngOnInit(): void {
    if (this.weekInfo === undefined) {
      this.weekInfo = {
        semestrBeginWeekNumber: [],
        semesterBeginDayNumber: 0,
        installationSessionBeginDayNumber: 0,
        installationSessionEndDayNumber: 0,
        days: [
          {
            daysAmount: environment.daysInWeek,
            symbol: '',
            backgroundColor: '#ffffff',
          } as CellDayType,
        ],
        weekNumber: 0,
      };
    }

    this.isSemesterBegin = !!this.weekInfo.semestrBeginWeekNumber?.includes(
      this.currentWeekNumber
    );

    this.isInstallationSession = this.weekInfo.isInstallationSession ? this.weekInfo.isInstallationSession: false
    this.isInstallationSessionBegin = this.weekInfo.installationSessionBeginWeekNumber === this.currentWeekNumber;
    this.isInstallationSessionEnd = this.weekInfo.installationSessionEndWeekNumber === this.currentWeekNumber;

    if (this.weekInfo.days.length > 1) {
      this.isWholeWeek = false;
      let fillStart = 0;
      this.weekInfo.days.forEach((dayInfo) => {
        this.days.fill(
          { ...dayInfo, daysAmount: 1 },
          fillStart,
          fillStart + dayInfo.daysAmount
        );
        fillStart += dayInfo.daysAmount;
      });
    }

    if (this.isWholeWeek)
      this.days = this.getDefaultDays(this.weekInfo.days[0] || {});
  }

  public openMenu(event: MouseEvent, day: number) {
    event.preventDefault();
    this.currentDay = day;
    if (event.target)
      this.anchorPopup = event.target as unknown as ElementRef<any>;
    if (this.anchorPopup)
      this.cellOpenMenu.emit({ anchorPopup: this.anchorPopup, cell: this });
  }

  public selectCell(day?: number) {
    if (!this.isBlockedAction) {
      if (this.weekInfo.days[0].daysAmount !== 6 && day == 0){
        this.currentDay = 0;
        this.selectedCell.emit(this);
      }
      else {
        this.currentDay = day ? day : 7;
        this.selectedCell.emit(this);
      }
    }
  }

  public selectCells() {
    if (this.isChoosedCells && !this.isSelectedCell) this.selectCell();
  }

  public getDefaultDays(cell?: CellDayType) {
    return Array(environment.daysInWeek).fill({
      ...cell,
      daysAmount: 1,
      symbol: cell?.symbol || '',
      backgroundColor: cell?.backgroundColor || '#ffffff',
    });
  }

  public startDragFunction(event: MouseEvent) {
    this.startDrag.emit({
      x: event.pageX,
      y: event.pageY,
      currentWeekNumber: this.currentWeekNumber,
      course: this.course,
    });
  }
}

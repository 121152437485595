import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApplicationConstructorTabsEnum} from '../../models/mfc/enums/application-constructor-tabs.enum';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConstructorEditFormService {

  private hasChanges: boolean = false;
  public checkChanges$ = new BehaviorSubject<ApplicationConstructorTabsEnum|null>(null);

  public setHasChanges(value: boolean = false) {
    this.hasChanges = value;
  }

  public hasFormChanges(tab: ApplicationConstructorTabsEnum) {
    this.checkChanges$.next(tab);
    return this.hasChanges;
  }
}

import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ComboBoxComponent, DropDownFilterSettings} from '@progress/kendo-angular-dropdowns';
import {AddEvent, CancelEvent, CellClickEvent, GridComponent} from '@progress/kendo-angular-grid';
import { groupBy, GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import {AssignmentsService} from "../../../../services/disciplineworkload/assignments.service";
import {HttpParams} from "@angular/common/http";
import {DepartmentService} from "../../../../services/disciplineworkload/externals/department.service";
import {
  AvailableSemesters,
  DictAdditionalWork,
  DictStudyForm,
  DictTrainingLevelGroup,
  EduGroup,
  Faculty,
  KafedraGet
} from "../../../../models/disciplineworkload/externals.model";
import { AssignmentDepartmentGet, MassOperationAssignmentAdd } from "../../../../models/disciplineworkload/assignements.model";
import {DictService} from "../../../../services/disciplineworkload/externals/dict.service";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {saveAs} from "@progress/kendo-file-saver";
import {EduGroupService} from "../../../../services/disciplineworkload/externals/edu-group.service";
import {DisciplineAssignmentComponent} from "../disciplineAssignment/disciplineAssignment.component";
import {EducationPlanService} from "../../../../services/disciplineworkload/externals/education-plan.service";
import {AdditionalWorkService} from "../../../../services/disciplineworkload/additional-work.service";
import {CreateQuery} from "../../../../helpers/createQuery-helper";
import {AssignmentDepartmentQuery} from "../../../../models/disciplineworkload/query/assignmentDepartmentQuery.model";
import { AssignmentDepartmentExportQuery } from "../../../../models/disciplineworkload/query/exportAssignmentDepartmentQuery.model";
import {AdditionalWorksQuery} from "../../../../models/disciplineworkload/query/additionalWorks.model";
import { EduGroupQuery } from 'src/app/models/disciplineworkload/query/eduGroupQuery.model';
import {AvailableKafedraFilter} from "../../../../models/disciplineworkload/query/available_kafedra_query.model";
import {getErrorMessage} from "../../../../helpers/errorHandle-helper";
import { DisciplineWorkloadUserAccessService } from "../../../../services/useraccess/disciplineworkload-user-access.service";
import {detectTypeForRewrite} from "../../../../helpers/multiselect-helper";
import {DateToString} from "../../../../helpers/date-helper";
import {AssignmentSettingsService} from "../../../../services/disciplineworkload/assignment-settings.service";
import {StaticPropertyEnum} from "../../../../models/disciplineworkload/enums/settings.enum";
import {getStaticPropertyValue, StaticProperty} from "../../../../models/disciplineworkload/settings.model";

@Component({
  selector: 'departmentAssignment',
  templateUrl: './departmentAssignment.component.html',
  styleUrls: ['./departmentAssignment.component.scss']
})
export class DepartmentAssignmentComponent implements OnInit {

  @ViewChild('extraGrid') private extraGrid!: GridComponent;
  @ViewChild('grid') private grid!: GridComponent;
  @ViewChild('eduGroup') private eduGroupCombobox!: ComboBoxComponent;
  @ViewChild('child') private child!: DisciplineAssignmentComponent;

  @Output() discipline: EventEmitter<any[]> = new EventEmitter<any[]>();

  public staticPropertyEnum = StaticPropertyEnum;
  public staticProperties: StaticProperty[] = [];

  public isEdit: boolean = false;
  public isAdd: boolean = false;
  public loading: boolean = false;
  public assignmentUpdate: 'contingent' | 'edu-plan' | 'all-cathedras' | null = null;
  public opened: boolean = false;
  public lastCheckbox: any;
  public selectedId: any;
  public itemToDelete: any;
  public assignmentDiscipline: boolean = false;
  public groupSelection: any[] = [];
  public selectedDiscipline: any;
  public userEditing: boolean = false;

  // Database sets
  public kafedras: KafedraGet[] = [];
  public studyForms: DictStudyForm[] = [];
  public extraWork: any[] = [];
  public trainingLevels: DictTrainingLevelGroup[] = [];
  public faculties: Faculty[] = [];
  public yearAndSemestrs: AvailableSemesters[] = [];
  public departmentAssignments: AssignmentDepartmentGet[] = [];
  public mainGridData: any[] = [];
  public dictAdditionalWorks: DictAdditionalWork[] = [];
  public massOperationAssignments: MassOperationAssignmentAdd[] = [];
  public typeWorks: any[] = [];
  public sort: SortDescriptor[] = [
    { field: "educationPlanDiscipline.semesterNumber", dir: 'asc' },
    { field: "educationPlanDiscipline.disciplineFullName", dir: 'asc' }
  ];
  public lastAssignmentsUpdate: any = '';

  // Queries
  private assignmentDepartmentQuery!: AssignmentDepartmentQuery;
  private assignmentDepartmentExportQuery!: AssignmentDepartmentExportQuery;
  private additionalWorksQuery!: AdditionalWorksQuery;
  private eduGroupQuery!: EduGroupQuery;

  public trainingLevelsEdit: DictTrainingLevelGroup | null = null;
  public facultiesEdit: any;
  public studyFormEdit: any;
  public kafedraEdit: any;
  public yearAndSemestrsEdit: any;
  public eduGroups: EduGroup[] = [];

  private query = new HttpParams();
  public editable: boolean = false;

  public groups: GroupDescriptor[] = [{ field: "groupByDiscipline" }];
  public virtual: any = {
    itemHeight: 28,
  };
  public formGroup!: FormGroup | undefined;
  private editedRowIndex!: number | undefined;

  private assignmentLocalStorage = localStorage.getItem('assignmentFilters');
  private assignmentLocalStorageValues: any;

  constructor(
      private departmentService: DepartmentService,
      private dictService: DictService,
      private assignmentsService: AssignmentsService,
      private notificationService: NotificationsService,
      private educationPlanService: EducationPlanService,
      private eduGroupService: EduGroupService,
      private additonalWorkService: AdditionalWorkService,
      private userAccess: DisciplineWorkloadUserAccessService,
      private settings: AssignmentSettingsService,) {
    this.assignmentLocalStorage ? this.assignmentLocalStorageValues = JSON.parse(this.assignmentLocalStorage) : null
  }

  //#region Data

  private async getStudyForms(setEditModel: boolean = true) {
    await this.dictService.getStudyForms().toPromise().then(
      (response) => {
        this.studyForms = response!;
        if (setEditModel) this.studyFormEdit = response![0];
    },
      reason => this.notificationService.showError(getErrorMessage(reason), 5000))
  }

  private async getTrainingLevels(setEditModel: boolean = true) {
    await this.dictService.getTrainingLevels().toPromise().then(
      (response) => {
        this.trainingLevels = [];

        if (!response && setEditModel) {
          this.trainingLevelsEdit = null;
        }

        //TODO: нужно настроить группировки на бэке
        let trainingLevels = ['Бакалавриат', 'Специалитет'];
        let item = new DictTrainingLevelGroup();
        let items = response!.filter(_ => trainingLevels.includes(_.name));
        for (let i = 0; i < items.length; i++) {
          item.ids.push(items[i].id);
          item.name += items[i].name + (i + 1 < items.length ? ' / ' : '');
          item.shortName += items[i].shortName + (i + 1 < items.length ? ' / ' : '');
        }
        this.trainingLevels.push(item);

        items = response!.filter(_ => !trainingLevels.includes(_.name));
        for (let i = 0; i < items.length; i++) {
          item = new DictTrainingLevelGroup();
          item.ids.push(items[i].id);
          item.name = items[i].name;
          item.shortName = items[i].shortName;

          this.trainingLevels.push(item);
        }

        if (setEditModel)
          this.trainingLevelsEdit = this.assignmentLocalStorage
            ? (this.trainingLevels ? (this.trainingLevels.find((item) => item.name === this.assignmentLocalStorageValues.trainingLevel) ?? null)
                : null)
            : (this.trainingLevels ? this.trainingLevels[0] : null);
    })
  }

  private async getFaculties(setEditModel: boolean = true) {
    await this.departmentService.getFaculties().toPromise().then(
      (response) => {
        this.faculties = response as Faculty[];
        if (setEditModel)
          this.facultiesEdit = this.assignmentLocalStorage
            ? this.faculties.find((item) => item.name === this.assignmentLocalStorageValues.faculty)
            : response![0];
      })
  }

  private getDictAdditionalWorks() {
    this.dictService.getAdditionalWorks(1).subscribe({
      next: (response) => {
        this.dictAdditionalWorks = response;
      }
    })
  }

  public async getStaticProperties() {
    await this.settings.getStaticProperties().toPromise().then(value => this.staticProperties = value!);
  }

  private getAdditionalWorks() {
    this.extraWork = [];

    if (!this.kafedraEdit) {
      return;
    }

    this.additionalWorksQuery = {
      studyYear: this.yearAndSemestrsEdit.year,
      semester: this.yearAndSemestrsEdit.semester,
      dictTrainingLevelId: this.trainingLevelsEdit!.ids,
      dictStudyFormId: this.studyFormEdit.id,
      facultyId: this.facultiesEdit?.id,
      kafedraId: this.kafedraEdit.id,
      additionalWorkType: 1
    }

    const query = CreateQuery(this.additionalWorksQuery);

    this.additonalWorkService.getAdditionalWorks(query).subscribe(
      response => this.extraWork = response,
      reason => this.notificationService.showError(getErrorMessage(reason), 5000)
    )
  }

  public toLocalDatetime(dt: any) {
    return dt != undefined && dt != '' ? DateToString(dt,'dd.MM.yyyy HH:mm') : '';
  }

  private getAssignmentDepartments() {
    this.departmentAssignments = [];

    if (!this.kafedraEdit) {
      return;
    }

    this.loading = true;

    if(this.assignmentLocalStorage)
      this.kafedraEdit = this.kafedras.find((item) => item.name === this.assignmentLocalStorageValues.kafedra)
      ?? this.kafedras[0]

    this.assignmentDepartmentQuery = {
      facultyId: this.facultiesEdit?.id,
      kafedraId: this.kafedraEdit.id ?? null,
      semester: this.yearAndSemestrsEdit.semester,
      studyYear: this.yearAndSemestrsEdit.year,
      dictTrainingLevelId: this.trainingLevelsEdit?.ids,
      dictStudyFormId: this.studyFormEdit.id
    }

    this.assignmentsService.getAssignmentDepartments(this.assignmentDepartmentQuery).subscribe(
        {
          next: (response) => {
            this.assignmentLocalStorage = null
            this.assignmentLocalStorageValues = null;
            localStorage.removeItem('assignmentFilters')

            this.departmentAssignments = response;
            this.lastAssignmentsUpdate = '';
            this.departmentAssignments.forEach((item, index) => {
              item.id = index + 1;
              item.groupByDiscipline = item.educationPlanDiscipline.semesterNumber + ". " + item.educationPlanDiscipline.disciplineFullName;
              item.groupBySemester = item.educationPlanDiscipline.semesterNumber;

              if(item.assignmentDiscipline?.updatedAt > this.lastAssignmentsUpdate)
                this.lastAssignmentsUpdate = item.assignmentDiscipline?.updatedAt;
            })

            this.query = new HttpParams();
            this.loading = false;

            this.mainGridData = groupBy(this.departmentAssignments, this.groups);
          },
          error: (reason) => {
            this.notificationService.showError(getErrorMessage(reason), 5000);

            this.loading = false;
          }
        })
  }

  private async getAllYearAndSemestrs(setEditModel: boolean = true) {
    await this.educationPlanService.getAvailableSemesters().toPromise().then(
      (response) => {
        response!.forEach((item) => {
          item.name = `${item.year} - ${item.year + 1}, ${item.semester} семестр`;
        })
        this.yearAndSemestrs = response!;
        if (setEditModel)
          this.yearAndSemestrsEdit = this.assignmentLocalStorageValues
            ? this.yearAndSemestrs.find((item) => item.year === this.assignmentLocalStorageValues.studyYear)
            : response![0]
        ;
      },
      reason => {
        this.notificationService.showError(getErrorMessage(reason), 5000);
      })
  }

  private getEduGroups() {
    this.eduGroupQuery = {
      studyYear: this.yearAndSemestrsEdit.year,
      semester: this.yearAndSemestrsEdit.semester,
      studyFormId: this.studyFormEdit?.id,
      trainingLevelId: this.trainingLevelsEdit!.ids,
      facultyId: this.facultiesEdit?.id ? this.facultiesEdit?.id:null
    }
    const query = CreateQuery(this.eduGroupQuery);
    this.eduGroupService.getEduGroups(query).subscribe({
      next: (response) => {
        this.eduGroups = response;
        this.eduGroups.forEach((item) => {
          item.name += ` (${item.studentCount} чел.)`
        })
      },
      error: (reason) => this.notificationService.showError(getErrorMessage(reason), 5000)
    })
  }

  private async getKafedras(setEditModel: boolean = true) {
    let filter = new AvailableKafedraFilter();
    filter.semester = this.yearAndSemestrsEdit?.semester;
    filter.studyYear = this.yearAndSemestrsEdit?.year;
    filter.facultyId = this.facultiesEdit?.id;
    filter.dictStudyFormId = this.studyFormEdit?.id;
    filter.dictTrainingLevelId = this.trainingLevelsEdit?.ids;

    await this.educationPlanService.getAvailableKafedras(filter).toPromise().then(
      (response) => {
        if (response == null) return;

        this.kafedras = response;

        if (!this.kafedraEdit || !this.kafedras.find(_ => _.id == this.kafedraEdit.id)) {
          if (setEditModel) this.kafedraEdit = response[0];
        }
      },
      error => this.notificationService.showError(getErrorMessage(error)))
  }

  //#endregion

  //#region Additional Work

  // Save additional work event
  public saveAdditionalWork(): void {
    // Form group is valid and not null
    if(this.formGroup && this.formGroup.valid) {
      this.formGroup.controls['assignmentDepartment'].setValue({
        // TODO: Id
        //id: null,
        year: this.yearAndSemestrsEdit.year,
        semestr: this.yearAndSemestrsEdit.semester,
        kafedraId: this.kafedraEdit.id,
      });
      //console.log(JSON.stringify(this.formGroup.value));
      // Add new
      if(this.isAdd) {
        this.additonalWorkService.createAdditionalWork(this.formGroup.value).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            this.getAdditionalWorks();
            this.isEdit = false;
          },
          error: (reason) => {
            this.notificationService.showError(getErrorMessage(reason), 5000);
            this.isEdit = false;
          }
        })
      }
      // Edit
      else {
        this.additonalWorkService.updateAdditionalWork(this.formGroup.value).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            this.getAdditionalWorks();
            this.isEdit = false;
          },
          error: (reason) => {
            this.notificationService.showError(getErrorMessage(reason), 5000);
            this.isEdit = false;
          }
        })
      }

    }
    this.closeEditor();
  }

  public editAdditionalWork({isEdited, dataItem, rowIndex}: CellClickEvent): void {
    if (isEdited || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    this.isAdd = false;
    this.isEdit = true;
    this.extraGrid.closeRow(this.editedRowIndex);
    this.formGroup = editFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.extraGrid.editRow(rowIndex, this.formGroup);
  }

  // Remove additional work event
  public removeAdditionalWork() {
    this.additonalWorkService.deleteAdditionalWork(this.selectedId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Успешно');
        this.opened = false;
        this.getAdditionalWorks();
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);
        this.opened = false;
      }
    })
  }

  // Add extra work assignment
  public addAdditionalWorkHandler(args: AddEvent): void {
    // Add edugroups to dropdown
    this.isEdit = true;
    this.isAdd = true;
    this.formGroup = createFormGroup({
      assignmentDepartment: '',
      eduGroupId: '',
      totalStudentsLoad: 0,
      dictAdditionalWorksId: ''
    });

    args.sender.addRow(this.formGroup);
  }

  // Cancel editing
  public cancelHandler(args: CancelEvent): void {
    this.isEdit = false;
    this.isAdd = false;
    this.closeEditor();
  }

  // Close editing
  private closeEditor(): void {
    this.extraGrid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  // Open Confirmation window for deleting extra work
  public open(dataItem: any) {
    this.selectedId = dataItem.id;
    this.opened = true;
    this.itemToDelete = `${dataItem.dictAdditionalWork.name} ${dataItem.eduGroup.name}`;
  }

  // Close confirmation window for deleting extra work
  public close() {
    this.opened = false;
    this.selectedId = null;
    this.itemToDelete = "";
  }

  //#endregion

  //#region Export

  // Export assignments event
  public exportAssignmentDepartments() {
    if(!this.kafedraEdit)
      return

    this.assignmentDepartmentExportQuery = {
      facultyId: this.facultiesEdit?.id,
      kafedraId: this.kafedraEdit.id,
      semester: this.yearAndSemestrsEdit.semester,
      studyYear: this.yearAndSemestrsEdit.year,
      dictTrainingLevelId: this.trainingLevelsEdit?.ids,
      dictStudyFormId: this.studyFormEdit.id
    }

    this.assignmentsService.getExportAssignmentDepartments(this.assignmentDepartmentExportQuery).subscribe({
      next: (response) => {
        let blob:any = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        saveAs(blob, `${this.yearAndSemestrsEdit.name} ${this.kafedraEdit.name}`);
      },
      error: err => {
        this.notificationService.showError(getErrorMessage(err), 5000);
      },
    })
  }

  //#endregion

  //#region Filters

  public async valueChange(value: any, editItemName: any) {
    (this as any)[`${editItemName}`] = detectTypeForRewrite((this as any)[`${editItemName}`], value);

    if(editItemName !== 'kafedraEdit') await this.getKafedras();

    this.getEduGroups();

    await this.filterGrid();

    this.clearMassOperations();
  }

  // Display data table after selecting values
  public async filterGrid() {
    // Check if dropdown values are filled
    if(this.yearAndSemestrsEdit && this.kafedraEdit) {
      await this.getEditingRights();

      this.getAssignmentDepartments();
      this.getAdditionalWorks();

      this.setFilterOptions();
    }
  }

  //endregion

  //#region Assignment departments

  //#region Grid settings

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  //#endregion

  //#region Mass operation

  // Toggle mass operations
  toggleMassOperations() {
    this.editable = !this.editable;
    if(!this.editable) {
      this.groupSelection = [];
    }
  }

  // Массовое создание поручений
  public massOperationCreateAssignmentDisciplines() {
    if(this.groupSelection.length == 0) return;

    // Передаем выбранные данные в DTO
    const arr = [...this.departmentAssignments.filter((item) => this.groupSelection.includes(item.id))];

    arr.forEach((item) => {
      // поиск ID поручения в рамках группировки. Это необходимо для того, чтобы записать группу в одно поручение
      let assignmentDisciplineId =
        this.departmentAssignments.find(d =>
          d.groupByDiscipline == item.groupByDiscipline && d.assignmentDiscipline != null
        )?.assignmentDiscipline?.id;

      this.massOperationAssignments.push({
        eduGroupId: item.eduGroup?.id,
        educationPlanDisciplineId: item.educationPlanDiscipline.id,
        assignmentDisciplineId: assignmentDisciplineId
      })
    })

    const queryModel = {
      year: this.yearAndSemestrsEdit.year,
      semestr: this.yearAndSemestrsEdit.semester,
      kafedraId: this.kafedraEdit.id
    }

    this.assignmentsService.createAssignmentDisciplines(this.massOperationAssignments, queryModel).subscribe({
      next: () => {
        this.notificationService.showSuccess('Успешно');
        this.getAssignmentDepartments();
        this.editable = false;
        this.groupSelection = [];
        this.massOperationAssignments = [];
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);

        this.editable = false;
        this.groupSelection = [];
        this.massOperationAssignments = [];
      }
    })
  }

  // Массовое отозвание поручений
  public massOperationRecallAssignmentDisciplines() {
    if(this.groupSelection.length == 0) {
      return;
    }
    const arr = [...this.departmentAssignments.filter((item) => this.groupSelection.includes(item.id))];
    let ids: any[] = [];
    arr.forEach((item) => {
      if(item.disciplineGroup?.id) {
        ids.push(item.disciplineGroup.id)
      }
    })
    if(arr.length == 0) {
      return;
    }
    this.assignmentsService.recallAssignmentDisciplines(ids).subscribe({
      next: () => {
        this.notificationService.showSuccess('Успешно');
        this.editable = false;
        this.groupSelection = [];
        this.getAssignmentDepartments();
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);

        this.groupSelection = [];
        this.editable = false;
      }
    })
  }

  // Handle checkmarks for grouping when selected all
  public checkmarkSelectAll(e: any) {
    const checked = e.target.checked;
    const groupRows = this.grid.ariaRoot.nativeElement.querySelectorAll('.k-grouping-row');
    groupRows.forEach((item: any) => {
      item.querySelector("input").checked = checked;
    })
  }

  private clearMassOperations() {
    if(this.editable) {
      this.groupSelection = [];
    }
  }

  //endregion

  //#region Table display

  // Отобразить сопряжения в группировке
  public displayConjurations(group: any) {
    // Сопряжения
    let disciplineConjugations: any[] = group.items[0].disciplineConjugations;
    let text = "";
    // Nullable check
    if(disciplineConjugations == null) {
      return;
    }

    if(disciplineConjugations.length > 0) {
      text = "(+ "
      disciplineConjugations.forEach((item) => {
        text += item + ", ";
      })
      text = text.slice(0, -2);
      text += ")"
    }
    return text;
  }

  // Отобразить стиль на расхождение
  mismatchStyle(dataItem: any , typework: string) {
    if (!dataItem.disciplineGroup?.onAssignment)
      return '';

    let typeWorkId = getStaticPropertyValue(this.staticProperties, typework);

    const typeWorks: any = dataItem.educationPlanDiscipline.typeWorks
      ?.find((item: { dictTypeWork: { id: string; }; }) => item.dictTypeWork.id === typeWorkId)
      ?.hours;
    const disciplineTypeWorks: any = dataItem.disciplineAssignmentLoad?.hours
      ?.find((item: { dictTypeWork: { id: string; }; }) => item.dictTypeWork.id === typeWorkId)
      ?.numberHours;

    return typeWorks != disciplineTypeWorks ? 'red' : '';
  }

  mismatchStudentStyle(dataItem: any) {
    if (!dataItem?.disciplineGroup?.onAssignment)
      return '';

    return dataItem?.contingentLoad?.plannedStudentCount != dataItem?.disciplineGroup?.students ? 'red' : '';
  }

  mismatchSubGroupStyle(dataItem: any) {
    if (!dataItem?.disciplineGroup?.onAssignment ||
      dataItem.assignmentDiscipline?.customSubGroups)
      return '';

    return dataItem.contingentLoad?.subGroupCount != dataItem.disciplineGroup?.subEduGroups ? 'red' : '';
  }

  // Display type works on table
  public displayTypeWorks(dataItem: any, typeWorkName: string) {
    let typeWorkId = getStaticPropertyValue(this.staticProperties, typeWorkName);

    const typeWorkHours: any = dataItem.educationPlanDiscipline.typeWorks
      ?.find((item: { dictTypeWork: { id: string; } }) => item.dictTypeWork.id == typeWorkId)
      ?.hours;

    if (!dataItem.disciplineGroup?.onAssignment)
      return typeWorkHours ?? 0;

    let disciplineTypeWorkHours;
    if(dataItem.disciplineAssignmentLoad) {
      disciplineTypeWorkHours = dataItem.disciplineAssignmentLoad?.hours
        .find((item: { dictTypeWork: { id: string; }; }) => item.dictTypeWork.id == typeWorkId)
        ?.numberHours;
    }

    if (disciplineTypeWorkHours == typeWorkHours)
      return disciplineTypeWorkHours ?? 0;

    return (disciplineTypeWorkHours ?? '-') + ' (' + (typeWorkHours ?? '-') + ')';
  }

  public displayCourseWork(dataItem: any): string {
    if (!dataItem) return '';

    let hasCourseWork = dataItem.educationPlanDiscipline.hasCourseWork;
    let hasCourseWorkFixed = dataItem.disciplineAssignmentLoad?.hasCourseWork;

    if (hasCourseWorkFixed == null || hasCourseWork == hasCourseWorkFixed)
      return hasCourseWork ? 'Да' : 'Нет';

    return (hasCourseWorkFixed ? 'Да' : 'Нет') + ' (' + (hasCourseWork ? 'Да' : 'Нет') + ')';
  }

  public displayCourseProject(dataItem: any): string {
    if (!dataItem) return '';

    let hasCourseProject = dataItem.educationPlanDiscipline.hasCourseProject;
    let hasCourseProjectFixed = dataItem.disciplineAssignmentLoad?.hasCourseProject;

    if (hasCourseProjectFixed == null || hasCourseProject == hasCourseProjectFixed)
      return hasCourseProject ? 'Да' : 'Нет';

    return (hasCourseProjectFixed ? 'Да' : 'Нет') + ' (' + (hasCourseProject ? 'Да' : 'Нет') + ')';
  }

  public displayGroupFlows(dataItem: any) {
    //console.log(dataItem);
    if (!dataItem) return;

    if(dataItem.length < 1) {
      return;
    }
    let arr: any[] = dataItem;
    let text: string = "";
    arr.forEach((item: { flowNumber: number; }) => {
      text += item.flowNumber + ", ";
    })
    text = text.slice(0, -2);
    return text;
  }

  public displayDisciplineName(group: any) {
    //console.log(group);
    return group?.items?.length > 0 ? group.items[0].educationPlanDiscipline.disciplineFullName : "";
  }

  public displaySubGroup(dataItem: any): string {
    if (!dataItem) return '';

    let subGroup = dataItem.contingentLoad?.subGroupCount ?? 0;
    let subGroupFixed = dataItem.disciplineGroup?.subEduGroups ?? 0;

    if (dataItem.assignmentDiscipline?.customSubGroups) return subGroupFixed;

    if (subGroup == subGroupFixed || !dataItem.assignmentDiscipline?.onAssignment)
      return subGroup;

    return (subGroupFixed ?? 0) + ' (' + (subGroup ?? 0) + ')';
  }

  public displayStudentCount(dataItem: any): string {
    if (!dataItem) return '';

    let students = dataItem.contingentLoad?.plannedStudentCount ?? 0;
    let studentsFixed = dataItem.disciplineGroup?.students ?? 0;

    if (students == studentsFixed || !dataItem.assignmentDiscipline?.onAssignment)
      return students;

    return (studentsFixed ?? 0) + ' (' + (students ?? 0) + ')';
  }

  public displayGroupName(dataItem: AssignmentDepartmentGet | null) {
    if (!dataItem) return '';

    return dataItem.eduGroup.name +
      " (" +
      (this.yearAndSemestrsEdit.year == dataItem.eduGroup.yearAdmission &&
      (dataItem.eduGroup.studentCount == null || dataItem.eduGroup.studentCount == 0)
        ? dataItem.contingentLoad?.plannedStudentCount
        : dataItem.eduGroup.studentCount)+
      " чел.)";
  }

  //#endregion

  //#region Group

  // Выбор группировки для массовых операций
  public selectGroup(e: any, group: any) {
    const checked = e.target.closest("td").querySelector("input").checked;
    const currentCheckbox = e.target.closest("td").querySelector("input");

    if(this.groupSelection.length == 0) {
      this.groupSelection = [];
    }

    const groups = [
      ...this.departmentAssignments.filter((item) =>
        item.groupByDiscipline === group.value)];

    // Галочка снята
    if(!checked) {
      const currentIds: any[] = [];
      groups.forEach((item) => {
        currentIds.push(item.id);
      })
      this.groupSelection = [...this.groupSelection.filter((item => !currentIds.includes(item)))];
      return;
    }

    // Добавляем выбранные
    groups.forEach((item) => {
      this.groupSelection.push(item.id);
    })

    this.groupSelection = [...new Set(this.groupSelection)];
    this.lastCheckbox = currentCheckbox;
  }

  // Grouping click event
  public onClickGroupDiscipline(e: any, group: any): void {
    if(!this.userEditing) {
      return;
    }
    if (!e.target.classList.contains("k-icon") &&
      e.target.closest("tr").classList.contains("k-grouping-row") &&
      !e.target.classList.contains("k-checkbox")) {
      if(this.editable) {
        return;
      }

      this.disciplineAssignmentNavigate({dataItem: {groupByDiscipline: group.value}}, true);
    }
  }

  //#endregion

  //#region Helper methods

  public onStateChange(value: boolean) {
    this.assignmentDiscipline = value;
    this.getAssignmentDepartments();
  }

  // Открыть конструктор для кнопки "Добавить дисциплину"
  public addDisciplineNavigate() {
    this.selectedDiscipline = [...this.departmentAssignments];
    this.child.checkDiscipline(null, this.selectedDiscipline, true);
    this.assignmentDiscipline = true;
  }

  // Navigate to discipline assignments on cell click
  disciplineAssignmentNavigate(cell: any, groupingClick: boolean): void {
    if(this.editable) {
      return;
    }

    let dataItem = cell.dataItem ?? cell;

    //console.log('dataItem', dataItem);

    let assignmentDisciplineId: string;
    this.selectedDiscipline = [...this.departmentAssignments
      .filter(item=> item.groupByDiscipline === dataItem.groupByDiscipline)
    ];

    //console.log('selectedDiscipline', this.selectedDiscipline);

    // Grouping click handle
    if(groupingClick) {
      // Находим есть ли в группировке дисциплины с поручением (первую попавшуюся)
      assignmentDisciplineId = {...this.selectedDiscipline.find((item: any) =>
          item.assignmentDiscipline?.id && item?.assignmentDiscipline?.onAssignment)
      }.assignmentDiscipline?.id;
    }
    else {
      // Клик по строке
      assignmentDisciplineId = dataItem.assignmentDiscipline?.id;
    }

    if(!this.userEditing && !assignmentDisciplineId) {
      return;
    }

    // Handle discipline is inAssignment
    this.child.checkDiscipline(assignmentDisciplineId, this.selectedDiscipline);
    this.assignmentDiscipline = true;
  }

  //#endregion

  //endregion

  //#region Assignment update

  public updateEducationPlanes() {
    let ids: string[] = [];

    this.departmentAssignments?.forEach((item, index) => {
      if (item.assignmentDiscipline?.id) {
        ids.push(item.assignmentDiscipline.id)
      }
    });

    if (!ids) return;

    this.assignmentUpdate = 'edu-plan';

    this.assignmentsService.updateEducationPlanes(ids).subscribe({
      next: () => {
        this.notificationService.showSuccess('Успешно');

        this.editable = false;
        this.groupSelection = [];

        this.getAssignmentDepartments();

        this.assignmentUpdate = null;
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);

        this.groupSelection = [];
        this.editable = false;

        this.assignmentUpdate = null;
      }
    })
  }

  public updateContingent() {
    let ids: string[] = [];

    this.departmentAssignments?.forEach((item, index) => {
      if(!item.assignmentDiscipline) {
        return;
      }
      if (item.assignmentDiscipline!.id) {
        ids.push(item.assignmentDiscipline!.id)
      }
    });

    if (!ids) return;

    this.assignmentUpdate = 'contingent';

    this.assignmentsService.updateContingent(ids).subscribe({
      next: () => {
        this.notificationService.showSuccess('Успешно');
        this.editable = false;
        this.groupSelection = [];
        this.getAssignmentDepartments();
        this.assignmentUpdate = null;
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);
        this.groupSelection = [];
        this.editable = false;
        this.assignmentUpdate = null;
      }
    })
  }

  //Обновить контингент и учебные планы
  public UpdateContingentAndPlans() {
    if (!this.facultiesEdit?.id) return;

    this.assignmentUpdate = 'all-cathedras';

    this.assignmentsService.UpdateContingentAndPlans(this.facultiesEdit?.id).subscribe({
      next: () => {
        this.notificationService.showSuccess('Успешно');
        this.editable = false;
        this.groupSelection = [];
        this.getAssignmentDepartments();
        this.assignmentUpdate = null;
      },
      error: (reason) => {
        this.notificationService.showError(getErrorMessage(reason), 5000);
        this.groupSelection = [];
        this.editable = false;
        this.assignmentUpdate = null;
      }
    })
  }

  //#endregion

  //#region Access rights

  // Проверяем есть ли у пользователя права на редактирования
  private async getEditingRights() {
    if(!this.kafedraEdit?.id) return;
    await this.userAccess.getEditingRightsInErrands({
      kafedraId: this.kafedraEdit?.id
    }).toPromise().then(
      (response) => {
        if(response)
          this.userEditing = response.isApproved;
        //console.log(this.userEditing);
      }
    )
  }

  //#endregion

  //#region Helper methods

  private storage_filter_key: string = 'assignment_department-filter';

  private setFilterOptions() {
    localStorage.removeItem(this.storage_filter_key);
    localStorage.setItem(this.storage_filter_key, JSON.stringify({
      yearAndSemestrsEdit: this.yearAndSemestrsEdit,
      kafedraEdit: this.kafedraEdit,
      trainingLevelsEdit: this.trainingLevelsEdit,
      studyFormEdit: this.studyFormEdit,
      facultiesEdit: this.facultiesEdit
    }));
  }

  private getFilterOptions(): boolean {
    const json = localStorage.getItem(this.storage_filter_key);

    if (json != null) {
      try {
        const filters = JSON.parse(json);

        this.yearAndSemestrsEdit = filters.yearAndSemestrsEdit;
        this.kafedraEdit = filters.kafedraEdit;
        this.trainingLevelsEdit = filters.trainingLevelsEdit;
        this.studyFormEdit = filters.studyFormEdit;
        this.facultiesEdit = filters.facultiesEdit;

        return true;
      }
      catch (e) {
        localStorage.removeItem(this.storage_filter_key);
        return false;
      }
    }

    return false;
  }

  //#endregion

  async ngOnInit() {
    const needSetEditModel = !this.getFilterOptions();

    await this.getStaticProperties();
    await this.getAllYearAndSemestrs(needSetEditModel);
    await this.getTrainingLevels(needSetEditModel);
    await this.getStudyForms(needSetEditModel);
    await this.getFaculties(needSetEditModel);
    await this.getKafedras(needSetEditModel);

    this.getDictAdditionalWorks();

    this.getEduGroups();


    await this.getEditingRights();

    this.getAssignmentDepartments();
    this.getAdditionalWorks();
  }
}

// Formgroup for adding new item
const createFormGroup = (dataItem: {
  assignmentDepartment: any;
  eduGroupId: any;
  eduGroup?: any;
  dictAdditionalWork?: any;
  totalStudentsLoad: number;
  dictAdditionalWorksId: any;}) =>
  new FormGroup({
    assignmentDepartment: new FormControl(dataItem.assignmentDepartment),
    eduGroupId: new FormControl(dataItem.eduGroup, Validators.required),
    totalStudentsLoad: new FormControl(dataItem.totalStudentsLoad),
    dictAdditionalWorksId: new FormControl(dataItem.dictAdditionalWorksId, Validators.required),
  });

// Formgroup for editing item
const editFormGroup = (dataItem: {
  id?: any;
  assignmentDepartment?: any;
  eduGroupId?: any;
  eduGroup?: any;
  dictAdditionalWork?: any;
  totalStudentsLoad?: number | null;
  dictAdditionalWorksId?: any;}) =>
    new FormGroup({
      id: new FormControl(dataItem.id, Validators.required),
      assignmentDepartment: new FormControl(dataItem.assignmentDepartment),
      eduGroupId: new FormControl(dataItem.eduGroup.id, Validators.required),
      totalStudentsLoad: new FormControl(dataItem.totalStudentsLoad),
      dictAdditionalWorksId: new FormControl(dataItem.dictAdditionalWork.id, Validators.required),
    });

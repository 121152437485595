<workload-settings></workload-settings>
<h1>Настройки</h1>
<span class="workloadDropdownContainer">
  <h3>{{getPropertyName(staticPropertyEnum.lectionId)}}</h3>
  <kendo-dropdownlist valueField="id" textField="name" class="workloadDropdown"
                      [data]="dictTypeWorks"
                      [(ngModel)]="lectionId"
                      [valuePrimitive]="true"
                      (valueChange)="saveProperty(staticPropertyEnum.lectionId, $event)">
  </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
  <h3>{{getPropertyName(staticPropertyEnum.practiceId)}}</h3>
  <kendo-dropdownlist valueField="id" textField="name" class="workloadDropdown"
                      [data]="dictTypeWorks"
                      [(ngModel)]="practiceId"
                      [valuePrimitive]="true"
                      (valueChange)="saveProperty(staticPropertyEnum.practiceId, $event)">
  </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
  <h3>{{getPropertyName(staticPropertyEnum.laboratoryId)}}</h3>
  <kendo-dropdownlist valueField="id" textField="name" class="workloadDropdown"
                      [data]="dictTypeWorks"
                      [(ngModel)]="laboratoryId"
                      [valuePrimitive]="true"
                      (valueChange)="saveProperty(staticPropertyEnum.laboratoryId, $event)">
  </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
  <h3>{{getPropertyName(staticPropertyEnum.CSR)}}</h3>
  <kendo-dropdownlist valueField="id" textField="name" class="workloadDropdown"
                      [data]="dictTypeWorks"
                      [(ngModel)]="csrId"
                      [valuePrimitive]="true"
                      (valueChange)="saveProperty(staticPropertyEnum.CSR, $event)">
  </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
  <h3>{{getPropertyName(staticPropertyEnum.RGR)}}</h3>
  <kendo-dropdownlist valueField="id" textField="name" class="workloadDropdown"
                      [data]="dictTypeWorks"
                      [(ngModel)]="rgrId"
                      [valuePrimitive]="true"
                      (valueChange)="saveProperty(staticPropertyEnum.RGR, $event)">
  </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
  <h3>{{getPropertyName(staticPropertyEnum.ControlWork)}}</h3>
  <kendo-dropdownlist valueField="id" textField="name" class="workloadDropdown"
                      [data]="dictTypeWorks"
                      [(ngModel)]="controlWorkId"
                      [valuePrimitive]="true"
                      (valueChange)="saveProperty(staticPropertyEnum.ControlWork, $event)">
  </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
  <h3>{{getPropertyName(staticPropertyEnum.Referat)}}</h3>
  <kendo-dropdownlist valueField="id" textField="name" class="workloadDropdown"
                      [data]="dictTypeWorks"
                      [(ngModel)]="referatid"
                      [valuePrimitive]="true"
                      (valueChange)="saveProperty(staticPropertyEnum.Referat, $event)">
  </kendo-dropdownlist>
</span>

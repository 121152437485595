import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StudPerson } from '../../models/contingent/studperson.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {IReportTemplateInfo} from "../../models/report-designer/ITemplateInfo";

@Injectable({
  providedIn: "root"
})
export class ReportDesignerService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.reportViewer.base}/${environment.apiPaths.reportViewer.paths.templates}`;

  constructor(private http: HttpClient) { }

  getTemplatesByServiceName(serviceName: string): Observable<any[]> {
    return this.http.get<any[]>(this.baseUrl + '/Info?serviceName=' + serviceName);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

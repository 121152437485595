import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {CreateQuery} from "../../helpers/createQuery-helper";
import {getErrorMessage} from "../../helpers/errorHandle-helper";
import {NotificationsService} from "../notifications/notifications.service";
import {DownloadFile} from "../../helpers/downloadFile-helper";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.reports}`;
  constructor(private http: HttpClient,
              private notificationService: NotificationsService) { }

  // Отчет Учебная нагрузка кафедры по институту
  public getExcelReportTeachingLoadOfSubdepartmentByInstitute(filters: { studyYear: number, kathedraId: string, facultyId: string }) {
    const query = CreateQuery(filters);
    this.http.get(this.baseUrl + '/GetExcelReportTeachingLoadOfSubdepartmentByInstitute', {
      params: query,
      responseType: 'blob',
      observe: 'response',
    }).subscribe({
      next: (response) => {
        DownloadFile(response);
      },
      error: err => this.notificationService.showError(getErrorMessage(err), 5000)
    });
  }

  // Отчет Учебная нагрузка кафедры по преподавателю
  public getExcelReportPersonLoadOfSubdepartment(filters: {studyYear: number, kafedraId: string; personId: string; loadTypeEnum: number}) {
    const query = CreateQuery(filters);
    this.http.get(this.baseUrl + '/GetExcelReportPersonLoadOfSubdepartment', {
      params: query,
      responseType: 'blob',
      observe: 'response',
    }).subscribe({
      next: (response) => {
        DownloadFile(response);
      },
      error: err => this.notificationService.showError(getErrorMessage(err), 5000)
    });
  }

  // Отчет Учебная нагрузка кафедры по преподавателям
  public getExcelReportTeachersLoadOfSubdepartment(filters: {
    studyYear: number,
    kafedraId: string,
    trainingLevelId?: string[] | null,
    facultyId?: string | null,
    studyFormId?: string[] | null,
    personId?: string | null
  }) {
    const query = CreateQuery(filters);
    this.http.get(this.baseUrl + '/GetExcelReportTeachersLoadOfSubdepartment', {
      params: query,
      responseType: 'blob',
      observe: 'response',
    }).subscribe({
      next: (response) => {
        DownloadFile(response);
      },
      error: err => this.notificationService.showError(getErrorMessage(err), 5000)
    });
  }

  // Отчет по ППС сводный
  public getSummaryPps(filters: {
    studyYear: number,
    kafedraId: string
    //trainingLevelId?: string[] | null,
    //facultyId?: string | null,
    //studyFormId?: string[] | null,
    //personId?: string | null
  }) {
    const query = CreateQuery(filters);
    this.http.get(this.baseUrl + '/GetSummaryPps', {
      params: query,
      responseType: 'blob',
      observe: 'response',
    }).subscribe({
      next: (response) => {
        DownloadFile(response);
      },
      error: err => this.notificationService.showError(getErrorMessage(err), 5000)
    });
  }
}

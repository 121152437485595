import {Injectable} from "@angular/core";
import {catchError, OperatorFunction} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {NotificationsService} from "../notifications/notifications.service";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.applicationDoc}/`;

  constructor(private http: HttpClient, private notificationsService: NotificationsService) { }

  private errorHandler(): OperatorFunction<Blob, Blob> {
    return catchError((err) => {
      this.notificationsService.showError(err.error.Message ?? err.error);
      return handleError(err);
    });
  }

  public downloadDocument(applicationId: string, documentId: string) {
    return this.http.get(`${this.baseUrl}${applicationId}/${documentId}`, {responseType: 'blob'})
      .pipe(this.errorHandler());
  }

  public getWithStamp(applicationId: string, documentId: string) {
    return this.http.get(`${this.baseUrl}GetWithStamp/${applicationId}/${documentId}`, {responseType: 'blob'})
      .pipe(this.errorHandler());
  }

  public getCompletedTemplate(applicationId: string, documentId: string) {
    return this.http.get(`${this.baseUrl}GetCompletedTemplate/${applicationId}/${documentId}`, {responseType: 'blob'})
      .pipe(this.errorHandler());
  }
}

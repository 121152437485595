import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { dataCsvIcon } from '@progress/kendo-svg-icons';
import { catchError, Observable, throwError } from 'rxjs';
import { SearchCourses, SearchFaculty } from '../../models/announcement/search-students.model';
import { SearchGroups } from '../../models/announcement/search-students.model';
import { SearchDirections } from '../../models/announcement/search-students.model';
import { SearchSpecialties } from '../../models/announcement/search-students.model';
import { SearchBudgets } from '../../models/announcement/search-students.model';
import { SearchForms } from '../../models/announcement/search-students.model';
import { SearchLevels } from '../../models/announcement/search-students.model';
import { SearchDepartments } from '../../models/announcement/search-students.model';
import { SearchSupervisors } from '../../models/announcement/search-students.model';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

  export class SearchStudentsService {
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);

  constructor(private http: HttpClient) { }

  public getFaculty() {
    return this.http.get<SearchFaculty[]>(this.baseUrl + '/departments?type=1')
      .pipe(
        catchError(this.handleError));
  }

  public getGroups(faculty: number) {
    let params = new HttpParams();
    if (faculty !== 0){
      params = params.append('departmentId', faculty)
    }
    return this.http.get<SearchGroups[]>(this.baseUrl + '/groups', {params} )
      .pipe(
        catchError(this.handleError));
  }

  public getAllGroups() {
    return this.http.get<SearchGroups[]>(this.baseUrl + '/groups' )
      .pipe(
        catchError(this.handleError));
  }

  public getDirections( faculty: number, departments: []) {
    let params = new HttpParams();
    params = params.append('departmentId', faculty);
    departments.forEach(department => {
      params = params.append('kafedraIds', department)
    })
    return this.http.get<SearchDirections[]>(this.baseUrl + '/directions', {params} )
      .pipe(
        catchError(this.handleError));
  }

  public getAllDirections() {
    return this.http.get<SearchDirections[]>(this.baseUrl + '/directions' )
      .pipe(
        catchError(this.handleError));
  }

  public getSpecialties(directions: [], faculty: number) {
    let params = new HttpParams();
  /*  directions.forEach(direction => {
      params = params.append('educationStandartsIds', direction)
    })*/
    if (faculty !== 0){
      params = params.append('departmentId', faculty)
    }
    return this.http.get<SearchSpecialties[]>(this.baseUrl + '/specialties', {params} )
      .pipe(
        catchError(this.handleError));
  }

  public getAllSpecialties() {
    return this.http.get<SearchSpecialties[]>(this.baseUrl + '/specialties')
      .pipe(
        catchError(this.handleError));
  }
  
   public getBudgets() {
    return this.http.get<SearchBudgets[]>(this.baseUrl + '/budgets' )
      .pipe(
        catchError(this.handleError));
  }

  public getForms() {
    return this.http.get<SearchForms[]>(this.baseUrl + '/forms' )
      .pipe(
        catchError(this.handleError));
  }

    public getLevels() {
        return this.http.get<SearchLevels[]>(this.baseUrl + '/levels' )
        .pipe(
            catchError(this.handleError));
    }

    public getDepartments() {
      return this.http.get<SearchDepartments[]>(this.baseUrl + '/departments?type=2' )
        .pipe(
          catchError(this.handleError));
    }
       
    public getSupervisors() {
        return this.http.get<SearchSupervisors[]>(this.baseUrl + '/supervisors' )
        .pipe(
            catchError(this.handleError));
    }

    public getCourses() {
      return this.http.get<SearchCourses[]>(this.baseUrl + '/courses' )
      .pipe(
          catchError(this.handleError));
  }

    observer = new Subject();
    public subscriber$ = this.observer.asObservable();

    public getStudents(initials: string, departments: [], courses: []){
      let request = {userType: 1, fio: initials, facultyIds: departments, courses: courses};
     
      this.http.post(this.baseUrl  + '/users', request ).subscribe({next:(data: any) => {
           this.observer.next(data);
       }});

      return this.http.post(this.baseUrl  + '/users', request );     
    }

    public getExpandStudents(initials: string, faculty: [], courses: [], isMale: boolean, groups: [], budgets: [], directions: [],
      specialitys: [], forms: [], levels: [], departments: [], supervisors: [],)
    {
      let fio: any = "";
      if (initials == ""){
        fio = null;
      }
      else {
        fio = initials;
      }
      let male: any = "";
      if (isMale == undefined){
        male = null;
      }
      else {
        male = isMale;
      }
       let request = {userType: 1, fio: fio, facultyIds: faculty, courses: courses, isMale: male, groupIds: groups, budgetIds: budgets, 
          directionIds: directions, specialityIds: specialitys, formIds: forms, educationIds: levels, cathedraIds: departments,
          supervisorIds: supervisors};

        this.http.post(this.baseUrl  + '/users', request ).subscribe({next:(data: any) => {
          this.observer.next(data);
      }});

     return this.http.post(this.baseUrl  + '/users',request);         
    }

    public parameters$ = new Subject<any>();
    public parameter: Array<{initials: string; faculty: number; courses: []}> = [];
    public SearchParams(initials: string, departments: number, courses: []){
      this.parameter = [{initials: initials, faculty: departments, courses: courses}];
    }

    public getParams(){
      return this.parameter;
    }

    public getAllStudents(){
      let request = {userType: 1}
      return this.http.post(this.baseUrl  + '/users', request  );
    }


  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
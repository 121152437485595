<div class="p-l20">
  <div class="big-title"> Список выбранных сотрудников  <span *ngIf="quantitySelectedPersons != 0">{{quantitySelectedPersons}}</span> </div>
  <kendo-grid
    [data]="gridView"
    [kendoGridSelectBy]="mySelectionKey"
    [(selectedKeys)]="selection"
    [selectable]="{enabled: true, checkboxOnly: true}"
    class="case-table">
    <kendo-grid-checkbox-column
      [showSelectAll]="true"
      [width]="50">
    </kendo-grid-checkbox-column>
    <kendo-grid-column field="fio" headerClass="gridHeader" title="ФИО" [width]="400">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells text-left">
            {{ dataItem.fio }}
          </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="post" headerClass="gridHeader" title="Должность" [width]="300">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.post}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="department" headerClass="gridHeader" title="Отдел" [width]="300">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.department}}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <button kendoButton class="add-list shadow-none"
    icon="x"
    (click)="deleteSelectedElemens()">
    Убрать выбранные записи
  </button>
</div>
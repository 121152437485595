<div class="k-flex-column" [style.max-width.px]="1000">
  <div class="k-display-flex">
    <div class="k-mr-12">
      <h4 class="k-mb-5 title">{{isNew ? 'Новый учебный план' : 'Учебный план'}}</h4>
      <form class="k-form k-form-horizontal" [formGroup]="eduPlanFormGroup">
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Название учебного плана</kendo-label>
          <kendo-textbox #name formControlName="name" size="small" [clearButton]="true" placeholder="" required>
          </kendo-textbox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Год поступления</kendo-label>
          <kendo-numerictextbox format="YYYY" [min]="2000" [max]="2100" [autoCorrect]="true"
            formControlName="yearAdmission" placeholder="Введите год поступления">
          </kendo-numerictextbox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Технология обучения</kendo-label>
          <kendo-dropdownlist [data]="studyTechnologies" [valuePrimitive]="true"
            (valueChange)="onChangeStudyTechnology($event)" valueField="id" textField="name"
            formControlName="dictStudyTechnologyId" required [hidden]="!isNew" [(ngModel)]="studyTechnology">
            <ng-template kendoDropDownListNoDataTemplate></ng-template>
          </kendo-dropdownlist>
          <kendo-label class="form-label" *ngIf="!isNew">{{ eduPlan?.studyTechnologyName || '-' }}</kendo-label>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Форма обучения</kendo-label>
          <kendo-dropdownlist [data]="studyForms" [valuePrimitive]="true" valueField="id" textField="name"
            formControlName="dictStudyFormId" required [hidden]="!isNew" [(ngModel)]="studyForm">
            <ng-template kendoDropDownListNoDataTemplate></ng-template>
          </kendo-dropdownlist>
          <kendo-label class="form-label k-mb-1" *ngIf="!isNew">{{ eduPlan?.studyFormName || '-' }}</kendo-label>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Ускоренное</kendo-label>
          <div class="k-display-flex k-flex-1 k-align-items-center">
            <kendo-dropdownlist [style.width.px]="87" [style.min-width.px]="87" [data]="acceleratedSignDataItems" [valuePrimitive]="true"
              valueField="value" textField="text" formControlName="acceleratedSign" required [(ngModel)]="acceleratedSignData">
              <ng-template kendoDropDownListNoDataTemplate></ng-template>
            </kendo-dropdownlist>
            <span class="k-ml-2 k-mr-2" [style.width.px]="97" *ngIf="eduPlanFormGroup.value.acceleratedSign">на базе</span>
            <kendo-dropdownlist *ngIf="eduPlanFormGroup.value.acceleratedSign" [data]="acceleratedStudyBases" [valuePrimitive]="true"
              valueField="dictAcceleratedStudyBaseExternalId" textField="dictAcceleratedStudyBaseName" (valueChange)="onChangeAcceleratedStudyBase($event)"
              [value]="model.dictAcceleratedStudyBaseId">
              <ng-template kendoDropDownListNoDataTemplate></ng-template>
            </kendo-dropdownlist>
            <span class="required position">*</span>
          </div>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Срок обучения</kendo-label>
          <div class="k-display-flex k-flex-1 k-align-items-center">
            <kendo-numerictextbox [min]="0" [max]="10" [decimals]="0" [autoCorrect]="true"
              (valueChange)="onChangeDevPeriod('years', $event)" format="n" formControlName="developmentPeriodYears">
            </kendo-numerictextbox>
            <span class="required position">*</span>
            <span class="k-ml-2 k-mr-4">лет</span>
            <kendo-numerictextbox [value]="model.developmentPeriodMonths" [min]="0" [max]="11" [decimals]="0"
              [autoCorrect]="true" (valueChange)="onChangeDevPeriod('months', $event)" format="n">
            </kendo-numerictextbox>
            <span class="k-ml-2">месяцев</span>
          </div>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Факультет</kendo-label>
          <kendo-dropdownlist [data]="faculties" [valuePrimitive]="true"
                              (valueChange)="onChangeFaculty($event)" valueField="facultyId" textField="facultyShortName"
                              formControlName="facultyId" required>
            <ng-template kendoDropDownListNoDataTemplate></ng-template>
          </kendo-dropdownlist>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label class="form-label">Кафедра</kendo-label>
          <kendo-combobox [data]="kafedras" [valuePrimitive]="true"
                              (valueChange)="onChangeKafedra($event)" valueField="kafedraId" textField="kafedraName"
                              formControlName="kafedraId"
                              [kendoDropDownFilter]="filterSettings"
                              #departmentcombobox (focus)="onFocus(departmentcombobox)"
                              required >
            <ng-template kendoDropDownListNoDataTemplate></ng-template>
          </kendo-combobox>
          <span class="required">*</span>
          <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label [for]="protocol" class="form-label">Протокол</kendo-label>
          <kendo-textbox #protocol formControlName="protocol" size="small" [clearButton]="true"
            [placeholder]="'Например, №452 от ' + currentDateStr + 'г.'">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield orientation="horizontal">
          <kendo-label [for]="professionalActivities" class="form-label">Виды профессиональной деятельности</kendo-label>
          <kendo-textarea #professionalActivities formControlName="professionalActivities" [rows]="2">
          </kendo-textarea>
        </kendo-formfield>
      </form>
    </div>
    <div [style.min-width.px]="350">
      <h4 class="k-mb-6 title">Продолжительность обучения, нед.</h4>
      <table>
        <tr>
          <td></td>
          <td *ngFor="let semester of devPeriodInfo[0]?.semesters;">
            <div class="k-display-flex k-mb-3" style="white-space: nowrap;">
              {{ semester.semesterNum }} {{semesterName}}
            </div>
          </td>
        </tr>
        <tr *ngFor="let course of devPeriodInfo;">
          <td>
            <div class="k-display-flex k-flex-nowrap k-mr-3" style="white-space: nowrap;">
              {{ course.courseNum + ' курс' }}
            </div>
          </td>
          <td *ngFor="let semester of course.semesters">
            <kendo-numerictextbox class="k-mr-3" [style.width.px]="100" format="n" [decimals]="0" [min]="0"
              [value]="semester.duration"
              (valueChange)="onChangeDevPeriodValue(course.courseNum, semester.semesterNum, $event)"
              required
            >
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="semester.duration === null">* обязательное поле</kendo-formerror>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="k-display-flex k-justify-content-end k-mt-5">
    <button kendoButton (click)="onCancel.emit()" class="k-mr-3">Отмена</button>
    <button kendoButton themeColor="primary" (click)="onSubmitForm()">{{isNew ? 'Создать' : 'Сохранить'}}</button>
  </div>
</div>

<persondepartment-header></persondepartment-header>
<h1>Технические администраторы</h1>

<span class="filter">
    <h3>Поиск по ФИО</h3>
    <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
    <button kendoButton (click)="clearData()">Очистить</button>
    <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">Применить</button>
</span>

<kendo-grid class="technical"
            [kendoGridBinding]="gridData"
            (remove)="removeHandler($event)"
            (cellClick)="editHandler($event)"
            (add)="addHandler($event)"
            [loading]="loading"
            [sortable]="true">
    <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate [position]="position">
        <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
        <div *ngIf="isInEditingMode">
          <button kendoButton (click)="cancelHandler()">Отмена</button>
          <button kendoButton themeColor="primary" (click)="saveCurrent()">Сохранить</button>
        </div>
      </ng-template>
    <kendo-grid-column field="personName" headerClass="gridHeader dictionaries" title="ФИО">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox 
        [kendoDropDownFilter]="filterSettings" 
        [data]="persons" 
        textField="fullName" 
        valueField="personExternalId"
        [valuePrimitive]="true"
        [virtual]="virtual"
        [formControl]="formGroup.get('personId')">
      </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ dataItem.personName }}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="" [width]="60">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>
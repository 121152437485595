<h1>Настройки доступа</h1>
<dicts-header></dicts-header>

<span class="filter">
    <h3>Поиск по ФИО</h3>
    <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
    <button kendoButton (click)="clearData()">Очистить</button>
    <button kendoButton themeColor="primary" (click)="filterData(searchFIO)">Применить</button>
</span>

<kendo-grid class="technical"
            [loading]="loading"
            [sortable]="true"
            [sort]="sort"
            [kendoGridBinding]="gridData"
            (remove)="removeHandler($event)"
            (edit)="editHandler($event)"
            (add)="addHandler($event)">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoGridToolbarTemplate>
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton (click)="cancelHandler()">Отмена</button>
        <button kendoButton themeColor="primary" [disabled]="formGroup!.invalid" (click)="saveCurrent()">Сохранить</button>
      </div>
    </ng-template>
  <kendo-grid-column title="" [width]="35" [sticky]="true">
    <ng-template kendoGridCellTemplate>
      <span class="alignCells">
        <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full"></button>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="" [width]="35" [sticky]="true">
    <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="250" headerClass="gridHeader dictionaries" field="personName" title="ФИО" [sticky]="true">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
      [kendoDropDownFilter]="filterSettings"
      [data]="persons"
      textField="fio"
      valueField="id"
      [valuePrimitive]="true"
      [virtual]="virtual"
      [formControl]="formGroup.get('personId')">
    </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.personName }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="accessLevelQualification" title="Справочники категории 'Квалификации'">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevelQualification')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.accessLevelQualificationName }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" field="accessLevelOrganization" headerClass="gridHeader dictionaries" title="Справочник 'Организации'">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevelOrganization')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.accessLevelOrganizationName }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="accessLevelEducation" title="Справочники категории 'Образование'">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevelEducation')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.accessLevelEducationName }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="accessLevelDiscipline" title="Справочники категории 'Дисциплины'">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevelDiscipline')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.accessLevelDisciplineName }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="accessLevelSignatoryRole" title="Справочники категории 'Роли подписантов'">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevelSignatoryRole')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.accessLevelSignatoryRoleName }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="accessLevelFilial" title="Справочники категории 'Филиалы'">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevelFilial')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.accessLevelFilialName }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="accessLevelEducationalOrganizationName" title="Справочник 'Наименования образовательной организации'">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessLevelEducationalOrganizationName')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.accessLevelEducationalOrganizationNameString }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="userAccessLevel" title="Настройки доступа"
                     *ngIf="isAdmin">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('userAccessLevel')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.userAccessLevel ? 'Да' : 'Нет' }}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="140" headerClass="gridHeader dictionaries" field="signatoryAccessLevel" title="Настройки подписантов"
                     *ngIf="isAdmin">
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('signatoryAccessLevel')">
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.signatoryAccessLevel ? 'Да' : 'Нет' }}
        </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

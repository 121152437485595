import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { DisciplineTypeService } from 'src/app/services/dicts/disciplinetype.service';
import { DisciplineType } from 'src/app/models/dicts/disciplinetype.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { Guid } from 'guid-typescript';
import {
  CommonDictionariesUserAccessService
} from "../../../services/useraccess/common-dictionaries-user-access.service";
import { isChildOf } from 'src/app/helpers/elementRef-helper';
import { Router } from '@angular/router';
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";
import {trainingLevelEnumList} from "../../../models/dicts/enums/training-level.enum";
import {disciplineTypeEnumList} from "../../../models/dicts/enums/discipline-type.enum";

@Component({
  selector: 'app-disciplinetype',
  templateUrl: './disciplinetype.component.html',
  styleUrls: ['./disciplinetype.component.css']
})
export class DisciplinetypeComponent implements OnInit {
  public disciplinetypes: DisciplineType[] = [];
  public disciplinetype: DisciplineType | {} = {}
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  protected readonly disciplineTypeEnumList = disciplineTypeEnumList;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public sort: SortDescriptor[] = [
    {
      field: "",
      dir: "asc",
    },
  ];
  public gridView: any | GridDataResult;

  constructor(private disciplinetypeService: DisciplineTypeService,
              private renderer: Renderer2, private formBuilder: FormBuilder,
              private notificationService: NotificationsService,
              private userAccessService: CommonDictionariesUserAccessService,
              private dialogService: DialogService,
              private router: Router,
  ) {

  }

  public ngOnInit(): void {
    this.getAccessLevel()
    //this.getAllDisciplineType();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadDiscipline();
  }

  private loadDiscipline(): void {
    this.gridView = {
      data: orderBy(this.disciplinetypes, this.sort),
      total: this.disciplinetypes.length,
    };
  }

  public onStateChange(): void {
    this.getAllDisciplineType();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      dictDisciplineTypeId: 0,
      name: '',
      sName: null,
      isPractice: false,
      usedInStudyProcess: false,
      dictDisciplineTypeExternalId: Guid.createEmpty(),
      hiddenCipher: false,
      hiddenLaborIntensity: false
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public nameEnum(enums?: number) {
    return this.disciplineTypeEnumList.find((x) => x.value === enums)?.text ?? "";
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.disciplinetypeService.addDisciplineType(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllDisciplineType();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError("Не удалось добавить запись");
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.disciplinetypeService.updateDisciplineType(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllDisciplineType();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError("Не удалось изменить запись");
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  getAllDisciplineType() {
    this.disciplinetypeService.getAllDisciplineType()
      .subscribe(
        response => {
          this.disciplinetypes = response;
          this.loadDiscipline();
        }
      );
  }

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          if (response.accessLevelDiscipline == 0){
            this.notificationService.showError("У вас нет прав для просмотра данной страницы");
            this.router.navigate(['/profile']);
          } else {
            this.editable = response.accessLevelDiscipline != 1;
            this.getAllDisciplineType();
          }
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.name.toLowerCase()} ?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.disciplinetypeService.deleteDisciplineType(dataItem.dictDisciplineTypeExternalId)
          .subscribe({
            next:() => {
              this.getAllDisciplineType();
              this.notificationService.showSuccess("Удалено");
            },
            error:(error) => {
            this.notificationService.showError(error.error);
          }}
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { dictDisciplineTypeId?: any; name?: any; orderNum?: any; sName?: any; isPractice?: any; usedInStudyProcess?: any; dictDisciplineTypeExternalId?: any; hiddenCipher?: any; hiddenLaborIntensity?: any; enum?: any; }) =>
  new FormGroup({
    dictDisciplineTypeId: new FormControl(dataItem.dictDisciplineTypeId),
    name: new FormControl(dataItem.name, Validators.required),
    orderNum: new FormControl(dataItem.orderNum, [Validators.min(1), Validators.pattern("^[0-9]+$")]),
    sName: new FormControl(dataItem.sName),
    isPractice: new FormControl(dataItem.isPractice, Validators.required),
    usedInStudyProcess: new FormControl(dataItem.usedInStudyProcess, Validators.required),
    dictDisciplineTypeExternalId: new FormControl(dataItem.dictDisciplineTypeExternalId),
    hiddenCipher: new FormControl(dataItem.hiddenCipher, Validators.required),
    hiddenLaborIntensity: new FormControl(dataItem.hiddenLaborIntensity, Validators.required),
    enum: new FormControl(dataItem.enum),
  });

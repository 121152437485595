<app-cards
  *ngIf="isCards"
  [studentId]="studentId"
  (changeSection)="onChangeSection($event)"
  (reestrId)="setReestrId($event)">
</app-cards>
<app-add-to-list-forming
  *ngIf="isAddToListForming"
  [studentId]="studentId"
  [reestrIdFromInput]="reestrId"
  (changeSection)="onChangeSection($event)">
</app-add-to-list-forming>
<app-reestr
  *ngIf="isReestr"
  (changeSection)="onChangeSection($event)"
  [reestrIdFromInput]="reestrId"
  (reestrIdFromOutput)="setReestrId($event)">
</app-reestr>

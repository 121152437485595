import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  GroupInfoModel,
  SaveSignatoryModel,
  SignatoriesModel,
} from '../../models/currentcontrol/sheets.model';
import { GetDisciplinePrintFormData } from '../../models/currentcontrol/discipline.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import {LocalSignatoryCCService} from "./local-signatory.service";
import {CurrentControlSheetListQuery} from "../../models/currentcontrol/query.model";

@Injectable({
  providedIn: 'root',
})
export class SheetService {
  baseUrl = `${environment.apiEndpoint}`;

  public signatories$ = new BehaviorSubject<SignatoriesModel[]>([]);
  public groupInfo$ = new BehaviorSubject<GroupInfoModel>({} as GroupInfoModel);

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private localSignatoryService: LocalSignatoryCCService
  ) {}

  public getSignatoriesByFaculty(
    facultyId: string
  ): Observable<SignatoriesModel[]> {
    return this.http
      .get<SignatoriesModel[]>(
        this.baseUrl +
          environment.apiPaths.currentcontrol.signatories +
          `/${facultyId}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список подписантов'
          );
          return of([] as SignatoriesModel[]);
        })
      );
  }

  public updateSignatories(trainingLevelId:string| undefined, facultyId: string) {
    //this.getSignatoriesByFaculty(facultyId).subscribe((response) => {
    this.localSignatoryService.getSigners(trainingLevelId, facultyId).subscribe((response) => {
      this.signatories$.next(response);
    });
  }

  public saveSignatory(data: SaveSignatoryModel) {
    return this.http
      .post<SignatoriesModel>(
        this.baseUrl + environment.apiPaths.currentcontrol.signatory,
        data
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось сохранить подписанта');
          return of({} as SignatoriesModel);
        })
      );
  }

  public getGroupInfoByStudy(
    eduGroupId: string,
    studyYear: number,
    semesterInYear: number
  ) {
    return this.http
      .get<GroupInfoModel>(
        this.baseUrl +
          environment.apiPaths.currentcontrol.sheetinfo +
          `/${eduGroupId}/${studyYear}/${semesterInYear}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить информацию о группе'
          );
          return of({} as GroupInfoModel);
        })
      );
  }

  public updateGroupInfo(
    eduGroupId: string,
    studyYear: number,
    semesterInYear: number
  ) {
    this.getGroupInfoByStudy(eduGroupId, studyYear, semesterInYear).subscribe(
      (response) => {
        this.groupInfo$.next(response);
      }
    );
  }

  public getGroupInfoBySemester(eduGroupId: string, semesterNum: number) {
    return this.http
      .get<GroupInfoModel>(
        this.baseUrl +
          environment.apiPaths.currentcontrol.sheetinfo +
          `/${eduGroupId}/${semesterNum}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить информацию о группе'
          );
          return of({} as GroupInfoModel);
        })
      );
  }

  public updateGroupInfoBySemester(eduGroupId: string, semesterNum: number) {
    this.getGroupInfoBySemester(eduGroupId, semesterNum).subscribe(
      (response) => {
        this.groupInfo$.next(response);
      }
    );
  }

  public printFormEmpty(request: CurrentControlSheetListQuery) {
    return this.http
      .post(
        this.baseUrl +
          environment.apiPaths.currentcontrol.empty, request,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }

  public printFormFilled(request: CurrentControlSheetListQuery, printType: number) {
    return this.http
      .post(
        this.baseUrl + environment.apiPaths.currentcontrol.filled +`/${printType}` , request,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }

  public printDisciplineForm(data: GetDisciplinePrintFormData) {
    return this.http
      .post(
        this.baseUrl + environment.apiPaths.currentcontrol.filleddiscipline,
        data,
        {
          responseType: 'blob',
          observe: 'response',
        }
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить ведомость для печати'
          );
          return of(new HttpResponse({ body: new Blob() }));
        })
      );
  }
}

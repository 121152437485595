import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { CurrentControlService } from 'src/app/services/currentcontrol/currentcontrol.service';
import {
  ControlActions,
  GroupCommonModel,
  HeaderControlActions,
} from '../../../models/currentcontrol/groupCommon.model';
import {omit} from "lodash";
import {CurrentDayEnd} from "../../../helpers/date-helper";

@Component({
  selector: 'app-currentsessiontable',
  templateUrl: './currentsessiontable.component.html',
  styleUrls: ['./currentsessiontable.component.scss'],
})
export class CurrentsessiontableComponent implements OnChanges {
  @Input() week: string | null = null;
  @Input() eduGroupId: string = '';
  @Input() semesterNumber: number | null = null;
  @Input() contingentDate: Date = CurrentDayEnd();

  @Output() onChangeState: EventEmitter<string> = new EventEmitter<string>();
  @Output() onChangeStudent: EventEmitter<{
    studentId: string;
    studentName?: string;
  }> = new EventEmitter<{
    studentId: string;
    studentName?: string;
  }>();
  @Output() onChangeDiscipline: EventEmitter<{
    disciplineId: string;
    electiveDisciplineId: string | null;
  }> = new EventEmitter<{
    disciplineId: string;
    electiveDisciplineId: string | null;
  }>();

  public currentDate: Date = CurrentDayEnd();
  public gridData: GroupCommonModel[] = [];
  public isLoading: boolean = false;
  public controlActions: HeaderControlActions = {};
  public headerControlActions: string[] | never[] = [];

  constructor(
    private currentControlService: CurrentControlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const current_control_settings = localStorage.getItem('current_control_settings');
    if (current_control_settings !== null) {
      let storage = JSON.parse(current_control_settings)
      if(storage.discipline){
        this.openDiscipline(storage.disciplineId, storage.electiveDisciplineId)
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //if (changes['eduGroupId']) {
      this.getHeader();
    //}
    this.getGroupData();
  }

  public getGroupData() {
    if (this.semesterNumber !== null && this.eduGroupId) {
      this.isLoading = true;

      this.currentControlService
        .getSheetStudentsList( {
          eduGroupId: this.eduGroupId,
          semester: this.semesterNumber,
          attestationId: this.week,
          contingentDate: this.contingentDate,
          isCurrentSemester: +this.currentDate !== +this.contingentDate
        })
        .subscribe((response) => {
          this.gridData = response.map((student) => {
            student.marksByDisciplineId = student.marks?.reduce(
              (marks, item) => {
                if (marks)
                  marks[
                    item.educationPlanElectiveDisciplineId
                      ? item.educationPlanElectiveDisciplineId
                      : item.educationPlanDisciplineId
                  ] = item;
                return marks;
              },
              {} as GroupCommonModel['marksByDisciplineId']
            );

            return student;
          });
          this.isLoading = false;
        });
    }
  }

  private getHeader() {
    if (this.semesterNumber !== null && this.eduGroupId) {
      this.currentControlService
        .getSheetHeader(this.eduGroupId, this.semesterNumber)
        .subscribe((response) => {
          this.controlActions = response.reduce((controlActions, item) => {
            if (controlActions[item.controlActionName]) {
              controlActions[item.controlActionName].push(item);
            } else controlActions[item.controlActionName] = [item];
            return controlActions;
          }, {} as HeaderControlActions);
          // Фильтруем курсовые проекты в текущем контроле
          this.controlActions = omit(this.controlActions, ['Курсовой проект']);
          this.headerControlActions = Object.keys(this.controlActions);
        });
    }
  }

  public openDiscipline(
    disciplineId: string,
    electiveDisciplineId: string | null
  ) {
    const current_control_settings = localStorage.getItem('current_control_settings');
    if (current_control_settings !== null) {
      let storage = JSON.parse(current_control_settings)
      let currentControlSaveSettings: any = {
        disciplineId: disciplineId,
        electiveDisciplineId: electiveDisciplineId,
        discipline:true,
        event: storage.event,
        selectedSemester:storage.selectedSemester,
        checkable: storage.checkable,
        checkedItems: storage.checkedItems,
        expandedKeys: storage.expandedKeys,
        selectedTrainingLevel: storage.selectedTrainingLevel,
        week: storage.week,
        contingentDate: storage.contingentDate
      }
      localStorage.setItem('current_control_settings', JSON.stringify(currentControlSaveSettings));
    }

    this.onChangeDiscipline.emit({ disciplineId, electiveDisciplineId });
    this.onChangeState.emit('discipline');
  }

  public getBallAndHours(
    dataItem: GroupCommonModel,
    discipline: ControlActions
  ) {
    const disciplineId = discipline.educationPlanElectiveDisciplineId
      ? discipline.educationPlanElectiveDisciplineId
      : discipline.educationPlanDisciplineId;

    if(dataItem.marksByDisciplineId) {
      return disciplineId &&
      dataItem.marksByDisciplineId[disciplineId] &&
      dataItem.marksByDisciplineId[disciplineId].ball !== null
          ? dataItem.marksByDisciplineId[disciplineId].ballHoursView
          : "";
    }
    return ''
  }

  public getImplemented(
    dataItem: GroupCommonModel,
    discipline: ControlActions
  ) {
    const disciplineId = discipline.educationPlanElectiveDisciplineId
      ? discipline.educationPlanElectiveDisciplineId
      : discipline.educationPlanDisciplineId;

    return (
      dataItem.marksByDisciplineId &&
      disciplineId &&
      dataItem.marksByDisciplineId[disciplineId] &&
      dataItem.marksByDisciplineId[disciplineId].notImplemented
    );
  }

  public navigateTo(studentId: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/contingent/studentmaininfo/${studentId}/progress/currentControl`])
    );

    window.open(url, '_blank');
  }

  // instead -webkit-line-clamp in CSS
  public trimText(text: string, linesCount: number = 2, lineLength: number = 30) {
    const words = text.split(' ');
    const ellipsis = '…';
    let line = '';
    let lineNumber = 0;

    const result: string[] = words.reduce<string[]>((acc, word) => {
      const newLineLength = `${line} ${word}`.length;

      if (newLineLength > lineLength) {
        lineNumber++;
        if (lineNumber === linesCount) {
          line = `${line} ${word}`.substring(0, lineLength - ellipsis.length - 1);
          acc.push(`${line}${ellipsis}`);
          line = '';
        } else {
          if (line) {
            acc.push(line);
            line = word;
          } else {
            const hyphen = word.indexOf('-') + 1;
            const part = hyphen ? word.substring(0, hyphen) : word;
            acc.push(part);
            line = hyphen ? word.substring(hyphen) : '';
          }
        }
      } else {
        line += line ? ` ${word}` : word;
      }
      return acc;
    }, []);

    if (line) {
      result.push(line);
    }

    return result.join(' ');
  }
}

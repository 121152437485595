<education-header></education-header>

<div kendoDialogContainer></div>
<div style="display: flex; justify-content: space-between; align-items: end;">
  <h1>Настройки графика учебного процесса</h1>
  <button kendoButton (click)="goBack()" style="height: 38px;">Назад</button>
</div>

<form class="k-form k-form-horizontal k-mt-0" [formGroup]="settingsFormGroup">
  <kendo-formfield>
    <kendo-label [style.max-width.px]="160" [style.min-width.px]="160" class="form-label">Филиал
    </kendo-label>
    <kendo-dropdownlist formControlName="filialId" [style.width.px]="198" [data]="filials"
      textField="filialShortName" valueField="filialId" [valuePrimitive]="true" [disabled]="!editable"
      (valueChange)="filialChange($event)">
    </kendo-dropdownlist>
  </kendo-formfield>
  <kendo-formfield>
    <kendo-label [style.max-width.px]="160" [style.min-width.px]="160" class="form-label">Уровень подготовки
    </kendo-label>
    <kendo-dropdownlist formControlName="dictTrainingLevelId" [style.width.px]="198" [data]="traininglevels"
      textField="trainingLevelName" valueField="trainingLevelExternalId" [valuePrimitive]="true" [disabled]="!editable"
      (valueChange)="trainingLevelChange($event)">
    </kendo-dropdownlist>
  </kendo-formfield>
  <h4 class="k-mt-10">Настройки по умолчанию
    <button *ngIf="!isEditingDefault&&editable" type="button" icon="edit" kendoButton class="edit-button k-ml-5"
      (click)="onEditDefault()">Редактировать</button>
  </h4>
  <div *ngIf="!isEditingDefault && settings">
    <div class="k-display-flex k-mt-5 k-mb-7">
      <kendo-label class="form-label" [style.width.px]="400" text="Показывать даты на графике:"></kendo-label>
      <kendo-label class="form-label" [text]="settings.showDatesInGraph ? 'Да' : 'Нет'"></kendo-label>
    </div>
    <div class="k-display-flex k-mt-5 k-mb-7">
      <kendo-label class="form-label" [style.width.px]="400" text="Учитывать праздничные дни на графике:"></kendo-label>
      <kendo-label class="form-label" [text]="settings.considerHolidays ? 'Да' : 'Нет'"></kendo-label>
    </div>
    <div class="k-display-flex k-mt-5 k-mb-7">
      <kendo-label class="form-label" [style.width.px]="400">Тип календарного планирования по умолчанию:</kendo-label>
      <kendo-label class="form-label" [text]="processScheduleService.countDaysInWeekMap[settings!.countDaysInWeekPart]?.name ?? ''"></kendo-label>
    </div>
    <div class="k-display-flex k-mt-5 k-mb-5">
      <kendo-label class="form-label" [style.width.px]="400">Дата начала обучения по умолчанию (число, месяц):</kendo-label>
      <kendo-label class="form-label">{{settings.studyDateBeginDefault | date: 'dd MMMM'}}</kendo-label>
    </div>
  </div>
  <div *ngIf="isEditingDefault">
    <kendo-formfield orientation="horizontal">
      <kendo-label class="form-label" [style.width.px]="400" text="Показывать даты на графике"></kendo-label>
      <kendo-switch formControlName="showDatesInGraph"></kendo-switch>
    </kendo-formfield>
    <kendo-formfield orientation="horizontal">
      <kendo-label class="form-label" [style.width.px]="400" text="Учитывать праздничные дни на графике"></kendo-label>
      <kendo-switch formControlName="considerHolidays"></kendo-switch>
    </kendo-formfield>
    <kendo-formfield orientation="horizontal">
      <kendo-label class="form-label" [style.width.px]="400">Тип календарного планирования по умолчанию</kendo-label>
      <kendo-dropdownlist formControlName="countDaysInWeekPart" [valuePrimitive]="true"
        [style.width.px]="240" [data]="processScheduleService.countDaysInWeek" textField="name" valueField="value">
      </kendo-dropdownlist>
    </kendo-formfield>
    <kendo-formfield orientation="horizontal">
      <kendo-label class="form-label" [style.width.px]="400">Дата начала обучения по умолчанию (число, месяц)</kendo-label>
      <kendo-datepicker formControlName="studyDateBeginDefault" format="dd MMMM"
        [style.width.px]="240">
      </kendo-datepicker>
    </kendo-formfield>
  </div>

</form>
<div *ngIf="isEditingDefault" class="k-display-flex k-justify-content-end k-mt-10" [style.width.px]="620">
  <button type="button" kendoButton class="k-mr-3" (click)="onCancel()">Отмена</button>
  <button kendoButton themeColor="primary" (click)="onSave()">Сохранить</button>
</div>

<h4 class="k-mt-10">Виды работ на графике</h4>
<div class="k-mt-5 k-mb-7" *ngIf="editable">
  <button kendoButton (click)="addHandler()" themeColor="success" *ngIf="!isEditing" icon="plus">Добавить</button>
  <div *ngIf="isEditing">
    <button kendoButton (click)="closeEditor()">
      Отмена
    </button>
    <button kendoButton (click)="saveWorkType()" [disabled]="formGroup.invalid" themeColor="primary" class="k-ml-3">
      Сохранить
    </button>
  </div>
</div>

<kendo-grid [data]="workTypes" [loading]="loading" (remove)="removeHandler($event)" [height]="500" (cellClick)="editHandler($event)">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column field="name" title="Вид работ" [width]="250">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [style]="{ 'text-align': 'left', 'padding-left': '25px' }">{{dataItem.name}}</div>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-textbox size="small" [clearButton]="true" placeholder="" [formControl]="formGroup.get('name')">
      </kendo-textbox>
      <!--<kendo-dropdownlist [formControl]="formGroup.get('dictWorkScheduleStudyProcessTypeId')" [valuePrimitive]="true"
        [data]="dictWorkScheduleStudyProcessTypes" textField="name"
        valueField="dictWorkScheduleStudyProcessTypeExternalId"
        (selectionChange)="setWorkScheduleStudyProcessType($event)">
      </kendo-dropdownlist>-->
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="shortName" title="Сокр. название" [width]="100">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-textbox size="small" [clearButton]="true" placeholder="" [formControl]="formGroup.get('shortName')">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="symbol" title="Условное обозначение" [width]="100">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-textbox size="small" [clearButton]="true" placeholder="" [formControl]="formGroup.get('symbol')">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="displayedValue" title="Отображаемое обозначение" [width]="100">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-textbox size="small" [clearButton]="true" placeholder="" [formControl]="formGroup.get('displayedValue')">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="color" title="Цвет на графике" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="k-display-flex k-justify-content-center">
        <div class="color-block" [style.background]="dataItem.color"></div>
      </div>
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-colorpicker [formControl]="formGroup.get('color')">
      </kendo-colorpicker>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="dictWorkCategoryScheduleName" title="Категория работ" [width]="180">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-dropdownlist [formControl]="formGroup.get('dictWorkCategoryScheduleExternalId')" [valuePrimitive]="true"
        [data]="dictWorkCategorySchedules" textField="dictWorkCategoryScheduleName"
        [kendoDropDownFilter]="filterSettings"
        (selectionChange)="setWorkScheduleStudyProcessCategory($event)"
        valueField="dictWorkCategoryScheduleExternalId">
      </kendo-dropdownlist>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="isInvolvedCalculating" title="Входит в расчет" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.isInvolvedCalculating ? 'Да' : 'Нет'}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <input type="checkbox" kendoCheckBox [formControl]="formGroup.get('isInvolvedCalculating')" />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="symbolMMIS" title="Усл. обозначение для импорта" [width]="180">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-textbox size="small" [clearButton]="true" placeholder="" [formControl]="formGroup.get('symbolMMIS')">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-column field="" title="Отображать в списке работ" [width]="100">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
      <kendo-textbox size="small" [clearButton]="true" placeholder="" [formControl]="formGroup.get('name')">
      </kendo-textbox>
    </ng-template>
  </kendo-grid-column> -->

  <kendo-grid-column title="" [width]="60" *ngIf="editable">
    <ng-template kendoGridCellTemplate>
      <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none"
        class="custom-size" rounded="full" style="text-overflow: unset;">
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

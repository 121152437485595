export enum AdditionalFieldEnumList {
  live_hostel = "2dec5a8e-b9bc-4bcc-91eb-cd50351e953c",
  medical_date = "97b26e14-1185-4d6e-9259-6782b0475044",
  hostel = "773f3391-a8df-4a62-b4df-b239a8e24f1d",
  hostel_room = "11ef1db4-5fe0-4adb-bc03-ceafc7505689",
  lack_criminal_record_date = "642855c1-288b-4134-bd48-162f2d901aa4",
}

export const AdditionalFieldEnum = AdditionalFieldEnumList


export const nameAdditionalFields = {
  medical_date: "Срок действия справки о медицинском осмотре",
  lack_criminal_record_date: "Срок действия справки об отсутствии судимости",
}
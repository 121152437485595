import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DictFilial } from '../../models/dicts/dictfilial.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DictFilialService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictfilial}`;

  constructor(private http: HttpClient) { }

  //Get all filial
  public getAllfilial(): Observable<DictFilial[]> {
    return this.http.get<DictFilial[]>(this.baseUrl);
  }

  //Add filial
  addfilial(filial: DictFilial): Observable<DictFilial> {
    return this.http.post<DictFilial>(this.baseUrl, filial)
      .pipe(catchError(this.handleError));
  }

  //Delete filial
  deletefilial(id: string): Observable<DictFilial> {
    return this.http.delete<DictFilial>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit filial
  updatefilial(filial: DictFilial): Observable<DictFilial> {
    return this.http.put<DictFilial>(this.baseUrl + '/' + filial.filialId, filial)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

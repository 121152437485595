import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {handleError} from "../../../helpers/errorHandle-helper";
import {CompensationGuarantee} from "../../../models/mfc/dicts/compensation-guarantee.model";

@Injectable({
  providedIn: 'root'
})
export class CompensationGuaranteeService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/CompensationGuarantee/`;

  constructor(private http: HttpClient) { }

  public getCompensationGuarantees(): Observable<CompensationGuarantee[]> {
    return this.http.get<CompensationGuarantee[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }

  public getCompensationGuarantee(id: string): Observable<CompensationGuarantee> {
    return this.http.get<CompensationGuarantee>(`${this.baseUrl}${id}`);
  }

  public addCompensationGuarantee(compensationGuarantee: CompensationGuarantee): Observable<CompensationGuarantee> {
    return this.http.post<CompensationGuarantee>(this.baseUrl, compensationGuarantee)
      .pipe(catchError(handleError));
  }

  public updateCompensationGuarantee(compensationGuarantee: CompensationGuarantee): Observable<CompensationGuarantee> {
    return this.http.put<CompensationGuarantee>(this.baseUrl, compensationGuarantee)
      .pipe(catchError(handleError));
  }

  public removeCompensationGuarantee(id: string): Observable<CompensationGuarantee> {
    return this.http.delete<CompensationGuarantee>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

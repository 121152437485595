import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SocialNetworks } from '../../models/contingent/socialnetworks.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";

@Injectable({
  providedIn: 'root'
})
export class SocialnetworksService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.socialnetworks}`;

  constructor(private http: HttpClient) { }

  //Get all SocialNetworks
  public getAllSocialNetworks(): Observable<SocialNetworks[]> {
    return this.http.get<SocialNetworks[]>(this.baseUrl);
  }

  //Get By Id SocialNetworks
  getByIdSocialNetworks(id: string): Observable<SocialNetworks> {
    return this.http.get<SocialNetworks>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId
  getStudentSocialNetworks(id: string): Observable<SocialNetworks[]> {
    return this.http.get<SocialNetworks[]>(this.baseUrl + '/getsocialnetworksbystudpersonid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add SocialNetworks
  addSocialNetworks(SocialNetworks: SocialNetworks, studentId: string): Observable<SocialNetworks> {
    return this.http.post<SocialNetworks>(this.baseUrl + '/' + studentId, SocialNetworks)
      .pipe(catchError(this.handleError));
  }

  //Delete SocialNetworks
  deleteSocialNetworks(id: string, studentId: string): Observable<SocialNetworks> {
    return this.http.delete<SocialNetworks>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit SocialNetworks
  updateSocialNetworks(SocialNetworks: SocialNetworks, studentId: string): Observable<SocialNetworks> {
    return this.http.put<SocialNetworks>(this.baseUrl + '/' + SocialNetworks.socialNetworksExternalId + '/' + studentId, SocialNetworks)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}


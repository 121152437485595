import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictThematicRubricator} from "../../../models/publications/dict/thematicRubricator.model";

@Injectable({
  providedIn: 'root'
})
export class ThematicRubricatorsService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/GetGrnti/`;

  constructor(private http: HttpClient) {
  }

  public getThematicRubricators(): Observable<DictThematicRubricator[]> {
    return this.http.get<DictThematicRubricator[]>(this.baseUrl);
  }
}

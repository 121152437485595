import {GiaTabsEnumList} from "../../gia/enums/giaTabs.enum";

export interface GiaUserAccess {
  personId: string;
  isAdmin: boolean;
  giaSections: (string | null)[];
  studyForms: [];
  trainingLevels: [];
  departments: [];
  filials: [];
  giaProtocolDesigner: boolean;
  printTemplateAccess: boolean;
  dictCreating: boolean;
  dict: AccessRights;
  accessSettings: boolean;
  localSignatory: boolean;
}

export class GiaCurrentUserAccess {
  personId!: string;
  isAdmin!: boolean;
  accessSettings!: boolean;
  giaSections!: (number | null)[];
  studyForms!: [];
  trainingLevels!: [];
  departments!: [];
  filials!: [];
  giaProtocolDesigner!: boolean;
  printTemplateAccess!: boolean;
  dictCreating!: boolean;
  dict!: AccessRights;
  localSignatory!: boolean;
  diploma!: AccessRights;
}

export class GiaSectionFilter {
  studentId?: string;
  educationStandardId?: string;
  dictStudyFormId?: string;
  facultyId?: string;
  section!: GiaTabsEnumList
}

export enum AccessRights {
  Forbidden,
  Read,
  Write
}

export const accessRightEnum = AccessRights;

export enum DisplaySettingEnumList {
  fio = 1,
  fio_latin,
  gender ,
  citizenship,
  citizenship_type,
  compatriot,
  oksm,
  snils,
  inn,
  birthdate,
  marital_status,
  id,

  student_number,
  budget,
  budget_category,
  budget_sub_category,
  customer_targeted_training,
  employer,
  paid_training_agreement,
  faculty,
  course,
  group,
  education_standard,
  study_form,
  training_level,
  department,
  education_plan,
  education_program,
  academic_state,
  languages,
  elder,
  languages_level,
  pedagogical_head,
  external_id,
  scientific_adviser_tutor,
  average_score_total,
  average_score_semester,
  admission_year,
  graduate_year,
  score_number = 65,

  benefit = 40,
  health,
  sport_type,
  sport_category,

  personaldata,
  progress,
  achievements,
  orders,
  certificate,
  military,
  documents,
  targetedtraining,
  socialsphere,
  cards,
  scholarships,
  history,

  identification,
  education_doc,
  family_member,
  contacts,
  addresses,
  references,
  work,
  additional_info,
  other_documents,
  info_admissions_committee = 68,

  sed = 66,
  order_file,

  //Разделы персональных данных
  //Удостоверения личности
  pd_contractor = 69,
  //Документы об образовании
  pd_this_graduate,
  pd_faculty,
  pd_knowledge,
  pd_mark = 82,
  pd_study_form= 73,
  pd_qualification,
  pd_dissertation_year,
  pd_kandidate_disser_date = 87,
  pd_approve_vak_date = 88,
  pd_study_level = 76,
  pd_graduate_university,
  //Контакты
  pd_phone_type,
  pd_email_type,
  //Адреса
  pd_address,
  //Информация о родственниках
  pd_birthday,

  //Разделы Воинский учет
  military_certificate = 83,
  military_documents,
  military_form10 ,
  military_form452 ,
}

export const DisplaySettingEnum = DisplaySettingEnumList

import {Component, OnInit} from "@angular/core";
import {
  AvailableModule,
  Regulation,
  SimpleModuleEnum
} from "../../../models/oferta/regulation/regulation.model";
import {
  FormControl,
  FormGroup, Validators,
} from "@angular/forms";
import {DictRegulationType} from "../../../models/oferta/dicts/dictRegulationType.model";
import {FileRestrictions} from "@progress/kendo-angular-upload";
import {environment} from "../../../../environments/environment";
import {RegulationService} from "../../../services/oferta/regulation.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {saveAs} from "@progress/kendo-file-saver";
import {RegulationFileService} from "../../../services/oferta/regulationFile.service";
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {GetRegulationFile} from "../../../models/oferta/regulationFile/getRegulationFile.model";
import {DictRegulationTypeService} from "../../../services/oferta/dictRegulationType.service";
import {SimpleModulesEnum} from "../../../models/oferta/enums/simpleModuleEnum.enum";
import {SignatureTypeEnum} from "../../../models/oferta/enums/dictSignatureType.enum";
import {OfertaUserAccessService} from "../../../services/useraccess/oferta-user-access.service";
import {AccessRights} from "../../../models/oferta/enums/accessRights.enum";

@Component({
  selector   : 'regulationsettings',
  templateUrl: './regulation-settings.component.html',
  styleUrls  : ['./regulation-settings.component.scss']
})
export class RegulationSettingsComponent  implements OnInit {

  protected readonly tooltip = `Доступ к выбранным в данном поле модулям будет
    предоставлен только после присоединения пользователя к положению.`;

  protected editable = false;

  public regulation?: Regulation = undefined;
  public regulationTypes: DictRegulationType[] = [];
  public simpleModules: SimpleModuleEnum[] = [];
  public availableModules: AvailableModule[] = [];
  public selectRegulationType?: DictRegulationType = undefined;
  public selectSimpleModuleEnum?: SimpleModuleEnum = undefined;
  public regulationForm: FormGroup = this.getFormGroup();
  public settingsVis: boolean = false;
  public isReinforced: boolean = false;
  public isPerson: boolean = false;

  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: environment.contingent.maxFileSize,
    allowedExtensions: environment.oferta.ofertaFileAllowedExtensions,
  };

  protected readonly allowedExtensions: string = this.restrictions.allowedExtensions?.join(', ')!;

  public files?: Array<File>;

  constructor(
    private regulationTypeService: DictRegulationTypeService,
    private notificationsService: NotificationsService,
    private regulationService: RegulationService,
    private regulationFileService: RegulationFileService,
    private userAccessService: OfertaUserAccessService,
  ) { }

  public ngOnInit(): void {
    this.getAllRegulationTypes();
    this.getSimpleModules();
    this.getAllAvailableModules();
    this.getAccess();
  }

  public getAllRegulationTypes() {
    this.regulationTypeService.getAllRegulationTypes()
      .subscribe(
        response => {
          this.regulationTypes = response;
        }
      );
  }

  public getAllAvailableModules() {
    this.regulationService.getAllLicenses()
      .subscribe(
        response => {
          this.availableModules = response;
        }
      );
  }

  public getSimpleModules() {
    this.regulationService.getSimpleModules()
      .subscribe(
        response => {
          this.simpleModules = response;
        }
      );
  }

  public onChangeSignatureType(value: string)
  {
    this.selectRegulationType = this.regulationTypes.find(x => x.id == value);
    this.isReinforced = this.selectRegulationType?.userSignatureTypeEnum == SignatureTypeEnum.Reinforced;
    if (this.selectSimpleModuleEnum)
    {
      this.getRegulation();
      this.settingsVis = true;
    }
  }

  public onChangeSimpleModuleEnum(value: SimpleModulesEnum)
  {
    this.selectSimpleModuleEnum = this.simpleModules.find(x => x.value == value);
    this.getRegulation();
    this.settingsVis = true;
    this.isPerson = this.selectSimpleModuleEnum?.value == SimpleModulesEnum.LKP;
  }

  public getRegulation()
  {
    if (this.selectRegulationType && this.selectSimpleModuleEnum)
    {
      this.regulationService.getRegulation(this.selectRegulationType.id, this.selectSimpleModuleEnum.value)
        .subscribe(
          response => {
            this.regulation = response;
            this.regulationForm = this.getFormGroup();
          },
          error => {
            this.notificationsService.showError(error);
            this.settingsVis = false;
          }
        );
    }
  }

  public saveRegulation() {

    this.processRegulationInfo();

    if (this.regulationForm.invalid) {
      this.notificationsService.showError('Заполните обязательные поля');
      return;
    }

    const formValue = this.regulationForm.value;
    formValue.daysUntilBlocking = formValue.daysUntilBlocking ?? 0;

    this.regulationService.saveRegulation(this.regulationForm.value)
      .subscribe({
          next: () => {
            this.notificationsService.showSuccess('Сведения сохранены');
            this.getRegulation();
          },
          error: (error) => this.notificationsService.showError(error),
        }
      );
  }

  private processRegulationInfo() {
    if (this.regulationForm.value.regulationFile?.length) {
      const file = this.regulationForm.value.regulationFile[0];
      this.regulationForm.patchValue({
        regulationFile: file,
        regulationFileName: file.name
      });
    }

    if (this.regulationForm.value.statementFile?.length) {
      const file = this.regulationForm.value.statementFile[0];
      this.regulationForm.patchValue({
        statementFile: file,
        statementFileName: file.name
      });
    }
  }

  private getFileObserver(fileName?: string) {
    return {
      next: (response: Blob) => fileName && saveAs(response, fileName),
      error: (error: unknown) => this.notificationsService.showError(error),
    };
  }

  public getRegulationFile(file?: GetRegulationFile) {
    file && this.regulationFileService.getRegulationFile(file.id)
      .subscribe(this.getFileObserver(file.fileName));
  }

  public downloadFile(files?: File[]) {
    files?.length && DownloadFile(files[0]);
  }

  public removeFile(propertyName: string) {
    this.regulationForm.get(propertyName)?.patchValue('');
  }

  private getAccess() {
    this.userAccessService.getCurrentUserAccess().subscribe((response) =>
      this.editable = response?.userAccessRight.regulation === AccessRights.Write);
  }

  private getFormGroup() {
    return new FormGroup({
      id: new FormControl<string|null>(this.regulation?.id ?? null),
      moduleIds: new FormControl(this.regulation?.moduleIds ?? []),
      regulationName: new FormControl<string|null>(this.regulation?.regulationName ?? '', Validators.required),
      regulationFileName: new FormControl<string|null>(this.regulation?.regulationFileDto?.fileName ?? '', Validators.required),
      regulationFile: new FormControl(null),
      notificationText: new FormControl<string>(this.regulation?.notificationText ?? ''),
      statementText: new FormControl<string>(this.regulation?.statementText ?? ''),
      statementFileName: new FormControl<string>(this.regulation?.statementFileDto?.fileName ?? ''),
      statementFile: new FormControl(null),
      banText: new FormControl<string>(this.regulation?.banText ?? ''),
      daysUntilBlocking: new FormControl<number|null>(this.regulation?.daysUntilBlocking ?? null),
      recalculateDaysUntilBlocking: new FormControl<boolean>(false),
    });
  }
}

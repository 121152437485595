import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ActivatedRoute} from '@angular/router';
import { ParametersPersonService } from 'src/app/services/persondepartment/parameters-person.service';
import { Router} from "@angular/router";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { TimeZoneFix } from "src/app/helpers/date-helper"
import {CommonStatusEnum} from "../../../models/persondepartment/common-status.enum";

@Component({
    selector   : 'app-parameters-person',
    templateUrl: './parameters-person.component.html',
    styleUrls  : ['./parameters-person.component.scss']
})

export class ParametersPersonComponent implements OnInit{

    public personsData: any = [];

    public id: string = "";

    public commonStatus: number | undefined;
    public login: string = "";
    public male = { gender: "true" };
    public recordSourceName: string = "";
    public birthday: Date | undefined;
    public buhCode: string = "";
    public snils?: string;
    public mask = "000-000-000 00";

    public posts: any = [];
    public departments: any = [];
    public types: any = [];
    public scienceDegrees: any = [];

    public statuses: Array<{ statusName: string;statusId: number }> = [
        {statusName: "Действует", statusId: 1},
        {statusName: "Удален", statusId: 2},
        {statusName: "Неактивен", statusId: 3},
    ];

    public step2: boolean = false;
    public method1C: boolean = false;

    public personName = {firstName: "", lastName: "", middleName: " "};
    public experiences: any = [{experienceType: "Непрерывный стаж", years: 0, months: 0, days: 0,},
                               {experienceType: "Общий научно-педагогический стаж работы", years: 0, months: 0, days: 0,},
                               {experienceType: "Общий стаж",  years: 0, months: 0, days: 0,},
                               {experienceType: "Педагогический стаж",  years: 0, months: 0, days: 0,},
                               {experienceType: "Страховой стаж для оплаты больничных листов", years: 0, months: 0, days: 0,},
                               {experienceType: "Стаж на надбавку за выслугу лет", years: 0, months: 0, days: 0,}
                              ];
    public personSalaries = { post: "", salary: 1.00, isMainJob: false,  isOurPerson: false, buhCode: "",
                              salaryType: "", department: "", recordSource: 0};
    public personScientificDegrees = {scientificDegree: ""};

    public filterDepartments: any = [];
    public filterPosts: any = [];
    public filterDegrees: any = [];

    Form1 : FormGroup;
    Form2: FormGroup;

    public inactivePostId: string = "";
    public inactivePost: string = "";
    public inActive: boolean = true;

constructor(
    private notificationService: NotificationsService,
    private activatedroute: ActivatedRoute,
    private parametersPersonService: ParametersPersonService,
    private router: Router,
){
    this.Form1 = new FormGroup({
        firstName   : new FormControl('', [Validators.required]),
        lastName    : new FormControl('', [Validators.required]),
        login       : new FormControl('', [Validators.required]),
        post        : new FormControl('', [Validators.required]),
        department  : new FormControl('', [Validators.required]),
        commonStatus: new FormControl('', [Validators.required]),
    });

    this.Form2 = new FormGroup({
        birthday  : new FormControl('', [Validators.required]),
        salaryType: new FormControl('', [Validators.required]),
        salary    : new FormControl('', [Validators.required]),
        snils     : new FormControl('', [Validators.pattern("^[0-9 -]{11,14}$")]),
    })
}

ngOnInit() {

    this.getDepartments();
    this.getPosts();
    this.getScienceDegrees();
    this.getTypes();

}

    public getParametersPerson(id: any){
        this.parametersPersonService.getParametersPerson(id)
        .subscribe(response =>{
            this.personsData = response;
       //     console.log('данные на редактирование',this.personsData);

            this.id = this.personsData.externalId;

            this.personName = this.personsData.personName;
            this.male.gender = this.personsData.isMale.toString();
            this.login = this.personsData.contacts[0].loginName;
            this.recordSourceName = this.personsData.recordSourceName;

            if (this.recordSourceName == "1C") this.method1C = true;

            this.birthday = new Date(this.personsData.birthday);
            this.snils = this.personsData.snils;
            if (this.personsData.personScientificDegrees.length > 0) this.personScientificDegrees = this.personsData.personScientificDegrees[0];
            this.experiences = this.personsData.personExperiences;
            this.personSalaries = this.personsData.personSalaries[0];

            let datapost = this.personSalaries.post;
            if (this.posts.findIndex((el: any) => el.postName == datapost) == -1) {
                this.inactivePost = this.personsData.personSalaries[0].post
                this.inactivePostId = this.personsData.personSalaries[0].dictPostId;
                this.posts.push({'postName': this.inactivePost});
                this.inActive = false;
            }

            this.buhCode = this.personsData.personSalaries[0].buhCode.buhCode;
            if (this.personsData.personScientificDegrees.length > 0) this.personScientificDegrees = this.personsData.personScientificDegrees[0];
            this.commonStatus = this.personsData.commonStatus;
        });
    }

    public getPosts(){
      this.parametersPersonService.getPosts()
        .subscribe(response =>{
          this.posts = response;
          this.posts =  this.posts.filter((el:any) => el.postName !== null)
          this.filterPosts = this.posts;
          //   console.log(this.posts);

          let parameter = this.activatedroute.snapshot.params['id'];
          if (parameter)
            this.getParametersPerson(this.activatedroute.snapshot.params['id']);
          else{
            this.valueChangeStatus(CommonStatusEnum.Active)
            this.Form1.value.commonStatus = this.commonStatus
            this.Form1.reset(this.Form1.value)
          }
        });
    }

    public getDepartments(){
        this.parametersPersonService.getDepartments()
            .subscribe(response =>{
                this.departments = response;
                this.filterDepartments = this.departments;
         //       console.log(this.departments);
            });
    }

    public getScienceDegrees(){
        this.parametersPersonService.getScienceDegrees()
            .subscribe(response =>{
                this.scienceDegrees = response;
             //   console.log(this.scienceDegrees);
                this.filterDegrees = this.scienceDegrees;
            });
    }

    public getTypes(){
        this.parametersPersonService.getTypes()
            .subscribe(response =>{
                this.types = response;
     ///          console.log(this.types);
            });
    }

    public postFilter(value: any){
        this.filterPosts =   this.posts.filter(
            (s: any) => s.postName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    public departmentFilter(value: any){
        this.filterDepartments = this.departments.filter(
            (s: any) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    public degreeFilter(value: any){
        this.filterDegrees = this.scienceDegrees.filter(
            (s: any) => s.scientificDegreeName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }


    public stepTow(){
        this.parametersPersonService.checkLogin(this.login)
            .subscribe(response => {
                let validation: any = response;

                if (validation.isValidLogin == true) this.step2 = true;
                else  this.notificationService.showError("Такой логин уже существует");
            })
    }

    public stepOne(){
        this.step2 = false;
    }


    public save(){
        if (this.id == "") this.personSalaries.recordSource = 1;

        if (this.id !== "") {
            let newparam = {};
            newparam = {departmentId    : this.departments.find((el: any) => el.name == this.personSalaries.department).externalId,
                        dictPostId      : this.inActive ? this.posts.find((el: any) => el.postName == this.personSalaries.post).externalId : this.inactivePostId,
                        dictSalaryTypeId: this.types.find((el: any) => el.salaryTypeName == this.personSalaries.salaryType).externalId};
            Object.assign(this.personSalaries, newparam);
        }

        this.personSalaries.buhCode = this.buhCode;

      if(this.snils && this.snils.length === 11)
        this.snils = this.snils.replace(/(.{3})(.{3})(.{3})(.{2})/, '$1-$2-$3 $4')

        let data = {'personName': this.personName, 'isMale': this.male.gender == "true" ? true : false,  'login': this.login, 'commonStatus': this.commonStatus, 'birthday': TimeZoneFix(this.birthday),
                    'personExperiences': this.experiences, 'personSalaries': [this.personSalaries], 'snils': this.snils,
                    'personScientificDegrees': (this.personScientificDegrees.scientificDegree == "" || this.personScientificDegrees.scientificDegree == null) ? [] : [this.personScientificDegrees] };
      //console.log(data);
        if (this.id == '') {
            this.parametersPersonService.addParametersPerson(data)
                .subscribe(response => {
                    this.notificationService.showSuccess("Данные добавлены");
                    this.router.navigate(['technicaladministators/cadrebase']);
                })
        } else {
            this.parametersPersonService.checkLogin(this.login)
            .subscribe(response => {
                let validation: any = response;

                if (validation.isValidLogin == false && validation.personId !== this.id) {
                    this.notificationService.showError("Такой логин уже существует");
                }
                else {
                    this.parametersPersonService.updateParametersPerson(data, this.id)
                    .subscribe(response => {
                        this.getParametersPerson(this.id);
                        this.notificationService.showSuccess("Сохранено");
                        this.router.navigate(['technicaladministators/cadrebase']);
                    });
                }
            });
        }
    }

    public updateParameters(){

    }

    public changePost(){
        if (this.inactivePost !== "") {
            this.posts.splice(this.posts.findIndex((el: any) => el.postName == this.inactivePost), 1);
            this.personSalaries.post = "";
            this.inActive = true;
        }
    }

    public valueChangeStatus(value: any){
        this.commonStatus = value;
    }

    public onChangeYears(value: number, index: number) : void {
        if (value < 0 || value > 100) {
            this.experiences[index].years = 0;
            this.notificationService.showError("Количество лет введено некорректно");
        }
        else this.experiences[index].years = value;
    }

    public onChangeMonths(value: number, index: number) : void {
        if (value > 11 || value < 0 ) {
            this.experiences[index].months = 0;
            this.notificationService.showError("Количество месяцев введено некорректно");
        }
        else this.experiences[index].months = value;
    }

    public onChangeDays(value: number, index: number) : void {
        if (value > 30 || value < 0) {
            this.experiences[index].days = 0;
            this.notificationService.showError("Количество дней введено некорректно");
        }
        else this.experiences[index].days = value;
    }

}


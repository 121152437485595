import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DictRegulationType} from "../../models/oferta/dicts/dictRegulationType.model";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class DictSignatureTypeService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.oferta.dictRegulationType}`;

  constructor(private http: HttpClient) { }

  getAllRegulationTypes(): Observable<DictRegulationType[]> {
    return this.http.get<DictRegulationType[]>(this.baseUrl)
      .pipe(catchError(handleError));
  }

  updateSignatureType(dictSignatureType: DictRegulationType): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, dictSignatureType)
      .pipe(catchError(handleError));
  }
}

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictLaborRelation} from "../../../models/publications/dict/laborRelation.model";

@Injectable({
  providedIn: 'root'
})
export class LaborRelationsService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/LaborRelation`;

  constructor(private http: HttpClient) {
  }

  public getLaborRelations(): Observable<DictLaborRelation[]> {
    return this.http.get<DictLaborRelation[]>(this.baseUrl);
  }
}

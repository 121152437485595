<mfc-settings></mfc-settings>
<h1>Отчеты</h1>

<div class="action-bar">
    <kendo-dropdownlist
            [(ngModel)]="selectedReport"
            [data]="reports"
            [valuePrimitive]="true"
            valueField="name"
            textField="name">
    </kendo-dropdownlist>
    <button [disabled]="!selectedReport" (click)="openReport()" kendoButton themeColor="primary">Открыть</button>
</div>
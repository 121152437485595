import {Component} from "@angular/core";
import {breadcrumbItemsMap} from "src/app/models/home/breadcrumb-items.model";
import {boolOptions} from "src/app/models/mfc/enums/bool-options.enum";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApprovalStatus} from "../../../../models/mfc/dicts/approval-status.model";
import {ApprovalStatusService} from "../../../../services/mfc/dicts/approval-status.service";

@Component({
  selector: 'mfc-dict-application-type',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="approvalStatuses"
      [fieldsInfo]="info"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [pageable]="pagerSettings"
      [deletableField]="deletableField"
      (getMethod)="get()"
      (addMethod)="add($event)"
      (updateMethod)="update($event)"
      (removeMethod)="remove($event)">
    </app-dict>
  `
})
export class ApprovalStatusComponent {

  protected approvalStatuses: ApprovalStatus[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly deletableField = 'deletable';
  protected readonly info: FieldInfo<ApprovalStatus>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название',
      width: 300,
    },
    {
      field: 'yesText',
      type: FieldType.Textbox,
      title: 'Да',
      width: 300,
    },
    {
      field: 'noText',
      type: FieldType.Textbox,
      title: 'Нет',
      width: 300,
    },
    {
      field: 'notMarkedText',
      type: FieldType.Textbox,
      title: 'Не проставлено',
      width: 300,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 300,
    },
  ];

  protected formGroup(dataItem?: ApprovalStatus): FormGroup {
    // TODO: Добавить признак удаления
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      name: new FormControl(dataItem?.name, Validators.required),
      yesText: new FormControl(dataItem?.yesText, Validators.required),
      noText: new FormControl(dataItem?.noText),
      notMarkedText: new FormControl(dataItem?.notMarkedText, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber)
    });
  }

  constructor(private approvalStatusService: ApprovalStatusService) {}

  protected get() {
    this.approvalStatusService.getApprovalStatuses().subscribe(data =>
      this.approvalStatuses = data
    );
  }

  protected add({value, observer}: EventData<ApprovalStatus>) {
    this.approvalStatusService.addApprovalStatus(value).subscribe(observer);
  }

  protected update({value, observer}: EventData<ApprovalStatus>) {
    this.approvalStatusService.updateApprovalStatus(value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<ApprovalStatus>) {
    this.approvalStatusService.removeApprovalStatus(value.id).subscribe(observer);
  }
}

import { Component, OnInit, ViewChild, Renderer2  } from '@angular/core';
import { PagerType } from '@progress/kendo-angular-pager';
import { PagerPosition, GridComponent, CellClickEvent, EditEvent} from '@progress/kendo-angular-grid';
import { DepartmentsAdminService } from 'src/app/services/persondepartment/departments.service';
import { CompositeFilterDescriptor, filterBy, } from "@progress/kendo-data-query";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { Status, statuses } from "src/app/components/persondepartment/status";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { getDayStart } from 'src/app/helpers/date-helper';
import { FilialService } from 'src/app/services/persondepartment/filial.service';
import { Filial } from 'src/app/models/persondepartment/filial.model';

@Component({
    selector   : 'app-departments',
    templateUrl: './departments.component.html',
    styleUrls  : ['./departments.component.scss']
})

export class DepartmentsAdminComponent implements OnInit{

    public departments: any = [];

    public loading = false;
    public skip = 0;
    public pageSizes = [10, 20, 30];
    public pageSize = 10;
    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public previousNext = true;
    public position: PagerPosition = 'bottom';
    public info = true;
    public loaderExcelExport = false;
    public filials!: Filial[];
    public types: any = [];
    public parentDepartment: any = [];

    public filterData: any = [];
    public filterTypes: any = [];
    public statuses = statuses;

    public positionEdit: "top" | "bottom" | "both" = "top";

    public formGroup: FormGroup | undefined;
    private isLine = false;
    @ViewChild(GridComponent) private grid!: GridComponent;
    public editedRowIndex: number | undefined;
    private isNew = false;
    public get isInEditingMode(): boolean{
        return this.editedRowIndex !== undefined || this.isNew;
    }

    public typeNameEdit: string = "";
    public parentDepartmentEdit: string = "";
    public sNameEdit: string = "";
    public externalId: string = "";
    public filialIdEdit: string = "";

    public exists: boolean = false;
    public empty: boolean = false;

constructor(
    private departmentsAdminService: DepartmentsAdminService,
    private notificationService: NotificationsService,
    private renderer: Renderer2,
    private filialService: FilialService
){}

ngOnInit() {
    this.getDepartments();
    this.getTypes();
    this.getAllFilials();

    this.renderer.listen("document", "click", ({ target }) => {
        if (!isChildOf(target, "k-touch-action-auto") && !isChildOf(target, "editRecord") || target.outerHTML.indexOf('reset') !== -1 )
        {
          this.cancelHandler();
        }
    });
}

    public getDepartments(){
        this.departmentsAdminService.getAllDepartments()
            .subscribe(response =>{
                this.departments = response;
            //console.log(this.departments);

                let parent = [...new Set(this.departments.map((n:any) => n.parentDepartmentId))];
                this.parentDepartment = parent.map((parentDepartmentId, index) => {
                    return {
                      parentDepartmentId  : parentDepartmentId,
                      parentDepartmentName: this.departments.find((el:any) => el.parentDepartmentId ==  parentDepartmentId).parentDepartmentName,
                    }
                });
                this.parentDepartment = this.parentDepartment.filter((el: any) => el.parentDepartmentName !== null);

                for (let i=0; i< this.departments.length; i++){
                    this.departments[i].createdAt = getDayStart(this.departments[i].createdAt);
                    this.departments[i].updatedAt = getDayStart(this.departments[i].updatedAt);
                }

            })
    }

    public getTypes(){
        this.departmentsAdminService.getDepartmentsType()
            .subscribe( response => {
                this.types = response;
                this.filterTypes = this.types.filter((el: any) => el.commonStatus !== 2);
                this.types = this.types.filter((el: any) => el.commonStatus == 1);
            });
    }

    public getAllFilials() {
        this.filialService.getAllFilials()
            .subscribe(
            response => {
                this.filials = response;
            }
        );
    }

    public filter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
      };

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        this.loadData();
    }

    public loadData(): void {
        this.filterData = filterBy(this.departments, this.filter);
        this.filterData = orderBy(this.filterData, this.sort);
    }

    public exportToExcel(grid: GridComponent): void {
        this.loaderExcelExport = true
        this.allData = this.allData.bind(this);
        if (grid != undefined) grid.saveAsExcel();

        this.getDepartments();
        this.filterChange(this.filter);
    }

    public allData = (): any => {
        if (this.sort[0].field == "" && this.filter.filters.length == 0) {
            this.filterData = this.departments;
        }
        for (let element of this.filterData ){
            element.commonStatus = this.Status(element.commonStatus);
        }
        let response = {
          data: this.filterData,
          total: this.filterData.length
        };
        this.loaderExcelExport = false
        return response;
    };

    public sort: SortDescriptor[] = [
        {
          field: "",
          dir: "asc",
        },
    ];

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;
        this.loadData();
    }

    public Status(value:any){
        return Status(value);
    }

    public editHandler(value: EditEvent){
        if (this.isLine) {
            this.cancelHandler();
            return;
        }

        this.isLine = true;
        this.externalId = value.dataItem.externalId;
        this.typeNameEdit = value.dataItem.typeId;
        this.parentDepartmentEdit =  value.dataItem.parentDepartmentId;
        this.sNameEdit = value.dataItem.sName;
        this.editedRowIndex = value.rowIndex;
        this.filialIdEdit = value.dataItem.filialId;

        this.formGroup = formGroup(value.dataItem);
        value.sender.editRow(value.rowIndex, this.formGroup );
    }

    public cancelHandler(){
        this.isLine = false;
        this.empty = false;
        this.closeEditor(this.grid, this.editedRowIndex);
    }

    private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
        ): void {
          this.isNew = false;
          grid.closeRow(rowIndex);
          this.editedRowIndex = undefined;
          this.formGroup = undefined;
    }

    public onChange(value: any){
        if (value.trim() == '')  this.empty = true;
        else this.empty = false;
    }

    public saveCurrent(){
        if (this.formGroup !== undefined) {

            this.exists = false;

            let sName = this.formGroup.value.sName.toLowerCase().trim();
            let typeNameId = this.formGroup.value.typeId;

            let departmentsFilter = this.departments.filter((el: any) => el.sName !== null);
            let findSName = departmentsFilter.find((item:any) => item.sName.toLowerCase().trim() == sName );

            if (departmentsFilter.findIndex((item:any) => item.sName.toLowerCase().trim() == sName ) !== -1 && this.formGroup.value.sName.toLowerCase().trim() !== '' &&
                 findSName.externalId !== this.externalId && findSName.typeId == typeNameId) {

                this.notificationService.showError("Невозможно сохранить, так как такое сокращенное название уже существует");
                this.exists = true;
            }

            if (this.exists == false){
                this.departmentsAdminService.updateDepartments(this.formGroup.value, this.externalId)
                .subscribe( response => {
                    this.getDepartments();
                    this.notificationService.showSuccess("Сохранено");
                  },
                  error => {
                        if (error.status == 409) {
                            let index1 = error.error.indexOf(' ');
                            let index2 = error.error.indexOf('InnerException:');
                            let textError = error.error.slice(index1, index2);
                            this.notificationService.showError(textError);
                        } else this.notificationService.showError("Не удалось изменить запись");
                  });
            }
        }
        this.closeEditor(this.grid, this.editedRowIndex);
    }
}

const formGroup = (dataItem: {departmentSName?: any; departmentType?: any; commonStatus?: any; parentDepartmentId?: any; serialNumber?: any; filialId?: any}) =>
  new FormGroup({
    sName: new FormControl(dataItem.departmentSName, Validators.required),
    typeId: new FormControl(dataItem.departmentType, Validators.required),
    parentDepartmentId: new FormControl(dataItem.parentDepartmentId),
    commonStatus: new FormControl(dataItem.commonStatus, Validators.required),
    serialNumber: new FormControl(dataItem.serialNumber),
    filialId: new FormControl(dataItem.filialId),
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

import {Component, OnInit} from '@angular/core';
import {items} from 'src/app/models/mfc/menu-items';
import {UserAccessService} from '../../../services/mfc/userAccess.service';
import {DictCreatorService} from '../../../services/mfc/dict-creator.service';


@Component({
  selector: 'mfc-settings',
  templateUrl: './mfc-settings.component.html',
  styleUrls: ['./mfc-settings.component.scss']
})
export class MFCSettingsComponent implements OnInit {
  //Menu items
  protected items = items;
  private currentUser = this.userAccessService.currentUserAccess$.value;

  constructor(
    private userAccessService: UserAccessService,
    private dictCreatorService: DictCreatorService
  ) { }

  ngOnInit() {
    this.getUserAccess();
  }

  private getUserAccess() {
    const accessRights = this.currentUser?.user.userAccessMainSettings;
    this.items[0].items = items[0].items.filter((item: any) => {
      return (accessRights?.isReportAvailable ? item : !item.reports)
        && (accessRights?.isAccessSettingsAvailable || this.currentUser?.isAdmin ? item : !item.accessSettings)
        && (accessRights?.isApplicationConstructorAvailable ? item : !item.applicationConstructor)
        && (accessRights?.isApplicationConstructorTemplateAvailable ? item : !item.templates)
        && (accessRights?.isSignStampSettingsAvailable ? item : !item.stamp)
        && (accessRights?.isDictAvailable ? item : !item.dicts)
        && (accessRights?.isDictCreateAvailable ? item : !item.dictCreator)
    });

    if (!this.dictCreatorService.dictsLoaded && (accessRights?.isDictAvailable)) {
      this.addDynamicDictItems();
    }
  }

  private addDynamicDictItems() {
    const dictIndex = this.items[0].items.findIndex(item => item.dicts);
    const dynamicDicts = this.dictCreatorService.dicts$.value.map(a =>
      ({text: a.name, path: `/mfc/dict/${a.id}`})
    );
    this.items[0].items[dictIndex].items = this.items[0].items[dictIndex].items?.concat(dynamicDicts);
    this.dictCreatorService.dictsLoaded = true;
  }
}

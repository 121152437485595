import {CellOptions} from '@progress/kendo-angular-excel-export';

export const headerCellOptions: CellOptions = {
  background: '#FFF',
  color: '#000',
  borderTop: {color: '#000'},
  borderBottom: {color: '#000'},
  borderLeft: {color: '#000'},
  borderRight: {color: '#000'},
};

export const cellOptions: CellOptions = {
  borderTop: {color: '#000'},
  borderBottom: {color: '#000'},
  borderLeft: {color: '#000'},
  borderRight: {color: '#000'},
};

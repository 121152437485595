import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CellClickEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { lastValueFrom } from 'rxjs';
import { DictWorkCategorySchedule } from 'src/app/models/education/dictWorkCategorySchedule.model';
import { DictWorkScheduleStudyProcessType } from 'src/app/models/education/dictWorkScheduleStudyProcessType.model';
import { ProcessScheduleModels } from 'src/app/models/education/process-schedule.model';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { DictWorkCategoryScheduleService } from 'src/app/services/education/DictWorkCategorySchedule/dict-work-category-schedule.service';
import { DictWorkScheduleStudyProcessTypeService } from 'src/app/services/education/DictWorkScheduleStudyProcessType/dict-work-schedule-study-process-type.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ProcessScheduleService } from 'src/app/services/education/ProcessSchedule/process-schedule.service';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import * as moment from "moment";
import {EducationUserAccessService} from "../../../../../services/useraccess/education-user-access.service";
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import {Location} from '@angular/common';
import {FilialService} from "src/app/services/education/Filial/filial.service";
import {Filial} from "src/app/models/education/filial.model";

@Component({
  selector: 'global-schedule-settings',
  templateUrl: './global-schedule-settings.component.html',
  styleUrls: ['./global-schedule-settings.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class GlobalScheduleSettingsComponent implements OnInit {
  public trainingLevelId: string = '';
  public traininglevels: TrainingLevel[] = [];
  public filialId: string = '';
  public filials: Filial[] = [];
  public settingsFormGroup: FormGroup = new FormGroup({});
  public settings: ProcessScheduleModels.GlobalSettings | null = null;
  public workTypes: ProcessScheduleModels.GlobalSettingsWorkType[] = [];
  public formGroup: FormGroup = new FormGroup({});
  private editedRowIndex: number | undefined;
  public isEditingDefault = false;
  public isEditing = false;
  public loading = false;
  @ViewChild(GridComponent) private grid!: GridComponent;

  public dictWorkCategorySchedules: DictWorkCategorySchedule[] = [];
  public dictWorkScheduleStudyProcessTypes: DictWorkScheduleStudyProcessType[] = [];

  constructor(
    activateRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    public processScheduleService: ProcessScheduleService,
    private notificationService: NotificationsService,
    private trainingLevelService: TrainingLevelService,
    private dictWorkCategoryScheduleService: DictWorkCategoryScheduleService,
    private dictWorkScheduleStudyProcessTypeService: DictWorkScheduleStudyProcessTypeService,
    private userAccessService:EducationUserAccessService,
    private location: Location,
    private filialService: FilialService,
  ) { }

  ngOnInit() {
    this.getAccessLevel();
    this.trainingLevelService.getAllTrainingLevels().subscribe(traininglevels => {
      this.traininglevels = traininglevels;
      const trainingLevelId = traininglevels[0]?.trainingLevelExternalId;
      this.filialService.getAllFilials().subscribe(filials => {
        this.filials = filials;
        const filialId = filials[0]?.filialId;
        trainingLevelId && filialId && this.getGlobalSettings(trainingLevelId, filialId);
      });
    });

    this.settingsFormGroup = new FormGroup({
      dictTrainingLevelId: new FormControl(),
      filialId: new FormControl(),
      countDaysInWeekPart: new FormControl(),
      showDatesInGraph: new FormControl(),
      considerHolidays: new FormControl(),
      studyDateBeginDefault: new FormControl(),
    })
    this.dictWorkCategoryScheduleService.getAllWorkCategorySchedules().subscribe(
      _ => this.dictWorkCategorySchedules = _.sort((a, b) => (a.dictWorkCategoryScheduleName < b.dictWorkCategoryScheduleName ? -1 : 1))
    )
    this.dictWorkScheduleStudyProcessTypeService.allWorkScheduleStudyProcessTypes$.subscribe(
      _ => this.dictWorkScheduleStudyProcessTypes = _
    )
  }

  trainingLevelChange(trainingLevelId: string) {
    this.getGlobalSettings(trainingLevelId, this.filialId);
  }

  filialChange(filialId: string) {
    this.getGlobalSettings(this.trainingLevelId, filialId);
  }

  getGlobalSettings(trainingLevelId: string, filialId: string): void {
    this.loading = true;
    this.trainingLevelId = trainingLevelId;
    this.filialId = filialId;
    this.processScheduleService.getGlobalSettings(trainingLevelId, filialId).subscribe(data => {
      let date = new Date(data.studyDateBeginDefault);
      //фикс года, если дата пустая
      if(date.getFullYear()<2000)
        date.setFullYear(new Date().getFullYear());
      this.settingsFormGroup.patchValue({ ...data, studyDateBeginDefault: date });
      this.loading = false;
      this.settings = data;
      this.workTypes = data.workTypes;
    })
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = !response.dictAccessLevel;
    },error => {});
  }

  public addHandler(): void {
    this.closeEditor();
    this.isEditing = true;
    this.formGroup = this.createFormGroup({});
    this.grid.addRow(this.formGroup);
  }

  public editHandler({
    sender,
    isEdited,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isEditing) return;
    if(!this.editable) return;
    this.closeEditor();
    this.formGroup = this.createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;
    this.isEditing = true;
    sender.editRow(rowIndex, this.formGroup);
  }

  public createFormGroup(dataItem: Partial<ProcessScheduleModels.GlobalSettingsWorkType>): any {
    const {
      dictWorkScheduleStudyProcessTypeId,
      name = '',
      shortName = '',
      symbolMMIS = '',
      symbol = '',
      displayedValue = '',
      color = '#fff',
      dictWorkCategoryScheduleExternalId,
      isInvolvedCalculating = false,
      dictWorkCategoryScheduleName = '',
    } = dataItem
    return new FormGroup({
      dictWorkScheduleStudyProcessTypeId: new FormControl(dictWorkScheduleStudyProcessTypeId/*, Validators.required*/),
      name: new FormControl(name, Validators.required),
      shortName: new FormControl(shortName),
      symbolMMIS: new FormControl(symbolMMIS),
      symbol: new FormControl(symbol),
      displayedValue: new FormControl(displayedValue),
      color: new FormControl(color),
      dictWorkCategoryScheduleExternalId: new FormControl(dictWorkCategoryScheduleExternalId),
      isInvolvedCalculating: new FormControl(isInvolvedCalculating),
      dictWorkCategoryScheduleName: new FormControl(dictWorkCategoryScheduleName),
    });
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    if (this.isEditing) return;
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Удалить?",
      actions: [{ text: "Нет" }, { text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) { }
      else {
        if (result.text == "Да") {
          this.workTypes.splice(this.workTypes.indexOf(dataItem), 1)
          this.onSave()
        }
      }
    });
  }

  public closeEditor(): void {
    this.isEditing = false;
    this.grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
  }

  public setWorkScheduleStudyProcessType(value: any): void {
    this.formGroup.patchValue({ name: value.name })
  }

  public setWorkScheduleStudyProcessCategory(value: any): void {
    this.formGroup.patchValue({ dictWorkCategoryScheduleName: value.dictWorkCategoryScheduleName })
  }

  public saveWorkType(): void {
    if (this.editedRowIndex === undefined) {
      this.workTypes.unshift(this.formGroup.value)
    } else {
      this.workTypes[this.editedRowIndex] = this.formGroup.value
    }
    this.closeEditor();
    this.onSave();
  }

  onEditDefault(): void {
    this.isEditingDefault = true
  }

  onCancel(): void {
    this.isEditingDefault = false
  }

  async onSave() {
    if (this.trainingLevelId === '') return;
    try {
      await lastValueFrom(this.processScheduleService.saveGlobalSettings(this.trainingLevelId, {
        ...this.settingsFormGroup.value,
        studyDateBeginDefault: moment(this.settingsFormGroup.value.studyDateBeginDefault).utc(true),
        workTypes: this.workTypes,
      }))
      this.notificationService.showSuccess("Настройки сохранены");
      //this.dictWorkScheduleStudyProcessTypeService.updateAllWorkScheduleStudyProcessTypes();

      this.isEditingDefault = false
    } catch(e) {
      this.notificationService.showError("Не удалось сохранить настройки");
      //console.error('Error saving to localStorage', e);
    }
    this.getGlobalSettings(this.trainingLevelId, this.filialId)
  }

  goBack(){
    this.location.back();
  }
}

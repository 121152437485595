import {Component, OnInit, ViewChild} from '@angular/core';
import { CellClickEvent, GridComponent } from "@progress/kendo-angular-grid";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AchievementsStatuses } from 'src/app/models/contingent/achievementsstatuses.model';
import { AchievementsStatusesService } from 'src/app/services/contingent/achievementsstatuses.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
    selector: 'app-achievementsstatuses',
    templateUrl: './achievementsstatuses.component.html',
    styleUrls: ['./achievementsstatuses.component.scss']
  })

export class AchievementsStatusesComponent implements OnInit {

    public achievementsstatuses: AchievementsStatuses[] = [];

    public pageSize = 10;
    public sizes = [10, 20, 50];

    public formGroup: UntypedFormGroup | undefined;
    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean {
        return this.editedRowIndex !== undefined;
    }
    private editedRowIndex: number | undefined;
    private isLine = false;

    public dictAchievementStatusExternalId: string = "";

    constructor(
        private achievementsStatusesService: AchievementsStatusesService,
        private notificationService: NotificationsService,
    ) {}

    public ngOnInit(): void {
        this.getStatuses();
    }

    public getStatuses() {
        this.achievementsStatusesService.getAchievementsStatuses()
            .subscribe(response =>{
                this.achievementsstatuses = response;
            })
    }

    public editHandler({
            sender,
            rowIndex,
            dataItem,
        }: CellClickEvent): void {
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
             return;
        }

        this.isLine = true;
   //     this.saveRow();
        this.formGroup = formGroup(dataItem);
        this.editedRowIndex = rowIndex;
        this.dictAchievementStatusExternalId = dataItem.id;

        sender.editRow(rowIndex, this.formGroup);
    }

    public saveCurrent() {
        if (this.formGroup && !this.formGroup.valid) return;

        this.isLine = false;
        this.saveRow();
    }

    private saveRow(): void {
        if (this.formGroup == undefined) return;

        if (this.isInEditingMode) {
            this.achievementsStatusesService.updateAchievementsStatuses(this.formGroup.value, this.dictAchievementStatusExternalId)
                .subscribe(
                () => {
                    this.getStatuses();
                    this.notificationService.showSuccess("Сохранено");
                },
                () => {
                    this.notificationService.showError("Не удалось изменить запись");
                }
                );
        }

        this.closeEditor(this.grid, this.editedRowIndex)
    }

    public cancelHandler() {
        this.closeEditor(this.grid);
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        grid.closeRow(rowIndex);
        this.isLine = false;
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

}

const formGroup = (dataItem: {name: string; color: string; order?: number;}) =>
  new UntypedFormGroup({
    name: new UntypedFormControl(dataItem.name, Validators.required),
    color: new UntypedFormControl(dataItem.color, Validators.required),
    order: new UntypedFormControl(dataItem.order),
  });

import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {dictPagerSettings} from "../../../../models/dicts/pagerSettings.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";
import {getData$} from "../../../../../environments/environment";
import {openDialog} from "../../../../helpers/dialogHelper";
import {DictDiplomaTypeMethods} from "./dict-diploma-type.methods";
import {DictDiplomaTypeService} from "../../../../services/gia/dict-diploma-type.service";
import {DiplomaTypeDto} from "../../../../models/gia/dicts/dictDiplomaType.model";
import {DictDto} from "../../../../models/gia/dicts/dicts.model";
import {DictsService} from "../../../../services/gia/dicts.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {diplomaTypeEnumList} from "../../../../models/gia/enums/diploma-type.enum";
import {AccessRights} from "../../../../models/useraccess/gia/giaUserAccess";
import {SortDescriptor} from "@progress/kendo-data-query";

@Component({
  selector: 'app-dict-diploma-type',
  templateUrl: './dict-diploma-type.component.html',
  styleUrls: ['./dict-diploma-type.component.scss']
})
export class DictDiplomaTypeComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  // Pager settings
  public pagerSettings: PagerSettings = dictPagerSettings;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public group = [{ field: "trainingLevelName"}];
  public sort: SortDescriptor[] = [{ field: "", dir: "asc", }];
  public isNew: boolean = false;
  public isLine: boolean = false;
  private editedRowIndex: number | undefined;

  public diplomaTypes: DiplomaTypeDto[] = [];
  public trainingLevels: DictDto[] = [];

  public formGroup: FormGroup = this.resetFormGroup;
  public editable = this.userAccessService.currentUserAccess$.value.dict === AccessRights.Write;

  constructor(private activateRoute: ActivatedRoute,
              private dialogService: DialogService,
              private dictMethods: DictDiplomaTypeMethods,
              private userAccessService: GiaUserAccessService,
              private diplomaTypeService: DictDiplomaTypeService,
              private dictService: DictsService,) {
  }

  async ngOnInit() {
    getData$.subscribe(() => this.getDiplomaType());
    this.getTrainingLevels();
  }

  private getDiplomaType() {
    this.diplomaTypeService.getDiplomaTypes().subscribe(value => this.diplomaTypes = value);
  }

  public nameEnum(enums?: number) {
    return this.diplomaTypeEnumList.find((x) => x.value === enums)?.text ?? "";
  }

  private getTrainingLevels() {
    this.dictService.getTrainingLevels().subscribe((response) => {
      this.trainingLevels = response;
    });
  }

  private get resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      trainingLevelId: new FormControl(null, Validators.required),
      value: new FormControl(null)
    })
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  public addRow() {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      trainingLevelId: new FormControl(null, Validators.required),
      value: new FormControl(null)
    })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public async saveRow()  {
    if (this.formGroup) await this.dictMethods.saveDict(this.isNew, this.formGroup);
    this.onCancel();
  }

  public async editRow({ sender, rowIndex, dataItem, columnIndex }: CellClickEvent) {
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name),
      trainingLevelId: new FormControl(dataItem.trainingLevelId),
      value: new FormControl(dataItem.value),
    })

    if (this.isNew || !this.editable || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.onCancel();
    this.isLine = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}?"`);
    this.dictMethods.remove(dialog.result, dataItem.id);
  }

  protected readonly diplomaTypeEnumList = diplomaTypeEnumList;
}

import {Dict} from '../../dict.model';
import {KendoProperties} from '../../enums/kendo-properties.enum';
import {ApplicationPropertyValue} from '../../applicationForm/application-property-value.model';
import {DictProperty} from "../../dicts/property.model";

export interface TabsSettings {
  externalId: string
  name: string;
  sortNumber: number;
  groupProperties: GroupProperty[];
  displayInAddForm: boolean;
}
// TODO: начало - вынести в модели в applicationConstructor/form
export class TabsSettingsDto {
  name = '';
  sortNumber = -1;
  groupProperties: GroupPropertyDto[] = [];
}

export class GroupPropertyDto {
  name: string = '';
  sortNumber = -1;
  fieldCodes?: string[] = [];
  properties?: PropertyDto[] = [];
  additionalProperties: AdditionalPropertyDto[] = [];
}

export class PropertyDto {
  required: boolean = false;
  sortNumber: number = 0;
  isEditableForAll: boolean = false;
  isEditableForInitiator: boolean = false;
  roleIds: string[] = [];
  tooltip?: string = '';
  name: string = '';
  dictPropertyId: string = '';
  dictProperty?: DictProperty;
}

export class AdditionalPropertyDto {
  required = false;
  sortNumber = 1;
  isEditableForAll= false;
  isEditableForInitiator = false;
  roleIds: string[] = [];
  tooltip: string = '';
  additionalDictProperty: AdditionalDictPropertyDto = new AdditionalDictPropertyDto();
}

export class AdditionalDictPropertyDto {
  propertyTypeEnum = KendoProperties.Textbox;
  name = '';
  printKey = '';
  dictId?: string | null = null;
  parentPropertyId?: string | null = null;
}
// TODO: конец

export class GroupProperty {
  externalId: string = '';
  name: string = '';
  sortNumber: number = 0;
  fieldCodes?: FieldCodes[] = [];
  standardProperties: Property[] = [];
  additionalProperties: Property[] = [];
}

export interface Property {
  externalId: string;
  required: string;
  sortNumber: number;
  tooltip?: string;
  name: string;
  propertyTypeEnum: KendoProperties;
  isEditableForAll: boolean;
  isEditableForInitiator: boolean;
  roleIds: string[];
  dict?: Dict[];
  value?: ApplicationPropertyValue;
}

export interface FieldCodes {
  code: string;
  value?: string;
}

import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from '../../helpers/errorHandle-helper';
import {MilitaryTemplate, MilitaryTemplateForm} from '../../models/contingent/militarytemplate.model';
import {isArray} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MilitaryTemplateService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.militaryformprinttemplate}`;

  constructor(private http: HttpClient) { }

  public getMilitaryTemplates(): Observable<MilitaryTemplate[]> {
    return this.http.get<MilitaryTemplate[]>(`${this.baseUrl}/GetAll`);
  }

  private getFormData(body: MilitaryTemplateForm) {
    const data = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      if (isArray(value)) {
        data.append('file', value[0]);
      } else {
        if (key !== 'file') {
          data.append(key, value);
        }
      }
    });
    return data;
  }

  public addMilitaryTemplate(template: MilitaryTemplateForm): Observable<MilitaryTemplateForm> {
    const formData = this.getFormData(template);
    return this.http.post<MilitaryTemplateForm>(`${this.baseUrl}/Add`, formData)
      .pipe(catchError(handleError));
  }

  public updateMilitaryTemplate(template: MilitaryTemplateForm): Observable<MilitaryTemplateForm> {
    const formData = this.getFormData(template);
    return this.http.put<MilitaryTemplateForm>(`${this.baseUrl}/Update/${template.externalId}`, formData)
      .pipe(catchError(handleError));
  }

  public deleteMilitaryTemplate(id: string): Observable<MilitaryTemplate> {
    return this.http.delete<MilitaryTemplate>(`${this.baseUrl}/Delete/${id}`)
      .pipe(catchError(handleError));
  }

  public getTemplateFile(templateId: string) {
    return this.http.get(`${this.baseUrl}/GetFile/${templateId}`, {responseType: 'blob'})
      .pipe(catchError(handleError));
  }
}





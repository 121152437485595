import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {handleError} from '../../../helpers/errorHandle-helper';
import {TypeReceipt} from '../../../models/mfc/dicts/type-receipt.model';


@Injectable({
  providedIn: 'root'
})
export class TypeReceiptService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/TypeReceipt/`;

  constructor(private http: HttpClient) { }

  public getAll(): Observable<TypeReceipt[]> {
    return this.http.get<TypeReceipt[]>(this.baseUrl);
  }

  public getById(id: string): Observable<TypeReceipt> {
    return this.http.get<TypeReceipt>(`${this.baseUrl}${id}`);
  }

  public addTypeReceipt(typeReceipt: TypeReceipt): Observable<TypeReceipt> {
    return this.http.post<TypeReceipt>(this.baseUrl, typeReceipt)
      .pipe(catchError(handleError));
  }

  public updateTypeReceipt(typeReceipt: TypeReceipt): Observable<TypeReceipt> {
    return this.http.put<TypeReceipt>(this.baseUrl, typeReceipt)
      .pipe(catchError(handleError));
  }

  public removeTypeReceipt(id: string): Observable<TypeReceipt> {
    return this.http.delete<TypeReceipt>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

import {Injectable} from "@angular/core";
import {HttpClient } from "@angular/common/http";
import {catchError, Observable } from "rxjs";
import {AddNewRequest, StudentInfoCards, StudentInfoReestr, UpdateRequest} from "../../models/cards/studentinfocards";
import {environment} from "../../../environments/environment";
import {Guid} from "guid-typescript";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class CardsService {
  baseUrl: string = `${environment.cardsApiEndpoint}${environment.apiPaths.cards.cardRequest}`;

  constructor(private http: HttpClient) { }

  public getCardsRequests(): Observable<StudentInfoCards[]> {
    return this.http.get<StudentInfoCards[]>(`${this.baseUrl}/GetCardRequests`)
      .pipe(
        catchError(handleError));
  }

  public getCardRequest(studentId: Guid): Observable<StudentInfoCards[]> {
    return this.http.get<StudentInfoCards[]>(`${this.baseUrl}/GetCardRequest?studentId=${studentId}`)
        .pipe(
            catchError(handleError));
  }

  public getCardRequestsToReestr(reestrId: Guid): Observable<StudentInfoReestr[]> {
    return this.http.get<StudentInfoReestr[]>(`${this.baseUrl}/GetCardRequestsToReestr/${reestrId}`)
      .pipe(
        catchError(handleError));
  }

  public addNewCardRequest(addNewRequest: AddNewRequest) {
    return this.http.post<StudentInfoReestr[]>(`${this.baseUrl}/AddCardRequest`, addNewRequest)
      .pipe(
        catchError(handleError));
  }

  public deleteCardRequest(studPersonId: Guid, reestrId: Guid, salaryProjectId: Guid) : Observable<StudentInfoReestr[]> {
    return this.http.delete<StudentInfoReestr[]>(`${this.baseUrl}/DeleteCardRequest?studPersonId=${studPersonId}&reestrId=${reestrId}&salaryProjectId=${salaryProjectId}`)
      .pipe(
        catchError(handleError));
  }

  public updateCardRequest(updateRequest: UpdateRequest) {
    return this.http.put(`${this.baseUrl}/UpdateCardRequest`, updateRequest)
      .pipe(
        catchError(handleError));
  }
}

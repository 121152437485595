import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { items } from "../../../models/classroom/menu-items";
import { DrawerItem, DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { TokenStorageService } from 'src/app/services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { Role } from 'src/app/models/useraccess/role';
import { checkRole } from "../../../helpers/token/role-check";
import { ClassRoomUserAccessService } from "../../../services/useraccess/classroom-user-access.service";

@Component({
  selector: 'classroom-header',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})

export class ClassroomLayoutComponent implements OnInit{
  //title = 'ContingentWebApp';

  //Menu items
  public items: any[] = items;
  public isMainPage: boolean = this.router.url.endsWith('/classroom');

  public isAdmin: boolean;

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  public personId: string = "";
  public settings: boolean = false;

  constructor(private router: Router,
              private jwtHelper: JwtHelperService,
              private userAccessService: ClassRoomUserAccessService,
              private tokenStore: TokenStorageService,
              private personService: PersonService,)
  {
      this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

    ngOnInit(): void {
       this.getAccessLevel();
    //   this.accessSetting();
    }

    public getAccessLevel(){
      this.userAccessService.getAccessLevel()
      .subscribe({
        next: response => {
          if(this.isAdmin == true) this.accessSetting(true)
          else this.accessSetting(!response.settingsAccess);
        },
        error:() => {
          this.accessSetting(true)
        }
      });
    }


  public accessSetting(settings: boolean){
    
    // Hide admin items
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null)
    {
      user = this.jwtHelper.decodeToken(token);
    }
    let role = user.role;

    if(!role.includes(Role.Admin)&&settings){
      this.items[0].items = this.items[0].items.filter((a: { admin: boolean; }) => a.admin==null);
    }
  }

}

<div class="wrapper">
  <h5 class="progress-title">Успеваемость</h5>

  <div class="progress-period-container">
    <form class="k-form" [formGroup]="formStudyPeriod">
      <dl class="progress-period-dl">

        <div class="label-sticky-top" *ngIf="editable">
          <kendo-label text="Период обучения:"></kendo-label>
        </div>

        <dd class="dd-inline" *ngIf="!editable">
          <kendo-label text="Период обучения: " style="margin-right: 5px"></kendo-label>
          <kendo-label> c {{ orderStudyPeriod && orderStudyPeriod.dateStart !== undefined ? (orderStudyPeriod.dateStart | kendoDate: "dd.MM.yyyy") : "-"}}
            по {{orderStudyPeriod && orderStudyPeriod.dateFinish !== undefined ? (orderStudyPeriod.dateFinish| kendoDate: "dd.MM.yyyy") : "-"}}
          </kendo-label>
        </dd>

        <dd class="dd-inline" *ngIf="editable">
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              class="order-date-picker"
              #dateStart
              [openOnFocus]="dateStart"
              (focus)="onFocus()"
              [disabled]="studentMainInfoComponent.deleted"
              placeholder="с"
              formControlName="dateStart"
              required>
            </kendo-datepicker>
            <span class="required negative-margin">*</span>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>
          <kendo-formfield>
            <kendo-datepicker
              format="dd.MM.yyyy"
              class="order-date-picker"
              #dateFinish
              [disabled]="studentMainInfoComponent.deleted"
              [openOnFocus]="dateFinish"
              (focus)="onFocus()"
              placeholder="по"
              formControlName="dateFinish">
            </kendo-datepicker>
            <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
          </kendo-formfield>

          <div>
            <button kendoButton
                    size="small"
                    *ngIf="editable"
                    [disabled]="formStudyPeriod!.invalid || studentMainInfoComponent.deleted"
                    themeColor="primary"
                    (click)="onSave($event)">
              Сохранить
            </button>
          </div>

        </dd>
      </dl>
    </form>
  </div>

  <div class="group-change-history" *ngIf="groupChangeable">
    <kendo-label class="label" text="Выберите группу для отображения данных"></kendo-label>
    <div>
      <kendo-dropdownlist class="input-change-group"
                          [data]="groupHistory"
                          size="small"
                          textField="name"
                          valueField="externalId"
                          [(ngModel)]="historyId"
                          [valuePrimitive]="true"
                          (valueChange)="onGroupChange($event)">
      </kendo-dropdownlist>
    </div>
  </div>

  <kendo-tabstrip>
    <kendo-tabstrip-tab [selected]="initialTab === TabsEnum.MiddleControl">
      <ng-template kendoTabTitle>
        <h6 class="progress-tabs">Промежуточный контроль</h6>
      </ng-template>
      <ng-template kendoTabContent>
        <form novalidate>
          <kendo-grid [data]="gridViewMark"
                      [group]="group"
                      [sortable]="true"
                      [sort]="sort"
                      [height]="500"
                      [loading]="loading">
            <ng-template kendoGridLoadingTemplate>
              <div class="k-loading-color"></div>
              <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
              </div>
            </ng-template>

            <kendo-grid-column field="disciplineName"
                               title="Предмет"
                               headerClass="gridHeader"
                               [width]="200">
              <ng-template kendoGridCellTemplate let-dataItem>
                     <span class="alignCells leftAlignment">
                     {{(dataItem.disciplineName)}}
                     </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="controlActionName"
                               title="Форма контроля"
                               headerClass="gridHeader"
                               [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>
                     <span class="alignCells">
                     {{(dataItem.controlActionName)}}
                     </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="markName"
                               title="Оценка"
                               headerClass="gridHeader"
                               [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>
                     <span class="alignCells">
                     {{(dataItem.markName)}}
                     </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="markDate"
                               title="Дата получения"
                               headerClass="gridHeader"
                               [width]="150">
              <ng-template kendoGridCellTemplate let-dataItem>
                     <span class="alignCells">
                     {{(dataItem.markDate| date: "dd.MM.yyyy":'GMT')}}
                     </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="retakeCount"
                               title="Пересдачи"
                               headerClass="gridHeader"
                               [width]="120">
              <ng-template kendoGridCellTemplate let-dataItem>
                     <span class="alignCells">
                     {{(dataItem.retakeCount==0?"":dataItem.retakeCount)}}
                     </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="semesterNumber" [hidden]=true>
              <ng-template kendoGridGroupHeaderTemplate let-group="group">
                     <span>
                     {{ "Семестр: " + group.value}}
                     </span>
              </ng-template>
            </kendo-grid-column>

          </kendo-grid>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>


    <kendo-tabstrip-tab [selected]="initialTab === TabsEnum.CurrentControl">
      <ng-template kendoTabTitle>
        <h6 class="progress-tabs">Текущий контроль</h6>
      </ng-template>
      <ng-template kendoTabContent>
        <form>
          <kendo-grid [data]="gridViewCurrentMark"
                      [group]="group"
                      [sortable]="true"
                      [height]="500"
                      [loading]="loading">
            <ng-template kendoGridLoadingTemplate>
              <div class="k-loading-color"></div>
              <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
              </div>
            </ng-template>

            <kendo-grid-column field="disciplineName"
                               title="Предмет"
                               headerClass="gridHeader"
                               [width]="250">
              <ng-template kendoGridCellTemplate let-dataItem>
                     <span class="alignCells leftAlignment">
                     {{(dataItem.disciplineName)}}
                     </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column-group headerClass="gridHeader" title="1 Контрольная неделя">
              <kendo-grid-column headerClass="gridHeader" field="ball" title="Оценка" [width]="80" format="n0">
                <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="gridCell centerAlignment">
                        {{dataItem.firstAttestation != null && dataItem.firstAttestation.ball != null ? dataItem.firstAttestation.ball : ""}}
                        </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column headerClass="gridHeader" field="hoursAbsent" title="Пропущенные часы" [width]="80" format="n0">
                <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="gridCell centerAlignment" >
                        {{dataItem.firstAttestation != null && dataItem.firstAttestation.hoursAbsent != null ? dataItem.firstAttestation.hoursAbsent : ""}}
                        </span>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group headerClass="gridHeader" title="2 Контрольная неделя">
              <kendo-grid-column headerClass="gridHeader" field="ball" title="Оценка" [width]="80" format="n0">
                <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="gridCell centerAlignment">
                        {{dataItem.secondAttestation != null && dataItem.secondAttestation.ball != null ? dataItem.secondAttestation.ball : ""}}
                        </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column headerClass="gridHeader" field="hoursAbsent" title="Пропущенные часы" [width]="80" format="n0">
                <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="gridCell centerAlignment" >
                        {{dataItem.secondAttestation != null && dataItem.secondAttestation.hoursAbsent != null ? dataItem.secondAttestation.hoursAbsent : ""}}
                        </span>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column field="semesterNumber" [hidden]=true>
              <ng-template kendoGridGroupHeaderTemplate let-group="group">
                     <span style="text-decoration: underline;">
                     {{ "Семестр: " + group.value}}
                     </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </form>
      </ng-template>
    </kendo-tabstrip-tab>


    <kendo-tabstrip-tab [selected]="initialTab === TabsEnum.EducationPlan">
      <ng-template kendoTabTitle>
        <h6 class="progress-tabs">Текущий учебный план</h6>
      </ng-template>
      <ng-template kendoTabContent>

        <kendo-grid [data]="gridData"
                    [height]="500"
                    [rowClass]="rowClassCallback">
          <ng-template kendoGridToolbarTemplate *ngIf="editable">
               <span class="gridCell">
                  {{"Название плана: " + planName}}
                  </span>
            <kendo-grid-spacer></kendo-grid-spacer>
            <button kendoButton
                    [disabled]="studentMainInfoComponent.deleted"
                    *ngIf="editable"
                    themeColor="success"
                    size="small"
                    (click)="onChangePlan()">
              Сменить учебный план
            </button>
          </ng-template>

          <kendo-grid-column headerClass="gridHeader" field="Cipher" title="Шифр" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="gridCell">
                  {{dataItem.Cipher}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="DictDisciplineName" title="Дисциплина" headerClass="gridHeader" [width]="300">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="gridCell leftAlignment">
                  {{dataItem.DictDisciplineName}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="DepartmentName" title="Кафедра" [width]="150" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.DepartmentName}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="ControllingActions" title="Семестр" [width]="150" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="gridCell centerAlignment">
                <div class="alignCells">
                  {{removeDuplicateSemesters(dataItem.ControllingActions)}}
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="TotalLaborIntensity" title="З.ед." [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.TotalLaborIntensity}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="TotalNumberHours" title="Час." [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.TotalNumberHours}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Lectures" title="лек." [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.Lectures}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Laboratory" title="лаб. раб." [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.Laboratory}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Practice" title="практ." [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.Practice}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="KSR" title="КСР" [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.KSR}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="HoursSelfWork" title="Сам. раб." [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.HoursSelfWork}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="Control" title="Контроль" [width]="90" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.Control}}
                  </span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="HoursCredited" title="Зачтено" [width]="80" format="n0" headerClass="gridHeader">
            <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                  {{dataItem.HoursCredited}}
                  </span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>


    <kendo-tabstrip-tab [selected]="initialTab === TabsEnum.SemesterDates">
      <ng-template kendoTabTitle>
        <h6 class="progress-tabs">Сроки семестров</h6>
      </ng-template>
      <ng-template kendoTabContent>

        <div class="semester-tab">
          <div class="semester-buttons" *ngIf="!termsEdit && editable && currentGroup">
            <button kendoButton fillMode="outline" size="small" class="group-outline-buttons progress-right margin" (click)="termsOpen()" *ngIf="!termsEdit && editable">
              Редактировать
              <span class="k-icon k-i-edit" style="padding-bottom: 2px;"></span>
            </button>

            <button kendoButton size="small" class="progress-right" themeColor="success" *ngIf="!termsEdit && editable" (click)="openDialog()">
              Заполнить из УП
            </button>
          </div>

          <div [ngClass]="{edit: termsEdit, read: !termsEdit}" class="semester-box">
            <div class="alignCells leftAlignment">
              <kendo-label class="academic-label label-name"  text="Название группы"></kendo-label>
            </div>
            <div class="alignCells">
              <kendo-label class="academic-label">{{"Сроки 1го " + (trimester? "триместра" : "семестра")}}</kendo-label>
            </div>
            <div class="alignCells">
              <kendo-label class="academic-label">{{"Сроки 2го " + (trimester? "триместра" : "семестра")}}</kendo-label>
            </div>
            <div class="alignCells" [ngClass]="{editBtnColumn: (!termsEdit&&editable)}">
              <kendo-label [hidden]="!trimester" class="academic-label" text="Сроки 3го триместра"></kendo-label>
            </div>
            <div class="semester-row" *ngFor="let item of semesterData;let i=index">
              <div [ngClass]="{editSemester: (editable&&termsEdit)}">
                <kendo-label class="academic-label progress-course-label">{{(item.courseNum) + " курс"}}</kendo-label>
                <kendo-label class="academic-label group-name">{{item.groupName}}</kendo-label>
              </div>
              <div class="archived-group">
                <div *ngIf="!item.closed">
                  <kendo-label class="academic-label" text="с"></kendo-label>
                  <kendo-label class="academic-label terms-view" *ngIf="!termsEdit">{{item.firstSemesterStart| date: "dd.MM.yyyy"}}</kendo-label>
                  <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.firstSemesterStart" *ngIf="termsEdit" required></kendo-datepicker>
                  <kendo-label class="academic-label" text="по"></kendo-label>
                  <kendo-label class="academic-label terms-view" *ngIf="!termsEdit">{{item.firstSemesterEnd| date: "dd.MM.yyyy"}}</kendo-label>
                  <kendo-datepicker class="academic-datepicker" [(ngModel)]="item.firstSemesterEnd" *ngIf="termsEdit"></kendo-datepicker>
                </div>
                <div *ngIf="item.closed">
                  <kendo-label class="academic-label" text="архивирована"></kendo-label>
                </div>
              </div>
              <div class="archived-group">
                <div  *ngIf="!item.secondClosed">
                  <kendo-label class="academic-label" text="с" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)"></kendo-label>
                  <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.secondSemesterStart && !item.secondSemesterEnd">{{item.secondSemesterStart| date: "dd.MM.yyyy"}}</kendo-label>
                  <kendo-datepicker class="academic-datepicker" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)" [(ngModel)]="item.secondSemesterStart" *ngIf="termsEdit" required></kendo-datepicker>
                  <kendo-label class="academic-label" text="по" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)"></kendo-label>
                  <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.secondSemesterStart && !item.secondSemesterEnd">{{item.secondSemesterEnd| date: "dd.MM.yyyy"}}</kendo-label>
                  <kendo-datepicker class="academic-datepicker" [hidden]="(!item.secondSemesterStart && !item.secondSemesterEnd && !termsEdit)" [(ngModel)]="item.secondSemesterEnd" *ngIf="termsEdit"></kendo-datepicker>
                </div>
                <div *ngIf="item.secondClosed">
                  <kendo-label class="academic-label" text="архивирована"></kendo-label>
                </div>
              </div>
              <div>
                <kendo-label class="academic-label" text="с" [hidden]="!item.thirdSemesterStart"></kendo-label>
                <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.thirdSemesterStart">{{item.thirdSemesterStart| date: "dd.MM.yyyy"}}</kendo-label>
                <kendo-datepicker class="academic-datepicker" [hidden]="!item.thirdSemesterStart" [(ngModel)]="item.thirdSemesterStart" *ngIf="termsEdit"></kendo-datepicker>
                <kendo-label class="academic-label" text="по" [hidden]="!item.thirdSemesterStart"></kendo-label>
                <kendo-label class="academic-label terms-view" *ngIf="!termsEdit" [hidden]="!item.thirdSemesterStart">{{item.thirdSemesterEnd| date: "dd.MM.yyyy"}}</kendo-label>
                <kendo-datepicker class="academic-datepicker" [hidden]="!item.thirdSemesterStart" [(ngModel)]="item.thirdSemesterEnd" *ngIf="termsEdit"></kendo-datepicker>
              </div>
            </div>
          </div>

          <div class="semester-buttons" *ngIf="termsEdit">
            <button kendoButton size="small" class="progress-right left-margin" themeColor="primary" [disabled]="semesterIncorrect" (click)="onSaveSemesters()">
              Сохранить
            </button>
            <button kendoButton size="small" class="progress-right" (click)="termsClose()">
              Отмена
            </button>
          </div>

        </div>

      </ng-template>
    </kendo-tabstrip-tab>


    <kendo-tabstrip-tab [disabled]="true">
      <ng-template kendoTabTitle>
        <h6 class="progress-tabs">Текущий семестр{{(educationPlan.semesterNum == null ? "" : ": " + educationPlan.semesterNum)}}</h6>
      </ng-template>
      <ng-template kendoTabContent>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<div kendoDialogContainer></div>

<kendo-dialog title="Пожалуйста, подтвердите" *ngIf="opened" (close)="closeDialog()" [minWidth]="200" [width]="450">
  <p style="margin: 30px; text-align: center;">
    {{"Вы действительно хотите заполнить сроки семестров из текущего учебного плана студента?"}}
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Нет</button>
    <button kendoButton (click)="setSemesterFromPlan()" themeColor="success">
      Да
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

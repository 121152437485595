import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

export class ClassroomFundService {
    
    baseUrl = urlJoin(<string>environment.classroomApiEndpoint, environment.apiPaths.classroom.base);

    constructor(private http: HttpClient) { }

    public getAllClassroom(){
        return this.http.post(this.baseUrl + '/all', {});
    }

    public getAllOwners(){
        return this.http.get(this.baseUrl + '/d/departments/all');
    }

    public getAllowedOwners(){
        return this.http.get(this.baseUrl + '/d/departments/');
    }

    public getFilters(number: any, name: any, buildings: any, types: any, assigns: any, owners: any){
       let request = {number: number, name: name, buildingIds: buildings, ClassRoomTypeIds: types, ClassRoomPurposeIds: assigns, 
            DepartmentIds: owners};
        console.log(request);
        return this.http.post(this.baseUrl + '/all', request);
    }

}
import {ApplicationApprovalSettings} from "./approvalList.model";
import {ConstructorDocument} from "../applicationConstructor/form/constructor-main-settings.model";

export interface DocumentGroup {
  statusName: string;
  items: ApplicationApprovalSettings[];
}

export interface ApplicationDocument extends ConstructorDocument {
  expirationDate?: Date;
}

export interface DocumentItem extends ApplicationApprovalSettings {
  statusName: string;
  document?: ApplicationDocument;
  expirationDate?: Date;
  signDate?: Date;
}

export class ApplicationDocumentList {
  signature: ApplicationDocument[] = [];
  ready: ApplicationDocument[] = [];
}

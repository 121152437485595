import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {SpravkaTypes} from "../../../models/contingent/spravkatypes.model";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AddEvent, CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {DictspravkatypeService} from "../../../services/contingent/dictspravkatype.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {DictOtherDocumentType} from "../../../models/contingent/dictotherdocumenttype";
import {DictotherdocumentsService} from "../../../services/contingent/dictotherdocuments.service";

@Component({
  selector: 'app-dictotherdocumenttypes',
  templateUrl: './dictotherdocumenttypes.component.html',
  styleUrls: ['./dictotherdocumenttypes.component.scss']
})
export class DictotherdocumenttypesComponent implements OnInit {

  public otherDocumentTypes: DictOtherDocumentType[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private dictOtherDocumentsService: DictotherdocumentsService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: ContingentUserAccessService,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getOtherDocumentTypes();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getOtherDocumentTypes();
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = formGroup({
      typeName: ""
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: number | undefined = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.dictOtherDocumentsService.addOtherDocument(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getOtherDocumentTypes();
              this.notificationService.showSuccess("Добавлено");
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить тип справки')
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.dictOtherDocumentsService.updateOtherDocument(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getOtherDocumentTypes();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения')
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getOtherDocumentTypes() {
    this.dictOtherDocumentsService.getAllOtherDocument()
      .subscribe(
        response => {
          this.otherDocumentTypes = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.typeName + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.dictOtherDocumentsService.deleteOtherDocument(dataItem.externalId)
            .subscribe({
              next:() => {
                this.getOtherDocumentTypes();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }});
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { externalId?: string; typeName?: string; order?: number; }) =>
  new UntypedFormGroup({
    externalId: new UntypedFormControl(dataItem.externalId),
    typeName: new UntypedFormControl(dataItem.typeName, Validators.required),
    order: new UntypedFormControl(dataItem.order),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

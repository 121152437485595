<h5>Справка для военкомата</h5>
<form class="k-form" [formGroup]="formMilitary" *ngIf="!editMode">
  <dl class="dl-certificate militaryCertificate">
    <kendo-label class="label" text="Категория воинского учёта"></kendo-label>
    <dd class="ddinline">
      <div>
        <kendo-formfield>
          <kendo-dropdownlist class="input-military-certificate"
                              [data]="militaryStatuses"
                              textField="militaryStatusName"
                              valueField="dictMilitaryStatusExternalId"
                              [valuePrimitive]="true"
                              [disabled]="studentMainInfoComponent.deleted || !editable"
                              name="militaryStatusId"
                              (valueChange)="saveMilitary()"
                              formControlName="dictMilitaryStatusId">
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>
    </dd>
    <kendo-label class="label" text="Военкомат"></kendo-label>
    <dd>
      <div>
        <kendo-formfield class="military-commissariat">
          <kendo-combobox class="military-commissariat"
                          [kendoDropDownFilter]="filterSettings"
                          [data]="militaryCommissariats"
                          textField="commissariatName"
                          valueField="id"
                          [openOnFocus]="commissariat"
                          #commissariat
                          [valuePrimitive]="true"
                          (valueChange)="saveMilitary()"
                          [disabled]="!this.formMilitary.value.dictMilitaryStatusId || studentMainInfoComponent.deleted || !editable"
                          name="militaryCommissariatId"
                          formControlName="dictMilitaryCommissariatId">
          </kendo-combobox>
        </kendo-formfield>
      </div>
    </dd>
  </dl>
</form>

<div class="grid-container" [ngClass]="{militaryContainer: !editMode}">
  <form novalidate #myForm="ngForm">
    <kendo-grid [kendoGridBinding]="spravkas"
                *ngIf="!editMode; else edit"
                (save)="downloadFile($event)"
                (edit)="transitionHandler($event)"
                (remove)="removeHandler($event)"
                (add)="addHandler($event)"
                [sortable]="true"
                scrollable="none"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand
                [disabled]="studentMainInfoComponent.deleted || disableMilitary"
                *ngIf="editable"
                size="small"
                themeColor="success"
                icon="plus">
          Добавить справку
        </button>
      </ng-template>
      <kendo-grid-column field="code"
                         headerClass="gridHeader dictionaries"
                         title="Код"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{dataItem.code}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="numberFormatted"
                         headerClass="gridHeader dictionaries"
                         title="Рег. номер"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{dataItem.numberFormatted}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="date"
                         headerClass="gridHeader dictionaries"
                         title="Дата выдачи"
                         [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{ (dataItem?.date == null ? "" : dataItem?.date| date: "dd.MM.yyyy") }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictSpravkaTypeId"
                         headerClass="gridHeader dictionaries"
                         title="Справка"
                         [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{ nametype(dataItem.dictSpravkaTypeId) }}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="purpose"
                         headerClass="gridHeader dictionaries"
                         title="Куда выдана"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{dataItem.purpose!=" "?dataItem.purpose:dataItem.employerName}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="isIssued"
                         editor="boolean"
                         title="Отметка о вручении"
                         headerClass="gridHeader"
                         [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{(dataItem.isIssued == true ? "Да" : "Нет")}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fio"
                         title="Пользователь"
                         headerClass="gridHeader"
                         [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
            {{(dataItem.fio)}}
            </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column *ngIf="!studentMainInfoComponent.deleted"
                         [width]="200">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
          <button kendoButton type="button"
                  icon="save"
                  class="certificate-green-button"
                  fillMode="flat"
                  rounded="full"
                  size="small"
                  (click)="downloadFile(dataItem)">
          </button>
          <button kendoGridEditCommand type="button"
                  icon="edit"
                  *ngIf="editable"
                  fillMode="flat"
                  rounded="full"
                  size="small">
          </button>
          <button kendoGridRemoveCommand
                  type="button"
                  *ngIf="editable"
                  [disabled]="dataItem.isIssued == true"
                  [hidden]="dataItem.isIssued == true"
                  class="certificate-red-button"
                  icon="close"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
          <button
            kendoGridSaveCommand
            type="button"
            [disabled]="myForm.invalid!"
            size="small"
            themeColor="primary">
            {{ isNew ? "Добавить" : "Обновить" }}
          </button>
          <button kendoGridCancelCommand type="button" size="small">
            {{ "Отмена" }}
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</div>
<!------------Edit Identification------------>
<ng-template #edit >
  <div class="certificate-edit-block">
    <div class="certificate ngForm">
      <form class="k-form" [formGroup]="form">
        <dl class="dl-certificate military-top">
          <h3>Код</h3>
          <dd class="certificate-inline">
            <kendo-formfield>
              <kendo-textbox
                formControlName="code"
                class="military-top-inputs"
                size="small"
                placeholder="Код"
                [(ngModel)]="this.form.value.code"
                required
              ></kendo-textbox>
              <span class="required">*</span>
            </kendo-formfield>
            <h3 class="military-top-label">Рег.номер</h3>
            <kendo-formfield>
              <kendo-textbox
                formControlName="numberFormatted"
                [(ngModel)]="this.numberFormatted"
                class="military-top-inputs"
                size="small"
                [disabled]="true"
                placeholder="Рег.номер"
                required
              ></kendo-textbox>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
            <h3 class="military-top-label">Дата выдачи</h3>
            <kendo-formfield>
              <kendo-datepicker
                format="dd.MM.yyyy"
                #requestDate
                class="input-main-identity margin-eight"
                [openOnFocus]="requestDate"
                formControlName="date"
                [style.width.px]="142"
                required>
              </kendo-datepicker>
              <span class="required">*</span>
            </kendo-formfield>
          </dd>
          <h3>Выдана</h3>
          <dd>
            <kendo-formfield>
              <kendo-autocomplete
                [data]="militaryCommissariats"
                *ngIf="isMilitary"
                textField="commissariatName"
                valueField="commissariatName"
                formControlName="purpose"
                [kendoDropDownFilter]="filterSettings"
                class="military-issued-field"
                #commissariat
                [openOnFocus]="commissariat"
                size="small"
                placeholder=""
                required
              ></kendo-autocomplete>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
        </dl>
      </form>
      <dl class="dl-certificate row-gap-eighteen padding-eighteen-twenty">
        <h4 class="certificate-title">Обучающийся</h4>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-thirty">ФИО</h3>
          <h3>{{fio == null ? "-" :fio}}</h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-thirty">Направление подготовки</h3>
          <h3 class="certificate-education-way">
            {{studentMainInfo.contingent.eduGroup == null ? "(--.--.--) -" :studentMainInfo.nameEducationStandard(studentMainInfo.contingent.studPlan?.planId
            ? studentMainInfo.contingent.studPlan?.planId
            : studentMainInfo.contingent.eduGroup.planId)}}
          </h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-thirty">Профиль (программа)</h3>
          <h3 class="certificate-education-way">
            {{studentMainInfo.contingent.eduGroup == null ? "-" :studentMainInfo.nameEducationProgram(studentMainInfo.contingent.studPlan?.planId
            ? studentMainInfo.contingent.studPlan?.planId
            : studentMainInfo.contingent.eduGroup.planId)}}
          </h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-eighteen">Форма обучения</h3>
          <h3 class="margin-fifty">{{(!studyForm ? "" : studyForm.studyFormName) }}</h3>
          <h3 class="margin-eighteen"> Курс</h3>
          <h3 class="margin-fifty">
            {{(studentMainInfo.contingent.courseNum==null
            ? (studentMainInfo.contingent.eduGroup == null ? "-" : studentMainInfo.contingent.eduGroup?.courseNum)
            : studentMainInfo.contingent.courseNum)}}
          </h3>
          <h3 class="margin-eighteen">Группа</h3>
          <h3 class="margin-thirty">{{(studentMainInfo.contingent.eduGroup == null ? "-" : studentMainInfo.contingent.eduGroup?.groupName)}}</h3>
        </dd>
        <dd></dd>
        <dd class="certificate-inline">
          <h3 class="margin-eighteen">Источник финансирования</h3>
          <h3>{{(studentMainInfo.contingent.studentBudgetState == null ? "-" : studentMainInfo.contingent.studentBudgetState.studentBudgetName)}}</h3>
        </dd>
        <dd></dd>
      </dl>
      <form class="k-form" [formGroup]="form">
        <dl class="dl-certificate row-gap-eighteen padding-bottom-twenty">
          <h4>Срок обучения</h4>
          <dd></dd>
          <dd class="certificate-inline">
            <h3 class="margin-eighteen">Период обучения</h3>
            <h3 class="margin-eighteen">с</h3>
            <h3 class="margin-eighteen">{{(orderStudyPeriod == null || orderStudyPeriod.dateStart == null ? "--.--.----" : orderStudyPeriod.dateStart| date:"dd.MM.yyyy")}}</h3>
            <h3 class="margin-eighteen">по</h3>
            <h3 class="margin-eighteen">{{(orderStudyPeriod == null || orderStudyPeriod.dateFinish == null? "--.--.----" : orderStudyPeriod.dateFinish| date:"dd.MM.yyyy")}}</h3>
          </dd>
          <dd></dd>
          <dd class="certificate-inline">
            <h3 class="margin-eighteen">Срок обучения</h3>
            <h3 class="margin-eighteen">
              {{(spravkaStudyPeriod.studyPeriodYear == null ? "-" : spravkaStudyPeriod.studyPeriodYear)}}
              {{(spravkaStudyPeriod.studyPeriodYear != null&&spravkaStudyPeriod.studyPeriodMonth != null ? ", " : " ")}}
              {{(spravkaStudyPeriod.studyPeriodMonth == null ? " " : spravkaStudyPeriod.studyPeriodMonth)}}
            </h3>
          </dd>
          <dd></dd>
          <dd class="certificate-inline">
            <h3 class="margin-eighteen">Дата окончания обучения</h3>
            <kendo-formfield>
              <kendo-datepicker
                format="dd.MM.yyyy"
                #requestDateEnd
                class="input-main-identity margin-eight"
                [openOnFocus]="requestDateEnd"
                formControlName="employerDateEnd"
                [(ngModel)]="this.form.value.employerDateEnd"
                [style.width.px]="160"
                required>
              </kendo-datepicker>
              <span class="required">*</span>
              <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
            </kendo-formfield>
          </dd>
          <dd></dd>
        </dl>
      </form>
      <form class="k-form" [formGroup]="formCaseName">
        <dl class="dl-certificate">
          <h4>Склонение ФИО</h4>
          <dd></dd>
          <h3>(Родительный падеж)</h3>
          <dd>
          </dd>
        </dl>
        <dl class="dl-certificate-inline padding-ten-twenty">
          <dd>
            <h3 style="margin-bottom: 11px;">Фамилия</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="rLastName"
              placeholder="Фамилия"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Имя</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="rFirstName"
              placeholder="Имя"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Отчество</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="rMiddleName"
              placeholder="Отчество"
            ></kendo-textbox>
          </dd>
        </dl>
        <dl class="dl-certificate">
          <h3>(Дательный падеж)</h3>
          <dd>
          </dd>
        </dl>
        <dl class="dl-certificate-inline padding-ten-twenty">
          <dd>
            <h3 style="margin-bottom: 11px;">Фамилия</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="dLastName"
              placeholder="Фамилия"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Имя</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="dFirstName"
              placeholder="Имя"
            ></kendo-textbox>
          </dd>
          <dd>
            <h3 style="margin-bottom: 11px;">Отчество</h3>
            <kendo-textbox
              [style.width.px]="215"
              size="small"
              formControlName="dMiddleName"
              placeholder="Отчество"
            ></kendo-textbox>
          </dd>
        </dl>
      </form>
      <form class="k-form" [formGroup]="form">
        <dl class="dl-certificate" *ngIf="!isMilitary">
          <h4>Подписанты</h4>
          <dd></dd>
        </dl>
        <ng-container class="ddinline padding-five-five" *ngFor="let item of spravkaPersons;let i=index">
          <dl class="dl-certificate" *ngIf="isMilitary">
            <h4>{{i == 0 ? "Подписант" : "Исполнитель"}}</h4>
            <dd></dd>
          </dl>
          <ng-container *ngIf="!signerEditOpen[i]">
            <dl class="dl-certificate padding-five-five">
              <h3>{{i == 1  && isMilitary? "Исполнитель" : "Подписант"}}</h3>
              <dd>
                <kendo-combobox [data]="localSignatories" #combo
                                textField="fio"
                                valueField="personExternalId"
                                #person
                                [openOnFocus]="person"
                                size="small"
                                class="signer-field"
                                (valueChange)="personValueChange(i,$event)"
                                [kendoDropDownFilter]="filterSettings"
                                [valuePrimitive]="true"
                                [(ngModel)]="spravkaPersons[i].personId"
                                [ngModelOptions]="{standalone: true}">
                </kendo-combobox>
                <span class="required">*</span>
              </dd>
              <h3 class="certificate-signer">{{i == 1  && isMilitary? "Телефон" : "Должность подписанта"}}</h3>
              <dd>
                <kendo-formfield>
                  <kendo-textbox
                    *ngIf="!(isMilitary && i == 1)"
                    [ngModelOptions]="{standalone: true}"
                    size="small"
                    class="signer-field"
                    placeholder=""
                    [(ngModel)]="this.personPosts[i]"
                  ></kendo-textbox>
                  <kendo-textbox
                    *ngIf="isMilitary && i == 1"
                    size="small"
                    class="signer-field"
                    placeholder=""
                    formControlName="performerNumber"
                  ></kendo-textbox>
                </kendo-formfield>
              </dd>
              <h3>{{i == 1  && isMilitary? "Поиск исполнителя" : "Поиск подписанта"}}</h3>
              <dd>
                <kendo-autocomplete
                  [data]="persons"
                  class="signer-field"
                  valueField="fio"
                  size="small"
                  placeholder="Введите ФИО сотрудника..."
                  [clearButton]="true"
                  [filterable]="true"
                  [kendoDropDownFilter]="filterSettings"
                  (valueChange)="onChangeFindSigner(i,$event)">
                  <ng-template kendoAutoCompleteNoDataTemplate>
                    <p class="signer-edit__template-not-found">Подписант не найден</p>
                  </ng-template>
                </kendo-autocomplete>
              </dd>
              <dd></dd>
              <dd><button kendoButton
                          class="certificate-close-button"
                          size="small"
                          *ngIf="editMode"
                          (click)="closeSigner(i)">
                Закрыть
              </button>
              </dd>
            </dl>
          </ng-container>
          <ng-container *ngIf="signerEditOpen[i]">
            <dl class="dl-certificate-inline">
              <dd class="signer-certificate certificate-signer-name">
                <h3 (click)="openSigner(i)" style="display: contents; margin-right:20px;">
                  {{(spravkaPersons[i].personId == null ? "Фамилия Имя Отчество" :
                  nameperson(spravkaPersons[i].personId))}}  {{personPosts[i] == null||personPosts[i] == "" ? "Должность" : ", " + personPosts[i]}}
                </h3>
              </dd>
              <dd class="signer-certificate padding-five-five">
                <button kendoButton
                        themeColor="error"
                        fillMode="outline"
                        class="certificate-outline-buttons certificate-button-red"
                        size="small"
                        [disabled]="spravkaPersons.length<2"
                        *ngIf="spravkaPersons.length!=1&&!isMilitary&&!disabled"
                        (click)="removeSigner(i)">
                  Удалить
                  <span class="k-icon k-i-close certificate-button-red"></span>
                </button>
                <button kendoButton
                        *ngIf="!disabled"
                        class="certificate-right-button"
                        fillMode="outline"
                        size="small"
                        icon="edit"
                        (click)="openSigner(i)"
                ></button>
                <span class="required">*</span>
              </dd>
            </dl>
          </ng-container>
        </ng-container>
        <dd class="outlineButtons organization-href padding-ten-twenty">
          <button kendoButton *ngIf="!addSignerHide&&!disabled"
                  fillMode="outline" class="certificate-add-button"
                  size="small"
                  (click)="addSigner()">
            Добавить подписанта
            <span class="k-icon k-i-plus"></span>
          </button>
        </dd>
        <dl class="dl-certificate column-gap-twenty">
          <h4>Примечание</h4>
          <dd></dd>
          <h3 *ngIf="!isMilitary">Ответ на официальный запрос</h3>
          <dd *ngIf="!isMilitary" class="certificate-inline">
            <h3 class="margin-eight">Номер</h3>
            <kendo-textbox
              class="margin-eighteen"
              [style.width.px]="145"
              size="small"
              formControlName="requestNumber"
            ></kendo-textbox>
            <h3 class="margin-eight">Дата</h3>
            <kendo-datepicker
              format="dd.MM.yyyy"
              #requestDate
              class="input-main-identity"
              [openOnFocus]="requestDate"
              formControlName="requestDate"
              [style.width.px]="155"
            >
            </kendo-datepicker>
          </dd>
        </dl>
        <dl *ngIf="!isMilitary" class="dl-certificate column-gap-twenty padding-sixteen-zero">
          <h3>Образование соответствующего уровня получается впервые</h3>
          <dd>
            <input id="ft" type="checkbox" kendoCheckBox
                   name="firstTimeCheck"
                   formControlName="firstTime">
          </dd>
        </dl>
        <dl *ngIf="isMilitary" class="dl-certificate row-gap-eighteen" style="margin-top: 10px">
          <h3 style="margin-right: 33px;">Отметка о вручении</h3>
          <dd>
            <input id="ac" type="checkbox" kendoCheckBox
                   name="issued"
                   formControlName="isIssued"
                   (change)="toggleEditForm()">
          </dd>
        </dl>
      </form>
    </div>
    <div class="editModeButtons">
      <button kendoButton
              size="small"
              *ngIf="editMode"
              (click)="closeIdentification()">
        Отмена
      </button>
      <button kendoButton
              size="small"
              *ngIf="editMode&&!disabled"
              themeColor="primary"
              [disabled]="!form.valid||!saveDisable"
              (click)="onSave($event)"
              style="margin-left: 15px">
        Сохранить
      </button>
      <button kendoButton
              size="small"
              *ngIf="editMode&&!disabled"
              themeColor="primary"
              style="float:right; margin: 0;"
              [disabled]="!form.valid||!saveDisable"
              (click)="onSaveAndPrint($event)"
              icon="print">
        Сохранить и распечатать
      </button>
      <button kendoButton
              size="small"
              *ngIf="editMode&&disabled"
              themeColor="primary"
              style="float:right; margin: 0;"
              (click)="downloadFile(form.value)"
              icon="print">
        Распечатать
      </button>
    </div>
  </div>
</ng-template>
<div kendoDialogContainer></div>

import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, catchError, Observable} from 'rxjs';
import {handleError} from '../../helpers/errorHandle-helper';
import {ApprovalSettingsForm} from '../../models/mfc/applicationConstructor/form/constructor-approval-settings.model';
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApprovalSettingsService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.applicationConstructor}/`;
  public additionalFilters = [];
  public approvalSettings$ = new BehaviorSubject<ApprovalSettingsForm[]>([]);

  constructor(private http: HttpClient) { }

  public getSettings(constructorId: string): Observable<ApprovalSettingsForm[]> {
    return this.http.get<ApprovalSettingsForm[]>(`${this.baseUrl}${constructorId}/ApprovalSetting`)
      .pipe(tap((value) => this.approvalSettings$.next(value)));
  }

  public updateSettings(constructorId: string, settings: ApprovalSettingsForm[]): Observable<ApprovalSettingsForm[]> {
    return this.http.put<ApprovalSettingsForm[]>(`${this.baseUrl}${constructorId}/ApprovalSetting`, settings)
      .pipe(catchError(handleError));
  }
}

<div class="button-control">

    <button kendoButton *ngIf="!newList"
            class="blue"                                      
            size="small"
            (click)="inDraft()"
            >
        в черновики
    </button>  
    <div style="justify-content: end; display: flex; width: 100%;"> 
        <button kendoButton *ngIf="isDraft == true && messageId !== 0"
                class="white m-r20"
                (click)="openpopupDelete()" 
        >
        Удалить
        </button>
        <button kendoButton *ngIf="!newList"
                class="white m-r20"
                (click)="openpopup()"

        >
        отмена
        </button>
        <button kendoButton *ngIf="!newList"
                class="blue"
                (click)="Announcement()"
        >
        далее
        </button> 
    </div>
</div>
<div kendoDialogContainer></div>
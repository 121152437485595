import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Person } from '../../models/contingent/person.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.person}`;

  constructor(private http: HttpClient) { }

  //Get all person
  public getAllperson(): Observable<Person[]> {
    return this.http.get<Person[]>(this.baseUrl);
  }

  //Get By Id person
  getByIdperson(id: string): Observable<Person> {
    return this.http.get<Person>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add person
  addperson(person: Person): Observable<Person> {
    return this.http.post<Person>(this.baseUrl, person)
      .pipe(catchError(this.handleError));
  }

  //Delete person
  deleteperson(id: string): Observable<Person> {
    return this.http.delete<Person>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit person
  updateperson(person: Person): Observable<Person> {
    return this.http.put<Person>(this.baseUrl + '/' + person.personId, person)
      .pipe(catchError(this.handleError));
  }
  
  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}

}

<h1>{{ standardName }}</h1>

<button kendoButton class="m-r15" *ngIf="editable" (click)="openForm()">Создать новый приказ</button>
<button kendoButton class="m-r15" *ngIf="editable" [disabled]="arrayStudents.length === 0 || releaseOrders?.length === 0" (click)="openDialog()">Добавить в приказ</button>

<h6 class="info-label">Для создания приказа выберите обучающихся и нажмите "Создать новый приказ". Для добавления обучающихся к уже существующему приказу выберите обучающихся и нажмите "Добавить в приказ"</h6>

<kendo-grid #grid
            class="m-t10"
            [kendoGridBinding]="students"
            [selectable]="{checkboxOnly: true}"
            kendoGridSelectBy="studentId"
            [loading]="loading"
            [filterable]="true"
            [filter]="filter"
            (filterChange)="filterChange($event)"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [pageSize]="pageSize"
            (pageChange)="pageChange($event)"
            (cellClick)="cellClickHandler($event)"
            [pageable]="pagerSettings"
            [skip]="skip"
            [rowClass]="rowCallback"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>

  <kendo-grid-column field="studentId"
                     [width]="60"
                     [sortable]="false"
                     [resizable]="false"
                     [filterable]="false"
                     *ngIf="editable"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridHeaderTemplate>
      <div headerClass="gridHeader">
        <div class="m-t10">
          <input
            type="checkbox"
            kendoCheckBox
            [checked]="allItemsChecked"
            (click)="selectAll(allItemsChecked)"
          />
        </div>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox *ngIf="!dataItem.releaseOrderId && !dataItem.isBadProtocolResult"
                       [checked]="checked(dataItem.studentId)"
                       (change)="checkedChange(dataItem.studentId)"
                />
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="studentFio"
                     title="ФИО обучающегося"
                     [width]="200"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells leftAlignment">
                {{ dataItem.studentFio }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="groupName"
                     title="Академическая группа"
                     [width]="140"
                     *ngIf="trainingLevel === TrainingLevelEnum.Specialty || trainingLevel === TrainingLevelEnum.Master"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.groupName }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="standardName"
                     title="Специальность"
                     [width]="140"
                     *ngIf="trainingLevel === TrainingLevelEnum.Residency"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.standardName }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="admissionYear"
                     title="Год поступления"
                     [width]="150"
                     headerClass="gridHeader dictionaries">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.admissionYear }}
            </span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column field="diplomaStatus"
                     title="Статус диплома"
                     [width]="150"
                     headerClass="gridHeader dictionaries"
                     filter="numeric">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.diplomaStatus }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="releaseOrder"
                     title="Приказ"
                     [width]="160"
                     headerClass="gridHeader dictionaries"
                     filter="numeric">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.releaseOrder }}
            </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column headerClass="gridHeader dictionaries" [width]="50">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" *ngIf="dataItem.releaseOrderId">
          <span class="k-icon k-i-download pointer" (click)="printOrder(dataItem.releaseOrderId)"></span>
        </span>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>


<kendo-dialog title="Пожалуйста, выберите приказ" *ngIf="opened" (close)="closeDialog()" [minWidth]="200" [width]="450">
  <div class="filter">
    <span class="selectText k-mr-3">Приказ</span>
    <kendo-dropdownlist
      [data]="releaseOrders"
      textField="name"
      valueField="id"
      [valuePrimitive]="true"
      [(ngModel)]="orderId"
    >
    </kendo-dropdownlist>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog()">Отмена</button>
    <button kendoButton themeColor="primary" (click)="addToOrder()">Добавить в приказ</button>
  </kendo-dialog-actions>
</kendo-dialog>

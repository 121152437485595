<div class="grid-layout-container" style="margin-top: 20px;"> 
    <div class="main-title"> Структурные подразделения </div>

        <kendo-grid [kendoGridBinding]="structurals" (remove)="removeHandler($event)"
                [height]="700"
                [pageable]="{
                    buttonCount: buttonCount,
                    info: info,
                    type: type,
                    pageSizes: pageSizes,
                    previousNext: previousNext,
                    position: positionPager
                }"
                [pageSize]="10"
                (cellClick)="editHandler($event)"
                (add)="addHandler($event)"
                (dataStateChange)="onStateChange($event)"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>

      <ng-template kendoGridToolbarTemplate [position]="position">
        <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">
           Добавить
        </button>
        <div *ngIf="isInEditingMode">
          <button kendoButton
                  (click)="cancelHandler()">
            Отмена
          </button>
          <button kendoButton
                  
                  themeColor="primary"
                  >
            Сохранить
          </button>
        </div>
      </ng-template>

      <kendo-grid-column field="orderNumber"
                       title="№ п/п"
                       [width]="100"
                       headerClass="gridHeader dictionaries">
    
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
                1
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="structuralName"
            title="Наименование"
            [width]="300"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              Наименованиеуч.корпус №1
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="abbreviatedName"
            title="Сокр. наименование"
            [width]="200"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              кор. №1
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="displayNumber"
            title="Порядковый номер отображения корпусов"
            [width]="150"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells centerAlignment">
              1
            </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column headerClass="gridHeader dictionaries" title="" [width]="70" >
        <ng-template kendoGridCellTemplate>
          <span class="alignCells centerAlignment">
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
              rounded="full" style="text-overflow: unset; color: red;">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>

  <!--  <div style="background:  #FFFFFF;">

        <table class="table-class">
            <tr style="height: 57px; border-bottom: 1px solid #C5C5C5;">
                <td style="text-align: left;">
                    <button class="k-button k-button-sm k-rounded-md k-button-solid-success k-button-solid ng-star-inserted"
                    kendoButton
                    icon="plus"
                        >
                    Добавить
                    </button>
                </td>
            </tr>
        </table>
        
        <table class="table-class">
            <tr style="height: 57px; border-bottom: 1px solid #C5C5C5;">
                <th style="width: 10%;"> № п/п </th>
                <th style="width: 30%;"> Наименование </th>
                <th style="width: 30%;"> Сокр. наименование </th>
                <th style="width: 20%;"> Порядковый номер отображения корпусов</th>
                <th style="width: 10%;">  </th>
            </tr>
        </table>
        
        <table class="table-class">
            <tr style="height: 49px; border-bottom: 1px solid #C5C5C5;">
                <td style="width: 10%;"> 1 </td>
                <td style="width: 30%;"> Структурное 1</td>
                <td style="width: 30%;"> Стр. подр. 1</td>
                <td style="width: 20%;"> 1 </td>
                <td style="width: 10%;">
                    <button kendoButton icon="delete" fillMode="flat" size="none" class="custom-size" style="color: red;"
                    rounded="full">
                  </button> 
                </td>
            </tr>
        </table>
        
        <table class="table-class">
            <tr style="height: 57px; border-bottom: 1px solid #C5C5C5;">
                <td style="text-align: left;">
                    <button class="k-button k-button-sm k-rounded-md k-button-solid-success k-button-solid ng-star-inserted"
                    kendoButton
                    icon="plus"
                    >
                    Добавить
                    </button>
                </td>
            </tr>
        </table>
    </div>-->
</div>
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {
  CompleteApprovalStepRequest,
  MoveToApprovalStepRequest
} from "../../models/mfc/applicationForm/approvalList.model";
import {catchError} from "rxjs";
import {handleError} from "../../helpers/errorHandle-helper";
import {NotificationsService} from "../notifications/notifications.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApprovalListService {
  private baseURL = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.personApplication}/`;

  constructor(private http: HttpClient, private notificationsService: NotificationsService) { }

  public completeApprovalStep(applicationId: string, request: CompleteApprovalStepRequest) {
    return this.http.put(`${this.baseURL}${applicationId}/CompleteApprovalStep/`, request)
      .pipe(
        tap(() => this.notificationsService.showSuccess('Успешно')),
        catchError(err => {
          this.notificationsService.showError(err.error);
          return handleError(err);
        })
      );
  }

  public moveToApprovalStep(applicationId: string, request: MoveToApprovalStepRequest) {
    return this.http.put(`${this.baseURL}${applicationId}/MoveToApprovalStep/`, request)
      .pipe(
        tap(() => this.notificationsService.showSuccess('Успешно')),
        catchError(err => {
          this.notificationsService.showError(err.error);
          return handleError(err);
        })
      );
  }

  public deleteCompletedApprovalStep(applicationId: string, request: MoveToApprovalStepRequest) {
    return this.http.delete(`${this.baseURL}${applicationId}/DeleteCompletedApprovalStep/`, {body: request})
      .pipe(
        tap(() => this.notificationsService.showSuccess('Успешно')),
        catchError(err => {
          this.notificationsService.showError(err.error);
          return handleError(err);
        })
      );
  }
}
import {Component, OnInit, ViewChild} from '@angular/core';
import {
  CellClickEvent,
  GridComponent,
  GridDataResult,
  PageChangeEvent,
  PagerSettings,
  RowClassArgs
} from "@progress/kendo-angular-grid";
import {ActivatedRoute, Router} from "@angular/router";
import {GiaUserAccessService} from "../../../../services/useraccess/gia-user-access.service";
import {GiaTabsEnum} from "../../../../models/gia/enums/giaTabs.enum";
import {dictPagerSettings} from "../../../../models/dicts/pagerSettings.model";
import {EducationService} from "../../../../services/gia/education.service";
import {
  AdmissionOrderList,
  AdmissionOrderStudentList
} from "../../../../models/gia/orders/admission-order.model";
import {AdmissionOrderService} from "../../../../services/gia/admission-order.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import {CompositeFilterDescriptor, filterBy, groupBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";

@Component({
  selector: 'app-admission-order-table',
  templateUrl: './admission-order-table.component.html',
  styleUrls: ['./admission-order-table.component.scss']
})
export class AdmissionOrderTableComponent implements OnInit {

  public standardId: string = "";

  public students: AdmissionOrderStudentList[] = [];
  public admissionOrders: AdmissionOrderList[] = [];
  public orderId: string = '';
  public editable: boolean = false;

  public loading: boolean = false;
  public opened = false;

  public allItemsChecked: boolean = false;
  public arrayStudents: string[] = [];

  public pagerSettings: PagerSettings = dictPagerSettings;
  public pageSize = 20;
  public skip = 0;

  public standardName: string = "";
  public graduateYear: number = 0;
  public studyFormId: string = "";
  public facultyId: string = "";
  public admissionOrderSettingString: string = 'admission_order_grid_settings';

  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  public gridView!: GridDataResult;

  @ViewChild(GridComponent) private grid!: GridComponent;

  constructor(
    private activateRoute: ActivatedRoute,
    private admissionOrderService: AdmissionOrderService,
    private userAccessService: GiaUserAccessService,
    private router: Router,
    private educationService: EducationService,
    private notificationService: NotificationsService,
  ) {
    this.activateRoute.params.subscribe(params => {
      this.standardId = params["standardId"];
      this.graduateYear = params["graduateYear"];
      this.studyFormId = params["studyFormId"];
      this.facultyId = params["facultyId"];
      const storage = localStorage.getItem('diplomas_settings');
      if (storage !== null) {
        this.standardName = JSON.parse(storage).changeGroup;
      }
      this.getOrderStudents(this.standardId);
    });
  }

  async ngOnInit() {
    await this.getAccessLevel();
    this.getStandardInfo();
    this.getAdmissionOrders();
  }

  public getOrderStudents(standardId: string) {
    this.loading = true;
    this.admissionOrderService.getStudents(standardId, this.graduateYear, this.studyFormId, this.facultyId)
      .subscribe(response => {
        this.students = response;
        this.loading = false;
        this.getItems();
      })
  }

  public getAdmissionOrders() {
    this.admissionOrderService.getOrderList(this.standardId, this.facultyId, this.graduateYear)
      .subscribe(response => {
        this.admissionOrders = response;
      })
  }

  public async getAccessLevel(){
    this.editable = await this.userAccessService.hasEditingGia( {
      educationStandardId: this.standardId,
      dictStudyFormId: this.studyFormId,
      facultyId: this.facultyId,
      section: GiaTabsEnum.приказы_о_допуске
    });
  }

  public getStandardInfo() {
    this.educationService.getStandardInfo(this.standardId)
      .subscribe(response => {
        if (response !== null) this.standardName = response.standardName;
      })
  }

  public selectAll() {
    this.allItemsChecked = !this.allItemsChecked;
    this.allItemsChecked ? this.arrayStudents = this.students.filter(s => !s.admissionOrderId && s.notEnteredGradeCount === 0 && s.unsatisfactoryGrades === 0).map(el => el.studentId) : this.arrayStudents = [];
  }

  public checked(studentId: string) {
    return this.arrayStudents.includes(studentId);
  }

  public checkedChange(studentId: string) {
    this.getArraysForMassChange(studentId, this.arrayStudents);
    if (this.arrayStudents.length < this.students.filter(s => !s.admissionOrderId && s.notEnteredGradeCount === 0).length) this.allItemsChecked = false;
    if (this.arrayStudents.length == this.students.filter(s => !s.admissionOrderId && s.notEnteredGradeCount === 0).length) this.allItemsChecked = true;
  }

  private getArraysForMassChange(studentId: string, array: string[]) {
    const index = array.indexOf(studentId);
    index == -1 ? array.push(studentId) : array.splice(index, 1);
  }

  public cellClickHandler({ dataItem }: CellClickEvent): void {
    if (dataItem.admissionOrderId)
      this.openForm(dataItem.admissionOrderId);

    if (!dataItem.admissionOrderId && dataItem.notEnteredGradeCount === 0 && this.editable)
      this.checkedChange(dataItem.studentId);
  }

  public openForm(orderId?: string) {
    this.saveSettings();
    this.router.navigate([`gia/admissionorderform` + (orderId ? `/${orderId}` : "")], { state: { graduateYear: this.graduateYear , standard: this.standardId, students: this.students.filter(s => this.arrayStudents.includes(s.studentId)) } });
  }

  public openDialog() {
    this.opened = true;
  }

  public closeDialog(): void {
    this.opened = false;
  }

  public printOrder(orderId: string){
    this.admissionOrderService.printOrder(orderId).subscribe({
      next: (value) => {
        DownloadFile(value);
      },
      error: () => {
        this.notificationService.showError('Не удалось скачать файл');
      },
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.skip = 0;
    this.sort = sort;
    this.loadItems();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.skip = 0;
    this.filter = filter;
    this.loadItems();
  }

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.loadItems();
  }

  public loadItems() {
    this.saveSettings();
    this.gridView = {
      data: orderBy(filterBy(this.students.slice(0),this.filter),this.sort).slice(this.skip, this.skip + this.pageSize),
      total: orderBy(filterBy(this.students.slice(0),this.filter),this.sort).slice().length//this.filteredAcademicGroups.length
    };
  }

  public addToOrder(): void {
    const order = {
      admissionOrderId: this.orderId,
      students: this.arrayStudents.map(function(v){
        return {studentId: v};
      })
    };

    this.admissionOrderService.addOrderStudents(order)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.getOrderStudents(this.standardId);
          this.arrayStudents = [];
        },
        error:(error) => {
          this.notificationService.showError(error.error);
        }}
      );

    this.opened = false;
  }

  public studentDebt(studentId: string){
    this.saveSettings();
    this.router.navigateByUrl(`/gia/admissionorder/studentdebt/${studentId}`).then();
  }

  private saveSettings() {
    let admissionOrderSaves = {
      sort: this.sort,
      skip: this.skip,
      pageSize: this.pageSize,
      filter: this.filter,
    }
    localStorage.setItem(this.admissionOrderSettingString, JSON.stringify(admissionOrderSaves));
  }

  private getItems() {
    const admission_order_settings = localStorage.getItem(this.admissionOrderSettingString);
    if (admission_order_settings !== null) {
      let storage = JSON.parse(admission_order_settings)
      this.skip = storage.skip
      this.sort = storage.sort
      this.pageSize = storage.pageSize
      this.filter = storage.filter
    }
    this.loadItems();
  }

  public rowCallback(context: RowClassArgs) {
    return context.dataItem.admissionOrderId
      ? {green: true}
      : context.dataItem.notEnteredGradeCount !== 0 || context.dataItem.unsatisfactoryGrades !== 0
        ? {gray: true}
        : {green: false};
  }
}

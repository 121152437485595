import {
  Component,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  AddEvent,
  EditEvent,
  GridComponent,
  RemoveEvent,
} from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { TokenStorageService } from '../../../services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { checkRole } from '../../../helpers/token/role-check';
import { Role } from '../../../models/useraccess/role';
import {
  arrayRewrite,
  DisplayValuesInEditMode,
  valueChange,
  valueChangeBySourceName,
} from '../../../helpers/multiselect-helper';
import { GridDisplayList } from '../../../helpers/grid-helper';
import {
  dialogResultHelper,
  openRemoveDialog,
} from '../../../helpers/dialogHelper';
import { isChildOf } from '../../../helpers/elementRef-helper';
import { GiaUserAccessService } from '../../../services/useraccess/gia-user-access.service';
import {
  accessRightEnum,
  GiaUserAccess,
} from '../../../models/useraccess/gia/giaUserAccess';
import { getData$ } from '../../../../environments/environment';
import { GiaPersonService } from '../../../services/gia/person.service';
import { Person } from '../../../models/gia/person.model';
import { DictsService } from '../../../services/gia/dicts.service';
import { DictDto } from '../../../models/gia/dicts/dicts.model';
import { DepartmentService } from '../../../services/gia/department.service';
import { Department } from '../../../models/gia/dicts/department.model';
import {
  AccessRightSection,
  accessRightSections,
} from '../../../models/gia/accessright/accessrightsections';
import { PublicationsUserAccess } from '../../../models/useraccess/publications/publicationsUserAccess';

@Component({
  selector: 'app-gia-access',
  templateUrl: './gia-access.component.html',
  styleUrls: ['./gia-access.component.scss'],
})
export class GiaAccessComponent implements OnInit {
  public isAdmin: boolean;
  public virtual = {
    itemHeight: 28,
  };

  public userAccesses: GiaUserAccess[] = [];
  public studyForms: DictDto[] = [];
  public trainingLevels: DictDto[] = [];
  public departments: Department[] = [];
  public allDepartments: Department[] = [];
  public persons: Person[] = [];
  public filials: DictDto[] = [];
  public accessRightSectionList: AccessRightSection[] =
    accessRightSections.slice();

  public trainingLevelsEdit: [] = [];
  public departmentsEdit: [] = [];
  public studyFormsEdit: [] = [];
  public kafedrasEdit: [] = [];
  public filialsEdit: (string | undefined)[] = [];
  public giaSectionsEdit: string[] = [];

  public dataSources: string[] = [
    'trainingLevels',
    'departments',
    'studyForms',
    'filials',
    'giaSections',
  ];

  public filteredData: any = {};

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  public boolOptions = [
    { id: true, text: 'Да' },
    { id: false, text: 'Нет' },
  ];

  public dropdownOptions = [
    { id: 1, text: 'Чтение' },
    { id: 2, text: 'Запись' },
  ];

  public position: 'top' | 'bottom' | 'both' = 'top';
  public formGroup: FormGroup | undefined;
  public sort: SortDescriptor[] = [{ field: 'personName', dir: 'asc' }];

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;
  public loading = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: GiaUserAccess[] = [];

  constructor(
    private renderer: Renderer2,
    private dialogService: DialogService,
    private departmentService: DepartmentService,
    private notificationService: NotificationsService,
    private userAccessService: GiaUserAccessService,
    private dictService: DictsService,
    private personService: GiaPersonService,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
    this.accessRightSectionList.unshift({ title: 'Все', value: '' });
    this.accessRightSectionList.unshift({ title: 'Нет', value: 0 });
  }

  ngOnInit(): void {
    getData$.subscribe(() => this.getAccesses());
    this.getPersons();
    this.getFilials();
    this.getStudyForm();
    this.getTrainingLevels();
    this.getDepartments();
  }

  // Get persons
  private getPersons() {
    this.personService.getPersons().subscribe((value) => {
      this.persons = value;
      this.persons.forEach((item) => {
        item.fio = item.lastName + ' ' + item.firstName + ' ' + item.middleName;
      });
    });
  }

  public getPersonName(dataItem: PublicationsUserAccess) {
    return this.persons.find((item) => item.id === dataItem.personId)?.fio;
  }

  private getFilials() {
    this.dictService.getFilials().subscribe((value) => {
      this.filials = value;
      if (this.filials) {
        let item = new DictDto();
        item.id = '';
        item.shortName = 'Все';
        this.filials.unshift(item);
      }
    });
  }

  public getTrainingLevels() {
    this.dictService.getTrainingLevels().subscribe((response) => {
      this.trainingLevels = response;
      if (this.trainingLevels) {
        let item = new DictDto();
        item.id = '';
        item.name = 'Все';
        this.trainingLevels.unshift(item);
      }
    });
  }

  public getStudyForm() {
    this.dictService.getStudyForms().subscribe((response) => {
      this.studyForms = response;
      if (this.studyForms) {
        let item = new DictDto();
        item.id = '';
        item.name = 'Все';
        this.studyForms.unshift(item);
      }
    });
  }

  public getDepartments() {
    this.departmentService.getDepartments().subscribe((response) => {
      this.departments = response;
      if (this.departments) {
        let item = new Department();
        item.id = '';
        item.name = 'Все';
        item.shortName = 'Все';
        item.filialId = '';
        this.departments.unshift(item);
      }
      this.allDepartments = this.departments.slice();
    });
  }

  private getAccesses(fio?: string) {
    this.loading = true;
    this.userAccessService.getAccess(fio).subscribe((value) => {
      this.userAccesses = value;
      if (this.userAccesses) {
        for (let i = 0; i < this.userAccesses.length; i++) {
          valueChangeBySourceName(
            this.userAccesses[i].trainingLevels,
            'id',
            null,
            '',
          );
          valueChangeBySourceName(
            this.userAccesses[i].trainingLevels,
            'name',
            null,
            'Все',
          );
          valueChangeBySourceName(this.userAccesses[i].filials, 'id', null, '');
          valueChangeBySourceName(
            this.userAccesses[i].filials,
            'shortName',
            null,
            'Все',
          );
          valueChangeBySourceName(
            this.userAccesses[i].studyForms,
            'id',
            null,
            '',
          );
          valueChangeBySourceName(
            this.userAccesses[i].studyForms,
            'name',
            null,
            'Все',
          );
          valueChangeBySourceName(
            this.userAccesses[i].departments,
            'id',
            null,
            '',
          );
          valueChangeBySourceName(
            this.userAccesses[i].departments,
            'shortName',
            null,
            'Все',
          );
          if (
            this.userAccesses[i].giaSections &&
            this.userAccesses[i].giaSections.length !== 0 &&
            this.userAccesses[i].giaSections.includes(null)
          ) {
            this.userAccesses[i].giaSections = [''];
          }
        }
      }
      this.gridData = this.userAccesses;
      this.loading = false;
    });
  }

  public getList(dataItem: any, key: any) {
    return GridDisplayList(dataItem, key);
  }

  public getListTabsFromEnum(dataItem: [string | undefined | null]) {
    let string = '';
    if (dataItem.includes(string) || dataItem.includes(null)) {
      return 'Все';
    }
    for (let i = 0; i < dataItem.length; i++) {
      string +=
        this.accessRightSectionList.find((x) => x.value == dataItem[i])?.title +
        ', ';
    }
    return string.slice(0, -2);
  }

  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if (this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }

      for (let i = 0; i < this.dataSources.length; i++) {
        valueChange(this.formGroup.value[this.dataSources[i]], '', null);
      }

      this.formGroup.value.id === ''
        ? this.userAccessService.createAccess(this.formGroup.value)
        : this.userAccessService.updateAccess(this.formGroup.value);
    }
    this.closeEditor(this.grid);
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    const targetKey: string = 'id';

    // Вывод данных в редактирование
    for (let i = 0; i < this.dataSources.length; i++) {
      this.filteredData[this.dataSources[i]] = (this as any)[this.dataSources[i]];
      (this as any)[`${this.dataSources[i]}Edit`] = DisplayValuesInEditMode(
        dataItem[this.dataSources[i]],
        [targetKey],
      );
    }
    this.giaSectionsEdit = dataItem.giaSections;

    this.isLine = true;
    this.formGroup = formGroup(dataItem);
    this.filialFilter(this.filialsEdit);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  //Finish editing
  private closeEditor(
    grid: GridComponent,
    rowIndex = this.editedRowIndex,
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.trainingLevelsEdit = [];
    this.departmentsEdit = [];
    this.studyFormsEdit = [];
    this.kafedrasEdit = [];
    this.filialsEdit = [];

    this.formGroup = formGroup({
      id: '',
      personId: '',
      trainingLevels: '',
      studyForms: '',
      departments: '',
      filials: '',
      giaSections: '',
      giaProtocolDesigner: false,
      printTemplateAccess: false,
      dictCreating: false,
      dict: 1,
      accessSettings: false,
      localSignatory: false,
    });

    this.isLine = true;
    this.isNew = true;
    this.filialFilterClean();
    sender.addRow(this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const personName = this.getPersonName(dataItem);
    const dialog: DialogRef = openRemoveDialog(this.dialogService,`${personName} из настроек доступа`);
    this.opened = dialogResultHelper(dialog.result, this.userAccessService.deleteAccess(dataItem.id), this.notificationService);
  }

  public clearData() {
    this.searchFIO = '';
    this.filterData(this.searchFIO);
  }

  public filterData(fio: string) {
    this.getAccesses(fio);
  }

  public valueChange(value: any, editItem: any) {
    (this as any)[`${editItem}`] = arrayRewrite(value);

    if (
      editItem == 'giaSectionsEdit' &&
      value.includes(accessRightEnum.Forbidden)
    ) {
      (this as any)[`${editItem}`] = value.filter((_: number) =>
        value[value.length - 1] === accessRightEnum.Forbidden
          ? _ === accessRightEnum.Forbidden
          : _ !== accessRightEnum.Forbidden,
      );
    }
    if (editItem == 'filialsEdit') {
      this.filialFilter(this.filialsEdit);
    }
  }

  public filialFilter(value: (string | undefined)[]) {
    if (value.includes('') || value.length === 0) {
      this.filialFilterClean();
      return;
    }

    this.departments = [
      ...this.allDepartments.filter(
        (x) => x.filialId == '' || value.includes(x.filialId),
      ),
    ];
  }

  public filialFilterClean() {
    this.departments = [...this.allDepartments.slice()];
  }

  public getAccessLevelName(accessLevel: number): string {
    return this.dropdownOptions.find((value) => value.id === accessLevel)?.text ?? '';
  }


  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    let element = (event as any).target;
    this.dataSources.forEach((item) => {
      if(isChildOf(element, item)) {
        this.filteredData[item] = (this as any)[item];
      }
    })
  }

  public onTagClick(items: any, sourceName: string) {
    this.filteredData[sourceName] = items;
    // console.log(this.filteredData);
  }

}

const formGroup = (dataItem: {
  id?: string;
  personId?: string;
  giaSections?: string;
  giaProtocolDesigner?: boolean;
  printTemplateAccess?: boolean;
  dictCreating?: boolean;
  dict?: number;
  accessSettings?: boolean;
  localSignatory?: boolean;
  studyForms?: string;
  trainingLevels?: string;
  departments?: string;
  filials?: string;
}) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    trainingLevels: new FormControl(
      dataItem.trainingLevels,
      Validators.required,
    ),
    studyForms: new FormControl(dataItem.studyForms, Validators.required),
    departments: new FormControl(dataItem.departments, Validators.required),
    filials: new FormControl(dataItem.filials, Validators.required),
    giaSections: new FormControl(dataItem.giaSections, Validators.required),
    giaProtocolDesigner: new FormControl(
      dataItem.giaProtocolDesigner,
      Validators.required,
    ),
    printTemplateAccess: new FormControl(
      dataItem.printTemplateAccess,
      Validators.required,
    ),
    dictCreating: new FormControl(dataItem.dictCreating, Validators.required),
    dict: new FormControl(dataItem.dict, Validators.required),
    accessSettings: new FormControl(
      dataItem.accessSettings,
      Validators.required,
    ),
    localSignatory: new FormControl(
      dataItem.localSignatory,
      Validators.required,
    ),
  });

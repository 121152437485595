import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {
  AddReleaseOrder,
  ReleaseOrder, ReleaseOrderList,
  ReleaseOrderStudentList,
  ReleaseSearchStudents, SaveReleaseOrderStudent, UpdateReleaseOrder
} from "../../models/gia/orders/release-order.model";
import {OrderInfo} from "../../models/gia/education/standard.model";
import {StudentSearchQuery} from "../../models/gia/orders/student-search-query.model";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/publications/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class ReleaseOrderService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.gia.releaseOrder}`;

  constructor(private http: HttpClient) {}

  public getStudents(standardId: string, graduateYear: number, studyFormId: string, facultyId: string) {
    let params = new HttpParams();
    params = params.append('EducationStandardId', standardId);
    params = params.append('GraduateYear', graduateYear);
    params = params.append('StudyFormId', studyFormId);
    params = params.append('FacultyId', facultyId);
    return this.http.get<ReleaseOrderStudentList[]>(this.baseUrl + '/GetAllStudents', {params});
  }

  public getOrder(orderId: string) {
    let params = new HttpParams();
    params = params.append('ReleaseOrderId', orderId);
    return this.http.get<ReleaseOrder>(this.baseUrl + '/GetOrder', {params});
  }

  public studentSearch(query: StudentSearchQuery) {
    const filter = (`&TrainingLevelId=${query.trainingLevelId}`)
      + (query.facultyId ? `&FacultyId=${query.facultyId}` :'')
      + (query.filialId ? `&FilialId=${query.filialId}` :'')
      + (query.studyFormId ? `&StudyFormId=${query.studyFormId}` :'')
      + (query.educationStandardId ? `&EducationStandardId=${query.educationStandardId}` :'')
      + (query.studentFio ? `&StudentFio=${query.studentFio}` :'')
      + (query.graduateYear ? `&GraduateYear=${query.graduateYear}` :'')
      + (query.standardName ? `&StandardName=${query.standardName}` :'');
    return this.http.get<ReleaseSearchStudents[]>(this.baseUrl + '/StudentSearch' + `/?${filter}`);
  }

  public getOrderInfo(orderId: string) {
    return this.http.get<OrderInfo>(this.baseUrl +'/GetOrderInfo/' + orderId);
  }

  public addOrderStudents(data: SaveReleaseOrderStudent) {
    return this.http.post(this.baseUrl + '/SaveStudent', data);
  }

  public addAdmissionOrder(data: AddReleaseOrder) {
    return this.http.post<string>(this.baseUrl, data);
  }

  public updateAdmissionOrder(data: UpdateReleaseOrder) {
    return this.http.put<string>(this.baseUrl, data);
  }

  public printOrder(orderId: string) {
    return this.http.get(`${this.baseUrl}/Print/${orderId}`, { responseType: 'blob', observe: 'response', }).pipe(catchError(handleError));
  }

  public getOrderList(standardId: string, facultyId: string, graduateYear: number) {
    let params = new HttpParams();
    params = params.append('EducationStandardId', standardId);
    params = params.append('FacultyId', facultyId);
    params = params.append('GraduateYear', graduateYear);
    return this.http.get<ReleaseOrderList[]>(this.baseUrl + '/GetOrderList', {params});
  }
}

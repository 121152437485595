export enum DisciplineTypeEnum {
  d = 1,
  up,
  pp,
  gia,
  vkr,
  efk,
  pdp,
  dd,
  nid,
}

export const disciplineTypeEnumList: {text: string, value: DisciplineTypeEnum}[] = [
  {text: 'Дисциплина', value: DisciplineTypeEnum.d},
  {text: 'Учебная практика', value: DisciplineTypeEnum.up},
  {text: 'Производственная практика', value: DisciplineTypeEnum.pp},
  {text: 'ГИА', value: DisciplineTypeEnum.gia},
  {text: 'Выпускная квалификационная работа', value: DisciplineTypeEnum.vkr},
  {text: 'Элективная физическая культура', value: DisciplineTypeEnum.efk},
  {text: 'Преддипломная практика', value: DisciplineTypeEnum.pdp},
  {text: 'Дополнительные дисциплины', value: DisciplineTypeEnum.dd},
  {text: 'Научно-исследовательская деятельность', value: DisciplineTypeEnum.nid},
];

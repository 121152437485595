<education-header></education-header>
<div class="header">
  <h1>Выбор учебного плана</h1>
</div>

<div class="grid-container">
  <kendo-grid
    #EProgramGrid
    [kendoGridBinding]="gridData"
    [pageable]="{
      info: info,
      type: type,
      pageSizes: pageSizes,
      previousNext: previousNext,
      position: position
    }"
    [pageSize]="pageSize"
    [skip]="skip"
    [sortable]="true"
    [sort]="sort"
    [filterable]="true"
    (cellClick)="onClick($event)"
    [loading]="loading"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="directionTraining" title="Направление подготовки">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment" style="text-align: left; justify-content: left;">
          {{dataItem.directionTraining}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <!--kendo-grid-column-- headerClass="gridHeader dictionaries" field="educationProgramCipher" title="Шифр ОП" [width]="120">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.educationProgramCipher}}
        </span>
      </ng-template>
    </kendo-grid-column-->
    <kendo-grid-column headerClass="gridHeader dictionaries" field="educationProgramNameWithCipher" title="Образовательная программа">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.educationProgramNameWithCipher}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <!--kendo-grid-column field="educationLevelName" title="Уровень образования">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="eLevels"
          textField="educationLevelName"
          valueField="educationLevelId"
        >
        </filter-dropdown>
      </ng-template>
    </kendo-grid-column-->
    <!--kendo-grid-column headerClass="gridHeader dictionaries" field="studyFormName" title="Форма обучения" [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="studyforms"
          textField="studyFormName"
          valueField="studyFormId"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.studyFormName}}
        </span>
      </ng-template>
    </kendo-grid-column-->
    <kendo-grid-column headerClass="gridHeader dictionaries" field="trainingLevelName" title="Уровень подготовки" [width]="180">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [userAccessValue]="true"
          [userAccessSource]="'trainingLevel'"
          [data]="trainingLevels"
          textField="trainingLevelName"
          valueField="trainingLevelId"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.trainingLevelName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="facultyName" title="Институт/факультет" [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="faculties"
          [userAccessValue]="true"
          [userAccessSource]="'faculties'"
          textField="facultyName"
          valueField="facultyName"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.facultyName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="educationStandardTypeName" title="Тип стандарта" [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="eStandardTypes"
          textField="educationStandardTypeName"
          valueField="educationStandardTypeId"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.educationStandardTypeName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="studyFormName" title="Форма обучения" [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="eStudyForms"
          [useVirtual]='false'
          textField="studyFormName"
          valueField="studyFormName"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.studyFormName}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="yearAdmission" title="Год поступления" [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="eYears"
          textField="yearAdmission"
          valueField="yearAdmission"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.yearAdmission}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="educationPlanName" title="Название плана">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
                [column]="column"
                [filter]="filter"
                [showOperators]="false"
                operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.educationPlanName}}
          </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader dictionaries" field="status" title="Статус плана" [width]="150" class="statusColumn">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="eStatuses"
          textField="statusName"
          valueField="status"
        >
        </filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [ngClass]="{
          alignCells: true,
          yellow: dataItem.status == 1,
          green: dataItem.status == 2,
          gray: dataItem.status == 3
        }">
          <span>{{planStatusText(dataItem.status)}}</span>
        </div>
      </ng-template>
    </kendo-grid-column>
    <!--<kendo-grid-column headerClass="gridHeader dictionaries" title="Статус плана" [width]="180">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell
          [column]="column"
          [filter]="filter"
          [showOperators]="false"
          operator="contains"
        >
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ul class="select-eprogram-eplan-status">
          <li
            *ngFor="let item of dataItem.educationPlans"
            [ngClass]="{
              yellow: item.status == 1,
              green: item.status == 2,
              gray: item.status == 3
            }"
          >
            {{ item.yearAdmission }} {{ item.studyFormName }} {{ item.accelerated }}
          </li>
        </ul>
      </ng-template>
    </kendo-grid-column>-->
  </kendo-grid>
</div>

<blanks-settings></blanks-settings>
<h1>Добавление бланков</h1>

<form [formGroup]="Form" novalidate>
  <div class="selectContent w-700 m-t20">
    <dd class="list">
      <span class="selectText">Тип бланка</span>
      <kendo-dropdownlist class="w-400"
                          [data]="blankTypes"
                          textField="name"
                          valueField="externalId"
                          [valuePrimitive]="true"
                          formControlName="dictBlankTypeId"
                          (valueChange)="onChange($event)"
                          [(ngModel)]="dictBlankTypeId"
                          >
      </kendo-dropdownlist>
      <span class="required m-l5">*</span>
    </dd>

    <dd class="list">
      <span class="selectText">Шаблон</span>
      <kendo-dropdownlist class="w-400"
                          [data]="blankTemplates"
                          textField="name"
                          valueField="externalId"
                          [valuePrimitive]="true"
                          formControlName="dictBlankTemplateId"
                          (valueChange)="onChange($event)"
                          [(ngModel)]="dictBlankTemplateId"
                          >
      </kendo-dropdownlist>
      <span class="required m-l5">*</span>
    </dd>

    <dd class="list">
      <span class="selectText">Серия бланков</span>
     <kendo-numerictextbox
              class="w-400"
              [min]="100000"
              [max]="999999"
              [maxlength]="6"
              format="##"
              [decimals]="0"
              formControlName="series"
              (valueChange)="onChange($event)"
              [(ngModel)]="series">
      </kendo-numerictextbox>
      <span class="required m-l5">*</span>
    </dd>

    <dd class="list">
      <span class="selectText">Номер бланка / первого бланка</span>
      <kendo-textbox class="w-400"
              [clearButton]="true"
              [maxlength]="7"
              [minlength]="7"
              formControlName="numberFirstBlank"
              (valueChange)="onChange($event)"
              [(ngModel)]="numberFirstBlank">
      </kendo-textbox>
      <span class="required m-l5">*</span>
    </dd>

    <dd class="list">
      <span class="selectText">Количество бланков</span>
      <kendo-numerictextbox
          class="w-400"
          [min]="1"
          format="n0"
          [decimals]="0"
          formControlName="count"
          (valueChange)="onChange($event)"
          [(ngModel)]="count">
      </kendo-numerictextbox>
      <span class="required m-l5">*</span>
    </dd>
  </div>
</form>

<div class="error m-l280" *ngIf="this.existBlanks !== ''">
    <span>{{ existBlanks }}</span>
    <span kendoTooltip class="m-l5 tooltip k-icon k-i-info k-icon-md"
          [tooltipWidth]="300"
          showOn="click"
          [closable]="true"
          [title]="tooltip"
          position="right"
          ></span>
</div>

<div class="buttons m-t20 w-680">
    <button kendoButton (click)="cancel()">Отмена</button>
    <button kendoButton themeColor="primary" class="m-l15" [disabled]="Form!.invalid" (click)="addBlanks()">Добавить</button>
</div>
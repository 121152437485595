import {Injectable} from "@angular/core";
import {AuthorRolesService} from "../../../../../services/science/publications/authorRoles.service";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {FormGroup} from "@angular/forms";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {Observable} from "rxjs";
import {getData$} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PublicationsAuthorRolesMethods {
  constructor(
    private authorRolesService: AuthorRolesService,
    private notificationService: NotificationsService
  ) {}

  async saveAuthorRole(isNew: boolean, formGroup: FormGroup) {
    if (isNew) {
      this.authorRolesService.addAuthorRole(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: (error) => this.notificationService.showError(error)
      })
    }
    else {
      this.authorRolesService.updateAuthorRole(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: (error) => this.notificationService.showError(error)
      })
    }
  }

  removeAuthorRole(dialogResult: Observable<DialogResult>, dataItem: any) {
    dialogResult.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.authorRolesService.removeAuthorRole(dataItem.id)
          .subscribe({
            next: () => {
              getData$.next(true);
              this.notificationService.showSuccess('Успешно');
            },
            error: (error) => this.notificationService.showError(error)
          });
      }
    });
  }
}

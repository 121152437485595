import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BlankTypesModel} from '../../models/diplomablanks/dicts.model';

@Injectable({
    providedIn: 'root'
})

export class DictBlankTypeService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.dict}`;

  constructor(private http: HttpClient) {}

  public getBlankTypes() {
    return this.http.post<BlankTypesModel[]>(this.baseUrl + '/blanktype/getAll', {});
  }

  public addBlankType(data: BlankTypesModel) {
    return this.http.post(this.baseUrl + '/blanktype', data);
  }

  public updateBlankType(data: BlankTypesModel) {
    return this.http.put(this.baseUrl + '/blanktype', data);
  }

  public deleteBlankType(id: string) {
    return this.http.delete(this.baseUrl + '/blanktype/' + id)
  }
}
<div class="content-listbox">
  <kendo-grid class="grid-data borderless hidden-columns"
              [data]="hiddenColumns" [height]="300"
              (cellClick)="onItemClick($event, false)">
    <ng-container *ngFor="let column of info.hidden">
      <kendo-grid-column [field]="column.field" [title]="column.title" [width]="column.width">
        <ng-template kendoGridHeaderTemplate *ngIf="column.tooltip">
          <div class="tooltip-header">
            {{ column.title }}
            <kendo-icon kendoTooltip
                        [tooltipWidth]="450"
                        [title]="column.tooltip"
                        name="info"
                        tooltipClass="mfc-tooltip"
                        position="top"
                        size="medium">
            </kendo-icon>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-dataItem
                     *ngIf="column.type === FieldType.Text">
          <span [ngClass]="{'selected': !selectedRow?.visible && selectedRow?.rowIndex === rowIndex}">
            {{ column.map ? column.map.get(dataItem[column.field]) : dataItem[column.field] }}
          </span>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
  </kendo-grid>
  <div class="toolbar">
    <div class="toolbar__group">
      <button kendoButton icon="arrow-e"
              [disabled]="transferButtonDisabled"
              (click)="transferFrom()">
      </button>
      <button kendoButton icon="arrow-double-60-right"
              [disabled]="!editable || !hiddenColumns.length"
              (click)="transferAllTo()">
      </button>
    </div>
    <div class="toolbar__group" *ngIf="movable">
      <button kendoButton icon="arrow-n"
              [disabled]="moveButtonDisabled || selectedRow?.rowIndex === 0"
              (click)="move(true)">
      </button>
      <button kendoButton icon="arrow-s"
              [disabled]="moveButtonDisabled || selectedRow?.rowIndex === visibleColumns.length - 1"
              (click)="move()">
      </button>
    </div>
  </div>
  <kendo-grid class="grid-data borderless"
              [ngClass]="{'hide-scroll': !visibleColumns.length}"
              [style]="{'max-width': gridMaxWidth + 'px'}"
              [data]="visibleColumns" [height]="300"
              (cellClick)="onItemClick($event)">
    <ng-container *ngFor="let column of info.visible">
      <kendo-grid-column [field]="column.field" [title]="column.title" [width]="column.width">
        <ng-template kendoGridHeaderTemplate *ngIf="column.tooltip">
          <div class="tooltip-header">
            {{ column.title }}
            <kendo-icon kendoTooltip
                        [tooltipWidth]="450"
                        [title]="column.tooltip"
                        name="info"
                        tooltipClass="mfc-tooltip"
                        position="top"
                        size="medium">
            </kendo-icon>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" [ngSwitch]="column.type">
          <span *ngSwitchCase="FieldType.Text"
                [ngClass]="{'selected': selectedRow?.visible && selectedRow?.rowIndex === rowIndex}">
            <ng-container *ngIf="column.objectField; else defaultValue">
              {{dataItem[column.field] ? dataItem[column.field][column.objectField] : ''}}
            </ng-container>
            <ng-template #defaultValue>
              {{ column.map ? column.map.get(dataItem[column.field]) : dataItem[column.field] }}
            </ng-template>
          </span>
          <div class="field">
            <kendo-textbox *ngSwitchCase="FieldType.Textbox"
                           [ngClass]="{
                             error: column.required
                               && !dataItem[column.field]
                           }"
                           [disabled]="!editable"
                           [(value)]="dataItem[column.field]"
                           (afterValueChanged)="onChange({field: column.field, rowIndex: rowIndex})"
                           [clearButton]="true">
            </kendo-textbox>

            <kendo-dropdownlist *ngSwitchCase="FieldType.Dropdown"
                                [ngClass]="{
                                  error: column.required
                                    && dataItem[column.field] == null
                                }"
                                [data]="column.listOptions?.data ?? []"
                                [textField]="column.listOptions?.textField ?? ''"
                                [valueField]="column.listOptions?.valueField ?? ''"
                                [valuePrimitive]="true"
                                [(value)]="dataItem[column.field]"
                                (valueChange)="onChange({field: column.field, rowIndex: rowIndex})"
                                [disabled]="!editable">
            </kendo-dropdownlist>

            <kendo-combobox *ngSwitchCase="FieldType.Combobox"
                            [ngClass]="{
                              error: column.required
                                && dataItem[column.field] == null
                            }"
                            [data]="column.listOptions?.data ?? []"
                            [textField]="column.listOptions?.textField ?? ''"
                            [valueField]="column.listOptions?.valueField ?? ''"
                            [valuePrimitive]="true"
                            [kendoDropDownFilter]="filterSettings"
                            [(value)]="dataItem[column.field]"
                            (valueChange)="onChange({field: column.field, rowIndex: rowIndex})"
                            [disabled]="!editable">
            </kendo-combobox>

            <kendo-multiselect *ngSwitchCase="FieldType.Multiselect"
                               [ngClass]="{
                                 error: column.required
                                   && !dataItem[column.field].length
                               }"
                               [data]="column.listOptions?.data ?? []"
                               [textField]="column.listOptions?.textField ?? ''"
                               [valueField]="column.listOptions?.valueField ?? ''"
                               [valuePrimitive]="true"
                               [kendoDropDownFilter]="filterSettings"
                               [(value)]="dataItem[column.field]"
                               (valueChange)="onChange({field: column.field, rowIndex: rowIndex})"
                               [disabled]="!editable">
              <ng-template kendoMultiSelectTagTemplate let-dataItem>
                <span *ngIf="column.listOptions" [title]="dataItem[column.listOptions.textField]" class="template">
                  {{ dataItem[column.listOptions.textField] }}
                </span>
              </ng-template>
            </kendo-multiselect>
            <span class="required" *ngIf="column.required">*</span>
          </div>
        </ng-template>
      </kendo-grid-column>
    </ng-container>
    <kendo-grid-column [width]="40" *ngIf="editable">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <kendo-icon class="delete-icon" name="close" size="medium"
                    (click)="transferTo(dataItem, rowIndex)">
        </kendo-icon>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {CreateSessionResponse, CryptoArmDirectOperationResult} from "../../models/mfc/cryptoarm-model";
import {CryptoArmSignStatusEnum} from "../../models/mfc/enums/cryptoarm-sign-status.enum";

@Injectable({
  providedIn: 'root'
})
export class CryptoArmService {

  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.cryptoArm}/`;

  constructor(private http: HttpClient) { }

  // Создать сессию подписания
  createSession(approvalStepId: string) {
    return this.http.post<CreateSessionResponse>(`${this.baseUrl}session/`, {approvalStepId: approvalStepId});
  }

  // Получить статус подписания
  getSigningStatus(sessionId: string) {
    return this.http.get<CryptoArmSignStatusEnum>(`${this.baseUrl}session/${sessionId}/status`);
  }

  // Получить параметры подписания
  getSigningOptions(id: string) {
    return this.http.post(`${this.baseUrl}signing-options`, {params: id});
  }

  // Отправить результаты подписания
  uploadSigningResult(result: CryptoArmDirectOperationResult) {
    return this.http.post(`${this.baseUrl}signing-result`, result);
  }

  // Загрузить существующий заполненный документ
  getFile(documentId: string) {
    return this.http.get(`${this.baseUrl}document`);
  }
}
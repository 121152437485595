import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject, catchError, Observable, of, skip, switchMap} from 'rxjs';
import { environment } from '../../../environments/environment';
import { TreeCommonModel } from '../../models/middlecontrol/trees.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import {MiddleControlTreeQuery} from "../../models/middlecontrol/query.model";

@Injectable({
  providedIn: 'root',
})
export class TreesService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.session.trees}`;

  public departmentTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  public studentTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  public disciplineTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  public trainingLevelTree$ = new BehaviorSubject<TreeCommonModel[]>([]);
  protected triggerDepartmentTreeFetch$ = new BehaviorSubject<MiddleControlTreeQuery>(new MiddleControlTreeQuery);
  protected triggerStudentTreeFetch$ = new BehaviorSubject<MiddleControlTreeQuery>(new MiddleControlTreeQuery);
  protected triggerDisciplineTreeFetch$ = new BehaviorSubject<MiddleControlTreeQuery>(new MiddleControlTreeQuery);

  constructor(
    private http: HttpClient,
    private notification: NotificationsService
  ) {
    this.triggerDepartmentTreeFetch$.pipe(
      skip(1),
      switchMap((request) => {
        return this.getDepartmentTree(request)
      }))
      .subscribe((response) => {
        this.departmentTree$.next(response);
      });

    this.triggerStudentTreeFetch$.pipe(
      skip(1),
      switchMap((request) => {
        return this.getStudentTree(request)
      }))
      .subscribe((response) => {
        this.studentTree$.next(response);
      });

    this.triggerDisciplineTreeFetch$.pipe(
      skip(1),
      switchMap((request) => {
        return this.getDisciplineTree(request)
      }))
      .subscribe((response) => {
        this.disciplineTree$.next(response);
      });
  }

  public getDepartmentTree(request: MiddleControlTreeQuery): Observable<TreeCommonModel[]> {
    return this.http
      .post<TreeCommonModel[]>(
        this.baseUrl +
          `/GetDepartmentTree`, request
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notification.showError('Не удалось получить список факультетов');
          return of([]);
        })
      );
  }

  public updateDepartmentTree(request: MiddleControlTreeQuery) {
    this.triggerDepartmentTreeFetch$.next(request);
  }

  public getStudentTree(request: MiddleControlTreeQuery): Observable<TreeCommonModel[]> {
    return this.http
      .post<TreeCommonModel[]>(
        this.baseUrl +
          `/GetStudentTree`, request
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notification.showError('Не удалось получить список факультетов');
          return of([]);
        })
      );
  }

  public updateStudentTree( request: MiddleControlTreeQuery) {
    this.triggerStudentTreeFetch$.next(request);
  }

  public getDisciplineTree(request: MiddleControlTreeQuery): Observable<TreeCommonModel[]> {
    return this.http
      .post<TreeCommonModel[]>(
        this.baseUrl +
          `/GetDisciplineTree`, request
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notification.showError('Не удалось получить список факультетов');
          return of([]);
        })
      );
  }

  public updateDisciplineTree(request: MiddleControlTreeQuery) {
    this.triggerDisciplineTreeFetch$.next(request);
  }

  public getTrainingLevelTree(): Observable<TreeCommonModel[]> {
    return this.http
      .get<TreeCommonModel[]>(this.baseUrl + '/GetTrainingLevelTree')
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notification.showError(
            'Не удалось получить список уровней образования'
          );
          return of([]);
        })
      );
  }

  public updateTrainingLevelTree() {
    this.getTrainingLevelTree().subscribe((response) => {
      this.trainingLevelTree$.next(response);
    });
  }
}

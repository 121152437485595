<div class="toggle">
  <button
    *ngFor="let attestation of dictAttestations"
    kendoButton
    (click)="onToggleClick(attestation.id)"
    [class]="{
      toggle__button: true,
      'toggle__button--active': toggleStep === attestation.id
    }"
  >
    {{ attestation.name }}
  </button>

  <button
    kendoButton
    (click)="onToggleClick(null)"
    [class]="{
      toggle__button: true,
      'toggle__button--active': toggleStep === null
    }"
  >
    1 и 2 контрольные недели
  </button>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Phones } from '../../models/contingent/phones.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';
import {Spravka, SpravkaStudyPeriod} from "../../models/contingent/spravka.model";

@Injectable({
  providedIn: 'root'
})
export class SpravkaService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.spravka}`;

  constructor(private http: HttpClient) { }

  //Get all spravka
  public getAllspravka(): Observable<Spravka[]> {
    return this.http.get<Spravka[]>(this.baseUrl);
  }

  //Get By Id spravka
  getByIdspravka(id: string): Observable<Spravka> {
    return this.http.get<Spravka>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  downloadFile(fileName: string) {
    return this.http.get(this.baseUrl + '/GetPrintForm/' + fileName, { responseType: 'blob' } )
  }

  //Get By StudPersonId
  getStudentSpravka(id: Guid): Observable<Spravka[]> {
    return this.http.get<Spravka[]>(this.baseUrl + '/getspravkabystudentid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getStudyPeriod(id: Guid): Observable<SpravkaStudyPeriod> {
    return this.http.get<SpravkaStudyPeriod>(this.baseUrl + '/GetSpravkaDataByStudentId' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add spravka
  addCertificate(dto: Spravka): Observable<Spravka> {
    return this.http.post<Spravka>(this.baseUrl + "/CreateCertificate", dto)
      .pipe(catchError(this.handleError));
  }

  addCertificateMilitary(dto: Spravka): Observable<Spravka> {
    return this.http.post<Spravka>(this.baseUrl + "/CreateMilitaryCertificate", dto)
      .pipe(catchError(this.handleError));
  }

  //Delete spravka
  deleteCertificate(id: string, studentId : Guid| undefined): Observable<Spravka> {
    return this.http.delete<Spravka>(this.baseUrl + '/DeleteCertificate/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  deleteCertificateMilitary(id: string, studentId : Guid| undefined): Observable<Spravka> {
    return this.http.delete<Spravka>(this.baseUrl + '/DeleteMilitaryCertificate/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit spravka
  updateCertificate(dto: Spravka): Observable<Spravka> {
    return this.http.put<Spravka>(this.baseUrl + '/EditCertificate/' + dto.spravkaExternalId, dto)
      .pipe(catchError(this.handleError));
  }

  updateCertificateMilitary(dto: Spravka): Observable<Spravka> {
    return this.http.put<Spravka>(this.baseUrl + '/EditMilitaryCertificate/' + dto.spravkaExternalId, dto)
      .pipe(catchError(this.handleError));
  }

  getNewNumber(military: boolean): Observable<string> {
    return this.http.post(this.baseUrl + '/getnewnumber' + '/' + military,{}, {responseType: 'text'})
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}


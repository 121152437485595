<gia-settings></gia-settings>

<h1>Отчеты</h1>

<div class="k-display-flex">
  <kendo-dropdownlist placeholder="Список отчетов"
                      textField="name" valueField="path"
                      style="width: 30%; max-width: 500px;"
                      [valuePrimitive]="true"
                      [data]="reportTemplatesList"
                      [(ngModel)]="reportPath"
  ></kendo-dropdownlist>
  <button kendoButton
          class="k-ml-2"
          themeColor="primary"
          style="max-width: 200px;"
          (click)="openReport()"
  >Открыть</button>
</div>


import {Routes} from "@angular/router";
import {PublicationsHomeComponent} from "../components/science/publications/mainPage/home/publications-home.component";
import {PublicationsSummaryInfoComponent} from "../components/science/publications/summaryInfo/publications-summary-info.component";
import {PublicationsAuthorsHomeComponent} from "../components/science/publications/authors/home/publications-authors-home.component";
import {PublicationsAuthorsEditFormComponent} from "../components/science/publications/authors/editForm/publications-authors-edit-form.component";
import {PublicationsAuthorCardComponent} from "../components/science/publications/authors/author-card/publications-author-card.component";
import {PublicationsReportsComponent} from "../components/science/publications/reports/publications-reports.component";
import {PublicationsEditFormHomeComponent} from "../components/science/publications/editForm/home/publications-edit-form-home.component";
import {PublicationsPostsComponent} from "../components/science/publications/dicts/posts/publications-posts.component";
import {PublicationsScientometricBasesComponent} from "../components/science/publications/dicts/scientometricBases/publications-scientometric-bases.component";
import {PublicationsAuthorRolesComponent} from "../components/science/publications/dicts/authorRoles/publications-author-roles.component";
import {PublicationsScienceDegreesComponent} from "../components/science/publications/dicts/scienceDegrees/publications-science-degrees.component";
import {PublicationsSubTypesComponent} from "../components/science/publications/dicts/publicationsSubTypes/publications-sub-types.component";
import {PublicationsTypesComponent} from "../components/science/publications/dicts/publicationsTypes/publications-types.component";
import {PublicationsEditFormAddAuthorComponent} from "../components/science/publications/editForm/addAuthor/publications-edit-form-add-author.component";
import {PublicationsAccessComponent} from "../components/useraccess/publications-access/publications-access.component";
import {PublicationsDictComponent} from "../components/science/publications/dicts/dict/publications-dict.component";
import {PublicationsRoleGuard} from "../guards/publications-role.guard";
import {PublicationsUserAccessGuard} from "../guards/publications-user-access.guard";
import {PublicationsOrganizationsComponent} from "../components/science/publications/dicts/organizations/publications-organizations.component";

// Пути в разделе "Публикации"
export const PublicationsRoutes: Routes = [
    { path: 'publications', component: PublicationsHomeComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/authors', component: PublicationsAuthorsHomeComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/authors/summaryInfo/:authorId', component: PublicationsSummaryInfoComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/authors/addAuthor', component: PublicationsAuthorsEditFormComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/authors/addAuthorForPublication', component: PublicationsEditFormAddAuthorComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/authors/:authorId/addAuthorForPublication', component: PublicationsEditFormAddAuthorComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/authors/addAuthorForPublication/:index', component: PublicationsEditFormAddAuthorComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/authors/authorCard/:id', component: PublicationsAuthorCardComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/reports', component: PublicationsReportsComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/newPublication', component: PublicationsEditFormHomeComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/newPublication/:publicationId', component: PublicationsEditFormHomeComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dictPosts', component: PublicationsPostsComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dictScientometricBases', component: PublicationsScientometricBasesComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dictAuthorRoles', component: PublicationsAuthorRolesComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dictScienceDegrees', component: PublicationsScienceDegreesComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dictPublicationSubTypes', component: PublicationsSubTypesComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dictPublicationTypes', component: PublicationsTypesComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dictOrganizations', component: PublicationsOrganizationsComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/dict/:dictId', component: PublicationsDictComponent, canActivate: [PublicationsRoleGuard]},
    { path: 'publications/userAccess', component: PublicationsAccessComponent, canActivate: [PublicationsUserAccessGuard]}
]

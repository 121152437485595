import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AnnouncementUserAccess } from '../../models/useraccess/announcement/announcementuseraccess.model';
import { environment } from '../../../environments/environment';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AnnouncementUserAccessService {
  baseUrl = `${environment.announcementApiEndpoint}${environment.apiPaths.announcement.base}`;

  constructor(private http: HttpClient) { }

  //Get access
  public getAccess(personId?: string): Observable<AnnouncementUserAccess[]> {
    let params = new HttpParams();
    if(personId != '') params = params.append('personId', personId ?? '');;
    return this.http.get<AnnouncementUserAccess[]>(this.baseUrl + '/GetAccess', { params })
      .pipe(
        catchError(this.handleError));
  }

  public getUserAccessLevel() {
    return this.http.get(this.baseUrl + '/GetAccessLevel')
      .pipe(map(res => !!res));
  }

  //Save access
  public saveAccess(useraccess: AnnouncementUserAccess): Observable<AnnouncementUserAccess> {
    return this.http.post<AnnouncementUserAccess>(this.baseUrl + '/SaveAccess/', useraccess)
      .pipe(catchError(this.handleError));
  }

  //Delete access
  public deleteAccess(id: string): Observable<AnnouncementUserAccess> {
    return this.http.delete<AnnouncementUserAccess>(this.baseUrl + '/DeleteAccess/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import { AllowedProperty } from '../../../../../../models/publications/editForm/property.model';
import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';

@Pipe({
  name: 'changePropertyOrder'
})
export class ChangePropertyOrderPipe implements PipeTransform {
  transform(property: AllowedProperty, formProperties: FormArray): number {
    return formProperties.value.findIndex((item : AllowedProperty) => item.id === property.id);
  }
}
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {OrderCategoryEnum, OrdersCategory, OrdersCategoryWithDsl} from '../../models/contingent/orderscategory.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OrderscategoryService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.orderscategory}`;

  constructor(private http: HttpClient) { }

  //Get all orderscategory
  public getAllorderscategory(visible: boolean): Observable<OrdersCategory[]> {
    return this.http.get<OrdersCategory[]>(this.baseUrl + "/GetAllDictOrdersCategory/" + visible);
  }

  //Add orderscategory
  addorderscategory(orderscategory: OrdersCategory): Observable<OrdersCategory> {
    //
    return this.http.post<OrdersCategory>(this.baseUrl, orderscategory)
      .pipe(catchError(this.handleError));
  }

  //Add orderscategory
  addorderscategorywitharray(orderscategory: OrdersCategoryWithDsl): Observable<OrdersCategory> {
    //
    return this.http.post<OrdersCategory>(this.baseUrl + '/CreateDictWithArray', orderscategory)
      .pipe(catchError(this.handleError));
  }

  //Delete orderscategory
  deleteorderscategory(id: string): Observable<OrdersCategory> {
    return this.http.delete<OrdersCategory>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit orderscategory
  updateorderscategory(orderscategory: OrdersCategory): Observable<OrdersCategory> {
    return this.http
      .put<OrdersCategory>(this.baseUrl + '/' + orderscategory.dictOrdersCategoryExternalId, orderscategory)
      .pipe(catchError(this.handleError));
  }

  public getServiceCategories(): Observable<OrderCategoryEnum[]> {
    return this.http.get<OrderCategoryEnum[]>(`${this.baseUrl}/ServiceCategory/`);
  }

    // Error
    handleError(error: HttpErrorResponse) {
      return throwError(error);
  }
}

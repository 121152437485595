import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { DictInstitutionType } from 'src/app/models/contingent/dictinstitutiontype.model';

@Injectable({
  providedIn: 'root'
})
export class DictinstitutiontypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictinstitutiontype}`;

  constructor(private http: HttpClient) { }

  //Get all InstitutionType
  public getAllInstitutionType(): Observable<DictInstitutionType[]> {
    return this.http.get<DictInstitutionType[]>(this.baseUrl);
  }

  //Add InstitutionType
  addInstitutionType(dto: DictInstitutionType): Observable<DictInstitutionType> {
    return this.http.post<DictInstitutionType>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete InstitutionType
  deleteInstitutionType(id: string): Observable<DictInstitutionType> {
    return this.http.delete<DictInstitutionType>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit InstitutionType
  updateInstitutionType(dto: DictInstitutionType): Observable<DictInstitutionType> {
    return this.http.put<DictInstitutionType>(this.baseUrl + '/' + dto.dictInstitutionTypeExternalId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

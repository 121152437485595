import {Component, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, GridComponent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {ScientometricBasesService} from "../../../../../services/science/publications/scientometric-bases.service";
import {DictScientometricBase} from "../../../../../models/publications/dict/scientometricBase.model";
import {PublicationsScientometricBasesMethods} from "./publications-scientometric-bases.methods";
import {dictPagerSettings} from "../../../../../models/dicts/pagerSettings.model";
import {getData$} from "../../../../../../environments/environment";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";

@Component({
  selector: 'publications-scientometric-bases',
  templateUrl: './publications-scientometric-bases.component.html',
  styleUrls: ['./publications-scientometric-bases.component.scss']
})
export class PublicationsScientometricBasesComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  // Pager settings
  public pagerSettings: PagerSettings = dictPagerSettings;

  public isNew: boolean = false;
  public isLine: boolean = false;
  private editedRowIndex: number | undefined;

  public scientometricBases: DictScientometricBase[] = [];
  public formGroup: FormGroup = this.resetFormGroup;

  public editable: boolean = this.userAccessService.currentUserAccess$.value.dict === AccessRights.Write;

  constructor(private dialogService: DialogService,
              private scientometicBaseService: ScientometricBasesService,
              private scientometicBasesMethods: PublicationsScientometricBasesMethods,
              private userAccessService: PublicationsUserAccessService) { }

  async ngOnInit() {
    getData$.subscribe(() => this.getScientometricBases())
  }

  private get resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
    })
  }

  private getScientometricBases() {
    this.scientometicBaseService.getScientometricBases().subscribe(value => this.scientometricBases = value);
  }

  public addRow() {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
    })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public onCancel() {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
  }

  public async editRow({ sender, rowIndex, dataItem, columnIndex }: CellClickEvent) {
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name),
    })

    if (this.isNew || !this.editable || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.onCancel();
    this.isLine = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, {columnIndex});
  }

  public async saveRow()  {
    if (this.formGroup) await this.scientometicBasesMethods.savecientometricBase(this.isNew, this.formGroup);
    this.onCancel();
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}?"`);
    this.scientometicBasesMethods.removeQuartile(dialog.result, dataItem);
  }
}

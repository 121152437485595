<h5 class="achievements-title">История изменений</h5>

<kendo-grid [kendoGridBinding]="history"
            [sortable]="true"
            scrollable="none"
            [loading]="loading"
            (remove)="removeHandler($event)">
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <kendo-grid-column field="date"
                     headerClass="gridHeader dictionaries"
                     title="Дата и время">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{(dataItem?.date == null ? "" : dataItem.date | date: "dd.MM.yyyy, HH:mm")}}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="typeName" title="Действие" headerClass="gridHeader">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{dataItem.typeName}}
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="review" title="Детали операции" headerClass="gridHeader">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment details-painter" (click)="openDetails(dataItem)" *ngIf="dataItem.type != historyEnum.generated">
          <h2>Посмотреть детали</h2>
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="personName" title="Пользователь" headerClass="gridHeader">
    <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{dataItem.personName}}
        </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="editable" headerClass="gridHeader">
    <ng-template kendoGridHeaderTemplate>
      <div>
        <kendo-label text="Выбрать все" class="label label--right">
          <input
            type="checkbox"
            kendoCheckBox
            [checked]="selectedEntries.length === history.length"
            (click)="selectAll()"
          />
        </kendo-label>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="alignCells">
        <input
          *ngIf="dataItem.type != historyEnum.generated"
          type="checkbox"
          kendoCheckBox
          [checked]="checkedDate[dataItem.id]"
          (change)="checkedChange($event, dataItem.id)"
        />
      </div>
    </ng-template>
    <ng-template kendoGridFooterTemplate>
      <div class="alignCells">
        <button kendoButton (click)="deleteSelected()" [disabled] = 'disableMassDelete'>
          Удалить
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="" *ngIf="editable">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button *ngIf="dataItem.type != historyEnum.generated && !dataItem.deleted === true"
              kendoGridRemoveCommand
              themeColor="secondary"
              icon="delete"
              fillMode="flat"
              size="small"
              rounded="full">
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>


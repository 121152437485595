import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcademicstateComponent } from './components/contingent/academicstate/academicstate.component';
import { ContingentHomeComponent } from './components/contingent/contingenthome/contingenthome.component';
import { MilitarydoctypesComponent } from './components/contingent/militarydoctypes/militarydoctypes.component';
import { MilitaryranksComponent } from './components/contingent/militaryranks/militaryranks.component';
import { MilitarystatesComponent } from "./components/contingent/militarystates/militarystates.component";
import { MilitarystatusesComponent } from './components/contingent/militarystatuses/militarystatuses.component';
import { MilitarystockcategoriesComponent } from './components/contingent/militarystockcategories/militarystockcategories.component';
import { BudgetComponent } from './components/contingent/budget/budget.component';
import { BudgetsubcategoryComponent } from './components/contingent/budgetsubcategory/budgetsubcategory.component';
import { IdentificationtypeComponent } from './components/contingent/identificationtype/identificationtype.component';
import { LanguagesComponent } from './components/contingent/languages/languages.component';
import { PhonetypesComponent } from './components/contingent/phonetypes/phonetypes.component';
import { EducationdoctypesComponent } from './components/contingent/educationdoctypes/educationdoctypes.component';
import { StudentmaininfoComponent } from './components/contingent/studentmaininfo/studentmaininfo.component';
import { OrdersComponent } from './components/contingent/orders/orders.component';
import { AcademicprogressComponent } from './components/contingent/academicprogress/academicprogress.component';
import { PersonaldataComponent } from './components/contingent/personaldata/personaldata.component';
import { AddstudentComponent } from './components/contingent/addstudent/addstudent.component';
import { CitizenshipComponent } from './components/contingent/citizenship/citizenship.component';
import { CitizenshiptypeComponent } from './components/contingent/citizenshiptype/citizenshiptype.component';
import { FamilymemberstatusesComponent } from './components/contingent/familymemberstatuses/familymemberstatuses.component';
import { EmailtypesComponent } from './components/contingent/emailtypes/emailtypes.component';
import { StreettypesComponent } from './components/contingent/streettypes/streettypes.component';
import { CitytypesComponent } from './components/contingent/citytypes/citytypes.component';
import { OrdertypesComponent } from './components/contingent/ordertypes/ordertypes.component';
import { AdditionalfieldsComponent } from './components/contingent/additionalfields/additionalfields.component';
import { AdditionalstudentfieldsComponent } from './components/contingent/additionalstudentfields/additionalstudentfields.component';
import { LanguagelevelsComponent } from './components/contingent/languagelevels/languagelevels.component';
import { OrganizationComponent } from './components/dicts/organization/organization.component';
import { OrderscategoryComponent } from './components/contingent/orderscategory/orderscategory.component';
import { AchievementsComponent } from './components/contingent/achievements/achievements.component';
import { CertificateComponent } from './components/contingent/certificate/certificate.component';
import { ContingentselectionComponent } from './components/contingent/contingentselection/contingentselection.component';
import { TrainingLevelComponent } from "./components/dicts/traininglevel/traininglevel.component";
import { StudyformtypeComponent } from "./components/dicts/studyformtype/studyformtype.component";
import { StudyformComponent } from "./components/dicts/studyform/studyform.component";
import { StudylevelComponent } from "./components/dicts/studylevel/studylevel.component";
import { QualificationdegreeComponent } from "./components/dicts/qualificationdegree/qualificationdegree.component";
import { DictsHomeComponent } from "./components/dicts/dictshome/dictshome.component";
import { FilialComponent } from './components/dicts/filial/filial.component';
import {Contingent, ContingentBack} from './models/contingent/contingent.model';
import { AcademicgroupsComponent } from './components/contingent/academicgroups/academicgroupshome/academicgroups.component';
import { ContingentsettingsComponent } from './components/contingent/contingentsettings/contingentsettings.component';
import { DictAcceleratedStudyBaseComponent } from './components/education/dict-accelerated-study-base/dict-accelerated-study-base.component';
import { DictComponentTypeComponent } from './components/education/dict-component-type/dict-component-type.component';
import { DictComponentComponent } from './components/education/dict-component/dict-component.component';
import { DictCycleTypeComponent } from './components/education/dict-cycle-type/dict-cycle-type.component';
import { DictCycleComponent } from './components/education/dict-cycle/dict-cycle.component';
import { DictLessonFormComponent } from './components/education/dict-lesson-form/dict-lesson-form.component';
import { DictLessonTypeComponent } from './components/education/dict-lesson-type/dict-lesson-type.component';
import { DictScienceBranchComponent } from './components/education/dict-science-branch/dict-science-branch.component';
import { DictTypeWorkDisciplineAdditionalComponent } from './components/education/dict-type-work-discipline-additional/dict-type-work-discipline-additional.component';
import { DictWorkCategoryScheduleComponent } from './components/education/dict-work-category-schedule/dict-work-category-schedule.component';
import { DictWorkScheduleStudyProcessTypeComponent } from './components/education/dict-work-schedule-study-process-type/dict-work-schedule-study-process-type.component';
import { DictWorkStudyPlanTypeComponent } from './components/education/dict-work-study-plan-type/dict-work-study-plan-type.component';
import { OopComponent } from './components/education/oop/oop.component';
import { StandardComponent } from './components/education/standard/standard.component';
import { StandardtypeComponent } from './components/education/standardtype/standardtype.component';
//import { EducationPlanComponent } from './components/education/education-plan/education-plan.component';
import { EducationPlanDetailsComponent } from "./components/education/plan/education-plan-details/education-plan-details.component";
import { EducationProgramSelectComponent } from './components/education/education-program-select/education-program-select.component';
import { PersonProfileComponent } from './components/person/person-profile/person-profile.component';
import { AddacademicgroupsComponent } from './components/contingent/academicgroups/addacademicgroups/addacademicgroups.component';
import { EducationProgramCompetenciesComponent } from './components/education/education-program-competencies/education-program-competencies.component';
import { ControlactionComponent } from './components/dicts/controlaction/controlaction.component';
import { DisciplineComponent } from './components/dicts/discipline/discipline.component';
import { DisciplinetypeComponent } from './components/dicts/disciplinetype/disciplinetype.component';
import { StudytechnologyComponent } from './components/dicts/studytechnology/studytechnology.component';
import { StandardLimitationComponent } from './components/education/standard-limitation/standard-limitation.component';
import { DictGroupStandardLimitationComponent } from './components/education/dict-group-standard-limitation/dict-group-standard-limitation.component';
import { LoginpageComponent } from "./components/auth/loginpage.component";
import { CallBackComponent } from "./components/auth/callback.component";
import { AuthGuard } from "./guards/authguard";
import { NewEducationPlanComponent } from './components/education/plan/new-education-plan/new-education-plan.component';
import { DisciplineFormComponent } from './components/education/plan/discipline-form/discipline-form.component';
import { BenefitComponent } from './components/contingent/benefit/benefit.component';
import { InstitutionComponent } from './components/contingent/institution/institution.component';
import { InstitutiontypeComponent } from './components/contingent/institutiontype/institutiontype.component';
import { SportcategoryComponent } from './components/contingent/sportcategory/sportcategory.component';
import { SporttypeComponent } from './components/contingent/sporttype/sporttype.component';
import { DicthealthComponent } from './components/contingent/dicthealth/dicthealth.component';
import { DictCodeArticleComponent } from './components/contingent/dictcodearticle/dictcodearticle.component';
import { MiddlecontrolHomeComponent } from './components/middlecontrol/middlecontrolhome/middlecontrolhome.component';
import {MiddlecontrolprintstatementComponent} from "./components/middlecontrol/middlecontrolprintstatement/middlecontrolprintstatement.component";
import {MiddlecontrolprintsessionresultComponent} from "./components/middlecontrol/middlecontrolprintsessionresult/middlecontrolprintsessionresult.component";
import {MiddlecontrolprintreportComponent} from "./components/middlecontrol/middlecontrolprintreport/middlecontrolprintreport.component";
import {StatementParamsComponent} from "./components/middlecontrol/statementparams/statementparams.component";
import {CurrentcontrolHomeComponent} from "./components/currentcontrol/currentcontrolhome/currentcontrolhome.component"
import { CurrentcontrolprintstatementComponent } from "./components/currentcontrol/currentcontrolprintstatement/currentcontrolprintstatement.component"
import {AcademicGroup, AcademicGroupFilters} from './models/contingent/edugroup.model';
import {DictspravkatypeComponent} from "./components/contingent/dictspravkatype/dictspravkatype.component";
import { DictGroupStandardLimitation } from './models/education/dictGroupStandardLimitation.model';
import { StandardLimitationSettingComponent } from './components/education/standard-limitation-setting/standard-limitation-setting.component';
import { CopyPlanComponent } from 'src/app/components/education/plan/copy-plan/copy-plan.component';
import { GlobalScheduleSettingsComponent } from 'src/app/components/education/plan/schedule/global-schedule-settings/global-schedule-settings.component';
import { AuthorComponent } from "./components/announcement/author/author.component";
import { ModifyComponent } from "./components/announcement/modify/modify.component";
import { ShowComponent } from "./components/announcement/show/show.component";
import { TechnicalAdministratorsComponent } from './components/useraccess/technical-administrators/technical-administrators.component';
import { ContingentAccessComponent } from './components/useraccess/contingent-access/contingent-access.component';
import { CurrentControlAccessComponent } from './components/useraccess/current-control-access/current-control-access.component';
import { MiddleControlAccessComponent } from './components/useraccess/middle-control-access/middle-control-access.component';
import { EducationAccessComponent } from './components/useraccess/education-access/education-access.component';
import { CommonDictionariesAccessComponent } from './components/useraccess/common-dictionaries-access/common-dictionaries-access.component';
import { AlertComponent } from "./components/announcement/alert/alert.component";
import { AnnouncementComponent } from "./components/announcement/modify/announcement/announcement.component";
import { DictionariesGuard } from './guards/dictionaries.guard';
import { SessionRoleGuard } from './guards/session-role.guard';
import { CurrentControlRoleGuard } from './guards/current-control-role.guard';
import { ContingentRoleGuard } from './guards/contingent-role.guard';
import { ProjectingRoleGuard } from './guards/projecting-role.guard';
import { AdminRoleGuard } from './guards/admin-role.guard';
import { SwitchUserComponent } from './components/switchuser/switchuser.component';
import {SessionUserAccessGuard} from "./guards/session-user-access.guard";
import {CurrentControlUserAccessGuard} from "./guards/current-control-user-access.guard";
import {EducationUserAccessGuard} from "./guards/education-user-access.guard";
import {DictsUserAccessGuard} from "./guards/dicts-user-access.guard";
import {ContingentUserAccessGuard} from "./guards/contingent-user-access.guard";
import {ClassroomGuard} from "./guards/classroom.guard";
//#region Signatory
import { GlobalSignatoryComponent } from "./components/dicts/global-signatory/global-signatory.component";
import { GlobalSignatoryRoleComponent } from "./components/dicts/global-signatory-role/global-signatory-role.component";
import {LocalSignatoryComponent} from "./components/contingent/localsignatory/localsignatory.component";
import {LocalSignatoryCCComponent} from "./components/currentcontrol/local-signatory/local-signatory.component";
import {LocalSignatoryEduComponent} from "./components/education/local-signatory/local-signatory.component";
import {LocalSignatoryMCComponent} from "./components/middlecontrol/local-signatory/local-signatory.component";
import { HolidaysAndWeekendsComponent } from './components/dicts/holidays-and-weekends/holidays-and-weekends/holidays-and-weekends.component';
//#endregion
import { DisciplineWorkloadContingentComponent } from './components/disciplineworkload/discipline-workload-contingent/discipline-workload-contingent.component';
import { AssignmentHomeComponent } from './components/disciplineworkload/assignments/assignmentHome/assignmentHome.component';
import { DepartmentWorkloadComponent } from './components/disciplineworkload/department-workload/department-workload.component';
import { DisciplineAssignmentComponent } from './components/disciplineworkload/assignments/disciplineAssignment/disciplineAssignment.component';
import { DisciplineWorkloadAccessComponent } from './components/useraccess/discipline-workload-access/discipline-workload-access.component';
import { AnnouncementAccessComponent } from './components/useraccess/announcement-control-access/announcement-access.component';
import { AnnouncementUserAccessGuard } from './guards/announcement-user-access.guard';
import {DictmaritalstatusService} from "./services/contingent/dictmaritalstatus.service";
import {DictmaritalstatusComponent} from "./components/contingent/dictmaritalstatus/dictmaritalstatus.component";
import { DisciplineInfoComponent } from './components/dicts/discipline-info/discipline-info.component';
import { DictmarkchangereasonComponent } from './components/middlecontrol/dictmarkchangereason/dictmarkchangereason.component';
import { MarkComponent } from './components/dicts/mark/mark.component';
import { DictmarkcriterialevelComponent } from './components/currentcontrol/dictmarkcriterialevel/dictmarkcriterialevel.component';
import { DictcontrolactionComponent } from './components/middlecontrol/dict-control-action/dictcontrolaction.component';
import { DictDisciplineTypeComponent } from './components/education/dict-discipline-type/dict-discipline-type.component';
import { DepartmentComponent } from './components/education/department/department.component';
import {TeacherWorkloadComponent} from "./components/disciplineworkload/teacher-workload/teacher-workload.component";

import { ClassroomFundComponent } from './components/classroom/classroom-fund.component';
import { BuildingComponent } from './components/classroom/building/building.component';
import { TypesAuditoriumComponent } from './components/classroom/types-auditorium/types-auditorium.component';
import { AssignAuditoriumComponent } from './components/classroom/assign-auditorium/assign-auditorium.component';
import { StructuralComponent } from './components/classroom/structural/structural.component';
import { ParametersAuditoriumComponent } from './components/classroom/parameters-auditorium/parameters-auditorium.component';
import { ClassroomAccessComponent } from './components/useraccess/classroom-access/classroom-access.component';
import {
  MilitarycommissariatComponent
} from "./components/contingent/militarycommissariat/militarycommissariat.component";
//import {DictstudyformComponent} from "./components/contingent/dictstudyform/dictstudyform.component";
import {
  DisciplineWorkloadRedirectComponent
} from "./components/disciplineworkload/redirect/discipline-workload-redirect.component";
import {DisciplineWorkloadUserAccessGuard} from "./guards/discipline-workload-user-access.guard";
import {DisciplineWorkloadRedirectGuard} from "./guards/discipline-workload-redirect.guard";
import {
  EditacademicgroupsComponent
} from "./components/contingent/academicgroups/editacademicgroups/editacademicgroups.component";
import {ReportsWorkloadComponent} from "./components/disciplineworkload/reportsWorkload/reports-workload.component";
import {
  DictEnlargedGroupSpecializationComponent
} from "./components/education/dict-enlarged-group-specialization/dict-enlarged-group-specialization.component";
import {
  ContingentjournalComponent
} from "./components/contingent/contingentjournal/contingentjournalhome/contingentjournal.component";
import {
  ContingentjournaldetailsComponent
} from "./components/contingent/contingentjournal/contingentjournaldetails/contingentjournaldetails.component";
import {DocumentnamesComponent} from "./components/contingent/documentnames/documentnames.component";
import {DocumentsComponent} from "./components/contingent/documents/documents.component";
import {DictTeachersComponent} from "./components/disciplineworkload/dictTeachers/dict-teachers.component";
import {
  AssignmentsMonitoringComponent
} from "./components/disciplineworkload/assignments/assignmentsMonitoring/assignments-monitoring.component";
import { ExportScheduleEducationComponent } from './components/education/export-schedule-education-process/export-schedule-education-process.component';
import {
  EducationPlanReattachmentComponent
} from "./components/education/plan/education-plan-reattachment/education-plan-reattachment.component";
import {PublicationsRoutes} from "./routes/publications-routes";
import { CadreBaseComponent } from './components/persondepartment/cadre-base/cadre-base.component';
import { ParametersPersonComponent} from './components/persondepartment/parameters-person/parameters-person.component';
import { PersonCardComponent } from './components/persondepartment/person-card/person-card.component';
import { PostsComponent } from './components/persondepartment/posts/posts.component';
import { ScientificDegreeComponent } from './components/persondepartment/scientific-degree/scientific-degree.component';
import { DepartmentsAdminComponent } from './components/persondepartment/departments/departments.component';
import { DepartmentsNameComponent } from './components/persondepartment/departments-name/departments-name.component';
import { DepartmentsTypeComponent } from './components/persondepartment/departments-type/departments-type.component';

import {StudplanComponent} from "./components/contingent/studplan/studplan.component";
import {
  StudenthistorylistComponent
} from "./components/contingent/studenthistory/studenthistorylist/studenthistorylist.component";
import {
  StudenthistorydetailsComponent
} from "./components/contingent/studenthistory/studenthistorydetails/studenthistorydetails.component";
import { DictAchievement } from './components/contingent/dictachievement/dictachievement.component';
import {
  DictcertificatepurposeComponent
} from "./components/contingent/dictcertificatepurpose/dictcertificatepurpose.component";
import {StudentcardsettingsComponent} from "./components/contingent/studentcardsettings/studentcardsettings.component";
import {ContingentDisplaySettingGuard} from "./guards/contingent-display-setting.guard";
import {WorkLoadSettingsComponent} from "./components/disciplineworkload/settings/settings.component";
import {PayslipComponent} from "./components/payslip/payslip.component";
import {BudgetcategoryComponent} from "./components/contingent/budgetcategory/budgetcategory.component";
import {CardsComponent} from "./components/cards/cards.component";
import {AddToListFormingComponent} from "./components/cards/add-to-list-forming/add-to-list-forming.component";
import {ReestrComponent} from "./components/cards/reestr/reestr.component";
import {
  DictotherdocumenttypesComponent
} from "./components/contingent/dictotherdocumenttypes/dictotherdocumenttypes.component";
import {
  ComplaintmanagementstatusComponent
} from "./components/contingent/complaintmanagemenstatus/complaintmanagementstatus.component";
import {PaymentstateComponent} from "./components/contingent/paymentstate/paymentstate.component";
import {EmploymentstatusComponent} from "./components/contingent/employmentstatus/employmentstatus.component";
import {DictfacultyComponent} from "./components/contingent/dictfaculty/dictfaculty.component";
import {DicttraininglevelsComponent} from "./components/contingent/dicttraininglevels/dicttraininglevels.component";
import { MilitaryCategoriesComponent } from './components/contingent/dicts/militarycategories/militarycategories.component';
import {TargetedTrainingComponent} from "./components/contingent/targetedtraining/targeted-training.component";
import { WorktypesComponent } from "./components/contingent/dicts/worktypes/worktypes.component";
import { MilitaryProfilesComponent } from "./components/contingent/dicts/militaryprofiles/militaryprofiles.component";
import { NonEmploymentReasonsComponent } from "./components/contingent/dicts/nonemploymentreasons/nonemploymentreasons.component";
import { SalaryProjectComponent } from "./components/cards/dicts/salaryproject/salaryproject.component";
import { AchievementsStatusesComponent } from "./components/contingent/dicts/achievementsstatuses/achievementsstatuses.component";
import {DictgeneralsheetComponent} from "./components/middlecontrol/dictgeneralsheet/dictgeneralsheet.component";
import {
  MilitaryCertificatesComponent
} from './components/contingent/militarycertificates/military-certificates.component';
import {MilitaryComponent} from './components/contingent/military/military.component';
import {MilitaryFormTypesComponent} from './components/contingent/militaryformtypes/military-form-types.component';
import {StudentInfoCardsComponent} from "./components/cards/studentinfocards/studentinfocards.component";
//#region Report
import {ReportListComponent as ContingentReportListComponent} from "./components/contingent/report-list/report-list.component";
import {ReportListComponent as SessionReportListComponent } from "./components/middlecontrol/report-list/report-list.component";
import {ReportDesignerComponent} from "./components/report-designer/report-designer.component";
import {ReportViewerComponent} from "./components/report-viewer/report-viewer.component";
//#endregion
import {CardsAccessComponent} from "./components/useraccess/cards-access/cards-access.component";
import {CardsUserAccessGuard} from "./guards/cards-user-access.guard";
import {CardsRoleGuard} from "./guards/cards-role.guard";
import { DictLocalSignatoryRoleComponent } from './components/contingent/dicts/localsignatoryrole/localsignatoryrole.component';
import { PrintDebtorsByCourseComponent } from "./components/middlecontrol/print-debtors-by-course/print-debtors-by-course.component";
import {
  DictlocalsignatoryroleComponent
} from "./components/middlecontrol/dictlocalsignatoryrole/dictlocalsignatoryrole.component";
import {ContingentMilitaryCertificateGuard} from "./guards/contingent-military-certificate.guard";
import {
  EducationalorganizationnameComponent
} from "./components/dicts/educationalorganizationname/educationalorganizationname.component";
import {GiaRoutes} from "./routes/gia-routes";
import {MFCRoutes} from './routes/mfc-routes';
import {OfertaRoutes} from "./routes/oferta-routes";
import {DiplomablanksRoutes} from "./routes/diplomablanks-routes";

const routes: Routes = [
  { path: 'profile', component: PersonProfileComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginpageComponent },
  { path: 'callback', component: CallBackComponent },
  //{ path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'contingent', component: ContingentHomeComponent, data: { ContingentBack }, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/contingentselection', component: ContingentselectionComponent, data: { Contingent }, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/contingentjournal', component: ContingentjournalComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/contingentjournal/:id', component: ContingentjournaldetailsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/academicgroupshome', component: AcademicgroupsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/addacademicgroups/:academicgroupsId', component: AddacademicgroupsComponent, data: { AcademicGroup }, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/editacademicgroups/:academicgroupsId', component: EditacademicgroupsComponent, data: { AcademicGroup }, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/addacademicgroups', component: AddacademicgroupsComponent, data: { AcademicGroupFilters }, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/addacademicgroups', component: AddacademicgroupsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/academicstate', component: AcademicstateComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militarydoctypes', component: MilitarydoctypesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militaryranks', component: MilitaryranksComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militarystates', component: MilitarystatesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militarystatuses', component: MilitarystatusesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militarycommissariat', component: MilitarycommissariatComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militarycertificates', component: MilitaryCertificatesComponent, canActivate: [ContingentMilitaryCertificateGuard] },
  { path: 'contingent/benefits', component: BenefitComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/documentnames', component: DocumentnamesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/institution', component: InstitutionComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/institutiontype', component: InstitutiontypeComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/sportcategory', component: SportcategoryComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/spravkatype', component: DictspravkatypeComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/sporttype', component: SporttypeComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/health', component: DicthealthComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/codearticle', component: DictCodeArticleComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militarystockcategories', component: MilitarystockcategoriesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/budget', component: BudgetComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/budgetcategory', component: BudgetcategoryComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/budgetsubcategory', component: BudgetsubcategoryComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/identificationtype', component: IdentificationtypeComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/languages', component: LanguagesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/phonetypes', component: PhonetypesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/educationdoctypes', component: EducationdoctypesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/addstudent', component: AddstudentComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/citizenship', component: CitizenshipComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/citizenshiptype', component: CitizenshiptypeComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/familymemberstatuses', component: FamilymemberstatusesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/emailtypes', component: EmailtypesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/complaintmanagementstatus', component: ComplaintmanagementstatusComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/paymentstate', component: PaymentstateComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/employmentstatus', component: EmploymentstatusComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/dictfaculty', component: DictfacultyComponent, canActivate: [AdminRoleGuard]  },
  { path: 'contingent/dicttraininglevels', component: DicttraininglevelsComponent, canActivate: [AdminRoleGuard]  },
  { path: 'contingent/streettypes', component: StreettypesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/citytypes', component: CitytypesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/ordertypes', component: OrdertypesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/additionalfields', component: AdditionalfieldsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/additionalstudentfields', component: AdditionalstudentfieldsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/languagelevels', component: LanguagelevelsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/contingentsettings', component: ContingentsettingsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/dictmaritalstatus', component: DictmaritalstatusComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/dictcertificatepurpose', component: DictcertificatepurposeComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/dictotherdocumenttypes', component: DictotherdocumenttypesComponent, canActivate: [ContingentRoleGuard] },
  //{ path: 'contingent/studyform', component: DictstudyformComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/orderscategory', component: OrderscategoryComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/localsignatoryrole', component: DictLocalSignatoryRoleComponent, canActivate: [ContingentRoleGuard] },
  {
    path: 'contingent/studentmaininfo/:studentId', component: StudentmaininfoComponent, canActivate: [ContingentRoleGuard],

    children: [
      {
        path: 'progress/currentControl',
        component: AcademicprogressComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'progress',
        component: AcademicprogressComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'achievements',
        component: AchievementsComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'targetedtraining',
        component: TargetedTrainingComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'orders',
        component: OrdersComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'personaldata/:studpersonId',
        component: PersonaldataComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'certificate',
        component: CertificateComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'military',
        component: MilitaryComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'documents',
        component: DocumentsComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'history',
        component: StudenthistorylistComponent,
        canActivate: [ContingentDisplaySettingGuard],
      },
      {
        path: 'studentcards',
        component: StudentInfoCardsComponent,
        canActivate: [ContingentDisplaySettingGuard],
      }
    ],
  },
  { path: 'contingent/studentmaininfo/progress/:studentId', component: AcademicprogressComponent, canActivate: [AuthGuard] },
  { path: 'contingent/studplan/:studentId', component: StudplanComponent, canActivate: [ContingentRoleGuard]},
  { path: 'contingent/studentmaininfo/history/:studentId/:id', component: StudenthistorydetailsComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/dictachievement', component: DictAchievement, canActivate: [ContingentRoleGuard]},
  { path: 'contingent/studentcardsettings', component: StudentcardsettingsComponent, canActivate: [AdminRoleGuard] },
  { path: 'contingent/worktypes', component: WorktypesComponent, canActivate: [ContingentRoleGuard]},
  { path: 'contingent/militarycategories', component: MilitaryCategoriesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militaryprofiles', component: MilitaryProfilesComponent, canActivate: [ContingentRoleGuard]},
  { path: 'contingent/nonemploymentreasons', component: NonEmploymentReasonsComponent, canActivate: [ContingentRoleGuard]},
  { path: 'contingent/achievementsstatuses', component: AchievementsStatusesComponent, canActivate: [ContingentRoleGuard] },
  { path: 'contingent/militaryprofiles', component: MilitaryProfilesComponent, canActivate: [ContingentRoleGuard]},
  { path: 'contingent/militaryformtypes', component: MilitaryFormTypesComponent, canActivate: [ContingentRoleGuard]},
  { path: 'contingent/report-list', component: ContingentReportListComponent, canActivate: [ContingentRoleGuard]},

  { path: 'middlecontrol', component: MiddlecontrolHomeComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/printstatement', component: MiddlecontrolprintstatementComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/printsessionresult', component: MiddlecontrolprintsessionresultComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/debtors-by-course', component: PrintDebtorsByCourseComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/printreport', component: MiddlecontrolprintreportComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/dictmarkchangereason', component: DictmarkchangereasonComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/statementparams', component: StatementParamsComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/dictgeneralsheet', component: DictgeneralsheetComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/report-list', component: SessionReportListComponent, canActivate: [SessionRoleGuard] },
  { path: 'middlecontrol/localsignatoryrole', component: DictlocalsignatoryroleComponent, canActivate: [SessionRoleGuard] },

  { path: 'currentcontrol', component: CurrentcontrolHomeComponent, canActivate: [CurrentControlRoleGuard] },
  { path: 'currentcontrol/printstatement', component: CurrentcontrolprintstatementComponent, canActivate: [CurrentControlRoleGuard] },

  { path: 'education', component: EducationProgramSelectComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/standard', component: StandardComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/standardtype', component: StandardtypeComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/oop', component: OopComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/oop/competence/:id', component: EducationProgramCompetenciesComponent, canActivate: [ProjectingRoleGuard] },
  //{ path: 'education/faculty', component: FacultyComponent, canActivate: [AuthGuard]},
  //{ path: 'education/kafedra', component: KafedraComponent, canActivate: [AuthGuard]},
  { path: 'education/dictcomponenttype', component: DictComponentTypeComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictcomponent', component: DictComponentComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictcycletype', component: DictCycleTypeComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictdisciplinetype', component: DictDisciplineTypeComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/department', component: DepartmentComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictcycle', component: DictCycleComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictLessonType', component: DictLessonTypeComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictLessonForm', component: DictLessonFormComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/sciencebranch', component: DictScienceBranchComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictTypeWorkDisciplineAdditional', component: DictTypeWorkDisciplineAdditionalComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictAcceleratedStudyBase', component: DictAcceleratedStudyBaseComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictWorkCategorySchedule', component: DictWorkCategoryScheduleComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictWorkStudyPlanType', component: DictWorkStudyPlanTypeComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictgroupstandardlimitation', component: DictGroupStandardLimitationComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictstandardlimitation/:education_standard_id', component: StandardLimitationComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictstandardlimitationsetting', component: StandardLimitationSettingComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/dictEnlargedGroupSpecialization', component: DictEnlargedGroupSpecializationComponent, canActivate: [ProjectingRoleGuard]},
  // { path: 'education', component: EducationPlanComponent, canActivate: [ProjectingRoleGuard] },

  { path: 'education/plans', component: EducationPlanDetailsComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'education/:education_program_id/plans', component: EducationPlanDetailsComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/:education_program_id/plans/plan/:education_plan_id', component: EducationPlanDetailsComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/global-schedule-settings', component: GlobalScheduleSettingsComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/:education_program_id/plans/add-plan', component: NewEducationPlanComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/:education_program_id/plans/copy-plan/:destination_plan_id', component: CopyPlanComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/:education_program_id/plans/:education_plan_id/add-discipline/:cycle_id/:component_id', component: DisciplineFormComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/:education_program_id/plans/:education_plan_id/edit-discipline/:discipline_id', component: DisciplineFormComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/export-schedule', component: ExportScheduleEducationComponent, canActivate: [ProjectingRoleGuard], },
  { path: 'education/:education_program_id/plans/reattach-plan/:destination_plan_id', component: EducationPlanReattachmentComponent, canActivate: [ProjectingRoleGuard] },

  { path: 'academicgroupshome', component: AcademicgroupsComponent, canActivate: [AuthGuard] },
  { path: 'dicts', component: DictsHomeComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/traininglevel', component: TrainingLevelComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/studylevel', component: StudylevelComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/studyformtype', component: StudyformtypeComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/studyform', component: StudyformComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/qualificationdegree', component: QualificationdegreeComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/controlaction', component: ControlactionComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/discipline', component: DisciplineComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/discipline/info/:discipline_id', component: DisciplineInfoComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/disciplinetype', component: DisciplinetypeComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/studytechnology', component: StudytechnologyComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/holidaysandweekends', component: HolidaysAndWeekendsComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/mark', component: MarkComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/filial', component: FilialComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/organization', component: OrganizationComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/educationalorganizationname', component: EducationalorganizationnameComponent, canActivate: [DictionariesGuard] },

  { path: 'profile', component: PersonProfileComponent, canActivate: [AuthGuard] },
  { path: 'author', component: AuthorComponent, canActivate: [AuthGuard], },
  { path: 'author/modify/:id', component: ModifyComponent, canActivate: [AuthGuard] },
  { path: 'author/modify', component: ModifyComponent, canActivate: [AuthGuard] },
  { path: 'show/:id', component: ShowComponent, canActivate: [AuthGuard] },
  { path: 'alert', component: AlertComponent, canActivate: [AuthGuard] },
  { path: 'author/announcement/:id', component: AnnouncementComponent, canActivate: [AuthGuard]},
  { path: 'author/announcement', component: AnnouncementComponent, canActivate: [AuthGuard]},
  { path: 'technicaladministators', component: TechnicalAdministratorsComponent, canActivate: [AdminRoleGuard] },
  { path: 'alert/useraccess', component: AnnouncementAccessComponent, canActivate: [AnnouncementUserAccessGuard] },
  { path: 'contingent/useraccess', component: ContingentAccessComponent, canActivate: [ContingentUserAccessGuard] },
  { path: 'currentcontrol/useraccess', component: CurrentControlAccessComponent, canActivate: [CurrentControlUserAccessGuard] },
  { path: 'currentcontrol/dictmarkcriterialevel', component: DictmarkcriterialevelComponent, canActivate: [CurrentControlRoleGuard] },
  { path: 'middlecontrol/useraccess', component: MiddleControlAccessComponent, canActivate: [SessionUserAccessGuard] },
  { path: 'middlecontrol/dictcontrolaction', component: DictcontrolactionComponent, canActivate: [SessionRoleGuard] },
  { path: 'education/useraccess', component: EducationAccessComponent, canActivate: [EducationUserAccessGuard] },
  { path: 'dicts/useraccess', component: CommonDictionariesAccessComponent, canActivate: [DictsUserAccessGuard] },
  { path: 'switchuser', component: SwitchUserComponent, canActivate: [AdminRoleGuard] },
  //#region Signatory
  { path: 'dicts/signatory', component: GlobalSignatoryComponent, canActivate: [DictionariesGuard] },
  { path: 'dicts/signatoryrole', component: GlobalSignatoryRoleComponent, canActivate: [DictionariesGuard] },
  { path: 'contingent/signatory', component: LocalSignatoryComponent, canActivate: [ContingentRoleGuard] },
  { path: 'currentcontrol/signatory', component: LocalSignatoryCCComponent, canActivate: [CurrentControlRoleGuard] },
  { path: 'education/signatory', component: LocalSignatoryEduComponent, canActivate: [ProjectingRoleGuard] },
  { path: 'middlecontrol/signatory', component: LocalSignatoryMCComponent, canActivate: [SessionRoleGuard] },
  //#endregion

  { path: 'switchuser', component: SwitchUserComponent, canActivate: [AdminRoleGuard] },
  { path: 'disciplineworkload/contingentworkload', component: DisciplineWorkloadContingentComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload/assignments', component: AssignmentHomeComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload/departmentworkload', component: DepartmentWorkloadComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload/teacherworkload', component: TeacherWorkloadComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload/useraccess', component: DisciplineWorkloadAccessComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload/reportsWorkload', component: ReportsWorkloadComponent, canActivate: [DisciplineWorkloadUserAccessGuard]},
  { path: 'disciplineworkload/dictTeachers', component: DictTeachersComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload/assignmentsMonitoring', component: AssignmentsMonitoringComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload/settings', component: WorkLoadSettingsComponent, canActivate: [DisciplineWorkloadUserAccessGuard] },
  { path: 'disciplineworkload', component: DisciplineWorkloadRedirectComponent, canActivate: [DisciplineWorkloadRedirectGuard] },

  { path: 'classroom', component: ClassroomFundComponent, canActivate: [ClassroomGuard]},
  { path: 'classroom/building', component: BuildingComponent, canActivate: [ClassroomGuard] },
  { path: 'classroom/auditorium', component: TypesAuditoriumComponent, canActivate: [ClassroomGuard] },
  { path: 'classroom/assign', component: AssignAuditoriumComponent,  canActivate: [ClassroomGuard]},
  { path: 'classroom/department', component: StructuralComponent, canActivate: [ClassroomGuard]},
  { path: 'classroom/parameters', component: ParametersAuditoriumComponent, canActivate: [ClassroomGuard]},
  { path: 'classroom/useraccess', component: ClassroomAccessComponent, canActivate: [ClassroomGuard]},
  { path: 'classroom/parameters/:id', component: ParametersAuditoriumComponent, canActivate: [ClassroomGuard]},

  { path: 'technicaladministators/cadrebase', component: CadreBaseComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/paramperson', component: ParametersPersonComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/paramperson/:id', component: ParametersPersonComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/personcard/:id', component: PersonCardComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/posts', component: PostsComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/sciencedegree', component: ScientificDegreeComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/departments', component: DepartmentsAdminComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/departmentsname', component: DepartmentsNameComponent, canActivate: [AdminRoleGuard]},
  { path: 'technicaladministators/departmentstype', component: DepartmentsTypeComponent, canActivate: [AdminRoleGuard]},

  { path: 'payslip', component: PayslipComponent },

  { path: 'cards', component: CardsComponent, canActivate: [CardsRoleGuard] },
  { path: 'cards/addtolistforming', component: AddToListFormingComponent, canActivate: [CardsRoleGuard] },
  { path: 'cards/addtolistforming/:id', component: AddToListFormingComponent, canActivate: [CardsRoleGuard] },
  { path: 'cards/reestr/:id', component: ReestrComponent, canActivate: [CardsRoleGuard] },
  { path: "cards/salaryproject", component: SalaryProjectComponent, canActivate: [CardsRoleGuard] },
  { path: "cards/useraccess", component: CardsAccessComponent, canActivate: [CardsUserAccessGuard]},


  // Публикации
  ...PublicationsRoutes,

  { path: 'report-designer', component: ReportDesignerComponent, canActivate: [AdminRoleGuard]},
  { path: 'report-viewer', component: ReportViewerComponent, canActivate: [AuthGuard]},

  //GIA
  ...GiaRoutes,
  // МФЦ
  ...MFCRoutes,
  // Присоединение к оферте
  ...OfertaRoutes,
  //Бланки
  ...DiplomablanksRoutes,

  // Put this wildcard path on the last place (!)
  { path: "**", redirectTo: 'profile', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }



import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {GlobalSignatoryEduRead} from "../../models/education/globalsignatory.model";

@Injectable({
  providedIn: "root"
})
export class GlobalSignatoryEduService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.education.globalSignatory}`;

  constructor(private http: HttpClient) {
  }

  public get(): Observable<GlobalSignatoryEduRead[]> {
    return this.http.get<GlobalSignatoryEduRead[]>(this.baseUrl);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

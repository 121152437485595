<h1>Протокол заседания государственной экзаменационной комиссии</h1>

<div class="selectContent w-850">
    <dd class="list">
      <span class="selectText">
        ФИО обучающегося
      </span>
      <p class="w-550">{{ studentData.studentFio}}</p>
    </dd>

    <dd class="list">
      <span class="selectText">
        Группа
      </span>
      <p class="w-550">{{ studentData.groupName}}</p>
    </dd>

    <dd class="list" *ngIf="studentData.dateStart">
        <span class="selectText">Период обучения</span>
        <p class="w-550"> c <span class="m-l15 m-r15">{{ (studentData.dateStart | date:'dd.MM.yyyy') ?? "-" }}</span>
                         по <span class="m-l15">{{ (studentData.dateFinish | date:'dd.MM.yyyy') ?? "-" }}</span>
        </p>
    </dd>
</div>

<kendo-tabstrip class="custom-tabs" [ngClass]="{'none': filterTabsList.length == 1}" (tabSelect)="onTabSelect($event)">
    <kendo-tabstrip-tab *ngFor="let item of filterTabsList; let i = index"
            [title]="item.tabName"
            [selected]="i === selected"
            [disabled]="needSave"
            >
        <ng-template kendoTabContent>
            <div class="selectContent w-850">
                <dd class="list">
                    <span class="selectText">Номер и дата протокола</span>
                    <div class="w-600 flex">
                    <span class="parameters w-30">№</span>
                    <kendo-textbox class="w-200"
                                   [ngClass]="{redborder: !structure.protocolNumber}"
                                   (valueChange)="onChange($event)"
                                   [(ngModel)]="structure.protocolNumber">
                    </kendo-textbox>
                    <span class="required">*</span>

                    <span class="parameters w-50 m-l20">Дата</span>
                    <kendo-datepicker class="w-200"
                                      [ngClass]="{redborder: !dateProtocol}"
                                      format="dd.MM.yyyy"
                                      (valueChange)="onChange($event)"
                                      [(ngModel)]="dateProtocol"
                                      [disabled]="!allowEdit"
                                      >
                    </kendo-datepicker>
                    <span class="required">*</span>
                    </div>
                </dd>
            </div>
            <div *ngFor="let element of structure.protocolGroups">
                <p class="title m-t20 m-b20">{{ element.name }}</p>
                <div *ngFor="let field of element.protocolProperties"
                     class="selectContent w-850">
                     <dd class="list" [ngClass]="{indent: field.dataTypeEnum !== types.title}">
                        <span class="selectText">
                            {{ field.name }}
                        </span>
                        <div class="w-550 m-l15">
                            <kendo-textarea [rows]="3" resizable="none" class="w-500"
                                            [ngClass]="{redborder: validationField(field)}"
                                            (valueChange)="onChange($event)"
                                            *ngIf="field.dataTypeEnum == types.text || field.dataTypeEnum == types.questions"
                                            [disabled]="!allowEdit"
                                            [(ngModel)]="field.value"
                                            >
                            </kendo-textarea>
                            <kendo-datepicker class="w-300" *ngIf="field.dataTypeEnum == types.date"
                                              [ngClass]="{redborder: validationField(field)}"
                                              (valueChange)="onChange($event)"
                                              format="dd.MM.yyyy"
                                              [disabled]="!allowEdit"
                                              [(ngModel)]="field.value">
                            </kendo-datepicker>
                            <kendo-numerictextbox *ngIf="field.dataTypeEnum == types.number"
                                        class="w-300" [ngClass]="{redborder: validationField(field)}"
                                        [min]="0"
                                        [decimals]="2"
                                        [spinners]="false"
                                        [format]="getFormat(field.value)"
                                        [autoCorrect]="true"
                                        (valueChange)="onChange($event)"
                                        [disabled]="!allowEdit"
                                        [(ngModel)]="field.value"
                            ></kendo-numerictextbox>
                            <kendo-numerictextbox *ngIf="field.dataTypeEnum == types.numberint"
                                        class="w-300" [ngClass]="{redborder: validationField(field)}"
                                        [min]="1"
                                        [decimals]="0"
                                        [spinners]="false"
                                        format="n0"
                                        [autoCorrect]="true"
                                        [disabled]="!allowEdit"
                                        (valueChange)="onChange($event)"
                                        [(ngModel)]="field.value"
                            ></kendo-numerictextbox>
                            <kendo-dropdownlist class="w-300" *ngIf="field.dataTypeEnum == types.dict"
                                        [ngClass]="{redborder: validationField(field)}"
                                        [data]="getDictData(field.dictionaryId ? field.dictionaryId : field.staticDictEnum)"
                                        textField="name"
                                        valueField="id"
                                        [disabled]="!allowEdit"
                                        [valuePrimitive]="true"
                                        (valueChange)="onChange($event)"
                                        [(ngModel)]="field.value"
                            >
                            </kendo-dropdownlist>
                            <span class="required m-l15" *ngIf="field.isRequired">*</span>
                        </div>
                    </dd>
                </div>
            </div>
        </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>

<div kendoDialogContainer></div>

<div class="buttons">
    <div>
        <button kendoButton (click)="back()">Отмена</button>
        <button kendoButton themeColor="primary" class="m-l15" (click)="saveProtocol()" *ngIf="allowEdit">Сохранить</button>
    </div>
    <button kendoButton themeColor="primary" icon="print" class="m-l15" (click)="saveProtocol(true)" *ngIf="allowEdit">Сохранить и распечатать</button>
</div>

<div class="params-list">
  <div class="params-list__wrapper">
    <kendo-label text="Уровень подготовки" class="params-list__label">
      <kendo-multiselect
        *ngIf="trainingLevelMode == 'multi'"
        [data]="traininglevels"
        textField="name"
        valueField="id"
        kendoMultiSelectSummaryTag
        [autoClose]="false"
        [valuePrimitive]="true"
        (valueChange)="onSelectedFacultyChanged($event)"
        (blur)="blurLevels()"
        [(ngModel)]="selectedTrainingLevel"
        class="params-list__field"
      ><ng-template kendoDropDownListNoDataTemplate>
        <h3>Нет данных</h3>
      </ng-template>

        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span *ngIf="this.selectedTrainingLevel"> {{ dataItems.length }} выбрано </span>
        </ng-template>

        <ng-template kendoMultiSelectHeaderTemplate>
                          <span class="multiselect-all">
                            <input
                              type="checkbox"
                              id="chk"
                              kendoCheckBox
                              [checked]="isCheckedMultiSelectTrainingLevel"
                              [indeterminate]="isIndet()"
                              (click)="onClick()"/>
                            <kendo-label for="chk">{{'Все'}}</kendo-label>
                          </span>
        </ng-template>
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
                          <span>
                              <input
                                type="checkbox"
                                id="chk-{{ dataItem.id }}"
                                kendoCheckBox
                                [checked]="isItemSelected(dataItem)"
                              />
                          </span>
          <span class="multiselect-text"> {{ dataItem.name }}</span>
        </ng-template>

      </kendo-multiselect>
      <kendo-dropdownlist
        *ngIf="trainingLevelMode == 'single'"
        textField="name"
        valueField="id"
        class="params-list__field"
        [data]="traininglevels"
        [value]="selectedTrainingLevel ? selectedTrainingLevel[0] : null"
        [valuePrimitive]="true"
        (valueChange)="onTrainingLevelSingleValueChange($event)"
      >
        <ng-template kendoDropDownListNoDataTemplate>
          <h3>Нет данных</h3>
        </ng-template>
      </kendo-dropdownlist>
    </kendo-label>

    <kendo-label text="Семестр" class="params-list__label">
      <kendo-dropdownlist
        [data]="educationSlices"
        textField="semesterNumberString"
        valueField="semesterNumberString"
        [value]="selectedSemester"
        (valueChange)="onChangeEducationSlices($event, true)"
        class="params-list__field"
        [disabled]="+currentDate !== +contingentDate"
      >
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          <span>{{
            dataItem.semesterInYear + " семестр " + dataItem.semesterYearString
            }}</span>
        </ng-template>
        <ng-template kendoDropDownListNoDataTemplate>
          <h3>Нет данных</h3>
        </ng-template>
      </kendo-dropdownlist>
    </kendo-label>

    <div>
      <kendo-label text="Контингент на дату:" class="params-list__label">
        <div class="params-list__date" *ngIf="!isEditDate">
          <kendo-icon name="calendar"></kendo-icon>
          <p kendoTypography variant="p" class="params-list__text">
            {{ getToday(currentContingentDate) }}
          </p>
        </div>
        <ng-template [ngIf]="isEditDate">
          <kendo-datepicker
            class="params-list__field"
            calendarType="infinite"
            (valueChange)="onChangeContingentDate($event)"
            [(ngModel)]="contingentDate"
          ></kendo-datepicker>
        </ng-template>
      </kendo-label>
      <kendo-label text="Оценки на дату:" class="params-list__label__mark">
        <div class="params-list__date" *ngIf="!isEditDate">
          <kendo-icon name="calendar"></kendo-icon>
          <p kendoTypography variant="p" class="params-list__text">
            {{ getToday(currentRaitingDate) }}
          </p>
        </div>
        <ng-template [ngIf]="isEditDate">
          <kendo-datepicker
            class="params-list__field"
            calendarType="infinite"
            (valueChange)="onChangeMarkDate($event)"
            [(ngModel)]="raitingDate"
          ></kendo-datepicker>
        </ng-template>
      </kendo-label>
      <div class="buttons">
        <button
          kendoButton
          fillMode="outline"
          icon="edit"
          class="buttons__edit"
          *ngIf="!isEditDate"
          (click)="toggleEditDate()"
          [disabled]="currentSemester?.semesterNumberString !== selectedSemester?.semesterNumberString"
        >
          Изменить даты
        </button>
        <ng-template [ngIf]="isEditDate">
          <button
            kendoButton
            class="buttons__action"
            (click)="toggleEditDate()"
          >
            Отмена
          </button>
          <button
            kendoButton
            class="buttons__action buttons__action--ok"
            (click)="acceptDate(true)"
          >
            Ok
          </button>
        </ng-template>
      </div>
    </div>

    <kendo-label text="Группы:" class="params-list__label group-reload">
      <button
        kendoButton
        fillMode="outline"
        icon="reload"
        class="button"
        (click)="reloadTree()">
      </button>
    </kendo-label>

  </div>

  <kendo-stacklayout
    [align]="{ horizontal: 'center', vertical: 'middle' }"
    *ngIf="loadingTreeData"
  >
    <kendo-loader size="medium" type="converging-spinner"></kendo-loader>
  </kendo-stacklayout>
  <kendo-treeview
    *ngIf="!loadingTreeData"
    [nodes]="dataTree"
    textField="text"
    kendoTreeViewExpandable
    kendoTreeViewSelectable
    [kendoTreeViewCheckable]="treeSettings"
    class="tree"
    [children]="fetchChildren"
    [hasChildren]="checkGroup ? hasChildrenGroup : hasChildren"
    [expandedKeys]="expandedKeys"
    [isExpanded]="isExpanded"
    [(checkedKeys)]="checkedItems"
    [selectedKeys]="selectedKeys"
    [checkBy]="checkNodeBy"
    (nodeClick)="onNodeClick($event)"
    (selectionChange)="onSelectionTreeChange($event, false)"
    (blur)="onBlur()"
    [loadOnDemand]="false"
  >
  </kendo-treeview>
</div>

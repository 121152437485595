import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StudyLevel } from 'src/app/models/education/studylevel.model';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StudylevelService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.studylevel}`;

  constructor(private http: HttpClient) { }

  //Get all StudyLevel
  public getAllStudyLevel(): Observable<StudyLevel[]> {
    return this.http.get<StudyLevel[]>(this.baseUrl + '/GetAllEducationLevels');
  }

}

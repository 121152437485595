import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClient, HttpErrorResponse,  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { Announcement } from '../../models/announcement/announcement.model';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

  export class AnnouncementService {
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);

    constructor(private http: HttpClient){ }

    postData(Message: Announcement): Observable<Announcement>{
          
      let httpheaders=new HttpHeaders()
      .set('Content-type','authorization');
      let options={
        headers:httpheaders
      };
    return this.http.post<Announcement>(urlJoin(this.baseUrl, '/message'), Message, options);
  }

  public getAnnouncement(id: number){
      return this.http.get(urlJoin(this.baseUrl, '/message/') + id);
  }
   
  public SendPattern = new BehaviorSubject<any> ({
    announcement: [],
    pattern: true,
  });
   
  public currentAnnouncement = new BehaviorSubject<any>({
    deliveries: [],
    deliveriesFromList: [],
    announcement: [],
    back: false,
    pattern: false
  });

  observer = new Subject();
  public subscriber$ = this.observer.asObservable();
  public Badge(badge: number){
    this.observer.next(badge);
    
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

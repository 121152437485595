import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";
import { Discipline } from 'src/app/models/dicts/discipline.model';
import { Guid } from 'guid-typescript';
import { DisciplineInfo } from 'src/app/models/dicts/disciplineinfo.model';

@Injectable({
  providedIn: 'root'
})
export class DisciplineService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.discipline}`;

  constructor(private http: HttpClient) { }

  //Get all Discipline
  public getAllDiscipline(): Observable<Discipline[]> {
    return this.http.get<Discipline[]>(this.baseUrl);
  }

  //Add Discipline
  addDiscipline(discipline: Discipline): Observable<Discipline> {
    return this.http.post<Discipline>(this.baseUrl, discipline)
      .pipe(catchError(this.handleError));
  }

  //Delete Discipline
  deleteDiscipline(id: string): Observable<Discipline> {
    return this.http.delete<Discipline>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit Discipline
  updateDiscipline(discipline: Discipline): Observable<Discipline> {
    return this.http.put<Discipline>(this.baseUrl + '/' + discipline.dictDisciplineExternalId, discipline)
      .pipe(catchError(this.handleError));
  }

  getAllDisciplineInfo(disciplineId: Guid): Observable<DisciplineInfo[]> {
    return this.http.get<DisciplineInfo[]>(this.baseUrl + '/GetDictDisciplineInfo/' + disciplineId)
      .pipe(catchError(this.handleError));
  }

  getDisciplineById(disciplineId: Guid): Observable<Discipline> {
    return this.http.get<Discipline>(this.baseUrl + '/' + disciplineId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}
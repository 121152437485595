export class StandardModel {
    cipher!: string;
    id!: string;
    name!:  string;
    standardName!: string;
    standardTypeShortName!:  string;
    trainingLevelEnum!: number;
}

// Вкладка структуры протокола ГИА
export class StructTabDto {
    filialId?: string;
    trainingLevelId?: string;
    id!: string | null;
    name!: string;
    isHidden!: boolean;
    isMarkIncluded!: boolean;

    // Список моделей групп полей структуры
    displayPropertyGroups! : StructPropertyGroupDto[];
}

export class StructPropertyGroupDto {
   // id!: string | null;
    name!: string;
    order!: number;
    // Список полей группы
    displayProperties!: StructPropertyDto[];
}

export class StructPropertyDto {
 //   id!: string | null;
    name!: string;
    printFormName!: string
    isRequired!: boolean;
    dataTypeEnum!: Number | null;
    dictionaryId?: string | number | null;
    order!: number;
    staticDictEnum?: number | null;
}

export class StructTabShortModel {
    id!: string | null;
    tabName!: string;
    isHidden?: boolean;
}

export class GiaDisciplineListModel {
    id?: string;
    filialId!: string;
    graduateYear!: number;
    educationStandardId!: string;
    departmentId!: string | null;
    trainingLevelId!: string;
    disciplineData!: StructDisciplineDto[];
}

export class StructDisciplineDto {
    dictDisciplineId!: string;
    disciplineName!: string;
}

export class DictsModel {
    id!: string | number;
    isHidden!: boolean;
    name!: string;
    sortNumber?: string;
}

import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EducationStandardTree, DictTrainingLevel, DepartmentsModel} from 'src/app/models/gia/diplomhome.model';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class DiplomHomeService {

    baseUrl = `${environment.apiEndpoint}`;


    constructor(private http: HttpClient) { }

    public getTrainingLevel() {
        return this.http.get<DictTrainingLevel[]>(this.baseUrl + 'gia/dict/TrainingLevel');
    }

    public getDepartmentsByStudents(type?: number, trainingLevelId?: string, graduateYear?: number, name?: string, filialId?: string, standardName?: string) {
      let filter = '';

      if (name !== null && name !== undefined) filter += `Name=${name}`;
      if (type) filter += `${filter ? '&' : ''}DepartmentType=${type}`;
      if (trainingLevelId) filter += `${filter ? '&' : ''}TrainingLevelId=${trainingLevelId}`;
      if (graduateYear) filter += `${filter ? '&' : ''}GraduateYear=${graduateYear}`;
      if (filialId) filter += `${filter ? '&' : ''}FilialId=${filialId}`;
      if (standardName) filter += `${filter ? '&' : ''}StandardName=${standardName}`;

      const queryString = filter ? `?${filter}` : '';

      return this.http.get<DepartmentsModel[]>(this.baseUrl + `gia/department/GetAllByStudents/${queryString}`);
    }

    public getStandardTree(facultyId: string, trainingLevelId: string, graduateYear: number) {
        let params = new HttpParams();
        params = params.append('facultyId', facultyId);
        params = params.append('trainingLevelId', trainingLevelId);
        params = params.append('graduateYear', graduateYear);
        return this.http.get<EducationStandardTree[]>(this.baseUrl + 'gia/tree/StandardTree/', {params});
    }
}

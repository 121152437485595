import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TypesAuditorium } from 'src/app/models/classroom/types-auditorium.model';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

export class TypesAuditoriumService {
    
    baseUrl = `${environment.classroomApiEndpoint}${environment.apiPaths.classroom.dict}`;

    constructor(private http: HttpClient) { }

    public getAllTypesAuditorium(){
        return this.http.get<TypesAuditorium>(this.baseUrl + '/classroomtypes/all' );
    }

    public addTypesAuditorium(types: any){
        let data = {};
        if (types.classRoomTypeNumber == null || types.classRoomTypeNumber.length == 0){
            data = {name: types.classRoomTypeName};
        } else data = {name: types.classRoomTypeName, number: types.classRoomTypeNumber};
        //let data = {name: types.classRoomTypeName, number: types.classRoomTypeNumber};
        return this.http.post(this.baseUrl + '/classroomtype', data)
        .pipe(catchError(this.handleError));
    }

    public updateTypesAuditorium(types: any, id: string){
      //  let data = {name: types.classRoomTypeName, number: types.classRoomTypeNumber}
        let data = {};
        if (types.classRoomTypeNumber !== ""){
            data = {name: types.classRoomTypeName, number: types.classRoomTypeNumber};
        } else data = {name: types.classRoomTypeName};
        return this.http.put(this.baseUrl + '/classroomtype/' + id, data)
        .pipe(catchError(this.handleError));
    }

    public deleteTypeAuditorium(id: string){
        return this.http.delete(this.baseUrl + '/classroomtype/' + id)
        .pipe(catchError(this.handleError));
    }

      // Error
    /*  handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
        // Handle client error
        errorMessage = error.error.message;
        } else {
        // Handle server error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(() => {
        errorMessage;
        });
    }*/

    handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable, throwError} from "rxjs";
import {Guid} from "guid-typescript";
import {
  LocalSignatoryEduAdd,
  LocalSignatoryEduRead,
  LocalSignatoryEduUpdate
} from "../../models/education/localsignatory.model";

@Injectable({
  providedIn: "root"
})
export class LocalSignatoryEduService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.education.localSignatory}`;

  constructor(private http: HttpClient) {
  }

  public get(): Observable<LocalSignatoryEduRead[]> {
    return this.http.get<LocalSignatoryEduRead[]>(this.baseUrl);
  }

  public add(data: LocalSignatoryEduAdd): Observable<Guid | string> {
    return this.http.post<Guid | string>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public update(data: LocalSignatoryEduUpdate): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public delete(localSignatoryId: Guid | string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + localSignatoryId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

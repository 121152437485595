import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import {DictCycleType, DictCycleTypeEnum} from 'src/app/models/education/dictcycletype.model';
import { environment } from 'src/environments/environment';
import {OrderCategoryEnum} from "../../../models/contingent/orderscategory.model";

@Injectable({
  providedIn: 'root'
})
export class DictCycleTypeService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictCycleType}`;

  constructor(private http: HttpClient) { }

  //Get all Cycle types
  public getAllCycleTypes(): Observable<DictCycleType[]> {
    return this.http.get<DictCycleType[]>(this.baseUrl + '/GetCycleTypes/');
  }

  // Add Cycle Type
  addCycleType(dictCycleType: DictCycleType): Observable<DictCycleType> {
    return this.http.post<DictCycleType>(this.baseUrl + '/CreateCycleType/' , dictCycleType)
      .pipe(catchError(this.handleError));
  }

  // Edit Cycle Type
  updateCycleType(dictCycleType: DictCycleType): Observable<DictCycleType> {
    return this.http.put<DictCycleType>(this.baseUrl + '/UpdateCycleType/' + dictCycleType.dictCycleTypeId, dictCycleType)
      .pipe(catchError(this.handleError));
  }

  // Remove Cycle Type
  deleteCycleType(id: string): Observable<DictCycleType> {
    return this.http.delete<DictCycleType>(this.baseUrl + '/DeleteCycleType/' + id)
      .pipe(catchError(this.handleError));
  }

  public getServiceCategories(): Observable<DictCycleTypeEnum[]> {
    return this.http.get<DictCycleTypeEnum[]>(`${this.baseUrl}/ServiceCategory/`);
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

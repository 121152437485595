export enum ContingentTabsEnumList {
  personaldata = 1,
  progress,
  achievements,
  orders,
  certificate,
  grants,
  social_sphere,
  cards,
  military,
  documents,
  targetedtraining,
  history,
}

export const ContingentTabsEnum = ContingentTabsEnumList

import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import {
  DropDownsModule,
  DropDownListModule,
} from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  GridModule,
  ExcelModule,
  PDFModule,
} from '@progress/kendo-angular-grid';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { MenuModule } from '@progress/kendo-angular-menu';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AcademicstateComponent } from './components/contingent/academicstate/academicstate.component';
import { MilitarydoctypesComponent } from './components/contingent/militarydoctypes/militarydoctypes.component';
import { MilitaryranksComponent } from './components/contingent/militaryranks/militaryranks.component';
import { MilitarystatesComponent } from "./components/contingent/militarystates/militarystates.component";
import { MilitarystatusesComponent } from './components/contingent/militarystatuses/militarystatuses.component';
import { MilitarystockcategoriesComponent } from './components/contingent/militarystockcategories/militarystockcategories.component';
import { BudgetComponent } from './components/contingent/budget/budget.component';
import { BudgetsubcategoryComponent } from './components/contingent/budgetsubcategory/budgetsubcategory.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { IdentificationtypeComponent } from './components/contingent/identificationtype/identificationtype.component';
import { LanguagesComponent } from './components/contingent/languages/languages.component';
import { PhonetypesComponent } from './components/contingent/phonetypes/phonetypes.component';
import { EducationdoctypesComponent } from './components/contingent/educationdoctypes/educationdoctypes.component';
import { StudentmaininfoComponent } from './components/contingent/studentmaininfo/studentmaininfo.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { AcademicprogressComponent } from './components/contingent/academicprogress/academicprogress.component';
import { OrdersComponent } from './components/contingent/orders/orders.component';
import { PersonaldataComponent } from './components/contingent/personaldata/personaldata.component';
import { EditService } from './edit.service';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AddstudentComponent } from './components/contingent/addstudent/addstudent.component';
import { CitizenshipComponent } from './components/contingent/citizenship/citizenship.component';
import { CitizenshiptypeComponent } from './components/contingent/citizenshiptype/citizenshiptype.component';
import { FamilymemberstatusesComponent } from './components/contingent/familymemberstatuses/familymemberstatuses.component';
import { EmailtypesComponent } from './components/contingent/emailtypes/emailtypes.component';
import { StreettypesComponent } from './components/contingent/streettypes/streettypes.component';
import { CitytypesComponent } from './components/contingent/citytypes/citytypes.component';
import { OrdertypesComponent } from './components/contingent/ordertypes/ordertypes.component';
import { AdditionalfieldsComponent } from './components/contingent/additionalfields/additionalfields.component';
import { AdditionalstudentfieldsComponent } from './components/contingent/additionalstudentfields/additionalstudentfields.component';
import { LanguagelevelsComponent } from './components/contingent/languagelevels/languagelevels.component';
import { OrganizationComponent } from './components/dicts/organization/organization.component';
import { OrderscategoryComponent } from './components/contingent/orderscategory/orderscategory.component';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/ru/all';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FilterModule } from '@progress/kendo-angular-filter';
import { ListBoxModule } from '@progress/kendo-angular-listbox';
import { AchievementsComponent } from './components/contingent/achievements/achievements.component';
import { CertificateComponent } from './components/contingent/certificate/certificate.component';
import { ContingentselectionComponent } from './components/contingent/contingentselection/contingentselection.component';
import { ContingentHomeComponent } from './components/contingent/contingenthome/contingenthome.component';
import { LayoutComponent } from './components/contingent/layout/layout.component';
import { HomeComponent } from './components/home/home.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DictsLayoutComponent } from './components/dicts/layout/layout.component';
import { TrainingLevelComponent } from './components/dicts/traininglevel/traininglevel.component';
import { StudyformtypeComponent } from './components/dicts/studyformtype/studyformtype.component';
import { StudyformComponent } from './components/dicts/studyform/studyform.component';
import { StudylevelComponent } from './components/dicts/studylevel/studylevel.component';
import { QualificationdegreeComponent } from './components/dicts/qualificationdegree/qualificationdegree.component';
import { DictsHomeComponent } from './components/dicts/dictshome/dictshome.component';
import { FilialComponent } from './components/dicts/filial/filial.component';
import { FilterDropdownComponent } from './components/common/filter-dropdown/filter-dropdown.component';
import { AcademicgroupsComponent } from './components/contingent/academicgroups/academicgroupshome/academicgroups.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ContingentsettingsComponent } from './components/contingent/contingentsettings/contingentsettings.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { DictAcceleratedStudyBaseComponent } from './components/education/dict-accelerated-study-base/dict-accelerated-study-base.component';
import { DictComponentTypeComponent } from './components/education/dict-component-type/dict-component-type.component';
import { DictComponentComponent } from './components/education/dict-component/dict-component.component';
import { DictCycleTypeComponent } from './components/education/dict-cycle-type/dict-cycle-type.component';
import { DictCycleComponent } from './components/education/dict-cycle/dict-cycle.component';
import { DictLessonFormComponent } from './components/education/dict-lesson-form/dict-lesson-form.component';
import { DictLessonTypeComponent } from './components/education/dict-lesson-type/dict-lesson-type.component';
import { DictScienceBranchComponent } from './components/education/dict-science-branch/dict-science-branch.component';
import { DictTypeWorkDisciplineAdditionalComponent } from './components/education/dict-type-work-discipline-additional/dict-type-work-discipline-additional.component';
import { DictWorkCategoryScheduleComponent } from './components/education/dict-work-category-schedule/dict-work-category-schedule.component';
import { DictWorkScheduleStudyProcessTypeComponent } from './components/education/dict-work-schedule-study-process-type/dict-work-schedule-study-process-type.component';
import { DictWorkStudyPlanTypeComponent } from './components/education/dict-work-study-plan-type/dict-work-study-plan-type.component';
import { FiltermenuDropdownComponent } from './components/education/filtermenu-dropdown/filtermenu-dropdown.component';
import { KafedraEditFormComponent } from './components/education/kafedra/kafedra-edit-form/kafedra-edit-form.component';
import { KafedraComponent } from './components/education/kafedra/kafedra.component';
import { OopComponent } from './components/education/oop/oop.component';
import { StandardComponent } from './components/education/standard/standard.component';
import { StandardtypeComponent } from './components/education/standardtype/standardtype.component';
import { BaseEducationPlanComponent } from './components/education/plan/base-education-plan/base-education-plan.component';
// import { EducationPlanComponent } from './components/education/education-plan/education-plan.component';
import { EducationProgramSelectComponent } from './components/education/education-program-select/education-program-select.component';
import { EducationPlanEditComponent } from './components/education/plan/education-plan-edit/education-plan-edit.component';
import { EducationPlanDetailsComponent } from './components/education/plan/education-plan-details/education-plan-details.component';
import { EducationalProcessScheduleComponent } from './components/education/educational-process-schedule/educational-process-schedule.component';
import { EducationLayoutComponent } from './components/education/layout/layout.component';
import { PersonProfileComponent } from './components/person/person-profile/person-profile.component';
import { AddacademicgroupsComponent } from './components/contingent/academicgroups/addacademicgroups/addacademicgroups.component';
import { OpenonfocusDirective } from './directives/openonfocus.directive';
import { BlockwheelDirective } from './directives/blockwheel.directive';
import { EducationProgramCompetenciesComponent } from './components/education/education-program-competencies/education-program-competencies.component';
import { ControlactionComponent } from './components/dicts/controlaction/controlaction.component';
import { DisciplinetypeComponent } from './components/dicts/disciplinetype/disciplinetype.component';
import { StudytechnologyComponent } from './components/dicts/studytechnology/studytechnology.component';
import { DisciplineComponent } from './components/dicts/discipline/discipline.component';
import { OpenonfocusdateDirective } from './directives/openonfocusdate.directive';
import { DoubleclicktransferDirective } from './directives/doubleclicktransfer.directive';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { NewEducationPlanComponent } from './components/education/plan/new-education-plan/new-education-plan.component';
import { DisciplineFormComponent } from './components/education/plan/discipline-form/discipline-form.component';
import { LoginpageComponent } from './components/auth/loginpage.component';
import { CallBackComponent } from './components/auth/callback.component';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './guards/authguard';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { TokenStorageService } from './services/token.service';
import { jwtOptionsFactory } from './services/jwt';
import { SocialnetworksComponent } from './components/contingent/socialnetworks/socialnetworks.component';
import { InstitutionComponent } from './components/contingent/institution/institution.component';
import { InstitutiontypeComponent } from './components/contingent/institutiontype/institutiontype.component';
import { SportcategoryComponent } from './components/contingent/sportcategory/sportcategory.component';
import { SporttypeComponent } from './components/contingent/sporttype/sporttype.component';
import { BenefitComponent } from './components/contingent/benefit/benefit.component';
import { DicthealthComponent } from './components/contingent/dicthealth/dicthealth.component';
import { DictCodeArticleComponent } from './components/contingent/dictcodearticle/dictcodearticle.component';
import { IMaskModule } from 'angular-imask';
import { OpenOnFocusMultiselectDirective } from './directives/open-on-focus-multiselect.directive';
import { CompetenceMatrixComponent } from 'src/app/components/education/plan/competence-matrix/competence-matrix.component';
import { DictspravkatypeComponent } from './components/contingent/dictspravkatype/dictspravkatype.component';
import { OpenOnFocusAutocompleteDirective } from './directives/open-on-focus-autocomplete.directive';
import { StandardLimitationComponent } from './components/education/standard-limitation/standard-limitation.component';
import { StandardLimitationSettingComponent } from './components/education/standard-limitation-setting/standard-limitation-setting.component';
import { DictGroupStandardLimitationComponent } from './components/education/dict-group-standard-limitation/dict-group-standard-limitation.component';
import { CopyPlanComponent } from 'src/app/components/education/plan/copy-plan/copy-plan.component';
/*
import { ScheduleSettingsComponent } from 'src/app/components/education/plan/schedule/process-schedule-old/schedule-settings/schedule-settings.component';
import { ProcessScheduleComponent } from 'src/app/components/education/plan/schedule/process-schedule-old/process-schedule-old.component';
*/
//#region Signatory
import { GlobalSignatoryComponent} from "./components/dicts/global-signatory/global-signatory.component";
import { GlobalSignatoryRoleComponent } from "./components/dicts/global-signatory-role/global-signatory-role.component";
import { LocalSignatoryComponent } from "./components/contingent/localsignatory/localsignatory.component";
import { DictLocalSignatoryRoleComponent } from "./components/contingent/dicts/localsignatoryrole/localsignatoryrole.component";
import { LocalSignatoryCCComponent } from "./components/currentcontrol/local-signatory/local-signatory.component";
import {LocalSignatoryEduComponent} from "./components/education/local-signatory/local-signatory.component";
import {LocalSignatoryMCComponent} from "./components/middlecontrol/local-signatory/local-signatory.component";
//#endregion
import { MiddlecontrolHomeComponent } from './components/middlecontrol/middlecontrolhome/middlecontrolhome.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { MiddlecontrolfiltersComponent } from './components/middlecontrol/middlecontrolfilters/middlecontrolfilters.component';
import { MiddlecontrolprintstatementComponent } from './components/middlecontrol/middlecontrolprintstatement/middlecontrolprintstatement.component';
import { MiddlecontrolhometreeComponent } from './components/middlecontrol/middlecontrolhometree/middlecontrolhometree.component';
import { MiddlecontrolsignereditComponent } from './components/middlecontrol/middlecontrolsigneredit/middlecontrolsigneredit.component';
import { MiddlecontrolprintsessionresultComponent } from './components/middlecontrol/middlecontrolprintsessionresult/middlecontrolprintsessionresult.component';
import { MiddlecontrolprintreportComponent } from './components/middlecontrol/middlecontrolprintreport/middlecontrolprintreport.component';
import { PrintDebtorsByCourseComponent } from "./components/middlecontrol/print-debtors-by-course/print-debtors-by-course.component";
import { SessiontableComponent } from './components/middlecontrol/sessiontable/sessiontable.component';
import { SessiontablelegendComponent } from './components/middlecontrol/sessiontablelegend/sessiontablelegend.component';
import { DisciplinetableComponent } from './components/middlecontrol/disciplinetable/disciplinetable.component';
import { DisciplinefooterComponent } from './components/middlecontrol/disciplinefooter/disciplinefooter.component';
import { StatementsettingsComponent } from './components/middlecontrol/statementsettings/statementsettings.component';
import { SignerparamsComponent } from './components/middlecontrol/signerparams/signerparams.component';
import { RetakeComponent } from './components/middlecontrol/retake/retake.component';
import { SessiondateComponent } from './components/middlecontrol/sessiondate/sessiondate.component';
import { StatementParamsComponent } from './components/middlecontrol/statementparams/statementparams.component';
import { CurrentcontrolHomeComponent } from './components/currentcontrol/currentcontrolhome/currentcontrolhome.component';
import { CurrentcontrolhometreeComponent } from './components/currentcontrol/currentcontrolhometree/currentcontrolhometree.component';
import { CurrentcontrolprintstatementComponent } from "./components/currentcontrol/currentcontrolprintstatement/currentcontrolprintstatement.component"
import { CurrentstatementsettingsComponent } from './components/currentcontrol/currentstatementsettings/currentstatementsettings.component';
import { CurrentdisciplinetableComponent } from './components/currentcontrol/currentdisciplinetable/currentdisciplinetable.component';
import { RetakeeditComponent } from './components/middlecontrol/retakeedit/retakeedit.component';
import { CurrentcontrolfiltersComponent } from './components/currentcontrol/currentcontrolfilters/currentcontrolfilters.component';
import { CurrentsessiontableComponent } from './components/currentcontrol/currentsessiontable/currentsessiontable.component';
import { CurrentsessionlegendComponent } from './components/currentcontrol/currentsessionlegend/currentsessionlegend.component';
import { TogglestepComponent } from './components/currentcontrol/togglestep/togglestep.component';
import { CurrentcontrolsignereditComponent } from './components/currentcontrol/currentcontrolsigneredit/currentcontrolsigneredit.component';
import { CurrentcontrolsignerparamsComponent } from './components/currentcontrol/currentcontrolsignerparams/currentcontrolsignerparams.component';
import { EducationalProcessScheduleSettingsComponent } from './components/education/educational-process-schedule-settings/educational-process-schedule-settings.component';
import { EducationalProcessScheduleTableComponent } from './components/education/educational-process-schedule-table/educational-process-schedule-table.component';
import { EducationalProcessScheduleCellComponent } from './components/education/educational-process-schedue-cell/educational-process-schedule-cell.component';
import { EducationalProcessScheduleStatisticComponent } from './components/education/educational-process-schedule-statistic/educational-process-schedule-statistic.component';
import { GlobalScheduleSettingsComponent } from 'src/app/components/education/plan/schedule/global-schedule-settings/global-schedule-settings.component';
import { MessageService } from '@progress/kendo-angular-l10n';
import { LocalizationMessageService } from './services/messages/localization-message-service.service';
import { EducationalProcessScheduleLegendComponent } from './components/education/educational-process-schedule-legend/educational-process-schedule-legend.component';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { StudentsComponent} from "./components/announcement/modify/students/students.component";
import { ModifyComponent } from "./components/announcement/modify/modify.component";
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { PersonsComponent} from "./components/announcement/modify/persons/persons.component";
import { AuthorComponent } from "./components/announcement/author/author.component";
import { ShowComponent } from "./components/announcement/show/show.component";
import { DialogComponent } from "./components/announcement/modify/announcement/dialog/dialog.component";
import { UploadComponent } from "./components/announcement/modify/announcement/dialog/upload.component";
import { CommentsComponent} from "./components/announcement/show/comments/comments.component";
import { DistribuitionsComponent } from "./components/announcement/modify/distribuitions/distribuitions.component";
import { TechnicalAdministratorsComponent } from './components/useraccess/technical-administrators/technical-administrators.component';
import { ContingentAccessComponent } from './components/useraccess/contingent-access/contingent-access.component';
import { MiddleControlAccessComponent } from './components/useraccess/middle-control-access/middle-control-access.component';
import { CurrentControlAccessComponent } from './components/useraccess/current-control-access/current-control-access.component';
import { EducationAccessComponent } from './components/useraccess/education-access/education-access.component';
import { CommonDictionariesAccessComponent } from './components/useraccess/common-dictionaries-access/common-dictionaries-access.component';
import { CurrentControlMenuAccessComponent } from './components/currentcontrol/currentcontrolmenuaccess/currentcontrolmenuaccess.component';
import { MiddleControlMenuAccessComponent } from './components/middlecontrol/middlecontrolmenuaccess/middlecontrolmenuaccess.component';
import { AlertComponent} from "./components/announcement/alert/alert.component";
import { SelectedStudentsComponent } from "./components/announcement/modify/students/selected-students/selected-students.component";
import { SelectedStudentsDistrComponent } from "./components/announcement/modify/distribuitions/selected-students/selected-students.component";
import { SelectedStudentNewListComponent } from "./components/announcement/modify/distribuitions/new-list/selected-students-new-list/selected-students-new-list.component";
import { FoundStudentsComponent } from "./components/announcement/modify/students/found-students/found-students.component";
import { SelectedPersonsComponent } from "./components/announcement/modify/persons/selected-persons/selected-persons.component";
import { SelectedPersonsDistrComponent } from "./components/announcement/modify/distribuitions/selected-persons/selected-persons.component";
import { SelectedPersonsNewListComponent } from "./components/announcement/modify/distribuitions/new-list/selected-persons-new-list/selected-persons-new-list.component";
import { FoundPersonsComponent } from "./components/announcement/modify/persons/found-persons/found-persons.component";
import { SearchStudentsComponent } from "./components/announcement/modify/students/search-students/search-students.component";
import { ExpandStudentsComponent } from "./components/announcement/modify/students/expand-students/expand-students.component";
import { AnnouncementComponent } from "./components/announcement/modify/announcement/announcement.component";
import { EditorModule } from "@progress/kendo-angular-editor";
import { PanelControlComponent } from "./components/announcement/modify/students/panel-control/panel-control.component";
import { SearchPersonsComponent } from "./components/announcement/modify/persons/search-persons/search-persons.component";
import { PagerModule } from '@progress/kendo-angular-pager';

import { Routes, RouterModule } from '@angular/router';
import { DisciplineActionButtonComponent } from './components/middlecontrol/discipline-action-button/discipline-action-button.component';
import { DictCompetencesComponent } from './components/education/plan/dict-competences/dict-competences.component';
import { SwitchUserComponent } from './components/switchuser/switchuser.component';
import { DisciplineWorkloadContingentComponent } from './components/disciplineworkload/discipline-workload-contingent/discipline-workload-contingent.component';
import { AssignmentHomeComponent } from './components/disciplineworkload/assignments/assignmentHome/assignmentHome.component';
import { DepartmentWorkloadComponent } from './components/disciplineworkload/department-workload/department-workload.component';
import { DepartmentAssignmentComponent } from './components/disciplineworkload/assignments/departmentAssignment/departmentAssignment.component';
import { DisciplineAssignmentComponent } from './components/disciplineworkload/assignments/disciplineAssignment/disciplineAssignment.component';
import { DisciplineWorkloadAccessComponent } from './components/useraccess/discipline-workload-access/discipline-workload-access.component';
import { AnnouncementLayoutComponent } from './components/announcement/layout/layout.component';
import { AnnouncementAccessComponent } from './components/useraccess/announcement-control-access/announcement-access.component';
import { DictmaritalstatusComponent } from './components/contingent/dictmaritalstatus/dictmaritalstatus.component';
import { HolidaysAndWeekendsComponent } from './components/dicts/holidays-and-weekends/holidays-and-weekends/holidays-and-weekends.component';
import { DisciplineInfoComponent } from './components/dicts/discipline-info/discipline-info.component';
import { DictmarkchangereasonComponent } from './components/middlecontrol/dictmarkchangereason/dictmarkchangereason.component';
import { MarkComponent } from './components/dicts/mark/mark.component';
import { TeacherWorkloadComponent } from './components/disciplineworkload/teacher-workload/teacher-workload.component';
import { DictmarkcriterialevelComponent } from './components/currentcontrol/dictmarkcriterialevel/dictmarkcriterialevel.component';
import { DictcontrolactionComponent } from './components/middlecontrol/dict-control-action/dictcontrolaction.component';
import { DictDisciplineTypeComponent } from './components/education/dict-discipline-type/dict-discipline-type.component';
import { DepartmentComponent } from './components/education/department/department.component';

import { ClassroomFundComponent } from './components/classroom/classroom-fund.component';
import { BuildingComponent } from './components/classroom/building/building.component';
import { TypesAuditoriumComponent } from './components/classroom/types-auditorium/types-auditorium.component';
import { AssignAuditoriumComponent } from './components/classroom/assign-auditorium/assign-auditorium.component';
import { StructuralComponent } from './components/classroom/structural/structural.component';
import { ParametersAuditoriumComponent } from './components/classroom/parameters-auditorium/parameters-auditorium.component';
import { ClassroomLayoutComponent } from './components/classroom/layout/layout.component';
import { ClassroomAccessComponent } from './components/useraccess/classroom-access/classroom-access.component';
import { MilitarycommissariatComponent } from './components/contingent/militarycommissariat/militarycommissariat.component';
//import { DictstudyformComponent } from './components/contingent/dictstudyform/dictstudyform.component';
import { DisciplineWorkloadRedirectComponent } from './components/disciplineworkload/redirect/discipline-workload-redirect.component';
import { EditacademicgroupsComponent } from './components/contingent/academicgroups/editacademicgroups/editacademicgroups.component';
import { ReportsWorkloadComponent } from './components/disciplineworkload/reportsWorkload/reports-workload.component';
import { DictEnlargedGroupSpecializationComponent } from './components/education/dict-enlarged-group-specialization/dict-enlarged-group-specialization.component';
import { MilitarycertificateComponent } from './components/contingent/militarycertificate/militarycertificate.component';
import { ContingentjournaldetailsComponent } from './components/contingent/contingentjournal/contingentjournaldetails/contingentjournaldetails.component';
import {
  ContingentjournalComponent
} from "./components/contingent/contingentjournal/contingentjournalhome/contingentjournal.component";
import { DocumentnamesComponent } from './components/contingent/documentnames/documentnames.component';
import { DocumentsComponent } from './components/contingent/documents/documents.component';
import { DictTeachersComponent } from './components/disciplineworkload/dictTeachers/dict-teachers.component';
import { AssignmentsMonitoringComponent } from './components/disciplineworkload/assignments/assignmentsMonitoring/assignments-monitoring.component';
import { EducationPlanReattachmentComponent } from './components/education/plan/education-plan-reattachment/education-plan-reattachment.component';
import { PublicationsHomeComponent } from './components/science/publications/mainPage/home/publications-home.component';
import { PublicationsSummaryInfoComponent } from './components/science/publications/summaryInfo/publications-summary-info.component';
import { PublicationsAuthorsHomeComponent } from './components/science/publications/authors/home/publications-authors-home.component';
import { PublicationsAuthorsEditFormComponent } from './components/science/publications/authors/editForm/publications-authors-edit-form.component';
import { PublicationsReportsComponent } from './components/science/publications/reports/publications-reports.component';
import { PublicationsSettingsComponent } from './components/science/publications/settings/publications-settings.component';
import { PublicationsEditFormHomeComponent } from './components/science/publications/editForm/home/publications-edit-form-home.component';
import { PublicationsAuthorsAndThematicRubricatorsComponent } from './components/science/publications/editForm/authors-and-thematicRubricators/publications-authors-and-thematic-rubricators.component';
import { PublicationsEditFormCodesComponent } from './components/science/publications/editForm/codes/publications-edit-form-codes.component';
import { PublicationsEditFormMainInfoComponent } from './components/science/publications/editForm/mainInfo/publications-edit-form-main-info.component';
import { PublicationsEditFormAboutComponent } from './components/science/publications/editForm/about/publications-edit-form-about.component';
import { PublicationsEditFormCommentsComponent } from './components/science/publications/editForm/comments/publications-edit-form-comments.component';
import { PublicationsEditFormAddAuthorComponent } from './components/science/publications/editForm/addAuthor/publications-edit-form-add-author.component';
import { PublicationsAuthorCardComponent } from './components/science/publications/authors/author-card/publications-author-card.component';
import { PublicationsPostsComponent } from './components/science/publications/dicts/posts/publications-posts.component';
import { PublicationsScienceDegreesComponent } from './components/science/publications/dicts/scienceDegrees/publications-science-degrees.component';
import { PublicationsTypesComponent } from './components/science/publications/dicts/publicationsTypes/publications-types.component';
import { PublicationsScientometricBasesComponent } from './components/science/publications/dicts/scientometricBases/publications-scientometric-bases.component';
import { PublicationsAuthorRolesComponent } from './components/science/publications/dicts/authorRoles/publications-author-roles.component';
import { PublicationsSubTypesComponent } from './components/science/publications/dicts/publicationsSubTypes/publications-sub-types.component';
import { PublicationsAccessComponent } from './components/useraccess/publications-access/publications-access.component';
import { ExportScheduleEducationComponent } from './components/education/export-schedule-education-process/export-schedule-education-process.component';
import { PublicationsOrganizationsComponent } from "./components/science/publications/dicts/organizations/publications-organizations.component";
//publication
import { PublicationsDictComponent } from './components/science/publications/dicts/dict/publications-dict.component';
import { FilterMultiselectComponent } from './components/common/filter-multiselect/filter-multiselect.component';
import { FilterComboboxComponent } from './components/common/filter-combobox/filter-combobox.component';
//technicaladmin
import { CadreBaseComponent } from './components/persondepartment/cadre-base/cadre-base.component';
import { PersondepartmentLayoutComponent } from './components/persondepartment/layout/layout.component';
import { ParametersPersonComponent} from './components/persondepartment/parameters-person/parameters-person.component';
import { PersonCardComponent } from './components/persondepartment/person-card/person-card.component';
import { PostsComponent } from './components/persondepartment/posts/posts.component';
import { ScientificDegreeComponent } from './components/persondepartment/scientific-degree/scientific-degree.component';
import { DepartmentsAdminComponent } from './components/persondepartment/departments/departments.component';
import { DepartmentsNameComponent } from './components/persondepartment/departments-name/departments-name.component';
import { DepartmentsTypeComponent } from './components/persondepartment/departments-type/departments-type.component';

import { StudplanComponent } from './components/contingent/studplan/studplan.component';
import { StudenthistorylistComponent } from './components/contingent/studenthistory/studenthistorylist/studenthistorylist.component';
import { StudenthistorydetailsComponent } from './components/contingent/studenthistory/studenthistorydetails/studenthistorydetails.component';
import { DictAchievement } from './components/contingent/dictachievement/dictachievement.component';
import { ChangePropertyOrderPipe } from './components/science/publications/editForm/about/pipes/changePropertyOrder.pipe';
import { DictcertificatepurposeComponent } from './components/contingent/dictcertificatepurpose/dictcertificatepurpose.component';
import {PayslipComponent} from "./components/payslip/payslip.component";
import {WorkLoadSettingsComponent} from "./components/disciplineworkload/settings/settings.component";
import { DateRangeFilterComponent } from './components/common/date-range-filter/date-range-filter.component';
import { StudentcardsettingsComponent } from './components/contingent/studentcardsettings/studentcardsettings.component';
import { TargetedTrainingComponent } from './components/contingent/targetedtraining/targeted-training.component';
import { BudgetcategoryComponent } from './components/contingent/budgetcategory/budgetcategory.component';
import { DictotherdocumenttypesComponent } from './components/contingent/dictotherdocumenttypes/dictotherdocumenttypes.component';
import { ComplaintmanagementstatusComponent } from './components/contingent/complaintmanagemenstatus/complaintmanagementstatus.component';
import { PaymentstateComponent } from './components/contingent/paymentstate/paymentstate.component';
import { EmploymentstatusComponent } from './components/contingent/employmentstatus/employmentstatus.component';
import { DictfacultyComponent } from './components/contingent/dictfaculty/dictfaculty.component';
import { DicttraininglevelsComponent } from './components/contingent/dicttraininglevels/dicttraininglevels.component';
import { WorkloadSettingsComponent as DisciplineWorkLoadSettings } from "./components/disciplineworkload/workload-settings/workload-settings.component";
import { MilitaryCategoriesComponent } from './components/contingent/dicts/militarycategories/militarycategories.component';
import {ReestrComponent} from "./components/cards/reestr/reestr.component";
import {CardsComponent} from "./components/cards/cards.component";
import {AddToListFormingComponent} from "./components/cards/add-to-list-forming/add-to-list-forming.component";
import { WorktypesComponent } from "./components/contingent/dicts/worktypes/worktypes.component";
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { MilitaryProfilesComponent } from "./components/contingent/dicts/militaryprofiles/militaryprofiles.component";
import { SalaryProjectComponent } from "./components/cards/dicts/salaryproject/salaryproject.component";
import { NonEmploymentReasonsComponent } from "./components/contingent/dicts/nonemploymentreasons/nonemploymentreasons.component";
import { AchievementsStatusesComponent } from "./components/contingent/dicts/achievementsstatuses/achievementsstatuses.component";
import { DictgeneralsheetComponent } from './components/middlecontrol/dictgeneralsheet/dictgeneralsheet.component';
import {MilitaryCertificatesComponent} from './components/contingent/militarycertificates/military-certificates.component';
import {MilitaryCertificateFormComponent} from './components/contingent/militarycertificateform/military-certificate-form.component';
import {MilitaryDocumentsComponent} from './components/contingent/militarydocuments/military-documents.component';
import {MilitaryComponent} from './components/contingent/military/military.component';
import {MilitaryFormTypesComponent} from './components/contingent/militaryformtypes/military-form-types.component';
import {CardLayoutComponent} from "./components/cards/layout/layout.component";
import { StudentInfoCardsComponent } from './components/cards/studentinfocards/studentinfocards.component';
import { CardsAccessComponent } from './components/useraccess/cards-access/cards-access.component';
//report
import {ReportListComponent as ContingentReportListComponent} from "./components/contingent/report-list/report-list.component";
import {ReportListComponent as SessionReportListComponent } from "./components/middlecontrol/report-list/report-list.component";
import {ReportDesignerComponent} from "./components/report-designer/report-designer.component";
import {ReportViewerComponent} from "./components/report-viewer/report-viewer.component";
import { DictlocalsignatoryroleComponent } from './components/middlecontrol/dictlocalsignatoryrole/dictlocalsignatoryrole.component';
//GIA
import {DictPrintTemplateCategoryComponent} from './components/gia/dicts/dict-print-template-category/dict-print-template-category.component';
import {PrintTemplateComponent} from './components/gia/print-template/print-template.component';
import {AddPrintTemplateComponent} from './components/gia/print-template/add-print-template/add-print-template.component';
import {FormationDiplomaComponent} from './components/gia/diploms/formationdiploma/formationdiploma.component';
import {BasicInformationComponent} from './components/gia/diploms/basicinformation/basicinformation.component';
import {ApplicationDiplomaComponent} from './components/gia/diploms/applicationdiploma/applicationdiploma.component';
import {StateExamCommissionComponent} from './components/gia/state-exam-commission/state-exam-commission.component';
import {AddStateExamCommissionComponent} from './components/gia/state-exam-commission/add-state-exam-commission/add-state-exam-commission.component';
import {AdditionalDataComponent} from './components/gia/diploms/additionaldata/additionaldata.component';
import {AddDisciplineComponent} from './components/gia/diploms/adddiscipline/adddiscipline.component';
import { DictStateExamCommissionTypeComponent } from './components/gia/dicts/dict-state-exam-commission-type/dict-state-exam-commission-type.component';
import { GiaAccessComponent } from './components/useraccess/gia-access/gia-access.component';
import { GiaSettingsComponent } from './components/gia/settings/gia-settings.component';
import { GiaDictComponent } from './components/gia/dicts/dict/gia-dict.component';
import { DictDesignerComponent } from './components/gia/dicts/dict-designer/dict-designer.component';
import { GiaDictLocalSignatoryRoleComponent } from './components/gia/dicts/dict-local-signatory-role/gia-dict-local-signatory-role.component';
import { GiaLocalSignatoryComponent } from './components/gia/local-signatory/gia-local-signatory.component';
import { DictDiplomaTypeComponent } from './components/gia/dicts/dict-diploma-type/dict-diploma-type.component';
import { GiaPageComponent } from './components/gia/diploms/giapage/giapage.component';
import { DictStateExamCommissionMemberRoleComponent } from './components/gia/dicts/dict-state-exam-commission-member-role/dict-state-exam-commission-member-role.component';
import { ReportListComponent } from './components/gia/report-list/report-list.component';
import { ConstructorGiaProtocolComponent } from './components/gia/constructor-giaprotocol/constructor-giaprotocol.component';
import { GiaProtocolHomeComponent } from './components/gia/gia-protocol/giaprotocolhome/giaprotocolhome.component';
import { GiaProtocolTableComponent } from './components/gia/gia-protocol/giaprotocoltable/giaprotocoltable.component';
import { StudentProtocolComponent } from './components/gia/gia-protocol/student-protocol/student-protocol.component';

import {MFCComponents} from './routes/mfc-routes';
import { AdmissionOrderHomeComponent } from './components/gia/admission-order/admission-order-home/admission-order-home.component';
import { ReleaseOrderHomeComponent } from './components/gia/release-order/release-order-home/release-order-home.component';
import { AdmissionOrderTableComponent } from './components/gia/admission-order/admission-order-table/admission-order-table.component';
import { ReleaseOrderTableComponent } from './components/gia/release-order/release-order-table/release-order-table.component';
import { AdmissionOrderFormComponent } from './components/gia/admission-order/admission-order-form/admission-order-form.component';
import { ReleaseOrderFormComponent } from './components/gia/release-order/release-order-form/release-order-form.component';
import { AdmissionOrderAddStudentComponent } from './components/gia/admission-order/admission-order-add-student/admission-order-add-student.component';
import { ReleaseOrderAddStudentComponent } from './components/gia/release-order/release-order-add-student/release-order-add-student.component';
import { OrderStudentSearchComponent } from './components/gia/order-student-search/order-student-search.component';
import { StudentDebtComponent } from './components/gia/admission-order/student-debt/student-debt.component';
import {OfertaComponents} from "./routes/oferta-routes";
import {GiaComponents} from "./routes/gia-routes";
import {DiplomHomeComponent} from "./components/gia/diplomhome/diplomhome.component";
import {DiplomTableComponent} from "./components/gia/diploms/diplomatable/diplomatable.component";
import {EducationalorganizationnameComponent} from "./components/dicts/educationalorganizationname/educationalorganizationname.component";

//diplomablanks
import {DiplomablanksComponents} from './routes/diplomablanks-routes';
import {DictComponent} from "./components/common/dict/dict.component";
import {
  FilterMultiselectTreeComponent
} from "./components/common/filter-multiselect-tree/filter-multiselect-tree.component";
import {AfterValueChangedDirective} from './directives/after-value-changed.directive';

const appRoutes: Routes = [

  { path: 'show/:id', component: ShowComponent },
  {path: 'announcement/:id', component: AnnouncementComponent},
  {path: 'modify/:id', component: ModifyComponent},

]

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    AcademicstateComponent,
    ContingentHomeComponent,
    MilitarydoctypesComponent,
    MilitaryranksComponent,
    MilitarystatesComponent,
    MilitarystatusesComponent,
    MilitarystockcategoriesComponent,
    BudgetComponent,
    BudgetsubcategoryComponent,
    IdentificationtypeComponent,
    LanguagesComponent,
    PhonetypesComponent,
    EducationdoctypesComponent,
    StudentmaininfoComponent,
    AcademicprogressComponent,
    OrdersComponent,
    PersonaldataComponent,
    AddstudentComponent,
    CitizenshipComponent,
    CitizenshiptypeComponent,
    FamilymemberstatusesComponent,
    EmailtypesComponent,
    StreettypesComponent,
    CitytypesComponent,
    OrdertypesComponent,
    AdditionalfieldsComponent,
    AdditionalstudentfieldsComponent,
    LanguagelevelsComponent,
    FilialComponent,
    OrganizationComponent,
    OrderscategoryComponent,
    AchievementsComponent,
    CertificateComponent,
    ContingentselectionComponent,
    ContingentjournalComponent,
    LayoutComponent,
    HomeComponent,
    TrainingLevelComponent,
    DictsHomeComponent,
    StudylevelComponent,
    StudyformtypeComponent,
    StudyformComponent,
    QualificationdegreeComponent,
    PersonProfileComponent,
    FilterDropdownComponent,
    DictsLayoutComponent,
    AcademicgroupsComponent,
    ContingentsettingsComponent,
    StandardComponent,
    StandardtypeComponent,
    FilterDropdownComponent,
    OopComponent,
    KafedraComponent,
    KafedraEditFormComponent,
    DictComponentTypeComponent,
    DictComponentComponent,
    DictCycleTypeComponent,
    DictCycleComponent,
    DictWorkCategoryScheduleComponent,
    DictWorkScheduleStudyProcessTypeComponent,
    DictLessonTypeComponent,
    DictLessonFormComponent,
    DictAcceleratedStudyBaseComponent,
    DictWorkStudyPlanTypeComponent,
    FiltermenuDropdownComponent,
    // EducationPlanComponent,
    EducationPlanDetailsComponent,
    GlobalScheduleSettingsComponent,
    EducationPlanEditComponent,
    NewEducationPlanComponent,
    CopyPlanComponent,
    //ScheduleSettingsComponent,
    //ProcessScheduleComponent,
    DisciplineFormComponent,
    CompetenceMatrixComponent,
    EducationProgramSelectComponent,
    EducationalProcessScheduleComponent,
    EducationLayoutComponent,
    AddacademicgroupsComponent,
    OpenonfocusDirective,
    BlockwheelDirective,
    BaseEducationPlanComponent,
    EducationProgramCompetenciesComponent,
    ControlactionComponent,
    DisciplineComponent,
    DisciplinetypeComponent,
    StudytechnologyComponent,
    OpenonfocusdateDirective,
    DoubleclicktransferDirective,
    LoginpageComponent,
    CallBackComponent,
    SocialnetworksComponent,
    InstitutionComponent,
    InstitutiontypeComponent,
    SportcategoryComponent,
    SporttypeComponent,
    BenefitComponent,
    DicthealthComponent,
    DictCodeArticleComponent,
    StandardLimitationComponent,
    OpenOnFocusMultiselectDirective,
    DictspravkatypeComponent,
    OpenOnFocusAutocompleteDirective,
    StandardLimitationSettingComponent,
    DictGroupStandardLimitationComponent,
    MiddlecontrolHomeComponent,
    MiddlecontrolfiltersComponent,
    MiddlecontrolprintstatementComponent,
    MiddlecontrolprintreportComponent,
    MiddlecontrolhometreeComponent,
    MiddlecontrolsignereditComponent,
    MiddlecontrolprintsessionresultComponent,
    PrintDebtorsByCourseComponent,
    SessiontableComponent,
    SessiontablelegendComponent,
    DisciplinetableComponent,
    DisciplinefooterComponent,
    StatementsettingsComponent,
    SignerparamsComponent,
    RetakeComponent,
    CurrentcontrolHomeComponent,
    CurrentcontrolhometreeComponent,
    CurrentcontrolprintstatementComponent,
    CurrentstatementsettingsComponent,
    CurrentdisciplinetableComponent,
    SessiondateComponent,
    RetakeComponent,
    RetakeeditComponent,
    CurrentcontrolfiltersComponent,
    CurrentsessiontableComponent,
    CurrentsessionlegendComponent,
    TogglestepComponent,
    CurrentcontrolsignereditComponent,
    CurrentcontrolsignerparamsComponent,
    StudentsComponent,
    ModifyComponent,
    PersonsComponent,
    AuthorComponent,
    ShowComponent,
    CommentsComponent,
    DistribuitionsComponent,
    AlertComponent,
    SelectedStudentsComponent,
    SelectedStudentsDistrComponent,
    FoundStudentsComponent,
    SelectedPersonsComponent,
    SelectedPersonsDistrComponent,
    FoundPersonsComponent,
    SearchStudentsComponent,
    ExpandStudentsComponent,
    AnnouncementComponent,
    DialogComponent,
    UploadComponent,
    PanelControlComponent,
    SearchPersonsComponent,
    EducationalProcessScheduleSettingsComponent,
    EducationalProcessScheduleTableComponent,
    EducationalProcessScheduleCellComponent,
    EducationalProcessScheduleLegendComponent,
    EducationalProcessScheduleStatisticComponent,
    TechnicalAdministratorsComponent,
    ContingentAccessComponent,
    MiddleControlAccessComponent,
    CurrentControlAccessComponent,
    EducationAccessComponent,
    CommonDictionariesAccessComponent,
    CurrentControlMenuAccessComponent,
    MiddleControlMenuAccessComponent,
    DisciplineActionButtonComponent,
    DictCompetencesComponent,
    SwitchUserComponent,
    //#region Signatory
    GlobalSignatoryComponent,
    GlobalSignatoryRoleComponent,
    LocalSignatoryComponent,
    DictLocalSignatoryRoleComponent,
    LocalSignatoryCCComponent,
    LocalSignatoryEduComponent,
    LocalSignatoryMCComponent,
    //#endregion
    DisciplineWorkloadContingentComponent,
    AssignmentHomeComponent,
    DepartmentWorkloadComponent,
    DepartmentAssignmentComponent,
    DisciplineAssignmentComponent,
    DisciplineWorkloadAccessComponent,
    AnnouncementLayoutComponent,
    AnnouncementAccessComponent,
    DictmaritalstatusComponent,
    HolidaysAndWeekendsComponent,
    DisciplineInfoComponent,
    DictmarkchangereasonComponent,
    MarkComponent,
    DictmarkcriterialevelComponent,
    DictcontrolactionComponent,
    DictDisciplineTypeComponent,
    DepartmentComponent,
    ClassroomFundComponent,
    BuildingComponent,
    TypesAuditoriumComponent,
    AssignAuditoriumComponent,
    StructuralComponent,
    ParametersAuditoriumComponent,
    ClassroomLayoutComponent,
    ClassroomAccessComponent,
    TeacherWorkloadComponent,
    MilitarycommissariatComponent,
    //DictstudyformComponent,
    MilitarycommissariatComponent,
    DisciplineWorkloadRedirectComponent,
    ReportsWorkloadComponent,
    EditacademicgroupsComponent,
    DictEnlargedGroupSpecializationComponent,
    MilitarycertificateComponent,
    ContingentjournaldetailsComponent,
    DocumentnamesComponent,
    DocumentsComponent,
    DictTeachersComponent,
    SelectedStudentNewListComponent,
    SelectedPersonsNewListComponent,
    AssignmentsMonitoringComponent,
    ExportScheduleEducationComponent,
    EducationPlanReattachmentComponent,
    CadreBaseComponent,
    PersondepartmentLayoutComponent,
    ParametersPersonComponent,
    PersonCardComponent,
    StudplanComponent,
    PostsComponent,
    ScientificDegreeComponent,
    DepartmentsAdminComponent,
    DepartmentsNameComponent,
    DepartmentsTypeComponent,
    StudenthistorylistComponent,
    StudenthistorydetailsComponent,
    PublicationsHomeComponent,
    PublicationsSummaryInfoComponent,
    PublicationsAuthorsHomeComponent,
    PublicationsAuthorsEditFormComponent,
    PublicationsReportsComponent,
    PublicationsSettingsComponent,
    PublicationsEditFormHomeComponent,
    PublicationsAuthorsAndThematicRubricatorsComponent,
    PublicationsEditFormCodesComponent,
    PublicationsEditFormMainInfoComponent,
    PublicationsEditFormAboutComponent,
    PublicationsEditFormCommentsComponent,
    PublicationsEditFormAddAuthorComponent,
    PublicationsAuthorCardComponent,
    PublicationsPostsComponent,
    PublicationsScienceDegreesComponent,
    PublicationsTypesComponent,
    PublicationsScientometricBasesComponent,
    PublicationsAuthorRolesComponent,
    PublicationsSubTypesComponent,
    PublicationsAccessComponent,
    PublicationsDictComponent,
    PublicationsOrganizationsComponent,
    FilterMultiselectComponent,
    FilterComboboxComponent,
    FilterMultiselectTreeComponent,
    DictAchievement,
    ChangePropertyOrderPipe,
    DictcertificatepurposeComponent,
    DictTypeWorkDisciplineAdditionalComponent,
    DictScienceBranchComponent,
    PayslipComponent,
    StudentcardsettingsComponent,
    WorkLoadSettingsComponent,
    DateRangeFilterComponent,
    TargetedTrainingComponent,
    DateRangeFilterComponent,
    BudgetcategoryComponent,
    DictotherdocumenttypesComponent,
    ComplaintmanagementstatusComponent,
    PaymentstateComponent,
    EmploymentstatusComponent,
    DictfacultyComponent,
    DicttraininglevelsComponent,
    DisciplineWorkLoadSettings,
    MilitaryCategoriesComponent,
    StatementParamsComponent,
    ReestrComponent,
    CardsComponent,
    AddToListFormingComponent,
    CardLayoutComponent,
    AddToListFormingComponent,
    WorktypesComponent,
    MilitaryProfilesComponent,
    SalaryProjectComponent,
    NonEmploymentReasonsComponent,
    AchievementsStatusesComponent,
    DictgeneralsheetComponent,
    MilitaryCertificatesComponent,
    MilitaryCertificateFormComponent,
    MilitaryDocumentsComponent,
    MilitaryComponent,
    MilitaryFormTypesComponent,
    //#region Report
    ContingentReportListComponent,
    SessionReportListComponent,
    ReportDesignerComponent,
    ReportViewerComponent,
    //endregion
    StudentInfoCardsComponent,
    CardsAccessComponent,
    DictlocalsignatoryroleComponent,
    DiplomHomeComponent,
    DiplomTableComponent,
    DictPrintTemplateCategoryComponent,
    PrintTemplateComponent,
    AddPrintTemplateComponent,
    EducationalorganizationnameComponent,
    FormationDiplomaComponent,
    BasicInformationComponent,
    ApplicationDiplomaComponent,
    StateExamCommissionComponent,
    AddStateExamCommissionComponent,
    AdditionalDataComponent,
    AddDisciplineComponent,
    GiaAccessComponent,
    DictStateExamCommissionTypeComponent,
    GiaSettingsComponent,
    GiaDictComponent,
    DictDesignerComponent,
    GiaDictLocalSignatoryRoleComponent,
    GiaLocalSignatoryComponent,
    DictDiplomaTypeComponent,
    GiaPageComponent,
    DictStateExamCommissionMemberRoleComponent,
    ReportListComponent,
    ConstructorGiaProtocolComponent,
    GiaProtocolHomeComponent,
    GiaProtocolTableComponent,
    StudentProtocolComponent,
    ...MFCComponents,
    AdmissionOrderHomeComponent,
    ReleaseOrderHomeComponent,
    AdmissionOrderTableComponent,
    ReleaseOrderTableComponent,
    AdmissionOrderFormComponent,
    ReleaseOrderFormComponent,
    AdmissionOrderAddStudentComponent,
    ReleaseOrderAddStudentComponent,
    OrderStudentSearchComponent,
    StudentDebtComponent,
    ...OfertaComponents,
    ...GiaComponents,
    ...DiplomablanksComponents,
    DictComponent,
    AfterValueChangedDirective,
  ],
  imports: [
    IMaskModule,
    BrowserModule,
    AppRoutingModule,
    DropDownsModule,
    BrowserAnimationsModule,
    GridModule,
    IndicatorsModule,
    PDFModule,
    HttpClientModule,
    FormsModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    MenuModule,
    DialogsModule,
    ReactiveFormsModule,
    DropDownListModule,
    NotificationModule,
    ExcelModule,
    LayoutModule,
    TypographyModule,
    ListViewModule,
    DateInputsModule,
    IntlModule,
    NavigationModule,
    IconsModule,
    FilterModule,
    ListBoxModule,
    ChartsModule,
    TreeViewModule,
    UploadsModule,
    CommonModule,
    PDFExportModule,
    PopupModule,
    PagerModule,
    TooltipsModule,
    EditorModule,
    RouterModule.forRoot(appRoutes),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenStorageService],
      },
    }),
    TreeListModule,
    DatePipe,
  ],
  providers: [
    AuthGuard,
    {
      deps: [HttpClient],
      useFactory: (jsonp: HttpClient) => () => new EditService(jsonp),
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: MessageService, useClass: LocalizationMessageService },
    { provide: HTTP_INTERCEPTORS, useClass: DialogComponent, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

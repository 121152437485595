import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BlanksAvailableModel,
        BlankModel,
        IssueBlanksModel,
        ReceiptModel,
        AddBlanksModel,
        reportModel} from '../../models/diplomablanks/blanks-available.model';

@Injectable({
    providedIn: 'root'
})

export class BlanksAvailableService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.blank}`;
  private baseHomeUrl = `${environment.apiEndpoint}${environment.apiPaths.diplomablanks.home}`;

  constructor(private http: HttpClient) {}

  public getAllBlank(data: string[] | [null]) {
    return this.http.post<BlanksAvailableModel[]>(this.baseUrl + '/getNotIssuedBlanks', data);
  }

  public addBlanks(data: AddBlanksModel) {
    return this.http.post(this.baseUrl, data);
  }

  public getTemplate(blankTypeId: string) {
    return this.http.get<[]>(this.baseHomeUrl + '/d/blanktemplate/GetByBlankType/' + blankTypeId);
  }
 
  public getSeries(blankTypeId: string, blankTemplateId: string) {
    let params = new HttpParams();
    params = params.append('dictBlankTemplateId', blankTemplateId);
    params = params.append('dictBlankTypeId', blankTypeId);
    return this.http.get<[]>(this.baseUrl + '/getSeries', {params});
  }

  public getBlanksNumbers(series: string, dictBlankTypeId: string, dictBlankTemplateId: string) {
    let params = new HttpParams();
    params = params.append('series', series);
    params = params.append('dictBlankTemplateId', dictBlankTemplateId);
    params = params.append('dictBlankTypeId', dictBlankTypeId);
    return this.http.get<BlankModel[]>(this.baseUrl + '/getBlanksBySeries', {params});
  }

  public issueBlanks(data: IssueBlanksModel) {
    return this.http.put(this.baseUrl + '/Issue', data);
  }

  public getReceipt(data: ReceiptModel) {
    return this.http.post(this.baseUrl + '/GetReceipt', data, {responseType: 'blob', observe: 'response'});
  }

  public deleteBlanks(data: number[]) {
    return this.http.post(this.baseUrl + '/DeleteBlanks', data);
  }

  public exportList(data: reportModel) {
    return this.http.post(this.baseUrl + '/exportNotIssuedBlanks', data, {responseType: 'blob', observe: 'response'});
  }
}
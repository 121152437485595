import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Citizenship} from '../../../models/mfc/dicts/citizenship.model';


@Injectable({
  providedIn: 'root'
})
export class CitizenshipService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.externalDict}/Citizenship/`;

  constructor(private http: HttpClient) { }

  public getCitizenships(): Observable<Citizenship[]> {
    return this.http.get<Citizenship[]>(this.baseUrl);
  }

  public getCitizenship(id: string): Observable<Citizenship> {
    return this.http.get<Citizenship>(`${this.baseUrl}${id}`);
  }
}

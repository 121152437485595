export enum DiplomaTypeEnum {
  Specialty = 1,
  ExcellentSpecialty,
  Master,
  ExcellentMaster,
  Graduate,
  Residency,
}

export const diplomaTypeEnumList: {text: string, value: DiplomaTypeEnum}[] = [
  {text: 'Специалиста', value: DiplomaTypeEnum.Specialty},
  {text: 'Специалиста с отличием', value: DiplomaTypeEnum.ExcellentSpecialty},
  {text: 'Магистра', value: DiplomaTypeEnum.Master},
  {text: 'Магистра с отличием', value: DiplomaTypeEnum.ExcellentMaster},
  {text: 'Об окончании аспирантуры', value: DiplomaTypeEnum.Graduate},
  {text: 'Об окончании ординатуры', value: DiplomaTypeEnum.Residency},
];

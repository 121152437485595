import {Injectable} from "@angular/core";
import {BehaviorSubject, catchError, lastValueFrom, Observable} from "rxjs";
import {environment, getData$} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {NotificationsService} from "../notifications/notifications.service";
import {handleError} from "../../helpers/errorHandle-helper";
import {CurrentUserAccess, UserAccess, UserAccessCreate} from "../../models/oferta/userAccess.model";

@Injectable({
  providedIn: 'root'
})
export class OfertaUserAccessService {

  public currentUserAccess$ = new BehaviorSubject<CurrentUserAccess|null>(null);

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.oferta.accessRight}`;

  constructor(private http: HttpClient,
              private notificationsService: NotificationsService,
              ) { }

  public getCurrentUserAccess(studentId?: string): Observable<CurrentUserAccess|null> {
    return this.http.get<CurrentUserAccess|null>(`${this.baseUrl}/GetCurrentUserAccess`)
      .pipe(catchError(handleError));
  }

  public getAccess(): Observable<UserAccess[]> {
    return this.http.get<UserAccess[]>(this.baseUrl)
      .pipe(catchError(handleError));
  }

  public createAccess(useraccess: UserAccessCreate) {
    return this.http.post<UserAccess>(this.baseUrl, useraccess)
      .pipe(catchError(handleError)).subscribe({
        next: () => {
          getData$.next(true);
          this.notificationsService.showSuccess("Добавлено");
        },
        error: () =>  {
          this.notificationsService.showError("Произошла ошибка")
        }
      });
  }

  public updateAccess(useraccess: UserAccess) {
    return this.http.put<UserAccess>(this.baseUrl, useraccess)
      .pipe(catchError(handleError)).subscribe({
        next: () => {
          getData$.next(true);
          if (useraccess.personId === this.currentUserAccess$.value?.userAccessRight.personId) {
            this.currentUserAccess$.next(new CurrentUserAccess());
          }
          this.notificationsService.showSuccess("Успешно");
        },
        error: () =>  {
          this.notificationsService.showError("Произошла ошибка")
        }
      });
  }

  public deleteAccess(id: string): Observable<UserAccess> {
    return this.http.delete<UserAccess>(this.baseUrl + `/${id}`)
      .pipe(catchError(handleError));
  }
}

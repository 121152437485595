import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Align, PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { Subscription } from 'rxjs';
import { DictWorkScheduleStudyProcessType } from 'src/app/models/education/dictWorkScheduleStudyProcessType.model';
import {
  CellDataType,
  CellDayType,
  ResponseScheduleProcess,
  ScheduleDate,
  TableDataType,
  TableHeaderType,
  WorkType,
} from 'src/app/models/education/education-plan-schedule.model';
import { DictWorkScheduleStudyProcessTypeService } from 'src/app/services/education/DictWorkScheduleStudyProcessType/dict-work-schedule-study-process-type.service';
import { EducationPlanDetailsService } from 'src/app/services/education/EducationPlan/education-plan-details.service';
import { EducationPlanScheduleService } from 'src/app/services/education/EducationPlan/education-plan-schedule.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { environment } from 'src/environments/environment';
import { EducationalProcessScheduleCellComponent } from '../educational-process-schedue-cell/educational-process-schedule-cell.component';
import {EducationUserAccessService} from "../../../services/useraccess/education-user-access.service";
import {ProcessScheduleService} from "../../../services/education/ProcessSchedule/process-schedule.service";
import {ProcessScheduleModels} from "../../../models/education/process-schedule.model";

interface Delta {
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  startWeek: number;
  endWeek: number;
  startCourse: number;
  endCourse: number;
}
@Component({
  selector: 'educational-process-schedule-table',
  templateUrl: './educational-process-schedule-table.component.html',
  styleUrls: ['./educational-process-schedule-table.component.scss'],
})
export class EducationalProcessScheduleTableComponent implements OnInit {
  @Input() educationPlanId: string = '';
  @Input() educationPlanStatus: number = 2;
  @Input() isLoading = false;
  @Output() toggleShowSettingsChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('popupTemplate', { read: TemplateRef })
  public popupTemplate?: TemplateRef<{ [key: string]: unknown }>;
  @ViewChild('cellFrame')
  public cellFrame?: ElementRef;

  @HostListener('document:click', ['$event'])
  public documentClick(event: MouseEvent): void {
    if (!this.contains(event.target)) {
      this.closePopUp();
    }
  }

  private contains(target: EventTarget | null): boolean {
    return this.popupRef && target
      ? this.popupRef.popupElement.contains(target as Node)
      : false;
  }

  public settings?: ProcessScheduleModels.Settings;
  public tableData: TableDataType[] = [];
  public tableHeaderData: TableHeaderType[] = [];

  public dictMonth: { [key: string]: string } = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  };

  public currentCell: EducationalProcessScheduleCellComponent | null = null;
  public bufferCell: CellDayType[] | null = null;
  public anchorAlign: Align = { horizontal: 'right', vertical: 'top' };
  public popupAlign: Align = { horizontal: 'left', vertical: 'top' };
  public anchorPopup: ElementRef | null = null;
  private popupRef: PopupRef | null = null;
  public dictScheduleStudyProcessTypes:
    | DictWorkScheduleStudyProcessType[]
    | never[] = [];
  public isDruggedCell = false;
  private delta: Delta = {
    startX: 0,
    endX: 0,
    startY: 0,
    endY: 0,
    startWeek: 0,
    endWeek: 0,
    startCourse: 0,
    endCourse: 0,
  };

  private offsetFrame = { x: 0, y: 0 };

  public isChoosedCells = false;
  public choosedCells: EducationalProcessScheduleCellComponent[] = [];
  public categoryCells: 'currentWeekNumber' | 'course' | '' = '';
  public isBlockedAction = false;
  private subscriptions = new Subscription();
  private emptyCell: DictWorkScheduleStudyProcessType | null = null;

  constructor(
    public processScheduleService: ProcessScheduleService,
    public educationPlanScheduleService: EducationPlanScheduleService,
    private popupService: PopupService,
    private workScheduleStudyProcessTypeService: DictWorkScheduleStudyProcessTypeService,
    public notificationService: NotificationsService,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private userAccessService:EducationUserAccessService
  ) { }

  ngOnInit(): void {
    this.getAccessLevel(this.educationPlanId);
    this.getSymbols();

    this.subscriptions.add(
      this.processScheduleService.settings$.subscribe(
        (response) => {
          this.settings = response;
        })
    )
    this.subscriptions.add(
      this.educationPlanScheduleService.processScheduleData$.subscribe(
        (response) => {
          this.isLoading = false;
          //this.settings = response.settings;
          this.getTableHeaderData(response);
          this.getTableData(response);
        })
    );
    this.isBlockedAction = this.educationPlanStatus !== 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['educationPlanStatus']) {
      this.isBlockedAction = this.educationPlanStatus !== 1;
    }
  }

  ngAfterViewInit() {
    const elementSchudeleTable = document.querySelector(
      'educational-process-schedule-table'
    );
    if (elementSchudeleTable)
      elementSchudeleTable.addEventListener(
        'mousedown',
        this.selectCells.bind(this),
        {
          capture: true,
        }
      );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public editable?: boolean;

  public getAccessLevel(educationPlanId:string):any{
    this.userAccessService.getPlanEditAccessLevel(educationPlanId)
      .subscribe(
        response => {
          this.editable = response.accessLevel;
        }
      );
  }
  public getSymbols() {
    this.subscriptions.add(
      this.workScheduleStudyProcessTypeService.allWorkScheduleStudyProcessTypes$.subscribe(
        (response) => {
          this.dictScheduleStudyProcessTypes = response.filter(
            (item) =>
              item.trainingLevelExternalId ===
              this.educationPlanDetailsService.currentTrainingLevelId
          );
          this.emptyCell =
            this.dictScheduleStudyProcessTypes.find(
              (dictType) => dictType.name === 'Пустая ячейка'
            ) || null;
        }
      )
    );
  }

  public handleClickSettings() {
    this.toggleShowSettingsChange.emit(true);
  }

  public getTableHeaderData(processScheduleData: ResponseScheduleProcess) {
    this.tableHeaderData = [];
    let templateHeaderData: TableHeaderType[] = [];

    processScheduleData.dates.forEach((date) => {
      const dateStart = new Date(date.start);
      const dateEnd = new Date(date.end);
      if (dateStart.getMonth() !== dateEnd.getMonth() && !(this.settings?.showDatesInGraph==false)) {
        templateHeaderData.push({
          days: `${dateStart.getDate()} - ${dateEnd.getDate()}`,
          title: date.title,
        });
      } else {
        if (
          templateHeaderData.at(-1)?.month !== undefined &&
          templateHeaderData.at(-1)?.month === dateStart.getMonth().toString()
        ) {
          templateHeaderData.at(-1)?.weeks?.push({
            days: `${dateStart.getDate()} - ${dateEnd.getDate()}`,
            title: date.title,
          });
        } else {
          templateHeaderData.push({
            month: dateStart.getMonth().toString(),
            weeks: [
              {
                days: `${dateStart.getDate()} - ${dateEnd.getDate()}`,
                title: date.title,
              },
            ],
          });
        }
      }
    });

    this.tableHeaderData = templateHeaderData;
  }

  public getTableData(processScheduleData: ResponseScheduleProcess) {
    let templateTableData: TableDataType[] = [];

    processScheduleData.courses.forEach((item) => {
      let semesterNumber = item.workTypes[0]?.semesterNumber
        ? item.workTypes[0].semesterNumber
        : 1;
      let isNextSemester = false;
      let nextSemesterIndex = 1;
      let semestrBeginWeekNumber = item.semesters.map((semester) =>
        semester.semesterBeginWeekNumber ? semester.semesterBeginWeekNumber : 0
      );
      this.educationPlanScheduleService.semestersByCourse[item.courseNumber] =
        JSON.parse(JSON.stringify(item.semesters));

      const weeksNumber = item.workTypes.reduce(
        (weeks: CellDataType[], type: WorkType) => {
          if (!type.serialNumberWeek) {
            if (
              isNextSemester &&
              semesterNumber !==
              item.semesters[nextSemesterIndex]?.semesterNumber &&
              (type.dictWorkScheduleStudyProcessType
                  .dictWorkCategoryScheduleId ===
                '55c9b980-e214-4e28-9d3d-7da90a625f05' ||
                type.dictWorkScheduleStudyProcessType
                  .dictWorkCategoryScheduleId ===
                '0c39ac39-3920-41bc-83ba-9f996976f6ba' ||
                type.dictWorkScheduleStudyProcessType
                  .dictWorkCategoryScheduleId ===
                '1c860063-aab3-4acd-a958-ec58d2551927' ||
                type.dictWorkScheduleStudyProcessType
                  .dictWorkCategoryScheduleId ===
                'e920e5a5-34fd-453b-9c36-185cb47e923b' ||
                type.dictWorkScheduleStudyProcessType
                  .dictWorkCategoryScheduleId ===
                'cc5e1966-5c83-412c-81d6-36f968d38ce8')
            ) {
              isNextSemester = false;

              if (item.semesters[nextSemesterIndex]?.semesterBegin === null) {
                semesterNumber =
                  item.semesters[nextSemesterIndex]?.semesterNumber;
                semestrBeginWeekNumber.push(type.weekNumber);
                this.educationPlanScheduleService.semestersByCourse[
                  item.courseNumber
                  ][nextSemesterIndex].semesterBeginWeekNumber = type.weekNumber;
              }

              if (nextSemesterIndex < item.semesters.length - 1)
                nextSemesterIndex++;
            }

            if (
              type.dictWorkScheduleStudyProcessType
                .dictWorkCategoryScheduleId ===
              'd36da979-6286-4a9f-8476-aedfe22cd374'
            ) {
              isNextSemester = true;
            }

            const currentSemesterNumber = type.semesterNumber ?? semesterNumber;
            const currentSemester = item.semesters.find((semester) =>
              semester.semesterNumber == currentSemesterNumber);

            const semesterBeginDayNumber = !currentSemester?.semesterBeginWeekNumber ? 0 : this.getWeekDayNumber(
              processScheduleData.dates[currentSemester?.semesterBeginWeekNumber - 1].start,
              currentSemester?.semesterBegin
            );

            const installationSessionBeginWeek = this.findWeek(currentSemester?.installationSessionBegin, processScheduleData.dates);
            const installationSessionEndWeek = this.findWeek(currentSemester?.installationSessionEnd, processScheduleData.dates);

            const isInstallationSession = item.semesters.some((x) => this.InstallationSession(x, processScheduleData.dates, type.weekNumber))

            const installationSessionBeginDayNumber = this.getWeekDayNumber(installationSessionBeginWeek?.start, currentSemester?.installationSessionBegin);
            const installationSessionEndDayNumber = this.getWeekDayNumber(installationSessionEndWeek?.start, currentSemester?.installationSessionEnd);

            weeks.push({
              semestrBeginWeekNumber,
              semesterBeginDayNumber,
              installationSessionBeginWeekNumber: installationSessionBeginWeek?.weekNumber,
              installationSessionEndWeekNumber: installationSessionEndWeek?.weekNumber,
              isInstallationSession: isInstallationSession,
              installationSessionBeginDayNumber,
              installationSessionEndDayNumber,
              weekNumber: type.weekNumber,
              semesterNumber: currentSemesterNumber,
              days: [
                {
                  daysAmount: type.daysAmount,
                  symbol: type.dictWorkScheduleStudyProcessType.symbol,
                  backgroundColor: type.dictWorkScheduleStudyProcessType.color,
                  dictWorkScheduleStudyProcessType:
                  type.dictWorkScheduleStudyProcessType,
                  semesterNumber: type.semesterNumber,
                },
              ],
            });
          } else {
            const currentWeek = weeks.find(
              (item) => item.weekNumber === type.weekNumber
            );
            if (currentWeek)
              currentWeek.days[type.serialNumberWeek] = {
                daysAmount: type.daysAmount,
                symbol: type.dictWorkScheduleStudyProcessType.symbol,
                backgroundColor: type.dictWorkScheduleStudyProcessType.color,
                dictWorkScheduleStudyProcessType:
                type.dictWorkScheduleStudyProcessType,
                semesterNumber: type.semesterNumber,
              };
          }
          return weeks;
        },
        []
      );
      if (weeksNumber.length===0) return;
      let offset = weeksNumber[0].weekNumber - 1;

      while (offset && this.emptyCell) {
        weeksNumber.unshift({
          weekNumber: offset,
          semesterNumber: 0,
          semesterBeginDayNumber: 0,
          installationSessionBeginDayNumber: 0,
          installationSessionEndDayNumber: 0,
          days: [
            {
              daysAmount: 6,
              semesterNumber: 0,
              backgroundColor: this.emptyCell.color,
              dictWorkScheduleStudyProcessType: {
                ...this.emptyCell,
                id: this.emptyCell.dictWorkScheduleStudyProcessTypeExternalId,
                dictWorkCategoryScheduleId:
                this.emptyCell.dictWorkCategoryScheduleExternalId,
              },
              symbol: this.emptyCell.symbol,
            },
          ],
          isBlockedAction: true,
        });
        offset--;
      }
      templateTableData.push({ courseNumber: item.courseNumber, weeksNumber });
    });
    this.tableData = templateTableData;
    this.educationPlanScheduleService.setCoursesHandleData(templateTableData);
  }

  public InstallationSession = (semester: any, dates: any, weekNumber: any) => {
    const semesterBegin = this.findWeek(semester?.installationSessionBegin, dates)
    const semesterEnd = this.findWeek(semester?.installationSessionEnd, dates)
    return (semesterBegin?.weekNumber && semesterEnd?.weekNumber
      ? weekNumber >= semesterBegin?.weekNumber
      && weekNumber <= semesterEnd?.weekNumber : false);
  }

  modificationSchedule(){
    this.educationPlanDetailsService.modification.next({
      value: true
    });
  }

  @HostListener('keydown.Control.c')
  public copyCell() {
    if (!this.isBlockedAction && !this.currentCell?.weekInfo.isBlockedAction) {
      this.bufferCell = this.currentCell?.weekInfo.days || null;
      this.closePopUp();
    }
  }

  @HostListener('keydown.Control.v')
  public pasteCell() {
    if (
      this.bufferCell &&
      this.currentCell &&
      !this.isBlockedAction &&
      !this.currentCell.weekInfo.isBlockedAction
    ) {
      this.educationPlanScheduleService.changeWorkTypes(
        this.bufferCell,
        this.currentCell.course,
        this.currentCell.weekInfo.weekNumber
      );
      this.modificationSchedule();
      this.closePopUp();
    }
  }

  public separateCell() {
    if (this.currentCell) {
      if (this.currentCell.isWholeWeek)
        this.educationPlanScheduleService.changeWorkTypes(
          this.currentCell.days,
          this.currentCell.course,
          this.currentCell.weekInfo.weekNumber
        );
      else
        this.educationPlanScheduleService.changeWorkTypes(
          [
            {
              ...this.currentCell.days[0],
              daysAmount: environment.daysInWeek,
            },
          ],
          this.currentCell.course,
          this.currentCell.weekInfo.weekNumber
        );

      this.currentCell.isWholeWeek = !this.currentCell.isWholeWeek;

      this.modificationSchedule();
      this.closePopUp();
    }
  }

  public openPopup({
                     anchorPopup,
                     cell,
                   }: {
    anchorPopup: ElementRef | null;
    cell: EducationalProcessScheduleCellComponent;
  }) {
    if(!this.editable)return
    this.closePopUp();
    if (!this.isBlockedAction && !cell.weekInfo.isBlockedAction) {
      this.anchorPopup = anchorPopup;
      this.selectCell(cell);
      if (this.anchorPopup)
        this.popupRef = this.popupService.open({
          anchor: this.anchorPopup,
          content: this.popupTemplate,
        });
    }
  }

  public closePopUp() {
    if (this.popupRef) {
      if (this.currentCell)  this.currentCell.isSelectedCell = false;
      this.popupRef.close();
      this.popupRef = null;
    }
  }

  private handleOneCell(key: string) {
    if (!this.currentCell?.weekInfo.isBlockedAction) {

      if (key === 'Delete') this.clearCell();

      const currentSymbol = this.dictScheduleStudyProcessTypes.find(
        (dictType) => dictType.symbol === key
      );
      currentSymbol && this.applyDictSymbol(currentSymbol);
    }
  }

  @HostListener('keydown', ['$event'])
  public enterSymbol(event: KeyboardEvent) {
    event.preventDefault();
    if (!this.isBlockedAction) {
      if (this.choosedCells.length) {
        this.choosedCells.forEach((cell) => {
          this.currentCell = cell;
          this.handleOneCell(event.key);
        });
        this.currentCell = null;
        this.choosedCells = [];
      } else this.handleOneCell(event.key);
    }
  }

  public clearCell() {
    if (this.currentCell) {
      this.emptyCell && this.applyDictSymbol(this.emptyCell);
    }
  }

  public applyDictSymbol(dictData: DictWorkScheduleStudyProcessType) {
    if (this.currentCell) {
      let workTypes: CellDayType[] = [];

      if (this.currentCell.isWholeWeek) {
        workTypes = this.currentCell.weekInfo.days;
        workTypes[0].dictWorkScheduleStudyProcessType = {
          id: dictData.dictWorkScheduleStudyProcessTypeExternalId,
          symbol: dictData.symbol,
          color: dictData.color,
          isInvolvedCalculating: dictData.isInvolvedCalculating,
          dictWorkCategoryScheduleId:
          dictData.dictWorkCategoryScheduleExternalId,
        };
      } else {
        this.currentCell.days[this.currentCell.currentDay] = {
          daysAmount: 1,
          dictWorkScheduleStudyProcessType: {
            id: dictData.dictWorkScheduleStudyProcessTypeExternalId,
            symbol: dictData.symbol,
            color: dictData.color,
            isInvolvedCalculating: dictData.isInvolvedCalculating,
            dictWorkCategoryScheduleId:
            dictData.dictWorkCategoryScheduleExternalId,
          },
          symbol: dictData.symbol,
          backgroundColor: dictData.color,
          semesterNumber: 0,
        };

        workTypes = this.currentCell.days;
        //workTypes = [this.currentCell.days[0]];

        let previouseValueId =
          this.currentCell.days[0].dictWorkScheduleStudyProcessType?.id;
        let daysAmount = 0;
        let count = 0;
        /*  for (const item of this.currentCell.days) {
            count++;
            if (item.dictWorkScheduleStudyProcessType?.id === previouseValueId) {
              daysAmount++;
              if (count === this.currentCell.days.length) workTypes.at(-1)!.daysAmount = daysAmount;
              continue;
            }
            if (workTypes.length) { workTypes.at(-1)!.daysAmount = daysAmount;}
            previouseValueId = item.dictWorkScheduleStudyProcessType?.id;
            workTypes.push(item);
            daysAmount = 1;
          }*/
      }

      this.educationPlanScheduleService.changeWorkTypes(
        workTypes,
        this.currentCell.course,
        this.currentCell.weekInfo.weekNumber
      );

      this.modificationSchedule();
      this.closePopUp();
    }
  }

  public saveTable() {
    this.educationPlanScheduleService
      .postProcessSchedule(this.educationPlanId)
      .subscribe((response) =>{
        this.educationPlanDetailsService.modification.next({
          value: false
        });

        this.notificationService.showSuccess(
          'График учебного процесса успешно обновлен'
        )
      });
  }

  public selectCell(cell: EducationalProcessScheduleCellComponent) {
    if(!this.editable)return
    if (this.currentCell) this.currentCell.isSelectedCell = false;

    if (this.isChoosedCells && !this.isDruggedCell) {
      if (
        this.choosedCells.length < 2 ||
        (this.categoryCells &&
          this.choosedCells[0][this.categoryCells] === cell[this.categoryCells])
      ) {
        this.choosedCells.push(cell);
        cell.isSelectedCell = true;

        this.choosedCells.sort((prev, next) => {
          if (this.categoryCells === 'course')
            return prev.currentWeekNumber - next.currentWeekNumber;
          else return prev.course - next.course;
        });
        this.choosedCells.forEach((item, index) => {
          item.isDisplayDot = false;
          if (index + 1 === this.choosedCells.length) item.isDisplayDot = true;
        });
      }

      if (this.choosedCells.length === 2) {
        if (
          this.choosedCells[0].currentWeekNumber ===
          this.choosedCells[1].currentWeekNumber
        )
          this.categoryCells = 'currentWeekNumber';

        if (this.choosedCells[0].course === this.choosedCells[1].course)
          this.categoryCells = 'course';
      }
    } else {
      this.resetChoosedCells();
      this.currentCell = cell;
      this.currentCell.isSelectedCell = true;
    }
  }

  public resetChoosedCells() {
    if (this.choosedCells.length) {
      this.choosedCells.forEach((cell) => (cell.isSelectedCell = false));
      this.choosedCells = [];
      this.categoryCells = '';
    }
  }

  @HostListener('mousemove', ['$event'])
  public moveCell(event: MouseEvent) {
    if (this.isDruggedCell) {
      this.delta.endX = event.pageX;
      this.delta.endY = event.pageY;
      const deltaX = this.delta.endX - this.delta.startX;
      const deltaY = this.delta.endY - this.delta.startY;

      const widthFrame =
        39 *
        (this.categoryCells === 'course' && this.choosedCells.length
          ? this.choosedCells.length
          : 1);
      const heightFrame =
        120 *
        (this.categoryCells === 'currentWeekNumber' && this.choosedCells.length
          ? this.choosedCells.length
          : 1);

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        const offsetX = deltaX < 0 - widthFrame ? deltaX + widthFrame : 0;
        this.cellFrame?.nativeElement.setAttribute(
          'style',
          'transform:' +
          'translate(' +
          (this.offsetFrame.x - widthFrame + offsetX + 39) +
          'px, ' +
          (this.offsetFrame.y - heightFrame + 119) +
          'px); visibility: visible; width:' +
          (widthFrame + Math.abs(deltaX > 0 ? deltaX : offsetX)) +
          'px; height:' +
          heightFrame +
          'px'
        );
      } else {
        const offsetY = deltaY < 0 - heightFrame ? deltaY + heightFrame : 0;
        this.cellFrame?.nativeElement.setAttribute(
          'style',
          'transform:' +
          'translate(' +
          (this.offsetFrame.x - widthFrame + 39) +
          'px, ' +
          (this.offsetFrame.y + offsetY - heightFrame + 119) +
          'px); visibility: visible; width:' +
          widthFrame +
          'px; height:' +
          (heightFrame + Math.abs(deltaY > 0 ? deltaY : offsetY)) +
          'px'
        );
      }
    }
  }

  public getStartPosition(offset: number, propertyStart: keyof Delta) {
    if (offset < 0) {
      return this.delta[propertyStart];
    }

    if (propertyStart === 'startCourse' && this.categoryCells === 'course')
      return this.delta[propertyStart];

    if (
      propertyStart === 'startWeek' &&
      this.categoryCells === 'currentWeekNumber'
    )
      return this.delta[propertyStart];

    return this.delta[propertyStart] - this.choosedCells.length + 1;
  }

  @HostListener('mouseup')
  draggedCellDisable() {
    this.isChoosedCells = false;

    if (this.isDruggedCell) {
      this.isDruggedCell = false;

      this.cellFrame?.nativeElement.setAttribute(
        'style',
        'transform: translate(0,0)'
      );
      const deltaX = this.delta.startX - this.delta.endX;
      const deltaY = this.delta.startY - this.delta.endY;
      let step = 0;
      let propertyEnd: 'endCourse' | 'endWeek' | '' = '';
      let propertyStart: 'startCourse' | 'startWeek' | '' = '';
      let count;

      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        count = Math.floor(deltaX / 40);
        this.delta.endWeek -= count;
        propertyEnd = 'endWeek';
        propertyStart = 'startWeek';
      } else {
        count = Math.floor(deltaY / 120);
        this.delta.endCourse -= count;
        propertyEnd = 'endCourse';
        propertyStart = 'startCourse';
      }
      step = count / Math.abs(count);

      let startCell =
        count > 0
          ? 0
          : Math.abs(count) % this.choosedCells.length
            ? (Math.abs(count) % this.choosedCells.length) - 1
            : this.choosedCells.length - 1;

      for (
        let i = this.getStartPosition(count, propertyStart);
        i !== this.delta[propertyEnd];

      ) {
        if (this.choosedCells.length) {
          if (
            this.categoryCells === 'currentWeekNumber' &&
            propertyEnd === 'endWeek'
          ) {
            this.choosedCells.forEach(
              (cell) =>
                !cell.weekInfo.isBlockedAction &&
                this.educationPlanScheduleService.changeWorkTypes(
                  cell.weekInfo.days,
                  cell.course,
                  this.delta.endWeek
                )
            );
          }

          if (this.categoryCells === 'course' && propertyEnd === 'endCourse') {
            this.choosedCells.forEach(
              (cell) =>
                !cell.weekInfo.isBlockedAction &&
                this.educationPlanScheduleService.changeWorkTypes(
                  cell.weekInfo.days,
                  this.delta.endCourse,
                  cell.currentWeekNumber
                )
            );
          }

          if (
            (this.categoryCells === 'currentWeekNumber' &&
              propertyEnd === 'endCourse') ||
            (this.categoryCells === 'course' && propertyEnd === 'endWeek')
          )
            !this.choosedCells[startCell].weekInfo.isBlockedAction &&
            this.educationPlanScheduleService.changeWorkTypes(
              this.choosedCells[startCell].weekInfo.days,
              this.delta.endCourse,
              this.delta.endWeek
            );
        } else
          this.currentCell &&
          !this.currentCell.weekInfo.isBlockedAction &&
          this.educationPlanScheduleService.changeWorkTypes(
            this.currentCell.weekInfo.days,
            this.delta.endCourse,
            this.delta.endWeek
          );

        this.delta[propertyEnd] += step;
        startCell += step;

        if (startCell < 0 || startCell == this.choosedCells.length) {
          startCell = step > 0 ? 0 : this.choosedCells.length - 1;
        }
      }

      this.resetChoosedCells();
    }
  }

  public draggedCellActive(cell: {
    x: number;
    y: number;
    course: number;
    currentWeekNumber: number;
  }) {
    if(!this.editable)return
    this.isDruggedCell = true;
    this.delta.startX = cell.x;
    this.delta.startY = cell.y;
    this.delta.startWeek = this.delta.endWeek = cell.currentWeekNumber;
    this.delta.startCourse = this.delta.endCourse = cell.course;

    if (this.cellFrame) {
      const { top, left } =
        this.cellFrame?.nativeElement.getBoundingClientRect();

      this.offsetFrame = {
        x: cell.x - left - 38,
        y: cell.y - top - window.scrollY - 118,
      };
      this.cellFrame.nativeElement.setAttribute(
        'style',
        'transform: translate(' +
        this.offsetFrame.x +
        'px, ' +
        this.offsetFrame.y +
        'px); visibility: visible;'
      );
    }
  }

  public selectCells(event: any) {
    const isDot = event.target?.classList.contains('dot');
    if (!this.isDruggedCell && !this.isBlockedAction && !isDot) {
      this.isChoosedCells = true;
    }
  }

  private findWeek(date: string | null | undefined, weeks: ScheduleDate[]) {
    return !date ? null :
      weeks.find(
        (week) => new Date(week.start) <= new Date(date)
          && new Date(week.end) >= new Date(date));
  }

  private getWeekDayNumber(startWeekDate: string | null | undefined, date: string | null | undefined) {
    if (startWeekDate && date) {
      const firstDayNumber = new Date(startWeekDate).getDay();
      let secondDayNumber = new Date(date).getDay();

      secondDayNumber += secondDayNumber < firstDayNumber ? 7 : 0;
      return secondDayNumber - firstDayNumber;
    }

    return 0;
  }
}

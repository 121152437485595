<workload-settings></workload-settings>

<h1>Контингент для расчета нагрузки</h1>
<span class="workloadDropdownContainer">
    <h3>Учебный год</h3>
    <kendo-dropdownlist
            (valueChange)="valueChange($event, 'studyYearsEdit')"
            [data]="yearAndSemestrs"
            [(ngModel)]="studyYearsEdit"
            [valuePrimitive]="false"
            valueField="name"
            textField="name"
            class="workloadDropdown">
    </kendo-dropdownlist>
</span>
<span class="workloadDropdownContainer">
    <h3>Институт/факультет</h3>
    <kendo-multiselect class="workloadDropdown" textField="sName" valueField="id"
                       [data]="faculties"
                       (valueChange)="valueChange($event, 'facultiesEdit')"
                       [(ngModel)]="facultiesEdit"
                       [kendoDropDownFilter]="filterSettings"
                       [valuePrimitive]="true">
    </kendo-multiselect>
</span>
<span class="workloadDropdownContainer">
    <h3>Уровень подготовки</h3>
    <kendo-multiselect class="workloadDropdown" textField="name" valueField="id"
                       (valueChange)="valueChange($event, 'trainingLevelsEdit')"
                       [data]="trainingLevels"
                       [(ngModel)]="trainingLevelsEdit"
                       [kendoDropDownFilter]="filterSettings"
                       [valuePrimitive]="true">
    </kendo-multiselect>
</span>
<span [ngClass]="{readOnly: !editingRights}" class="workloadDropdownContainer">
    <h3>Форма обучения</h3>
    <kendo-multiselect class="workloadDropdown" textField="studyFormName" valueField="id"
                       [kendoDropDownFilter]="filterSettings"
                       (valueChange)="valueChange($event, 'studyFormEdit')"
                       [(ngModel)]="studyFormEdit"
                       [valuePrimitive]="true"
                       [data]="studyForms">
    </kendo-multiselect>
</span>
<span class="workloadDropdownContainer footer-buttons" *ngIf="editingRights">
  <button  kendoButton class="button" icon="reload"
           [disabled]="!studyYearsEdit"
           (click)="updateContingentLoad()"
  >Обновить студентов и подгруппы</button>
  <button kendoButton class="button" icon="reload"
          [disabled]="!studyYearsEdit"
          (click)="updateContingentLoad(true)"
  >Обновить только студентов</button>
</span>

<kendo-grid class="workloadGrid workload-contingent"
            [ngClass]="{noData: gridData.length < 7}"
            [height]="600"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            [kendoGridBinding]="gridData"
            [loading]="loading"
            [sortable]="true"
            [pageable]="{
              buttonCount: buttonCount,
              info: info,
              type: type,
              pageSizes: pageSizes,
              previousNext: previousNext,
              position: position
            }"
            [filterable]="true"
            [group]="groups"
            [groupable]="true"
            [pageSize]="pageSizes[2]"
            [rowClass]="isRowArchived">
            (add)="addHandler()">
    <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    <kendo-grid-column headerClass="gridHeader" field="eduGroup.courseNum" title="Курс"
                       [width]="70">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
               {{dataItem.eduGroup.courseNum}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="eduGroup.name" title="Группа"
                       [width]="115">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell operator="contains"
                                           [column]="column"
                                           [filter]="filter"
                                           [showOperators]="false">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{dataItem.eduGroup.name}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="eduGroup.actualTotalStudents" title="Обуч. факт"
                       [filterable]="false"
                       [width]="90">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{dataItem.eduGroup.actualTotalStudents}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="eduGroup.expectedTotalStudents" title="Обуч. в поруч."
                       [filterable]="false"
                       [width]="90">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{dataItem.eduGroup.expectedTotalStudents}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
            <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false" [(ngModel)]="dataItem.eduGroup.expectedTotalStudents"></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="eduGroup.expectedTotalStudentSubgroups" title="П/гр"
                       [filterable]="false" [width]="70">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{dataItem.eduGroup.expectedTotalStudentSubgroups}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
            <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false" [(ngModel)]="dataItem.eduGroup.expectedTotalStudentSubgroups"></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="dictTrainingLevel.name" title="Уровень подготовки"
                       [width]="180">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [filter]="filter" gridField="dictTrainingLevel.name"  [data]="filterTrainingLevels" textField="name"
                             valueField="name">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.dictTrainingLevel.name}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="dictStudyForm.studyFormName" title="Форма обучения"
                       [width]="180">
        <ng-template kendoGridFilterCellTemplate let-filter>
            <filter-dropdown [filter]="filter" gridField="dictStudyForm.studyFormName"  [data]="filterStudyForms" textField="studyFormName"
                             valueField="studyFormName">
            </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.dictStudyForm.studyFormName}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="educationPlan.name" title="Учебный план группы"
                       [width]="350">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                <span (click)="hyperlinkClick(dataItem)" class="hyperlink">{{dataItem.educationPlan.name}}
                    ({{dataItem.educationPlan.statusName}})
                </span>
            </span>
            <span class="alignCells leftAlignment">
                <span>Изменено: {{dataItem.educationPlan.lastUpdatedAt | date: 'dd.MM.yyyy HH:mm':'GMT'}} </span>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="educationStandard.name" title="Направление"
                       [width]="350">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.educationStandard.name}}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader" field="educationProgram.name" title="Направленность (профиль)"
                       [width]="350">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
                <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.educationProgram.name}}
            </span>
        </ng-template>
        <ng-template kendoGridGroupHeaderTemplate let-group="group" let-rowIndex="rowIndex">
            <span class="alignCells bold leftAlignment">
              {{ group.value }}
            </span>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
<kendo-dialog title="Пожалуйста, подтвердите" *ngIf="updateContingentLoadState.update"
              [minWidth]="250" [width]="450"
              (close)="updateContingentLoadClose()">
  <p style="margin: 30px; text-align: center;">
    Вы уверены что хотите заполнить обучающихся в нагрузку из контингента?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="updateContingentLoadClose()">Нет</button>
    <button kendoButton (click)="updateRangeContingentLoad()" [primary]="true">Да</button>
  </kendo-dialog-actions>
</kendo-dialog>
<kendo-loader [type]="loaderType" *ngIf="updateLoading" class="dialogLoader"></kendo-loader>

export class CompetencePayload {
    public payload!: Competence;
    public total!: number;
    public pageNumber!: number;
    public pageSize!: number;
}

export class Competence {
    public directionTraining!: string;
    public educationProgram!: string;
    public competencies!: CompetenceList[]
    public typeCompetences!: TypeCompetenceList[]
}

export interface CompetenceList {
    competenceId: number;
    externalId: string;
    code: string;
    description: string;
    typeCompetenceId: string;
}

export interface TypeCompetenceList {
    competenceId: number;
    competenceExternalId: string;
    name: string;
    competence: CompetenceList[]
}
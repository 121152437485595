<div>
    <div  *ngIf="VisibleAdvancedSearch" class="search-data">
        <app-search-students (buttonClick)="advanced_search()" > </app-search-students>
    </div>
    

    <!--Расширенный поиск-->
    <div class="expand-search-data" *ngIf="!VisibleAdvancedSearch">
        <app-expand-students (buttonClick)="advanced_search()"> </app-expand-students>
    </div>

    <!--Найденные студенты-->
    
        <app-found-students> </app-found-students>
        

    <!--Выбранные студенты-->    
        
        <app-selected-students> </app-selected-students>
    
</div>
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {TokenStorageService} from "../services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {DisciplineWorkloadUserAccessService} from "../services/useraccess/disciplineworkload-user-access.service";
import {Role} from "../models/useraccess/role";
import { PersonService } from '../services/disciplineworkload/externals/person.service';
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})

export class DisciplineWorkloadUserAccessGuard implements CanActivate {
  constructor(
      private tokenStore: TokenStorageService,
      private jwtHelper: JwtHelperService,
      private userAccessService: DisciplineWorkloadUserAccessService,
      private router: Router,
      private location: Location,
      private personService: PersonService
  ) {}

    async canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Promise<boolean | UrlTree> {

      const token = this.tokenStore.getAuthToken();
      const path = route.routeConfig?.path;

      //console.log('discipline work load guard', 'path', path);

      // Checking if token is not null
      if (token == null) {
        this.forbidden();
        return false;
      }

      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;

      //console.log('discipline work load guard', 'roles', roles);

      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin)) return true;

      let hasPps: boolean = false;

      await this.personService.hasPps().toPromise().then(value => {
        hasPps = value == true;
      })

      //console.log('discipline work load guard', 'hasPps', hasPps);

      if (path === 'disciplineworkload/teacherworkload' && hasPps)
        return true;

      // Check if user has discipline workload role
      if (roles?.includes(Role.Disciplineworkload)) {
        let accesses: any[] = [];

         await this.userAccessService.getAccess().toPromise().then(value => {
           if (value) {
             accesses = value.userAccesses.filter((item: { personId: any; }) =>
               item.personId === user.person_id);
           }
         });

        let checkAccess = (property: string) => {
          let access = accesses?.length > 0
            ? Math.max(...accesses.map((_: any) =>
              typeof _[property] === 'boolean'
                ? (_[property] ? 1 : 0)
                : _[property]))
            : 0;

          console.log('check access', property, access);

          return access;
        }

        //console.log('discipline work load guard', 'accesses', accesses);

         switch (path) {
           case 'disciplineworkload/contingentworkload':
             if (checkAccess("contingentLoadAccess") > 0) return true;

             alert("У Вас нет доступа к контингенту для расчета нагрузки");
             break;
           case 'disciplineworkload/assignments':
             if (checkAccess("errandsAccess") > 0) return true;

             alert("У Вас нет доступа к поручениям");

             break;
           case 'disciplineworkload/departmentworkload':
             if (checkAccess("subdepartmentLoadAccess") > 0) return true;

             alert("У Вас нет доступа к нагрузке кафедры");

             break;
           case 'disciplineworkload/teacherworkload':
             if (checkAccess("teacherLoadAccess") > 0) return true;

             alert("У Вас нет доступа к нагрузке преподавателя");
             break;
           case 'disciplineworkload/reportsWorkload':
             if (checkAccess("reportsAccess") > 0) return true;

             alert("У Вас нет доступа к отчетам");
             break;
           case 'disciplineworkload/useraccess':
             if (checkAccess("isModerator") > 0) return true;

             alert("У Вас нет доступа к настройкам прав доступа");
             break;
           case 'disciplineworkload/settings':
             if (checkAccess("isModerator") > 0) return true;

             alert("У Вас нет доступа к настройкам");
             break;
           case 'disciplineworkload/assignmentsMonitoring':
             if (checkAccess("assignmentMonitoringAccess") > 0) return true;

             alert("У Вас нет доступа к мониторингу");
             break;
           case 'disciplineworkload/dictTeachers':
             if (checkAccess("subdepartmentLoadAccess") > 0) return true;

             alert("У Вас нет доступа к ППС");
             break;
         }
      }

      this.forbidden();

      return false;
    }

    private forbidden(): void {
      //alert('Недостаточно прав для доступа на эту страницу');
      localStorage.setItem('last_url', this.location.path())
      this.router.navigate([`/profile`]);
    }
}

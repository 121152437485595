import { Component, OnInit } from '@angular/core';
import {saveAs} from "@progress/kendo-file-saver";
import {GetRegulationFile} from "../../../models/oferta/regulationFile/getRegulationFile.model";
import {RegulationFileService} from "../../../services/oferta/regulationFile.service";
import {UserSignatureService} from "../../../services/oferta/user-signature.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {GetPersonRegulationQuery} from "../../../models/oferta/usersignature/query/getPersonRegulationQuery";
import {SignatureTypeEnum} from "../../../models/oferta/enums/dictSignatureType.enum";
import {CreateQuery} from "../../../helpers/createQuery-helper";
import {Location} from "@angular/common";
import {Router} from "@angular/router";
import {UserSignatureInfo} from "../../../models/oferta/usersignature/userSignatureInfo.model";

@Component({
  selector: 'reinforced-module-notification',
  templateUrl: './reinforced-module-notification.component.html',
  styleUrls: ['./reinforced-module-notification.component.css']
})
export class ReinforcedModuleNotificationComponent implements OnInit {

  public userSignatureInfo!: UserSignatureInfo;
  private getPersonRainforcedRegulationQuery?: GetPersonRegulationQuery;

  constructor(private regulationFileService: RegulationFileService,
              private userSignatureService: UserSignatureService,
              private notificationsService: NotificationsService,
              private location: Location,
              private router: Router) { }

  private getPersonRainforcedRegulation(){

    this.getPersonRainforcedRegulationQuery = {
      UserSignatureTypeEnum: SignatureTypeEnum.Reinforced
    }

    const query = CreateQuery(this.getPersonRainforcedRegulationQuery);

    this.userSignatureService.getPersonRegulation(query)
      .subscribe(
        response => {
          this.userSignatureInfo = response;
        }
      );
  }

  private getFileObserver(fileName?: string) {
    return {
      next: (response: Blob) => fileName && saveAs(response, fileName),
      error: (error: unknown) => this.notificationsService.showError(error)
    };
  }

  public Close = () => {
    localStorage.setItem('last_url', this.location.path());
    this.router.navigate([`/profile`]).then();
  }

  public getRegulationFile(file?: GetRegulationFile) {
    file && this.regulationFileService.getRegulationFile(file.id)
      .subscribe(this.getFileObserver(file.fileName));
  }

  ngOnInit(): void {
    this.getPersonRainforcedRegulation();
  }

}

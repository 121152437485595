enum ControlActionEnum {
    test = 2
}
  
export const ControlAction: any = ControlActionEnum;

enum markEnum {
    isTest = 0,
    failed = 6,
    notAllowed = -3
}

export const mark: any = markEnum

export const isGoodMarkValue = [3, 4, 5, 6];
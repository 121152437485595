import {Component, OnInit} from '@angular/core';
import {
  CellClickEvent,
  ColumnComponent,
  GridDataResult,
  PageChangeEvent,
  PagerSettings,
  RowClassArgs
} from '@progress/kendo-angular-grid';
import {ApplicationConstructorService} from '../../../../services/mfc/application-constructor.service';
import {
  ApplicationConstructor,
  ApplicationConstructorList,
  ConstructorFilters
} from '../../../../models/mfc/applicationConstructor/application-constructor.model';
import {GroupDescriptor, process, SortDescriptor, State} from '@progress/kendo-data-query';
import {pagerSettings} from '../../../../models/mfc/pagerSettings.model';
import {ApplicationCategoryService} from '../../../../services/mfc/dicts/application-category.service';
import {ApplicationTypeService} from '../../../../services/mfc/dicts/application-type.service';
import {FilialService} from '../../../../services/mfc/dicts/filial.service';
import {TrainingLevelService} from '../../../../services/mfc/dicts/training-level.service';
import {DepartmentService} from '../../../../services/mfc/dicts/department.service';
import {ApplicationCategory} from '../../../../models/mfc/dicts/application-category.model';
import {ApplicationType} from '../../../../models/mfc/dicts/application-type.model';
import {TrainingLevel} from '../../../../models/mfc/dicts/training-level.model';
import {
  ApplicationConstructorStatusesEnum,
  NumericApplicationConstructorStatusesEnum
} from '../../../../models/mfc/enums/application-constructor-statuses.enum';
import {
  ApplicationConstructorStatus,
  constructorStatuses
} from '../../../../models/mfc/applicationConstructor/application-constructor-statuses.model';
import {Router} from '@angular/router';
import {CitizenshipService} from '../../../../services/mfc/dicts/citizenship.service';
import {Citizenship} from '../../../../models/mfc/dicts/citizenship.model';
import {StudyFormService} from '../../../../services/mfc/dicts/study-form.service';
import {StudyForm} from '../../../../models/mfc/dicts/study-form.model';
import {FacultyService} from "../../../../services/mfc/dicts/faculty.service";
import {FilialTreeItem} from "../../../../models/mfc/dicts/filial.model";
import {Dict} from '../../../../models/mfc/dict.model';
import {
  ApplicationConstructorFiltersEnum,
  applicationConstructorFiltersMap
} from '../../../../models/mfc/enums/application-constructor-filters.enum';


@Component({
  selector: 'mfc-application-constructor',
  templateUrl: './application-constructor.component.html',
  styleUrls: ['./application-constructor.component.scss']
})
export class ApplicationConstructorComponent implements OnInit {

  protected loading = false;

  protected pagerSettings: PagerSettings = pagerSettings;
  protected skip = 0;
  protected pageSize = 10;
  protected groups: GroupDescriptor[] = [{field: 'category.name'}];
  protected sort: SortDescriptor[] = [];
  protected state: State = {
    skip: 0,
    take: this.pageSize,
    group: this.groups
  };

  protected dictApplicationCategories: ApplicationCategory[] = [];
  protected dictApplicationTypes: ApplicationType[] = [];
  protected dictTrainingLevels: TrainingLevel[] = [];
  protected dictStudyForms: StudyForm[] = [];
  protected dictCitizenShips: Citizenship[] = [];
  protected filialTree: FilialTreeItem[] = [];

  protected applicationConstructors: ApplicationConstructorList[] = [];
  protected gridData: ApplicationConstructorList[] = [];
  protected gridView!: GridDataResult;
  protected filters: ConstructorFilters = {
    status: [ApplicationConstructorStatusesEnum.Published],
    type: [],
    trainingLevels: [],
    faculties: [],
    studyForms: [],
    citizenShips: []
  };
  protected nameFilter = '';

  protected readonly constructorStatuses = constructorStatuses;

  constructor(
    private router: Router,
    private applicationCategoryService: ApplicationCategoryService,
    private applicationTypeService: ApplicationTypeService,
    private filialService: FilialService,
    private trainingLevelService: TrainingLevelService,
    private departmentService: DepartmentService,
    private citizenshipService: CitizenshipService,
    private studyFormService: StudyFormService,
    private applicationConstructorService: ApplicationConstructorService,
    private facultyService: FacultyService
  ) { }

  ngOnInit(): void {
    this.getDicts();
    this.getApplicationConstructorData();
  }

  private getDicts() {
    this.applicationCategoryService.getApplicationCategories().subscribe((response) =>
      this.dictApplicationCategories = response ?? []);
    this.applicationTypeService.getApplicationTypes().subscribe((response) =>
      this.dictApplicationTypes = response ?? []);
    this.trainingLevelService.getTrainingLevels().subscribe((response) => {
      this.dictTrainingLevels = response ?? [];
      this.addAllValue(this.dictTrainingLevels);
    });
    this.citizenshipService.getCitizenships().subscribe((response) => {
      this.dictCitizenShips = response ?? [];
      this.addAllValue(this.dictCitizenShips);
    });
    this.studyFormService.getStudyForms().subscribe((response) => {
      this.dictStudyForms = response ?? [];
      this.addAllValue(this.dictStudyForms);
    });
    this.facultyService.getFilialsAndFaculties().subscribe((response) => {
      this.filialTree = response ?? [];
      this.addAllValue(this.filialTree);
    });
  }

  private addAllValue(dict: Dict[]) {
    dict.unshift({id: '', name: 'Все'});
  }

  private getApplicationConstructorData() {
    this.loading = true;
    this.applicationConstructorService.getApplicationConstructors().subscribe((response) => {
      this.applicationConstructors = this.mapConstructorData(response);
      this.filterData();
      this.loading = false;
    });
  }

  private mapConstructorData(data: ApplicationConstructor[]): ApplicationConstructorList[] {
    return data.map((constructor) => {
      const status = constructorStatuses.find((item) =>
        item.enum === constructor.status) ?? <ApplicationConstructorStatus>{};
      return {
        externalId: constructor.externalId,
        name: constructor.basicSettings.name,
        status,
        category: constructor.basicSettings.dictApplicationCategoryDto,
        type: constructor.basicSettings.dictApplicationTypeDto,
        trainingLevels: constructor.filter.trainingLevelDtos,
        faculties: constructor.filter.facultyDtos,
        studyForms: constructor.filter.studyFormDtos,
        citizenShips: constructor.filter.citizenShipDtos,
        isAllTrainingLevelAvailable: constructor.basicSettings.isAllTrainingLevelAvailable,
        isAllFacultyAvailable: constructor.basicSettings.isAllFacultyAvailable,
        isAllStudyFormAvailable: constructor.basicSettings.isAllStudyFormAvailable,
        isAllCitizenshipAvailable: constructor.basicSettings.isAllCitizenshipAvailable
      };
    });
  }

  protected sortChange(sort: SortDescriptor[]) {
    this.sort = sort;
    this.state = { ...this.state, sort};
    this.gridView = process(this.gridData, this.state);
  }

  protected pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.state = {
      ...this.state,
      skip: event.skip,
      take: event.take,
    };

    this.gridView = process(this.gridData, this.state);
  }

  protected filterChange(value: string[], {field}: ColumnComponent) {
    if (field === 'faculties') {
      value = this.filterParentIds(value);
    }

    this.filters[field as keyof typeof this.filters] = value;
    this.filterData();
  }

  private filterParentIds(value: string[]) {
    return value.filter(item => !this.filialTree.some(treeItem => treeItem.id === item) || item === '');
  }

  protected filterData() {
    this.skip = 0;
    this.state = {
      ...this.state,
      skip: this.skip,
      take: this.pageSize
    };

    this.gridData = this.applicationConstructors.filter((a) => {
      return (this.filters.status.length ? this.filters.status.includes(a.status.name) : a.status.name)
        && (this.filters.type.length ? this.filters.type.includes(a.type.id) : a.type.id)
        && (this.nameFilter ? a.name.toLowerCase().includes(this.nameFilter.toLowerCase()) : a.name)
        && this.filterByConstructorFilters(ApplicationConstructorFiltersEnum.isAllTrainingLevelAvailable, a)
        && this.filterByConstructorFilters(ApplicationConstructorFiltersEnum.isAllFacultyAvailable, a)
        && this.filterByConstructorFilters(ApplicationConstructorFiltersEnum.isAllStudyFormAvailable, a)
        && this.filterByConstructorFilters(ApplicationConstructorFiltersEnum.isAllCitizenshipAvailable, a);
    });
    this.gridView = process(this.gridData, this.state);
  }

  private filterByConstructorFilters(filter: ApplicationConstructorFiltersEnum, constructor: ApplicationConstructorList) {
    return this.filters[filter].length
      ? constructor[filter].some((item) => this.filters[filter].includes(item.id))
        || constructor[applicationConstructorFiltersMap.get(filter) as keyof ApplicationConstructorList]
      : constructor[filter];
  }

  protected rowCallback = (context: RowClassArgs) => {
    const dataItem: ApplicationConstructorList = context.dataItem;
    return {
      'draft': dataItem.status.enum === NumericApplicationConstructorStatusesEnum.Draft
    };
  }

  protected addConstructor() {
    // добавление
    this.router.navigate([`mfc/applicationConstructor/constructorForm`]);
  }

  protected onCellClick({dataItem}: CellClickEvent) {
    this.router.navigate([`mfc/applicationConstructor/constructorForm/${dataItem.externalId}`]);
  }
}

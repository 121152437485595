import { Component, OnInit, Input} from '@angular/core';
import { SearchPersonsService } from 'src/app/services/announcement/search-persons.service';
import { FoundPersonsService} from 'src/app/services/announcement/found-persons.service';
import { Subscription } from 'rxjs';
import { GridDataResult, RowArgs } from '@progress/kendo-angular-grid';


@Component({
  selector: 'app-found-persons',
  templateUrl: './found-persons.component.html',
  styleUrls: ['./found-persons.component.scss']
})

export class FoundPersonsComponent implements OnInit {
  @Input() isNewList: boolean = false;

  public gridView!: GridDataResult;
  public selection: any[] = [];
  public persons: any = [];
  public quantityPersons: number = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private searchpersonsService: SearchPersonsService,
    private foundpersonsService: FoundPersonsService,
  ) { }

  public addListPersons() {
    if (!this.isNewList){
      this.foundpersonsService.getSelectPersons(
        this.selection,
        this.foundpersonsService.selectPersons$.getValue()
      );
      this.selection = [];
    } else {
      this.foundpersonsService.getNewListPersons(
        this.selection,
        this.foundpersonsService.selectNewListPersons$.getValue()
      );
      this.selection = [];
    }
  }

  public mySelectionKey(context: RowArgs): string {
    return context.dataItem;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.searchpersonsService.subscriber$.subscribe(data => {
        this.persons = data;
        this.quantityPersons = this.persons.length;
        this.gridView = {
          data: this.persons,
          total: this.persons.length
        };
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

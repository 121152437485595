import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DistribuitionsService } from 'src/app/services/announcement/distribuitions.service';
import { Distribuitions } from 'src/app/models/announcement/distribuitions.model';
import { FoundStudentsService } from 'src/app/services/announcement/found-students.service';
import { FoundPersonsService } from 'src/app/services/announcement/found-persons.service';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import urlJoin from 'url-join';
import { Subscription } from 'rxjs';
import { GridDataResult, RemoveEvent, RowArgs } from '@progress/kendo-angular-grid';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
    selector: 'app-distribuitions',
    templateUrl: './distribuitions.component.html',
    styleUrls: ['./distribuitions.component.scss']
})

export class DistribuitionsComponent implements OnInit{
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);
    
    VisibleAdvancedSearch: boolean = true;
    

    public advanced_search(): void {
        this.VisibleAdvancedSearch = !this.VisibleAdvancedSearch;
    }
    
    Save_List: boolean = true;
    New_List: boolean = false;
 
    savelist = true;
    newlist = false;

    public select_newlist(): void{
        this.announcementService.currentAnnouncement.next({
            deliveries: [],
            announcement: [],
        });

    //    this.foundpersonsService.getSelectPersons([], []);
    //    this.foundStudentsService.getSelectStudents([], []);
        
        this.New_List = true;
        this.Save_List = false;

        this.savelist = false;
        this.newlist = true;

        this.titleList = "";

        this.distribuitionsService.getNewList(this.New_List);
    }

    public select_savelist(): void{
        this.announcementService.currentAnnouncement.next({
            deliveries: [],
            announcement: [],
        });

     //   this.foundpersonsService.getSelectPersons([], []);
    //    this.foundStudentsService.getSelectStudents([], []);
        
        this.New_List = false;
        this.Save_List = true;

        this.savelist = true;
        this.newlist = false;

        this.selection = [];

        this.titleList = "";
        this.commonList = [];

        this.distribuitionsService.getNewList(this.New_List);
    }

    constructor(
        private distribuitionsService: DistribuitionsService,
        private foundStudentsService: FoundStudentsService,
        private foundpersonsService: FoundPersonsService,
        private announcementService: AnnouncementService,
        private http: HttpClient,
        private notificationService: NotificationsService,
        private dialogService: DialogService,
    ) { }
    
    public gridView!: GridDataResult;
    public selection: any[] = [];
    public distribuitions: Distribuitions[] = [];
    public Deliveries: Array<{userId: number}> = [];
    public selectedStudents: any = [];
    public selectedPersons: any = [];
    public studentsFromList: Array<any> = [];
    public personsFromList: Array<any> = [];
    public commonList: Array<any> = [];
    public titleList: string = "";
    public externalId: any[] = [];
    private subscriptions: Subscription[] = [];

    public mySelectionKey(context: RowArgs): string {
        return context.dataItem;
      }

    public getDistribuitions() {
        this.distribuitionsService.getDistribuitions()
        .subscribe(
            response => {
                this.distribuitions = response;
                this.gridView = {
                    data: this.distribuitions,
                    total: this.distribuitions.length
                  };
            }
        );
    }

    public getDistribuitionId(id: number){
        this.distribuitionsService.getDistribuitionId(id)
        .subscribe(response => {
            let data: any = response;
            for (let i=0; i < data.length; i++){
               if (this.commonList.some(e => e.userId === data[i].userId) == false) {
                this.commonList.push(data[i]);
               }  
            }
            for (let i=0; i < this.commonList.length; i++){
                if (this.commonList[i].studentNumber == null && this.personsFromList.some(e => e.userId === this.commonList[i].userId) == false){
                    this.personsFromList.push(this.commonList[i]);
                }
                if (this.commonList[i].studentNumber !== null && this.studentsFromList.some(e => e.userId === this.commonList[i].userId) == false) {
                    this.studentsFromList.push(this.commonList[i]);
                }
            }
            this.commonList = [];

            this.foundStudentsService.getDistributionStudents(this.studentsFromList, this.foundStudentsService.selectDistrStudents$.getValue());
            this.foundpersonsService.getDistributionPersons(this.personsFromList, this.foundpersonsService.selectDistrPersons$.getValue());
        });
    }

    public removeHandler({ dataItem }: RemoveEvent): void {
        const dialog: DialogRef = this.dialogService.open({
            title: "Пожалуйста подтвердите",
            content: `Вы действительно хотите удалить данный список?`,
            actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
            width: 450,
            height: 200,
            minWidth: 250,
          });
          dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
                if(result.text == "Да"){
                    this.distribuitionsService.deleteList(dataItem.id)
                    .subscribe(() => {
                        this.notificationService.showSuccess("Удалено");
                        this.getDistribuitions();         
                    });
                }
              }
          })
        
    }

    public downloadList(){
        this.personsFromList = [];
        this.studentsFromList = [];
        for (let i=0; i < this.selection.length; i++) {
            this.getDistribuitionId(this.selection[i].id);
        }
    }

    public SendNewList(sectionNewList: boolean){
        this.Deliveries = [];
        if (sectionNewList == true){
            this.selectedStudents = this.foundStudentsService.selectStudentsNewList$.getValue();
            this.selectedPersons = this.foundpersonsService.selectNewListPersons$.getValue();
        } else {
            this.selectedStudents = this.foundStudentsService.selectDistrStudents$.getValue();
            this.selectedPersons = this.foundpersonsService.selectDistrPersons$.getValue();
        }
        
        for(let i = 0; i < this.selectedStudents.length; i++){
            if (this.Deliveries.some(e => e.userId === this.selectedStudents[i].userId) == false) {
                this.Deliveries.push({"userId": this.selectedStudents[i].userId});
                this.externalId.push({"userExternalId": this.selectedStudents[i].userExternalId});
            }
        }
        for(let i = 0; i < this.selectedPersons.length; i++){
            if (this.Deliveries.some(e => e.userId === this.selectedPersons[i].userId) == false) {
                this.Deliveries.push({"userId": this.selectedPersons[i].userId});
                this.externalId.push({"userExternalId": this.selectedPersons[i].userExternalId});
            }
        }
        let distUsersIds = [];
        distUsersIds = this.Deliveries.map((userId, index) => {
            return {
                userId: this.Deliveries[index].userId,
                userExternalId: this.externalId[index].userExternalId,
            }
        });
      
        if (this.Deliveries.length > 0 && this.titleList.trim() !== "" && this.distribuitions.some(e => e.title === this.titleList) == false) {
            let NewList = {title: this.titleList, distUsersIds: distUsersIds};
       
        this.http.post(this.baseUrl + '/distribution', NewList)
              .subscribe((res) => {
                this.selection = [];
                
                this.getDistribuitions();
                this.notificationService.showSuccess("Список сохранён");      
              }
            );
        } else {
            this.notificationService.showError("Получатели не выбраны или название списка уже существует либо не задано");
        }
    }

    ngOnInit() {
        this.getDistribuitions();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    ngOnChanges(){
        this.getDistribuitions();
    }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import {EducationAccessLevel, EducationUserAccess} from 'src/app/models/useraccess/education/educationuseraccess.model';
import { environment } from 'src/environments/environment';
import {CurrentControlUserAccess} from "../../models/useraccess/currentcontrol/currentcontroluseraccess.model";
import {MiddleControlUserAccess} from "../../models/useraccess/middlecontrol/middlecontroluseraccess.model";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EducationUserAccessService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.education.useraccess}`;

  constructor(private http: HttpClient) { }

  //Get access
  public getAccess(): Observable<EducationUserAccess[]> {
    return this.http.get<EducationUserAccess[]>(this.baseUrl + '/GetAccess/')
      .pipe(
        catchError(this.handleError));
  }

  public getUserAccessLevel():Observable<boolean> {
    return this.http.get<EducationUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(
        map(
          (response)=> response.userAccessLevel
        )
      );
  }

  public getProgramAccessLevel(id: string) {
    return this.http.get<EducationAccessLevel>(this.baseUrl + '/GetProgramAccessLevel/' + id)
      .pipe(
        catchError(this.handleError));
  }

  public getPlanEditAccessLevel(id: string) {
    return this.http.get<EducationAccessLevel>(this.baseUrl + '/GetPlanEditAccessLevel/' + id)
      .pipe(
        catchError(this.handleError));
  }


  public getAccessLevel(): Observable<EducationUserAccess> {
    return this.http.get<EducationUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(catchError(this.handleError));
  }

  //Save access
  public saveAccess(useraccess: EducationUserAccess): Observable<EducationUserAccess> {
    return this.http.post<EducationUserAccess>(this.baseUrl + '/SaveAccess/', useraccess)
      .pipe(catchError(this.handleError));
  }

  //Delete access
  public deleteAccess(id: string): Observable<EducationUserAccess> {
    return this.http.delete<EducationUserAccess>(this.baseUrl + '/DeleteAccess/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentItem, DocumentGroup, ApplicationDocument} from '../../../../models/mfc/applicationForm/document.model';
import {DocumentsService} from "../../../../services/mfc/documents.service";
import {ApplicationApprovalSettings, CompleteApprovalStepRequest, MoveToApprovalStepRequest} from "../../../../models/mfc/applicationForm/approvalList.model";
import {saveAs} from "@progress/kendo-file-saver";
import {SignatoryRoleService} from "../../../../services/mfc/dicts/signatory-role.service";
import {lastValueFrom} from "rxjs";
import {createMap} from "../../../../helpers/map-helper";
import {DictSignatoryRole} from "../../../../models/mfc/dicts/signatory-role.model";
import {RoleService} from "../../../../services/mfc/dicts/role.service";
import {Role} from "../../../../models/mfc/dicts/role.model";
import {ApplicationApprovalTypeEnum} from "../../../../models/mfc/enums/application-approval-type.enum";
import {ApprovalListService} from "../../../../services/mfc/approvalList.service";
import {ApplicationFormService} from "../../../../services/mfc/application-form.service";
import {CryptoArmService} from "../../../../services/mfc/cryptoarm-service";
import {CreateSessionResponse} from "../../../../models/mfc/cryptoarm-model";
import {CryptoArmSignStatusEnum} from "../../../../models/mfc/enums/cryptoarm-sign-status.enum";
import {ApplicationEditFormService} from '../../../../services/mfc/application-edit-form.service';

@Component({
  selector: 'mfc-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationFormDocumentsComponent implements OnInit, OnChanges {

  @Input() approvalSettings: ApplicationApprovalSettings[] = [];
  @Input() documents: ApplicationDocument[] = [];
  @Input() applicationId = '';

  protected documentGroups: DocumentGroup[] = [];
  protected signatoryRoles: DictSignatoryRole[] = [];
  protected roles: Role[] = [];

  protected disableEnhancedSigning = false;

  // Ng-Templates
  protected readonly signatureTemplate?: {approvalSettings: DocumentItem};
  protected readonly documentTemplate?: {approvalSettings: DocumentItem};

  protected signatoryRolesMap = new Map<unknown, unknown>([]);
  protected rolesMap = new Map<unknown, unknown>([]);
  protected signingsMap = new Map<ApplicationApprovalTypeEnum, string>([
    [ApplicationApprovalTypeEnum.SigningOfAnEnhancedElectronicSignature, 'Подписано усиленной ЭП'],
    [ApplicationApprovalTypeEnum.SigningASimpleElectronicSignature, 'Подписано простой ЭП'],
    [ApplicationApprovalTypeEnum.SigningAPhysicalSignature, 'Подписано физической подписью'],
  ]);

  protected readonly ApplicationApprovalTypeEnum = ApplicationApprovalTypeEnum;

  constructor(
    private cdr: ChangeDetectorRef,
    private documentsService: DocumentsService,
    private signatoryRoleService: SignatoryRoleService,
    private roleService: RoleService,
    private approvalListService: ApprovalListService,
    private applicationFormService: ApplicationFormService,
    private cryptoArmService: CryptoArmService,
    private editFormService: ApplicationEditFormService
  ) { }

  async ngOnInit() {
    await this.getSignatoryRoles();
    await this.getRoles();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.approvalSettings.length) {
      this.documentGroups = this.mapApprovalSettings(this.approvalSettings);
    }
  }

  private async getSignatoryRoles() {
    await lastValueFrom(this.signatoryRoleService.getSignatoryRoles()).then((response) => {
      this.signatoryRoles = response;
      this.signatoryRolesMap = createMap({value: response, valueField: 'externalId', textField: 'name'});
    });
  }

  private async getRoles() {
    await lastValueFrom(this.roleService.getRoles()).then((response) => {
      this.roles = response;
      this.rolesMap = createMap({value: response, valueField: 'id', textField: 'name'});
    });
  }

  private mapApprovalSettings(approvalSettings: ApplicationApprovalSettings[])  {
    approvalSettings = this.editFormService.mapApprovalSettings(approvalSettings);

    const documentItems = approvalSettings.map((item) => {
      return {
        ...item,
        signDate: item.completedApprovalStep?.enhancedSignatureInfo?.signDate,
        expirationDate: item.completedApprovalStep?.enhancedSignatureInfo?.certificateExpirationDate,
        document: this.documents.find(a => a.externalId === item.applicationConstructorDocumentId),
        statusName: (item.completedApprovalStep
          ? item.completedApprovalStep.approvalResult ? item.yesText : item.noText
          : item.notMarkedText) ?? ''
      } as DocumentItem;
    }).filter((a) => a.isSignatoryRequired);

    return this.groupDocuments(documentItems);
  }

  private groupDocuments(docs: DocumentItem[]) {
    let statusGroups: DocumentGroup[] = [];
    const groups = docs.reduce((acc, item) => {
      acc[item.statusName] = acc[item.statusName] ?? [];
      acc[item.statusName].push(item);
      return acc;
    }, {} as Record<string, DocumentItem[]>);
    Object.entries(groups).forEach(([key, value]) => {
      statusGroups.push({statusName: key, items: value});
    });
    return statusGroups;
  }

  protected downloadDocument(item: DocumentItem) {
    if (!item.document) {
      return;
    }

    if (item.completedApprovalStep) {
      this.getCompletedTemplate(item.document);
      return;
    }

    if (item.applicationConstructorDocumentId) {
      this.documentsService.downloadDocument(this.applicationId, item.applicationConstructorDocumentId).subscribe((response) => {
        saveAs(response, item.document?.name ?? '');
      });
    }
  }

  protected downloadReadyDocument(document: ApplicationDocument) {
    if (document.externalId) {
      this.documentsService.downloadDocument(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, document.name);
      });
    }
  }

  protected completeApprovalStep(request: CompleteApprovalStepRequest) {
    this.approvalListService.completeApprovalStep(this.applicationId, request).subscribe(() => {
      this.applicationFormService.refresh$.next(true);
    });
  }

  protected signDocument(item: DocumentItem) {
    switch (item.approvalType) {
      case ApplicationApprovalTypeEnum.SigningAPhysicalSignature:
      case ApplicationApprovalTypeEnum.SigningASimpleElectronicSignature:
        this.completeApprovalStep({approvalResult: true, applicationApprovalSettingId: item.externalId});
        break;

      case ApplicationApprovalTypeEnum.SigningOfAnEnhancedElectronicSignature:
        this.cryptoArmService.createSession(item.externalId).subscribe({
          next: (response) => {
            this.disableEnhancedSigning = true;
            window.open(response.link);
            this.getSigningStatus(response);
          }
        });
    }
  }

  protected getSigningStatus(response: CreateSessionResponse) {
    const interval = setInterval(() => {
      this.cryptoArmService.getSigningStatus(response.sessionId).subscribe({
        next: (statusResponse) => {

          if (statusResponse === CryptoArmSignStatusEnum.Signed || statusResponse === CryptoArmSignStatusEnum.NotSigned) {
            clearInterval(interval);
            this.applicationFormService.refresh$.next(true);
            this.disableEnhancedSigning = false;
          }

        }
      })
    }, 2000);
  }

  protected moveToApprovalStep(item: DocumentItem) {
    this.approvalListService.moveToApprovalStep(this.applicationId, {applicationApprovalSettingId: item.externalId})
      .subscribe(() => {
        this.applicationFormService.refresh$.next(true);
      });
  }

  protected getCompletedTemplate(document?: ApplicationDocument) {
    if (document?.externalId) {
      this.documentsService.getCompletedTemplate(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, document.name);
      });
    }
  }

  protected deleteCompletedApprovalStep(request: MoveToApprovalStepRequest) {
    this.approvalListService.deleteCompletedApprovalStep(this.applicationId, request).subscribe(() => {
      this.applicationFormService.refresh$.next(true);
    });
  }

  protected downloadDocumentSign(document?: ApplicationDocument) {
    if (document?.externalId) {
      this.applicationFormService.downloadDocumentSign(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, `${document.name}.docx.sig`);
      });
    }
  }

  protected downloadWithStamp(document?: ApplicationDocument) {
    if (document?.externalId) {
      this.documentsService.getWithStamp(this.applicationId, document.externalId).subscribe((response) => {
        saveAs(response, document.name);
      });
    }
  }
}

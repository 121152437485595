import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { items } from "../../../models/middlecontrol/menu-items";
import { DrawerItemExpandedFn } from '@progress/kendo-angular-layout';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import {MiddleControlUserAccessService} from "../../../services/useraccess/middle-control-user-access.service";

@Component({
  selector: 'middle-control-menu-access',
  templateUrl: './middlecontrolmenuaccess.component.html',
  styleUrls: ['./middlecontrolmenuaccess.component.scss']
})
export class MiddleControlMenuAccessComponent implements OnInit {

  public adminCheck = false;

  //Menu items
  public items: any[] = items;
  public isMainPage: boolean = this.router.url.endsWith('/middlecontrol');

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.items.findIndex((i) => i.text === item.text);
    const url = this.items
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.text.toLowerCase()}` );
    this.router.navigate(url);
  }
  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private router: Router,
              private jwtHelper: JwtHelperService,
              private userAccessService: MiddleControlUserAccessService,
              private tokenStore: TokenStorageService)
    { }

  ngOnInit(): void {
    this.getAccessLevel()
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe(
        response => {
          this.accessSetting(response)
        },
        error => {
          this.accessSetting( {
            userAccessLevel: false,
            localSignatoryAccessLevel: false,
            dictAccessLevel: false
          })
        }
      );
  }

  public accessSetting(response:any){
    // Hide admin items
    const token = this.tokenStore.getAuthToken();
    let user;
    if(token !== null)
    {
      user = this.jwtHelper.decodeToken(token);
    }
    if(response.userAccessLevel||response.localSignatoryAccessLevel||response.dictAccessLevel)this.adminCheck = true;
    let role = user.role;
    if(!role.includes(Role.Admin)&&!response.userAccessLevel)
    {
      this.items[0].items = this.items[0].items.filter((a: { useraccess: boolean; }) => a.useraccess==null);
    }
    if(!role.includes(Role.Admin)&&!response.dictAccessLevel)
    {
      this.items[0].items = this.items[0].items.filter((a: { dicts: boolean; }) => a.dicts==null);
    }
    if(!role.includes(Role.Admin)&&!response.localSignatoryAccessLevel)
    {
      this.items[0].items = this.items[0].items.filter((a: { signers: boolean; }) => a.signers==null);
    }
    if(role.includes(Role.Admin))
    {
      this.adminCheck = true;
    }
  }

}

<div>
    <h1>{{ standardName }}</h1>

<kendo-grid #grid
    class="m-t20"
    [kendoGridBinding]="protocols"
    [loading]="loading"
    [filterable]="true"
    [sortable]="true"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip"
    (cellClick)="cellClickHandler($event)"
    [pageable]="{
        buttonCount: buttonCount,
        info: info,
        type: type,
        pageSizes: pageSizes,
        previousNext: previousNext,
        position: position
    }"
    [rowClass]="rowCallback"
>
    <kendo-grid-column field="sericalNumber"
                title="№ приказа о допуске"
                [width]="120"
                [filterable]="false"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                {{ dataItem.sericalNumber}}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studentFio"
                title="ФИО обучающегося"
                [width]="200"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells leftAlignment">
              {{ dataItem.studentFio }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studentGroupName"
                title="Ак. группа"
                [width]="120"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
              {{ dataItem.studentGroupName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="admissionYear"
                title="Год поступления"
                [width]="150"
                headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
              {{ dataItem.admissionYear }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngFor="let column of tabsList"
                        [sortable]="false"
                        [width]="180" headerClass="gridHeader" 
                        field="{{ column.id }}"
                        title="{{ column.tabName }}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem >
            <span class="alignCells">
                <span>{{ dataItem[column.id]}}</span>
                <button kendoGridRemoveCommand icon="download" fillMode="flat" size="none" class="custom-size m-l15" rounded="full"
                  *ngIf="showButton(dataItem.studentId, column.id)" (click)="downloadProtocol(dataItem.studentId, column.id)">
                </button>
            </span>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>

</div>

<div>
    <router-outlet></router-outlet>
</div>
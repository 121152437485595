import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Military } from '../../models/contingent/military.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MilitaryService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.military}`;

  constructor(private http: HttpClient) { }

  //Get all military
  public getAllmilitary(): Observable<Military[]> {
    return this.http.get<Military[]>(this.baseUrl);
  }

  //Get By Id military
  getByIdmilitary(id: string): Observable<Military> {
    return this.http.get<Military>(this.baseUrl + '/GetMilitaryById/' + id)
      .pipe(catchError(this.handleError));
  }

  //Get By StudPersonId military
  getByStudPersonIdMilitary(id: string): Observable<Military> {
    return this.http.get<Military>(this.baseUrl + '/GetMilitaryByStudPersonId/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add military
  addmilitary(military: Military, studentId: string): Observable<Military> {
    return this.http.post<Military>(this.baseUrl + '/' + studentId, military)
      .pipe(catchError(this.handleError));
  }

  //Delete military
  deletemilitary(id: string, studentId: string): Observable<Military> {
    return this.http.delete<Military>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit military
  updatemilitary(military: Military, studentId: string): Observable<Military> {
    return this.http.put<Military>(this.baseUrl + '/' + military.militaryExternalId + '/' + studentId, military)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}


<kendo-stacklayout [gap]="25" orientation="vertical" class="retake">
  <h2>Пересдача</h2>
  <kendo-stacklayout [gap]="8" orientation="vertical">
    <kendo-label text="Фамилия Имя Отчество" class="retake__user">
      <span class="retake__value">{{ studentName }}</span>
    </kendo-label>
    <kendo-label text="Семестр" class="retake__user">
      <span class="retake__value"
        >{{ semester?.semesterInYear }} семестр
        {{ semester?.semesterYearString }}</span
      >
    </kendo-label>
  </kendo-stacklayout>
  <kendo-grid [data]="dataGrid" class="retake__table" [loading]="isLoading">
    <ng-template kendoGridLoadingTemplate>
      <div class="retake__loader">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <kendo-grid-column
      title="Дисциплина"
      field="disciplineName"
      [width]="400"
      [style]="{
        'text-align': 'left'
      }"
      [headerStyle]="{
        padding: '16px',
        'text-align': 'center'
      }"
    >
    </kendo-grid-column>
    <kendo-grid-column
      title="Оценка"
      field="markName"
      [width]="260"
      [style]="{
        'text-align': 'left'
      }"
      [headerStyle]="{
        padding: '16px',
        'text-align': 'center'
      }"
    >
    </kendo-grid-column>
    <kendo-grid-column
      title="Дата"
      field="markDate"
      [width]="200"
      [style]="{
        'text-align': 'center'
      }"
      [headerStyle]="{
        padding: '16px',
        'text-align': 'center'
      }"
    >
    </kendo-grid-column>
  </kendo-grid>
  <div class="retake__button-container">
    <button kendoButton (click)="onGoBack()" class="button button--action">
      Назад
    </button>
  </div>
</kendo-stacklayout>

import { Component, OnInit } from '@angular/core';
import { items } from '../../../models/diplomablanks/menu-items';
import {DiplomablanksUserAccessService} from '../../../services/useraccess/diplomablanks-user-access.service';
import {UserSettingsModel} from '../../../models/diplomablanks/settings.model';

@Component({
  selector: 'blanks-settings',
  templateUrl: './blanks-settings.component.html',
  styleUrls: ['./blanks-settings.component.scss'],
})
export class BlanksSettingsComponent implements OnInit {
  public items: any[] = items;
  public user: UserSettingsModel = new UserSettingsModel;

  constructor(
    private diplomablanksUserAccessService: DiplomablanksUserAccessService,
  ) {}

  async ngOnInit() {
    this.getUserAccess();
  }

  public getUserAccess() {
    this.diplomablanksUserAccessService.getUserAccess()
      .subscribe(response => {
        this.user = response;
        if (!this.user.isAdmin) {
          this.items[0].items = this.items[0].items.filter((item: any) => {
            return (
            //  (this.user.dict ? item : !item.dicts) &&
              (this.user.isAccessSettings ? item : !item.accessSettings)
            );
          });
        }
      })
  }

}

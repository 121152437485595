import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent } from '@progress/kendo-angular-grid';
import { Validators, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { BudgetsubcategoryService } from 'src/app/services/contingent/budgetsubcategory.service';
import { BudgetSubCategory } from 'src/app/models/contingent/budgetsubcategory.model';
import { NotificationsService } from "../../../services/notifications/notifications.service";
import {
  DialogService,
  DialogRef,
  DialogCloseResult,
} from "@progress/kendo-angular-dialog";
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import {BudgetCategory} from "../../../models/contingent/budgetcategory.model";
import {BudgetcategoryService} from "../../../services/contingent/budgetcategory.service";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import { DictFilialService } from '../../../services/contingent/dictfilial.service';
import { DictFilial } from '../../../models/contingent/dictfilial.model';
import { DictFilter } from '../../../models/contingent/filter.model';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-budgetsubcategory',
  templateUrl: './budgetsubcategory.component.html',
  styleUrls: ['./budgetsubcategory.component.scss']
})
export class BudgetsubcategoryComponent implements OnInit {

  public budgetCategories: BudgetCategory[]=[];

  public budgetSubCategories: BudgetSubCategory[] = [];
  public budgetSubCategory: BudgetSubCategory = {
    dictStudentBudgetSubCategoryId: 0,
    budgetSubCategoryName: '',
    filialId: ''
  }
  public filials: DictFilial[] = [];
  public secondFilter: DictFilter = {
    dictFilialExternalId: undefined
  }
  public budgetSubCategoriesByFilter: BudgetSubCategory[] = [];
  public budgetCategoriesByFilter: BudgetCategory[] = [];
  public position: "top" | "bottom" | "both" = "bottom";
  public formGroup: UntypedFormGroup | undefined;
  public names: string[] | undefined;
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public editable?: boolean
  public opened = false;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private budgetSubCategoryService: BudgetsubcategoryService,
              private budgetCategoryService: BudgetcategoryService,
              private renderer: Renderer2,
              private notificationService: NotificationsService,
              private dialogService: DialogService,
              private userAccessService: ContingentUserAccessService,
              private dictFilialService: DictFilialService,
  ) {
  }

  public ngOnInit(): void {
    this.getAccessLevel()
    this.getAllFilials();
    this.getAllBudgetSubCategory();
    this.getAllBudgetCategory();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  //Get budget name for id
  public nameBudgetCategory(id: string): string {
     return this.budgetCategories.find((x) => x.dictStudentBudgetCategoryExternalId === id)?.categoryName ?? ""
  }

  public onStateChange(): void {
    this.getAllBudgetSubCategory();
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }
  public defaultItemFilial: { filialSName: string; dictFilialExternalId?: any } = {
    filialSName: "Не выбрано",
    dictFilialExternalId: null,
  };

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public onValueChange(value: any): void {
    this.saveCurrentFilter();
  }

  saveCurrentFilter() {
    this.budgetSubCategoriesByFilter = [];
    this.budgetSubCategoriesByFilter = this.budgetSubCategories.filter(x => Guid.parse(x.filialId) == this.secondFilter.dictFilialExternalId);
    this.budgetCategoriesByFilter = [];
    this.budgetCategoriesByFilter = this.budgetCategories.filter(x => Guid.parse(x.filialId) == this.secondFilter.dictFilialExternalId);
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);
    this.formGroup = formGroup({
      dictStudentBudgetSubCategoryId: 0,
      budgetSubCategoryName: '',
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start Editing
  public editHandler({ sender,
                       rowIndex,
                       dataItem }: CellClickEvent): void {
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode) {
      //Adding new entry to dictionary
      if (this.isNew && this.formGroup !== undefined) {
        this.budgetSubCategoryService.addBudgetSubCategory(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllBudgetSubCategory();
              this.notificationService.showSuccess("Добавлено");
              this.budgetSubCategory = {
                dictStudentBudgetSubCategoryId: 0,
                budgetSubCategoryName: '',
                filialId: '',
                dictStudentBudget:{
                  dictStudentBudgetId: 0,
                  studentBudgetName: '',
                  studentBudgetSName: '',
                  isBudget: false,
                  filialId: ''
                }
              }
            },
            error:() => {
              this.notificationService.showError('Не удалось добавить подкатегорию бюджета');
            }}
          );
      }
      //Update dictionary
      else if (this.formGroup !== undefined) {
        this.budgetSubCategoryService.updateBudgetSubCategory(this.formGroup.value)
          .subscribe({
            next:() => {
              this.getAllBudgetSubCategory();
              this.notificationService.showSuccess("Сохранено");
            },
            error:() => {
              this.notificationService.showError('Не удалось сохранить изменения');
            }}
          );
      }
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllBudgetSubCategory() {
    this.budgetSubCategoryService.getAllBudgetSubCategory()
      .subscribe(
        response => {
          this.budgetSubCategories = response;
          this.saveCurrentFilter();
        }
      );
  }

  //Getting all entries from dictionary
  public getAllBudgetCategory() {
    this.budgetCategoryService.getAllBudgetCategory()
      .subscribe(
        response => {
          this.budgetCategories = response;
          this.saveCurrentFilter();
        }
      );
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить: "+ dataItem.budgetSubCategoryName + "?",
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.budgetSubCategoryService.deleteBudgetSubCategory(dataItem.dictStudentBudgetSubCategoryExternalId)
            .subscribe({
              next:() => {
                this.getAllBudgetSubCategory();
                this.notificationService.showSuccess("Удалено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }}
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

}

const formGroup = (dataItem: { studentBudgetName?: string; studentBudgetSName?: string; isBudget?: boolean; dictStudentBudgetSubCategoryId?: number; dictStudentBudgetSubCategoryExternalId?: string; dictStudentBudgetCategoryId?: string;
    budgetSubCategoryName?: string;  order?: number; }) =>
  new UntypedFormGroup({
    dictStudentBudgetSubCategoryId: new UntypedFormControl(dataItem.dictStudentBudgetSubCategoryId),
    dictStudentBudgetSubCategoryExternalId: new UntypedFormControl(dataItem.dictStudentBudgetSubCategoryExternalId),
    dictStudentBudgetCategoryId: new UntypedFormControl(dataItem.dictStudentBudgetCategoryId, Validators.required),
    budgetSubCategoryName: new UntypedFormControl(dataItem.budgetSubCategoryName, Validators.required),
    order: new UntypedFormControl(dataItem.order),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

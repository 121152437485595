export class StatusInfo {
  allowedStatuses!: AllowedStatus[];
  currentStatus!: Status;
}

export class Status {
  id!: number;
  name!: string;
  allowCopy!: boolean;
  allowDelete!: boolean;
  allowSave!: boolean;
}

export class AllowedStatus {
  description!: string;
  status!: Status;
}

export enum StatusEnum {
  DRAFT = "Черновик",
  CHECKING = "На проверке",
  APPROVED = "Принято",
  REVISION = "На доработке",
  DISAPPROVED = "Не принято",
  ARCHIVE = "Архив"
}

export enum NumericStatusEnum {
  DRAFT = 1,
  CHECKING = 2,
  APPROVED = 3,
  REVISION = 4,
  DISAPPROVED = 5,
  ARCHIVE = 7
}

export const Statuses: string[] = Object.values(StatusEnum);

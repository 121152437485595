import {Injectable} from '@angular/core';
import {
  ApplicationAccessRights,
  CurrentUserAccess,
  UpdateAccessRights,
  UserAccessRight
} from '../../models/mfc/userAccess.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, catchError, Observable} from 'rxjs';
import {handleError} from '../../helpers/errorHandle-helper';
import {NotificationsService} from '../notifications/notifications.service';
import {map, tap} from 'rxjs/operators';
import {AccessRights} from '../../models/mfc/enums/access-rights.enum';


@Injectable({
  providedIn: 'root'
})

export class UserAccessService {
  public currentUserAccess$ = new BehaviorSubject<CurrentUserAccess|null>(null);
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.userAccess}`;

  constructor(
    private http: HttpClient,
    private notificationService: NotificationsService
  ) { }

  private getApplicationAccess(userAccess: CurrentUserAccess)  {
    let applicationAccess: AccessRights = AccessRights.No;
    const write = userAccess.user.userAccessAccessRights.find((item) =>
      item.userAccessRight === AccessRights.Write);
    if (write && this.hasFilters(write)) {
      applicationAccess = AccessRights.Write;
    } else {
      const read = userAccess.user.userAccessAccessRights.find((item) =>
        item.userAccessRight === AccessRights.Read);
      if (read && this.hasFilters(read)) {
        applicationAccess = AccessRights.Read;
      }
    }
    userAccess.user.userAccessMainSettings.applicationAccess = applicationAccess;
    return userAccess;
  }

  private hasFilters(access: ApplicationAccessRights) {
    return (access.isAllApplicationTypesAvailable || !!access.applicationTypes.length)
      && (access.isAllFacultiesAvailable || !!access.faculties.length)
      && (access.isAllStudyFormsAvailable || !!access.studyForms.length)
      && (access.isAllTrainingLevelsAvailable || !!access.trainingLevels.length);
  }

  public getAll(): Observable<UserAccessRight[]> {
    return this.http.get<UserAccessRight[] | undefined>(this.baseUrl).pipe(
      map((data) => data ?? [])
    );
  }

  public getCurrentUserAccess(): Observable<CurrentUserAccess> {
    return this.http.get<CurrentUserAccess>(`${this.baseUrl}/GetCurrentUser`)
      .pipe(map((data) => this.getApplicationAccess(data)),catchError(handleError));
  }

  public edit(request: UpdateAccessRights): Observable<UpdateAccessRights>  {
    return this.http.put<UpdateAccessRights>(this.baseUrl, request)
      .pipe(
        tap(() => {
          if (request.personId === this.currentUserAccess$.value?.user.personId) {
            this.currentUserAccess$.next(null);
          }
          this.notificationService.showSuccess('Сохранено');
        }),
        catchError(err => {
          this.notificationService.showError(err);
          return handleError(err);
        })
      );
  }
}

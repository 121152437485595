<div class="buttons" *ngIf="editable">
  <button kendoButton (click)="onCancel()">Отмена</button>
  <button (click)="onSave()" themeColor="primary" kendoButton>Сохранить</button>
</div>

<div class="form" [formGroup]="formGroup">
  <span class="field">
    <h3>Категория</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="categories"
        [valuePrimitive]="true"
        (valueChange)="categoryChange($event)"
        [disabled]="!editable"
        formControlName="category"
        valueField="id"
        textField="name">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Тип</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="filteredTypes"
        [valuePrimitive]="true"
        (valueChange)="typeChange($event)"
        [disabled]="!editable"
        formControlName="type"
        valueField="id"
        textField="name">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Наименование шаблона формы заявки</h3>
    <span class="input">
      <kendo-textbox formControlName="name" (valueChange)="fieldChange()" [disabled]="!editable"></kendo-textbox>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Уровень подготовки</h3>
    <span class="input">
      <kendo-multiselect
        #trainingLevels
        [data]="dictTrainingLevels"
        [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="true"
        [autoClose]="false"
        [checkboxes]="true"
        [tagMapper]="tagMapper"
        (valueChange)="multiselectValueChange(
          formGroup.get('trainingLevels'),
          dictTrainingLevels,
          formGroup.get('isAllTrainingLevelAvailable'))"
        [disabled]="!editable"
        formControlName="trainingLevels"
        textField="name"
        valueField="id">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
          {{dataItem.length === dictTrainingLevels.length ? 'Все' : dataItem.length + ' выбрано'}}
        </ng-template>
        <ng-template kendoMultiSelectHeaderTemplate>
          <span class="multiselect-all">
            <input type="checkbox"
                   kendoCheckBox
                   [checked]="formGroup.get('isAllTrainingLevelAvailable')?.value"
                   [indeterminate]="isIndet(dictTrainingLevels.length, formGroup.get('trainingLevels')?.value.length)"
                   (click)="onIsAllClick(
                     trainingLevels,
                     dictTrainingLevels,
                     formGroup.get('isAllTrainingLevelAvailable'),
                     formGroup.get('trainingLevels'))"/>
            <kendo-label>Все</kendo-label>
          </span>
        </ng-template>
      </kendo-multiselect>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Институт/факультет</h3>
    <span class="input">
      <kendo-multiselecttree
        kendoMultiSelectTreeExpandable
        #faculties
        [kendoMultiSelectTreeFlatBinding]="dictFaculties"
        [popupSettings]="{width: 'auto'}"
        [tagMapper]="facultiesTagMapper"
        (valueChange)="multiselectValueChange(
          formGroup.get('faculties'),
          dictFaculties,
          formGroup.get('isAllFacultyAvailable'))"
        [disabled]="!editable"
        formControlName="faculties"
        parentIdField="filialId"
        textField="name"
        valueField="id">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
          {{dataItem.length === facultiesList.length ? 'Все' : dataItem.length + ' выбрано'}}
        </ng-template>
        <ng-template kendoMultiSelectHeaderTemplate>
          <span class="multiselect-all_tree">
            <input type="checkbox"
                   kendoCheckBox
                   [checked]="formGroup.get('isAllFacultyAvailable')?.value"
                   [indeterminate]="isIndet(dictFaculties.length, formGroup.get('faculties')?.value.length)"
                   (click)="onIsAllClick(
                     faculties,
                     dictFaculties,
                     formGroup.get('isAllFacultyAvailable'),
                     formGroup.get('faculties'),
                     false)"/>
            <kendo-label>Все</kendo-label>
          </span>
        </ng-template>
      </kendo-multiselecttree>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Форма обучения</h3>
    <span class="input">
      <kendo-multiselect
        #studyForms
        [data]="dictStudyForms"
        [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="true"
        [autoClose]="false"
        [checkboxes]="true"
        [tagMapper]="tagMapper"
        (valueChange)="multiselectValueChange(
          formGroup.get('studyForms'),
          dictStudyForms,
          formGroup.get('isAllStudyFormAvailable'))"
        [disabled]="!editable"
        formControlName="studyForms"
        textField="name"
        valueField="id">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
          {{dataItem.length === dictStudyForms.length ? 'Все' : dataItem.length + ' выбрано'}}
        </ng-template>
        <ng-template kendoMultiSelectHeaderTemplate>
          <span class="multiselect-all">
            <input type="checkbox"
                   kendoCheckBox
                   [checked]="formGroup.get('isAllStudyFormAvailable')?.value"
                   [indeterminate]="isIndet(dictStudyForms.length, formGroup.get('studyForms')?.value.length)"
                   (click)="onIsAllClick(
                     studyForms,
                     dictStudyForms,
                     formGroup.get('isAllStudyFormAvailable'),
                     formGroup.get('studyForms'))"/>
            <kendo-label>Все</kendo-label>
          </span>
        </ng-template>
      </kendo-multiselect>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Гражданство</h3>
    <span class="input">
      <kendo-multiselect
        #citizenships
        [data]="dictCitizenships"
        [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="true"
        [autoClose]="false"
        [checkboxes]="true"
        [tagMapper]="tagMapper"
        (valueChange)="multiselectValueChange(
          formGroup.get('citizenShips'),
          dictCitizenships,
          formGroup.get('isAllCitizenshipAvailable'))"
        [disabled]="!editable"
        formControlName="citizenShips"
        textField="name"
        valueField="id">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
          {{dataItem.length === dictCitizenships.length ? 'Все' : dataItem.length + ' выбрано'}}
        </ng-template>
        <ng-template kendoMultiSelectHeaderTemplate>
          <span class="multiselect-all">
            <input type="checkbox"
                   kendoCheckBox
                   [checked]="formGroup.get('isAllCitizenshipAvailable')?.value"
                   [indeterminate]="isIndet(dictCitizenships.length, formGroup.get('citizenShips')?.value.length)"
                   (click)="onIsAllClick(
                     citizenships,
                     dictCitizenships,
                     formGroup.get('isAllCitizenshipAvailable'),
                     formGroup.get('citizenShips'))"/>
            <kendo-label>Все</kendo-label>
          </span>
        </ng-template>
      </kendo-multiselect>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Вариант получения</h3>
    <span class="input">
      <kendo-multiselect
        [data]="receiptTypes"
        [valuePrimitive]="true"
        (valueChange)="typeReceiptChange($event)"
        [disabled]="!editable"
        formControlName="typeReceipts"
        textField="name"
        valueField="id">
      </kendo-multiselect>
      <h6 class="required" *ngIf="editable">*</h6>
    </span>
  </span>
  <span class="field">
    <h3>Добавление за отчисленных и окончивших обучение</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="boolOptions"
        [valuePrimitive]="true"
        (valueChange)="fieldChange()"
        [disabled]="!editable"
        formControlName="allowedAddInsteadOfStudents"
        valueField="id"
        textField="text">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
      <kendo-icon kendoTooltip
                  [tooltipWidth]="450"
                  title="У отчисленных и окончивших обучение студентов нет доступа к ЛК,
                  справку нужно выдавать лично"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </span>
  </span>
  <span class="field">
    <h3>Добавление за обучающихся, которых нет в базе</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="boolOptions"
        [valuePrimitive]="true"
        (valueChange)="fieldChange()"
        [disabled]="!editable"
        [value]="false"
        valueField="id"
        textField="text">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
      <kendo-icon kendoTooltip
                  [tooltipWidth]="450"
                  title="У обучающихся, которых нет в базе,
                  отсутствуют данные для формирования справок,
                  предполагается формирование справок вручную с минимальной подстановкой данных,
                  справку нужно выдавать лично"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </span>
  </span>
  <span class="field">
    <h3>Отображение в карточке обучающегося</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="boolOptions"
        [valuePrimitive]="true"
        (valueChange)="fieldChange()"
        [disabled]="!editable"
        formControlName="showOnStudentCard"
        valueField="id"
        textField="text">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
      <kendo-icon kendoTooltip
                  [tooltipWidth]="450"
                  title="Отображение справок из заявок данного типа
                  в карточке обучающегося в модуле “Контингент” во вкладке “Справки”"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </span>
  </span>
  <span class="field">
    <h3>Наличие печатной формы документа</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="boolOptions"
        [valuePrimitive]="true"
        (valueChange)="documentsChange($event, DocumentTypeStrings.Document, DocumentTypes.Document)"
        [disabled]="!editable"
        formControlName="hasDocuments"
        valueField="id"
        textField="text">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
      <kendo-icon kendoTooltip
                  [tooltipWidth]="450"
                  title="В модуле “Шаблоны справок” добавится возможность загрузки печатной формы документа,
                  который будет формироваться в заявке"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </span>
  </span>
  <div class="documents" *ngIf="formGroup.controls['hasDocuments'].value" [formArrayName]="DocumentTypeStrings.Document">
    <h3 *ngIf="$any(formGroup).controls[DocumentTypeStrings.Document].controls.length">Наименование</h3>
    <div class="row" *ngFor="let document of $any(formGroup).controls[DocumentTypeStrings.Document].controls; let i = index" [formGroupName]="i">
      <span class="input">
        <kendo-textbox (valueChange)="fieldChange()" [disabled]="!editable" formControlName="name"></kendo-textbox>
        <ng-container *ngIf="editable">
          <h6 class="required">*</h6>
          <kendo-icon class="delete-icon" name="close" size="medium"
                      (click)="removeDocument(DocumentTypeStrings.Document, i)">
          </kendo-icon>
        </ng-container>
      </span>
    </div>
    <div class="outlineButtons" *ngIf="editable">
      <button kendoButton fillMode="outline" size="small" icon="plus"
              (click)="addDocument(DocumentTypeStrings.Document, DocumentTypes.Document)">
        Добавить
      </button>
    </div>
  </div>
  <span class="field">
    <h3>Наличие заявления</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="boolOptions"
        [valuePrimitive]="true"
        (valueChange)="documentsChange($event, DocumentTypeStrings.Statement, DocumentTypes.Statement)"
        [disabled]="!editable"
        formControlName="hasStatements"
        valueField="id"
        textField="text">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
      <kendo-icon kendoTooltip
                  [tooltipWidth]="450"
                  title="В модуле “Шаблоны справок” добавится возможность загрузки печатной формы заявления,
                  которое отобразится в форме заявки"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </span>
  </span>
  <div class="documents" *ngIf="formGroup.controls['hasStatements'].value" [formArrayName]="DocumentTypeStrings.Statement">
    <h3 *ngIf="$any(formGroup).controls[DocumentTypeStrings.Statement].controls.length">Наименование</h3>
    <div class="row" *ngFor="let document of $any(formGroup).controls[DocumentTypeStrings.Statement].controls; let i = index" [formGroupName]="i">
      <span class="input">
        <kendo-textbox (valueChange)="fieldChange()" [disabled]="!editable" formControlName="name"></kendo-textbox>
        <ng-container *ngIf="editable">
          <h6 class="required">*</h6>
          <kendo-icon class="delete-icon" name="close" size="medium"
                      (click)="removeDocument(DocumentTypeStrings.Statement, i)">
          </kendo-icon>
        </ng-container>
      </span>
    </div>
    <div class="outlineButtons" *ngIf="editable">
      <button kendoButton fillMode="outline" size="small" icon="plus"
              (click)="addDocument(DocumentTypeStrings.Statement, DocumentTypes.Statement)">
        Добавить
      </button>
    </div>
  </div>
  <span class="field">
    <h3>Наличие рег. данных (рег. номер и дата выдачи)</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="boolOptions"
        [valuePrimitive]="true"
        (valueChange)="fieldChange()"
        [disabled]="!editable"
        formControlName="allowRegistrationNumber"
        valueField="id"
        textField="text">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
      <kendo-icon kendoTooltip
                  [tooltipWidth]="450"
                  title="В заявке добавятся поля для ввода рег. номера и даты выдачи документа заявки"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </span>
  </span>
  <span class="field">
    <h3>Наличие табличного списка дисциплин</h3>
    <span class="input">
      <kendo-dropdownlist
        [data]="boolOptions"
        [valuePrimitive]="true"
        (valueChange)="fieldChange()"
        [disabled]="!editable"
        [value]="false"
        valueField="id"
        textField="text">
      </kendo-dropdownlist>
      <h6 class="required" *ngIf="editable">*</h6>
      <kendo-icon kendoTooltip
                  [tooltipWidth]="450"
                  title="В заявке добавится вкладка с выбранным списком дисциплин"
                  name="info"
                  tooltipClass="mfc-tooltip"
                  position="right"
                  size="medium">
      </kendo-icon>
    </span>
  </span>
  <span class="field field_table">
    <h3>Системные комментарии при смене статусов</h3>
    <kendo-grid class="grid-data borderless"
                #systemNotifications
                [data]="systemNotificationsFormArray.value"
                (add)="addRow($event)"
                (edit)="editRow($event)"
                (remove)="removeRow($event)">
      <ng-template kendoGridToolbarTemplate *ngIf="editable">
        <span *ngIf="notificationsEditMode; else view">
          <button (click)="cancelChanges(systemNotifications)" kendoButton>Отмена</button>
          <button [disabled]="gridFormGroup.invalid" kendoButton themeColor="primary"
                  (click)="saveRow({
                    dataItem: gridFormGroup?.value,
                    isNew: isNew,
                    rowIndex: (lastRowIndex ?? -1),
                    formGroup: gridFormGroup,
                    sender: systemNotifications
                  })">
            Сохранить
          </button>
        </span>
        <ng-template #view>
          <button kendoGridAddCommand themeColor="success" icon="plus">Добавить</button>
        </ng-template>
      </ng-template>
      <kendo-grid-column *ngIf="editable" [width]="150" title="">
        <ng-template kendoGridCellTemplate>
          <span class="action-buttons" *ngIf="!notificationsEditMode">
            <button kendoGridEditCommand
                    themeColor="secondary"
                    icon="edit"
                    fillMode="flat"
                    size="medium"
                    rounded="full">
            </button>
            <button kendoGridRemoveCommand
                    themeColor="secondary"
                    icon="delete"
                    fillMode="flat"
                    size="medium"
                    rounded="full">
            </button>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="applicationStatus" title="Статус">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{statusMap.get(dataItem.applicationStatus)}}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-column="column">
          <kendo-dropdownlist
            [data]="applicationStatuses"
            [valuePrimitive]="true"
            [formControl]="formGroup.get(column.field)"
            valueField="applicationStatusEnum"
            textField="name">
          </kendo-dropdownlist>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dictTypeReceiptId" title="Вариант получения">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{receiptTypesMap.get(dataItem.dictTypeReceiptId)}}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-column="column">
          <kendo-dropdownlist
            [data]="receiptTypes"
            [valuePrimitive]="true"
            [formControl]="formGroup.get(column.field)"
            valueField="id"
            textField="name">
          </kendo-dropdownlist>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="text" title="Комментарий">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{dataItem.text}}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-column="column">
          <kendo-textarea [formControl]="formGroup.get(column.field)"></kendo-textarea>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </span>
  <span class="field field_table">
    <h3>Текст пояснения для поля “Вариант получения”</h3>
    <kendo-grid class="grid-data borderless"
                [data]="typeReceiptDescriptions">
      <kendo-grid-column field="typeReceipt" title="Вариант получения">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{dataItem.typeReceipt}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="description" title="Текст пояснения">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{dataItem.description}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </span>
</div>

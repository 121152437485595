import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Persons } from "../../models/classroom/person.model";

@Injectable({
    providedIn: 'root'
})

export class SearchPersonsService {
  baseUrl = `${environment.classroomApiEndpoint}${environment.apiPaths.classroom.dict}`;

  constructor(private http: HttpClient) { }

  public getAllPersons(): Observable<Persons[]> {
    return this.http.get<Persons[]>(this.baseUrl  + '/persons')      
      .pipe(
        catchError(this.handleError));
  }
  
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
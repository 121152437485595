import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TemplateFormDto, TemplateList} from '../../models/mfc/templates/template.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  ApplicationConstructorStatus,
  constructorStatuses
} from '../../models/mfc/applicationConstructor/application-constructor-statuses.model';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.commonApplicationConstructor}/`;

  constructor(private http: HttpClient) { }

  private mapTemplate(template: TemplateList & TemplateFormDto): TemplateList & TemplateFormDto {
    const status = constructorStatuses.find((item) =>
      item.enum === template.applicationConstructorStatus) ?? <ApplicationConstructorStatus>{};
    return {...template, status};
  }

  public getTemplates(): Observable<TemplateList[]> {
    return this.http.get<TemplateList[]>(`${this.baseUrl}Template`)
      .pipe(map((t) => t.map((item) =>
        this.mapTemplate(item as TemplateList & TemplateFormDto)) ?? []));
  }

  public getTemplate(id: string): Observable<TemplateFormDto> {
    return this.http.get<TemplateFormDto>(`${this.baseUrl}${id}/Template`)
      .pipe(map((t) => this.mapTemplate(t as TemplateList & TemplateFormDto)));
  }
}

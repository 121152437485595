import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { DictSportCategory } from 'src/app/models/contingent/dictsportcategory.model';

@Injectable({
  providedIn: 'root'
})
export class DictsportcategoryService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.dictsportcategory}`;

  constructor(private http: HttpClient) { }

  //Get all SportCategory
  public getAllSportCategory(): Observable<DictSportCategory[]> {
    return this.http.get<DictSportCategory[]>(this.baseUrl);
  }

  //Add SportCategory
  addSportCategory(dto: DictSportCategory): Observable<DictSportCategory> {
    return this.http.post<DictSportCategory>(this.baseUrl, dto)
      .pipe(catchError(this.handleError));
  }

  //Delete SportCategory
  deleteSportCategory(id: string): Observable<DictSportCategory> {
    return this.http.delete<DictSportCategory>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit SportCategory
  updateSportCategory(dto: DictSportCategory): Observable<DictSportCategory> {
    return this.http.put<DictSportCategory>(this.baseUrl + '/' + dto.dictSportCategoryExternalId, dto)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}
}

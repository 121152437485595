import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { Role } from '../models/useraccess/role';
import { TokenStorageService } from '../services/token.service';
import {map} from "rxjs/operators";
import {MiddleControlUserAccessService} from "../services/useraccess/middle-control-user-access.service";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SessionRoleGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: MiddleControlUserAccessService,
    private location: Location,
    private router: Router
    ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;
      // Admin accesses page without any restrictions
      if (roles?.includes(Role.Admin))
        return true;
      // Check if user has session role
      if (roles?.includes(Role.Session)) {
        return this.userAccessService.getAccessLevel().pipe(
            map(value => {
              const signatory = value.localSignatoryAccessLevel;
              const dictAccessLevel = value.dictAccessLevel;
              // Signatory case
              if (path === 'middlecontrol/signatory') {
                return signatory;
              }
              // Dictionaries case
              if(path?.includes('dict')) {
                  return dictAccessLevel;
              }
              return true;
            }))
      }
    }
    alert('Недостаточно прав для доступа на эту страницу');
    localStorage.setItem('last_url', this.location.path())
    this.router.navigate([`/profile`]);
    return false;
  }
}

<gia-settings></gia-settings>
<h1>Настройки списка подписантов</h1>

<div class="grid-container">
  <kendo-grid
    [data]="gridView"
    (remove)="removeHandler($event)"
    (cellClick)="editHandler($event)"
    (add)="addHandler($event)"
    [loading]="loading"
    [pageable]="pagerSettings"
    [pageSize]="pageSizes[0]"
    [sortable]="true"
    [filterable]="true"
    [filter]="filter"
    (filterChange)="filterChange($event)"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>
    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button
        kendoGridAddCommand
        themeColor="success"
        icon="plus"
        *ngIf="!isInEditingMode"
      >
        Добавить
      </button>
      <div *ngIf="isInEditingMode">
        <button kendoButton (click)="closeEditor()">Отмена</button>
        <button
          kendoButton
          themeColor="primary"
          [disabled]="formGroup!.invalid"
          (click)="saveCurrent()"
        >
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column
      field="dictSignatoryRoleId"
      title="Категория"
      headerClass="gridHeader dictionaries"
      [width]="300"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="dictSignatoryRoles"
          gridField="dictSignatoryRoleId"
          textField="name"
          valueField="id"
        ></filter-dropdown>
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup"
      >
        <kendo-combobox
          textField="name"
          valueField="id"
          [kendoDropDownFilter]="filterSettings"
          [data]="dictSignatoryRoles"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('dictSignatoryRoleId')"
        ></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getSignatoryRoleName(dataItem.dictSignatoryRoleId) }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="filials"
      title="Филиалы"
      headerClass="gridHeader dictionaries"
      [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="filials"
          gridField="filials"
          textField="shortName"
          valueField="id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getList(dataItem.filials, "shortName") }}
        </span>
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup"
      >
        <kendo-multiselect
          kendoMultiSelectSummaryTag
          textField="shortName"
          valueField="id"
          [data]="filials"
          [valuePrimitive]="true"
          [(ngModel)]="filialsEdit"
          [kendoDropDownFilter]="filterSettings"
          [formControl]="formGroup.get('filials')"
          (valueChange)="valueChange($event, 'filialsEdit')"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span>
            {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="trainingLevels"
      title="Уровень подготовки"
      headerClass="gridHeader dictionaries"
      [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="trainingLevels"
          gridField="trainingLevels"
          textField="name"
          valueField="id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getList(dataItem.trainingLevels, "name") }}
        </span>
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup"
      >
        <kendo-multiselect
          kendoMultiSelectSummaryTag
          textField="name"
          valueField="id"
          [data]="trainingLevels"
          [valuePrimitive]="true"
          [(ngModel)]="trainingLevelsEdit"
          [kendoDropDownFilter]="filterSettings"
          [formControl]="formGroup.get('trainingLevels')"
          (valueChange)="valueChange($event, 'trainingLevelsEdit')"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span>
            {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="dictLocalSignatoryRoleId"
      title="Роль"
      [width]="250"
      headerClass="gridHeader dictionaries"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="localSignatoryRoles"
          gridField="dictLocalSignatoryRoleId"
          textField="name"
          valueField="id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          textField="name"
          valueField="id"
          [kendoDropDownFilter]="filterSettings"
          [data]="localSignatoryRoles"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('dictLocalSignatoryRoleId')"
        ></kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getLocalSignatoryRoleName(dataItem.dictLocalSignatoryRoleId) }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="departments"
      title="Структурное подразделение"
      headerClass="gridHeader dictionaries"
      [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="departments"
          gridField="departments"
          textField="shortName"
          valueField="id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getList(dataItem.departments, "shortName") }}
        </span>
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup"
      >
        <kendo-multiselect
          kendoMultiSelectSummaryTag
          textField="shortName"
          valueField="id"
          [data]="departments"
          [valuePrimitive]="true"
          [(ngModel)]="departmentsEdit"
          [kendoDropDownFilter]="filterSettings"
          [formControl]="formGroup.get('departments')"
          (valueChange)="valueChange($event, 'departmentsEdit')"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span>
            {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sections"
      title="Раздел"
      headerClass="gridHeader dictionaries"
      [width]="250"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown
          [filter]="filter"
          [data]="sections"
          gridField="sections"
          textField="name"
          valueField="id"
        ></filter-dropdown>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment">
          {{ getList(dataItem.sections, "name") }}
        </span>
      </ng-template>
      <ng-template
        kendoGridEditTemplate
        let-dataItem="dataItem"
        let-formGroup="formGroup"
      >
        <kendo-multiselect
          kendoMultiSelectSummaryTag
          textField="name"
          valueField="id"
          [data]="sections"
          [valuePrimitive]="true"
          [(ngModel)]="sectionsEdit"
          [kendoDropDownFilter]="filterSettings"
          [formControl]="formGroup.get('sections')"
          (valueChange)="valueChange($event, 'sectionsEdit')"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span>
            {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title=""
      [width]="60"
      *ngIf="editable"
      headerClass="gridHeader dictionaries"
    >
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button
            kendoGridRemoveCommand
            themeColor="secondary"
            icon="delete"
            fillMode="flat"
            size="none"
            class="custom-size"
            rounded="full"
            style="text-overflow: unset"
          ></button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

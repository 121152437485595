<div class="selectContent w-900 m-t20">
    <dd class="list">
      <span class="selectText">Уровень подготовки</span>
      <div class="w-700">
        <kendo-multiselect
            class="w-300"
            kendoMultiSelectSummaryTag
            [autoClose]="false"
            [data]="trainingLevel"
            textField="name"
            valueField="externalId"
            [valuePrimitive]="true"
            (valueChange)="onChangeLevel($event)"
            (blur)="blur()"
            [(ngModel)]="trainingLevelList"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>
              {{ getValue(dataItems) }}
            </span>
          </ng-template>
        </kendo-multiselect>
      </div>
    </dd>

    <dd class="list">
      <span class="selectText">Год выдачи</span>
      <div class="w-700">
        <kendo-dropdownlist class="w-300"
            [data]="years"
            (valueChange)="onChangeYear($event)"
            [(ngModel)]="year"
        >
        </kendo-dropdownlist>
      </div>
    </dd>

    <dd class="list">
      <span class="selectText">Тип бланка</span>
      <div class="w-700">
        <kendo-multiselect
            class="w-300"
            kendoMultiSelectSummaryTag
            [autoClose]="false"
            [data]="blankTypes"
            textField="name"
            valueField="externalId"
            [valuePrimitive]="true"
            (valueChange)="onChangeType($event)"
            (blur)="blur()"
            [(ngModel)]="blankTypeList"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>
              {{ getValue(dataItems) }}
            </span>
          </ng-template>
        </kendo-multiselect>
      </div>
    </dd>
</div>
<p class="parameters normal m-l200">Бланков найдено: {{ findBlanks }}</p>

<div class="m-t20" [ngClass]="{buttons: editable}" *ngIf="!toReturn">
    <button kendoButton themeColor="primary" (click)="returnBlanks()" *ngIf="editable">Вернуть бланки</button>
    <div [ngClass]="{'buttons, rightAlign': !editable}">
        <button kendoButton icon="filter-clear" (click)="clearFilters()">Сбросить фильтр</button>
        <button kendoButton class="m-l15" icon="download" (click)="report()">Отчет</button>
    </div>
</div>
<div class="m-t20" *ngIf="toReturn">
    <button kendoButton (click)="cancelReturnBlanks()">Отмена</button>
    <button kendoButton themeColor="primary"
            [disabled]="checkedBlanks.length == 0"
            class="m-l15"
            (click)="return()"
            >
      Вернуть
    </button>
</div>

<kendo-grid
    class="m-t20"
    [kendoGridBinding]="toReturn ? dataReturnBlanks : givenBlanks"
    [loading]="loading"
    [sortable]="true"
    [pageSize]="pageSize"
    [pageable]="pagerSettings"
    [filterable]="true"
    [filter]="filter"
    (filterChange)="filterChange($event)"
    (pageChange)="pageChange($event)"
    (cellClick)="editStatus($event)"
    >
    <ng-template kendoGridLoadingTemplate>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>

    <kendo-grid-column [width]="80" *ngIf="!toReturn"
                       title="№"
                       [filterable]="false"
                       [sortable]="false"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <span class="alignCells">
            {{ rowIndex + 1}}
          </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="toReturn"
          [width]="80"
          [resizable]="false"
          headerClass="gridHeader dictionaries">
        <ng-template kendoGridHeaderTemplate>
          <div headerClass="gridHeader">
                <input
                type="checkbox"
                kendoCheckBox
                [checked]="allItemsChecked"
                (click)="selectAll(allItemsChecked)"
                />
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="alignCells">
                <input type="checkbox" kendoCheckBox
                [checked]="checked(dataItem.id)"
                (change)="checkedChange(dataItem.id)"
                />
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="series"
                       [width]="150"
                       title="Серия"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="blankSeries" textField="series" valueField="series">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.series }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="number"
                       [width]="150"
                       title="Номер"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.number }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="blankTemplateName"
                       [width]="220"
                       title="Шаблон"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="blankTemplates" textField="blankTemplateName" valueField="blankTemplateName">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.blankTemplateName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="issueDate"
                       [width]="300"
                       title="Дата выдачи"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <date-range-filter [field]="column.field"
                             [filter]="filter">
          </date-range-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.issueDate | date:'dd.MM.yyyy HH:mm' }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="recipientPersonFioInitialsAfter"
                       [width]="220"
                       title="Ответственный"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-combobox [useVirtual]='false' [filter]="filter" [data]="recipientPerson" textField="recipientPersonFioInitialsAfter" valueField="recipientPersonFioInitialsAfter">
          </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                {{ dataItem.recipientPersonFioInitialsAfter }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="blankStatusName"
                       [width]="220"
                       title="Статус"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="blankStatuses" textField="blankStatusName" valueField="blankStatusName">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells" [ngStyle]="{'color': dataItem.blankStatusColor}">
                {{ dataItem.blankStatusName }}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <kendo-dropdownlist
            kendoGridFocusable
            [data]="getStatus(dataItem)"
            textField="name"
            valueField="externalId"
            (valueChange)="changeStatus($event, dataItem)"
            [valuePrimitive]="true"
            [(ngModel)]="status"
          >
          </kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column-group headerClass="gridHeader dictionaries" title="Дипломы">
        <kendo-grid-column field="diplomaRegNumber"
                    title="Рег. номер"
                    [width]="120"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <kendo-grid-string-filter-cell [showOperators]="false" [column]="column" [filter]="filter" operator="contains">
                    <kendo-filter-contains-operator></kendo-filter-contains-operator>
                </kendo-grid-string-filter-cell>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.diplomaRegNumber }}
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="graduateYear"
                title="Год выпуска"
                [width]="150"
                headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="graduateYears" textField="graduateYear" valueField="graduateYear">
              </filter-dropdown>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.graduateYear }}
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="diplomaIssueDate"
                    title="Дата выдачи"
                    [width]="180"
                    filter="date" format="dd.MM.yyyy"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ dataItem.diplomaIssueDate | date:'dd.MM.yyyy' }}
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="isDuplicate"
                    title="Статус"
                    [width]="150"
                    headerClass="gridHeader dictionaries">
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <filter-dropdown [useVirtual]='false' [filter]="filter" [data]="isDuplicate" textField="text" valueField="isDuplicate">
                </filter-dropdown>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem >
                <span class="alignCells">
                    {{ getValueDuplicate(dataItem.isDuplicate) }}
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>

</kendo-grid>

<kendo-dialog *ngIf="opened"
              title="Пожалуйста, подтвердите"
              (close)="close('cancel')"
              [minWidth]="250"
              [width]="450">
  <p class="m-l15">Вернуть выбранные бланки?</p>
  <kendo-dialog-actions>
    <button kendoButton (click)="close('no')">Нет</button>
    <button kendoButton (click)="close('yes')" themeColor="primary">Да</button>
  </kendo-dialog-actions>
</kendo-dialog>

<div kendoDialogContainer></div>

import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {AvailableModule, Regulation, SimpleModuleEnum} from "../../models/oferta/regulation/regulation.model";
import {SimpleModulesEnum} from "../../models/oferta/enums/simpleModuleEnum.enum";
import {isArray, isString} from "lodash";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/errorHandle-helper";

@Injectable({
  providedIn: 'root'
})
export class RegulationService {
  public reinforcedServiceUrls = new BehaviorSubject<string[]|null>(null);
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.oferta.regulation}`;

  constructor(private http: HttpClient) { }

  public getSimpleModules(): Observable<SimpleModuleEnum[]> {
    return this.http.get<SimpleModuleEnum[]>(this.baseUrl + '/SimpleModules');
  }

  private getFormData(body: Regulation) {
    const data = new FormData();

    Object.entries(body).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((item, i) => {
          if (isString(item)) {
            data.append(`${key}[${i}]`, item);
          } else {
            Object.entries<string | File>(item).forEach(([itemKey, itemValue]) => {
              if (itemValue instanceof File) {
                data.append(`files[${i}].file`, itemValue, itemValue.name);
              } else {
                data.append(`${key}[${i}].${itemKey}`, itemValue);
              }
            });
          }
        });
      } else {
        data.append(key, value);
      }
    });

    return data;
  }

  public getAllLicenses(): Observable<AvailableModule[]> {
    return this.http.get<AvailableModule[]>(this.baseUrl + "/GetRegilationAvailableServices");
  }

  public getRegulation(dictRegulationTypeId: string, simpleModuleEnum: SimpleModulesEnum) : Observable<Regulation> {
    let params = new HttpParams();
    params = params.append('dictRegulationTypeId', dictRegulationTypeId);
    params = params.append('simpleModuleEnum', simpleModuleEnum);
    return this.http.get<Regulation>(`${this.baseUrl}/GetByEnumAndDictId`, {params});
  }

  public saveRegulation(regulation: Regulation): Observable<Regulation> {
    return this.http.put<Regulation>(this.baseUrl, this.getFormData(regulation));
  }

  public getAllReinforcedModulesUrl(): Observable<string[]|null>{
    return this.http.get<string[]|null>(`${this.baseUrl}/GetAllReinforcedModulesUrl`)
      .pipe(catchError(handleError));
  }
}

import {Component, OnInit} from "@angular/core";
import {UserSignatureService} from "../../../services/oferta/user-signature.service";
import {RegulationFileService} from "../../../services/oferta/regulationFile.service";
import {RegulationService} from "../../../services/oferta/regulation.service";
import {DictRegulationTypeService} from "../../../services/oferta/dictRegulationType.service";
import {SignatureTypeEnum} from "../../../models/oferta/enums/dictSignatureType.enum";
import {Regulation} from "../../../models/oferta/regulation/regulation.model";
import {SimpleModulesEnum} from "../../../models/oferta/enums/simpleModuleEnum.enum";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {saveAs} from "@progress/kendo-file-saver";
import {GetRegulationFile} from "../../../models/oferta/regulationFile/getRegulationFile.model";
import {Router} from "@angular/router";

@Component({
  selector: 'joining-person-to-reinforced-regulation',
  templateUrl: './joining-person-to-reinforced-regulation.component.html',
  styleUrls: ['./joining-person-to-reinforced-regulation.component.scss']
})
export class JoiningPersonToReinforcedRegulationComponent implements OnInit {

  public regulation?: Regulation = undefined;
  public reinforcedInfoVisible: boolean = false;
  public abortVisible: boolean = false;

  constructor(
    private notificationsService: NotificationsService,
    private userSignatureService: UserSignatureService,
    private regulationService: RegulationService,
    private regulationTypeService: DictRegulationTypeService,
    private regulationFileService: RegulationFileService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getRegulation();
  }

  private getRegulation() {
    this.regulationTypeService.getAllRegulationTypes()
      .subscribe(
        response => {
          let simpleRegulationType = response.find(a => a.userSignatureTypeEnum == SignatureTypeEnum.Reinforced);
          if (simpleRegulationType)
          {
            this.regulationService.getRegulation(simpleRegulationType.id, SimpleModulesEnum.LKP)
              .subscribe(
                response => {
                  this.regulation = response;
                },
                error => {
                  this.notificationsService.showError(error);
                }
              );
          }
        }
      );
  }

  public onAbort() {
    this.reinforcedInfoVisible = false;
    this.abortVisible = true;
  }

  public onCancel() {
    this.reinforcedInfoVisible = false;
    this.abortVisible = false;
  }

  public toProfile = () => {
    localStorage.removeItem('reinforced_path');
    this.router.navigate([`/profile`]);
  }

  public onConfirmation() {
    this.userSignatureService.joinPersonToRegulation({
      regulationId: this.regulation!.id,
    })
      .subscribe(response => {
        const nextPath =localStorage.getItem('reinforced_path')
        this.router.navigate([nextPath]);
        localStorage.removeItem('reinforced_path');
      });
  }

  public joinPersonToReinforcedRegulation() {
    this.reinforcedInfoVisible = true;
  }

  private getFileObserver(fileName?: string) {
    return {
      next: (response: Blob) => fileName && saveAs(response, fileName),
      error: (error: unknown) => this.notificationsService.showError(error)
    };
  }

  public getRegulationFile(file?: GetRegulationFile) {
    file && this.regulationFileService.getRegulationFile(file.id)
      .subscribe(this.getFileObserver(file.fileName));
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {DictEnlargedGroupSpecialization} from "../../../models/education/dictEnlargedGroupSpecialization.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {
  DictEnlargedGroupSpecializationService
} from "../../../services/education/DictEnlargedGroupSpecialization/dict-enlarged-group-specialization.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {StandardtypeService} from "../../../services/education/StandardType/standardtype.service";
import {StandardType} from "../../../models/education/standardtype.model";
import {SortDescriptor} from "@progress/kendo-data-query";

@Component({
  selector: 'dict-enlarged-group-specialization',
  templateUrl: './dict-enlarged-group-specialization.component.html',
  styleUrls: ['./dict-enlarged-group-specialization.component.scss']
})
export class DictEnlargedGroupSpecializationComponent implements OnInit {

  public isOpened: boolean = false;
  public loading: boolean = false;
  public editable?: boolean;
  @Input() public isNew = false;

  public enlargedGroupSpecializations: DictEnlargedGroupSpecialization[] = [];
  public standardTypes: StandardType[] = [];

  // Edit Form
  public editForm: FormGroup = new FormGroup({
    externalId: new FormControl(""),
    dictStandardTypeId: new FormControl(""),
    name: new FormControl(""),
    shortName: new FormControl(""),
    cipher: new FormControl("")
  });

  constructor(private dictEnlargedGroupSpecializationService: DictEnlargedGroupSpecializationService,
              private notificationService: NotificationsService,
              private standardTypeService: StandardtypeService) {
  }

  ngOnInit(): void {
    this.getStandardTypes();
    this.getEnlargedGroupSpecializations();
  }

  public onCancel() {
    this.isOpened = false;
    this.editForm.reset();
  }

  private getStandardTypes() {
    this.standardTypeService.getAllStandardTypes().subscribe({
      next: (response) => {
        this.standardTypes = response;
      }
    })
  }

  private getEnlargedGroupSpecializations() {
    this.dictEnlargedGroupSpecializationService.getEnlargedGroupSpecialization().subscribe({
      next: (response) => {
        this.enlargedGroupSpecializations = response;
      }
    })
  }

  public addFormNavigate() {
    this.isNew = true;
    this.isOpened = true;
  }

  public editFormNavigate(event: any) {
    this.isOpened = true;
    this.isNew = false;
    this.editForm = formGroup(event.dataItem);
  }

  public saveHandler() {
    if (this.isNew) {
      this.dictEnlargedGroupSpecializationService.addEnlargedGroupSpecialization(this.editForm.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Добавлено");
              this.onCancel();
              this.getEnlargedGroupSpecializations();
            },
            error: () => {
              this.notificationService.showError("Произошла ошибка");
              this.isOpened = false;
            }
          });
    } else {
      this.dictEnlargedGroupSpecializationService.updateEnlargedGroupSpecialization(this.editForm.value)
          .subscribe({
            next: () => {
              this.notificationService.showSuccess("Сохранено");
              this.onCancel();
              this.getEnlargedGroupSpecializations();
            },
            error: () => {
              this.notificationService.showError("Произошла ошибка");
              this.isOpened = false;
            }
          });
    }
  }

  public sort: SortDescriptor[] = [
    {
      field: "cipher",
      dir: "asc",
    },
  ];

}

const formGroup = (dataItem: {
  dictStandardTypeId?: any;
  name?: any;
  cipher?: any;
  externalId?: any;
}) =>
    new FormGroup({
      externalId: new FormControl(dataItem.externalId),
      dictStandardTypeId: new FormControl(dataItem.dictStandardTypeId, Validators.required),
      name: new FormControl(dataItem.name, Validators.required),
      cipher: new FormControl(dataItem.cipher, Validators.required),
    });
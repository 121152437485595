import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {lastValueFrom} from 'rxjs';
import {Role} from '../models/useraccess/role';
import {TokenStorageService} from '../services/token.service';
import {Location} from '@angular/common';
import {AccessRights} from '../models/mfc/enums/access-rights.enum';
import {UserAccessService} from '../services/mfc/userAccess.service';
import {DictCreatorService} from "../services/mfc/dict-creator.service";

@Injectable({
  providedIn: 'root'
})
export class MfcRoleGuard implements CanActivate {
  constructor(
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
    private userAccessService: UserAccessService,
    private dictCreatorService: DictCreatorService,
    private location: Location,
    private router: Router) {
  }

  private returnValue(value: boolean, ): boolean {
    if (!value) {
      alert('Недостаточно прав для доступа на эту страницу');
      localStorage.setItem('last_url', this.location.path());
      this.router.navigate([`/profile`]).then();
    }
    return value;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise <boolean> {
    const token = this.tokenStore.getAuthToken();
    const path = route.routeConfig?.path;

    // Checking if token is not null
    if (token !== null) {
      const user = this.jwtHelper.decodeToken(token!);
      const roles = user?.role;

      if (!this.userAccessService.currentUserAccess$.value) {
        await lastValueFrom(this.userAccessService.getCurrentUserAccess()).then(value => {
          this.userAccessService.currentUserAccess$.next(value);
        });
      }

      if (!this.dictCreatorService.dicts$.value.length) {
        await lastValueFrom(this.dictCreatorService.getAllDicts());
      }

      // Check if user has mfc or admin role
      if (roles?.includes(Role.Admin) || roles?.includes(Role.MFC)) {
        const accessRights = this.userAccessService.currentUserAccess$.value?.user.userAccessMainSettings;
        // Application access
        if (path?.includes('mfc/applicationForm')) {
          return this.returnValue(accessRights?.applicationAccess !== AccessRights.No);
        }
        // Report access
        if (path === 'mfc/reports') {
          return this.returnValue(!!accessRights?.isReportAvailable);
        }
        // Stamp access
        if (path?.includes('mfc/stamp')) {
          return this.returnValue(!!accessRights?.isSignStampSettingsAvailable);
        }
        // Application constructor access
        if (path?.includes('mfc/applicationConstructor')) {
          return this.returnValue(!!accessRights?.isApplicationConstructorAvailable);
        }
        // Templates access
        if (path?.includes('mfc/templates')) {
          return this.returnValue(!!accessRights?.isApplicationConstructorTemplateAvailable);
        }
        // Dicts creator access
        if (path === 'mfc/dictCreator') {
          return this.returnValue(!!accessRights?.isDictCreateAvailable);
        }
        // Dicts access
        if (path?.includes('mfc/dict')) {
          return this.returnValue(!!accessRights?.isDictAvailable);
        }

        return this.returnValue(true);
      }
    }
    return this.returnValue(false);
  }
}

import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {AddEvent, EditEvent, GridComponent, PageChangeEvent, PagerSettings, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Person} from "../../../models/publications/person.model";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {dialogResultHelper, openRemoveDialog} from "../../../helpers/dialogHelper";
import {AccessRights, PublicationsUserAccess} from "../../../models/useraccess/publications/publicationsUserAccess";
import {isChildOf} from "../../../helpers/elementRef-helper";
import {PublicationsUserAccessService} from "../../../services/useraccess/publications-user-access.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {boolOptions, dropdownOptions} from "../../../models/useraccess/options";
import {PublicationsPersonService} from "../../../services/science/publications/person.service";
import {getData$} from "../../../../environments/environment";
import {dictPagerSettings} from "../../../models/dicts/pagerSettings.model";

@Component({
  selector: 'publications-access',
  templateUrl: './publications-access.component.html',
  styleUrls: ['./publications-access.component.scss']
})
export class PublicationsAccessComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;

  public userAccesses: PublicationsUserAccess[] = [];
  public persons: Person[] = [];

  public pagerSettings: PagerSettings = dictPagerSettings;
  public pageSize: number = 10;
  public pageNumber: number =  0;
  public skip: number = 0;

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public virtual: any = {
    itemHeight: 28,
  };

  public boolOptions = boolOptions;
  public dropdownOptions = dropdownOptions;
  private editedRowIndex: number | undefined;
  private isNew: boolean = false;
  private isLine: boolean = false;
  public searchFIO: string = '';
  public formGroup: FormGroup = this.resetFormGroup();

  constructor(
    private dialogService: DialogService,
    private userAccessService: PublicationsUserAccessService,
    private personService: PublicationsPersonService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    getData$.subscribe(() => this.getAccesses());
    this.getPersons();
  }

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  private resetFormGroup(): FormGroup {
    return new FormGroup({
      id: new FormControl(''),
      personId: new FormControl('', Validators.required),
      personName: new FormControl(''),
      report: new FormControl(false, Validators.required),
      myPublication: new FormControl(false, Validators.required),
      allPublication: new FormControl(AccessRights.Read, Validators.required),
      author: new FormControl(AccessRights.Read, Validators.required),
      dict: new FormControl(AccessRights.Read, Validators.required),
      accessSettings: new FormControl(false, Validators.required)
    });
  }

  private getAccesses(fio?: string) {
    this.userAccessService.getAccess(fio).subscribe((value) => {
      this.userAccesses = value;
      this.skip = 0;
    });
  }

  private getPersons() {
    this.personService.getPersons().subscribe((value) => {
      this.persons = value;
      this.persons.forEach((item) => {
        item.fio = item.lastName + ' ' + item.firstName + ' ' + item.middleName;
      });
    });
  }

  public getPersonName(dataItem: PublicationsUserAccess) {
    return this.persons.find((item) => item.id === dataItem.personId)?.fio;
  }

  //Start adding
  public addHandler({sender}: AddEvent): void {
    this.isNew = true;
    this.isLine = true;
    sender.addRow(this.formGroup);
  }

  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = this.resetFormGroup();
  }

  public editHandler({sender, rowIndex, dataItem}: EditEvent): void  {
    this.formGroup = new FormGroup({
      id: new FormControl(dataItem.id ?? ''),
      personId: new FormControl(dataItem.personId, Validators.required),
      personName: new FormControl(dataItem.personName),
      report: new FormControl(dataItem.report, Validators.required),
      myPublication: new FormControl(dataItem.myPublication, Validators.required),
      allPublication: new FormControl(dataItem.allPublication, Validators.required),
      author: new FormControl(dataItem.author, Validators.required),
      dict: new FormControl(dataItem.dict, Validators.required),
      accessSettings: new FormControl(dataItem.accessSettings, Validators.required)
    });

    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      return;
    }

    this.isLine = true;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup);
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const personName = this.getPersonName(dataItem);
    const dialog: DialogRef = openRemoveDialog(this.dialogService, `${personName} из настроек доступа`);
    dialogResultHelper(dialog.result, this.userAccessService.deleteAccess(dataItem.id), this.notificationsService);
  }

  public onSave() {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      this.formGroup.value.id === ''
          ? this.userAccessService.createAccess(this.formGroup.value)
          : this.userAccessService.updateAccess(this.formGroup.value);
      this.cancelHandler();
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

  public clearData() {
    this.searchFIO = '';
    this.filterData(this.searchFIO);
  }

  public filterData(fio: string) {
    this.getAccesses(fio);
  }

  public async pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNumber = event.skip / event.take;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {
  EducationPlan, GroupEducationPlanList, PlanJsonData,
  StudentEducationPlanList
} from 'src/app/models/contingent/educationplan.model';
import {EducationProgram, EducationProgramName} from 'src/app/models/contingent/educationprogram.model';
import { EducationStandard } from 'src/app/models/contingent/educationstandard.model';
import {DictState} from "../../models/contingent/dictstate.model";
import {SpravkaTypes} from "../../models/contingent/spravkatypes.model";
import {Guid} from "guid-typescript";


@Injectable({
  providedIn: 'root'
})
export class EducationService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.educations}`;

  constructor(private http: HttpClient) { }

  //Get all plan
  public getAllEducationPlans(): Observable<EducationPlan[]> {
    return this.http.get<EducationPlan[]>(this.baseUrl + '/GetAllEducationPlan');
  }

  public getEducationPlanByStudent(id: string): Observable<StudentEducationPlanList[]> {
    return this.http.get<StudentEducationPlanList[]>(this.baseUrl + '/GetEducationPlanByStudent' + "/" + id);
  }

  public getEducationPlanByGroup(id: string): Observable<GroupEducationPlanList[]> {
    return this.http.get<GroupEducationPlanList[]>(this.baseUrl + '/GetEducationPlanByGroup' + "/" + id);
  }

  public async getPlanJsonData(id: Guid): Promise<Observable<PlanJsonData>> {
    return this.http.get<PlanJsonData>(this.baseUrl + '/GetPlanJsonData' + "/" + id);
  }

  public getEducationPlans(id: string): Observable<EducationPlan> {
    return this.http.get<EducationPlan>(this.baseUrl + '/GetEducationPlan/' + id)
      .pipe(catchError(this.handleError));
  }

    //Get all filial
  public getAllEducationProgram(): Observable<EducationProgram[]> {
    return this.http.get<EducationProgram[]>(this.baseUrl + '/GetAllEducationProgram');
  }

  public getEducationProgramByStandard(id: string): Observable<EducationProgramName[]> {
    return this.http.get<EducationProgramName[]>(this.baseUrl + '/GetEducationProgramByStandard/' + id)
      .pipe(catchError(this.handleError));
  }

  public getAllEducationProgramName(): Observable<EducationProgramName[]> {
    return this.http.get<EducationProgramName[]>(this.baseUrl + '/GetAllEducationProgramName');
  }

    //Get all filial
  public getAllEducationStandard(): Observable<EducationStandard[]> {
    return this.http.get<EducationStandard[]>(this.baseUrl + '/GetAllEducationStandard');
  }

  checkStudentsLastCourse(request: any): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + "/CheckStudentsLastCourse", request)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
}

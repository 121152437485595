import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {CellClickEvent, ColumnComponent, GridDataResult, PageChangeEvent, PagerSettings} from "@progress/kendo-angular-grid";
import {dictPagerSettings} from "../../../../../models/dicts/pagerSettings.model";
import {Property, Publication} from "../../../../../models/publications/mainPage/publication.model";
import {PublicationsService} from "../../../../../services/science/publications/publications.service";
import {StatusEnum, Statuses} from "../../../../../models/publications/statuses.model";
import {DictPublicationType} from "../../../../../models/publications/dict/publicationType.model";
import {DictPublicationSubType} from "../../../../../models/publications/dict/publicationSubType.model";
import {DictScientometricBase} from "../../../../../models/publications/dict/scientometricBase.model";
import {DictStructureSubdivision} from "../../../../../models/publications/dict/structureSubdivision.model";
import {TypesService} from "../../../../../services/science/publications/types.service";
import {SubTypeService} from "../../../../../services/science/publications/subTypes.service";
import {ScientometricBasesService} from "../../../../../services/science/publications/scientometric-bases.service";
import {StructureSubdivisionsService} from "../../../../../services/science/publications/structureSubdivisions.service";
import {DateToString, getDateDiff} from "../../../../../helpers/date-helper";
import {SelectEvent} from "@progress/kendo-angular-layout";
import {Router} from "@angular/router";
import {NotificationService} from "../../../../../services/science/publications/notification.service";
import {PersonService} from "../../../../../services/person/lkperson.service";
import {lastValueFrom} from "rxjs";
import {PublicationsReportsService} from "../../../../../services/science/publications/reports.service";
import {getErrorMessage} from "../../../../../helpers/errorHandle-helper";
import {NotificationsService} from "../../../../../services/notifications/notifications.service";
import {DownloadFile} from "../../../../../helpers/downloadFile-helper";
import {PublicationsFileService} from "../../../../../services/science/publications/file.service";
import {Codes, Properties} from "../../../../../models/publications/mainPage/mainPage.enums";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../../helpers/dialogHelper";
import {PublicationsUserAccessService} from "../../../../../services/useraccess/publications-user-access.service";
import {AccessRights} from "../../../../../models/useraccess/publications/publicationsUserAccess";
import {FormControl, FormGroup} from "@angular/forms";
import {PublicationEditFormTabs} from "../../../../../models/publications/editForm/editForm.enums";
import {PublicationsEditFormService} from "../../../../../services/science/publications/editForm.service";
import {isArray, isDate, isNumber} from "lodash";
import {DateInputFormatPlaceholder} from '@progress/kendo-angular-dateinputs/dateinput/models/format-placeholder.model';

@Component({
  selector: 'publications-home',
  templateUrl: './publications-home.component.html',
  styleUrls: ['./publications-home.component.scss']
})
export class PublicationsHomeComponent implements OnInit {

  @Input() public filterService: any;

  public pagerSettings: PagerSettings = dictPagerSettings;
  public pageSize: number = 10;
  public pageNumber: number =  0;
  public skip: number = 0;
  public loading: boolean = false;

  public IsSummaryInfo: boolean = false;
  private userName: string = "";
  public authorId!: string;

  public gridData!: GridDataResult;

  public filteredData: any[] = [];
  public publications: Publication[] = [];
  public types: DictPublicationType[] = [];
  public subTypes: DictPublicationSubType[] = [];
  public scientometricBases: DictScientometricBase[] = [];
  public subdivisions: DictStructureSubdivision[] = [];
  public statuses = Statuses;
  public years = this.getYears();
  public myPublicationsAlertCount: number = 0;
  public allPublicationsAlertCount: number = 0;

  public form16 = {
    periodBegin: null,
    periodEnd: null
  }

  public fileUploadRestrictions = {
    extensions: '',
    maxFileSize: 0,
    maxFileSizeInMb: 0
  };

  public codeColumns: any[] = [];
  public filters = {
    name: '',
    source: '',
    authors: '',
    categories: '',
    addedBy: '',
    status: [] as string[],
    departments: '',
    year: null,
    type: [] as string[],
    subType: [] as string[],
    createdAt: null
  }
  public DOI: string = '';
  public MPK: string = '';
  public ISBN: string = '';

  public showSource: boolean = true;

  public filterCodesGroup: FormGroup = new FormGroup({});

  public selectedTab: string = 'Мои';

  public allPublicationUserAccessLevel: number = this.userAccessService.currentUserAccess$.value.allPublication;
  public myPublicationUserAccess: boolean = this.userAccessService.currentUserAccess$.value.myPublication;

  public periodPlaceholder: DateInputFormatPlaceholder = {
    year: 'гггг',
    month: 'мм',
    day: 'дд',
    hour: 'чч',
    minute: 'мм',
    second: 'сс'
  }

  protected readonly DateToString = DateToString;
  protected readonly StatusEnum = StatusEnum;
  protected readonly AccessRights = AccessRights;

  @ViewChild('fileSelect') input!: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private personService: PersonService,
    private publicationsService: PublicationsService,
    private typesService: TypesService,
    private subTypesService: SubTypeService,
    private scientometricBasesService: ScientometricBasesService,
    private structureSubdivisionsService: StructureSubdivisionsService,
    private notificationService: NotificationService,
    private messageService: NotificationsService,
    private reportsService: PublicationsReportsService,
    private fileService: PublicationsFileService,
    private dialogService: DialogService,
    private editFormService: PublicationsEditFormService,
    private userAccessService: PublicationsUserAccessService
  ) { }

  async ngOnInit() {
    await this.getUserAccess();

    this.getFileRestrictions();

    await this.getCurrentAuthor();
    this.getNotification();

    this.getTypes();
    this.getSubTypes();
    this.getYears();
    this.getScientometricBases();
    this.getStructureSubdivisions();
  }

  private async applyFilters() {
    this.filters = this.publicationsService.filters$.value.filters;
    this.filterCodesGroup = this.publicationsService.filters$.value.codesGroup;
    this.ISBN = this.publicationsService.filters$.value.ISBN;
    this.DOI = this.publicationsService.filters$.value.DOI;
    this.MPK = this.publicationsService.filters$.value.MPK;

    await this.filterStaticColumns();
    await this.onTypeFilterChange(this.filters.type);
    this.filteredData = this.filterCodes(this.filteredData);
    this.gridData.data = this.filteredData;
  }

  public addPublication() {
    this.editFormService.currentTab$.next(PublicationEditFormTabs.MainInfo);
    this.router.navigate(['publications/newPublication']);
  }

  private async getCurrentAuthor() {
    await lastValueFrom(this.personService.getCurrentPerson())
      .then(response => {
        this.authorId = response.personExternalId;
        this.userName = response.fullName;
      });
  }

  private getNotification() {
    this.notificationService.getNotification().subscribe((value) => {
      this.myPublicationsAlertCount = value.unreadMyPublications;
      this.allPublicationsAlertCount = value.unreadAllPublications;
    });
  }

  public async pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.pageNumber = event.skip / event.take;
    await this.filterStaticColumns();
    this.gridData.data = this.filterCodes(this.filteredData);
  }

  private async getPublications(allPublications: boolean, filterBy?: any[]) {
    this.loading = true;
    await lastValueFrom(this.publicationsService.getPublications(
        {
          allPublication: allPublications && this.allPublicationUserAccessLevel !== AccessRights.No,
          properties: [
            Properties.CodesId, Properties.ConferenceCollectionId, Properties.JournalId
          ],
          page: {
            index: this.pageNumber,
            size: this.pageSize,
          },
          filterBy: filterBy
        }
    )).then(value => {
      this.publications = value.items;
      this.pageNumber = value.pageIndex;
      this.skip = (this.pageNumber) * this.pageSize;
      this.publications.forEach((item) => {
        this.handleProperties(item);
      });
      this.gridData = {
        data: this.publications,
        total: value.totalCount
      };
      this.filteredData = this.gridData.data;
      this.loading = false;
    },
      error => {
        this.loading = false;
        this.messageService.showError(error);
      });
  }

  private handleProperties(publication: Publication) {
    publication.codes = this.mapCodes(publication.properties?.find((property) => property.id === Properties.CodesId));
    publication.source = publication.properties?.find((property: Property) => property.id === Properties.ConferenceCollectionId
      || property.id === Properties.JournalId)?.value ?? '';
    return publication;
  }

  private mapCodes(property?: Property) {
    const codes: Publication["codes"] = [];
    if (property && property.toString) {
      const array: string[] = property.toString.split(/[ ,;]+/);

      for (let i = 0; i < array.length; i++) {
        codes.push({name: array[i], value: array[i + 1]});
        i++;
      }
    }
    return codes;
  }

  public findCodes(codes: [{name: string, value: string}], codeName: string) {
    const filteredCodes = codes.find((item) => item.name === codeName);
    return filteredCodes?.value;
  }

  private getTypes() {
    this.typesService.getTypes()
      .subscribe(value => this.types = value);
  }

  private getSubTypes() {
    this.subTypesService.getSubTypes()
      .subscribe(value => this.subTypes = value);
  }

  private getYears() {
    const startYear = 1933;
    const currentYear = new Date().getFullYear();
    return Array.from({length: currentYear - startYear}, (_, i) => currentYear - i);
  }

  private getScientometricBases() {
    this.scientometricBasesService.getScientometricBases()
      .subscribe(value => this.scientometricBases = value);
  }

  private getStructureSubdivisions() {
    this.structureSubdivisionsService.getStructureSubdivisions()
      .subscribe(value => this.subdivisions = value);
  }

  public getNotificationText(dataItem: Publication) {
    const dayCount = 14 - getDateDiff(dataItem.updatedAt, new Date(), "days")
    const dayTechName = dayCount === 1 ? "день" :
                        dayCount >= 2 && dayCount <= 4 ? "дня" :
                        "дней";

    const techWord = dayCount === 1 ? "Остался" : "Осталось";

    return `${techWord} ${dayCount} ${dayTechName}`;
  }

  public async onTabChange(source: SelectEvent) {
    this.selectedTab = source.title;

    if (!this.authorId) {
      source.preventDefault();
      return;
    }
    this.IsSummaryInfo = source.title === 'Моя сводная информация';
    this.pageNumber = 0;

    await this.identifyPublicationSection();
  }

  private async identifyPublicationSection() {
    await this.filterStaticColumns();
    this.gridData.data = this.filterCodes(this.filteredData);
  }

  public fileSelect() {
    this.input.nativeElement.click();
  }

  public onFileChange(event: any, dataItem: Publication) {
    let formData = new FormData();
    let file: File = event.target.files[0];
    if (file.size > this.fileUploadRestrictions.maxFileSize)
      return this.messageService.showError(`Размер файла должен быть не больше ${this.fileUploadRestrictions.maxFileSizeInMb} МБ!`);

    formData.append(`files`, file, file.name);
    this.fileService.createPublicationFiles(dataItem.id, formData).subscribe({
      next: async () => {
        this.messageService.showSuccess('Успешно');
        await this.getPublications(this.selectedTab === 'Все');
      },
      error: (err) => {
        this.messageService.showError(err);
      }
    });
  }

  public isFileSelect(originalEvent: any): boolean {
    return originalEvent.target.querySelectorAll('input').length > 0;
  }

  public navigateToSelectedPublication({dataItem, originalEvent}: CellClickEvent): void {
    if (this.isFileSelect(originalEvent)) return;
    this.editFormService.currentTab$.next(PublicationEditFormTabs.MainInfo);
    this.router.navigate(['publications/newPublication', dataItem.id]);
  }

  public exportForm16() {
    if ((this.form16.periodBegin && this.form16.periodEnd) && (this.form16.periodBegin > this.form16.periodEnd)) {
      return this.messageService.showError('Дата начала периода не может быть больше даты конца');
    }
    this.reportsService.exportForm16(this.form16).subscribe({
      next: (response) => {
        DownloadFile(response);
      },
      error: err => {
        this.messageService.showError(getErrorMessage(err), 5000);
      },
    });
  }

  public async onTypeFilterChange(values: any[]) {
    this.codeColumns = [];
    this.showSource = !values.length || values.some(item => this.publicationsService.sourcePublicationTypes.includes(item));
    if (values.length === 0) return;

    if (values.some((item) => this.publicationsService.DOIPublicationTypes.includes(item)) ) {
      this.codeColumns.push({name: Codes.DOI, field: Codes.DOI});
    }

    if (values.some((item) => this.publicationsService.MPKPublicationTypes.includes(item)) ) {
      this.codeColumns.push({name: Codes.MPK, field: 'MPK'});
    }

    if (values.some((item) => this.publicationsService.ISBNPublicationTypes.includes(item)) ) {
      this.codeColumns.push({name: Codes.ISBN, field: Codes.ISBN});
    }
  }

  public filterCodes(filteredData: Publication[]) {
    Object.keys(this.filterCodesGroup.controls).forEach(key => {
      if (this.filterCodesGroup.get(key)?.value === null || this.filterCodesGroup.get(key)?.value === '') {
        return;
      }
      filteredData = filteredData.filter((item) => {
        return (item.codes.filter((code) => {
          const codeName = code.name === 'МПК' ? 'MPK' : code.name;
          return code.value.includes(this.filterCodesGroup.get(key)?.value) && key === codeName
        }).length > 0);
      })
    });
    return filteredData;
  }

  public async onFilterChange(value: any, column: ColumnComponent) {
    const codes = this.codeColumns.map((item) => item.field);
    this.filteredData = this.publications;

    if (column.field === 'type') {
      this.filterCodesGroup = new FormGroup({});
      await this.onTypeFilterChange(value);
    }

    if (codes.includes(column.field)) {
      (this as any)[column.field] = value;
      this.filterCodesGroup.addControl(`${column.field}`, new FormControl(value))
      this.filterCodesGroup.patchValue({[column.field]: value});
    }

    await this.filterStaticColumns(true);
    this.gridData.data = this.filterCodes(this.filteredData);
  }

  private async filterStaticColumns(filterChanged?: boolean) {
    let filters: any[] = [];
    Object.entries(this.filters).forEach(([key, value]) => {
      if (isNumber(value)) {
        value = `${value}`;
      }

      if (isDate(value)) {
        value = DateToString(value, 'yyyy-MM-dd');
      }

      if (isArray(value)) {
        value.forEach((arrayItem) => {
          filters.push({field: key, value: arrayItem});
        })
        return;
      }

      if (value) {
        filters.push({field: key, value: value});
      }
    });

    this.pageNumber = filterChanged ? 0 : this.pageNumber;
    await this.getPublications(this.selectedTab === 'Все', filters);
    this.filteredData = this.gridData.data;

    this.publicationsService.filters$.next({
      filters: this.filters,
      codesGroup: this.filterCodesGroup,
      selectedTab: this.selectedTab,
      DOI: this.DOI,
      ISBN: this.ISBN,
      MPK: this.MPK
    });
  }

  public getPublicationFile(dataItem: Publication) {
    this.fileService.getPublicationFile(dataItem.fileId!).subscribe({
      next: (value) => {
        DownloadFile(value);
      }
    });
  }

  public onDeletePublicationFile(dataItem: Publication) {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить файл?`);
    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.fileService.deletePublicationFile(dataItem.fileId!).subscribe({
          next: async () => {
            this.messageService.showSuccess('Успешно');
            await this.getPublications(this.selectedTab === 'Все');
          },
          error: (err) => {
            this.messageService.showError(err);
          }
        });
      }
    });
  }

  public async getUserAccess() {
    if (this.allPublicationUserAccessLevel === AccessRights.No && this.userAccessService.currentUserAccess$.value.isAdmin)
      this.allPublicationUserAccessLevel = AccessRights.Read;

    if (this.publicationsService.filters$.value) {
      this.selectedTab = this.publicationsService.filters$.value.selectedTab;
      await this.applyFilters();
      return;
    }

    if (this.myPublicationUserAccess) {
      this.selectedTab = 'Мои';
      await this.getPublications(false);
    } else if (this.allPublicationUserAccessLevel !== AccessRights.No) {
      this.selectedTab = 'Все';
      await this.getPublications(true);
    }
  }

  public getDynamicFilters(name: string) {
    return (this as any)[name] ?? null;
  }

  private getFileRestrictions() {
    this.fileService.getFileUploadRestrictions().subscribe(value => {
      this.fileUploadRestrictions.maxFileSize = value.maximumFileSize ?? 0;
      this.fileUploadRestrictions.extensions = value.extensions!.join(",");
      this.fileUploadRestrictions.maxFileSizeInMb = value.maximumFileSizeInMb ?? 10;
    });
  }

  public async clearDate(column: ColumnComponent) {
    this.filters.createdAt = null;
    await this.onFilterChange(this.filters.createdAt, column);
  }
}

import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Component} from "@angular/core";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";
import {DictValues} from "../../../../models/mfc/dicts/dynamic-dict.model";
import {DynamicDictService} from "../../../../services/mfc/dicts/dynamic-dict.sevice";
import {ActivatedRoute, Router} from "@angular/router";
import {DictCreatorService} from "../../../../services/mfc/dict-creator.service";

@Component({
  selector: 'mfc-dict-dynamic',
  template: `
      <mfc-settings></mfc-settings>
      <app-dict
              [data]="dictValues"
              [fieldsInfo]="info"
              [editable]="editable"
              [sortable]="true"
              [getFormGroup]="formGroup"
              [pageable]="pagerSettings"
              [breadcrumbs]="breadcrumbItemsMap"
              (getMethod)="get()"
              (addMethod)="add($event)"
              (updateMethod)="update($event)"
              (removeMethod)="remove($event)">
      </app-dict>
  `
})
export class DynamicDictComponent {

  protected dictValues: DictValues[] = [];
  private dictId: string = this.activateRoute.snapshot.paramMap.get('dictId') ?? '';
  private readonly dict = this.dictCreatorService.dicts$.value
    .find(a => a.id === this.dictId);
  protected editable = this.dict?.editable ?? false;

  // Хлебные крошки не показываются у динамических справочников на данный момент
  protected readonly breadcrumbItemsMap = new Map(
    this.dictCreatorService.dicts$.value.map(obj => [obj.id, obj.name])
  );
  protected readonly pagerSettings = pagerSettings;
  protected readonly info: FieldInfo<DictValues>[] = [
    {
      field: 'value',
      type: FieldType.Textbox,
      title: 'Значение',
      width: 350,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    }
  ];

  protected formGroup(dataItem?: DictValues): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      value: new FormControl(dataItem?.value, Validators.required),
      sortNumber: new FormControl(dataItem?.sortNumber)
    });
  }

  constructor(
    private dynamicDictService: DynamicDictService,
    private dictCreatorService: DictCreatorService,
    private activateRoute: ActivatedRoute,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  protected get() {
    this.dynamicDictService.getDictById(this.dictId).subscribe(data =>
      this.dictValues = data
    );
  }

  protected add({value, observer}: EventData<DictValues>) {
    this.dynamicDictService.addDictValue(this.dictId, value).subscribe(observer);
  }

  protected update({value, observer}: EventData<DictValues>) {
    this.dynamicDictService.updateDictValue(this.dictId, value).subscribe(observer);
  }

  protected remove({value, observer}: EventData<DictValues>) {
    this.dynamicDictService.removeDictValue(this.dictId, value.id).subscribe(observer);
  }
}

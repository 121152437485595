import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AcademicState } from '../../models/contingent/academicstate.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AcademicStateService {
  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.academicState}`;

  constructor(private http: HttpClient) { }

  //Get all AcademicState
  public getAllAcademicState(): Observable<AcademicState[]>{
    return this.http
      .get<AcademicState[]>(this.baseUrl);
  }

  //Add AcademicState
  addAcademicState(academicstate: AcademicState): Observable<AcademicState> {
    return this.http.post<AcademicState>(this.baseUrl, academicstate)
      .pipe(catchError(this.handleError));
  }

  //Delete AcademicState
  deleteAcademicState(id: string): Observable<AcademicState> {
    return this.http.delete<AcademicState>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit AcademicState
  updateAcademicState(academicstate: AcademicState): Observable<AcademicState> {
    return this.http.put<AcademicState>(this.baseUrl + '/' + academicstate.dictStudentAcademicStateExternalId, academicstate)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
      return throwError(error);
  }

}

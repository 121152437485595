import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {
  AdditionalWork, AdditionalWorkAddDto, AssignmentDepartmentGet, AssignmentDisciplineAdd,
  AvailableDiscipline, DictTypeWorkGet, DisciplineAssignment, MassOperationAssignmentAdd, RecallAssignmentDiscipline
} from "../../models/disciplineworkload/assignements.model";
import {handleError} from "../../helpers/errorHandle-helper";
import { AssignmentDepartmentQuery } from 'src/app/models/disciplineworkload/query/assignmentDepartmentQuery.model';
import {
  AssignmentDepartmentExportQuery
} from "../../models/disciplineworkload/query/exportAssignmentDepartmentQuery.model";
import {MonitoringQuery} from "../../models/disciplineworkload/query/monitoringQuery.model";
import {MonitoringAssignmentDepartment} from "../../models/disciplineworkload/monitoringAssignmentDepartmentGet.model";
import { DictCalculationMethod } from '../../models/disciplineworkload/externals.model';
import {StaticProperty} from "../../models/disciplineworkload/settings.model";

@Injectable({
  providedIn: 'root'
})
export class AssignmentSettingsService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.settings}`;

  constructor(private http: HttpClient) { }

  // Получение списка доступных видов работ для расчета потоков
  amountFlowTypeWorks(): Observable<DictTypeWorkGet[]> {
    return this.http.get<DictTypeWorkGet[]>(this.baseUrl + '/AmountFlowTypeWorks/');
  }

  // Получение списка доступных видов работ для способа расчета
  calculationMethodTypeWorks(): Observable<DictCalculationMethod[]> {
    return this.http.get<DictCalculationMethod[]>(this.baseUrl + '/CalculationMethodTypeWorks/');
  }

  getStaticProperties(): Observable<StaticProperty[]> {
    return this.http.get<StaticProperty[]>(this.baseUrl + '/StaticProperty');
  }

  getStaticPropertyByName(name: string): Observable<StaticProperty> {
    return this.http.get<StaticProperty>(this.baseUrl + '/StaticProperty/' + name);
  }

  updateStaticProperty(name: string, value: string): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl + '/StaticProperty/' + name, { value: value });
  }
}

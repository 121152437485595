export var StaticPropertyEnum: {
  lectionId: string,
  practiceId: string,
  laboratoryId: string,
  CSR: string,
  ControlWork: string,
  RGR: string,
  Referat: string,
} = {
  lectionId: "lectionId",
  practiceId: "practiceId",
  laboratoryId: "laboratoryId",
  CSR: "CSR",
  ControlWork: "ControlWork",
  RGR: "RGR",
  Referat: "Referat"
}

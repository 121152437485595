import { Component, OnInit, SimpleChanges } from '@angular/core';
import { DictWorkCategoryScheduleService } from '../../../services/education/DictWorkCategorySchedule/dict-work-category-schedule.service';
import { DictWorkCategorySchedule } from '../../../models/education/dictWorkCategorySchedule.model';
import { EducationPlanScheduleService } from 'src/app/services/education/EducationPlan/education-plan-schedule.service';
import {
  ScheduleCourseData, SemestrOffsetToTheory,
  SemestrSettings,
  TableDataType
} from '../../../models/education/education-plan-schedule.model';
import { DictWorkScheduleStudyProcessTypeService } from '../../../services/education/DictWorkScheduleStudyProcessType/dict-work-schedule-study-process-type.service';
import { DictWorkScheduleStudyProcessType } from '../../../models/education/dictWorkScheduleStudyProcessType.model';
import { environment } from 'src/environments/environment';
import { EducationPlanDetailsService } from '../../../services/education/EducationPlan/education-plan-details.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-educational-process-schedule-statistic',
  templateUrl: './educational-process-schedule-statistic.component.html',
  styleUrls: ['./educational-process-schedule-statistic.component.scss'],
})
export class EducationalProcessScheduleStatisticComponent implements OnInit {
  public dataCategory: DictWorkCategorySchedule[] = [];
  public gridData: any[] = [];
  public statisticData: TableDataType[] = [];
  private symbolDict: DictWorkScheduleStudyProcessType[] = [];
  private skipSymbols: string[] = [];
  private daysInWeek: number = environment.daysInWeek;
  private creditUnitsValue: number = environment.creditUnitsValue;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public dictWorkCategoryScheduleService: DictWorkCategoryScheduleService,
    public educationPlanScheduleService: EducationPlanScheduleService,
    public dictWorkScheduleStudyProcessTypes: DictWorkScheduleStudyProcessTypeService,
    public educationPlanDetailsService: EducationPlanDetailsService
  ) {}

  ngOnInit(): void {
    this.dictWorkCategoryScheduleService
      .getAllWorkCategorySchedules()
      .subscribe((response) => {
        this.dataCategory = response;
      });
    this.subscriptions.add(
      this.educationPlanScheduleService.processScheduleData$.subscribe(
        (response) => {
          this.gridData = response.courses;
        }
      )
    );
    this.subscriptions.add(
      this.educationPlanScheduleService.coursesHandleData$.subscribe(
        (response) => {
          this.statisticData = response;
        }
      )
    );
    this.subscriptions.add(
      this.dictWorkScheduleStudyProcessTypes.allWorkScheduleStudyProcessTypes$.subscribe(
        (response) => {
          this.symbolDict = response.filter(
            (item) =>
              item.trainingLevelExternalId ===
              this.educationPlanDetailsService.currentTrainingLevelId
          );
          this.skipSymbols = response
            .filter(
              (item) =>
                !item.isInvolvedCalculating &&
                item.trainingLevelExternalId ===
                this.educationPlanDetailsService.currentTrainingLevelId
            )
            .map((item) => item.symbol);
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public getCategory(field: string): DictWorkCategorySchedule | undefined {
    return this.symbolDict.find((item: DictWorkScheduleStudyProcessType) => item.dictWorkCategorySchedule?.dictWorkCategoryScheduleInternalName === field)
      ?.dictWorkCategorySchedule;
  }
  public getSymbols(field: string, dictWorkCategoryScheduleExternalId: string = ''): string[] {
    let items = this.symbolDict.filter((item: DictWorkScheduleStudyProcessType) =>
      item.dictWorkCategorySchedule?.dictWorkCategoryScheduleInternalName === field ||
      item.dictWorkCategorySchedule?.dictWorkCategoryScheduleExternalId === dictWorkCategoryScheduleExternalId
    );
    if(items.length)
      return items.map((item: DictWorkScheduleStudyProcessType) => item.symbol);
    return [];
  }

  public getDataCell(
    field: string,
    courseNumber: number,
    semesterNumber?: number
  ) {
    const days = this.getSumOfDaysBySymbolOffset(field, courseNumber, semesterNumber);
    return days > 0 ? this.getFormatCountWeeks(days) : '';
  }

  public getSumDataCell(field: string) {
    let countSemesters = this.gridData.reduce((count: number, el: any) => count + el.semesters.length, 0);
    let days = this.statisticData.reduce(
      (count: number, course, index) =>
        count + this.getSumOfDaysBySymbolOffset(field, index + 1, 1),
      0
    );

    for (let j = 2; j < countSemesters + 1; j++){
      days = days + this.statisticData.reduce(
        (count: number, course, index) =>
          count + this.getSumOfDaysBySymbolOffset(field, index + 1, j),
        0
      );
    }
    /* let days = this.statisticData.reduce(
       (count: number, course, index) =>
         count + this.getSumOfDaysBySymbolOffset(field, index + 1),
       0
     );*/

    return days > 0 ? this.getFormatCountWeeks(days) : '0';
  }

  public getFormatCountWeeks(days: number): string {
    return days % this.daysInWeek
      ? `${(days / this.daysInWeek) | 0} ${days % this.daysInWeek}/${
        this.daysInWeek
      }`
      : String(days / this.daysInWeek);
  }

  public getFormatCreditUnits(days: number): number {
    return (
      Math.round((days / this.daysInWeek) * this.creditUnitsValue * 100) / 100
    );
  }

  public getAllDaysOfCourse(courseNumber: number) {
    return this.statisticData[courseNumber - 1].weeksNumber.reduce(
      (daysCount, item) => {
        return (
          daysCount +
          item.days.reduce(
            (count: number, day) =>
              !this.skipSymbols.includes(day.symbol)
                ? count + day.daysAmount
                : count,
            0
          )
        );
      },
      0
    );
  }

  public getAllCategoryDataCell(courseNumber?: number) {
    if (this.statisticData.length===0) return '0';
    if (courseNumber) {
      return this.getFormatCountWeeks(this.getAllDaysOfCourse(courseNumber));
    } else {
      const days = this.statisticData.reduce(
        (count: number, course, index) =>
          count + this.getAllDaysOfCourse(index + 1),
        0
      );
      return days > 0 ? this.getFormatCountWeeks(days) : '0';
    }
  }

  public getCreditUnitsOfCourse(courseNumber: number) {
    const symbols = this.getSymbols('vacation').concat(this.getSymbols('vacation2'));
    return this.statisticData[courseNumber - 1].weeksNumber.reduce(
      (daysCount, item) => {
        return (
          daysCount +
          item.days.reduce(
            (count: number, day) =>
              symbols.includes(day.symbol) ? count : count + day.daysAmount,
            0
          )
        );
      },
      0
    );
  }

  public getCreditUnits(courseNumber?: number) {
    if (this.statisticData.length===0) return 0;
    if (courseNumber) {
      return this.getFormatCreditUnits(
        this.getCreditUnitsOfCourse(courseNumber)
      );
    } else {
      const days = this.statisticData.reduce(
        (count: number, course, index) =>
          count + this.getCreditUnitsOfCourse(index + 1),
        0
      );
      return this.getFormatCreditUnits(days);
    }
  }

  //количество дней по указанной категории с учетом сдвигов по трудоемкости дисциплин РУП
  public getSumOfDaysBySymbolOffset(
    field: string,
    courseNumber: number,
    semesterNumber?: number
  ) {
    const category = this.getCategory(field);
    const semesters = this.gridData.find((item: ScheduleCourseData) => item.courseNumber == courseNumber)
      ?.semesters.filter((item: SemestrSettings) => semesterNumber == null || item.semesterNumber == semesterNumber)

    // проверка на распределённую дисциплину и теор. обучение
    const offsetValuesFrom = semesters.flatMap((item: SemestrSettings) => item.semesterCategoriesOffsetToTheory
      ?.filter((cat: SemestrOffsetToTheory) => cat.categoryFromId == category?.dictWorkCategoryScheduleExternalId));

    const offsetValuesTo = semesters.flatMap((item: SemestrSettings) => item.semesterCategoriesOffsetToTheory
      ?.filter((cat: SemestrOffsetToTheory) => cat.categoryToId == category?.dictWorkCategoryScheduleExternalId));

    if (offsetValuesFrom.length === 0 && offsetValuesTo.length === 0)
    {
      //если дисциплина не распределённная и не получает дни от распределённой, возвращаем значение из таблицы
      return this.getSumOfDaysBySymbol(
        courseNumber,
        this.getSymbols(field),
        semesterNumber
      );
    }

    //если дисциплина распределённая, то возвращаем только её трудоёмкость из учебного плана
    if (offsetValuesFrom.length > 0)
    {
      return offsetValuesFrom.reduce((value: number, item: SemestrOffsetToTheory) =>
        value += item.value, 0
      );
    }

    //количество дней, которые занимает сама дисциплина
    const days = this.getSumOfDaysBySymbol(
      courseNumber,
      this.getSymbols(field),
      semesterNumber
    )

    //количество дней, которые занимает распред. дисциплина (categoryFrom)
    const offsetDays = offsetValuesTo.reduce((value: number, item: SemestrOffsetToTheory) =>
      value += this.getSumOfDaysBySymbol(
        courseNumber,
        this.getSymbols('', item.categoryFromId),
        semesterNumber
      ), 0
    );

    return days + offsetDays;
  }

  //количество дней по указанной категории из основной сетки графика
  public getSumOfDaysBySymbol(
    courseNumber: number,
    symbolMatch: string[],
    semester?: number
  ): number {
    if (this.statisticData.length===0) return 0;
    return this.statisticData[courseNumber - 1].weeksNumber.reduce(
      (daysCount, item) => {
        if (semester) {
          if (item.semesterNumber === semester) {
            return (
              daysCount +
              item.days.reduce(
                (count: number, day) =>
                  symbolMatch.includes(day.symbol) ? count + day.daysAmount : count,
                0
              )
            );
          } else return daysCount;
        } else {
          return (
            daysCount +
            item.days.reduce(
              (count: number, day) =>
                symbolMatch.includes(day.symbol) ? count + day.daysAmount : count,
              0
            )
          );
        }
      },
      0
    );
  }

  public sum(field: any, course: any){
    let semesters = this.gridData[course - 1].semesters;
    let days =  this.getSumOfDaysBySymbolOffset(field, course, semesters[0].semesterNumber)
    for (let j = 1; j < semesters.length; j++){
      days = days + this.getSumOfDaysBySymbolOffset(field, course, semesters[j].semesterNumber)
    }

    return days > 0 ? this.getFormatCountWeeks(days) : '';
  }
}

<dicts-header></dicts-header>
<h1>Сторонние организации</h1>
<div class="grid-container">
  <!--Grid-->
  <kendo-grid [kendoGridBinding]="organizations"
              (add)="addHandler($event)"
              (remove)="removeHandler($event)"
              (cellClick)="editHandler($event)"
              [pageable]="true" [pageSize]="pageSize"
              [sortable]="true"
              [filterable]="true">
    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" size="small" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton (click)="cancelHandler()" size="small"> Отмена </button>
        <button kendoButton [disabled]="formGroup!.invalid" themeColor="primary" (click)="saveCurrent()" size="small"> Сохранить </button>
      </div>
    </ng-template>

    <kendo-grid-column field="organizationName" title="Название организации" headerClass="gridHeader" [width]="350">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-textbox size="small"
                       [formControl]="formGroup.get('organizationName')"
                       [clearButton]="true">
        </kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
                        {{dataItem.organizationName}}
        </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="ogrn" title="ОГРН" headerClass="gridHeader" [width]="200">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-textbox size="small"
                       [maxlength]=20
                       [formControl]="formGroup.get('ogrn')"
                       [clearButton]="true">
        </kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
                        {{dataItem.ogrn}}
        </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="inn" title="ИНН" headerClass="gridHeader" [width]="200">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-textbox size="small"
                       [maxlength]=20
                       [formControl]="formGroup.get('inn')"
                       [clearButton]="true">
        </kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
                        {{dataItem.inn}}
        </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="kpp" title="КПП" headerClass="gridHeader" [width]="200">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <kendo-textbox size="small"
                       [maxlength]=20
                       [formControl]="formGroup.get('kpp')"
                       [clearButton]="true">
        </kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
                        {{dataItem.kpp}}
        </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="order" title="Порядковый номер для сортировки при отображении" headerClass="gridHeader" [width]="170">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
        <span class="alignCells">
          <kendo-numerictextbox
                  [style.width.px]="170"
                  [value]="dataItem.order"
                  (valueChange)="orderChange($event)"
                  [min]="1" format="n0"
                  [formControl]="formGroup.get('order')">
          </kendo-numerictextbox>
        </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
              {{dataItem.order}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isTarget" title="Целевая организация" headerClass="gridHeader" [width]="170">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          [data]="boolOptions"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('isTarget')"></kendo-combobox>
      </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{ dataItem.isTarget ? 'Да' : 'Нет' }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isWorkPlace" title="Место работы" headerClass="gridHeader" [width]="170">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
          [data]="boolOptions"
          textField="text"
          valueField="id"
          [valuePrimitive]="true"
          [formControl]="formGroup.get('isWorkPlace')"></kendo-combobox>
      </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
          {{ dataItem.isWorkPlace ? 'Да' : 'Нет' }}
        </span>
      </ng-template>
    </kendo-grid-column>
<!--    <kendo-grid-column field="isTarget" title="Целевая организация" headerClass="gridHeader" [width]="170">-->
<!--      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">-->
<!--&lt;!&ndash;        <span class="alignCells">&ndash;&gt;-->
<!--&lt;!&ndash;          <kendo-numerictextbox&ndash;&gt;-->
<!--&lt;!&ndash;            [style.width.px]="170"&ndash;&gt;-->
<!--&lt;!&ndash;            [value]="dataItem.isTarget"&ndash;&gt;-->
<!--&lt;!&ndash;            (valueChange)="orderChange($event)"&ndash;&gt;-->
<!--&lt;!&ndash;            [min]="0" format="n0"&ndash;&gt;-->
<!--&lt;!&ndash;            [formControl]="formGroup.get('isTarget')">&ndash;&gt;-->
<!--&lt;!&ndash;          </kendo-numerictextbox>&ndash;&gt;-->
<!--&lt;!&ndash;        </span>&ndash;&gt;-->
<!--        <kendo-combobox-->
<!--          [data]="boolOptions"-->
<!--          textField="text"-->
<!--          [value]="dataItem.isTarget"-->
<!--          (valueChange)="orderChange($event)"-->
<!--          valueField="id"-->
<!--          [valuePrimitive]="false"-->
<!--          [formControl]="formGroup.get('isTarget')"></kendo-combobox>-->
<!--      </ng-template>-->
<!--      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">-->
<!--        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">-->
<!--          <kendo-filter-contains-operator></kendo-filter-contains-operator>-->
<!--        </kendo-grid-string-filter-cell>-->
<!--      </ng-template>-->
<!--      <ng-template kendoGridCellTemplate let-dataItem>-->
<!--        <span class="alignCells">-->
<!--              {{dataItem.isTarget ? 'Да' : 'Нет'}}-->
<!--        </span>-->
<!--      </ng-template>-->
<!--    </kendo-grid-column>-->

    <kendo-grid-column title="" [width]="70" *ngIf="editable">
      <ng-template kendoGridCellTemplate> <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="small" rounded="full">
          </button>
        </span> </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="5"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
</div>
<div kendoDialogContainer></div>

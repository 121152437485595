<form class="k-form k-form-horizontal k-mt-10" [formGroup]="settingsFormGroup">
  <kendo-formfield orientation="horizontal">
    <kendo-label class="form-label" [style.width.px]="270">Тип календарного планирования</kendo-label>
    <kendo-dropdownlist formControlName="countDaysInWeekPart" [valuePrimitive]="true" [style.width.px]="180"
                        [data]="processScheduleService.countDaysInWeek" textField="name" valueField="value">
    </kendo-dropdownlist>
  </kendo-formfield>

  <kendo-formfield orientation="horizontal">
    <kendo-label class="custom-switch" text="Показывать даты на графике">
      <kendo-switch formControlName="showDatesInGraph"></kendo-switch>
    </kendo-label>
  </kendo-formfield>

  <div *ngIf="courses.length > 0" class="k-d-flex k-flex-row">
    <div class="k-d-flex k-flex-column k-mt-10">
      <div *ngFor="let course of courses; let i=index" class="k-d-flex k-flex-column k-mb-17">
        <div class="k-d-flex k-justify-content-end">
          <kendo-label class="form-label bold" [style.width.px]="200">Начало 1 {{studyTechName}}а</kendo-label>
          <kendo-label class="form-label bold" [style.width.px]="180">Начало 2 {{studyTechName}}а</kendo-label>
          <kendo-label *ngIf="courses[0].length > 2" class="form-label bold" [style.width.px]="200">Начало 3
            {{studyTechName}}а</kendo-label>
        </div>
        <div class="k-d-flex k-mt-3">
          <kendo-label class="form-label" [style.width.px]="60">{{i + 1}} курс</kendo-label>
          <kendo-datepicker *ngFor="let semester of course" format="dd MMMM" class="k-ml-5" [style.width.px]="180"
                            [min]="minDate" [max]="maxDate" [focusedDate]="navigationDate"
                            [(ngModel)]="semester.startTraining" [ngModelOptions]="{standalone: true}"></kendo-datepicker>
        </div>
      </div>
    </div>

    <div
      *ngIf="educationPlanDetailsService.currentStudyForm === 'Заочная' ||
        educationPlanDetailsService.currentStudyForm === 'Заочная уск.'"
      class="k-d-flex k-flex-column k-ml-7 k-mt-5"
    >
      <div *ngFor="let course of courses" class="k-mt-5">
        <div class="k-d-flex">
          <kendo-label class="k-d-flex form-label bold" [style.width.px]="280">Установочная сессия</kendo-label>
        </div>
        <div *ngFor="let semester of course; let i=index" class="k-d-flex k-mt-3">
          <kendo-label class="form-label" [style.width.px]="80">{{i + 1}} {{studyTechName}}</kendo-label>
          <kendo-datepicker format="dd MMMM" class="k-ml-5" [style.width.px]="180"
                            [min]="minDate" [max]="maxDate" [focusedDate]="navigationDate"
                            [(ngModel)]="semester.installationSessionBegin" [ngModelOptions]="{standalone: true}"></kendo-datepicker>
          <kendo-datepicker format="dd MMMM" class="k-ml-5" [style.width.px]="180"
                            [min]="minDate" [max]="maxDate" [focusedDate]="navigationDate"
                            [(ngModel)]="semester.installationSessionEnd" [ngModelOptions]="{standalone: true}"></kendo-datepicker>
        </div>
      </div>
    </div>
  </div>

  <div class="k-display-flex k-justify-content-end k-mt-3">
    <button type="button" kendoButton class="k-mr-3" (click)="goBack()">Отмена</button>
    <button kendoButton themeColor="primary" (click)="onSave()" [disabled]="educationPlanDetailsService.currentEducationPlan.status !== 1">
      Сохранить
    </button>
  </div>

  <kendo-label class="form-label bold">Праздничные и выходные дни ВУЗа</kendo-label>

  <kendo-formfield orientation="horizontal">
    <kendo-label class="custom-switch" text="Учитывать на графике">
      <kendo-switch formControlName="considerHolidays"></kendo-switch>
    </kendo-label>
  </kendo-formfield>

</form>

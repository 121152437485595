import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import {Guid} from "guid-typescript";
import {
  ComplaintManagementStatus
} from "../../models/contingent/complaintmanagementstatus.model";

@Injectable({
  providedIn: 'root'
})
export class ComplaintmanagementstatusService {


  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.complaintmanagementstatus}`;

  constructor(private http: HttpClient) { }

  //Get all complaint management status
  public getAllComplaintManagementStatus(): Observable<ComplaintManagementStatus[]> {
    return this.http.get<ComplaintManagementStatus[]>(this.baseUrl + '/GetList');
  }

  //Add complaint management status
  addComplaintManagementStatus(complaintManagementStatus: ComplaintManagementStatus): Observable<ComplaintManagementStatus> {
    return this.http.post<ComplaintManagementStatus>(this.baseUrl, complaintManagementStatus)
      .pipe(catchError(this.handleError));
  }

  //Delete complaint management status
  deleteComplaintManagementStatus(id: Guid): Observable<ComplaintManagementStatus> {
    return this.http.delete<ComplaintManagementStatus>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Update complaint management status
  updateComplaintManagementStatus(complaintManagementStatus: ComplaintManagementStatus): Observable<ComplaintManagementStatus> {
    return this.http.put<ComplaintManagementStatus>(this.baseUrl + '/' + complaintManagementStatus.externalId, complaintManagementStatus)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

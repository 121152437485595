<dicts-header></dicts-header>
<h1>Технология обучения</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="gridView" (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: studytechnologies.length < 8}"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              >

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictStudyTechnologyId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="studyTechnologyName"
                       title="Название технологии"
                       required
                       [width]="350"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.studyTechnologyName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="studyTechnologySName"
                       title="Сокр. название"
                       required
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.studyTechnologySName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="semesterName"
                       title="Номер семестра"
                       required
                       [width]="300"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.semesterName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="semesterNumberPerYear"
                       title="Количество семестров в году"
                       required
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.semesterNumberPerYear}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="orderNum"
                       title="Порядковый номер"
                       required
                       [width]="150"
                       headerClass="gridHeader">
                       <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-textbox class="border"
                          [formControl]="formGroup.get('orderNum')"
                        >
                        </kendo-textbox>
                      </ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.orderNum}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TreeCommonModel } from '../../../models/currentcontrol/trees.model';
import { SemesterYearsModel } from '../../../models/currentcontrol/semesterYears.model';
import { SheetService } from '../../../services/currentcontrol/sheet.service';
import {
  GroupInfoModel,
  SignatoriesModel,
} from '../../../models/currentcontrol/sheets.model';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import {DownloadFile} from "../../../helpers/downloadFile-helper";
import {CurrentDayEnd} from "../../../helpers/date-helper";

type SemesterType = { semesterNum: number; semesterName: string };

@Component({
  selector: 'app-currentcontrolfilters',
  templateUrl: './currentcontrolfilters.component.html',
  styleUrls: ['./currentcontrolfilters.component.scss'],
})
export class CurrentcontrolfiltersComponent implements OnInit {
  @Output() semesterChange: EventEmitter<SemesterYearsModel> =
    new EventEmitter<SemesterYearsModel>();
  @Input() group: TreeCommonModel = {} as TreeCommonModel;
  @Output() weekFilterChange: EventEmitter<string | null> = new EventEmitter();
  @Output() onChangeEditable: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() stateChange: EventEmitter<string> = new EventEmitter();
  @Input() weekFilter: string | null = null;
  @Input() contingentDate: Date = CurrentDayEnd();
  @Input('semester') semesterDefault: SemesterYearsModel =
    {} as SemesterYearsModel;

  public currentDate: Date = CurrentDayEnd();
  public semesterList: SemesterType[] = [];
  public selectedSemester: SemesterType = {} as SemesterType;
  public groupInfo: GroupInfoModel = {} as GroupInfoModel;
  private defaultSigner = {
    fio: 'Фамилия Имя Отчество',
    postName: 'должность',
    id: Guid.create(),
    personId: Guid.create(),
  };
  public signer: SignatoriesModel = {
    ...this.defaultSigner,
  };
  public eduGroupId: string = '';
  public loadingEmptyList = false;
  public loadingFilledList = false;
  public loadingGradeOnly = false;
  public loadingAttendanceOnly = false;
  public loadingFullList = false;
  public editable?: boolean;
  private destroy$!: Subscription;

  constructor(private sheetService: SheetService) {}

  ngOnInit(): void {
    this.selectedSemester = {
      semesterNum: this.semesterDefault.semesterNum || 1,
      semesterName:
        this.semesterDefault.semesterNum +
        ' семестр ' +
        this.semesterDefault.semesterYearString,
    };

    this.sheetService.groupInfo$.subscribe((response) => {
      if (response.id?.toString() === this.eduGroupId) {
        this.groupInfo = response;
        this.editable = this.groupInfo.editable;
        this.onChangeEditable.emit(this.editable);
        if (response.signatory) {
          this.signer = {
            fio: response.signatory.fio,
            postName: response.signatory.postName,
            personId: Guid.parse(response.signatory.personId),
          };
        } else {
          this.signer = { ...this.defaultSigner };
        }
        if (response.semesters) {
          this.semesterList = response.semesters;

          if (
            !this.semesterList.find(
              (item) => item.semesterName === this.selectedSemester.semesterName
            )
            && +this.currentDate === +this.contingentDate
          ) {
            this.stateChange.emit('');
          }
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setEduGroupId();

    if (changes['semesterDefault']) {
      this.selectedSemester = this.selectedSemester = {
        semesterNum: changes['semesterDefault'].currentValue.semesterNum || 1,
        semesterName: `${changes['semesterDefault'].currentValue.semesterNum} семестр ${changes['semesterDefault'].currentValue.semesterYearString}`,
      };

      this.eduGroupId &&
        this.sheetService.updateGroupInfoBySemester(
          this.eduGroupId,
          this.selectedSemester.semesterNum
        );
    }
  }

  ngOnDestroy() {
    this.destroy$?.unsubscribe();
  }

  private setLoading(param: number, value: boolean) {
    switch (param) {
      case 0: {
        this.loadingFilledList = value;
        break;
      }
      case 1: {
        this.loadingFullList = value;
        break;
      }
      case 2: {
        this.loadingGradeOnly = value;
        break;
      }
      case 3: {
        this.loadingAttendanceOnly = value;
        break;
      }
    }
  }

  onPrintEmptyList(): void {
    this.loadingEmptyList = true;

    this.sheetService
      .printFormEmpty({
        eduGroupId : this.eduGroupId,
        semester : this.selectedSemester.semesterNum,
        attestationId : this.weekFilter,
        contingentDate : this.contingentDate
      })
      .subscribe((response) => {
        DownloadFile(response);
        this.loadingEmptyList = false;
      });
  }

  onPrintFilledList(param: number): void {
    this.setLoading(param, true);
    this.sheetService
      .printFormFilled(
        {
          eduGroupId : this.eduGroupId,
          semester : this.selectedSemester.semesterNum,
          attestationId : this.weekFilter,
          contingentDate : this.contingentDate
        },
        param
      )
      .subscribe((response) => {
        DownloadFile(response);
        this.setLoading(param, false);
      });
  }

  onChangeWeekFilter(value: string | null): void {
    this.weekFilter = value;
    this.weekFilterChange.emit(value);
  }

  public onChangeSelectedSemester(semester: SemesterType) {
    this.selectedSemester = semester;
    this.sheetService.updateGroupInfoBySemester(
      this.eduGroupId,
      this.selectedSemester.semesterNum
    );
    this.semesterChange.emit({
      ...this.semesterDefault,
      semesterNum: semester.semesterNum,
      semesterYearString: semester.semesterName.split(' ')[2],
      semesterYear: Number(semester.semesterName.split(' ')[2].split('-')[0]),
    });
  }

  setEduGroupId() {
    this.eduGroupId = this.group.id
      .toString()
      .trimStart()
      .split('_')
      .slice(-1)
      .join('');
  }
}

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DictAuthorType} from "../../../models/publications/dict/authorType.model";

@Injectable({
  providedIn: 'root'
})
export class AuthorTypesService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.dict}/AuthorType`;

  constructor(private http: HttpClient) {
  }

  public getAuthorTypes(): Observable<DictAuthorType[]> {
    return this.http.get<DictAuthorType[]>(this.baseUrl);
  }
}

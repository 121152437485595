import { Component, OnInit, ViewChild, Renderer2} from '@angular/core';
import { AddEvent, GridComponent, RemoveEvent, CellClickEvent, PagerPosition } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { AssignAuditorium } from 'src/app/models/classroom/assign-auditorium.model';
import { AssignAuditoriumService } from 'src/app/services/classroom/assign-auditorium.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { CompositeFilterDescriptor, State } from "@progress/kendo-data-query";
import { ClassRoomUserAccessService } from '../../../services/useraccess/classroom-user-access.service';
import { PersonService } from 'src/app/services/person/lkperson.service';
import { checkRole } from "../../../helpers/token/role-check";
import { TokenStorageService } from "../../../services/token.service";
import { JwtHelperService}  from "@auth0/angular-jwt";
import { Role } from "../../../models/useraccess/role";
import { Guid } from 'guid-typescript';
import { Router} from "@angular/router";

@Component({
    selector: 'app-assign-auditorium',
    templateUrl: './assign-auditorium.component.html',
    styleUrls: ['./assign-auditorium.component.scss']
})

export class AssignAuditoriumComponent implements OnInit{

    assignsAuditorium: any = [];
  //  assignsAuditoriumN: any[] = [];
    public assignAuditorium: any = {
        classRoomPurposeId: '',
        classRoomPurposeName: '',
        classRoomPurposeNumber: 0,
    }

    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public info = true;
    public pageSizes = [20, 50, 100];
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';
    public skip = 0;

    public loading = false;
    public opened = false;

    public position: "top" | "bottom" | "both" = "both";
    public formGroup: FormGroup | undefined;
    public editable?: boolean;

    private editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    @ViewChild(GridComponent) private grid!: GridComponent;

    public get isInEditingMode(): boolean{
        return this.editedRowIndex !== undefined || this.isNew;
    }

    public gridState: State = {
        sort: [],
        skip: 0,
        take: 10,
    };

    public classRoomPurposeId: string = '';

    public accessLevel: boolean = false;
    public accesses: any = [];
    public personId: string = "";
    public isAdmin: boolean;

    public exists: boolean = false;
    public assignForEdit: any = [];

constructor(
    private assignAuditoriumService: AssignAuditoriumService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private renderer: Renderer2,
    private userAccessService: ClassRoomUserAccessService,
    private personService: PersonService,
    private tokenStore: TokenStorageService,
    private jwtHelper: JwtHelperService,
)
    { this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);}

ngOnInit() {
    this.getAllAssigns();
    this.renderer.listen("document", "click", ({ target }) => {
        if (!isChildOf(target, "k-grid")) {
          this.saveCurrent();
        }
      });
      this.getAccess();
}

    public getAllAssigns() {
        this.loading = true;
        this.assignAuditoriumService.getAllAssigns()
        .subscribe(
            response => {
            this.assignsAuditorium = response;
          //  console.log(this.assignsAuditorium);
            this.loading = false;
            },
            error => {
            console.log(error.error);
            this.loading = false;
            });
    }

    public removeHandler({ dataItem }: RemoveEvent): void {
        const dialog: DialogRef = this.dialogService.open({
            title: "Пожалуйста подтвердите",
            content: `Вы действительно хотите удалить: ${dataItem.classRoomPurposeName.toLowerCase()}?`,
            actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
            width: 450,
            height: 200,
            minWidth: 250,
          });
          dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {
            } else {
              if(result.text == "Да"){
                this.opened = false;
                this.assignAuditoriumService.deleteAssignAuditorium(dataItem.classRoomPurposeId)
                .subscribe(
                  ( response: any) => {
                    if (response == null){
                      this.notificationService.showError("Невозможно удалить назначение, так как оно используется");
                    }
                    else {
                      this.getAllAssigns();
                      this.notificationService.showSuccess("Удалено");
                    }
                  },
                  error => {
                    if ( error.error.Error[0] == "Запись используется"){
                      this.notificationService.showError("Невозможно удалить назначение, так как оно используется");
                    }
                    else this.notificationService.showError(error.error);
                }
                );
                  }
                else{
                  this.opened = false;
                }
            }
          });
    }

    public editHandler({
        sender,
        isEdited,
        rowIndex,
        dataItem,
    }: CellClickEvent): void {
      if (this.accessLevel == true){
        if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
            return;
          }
      
          this.isLine = true;
          this.classRoomPurposeId = dataItem.classRoomPurposeId;
          this.saveRow();
          this.formGroup = formGroup(dataItem);
          this.editedRowIndex = rowIndex;
      
          this.assignForEdit = [];
          let name = this.formGroup.value.classRoomPurposeName;
          this.assignForEdit = this.assignsAuditorium.filter((item: any) => !(item.classRoomPurposeName == name));

          sender.editRow(rowIndex, this.formGroup);
        }
    }

    public addHandler({ sender }: AddEvent): void {
        this.closeEditor(sender);

        this.formGroup = formGroup({
   //     auditoriumId: 0,
          classRoomPurposeName: '',
          classRoomPurposeNumber: undefined,
        //dictAuditoriumExternalId: Guid.create()
        });

        this.isLine = true;
        this.isNew = true;
        sender.addRow(this.formGroup);
    }

    public cancelHandler(): void {
        this.closeEditor(this.grid, this.editedRowIndex);
    }

    private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
        ): void {
        this.isNew = false;
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

    public onStateChange(state: any): void {
        this.gridState = state;
        this.getAllAssigns();
    }

    public saveCurrent(){
        if (this.formGroup && !this.formGroup.valid) {
            return;
          }
          this.isLine = false;
          this.saveRow();
    }

    private saveRow(): void {
        if (this.isInEditingMode) {
          //Adding new entry to dictionary
          if (this.isNew == true && this.formGroup !== undefined) {

            this.exists = false;
          
            let name = this.formGroup.value.classRoomPurposeName.toLowerCase().trim();
            if (this.assignsAuditorium.findIndex((item:any) => item.classRoomPurposeName.toLowerCase() == name ) !== -1){
              this.notificationService.showError("Невозможно сохранить назначение, так как оно уже существует");
              this.exists = true;
            }

            if (this.exists == false){
              this.assignAuditoriumService.addAssignAuditorium(this.formGroup.value)
                .subscribe(
                  response => {
                    this.getAllAssigns();
                    this.notificationService.showSuccess("Добавлено");
                    this.assignAuditorium = {
                      classRoomPurposeName: '',
                      classRoomPurposeNumber: undefined,
                    }
                  },
                  error => {
                    this.notificationService.showError("Не удалось сохранить запись");
                  }
                );
            }
          }
          //Update dictionary
          else if (this.formGroup !== undefined) {

            this.exists = false;
          
            let name = this.formGroup.value.classRoomPurposeName.toLowerCase().trim();
            let checkName = false;
            if (this.assignForEdit.findIndex((item:any) => item.classRoomPurposeName.toLowerCase() == name ) !== -1){
              this.notificationService.showError("Невозможно сохранить назначение, так как оно уже существует");
              this.exists = true;
            }

            if (this.exists == false){
            this.assignAuditoriumService.updateAssignAuditorium(this.formGroup.value, this.classRoomPurposeId)
              .subscribe(
                response => {
                  this.getAllAssigns();
                  this.notificationService.showSuccess("Сохранено");
                },
                error => {
                  this.notificationService.showError("Не удалось изменить запись");
                }
              );
            }
          }
        }
        this.closeEditor(this.grid);
    }

    public getAccess()  {
      this.userAccessService.getAccessLevel()
      .subscribe(response => {
        if (response.dictionaryAccessLevel == 2){
          this.accessLevel = true;
        }
      })
    }

}

const formGroup = (dataItem: { classRoomPurposeId?: any; classRoomPurposeName?: any; classRoomPurposeNumber?: any; dictAuditoriumExternalId?: any}) =>
  new FormGroup({
 //   auditoriumId: new FormControl(dataItem.auditoriumId),
      classRoomPurposeName: new FormControl(dataItem.classRoomPurposeName, Validators.required),
      classRoomPurposeNumber: new FormControl(dataItem.classRoomPurposeNumber, [Validators.min(1), Validators.pattern("^[0-9]+$")]),
 //   dictAuditoriumExternalId: new FormControl(dataItem.dictAuditoriumExternalId)
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
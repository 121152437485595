<gia-settings></gia-settings>

<h1>Шаблоны печатных форм</h1>

<div class="selectContent">
  <dd class="filter">
    <span class="selectText">Филиал</span>
    <kendo-dropdownlist
      [data]="allFilial"
      class="w-400"
      textField="shortName"
      valueField="id"
      [valuePrimitive]="true"
      name="filial"
      (valueChange)="onValueChange($event)"
      [(ngModel)]="filial"
    >
    </kendo-dropdownlist>
  </dd>

  <dd class="filter">
    <span class="selectText">Учебный год</span>
    <kendo-dropdownlist
      [data]="yearAdmissionList"
      class="w-400"
      textField="studyYearString"
      valueField="studyYear"
      [valuePrimitive]="true"
      name="studyYear"
      (valueChange)="onValueChange($event)"
      [(ngModel)]="yearAdmission"
    >
    </kendo-dropdownlist>
  </dd>
</div>

<div>
  <div class="buttons" [ngClass]="{ alignend: !editable }">
    <div *ngIf="editable">
      <button kendoButton themeColor="success" (click)="addPrintTemplate()" icon="plus">Добавить</button>
    </div>
  </div>

  <kendo-grid
    class="m-t15"
    #grid
    [kendoGridBinding]="printTemplates"
    (cellClick)="onRowClick($event)"
    [filterable]="true"
    [pageSize]="pageSize"
    [loading]="loading"
    [sortable]="true"
    [pageable]="pagerSettings"
  >
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <kendo-grid-column field="dictPrintTemplateCategoryName" title="Категория шаблона" [width]="180" headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <filter-combobox
          [useVirtual]="false"
          [filter]="filter"
          [data]="dictPrintTemplateCategories"
          textField="dictPrintTemplateCategoryName"
          valueField="dictPrintTemplateCategoryId"
        >
        </filter-combobox>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ dataItem.dictPrintTemplateCategoryName }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="name" title="Наименование шаблона" [width]="180" headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ dataItem.name }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="tLevels" title="Уровень подготовки" [sortable]="false" [width]="200" headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-multiselect
          class="border"
          [data]="dictTrainingLevels"
          [kendoDropDownFilter]="{
            operator: 'contains'
          }"
          kendoMultiSelectSummaryTag
          [checkboxes]="true"
          (valueChange)="onFilterChange($event, column)"
          [autoClose]="false"
          [valuePrimitive]="true"
          textField="name"
          valueField="id"
        >
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span>{{ dataItems.length }} выбрано</span>
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells"> {{ getList(dataItem.printTemplateTrainingLevels, "name") }} </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="departmentName" title="Институт/Факультет" [width]="180" headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <filter-combobox [useVirtual]="false" [filter]="filter" [data]="departments" textField="departmentName" valueField="departmentId"> </filter-combobox>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ dataItem.departmentName ?? "Все" }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="printerName" title="Наименование принтера" [width]="180" headerClass="gridHeader dictionaries">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ dataItem.printerName }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isDefault" title="Шаблон по умолчанию" [width]="180" headerClass="gridHeader dictionaries" filter="boolean">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-boolean-filter-cell [column]="column" [filter]="filter" class="operator"> </kendo-grid-boolean-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">{{ getIsDefaultPrintTemplate(dataItem.isDefault) }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column headerClass="gridHeader dictionaries" title="Шаблон" [width]="100">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          <span
            class="k-icon k-i-file pointer"
            (click)="downloadFile(dataItem.id, dataItem.dictPrintTemplateCategoryValue, dataItem.fileName, dataItem.name)"
          ></span>
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {GridComponent} from "@progress/kendo-angular-grid";
import {SummaryInfo, Info} from "../../../../models/publications/summaryInfo/summaryInfo.model";
import {DateFromUTCAsLocal, DateToString} from "../../../../helpers/date-helper";
import {SummaryInfoService} from "../../../../services/science/publications/summaryInfo.service";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {DictScientometricBase} from "../../../../models/publications/dict/scientometricBase.model";
import {ScientometricBasesService} from "../../../../services/science/publications/scientometric-bases.service";
import {DictSummary} from "../../../../models/publications/dict/summary.model";
import {SummaryService} from "../../../../services/science/publications/summary.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'publications-summaryInfo',
  templateUrl: './publications-summary-info.component.html',
  styleUrls: ['./publications-summary-info.component.scss']
})
export class PublicationsSummaryInfoComponent implements OnInit {

  @ViewChild(GridComponent) private grid!: GridComponent;
  @Input() isAuthorInfo: boolean = true;
  @Input() authorId: string = '';
  public loading: boolean = false;
  public editable: boolean = false;
  public isEditingMode: boolean = false;

  private scientometricBases!: DictScientometricBase[];
  private summary!: DictSummary[];
  public summaryInfo!: SummaryInfo;

  public gridData: any[] = [];
  public columns: string[] = [];
  private rows: string[] = [];
  private infoList: Info[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    private summaryInfoService: SummaryInfoService,
    private scientometricBaseService: ScientometricBasesService,
    private summaryService: SummaryService,
    private location: Location
  ) { }

  async ngOnInit() {
    if (this.authorId === '') this.authorId = this.activatedRoute.snapshot.params['authorId'];
    this.editable = true;

    await this.getDictScientometricBases();
    await this.getDictSummary();
    await this.getSummaryInfo();
  }

  public goBack() {
    this.location.back();
  }

  private async getDictScientometricBases() {
    await lastValueFrom(this.scientometricBaseService.getScientometricBases())
      .then((value) => {
        this.scientometricBases = value;
        this.getColumns();
      });
  }

  private async getDictSummary() {
    await lastValueFrom(this.summaryService.getSummary())
      .then((value) => {
        this.summary = value;
        this.getRows();
      });
  }

  private async getSummaryInfo() {
    this.loading = true;
    await lastValueFrom(this.summaryInfoService.getSummaryInfo(this.authorId))
      .then((value) => {
        this.summaryInfo = value;

        if (this.isAuthorInfo) {
          this.summaryInfo.person!.birthday = DateFromUTCAsLocal(this.summaryInfo.person!.birthday);
          this.summaryInfo.person!.fio =
            `${this.summaryInfo.person!.lastName} ${this.summaryInfo.person!.firstName} ${this.summaryInfo.person!.middleName}`;
        }

        if (this.summaryInfo.updateAt && this.summaryInfo.addedBy) {
          this.summaryInfo.addedBy.fio =
            `${this.summaryInfo.addedBy.lastName} ${this.summaryInfo.addedBy.firstName} ${this.summaryInfo.addedBy.middleName}`;
        }
        this.transposeInfo();
        this.setInfoList();
        this.loading = false;
      });
  }

  private getColumns() {
    this.columns = Array.from(new Set(this.scientometricBases.map((item) => {
      return item.id
    })));
  }

  private getRows() {
    this.rows = Array.from(new Set(this.summary.map((item) => {
      return item.id
    })));
  }

  private transposeInfo() {
    this.gridData = [];
    this.rows.forEach((row) => {
      let data: any = {};
      data["property"] = row;

      this.columns.forEach((column) => {
        data[column] = this.summaryInfo.matrix.find((info) =>
          info.dictSummaryId === row && info.dictScientometricBaseId === column)!.value;
      });
      this.gridData.push(data);
    });
  }

  private setInfoList() {
    this.infoList = this.summaryInfo.matrix;
  }

  private setInfo(value: number, row: string, column: string): Info {
    return {
      dictSummaryId: row,
      dictScientometricBaseId: column,
      value: value
    };
  }

  public onEdit() {
    this.isEditingMode = true;
  }

  public async onCancel() {
    this.isEditingMode = false;
    await this.getSummaryInfo();
  }

  public async onSave() {
    this.summaryInfoService.addSummaryInfo(this.authorId, this.infoList).subscribe({
      next: async () => {
        this.notificationsService.showSuccess('Успешно');
        this.isEditingMode = false;
        await this.getSummaryInfo();
      },
      error: (error) => {
        this.notificationsService.showError(error);
      }
    });
  }

  public changeInfo(value: number, row: string, column: string) {
    const index = this.infoList.findIndex(item =>
      item.dictSummaryId === row && item.dictScientometricBaseId === column);

    if (index > -1) {
      this.infoList[index] = this.setInfo(value, row, column);
    }
  }

  public getColumnName(id: string) {
    return this.scientometricBases.find(
      (item) => item.id === id)!.name
  }

  public getRowName(id: string) {
    return this.summary.find(
      (item) => item.id === id)!.name
  }

  public getDateString(date: Date | null | undefined, format: string) {
    return date ? DateToString(date, format) : "";
  }
}

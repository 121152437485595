import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DictProperty} from "../../../models/mfc/dicts/property.model";

@Injectable({
  providedIn: 'root'
})
export class DictPropertyService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/DictProperty/`;

  constructor(private http: HttpClient) { }

  public getAll() {
    return this.http.get<DictProperty[]>(this.baseUrl);
  }
}
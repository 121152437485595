<div class="grid-layout-container m-t30">
    <kendo-gridlayout class="personLayout"
      [align]="align"
      [gap]="{ rows: 10, cols: 20}">
      <kendo-gridlayout-item [col]="1" [row]="1">
        <div class="profilePicture" [style.background-image]="photoUrl"></div>
        <h3 class="personName">{{fullName}}</h3>
        <div class="contacts">
          <h2 class="title">Контакты</h2>
          <div class="email">
            <table class="table__first">
              <tr class="spaceUnder">
                <th class="w-65 align-left">E-mail</th>
                <td>
                  <div class="m-l20" *ngIf="emails.length == 0"> - </div>
                </td>
              </tr>
            </table>
            <table class="table" *ngFor="let item of emails">
              <tr class="spaceUnder">
                <td>
                  <a class="mailLink" href="mailto:{{item.email}}">{{item.email}}</a>
                </td>
              </tr>
            </table>
          </div>
          <div class="phone">
            <table class="table__padding">
              <tr class="spaceUnder">
                <th class="w-65">Телефон</th>
                <td>
                  <div class="m-l20" *ngIf="phoneNumbers.length == 0"> - </div>
                </td>
              </tr>
            </table>
            <table class="table" *ngFor="let item of phoneNumbers">
              <tr class="spaceUnder">
                <td>
                  {{phoneFormat(item.phoneNumber) | imask: mask}}
                </td>
              </tr>
            </table>
          </div>
          <div class="social">
            <h2 class="social__title">Соц. сети</h2>
            <table class="social__table">
              <tr>
                <td>
                  <div *ngIf="personSocialMedias.length == 0"> - </div>
                  <div class="socialItem" *ngFor="let item of personSocialMedias">
                      <a class="{{item.socialMedia.name.toLowerCase()}}" href="{{item.socialProfileUrl}}" target="_blank"></a>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="support">
          <h2 class="supportTitle">Техническая поддержка ЛК</h2>
          <p>
            E-mail  <a class="mailLink" href="mailto:{{supportEmail}}">{{supportEmail}}</a>
          </p>
          <p>
            Тел. 220-15-94
          </p>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item [col]="2" [row]="1">
        <div class="info">
          <h1>Основная информация</h1>
        </div>
      </kendo-gridlayout-item>
     <kendo-gridlayout-item class="rightColumn">
      <!--  <kendo-listview [data]="experiences" class="removeBackground">
        </kendo-listview> -->
        <table class="table" *ngFor="let item of experiences">
          <tr>
            <th>{{item.experienceType}}</th>
            <td>
              <div class="experienceNumber">
                <div *ngIf="item.years != 0">{{item.years}} {{yearConversion[item.years] ?? 'лет'}}</div>
                <div *ngIf="item.years != 0">&nbsp;</div>
                <div *ngIf="item.months != 0">{{item.months}} {{monthConversion[item.months] ?? 'месяцев'}}</div>
              </div>
              <div *ngIf="item.years == 0 &&  item.months == 0 && item.days == 0" class="experienceNumber">-</div>
            </td>
          </tr>
        </table>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="leftColumn">
        <table class="table">
            <tr class="spaceUnder">
              <th>Дата рождения</th>
              <td>{{personsData.birthday | date: 'dd.MM.yyyy':'GMT'}}</td>
            </tr>
          <tr>
            <th>СНИЛС</th>
            <td>
              {{ personsData.snils ? personsData.snils : "-" }}
            </td>
          </tr>
            <tr>
              <th>Табельный номер</th>
              <td>
                {{ buhCode }}
                <div *ngIf="buhCode == '' ">-</div>
              </td>
            </tr>
        </table>
        <table class="table">
          <tr>
            <th>Ученая степень</th>
            <td>
              <div *ngFor="let item of personScientificDegrees; let isLast=last">{{item.scientificDegree}}</div>
              <div *ngIf="personScientificDegrees.length == 0">-</div>
            </td>
          </tr>
        </table>
        <table class="table">
          <tr>
            <th>Должность</th>
            <td>
              <div *ngFor="let item of personSalaries; let isLast=last">
                <div *ngIf="item.commonStatus == 1">
                  {{ formatSalaryInfo(item) }}
                </div>
              </div>
              <div *ngIf="personSalaries.length == 0">-</div>
            </td>
          </tr>
        </table>
        <table class="table">
          <tr>
            <th>Количество дней отпуска</th>
            <td>{{ personsData.remainingVacationDaysNumber ? personsData.remainingVacationDaysNumber.toFixed(2) : '-' }}</td>
          </tr>
        </table>
      </kendo-gridlayout-item>

<div class="dopInfoColumn" *ngIf="methodAdd">
      <div class="infoColumn">
            <button class="addInfo" (click)="hide()" *ngIf="addinformation" >Скрыть дополнительную информацию о сотруднике </button>
            <button class="addInfo" (click)="display()"  *ngIf="!addinformation">Отобразить дополнительную информацию о сотруднике </button>
      </div>

      <kendo-gridlayout-item class="educationColumn">
      <div class="grid">
        <h2>Образование (базовое, профессиональная переподготовка)</h2>
        <kendo-grid [data]="educationGridView">
            <ng-template kendoGridToolbarTemplate position="bottom" *ngIf="!allEducations">
              <button kendoButton (click)="showAllEducationData()">Показать все</button>
            </ng-template>
            <kendo-grid-column field="year" title="Год" [width]="100"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">{{dataItem.year}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="400" field="educationOrganization" title="Образовательная организация"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.organization }}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="280" field="speciality" title="Специальность (Курс)"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{dataItem.courseName}}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="280" field="special" title="Направление подготовки" *ngIf="addinformation"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.special }}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="200" field="qualification" title="Квалификация"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.qualification }}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="150" field="studyLevel" title="Уровень подготовки" *ngIf="addinformation"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                   {{ dataItem.studyLevel }}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="150" field="diplom" title="Диплом" *ngIf="addinformation"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                   {{ dataItem.diplom }}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                   {{ dataItem.externalId }}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                  </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
            headerClass="gridHeader">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells">
                    {{ Status(dataItem.commonStatus) }}
                </span>
              </ng-template>
            </kendo-grid-column>
        </kendo-grid>
      </div>
      </kendo-gridlayout-item>

      <kendo-gridlayout-item class="qualificationColumn">
        <div class="grid">
          <h2>Повышение квалификации</h2>
          <kendo-grid [data]="qualificationsGridView" [pageSize]="qualificationsPageSize">
             <ng-template kendoGridToolbarTemplate position="bottom" *ngIf="!allQualifications">
                <button kendoButton (click)="showAllQualificationData()">Показать все</button>
              </ng-template>
              <kendo-grid-column field="year" title="Период" [width]="140"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    {{groupQualificationPeriod(dataItem.startDate, dataItem.completeDate)}}
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="traineeshipType" title="Вид" [width]="220"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{ dataItem.traineeshipType }}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="hoursNumber" title="Ак.часы" [width]="120"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    <span *ngIf="dataItem.hoursNumber != 0">{{ dataItem.hoursNumber }}</span>
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="qualificationAdvancementDocument" title="Документ о повышении квалификации"
              headerClass="gridHeader" [width]="300">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells document">
                    {{groupQualificationDocument(dataItem.docName, dataItem.docSerial, dataItem.docNumber, dataItem.docDate)}}
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="300" field="companyName" title="Организация"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{ dataItem.companyName }} {{ dataItem.city}}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="250" field="courseName" title="Программа"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{ dataItem.courseName }}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                      {{ dataItem.externalId }}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения " *ngIf="addinformation"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                    </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="100" field="commonStatus" title="Статус " *ngIf="addinformation"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    {{ Status(dataItem.commonStatus) }}
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column [width]="150" field="filePath" title="Сканированная копия " *ngIf="addinformation"
              headerClass="gridHeader">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="alignCells">
                    <button kendoButton class="button-save" icon="save" *ngIf="dataItem.filePath !== ''" (click)="loadFile(dataItem.externalId,dataItem.filePath)"></button>
                  </span>
                </ng-template>
              </kendo-grid-column>
          </kendo-grid>
        </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="scienceDegreeColumn">
            <div class="grid">
              <h2>Ученая степень</h2>
              <kendo-grid [data]="personScientificDegrees">
                  <kendo-grid-column field="scientificDegree" title="Ученая степень" [width]="250"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">{{dataItem.scientificDegree}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="250" field="special" title="Специальность"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                          {{ dataItem.special }}
                        </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="250" field="scienceBranch" title="Отрасль науки"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                          {{dataItem.scienceBranch}}
                        </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="250" field="dissertationSovet" title="Диссертационный совет"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                          {{ dataItem.dissertationSovet }}
                        </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="200" field="diplom" title="Диплом"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{groupDiplom(dataItem.diplom, dataItem.date, dataItem.shifr)}}
                        </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                         {{ dataItem.externalId }}
                        </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ Status(dataItem.commonStatus) }}
                       </span>
                    </ng-template>
                  </kendo-grid-column>
              </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="academicTitleColumn">
            <div class="grid">
                <h2>Ученое звание</h2>
                <kendo-grid [data]="academicStatuses">
                    <kendo-grid-column field="academicStatus" title="Ученое звание" [width]="180"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">{{dataItem.academicStatus}}</span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="diplom" title="Диплом"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.attSeries }} {{dataItem.attNum}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="organization" title="Организация"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{dataItem.organization}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="180" field="special" title="Специальность"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.special }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="kafedra" title="Кафедра"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                        {{dataItem.kafedra}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="date" title="Дата"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.date }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.externalId }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="experienceColumn">
            <div class="grid">
                <h2>Стаж</h2>
                <kendo-grid [data]="experiences">
                    <kendo-grid-column field="experienceType" title="Вид стажа" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">{{dataItem.experienceType}}</span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="300" field="experience" title="Стаж работы"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            <div *ngIf="dataItem.years != 0">{{dataItem.years}} {{yearConversion[dataItem.years] ?? 'лет'}}</div>
                            <div *ngIf="dataItem.years != 0">&nbsp;</div>
                            <div *ngIf="dataItem.months != 0">{{dataItem.months}} {{monthConversion[dataItem.months] ?? 'месяцев'}}</div>
                            <div *ngIf="dataItem.months != 0">&nbsp;</div>
                            <div *ngIf="dataItem.days != 0">{{dataItem.days}} {{dayConversion[dataItem.days] ?? 'дней'}}</div>
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.externalId }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="achievementsColumn">
            <div class="grid">
              <h2>Достижения</h2>
              <kendo-grid [data]="achievementsGridView" [pageSize]="achievementsPageSize">
                <ng-template kendoGridToolbarTemplate position="bottom" *ngIf="!allAchievements">
                    <button kendoButton (click)="showAllAchievementsData()">Показать все</button>
                  </ng-template>
                  <kendo-grid-column field="achievementName" title="Название достижения" [width]="140"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ dataItem.achievementName }}
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="orderDate" title="Дата приказа" [width]="220"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ dataItem.orderDate | date: 'dd.MM.yyyy' }}
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="orderNumber" title="Номер приказа" [width]="120"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ dataItem.orderNumber }}
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                          {{ dataItem.externalId }}
                        </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                          {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения " *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="100" field="commonStatus" title="Статус " *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{ Status(dataItem.commonStatus) }}
                       </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [width]="150" field="scanCopy" title="Сканированная копия " *ngIf="addinformation"
                  headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        <button kendoButton class="button-save" icon="save" *ngIf="dataItem.filePath !== ''" (click)="loadAchievementsFile(dataItem.externalId,dataItem.filePath)"></button>
                      </span>
                    </ng-template>
                  </kendo-grid-column>
              </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="salaryColumn">
            <div class="grid">
                <h2>Ставка</h2>
                <kendo-grid [data]="personSalaries">
                    <kendo-grid-column field="department" title="Структурное подразделение" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">{{dataItem.department}}</span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="post" title="Должность"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.post }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="salaryType" title="Тип ставки"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{dataItem.salaryType}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="180" field="salary" title="Ставка"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.salary }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="180" field="salary" title="Срок договора"
                                       headerClass="gridHeader">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                              {{ dataItem.contractTerm | date: 'dd.MM.yyyy'}}
                          </span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="180" field="salary" title="Дата приема"
                                       headerClass="gridHeader">
                      <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="alignCells">
                                {{ dataItem.employmentDate | date: 'dd.MM.yyyy'}}
                            </span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="180" field="salary" title="Дата увольнения"
                                       headerClass="gridHeader">
                      <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="alignCells">
                                {{ dataItem.dismissalDate | date: 'dd.MM.yyyy'}}
                            </span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="isOurPerson" title="Сотрудник организации" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            <div *ngIf="dataItem.isOurPerson == true">Да</div>
                            <div *ngIf="dataItem.isOurPerson == false">Нет</div>
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="isMainJob" title="Основное место работы" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            <div *ngIf="dataItem.isMainJob == true">Да</div>
                            <div *ngIf="dataItem.isMainJob == false">Нет</div>
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="isPPS" title="Сотрудник ППС" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            <div *ngIf="dataItem.isPPS == true">Да</div>
                            <div *ngIf="dataItem.isPPS == false">Нет</div>
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="recordSource" title="Источник записи" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.recordSource }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.externalId }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="buhCodeColumn">
            <div class="grid">
                <h2>Бухгалтерские коды</h2>
                <kendo-grid [data]="personSalaries">
                    <kendo-grid-column field="buhCode" title="Бухгалтерский код" [width]="150"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">{{dataItem.buhCode.buhCode}}</span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.externalId }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="fioColumn">
            <div class="grid">
                <h2>Ф.И.О</h2>
                <kendo-grid [data]="personName">
                    <kendo-grid-column field="fullName" title="ФИО" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem >
                        <span class="alignCells">{{dataItem.lastName}} {{dataItem.firstName}} {{dataItem.middleName}}</span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="id" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate >
                        <span class="alignCells">
                            {{ id }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="assistentColumn">
            <div class="grid">
                <h2>Ассистенты</h2>
                <kendo-grid [data]="assistents">
                    <kendo-grid-column field="assistantTypeName" title="Тип ассистента" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.personAssistantType.assistantTypeName }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="assistantNames" title="Ассистент" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.assistantNames.lastName }} {{ dataItem.assistantNames.firstName }} {{ dataItem.assistantNames.middleName }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.externalId) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="phonesColumn">
            <div class="grid">
                <h2>Телефоны</h2>
                <kendo-grid [data]="phoneNumbers">
                    <kendo-grid-column field="phoneType" title="Тип телефона" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">{{dataItem.phoneType}} </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="phoneNumber" title="Номер телефона" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{phoneFormat(dataItem.phoneNumber) | imask: mask}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{dataItem.externalId}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="socialColumn">
            <div class="grid">
                <h2>Социальные сети</h2>
                <kendo-grid [data]="personSocialMedias">
                    <kendo-grid-column field="socialMedia" title="Социальная сеть" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{dataItem.socialMedia.name}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="socialProfileUrl" title="Ссылка" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{dataItem.socialProfileUrl}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{dataItem.externalId}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="emailColumn">
            <div class="grid">
                <h2>Электронная почта</h2>
                <kendo-grid [data]="emails">
                    <kendo-grid-column field="emailType" title="Тип e-mail" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.emailType}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="link" title="E-mail" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.email}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.externalId}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="contactsColumn">
            <div class="grid">
                <h2>Контакты</h2>
                <kendo-grid [data]="contacts">
                    <kendo-grid-column field="loginName" title="Логин" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.loginName }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="workHours" title="Рабочие часы" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.workHours}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{ dataItem.externalId}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>

        <kendo-gridlayout-item class="languagesColumn">
            <div class="grid">
                <h2>Иностранные языки</h2>
                <kendo-grid [data]="personLanguages">
                    <kendo-grid-column field="languageName" title="Иностранный язык" [width]="200"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.languageName}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="200" field="externalId" title="ID" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.externalId}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="createdAt" title="Дата создания" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="updatedAt" title="Дата изменения" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ dataItem.updatedAt | date: 'dd.MM.yyyy HH:mm'}}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="150" field="commonStatus" title="Статус" *ngIf="addinformation"
                    headerClass="gridHeader">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                            {{ Status(dataItem.commonStatus) }}
                        </span>
                    </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </kendo-gridlayout-item>
    </div>

    </kendo-gridlayout>
  </div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LanguageLevels } from '../../models/contingent/languagelevels.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LanguagelevelsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.languagelevels}`;

  constructor(private http: HttpClient) { }

  //Get all languagelevels
  public getAlllanguagelevels(): Observable<LanguageLevels[]> {
    return this.http.get<LanguageLevels[]>(this.baseUrl);
  }

  //Add languagelevels
  addlanguagelevels(languagelevels: LanguageLevels): Observable<LanguageLevels> {
    return this.http.post<LanguageLevels>(this.baseUrl, languagelevels)
      .pipe(catchError(this.handleError));
  }

  //Delete languagelevels
  deletelanguagelevels(id: string): Observable<LanguageLevels> {
    return this.http.delete<LanguageLevels>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit languagelevels
  updatelanguagelevels(languagelevels: LanguageLevels): Observable<LanguageLevels> {
    return this.http.put<LanguageLevels>(this.baseUrl + '/' + languagelevels.dictLanguageLevelExternalId, languagelevels)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

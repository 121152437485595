import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import {
  GroupCommonModel,
  ControlActions,
  DisciplineStatement,
  StudentByDiscipline,
} from 'src/app/models/currentcontrol/groupCommon.model';
import {
  MarkModel,
  StudentDisciplineInfo,
} from 'src/app/models/currentcontrol/marks.model';
import {
  ExaminerInfo,
  PersonsModel,
} from 'src/app/models/currentcontrol/persons.model';
import { environment } from '../../../environments/environment';
import { NotificationsService } from 'src/app/services/notifications/notifications.service'
import {CurrentControlSheetListQuery} from "../../models/currentcontrol/query.model";
import {Moment} from "moment";

@Injectable({
  providedIn: 'root',
})
export class CurrentControlService {
  baseUrl = `${environment.apiEndpoint}`;
  disciplineInfo$ = new BehaviorSubject<Partial<DisciplineStatement>>({});
  sessionDisciplines$ = new BehaviorSubject<ControlActions[]>([]);
  markDate$ = new BehaviorSubject<Date>(new Date());
  marksStatistic$ = new BehaviorSubject<MarkModel[]>([]);

  constructor(
    private httpClient: HttpClient,
    private notifications: NotificationsService
  ) {}

  public getSheetHeader(
    eduGroupId: string,
    semesterNumber: number
  ): Observable<ControlActions[]> {
    return this.httpClient
      .get<ControlActions[]>(
        this.baseUrl +
          `${environment.apiPaths.currentcontrol.sheetheader}/${eduGroupId}/${semesterNumber}`
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список дисциплин для группы'
          );
          return of([] as ControlActions[]);
        })
      );
  }

  public getSheetStudentsList(
    request: CurrentControlSheetListQuery
  ): Observable<GroupCommonModel[]> {
    return this.httpClient
      .post<GroupCommonModel[]>(
        this.baseUrl +
          `${environment.apiPaths.currentcontrol.sheetstudentlist}` , request
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список студентов в группе'
          );
          return of([] as GroupCommonModel[]);
        })
      );
  }

  public getSheetDiscipline(params: {
    disciplineId: string;
    eduGroupId: string;
    semester: number;
    electiveDisciplineId?: string | null;
    dictAttestationId?: string | null;
  }): Observable<DisciplineStatement> {
    return this.httpClient
      .post<DisciplineStatement>(
        this.baseUrl + `${environment.apiPaths.currentcontrol.info}`,
        params
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить информацию о дисциплине'
          );
          return of({} as DisciplineStatement);
        })
      );
  }

  public getSheetStudentsByDiscipline(params: {
    disciplineId: string;
    eduGroupId: string;
    semester: number;
    electiveDisciplineId?: string | null;
    dictAttestationId?: string | null;
    contingentDate: Date|Moment,
    isCurrentSemester: boolean
  }): Observable<StudentByDiscipline[]> {
    this.getSheetDiscipline(params).subscribe((response) =>
      this.disciplineInfo$.next(response)
    );
    return this.httpClient
      .post<StudentByDiscipline[]>(
        this.baseUrl + `${environment.apiPaths.currentcontrol.students}`,
        params
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError(
            'Не удалось получить список студентов для дисциплины'
          );
          return of([] as StudentByDiscipline[]);
        })
      );
  }

  public changeMarksDiscipline(student: StudentDisciplineInfo) {
    return this.httpClient
      .post<StudentDisciplineInfo>(
        this.baseUrl + environment.apiPaths.currentcontrol.addmark,
        student
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось сохранить баллы');
          return of({} as StudentDisciplineInfo);
        })
      );
  }

  public addExaminer(saveData: any) {
    return this.httpClient
      .post<PersonsModel>(
        this.baseUrl + environment.apiPaths.currentcontrol.examiner,
        saveData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось добавить преподавателя');
          return of({} as PersonsModel);
        })
      );
  }

  public changeExaminer(teacherId: string, saveData: any) {
    return this.httpClient
      .put<PersonsModel>(
        this.baseUrl +
          environment.apiPaths.currentcontrol.examiner +
          '/' +
          teacherId,
        saveData
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось изменить преподавателя');
          return of({} as PersonsModel);
        })
      );
  }

  public deleteExaminer(examinerId: string) {
    return this.httpClient
      .delete<Pick<ExaminerInfo, 'id'>>(
        this.baseUrl +
          environment.apiPaths.currentcontrol.examiner +
          '/' +
          examinerId
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.notifications.showError('Не удалось удалить преподавателя');
          return of([]);
        })
      );
  }
}

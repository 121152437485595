<education-header></education-header>
<title>Циклы</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Циклы</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новый цикл' : 'Изменить цикл' }}
  </h1>

  <div class="selectContent" *ngIf="!isOpened">
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Уровень подготовки
      </span>
      <kendo-dropdownlist [data]="traininglevels"
                          textField="trainingLevelName"
                          valueField="trainingLevelExternalId"
                          [valuePrimitive]="true"
                          name="trainingLevel"
                          [(ngModel)]="secondFilter.trainingLevelId"
                          [defaultItem]="defaultItemTrainingLevel"
                          (valueChange)="onValueChange($event)"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
    
    <dd style="display: flex; align-items: center">
      <span class="selectText">
        Тип стандарта
      </span>
      <kendo-dropdownlist [data]="standardTypes"
                          textField="standardTypeShortName"
                          valueField="standardTypeExternalId"
                          [valuePrimitive]="true"
                          name="standardType"
                          (valueChange)="onValueChange($event)"
                          [(ngModel)]="secondFilter.standardTypeId"
                          [defaultItem]="defaultItemStandardType"
                          style="width: 400px;">
      </kendo-dropdownlist>
    </dd>
  </div>
  
  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" class="addButton" kendoButton [primary]="true" (click)="addHandler()" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid #grid
                (remove)="removeHandler($event)"
                [data]="dictCyclesByFilter"
                [height]="600"
                [kendoGridBinding]="dictCyclesByFilter"
                [navigable]="true"
                [sortable]="true"
                [filterable]="true"
                [filter]="filter"
                (filterChange)="filterChange($event)"
                [pageable]="
                {
                  buttonCount: buttonCount,
                  info: info,
                  type: type,
                  pageSizes: pageSizes,
                  previousNext: previousNext,
                  position: position
                }"
                [loading]="loading"
                [pageSize]="10">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCycleName" [width]="200" title="Название" [headerStyle]="{'text-align': 'center; align-items: center; white-space: pre-wrap;'}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells" style="text-align: left; justify-content: left;">
            {{dataItem.dictCycleName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCycleShortName" [width]="170" title="Сокр.название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictCycleShortName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <!-- <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCyclePrintName" [width]="200" title="Печатное название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictCyclePrintName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCycleNameGenitiveCase" [width]="200" title="Название в род.падеже">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictCycleNameGenitiveCase}}
          </span>
        </ng-template>
      </kendo-grid-column> -->


      <!-- <kendo-grid-column headerClass="gridHeader dictionaries" field="dictTrainingLevel.trainingLevelName" [width]="250" title="Уровень подготовки">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <filter-dropdown [filter]="filter" [data]="traininglevels" textField="trainingLevelName"
                           valueField="trainingLevelExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictTrainingLevel.trainingLevelName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictStandardType.standardTypeShortName" [width]="200" title="Тип стандарта">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [filter]="filter" [data]="standardTypes" textField="standardTypeShortName"
                           valueField="standardTypeExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictStandardType.standardTypeShortName}}
          </span>
        </ng-template>
      </kendo-grid-column> -->
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCycleType.dictCycleTypeName" [width]="250" title="Тип цикла">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-dropdown [filter]="filter" [data]="cycleTypes" textField="dictCycleTypeName"
                           valueField="dictCycleTypeExternalId">
          </filter-dropdown>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictCycleType.dictCycleTypeName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictCycleOptionalMarks" [width]="190" title="Факультативный признак" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells" *ngIf="dataItem.dictCycleOptionalMarks == true">{{"Да"}}</div>
          <div class="alignCells" *ngIf="dataItem.dictCycleOptionalMarks == false">{{"Нет"}}</div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="170">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="alignCells">
            <button kendoButton type="button" (click)="editHandler(dataItem)" class="editButton">Изменить</button>
          </div>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="170">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full" style="text-overflow: unset;">
          </button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictCycleName" [maxlength]="255" #dictCycleName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="dictCycleShortName" [maxlength]="50" #dictCycleShortName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Печатное название</h2>
          <kendo-textbox formControlName="dictCyclePrintName" [maxlength]="255" #dictCyclePrintName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название в род.падеже</h2>
          <kendo-textbox formControlName="dictCycleNameGenitiveCase" [maxlength]="255" #dictCycleNameGenitiveCase required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>


      <!-- <kendo-formfield>
        <div class="formRow">
          <h2>Уровень подготовки</h2>
          <kendo-dropdownlist
                  [data]="traininglevels"
                  textField="trainingLevelName"
                  valueField="trainingLevelExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['trainingLevelExternalId']"
                  formControlName="trainingLevelExternalId" #trainingLevelExternalId required>
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2>Тип стандарта</h2>
          <kendo-dropdownlist
                  [data]="standardTypes"
                  textField="standardTypeName"
                  valueField="standardTypeExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['standardTypeExternalId']"
                  formControlName="standardTypeExternalId" #standardTypeExternalId required>
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield> -->
      <kendo-formfield>
        <div class="formRow required">
          <h2>Тип цикла</h2>
          <kendo-dropdownlist
                  [data]="cycleTypes"
                  textField="dictCycleTypeName"
                  valueField="dictCycleTypeExternalId"
                  [valuePrimitive]="true"
                  [formControl]="$any(editForm).controls['dictCycleTypeExternalId']"
                  formControlName="dictCycleTypeExternalId" #dictCycleTypeExternalId required>
          </kendo-dropdownlist>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow">
          <h2 style="width: 50%">
            Факультативный признак
          </h2>
          <div style="width: 50%">
            <input type="checkbox" class="editCheckBox" kendoCheckBox formControlName="dictCycleOptionalMarks" #dictCycleOptionalMarks/>
          </div>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton (click)="onCancel()" type="button" class="cancelButton" >Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveCycle()">Сохранить</button>
      </div>
    </form>
  </div>
</div>

<div kendoDialogContainer></div>
<education-header></education-header>
<title>Тип стандарта</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">Тип стандарта</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новый тип стандарта' : 'Изменить тип стандарта' }}
  </h1>

  <div [class]="[gridStyle]" class="grid-container">
    <button *ngIf="!isOpened&&editable" class="addButton" (click)="addHandler()" kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid
            [data]="standardTypes"
            [kendoGridBinding]="standardTypes"
            [navigable]="true"
            [sortable]="true"
            [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader" field="standardTypeName" title="Название">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells leftAlignment">
            {{dataItem.standardTypeName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader" field="standardTypeShortName" [width]="200" title="Сокр.название">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.standardTypeShortName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader" field="standardTypePrintName" title="Печатное название">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.standardTypePrintName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader" *ngIf="editable" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button kendoButton (click)="editHandler(dataItem)" type="button" class="editButton">Изменить</button>
          </span>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>
  <!--Edit form-->
  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="standardTypeName" #standardTypeName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Сокращенное название</h2>
          <kendo-textbox formControlName="standardTypeShortName" #standardTypeShortName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <div class="formRow required">
          <h2>Печатное название</h2>
          <kendo-textbox formControlName="standardTypePrintName" #standardTypePrintName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton class="cancelButton" type="button" (click)="onCancel()">Отменить</button>
        <button kendoButton themeColor="primary" type="button" [disabled]="!editForm.valid" (click)="saveStandardType()">Сохранить</button>
      </div>
    </form>
  </div>
</div>
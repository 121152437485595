<contingent-header></contingent-header>
<h1>Гражданство</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [kendoGridBinding]="citizenship" (remove)="removeHandler($event)"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [pageable]="true"
              [pageSize]="pageSize"
              [sortable]="true"
              [resizable]="true"
              [filterable]="true">

    <ng-template kendoGridToolbarTemplate position="both" *ngIf="editable">
      <button kendoGridAddCommand size="small" themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()"
                size="small">
          Отмена
        </button>
        <button kendoButton
                [disabled]="formGroup!.invalid"
                themeColor="primary"
                (click)="saveCurrent()"
                size="small">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="citizenshipId" title="citizenshipId"> </kendo-grid-column> -->
    <kendo-grid-column field="country"
                       title="Страна"
                       headerClass="gridHeader"
                       [width]="200">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [formControl]="formGroup.get('country')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.country}}
                      </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="countryPhoneCode"
                       title="Код телефона"
                       headerClass="gridHeader"
                       [width]="150">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [maxlength]=50
          [formControl]="formGroup.get('countryPhoneCode')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.countryPhoneCode}}
                      </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="code"
                       title="Код"
                       headerClass="gridHeader"
                       [width]="120">
      <ng-template kendoGridEditTemplate
                   let-formGroup="formGroup">
        <kendo-textbox
          [maxlength]=4
          [formControl]="formGroup.get('code')">
        </kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.code}}
            </span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="rCountry"
                       title="Р. падеж"
                       headerClass="gridHeader"
                       [filterable]="false">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [maxlength]=250
          [formControl]="formGroup.get('rCountry')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.rCountry}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dCountry"
                       title="Д. падеж"
                       headerClass="gridHeader"
                       [filterable]="false">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [maxlength]=250
          [formControl]="formGroup.get('dCountry')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.dCountry}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="vCountry"
                       title="В. падеж"
                       headerClass="gridHeader"
                       [filterable]="false">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [maxlength]=250
          [formControl]="formGroup.get('vCountry')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.vCountry}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="tCountry"
                       title="Т. падеж"
                       headerClass="gridHeader"
                       [filterable]="false">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [maxlength]=250
          [formControl]="formGroup.get('tCountry')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.tCountry}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="pCountry"
                       title="П. падеж"
                       headerClass="gridHeader"
                       [width]="200"
                       [filterable]="false">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-textbox
          size="small"
          [maxlength]=250
          [formControl]="formGroup.get('pCountry')"
          [clearButton]="true"
        ></kendo-textbox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.pCountry}}
                      </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title=""
                       [width]="70"
                       *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand
                  themeColor="secondary"
                  icon="delete"
                  fillMode="flat"
                  size="small"
                  rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>

</div>
<div kendoDialogContainer></div>

import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {catchError, Observable, throwError} from "rxjs";
import {GlobalSignatory, PostGlobalSignatory, PutGlobalSignatory} from "../../models/dicts/global-signatory";
import {Guid} from "guid-typescript";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GlobalSignatoryService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.globalSignatory}`;

  constructor(private http: HttpClient) {
  }

  public get(): Observable<GlobalSignatory[]> {
    return this.http.get<GlobalSignatory[]>(this.baseUrl)
      .pipe(
        map((data: GlobalSignatory[]) => {
          return data.map(function(_: any): GlobalSignatory {
            if (_.vacationStart)
              _.vacationStart = new Date(_.vacationStart);
            if (_.vacationEnd)
              _.vacationEnd = new Date(_.vacationEnd)
            return _;
          })
        })
      );
  }

  public add(data: PostGlobalSignatory): Observable<Guid | string> {
    return this.http.post<Guid | string>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public update(data: PutGlobalSignatory): Observable<boolean> {
    return this.http.put<boolean>(this.baseUrl, data)
      .pipe(catchError(this.handleError));
  }

  public delete(signatoryId: Guid | string): Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + '/' + signatoryId)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

<gia-settings></gia-settings>

<h1>Настройки доступа</h1>

<span class="filter">
  <h3>Поиск по ФИО</h3>
  <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
  <button kendoButton (click)="clearData()">Очистить</button>
  <button kendoButton (click)="filterData(searchFIO)" themeColor="primary">
    Применить
  </button>
</span>

<kendo-grid
  class="technical"
  [kendoGridBinding]="gridData"
  [loading]="loading"
  [sortable]="true"
  [sort]="sort"
  (remove)="removeHandler($event)"
  (edit)="editHandler($event)"
  (add)="addHandler($event)"
>
  <ng-template kendoGridLoadingTemplate>
    <div class="k-loading-color"></div>
    <div class="loading-container">
      <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
    </div>
  </ng-template>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoGridAddCommand
      themeColor="success"
      *ngIf="!isInEditingMode"
      icon="plus"
    >
      Добавить
    </button>
    <div *ngIf="isInEditingMode">
      <button kendoButton (click)="cancelHandler()">Отмена</button>
      <button
        kendoButton
        themeColor="primary"
        [disabled]="formGroup!.invalid"
        (click)="saveCurrent()"
      >
        Сохранить
      </button>
    </div>
  </ng-template>
  <kendo-grid-column [sticky]="true" title="" [width]="35">
    <ng-template kendoGridCellTemplate>
      <span class="alignCells">
        <button
          kendoGridEditCommand
          themeColor="secondary"
          icon="edit"
          fillMode="flat"
          size="none"
          class="custom-size"
          rounded="full"
        ></button>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [sticky]="true" title="" [width]="35">
    <ng-template kendoGridCellTemplate>
      <span class="alignCells">
        <button
          kendoGridRemoveCommand
          themeColor="secondary"
          icon="delete"
          fillMode="flat"
          size="none"
          class="custom-size"
          rounded="full"
        ></button>
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [sticky]="true"
    [width]="250"
    field="personId"
    headerClass="gridHeader dictionaries"
    title="ФИО"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [kendoDropDownFilter]="filterSettings"
        [data]="persons"
        textField="fio"
        valueField="id"
        [valuePrimitive]="true"
        [virtual]="virtual"
        [formControl]="formGroup.get('personId')"
      >
      </kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getPersonName(dataItem) }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [sortable]="false"
    [width]="195"
    field="filials"
    headerClass="gridHeader dictionaries"
    title="Филиал"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-formGroup="formGroup"
    >
      <kendo-multiselect
        kendoMultiSelectSummaryTag
        class="filials"
        [data]="filteredData.filials"
        [kendoDropDownFilter]="filterSettings"
        textField="shortName"
        [(ngModel)]="filialsEdit"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('filials')"
        (valueChange)="valueChange($event, 'filialsEdit')"
      >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'filials')">
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getList(dataItem.filials, "shortName") }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [sortable]="false"
    [width]="195"
    field="trainingLevels"
    headerClass="gridHeader dictionaries"
    title="Уровень подготовки"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-formGroup="formGroup"
    >
      <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.trainingLevels"
        class="trainingLevels"
        textField="name"
        [(ngModel)]="trainingLevelsEdit"
        [kendoDropDownFilter]="filterSettings"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('trainingLevels')"
        (valueChange)="valueChange($event, 'trainingLevelsEdit')"
      >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'trainingLevels')">
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getList(dataItem.trainingLevels, "name") }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [sortable]="false"
    [width]="195"
    field="studyForms"
    headerClass="gridHeader dictionaries"
    title="Форма обучения"
  >
    <ng-template
      kendoGridEditTemplate
      let-formGroup="formGroup"
      let-dataItem="dataItem"
    >
      <kendo-multiselect
        kendoMultiSelectSummaryTag
        [data]="filteredData.studyForms"
        class="studyForms"
        [(ngModel)]="studyFormsEdit"
        [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="true"
        textField="name"
        valueField="id"
        [formControl]="formGroup.get('studyForms')"
        (valueChange)="valueChange($event, 'studyFormsEdit')"
      >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'studyForms')">
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getList(dataItem.studyForms, "name") }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [sortable]="false"
    [width]="195"
    field="departments"
    headerClass="gridHeader dictionaries"
    title="Структурное подразделение"
  >
    <ng-template
      kendoGridEditTemplate
      let-formGroup="formGroup"
      let-dataItem="dataItem"
    >
      <kendo-multiselect
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="filteredData.departments"
        [(ngModel)]="departmentsEdit"
        class="departments"
        textField="shortName"
        [valuePrimitive]="true"
        valueField="id"
        [formControl]="formGroup.get('departments')"
        (valueChange)="valueChange($event, 'departmentsEdit')"
      >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span (click)="onTagClick(dataItems, 'departments')">
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getList(dataItem.departments, "shortName") }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [sortable]="false"
    [width]="220"
    field="giaSections"
    headerClass="gridHeader dictionaries"
    title="Запись в разделах"
  >
    <ng-template
      kendoGridEditTemplate
      let-formGroup="formGroup"
      let-dataItem="dataItem"
    >
      <kendo-multiselect
        class="gia-sections"
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="accessRightSectionList"
        [(ngModel)]="giaSectionsEdit"
        [valuePrimitive]="true"
        textField="title"
        valueField="value"
        [formControl]="formGroup.get('giaSections')"
        (valueChange)="valueChange($event, 'giaSectionsEdit')"
      >
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span
            class="multiTag"
            (click)="onTagClick(dataItems, 'giaSections')"
          >
            {{ dataItems.length }} выбрано
          </span>
        </ng-template>
      </kendo-multiselect>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getListTabsFromEnum(dataItem.giaSections) }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="140"
    field="giaProtocolDesigner"
    headerClass="gridHeader dictionaries"
    title="Конструктор протокола ГИА"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('giaProtocolDesigner')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ dataItem.giaProtocolDesigner ? "Да" : "Нет" }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="140"
    field="printTemplateAccess"
    headerClass="gridHeader dictionaries"
    title="Шаблоны печатных форм"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('printTemplateAccess')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ dataItem.printTemplateAccess ? "Да" : "Нет" }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="140"
    field="dictCreating"
    headerClass="gridHeader dictionaries"
    title="Создание справочников"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('dictCreating')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ dataItem.dictCreating ? "Да" : "Нет" }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [width]="140"
    field="dict"
    headerClass="gridHeader dictionaries"
    title="Справочники"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('dict')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ getAccessLevelName(dataItem.dict) }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="140"
    field="accessSettings"
    headerClass="gridHeader dictionaries"
    title="Настройки доступа"
    *ngIf="isAdmin"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('accessSettings')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ dataItem.accessSettings ? "Да" : "Нет" }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [width]="140"
    field="localSignatory"
    headerClass="gridHeader dictionaries"
    title="Настройки локального списка подписантов"
    *ngIf="isAdmin"
  >
    <ng-template kendoGridEditTemplate let-formGroup="formGroup">
      <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('localSignatory')"
      ></kendo-combobox>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="alignCells">
        {{ dataItem.localSignatory ? "Да" : "Нет" }}
      </span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<div kendoDialogContainer></div>

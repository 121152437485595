import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs";
import {handleError} from "../../helpers/publications/errorHandle-helper";
import {
  TabsSettingsDto
} from "../../models/mfc/applicationConstructor/settings/constructor-tabs-settings.model";
import {NotificationsService} from "../notifications/notifications.service";

@Injectable({
  providedIn: 'root'
})
export class ApplicationConstructorTabsService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.commonApplicationConstructor}`;

  constructor(private http: HttpClient, private notificationsService: NotificationsService) { }

  public getTabSettings(constructorId: string) {
    return this.http.get<TabsSettingsDto[]>(`${this.baseUrl}/${constructorId}/Tab/`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err);
        return handleError(err);
      }));
  }

  public saveTabSettings(constructorId: string, tabs: TabsSettingsDto[]) {
    return this.http.post(`${this.baseUrl}/${constructorId}/Tab/`, tabs)
      .pipe(catchError(err => {
        this.notificationsService.showError(err);
        return handleError(err);
      }));
  }
}

<dicts-header></dicts-header>
<h2 class="title">Праздничные и выходные дни ВУЗа</h2>
<div class="studyYear">
  <h3 class="yearTitle">Учебный год</h3>
    <kendo-dropdownlist
      [data]="years"
      [valuePrimitive]="true"
      textField="text"
      valueField="value"
      [(ngModel)]="filter.year"
      (selectionChange)="yearChange($event)"
      style="width: 250px; font-family: 'Montserrat';">
    </kendo-dropdownlist>
</div>

<div class="weekTypesEdit">
  <strong class="weekTypeTitle">Параметры календаря</strong>
  <button *ngIf="editable" kendoButton icon="edit" class="edit-button k-ml-5" style="width: 200px;" (click)="changeWeekTypeEditStatus()">
    Редактировать
  </button>
</div>
<div class="weekTypes">
  <p style="font-family: 'Montserrat';">Продолжительность недели</p>
  <div *ngFor="let weekType of weekTypes" style="float: right; margin-left: 27px; font-family: 'Montserrat';">
    <input [(ngModel)]="filter.weekType" [value]="weekType.value" type="radio" [disabled]="weekTypeEdit" (ngModelChange)="weekTypeChange()">
    <span style="margin-left: 5px; margin-bottom: 1px;">
      {{weekType.text}}
    </span>
  </div>
</div>
<div class="contentTitle">
  <strong style="margin-top: 7px;">Праздничные и выходные дни в учебном году</strong>
  <button *ngIf="editable" kendoButton icon="edit" class="edit-button k-ml-5" style="width: 200px;" (click)="changeCalendarEditStatus()">
    Редактировать
  </button>
</div>
<div class="content">
  <kendo-calendar selection="multiple" type="infinite"
  [focusedDate]="focusedDate"
  [(value)]="values"
    [min]="filter.minDate"
    [max]="filter.maxDate"
    [weekNumber]="false"
    class="myCalendar"
    [disabled]="calendarTypeEdit"
    (valueChange)="onChange($event)">
    <ng-template kendoCalendarMonthCellTemplate let-date let-context>
      <span [ngClass]="isColored(date)">{{ date.getDate() }}</span>
    </ng-template>
    <ng-template kendoCalendarHeaderTitleTemplate let-title>
      {{ changeTitle(title) }}
    </ng-template>
    <ng-template kendoCalendarNavigationItemTemplate let-title>
      <span class="custom">{{ changeMonthTitle(title) }}</span>
    </ng-template>
  </kendo-calendar>
  <div class="contentHolidays">
    <div class="firstHolidays">
      <strong class="titleHolidays">
        1 полугодие
      </strong>
      <div *ngFor="let holiday of firstSemesterHolidays" style="margin-top: 8px;">
        <span style="font-family: 'Montserrat'; font-size: 14px;">
          {{holiday}}
        </span>
      </div>
    </div>
    <div class="secondHolidays">
      <strong class="titleHolidays">
        2 полугодие
      </strong>
      <div *ngFor="let holiday of secondSemesterHolidays" style="margin-top: 8px;">
        <span style="font-family: 'Montserrat'; font-size: 14px;">
          {{holiday}}
        </span>
      </div>
    </div>
  </div>
</div>
<div class="buttonDiv" *ngIf="editable">
  <button kendoButton (click)="updateData()" class="updateButton">
    Заполнить из внешнего сервиса
  </button>
  <div>
    <button kendoButton (click)="CancelHandler()" style="margin-right: 15px;">
      Отмена
    </button>
    <button kendoButton themeColor="primary" (click)="saveCurrent()">
      Сохранить
    </button>
  </div>
</div>

<div kendoDialogContainer></div>

import {ApplicationStatusesEnum} from '../enums/application-statuses.enum';
import {ButtonThemeColor} from '@progress/kendo-angular-buttons/common/models';

export interface ApplicationStatus {
  id: string;
  name: string;
  applicationStatusEnum: ApplicationStatusesEnum;
  sortNumber?: number;
  deletable: boolean;
}

export const editableStatuses: ApplicationStatusesEnum[] = [
  ApplicationStatusesEnum.Sent,
  ApplicationStatusesEnum.InProgress,
];

export const displayFIOStatuses: ApplicationStatusesEnum[] = [
  ApplicationStatusesEnum.Sent,
  ApplicationStatusesEnum.Revision,
  ApplicationStatusesEnum.Rejected,
];

export interface AllowedStatus {
  enum: ApplicationStatusesEnum;
  description: string;
  color: ButtonThemeColor;
  // проверка доп. условий для отображения
  checkConditions?: boolean;
}

export const allowedStatuses = new Map<ApplicationStatusesEnum, AllowedStatus[]>([
  [
    ApplicationStatusesEnum.Sent,
    [
      {enum: ApplicationStatusesEnum.Revision, description: 'На доработку', checkConditions: true, color: 'base'},
      {enum: ApplicationStatusesEnum.Rejected, description: 'Отклонить', checkConditions: true, color: 'none'},
    ]
  ],
  [
    ApplicationStatusesEnum.InProgress,
    [
      {enum: ApplicationStatusesEnum.Revision, description: 'На доработку', checkConditions: true, color: 'base'},
      {enum: ApplicationStatusesEnum.Rejected, description: 'Отклонить', checkConditions: true, color: 'none'},
    ]
  ],
  [
    ApplicationStatusesEnum.Revision,
    [
      {
        enum: ApplicationStatusesEnum.InProgress,
        description: 'Вернуть в работу',
        checkConditions: true,
        color: 'primary'
      }
    ]
  ],
  [
    ApplicationStatusesEnum.Rejected,
    [
      {
        enum: ApplicationStatusesEnum.InProgress,
        description: 'Вернуть в работу',
        checkConditions: true,
        color: 'primary'
      }
    ]
  ],
]);

export const statusActions = new Map<ApplicationStatusesEnum, string>([
  [ApplicationStatusesEnum.Sent, ''],
  [ApplicationStatusesEnum.InProgress, 'ToInProgress'],
  [ApplicationStatusesEnum.Revision, 'ToReWork'],
  [ApplicationStatusesEnum.Rejected, 'ToReject'],
  [ApplicationStatusesEnum.Ready, 'ToReady'],
]);

import { NumberLiteralType } from "typescript";

export class GeneralInformationModel {
    id!: string;
    studentFio!: string;
    educationStandardId!: string;
    qualificationId!: string;
    diplomaFillStatus!: number;
    educationStandardName!: string;
    qualificationName!: string;
    printTemplateId!: string;
    studentId!: string;
    admissionOrder!: string;
}

export class basicStudentInformationModel {
    dictStudyFormName!: string;
    educationProgramLength!: number;
    dateStart!: string;
    dateFinish!: string;
    birthday!: string;
    isMale!: boolean;
    citizenshipName!: string;
    educationDocYear!: number;
    educationDocTypeName?: string;
}

export class basicInformationModel {
    averageGrade!: number;
    dictEducationDocTypeId?: string | null;
    diplomaInfoId?: string;
    diplomaTypeId?: string;
    diplomaReplacementReasonId?: string | null;
    duplicateRecipient?: string | null;
    giaProtocol!: number;
    giaProtocolDate!: Date | null;
    secChairman?: string;
    diplomaRegNumber?: number | null;
    diplomaIssueDate!: Date | null;
    diplomaStatusId?: number;
    diplomaSeries?: string | null;
    diplomaNumber?: string | null;
    diplomaAttachmentStatusId?: number;
    diplomaAttachmentSeries?: string | null;
    diplomaAttachmentNumber?: string | null;
    duplicateDiplomaStatusId?: number;
    duplicateDiplomaRegNumber?: number | null;
    replacementDate?: Date;
    duplicateDiplomaSeries?: string | null;
    duplicateDiplomaNumber?: string | null;
    duplicateDiplomaAttachmentStatusId?: string | null;
    duplicateDiplomaAttachmentSeries?: string | null;
    duplicateDiplomaAttachmentNumber?: string | null;
}

export class additionalDataModel {
    diplomaInfoId!: string;
    includeStudyFormInfo!: boolean;
    dictStudyFormId!: string;
    includeAccelerated!: boolean;
    isPartMasteredAtAnotherUniversity!: boolean;
    testUnitsNumber?: number;
    universityName?: string;
}

export class FillStatusesModel {
    id!: number;
    name!: string;
    fillStatus!: number;
}

export class DiplomaStatusesModel {
    id!: number;
    name!: string;
    fillStatus!: number;
}

export class ApplicationDataModel {
    id!: string;
    auditHours!: number;
    giaInvestment!: number;
    diplomaInfoId!: string;
    vkrTitle!: string;
    isFacultativeDisciplinesInfoIncluded!: boolean;
    disciplines!: disciplineForApplication[] | null;
}

export class disciplineForApplication {
    serialNumber?: number | null;
    disciplineId!: string | null;
    disciplineName!: string;
    markId?: string;
    markName?: string;
    creditUnit!: number;
    hours!: number;
    isExamination?: boolean;
    isFacultative?: boolean;
    isCourseWork?: boolean;
    isPractical?: boolean;
    isAddedManually?: boolean;
    total?: boolean;
    controlActionEnum?: number | null;
    attachmentDisciplineName?: string | null;
    disciplineType?: string;
    markValue?: number;
    editable?: boolean;
}

export class ReplacingReasonsModel {
    dictId!: string;
    dictType!: string | null;
    isActive!: boolean;
    name!: string;
    sortNumber!: number;
}

export class MarksModel {
    icon!: string;
    id!: string;
    name!: string;
    shortName!: string;
    value!: number;
}

export class TypeDisciplineModel {
    id!: string;
    isPractice!: boolean;
    name!: string;
    shortName!: string | null;
    usedInStudyProcess!: boolean;
}

export class DisciplineModel {
    id!: string;
    name!: string;
    shortName?:string;
}

export class saveDisciplineModel {
    disciplineId!: string;
    disciplineName!: string;
    shortName?: string;
    attachmentDisciplineName!: string | null;
    controlActionEnum!: number | null;
    isFacultative!: boolean | null;
    isCourseWork!: boolean | null;
    hours!: number | null;
    creditUnit!: number | null;
    disciplineType!: string;
    markId?: string | null;
    serialNumber?: number | null;
    isAddedManually?: boolean;
    hoursContactWork!: number | null;
}

export class ControlActionModel {
    id!: string;
    name!: string;
    shortName!: string;
    controlActionEnum!: number;
}

export class TypeDiplomaModel {
    id!: string;
    name!: string;
}
export class PrintTemplateDiplomaModel {
    printTemplateId!: string;
    printTemplateName!: string;
    isDefault!: boolean;
}

export class editDisciplineModel {
    deletedDisciplineId!: string | null;
    editedDiscipline!: saveDisciplineModel | null;
}

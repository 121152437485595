import {Guid} from "guid-typescript";
import {Kafedra} from "./kafedra.model";
import {CommonDictPerson} from "./person.model";
import { DictSignatoryRoleRead } from "./global-signatory-role";
import {DictPersonStatus} from "./person-status.model";

export class GlobalSignatory {
  public id!: Guid | string;
  public person!: CommonDictPerson;
  public postName!: string;
  public postSName!: string;
  public printPostName!: string;
  public department!: Kafedra | null;
  public signatoryRole!: DictSignatoryRoleRead;
  public personStatus!: DictPersonStatus | null;
  public personStatusWork?: boolean;
  public vacationStart!: Date | string | null;
  public vacationEnd!: Date | string | null;
  public interimSignatoryId?: Guid;
  public interimPostName!: string | null;

  readonly personStatusId: Guid | string | undefined = this.personStatus?.id;
  readonly signatoryRoleId: Guid | string | undefined = this.signatoryRole?.id;
}

export class PostGlobalSignatory {
  public personId!: Guid | string;
  public postName!: string;
  public postSName!: string;
  public printPostName!: string;
  public departmentId!: Guid | string | null;
  public signatoryRoleId!: Guid | string | null;
  public personStatusId!: Guid | string | null;
  public vacationStart!: Date | string | null;
  public vacationEnd!: Date | string | null;
  public interimPostName!: string | null;
}

export class PutGlobalSignatory {
  public id!: Guid | string;
  public personId!: Guid | string;
  public postName!: string;
  public postSName!: string;
  public printPostName!: string;
  public departmentId!: Guid | string | null;
  public signatoryRoleId!: Guid | string | null;
  public personStatusId!: Guid | string | null;
  public vacationStart!: Date | string | null;
  public vacationEnd!: Date | string | null;
  public interimPostName!: string | null;
}

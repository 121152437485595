<mfc-settings></mfc-settings>
<h1>Штамп ЭП</h1>

<div class="content" [formGroup]="formGroup">
    <span class="field">
        <h4>Тип подписи</h4>
        <kendo-dropdownlist
                [data]="signTypes"
                [valuePrimitive]="true"
                (valueChange)="onTypeChange($event)"
                formControlName="signStampType"
                textField="value"
                valueField="enum">
        </kendo-dropdownlist>
    </span>
    <span class="field top-align">
        <h4>Шаблон штампа ЭП</h4>
        <span>
            <span class="bar">
                <kendo-fileselect
                        [multiple]="false"
                        [restrictions]="fileRestrictions"
                        [accept]="allowedExtensions"
                        (valueChange)="createStamp($event)"
                        (remove)="removeStamp()"
                        formControlName="file">
                    <ng-template kendoFileSelectFileInfoTemplate let-files>
                        <div>{{ files[0].name }}</div>
                    </ng-template>
                </kendo-fileselect>
                <h6 class="required">*</h6>
                <kendo-icon kendoTooltip
                            [tooltipWidth]="320"
                            [title]="tooltip"
                            name="info"
                            tooltipClass="mfc-tooltip"
                            position="right"
                            size="medium">
                </kendo-icon>
            </span>
            <h6 class="file-requirement">До 1 файла формата .doc или .docx</h6>
            <a *ngIf="formGroup.value.file" class="file-link" (click)="downloadFile(formGroup.value.file)">
                Скачать
            </a>
        </span>
    </span>
    <span class="field">
        <button (click)="onCancel()" kendoButton>Закрыть</button>
    </span>
</div>

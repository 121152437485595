import { Guid } from 'guid-typescript';
import { DictAttestation } from './dict.model';

export const statementStringsData: StatementData[] = [
  { value: 0, text: 'Выбранных ведомостей' },
  { value: 2, text: 'Выбранных ведомостей с успеваемостью' },
  { value: 3, text: 'Выбранных ведомостей с посещаемостью' },
  { value: 1, text: 'Выбранных ведомостей с успеваемостью и посещаемостью' }
];

export class GetMassPrintSheetData {
  public signatory!: Signatory;
  public disciplines!: Discipline[];
  public dictAttestationId?: string;

  public contingentDate?: Date;
}

class Signatory {
  public fio!: string;
  public personId!: Guid;
  public postName!: string;
}

export class Discipline {
  public eduGroupId!: string;
  public disciplineId!: string;
  public electiveDisciplineId!: string | null;
  public semesterNum?: number;
}

export class StatementData {
  public value!: number;
  public text!: string;
}

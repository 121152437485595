import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class DepartmentsAdminService {

    baseUrl = `${environment.lkPersonApiEndpoint}`;

    constructor(private http: HttpClient) { }

    public getAllDepartments(){
        return this.http.get(this.baseUrl + 'department/getDepartments');
    }

    public getDepartmentsName(){
        return this.http.get(this.baseUrl + 'department/getDepartmentNames')
    }

    public getDepartmentsType(){
        return this.http.get(this.baseUrl + 'departmentTypes/getDepartmentTypes')
    }

    public addType(data: any){
        return this.http.post(this.baseUrl + 'departmentTypes/addDepartmentType', data)
    }

    public updateType(data: any, id: string){
        data.externalId = id;
        return this.http.put(this.baseUrl + 'departmentTypes/updateDepartmentType', data)
    }

    public updateDepartments(data: any, id: string){
        data.externalId = id;
        return this.http.put(this.baseUrl + 'department/updateDepartment', data)
    }

    public updateDepartmentsName(data: any, id: string){
        data.externalId = id;
        return this.http.put(this.baseUrl + 'department/updateDepartmentName', data)
    }
    
}
import {Injectable} from '@angular/core';
import {catchError, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {ApplicationCategory} from '../../../models/mfc/dicts/application-category.model';
import {handleError} from '../../../helpers/errorHandle-helper';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApplicationCategoryService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/ApplicationCategory/`;

  constructor(private http: HttpClient) { }

  public getApplicationCategories(showHidden = false): Observable<ApplicationCategory[]> {
    return this.http.get<ApplicationCategory[]>(this.baseUrl)
      .pipe(map((data) => data.filter(a => showHidden ? a : !a.hidden) ?? []));
  }

  public getApplicationCategory(id: string): Observable<ApplicationCategory> {
    return this.http.get<ApplicationCategory>(`${this.baseUrl}${id}`);
  }

  public addApplicationCategory(category: ApplicationCategory): Observable<ApplicationCategory> {
    return this.http.post<ApplicationCategory>(this.baseUrl, category)
      .pipe(catchError(handleError));
  }

  public updateApplicationCategory(category: ApplicationCategory): Observable<ApplicationCategory> {
    return this.http.put<ApplicationCategory>(this.baseUrl, category)
      .pipe(catchError(handleError));
  }

  public removeApplicationCategory(id: string): Observable<ApplicationCategory> {
    return this.http.delete<ApplicationCategory>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

import {Component, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AddEvent, CellClickEvent, GridComponent, RemoveEvent, EditEvent } from '@progress/kendo-angular-grid';

import { ClassRoomUserAccess } from '../../../models/useraccess/classroom/classroomuseraccess.model';
import { AssignAuditorium } from '../../../models/classroom/assign-auditorium.model';
import { Department } from '../../../models/announcement/department.model';
import { Building } from '../../../models/classroom/building.model';
import { Persons } from "../../../models/classroom/person.model";
import { ClassRoomUserAccessService } from '../../../services/useraccess/classroom-user-access.service';
import { SearchPersonsService } from "../../../services/classroom/search-persons.service";
import { AssignAuditoriumService } from '../../../services/classroom/assign-auditorium.service';
import { BuildingService } from "../../../services/classroom/building.service";
import { StructuralService } from '../../../services/classroom/structural.service';

import { NotificationsService } from '../../../services/notifications/notifications.service';

import { arrayRewrite, valueChange, valueChangeBySourceName } from "../../../helpers/multiselect-helper";
import { TokenStorageService } from "../../../services/token.service";
import { JwtHelperService}  from "@auth0/angular-jwt";
import { checkRole } from "../../../helpers/token/role-check";
import { Role } from "../../../models/useraccess/role";
import {isChildOf} from "../../../helpers/elementRef-helper";

@Component({
  selector: 'app-classroom-access',
  templateUrl: './classroom-access.component.html',
  styleUrls: ['./classroom-access.component.scss']
})
export class ClassroomAccessComponent implements OnInit {
  public isAdmin: boolean;
  public virtual: any = {
      itemHeight: 28,
  };
  public buildingsEdit: any[] = [];
  public classRoomPurposesEdit: any[] = [];
  public departmentsEdit: any[] = [];

  public accesses: ClassRoomUserAccess[] = [];
  public persons: Persons[] = [];
  public buildings: Building[] = [];
  public classRoomPurposes: AssignAuditorium[] = [];
  public departments: Department[] = [];

  public boolOptions: any[] = [
    {id: true, text: 'Да'},
    {id: false, text: 'Нет'}
  ]

  public dropdownOptions: any[] = [
    {id: 1, text: 'Чтение' },
    {id: 2, text: 'Запись' }
  ]

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };

  public position: "top" | "bottom" | "both" = "top";
  public formGroup: FormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;

  public opened = false;
  public loading = false;

  @Input() searchFIO: string = '';

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  public gridData: any[] = [];

  public settings: boolean = false;

  constructor(private renderer: Renderer2,
              private dialogService: DialogService,
              private buildingService: BuildingService,
              private structuralService: StructuralService,
              private assignAuditoriumService: AssignAuditoriumService,
              private personService: SearchPersonsService,
              private notificationService: NotificationsService,
              private userAccessService: ClassRoomUserAccessService,
              private tokenStore: TokenStorageService,
              private jwtHelper: JwtHelperService
  ) {
    this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
  }

  ngOnInit(): void {
    this.getAccess();
    this.getAllPersons();
    this.getAllAssigns();
    this.getAllDepartments();
    this.getAllBuildings();
  }

  // Get access
  public getAccess() {
    this.userAccessService.getAccess()
      .subscribe(
        response => {
          this.accesses = response;
          
          if (this.accesses) {
            for (let i = 0; i < this.accesses.length; i++) {
              valueChangeBySourceName(this.accesses[i].buildings,
                "buildingId", null, "");
              valueChangeBySourceName(this.accesses[i].buildings,
                "buildingName", null, "Все");
              valueChangeBySourceName(this.accesses[i].departments,
                "departmentId", null, "");
              valueChangeBySourceName(this.accesses[i].departments,
                "departmentName", null, "Все");
              valueChangeBySourceName(this.accesses[i].classRoomPurposes,
                "classRoomPurposeId",  null, "");
              valueChangeBySourceName(this.accesses[i].classRoomPurposes,
                "classRoomPurposeName",  null, "Все");
            }
          }
          this.gridData = this.accesses;
        }
      );
  }

  // Назначения
  public getAllAssigns() {
    this.assignAuditoriumService.getAllAssigns()
    .subscribe(
      response => {
        this.classRoomPurposes = response;
       
        this.classRoomPurposes = this.classRoomPurposes.sort((a, b) => a.classRoomPurposeName.localeCompare(b.classRoomPurposeName));
        if (this.classRoomPurposes) {
          let item = new AssignAuditorium();
          item.classRoomPurposeId = "";
          item.classRoomPurposeName = 'Все';
          this.classRoomPurposes.unshift(item);
        }
      }
    );
  }

  // Подразделения
  public getAllDepartments() {
    this.structuralService.getAllStructural()
    .subscribe(
      response => {
        this.departments = response;
        
        this.departments = this.departments.sort((a, b) => a.departmentName.localeCompare(b.departmentName));
        if (this.departments) {
          let item = new Department();
          item.departmentId = "";
          item.departmentName = 'Все';
          this.departments.unshift(item);
        }
      }
    );
  }

    // Корпуса
    public getAllBuildings() {
      this.buildingService.getAllBuildings()
      .subscribe(
        response => {
          this.buildings = response;
          this.buildings = this.buildings.sort((a, b) => a.buildingName.localeCompare(b.buildingName));
          if (this.buildings) {
            let item = new Building();
            item.buildingId = "";
            item.buildingName = 'Все';
            this.buildings.unshift(item);
          }
        }
      );
    }

  // Get persons
  public getAllPersons() {
    this.personService.getAllPersons()
      .subscribe(
        response => {
          this.persons = response;
        }
      );
  }

  buildingsList(dataItem: any) {
    let string = "";
    for(var t = 0; t < dataItem.length; t++) {
      string += dataItem[t].buildingName + ', ';
    }
    string = string.slice(0, -2)
    return string;
  }

  classRoomPurposesList(dataItem: any) {
    let string = "";
    for(var t = 0; t < dataItem.length; t++) {
      string += dataItem[t].classRoomPurposeName + ', ';
    }
    string = string.slice(0, -2);
    return string;
  }

  departmentsList(dataItem: any) {
    let string = "";
    for(var t = 0; t < dataItem.length; t++) {
      string += dataItem[t].departmentName + ', ';
    }
    string = string.slice(0, -2)
    return string;
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex): void {
    this.isNew = false;
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Start adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.buildingsEdit = [];
    this.classRoomPurposesEdit = [];
    this.departmentsEdit = [];

    this.formGroup = formGroup({
      id: 0,
      personId: '',
      classRoomPurposes: '',
      buildings: '',
      departments: '',
      canEdit: false,
      dictionaryAccessLevel: 1,
      settingsAccess: false,
    });

    this.isLine = true;
    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    this.saveRow();
  }

  //Start Editing***
//  public editHandler({sender, columnIndex, rowIndex, dataItem}: CellClickEvent): void {
  public editHandler(args: EditEvent): void {
   
    if (this.isLine ||(this.formGroup && !this.formGroup.valid)) {
      //this.saveCurrent();
      return;
    }
    let filterBuildings: any = [];
    let filterClassRoomPurposes: any = [];
    let filterDepartments: any = [];

    // Вывод корпусов в редактирование
    for(var t = 0; t < args.dataItem.buildings.length; t++) {
      for (var i=0; i < this.buildings.length; i++) {
        if(this.buildings[i].buildingId === args.dataItem.buildings[t].buildingId)
        {
          filterBuildings.push(this.buildings[i].buildingId)
        }
      }
    }

    // Вывод назначений в редактирование
    for(var t = 0; t < args.dataItem.classRoomPurposes.length; t++) {
      for (var i=0; i < this.classRoomPurposes.length; i++) {
        if(this.classRoomPurposes[i].classRoomPurposeId === args.dataItem.classRoomPurposes[t].classRoomPurposeId)
        {
          filterClassRoomPurposes.push(this.classRoomPurposes[i].classRoomPurposeId)
        }
      }
    }

    // Вывод подразделений в редактирование
    for(var t = 0; t < args.dataItem.departments.length; t++) {
      for (var i=0; i < this.departments.length; i++) {
        if(this.departments[i].departmentId === args.dataItem.departments[t].departmentId)
        {
          filterDepartments.push(this.departments[i].departmentId)
        }
      }
    }

    this.buildingsEdit = filterBuildings;
    this.classRoomPurposesEdit = filterClassRoomPurposes;
    this.departmentsEdit = filterDepartments;

    this.isLine = true;
    this.formGroup = formGroup(args.dataItem);
    this.editedRowIndex = args.rowIndex;

    args.sender.editRow(args.rowIndex, this.formGroup );
  }

  //Save data
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup !== undefined) {
      if(this.formGroup.value.id === 0) {
        this.formGroup.value.id = null;
      }

      valueChange(this.formGroup.value.buildings, "", null);
      valueChange(this.formGroup.value.classRoomPurposes, "", null);
      valueChange(this.formGroup.value.departments, "", null);

      this.userAccessService.saveAccess(this.formGroup.value)
      .subscribe(
        response => {
          this.getAccess();
          this.notificationService.showSuccess("Добавлено");
        },
        error => {
          this.notificationService.showError("Не удалось добавить запись");
        }
      );
    }
    this.closeEditor(this.grid);
  }

  //Cancel
  public cancelHandler(): void {
    this.isLine = false;
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  clearData() {
    this.gridData = this.accesses;
    this.searchFIO = ''
  }

  filterData(dataItem: any) {
    this.gridData = this.accesses.filter((s) => s.personName.toLowerCase().includes(dataItem.toLowerCase()));
  }

  // Ивенты ячеек при редактировании
  @HostListener('document:keydown.enter', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    let element = (event as any).target;
    if (element.className == 'k-input-inner' && isChildOf(element, 'filter')) {
      this.filterData(element.value);
    }
  }

  //Deleting an entry
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста, подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.personName} из настроек доступа?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.userAccessService.deleteAccess(dataItem.id)
          .subscribe(
            (response: any) => {
              this.getAccess();
              this.notificationService.showSuccess("Удалено");
            },
            error => {
            this.notificationService.showError(error.error);
          }
          );
            }
          else{
            this.opened = false;
          }
      }
    });
  }

  //#region Multiselect Events

  public buildingValueChange(value: any, dataItem: any) {
    dataItem.buildings = arrayRewrite(dataItem.buildings, "buildingId");
    this.buildingsEdit = arrayRewrite(value);
  }

  public assignValueChange(value: any, dataItem: any) {
    dataItem.assigns = arrayRewrite(dataItem.assigns, "classRoomPurposeId");
    this.classRoomPurposesEdit = arrayRewrite(value);
    this.getAllBuildings();
  }

  public departmentValueChange(value: any, dataItem: any) {
    dataItem.departments = arrayRewrite(dataItem.departments, "departmentId");
    this.departmentsEdit = arrayRewrite(value);
  }

  //#endregion
}

// TODO: Change key after API integration
const formGroup = (dataItem:
  {
    id?: any;
    personId?: any;
    classRoomPurposes?: any;
    buildings?: any;
    departments?: any;
    canEdit?: boolean;
    dictionaryAccessLevel?: any;
    settingsAccess?: false;
  }) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    personId: new FormControl(dataItem.personId, Validators.required),
    classRoomPurposes: new FormControl(dataItem.classRoomPurposes, Validators.required),
    buildings: new FormControl(dataItem.buildings, Validators.required),
    departments: new FormControl(dataItem.departments, Validators.required),
    canEdit: new FormControl(dataItem.canEdit, Validators.required),
    dictionaryAccessLevel: new FormControl(dataItem.dictionaryAccessLevel, Validators.required),
    settingsAccess: new FormControl(dataItem.settingsAccess, Validators.required)
  });
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/publications/errorHandle-helper";
import {DictStateExamCommissionTypeModel} from "../../models/gia/dicts/dictStateExamCommissionType.model";

@Injectable({
  providedIn: 'root'
})
export class DictStateExamCommissionTypeService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.gia.dictStateExamCommissionType}`;
  constructor(private http: HttpClient) { }

  getList(): Observable<DictStateExamCommissionTypeModel[]> {
    return this.http.get<DictStateExamCommissionTypeModel[]>(this.baseUrl);
  }

  update(dictStateExamCommissionType: DictStateExamCommissionTypeModel): Observable<DictStateExamCommissionTypeModel> {
    return this.http.put<DictStateExamCommissionTypeModel>(this.baseUrl, dictStateExamCommissionType)
      .pipe(catchError(handleError));
  }
}

import { Component, OnInit, Injectable} from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Show } from 'src/app/models/announcement/show.model';
import { ShowService } from 'src/app/services/announcement/show.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { PostComment } from 'src/app/models/announcement/post-comment.model';
import  {Router, RouterLink} from "@angular/router";
import { CommentsService } from 'src/app/services/announcement/comments.service';
import { AnnouncementService } from 'src/app/services/announcement/announcement.service';
import { AnnouncementUserAccessService } from '../../../services/useraccess/announcement-user-access.service';
import { AnnouncementUserAccess } from '../../../models/useraccess/announcement/announcementuseraccess.model';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';
import { FileRestrictions, SelectEvent, FileSelectComponent } from "@progress/kendo-angular-upload";
import urlJoin from 'url-join';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from 'src/app/services/token.service';
import { Role } from 'src/app/models/useraccess/role';
import { checkRole } from '../../../helpers/token/role-check';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
    selector: 'app-show',
    templateUrl: './show.component.html',
    styleUrls: ['./show.component.scss'],

})

//@Injectable()
export class ShowComponent  implements OnInit{
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);

    public id: number | undefined;
    public messageId: number = 0;

    public show: Show | undefined;

    public title: string = "";
    public content: string = "";
    public authorFio: string = "";
    public userId: number = 0;
    public authorId: number = 0;
    public published : Date = new Date();
    public attachment: [] = [];
    public message_type : number = 0;
    public tags: Show["tags"] = [];
    public post: string = "";
    public draft: boolean = false;

    public isAdmin: boolean = false;
    public personId: string = "";
    public accessLevel: boolean = false;
    public accesses: AnnouncementUserAccess[] = [];

    public refreshComments: number = 0;

    public myRestrictions: FileRestrictions = {
      maxFileSize: environment.announcement.commentMaxFileSize,
      allowedExtensions: [".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".jpeg", ".gif", ".tif", ".bmp", ".png", ".zip", ".txt"],
    };

    public position: string = "";

    public text_comment: string = "";
    public commentAttachments = new Array();

    constructor(
        private activatedroute: ActivatedRoute,
        private showService: ShowService,
        private http: HttpClient,
        private router: Router,
        private commentsService: CommentsService,
        private announcementService: AnnouncementService,
        private location: Location,
        private jwtHelper: JwtHelperService,
        private tokenStore: TokenStorageService,
        private userAccessService: AnnouncementUserAccessService,
        private notificationService: NotificationsService,
        private dialogService: DialogService,
      ) {
        this.isAdmin = checkRole(tokenStore, jwtHelper, Role.Admin);
      }

    ngOnInit() {
      this.getShow(this.activatedroute.snapshot.params['id']);
      this.getAccess();
    }

    public goToBack(){
      this.location.back();
    }

    public getShow(id: number) {
        this.showService.getShow(id)
          .subscribe(
            response => {
            this.show = response;
            this.userId = this.show.userId;
            this.authorId = this.show.authorId;
            this.title = this.show.title;
            this.content = this.show.content;
            this.authorFio = this.show.authorFio;
            this.published = this.show.published;
            this.attachment = this.show.attachments;
            this.message_type = this.show.messageType;
            this.tags = this.show.tags;
            this.post = this.show.post;
            this.messageId = +id;
            this.position = this.show.post;
            this.draft = this.show.isDraft;
            let refreshBadge = 0;
            this.announcementService.Badge(refreshBadge + 1);

          //  console.log('***',this.messageId);
            }
          );
    }

    public openpopup(){
      const dialog: DialogRef = this.dialogService.open({
        title: "Пожалуйста подтвердите",
        content: `Вы действительно хотите удалить данное оповещение?`,
        actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
        width: 450,
        height: 200,
        minWidth: 250,
      });
      dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
        } else {
            if(result.text == "Да"){
              this.deleteMessage();
            }
          }
      })
    }

    public deleteMessage(){
    //  console.log('id',this.messageId);
        this.showService.deleteMessage(this.messageId)
        .subscribe(message => {
            this.notificationService.showSuccess("Оповещение удалено");
            this.location.back();
        });
    }

    public sendComment(){
        if(this.commentAttachments.length == 0 && this.text_comment.trim() == ""){
          this.notificationService.showError("Комментарий не должен быть пустым");
        }
        else{
          var formData = new FormData();
          formData.append('messageId', this.messageId.toString());
          formData.append('userPost', this.post !== "" ? this.post : this.position);
          formData.append('text', this.text_comment);
          this.commentAttachments.forEach(element => {
            formData.append('commentAttachments', element);
          });

          this.http.post(this.baseUrl + '/comment', formData).subscribe(() => {
            this.refreshComments++;
          });

          this.text_comment = "";
          this.commentAttachments.length = 0;
        }
    }

    public selectEventHandler(e: SelectEvent){
    //  fileSelect.clearFiles();
      for (let i = 0; i < e.files.length; i++){
        if(e.files[i].validationErrors == undefined) this.commentAttachments.push(e.files[i].rawFile)
        else this.notificationService.showError("Файл данного формата добавить нельзя");
      }
      if(this.commentAttachments.length > 5){
        this.notificationService.showError("Можно добавить не более 5 файлов");
        this.commentAttachments.length = 5;
      }

    }

    public valueChange(fileSelect: FileSelectComponent) {
  /*    if(this.commentAttachments !== null && this.commentAttachments.length > 5)
        this.commentAttachments.length = 5;*/
    }

    public removeFile(i : number) {
      this.commentAttachments.splice(i, 1);
      return false;
    }

    public usePattern(messageId: number){
        this.announcementService.SendPattern.next({
          messageId: messageId,
          pattern:true,
        });
        this.router.navigate(['author/modify']);
    }

    public EditAnnouncement(messageId: number){
        if (this.draft == false){
          this.router.navigate(['author/announcement', messageId]);
        } else {
          this.router.navigate(['author/modify', messageId]);
        }
    }

    public getFile(fileid: string, filename: string){
      var url = this.baseUrl + "/getfile?fileid=" + fileid + "&filename=" + filename;
      this.http.get(url, { responseType: 'blob' }).subscribe(
        (response) => {
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
          if (filename) {
            downloadLink.setAttribute('download', filename);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink)
        }
      )
  }

  public getAccess (){
    this.userAccessService.getAccess(this.personId)
      .subscribe(res => {
          this.accesses = res;
        }
      );
      this.accessLevel = this.accesses.length > 0 ? this.accesses[0].userAccessLevel : false;
  }
}


import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {DropDownFilterSettings, VirtualizationSettings} from '@progress/kendo-angular-dropdowns';
import {AddEvent, CancelEvent, CellClickEvent, GridComponent} from '@progress/kendo-angular-grid';
import {groupBy, GroupDescriptor} from '@progress/kendo-data-query';
import {DictService} from "../../../services/disciplineworkload/externals/dict.service";
import {
  AvailableSemesters,
  DictAdditionalWork, DictExamTypeParameter, DictStudyForm, DictTrainingLevel, Person, PPSGet
} from '../../../models/disciplineworkload/externals.model';
import {DepartmentService} from "../../../services/disciplineworkload/externals/department.service";
import {YearAndSemestr} from "../../../models/disciplineworkload/yearAndSemestrs.model";
import {
  DisciplineWorkloadContingentService
} from "../../../services/disciplineworkload/discipline-workload-contingent.service";
import {HttpParams} from "@angular/common/http";
import {AdditionalWorkService} from "../../../services/disciplineworkload/additional-work.service";
import {arrayRewrite} from "../../../helpers/multiselect-helper";
import {EduGroupService} from "../../../services/disciplineworkload/externals/edu-group.service";
import {PersonService} from "../../../services/disciplineworkload/externals/person.service";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {DepartmentWorkloadService} from "../../../services/disciplineworkload/department-workload.service";
import {CreateQuery} from "../../../helpers/createQuery-helper";
import {EducationPlanService} from "../../../services/disciplineworkload/externals/education-plan.service";
import {TypeWorkEnum} from "../../../models/disciplineworkload/enums/type-work.enum";
import {Faculty} from "../../../models/disciplineworkload/departmentworkload.model";
import {getErrorMessage} from "../../../helpers/errorHandle-helper";
import {saveAs} from "@progress/kendo-file-saver";
import { isChildOf } from 'src/app/helpers/elementRef-helper';
import { ReportsService } from '../../../services/disciplineworkload/reports.service';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { CoefficientTypeWorkEnum } from '../../../models/disciplineworkload/enums/cathedral-type-work.enum';

@Component({
  selector: 'department-workload',
  templateUrl: './department-workload.component.html',
  styleUrls: ['./department-workload.component.scss']
})
export class DepartmentWorkloadComponent implements OnInit {

  //#region Global var

  //#region Filters

  public studyForms: DictStudyForm[] = [];
  public faculties: Faculty[] = [];
  public trainingLevels: DictTrainingLevel[] = [];
  public yearAndSemestrs: AvailableSemesters[] = [];
  public kafedras: any[] = [];

  //#region Ng model

  public facultiesEdit: any;
  public kafedraEdit: any;
  public yearAndSemestrsEdit: any;
  public trainingLevelsEdit: any[] = [];
  public studyFormEdit: any;

  //#endregion

  //#region Settings

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public filterVirtualization: boolean | VirtualizationSettings = {
    itemHeight: 28
  };

  public numberFormatOptions: NumberFormatOptions = {
    style: 'decimal'
  };

  //#endregion

  //#endregion

  //#region Main load

  @ViewChild('mainLoad') private mainGrid!: GridComponent;

  public formGroup!: FormGroup | undefined;

  public departmentLoads: any[] = [];

  public mainGridData: any[] = [];
  public mainGridDataNotGrouped: any[] = [];
  public mainGridOptions: {
    loading: boolean,
    groups: GroupDescriptor[],
    hasAssignments: boolean,
    row: {
      isMassOperation: boolean,
      iskafedraWork: boolean,
      isFlow?: boolean,
      isClassesBySubGroups?: boolean,
      isGroup?: boolean,
      isSubGroup?: boolean,
      isDiscipline?: boolean,
      groupHasSubgroups?: boolean,
      isWorkType?: boolean,
      isExamWork?: boolean,
      isSubGroupDivided?: boolean,
      allowEditCoefficient?: boolean
    }
  } = {
    loading: false,
    groups: [
      { field: "semesterNumber" },
      { field: "discipline.name" },
      { field: "groupByGroupId" },
      { field: "groupBySubGroupNumber" },
      { field: "value" },
      { field: "typeWorks.typeWork.name" }
    ],
    hasAssignments: false,
    row: {
      isMassOperation: false,
      iskafedraWork: false
    }
  }

  private editedRowIndex!: number | undefined;

  public subGroupEmpty: any ={
    id: null,
    hours: null,
    personId: null,
  };

  //#endregion

  //#region Extra load

  @ViewChild('extraGrid') private extraGrid!: GridComponent;

  public formGroupAdditional!: FormGroup | undefined;

  public dictAdditionalWorks: DictAdditionalWork[] = [];

  public extraWork: any[] = [];
  public extraGridState: {
    isEdit: boolean,
    isAdd: boolean,
    opened: boolean,
    itemToDelete: any,
    selectedId: string | null,
  } = {
    isEdit: false,
    isAdd: false,
    opened: false,
    itemToDelete: null,
    selectedId: null,
  };
  private editedAdditionalRowIndex!: number | undefined;

  //#endregion

  public eduGroups: any[] = [];
  public persons: PPSGet[] = [];

  public typeWork: any = {
    kafedra: "Кафедра",
    faculty: "Факультет, институт"
  };
  public typeWorks: any = TypeWorkEnum;

  public dictExamTypeParameters: DictExamTypeParameter[] = [];

  //#endregion

  constructor(private dictService: DictService,
              private additionalWorkService: AdditionalWorkService,
              private personService: PersonService,
              private eduGroupService: EduGroupService,
              private departmentWorkloadService: DepartmentWorkloadService,
              private notificationService: NotificationsService,
              private sanitizer: DomSanitizer,
              private educationPlanService: EducationPlanService,
              private reportService: ReportsService,
              private renderer: Renderer2) { }

  //#region Data

  private async getDepartments(setEditModel: boolean = true) {
    await this.departmentWorkloadService.getAvailableCathedras().toPromise().then(
      (response) => {
        this.kafedras = response!;
        if (setEditModel) this.kafedraEdit = response![0];
      },
      error => this.notificationService.showError(getErrorMessage(error)))
  }

  private async getStudyForms(setEditModel: boolean = true) {
    await this.dictService.getStudyForms().toPromise().then(
      (response) => {
        this.studyForms = response!;

        if (this.studyForms && this.studyForms.length > 0) {
          let item = new DictStudyForm();
          item.id = "";
          item.studyFormName = 'Все';
          item.studyFormSName = 'Все';

          this.studyForms.unshift(item);

          if (setEditModel) this.studyFormEdit = response![0];
        }
      })
  }

  private async getTrainingLevels(setEditModel: boolean = true){
    await this.dictService.getTrainingLevels().toPromise().then(
      (response) => {
        this.trainingLevels = response!;
        if (this.trainingLevels) {
          let item = new DictTrainingLevel();
          item.id = "";
          item.name = 'Все';
          this.trainingLevels.unshift(item);

          if (setEditModel) this.trainingLevelsEdit = [ this.trainingLevels[0].id ];
        }
      },
      reason => this.notificationService.showError(getErrorMessage(reason), 5000))
  }

  private async getFaculties(setEditModel: boolean = true) {
    this.faculties = [];
    if (setEditModel) this.facultiesEdit = null;

    await this.departmentWorkloadService.getFaculties({
      year: this.yearAndSemestrsEdit.year,
      semester: this.yearAndSemestrsEdit.semester,
      kafedraId: this.kafedraEdit.id,
    }).toPromise().then(response => {
        this.faculties = response!;

        //console.log(this.faculties);

        if (this.faculties && this.faculties.length>0) {
          let item = new Faculty();
          item.id = "";
          item.shortName = 'Все';

          this.faculties.unshift(item);
          if (setEditModel) this.facultiesEdit = this.faculties[0];
        }
      })
  }

  private async getAllYearAndSemestrs(setEditModel: boolean = true) {
    await this.educationPlanService.getAvailableSemesters().toPromise()
      .then((response) => {
        response!.forEach((item) => {
          item.name = `${item.year} - ${item.year + 1}, ${item.semester} семестр`;
        })
        this.yearAndSemestrs = response!;
        if (setEditModel) this.yearAndSemestrsEdit = response![0];
      })
  }

  private getAdditionalWorks() {
    this.extraWork = [];

    if (this.yearAndSemestrsEdit == null || this.kafedraEdit == null)
      return;

    let query = CreateQuery({
      studyYear: this.yearAndSemestrsEdit.year,
      semester: this.yearAndSemestrsEdit.semester,
      kafedraId: this.kafedraEdit.id,
      dictTrainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      dictStudyFormId: this.studyFormEdit?.id,
      facultyId : this.facultiesEdit?.id
    });

    this.additionalWorkService.getAdditionalWorks(query).subscribe(
      response => this.extraWork = response,
      error => this.notificationService.showError(getErrorMessage(error)));
  }

  private getDepartmentLoad() {
    this.mainGridOptions.loading = true;

    if (this.yearAndSemestrsEdit == null || this.kafedraEdit == null || this.studyFormEdit == null)
      return;

    let query = CreateQuery({
      studyYear: this.yearAndSemestrsEdit.year,
      semester: this.yearAndSemestrsEdit.semester,
      kafedraId: this.kafedraEdit.id,
      dictTrainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      dictStudyFormId: this.studyFormEdit?.id == '' ? null : this.studyFormEdit?.id,
      facultyId: this.facultiesEdit?.id == '' ? null : this.facultiesEdit?.id
    });

    this.departmentWorkloadService.getCathedralLoad(query).subscribe(
      (response) => {
        this.mainGridOptions.hasAssignments = response && response.length > 0;

        if (!response) return;

        for (let i = 0; i < response.length; i++) {
          let discipline = response[i];

          if (discipline.disciplineConjugations == null || discipline.disciplineConjugations.length == 0)
            continue;

          discipline.discipline.name = discipline.discipline.name +
            ' (+' + discipline.disciplineConjugations.join(', ') + ')';
        }

        this.departmentLoads = response;

        const groups = this.departmentLoads
          .reduce(function (r, a) {
            return r.concat(a.groups.map(function (b: any) {
              return {
                semesterNumber: b.semesterNumber,
                discipline: a.discipline,
                groupByGroupId: b.id,
                isGroup: true,
                groups: b
              }
            }))
          }, [])
        const subGroupNumber = groups.reduce(function (r:any, a:any) {
          return r.concat(a.groups.typeWorks.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              groupBySubGroupNumber: a.groupByGroupId + "_подгруппа" + b.subGroupNumber,
              subGroupNumber: b.subGroupNumber > 0 ? b.subGroupNumber : null,
              classesBySubGroups: true,
              emptyGroupRow: b.subGroupNumber == 0 || b.subGroupNumber == null
            };
          }));
        }, []);
        const uniqueSubGroupNumber = [
          ...new Map(subGroupNumber.map((item: any) => [item["groupBySubGroupNumber"], item])).values(),
        ] as any[];
        const groupsWorkTypes = groups.reduce(function (r:any, a:any) {
          return r.concat(a.groups.typeWorks.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              groupBySubGroupNumber: a.groupByGroupId + "_подгруппа" + b.subGroupNumber,
              typeWorks: b,
              id: b.id,
              isGroupWorkType: true
            };
          }));
        }, []);
        const groupsSubGroups = groupsWorkTypes.reduce(function (r:any, a:any) {
          return r.concat(a.typeWorks.subGroups.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              groupBySubGroupNumber: a.groupBySubGroupNumber,
              typeWorks: a.typeWorks,
              id: a.id,
              subGroup: b,
              isSubGroup: true
            };
          }));
        }, []);
        const flows = this.departmentLoads
          .reduce(function (r, a) {
            return r.concat(a.flows.map(function (b: any) {
              return {
                semesterNumber: b.groups != null && b.groups.length > 0
                  ? b.groups.reduce((prev: any, curr: any) => prev.semesterNumber < curr.semesterNumber ? prev : curr)?.semesterNumber
                  : null,
                discipline: a.discipline,
                groupByGroupId: b.id,
                flows: b,
                isFlow: true
              }
            }))
          }, [])
        const flowsWorkTypes = flows.reduce(function (r: any, a: any) {
          return r.concat(a.flows.typeWorks.map(function (b: any) {
            return {
              semesterNumber: a.semesterNumber,
              discipline: a.discipline,
              groupByGroupId: a.groupByGroupId,
              typeWorks: b,
              id: b.id,
              isFlowWorkType: true
            }
          }));
        }, []);

        const discipline = [...groups, ...flows].map(function(x: any) {
          return {
            uniqueDisciplineName: x.discipline.name + '_' + x.semesterNumber,
            semesterNumber: x.semesterNumber,
            discipline: x.discipline,
            isDiscipline: true
          }
        }).sort((prev: any, curr: any) =>
          prev.semesterNumber === curr.semesterNumber
            ? 0
            : prev.semesterNumber > curr.semesterNumber
              ? 1
              : -1);
        const uniqueDiscipline = [
          ...new Map(discipline.map((item: any) => [item["uniqueDisciplineName"], item])).values(),
        ] as any[];

        this.mainGridDataNotGrouped = [ ...uniqueDiscipline, ...flows, ...flowsWorkTypes, ...groups,
          ...uniqueSubGroupNumber, ...groupsWorkTypes,
          ...groupsSubGroups.sort(function(a: any, b: any) {return a.subGroup.number - b.subGroup.number;})].slice();

        // Добавляем список подгрупп в выпадающий список
        this.mainGridDataNotGrouped.forEach((item: any) => {
          // для группировок с дисциплиной, потоками и группами не создаем
          if(item.isDiscipline || item.isFlow || item.isGroup ||
            !item.typeWorks?.parameters?.subGroupCount || item.classesBySubGroups)
            return;

          item.subGroupList = [{
            text: '',
            value: ''
          }];

          for(let i= 0; i < item.typeWorks.parameters.subGroupCount ; i++) {
            item.subGroupList.push({
              text: `${i+1} п/г`,
              value: i+1
            })
          }
        });


        this.mainGridData = groupBy(this.mainGridDataNotGrouped, this.mainGridOptions.groups);
        this.mainGridOptions.loading = false;
      },
      error => {
        this.notificationService.showError(getErrorMessage(error), 5000);

        this.mainGridOptions.loading = false;
      })
  }

  private getPersons() {
    this.personService.getPPSPersons({
      kafedraId: this.kafedraEdit?.id,
      studyYear: this.yearAndSemestrsEdit?.year,
      withLoad: false
    }).subscribe(
      (response) => {
        this.persons = response;
      })
  }

  private getDictAdditionalWorks() {
    this.dictService.getAdditionalWorks(2).subscribe({
      next: (response) => {
        this.dictAdditionalWorks = response;

        //console.log(response);
      },
      error: err => this.notificationService.showError(getErrorMessage(err))
    })
  }

  private getExamTypeParameters() {
    this.dictService.getExamTypeParameters().subscribe({
      next: (response) => {
        this.dictExamTypeParameters = response;
      },
      error: err => this.notificationService.showError(getErrorMessage(err))
    })
  }

  private getEduGroups() {
    let query = CreateQuery({
      studyYear: this.yearAndSemestrsEdit.year,
      semester: this.yearAndSemestrsEdit.semester,
      studyFormId: this.studyFormEdit?.id,
      trainingLevelId: this.trainingLevelsEdit,
      facultyId: this.facultiesEdit?.id ? this.facultiesEdit?.id : null
    });

    this.eduGroupService.getEduGroups(query).subscribe({
      next: (response) => {
        this.eduGroups = response;
        this.eduGroups.forEach((item) => {
          item.name += ` (${item.studentCount} чел.)`;
        })
      },
      error: err => this.notificationService.showError(getErrorMessage(err))
    })
  }

  //#endregion

  //#region Filter events

  public async yearAndSemestrsValueChange(value: any) {
    this.yearAndSemestrsEdit = value;

    await this.getFaculties();

    this.getPersons();

    this.filterGrid();
  }

  public async kafedraValueChange(value: any) {
    this.kafedraEdit = value;

    await this.getFaculties();
    this.getPersons();

    this.filterGrid();
  }

  public trainingLevelValueChange(value: any) {
    this.trainingLevelsEdit = arrayRewrite(value);

    this.getEduGroups();

    this.filterGrid();
  }

  public facultiesValueChange(value: any) {
    this.facultiesEdit = value;

    this.getEduGroups();

    this.filterGrid();
  }

  public studyFormValueChange(value: any) {
    this.studyFormEdit = value;

    this.getEduGroups();

    this.filterGrid();
  }

  public personValueChange(value: any) {
    if(!this.mainGridOptions.row.isDiscipline && !this.mainGridOptions.row.isGroup &&
       !this.mainGridOptions.row.isSubGroupDivided && !this.mainGridOptions.row.isClassesBySubGroups)
      return;

    this.onMassTeacherEdit();

    this.mainGrid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
  }

  public filterGrid() {
    // Check if dropdown values are filled
    if(this.yearAndSemestrsEdit.name != null && this.yearAndSemestrsEdit.year != null && this.kafedraEdit != null) {
      this.getDepartmentLoad()
      this.getAdditionalWorks();

      this.setFilterOptions();
    }
  }

  //#endregion

  //#region Main load

  //#region Event handlers

  public editDepartmentLoad({isEdited, dataItem, rowIndex}: CellClickEvent): void {
    if (isEdited || (this.formGroup && !this.formGroup.valid)) return;

    this.mainGridOptions.row.isMassOperation = false;

    if(this.mainGridOptions.row.isWorkType) this.saveDepartmentLoad()
    if(this.mainGridOptions.row.isSubGroup) this.onSubGroupEdit()

    this.mainGrid.closeRow(this.editedRowIndex);

    this.editedRowIndex = rowIndex;

    this.mainGridOptions.row.isFlow = dataItem.isFlow;
    this.mainGridOptions.row.isGroup = dataItem.isGroup;
    this.mainGridOptions.row.isClassesBySubGroups = dataItem.classesBySubGroups;
    this.mainGridOptions.row.isSubGroup = dataItem.isSubGroup
    this.mainGridOptions.row.isDiscipline = dataItem.isDiscipline
    this.mainGridOptions.row.isWorkType = dataItem.isGroupWorkType || dataItem.isFlowWorkType
    this.mainGridOptions.row.isExamWork =
      (dataItem.isGroupWorkType || dataItem.isFlowWorkType) && dataItem.typeWorks.typeWork.name === this.typeWorks.exam
    this.mainGridOptions.row.isSubGroupDivided =
      dataItem.typeWorks?.parameters?.subGroupCount && this.mainGridOptions.row.isWorkType;
    this.mainGridOptions.row.groupHasSubgroups = dataItem.isGroupWorkType && dataItem.typeWorks.subGroups?.length > 0;
    this.mainGridOptions.row.allowEditCoefficient =  dataItem.typeWorks?.subGroupNumber != null &&
      dataItem.typeWorks.typeWork.coefficientType == CoefficientTypeWorkEnum.student;

    this.formGroup = editFormGroup(dataItem);

    this.mainGrid.editRow(this.editedRowIndex, this.formGroup);
  }

  public onSubGroupEdit(): void {
    if(this.formGroup!= undefined){
      const index = this.formGroup.value.subGroups.map((e: any) => e.id).indexOf(this.formGroup?.value.subGroupId)
      const subGroup = this.mainGridDataNotGrouped.find((x) => x.id === this.formGroup?.value.id && x.isGroupWorkType)
      this.formGroup.value.subGroups = subGroup.typeWorks.subGroups
      this.formGroup.value.subGroups[index].personId = this.formGroup.value.personId
      this.formGroup.value.subGroups[index].hours = this.formGroup.value.hours
      this.formGroup.value.isGroupWorkType = true
    }
    this.saveDepartmentLoad()
  }

  public onMassTeacherEdit(): void {
    if(this.formGroup == undefined) return;

    //console.log('formGroup', this.formGroup);
    //console.log('mainGridDataNotGrouped', this.mainGridDataNotGrouped);

    const personId = this.formGroup.value.personId

    this.mainGridOptions.row.isMassOperation = true;

    this.mainGridDataNotGrouped
      ?.filter((x) => (x.isGroupWorkType || x.isFlowWorkType) && (
        this.mainGridOptions.row.isDiscipline && x.discipline.id == this.formGroup?.value.discipline.id ||
        this.mainGridOptions.row.isGroup && x.groupByGroupId == this.formGroup?.value.group.id ||
        this.mainGridOptions.row.isSubGroupDivided && x.id == this.formGroup?.value.id ||
        this.mainGridOptions.row.isClassesBySubGroups && x.isGroupWorkType && x.groupBySubGroupNumber == this.formGroup?.value.groupBySubGroupNumber
      ))
      ?.forEach((dataItem: any)=> {
        this.formGroup = editFormGroup(dataItem);
        this.formGroup.value.personId = personId;

        if(this.formGroup.value.subGroups?.length > 0)
          this.onSubGroupPersonChange();

        this.saveDepartmentLoad();
      });

    this.notificationService.showSuccess('Успешно');
  }

  public onSubGroupPersonChange(): void {
    if(this.formGroup!= undefined)
      this.formGroup.value.subGroups.forEach((dataItem: any)=>{
        dataItem.personId = this.formGroup!.value.personId;
      })
  }

  public onSubGroupCountChange(subGroupCount: any): void {
    if(!this.formGroup) return;

    const currentNumber = this.formGroup.value.subGroups?.length ?? 0;
    let selectedNumber = Number(subGroupCount);

    if (currentNumber === selectedNumber) return;

    if(currentNumber < selectedNumber)
      for(let i = currentNumber; i < selectedNumber; i++) {
        let subGroup = this.subGroupEmpty;
        subGroup.number = i + 1;

        this.formGroup.value.subGroups.push(subGroup);
      }
    if(currentNumber > selectedNumber)
      for(let i = 0; i < currentNumber - selectedNumber; i++)
        this.formGroup.value.subGroups.pop();

    this.saveDepartmentLoad()
  }

  //#endregion

  //#region Cell display

  public rowCallback = (args: any) => ({
    'hide-row': args.dataItem.Discontinued || args.dataItem.emptyGroupRow
  });

  public displayParameters(dataItem: any) {
    if(dataItem.isGroupWorkType && dataItem.typeWorks?.parameters?.subGroupCount)
      return dataItem.typeWorks?.subGroups?.length > 0
        ? dataItem.typeWorks.subGroups.length + " п/г"
        : "";

    if(dataItem.typeWorks?.parameters?.examType)
      return this.dictExamTypeParameters
        .find(_ => _.id == dataItem.typeWorks?.parameters?.examType)
        ?.name

    return null;
  }

  public displayGroupFlowInfo(dataItem: any){
    let text = " ";
    if(dataItem.groups) {
      text = dataItem.groups.eduGroup?.studentCount + " чел. уч.";
      if (dataItem.groups?.students > 0)
        text += ", " + dataItem.groups?.students + " чел. в расчет"
      if (dataItem.groups.subGroups)
        text += ", " + dataItem.groups.subGroups + " п/гр."

      text = " (" + text + ")" + (dataItem.groups.numberWeek ? ', ' + dataItem.groups.numberWeek + ' нед.' : '');
    }
    if(dataItem.flows && dataItem.flows.groups.length > 0)
    {
      text += " ("
      dataItem.flows.groups?.forEach(function(item: any, index: any) {
        text += item.eduGroup.name;
        if (index != (dataItem.flows.groups.length - 1)) {
          text += ', ';
        }
      })
      text += ")"
      text += dataItem.flows.numberWeek ? ', ' + dataItem.flows.numberWeek + ' нед.' : '';
    }
    return text;
  }

  public displayGroupFlowName(dataItem: any){
    return dataItem?.groups
      ? dataItem.groups.eduGroup.name
      : dataItem?.flows
        ? 'Поток ' + dataItem.flows.number
        : '';
  }

  public colorCode(dataItem: any): SafeStyle {
    let result = '#000000';

    if(!dataItem.loadHours) return this.sanitizer.bypassSecurityTrustStyle(result);
    if(dataItem.completedHours > dataItem.loadHours) result = '#FF0000';
    if(dataItem.completedHours == dataItem.loadHours) result = '#539A47';

    return this.sanitizer.bypassSecurityTrustStyle(result);
  }

  //#endregion

  //#region Helper methods

  public saveDepartmentLoad(): void {
    if(this.formGroup && this.formGroup.valid) {
      if(this.formGroup.value.examType !== null && this.formGroup.value.parameters !== null)
        this.formGroup.value.parameters.examType = this.formGroup.value.examType

      if(this.formGroup.value.isFlowWorkType)
        this.departmentWorkloadService.updateFlowTypeWork(this.formGroup.value).subscribe({
          next: () => {
            if(!this.mainGridOptions.row.isMassOperation) this.notificationService.showSuccess('Успешно');
            this.getDepartmentLoad();
            this.closeMainGrid();
          },
          error: (reason) => {
            this.notificationService.showError(getErrorMessage(reason), 5000);
            this.closeMainGrid();
          }
        })
      if(this.formGroup.value.isGroupWorkType) {
        for (let i = 0; i < this.formGroup.value.subGroups.length; i++)
          if(this.formGroup.value.subGroups[i].person && this.formGroup.value.subGroups[i].personId === undefined){
            this.formGroup.value.subGroups[i].personId = this.formGroup.value.subGroups[i].person.id
          }

        // TODO: Реализовать нормальную проверку на typeWork. Подгруппы добавляются сейчас везде
        this.departmentWorkloadService.updateGroupTypeWork(this.formGroup.value).subscribe({
          next: () => {
            if(!this.mainGridOptions.row.isMassOperation) this.notificationService.showSuccess('Успешно');
            this.closeMainGrid();
            this.getDepartmentLoad();
          },
          error: (reason) => {
            this.notificationService.showError(getErrorMessage(reason), 5000);
            this.closeMainGrid();
          }
        });
      }
    }
  }

  //#endregion

  //#endregion

  //#region Extra grid

  //#region Event handlers

  // Add extra work assignment
  public addAdditionalWork(args: AddEvent): void {
    this.extraGridState.isEdit = true;
    this.extraGridState.isAdd = true;

    this.mainGridOptions.row.iskafedraWork = true;

    this.formGroupAdditional = createFormGroup({
      assignmentDepartment: '',
      eduGroupId: '',
      totalStudentsLoad: 0,
      dictAdditionalWorksId: ''
    });

    args.sender.addRow(this.formGroupAdditional);
  }

  public editAdditionalWork({isEdited, dataItem, rowIndex}: CellClickEvent): void {
    if (isEdited || (this.formGroupAdditional && !this.formGroupAdditional.valid)) {
      return;
    }
    this.extraGridState.isAdd = false;
    this.extraGridState.isEdit = true;
    this.extraGrid.closeRow(this.editedAdditionalRowIndex);
    this.mainGridOptions.row.iskafedraWork =
      dataItem.dictAdditionalWork.dictAdditionalWorkType.name === this.typeWork.kafedra
    this.formGroupAdditional = editFormGroupAdditional(dataItem);
    this.editedAdditionalRowIndex = rowIndex;
    this.extraGrid.editRow(rowIndex, this.formGroupAdditional);
  }

  public cancelExtraHandler(args: CancelEvent): void {
    this.extraGridState.isEdit = false;
    this.extraGridState.isAdd = false;

    this.closeExtraGrid();
  }

  // Close editing for extra grid
  private closeExtraGrid(): void {
    this.extraGrid.closeRow(this.editedAdditionalRowIndex);
    this.editedAdditionalRowIndex = undefined;
    this.formGroupAdditional = undefined;
    this.extraGridState.isAdd = false;
    this.extraGridState.isEdit = false;
  }

  // Close editing for main grid
  private closeMainGrid(): void {
    // Close all rows
    for(let i=0; i < this.mainGridDataNotGrouped.length; i++) {
      this.mainGrid.closeRow(i);
    }
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //#endregion

  //#region Helper methods

  // Remove additional work event
  public removeAdditionalWork() {
    if (!this.extraGridState.selectedId) return;

    this.additionalWorkService.deleteAdditionalWork(this.extraGridState.selectedId).subscribe({
      next: () => {
        this.notificationService.showSuccess('Успешно');
        this.extraGridState.opened = false;
        this.getAdditionalWorks();
      },
      error: () => {
        this.notificationService.showError(`Произошла ошибка`);
        this.extraGridState.opened = false;
      }
    })
  }

  // Save additional work event
  public saveAdditionalWork(): void {
    // Form group is valid and not null
    if(this.formGroupAdditional && this.formGroupAdditional.valid) {
      this.formGroupAdditional.controls['assignmentDepartment'].setValue({
        // TODO: Id
        //id: null,
        year: this.yearAndSemestrsEdit.year,
        semestr: this.yearAndSemestrsEdit.semester,
        kafedraId: this.kafedraEdit.id,
      });
      //console.log(JSON.stringify(this.formGroupAdditional.value));
      // Add new
      if(this.extraGridState.isAdd) {
        this.additionalWorkService.createAdditionalWork(this.formGroupAdditional.value).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');

            this.getAdditionalWorks();

            this.extraGridState.isEdit = false;
            this.extraGridState.isAdd = false;
          },
          error: (reason) => {
            this.notificationService.showError(getErrorMessage(reason), 5000);

            this.extraGridState.isEdit = false;
            this.extraGridState.isAdd = false;
          }
        })
      }
      // Edit
      else {
        this.additionalWorkService.updateAdditionalWork(this.formGroupAdditional.value).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');

            this.getAdditionalWorks();

            this.extraGridState.isAdd = false;
            this.extraGridState.isEdit = false;
          },
          error: (reason) => {
            this.notificationService.showError(getErrorMessage(reason), 5000);

            this.extraGridState.isAdd = false;
            this.extraGridState.isEdit = false;
          }
        })
      }
    }
    this.closeExtraGrid();
  }

  //#endregion

  //#region Confirm window

  // Open Confirmation window for deleting extra work
  public openExtraConfirmWindow(dataItem: any) {
    if (!dataItem) return;

    this.extraGridState.selectedId = dataItem.id;
    this.extraGridState.opened = true;
    this.extraGridState.itemToDelete = `${dataItem.dictAdditionalWork.name} ${dataItem.eduGroup.name}`;
  }

  // Close confirmation window for deleting extra work
  public closeExtraConfirmWindow() {
    this.extraGridState.opened = false;
    this.extraGridState.selectedId = null;
    this.extraGridState.itemToDelete = "";
  }

  //#endregion

  //#endregion

  //#region Lifecycle events

  async ngOnInit() {
    const needSetEditModel = !this.getFilterOptions();

    await this.getAllYearAndSemestrs(needSetEditModel);
    await this.getDepartments(needSetEditModel);
    await this.getTrainingLevels(needSetEditModel);
    await this.getStudyForms(needSetEditModel);
    await this.getFaculties(needSetEditModel);

    this.getDictAdditionalWorks();
    this.getExamTypeParameters();
    this.getPersons();
    this.getEduGroups();

    this.getDepartmentLoad();
    this.getAdditionalWorks();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "department-workload-container")) return;

      if (!isChildOf(target, "main-load")) {
        this.saveDepartmentLoad();
      }
    });
  }

  //#endregion

  //#region Export

  public exportStudyWork() {
    if (!this.yearAndSemestrsEdit || !this.kafedraEdit) return;

    this.reportService.getExcelReportTeachersLoadOfSubdepartment({
      studyYear: this.yearAndSemestrsEdit.year,
      kafedraId: this.kafedraEdit.id,
      trainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      studyFormId: this.studyFormEdit?.id == '' ? null : [this.studyFormEdit?.id],
      facultyId: this.facultiesEdit?.id == '' ? null : this.facultiesEdit?.id
    });
  }

  public exportPlanFact() {
    if (!this.yearAndSemestrsEdit && !this.kafedraEdit) return;

    this.departmentWorkloadService.getExportPlanFact({
      studyYear: this.yearAndSemestrsEdit.year,
      kafedraId: this.kafedraEdit.id,
      trainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      studyFormId: this.studyFormEdit?.id == '' ? null : [this.studyFormEdit?.id],
      facultyId: this.facultiesEdit?.id == '' ? null : this.facultiesEdit?.id
    }).subscribe({
      next: (response) => {
        let blob:any = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        saveAs(blob, `План учебной нагрузки на ${this.yearAndSemestrsEdit.year} / ${this.yearAndSemestrsEdit.year + 1} учебный год кафедры ${this.kafedraEdit.name}`);
      },
      error: err => this.notificationService.showError(getErrorMessage(err), 5000)
    })
  }

  public exportSummaryPps() {
    if (!this.yearAndSemestrsEdit || !this.kafedraEdit) return;

    this.reportService.getSummaryPps({
      studyYear: this.yearAndSemestrsEdit.year,
      kafedraId: this.kafedraEdit.id,
      //trainingLevelId: this.trainingLevelsEdit.includes('') ? null : this.trainingLevelsEdit,
      //studyFormId: this.studyFormEdit?.id == '' ? null : [this.studyFormEdit?.id],
      //facultyId: this.facultiesEdit?.id == '' ? null : this.facultiesEdit?.id
    });
  }

  //#endregion

  //#region Helper methods

  private storage_filter_key: string = 'department_workload-filter';

  private setFilterOptions() {
    localStorage.removeItem(this.storage_filter_key);
    localStorage.setItem(this.storage_filter_key, JSON.stringify({
      yearAndSemestrsEdit: this.yearAndSemestrsEdit,
      kafedraEdit: this.kafedraEdit,
      trainingLevelsEdit: this.trainingLevelsEdit,
      studyFormEdit: this.studyFormEdit,
      facultiesEdit: this.facultiesEdit
    }));
  }

  private getFilterOptions(): boolean {
    const json = localStorage.getItem(this.storage_filter_key);

    if (json != null) {
      try {
        const filters = JSON.parse(json);

        this.yearAndSemestrsEdit = filters.yearAndSemestrsEdit;
        this.kafedraEdit = filters.kafedraEdit;
        this.trainingLevelsEdit = filters.trainingLevelsEdit;
        this.studyFormEdit = filters.studyFormEdit;
        this.facultiesEdit = filters.facultiesEdit;

        return true;
      }
      catch (e) {
        localStorage.removeItem(this.storage_filter_key);

        return false;
      }
    }

    return false;
  }

  //#endregion
}

// Formgroup for adding new item
const createFormGroup = (dataItem: {
  assignmentDepartment: any;
  eduGroupId: any;
  eduGroup?: any;
  dictAdditionalWork?: any;
  totalStudentsLoad: number;
  dictAdditionalWorksId: any;
  personId?: any;}) =>
  new FormGroup({
    assignmentDepartment: new FormControl(dataItem.assignmentDepartment),
    eduGroupId: new FormControl(dataItem.eduGroup),
    totalStudentsLoad: new FormControl(dataItem.totalStudentsLoad, Validators.required),
    dictAdditionalWorksId: new FormControl(dataItem.dictAdditionalWorksId, Validators.required),
    personId: new FormControl(dataItem.personId),
  });

// Formgroup for editing item
const editFormGroup = (dataItem: {
  id?: any;
  discipline?: any;
  groups?: any;
  typeWorks?: any;
  isSubGroup?: any;
  isWorkType?: any;
  isGroupWorkType?: any;
  isFlowWorkType?: any;
  isDiscipline?: any;
  subGroup?: any;
  examType?: number;
  personId?: any;
  classesBySubGroups?: boolean;
  groupBySubGroupNumber?: string;
}) => new FormGroup({
  id: new FormControl(dataItem?.id),
  discipline: new FormControl(dataItem?.discipline),
  group: new FormControl(dataItem?.groups),
  subGroupId: new FormControl(dataItem.isSubGroup? dataItem.subGroup?.id: dataItem?.id),
  personId: new FormControl((dataItem.isFlowWorkType||dataItem.isGroupWorkType)
    ? dataItem.typeWorks?.person?.id
    : dataItem.isSubGroup? dataItem.subGroup?.person?.id:undefined),
  hours: new FormControl(dataItem.subGroup?.hours),
  coefficient: new FormControl(dataItem.typeWorks?.coefficient),
  parameters: new FormControl(dataItem.typeWorks?.parameters),
  subGroupCount: new FormControl(dataItem.typeWorks?.parameters?.subGroupCount),
  subGroups: new FormControl(dataItem.typeWorks?.subGroups),
  examType: new FormControl(dataItem.typeWorks?.parameters?.examType),
  isGroupWorkType: new FormControl(dataItem.isGroupWorkType),
  isFlowWorkType: new FormControl(dataItem.isFlowWorkType),
  isDiscipline: new FormControl(dataItem.isDiscipline),
  isSubGroup: new FormControl(dataItem.isSubGroup),
  classesBySubGroups: new FormControl(dataItem.classesBySubGroups),
  groupBySubGroupNumber: new FormControl(dataItem.groupBySubGroupNumber)
});

const editFormGroupAdditional = (dataItem: {
  id?: any;
  assignmentDepartment?: any;
  eduGroupId?: any;
  eduGroup?: any;
  dictAdditionalWork?: any;
  totalStudentsLoad?: number | null;
  dictAdditionalWorksId?: any;
  person?: any;
  personId?: any;}) =>
  new FormGroup({
    id: new FormControl(dataItem.id, Validators.required),
    assignmentDepartment: new FormControl(dataItem.assignmentDepartment),
    eduGroupId: new FormControl(dataItem.eduGroup?.id),
    totalStudentsLoad: new FormControl(dataItem.totalStudentsLoad, Validators.required),
    dictAdditionalWorksId: new FormControl(dataItem.dictAdditionalWork.id, Validators.required),
    personId: new FormControl(dataItem.person?.id),
  });

enum DisciplineWorkTypeEnumList {
  lek = "лек.",
  lab = "лаб. раб.",
  pract = "практ.",
  csr = "КСР",
  control = "контрол.",
  credit = "зачет",
  self = "сам.",
  wcs = "н/а",
  kr = "Курсовая работа",
  kp = "Курсовой проект",
}

export var DisciplineWorkTypeEnum: any = DisciplineWorkTypeEnumList

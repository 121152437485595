import {Component, OnInit} from "@angular/core";
import {NotificationsService} from "../../services/notifications/notifications.service";
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {TokenStorageService} from "../../services/token.service";

declare var $: any;

@Component({
  selector: 'report-list',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.scss']
})
export class ReportViewerComponent implements OnInit {

  public baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.reportViewer.base}`;
  viewer: null | undefined;

  constructor(private notificationService: NotificationsService,
              private route: ActivatedRoute,
              private tokenStore: TokenStorageService) {
    //TODO: пока что будет перенаправление до тех пор, пока конструктор не будет переписан на angular
    window.location.href = this.baseUrl + '/index' + window.location.search + '&Token=' + tokenStore.getAuthToken();
  }

  ngOnInit(): void {
    /*this.viewer = $("#reportViewer").telerik_ReportViewer({
      serviceUrl: this.url,
      reportSource: {
        parameters: {}
      }
    }).data("telerik_ReportViewer");*/
  }
}

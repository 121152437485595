import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BudgetSubCategory } from '../../models/contingent/budgetsubcategory.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BudgetsubcategoryService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.budgetsubcategory}`;

  constructor(private http: HttpClient) { }

  //Get all BudgetSubCategory
  public getAllBudgetSubCategory(): Observable<BudgetSubCategory[]> {
    return this.http.get<BudgetSubCategory[]>(this.baseUrl);
  }

  //Add BudgetSubCategory
  addBudgetSubCategory(budgetsubcategory: BudgetSubCategory): Observable<BudgetSubCategory> {
    return this.http.post<BudgetSubCategory>(this.baseUrl, budgetsubcategory)
      .pipe(catchError(this.handleError));
  }

  //Delete BudgetSubCategory
  deleteBudgetSubCategory(id: string): Observable<BudgetSubCategory> {
    return this.http.delete<BudgetSubCategory>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Edit BudgetSubCategory
  updateBudgetSubCategory(budgetsubcategory: BudgetSubCategory): Observable<BudgetSubCategory> {
    return this.http
      .put<BudgetSubCategory>(this.baseUrl + '/' + budgetsubcategory.dictStudentBudgetSubCategoryExternalId, budgetsubcategory)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
      return throwError(error);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { flatten } from 'lodash';
import { ProcessScheduleModels } from 'src/app/models/education/process-schedule.model';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ProcessScheduleService } from 'src/app/services/education/ProcessSchedule/process-schedule.service';

import { EducationService } from 'src/app/services/education/EducationPlan/education.service';
import * as moment from 'moment/moment';
import { EducationPlanDetailsService } from '../../../services/education/EducationPlan/education-plan-details.service';
import { EducationPlanScheduleService } from 'src/app/services/education/EducationPlan/education-plan-schedule.service';
import { DateFromUTCAsLocal } from "../../../helpers/date-helper";
@Component({
  selector: 'educational-process-schedule-settings',
  templateUrl: './educational-process-schedule-settings.component.html',
  styleUrls: ['./educational-process-schedule-settings.component.scss'],
})
export class EducationalProcessScheduleSettingsComponent implements OnInit {
  @Input() educationPlanId: string | null = null;
  @Input() yearAdmission: number = 0;
  @Output() onHide: EventEmitter<void> = new EventEmitter();
  public eduProgramId: string = '';
  public minDate: Date = new Date(2000,0,1);
  public maxDate: Date = new Date(2100,11,31);
  public navigationDate: Date = this.minDate;
  public settingsFormGroup: FormGroup = new FormGroup({});

  //public countDaysInWeek = [1, 2, 3, 6]
  public studyTechName = 'семестр';
  public courses: ProcessScheduleModels.SettingsSemester[][] = [];

  constructor(
    activateRoute: ActivatedRoute,
    private router: Router,
    public processScheduleService: ProcessScheduleService,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private notificationService: NotificationsService,
    private educationPlanScheduleService: EducationPlanScheduleService
  ) {
    activateRoute.params.subscribe((params) => {
      this.eduProgramId = params['education_program_id'];
    });
  }

  ngOnInit(): void {
    this.settingsFormGroup = new FormGroup({
      countDaysInWeekPart: new FormControl(),
      showDatesInGraph: new FormControl(),
      considerHolidays: new FormControl(),
      semesters: new FormControl(),
      holidays: new FormControl(),
    });
  }

  ngOnChanges(): void {
    if (!this.educationPlanId) return;
    this.processScheduleService
      .getSettings(this.educationPlanId)
      .subscribe((data) => {
        this.settingsFormGroup.patchValue(data);
        this.parseSemesters(data.semesters);
      });
    this.processDateLimits();
  }

  parseSemesters(semesters: ProcessScheduleModels.SettingsSemester[]) {
    const courses: any = {};
    semesters.forEach((semester) => {
      if (!courses[semester.courseNumber]) {
        courses[semester.courseNumber] = [];
      }
      semester.startTraining = this.fixDate(semester.startTraining);
      semester.installationSessionBegin = this.fixDate(semester.installationSessionBegin);
      semester.installationSessionEnd = this.fixDate(semester.installationSessionEnd);
      courses[semester.courseNumber].push(semester);
    });
    this.courses = Object.values(courses);
    if (courses.length > 0) {
      this.studyTechName = courses[0].length > 2 ? 'триместр' : 'семестр';
    }
  }

  goBack() {
    this.onHide.emit();
  }

  onSave() {
    if (this.educationPlanId) {
      const semesters = flatten(
        this.courses.map((course) => {
          return course.map((semester) => {
            return {
              ...semester,
              startTraining: moment(semester.startTraining).utc(true),
              installationSessionBegin: moment(semester.installationSessionBegin).utc(true),
              installationSessionEnd: moment(semester.installationSessionEnd).utc(true),
            };
          });
        })
      );
      this.settingsFormGroup.patchValue({ semesters });
      this.processScheduleService
        .saveSettings(this.educationPlanId, this.settingsFormGroup.value)
        .subscribe((response) => {
          this.processScheduleService.settings$.next(response);
          this.notificationService.showSuccess('Настройки сохранены');
          this.educationPlanId &&
            this.educationPlanScheduleService.updateProcessScheduleData(
              this.educationPlanId
            );

          this.goBack();
        });
    }
  }

  private processDateLimits() {
    //ограничим ввод дат с августа тек. года по август следующего
    if(this.yearAdmission>0) {
      this.minDate = new Date(this.yearAdmission, 7, 1);
      this.maxDate = new Date(this.yearAdmission + 1, 7, 31);
    }
    else {
      const currentDate = new Date();
      this.minDate = new Date(currentDate.getFullYear(), 7, 1);
      this.maxDate = new Date(currentDate.getFullYear() + 1, 7, 31);
    }
    this.navigationDate = this.minDate;
  }

  private fixDate(date: Date | null) {
    return date ? DateFromUTCAsLocal(date) : null;
  }
}

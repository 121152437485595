import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { Show } from '../../models/announcement/show.model';
import { PostComment } from '../../models/announcement/post-comment.model';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

  export class ShowService {
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);


  constructor(private http: HttpClient) { }

  public getShow(Id: number) {
    return this.http.get<Show>(this.baseUrl + '/message/' + Id)
      .pipe(
        catchError(this.handleError));
  }

  public deleteMessage(messageId: number){
    let httpheaders=new HttpHeaders()
    .set('Content-type','application/Json');
    let options={
      headers:httpheaders
    };
    return this.http.delete(this.baseUrl + '/message/' + messageId,  {responseType: 'text' as 'text'});
  }
  
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
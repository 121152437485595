<div class="container" [formGroup]="formGroup" *ngIf="true">
    <kendo-loader *ngIf="loading" class="loader" type="converging-spinner" size="medium"></kendo-loader>
    <div class="row">
        <h2>Тип публикации</h2>
        <kendo-dropdownlist
               [data]="types"
               [disabled]="!isNew && isStaticPropertyDisabled()"
               formControlName="typeId"
               [valuePrimitive]="true"
               (valueChange)="onTypeChange($event)"
               textField="name"
               valueField="id">
        </kendo-dropdownlist>
        <span class="required">*</span>
    </div>
    <div class="row">
        <h2>Вид публикации</h2>
        <kendo-dropdownlist
                [data]="allowedSubTypes"
                [disabled]="!isNew && isStaticPropertyDisabled()"
                formControlName="subTypeId"
                [valuePrimitive]="true"
                (valueChange)="onSubTypeChange()"
                textField="name"
                valueField="id">
        </kendo-dropdownlist>
        <span class="required">*</span>
    </div>
    <div class="row">
        <h2>Индексация</h2>
        <kendo-multiselect
                [data]="scientometricBases"
                [disabled]="!isNew && isStaticPropertyDisabled()"
                formControlName="categories"
                [valuePrimitive]="true"
                (valueChange)="onFieldChange()"
                [kendoDropDownFilter]='filterSettings'
                textField="name"
                valueField="id">
        </kendo-multiselect>
        <span class="required">*</span>
    </div>
    <div class="row">
        <h2>Структурное подразделение</h2>
        <kendo-multiselect
                [data]="subdivisions"
                [disabled]="!isNew && isStaticPropertyDisabled()"
                formControlName="departmentId"
                [valuePrimitive]="true"
                (valueChange)="onFieldChange()"
                [kendoDropDownFilter]='filterSettings'
                textField="name"
                valueField="id">
        </kendo-multiselect>
        <span class="required">*</span>
    </div>
    <div class="row">
        <h2>Название</h2>
        <kendo-textarea formControlName="name"
                        [disabled]="!isNew && isStaticPropertyDisabled()"
                        (valueChange)="onFieldChange()">
        </kendo-textarea>
        <span class="required">*</span>
        <button *ngIf="isNew || !isStaticPropertyDisabled()" (click)="convertTextCase()" class="convertRegBtn" kendoButton>a - A - a</button>
    </div>
    <div class="row">
        <h2>Год</h2>
        <kendo-dropdownlist
                [data]="years"
                [disabled]="!isNew && isStaticPropertyDisabled()"
                (valueChange)="onYearChange($event)"
                formControlName="year">
        </kendo-dropdownlist>
        <span class="required">*</span>
    </div>
    <div class="row">
        <h2>Форма работы</h2>
        <kendo-dropdownlist
                textField="name"
                valueField="id"
                [disabled]="!isNew && isStaticPropertyDisabled()"
                [valuePrimitive]="true"
                (valueChange)="onWorkFormChange($event)"
                formControlName="workFormId"
                [data]="workForms">
        </kendo-dropdownlist>
        <span class="required">*</span>
    </div>
    <div class="dynamicComponents" formArrayName="properties">
        <div *ngFor="let property of properties.controls; index as i;">
            <div class="row" [formGroupName]="i">
                <h2>{{property.value.name}}</h2>
                <span [ngSwitch]="property?.value.type?.enum">
                    <kendo-textarea [required]="property.value.required"
                                    formControlName="value"
                                    *ngSwitchCase="KendoProperties.Textarea"
                                    (valueChange)="onFieldChange()">
                    </kendo-textarea>
                    <kendo-numerictextbox [required]="property.value.required"
                                          [decimals]="0" [changeValueOnScroll]="false"
                                          [min]="0" format="false" formControlName="value"
                                          *ngSwitchCase="KendoProperties.Number"
                                          (valueChange)="onFieldChange()">
                    </kendo-numerictextbox>
                    <kendo-numerictextbox [required]="property.value.required"
                                          formControlName="value"
                                          *ngSwitchCase="KendoProperties.Float"
                                          (valueChange)="onFieldChange()">
                    </kendo-numerictextbox>
                    <kendo-dropdownlist
                            [data]="property.value.data!"
                            textField="name"
                            valueField="name"
                            [valuePrimitive]="true"
                            formControlName="value"
                            [virtual]="filterVirtualization"
                            [required]="property.value.required"
                            *ngSwitchCase="KendoProperties.Dropdown"
                            (valueChange)="onFieldChange()">
                    </kendo-dropdownlist>
                    <kendo-combobox
                            [data]="property.value.data!"
                            textField="name"
                            valueField="name"
                            [valuePrimitive]="true"
                            [kendoDropDownFilter]="filterSettings"
                            formControlName="value"
                            [virtual]="filterVirtualization"
                            [required]="property.value.required"
                            *ngSwitchCase="KendoProperties.Combobox"
                            (valueChange)="onFieldChange()">
                    </kendo-combobox>
                    <kendo-datepicker #datePicker
                                      [openOnFocus]="datePicker"
                                      [required]="property.value.required"
                                      formControlName="value"
                                      *ngSwitchCase="KendoProperties.Date"
                                      (valueChange)="onFieldChange()">
                    </kendo-datepicker>
                    <kendo-textbox [required]="property.value.required"
                                   formControlName="value"
                                   *ngSwitchCase="KendoProperties.Textbox"
                                   (valueChange)="onFieldChange()">
                    </kendo-textbox>
                </span>
                <span *ngIf="property.value.required" class="required">*</span>
            </div>
        </div>
    </div>
</div>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, catchError, Observable, retry, tap, throwError} from 'rxjs';
import urlJoin from 'url-join';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../error.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { ProcessScheduleModels } from 'src/app/models/education/process-schedule.model';
import { join, mapKeys } from 'lodash';
import {ResponseScheduleProcess} from "../../../models/education/education-plan-schedule.model";


@Injectable({
  providedIn: 'root',
})
export class ProcessScheduleService {
  baseUrl = urlJoin(<string>environment.apiEndpoint, "v1", environment.apiPaths.education.processSchedule);

  public countDaysInWeek: {name: string, value: number}[] = [
    {name:'Дневной', value: 1},
    {name:'2 дня', value: 2},
    {name:'Полунеделя', value: 3},
    {name:'Неделя', value: 6}
  ]

  public countDaysInWeekMap = mapKeys(this.countDaysInWeek, 'value')
  settings$ = new BehaviorSubject<ProcessScheduleModels.Settings>({
    holidays: [],
    showDatesInGraph: false,
    considerHolidays: false,
    countDaysInWeekPart: 1,
    semesters: []
  });

  constructor(private httpClient: HttpClient, private errorService: ErrorService,
    private notificationService: NotificationsService) { }

  getSettings(educationPlanId: string): Observable<ProcessScheduleModels.Settings> {
    return this.httpClient
      .get<ProcessScheduleModels.Settings>(urlJoin(this.baseUrl, 'Settings', educationPlanId))
      .pipe(
        retry(2),
        catchError(this.errorHandler.bind(this))
      );
  }

  saveSettings(educationPlanId: string, settings: ProcessScheduleModels.Settings): Observable<ProcessScheduleModels.Settings> {
    return this.httpClient
      .post<ProcessScheduleModels.Settings>(urlJoin(this.baseUrl, 'Settings', educationPlanId), settings)
      .pipe(
        retry(2),
        catchError(this.errorHandler.bind(this))
      );
  }

  getGlobalSettings(dictTrainingLevelId: string, filialId: string): Observable<ProcessScheduleModels.GlobalSettings> {
    return this.httpClient
      .get<ProcessScheduleModels.GlobalSettings>(urlJoin(this.baseUrl, 'GlobalSettings', dictTrainingLevelId, filialId))
      .pipe(
        retry(2),
        catchError(this.errorHandler.bind(this))
      );
  }

  saveGlobalSettings(dictTrainingLevelId: string, settings: ProcessScheduleModels.GlobalSettings): Observable<ProcessScheduleModels.GlobalSettings> {
    return this.httpClient
      .post<ProcessScheduleModels.GlobalSettings>(urlJoin(this.baseUrl, 'GlobalSettings', dictTrainingLevelId), settings)
      .pipe(
        retry(2),
        catchError(this.errorHandler.bind(this))
      );
  }

  private errorHandler(error: HttpErrorResponse) {
    let errorText = join(Object.getOwnPropertyNames(error.error).map(x=>error.error[x], ', '), '; ');
    if(errorText.length == 0) errorText = 'Произошла ошибка';
    this.notificationService.showError(errorText);
    this.errorService.handle(error.message);
    return throwError(() => error.message);
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { PagerPosition, RemoveEvent } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Guid } from 'guid-typescript';
import { DictCycle } from 'src/app/models/education/dictcycle.model';
import { DictCycleType } from 'src/app/models/education/dictcycletype.model';
import { StandardType } from 'src/app/models/education/standardtype.model';
import { TrainingLevel } from 'src/app/models/education/traininglevel.model';
import { DictCycleService } from 'src/app/services/education/DictCycle/dict-cycle.service';
import { DictCycleTypeService } from 'src/app/services/education/DictCycleType/dict-cycle-type.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { StandardtypeService } from 'src/app/services/education/StandardType/standardtype.service';
import { TrainingLevelService } from 'src/app/services/education/TrainingLevel/traininglevel.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'dict-cycle',
  templateUrl: './dict-cycle.component.html',
  styleUrls: ['./dict-cycle.component.scss']
})
export class DictCycleComponent implements OnInit {

  public gridStyle: string = 'grid-default';
  public isOpened: boolean = false;
  public loading: boolean = false;

  // Pager settings
  public type: PagerType = 'numeric';
  public buttonCount: number = 5;
  public info: boolean = true;
  public pageSizes = [10, 20, 50];
  public previousNext: boolean = true;
  public position: PagerPosition = 'bottom';
  public editable?: boolean;
  public isNew: boolean = false;
  public opened = false;

  public traininglevels!: TrainingLevel[];
  public standardTypes!: StandardType[];
  public cycleTypes!: DictCycleType[];
  public cycles!: DictCycle[];
  public dictCyclesByFilter: DictCycle[] = [];
  public secondFilter: Filter = {
    standardTypeId: undefined,
    trainingLevelId: undefined
  }

  constructor(private cycleService: DictCycleService,
              private notificationService: NotificationsService,
              private cycleTypeService: DictCycleTypeService,
              private trainingLevelService: TrainingLevelService,
              private standardTypeService: StandardtypeService,
              private userAccessService:EducationUserAccessService,
              private dialogService: DialogService,) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getAllCycles();
    this.getAllCycleTypes();
    this.getAllStandardTypes();
    this.getAllTrainingLevels();
  }

  public defaultItemTrainingLevel: { trainingLevelName: string; dictTrainingLevelExternalId?: any } = {
    trainingLevelName: "Не выбрано",
    dictTrainingLevelExternalId: null,
  };

  public defaultItemStandardType: { standardTypeShortName: string; standardTypeExternalId?: any } = {
    standardTypeShortName: "Не выбрано",
    standardTypeExternalId: null,
  };

  public cancelFilterHandler(): void {
    this.secondFilter.standardTypeId = undefined;
    this.secondFilter.trainingLevelId = undefined;
    this.dictCyclesByFilter = [];
  }

  public onValueChange(value: any): void {
    this.saveCurrentFilter();
  }

  saveCurrentFilter() {
    this.dictCyclesByFilter = [];
    this.dictCyclesByFilter = this.cycles.filter(x => Guid.parse(x.standardTypeExternalId) == this.secondFilter.standardTypeId &&
      Guid.parse(x.trainingLevelExternalId) == this.secondFilter.trainingLevelId);
  }

  // Edit Form
  public editForm: FormGroup = new FormGroup ({
    dictCycleId: new FormControl(0),
    dictCycleExternalId: new FormControl(""),
    dictCycleName: new FormControl(""),
    dictCycleShortName: new FormControl(""),
    dictCyclePrintName: new FormControl(""),
    dictCycleNameGenitiveCase: new FormControl(""),
    trainingLevelExternalId: new FormControl(""),
    dictTrainingLevel: new FormControl(""),
    standardTypeExternalId: new FormControl(""),
    dictStandardType: new FormControl(""),
    dictCycleTypeExternalId: new FormControl(""),
    dictCycleType: new FormControl(""),
    dictCycleOptionalMarks: new FormControl()
  });

  //Getting all entries from Cycles
  public getAllCycles() {
    this.loading = true;
    this.cycleService.getAllCycles()
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.cycles = response;
          this.saveCurrentFilter();
        },
        error: () => {
          this.loading = false;
          this.notificationService.showError("Не удалось загрузить список циклов")
        }
      });
  }

  //Getting all entries from Cycle types
  public getAllCycleTypes() {
    this.cycleTypeService.getAllCycleTypes()
      .subscribe(
        response => {
          this.cycleTypes = response;
        }
      );
  }

  //Getting all entries from standard types
  public getAllStandardTypes() {
    this.standardTypeService.getAllStandardTypes()
      .subscribe(
        response => {
          this.standardTypes = response;
        }
      );
  }

  //Getting all entries from study levels
  public getAllTrainingLevels() {
    this.trainingLevelService.getAllTrainingLevels()
      .subscribe(
        response => {
          this.traininglevels = response;
        }
      );
  }

  public saveCycle() {
    // Add new cycle
    if (this.isNew) {
      this.checkBoxReturnsNullCheck();
      this.editForm.value.trainingLevelExternalId = this.secondFilter.trainingLevelId;
      this.editForm.value.standardTypeExternalId = this.secondFilter.standardTypeId;
      this.cycleService.addCycle(this.editForm?.value)
        .subscribe({
          next: () => {
            this.notificationService.showSuccess("Цикл был успешно добавлен!");
            this.getAllCycles();
            this.onCancel();
          },
          error: () => {
            this.notificationService.showError("Не удалось добавить цикл");
            this.onCancel();
          }
        });
    }
    // Edit cycle
    else {
      this.checkBoxReturnsNullCheck();
      this.cycleService.updateCycle(this.editForm?.value)
        .subscribe({
          next: (response) => {
            let currentDict = this.dictCyclesByFilter.find((x) => x.dictCycleExternalId === response.dictCycleExternalId)!;
            let currentIndex = this.dictCyclesByFilter.indexOf(currentDict);

            this.dictCyclesByFilter[currentIndex].dictCycleExternalId = response.dictCycleExternalId.toString();
            this.dictCyclesByFilter[currentIndex].dictCycleName = response.dictCycleName;
            this.dictCyclesByFilter[currentIndex].dictCycleShortName = response.dictCycleShortName;
            this.dictCyclesByFilter[currentIndex].dictCyclePrintName = response.dictCyclePrintName;
            this.dictCyclesByFilter[currentIndex].dictCycleNameGenitiveCase = response.dictCycleNameGenitiveCase;
            this.dictCyclesByFilter[currentIndex].trainingLevelExternalId = response.trainingLevelExternalId.toString();
            this.dictCyclesByFilter[currentIndex].standardTypeExternalId = response.standardTypeExternalId.toString();
            this.dictCyclesByFilter[currentIndex].dictCycleTypeExternalId = response.dictCycleTypeExternalId;
            this.dictCyclesByFilter[currentIndex].dictCycleOptionalMarks = response.dictCycleOptionalMarks;
            this.dictCyclesByFilter[currentIndex].nameFormatted = response.nameFormatted;
            this.dictCyclesByFilter[currentIndex].cycleInfo = response.cycleInfo;
            this.dictCyclesByFilter[currentIndex].dictCycleType = response.dictCycleType;
            this.notificationService.showSuccess("Цикл был успешно сохранен!");
            this.filter = {
              logic: 'and',
              filters: []
            }
            this.onCancel();
          },
          error: () => {
            this.notificationService.showError('Не удалось изменить цикл');
            this.onCancel();
          }
        })
    }
  }

  public checkBoxReturnsNullCheck() {
    if (this.editForm?.value.dictCycleOptionalMarks == null) {
      this.editForm.value.dictCycleOptionalMarks = false;
    }
  }

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe({
      next: (response) => {
        this.editable = !response.dictAccessLevel;
      },
      error: () => {}
    })
  }

  public addHandler(): void {
    this.isNew = true;
    this.isOpened = true;
    this.gridStyle = 'grid-hidden';
  }

  public editHandler(dataItem: any): void {
    this.editForm = formGroup(dataItem);
    this.isNew = false;
    this.gridStyle = 'grid-hidden';
    this.isOpened = true;
  }

  public onCancel(): void {
    this.editForm.reset();
    this.gridStyle = 'grid-default';
    this.isOpened = false;
  }

  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
  }

  //Deleting an entry from dictionary
  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Вы действительно хотите удалить: ${dataItem.dictCycleName.toLowerCase()}?`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.opened = false;
          this.cycleService.deleteCycle(dataItem.dictCycleExternalId)
            .subscribe(
              (response: any) => {
                this.getAllCycles();
                this.notificationService.showSuccess("Удалено");
              },
              (error: any) => {
                if(error.error[0].name)
                  this.notificationService.showError(`Цикл не может быть удалён, так как используется ${error.error[0].usableCount} раз, пример использования в плане: ${error.error[0].name}`);
                else
                  this.notificationService.showError(error.error);
              }
            );
        }
        else{
          this.opened = false;
        }
      }
    });
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup =
  (dataItem: {
    dictCycleId?: any;
    dictCycleExternalId?: any,
    dictCycleName?: any;
    dictCycleShortName?: any;
    dictCyclePrintName?: any;
    dictCycleNameGenitiveCase?: any;
    trainingLevelExternalId?: any,
    standardTypeExternalId?: any;
    dictCycleTypeExternalId?: any;
    dictCycleOptionalMarks?: any;
    dictCycleType?: any;
  }) =>
    new FormGroup({
      dictCycleId: new FormControl(dataItem.dictCycleId),
      dictCycleExternalId: new FormControl(dataItem.dictCycleExternalId),
      dictCycleName: new FormControl(dataItem.dictCycleName),
      dictCycleShortName: new FormControl(dataItem.dictCycleShortName),
      dictCyclePrintName: new FormControl(dataItem.dictCyclePrintName),
      dictCycleNameGenitiveCase: new FormControl(dataItem.dictCycleNameGenitiveCase),
      trainingLevelExternalId: new FormControl(dataItem.trainingLevelExternalId),
      standardTypeExternalId: new FormControl(dataItem.standardTypeExternalId),
      dictCycleTypeExternalId: new FormControl(dataItem.dictCycleTypeExternalId),
      dictCycleOptionalMarks: new FormControl(dataItem.dictCycleOptionalMarks),
      dictCycleType: new FormControl(dataItem.dictCycleType),
    });

export class Filter {
  public trainingLevelId!: Guid | undefined;
  public standardTypeId!: Guid | undefined;
}

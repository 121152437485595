<h1>Настройки карточки студента</h1>
<contingent-header></contingent-header>

<kendo-tabstrip class="custom-tabs k-mt-4">
  <kendo-tabstrip-tab *ngFor="let item of tabItems; let i = index"
                      [title]="item.title"
                      [selected]="i === selected">
    <ng-template kendoTabContent>

      <div class="content-container" [hidden]="i != 0">

        <div class="title">
          <h3>Выбор полей для отображения</h3>
        </div>

        <!--Персональные данные-->
        <div class="content" *ngFor = "let category of displayCategory; let c = index">
          <h2>{{category.name}}</h2>
          <div class="grid-box">
            <ng-container *ngFor = "let item of displaySettings; let i = index">
              <div class="child" *ngIf="category.externalId === item.dictDisplaySettingCategoryId">
                <input type="checkbox" kendoCheckBox [(ngModel)]="item.isDisplay" class="input-check" (change)="valueChange(item)"/>
                <kendo-label class="input-label">{{item.name}}</kendo-label>
              </div>
            </ng-container>
          </div>
        </div>

        <!--Buttons-->
        <div class="functionButtons editModeButtons">
          <button kendoButton
                  size="small"
                  (click)="closeHandler()">
            Закрыть
          </button>
          <button kendoButton
                  size="small"
                  themeColor="primary"
                  (click)="applyHandlerDisplaySetting()">
            Применить
          </button>
        </div>
      </div>

      <div class="content-container" [hidden]="i != 1">

        <div class="title">
          <h3>Настройки системы</h3>
        </div>

        <div class="content" *ngFor = "let setting of contingentSetting; let c = index">
          <h2 class="settings">{{setting.settingName}}</h2>

          <div class="radio-button" *ngIf="setting.category === settingCategory.login">
            <div class="button-block">
              <input
                type="radio"
                name="login"
                value="snils"
                #snils
                [(ngModel)]="contingentSetting[c].value"
                kendoRadioButton
              />
              <p class="text-area" [innerHTML]="loginGenerationFirst"></p>
            </div>

            <div class="button-block">
              <input
                type="radio"
                name="login"
                value="study_form"
                #study_form
                [(ngModel)]="contingentSetting[c].value"
                kendoRadioButton
              />
              <p class="text-area" [innerHTML]="loginGenerationSecond"></p>
            </div>
          </div>

          <div class="radio-button" *ngIf="setting.category === settingCategory.studentNumber">
            <div class="button-block">
              <input
                type="radio"
                name="student_number"
                value="residency"
                #residency
                [(ngModel)]="contingentSetting[c].value"
                kendoRadioButton
              />
              <p class="text-area" [innerHTML]="studNumberGenerationFirst"></p>
            </div>

            <div class="button-block">
              <input
                type="radio"
                name="student_number"
                value="equal_login"
                #equal_login
                [(ngModel)]="contingentSetting[c].value"
                kendoRadioButton
              />
              <p class="text-area" [innerHTML]="studNumberGenerationSecond"></p>
            </div>
          </div>
        </div>

        <div class="functionButtons editModeButtons">
          <button kendoButton
                  size="small"
                  (click)="closeHandler()">
            Закрыть
          </button>
          <button kendoButton
                  size="small"
                  themeColor="primary"
                  (click)="applyHandlerSystemSetting()">
            Применить
          </button>
        </div>
      </div>

    </ng-template>
  </kendo-tabstrip-tab>

</kendo-tabstrip>




import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {DictFacultyModel} from "../../models/contingent/faculty.model";

@Injectable({
  providedIn: 'root'
})
export class DictFacultyService {


  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.faculty}`;

  constructor(private http: HttpClient) { }

  //Get all dict faculty
  public getDictFaculty(): Observable<DictFacultyModel[]> {
    return this.http.get<DictFacultyModel[]>(this.baseUrl + '/GetList');
  }

  //Update dict faculty
  updateDictFaculty(dictFaculty: DictFacultyModel): Observable<DictFacultyModel> {
    return this.http.put<DictFacultyModel>(this.baseUrl + '/' + dictFaculty.externalId, dictFaculty)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

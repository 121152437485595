<classroom-header></classroom-header>
<div class="grid-layout-container" style="margin-top: 20px;"> 
  <div class="main-title"> Настройки доступа </div>

  <span class="filter">
      <h3>Поиск по ФИО</h3>
      <kendo-textbox [(ngModel)]="searchFIO" style="width: 300px"></kendo-textbox>
      <button kendoButton (click)="clearData()">Очистить</button>
      <button kendoButton themeColor="primary" (click)="filterData(searchFIO)">Применить</button>
  </span>

  <kendo-grid class="technical"
              [kendoGridBinding]="gridData"
              (remove)="removeHandler($event)"
              [loading]="loading"
              (add)="addHandler($event)"
              (edit)="editHandler($event)"
              [sortable]="true"
              >
    <ng-template kendoGridToolbarTemplate >
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton (click)="cancelHandler()">Отмена</button>
        <button kendoButton themeColor="primary" [disabled]="formGroup!.invalid"  (click)="saveCurrent()">Сохранить</button>
      </div>
    </ng-template>

    <kendo-grid-command-column  [sticky]="true" title="" [width]="60" >
      <ng-template kendoGridCellTemplate>
          <button kendoGridEditCommand themeColor="secondary" icon="edit" fillMode="flat" size="none" class="custom-size" rounded="full">
          </button>
      </ng-template>
   </kendo-grid-command-column>

    <kendo-grid-command-column [sticky]="true" title="" [width]="60" >
      <ng-template kendoGridCellTemplate>
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>       
      </ng-template>
    </kendo-grid-command-column>
  
    <kendo-grid-column [width]="250" [sticky]="true" headerClass="gridHeader dictionaries" field="personName" title="ФИО">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-combobox
        [sortable]="true"
        [kendoDropDownFilter]="filterSettings"
        [data]="persons"
        textField="personName"
        valueField="personId"
        [valuePrimitive]="true"
       
        [formControl]="formGroup.get('personId')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.personName }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="250" headerClass="gridHeader dictionaries" field="buildings" title="Корпус">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"  let-dataItem="dataItem">
        <kendo-multiselect
        [data]="buildings"
        [(ngModel)]="buildingsEdit"
        kendoMultiSelectSummaryTag        
        [autoClose]="false"
        [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="true"
        textField="buildingName"
        valueField="buildingId"
        [formControl]="formGroup.get('buildings')"
        (valueChange)="buildingValueChange($event, dataItem)">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span></span>
          {{ dataItems.length }} выбрано
        </ng-template>
        </kendo-multiselect>
      </ng-template>
       <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ buildingsList(dataItem.buildings) }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="400" field="departments" headerClass="gridHeader dictionaries" title="Владелец">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-dataItem="dataItem">
        <kendo-multiselect class="faculties"
        kendoMultiSelectSummaryTag
        [kendoDropDownFilter]="filterSettings"
        [data]="departments"
        [(ngModel)]="departmentsEdit"
        textField="departmentName"
        [valuePrimitive]="true"
        valueField="departmentId"
        [formControl]="formGroup.get('departments')"
        (valueChange)="departmentValueChange($event, dataItem)">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          {{ dataItems.length }} выбрано
        </ng-template>
      </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
            {{ departmentsList(dataItem.departments) }}
          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="350" headerClass="gridHeader dictionaries" field="classRoomPurposes" title="Назначение аудитории">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup"  let-dataItem="dataItem">
        <kendo-multiselect
        [data]="classRoomPurposes"
        [(ngModel)]="classRoomPurposesEdit"
        [valuePrimitive]="true"
        kendoMultiSelectSummaryTag        
        [autoClose]="false"
        [filterable]="true"
        [kendoDropDownFilter]="filterSettings"
        textField="classRoomPurposeName"
        valueField="classRoomPurposeId"
        [formControl]="formGroup.get('classRoomPurposes')"
        (valueChange)="assignValueChange($event, dataItem)">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span></span>
            {{ dataItems.length }} выбрано
          </ng-template>
        </kendo-multiselect>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ classRoomPurposesList(dataItem.classRoomPurposes) }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="200" headerClass="gridHeader dictionaries" field="canEdit" title="Возможность редактирования">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('canEdit')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.canEdit ? 'Да' : 'Нет' }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="180" headerClass="gridHeader dictionaries" field="dictionaryAccessLevel" title="Справочники">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="dropdownOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('dictionaryAccessLevel')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.dictionaryAccessLevelName }}
        </span>
      </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column [width]="180" headerClass="gridHeader dictionaries" field="settingsAccess" title="Настройки доступа"
                      *ngIf="isAdmin">
      <ng-template kendoGridEditTemplate let-formGroup="formGroup">
        <kendo-combobox
        [data]="boolOptions"
        textField="text"
        valueField="id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('settingsAccess')">
        </kendo-combobox>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ dataItem.settingsAccess ? 'Да' : 'Нет' }}
        </span>
      </ng-template>
    </kendo-grid-column>
    
  </kendo-grid>
</div>

 <div kendoDialogContainer></div>

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { CommonDictionariesUserAccess } from 'src/app/models/useraccess/commondictionaries/commondictionariesuseraccess.model';
import { environment } from 'src/environments/environment';
import {ContingentUserAccess} from "../../models/useraccess/contingent/contingentuseraccess.model";
import {MiddleControlUserAccess} from "../../models/useraccess/middlecontrol/middlecontroluseraccess.model";
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class CommonDictionariesUserAccessService {
  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.education.dictUserAccess}`;

  constructor(private http: HttpClient) { }

  //Get access
  public getAccess(): Observable<CommonDictionariesUserAccess[]> {
    return this.http.get<CommonDictionariesUserAccess[]>(this.baseUrl + '/GetAccess/')
      .pipe(
        catchError(this.handleError));
  }

  public getAccessLevel(): Observable<CommonDictionariesUserAccess> {
    return this.http.get<CommonDictionariesUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(catchError(this.handleError));
  }

  public getUserAccessLevel():Observable<boolean> {
    return this.http.get<CommonDictionariesUserAccess>(this.baseUrl + '/GetAccessLevel')
      .pipe(
        map(
          (response)=> response.userAccessLevel
        )
      );
  }

  //Save access
  public saveAccess(useraccess: CommonDictionariesUserAccess): Observable<CommonDictionariesUserAccess> {
    return this.http.post<CommonDictionariesUserAccess>(this.baseUrl + '/SaveAccess/', useraccess)
      .pipe(catchError(this.handleError));
  }

  //Delete access
  public deleteAccess(id: string): Observable<CommonDictionariesUserAccess> {
    return this.http.delete<CommonDictionariesUserAccess>(this.baseUrl + '/DeleteAccess/' + id)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector : 'app-giaprotocolhome',
    templateUrl: './giaprotocolhome.component.html',
    styleUrls : ['./giaprotocolhome.component.scss']
})

export class GiaProtocolHomeComponent implements OnInit{

    constructor() {}

    ngOnInit() {}

}
import { IdentificationType } from "./identificationtype.model";
import { StudPerson } from "./studperson.model";
import {Guid} from "guid-typescript";

export class Identifications{
  public identificationId!: number;
  public identificationExternalId!: Guid;
  public studPersonId!: Guid;
  public studPersons!:StudPerson;
  public dictIdentificationTypeId?: Guid;
  public dictIdentificationTypes?: IdentificationType;
  public serial!:string;
  public number!:string;
  public issueDate!:Date;
  public issueOrganization?:string;
  public issueCode?:string;
  public dateBegin!:Date;
  public dateEnd!:Date;
  public issueDateEnd!:Date;
  public visaDateEnd!:Date;
  public contractor?:string;
  public birthPlace?:string;
}

export class IdentificationsContDto{
  public identificationTypeName?: string;
  public serial!:string;
  public number!:string;
  public issueDate!:Date;
  public visaDateEndFormated?:string
  public issueDateFormated?:string;
  public issueOrganization?:string;
  public birthPlace?:string;
}


import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OOProgram } from 'src/app/models/education/oop.model';

@Injectable({
  providedIn: 'root'
})
export class OopService {

  baseUrl = `${environment.educationApiEndpoint}${environment.apiPaths.education.oop}`;

  constructor(private http: HttpClient) { }

  //Get all OOP
  public getAllOOP(): Observable<OOProgram[]> {
    return this.http.get<OOProgram[]>(this.baseUrl + '/');
  }

  // Add OOP
  addOOP(ooprogram: OOProgram): Observable<OOProgram> {
    return this.http.post<OOProgram>(this.baseUrl + '/CreateEducationProgram/', ooprogram)
      .pipe(catchError(this.handleError));
  }

  // Edit OOP
  updateOOP(ooprogram: OOProgram): Observable<OOProgram> {
    return this.http.put<OOProgram>(this.baseUrl + '/UpdateEducationProgram/' + ooprogram.externalId, ooprogram)
      .pipe(catchError(this.handleError));
  }

  // Remove OOP
  deleteOOP(id: string): Observable<OOProgram> {
    return this.http.delete<OOProgram>(this.baseUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  // Получение списка доступных образовательных программ
  getEducationProgramsByStandardId(standardId: string): Observable<OOProgram[]> {
    return this.http.get<OOProgram[]>(this.baseUrl + '/GetEducationProgramsByStandardId/' + standardId)
        .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}


<dl class="grid-container">

  <h1>Журнал массовых операций</h1>


  <dl class="about">
    <kendo-label text="Институт/факультет" class="journal-filter-label"></kendo-label>
    <dd>
      <kendo-multiselect [data]="faculties"
                         kendoMultiSelectSummaryTag
                         textField="shortName"
                         valueField="facultyExternalId"
                         [autoClose]="false"
                         placeholder="Все"
                         class="journal-filter-input"
                         [kendoDropDownFilter]="filterSettings"
                         [checkboxes]="true"
                         [valuePrimitive]="true"
                         (valueChange)="onFacultyChange()"
                         name="selectdepartment"
                         [(ngModel)]="selectedFaculty">
        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
          <span></span> {{ dataItems.length }} выбрано
        </ng-template>
      </kendo-multiselect>
    </dd>
  </dl>

  <div class="display-own">
    <kendo-label text="Отображать только мои операции    ">
      <kendo-switch [(ngModel)]="ownChecked"
                    (valueChange)="ownChange()">
      </kendo-switch>
    </kendo-label>
  </div>

  <kendo-grid [kendoGridBinding]="massOperationJournals"
              (remove)="removeHandler($event)"
              [pageable]="true"  [pageSize]="pageSize"
              [reorderable]="true"
              [filterable] = "true"

              [resizable]="true"
              [sortable]="true"
              [loading]="loading">
    <ng-template kendoGridLoadingTemplate>
      <div class="k-loading-color"></div>
      <div class="loading-container">
        <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
      </div>
    </ng-template>

    <!--Тип операции-->
    <kendo-grid-column field="typeName"
                       title="Тип операции"
                       headerClass="leftAlign"
                       [width]="160">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <filter-dropdown [filter]="filter" [data]="massOperationTypes" textField="typeName" valueField="typeName">
        </filter-dropdown>
      </ng-template>
    </kendo-grid-column>
    <!--Дата операции-->
    <kendo-grid-column field="dateWithoutTime"
                       title="Дата операции"
                       headerClass="leftAlign"
                       [width]="160">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{(dataItem?.date == null ? "" : dataItem.date | date: "dd.MM.yyyy HH:mm:ss")}}
      </ng-template>
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column"
      >
        <date-range-filter
          [field]="column.field"
          [filter]="filter"
        >
        </date-range-filter>
      </ng-template>

    </kendo-grid-column>
    <!--ФИО автора изменений-->
    <kendo-grid-column field="author"
                       title="ФИО автора изменений"
                       headerClass="leftAlign"
                       [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" [showOperators]="false" operator="contains">
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <!--Количество студентов-->
    <kendo-grid-column field="studentCount"
                       title="Количество студентов"
                       headerClass="leftAlign"
                       [width]="100">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter"  format="n0" [showOperators]="false" operator="eq">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column field="studentId"
                       title="Детали операции"
                       headerClass="leftAlign"
                       [filterable]="false"
                       [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells leftAlignment" style="cursor: pointer" (click)="openDetails(dataItem)">
          <h2 style="color: #42A7DD">Посмотреть детали</h2>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoGridRemoveCommand
                [hidden]="!dataItem.cancelable">
          Отменить
        </button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate>
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="5"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-info></kendo-pager-info>
      <kendo-grid-spacer></kendo-grid-spacer>
      <kendo-pager-page-sizes [pageSizes]="sizes"></kendo-pager-page-sizes>
    </ng-template>

  </kendo-grid>

  <!--Buttons-->
  <div class="functionButtons journal-button">
    <button kendoButton

            size="small"
            [routerLink]="['/contingent']">
      К списку студентов
    </button>
  </div>

  <div kendoDialogContainer></div>
</dl>

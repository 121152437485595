import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {BaseFilterCellComponent, FilterService} from "@progress/kendo-angular-grid";

@Component({
  selector: 'filter-multiselect-tree',
  templateUrl: './filter-multiselect-tree.component.html',
  styleUrls: ['./filter-multiselect-tree.component.scss']
})
export class FilterMultiselectTreeComponent extends BaseFilterCellComponent {
  constructor(filterService: FilterService) {
    super(filterService);
  }

  @Input() public data: any[] = [];
  @Input() public textField!: string;
  @Input() public valueField!: string;
  @Input() public gridField!: string;
  @Input() public childrenField: string = '';
  @Input() public parentField: string = '';

  @Output() onFilterChange = new EventEmitter<any[]>();

  public get selectedValue(): any {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  // public tagMapper(tags: any[]) {
  //   const types = tags.filter((item) => item[this.parentField]);
  //   return types.length ? [types] : [];
  // }
  //
  // private filterParentId(values: unknown[]) {
  //   return values.filter((item) => this.data.some(a =>  a[this.parentField] === item));
  // }

  public onChange(values: unknown[]): void {
    this.onFilterChange.emit(values);
    this.filterService.filter({
      filters: values.map(value => ({
        field: this.gridField ?? this.valueField,
        operator: 'contains',
        value
      })),
      logic: 'or'
    });
  }
}

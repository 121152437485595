import { Component, OnInit } from '@angular/core';
import {DownloadFile} from "../../../../helpers/downloadFile-helper";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {PublicationsReportsService} from "../../../../services/science/publications/reports.service";
import {TypesService} from "../../../../services/science/publications/types.service";
import {DictPublicationType} from "../../../../models/publications/dict/publicationType.model";
import {DictScientometricBase} from "../../../../models/publications/dict/scientometricBase.model";
import {ScientometricBasesService} from "../../../../services/science/publications/scientometric-bases.service";

@Component({
  selector: 'publications-reports',
  templateUrl: './publications-reports.component.html',
  styleUrls: ['./publications-reports.component.scss']
})
export class PublicationsReportsComponent implements OnInit {

  public years = this.getYears();
  public types: DictPublicationType[] = [];
  public scientometricBases: DictScientometricBase[] = [];

  public usisYear!: number;
  public reportYear!: number;
  public typeId!: string;
  public scientometricBaseIds!: string[];

  constructor(
    private notificationsService: NotificationsService,
    private typesService: TypesService,
    private scientometricBasesService: ScientometricBasesService,
    private reportsService: PublicationsReportsService
  ) { }

  ngOnInit(): void {
    this.getDicts();
  }

  private getYears() {
    const startYear = 1933;
    const currentYear = new Date().getFullYear();
    return Array.from({length: currentYear - startYear}, (_, i) => currentYear - i);
  }

  private getDicts() {
    this.typesService.getTypes().subscribe((value) => {
      this.types = value;
      this.types.unshift({id: '', name: 'Все'});
    });
    this.scientometricBasesService.getScientometricBases()
      .subscribe(value => this.scientometricBases = value);
  }

  private showEmptyParamsError() {
    this.notificationsService.showError('Выберите параметры для отчета');
  }

  public exportUsis() {
    if (this.usisYear) {
      this.reportsService.exportUsis(this.usisYear).subscribe({
        next: (response) => DownloadFile(response),
        error: (error) => this.notificationsService.showError(error),
      });
    } else {
      this.showEmptyParamsError();
    }
  }

  public exportFullReport() {
    if (this.reportYear) {
      this.reportsService.exportFullReport(this.typeId, this.reportYear, this.scientometricBaseIds).subscribe({
        next: (response) => DownloadFile(response),
        error: (error) => this.notificationsService.showError(error),
      });
    } else {
      this.showEmptyParamsError();
    }
  }
}

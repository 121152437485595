<education-header></education-header>
<title>База ускоренного обучения</title>
<div class="dictEducation__container">
  <h1 *ngIf="!isOpened">База ускоренного обучения</h1>
  <h1 *ngIf="isOpened">
    {{ isNew ? 'Новая база ускоренного обучения' : 'Изменить базу ускоренного обучения' }}
  </h1>

  <div [class]="[gridStyle]">
    <button *ngIf="!isOpened&&editable" class="addButton" (click)="addHandler()" kendoButton [primary]="true" iconClass="k-icon k-i-plus">Добавить</button>
    <kendo-grid #grid
                [data]="acceleratedStudyBases"
                [kendoGridBinding]="acceleratedStudyBases"
                [navigable]="true"
                [sortable]="true"
                [filterable]="true"
                [loading]="loading">
      <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
      </ng-template>
      <kendo-grid-column headerClass="gridHeader dictionaries" field="dictAcceleratedStudyBaseName" title="Название">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell
                  [column]="column"
                  [filter]="filter"
                  [showOperators]="false"
                  operator="contains">
            <kendo-filter-contains-operator></kendo-filter-contains-operator>
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{dataItem.dictAcceleratedStudyBaseName}}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column headerClass="gridHeader dictionaries" *ngIf="editable" title="" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton type="button" (click)="editHandler(dataItem)" class="editButton">Изменить</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  </div>

  <div class="editForm" *ngIf="isOpened">
    <form novalidate class="k-form" [formGroup]="editForm">
      <kendo-formfield>
        <div class="formRow required">
          <h2>Название</h2>
          <kendo-textbox formControlName="dictAcceleratedStudyBaseName" #dictAcceleratedStudyBaseName required></kendo-textbox>
        </div>
        <kendo-formerror>Ошибка: Заполните необходимое поле</kendo-formerror>
      </kendo-formfield>
      <div class="buttons">
        <button kendoButton (click)="onCancel()" type="button" class="cancelButton">Отменить</button>
        <button kendoButton themeColor="primary" [disabled]="!editForm.valid" (click)="onSave()">Сохранить</button>
      </div>
    </form>
  </div>
</div>
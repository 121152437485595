import {Component, Input, OnInit} from '@angular/core';
import { DictWorkScheduleStudyProcessTypeService } from '../../../services/education/DictWorkScheduleStudyProcessType/dict-work-schedule-study-process-type.service';
import { DictWorkScheduleStudyProcessType } from '../../../models/education/dictWorkScheduleStudyProcessType.model';
import { EducationPlanDetailsService } from '../../../services/education/EducationPlan/education-plan-details.service';
import { Router } from '@angular/router';
import {EducationUserAccessService} from "../../../services/useraccess/education-user-access.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-educational-process-schedule-legend',
  templateUrl: './educational-process-schedule-legend.component.html',
  styleUrls: ['./educational-process-schedule-legend.component.scss'],
})
export class EducationalProcessScheduleLegendComponent implements OnInit {
  @Input() educationPlanId: string | null = null;

  public dictWorkScheduleStudy: DictWorkScheduleStudyProcessType[] = [];
  public trainingLevelId: string = '';
  private allWorkScheduleStudyProcessTypesSubscription$!: Subscription;
  public isInstallationSession = false;

  constructor(
    public dictWorkScheduleStudyProcessTypes: DictWorkScheduleStudyProcessTypeService,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private router: Router,
    private userAccessService:EducationUserAccessService
  ) {}

    ngOnInit(): void {
    if(this.educationPlanId!=null) this.getAccessLevel(this.educationPlanId);
    this.allWorkScheduleStudyProcessTypesSubscription$ =
      this.dictWorkScheduleStudyProcessTypes.allWorkScheduleStudyProcessTypes$.subscribe(
        (response) => {
          this.dictWorkScheduleStudy = response.filter(
            (item) =>
              item.trainingLevelExternalId ===
              this.educationPlanDetailsService.currentTrainingLevelId &&
              item.filialId === this.educationPlanDetailsService.currentFilialId
          );
          this.isInstallationSession = this.educationPlanDetailsService.currentStudyForm.toLowerCase().includes("заоч")
        }
      );
  }

  ngOnDestroy() {
    this.allWorkScheduleStudyProcessTypesSubscription$.unsubscribe();
  }
  public toggleEdit() {
    this.router.navigate(['education/global-schedule-settings']);
  }

  public editable?: boolean;

  public getAccessLevel(educationPlanId:string):any{
    this.userAccessService.getPlanEditAccessLevel(educationPlanId)
      .subscribe(
        response => {
          this.editable = response.accessLevel;
        }
      );
  }
}

import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {CertificateStudent, Student, StudentName} from "../../models/contingent/student.model";
import {Guid} from "guid-typescript";
import {catchError} from "rxjs/operators";
import {StudentHistory, StudentHistoryRequest} from "../../models/contingent/studenthistory.model";
import {Spravka} from "../../models/contingent/spravka.model";

@Injectable({
  providedIn: 'root'
})
export class StudenthistoryService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.studenthistory}`;

  constructor(private http: HttpClient) { }

  //Get all studentHistory
  getStudentHistory(id: Guid): Observable<StudentHistory[]> {
    return this.http.get<StudentHistory[]>(this.baseUrl + '/GetStudentHistory/' + id)
      .pipe(catchError(this.handleError));
  }

  getHistoryDetails(request: StudentHistoryRequest): Observable<StudentHistory> {
    return this.http.post<StudentHistory>(this.baseUrl + '/GetHistoryDetails/', request)
      .pipe(catchError(this.handleError));
  }

  deleteStudentHistory(id: string| undefined): Observable<Spravka> {
    return this.http.delete<Spravka>(this.baseUrl + '/DeleteStudentHistory/' + id)
      .pipe(catchError(this.handleError));
  }

  deleteStudentHistoryList(ids: string[]): Observable<Spravka> {
    return this.http.post<Spravka>(this.baseUrl + '/DeleteStudentHistoryList/', ids)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

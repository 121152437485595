import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { PagerType } from '@progress/kendo-angular-pager';
import { PagerPosition, GridComponent, CellClickEvent, EditEvent, AddEvent} from '@progress/kendo-angular-grid';
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { PostsService } from 'src/app/services/persondepartment/posts.service';
import { CompositeFilterDescriptor, filterBy, } from "@progress/kendo-data-query";
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { Status, statuses } from "src/app/components/persondepartment/status";
import { getDayStart } from 'src/app/helpers/date-helper';

@Component({
    selector   : 'app-posts',
    templateUrl: './posts.component.html',
    styleUrls  : ['./posts.component.scss']
})

export class PostsComponent implements OnInit{
    
    public posts: any = [];
    public postId: string = "";
    
    public loading = false;
    public skip = 0;
    public pageSizes = [10, 20, 30];
    public pageSize = 10;
    public type: PagerType = 'numeric';
    public buttonCount = 5;
    public previousNext = true;
    public positionPager: PagerPosition = 'bottom';
    public info = true;

    public position: "top" | "bottom" | "both" = "top";
    public editedRowIndex: number | undefined;
    private isNew = false;
    private isLine = false;

    public get isInEditingMode(): boolean{
        return this.editedRowIndex !== undefined || this.isNew;
    }

    public formGroup: FormGroup | undefined;

    public filterData: any = [];

    public statuses = statuses;

    public method: Array<{ methodName: string; recordSource: number }> = [
        {methodName: "1C", recordSource: 0},
        {methodName: "Вручную", recordSource: 1},
    ];

    @ViewChild(GridComponent) private grid!: GridComponent;
    public exists: boolean = false;

constructor(
    private postsService: PostsService,
    private notificationService: NotificationsService,
    private renderer: Renderer2,
){}

ngOnInit() {
    this.getPostsPersons();

   this.renderer.listen("document", "click", ({ target }) => {       
        if (!isChildOf(target, "k-touch-action-auto") && !isChildOf(target, "editRecord") || target.outerHTML.indexOf('reset') !== -1 )
        {
          this.cancelHandler();
        }
    });
}

    public getPostsPersons(){
        this.postsService.getPosts()
            .subscribe(response => {
                this.posts = response;
          //      console.log(this.posts);

                for (let i=0; i< this.posts.length; i++){
                    this.posts[i].createdAt = getDayStart(this.posts[i].createdAt);
                    this.posts[i].updatedAt = getDayStart(this.posts[i].updatedAt);
                }
            })    
    }

    public Status(value:any){
        return Status(value)
    }

    public addHandler({ sender }: AddEvent): void {
        this.closeEditor(sender);
  
        this.formGroup = formGroup({
          postName: '',
          postSName: '',
          commonStatus: '',
        });
  
        this.isLine = true;
        this.isNew = true;
        sender.addRow(this.formGroup);
    }

    private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
        ): void {
          this.isNew = false;
          grid.closeRow(rowIndex);
          this.editedRowIndex = undefined;
          this.formGroup = undefined;
    }

    public cancelHandler(){
        this.isLine = false;
        this.closeEditor(this.grid, this.editedRowIndex);
    }

    public saveCurrent(){
        if (this.formGroup && !this.formGroup.valid) {
            return;
        }
        this.isLine = false;
        this.saveRow();
    }

    public editHandler(value: EditEvent): void {
        if (this.isLine) {
            this.cancelHandler();
            return
        }
        
        this.isLine = true;
        this.postId = value.dataItem.externalId;

        this.editedRowIndex = value.rowIndex;
        
        if (value.dataItem.recordSource == 0){
            this.formGroup = formGroup(value.dataItem);
            value.sender.editCell(value.rowIndex, 2, this.formGroup);
        }
        else {
            this.formGroup = formGroup(value.dataItem);
            value.sender.editRow(value.rowIndex, this.formGroup);
        }
    }

    private saveRow(): void {
        if (this.isNew == true && this.formGroup !== undefined) {
            this.exists = false;

            let name = this.formGroup.value.postName.toLowerCase().trim();
            if (this.posts.findIndex((item:any) => item.postName.toLowerCase() == name ) !== -1){
                this.notificationService.showError("Невозможно сохранить, так как такое наименование должности уже существует");
                this.exists = true;
            }

            if (this.exists == false) {
                this.postsService.addPost(this.formGroup.value)
                .subscribe(
                  response => {
                    this.getPostsPersons();
                    this.notificationService.showSuccess("Добавлено");
                  },
                  error => {
                    this.notificationService.showError("Не удалось сохранить запись");
                  }
                );
            }
            
        } else {
            if (this.formGroup !== undefined) {

                this.exists = false;
                let name = this.formGroup.value.postName.toLowerCase().trim();
                if (this.posts.findIndex((item:any) => item.postName.toLowerCase() == name ) !== -1 && 
                    this.posts.find((item:any) => item.postName.toLowerCase() == name).externalId !== this.postId) {
                        
                    this.notificationService.showError("Невозможно сохранить, так как такое наименование должности уже существует");
                    this.exists = true;
                }
                
                if (this.exists == false){
                    this.postsService.updatePost(this.formGroup.value, this.postId)
                    .subscribe(
                      response => {
                        this.getPostsPersons();
                        this.notificationService.showSuccess("Сохранено");
                      },
                      error => {
                        if (error.status == 409) {
                            let index1 = error.error.indexOf(' ');
                            let index2 = error.error.indexOf('InnerException:');
                            let textError = error.error.slice(index1, index2);
                            this.notificationService.showError(textError);
                        } else this.notificationService.showError("Не удалось изменить запись");
              
                    });
                }
            }
        }

        this.closeEditor(this.grid);
    }

    public filter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [],
      };

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        this.loadData();
    }

    public loadData(): void {
        this.filterData = filterBy(this.posts, this.filter);
    }

}

const formGroup = (dataItem: { postName?: any; postSName?: any; commonStatus?: any; }) =>
  new FormGroup({
    postName: new FormControl(dataItem.postName, Validators.required),
    postSName: new FormControl(dataItem.postSName),
    commonStatus: new FormControl(dataItem.commonStatus, Validators.required),
});

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { StatementSettings } from "../../../models/middlecontrol/statementSetting.model";
import { DictStatementSettingsCategory } from "../../../models/middlecontrol/dictStatementSettingsCategory.model";
import { StatementSettingsService } from "../../../services/middlecontrol/statement-settings.service";
import { DictsService } from "../../../services/middlecontrol/dicts.service";
import { NotificationsService } from "../../../services/notifications/notifications.service";
import { StatementSettingsEnum } from "../../../models/middlecontrol/enums/statement-setting.enum";

@Component({
    selector: 'app-statementparams',
    templateUrl: './statementparams.component.html',
    styleUrls: ['./statementparams.component.scss']
  })
export class StatementParamsComponent implements OnInit {
  constructor(
    private router: Router,
    private statementSettingsService: StatementSettingsService,
    private notificationService: NotificationsService,
    private dictService: DictsService,
  ) { }

  public ngOnInit(): void {
    this.getStatementSettings();
    this.getStatementSettingCategory();
  }

  public statementSettings: StatementSettings = {
    statementSettings: [],
    statementRadioSettings: []
  };
  public statementCategory: DictStatementSettingsCategory[] = [];
  public statementSettingsEnum = StatementSettingsEnum;

  public getStatementSettings() {
    this.statementSettingsService.GetStatementSettings().subscribe(
      response => {
        this.statementSettings = response;
      }
    );
  }

  public getStatementSettingCategory() {
    this.dictService.GetStatementSettingCategory().subscribe(
      response => {
        this.statementCategory = response;
      }
    );
  }

  public applySetting(){
    this.statementSettingsService.EditStatementSettings(this.statementSettings)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
          this.closeHandler();
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }});
  }

  public closeHandler(){
    this.router.navigateByUrl(`/middlecontrol`).then();
  };
}
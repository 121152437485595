import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { SearchPosts } from '../../models/announcement/search-persons.model';
import { SearchDepatments } from '../../models/announcement/search-persons.model';
import { FoundPersons} from '../../models/announcement/search-persons.model';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';
import { Persons } from "../../models/announcement/person.model";

@Injectable({
    providedIn: 'root'
  })

  export class SearchPersonsService {
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);

  constructor(private http: HttpClient) { }

  public getPosts() {
   
    return this.http.get<SearchPosts[]>(this.baseUrl + '/posts' )
      .pipe(
        catchError(this.handleError));
  }

  public getDepartments() {
   
    return this.http.get<SearchDepatments[]>(this.baseUrl + '/departments' )
      .pipe(
        catchError(this.handleError));
  }

  observer = new Subject();
  public subscriber$ = this.observer.asObservable();

  public getPersons(initials: string, posts: [], departments: []){
    let request = {userType: 0, fio: initials, postIds: posts, departmentIds: departments};

    this.http.post(this.baseUrl  + '/users', request ).subscribe({next:(data: any) => {
      this.observer.next(data);
  }});

   return this.http.post(this.baseUrl  + '/users',request); 
  }

  public getAllPerson(): Observable<Persons[]> {
    return this.http.get<Persons[]>(this.baseUrl  + '/GetPersons')      
      .pipe(
        catchError(this.handleError));
  }
  
  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
<div class="container">
  <h1>Поручение по дисциплине</h1>
  <div class="discipline">
    <span class="disciplineChild info">
      <h3 class="yearAndSemester">{{yearAndSemestrs?.name}}</h3>
      <h3>
          <!-- <span>{{trainingLevel}}</span> -->
          {{faculty?.name}} {{studyForm?.name}}</h3>
  </span>
    <span class="disciplineChild">
      <h3>{{kafedra?.name}}</h3>
  </span>
    <span class="disciplineChild info">
      <h3>В поручении:</h3>
      <h3 [ngSwitch]="onAssignment">
          <span class="green bold" *ngSwitchCase="true">Да</span>
          <span class="red bold" *ngSwitchCase="false">Нет</span>
      </h3>
  </span>
  </div>
  <div class="options" *ngIf="isPractice && !loading">
    <span *ngFor='let item of dictPracticeTypes' class="child">
      <h3>{{ item.name }}</h3>
      <kendo-switch [checked]='practiceTypeId == item.id'
                    [disabled]="!editing"
                    (valueChange)='practiceTypeChange($event, item.id)'
      ></kendo-switch>
    </span>
    <span class="child">
      <h3>Пользовательское количество подгрупп</h3>
      <kendo-switch [(ngModel)]="customSubGroups" [disabled]="!editing"></kendo-switch>
    </span>
  </div>
  <div *ngIf="!isPractice && !loading" style='display: flex;'>
    <div class="options">
      <form [formGroup]="calcalulationMethods">
        <span class="child">
            <h3>Способ расчета практических занятий</h3>
            <span class="radioButtons">
                <input type="radio" (click)="radioButtonSet('practiceCalc', 1)" [value]="1" formControlName="practiceMethod" kendoRadioButton />
                <h3>Группа</h3>
                <input type="radio" (click)="radioButtonSet('practiceCalc', 2)" [value]="2" formControlName="practiceMethod" kendoRadioButton />
                <h3>Подгруппа</h3>
            </span>
        </span>
        <span class="child padding">
            <h3>Способ расчета лабораторных занятий</h3>
            <span class="radioButtons">
                <input type="radio"
                       (click)="radioButtonSet('labCalc', 1)"
                       [value]="1" formControlName="labMethod" kendoRadioButton/>
                <h3>Группа</h3>
                <input type="radio" (click)="radioButtonSet('labCalc', 2)"
                       [value]="2" formControlName="labMethod" kendoRadioButton/>
                <h3>Подгруппа</h3>
            </span>
        </span>
      </form>
      <span class="child" *ngFor='let item of dictCalculationMethodCathedralWorks; let i = index'>
        <h3>{{item.name}}</h3>
        <span class="radioButtons">
          <input kendoRadioButton type="radio"
                 [disabled]="!editing"
                 [checked]='calculationMethodCathedralWorkCheck(item, 1)'
                 (click)="calculationMethodCathedralWorkChange(item.id, 1)"
          />
          <h3>Группа</h3>
          <input kendoRadioButton type="radio"
                 [disabled]="!editing"
                 [checked]='calculationMethodCathedralWorkCheck(item, 2)'
                 (click)="calculationMethodCathedralWorkChange(item.id, 2)"
          />
          <h3>Подгруппа</h3>
        </span>
      </span>
      <span class="child">
      <h3>Количество потоков по лекциям</h3>
      <span [ngSwitch]="editing">
        <h3 *ngSwitchCase="false">{{lectureFlowItem}}</h3>
        <kendo-dropdownlist [data]="lectureFlows"
                            #lectureFlowDropdown
                            (selectionChange)="flowChange($event, 'lecture')"
                            *ngSwitchCase="true"
                            [valuePrimitive]="true"
                            [(ngModel)]="lectureFlowItem.flowNumber"
                            valueField="flowNumber"
                            textField="flowNumber"
                            class="dropdown">
        </kendo-dropdownlist>
      </span>
    </span>
      <span class="child">
      <h3>Количество потоков по практическим занятиям</h3>
      <span [ngSwitch]="editing">
          <h3 *ngSwitchCase="false">{{practiceFlowItem}}</h3>
          <kendo-dropdownlist
            [data]="practiceFlows"
            #practiceFlowDropdown
            [disabled]="practiceFlowToggle"
            [(ngModel)]="practiceFlowItem.flowNumber"
            [valuePrimitive]="true"
            valueField="flowNumber"
            textField="flowNumber"
            (selectionChange)="flowChange($event, 'practice')"
            *ngSwitchCase="true"
            class="dropdown">
          </kendo-dropdownlist>
      </span>
    </span>
    </div>
    <div class="options k-ml-5">
      <span *ngFor='let item of dictIncreasedCoefficients' class="child">
          <h3>{{ item.name }}</h3>
          <kendo-switch [checked]='increasedCoefficientCheck(item.id)'
                        [disabled]="!editing"
                        (valueChange)='increasedCoefficientChange($event, item.id)'
          ></kendo-switch>
        </span>
      <span class="child">
        <h3>Пользовательское количество подгрупп</h3>
        <kendo-switch [(ngModel)]="customSubGroups" [disabled]="!editing"></kendo-switch>
      </span>
      <span class="child">
        <h3>Занятия по подгруппам</h3>
        <kendo-switch [(ngModel)]="classesBySubGroups" [disabled]="!editing"
                      (valueChange)='classesBySubGroupsCheck($event)'
        ></kendo-switch>
      </span>
    </div>
  </div>
  <div class="options">
    <span class="buttons">
      <button kendoButton (click)="onCancel()">Отмена</button>
      <button kendoButton (click)="recallAssignmentDisciplines()" *ngIf="!editing && editingRights">Отозвать поручения</button>
      <button kendoButton *ngIf="!editing && editingRights" icon="reload" themeColor="primary" (click)="updateAssignmentDiscipline()">Обновить поручение</button>
      <button kendoButton *ngIf="editing" [disabled]="!dataValidation.valid" (click)="saveAssignmentDiscipline()" [primary]="true" icon="plus">Создать поручение</button>
      <button kendoButton *ngIf="editing" [disabled]="!calcalulationMethods.valid" (click)="addHandler()" icon="plus" themeColor="primary">Дисциплину в сопряжение</button>
      <kendo-combobox *ngIf="editing"
              #disciplineDropdown
              valueField="id"
              (selectionChange)="changeDictDiscipline($event)"
              [kendoDropDownFilter]="filterSettings"
              textField="fullName"
              [virtual]="virtual"
              [data]="dictDisciplines">
      </kendo-combobox>
    </span>
  </div>
  <kendo-grid class="commissionsGrid"
              [ngClass]="{
                noData: groupResult.length < 4,
                edit: !onAssignment
              }"
              [loading]="loading"
              [height]="600"
              (remove)="removeGroupDiscipline($event)"
              (cellClick)="editDisciplineGroup($event)"
              [kendoGridSelectBy]="selectionKey"
              [selectedKeys]="selection"
              (selectionChange)='groupSelectionChange($event)'
              [selectable]="{ enabled: !onAssignment, checkboxOnly: true }"
              [kendoGridBinding]="groupResult"
              [rowSelected]='isRowSelected'>
    <ng-template kendoGridLoadingTemplate>
        <div class="k-loading-color"></div>
        <div class="loading-container">
            <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>
    <ng-template *ngIf="editing && isAddDiscipline && gridData.length > 0" kendoGridToolbarTemplate position="bottom">
          <div class="addDisciplineButton">
              <button (click)="addGroup()" kendoButton icon="plus" themeColor="primary">Добавить группу</button>
          </div>
      </ng-template>
    <kendo-grid-checkbox-column headerClass="gridHeader hideborder"
                                class='grid-checkbox'
                                [hidden]='onAssignment'
                                [width]="50"
                                [showSelectAll]="true">
    </kendo-grid-checkbox-column>
    <kendo-grid-column [hidden]="true" field="groupByDiscipline">
        <ng-template kendoGridGroupHeaderTemplate let-group="group" let-index="index">
            <span class="alignCells bold leftAlignment middle" style='margin-left: 20px'>
                <span class="groupValue">{{ displayGroupName(group) }}</span>
                <span *ngIf="editing && index > 0" class="removeBtn red"
                      (click)="deleteItem(group)"
                      kendoButton
                      icon="delete"
                      fillMode="flat"
                      size="large"
                      rounded="full"
                      style="text-overflow: unset;">
                </span>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="isAddDiscipline && editing" headerClass="gridHeader hideborder" [width]="50" title="">
        <ng-template kendoGridCellTemplate>
            <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
                    rounded="full">
            </button>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="educationPlanDiscipline.semesterNumber" title="Сем."
                       [width]="isEditGroup && editing ? 100 : 50"
                       [editable]='isAddDiscipline'>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.educationPlanDiscipline?.semesterNumber}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup>
            <kendo-dropdownlist required
                                [data]="semesters"
                                [formControl]="formGroup.get('educationPlanDiscipline.semesterNumber')">
            </kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="disciplineName"
                       title="Ак. группы (студ. факт)"
                       [width]="isEditGroup && editing ? 230 : 115"
                       [editable]='isAddDiscipline'>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells" [ngSwitch]="editing">
                <span *ngSwitchCase="true">{{dataItem.eduGroup?.name}} ({{dataItem.eduGroup?.studentCount}} чел.)</span>
                <span *ngSwitchCase="false">{{dataItem.eduGroup?.name}} ({{dataItem.eduGroup?.studentCount}} чел.)</span>
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-rowIndex="rowIndex">
            <kendo-combobox
                    [data]="eduGroups"
                    [kendoDropDownFilter]="filterSettings"
                    textField="fullName"
                    valueField="id"
                    (valueChange)="onEduGroupChange($event, dataItem.educationPlanDiscipline.id, rowIndex)"
                    required
                    [formControl]="formGroup.get('eduGroup')"></kendo-combobox>
        </ng-template>
        <ng-template kendoGridGroupHeaderTemplate let-group="group">
            <span>
              {{ group.value }}
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="studentCalc" [width]="85" title="Студ. в расч."
                       [editable]='isAddDiscipline'>
        <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells" [ngClass]="mismatchStudentStyle(dataItem)">
          {{displayStudentCount(dataItem)}}
        </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup>
            <kendo-numerictextbox required [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                    [formControl]="formGroup.get('contingentLoad.plannedStudentCount')"></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="subGroup" [width]="85" title="П/г"
                       [editable]='isAddDiscipline || customSubGroups'>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells" [ngClass]="mismatchSubGroupStyle(dataItem)">
              {{displaySubGroup(dataItem)}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup>
            <kendo-numerictextbox required [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                    [formControl]="formGroup.get('contingentLoad.subGroupCount')"></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="controlAction"
                       title="Контроль"
                       [width]="isEditGroup && editing ? 200 : 100"
                       [editable]='isAddDiscipline'>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.educationPlanDiscipline?.dictControllingAction?.name}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem let-formGroup>
            <kendo-dropdownlist required valueField="id" textField="name"
                                [data]="controllingActions"
                                [formControl]="formGroup.get('educationPlanDiscipline.dictControllingAction')"
            ></kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group headerClass="gridHeader hideborder" field="contactWork" title="Контактная работа, ч">
      <kendo-grid-column headerClass="gridHeader hideborder" field="errand" [width]="180" title="потоки лек.">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells checkboxes">
            <span *ngFor="let item of dataItem.lecturesFlows; let i = index">
              <input [checked]="viewonlyCheckbox(dataItem.groupFlows, i+1, staticPropertyEnum.lectionId)" type="checkbox"
                     [disabled]="true" kendoCheckBox />
            </span>
          </span>
          <form [formGroup]="calcalulationMethods">
            <span class="alignCells checkboxes">
              <span *ngFor="let item of lectureFlowAmount; let i = index">
                <input [checked]="viewonlyCheckbox(dataItem.groupFlows, item, staticPropertyEnum.lectionId)"
                       (click)="checkBoxChange(dataItem, item, staticPropertyEnum.lectionId, $event)"
                       [value]="item" type="radio" kendoCheckBox />
              </span>
            </span>
          </form>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader hideborder" field="lectureHours" title="лек."
                         [width]="isEditGroup && editing ? 100 : 60"
                         [editable]='isAddDiscipline'>
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells" [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.lectionId)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.lectionId)}}
              </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem let-formGroup>
              <div [formGroup]="formGroup.get('educationPlanDiscipline')">
                  <div formArrayName="typeWorks">
                      <div [formGroupName]="getTypeWorksFormGroupIndex(staticPropertyEnum.lectionId)">
                          <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                                                formControlName="hours"></kendo-numerictextbox>
                      </div>
                  </div>
              </div>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader hideborder" field="labHours" title="лаб."
                         [width]="isEditGroup && editing ? 100 : 60"
                         [editable]='isAddDiscipline'>
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells" [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.laboratoryId)">
                {{displayTypeWorks(dataItem, staticPropertyEnum.laboratoryId)}}
              </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem let-formGroup>
              <div [formGroup]="formGroup.get('educationPlanDiscipline')">
                  <div formArrayName="typeWorks">
                      <div [formGroupName]="getTypeWorksFormGroupIndex(staticPropertyEnum.laboratoryId)">
                          <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                                                formControlName="hours"></kendo-numerictextbox>
                      </div>
                  </div>
              </div>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader hideborder" field="errand" [width]="180" title="потоки практ.">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells checkboxes">
                  <span *ngFor="let item of dataItem.practiceFlows; let i = index">
                      <input [checked]="viewonlyCheckbox(dataItem.groupFlows, item, staticPropertyEnum.practiceId)" type="checkbox" [value]="item" [disabled]="true" kendoCheckBox />
                  </span>
              </span>
              <span class="alignCells checkboxes">
                  <form [formGroup]="calcalulationMethods">
                      <span class="alignCells checkboxes">
                          <span *ngFor="let item of practiceFlowAmount; let i = index">
                              <input [checked]="viewonlyCheckbox(dataItem.groupFlows, item, staticPropertyEnum.practiceId)"
                                     (click)="checkBoxChange(dataItem, item, staticPropertyEnum.practiceId, $event)"
                                     [value]="item" type="radio" kendoCheckBox />
                          </span>
                      </span>
                  </form>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader hideborder" field="practiceHours" title="практ."
                         [width]="isEditGroup && editing ? 100 : 60"
                         [editable]='isAddDiscipline'>
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells" [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.practiceId)">
                {{displayTypeWorks(dataItem, staticPropertyEnum.practiceId)}}
              </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup>
              <div [formGroup]="formGroup.get('educationPlanDiscipline')">
                  <div formArrayName="typeWorks">
                      <div [formGroupName]="getTypeWorksFormGroupIndex(staticPropertyEnum.practiceId)">
                          <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                                                formControlName="hours"></kendo-numerictextbox>
                      </div>
                  </div>
              </div>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column headerClass="gridHeader hideborder" field="ksrHours" title="КСР"
                         [width]="isEditGroup && editing ? 100 : 60"
                         [editable]='isAddDiscipline'>
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="alignCells" [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.CSR)">
                {{displayTypeWorks(dataItem, staticPropertyEnum.CSR)}}
              </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup>
              <div [formGroup]="formGroup.get('educationPlanDiscipline')">
                  <div formArrayName="typeWorks">
                      <div [formGroupName]="getTypeWorksFormGroupIndex(staticPropertyEnum.CSR)">
                          <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                                                formControlName="hours"></kendo-numerictextbox>
                      </div>
                  </div>
              </div>
          </ng-template>
    </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column headerClass="gridHeader hideborder" field="numberWeek" title="Нед."
                       [width]="isEditGroup && editing ? 100 : 55"
                       [editable]='isAddDiscipline'>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{dataItem.educationPlanDiscipline.numberWeek}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup>
            <kendo-numerictextbox required [decimals]="0" [changeValueOnScroll]="false" [min]="1" format="false"
                    [formControl]="formGroup.get('educationPlanDiscipline.numberWeek')"></kendo-numerictextbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="courseWork" title="КР"
                       [width]="isEditGroup && editing ? 130 : 50"
                       [editable]='isAddDiscipline'>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{displayCourseWork(dataItem)}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup>
            <kendo-dropdownlist
                    [data]="options"
                    [formControl]="formGroup.get('educationPlanDiscipline.hasCourseWork')"
                    valueField="value"
                    textField="text"
                    [valuePrimitive]="true"
            ></kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column headerClass="gridHeader hideborder" field="courseProject" title="КП"
                       [width]="isEditGroup && editing ? 130 : 50"
                       [editable]='isAddDiscipline'>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
              {{displayCourseProject(dataItem)}}
            </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup>
            <kendo-dropdownlist
                    [data]="options"
                    [formControl]="formGroup.get('educationPlanDiscipline.hasCourseProject')"
                    valueField="value"
                    [valuePrimitive]="true"
                    textField="text">
            </kendo-dropdownlist>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column-group headerClass="gridHeader hideborder" title="Работы (контр.работы, РГР, реф., эссе), шт">
        <kendo-grid-column headerClass="gridHeader hideborder" field="testNum" [width]="isEditGroup && editing ? 100 : 60" title="контр."
                           [editable]='isAddDiscipline'>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells" [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.ControlWork)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.ControlWork)}}
                </span>
            </ng-template>
            <ng-template kendoGridEditTemplate let-formGroup>
                <div [formGroup]="formGroup.get('educationPlanDiscipline')">
                    <div formArrayName="typeWorks">
                        <div [formGroupName]="getTypeWorksFormGroupIndex(staticPropertyEnum.ControlWork)">
                            <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                                                  formControlName="hours"></kendo-numerictextbox>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column headerClass="gridHeader hideborder" field="rgrNum" [width]="isEditGroup && editing ? 100 : 60" title="РГР"
                           [editable]='isAddDiscipline'>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells" [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.RGR)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.RGR)}}
                </span>
            </ng-template>
            <ng-template kendoGridEditTemplate let-formGroup>
                <div [formGroup]="formGroup.get('educationPlanDiscipline')">
                    <div formArrayName="typeWorks">
                        <div [formGroupName]="getTypeWorksFormGroupIndex(staticPropertyEnum.RGR)">
                            <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                                                  formControlName="hours"></kendo-numerictextbox>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column headerClass="gridHeader hideborder" field="refNum" [width]="isEditGroup && editing ? 100 : 60" title="реф."
                           [editable]='isAddDiscipline'>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="alignCells" [ngClass]="mismatchStyle(dataItem, staticPropertyEnum.Referat)">
                  {{displayTypeWorks(dataItem, staticPropertyEnum.Referat)}}
                </span>
            </ng-template>
            <ng-template kendoGridEditTemplate let-formGroup>
                <div [formGroup]="formGroup.get('educationPlanDiscipline')">
                    <div formArrayName="typeWorks">
                        <div [formGroupName]="getTypeWorksFormGroupIndex(staticPropertyEnum.Referat)">
                            <kendo-numerictextbox [decimals]="0" [changeValueOnScroll]="false" [min]="0" format="false"
                                                  formControlName="hours"></kendo-numerictextbox>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column headerClass="gridHeader hideborder" field="assignmentUpdate" [width]="90" title="Дата выдачи">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            {{updatedAt}}
          </span>
        </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

import {Component, OnInit, Renderer2} from '@angular/core';
import {MassoperationjournalService} from "../../../../services/contingent/massoperationjournal.service";
import {StudentService} from "../../../../services/contingent/student.service";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {DialogCloseResult, DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {
  MassOperationInfo,
  MassOperationStudents
} from "../../../../models/contingent/massoperationjournal.model";
import {PageSizeItem, RowClassArgs} from "@progress/kendo-angular-grid";
import {ActivatedRoute, Router} from "@angular/router";
import { OrdersService } from '../../../../services/contingent/orders.service';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { saveAs } from '@progress/kendo-file-saver';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-contingentjournaldetails',
  templateUrl: './contingentjournaldetails.component.html',
  styleUrls: ['./contingentjournaldetails.component.scss']
})
export class ContingentjournaldetailsComponent implements OnInit {

      constructor(private massoperationjournalService: MassoperationjournalService,
                  private studentService: StudentService,
                  private renderer: Renderer2,
                  private notificationService: NotificationsService,
                  private dialogService: DialogService,
                  private activateRoute: ActivatedRoute,
                  private router: Router,
                  private ordersService: OrdersService,
                  )
  {
    this.operationId = activateRoute.snapshot.params["id"];
  }

  ngOnInit(): void {
    this.getMassOperationInfo()
    this.getMassOperationStudents()
  }

  public operationId: any;
  public massOperationJournal?: MassOperationInfo;
  public massOperationStudents: MassOperationStudents[]=[]
  public loading = false;
  public pageSize = 10;
  public sizes:(number | PageSizeItem)[] = [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '200', value: 200}, {text: 'Все', value : 'all'}];
  public anyChanged = false
  public allChanged = false

  public restrictions: FileRestrictions = {
    allowedExtensions: [".docx", ".pdf"],
    maxFileSize: environment.contingent.maxFileSize,
  };
  public files?: Array<File>;

  public opensed(url:any) {
    setTimeout(() => window.open(url), 50);
  }

  public getMassOperationInfo() {
    this.massoperationjournalService.getByIdMassOperation(this.operationId)
      .subscribe(
        response => {
          this.massOperationJournal = response;
        }
      );
  }

  public getMassOperationStudents() {
    this.loading = true;
    this.massoperationjournalService.getMassOperationStudents(this.operationId)
      .subscribe(
        response => {
          this.massOperationStudents = response;
          this.anyChanged = this.massOperationStudents.find((x) => x.changed === true) !== undefined
          this.allChanged = this.massOperationStudents.find((x) => x.changed === false) === undefined
          this.loading = false;
        }
      );
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.changed) {
      return { changed: true };
    } else {
      return {  };
    }
  };

  public removeHandler(dataItem : any): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите отменить: "+ this.massOperationJournal?.typeName + "?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.massoperationjournalService.deleteMassOperationJournal(this.operationId)
            .subscribe({
              next:() => {
                this.router.navigateByUrl(`/contingent/contingentjournal`).then();
                this.notificationService.showSuccess("Отменено");
              },
              error:(error) => {
                this.notificationService.showError(error.error);
              }});
        }
      }
    });
  }

  public getOrderFile() {
    const fileName = this.massOperationJournal?.order?.fileName;
    const orderId = this.massOperationJournal?.order?.externalId;
    if(fileName) {
      this.ordersService.getOrderFile(orderId!).subscribe(
        (response: BlobPart) => {
          const blob = new Blob([response], { type: `Content-Type', 'application/octet-stream` });
          saveAs(blob, fileName);
        }
      );
    }
  }

  public saveOrderFile() {
    let formData = new FormData();
    if (this.files) {
      formData.append(`FileName`, this.files[0].name);
      formData.append(`File`, this.files[0], this.files[0].name);

      this.massoperationjournalService.saveOrderFile(formData, this.operationId).subscribe({
        next:() => {
          this.notificationService.showSuccess("Файл приказа был успешно добавлен!");
          this.getMassOperationInfo();
        },
        error:() => {
          this.notificationService.showError("Не удалось добавить файл приказа.");
        }}
      );
    }
  }

  public deleteOrderFile() {
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите удалить файлы приказов?",
      actions: [ { text: "Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да"){
          this.massoperationjournalService.deleteOrderFile(this.operationId).subscribe({
            next:() => {
              this.notificationService.showSuccess("Файл приказа был успешно удален!");
              this.getMassOperationInfo();
            },
            error:() => {
              this.notificationService.showError("Не удалось удалить файл приказа.");
            }}
          );
        }
      }
    });
  }
}

import {Injectable} from "@angular/core";
import {ScienceDegreesService} from "../../../../../services/science/publications/scienceDegrees.service";
import {NotificationsService} from 'src/app/services/notifications/notifications.service';
import {FormGroup} from "@angular/forms";
import {DialogCloseResult, DialogResult} from "@progress/kendo-angular-dialog";
import {Observable} from "rxjs";
import {getData$} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PublicationsScienceDegreesMethods {
  constructor(
    private scienceDegreesService: ScienceDegreesService,
    private notificationService: NotificationsService
  ) {}

  async saveScienceDegree(isNew: boolean, formGroup: FormGroup) {
    if (isNew) {
      this.scienceDegreesService.addScienceDegree(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: (error) => this.notificationService.showError(error)
      })
    }
    else {
      this.scienceDegreesService.updateScienceDegree(formGroup.value).subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: (error) => this.notificationService.showError(error)
      })
    }
  }

  removeScienceDegree(dialogResult: Observable<DialogResult>, dataItem: any) {
    dialogResult.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == "Да") {
        this.scienceDegreesService.removeScienceDegree(dataItem.id)
          .subscribe({
            next: () => {
              getData$.next(true);
              this.notificationService.showSuccess('Успешно');
            },
            error: () => this.notificationService.showError('Произошла ошибка')
          });
      }
    });
  }
}

import {Component, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CellClickEvent, GridComponent} from "@progress/kendo-angular-grid";
import {NotificationsService} from "../../../services/notifications/notifications.service";
import {DialogService} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../services/useraccess/contingent-user-access.service";
import {Guid} from "guid-typescript";
import {PaymentState} from "../../../models/contingent/paymentstate.model";
import {PaymentstateService} from "../../../services/contingent/paymentstate.service";

@Component({
  selector: 'app-paymentstate',
  templateUrl: './paymentstate.component.html',
  styleUrls: ['./paymentstate.component.scss']
})
export class PaymentstateComponent implements OnInit {

  public paymentState: PaymentState[] = [];
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public formGroup: UntypedFormGroup | undefined;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  private isLine = false;

  public opened = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined;
  }
  constructor(
    private paymentStateService: PaymentstateService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: ContingentUserAccessService,
  ) { }

  ngOnInit(): void {
    this.getAccessLevel()
    this.getAllPaymentState();
    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid")) {
        this.saveCurrent();
      }
    });
  }

  public onStateChange(): void {
    this.getAllPaymentState();
  }

  //Start Editing
  public editHandler({
                       sender,
                       columnIndex,
                       rowIndex,
                       dataItem,
                     }: CellClickEvent): void {
    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveCurrent();
      return;
    }
    if(!this.editable) return
    this.isLine = true;
    this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  //Cancel
  public cancelHandler(): void {
    this.closeEditor(this.grid, this.editedRowIndex);
  }

  //Start saving
  public saveCurrent(): void {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }

    this.isLine = false;
    this.saveRow();
  }

  //Finish editing
  private closeEditor(grid: GridComponent, rowIndex: any = this.editedRowIndex
  ): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  //Save data to dictionary
  private saveRow(): void {
    if (this.isInEditingMode && this.formGroup) {
      this.paymentStateService.updatePaymentState(this.formGroup.value)
        .subscribe({
          next:() => {
            this.getAllPaymentState();
            this.notificationService.showSuccess("Сохранено");
          },
          error:() => {
            this.notificationService.showError('Запись уже существует');
          }}
        );
    }
    this.closeEditor(this.grid);
  }

  //Getting all entries from dictionary
  public getAllPaymentState() {
    this.paymentStateService.getAllPaymentState()
      .subscribe(
        response => {
          this.paymentState = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.dictAccessLevel != 1
        },error:() => {}});
  }

  public close(): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }
}

const formGroup = (dataItem: { externalId?: Guid; name?: string; order?: number; paymentStateNumber?: number }) =>
  new UntypedFormGroup({
    externalId: new UntypedFormControl(dataItem.externalId),
    name: new UntypedFormControl(dataItem.name, Validators.required),
    order: new UntypedFormControl(dataItem.order),
    paymentStateNumber: new UntypedFormControl(dataItem.paymentStateNumber, Validators.required),
  });

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;

}

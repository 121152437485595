import { Component, OnInit } from '@angular/core';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { Guid } from 'guid-typescript';
import { Holiday } from 'src/app/models/dicts/holiday.model';
import { HolidaysAndWeekendsService } from 'src/app/services/dicts/holidays-and-weekends.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { EducationUserAccessService } from 'src/app/services/useraccess/education-user-access.service';

@Component({
  selector: 'app-holidays-and-weekends',
  templateUrl: './holidays-and-weekends.component.html',
  styleUrls: ['./holidays-and-weekends.component.scss']
})
export class HolidaysAndWeekendsComponent implements OnInit {
  public years: ArrayItem[] = [];
  public weekTypes: ArrayItem[] = [];
  public holidays: Holiday[] = [];
  public firstSemesterHolidays: string[] = [];
  public secondSemesterHolidays: string[] = [];
  public currentDate: Date = new Date;
  public focusedDate: Date = new Date;
  public newHolidays: Holiday[] = [];
  public dataFromService: DataFromRemoteService = new DataFromRemoteService;
  public values: Date[] = [];
  public resultValues: Date[] = [];
  public nullGuid: any = Guid.createEmpty();
  public filter = {
    year: new Date().getFullYear(),
    weekType: 0,
    minDate: new Date(this.currentDate.getFullYear(), 8, 1),
    maxDate: new Date(this.currentDate.getFullYear() + 1, 7, 31)
  };
  
  public customTitles: Map<string, string> = new Map();
  public months: string[] = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
  public weekTypeEdit: boolean = true;
  public calendarTypeEdit: boolean = true;

  constructor(private service: HolidaysAndWeekendsService, 
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private userAccessService: EducationUserAccessService,) { }

  ngOnInit(): void {
    this.getAccessLevel();
    this.getPersonalHolidays();

    for (var i = 2018; i < this.currentDate.getFullYear() + 2; i++) {
      let arraItem: ArrayItem = new ArrayItem();
      arraItem.text = i.toString() + '-' + (i + 1).toString();
      arraItem.value = i;
      this.years.push(arraItem);
    }

    let arraItem: ArrayItem = new ArrayItem();
    arraItem.text = "Пятидневная";
    arraItem.value = 0;
    this.weekTypes.push(arraItem);
    let arraItem2: ArrayItem = new ArrayItem();
    arraItem2.text = "Шестидневная";
    arraItem2.value = 1;
    this.weekTypes.push(arraItem2);
    
    this.focusedDate = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());

    this.customTitles.set("сентября", "Сентябрь");
    this.customTitles.set("октября", "Октябрь");
    this.customTitles.set("ноября", "Ноябрь");
    this.customTitles.set("декабря", "Декабрь");
    this.customTitles.set("января", "Январь");
    this.customTitles.set("февраля", "Февраль");
    this.customTitles.set("марта", "Март");
    this.customTitles.set("апреля", "Апрель");
    this.customTitles.set("мая", "Май");
    this.customTitles.set("июня", "Июнь");
    this.customTitles.set("июля", "Июль");
    this.customTitles.set("августа", "Август");

    this.customTitles.set("сент.", "Сентябрь");
    this.customTitles.set("окт.", "Октябрь");
    this.customTitles.set("нояб.", "Ноябрь");
    this.customTitles.set("дек.", "Декабрь");
    this.customTitles.set("февр.", "Февраль");
    this.customTitles.set("мар.", "Март");
    this.customTitles.set("апр.", "Апрель");
    this.customTitles.set("июн.", "Июнь");
    this.customTitles.set("июл.", "Июль");
    this.customTitles.set("авг.", "Август");
  }

  public yearChange($event: any) {
    let currentYear: number = $event.value;
    this.focusedDate = new Date(currentYear, 0, this.currentDate.getDate());
    this.filter.minDate = new Date(currentYear, 8, 1);
    this.filter.maxDate = new Date(currentYear + 1, 7, 31);
    this.filter.year = currentYear;

    this.getPersonalHolidays();
  }

  public weekTypeChange() {
    this.getPersonalHolidays();
  }

  public getAllHolidaysByRemoteService(dataFromService: DataFromRemoteService) {
    this.firstSemesterHolidays = [];
    this.secondSemesterHolidays = [];
    this.service.getAllHolidaysByRemoteService(this.focusedDate.getFullYear(), this.filter.weekType, dataFromService)
      .subscribe(
        response => {
          this.holidays = response;
          this.holidays.forEach(holiday => {
            holiday.currentDate = new Date(holiday.currentDate);
          })
          this.remakeDatasForView();
        },
        error => {
          this.notificationService.showError("Не удалось загрузить празничные и выходные дни")
        }
      );
  }

  public getPersonalHolidays() {
    this.firstSemesterHolidays = [];
    this.secondSemesterHolidays = [];
    this.service.getAllHolidays(this.focusedDate.getFullYear(), this.filter.weekType)
      .subscribe(
        response => {
          this.holidays = response;
          this.holidays.forEach(holiday => {
            holiday.currentDate = new Date(holiday.currentDate);
          })
          this.remakeDatasForView();
        }
      )
  }

  public saveCurrent() {
    this.newHolidays = [];
    if (this.filter.weekType != undefined && this.filter.year != undefined && this.resultValues.length > 0) {
      this.resultValues.forEach(date => {
        let newHoliday = new Holiday(this.filter.year, date, this.filter.weekType, 0, this.nullGuid.value);
        let currentHoliday = this.holidays.find((x) => x.currentDate.toDateString() == date.toDateString());

        if (currentHoliday !== undefined) {
          newHoliday.id = currentHoliday.id;
          newHoliday.externalId = currentHoliday.externalId
        }
        this.newHolidays.push(newHoliday);
      });
      this.resultValues = [];
      this.values = [];
      this.service.ChangeCurrentHolidays(this.newHolidays)
        .subscribe(
          response => {
          }
        )
    }
  }

  public CancelHandler() {
    this.newHolidays = [];
    this.values = [];
    this.resultValues = [];
  }

  public updateData() {
    if ((this.filter.year === new Date().getFullYear() || 
    this.filter.year === new Date().getFullYear() + 1 || 
    this.filter.year === new Date().getFullYear() + 2) && 
    this.filter.weekType === 1) {
      this.notificationService.showError("Информация по текущему году 6-дневной рабочей недели не доступна");
      return;
    }

    let dataFromService: DataFromRemoteService = new DataFromRemoteService();
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: `Ваши данные будут перезаписаны`,
      actions: [ {text:"Нет"},{ text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        if(result.text == "Да") {
          this.service.getDataFromRemoteService(this.focusedDate.getFullYear(), this.filter.weekType)
          .subscribe(
            response => {
              dataFromService.dataByBeginYear = response;
              this.service.getDataFromRemoteService(this.focusedDate.getFullYear() + 1, this.filter.weekType)
              .subscribe(
                response => {
                  dataFromService.dataByEndYear = response;
                  this.firstSemesterHolidays = [];
                  this.secondSemesterHolidays = [];
                  this.newHolidays = [];
                  this.holidays = [];
                  this.service.getAllHolidaysByRemoteService(this.focusedDate.getFullYear(), this.filter.weekType, dataFromService)
                    .subscribe(
                      response => {
                        this.holidays = response;
                        this.holidays.forEach(holiday => {
                          holiday.currentDate = new Date(holiday.currentDate);
                        })
                        this.remakeDatasForView();
                        this.notificationService.showSuccess("Перезаписано");
                      },
                      error => {
                        this.notificationService.showError("Не удалось загрузить празничные и выходные дни")
                      }
                    );
                }
              )
            }
          )
        }
      }
    });
  }

  // public change($event: any) {
  //   let element = this.newHolidays.find((x) => new Date(x.currentDate).toDateString() === new Date($event).toDateString())!;
  //   let holidaysElement = this.holidays.find((x) => new Date(x.currentDate).toDateString() === new Date($event).toDateString())!;

  //   if (element === undefined) {
  //     if (holidaysElement == undefined) {
  //       this.newHolidays.push(new Holiday(this.filter.year, new Date($event), this.filter.weekType, 0));
  //     }
  //     else {
  //       this.newHolidays.push(new Holiday(this.filter.year, new Date($event), this.filter.weekType, holidaysElement.id));
  //     }
  //   }
  //   else {
  //     let index = this.newHolidays.indexOf(element);
  //     this.newHolidays.splice(index, 1);
  //   }
  // }

  private remakeDatasForView() {
    let beginOfSecondSemester = new Date(this.filter.year + 1, 1, 1);
    this.holidays.forEach(holiday => {
      let currentDateString = (holiday.currentDate.getDate().toString() + ' ' +
      this.months[holiday.currentDate.getMonth()] + ' ' +
      holiday.currentDate.getFullYear().toString());

      if (holiday.currentDate < beginOfSecondSemester) {
        this.firstSemesterHolidays.push(currentDateString);
      }
      else {
        this.secondSemesterHolidays.push(currentDateString);
      }
    });
  }

  public isColored(date: Date) {
    let dateNow = new Date();
    let currentDateHoliday = this.holidays.find((x) => date.toDateString() == x.currentDate.toDateString());
    if (currentDateHoliday !== undefined) {
      return "holiday";
    }
    if (date.toDateString() === dateNow.toDateString()) {
      return "today";
    }
    else {
      return "";
    }
  }

  public onChange(values: Date[]): void {
    if (values.length > 1) {
      this.resultValues = [];

      values.forEach(value => {
        let currentDate = this.resultValues.find((x) => x.toDateString() === value.toDateString())!;
        if (currentDate === undefined) {
          this.resultValues.push(value);
        }
      });
    }
    else {
      let currentDate = this.resultValues.find((x) => x.toDateString() === values[0].toDateString());
      if (currentDate === undefined) {
        this.resultValues.push(values[0]);
      }
      else {
        let index = this.resultValues.indexOf(currentDate);
        this.resultValues.splice(index, 1);
      }

      this.resultValues.forEach(value => {
        this.values.push(value)
      });
    }
  }

  public changeWeekTypeEditStatus() {
    this.weekTypeEdit = !this.weekTypeEdit;
  }

  public changeCalendarEditStatus() {
    this.calendarTypeEdit = !this.calendarTypeEdit;
  }

  public changeTitle(title: string): string {
    let titleSplit = title.split(' ');
    let title1 = this.customTitles.get(titleSplit[0]);

    return title1 + ' ' + titleSplit[1];
  }

  public changeMonthTitle(title: string): string {
    let title1 = this.customTitles.get(title);

    if (title1 !== undefined) {
      return title1;
    }
    else {
      return title;
    }
  }
  public editable?: boolean;

  public getAccessLevel() {
    this.userAccessService.getAccessLevel().subscribe((response) => {
      this.editable = response.accessLevel == 1 ? false : true;
    }, error => { });
  }
}

export class ArrayItem {
  public text!: string;
  public value!: number;
}

export class DataFromRemoteService {
  public dataByBeginYear: string = "";
  public dataByEndYear: string = "";
}


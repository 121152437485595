import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DiplomsListModel, saveMassData} from 'src/app/models/gia/diploms.model';

@Injectable({
    providedIn: 'root'
})

export class DiplomaTableService {

    baseUrl = `${environment.apiEndpoint}${environment.apiPaths.gia.diploma}`;


    constructor(private http: HttpClient) { }

    public getDiploms(standartId: string, graduateYear: number, studyFormId: string, facultyId: string, trainingLevelId: string, isMassChange?: boolean) {
        let params = new HttpParams();
        params = params.append('EducationStandardId', standartId);
        params = params.append('GraduateYear', graduateYear);
        params = params.append('StudyFormId', studyFormId);
        params = params.append('FacultyId', facultyId);
        params = params.append('trainingLevelId', trainingLevelId);
        if (isMassChange) params = params.append('isMassChange', isMassChange);
        return this.http.get<DiplomsListModel[]>(this.baseUrl + '/GetAll', {params});
    }


    public getDiplomaIssueDate(standardId: string) {
        return this.http.get(this.baseUrl + '/GetDiplomaIssueDate/' + standardId);
    }

    public saveDiplomsIssueDate(standardId: string, date: Date) {
        const data = {'diplomaIssueDate': date, 'educationalStandardId': standardId};
        return this.http.put(this.baseUrl + '/UpdateDiplomaIssueDate', data);
    }

    public saveMassData(data: saveMassData) {
        return this.http.post(this.baseUrl + '/MassChangeDiploma', data);
    }
}

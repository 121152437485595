import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {DictState} from "../../models/contingent/dictstate.model";
import {catchError} from "rxjs/operators";
import {DocumentModel} from "../../models/contingent/document.model";

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.document}`;

  constructor(private http: HttpClient) { }

  public getStudentDocuments(studentId: string): Observable<DocumentModel[]> {
    return this.http.get<DocumentModel[]>(this.baseUrl + "/" + studentId);
  }

  addDocument(doc: FormData): Observable<DocumentModel> {
    return this.http.post<DocumentModel>(this.baseUrl, doc)
      .pipe(catchError(this.handleError));
  }

  downloadFile(fileName: string, studentId: string) {
    return this.http.get(this.baseUrl + '/DownloadFile/' + fileName +'/' + studentId, { responseType: 'blob' } )
  }


  deleteDocument(id: string, studentId: string): Observable<DocumentModel> {
    return this.http.delete<DocumentModel>(this.baseUrl + '/' + id +'/' + studentId)
      .pipe(catchError(this.handleError));
  }

  updateDocument(doc: FormData, documentId: string): Observable<DocumentModel> {
    return this.http.put<DocumentModel>(this.baseUrl + '/' + documentId, doc)
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

}

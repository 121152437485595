import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { getData$ } from '../../../../../environments/environment';
import { DictLocalSignatoryRoleService } from '../../../../services/gia/dict-local-signatory-role.service';

@Injectable({
  providedIn: 'root',
})
export class GiaDictLocalSignatoryRoleMethods {
  constructor(
    private dictLocalSignatoryRoleService: DictLocalSignatoryRoleService,
    private notificationService: NotificationsService,
  ) {}

  async saveDict(formGroup: FormGroup) {
    this.dictLocalSignatoryRoleService
      .updateLocalSignatoryRole(formGroup.value)
      .subscribe({
        next: () => {
          this.notificationService.showSuccess('Успешно');
          getData$.next(true);
        },
        error: () => this.notificationService.showError('Произошла ошибка'),
      });
  }
}

<h5 class="training-title">Целевое обучение</h5>
<div>
  <span class="title">
    <h4 class='form-title title-gray-color'>
      Сведения о заказчике целевого обучения
    </h4>
    <button kendoButton
            class="edit-button"
            fillMode="outline"
            size="small"
            *ngIf="editable && !editMode.get(EditMode.customer)"
            (click)="editCustomer()">
      Редактировать
      <span class="k-icon k-i-edit"></span>
    </button>
  </span>

  <div class="grid-container">
    <dl *ngIf="!editMode.get(EditMode.customer); else customerEditForm" class="rows">
      <div class="content-row">
        <kendo-label text="Номер договора"></kendo-label>
        <dd class="rows">
          {{ customer?.contractNumber ?? '-'}}
          <dl class="rows">
            <div class="content-row">
              <kendo-label class="additional" text="Дата договора"></kendo-label>
              <dd>
                {{ formatDate(customer?.contractDate) }}
              </dd>
            </div>
          </dl>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Организация"></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.organizationName, customer?.dictOrganizationId) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="ОГРН заказчика ц/о"></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.ogrn, customer?.dictOrganizationId) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="ИНН заказчика ц/о"></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.inn, customer?.dictOrganizationId) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="КПП заказчика ц/о"></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.kpp, customer?.dictOrganizationId) }}
        </dd>
      </div>
    </dl>
    <ng-template #customerEditForm>
      <form class="k-form" [formGroup]="customerForm">
        <dl class="edit-form">
          <div class="content-row">
            <kendo-label text="Номер договора"></kendo-label>
            <dd class="edit-form edit-form_nested">
              <kendo-formfield>
                <kendo-textbox formControlName="contractNumber" [clearButton]="true"></kendo-textbox>
              </kendo-formfield>
              <dl class="edit-form edit-form_nested">
                <div class="content-row">
                  <kendo-label class="additional" text="Дата договора"></kendo-label>
                  <dd>
                    <kendo-formfield>
                      <kendo-datepicker formControlName="contractDate"></kendo-datepicker>
                    </kendo-formfield>
                  </dd>
                </div>
              </dl>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Организация"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-combobox [data]="targetOrganizations"
                                textField="organizationName"
                                valueField="dictOrganizationExternalId"
                                [valuePrimitive]="true"
                                #organization
                                [openOnFocus]="organization"
                                [kendoDropDownFilter]="filterSettings"
                                placeholder="Организация"
                                formControlName="dictOrganizationId">
                </kendo-combobox>
                <span class="required">*</span>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="ОГРН заказчика ц/о"></kendo-label>
            <dd>
              {{ getOrganizationFieldInfo(OrganizationFields.ogrn, customerForm.controls['dictOrganizationId'].value) }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="ИНН заказчика ц/о"></kendo-label>
            <dd>
              {{ getOrganizationFieldInfo(OrganizationFields.inn, customerForm.controls['dictOrganizationId'].value) }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="КПП заказчика ц/о"></kendo-label>
            <dd>
              {{ getOrganizationFieldInfo(OrganizationFields.kpp, customerForm.controls['dictOrganizationId'].value) }}
            </dd>
          </div>
        </dl>
      </form>
      <div class="edit-mode-buttons">
        <button kendoButton
                size="small"
                *ngIf="editMode.get(EditMode.customer)"
                (click)="closeHandler()">
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                *ngIf="editMode.get(EditMode.customer)"
                (click)="saveCustomer()">
          Сохранить
        </button>
      </div>
    </ng-template>
  </div>

  <span class="title">
    <h4 class='form-title title-gray-color'>
      Работодатель по целевому договору
    </h4>
    <button kendoButton
            class="edit-button"
            fillMode="outline"
            size="small"
            *ngIf="editable && !editMode.get(EditMode.employer)"
            (click)="editEmployer()">
      Редактировать
      <span class="k-icon k-i-edit"></span>
    </button>
  </span>

  <div class="grid-container">
    <dl *ngIf="!editMode.get(EditMode.employer); else employerEditForm" class="rows">
      <div class="content-row">
        <kendo-label text="Организация"></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.organizationName, employer?.dictOrganizationId) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Субъект РФ"></kendo-label>
        <dd>
          {{ getStateName(employer?.dictStateId) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="ОГРН работодателя по ц.д."></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.ogrn, employer?.dictOrganizationId) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="ИНН работодателя по ц.д."></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.inn, employer?.dictOrganizationId) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="КПП работодателя по ц.д."></kendo-label>
        <dd>
          {{ getOrganizationFieldInfo(OrganizationFields.kpp, employer?.dictOrganizationId) }}
        </dd>
      </div>
    </dl>

    <ng-template #employerEditForm>
      <form class="k-form" [formGroup]="employerForm">
        <dl class="edit-form">
          <div class="content-row">
            <kendo-label text="Организация"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-combobox [data]="employerOrganizations"
                                textField="organizationName"
                                valueField="dictOrganizationExternalId"
                                [valuePrimitive]="true"
                                [kendoDropDownFilter]="filterSettings"
                                #organization
                                [openOnFocus]="organization"
                                placeholder="Организация"
                                formControlName="dictOrganizationId">
                </kendo-combobox>
                <span class="required">*</span>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Субъект РФ"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-combobox [data]="dictStates"
                                textField="stateName"
                                valueField="dictStateExternalId"
                                [valuePrimitive]="true"
                                [kendoDropDownFilter]="filterSettings"
                                #state
                                [openOnFocus]="state"
                                placeholder="Субъект РФ"
                                formControlName="dictStateId">
                </kendo-combobox>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="ОГРН работодателя по ц.д."></kendo-label>
            <dd>
              {{ getOrganizationFieldInfo(OrganizationFields.ogrn, employerForm.controls['dictOrganizationId'].value) }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="ИНН работодателя по ц.д."></kendo-label>
            <dd>
              {{ getOrganizationFieldInfo(OrganizationFields.inn, employerForm.controls['dictOrganizationId'].value) }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="КПП работодателя по ц.д."></kendo-label>
            <dd>
              {{ getOrganizationFieldInfo(OrganizationFields.kpp, employerForm.controls['dictOrganizationId'].value) }}
            </dd>
          </div>
        </dl>
      </form>
      <div class="edit-mode-buttons">
        <button kendoButton
                size="small"
                *ngIf="editMode.get(EditMode.employer)"
                (click)="closeHandler()">
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                *ngIf="editMode.get(EditMode.employer)"
                (click)="saveEmployer()">
          Сохранить
        </button>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="academicState === AcademicStateEnum.expelled">
  <span class="title">
    <h4 class='form-title title-gray-color'>
      Приказ об отчислении
    </h4>
    <button kendoButton
            class="edit-button"
            fillMode="outline"
            size="small"
            *ngIf="editable && !editMode.get(EditMode.expulsionInfo)"
            (click)="editExpulsionInfo()">
      Редактировать
      <span class="k-icon k-i-edit"></span>
    </button>
  </span>

  <div class="grid-container">
    <dl *ngIf="!editMode.get(EditMode.expulsionInfo); else orderEditForm" class="rows">
      <div class="content-row">
        <kendo-label text="Приказ"></kendo-label>
        <dd>
          № {{ expulsionOrder?.number ?? '-' }} от {{ formatDate(expulsionOrder?.orderDate) }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Категория приказа"></kendo-label>
        <dd>
          {{ expulsionOrder?.dictOrdersCategories?.categoryName ?? '-' }}
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Файл приказа"></kendo-label>
        <dd>
          <a *ngIf="expulsionOrder?.fileFullName; else orderFile" class="link" (click)="getOrderFile()">
            {{ expulsionOrder?.fileFullName }}
          </a>
          <ng-template #orderFile>-</ng-template>
        </dd>
      </div>
    </dl>

    <span *ngIf="!editMode.get(EditMode.expulsionInfo)" class="subtitle">Информирование заказчика</span>

    <dl *ngIf="!editMode.get(EditMode.expulsionInfo)" class="rows">
      <div class="content-row">
        <kendo-label text="Уведомление. Дата"></kendo-label>
        <dd  class="rows">
          {{ formatDate(expulsionInfo?.informingCustomer?.date) }}
          <dl class="rows">
            <div class="content-row">
              <kendo-label class="additional" text="Номер"></kendo-label>
              <dd>
                {{ expulsionInfo?.informingCustomer?.number ?? '-' }}
              </dd>
            </div>
          </dl>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Скан уведомления"></kendo-label>
        <dd>
          <a *ngIf="expulsionInfo?.informingCustomer?.fileFullName; else customerFile" class="link"
             (click)="getInformingFile(expulsionInfo?.informingCustomer?.id, expulsionInfo?.informingCustomer?.fileFullName)">
            {{ expulsionInfo?.informingCustomer?.fileFullName }}
          </a>
          <ng-template #customerFile>-</ng-template>
        </dd>
      </div>
    </dl>

    <span *ngIf="!editMode.get(EditMode.expulsionInfo)" class="subtitle">Информирование ОО</span>

    <dl *ngIf="!editMode.get(EditMode.expulsionInfo)" class="rows">
      <div class="content-row">
        <kendo-label text="Уведомление. Дата"></kendo-label>
        <dd class="rows">
          {{ formatDate(expulsionInfo?.informingEmployer?.date) }}
          <dl class="rows">
            <div class="content-row">
              <kendo-label class="additional" text="Номер"></kendo-label>
              <dd>
                {{ expulsionInfo?.informingEmployer?.number ?? '-' }}
              </dd>
            </div>
          </dl>
        </dd>
      </div>

      <div class="content-row">
        <kendo-label text="Скан уведомления"></kendo-label>
        <dd>
          <a *ngIf="expulsionInfo?.informingEmployer?.fileFullName; else employerFile" class="link"
             (click)="getInformingFile(expulsionInfo?.informingEmployer?.id, expulsionInfo?.informingEmployer?.fileFullName)">
            {{ expulsionInfo?.informingEmployer?.fileFullName }}
          </a>
          <ng-template #employerFile>-</ng-template>
        </dd>
      </div>
    </dl>
    <ng-template #orderEditForm>
      <form class="k-form">
        <dl class="edit-form">
          <div class="content-row">
            <kendo-label text="Приказ"></kendo-label>
            <dd>
               № {{ expulsionOrder?.number ?? '-' }} от {{ formatDate(expulsionOrder?.orderDate) }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Категория приказа"></kendo-label>
            <dd>
              {{ expulsionOrder?.dictOrdersCategories?.categoryName ?? '-' }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Файл приказа"></kendo-label>
            <dd>
              <a *ngIf="expulsionOrder?.fileFullName; else orderFile" class="link" (click)="getOrderFile()">
                {{ expulsionOrder?.fileFullName }}
              </a>
              <ng-template #orderFile>-</ng-template>
            </dd>
          </div>
        </dl>

        <span class="edit-form edit-form__title subtitle">Информирование заказчика</span>

        <dl class="edit-form" [formGroup]="informCustomerForm">
          <div class="content-row">
            <kendo-label text="Уведомление. Дата"></kendo-label>
            <dd class="edit-form edit-form_nested">
              <kendo-formfield>
                <kendo-datepicker formControlName="date"></kendo-datepicker>
              </kendo-formfield>
              <dl class="edit-form edit-form_nested">
                <div class="content-row">
                  <kendo-label class="additional" text="Номер"></kendo-label>
                  <dd>
                    <kendo-formfield>
                      <kendo-textbox formControlName="number" [clearButton]="true"></kendo-textbox>
                    </kendo-formfield>
                  </dd>
                </div>
              </dl>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label class="attachment-label" text="Скан уведомления"></kendo-label>
            <dd>
              <kendo-formfield class="fileselect">
                <kendo-fileselect formControlName='file'
                                  [multiple]="false"
                                  (remove)="removeFile(informCustomerForm, 'fileFullName')"
                                  [showFileList]="true"
                                  [restrictions]="restrictions">
                  <kendo-fileselect-messages
                    invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                  </kendo-fileselect-messages>
                </kendo-fileselect>
              </kendo-formfield>
              <a *ngIf="informCustomerForm?.value.file?.length; else customerFile" class="link"
                 (click)="downloadFile(informCustomerForm?.value.file)">
                Скачать
              </a>
              <ng-template #customerFile>
                <a *ngIf="informCustomerForm?.value.fileFullName" class="link"
                   (click)="getInformingFile(informCustomerForm?.value.id, informCustomerForm?.value.fileFullName)">
                  {{ informCustomerForm?.value.fileFullName }}
                  <span (click)="removeFile(informCustomerForm, 'fileFullName')" class="k-icon k-i-x"></span>
                </a>
              </ng-template>
            </dd>
          </div>
        </dl>

        <span class="edit-form edit-form__title subtitle">Информирование ОО</span>

        <dl class="edit-form" [formGroup]="informEmployerForm">
          <div class="content-row">
            <kendo-label text="Уведомление. Дата"></kendo-label>
            <dd class="edit-form edit-form_nested">
              <kendo-formfield>
                <kendo-datepicker formControlName="date"></kendo-datepicker>
              </kendo-formfield>
              <dl class="edit-form edit-form_nested">
                <div class="content-row">
                  <kendo-label class="additional" text="Номер"></kendo-label>
                  <dd>
                    <kendo-formfield>
                      <kendo-textbox formControlName="number" [clearButton]="true"></kendo-textbox>
                    </kendo-formfield>
                  </dd>
                </div>
              </dl>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label class="attachment-label" text="Скан уведомления"></kendo-label>
            <dd>
              <kendo-formfield class="fileselect">
                <kendo-fileselect formControlName='file'
                                  (remove)="removeFile(informCustomerForm, 'fileFullName')"
                                  [multiple]="false"
                                  [showFileList]="true"
                                  [restrictions]="restrictions">
                  <kendo-fileselect-messages
                    invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                  </kendo-fileselect-messages>
                </kendo-fileselect>
              </kendo-formfield>
              <a *ngIf="informEmployerForm?.value.file?.length; else employerFile" class="link"
                 (click)="downloadFile(informEmployerForm?.value.file)">
                Скачать
              </a>
              <ng-template #employerFile>
                <a *ngIf="informEmployerForm?.value.fileFullName" class="link"
                   (click)="getInformingFile(informEmployerForm?.value.id, informEmployerForm?.value.fileFullName)">
                  {{ informEmployerForm?.value.fileFullName }}
                  <span (click)="removeFile(informEmployerForm, 'fileFullName')" class="k-icon k-i-x"></span>
                </a>
              </ng-template>
            </dd>
          </div>
        </dl>
      </form>
      <div class="edit-mode-buttons">
        <button kendoButton
                size="small"
                *ngIf="editMode.get(EditMode.expulsionInfo)"
                (click)="closeHandler()">
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                *ngIf="editMode.get(EditMode.expulsionInfo)"
                (click)="saveExpulsionInfo()">
          Сохранить
        </button>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="academicState === AcademicStateEnum.graduated">
  <span class="title">
    <h4 class='form-title title-gray-color'>
      Подтверждение трудоустройства
    </h4>
    <button kendoButton
            class="edit-button"
            fillMode="outline"
            size="small"
            *ngIf="editable && !editMode.get(EditMode.labourActivity)"
            (click)="editLabourActivity()">
      Редактировать
      <span class="k-icon k-i-edit"></span>
    </button>
  </span>

  <div class="grid-container">
    <dl *ngIf="!editMode.get(EditMode.labourActivity); else labourActivityEditForm" class="rows">
      <div class="content-row">
        <kendo-label text="Трудоустроен к заказчику ц.о."></kendo-label>
        <dd>
          {{ getBoolOption(substantiation?.isHired) }}
        </dd>
      </div>

      <div class="content-row" *ngIf="substantiation?.isHired === false">
        <kendo-label text="Причина"></kendo-label>
        <dd>
          {{ getReasonName(substantiation?.dictNonEmploymentReasonId) }}
        </dd>
      </div>

      <ng-container *ngIf="substantiation?.isHired">
        <div class="content-row">
          <kendo-label text="Организация"></kendo-label>
          <dd>
            {{ getOrganizationFieldInfo(OrganizationFields.organizationName, substantiation?.dictOrganizationId) }}
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Дата трудоустройства"></kendo-label>
          <dd>
            {{ formatDate(substantiation?.hiredDate) }}
          </dd>
        </div>

        <div class="content-row">
          <kendo-label text="Подтверждающий документ"></kendo-label>
          <dd>
            <a *ngIf="substantiation?.fileFullName; else substantiationFile" class="link"
               (click)="getSubstantiationFile(substantiation?.id, substantiation?.fileFullName)">
              {{ substantiation?.fileFullName }}
            </a>
            <ng-template #substantiationFile>-</ng-template>
          </dd>
        </div>
      </ng-container>
    </dl>

    <ng-container *ngIf="!editMode.get(EditMode.labourActivity) && substantiation?.isHired">
      <span class="subtitle">Периоды трудовой деятельности</span>

      <ng-container *ngFor="let period of substantiation?.labourActivity">
        <dl class="rows">
          <div class="content-row">
            <kendo-label text="Статус трудовой деятельности"></kendo-label>
            <dd>
              {{ getEmploymentStatusName(period.employmentStatus) }}
            </dd>
          </div>

          <div *ngIf="period.employmentStatus === EmploymentStatuses.Continuation ||
                      period.employmentStatus === EmploymentStatuses.Freezing"
               class="content-row">
            <kendo-label text="Период"></kendo-label>
            <dd class="rows">
              <dl class="rows">
                <div class="content-row">
                  <kendo-label class="additional" text="с"></kendo-label>
                  <dd>
                    {{ formatDate(period.beginDate) }}
                  </dd>
                </div>
              </dl>

              <dl class="rows">
                <div class="content-row">
                  <kendo-label class="additional" text="по"></kendo-label>
                  <dd>
                    {{ formatDate(period.endDate) }}
                  </dd>
                </div>
              </dl>
            </dd>
          </div>

          <div *ngIf="period.employmentStatus === EmploymentStatuses.Continuation" class="content-row">
            <kendo-label text="Подтверждающий документ"></kendo-label>
            <dd>
              <a *ngIf="period?.activitySubstantiation?.fileFullName; else activityFile" class="link"
                 (click)="getActivityPeriodFile(period?.activitySubstantiation)">
                {{ period?.activitySubstantiation?.fileFullName }}
              </a>
              <ng-template #activityFile>-</ng-template>
            </dd>
          </div>

          <ng-container *ngIf="period.employmentStatus === EmploymentStatuses.Freezing">
            <div class="content-row">
              <kendo-label text="Дата возобновления"></kendo-label>
              <dd>
                {{ formatDate(period.recoveryDate) }}
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Причина приостановления"></kendo-label>
              <dd>
                {{ period.reason ?? '-' }}
              </dd>
            </div>
          </ng-container>

          <ng-container *ngIf="period.employmentStatus === EmploymentStatuses.Dismissal">
            <div class="content-row">
              <kendo-label text="Причина увольнения"></kendo-label>
              <dd>
                {{ period.reason ?? '-' }}
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Дата увольнения"></kendo-label>
              <dd>
                {{ formatDate(period.dismissalDate) }}
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Уведомление об увольнении"></kendo-label>
              <dd>
                <a *ngIf="period?.dismissalSubstantiation?.fileFullName; else dismissalFile" class="link"
                   (click)="getActivityPeriodFile(period?.dismissalSubstantiation)">
                  {{ period?.dismissalSubstantiation?.fileFullName }}
                </a>
                <ng-template #dismissalFile>-</ng-template>
              </dd>
            </div>
          </ng-container>
        </dl>
      </ng-container>

      <span class="subtitle">
        Общий стаж трудовой деятельности {{ getExperience(substantiation?.labourActivity) }}
      </span>
    </ng-container>

    <ng-template #labourActivityEditForm>
      <form class="k-form" [formGroup]="substantiationForm">
        <dl class="edit-form">
          <div class="content-row">
            <kendo-label text="Трудоустроен к заказчику ц.о."></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-dropdownlist [data]="boolOptions"
                                    textField="text"
                                    valueField="value"
                                    [valuePrimitive]="true"
                                    (valueChange)="isHiredChange($event)"
                                    formControlName="isHired">
                </kendo-dropdownlist>
              </kendo-formfield>
            </dd>
          </div>

          <div class="content-row" *ngIf="substantiationForm.value?.isHired === false">
            <kendo-label text="Причина"></kendo-label>
            <dd>
              <kendo-formfield>
                <kendo-dropdownlist [data]="dictReasons"
                                    textField="name"
                                    valueField="externalId"
                                    [valuePrimitive]="true"
                                    formControlName="dictNonEmploymentReasonId">
                </kendo-dropdownlist>
              </kendo-formfield>
            </dd>
          </div>

          <ng-container *ngIf="substantiationForm.value?.isHired">
            <div class="content-row">
              <kendo-label text="Организация"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-combobox [data]="employerOrganizations"
                                  textField="organizationName"
                                  valueField="dictOrganizationExternalId"
                                  [valuePrimitive]="true"
                                  [kendoDropDownFilter]="filterSettings"
                                  placeholder="Организация"
                                  formControlName="dictOrganizationId">
                  </kendo-combobox>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Дата трудоустройства"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-datepicker formControlName="hiredDate"></kendo-datepicker>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label class="attachment-label" text="Подтверждающий документ"></kendo-label>
              <dd>
                <kendo-formfield class="fileselect">
                  <kendo-fileselect formControlName='substantiation'
                                    (remove)="removeFile(substantiationForm, 'fileFullName')"
                                    [multiple]="false"
                                    [showFileList]="true"
                                    [restrictions]="restrictions">
                    <kendo-fileselect-messages
                      invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                    </kendo-fileselect-messages>
                  </kendo-fileselect>
                </kendo-formfield>
                <a *ngIf="substantiationForm?.value.substantiation?.length; else substantiationFile" class="link"
                   (click)="downloadFile(substantiationForm?.value.substantiation)">
                  Скачать
                </a>
                <ng-template #substantiationFile>
                  <a *ngIf="substantiationForm?.value.fileFullName" class="link"
                     (click)="getSubstantiationFile(substantiationForm?.value.id, substantiationForm?.value.fileFullName)">
                    {{ substantiationForm?.value.fileFullName }}
                    <span (click)="removeFile(substantiationForm, 'fileFullName')" class="k-icon k-i-x"></span>
                  </a>
                </ng-template>
              </dd>
            </div>
          </ng-container>
        </dl>

        <ng-container *ngIf="substantiationForm.value?.isHired">
          <span class="edit-form edit-form__title subtitle">Периоды трудовой деятельности</span>

          <ng-container *ngFor="let period of labourActivities.controls; index as i" formArrayName="labourActivity">
            <dl class="edit-form edit-form__block" [formGroupName]="i">
              <div class="content-row">
                <kendo-label text="Статус трудовой деятельности"></kendo-label>
                <dd>
                  <kendo-formfield>
                    <kendo-dropdownlist [data]="dictEmploymentStatuses"
                                        textField="name"
                                        valueField="employmentStatusNumber"
                                        [valuePrimitive]="true"
                                        (valueChange)="fieldChange($event, period, 'employmentStatus')"
                                        formControlName="employmentStatus">
                    </kendo-dropdownlist>
                    <span class="required">*</span>
                    <button class='remove-button'
                            kendoButton
                            icon="x"
                            (click)="removeStatus(i)">
                      Удалить
                    </button>
                  </kendo-formfield>
                </dd>
              </div>

              <div *ngIf="period.value.employmentStatus === EmploymentStatuses.Continuation ||
                          period.value.employmentStatus === EmploymentStatuses.Freezing"
                   class="content-row">
                <kendo-label text="Период"></kendo-label>
                <dd class="edit-form edit-form_nested">
                  <dl class="edit-form edit-form_nested">
                    <div class="content-row">
                      <kendo-label class="additional" text="с"></kendo-label>
                      <dd>
                        <kendo-formfield>
                          <kendo-datepicker formControlName="beginDate"></kendo-datepicker>
                        </kendo-formfield>
                      </dd>
                    </div>
                  </dl>

                  <dl class="edit-form edit-form_nested">
                    <div class="content-row">
                      <kendo-label class="additional" text="по"></kendo-label>
                      <dd>
                        <kendo-formfield>
                          <kendo-datepicker formControlName="endDate"></kendo-datepicker>
                        </kendo-formfield>
                      </dd>
                    </div>
                  </dl>
                </dd>
              </div>

              <ng-container *ngIf="period.value.employmentStatus === EmploymentStatuses.Continuation">
                <div class="content-row">
                  <kendo-label class="attachment-label" text="Подтверждающий документ"></kendo-label>
                  <dd>
                    <kendo-formfield class="fileselect">
                      <kendo-fileselect formControlName='activitySubstantiation'
                                        (remove)="removeFile(period, 'activityFileFullName')"
                                        [multiple]="false"
                                        [showFileList]="true"
                                        [restrictions]="restrictions">
                        <kendo-fileselect-messages
                          invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                        </kendo-fileselect-messages>
                      </kendo-fileselect>
                    </kendo-formfield>
                    <a *ngIf="period?.value.activitySubstantiation?.length; else activityFile" class="link"
                       (click)="downloadFile(period?.value.activitySubstantiation)">
                      Скачать
                    </a>
                    <ng-template #activityFile>
                      <a *ngIf="period?.value.activityFileFullName" class="link"
                         (click)="getActivityPeriodFile(period?.value.activitySubstantiation)">
                        {{ period?.value.activityFileFullName }}
                        <span (click)="removeFile(period, 'activityFileFullName')" class="k-icon k-i-x"></span>
                      </a>
                    </ng-template>
                  </dd>
                </div>
              </ng-container>

              <ng-container *ngIf="period.value.employmentStatus === EmploymentStatuses.Freezing">
                <div class="content-row">
                  <kendo-label text="Дата возобновления"></kendo-label>
                  <dd>
                    <kendo-formfield>
                      <kendo-datepicker formControlName="recoveryDate"></kendo-datepicker>
                    </kendo-formfield>
                  </dd>
                </div>

                <div class="content-row">
                  <kendo-label text="Причина приостановления"></kendo-label>
                  <dd>
                    <kendo-formfield>
                      <kendo-textbox formControlName="reason" [clearButton]="true"></kendo-textbox>
                    </kendo-formfield>
                  </dd>
                </div>
              </ng-container>

              <ng-container *ngIf="period.value.employmentStatus === EmploymentStatuses.Dismissal">
                <div class="content-row">
                  <kendo-label text="Причина увольнения"></kendo-label>
                  <dd>
                    <kendo-formfield>
                      <kendo-textbox formControlName="reason" [clearButton]="true"></kendo-textbox>
                    </kendo-formfield>
                  </dd>
                </div>

                <div class="content-row">
                  <kendo-label text="Дата увольнения"></kendo-label>
                  <dd>
                    <kendo-formfield>
                      <kendo-datepicker formControlName="dismissalDate"></kendo-datepicker>
                    </kendo-formfield>
                  </dd>
                </div>

                <div class="content-row">
                  <kendo-label class="attachment-label" text="Уведомление об увольнении"></kendo-label>
                  <dd>
                    <kendo-formfield class="fileselect">
                      <kendo-fileselect formControlName='dismissalSubstantiation'
                                        (remove)="removeFile(period, 'dismissalFileFullName')"
                                        [multiple]="false"
                                        [showFileList]="true"
                                        [restrictions]="restrictions">
                        <kendo-fileselect-messages
                          invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                        </kendo-fileselect-messages>
                      </kendo-fileselect>
                    </kendo-formfield>
                    <a *ngIf="period?.value.dismissalSubstantiation?.length; else dismissalFile" class="link"
                       (click)="downloadFile(period?.value.dismissalSubstantiation)">
                      Скачать
                    </a>
                    <ng-template #dismissalFile>
                      <a *ngIf="period?.value.dismissalFileFullName" class="link"
                         (click)="getActivityPeriodFile(period?.value.dismissalSubstantiation)">
                        {{ period?.value.dismissalFileFullName }}
                        <span (click)="removeFile(period, 'dismissalFileFullName')" class="k-icon k-i-x"></span>
                      </a>
                    </ng-template>
                  </dd>
                </div>
              </ng-container>
            </dl>
          </ng-container>

          <div class="edit-form edit-form__block">
            <button kendoButton
                    themeColor="success"
                    iconClass="k-icon k-i-plus"
                    (click)="addStatus()">
              Добавить
            </button>
          </div>

          <span class="edit-form edit-form__title subtitle">
            Общий стаж трудовой деятельности {{ getExperience(labourActivities.value) }}
          </span>
        </ng-container>
      </form>
      <div class="edit-mode-buttons">
        <button kendoButton
                size="small"
                *ngIf="editMode.get(EditMode.labourActivity)"
                (click)="closeHandler()">
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                *ngIf="editMode.get(EditMode.labourActivity)"
                (click)="saveLabourActivity()">
          Сохранить
        </button>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="academicState === AcademicStateEnum.expelled ||
            academicState === AcademicStateEnum.graduated && (
              substantiation?.isHired === false || actualEmploymentStatus === EmploymentStatuses.Dismissal
            )">
  <span class="title">
    <h4 class='form-title title-gray-color'>
      Претензионная работа
    </h4>
    <button kendoButton
            class="edit-button"
            fillMode="outline"
            size="small"
            *ngIf="editable && !editMode.get(EditMode.complaint)"
            (click)="editComplaint()">
      Редактировать
      <span class="k-icon k-i-edit"></span>
    </button>
  </span>

  <div class="grid-container">
    <ng-container *ngIf="!editMode.get(EditMode.complaint); else complaintEditForm">
      <dl class="rows">
        <ng-container *ngIf="academicState === AcademicStateEnum.expelled">
          <div class="content-row">
            <kendo-label text="Ведется претензионная работа"></kendo-label>
            <dd>
              {{ getBoolOption(complaint?.isComplaintManagementWork) }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Причина"></kendo-label>
            <dd>
              {{ complaint?.reason ?? '-' }}
            </dd>
          </div>
        </ng-container>

        <ng-container *ngIf="academicState === AcademicStateEnum.graduated || complaint?.isComplaintManagementWork">
          <div class="content-row">
            <kendo-label text="Требование штрафа. Дата"></kendo-label>
            <dd class="rows">
              {{ formatDate(complaint?.beginDate) }}
              <dl class="rows">
                <div class="content-row">
                  <kendo-label class="additional" text="Номер"></kendo-label>
                  <dd>
                    {{ complaint?.complaintNumber ?? '-' }}
                  </dd>
                </div>
              </dl>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Скан требования штрафа"></kendo-label>
            <dd>
              <a *ngIf="complaint?.fineCopy?.fileFullName; else fineCopy" class="link"
                 (click)="getComplaintFile(complaint?.fineCopy)">
                {{ complaint?.fineCopy?.fileFullName }}
              </a>
              <ng-template #fineCopy>-</ng-template>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Сумма штрафа"></kendo-label>
            <dd class="rows">
              {{ complaint?.penaltyAmount || complaint?.penaltyAmount === 0 ? complaint?.penaltyAmount?.toFixed(2) : '-' }}
              <dl class="rows">
                <div class="content-row">
                  <kendo-label class="additional" text="Срок выплаты"></kendo-label>
                  <dd [class]="{red: complaint?.dictPaymentState === PaymentStatesEnum.Debt}">
                    {{ formatDate(complaint?.endDate) }}
                  </dd>
                </div>
              </dl>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Подтверждение оплаты штрафа"></kendo-label>
            <dd>
              <a *ngIf="complaint?.fineDemand?.fileFullName; else fineDemand" class="link"
                 (click)="getComplaintFile(complaint?.fineDemand)">
                {{ complaint?.fineDemand?.fileFullName }}
              </a>
              <ng-template #fineDemand>-</ng-template>
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Выплаченная сумма"></kendo-label>
            <dd>
              {{ complaint?.paidAmount || complaint?.paidAmount === 0 ? complaint?.paidAmount?.toFixed(2) : '-' }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Задолженность"></kendo-label>
            <dd>
              {{ getDebt(complaint?.penaltyAmount, complaint?.paidAmount)?.toFixed(2) ?? '-' }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Статус оплаты"></kendo-label>
            <dd [class]="{
                red: complaint?.dictPaymentState
                  && complaint?.dictPaymentState !== PaymentStatesEnum.FullPayment
              }">
              {{ getPaymentStatusName(complaint?.dictPaymentState) }}
            </dd>
          </div>

          <div class="content-row">
            <kendo-label text="Статус претензионной работы"></kendo-label>
            <dd>
              {{ getComplaintStatusName(complaint?.dictComplaintManagementStatusId) }}
            </dd>
          </div>
        </ng-container>
      </dl>

      <ng-container *ngIf="academicState === AcademicStateEnum.graduated && !substantiation?.isHired">
        <span class="subtitle">Уведомление о неисполнении обязательств</span>

        <dl class="rows">
          <div class="content-row">
            <kendo-label text="Уведомление. Дата"></kendo-label>
            <dd class="rows">
              {{ formatDate(complaint?.notificationDate) }}
              <dl class="rows">
                <div class="content-row">
                  <kendo-label class="additional" text="Номер"></kendo-label>
                  <dd>
                    {{ complaint?.notificationNumber ?? '-' }}
                  </dd>
                </div>
              </dl>
            </dd>
          </div>
        </dl>
      </ng-container>
    </ng-container>

    <ng-template #complaintEditForm>
      <form class="k-form" [formGroup]="complaintForm">
        <dl class="edit-form">
          <ng-container *ngIf="academicState === AcademicStateEnum.expelled">
            <div class="content-row">
              <kendo-label text="Ведется претензионная работа"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-dropdownlist [data]="boolOptions"
                                      textField="text"
                                      valueField="value"
                                      [valuePrimitive]="true"
                                      (valueChange)="fieldChange($event, complaintForm, 'isComplaintManagementWork')"
                                      formControlName="isComplaintManagementWork">
                  </kendo-dropdownlist>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Причина"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-textbox formControlName="reason" [clearButton]="true"></kendo-textbox>
                </kendo-formfield>
              </dd>
            </div>
          </ng-container>

          <ng-container *ngIf="academicState === AcademicStateEnum.graduated || complaintForm?.value.isComplaintManagementWork">
            <div class="content-row">
              <kendo-label text="Требование штрафа. Дата"></kendo-label>
              <dd class="edit-form edit-form_nested">
                <kendo-formfield>
                  <kendo-datepicker formControlName="beginDate"
                                    (valueChange)="paymentStatusChange(complaintForm?.value.penaltyAmount, complaintForm?.value.paidAmount, $event)">
                  </kendo-datepicker>
                </kendo-formfield>
                <dl class="edit-form edit-form_nested">
                  <div class="content-row">
                    <kendo-label class="additional" text="Номер"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-textbox formControlName="complaintNumber" [clearButton]="true"></kendo-textbox>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label class="attachment-label" text="Скан требования штрафа"></kendo-label>
              <dd>
                <kendo-formfield class="fileselect">
                  <kendo-fileselect formControlName='fineCopy'
                                    (remove)="removeFile(complaintForm, 'fileCopyFullName')"
                                    [multiple]="false"
                                    [showFileList]="true"
                                    [restrictions]="restrictions">
                    <kendo-fileselect-messages
                      invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                    </kendo-fileselect-messages>
                  </kendo-fileselect>
                </kendo-formfield>
                <a *ngIf="complaintForm?.value.fineCopy?.length; else fineCopy" class="link"
                   (click)="downloadFile(complaintForm?.value.fineCopy)">
                  Скачать
                </a>
                <ng-template #fineCopy>
                  <a *ngIf="complaintForm?.value.fileCopyFullName" class="link"
                     (click)="getComplaintFile(complaintForm?.value.fineCopy)">
                    {{ complaintForm?.value.fileCopyFullName }}
                    <span (click)="removeFile(complaintForm, 'fileCopyFullName')" class="k-icon k-i-x"></span>
                  </a>
                </ng-template>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Сумма штрафа"></kendo-label>
              <dd class="edit-form edit-form_nested">
                <kendo-formfield>
                  <kendo-numerictextbox formControlName="penaltyAmount"
                                        (valueChange)="paymentStatusChange($event, complaintForm?.value.paidAmount, complaintForm?.value.beginDate)">
                  </kendo-numerictextbox>
                </kendo-formfield>
                <dl class="edit-form edit-form_nested">
                  <div class="content-row">
                    <kendo-label class="additional" text="Срок выплаты"></kendo-label>
                    <dd [class]="{red: complaintForm?.value.dictPaymentState === PaymentStatesEnum.Debt}">
                      {{ formatDate(complaintForm?.value.endDate) }}
                    </dd>
                  </div>
                </dl>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label class="attachment-label" text="Подтверждение оплаты штрафа"></kendo-label>
              <dd>
                <kendo-formfield class="fileselect">
                  <kendo-fileselect formControlName='fineDemand'
                                    (remove)="removeFile(complaintForm, 'fileDemandFullName')"
                                    [multiple]="false"
                                    [showFileList]="true"
                                    [restrictions]="restrictions">
                    <kendo-fileselect-messages
                      invalidFileExtension="Выбранный файл должен быть в формате {{ restrictions.allowedExtensions?.join(', ') }}">
                    </kendo-fileselect-messages>
                  </kendo-fileselect>
                </kendo-formfield>
                <a *ngIf="complaintForm?.value.fineDemand?.length; else fineDemand" class="link"
                   (click)="downloadFile(complaintForm?.value.fineDemand)">
                  Скачать
                </a>
                <ng-template #fineDemand>
                  <a *ngIf="complaintForm?.value.fileDemandFullName" class="link"
                     (click)="getComplaintFile(complaintForm?.value.fineDemand)">
                    {{ complaintForm?.value.fileDemandFullName }}
                    <span (click)="removeFile(complaintForm, 'fileDemandFullName')" class="k-icon k-i-x"></span>
                  </a>
                </ng-template>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Выплаченная сумма"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-numerictextbox formControlName="paidAmount"
                                        (valueChange)="paymentStatusChange(complaintForm?.value.penaltyAmount, $event, complaintForm?.value.beginDate)">
                  </kendo-numerictextbox>
                </kendo-formfield>
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Задолженность"></kendo-label>
              <dd>
                {{ getDebt(complaintForm?.value.penaltyAmount, complaintForm?.value.paidAmount)?.toFixed(2) ?? '-' }}
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Статус оплаты"></kendo-label>
              <dd [class]="{
                  red: complaintForm?.value.dictPaymentState !== PaymentStatesEnum.FullPayment
                }">
                {{ getPaymentStatusName(complaintForm?.value.dictPaymentState) }}
              </dd>
            </div>

            <div class="content-row">
              <kendo-label text="Статус претензионной работы"></kendo-label>
              <dd>
                <kendo-formfield>
                  <kendo-dropdownlist [data]="dictComplaintStatuses"
                                      textField="name"
                                      valueField="externalId"
                                      [valuePrimitive]="true"
                                      formControlName="dictComplaintManagementStatusId">
                  </kendo-dropdownlist>
                </kendo-formfield>
              </dd>
            </div>
          </ng-container>
        </dl>

        <ng-container *ngIf="academicState === AcademicStateEnum.graduated && !substantiation?.isHired">
          <span class="edit-form edit-form__title subtitle">Уведомление о неисполнении обязательств</span>

          <dl class="edit-form">
            <div class="content-row">
              <kendo-label text="Уведомление. Дата"></kendo-label>
              <dd class="edit-form edit-form_nested">
                <kendo-formfield>
                  <kendo-datepicker formControlName="notificationDate"></kendo-datepicker>
                </kendo-formfield>
                <dl class="edit-form edit-form_nested">
                  <div class="content-row">
                    <kendo-label class="additional" text="Номер"></kendo-label>
                    <dd>
                      <kendo-formfield>
                        <kendo-textbox formControlName="notificationNumber" [clearButton]="true"></kendo-textbox>
                      </kendo-formfield>
                    </dd>
                  </div>
                </dl>
              </dd>
            </div>
          </dl>
        </ng-container>
      </form>

      <div class="edit-mode-buttons">
        <button kendoButton
                size="small"
                *ngIf="editMode.get(EditMode.complaint)"
                (click)="closeHandler()">
          Отмена
        </button>
        <button kendoButton
                class="save-button"
                size="small"
                themeColor="primary"
                *ngIf="editMode.get(EditMode.complaint)"
                (click)="saveComplaint()">
          Сохранить
        </button>
      </div>
    </ng-template>
  </div>
</div>
<div kendoDialogContainer></div>

import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddEvent, CellClickEvent, GridComponent, GridDataResult, PagerSettings, RemoveEvent } from '@progress/kendo-angular-grid';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { TokenStorageService } from '../../../services/token.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { checkRole } from '../../../helpers/token/role-check';
import { Role } from '../../../models/useraccess/role';
import { arrayRewrite, DisplayValuesInEditMode, valueChangeBySourceName, valueChange } from '../../../helpers/multiselect-helper';
import { SignatoryService } from '../../../services/gia/signatory.service';
import { DictsService } from '../../../services/gia/dicts.service';
import { GiaUserAccessService } from '../../../services/useraccess/gia-user-access.service';
import { DictLocalSignatoryRoleService } from '../../../services/gia/dict-local-signatory-role.service';
import { DepartmentService } from '../../../services/gia/department.service';
import { Department } from '../../../models/gia/dicts/department.model';
import { DictDto } from '../../../models/gia/dicts/dicts.model';
import { GlobalSignatoryDto } from '../../../models/gia/signatory/globalsignatory.model';
import {
  LocalSignatoryDict,
  LocalSignatoryDictDto,
  LocalSignatoryDto,
  LocalSignatoryRole,
  SignatoryRole
} from '../../../models/gia/signatory/localsignatory.model';
import { GridDisplayList } from '../../../helpers/grid-helper';
import { Guid } from 'guid-typescript';
import { dictPagerSettings } from '../../../models/dicts/pagerSettings.model';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { GiaLocalSignatoryMethods } from './gia-local-signatory.methods';
import { openRemoveDialog } from '../../../helpers/dialogHelper';
import { getData$ } from '../../../../environments/environment';
import { isChildOf } from '../../../helpers/elementRef-helper';
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-gia-local-signatory',
  templateUrl: './gia-local-signatory.component.html',
  styleUrls: ['./gia-local-signatory.component.scss'],
})
export class GiaLocalSignatoryComponent implements OnInit {
  @ViewChild(GridComponent) private grid!: GridComponent;

  public localSignatories: LocalSignatoryDto[] = [];
  public departments: Department[] = [];
  public trainingLevels: DictDto[] = [];
  public filials: DictDto[] = [];
  public sections: DictDto[] = [];
  public localSignatoryRoles: LocalSignatoryRole[] = [];
  public dataSources = ['trainingLevels', 'departments', 'filials', 'sections'];
  public dictSignatoryRoles: SignatoryRole[] = [];
  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: "contains",
  };
  public gridView!: GridDataResult;
  public emptyGuid = Guid.createEmpty().toString();

  public trainingLevelsEdit: [] = [];
  public filialsEdit: [] = [];
  public sectionsEdit: [] = [];
  public departmentsEdit: [] = [];

  public formGroup: FormGroup | undefined;
  public editable: boolean = false;
  public isAdmin: boolean = false;

  private editedRowIndex: number | undefined;
  private isNew = false;
  private isLine = false;
  public loading = false;

  public pagerSettings: PagerSettings = dictPagerSettings;
  public pageSizes = [20, 50, 100];

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private localSignatoryService: SignatoryService,
    private departmentService: DepartmentService,
    private dictService: DictsService,
    private userAccessService: GiaUserAccessService,
    private renderer: Renderer2,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private tokenService: TokenStorageService,
    private jwtService: JwtHelperService,
    private dictLocalSignatoryRoleService: DictLocalSignatoryRoleService,
    private methods: GiaLocalSignatoryMethods,
  ) {
    this.pagerSettings.pageSizes = this.pageSizes;
    this.isAdmin = checkRole(tokenService, jwtService, Role.Admin);
  }

  public ngOnInit(): void {
    this.getAccessLevel();
    this.getDepartments();
    this.getTrainingLevels();
    this.getSections();
    this.getSignatoryRoles();
    this.getLocalSignatoryRoles();
    this.getFilial();

    getData$.subscribe(() => this.getLocalSignatories());

    this.renderer.listen('document', 'click', ({ target }) => {
      if (!isChildOf(target, 'k-grid')) {
        this.saveCurrent();
      }
    });
  }

  private getAccessLevel() {
    this.userAccessService.getCurrentUserAccess().subscribe((response) => (this.editable = response.localSignatory));
  }

  private getDepartments() {
    this.departments = [];
    this.departmentService.getDepartments().subscribe((response) => {
      this.departments = response;
      if (this.departments) {
        let item = new Department();
        item.id = '';
        item.shortName = 'Все';
        this.departments.unshift(item);
      }
    });
  }

  private getTrainingLevels() {
    this.trainingLevels = [];
    this.dictService.getTrainingLevels().subscribe((response) => {
      this.trainingLevels = response;
      if (this.trainingLevels) {
        let item = new DictDto();
        item.id = '';
        item.name = 'Все';
        this.trainingLevels.unshift(item);
      }
    });
  }

  private getSections() {
    this.sections = [];
    this.dictService.getSections().subscribe((response) => {
      this.sections = response;
      if (this.sections) {
        let item = new DictDto();
        item.id = '';
        item.name = 'Все';
        this.sections.unshift(item);
      }
    });
  }

  public getFilial() {
    this.filials = [];
    this.dictService.getFilials().subscribe((response) => {
      this.filials = response;
      if (this.filials) {
        let item = new DictDto();
        item.id = '';
        item.shortName = 'Все';
        this.filials.unshift(item);
      }
    });
  }

  public getLocalSignatoryRoles() {
    this.dictLocalSignatoryRoleService.getLocalSignatoryRoles().subscribe((response) => {
      this.localSignatoryRoles = response;
      if (this.localSignatoryRoles) {
        let item = new LocalSignatoryRole();
        item.id = this.emptyGuid;
        item.name = 'Нет';
        this.localSignatoryRoles.unshift(item);
      }
    });
  }

  private getSignatoryRoles() {
    this.localSignatoryService.getSignatoryRoles().subscribe({
      next: (response) => {
        this.dictSignatoryRoles = response;
      },
      error: () => {
        this.notificationService.showError('Не удалось получить список ролей');
      },
    });
  }

  private getLocalSignatories() {
    this.loading = true;
    this.localSignatoryService.getLocalSignatories().subscribe({
      next: (response) => {
        this.localSignatories = response;
        if (this.localSignatories) {
          for (let i = 0; i < this.localSignatories.length; i++) {
            for (let j = 0; j < this.dataSources.length; j++) {
              valueChangeBySourceName(this.getSource(this.localSignatories[i], this.dataSources[j]), 'id', null, '');
              valueChangeBySourceName(this.getSource(this.localSignatories[i], this.dataSources[j]), 'name', null, 'Все');
              valueChangeBySourceName(this.getSource(this.localSignatories[i], this.dataSources[j]), 'shortName', null, 'Все');
            }

            this.localSignatories[i].dictLocalSignatoryRoleId = this.localSignatories[i].dictLocalSignatoryRoleId
              ? this.localSignatories[i].dictLocalSignatoryRoleId
              : this.emptyGuid;
          }
        }
        this.loading = false;
        this.loadItems();
      },
      error: () => {
        this.notificationService.showError('Не удалось получить список локальных подписантов');
        this.loading = false;
      },
    });
  }

  //Start Adding
  public addHandler({ sender }: AddEvent): void {
    this.closeEditor();
    this.trainingLevelsEdit = [];
    this.departmentsEdit = [];
    this.filialsEdit = [];
    this.sectionsEdit = [];

    this.formGroup = formGroup(new LocalSignatoryDto());
    this.isLine = true;
    this.isNew = true;

    sender.addRow(this.formGroup);
  }

  public filteredData: any = {};

  public async editHandler({ sender, rowIndex, dataItem }: CellClickEvent) {
    if (this.isLine || !this.editable || (this.formGroup && !this.formGroup.valid)) {
      return;
    }

    const targetKey: string = 'id';

    // Вывод данных в редактирование
    for (let i = 0; i < this.dataSources.length; i++) {
      this.filteredData[this.dataSources[i]] = (this as any)[this.dataSources[i]];
      (this as any)[`${this.dataSources[i]}Edit`] = DisplayValuesInEditMode(dataItem[this.dataSources[i]], [targetKey]);
    }

    this.isLine = true;
    await this.saveRow();
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public removeHandler({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openRemoveDialog(this.dialogService, this.getSignatoryRoleName(dataItem.dictSignatoryRoleId) ?? '');
    this.methods.remove(dialog.result, dataItem.id);
  }

  public closeEditor() {
    this.isNew = false;
    this.grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public async saveCurrent() {
    if (this.formGroup && !this.formGroup.valid) {
      return;
    }
    this.isLine = false;
    await this.saveRow();
  }

  private async saveRow() {
    if (!this.isInEditingMode) {
      this.closeEditor();
      return;
    }

    if (!this.formGroup) return;

    for (let i = 0; i < this.dataSources.length; i++) {
      valueChange(this.formGroup.value[this.dataSources[i]], '', null);
    }
    this.formGroup.value.dictLocalSignatoryRoleId =
      this.formGroup.value.dictLocalSignatoryRoleId == this.emptyGuid ? null : this.formGroup.value.dictLocalSignatoryRoleId;

    if (this.formGroup) await this.methods.save(this.isNew, this.formGroup);

    this.closeEditor();
  }

  public getList(dataItem: any, key: any) {
    return GridDisplayList(dataItem, key);
  }

  public valueChange(value: any, editItem: any) {
    (this as any)[`${editItem}`] = arrayRewrite(value);
  }

  public getSignatoryRoleName(signatoryRoleId: string): string | undefined {
    return this.dictSignatoryRoles.find((_) => _.id === signatoryRoleId)?.name;
  }

  public getLocalSignatoryRoleName(localSignatoryRoleId: string): string | undefined {
    return this.localSignatoryRoles.find((_) => _.id === localSignatoryRoleId)?.name;
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.loadItems();
  }

  public getSource(source: LocalSignatoryDto, key: string): LocalSignatoryDictDto[] {
    return source[key as keyof LocalSignatoryDict];
  }

  public loadItems() {
    let data = this.localSignatories.slice();

    for (let i = 0; i < this.dataSources.length; i++) {
      const filter = this.filter.filters.map((item) => item as FilterDescriptor).find((item) => item.field === this.dataSources[i]);
      if (filter)
        data = data.filter((_) =>
          this.getSource(_, this.dataSources[i])
            .map((_) => _.id)
            .includes(filter?.value),
        );
    }
    const filters = {
      logic: this.filter.logic,
      filters: this.filter.filters.filter((item) => {
        const i = item as FilterDescriptor;
        return i.field && !this.dataSources.includes(i.field.toString());
      }),
    };

    data = filterBy(data.slice(0), filters);

    this.gridView = {
      data: data,
      total: data.length,
    };
  }
}

const formGroup = (dataItem: LocalSignatoryDto) =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    trainingLevels: new FormControl(dataItem.trainingLevels, Validators.required),
    departments: new FormControl(dataItem.departments, Validators.required),
    filials: new FormControl(dataItem.filials, Validators.required),
    sections: new FormControl(dataItem.sections, Validators.required),
    dictSignatoryRoleId: new FormControl(dataItem.dictSignatoryRoleId, Validators.required),
    dictLocalSignatoryRoleId: new FormControl(dataItem.dictLocalSignatoryRoleId, Validators.required),
  });

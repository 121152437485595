import {Component, OnInit, ViewChild, Renderer2} from "@angular/core";
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {pagerSettings} from 'src/app/models/diplomablanks/pagerSettings.model';
import {CellClickEvent, GridComponent, RemoveEvent} from "@progress/kendo-angular-grid";
import {FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {openDialog} from "../../../../helpers/dialogHelper";
import {DialogCloseResult, DialogResult} from '@progress/kendo-angular-dialog';
import {DictBlankTypeService} from '../../../../services/diplomablanks/dict-blanktypes.service';
import {GeneralDictsService} from '../../../../services/diplomablanks/general-dicts.service';
import {boolOptionsEnum} from '../../../../models/diplomablanks/enum/boolOptions.enum';
import {isDiplomaEnum} from '../../../../models/diplomablanks/enum/diploma.enum';
import {BlankTypesModel, trainingLevelModel} from '../../../../models/diplomablanks/dicts.model';
import {GridDisplayList} from "../../../../helpers/grid-helper";
import {arrayRewrite, valueChange} from "../../../../helpers/multiselect-helper";
import {DiplomablanksUserAccessService} from '../../../../services/useraccess/diplomablanks-user-access.service';
import {levelAccess} from '../../../../models/diplomablanks/enum/boolOptions.enum';
import {isChildOf} from 'src/app/helpers/elementRef-helper';

@Component({
    selector: 'dict-blanktypes',
    templateUrl: './dict-blanktypes.component.html',
    styleUrls: ['./dict-blanktypes.component.scss']
  })

export class DictBlankTypesComponent implements OnInit {

  public blankTypes: BlankTypesModel[] = [];
  public filterBlankTypes: string[] = [];
  public trainingLevel: trainingLevelModel[] = [];
  public trainingLevelEdit: trainingLevelModel[] = [];

  protected pageSize = 20;
  protected readonly pagerSettings = pagerSettings;
  public loading: boolean = false;

  @ViewChild(GridComponent) private grid!: GridComponent;
  private editedRowIndex: number | undefined;
  public isLine = false;
  public isNew = false;

  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined;
  }

  public formGroup: UntypedFormGroup | undefined;

  public boolOptions = boolOptionsEnum;
  public isDiploma = isDiplomaEnum;

  public notUniqueName: boolean = false;
  public editable: boolean = false;

  constructor(
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private dictBlankTypeService: DictBlankTypeService,
    private generalDictsService: GeneralDictsService,
    private diplomablanksUserAccessService: DiplomablanksUserAccessService,
    private renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    this.getUserAccess();
    this.getBlankTypes();
    this.getTraininglevel();

    this.renderer.listen("document", "click", ({ target }) => {
      if (!isChildOf(target, "k-grid") && !this.isNew) {
        this.saveRow();
      }
    });
  }

  public getUserAccess() {
    this.diplomablanksUserAccessService.getUserAccess()
    .subscribe(response => {
      this.editable = response.dict == levelAccess.write;
    })
  }

  public getTraininglevel() {
    this.generalDictsService.getTrainingLevel()
      .subscribe(response => {
        this.trainingLevel = response;
        if (this.trainingLevel) {
          this.trainingLevel.unshift({externalId: "", name: 'Все'});
        }
      })
  }

  public getBlankTypes() {
    this.loading = true;
    this.dictBlankTypeService.getBlankTypes()
      .subscribe(response => {
        if (response) this.blankTypes = response;
        this.loading = false;
        this.blankTypes.forEach(el => {
          if (el.blankTypeTrainingLevels?.some(el => el.externalId == null)) el.blankTypeTrainingLevels = [{externalId: '', name: 'Все'}];
        })

        this.filterBlankTypes = [...new Set((this.blankTypes.map(n => n.name)))]
      },
      error => {
        this.notificationService.showError(error.error);
        this.loading = false;
      })
  }

  public editRow({
    sender,
    columnIndex,
    rowIndex,
    dataItem,
  }: CellClickEvent): void {
    if (this.isNew || this.notUniqueName || !this.editable) return;

    if (this.isLine || (this.formGroup && !this.formGroup.valid)) {
      this.saveRow();
      return;
    }

    this.isLine = true;
    this.trainingLevelEdit = [...dataItem.blankTypeTrainingLevels];
    this.formGroup = formGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public onCancel(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.isLine = false;
    this.editedRowIndex = undefined;
    this.trainingLevelEdit = [];
    this.formGroup = undefined;
    this.notUniqueName = false
  }

  public addRow() {
    this.formGroup = new FormGroup({
        name: new FormControl(null, Validators.required),
        isDiploma: new FormControl(null, Validators.required),
        diplomaIsRed: new FormControl(null, Validators.required),
        blankTypeTrainingLevels: new FormControl(null, Validators.required),
        order: new FormControl(null, Validators.required),
      })
    this.isNew = true;
    this.isLine = true;
    this.grid.addRow(this.formGroup);
  }

  public saveRow(): void {
    if (!this.formGroup || this.formGroup && !this.formGroup.valid) {
        return;
    }

    if (this.formGroup?.value.blankTypeTrainingLevels.some((el: trainingLevelModel) => el.externalId == "")) this.formGroup.value.blankTypeTrainingLevels = [{externalId: null, name: 'Все'}];

    if (this.isNew) {
      this.dictBlankTypeService.addBlankType(this.formGroup?.value).subscribe(response => {
        this.getBlankTypes();
        this.notificationService.showSuccess('Добавлено');
      },
        error => {
          this.notificationService.showError(error.error);
      });
      } else {
        this.dictBlankTypeService.updateBlankType(this.formGroup?.value).subscribe(response => {
          this.getBlankTypes();
          this.notificationService.showSuccess('Сохранено');
        },
          error => {
            this.notificationService.showError(error.error);
        });
      }

      this.onCancel();
  }

  public onRemove({ dataItem }: RemoveEvent): void {
    const dialog: DialogRef = openDialog(this.dialogService, `Удалить "${dataItem.name}" из табличного списка?`);
    dialog.result.subscribe((result) => {
        if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
          this.dictBlankTypeService.deleteBlankType(dataItem.externalId).subscribe(
            response => {
              this.notificationService.showSuccess('Удалено');
              this.getBlankTypes();
            },
            error => {this.notificationService.showError(error.error)}
        )}
    });
  }

  public getTrainingLevelsList(dataItem: BlankTypesModel, key: string) {
    return GridDisplayList(dataItem, key);
  }

  public valueChangeLevel(value: trainingLevelModel[]) {
    this.trainingLevelEdit = arrayRewrite(value, "externalId");
  }

  public getText(value: boolean) {
    return this.isDiploma.find(el => el.id == value)?.text;
  }

  public valueChangeName(value: string, dataItem: BlankTypesModel) {
    this.blankTypes.some(el => el.name == value) && this.isNew || this.blankTypes.some(el => el.name == value && el.externalId !== dataItem.externalId)
     ? this.notUniqueName = true : this.notUniqueName = false;
  }
}

const formGroup = (dataItem: BlankTypesModel) =>
  new FormGroup({
    externalId: new FormControl(dataItem.externalId),
    name: new FormControl(dataItem.name, Validators.required),
    order: new FormControl(dataItem.order),
    blankTypeTrainingLevels: new FormControl(dataItem.blankTypeTrainingLevels, Validators.required),
    diplomaIsRed: new FormControl(dataItem.diplomaIsRed, Validators.required),
    isDiploma: new FormControl(dataItem.isDiploma, Validators.required),
  });

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthorAnnouncement } from '../../models/announcement/author.model';
import { environment } from '../../../environments/environment';
import urlJoin from 'url-join';

@Injectable({
    providedIn: 'root'
  })

  export class AuthorService {
    baseUrl = urlJoin(<string>environment.announcementApiEndpoint, environment.apiPaths.announcement.base);

  constructor(private http: HttpClient) { }

  public getAuthor(): Observable<AuthorAnnouncement>{
    return this.http.get<AuthorAnnouncement>(this.baseUrl + '/get/author')
      .pipe(
        catchError(this.handleError));
  }

  public getSearchMessages(page: number, type: number, query: string, tag: string, start: string, finish: string){
   
    let params = new HttpParams();
  if (page !== undefined){
    params = params.append('page', page);
  }  
  if (type !== 2){
    params = params.append('messageType', type);
  }
  if (query !== ""){
    params = params.append('query', query);
  }
  if (tag !== ""){
   params = params.append('tag', tag);
  }
  if ( start !== ""){
  params = params.append('start', start);
  }
  if ( start !== ""){
  params = params.append('finish', finish);
  }
    return this.http.get(this.baseUrl  + '/get/author', {params});
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => {
      errorMessage;
    });
  }
}
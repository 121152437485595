import { Injectable } from '@angular/core';
import {catchError, Observable} from "rxjs";
import {handleError} from "../../../helpers/errorHandle-helper";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {DictEnlargedGroupSpecialization} from "../../../models/education/dictEnlargedGroupSpecialization.model";

@Injectable({
  providedIn: 'root'
})
export class DictEnlargedGroupSpecializationService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.education.dictEnlargedGroupSpecialization}`;

  constructor(private http: HttpClient) { }

  // Получение списка Укрупненных групп специальностей
  public getEnlargedGroupSpecialization(): Observable<DictEnlargedGroupSpecialization[]> {
    return this.http.get<DictEnlargedGroupSpecialization[]>(this.baseUrl + '/GetEnlargedGroupSpecialization/');
  }

  // Добавление группы специальностей
  addEnlargedGroupSpecialization(dictEnlargedGroupSpecialization: DictEnlargedGroupSpecialization): Observable<DictEnlargedGroupSpecialization> {
    return this.http.post<DictEnlargedGroupSpecialization>(this.baseUrl + '/CreateEnlargedGroupSpecialization/' , dictEnlargedGroupSpecialization)
        .pipe(catchError(handleError));
  }

  // Обновление группы специальностей
  updateEnlargedGroupSpecialization(dictEnlargedGroupSpecialization: DictEnlargedGroupSpecialization): Observable<DictEnlargedGroupSpecialization> {
    return this.http.put<DictEnlargedGroupSpecialization>(this.baseUrl + '/UpdateEnlargedGroupSpecialization/' + dictEnlargedGroupSpecialization.externalId, dictEnlargedGroupSpecialization)
        .pipe(catchError(handleError));
  }

  // Удаление группы специальностей
  deleteEnlargedGroupSpecialization(id: string): Observable<DictEnlargedGroupSpecialization> {
    return this.http.delete<DictEnlargedGroupSpecialization>(this.baseUrl + '/DeleteIndustrialPartner/' + id)
        .pipe(catchError(handleError));
  }
}

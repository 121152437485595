<kendo-loader class="loader" *ngIf="loaderVisible" [type]="type" size="medium"> </kendo-loader>
<div class="alignend">
    <button kendoButton themeColor="primary" class="m-l15" [disabled]="loaderVisible" (click)="saveInformation()" *ngIf="allowEdit">Сохранить</button>
</div>

<div class="m-t15 display-flex">
    <input type="checkbox" kendoCheckBox [checked]="additionalData.includeStudyFormInfo" (change)="clickCheckbox('checkStudyForm')" [disabled]="!allowEdit"/>
    <kendo-label class="m-l10"
        text="Включить сведения о форме обучения"
    ></kendo-label>
</div>

<div class="m-t15 m-l40 display-flex" *ngIf="additionalData.includeStudyFormInfo">
    <p class="selectContent parameters w-150">Форма обучения</p>
    <kendo-dropdownlist class="w-300 m-l15"
                        [data]="studyForms"
                        textField="name"
                        valueField="id"
                        [disabled]="!allowEdit"
                        [valuePrimitive]="true"
                        [(ngModel)]="additionalData.dictStudyFormId">
    </kendo-dropdownlist>
</div>

<div class="m-t15 display-flex">
    <input type="checkbox" kendoCheckBox [checked]="additionalData.includeAccelerated" (change)="clickCheckbox('includeAccelerated')" [disabled]="!allowEdit"/>
    <kendo-label
        class="m-l10"
        text="Пройдено ускоренное обучение"
    ></kendo-label>
</div>

<div class="m-t15 display-flex">
    <input type="checkbox" kendoCheckBox [checked]="additionalData.isPartMasteredAtAnotherUniversity" (change)="clickCheckbox('otherInstitution')" [disabled]="!allowEdit"/>
    <kendo-label
        class="m-l10"
        text="Часть ОП освоена в другом вузе"
    ></kendo-label>
</div>

<div *ngIf="additionalData.isPartMasteredAtAnotherUniversity">
    <div class="m-t15 m-l40 display-flex">
        <p class="selectContent parameters w-150">Количество, з.ед.</p>
        <kendo-numerictextbox
                    class="w-300 m-l15"
                    [min]="0"
                    [spinners]="false"
                    format="n2"
                    [disabled]="!allowEdit"
                    [(ngModel)]="additionalData.testUnitsNumber"
        ></kendo-numerictextbox>
     <!--   <kendo-textbox class="w-300 m-l15" [(ngModel)]="additionalData.testUnitsNumber">
        </kendo-textbox>-->
    </div>

    <div class="m-t15 m-l40 display-flex">
        <p class="selectContent parameters w-150">Вуз</p>
        <kendo-textbox class="w-600 m-l15" [(ngModel)]="additionalData.universityName" [disabled]="!allowEdit">
        </kendo-textbox>
    </div>
</div>

<div class="alignend m-t15">
    <button kendoButton themeColor="primary" class="m-l15" [disabled]="loaderVisible" (click)="saveInformation()" *ngIf="allowEdit">Сохранить</button>
</div>

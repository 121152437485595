import {Observable} from "rxjs";
import {FormArray, FormControl, FormGroup} from "@angular/forms";

export function valueChangeBySourceName(array: any, sourceProperty: string, sourceValue: any, targetValue: any): void {
  if (!array || array.length == 0 || !sourceProperty) return;

  for (let i = 0; i < array.length; i++) {
    if (array[i][sourceProperty] == sourceValue)
      array[i][sourceProperty] = targetValue;
  }
}

export function valueChange(array: any, sourceValue: any, targetValue: any): void {
  if (!array || array.length == 0) return;

  for (let i = 0; i < array.length; i++) {
    if (array[i] === sourceValue)
      array[i] = targetValue;
  }
}

export function arrayRewrite(array: any, sourceProperty: string | null = null): any {
  if (!array || array.length == 0) return [];

  if (sourceProperty != null && array[array.length - 1][sourceProperty] === "" ||
      array[array.length - 1] === "")
    return sourceProperty == null
      ? array.filter((_:any) => _ === "")
      : array.filter((_:any) => _[sourceProperty] === "");

  return sourceProperty == null
    ? array.filter((_:any) => _ !== "")
    : array.filter((_:any) => _[sourceProperty] !== "");
}
export function objectRewrite(objects: any[]) {
  if (objects[objects.length - 1].id !== '') return objects.filter((obj: any) => obj.id !== '');
  else return objects.filter((obj: any) => obj.id === '');
}

/**
 * Определить тип данных перед отправкой в перезапись массив
 *
 * @param source - Источник данных
 * @param value - Значение
 * @param sourceProperty - Название поля для фильтрации
 */
export function detectTypeForRewrite(source: any, value: any, sourceProperty?: any) {
  return Array.isArray(source)
      ? arrayRewrite(value, sourceProperty)
      : value;
}



/**
 * Отобразить значения в режиме редактирования
 *
 * @param source - Источник данных
 * @param targetKey - Название поля для фильтрации
 * @returns Массив с заданным ключом
 * @example
 * Пример динамической передачи данных в заданные переменные:
 * ```ts
 * public courses: any[] = [];
 * public faculties: any[] = [];
 * public departments: any[] = [];
 *
 * public coursesEdit: any;
 * public facultiesEdit: any;
 * public departmentsEdit: any;
 * ...
 *
 * editHandler(dataItem: any) {
 *
 *    const dataSources: any[] = ['courses', 'faculties', 'departments'];
 *    const keys: any[] = ['courseNumber','facultyId', 'departmentId'];
 *
 *    for(let i = 0; i < dataSources.length; i++) {
 *        (this as any)[`${dataSources[i]}Edit`] = DisplayValuesInEditMode((dataItem[dataSources[i]]), [keys[i]]);
 *    }
 *
 *    ...
 * }
 *
 * ```
 */

export function DisplayValuesInEditMode(source: any[], targetKey: any ) {
  return source.map((x: any) => x[targetKey]).length === 0
      ? ['']
      : source.map((x: any) => x[targetKey]);
}

// export function DisplayValuesInEditMode(source: any, targetSource: any, sourceKey: string, targetKey: string) {
//   let arr: any[] = [];
//   for (let i = 0; i < targetSource.length; i++) {
//     let id = source.find((item: { [x: string]: any; }) => item[`${sourceKey}`] === targetSource[i][targetKey])?.[sourceKey];
//     if (id) arr.push(id);
//   }
//   return arr;
// }

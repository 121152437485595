import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AdditionalStudentFields } from '../../models/contingent/additionalstudentfields.model';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError } from "rxjs/operators";
import { Guid } from 'guid-typescript';


@Injectable({
  providedIn: 'root'
})
export class AdditionalStudentFieldsService {

  baseUrl = `${environment.apiEndpoint}${environment.apiPaths.additionalstudentfields}`;

  constructor(private http: HttpClient) { }

  //Get By Id additionalstudentfields
  getByIdadditionalstudentfields(id: string): Observable<AdditionalStudentFields> {
    return this.http.get<AdditionalStudentFields>(this.baseUrl + '/getadditionalstudentfieldsbyid' + '/' + id)
      .pipe(catchError(this.handleError));
  }


  //Get By Id additionalstudentfields
  getadditionalstudentfields(id: Guid|undefined): Observable<AdditionalStudentFields[]> {
    return this.http.get<AdditionalStudentFields[]>(this.baseUrl + '/getadditionalstudentfieldsbystudentid' + '/' + id)
      .pipe(catchError(this.handleError));
  }

  //Add additionalstudentfields
  addadditionalstudentfields(additionalstudentfields: AdditionalStudentFields, studentId: string): Observable<AdditionalStudentFields> {
    return this.http.post<AdditionalStudentFields>(this.baseUrl + '/' + studentId, additionalstudentfields)
      .pipe(catchError(this.handleError));
  }

  //Delete additionalstudentfields
  deleteadditionalstudentfields(id: Guid|undefined, studentId: string): Observable<AdditionalStudentFields> {
    return this.http.delete<AdditionalStudentFields>(this.baseUrl + '/' + id + '/' + studentId)
      .pipe(catchError(this.handleError));
  }

  //Edit additionalstudentfields
  updateadditionalstudentfields(additionalstudentfields: AdditionalStudentFields, studentId: string): Observable<AdditionalStudentFields> {
    return this.http.put<AdditionalStudentFields>(this.baseUrl + '/' + additionalstudentfields.additionalStudentFieldExternalId + '/' + studentId, additionalstudentfields)
      .pipe(catchError(this.handleError));
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);}

}

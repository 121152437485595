<dicts-header></dicts-header>
<div class="header"><h1>Общие словари</h1></div>

<div class="grid-container">
  <kendo-grid>
    <kendo-grid-column headerClass="gridHeader" field="string" title="Название">

    </kendo-grid-column>

  </kendo-grid>
</div>

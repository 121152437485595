import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {handleError} from "../../helpers/errorHandle-helper";
import {StudentDebt} from "../../models/gia/student/student-debt.model";
import {StudentInfo} from "../../models/gia/student/student-info.model";

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  private baseUrl = `${environment.apiEndpoint}${environment.apiPaths.gia.student}`;

  constructor(private http: HttpClient) {}

  public getStudentDebt(studentId: string) {
    return this.http.get<StudentDebt[]>(`${this.baseUrl}/Debt/${studentId}`).pipe(catchError(handleError));
  }

  public getStudentInfo(studentId: string) {
    return this.http.get<StudentInfo>(`${this.baseUrl}/Info/${studentId}`).pipe(catchError(handleError));
  }
}

<announcement-header *ngIf="isAdmin || accessLevel"></announcement-header>
<div class="grid-layout-container container">
    <div class = "go-list-news" (click)="goToBack()">
        < Перейти к списку новостей и объявлений
    </div>

    <div class="announcement">
        <div class="title-organization" >
            <div class="title-news" > {{ title }} </div>
        </div>
        <div class="from-date-news"> {{ authorFio }} </div>
        <div class="from-date-news"> {{ published | date:'dd.MM.yyyy HH:mm'}} </div>
     <!--   <div class="text-news" [innerHTML]="content"></div>-->
   
        <kendo-editor  [(ngModel)]="content" [iframe]="false"  [readonly]="true" class="editor" >
            <kendo-toolbar class="display-none"></kendo-toolbar>
        </kendo-editor>
    
        <div class="m-b20 display-t m-l-auto">
            <ng-container  *ngFor="let file of attachment">
                <div class="files">
                    <div><span class="k-icon k-i-paperclip m-r5"> </span></div>
                    <span (click)="getFile(file['externalId'],file['name'])" class="file-name">{{file["name"]}}</span>
                </div>
            </ng-container>
        </div>
        <div class="buttons">
            <div class="flex">
                <button  *ngIf="accesses.length > 0" kendoButton class="pattern" 
                        icon="copy"
                        (click)="usePattern(messageId)">
                    Использовать как шаблон
                </button>
                <button *ngIf="authorId == userId" kendoButton class="pattern"
                        icon="pencil" 
                        (click)="EditAnnouncement(messageId)">
                    Редактировать
                </button>
                <button *ngIf="authorId == userId" kendoButton class="pattern"
                        icon="x"
                        (click)="openpopup()">
                    Удалить
                </button>
            </div>
            <div  *ngIf="message_type == 1"  >
                <div  class="teg" *ngFor="let teg of tags"> 
                    <div class="m-r10">#{{ teg["title"] }} </div>
                 </div>
            </div> 
        </div>
    </div>


   <div class="title-comments">Добавить новый комментарий</div>

    
  <div class="unit">
        <div class="job"> Должность </div>
        <div>
            <kendo-textbox
                class="input-job"
                name="post"                                                        
                [(ngModel)]="post"
                >
            </kendo-textbox>
        </div>
    </div>
    <div class="explanation"> *В комментарии будет видна ваша должность. Вы можете редактировать данное поле. </div>
    <div>
        <textarea rows="3" class="text-comments" placeholder="Введите текст комментария" [(ngModel)]="text_comment" >
        </textarea>
        <div class="col-xs-12 col-sm-6 example-col">
            <kendo-fileselect #myFileSelect="kendoFileSelect" class="file-field"
            [restrictions]="myRestrictions"
            [showFileList]="false"
            (select)="selectEventHandler($event)"
            >
            </kendo-fileselect>
            <p class="file-properties">До 5 файлов формата .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .jpg, .jpeg, .gif, .tif, .bmp, .png, .zip, .txt с ограничением в 5Мб</p>
        </div>
        <div class="m-b20">
            <ng-container *ngFor="let file of commentAttachments; let i = index">
                <span class="attachment">
                    {{ file['name'] }} <a href="#!" class="remove-file" (click)="removeFile(i)">x</a>
                </span>
            </ng-container>
        </div>
    </div>
    <div class="text-r">
        <button kendoButton class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted"
                class="blue"
                size="small"
                (click)="sendComment()"
        >
        отправить
        </button>  
    </div>   

    <div class="comments">
        <app-comments [refreshComments]="refreshComments" [authorId]="authorId" [userId]="userId"></app-comments>
    </div>
    
</div>
<div kendoDialogContainer></div>
<announcement-header *ngIf="isAdmin || accessLevel"></announcement-header>
<div class="grid-layout-container" style="margin-top: 30px">
  <div class="notice"> Создание оповещения</div>
  <div class="author">
    <div class="author"> Автор </div>
    <div>
      <input type="text" class="text-author" disabled = true [(ngModel)]="authorFio">
    </div>
  </div>
  <div>
    <button class="send-message"        
            [ngClass]="{change_students: changestudents,
                        not_change_students: nchangestudents}" 
            (click)="send_students()"
    >
      Отправить студентам
      <span *ngIf="selectedStudentsCount != 0">({{selectedStudentsCount}})</span>
    </button>
    <button class="send-message" 
            [ngClass]="{not_change_persons: nchangepersons,
                        change_persons: changepersons}"                      
        (click) = "send_persons()"
    >
      Отправить сотрудникам
      <span *ngIf="selectedPersonsCount != 0">({{selectedPersonsCount}})</span>
    </button>
    <button class="send-message"
            [ngClass]="{not_recipient_list: nrecipientlist,
                        recipient_list: recipientlist}" 
            (click) = "select_recipients()"
    >
      Указать список получателей
      <span *ngIf="selectedDistrCount != 0">({{selectedDistrCount}})</span>
    </button>
  </div>
  <div class="departure" *ngIf="VisibleSendStudents">
    <app-send-students></app-send-students>
  </div>
  <div class="departure" *ngIf="VisibleSendPersons">
    <app-persons></app-persons>
  </div>
  <div class="departure" *ngIf="VisibleRecipients">
    <app-distribuitions></app-distribuitions>
  </div>
  <div class="departure" style="background: #FFFFFF;">
    <app-panel-control (onChanged)="onChanged($event)"></app-panel-control>
  </div>
  
  <!--PopUp подтверждения-->
  <div class="popup" *ngIf="VisiblePopUp" >
    <div class="popup-content">
      <div class="author" style="margin-bottom: 20px;">Закрыть форму добавления оповещения без сохранения изменений?</div>
    <div> 
      <button class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted"
          style="background: #FFFFFF; color: #6F6F6F; margin-right: 20px;"
          size="small"
          themeColor="success"
          (click) = "cancel()" 
      >
        нет
      </button>
      <button class = "k-button k-button-sm k-rounded-md k-button-solid ng-star-inserted"
              style="background: #2192CE; color: #FFFFFF; "
              size="small"
              themeColor="success"
              [routerLink]="['/alert']"
      >
        да
      </button> 
    </div>
  </div>
</div>
<form [formGroup]="groupsFormGroup">
  <ng-container *ngFor="let group of groups; let i = index" formArrayName="groups">
    <h4 class="subtitle">{{group.name}}</h4>
    <div class="group" [formGroupName]="i">
      <ng-container *ngFor="let fieldCode of group.fieldCodes">
          <span class="field">
              <h3>{{studentFieldsMap.get(fieldCode.code) ?? fieldCode.code}}</h3>
              <h3 class="text">{{fieldCode.value}}</h3>
          </span>
      </ng-container>
      <ng-container *ngFor="let property of formProperties(i).controls; let j = index" formArrayName="properties">
        <span [ngClass]="{
                field: true,
                table: property.value.propertyTypeEnum === KendoProperties.Table
              }">
          <h3>{{property.value.name}}</h3>
          <span [ngClass]="{
                  input: true,
                  'input_text': property.value.propertyTypeEnum === KendoProperties.Textbox
                }">
            <ng-container [formGroupName]="j" [ngSwitch]="property.value.propertyTypeEnum">
              <h3 class="text" *ngSwitchDefault>{{property.value.propertyValue}}</h3>

              <kendo-dropdownlist *ngSwitchCase="KendoProperties.Dropdown"
                                  [data]="(property.value.dict ?? [])"
                                  [valuePrimitive]="true"
                                  (valueChange)="valueChange()"
                                  textField="name"
                                  valueField="id"
                                  formControlName="propertyValue">
              </kendo-dropdownlist>

              <kendo-combobox *ngSwitchCase="KendoProperties.Combobox"
                              [data]="(property.value.dict ?? [])"
                              [valuePrimitive]="true"
                              [kendoDropDownFilter]="filterSettings"
                              (valueChange)="valueChange()"
                              textField="name"
                              valueField="id"
                              formControlName="propertyValue">
              </kendo-combobox>

              <kendo-textbox *ngSwitchCase="KendoProperties.Textbox"
                             (valueChange)="valueChange()"
                             formControlName="propertyValue">
              </kendo-textbox>

              <kendo-textarea *ngSwitchCase="KendoProperties.Textarea"
                              (valueChange)="valueChange()"
                              formControlName="propertyValue">
              </kendo-textarea>

              <kendo-datepicker #datePicker
                                *ngSwitchCase="KendoProperties.Date"
                                [openOnFocus]="datePicker"
                                (valueChange)="valueChange()"
                                formControlName="propertyValue">
              </kendo-datepicker>

              <kendo-numerictextbox *ngSwitchCase="KendoProperties.Number"
                                    [decimals]="0"
                                    [changeValueOnScroll]="false"
                                    [min]="0"
                                    (valueChange)="valueChange()"
                                    format="false"
                                    formControlName="propertyValue">
              </kendo-numerictextbox>

<!--              <kendo-numerictextbox *ngSwitchCase="KendoProperties.Float"-->
<!--                                    [changeValueOnScroll]="false"-->
<!--                                    [min]="0"-->
<!--                                    (valueChange)="valueChange()"-->
<!--                                    formControlName="propertyValue">-->
<!--              </kendo-numerictextbox>-->

              <span *ngSwitchCase="KendoProperties.File">
                <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
              </span>

              <span *ngSwitchCase="KendoProperties.Files">
                <ng-container *ngTemplateOutlet="fileTemplate"></ng-container>
              </span>

              <ng-template #fileTemplate>
                <kendo-fileselect [accept]="fileRestrictions.accept"
                                  [restrictions]="fileRestrictions"
                                  [multiple]="property.value.propertyTypeEnum === KendoProperties.Files"
                                  (valueChange)="fileChange(property.get('propertyValue'), $event)"
                                  formControlName="propertyValue">
                  <ng-template kendoFileSelectFileInfoTemplate let-files>
                    <div>{{ files[0].name }}</div>
                  </ng-template>
                </kendo-fileselect>
                <span *ngIf="property.value.editable" class="restrictions">До {{
                  property.value.propertyTypeEnum === KendoProperties.Files ? fileRestrictions.maxCount + ' файлов' : '1 файла'
                  }} форматов {{fileRestrictions.accept}}</span>
                <a *ngIf="property.get('propertyValue')?.value?.length" class="file-link" (click)="downloadFiles(property.get('propertyValue')?.value)">
                  Скачать {{property.value.propertyTypeEnum === KendoProperties.Files ? 'все' : ''}}
                </a>
              </ng-template>

              <kendo-multiselect *ngSwitchCase="KendoProperties.Multiselect"
                                 [data]="(property.value.dict ?? [])"
                                 [valuePrimitive]="true"
                                 [kendoDropDownFilter]="filterSettings"
                                 (valueChange)="valueChange()"
                                 kendoMultiSelectSummaryTag
                                 textField="name"
                                 valueField="id"
                                 formControlName="propertyValue">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItem>
                  {{ dataItem.length }} выбрано
                </ng-template>
              </kendo-multiselect>

              <input *ngSwitchCase="KendoProperties.Boolean"
                     (click)="valueChange()"
                     type="checkbox" kendoCheckBox
                     formControlName="propertyValue">

              <span *ngSwitchCase="KendoProperties.Table">
<!--                TODO-->
<!--                <ng-container [ngTemplateOutlet]="grid" [ngTemplateOutletContext]="{property: property.value}"></ng-container>-->
              </span>
            </ng-container>
            <span *ngIf="property.value.editable && property.value.required" class="required">*</span>
            <kendo-icon *ngIf="property.value.tooltip"
                        [title]="property.value.tooltip"
                        kendoTooltip
                        name="info"
                        tooltipClass="mfc-tooltip"
                        position="right"
                        size="medium">
            </kendo-icon>
          </span>
        </span>
      </ng-container>
    </div>
  </ng-container>
</form>

<ng-template [typedTemplate]="tableTemplate" #grid let-property='property'>
    <kendo-grid class="grid-data"
                [data]="property.tableData"
                (add)="addRow($event, property.columns)"
                (edit)="editRow($event)"
                (remove)="removeRow($event, property.tableData)"
                (save)="saveRow($event, property.tableData)"
                (cancel)="cancelRowEditing($event)">

        <ng-template kendoGridToolbarTemplate position="top">
            <button [primary]="true" [disabled]="editMode" kendoGridAddCommand icon="plus">Добавить</button>
        </ng-template>

        <ng-template kendoGridLoadingTemplate>
            <div class="k-loading-color"></div>
            <div class="loading-container">
                <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
            </div>
        </ng-template>

        <kendo-grid-column *ngIf="property.editable" title="" [width]="50">
            <ng-template kendoGridCellTemplate>
                <button kendoGridEditCommand icon="pencil"></button>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngFor="let column of property.columns; let i = index"
                           [field]="column.field"
                           [title]="column.name">
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem[column.field]}}
            </ng-template>
            <ng-template kendoGridEditTemplate let-formGroup="formGroup">
                <kendo-textbox [formControl]="formGroup.get(column.field)">
                </kendo-textbox>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="property.editable" title="" [width]="100">
            <ng-template kendoGridCellTemplate>
                <button *ngIf="!editMode" kendoGridRemoveCommand icon="delete"></button>
            </ng-template>
            <ng-template kendoGridEditTemplate>
                <div class="buttons">
                    <button kendoGridSaveCommand icon="save"></button>
                    <button kendoGridCancelCommand icon="cancel"></button>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</ng-template>

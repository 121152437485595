export class Department {
  id?: string | null;
  name!: string;
  shortName?: string;
  filialId?: string;
  departmentType?: number;
}

export enum DepartmentType {
  Факультет = 1,
  Кафедра,
}


export enum BudgetTypeEnum {
  Budget = 1,
  Contract = 2,
  TargetTraining = 3,
}

export const budgetTypeEnumList: {text: string, value: BudgetTypeEnum}[] = [
  {text: 'Бюджет', value: BudgetTypeEnum.Budget },
  {text: 'Контракт', value: BudgetTypeEnum.Contract },
  {text: 'Целевая квота', value: BudgetTypeEnum.TargetTraining },
];

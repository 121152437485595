import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Guid} from "guid-typescript";
import {StudentInfoCardsEnum} from "./studentinfocardsenum";

@Component({
  selector: 'app-studentinfocards',
  templateUrl: './studentinfocards.component.html',
  styleUrls: ['./studentinfocards.component.css']
})
export class StudentInfoCardsComponent implements OnInit {

  public isCards: boolean = true;
  public isAddToListForming: boolean = false;
  public isReestr: boolean = false;

  public studentId?: Guid;
  public reestrId: Guid | null = null;

  constructor(private activateRoute: ActivatedRoute, private router: Router,) {
    if(activateRoute.snapshot.parent!==null)
      this.studentId = activateRoute.snapshot.parent.params["studentId"];
  }

  ngOnInit(): void {

  }

  onChangeSection(typeSection: StudentInfoCardsEnum) {
    this.isCards = this.isReestr = this.isAddToListForming = false;
    switch(typeSection) {
      case StudentInfoCardsEnum.Cards:
        this.isCards = true;
        break;
      case StudentInfoCardsEnum.AddToListForming:
        this.isAddToListForming = true;
        break;
      case StudentInfoCardsEnum.Reestr:
        this.isReestr = true;
        break;
    }
  }

  setReestrId(reestrId: Guid | null) {
    this.reestrId = reestrId;
  }
}

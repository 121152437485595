import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {handleError} from "../../../helpers/errorHandle-helper";
import {DepartmentLitera, DepartmentLiteraRequest} from "../../../models/mfc/dicts/department-litera.model";
import {NotificationsService} from "../../notifications/notifications.service";

@Injectable({
  providedIn: 'root'
})
export class DepartmentLiteraService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/DepartmentLitera/`;

  constructor(private http: HttpClient, private notificationsService: NotificationsService) { }

  public getAll(): Observable<DepartmentLitera[]> {
    return this.http.get<DepartmentLitera[]>(this.baseUrl);
  }

  public getById(id: string): Observable<DepartmentLitera> {
    return this.http.get<DepartmentLitera>(`${this.baseUrl}${id}`);
  }

  public addDepartmentLitera(departmentLitera: DepartmentLiteraRequest) {
    return this.http.post<DepartmentLiteraRequest>(this.baseUrl, departmentLitera)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  public updateDepartmentLitera(departmentLitera: DepartmentLiteraRequest) {
    return this.http.put<DepartmentLiteraRequest>(this.baseUrl, departmentLitera)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }

  public removeDepartmentLitera(id: string): Observable<DepartmentLitera> {
    return this.http.delete<DepartmentLitera>(`${this.baseUrl}${id}`)
      .pipe(catchError(err => {
        this.notificationsService.showError(err.error.Message);
        return handleError(err);
      }));
  }
}

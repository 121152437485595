    <div class="title"> Расширенный поиск студента </div>
    <div class="flex">
        <div class="m-r30">
                <div class="unit">
                    <div class="search-explanation"> ФИО </div>
                    <div class="input-search expand">
                    <kendo-textbox
                        [clearButton]="true"
                        name="fio"
                        [(ngModel)]="initials"
                    >
                    </kendo-textbox>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Институт/Факультет </div>
                    <div>
                        <div class="input-search">
                            <kendo-dropdownlist
                                class="w-520"
                                [data]="facultys"
                                textField="shortName"
                                valueField="id"
                                [valuePrimitive]="true"
                                name="faculty"
                                (valueChange)="onFacultySelectionChange($event)"
                                [(ngModel)]="faculty"
                               >
                            </kendo-dropdownlist>
                        </div>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Курс</div>
                    <div>
                        <kendo-multiselect
                            class="w-520"
                            [data]="courses"
                            kendoMultiSelectSummaryTag
                            placeholder="Все"
                            textField="course"
                            valueField="courseNum"
                            [autoClose]="false"
                            [checkboxes]="true"
                            [valuePrimitive]="true"
                            name="course"
                            [(ngModel)]="arrayOfCourse"
                            >
                                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                  <span></span>
                                    {{ dataItems.length }} выбрано
                                </ng-template>
                        </kendo-multiselect>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Пол </div>
                    <div>
                        <kendo-dropdownlist
                        class="w-520"
                        [data]="listIsMale"
                        textField="text"
                        valueField="value"
                        [defaultItem]="defaultItemIsMale"
                        [valuePrimitive]="true"
                        name="isMale"
                        [(ngModel)]="Male"
                        >
                        </kendo-dropdownlist>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Группа </div>
                    <div>
                        <kendo-multiselect
                            class="w-520"
                            [data]="groups"
                            kendoMultiSelectSummaryTag
                            [kendoDropDownFilter]="filterSettings"
                            placeholder="Группа"
                            textField="name"
                            valueField="id"
                            [autoClose]="false"
                            [checkboxes]="true"
                            [valuePrimitive]="true"
                            name="group"
                            [(ngModel)]="arrayofGroupe">
                                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span>
                                 {{ dataItems.length }} выбрано
                                </ng-template>
                                <ng-template kendoMultiSelectHeaderTemplate>
                                    <span class="m-l11">
                                      <input
                                        type="checkbox"
                                        id="chk"
                                        kendoCheckBox
                                        [checked]="isCheckedMultiSelect"
                                        [indeterminate]="isIndet"
                                        (click)="onClick()"
                                      />
                                      <kendo-label for="chk">{{ toggleAllText }}</kendo-label>
                                    </span>
                                  </ng-template>
                        </kendo-multiselect>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Направление подготовки</div>
                    <div>
                        <kendo-multiselect
                            class="w-520"
                            [data]="directions"
                            kendoMultiSelectSummaryTag
                            [kendoDropDownFilter]="filterSettings"
                            placeholder="Направление подготовки"
                            textField="name"
                            valueField="name"
                            [autoClose]="false"
                            [checkboxes]="true"
                            [valuePrimitive]="true"
                            name="directions"
                            (valueChange)="onDirectionSelectionChange($event)"
                            [(ngModel)]="arrayofDirections">
                                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span>
                                 {{ dataItems.length }} выбрано
                                </ng-template>
                                <ng-template kendoMultiSelectHeaderTemplate>
                                    <span class="m-l11">
                                      <input
                                        type="checkbox"
                                        id="chk"
                                        kendoCheckBox
                                        [checked]="isMultiSelectDirections"
                                        [indeterminate]="isIndetDirections"
                                        (click)="onClickDirections()"
                                      />
                                      <kendo-label for="chk">{{ toggleAllTextDirections }}</kendo-label>
                                    </span>
                                  </ng-template>
                        </kendo-multiselect>
                    </div>
                </div>
        </div>

        <div>
                <div class="unit">
                    <div  class="search-explanation"> Профиль (программа)</div >
                    <div >
                        <kendo-multiselect
                            class="w-520"
                            [data]=" specialties"
                            kendoMultiSelectSummaryTag
                            [kendoDropDownFilter]="filterSettings"
                            placeholder="Специальность"
                            textField="name"
                            valueField="id"
                            [autoClose]="false"
                            [checkboxes]="true"
                            [valuePrimitive]="true"
                            name="specialitys"
                            [(ngModel)]="arrayofSpecialitys">
                                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span>
                                 {{ dataItems.length }} выбрано
                                </ng-template>
                                <ng-template kendoMultiSelectHeaderTemplate>
                                    <span class="m-l11">
                                      <input
                                        type="checkbox"
                                        id="chk"
                                        kendoCheckBox
                                        [checked]="isMultiSelectSpecialties"
                                        [indeterminate]="isIndetSpecialties"
                                        (click)="onClickSpecialties()"
                                      />
                                      <kendo-label for="chk">{{ toggleAllTextSpecialties }}</kendo-label>
                                    </span>
                                  </ng-template>
                        </kendo-multiselect>
                    </div >
                </div >
                <div  class="unit">
                    <div  class="search-explanation"> Источник финансирования</div>
                    <div>
                        <kendo-multiselect
                        class="w-520"
                        [data]=" budgets"
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        placeholder="Источник финансирования"
                        textField="name"
                        valueField="id"
                        [autoClose]="false"
                        [checkboxes]="true"
                        [valuePrimitive]="true"
                        name="budget"
                        [(ngModel)]="arrayofBudget">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span>
                                {{ dataItems.length }} выбрано
                            </ng-template>
                    </kendo-multiselect>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Форма обучения</div>
                    <div>
                        <kendo-multiselect
                        class="w-520"
                        [data]=" forms"
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        placeholder="Форма обучения"
                        textField="name"
                        valueField="id"
                        [autoClose]="false"
                        [checkboxes]="true"
                        [valuePrimitive]="true"
                        name="forms"
                        [(ngModel)]="arrayofForms">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span>
                                {{ dataItems.length }} выбрано
                            </ng-template>
                    </kendo-multiselect>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Уровень подготовки </div>
                    <div>
                        <kendo-multiselect
                        class="w-520"
                        [data]=" levels"
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        placeholder="Уровень подготовки"
                        textField="name"
                        valueField="id"
                        [autoClose]="false"
                        [checkboxes]="true"
                        [valuePrimitive]="true"
                        name="level"
                        [(ngModel)]="arrayofLevels">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span></span>
                                {{ dataItems.length }} выбрано
                            </ng-template>
                    </kendo-multiselect>
                    </div>
                </div>
                <div class="unit">
                    <div class="search-explanation"> Кафедра </div>
                    <div>
                        <kendo-multiselect
                        class="w-520"
                        [data]=" departments"
                        kendoMultiSelectSummaryTag
                        [kendoDropDownFilter]="filterSettings"
                        placeholder="Кафедра"
                        textField="name"
                        valueField="id"
                        [autoClose]="false"
                        [checkboxes]="true"
                        [valuePrimitive]="true"
                        (valueChange)="onDepartmentsSelectionChange($event)"
                        name="department"
                        [(ngModel)]="arrayofDepartments">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                            <span></span>
                             {{ dataItems.length }} выбрано
                            </ng-template>
                            <ng-template kendoMultiSelectHeaderTemplate>
                                <span class="m-l11">
                                  <input
                                    type="checkbox"
                                    id="chk"
                                    kendoCheckBox
                                    [checked]="isMultiSelectDepartments"
                                    [indeterminate]="isIndetDepartments"
                                    (click)="onClickDepartments()"
                                  />
                                  <kendo-label for="chk">{{ toggleAllTextDepartments }}</kendo-label>
                                </span>
                              </ng-template>
                        </kendo-multiselect>
                    </div>
                </div>
   <!--             <div class="unit">
                    <div class="search-explanation"> Научный руководитель</div>
                    <div>
                        <kendo-multiselect
                        style="width: 520px;"
                        [data]=" supervisors"
                        kendoMultiSelectSummaryTag
                        placeholder="Научный руководитель"
                        textField="name"
                        valueField="id"
                        [autoClose]="false"
                        [checkboxes]="true"
                        [valuePrimitive]="true"
                        name="supervisor"
                        [(ngModel)]="arrayofSupervisors">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                            <span></span>
                             {{ dataItems.length }} выбрано
                            </ng-template>
                    </kendo-multiselect>
                    </div>
                </div>-->
                <div class="unit h-35">
                    <div class="search-explanation"> </div>
                </div>
            <div class="unit right">

                <button class="blue-button m-r15"
                        (click)="advanced_search()" >
                Обычный поиск
                </button>
                <button class="blue-button"
                (click) = "FindStudents()">
                Найти студентов
                </button>
            </div>
        </div>
    </div>

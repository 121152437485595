import {Component} from "@angular/core";
import {ApprovalType} from "../../../../models/mfc/dicts/approval-type.model";
import {EventData, FieldInfo} from "../../../../models/common/common-dict.model";
import {FieldType} from "../../../../models/common/enums/common-dict.enum";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApprovalTypeService} from "../../../../services/mfc/dicts/approval-type.service";
import {breadcrumbItemsMap} from "src/app/models/home/breadcrumb-items.model";
import {boolOptions} from "src/app/models/mfc/enums/bool-options.enum";
import {pagerSettings} from "src/app/models/mfc/pagerSettings.model";

@Component({
  selector: 'mfc-dict-approval-type',
  template: `
    <mfc-settings></mfc-settings>
    <app-dict
      [data]="approvalTypes"
      [fieldsInfo]="info"
      [editOnly]="true"
      [breadcrumbs]="breadcrumbItemsMap"
      [editable]="true"
      [sortable]="true"
      [getFormGroup]="formGroup"
      [deletableField]="deletableField"
      [pageable]="pagerSettings"
      (getMethod)="get()"
      (updateMethod)="update($event)">
    </app-dict>
  `
})
export class ApprovalTypeComponent {
  protected approvalTypes: ApprovalType[] = [];
  protected readonly breadcrumbItemsMap = breadcrumbItemsMap;
  protected readonly boolOptions = boolOptions;
  protected readonly pagerSettings = pagerSettings;
  protected readonly deletableField = 'deletable';
  protected readonly info: FieldInfo<ApprovalType>[] = [
    {
      field: 'name',
      type: FieldType.Textbox,
      title: 'Название',
      width: 350,
    },
    {
      field: 'isSignatoryRequired',
      type: FieldType.Dropdown,
      listOptions: {
        data: this.boolOptions,
        textField: 'text',
        valueField: 'id'
      },
      title: 'Тип с подписанием',
      width: 200,
    },
    {
      field: 'buttonYes',
      type: FieldType.Textbox,
      title: 'Да',
      width: 200,
    },
    {
      field: 'buttonNo',
      type: FieldType.Textbox,
      title: 'Нет',
      width: 200,
    },
    {
      field: 'buttonToRework',
      type: FieldType.Textbox,
      title: 'Возврат в работу',
      width: 200,
    },
    {
      field: 'sortNumber',
      type: FieldType.Order,
      width: 200,
    },
  ];

  protected formGroup(dataItem?: ApprovalType): FormGroup {
    return new FormGroup({
      id: new FormControl(dataItem?.id),
      name: new FormControl(dataItem?.name, Validators.required),
      isSignatoryRequired: new FormControl(dataItem?.isSignatoryRequired ?? false, Validators.required),
      buttonYes: new FormControl(dataItem?.buttonYes, Validators.required),
      buttonNo: new FormControl(dataItem?.buttonNo),
      buttonToRework: new FormControl(dataItem?.buttonToRework),
      sortNumber: new FormControl(dataItem?.sortNumber),
    });
  }

  constructor(private approvalTypeService: ApprovalTypeService) {}

  protected get() {
    this.approvalTypeService.getApprovalTypes().subscribe(data => this.approvalTypes = data);
  }

  protected update({value, observer}: EventData<ApprovalType>) {
    this.approvalTypeService.updateApprovalType(value).subscribe(observer);
  }
}

<kendo-dialog
    title="Вставка изображения"
    *ngIf="opened"
    (close)="close()"
    [minWidth]="250"
    [width]="450"
>
    <div class="row example-wrapper">
    <div class="col-xs-8 col-sm-12 example-col">
        <div class="card">
        <div class="card-block">
            <form class="k-form-inline">
            <div class="k-form-field">
                <label>Изображение</label>
                <my-upload (valueChange)="setImageInfo($event)"> </my-upload>
            </div>
      <!--     <div class="k-form-field">
                <label [for]="heightInput">Высота (px)</label>
                <kendo-numerictextbox
                #heightInput
                format="n0"
                [(value)]="height"
                [min]="0"
                >
                </kendo-numerictextbox>
            </div>
            <div class="k-form-field">
                <label [for]="widthInput">Ширина (px)</label>
                <kendo-numerictextbox
                #widthInput
                format="n0"
                [(value)]="width"
                [min]="0"
                >
                </kendo-numerictextbox>
            </div>-->
            </form>
        </div>
        </div>
    </div>
    </div>
    <kendo-dialog-actions>
    <button kendoButton (click)="close()">Отмена</button>
    <button
        kendoButton
        [disabled]="canInsert"
        (click)="uploadImage()"
        themeColor="primary"
    >
        Вставить
    </button>
    </kendo-dialog-actions>
</kendo-dialog>
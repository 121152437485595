export enum MilitaryStatusesEnum {
  NonMilitary = 1,
  Military = 2,
  Conscript = 4,
}

export const militaryStatusesEnumList: {text: string, value: MilitaryStatusesEnum}[] = [
  {text: 'Невоеннообязанный', value: MilitaryStatusesEnum.NonMilitary },
  {text: 'Военнообязанный', value: MilitaryStatusesEnum.Military },
  {text: 'Призывник', value: MilitaryStatusesEnum.Conscript },
];

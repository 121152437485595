import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {AssignmentDepartmentGet} from "../../models/disciplineworkload/assignements.model";
import {handleError} from "../../helpers/errorHandle-helper";
import {
  CathedralLoadGet, Faculty, FacultyFilter,
  FlowTypeWorkUpdate,
  GroupTypeWorkUpdate
} from "../../models/disciplineworkload/departmentworkload.model";
import {CreateQuery} from "../../helpers/createQuery-helper";
import {KafedraGet} from "../../models/disciplineworkload/externals.model";

@Injectable({
  providedIn: 'root'
})
export class DepartmentWorkloadService {

  baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.cathedralLoad}`;
  baseUrlReports = `${environment.lkPersonApiEndpoint}${environment.apiPaths.disciplineWorkload.reports}`;

  constructor(private http: HttpClient) { }

  getCathedralLoad(filter: HttpParams): Observable<CathedralLoadGet[]> {
    return this.http.get<CathedralLoadGet[]>(this.baseUrl, {params: filter});
  }

  updateFlowTypeWork(model: FlowTypeWorkUpdate): Observable<any> {
    return this.http.post(this.baseUrl + '/UpdateFlowTypeWork', model);
  }

  updateGroupTypeWork(model: GroupTypeWorkUpdate): Observable<any> {
    return this.http.post(this.baseUrl + '/UpdateGroupTypeWork/', model);
  }

  public getFaculties(filter: FacultyFilter): Observable<Faculty[]> {
    const queryParams = CreateQuery(filter);

    return this.http.get<Faculty[]>(this.baseUrl + '/GetFaculties', {params: queryParams});
  }

  public getAvailableCathedras(): Observable<KafedraGet[]> {
    return this.http.get<KafedraGet[]>(this.baseUrl + '/GetAvailableCathedras');
  }

  //#region Export

  public getExportKathedraLoadByPersons(filters: { studyYear: number, kafedraId: string, facultyId: string }){
    const query = CreateQuery(filters);
    return this.http.get(this.baseUrl + '/GetExportKathedraLoadByPersons', {
      params: query,
      responseType: 'blob'
    });
  }

  public getExportPlanFact(filters: {
    studyYear: number,
    kafedraId: string,
    trainingLevelId?: string[] | null,
    facultyId?: string | null,
    studyFormId?: string[] | null,
    personId?: string | null
  }){
    const query = CreateQuery(filters);
    return this.http.get(this.baseUrlReports + '/GetExcelReportTeachersAnalyticsPlanFact', {
      params: query,
      responseType: 'blob'
    });
  }

  //#endregion
}

import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable, throwError} from "rxjs";
import {Department} from "../../models/dicts/department.model";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private baseUrl: string = `${environment.apiEndpoint}${environment.apiPaths.dicts.departmens}`;

  constructor(private http: HttpClient) {
  }

  public getDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(this.baseUrl + '/GetDepartments');
  }

  // Error
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}

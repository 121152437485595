import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CheckableSettings,
  NodeClickEvent,
  TreeItem,
} from '@progress/kendo-angular-treeview';
import { Observable, of } from 'rxjs';
import { DictsService } from '../../../services/middlecontrol/dicts.service';
import { TrainingLevelsModel } from '../../../models/middlecontrol/trainingLevels.model';
import { SemesterYearsModel } from '../../../models/middlecontrol/semesterYears.model';
import { TreesService } from '../../../services/middlecontrol/trees.service';
import { TreeCommonModel } from '../../../models/middlecontrol/trees.model';
import { Guid } from 'guid-typescript';
import {DateModel} from "../../../models/middlecontrol/date.model";
import {CurrentDayEnd, getDayEnd} from "../../../helpers/date-helper";

@Component({
  selector: 'app-middlecontrolhometree',
  templateUrl: './middlecontrolhometree.component.html',
  styleUrls: ['./middlecontrolhometree.component.scss'],
})
export class MiddlecontrolhometreeComponent implements OnInit {
  @Output() onChangeGroup: EventEmitter<TreeCommonModel> =
    new EventEmitter<TreeCommonModel>();
  @Output() onChangeChecked: EventEmitter<string[]> = new EventEmitter<
    string[]
    >();
  @Output() onChangeCheckedSemesters: EventEmitter<string[]> = new EventEmitter<
    string[]
    >();
  @Output() onChangeTreeData: EventEmitter<TreeCommonModel[]> =
    new EventEmitter<TreeCommonModel[]>();
  @Output() onChangeDate: EventEmitter<DateModel> =
    new EventEmitter<DateModel>();
  @Output() selectedTrainingLevelChange: EventEmitter<string[]> =
    new EventEmitter();
  @Output() selectedTrainingLevelNameChange: EventEmitter<string[]> = new EventEmitter();
  @Output() selectedSemesterChange: EventEmitter<SemesterYearsModel> =
    new EventEmitter();
  @Output() expandedKeysChange: EventEmitter<string[]> = new EventEmitter();
  @Output() selectedKeysChange: EventEmitter<string[]> = new EventEmitter();

  @Input() treeType: string = 'department';
  @Input() checkable: boolean = false;
  @Input() checkGroup: boolean = false;
  @Input() checkedItems: string[] = [];
  @Input() expandedKeys: string[] = [];
  @Input() selectedKeys: string[] = [];
  @Input() selectedSemester: SemesterYearsModel | null = null;
  @Input() selectedTrainingLevel?: string[] = [];
  @Input() trainingLevelMode: 'single' | 'multi' = 'multi';
  @Input() checkNodeBy: string = 'id';

  public dataTree: TreeCommonModel[] = [];
  public traininglevels!: TrainingLevelsModel[];
  public showContingentCalendar = false;
  public currentContingentDate: Date = CurrentDayEnd();
  public contingentDate: Date = CurrentDayEnd();
  public currentDate: Date = CurrentDayEnd();
  public showRaitingCalendar = false;
  public currentRaitingDate: Date = CurrentDayEnd();
  public raitingDate: Date = CurrentDayEnd();
  public educationSlices: SemesterYearsModel[] = [];
  public currentSemester: SemesterYearsModel = {} as SemesterYearsModel;
  public treeSettings: CheckableSettings = { enabled: false };
  public selectedGroupString: string = '';
  public selectedGroup: TreeCommonModel = {} as TreeCommonModel;
  public isCheckGroup: boolean = false;
  public isEditDate: boolean = false;
  public loadingTreeData = false;
  public discipline = false;
  public changeGroup = "";

  constructor(
    private dictsService: DictsService,
    private treesService: TreesService
  ) {}

  async ngOnInit() {
    await this.getTrainingLevel();

    this.treeSettings = { enabled: this.checkable };
    !this.dictsService.semesterYears$.getValue().length &&
    await this.dictsService.updateDictSemesterYears();

    if (this.treeType === 'department') {
      this.treesService.departmentTree$.subscribe((response) => {
        this.dataTree = response;
        const storage = this.getLocalStorage()
        if(response.length > 0 && storage !== null) this.expandedKeys = storage.expandedKeys;
        this.onCheckedSemesters();

        this.loadingTreeData = false;
      });
    } else if (this.treeType === 'student') {
      this.treesService.studentTree$.subscribe((response) => {
        this.dataTree = response;
        this.loadingTreeData = false;
      });
    } else if (this.treeType === 'discipline') {
      this.treesService.disciplineTree$.subscribe((response) => {
        this.dataTree = response;
        this.loadingTreeData = false;
        this.onChangeTreeData.emit(response);
      });
    } else if (this.treeType === 'trainingLevel') {
      this.treesService.trainingLevelTree$.subscribe((response) => {
        this.dataTree = response;
        this.loadingTreeData = false;
      });
    }

    this.dictsService.semesterYears$.subscribe((response) => {
      if (response.length) {
        response.forEach((item) => {
          item.semesterNumberString =
            item.semesterInYear + ' семестр ' + item.semesterYearString;
        });

        let semesterYear = response.find((x)=> x.isCurrent)!.semesterYear;
        this.educationSlices = response.filter((x)=> x.semesterYear <= semesterYear);
        const defaultValue =
          response.find((item) => item.isCurrent) || response[0];
        this.currentSemester = defaultValue;
        if (!this.selectedSemester) {
          this.selectedSemester = defaultValue;
        }
        if (this.selectedTrainingLevel) {
          this.changeTreeData(
            this.selectedTrainingLevel && this.selectedTrainingLevel.length != null ? this.selectedTrainingLevel : null,
            this.selectedSemester.semesterInYear,
            this.selectedSemester.semesterYear,
            this.contingentDate
          );
        }
      }
      if(this.selectedSemester != null) this.onChangeEducationSlices(this.selectedSemester, false)
    });
  }

  public async getTrainingLevel() {
    (!this.selectedTrainingLevel || this.selectedTrainingLevel.length === 0) && await this.dictsService.updateTrainingLevels();
    this.dictsService.trainingLevels$.subscribe((response) => {
      this.traininglevels = response;
      switch (this.trainingLevelMode) {
        case "single":
          this.selectedTrainingLevel = response.length > 0 ? [ response[0].id ] : [];
          break;
        default:
          this.selectedTrainingLevel = Array.from(this.traininglevels, (x: TrainingLevelsModel) => x.id!);
          break;
      }
      // Add "All" option in traininglevel list
      /*const all: boolean = this.traininglevels.filter(value => value.name === 'Все').length > 0;
      if(!all && this.traininglevels.length > 1) {
        let item = new TrainingLevelsModel();
        item.id = '';
        item.name = 'Все';
        this.traininglevels.unshift(item);
      }*/
      const storage = this.getLocalStorage()
      if (storage !== null) {
        this.selectedSemester = storage.selectedSemester
        this.checkable = storage.checkable
        this.checkedItems = storage.checkedItems
        this.selectedTrainingLevel = storage.selectedTrainingLevel
        this.discipline = storage.discipline
        this.contingentDate = new Date(storage.contingentDate)
        this.raitingDate = new Date(storage.markDate)
        this.acceptDate(false)
        if(storage.event) this.onSelectionTreeChange(storage.event, true)
        this.changeGroup = storage.changeGroup
        //localStorage.removeItem("middle_control_settings")
      }
      this.discipline = false
      /*if (this.traininglevels.length && !this.selectedTrainingLevel) {
        this.selectedTrainingLevel = this.traininglevels[0];
        this.selectedTrainingLevelChange.emit(this.selectedTrainingLevel);
      }*/
    });
  }

  public onBlur() {
    this.onChangeChecked.emit(this.checkedItems);
    this.onCheckedSemesters();
  }

  public onCheckedSemesters(){
    const semesters = this.dataTree.flatMap(x => x.value)
      .flatMap(x => x?.value)
      .flatMap(x => x?.value)
      .filter(x => x && this.checkedItems.includes(x.id.toString()))
      .map(x => x?.semesterId!)

    this.onChangeCheckedSemesters.emit(semesters);
  }

  public onChangeEducationSlices(value: SemesterYearsModel, change: boolean): void {
    this.isEditDate = false
    this.selectedSemester = value;
    if(change) {
      this.closeExpandedTree();
      this.selectedSemesterChange.emit(value);
    }
    this.onChangeGroup.emit(this.selectedGroup);

    this.selectedTrainingLevel &&
    this.changeTreeData(
      this.selectedTrainingLevel && this.selectedTrainingLevel.length != 0 ? this.selectedTrainingLevel : null,
      value.semesterInYear,
      value.semesterYear,
      this.contingentDate
    );
  }

  public onSelectedFacultyChanged(trainingLevel: string[]) {
    this.selectedTrainingLevel = trainingLevel;
    this.closeExpandedTree();

    if (this.selectedTrainingLevel)
      this.isCheckedMultiSelectTrainingLevel = this.selectedTrainingLevel.length === this.traininglevels.length;

    this.selectedTrainingLevelChange.emit(trainingLevel);
    this.selectedTrainingLevelNameChange.emit(this.traininglevels
      .filter(_ => trainingLevel.includes(_.id))
      .map(_ => _.name)
    )

    this.changeTreeData(
      this.selectedTrainingLevel && this.selectedTrainingLevel.length != 0  ? this.selectedTrainingLevel : null,
      this.selectedSemester?.semesterInYear || 0,
      this.selectedSemester?.semesterYear || 0,
      this.contingentDate
    );
  }

  public blurLevels() {
    if (!this.selectedTrainingLevel || this.selectedTrainingLevel.length == 0) {
      this.selectedTrainingLevel = Array.from(this.traininglevels, (x: TrainingLevelsModel) => x.id!) ;
      this.isCheckedMultiSelectTrainingLevel = true;
      this.onSelectedFacultyChanged(this.selectedTrainingLevel);
    }
  }

  public reloadTree(){
    this.changeTreeData(
      this.selectedTrainingLevel && this.selectedTrainingLevel.length != null ? this.selectedTrainingLevel : null,
      this.selectedSemester?.semesterInYear || 0,
      this.selectedSemester?.semesterYear || 0,
      this.contingentDate
    );
  }

  private changeTreeData(
    trainingLevelId: string[] | null,
    semesterInYear: number,
    year: number,
    contingentDate: Date
  ) {
    this.loadingTreeData = true;
    const useContingentDate = contingentDate.getTime() !== getDayEnd(new Date()).getTime()
    if (this.treeType === 'department') {
      this.treesService.updateDepartmentTree({semesterInYear, year, trainingLevelId, contingentDate,
        isCurrentSemester: this.currentSemester?.semesterNumberString === this.selectedSemester?.semesterNumberString,
        useContingentDate});
    } else if (this.treeType === 'student') {
      this.treesService.updateStudentTree({semesterInYear, year, trainingLevelId, contingentDate,
        isCurrentSemester: this.currentSemester?.semesterNumberString === this.selectedSemester?.semesterNumberString,
        useContingentDate});
    } else if (this.treeType === 'discipline') {
      this.treesService.updateDisciplineTree({semesterInYear, year, trainingLevelId, contingentDate,
        isCurrentSemester: this.currentSemester?.semesterNumberString === this.selectedSemester?.semesterNumberString,
        useContingentDate});
    } else if (this.treeType === 'trainingLevel') {
      this.treesService.updateTrainingLevelTree();
    }
  }

  public getToday(date: Date): string {
    date = new Date(date)
    let dateToday = date.getDate();
    return `${dateToday < 10 ? '0' : ''}${dateToday}.${
      ((date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1))
    }.${date.getFullYear()}`;
  }

  public onChangeContingentDate(date: any){
    if(date)
      this.contingentDate = getDayEnd(date)
  }

  public onChangeMarkDate(date: any){
    if(date)
      this.raitingDate = getDayEnd(date)
  }

  public onChangeContingentCalendar(): void {
    this.showContingentCalendar = false;
  }

  public onChangeRaitingCalendar(): void {
    this.showRaitingCalendar = false;
  }

  public disabledDates(date: Date): boolean {
    return date.getTime() > Date.now();
  }

  public onSelectionTreeChange(event: TreeItem, saved: boolean) {
    if(!saved)
      this.selectedSemester = this.educationSlices.find((item) => item.semesterNumberString === this.selectedSemester?.semesterNumberString)!

    if (event.index.length > 5) this.changeGroup = event.dataItem.text;
    let middleControlSaveSettings: any = {
      event: event,
      discipline:this.discipline,
      selectedSemester:this.selectedSemester,
      checkable: this.checkable,
      checkedItems: this.checkedItems,
      selectedTrainingLevel: this.selectedTrainingLevel,
      contingentDate: this.currentContingentDate,
      markDate: this.currentRaitingDate,
      changeGroup: this.changeGroup
    }
    const storage = this.getLocalStorage()
    if (storage !== null){
      middleControlSaveSettings.disciplineId = storage.disciplineId
      middleControlSaveSettings.expandedKeys = storage.expandedKeys
      middleControlSaveSettings.electiveDisciplineId = storage.electiveDisciplineId
      middleControlSaveSettings.contingentDate = storage.contingentDate
      middleControlSaveSettings.markDate = storage.markDate
    }
    localStorage.setItem('middle_control_settings', JSON.stringify(middleControlSaveSettings));

    if (event.dataItem.isGroup) {
      this.selectedGroupString = event.dataItem.text;
      this.selectedGroup = event.dataItem;
      if (event.dataItem.text.trim() && this.selectedSemester) {
        this.selectedKeysChange.emit([event.index]);
        this.selectedSemesterChange.emit({
          ...this.selectedSemester,
          semesterNum: event.dataItem.semesterNum,
        });
        this.onChangeGroup.emit(event.dataItem);
      }
    }
    if (this.checkable && !event.dataItem.value) {
      const isExist = this.checkedItems.includes(event.dataItem[this.checkNodeBy]);
      if (isExist) {
        const parentId = this.checkNodeBy == 'id'
          ? event.dataItem.id
            .split('_')
            .filter((item: string) => item.trim())
            .slice(0, -1)
            .join('_')
          : event.dataItem[this.checkNodeBy];
        this.onChangeChecked.emit(
          this.checkedItems.filter(
            (id) => id !== event.dataItem[this.checkNodeBy] && id !== parentId
          )
        );
      } else
        this.onChangeChecked.emit([...this.checkedItems, event.dataItem[this.checkNodeBy]]);
    }
    this.onCheckedSemesters();
  }

  public fetchChildren(node: any): Observable<any[]> {
    //Return the items collection of the parent node as children.
    return of(node.value);
  }

  public hasChildren(node: any): boolean {
    //Check if the parent node has children.
    return Boolean(node.value);
  }

  public hasChildrenGroup(node: any): boolean {
    //Check if the parent node has children.
    return (
      Boolean(node.value) &&
      !(Object.keys(node).includes('isGroup') && node.isGroup)
    );
  }

  public toggleEditDate() {
    this.isEditDate = !this.isEditDate;
    this.contingentDate = this.currentContingentDate;
    this.raitingDate = this.currentRaitingDate;
  }

  public acceptDate(editMode: boolean) {
    //TODO: add service get Tree
    this.currentContingentDate = this.contingentDate;
    this.currentRaitingDate = this.raitingDate;

    const semester = this.selectedSemester;

    if(+this.contingentDate !== +this.currentDate && editMode)
    {
      //this.selectedSemester = this.currentSemester
      this.selectedSemesterChange.emit(this.currentSemester);
      const storage =  this.getLocalStorage()
      if (storage && storage.event) {
        this.onSelectionTreeChange(storage.event, true)
      }
    }

    this.onChangeDate.emit({
      contingentDate: this.currentContingentDate,
      markDate: this.currentRaitingDate
    })
    if(editMode)
    {
      const storage = this.getLocalStorage()
      if (storage !== null){
        let middleControlSaveSettings: any = {
          event: storage.event,
          discipline:storage.discipline,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: storage.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          disciplineId: storage.disciplineId,
          electiveDisciplineId: storage.electiveDisciplineId,
          contingentDate: this.currentContingentDate,
          markDate: this.currentRaitingDate,
        }
        localStorage.setItem('middle_control_settings', JSON.stringify(middleControlSaveSettings));
      }
      this.toggleEditDate();

      this.selectedSemester = semester;

      this.changeTreeData(
        this.selectedTrainingLevel && this.selectedTrainingLevel.length != 0 ? this.selectedTrainingLevel : null,
        this.selectedSemester?.semesterInYear || 0,
        this.selectedSemester?.semesterYear || 0,
        this.contingentDate
      );
    }
  }

  public onNodeClick(event: NodeClickEvent): void {
    if (event.item) {
      const index = this.expandedKeys.indexOf(event.item.index);
      if (index > -1) this.expandedKeys.splice(index, 1);
      else this.expandedKeys.push(event.item.index);

      const storage = this.getLocalStorage()
      if (storage !== null){
        let middleControlSaveSettings: any = {
          event: storage.event,
          discipline:storage.discipline,
          selectedSemester:storage.selectedSemester,
          checkable: storage.checkable,
          checkedItems: storage.checkedItems,
          expandedKeys: this.expandedKeys,
          selectedTrainingLevel: storage.selectedTrainingLevel,
          disciplineId: storage.disciplineId,
          electiveDisciplineId: storage.electiveDisciplineId,
          contingentDate: storage.contingentDate,
          markDate: storage.markDate,
          changeGroup: this.changeGroup
        }
        localStorage.setItem('middle_control_settings', JSON.stringify(middleControlSaveSettings));
      }
    }
  }

  public onTrainingLevelSingleValueChange(id: string) {
    this.onSelectedFacultyChanged([ id ]);
  }

  public getLocalStorage(){
    let storage = localStorage.getItem('middle_control_settings');
    if (storage !== null) return JSON.parse(storage)
    else return null
  }

  public isExpanded = (dataItem: any, index: string) => {
    const storage = this.getLocalStorage();
    if (storage !== null){
      let text = storage.changeGroup;
      let all = document.querySelectorAll("span");
      let arraySelector = Array.from(all);
      let span = arraySelector.findIndex(el => el.innerText == text);
      if (span > -1) all[span].classList.add('k-selected');
    }
    return this.expandedKeys.indexOf(index) > -1;
  };

  private closeExpandedTree() {
    this.selectedKeysChange.emit([]);
    this.expandedKeys = [];
    const storage = this.getLocalStorage()
    if (storage !== null){
      let middleControlSaveSettings: any = {
        event: undefined,
        discipline: storage.discipline,
        selectedSemester: this.selectedSemester,
        checkable: storage.checkable,
        checkedItems: storage.checkedItems,
        expandedKeys: this.expandedKeys,
        selectedTrainingLevel: this.selectedTrainingLevel,
        disciplineId: storage.disciplineId,
        electiveDisciplineId: storage.electiveDisciplineId,
        contingentDate: storage.contingentDate,
        markDate: storage.markDate,
        changeGroup: ""
      }
      localStorage.setItem('middle_control_settings', JSON.stringify(middleControlSaveSettings));
    }
  }

  public isCheckedMultiSelectTrainingLevel = true;

  public isIndet() {
    if(this.selectedTrainingLevel)
      return (
        this.selectedTrainingLevel.length !== 0 && this.selectedTrainingLevel.length !== this.traininglevels.length
      );
    return false
  }

  public onClick() {
    this.isCheckedMultiSelectTrainingLevel = !this.isCheckedMultiSelectTrainingLevel;
    this.selectedTrainingLevel = this.isCheckedMultiSelectTrainingLevel ? Array.from(this.traininglevels.slice().reverse(), x => x.id!) : undefined;
  }

  public isItemSelected(item: TrainingLevelsModel) {
    if(this.selectedTrainingLevel)
      return this.selectedTrainingLevel.some((x) => x === item.id);
    return false
  }
}

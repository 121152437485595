<dicts-header></dicts-header>
<h1>Контрольные акции</h1>

<div class="selectContent" *ngIf="!opened">
  <dd style="display: flex; align-items: center">
    <span class="selectText">
      Уровень подготовки
    </span>
    <kendo-dropdownlist [data]="traininglevels"
                        textField="trainingLevelName"
                        valueField="dictTrainingLevelExternalId"
                        [valuePrimitive]="true"
                        name="trainingLevel"
                        [(ngModel)]="secondFilter.dictTrainingLevelId"
                        [defaultItem]="defaultItemTrainingLevel"
                        (valueChange)="applyFilter()"
                        style="width: 400px;">
    </kendo-dropdownlist>
  </dd>

  <dd style="display: flex; align-items: center">
    <span class="selectText">
      Тип стандарта
    </span>
    <kendo-dropdownlist [data]="standardTypes"
                        textField="standardTypeShortName"
                        valueField="standardTypeExternalId"
                        [valuePrimitive]="true"
                        name="standardType"
                        (valueChange)="applyFilter()"
                        [(ngModel)]="secondFilter.dictStandardTypeId"
                        [defaultItem]="defaultItemStandardType"
                        style="width: 400px;">
    </kendo-dropdownlist>
  </dd>
</div>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="controlActionsByFilter"
              (remove)="removeHandler($event)"
              [height]="500"
              [ngClass]="{noData: controlActions.length < 8}"
              (cellClick)="editHandler($event)"
              (add)="addHandler($event)"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)">

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <button kendoGridAddCommand themeColor="success" *ngIf="!isInEditingMode" icon="plus">Добавить</button>
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <!--<kendo-grid-column field="dictControlActionId" title="AcademicStateId"> </kendo-grid-column> -->
    <kendo-grid-column field="controlActionName"
                       title="Название акций"
                       [width]="350"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.controlActionName}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="controlActionSName"
                       title="Сокр. название"
                       [width]="200"
                       headerClass="gridHeader">
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.controlActionSName}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="serialNumber"
                       title="Порядковый номер"
                       [width]="100"
                       headerClass="gridHeader">
                      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                        <kendo-numerictextbox class="border"
                          [formControl]="formGroup.get('serialNumber')"
                                       format="n"
                                       [decimals]="0"
                                       [min]="1"
                        ></kendo-numerictextbox>
                      </ng-template>
                       <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.serialNumber}}
                          </span>
                        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="controlActionAbbreviation"
                      title="Аббревиатура"
                      [width]="100"
                      headerClass="gridHeader">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.controlActionAbbreviation}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isNotUsed"
                       editor="boolean"
                       title="Не учитывать оценки"
                       [width]="100"
                       headerClass="gridHeader">
      <ng-template kendoGridCellTemplate let-dataItem>
                          <span class="alignCells">
                            {{dataItem.isNotUsed ? "Да" : "Нет"}}
                          </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="" [width]="60" *ngIf="editable">
      <ng-template kendoGridCellTemplate>
        <span class="alignCells">
          <button kendoGridRemoveCommand themeColor="secondary" icon="delete" fillMode="flat" size="none" class="custom-size"
            rounded="full">
          </button>
        </span>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>

</div>

<div kendoDialogContainer></div>

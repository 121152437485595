import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {handleError} from "../../../helpers/errorHandle-helper";
import {ApprovalStatus} from "../../../models/mfc/dicts/approval-status.model";

@Injectable({
  providedIn: 'root'
})
export class ApprovalStatusService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/ApprovalStatus/`;

  constructor(private http: HttpClient) { }

  public getApprovalStatuses(): Observable<ApprovalStatus[]> {
    return this.http.get<ApprovalStatus[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }

  public getApprovalStatus(id: string): Observable<ApprovalStatus> {
    return this.http.get<ApprovalStatus>(`${this.baseUrl}${id}`);
  }

  public addApprovalStatus(approvalStatus: ApprovalStatus): Observable<ApprovalStatus> {
    return this.http.post<ApprovalStatus>(this.baseUrl, approvalStatus)
      .pipe(catchError(handleError));
  }

  public updateApprovalStatus(approvalStatus: ApprovalStatus): Observable<ApprovalStatus> {
    return this.http.put<ApprovalStatus>(this.baseUrl, approvalStatus)
      .pipe(catchError(handleError));
  }

  public removeApprovalStatus(id: string): Observable<ApprovalStatus> {
    return this.http.delete<ApprovalStatus>(`${this.baseUrl}${id}`)
      .pipe(catchError(handleError));
  }
}

<kendo-stacklayout orientation="vertical" [gap]="10" class="signer-edit">
  <kendo-label text="Подписант" class="signer-edit__label">
    <kendo-dropdownlist
      class="signer-edit__input"
      [data]="userList"
      (valueChange)="onChangeSigner($event)"
      [value]="signer"
      textField="fio"
      valueField="personId"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p class="signer-edit__template-not-found">Не найдено</p>
      </ng-template>
      <ng-template kendoDropDownListItemTemplate let-dataItem>
        {{ dataItem?.fio }}
      </ng-template>
      <ng-template kendoDropDownListValueTemplate let-dataItem>
        {{ dataItem?.fio || dataItem }}
      </ng-template>
    </kendo-dropdownlist>
  </kendo-label>

  <kendo-label text="Должность подписанта" class="signer-edit__label">
    <kendo-combobox
      class="signer-edit__input"
      [data]="postList"
      [allowCustom]="true"
      [value]="signer.postName"
      (valueChange)="onChangeSignerPost($event)"
    >
      <ng-template kendoDropDownListNoDataTemplate>
        <p class="signer-edit__template-not-found">Не найдено</p>
      </ng-template>
    </kendo-combobox>
  </kendo-label>

  <kendo-label text="Поиск подписанта" class="signer-edit__label">
    <kendo-autocomplete
      [data]="personsToFilter"
      valueField="fio"
      placeholder="Введите ФИО сотрудника..."
      [clearButton]="true"
      [filterable]="true"
      (filterChange)="handleFilter($event)"
      (valueChange)="onChangeFindSigner($event)"
      class="signer-edit__input"
    >
      <ng-template kendoAutoCompleteNoDataTemplate>
        <p class="signer-edit__template-not-found">Подписант не найден</p>
      </ng-template>
    </kendo-autocomplete>
  </kendo-label>
</kendo-stacklayout>

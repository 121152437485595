<education-header></education-header>
<h1>Тип дисциплины</h1>

<div class="grid-container">

  <!--Grid-->
  <kendo-grid [data]="disciplinetypes"
              [height]="500"
              (cellClick)="editHandler($event)">

    <ng-template kendoGridToolbarTemplate [position]="position" *ngIf="editable">
      <div *ngIf="isInEditingMode">
        <button kendoButton
                (click)="cancelHandler()">
          Отмена
        </button>
        <button kendoButton
                themeColor="primary"
                [disabled]="formGroup!.invalid"
                (click)="saveCurrent()">
          Сохранить
        </button>
      </div>
    </ng-template>

    <kendo-grid-column field="name"
                       title="Название типа дисциплины"
                       [width]="200"
                       headerClass="gridHeader dictionaries"
                       [editable]="false">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells leftAlignment">
                          {{dataItem.name}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="orderNum"
                      title="Порядок сортировки"
                      [width]="100"
                      headerClass="gridHeader dictionaries">
                      <ng-template kendoGridCellTemplate let-dataItem>
                      <span class="alignCells">
                        {{dataItem.orderNum}}
                      </span>
                    </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isPractice"
                       editor="boolean"
                       title="Практическое"
                       [width]="150"
                       headerClass="gridHeader dictionaries"
                       [editable]="false">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.isPractice == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="usedInStudyProcess"
                       editor="boolean"
                       title="Используется в обучающем процессе"
                       [width]="150"
                       headerClass="gridHeader dictionaries"
                       [editable]="false">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.usedInStudyProcess == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hiddenCipher"
                       editor="boolean"
                       title="Скрывать порядковый номер в структуре плана"
                       [width]="150"
                       headerClass="gridHeader dictionaries"
                       [editable]="false">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.hiddenCipher == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="hiddenLaborIntensity"
                       editor="boolean"
                       title="Не учитывать в суммарной трудоемкости"
                       [width]="150"
                       headerClass="gridHeader dictionaries"
                       [editable]="false">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{(dataItem.hiddenLaborIntensity == true ? "Да" : "Нет")}}
                        </span>
                      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="typeEnum"
                       title="Тип для локальной обработки"
                       [width]="200"
                       headerClass="gridHeader dictionaries">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="alignCells">
          {{ getNameTypeById(dataItem.typeEnum) }}
        </span>
      </ng-template>
      <ng-template kendoGridEditTemplate
                   let-dataItem="dataItem"
                   let-formGroup="formGroup">
        <kendo-dropdownlist [data]="enumModels"
                            textField="name"
                            valueField="enumId"
                            [valuePrimitive]="true"
                            [formControl]="formGroup.get('typeEnum')"></kendo-dropdownlist>
      </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column field="filial"
                       title="Филиал"
                       [width]="200"
                       headerClass="gridHeader dictionaries"
                       [editable]="false">
                       <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="alignCells">
                          {{dataItem.filial}}
                        </span>
                      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<div kendoDialogContainer></div>

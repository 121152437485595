import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {handleError} from '../../../helpers/errorHandle-helper';
import {ApplicationStatus} from '../../../models/mfc/dicts/application-status.model';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApplicationStatusService {
  private readonly baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/ApplicationStatus/`;

  constructor(private http: HttpClient) { }

  public getAll(): Observable<ApplicationStatus[]> {
    return this.http.get<ApplicationStatus[]>(this.baseUrl).pipe(map((data) => data ?? []));
  }

  public updateApplicationStatus(status: ApplicationStatus): Observable<ApplicationStatus> {
    return this.http.put<ApplicationStatus>(this.baseUrl, status)
      .pipe(catchError(handleError));
  }
}

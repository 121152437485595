import {Injectable} from "@angular/core";
import {environment, getData$} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, catchError, Observable} from "rxjs";
import {PublicationCurrentUserAccess, PublicationsUserAccess} from "../../models/useraccess/publications/publicationsUserAccess";
import {handleError} from "../../helpers/errorHandle-helper";
import {NotificationsService} from "../notifications/notifications.service";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class PublicationsUserAccessService {
    public currentUserAccess$ = new BehaviorSubject<PublicationCurrentUserAccess>(new PublicationCurrentUserAccess());

    baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.publications.userAccess}`;

    constructor(private http: HttpClient,
                private notificationsService: NotificationsService) { }

    public getCurrentUserAccess(): Observable<PublicationCurrentUserAccess> {
        return this.http.get<PublicationCurrentUserAccess>(`${this.baseUrl}/CurrentUser`)
            .pipe(catchError(handleError));
    }

    public getCurrentUserAccessSettings(): Observable<boolean> {
        return this.http.get<PublicationCurrentUserAccess>(`${this.baseUrl}/CurrentUser`)
            .pipe(map(response => response.accessSettings));
    }

    public getAccess(fio?: string): Observable<PublicationsUserAccess[]> {
        const filter = fio ? `?Fio=${fio}` : '';
        return this.http.get<PublicationsUserAccess[]>(this.baseUrl + filter)
            .pipe(catchError(handleError));
    }

    public createAccess(useraccess: PublicationsUserAccess) {
        return this.http.post<PublicationsUserAccess>(this.baseUrl, useraccess)
            .pipe(catchError(handleError)).subscribe({
                next: () => {
                    getData$.next(true);
                    this.notificationsService.showSuccess("Добавлено");
                },
                error: () =>  {
                    this.notificationsService.showError("Произошла ошибка")
                }
            });
    }

    public updateAccess(useraccess: PublicationsUserAccess) {
        return this.http.put<PublicationsUserAccess>(this.baseUrl, useraccess)
            .pipe(catchError(handleError)).subscribe({
                next: () => {
                    getData$.next(true);
                    if (useraccess.personId === this.currentUserAccess$.value.personId) {
                      this.currentUserAccess$.next(new PublicationCurrentUserAccess());
                    }
                    this.notificationsService.showSuccess("Успешно");
                },
                error: () =>  {
                    this.notificationsService.showError("Произошла ошибка")
                }
            });
    }

    public deleteAccess(id: string): Observable<PublicationsUserAccess> {
        return this.http.delete<PublicationsUserAccess>(this.baseUrl + `/${id}`)
            .pipe(catchError(handleError));
    }
}

// Тип подразделения
export enum DepartmentType {
  Faculty = 1,
  Kafedra,
  Filial
}

export const departmentTypes = [
  {enum: DepartmentType.Faculty, name: 'Факультет'},
  {enum: DepartmentType.Kafedra, name: 'Кафедра'},
  {enum: DepartmentType.Filial, name: 'Филиал'},
];

export const departmentTypesMap = new Map(Object.values(departmentTypes)
  .map((value) => [value.enum, value.name]));

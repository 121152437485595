import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {handleError} from "../../../helpers/errorHandle-helper";
import {DynamicDictData, DictValues} from "../../../models/mfc/dicts/dynamic-dict.model";

@Injectable({
  providedIn: 'root'
})
export class DynamicDictService {
  private baseUrl = `${environment.lkPersonApiEndpoint}${environment.apiPaths.mfc.dict}/Dynamic/`;

  constructor(private http: HttpClient) { }

  public getDictById(dictId: string): Observable<DictValues[]> {
    return this.http.get<DynamicDictData>(`${this.baseUrl}${dictId}`)
      .pipe(map((data) => data.dictValues ?? []));
  }

  public addDictValue(dictId: string, dynamic: DictValues): Observable<DictValues> {
    return this.http.post<DictValues>(`${this.baseUrl}${dictId}/value/`, dynamic)
      .pipe(catchError(handleError));
  }

  public updateDictValue(dictId: string, dynamic: DictValues): Observable<DictValues> {
    return this.http.put<DictValues>(`${this.baseUrl}${dictId}/value/${dynamic.id}`, dynamic)
      .pipe(catchError(handleError));
  }

  public removeDictValue(dictId: string, id: string): Observable<DictValues> {
    return this.http.delete<DictValues>(`${this.baseUrl}${dictId}/value/${id}`)
      .pipe(catchError(handleError));
  }
}

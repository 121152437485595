import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {studentsProtocolsModel, StructTabStudentModel, StructTabDto} from '../../models/gia/giaprotocol.model';
import {StudentInfo} from '../../models/gia/student/student-info.model';

@Injectable({
    providedIn: 'root'
})

export class ProtocolGiaService {

    baseUrl = `${environment.apiEndpoint}`;

    constructor(private http: HttpClient) { }

    public getStudentTabs(studentId: string) {
        return this.http.get<StructTabStudentModel[]>(this.baseUrl + 'gia/protocolConstructor/GetAllDisplayTabsByStudentId/' + studentId);
    }

    public getStudentInfo(studentId: string) {
        return this.http.get<StudentInfo>(this.baseUrl + 'gia/protocol/GetProtocolStudentInfoById/' + studentId);
    }

    public getStudentProtocolInfo(tabId:string, StudentId: string) {
        let params = new HttpParams();
        params = params.append('DisplayProtocolTabId', tabId);
        params = params.append('StudentId', StudentId);
        return this.http.get<StructTabDto>(this.baseUrl + 'gia/protocol/GetProtocolTabById', {params});
    }

    public saveGiaProtocol(data: StructTabDto) {
      //  return this.http.put(this.baseUrl + 'gia/giaProtocol/SaveGiaProtocol', data)
      return this.http.post(this.baseUrl + 'gia/protocol', data);
    }
    
    public getStudentsProtocol(EducationStandardId: string, GraduateYear: string, StudyFormId: string, FacultyId: string, trainingLevelId: string) {
        let params = new HttpParams();
        params = params.append('EducationStandardId', EducationStandardId);
        params = params.append('GraduateYear', GraduateYear);
        params = params.append('StudyFormId', StudyFormId);
        params = params.append('FacultyId', FacultyId);
        params = params.append('TrainingLevelId', trainingLevelId);
        return this.http.get<studentsProtocolsModel[]>(this.baseUrl + 'gia/protocol/GetAll', {params});
    }

    public getQualification(EducationStandardId: string) {
        let params = new HttpParams();
        params = params.append('EducationStandardId', EducationStandardId);
        return this.http.get<[]>(this.baseUrl + 'gia/d/qualification', {params});
    }

    public printProtocol(protocolId: string) {
        return this.http.get(this.baseUrl + 'gia/protocol/Print/' + protocolId, { responseType: 'blob', observe: 'response'});
    }
}
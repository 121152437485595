import { Component, OnInit } from '@angular/core';
import { DepartmentName, Faculty } from 'src/app/models/contingent/departmentname.model';
import { DictStudyForm } from 'src/app/models/contingent/dictstudyform.model';
import { DepartmentService } from 'src/app/services/contingent/department.service';
import { CommondictService } from 'src/app/services/contingent/commondict.service';
import { EdugroupService } from 'src/app/services/contingent/edugroup.service';
import {
  AcademicGroup,
  AcademicGroupFilters, AcademicGroupQuery,
  EduGroupNameRequest
} from 'src/app/models/contingent/edugroup.model';
import {NotificationsService} from "../../../../services/notifications/notifications.service";
import {CellClickEvent, GridComponent, GridDataResult, PageChangeEvent, RowArgs} from '@progress/kendo-angular-grid';
import {
  AggregateDescriptor,
  CompositeFilterDescriptor, filterBy,
  groupBy,
  GroupDescriptor,
  orderBy,
  SortDescriptor
} from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import {Guid} from "guid-typescript";
import { RowClassArgs } from "@progress/kendo-angular-grid";
import {
  DialogService
} from "@progress/kendo-angular-dialog";
import {ContingentUserAccessService} from "../../../../services/useraccess/contingent-user-access.service";
import {DictTrainingLevel} from "../../../../models/contingent/dicttraininglevel.model";
import {CreateQuery} from "../../../../helpers/createQuery-helper";
import { DictFilial } from 'src/app/models/contingent/dictfilial.model';
import { DictFilialService } from 'src/app/services/contingent/dictfilial.service';

@Component({
  selector: 'app-academicgroupshome',
  templateUrl: './academicgroups.component.html',
  styleUrls: ['./academicgroups.component.scss']
})
export class AcademicgroupsComponent implements OnInit {

  public loading = false;
  public loaderExcelExport = false;
  public year?: number;
  public savedYear?:number;
  public thisYear: number = (new Date).getFullYear();
  public facultyId?:Guid;
  public departments: DepartmentName[] = [];
  public faculties: Faculty[] = [];
  public filteredFaculties: Faculty[] = [];
  public selectDepartment = undefined;
  public selectStatus?: boolean = false;
  public dictStudyForms: DictStudyForm[]=[];
  public academicGroups: AcademicGroup[] = [];
  public filials: DictFilial[] = []
  public selectFilials?: (Guid | string)[] = [];
  public trainingLevels!: DictTrainingLevel[]
  public selection: AcademicGroup[] = [];
  public nextYear: boolean =false
  public nameRequests:EduGroupNameRequest[]=[]
  public componentName: string = 'academicgroupPage'
  public academicGroupRequest?: AcademicGroupQuery

  constructor(private router: Router,
              private departmentService:DepartmentService,
              private notificationService: NotificationsService,
              private commonDictService:CommondictService,
              private eduGroupService:EdugroupService,
              private dialogService: DialogService,
              private userAccessService: ContingentUserAccessService,
              private dictFilialService: DictFilialService
              ) { }

  ngOnInit(): void {
    this.getAccessLevel()
    this.getAllDepartment();
    this.getAllStudyForm();
    this.getAllTrainingLevel();
    this.getYears()
    this.getAllFaculty();
    this.getAllFilials();
    const group_settings = localStorage.getItem('group_settings');

    if (group_settings !== null) {
      let storage = JSON.parse(group_settings)
      this.isSavedFilters = true
      this.savedYear = storage.yearBegin
      this.selectDepartment = storage.facultyId
      this.selectFilials = storage.filialId
      this.selectStatus = storage.statusId
      this.skip = storage.skip
      this.group = storage.group
      this.sort = storage.sort
      this.pageSize = storage.pageSize
      this.filter = storage.filter
      setTimeout(() => {
        window.scrollBy(0,storage.posY)
      }, 100);
    }

    this.getAllAcademicGroup(this.savedYear ? this.savedYear: this.thisYear);
  }

  public gridView!: GridDataResult;
  public pageSize = 10;
  public sizes = [10, 20, 50];
  public skip = 0;
  public isSavedFilters = false;

  public pageChange({ skip, take }: PageChangeEvent): void {
    this.skip = skip;
    this.pageSize = take;
    this.loadItems();
  }

  public loadItems() {
    this.gridView = {
      data: groupBy(orderBy(filterBy(this.academicGroups.slice(0),this.filter),this.sort), this.group).slice(this.skip, this.skip + this.pageSize),
      total: groupBy(orderBy(filterBy(this.academicGroups.slice(0),this.filter),this.sort), this.group).slice().length//this.filteredAcademicGroups.length
    };
  }

  public cellClick({ columnIndex, dataItem }: CellClickEvent): void {
    if(columnIndex == 1) this.transitionHandler(dataItem);
    if(columnIndex == 3) this.openPlan(dataItem);
  }

  public transitionHandler(dataItem: any): void {
    let academicGroupSaves: any = {
      yearBegin: this.year,
      facultyId: this.selectDepartment,
      filialId: this.selectFilials,
      statusId: this.selectStatus,
      group: this.group,
      sort: this.sort,
      skip: this.skip,
      pageSize: this.pageSize,
      filter: this.filter,
      posX: window.scrollX,
      posY: window.scrollY
    }
    localStorage.setItem('group_settings', JSON.stringify(academicGroupSaves));

    if(this.year!=undefined&&this.year!=this.years.at(-1)!.value) dataItem.courseNum=this.year!-dataItem.admissionYear + 1
    this.router.navigateByUrl(`/contingent/editacademicgroups/${dataItem.eduGroupExternalId}`, { state: { academicGroup:dataItem, editable:this.editable } }).then();
  }

  public openPlan(dataItem: any) {
    let planRoute = `/education/${dataItem.programId}/plans`;
    if(dataItem.planId) planRoute += '/plan/' + dataItem.planId;
    window.open(planRoute, '_blank')
  }

  //Department
  public getAllDepartment() {
    this.departmentService.getAllDepartment()
      .subscribe(
        response => {
          this.departments = response;
        }
      );
  }

  //DictStudyForm
  public getAllStudyForm() {
    this.commonDictService.getAllStudyForm()
      .subscribe(
        response => {
          this.dictStudyForms = response;
        }
      );
  }

  public getAllTrainingLevel() {
    this.commonDictService.getAllTrainingLevel()
      .subscribe(
        response => {
          this.trainingLevels = response;
        }
      );
  }

  public editable?: boolean

  public getAccessLevel() {
    this.userAccessService.getAccessLevel()
      .subscribe({
        next:(response) => {
          this.editable = response.groupAccessLevel != 1
        },
        error: () => {}
      });
  }

  selectableSettings:boolean= false;

  public getAllAcademicGroup(year: number) {
    this.loading = true;

    this.academicGroupRequest = {
      year: year,
      facultyId: this.selectDepartment,
      filialIds: this.selectFilials,
      status: this.selectStatus,
    }

    const query = CreateQuery(this.academicGroupRequest);
    this.eduGroupService.getAllAcademicGroup(query)
      .subscribe({
        next: (response) => {
          this.academicGroups = response;
          this.loading = false;
          if(this.studyYearArr.length === 0) this.getStudyYearList(this.academicGroups)
          this.isSavedFilters = false
          this.loadItems()
          //if(this.nextYear) this.onValueYearChange(this.years[this.years.length-1].value)
        },
        error: () => {
          this.notificationService.showError("Отсутствует подключение к серверу");
          this.loading = false;
        }});
  }

  public studyYearArr:number[]=[]
  //создание списка учебных годов из всех существующих
  public getStudyYearList(arr: AcademicGroup[]){
    for(let group of arr){
      this.studyYearArr.push(group.admissionYear)
    }
    let dateNow = new Date()
    if(dateNow.getMonth()>6) this.studyYearArr.push(dateNow.getFullYear()+1)
    else this.studyYearArr.push(dateNow.getFullYear())

    this.savedYear===undefined ? this.year = this.thisYear: this.year=this.savedYear
    this.studyYearArr=this.studyYearArr.filter((el, ind) => ind === this.studyYearArr.indexOf(el)).sort();
    for(let year of this.studyYearArr){
      this.years.push({text : year.toString() + "-" + (year + 1).toString(), value : year })
    }
    this.checkNextYear(this.year)
  }

  public getYears(){
    let dateNow = new Date()
    if(dateNow.getMonth()>6) this.thisYear = dateNow.getFullYear()
    else this.thisYear = dateNow.getFullYear()-1
  }

  public getAllFaculty() {
    this.departmentService.getAllFaculty(2)
      .subscribe(
        response => {
          this.faculties = response;
          this.filteredFaculties = this.faculties;
        }
      );
  }

  public getAllFilials() {
    this.dictFilialService.getAllfilial()
      .subscribe(
        response => {
          this.filials = response;
        }
      );
  }

  public addAcademicGroup() {
    let filters:AcademicGroupFilters = {
      yearBegin: this.year,
      facultyId: this.selectDepartment,
      planned: this.nextYear
    }

    let academicGroupSaves: any = {
      yearBegin: this.year,
      facultyId: this.selectDepartment,
      statusId: this.selectStatus,
      group: this.group,
      sort: this.sort,
      skip: this.skip,
      pageSize: this.pageSize,
      filter: this.filter,
      posX: window.scrollX,
      posY: window.scrollY
    }
    localStorage.setItem('group_settings', JSON.stringify(academicGroupSaves));

    this.router.navigateByUrl(`/contingent/addacademicgroups`, { state: { academicGroupFilters: filters} }).then();
  }

  public opened = false;

  public closeDialog(): void {
    this.opened = false;
  }

  public openDialog(): void {
    this.opened = true;
  }

  public updateNameGroups(): void{
    this.opened = false;
    this.loading = true;
    this.eduGroupService.massCreateNewGroupName(this.nameRequests)
      .subscribe({
        next: () => {
          this.notificationService.showSuccess("Названия групп обновлены");
          this.getAllAcademicGroup(this.thisYear)
          this.loading = false;
        },
        error: () => {
          this.notificationService.showError("Не удалось обновить названия групп");
          this.loading = false;
        }}
      );
  }

  public exportToExcel(grid: GridComponent): void {
    this.loaderExcelExport = true
    this.allData = this.allData.bind(this);
    if (grid != undefined) grid.saveAsExcel();
  }

  public allData = (): any => {
    let response = {
      data: orderBy(filterBy(this.academicGroups.slice(0),this.filter),this.sort).slice(),
      total: groupBy(orderBy(filterBy(this.academicGroups.slice(0),this.filter),this.sort), this.group).slice().length
    };
    this.loaderExcelExport = false
    return response;
  };

  public close(): void {
    this.selectableSettings=false;
  }

  public onValueYearChange(year:any) {
    this.checkNextYear(year)
    this.getAllAcademicGroup(year)
  }

  public checkNextYear(year:any){
    this.nextYear = year === this.years[this.years.length - 1].value;
  }

  public onFilterChange() {
    if(this.year != undefined)
      this.getAllAcademicGroup(this.year)
  }

  public onFilialChange(e: any) {
    this.filteredFaculties = e === undefined ? this.faculties : this.faculties.filter((f) => f.filialId==e);
    this.selectDepartment = undefined;
    if(this.year != undefined)
      this.getAllAcademicGroup(this.year);
  }

  onGroupChange(group: GroupDescriptor[]): void {
    this.skip=0
    // set aggregates to the returned GroupDescriptor
    group.map((group) => (group.aggregates = this.aggregates));

    this.group = group;
    this.loadItems();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.skip=0
    this.sort = sort;
    this.loadItems();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.skip=0
    this.filter = filter;
    this.loadItems();
  }

  public rowCallback = (context: RowClassArgs) => {
    if (context.dataItem.closed === true || (this.nextYear && context.dataItem.closedNewYear === true)) {
      return { gray: true };
    } else {
      return {  };
    }
  };

  public group: GroupDescriptor[] = [];
  public aggregates: AggregateDescriptor[] = [];

  public sort: SortDescriptor[] = [
    {
      field: "courseNum",
      dir: "asc",
    },
  ];

  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };

  public position: "top" | "bottom" | "both" = "both";
  public defaultItemFaculty: { shortName: string; name: string; id?: any } = {
    shortName: "Все",
    name: "Все",
    id: null,
  };

  public defaultItemFilial: { filialSName: string; id?: any } = {
    filialSName: "Все",
    id: null,
  };

  public groupStatus: any[] = [
    {
      name: "Активный",
      id: false,
    },
    {
      name: "Архивированный",
      id: true,
    },
  ];

  public years: Array<{ text: string; value: number }> = [
  ];

  public mySelectionKey(context: RowArgs): string {
    return context.dataItem;
  }
}

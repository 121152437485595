import { Budget } from "./budget.model";
import {Guid} from "guid-typescript";

export class BudgetSubCategory{
  constructor() {}
  public dictStudentBudgetSubCategoryId!: number;
  public dictStudentBudgetSubCategoryExternalId?: string;
  public dictStudentBudgetCategoryId?: string;
  public dictStudentBudget?: Budget;
  public budgetSubCategoryName!: string;
  public filialId!: string;
}

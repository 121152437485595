<div class="selectContent w-400 m-t20">
    <dd class="list">
      <span class="selectText">Год выдачи бланков</span>
      <kendo-dropdownlist class="w-200"
            [data]="years"
            (valueChange)="onChange($event)"
            [(ngModel)]="year"
        >
      </kendo-dropdownlist>
    </dd>
</div>

<kendo-grid
    class="m-t20"
    [kendoGridBinding]="blanks"
    [loading]="loading"
    [sortable]="true"
    [pageSize]="pageSize"
    [pageable]="pagerSettings"
    [filterable]="true"
    >
    <ng-template kendoGridLoadingTemplate>
        <div class="loading-container">
          <kendo-loader type="converging-spinner" size="medium"></kendo-loader>
        </div>
    </ng-template>

    <kendo-grid-column [width]="80"
                       title="№"
                       [filterable]="false"
                       [sortable]="false"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
          <span class="alignCells">
            {{ rowIndex + 1}}
          </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="issuerPersonFio"
                       [width]="200"
                       title="Выдал"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-combobox [useVirtual]='false' [filter]="filter" [data]="issuePersons" textField="issuerPersonFio" valueField="issuerPersonFio">
          </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                {{ dataItem.issuerPersonFio }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="recipientPersonFio"
                       [width]="200"
                       title="Получил"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-combobox [useVirtual]='false' [filter]="filter" [data]="recipientPersons" textField="recipientPersonFio" valueField="recipientPersonFio">
          </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                {{ dataItem.recipientPersonFio }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="issueFilterDate"
                       [width]="400"
                       title="Дата выдачи"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <date-range-filter [field]="column.field"
                             [filter]="filter">
          </date-range-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.issueFilterDate | date:'dd.MM.yyyy HH:mm' }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dictBlankTypeName"
                       [width]="250"
                       title="Тип бланков"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <filter-combobox [useVirtual]='false' [filter]="filter" [data]="blanksType" textField="dictBlankTypeName" valueField="dictBlankTypeName">
          </filter-combobox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells leftAlignment">
                {{ dataItem.dictBlankTypeName }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="count"
                       [width]="150"
                       [filterable]="false"
                       title="Количество"
                       headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="alignCells">
                {{ dataItem.count }}
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column 
            [width]="120"
            title="Расписка"
            [filterable]="false"
            headerClass="gridHeader dictionaries">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="alignCells">
            <button KendoButton kendoGridRemoveCommand icon="download"
                    fillMode="flat"
                    size="none"
                    class="custom-size"
                    rounded="full"
                    (click)="downloadReceipt(dataItem)">
            </button>
          </span>
        </ng-template>
    </kendo-grid-column>

</kendo-grid>
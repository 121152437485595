import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { PagerPosition } from '@progress/kendo-angular-grid';
import { PagerType } from '@progress/kendo-angular-pager';
import { keyBy } from 'lodash';
import { StudyFormService } from 'src/app/services/education/External/edu.studyforms.service';
import { EducationPlanDetailsModels } from "../../../../models/education/education-plan-details-model";
import { NotificationsService } from "../../../../services/notifications/notifications.service";
import { EducationPlanDetailsService } from "../../../../services/education/EducationPlan/education-plan-details.service";
import {Location} from '@angular/common';

@Component({
  selector: 'copy-plan',
  templateUrl: './copy-plan.component.html',
  styleUrls: ['./copy-plan.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
export class CopyPlanComponent implements OnInit {
  public eduProgramId: string = '';
  public destinationPlanId: string = '';
  public copyPlanList: EducationPlanDetailsModels.ECopyPlan[] = []
  public isLoading = true

  public type: PagerType = 'numeric';
  public buttonCount = 5;
  public info = true;
  public pageSizes = [20, 50, 100];
  public previousNext = true;
  public position: PagerPosition = 'bottom';
  public skip = 0;

  public statusesMap: any = {}
  public studyForms: string[] = [];

  constructor(
    activateRoute: ActivatedRoute,
    private router: Router,
    public educationPlanDetailsService: EducationPlanDetailsService,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
    private studyFormService: StudyFormService,
    private location: Location,
  ) {
    activateRoute.params.subscribe(params => {
      this.eduProgramId = params['education_program_id'];
      this.destinationPlanId = params['destination_plan_id'];
    });
  }

  ngOnInit(): void {
    if (!this.educationPlanDetailsService.currentEducationPlan?.educationPlanId) {
      if (!this.eduProgramId || !this.destinationPlanId) {
        this.router.navigate(['/education']);
      }
      this.educationPlanDetailsService.getPlanInfo(this.destinationPlanId).subscribe();
      this.educationPlanDetailsService.getDetails(this.eduProgramId).subscribe();
    }
    this.educationPlanDetailsService.getEduPlanCopyList(this.destinationPlanId).subscribe(copyPlanList => {
      this.copyPlanList = copyPlanList
      this.isLoading = false
    })
    this.studyFormService.getList().subscribe(
      studyForms => {
        this.studyForms = studyForms.map(studyForm => studyForm.name)
      }
    );
    this.statusesMap = keyBy(this.educationPlanDetailsService.eduPlanStatuses, 'value')
  }

  public onChoosePlan($event: any) {
    const sourcePlanId = $event.dataItem.id;
    const dialog: DialogRef = this.dialogService.open({
      title: "Пожалуйста подтвердите",
      content: "Вы действительно хотите скопировать данный УП?",
      actions: [{ text: "Нет" }, { text: "Да", themeColor: "primary" }],
      width: 450,
      height: 200,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) { }
      else {
        if (result.text == "Да") {
          if (sourcePlanId == this.destinationPlanId) this.notificationService.showError("Учебный план совпадает с текущим, выберите другой учебный план") 
          else {
            this.isLoading = true
            this.educationPlanDetailsService.copyEduPlan(sourcePlanId, this.destinationPlanId).subscribe(() => {
              this.notificationService.showSuccess("Учебный план скопирован");
              this.isLoading = false
              this.goBack()
            })
          }
        }
      }
    });
  }

  goBack() {
    this.location.back();
  }

}
